import React, { useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { Mutation, Query } from 'react-apollo';
import { Box } from 'rebass';
import styled from 'styled-components';
import gql from 'graphql-tag';
import FullLayout2 from '../../../components/FullLayout';
import Logo from '../../../components/Logo';
import PleaseWait from '../../../components/PleaseWait';
import { CANCEL_SUBSCRIPTION, CURRENT_USER, GET_MEMBERSHIPS } from '../../../graphql';
import SubscriptionCancelled from '../commonSteps/SubscriptionCancelled';
import Confirm from './steps/Confirm';
import Offers from './steps/Offers';
import Reason from './steps/Reason';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
  padding-top: 64px;
`;

const LogoContainer = styled(Box)`
  max-width: 200px;
  margin: 0 auto 32px auto;
`;

const PRE_CANCELLATION = gql`
  query getPreMembershipCancelInformation {
    getPreMembershipCancelInformation {
      flow
    }
  }
`;

export const CancelMembership = ({ location, history }) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [reason, setReason] = useState('');
  const subid = (location.state || {}).id;
  const subInterval = (location.state || {}).interval;
console.log({
  subInterval
})
  return (
    <FullLayout2 style={{ zIndex: 10, overflow: 'auto' }}>
      <Container>
        <LogoContainer>
          <Logo dark={false} />
        </LogoContainer>
        <Query query={PRE_CANCELLATION}>
          {({ data, loading: loadingPre }) => {
            if (!!loadingPre) {
              return <PleaseWait />;
            }
            return (
              <Mutation
                mutation={CANCEL_SUBSCRIPTION}
                refetchQueries={[{ query: GET_MEMBERSHIPS }, { query: CURRENT_USER }]}
                awaitRefetchQueries
                variables={{
                  subscriptionId: subid,
                  isMembership: true,
                }}
                onCompleted={() => {
                  setIsCompleted(true);
                }}
              >
                {(cancelSubscription, { loading }) => {
//                   const getCancelledDescription = () => {
//                     if(!data || !data.getPreMembershipCancelInformation || !data.getPreMembershipCancelInformation.flow ||
//                       (data.getPreMembershipCancelInformation.flow!=='INSURANCE' && 
//                       data.getPreMembershipCancelInformation.flow!=='SELFPAY_INSURANCE' && 
//                       data.getPreMembershipCancelInformation.flow!=='SELFPAY')){
// return "Your membership will end at the end of the current billing period. You will maintain access to all member services and benefits until that date."
//                       }else{
//                         if(reason === `I am not feeling better` || reason === `Poor experience with the Paloma Doctor`) {
//                           return `someone from your care team will reach out to schedule this free visit`
//                         }else{

//                         }
//                         return "Your membership will end at the end of the current billing period. You will maintain access to all member services and benefits until that date."
//                       }
//                   }
                  return loading ? (
                    <PleaseWait />
                  ) : isCompleted ? (
                    <SubscriptionCancelled
                      title="We are sorry to see you go."
                      description={"Your Paloma membership will remain active until the end of the billing period. You will maintain access to all member services and benefits until that date."}
                    />
                  ) : (
                    <Wizard
                      basename="/cancel-membership"
                      render={({ step, ...p }, a) => {
                        return (
                          <Steps>
                            <Step id="confirm">
                              <Confirm
                                goBack={() => {
                                  history.push('/my-subscriptions');
                                }}
                                confirm={(wizard) => wizard.next()}
                              />
                            </Step>
                            <Step id="reason">
                              <Reason
                                goBack={() => {
                                  p.previous();
                                }}
                                viewOffers={(wizard, reason) => {
                                  setReason(reason);
                                  wizard.next();
                                }}
                                confirm={() => cancelSubscription()}
                                hasOffers={subInterval !== 'year'}
                                interval={subInterval}
                                flow={((data || {}).getPreMembershipCancelInformation || {}).flow}
                              />
                            </Step>
                            <Step id="offers">
                              <Offers
                                goBack={() => {}}
                                confirm={() => cancelSubscription()}
                                subscriptionId={subid}
                                subscriptionType="membership"
                                reason={reason}
                                flow={((data || {}).getPreMembershipCancelInformation || {}).flow}
                              />
                            </Step>
                          </Steps>
                        );
                      }}
                    />
                  );
                }}
              </Mutation>
            );
          }}
        </Query>
      </Container>
    </FullLayout2>
  );
};
