import React, { useState } from 'react';
import { Flex, Box, Heading, Button } from 'rebass';
import { Mutation, Query, withApollo } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';
import { ADMIN_GET_MOBILE_COURSE_JOURNEYS, ADMIN_GET_THYROID_PROGRAMS_SECTIONS, ADMIN_MOBILE_CHANGE_ORDER_PROGRAM_SECTION, ADMIN_MOBILE_DELETE_PROGRAM_SECTION } from '../../graphql';
import ConfirmModal from '../../components/ConfirmModal';
import AddSection, { UpdateSection } from './modals/AddSection';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const SectionsList = ({ programId, client }) => {
  const [createNew, setCreateNew] = useState(false);
  const [edit, setEdit] = useState(null);

  const changeDirectionOnClick = async (id, direction) => {
    console.log('changeDirectionOnClick33', { id, direction, client });
    await client.mutate({
      mutation: ADMIN_MOBILE_CHANGE_ORDER_PROGRAM_SECTION,
      variables: {
        sectionId: id,
        direction,
      },
      refetchQueries: [
        {
          query: ADMIN_GET_THYROID_PROGRAMS_SECTIONS,
          variables: {
            programId: programId,
          },
        },
      ],
    });
  };

  const journeyColumns = [
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'description',
      accessor: 'description',
    },
    {
      Header: 'order',
      accessor: 'order',
    },
    {
      Header: 'active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div>{row.original.isActive ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'is deleted',
      accessor: 'isArchived',
      Cell: (row) => {
        return <div>{row.original.isArchived ? '✓' : ''}</div>;
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <Mutation
              mutation={ADMIN_MOBILE_DELETE_PROGRAM_SECTION}
              variables={{
                sectionId: row.original.id,
              }}
              refetchQueries={[
                {
                  query: ADMIN_GET_THYROID_PROGRAMS_SECTIONS,
                  variables: {
                    programId: programId,
                  },
                },
              ]}
            >
              {(mutate) => {
                return (
                  <div title="delete" style={{ cursor: 'pointer' }} onClick={mutate}>
                    🗑
                  </div>
                );
              }}
            </Mutation>
            <Mutation
              mutation={ADMIN_MOBILE_CHANGE_ORDER_PROGRAM_SECTION}
              variables={{
                sectionId: row.original.id,
                direction: 'UP',
              }}
              refetchQueries={[
                {
                  query: ADMIN_GET_THYROID_PROGRAMS_SECTIONS,
                  variables: {
                    programId: programId,
                  },
                },
              ]}
            >
              {(mutate) => {
                return (
                  <div title="move order up" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={mutate}>
                    ↑
                  </div>
                );
              }}
            </Mutation>
            <Mutation
              mutation={ADMIN_MOBILE_CHANGE_ORDER_PROGRAM_SECTION}
              variables={{
                sectionId: row.original.id,
                direction: 'DOWN',
              }}
              refetchQueries={[
                {
                  query: ADMIN_GET_THYROID_PROGRAMS_SECTIONS,
                  variables: {
                    programId: programId,
                  },
                },
              ]}
            >
              {(mutate) => {
                return (
                  <div title="move order down" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={mutate}>
                    ↓
                  </div>
                );
              }}
            </Mutation>
            <div
              title="edit"
              style={{ cursor: 'pointer', marginLeft: '12px' }}
              onClick={() => {
                setEdit(row.original);
              }}
            >
              ✎
            </div>
          </Flex>
        );
      },
    },
  ];
  return (
    <div>
      <Flex flexDirection="row" style={{ justifyContent: 'space-between' }} mt={4} mb={3}>
        <Heading fontSize="20px">Sections</Heading>
        <Button
          style={{ background: '#344f79' }}
          onClick={() => {
            setEdit(null);
            setCreateNew(true);
          }}
        >
          Add Section
        </Button>
      </Flex>
      <Query query={ADMIN_GET_THYROID_PROGRAMS_SECTIONS} variables={{ programId: programId }}>
        {({ data, loading, error }) => {
          if (loading || error) return <div />;
          console.log('GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES', data);
          const ordered = data.getAdminProgramSectionWithCourses.sort((a, b) => {
            if (a.order < b.order) return -1;
            return 1;
          });
          return (
            <Box>
              <ReactTable
                data={ordered}
                columns={journeyColumns}
                defaultPageSize={data.getAdminProgramSectionWithCourses && data.getAdminProgramSectionWithCourses.length > 3 ? Math.min(data.getAdminProgramSectionWithCourses.length, 25) : 3}
                showPagination={data.getAdminProgramSectionWithCourses && data.getAdminProgramSectionWithCourses.length > 24}
              />

              <ConfirmModal
                childrenManaged
                isOpen={createNew}
                confirmLabel="create"
                onClose={() => {
                  setCreateNew(false);
                }}
                onBackgroundClick={() => {
                  setCreateNew(false);
                }}
                onEscapeKeydown={() => {
                  setCreateNew(false);
                }}
                onConfirm={() => {
                  setCreateNew(false);
                }}
              >
                <ModalInner>
                  <Heading fontSize="20px" mb={'20px'}>
                    Create New Section
                  </Heading>
                  <AddSection
                    programId={programId}
                    order={data.getAdminProgramSectionWithCourses.length}
                    onClose={() => {
                      setCreateNew(false);
                    }}
                    onSave={() => {
                      setCreateNew(false);
                    }}
                  />
                </ModalInner>
              </ConfirmModal>

              <ConfirmModal
                childrenManaged
                isOpen={!!edit && !!edit.id}
                confirmLabel="create"
                onClose={() => {
                  setEdit(null);
                }}
                onBackgroundClick={() => {
                  setEdit(null);
                }}
                onEscapeKeydown={() => {
                  setEdit(null);
                }}
                onConfirm={() => {
                  setEdit(null);
                }}
              >
                <ModalInner>
                  <Heading fontSize="20px" mb={'20px'}>
                    Edit Section
                  </Heading>
                  <UpdateSection
                    section={edit}
                    programId={programId}
                    onClose={() => {
                      setEdit(null);
                    }}
                    onSave={() => {
                      setEdit(null);
                    }}
                  />
                </ModalInner>
              </ConfirmModal>
            </Box>
          );
        }}
      </Query>
    </div>
  );
};

export default withApollo(SectionsList);
