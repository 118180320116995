import React from 'react';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import LoadingScreen from '../components/LoadingScreen';
import withAnalytics from '../lib/withAnalytics';
import { CHECK_COUPON_EXISTS } from '../graphql';

class DeepLinksRedirect extends React.Component {
  constructor(props) {
    super(props);

    if (this.props && this.props.match && this.props.match.params) {
      this.props.analytics.page('deep link');
      const { linktype, env } = this.props.match.params;
      const urldomain = env==='p'?'palomamobile://' : 'palomamobilestaging://'
      if (linktype === 'resetpwd') {

        const { id, email } = this.props.match.params;
        const finalUrl = `${urldomain}/mobile-recovery/${email}/${id}`
        // console.log(finalUrl)
        window.location = finalUrl
        // cookies.set('referralCouponTest', coupon, {path: '/', sameSite: 'none', domain: '.palomahealth.com', secure: true})
        return;
      }
    }
    window.location = 'https://palomahealth.com';
  }

  checkCoupon = async coupon => {
    const { cookies } = this.props;
    cookies.set('referralCoupon', coupon, { path: '/', sameSite: false });
    try {
      const { data } = await this.props.client.query({
        query: CHECK_COUPON_EXISTS,
        variables: {
          coupon: coupon,
        },
      });

      if (data && data.checkCouponExists && data.checkCouponExists.found) {
        const firstname = data.checkCouponExists.firstName;
        const uri = `https://www.palomahealth.com/landing/referral?first_name=${firstname ||
          ''}&ID=${coupon}`;
        // console.log('Coupon found', {coupon, firstname, uri:encodeURI(uri)})
        window.location = encodeURI(uri);
        return;
      } else {
        window.location = 'https://palomahealth.com';
      }
    } catch (error) {
      console.log(error);
    }
    window.location = 'https://palomahealth.com';
  };
  render() {
    return <LoadingScreen />;
  }
}

export default withCookies(
  withRouter(withAnalytics(DeepLinksRedirect))
);
