import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading, Text } from 'rebass';
import { Formik, Form } from 'formik';
import {
  injectStripe,
  PaymentRequestButtonElement,
} from 'react-stripe-elements';
import { compose, withApollo } from 'react-apollo';
import { withWizard } from 'react-albus';
import { withToastManager } from 'react-toast-notifications';
import * as Yup from 'yup';
import { debounce } from 'lodash';

import { StripeInput } from '../../components/StripeInput';
import { FormField } from '../../components/FormField';
import InformationSecuredDisclaimer from '../../components/InformationSecuredDisclaimer';
import { useConstructor } from '../../utils/hooks/lifecycle/index';

const schema = Yup.object().shape({
  number: Yup.string().required('Required'),
  cvc: Yup.string().required('Required'),
  expire: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
});

const Payment = ({
  stripe,
  toastManager,
  defaultCard,
  address,
  onSubmit,
  wizard,
  onPayNow,
  canMakePayment,
  canMakePaymentApplePay,
  continuePayWithWallet,
  paymentRequest,
  createInitialOrder,
  additionalBiomarkersFeature,
  setSourceId
}) => {
  useConstructor(() => {
    if (!additionalBiomarkersFeature) {
      createInitialOrder();
    }
  });

  if (!address.street1) {
    wizard.push('profile');
  }

  const delayedonConfirmPurchase = debounce(q => createSourcePayNow(q), 500);
  const delayedonSubmit = debounce(q => onSubmit(q), 500);
  const createSourceWallet = async actions => {
    try {
      await continuePayWithWallet(actions, wizard, null);
    } catch (error) {
      console.log('ERR', error);
      actions.setSubmitting(false);
      toastManager.add('There was an error with the payment method.', {
        appearance: 'error',
      });
    }
  };

  const createSource = async actions => {
    try {
      const { source } = await stripe.createSource({ type: 'card' });
      setSourceId(source.id);

      await onSubmit(actions, wizard, source.id);
    } catch (error) {
      actions.setSubmitting(false);
      toastManager.add('There was an error with the payment method.', {
        appearance: 'error',
      });
    }
  };

  const createSourcePayNow = async setSubmitting => {
    setSubmitting(true);
    try {
      const { source } = await stripe.createSource({ type: 'card' });
      setSourceId(source.id);
      await onPayNow(setSubmitting, wizard, source.id);
    } catch (error) {
      setSubmitting(false);
      toastManager.add('There was an error with the payment method.', {
        appearance: 'error',
      });
    }
  };

  return (
    <Fragment>
      <Flex flexDirection="column" flex={1}>
        {defaultCard ? (
          <Card p={4} mb={4}>
            <Flex flexDirection="column" alignItems="center" mb={4}>
              <Heading style={{ fontWeight: 'bold' }} mb={4}>
                Default Payment Method
              </Heading>
              <Text>
                {defaultCard.brand.toUpperCase()} **
                {defaultCard.last4}
              </Text>
              <Text>
                Expires {defaultCard.expMonth}/{defaultCard.expYear}
              </Text>
            </Flex>

            <Flex justifyContent="center">
              <Button
                variant="pink"
                width={[1 / 2, 1 / 3]}
                onClick={() => delayedonSubmit(null, wizard)}
              >
                Review Order
              </Button>
            </Flex>
          </Card>
        ) : null}

        <Card p={4}>
          <Flex flexDirection="column" mb={4}>
            <Heading textAlign="center" style={{ fontWeight: 'bold' }} mb={2}>
              Payment Method
            </Heading>
          </Flex>
          {canMakePayment && paymentRequest ? (
            <Flex alignItems="normal" flexDirection={'column'}>
              <Text
                textAlign="center"
                fontWeight={500}
                style={{ marginTop: '-12px', marginBottom: '12px' }}
              >
                Express Checkout
              </Text>
              <PaymentRequestButtonElement
                paymentRequest={paymentRequest}
                className="PaymentRequestButton"
                value="Pay Now"
                style={{
                  // For more details on how to style the Payment Request Button, see:
                  // https://stripe.com/docs/elements/payment-request-button#styling-the-element
                  paymentRequestButton: {
                    theme: 'dark',
                    height: '48px',
                    width: '100%',
                    borderRadius: '20px',
                    marginBottom: '16px',
                    flex: 1,
                    minWidth: '300px',
                  },
                }}
              />
              <Flex
                flexDirection="row"
                alignItems="center"
                style={{ margin: '12px auto 12px auto' }}
              >
                <div
                  style={{
                    height: '1px',
                    background: 'black',
                    opacity: 0.5,
                    width: '48px',
                    marginRight: '12px',
                  }}
                />
                <Text>OR</Text>
                <div
                  style={{
                    height: '1px',
                    background: 'black',
                    opacity: 0.5,
                    width: '48px',
                    marginLeft: '12px',
                  }}
                />
              </Flex>
              <Text textAlign="center" fontWeight={500}>
                Pay By Credit Card
              </Text>
            </Flex>
          ) : null}

          <Text textAlign="center" fontWeight={300} mb={4}>
            You can also use your HSA/FSA card to pay for a kit
          </Text>
          <Formik
            initialValues={{ number: '', cvc: '', expire: '', zip: '' }}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
              await delayedonConfirmPurchase(actions.setSubmitting);
            }}
            render={({
              errors,
              touched,
              isValid,
              isSubmitting,
              actions,
              setSubmitting,
            }) => {
              return (
                <Form>
                  <Flex flexDirection="column">
                    <FormField error={touched.number && errors.number} mb={3}>
                      <StripeInput type="number" name="number" />
                    </FormField>

                    <Flex flexDirection={['column', 'column', 'row']} mb={3}>
                      <FormField
                        width={[1, 1, 1 / 3]}
                        mr={[0, 0, 3]}
                        mb={1}
                        error={touched.expire && errors.expire}
                      >
                        <StripeInput type="expire" name="expire" />
                      </FormField>

                      <FormField
                        width={[1, 1, 1 / 3]}
                        mr={[0, 0, 3]}
                        mb={1}
                        error={touched.cvc && errors.cvc}
                      >
                        <StripeInput type="cvc" name="cvc" />
                      </FormField>

                      <FormField
                        width={[1, 1, 1 / 3]}
                        mb={1}
                        error={touched.zip && errors.zip}
                      >
                        <StripeInput type="zip" name="zip" />
                      </FormField>
                    </Flex>
                  </Flex>

                  <Flex alignItems="center" flexDirection={['column', 'row']}>
                    <Button
                      disabled={!isValid || isSubmitting}
                      variant="pink"
                      mb={3}
                      mr={0}
                      width={1}
                      type="submit"
                    >
                      Purchase
                    </Button>
                  </Flex>
                  <InformationSecuredDisclaimer />
                </Form>
              );
            }}
          />
        </Card>
      </Flex>
    </Fragment>
  );
};

export default injectStripe(
  compose(
    withWizard,
    withApollo,
    withToastManager
  )(Payment)
);
