import Icon from '@iconify/react';
import check from '@iconify/icons-entypo/check';
import React, { useState } from 'react';
import { Box, Button, Flex, Text } from 'rebass';

const PickRelatedArticles = ({ workout, articlesList, relatedArticles, onClose, onSave }) => {
  const [selection, setSelection] = useState(articlesList.filter((x) => relatedArticles.filter((y) => decodeURIComponent(y) === decodeURIComponent(x.title)).length > 0));

  return (
    <Box>
      {articlesList.map((x) => {
        return (<Flex
          onClick={() => {
            if (selection.findIndex((y) => decodeURIComponent(y.title) === decodeURIComponent(x.title)) > -1) {
              setSelection(selection.filter((y) =>decodeURIComponent(y.title) !== decodeURIComponent(x.title)));
            } else {
              setSelection([...selection, x]);
            }
          }}
        >
          <Icon
            icon={check}
            style={{
              color: 'rgb(53, 78, 121)',
              fontSize: '18px',
              alignSelf: 'center',
              opacity: selection.findIndex((y) => decodeURIComponent(y.title) === decodeURIComponent(x.title)) > -1 ? 1 : 0,
            }}
          />

          <Text>{x.title}</Text>
        </Flex>);
      })}
      <Flex mt={4} flexDirection="row" alignItems="center" justifyContent="space-between">
        <Button
          style={{
            background: 'white',
            color: '#344f79',
            border: '1px solid #344f79',
          }}
          type="button"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button type="button" onClick={()=>onSave(selection)} style={{ background: '#344f79' }}>
          {'Save'}
        </Button>
      </Flex>
    </Box>
  );
};

export default PickRelatedArticles;
