import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { Flex, Text, Button } from 'rebass';
import Dropzone from 'react-dropzone';
import { Formik, Form } from 'formik';
import Div100vh from 'react-div-100vh';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { GET_CARE_TEAM_CONVERSATION, MEMBER_CONVERSATION_THREAD_REPLY } from '../../../graphql';

import SpinLoader from '../../SpinLoader';
import withSession from '../../../lib/withSession';
import TextArea, { AutoExtendTextArea } from '../../TextArea';
import AttachmentIcon from '../../../static/attachment.png';
import SendIcon from '../../../static/send.png';
import FormField from '../../FormField';
import BasicScrollToBottom, { StateContext } from '../../scrollToBottomAuto/index';
import { CustomerBubble, UserBubble, BotBubbleStyled, ToBeUploadedFilesMiniatures, AttachmentModal } from '../../communicator';
import withAnalytics from '../../../lib/withAnalytics';

const Inner = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 52em) {
    overflow-y: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

const PhantomMargin = styled.div`
  @media screen and (max-width: 52em) {
    height: 10px;
  }
`;

const FormWrapper = styled.div`
  @media screen and (max-width: 52em) {
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
    background: white;
    max-width: 100vw;
    box-shadow: ${(props) => (props.mobileApp ? ' -1px 6px 20px 1px #c1c1c1' : ' 0px -9px 23px -16px rgb(0, 0, 0)')};
  }
`;

const generateInitiateConversationVariables = (appointmentId, appointment, prescription, summary, thread) => {};

const Wrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 52em) {
    max-width: 100vw;
    width: 100vw;
    margin-top: ${(props) => (props.mobileApp ? '0' : '37px')};
    overflow-y: hidden;
    position: relative;
  }
`;

const MessagesDiv = styled(Div100vh)`
  cursor: auto;
  overflow-y: hidden;
  @media screen and (max-width: 52em) {
    overflow-y: hidden;
    padding-bottom: 10px;
    flex: 1;
    /* padding-bottom: 100px; */
  }
  @media screen and (min-width: 52em) {
    height: auto !important;
  }
`;
const MobileOnly = styled.div`
  display: none;
  @media screen and (max-width: 52em) {
    display: block;
  }
`;

const DesktopOnly = styled.div`
  display: none;
  @media screen and (min-width: 52em) {
    display: block;
  }
`;

class Communicator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      msg: '',
      pollInterval: 5000,
      threadsState: [],
    };
    this.input = React.createRef();
    this.threadContainer = React.createRef();
  }

  getThreadsObject = (data) => {
    const { threadsState } = this.state;
    let threads = [];

    if (data && data.getCareTeamConversation && data.getCareTeamConversation.length > 0) {
      const convos = [...data.getCareTeamConversation].reverse();
      for (let index = 0; index < convos.length; index++) {
        const element = convos[index];
        if (element.threads && element.threads.length) {
          const ths = element.threads;
          const filtered = (ths || []).filter((xx) => {
            if (xx.body && xx.body.toLowerCase() === 'initiate_1') return false;
            if (xx.state !== 'published') return false;
            if ((xx.type || '').toString().toLowerCase() !== 'message' && (xx.type || '').toString().toLowerCase() !== 'customer') return false;
            if (!xx.body && !xx._embedded && !xx._embedded.attachments && xx._embedded.attachments.length < 1) return false;
            return true;
            // xx.body && (xx.body || '').toLowerCase() !== 'initiate_1' && (xx.type || '').toString().toLowerCase() !== 'note'
          });
          if (filtered.length) {
            let tts = filtered.reverse();
            threads = [...threads, ...tts];
          }
        }
      }
    }
    if (!threadsState || threadsState.length !== threads.length) {
      this.setState({
        threadsState: threads,
      });
    }
  };

  render() {
    const { appointmentId, appointment, prescription, summary, loadingWrapper } = this.props;
    const { files, pollInterval, isSendingFirstMessage, threadsState } = this.state;

    return (
      <Query
        query={GET_CARE_TEAM_CONVERSATION}
        notifyOnNetworkStatusChange={true}
        fetchPolicy="cache-and-network"
        pollInterval={pollInterval}
        onCompleted={(data) => {
          if (data && data.getCareTeamConversation && data.getCareTeamConversation.length > 0) {
            this.getThreadsObject(data);
            if (pollInterval < 30000) {
              this.setState({
                pollInterval: 30000,
                isSendingFirstMessage: false,
              });
            } else if (isSendingFirstMessage) {
              this.setState({
                isSendingFirstMessage: false,
              });
            }
          }
        }}
      >
        {({ loading, error, data }) => {
          if (loading && (!data || !data.getCareTeamConversation)) {
            if (loadingWrapper) {
              return (
                <div
                  style={{
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '50%',
                  }}
                >
                  <loadingWrapper>
                    <SpinLoader />
                  </loadingWrapper>
                </div>
              );
            } else {
              return (
                <Flex flex={1} justifyContent="center" alignItems="center">
                  <SpinLoader />
                </Flex>
              );
            }
          }
          if (error) {
            if (loadingWrapper) {
              return (
                <div
                  style={{
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '50%',
                  }}
                >
                  {' '}
                  <loadingWrapper>Couldn't load messages {error}</loadingWrapper>
                </div>
              );
            } else {
              return (
                <Flex flex={1} justifyContent="center" alignItems="center">
                  Couldn't load messages
                </Flex>
              );
            }
          }
          if (data && data.getCareTeamConversation) {
            return (
              <Wrapper
                style={{
                  flex: '1 0 0',
                  overflowY: 'hidden',
                }}
                mobileApp={this.props.mobileApp || false}
              >
                <Dropzone
                  noClick={true}
                  id="dropzone"
                  multiple={true}
                  onDrop={(files) => {
                    // console.log('OnDrop', files);
                  }}
                  onDropRejected={this.handleRejected}
                  onDropAccepted={(f) => {
                    for (let index = 0; index < f.length; index++) {
                      const element = f[index];
                      // console.log('fff', element);
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        const fls = files;
                        fls.push({
                          fileName: element.name,
                          mimeType: element.type,
                          data: event.target.result,
                        });
                        this.setState({ files: fls });
                      };
                      reader.readAsDataURL(element);
                    }
                  }}
                  maxSize={10000000}
                  style={{ width: '100%', height: '100%' }}
                >
                  {({ getRootProps, getInputProps, isDragActive, open }) => (
                    <Inner>
                      <MessagesDiv
                        style={{
                          display: 'flex',
                          flex: 1,
                          paddingBottom: !!this.props.noBottomPadding ? '0px' : !!window && !!window.ReactNativeWebView ? '100px' : '',
                        }}
                        {...getRootProps()}
                        noClick={true}
                        id="messagesdiv"
                        className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        ref={this.threadContainer}
                      >
                        <BasicScrollToBottom paddingBottom="0px">
                          <StateContext.Consumer>
                            {({ sticky }) => {
                              return (
                                <>
                                  <input type="file" {...getInputProps()} />
                                  {data && data.getCareTeamConversation && threadsState.length ? (
                                    threadsState.map((thread, index) => {
                                      if (thread.createdBy.id.toString() === this.props.session.helpscoutId || thread.createdBy.type === 'customer') {
                                        return (
                                          <React.Fragment key={thread.id}>
                                            <div
                                              style={{
                                                width: '100%',
                                                height: '12px',
                                                display: index === 0 ? '' : 'none',
                                              }}
                                            />
                                            <CustomerBubble mobileApp={this.props.mobileApp} thread={thread} key={thread.id} />
                                          </React.Fragment>
                                        );
                                      } else {
                                        return (
                                          <React.Fragment key={thread.id}>
                                            <div
                                              style={{
                                                width: '100%',
                                                height: '12px',
                                                display: index === 0 ? '' : 'none',
                                              }}
                                            />
                                            <UserBubble mobileApp={this.props.mobileApp} thread={thread} key={thread.id} />
                                          </React.Fragment>
                                        );
                                      }
                                    })
                                  ) : (
                                    <Flex
                                      justifyContent="flex-start"
                                      style={{
                                        marginBottom: '24px',
                                        alignItems: isSendingFirstMessage ? 'center' : '',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      {isSendingFirstMessage ? (
                                        <>
                                          <SpinLoader />
                                          <br />
                                          Sending Your 1st Message
                                        </>
                                      ) : (
                                        <BotBubbleStyled>
                                          <Flex flexDirection="row" style={{ marginBottom: '16px' }}>
                                            <Text fontSize="16px" fontWeight="600">
                                              The Paloma Health Team
                                            </Text>
                                          </Flex>

                                          <Text
                                            style={{
                                              wordWrap: 'break-word',
                                              whiteSpace: 'pre-line',
                                            }}
                                          >
                                            Type your message below and press the button{' '}
                                            <span>
                                              <img height="16px" src={SendIcon} alt="send" />
                                            </span>{' '}
                                            to start your conversation with your Care Manager
                                          </Text>
                                        </BotBubbleStyled>
                                      )}
                                    </Flex>
                                  )}
                                  <Text textAlign="center" opacity={0.5} mt={3} style={{ opacity: 0.5 }}>
                                    usually replies within 1 business day
                                  </Text>

                                  <PhantomMargin />
                                </>
                              );
                            }}
                          </StateContext.Consumer>
                        </BasicScrollToBottom>
                      </MessagesDiv>

                      {/* <div
                        style={{
                          background: 'black',
                          opacity: 0.5,
                          height: '1px',
                          width: '100%',
                        }}
                      /> */}
                      <Mutation mutation={MEMBER_CONVERSATION_THREAD_REPLY}>
                        {(sendEmailReminder, { loading }) => {
                          return (
                            <Formik
                              initialValues={{ msgTxt: '' }}
                              validate={(values, props) => {
                                let errors = {};
                                if (files.length > 0 && !values.msgTxt) {
                                  // console.log('VALIDATE INV');
                                  errors.msgTxt('A message is required to send');
                                }
                                return errors;
                              }}
                              render={({ isValid, errors, touched, submitForm, setSubmitting, values, resetForm, isSubmitting, setFieldValue }) => (
                                <FormWrapper mobileApp={this.props.mobileApp || false}>
                                  <Form>
                                    <Flex flexDirection="column" pb={[4, 2]}>
                                      <MobileOnly>
                                        <Flex flexDirection="column">
                                          <Flex flexDirection="row" justifyContent={'space-between'}>
                                            {/* <Button
                                              type="button"
                                              style={{
                                                padding: '8px',
                                                alignSelf: 'flex-start',
                                                marginTop: '10px',
                                              }}
                                              variant="white"
                                              onClick={() => {
                                                open();
                                              }}
                                            >
                                              <img src={AttachmentIcon} width="20px" style={{ opacity: 0.75 }} alt="attach" />
                                            </Button> */}
                                            <Button
                                              type="button"
                                              style={{
                                                padding: '8px',
                                                alignSelf: 'flex-start',
                                                marginTop: '10px',
                                                display: 'flex',
                                              }}
                                              variant="white"
                                              onClick={() => {
                                                open();
                                              }}
                                            >
                                              <img src={AttachmentIcon} width="20px" style={{ opacity: 0.75 }} alt="attach" />
                                              <Text fontWeight={'300'}>Add file</Text>
                                            </Button>
                                            <Button
                                              disabled={(!values.msgTxt && (!files || files.length < 1)) || isSubmitting}
                                              style={{
                                                padding: '8px',
                                                alignSelf: 'flex-start',
                                                marginTop: '10px',
                                                marginRight: '6px',
                                                display: 'flex',
                                              }}
                                              type="button"
                                              variant="white"
                                              onClick={async () => {
                                                setSubmitting(true);
                                                const attachments = [];
                                                if (files) {
                                                  for (let index = 0; index < files.length; index++) {
                                                    const element = files[index];
                                                    attachments.push({
                                                      ...element,
                                                      data: element.data.substr(element.data.indexOf(',') + 1),
                                                    });
                                                  }
                                                }
                                                if (!data || !data.getCareTeamConversation || data.getCareTeamConversation.length < 1) {
                                                  // if (values.msgTxt) {
                                                  const vars = generateInitiateConversationVariables(appointmentId, appointment, prescription, summary, {
                                                    text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                    attachments: attachments,
                                                    isMemberConvo: true,
                                                  });
                                                  await sendEmailReminder({
                                                    variables: vars,
                                                    refetchQueries: [
                                                      {
                                                        query: GET_CARE_TEAM_CONVERSATION,
                                                      },
                                                    ],
                                                    awaitRefetchQueries: true,
                                                  });
                                                  resetForm({ msgTxt: '' });

                                                  this.props.analytics.track('Send Care Manager Message');
                                                  this.setState({
                                                    files: [],
                                                    pollInterval: 2000,
                                                    isSendingFirstMessage: true,
                                                  });
                                                } else {
                                                  await sendEmailReminder({
                                                    variables: {
                                                      conversationId: data.getCareTeamConversation[0].id.toString(),
                                                      text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                      attachments: attachments,
                                                      isMemberConvo: true,
                                                    },
                                                    refetchQueries: [
                                                      {
                                                        query: GET_CARE_TEAM_CONVERSATION,
                                                      },
                                                    ],
                                                  });

                                                  resetForm({ msgTxt: '' });

                                                  this.props.analytics.track('Send Care Manager Message');
                                                  this.setState({
                                                    files: [],
                                                  });
                                                }

                                                setSubmitting(false);
                                              }}
                                            >
                                              <Text fontWeight={'300'}>send</Text>
                                              <img src={SendIcon} width="20px" style={{ opacity: 0.75 }} alt="send" />
                                            </Button>
                                          </Flex>
                                          {/* <FormField
                                            style={{
                                              flexGrow: 1,
                                              maxHeight: '40vh',
                                              minHeight: '59px',
                                              height: 'auto',
                                            }}
                                            error={errors.msgTxt}
                                          > */}
                                            {/* <GrowWrap> */}
                                            <AutoExtendTextArea
                                              disabled={isSubmitting}
                                              name="msgTxt"
                                              style={{
                                                flexGrow: 1,
                                                background: 'white',
                                                border: '0px solid white',
                                                maxHeight: '40vh',
                                                minHeight: '59px',
                                                font: 'inherit',
                                                padding: '4px 12px',
                                                outlineStyle: 'none',
                                                boxShadow: 'none',
                                              }}
                                              placeholder="type your message..."
                                              id="autocomplete"
                                              className="input-field"
                                              type="text"
                                              value={values.msgTxt || ''}
                                              onChange={e=>{setFieldValue('msgTxt',e.target.value)}}

                                            />
                                            {/* </GrowWrap> */}
                                          {/* </FormField> */}
                                        </Flex>
                                      </MobileOnly>
                                      <DesktopOnly>
                                        <Flex flexDirection="row">
                                          <Button
                                            type="button"
                                            style={{
                                              padding: '8px',
                                              alignSelf: 'flex-start',
                                              marginTop: '10px',
                                            }}
                                            variant="white"
                                            onClick={() => {
                                              open();
                                            }}
                                          >
                                            <img src={AttachmentIcon} width="20px" style={{ opacity: 0.75 }} alt="attach" />
                                          </Button>
                                          {/* <FormField
                                            style={{
                                              flexGrow: 1,
                                              maxHeight: '40vh',
                                              minHeight: '59px',
                                              height: 'auto',
                                            }}
                                            error={errors.msgTxt}
                                          > */}
                                            {/* <GrowWrap> */}
                                            <AutoExtendTextArea
                                              disabled={isSubmitting}
                                              name="msgTxt"
                                              style={{
                                                flexGrow: 1,
                                                background: 'white',
                                                border: '0px solid white',
                                                maxHeight: '40vh',
                                                minHeight: '59px',
                                                font: 'inherit',
                                                padding: '4px 12px',
                                                outlineStyle: 'none',
                                                boxShadow: 'none',
                                              }}
                                              placeholder="type your message..."
                                              id="autocomplete"
                                              className="input-field"
                                              type="text"
                                              value={values.msgTxt || ''}
                                              onChange={e=>{setFieldValue('msgTxt',e.target.value)}}
                                            />
                                            {/* </GrowWrap> */}
                                          {/* </FormField> */}
                                          {/* <FormField
                                          style={{
                                            flexGrow: 1,
                                            maxHeight: '150px',
                                            minHeight: '59px',
                                            height: 'auto',
                                            overflowX: 'hidden',
                                          }}
                                          error={errors.msgTxt}
                                        >
                                          <TextArea
                                            disabled={isSubmitting}
                                            name="msgTxt"
                                            style={{
                                              flexGrow: 1,
                                              background: 'white',
                                              border: '0px solid white',
                                              maxHeight: '150px',
                                              minHeight: '59px',
                                              height: 'auto',
                                            }}
                                            placeholder="type your message..."
                                            id="autocomplete"
                                            className="input-field"
                                            type="text"
                                          />
                                        </FormField> */}
                                          <Button
                                            disabled={(!values.msgTxt && (!files || files.length < 1)) || isSubmitting}
                                            style={{
                                              padding: '8px',
                                              alignSelf: 'flex-start',
                                              marginTop: '10px',
                                              marginRight: '6px',
                                            }}
                                            type="button"
                                            variant="white"
                                            onClick={async () => {
                                              setSubmitting(true);
                                              const attachments = [];
                                              if (files) {
                                                for (let index = 0; index < files.length; index++) {
                                                  const element = files[index];
                                                  attachments.push({
                                                    ...element,
                                                    data: element.data.substr(element.data.indexOf(',') + 1),
                                                  });
                                                }
                                              }
                                              if (!data || !data.getCareTeamConversation || data.getCareTeamConversation.length < 1) {
                                                // if (values.msgTxt) {
                                                const vars = generateInitiateConversationVariables(appointmentId, appointment, prescription, summary, {
                                                  text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                  attachments: attachments,
                                                  isMemberConvo: true,
                                                });
                                                await sendEmailReminder({
                                                  variables: vars,
                                                  refetchQueries: [
                                                    {
                                                      query: GET_CARE_TEAM_CONVERSATION,
                                                    },
                                                  ],
                                                  awaitRefetchQueries: true,
                                                });
                                                resetForm({ msgTxt: '' });

                                                this.props.analytics.track('Send Care Manager Message');
                                                this.setState({
                                                  files: [],
                                                  pollInterval: 2000,
                                                  isSendingFirstMessage: true,
                                                });
                                              } else {
                                                await sendEmailReminder({
                                                  variables: {
                                                    conversationId: data.getCareTeamConversation[0].id.toString(),
                                                    text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                    attachments: attachments,
                                                    isMemberConvo: true,
                                                  },
                                                  refetchQueries: [
                                                    {
                                                      query: GET_CARE_TEAM_CONVERSATION,
                                                    },
                                                  ],
                                                });

                                                resetForm({ msgTxt: '' });

                                                this.props.analytics.track('Send Care Manager Message');
                                                this.setState({
                                                  files: [],
                                                });
                                              }

                                              setSubmitting(false);
                                            }}
                                          >
                                            <img src={SendIcon} width="20px" style={{ opacity: 0.75 }} alt="send" />
                                          </Button>
                                        </Flex>
                                      </DesktopOnly>
                                      {files && files.length > 0 ? (
                                        <>
                                          {isMobile ? (
                                            <ToBeUploadedFilesMiniatures
                                              files={files}
                                              onFileRemove={(index) => {
                                                const fls = files;
                                                fls.splice(index, 1);
                                                this.setState({ files: fls });
                                              }}
                                            />
                                          ) : (
                                            <AttachmentModal
                                              isSubmitting={isSubmitting}
                                              onFileRemove={(index) => {
                                                const fls = files;
                                                fls.splice(index, 1);
                                                this.setState({ files: fls });
                                              }}
                                              confirmModalForm={async () => {
                                                setSubmitting(true);
                                                const attachments = [];
                                                if (files) {
                                                  for (let index = 0; index < files.length; index++) {
                                                    const element = files[index];
                                                    attachments.push({
                                                      ...element,
                                                      data: element.data.substr(element.data.indexOf(',') + 1),
                                                    });
                                                  }
                                                }
                                                if (!data || !data.getCareTeamConversation || data.getCareTeamConversation.length < 1) {
                                                  // if (values.msgTxt) {
                                                  const vars = generateInitiateConversationVariables(appointmentId, appointment, prescription, summary, {
                                                    text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                    attachments: attachments,
                                                    isMemberConvo: true,
                                                  });
                                                  await sendEmailReminder({
                                                    variables: vars,
                                                    refetchQueries: [
                                                      {
                                                        query: GET_CARE_TEAM_CONVERSATION,
                                                      },
                                                    ],
                                                    awaitRefetchQueries: true,
                                                  });
                                                  resetForm({ msgTxt: '' });

                                                  this.props.analytics.track('Send Care Manager Message');
                                                  this.setState({
                                                    files: [],
                                                    pollInterval: 2000,
                                                    isSendingFirstMessage: true,
                                                  });
                                                } else {
                                                  await sendEmailReminder({
                                                    variables: {
                                                      conversationId: data.getCareTeamConversation[0].id.toString(),
                                                      text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                      attachments: attachments,
                                                      isMemberConvo: true,
                                                    },
                                                    refetchQueries: [
                                                      {
                                                        query: GET_CARE_TEAM_CONVERSATION,
                                                      },
                                                    ],
                                                  });

                                                  resetForm({ msgTxt: '' });

                                                  this.props.analytics.track('Send Care Manager Message');
                                                  this.setState({
                                                    files: [],
                                                  });
                                                }

                                                setSubmitting(false);
                                              }}
                                              close={() => {
                                                this.setState({
                                                  files: [],
                                                });
                                              }}
                                              files={files}
                                              values={values}
                                              addFiles={(files) => {
                                                // console.log('FilsAdd', files);
                                                this.setState({ files });
                                              }}
                                            />
                                          )}
                                        </>
                                      ) : null}
                                    </Flex>
                                  </Form>
                                </FormWrapper>
                              )}
                            />
                          );
                        }}
                      </Mutation>
                    </Inner>
                  )}
                </Dropzone>
              </Wrapper>
            );
          }
          return <div />;
        }}
      </Query>
    );
  }
}

export default withSession(withAnalytics(Communicator));
