import React, { useEffect, useRef, useState } from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import FullPage from './components/FullPage';
import Slide from './components/Slide';
import IntroductionLayout from './layouts/Introduction';
import PickOneLayout from './layouts/PickOneLayout';
import PickOneOtherLayout from './layouts/PickOneOtherLayout';
import { sleep } from '../../utils/sleep';
import ConsentLayout from './layouts/Consent';
import PickMultipleOtherLayout from './layouts/PickMultipleOtherLayout';
import FormerBackground from './FormerBackground';
import { FormerTitle } from './components/Shared';
import SlideSectionContentElement from './components/SlideSectionContentElement';
import FullLayout2 from '../FullLayout';
import withAnalytics from '../../lib/withAnalytics';
import OpenTextLayout from './layouts/OpenTextLayout';
import { isMobile } from 'react-device-detect';

const FullLayoutBg = styled(Flex)`
  position: absolute;
  flex: 1;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  min-height: 100vh;
  overflow: hidden;
  width: 100vw;
  flex-direction: column;
`;

const SlideSection = styled(Flex)`
  height: 100%;
  z-index: 10000;
  & > * {
    z-index: 10;
  }
`;

const SlideSectionContent = styled(Flex)`
  width: 700px;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const Former = ({ questions, getPastData, localStorageVariable, pageName, analytics, onCompleted, onNext }) => {
  const [maxViewedSlide, setMaxViewedSlide] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [preIndex, setPreIndex] = useState(0);
  const [data, setData] = useState(!!getPastData ? getPastData : []);
  const refBox = useRef(null);

  useEffect(() => {
    const rem = async () => {
      await sleep(500);
      if (window.Beacon) {
        window.Beacon('on', 'ready', () => window.Beacon('destroy'));
      } else {
        await sleep(3000);
        if (window.Beacon) {
          window.Beacon('on', 'ready', () => window.Beacon('destroy'));
        }
      }
      await sleep(3000)
      try {
        window.Beacon('destroy')
      } catch (error) {
        
      }
    };

    rem();
  }, []);

  const onNextSlide = (selection) => {
    if (selection) {
      // console.log({ id: (selection || {}).id, questions, selection });
      const currentIndex = data.findIndex((x) => x.id === selection.id);
      let dta = JSON.parse(JSON.stringify(data));
      if (currentIndex > -1) {
        dta[currentIndex].selection = selection.selection;
      } else {
        dta.push(selection);
      }
      if (!!localStorageVariable) {
        localStorage.setItem(localStorageVariable, JSON.stringify(dta));
      }
      // if (!!onNext) {
      //   onNext(JSON.stringify(dta));
      // }
      if (!!analytics) {
        // console.log({ id: (selection || {}).id, questions });
        const slide = (questions || []).find((x) => x.id === selection.id);
        analytics.track(`${pageName} - ${selection.id}`, {
          question: slide.id,
          selection: selection.selection,
        });
      }
      setData(dta);
      const slideIndex = (questions || []).findIndex((x) => x.id === selection.id);
      if (slideIndex >= questions.length - 1) {
        if (!!onCompleted) {
          onCompleted(JSON.stringify(dta));
        }
      }else{

      if (!!onNext) {
        onNext(JSON.stringify(dta));
      }
      }
    }
    if (refBox && refBox.current) {
      refBox.current.scrollNext();
    }
  };

  const onSlideChanged = ({ from, to }) => {
    setCurrentIndex(to);
    if (refBox && refBox.current) {
      refBox.current.waitingInput(false);
    }
    if (to > maxViewedSlide) {
      setMaxViewedSlide(to);
    }
  };

  const waitingUserInput = (value) => {
    if (refBox && refBox.current) {
      refBox.current.waitingInput(value);
    }
  };

  const beforeChange = ({ from, to }) => {
    setPreIndex(to);
  };

  return (
    <FullLayout2 style={{ zIndex: 1, overflow: 'hidden', maxHeight: '100vh' }}>
      <FormerBackground questionsLength={questions.length} preIndex={preIndex} />
      <FullPage
        controls
        ref={refBox}
        data={data}
        afterChange={onSlideChanged}
        questions={questions}
        maxViewedSlide={maxViewedSlide}
        beforeChange={beforeChange}
        initialSlide={!data || data.length < 1 ? 0 : data.length - 1}
      >
        {questions.map((x, i) => {
          return (
            <Slide key={`slide-${x.id}`}>
              <SlideSection
                style={{
                  paddingLeft: isMobile ? '10px' : 'calc(100vw * 0.16)',
                  paddingRight: isMobile ? '10px' : 'calc(100vw * 0.16)',
                }}
              >
                <SlideSectionContent>
                  <SlideSectionContentElement>
                    <FormerTitle questionNumber={i + 1} title={x.title} isRequired={x.cantBypass} />
                    <>
                      {x.type === 'INTRODUCTION' ? (
                        <IntroductionLayout
                          text={x.text}
                          nextTitle={x.nextTitle}
                          defaultValue={data.find((xx) => xx.id === x.id)}
                          onNext={onNextSlide}
                          onSetWaitingUserInput={waitingUserInput}
                          id={x.id}
                          pageName={pageName}
                        />
                      ) : x.type === 'PICKONE' ? (
                        <PickOneLayout
                          options={x.options}
                          onNext={onNextSlide}
                          defaultValue={data.find((xx) => xx.id === x.id)}
                          nextTitle={x.nextTitle}
                          onSetWaitingUserInput={waitingUserInput}
                          id={x.id}
                          pageName={pageName}
                        />
                      ) : x.type === 'PICKONEOTHER' ? (
                        <PickOneOtherLayout
                          options={x.options}
                          onNext={onNextSlide}
                          defaultValue={data.find((xx) => xx.id === x.id)}
                          nextTitle={x.nextTitle}
                          onSetWaitingUserInput={waitingUserInput}
                          isActive={i === currentIndex}
                          id={x.id}
                          pageName={pageName}
                        />
                      ) : x.type === 'CONSENT' ? (
                        <ConsentLayout
                          options={x.options}
                          onNext={onNextSlide}
                          defaultValue={data.find((xx) => xx.id === x.id)}
                          nextTitle={x.nextTitle}
                          onSetWaitingUserInput={waitingUserInput}
                          isActive={i === currentIndex}
                          id={x.id}
                          pageName={pageName}
                        />
                      ) : x.type === 'PICKMULTIPLEOTHER' ? (
                        <PickMultipleOtherLayout
                          options={x.options}
                          onNext={onNextSlide}
                          defaultValue={data.find((xx) => xx.id === x.id)}
                          nextTitle={x.nextTitle}
                          onSetWaitingUserInput={waitingUserInput}
                          isActive={i === currentIndex}
                          id={x.id}
                          pageName={pageName}
                          isMandatory={x.cantBypass}
                        />
                      ) : x.type === 'OPENTEXT' ? (
                        <OpenTextLayout
                          options={x.options}
                          onNext={onNextSlide}
                          defaultValue={data.find((xx) => xx.id === x.id)}
                          nextTitle={x.nextTitle}
                          onSetWaitingUserInput={waitingUserInput}
                          isActive={i === currentIndex}
                          id={x.id}
                          pageName={pageName}
                          isMandatory={x.cantBypass}
                        />
                      ) : null}
                    </>
                  </SlideSectionContentElement>
                </SlideSectionContent>
              </SlideSection>
            </Slide>
          );
        })}
      </FullPage>
    </FullLayout2>
  );
};

export default withAnalytics(Former);
