import React from 'react';
import { Flex, Box, Heading, Button } from 'rebass';
import { Query, withApollo } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';
import {
  ADMIN_GET_THYROID_PROGRAM_COURSES,
  ADMIN_DELETE_MOBILE_COURSE,
  ADMIN_MOBILE_CHANGE_ORDER_COURSE,
  ADMIN_THYROID_PROGRAM_CHANGE_ORDER_COURSE,
  ADMIN_DELETE_THYROID_PROGRAM_COURSE,
} from '../../graphql';
import AddProgramCourseModalInner from './modals/AddProgramCourseModalInner';
// import AddCourseModalInner from './modals/AddCourseModalInner';
import ConfirmModal from '../../components/ConfirmModal';
import ProgramCoursesListScreenDetailRow from './ProgramCoursesListScreenDetailRow';
// import CoursesListScreenDetailRow from './coursesListScreenDetailRow';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;

class ProgramCoursesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddNewJourneyVisible: false,
      editingData: null,
    };
  }

  deleteOnClick = async (courseId) => {
    const { client, programId } = this.props;
    var r = window.confirm('Confirm Delete2. (Any screen logged by user will be marked as inactive instead)');
    if (r === true) {
      await client.mutate({
        mutation: ADMIN_DELETE_THYROID_PROGRAM_COURSE,
        variables: {
          courseId,
          direction: 'UP'
        },
        refetchQueries: [
          {
            query: ADMIN_GET_THYROID_PROGRAM_COURSES,
            variables: {
              programId,
            },
          },
        ],
      });
    }
  };

  changeDirectionOnClick = async (id, direction) => {
    const { client, programId } = this.props;
    await client.mutate({
      mutation: ADMIN_THYROID_PROGRAM_CHANGE_ORDER_COURSE,
      variables: {
        courseId: id,
        direction,
      },
      refetchQueries: [
        {
          query: ADMIN_GET_THYROID_PROGRAM_COURSES,
          variables: {
            programId,
          },
        },
      ],
      awaitRefetchQueries: true
    });
  };

  coursesColumns = [
    {
      Header: 'title',
      accessor: 'title',
    },
    // {
    //   Header: 'week',
    //   accessor: 'week',
    //   Cell: (row) => {
    //     return <div>week {row.original.week}</div>;
    //   },
    // },
    {
      Header: 'order',
      accessor: 'order',
      Cell: (row) => {
        return <div style={{ textAlign: 'center' }}>{row.original.order}</div>;
      },
    },
    {
      Header: 'section',
      accessor: 'programSection',
      Cell: (row) => {
        return <div>{row.original.programSection ? row.original.programSection.name : ''}</div>;
      },
    },
    {
      Header: 'type',
      Cell: (row) => {
        let typ = 'Standard';
        if (row.original.isFoodJournaling) typ = 'Food Journaling';
        if (row.original.webflowArticleId) typ = 'Webflow Article';
        if (row.original.externalArticleLink) typ = 'External Link';
        return <div style={{ textAlign: 'center' }}>{typ}</div>;
      },
    },
    {
      Header: 'colors',
      Cell: (row) => {
        return (
          <Flex flexDirection="row">
            <Box
              title="screen color"
              style={{
                height: '24px',
                width: '24px',
                marginRight: '12px',
                border: '1px solid #777',
                backgroundColor: row.original.bgMainColor,
              }}
            />
            <Box
              title="hormone color"
              style={{
                height: '24px',
                width: '24px',
                marginRight: '12px',
                border: '1px solid #777',
                backgroundColor: row.original.bgBubblesColor,
              }}
            />
          </Flex>
        );
      },
    },
    {
      Header: 'Pro',
      accessor: 'isPro',
      Cell: (row) => {
        return <div>{row.original.isPro ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div>{row.original.isActive ? '✓' : ''}</div>;
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div title="delete" style={{ cursor: 'pointer' }} onClick={() => this.deleteOnClick(row.original.id)}>
              🗑
            </div>
            <div title="move order up" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => this.changeDirectionOnClick(row.original.id, 'UP')}>
              ↑
            </div>
            <div title="move order down" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => this.changeDirectionOnClick(row.original.id, 'DOWN')}>
              ↓
            </div>
            <div
              title="edit"
              style={{ cursor: 'pointer', marginLeft: '12px' }}
              onClick={() =>
                this.setState({
                  isAddNewJourneyVisible: true,
                  editingData: row.original,
                })
              }
            >
              ✎
            </div>
          </Flex>
        );
      },
    },
  ];

  render() {
    const { programId } = this.props;
    const { isAddNewJourneyVisible, editingData } = this.state;
    return (
      <div>
        <Flex flexDirection="row" style={{ justifyContent: 'space-between' }} mt={4} mb={3}>
          <Heading fontSize="20px">Courses</Heading>
          <Button
            style={{ background: '#344f79' }}
            onClick={() => {
              this.setState({ isAddNewJourneyVisible: true });
            }}
          >
            Add Course
          </Button>
        </Flex>
        <Query
          query={ADMIN_GET_THYROID_PROGRAM_COURSES}
          variables={{
            programId,
          }}
        >
          {({ data, loading, error }) => {
            if (loading || error) return <div />;
            console.log('ADMIN_GET_MOBILE_COURSES', data);
            return (
              <Box style={{marginBottom: '72px'}}>
                <ReactTable
                  data={data.getAdminThyroidProgramCourses}
                  columns={this.coursesColumns}
                  SubComponent={(row) => {
                    return (
                      <ProgramCoursesListScreenDetailRow original={row.original} />
                    );
                  }}
                  defaultPageSize={data.getAdminThyroidProgramCourses && data.getAdminThyroidProgramCourses.length > 3 ? Math.min(data.getAdminThyroidProgramCourses.length, 25) : 3}
                  pageSize={data.getAdminThyroidProgramCourses && data.getAdminThyroidProgramCourses.length > 3 ? data.getAdminThyroidProgramCourses.length : 3}
                  showPagination={data.getAdminThyroidProgramCourses && data.getAdminThyroidProgramCourses.length > 24}
                />

                <ConfirmModal
                  childrenManaged
                  isOpen={isAddNewJourneyVisible}
                  confirmLabel="create"
                  onClose={() => {
                    this.setState({
                      isAddNewJourneyVisible: false,
                      editingData: null,
                    });
                  }}
                  onBackgroundClick={() => {
                    this.setState({
                      isAddNewJourneyVisible: false,
                      editingData: null,
                    });
                  }}
                  onEscapeKeydown={() => {
                    this.setState({
                      isAddNewJourneyVisible: false,
                      editingData: null,
                    });
                  }}
                  onConfirm={() => {
                    this.setState({
                      isAddNewJourneyVisible: false,
                      editingData: null,
                    });
                  }}
                >
                  <ModalInner>
                    <Heading fontSize="20px">Create Course</Heading>
                    <AddProgramCourseModalInner
                      programId={programId}
                      editingData={editingData}
                      onClose={() => {
                        this.setState({
                          isAddNewJourneyVisible: false,
                          editingData: null,
                        });
                      }}
                      onSave={() => {
                        this.setState({
                          isAddNewJourneyVisible: false,
                          editingData: null,
                        });
                      }}
                    />
                  </ModalInner>
                </ConfirmModal>
              </Box>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withApollo(ProgramCoursesList);
