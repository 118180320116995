import React from 'react';
import { Box, Heading } from 'rebass';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';
import JourneysList from './adminMobileCourse/Journeys';
import ScreenFormatList from './adminMobileCourse/ScreenFormats';
import CoursesList from './adminMobileCourse/CoursesList';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;

class MobileCourses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNewCategoryVisible: false,
      isAddNewTrackerVisible: false,
      isAddNewLabelsVisible: false,
    };
  }

  showAddNewCategory = () => {
    this.setState({ isAddNewCategoryVisible: true });
  };

  cancelAddNewCategory = () => {
    this.setState({ isAddNewCategoryVisible: false });
  };

  showAddNewTracker = () => {
    this.setState({ isAddNewTrackerVisible: true });
  };

  cancelAddNewTracker = () => {
    this.setState({ isAddNewTrackerVisible: false });
  };

  showAddNewLabels = () => {
    this.setState({ isAddNewLabelsVisible: true });
  };

  cancelAddNewLabels = () => {
    this.setState({ isAddNewLabelsVisible: false });
  };

  render() {
    const {
      isAddNewTrackerVisible,
    } = this.state;
    console.log('isAddNewTrackerVisible', isAddNewTrackerVisible);
    return (
      <Container>
        <Heading>MOBILE COURSES</Heading>

        <JourneysList />
        <ScreenFormatList />
        <CoursesList />
      </Container>
    );
  }
}

export default MobileCourses;
