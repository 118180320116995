import React from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Flex } from 'rebass';

const HealthCoachesList = ({ data, onEditClick, onDeleteClick }) => {
  const CATEGORIES_COLUMNS = [
    {
      Header: 'display name',
      accessor: 'displayName',
    },
    {
      Header: 'user email',
      accessor: 'email',
    },
    {
      Header: 'helpscout Id',
      accessor: 'helpscoutId',
    },
    {
      Header: 'calendly Id',
      accessor: 'calendlyUserId',
    },
    {
      Header: 'phone',
      accessor: 'phone',
    },
    {
      Header: 'active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div style={{ textAlign: 'center', color: row.original.isActive ? 'green' : 'red' }}>{row.original.isActive ? '✓' : 'x'}</div>;
      },
    },
    {
      Header: 'must complete registration',
      accessor: 'mustCompleteRegistration',
      Cell: (row) => {
        return <div style={{ textAlign: 'center', color: row.original.mustCompleteRegistration ? 'red' : 'green' }}>{row.original.mustCompleteRegistration ? '✓' : 'x'}</div>;
      },
    },
    {
      Header: 'calendly email',
      accessor: 'calendlyUserEmail',
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <Flex flexDirection="row">
              <div title="delete" style={{ cursor: 'pointer' }} onClick={() => onDeleteClick(row.original.id)}>
                🗑
              </div>
              <div title="edit" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => onEditClick(row.original)}>
                ✎
              </div>
            </Flex>
          </Flex>
        );
      },
    },
  ];

  return (
    <div>
      {data &&
        data.adminGetHealthCoachUsersList && (
          <ReactTable
            data={data.adminGetHealthCoachUsersList}
            columns={CATEGORIES_COLUMNS}
            defaultPageSize={data.adminGetHealthCoachUsersList && data.adminGetHealthCoachUsersList.length > 3 ? Math.min(data.adminGetHealthCoachUsersList.length, 25) : 3}
            pageSize={data.adminGetHealthCoachUsersList && data.adminGetHealthCoachUsersList.length > 3 ? data.adminGetHealthCoachUsersList.length : 3}
            showPagination={false}
          />
        )}
    </div>
  );
};

export default HealthCoachesList;
