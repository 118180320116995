import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import Modal, { BaseModalBackground } from 'styled-react-modal';
import Former from '../../components/former/Former';
import FullLayout2 from '../../components/FullLayout';
import { LogoHeader } from '../../components/Logo';
import PleaseWait from '../../components/PleaseWait';
import { GET_FOLLOWUP_SURVEY_ANSWERS, RESET_FOLLOWUP_SURVEY, SAVE_FOLLOWUP_SURVEY_TEMP_DATA } from '../../graphql';
import { FOLLOWUP_SURVEYS_ID } from '../../utils/followupSurveysUtils';
import { sleep } from '../../utils/sleep';
import withSession from '../../lib/withSession';

// export const LOCAL_STORAGE_BASELINE_KEY = 'BaselineSurvey';

const SpecialModalBackground = styled(BaseModalBackground)`
  backdrop-filter: blur(5px);
`;

const Header = styled(Flex)`
  height: 80px;
  max-height: 80px;
  background: #fdf5f2;
  width: 100%;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.08);
  z-index: 10;
  max-width: 100vw;
`;

const HeaderSub = styled(Flex)`
  max-width: 100vw;
  width: 100%;
  padding-left: 0;
  @media screen and (min-width: 52em) {
    padding-left: 30px;
  }
`;

const HeaderContent = styled(Flex)`
  height: 80px;
  max-height: 80px;
  width: 100vw;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

export const getPastBaselingSurveyData = async (client, redirectIfCompletedUrl, history, localStorageKey, surveyName) => {
  if (localStorage.getItem(localStorageKey)) {
    const pastData = JSON.parse(localStorage.getItem(localStorageKey));

    return pastData;
  }

  const { data } = await client.query({
    query: GET_FOLLOWUP_SURVEY_ANSWERS,
    variables: {
      surveyName: surveyName,
    },
  });
  if (data.getFollowUpSurveyAnswers && data.getFollowUpSurveyAnswers.status === 'complete' && redirectIfCompletedUrl) {
    history.push(redirectIfCompletedUrl);
  }
  if (data && data.getFollowUpSurveyAnswers && data.getFollowUpSurveyAnswers.values) {
    const dt = JSON.parse(data.getFollowUpSurveyAnswers.values);

    return dt;
  }
  return [];
};

const FollowUpSurvey = ({ client, history, match, session }) => {
  const [isResume, setIsResume] = useState();
  const [surveyLoaded, setSurveyLoaded] = useState(false);
  const [prechecksDone, setPrechecksDone] = useState(false);
  const [pastData, setPastData] = useState([]);
  const [survey, setSurvey] = useState();

  useEffect(() => {
    const rem = async () => {
      await sleep(500);
      if (window.Beacon) {
        window.Beacon('on', 'ready', () => window.Beacon('destroy'));
      } else {
        await sleep(3000);
        if (window.Beacon) {
          window.Beacon('on', 'ready', () => window.Beacon('destroy'));
        }
      }
      await sleep(5000);
      try {
        window.Beacon('destroy');
      } catch (error) {}
    };
    const surveyId = ((match || {}).params || {}).id;
    if (!surveyId) {
      setPrechecksDone(true);
      setSurveyLoaded(true);
      return;
    }

    const element = FOLLOWUP_SURVEYS_ID.find((x) => x.id.toLowerCase() === surveyId.toLowerCase());
    const filtered = element.questions.filter((x) => {
      if (!!x.mobileUserOnly && !session.isMobileAppUser) return false;

      return true;
    });

    element.questions = filtered;

    setSurvey(element);
    setSurveyLoaded(true);
    rem();
    // checkStoredValues();
  }, []);

  useEffect(
    () => {
      if (surveyLoaded && survey) {
        checkStoredValues();
      }
    },
    [surveyLoaded, survey]
  );

  const checkStoredValues = async () => {
    const past = await getPastBaselingSurveyData(client, `/followup-survey-complete/${match.params.id}`, history, survey.localStorageKey, survey.name);
    // console.log({past})
    if (past && past.length > 0) {
      setPastData(past);
      setIsResume(true);
    } else {
      setIsResume(false);
    }
    setPrechecksDone(true);
  };

  const onCompleted = async (data) => {
    localStorage.setItem(survey.localStorageKey, data);
    await client.mutate({
      mutation: SAVE_FOLLOWUP_SURVEY_TEMP_DATA,
      variables: {
        data: data,
        surveyName: survey.name,
      },
      refetchQueries: [
        {
          query: GET_FOLLOWUP_SURVEY_ANSWERS,
          variables: {
            surveyName: survey.name,
          },
        },
      ],
    });
    history.push(`/followup-survey-complete/${match.params.id}`);
  };

  const initTempData = async (acceptResume) => {
    if (!acceptResume) {
      localStorage.removeItem(survey.localStorageKey);
      await client.mutate({
        mutation: RESET_FOLLOWUP_SURVEY,
        variables: {
          surveyName: survey.name,
        },
        refetchQueries: [
          {
            query: GET_FOLLOWUP_SURVEY_ANSWERS,
            variables: {
              surveyName: survey.name,
            },
          },
        ],
      });
      setPastData([]);
    }
    setIsResume(false);
  };

  const onNext = async (data) => {
    await client.mutate({
      mutation: SAVE_FOLLOWUP_SURVEY_TEMP_DATA,
      variables: {
        data: data,
        surveyName: survey.name,
      },
      refetchQueries: [
        {
          query: GET_FOLLOWUP_SURVEY_ANSWERS,
          variables: {
            surveyName: survey.name,
          },
        },
      ],
    });
  };
  console.log({ prechecksDone, isResume });

  if (!prechecksDone) {
    return (
      <Modal isOpen={true} onBackgroundClick={() => false} onEscapeKeydown={() => false}>
        {/* <SpecialModalBackground> */}
          <Box
            style={{
              margin: '0 auto',
              alignSelf: 'center',
              borderRadius: '20px',
              position: 'relative',
              backgroundColor: 'white',
              padding: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <PleaseWait />
          </Box>
        {/* </SpecialModalBackground> */}
      </Modal>
    );
  }

  if ((!survey || !survey.questions) && (
      <FullLayout2 style={{ zIndex: 1 }}>
        <Flex flex={1} flexDirection={'column'}>
          <Header flex={1}>
            <HeaderSub>
              <HeaderContent>
                <Flex flex={[1, 1, 0]} px="30px" alignItems="center" justifyContent={['center', 'center', 'flex-start']}>
                  <Link to="/">
                    <LogoHeader height="40px" />
                  </Link>
                </Flex>
              </HeaderContent>
            </HeaderSub>
          </Header>
          <Flex justifyContent={'center'} flexDirection="column" alignItems="center" style={{ margin: '0 auto', zIndex: 10, maxWidth: '80vw', width: '420px' }}>
            <Heading mt={4} textAlign={'center'}>
              Oooops
            </Heading>
            <Text mt={3} textAlign="center">
              There seems to be a glitch. This survey doesn't exist
            </Text>
            <Button mt={4} variant={'primary'} onClick={() => history.push(`/`)}>
              Go back to dashboard
            </Button>
          </Flex>
        </Flex>
      </FullLayout2>
    )
  )
    // console.log('IS RESUME', { isResume });
  if (!!isResume) {
    // console.log('IS RESUME');
    return (
      <Modal isOpen={true} onBackgroundClick={() => initTempData(false)} onEscapeKeydown={() => initTempData(false)}>
        <SpecialModalBackground>
          <Box
            style={{
              margin: '0 auto',
              alignSelf: 'center',
              borderRadius: '20px',
              position: 'relative',
              backgroundColor: 'white',
              padding: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              style={{
                maxWidth: '600px',
                width: '100%',
              }}
            >
              <Heading textAlign="center">Resume</Heading>
              <Text mt={3} mb={4} textAlign="center">
                Do you want to resume where you left off?
              </Text>

              <Flex flexDirection="row" justifyContent="center">
                <Button variant="primary" mr={2} onClick={() => initTempData(true)}>
                  Yes
                </Button>
                <Button variant="white" ml={2} onClick={() => initTempData(false)}>
                  No
                </Button>
              </Flex>
            </Box>
          </Box>
        </SpecialModalBackground>
      </Modal>
    );
  } else {
    console.log('PRELAST');
  }
  return <Former questions={survey.questions} getPastData={pastData} localStorageVariable={survey.localStorageKey} pageName={survey.name} onCompleted={onCompleted} onNext={onNext} />;

  // console.log('LAST');
  // return null;
};

export default withSession(withRouter(withApollo(FollowUpSurvey)));
