import React from 'react';
import { Flex, Text } from 'rebass';
import { BigRightHormone, InnerLeftHormone, InnerRightHormone, MediumHormone, SmallHormone } from '../../YearlyReview/icons/InnerRightHormone';
import styled from 'styled-components';
import { CDescription, CText } from '../../baselineSurvey/pages/Components/Texts';

export const ThankYouBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <BigRightHormone
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      />
      <MediumHormone style={{ position: 'absolute', top: 120, right: 150 }} />
      <InnerLeftHormone style={{ position: 'absolute', bottom: 120, left: 0 }} />
      <SmallHormone style={{ position: 'absolute', bottom: 200, right: 0 }} />
    </Flex>
  );
};

export const ThankYou = ({}) => {
  return (
    <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', zIndex: 100, flexDirection: 'column' }}>
      <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', maxWidth: '670px', flexDirection: 'column' }}>
        <CText style={{ fontWeight: 700, textAlign: 'center', color: 'white' }}>Thank you for completing your care check in!</CText>
        <CDescription style={{ color: 'white', textAlign: 'center', fontWeight: 300 }} mt={5}>
          Here is a snapshot of your progress since you started your journey with Paloma Health
        </CDescription>
      </Flex>
    </Flex>
  );
};
