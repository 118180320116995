import React from 'react';
import { Query, withApollo } from 'react-apollo';
import { Button, Text, Flex } from 'rebass';
import styled from 'styled-components';
import { BoxButton } from '../../../components/async/SharedComponents';
import SpinLoader from '../../../components/SpinLoader';
import { GET_HAD_ASYNC_VISITS, SEND_KLAVIYO_STATUS } from '../../../graphql';
import Imge from '../../../static/Kit_Extra_BG_2.png';
import { useMount } from '../../../utils/hooks/lifecycle';

const PinkHeading = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
  margin-top: 36px;
  color: #f6a293;
`;

const BlueHeading = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #354e79;
`;

const LetsTalk = ({ onSubmit, data, client, onSubmitBypass }) => {
  useMount(async () => {
    if (data && data.klaviyoEvent) {
      try {
        await client.mutate({
          mutation: SEND_KLAVIYO_STATUS,
          variables: {
            step: data.klaviyoEvent,
          },
        });
      } catch (error) {}
    }
  });
  return (
    <Query query={GET_HAD_ASYNC_VISITS}>
      {({ loading, data, error }) => {
        if (loading) {
          return (
            <div
              style={{
                marginLeft: 'calc(50% - 25px)',
                marginBottom: '12px',
              }}
            >
              <SpinLoader />
            </div>
          );
        }
        if (
          !data ||
          !data.hadAsyncVisitPast ||
          !data.hadAsyncVisitPast.hadVisits
        ) {
          return (
            <Flex flex={1} flexDirection="column">
              <Flex flex={1} flexDirection="column" p="16px">
                <>
                  <PinkHeading mb={3}>Let's talk about your health</PinkHeading>
                  <BlueHeading mb={3}>Your Health</BlueHeading>
                </>
                <Text mb={4}>
                  Your doctor needs to know about your symptoms and overall
                  health to determine the most appropriate treatment for you.
                  It’s important that you provide accurate information.
                </Text>

                <img
                  src={Imge}
                  alt=""
                  style={{ width: '50%', margin: '0 auto' }}
                />
              </Flex>

              <BoxButton className="visible">
                <Button
                  variant="primary"
                  onClick={onSubmit}
                  style={{
                    marginLeft: '12px',
                    marginRight: '12px',
                  }}
                  width={[1, 1 / 2, 1 / 4]}
                >
                  Continue
                </Button>
              </BoxButton>
            </Flex>
          );
        } else {
          return (
            <Flex flex={1} flexDirection="column">
              <Flex flex={1} flexDirection="column" p="16px">
                <>
                  <PinkHeading mb={3}>Welcome Back!</PinkHeading>
                  <BlueHeading mb={3}>
                    Please answer a few questions so that we can better serve
                    you
                  </BlueHeading>
                </>
                <Text mb={4}>
                  Depending on recent changes on your health, some services
                  might be better suited for your needs. Please answer this
                  short series of questions before scheduling your next visit.
                </Text>
              </Flex>

              <BoxButton className="visible">
                <Button
                  variant="primary"
                  onClick={onSubmit}
                  style={{
                    marginLeft: '12px',
                    marginRight: '12px',
                  }}
                  width={[1, 1 / 2, 1 / 4]}
                >
                  Continue
                </Button>
              </BoxButton>
            </Flex>
          );
        }
      }}
    </Query>
  );
};

export default withApollo(LetsTalk);
