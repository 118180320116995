import React, { Fragment, useState } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import FullLayout from '../../../components/FullLayout';
import { Mutation } from 'react-apollo';
import ConfirmModal from '../../../components/ConfirmModal';
import styled from 'styled-components';
import { Input } from '../../../components/TextInput';
import { WebView } from './WebView';
import { ADMIN_CHANGE_MOBILE_USER_BOOLEAN_FLAG_VALUE, ADMIN_CHANGE_MOBILE_USER_STRING_VALUE } from '../../../graphql';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

export const MobileView = ({ data, refetch, toastManager, isEditable }) => {
  const EDIT_MODE_FEATURE_ACTIVE = isEditable;
  const [showChangeBooleanFlag, setShowChangeBooleanFlag] = useState(null);
  const [isShowWeb, setIsShowWeb] = useState(false);
  const [showChangeStringValue, setShowChangeStringValue] = useState(null);
  const [editInputValue, setEditInputValue] = useState(null);

  const handleCloseModalChangeStringValue = () => {
    setShowChangeStringValue(null);
    setEditInputValue(null);
  };

  const handleShowModalChangeStringValue = (value) => {
    setShowChangeStringValue(value);
    setEditInputValue((value || {}).currentValue);
  };

  const handleCloseModalChangeBooleanFlag = () => {
    setShowChangeBooleanFlag(null);
  };

  const validateEmailProps = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  if (!!showChangeStringValue) {
    const { flagName, name, description, currentValue, onNull, isEmail, validate, warningText } = showChangeStringValue;
    return (
      <Mutation
        mutation={ADMIN_CHANGE_MOBILE_USER_STRING_VALUE}
        variables={{
          id: (data || {}).id,
          flagName: flagName,
          value: editInputValue,
        }}
        onCompleted={async (res) => {
          await refetch();
          handleCloseModalChangeStringValue();
          if (!!res && res.adminChangeMobileUserStringValue) {
            if (!!res.adminChangeMobileUserStringValue.ok) {
              toastManager.add('Update successful', {
                appearance: 'success',
              });
            } else {
              toastManager.add(res.adminChangeMobileUserStringValue.customError || 'An error occured', {
                appearance: 'error',
              });
            }
          } else {
            toastManager.add('An error occured', {
              appearance: 'error',
            });
          }
        }}
      >
        {(mutate, { loading }) => (
          <Fragment>
            <ConfirmModal
              isOpen={true}
              onClose={handleCloseModalChangeStringValue}
              onBackgroundClick={handleCloseModalChangeStringValue}
              onEscapeKeydown={handleCloseModalChangeStringValue}
              childrenManaged
            >
              <ModalInner>
                <Heading mb={4} textAlign="center">
                  Change {name}
                </Heading>

                {!!warningText && (
                  <Box border="1px solid #F6A293" style={{ borderRadius: '6px', backgroundColor: '#FDF5F2' }} p={3} mb={4}>
                    <Text>{warningText}</Text>
                  </Box>
                )}

                <Text textAlign="center" mb={3}>
                  {description}
                </Text>
                <Text textAlign="center" mb={4}>
                  Current Value: <span style={{ fontWeight: 500 }}>{currentValue}</span>
                </Text>

                <Input
                  value={editInputValue}
                  onChange={(r) => {
                    setEditInputValue(r.target.value);
                  }}
                />

                <Flex justifyContent={'center'} mt={4}>
                  <Button
                    disabled={!!loading}
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={handleCloseModalChangeStringValue}
                  >
                    Cancel
                  </Button>
                  <Button
                    ml={4}
                    disabled={!!loading || (!!isEmail && !validateEmailProps(editInputValue)) || (!!validate && !validate(editInputValue))}
                    style={{ background: '#F6A293' }}
                    onClick={() => {
                      mutate();
                    }}
                  >
                    Change
                  </Button>
                </Flex>
              </ModalInner>
            </ConfirmModal>
            <FullLayout />
          </Fragment>
        )}
      </Mutation>
    );
  }
  if (!!showChangeBooleanFlag) {
    const { flagName, name, description, currentValue, onNull } = showChangeBooleanFlag;
    return (
      <Mutation
        mutation={ADMIN_CHANGE_MOBILE_USER_BOOLEAN_FLAG_VALUE}
        variables={{
          id: (data || {}).id,
          flagName: flagName,
          value: !currentValue ? true : false,
        }}
        onCompleted={async () => {
          await refetch();
          handleCloseModalChangeBooleanFlag();
        }}
      >
        {(mutate, { loading }) => (
          <Fragment>
            <ConfirmModal
              isOpen={true}
              onClose={handleCloseModalChangeBooleanFlag}
              onBackgroundClick={handleCloseModalChangeBooleanFlag}
              onEscapeKeydown={handleCloseModalChangeBooleanFlag}
              childrenManaged
            >
              <ModalInner>
                <Heading mb={4} textAlign="center">
                  Change {name}
                </Heading>

                <Text fontSize={3} textAlign="center" mb={3}>
                  {description}
                </Text>
                <Text fontSize={3} textAlign="center" mb={4}>
                  change from <span style={{ fontWeight: 500 }}>{currentValue === null ? onNull : currentValue === true ? 'Yes' : 'No'}</span> to{' '}
                  <span style={{ fontWeight: 500 }}>{!currentValue ? 'Yes' : 'No'}</span>
                </Text>

                <Flex justifyContent={'center'}>
                  <Button
                    disabled={!!loading}
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={handleCloseModalChangeBooleanFlag}
                  >
                    Cancel
                  </Button>
                  <Button
                    ml={4}
                    disabled={!!loading}
                    style={{ background: '#F6A293' }}
                    onClick={() => {
                      mutate();
                    }}
                  >
                    Change
                  </Button>
                </Flex>
              </ModalInner>
            </ConfirmModal>
            <FullLayout />
          </Fragment>
        )}
      </Mutation>
    );
  }
  return (
    <Flex flexDirection={'column'} style={{ textAlign: 'left' }}>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>id:</Text>
          <Text fontWeight={500}>{(data || {}).id}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>email:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).email}
            </Text>
            {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change flag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleShowModalChangeStringValue({
                    flagName: 'email',
                    name: 'Email',
                    description: "Change the patient's email",
                    currentValue: (data || {}).email,
                    onNull: '',
                    isEmail: true,
                    warningText: 'This will update all credential emails both web and mobile if any',
                  });
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>first name:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).firstName}
            </Text>
            {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change flag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleShowModalChangeStringValue({
                    flagName: 'firstName',
                    name: 'First Name',
                    description: "Change the patient's first name",
                    currentValue: (data || {}).firstName,
                    onNull: '',
                    validate: (val) => {
                      if (!val) return false;
                      return val.length > 1;
                    },
                  });
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )}
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>last name:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).lastName}
            </Text>
            {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change flag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleShowModalChangeStringValue({
                    flagName: 'lastName',
                    name: 'Last Name',
                    description: "Change the patient's last name",
                    currentValue: (data || {}).lastName,
                    onNull: '',
                    validate: (val) => {
                      if (!val) return false;
                      return val.length > 1;
                    },
                  });
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>birthYear:</Text>
          <Text fontWeight={500}>{(data || {}).birthYear}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>was Diagnosed Hypothyroidism:</Text>
          <Text fontWeight={500}>
            {(data || {}).wasDiagnosedHypothyroidism === null || (data || {}).wasDiagnosedHypothyroidism === undefined ? '' : (data || {}).wasDiagnosedHypothyroidism ? 'yes' : 'no'}
          </Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>was Diagnosed Hashimoto:</Text>
          <Text fontWeight={500}>{(data || {}).wasDiagnosedHashimoto === null || (data || {}).wasDiagnosedHashimoto === undefined ? '' : (data || {}).wasDiagnosedHashimoto ? 'yes' : 'no'}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>created at:</Text>
          <Text fontWeight={500}>{(data || {}).createdAt}</Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>recovery Uid:</Text>
          <Text fontWeight={500}>{(data || {}).recoveryUid}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>is Active:</Text>
          <Text fontWeight={500}>{(data || {}).isActive ? 'yes' : 'no'}</Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>account locked:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).accountLocked ? 'yes' : 'no'}
            </Text>
            {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change flag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowChangeBooleanFlag({
                    flagName: 'accountLocked',
                    name: 'Account Locked',
                    description: "Change the flag value. If set to true, the user's mobile account will be locked",
                    currentValue: (data || {}).accountLocked,
                    onNull: 'No',
                  });
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>must Confirm Email:</Text>
          <Text fontWeight={500}>{(data || {}).mustConfirmEmail ? 'yes' : 'no'}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>must reset password:</Text>
          <Text fontWeight={500}>{(data || {}).mustResetPassword ? 'yes' : 'no'}</Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>must complete profile:</Text>
          <Text fontWeight={500}>{(data || {}).mustCompleteProfile ? 'yes' : 'no'}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>state:</Text>
          <Text fontWeight={500}>{(data || {}).state}</Text>
        </Flex>
      </Flex>
      {data.webUser ? (
        <Flex flexDirection={'column'} pl={3} style={{ borderLeftColor: 'blue', borderWidth: '2px' }} mt={2}>
          <Text fontWeight={500} mb={3} />
          <Flex
            onClick={() => {
              setIsShowWeb(!isShowWeb);
            }}
          >
            <Text fontWeight={500} mb={3} flex={1}>
              Web User Associated
            </Text>
            <FontAwesomeIcon size="1x" color="black" icon={isShowWeb ? faChevronUp : faChevronRight} />
          </Flex>
          {!!isShowWeb && <WebView data={data.webUser} refetch={refetch} toastManager={toastManager} isEditable={isEditable} />}
        </Flex>
      ) : (
        <Text>No Web User Associated</Text>
      )}
    </Flex>
  );
};