import React, { useRef, useState } from 'react';
import { withApollo } from 'react-apollo';
import Select, { Async, components } from 'react-select';
import { Icon } from '@iconify/react';
import magnifier from '@iconify/icons-entypo/magnifying-glass';
import { SEARCH_ATHENA_ALLERGY, SEARCH_ATHENA_MEDICATION } from '../../graphql';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    height: '64px',
    borderRadius: '10px',
    border: `${state.focused ? '3' : '1'}px solid #44a5ff`,
  }),
};
const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <span>
        <Icon
          icon={magnifier}
          style={{
            color: 'rgb(53, 78, 121)',
            fontSize: '24px',
            alignSelf: 'center',
            marginLeft: '6px',
            opacity: 0.7,
          }}
        />
      </span>
      {children}
    </components.Control>
  );
};

const AllergySelector = ({ onChange, client, initialValue = '' }) => {
  const asyncSelect = useRef();
  const [inputValue, setInputValue] = useState(initialValue);
  const [isFetching, setIsFetching] = useState(false);
  const [val, setVal] = useState(null);
  const handleInputChange = newValue => {
    const inputValue = newValue; //.replace(/\W/g, '');
    setInputValue(inputValue);
    return inputValue;
  };
  const loadOptions = async (inputValue, callback) => {
    if (!inputValue || inputValue.length < 2) {
      callback(null);
      return;
    }
    setIsFetching(true);

    const meds = await client.query({
      query: SEARCH_ATHENA_ALLERGY,
      variables: {
        search: inputValue,
      },
    });
    
    if(!meds || !meds.data || !meds.data.searchAthenaAllergies) {
        callback([])
    }
    callback(
        meds.data.searchAthenaAllergies.map(x => {
        return {
          label: x.allergy,
          value: x.allergyId,
        };
      })
    );
    setIsFetching(false);
  };

  return (
    <Async
      ref={asyncSelect}
      styles={customStyles}
      cacheOptions
      isClearable
      loadOptions={loadOptions}
      components={{ Control }}
      defaultOptions
      value={val}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      noOptionsMessage={() =>
        inputValue
          ? inputValue.length > 2
            ? inputValue
            : 'Minimum 2 characters to search'
          : 'Minimum 2 characters to search'
      }
      onChange={option => {
        onChange(option);
        try {
          asyncSelect.current.value = '';
        } catch (error) {}
      }}
    />
  );
};

export default withApollo(AllergySelector);
