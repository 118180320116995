import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'rebass';
import { Field } from 'formik';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  CardElement,
} from 'react-stripe-elements';
import { getCardTypeByValue } from './creditCardInput/utils/cardTypes';

const StyledInput = styled(Box)`
  flex: 1 0 auto;
  background: ${props =>
    props.disabled ? props.theme.bg.wash : props.theme.bg.default};
  font-weight: 400;
  font-size: 16px;
  border: 1px solid
    ${props =>
      props.disabled ? props.theme.bg.border : props.theme.bg.inactive};
  border-radius: 10px;
  padding: 18px 12px;
  box-shadow: none;
  ${props =>
    props.type === 'checkbox' &&
    css`
      flex: initial;
      width: initial;
      margin-right: 0.5em;
    `} &::placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &::-webkit-input-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:-moz-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:-ms-input-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:focus {
    border-color: ${props => props.theme.brand.default};
    outline: none;
  }
  &[type='file'] {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: hidden;
  }
`;

const Input = props => {
  const { field, form, type, handleReady } = props;
  const formInput = React.createRef();

  const handleChange = (name, evt) => {
    if (evt.error) {
      form.setFieldError(name, evt.error.message);
    } else if (evt.complete) {
      form.setFieldValue(name, 'Complete');
    }
  };

  return (
    <StyledInput
      onClick={() => {
        if (formInput) {
          const input = formInput.current;
          if (input) {
            formInput.current._element.focus();
          }
        }
      }}
    >
      {type === 'number' && (
        <CardNumberElement
          ref={formInput}
          placeholder="Card Number"
          onReady={element =>
            handleReady ? handleReady('_cardNumber', element) : null
          }
          onBlur={() => form.setFieldTouched(field.name, true)}
          onChange={evt => handleChange(field.name, evt)}
        />
      )}

      {type === 'expire' && (
        <CardExpiryElement
          ref={formInput}
          onReady={element =>
            handleReady ? handleReady('_cardExpire', element) : null
          }
          onBlur={() => form.setFieldTouched(field.name, true)}
          onChange={evt => handleChange(field.name, evt)}
        />
      )}

      {type === 'cvc' && (
        <CardCVCElement
          ref={formInput}
          onReady={element =>
            handleReady ? handleReady('_cardCVC', element) : null
          }
          onBlur={() => form.setFieldTouched(field.name, true)}
          onChange={evt => handleChange(field.name, evt)}
        />
      )}

      {type === 'zip' && (
        <PostalCodeElement
          ref={formInput}
          placeholder="Zip Code"
          onReady={element =>
            handleReady ? handleReady('_postal', element) : null
          }
          onBlur={() => form.setFieldTouched(field.name, true)}
          onChange={evt => handleChange(field.name, evt)}
        />
      )}

      {type === 'cardelement' && (
        <CardElement
          ref={formInput}
          onReady={element =>
            handleReady ? handleReady('_cardelement', element) : null
          }
          onBlur={() => form.setFieldTouched(field.name, true)}
          onChange={evt => handleChange(field.name, evt)}
        />
      )}
    </StyledInput>
  );
};

export const StripeInput = props => {
  return <Field {...props} component={Input} />;
};

export default StripeInput;
