import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { Draggable, Droppable } from 'react-beautiful-dnd';

const Container = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding: 20px 16px 40px 16px;
  min-height: 80vh;
  max-height: 95vh;
  overflow-y: auto;
  @media screen and (max-width: 52em) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const Toolbox = ({ availables }) => {
  if (!availables) return null;
  const [aa, setAA] = useState(availables);
  useEffect(
    () => {
      console.log('ue11');
      setAA(availables);
    },
    [availables]
  );
  return (
    <Flex flex={1}>
      <Droppable droppableId="availables">
        {(provided2, snapshot) => {
          return (
            <div
              ref={provided2.innerRef}
              {...provided2.droppableProps}
              style={{ minHeight: '100%', width: '100%', padding: '12px', border: snapshot.isDraggingOver ? '2px dashed rgb(227, 117, 117)' : '2px solid rgba(255,255,255,0)' }}
            >
              {availables
                .sort((a, b) => {
                  if (a.order < b.order) return -1;
                  return 1;
                })
                .map((x, i) => {
                  return (
                    <Draggable key={`avail${x.id}`} draggableId={`avail${x.id}`} index={i}>
                      {(provided, snapshot) => {
                        const opacity = snapshot.isDragging ? 0.5 : 1;
                        const backgroundColor = snapshot.isDragging ? 'lightgray' : 'white';
                        return (
                          <>
                            <div key={x.name} ref={provided.innerRef} isDragging={snapshot.isDragging} {...provided.dragHandleProps} {...provided.draggableProps}>
                              <Flex flexDirection={'row'} mb={2}>
                                <Box
                                  style={{
                                    marginLeft: `8px`,
                                    background: 'white',
                                    border: `1px dashed #717171`,
                                    padding: '8px 12px',
                                    borderRadius: '4px',
                                    width: '100%',
                                  }}
                                >
                                  <Flex flexDirection={'row'} alignItems={'center'}>
                                    <i className={x.icon} />
                                    <Flex flexDirection={'column'} ml={2}>
                                      <Text>{x.name}</Text>
                                    </Flex>
                                  </Flex>
                                </Box>
                              </Flex>
                            </div>
                            {snapshot.isDragging && (
                              <Flex flexDirection={'row'} mb={2}>
                                <Box
                                  style={{
                                    marginLeft: `8px`,
                                    background: 'white',
                                    border: `1px solid #717171`,
                                    padding: '8px 12px',
                                    borderRadius: '4px',
                                    width: '100%',
                                  }}
                                >
                                  <Flex flexDirection={'column'}>
                                    <Flex flexDirection={'column'}>
                                      <Text>{x.name}</Text>
                                    </Flex>
                                  </Flex>
                                </Box>
                              </Flex>
                            )}
                          </>
                        );
                      }}
                    </Draggable>
                  );
                })}
            </div>
          );
        }}
      </Droppable>
    </Flex>
  );
};
