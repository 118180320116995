import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'rebass';
import { Field } from 'formik';

const InputContainer = styled(Flex)`
  flex-direction: column;
  position: relative;
  opacity: ${({ isDisabled }) => (!!isDisabled ? 0.75 : 1)};
  & > label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 19px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #6f81a5;
    font-size: 16px;
    line-height: 1;
    left: 16px;
  }

  .filled {
    transform: translate(-3px, 6px) scale(0.8);
    /* padding-top: 20px; */
  }
  .filled2 {
    margin-top: 20px;
  }

  &:focus-within > label {
    transform: translate(-3px, 6px) scale(0.8);
    color: #425d93;
  }
`;

export const StyledInputArea = styled.textarea`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1 0 auto;
  background: ${(props) => (props.disabled ? props.theme.bg.wash : props.theme.bg.default)};
  font-weight: 400;
  font-size: 16px;
  border: 1px solid
    ${(props) => (props.disabled ? props.theme.bg.border : props.isAsyncMode ? '#d7d7d7' : props.theme.bg.inactive)};
  border-radius: 10px;
  padding: 18px 12px;
  box-shadow: none;
  transition: border 300ms ease-in-out;

  .filled2 {
    margin-top: 20px;
  }
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:-moz-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:-ms-input-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:focus {
    border-color: ${(props) => (props.isAsyncMode ? '#44a5ff' : props.theme.brand.default)};
    outline: none;
  }
  /* &[type='checkbox']:checked {
    background: #344f79;
    color: white;
  }
  &[type='checkbox']:checked + label:after {
    content: '✔';
    display: inline-block;
    font-size: 1.6em;
  } */
  &[type='file'] {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: hidden;
  }
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  &[type='checkbox'] {
    display: none;
  }
  &[type='checkbox'] + label {
    display: flex;
    border: 1px solid #000;
    width: 18px;
    height: 18px;
    position: relative;
  }
  &[type='checkbox']:checked + label {
    background: #344f79;
  }
  &[type='checkbox']:checked + label:after {
    content: '✔';
    color: white;
    /* display: inline-block; */
    margin-top: -4px;
    margin-left: 2px;
  }

  /* &[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    margin-right: 10px;
    border-radius: 6px;
    outline: 0;
    background: #ccc;
} */

  /* ${(props) =>
    props.type === 'checkbox' &&
    css`
      flex: initial;
      width: initial;
      margin-right: 0.5em;
      padding: 0;
      min-width: 18px;
      width: 18px;
      height: 18px;
      border-radius: 2px;
    `} &::placeholder {
    color: ${(props) => props.theme.text.placeholder};
  } */
`;

export const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1 0 auto;
  background: ${(props) => (props.disabled ? props.theme.bg.wash : props.theme.bg.default)};
  font-weight: 400;
  font-size: 16px;
  border: 1px solid
    ${(props) => (props.disabled ? props.theme.bg.border : props.isAsyncMode ? '#d7d7d7' : props.theme.bg.inactive)};
  border-radius: 10px;
  padding: 18px 12px;
  box-shadow: none;
  transition: border 300ms ease-in-out;
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:-moz-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:-ms-input-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:focus {
    border-color: ${(props) => (props.isAsyncMode ? '#44a5ff' : props.theme.brand.default)};
    outline: none;
  }
  /* &[type='checkbox']:checked {
    background: #344f79;
    color: white;
  }
  &[type='checkbox']:checked + label:after {
    content: '✔';
    display: inline-block;
    font-size: 1.6em;
  } */
  &[type='file'] {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: hidden;
  }
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  &[type='checkbox'] {
    display: none;
  }
  &[type='checkbox'] + label {
    display: flex;
    border: 1px solid #000;
    width: 18px;
    height: 18px;
    position: relative;
  }
  &[type='checkbox']:checked + label {
    background: #344f79;
  }
  &[type='checkbox']:checked + label:after {
    content: '✔';
    color: white;
    /* display: inline-block; */
    margin-top: -4px;
    margin-left: 2px;
  }

  /* &[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    margin-right: 10px;
    border-radius: 6px;
    outline: 0;
    background: #ccc;
} */

  /* ${(props) =>
    props.type === 'checkbox' &&
    css`
      flex: initial;
      width: initial;
      margin-right: 0.5em;
      padding: 0;
      min-width: 18px;
      width: 18px;
      height: 18px;
      border-radius: 2px;
    `} &::placeholder {
    color: ${(props) => props.theme.text.placeholder};
  } */
`;

export const Input = (props) => {
  if (props.type && props.type === 'checkbox') {
    return <InputCheckbox {...props} />;
  }
  return (
    <Flex flex={1} style={props.containerStyle}>
      <StyledInput
        {...props.field}
        {...props}
        ref={props.subref}
        onKeyDown={({ key }) => {
          if (key === 'Enter' && props.form && !props.multiline && !!props.form.isValid) {
            try {
              props.form.submitForm();
            } catch (error) {}
          } else {
            if (key === 'Enter' && props.onEnter) {
              props.onEnter();
            }
          }
          // key === 'Enter' ? props.form.submitForm() : null
        }}
      />
    </Flex>
  );
};

export const InputWithLabel = (props) => {
  const [value, setValue] = useState(props.value || (props.field || {}).value);

  function handleChange(e) {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
    if ((props.field || {}).onChange) {
      (props.field || {}).onChange(e);
    }
  }

  return (
    <InputContainer isDisabled={props.disabled}>
      <StyledInput
        {...props.field}
        {...props}
        placeholder=""
        ref={props.subref}
        onChange={handleChange}
        onKeyDown={({ key }) => {
          if (key === 'Enter' && props.form && !props.multiline && !!props.form.isValid) {
            try {
              props.form.submitForm();
            } catch (error) {}
          }
          // key === 'Enter' ? props.form.submitForm() : null
        }}
      />
      <label className={!!value || value === 0 ? 'filled' : ''} htmlFor={props.id}>
        {props.placeholder}
      </label>
    </InputContainer>
  );
};

export const TextAreaWithLabel = (props) => {
  const [value, setValue] = useState(props.value || (props.field || {}).value);

  function handleChange(e) {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
    if ((props.field || {}).onChange) {
      (props.field || {}).onChange(e);
    }
  }

  return (
    <InputContainer isDisabled={props.disabled}>
      <StyledInputArea
        {...props.field}
        {...props}
        className={!!value || value === 0 ? 'filled2' : ''}
        placeholder=""
        ref={props.subref}
        onChange={handleChange}
        onKeyDown={({ key }) => {
          if (key === 'Enter' && props.form && !props.multiline && !!props.form.isValid) {
            try {
              props.form.submitForm();
            } catch (error) {}
          }
          // key === 'Enter' ? props.form.submitForm() : null
        }}
      />
      <label className={!!value || value === 0 ? 'filled' : ''} htmlFor={props.id}>
        {props.placeholder}
      </label>
    </InputContainer>
  );
};

export const SearchInput = (props) => {
  return (
    <Flex flexDirection={'row'} style={{ background: 'white', borderRadius: '12px', padding: '0 6px 0 12px', height: '50px', cursor: 'pointer' }} alignItems="center">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: '' }}>
        <path
          d="M21 21L16.514 16.506L21 21ZM19 10.5C19 12.7543 18.1045 14.9163 16.5104 16.5104C14.9163 18.1045 12.7543 19 10.5 19C8.24566 19 6.08365 18.1045 4.48959 16.5104C2.89553 14.9163 2 12.7543 2 10.5C2 8.24566 2.89553 6.08365 4.48959 4.48959C6.08365 2.89553 8.24566 2 10.5 2C12.7543 2 14.9163 2.89553 16.5104 4.48959C18.1045 6.08365 19 8.24566 19 10.5V10.5Z"
          stroke="#344F7A"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
      <Input style={{ backgroundColor: 'white', border: '0px', flex: 1, paddingTop: '12px', paddingBottom: '12px' }} containerStyle={{ width: '100%' }} {...props} />
    </Flex>
  );
};

const InputCheckbox = (props) => {
  return (
    <div>
      <StyledInput
        {...props.field}
        {...props}
        onKeyDown={({ key }) => {
          if (key === 'Enter' && props.form && !!props.form.isValid) {
            try {
              props.form.submitForm();
            } catch (error) {}
          }
          // key === 'Enter' ? props.form.submitForm() : null
        }}
      />
      {props.id && props.type && props.type === 'checkbox' ? <label for={props.id} >{(props || {}).enforceLabel}</label> : null}
    </div>
  );
};

export const TextInput = (props) => {
  return <Field {...props} component={Input} />;
};

export const TextInputWithLabel = (props) => {
  return <Field {...props} component={InputWithLabel} />;
};

export default TextInput;
