import React from 'react';
import { Box, Flex, Text, Button } from 'rebass';
import styled from 'styled-components';
import { Route, NavLink as NavLinkRouter, withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import moment from 'moment';

import { LABS, IS_DIAGNOSED } from '../graphql';
import SpinLoader from '../components/SpinLoader';
import LabDetail from './LabDetail';
import Thyroid from '../components/icons/Thyroid';
import EmptyState from './labs/emptyState';

const ListContainer = styled(Flex)`
  min-width: 300px;
  border-right: 1px solid #ccc;
  width: 100%;
  /* overflow-y: auto; */
  &.selected {
    display: none;
  }

  @media screen and (min-width: 52em) {
    width: auto;
    &.selected {
      display: flex;
    }
  }
`;

const DetailContainer = styled(Flex)`
  display: none;
  overflow-y: auto;
  &.selected {
    display: flex;
  }

  @media screen and (min-width: 52em) {
    display: flex;
  }
`;

const Inner = styled(Flex)`
  max-width: 750px;
`;

const NavLink = styled(NavLinkRouter)`
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100px;
  transition: all 300ms ease;
  &.active {
    background: rgba(180, 205, 244, 0.5);
  }
  &:hover {
    background: rgba(180, 205, 244, 0.5);
  }
`;

const ListItem = styled.div`
  border-bottom: 1px solid #ccc;
`;

const IconContainer = styled(Box)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  background: #fff;
`;

const BackButton = styled.div`
  background: rgba(180, 205, 244, 0.5);
  width: 100%;
  color: #333;
  padding: 6px 12px;
  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const LabsWrapper = props => (
  <Query query={IS_DIAGNOSED} fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <Flex flex={1} justifyContent="center" alignItems="center">
            <SpinLoader />
          </Flex>
        );
      }
      return <Labs {...props} isDiagnosed={data.isDiagnosed} />;
    }}
  </Query>
)

const Labs = ({isDiagnosed, ...props}) => (
  <Query query={LABS} fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <Flex flex={1} justifyContent="center" alignItems="center">
            <SpinLoader />
          </Flex>
        );
      }

      if (error) {
        return (
          <Flex flex={1} justifyContent="center" alignItems="center">
            <Text fontSize={5} fontWeight={600} textAlign="center">
              Unable to retrieve lab tests. Please try again.
            </Text>
          </Flex>
        );
      }

      const { labs } = data;

      if (!labs || labs.length === 0) {
        return <EmptyState {...props} />;
      }

      const selected = !props.match.isExact ? 'selected' : null;

      return (
        <Flex flex={1} style={{ overflowY: 'hidden' }}>
          <ListContainer className={selected} flexDirection="column">
            <div style={{ width: '100%' }}>
              <Button
                variant="primary"
                height="48px"
                style={{ margin: '12px', width: 'calc(100% - 24px)' }}
                onClick={() => {
                  props.history.push('/uploadLabResults');
                }}
              >
                Upload Lab Results
              </Button>
            </div>
            <Flex
              flex={1}
              flexDirection={'column'}
              style={{ overflow: 'auto' }}
            >
              {labs
                .sort((a, b) => {
                  const dateA = a.resultedAt ||  a.created;
                  const dateB = b.resultedAt || b.created;

                  if (dateA < dateB) return 1;
                  if (dateA > dateB) return -1;

                  return 0;
                })
                .map(x => {
                  
                let title = `Thyroid Test`
                if(x && x.biomarkers && x.biomarkers.length>0) {
                  if(x.biomarkers.indexOf('rT3')>-1) {
                    title+=` + rT3`
                  }
                  if(x.biomarkers.indexOf('Vit. D')>-1) {
                    title+=` + Vit. D`
                  }
                }
                  return(
                  <ListItem key={x.id}>
                    <NavLink to={`/labs/${x.id}`}>
                      <Flex p={3} alignItems="center">
                        <Flex mr={3}>
                          <IconContainer>
                            <Thyroid width={60} />
                          </IconContainer>
                        </Flex>
                        <Flex flexDirection="column">
                          <Text mb={1} fontSize={'16px'} color="#344f79">
                            {title}
                          </Text>
                          <Text fontSize={'14px'} color="grey">
                            {!!x.resultedAt?`${moment(x.resultedAt).format('ddd MMM Do YYYY')}`:`${moment(x.created).format('ddd MMM Do YYYY')}`}
                          </Text>
                        </Flex>
                      </Flex>
                    </NavLink>
                  </ListItem>
                )})}
            </Flex>
          </ListContainer>
          <DetailContainer
            flex={1}
            justifyContent="center"
            className={selected}
          >
            <Inner flex={1} flexDirection="column" pt={[0, 0, 3]}>
              <div>
                <BackButton onClick={() => props.history.goBack()}>
                  {'<  Your Lab Results'}
                </BackButton>
              </div>
              <Route
                path="/labs/:id"
                component={props => <LabDetail labs={labs} {...props} isDiagnosed={isDiagnosed} />}
              />
            </Inner>
          </DetailContainer>
        </Flex>
      );
    }}
  </Query>
);

export default withRouter(LabsWrapper);
