import React, { useState } from 'react';
import { Section } from './components/Section';
import subscriptionsPaloma from '../../static/subscriptionsPaloma.png';
import ConsultsContent from './editing/ConsultsContent';
import { Query } from 'react-apollo';
import { GET_MEMBERSHIPS } from '../../graphql';
import { withToastManager } from 'react-toast-notifications';

const DC = ({toastManager}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Query query={GET_MEMBERSHIPS}>
      {({ data, loading, error }) => {
        if (loading || !data.getMemberships|| !data.getMemberships.memberships) return null;

        const membershipData = data.getMemberships.memberships.find(
          x => x.subscriptionTarget === 'SUBSCRIPTION_MEMBERSHIP'
        );

        if(!membershipData) return null

        return (
          <Section
            card={membershipData.card}
            title="Membership"
            icon={subscriptionsPaloma}
            isExpanded={isExpanded}
            setIsExpanded={()=>{

            }}
            data={membershipData}
            isMembership={true}
          >
            {/* <ConsultsContent
              onSubmit={() => {
                setIsExpanded(false);
                toastManager.add('Card updated.', {
                  appearance: 'success',
                });
              }}
            /> */}
          </Section>
        );
      }}
    </Query>
  );
};

export const Membership = withToastManager(DC)