import React from 'react';
import { Flex, Text } from 'rebass';
import { GraphPink } from '../../YearlyReview/icons/GraphPink';
import { InnerRightHormone } from '../../YearlyReview/icons/InnerRightHormone';
import Odometer from '../../YearlyReview/components/RollingNumbers';
import styled from 'styled-components';
import { CText, CTextWithCount } from '../../baselineSurvey/pages/Components/Texts';


export const GlobalImprovementsBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <InnerRightHormone
        style={{
          position: 'absolute',
          right: 0,
          bottom: 120,
        }}
      />
      <GraphPink width={700} style={{ position: 'absolute', top: 80, left: 0 }} />
    </Flex>
  );
};

export const GlobalImprovements = () => {
  return (
    <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', zIndex: 100 }}>
      <CTextWithCount style={{ fontWeight: 700, textAlign: 'center' }}>
        <span>
          <Odometer delay={0} startNumber={0} to={95} style={{ color: '#F8A294', fontSize: window.matchMedia('(min-width: 52em)').matches ? 70 : 42, fontWeight: 'bold', width: '100%' }} />
        </span>
        <span style={{ color: '#F8A294' }}>%</span> of our patients reported improvement in at least 2 thyroid-related symptoms in their first{' '}
        <span>
          <Odometer delay={0} startNumber={0} to={180} style={{ color: '#F8A294', fontSize: window.matchMedia('(min-width: 52em)').matches ? 70 : 42, fontWeight: 'bold', width: '100%' }} />
        </span>{' '}
        days
      </CTextWithCount>
    </Flex>
  );
};
