import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { Formik, Form } from 'formik';
import { FormField } from '../components/FormField';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';
import gql from 'graphql-tag';
import { MaskInput } from '../components/async/SharedComponents';
import TextInput from '../components/TextInput';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;

const MUTATION = gql`
  mutation runPr($pr: String!) {
    runPr(pr: $pr) {
      ok
    }
  }
`;

const AdminRunPr = (props) => {
  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      const res = await props.client.mutate({
        mutation: MUTATION,
        variables: {
          pr: values.pr,
        },
      });
      console.log('res', res);

      if (res && res.data && res.data.runPr && res.data.runPr.ok) {
        props.toastManager.add('Process completed', {
          appearance: 'success',
        });
        actions.setSubmitting(false);
      } else {
        actions.setSubmitting(false);
        // console.log(re)
        props.toastManager.add('Error generating, please try again', {
          appearance: 'error',
        });
      }

      actions.setSubmitting(false);
      //   toastManager.add('Error submitting intake form', { appearance: 'error' });
    } catch (err) {
      actions.setSubmitting(false);
      props.toastManager.add('Error generating, please try again', {
        appearance: 'error',
      });
    }
  };

  return (
    <Container>
      <Flex m={[3, 5]} flex={1} flexDirection="column">
        <Heading mb={4} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
          Run Process
        </Heading>
        <Card mb={4} p={[3, 4]}>
          <Flex flexDirection={'column'} flex={1} alignItems="center">
            <Formik
              initialValues={{ pr: '' }}
              // validationSchema={schema}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
              render={({ errors, touched, isValid, isSubmitting, handleChange, handleBlur, values }) => (
                <Form style={{ width: '300px' }}>
                  <Flex flexDirection="column" flex={1}>
                    <Heading mb={4}>Process</Heading>
                  </Flex>
                  <FormField mb={4} error={touched.pr && errors.pr}>
                    <TextInput style={{textAlign:'center'}} name="pr" placeholder="Process name" />
                  </FormField>

                  <Button mt={4} mb={4} variant="primary" width={1 / 2} disabled={isSubmitting} type="submit">
                    Run
                  </Button>
                </Form>
              )}
            />
          </Flex>
        </Card>
      </Flex>
    </Container>
  );
};

export default compose(
  withApollo,
  withToastManager
)(AdminRunPr);
