import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { APPOINTMENTS, APPT_SUMMARY, PRESCRIPTION } from '../../graphql';
import SpinLoader from '../../components/SpinLoader';
import Communicator from './Communicator';
import { measureHeight } from 'react-div-100vh';

const DetailNavigation = styled.div`
  width: 100%;
  position: fixed;
  flex: 1;
  margin-bottom: 36px;
  z-index: 1;
`;

const BackButton = styled.div`
  background: rgba(180, 205, 244, 1);
  width: 100%;
  color: #333;
  padding: 6px 12px;
`;

const LoadingWrapper = styled(Flex)`
  flex-grow: 1;
  width: 100vw;
  height: calc(100vh - 120px);
  justify-content: center;
  align-items: center;
`;

const MobileCommunicatorPage = (props) => {
  const [isMobileSize, setIsMobileSize] = useState(null);
  useEffect(() => {
    if (window.innerWidth > 425) {
      setIsMobileSize(false);
    } else {
      setIsMobileSize(true);
    }

    const updateMedia = () => {
      if (window.innerWidth > 425) {
        setIsMobileSize(false);
      } else {
        setIsMobileSize(true);
      }
    };
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return <>{!!isMobileSize ? <MobileCommunicatorPageInner {...props} /> : <DesktopCommunicatorPageInner {...props} />}</>;
};
class DesktopCommunicatorPageInner extends React.Component {
  constructor(props) {
    super(props);
    let isAsync = false;
    let isDesktopForced = false;
    let blockSend = false;

    if (props.location && props.location.state) {
      isAsync = props.location.state.isAsync;
      isDesktopForced = props.location.state.isDesktopForced;
      blockSend = props.location.state.blockSend;
    }
    this.state = {
      isAsync,
      isDesktopForced,
      blockSend,
    };
    if (window.Beacon) {
      // console.log('Beacon found');
      window.Beacon('destroy');
    }
  }
  componentWillUnmount() {
    // console.log('window.onbeforeunload', window);
    if (window.Beacon) {
      // console.log('window.onbeforeunload Beacon found');
      window.Beacon('init', '828e0d09-171b-4d1e-9d3c-dc4f59c81115');
      // window.Beacon('config', { display: '' });
    }
  }
  render() {
    const { match, history } = this.props;
    const { isAsync, blockSend } = this.state;
    const vh100 = `${measureHeight()}px` || '100vh';
    const isMobileApp = !!window && !!window.ReactNativeWebView;
    const calc = `calc(${isMobileApp || vh100 === 'px' ? '100vh' : vh100} - ${isMobileApp ? '0px' : '100px'})`;
    return (
      <Flex id="ppp" style={{ maxWidth: '490px', width: '100%', position: 'relative', height: '100%', paddingLeft: '20px' }}>
        <Query query={APPOINTMENTS} fetchPolicy="network-only" variables={{ includeNut: true }}>
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <Flex flex={1} height={'100%'} width={'100%'} justifyContent="center" alignItems={'center'}>
                  <SpinLoader />
                </Flex>
              );
            }

            if (error) {
              return (
                <Flex flex={1} height={'100%'} width={'100%'} justifyContent="center" alignItems={'center'}>
                  <Text fontSize={5} fontWeight={600} textAlign="center">
                    Unable to retrieve visits. Please try again.
                  </Text>
                </Flex>
              );
            }

            const { appointments } = data;

            if (appointments.length === 0) {
              return <Redirect to={{ pathname: '/consultations' }} />;
            }

            const appointment = appointments.find((item) => {
              return item.id === match.params.id;
            });

            if (!appointment) {
              return <Redirect to={{ pathname: '/consultations' }} />;
            }
            return (
              <Query
                query={PRESCRIPTION}
                variables={{
                  id: appointment.id,
                }}
                fetchPolicy="network-only"
              >
                {(prescriptionQuery) => {
                  if (prescriptionQuery.loading) {
                    return (
                      <Flex flex={1} height={'100%'} width={'100%'} justifyContent="center" alignItems={'center'}>
                        <SpinLoader />
                      </Flex>
                    );
                  }

                  if (prescriptionQuery.error) {
                    return (
                      <Flex flex={1} height={'100%'} width={'100%'} justifyContent="center" alignItems={'center'}>
                        <Text fontSize={5} fontWeight={600} textAlign="center">
                          Unable to retrieve visits. Please try again.
                        </Text>
                      </Flex>
                    );
                  }
                  return (
                    <Query
                      query={APPT_SUMMARY}
                      variables={{
                        id: appointment.id,
                      }}
                      fetchPolicy="network-only"
                    >
                      {(summaryQuery) => {
                        if (summaryQuery.loading) {
                          return (
                            <Flex flex={1} height={'100%'} width={'100%'} justifyContent="center" alignItems={'center'}>
                              <SpinLoader />
                            </Flex>
                          );
                        }

                        if (summaryQuery.error) {
                          return (
                            <Flex flex={1} height={'100%'} width={'100%'} justifyContent="center" alignItems={'center'}>
                              <Text fontSize={5} fontWeight={600} textAlign="center">
                                Unable to retrieve visits. Please try again.
                              </Text>
                            </Flex>
                          );
                        }

                        return (
                          <Flex flex={1} flexDirection="column">
                            <Heading mb={4}>A Question For Your clinician?</Heading>
                            <Communicator
                              loadingWrapper={LoadingWrapper}
                              appointmentId={appointment.id}
                              appointment={appointment}
                              prescription={prescriptionQuery.data}
                              summary={summaryQuery.data}
                              isAsync={isAsync}
                              isShowOnlyDesktop
                              blockSend
                            />
                          </Flex>
                        );
                      }}
                    </Query>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </Flex>
    );
  }
}
class MobileCommunicatorPageInner extends React.Component {
  constructor(props) {
    super(props);
    let isAsync = false;
    let isDesktopForced = false;
    let blockSend = false;

    if (props.location && props.location.state) {
      isAsync = props.location.state.isAsync;
      isDesktopForced = props.location.state.isDesktopForced;
      blockSend = props.location.state.blockSend;
    }
    this.state = {
      isAsync,
      isDesktopForced,
      blockSend,
    };
    if (window.Beacon) {
      // console.log('Beacon found');
      window.Beacon('destroy');
    }
  }
  componentWillUnmount() {
    // console.log('window.onbeforeunload', window);
    if (window.Beacon) {
      // console.log('window.onbeforeunload Beacon found');
      window.Beacon('init', '828e0d09-171b-4d1e-9d3c-dc4f59c81115');
      // window.Beacon('config', { display: '' });
    }
  }
  render() {
    const { match, history } = this.props;
    const { isAsync, isDesktopForced, blockSend } = this.state;
    const vh100 = `${measureHeight()}px` || '100vh';
    const isMobileApp = !!window && !!window.ReactNativeWebView;
    const calc = `calc(${isMobileApp || vh100 === 'px' ? '100vh' : vh100} - ${isMobileApp ? '0px' : '100px'})`;
    return (
      <div style={{ maxWidth: '100vw', position: 'relative', maxHeight: calc }}>
        {!window.ReactNativeWebView &&
          !isDesktopForced && (
            <DetailNavigation>
              <BackButton
                onClick={() => {
                  history.goBack();
                }}
              >{`<  Your visit`}</BackButton>
            </DetailNavigation>
          )}
        <Query query={APPOINTMENTS} fetchPolicy="network-only" variables={{ includeNut: true }}>
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <LoadingWrapper>
                  <SpinLoader />
                </LoadingWrapper>
              );
            }

            if (error) {
              return (
                <LoadingWrapper>
                  <Text fontSize={5} fontWeight={600} textAlign="center">
                    Unable to retrieve visits. Please try again.
                  </Text>
                </LoadingWrapper>
              );
            }

            const { appointments } = data;

            if (appointments.length === 0) {
              return <Redirect to={{ pathname: '/consultations' }} />;
            }

            const appointment = appointments.find((item) => {
              return item.id === match.params.id;
            });

            if (!appointment) {
              return <Redirect to={{ pathname: '/consultations' }} />;
            }
            return (
              <Query
                query={PRESCRIPTION}
                variables={{
                  id: appointment.id,
                }}
                fetchPolicy="network-only"
              >
                {(prescriptionQuery) => {
                  if (prescriptionQuery.loading) {
                    return (
                      <LoadingWrapper>
                        <SpinLoader />
                      </LoadingWrapper>
                    );
                  }

                  if (prescriptionQuery.error) {
                    return (
                      <LoadingWrapper>
                        <Text fontSize={5} fontWeight={600} textAlign="center">
                          Unable to retrieve visits. Please try again.
                        </Text>
                      </LoadingWrapper>
                    );
                  }
                  return (
                    <Query
                      query={APPT_SUMMARY}
                      variables={{
                        id: appointment.id,
                      }}
                      fetchPolicy="network-only"
                    >
                      {(summaryQuery) => {
                        if (summaryQuery.loading) {
                          return (
                            <LoadingWrapper>
                              <SpinLoader />
                            </LoadingWrapper>
                          );
                        }

                        if (summaryQuery.error) {
                          return (
                            <LoadingWrapper>
                              <Text fontSize={5} fontWeight={600} textAlign="center">
                                Unable to retrieve visits. Please try again.
                              </Text>
                            </LoadingWrapper>
                          );
                        }

                        return (
                          <Communicator
                            loadingWrapper={LoadingWrapper}
                            appointmentId={appointment.id}
                            appointment={appointment}
                            prescription={prescriptionQuery.data}
                            summary={summaryQuery.data}
                            isAsync={isAsync}
                            blockSend={blockSend}
                          />
                        );
                      }}
                    </Query>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default MobileCommunicatorPage;
