import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import SpinLoader from './SpinLoader';
import { ToastConsumer } from 'react-toast-notifications';

const Container = styled(Flex)`
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 10px;
  max-height: 800px;
  overflow: hidden;
  cursor: pointer;
  width: 350px;
  background: ${props => props.theme.toast[props.appearance]};
  color: ${props => props.theme.toastText[props.appearance]};

  @media screen and (max-width: 46em) {
    width: 100vw;
    margin-bottom: 0;
    border-bottom: 1px solid black;
    border-radius: 0;
    padding: 0;
  }
`;

const Body = styled(Flex)`
  flex: 1;
  margin: auto 0;
  flex-direction: column;
  font-weight: 500;
  font-size: 18px;
`;

export const StyledToast = ({ children, appearance, loading }) => (
  <ToastConsumer>
    {props => (
      <Container
        appearance={appearance}
        onClick={() => !loading && props.remove(props.toasts[0].id)}
      >
        <Body>
          <Flex mx={3} my={1} textAlign="center" justifyContent="center">
            {children}
          </Flex>

          {loading && (
            <Flex justifyContent="center" my={2}>
              <SpinLoader height={25} width={25} />
            </Flex>
          )}
        </Body>
      </Container>
    )}
  </ToastConsumer>
);
