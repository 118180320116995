import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Button, Card, Flex, Heading } from 'rebass';
import { Wizard } from 'react-albus';

import { withRouter } from 'react-router';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;
const UploadLabResultsSuccess = ({ history }) => {
  return (
    <Container>
      <Card style={{padding: '24px'}}>
        <Flex
        
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Heading>Your Lab results have been successfully uploaded</Heading>

          <Button
          mt={4}
            variant="primary"
            onPress={() => {
              history.push('/');
            }}
          >
            Go To Dashboard
          </Button>
        </Flex>
      </Card>
    </Container>
  );
};

export default withRouter(UploadLabResultsSuccess);
