import React from 'react';
import { Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { MembershipBulletItem } from '../../../../components/MembershipBulletItem';
import { isToggleActive } from '../../../../components/featureToggle/toggles';
import { Constants } from '../../../../utils/constants';

const A = styled(Text)`
  text-decoration: underline;
  margin-top: 28px;
  text-align: center;
  cursor: pointer;
`;

export const IntroInsurance = ({ subscribe, skip }) => (
  <Flex flexDirection="column">
    <Card
      style={{
        position: 'relative',
        padding: '24px',
        margin: '12px',
        paddingBottom: '24px',
        maxWidth: '400px',
        zIndex: 1,
      }}
    >
      <>
        <svg width="216" height="193" viewBox="0 0 216 193" fill="none" style={{ position: 'absolute', zIndex: -1, top: 0, left: 0 }} xmlns="http://www.w3.org/2000/svg">
          <path
            d="M200.431 141.828C188.829 156.058 174.345 165.629 158.855 173.61C121.528 192.844 82.2117 197.419 41.1933 188.885C32.8552 187.151 24.3359 186.235 15.8704 185.188C0.732343 183.313 -13.2054 178.36 -24.5777 168.012C-37.5196 156.236 -46.8009 141.73 -53.0749 125.393C-65.3319 93.4707 -68.9271 60.8566 -60.1484 27.4045C-57.0406 15.5568 -50.9512 5.13435 -44.6573 -5.27518C-35.3071 -20.7471 -22.4797 -32.7319 -8.15858 -43.2071C0.696121 -49.683 11.2666 -52.2324 22.0077 -53.3318C63.932 -57.6135 105.094 -53.769 145.167 -40.4526C152.432 -38.0381 160.175 -35.1781 165.871 -30.3513C174.238 -23.0118 181.786 -14.7831 188.383 -5.80966C199.755 9.16994 205.968 26.6579 209.888 44.9347C214.073 64.4562 217.517 84.1066 215.313 104.198C213.766 118.256 207.98 130.723 200.431 141.828Z"
            fill="#ebb6a7"
            fill-opacity="0.15"
          />
        </svg>
        <Heading textAlign={'center'} mb={3} mt={3}>
          With Insurance
        </Heading>
        <Flex justifyContent={'center'} alignItems="center">
          <Text fontSize={'18px'} textAlign="center" mr={3} style={{ textDecoration: 'line-through' }}>
            {`$${isToggleActive('MEMBERSHIP_PRICE_2024') ? Constants.MEMBERSHIP_PRICE_2024 : Constants.MEMBERSHIP_PRICE}`}
          </Text>
          <Text fontSize={'30px'} textAlign="center">
            $96
          </Text>
        </Flex>
        <Text textAlign={'center'} fontWeight="300" mb={3}>
          PER YEAR
        </Text>

        <MembershipBulletItem
          textStyle={{ textAlign: 'center' }}
          showBullet={false}
          style={{ width: '90%', margin: '0 auto 12px auto', marginBottom: '12px', justifyContent: 'left' }}
          text="Doctors visits billed through insurance"
        />
        <MembershipBulletItem
          textStyle={{ textAlign: 'center' }}
          showBullet={false}
          style={{ width: '90%', margin: '0 auto 12px auto', marginBottom: '12px', justifyContent: 'left' }}
          text="Exclusive discounts on all our services and products"
        />
        <MembershipBulletItem
          textStyle={{ textAlign: 'center' }}
          showBullet={false}
          style={{ width: '90%', margin: '0 auto 36px auto', marginBottom: '36px', justifyContent: 'left' }}
          text="Members-only resources and community"
        />

        <Button
          style={{
            width: '90%',
            margin: '0 auto',
            display: 'block',
            maxWidth: '200px',
          }}
          variant="primary"
          onClick={() => {
            subscribe();
          }}
        >
          Join Now
        </Button>
      </>
    </Card>
    {!!skip && <A onClick={skip}>Skip</A>}
  </Flex>
);
