import React, { Fragment } from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router';
import yourLab from '../../../static/your_lab.png';

const DisqualifiedDob = () => {
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }} pb="60px">
        <img
          src={yourLab}
          alt=""
          width="140px"
          style={{ alignSelf: 'center' }}
        />
        <Box m={2} mt={4} mb={3}>
          <Heading textAlign="left">We are very sorry</Heading>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Text
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            You must be 18 years or older to be able to use our services.
          </Text>
        </Box>
        
      </Flex>
    </Fragment>
  );
};

export default compose(
  withRouter,
  withApollo,
  withWizard,
  withToastManager
)(DisqualifiedDob);
