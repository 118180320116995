import React, { Component, Fragment, useState } from 'react';
import { Flex, Box, Button, Heading, Text, Card } from 'rebass';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import { Query } from 'react-apollo';
import { GET_INTAKE_PREVIOUS_VALUES } from '../../graphql';
import withSession from '../../lib/withSession';
import SpinLoader from '../SpinLoader';
import Header from '../Header';

const StyledText = styled(Text)`
  line-height: 1.6;
  border-bottom: 1px solid;
  /* color: white; */
  text-align: center;

  &:hover {
    cursor: pointer;
    font-weight: 600;
  }
`;

const SelectedMarkerOut = styled(Flex)`
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #354e79;
  transition: background 150ms ease-in-out;
  width: 16px;
  height: 16px;
  margin-right: 20px;
  margin-top: 2px;
  justify-content: center;
  align-items: center;
  &.isChecked {
    background: #354e79;
  }
`;

const formatSubmit = (values) => {
  let payload = [];

  for (const k in values) {
    if (values[k].chosen === 'Y') {
      payload.push({
        questionId: values[k].questionId,
        answer: values[k].answer || '',
        value: values[k].value,
      });
    }
  }

  return payload;
};

const ReturnIntakeChanges = ({ session, onSubmit, wizard }) => {
  const [selected, setSelected] = useState([]);
  const handleChosen = (element) => {
    if (selected.indexOf(element) > -1) {
      setSelected(selected.filter((x) => x !== element));
      return;
    }

    setSelected([...selected, element]);
  };

  const getContent = (data) => {
    const entries = [];
    const { diagnoseFull } = session;

    const canId = window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '297' : '301';
    const fineNeedleId = window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '362' : '381';
    if (!!data && data.getCurrentIntakeValues) {
      if (data.getCurrentIntakeValues.medicalhistory) {
        for (let index = 0; index < data.getCurrentIntakeValues.medicalhistory.length; index++) {
          const element = data.getCurrentIntakeValues.medicalhistory[index];

          if (element.questionId.toString() === '18') {
            if (element.answer !== 'Y') {
              entries.push('hyper');
            }
          } else if (element.questionId.toString() === '19') {
            if (element.answer !== 'Y') {
              entries.push('diagnosed');
            }
          } else if (element.questionId.toString() === '281') {
            if (element.answer !== 'Y') {
              entries.push('hashimoto');
            }
          } else if (element.questionId.toString() === canId) {
            if (element.answer !== 'Y') {
              entries.push('cancer');
            }
          } else if (element.questionId.toString() === '282') {
            if (element.answer !== 'Y') {
              entries.push('thyroid');
            }
          } else if (element.questionId.toString() === '283') {
            if (element.answer !== 'Y') {
              entries.push('radiation');
            }
          }
          // else if (element.questionId.toString() === '284') {
          //   if (element.answer !== 'Y') {
          //     entries.push('goiter');
          //   }
          // }
          // else if (element.questionId.toString() === fineNeedleId) {
          //   if (element.answer !== 'Y') {
          //     entries.push('goiterBiopsy');
          //   }
          // }
        }
      }
    }
    // if(data,getCurrentIntakeValues.medicalhistory.())
    entries.push('currentPregnant');
    return entries;
  };

  const convertKeyToQuestion = (element) => {
    switch (element) {
      case 'hyper':
        return `You've never had hyperthyroidism`;
      case 'diagnosed':
        return `You've never had hypothyroidism`;
      case 'hashimoto':
        return `You've never had Hashimoto's`;
      case 'cancer':
        return `You've never had thyroid cancer`;
      case 'thyroid':
        return `You've never had your thyroid surgically removed`;
      case 'radiation':
        return `You've never undergone radiation therapy`;
      case 'goiterBiopsy':
        return `You've never had a fine needle biopsy`;
      case 'currentPregnant':
        return `Your pregnancy status hasn't changed`;
      default:
        break;
    }
  };

  return (
    <Query query={GET_INTAKE_PREVIOUS_VALUES}>
      {({ loading, error, data }) => {
        if (loading)
          return (
            <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
              <SpinLoader width={100} height={100} />
              <Text
                style={{
                  color: '#486288',
                  fontSize: '18px',
                  marginTop: '40px',
                }}
              >
                Initializing The Intake Form
              </Text>
            </Flex>
          );
        return (
          <Fragment>
            <Flex flex={1} flexDirection="column" style={{ zIndex: 1, maxWidth: '700px', paddingTop: '40px', margin: '0 auto' }}>
              <Flex flex={1}>
                <Header dark={false} mb={3} />
              </Flex>
              <Box m={2}>
                <Text fontSize={'20px'} mb={2} fontWeight={400} color="#344f77" textAlign="center">
                  Has any of the following changed since your last visit?
                </Text>
                <Text textAlign="center">Please select what needs to be updated in your chart</Text>
              </Box>

              <Flex my={3} flex={1} flexDirection="column">
                <Flex flexDirection={'column'} justifyContent="center" style={{ maxWidth: '500px', width: '500px', margin: '0 auto' }}>
                  {getContent(data).map((el) => (
                    <Fragment key={el}>
                      <Card
                        p="12px"
                        mb={3}
                        onClick={() => handleChosen(el)}
                        key={el}
                        style={{
                          border: `1px solid ${selected.indexOf(el) > -1 ? '#354E79' : 'white'}`,
                          backgroundColor: selected.indexOf(el) > -1 ? '#E7F0FF' : 'white',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Flex flexDirection="row">
                          <SelectedMarkerOut className={selected.indexOf(el) > -1 ? 'isChecked' : ''}>
                            {selected.indexOf(el) > -1 && (
                              <svg width="38px" height="38px" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M14.0866 6.84591C14.2627 6.67827 14.497 6.58559 14.7401 6.58743C14.9832 6.58927 15.2161 6.68548 15.3897 6.85577C15.5632 7.02605 15.6638 7.25709 15.6702 7.50012C15.6766 7.74316 15.5884 7.97919 15.4241 8.15841L10.4366 14.3959C10.3508 14.4883 10.2473 14.5624 10.1323 14.6139C10.0172 14.6653 9.89294 14.693 9.76691 14.6954C9.64089 14.6977 9.51569 14.6746 9.39879 14.6274C9.2819 14.5803 9.17572 14.51 9.0866 14.4209L5.7791 11.1134C5.68699 11.0276 5.61311 10.9241 5.56187 10.8091C5.51063 10.6941 5.48308 10.5699 5.48086 10.4441C5.47864 10.3182 5.50179 10.1931 5.54895 10.0764C5.5961 9.95967 5.66628 9.85363 5.7553 9.76461C5.84432 9.67558 5.95037 9.6054 6.0671 9.55825C6.18384 9.5111 6.30887 9.48795 6.43475 9.49017C6.56063 9.49239 6.68477 9.51994 6.79977 9.57118C6.91477 9.62242 7.01827 9.6963 7.1041 9.78841L9.7216 12.4047L14.0628 6.87341C14.0707 6.86378 14.079 6.8546 14.0878 6.84591H14.0866Z"
                                  fill="white"
                                />
                              </svg>
                            )}
                          </SelectedMarkerOut>

                          <Flex flexDirection="column" flex={1}>
                            <Text fontSize="14px">{convertKeyToQuestion(el)}</Text>
                          </Flex>
                        </Flex>
                      </Card>
                      {/* <Button type="button" variant={selected.indexOf(el) > -1 ? 'selected' : 'white'} width={[1, 1 / 3, 1 / 4]} m={3} onClick={() => handleChosen(el)}>
                        {convertKeyToQuestion(el)}
                      </Button> */}
                    </Fragment>
                  ))}
                </Flex>
              </Flex>

              <Flex justifyContent="center" alignItems="center" flexDirection="column" mx={[3, 3, 0]} my={[2, 3]}>
                <Button
                  variant="pink"
                  width={[1, 1 / 2, 1 / 4]}
                  onClick={() => {
                    // const formatted = formatSubmit(this.state);

                    // onSubmit({ values: formatted, endpoint: data.endpoint }, null, wizard, data);
                    const itms = ['diagnosed', 'hyper', 'hashimoto', 'cancer', 'thyroid', 'radiation', 'currentPregnant', 'goiterBiopsy', 'goiter', 'goiterUltraSound'];
                    const bypass = [];
                    for (let index = 0; index < itms.length; index++) {
                      const element = itms[index];
                      if (element === 'goiterUltraSound') {
                        if (selected.indexOf('goiterBiopsy') < 0) {
                          bypass.push(element);
                        }
                      }
                      if (selected.indexOf(element) < 0) {
                        if(element==='thyroid'){
                          bypass.push('thyroid-removal-details')
                        }
                        bypass.push(element);
                      }
                    }
                    onSubmit(bypass);
                  }}
                >
                  Next
                </Button>

                {/* {data.skip && (
                  <StyledText mt={4} mb={8} onClick={() => wizard.push(data.skip)}>
                    Skip
                  </StyledText>
                )} */}
              </Flex>
            </Flex>
          </Fragment>
        );
      }}
    </Query>
  );
};

export default withSession(ReturnIntakeChanges);
