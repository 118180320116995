import React, { useState } from 'react';
import { Flex, Text } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Mutation, Query } from 'react-apollo';
import { DELETE_ATHENA_CARD_FOR_PATIENT, ATHENA_CARD_FOR_PATIENT } from '../../../graphql';

export const AthenaView = ({ data }) => {
  const [isShowAthena, setIsShowAthena] = useState(false);

  return (
    <Flex flexDirection={'column'} pl={3} style={{ borderLeftColor: 'blue', borderWidth: 2 }} mt={3}>
      <Flex
        onClick={() => {
          setIsShowAthena(!isShowAthena);
        }}
      >
        <Text fontWeight={500} mb={3} flex={1}>
          Athena Data
        </Text>
        <FontAwesomeIcon size="1x" color="black" icon={isShowAthena ? faChevronUp : faChevronRight} />
      </Flex>
      {!!isShowAthena && (
        <>
          <Flex flexDirection={'row'} mb={2}>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>email:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).email}</Text>
            </Flex>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>athenaId:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).patientId}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection={'row'} mb={2}>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>first name:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).firstName}</Text>
            </Flex>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>last name:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).lastName}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection={'row'} mb={2}>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>address1:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).address1}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection={'row'} mb={2}>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>address2:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).address2}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection={'row'} mb={2}>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>city:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).city}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection={'row'} mb={2}>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>state:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).state}</Text>
            </Flex>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>zip:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).zip}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection={'row'} mb={2}>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>mobile Phone:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).mobilePhone}</Text>
            </Flex>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>home Phone:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).homePhone}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection={'row'} mb={2}>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>industry code:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).industrycode}</Text>
            </Flex>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>sex:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).sex}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection={'row'} mb={2}>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>departmentId:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).departmentId}</Text>
            </Flex>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>primary Department Id:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).primaryDepartmentId}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection={'row'} mb={2}>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>dob:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).dob}</Text>
            </Flex>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>registration Date:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).registrationDate}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection={'row'} mb={2}>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>primary Provider Id:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).primaryProviderId}</Text>
            </Flex>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>last Appointment:</Text>
              <Text fontWeight={500}>{(data.athenaData || {}).lastAppointment}</Text>
            </Flex>
          </Flex>

          <Flex flexDirection={'column'} mb={2}>
            <Text fontWeight={500}>Credit card on file:</Text>
            {(data.athenaData || {}).patientId && (
            <Query query={ATHENA_CARD_FOR_PATIENT} variables={{ patientId: parseInt(data.athenaData.patientId) }}>
              {({ data: dataCard, loading: loadingCard, error }) => {
                if (loadingCard) {
                  return <Text fontWeight={300}>Loading...</Text>;
                }
                if (!dataCard || !dataCard.athenaCardForPatient) {
                  return <Text fontWeight={300}>No card on file</Text>;
                }
                return (
                  <Flex>
                    <Text fontWeight={300} mr={2}>
                      {dataCard.athenaCardForPatient.brand}
                    </Text>
                    <Text fontWeight={300} mr={2}>
                      ***
                      {dataCard.athenaCardForPatient.last4}
                    </Text>
                    <Text fontWeight={300} mr={4}>
                      {dataCard.athenaCardForPatient.expMonth}/{dataCard.athenaCardForPatient.expYear}
                    </Text>

                    <Mutation
                      mutation={DELETE_ATHENA_CARD_FOR_PATIENT}
                      refetchQueries={[
                        {
                          query: ATHENA_CARD_FOR_PATIENT,
                          variables: {
                            patientId: parseInt((data.athenaData || {}).patientId, 10),
                          },
                        },
                      ]}
                      variables={{
                        cardId: dataCard.athenaCardForPatient.id,
                        athenaId: (data.athenaData || {}).patientId,
                      }}
                    >
                      {(mutate, { loading: loadingDelete }) => {
                        return (
                          <Text
                            onClick={() => {
                              if (loadingDelete) return;

                              mutate();
                            }}
                            style={{ textDecoration: !loadingDelete ? 'underline' : '', opacity: !loadingDelete ? 1 : 0.5, cursor: !loadingDelete ? 'pointer' : 'not-allowed' }}
                          >
                            {!loadingDelete ? 'delete card' : 'deleting...'}
                          </Text>
                        );
                      }}
                    </Mutation>
                  </Flex>
                );
              }}
            </Query>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};
