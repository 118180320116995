import React, { useEffect, useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

import FullLayout2 from '../asyncIntake/screens/components/FullLayout';
import Header from '../asyncIntake/Header';
import { Waiter } from '../asyncIntake/screens/Waiter';
import { LearnMoreModal } from '../asyncIntake/screens/LearnMoreModal';
import Welcome from './screens/Welcome';
import Profile from './screens/Profile';
import { GENERATE_CIRCLE_SO_CALLBACK, GET_ANALYTICS_ID, PHONE_AVAILABLE, SIGNUP } from '../../graphql';
import moment from 'moment';
import withAnalytics from '../../lib/withAnalytics';
import { isMobile } from 'react-device-detect';
import Div100vh, { measureHeight } from 'react-div-100vh';
import BirthSex from './screens/BirthSex';
import PreferredPronouns from './screens/PreferredPronouns';
import PleaseWait from '../../components/PleaseWait';
import GenderIdentity from './screens/GenderIdentity';
import Extended from './screens/Extended';
import Otp from '../registration/Otp';
import { withCookies } from 'react-cookie';

const Container = styled(Div100vh)`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  overflow-y: ${(props) => (isMobile ? 'hidden' : '')};
`;

const InnerContainer = styled(Flex)`
  max-width: ${(props) => (isMobile ? 'calc(100% - 24px)' : '850px')};
  height: 100%;
  margin: 0 auto;
  margin-bottom: ${(props) => (isMobile ? '80px' : '')};
  max-height: 100%;
  /* margin-right:  ${(props) => (isMobile ? '12px' : '850px')}; */
  flex-direction: column;
  overflow-y: ${(props) => (isMobile ? 'auto' : '')};
`;

const Ar = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [bypassOtp, setbypassOtp] = useState(false);
  useEffect(() => {
    console.log('useEffect', props);
    if (!loaded) {
      let bpotp;
      try {
        const tmp = props.location.state;
        console.log({ tmp });
        bpotp = (tmp || {}).find((x) => x.id === 'bpotp');
        if (!!bpotp && !!bpotp.values && (bpotp.values === true || bpotp.values === 'true')) {
          setbypassOtp(true);
        }
      } catch (error) {}
      setLoaded(true);
    }
  }, []);

  if (!loaded) return <Flex />;
  if (!bypassOtp) {
    return <AsyncRegisterOtp {...props} />;
  }
  return <AsyncRegister {...props} />;
};

class AsyncRegisterOtp extends React.Component {
  constructor(props) {
    super(props);
    let bypassOtp = false;
    const tmp = this.props.location.state;

    this.state = {
      values: {},
      isLoading: true,
      stepPreviousHistory: null,
      direction: 1,
      learnMoreModal: null,
      prequal: null,
      isRegistering: false,
      bypassOtp: false,
    };
    console.log(bypassOtp, this.props.location);
    window.Beacon('on', 'ready', () => window.Beacon('destroy'));
    this.wizard = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;
    if (currentPage !== prevPage) {
      const split = currentPage.split('/');
      let tag = '';

      tag = split[split.length - 1];
      if (tag) {
        this.props.analytics.page(tag, 'intake');
      }
    }
  }

  checkIncomingData = () => {
    const { location } = this.props;

    console.log({ location });
    if (!location || !location.state) {
      window.location = '/prequalification';
    }
    const tmp = location.state;
    // if (tmp) {
    // console.log({ tmp });
    let bypassedNotDiagnosed = false;
    const allowNotDiagnosed = (tmp || []).find((x) => x.id === 'allowNotDiagnosed');
    if (!allowNotDiagnosed || !allowNotDiagnosed.values || allowNotDiagnosed.values !== 'yes') {
      const diagnosed = (tmp || []).find((x) => x.id === 'previouslyDiagnosed');
      if (!diagnosed || !diagnosed.values || (diagnosed.values.value !== 'Yes' && diagnosed.values.value !== 'I have abnormal labs I can upload')) {
        window.location = '/prequalification';
      }
    } else {
      bypassedNotDiagnosed = true;
    }
    const dobs = (tmp || []).find((x) => x.id === 'dobSex');
    let dob;
    if (!dobs || !dobs.values || dobs.values.length < 1 || !dobs.values[0].value || !dobs.values[0].value.dob) {
      window.location = '/prequalification';
    } else {
      dob = dobs.values[0].value.dob;
    }
    let sex;
    if (!dobs || !dobs.values || dobs.values.length < 1 || !dobs.values[0].value || !dobs.values[0].value.sex) {
      window.location = '/prequalification';
    } else {
      sex = dobs.values[0].value.sex;
    }
    const forwardToCommunitySt = (tmp || []).find((x) => x.id === 'forwardToCommunity');
    let forwardToCommunity = false;

    if (!!forwardToCommunitySt && !!forwardToCommunitySt.values && (forwardToCommunitySt.values === true || forwardToCommunitySt.values === 'true')) {
      forwardToCommunity = true;
    }

    this.setState({
      prequal: {
        dob,
        sex,
      },
      bypassedNotDiagnosed,
      forwardToCommunity,
    });
    // }
  };

  componentDidMount() {
    window.Beacon('destroy');
    this.checkIncomingData();
  }

  showLearnMore = (learnMoreId) => {
    this.setState({
      learnMoreModal: learnMoreId,
    });
  };

  submitCheckWelcome = async (values, actions) => {
    this.setState({
      values: { ...values },
    });
    try {
      this.handleSubmit(values, actions);
    } catch (error) {
      throw Error(error);
    }
  };

  submitCheckProfile = async (values, actions) => {
    const { client } = this.props;
    this.setState({
      values: { ...values },
    });

    try {
      const { data } = await client.query({
        query: PHONE_AVAILABLE,
        variables: {
          phone: values.phone,
        },
      });
      if (actions) {
        actions.setSubmitting(false);
      }
      if (!data.phoneAvailable.ok) {
        throw Error('Number already in use');
      }

      this.handleSubmit(values, actions);
    } catch (error) {
      console.log('submitCheckProfile e', error);
      if (actions) {
        actions.setSubmitting(false);
        actions.setFieldError('phone', error.message);
        return;
      }
    }
  };

  submitForRegistration = async (values, actions) => {
    this.setState(
      {
        values: { ...values },
        isRegistering: true,
      },
      () => {
        this.register(values, actions);
      }
    );
  };

  register = async (vals, actions) => {
    const { location } = this.props;
    if (actions) {
      actions.setSubmitting(true);
    }
    let diagnosed = true;

    const { values, prequal, bypassedNotDiagnosed } = this.state;
    if (bypassedNotDiagnosed) {
      diagnosed = false;
    }
    let isNpThyroid = false;
    try {
      const { cookies } = this.props;
      const hasCookie = cookies.get('referralNP');
      isNpThyroid = !!hasCookie;
    } catch (error) {}
    try {
      const { data: d2 } = await this.props.client.mutate({
        mutation: SIGNUP,
        variables: {
          ...values,
          dob: moment(prequal.dob, 'YYYY-MM-DD').format('MM/DD/YYYY'),
          gender: prequal.sex.toLowerCase() === 'female' ? 'F' : 'M',
          diagnosed: diagnosed,
          assignedsexatbirth: !values.assignedsexatbirth || values.assignedsexatbirth.toUpperCase() === 'F' || prequal.sex.toLowerCase() === 'female' ? 'F' : 'M',
          isNPThyroid: isNpThyroid,
        },
      });
      if (actions) {
        actions.setSubmitting(false);
      }
      if (!d2.signup || !d2.signup.token) {
        throw Error('Error Creating Account');
      }

      this.setState({ t: d2.signup.token, values: { ...values } }, () => this.finalize());
    } catch (error) {
      console.log('register e', error);
      this.setState({
        isRegistering: false,
      });
      if (actions) {
        actions.setSubmitting(false);
      }
    }
  };

  handleSubmit = (values, actions) => {
    this.setState(
      {
        values: { ...values },
      },
      () => {
        console.log('AAAAA');
        this.wizard.current.next();
      }
    );
  };

  finalize = async () => {
    let diagnosed = true;

    const { values, t, bypassedNotDiagnosed, forwardToCommunity } = this.state;
    if (bypassedNotDiagnosed) {
      diagnosed = false;
    }

    localStorage.setItem('token', t);
    try {
      const aa = await this.props.client.query({
        query: GET_ANALYTICS_ID,
        fetchPolicy: 'network-only',
      });
      let age = 0;
      try {
        age = moment().diff(moment(values.dob, 'MM/DD/YYYY'), 'years');
      } catch (error) {}
      const { analytics } = this.props;
      if (aa && aa.data && aa.data.getAnalyticsId) {
        analytics.Alias(aa.data.getAnalyticsId);
        analytics.Identify(aa.data.getAnalyticsId, {
          age,
          gender: values.gender,
          state: values.state,
          diagnosed: !diagnosed ? false : values.diagnosed,
        });
      }

      analytics.track('Account Created', {
        age,
        gender: values.gender,
        diagnosed: !diagnosed ? false : values.diagnosed,
        state: values.state,
      });
    } catch (error2) {}
    this.setState({
      isRegistering: false,
    });

    if (forwardToCommunity) {
      const circleSo = await this.props.client.mutate({
        mutation: GENERATE_CIRCLE_SO_CALLBACK,
      });

      if (circleSo && circleSo.data && circleSo.data.generateCircleSoCallback) {
        if (!!circleSo.data.generateCircleSoCallback.callback) {
          window.open(circleSo.data.generateCircleSoCallback.callback, '_blank');
        } else if (!!circleSo.data.generateCircleSoCallback.mustAcceptTerms) {
          this.props.history.push({
            pathname: '/community-terms',
            state: { sameWindow: true },
          });
          return;
        }
      }
    }
    if (diagnosed) {
      this.props.history.push({
        pathname: '/post-register',
      });
    } else {
      this.props.history.push({
        pathname: '/',
      });
    }
  };

  render() {
    const { history } = this.props;
    const { direction, fakeDelaying, learnMoreModal, values, isRegistering, bypassOtp } = this.state;
    console.log({ bypassOtp });
    const vh100 = `${measureHeight()}px` || '100vh';

    if (!!isRegistering || bypassOtp === null || bypassOtp === undefined) {
      return (
        <FullLayout2 style={{ zIndex: 11, overflowY: isMobile ? 'hidden' : 'auto', maxHeight: vh100, display: 'flex', alignItems: 'center', justifyContent: 'center' }} pb={5}>
          <PleaseWait />
        </FullLayout2>
      );
    }

    return (
      <FullLayout2 style={{ zIndex: 11, overflowY: isMobile ? 'hidden' : 'auto', maxHeight: vh100 }} pb={5}>
        <Container>
          <Header previousPage={this.previousQuestion} hideBack={true} />
          <Box
            mt="60px"
            p="8px"
            style={{
              height: `calc(${vh100} - ${isMobile ? '60px' : '120px'})`,
              overflowY: 'hidden', // isMobile ? 'auto' : '',
            }}
          >
            {fakeDelaying ? (
              <Waiter />
            ) : (
              <Wizard
                ref={this.wizard}
                basename="/signup"
                history={history}
                render={({ step, ...p }, a) => {
                  return (
                    <>
                      <TransitionGroup>
                        <CSSTransition key={step.id} timeout={{ enter: 500, exit: 500 }} classNames={direction < 0 ? 'example-back' : 'example'}>
                          <div
                            className="example-steps2"
                            id="testtmp"
                            style={{
                              height: '100%',
                              width: 'min(100%, 680px) !important',
                              maxWidth: 'min(100%,680px) !important',
                            }}
                          >
                            <Steps step={step}>
                              <Step id="welcome">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <Welcome data={values} onSubmit={this.submitCheckWelcome} savedData={values} />
                                  </InnerContainer>
                                </Container>
                              </Step>
                              <Step id="profile">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <Profile data={values} onSubmit={this.submitCheckProfile} savedData={values} />
                                  </InnerContainer>
                                </Container>
                              </Step>
                              <Step id="otp">
                                <Otp
                                  onSubmit={this.handleSubmit}
                                  values={this.state.values}
                                  changePhone={(phone, mutate) => {
                                    this.setState({
                                      values: { ...this.state.values, phone },
                                    });
                                  }}
                                />
                              </Step>
                              <Step id="birthsex">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <BirthSex values={values} onSubmit={this.handleSubmit} />
                                  </InnerContainer>
                                </Container>
                              </Step>
                              <Step id="genderIdentity">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <GenderIdentity values={values} onSubmit={this.handleSubmit} />
                                  </InnerContainer>
                                </Container>
                              </Step>
                              <Step id="pronouns">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <PreferredPronouns
                                      values={values}
                                      onSubmit={(vals, actions) => {
                                        if (values.state !== 'CA') {
                                          this.submitForRegistration(vals, actions);
                                        } else {
                                          this.handleSubmit(vals, actions);
                                        }
                                      }}
                                    />
                                  </InnerContainer>
                                </Container>
                              </Step>
                              <Step id="extended">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <Extended data={values} onSubmit={this.submitForRegistration} />
                                  </InnerContainer>
                                </Container>
                              </Step>
                            </Steps>
                          </div>
                        </CSSTransition>
                      </TransitionGroup>
                    </>
                  );
                }}
              />
            )}
          </Box>
        </Container>
        {learnMoreModal && <LearnMoreModal learnMoreId={learnMoreModal} onClose={() => this.setState({ learnMoreModal: null })} />}
      </FullLayout2>
    );
  }
}

class AsyncRegister extends React.Component {
  constructor(props) {
    super(props);
    let bypassOtp = false;
    const tmp = this.props.location.state;

    this.state = {
      values: {},
      isLoading: true,
      stepPreviousHistory: null,
      direction: 1,
      learnMoreModal: null,
      prequal: null,
      isRegistering: false,
      bypassOtp: false,
    };
    console.log(bypassOtp, this.props.location);
    window.Beacon('on', 'ready', () => window.Beacon('destroy'));
    this.wizard = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;
    if (currentPage !== prevPage) {
      const split = currentPage.split('/');
      let tag = '';

      tag = split[split.length - 1];
      if (tag) {
        this.props.analytics.page(tag, 'intake');
      }
    }
  }

  checkIncomingData = () => {
    const { location } = this.props;
    console.log({ location });
    if (!location || !location.state) {
      window.location = '/prequalification';
    }
    const tmp = location.state;
    // if (tmp) {

    let bypassedNotDiagnosed = false;
    const allowNotDiagnosed = (tmp || []).find((x) => x.id === 'allowNotDiagnosed');
    if (!allowNotDiagnosed || !allowNotDiagnosed.values || allowNotDiagnosed.values !== 'yes') {
      const diagnosed = (tmp || []).find((x) => x.id === 'previouslyDiagnosed');
      if (!diagnosed || !diagnosed.values || (diagnosed.values.value !== 'Yes' && diagnosed.values.value !== 'I have abnormal labs I can upload')) {
        window.location = '/prequalification';
      }
    } else {
      bypassedNotDiagnosed = true;
    }

    const forwardToCommunitySt = (tmp || []).find((x) => x.id === 'forwardToCommunity');
    let forwardToCommunity = false;

    if (!!forwardToCommunitySt && !!forwardToCommunitySt.values && (forwardToCommunitySt.values === true || forwardToCommunitySt.values === 'true')) {
      forwardToCommunity = true;
    }

    const dobs = (tmp || []).find((x) => x.id === 'dobSex');
    let dob;
    if (!dobs || !dobs.values || dobs.values.length < 1 || !dobs.values[0].value || !dobs.values[0].value.dob) {
      window.location = '/prequalification';
    } else {
      dob = dobs.values[0].value.dob;
    }
    let sex;
    if (!dobs || !dobs.values || dobs.values.length < 1 || !dobs.values[0].value || !dobs.values[0].value.sex) {
      window.location = '/prequalification';
    } else {
      sex = dobs.values[0].value.sex;
    }

    this.setState({
      prequal: {
        dob,
        sex,
      },
      bypassedNotDiagnosed,
      forwardToCommunity,
    });
    // }
  };

  componentDidMount() {
    window.Beacon('destroy');
    this.checkIncomingData();
  }

  showLearnMore = (learnMoreId) => {
    this.setState({
      learnMoreModal: learnMoreId,
    });
  };

  submitCheckWelcome = async (values, actions) => {
    this.setState({
      values: { ...values },
    });
    try {
      this.handleSubmit(values, actions);
    } catch (error) {
      throw Error(error);
    }
  };

  submitCheckProfile = async (values, actions) => {
    const { client } = this.props;
    this.setState({
      values: { ...values },
    });

    try {
      const { data } = await client.query({
        query: PHONE_AVAILABLE,
        variables: {
          phone: values.phone,
        },
      });
      if (actions) {
        actions.setSubmitting(false);
      }
      if (!data.phoneAvailable.ok) {
        throw Error('Number already in use');
      }

      this.handleSubmit(values, actions);
    } catch (error) {
      console.log('submitCheckProfile e', error);
      if (actions) {
        actions.setSubmitting(false);
        actions.setFieldError('phone', error.message);
        return;
      }
    }
  };

  submitForRegistration = async (values, actions) => {
    this.setState(
      {
        values: { ...values },
        isRegistering: true,
      },
      () => {
        this.register(values, actions);
      }
    );
  };

  register = async (vals, actions) => {
    const { values, prequal, bypassedNotDiagnosed } = this.state;
    if (actions) {
      actions.setSubmitting(true);
    }

    let diagnosed = true;

    if (bypassedNotDiagnosed) {
      diagnosed = false;
    }
    let isNpThyroid = false;
    try {
      const { cookies } = this.props;
      const hasCookie = cookies.get('referralNP');
      isNpThyroid = !!hasCookie;
    } catch (error) {}
    try {
      const { data: d2 } = await this.props.client.mutate({
        mutation: SIGNUP,
        variables: {
          ...values,
          dob: moment(prequal.dob, 'YYYY-MM-DD').format('MM/DD/YYYY'),
          gender: prequal.sex.toLowerCase() === 'female' ? 'F' : 'M',
          diagnosed: diagnosed,
          assignedsexatbirth: !values.assignedsexatbirth || values.assignedsexatbirth.toUpperCase() === 'F' || prequal.sex.toLowerCase() === 'female' ? 'F' : 'M',
          isNPThyroid: isNpThyroid,
        },
      });
      if (actions) {
        actions.setSubmitting(false);
      }
      if (!d2.signup || !d2.signup.token) {
        throw Error('Error Creating Account');
      }

      this.setState({ t: d2.signup.token, values: { ...values } }, () => this.finalize());
    } catch (error) {
      console.log('register e', error);
      this.setState({
        isRegistering: false,
      });
      if (actions) {
        actions.setSubmitting(false);
      }
    }
  };

  handleSubmit = (values, actions) => {
    this.setState(
      {
        values: { ...values },
      },
      () => {
        console.log('AAAAA');
        this.wizard.current.next();
      }
    );
  };

  finalize = async () => {
    let diagnosed = true;

    const { values, t, bypassedNotDiagnosed, forwardToCommunity } = this.state;
    if (bypassedNotDiagnosed) {
      diagnosed = false;
    }

    localStorage.setItem('token', t);
    try {
      const aa = await this.props.client.query({
        query: GET_ANALYTICS_ID,
        fetchPolicy: 'network-only',
      });
      let age = 0;
      try {
        age = moment().diff(moment(values.dob, 'MM/DD/YYYY'), 'years');
      } catch (error) {}
      const { analytics } = this.props;
      if (aa && aa.data && aa.data.getAnalyticsId) {
        analytics.Alias(aa.data.getAnalyticsId);
        analytics.Identify(aa.data.getAnalyticsId, {
          age,
          gender: values.gender,
          state: values.state,
          diagnosed: !diagnosed ? false : values.diagnosed,
        });
      }

      analytics.track('Account Created', {
        age,
        gender: values.gender,
        diagnosed: !diagnosed ? false : values.diagnosed,
        state: values.state,
      });
    } catch (error2) {}
    this.setState({
      isRegistering: false,
    });

    if (forwardToCommunity) {
      const circleSo = await this.props.client.mutate({
        mutation: GENERATE_CIRCLE_SO_CALLBACK,
      });

      if (circleSo && circleSo.data && circleSo.data.generateCircleSoCallback) {
        if (!!circleSo.data.generateCircleSoCallback.callback) {
          window.open(circleSo.data.generateCircleSoCallback.callback, '_blank');
        } else if (!!circleSo.data.generateCircleSoCallback.mustAcceptTerms) {
          this.props.history.push({
            pathname: '/community-terms',
            state: { sameWindow: true },
          });
          return;
        }
      }
    }

    if (diagnosed) {
      this.props.history.push({
        pathname: '/post-register',
      });
    } else {
      this.props.history.push({
        pathname: '/',
      });
    }
  };

  render() {
    const { history } = this.props;
    const { direction, fakeDelaying, learnMoreModal, values, isRegistering, bypassOtp } = this.state;
    console.log({ bypassOtp });
    const vh100 = `${measureHeight()}px` || '100vh';

    if (!!isRegistering || bypassOtp === null || bypassOtp === undefined) {
      return (
        <FullLayout2 style={{ zIndex: 11, overflowY: isMobile ? 'hidden' : 'auto', maxHeight: vh100, display: 'flex', alignItems: 'center', justifyContent: 'center' }} pb={5}>
          <PleaseWait />
        </FullLayout2>
      );
    }

    return (
      <FullLayout2 style={{ zIndex: 11, overflowY: isMobile ? 'hidden' : 'auto', maxHeight: vh100 }} pb={5}>
        <Container>
          <Header previousPage={this.previousQuestion} hideBack={true} />
          <Box
            mt="60px"
            p="8px"
            style={{
              height: `calc(${vh100} - ${isMobile ? '60px' : '120px'})`,
              overflowY: 'hidden', // isMobile ? 'auto' : '',
            }}
          >
            {fakeDelaying ? (
              <Waiter />
            ) : (
              <Wizard
                ref={this.wizard}
                basename="/signup"
                history={history}
                render={({ step, ...p }, a) => {
                  return (
                    <>
                      <TransitionGroup>
                        <CSSTransition key={step.id} timeout={{ enter: 500, exit: 500 }} classNames={direction < 0 ? 'example-back' : 'example'}>
                          <div
                            className="example-steps2"
                            id="testtmp"
                            style={{
                              height: '100%',
                              width: 'min(100%, 600px) !important',
                              maxWidth: 'min(100%,600px) !important',
                            }}
                          >
                            <Steps step={step}>
                              <Step id="welcome">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <Welcome data={values} onSubmit={this.submitCheckWelcome} savedData={values} />
                                  </InnerContainer>
                                </Container>
                              </Step>
                              <Step id="profile">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <Profile data={values} onSubmit={this.submitCheckProfile} savedData={values} />
                                  </InnerContainer>
                                </Container>
                              </Step>
                              {/* <Step id="otp">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <Otp
                                      onSubmit={this.handleSubmit}
                                      values={this.state.values}
                                      changePhone={(phone, mutate) => {
                                        this.setState({
                                          values: { ...this.state.values, phone },
                                        });
                                      }}
                                    />
                                  </InnerContainer>
                                </Container>
                              </Step> */}
                              <Step id="birthsex">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <BirthSex values={values} onSubmit={this.handleSubmit} />
                                  </InnerContainer>
                                </Container>
                              </Step>
                              <Step id="genderIdentity">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <GenderIdentity values={values} onSubmit={this.handleSubmit} />
                                  </InnerContainer>
                                </Container>
                              </Step>
                              <Step id="pronouns">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <PreferredPronouns
                                      values={values}
                                      onSubmit={(vals, actions) => {
                                        if (values.state !== 'CA') {
                                          this.submitForRegistration(vals, actions);
                                        } else {
                                          this.handleSubmit(vals, actions);
                                        }
                                      }}
                                    />
                                  </InnerContainer>
                                </Container>
                              </Step>
                              <Step id="extended">
                                <Container style={{ overflowY: 'auto' }}>
                                  <InnerContainer p={3}>
                                    <Extended data={values} onSubmit={this.submitForRegistration} />
                                  </InnerContainer>
                                </Container>
                              </Step>
                            </Steps>
                          </div>
                        </CSSTransition>
                      </TransitionGroup>
                    </>
                  );
                }}
              />
            )}
          </Box>
        </Container>
        {learnMoreModal && <LearnMoreModal learnMoreId={learnMoreModal} onClose={() => this.setState({ learnMoreModal: null })} />}
      </FullLayout2>
    );
  }
}

export default withCookies(withApollo(withRouter(withAnalytics(Ar))));
