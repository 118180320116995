import React, { Fragment } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';

import { TextInput } from '../TextInput';
import { FormField } from '../FormField';
import TextArea from '../TextArea';
import { withApollo, compose } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { APPOINTMENTS, CHARTS, CURRENT_USER, SET_AS_INACTIVE } from '../../graphql';
import * as Yup from 'yup';
import ScrollAnimation from 'react-animate-on-scroll';
import { fadeInDown, fadeInUp } from '../../utils/animationsStyleBuilder';

const StyledText = styled(Text)`
  line-height: 1.6;
  border-bottom: 1px solid;
  /* color: white; */
  text-align: center;

  &:hover {
    cursor: pointer;
    font-weight: 600;
  }
`;
const schema = Yup.object().shape({
  feet: Yup.number()
    .required()
    .positive()
    .integer()
    .lessThan(9),
  inches: Yup.number()
    .required()
    .moreThan(-1)
    .integer()
    .lessThan(13),
  pounds: Yup.number()
    .required()
    .positive()
    .integer(),
});

class HeightWeight extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  handleClick = async (element) => {
    const { data, wizard, client, toastManager, userData } = this.props;

    this.setState({ loading: true });
    const dta = JSON.parse(JSON.stringify(userData));

    try {
      await client.mutate({
        mutation: CHARTS,
        variables: {
          data: dta,
          intakeStatus: 'disqualified',
        },
      });
      await client.mutate({
        mutation: SET_AS_INACTIVE,
        variables: {
          disqualifyingReason: data.disqualifyingText || data.id,
        },
        refetchQueries: [{ query: CURRENT_USER }, { query: APPOINTMENTS }],
        awaitRefetchQueries: true,
      });

      wizard.history.push('disqualified');
    } catch (err) {
      console.log('ERR', err);
      this.setState({ loading: false });
      toastManager.add('Error submitting intake form', {
        appearance: 'error',
      });
    }
    // }
  };

  formatSubmit = (values) => {
    const { data } = this.props;
    const regex = /_/g;
    let payload = [];
    payload.push(
      {
        questionId: 'LOCAL.121',
        answer: `${values.feet}'${values.inches}"`,
      },
      {
        questionId: 'LOCAL.122',
        answer: `${values.pounds}`,
      }
    );

    // for (const k in values) {
    //   const questionId = k.replace(regex, '.');
    //   let answer;

    //   if (data.prefix) {
    //     answer = `${data.prefix} ${values[k]}`;
    //   } else {
    //     answer = values[k];
    //   }

    //   payload.push({ questionId, answer });
    // }

    return payload;
  };
  render() {
    const { data, onSubmit, wizard } = this.props;

    return (
      <Fragment>
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          <Box m={2} style={{ ...fadeInDown() }}>
            {/* <ScrollAnimation animateIn="fadeInDownFrom20" animateOnce={false}> */}
            <Text fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
              {data.prompt}
            </Text>
            {/* </ScrollAnimation> */}
          </Box>

          <Formik
            initialValues={data.defaults}
            validationSchema={schema}
            onSubmit={(values, actions) => {
              const formatted = this.formatSubmit(values);

              onSubmit(
                {
                  values: formatted,
                  endpoint: data.endpoint,
                },
                actions,
                wizard,
                data
              );
            }}
            render={({ isValid, errors, touched }) => (
              <Form>
                <Flex flex={1} flexDirection="column" style={{ zIndex: 1, alignItems: 'center' }}>
                  <Flex my={3} flex={1} flexDirection="column">
                    <Flex my={3} flexDirection="row" alignItems="center" style={{ ...fadeInUp(20, '1.1s', '100ms') }}>
                      <FormField containerStyle={{ flex: 'initial' }} style={{ maxWidth: '80px', flex: 'initial' }} error={touched['feet'] && errors['feet']} alignSelf="center">
                        <TextInput containerStyle={{ flex: 'initial' }} style={{ maxWidth: '80px', flex: 'initial' }} isAsyncMode={true} name={'feet'} type={'number'} error={errors} />
                      </FormField>
                      <Text ml={2} mr={4}>{` FEET`}</Text>

                      <FormField containerStyle={{ flex: 'initial' }} style={{ maxWidth: '80px', flex: 'initial' }} error={touched['inches'] && errors['inches']} alignSelf="center">
                        <TextInput containerStyle={{ flex: 'initial' }} style={{ maxWidth: '80px', flex: 'initial' }} isAsyncMode={true} name={'inches'} type={'number'} error={errors} />
                      </FormField>
                      <Text ml={2}>{` INCHES`}</Text>
                    </Flex>
                    
                    <Flex my={4} flexDirection="row" alignItems="center" style={{ ...fadeInUp(20, '1.2s', '200ms') }}>
                      <FormField containerStyle={{ flex: 'initial' }} style={{ maxWidth: '80px', flex: 'initial' }} error={touched['pounds'] && errors['pounds']} alignSelf="center">
                        <TextInput containerStyle={{ flex: 'initial' }} style={{ maxWidth: '100px', flex: 'initial' }} isAsyncMode={true} name={'pounds'} type={'number'} error={errors} />
                      </FormField>
                      <Text ml={2}>{` POUNDS`}</Text>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex justifyContent="center" alignItems="center" flexDirection="column" mx={[3, 3, 0]} my={[2, 3]} style={{ ...fadeInUp(40, '1s', '300ms') }}>
                  <Button disabled={!isValid || this.state.loading} variant="pink" width={[1, 1 / 2, 1 / 4]} type="submit">
                    Next
                  </Button>

                  {data.hasSecondOption && (
                    <Button disabled={this.state.loading} variant="dim" width="auto" type="button" mt={4} onClick={() => this.handleClick(data.options[0])}>
                      {data.secondOptionText}
                    </Button>
                  )}

                  {data.skip && (
                    <StyledText
                      mt={4}
                      onClick={() => {
                        wizard.push(data.skip);
                      }}
                      mb={4}
                    >
                      Skip
                    </StyledText>
                  )}
                </Flex>
              </Form>
            )}
          />
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(HeightWeight);
