import React, { useState } from 'react';
import { Elements } from 'react-stripe-elements';
import { useConstructor } from '../../utils/hooks/lifecycle';
import RegisterKitPaymentComponent from './components/RegisterKitPaymentComponent';

const RegisterKitPayment = props => {
  const [serial, setSerial] = useState('');
  const [loadedSerial, setLoadedSerial] = useState(false);

  useConstructor(() => {
    let serialLoc;
    if (props.location && props.location.state) {
      serialLoc = props.location.state.serial;
    }
    setSerial(serialLoc);
    setLoadedSerial(true);
  });

  if (!serial || !loadedSerial) {
    return <div />;
  }
  return (
    <Elements>
      <RegisterKitPaymentComponent {...props} serial={serial} />
    </Elements>
  );
};

export default RegisterKitPayment;
