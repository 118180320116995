import React from 'react';
import { Flex, Text } from 'rebass';
import { GraphPink } from '../icons/GraphPink';
import { BigRightHormone, InnerLeftHormone, InnerRightHormone, MediumHormone, SmallHormone } from '../icons/InnerRightHormone';
import Odometer from '../components/RollingNumbers';
import styled from 'styled-components';

const CText = styled(Text)`
  font-size: 50px;
  line-height: 90px;

  @media screen and (max-width: 52em) {
    font-size: 32px;
  line-height: 50px;
  }
`
export const TrackedSymptomsCountBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <BigRightHormone
        style={{
          position: 'absolute',
          right: 0,
          top: 80,
        }}
      />
      <MediumHormone style={{ position: 'absolute', top: 200, right: 150 }} />
      <InnerLeftHormone style={{ position: 'absolute', bottom: 120, left:0 }} />
      <SmallHormone style={{ position: 'absolute', bottom: 200, right: 0 }} />
    </Flex>
  );
};

export const TrackedSymptomsCount = ({ trackedCount=0 }) => {
  return (
    <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', zIndex: 100 }}>
      <CText style={{ fontWeight: 700, textAlign: 'center', color: 'white' }}>
        You have tracked your symptoms{' '}
        <span>
          <Odometer delay={0} startNumber={0} to={trackedCount || 0} style={{ color: '#F8A294', fontSize: window.matchMedia('(min-width: 52em)').matches?70:42, fontWeight: 'bold', width: '100%' }} />
        </span>{' '}
        times this year!
      </CText>
    </Flex>
  );
};
