import React from 'react';
import { Flex, Text } from 'rebass';
import { GraphPink } from '../icons/GraphPink';
import { BigRightHormone, InnerLeftHormone, InnerRightHormone, MediumHormone, SmallHormone } from '../icons/InnerRightHormone';
import Odometer from '../components/RollingNumbers';
import styled from 'styled-components';
const Avatar = styled(Flex)`
  width: 100px;
  height: 100px;
  background: #ffe3d8;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-left: -20px;

@media screen and (max-width: 52em) {
  width: 72px;
  height: 72px;
}
`;

const CText = styled(Text)`
  font-size: 50px;
  line-height: 90px;

  @media screen and (max-width: 52em) {
    font-size: 32px;
  line-height: 50px;
  }
`

const Img= styled.img`
  height: 100px;
  @media screen and (max-width: 52em) {
  height: 72px;
  }
`

export const DoctorsVisitsCountBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <MediumHormone style={{ position: 'absolute', top: 200, right: 150 }} />
      <InnerLeftHormone style={{ position: 'absolute', bottom: 120, left: 0 }} />
      <SmallHormone style={{ position: 'absolute', bottom: 200, right: 0 }} />
    </Flex>
  );
};

export const DoctorsVisitsCount = ({ visitCount = 0, uniqueProviders = [] }) => {
  console.log({wmm: window.matchMedia('(min-width: 52em)').matches})
  return (
    <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', flexDirection: 'column', zIndex: 100 }}>
      <CText style={{ fontWeight: 700, textAlign: 'center', color: 'white' }}>
        You had&nbsp;
        <span>
          <Odometer delay={0} startNumber={0} to={visitCount} style={{ color: '#F8A294', fontSize: window.matchMedia('(min-width: 52em)').matches?70:42, fontWeight: 'bold', width: '100%' }} />
        </span>
        &nbsp; visit{(visitCount || 0)>1?'s':''} this year with&nbsp;
        <span>
          <Odometer delay={0} startNumber={0} to={uniqueProviders.length} style={{ color: '#F8A294', fontSize: window.matchMedia('(min-width: 52em)').matches?70:42, fontWeight: 'bold', width: '100%' }} />
        </span>
        &nbsp; {(uniqueProviders.length || 0)>1?'different providers' : 'provider'}
      </CText>
      <Flex mt={4} flexDirection="row-reverse">
        {uniqueProviders.reverse().map((x, i) => {
          return (
            <Avatar key={`avt${x.id}-${i}`}>
              <Img src={x.picture} alt="" />
            </Avatar>
          );
        })}
      </Flex>
    </Flex>
  );
};
