import React from 'react';
import { withRouter } from 'react-router';
import { Button, Card, Heading, Flex, Text } from 'rebass';

const SubscriptionCancelled = ({ title, description, history }) => {
  return (
    <Flex flexDirection="column">
      <Card
        style={{
          flexDirection: 'column',
          textAlign: 'center',
          padding: '32px',
        }}
      >
        <Heading style={{ maxWidth: '650px', margin: '0 auto' }}>
          {title}
        </Heading>
        {!!description && <Text mt={4}>{description}</Text>}
        <Button
          style={{ marginTop: '28px' }}
          variant="primary"
          onClick={() => {
            history.push('/');
          }}
        >
          continue
        </Button>
      </Card>
    </Flex>
  );
};

export default withRouter(SubscriptionCancelled);
