import React from 'react';
import { Flex, Button, Text } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import Imge from '../../../static/Character22.png';

const BlueHeading = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #354e79;
`;

const WhiteBullet = styled(Flex)`
  background: #ffffff;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 20px;
  color: #354e79;
  justify-content: center;
  align-items: center;
`;

const Eligible = ({ onSubmit }) => {
  return (
    <Flex flex={1} flexDirection="column" p="16px">
      <img src={Imge} alt="" style={{ width: '60%', margin: '0 auto', maxWidth: '332px' }} />
      <Flex flex={1} flexDirection="column" mt={4}>
        <BlueHeading mb={3}>
        We make getting high-quality hypothyroid care simple. Here’s how it works:
        </BlueHeading>

        <Flex flexDirection="row" alignItems="center" mb={3}>
          <WhiteBullet>1</WhiteBullet>
          <Text style={{ flex: 1 }}>Answer some simple medical questions. This process should take 5-10 minutes.</Text>
        </Flex>

        <Flex flexDirection="row" alignItems="center" mb={3}>
          <WhiteBullet>2</WhiteBullet>
          <Text style={{ flex: 1 }}>
          Get a personalized recommendation based on your answers and where you are in your thyroid journey.
          </Text>
        </Flex>

        <Flex flexDirection="row" alignItems="center">
          <WhiteBullet>3</WhiteBullet>
          <Text style={{ flex: 1 }}>Feel better. Whether it is our at-home test, an in-depth video visit, or just a quick and easy Rx refill, in 5-10 minutes we’re going to get you on the path to feeling your best.</Text>
        </Flex>
      </Flex>

      <Button
        variant="primary"
        onClick={onSubmit}
        style={{ borderRadius: '20px' }}
        mt={4}
      >
        Continue
      </Button>
      <Text fontWeight="300" fontSize="14px" mt="24px">
        By clicking "Continue" above, I agree to the{' '}<a href="https://www.palomahealth.com/legal-doc/consent-to-telehealth" target="_blank">Telehealth Informed
        Consent</a>{' '}and acknowledge the <a href="https://www.palomahealth.com/legal-doc/hipaa-privacy-policy" target="_blank">Notice of Privacy Practices</a>
      </Text>
    </Flex>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(Eligible);
// export default withWizard(Single);
