import React, { useState } from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { loadStripe } from '@stripe/stripe-js';
import Intro from './sections/Intro';
import Diets from './sections/Diets';
import Essentials from './sections/Essentials';
import withSession from '../../lib/withSession';
import SelectMembership from './selectMembership';
import { Mutation } from 'react-apollo';
import { B2B_ORDER_VITAMINS, CREATE_SESSION_VITAMINS_CHECKOUT_SESSION, CREATE_SESSION_VITAMINS_SUBSCRIPTIONS_CHECKOUT_SESSION } from '../../graphql';
import withSubscriptions from '../../lib/withSubscriptions';
import IntroB2B from './sections/IntroB2B';
import Address from './sections/Address';
import { withToastManager } from 'react-toast-notifications';
import VitaminsOrderConfirmationB2B from './VitaminsOrderConfirmationB2B';

const Container = styled(Box)`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 0;
  @media screen and (max-width: 480px) {
    max-width: 100vw;
    padding-left: 6px;
    padding-right: 6px;
  }
`;
const OrderB2B = ({ toastManager }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [address, setAddress] = useState();
  if (currentStep === 0) {
    return (
      <Container>
        <IntroB2B
          order={() => {
            setCurrentStep(1);
          }}
        />
      </Container>
    );
  } else if (currentStep === 1) {
    return (
      <Mutation
        mutation={B2B_ORDER_VITAMINS}
        onCompleted={async (res) => {
          if (res  && !!res.b2bOrderVitamins && !!res.b2bOrderVitamins.ok) {
            setCurrentStep(2);
          } else {
            toastManager.add('Unable to order vitamins', {
              appearance: 'error',
              autoDismissTimeout: 10000,
            });
          }
        }}
      >
        {(mutate, { loading }) => (
          <>
            <Container>
              <Address
              isSubmiting={!!loading}
                onSubmit={(address) => {
                  setAddress({ ...address });
                  mutate({
                    variables: {
                      address: {
                        street1: address.street1,
                        street2: address.street2 || '',
                        city: address.city,
                        state: address.state,
                        zip: address.zip,
                      },
                    },
                  });
                  setCurrentStep(1);
                }}
              />
            </Container>
          </>
        )}
      </Mutation>
    );
  } else if (currentStep === 2) {
    return (
      <Container>
        <VitaminsOrderConfirmationB2B address={address} />
      </Container>
    );
  }
};

export default withToastManager(OrderB2B);
