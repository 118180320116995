import { isToggleActive } from "../components/featureToggle/toggles"

export const getMemberVisitPrice = () => {
  if(isToggleActive('NEW_PRICING_60')){
    return '60'
  }
  return '42'
}
export const getMemberVisitPriceNumber = () => {
  if(isToggleActive('NEW_PRICING_60')){
    return 60
  }
  return 42
}