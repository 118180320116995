import styled from 'styled-components';
import { Box } from 'rebass';

const Spinner = styled(Box)`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #344f79;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;