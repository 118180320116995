import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { OPERATORS as FILTER_OPERATORS, PROPERTIES } from './FilterEdit';
import { CostIcon } from './CostIcon';
import { AthenaSVG, DbSVG, StripeSVG } from './icons';
import { queryMatch } from '../../utils/filterBannerQueryMatcher';

const BtnEditActionMini = styled(Button)`
  flex: initial;
  opacity: 0.5;
  width: fit-content;
  border: 0px solid #344f79;
  padding: 2px;
  font-size: 12px;
  border-radius: 2px;
  &:hover {
    opacity: 1;
  }
`;

export const FilterEntry = ({ data, editFilter, onDelete, selectedTestUser, athenaIdsFiltered }) => {
  const { property, filter, value } = data;
  const [isHovered, setIsHovered] = useState();
  const [originalProperty, setOriginalProperty] = useState(PROPERTIES.find((x) => x.value === property.value));
  const [testStatus, setTestStatus] = useState(null);

  useEffect(
    () => {
      checkTestUser();
    },
    [selectedTestUser]
  );

  useEffect(() => {
    checkTestUser();
  }, []);

  const costConverter = () => {
    if ((property.value || '').indexOf('db.') === 0) return 0;
    if ((property.value || '').indexOf('athena.') === 0) return 1;
    if ((property.value || '').indexOf('stripe.') === 0) return 2;
  };

  const convertFiltering = () => {
    if (!filter) return '';

    let op;

    Object.keys(FILTER_OPERATORS).forEach(function(key, index) {
      let a = FILTER_OPERATORS[key].find((xx) => xx.value === filter);
      if (!!a) {
        op = a.label;
      }
    });

    return op || '';
  };

  const isList = () => {
    if (!filter) return '';
    let res = false;
    Object.keys(FILTER_OPERATORS).forEach(function(key, index) {
      let a = FILTER_OPERATORS[key].find((xx) => xx.value === filter);
      if (!!a && (a.value === 'in' || a.value === '!in')) {
        res = true;
      }
    });

    return res;
  };

  const getIcon = () => {
    if (!property.value) return null;

    if (property.value.indexOf('db.') === 0) return <DbSVG />;
    if (property.value.indexOf('athena.') === 0) return <AthenaSVG />;
    if (property.value.indexOf('stripe.') === 0) return <StripeSVG />;

    return null;
  };

  const getDateElement = () => {
    if (originalProperty.inputType !== 'date') return null;

    if (['lastN', 'atLeastN', 'inNextN', 'dayInNextN', 'dayInLastN'].indexOf(filter) > -1) {
      // date input + dropdown select unit
      // const v = JSON.parse(value || '');
      return (
        <Flex flex={1}>
          <Text fontSize={'13px'} color={'#869ba7'} mr={1}>
            {(value || {}).value}
          </Text>
          <Text fontSize={'13px'} color={'#869ba7'} mr={1}>
            {(value || {}).unit}
          </Text>

          {filter === 'atLeastN' && (
            <Text fontSize={'13px'} color={'#869ba7'} mr={1}>
              ago
            </Text>
          )}
          <Box flex={1} />
        </Flex>
      );
    }
    return (
      <Text fontSize={'13px'} color={'#869ba7'} flex={1}>
        {(value || '').toString()}
      </Text>
    );
  };

  const checkTestUser = () => {
    if (!selectedTestUser) {
      setTestStatus(null);
      return;
    }

    const filter = {
      datatype: 'content',
      filter: data.filter,
      property,
      value: data.value,
    };

    const res = queryMatch(filter, selectedTestUser);
    if (res) {
      setTestStatus('Pass');
    } else {
      setTestStatus('Fail');
    }
  };

  // let islist = isList();
  // console.log({islist})
  return (
    <Box
      mt={3}
      style={{
        padding: '8px 16px',
        border: `1px solid ${!testStatus ? '#d8e1e6' : testStatus === 'Pass' ? 'green' : 'orange'}`,
        borderRadius: '18px',
        background: !testStatus ? 'transparent' : testStatus === 'Pass' ? 'rgba(0,128,0,0.05)' : 'rgba(255,165,0,0.05)',
        opacity: !!athenaIdsFiltered && athenaIdsFiltered.length > 0 ? ((originalProperty || {}).inputType === 'stringAthenaId' ? 1 : 0.3) : testStatus === 'Fail' ? 0.65 : 1,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={(e) => setIsHovered(false)}
      onMouseMove={(e) => setIsHovered(true)}
    >
      <Flex>
        {getIcon()}
        <Text fontSize={'13px'} mr={1} ml={2}>
          Select Patients where{' '}
        </Text>
        <Text fontSize={'13px'} mr={1} color={'#869ba7'}>
          {property.label}{' '}
        </Text>
        <Text fontSize={'13px'} mr={1} fontWeight={'500'}>
          {convertFiltering()}{' '}
        </Text>
        {(originalProperty || {}).inputType !== 'date' ? (
          <>
            {!isList() ? (
              <Text fontSize={'13px'} color={'#869ba7'} flex={1}>
                {(value || '').toString()}
              </Text>
            ) : (
              <Flex flexWrap={'wrap'} style={{ gap: '4px' }} flex={1}>
                {value.split(';').map((x) => (
                  <Box p={'2px 6px'} fontSize={'13px'} backgroundColor={'#fbfbfb'} style={{ border: '1px solid #ddd', background: '#fdfdfd', borderRadius: '4px', color: '#869ba7' }}>
                    {x}
                  </Box>
                ))}
              </Flex>
            )}
          </>
        ) : (
          getDateElement()
        )}

        <Flex justifyContent={'flex-end'} alignItems={'center'}>
          <CostIcon cost={costConverter()} />
          {isHovered && (
            <>
              <BtnEditActionMini variant={'outline'} onClick={() => editFilter(data.id, data)}>
                <FontAwesomeIcon icon={faEdit} />
              </BtnEditActionMini>
              <BtnEditActionMini variant={'outline'} ml={2} onClick={() => onDelete(data.id)}>
                <FontAwesomeIcon icon={faTrash} />
              </BtnEditActionMini>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
