import React, { useState } from 'react';
import { Box, Card, Flex, Heading, Button, Text } from 'rebass';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import TextInput from '../../components/TextInput';
import { MaskInput } from '../../components/MaskInput';
import withSession from '../../lib/withSession';
import { compose, Mutation, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { ADMIN_GET_HEALTH_COACHES_LIST, UPDATE_HEALTHCOACH_USER } from '../../graphql';
import Modal from 'styled-react-modal';
import { SpecialModalBackground } from './../adminReorderPhysicianConvo/shared';

const CardBox = styled(Card)`
  width: 100%;
  padding: 32px;
`;
const Container = styled(Box)`
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
  padding-top: 20px;
`;

const phoneRegExp = /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

const schema = Yup.object().shape({
  displayName: Yup.string().required('Required'),
  phone: Yup.string()
    .trim()
    .required('Required')
    .matches(phoneRegExp, 'Phone number is not valid'),
});

const EditNewHealthCoachUser = ({ toastManager, selectedCalendlyUser, onClose }) => {
  return (
    <Flex flexDirection="column" style={{overflowY: 'auto'}}>
      <Box>
        <Heading textAlign="left" mb={4}>
          Edit Health Coach User
        </Heading>
        <Mutation
          mutation={UPDATE_HEALTHCOACH_USER}
          refetchQueries={[{ query: ADMIN_GET_HEALTH_COACHES_LIST }]}
          awaitRefetchQueries={true}
          onCompleted={(data) => {
            if (!data || !data.adminUpdateHealthCoachUser || !data.adminUpdateHealthCoachUser.ok) {
              toastManager.add(((data || {}).adminUpdateHealthCoachUser || {}).customError || 'An error occured', {
                appearance: 'error',
              });
              return;
            }
            toastManager.add('Health coach updated', {
              appearance: 'success',
            });
            onClose()
          }}
          onError={() => {
            toastManager.add('An error occured', {
              appearance: 'error',
            });
          }}
        >
          {(create, { loading }) => (
            <>
              <Formik
                initialValues={{
                  helpscoutId: selectedCalendlyUser.helpscoutId.toString(),
                  phone: selectedCalendlyUser.phone,
                  isActive: selectedCalendlyUser.isActive,
                  displayName: selectedCalendlyUser.displayName,
                  calendlyUserId: selectedCalendlyUser.calendlyUserId,
                  calendlyUserEmail: selectedCalendlyUser.calendlyUserEmail,
                }}
                isInitialValid={({ initialValues }) =>
                  schema.isValidSync(initialValues)
                }
                validationSchema={schema}
                onSubmit={(values, actions) => {
                  const hid = parseInt(values.helpscoutId.toString(), 10);
                  console.log({
                    hid,
                    t: typeof hid,
                  });
                  create({
                    variables: {
                      id: selectedCalendlyUser.id,
                      helpscoutId: hid,
                      phone: values.phone,
                      displayName: values.displayName,
                      mentionTag: values.mentionTag,
                      calendlyUserId: values.calendlyUserId,
                      calendlyUserEmail: values.calendlyUserEmail,
                    },
                  });
                }}
                render={({ touched, errors, isValid, values, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
                  <Form>
                    <Flex>
                      <Flex flexDirection={'column'} ml={3} flex={1}>
                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Display name:
                        </Text>
                        <FormField mb={4} error={touched.email && errors.email}>
                          <TextInput placeholder="Display Name" name="displayName" />
                        </FormField>
                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Phone:
                        </Text>
                        <FormField mb={4} error={touched.phone && errors.phone}>
                          <MaskInput name="phone" placeholder="Phone Number" mask="999-999-9999" value={values.phone} onChange={handleChange} onBlur={handleBlur} />
                        </FormField>
                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Helpscout ID:
                        </Text>
                        <FormField mb={3} error={touched.helpscoutId && errors.helpscoutId}>
                          <TextInput placeholder="Helpscout ID" type="number" name="helpscoutId" />
                        </FormField>

                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Calendly User ID:
                        </Text>
                        <FormField mb={3} error={touched.calendlyUserId && errors.calendlyUserId}>
                          <TextInput name="calendlyUserId" disabled />
                        </FormField>
                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Calendly User Email:
                        </Text>
                        <FormField mb={3} error={touched.calendlyUserEmail && errors.calendlyUserEmail}>
                          <TextInput name="calendlyUserEmail" disabled />
                        </FormField>

                        <Flex alignItems="center" justifyContent={'center'} flexDirection="row" mt={4}>
                          <Button mb={4} variant="pink" width="200px" type="button" onClick={() => onClose()}>
                            Cancel
                          </Button>
                          <Button mb={4} disabled={!isValid || !!loading} variant="primary" width="200px" type="submit">
                            Update
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Form>
                )}
              />
            </>
          )}
        </Mutation>
      </Box>
    </Flex>
  );
};

export default compose(
  withApollo,
  withSession,
  withToastManager
)(EditNewHealthCoachUser);
