
import React from 'react';
import { withWizard } from 'react-albus';
import { Mutation, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { Button, Card, Heading, Text, Flex } from 'rebass';
import styled from 'styled-components';
import PleaseWait from '../../../../../components/PleaseWait';
import { APPLY_CANCELLATION_COUPON, GET_MEMBERSHIPS } from '../../../../../graphql';
import plant from '../../../../../static/plant.png';
import withAnalytics from '../../../../../lib/withAnalytics';

const A = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: #44a5ff;
  text-decoration: underline;
  margin: 36px auto 0 auto;
  cursor: pointer;
`;

const OfferWrapper = styled(Flex)`
  border-radius: 10px;
  background: #d9e6f8;
  flex-direction: column;
  padding: 30px 10px;
  margin-bottom: 18px;
`;

const Content = styled(Flex)`
  flex-direction: row;
`;

const OfferTextWrapper = styled(Flex)`
  flex-direction: column;
  margin-left: 16px;
  text-align: left;
`;

const PoorExperienceDoctor = ({ subscriptionId, wizard, history, subscriptionType, confirm, flow, reason, analytics  }) => {
  let title = 'Explore a Better Fit: Try a New Doctor on Us!';
  let description1 =
    `We're sorry to hear your recent experience didn't meet your expectations. At Paloma Health, your comfort and satisfaction are paramount. That's why we offer our Doctor Fit Guarantee - a chance to connect with a different doctor for a second consultation, absolutely free.`;
  let description2 = `Finding the right doctor is key to your health journey, and we're committed to ensuring you find the perfect match`;
  let buttonText = `Schedule Your Complimentary Visit`;
  
  return (
    <Query query={GET_MEMBERSHIPS} fetchPolicy="network-only">
      {({ loading, data, error }) => {
        if (loading) return <PleaseWait style={{ position: 'absolute', left: '50%', top: '50%' }} />;
        const vitaminsData = data.getMemberships.memberships.find((x) => x.subscriptionTarget === 'SUBSCRIPTION_VITAMINS');
        let price = '';
        if (vitaminsData && vitaminsData.price) {
          price = ((vitaminsData.price / 100) * 0.75).toFixed(2);
        }
        return (
          <Mutation 
            mutation={APPLY_CANCELLATION_COUPON}
            refetchQueries={[{ query: GET_MEMBERSHIPS }]}
            awaitRefetchQueries
            variables={{
              subscriptionId,
              subscriptionType,
              reason
            }}
            onCompleted={() => {
              history.push({
                pathname: '/subscription-updated',
                state: {
                  title: 'Your free visit awaits!',
                  text: 'As a thank-you for staying with us, enjoy a complimentary provider visit on us. Details of your free visit will be sent to your email soon.',
                },
              });
            }}
          >
            {(applyCoupon, { loading }) => {
              return loading ? (
                <PleaseWait />
              ) : (
                <Flex flexDirection="column">
                  <Card
                    style={{
                      flexDirection: 'column',
                      textAlign: 'center',
                      padding: '32px',
                    }}
                  >
                    <Heading mb={4}>{title}</Heading>

                    <Flex
                      flexDirection="column"
                      style={{
                        margin: '0 auto',
                        display: 'table',
                      }}
                    >
                      <OfferWrapper >
                        <Content>
                          {/* <img src={plant} alt="" /> */}

                          <OfferTextWrapper>
                            <Text fontWeight="600">{description1}</Text>
                            <br />
                            <Text fontWeight="300">{description2}</Text>
                          </OfferTextWrapper>
                        </Content>
                        
                        <Flex mt={4} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                          <Button variant="primary" minWidth="150px" 
                            onClick={() => {
                              analytics.track('membership retained', {
                                coupon: 'Free visit',
                                reason: 'Poor doctor experience'
                              });
                              applyCoupon();
                            }}>
                            {buttonText}
                          </Button>

                          <A
                            onClick={() => {
                              confirm(wizard);
                            }}
                            style={{
                              marginTop: '24px',
                              padding: '6px',
                            }}
                          >
                            Cancel Membership
                          </A>
                        </Flex>
                      </OfferWrapper>
                    </Flex>
                  </Card>
                  <A
                    onClick={() => {
                      history.push('/');
                    }}
                  >
                    Back to Dashboard
                  </A>
                </Flex>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default withRouter(withWizard(withAnalytics(PoorExperienceDoctor)));
