import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';

const CollapsibleNavLinkSectionText = styled(Text)`
  color: #344f79;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  padding: 8px 14px;
  cursor: pointer;

  @media screen and (max-width: 52em) {
    margin-bottom: 0;
  }
  @media screen and (max-width: 440px) {
    color: #344f79;
  }
`;

const CollapsibleNavLinkSection = ({ defaultExpanded, text, children }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Flex flexDirection={'column'} mt='16px'>
      <Flex flexDirection={'row'} onClick={toggleCollapse} alignItems="center" style={{ marginLeft: '46px' }}>
        <svg
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: isExpanded ? 'rotate(0)' : 'rotate(-90deg)',
          }}
        >
          <path d="M5 8L0.669872 0.5L9.33013 0.5L5 8Z" fill="#344F7A" />
        </svg>

        <CollapsibleNavLinkSectionText ml={2}>{text}</CollapsibleNavLinkSectionText>
      </Flex>

      {isExpanded && <Flex flexDirection={'column'}>{children}</Flex>}
    </Flex>
  );
};

CollapsibleNavLinkSection.propTypes = {
  defaultExpanded: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

CollapsibleNavLinkSection.defaultProps = {
  defaultExpanded: true,
};

export default CollapsibleNavLinkSection;
