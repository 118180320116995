import React, { Fragment } from 'react';
import { Flex, Button, Text } from 'rebass';
import { Formik, Form } from 'formik';

import { TextInput } from '../../../../../components/TextInput';
import { FormField } from '../../../../../components/FormField';

export const MedicationDetailsContent = ({
  dose,
  frequency,
  onSave,
  onDelete,
  dosePlaceholder = 'Dose',
}) => {
  return (
    <Flex flexDirection="column">
      <Text fontSize="20px" fontWeight="600" ml="16px">
        Edit medication details
      </Text>

      <Formik
        initialValues={{
          dose,
          frequency,
        }}
        enableReinitialize={true}
        isInitialValid={(aaa, bbb) => {}}
        onSubmit={(values, actions) => {
          onSave({
            dose: values.dose,
            frequency: values.frequency,
          });
        }}
        render={({ isValid, errors, touched, values }) => {
          return (
            <Form>
              <Flex my={3} flex={1} flexDirection="column">
                <Fragment>
                  <FormField
                    width={1}
                    px={3}
                    mb={3}
                    error={touched['dose'] && errors['dose']}
                    alignSelf="center"
                  >
                    <TextInput
                      name={'dose'}
                      placeholder={dosePlaceholder}
                      type={'text'}
                    />
                  </FormField>
                  <FormField
                    width={1}
                    px={3}
                    mb={3}
                    error={touched['dose'] && errors['dose']}
                    alignSelf="center"
                  >
                    <TextInput
                      name={'frequency'}
                      placeholder={'Frequency (daily, 2x a day, ...)'}
                      type={'text'}
                    />
                  </FormField>
                </Fragment>
              </Flex>

              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                mx={[3, 3, 0]}
                my={[2, 3]}
              >
                <Button
                  variant="primary"
                  width={[1, 1 / 2]}
                  type="submit"
                >
                  Save
                </Button>
              </Flex>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                mx={[3, 3, 0]}
                my={[2, 3]}
              >
                <Button
                  onClick={onDelete}
                  variant="outline"
                  width={[1, 1 / 2]}
                  type="button"
                >
                  Remove
                </Button>
              </Flex>
            </Form>
          );
        }}
      />
    </Flex>
  );
};
