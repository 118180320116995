import { useEffect } from 'react';
import { usePersistFn } from '../advanced/usePersistFn';

const useUnmount = (callback=()=>{}) => {
  const fnPersist = usePersistFn(callback);

  useEffect(
    () => () => {
      if (typeof fnPersist === 'function') {
        fnPersist();
      }
    },
    [fnPersist],
  );
};

export default useUnmount;
