import * as Yup from 'yup';
import { intakeCommonTrunks } from './intakesCommonTrunk';

export const medicalHistoryMenopause = [
  {
    id: 'vitals',
    type: 'HeightWeight',
    endpoint: 'socialHistory',
    prompt: 'Please enter your height and weight',
    defaults: { LOCAL_121: '', LOCAL_122: '' },
    schema: Yup.object().shape({
      LOCAL_121: Yup.string().required('Required'),
      LOCAL_122: Yup.string().required('Required'),
    }),
    options: [
      { questionId: 'LOCAL_121', placeholder: `Height (e.g. 6'3")` },
      {
        questionId: 'LOCAL_122',
        placeholder: 'Weight (pounds)',
        // inputType: 'number',
      },
    ],
  },
  {
    id: 'hormonalBirthControl',
    type: 'single',
    endpoint: 'obgyn',
    prompt: 'Are you currently taking (or have stopped taking within the last 90 days) any form of hormonal birth control?',
    options: [
      {
        answer: 'Y',
        value: 'Yes',
        nextQuestion: 'disclaimerhormonalBirthControl',
      },
      {
        answer: 'N',
        value: 'No',
        nextQuestion: 'hormonalTreatmentInterest',
      },
    ],
  },
  {
    id: 'disclaimerhormonalBirthControl',
    type: 'single',
    endpoint: 'obgyn',
    prompt: `Unless you are willing to discontinue your birth control we are limited with what we can check with your labs and what we can prescribe`,
    options: [
      {
        answer: 'Y',
        value: 'Willing to stop',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_128' : 'LOCAL_145',
      },
      {
        answer: 'N',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_128' : 'LOCAL_145',
        value: 'Not willing to stop', // TODO: What supposed to do here?
      },
    ],
  },
  {
    id: 'currentHormonalBirthControl',
    type: 'input',
    endpoint: 'obgyn',
    // onlyFirstTime: true,
    prompt: 'What type of birth control hormonal treatment are you currently taking or have recently stopped taking?',
    defaults: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? { LOCAL_147: '' } : { LOCAL_562: '' },
    prefix: '',
    answer: 'Y',
    skip: 'hormonalTreatmentInterest',
    options: [
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_147' : 'LOCAL_562',
        placeholder: 'Indicate birth control hormonal treatment',
      },
    ],
  },
  {
    id: 'hormonalTreatmentInterest',
    type: 'input',
    endpoint: 'obgyn',
    skip: 'hormonalTreatmentNotBirthControl',
    skipText: `I don't know`,
    prompt: 'What type of form of hormonal prescriptive therapy are you interested in obtaining?',
    defaults: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? { LOCAL_130: '' } : { LOCAL_144: '' },
    prefix: '',
    answer: 'Y',
    options: [
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_130' : 'LOCAL_144',
        placeholder: 'Indicate interested hormonal prescriptive therapy',
      },
    ],
  },

  {
    id: 'hormonalTreatmentNotBirthControl',
    type: 'single',
    endpoint: 'obgyn',
    skip: 'vitals',
    prompt: 'Are you currently taking (or have stopped taking within the last 90 days) any supplemental/prescribed form of hormone treatment other than birth control?',
    defaults: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? { LOCAL_143: '' } : { LOCAL_161: '' },
    options: [
      {
        answer: 'Y',
        value: 'Yes',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_143' : 'LOCAL_161',
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_143' : 'LOCAL_161',
        answer: 'N',
        value: 'No',
        nextQuestion: 'ssri',
      },
    ],
  },

  // Create Multiselect to note type of screen
  // What type of hormone treatment are you currently taking or have recently stopped taking?
  {
    id: 'currentHormonalTreatmentNotBirthControl',
    type: 'multiSelect',
    endpoint: 'obgyn',
    questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_143' : 'LOCAL_161',
    prompt: 'What type of hormone treatment are you currently taking or have recently stopped taking?',
    options: [
      // {
      //   answer: 'Oral contraceptive pills',
      // },
      // {
      //   answer: 'Implanted contraception (e.g. Nexplanon)',
      // },
      // {
      //   answer: 'Injectable contraception (e.g. Depo-Provera)',
      // },
      // {
      //   answer: 'Intrauterine device (IUD) (e.g. Mirena)',
      // },
      {
        answer: 'Transdermal (patch) contraception',
      },
      {
        answer: 'Oral hormone therapy',
      },
      {
        answer: 'Topical hormone therapy (e.g. cream/gel/spray/patch)',
      },
      {
        answer: 'Vaginal hormone therapy (e.g. ring/cream/tablet)',
      },
    ],
  },

  {
    id: 'ssri',
    type: 'single',
    endpoint: 'obgyn',
    prompt:
      'Are you currently taking any Selective Serotonin Reuptake Inhibitors (SSRIs), such as escitalopram (Lexapro), sertraline (Zoloft), paroxetine (Paxil), fluoxetine (Prozac), or venlafaxine (Effexor)?',
    options: [
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_145' : 'LOCAL_163',
        prefix: '',
        answer: 'Y',
        value: 'Yes',
        placeholder: 'List your SSRIs here',
      },
      { questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_145' : 'LOCAL_163', answer: 'N', value: 'No' },
    ],
  },
  {
    id: 'hypomed',
    skip: 'medications',
    type: 'medicationPick',
    endpoint: 'medications',
    title: 'Hypothyroidism Medications',
    prompt: 'Please list your hypothyroidism medication',
    other: { questionId: '367151' },
    isInputOnly: false,
    options: [
      {
        value: 'Unithroid',
        hasDosage: false,
        questionId: 313430,
      },
      {
        value: 'Unithroid 100 mcg tablet',
        hasDosage: true,
        questionId: 248353,
      },
      {
        value: 'Unithroid 112 mcg tablet',
        hasDosage: true,
        questionId: 239279,
      },
      {
        value: 'Unithroid 125 mcg tablet',
        hasDosage: true,
        questionId: 239194,
      },
      {
        value: 'Unithroid 137 mcg tablet',
        hasDosage: true,
        questionId: 348963,
      },
      {
        value: 'Unithroid 150 mcg tablet',
        hasDosage: true,
        questionId: 246691,
      },
      {
        value: 'Unithroid 175 mcg tablet',
        hasDosage: true,
        questionId: 252038,
      },
      {
        value: 'Unithroid 200 mcg tablet',
        hasDosage: true,
        questionId: 233390,
      },
      {
        value: 'Unithroid 25 mcg tablet',
        hasDosage: true,
        questionId: 251346,
      },
      {
        value: 'Unithroid 300 mcg tablet',
        hasDosage: true,
        questionId: 245346,
      },
      {
        value: 'Unithroid 50 mcg tablet',
        hasDosage: true,
        questionId: 244067,
      },
      {
        value: 'Unithroid 75 mcg tablet',
        hasDosage: true,
        questionId: 253346,
      },
      {
        value: 'Unithroid 88 mcg tablet',
        hasDosage: true,
        questionId: 226774,
      },
      {
        value: 'Thyrogen',
        hasDosage: false,
        questionId: 310408,
      },
      {
        value: 'Thyrogen 0.9 mg intramuscular solution',
        hasDosage: true,
        questionId: 396360,
      },
      {
        value: 'liothyronine',
        hasDosage: false,
        questionId: 302000,
      },
      {
        value: 'liothyronine (bulk)',
        hasDosage: true,
        questionId: 349888,
      },
      {
        value: 'liothyronine (bulk) 1 mg/gram powder',
        hasDosage: true,
        questionId: 361005,
      },
      {
        value: 'liothyronine (bulk) 100 % powder',
        hasDosage: true,
        questionId: 227807,
      },
      {
        value: 'liothyronine 10 mcg/mL intravenous solution',
        hasDosage: true,
        questionId: 230025,
      },
      {
        value: 'liothyronine 25 mcg tablet',
        hasDosage: true,
        questionId: 230116,
      },
      {
        value: 'liothyronine 5 mcg tablet',
        hasDosage: true,
        questionId: 236739,
      },
      {
        value: 'liothyronine 50 mcg tablet',
        hasDosage: true,
        questionId: 246932,
      },
      {
        value: 'LevoxyL',
        hasDosage: false,
        questionId: 300534,
      },
      {
        value: 'LevoxyL 100 mcg tablet',
        hasDosage: true,
        questionId: 231895,
      },
      {
        value: 'LevoxyL 112 mcg tablet',
        hasDosage: true,
        questionId: 252514,
      },
      {
        value: 'LevoxyL 125 mcg tablet',
        hasDosage: true,
        questionId: 227013,
      },
      {
        value: 'LevoxyL 137 mcg tablet',
        hasDosage: true,
        questionId: 257592,
      },
      {
        value: 'LevoxyL 150 mcg tablet',
        hasDosage: true,
        questionId: 225651,
      },
      {
        value: 'LevoxyL 175 mcg tablet',
        hasDosage: true,
        questionId: 233107,
      },
      {
        value: 'LevoxyL 200 mcg tablet',
        hasDosage: true,
        questionId: 229394,
      },
      {
        value: 'LevoxyL 25 mcg tablet',
        hasDosage: true,
        questionId: 239555,
      },
      {
        value: 'LevoxyL 50 mcg tablet',
        hasDosage: true,
        questionId: 250009,
      },
      {
        value: 'LevoxyL 75 mcg tablet',
        hasDosage: true,
        questionId: 233953,
      },
      {
        value: 'LevoxyL 88 mcg tablet',
        hasDosage: true,
        questionId: 226499,
      },
      {
        value: 'Levo-T',
        hasDosage: false,
        questionId: 307279,
      },
      {
        value: 'Levo-T 100 mcg tablet',
        hasDosage: true,
        questionId: 382302,
      },
      {
        value: 'Levo-T 112 mcg tablet',
        hasDosage: true,
        questionId: 382282,
      },
      {
        value: 'Levo-T 125 mcg tablet',
        hasDosage: true,
        questionId: 249338,
      },
      {
        value: 'Levo-T 137 mcg tablet',
        hasDosage: true,
        questionId: 382291,
      },
      {
        value: 'Levo-T 150 mcg tablet',
        hasDosage: true,
        questionId: 382281,
      },
      {
        value: 'Levo-T 175 mcg tablet',
        hasDosage: true,
        questionId: 382295,
      },
      {
        value: 'Levo-T 200 mcg tablet',
        hasDosage: true,
        questionId: 382307,
      },
      {
        value: 'Levo-T 25 mcg tablet',
        hasDosage: true,
        questionId: 382300,
      },
      {
        value: 'Levo-T 300 mcg tablet',
        hasDosage: true,
        questionId: 382289,
      },
      {
        value: 'Levo-T 50 mcg tablet',
        hasDosage: true,
        questionId: 382292,
      },
      {
        value: 'Levo-T 75 mcg tablet',
        hasDosage: true,
        questionId: 382290,
      },
      {
        value: 'Levo-T 88 mcg tablet',
        hasDosage: true,
        questionId: 382303,
      },
      {
        value: 'Euthyrox',
        hasDosage: false,
        questionId: 299187,
      },
      {
        value: 'Euthyrox 100 mcg tablet',
        hasDosage: true,
        questionId: 237400,
      },
      {
        value: 'Euthyrox 112 mcg tablet',
        hasDosage: true,
        questionId: 241991,
      },
      {
        value: 'Euthyrox 125 mcg tablet',
        hasDosage: true,
        questionId: 241213,
      },
      {
        value: 'Euthyrox 137 mcg tablet',
        hasDosage: true,
        questionId: 389367,
      },
      {
        value: 'Euthyrox 150 mcg tablet',
        hasDosage: true,
        questionId: 243351,
      },
      {
        value: 'Euthyrox 175 mcg tablet',
        hasDosage: true,
        questionId: 225746,
      },
      {
        value: 'Euthyrox 200 mcg tablet',
        hasDosage: true,
        questionId: 226297,
      },
      {
        value: 'Euthyrox 25 mcg tablet',
        hasDosage: true,
        questionId: 239811,
      },
      {
        value: 'Euthyrox 50 mcg tablet',
        hasDosage: true,
        questionId: 249702,
      },
      {
        value: 'Euthyrox 75 mcg tablet',
        hasDosage: true,
        questionId: 242062,
      },
      {
        value: 'Euthyrox 88 mcg tablet',
        hasDosage: true,
        questionId: 244029,
      },
      {
        value: 'Synthroid',
        hasDosage: false,
        questionId: 301371,
      },
      {
        value: 'Synthroid 100 mcg tablet',
        hasDosage: true,
        questionId: 231970,
      },
      {
        value: 'Synthroid 112 mcg tablet',
        hasDosage: true,
        questionId: 251383,
      },
      {
        value: 'Synthroid 125 mcg tablet',
        hasDosage: true,
        questionId: 231796,
      },
      {
        value: 'Synthroid 137 mcg tablet',
        hasDosage: true,
        questionId: 276415,
      },
      {
        value: 'Synthroid 150 mcg tablet',
        hasDosage: true,
        questionId: 230662,
      },
      {
        value: 'Synthroid 175 mcg tablet',
        hasDosage: true,
        questionId: 238520,
      },
      {
        value: 'Synthroid 200 mcg tablet',
        hasDosage: true,
        questionId: 230352,
      },
      {
        value: 'Synthroid 25 mcg tablet',
        hasDosage: true,
        questionId: 242725,
      },
      {
        value: 'Synthroid 300 mcg tablet',
        hasDosage: true,
        questionId: 248100,
      },
      {
        value: 'Synthroid 50 mcg tablet',
        hasDosage: true,
        questionId: 248284,
      },
      {
        value: 'Synthroid 75 mcg tablet',
        hasDosage: true,
        questionId: 247491,
      },
      {
        value: 'Synthroid 88 mcg tablet',
        hasDosage: true,
        questionId: 245881,
      },
      {
        value: 'Tirosint',
        hasDosage: false,
        questionId: 355544,
      },
      {
        value: 'Tirosint 100 mcg capsule',
        hasDosage: true,
        questionId: 355490,
      },
      {
        value: 'Tirosint 112 mcg capsule',
        hasDosage: true,
        questionId: 355501,
      },
      {
        value: 'Tirosint 125 mcg capsule',
        hasDosage: true,
        questionId: 355484,
      },
      {
        value: 'Tirosint 13 mcg capsule',
        hasDosage: true,
        questionId: 355475,
      },
      {
        value: 'Tirosint 137 mcg capsule',
        hasDosage: true,
        questionId: 355509,
      },
      {
        value: 'Tirosint 150 mcg capsule',
        hasDosage: true,
        questionId: 355503,
      },
      {
        value: 'Tirosint 175 mcg capsule',
        hasDosage: true,
        questionId: 389564,
      },
      {
        value: 'Tirosint 200 mcg capsule',
        hasDosage: true,
        questionId: 389561,
      },
      {
        value: 'Tirosint 25 mcg capsule',
        hasDosage: true,
        questionId: 355470,
      },
      {
        value: 'Tirosint-SoL 100 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389905,
      },
      {
        value: 'Tirosint-SoL 112 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389897,
      },
      {
        value: 'Tirosint-SoL 125 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389921,
      },
      {
        value: 'Tirosint-SoL 200 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389916,
      },
      {
        value: 'Tirosint-SoL 25 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389911,
      },
      {
        value: 'Tirosint 37.5 mcg capsule',
        hasDosage: true,
        questionId: 402011,
      },
      {
        value: 'Tirosint-SoL 37.5 mcg/mL oral solution',
        hasDosage: true,
        questionId: 397162,
      },
      {
        value: 'Tirosint 44 mcg capsule',
        hasDosage: true,
        questionId: 402019,
      },
      {
        value: 'Tirosint-SoL 44 mcg/mL oral solution',
        hasDosage: true,
        questionId: 397164,
      },
      {
        value: 'Tirosint 50 mcg capsule',
        hasDosage: true,
        questionId: 355467,
      },
      {
        value: 'Tirosint 62.5 mcg capsule',
        hasDosage: true,
        questionId: 402015,
      },
      {
        value: 'Tirosint-SoL 50 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389922,
      },
      {
        value: 'Tirosint-SoL 62.5 mcg/mL oral solution',
        hasDosage: true,
        questionId: 397169,
      },
      {
        value: 'Tirosint 75 mcg capsule',
        hasDosage: true,
        questionId: 355469,
      },
      {
        value: 'Tirosint-SoL 75 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389900,
      },
      {
        value: 'Tirosint 88 mcg capsule',
        hasDosage: true,
        questionId: 355496,
      },
      {
        value: 'Tirosint-SoL 88 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389910,
      },
      {
        value: 'Tirosint-SoL 13 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389902,
      },
      {
        value: 'Tirosint-SoL 137 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389899,
      },
      {
        value: 'Tirosint-SoL 150 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389901,
      },
      {
        value: 'Tirosint-SoL 175 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389903,
      },
      {
        value: 'Tirosint-SoL',
        hasDosage: true,
        questionId: 389925,
      },
      {
        value: 'levothyroxine',
        hasDosage: false,
        questionId: 296025,
      },
      {
        value: 'levothyroxine (bulk)',
        hasDosage: true,
        questionId: 347088,
      },
      {
        value: 'levothyroxine (bulk) 1 mg/gram powder',
        hasDosage: true,
        questionId: 362431,
      },
      {
        value: 'levothyroxine (bulk) 100 % powder',
        hasDosage: true,
        questionId: 237253,
      },
      {
        value: 'levothyroxine 100 mcg capsule',
        hasDosage: true,
        questionId: 355507,
      },
      {
        value: 'levothyroxine 100 mcg intravenous powder for solution',
        hasDosage: true,
        questionId: 362885,
      },
      {
        value: 'levothyroxine 100 mcg tablet',
        hasDosage: true,
        questionId: 239330,
      },
      {
        value: 'levothyroxine 100 mcg/mL intravenous solution',
        hasDosage: true,
        questionId: 390404,
      },
      {
        value: 'levothyroxine 100 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383551,
      },
      {
        value: 'levothyroxine 112 mcg capsule',
        hasDosage: true,
        questionId: 355495,
      },
      {
        value: 'levothyroxine 112 mcg tablet',
        hasDosage: true,
        questionId: 257160,
      },
      {
        value: 'levothyroxine 112 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383553,
      },
      {
        value: 'levothyroxine 125 mcg capsule',
        hasDosage: true,
        questionId: 355488,
      },
      {
        value: 'levothyroxine 125 mcg tablet',
        hasDosage: true,
        questionId: 244788,
      },
      {
        value: 'levothyroxine 20 mcg/mL intravenous solution',
        hasDosage: true,
        questionId: 390400,
      },
      {
        value: 'levothyroxine 20 mcg/mL oral solution',
        hasDosage: true,
        questionId: 395094,
      },
      {
        value: 'levothyroxine 200 mcg capsule',
        hasDosage: true,
        questionId: 389574,
      },
      {
        value: 'levothyroxine 200 mcg intravenous powder for solution',
        hasDosage: true,
        questionId: 241562,
      },
      {
        value: 'levothyroxine 200 mcg tablet',
        hasDosage: true,
        questionId: 250544,
      },
      {
        value: 'levothyroxine 200 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383570,
      },
      {
        value: 'levothyroxine 25 mcg capsule',
        hasDosage: true,
        questionId: 355476,
      },
      {
        value: 'levothyroxine 25 mcg tablet',
        hasDosage: true,
        questionId: 237942,
      },
      {
        value: 'levothyroxine 25 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383568,
      },
      {
        value: 'levothyroxine 30 mcg/mL oral solution',
        hasDosage: true,
        questionId: 399165,
      },
      {
        value: 'levothyroxine 300 mcg tablet',
        hasDosage: true,
        questionId: 230002,
      },
      {
        value: 'levothyroxine 37.5 mcg capsule',
        hasDosage: true,
        questionId: 402032,
      },
      {
        value: 'levothyroxine 37.5 mcg/mL oral solution',
        hasDosage: true,
        questionId: 397165,
      },
      {
        value: 'levothyroxine 40 mcg/mL intravenous solution',
        hasDosage: true,
        questionId: 390397,
      },
      {
        value: 'levothyroxine 44 mcg capsule',
        hasDosage: true,
        questionId: 402023,
      },
      {
        value: 'levothyroxine 44 mcg/mL oral solution',
        hasDosage: true,
        questionId: 397173,
      },
      {
        value: 'levothyroxine 50 mcg capsule',
        hasDosage: true,
        questionId: 355493,
      },
      {
        value: 'levothyroxine 50 mcg tablet',
        hasDosage: true,
        questionId: 243806,
      },
      {
        value: 'levothyroxine 50 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383550,
      },
      {
        value: 'levothyroxine 500 mcg intravenous powder for solution',
        hasDosage: true,
        questionId: 242200,
      },
      {
        value: 'levothyroxine 62.5 mcg capsule',
        hasDosage: true,
        questionId: 402012,
      },
      {
        value: 'levothyroxine 62.5 mcg/mL oral solution',
        hasDosage: true,
        questionId: 397154,
      },
      {
        value: 'levothyroxine 75 mcg capsule',
        hasDosage: true,
        questionId: 355468,
      },
      {
        value: 'levothyroxine 75 mcg tablet',
        hasDosage: true,
        questionId: 238501,
      },
      {
        value: 'levothyroxine 75 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383563,
      },
      {
        value: 'levothyroxine 88 mcg capsule',
        hasDosage: true,
        questionId: 355486,
      },
      {
        value: 'levothyroxine 88 mcg tablet',
        hasDosage: true,
        questionId: 240026,
      },
      {
        value: 'levothyroxine 88 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383579,
      },
      {
        value: 'levothyroxine 125 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383582,
      },
      {
        value: 'levothyroxine 13 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383573,
      },
      {
        value: 'levothyroxine 137 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383565,
      },
      {
        value: 'levothyroxine 150 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383558,
      },
      {
        value: 'levothyroxine 175 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383561,
      },
      {
        value: 'levothyroxine 137 mcg tablet',
        hasDosage: true,
        questionId: 226061,
      },
      {
        value: 'levothyroxine 150 mcg tablet',
        hasDosage: true,
        questionId: 246273,
      },
      {
        value: 'levothyroxine 175 mcg tablet',
        hasDosage: true,
        questionId: 225902,
      },
      {
        value: 'CytomeL',
        hasDosage: false,
        questionId: 301999,
      },
      {
        value: 'CytomeL 25 mcg tablet',
        hasDosage: true,
        questionId: 250263,
      },
      {
        value: 'CytomeL 5 mcg tablet',
        hasDosage: true,
        questionId: 237429,
      },
      {
        value: 'CytomeL 50 mcg tablet',
        hasDosage: true,
        questionId: 236623,
      },
      {
        value: 'Armour Thyroid',
        hasDosage: false,
        questionId: 298245,
      },
      {
        value: 'Armour Thyroid 120 mg tablet',
        hasDosage: true,
        questionId: 233637,
      },
      {
        value: 'Armour Thyroid 15 mg tablet',
        hasDosage: true,
        questionId: 239332,
      },
      {
        value: 'Armour Thyroid 180 mg tablet',
        hasDosage: true,
        questionId: 229937,
      },
      {
        value: 'Armour Thyroid 240 mg tablet',
        hasDosage: true,
        questionId: 250548,
      },
      {
        value: 'Armour Thyroid 30 mg tablet',
        hasDosage: true,
        questionId: 251437,
      },
      {
        value: 'Armour Thyroid 300 mg tablet',
        hasDosage: true,
        questionId: 255429,
      },
      {
        value: 'Armour Thyroid 60 mg tablet',
        hasDosage: true,
        questionId: 224882,
      },
      {
        value: 'Armour Thyroid 90 mg tablet',
        hasDosage: true,
        questionId: 226943,
      },
      {
        questionId: '299900',
        value: 'Westhroid',
      },
      {
        questionId: '303622',
        value: 'Triostat',
      },
      {
        questionId: '308071',
        value: 'Thyrolar-1',
      },
      {
        questionId: '307953',
        value: 'Thyrolar-2',
      },
      {
        questionId: '303601',
        value: 'Thyrolar-3',
      },
      {
        questionId: '301146',
        value: 'Liotrix',
      },
      {
        questionId: '371624',
        value: 'WP Thyroid',
      },
      {
        questionId: '311558',
        value: 'Nature-Throid',
      },
      {
        value: 'NP Thyroid',
        questionId: '357795',
      },
      {
        value: 'NP Thyroid 120 mg tablet',
        questionId: '384698',
      },
      {
        value: 'NP Thyroid 15 mg tablet',
        questionId: '382981',
      },
      {
        value: 'NP Thyroid 30 mg tablet',
        questionId: '357746',
      },
      {
        value: 'NP Thyroid 60 mg tablet',
        questionId: '357763',
      },
      {
        value: 'NP Thyroid 90 mg tablet',
        questionId: '357753',
      },
    ],
  },
  {
    id: 'medications',
    type: 'medicationList',
    endpoint: 'medications',
    // skip: 'supplements',
    skip: 'allergies',
    useUsaRx: true,
    title: 'Other Medications and Supplements',
    prompt: 'Please add the other medications and supplements you are currently taking',
    other: { questionId: '301410' },
    prefix: 'Medications currently taking -',
    extend: true,
    isInputOnly: true,
    options: [],
    nothingText: "I don't take other medications",
  },
  {
    id: 'allergies',
    type: 'allergies',
    endpoint: 'allergies',
    prompt: 'Do you have any allergies to medications or other substances?',
    options: [
      {
        questionId: '12990',
        prefix: 'Allergies to medications / other substances -',
        answer: 'Y',
        value: 'Yes',
        placeholder: 'Explain',
        check: (data) => {
          return data.gender === 'M' ? false : true;
        },
        failRoute: 'conditions',
      },
      {
        questionId: '12990',
        answer: 'NKDA',
        value: 'No',
        check: (data) => {
          return data.gender === 'M' ? false : true;
        },
        failRoute: 'conditions',
      },
    ],
  },
  {
    id: 'currentPregnant',
    type: 'single',
    endpoint: 'obgyn',
    prompt: 'Are you currently pregnant?',
    options: [
      {
        questionId: 'LOCAL.83',
        answer: 'Currently pregnant',
        display: 'Currently pregnant',
        nextQuestion: 'pregnancies',
      },
      {
        questionId: 'LOCAL.83',
        answer: 'Planning to be pregnant',
        display: 'Plan to be',
        nextQuestion: 'potentiallyPregnant',
      },
      {
        questionId: 'LOCAL.83',
        answer: 'Not pregnant',
        display: 'Not pregnant',
        nextQuestion: 'potentiallyPregnant',
      },
    ],
  },
  {
    id: 'potentiallyPregnant',
    type: 'single',
    endpoint: 'obgyn',
    prompt: `Is there a possibility that you could currently be pregnant?`,
    options: [
      {
        answer: 'Y',
        display: 'Yes',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_153' : 'LOCAL_165',
      },
      {
        answer: 'N',
        display: 'No',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_153' : 'LOCAL_165',
      },
    ],
  },
  {
    id: 'pregnancies',
    type: 'input',
    endpoint: 'obgyn',
    // onlyFirstTime: true,
    prompt: 'Tell us about your pregnancy history',
    defaults: { LOCAL_85: '', LOCAL_86: '' },
    options: [
      {
        questionId: 'LOCAL_85',
        placeholder: 'Total Pregnancies',
        inputmode: 'decimal',
      },
      { questionId: 'LOCAL_86', placeholder: 'Miscarriage', inputmode: 'decimal' },
    ],
  },

  {
    id: 'conditions',
    type: 'multiple',
    endpoint: 'medicalHistory',
    // onlyFirstTime: true,
    skip: 'concerns',
    prompt: 'Which of the following medical conditions have YOU (does not apply to your family history, which will be asked later) currently or previously been diagnosed with?',
    options: [
      // {
      //   question: 'Depression',
      //   questionId: '5',
      // },
      // {
      //   question: 'Anxiety Disorder',
      //   questionId: '285',
      // },
      {
        value: 'Blood in the stool and/or a diagnosis of colon cancer',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '422' : '464',
      },
      {
        value: 'Diagnosis of atypical ductal hyperplasia on breast biopsy',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '435' : '462',
      },
      // {
      //   value: 'Cancer',
      //   questionId: '290',
      // },
      {
        value: 'Elevated Blood Pressure',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '424' : '465',
      },
      {
        value: 'Insulin-dependent diabetes',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '433' : '468',
      },
      {
        value: 'Abnormal Pap smear and/or a diagnosis of cervical cancer',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '431' : '461',
      },
      {
        value: 'Positive BRCA 1/2 test (genetic test for cancer risk)',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '432' : '473',
      },
      {
        value: 'Migraine headaches with aura (e.g. flashing lights or blind spots)',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '430' : '470',
      },
      {
        value: 'Stroke or transient ischemic attack (TIA or "mini-strokes")',
        questionId: '11',
      },
      {
        value: 'Unintentional weight loss of 5% or 10 pounds of body weight within the last 6-12 months without trying',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '427' : '475',
      },
      {
        value: 'Non-insulin-dependent diabetes (diabetes controlled with diet or medications other than insulin)',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '425' : '471',
      },
      {
        value: 'Radiation Therapy',
        questionId: '283',
      },
      {
        value: 'Heart attack or blockage in the heart arteries (coronary artery disease)',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '423' : '466',
      },
      {
        value: 'Previous suicide attempt in past 2 years',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '426' : '474',
      },
      {
        value: 'Elevated cholesterol',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '402' : '467',
      },
      {
        value: 'Liver Disease',
        questionId: '10',
      },
      {
        value: 'Blood clot in legs or lungs',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '434' : '463',
      },
      {
        value: 'Pain or bulging/bloating in the pelvis',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '429' : '472',
      },
      {
        value: 'Lupus',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '428' : '469',
      },
      {
        value: 'Osteoporosis',
        questionId: '20',
      },
      { skip: 'cancer', value: 'None' },
    ],
  },
  {
    id: 'diabetes',
    type: 'single',
    endpoint: 'medicalHistory',
    prompt: `Do any of the following apply to your diabetes status?`,
    skipIfNotQuestionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '433' : '468',
    no: 'family',
    options: [
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '433' : '468',
        answer: 'Y',
        value: 'Greater than 20 years duration',
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '433' : '468',
        answer: 'Y',
        value: 'Complicated by diabetic kidney disease (nephropathy), eye disease (retinopathy), or nerve disease (neuropathy) ',
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '433' : '468',
        answer: 'Y',
        value: 'None of the above',
      },
    ],
  },
  {
    id: 'diabetes-insulin',
    type: 'single',
    endpoint: 'medicalHistory',
    prompt: `Do any of the following apply to your diabetes status?`,
    skipIfNotQuestionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '425' : '471',
    no: 'family',
    options: [
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '425' : '471',
        answer: 'Y',
        value: 'Greater than 20 years duration',
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '425' : '471',
        answer: 'Y',
        value: 'Complicated by diabetic kidney disease (nephropathy), eye disease (retinopathy), or nerve disease (neuropathy) ',
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '425' : '471',
        answer: 'Y',
        value: 'None of the above',
      },
    ],
  },

  {
    id: 'cancer',
    type: 'single',
    endpoint: 'medicalHistory',
    // onlyFirstTime: true,
    prompt: `Have you ever been diagnosed with any type of cancer?`,
    extend: true,
    options: [
      {
        questionId: '290',
        prefix: '',
        answer: 'Y',
        value: 'Yes',
        placeholder: 'what type of cancer and what was treatment and remission date',
        mask: '9999',
      },
      { questionId: '290', answer: 'N', value: 'No' },
    ],
  },

  {
    id: 'mental-health-conditions',
    type: 'multiple',
    endpoint: 'medicalHistory',
    skip: 'concerns',
    prompt: 'Have you ever been diagnosed with the following mental health conditions?',
    options: [{ questionId: '285', value: 'Anxiety' }, { questionId: '5', value: 'Depression' }],
  },
  {
    id: 'concerns',
    type: 'input',
    endpoint: 'problems',
    skip: 'relatives-cancer',
    // onlyFirstTime: true,
    prompt: 'Please list any other medical concerns you have and any details that may be important to your provider',
    description: `Some patients want to ensure that their providers are aware of conditions such as heart disease and family histories of breast, ovarian, primary peritoneal, or uterine cancer.`,
    footer: `Don't worry if you forget something now or have questions later. You can always message your provider at any time.`,
    defaults: { '29064005': '' },
    prefix: 'Other medical concerns / details -',
    extend: true,
    options: [{ questionId: '29064005', placeholder: 'Add them here...' }],
  },

  {
    id: 'relatives-cancer',
    type: 'single',
    endpoint: 'familyHistory',
    prompt: `Has one of your parents or relatives had breast cancer, ovarian cancer, or uterine cancer?`,
    extend: true,
    options: [
      {
        questionId: '291',
        prefix: '',
        display: 'Yes',
        value: 'Yes',
        placeholder: 'Which type of cancer and which family member was affected?',
      },
      { questionId: '291', answer: 'N', display: 'No', isSkip: true },
    ],
  },
  {
    id: 'familyConditions',
    type: 'input',
    endpoint: 'familyHistory',
    skip: 'uploadLabResults',
    // onlyFirstTime: true,
    prompt: 'Please indicate any major conditions/illnesses that your immediate family members have',
    defaults: { '8': '' },
    prefix: 'Major conditions / illnesses -',
    options: [
      {
        questionId: '8',
        placeholder: 'Indicate any major conditions/illnesses',
      },
    ],
  },
  {
    id: 'uploadLabResults',
    type: 'uploadLabResults',
    endpoint: 'medicalHistory',
    skip: true,
    prompt: 'Do you have any previous hormonal labs you would like to upload?',
    disclaimer: `Please note that for prescribing treatment without new lab tests, the existing results must be no older than 120 day. If new tests are required, your provider will arrange the necessary lab orders for you.`,
    onNextRoute: '/prior-mthfr-test',
    // skip: 'disclaimerLabUpload',
  },
  // {
  //   id: 'disclaimerLabUpload',
  //   type: 'single',
  //   endpoint: 'obgyn',
  //   prompt: `Please note for prescriptive treatment without new labs the results must be less than 120 days old.  New labs may be needed at provider discretion.`,
  //   options: [
  //     {
  //       answer: 'Ok',
  //       value: 'Ok',
  //     },
  //   ],
  // },
  {
    id: 'prior-mthfr-test',
    type: 'single',
    endpoint: 'medicalHistory',
    prompt: `Have you had prior testing for MTHFR?`,
    extend: true,
    options: [
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '442' : '422',
        answer: 'Y',
        value: 'Yes',
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '442' : '422',
        answer: 'N',
        value: 'No',
        nextQuestion: 'has-menstrual-cycles',
      },
    ],
  },

  // TODO : Handle selection as note
  {
    id: 'mthfr-positive',
    type: 'single',
    endpoint: 'medicalHistory',
    prompt: `Was your MTHFR test positive`,
    extend: true,
    options: [
      {
        answer: 'Y',
        value: 'Yes',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '442' : '422',
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '442' : '422',
        answer: 'N',
        value: 'No',
      },
    ],
  },
  {
    id: 'has-menstrual-cycles',
    type: 'single',
    endpoint: 'obgyn',
    prompt: `Do you still have menstrual cycles`,
    extend: true,
    options: [
      {
        answer: 'Y',
        display: 'Yes',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_129' : 'LOCAL_148',
        nextQuestion: 'last-menstruation-cycle',
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_129' : 'LOCAL_148',
        answer: 'N',
        display: 'No',
      },
    ],
  },
  {
    id: 'last-periods',
    type: 'single',
    endpoint: 'obgyn',
    prompt: `how long has it been since your last period?`,
    options: [
      {
        answer: 'Less than a year',
        value: 'Less than a year',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_163' : 'LOCAL_181',
        nextQuestion: 'last-menstruations-date-lt1',
      },
      {
        answer: 'Greater than a year',
        value: 'Greater than a year',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_163' : 'LOCAL_181',
        nextQuestion: 'last-menstruations-date',
      },
      {
        answer: 'Greater than 5 years',
        value: 'Greater than 5 years',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_163' : 'LOCAL_181',
        nextQuestion: 'post-menopausal-bleeding',
      },
      {
        answer: 'Greater than 10 years',
        value: 'Greater than 10 years',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_163' : 'LOCAL_181',
        nextQuestion: 'post-menopausal-bleeding',
      },
    ],
  },
  {
    id: 'last-menstruations-date',
    type: 'input',
    endpoint: 'obgyn',
    // onlyFirstTime: true,
    prompt: 'What was the approximate date of your last menstrual period?',
    defaults: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? { LOCAL_165: '' } : { LOCAL_183: '' },
    options: [
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_165' : 'LOCAL_183',
        placeholder: 'Month/day/year',
        mask: '99/99/9999',
      },
    ],
  },
  {
    id: 'post-menopausal-bleeding',
    type: 'single',
    endpoint: 'medicalHistory',
    prompt: `Have you experienced any vaginal bleeding since your periods stopped?`,
    options: [
      {
        answer: 'Y',
        value: 'Yes',
        questionId: 'POSTMENOPAUSALBLEEDING',
        nextQuestion: 'hysteroctomy',
      },
      {
        questionId: 'POSTMENOPAUSALBLEEDING',
        answer: 'N',
        value: 'No',
        nextQuestion: 'hysteroctomy',
      },
    ],
  },
  {
    id: 'last-menstruations-date-lt1',
    type: 'input',
    endpoint: 'obgyn',
    // onlyFirstTime: true,
    prompt: 'What was the approximate date of your last menstrual period?',
    defaults: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? { LOCAL_165: '' } : { LOCAL_183: '' },
    options: [
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_165' : 'LOCAL_183',
        placeholder: 'Month/day/year',
        mask: '99/99/9999',
      },
    ],
  },
  {
    id: 'post-menopausal-bleeding-lt1',
    type: 'single',
    endpoint: 'medicalHistory',
    prompt: `Have you experienced any vaginal bleeding since your periods stopped?`,
    options: [
      {
        answer: 'Y',
        value: 'Yes',
        questionId: 'POSTMENOPAUSALBLEEDING',
      },
      {
        questionId: 'POSTMENOPAUSALBLEEDING',
        answer: 'N',
        value: 'No',
      },
    ],
  },
  {
    id: 'last-menstruation-cycle',
    type: 'single',
    endpoint: 'obgyn',
    prompt: `How would you describe your menstrual cycle in the past year?`,
    extend: true,
    options: [
      {
        answer: 'Regular',
        value: 'Regular',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_125' : 'LOCAL_152',
      },
      {
        answer: 'Irregular or unpredictable',
        value: 'Irregular or unpredictable',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_125' : 'LOCAL_152',
      },
      {
        answer: 'Changes (no periods) in menstrual cycle due to hysteroctomy',
        value: 'Changes (no periods) in menstrual cycle due to hysteroctomy',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_125' : 'LOCAL_152',
        nextQuestion: 'hysteroctomy',
      },
      {
        answer: 'Other vaginal discharge (with or without bleeding)',
        value: 'Other vaginal discharge (with or without bleeding)',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_125' : 'LOCAL_152',
        nextQuestion: 'hysteroctomy',
      },
    ],
  },
  {
    id: 'menstruation-cycle-average-length',
    type: 'single',
    endpoint: 'obgyn',
    prompt: `What is the average length of your period duration?`,
    extend: true,
    options: [
      {
        answer: '1-3 days',
        value: '1-3 days',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_127' : 'LOCAL_149',
      },
      {
        answer: '3-5 days',
        value: '3-5 days',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_127' : 'LOCAL_149',
      },
      {
        answer: '5-7 days',
        value: '5-7 days',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_127' : 'LOCAL_149',
      },
      {
        answer: '7 or more days',
        value: '7 or more days',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_127' : 'LOCAL_149',
      },
    ],
  },
  {
    id: 'menstruation-cycles-count',
    type: 'single',
    endpoint: 'obgyn',
    prompt: `How many would you approximate in the past 12 months?`,
    options: [
      {
        answer: '1-2 periods',
        value: '1-2 periods',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_126' : 'LOCAL_154',
      },
      {
        answer: '3-5 periods',
        value: '3-5 periods',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_126' : 'LOCAL_154',
      },
      {
        answer: '6-12 periods',
        value: '6-12 periods',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_126' : 'LOCAL_154',
      },
      {
        answer: 'More than 12 periods',
        value: 'More than 12 periods',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_126' : 'LOCAL_154',
      },
    ],
  },
  {
    id: 'hysteroctomy',
    type: 'single',
    endpoint: 'medicalHistory',
    prompt: `Have you had a Hysterectomy`,
    description: `Hysterectomy is the surgical removal of the uterus. During menopausal hormone therapy, if estrogen is prescribed, progesterone is also administered to protect the uterus.`,
    options: [
      {
        answer: 'Y',
        value: 'Yes',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '443' : '441',
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '443' : '441',
        answer: 'N',
        value: 'No',
        nextQuestion: 'uterineAblation',
      },
    ],
  },
  // TODO : Handle selection as note
  {
    id: 'hysteroctomy-details',
    type: 'single',
    endpoint: 'medicalHistory',
    prompt: `What type of Hysterectomy?`,
    extend: true,
    options: [
      {
        answer: 'Y',
        value: 'Partial',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '443' : '441',
        prefix: 'Partial - ',
        placeholder: 'Which year?',
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '443' : '441',
        answer: 'N',
        value: 'Total',
        prefix: 'Total - ',
        placeholder: 'Which year?',
      },
    ],
  },
  {
    id: 'uterineAblation',
    type: 'single',
    endpoint: 'medicalHistory',
    prompt: `Have you had any type of uterine ablation`,
    extend: true,
    options: [
      {
        answer: 'Y',
        value: 'Yes',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '444' : '442',
        placeholder: 'Which year?',
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '444' : '442',
        answer: 'N',
        value: 'No',
      },
    ],
  },
  {
    id: 'hospital',
    type: 'input',
    endpoint: 'surgicalHistory',
    skip: 'last-mammogram',
    // onlyFirstTime: true,
    prompt: 'Please list all other surgeries and hospitalizations, including approximate dates for each',
    defaults: { '80050': '' },
    prefix: 'Past surgeries / hospitalizations -',
    extend: true,
    options: [{ questionId: '80050', placeholder: 'Add them here...' }],
  },
  {
    id: 'last-mammogram',
    type: 'single',
    endpoint: 'obgyn',
    prompt: `When was your last mammogram?`,
    extend: true,
    options: [
      {
        answer: 'Never',
        value: 'Never',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_167' : 'LOCAL_185',
        nextQuestion: 'pcp',
      },
      {
        answer: 'within past year',
        value: 'within past year',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_167' : 'LOCAL_185',
      },
      {
        answer: '1 or more years',
        value: '1 or more years',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_167' : 'LOCAL_185',
      },
    ],
  },
  {
    id: 'last-mammogram-results',
    type: 'single',
    endpoint: 'obgyn',
    prompt: `What were the results of your last mammogram?`,
    extend: true,
    options: [
      {
        answer: 'Normal',
        value: 'Normal',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_169' : 'LOCAL_187',
      },
      {
        answer: 'Abnormal',
        value: 'Abnormal',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_169' : 'LOCAL_187',
        nextQuestion: 'pcp',
      },
      {
        answer: 'Additional testing done but everything was normal',
        value: 'Additional testing done but everything was normal',
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? 'LOCAL_169' : 'LOCAL_187',
      },
    ],
  },
  {
    id: 'upload-last-mammogram',
    type: 'uploadLabResults',
    endpoint: 'medicalHistory',
    prompt: 'Please upload a copy of your last mammogram',
    skip: 'pcp',
    onlyOver: 40,
  },
  {
    id: 'pcp',
    type: 'pcp',
    endpoint: 'medicalHistory',
    title: 'Your Primary Care Provider',
    prompt: 'Approximately when were you diagnosed with hypothyroidism?',
    defaults: { '19': '' },
    prefix: 'Diagnosed in (Year) -',
    options: [{ questionId: '19', placeholder: 'Year (YYYY)', mask: '9999' }],
  },
  {
    id: 'nicotine-use',
    type: 'single',
    endpoint: 'socialHistory',
    prompt: `Do you use nicotine/tobacco in any form?`,
    options: [
      {
        answer: 'Y',
        value: 'Yes',
        questionId: 'doYouUseAnyOtherFormsOfTobacco',
      },
      {
        answer: 'N',
        value: 'No',
        questionId: 'doYouUseAnyOtherFormsOfTobacco',
      },
    ],
  },
  {
    id: 'thanks',
    type: 'information',
    title: 'Thank You',
    skip: 'great',
    status: 'symptoms',
    text: [
      'You are almost done!',
      'We do have questions for you about your symptoms and your lifestyle. Please take the time to fill out the next questions. Your answers help us to better serve you!',
    ],
  },
  ...intakeCommonTrunks,
];
