import React from 'react';
import { withRouter } from 'react-router';
import { Flex } from 'rebass';
import styled from 'styled-components';
import CommunicatorWrapper from '../components/memberCommunicator/careTeam/CommunicatorWrapper';
import withSession from '../lib/withSession';

const Wrap = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow-y: hidden;
`
const ChatCareTeam = ({ history, session: { isMember } }) => {
  if (!isMember) {
    history.push('/become-member');

    return <div />;
  }

  return (
    <Wrap id="ttoto">
      <CommunicatorWrapper />
    </Wrap>
  );
};

export default withRouter(withSession(ChatCareTeam));
