import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading, Text } from 'rebass';

import { Formik, Form } from 'formik';
import { injectStripe } from 'react-stripe-elements';
import { compose, withApollo } from 'react-apollo';
import { withWizard } from 'react-albus';
import { withToastManager } from 'react-toast-notifications';
import * as Yup from 'yup';

import { StripeInput } from '../../../components/StripeInput';
import { FormField } from '../../../components/FormField';
import InformationSecuredDisclaimer from '../../../components/InformationSecuredDisclaimer';
import { UPDATE_CARD } from '../../../graphql';
import { isToggleActive } from '../../../components/featureToggle/toggles';

const schema = Yup.object().shape({
  number: Yup.string().required('Required'),
  cvc: Yup.string().required('Required'),
  expire: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
});

const Payment = props => {
  const {
    stripe,
    toastManager,
    onSubmit,
    wizard,
    onPayNow,
    defaultCard,
    setSourceId,
    canMakePayment,
    canMakePaymentApplePay,
    continuePayWithWallet,
  } = props;
  const preventUseCoupon = isToggleActive('PREVENT_COUPON_BIOMARKERS');

  const createSourceWallet = async actions => {
    // console.log('createSourceWallet');
    try {
      // const { source } = await stripe.createSource({ type: 'card' });
      // console.log('createSourceWallet', source)
      // this.props.setSourceId(source.id);

      await continuePayWithWallet(actions, wizard, null);
    } catch (error) {
      console.log('ERR', error);
      actions.setSubmitting(false);
      toastManager.add('There was an error with the payment method.', {
        appearance: 'error',
      });
    }
  };

  const createSource = async actions => {
    try {
      const { source } = await stripe.createSource({ type: 'card' });
      setSourceId(source.id);
      await props.client.mutate({
        mutation: UPDATE_CARD,
        variables: {
          source: source.id,
        },
      });

      // await refetch();

      await onSubmit(actions, wizard, source.id);
    } catch (error) {
      actions.setSubmitting(false);
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(error => {
          toastManager.add(
            (error.message || '').replace(/Error:/g, '').trim(),
            {
              appearance: 'error',
            }
          );
        });
      } else {
        toastManager.add('There was an error with the payment method', {
          appearance: 'error',
        });
      }
    }
  };

  const createSourcePayNow = async actions => {
    actions.setSubmitting(true);
    try {
      const { source } = await stripe.createSource({ type: 'card' });
      setSourceId(source.id);
      await props.client.mutate({
        mutation: UPDATE_CARD,
        variables: {
          source: source.id,
        },
      });

      await onPayNow(actions, wizard, source.id);
    } catch (error) {
      console.log('createSourcePayNow error', {
        actions,
      });
      actions.setSubmitting(false);
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(error => {
          toastManager.add(
            (error.message || '').replace(/Error:/g, '').trim(),
            {
              appearance: 'error',
            }
          );
        });
      } else {
        toastManager.add('There was an error with the payment method', {
          appearance: 'error',
        });
      }
    }
  };

  return (
    <Fragment>
      <Flex flexDirection="column" flex={1}>
        {defaultCard ? (
          <Card p={4} mb={4}>
            <Flex flexDirection="column" alignItems="center" mb={4}>
              <Heading style={{ fontWeight: 'bold' }} mb={4}>
                Default Payment Method
              </Heading>
              <Text>
                {defaultCard.brand.toUpperCase()} **
                {defaultCard.last4}
              </Text>
              <Text>
                Expires {defaultCard.expMonth}/{defaultCard.expYear}
              </Text>
            </Flex>

            <Flex justifyContent="center">
              <Button
                variant="pink"
                width={[1 / 2, 1 / 2]}
                onClick={() => {
                  if (!preventUseCoupon) {
                    onSubmit(null, wizard);
                  } else {
                    onSubmit(null, wizard, null, true);
                  }
                }}
              >
                {!preventUseCoupon ? 'Review Order' : 'Pay Now'}
              </Button>
            </Flex>
          </Card>
        ) : null}
        <Card p={4}>
          <Flex flexDirection="column" mb={4}>
            <Heading textAlign="center" style={{ fontWeight: 'bold' }} mb={2}>
              Payment Method
            </Heading>
            <Text textAlign="center" fontWeight={300}>
              You can also use your HSA/FSA card to pay for a kit
            </Text>
          </Flex>

          <Formik
            initialValues={{ number: '', cvc: '', expire: '', zip: '' }}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
              await createSource(actions);
            }}
            render={({
              errors,
              touched,
              isValid,
              isSubmitting,
              actions,
              setSubmitting,
            }) => {
              return (
                <Form>
                  <Flex flexDirection="column">
                    <FormField error={touched.number && errors.number} mb={3}>
                      <StripeInput type="number" name="number" />
                    </FormField>

                    <Flex flexDirection={['column', 'column', 'row']} mb={3}>
                      <FormField
                        width={[1, 1, 1 / 3]}
                        mr={[0, 0, 3]}
                        mb={1}
                        error={touched.expire && errors.expire}
                      >
                        <StripeInput type="expire" name="expire" />
                      </FormField>

                      <FormField
                        width={[1, 1, 1 / 3]}
                        mr={[0, 0, 3]}
                        mb={1}
                        error={touched.cvc && errors.cvc}
                      >
                        <StripeInput type="cvc" name="cvc" />
                      </FormField>

                      <FormField
                        width={[1, 1, 1 / 3]}
                        mb={1}
                        error={touched.zip && errors.zip}
                      >
                        <StripeInput type="zip" name="zip" />
                      </FormField>
                    </Flex>
                  </Flex>

                  <Flex
                    alignItems="center"
                    flexDirection={
                      preventUseCoupon ? 'column' : ['column', 'row']
                    }
                  >
                    <Button
                      disabled={!isValid || isSubmitting}
                      variant="pink"
                      mb={3}
                      mr={[0, 3]}
                      width={[1, 1 / 2]}
                      type="button"
                      onClick={() => createSourcePayNow({ setSubmitting })}
                    >
                      Pay Now
                    </Button>
                    {!preventUseCoupon ? (
                      <Button
                        disabled={!isValid || isSubmitting}
                        variant="pink"
                        mb={3}
                        width={[1, 1 / 2]}
                        type="submit"
                      >
                        I Have a Coupon
                      </Button>
                    ) : (
                      <div />
                    )}
                  </Flex>
                  {canMakePayment ? (
                    <Flex alignItems="center" flexDirection={'column'}>
                      <Flex flexDirection="row" alignItems="center" mb="12px">
                        <div
                          style={{
                            height: '1px',
                            background: 'black',
                            opacity: 0.5,
                            width: '48px',
                            marginRight: '12px',
                          }}
                        />
                        <Text>OR</Text>
                        <div
                          style={{
                            height: '1px',
                            background: 'black',
                            opacity: 0.5,
                            width: '48px',
                            marginLeft: '12px',
                          }}
                        />
                      </Flex>
                      <Button
                        variant="pink"
                        width="auto"
                        disabled={isSubmitting}
                        type="button"
                        onClick={async () => {
                          setSubmitting(true);
                          await createSourceWallet({ setSubmitting });
                        }}
                      >
                        Pay with{' '}
                        {canMakePaymentApplePay ? 'Apple Pay' : 'Google Wallet'}
                      </Button>
                    </Flex>
                  ) : null}

                  <InformationSecuredDisclaimer />
                </Form>
              );
            }}
          />
        </Card>
      </Flex>
    </Fragment>
  );
};

export default injectStripe(
  compose(
    withWizard,
    withApollo,
    withToastManager
  )(Payment)
);
