import React from 'react';
import { withApollo } from 'react-apollo';
import { SEND_KLAVIYO_STATUS } from '../../graphql';
import { useMount } from '../../utils/hooks/lifecycle';

const KlaviyoEventer = ({ event, client }) => {
  useMount(async () => {
    if (!!event) {
      try {
        await client.mutate({
          mutation: SEND_KLAVIYO_STATUS,
          variables: {
            step: event,
          },
        });
      } catch (error) {}
    }
  });

  return <div />;
};

export default withApollo(KlaviyoEventer);
