import React, { Component, Fragment } from 'react';
import { Box, Button, Card, Flex, Text } from 'rebass';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Mutation, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';

import TextInput from '../components/TextInput';
import FormField from '../components/FormField';
import FullLayout from '../components/FullLayout';
import Header from '../components/Header';
import withAnalytics from '../lib/withAnalytics';
import {
  IMPERSONATE,
  CURRENT_USER,
  ATHENA_CARD,
  LABS,
  GET_USER_PCP,
  APPOINTMENTS,
} from '../graphql';
import LoadingScreen from '../components/LoadingScreen';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('invalid email address')
    .required('Required'),
  password: Yup.string()
    .trim()
    .required(),
});

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const CardContainer = styled(Flex)`
  max-width: 400px;
`;

class ImpersonatingChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEnteringImpersonation: false,
      idForced: props.match && props.match.params && props.match.params.id,
    };
  }
  componentDidMount() {
    this.props.analytics.page();
  }

  handleSubmit = async (values, impersonate, actions) => {
    const { toastManager, client, match } = this.props;
    const { idForced } = this.state;
    this.setState({
      isEnteringImpersonation: true,
    });
    if (idForced) {
      values.emailToImpersonate = match.params.id;
    }
    const initialToken = localStorage.getItem('token');
    try {
      const attemptedRoute = localStorage.getItem('route');
      let { data } = await impersonate({
        variables: {
          ...values,
        },
      });

      localStorage.setItem('initialToken', initialToken);
      localStorage.setItem('token', data.impersonate.token);

      await client.query({
        query: CURRENT_USER,
        refetchQueries: [
          { query: ATHENA_CARD },
          { query: APPOINTMENTS },
          { query: LABS },
          { query: GET_USER_PCP },
        ],
        fetchPolicy: 'network-only',
        awaitRefetchQueries: true,
      });
      actions.setSubmitting(false);
      this.setState({
        isEnteringImpersonation: false,
      });
      this.props.history.push(attemptedRoute || '/');
    } catch (error) {
      this.setState({
        isEnteringImpersonation: false,
      });
      actions.setSubmitting(false);
      console.log(error);
      if (!error.graphQLErrors) {
        return;
      }

      error.graphQLErrors.forEach(error => {
        toastManager.add(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
    }
  };

  render() {
    const { isEnteringImpersonation, idForced } = this.state;
    const { match } = this.props;
    if (isEnteringImpersonation) {
      return (
        <LoadingScreen
          layoutStyle={{ zIndex: 10 }}
          text="Entering Impersonation Mode"
        />
      );
    }
    return (
      <Fragment>
        <Container>
          <Flex flex={1}>
            <Header dark={false} />
          </Flex>
          <Flex flex={1} alignItems="center" justifyContent="center">
            <CardContainer flex={1} px={3}>
              <Card p={4}>
                <Flex flexDirection="column">
                  <Mutation mutation={IMPERSONATE}>
                    {(impersonate, { loading }) => {
                      return (
                        <Fragment>
                          <Formik
                            initialValues={{
                              email: '',
                              password: '',
                            }}
                            validationSchema={schema}
                            onSubmit={(values, actions) => {
                              actions.setSubmitting(true);
                              this.handleSubmit(values, impersonate, actions);
                            }}
                            render={({ touched, errors, isValid }) => (
                              <Form>
                                <FormField
                                  mb={3}
                                  error={touched.email && errors.email}
                                >
                                  <TextInput
                                    placeholder="Admin email address"
                                    type="email"
                                    name="email"
                                    id="input_email"
                                  />
                                </FormField>

                                <FormField
                                  mb={4}
                                  error={touched.phone && errors.phone}
                                >
                                  <TextInput
                                    placeholder="Admin Password"
                                    type="password"
                                    name="password"
                                    id="input_password"
                                  />
                                </FormField>
                                {!idForced ? (
                                  <FormField mb={4}>
                                    <TextInput
                                      placeholder="Patient's email address or ID"
                                      name="emailToImpersonate"
                                      id="input_emailToImpersonate"
                                    />
                                  </FormField>
                                ) : (
                                  <Text>{`Patient Id: ${
                                    match.params.id
                                  }`}</Text>
                                )}

                                <Flex
                                  alignItems="center"
                                  flexDirection="column"
                                >
                                  <Button
                                    disabled={!isValid || loading}
                                    variant="primary"
                                    width={1 / 2}
                                    type="submit"
                                    id="btn_login"
                                  >
                                    Impersonate
                                  </Button>
                                </Flex>
                              </Form>
                            )}
                          />
                        </Fragment>
                      );
                    }}
                  </Mutation>
                </Flex>
              </Card>
            </CardContainer>
          </Flex>
        </Container>
        <FullLayout />
      </Fragment>
    );
  }
}

export default withApollo(withAnalytics(withToastManager(ImpersonatingChange)));
