import React, { Fragment } from 'react';
import { Button, Card, Flex, Text, Box } from 'rebass';
import { withWizard } from 'react-albus';
import { Query, withApollo } from 'react-apollo';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import withSession from '../../lib/withSession';
import { withToastManager } from 'react-toast-notifications';
import Dropzone from 'react-dropzone';
import doctor from '../../static/uploadImagePlaceholder.png';
import PricingInfoModal from './components/pricingInfoModal/pricingInfoModal';
import TextInput from '../../components/TextInput';
import {
  GET_CLINICAL_PROVIDERS_DOCTOR,
  SEND_INSURANCE_INITIAL,
} from '../../graphql';
import FormField from '../../components/FormField';
import { objectHas } from '../../utils/helpers';
import PleaseWait from '../../components/PleaseWait';
import { MaskInput } from '../../components/MaskInput';
import CheckboxInput from '../../components/CheckboxInput';
import { isToggleActive } from '../../components/featureToggle/toggles';
import { getMemberVisitPrice } from '../../utils/pricing';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('invalid email address')
    .required('Required'),
});

const ToBeUploadedFilesMiniaturesLarge = ({ file, onFileRemove }) => {
  // console.log(file);
  return (
    <Flex
      flexDirection="row"
      flexWrap="wrap"
      style={{
        border: '1px solid rgb(221,221,221)',
        padding: '12px',
        width: 200,
        height: 200,
      }}
    >
      <Flex
        style={{
          position: 'relative',
          margin: '3px',
          minWidth: '48px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={file.data}
          alt="No Preview Available"
          width="100%"
          style={{ objectFit: 'fill' }}
        />
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onFileRemove(file);
          }}
          style={{
            height: '20px',
            width: '20px',
            borderRadius: '50%',
            background: '#f9a394',
            color: 'white',
            position: 'absolute',
            top: 0,
            right: 0,
            fontSize: '10px',
            textAlign: 'center',
            paddingTop: '3px',
            paddingLeft: '1px',
          }}
        >
          X
        </div>
      </Flex>
    </Flex>
  );
};
class InsuranceBenefitsCheck extends React.Component {
  constructor(props) {
    super(props);
    this.frontInput = React.createRef();

    this.state = {
      selectedPlanType:
        props &&
        props.selectedProvider &&
        props.selectedProvider.value === 'Medicare'
          ? `I don't know`
          : null,
      uploading: false,
      imagesFront: null,
      back: null,
    };
  }

  componentWillMount() {
    window.scroll(0, 0);
  }

  fileAcceptedFront = f => {
    for (let index = 0; index < f.length; index++) {
      const element = f[index];

      const reader = new FileReader();
      reader.onload = event => {
        let fls = f;
        fls = {
          fileName: element.name,
          mimeType: element.type,
          data: event.target.result,
        };
        // console.log('fls', fls);
        this.setState({
          imageFront: fls,
        });
      };
      reader.readAsDataURL(element);
    }
  };

  fileAccepted = f => {
    for (let index = 0; index < f.length; index++) {
      const element = f[index];

      const reader = new FileReader();
      reader.onload = event => {
        let fls = f;
        fls = {
          fileName: element.name,
          mimeType: element.type,
          data: event.target.result,
        };
        // console.log('fls', fls);
        this.setState({
          back: fls,
        });
      };
      reader.readAsDataURL(element);
    }
  };

  render() {
    const { selectedPlanType, imageFront, back } = this.state;

    const {
      selectedProvider,
      selectedProviderNonSupported,
      reinitFlow,
      isAdmin,
      impersonatedPatient,
      enforceDept,
      wizard,
      departmentInitials,
      bypassInsuranceKeepProvider,
    } = this.props;
    const hasNewPriceFeature = isToggleActive('USE_NEW_PRICING');

    const departmentId = isAdmin
      ? impersonatedPatient.departmentId || ''
      : objectHas(() => {
          return this.props.session.department.departmentId;
        })
        ? this.props.session.department.departmentId
        : enforceDept
          ? enforceDept.id.toString()
          : '';
    if (!selectedProvider && !selectedProviderNonSupported) {
      reinitFlow(wizard);
      return null;
    }

    if (selectedProviderNonSupported) {
      return (
        <Card p={4} mb={5}>
          <Flex mb={4} flexDirection="column">
            <Flex
              flex={1}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              mt={4}
            >
              <Fragment>
                <Text
                  fontWeight="600"
                  textAlign="center"
                  mt={2}
                  fontSize="24px"
                >
                  {selectedProviderNonSupported.value === 'Medicare'
                    ? `You're eligible to the Self-Pay membership`
                    : 'Your insurance is accepted out-of-network'}
                </Text>
                <Text textAlign="center" mt={4} fontSize="18px">
                  {`Your price: $${
                    this.props.session && this.props.session.isMember
                      ? `${getMemberVisitPrice()}`
                      : hasNewPriceFeature
                        ? '110'
                        : '99'
                  }`}
                </Text>

                {selectedProviderNonSupported.value !== 'Medicare' && (
                  <Text
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    textAlign="center"
                    onClick={() => {
                      // console.log('clicked!');
                      this.setState({ prompt: true });
                    }}
                  >
                    Learn More
                  </Text>
                )}

                <Flex style={{ justifyContent: 'center' }}>
                  <Button
                    variant="primary"
                    mt={4}
                    type="button"
                    style={{ width: '282px' }}
                    onClick={() => this.props.bypassInsurance(wizard)}
                  >
                    Book a Visit
                  </Button>
                </Flex>
                <div
                  style={{
                    height: '1px',
                    backgroundColor: '#ccc',
                    margin: '40px -32px',
                    width: 'calc(100% + 64px)',
                  }}
                />
                <Flex
                  flex={1}
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  textAlign="center"
                >
                  <Text
                    textAlign="center"
                    mb={1}
                    fontSize="18px"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.props.bypassInsurance(wizard)}
                  >
                    <span style={{ color: '#364f79' }}>Skip Insurance</span>
                    <span style={{ color: '#f9a394', fontWeight: '600' }}>
                      {' >'}
                    </span>
                  </Text>
                  {/* <Text
                    textAlign="center"
                    fontSize="16px"
                    fontWeight={200}
                    color="#7c7c7c"
                  >
                    We will provide you with the superbill necessary for an
                    out-of-network reimbursement.
                  </Text> */}
                </Flex>
              </Fragment>
            </Flex>
          </Flex>
          <PricingInfoModal
            isOpen={this.state.prompt}
            close={() => {
              this.setState({ prompt: null });
            }}
          />
        </Card>
      );
    }
    return (
      <Card p={4} mb={5}>
        <Flex mb={4} flexDirection="column">
          <Flex
            flex={1}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={4}
          >
            <Fragment>
              <Query
                query={GET_CLINICAL_PROVIDERS_DOCTOR}
                variables={{
                  departmentId,
                  selectedInsurance: !selectedProvider?null:selectedProvider.value
                }}
                fetchPolicy="cache-and-network"
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <>
                        <Text
                          fontWeight="600"
                          textAlign="center"
                          mt={2}
                          fontSize="24px"
                        >
                          Insurance Information
                        </Text>
                        <PleaseWait style={{ marginTop: 24 }} />
                      </>
                    );
                  }

                  let inNetworkCount = false;
                  // console.log('RRR', {
                  //   departmentId,
                  //   data,
                  // });
                  if (
                    data &&
                    data.getClinicalProvidersDoctorsList &&
                    data.getClinicalProvidersDoctorsList.length > 0
                  ) {
                    for (
                      let index = 0;
                      index < data.getClinicalProvidersDoctorsList.length;
                      index++
                    ) {
                      const element =
                        data.getClinicalProvidersDoctorsList[index];
                      if (element.networks) {
                        const ns = element.networks.split(';');
                        for (let index = 0; index < ns.length; index++) {
                          const nt = ns[index];
                          if (
                            nt.toLowerCase() ===
                            selectedProvider.value.toLowerCase()
                          ) {
                            inNetworkCount = true;
                            break;
                          }
                        }
                        if (inNetworkCount) {
                          break;
                        }
                      }
                    }
                  }
                  console.log({inNetworkCount})

                  if (!inNetworkCount) {
                    return (
                      <>
                        <Text
                          fontWeight="600"
                          textAlign="center"
                          mt={2}
                          fontSize="24px"
                        >
                          {selectedProvider.value === 'Medicare'
                            ? `You're eligible to the Self-Pay membership`
                            : 'Your insurance is accepted out-of-network'}
                        </Text>
                        <Text textAlign="center" mt={4} fontSize="18px">
                          {`Your price: $${
                            this.props.session && this.props.session.isMember
                              ? `${getMemberVisitPrice()}`
                              : hasNewPriceFeature
                                ? '110'
                                : '99'
                          }`}
                        </Text>

                        {selectedProvider.value !== 'Medicare' && (
                          <Text
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            textAlign="center"
                            onClick={() => {
                              // console.log('clicked!');
                              this.setState({ prompt: true });
                            }}
                          >
                            Learn More
                          </Text>
                        )}

                        <Button
                          variant="primary"
                          mt={4}
                          type="button"
                          style={{ width: '282px' }}
                          onClick={() => {
                            // if (selectedProvider.value !== 'Medicare') {
                            //   this.props.setInsuranceCard(
                            //     selectedPlanType,
                            //     imageFront,
                            //     back,
                            //     wizard
                            //   );
                            // } else {
                            this.props.bypassInsurance(wizard);
                            // }
                          }}
                        >
                          Book a Visit
                        </Button>
                      </>
                    );
                  }
                  return (
                    <>
                      <Text
                        fontWeight="600"
                        textAlign="center"
                        mt={2}
                        fontSize="24px"
                      >
                        Insurance Information
                      </Text>
                      {selectedProvider &&
                        selectedProvider.value !== 'Medicare' && (
                          <>
                            <Text mt={36} mb={16}>
                              Select Your Plan Type
                            </Text>

                            <Flex
                              flexDirection={['row']}
                              flexWrap="wrap"
                              mb={32}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Box
                                onClick={() =>
                                  this.setState({ selectedPlanType: 'PPO' })
                                }
                                style={{
                                  backgroundColor:
                                    selectedPlanType &&
                                    selectedPlanType === 'PPO'
                                      ? '#344f79'
                                      : 'white',
                                  borderRadius: 12,
                                  padding: '4px 8px',
                                  color:
                                    selectedPlanType &&
                                    selectedPlanType === 'PPO'
                                      ? 'white'
                                      : '#344f79',
                                  border: '1px solid #344f79',
                                  margin: 6,
                                  cursor: 'pointer',
                                  width: '140px',
                                  borderRadius: '50px',
                                  textAlign: 'center',
                                }}
                              >
                                <Text>PPO</Text>
                              </Box>
                              <Box
                                onClick={() =>
                                  this.setState({ selectedPlanType: 'EPO' })
                                }
                                style={{
                                  backgroundColor:
                                    selectedPlanType &&
                                    selectedPlanType === 'EPO'
                                      ? '#344f79'
                                      : 'white',
                                  borderRadius: 12,
                                  padding: '4px 8px',
                                  color:
                                    selectedPlanType &&
                                    selectedPlanType === 'EPO'
                                      ? 'white'
                                      : '#344f79',
                                  border: '1px solid #344f79',
                                  margin: 6,
                                  cursor: 'pointer',
                                  width: '140px',
                                  borderRadius: '50px',
                                  textAlign: 'center',
                                }}
                              >
                                <Text>EPO / POS</Text>
                              </Box>
                              <Box
                                onClick={() =>
                                  this.setState({ selectedPlanType: 'HMO' })
                                }
                                style={{
                                  backgroundColor:
                                    selectedPlanType &&
                                    selectedPlanType === 'HMO'
                                      ? '#344f79'
                                      : 'white',
                                  borderRadius: 12,
                                  padding: '4px 8px',
                                  color:
                                    selectedPlanType &&
                                    selectedPlanType === 'HMO'
                                      ? 'white'
                                      : '#344f79',
                                  border: '1px solid #344f79',
                                  margin: 6,
                                  cursor: 'pointer',
                                  width: '140px',
                                  borderRadius: '50px',
                                  textAlign: 'center',
                                }}
                              >
                                <Text>HMO</Text>
                              </Box>
                              <Box
                                onClick={() =>
                                  this.setState({
                                    selectedPlanType: `I don't know`,
                                  })
                                }
                                style={{
                                  backgroundColor:
                                    selectedPlanType &&
                                    selectedPlanType === `I don't know`
                                      ? '#344f79'
                                      : 'white',
                                  borderRadius: 12,
                                  padding: '4px 8px',
                                  color:
                                    selectedPlanType &&
                                    selectedPlanType === `I don't know`
                                      ? 'white'
                                      : '#344f79',
                                  border: '1px solid #344f79',
                                  margin: 6,
                                  cursor: 'pointer',
                                  width: '140px',
                                  borderRadius: '50px',
                                  textAlign: 'center',
                                }}
                              >
                                <Text>I don't know</Text>
                              </Box>
                            </Flex>
                          </>
                        )}
                      {!!selectedPlanType &&
                        selectedPlanType !== 'HMO' && (
                          <>
                            <Formik
                              initialValues={{
                                email: this.props.session
                                  ? this.props.session.email
                                  : '',
                                isPrimarySubscriber: true,
                              }}
                              validationSchema={schema}
                              isInitialValid={
                                this.props.session && this.props.session.email
                              }
                              onSubmit={async (values, actions) => {
                                this.props.setInsuranceCard(
                                  selectedPlanType,
                                  wizard,
                                  values.email,
                                  values.primarySubscriberFullName,
                                  values.dob
                                );
                              }}
                              render={({
                                values,
                                errors,
                                touched,
                                isValid,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                validateForm,
                              }) => (
                                <Form>
                                  <FormField
                                    width={1}
                                    error={touched.email && errors.email}
                                  >
                                    <label for="email">
                                      Enter your email so that we can confirm
                                      your eligibility:
                                    </label>
                                    <TextInput
                                      disabled={
                                        this.props.session &&
                                        this.props.session.email
                                      }
                                      name="email"
                                      placeholder={`Your email address`}
                                    />
                                  </FormField>

                                  <FormField
                                    flexDirection="row"
                                    mb={4}
                                    mt={4}
                                    error={
                                      touched.isPrimarySubscriber &&
                                      errors.isPrimarySubscriber
                                    }
                                  >
                                    <CheckboxInput name="isPrimarySubscriber" />
                                    <Text fontSize={1}>
                                      I am the primary subscriber
                                    </Text>
                                  </FormField>

                                  {!values.isPrimarySubscriber && (
                                    <>
                                      <FormField
                                        width={1}
                                        mb={3}
                                        error={
                                          touched.primarySubscriberFullName &&
                                          errors.primarySubscriberFullName
                                        }
                                      >
                                        <label for="primarySubscriberFullName">
                                          Full name of the primary subscriber
                                        </label>
                                        <TextInput
                                          name="primarySubscriberFullName"
                                          placeholder={`Full name`}
                                        />
                                      </FormField>
                                      <FormField
                                        mb={4}
                                        error={touched.dob && errors.dob}
                                      >
                                        <label for="dob">
                                          Primary Subscriber Date of Birth
                                          (MM/DD/YYYY)
                                        </label>
                                        <MaskInput
                                          name="dob"
                                          placeholder="MM/DD/YYYY"
                                          mask="99/99/9999"
                                          value={values.dob}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          id="input_dob"
                                        />
                                      </FormField>
                                    </>
                                  )}

                                  <Flex style={{ justifyContent: 'center' }}>
                                    <Button
                                      disabled={
                                        isSubmitting ||
                                        !isValid ||
                                        (!values.isPrimarySubscriber &&
                                          (!values.primarySubscriberFullName ||
                                            !values.dob))
                                      }
                                      variant="primary"
                                      mt={4}
                                      style={{ width: '282px' }}
                                      type="submit"
                                    >
                                      Book a Visit
                                    </Button>
                                  </Flex>
                                </Form>
                              )}
                            />
                          </>
                        )}

                      {!!selectedPlanType &&
                        selectedPlanType === 'HMO' && (
                          <>
                            <Text>
                              We are not currently supporting HMO plans.
                            </Text>
                            <Text style={{ marginTop: 24 }}>
                              But you can still book an appointment
                            </Text>

                            <Flex style={{ justifyContent: 'center' }}>
                              <Button
                                variant="primary"
                                mt={4}
                                type="button"
                                style={{ width: '282px' }}
                                onClick={() =>
                                  this.props.bypassInsurance(wizard)
                                }
                              >
                                Book a Visit
                              </Button>
                            </Flex>
                          </>
                        )}
                    </>
                  );
                }}
              </Query>

              <div
                style={{
                  height: '1px',
                  backgroundColor: '#ccc',
                  margin: '40px -32px',
                  width: 'calc(100% + 64px)',
                }}
              />
              <Flex
                flex={1}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                textAlign="center"
              >
                <Text
                  textAlign="center"
                  mb={1}
                  fontSize="18px"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.props.bypassInsurance(wizard)}
                >
                  <span style={{ color: '#364f79' }}>Skip Insurance</span>
                  <span style={{ color: '#f9a394', fontWeight: '600' }}>
                    {' >'}
                  </span>
                </Text>
                {/* <Text
                  textAlign="center"
                  fontSize="16px"
                  fontWeight={200}
                  color="#7c7c7c"
                >
                  We will provide you with the superbill necessary for an
                  out-of-network reimbursement.
                </Text> */}
              </Flex>
            </Fragment>
          </Flex>
        </Flex>
        <PricingInfoModal
          isOpen={this.state.prompt}
          close={() => {
            this.setState({ prompt: null });
          }}
        />
      </Card>
    );
  }
}
export const InsuranceBenefitsCheckUnregistered = withApollo(
  withToastManager(withWizard(InsuranceBenefitsCheck))
);

export default withApollo(
  withSession(withToastManager(withWizard(InsuranceBenefitsCheck)))
);
