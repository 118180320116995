import React, { Fragment } from 'react';
import { Flex, Text, Card, Box } from 'rebass';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;

const Video = styled.iframe`
  width: 640px;
  height: 360px;
  margin-bottom: 25px;

  @media screen and (max-width: 950px) {
    width: 400px;
    height: 200px;
  }
`;

const RegisteredKit = () => {
  return (
    <Fragment>
      <Container flex={1} px={3}>
        <Flex flex={1} textAlign="center">
          <ScrollAnimation
            animateIn="fadeInDown"
            style={{ margin: '0 auto' }}
            animateOnce={true}
          >
            <Text
              fontSize="38px"
              fontWeight={600}
              textAlign="center"
              mb={4}
              color="#344f79"
            >
              Well Done!
            </Text>
          </ScrollAnimation>
        </Flex>
        <Flex flex={1} textAlign="center">
          <ScrollAnimation
            animateOnce={true}
            animateIn="fadeIn"
            delay={700}
            style={{ margin: '0 auto' }}
          >
            <Text
              fontSize="18px"
              fontWeight={400}
              textAlign="center"
              mb={4}
              color="#344f79"
            >
              Your kit is registered
            </Text>
          </ScrollAnimation>
        </Flex>

        <ScrollAnimation
          animateOnce={true}
          animateIn="fadeInUpFrom30"
          delay={300}
          style={{ margin: '0 auto' }}
        >
          <Card p={3} mb={5}>
            <Flex
              flexDirection="column"
              flex={1}
              textAlign="center"
              alignItems="center"
              style={{ margin: '0 auto' }}
              mt={4}
            >
              <Text
                fontSize="18px"
                fontWeight={400}
                textAlign="center"
                mb={4}
                mt={4}
                color="#344f79"
              >
                Don't forget to watch our video that will show you how to use
                the kit
              </Text>

              <Video
                title="vimeo-player"
                src="https://player.vimeo.com/video/351018081"
                frameBorder="0"
                allowFullScreen
              />
            </Flex>
          </Card>
        </ScrollAnimation>
      </Container>
    </Fragment>
  );
};

export default RegisteredKit;
