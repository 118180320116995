import React, { Fragment, Component } from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';

import SpinLoader from '../components/SpinLoader';
import ConfirmModal from '../components/ConfirmModal';
import withSession from '../lib/withSession';
import {
  CANCEL_APPT,
  CURRENT_USER,
  ADMIN_GET_APPOINTMENTS_LIST,
  FORCE_CANCEL_APPT_LOCAL,
  GET_APPOINTMENT_ENCOUNTER_STATE,
} from '../graphql';
import ConsultationFutureAdmin from '../components/admin/consultation/ConsultationFuture';

const InfoBox = styled(Flex)`
  flex: 1;
  background: ${props => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${props => props.theme.color.darkBlue};
  justify-content: center;
`;

const DetailNavigation = styled.div`
  flex: 1;
  margin-bottom: 36px;
  margin-left: -16px;
  margin-top: -16px;
  @media screen and (min-width: 52em) {
    display: none;
    margin-bottom: 0px;
    margin-left: 0;
    margin-top: 0;
  }
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const BackButton = styled.div`
  background: rgba(180, 205, 244, 0.5);
  width: 100%;
  color: #333;
  padding: 6px 12px;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const initialState = {
  prompt: false,
  loading: false,
  ready: false,
  appointment: {},
  prescription: '',
  summary: '',
};

class ConsultationDetailAdmin extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = initialState;
  }

  handleCancel = async (id, patientId) => {
    const { client, history, toastManager } = this.props;

    this.setState({ loading: true });
    let foundInAthena = true;
    try {
      await client.mutate({
        mutation: CANCEL_APPT,
        variables: {
          id,
          enforcePatientId: patientId,
        },
        refetchQueries: [
          { query: ADMIN_GET_APPOINTMENTS_LIST },
          { query: CURRENT_USER },
        ],
        awaitRefetchQueries: true,
      });
      history.push('/consultations');
    } catch (err) {
      console.log('About to check ENCOUNTER', id)
      const encounter = await client.query({
        query: GET_APPOINTMENT_ENCOUNTER_STATE,
        variables: {
          appointmentId: id,
        },
      });
      console.log('ENCOUNTER', encounter)
      if (
        encounter &&
        encounter.data &&
        encounter.data.getAppointmentEncounterState &&
        encounter.data.getAppointmentEncounterState.encounterState
      ) {
        if (
          encounter.data.getAppointmentEncounterState.encounterState.toUpperCase() ===
          'DELETED'
        ) {
          console.log('ENCOUNTER is deleted')
          try {
            await client.mutate({
              mutation: FORCE_CANCEL_APPT_LOCAL,
              variables: {
                id,
                patientId: patientId,
              },
              refetchQueries: [
                { query: ADMIN_GET_APPOINTMENTS_LIST },
                { query: CURRENT_USER },
              ],
              awaitRefetchQueries: true,
            });
            toastManager.add('Appointment deleted locally', {
              appearance: 'success',
            });
            history.push('/consultations');
          } catch (err) {
            console.log('ERR Force cancel', err);
            this.setState({ loading: false });
          }
        }
      } else {
        if (err.toString().indexOf('is too late') > -1) {
          toastManager.add('It is too late to cancel this appointment.', {
            appearance: 'error',
          });
        } else {
          console.log('ERR', err);
          if (err.toString().indexOf('code 404') > -1) {
            foundInAthena = false;
          }
        }
      }
      this.setState({ loading: false });
    }
    if (!foundInAthena) {
      try {
        await client.mutate({
          mutation: FORCE_CANCEL_APPT_LOCAL,
          variables: {
            id,
            patientId: patientId,
          },
          refetchQueries: [
            { query: ADMIN_GET_APPOINTMENTS_LIST },
            { query: CURRENT_USER },
          ],
          awaitRefetchQueries: true,
        });
        history.push('/consultations');
      } catch (err) {
        console.log('ERR Force cancel', err);
        this.setState({ loading: false });
      }
    }
  };

  componentDidMount = async () => {
    const { appointments, match } = this.props;
    if (appointments && appointments.length > 0) {
      // console.log('MATCH', match);
      const appointment = appointments.find(item => {
        return (
          item.appointmentId &&
          item.appointmentId.toString() === match.params.id.toString()
        );
      });
      if (appointment) {
        this.setState({
          appointment,
          ready: true,
        });
      } else {
        this.setState({ ready: true });
      }
    } else {
      this.setState({ ready: true });
      // toastManager.add('Unable to fetch consultation, try again!', {
      //   appearance: 'error',
      // });
    }
  };

  handleModal = () => {
    this.setState({ prompt: !this.state.prompt });
  };

  render() {
    const { appointments, history, session, bypassPatientId } = this.props;
    const { appointment, ready } = this.state;

    if (!appointments || !appointments.length || !appointment) {
      return null;
    }

    if (!ready) {
      return (
        <Flex flex={1} justifyContent="center" alignItems="center">
          <SpinLoader />
        </Flex>
      );
    }

    return (
      <Fragment>
        <Flex
          p={3}
          flexDirection="column"
          pb={5}
          style={{
            overflowY: 'scroll',
          }}
        >
          <DetailNavigation mb={4} mt={3}>
            <BackButton onClick={() => history.push('/consultations')}>
              {`<  Your Visits`}
            </BackButton>
          </DetailNavigation>

          {(appointment.status === 'f' ||
            appointment.status === '2' ||
            bypassPatientId) && (
            <ConsultationFutureAdmin
              appointment={appointment}
              history={history}
              intakeStatus={session.intakeStatus}
              onClick={this.handleModal}
            />
          )}
        </Flex>

        <ConfirmModal
          isOpen={this.state.prompt}
          onClose={this.handleModal}
          onBackgroundClick={this.handleModal}
          onConfirm={() =>
            this.handleCancel(
              appointment.appointmentId.toString(),
              appointment.patientId
            )
          }
        >
          <ModalInner>
            <Heading mb={4} textAlign="center">
              Confirm Appointment Cancellation
            </Heading>
            {!this.state.loading && (
              <InfoBox bg="#D9E6F9" mb={4}>
                <Text fontSize={3} fontWeight={700} textAlign="center">
                  {`${moment(appointment.startDate).format(
                    'ddd MMM Do h:mma'
                  )}`}
                </Text>
              </InfoBox>
            )}

            {this.state.loading && (
              <Flex justifyContent="center" alignItems="center" pb={3}>
                <SpinLoader />
              </Flex>
            )}
          </ModalInner>
        </ConfirmModal>
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  withToastManager
)(withSession(ConsultationDetailAdmin));
