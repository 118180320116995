import React, { useState } from 'react';
import { Flex, Text } from 'rebass';
import RichTextEditor from 'react-rte';

export const TextAreaCtrl = ({ properties }) => {
  const [valu, setValu] = useState(RichTextEditor.createEmptyValue());
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' }, { label: 'Italic', style: 'ITALIC' }, { label: 'Underline', style: 'UNDERLINE' }],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' },
    ],
    BLOCK_TYPE_BUTTONS: [{ label: 'UL', style: 'unordered-list-item' }, { label: 'OL', style: 'ordered-list-item' }],
  };
  return (
    <>
      <Flex flexDirection={'column'} flex={1 / 4}>
        <Text onClick={() => { }} fontWeight={500}>
          {`${(properties || {}).question || 'Question'}${((properties || {}).ctrlProps || {}).required ? '*' : ''}`}
        </Text>
        <Text fontSize={'12px'} fontWeight={300}>
          {(properties || {}).description || 'Description'}
        </Text>
      </Flex>
      <Flex flex={1} height={'200px'}>
        <RichTextEditor rootStyle={{ width: '100%', flex: 1, height: '200px' }} toolbarConfig={toolbarConfig} value={valu} onChange={(e) => setValu(e)} />
      </Flex>
    </>
  );
};
