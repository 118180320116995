import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Box, Button, Flex, Text } from 'rebass';
import styled from 'styled-components';

import Logo from './Logo';

const ScreenWrapper = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: linear-gradient(to bottom, #344f79 39%, #6b7f9d 100%);
  transition: opacity 0.4s, visibility -0.3s linear 0.5s;
  color: #dadfe6;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 22px;
`;

const LogoContainer = styled(Box)`
  min-width: 300px;
`;

const StyledButton = styled(Button)`
  color: #dadfe6;
  border-color: #dadfe6;

  &:hover {
    background-color: transparent;
  }
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      return (
        <ScreenWrapper>
          <Flex
            flex={1}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <LogoContainer>
              <Logo dark={true} />
              <Text mt={4} mb={4} fontWeight={600} fontSize={4}>
                Something went wrong
              </Text>
              <StyledButton
                variant="outline"
                color="#fff"
                onClick={() => Sentry.showReportDialog()}
              >
                Report Feedback
              </StyledButton>
            </LogoContainer>
          </Flex>
        </ScreenWrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
