import React from 'react';
import IconContainer from './IconContainer';

const Arrow = props => (
  <IconContainer {...props}>
    <svg data-name="Arrow" viewBox="0 0 146 85" transform={props.rotate}>
      <path
        d="M 0.00 0.00 L 146.00 0.00 L 146.00 85.00 L 0.00 85.00 L 0.00 0.00 Z M 75.20 61.99 C 58.17 44.97 41.12 27.95 24.12 10.88 C 21.78 8.63 19.45 5.54 15.95 5.33 C 11.42 4.86 7.31 8.49 7.22 13.04 C 7.09 15.78 8.45 17.83 10.30 19.69 C 28.98 38.33 47.66 57.02 66.29 75.71 C 68.86 78.18 71.09 81.26 74.99 81.30 C 79.30 81.47 81.62 78.09 84.42 75.42 C 102.24 57.58 120.05 39.72 137.92 21.92 C 140.10 19.65 142.96 17.43 143.18 14.03 C 143.65 9.30 139.79 5.17 135.05 5.30 C 131.11 5.25 128.66 8.63 126.09 11.09 C 109.14 28.07 92.20 45.07 75.20 61.99 Z"
        fill="#ffffff"
      />
      <path
        d="M 15.95 5.33 C 19.45 5.54 21.78 8.63 24.12 10.88 C 41.12 27.95 58.17 44.97 75.20 61.99 C 92.20 45.07 109.14 28.07 126.09 11.09 C 128.66 8.63 131.11 5.25 135.05 5.30 C 139.79 5.17 143.65 9.30 143.18 14.03 C 142.96 17.43 140.10 19.65 137.92 21.92 C 120.05 39.72 102.24 57.58 84.42 75.42 C 81.62 78.09 79.30 81.47 74.99 81.30 C 71.09 81.26 68.86 78.18 66.29 75.71 C 47.66 57.02 28.98 38.33 10.30 19.69 C 8.45 17.83 7.09 15.78 7.22 13.04 C 7.31 8.49 11.42 4.86 15.95 5.33 Z"
        fill="#f9a394"
      />
    </svg>
  </IconContainer>
);

export default Arrow;
