import React, { useEffect, useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import { GET_FILTER_BANNER_TEMPLATE, GET_FILTER_BANNERS, HIDE_FILTER_BANNER } from '../../graphql';
import withSession from '../../lib/withSession';
import { queryMatch } from '../../utils/filterBannerQueryMatcher';
import { Card, Flex, Text } from 'rebass';
import { Drawer } from '../adminBannerFilterBuilder/Drawer';
import { stringify } from '../../utils/htmlStringParser/htmlStringParser';
import withAnalytics from '../../lib/withAnalytics';
import { uuidv4 } from '../../utils/uuid';

const BannerTemplate = ({ bannerId, analytics, banners, refetch }) => {
  const [previewReadMore, setPreviewReadMore] = useState(false);
  // console.log({bannerId, banners})
  useEffect(() => {
    if (!!analytics) {
      const banner = (banners || []).find((x) => x.id === bannerId);
      analytics.track('Filtered banner displayed', {
        bannerId: banner.bannerId,
      });
    }
  }, []);

  const viewReadMore = (id) => {
    setPreviewReadMore(true);
    if (!!analytics) {
      const banner = (banners || []).find((x) => x.id === bannerId);
      analytics.track('Filtered banner view readmore', {
        bannerId: banner.bannerId,
        versionId: id,
      });
    }
  };

  const getId = () => {
    const banner = (banners || []).find((x) => x.id === bannerId);
    // console.log({banner})
    return (banner || {}).bannerId;
  };

  return (
    <Query
      query={GET_FILTER_BANNER_TEMPLATE}
      variables={{
        id: bannerId,
      }}
    >
      {({ loading, data, error }) => {
        if (loading) return <div />;
        if (!data || !data.getFilterBannerTemplate) return null;

        const templateTitle = data.getFilterBannerTemplate.templateTitle;
        const templateBackground = data.getFilterBannerTemplate.templateBackground;
        const templateDescription = data.getFilterBannerTemplate.templateDescription;
        const templateReadMore = data.getFilterBannerTemplate.templateReadMore;

        return (
          <Card p={3} style={{ width: '100%', maxWidth: '700px', backgroundColor: templateBackground || 'white', borderRadius: '10px' }} mb={'16px'}>
            <Flex>
              <Flex flex={1} flexDirection={'column'}>
                {templateTitle && (
                  <Text fontWeight={500} mb={2}>
                    {templateTitle}
                  </Text>
                )}
                {templateDescription && <div dangerouslySetInnerHTML={{ __html: templateDescription || '' }} />}
                {templateReadMore && (
                  <Text textAlign={'right'} style={{ width: '100%', textDecoration: 'underline', cursor: 'pointer' }} mt={3} onClick={() => viewReadMore(data.getFilterBannerTemplate.id)}>
                    read more
                  </Text>
                )}
              </Flex>

              <Mutation
                mutation={HIDE_FILTER_BANNER}
                variables={{
                  id: getId(),
                }}
                // refetchQueries={[{ query: GET_FILTER_BANNERS }]}
                // awaitRefetchQueries
                onCompleted={(e) => {
                  if (refetch) refetch();
                }}
              >
                {(hide, { loading }) => {
                  return (
                    <svg
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        hide();
                        analytics.track('Hide filtered banner', {
                          bannerId: bannerId,
                        });
                      }}
                      style={{ cursor: 'pointer', opacity: !!loading ? 0.5 : 1 }}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C3.58175 0 0 3.58175 0 8C0 12.4185 3.58175 16 8 16C12.4185 16 16 12.4185 16 8C16 3.58175 12.4185 0 8 0ZM8 15.0157C4.14025 15.0157 1 11.8597 1 7.99997C1 4.14022 4.14025 0.999969 8 0.999969C11.8598 0.999969 15 4.14023 15 7.99997C15 11.8597 11.8598 15.0157 8 15.0157ZM10.8282 5.17175C10.633 4.9765 10.3165 4.9765 10.1213 5.17175L8 7.293L5.87875 5.17175C5.6835 4.9765 5.367 4.9765 5.1715 5.17175C4.97625 5.367 4.97625 5.6835 5.1715 5.87875L7.29275 8L5.1715 10.1213C4.97625 10.3163 4.97625 10.6333 5.1715 10.8283C5.36675 11.0235 5.68325 11.0235 5.87875 10.8283L8 8.70702L10.1213 10.8283C10.3165 11.0235 10.633 11.0235 10.8282 10.8283C11.0235 10.6333 11.0235 10.3163 10.8282 10.1213L8.707 8L10.8282 5.87875C11.0237 5.68325 11.0237 5.36675 10.8282 5.17175Z"
                        fill="#4E658A"
                      />
                    </svg>
                  );
                }}
              </Mutation>
            </Flex>
            <Drawer isOpen={previewReadMore} onClose={() => setPreviewReadMore(false)}>
              <Flex style={{ overflowY: 'auto' }}>
                <Text dangerouslySetInnerHTML={{ __html: templateReadMore || '' }} />
              </Flex>
            </Drawer>
          </Card>
        );
      }}
    </Query>
  );
};

const FilteredContent = ({ banners, user, analytics, refetch }) => {
  const [matching, setMatching] = useState();

  useEffect(() => {
    let matched = [];
    if (!!banners) {
      for (let index = 0; index < banners.length; index++) {
        const element = banners[index];
        if (!!element.force) {
          matched.push(element.id);
        } else {
          const p = JSON.parse(element.filter);
          const isMatch = queryMatch(p.filters, user);
          if (isMatch) {
            matched.push(element.id);
          }
        }
      }
    }
    setMatching(matched);
  }, []);
  if (!matching) return null;
  return (
    <>
      {matching.map((x) => {
        return <BannerTemplate key={x} bannerId={x} analytics={analytics} banners={banners} refetch={refetch} />;
      })}
    </>
  );
};

const FilteredBanner = ({ session, analytics }) => {
  const [k, setK] = useState(uuidv4());
  return (
    <Query query={GET_FILTER_BANNERS} fetchPolicy={'network-only'}>
      {({ loading, data, error, refetch }) => {
        if (loading) return <div />;
        return (
          <FilteredContent
            key={k}
            banners={data.getFilterBanners}
            user={session}
            analytics={analytics}
            refetch={() => {
              refetch();
              setK(uuidv4());
            }}
          />
        );
      }}
    </Query>
  );
};

export default withAnalytics(withSession(FilteredBanner));
