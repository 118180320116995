import logoAetna from '../../../static/logoAetna.png';
import logoAnthem from '../../../static/logoAnthem.png';
import logoBcbs from '../../../static/logoBcbs.png';
import logoBlueCross from '../../../static/logoBlueCross.png';
import logoBlueShield from '../../../static/logoBlueShield.png';
import logoBlueShieldCa from '../../../static/logoBlueShield-CA.png';
import logoHumana from '../../../static/logoHumana.png';
import logoCigna from '../../../static/logoCigna.png';
import logoCentene from '../../../static/logoCentene.png';
import logoKP from '../../../static/logoKP.png';
import logoMolina from '../../../static/logoMolina.png';
import logoWellcare from '../../../static/logoWellcare.png';
import logoUhc from '../../../static/logoUhc.png';
import logoMultiplan from '../../../static/logoMultiplan.png';
import logoFirstHealth from '../../../static/logoFirstHealth.png';
import logoPriorityHealth from '../../../static/logoPriorityHealth.png';

export const getLogoSource = (insuranceProvider) => {
  if (!insuranceProvider) return '';
  // console.log('insuranceProvider', insuranceProvider)
  switch (insuranceProvider.toLowerCase()) {
    case 'aetna':
    case 'aetna & aetna/us healthcare':
      return logoAetna;
    case 'anthem':
      return logoAnthem;
    case 'blue cross':
      return logoBlueCross;
    case 'blue shield':
      return logoBlueShield;
    case 'blue shield of california':
    case 'blue shield ca':
      return logoBlueShieldCa;
    case 'blue cross blue shield':
      return logoBcbs;
    case 'cigna':
    case 'healthpartners':
    case 'healthpartners-cigna':
    case 'healthpartners - cigna':
      return logoCigna;
    case 'humana':
      return logoHumana;
    case 'centene':
      return logoCentene;
    case 'multiplan':
      return logoMultiplan;
    case 'kaiser permanente':
      return logoKP;
    case 'molina healthcare':
      return logoMolina;
    case 'wellcare health plans':
      return logoWellcare;
    case 'united healthcare':
    case 'unitedhealthcare':
    case 'united healthone':
    case 'united health one':
    case 'unitedhealthone':
      return logoUhc;
    case 'first health':
    case 'firsthealth':
      return logoFirstHealth;
    case 'priority health':
    case 'priorityhealth':
      return logoPriorityHealth;
    default:
      return '';
  }
};
