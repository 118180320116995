import React from 'react';
import { Box, Card, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useUnmount from '../../utils/hooks/lifecycle/useUnmount';
import TextTransition, { presets } from 'react-text-transition';

import Stethoscope from '../../static/DocWithSamples.png';
import Beaker from '../../components/icons/Beaker';

const CardFlex = styled(Card)({
  display: 'flex',
  flex: '1 1 auto',
  alignItems: 'center',
  justifyContent: 'center',
});
const TEXTS = ['mood swings', 'hot flashes', 'night sweats', 'skin changes', 'problems sleeping', 'weight gain', 'vaginal dryness', 'brain fog'];
const RotatingText = (props) => {
  const [index, setIndex] = React.useState(0);
  const [intervalId, setIntervalId] = React.useState(null);
  const [arr, setArr] = React.useState(TEXTS);

  React.useEffect(() => {
    const handle = setInterval(
      () => {
        setIndex((index) => {
          if (index + 1 < TEXTS.length) {
            return index + 1;
          } else {
            return 0;
          }
        });
      },
      2000 // every 10 seconds
    );
    setIntervalId(handle);
  }, []);

  useUnmount(() => {
    // console.log('unmount', intervalId);
    if (intervalId) {
      clearInterval(intervalId);
    }
  });

  return (
    <Box
      style={{
        whiteSpace: 'nowrap',
        marginTop: '40px',
        // width: '100%',
        maxWidth: '350px',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <TextTransition
        noOverflow
        style={{
          whiteSpace: 'nowrap',
          wordWrap: 'break-word',
          color: 'rgb(250, 169, 169)',
          fontWeight: 500,
          textDecoration: 'underline !important',
          fontSize: '20px',
        }}
        text={index < arr.length ? arr[index] : 'Finalizing...'}
        springConfig={presets.default}
      />
      <div
        style={{
          height: '2px',
          // width: '150px',
          backgroundColor: 'rgb(250, 169, 169)',
          color:'transparent',
          fontWeight: 500,
          fontSize: '20px',
          transition:'all 0.35s cubic-bezier(0, 0.51, 0, 0.99) 0.2s'
        }}
      >
        {index < arr.length ? arr[index] : 'Finalizing...'}
      </div>
    </Box>
  );
};

const Aside = () => (
  <Flex flexDirection="column" width={[1, 1, 1 / 3]}>
    <Card mb={4} p={4}>
      <Flex mb={4} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        <Box mr={3}>
          <img src={Stethoscope} height={137} />
        </Box>

        <Box px={2} mt={3}>
          <Text
            style={{
              textAlign: 'center',
              fontWeight: 500,
              fontSize: '16px',
              color: '#486288'
            }}
          >
            Expert hormonal care<br />to treat your
          </Text>
        </Box>
        <div style={{ marginTop: '-30px' }}>
          <RotatingText />
        </div>
      </Flex>
    </Card>

    <CardFlex p={4}>
      <Flex flexDirection="column" alignItems="center">
        <Text mb={2} style={{ textAlign: 'center' }}>
          Already have an account?
        </Text>
        <Box>
          <Link to="/login">Login</Link>
        </Box>
      </Flex>
    </CardFlex>
  </Flex>
);

export default Aside;
