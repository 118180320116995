import React, { useState } from 'react';
import { Elements } from 'react-stripe-elements';
import { Section } from './components/Section';
import subscriptionsVitamins from '../../static/subscriptionsVitamins.png';
import KitsContent from './editing/KitsContent';
import { withToastManager } from 'react-toast-notifications';

const K = ({ session, toastManager }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Section
      card={session.card}
      title="Thyroid Kits and Vitamin supplements"
      icon={subscriptionsVitamins}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <Elements>
        <KitsContent
          onSubmit={() => {
            setIsExpanded(false);
            toastManager.add('Card updated.', {
              appearance: 'success',
            });
          }}
        />
      </Elements>
    </Section>
  );
};

export const Kits = withToastManager(K);
