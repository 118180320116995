import React, { useState } from 'react';
import { Mutation, Query, compose } from 'react-apollo';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import Modal from 'styled-react-modal';
import ConfirmModal from '../components/ConfirmModal';
import PleaseWait from '../components/PleaseWait';
import { ADMIN_GET_HEALTH_COACHES_LIST, DELETE_HEALTHCOACH_USER, GET_CALENDLY_USER_BY_EMAIL } from '../graphql';
import CareManagersList from './adminCareManagers/CareManagersList';
import ModalSearchUser from './adminCareManagers/ModalSearchUser';
import HealthCoachesList from './adminHealthCoaches/HealthCoachesList';
import CalendlyUsersList from './adminHealthCoaches/CalendlyUsersList';
import { withToastManager } from 'react-toast-notifications';
import { SpecialModalBackground } from './adminReorderPhysicianConvo/shared';
import EditNewHealthCoachUser from './adminHealthCoaches/EditNewHealthCoachUser';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;
const ModalInner = styled(Box)`
  max-width: 80vw;
  min-width: 80vw;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;
const PeriodSelectorItem = styled(Box)`
  padding: 8px 16px;
  border-radius: 24px;
  margin-left: 4px;
  margin-right: 12px;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.isSelected ? '#354E79' : 'transparent')};
  color: ${(props) => (!props.isSelected ? '#354E79' : 'white')};
  cursor: pointer;
`;

const AdminHealthCoaches = ({ toastManager }) => {
  const [viewCalendlyUsers, setviewCalendlyUsers] = useState(false);
  const [edit, setEdit] = useState(null);
  return (
    <Container>
      <Heading flex={1} mb={4}>
        HEALTH COACHES MANAGEMENT
      </Heading>
      <Flex mb={4}>
        <PeriodSelectorItem onClick={() => setviewCalendlyUsers(false)} isSelected={!viewCalendlyUsers}>
          View Health Coaches
        </PeriodSelectorItem>
        <PeriodSelectorItem onClick={() => setviewCalendlyUsers(true)} isSelected={!!viewCalendlyUsers}>
          View Calendly Users
        </PeriodSelectorItem>
      </Flex>
      <Query query={ADMIN_GET_HEALTH_COACHES_LIST}>
        {({ data, loading, error }) => {
          if (!!loading) return <PleaseWait />;
          return (
            <>
              {!viewCalendlyUsers ? (
                <Mutation
                  mutation={DELETE_HEALTHCOACH_USER}
                  refetchQueries={[{query: ADMIN_GET_HEALTH_COACHES_LIST}]}
                  awaitRefetchQueries={true}
                  onCompleted={(data) => {
                    if (!data || !data.adminDeleteHealthCoach || !data.adminDeleteHealthCoach.ok) {
                      toastManager.add(((data || {}).adminDeleteHealthCoach || {}).customError || 'An error occured', {
                        appearance: 'error',
                      });
                      return;
                    }
                    toastManager.add('Health coach deleted', {
                      appearance: 'success',
                    });
                  }}
                  onError={() => {
                    toastManager.add('An error occured', {
                      appearance: 'error',
                    });
                  }}
                >
                  {(deleteCoach, { loading }) => (
                    <HealthCoachesList
                      data={data}
                      onEditClick={setEdit}
                      onDeleteClick={(u) =>
                        deleteCoach({
                          variables: {
                            id: u,
                          },
                        })
                      }
                    />
                  )}
                </Mutation>
              ) : (
                <Query query={GET_CALENDLY_USER_BY_EMAIL}>
                  {({ data: dataCalendly, loading: loadingCalendly, error }) => {
                    if (!!loading || !!loadingCalendly) return <PleaseWait />;

                    return <CalendlyUsersList data={dataCalendly} healthCoaches={data} />;
                  }}
                </Query>
              )}
            </>
          );
        }}
      </Query>

      {!!edit && (
        <Modal
          isOpen={true}
          onBackgroundClick={() => {
            setEdit(null);
          }}
          onEscapeKeydown={() => {
            setEdit(null);
          }}
        >
          <SpecialModalBackground>
            <Box
              style={{
                margin: '0 auto',
                alignSelf: 'center',
                borderRadius: '20px',
                position: 'relative',
                backgroundColor: 'white',
                padding: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                maxWidth: '95vw',
                minWidth: 'initial',
                maxHeight: '95vh',
                overflow: 'hidden'
              }}
            >
              <EditNewHealthCoachUser selectedCalendlyUser={edit} onClose={()=>setEdit(null)} />
            </Box>
          </SpecialModalBackground>
        </Modal>
      )}
    </Container>
  );
};

export default compose(withToastManager)(AdminHealthCoaches);
