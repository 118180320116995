import React, { Component, Fragment } from 'react';
import { Steps, Step, Wizard } from 'react-albus';
import { withRouter } from 'react-router-dom';
import { compose, withApollo } from 'react-apollo';
import { Box, Flex, Text } from 'rebass';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';

import FullLayout from '../../components/FullLayout';
import Header from '../../components/Header';
import LoadingScreen from '../../components/LoadingScreen';
import Basic from './Basic';
import Profile from './Profile';
import Extended from './Extended';
import moment from 'moment';

import { GET_ANALYTICS_ID, SIGNUP, MEMBERSHIP_CREATE_SESSION } from '../../graphql';
import withAnalytics from '../../lib/withAnalytics';
import { isToggleActive } from '../../components/featureToggle/toggles';
import { getRegionByAbbreviation } from '../../components/RegionSelect';
import Otp from './Otp';
import GenderIdentity from './GenderIdentity';
import PreferredPronouns from './PreferredPronouns';
import BirthSex from './BirthSex';
import LegalSex from './LegalSex';
import { withCookies } from 'react-cookie';

const initalState = {
  values: {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    dob: '',
    phone: '',
    gender: '',
    genderidentity: '',
    genderidentityother: null,
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    consent: false,
    diagnosed: null,
    mfa: null,
    bypassOtp: false,
  },
};

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

class Registration extends Component {
  constructor(props, context) {
    super(props, context);

    let initial = initalState;

    const isSubscribe = this.props.location && this.props.location.state && this.props.location.state.subscribe;

    const frequency = this.props.location && this.props.location.state && this.props.location.state.frequency;

    const continueWith = this.props.location && this.props.location.state && this.props.location.state.continueWith;

    if (this.props.location && this.props.location.state && this.props.location.state.orderKit) {
      initial = {
        values: {
          ...initalState.values,
          ...this.props.location.state.orderKit,
        },
      };
    }

    let forwardToSelectPlan = false;
    let trial = false;
    // if (this.props.location && this.props.location.search && this.props.location.search.indexOf('community') > -1) {
    //   forwardToCommunity = true;
    // }
    if (this.props.location && (this.props.location.search || '').indexOf('q=1') > -1) {
      forwardToSelectPlan = true;
    }
    if (this.props.location && (this.props.location.search || '').indexOf('q=trial') > -1) {
      forwardToSelectPlan = true;
      trial = true;
    }

    this.state = {
      ...initial,
      isSubscribe,
      frequency,
      continueWith,
      forwardToSelectPlan,
      trial,
    };
  }

  componentDidMount() {
    let tag = '';
    if (this.props.location.pathname.indexOf('basic') > -1) {
      tag = 'basic';
    } else if (this.props.location.pathname.indexOf('profile') > -1) {
      tag = 'profile';
    } else if (this.props.location.pathname.indexOf('extended') > -1) {
      tag = 'extended';
    }
    if (tag) {
      this.props.analytics.page(tag, '/registration');
    }
    // console.log('ANA CDM', {tag, reg: 'registration'})
  }

  componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;
    if (currentPage !== prevPage) {
      let tag = '';

      if (currentPage.indexOf('basic') > -1) {
        tag = 'basic';
      } else if (currentPage.indexOf('profile') > -1) {
        tag = 'profile';
      } else if (currentPage.indexOf('extended') > -1) {
        tag = 'extended';
      }
      if (tag) {
        this.props.analytics.page(tag, '/registration');
      }
    }
  }

  handleSubmit = (values, actions, wizard, force) => {
    this.setState({ values: { ...values } });
    // console.log('Values', values)
    if (!!actions && !!actions.setSubmitting) {
      actions.setSubmitting(false);
    }
    if (!!force) {
      wizard.push(force);
    } else {
      wizard.next();
    }
  };

  handleComplete = async (values, actions, wizard) => {
    const { toastManager, analytics } = this.props;
    const { isSubscribe, continueWith, frequency, forwardToSelectPlan, trial } = this.state;

    this.setState({
      values: { ...values },
      loading: true,
    });

    const variables = this.state.values;

    let isNpThyroid = false;
    try {
      const { cookies } = this.props;
      const hasCookie = cookies.get('referralNP');
      isNpThyroid = !!hasCookie;
    } catch (error) {}

    try {
      const { data } = await this.props.client.mutate({
        mutation: SIGNUP,
        variables: {
          ...variables,
          isNPThyroid: isNpThyroid,
        },
      });

      const attemptedRoute = localStorage.getItem('route');
      localStorage.setItem('token', data.signup.token);
      const aa = await this.props.client.query({
        query: GET_ANALYTICS_ID,
        fetchPolicy: 'network-only',
      });
      let age = 0;
      try {
        age = moment().diff(moment(variables.dob, 'MM/DD/YYYY'), 'years');
      } catch (error) {}
      if (aa && aa.data && aa.data.getAnalyticsId) {
        analytics.Alias(aa.data.getAnalyticsId);
        analytics.Identify(aa.data.getAnalyticsId, {
          age,
          gender: variables.gender,
          // city: variables.city,
          // zip: variables.zip,
          state: variables.state,
          // dob: variables.dob,
          // phone: variables.phone,
          // departmentId: (data.signup.user || {}).departmentId,
          diagnosed: variables.diagnosed,
        });
      }

      analytics.track('Account Created', {
        age,
        gender: variables.gender,
        // city: variables.city,
        // zip: variables.zip,
        // departmentId: (data.signup.user || {}).departmentId,
        diagnosed: variables.diagnosed,
        state: variables.state,
      });
      if (this.props && this.props.location && this.props.location.pathname === '/register') {
        this.props.history.push('/register-kit');
      } else {
        if (isSubscribe) {
          let priceId = 'Paloma_Health_Membership_202786869790172_1000_20';
          let freq = frequency === 'annualy' ? 'ma' : 'mm';

          const ft_membership_price_2024 = isToggleActive('MEMBERSHIP_PRICE_2024');
          if (frequency === 'annualy') {
            if (ft_membership_price_2024) {
              priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL_2024;
            } else {
              priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL;
            }
          } else {
            priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_MONTHLY;
          }
          try {
            const createSession = await this.props.client.mutate({
              mutation: MEMBERSHIP_CREATE_SESSION,
              variables: {
                priceId,
                forwardUrl: continueWith === '/schedule' ? `subscribed/book/success/${freq}` : `subscribed/call/${freq}`,
                cancel_url: attemptedRoute || '',
              },
            });
            // console.log({ createSession });
            const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_KEY);
            const stripe = await stripePromise;
            await stripe.redirectToCheckout({
              sessionId: createSession.data.createCheckoutSession.sessionId,
            });
          } catch (error) {
            this.setState({ loading: false });

            actions.setSubmitting(false);

            this.props.history.push(attemptedRoute || '/');
          }
        } else {
          const dept = getRegionByAbbreviation(variables.state);

          if (isToggleActive('MEMBERSHIP') && dept.level !== 'none' && !!variables.diagnosed) {
            if (!!continueWith && continueWith === '/community-terms') {
              this.props.history.push('/community-terms');
            } else {
              if (forwardToSelectPlan) {
                this.props.history.push(`/join-membership${trial ? '?q=trial' : ''}`);
              } else {
                this.props.history.push('/join-member');
              }
            }
          } else {
            this.props.history.push(attemptedRoute || '/');
          }
        }
      }
    } catch (error) {
      this.setState({ loading: false });

      actions.setSubmitting(false);

      error.graphQLErrors.forEach((error) => {
        toastManager.add(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
    }
  };

  render() {
    const { history } = this.props;
    const { loading } = this.state;
    if (loading) {
      return <LoadingScreen loading={true} />;
    }
    const stepsVal = [
      {
        comp: (
          <Step id="basic">
            <Basic onSubmit={this.handleSubmit} values={this.state.values} />
          </Step>
        ),
        key: 'basic',
        display: true,
      },
      {
        comp: (
          <Step id="profile">
            <Profile onSubmit={this.handleSubmit} values={this.state.values} />
          </Step>
        ),
        key: 'profile',
        display: true,
      },
      {
        comp: (
          <Step id="otp">
            <Otp
              onSubmit={this.handleSubmit}
              values={this.state.values}
              changePhone={(phone, mutate) => {
                this.setState({
                  values: { ...this.state.values, phone },
                });
              }}
            />
          </Step>
        ),
        key: 'otp',
        display: isToggleActive('OTP_VERIFICATION'),
      },
      {
        comp: (
          <Step id="birthsex">
            <BirthSex onSubmit={this.handleSubmit} values={this.state.values} />
          </Step>
        ),
        key: 'basic',
        display: true,
      },
      {
        comp: (
          <Step id="legalsex">
            <LegalSex onSubmit={this.handleSubmit} values={this.state.values} />
          </Step>
        ),
        key: 'basic',
        display: true,
      },
      {
        comp: (
          <Step id="genderIdentity">
            <GenderIdentity onSubmit={this.handleSubmit} values={this.state.values} />
          </Step>
        ),
        key: 'basic',
        display: true,
      },
      {
        comp: (
          <Step id="pronouns">
            <PreferredPronouns onSubmit={this.handleSubmit} values={this.state.values} />
          </Step>
        ),
        key: 'basic',
        display: true,
      },
      {
        comp: (
          <Step id="extended">
            <Extended onSubmit={this.handleComplete} values={this.state.values} />
          </Step>
        ),
        key: 'basic',
        display: true,
      },
    ];

    return (
      <Fragment>
        <Container flex={1} px={3}>
          <Flex flex={1}>
            <Header dark={false} mb={0}>
              {this.props.location.pathname.indexOf('basic') > -1 && (
                <Text color="#486288" fontSize={3} fontWeight={500} textAlign="center">
                  Hi there! Let's get to know each other.
                </Text>
              )}
            </Header>
          </Flex>
          <Wizard history={history} basename="/registration">
            <Steps>{stepsVal.filter((x) => x.display).map((x) => x.comp)}</Steps>
          </Wizard>
        </Container>
        <FullLayout />
      </Fragment>
    );
  }
}

export default compose(
  withCookies,
  withApollo,
  withRouter,
  withToastManager,
  withAnalytics
)(Registration);
