import React from 'react';
import { Card, Flex, Heading, Text, Box } from 'rebass';
import Select from 'react-select';
import styled from 'styled-components';
import LabResultsTrend from '../../components/LabResultsTrend';
import moment from 'moment';
import { hormoneName } from '../LabDetail';
import { Query } from 'react-apollo';
import { LABS } from '../../graphql';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;
class LabResultsTrendPageContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMarker: null,
    };
  }

  rangesCalculator = (labs) => {
    // console.log('rangesCalculator', labs);
    let minTsh = null;
    let maxTsh = null;
    let normalTshRange = {};
    let maxTpo = null;
    let minTpo = null;
    let normalTpoRange = {};
    let maxFt3 = null;
    let minFt3 = null;
    let normalfT3Range = {};
    let maxFt4 = null;
    let minFt4 = null;
    let normalfT4Range = {};
    let maxRT3 = null;
    let minRT3 = null;
    let normalrT3Range = {};
    let maxVitD = null;
    let minVitD = null;
    let normalVitDRange = {};
    for (let index = 0; index < labs.length; index++) {
      const element = labs[index];
      const _tsh = element.results.outcome.filter((x) => x.hormone === 'TSH' || x.hormone === 'TSH-Blood Spot');
      const _tpo = element.results.outcome.filter((x) => x.hormone === 'TPO' || x.hormone === 'TPO-Blood Spot');
      const _fT3 = element.results.outcome.filter((x) => x.hormone === 'fT3' || x.hormone === 'fT3-Blood Spot');
      const _fT4 = element.results.outcome.filter((x) => x.hormone === 'fT4' || x.hormone === 'fT4-Blood Spot');
      const _rT3 = element.results.outcome.filter((x) => x.hormone === 'rT3' || x.hormone === 'Reverse T3');
      const _vitD = element.results.outcome.filter((x) => x.hormone === 'Vitamin D' || x.hormone === 'Vitamin D-Blood Spot' || x.hormone === 'Vitamin D - Blood Spot');

      if (_tsh && _tsh.length > 0) {
        const val =
          parseInt(
            (_tsh[0].value || 0)
              .replace('< ', '')
              .replace('> ', '')
              .replace(' >', '')
              .replace(' <', '')
              .replace('>', '')
              .replace('<', '') * 100,
            10
          ) / 100;
        if (!maxTsh || maxTsh < val) {
          // const { referenceRange } = _tsh[0];
          const topRange = hormoneName['TSH'].max;
          const bottomRange = hormoneName['TSH'].min;
          normalTshRange = {
            low: parseInt(bottomRange * 100, 10) / 100,
            high: parseInt(topRange * 100, 10) / 100,
          };
          maxTsh = val;
        }
        if (!minTsh || minTsh > val) {
          minTsh = val;
        }
      }

      if (_tpo && _tpo.length > 0) {
        const val =
          parseInt(
            (_tpo[0].value || 0)
              .replace('< ', '')
              .replace('> ', '')
              .replace(' >', '')
              .replace(' <', '')
              .replace('>', '')
              .replace('<', '') * 100,
            10
          ) / 100;

        if (!maxTpo || maxTpo < val) {
          const topRange = hormoneName['TPO'].max;
          const bottomRange = hormoneName['TPO'].min;
          normalTpoRange = {
            low: parseInt(bottomRange * 100, 10) / 100,
            high: parseInt(topRange * 100, 10) / 100,
          };
          maxTpo = val;
        }
        if (!minTpo || minTpo > val) {
          minTpo = val;
        }
      }

      if (_fT3 && _fT3.length > 0) {
        const val =
          parseInt(
            (_fT3[0].value || 0)
              .replace('< ', '')
              .replace('> ', '')
              .replace(' >', '')
              .replace(' <', '')
              .replace('>', '')
              .replace('<', '') * 100,
            10
          ) / 100;

        if (!maxFt3 || maxFt3 < val) {
          const topRange = hormoneName['fT3'].max;
          const bottomRange = hormoneName['fT3'].min;
          normalfT3Range = {
            low: parseInt(bottomRange * 100, 10) / 100,
            high: parseInt(topRange * 100, 10) / 100,
          };
          maxFt3 = val;
        }
        if (!minFt3 || minFt3 > val) {
          minFt3 = val;
        }
      }

      if (_fT4 && _fT4.length > 0) {
        const val =
          parseInt(
            (_fT4[0].value || 0)
              .replace('< ', '')
              .replace('> ', '')
              .replace(' >', '')
              .replace(' <', '')
              .replace('>', '')
              .replace('<', '') * 100,
            10
          ) / 100;

        if (!maxFt4 || maxFt4 < val) {
          const topRange = hormoneName['fT4'].max;
          const bottomRange = hormoneName['fT4'].min;
          normalfT4Range = {
            low: parseInt(bottomRange * 100, 10) / 100,
            high: parseInt(topRange * 100, 10) / 100,
          };
          maxFt4 = val;
        }
        if (!minFt4 || minFt4 > val) {
          minFt4 = val;
        }
      }

      if (_rT3 && _rT3.length > 0) {
        const val =
          parseInt(
            (_rT3[0].value || 0)
              .replace('< ', '')
              .replace('> ', '')
              .replace(' >', '')
              .replace(' <', '')
              .replace('>', '')
              .replace('<', '') * 100,
            10
          ) / 100;

        if (!maxRT3 || maxRT3 < val) {
          const topRange = hormoneName['rT3'].max;
          const bottomRange = hormoneName['rT3'].min;
          normalrT3Range = {
            low: parseInt(bottomRange * 100, 10) / 100,
            high: parseInt(topRange * 100, 10) / 100,
          };
          maxRT3 = val;
        }
        if (!minRT3 || minRT3 > val) {
          minRT3 = val;
        }
      }

      if (_vitD && _vitD.length > 0) {
        const val =
          parseInt(
            (_vitD[0].value || 0)
              .replace('< ', '')
              .replace('> ', '')
              .replace(' >', '')
              .replace(' <', '')
              .replace('>', '')
              .replace('<', '') * 100,
            10
          ) / 100;

        if (!maxVitD || maxVitD < val) {
          const topRange = hormoneName['Vitamin D'].max;
          const bottomRange = hormoneName['Vitamin D'].min;
          normalVitDRange = {
            low: parseInt(bottomRange * 100, 10) / 100,
            high: parseInt(topRange * 100, 10) / 100,
          };
          maxVitD = val;
        }
        if (!minVitD || minVitD > val) {
          minVitD = val;
        }
      }
    }

    return {
      tsh: {
        low: normalTshRange.low,
        normal: {
          low: normalTshRange.low,
          high: normalTshRange.high,
        },
        high: (maxTsh > normalTshRange.high ? maxTsh - normalTshRange.high : maxTsh) - normalTshRange.low + 1,
      },
      tpo: {
        low: 0,
        normal: {
          low: normalTpoRange.low,
          high: normalTpoRange.high,
        },
        high: (maxTpo > normalTpoRange.high ? maxTpo - normalTpoRange.high : maxTpo) - normalTpoRange.low + 1,
      },
      fT3: {
        low: normalfT3Range.low,
        normal: {
          low: normalfT3Range.low,
          high: normalfT3Range.high,
        },
        high: (maxFt3 > normalfT3Range.high ? maxFt3 - normalfT3Range.high : maxFt3) - normalfT3Range.low + 1,
      },
      fT4: {
        low: normalfT4Range.low,
        normal: {
          low: normalfT4Range.low,
          high: normalfT4Range.high,
        },
        high: (maxFt3 > normalfT4Range.high ? maxFt4 - normalfT4Range.high : maxFt4) - normalfT4Range.low + 1,
      },
      rT3: {
        low: normalrT3Range.low,
        normal: {
          low: normalrT3Range.low,
          high: normalrT3Range.high,
        },
        high: (maxRT3 > normalrT3Range.high ? maxRT3 - normalrT3Range.high : maxRT3) - normalrT3Range.low + 1,
      },
      vitD: {
        low: normalVitDRange.low,
        normal: {
          low: normalVitDRange.low,
          high: normalVitDRange.high,
        },
        high: (maxVitD > normalVitDRange.high ? maxVitD - normalVitDRange.high : maxVitD) - normalVitDRange.low + 1,
      },
    };
  };

  render() {
    const { labs } = this.props;
    const { selectedMarker } = this.state;
    const tsh = [];
    const fT3 = [];
    const fT4 = [];
    const tpo = [];
    const rT3 = [];
    const vitD = [];
    const ranges = this.rangesCalculator(labs);
    const markers = [];
    for (let index = 0; index < labs.length; index++) {
      const element = labs[index];
      const _tsh = element.results.outcome.filter((x) => x.hormone === 'TSH' || x.hormone === 'TSH-Blood Spot' || x.hormone === 'TSH - Blood Spot');
      const _tpo = element.results.outcome.filter((x) => x.hormone === 'TPO' || x.hormone === 'TPO-Blood Spot' || x.hormone === 'TPO - Blood Spot');
      const _fT3 = element.results.outcome.filter((x) => x.hormone === 'fT3' || x.hormone === 'fT3-Blood Spot' || x.hormone === 'fT3 - Blood Spot');
      const _fT4 = element.results.outcome.filter((x) => x.hormone === 'fT4' || x.hormone === 'fT4-Blood Spot' || x.hormone === 'fT4 - Blood Spot');
      const _rT3 = element.results.outcome.filter((x) => x.hormone === 'rT3' || x.hormone === 'Reverse T3');
      const _vitD = element.results.outcome.filter((x) => x.hormone === 'Vitamin D' || x.hormone === 'Vitamin D-Blood Spot' || x.hormone === 'Vitamin D - Blood Spot');

      if (_tsh && _tsh.length > 0) {
        tsh.push({
          id: element.id,
          created: moment(element.created).format('MM-DD-YYYY'),
          value:
            parseInt(
              (_tsh[0].value || 0)
                .replace(/ /g, '')
                .replace('<', '')
                .replace('>', '') * 100,
              10
            ) / 100,
          lowRange: ranges.tsh.low,
          highRange: ranges.tsh.high,
          normalRangeHigh: ranges.tsh.normal.high,
          normalRangeLow: ranges.tsh.normal.low,
          yAxisMinTop: 6,
          yAxisInterval: 2,
        });
      }

      if (_tpo && _tpo.length > 0) {
        tpo.push({
          id: element.id,
          created: moment(element.created).format('MM-DD-YYYY'),
          value:
            parseInt(
              (_tpo[0].value || 0)
                .replace(/ /g, '')
                .replace('<', '')
                .replace('>', '') * 100,
              10
            ) / 100,
          lowRange: ranges.tpo.low,
          highRange: ranges.tpo.high,
          normalRangeHigh: ranges.tpo.normal.high,
          normalRangeLow: ranges.tpo.normal.low,
          yAxisMinTop: 80,
          yAxisInterval: 20,
        });
      }

      if (_fT3 && _fT3.length > 0) {
        fT3.push({
          id: element.id,
          created: moment(element.created).format('MM-DD-YYYY'),
          value:
            parseInt(
              (_fT3[0].value || 0)
                .replace(/ /g, '')
                .replace('<', '')
                .replace('>', '') * 100,
              10
            ) / 100,
          lowRange: ranges.fT3.low,
          highRange: ranges.fT3.high,
          normalRangeHigh: ranges.fT3.normal.high,
          normalRangeLow: ranges.fT3.normal.low,
          yAxisMinTop: 6,
          yAxisInterval: 2,
        });
      }

      if (_fT4 && _fT4.length > 0) {
        fT4.push({
          id: element.id,
          created: moment(element.created).format('MM-DD-YYYY'),
          value:
            parseInt(
              (_fT4[0].value || 0)
                .replace(/ /g, '')
                .replace('<', '')
                .replace('>', '') * 100,
              10
            ) / 100,
          lowRange: ranges.fT4.low,
          highRange: ranges.fT4.high,
          normalRangeHigh: ranges.fT4.normal.high,
          normalRangeLow: ranges.fT4.normal.low,
          yAxisMinTop: 3,
          yAxisInterval: 1,
        });
      }

      if (_rT3 && _rT3.length > 0) {
        rT3.push({
          id: element.id,
          created: moment(element.created).format('MM-DD-YYYY'),
          value:
            parseInt(
              (_rT3[0].value || 0)
                .replace(/ /g, '')
                .replace('<', '')
                .replace('>', '') * 100,
              10
            ) / 100,
          lowRange: ranges.rT3.low,
          highRange: ranges.rT3.high,
          normalRangeHigh: ranges.rT3.normal.high,
          normalRangeLow: ranges.rT3.normal.low,
          yAxisMinTop: 30,
          yAxisInterval: 5,
        });
      }

      if (_vitD && _vitD.length > 0) {
        vitD.push({
          id: element.id,
          created: moment(element.created).format('MM-DD-YYYY'),
          value:
            parseInt(
              (_vitD[0].value || 0)
                .replace(/ /g, '')
                .replace('<', '')
                .replace('>', '') * 100,
              10
            ) / 100,
          lowRange: ranges.vitD.low,
          highRange: ranges.vitD.high,
          normalRangeHigh: ranges.vitD.normal.high,
          normalRangeLow: ranges.vitD.normal.low,
          yAxisMinTop: 80,
          yAxisInterval: 20,
        });
      }
    }

    if (tsh.length > 1) {
      markers.push({
        label: 'TSH',
        value: 'TSH',
      });
    }

    if (tpo.length > 1) {
      markers.push({
        label: 'TPO',
        value: 'TPO',
      });
    }

    if (fT3.length > 1) {
      markers.push({
        label: 'fT3',
        value: 'fT3',
      });
    }

    if (fT4.length > 1) {
      markers.push({
        label: 'fT4',
        value: 'fT4',
      });
    }

    if (rT3.length > 1) {
      markers.push({
        label: 'rT3',
        value: 'rT3',
      });
    }

    if (vitD.length > 1) {
      markers.push({
        label: 'Vit. D',
        value: 'vitD',
      });
    }

    const vals = {
      fT3: fT3.sort((a, b) => {
        const aa = moment(a.created, 'MM-DD-YYYY');
        const bb = moment(b.created, 'MM-DD-YYYY');
        if (aa.isBefore(bb)) return -1;
        if (aa.isAfter(bb)) return 1;
        return 0;
      }),
      fT4: fT4.sort((a, b) => {
        const aa = moment(a.created, 'MM-DD-YYYY');
        const bb = moment(b.created, 'MM-DD-YYYY');
        if (aa.isBefore(bb)) return -1;
        if (aa.isAfter(bb)) return 1;
        return 0;
      }),
      TPO: tpo.sort((a, b) => {
        const aa = moment(a.created, 'MM-DD-YYYY');
        const bb = moment(b.created, 'MM-DD-YYYY');
        if (aa.isBefore(bb)) return -1;
        if (aa.isAfter(bb)) return 1;
        return 0;
      }),
      TSH: tsh.sort((a, b) => {
        const aa = moment(a.created, 'MM-DD-YYYY');
        const bb = moment(b.created, 'MM-DD-YYYY');
        if (aa.isBefore(bb)) return -1;
        if (aa.isAfter(bb)) return 1;
        return 0;
      }),
      rT3: rT3.sort((a, b) => {
        const aa = moment(a.created, 'MM-DD-YYYY');
        const bb = moment(b.created, 'MM-DD-YYYY');
        if (aa.isBefore(bb)) return -1;
        if (aa.isAfter(bb)) return 1;
        return 0;
      }),
      vitD: vitD.sort((a, b) => {
        const aa = moment(a.created, 'MM-DD-YYYY');
        const bb = moment(b.created, 'MM-DD-YYYY');
        if (aa.isBefore(bb)) return -1;
        if (aa.isAfter(bb)) return 1;
        return 0;
      }),
    };
    const hormone = hormoneName[selectedMarker];

    if (!selectedMarker) {
      this.setState({ selectedMarker: markers[0].value });
      return <div />;
    }

    return (
      <Flex flexDirection={'column'} style={{ flex: 'none' }}>
        <Flex flexDirection={['column', 'column', 'row']} p={'8px'} mb={4}>
          <Heading style={{ flex: 1 }}>Lab Results Trend</Heading>
          <div style={{ width: '250px', minWidth: '250px' }}>
            <Select
              classNamePrefix="region-select"
              options={markers}
              value={markers.filter((element) => element.value === selectedMarker)}
              onChange={(option) => {
                this.setState({
                  selectedMarker: option.value,
                });
              }}
              theme={(base) => ({
                ...base,
                colors: {
                  ...base.colors,
                  primary: '#364f79',
                  primary50: '#dae6fa',
                },
              })}
            />
          </div>
        </Flex>
        <Card p={[3, 4]} mb={4}>
          <Flex flexDirection={['column']} flex={1}>
            <Flex flexDirection="column" flex={1}>
              <Text mb={4}>Your {selectedMarker} trend</Text>

              <LabResultsTrend data={vals[selectedMarker]} value={selectedMarker} />
            </Flex>

            <Flex flexDirection="row" mb={3}>
              <Text mt={2}>Normal Range:</Text>
              <Text mt={2} fontWeight={500} ml="8px">
                {hormone ? ` ${hormone.min} - ${hormone.max}` : ''} {hormone.unit}
              </Text>
            </Flex>

            {hormone &&
              hormone.description &&
              hormone.description.split('\n').map((item, i) => {
                return (
                  <Text mt={2} key={`${hormone.name}${i}`}>
                    {item}
                  </Text>
                );
              })}
          </Flex>
        </Card>
      </Flex>
    );
  }
}

const LabResultsTrendPage = (props) => {
  // return <div>Hi</div>
  return (
    <Container>
      <Query query={LABS}>
        {({ loading, error, data }) => {
          if (loading || error) {
            return <div />;
          }
          if (!data.labs) {
            props.history.push('/labs');
            return null;
          }
          const labs = data.labs.filter((x) => (x.status || '').toUpperCase() === 'RELEASED');

          if (!labs || labs.length < 2) {
            props.history.push('/labs');
            return null;
          }
          return <LabResultsTrendPageContent labs={labs} />;
        }}
      </Query>
    </Container>
  );
};

export default LabResultsTrendPage;
