import React, { Component, Fragment } from 'react';
import { Flex, Box, Button, Heading, Card, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import ScrollAnimation from 'react-animate-on-scroll';
import { ButtonWithSelectionCircle, ButtonWithSelectionCircleSelectedMarkerIn, ButtonWithSelectionCircleSelectedMarkerOut } from './components/ButtonWithSelectionCircle';
import { fadeInDown, fadeInUp } from '../../utils/animationsStyleBuilder';
class SmokePick extends Component {
  state = {
    display: false,
    selected: [],
    active: undefined,
    tempValues: {},
  };

  constructor(props) {
    super(props);

    if (props.data && props.data.skipIfNotQuestionId) {
      let isFound = false;
      for (let index = 0; index < props.userData.length; index++) {
        const element = props.userData[index];
        for (let index = 0; index < element.values.length; index++) {
          const val = element.values[index];
          if (val.questionId && val.questionId === props.data.skipIfNotQuestionId) {
            isFound = true;
            break;
          }
        }
      }
      if (!isFound) {
        props.wizard.next();
      }
    }
  }

  handleChosen = (item) => {
    this.setState({
      active: item,
    });
  };

  formatSubmit = (values) => {
    const { data } = this.props;
    const { active } = this.state;
    let payload = [];
    const questionId = data.questionId;
    payload.push({ questionId, answer: active.value });

    return payload;
  };

  shiftView = () => {
    setTimeout(() => {
      window.scrollTo(0, this.inputRef.offsetTop);
    }, 150);
  };

  render() {
    const { data, onSubmit, wizard } = this.props;
    const { selected, loading, active } = this.state;
    // console.log('selected', selected);

    return (
      // <Card p={3} mb={5} mx={3} style={{ zIndex: 1 }}>
      <Fragment>
        <Flex flex={1} flexDirection="column">
          <Box m={2} style={{ ...fadeInDown() }}>
            <Text fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
              {data.title}
            </Text>
          </Box>

          {/* <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={false} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}
          <Formik
            initialValues={{ [data.id]: '' }}
            validationSchema={data.schema || ''}
            onSubmit={(values, actions) => {
              const formatted = this.formatSubmit(selected);

              onSubmit(
                {
                  values: formatted,
                  endpoint: data.endpoint,
                },
                actions,
                wizard,
                data
              );
            }}
            render={({ isValid, errors, touched, submitForm }) => (
              <Form>
                <Flex flex={1} flexDirection="column">
                  <Flex my={3} flexWrap="wrap" justifyContent="center">
                    <div
                      style={{
                        maxWidth: '500px',
                        alignSelf: 'center',
                        width: '100%',
                        margin: '0 auto',
                      }}
                    >
                      <Text mt={3} mb="24px" color="hsl(0,0%,50%)" textAlign="center">
                        {data.prompt}
                      </Text>
                      <Flex flex={1} flexDirection="row" flexWrap={'wrap'}>
                        {data.options.map((el, ii) => (
                          <Box key={el.value} pr={[0, 2]} width={['100%', '33%']} style={{ marginBottom: '24px' }}>
                            <ButtonWithSelectionCircle
                              style={{ flex: 1, width: '100%', ...fadeInUp(20, '1.1s', `${ii * 100}ms`) }}
                              type="button"
                              variant={'white'}
                              disabled={loading}
                              onClick={() => this.handleChosen(el)}
                            >
                              <Flex alignItems={'center'}>
                                <ButtonWithSelectionCircleSelectedMarkerOut>
                                  {active && active.value === el.value && <ButtonWithSelectionCircleSelectedMarkerIn />}
                                </ButtonWithSelectionCircleSelectedMarkerOut>
                                <Text>{el.value}</Text>
                              </Flex>
                            </ButtonWithSelectionCircle>
                          </Box>
                        ))}
                      </Flex>
                    </div>
                  </Flex>
                  <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column" textAlign="center">
                    <Button disabled={loading} variant="pink" width={[1 / 2, 1 / 3]} type="submit">
                      {loading ? 'Processing' : 'Next'}
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            )}
          />
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(SmokePick);
// export default withWizard(Single);
