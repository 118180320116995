import React from 'react';

const withAnalytics = Component => props => {
  const page = (name, category, properties, options) => {
    // console.log('PAGE', window.analytics);
    let props = {
      ...properties,
      isMobileApp: !!window && !!window.ReactNativeWebView
    };
    if (window.analytics) {
      window.analytics.page(category, name, props, {
        ...options,
        context: { ip: '0.0.0.0' },
      });
    }
  };

  const track = (name, properties, options) => {
    if (window.analytics) {
      let props = {
        ...properties,
        isMobileApp: !!window && !!window.ReactNativeWebView
      };
      window.analytics.track(name, props, {
        ...options,
        context: { ip: '0.0.0.0' },
      });
    }
  };

  const Identify = (userId, traits, options, callback) => {
    if (window.analytics) {
      window.analytics.identify(
        userId,
        traits,
        { ...options, isMobileApp: !!window && !!window.ReactNativeWebView, context: { ip: '0.0.0.0' } },
        callback
      );
    }
  };

  const Alias = (userId, properties, options) => {
    if (window.analytics) {
      let props = {
        ...properties,
        isMobileApp: !!window && !!window.ReactNativeWebView
      };
      window.analytics.alias(userId, props, {
        ...options,
        context: { ip: '0.0.0.0' },
      });
    }
  };

  const logRevenue = (price, revenue, discount, coupon, products, orderId, affiliation) => {
    track('Order Completed', {
      orderId,
      affiliation,
      total: price,
      revenue: revenue,
      shipping: 1,
      discount: discount,
      coupon: coupon,
      currency: 'USD',
      products,
    });
  };

  const logCancel = (price, revenue, discount, coupon, products, orderId, affiliation, reason) => {
    track('Order Cancelled', {
      orderId,
      affiliation,
      total: price,
      revenue: revenue,
      shipping: 1,
      discount: discount,
      coupon: coupon,
      currency: 'USD',
      products,
      reason
    });
  };

  const rest = {
    ...props,
    analytics: {
      page,
      track,
      Identify,
      Alias,
      logRevenue,
      logCancel
    },
  };

  return <Component {...rest} />;
};

export default withAnalytics;
