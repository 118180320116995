import React from 'react';
import { Box, Text } from 'rebass';
import styled from 'styled-components';
import ConfirmModal from '../../../../components/ConfirmModal';
import { isToggleActive } from '../../../../components/featureToggle/toggles';
import { getMemberVisitPrice } from '../../../../utils/pricing';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;
const PricingInfoModal = props => {
  const { isOpen, close } = props;
  const hasNewPriceFeature = isToggleActive('USE_NEW_PRICING');
// console.log('PricingInfoModal', isOpen)
  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={close}
      onBackgroundClick={close}
      onConfirm={close}
      okOnly
      displayX
    >
      <ModalInner>
        <Text>
          {`This is your total cost for your Paloma Health Video Visit. 
            It includes any Co-Pay (typically $0-$50), any Co-Insurance (typically 0-20%) 
            and an additional cost up to $${hasNewPriceFeature? '110': '99'} ($${getMemberVisitPrice()} for Paloma members), if you have not met your minimum deductible for this year 
            (typically, a few hundred to a few thousand dollars). 
            You are guaranteed that Paloma Health will NOT charge you more than $${hasNewPriceFeature? '110': '99'} ($${getMemberVisitPrice()} for Paloma members) for your video visit. 
            Your Pharmacy or Lab costs are not included in your video visit price.`}
        </Text>
      </ModalInner>
    </ConfirmModal>
  );
};

export default PricingInfoModal;
