import React, { Fragment } from 'react';
import { Steps, Step, Wizard } from 'react-albus';
import { withRouter } from 'react-router-dom';
import { withApollo, compose } from 'react-apollo';
import { Box, Flex, Text } from 'rebass';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';

import FullLayout from '../components/FullLayout';
import Header from '../components/Header';
import LoadingScreen from '../components/LoadingScreen';
import Basic from './completeRegistration/Basic';
import Profile from './completeRegistration/Profile';
import Extended from './completeRegistration/Extended';
import { GET_PROFILE_COMPLETION_INFO_BY_UID, COMPLETE_PROFILE_REGISTRATION, COMPLETE_PROFILE_ADD_CARD, GET_ANALYTICS_ID } from '../graphql';
import withAnalytics from '../lib/withAnalytics';
import { getRegionById } from '../components/RegionSelect';
import CreditCard from './completeRegistration/CreditCard';
import BirthSex from './completeRegistration/BirthSex';
import LegalSex from './completeRegistration/LegalSex';
import GenderIdentity from './completeRegistration/GenderIdentity';
import PreferredPronouns from './completeRegistration/PreferredPronouns';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;
class CompleteRegistration extends React.Component {
  constructor(props) {
    super(props);

    if (props && props.match && props.match.params) {
      const { id } = props.match.params;

      this.state = {
        isInitialLoaded: false,
        uid: id,
        values: {
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          dob: '',
          phone: '',
          gender: '',
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: '',
          consent: false,
          diagnosed: null,
        },
      };

      this.loadInitial();
    } else {
      window.location = 'https://app.palomahealth.com';
    }
  }

  loadInitial = async () => {
    const { client } = this.props;
    const { uid, values } = this.state;
    const { data } = await client.query({
      query: GET_PROFILE_COMPLETION_INFO_BY_UID,
      variables: {
        uid,
      },
      fetchPolicy: 'network-only',
    });

    if (!data || !data.getProfileCompletionInfoByUid) {
      window.location = 'https://app.palomahealth.com';
    } else {
      this.setState({
        values: {
          ...values,
          ...data.getProfileCompletionInfoByUid,
          email: data.getProfileCompletionInfoByUid.email,
          firstName: data.getProfileCompletionInfoByUid.firstName,
          lastName: data.getProfileCompletionInfoByUid.lastName,

          state: getRegionById(data.getProfileCompletionInfoByUid.departmentId),
        },
      });
    }
  };

  handleSubmit = (vals, actions, wizard) => {
    this.setState({ values: { ...this.state.values, ...vals } });
    if(actions && actions.setSubmitting){
      actions.setSubmitting(false);
    }
    wizard.next();
  };

  handleComplete = async (values, actions, wizard) => {
    this.setState({
      values: { ...values },
      loading: true,
    });

    return;
  };

  onComplete = async (vals, action) => {
    const { values } = this.state;
    const { toastManager } = this.props;
    try {
      const addcc = await this.addCC(vals, values);

      if (addcc) {
        await this.completeProfileRegistration();
      }
      if(action && action.setSubmitting){
        action.setSubmitting(false);
      }
    } catch (e) {
      if(action && action.setSubmitting){
        action.setSubmitting(false);
      }
      console.log('on complete err', e);
      toastManager.add('Unable to complete registration', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  completeProfileRegistration = async () => {
    const { uid, values } = this.state;
    const { toastManager, analytics, history } = this.props;
    try {
      const res = await this.props.client.mutate({
        mutation: COMPLETE_PROFILE_REGISTRATION,
        variables: {
          uid,
          email: values.email,
          phone: values.phone,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          street1: values.street1,
          street2: values.street2,
          city: values.city,
          state: values.state.value,
          zip: values.zip,
          dob: values.dob,
          gender: values.gender,
          diagnosed: values.diagnosed,
          medicationHistoryConsentVerified: values.medicationHistoryConsentVerified,
          genderidentity: values.genderidentity,
          genderidentityother: values.genderidentityother,
          preferredpronouns: values.preferredpronouns,
          assignedsexatbirth: values.assignedsexatbirth,
        },
      });

      if (res && res.data && res.data.completeProfileRegistration && res.data.completeProfileRegistration.token) {
        localStorage.setItem('token', res.data.completeProfileRegistration.token);

        const aa = await this.props.client.query({
          query: GET_ANALYTICS_ID,
          fetchPolicy: 'network-only',
        });
        if (aa && aa.data && aa.data.getAnalyticsId) {
          analytics.Alias(aa.data.getAnalyticsId);
          analytics.Identify(aa.data.getAnalyticsId);
        }

        analytics.track('Account Created');
        history.push('/');
      } else {
        toastManager.add('Unable to complete registration', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } catch (error) {
      error.graphQLErrors.forEach((error) => {
        toastManager.add(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
    }
  };

  addCC = async (storedCard, values) => {
    const zip = storedCard.billingSameAsShiping ? values.zip : storedCard.zip;
    const street1 = storedCard.billingSameAsShiping ? values.street1 : storedCard.street1;

    const expMonth = storedCard.expirationdate.split('/')[0];
    const expYear = storedCard.expirationdate.split('/')[1];
    try {
      const res = await this.props.client.mutate({
        mutation: COMPLETE_PROFILE_ADD_CARD,
        variables: {
          patientId: values.id,
          accountNumber: storedCard.cardnumber,
          cvc: storedCard.cvc2,
          expMonth: expMonth.length < 2 ? `0${expMonth}` : expMonth,
          expYear: expYear,
          zip: zip,
          street1: street1,
        },
      });

      if (res && ((res.data && res.data.completeProfileAddCard && res.data.completeProfileAddCard.ok) || res.ok)) {
        this.props.analytics.track('Card Added');
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('ERRO', error);
      return false;
    }
  };

  render() {
    const { history, location } = this.props;
    const { uid, isInitialLoaded, values } = this.state;

    if (isInitialLoaded) return <LoadingScreen />;

    return (
      <Fragment>
        <Container flex={1} px={3}>
          <Flex flex={1}>
            <Header dark={false}>
              {location.pathname.indexOf('basic') > -1 && (
                <Text color="#486288" fontSize={3} fontWeight={500} textAlign="center">
                  Hi there! Let's get to know each other.
                </Text>
              )}
            </Header>
          </Flex>
          <Wizard history={history} basename={`/complete-registration/${uid}`}>
            <Steps>
              <Step id="basic">
                <Basic email={values.email} onSubmit={this.handleSubmit} values={values} />
              </Step>

              <Step id="profile">
                <Profile onSubmit={this.handleSubmit} values={values} />
              </Step>
              <Step id="birthsex">
                <BirthSex onSubmit={this.handleSubmit} values={values} />
              </Step>
              <Step id="legalsex">
                <LegalSex onSubmit={this.handleSubmit} values={values} />
              </Step>
              <Step id="genderIdentity">
                <GenderIdentity onSubmit={this.handleSubmit} values={values} />
              </Step>
              <Step id="pronouns">
                <PreferredPronouns onSubmit={this.handleSubmit} values={values} />
              </Step>

              <Step id="extended">
                <Extended onSubmit={this.handleSubmit} values={values} />
              </Step>

              <Step id="payment">
                <CreditCard onComplete={this.onComplete} />
              </Step>
            </Steps>
          </Wizard>
        </Container>
        <FullLayout />
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withRouter,
  withToastManager,
  withAnalytics
)(CompleteRegistration);
