import React, { Fragment } from 'react';
import { Card, Text, Heading } from 'rebass';

const Profile = props => {
  const {
    session: { firstName, lastName, address, phone },
  } = props;

  return (
    <Card p={4}>
      <Heading mb={4}>Contact Information</Heading>

      <Text>
        {firstName} {lastName}
      </Text>

      {address && (
        <Fragment>
          <Text>{address.street1}</Text>
          <Text>{address.street2}</Text>
          <Text>
            {address.city}, {address.state} {address.zip}
          </Text>
        </Fragment>
      )}

      <Text>{phone}</Text>
    </Card>
  );
};

export default Profile;
