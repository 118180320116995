import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import { Line, ResponsiveContainer, LineChart } from 'recharts';
import { GET_MOBILE_TRACKED_SYMPTOMS, SEND_MOBILE_APP_DOWNLOAD_LINK } from '../../graphql';
import withSession from '../../lib/withSession';
import { Skeleton } from '../../components/loadingSkeleton/Skeleton';
import styled from 'styled-components';
import { Mutation, Query } from 'react-apollo';
import ConfirmModal from '../../components/ConfirmModal';

const NoScrollBarContainer = styled(Flex)`
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ModalInner = styled(Flex)`
  max-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Container = styled(Flex)`
  width: 100%;
  max-width: 700px;

  @media screen and (max-width: 480px) {
    width: 100vw;
    max-width: 100vw;
    padding: 0 20px;
    overflow-x: hidden;
  }
`;

const ChartScrollButtons = styled(Box)`
  /* position: absolute; */
  opacity: 0.75;
  top: calc(50% - 30px);
  background: #ffffff;
  border-radius: 14px;
  align-self: center;

  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #344f;
  transition: all 0.3s ease;
  z-index: 100;

  &:hover {
    opacity: 1;
  }
`;

const VirtualPadder = styled(Box)`
  width: 20px;
  min-width: 20px;
  @media screen and (min-width: 480px) {
    display: none;
  }
`;

const ServicesContainer = styled(Flex)`
  width: 100%;
  max-width: 700px;

  @media screen and (max-width: 480px) {
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 24px;
    width: 100vw;
  }
`;

const BlurredContainer = styled(Flex)`
  box-shadow: inset 0 0 0 110px rgba(253, 245, 242, 0.55);
  backdrop-filter: blur(6px);
  left: 0;
  right: 0;
  @media screen and (max-width: 480px) {
    left: -20px;
    right: -20px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const SymptomCard = ({ detail, color, textLineColor, containerStyle = {} }) => {
  const [data, setData] = useState();
  const [trend, setTrend] = useState();
  useEffect(() => {
    let dataValues = [];
    let lastVal;
    let currVal;
    if (detail.values) {
      const rrrs = detail.values;

      for (let index = 0; index < rrrs.length; index++) {
        const element = rrrs[index];
        let val;
        if (!element.values && dataValues.length < 1) continue;

        dataValues.push({
          x: element.date,
          y: element.values ? element.values / 100 : lastVal || null,
        });
        if (index < rrrs.length - 1) {
          if (element.values) lastVal = element.values / 100;
        } else {
          if (element.values) currVal = element.values / 100;
        }
      }
    }

    let evolution;
    if (!!currVal && !!lastVal) {
      if (currVal > lastVal) evolution = 'up';
      if (currVal < lastVal) evolution = 'down';
      if (currVal === lastVal) evolution = 'same';
    }

    setTrend(evolution);
    setData(dataValues);
  }, []);

  return (
    <Flex
      key={detail.id}
      style={{
        background: color,
        width: 128,
        minWidth: 128,
        height: 176,
        borderRadius: 18,
        marginRight: 17,
        flexDirection: 'column',
        paddingTop: 20,
        paddingBottom: 8,
        position: 'relative',
        marginTop: '12px',
        marginBottom: '12px',
        ...containerStyle,
      }}
    >
      <Flex flex={1} style={{ marginRight: '-5px', marginLeft: '-5px', width: 'calc(100% + 10px)' }}>
        {!!data && (
          <ResponsiveContainer width={'100%'} height="100%">
            <LineChart data={data}>
              {/* <XAxis dataKey="x" />
            <YAxis dataKey={'y'}/>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" /> */}
              <Line type="monotone" dataKey="y" stroke={textLineColor} strokeWidth={4} dot={false} />
            </LineChart>
          </ResponsiveContainer>
        )}
      </Flex>
      <svg
        style={{
          position: 'absolute',
          top: 8,
          left: 8,
          transform: trend === 'down' ? '' : trend === 'same' ? 'rotate(-90deg)' : 'rotate(-180deg)',
        }}
        width="8"
        height="11"
        viewBox="0 0 8 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.74842 1.19376L3.74842 8.96752" stroke={textLineColor} stroke-width="0.895652" stroke-linecap="square" />
        <path d="M3.80638 9.43746L6.56413 6.63979" stroke={textLineColor} stroke-width="0.895652" stroke-linecap="square" />
        <path d="M3.78444 9.43746L1.02669 6.63979" stroke={textLineColor} stroke-width="0.895652" stroke-linecap="square" />
      </svg>

      <Text textAlign={'center'} color={textLineColor}>
        {detail.name}
      </Text>
    </Flex>
  );
};

const FakedSymptomTrackedData = ({ children, isLoading }) => {
  const faked = [];
  let detail = {};
  detail.values = [
    {
      date: '11/21/2021',
      values: 100,
    },
    {
      date: '11/22/2021',
      values: 50,
    },
    {
      date: '11/23/2021',
      values: 150,
    },
    {
      date: '11/24/2021',
      values: 50,
    },
    {
      date: '11/25/2021',
      values: 200,
    },
  ].reverse();
  detail.name = !isLoading ? 'Focus' : <Skeleton width="75px" height="18px" />;

  return (
    <ServicesContainer flexDirection={'row'} style={{ position: 'relative', opacity: isLoading ? 0.5 : 1, overflow: 'hidden' }}>
      <VirtualPadder />
      <SymptomCard detail={detail} textLineColor={'#B8D6F5'} color={'#354E79'} containerStyle={{ marginLeft: '12px' }} />
      <SymptomCard detail={detail} textLineColor={'#B8D6F5'} color={'#354E79'} />
      <SymptomCard detail={detail} textLineColor={'#B8D6F5'} color={'#354E79'} />
      <SymptomCard detail={detail} textLineColor={'#354E79'} color={'#B8D6F5'} />
      <SymptomCard detail={detail} textLineColor={'#354E79'} color={'#B8D6F5'} />
      <SymptomCard detail={detail} textLineColor={'#354E79'} color={'#B8D6F5'} />
      {!isLoading && (
        <BlurredContainer style={{ position: 'absolute', top: 0, bottom: 0 }} justifyContent="center">
          {children}
        </BlurredContainer>
      )}
    </ServicesContainer>
  );
};

const SymptomsTrackedInner = ({ isMobileAppUser, phone }) => {
  const refBox = useRef(null);
  const intervalRef = React.useRef(null);
  const [, forceupdate] = useReducer((x) => x + 1, 0);
  const [isLinkSent, setIsLinkSent] = useState(false);

  const scrollWidth = refBox.current ? refBox.current.scrollWidth : null;
  const scrollLeft = refBox.current ? refBox.current.scrollLeft : null;
  const clientWidth = refBox.current ? refBox.current.clientWidth : null;

  const scroll = (scrollOffset) => {
    if (refBox && refBox.current)
      if (scrollWidth && scrollLeft && clientWidth && scrollLeft + clientWidth >= scrollWidth && scrollOffset > 0) {
        stopCounter();
        return;
      } else if (scrollOffset < 0 && scrollLeft <= 0) {
        stopCounter();
        return;
      }
    refBox.current.scrollLeft += scrollOffset;
  };

  const startCounter = (orientation) => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      scroll(orientation === 'LEFT' ? -20 : 20);
    }, 10);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      forceupdate();
    }
  };
  if (!isMobileAppUser) {
    return (
      <Mutation mutation={SEND_MOBILE_APP_DOWNLOAD_LINK} onCompleted={() => setIsLinkSent(true)}>
        {(mutate, { loading }) => {
          return (
            <>
              <FakedSymptomTrackedData>
                <Flex justifyContent={'center'} alignItems={'center'} flexDirection="column" p={2}>
                  <Text textAlign="center">Download the Paloma mobile app to monitor your symptoms over time</Text>
                  <Button
                    variant={'primary'}
                    mt={2}
                    onClick={() => {
                      if (!!loading) return;
                      mutate();
                    }}
                  >
                    Send me a link
                  </Button>
                </Flex>
              </FakedSymptomTrackedData>
              <ConfirmModal
                confirmLabel="OK"
                childrenManaged
                isOpen={isLinkSent}
                okOnly
                onClose={() => {
                  setIsLinkSent(false);
                }}
                onConfirm={() => {
                  setIsLinkSent(false);
                }}
                onBackgroundClick={() => {
                  setIsLinkSent(false);
                }}
                onEscapeKeydown={() => {
                  setIsLinkSent(false);
                }}
              >
                <ModalInner>
                  <Heading mb={4} mx={3} textAlign="center">
                    We sent you an SMS
                  </Heading>
                  <Text textAlign="center">On number {phone}</Text>
                  <Button
                    disabled={!!loading}
                    variant={'primary'}
                    mt={4}
                    onClick={() => {
                      setIsLinkSent(false);
                    }}
                  >
                    Got It
                  </Button>
                </ModalInner>
              </ConfirmModal>
            </>
          );
        }}
      </Mutation>
    );
  }
  return (
    <Query query={GET_MOBILE_TRACKED_SYMPTOMS}>
      {({ data, loading, error }) => {
        if (loading) {
          return <FakedSymptomTrackedData isLoading={true} />;
        }

        if (
          !data ||
          !data.getMobileTrackedSymptomsFromWeb ||
          !data.getMobileTrackedSymptomsFromWeb.details ||
          data.getMobileTrackedSymptomsFromWeb.details.filter((x) => !!x.values && x.values.filter((y) => y.values.length > 0).length > 0).length < 1
        ) {
          return (
            <FakedSymptomTrackedData>
              <Flex justifyContent={'center'} alignItems={'center'} flexDirection="column" p={2}>
                <Text fontWeight={500} textAlign="center">
                  You have not tracked in the past 7 days.
                </Text>
                <Text textAlign="center">Did you know? You can set up notification reminders to always remember to track your symptoms</Text>
              </Flex>
            </FakedSymptomTrackedData>
          );
        }

        let keepTracking = false 

        for (let index = 0; index < data.getMobileTrackedSymptomsFromWeb.details.length; index++) {
          const element = data.getMobileTrackedSymptomsFromWeb.details[index];

          for (let index2 = 0; index < element.values.length; index++) {
            const element2 = element.values[index2];
            if(element2.values.filter(x=>x.values !== null).length<2){
              keepTracking = true
            }
          }
        }

        if (keepTracking) {
          return (
            <FakedSymptomTrackedData>
              <Flex justifyContent={'center'} alignItems={'center'} flexDirection="column" p={2}>
                <Text fontWeight={500} textAlign="center">
                  Keep tracking your symptoms and monitor how you feel over time.
                </Text>
              </Flex>
            </FakedSymptomTrackedData>
          );
        }

        return (
          <ServicesContainer flexDirection={'row'} style={{ position: 'relative', overflow: 'hidden' }}>
            <Flex
              style={{
                background: 'linear-gradient(to left, rgba(253,245,242,0), rgb(253 245 242))',
                zIndex: 10,
                display: scrollWidth && (scrollLeft !== null && scrollLeft !== undefined) && clientWidth && scrollLeft <= 0 ? 'none' : '',
              }}
            >
              <ChartScrollButtons style={{ marginRight: '24px' }} onMouseDown={() => startCounter('LEFT')} onMouseUp={stopCounter} onMouseLeave={stopCounter}>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.1405 21.6667L16.1329 21.6667C16.0333 21.667 15.9349 21.645 15.8449 21.6023L15.6306 22.0541L15.8449 21.6023C15.7552 21.5598 15.6761 21.4977 15.6134 21.4206C15.6132 21.4204 15.6131 21.4202 15.6129 21.42L9.9791 14.4215L9.97912 14.4215L9.97588 14.4175C9.87782 14.2982 9.82422 14.1486 9.82422 13.9942C9.82422 13.8402 9.87749 13.6911 9.97496 13.572L15.8071 6.57343L15.8075 6.57294C15.9207 6.43667 16.0835 6.35097 16.2599 6.3347C16.4364 6.31844 16.6121 6.37293 16.7484 6.48619L17.0639 6.10654L16.7484 6.48619C16.8846 6.59946 16.9703 6.76222 16.9866 6.93867L17.4845 6.89276L16.9866 6.93867C17.0028 7.11497 16.9485 7.29052 16.8354 7.42675C16.8353 7.42685 16.8352 7.42696 16.8351 7.42707L11.6206 13.6798L11.3581 13.9946L11.6153 14.3138L16.6553 20.5671L16.6553 20.5671L16.6605 20.5734C16.742 20.6712 16.7938 20.7904 16.8097 20.9168C16.8256 21.0431 16.805 21.1714 16.7503 21.2864C16.6956 21.4014 16.6091 21.4984 16.501 21.5658C16.3929 21.6332 16.2678 21.6682 16.1405 21.6667L16.1405 21.6667Z"
                    fill="black"
                    stroke="#344F7A"
                  />
                </svg>
              </ChartScrollButtons>
            </Flex>

            <NoScrollBarContainer
              style={{
                marginRight: scrollWidth && scrollLeft && clientWidth && scrollLeft + clientWidth >= scrollWidth ? 0 : -62,
                marginLeft: scrollWidth && (scrollLeft !== null && scrollLeft !== undefined) && clientWidth && scrollLeft <= 0 ? 0 : -62,
              }}
              ref={refBox}
            >
              <VirtualPadder />
              {data.getMobileTrackedSymptomsFromWeb.details.map((x) => {
                return (
                  <>
                    {(x || {}).values.map((xx) => {
                      return <SymptomCard detail={xx} textLineColor={x.textLineColor} color={x.color} />;
                    })}
                  </>
                );
              })}
              <VirtualPadder />
            </NoScrollBarContainer>
            <Flex
              style={{
                background: 'linear-gradient(to right, rgba(253,245,242,0), rgb(253 245 242))',
                zIndex: 10,
                display: scrollWidth && scrollLeft && clientWidth && scrollLeft + clientWidth >= scrollWidth ? 'none' : '',
              }}
            >
              <ChartScrollButtons
                style={{ marginLeft: '24px', display: scrollWidth && scrollLeft && clientWidth && scrollLeft + clientWidth >= scrollWidth ? 'none' : '' }}
                onMouseDown={() => startCounter('RIGHT')}
                onMouseUp={stopCounter}
                onMouseLeave={stopCounter}
              >
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.8595 6.33329L11.8595 6.33335L11.8671 6.33332C11.9667 6.33299 12.0651 6.35497 12.1551 6.39765C12.2448 6.4402 12.3238 6.50225 12.3865 6.57927C12.3867 6.57951 12.3869 6.57975 12.3871 6.57999L18.0209 13.5785L18.0209 13.5785L18.0241 13.5825C18.1222 13.7018 18.1758 13.8514 18.1758 14.0058C18.1758 14.1598 18.1225 14.3089 18.0251 14.428L12.1929 21.4266L12.1925 21.4271C12.0793 21.5633 11.9165 21.649 11.7401 21.6653C11.5636 21.6816 11.3879 21.6271 11.2516 21.5138C11.1154 21.4005 11.0297 21.2378 11.0134 21.0613C10.9972 20.885 11.0516 20.7095 11.1647 20.5732C11.1647 20.5731 11.1648 20.573 11.1649 20.5729L16.3794 14.3202L16.6419 14.0054L16.3847 13.6862L11.3447 7.4329L11.3447 7.43286L11.3395 7.42662C11.258 7.32877 11.2062 7.20961 11.1903 7.08324C11.1744 6.95688 11.195 6.82859 11.2497 6.71358C11.3044 6.59856 11.3909 6.50162 11.499 6.43422C11.6071 6.36683 11.7322 6.3318 11.8595 6.33329Z"
                    fill="black"
                    stroke="#344F7A"
                  />
                </svg>
              </ChartScrollButtons>
            </Flex>
          </ServicesContainer>
        );
      }}
    </Query>
  );
};

const SymptomsTracked = ({ session: { isMobileAppUser, phone } }) => {
  return (
    <Container flexDirection={'column'} mb="40px">
      <Text mb="20px">Symptoms Tracked</Text>
      <SymptomsTrackedInner isMobileAppUser={isMobileAppUser} phone={phone} />
    </Container>
  );
};

export default withSession(SymptomsTracked);
