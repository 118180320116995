import { Form, Formik } from 'formik';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Button, Flex } from 'rebass';
import CheckboxInput from '../../../components/CheckboxInput';
import FormField from '../../../components/FormField';
import TextInput from '../../../components/TextInput';
import { ADMIN_CREATE_UPDATE_THYROID_PROGRAM_SECTION, ADMIN_GET_THYROID_PROGRAMS_SECTIONS } from '../../../graphql';

const AddSection = ({ onSave, onClose, id, programId, order }) => {
  return (
    <Mutation
      mutation={ADMIN_CREATE_UPDATE_THYROID_PROGRAM_SECTION}
      refetchQueries={[{ query: ADMIN_GET_THYROID_PROGRAMS_SECTIONS, variables: { programId: programId } }]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        onSave();
      }}
    >
      {(createPlan, { loading }) => (
        <Formik
          isInitialValid={
            ({ initialValues }) => true
            //   schema.isValidSync(initialValues)
          }
          // validationSchema={schema}
          onSubmit={async (values, actions) => {
            let vars = {
              id,
              programId,
              name: values.name,
              description: values.description,
              isActive: values.isActive,
              isArchived: values.isArchived,
              order,
            };

            await createPlan({
              variables: {
                ...vars,
              },
            });
          }}
          render={({ touched, errors, isValid, handleChange, handleBlur, isSubmitting, setFieldValue, setFieldTouched, values }) => (
            <Form>
              <Flex flexDirection="column" width="400px" style={{ maxHeight: '900px' }}>
                <FormField mb={4} error={touched.name && errors.name}>
                  <label for="name">Name</label>
                  <TextInput name="name" placeholder="Name" />
                </FormField>
                <FormField mb={4} error={touched.description && errors.description}>
                  <label for="description">Description</label>
                  <TextInput name="description" placeholder="Description" />
                </FormField>

                <FormField mb={4} error={touched.phone && errors.phone}>
                  <label for="isActive">Is Active</label>
                  <CheckboxInput
                    name="isActive"
                    styles={{
                      checked: {
                        Box: {
                          backgroundColor: 'rgb(54, 79, 121)',
                        },
                      },
                    }}
                  />
                </FormField>

                <Flex mt={4} flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Button
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    // disabled={!isValid}
                    style={{ background: '#344f79' }}
                    onClick={() => {
                      // createCategory();
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )}
        />
      )}
    </Mutation>
  );
};

export const UpdateSection = ({ onSave, onClose, programId, section }) => {
  if(!section || !section.id) return null
  console.log({section})
  return (
    <Mutation
      mutation={ADMIN_CREATE_UPDATE_THYROID_PROGRAM_SECTION}
      refetchQueries={[{ query: ADMIN_GET_THYROID_PROGRAMS_SECTIONS, variables: { programId: programId } }]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        onSave();
      }}
    >
      {(createPlan, { loading }) => (
        <Formik
          initialValues={{
            id: section.id,
            name: section.name,
            description: section.description,
            isActive: section.isActive,
            isArchived: section.isArchived,
            order: section.order,
          }}
          isInitialValid={
            ({ initialValues }) => true
            //   schema.isValidSync(initialValues)
          }
          // validationSchema={schema}
          onSubmit={async (values, actions) => {
            console.log({values})
            let vars = {
              id: section.id,
              programId,
              name: values.name,
              description: values.description,
              isActive: values.isActive,
              isArchived: values.isArchived,
              order: values.order,
            };

            await createPlan({
              variables: {
                ...vars,
              },
            });
          }}
          render={({ touched, errors, isValid, handleChange, handleBlur, isSubmitting, setFieldValue, setFieldTouched, values }) => (
            <Form>
              <Flex flexDirection="column" width="400px" style={{ maxHeight: '900px' }}>
                <FormField mb={4} error={touched.name && errors.name}>
                  <label for="name">Name</label>
                  <TextInput name="name" placeholder="Name" />
                </FormField>
                <FormField mb={4} error={touched.description && errors.description}>
                  <label for="description">Description</label>
                  <TextInput name="description" placeholder="Description" />
                </FormField>

                <FormField mb={4} error={touched.phone && errors.phone}>
                  <label for="isActive">Is Active</label>
                  <CheckboxInput
                    name="isActive"
                    styles={{
                      checked: {
                        Box: {
                          backgroundColor: 'rgb(54, 79, 121)',
                        },
                      },
                    }}
                  />
                </FormField>

                <Flex mt={4} flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Button
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    // disabled={!isValid}
                    style={{ background: '#344f79' }}
                    onClick={() => {
                      // createCategory();
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )}
        />
      )}
    </Mutation>
  );
};

export default AddSection;
