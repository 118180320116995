import React from 'react';
import ReactInputMask from 'react-input-mask';
import { Box } from 'rebass';
import styled from 'styled-components';
import Ti from '../TextInput';

export const TextInput = styled(Ti)`
  border: 1px solid #d7d7d7;
  transition: border 300ms ease-in-out;

  &:focus {
    border: 1px solid #44a5ff !important;
  }
`;

export const MaskInput = (props) => <ReactInputMask {...props}>{(inputProps) => <TextInput {...inputProps} />}</ReactInputMask>;

export const BoxButton = styled(Box)`
  height: 60px;
  position: fixed;
  bottom: calc(0%);
  width: 100%;
  background-color: white;
  opacity: 1;
  transition: transform 100ms ease-in;
  transform: translate3d(0, 100%, 0);
  left: 0;
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: center;
  z-index: 1;
  &.visible {
    transform: translate3d(0, 0, 0);
  }
`;

export const BoxButtonNonFixed = styled(Box)`
  height: 60px;
  width: 100%;
  opacity: 1;
  transition: all 100ms ease-in;
  display: none;
  align-items: center;
  padding: 12px;
  justify-content: center;
  z-index: 1;
  &.visible {
    display: flex;
  }
`;
