import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Flex, Text } from 'rebass';
import { sleep } from '../../../utils/sleep';
import { SelectButton } from '../components/Shared';
import { FormerConstants } from '../utils/constants';
import { getDefaultValueSingle } from '../utils/extractBackResponse';

const IntroductionLayout = ({ text, onNext, defaultValue, id }) => {
  const [selected, setSelected] = useState(getDefaultValueSingle(defaultValue));
  
  const onSelection = async (selected) => {
    setSelected(selected);
    await sleep(FormerConstants.WAIT_AFTER_SELECTION);
    
    onNext({
      id,
      selection: [
        {
          value: selected,
        },
      ],
    });
  };

  return (
    <>
      <Text fontSize={isMobile ? '14px' : '16px'} fontWeight="300" dangerouslySetInnerHTML={{ __html: text }} />
      <Flex flexDirection='row' flexWrap={'wrap'} mt={4}>
      <SelectButton
        text="Yes, I agree to take part"
        isSelected={selected === 'Yes, I agree to take part'}
        hasSelection={!!selected}
        onClick={() => {
          onSelection('Yes, I agree to take part');
        }}
      />
      <SelectButton
        text="No, I do not agree"
        isSelected={selected === 'No, I do not agree'}
        hasSelection={!!selected}
        onClick={() => {
          onSelection('No, I do not agree');
        }}
      />
      </Flex>
    </>
  );
};

export default IntroductionLayout
