import React, { useState,useEffect } from 'react';
import raf from 'raf'
import { Text } from 'rebass';
const inOutCube = function(n){
  n *= 2;
  if (n < 1) return 0.5 * n * n * n;
  return 0.5 * ((n -= 2 ) * n * n + 2);
};
const Counter =({to, time, begin, style, suffix})=> {
  const [start, setStart]=useState(Date.now())
  const [stop, setStop] = useState(false)
  const [value, setValue]=useState(0)

  useEffect(()=>{
    setStart(Date.now())
    raf(animate)
  },[])

  const animate=()=> {
    if (stop) return;

    raf(animate);
    draw()
  }

  const draw=()=> {
    if(!start) return
    var time = time || 1000;
    var begin = begin || 0;
    // var easing = this.props.easing;

    // easing = easing && easing in ease ? easing : 'outCube';
    var now = Date.now()
    if (now - start >= time) setStop(true);
    var percentage = (now - start) / time;
    percentage = percentage > 1 ? 1 : percentage;
    var easeVal = inOutCube(percentage);
    var val = begin + (to - begin) * easeVal;

    setValue(val)
  }

    return <div style={{display: 'inline', position:'relative'}}>
      <Text style={{display: 'inline', ...style, opacity: 0}}>{to}</Text>
      <Text style={{display: 'inline', position: 'absolute', left: 0, textAlign: 'center', ...style}}>{Math.round(value)}</Text>
      {!!suffix && (
      <Text style={{display: 'inline', ...style}}>{suffix}</Text>
      )}
    </div>
};

export default Counter;
