import React from 'react';
import { Mutation } from 'react-apollo';
import { Flex, Text, Button } from 'rebass';
import Dropzone from 'react-dropzone';
import { Formik, Form } from 'formik';
import Div100vh from 'react-div-100vh';
import { isMobile } from 'react-device-detect';

import { GET_PERPETUAL_PHYSICIAN_CONVERSATION, APPOINTMENT_INITIATE_CONVERSATION, PERPETUAL_CONVERSATION_THREAD_REPLY } from '../../../graphql';
import SpinLoader from '../../../components/SpinLoader';
import TextArea, { AutoExtendTextArea } from '../../../components/TextArea';
import AttachmentIcon from '../../../static/attachment.png';
import SendIcon from '../../../static/send.png';
import FormField from '../../../components/FormField';
import BasicScrollToBottom, { StateContext } from '../../../components/scrollToBottomAuto/index';
import { CustomerBubble, UserBubble, BotBubbleStyled, ToBeUploadedFilesMiniatures, AttachmentModal } from '../../../components/communicator';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Inner = styled.section`
  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 52em) {
    overflow-y: hidden;
  }
  @media screen and (min-width: 52em) {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: 100%;
  }
`;
const PhantomMargin = styled.div`
  @media screen and (max-width: 52em) {
    height: 10px;
  }
`;

const FormWrapper = styled.div`
  @media screen and (max-width: 52em) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0px -9px 23px -16px rgb(0, 0, 0);
    max-width: 99vw;
  }
`;

const MessagesDiv = styled(Div100vh)`
  cursor: auto;
  overflow-y: hidden;
  @media screen and (max-width: 52em) {
    overflow-y: hidden;
    padding-bottom: 100px;
  }
`;
const Desktop = ({
  isShowOnlyDesktop,
  handleRejected,
  setFiles,
  files,
  threadContainer,
  setTarg,
  forwardRefPnl,
  onScroll,
  conversation,
  fetchMore,
  session,
  isSendingFirstMessage,
  scrollItThere,
  generateInitiateConversationVariables,
  setPollInterval,
  setIsSendingFirstMessage,
  setRef,
  analytics,
  appointmentId,
  appointment,
  prescription,
  summary,
  isAsync
}) => {
  return (
    <Dropzone
      noClick={true}
      id="dropzone"
      multiple={true}
      onDrop={(files) => {}}
      onDropRejected={handleRejected}
      onDropAccepted={(f) => {
        for (let index = 0; index < f.length; index++) {
          const element = f[index];
          const reader = new FileReader();
          reader.onload = (event) => {
            const fls = files;
            fls.push({
              fileName: element.name,
              mimeType: element.type,
              data: event.target.result,
            });
            setFiles(fls);
          };
          reader.readAsDataURL(element);
        }
      }}
      maxSize={10000000}
      style={{ width: '100%', height: '100%' }}
    >
      {({ getRootProps, getInputProps, isDragActive, open }) => (
        <Inner>
          <MessagesDiv
            style={{
              display: 'flex',
              flex: 1,
              maxHeight: '100%',
            }}
            {...getRootProps()}
            noClick={true}
            id="messagesdiv"
            className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            ref={threadContainer}
          >
            <BasicScrollToBottom
              forwardRef={(t) => {
                setTarg(t);
              }}
              forwardRefPnl={forwardRefPnl}
              onScroll={(e) => {
                onScroll(e, conversation, fetchMore);
              }}
            >
              <StateContext.Consumer>
                {({ sticky }) => (
                  <>
                    <input type="file" {...getInputProps()} />
                    {conversation &&
                    conversation.threads &&
                    conversation.threads.filter((xx) => xx.body && (xx.body || '').toLowerCase() !== 'initiate_1' && (xx.type || '').toString().toLowerCase() !== 'note').length > 0 ? (
                      conversation.threads
                        .filter((x) => {
                          if (x.body && x.body.toLowerCase() === 'initiate_1') return false;
                          if (x.state !== 'published') return false;
                          if ((x.type || '').toString().toLowerCase() !== 'message' && (x.type || '').toString().toLowerCase() !== 'customer') return false;
                          if (!x.body && !x._embedded && !x._embedded.attachments && x._embedded.attachments.length < 1) return false;
                          return true;
                        })
                        .reverse()
                        .map((thread, index) => {
                          if (thread.createdBy.id.toString() === session.helpscoutId || thread.createdBy.type === 'customer') {
                            return (
                              <React.Fragment key={thread.id}>
                                <div
                                  id={thread.id}
                                  ref={setRef(thread.id.toString())}
                                  data-post={thread.id.toString()}
                                  data-item="true"
                                  style={{
                                    width: '100%',
                                    height: '12px',
                                    display: index === 0 ? '' : 'none',
                                  }}
                                />
                                <CustomerBubble thread={thread} key={thread.id} />
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <React.Fragment key={thread.id}>
                                <div
                                  id={thread.id}
                                  ref={setRef(thread.id.toString())}
                                  data-post={thread.id.toString()}
                                  data-item="true"
                                  style={{
                                    width: '100%',
                                    height: '12px',
                                    display: index === 0 ? '' : 'none',
                                  }}
                                />
                                <UserBubble thread={thread} key={thread.id} />
                              </React.Fragment>
                            );
                          }
                        })
                    ) : (
                      <Flex
                        justifyContent="flex-start"
                        style={{
                          marginBottom: '24px',
                          alignItems: isSendingFirstMessage ? 'center' : '',
                          flexDirection: 'column',
                        }}
                      >
                        {isSendingFirstMessage ? (
                          <>
                            <SpinLoader />
                            <br />
                            Sending Your 1st Message
                          </>
                        ) : (
                          <BotBubbleStyled>
                            <Flex flexDirection="row" style={{ marginBottom: '16px' }}>
                              <Text fontSize="16px" fontWeight="600">
                                Paloma Health Team
                              </Text>
                            </Flex>

                            <Text
                              style={{
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-line',
                              }}
                            >
                              Our medical team can help with questions related to symptoms, treatments or lab values. For any other question, please
                              <Link style={{ color: 'white' }} to="/chat-care" id="link_chatcare">
                                {` message your care manager >`}
                              </Link>
                            </Text>
                          </BotBubbleStyled>
                        )}
                      </Flex>
                    )}
<Text textAlign={'center'} style={{opacity: 0.5}} mt={4}>usually reply within 3 to 5 business days</Text>

                    <PhantomMargin />
                  </>
                )}
              </StateContext.Consumer>
            </BasicScrollToBottom>
          </MessagesDiv>

          <div
            style={{
              background: 'black',
              opacity: 0.5,
              height: '1px',
              width: '100%',
            }}
          />
          {/* {!blockSend ? ( */}
          <Mutation
            mutation={conversation && conversation.threads && conversation.threads.length > 0 ? PERPETUAL_CONVERSATION_THREAD_REPLY : APPOINTMENT_INITIATE_CONVERSATION}
            onCompleted={() => {
              analytics.track('Send Provider Message');
            }}
          >
            {(sendEmailReminder, { loading }) => {
              return (
                <Formik
                  initialValues={{ msgTxt: '' }}
                  validate={(values, props) => {
                    let errors = {};
                    if (files.length > 0 && !values.msgTxt) {
                      errors.msgTxt('A message is required to send');
                    }
                    return errors;
                  }}
                  render={({ isValid, errors, touched, submitForm, setSubmitting, values, resetForm, isSubmitting, setFieldValue }) => (
                    <FormWrapper>
                      <Form>
                        <Flex flexDirection="column">
                          <Flex flexDirection="row">
                            <Button
                              type="button"
                              style={{
                                padding: '8px',
                                alignSelf: 'flex-start',
                                marginTop: '10px',
                              }}
                              variant="white"
                              onClick={() => {
                                scrollItThere();
                                // open();
                              }}
                            >
                              <img src={AttachmentIcon} width="20px" style={{ opacity: 0.75 }} alt="attach" />
                            </Button>
                            {/* <FormField
                              style={{
                                flexGrow: 1,
                                maxHeight: '40vh',
                                minHeight: '59px',
                                height: 'auto',
                              }}
                              error={errors.msgTxt}
                            > */}
                              <AutoExtendTextArea
                                                  disabled={isSubmitting}
                                                  name="msgTxt"
                                                  style={{
                                                    flexGrow: 1,
                                                    background: 'white',
                                                    border: '0px solid white',
                                                    maxHeight: '40vh',
                                                    minHeight: '59px',
                                                    font: 'inherit',
                                                    padding: '4px 12px',
                                                    outlineStyle: 'none',
                                                    boxShadow: 'none'
                                                  }}
                                                  placeholder="type your message..."
                                                  id="autocomplete"
                                                  className="input-field"
                                                  type="text"
                                                  value={values.msgTxt || ''}
                                                  onChange={e=>{setFieldValue('msgTxt',e.target.value)}}
                                                />
                              {/* <TextArea
                                disabled={isSubmitting}
                                name="msgTxt"
                                style={{
                                  flexGrow: 1,
                                  background: 'white',
                                  border: '0px solid white',
                                  maxHeight: '150px',
                                  minHeight: '59px',
                                  height: 'auto',
                                }}
                                placeholder="type your message..."
                                id="autocomplete"
                                className="input-field"
                                type="text"
                              /> */}
                            {/* </FormField> */}
                            <Button
                              disabled={(!values.msgTxt && (!files || files.length < 1)) || isSubmitting}
                              style={{
                                padding: '8px',
                                alignSelf: 'flex-start',
                                marginTop: '10px',
                                marginRight: '6px',
                              }}
                              type="button"
                              variant="white"
                              onClick={async () => {
                                setSubmitting(true);
                                const attachments = [];
                                if (files) {
                                  for (let index = 0; index < files.length; index++) {
                                    const element = files[index];
                                    attachments.push({
                                      ...element,
                                      data: element.data.substr(element.data.indexOf(',') + 1),
                                    });
                                  }
                                }
                                if (!conversation || !conversation.threads || conversation.threads.length < 1) {
                                  // if (values.msgTxt) {
                                  const vars = generateInitiateConversationVariables(
                                    appointmentId,
                                    appointment,
                                    prescription,
                                    summary,
                                    {
                                      text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                      attachments: attachments,
                                    },
                                    isAsync
                                  );
                                  await sendEmailReminder({
                                    variables: vars,
                                    refetchQueries: [
                                      {
                                        query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                        variables: {
                                          treeIndex: 0,
                                        },
                                      },
                                    ],
                                    awaitRefetchQueries: true,
                                  });
                                  resetForm({ msgTxt: '' });
                                  setFiles([]);
                                  setPollInterval(2000);
                                  setIsSendingFirstMessage(true);
                                } else {
                                  await sendEmailReminder({
                                    variables: {
                                      conversationId: conversation.id.toString(),
                                      text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                      attachments: attachments,
                                      isAsync,
                                    },
                                    refetchQueries: [
                                      {
                                        query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                        variables: {
                                          treeIndex: 0,
                                        },
                                      },
                                    ],
                                  });

                                  resetForm({ msgTxt: '' });
                                  setFiles([]);
                                }

                                setSubmitting(false);
                              }}
                            >
                              <img src={SendIcon} width="20px" style={{ opacity: 0.75 }} alt="send" />
                            </Button>
                          </Flex>
                          {files && files.length > 0 ? (
                            <>
                              {isMobile ? (
                                <ToBeUploadedFilesMiniatures
                                  files={files}
                                  onFileRemove={(index) => {
                                    const fls = files;
                                    fls.splice(index, 1);
                                    setFiles(fls);
                                  }}
                                />
                              ) : (
                                <AttachmentModal
                                  isSubmitting={isSubmitting}
                                  onFileRemove={(index) => {
                                    const fls = files;
                                    fls.splice(index, 1);
                                    setFiles(fls);
                                  }}
                                  confirmModalForm={async () => {
                                    setSubmitting(true);
                                    const attachments = [];
                                    if (files) {
                                      for (let index = 0; index < files.length; index++) {
                                        const element = files[index];
                                        attachments.push({
                                          ...element,
                                          data: element.data.substr(element.data.indexOf(',') + 1),
                                        });
                                      }
                                    }
                                    if (!conversation || !conversation.threads || conversation.threads.length < 1) {
                                      // if (values.msgTxt) {
                                      const vars = generateInitiateConversationVariables(
                                        appointmentId,
                                        appointment,
                                        prescription,
                                        summary,
                                        {
                                          text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                          attachments: attachments,
                                        },
                                        isAsync
                                      );
                                      await sendEmailReminder({
                                        variables: vars,
                                        refetchQueries: [
                                          {
                                            query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                            variables: {
                                              treeIndex: 0,
                                            },
                                          },
                                        ],
                                        awaitRefetchQueries: true,
                                      });
                                      resetForm({ msgTxt: '' });
                                      setFiles([]);
                                      setPollInterval(2000);
                                      setIsSendingFirstMessage(true);
                                    } else {
                                      await sendEmailReminder({
                                        variables: {
                                          conversationId: conversation.id.toString(),
                                          text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                          attachments: attachments,
                                          isAsync,
                                        },
                                        refetchQueries: [
                                          {
                                            query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                            variables: {
                                              treeIndex: 0,
                                            },
                                          },
                                        ],
                                      });

                                      resetForm({ msgTxt: '' });
                                      setFiles([]);
                                    }

                                    setSubmitting(false);
                                  }}
                                  close={() => {
                                    setFiles([]);
                                  }}
                                  files={files}
                                  values={values}
                                  addFiles={(files) => {
                                    setFiles(files);
                                  }}
                                />
                              )}
                            </>
                          ) : null}
                        </Flex>
                      </Form>
                    </FormWrapper>
                  )}
                />
              );
            }}
          </Mutation>
        </Inner>
      )}
    </Dropzone>
  );
};

export default Desktop;
