import React, { useState, useEffect } from 'react';
import { Flex, Text, Box } from 'rebass';
import moment from 'moment';
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ComposedChart,
  Area,
  ResponsiveContainer,
  CartesianGrid,
  Label,
  ReferenceArea,
} from 'recharts';
import { InnerRightHormone, LeftMediumHormone, TopRightHormone } from '../icons/InnerRightHormone';
import styled, { keyframes } from 'styled-components';
import { WorkoutsIcon } from '../icons/WorkoutsIcon';
const slideLeft = keyframes`
  0% {
    /* opacity: 0; */
    transform: translateX(-350px);
  };
  100% {
    /* opacity: 1; */
    transform: translateX(0);
  };
`;

const Opaciter = keyframes`
  0% {
    opacity: 0;
    /* transform: translateX(-350px); */
  };
  100% {
    opacity: 1;
    /* transform: translateX(0); */
  };
`;

const Bar = styled(Flex)`
  padding: 8px 16px;
  border-radius: 0 80px 80px 0;
  min-height: 60px;
  background-color: #344f7a;
  animation: 1s 1 forwards ease-in ${slideLeft};
  justify-content: flex-end;
`;

const Item = styled(Text)`
  opacity: 0;
  animation: 1s 1 forwards ease-in ${Opaciter};
  animation-delay: ${(props) => props.delay};
  margin-left: 12px;
  font-size: 22px;
`;

export const MarkerEvolutionBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <TopRightHormone />
      <InnerRightHormone
        style={{
          position: 'absolute',
          right: 0,
          bottom: 60,
        }}
      />

      <LeftMediumHormone
        style={{
          position: 'absolute',
          left: 0,
          bottom: 260,
        }}
      />
    </Flex>
  );
};

const CustomDot = ({ cx, cy, stroke, payload, value, ...props }) => {
  // console.log('Dot', {
  //   cx,
  //   cy,
  //   stroke,
  //   payload,
  //   value,
  //   ...props,
  // });
  if (
    value[value.length - 1] > props.normalTop ||
    value[value.length - 1] < props.normalBottom
  ) {
    return (
      <svg x={cx - 4} y={cy - 4} height="8" width="8">
        <circle cx="4" cy="4" r="4" fill="#F8A294" />
      </svg>
    );
  }
  return (
    <svg x={cx - 4} y={cy - 4} height="8" width="8">
      <circle cx="4" cy="4" r="4" fill="#3bb496" />
    </svg>
  );
};

export const MarkerEvolution = ({ tests, markerName, normalRangeLow, normalRangeHigh, yAxisMinTop }) => {
  const gradientOffset = (dataMax, dataMin, normalTop, normalBottom) => {
    const t = ((Math.min(normalTop, dataMax) - 0) * 100) / dataMax;
    const b = ((normalBottom - 0) * 100) / dataMax;

    const res = {
      normalTop: 1 - t / 100,
      normalBottom: 1 - b / 100,
    };

    return res;
  };

  const gradientOffset2 = (dataMax, dataMin, normalTop, normalBottom) => {
    const low = ((dataMin - 0) * 100) / dataMax;
    const high = ((Math.min(normalTop, dataMax) - 0) * 100) / dataMax;
    const t = ((Math.min(normalTop, dataMax) - 0 - dataMin * (1 - high / 100)) * 100) / dataMax;
    const b = ((normalBottom - 0 - dataMin * (1 - low / 100)) * 100) / dataMax;

    const res = {
      normalTop: 1 - t / 100,
      normalBottom: 1 - b / 100,
    };

    return res;
  };

  const dataMax = Math.max(...tests.map((i) => i.value));
  const dataMin = Math.min(...tests.map((i) => i.value));
  const normalBottom = normalRangeLow;
  const normalTop = normalRangeHigh;

  const off = gradientOffset(dataMax, dataMin, normalTop, normalBottom);
  const off2 = gradientOffset2(dataMax, dataMin, normalTop, normalBottom);

  let LinearGradientStroke = <linearGradient id="splitColor2" />;
  let LinearGradientStrokeStops = [];

  if (dataMax > normalTop) {
    LinearGradientStrokeStops.push(<stop offset={0} stopColor="#F8A294" stopOpacity={1} />);
    LinearGradientStrokeStops.push(<stop offset={off2.normalTop} stopColor="#F8A294" stopOpacity={1} />);
  }
  if (dataMax >= normalBottom && dataMin <= normalTop) {
    LinearGradientStrokeStops.push(<stop offset={off2.normalTop} stopColor="#3bb496" stopOpacity={1} />);
    LinearGradientStrokeStops.push(<stop offset={off2.normalBottom} stopColor="#3bb496" stopOpacity={1} />);
  }
  if (dataMin < normalBottom) {
    LinearGradientStrokeStops.push(<stop offset={off2.normalBottom} stopColor="#F8A294" stopOpacity={1} />);
    LinearGradientStrokeStops.push(<stop offset={1} stopColor="#F8A294" stopOpacity={1} />);
  }
  LinearGradientStroke = (
    <linearGradient id="splitColor2" x1="0" y1="0" x2="0" y2="1">
      {LinearGradientStrokeStops.map((x) => {
        return x;
      })}
    </linearGradient>
  );

  return (
    <Flex style={{ alignItems: 'center', height: '100%', flexDirection: 'column', zIndex: 100 }}>
      <Flex style={{alignItems:'flex-end', flex: 1/3}}>
      <Text style={{ fontSize: window.matchMedia('(min-width: 52em)').matches?36:26, fontWeight: 700, textAlign: 'center', marginBottom: '36px', color: 'white' }}>Your {markerName} levels</Text>
      </Flex>
      {/* {!!loaded && ( */}
      <Flex style={{ width: 'calc(100% + 18px)', marginLeft: '-18px', flex: 2/3, alignItems: 'flex-start' }}>
      <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            data={tests}
            margin={{
              top: 5,
              bottom: 5,
              left: -5,
            }}
          >
            <Tooltip />
            <defs>
              {LinearGradientStroke}
              <linearGradient id="splitColor3" x1="0" y1="0" x2="0" y2="1">
                <stop offset={0} stopColor="#6B7F9D" stopOpacity={0.4} />
                <stop offset={1} stopColor="#465F85" stopOpacity={1} />
              </linearGradient>
            </defs>
            <ReferenceArea
              y1={normalBottom}
              y2={normalTop}
              style={{
                fill: 'rgba(59, 180, 150, 1)',
              }}
              // label="Normal Range"
            >
              <Label
                value="Normal Range"
                position="insideBottomLeft"
                style={{
                  fontSize: '12px',
                }}
              />
            </ReferenceArea>
            <Area
              type="monotone"
              dataKey="value"
              stroke="url(#splitColor2)"
              strokeWidth={2}
              fill="url(#splitColor3)"
              legendType="none"
              name="Value"
              dot={<CustomDot normalTop={normalTop} normalBottom={normalBottom} />}
            />
            <XAxis dy={5} padding={{ left: 28, right: 12 }} dataKey="created" axisLine={false} tick={{ fontSize: '12px', fill: 'white' }} />
            <YAxis
              dx={-5}
              allowDecimals={false}
              domain={[0, (dataMax) => (dataMax < yAxisMinTop ? yAxisMinTop : Number(Math.round(dataMax * 1.1 + 'e2') + 'e-2').toFixed(2))]}
              type="number"
              axisLine={true}
              tick={{ fontSize: '12px', fill: 'white' }}
            />
            {normalBottom > 0 && (
              <ReferenceLine y={normalBottom} stroke="transparent">
                <Label
                  value={normalBottom}
                  position="insideLeft"
                  style={{
                    fill: '#3bb496',
                    fontSize: '12px',
                  }}
                />
              </ReferenceLine>
            )}
            <ReferenceLine y={normalTop} stroke="transparent">
              <Label value={normalTop} style={{ fill: '#3bb496', fontSize: '12px' }} position="insideLeft" />
            </ReferenceLine>
            {/* {normalBottom > 0 && ( */}
            <Line dataKey="normalRangeLow" stroke="#3bb496" dot={false} legendType="none" name="Normal Range Low" strokeWidth={4} />
            {/* )} */}
            <Line dataKey="normalRangeHigh" stroke="#3bb496" dot={false} legendType="none" name="Normal Range High" strokeWidth={4} />
            <CartesianGrid vertical={false} />
          </ComposedChart>
        </ResponsiveContainer>
      </Flex>
      {/* )} */}
    </Flex>
  );
};
