import React from 'react';
import { withRouter } from 'react-router';
import { Box, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { GET_MEMBERSHIPS } from '../../graphql';
import withSession from '../../lib/withSession';
import videoVisitIcon from '../../static/Doctor_Female_White_shirt_2.png';
import kitIcon from '../../static/kitIcon.png';
import nutritionistDashIcon from '../../static/nutritionistDashIcon.png';
import Medication from '../../static/Medication.png';
import { Skeleton } from '../../components/loadingSkeleton/Skeleton';
import PleaseWait from '../../components/PleaseWait';
import { Query } from 'react-apollo';
import { isToggleActive } from '../../components/featureToggle/toggles';

const Card = styled(Flex)`
  background: white;
  border-radius: 10px;
  box-shadow: 5px 10px 50px rgba(0, 0, 0, 0.07);
  flex-direction: column;
  padding: 14px 10px;
  margin-right: 14px;
  width: 126px;
  max-width: 126px;
  height: 138px;
  max-height: 138px;
  overflow: hidden;
`;
const ServicesContainer = styled(Flex)`
  transition: 0.3s all ease-in-out;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-bottom: 24px;
  &::-webkit-scrollbar {
    display: none;
  }
  & > * {
    transition: 0.3s all ease-in-out;
  }
  &:hover > * {
    opacity: 0.5;
  }
  &:hover > :hover {
    opacity: 1;
  }

  @media screen and (max-width: 480px) {
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 24px;
    &:hover > * {
      opacity: 1;
    }
  }
`;

const VirtualPadder = styled(Box)`
  width: 20px;
  min-width: 20px;
  @media screen and (min-width: 480px) {
    display: none;
  }
`;

const Container = styled(Flex)`
  width: 100%;
  max-width: 700px;

  @media screen and (max-width: 480px) {
    width: 100vw;
    max-width: 100vw;
    padding: 0 20px;
    overflow-x: hidden;
  }
`;

const LoadingCard = () => {
  return (
    <ServiceCard style={{ flexDirection: 'column', padding: '8px', opacity: 0.5 }}>
      <Flex flex={1} alignItems="center" justifyContent={'center'} style={{}}>
        <PleaseWait
          text={' '}
          spinner={{
            sColor: '#ECEFF2',
            pColor: '#909FB5',
          }}
        />
      </Flex>
      <Flex alignItems={'center'} justifyContent="center">
        <Skeleton width="75px" height="22px" />
      </Flex>
    </ServiceCard>
  );
};

const ServiceCard = styled(Card)`
  width: 126px;
  min-width: 126px;
  flex: initial;
`;

const ServiceAvailable = ({ history, session, loadingAppointments, hasFutureAppointments, hasPendingAsync, hasFutureAppointmentsNutritionist }) => {
  return (
    <Query query={GET_MEMBERSHIPS}>
      {({ data, loading }) => {
        return (
          <ServiceAvailableInner
            hasPendingAsync={hasPendingAsync}
            history={history}
            session={session}
            loadingAppointments={loadingAppointments}
            hasFutureAppointments={hasFutureAppointments}
            data={data}
            loading={loading}
            hasFutureAppointmentsNutritionist={hasFutureAppointmentsNutritionist}
          />
        );
      }}
    </Query>
  );
};

const ServiceAvailableInner = ({
  history,
  data,
  loading,
  hasFutureAppointmentsNutritionist,
  session: { eligibility, intakeStatus, isMember, diagnoseFull },
  loadingAppointments,
  hasFutureAppointments,
  hasPendingAsync,
}) => {
  let canBookVisit = intakeStatus !== 'disqualified' && (!hasFutureAppointments && !hasPendingAsync) && (eligibility.consultation || eligibility.canBookAsync);
  let canBookNoHypo = false;
  const canBuyKit = eligibility.status !== 'no_service' && eligibility.status !== 'unknown' && eligibility.purchase;
  let canBookNutritionist = eligibility.canBookNutritionist && intakeStatus !== 'disqualified';
  if (canBookVisit) {
    if (!diagnoseFull || !diagnoseFull.hasHypo) {
      canBookNoHypo = true;
      canBookVisit = false;
    }
  }
  if (canBookNutritionist) {
    if (!diagnoseFull || (!diagnoseFull.hasHypo && !diagnoseFull.hasHashi)) {
      canBookNutritionist = false;
    }
  }
  // console.log({canBookVisit,canBookNoHypo, canBookPerimenopause:eligibility.canBookPerimenopause,canBookPostmenopause: eligibility.canBookPostmenopause})
  const vitaminsData = !loading && !!data && data.getMemberships && data.getMemberships.memberships.find((x) => x.subscriptionTarget === 'SUBSCRIPTION_VITAMINS');
  const vitaminsOutOfStock = isToggleActive('VITAMINS_SUPPLEMENTS_OUT_OF_STOCK');
  return (
    <Container flexDirection="column" mt="32px">
      <Heading mb="32px">Services available to you</Heading>
      {!!loadingAppointments || !!loading ? (
        <ServicesContainer flexDirection={'row'}>
          <VirtualPadder />
          {[1, 2, 3, 4].map((x) => {
            return <LoadingCard />;
          })}
          <VirtualPadder />
        </ServicesContainer>
      ) : (
        <ServicesContainer flexDirection="row">
          <VirtualPadder />
          {!!canBookVisit && (
            <ServiceCard style={{ cursor: 'pointer' }} onClick={() => history.push(!hasPendingAsync ? '/schedule-split' : '/schedule')}>
              <Flex flex={1} alignItems="center" justifyContent={'center'} style={{ marginTop: '-20px' }}>
                <img src={videoVisitIcon} />
              </Flex>
              <Text textAlign={'center'} fontSize="14px">
                Schedule a visit
              </Text>
            </ServiceCard>
          )}
          {!!canBookNoHypo &&
            !canBookVisit && (
              <ServiceCard style={{ cursor: 'pointer' }} onClick={() => history.push('/schedule-split')}>
                <Flex flex={1} alignItems="center" justifyContent={'center'} style={{ marginTop: '-20px' }}>
                  <img src={videoVisitIcon} />
                </Flex>
                <Text textAlign={'center'} fontSize="14px">
                  Schedule a visit
                </Text>
              </ServiceCard>
            )}
          {!canBookNoHypo &&
            !canBookVisit &&
            (!!eligibility.canBookPerimenopause || !!eligibility.canBookPostmenopause) && (
              <ServiceCard style={{ cursor: 'pointer' }} onClick={() => history.push('/schedule-split')}>
                <Flex flex={1} alignItems="center" justifyContent={'center'} style={{ marginTop: '-20px' }}>
                  <img src={videoVisitIcon} />
                </Flex>
                <Text textAlign={'center'} fontSize="14px">
                  Schedule a visit
                </Text>
              </ServiceCard>
            )}
          {!!canBuyKit && (
            <ServiceCard style={{ cursor: 'pointer' }} onClick={() => history.push('/purchase')}>
              <Flex flex={1} alignItems="center" justifyContent={'center'} style={{ marginTop: '-20px', marginBottom: '-20px', overflow: 'hidden' }}>
                <img src={kitIcon} style={{ height: '100%' }} />
              </Flex>
              <Text textAlign={'center'} fontSize="14px">
                Test your Thyroid
              </Text>
            </ServiceCard>
          )}
          {!!canBookNutritionist &&
            !hasFutureAppointmentsNutritionist && (
              <ServiceCard style={{ cursor: 'pointer' }} onClick={() => history.push('/consultations-nutritionist')}>
                <Flex flex={1} alignItems="center" justifyContent={'center'} style={{ marginTop: '-10px', marginBottom: '-10px', overflow: 'hidden' }}>
                  <img src={nutritionistDashIcon} style={{ height: '100%' }} />
                </Flex>
                <Text textAlign={'center'} fontSize="14px">
                  Meet with a nutritionist
                </Text>
              </ServiceCard>
            )}
          {!vitaminsData && !vitaminsOutOfStock && (
            <ServiceCard style={{ cursor: 'pointer' }} onClick={() => history.push('/order-vitamins')}>
              <Flex flex={1} alignItems="center" justifyContent={'center'} style={{ marginTop: '5px', marginBottom: '10px', overflow: 'hidden' }}>
                <img src={Medication} style={{ height: '100%' }} />
              </Flex>
              <Text textAlign={'center'} fontSize="14px">
                Thyroid daily supplements
              </Text>
            </ServiceCard>
          )}
          {/* <Card>
          <Flex flex={1} />
          <Text textAlign={'center'} fontSize="14px">
            Paloma Health Membership
          </Text>
        </Card> */}
          <VirtualPadder />
        </ServicesContainer>
      )}
    </Container>
  );
};

export default withSession(withRouter(ServiceAvailable));
