export const bounceIn = (duration, delay) => {
  return {
    animationName: 'bounceIn',
    animationDuration: duration || '1s',
    animationDelay: delay || '0s',
    opacity: 0,
    animationFillMode: 'forwards',
  };
};

export const fadeIn = (duration, delay) => {
  return {
    animationName: 'fadeIn',
    animationDuration: duration || '1s',
    animationDelay: delay || '0s',
    opacity: 0,
    animationFillMode: 'forwards',
  };
};

export const fadeInDown = (duration, delay) => {
  return {
    animationName: 'fadeInDownFrom20',
    animationDuration: duration || '1s',
    animationDelay: delay || '0s',
    opacity: 0,
    animationFillMode: 'forwards',
  };
};

/*
from: 20, 30, or 40
*/
export const fadeInUp = (from, duration, delay) => {
  return {
    animationName: `fadeInUpFrom${from}`,
    animationDuration: duration || '1s',
    animationDelay: delay || '0s',
    opacity: 0,
    animationFillMode: 'forwards',
  };
};
