import React from 'react';
import { Flex, Text } from 'rebass';
import TelehealthConsult from '../../../static/telehealthConsult.png';

export const VisitConfirmed = () => {
  return (
    <Flex flexDirection={'column'} style={{zIndex:1}}>
      <Text mb={4} fontWeight={500} textAlign={'center'}>
        Your visit is confirmed
      </Text>
      <Text mb={4} fontWeight={300} textAlign={'center'}>
        Thank you, your visit is confirmed and we are looking forward to seeing you soon.
      </Text>

      <Flex flexDirection={'column'} width={'100%'} style={{ background: '#E7F0FF', borderRadius: '10px', padding: '22px 24px' }}>
        <Flex mb={2}>
          <Text mr={2} flex={1} fontWeight={500} style={{ flexWrap: 'wrap' }}>
            Telemedicine Best Practices
          </Text>

          <img src={TelehealthConsult} width={'112px'} style={{ objectFit: 'contain', marginTop: '-48px' }} />
        </Flex>

        <ul>
          <li>
            <Text fontWeight={300} mb={2}>Choose a well-lit space with a steady internet connection and minimal background noise.</Text>
          </li>
          <li>
            <Text fontWeight={300} mb={2}>When you join the video visit, check that your video and audio are working correctly.</Text>
          </li>
          <li>
            <Text fontWeight={300} mb={2}>You may enter the waiting room 15 minutes before your appointment.</Text>
          </li>
          <li>
            <Text fontWeight={300}>
              You can use your mobile or laptop/desktop computer with a supported web browser (Chrome, Safari, or Firefox). Make sure your computer is equipped with a webcam.
            </Text>
          </li>
        </ul>
      </Flex>
    </Flex>
  );
};
