import React, {useEffect} from "react";
// import {useEffect} from "react";
import {
    SwitchSelectorWrapper,
    OptionItem,
    OptionItemLabel,
    OptionInput,
    OptionItemLabelSmall
} from "./SwitchSelector.styled";

const classNamesPrefix = "react-switch-selector";

const defaultColors = {
    backgroundColor: "#ecf0f1",
    selectedBackgroundColor: "#2ecc71",
    fontColor: "#000",
    selectedFontColor: "#fff"
};

const SwitchSelector=(props) => {
    const {
        onChange = (value) => console.log(value),
        options = [],
        initialSelectedIndex = 0
    } = props;
    const canApplyInitialSelectedIndex = !!options[initialSelectedIndex];
    const [selectedIndex, setSelectedIndex] = React.useState(
        canApplyInitialSelectedIndex ? initialSelectedIndex : 0
    );

    if (!canApplyInitialSelectedIndex) {
        console.warn(
            "[react-switch-selector]: Passed initialSelectedIndex prop doesn't match item from your options array"
        );
    }

    const {
        border = 0,
        backgroundColor = defaultColors.backgroundColor,
        selectedBackgroundColor = defaultColors.selectedBackgroundColor,
        wrapperBorderRadius = 20,
        optionBorderRadius = 18,
        fontSize = 14,
        fontColor = defaultColors.fontColor,
        selectedFontColor = defaultColors.selectedFontColor,
        selectionIndicatorMargin = 2,
        forcedSelectedIndex, optionStyle={}
    } = props;

    useEffect(() => {
        if (
            forcedSelectedIndex !== undefined &&
            !!options[forcedSelectedIndex] &&
            forcedSelectedIndex !== selectedIndex
        ) {
            setSelectedIndex(forcedSelectedIndex);
        }
    }, [forcedSelectedIndex, options, selectedIndex]);

    const handleOnClick = (idx) => {
        if (idx !== selectedIndex) {
            setSelectedIndex(idx);
            onChange(options[idx].value);
        }
    };

    const renderOptions = () => {
        return options.map((option, idx) => {
            const _optionId = `rss-option-${idx}`;
            const isRawText = typeof option.label === "string";

            const labelRawTextProps = {
                fontSize,
                fontColor: option.fontColor || fontColor,
                selectedFontColor: option.selectedFontColor || selectedFontColor
            };
            return (
                <OptionItem
                    key={_optionId}
                    optionsAmount={options.length}
                    className={`${classNamesPrefix}-option`}
                    optionBorderRadius={optionBorderRadius}
                >
                    <OptionItemLabelSmall
                        className={`${classNamesPrefix}-option-label`}
                        selected={selectedIndex === idx}
                        isRawText={isRawText}
                        {...(isRawText ? labelRawTextProps : {})}
                        style={{...optionStyle}}
                    >
                        <OptionInput
                            type="radio"
                            id={_optionId}
                            onChange={() => handleOnClick(idx)}
                            checked={selectedIndex === idx}
                            style={{...optionStyle}}
                        />
                        {option.label}
                    </OptionItemLabelSmall>
                </OptionItem>
            );
        });
    };

    if (!options.length) return null;
    return (
        <SwitchSelectorWrapper
            selectedIndex={selectedIndex}
            optionsAmount={options.length}
            className={`${classNamesPrefix}-wrapper`}
            border={border}
            backgroundColor={backgroundColor}
            selectedBackgroundColor={
                selectedBackgroundColor
            }
            wrapperBorderRadius={wrapperBorderRadius}
            optionBorderRadius={optionBorderRadius}
            selectionIndicatorMargin={selectionIndicatorMargin}
        >
            {renderOptions()}
        </SwitchSelectorWrapper>
    );
};

export default SwitchSelector;