import React, { useState } from 'react';
import { Flex, Heading } from 'rebass';
import FormTargets from './list/FormTargets';
import Templates from './list/Templates';

const HealthCoachFormsList = () => {
  return (
    <Flex flexDirection={'column'} width={'100%'} flex={1} p={4}>
      <Heading mb={4}>Health Coach Form Templates</Heading>

      <FormTargets />
      <Templates />
    </Flex>
  );
};

export default HealthCoachFormsList;
