import React, { Fragment, Component } from 'react';
import { Button, Card, Flex, Box, Heading, Text } from 'rebass';
import moment from 'moment';
import styled from 'styled-components';

import { Formik, Form, Field } from 'formik';
import { compose, withApollo, Query } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { withWizard } from 'react-albus';
import * as card from 'card-validator';

import { FormField } from '../components/FormField';
import { TextInput } from '../components/TextInput';
import DoctorIcon from '../static/doctorImage.png';

import { ATHENA_CARD, UPDATE_ATHENA_CARD, ATHENA_CARD_FOR_PATIENT } from '../graphql';
import withAnalytics from '../lib/withAnalytics';
import SpinLoader from '../components/SpinLoader';
import Review from './scheduleDoctor/components/payment/review';
import CheckboxInput from '../components/CheckboxInput';
import withSession from '../lib/withSession';
import PlacesAutocomplete from '../components/addressAutoComplete/PlacesAutocomplete';
import InformationSecuredDisclaimer from '../components/InformationSecuredDisclaimer';
import { isToggleActive } from '../components/featureToggle/toggles';
import { uuidv4 } from '../utils/uuid';
import { isMobile } from 'react-device-detect';
import { MaskInput } from '../components/MaskInput';
import { PaymentInputsContainer, PaymentInputsWrapper } from '../components/creditCardInput';
import images from '../components/creditCardInput/cardsIcons';
import { Constants } from '../utils/constants';

const Avatar = styled(Flex)`
  width: 160px;
  height: 160px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  & > img {
    width: 200px;
  }
  @media screen and (min-width: 52em) {
    &.small {
      width: 60px;
      height: 60px;
      & > img {
        width: 60px;
      }
    }
  }
`;

const BoxRight = styled(Box)`
  width: 100%;
  @media screen and (min-width: 52em) {
    -webkit-transition: width 0.5s; /* For Safari 3.1 to 6.0 */
    transition: width 0.5s;
    &.loaded {
      width: 33%;
    }
  }
`;

const TextDescriptionAppointment = styled(Text)`
  transition-delay: 0.4s;
  opacity: 0;
  &.loaded {
    opacity: 1;
  }
`;

const BoxLeft = styled(Box)`
  width: 100%;
  @media screen and (min-width: 52em) {
    transform: scale(0);
    width: 0%;
    -webkit-transition: all 0.1s; /* For Safari 3.1 to 6.0 */
    transition: all 0.1s;
    transition-delay: 0.3s;
    &.loaded {
      width: 67%;
      transform: scale(1);
    }
  }
`;

class SchedulePayment extends Component {
  constructor(props) {
    super(props);
    
    const hasNewPriceFeature = isToggleActive('USE_NEW_PRICING');
    const hasNewPrice60Feature = isToggleActive('NEW_PRICING_60');
    const { selectedAppointment, reinitFlow, wizard, session, addMembership, quadPaySubscribedData } = this.props;
    const isB2bFreeVisits = !!session && session.b2b && session.b2b.visits && session.b2b.isActive && !session.b2b.mustVerify;
    this.state = {
      step: !!isB2bFreeVisits ? 'review' : 'payment',
      isLoadingInsuranceQuote: true,
      regularCost: !!isB2bFreeVisits ? 0 : (session && session.isMember) || !!addMembership || !!quadPaySubscribedData ? (hasNewPrice60Feature ? 60 : 42) : hasNewPriceFeature ? 110 : 99,
      copayCost: undefined,
      copayLoaded: false,
      billingSameAsShiping: true,
      forTheNext: null,
      quadpayOrderReference: uuidv4(),
    };
    if (!selectedAppointment) {
      reinitFlow(wizard);
    }
    this.holdSpotForTheNextUpdate();
  }

  componentDidMount() {}

  onCompleteCallback = function(result) {
    const { setQuadPayData } = this.props;
    const { quadpayOrderReference } = this.state;
    console.log(result);
    setQuadPayData(result.card.number, result.card.cvc, result.card.expirationMonth, result.card.expirationYear, quadpayOrderReference);
  };

  holdSpotForTheNextUpdate = () => {
    const eventTime = moment().add(5, 'minutes'); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
    const currentTime = moment(); // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
    const diffTime = eventTime - currentTime;
    let duration = moment.duration(diffTime, 'milliseconds');
    const interval = 1000;
    setInterval(() => {
      duration = moment.duration(duration - interval, 'milliseconds');
      if (duration > 0) {
        this.setState({
          forTheNext: duration.minutes() + ':' + (duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds()),
        });
      } else {
        this.setState({
          forTheNext: '-1',
        });
      }
    }, interval);
  };

  isDoctorInNetwork = (networks, network) => {
    for (let index = 0; index < networks.length; index++) {
      const nt = networks[index];
      if (nt.toLowerCase() === network.toLowerCase()) {
        return true;
      }
    }
    return false;
  };

  componentDidMount = async () => {
    const { newInsurance, onFileInsurance, selectedAppointment } = this.props;

    this.setState(
      {
        isLoadingInsuranceQuote: false,
        copayCost: null,
        copayError: !!selectedAppointment && selectedAppointment.isInNetwork && (newInsurance || (onFileInsurance && onFileInsurance.use)) ? 'Err' : null,
      },
      () => {}
    );
  };

  componentDidMount() {
    this.props.analytics.page('Payment', '/schedule');
  }

  updatePayment = async (values, actions) => {
    const { client, toastManager, isNewPatientFlow, storeCard } = this.props;
    try {
      const expiration = values.expirationdate.split('/');
      if (expiration.length !== 2) {
        toastManager.add('Invalid Expiration Date.', {
          appearance: 'error',
        });
        actions.setSubmitting(false);
        return;
      }
      if (expiration[1].toString().length < 2) {
        toastManager.add('Invalid Expiration Date.', {
          appearance: 'error',
        });
        actions.setSubmitting(false);
        return;
      }
      if (expiration[0].toString().length < 1 || expiration[0].toString().length > 2) {
        toastManager.add('Invalid Expiration Date.', {
          appearance: 'error',
        });
        actions.setSubmitting(false);
        return;
      }
      storeCard({
        accountNumber: values.cardnumber,
        cvc: values.cvc2,
        expMonth: expiration[0].length < 2 ? `0${expiration[0]}` : expiration[0],
        expYear: expiration[1].toString().substr(expiration[1].length - 2),
        zip: values.zip || null,
        street1: values.street1 || null,
      });

      if (isNewPatientFlow) {
        actions.setSubmitting(false);
        this.setState({
          step: 'review',
          emulatedAthenaCard: {
            last4: values.cardnumber.slice(-4),
            expMonth: expiration[0].length < 2 ? `0${expiration[0]}` : expiration[0],
            expYear: expiration[1].toString().substr(expiration[1].length - 2),
            brand: '',
          },
        });
        return;
      } else {
        this.setState({
          step: 'review',
          emulatedAthenaCard: {
            last4: values.cardnumber.slice(-4),
            expMonth: expiration[0].length < 2 ? `0${expiration[0]}` : expiration[0],
            expYear: expiration[1].toString().substr(expiration[1].length - 2),
            brand: '',
          },
        });
      }
      this.setState({ step: 'review' });
    } catch (error) {
      console.log(error);
      actions.setSubmitting(false);
    }
  };

  render() {
    const { handlePayment, selectedAppointment, wizard, isAdmin, enforcePatientId, isNewPatientFlow, duration, addMembership, session, quadPaySubscribedData } = this.props;

    const { copayCost, regularCost, isLoadingInsuranceQuote, emulatedAthenaCard, couldntConfirmCard, aCard, copayError, cardLoaded, forTheNext } = this.state;
    console.log('schedulePayment', { session, addMembership });
    if (!selectedAppointment) {
      return <div />;
    }

    return (
      <Fragment>
        {this.state.step === 'payment' && (
          <>
            {!quadPaySubscribedData ? (
              forTheNext !== '-1' ? (
                <Text textAlign="center" mb={3}>
                  We are holding this slot for the next
                  <br />
                  {forTheNext} minutes.
                </Text>
              ) : (
                <Text textAlign="center" mb={3}>
                  This slot is no longer held.
                </Text>
              )
            ) : (
              <Text textAlign="center" mb={3}>
                <span style={{ fontWeight: 600 }}>Welcome to the Paloma Membership!</span>
                <br />
                Finish booking your visit
              </Text>
            )}
            <Flex flexDirection={['column', 'column', 'row']} px={3}>
              <BoxLeft flexDirection="column" flex={1} className={!isLoadingInsuranceQuote ? 'loaded' : ''}>
                {!isNewPatientFlow &&
                  !addMembership && (
                    <Query
                      query={isAdmin ? ATHENA_CARD_FOR_PATIENT : ATHENA_CARD}
                      variables={isAdmin ? { patientId: enforcePatientId } : null}
                      onCompleted={(data) => {
                        if (!aCard && !cardLoaded) {
                          this.setState({
                            aCard: isAdmin ? data.athenaCardForPatient : data.athenaCard,
                            cardLoaded: true,
                          });
                        }
                      }}
                    >
                      {({ data, loading, error }) => {
                        if (loading) {
                          return <div />;
                        }

                        let athenaCard = {};
                        if (!loading && !error && data && !isNewPatientFlow) {
                          athenaCard = isAdmin ? data.athenaCardForPatient : data.athenaCard;
                        }
                        if (athenaCard && !isNewPatientFlow) {
                          return (
                            <Box mr={[0, 0, 4]} mb={4}>
                              <Card p={4}>
                                <Flex flexDirection="column" alignItems="center" mb={4}>
                                  <Heading textAlign="center" style={{ fontWeight: 'bold' }} mb={4}>
                                    Default Payment Method
                                  </Heading>
                                  <Text>
                                    {athenaCard.brand} **
                                    {athenaCard.last4}
                                  </Text>
                                  <Text>
                                    Expires {athenaCard.expMonth}/{athenaCard.expYear}
                                  </Text>
                                </Flex>

                                <Flex justifyContent="center">
                                  <Button
                                    id="btn-useThisCard"
                                    variant="pink"
                                    width={[1, 1 / 2]}
                                    onClick={() =>
                                      this.setState({
                                        step: 'review',
                                      })
                                    }
                                  >
                                    Use this Card
                                  </Button>
                                </Flex>
                              </Card>
                            </Box>
                          );
                        } else {
                          return <div />;
                        }
                      }}
                    </Query>
                  )}
                {/* {!!addMembership && (
                  <Box mr={[0, 0, 4]} mb={4}>
                    <Card p={4}>
                      
                    </Card>
                  </Box>
                )} */}
                {!isAdmin && (
                  <Box mr={[0, 0, 4]} mb={4}>
                    <Card p={4}>
                      <Flex flexDirection="column" mb={4}>
                        <Heading textAlign="center" style={{ fontWeight: 'bold' }} mb={2}>
                          {aCard && !isNewPatientFlow & !addMembership ? 'Update Payment Method' : 'Payment Method'}
                        </Heading>
                        <Text textAlign="center" fontWeight={300}>
                          We accept HSA and FSA cards
                          <br />
                          Entering payment information holds your spot.
                        </Text>
                      </Flex>

                      <PaymentInputsContainer>
                        {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps, wrapperProps, getCardImageProps }) => (
                          <Formik
                            initialValues={{
                              nameoncard: '',
                              cardnumber: '',
                              cvc2: '',
                              expirationdate: '',
                              zip: '',
                              billingSameAsShiping: true,
                            }}
                            validate={(values, props) => {
                              let errors = {};
                              if (!values.cardnumber || values.cardnumber === '') {
                                errors.cardnumber = 'Required';
                              }
                              if (meta.erroredInputs.cardNumber) {
                                errors.cardnumber = meta.erroredInputs.cardNumber;
                              }
                              if (meta.erroredInputs.expiryDate) {
                                errors.expirationdate = meta.erroredInputs.expiryDate;
                              }
                              if (meta.erroredInputs.cvc) {
                                errors.cvc2 = meta.erroredInputs.cvc;
                              }
                              // if (!values.cvc2 || values.cvc2 === '') {
                              //   errors.cvc2 = 'Required';
                              // } else {
                              //   if (card.number(values.cvc2) && card.number(values.cvc2).card && card.number(values.cvc2).card.code && card.number(values.cvc2).card.code.size) {
                              //     if (!card.cvv(values.cvc2, card.number(values.cardnumber).card.code.size)) {
                              //       errors.cardnumber = 'CVC is invalid';
                              //     }
                              //   } else {
                              //     if (!card.cvv(values.cvc2, 3)) {
                              //       errors.cardnumber = 'CVC is invalid';
                              //     }
                              //   }
                              // }

                              // if (!values.expirationdate || values.expirationdate === '') {
                              //   errors.expirationdate = 'Required';
                              // } else {
                              //   if (!card.expirationDate(values.expirationdate).isValid) {
                              //     errors.expirationdate = 'Expiration date is invalid';
                              //   }

                              //   if (values.expirationdate.length < 3 || values.expirationdate.indexOf('/') < 1) {
                              //     errors.expirationdate = 'Expiration date is invalid';
                              //   }
                              // }

                              if (!this.state.billingSameAsShiping || isNewPatientFlow) {
                                if (!values.originalAddress || values.originalAddress === '') {
                                  errors.street1 = 'Required';
                                }
                                if (!values.street1 || values.street1 === '') {
                                  errors.street1 = 'Required';
                                }
                              }
                              console.log({ errors, values });
                              return errors;
                            }}
                            onSubmit={async (values, actions) => {
                              const aa = document.getElementsByClassName('pac-container');
                              // const bb = document.getElementsById('pac-container')
                              // console.log(aa);
                              if (aa) {
                                for (let index = 0; index < aa.length; index++) {
                                  const element = aa[index];
                                  // console.log(element.style);
                                  if (element.style && element.style.display !== 'none') {
                                    actions.setSubmitting(false);
                                    return;
                                  }
                                }
                              }
                              this.updatePayment(values, actions);
                            }}
                            render={({ values, errors, touched, isValid, isSubmitting, handleChange, validateForm, handleBlur, setFieldValue }) => (
                              <Form>
                                {/* <Flex flexDirection="column">
                              <FormField error={touched.cardnumber && errors.cardnumber} mb={3}>
                                <label for="cardnumber">Credit Card Number</label>
                                <TextInput name="cardnumber" />
                              </FormField>

                              <Flex flexDirection={['column', 'column', 'row']} mb={4}>
                                <FormField width={[1, 1, 1 / 3]} mr={[0, 0, 3]} error={touched.expirationdate && errors.expirationdate}>
                                  <label for="expirationdate">Expiration Date</label>
                                  <MaskInput name="expirationdate" mask="99/99" value={values.expirationdate} onChange={handleChange} onBlur={handleBlur} id="expirationdate" />
                                </FormField>

                                <FormField width={[1, 1, 1 / 3]} mr={[0, 0, 3]} error={touched.cvc2 && errors.cvc2}>
                                  <label for="cvc2">CVC</label>
                                  <TextInput name="cvc2" />
                                </FormField>
                              </Flex>
                            </Flex> */}

                                <Flex flexDirection="column" mb={3}>
                                  <PaymentInputsWrapper {...wrapperProps}>
                                    <Flex flexDirection={['column', 'row']} alignItems={'center'} width={'100%'}>
                                      {/* <FormField error={touched.cardnumber && errors.cardnumber}> */}
                                      <Flex alignItems={'center'} flex={1}>
                                        <svg {...getCardImageProps({ images })} />
                                        <Field name="cardnumber">
                                          {({ field }) => (
                                            <input
                                              {...getCardNumberProps({
                                                onBlur: field.onBlur,
                                                onChange: (e) => {
                                                  field.onChange(e);
                                                  setFieldValue('cardnumber', (e.target.value || '').replace(/ /g, ''));
                                                },
                                              })}
                                              style={{ color: '#4E658A' }}
                                            />
                                          )}
                                        </Field>
                                      </Flex>
                                      <Flex alignItems={'center'} mt={[3, 0]} width={['100%', 'initial']}>
                                        <Flex flex={[1, 'initial']}>
                                          <Field name="expirationdate">
                                            {({ field }) => (
                                              <input
                                                {...getExpiryDateProps({
                                                  onBlur: field.onBlur,
                                                  onChange: (e) => {
                                                    field.onChange(e);
                                                    setFieldValue('expirationdate', (e.target.value || '').replace(/ /g, ''));
                                                  },
                                                })}
                                                style={{ color: '#4E658A' }}
                                              />
                                            )}
                                          </Field>
                                        </Flex>
                                        <Field name="cvc2">
                                          {({ field }) => (
                                            <input
                                              {...getCVCProps({
                                                onBlur: field.onBlur,
                                                onChange: (e) => {
                                                  field.onChange(e);
                                                  setFieldValue('cvc2', e.target.value);
                                                },
                                              })}
                                              style={{ color: '#4E658A' }}
                                            />
                                          )}
                                        </Field>
                                      </Flex>
                                    </Flex>
                                  </PaymentInputsWrapper>
                                </Flex>

                                <label for="billingSameAsShiping">Billing Address</label>
                                {!isNewPatientFlow && (
                                  <FormField flexDirection="row" mb={4}>
                                    <CheckboxInput
                                      name="billingSameAsShiping"
                                      onChanged={(option) => {
                                        this.setState({
                                          billingSameAsShiping: !option,
                                        });
                                      }}
                                      styles={{
                                        checked: {
                                          Box: {
                                            backgroundColor: 'rgb(54, 79, 121)',
                                          },
                                        },
                                      }}
                                    />
                                    <Text fontSize={1}>Same as shipping address</Text>
                                  </FormField>
                                )}
                                {(!this.state.billingSameAsShiping || isNewPatientFlow) && (
                                  <FormField
                                    mb={3}
                                    error={touched.address && (errors.street1 || errors.city || errors.zip || errors.state) ? errors.street1 || errors.city || errors.zip || errors.state || '' : null}
                                  >
                                    <PlacesAutocomplete fieldname="address" handleChange={handleChange} street1FieldName="street1" cityFieldName="city" zipFieldName="zip" stateFieldName="state" />
                                  </FormField>
                                )}

                                <Text textAlign="center" fontWeight={600} mb={3} color='#F7A396'>
                                  We will not charge you until after your appointment completes.
                                </Text>
                                <Flex alignItems="center" flexDirection="column">
                                  <Button disabled={!isValid || isSubmitting} variant="pink" width={[1, 1 / 2]} type="submit">
                                    {aCard && !isNewPatientFlow ? 'Update Card' : 'Save Card'}
                                  </Button>
                                </Flex>

                                <InformationSecuredDisclaimer />
                              </Form>
                            )}
                          />
                        )}
                      </PaymentInputsContainer>
                    </Card>
                  </Box>
                )}
              </BoxLeft>

              <BoxRight
                className={!isLoadingInsuranceQuote ? 'loaded' : ''}
                mb={[4, 4, 0]}
                flexDirection="column"
                order={[-1, -1, 3]}
                style={{
                  paddingLeft: !!isMobile ? '0' : '16px',
                  paddingRight: !!isMobile ? '0' : '16px',
                  minWidth: '310px',
                }}
              >
                <Box onClick={() => this.setState({ copayLoaded: true })}>
                  <Card p={4}>
                    <Flex flexDirection="column">
                      <Flex mb={3} justifyContent="center">
                        {selectedAppointment && selectedAppointment.doctor && selectedAppointment.doctor.picture ? (
                          <Avatar>
                            <img src={selectedAppointment.doctor.picture} alt="" />
                          </Avatar>
                        ) : (
                          <img src={DoctorIcon} alt="" height="200px" />
                        )}
                      </Flex>

                      <Flex mb={3} justifyContent="center">
                        <Text textAlign="center" fontSize={3} fontWeight={700}>
                          Video visit with
                          <br />
                          {selectedAppointment && selectedAppointment.doctor && selectedAppointment.doctor.patientFacingName ? selectedAppointment.doctor.patientFacingName : 'A Thyroid Doctor'}
                        </Text>
                      </Flex>
                      {isLoadingInsuranceQuote ? (
                        <Flex mb={3} justifyContent="center" flexDirection="column" textAlign="center">
                          <SpinLoader style={{ margin: '0 auto' }} width="25" height="25" />
                          <Text fontWeight="400" textAlign="center" mt={3}>
                            We Are Retrieving Your Quote
                          </Text>
                        </Flex>
                      ) : (
                        <Flex mb={copayError ? 2 : 3} justifyContent="center">
                          <Text textAlign="center" fontSize={copayError ? '16px' : 3} fontWeight={700} style={{ marginTop: '8px' }}>
                            {copayCost ? (
                              <Fragment>
                                <span style={{ fontWeight: '400' }}>{`Your Copay: `}</span>
                                {`$${copayCost}`}
                              </Fragment>
                            ) : copayError ? (
                              'Pricing'
                            ) : (
                              `$${regularCost}`
                            )}
                          </Text>
                        </Flex>
                      )}

                      <Flex mb={3} justifyContent="center" flexDirection="column">
                        {copayError && (
                          <TextDescriptionAppointment className={!isLoadingInsuranceQuote ? 'loaded' : ''} textAlign="center" fontSize={1} mb={4} fontWeight={200}>
                            <>
                              <span>
                                Please note it can take up to 6 weeks for the claim to be completed. You will be charged for any co-insurance, co-pays, and deductibles. Most patients pay only their
                                co-pay.
                              </span>

                              {((this.props.session && this.props.session.isMember && this.props.session.membershipSpecific === 'insurance') || (addMembership && addMembership === 'mi')) && (
                                <span>
                                  <br />
                                  If your insurance cannot be billed for this visit, your cash pay price will be $110.
                                </span>
                              )}
                            </>
                          </TextDescriptionAppointment>
                        )}
                        <Flex mb={3} justifyContent="center" />
                        {!!isToggleActive('MEMBERSHIP_ONLY') &&
                          !!addMembership &&
                          !quadPaySubscribedData && (
                            <>
                              <TextDescriptionAppointment className={!isLoadingInsuranceQuote ? 'loaded' : ''} textAlign="center" fontSize={1} fontWeight={200} mb={2}>
                                {`Your ${addMembership === 'mi' ? '$96' : `$${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}`} annual membership fee will be charged today.`}
                              </TextDescriptionAppointment>
                            </>
                          )}
                        <TextDescriptionAppointment className={!isLoadingInsuranceQuote ? 'loaded' : ''} textAlign="center" fontSize={1} fontWeight={200} mb={3}>
                          {`Your video visit will last up to ${duration || 30} minutes. You can join the visit with any smartphone or computer equipped with a webcam.`}
                        </TextDescriptionAppointment>
                      </Flex>
                    </Flex>
                  </Card>
                </Box>
              </BoxRight>
            </Flex>
          </>
        )}

        {this.state.step === 'review' && (
          <Review
            forTheNext={forTheNext}
            copayError={copayError}
            regularCost={regularCost}
            copayCost={copayCost}
            selectedAppointment={selectedAppointment}
            athenaCard={emulatedAthenaCard || aCard}
            handlePayment={handlePayment}
            analytics={this.props.analytics}
            wizard={wizard}
            couldntConfirmCard={couldntConfirmCard}
            addMembership={addMembership}
            session={session}
          />
        )}
      </Fragment>
    );
  }
}

export const SchedulePaymentUnregistered = compose(
  withApollo,
  withToastManager,
  withAnalytics,
  withWizard
)(SchedulePayment);

export default compose(
  withApollo,
  withToastManager,
  withAnalytics,
  withWizard,
  withSession
)(SchedulePayment);
