import React, { Component, Fragment } from 'react';
import { Box, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { compose, withApollo } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import moment from 'moment-timezone';
import { loadStripe } from '@stripe/stripe-js';

import ConfirmModal from '../components/ConfirmModal';
import { SchedulePaymentUnregistered } from './SchedulePayment';
import { Steps, Step, Wizard } from 'react-albus';

import {
  APPOINTMENTS,
  BOOK_APPT,
  CURRENT_USER,
  ADD_INSURANCE,
  ASSIGN_INSURANCE_TO_APPOINTMENT,
  UPCOMING_APPOINTMENTS,
  SIGNUP,
  UPDATE_ATHENA_CARD,
  ATHENA_CARD,
  SEND_INSURANCE,
  GET_ANALYTICS_ID,
  MEMBERSHIP_CREATE_SESSION,
  QUADPAY_SUBSCRIBED,
} from '../graphql';
import FullLayout from '../components/FullLayout';
import withAnalytics from '../lib/withAnalytics';
import PickInsuranceProvider from './scheduleDoctor2/index';
import { PickDoctorNewPatient } from './scheduleDoctor2/pickDoctor';
import { InsuranceBenefitsCheckUnregistered } from './scheduleDoctor2/insuranceBenefitsCheck';
import { Stepper } from './scheduleDoctor/stepper';
import Complete from './scheduleDoctor/complete';
import Logo from '../static/palomaTransparent.png';
import ByAKit from './scheduleDoctor/newPatientOnly/buyAKit';
import NoServiceState from './scheduleDoctor/noServiceState';
import { getRegionByAbbreviation, getRegionByName } from '../components/RegionSelect';
import Basic from './registration/Basic';
import Profile from './registration/Profile';
import Extended from './registration/Extended';
import SpinLoader from '../components/SpinLoader';
import SelectMembership from './scheduleDoctor2/selectMembership';
import SelectPlan from './scheduleDoctor2/SelectPlan2';
import { isMobile } from 'react-device-detect';
import { isToggleActive } from '../components/featureToggle/toggles';
import UploadInsuranceCard from './scheduleDoctor2/UploadInsuranceCardNew';
import QuadpayComplete from './scheduleDoctor2/QuadpayComplete';
import Otp from './registration/Otp';
import PreferredPronouns from './registration/PreferredPronouns';
import GenderIdentity from './registration/GenderIdentity';
import LegalSex from './registration/LegalSex';
import BirthSex from './registration/BirthSex';
import { uuidv4 } from '../utils/uuid';
import { getMemberVisitPrice, getMemberVisitPriceNumber } from '../utils/pricing';
import { withCookies } from 'react-cookie';
import { Constants } from '../utils/constants';

const initalState = {
  loading: false,
  isConfirmOpen: false,
  slot: {},
  step: 'pickProvider',
  athenaPackageId: undefined,
  isAlreadyBookedAppointmentLoaded: false,
};

const MobileButton = styled(Box)`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  justify-content: center;
  z-index: 1;
`;

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
  @media screen and (max-width: 52em) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const DesktopOnly = styled(Flex)`
  max-width: 800px;
  margin: 40px auto 0;
  width: 100%;
  flex: none;
  @media screen and (max-width: 52em) {
    display: none;
  }
`;

const InnerContainer = styled(Flex)`
  max-width: 850px;
  margin: 0 auto;
  flex-direction: column;
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const InfoBox = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${(props) => props.theme.color.darkBluw};
  justify-content: center;
  max-width: 550px;
`;

class ScheduleNewPatient extends Component {
  constructor(props, context) {
    super(props, context);
    this.wizard = React.createRef();
    let locationState = undefined;
    // console.log('props', this.props);
    if (props && props.match && props.match.params && props.match.params.state) {
      const dept = getRegionByName(props.match.params.state);
      // console.log('props dept', dept);
      let isNonServicedState = null;
      if (dept) {
        isNonServicedState = dept.level !== 'full' && dept.level !== 'visit';
      }
      this.state = {
        ...initalState,
        locationState,
        isBuyAKit: false,
        isSubmitting: false,
        department: dept,
        linkDepartment: !!dept,
        isNonServicedState,
        quadPaySubscribedData: undefined,
      };
    } else {
      this.state = {
        ...initalState,
        locationState,
        isBuyAKit: false,
        isSubmitting: false,
        quadPaySubscribedData: undefined,
      };
    }
  }

  setInsuranceCard = (planType, cardFront, cardBack, wizard, email, primarySubscriberFullName, dob, conversationId) => {
    this.setState({
      newInsurance: {
        planType,
        cardBack,
        cardFront,
        email,
        primarySubscriberFullName,
        dob,
        conversationId,
      },
    });

    this.wizard.current.push('pickDoctor');
  };

  componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;
    if (currentPage !== prevPage) {
      let tag = '';
      window.scrollTo(0, 0);

      if (currentPage.indexOf('pickDoctor') > -1) {
        tag = 'pickDoctor';
      } else if (currentPage.indexOf('pickProvider') > -1) {
        tag = 'pickProvider';
      } else if (currentPage.indexOf('insurance') > -1) {
        tag = 'insurance';
      } else if (currentPage.indexOf('insuranceBenefitsCheck') > -1) {
        tag = 'insuranceBenefitsCheck';
      } else if (currentPage.indexOf('payment') > -1) {
        tag = 'payment';
      } else if (currentPage.indexOf('insuranceBenefitsCheck') > -1) {
        tag = 'insuranceBenefitsCheck';
      } else if (currentPage.indexOf('basic') > -1) {
        tag = 'basic';
      } else if (currentPage.indexOf('profile') > -1) {
        tag = 'profile';
      } else if (currentPage.indexOf('extended') > -1) {
        tag = 'extended';
      } else if (currentPage.indexOf('complete') > -1) {
        tag = 'complete';
      } else if (currentPage.indexOf('nonServiced') > -1) {
        tag = 'nonServiced';
      }
      if (tag) {
        this.props.analytics.page(tag, 'bookConsultation');
      }
    }
  }

  handleSelect = (slot) => {
    this.setState({
      isConfirmOpen: true,
      slot: slot,
    });
  };

  handleSubmit = (values, actions, wizard) => {
    this.setState({ values: { ...values } });
    if (!!actions) {
      actions.setSubmitting(false);
    }
    this.wizard.current.next();
  };

  parseCustomDate = (date, time, timezoneOffset) => {
    var dateParts = date.split('/');

    var st = dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1];
    var st2 = `${st}T${time}:00.000${this.timezoneOffsetFormatter(timezoneOffset)}00`;

    var dt = new Date(st2);

    return dt;
  };

  parseCustomDateLocal = (date, time, timeZoneName) => {
    var dateParts = date.split('/');

    var st = dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1];
    var st2 = `${st} ${time}`;

    return moment.tz(st2, timeZoneName);
  };

  timezoneOffsetFormatter = (timezoneOffset) => {
    if (timezoneOffset === 0) return '+00';
    if (timezoneOffset < 0) {
      const t = timezoneOffset * -1;
      if (timezoneOffset > -10) {
        return `-0${t}`;
      }
      return `-${t}`;
    }

    if (timezoneOffset > 0) {
      if (timezoneOffset < 10) {
        return `+0${timezoneOffset * 1}`;
      }
      return `+${timezoneOffset * 1}`;
    }

    return '+00';
  };

  addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
  };

  handlePayment = async (wizard, callback) => {
    if (!!this.state.newInsurance) {
      this.wizard.current.next();
    } else {
      this.wizard.current.push('basic');
    }
  };

  handleCompleteProfile = async (values, actions, wizard) => {
    const { toastManager, analytics } = this.props;

    this.setState({
      values: { ...values },
      loading: true,
      isSubmitting: true,
    });

    const variables = this.state.values;

    this.setState({ loading: false });
    let isNpThyroid=false
    try {
      
      const { cookies } = this.props;
     const hasCookie= cookies.get('referralNP');
     isNpThyroid=!!hasCookie
    } catch (error) {
      
    }
    try {
      const { data } = await this.props.client.mutate({
        mutation: SIGNUP,
        variables: {
          ...variables,
          isNPThyroid: isNpThyroid
        },
      });

      localStorage.setItem('token', data.signup.token);
      const aa = await this.props.client.query({
        query: GET_ANALYTICS_ID,
        fetchPolicy: 'network-only',
      });
      let age = 0;
      try {
        age = moment().diff(moment(variables.dob, 'MM/DD/YYYY'), 'years');
      } catch (error) {}
      if (aa && aa.data && aa.data.getAnalyticsId) {
        analytics.Alias(aa.data.getAnalyticsId);
        analytics.Identify(aa.data.getAnalyticsId, {
          age,
          gender: variables.gender,
          // city: variables.city,
          // zip: variables.zip,
          state: variables.state,
          departmentId: (data.signup.user || {}).departmentId,
          diagnosed: variables.diagnosed,
        });
      }
      analytics.track('Account Created');
      if (this.state.newInsurance) {
        await this.sendInsuranceInfo(data.signup.user.id);
      }

      if (!!this.state.quadPaySubscribedData) {
        await this.props.client.mutate({
          mutation: QUADPAY_SUBSCRIBED,
          variables: {
            ...this.state.quadPaySubscribedData,
          },
          refetchQueries: [{ query: CURRENT_USER }],
        });

        this.props.analytics.track('QuadPay - Membership', {
          orderReference: (this.state.quadPaySubscribedData || {}).quadpayOrderReference,
        });
      }
      this.addCC(wizard);
    } catch (error) {
      console.log('handleCompleteProfile', error);
      this.setState({ loading: false, isSubmitting: false });

      actions.setSubmitting(false);

      error.graphQLErrors.forEach((error) => {
        toastManager.add(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
    }
  };

  generateInitiateConversationVariables = (
    appointmentDate,
    planType,
    patientState,
    doctor,
    email,
    // appointmentId,
    thread,
    subscriberFullName,
    subscriberDob,
    conversationId
  ) => {
    return {
      planType,
      thread: {
        ...thread,
      },
      state: patientState,
      doctor,
      email,
      // appointmentId,
      appointmentDate,
      subscriberFullName,
      subscriberDob,
      conversationId,
    };
  };

  sendInsuranceInfo = async (patientId) => {
    // console.log('STATE sendInsuranceInfo', this.state);
    const appointmentDate = moment(`${this.state.selectedAppointment.slot.date} ${this.state.selectedAppointment.slot.startTime}`, 'MM/DD/YYYY HH:mm').format('MM-DD-YYYY hh:mma');
    const preVars = {
      cardFront: this.state.newInsurance.cardFront,
      cardBack: this.state.newInsurance.cardBack,
      planType: this.state.newInsurance.planType,
      provider: (this.state.selectedProvider || {}).value,
      subscriberFullName: this.state.newInsurance.primarySubscriberFullName,
      subscriberDob: this.state.newInsurance.dob,
      patientId,
      patientState: this.state.department.value, //id
      patientEmail: this.state.values.email,
      email: this.state.values.email,
      doctor: `${this.state.selectedAppointment.doctor.firstName} ${this.state.selectedAppointment.doctor.lastName}`,
      consultationDate: appointmentDate,
      conversationId: this.state.newInsurance.conversationId,
    };

    const attachments = [];

    if (this.state.newInsurance.cardFront) {
      attachments.push({
        ...this.state.newInsurance.cardFront,
        data: this.state.newInsurance.cardFront.data.substr(this.state.newInsurance.cardFront.data.indexOf(',') + 1),
      });
    }

    if (this.state.newInsurance.cardBack) {
      attachments.push({
        ...this.state.newInsurance.cardBack,
        data: this.state.newInsurance.cardBack.data.substr(this.state.newInsurance.cardBack.data.indexOf(',') + 1),
      });
    }
    let thread = {
      text: `Insurance information ${attachments.length > 0 ? 'following:' : 'not sent'}`,
      attachments: attachments,
    };

    const vars = this.generateInitiateConversationVariables(
      preVars.consultationDate,
      `${preVars.provider} - ${preVars.planType}`,
      preVars.patientState,
      preVars.doctor,
      preVars.patientEmail,
      thread,
      preVars.subscriberFullName,
      preVars.subscriberDob,
      preVars.conversationId
    );

    try {
      await this.props.client.mutate({
        mutation: SEND_INSURANCE,
        variables: {
          ...vars,
          email: this.state.values.email,
        },
      });
      // console.log('send insurance res', res);
    } catch (error) {
      console.log(error);
    }
  };

  setQuadPayData = (accountNumber, cvc, expMonth, expYear, quadpayOrderReference) => {
    this.setState(
      {
        quadPaySubscribedData: {
          accountNumber,
          cvc,
          expMonth,
          expYear,
          quadpayOrderReference,
        },
      },
      () => {
        this.wizard.current.next();
      }
    );
  };

  addCC = async (wizard) => {
    const { storedCard, addMembership, quadPaySubscribedData } = this.state;

    try {
      const res = await this.props.client.mutate({
        mutation: UPDATE_ATHENA_CARD,
        variables: {
          accountNumber: storedCard.accountNumber,
          cvc: storedCard.cvc,
          expMonth: storedCard.expMonth.length < 2 ? `0${storedCard.expMonth}` : storedCard.expMonth,
          expYear: storedCard.expYear,
          zip: storedCard.zip || null,
          street1: storedCard.street1 || null,
          addMembership: !!addMembership && !quadPaySubscribedData ? addMembership : null,
        },
        refetchQueries: [{ query: ATHENA_CARD }],
      });

      if (res && ((res.data && res.data.updateAthenaCard && res.data.updateAthenaCard.ok) || res.ok)) {
        // if (!!quadPaySubscribedData) {
        //   await this.props.client.mutate({
        //     mutation: QUADPAY_SUBSCRIBED,
        //     variables: {
        //       ...quadPaySubscribedData,
        //     },
        //   });
        //   this.props.analytics.track('QuadPay - Membership', {
        //     orderReference: quadPaySubscribedData.quadpayOrderReference,
        //   });
        // }
        this.props.analytics.track('Card Added');
        this.handleConfirm(wizard);
      } else {
        // console.log('addCC complete err', res);

        // this.props.toastManager.add('Invalid Credit Card.', {
        //   appearance: 'error',
        // });
        this.setState({ couldntConfirmCard: true }, () => this.handleConfirm(wizard));
      }
    } catch (error) {
      this.setState({ couldntConfirmCard: true }, () => this.handleConfirm(wizard));
      // this.props.toastManager.add(
      //   `Couldn't complete the booking. Please try again`,
      //   {
      //     appearance: 'error',
      //   }
      // );

      // console.log(error);
      // this.props.history.push('/consultations');
    }
  };

  handleConfirm = async (wizard) => {
    // console.log('handleConfirm');
    const { selectedProvider, insuranceInfo, coverage, athenaPackageId, selectedAppointment, values } = this.state;

    const { toastManager } = this.props;

    let session;
    try {
      const currentUserResponse = await this.props.client.query({
        query: CURRENT_USER,
      });
      session = currentUserResponse.data.currentUser;
    } catch (error) {
      console.log('handleConfirm curr user err', error);
    }

    let athenaInsuranceId;

    if (insuranceInfo) {
      if (athenaPackageId) {
        const insurancePackageId = parseInt(athenaPackageId, 10);
        try {
          const res = await this.props.client.mutate({
            mutation: ADD_INSURANCE,
            variables: {
              patientId: null,
              insurancePackageId,
              insuranceIdNumber: insuranceInfo.memberId,
              // $expirationDate: String
              insurancePolicyHolderFirstname: insuranceInfo.primaryInsured ? values.firstName : insuranceInfo.primary_firstName,
              insurancePolicyHolderlastname: insuranceInfo.primaryInsured ? values.lastName : insuranceInfo.primary_lastName,
              insurancePolicyHolderstate: insuranceInfo.primaryInsured ? values.state : insuranceInfo.primary_state,
              relationshipToInsuredid: insuranceInfo.relationship ? insuranceInfo.relationship.athenaId || 1 : 1,
              insurancePolicyHolderaddress1: insuranceInfo.primaryInsured ? values.street1 : insuranceInfo.primary_street1,
              insurancePolicyHolderaddress2: insuranceInfo.primaryInsured ? '' : insuranceInfo.primary_street2,
              insurancePolicyHoldercity: insuranceInfo.primaryInsured ? values.city : insuranceInfo.primary_city,
              insurancePolicyHolderzip: insuranceInfo.primaryInsured ? values.zip : insuranceInfo.primary_zip,
              insurancepolicyHolderSex: insuranceInfo.primaryInsured ? values.gender || 'F' : insuranceInfo.primary_sex.value,
              groupId: insuranceInfo.groupId,
              insuranceCompanyName: selectedProvider.value,
              copayAmount: this.state.costEstimates && this.state.costEstimates.costEstimate ? this.state.costEstimates.costEstimate : 0,
              copayType: 1,
              payerId: coverage.payerId,
            },
          });
          // console.log('res.data.setInsurance', res.data);
          athenaInsuranceId = parseInt(res.data.setInsurance.insuranceId, 10);
        } catch (error) {
          console.log('RES error', error);
        }
      }
    }

    let payload = {};

    var dateParts = selectedAppointment.slot.date.split('/');

    var st = dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1];
    const date2 = moment.tz(`${st} ${selectedAppointment.slot.startTime}`, session.department.timeZoneName);

    const date3 = moment.tz(`${st} ${selectedAppointment.slot.startTime}`, session.department.timeZoneName).add(30, 'minutes');

    const startDateUTC = date2.utc();
    const endDateUTC = date3.utc();

    const departmentId = session.department.departmentId;

    // console.log('reschedule', enforcePatientId);
    payload = {
      mutation: BOOK_APPT,
      variables: {
        id: selectedAppointment.slot.id.toString(),
        appointmentDetails: {
          startDate: startDateUTC.format('YYYY-MM-DD HH:mm'),
          endDate: endDateUTC.format('YYYY-MM-DD HH:mm'),
          subject: `Paloma ${values ? values.state + ' ' : ''} patient`,
          htmlMessage: '',
          inviteSubject: `Paloma ${values ? values.state + ' ' : ''} patient`,
          inviteDescription: '',
          inviteLocation: `<a href="https://app.palomahealth.com/visit-room/${selectedAppointment.slot.providerId}">${
            (selectedAppointment.doctor || '').zocdocVideoLink.indexOf('whereby.com') < 0 ? 'ZocDoc' : 'Whereby'
          } ${session.address ? session.address.state + ' ' : ''} ${session.department.timeZoneName}</a>`,
          // `Chiron ${values ? values.state + ' ' : ''} ${
          //   session.department.timeZoneName
          // }`,
          providerId: selectedAppointment.slot.providerId,
          departmentId: parseInt(session.department.departmentId, 10),
        },
      },
    };

    this.setState({
      loading: true,
      isConfirmOpen: false,
    });

    let refetchQueries = [];

    refetchQueries = [{ query: APPOINTMENTS }, { query: UPCOMING_APPOINTMENTS }, { query: CURRENT_USER }];
    try {
      let id = 0;
      if (athenaInsuranceId) {
        const book = await this.props.client.mutate({
          ...payload,
        });

        id = parseInt(book.data.bookAppointment.appointmentId.toString(), 10);

        await this.props.client.mutate({
          mutation: ASSIGN_INSURANCE_TO_APPOINTMENT,
          variables: {
            appointmentId: id,
            insuranceId: athenaInsuranceId,
          },
          refetchQueries,
          awaitRefetchQueries: true,
        });
      } else {
        const book = await this.props.client.mutate({
          ...payload,
          refetchQueries,
          awaitRefetchQueries: true,
        });
        id = parseInt(book.data.bookAppointment.appointmentId.toString(), 10);
      }
      try {
        if (this.state.addMembership) {
          const ft_membership_price_2024 = isToggleActive('MEMBERSHIP_PRICE_2024');
          this.props.analytics.track('membership sign up', {
            frequency: this.state.addMembership === 'mi' ? 'mi' : 'ma',
            content_ids: this.state.addMembership === 'mi' ? 'mi' : 'ma',
            value: this.state.addMembership === 'mi' ? '96.00' : `${!ft_membership_price_2024 ? Constants.MEMBERSHIP_PRICE : Constants.MEMBERSHIP_PRICE_2024}.00`,
            trial: false,
          });
        }
      } catch (error) {}
      // console.log('handleConfirm mutate');
      try {
        const hasFeature = isToggleActive('USE_NEW_PRICING');
        const apptTypeID = hasFeature ? window._env_.REACT_APP_ATHENA_NEWVISIT30_APPOINTMENT_TYPE_ID : window._env_.REACT_APP_ATHENA_CONSULT30_APPOINTMENT_TYPE_ID;

        const opts = {
          providerId: selectedAppointment.slot.providerId,
          doctorName: `${(selectedAppointment.doctor || '').firstName} ${(selectedAppointment.doctor || '').lastName}`,
          departmentId: departmentId.toString(),
          startDate: startDateUTC.format('YYYY-MM-DD HH:mm'),
          endDate: endDateUTC.format('YYYY-MM-DD HH:mm'),
          cost: this.state.addMembership || !!this.state.quadPaySubscribedData ? `$${getMemberVisitPrice()}.00` : hasFeature ? '$110.00' : '$99.00',
          copay: athenaInsuranceId && this.state.costEstimates ? `$${(this.state.costEstimates.costEstimate || 0) * 100}` : '',
          appointmentTypeId: apptTypeID,
          appointmentId: (id || 0).toString(),
        };
        this.props.analytics.track('Appointment Booked', { ...opts });
        // const hasFeature = isToggleActive('USE_NEW_PRICING');
        // const apptTypeID = hasFeature?window._env_.REACT_APP_ATHENA_NEWVISIT30_APPOINTMENT_TYPE_ID:window._env_.REACT_APP_ATHENA_CONSULT30_APPOINTMENT_TYPE_ID

        this.props.analytics.logRevenue(
          this.state.addMembership || !!this.state.quadPaySubscribedData ? getMemberVisitPriceNumber() : hasFeature ? 110 : 99,
          this.state.addMembership || !!this.state.quadPaySubscribedData ? getMemberVisitPriceNumber() : hasFeature ? 110 : 99,
          0,
          '',
          [
            {
              productId: 'Appointment',
              name: 'Appointment',
              price: this.state.addMembership || !!this.state.quadPaySubscribedData ? getMemberVisitPriceNumber() : hasFeature ? 110 : 99,
              quantity: 1,
              category: 'Appointment',
              departmentId: departmentId.toString(),
              startDate: startDateUTC.format('YYYY-MM-DD HH:mm'),
              providerId: selectedAppointment.slot.providerId,
              doctorName: `${(selectedAppointment.doctor || '').firstName} ${(selectedAppointment.doctor || '').lastName}`,
              appointmentTypeId: apptTypeID,
              appointmentId: (id || 0).toString(),
            },
          ],
          uuidv4()
        );
      } catch (error) {
        console.log('cannot send segment', error);
      }
      this.setState(
        {
          slot: selectedAppointment.slot,
          isConfirm: true,
          isSubmitting: false,
        },
        () => {
          this.props.history.push({
            pathname: '/schedule-complete',
            state: {
              couldntConfirmCard: this.state.couldntConfirmCard,
              // selectedAppointment: this.state.selectedAppointment,
              date: this.state.selectedAppointment.slot.date,
              startTime: this.state.selectedAppointment.slot.startTime,
              prompt: true,
            },
          });
          // wizard.push('complete');
        }
      );
    } catch (error) {
      this.setState({
        isSubmitting: false,
      });
      console.log('ERROR BKG', error);
      toastManager.add('Error booking appointment', { appearance: 'error' });
      this.props.history.push('/consultations');
    }
  };

  handleClose = () => {
    const { isConfirmOpen } = this.state;

    this.setState({
      isConfirmOpen: !isConfirmOpen,
      slot: {},
    });
  };

  onProviderSelected = (provider, wizard) => {
    this.setState(
      {
        isInNetworkProviderSelected: true,
        selectedProvider: provider,
        insuranceInfo: undefined,
      },
      () => {
        this.wizard.current.push('insuranceBenefitsCheck');
      }
    );
  };

  onNonSupportedProviderSelected = (provider, wizard) => {
    this.setState(
      {
        isInNetworkProviderSelected: false,
        selectedProviderNonSupported: provider,
        insuranceInfo: undefined,
      },
      () => {
        this.wizard.current.push('insuranceBenefitsCheck');
      }
    );
  };

  onSubmitInsuranceInfo = (values, wizard) => {
    this.setState(
      {
        insuranceInfo: {
          ...values,
        },
      },
      () => {
        this.nextStep(wizard);
      }
    );
  };

  onCoverageChecked = (coverage, athenaPackageId, wizard) => {
    this.setState(
      {
        coverage,
        athenaPackageId,
      },
      this.nextStep(wizard)
    );
  };

  onAppointmentSelection = (doctor, slot, isInNetwork, wizard, hasInNetwork) => {
    this.setState(
      {
        selectedAppointment: {
          doctor,
          slot,
          isInNetwork,
          hasInNetwork,
        },
      },
      () => this.nextStep(wizard)
    );
  };

  nextStep = (wizard) => {
    this.wizard.current.next();
  };

  previousStep = (wizard) => {
    // console.log({wizard, cur: this.wizard.current})
    if (!!wizard) {
      wizard.previous();
    } else {
      this.wizard.current.previous();
    }
  };

  reinitFlow = (wizard) => {
    this.setState(
      {
        selectedProvider: null,
        selectedProviderNonSupported: null,
        selectedAppointment: null,
        coverage: null,
      },
      () => {
        this.wizard.current.replace('pickProvider');
      }
    );
  };

  bypassInsurance = (wizard) => {
    this.setState({
      selectedProvider: null,
      selectedProviderNonSupported: null,
      onFileInsurance: {
        ...this.state.onFileInsurance,
        use: false,
      },
    });
    const hasFeature = isToggleActive('MEMBERSHIP');
    this.wizard.current.push('pickDoctor');
  };

  bypassInsuranceKeepProvider = (wizard) => {
    this.setState({
      selectedProviderNonSupported: null,
      onFileInsurance: {
        ...this.state.onFileInsurance,
        use: false,
      },
    });
    const hasFeature = isToggleActive('MEMBERSHIP');
    this.wizard.current.push('pickDoctor');
  };

  jumpToStep = (step, wizard) => {
    switch (step) {
      case 0:
        wizard.push('pickProvider');
        break;
      case 1:
        wizard.push('pickDoctor');
        break;
      default:
        break;
    }
  };

  reinitData = () => {
    this.setState({
      selectedProvider: null,
      selectedProviderNonSupported: null,
      selectedAppointment: null,
      coverage: null,
    });
  };

  setOnFileInsurance = (onFileInsurance, use) => {
    this.setState({
      onFileInsurance: {
        ...onFileInsurance,
        use,
      },
    });
  };

  useOnFileInsurance = (onFileInsurance, wizard) => {
    this.setState(
      {
        onFileInsurance: {
          ...onFileInsurance,
          use: true,
        },
      },
      () => {
        this.wizard.current.push('insuranceBenefitsCheck');
      }
    );
  };

  setCostEstimates = (costEstimates) => {
    this.setState({
      costEstimates,
    });
  };

  setProfile = (values, actions, wizard) => {
    this.setState(
      {
        profile: { ...values },
      },
      () => {
        this.nextStep(wizard);
      }
    );
  };

  storeCard = (values) => {
    this.setState({
      storedCard: {
        ...values,
      },
    });
  };
  onNonServiceStateSelected = (wizard) => {
    this.props.history.push({
      pathname: '/buykit',
      state: {
        patientState: this.state.department,
      },
    });
    // this.wizard.current.push('nonServiced');
  };

  createSubscription = async (frequency) => {
    let priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_MONTHLY;
    let freq = frequency === 'annualy' ? 'ma' : frequency === 'insurance' ? 'mi' : 'mm';
    // let freq = frequency === 'annualy' ? 'ma' : 'mm';

    const ft_membership_price_2024 = isToggleActive('MEMBERSHIP_PRICE_2024');
    if (frequency === 'annualy') {
      if (ft_membership_price_2024) {
        priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL_2024;
      } else {
        priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL;
      }
    } else if (frequency === 'insurance') {
      priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_INSURANCE;
    } else {
      priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_MONTHLY;
    }

    const createSession = await this.props.client.mutate({
      mutation: MEMBERSHIP_CREATE_SESSION,
      variables: {
        priceId: priceId,
        forwardUrl: `subscribed/purchase/success/${freq}`,
        cancel_url: 'purchase',
      },
    });

    const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_KEY);
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({
      sessionId: createSession.data.createCheckoutSession.sessionId,
    });
  };

  render() {
    const {
      isConfirmOpen,
      slot,
      selectedAppointment,
      selectedProvider,
      insuranceInfo,
      selectedProviderNonSupported,
      coverage,
      athenaPackageId,
      onFileInsurance,
      isConfirm,
      reschedulingAppointment,
      locationState,
      impersonatedPatient,
      profile,
      isBuyAKit,
      department,
      couldntConfirmCard,
      isSubmitting,
      linkDepartment,
      isNonServicedState,
      newInsurance,
    } = this.state;

    if (isNonServicedState) {
      return (
        <Container>
          <InnerContainer>
            <NoServiceState patientState={department} />
          </InnerContainer>
        </Container>
      );
    }

    const { isAdmin, history } = this.props;
    const t = isToggleActive('INSURANCE_MEMBERSHIP_WIND_DOWN');
    const prompt = true;

    const enforcePatientId = isAdmin && locationState ? locationState.patientId : null;

    const stepsVal = [
      {
        comp: (
          <Step id="pickProvider">
            {/* <div>A</div> */}
            <Container>
              <InnerContainer>
                <PickInsuranceProvider
                  onStateSelected={(val) => {
                    this.setState({
                      department: getRegionByAbbreviation(val),
                    });
                  }}
                  nonServiceStateSelected={this.onNonServiceStateSelected}
                  profile={profile}
                  isNewPatientFlow={true}
                  reinitFlow={this.reinitFlow}
                  reinitData={this.reinitData}
                  bypassInsurance={this.bypassInsurance}
                  selectedProvider={this.state.selectedProvider}
                  onProviderSelected={this.onProviderSelected}
                  setOnFileInsurance={this.setOnFileInsurance}
                  useOnFileInsurance={this.useOnFileInsurance}
                  notDetected={(wizard) => this.wizard.current.push('buyakit')}
                  onNonSupportedProviderSelected={this.onNonSupportedProviderSelected}
                  isAdmin={isAdmin}
                  enforcePatientId={enforcePatientId}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'pickProvider',
        display: !linkDepartment,
      },
      {
        comp: (
          <Step id="insuranceBenefitsCheck">
            <Container>
              <InnerContainer>
                <InsuranceBenefitsCheckUnregistered
                  departmentInitials={this.state.department ? this.state.department.value : ''}
                  setInsuranceCard={this.setInsuranceCard}
                  departmentId
                  selectedProviderNonSupported={selectedProviderNonSupported}
                  reinitFlow={this.reinitFlow}
                  selectedProvider={selectedProvider}
                  enforceDept={this.state.department}
                  reschedulingAppointment={reschedulingAppointment}
                  onAppointmentSelection={this.onAppointmentSelection}
                  isAdmin={isAdmin}
                  enforcePatientId={enforcePatientId}
                  impersonatedPatient={impersonatedPatient}
                  bypassInsurance={this.bypassInsurance}
                  bypassInsuranceKeepProvider={this.bypassInsuranceKeepProvider}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'insuranceBenefitsCheck',
        display: !linkDepartment,
      },
      {
        comp: (
          <Step id="pickDoctor">
            <div
              style={{
                height: 'calc(100% - 80px)',
                borderTop: '1px solid #ccc',
              }}
            >
              <PickDoctorNewPatient
                {...this.props}
                containerStyle={{
                  height: '100%',
                }}
                selectedInsurance={
                  onFileInsurance && onFileInsurance.use && onFileInsurance.insuranceCompany ? onFileInsurance.insuranceCompany : this.state.selectedProvider ? this.state.selectedProvider.value : null
                }
                selectedProvider={selectedProvider}
                enforceDept={this.state.department}
                reschedulingAppointment={reschedulingAppointment}
                onAppointmentSelection={this.onAppointmentSelection}
                isAdmin={isAdmin}
                coverage={this.state.coverage}
                enforcePatientId={enforcePatientId}
                impersonatedPatient={impersonatedPatient}
              />
            </div>
          </Step>
        ),
        key: 'pickDoctor',
        display: true,
      },
      {
        comp: (
          <Step id="selectMembership">
            {/* <div>A</div> */}
            <Container>
              <InnerContainer>
                <SelectMembership
                  onGoBack={(wizard) => {
                    this.previousStep(wizard);
                  }}
                  hasInNetwork={this.state && this.state.selectedAppointment && this.state.selectedAppointment.hasInNetwork}
                  isInNetwork={this.state && this.state.selectedAppointment && this.state.selectedAppointment.isInNetwork}
                  insuranceAccepted={!!newInsurance}
                  onSubscribe={(wizard) => {
                    if (this.state && this.state.selectedAppointment && this.state.selectedAppointment.isInNetwork && this.state.selectedAppointment.hasInNetwork) {
                      this.setState({
                        addMembership: !t?'mi':'ma',
                      });
                      // this.heartbeat();
                      this.wizard.current.push('payment');
                    } else {
                      this.wizard.current.push('selectPlan');
                    }
                  }}
                  onContinue={(wizard) => {
                    this.setState({
                      addMembership: null,
                    });
                    this.wizard.current.push('payment');
                  }}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'selectMembership',
        display: isToggleActive('MEMBERSHIP'),
      },
      {
        comp: (
          <Step id="selectMemberships">
            {/* <div>A</div> */}
            <Container>
              <InnerContainer>
                <SelectMembership
                  onGoBack={(wizard) => {
                    this.previousStep(wizard);
                  }}
                  hasInNetwork={this.state && this.state.selectedAppointment && this.state.selectedAppointment.hasInNetwork}
                  isInNetwork={this.state && this.state.selectedAppointment && this.state.selectedAppointment.isInNetwork}
                  insuranceAccepted={!!newInsurance}
                  onSubscribe={(wizard) => {
                    if (this.state && this.state.selectedAppointment && this.state.selectedAppointment.isInNetwork && this.state.selectedAppointment.hasInNetwork) {
                      this.setState({
                        addMembership: !t?'mi':'ma',
                      });
                      // this.heartbeat();
                      this.wizard.current.push('payment');
                    } else {
                      this.wizard.current.push('selectPlan');
                    }
                  }}
                  onContinue={(wizard) => {
                    this.setState({
                      addMembership: null,
                    });
                    this.wizard.current.push('payment');
                  }}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'selectMemberships',
        display: isToggleActive('MEMBERSHIP'),
      },
      {
        comp: (
          <Step id="selectPlan">
            <Container>
              <InnerContainer>
                <SelectPlan
                  isInNetwork={this.state && this.state.selectedAppointment && this.state.selectedAppointment.isInNetwork}
                  reinitFlow={this.reinitFlow}
                  selectedProvider={this.state.selectedAppointment}
                  isNewPatientFlow={true}
                  setQuadPayData={this.setQuadPayData}
                  onSubscribe={(frequency) => {
                    this.setState({
                      addMembership: !frequency ? null : frequency === 'monthly' ? 'mm' : frequency === 'insurance' && !t ? 'mi' : 'ma',
                    });
                    // this.setState({
                    //   addMembership: !frequency
                    //     ? null
                    //     : frequency === 'monthly'
                    //       ? 'mm'
                    //       : 'ma',
                    // });
                    this.wizard.current.push('payment');
                    // this.createSubscription(frequency);
                  }}
                  onContinue={(wizard) => {
                    this.setState({
                      addMembership: null,
                    });
                    this.wizard.current.push('payment');
                  }}
                  continueWithQuadPay={() => {
                    this.wizard.current.next();
                  }}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'selectPlan',
        display: isToggleActive('MEMBERSHIP'),
      },
      {
        comp: (
          <Step id="welcomemembership" key="welcomemembership">
            <QuadpayComplete
              continueWithQuadPay={() => {
                this.wizard.current.push('payment');
              }}
            />
          </Step>
        ),
        key: 'welcomemembership',
        display: true,
      },
      {
        comp: (
          <Step id="payment">
            <Container>
              <InnerContainer>
                <SchedulePaymentUnregistered
                  newInsurance={newInsurance}
                  addMembership={this.state.addMembership}
                  isNewPatientFlow={true}
                  athenaPackageId={athenaPackageId}
                  storeCard={this.storeCard}
                  {...this.props}
                  handlePayment={this.handlePayment}
                  selectedAppointment={selectedAppointment}
                  coverage={coverage}
                  insuranceInfo={insuranceInfo}
                  selectedProvider={selectedProvider}
                  reinitFlow={this.reinitFlow}
                  setCostEstimates={this.setCostEstimates}
                  onFileInsurance={onFileInsurance}
                  isAdmin={isAdmin}
                  enforcePatientId={enforcePatientId}
                  impersonatedPatient={impersonatedPatient}
                  setQuadPayData={this.setQuadPayData}
                  quadPaySubscribedData={this.state.quadPaySubscribedData}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'payment',
        display: true,
      },
      {
        comp: (
          <Step id="uploadInsuranceCard" key="uploadInsuranceCard">
            <Container>
              <InnerContainer>
                <UploadInsuranceCard
                  onSubmit={(front, back) =>
                    this.setState(
                      {
                        newInsurance: {
                          ...this.state.newInsurance,
                          cardFront: front,
                          cardBack: back,
                        },
                      },
                      () => {
                        this.wizard.current.next();
                        // if (this.state.newInsurance) {
                        //   await this.sendInsuranceInfo(session.id);
                        // }
                      }
                    )
                  }
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'uploadInsuranceCard',
        display: true,
      },
      {
        comp: (
          <Step id="basic">
            <Container>
              <InnerContainer>
                <Basic
                  onSubmit={this.handleSubmit}
                  values={{
                    ...this.state.values,
                    email: this.state.newInsurance ? this.state.newInsurance.email : '',
                  }}
                  isNewPatientFlow
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'basic',
        display: true,
      },
      {
        comp: (
          <Step id="profile">
            <Container>
              <InnerContainer>
                <Profile isNewPatientFlow enforceDepartment={this.state.department} onSubmit={this.handleSubmit} values={this.state.values} />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'profile',
        display: true,
      },
      {
        comp: (
          <Step id="otp">
            <Container>
              <InnerContainer>
                <Otp
                  onSubmit={this.handleSubmit}
                  values={this.state.values}
                  changePhone={(phone, mutate) => {
                    this.setState({
                      values: { ...this.state.values, phone },
                    });
                  }}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'otp',
        display: isToggleActive('OTP_VERIFICATION'),
      },
      {
        comp: (
          <Step id="birthsex">
            <Container>
              <InnerContainer>
                <BirthSex onSubmit={this.handleSubmit} values={this.state.values} />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'basic',
        display: true,
      },
      {
        comp: (
          <Step id="legalsex">
            <Container>
              <InnerContainer>
                <LegalSex onSubmit={this.handleSubmit} values={this.state.values} />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'basic',
        display: true,
      },
      {
        comp: (
          <Step id="genderIdentity">
            <Container>
              <InnerContainer>
                <GenderIdentity onSubmit={this.handleSubmit} values={this.state.values} />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'basic',
        display: true,
      },
      {
        comp: (
          <Step id="pronouns">
            <Container>
              <InnerContainer>
                <PreferredPronouns onSubmit={this.handleSubmit} values={this.state.values} />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'basic',
        display: true,
      },
      {
        comp: (
          <Step id="extended">
            <Container>
              <InnerContainer>
                <Extended isNewPatientFlow onSubmit={this.handleCompleteProfile} values={this.state.values} />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'extended',
        display: true,
      },
      {
        comp: (
          <Step id="complete">
            <Container>
              <InnerContainer p={3}>
                <Complete
                  currentIntakeStatus={''}
                  couldntConfirmCard={couldntConfirmCard}
                  prompt={prompt}
                  slot={selectedAppointment ? selectedAppointment.slot : null}
                  reinitFlow={this.reinitFlow}
                  history={this.props.history}
                  showInsuranceDisclaimer={this.state.addMembership && this.state.addMembership === 'mi'}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'complete',
        display: true,
      },
      {
        comp: (
          <Step id="nonServiced">
            <Container>
              <InnerContainer>
                <NoServiceState patientState={department} />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'nonServiced',
        display: true,
      },
      {
        comp: (
          <Step id="buyakit">
            <>
              <img src={Logo} width="250px" alt="" style={{ margin: '40px auto 0' }} />
              <ByAKit patientState={department} history={history} />
            </>
          </Step>
        ),
        key: 'buyakit',
        display: true,
      },
    ];

    return (
      <Flex flex={1} flexDirection="column" style={{ height: '100vh' }} id="ttet">
        {isBuyAKit && (
          <>
            <img src={Logo} width="250px" alt="" style={{ margin: '40px auto 0' }} />
            <ByAKit history={history} />
          </>
        )}

        {!isBuyAKit && (
          <Wizard
            ref={this.wizard}
            history={history}
            basename="/book-appointment"
            render={({ step, ...p }, a) => {
              return (
                <Fragment>
                  {step.id !== 'buyakit' && (
                    <img
                      src={Logo}
                      width="250px"
                      alt=""
                      style={{
                        margin: step.id !== 'pickDoctor' ? '40px auto 0' : '0 auto',
                      }}
                    />
                  )}
                  {((step.id !== 'pickDoctor' && step.id !== 'selectMembership' && step.id !== 'nonServiced' && step.id !== 'buyakit' && step.id !== 'selectPlan' && step.id !== 'welcomemembership') ||
                    isConfirm) && (
                    <DesktopOnly>
                      <Stepper lockJump={isConfirm} wizard={p} jumpToStep={this.jumpToStep} currentStep={step.id} />
                    </DesktopOnly>
                  )}

                  {step.id !== 'complete' &&
                    step.id !== 'uploadInsuranceCard' &&
                    isConfirm && (
                      <Container>
                        <InnerContainer p={3}>
                          <Complete
                            currentIntakeStatus={''}
                            couldntConfirmCard={couldntConfirmCard}
                            prompt={prompt}
                            slot={selectedAppointment ? selectedAppointment.slot : null}
                            reinitFlow={this.reinitFlow}
                            history={this.props.history}
                          />
                        </InnerContainer>
                      </Container>
                    )}
                  {((step.id !== 'complete' && !isConfirm) || step.id === 'complete' || step.id === 'uploadInsuranceCard') && <Steps>{stepsVal.filter((x) => x.display).map((x) => x.comp)}</Steps>}
                  {/* {isMobile &&
                    step.id === 'selectMembership' && (
                      <MobileButton>
                        <Link
                          style={{ flex: 1, textDecoration: 'none' }}
                          to={{
                            pathname: '/register',
                            state: {
                              subscribe: true,
                              continueWith: '/schedule',
                            },
                          }}
                        >
                          <Box
                            style={{
                              background: '#354E79',
                              color: 'white',
                              width: '100%',
                              padding: '6px',
                              textAlign: 'center',
                            }}
                          >
                            Join Now
                          </Box>
                        </Link>
                      </MobileButton>
                    )} */}
                </Fragment>
              );
            }}
          />
        )}
        {isSubmitting ? (
          <div
            style={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              position: 'absolute',
              background: 'rgba(255,255,255,0.65)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              fontWeight: 500,
            }}
          >
            <SpinLoader />
            <br />
            Please Wait...
          </div>
        ) : null}
        <Fragment>
          <ConfirmModal
            isOpen={isConfirmOpen}
            onClose={this.handleClose}
            onConfirm={() => {
              this.setState({ step: 'payment', isConfirmOpen: false });
            }}
            onBackgroundClick={this.handleClose}
            onEscapeKeydown={this.handleClose}
          >
            <ModalInner>
              <Heading mb={4} mx={3} textAlign="center">
                Confirm Your Visit
              </Heading>
              <InfoBox bg="#D9E6F9" mb={4}>
                <Text fontSize={3} fontWeight={700} textAlign="center">
                  {`${moment(slot.startTime, 'HH:mm').format('h:mma')} on 
                ${moment(slot.date, 'MM/DD/YYYY').format('dddd MMMM Do')}`}
                </Text>
              </InfoBox>
            </ModalInner>
          </ConfirmModal>
        </Fragment>
        <FullLayout />
      </Flex>
    );
  }
}

export default compose(
  withCookies,
  withRouter,
  withApollo,
  withToastManager,
  withAnalytics
)(ScheduleNewPatient);
