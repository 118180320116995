import React from 'react';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import LoadingScreen from '../components/LoadingScreen';
import withAnalytics from '../lib/withAnalytics';

class referralRedirectBoost extends React.Component {
  constructor(props) {
    super(props);

    const { cookies } = props;
    if (this.props && this.props.match && this.props.match.params) {
      this.props.analytics.page('referralRedirectBoost', 'referral');
      const { coupon } = this.props.match.params;
      if (coupon) {
        cookies.set('referralCoupon', coupon, { path: '/', sameSite: false });
      }
    }
    // 
  }

  componentDidMount() {

    var script = document.createElement('script');
    script.async=true
    script.onload = function() {
      var script2 = document.createElement('script');
      
      script2.onload = function() {
      }
      script2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-131262403-1');`;
  
      document.head.appendChild(script2);

      setTimeout(() => {
        window.location='http://palomahealth.com/landing/boost';
      }, 300);
      //do stuff with the script
    };
    script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-131262403-1';

    document.head.appendChild(script);

  }
  render() {
    return <LoadingScreen />;
  }
}

export default withCookies(
  withRouter(withAnalytics(referralRedirectBoost))
);
