import React, { Fragment, useState } from 'react';
import { Flex, Box, Button, Heading, Text, Card } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, Query, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import vitaminsBottle from '../../../static/vitaminsBottle.png';
import palomaKit from '../../../static/palomaKit.png';
import withSession from '../../../lib/withSession';
import { useMount } from '../../../utils/hooks/lifecycle';
import { GET_MEMBERSHIPS } from '../../../graphql';
import PleaseWait from '../../../components/PleaseWait';
import { BoxButton } from '../../../components/async/SharedComponents';

const SelectedMarkerOut = styled(Flex)`
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #f6a293;
  transition: background 150ms ease-in-out;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  &.isChecked {
    background: #f6a293;
    border: 2px solid #f6a293;
  }
`;

export const AddonCard = ({ isSelected, onClick, title, description, discounted, price, image, containerStyle = {}, hidebox = false, showContinue = false }) => {
  return (
    <Card
      mt={4}
      onClick={onClick}
      style={{
        border: '1px solid rgba(53, 78, 121, 0.15)',
        borderRadius: '10px',
        padding: '20px',
        ...containerStyle,
      }}
    >
      <Flex flexDirection="row">
        <Flex>
          {!hidebox && (
            <SelectedMarkerOut mt="3px" className={isSelected ? 'isChecked' : ''}>
              {isSelected && (
                <svg width="42px" height="42px" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.0866 6.84591C14.2627 6.67827 14.497 6.58559 14.7401 6.58743C14.9832 6.58927 15.2161 6.68548 15.3897 6.85577C15.5632 7.02605 15.6638 7.25709 15.6702 7.50012C15.6766 7.74316 15.5884 7.97919 15.4241 8.15841L10.4366 14.3959C10.3508 14.4883 10.2473 14.5624 10.1323 14.6139C10.0172 14.6653 9.89294 14.693 9.76691 14.6954C9.64089 14.6977 9.51569 14.6746 9.39879 14.6274C9.2819 14.5803 9.17572 14.51 9.0866 14.4209L5.7791 11.1134C5.68699 11.0276 5.61311 10.9241 5.56187 10.8091C5.51063 10.6941 5.48308 10.5699 5.48086 10.4441C5.47864 10.3182 5.50179 10.1931 5.54895 10.0764C5.5961 9.95967 5.66628 9.85363 5.7553 9.76461C5.84432 9.67558 5.95037 9.6054 6.0671 9.55825C6.18384 9.5111 6.30887 9.48795 6.43475 9.49017C6.56063 9.49239 6.68477 9.51994 6.79977 9.57118C6.91477 9.62242 7.01827 9.6963 7.1041 9.78841L9.7216 12.4047L14.0628 6.87341C14.0707 6.86378 14.079 6.8546 14.0878 6.84591H14.0866Z"
                    fill="white"
                  />
                </svg>
              )}
            </SelectedMarkerOut>
          )}
        </Flex>

        <Flex flexDirection="column" flex={1}>
          <Flex flexDirection="row">
            <Flex flexDirection="column" flex={1}>
              <Text fontWeight="600" fontSize="16px" lineHeight="27px" mb="10px">
                {title}
              </Text>
              <Text fontWeight="300" fontSize="14px" lineHeight="20px" mb="10px">
                {description}
              </Text>
            </Flex>
            <img src={image} alt="" height="93px" />
          </Flex>
          <Flex>
            <Text fontWeight="600" fontSize="14px" lineHeight="20px" mb="10px">
              {price}
            </Text>
            <Text fontWeight="300" fontSize="14px" lineHeight="20px" mb="10px">
              &nbsp;
              {`${' '}${discounted}`}
            </Text>
          </Flex>
          {!!showContinue && (
            <Button variant={'primary'} width={'auto'} mt={2} mb={'10px'}>
              Continue
            </Button>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

const OtherCustomerFavourites = ({ onSubmit, onLearnMore, session: { isMember }, getState = () => {} }) => {
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [finalIsMember, setFinalIsMember] = useState(isMember);
  useMount(() => {
    const state = getState();
    // console.log({state})
    if (state && state.upsellSelection) {
      setSelectedAddons([...state.upsellSelection]);

      for (let index = 0; index < state.upsellSelection.length; index++) {
        const element = state.upsellSelection[index];
        // console.log({element})
        if (element === 'MEMBERSHIP' || element.id === 'MEMBERSHIP') {
          setFinalIsMember(true);
        }
      }
    }
  });
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }} pb="60px">
        <Box m={2} mt="0" mb={3}>
          <Heading textAlign="left">Other customer favorites for a healthy thyroid</Heading>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Text textAlign="left" fontSize="16px" fontWeight="300" lineHeight="24px">
            Pick what you’d like to add to your order to keep your thyroid healthy
          </Text>
        </Box>
        <Flex flexDirection="column">
          <Query query={GET_MEMBERSHIPS}>
            {({ loading, data, error }) => {
              if (loading) return <PleaseWait style={{ position: 'absolute', left: '50%', top: '50%' }} />;

              const vitaminsData = data.getMemberships.memberships.find((x) => x.subscriptionTarget === 'SUBSCRIPTION_VITAMINS');

              return (
                <>
                  {!vitaminsData ? (
                    <>
                      <AddonCard
                        title="Daily Thyroid Care"
                        description="Vitamin Support to support thyroid health"
                        price={!finalIsMember ? '$34/month' : '$27/month'}
                        discounted={!finalIsMember ? '' : ' ($7 member discount applied)'}
                        image={vitaminsBottle}
                        isSelected={JSON.parse(JSON.stringify(selectedAddons || [])).filter((x) => x === 'vitamins').length > 0}
                        onClick={() => {
                          const ads = JSON.parse(JSON.stringify(selectedAddons));
                          if (ads.filter((x) => x === 'vitamins').length > 0) {
                            const others = ads.filter((x) => x !== 'vitamins');
                            setSelectedAddons(others);
                          } else {
                            ads.push('vitamins');
                            setSelectedAddons(ads);
                          }
                        }}
                      />
                      <Text
                        fontWeight="300"
                        fontSize="12px"
                        style={{
                          textDecoration: 'underline',
                          textTransform: 'uppercase',
                          cursor: 'pointer',
                          textAlign: 'right',
                          marginTop: '8px',
                        }}
                        onClick={() => onLearnMore('vitamins')}
                      >
                        LEARN MORE
                      </Text>
                    </>
                  ) : null}
                  <AddonCard
                    title="At-Home Thyroid Test Kit"
                    description="Test your TSH, free T4, Free T3 and TPO antibodies from the comfort of your own home"
                    price={!finalIsMember ? '$99' : '$75'}
                    discounted={!finalIsMember ? '' : ' ($24 member discount applied)'}
                    image={palomaKit}
                    isSelected={JSON.parse(JSON.stringify(selectedAddons || [])).filter((x) => x === 'kits').length > 0}
                    onClick={() => {
                      const ads = JSON.parse(JSON.stringify(selectedAddons));
                      if (ads.filter((x) => x === 'kits').length > 0) {
                        const others = ads.filter((x) => x !== 'kits');
                        setSelectedAddons(others);
                      } else {
                        ads.push('kits');
                        setSelectedAddons(ads);
                      }
                    }}
                  />
                  <Text
                    fontWeight="300"
                    fontSize="12px"
                    mb={4}
                    style={{
                      textDecoration: 'underline',
                      textTransform: 'uppercase',
                      cursor: 'pointer',
                      textAlign: 'right',
                      marginTop: '8px',
                    }}
                    onClick={() => onLearnMore('athomekit')}
                  >
                    LEARN MORE
                  </Text>
                  {selectedAddons.length > 0 ? (
                    <BoxButton className={'visible'}>
                      <Button
                        variant="primary"
                        onClick={() => {
                          onSubmit(selectedAddons);
                        }}
                        width={[1, 1 / 2, 1 / 4]}
                        mt={4}
                        alignSelf="center"
                        style={{
                          marginTop: '0px',
                        }}
                      >
                        Continue
                      </Button>
                    </BoxButton>
                  ) : (
                    <BoxButton className={'visible'}>
                      <Button
                        variant="white"
                        style={{
                          background: '#FFF2F2',
                          border: '2px solid #F6A293',
                          borderRadius: '20px',
                          marginTop: '0px',
                        }}
                        onClick={() => {
                          onSubmit(selectedAddons);
                        }}
                        width={[1, 1 / 2, 1 / 4]}
                        mt={4}
                        alignSelf="center"
                      >
                        Skip
                      </Button>
                    </BoxButton>
                  )}
                </>
              );
            }}
          </Query>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default compose(
  withSession,
  withApollo,
  withWizard,
  withToastManager
)(OtherCustomerFavourites);
