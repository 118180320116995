import React, { useState } from 'react';
import { withWizard } from 'react-albus';
import { withRouter } from 'react-router';
import { Button, Card, Heading, Text, Flex } from 'rebass';
import styled from 'styled-components';
import withAnalytics from '../../../../lib/withAnalytics';
import smileySad from '../../../../static/smileySad.png';
import { CheckRadio } from '../../components/CheckRadio';

const A = styled(Text)`
  text-decoration: underline;
  margin-top: 28px;
  text-align: center;
  cursor: pointer;
`;

export const rs = [
  {
    label: `I don't have hypothyroidism`,
    isChecked: false,
  },
  {
    label: `Can't afford it anymore`,
    isChecked: false,
  },
  {
    label: `Going for another company`,
    isChecked: false,
  },
  {
    label: `Don’t like the service`,
    isChecked: false,
  },
  {
    label: `I am not feeling better`,
    isChecked: false,
  },
  {
    label: `Poor experience with customer service`,
    isChecked: false,
  },
  {
    label: `No providers available in my state`,
    isChecked: false,
  },
  {
    label: `Poor experience with the Paloma Doctor`,
    isChecked: false,
  },
  {
    label: `Going back to in person visits`,
    isChecked: false,
  },
  {
    label: `I feel good`,
    isChecked: false,
  },
  {
    label: `Other`,
    isChecked: false,
  },
];

const Reason = ({ confirm, wizard, viewOffers, hasOffers, history, analytics, interval, flow }) => {
  const [reasons, setReasons] = useState(rs);
  const hasSelected = reasons.filter((x) => x.isChecked).length > 0;
console.log({flow})
  return (
    <Flex flexDirection="column">
      <Card
        style={{
          flexDirection: 'column',
          textAlign: 'center',
          padding: '32px',
        }}
      >
        <Heading>Help us Understand The Reason</Heading>

        <Flex justifyContent="center" mb={4} mt={4}>
          <Text textAlign="center">We are sorry to see you go!</Text>

          <img src={smileySad} style={{ marginLeft: '12px' }} alt="" />
        </Flex>
        <Flex
          flexDirection="column"
          style={{
            margin: '0 auto',
            display: 'table',
          }}
        >
          {reasons.map((x, ind) => (
            <CheckRadio
              isChecked={x.isChecked}
              key={x.label}
              onClick={() => {
                const r = JSON.parse(JSON.stringify(reasons));
                for (let index = 0; index < r.length; index++) {
                  r[index].isChecked = false;
                }
                r[ind].isChecked = !x.isChecked;
                setReasons(r);

                if (!!flow && flow !== 'NAM' && flow !== 'LEGACY') {
                  viewOffers(wizard, (x || {}).label);
                }
              }}
            >
              {x.label}
            </CheckRadio>
          ))}
        </Flex>
        {hasSelected &&(!flow || flow === 'LEGACY') && (
          <>
            {hasOffers && (
              <Button
                style={{ marginTop: '28px', width: 'auto' }}
                variant="outline"
                onClick={() => {
                  const reason = reasons.find((x) => !!x.isChecked);
                  viewOffers(wizard, (reason || {}).label);
                }}
              >
                See Our Exclusive Offers
              </Button>
            )}
            <A
              onClick={() => {
                analytics.track('membership cancel', {
                  subscriptionType: interval === 'year' ? 'ma' : 'mm',
                  reasons: (JSON.parse(JSON.stringify(reasons)) || [])
                    .filter((x) => x.isChecked)
                    .map((x) => x.label)
                    .join(', '),
                });
                confirm(wizard);
              }}
            >
              Cancel Subscription
            </A>
          </>
        )}
        {!!flow &&
          flow !== 'NAM' &&
          flow !== 'LEGACY' &&
          hasSelected && (
            <>
              <Button
                style={{ marginTop: '28px', width: 'auto' }}
                variant="outline"
                onClick={() => {
                  const reason = reasons.find((x) => !!x.isChecked);
                  viewOffers(wizard, (reason || {}).label);
                }}
              >
                See Our Exclusive Offers
              </Button>
              <A
                onClick={() => {
                  analytics.track('membership cancel', {
                    subscriptionType: interval === 'year' ? 'ma' : 'mm',
                    reasons: (JSON.parse(JSON.stringify(reasons)) || [])
                      .filter((x) => x.isChecked)
                      .map((x) => x.label)
                      .join(', '),
                  });
                  confirm(wizard);
                }}
              >
                Cancel Subscription
              </A>
            </>
          )}
      </Card>
      <A
        onClick={() => {
          history.goBack();
        }}
      >
        Go Back
      </A>
    </Flex>
  );
};

export default withAnalytics(withRouter(withWizard(Reason)));
