import React from 'react';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import { Query, Mutation, withApollo } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';
import Select from 'react-select';
import {
  GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES,
  ADD_SYMPTOM_TRACKER_CATEGORY,
  GET_MOBILE_SYMPTOM_TRACKERS,
  ADD_SYMPTOM_TRACKER_LABELS_GROUP,
  GET_SYMPTOM_TRACKER_LABELS_GROUPS,
  ADD_SYMPTOM_TRACKER_MARKER,
  DELETE_SYMPTOM_TRACKER_MARKER,
} from '../graphql';
import ConfirmModal from '../components/ConfirmModal';
import { Input } from '../components/TextInput';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const categoriesColumns = [
  {
    Header: 'name',
    accessor: 'name',
  },
  {
    Header: 'description',
    accessor: 'description',
  },
  {
    Header: 'is active',
    accessor: 'isActive',
  },
  {
    Header: 'color',
    accessor: 'color',
    Cell: row => {
      console.log('ROW', row);
      return (
        <div
          style={{
            backgroundColor: row.value,
            border: '1px solid black',
            width: '24px',
            height: '24px',
            borderRadius: '12px',
            margin: '0 auto',
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      );
    },
  },
];

const labelsColumns = [
  {
    Header: 'labels',
    accessor: 'labels',
    Cell: row => {
      return (
        <div>
          {row.original.labels
            .sort((a, b) => {
              if (a.value < b.value) return -1;
              if (a.value > b.value) return 1;

              return 0;
            })
            .map(x => `${x.value}% ${x.label}`)
            .join(' - ')}
        </div>
      );
    },
  },
];

class LabelsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topPct0: null,
      label0: '',
      topPct1: null,
      label1: '',
      topPct2: null,
      label2: '',
      topPct3: null,
      label3: '',
      topPct4: null,
      label4: '',
      topPct5: null,
      label5: '',
    };
  }
  render() {
    const { onClose, onSave } = this.props;
    const {
      topPct0,
      label0,
      topPct1,
      label1,
      topPct2,
      label2,
      topPct3,
      label3,
      topPct4,
      label4,
      topPct5,
      label5,
    } = this.state;
    // const isValid = this.validate();
    const vars = [];
    if (topPct0 && label0) {
      vars.push({
        label: label0,
        value: parseInt(topPct0, 10),
      });
    }
    if (topPct1 && label1) {
      vars.push({
        label: label1,
        value: parseInt(topPct1, 10),
      });
    }
    if (topPct2 && label2) {
      vars.push({
        label: label2,
        value: parseInt(topPct2, 10),
      });
    }
    if (topPct3 && label3) {
      vars.push({
        label: label3,
        value: parseInt(topPct3, 10),
      });
    }
    if (topPct4 && label4) {
      vars.push({
        label: label4,
        value: parseInt(topPct4, 10),
      });
    }
    if (topPct5 && label5) {
      vars.push({
        label: label5,
        value: parseInt(topPct5, 10),
      });
    }
    return (
      <Mutation
        mutation={ADD_SYMPTOM_TRACKER_LABELS_GROUP}
        refetchQueries={[{ query: GET_SYMPTOM_TRACKER_LABELS_GROUPS }]}
        variables={{
          labels: vars,
        }}
        onCompleted={() => {
          this.setState({
            topPct0: null,
            label0: '',
            topPct1: null,
            label1: '',
            topPct2: null,
            label2: '',
            topPct3: null,
            label3: '',
            topPct4: null,
            label4: '',
            topPct5: null,
            label5: '',
          });
          onSave();
        }}
      >
        {(createCategory, { loading }) => (
          <Flex flexDirection="column" width="400px">
            <Flex flexDirection="row">
              <Input
                placeholder="Top Pct"
                value={topPct0}
                type="number"
                onChange={val => {
                  this.setState({
                    topPct0: val.target.value,
                  });
                }}
              />
              <Input
                placeholder="Label"
                value={label0}
                onChange={val => {
                  this.setState({
                    label0: val.target.value,
                  });
                }}
              />
            </Flex>

            <Flex flexDirection="row">
              <Input
                placeholder="Top Pct"
                value={topPct1}
                type="number"
                onChange={val => {
                  this.setState({
                    topPct1: val.target.value,
                  });
                }}
              />
              <Input
                placeholder="Label"
                value={label1}
                onChange={val => {
                  this.setState({
                    label1: val.target.value,
                  });
                }}
              />
            </Flex>

            <Flex flexDirection="row">
              <Input
                placeholder="Top Pct"
                value={topPct2}
                onChange={val => {
                  this.setState({
                    topPct2: val.target.value,
                  });
                }}
              />
              <Input
                placeholder="Label"
                value={label2}
                onChange={val => {
                  this.setState({
                    label2: val.target.value,
                  });
                }}
              />
            </Flex>

            <Flex flexDirection="row">
              <Input
                placeholder="Top Pct"
                type="number"
                value={topPct3}
                onChange={val => {
                  this.setState({
                    topPct3: val.target.value,
                  });
                }}
              />
              <Input
                placeholder="Label"
                value={label3}
                onChange={val => {
                  this.setState({
                    label3: val.target.value,
                  });
                }}
              />
            </Flex>

            <Flex flexDirection="row">
              <Input
                placeholder="Top Pct"
                value={topPct4}
                type="number"
                onChange={val => {
                  this.setState({
                    topPct4: val.target.value,
                  });
                }}
              />
              <Input
                placeholder="Label"
                value={label4}
                onChange={val => {
                  this.setState({
                    label4: val.target.value,
                  });
                }}
              />
            </Flex>

            <Flex flexDirection="row">
              <Input
                placeholder="Top Pct"
                value={topPct5}
                type="number"
                onChange={val => {
                  this.setState({
                    topPct5: val.target.value,
                  });
                }}
              />
              <Input
                placeholder="Label"
                value={label5}
                onChange={val => {
                  this.setState({
                    label5: val.target.value,
                  });
                }}
              />
            </Flex>

            <Flex
              mt={4}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                style={{
                  background: 'white',
                  color: '#344f79',
                  border: '1px solid #344f79',
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                // disabled={!isValid}
                style={{ background: '#344f79' }}
                onClick={() => {
                  createCategory();
                }}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        )}
      </Mutation>
    );
  }
}

class CategoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      color: '',
      textLineColor: '',
    };
  }

  validate = () => {
    const { existingCategories } = this.props;
    const { name, color } = this.state;
    console.log('validate', { name, color });
    if (!name || !name.trim() || !color) return false;
    if (
      existingCategories.filter(
        x => x.name.toUpperCase() === (name || '').trim().toUpperCase()
      ).length > 0
    ) {
      return false;
    }

    return true;
  };

  render() {
    const { onClose, onSave } = this.props;
    const { name, description, color, textLineColor } = this.state;
    const isValid = this.validate();
    return (
      <Mutation
        mutation={ADD_SYMPTOM_TRACKER_CATEGORY}
        refetchQueries={[{ query: GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES }]}
        variables={{
          name: name.trim(),
          description: description.trim(),
          color: color.trim(),
          textLineColor: textLineColor.trim(),
          isActive: true,
        }}
        onCompleted={() => {
          this.setState({
            name: '',
            description: '',
            color: '',
          });
          onSave();
        }}
      >
        {(createCategory, { loading }) => (
          <Flex flexDirection="column" width="400px">
            <Text>Name</Text>
            <Input
              placeholder="Category name*"
              value={name}
              onChange={val => {
                this.setState({
                  name: val.target.value,
                });
              }}
            />
            <Text mt={2}>Description</Text>
            <Input
              placeholder="Category description"
              value={description}
              onChange={val => {
                this.setState({
                  description: val.target.value,
                });
              }}
            />
            <Text mt={2}>Color</Text>
            <Input
              placeholder="Category color*"
              value={color}
              onChange={val => {
                this.setState({
                  color: val.target.value,
                });
              }}
            />
            <Text mt={2}>Text/Line Color</Text>
            <Input
              placeholder="Text/Line Color*"
              value={textLineColor}
              onChange={val => {
                this.setState({
                  textLineColor: val.target.value,
                });
              }}
            />

            <Flex
              mt={4}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                style={{
                  background: 'white',
                  color: '#344f79',
                  border: '1px solid #344f79',
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid}
                style={{ background: '#344f79' }}
                onClick={() => {
                  createCategory();
                }}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        )}
      </Mutation>
    );
  }
}

class TrackerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      question: '',
      config: {},
      category: null,
    };
  }

  validate = () => {
    // const { existingCategories } = this.props;
    // const { name, color } = this.state;
    // console.log('validate', { name, color });
    // if (!name || !name.trim() || !color) return false;
    // if (
    //   existingCategories.filter(
    //     x => x.name.toUpperCase() === (name || '').trim().toUpperCase()
    //   ).length > 0
    // ) {
    //   return false;
    // }

    return true;
  };

  render() {
    const { onClose, onSave, labelsSelect, categoriesSelect } = this.props;
    const { name, question, config, category } = this.state;
    const isValid = this.validate();
    return (
      <Mutation
        mutation={ADD_SYMPTOM_TRACKER_MARKER}
        refetchQueries={[{ query: GET_MOBILE_SYMPTOM_TRACKERS }]}
        variables={{
          data: {
            categoryId: category,
            name: name,
            question: question,
            title: name,
            labelsGroupId: config.labelGroupId,
            negativeColor: config.negativeColor,
            positiveColor: config.positiveColor,
            trackColor: config.trackColor,
            knobColor: config.knobColor,
            titleColor: config.titleColor,
            textColor: config.textColor,
            lastValuesIndicatorColor: config.lastValuesIndicatorColor,
          },
        }}
        onCompleted={() => {
          this.setState({
            name: '',
            question: '',
            config: {},
            category: null,
          });
          onSave();
        }}
      >
        {(createCategory, { loading }) => (
          <Flex flexDirection="row" width="600px">
            <Flex flexDirection="column" flex={1}>
              <Flex
                flexDirection="column"
                style={{ overflowY: 'scroll', height: '400px' }}
              >
                <Text>Name</Text>
                <Input
                  placeholder="Tracker name*"
                  value={name}
                  onChange={val => {
                    this.setState({
                      name: val.target.value,
                    });
                  }}
                />
                <Text>Question</Text>
                <Input
                  placeholder="Question*"
                  value={question}
                  onChange={val => {
                    this.setState({
                      question: val.target.value,
                    });
                  }}
                />
                <Text>Title Color</Text>
                <Input
                  placeholder="#cccccc"
                  value={config.titleColor}
                  onChange={val => {
                    this.setState({
                      config: {
                        ...config,
                        titleColor: val.target.value,
                      },
                    });
                  }}
                />
                <Text>Text Color</Text>
                <Input
                  placeholder="#cccccc"
                  value={config.textColor}
                  onChange={val => {
                    this.setState({
                      config: {
                        ...config,
                        textColor: val.target.value,
                      },
                    });
                  }}
                />
                <Text>Main Background Color</Text>
                <Input
                  placeholder="#cccccc"
                  value={config.negativeColor}
                  onChange={val => {
                    this.setState({
                      config: {
                        ...config,
                        negativeColor: val.target.value,
                      },
                    });
                  }}
                />
                <Text>Progress Background Color</Text>
                <Input
                  placeholder="#cccccc"
                  value={config.positiveColor}
                  onChange={val => {
                    this.setState({
                      config: {
                        ...config,
                        positiveColor: val.target.value,
                      },
                    });
                  }}
                />
                <Text>Track Background Color</Text>
                <Input
                  placeholder="#cccccc"
                  value={config.trackColor}
                  onChange={val => {
                    this.setState({
                      config: {
                        ...config,
                        trackColor: val.target.value,
                      },
                    });
                  }}
                />
                <Text>Knob Background Color</Text>
                <Input
                  placeholder="#cccccc"
                  value={config.knobColor}
                  onChange={val => {
                    this.setState({
                      config: {
                        ...config,
                        knobColor: val.target.value,
                      },
                    });
                  }}
                />
                <Text>Previous Indicator Color</Text>
                <Input
                  placeholder="#cccccc"
                  value={config.lastValuesIndicatorColor}
                  onChange={val => {
                    this.setState({
                      config: {
                        ...config,
                        lastValuesIndicatorColor: val.target.value,
                      },
                    });
                  }}
                />
                <Text>Labels</Text>
                <Select
                  classNamePrefix="region-select"
                  options={labelsSelect}
                  value={labelsSelect.filter(
                    element => element.value === config.labelGroupId
                  )}
                  onChange={option =>
                    this.setState({
                      config: {
                        ...config,
                        labelGroupId: option.value,
                      },
                    })
                  }
                  isSearchable={true}
                  theme={base => ({
                    ...base,
                    colors: {
                      ...base.colors,
                      primary: '#364f79',
                      primary50: '#dae6fa',
                    },
                  })}
                />
                <Text>Category</Text>
                <Select
                  classNamePrefix="region-select"
                  options={categoriesSelect}
                  value={categoriesSelect.filter(
                    element => element.value === category
                  )}
                  onChange={option =>
                    this.setState({
                      category: option.value,
                    })
                  }
                  isSearchable={true}
                  theme={base => ({
                    ...base,
                    colors: {
                      ...base.colors,
                      primary: '#364f79',
                      primary50: '#dae6fa',
                    },
                  })}
                />
              </Flex>

              <Flex
                mt={4}
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  style={{
                    background: 'white',
                    color: '#344f79',
                    border: '1px solid #344f79',
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isValid}
                  style={{ background: '#344f79' }}
                  onClick={() => {
                    createCategory();
                  }}
                >
                  Save
                </Button>
              </Flex>
            </Flex>

            <Box
              style={{
                backgroundColor: '#ccc',
                padding: '20px',
                width: '250px',
                height: '100%',
              }}
            >
              <Flex
                flexDirection="column"
                style={{ minHeight: '400px', position: 'relative' }}
              >
                <Flex
                  flex="1"
                  style={{
                    backgroundColor: config.negativeColor,
                    padding: '6px',
                  }}
                >
                  <Text style={{ color: config.titleColor || '' }}>
                    {question}
                  </Text>
                </Flex>
                <Flex
                  flex="1"
                  style={{
                    backgroundColor: config.positiveColor,
                    alignItems: 'flex-end',
                    padding: '6px',
                  }}
                >
                  <Text style={{ color: config.textColor || '' }}>label</Text>
                </Flex>
                <Box
                  style={{
                    position: 'absolute',
                    right: 20,
                    top: 40,
                    bottom: 40,
                    width: 6,
                    backgroundColor: config.trackColor || 'white',
                  }}
                />
                <Box
                  style={{
                    position: 'absolute',
                    right: 13,
                    borderRadius: 10,
                    bottom: 150,
                    width: 20,
                    height: 40,
                    backgroundColor: config.knobColor || 'white',
                  }}
                />
              </Flex>
            </Box>
          </Flex>
        )}
      </Mutation>
    );
  }
}

class MobileSymptomTrackers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNewCategoryVisible: false,
      isAddNewTrackerVisible: false,
      isAddNewLabelsVisible: false,
    };
  }

  showAddNewCategory = () => {
    this.setState({ isAddNewCategoryVisible: true });
  };

  cancelAddNewCategory = () => {
    this.setState({ isAddNewCategoryVisible: false });
  };

  showAddNewTracker = () => {
    this.setState({ isAddNewTrackerVisible: true });
  };

  cancelAddNewTracker = () => {
    this.setState({ isAddNewTrackerVisible: false });
  };

  showAddNewLabels = () => {
    this.setState({ isAddNewLabelsVisible: true });
  };

  cancelAddNewLabels = () => {
    this.setState({ isAddNewLabelsVisible: false });
  };

  deleteOnClick = async id => {
    const { client } = this.props;
    // var r = window.confirm(
    //   'Confirm Delete. (Any screen logged by user will be marked as inactive instead)'
    // );
    // if (r == true) {
      await client.mutate({
        mutation: DELETE_SYMPTOM_TRACKER_MARKER,
        variables: {
          id,
        },
        refetchQueries: [{ query: GET_MOBILE_SYMPTOM_TRACKERS }],        
        awaitRefetchQueries: true
      });
    // }
  };

  trackersColumns = [
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'category',
      accessor: 'category.name',
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div
              title="delete"
              style={{ cursor: 'pointer' }}
              onClick={() => this.deleteOnClick(row.original.id)}
            >
              🗑
            </div>
          </Flex>
        );
      },
    },
  ];

  render() {
    const {
      isAddNewCategoryVisible,
      isAddNewTrackerVisible,
      isAddNewLabelsVisible,
    } = this.state;
    console.log('isAddNewTrackerVisible', isAddNewTrackerVisible);
    return (
      <Container>
        <Heading>SYMPTOM TRACKERS</Heading>
        <Flex
          flexDirection="row"
          style={{ justifyContent: 'space-between' }}
          mt={4}
          mb={3}
        >
          <Heading fontSize="20px">Categories</Heading>
          <Button
            style={{ background: '#344f79' }}
            onClick={this.showAddNewCategory}
          >
            Add Category
          </Button>
        </Flex>
        <Query query={GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES}>
          {({ data, loading, error }) => {
            if (loading || error) return <div />;
            console.log('GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES', data);
            return (
              <Box>
                <ReactTable
                  data={data.getSymptomTrackerCategories}
                  columns={categoriesColumns}
                  defaultPageSize={
                    data.getSymptomTrackerCategories &&
                    data.getSymptomTrackerCategories.length > 3
                      ? Math.min(data.getSymptomTrackerCategories.length, 25)
                      : 3
                  }
                  showPagination={
                    data.getSymptomTrackerCategories &&
                    data.getSymptomTrackerCategories.length > 24
                  }
                />

                <ConfirmModal
                  childrenManaged
                  isOpen={isAddNewCategoryVisible}
                  confirmLabel="create"
                  onClose={this.cancelAddNewCategory}
                  onBackgroundClick={this.cancelAddNewCategory}
                  onEscapeKeydown={this.cancelAddNewCategory}
                  onConfirm={this.cancelAddNewCategory}
                >
                  <ModalInner>
                    <Heading fontSize="20px">Create Category</Heading>
                    <CategoryModal
                      existingCategories={data.getSymptomTrackerCategories}
                      onClose={this.cancelAddNewCategory}
                      onSave={this.cancelAddNewCategory}
                    />
                  </ModalInner>
                </ConfirmModal>
              </Box>
            );
          }}
        </Query>

        <Flex
          flexDirection="row"
          style={{ justifyContent: 'space-between' }}
          mt={4}
          mb={3}
        >
          <Heading fontSize="20px">Label Groups</Heading>
          <Button
            style={{ background: '#344f79' }}
            onClick={this.showAddNewLabels}
          >
            Add Label groups
          </Button>
        </Flex>
        <Query query={GET_SYMPTOM_TRACKER_LABELS_GROUPS}>
          {({ data, loading, error }) => {
            if (loading || error) return <div />;
            console.log('GET_SYMPTOM_TRACKER_LABELS_GROUPS', data);
            return (
              <Box>
                <ReactTable
                  data={data.getSymptomTrackerLabelsGroups}
                  columns={labelsColumns}
                  defaultPageSize={
                    data.getSymptomTrackerLabelsGroups &&
                    data.getSymptomTrackerLabelsGroups.length > 3
                      ? Math.min(data.getSymptomTrackerLabelsGroups.length, 25)
                      : 3
                  }
                  showPagination={
                    data.getSymptomTrackerLabelsGroups &&
                    data.getSymptomTrackerLabelsGroups.length > 24
                  }
                />
              </Box>
            );
          }}
        </Query>

        <Flex
          flexDirection="row"
          style={{ justifyContent: 'space-between' }}
          mt={4}
          mb={3}
        >
          <Heading fontSize="20px">Trackers</Heading>
          <Button
            style={{ background: '#344f79' }}
            onClick={this.showAddNewTracker}
          >
            Add Tracker
          </Button>
        </Flex>
        <Query query={GET_MOBILE_SYMPTOM_TRACKERS}>
          {({ data, loading, error }) => {
            if (loading || error) return <div />;
            console.log('GET_MOBILE_SYMPTOM_TRACKERS', data);
            return (
              <Box>
                <ReactTable
                  data={data.getSymptomTrackers}
                  columns={this.trackersColumns}
                  defaultPageSize={
                    data.getSymptomTrackers &&
                    data.getSymptomTrackers.length > 3
                      ? Math.min(data.getSymptomTrackers.length, 25)
                      : 3
                  }
                  showPagination={
                    data.getSymptomTrackers &&
                    data.getSymptomTrackers.length > 24
                  }
                />
              </Box>
            );
          }}
        </Query>

        <ConfirmModal
          childrenManaged
          isOpen={isAddNewLabelsVisible}
          confirmLabel="create"
          onClose={this.cancelAddNewLabels}
          onBackgroundClick={this.cancelAddNewLabels}
          onEscapeKeydown={this.cancelAddNewLabels}
          onConfirm={this.cancelAddNewLabels}
        >
          <ModalInner>
            <Heading fontSize="20px">Create Category</Heading>
            <LabelsModal
              onClose={this.cancelAddNewLabels}
              onSave={this.cancelAddNewLabels}
            />
          </ModalInner>
        </ConfirmModal>
        <ConfirmModal
          childrenManaged
          isOpen={isAddNewTrackerVisible}
          confirmLabel="create"
          onClose={this.cancelAddNewTracker}
          onBackgroundClick={this.cancelAddNewTracker}
          onEscapeKeydown={this.cancelAddNewTracker}
          onConfirm={this.cancelAddNewTracker}
        >
          <ModalInner>
            <Heading fontSize="20px">Create Category</Heading>
            <Query query={GET_SYMPTOM_TRACKER_LABELS_GROUPS}>
              {({ data, loading, error }) => {
                if (loading || error) return <div />;
                const labelsSelect = [];

                for (
                  let index = 0;
                  index < data.getSymptomTrackerLabelsGroups.length;
                  index++
                ) {
                  const element = data.getSymptomTrackerLabelsGroups[index];
                  labelsSelect.push({
                    value: element.id,
                    label: element.labels
                      .map(x => `${x.value}% ${x.label}`)
                      .join(' - '),
                  });
                }
                return (
                  <Query query={GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES}>
                    {({ data, loading, error }) => {
                      const categoriesSelect = [];

                      for (
                        let index = 0;
                        index < data.getSymptomTrackerCategories.length;
                        index++
                      ) {
                        const element = data.getSymptomTrackerCategories[index];
                        categoriesSelect.push({
                          value: element.id,
                          label: element.name,
                        });
                      }
                      return (
                        <TrackerModal
                          onClose={this.cancelAddNewTracker}
                          onSave={this.cancelAddNewTracker}
                          labelsSelect={labelsSelect}
                          categoriesSelect={categoriesSelect}
                        />
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          </ModalInner>
        </ConfirmModal>
      </Container>
    );
  }
}

export default withApollo(MobileSymptomTrackers);
