import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass';
import LabelText from './LabelText';

const ErrorTooltip = styled(Box)`
  position: absolute;
  top: 100%;
  font-size: 14px;
  background: ${props => props.theme.errorTooltip.backgroundColor};
  color: ${props => props.theme.errorTooltip.color};
  padding: 5px 8px;
  border-radius: 4px;
  z-index: 3;
  margin-top: -8px;
  max-width: 100%;
  word-break: break-word;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.30);
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    top: -5px;
    left: 10px;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent ${props => props.theme.errorTooltip.borderColor}
      transparent;
  }
`;

function validationErrorMapper(errorCode) {
  return [`form.validationErrors.${String(errorCode)}`, String(errorCode)];
}

export class FormField extends Component {
  static childContextTypes = {
    formFieldHasError: PropTypes.bool,
  };

  getChildContext() {
    return {
      formFieldHasError: this.props.error && this.props.error.length > 0,
    };
  }

  renderLabel() {
    if (!this.props.label) return null;

    return (
      <Box mb={2} helpText={this.props.helpText} htmlFor={this.uniqueId}>
        <Box>
          {this.props.label}
          {this.props.required && (
            <Text>&nbsp;*</Text>
          )}
        </Box>
      </Box>
    );
  }

  renderError() {
    const { error } = this.props;
    if (!error || error.length < 1) return null;
    
    const message = (typeof error === Array)
      ? error.map(validationErrorMapper).join(', ')
      : error;

    return (
      <ErrorTooltip>{message}</ErrorTooltip>
    );
  }

  render() {
    return (
      <FormFieldStyled flexDirection="column" {...this.props}>
        {this.props.label && (
          <LabelText helpText={this.props.label} />
        )}
        {this.props.children}
        {this.renderError()}
      </FormFieldStyled>
    );
  }
}

const FormFieldStyled = styled(Flex)`
  position: relative;
`;

export default FormField;