import React, { Fragment, Component } from 'react';
import { Flex, Text, Button } from 'rebass';
import styled from 'styled-components';
import { Route, NavLink as NavLinkRouter, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { Query, compose } from 'react-apollo';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import SpinLoader from '../SpinLoader';
import { Stethoscope } from '../icons';
import ScheduleNewNutritionist from './ScheduleNewNutritionist';
import { APPOINTMENTS_NUTRITIONIST } from '../../graphql';
import withSession from '../../lib/withSession';
import ConsultationNutritionistDetails from './ConsultationNutritionistDetails';
import withAnalytics from '../../lib/withAnalytics';

const Section = styled.div`
  color: #000;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  transition: all 300ms ease;
  &.active {
    background: rgba(180, 205, 244, 0.5);
  }
`;
const iconStyle = {
  width: '20px',
  height: '20px',
  background: '#FFFFFF',
  border: '3px solid #BCFFEB',
  margin: '10px',
  boxShadow: 'initial',
};
const iconAsyncStyle = {
  width: '20px',
  height: '20px',
  background: '#FFFFFF',
  border: '3px solid rgba(235, 182, 167, 1)',
  margin: '10px',
  boxShadow: 'initial',
};
const ListContainer = styled(Flex)`
  min-width: 300px;
  width: 100%;
  overflow-y: auto;
  &.selected {
    display: none;
  }

  @media screen and (min-width: 68.8125em) {
    /* width: auto; */
    &.selected {
      display: flex;
    }
  }
`;
const BackButtonDesktop = styled.div`
  @media screen and (max-width: 52em) {
    display: none;
  }
`;
const BackButton = styled.div`
  background: rgba(180, 205, 244, 0.5);
  width: 100%;
  color: #333;
  padding: 6px 12px;
  @media screen and (min-width: 52em) {
    display: none;
  }
`;
const DetailContainer = styled(Flex)`
  display: none;
  overflow-y: auto;
  &.selected {
    right: 0;
    top: 0;
    position: fixed;
    height: 100vh;
    z-index: 1000;
    width: 100vw;
    max-width: 100vw;
    display: flex;
    justify-content: flex-end;
    background: transparent;
    backdrop-filter: blur(2px);
  }

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const Inner = styled(Flex)`
  max-width: 750px;
  height: 100vh;
  background: rgb(253, 245, 242);
  overflow-y: auto;
  box-shadow: 5px 5px 30px 0px rgba(78, 101, 138, 0.35);
  @media screen and (min-width: 52em) {
    padding: 24px;
  }
  @media screen and (max-width: 52em) {
    max-width: 100vw;
    width: 100vw;
  }
`;

const FlexDesktop = styled(Flex)`
  display: flex;
  @media screen and (max-width: 52em) {
    display: none;
  }
`;
const FlexMobile = styled(Flex)`
  display: flex;
  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const NavLink = styled(NavLinkRouter)`
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100px;
  transition: all 300ms ease;
  &.active {
    background: rgba(180, 205, 244, 0.5);
  }
  &:hover {
    background: rgba(180, 205, 244, 0.5);
  }
`;

const ListItem = styled(Flex)`
  /* border-bottom: 1px solid #ccc; */
`;

const Avatar = styled(Flex)`
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

class ConsultationsNutritionistV3 extends Component {
  state = {
    searchPatientId: '',
    prompt: false,
  };

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;

    document.body.appendChild(script);
  }

  componentWillUnmount() {
    var tags = document.getElementsByTagName('script');
    try {
      window.removeEventListener('message', this.windowMessageReceived);
    } catch (error) {}
    for (var i = tags.length; i >= 0; i--) {
      if (tags[i] && tags[i].getAttribute('src') != null && tags[i].getAttribute('src').indexOf('https://assets.calendly.com/assets/external/widget.js') > -1) tags[i].parentNode.removeChild(tags[i]); //remove element by calling parentNode.removeChild()
    }
  }

  extractTzFromAppt = (appointment) => {
    const splt = appointment.inviteLocation.split(' ');
    return splt.find((x) => x.startsWith('America'));
  };

  render() {
    const {
      session: { email, firstName, lastName, b2b },
    } = this.props;

    const name = encodeURI(`${firstName} ${lastName}`);
    const selected = !this.props.match.isExact ? 'selected' : null;

    return (
      <Fragment>
        <Query query={APPOINTMENTS_NUTRITIONIST}>
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <Flex flex={1} justifyContent="center" alignItems="center">
                  <SpinLoader />
                </Flex>
              );
            }

            if (error) {
              return (
                <Flex flex={1} justifyContent="center" alignItems="center">
                  <Text fontSize={5} fontWeight={600} textAlign="center">
                    Unable to retrieve visits. Please try again.
                  </Text>
                </Flex>
              );
            }

            const { appointmentsNutritionist } = data;

            if (appointmentsNutritionist.length === 0) {
              return <ScheduleNewNutritionist {...this.props} />;
            }

            return (
              <Flex style={{ overflowY: 'hidden' }} flex={1}>
                <ListContainer className={selected} flexDirection="column">
                  <Flex mt={3} mb={3} alignItems={'center'} style={{ borderBottom: '1px solid rgba(52, 79, 122, 0.19)' }} pb={3}>
                    <Text fontSize={'20px'} fontWeight={'600'} ml={3} textAlign={'left'} flex={1}>
                      Your Nutritionist Visits
                    </Text>

                    <FlexDesktop>
                      <Button
                        variant={'white'}
                        style={{ border: '1px solid #4E658A', paddingTop: '6px', paddingBottom: '6px', display: 'flex', fontWeight: 300, fontSize: '14px', alignItems: 'center' }}
                        onClick={() => {
                          window.Calendly.initPopupWidget({
                            url:
                              !b2b || !b2b.nutritionist
                                ? `https://calendly.com/paloma-video-visit/paloma-health-consultation?hide_event_type_details=1&name=${name}&email=${email}`
                                : `https://calendly.com/paloma-video-visit/paloma-health-consultation-fringe?hide_event_type_details=1&name=${name}&email=${email}`,
                          });

                          this.props.analytics.track('Book nutritionist consultation');
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '12px' }} width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <rect width="18" height="18" fill="white" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1423 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1423 16.5 9 16.5C4.85775 16.5 1.5 13.1423 1.5 9ZM9 3C7.4087 3 5.88258 3.63214 4.75736 4.75736C3.63214 5.88258 3 7.4087 3 9C3 10.5913 3.63214 12.1174 4.75736 13.2426C5.88258 14.3679 7.4087 15 9 15C10.5913 15 12.1174 14.3679 13.2426 13.2426C14.3679 12.1174 15 10.5913 15 9C15 7.4087 14.3679 5.88258 13.2426 4.75736C12.1174 3.63214 10.5913 3 9 3Z"
                            fill="#344F7A"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.75 5.25C9.75 5.05109 9.67098 4.86032 9.53033 4.71967C9.38968 4.57902 9.19891 4.5 9 4.5C8.80109 4.5 8.61032 4.57902 8.46967 4.71967C8.32902 4.86032 8.25 5.05109 8.25 5.25V8.25H5.25C5.05109 8.25 4.86032 8.32902 4.71967 8.46967C4.57902 8.61032 4.5 8.80109 4.5 9C4.5 9.19891 4.57902 9.38968 4.71967 9.53033C4.86032 9.67098 5.05109 9.75 5.25 9.75H8.25V12.75C8.25 12.9489 8.32902 13.1397 8.46967 13.2803C8.61032 13.421 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.421 9.53033 13.2803C9.67098 13.1397 9.75 12.9489 9.75 12.75V9.75H12.75C12.9489 9.75 13.1397 9.67098 13.2803 9.53033C13.421 9.38968 13.5 9.19891 13.5 9C13.5 8.80109 13.421 8.61032 13.2803 8.46967C13.1397 8.32902 12.9489 8.25 12.75 8.25H9.75V5.25Z"
                            fill="#344F7A"
                          />
                        </svg>
                        Schedule a Visit
                      </Button>
                    </FlexDesktop>
                    <FlexMobile>
                      <Button
                        variant={'white'}
                        style={{
                          border: '0px solid transparent',
                          background: 'transparent',
                          backgroundColor: 'transparent',
                          paddingTop: '6px',
                          paddingBottom: '6px',
                          display: 'flex',
                          fontWeight: 300,
                          fontSize: '14px',
                          alignItems: 'center',
                          color: 'rgba(101, 122, 164, 1)',
                        }}
                        onClick={() => {
                          window.Calendly.initPopupWidget({
                            url:
                              !b2b || !b2b.nutritionist
                                ? `https://calendly.com/paloma-video-visit/paloma-health-consultation?hide_event_type_details=1&name=${name}&email=${email}`
                                : `https://calendly.com/paloma-video-visit/paloma-health-consultation-fringe?hide_event_type_details=1&name=${name}&email=${email}`,
                          });

                          this.props.analytics.track('Book nutritionist consultation');
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '12px' }} width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <rect width="18" height="18" fill="transparent" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1423 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1423 16.5 9 16.5C4.85775 16.5 1.5 13.1423 1.5 9ZM9 3C7.4087 3 5.88258 3.63214 4.75736 4.75736C3.63214 5.88258 3 7.4087 3 9C3 10.5913 3.63214 12.1174 4.75736 13.2426C5.88258 14.3679 7.4087 15 9 15C10.5913 15 12.1174 14.3679 13.2426 13.2426C14.3679 12.1174 15 10.5913 15 9C15 7.4087 14.3679 5.88258 13.2426 4.75736C12.1174 3.63214 10.5913 3 9 3Z"
                            fill="rgba(101, 122, 164, 1)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.75 5.25C9.75 5.05109 9.67098 4.86032 9.53033 4.71967C9.38968 4.57902 9.19891 4.5 9 4.5C8.80109 4.5 8.61032 4.57902 8.46967 4.71967C8.32902 4.86032 8.25 5.05109 8.25 5.25V8.25H5.25C5.05109 8.25 4.86032 8.32902 4.71967 8.46967C4.57902 8.61032 4.5 8.80109 4.5 9C4.5 9.19891 4.57902 9.38968 4.71967 9.53033C4.86032 9.67098 5.05109 9.75 5.25 9.75H8.25V12.75C8.25 12.9489 8.32902 13.1397 8.46967 13.2803C8.61032 13.421 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.421 9.53033 13.2803C9.67098 13.1397 9.75 12.9489 9.75 12.75V9.75H12.75C12.9489 9.75 13.1397 9.67098 13.2803 9.53033C13.421 9.38968 13.5 9.19891 13.5 9C13.5 8.80109 13.421 8.61032 13.2803 8.46967C13.1397 8.32902 12.9489 8.25 12.75 8.25H9.75V5.25Z"
                            fill="rgba(101, 122, 164, 1)"
                          />
                        </svg>
                        Schedule a Visit
                      </Button>
                    </FlexMobile>
                  </Flex>
                  {/* <Button
                    variant="primary"
                    style={{ margin: '12px' }}
                    onClick={() => {
                      window.Calendly.initPopupWidget({
                        url:
                          !b2b || !b2b.nutritionist
                            ? `https://calendly.com/paloma-video-visit/paloma-health-consultation?hide_event_type_details=1&name=${name}&email=${email}`
                            : `https://calendly.com/paloma-video-visit/paloma-health-consultation-fringe?hide_event_type_details=1&name=${name}&email=${email}`,
                      });

                      this.props.analytics.track('Book new nutritionist consultation');
                    }}
                  >
                    Book a Visit
                  </Button> */}
                  <Section>
                    <Text fontSize="18px" fontWeight="500" ml="20px" color="#344f79">
                      Upcoming Appointments
                    </Text>
                  </Section>
                  {appointmentsNutritionist.filter((appointment) => appointment.status === 'f' || appointment.status === '2').length > 0 ? (
                    <VerticalTimeline layout="1-column" lineColor="#344F7A" className="vertical-timeline-consults">
                      <>
                        {appointmentsNutritionist.filter((appointment) => appointment.status === 'f' || appointment.status === '2').map((appointment) => (
                          <VerticalTimelineElement className="vertical-timeline-element--consults" contentStyle={{ background: 'white' }} date={''} iconStyle={iconStyle} icon={<div />}>
                            <ListItem key={appointment.id}>
                              <NavLink to={`/consultations-nutritionist/${appointment.id}`}>
                                <Flex p={3} alignItems="center">
                                  <Flex mr={3}>
                                    <Avatar>
                                      {appointment && appointment.provider && appointment.provider.picture ? (
                                        <img src={appointment.provider.picture} alt="" height="60px" width="60px" />
                                      ) : (
                                        <Stethoscope height={45} width={45} />
                                      )}
                                    </Avatar>
                                  </Flex>
                                  <Flex flexDirection="column">
                                    <Text mb={1} fontSize={'16px'} color="#344f79">
                                      {appointment.provider.displayName}
                                    </Text>
                                    <Text fontSize={1} color="grey">
                                      {`${moment(appointment.date, 'MM/DD/YYYY').format('ddd MMM Do')} ${moment(appointment.startTime, 'HH:mm').format('h:mma')}`}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </NavLink>
                            </ListItem>
                          </VerticalTimelineElement>
                        ))}
                      </>
                    </VerticalTimeline>
                  ) : (
                    <Text mt={1} mb={1} fontSize="18px" fontWeight="400" color="#8e8e8e" ml="20px">
                      no upcoming appointment
                    </Text>
                  )}
                  <Section>
                    <Text fontSize="18px" fontWeight="500" ml="20px" color="#344f79">
                      Past Appointments
                    </Text>
                  </Section>
                  {appointmentsNutritionist.filter((appointment) => appointment.status !== 'f' && appointment.status !== '2').length > 0 ? (
                    <VerticalTimeline layout="1-column" lineColor="#344F7A" className="vertical-timeline-consults">
                      <>
                        {appointmentsNutritionist.filter((appointment) => appointment.status !== 'f' && appointment.status !== '2').map((appointment) => (
                          <VerticalTimelineElement className="vertical-timeline-element--consults" contentStyle={{ background: 'white' }} date={''} iconStyle={iconStyle} icon={<div />}>
                            <ListItem key={appointment.id}>
                              <NavLink to={`/consultations-nutritionist/${appointment.id}`}>
                                <Flex p={3} alignItems="center">
                                  <Flex mr={3}>
                                    <Avatar>
                                      {appointment && appointment.provider && appointment.provider.picture ? (
                                        <img src={appointment.provider.picture} alt="" height="60px" width="60px" />
                                      ) : (
                                        <Stethoscope height={45} width={45} />
                                      )}
                                    </Avatar>
                                  </Flex>
                                  <Flex flexDirection="column">
                                    <Text mb={1} fontSize={'16px'} color="#344f79">
                                      {appointment.provider.displayName}
                                    </Text>
                                    <Text fontSize={1} color="grey">
                                      {`${moment(appointment.date, 'MM/DD/YYYY').format('ddd MMM Do')} ${moment(appointment.startTime, 'HH:mm').format('h:mma')}`}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </NavLink>
                            </ListItem>
                          </VerticalTimelineElement>
                        ))}
                      </>
                    </VerticalTimeline>
                  ) : (
                    <Text mt={1} mb={1} fontSize="18px" fontWeight="400" color="#8e8e8e" ml="20px">
                      no past appointment
                    </Text>
                  )}
                </ListContainer>
                <DetailContainer
                  flex={1}
                  justifyContent="center"
                  className={selected}
                  id="testContainer"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <Inner
                    flex={1}
                    flexDirection="column"
                    pt={[0, 0, 3]}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <div>
                      <BackButtonDesktop>
                        <svg
                          onClick={() => this.props.history.goBack()}
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
                        >
                          <path d="M21.3337 8.33335L31.3337 20L21.3337 31.6667" stroke="#4E658A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M11.6667 8.33335L21.6667 20L11.6667 31.6667" stroke="#4E658A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </BackButtonDesktop>
                      {/* <BackButton onClick={() => this.props.history.goBack()}>{'<  Your Visits'}</BackButton> */}
                    </div>
                    <Route path="/consultations-nutritionist/:id" component={(props) => <ConsultationNutritionistDetails appointments={appointmentsNutritionist} {...props} />} />
                  </Inner>
                </DetailContainer>
              </Flex>
            );
          }}
        </Query>
      </Fragment>
    );
  }
}

export default compose(
  withSession,
  withRouter,
  withAnalytics
)(ConsultationsNutritionistV3);
