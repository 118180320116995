import React from 'react';
import { Flex, Heading, Text } from 'rebass';
import moment from 'moment';
import styled from 'styled-components';

import ItemCollection from '../../components/icons/ItemCollection';
import { DateMobile } from './LabResults2';

const Icon = styled(Flex)`
  display: none;
  @media screen and (min-width: 52em) {
    display: block;
  }
`;
export const LabStep2 = ({ currentStep, date }) => {
  return (
    <Flex flexDirection={['column', 'row']} flex={1}>

      <Flex flexDirection="column" flex={1}>
        <DateMobile mb={3}>{date}</DateMobile>
        <Text mb={3} fontWeight={600}>Congrats on collecting your sample</Text>
        <Text lineHeight={1.6} fontSize={'14px'}>
        Your sample is en route to our lab
          {currentStep.returnStatus
            ? `. ${currentStep.returnStatus}`
            : ` and USPS tracking shows it's expected to arrive ${moment(currentStep.eta).format(
                'MMMM Do, YYYY'
              )}.`}{' '}
          Hold Tight!
        </Text>
      </Flex>
      <Icon
        mr={[0, 4]}
        mb={[3, 0]}
        alignItems="flex-start"
        justifyContent="center"
      >
        <ItemCollection width={90} />
      </Icon>
    </Flex>
  );
};
