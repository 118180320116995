import React, { Fragment } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Mutation } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import gql from 'graphql-tag';
import styled from 'styled-components';

import TextInput from '../components/TextInput';
import FormField from '../components/FormField';
import FullLayout from '../components/FullLayout';
import Header from '../components/Header';
import withAnalytics from '../lib/withAnalytics';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('invalid email address')
    .required('Required'),
});

const TRIGGER_RESET = gql`
  mutation TriggerReset($email: String!) {
    triggerPasswordReset(email: $email) {
      ok
    }
  }
`;

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const CardContainer = styled(Flex)`
  max-width: 400px;
`;

const ForgotPassword = props => {
  props.analytics.page();

  const handleSubmit = async (values, triggerPassword) => {
    const { toastManager } = props;

    try {
      await triggerPassword({ variables: { ...values } });
      props.analytics.track('Password Requested');
    } catch (error) {
      error.graphQLErrors.forEach(error => {
        toastManager.add(error.message, { appearance: 'error' });
      });
    }
  };

  return (
    <Fragment>
      <Container>
        <Flex flex={1}>
          <Header dark={false}/>
        </Flex>
        <Flex flex={1} alignItems="center" justifyContent="center">
          <CardContainer flex={1} px={3}>
            <Card p={4}>
              <Flex flexDirection="column">
                <Box mb={4}>
                  <Heading textAlign="center">Reset Your Password</Heading>
                </Box>
                <Mutation mutation={TRIGGER_RESET}>
                  {(triggerPassword, { loading, data }) => {
                    if (data) {
                      return (
                        <Text textAlign="center">
                          An email with further instructions has been sent to
                          the address provided. Check the spam folder if you
                          don't see the email.
                        </Text>
                      );
                    }

                    return (
                      <Fragment>
                        <Formik
                          initialValues={{ email: '' }}
                          validationSchema={schema}
                          onSubmit={values =>
                            handleSubmit(values, triggerPassword)
                          }
                          render={({ touched, errors, isValid }) => (
                            <Form>
                              <FormField
                                mb={4}
                                error={touched.email && errors.email}
                              >
                                <TextInput
                                  placeholder="Email Address"
                                  type="email"
                                  name="email"
                                />
                              </FormField>

                              <Flex justifyContent="center">
                                <Button
                                  disabled={!isValid || loading}
                                  variant="primary"
                                  type="submit"
                                >
                                  Get Reset Link
                                </Button>
                              </Flex>
                            </Form>
                          )}
                        />
                      </Fragment>
                    );
                  }}
                </Mutation>
              </Flex>
            </Card>
          </CardContainer>
        </Flex>
      </Container>

      <FullLayout />
    </Fragment>
  );
};

export default withAnalytics(withToastManager(ForgotPassword));
