import React from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import Check from '../../../../components/icons/Check';
import moment from 'moment';
import { AppointmentAddToCalendar } from '../../../../components/addToCalendarButton/AppointmentAddToCalendar';

const InfoBox = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${(props) => props.theme.color.darkBluw};
  justify-content: center;
  max-width: 550px;
`;

const Icon = styled(Box)`
  display: block;
  margin: auto 0;
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const PromptIntake = (props) => {
  return (
    <>
      {/* <InfoCard p={4} mb={[4, 4, 5]}>
        <Heading mb={3} fontSize={4} fontWeight={900}>
          Don't forget to complete your intake form
        </Heading>
        <Text lineHeight={1.5}>
          Your consultation is booked but we cannot see you if you don't
          complete the intake prior to your consultation.
        </Text>

        <Flex flex={1} justifyContent="center" mt={3}>
          <Button
            variant="primary"
            width={[1, 1 / 2]}
            onClick={() => props.history.push('/intake')}
          >
            Complete Intake
          </Button>
        </Flex>
      </InfoCard> */}

      <Card p={4} mb={5}>
        <Heading mb={3} textAlign="center">
          Next: Complete Your Intake Form
        </Heading>
        <Text textAlign="center" style={{ maxWidth: '550px', margin: '0 auto', marginBottom: '16px' }}>
          Please note that without an up to date intake, we may have to cancel your visit
        </Text>

        <Flex justifyContent="center">
          <InfoBox mb={4}>
            <Icon>
              <Check width="50px" />
            </Icon>

            <Flex flexDirection="column" ml={[0, 4, 4]}>
              <Heading mb={2} textAlign="center">
                Your visit is confirmed
              </Heading>
              <Text fontWeight={700} textAlign="center" mb={4}>
                {`${moment(props.slot.startTime, 'HH:mm').format('h:mma')} on 
                          ${moment(props.slot.date, 'MM/DD/YYYY').format('dddd MMMM Do')}`}
              </Text>
              <AppointmentAddToCalendar
                appointment={{
                  date: props.slot.date,
                  startTime: props.slot.startTime,
                  timezoneName: props.slot.timezoneName,
                  duration: props.slot.duration,
                  isMeno: props.isMeno,
                }}
              />
            </Flex>
          </InfoBox>
        </Flex>

        {props.couldntConfirmCard && (
          <Flex flexDirection="column" mb={4}>
            <Text style={{ fontWeight: 'bold' }}>Payment</Text>
            <Text>We could not confirm your credit card but your visit is booked. A care manager will reach out to confirm your payment details</Text>
          </Flex>
        )}

        <Text mb={4} fontSize={2} textAlign="center">
          Answer a few questions to help us deliver the best thyroid care.
        </Text>

        <Flex flex={1} justifyContent="center" mb={[3, 0, 0]}>
          <Button
            variant="primary"
            width={[1, 1 / 2, 1 / 3]}
            onClick={() => {
              if (props.isMeno) {
                props.history.push('/intake-menopause');
              } else {
                props.history.push('/intake');
              }
            }}
          >
            Complete Intake
          </Button>
        </Flex>
      </Card>
    </>
  );
};

export default PromptIntake;
