import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';

import LockIcon from '../static/lockIcon.png';

const Wrapper = styled(Flex)`
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
`;

const InformationSecuredDisclaimer = props => {
  return (
    <Wrapper>
      <img src={LockIcon} height="44px" alt="" />
      <Text ml={3} fontWeight="400">
        Paloma takes your security very seriously and encrypts all of your
        information, meaning that your confidential data is handled securely.
      </Text>
    </Wrapper>
  );
};

export default InformationSecuredDisclaimer;
