import { Form, Formik } from 'formik';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Button, Flex } from 'rebass';
import FormField from '../../../components/FormField';
import TextInput from '../../../components/TextInput';
import { ADMIN_GET_WORKOUTS, ADMIN_GET_WORKOUTS_DIFFICULTIES, ADMIN_UPSERT_WORKOUT_DIFFICULTY } from '../../../graphql';

const AddDifficulty = ({ onSave, onClose, difficulty }) => {
  if(!difficulty) return null
  return (
    <Mutation
      mutation={ADMIN_UPSERT_WORKOUT_DIFFICULTY}
      refetchQueries={[{ query: ADMIN_GET_WORKOUTS_DIFFICULTIES }, { query: ADMIN_GET_WORKOUTS }]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        onSave();
      }}
    >
      {(upsertCategory, { loading }) => (
        <Formik
          isInitialValid={
            ({ initialValues }) => true
            //   schema.isValidSync(initialValues)
          }
          initialValues={{
            ...difficulty,
          }}
          // validationSchema={schema}
          onSubmit={async (values) => {
            let vars = {
              id: difficulty.id,
              name: values.name,
            };

            await upsertCategory({
              variables: {
                ...vars,
              },
            });
          }}
          render={({ touched, errors }) => (
            <Form>
              <Flex flexDirection="column" width="400px" style={{ maxHeight: '900px' }}>
                <FormField mb={4} error={touched.name && errors.name}>
                  <label for="name">Name *</label>
                  <TextInput name="name" placeholder="Name" />
                </FormField>

                <Flex mt={4} flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Button
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button disabled={!!loading} style={{ background: '#344f79' }}>
                    {difficulty.id ? 'Update' : 'Save'}
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )}
        />
      )}
    </Mutation>
  );
};

export default AddDifficulty;
