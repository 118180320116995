import React from 'react';
import { Flex, Text, Button, Card, Box } from 'rebass';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';

import barCode from '../../static/barCode.jpg';
import openKitSerial from '../../static/openKitSerial.png';
import FormField from '../../components/FormField';
import RadioInput from '../../components/RadioInput';
import { MaskInput } from '../../components/MaskInput';

import { REGISTER_KIT, LABS, CHECK_SERIAL_AVAILABLE } from '../../graphql';
import withAnalytics from '../../lib/withAnalytics';
import { isToggleActive } from '../../components/featureToggle/toggles';
import CheckboxInput from '../../components/CheckboxInput';

const eligibility = async (value) => {
  await Yup.string()
    .required('Required')
    .validate(value);

  return value.replace(/_/g, '').replace(/-/g, '').length === 9;
};

const schema = Yup.object().shape({
  serial: Yup.string()
    .required('Required')
    .test('serial', 'Incorrect serial number', eligibility),
  willbeusing: Yup.boolean()
    .oneOf([true])
    .required('The test taker must register this kit to their own account'),
});

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;

const RegisterKitEnter = (props) => {
  const handleSubmit = async (values, actions) => {
    const { client, toastManager } = props;
    const { data } = await client.query({
      query: CHECK_SERIAL_AVAILABLE,
      variables: {
        serial: values.serial,
      },
    });

    if (!data || !data.checkSerialAvailable) {
      toastManager.add('An error occured. Please retry', {
        appearance: 'error',
      });
      return;
    } else if (!data.checkSerialAvailable.ok) {
      toastManager.add(data.checkSerialAvailable.reason || 'Invalid Serial', {
        appearance: 'error',
      });
      return;
    }

    // if (
    //   data.checkSerialAvailable.billOnActivate
    // ) {
    //   props.history.push({
    //     pathname: '/register-kit-payment',
    //     state: {
    //       serial: values.serial,
    //     },
    //   });
    //   return;
    // }
    if (isToggleActive('KIT_ADDITIONAL_BIOMARKERS') && isToggleActive('KIT_ADDITIONAL_BIOMARKERS_ON_REGISTER') && (values.serial.indexOf('723-') !== 0 ||data.checkSerialAvailable.billOnActivate)) {
      props.history.push({
        pathname: '/register-kit-addons',
        state: {
          serial: values.serial,
          payOnActivate: !!data.checkSerialAvailable.billOnActivate,
        },
      });
      return;
    }

    try {
      await client.mutate({
        mutation: REGISTER_KIT,
        variables: {
          serial: values.serial,
        },
        refetchQueries: [{ query: LABS }],
        awaitRefetchQueries: true,
      });

      const opts = {
        serial: values.serial,
      };
      props.analytics.track('Kit Registered', { ...opts });
      props.history.push('/registered-kit');
    } catch (error) {
      actions.setSubmitting(false);

      if (!error.graphQLErrors) {
        toastManager.add(error.message, { appearance: 'error' });
      }

      error.graphQLErrors.forEach((error) => {
        toastManager.add(error.message, {
          appearance: 'error',
        });
      });
    }
  };

  return (
    <div style={{ width: '100%', overflowY: 'auto' }}>
      <Container flex={1} px={3}>
        <Flex flex={1} textAlign="center">
          <ScrollAnimation animateIn="fadeInDown" style={{ margin: '0 auto' }} animateOnce={true}>
            <Text fontSize="38px" fontWeight={600} textAlign="center" mb={4} style={{ fontFamily: 'Playfair Display' }} color="#344f79">
              Let's Register Your Paloma Kit
            </Text>
          </ScrollAnimation>
        </Flex>
        {/* <Flex flex={1} textAlign="center">
          <ScrollAnimation
            animateOnce={true}
            animateIn="fadeIn"
            delay={700}
            style={{ margin: '0 auto' }}
          >
            <Text
              fontSize="18px"
              fontWeight={400}
              textAlign="center"
              mb={4}
              color="#344f79"
            >
            </Text>
          </ScrollAnimation>
        </Flex> */}

        <ScrollAnimation animateOnce={true} animateIn="fadeInUpFrom30" delay={300} style={{ margin: '0 auto' }}>
          <Card p={3} mb={5} style={{ maxWidth: 'calc(100% - 0px)' }}>
            <Flex width={[1 / 2]} flexDirection="column" flex={1} textAlign="center" style={{ margin: '0 auto', minWidth: '270px' }} mt={4}>
              <Text fontSize="18px" fontWeight={400} textAlign="center" mb={4} mt={3} color="#344f79">
                Please locate the Unique Kit ID located inside your kit box.
              </Text>
              <img src={barCode} alt="" style={{ alignSelf: 'center', maxWidth: '80%' }} />
              <Formik
                initialValues={{ willbeusing: false, serial: '', acceptTerms: false }}
                validationSchema={schema}
                onSubmit={(values, actions) => handleSubmit(values, actions)}
                render={({ errors, touched, isValid, isSubmitting, handleChange, handleBlur, values }) => (
                  <Form>
                    <Text mt={3} fontSize="16px" fontWeight={400} textAlign="left" color="#344f79" mb="8px">
                      Test Kit Serial Number:
                    </Text>
                    <FormField mb={3} error={touched.serial && errors.serial}>
                      <MaskInput
                        mask="***-***-***"
                        value={values.serial}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          marginBottom: '16px',
                          textAlign: 'center',
                          letterSpacing: '4px',
                        }}
                        type="serial"
                        name="serial"
                      />
                    </FormField>
                    {/* <RadioInput label="I will be using this kit" color="#344f79" name="willbeusing" value={true} /> */}
                    <FormField mt={4} error={touched.willbeusing && errors.willbeusing}>
                          <Flex justifyContent={'center'}>
                            <CheckboxInput
                              name="willbeusing"
                              styles={{
                                checked: {
                                  Box: {
                                    backgroundColor: 'rgb(54, 79, 121)',
                                  },
                                },
                              }}
                            />
                            <label for="willbeusing" style={{textAlign: 'left'}}>
                            I will be using this kit
                            </label>
                          </Flex>
                        </FormField>
                    {values.serial &&
                      values.serial.indexOf('723') === 0 && (
                        <FormField mt={4} error={touched.acceptTerms && errors.acceptTerms}>
                          <Flex justifyContent={'center'}>
                            <CheckboxInput
                              name="acceptTerms"
                              styles={{
                                checked: {
                                  Box: {
                                    backgroundColor: 'rgb(54, 79, 121)',
                                  },
                                },
                              }}
                            />
                            <label for="acceptTerms" style={{textAlign: 'left'}}>
                              I have read, fully understood, and agree to the{' '}
                              <a href="https://uploads-ssl.webflow.com/5c17fc782f30f90cd15c25b4/64bec2582fb9cab8b5876ec4_Informed%20Consent%20for%20testing.pdf" target="_blank">
                                Informed Consent for Testing.
                              </a>
                            </label>
                          </Flex>
                        </FormField>
                      )}
                    <Button mt={4} mb={4} disabled={!isValid || isSubmitting || !values.willbeusing || ((values.serial || '').indexOf('723') === 0 && !values.acceptTerms)} variant="primary" width={1 / 2} type="submit">
                      Submit
                    </Button>
                  </Form>
                )}
              />
            </Flex>
          </Card>
        </ScrollAnimation>

        <Flex flex={1} textAlign="center" my={4}>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={350} style={{ margin: '0 auto' }}>
            <Text fontSize="18px" fontWeight={600} textAlign="center" mb={4} color="#344f79">
              Can't find your Serial Number?
              <br />
              Try looking here:
            </Text>
            <img src={openKitSerial} alt="" />
            <Text fontSize="18px" fontWeight={400} textAlign="center" mt={4} color="#344f79">
              Inside your test kit box,
              <br />
              next to the barcode
            </Text>
          </ScrollAnimation>
        </Flex>
      </Container>
    </div>
  );
};

export default compose(
  withToastManager,
  withRouter,
  withApollo,
  withAnalytics
)(RegisterKitEnter);
