import React, { useEffect, useState } from 'react';
import { Button, Card, Flex, Text, Box, Heading } from 'rebass';
import Select from 'react-select';
import styled from 'styled-components';
import { Drawer } from './adminBannerFilterBuilder/Drawer';
import { FilterEdit } from './adminBannerFilterBuilder/FilterEdit';
import { Group } from './adminBannerFilterBuilder/Group';
import {
  recursiveAddContent,
  recursiveChangeContent,
  recursiveChangeOperator,
  recursiveRemoveContent,
  recursiveRemoveGroup,
  recursiveReplaceContent,
} from './adminBannerFilterBuilder/dataOperationsUtils';
import ConfirmModal from '../components/ConfirmModal';
import { BannerTemplate } from './adminBannerFilterBuilder/BannerTemplate';
import { withRouter } from 'react-router-dom';
import { Mutation, Query } from 'react-apollo';
import PleaseWait from '../components/PleaseWait';
import gql from 'graphql-tag';
import { Input } from '../components/TextInput';
import { uuidv4 } from '../utils/uuid';
import { ADMIN_BANNERS_LIST } from './AdminBannerFilterList';
import { TestUsersDrawer } from './adminBannerFilterBuilder/TestUsersDrawer';
import { queryMatchFindAthenaIds } from '../utils/filterBannerQueryMatcher';

export const ADMIN_GET_BANNER_BY_ID = gql`
  query getAdminFilterBannerById($id: String!) {
    getAdminFilterBannerById(id: $id) {
      id
      name
      isPublished
      activeVersion
      versions {
        id
        version
        lastUpdated
        filter
        templateTitle
        templateBackground
        templateDescription
        templateReadMore
        bannerId
      }
    }
  }
`;

const ADMIN_CHANGE_BANNER_NAME = gql`
  mutation adminChangeFilteredBannerName($bannerId: String!, $name: String!) {
    adminChangeFilteredBannerName(bannerId: $bannerId, name: $name)
  }
`;

const ADMIN_PUBLISH_BANNER = gql`
  mutation adminPublishFilteredBanner($bannerId: String!) {
    adminPublishFilteredBanner(bannerId: $bannerId)
  }
`;

const ADMIN_UNPUBLISH_BANNER = gql`
  mutation adminUnpublishFilteredBanner($bannerId: String!) {
    adminUnpublishFilteredBanner(bannerId: $bannerId)
  }
`;

const ADMIN_NEW_VERSION = gql`
  mutation adminSaveNewBannerVersion($filter: String, $templateBackground: String, $templateDescription: String, $templateReadMore: String, $templateTitle: String, $bannerId: String!) {
    adminSaveNewBannerVersion(
      filter: $filter
      templateBackground: $templateBackground
      templateDescription: $templateDescription
      templateReadMore: $templateReadMore
      templateTitle: $templateTitle
      bannerId: $bannerId
    )
  }
`;

const ADMIN_OVERWRITE_VERSION = gql`
  mutation adminOverwriteBannerVersion($filter: String, $templateBackground: String, $templateDescription: String, $templateReadMore: String, $templateTitle: String, $versionId: String!) {
    adminOverwriteBannerVersion(
      filter: $filter
      templateBackground: $templateBackground
      templateDescription: $templateDescription
      templateReadMore: $templateReadMore
      templateTitle: $templateTitle
      versionId: $versionId
    )
  }
`;

const ADMIN_MAKE_VERSION_ACTIVE = gql`
  mutation adminSetFilteredBannerVersionAsActive($bannerId: String!, $versionId: String!) {
    adminSetFilteredBannerVersionAsActive(bannerId: $bannerId, versionId: $versionId)
  }
`;

const ADMIN_DUPLICATE_BANNER = gql`
  mutation adminDuplicateFilteredBanner($bannerId: String!, $name: String!) {
    adminDuplicateFilteredBanner(bannerId: $bannerId, name: $name)
  }
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  min-width: 1024px;
  max-height: 95vh;
  width: 100%;
  overflow-y: auto;
`;
const Container = styled(Flex)`
  width: 100%;
  height: 100%;
  max-width: 1024px;
  min-width: 1024px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: block;
  margin: 0 auto;
  padding: 24px;
`;

const SelectStyled = styled(Box)`
  .region-select__control {
    border: 1px solid rgba(53, 78, 121, 0.1);
    border-radius: 10px;
    padding: 0px 12px;
    background: #fff;
    min-width: 350px;
    font-size: 12px;
    max-width: 350px;

    &:hover {
      border-color: #eee;
    }

    &--is-focused {
      box-shadow: none;
    }

    .region-select__indicator-separator {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const formatOptionLabel = ({ value, label, isActiveVersion }) => (
  <Flex alignItems={'center'}>
    <div>{label}</div>
    {isActiveVersion && (
      <Box style={{ padding: '0 8px', border: '1px solid #CFCFCF', marginLeft: '8px', background: '#EBECEC', opacity: 1, fontSize: '12px', borderRadius: '8px', color: 'green' }}>active</Box>
    )}
  </Flex>
);

const FilterWrap = ({ selectedVersion, filters, setFilters, setisDrawerOpen, selectedTestUser, athenaIdsFiltered }) => {
  const [rndId, setRndId] = useState(uuidv4());
  const [groupHovered, setGroupHovered] = useState();

  const changeContent = (id, newContent) => {
    const tmp = JSON.parse(JSON.stringify(filters));
    const f = recursiveChangeContent(tmp, id, newContent);
    setFilters({ ...f, lastUpdate: new Date() });
  };

  const updateOperator = (id, operator) => {
    const tmp = JSON.parse(JSON.stringify(filters));
    const f = recursiveChangeOperator(tmp, id, operator);
    setFilters({ ...f, lastUpdate: new Date() });
  };
  useEffect(
    () => {
      setRndId(uuidv4());
    },
    [filters.data]
  );

  return (
    <Group
      depth={0}
      selectedTestUser={selectedTestUser}
      key={`${(selectedVersion || {}).value}_${filters.data.id}_${rndId}`}
      groupHovered={groupHovered}
      setGroupHovered={setGroupHovered}
      data={filters.data}
      onOperatorChange={updateOperator}
      onContentChanged={changeContent}
      athenaIdsFiltered={athenaIdsFiltered}
      addFilter={(e, x) => {
        setisDrawerOpen({
          parentId: e,
          edit: x,
        });
      }}
      onDelete={(id) => {
        const tmp = JSON.parse(JSON.stringify(filters));
        let f = recursiveRemoveContent(tmp, id);
        setFilters({ ...f, lastUpdate: new Date() });
      }}
      onDeleteGroup={(id) => {
        const tmp = JSON.parse(JSON.stringify(filters));
        let f = recursiveRemoveGroup(tmp, id);
        setFilters({ ...f, lastUpdate: new Date() });
      }}
    />
  );
};

const AdminBannerFilterBuilder = (props) => {
  const [isEditName, setIsEditName] = useState(false);
  const [newName, setNewName] = useState('');
  const [duplicateName, setDuplicateName] = useState('');
  const [title, setTitle] = useState();
  const [isDrawerOpen, setisDrawerOpen] = useState();
  const [isEditTemplate, setisEditTemplate] = useState();
  const [selectedVersion, setSelectedVersion] = useState();
  const [template, setTemplate] = useState();
  const [versions, setVersions] = useState();
  const [filters, setFilters] = useState();
  const [isDuplicateBanner, setIsDuplicateBanner] = useState(false);
  const [isTestsUsersDrawerVisible, setIsTestsUsersDrawerVisible] = useState(false);
  const [testUsers, setTestUsers] = useState([]);
  const [selectedTestUser, setSelectedTestUser] = useState();
  const [athenaIdsFiltered, setAthenaIdsFiltered] = useState();

  const uETemplate = () => {
    const { template, paramsVersion } = props;
    if (template) {
      setTitle(template.name);
      const vers = [];
      let activeVersion;
      if (template.versions) {
        for (let index = 0; index < template.versions.length; index++) {
          const element = template.versions[index];
          vers.push({
            label: `v${element.version} (${element.lastUpdated})`,
            value: element.id,
            isActiveVersion: element.id === template.activeVersion,
          });
          if (element.id === template.activeVersion) {
            activeVersion = element;
          }
        }
        if (!activeVersion) {
          activeVersion = template.versions[0];
        }
      }
      setVersions(vers);

      if (!paramsVersion) {
        props.history.push(`/AdminBannerFilterBuilder/${template.id}/${activeVersion.id}`);
        return;
      }
      
      for (let index = 0; index < template.versions.length; index++) {
        const element = template.versions[index];
        if (element.id === paramsVersion) {
          if (element.filter) {
            const filt = JSON.parse(element.filter.replace(/\\n/g, '<br />'));
            setFilters(filt.filters);
          } else {
            setFilters({ data: {} });
          }
          
          setTemplate({
            title: element.templateTitle,
            background: element.templateBackground,
            description: element.templateDescription,
            readMore: element.templateReadMore,
          });
          setSelectedVersion({
            label: `v${element.version} (${element.lastUpdated})`,
            value: element.id,
            isActiveVersion: element.id === template.activeVersion,
          });
        }
      }

      return;
    }
  };

  useEffect(() => {
    uETemplate();
  }, []);

  useEffect(
    () => {
      uETemplate();
    },
    [props.paramsVersion]
  );

  useEffect(
    () => {
      uETemplate();
    },
    [props.template]
  );

  useEffect(
    () => {
      if (!filters || !filters.data) return;
      try {
        const res = queryMatchFindAthenaIds(filters);
        if (!!res) {
          setAthenaIdsFiltered(res);
          setTestUsers([])
        } else {
          setAthenaIdsFiltered(null);
        }
      } catch (error) {
        setAthenaIdsFiltered(null);
      }
    },
    [filters]
  );

  useEffect(
    () => {
      if (!selectedVersion) return;
      if (!props.paramsVersion || props.paramsVersion !== selectedVersion.value) {
        props.history.push(`/AdminBannerFilterBuilder/${props.template.id}/${selectedVersion.value}`);
      }
    },
    [selectedVersion]
  );

  if (filters === undefined || filters.data === undefined) return null;
  
  return (
    <Container>
      <Card flex={1} style={{ padding: '24px', borderRadius: '24px' }}>
        <Flex>
          <Flex flex={1} alignItems={'center'}>
            <svg
              style={{ cursor: 'pointer' }}
              onClick={() => {
                props.history.push('/AdminBannerFilterList');
              }}
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.75 0C4.55109 0 4.36032 0.0790175 4.21967 0.21967C4.07902 0.360322 4 0.551088 4 0.75C4 0.948912 4.07902 1.13968 4.21967 1.28033C4.36032 1.42098 4.55109 1.5 4.75 1.5H15.25C15.4489 1.5 15.6397 1.42098 15.7803 1.28033C15.921 1.13968 16 0.948912 16 0.75C16 0.551088 15.921 0.360322 15.7803 0.21967C15.6397 0.0790175 15.4489 0 15.25 0H4.75ZM6.75 4.25C6.55109 4.25 6.36032 4.32902 6.21967 4.46967C6.07902 4.61032 6 4.80109 6 5C6 5.19891 6.07902 5.38968 6.21967 5.53033C6.36032 5.67098 6.55109 5.75 6.75 5.75H15.25C15.4489 5.75 15.6397 5.67098 15.7803 5.53033C15.921 5.38968 16 5.19891 16 5C16 4.80109 15.921 4.61032 15.7803 4.46967C15.6397 4.32902 15.4489 4.25 15.25 4.25H6.75ZM3.34 2.72C3.19937 2.57955 3.00875 2.50066 2.81 2.50066C2.61125 2.50066 2.42063 2.57955 2.28 2.72L0.53 4.47L0 5L0.53 5.53L2.28 7.28C2.34866 7.35369 2.43146 7.41279 2.52346 7.45378C2.61546 7.49477 2.71477 7.51682 2.81548 7.51859C2.91618 7.52037 3.01621 7.50184 3.1096 7.46412C3.20299 7.4264 3.28782 7.37026 3.35904 7.29904C3.43026 7.22782 3.4864 7.14299 3.52412 7.0496C3.56184 6.95621 3.58037 6.85618 3.57859 6.75548C3.57681 6.65478 3.55477 6.55546 3.51378 6.46346C3.47279 6.37146 3.41369 6.28866 3.34 6.22L2.12 5L3.34 3.78C3.48045 3.63937 3.55934 3.44875 3.55934 3.25C3.55934 3.05125 3.48045 2.86063 3.34 2.72ZM4.75 8.5C4.55109 8.5 4.36032 8.57902 4.21967 8.71967C4.07902 8.86032 4 9.05109 4 9.25C4 9.44891 4.07902 9.63968 4.21967 9.78033C4.36032 9.92098 4.55109 10 4.75 10H15.25C15.4489 10 15.6397 9.92098 15.7803 9.78033C15.921 9.63968 16 9.44891 16 9.25C16 9.05109 15.921 8.86032 15.7803 8.71967C15.6397 8.57902 15.4489 8.5 15.25 8.5H4.75Z"
                fill="#4E648A"
              />
            </svg>

            <Text ml={2} mr={2} fontWeight={600} fontSize={'22px'}>
              {props.template.name}
            </Text>
            <svg
              data-tip="Change flag"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setNewName(props.template.name);
                setIsEditName(true);
              }}
            >
              <path
                d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                fill="#F6A293"
              />
            </svg>
          </Flex>
          <Mutation
            mutation={props.template.isPublished ? ADMIN_UNPUBLISH_BANNER : ADMIN_PUBLISH_BANNER}
            variables={{
              bannerId: props.template.id,
            }}
            refetchQueries={[{ query: ADMIN_GET_BANNER_BY_ID, variables: { id: props.template.id } }]}
          >
            {(changeStatus, { loading }) => {
              return (
                <Flex
                  justifyContent={'center'}
                  alignItems={'center'}
                  style={{ border: `1px solid ${props.template.isPublished ? 'green' : 'orange'}`, borderRadius: '12px', cursor: 'pointer' }}
                  px={3}
                  py={1}
                  mr={4}
                  disabled={!!loading}
                  onClick={changeStatus}
                >
                  {!!loading ? (
                    <Text fontWeight={300} style={{ opacity: 0.5 }}>
                      {props.template.isPublished ? 'Unpublishing' : 'Publishing'}
                    </Text>
                  ) : (
                    <>
                      <Box style={{ height: '8px', width: '8px', borderRadius: '50%', backgroundColor: props.template.isPublished ? 'green' : 'orange' }} />
                      <Text ml={3} fontWeight={300} fontSize={'12px'} mr={3}>
                        {props.template.isPublished ? 'Live' : 'Not Live'}
                      </Text>
                      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_923_241)">
                          <path
                            d="M5.56768 0V7.51801H7.43232V0H5.56768ZM1.9035 1.9035C0.727231 3.07978 0 4.70507 0 6.5C0 10.0899 2.91015 13 6.5 13C10.0899 13 13 10.0899 13 6.5C13 4.70507 12.2728 3.07978 11.0965 1.9035L9.78729 3.21271C10.6286 4.05401 11.1489 5.21636 11.1489 6.5C11.1489 9.06729 9.06728 11.1489 6.5 11.1489C3.93271 11.1489 1.85113 9.06729 1.85113 6.5C1.85113 5.21636 2.37141 4.05401 3.21271 3.21271L1.9035 1.9035Z"
                            fill={!props.template.isPublished ? 'green' : 'orange'}
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_923_241">
                            <rect width="13" height="13" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </>
                  )}
                </Flex>
              );
            }}
          </Mutation>
          <Button
            variant={'primary'}
            style={{ padding: '8px 16px', fontSize: '12px', fontWeight: 300 }}
            onClick={(e) => {
              setIsDuplicateBanner(true);
            }}
          >
            Duplicate to new banner
          </Button>
        </Flex>

        <Box style={{ width: '100%', height: '1px', background: '#4E648A', opacity: 0.1 }} mt={3} mb={3} />
        {!!versions && (
          <Flex flexDirection={'column'}>
            <Text mb={3} fontWeight={600} fontSize={'14px'}>
              VERSIONS
            </Text>

            <Flex>
              <Flex flex={1}>
                <SelectStyled>
                  <Select
                    classNamePrefix="region-select"
                    options={versions}
                    name="primary_sex"
                    value={versions.find((x) => x && x.value === (selectedVersion || {}).value)}
                    placeholder="select version"
                    onChange={(option) => {
                      setSelectedVersion(option);
                    }}
                    formatOptionLabel={formatOptionLabel}
                  />
                </SelectStyled>

                {!!selectedVersion &&
                  !selectedVersion.isActiveVersion && (
                    <Mutation mutation={ADMIN_MAKE_VERSION_ACTIVE} refetchQueries={[{ query: ADMIN_GET_BANNER_BY_ID, variables: { id: props.template.id } }]} awaitRefetchQueries>
                      {(makeAsActive, { loading }) => {
                        return (
                          <Button
                            alignSelf={'center'}
                            onClick={() => {
                              makeAsActive({
                                variables: {
                                  bannerId: props.template.id,
                                  versionId: selectedVersion.value,
                                },
                              });
                            }}
                            disabled={loading}
                            ml={3}
                            variant={'outline'}
                            style={{ width: 'fit-content', paddingLeft: '16px', paddingRight: '16px', paddingTop: '8px', paddingBottom: '8px', borderWidth: '1px', fontSize: '12px', fontWeight: 300 }}
                          >
                            Make as active version
                          </Button>
                        );
                      }}
                    </Mutation>
                  )}
              </Flex>
              <Mutation mutation={ADMIN_OVERWRITE_VERSION} refetchQueries={[{ query: ADMIN_GET_BANNER_BY_ID, variables: { id: props.template.id } }]} awaitRefetchQueries>
                {(overwrite, { loading }) => {
                  return (
                    <Button
                      alignSelf={'center'}
                      mr={3}
                      disabled={loading}
                      variant={'pink'}
                      style={{ padding: '8px 16px', fontSize: '12px', fontWeight: 300 }}
                      onClick={(e) => {
                        overwrite({
                          variables: {
                            filter: JSON.stringify({ filters }),
                            templateBackground: template.background,
                            templateDescription: template.description,
                            templateReadMore: template.readMore,
                            templateTitle: template.title,
                            versionId: selectedVersion.value,
                          },
                        });
                      }}
                    >
                      Overwrite
                    </Button>
                  );
                }}
              </Mutation>
              <Mutation
                mutation={ADMIN_NEW_VERSION}
                refetchQueries={[{ query: ADMIN_GET_BANNER_BY_ID, variables: { id: props.template.id } }]}
                awaitRefetchQueries
                onCompleted={(e) => {
                  if (e.adminSaveNewBannerVersion) {
                    props.history.push(`/AdminBannerFilterBuilder/${props.template.id}/${e.adminSaveNewBannerVersion}`);
                  }
                }}
              >
                {(saveNew, { loading }) => {
                  return (
                    <Button
                      alignSelf={'center'}
                      variant={'primary'}
                      style={{ padding: '8px 16px', fontSize: '12px', fontWeight: 300 }}
                      onClick={(e) => {
                        saveNew({
                          variables: {
                            filter: JSON.stringify({ filters }),
                            templateBackground: template.background,
                            templateDescription: template.description,
                            templateReadMore: template.readMore,
                            templateTitle: template.title,
                            bannerId: props.template.id,
                          },
                        });
                      }}
                    >
                      Save to new
                    </Button>
                  );
                }}
              </Mutation>
            </Flex>
          </Flex>
        )}

        <Box style={{ width: '100%', height: '1px', background: '#4E648A', opacity: 0.1 }} mt={3} mb={3} />
        <Text mb={3} fontWeight={600} fontSize={'14px'}>
          TEMPLATE
        </Text>

        <Flex>
          <Card p={3} m={3} style={{ backgroundColor: (template || {}).background || 'white' }}>
            <Flex flexDirection={'column'}>
              <Text fontWeight={600}>{(template || {}).title}</Text>
              <Text
                mt={3}
                dangerouslySetInnerHTML={{
                  __html: `${(template || {}).description}`,
                }}
              />
              {!!(template || {}).readMore && (
                <Text textAlign={'right'} style={{ width: '100%', textDecoration: 'underline' }} mt={3}>
                  read more
                </Text>
              )}
            </Flex>
          </Card>
        </Flex>
        <Flex justifyContent={'flex-end'}>
          <Button
            mt={2}
            justifySelf={'flex-end'}
            variant={'primary'}
            style={{ padding: '8px 16px', fontSize: '12px', fontWeight: 300 }}
            onClick={(e) => {
              setisEditTemplate(true);
            }}
          >
            Edit Template
          </Button>
        </Flex>

        <Box style={{ width: '100%', height: '1px', background: '#4E648A', opacity: 0.1 }} mt={3} mb={3} />
        <Flex mb={3} alignItems={'center'}>
          <Text fontWeight={600} fontSize={'14px'} flex={1}>
            INCLUSION FILTERS
          </Text>
          {(!athenaIdsFiltered || athenaIdsFiltered.length < 1) && (
            <Button
              variant={'primary'}
              style={{ padding: '8px 16px', fontSize: '12px', fontWeight: 300 }}
              onClick={(e) => {
                setIsTestsUsersDrawerVisible(true);
              }}
            >
              Test Users
            </Button>
          )}
        </Flex>
        {!!selectedTestUser && (
          <Flex justifyContent={'center'}>
            <Box p={3} style={{ background: '#c258bf', color: 'white', fontSize: '13px', fontWeight: 300, cursor: 'pointer', borderRadius: '16px' }} onClick={() => setSelectedTestUser(null)}>
              testing with user{' '}
              <span style={{ fontWeight: 500 }}>
                {selectedTestUser.firstName} {selectedTestUser.lastName}
              </span>
              &nbsp;&nbsp;&nbsp;X
            </Box>
          </Flex>
        )}

        {!!athenaIdsFiltered && athenaIdsFiltered.length > 0 && (
          <Flex justifyContent={'center'}>
            <Box p={3} style={{ background: 'orange', color: 'white', fontSize: '13px', fontWeight: 300, borderRadius: '16px' }} >
              Template available only to following Athena Ids:{' '}
              <span style={{ fontWeight: 500 }}>
                {athenaIdsFiltered.join(' - ')}
              </span>
              <br />
              Any other filters present on the version <span style={{ fontWeight: 500 }}>won't be used</span>
            </Box>
          </Flex>
        )}

        <Flex flexDirection={'column'}>
          <FilterWrap
            key={!!selectedTestUser ? `tstusr_${selectedTestUser.id}` : `${new Date()}`}
            filters={filters}
            selectedVersion={selectedVersion}
            setFilters={setFilters}
            setisDrawerOpen={setisDrawerOpen}
            selectedTestUser={selectedTestUser}
            athenaIdsFiltered={athenaIdsFiltered}
          />
        </Flex>
      </Card>
      <Drawer isOpen={isDrawerOpen && isDrawerOpen.parentId} onClose={() => setisDrawerOpen(null)}>
        <FilterEdit
          parentId={(isDrawerOpen || {}).parentId}
          edit={(isDrawerOpen || {}).edit}
          onSave={(parentId, itemToEditId, res) => {
            const tmp = JSON.parse(JSON.stringify(filters));
            let f;
            if (!itemToEditId) {
              f = recursiveAddContent(tmp, parentId, res);
            } else {
              f = recursiveReplaceContent(tmp, itemToEditId, res);
            }

            setFilters({ ...f, lastUpdate: new Date() });
            setisDrawerOpen(null);
          }}
        />
      </Drawer>

      <Drawer isOpen={isTestsUsersDrawerVisible} onClose={() => setIsTestsUsersDrawerVisible(false)}>
        <TestUsersDrawer testUsers={testUsers} setTestUsers={setTestUsers} filters={filters} selectedTestUser={selectedTestUser} setSelectedTestUser={setSelectedTestUser} />
      </Drawer>

      <ConfirmModal
        childrenManaged
        isOpen={isEditTemplate}
        okOnly
        onClose={() => {
        }}
        onConfirm={() => {
        }}
        onBackgroundClick={() => {
        }}
        onEscapeKeydown={() => {
        }}
      >
        <ModalInner id="mi">
          <BannerTemplate
            template={template}
            onSave={(e) => {
              setisEditTemplate(false);
              setTemplate(e);
            }}
            onCancel={() => {
              setisEditTemplate(false);
            }}
          />
        </ModalInner>
      </ConfirmModal>
      {!!isEditName && (
        <ConfirmModal
          childrenManaged
          isOpen={isEditName}
          okOnly
          onClose={() => {
          }}
          onConfirm={() => {
          }}
          onBackgroundClick={() => {
            setIsEditName(false);
          }}
          onEscapeKeydown={() => {
            setIsEditName(false);
          }}
        >
          <ModalInner id="mi">
            <Flex flexDirection={'column'}>
              <Heading>Edit filtered banner</Heading>

              <Text mt={4} mb={2}>
                Name
              </Text>
              <Input style={{ fontSize: '13px', padding: '12px' }} value={newName} onChange={(e) => setNewName(e.target.value)} />
              <Mutation
                mutation={ADMIN_CHANGE_BANNER_NAME}
                variables={{
                  bannerId: props.template.id,
                  name: newName,
                }}
                onCompleted={(e) => {
                  if (e && e.adminChangeFilteredBannerName) {
                    setIsEditName(false);
                  }
                }}
                refetchQueries={[{ query: ADMIN_GET_BANNER_BY_ID, variables: { id: props.template.id } }, { query: ADMIN_BANNERS_LIST }]}
              >
                {(createBanner, { loading }) => {
                  if (loading) return <PleaseWait />;
                  return (
                    <Flex justifyContent={'center'} alignItems={'center'} mt={4}>
                      <Button
                        variant={'outline'}
                        style={{ width: 'fit-content', paddingLeft: '16px', paddingRight: '16px', paddingTop: '8px', paddingBottom: '8px', borderWidth: '1px', fontSize: '12px', fontWeight: 300 }}
                        onClick={() => setIsEditName(false)}
                      >
                        Cancel
                      </Button>
                      <Button variant={'primary'} ml={3} disabled={!newName} onClick={createBanner} style={{ padding: '8px 16px', fontSize: '12px', fontWeight: 300 }}>
                        Save
                      </Button>
                    </Flex>
                  );
                }}
              </Mutation>
            </Flex>
          </ModalInner>
        </ConfirmModal>
      )}
      {!!isDuplicateBanner && (
        <ConfirmModal
          childrenManaged
          isOpen={isDuplicateBanner}
          okOnly
          onClose={() => {
          }}
          onConfirm={() => {
          }}
          onBackgroundClick={() => {
            setIsDuplicateBanner(false);
          }}
          onEscapeKeydown={() => {
            setIsDuplicateBanner(false);
          }}
        >
          <ModalInner id="mi">
            <Flex flexDirection={'column'}>
              <Heading>Duplicate filtered banner</Heading>

              <Flex flexDirection={'column'} style={{ background: '#eee', border: '1px solid #dd8f00' }} p={3} mt={4}>
                <Text style={{ color: '#dd8f00' }} fontWeight={500} mb={3}>
                  Warning
                </Text>
                <Text fontWeight={300} fontSize={'14px'}>
                  Will create a duplicate of the filtered banner using the <span style={{ fontWeight: 500 }}>current active version as saved in local DB</span>. Any current unsaved changes will be
                  lost
                </Text>
              </Flex>

              <Text mt={4} mb={2} fontSize={'14px'}>
                Name
              </Text>
              <Input style={{ fontSize: '13px', padding: '12px' }} value={duplicateName} onChange={(e) => setDuplicateName(e.target.value)} />
              <Mutation
                mutation={ADMIN_DUPLICATE_BANNER}
                variables={{
                  bannerId: props.template.id,
                  name: duplicateName,
                }}
                onCompleted={(e) => {
                  if (e && e.adminDuplicateFilteredBanner) {
                    props.history.push(`/AdminBannerFilterBuilder/${e.adminDuplicateFilteredBanner}`);
                  }
                }}
                refetchQueries={[{ query: ADMIN_BANNERS_LIST }]}
                awaitRefetchQueries
              >
                {(createBanner, { loading }) => {
                  if (loading) return <PleaseWait />;
                  return (
                    <Flex justifyContent={'center'} alignItems={'center'} mt={4}>
                      <Button
                        variant={'outline'}
                        style={{ width: 'fit-content', paddingLeft: '16px', paddingRight: '16px', paddingTop: '8px', paddingBottom: '8px', borderWidth: '1px', fontSize: '12px', fontWeight: 300 }}
                        onClick={() => setIsEditName(false)}
                      >
                        Cancel
                      </Button>
                      <Button variant={'primary'} ml={3} disabled={!duplicateName} onClick={createBanner} style={{ padding: '8px 16px', fontSize: '12px', fontWeight: 300 }}>
                        Save
                      </Button>
                    </Flex>
                  );
                }}
              </Mutation>
            </Flex>
          </ModalInner>
        </ConfirmModal>
      )}
    </Container>
  );
};

const AdminBannerFilterBuilderWrapper = (props) => {
  console.log({ props });
  if (!props.match || !props.match.params || !props.match.params.id) {
    props.history.push(`/AdminBannerFilterList`);
    return null;
  }

  return (
    <Query
      query={ADMIN_GET_BANNER_BY_ID}
      variables={{
        id: props.match.params.id,
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (!!loading) return <PleaseWait text={' '} />;
        return <AdminBannerFilterBuilder template={data.getAdminFilterBannerById} paramsVersion={props.match.params.versionId} history={props.history} />;
      }}
    </Query>
  );
};

export default withRouter(AdminBannerFilterBuilderWrapper);
