import React, { Fragment } from 'react';
import { Flex, Text, Button, Box, Heading } from 'rebass';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import BuyAKitIcon from '../../static/BuyAKitIcon.png';
import telemedicineIcon from '../../static/telemedicine2.png';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;
const Meno = (props) => {
  const {
    session: { eligibility, intakeStatus },
  } = props;
  return (
    <Fragment>
      <Container flex={1} px={3}>
        <Flex flex={1} textAlign="center">
          <ScrollAnimation animateIn="fadeInDown" style={{ margin: '0 auto' }} animateOnce={true}>
            <Heading style={{ marginBottom: '20px' }} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
              Specialized Menopause Care When Thyroid Treatment Isn’t an Option
            </Heading>
          </ScrollAnimation>
        </Flex>
        <Flex flex={1} textAlign="center">
          <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={700} style={{ margin: '0 auto' }}>
            <Text fontSize="18px" fontWeight={400} textAlign="center" mb={4} color="#344f79">
              You can still get menopause care via Paloma!
            </Text>
          </ScrollAnimation>
        </Flex>

        <Flex flex={1} width={[1, 1, 1 / 2]} flexDirection="column" mb={4} style={{ margin: '0 auto' }}>
          <div style={{ textAlign: 'center' }}>
            <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
              <Flex mb={3} justifyContent="center">
                <img src={telemedicineIcon} alt="" height="250px" />
              </Flex>

              <Text textAlign="center" lineHeight={1.6} fontSize="18px" fontWeight={300} color="#344f79">
                Wave goodbye to brain fog, hot flashes, poor sleep, and vaginal dryness with our expert menopause care, comfortably at home. Our specialized menopause providers are dedicated to
                compassionate, personalized care, all from the convenience of your home. Take control of your well-being with our supportive and understanding treatment experience.
              </Text>

              <Button style={{ width: '250px', marginTop: '24px' }} variant="outline" onClick={() => props.history.push('/schedulemeno')}>
                Get Started
              </Button>
            </ScrollAnimation>
          </div>
        </Flex>
      </Container>
    </Fragment>
  );
};

const NoAppointment = (props) => {
  const {
    history,
    session: { eligibility, intakeStatus, gender },
  } = props;

  if (intakeStatus === 'disqualified') {
    if (gender !== 'F') {
      history.push('/');
      return <div />
    }
    return <Meno {...props} />;
  }
  return (
    <Fragment>
      <Container flex={1} px={3}>
        <Flex flex={1} textAlign="center">
          <ScrollAnimation animateIn="fadeInDown" style={{ margin: '0 auto' }} animateOnce={true}>
            <Heading style={{ marginBottom: '20px' }} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
              {eligibility && (eligibility.status === 'consultation_required' || eligibility.status === 'lab_processing' || eligibility.status === 'new_patient_diagnosed')
                ? 'Get Expert Thyroid Care Without The Headaches'
                : 'Get Expert Thyroid Care Without The Headaches?'}
            </Heading>
            {/* <Text
              fontSize="38px"
              fontWeight={600}
              textAlign="center"
              mb={4}
              color="#344f79"
            >
              Ready For Your Online Visit?
            </Text> */}
          </ScrollAnimation>
        </Flex>
        <Flex flex={1} textAlign="center">
          <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={700} style={{ margin: '0 auto' }}>
            <Text fontSize="18px" fontWeight={400} textAlign="center" mb={4} color="#344f79">
              {eligibility && (eligibility.status === 'consultation_required' || eligibility.status === 'lab_processing' || eligibility.status === 'new_patient_diagnosed')
                ? ''
                : `Here's how it works`}
            </Text>
          </ScrollAnimation>
        </Flex>

        {(() => {
          switch (eligibility.status) {
            case 'lab_processing':
              return (
                <Flex flex={1} width={[1, 1, 1 / 2]} flexDirection="column" mb={4} style={{ margin: '0 auto' }}>
                  <div style={{ textAlign: 'center' }}>
                    <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                      <Flex mb={3} justifyContent="center">
                        <img src={telemedicineIcon} alt="" height="250px" />
                      </Flex>

                      <Text textAlign="center" lineHeight={1.6} fontSize="18px" fontWeight={300} color="#344f79">
                        Our team of thyroid providers provide you with quality, proven thyroid care - all through an online experience. Get fast, effective medical treatment and prescriptions at your
                        fingertips.
                      </Text>

                      <Button style={{ width: '250px', marginTop: '24px', marginBottom: '36px' }} variant="outline" onClick={() => props.history.push('/schedule-split')}>
                        Get Started
                      </Button>
                    </ScrollAnimation>
                  </div>
                </Flex>
              );
            case 'new_patient_diagnosed':
              return (
                <Flex flex={1} width={[1, 1, 1 / 2]} flexDirection="column" mb={4} style={{ margin: '0 auto' }}>
                  <div style={{ textAlign: 'center' }}>
                    <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                      <Flex mb={3} justifyContent="center">
                        <img src={telemedicineIcon} alt="" height="250px" />
                      </Flex>

                      <Text textAlign="center" lineHeight={1.6} fontSize="18px" fontWeight={300} color="#344f79">
                        Our team of thyroid providers provide you with quality, proven thyroid care - all through an online experience. Get fast, effective medical treatment and prescriptions at your
                        fingertips.
                      </Text>

                      <Button style={{ width: '250px', marginTop: '24px' }} variant="outline" onClick={() => props.history.push('/schedule-split')}>
                        Get Started
                      </Button>
                    </ScrollAnimation>
                  </div>
                </Flex>
                // <Fragment>
                //   <Flex flexDirection={['column', 'column', 'row']}>
                //     <Flex flex={1} mr={[0, 0, 4]} mb={4}>
                //       <div style={{ textAlign: 'left' }}>
                //         <ScrollAnimation
                //           animateIn="fadeInUpFrom20"
                //           animateOnce={true}
                //         >
                //           <Flex mb={3} justifyContent="left">
                //             <Image>
                //               <img
                //                 src={LabResultsQuestion}
                //                 alt=""
                //                 height="200px"
                //               />
                //             </Image>
                //           </Flex>
                //           <Text
                //             textAlign="left"
                //             mb={4}
                //             lineHeight={1.6}
                //             fontSize="22px"
                //             fontWeight={600}
                //             color="#344f79"
                //           >
                //             You Have Recent Lab Results?
                //           </Text>

                //           <Text
                //             textAlign="left"
                //             lineHeight={1.6}
                //             fontSize="18px"
                //             fontWeight={300}
                //             color="#344f79"
                //           >
                //             If you have recent thyroid hormones blood results,
                //             you can upload them and you will be able to book a
                //             consultation.
                //           </Text>

                //           <Button
                //             style={{ width: '250px', marginTop: '24px' }}
                //             variant="outline"
                //             onClick={() =>
                //               props.history.push('/uploadLabResults')
                //             }
                //           >
                //             Upload Lab Results
                //           </Button>
                //         </ScrollAnimation>
                //       </div>
                //     </Flex>
                //     <Flex width={[1, 1, 1 / 2]} flexDirection="column" mb={4}>
                //       <div style={{ textAlign: 'left' }}>
                //         <ScrollAnimation
                //           animateIn="fadeInUpFrom20"
                //           animateOnce={true}
                //         >
                //           <Flex mb={3} justifyContent="left">
                //             <img src={BuyAKitIcon} alt="" height="200px" />
                //           </Flex>
                //           <Text
                //             textAlign="left"
                //             mb={4}
                //             lineHeight={1.6}
                //             fontSize="22px"
                //             fontWeight={600}
                //             color="#344f79"
                //           >
                //             You Don't Have Recent Lab Results?
                //           </Text>

                //           <Text
                //             textAlign="left"
                //             lineHeight={1.6}
                //             fontSize="18px"
                //             fontWeight={300}
                //             color="#344f79"
                //           >
                //             You can buy one of our at-home blood kits and you
                //             will be able to book a consultation once your
                //             results are in.
                //           </Text>

                //           <Button
                //             style={{ width: '250px', marginTop: '24px' }}
                //             variant="outline"
                //             onClick={() => props.history.push('/purchase')}
                //           >
                //             Buy a Kit
                //           </Button>
                //         </ScrollAnimation>
                //       </div>
                //     </Flex>
                //   </Flex>
                // </Fragment>
              );
            case 'consultation_required':
              return (
                <Flex flex={1} width={[1, 1, 1 / 2]} flexDirection="column" mb={4} style={{ margin: '0 auto' }}>
                  <div style={{ textAlign: 'center' }}>
                    <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                      <Flex mb={3} justifyContent="center">
                        <img src={telemedicineIcon} alt="" height="250px" />
                      </Flex>

                      <Text textAlign="center" lineHeight={1.6} fontSize="18px" fontWeight={300} color="#344f79">
                        Our team of thyroid providers provide you with quality, proven thyroid care - all through an online experience. Get fast, effective medical treatment and prescriptions at your
                        fingertips.
                      </Text>

                      <Button style={{ width: '250px', marginTop: '24px' }} variant="outline" onClick={() => props.history.push('/schedule-split')}>
                        Get Started
                      </Button>
                    </ScrollAnimation>
                  </div>
                </Flex>
              );
            default:
              return (
                <Flex flex={1} width={[1, 1, 1 / 2]} flexDirection="column" mb={4} style={{ margin: '0 auto' }}>
                  <div style={{ textAlign: 'center' }}>
                    <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                      <Flex mb={3} justifyContent="center">
                        <img src={telemedicineIcon} alt="" height="250px" />
                      </Flex>

                      <Text textAlign="center" lineHeight={1.6} fontSize="18px" fontWeight={300} color="#344f79">
                        Our team of thyroid providers provide you with quality, proven thyroid care - all through an online experience. Get fast, effective medical treatment and prescriptions at your
                        fingertips.
                      </Text>

                      <Button style={{ width: '250px', marginTop: '24px' }} variant="outline" onClick={() => props.history.push('/schedule-split')}>
                        Get Started
                      </Button>
                    </ScrollAnimation>
                  </div>
                </Flex>
              );
          }
        })()}
      </Container>
    </Fragment>
  );
};
export default NoAppointment;
