import React, { useState } from 'react';
import { Heading, Text, Flex, Card, Box, Image, Button } from 'rebass';
import { Link } from 'react-router-dom';
import doctor from '../../static/Doctor_Female_White_shirt_2.png';
import discoverPossibleCauses from '../../static/Character_2.png';
import { MembershipBulletItem } from '../../components/MembershipBulletItem';
import { withWizard } from 'react-albus';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { isToggleActive } from '../../components/featureToggle/toggles';
import Modal, { BaseModalBackground } from 'styled-react-modal';
import { useMount } from '../../utils/hooks/lifecycle/useMount';
import { getMemberVisitPrice } from '../../utils/pricing';
import { Constants } from '../../utils/constants';

const A = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: #44a5ff;
  text-decoration: underline;
  cursor: pointer;
`;

const FakeButton = styled(Box)`
  height: 40px;
  background: #354e79;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  margin-top: 24px;
  width: 250px;
`;
const SpecialModalBackground = styled(BaseModalBackground)`
  backdrop-filter: blur(5px);
`;

const BecomeAMember = ({ insuranceAccepted, insuranceForced, hasInNetwork }) => {
  const hasNewPriceFeature = isToggleActive('USE_NEW_PRICING');
  const t = isToggleActive('INSURANCE_MEMBERSHIP_WIND_DOWN');
  return (
    <>
      <Heading>Become a Member</Heading>
      <Text mt="8px" fontWeight="300">
        <span style={{ fontWeight: 600 }}>
          {!insuranceForced || !hasInNetwork || !!t ? (
            `$${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}`
          ) : (
            <>
              <span
                style={{
                  textDecoration: 'line-through',
                }}
              >
                {`$${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}`}
              </span>
              <span>&nbsp;$96</span>
            </>
          )}
        </span>{' '}
        billed annually
      </Text>
       
      {(!insuranceForced || !hasInNetwork || !!t) && <quadpay-widget-v3 amount={`${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}`} merchantId={window._env_.REACT_APP_QUADPAY_MERCHANTID} alignment="left" widgetVerbiage="4-easy-payments" />}
      {/* {(!insuranceForced || !hasInNetwork) && <zip-payment-widget amount= "192" merchantId={window._env_.REACT_APP_QUADPAY_MERCHANTID} > </zip-payment-widget>} */}
  {/* <quadpay-widget-v3 amount="192" alignment="left" widgetVerbiage="4-easy-payments" />} */}
      {!insuranceAccepted || !!t ? (
        <>
          <Flex flexDirection="row" style={{ marginTop: '4px' }}>
            <img src={doctor} height="77px" alt="" />
            <Flex flexDirection="column" justifyContent="flex-end">
              <Text style={{ fontWeight: 600 }}>Visit</Text>
              <Flex flexDirection="row" alignItems="baseline">
                {!isToggleActive('MEMBERSHIP_ONLY') && (
                  <Text
                    style={{
                      fontWeight: 600,
                      textDecoration: 'line-through',
                      marginRight: '6px',
                    }}
                  >
                    {`$${hasNewPriceFeature ? '110' : '99'}`}
                  </Text>
                )}
                <Text style={{ fontWeight: 600, fontSize: '20px' }}>
                  ${getMemberVisitPrice()}
                  {isToggleActive('MEMBERSHIP_ONLY') ? <span style={{ fontSize: '14px', fontWeight: 300 }}> cash or your copay</span> : ''}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Text
            style={{
              fontWeight: 500,
              fontSize: '16px',
              marginTop: '28px',
              marginBottom: '16px',
            }}
          >
            And get access to:
          </Text>
          <MembershipBulletItem text="Dedicated Care manager" />
          <MembershipBulletItem text="Unlimited Doctor Chats" />
          <MembershipBulletItem text="Easy Refills " />
          <MembershipBulletItem text="Cancel Anytime" style={{ maxWidth: '150px' }} />

          <img
            alt=""
            src={discoverPossibleCauses}
            style={{
              position: 'absolute',
              bottom: '62px',
              right: '12px',
              width: '140px',
            }}
          />
          {/* {!isMobile && ( */}
          {/* <FakeButton>
            Join {isToggleActive('MEMBERSHIP_ONLY') ? 'to Book' : 'Now'}
          </FakeButton> */}
          {/* )} */}
        </>
      ) : (
        <>
          <Text mt="16px" fontWeight="300">
            The hypothyroid membership loaded with perks and discounts
          </Text>
          <Text
            style={{
              fontWeight: 500,
              fontSize: '16px',
              marginTop: '28px',
              marginBottom: '16px',
            }}
          >
            And get access to:
          </Text>
          <MembershipBulletItem style={{ width: '90%', marginBottom: '22px' }} text="Exclusive discounts on all our services and products" />
          <MembershipBulletItem style={{ width: '90%', marginBottom: '22px' }} text="Members-only resources and community" />
          <MembershipBulletItem style={{ width: '90%', marginBottom: '22px' }} text="Symptom tracker application" />
        </>
      )}
      <FakeButton>Join {isToggleActive('MEMBERSHIP_ONLY') ? 'to Book' : 'Now'}</FakeButton>
    </>
  );
};

const SelectMembership = ({ wizard, onContinue, onSubscribe, insuranceAccepted, canSkip, isInNetwork, hasInNetwork, onGoBack }) => {
  const [isConfirmOutNetworkInsuranceModal, setIsConfirmOutNetworkInsuranceModal] = useState();
  useMount(() => {
    console.log('Mount', { isInNetwork, hasInNetwork });
    setIsConfirmOutNetworkInsuranceModal(!isInNetwork && !!hasInNetwork);
  });
  const handleClose = () => {
    setIsConfirmOutNetworkInsuranceModal(false);
  };
  return (
    <Flex flexDirection="column" alignItems="center" pb="95px">
      {isToggleActive('MEMBERSHIP_ONLY') ? <Heading textAlign="center">Video Visits Reserved for Members Only</Heading> : <Heading textAlign="center">Give Your Thyroid Superpowers!</Heading>}
      {!isToggleActive('MEMBERSHIP_ONLY') ? (
        <Text textAlign="center" style={{ marginTop: '24px' }}>
          Explore our membership option and save $57 on your video visit.
        </Text>
      ) : (
        <Text textAlign="center" style={{ marginTop: '24px' }}>
          Get concierge care to support every step of your thyroid journey.
        </Text>
      )}

      {/* <Flex flexDirection="row" style={{ marginTop: '32px' }}> */}
      <Flex flexDirection={['column', 'row']}>
        <Card
          style={{
            position: 'relative',
            padding: '18px',
            margin: '12px auto',
            cursor: 'pointer',
            width: '300px',
            zIndex: 1,
            height: 'fit-content',
          }}
          onClick={() => {
            if (onSubscribe) {
              onSubscribe(wizard);
            }
          }}
        >
          <svg width="216" height="193" viewBox="0 0 216 193" fill="none" style={{ position: 'absolute', zIndex: -1, top: 0, left: 0 }} xmlns="http://www.w3.org/2000/svg">
            <path
              d="M200.431 141.828C188.829 156.058 174.345 165.629 158.855 173.61C121.528 192.844 82.2117 197.419 41.1933 188.885C32.8552 187.151 24.3359 186.235 15.8704 185.188C0.732343 183.313 -13.2054 178.36 -24.5777 168.012C-37.5196 156.236 -46.8009 141.73 -53.0749 125.393C-65.3319 93.4707 -68.9271 60.8566 -60.1484 27.4045C-57.0406 15.5568 -50.9512 5.13435 -44.6573 -5.27518C-35.3071 -20.7471 -22.4797 -32.7319 -8.15858 -43.2071C0.696121 -49.683 11.2666 -52.2324 22.0077 -53.3318C63.932 -57.6135 105.094 -53.769 145.167 -40.4526C152.432 -38.0381 160.175 -35.1781 165.871 -30.3513C174.238 -23.0118 181.786 -14.7831 188.383 -5.80966C199.755 9.16994 205.968 26.6579 209.888 44.9347C214.073 64.4562 217.517 84.1066 215.313 104.198C213.766 118.256 207.98 130.723 200.431 141.828Z"
              fill="#E7F0FF"
              fill-opacity="0.35"
            />
          </svg>
          {!onSubscribe ? (
            <Link
              style={{ flex: 1, textDecoration: 'none' }}
              to={{
                pathname: '/register',
                // search: "?sort=name",
                // hash: "#the-hash",
                state: { subscribe: true, continueWith: '/schedule' },
              }}
            >
              <BecomeAMember insuranceAccepted={insuranceAccepted} insuranceForced={isInNetwork} hasInNetwork={hasInNetwork} />
            </Link>
          ) : (
            <BecomeAMember insuranceAccepted={insuranceAccepted} insuranceForced={isInNetwork} hasInNetwork={hasInNetwork} />
          )}
        </Card>
        {!!insuranceAccepted && (
          <>
            <Card
              style={{
                position: 'relative',
                background: 'transparent',
                padding: '14px',
                margin: '12px',
                paddingBottom: isMobile ? '172px' : '24px',
                boxShadow: 'none',
                marginBottom: '30px',
                maxWidth: '400px',
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  marginBottom: '16px',
                }}
              >
                Also included in your membership:
              </Text>
              <MembershipBulletItem showBullet={false} text="• The Paloma private community" />
              <MembershipBulletItem showBullet={false} text="• Live doctor Q&A" />
              <MembershipBulletItem showBullet={false} text="• Online courses on thyroid health" />
              <MembershipBulletItem showBullet={false} text="• Monthly Speaker Series " />
              <MembershipBulletItem showBullet={false} text="• Cancel anytime" />
              <Image
                src={discoverPossibleCauses}
                mt={['16px', '']}
                mb={['16px', '']}
                sx={{
                  position: ['relative', 'absolute'],
                  bottom: '12px',
                  left: ['0', '-76px'],
                  width: '270px',
                }}
                style={{}}
              />
            </Card>
          </>
        )}
      </Flex>
      <A
        style={{
          color: isToggleActive('MEMBERSHIP_ONLY') && !canSkip ? 'transparent' : '',
        }}
        onClick={() => {
          onContinue(wizard);
        }}
      >
        Not Interested
      </A>
      <Modal isOpen={isConfirmOutNetworkInsuranceModal} onBackgroundClick={handleClose} onEscapeKeydown={handleClose}>
        {/* <SpecialModalBackground> */}
          <Box
            style={{
              margin: '0 auto',
              alignSelf: 'center',
              borderRadius: '20px',
              position: 'relative',
              backgroundColor: 'white',
              padding: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              maxWidth: '80vw',
              width: '400px'
            }}
          >
            <Heading mb={4} textAlign={'center'}>This Provider is Out-of-Network</Heading>
            <Text mb={3} textAlign="center">
              You will not be eligible to the insurance membership if you select an out-of-network provider.
            </Text>
            <Text mb={4} textAlign="center">
              Do you wish to proceed with the selected provider?
            </Text>
            <Flex flexDirection={'row'} justifyContent="space-between" width={'100%'}>
              <Button
                variant={'pink'}
                onClick={() => {
                  if (onGoBack) {
                    onGoBack(wizard);
                  }
                }}
                mt={4}
              >
                Change Provider
              </Button>
              <Button variant={'primary'} onClick={handleClose} mt={4}>
                Continue
              </Button>
            </Flex>
            <Box
              onClick={handleClose}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                height: '34px',
                width: '34px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F8A294',
                padding: '6px',
                borderRadius: '50%',
                color: 'white',
              }}
            >
              X
            </Box>
          </Box>
        {/* </SpecialModalBackground> */}
      </Modal>
      {/* {!isMobile && */}
      {/* {!!insuranceAccepted && (
        <FakeButton
          onClick={() => {
            if (onSubscribe) {
              onSubscribe(wizard);
            }
          }}
        >
          Join {isToggleActive('MEMBERSHIP_ONLY') ? 'to Book' : 'Now'}
        </FakeButton>
      )} */}
    </Flex>
  );
};

export default withWizard(SelectMembership);
