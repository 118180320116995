import React, { Fragment } from 'react';
import { Flex, Text, Button, Box } from 'rebass';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import thyroidIcon from '../../../static/thyroid.png';
import palomaKit from '../../../static/palomaKit.png';
import painFree from '../../../static/painFree.png';
import resultsCanTrust from '../../../static/resultsCanTrust.png';
import ongoingAdvice from '../../../static/ongoingAdvice.png';
import iconBackgroundShape from '../../../static/iconBackgroundShape.png';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 96px;
`;

const Biomarker = styled.div`
  border-radius: 15px;
  border: 1px solid #f1a493;
  color: #344f79;
  display: inline-block;
  margin-right: 12px;
  padding: 5px 16px;
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  z-index: 3;
  display: none;
  width: 250px;
  min-height: 5px;
  padding: 10px;
  border-radius: 30px;
  background-color: #344f79;
  bottom: 120%;
  left: -108px;
  font-weight: 300;
  color: white;
  padding: 10;
  text-align: center;
  font-size: 14px;
  ${Biomarker}:hover & {
    display: block;
  }
`;

const Image = styled.div`
  &::before {
    float: left;
    border-radius: 0px;
    background-image: url(${iconBackgroundShape});
    background-position: 0px 0px;
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    height: 110px;
    width: 100%;
  }
`;

const DesktopOnly = styled(Flex)`
  @media screen and (max-width: 52em) {
    display: none;
  }
`;

const MobileView = styled(Flex)`
  flex: 1;
  justify-content: center;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const ByAKit = props => (
  <Fragment>
    <Container flex={1} px={3}>
      <Flex flex={1} mr={[0, 0, 0]} mb={3}>
        <ScrollAnimation
          animateIn="fadeInUpFrom40"
          animateOnce={true}
          style={{ textAlign: 'center', height: '100%', width: '100%' }}
        >
          <div style={{ textAlign: 'center' }}>
            {/* <div
                  style={{
                    backgroundImage: `url(${palomaKit})`,
                    width: '130%',
                    height: '100%',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginLeft: '-30%',
                  }}
                /> */}
            <img
              alt=""
              src={thyroidIcon}
              style={{
                width: '200px',
                // height: '100%',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                marginBottom: '24px',
                // marginLeft: '-30%',
              }}
            />
          </div>
          <Text
            fontSize="38px"
            fontWeight={600}
            mb={3}
            textAlign="center"
            color="#344f79"
            lineHeight="44px"
            style={{ fontFamily: 'Playfair Display' }}
          >
            You can still use our at home thyroid test kit{' '}
            {props && props.patientState && props.patientState.label
              ? `in`
              : ''}
            <br />{' '}
            {props && props.patientState && props.patientState.label
              ? `${props.patientState.label}`
              : ''}
          </Text>
          <Text
            textAlign="center"
            lineHeight="24px"
            fontSize="18px"
            fontWeight={200}
            color="#344f79"
            mb="36px"
          >
            Our thyroid at-home test can help you understand how your thyroid is
            working and if there may be a need for a further evaluation of your
            thyroid function. Expect your results in one week.
          </Text>

          <Button
            width="auto"
            variant="primary"
            style={{ textAlign: 'center' }}
            onClick={() => props.history.push('/order-kit')}
            mb={4}
          >
            Buy a Kit
          </Button>
          <div
            style={{
              background: '#344f79',
              width: '100%',
              height: '2px',
              margin: '12px 0 36px',
            }}
          />
        </ScrollAnimation>
      </Flex>
      <Flex flexDirection={['column', 'column', 'row']}>
        <Flex flex={1} mr={[0, 0, 4]} mb={3}>
          <DesktopOnly>
            <ScrollAnimation
              animateIn="fadeInUpFrom40"
              animateOnce={true}
              style={{ textAlign: 'left', height: '100%', width: '100%' }}
            >
              <div style={{ textAlign: 'left', height: '100%', width: '100%' }}>
                {/* <div
                  style={{
                    backgroundImage: `url(${palomaKit})`,
                    width: '130%',
                    height: '100%',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginLeft: '-30%',
                  }}
                /> */}
                <img
                  alt=""
                  src={palomaKit}
                  style={{
                    width: '90%',
                    // height: '100%',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    // marginLeft: '-30%',
                  }}
                />
              </div>
            </ScrollAnimation>
          </DesktopOnly>
          <MobileView>
            <ScrollAnimation
              animateIn="fadeInUpFrom40"
              animateOnce={true}
              style={{ textAlign: 'center', height: '50vh', width: '100%' }}
            >
              <div style={{ textAlign: 'left', height: '100%', width: '100%' }}>
                <img
                  alt=""
                  src={palomaKit}
                  style={{
                    width: '90%',
                    // height: '100%',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    // marginLeft: '-30%',
                  }}
                />
                {/* <div
                  style={{
                    backgroundImage: `url(${palomaKit})`,
                    // width: '130%',
                    height: '100%',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    // marginLeft: '-30%',
                  }}
                /> */}
              </div>
            </ScrollAnimation>
          </MobileView>
        </Flex>

        <Flex width={[1, 1, 1 / 2]} flexDirection="column" mb={3}>
          <div style={{ textAlign: 'left' }}>
            <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
              <Text
                fontSize="38px"
                fontWeight={600}
                mb={3}
                color="#344f79"
                lineHeight="44px"
                style={{ fontFamily: 'Playfair Display' }}
              >
                Complete Thyroid Blood Test Kit
              </Text>
              <Text
                textAlign="left"
                mb={3}
                lineHeight={1.6}
                fontSize={1}
                fontWeight={600}
                style={{ textTransform: 'uppercase' }}
              >
                At-home collection. Meaningful insights. Personalized plan.
              </Text>

              <Text
                textAlign="left"
                lineHeight="24px"
                fontSize="18px"
                fontWeight={200}
                color="#344f79"
                mb={3}
              >
                This at-home test can help you understand how your thyroid is
                working and if there may be a need for a further evaluation of
                your thyroid function. Expect your results in one week.
              </Text>

              <Text
                textAlign="left"
                lineHeight={1.6}
                fontSize={3}
                fontWeight={600}
                mb={3}
              >
                $99
              </Text>
              <Text
                textAlign="left"
                mb={3}
                lineHeight={1.6}
                fontSize={1}
                fontWeight={200}
              >
                Test takers must be 18+ and reside in the US
              </Text>
              <Text
                textAlign="left"
                mb={3}
                lineHeight={1.6}
                fontSize={1}
                fontWeight={600}
              >
                Biomarkers Included
              </Text>

              <div>
                <Biomarker>
                  <Text
                    textAlign="left"
                    lineHeight={1.6}
                    fontSize={1}
                    fontWeight={600}
                  >
                    TSH
                  </Text>
                  <Tooltip className="tooltip">
                    <span style={{ fontWeight: 600 }}>TSH</span> is a hormone
                    produce by your brain that stimulates the thyroid gland
                  </Tooltip>
                </Biomarker>
                <Biomarker>
                  <Text
                    textAlign="left"
                    lineHeight={1.6}
                    fontSize={1}
                    fontWeight={600}
                  >
                    T4
                  </Text>
                  <Tooltip className="tooltip">
                    <span style={{ fontWeight: 600 }}>Thyroxine</span> (T4) is
                    the main hormone secreted into the bloodstream by the
                    thyroid gland
                  </Tooltip>
                </Biomarker>
                <Biomarker>
                  <Text
                    textAlign="left"
                    lineHeight={1.6}
                    fontSize={1}
                    fontWeight={600}
                  >
                    T3
                  </Text>
                  <Tooltip className="tooltip">
                    <span style={{ fontWeight: 600 }}>Triiodothyronine</span> ,
                    known as&nbsp;
                    <span style={{ fontWeight: 600 }}>T3</span> is another
                    hormone produce by your thyroid. Most of the&nbsp;
                    <span style={{ fontWeight: 600 }}>T3</span> in your body
                    binds to protein and regulates your body's temperature,
                    metabolism, and heart rate.
                  </Tooltip>
                </Biomarker>
                <Biomarker>
                  <Text
                    textAlign="left"
                    lineHeight={1.6}
                    fontSize={1}
                    fontWeight={600}
                  >
                    TPO
                  </Text>
                  <Tooltip className="tooltip">
                    The presence of&nbsp;
                    <span style={{ fontWeight: 600 }}>TPO antibodies</span> in
                    your blood suggests that the cause of thyroid disease is an
                    autoimmune disorder, such as Hashimoto's disease.
                  </Tooltip>
                </Biomarker>
              </div>

              <Button
                width="auto"
                variant="primary"
                onClick={() => props.history.push('/order-kit')}
                mt={4}
              >
                Buy a Kit
              </Button>
            </ScrollAnimation>
          </div>
        </Flex>
      </Flex>

      <Flex
        flex={1}
        textAlign="center"
        style={{ marginTop: '200px', marginBottom: '72px' }}
      >
        <ScrollAnimation
          animateIn="fadeInUp"
          style={{ margin: '0 auto' }}
          animateOnce={true}
        >
          <Text
            fontSize="38px"
            fontWeight={600}
            textAlign="center"
            mb={3}
            color="#344f79"
            lineHeight="44px"
            style={{ fontFamily: 'Playfair Display' }}
          >
            The Benefits Of Our Tests
          </Text>
        </ScrollAnimation>
      </Flex>
      <Flex flexDirection={['column']}>
        <Flex flexDirection={['column', 'column', 'row']}>
          <Flex flex={1} mr={[0, 0, 4]} mb={3}>
            <div style={{ textAlign: 'left', padding: '0 12px' }}>
              <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                <Flex mb={3} justifyContent="left">
                  <Image>
                    <ScrollAnimation
                      animateIn="fadeIn"
                      delay="0.5"
                      animateOnce={true}
                    >
                      <img src={painFree} alt="" height="100px" />
                    </ScrollAnimation>
                  </Image>
                </Flex>
                <Text
                  textAlign="left"
                  mb={3}
                  lineHeight={1.6}
                  fontSize="22px"
                  fontWeight={600}
                  color="#344f79"
                >
                  Pain-Free Home Testing
                </Text>

                <Text
                  textAlign="left"
                  lineHeight={1.6}
                  fontSize="18px"
                  fontWeight={300}
                  color="#344f79"
                >
                  - There's no needles
                  <br />
                  <br />
                  - Finger-prick test
                  <br />
                  <br />- Only takes a few minutes
                </Text>
              </ScrollAnimation>
            </div>
          </Flex>

          <Flex width={[1, 1, 1 / 3]} flexDirection="column" mb={3}>
            <div style={{ textAlign: 'left', padding: '0 12px' }}>
              <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                <Flex mb={3} justifyContent="left">
                  <Image>
                    <img src={resultsCanTrust} alt="" height="100px" />
                  </Image>
                </Flex>
                <Text
                  textAlign="left"
                  mb={3}
                  lineHeight={1.6}
                  fontSize="22px"
                  fontWeight={600}
                  color="#344f79"
                >
                  Results You Can Trust
                </Text>

                <Text
                  textAlign="left"
                  lineHeight={1.6}
                  fontSize="18px"
                  fontWeight={300}
                  color="#344f79"
                >
                  - Comprehensive results
                  <br />
                  <br />
                  - Analyzed by CLIA CERTIFIED Labs ‍
                  <br />
                  <br />- Reviewed by US physicians
                </Text>
              </ScrollAnimation>
            </div>
          </Flex>

          <Flex width={[1, 1, 1 / 3]} flexDirection="column" mb={3}>
            <div style={{ textAlign: 'left', padding: '0 12px' }}>
              <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                <Flex mb={3} justifyContent="left">
                  <Image>
                    <img src={ongoingAdvice} alt="" height="100px" />
                  </Image>
                </Flex>
                <Text
                  textAlign="left"
                  mb={3}
                  lineHeight={1.6}
                  fontSize="22px"
                  fontWeight={600}
                  color="#344f79"
                >
                  Ongoing Advice
                </Text>

                <Text
                  textAlign="left"
                  lineHeight={1.6}
                  fontSize="18px"
                  fontWeight={300}
                  color="#344f79"
                >
                  - Personal guidance based on your test results
                  <br />
                  <br />- Action-oriented recommendations
                </Text>
              </ScrollAnimation>
            </div>
          </Flex>
        </Flex>

        <ScrollAnimation
          animateIn="fadeInUp"
          style={{ margin: '0 auto' }}
          animateOnce={true}
        >
          <Button
            width="auto"
            variant="primary"
            onClick={() => props.history.push('/order-kit')}
            mt={4}
          >
            Buy a Kit
          </Button>
        </ScrollAnimation>
      </Flex>
    </Container>
  </Fragment>
);

export default ByAKit;
