import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Text } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Select from 'react-select';
import DraggableComponent from '../../components/draggable/draggable';
import { FilterEntry } from './FilterEntry';
import { queryMatch } from '../../utils/filterBannerQueryMatcher';

const OPERATORS = [
  {
    label: 'AND',
    value: 'AND',
  },
  {
    label: 'OR',
    value: 'OR',
  },
];

const SelectStyled = styled(Box)`
  .region-select__control {
    border: 1px solid rgba(53, 78, 121, 0.1);
    border-radius: 10px;
    padding: 0px 12px;
    background: #fff;
    min-width: 150px;
    font-size: 12px;

    &:hover {
      border-color: #eee;
    }

    &--is-focused {
      box-shadow: none;
    }

    .region-select__indicator-separator {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const BtnEditAction = styled(Button)`
  flex: initial;
  opacity: 0.5;
  border: 1px solid #344f79;
  width: fit-content;
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 2px;
  &:hover {
    opacity: 1;
  }
`;

const BtnAction = styled(Button)`
  flex: initial;
  opacity: 0.5;
  border: 1px dashed #344f79;
  width: fit-content;
  padding: 3px 8px;
  font-size: 12px;
  border-radius: 6px;
  &:hover {
    opacity: 1;
  }
`;

const GroupContainer = styled(Flex)`
  background-color: white;
`;

const LeftIndicator = ({ operator = 'AND' }) => {
  const styles = {
    AND: {
      strokeColor: '#739efc',
      bubbleBackground: '#eaf0fe',
    },
    OR: {
      strokeColor: '#ffd8a8',
      bubbleBackground: '#ffd8a8',
    },
  };

  return (
    <Flex flexDirection={'column'} mr={3} pt={'20px'} pb={'10px'} id="toto">
      <Flex>
        <Box flex={1} />
        <Box flex={1} style={{ height: '2px', backgroundColor: styles[operator].strokeColor }} />
      </Flex>
      <Flex flex={1}>
        <Flex flex={1}>
          <Box flex={1} />
          <Flex flex={1}>
            <Box style={{ height: '100%', width: '2px', backgroundColor: styles[operator].strokeColor }} />
            <Box flex={1} />
          </Flex>
        </Flex>
      </Flex>
      <Box style={{ background: styles[operator].bubbleBackground, borderRadius: '20px', padding: ' 4px 8px', fontSize: '12px' }}>{operator}</Box>
      <Flex flex={1}>
        <Flex flex={1}>
          <Box flex={1} />
          <Flex flex={1}>
            <Box style={{ height: '100%', width: '2px', backgroundColor: styles[operator].strokeColor }} />
            <Box flex={1} />
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        <Box flex={1} />
        <Box flex={1} style={{ height: '2px', backgroundColor: styles[operator].strokeColor }} />
      </Flex>
    </Flex>
  );
};

const EditGroupBar = ({ onDelete }) => {
  return (
    <Flex justifyContent={'flex-end'} alignItems={'center'} flex={1}>
      <BtnEditAction variant={'outline'} ml={2} onClick={onDelete}>
        <FontAwesomeIcon icon={faTrash} />
      </BtnEditAction>
    </Flex>
  );
};

const GroupBar = ({ operator, onChange, showEdits, onDelete, filter, selectedTestUser, athenaIdsFiltered }) => {
  const [testStatus, setTestStatus] = useState('');
  const runTest = () => {
    if (!selectedTestUser || !filter) {
      setTestStatus('');
      return;
    }

    if (filter && selectedTestUser) {
      if (!selectedTestUser) {
        setTestStatus('');
        return;
      }

      const res = queryMatch(
        {
          data: {
            ...filter,
            datatype: 'grouping',
          },
          datatype: 'grouping',
        },
        selectedTestUser
      );
      if (res) {
        setTestStatus('Pass');
      } else {
        setTestStatus('Fail');
      }
    }
  };
  useEffect(() => {
    runTest();
  }, []);

  useEffect(
    () => {
      runTest();
    },
    [selectedTestUser, filter]
  );

  return (
    <Flex flex={1} alignItems={'center'}>
      <SelectStyled>
        {!!athenaIdsFiltered && athenaIdsFiltered.length > 0 ? null : (
          <Select
            classNamePrefix="region-select"
            options={OPERATORS}
            name="primary_sex"
            value={OPERATORS.find((x) => x.value === (operator || 'AND'))}
            placeholder="select title"
            onChange={(option) => {
              onChange(option.value);
            }}
            isSearchable={true}
            disabled={!!athenaIdsFiltered && athenaIdsFiltered.length > 0}
          />
        )}
      </SelectStyled>
      {!!selectedTestUser &&
        !!testStatus && (
          <>
            {testStatus === 'Pass' && (
              <Box style={{ width: 'fit-content', height: 'fit-content', borderRadius: '3px', background: '#4c9b4c', padding: '2px 12px' }} ml={3}>
                <Text fontSize="10px" fontWeight={600} style={{ color: 'white', margin: 0, padding: 0 }}>
                  PASS
                </Text>
              </Box>
            )}
            {testStatus === 'Fail' && (
              <Box style={{ width: 'fit-content', height: 'fit-content', borderRadius: '3px', background: 'rgb(255 187 59)', padding: '2px 12px' }} ml={3}>
                <Text fontSize="10px" fontWeight={600} style={{ color: 'white', margin: 0, padding: 0 }}>
                  FAIL
                </Text>
              </Box>
            )}
          </>
        )}
      {showEdits && <EditGroupBar onDelete={onDelete} />}
    </Flex>
  );
};

const GroupButtons = ({ onAddGroup, onAddFilter }) => {
  return (
    <Flex flex={1} mt={3}>
      <BtnAction variant={'outline'} onClick={onAddFilter}>
        Add Filter
      </BtnAction>
      <BtnAction variant={'outline'} ml={3} onClick={onAddGroup}>
        Add Group
      </BtnAction>
    </Flex>
  );
};

export const Group = ({ data, onOperatorChange, onContentChanged, groupHovered, setGroupHovered, addFilter, depth, onDelete, onDeleteGroup, selectedTestUser, athenaIdsFiltered }) => {
  const [isHovered, setIsHovered] = useState();
  useEffect(
    () => {
      setIsHovered(!!groupHovered && groupHovered === data.id);
    },
    [groupHovered, data, (data || {}).id]
  );

  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  const getChangedPos = (currentPos, newPos) => {
    if (currentPos === newPos) return;
    const el = array_move(JSON.parse(JSON.stringify(data.content)), currentPos, newPos);

    onContentChanged(data.id, el);
  };

  const addGroup = () => {
    const el = JSON.parse(JSON.stringify(data.content || []));

    el.push({
      datatype: 'grouping',
      id: `tmpGrp_${new Date()}`,
      data: {
        id: `tmpGrpData_${new Date()}`,
        operator: 'AND',
        content: [],
      },
    });
    onContentChanged(data.id, el);
  };

  return (
    <GroupContainer
      mt={4}
      onMouseEnter={(e) => {
        setGroupHovered(data.id);
      }}
      onMouseLeave={(e) => {
        setGroupHovered(null);
      }}
      onMouseMove={(e) => {
        if (!groupHovered) {
          setGroupHovered(data.id);
        }
      }}
    >
      <LeftIndicator operator={data.operator} />
      <Flex flexDirection={'column'} flex={1}>
        <GroupBar
          operator={data.operator}
          onChange={(operator) => {
            onOperatorChange(data.id, operator);
          }}
          showEdits={isHovered}
          onDelete={() => onDeleteGroup(data.id)}
          filter={data}
          selectedTestUser={selectedTestUser}
          athenaIdsFiltered={athenaIdsFiltered}
        />
        <DraggableComponent onPosChange={getChangedPos} key={`dragbl_${data.id}`} inv={data.id}>
          {!!data.content &&
            data.content.map((x) => {
              if (x.datatype === 'content') {
                return <FilterEntry athenaIdsFiltered={athenaIdsFiltered} key={`${x.id}`} data={x} editFilter={addFilter} onDelete={onDelete} selectedTestUser={selectedTestUser} />;
              } else {
                return (
                  <Group
                    depth={depth + 1}
                    addFilter={addFilter}
                    data={x.data}
                    key={`${x.id}`}
                    onOperatorChange={onOperatorChange}
                    onContentChanged={onContentChanged}
                    groupHovered={groupHovered}
                    setGroupHovered={setGroupHovered}
                    onDelete={onDelete}
                    onDeleteGroup={onDeleteGroup}
                    selectedTestUser={selectedTestUser}
                    athenaIdsFiltered={athenaIdsFiltered}
                  />
                );
              }
            })}
        </DraggableComponent>
        {(!athenaIdsFiltered || athenaIdsFiltered.length < 1) && <GroupButtons onAddGroup={addGroup} onAddFilter={() => addFilter(data.id)} />}
      </Flex>
    </GroupContainer>
  );
};
