import React from 'react';
import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';

const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1 0 auto;
  background: ${props =>
    props.disabled ? props.theme.bg.wash : props.theme.bg.default};
  font-weight: 400;
  font-size: 16px;
  border: 1px solid
    ${props =>
      props.disabled ? props.theme.bg.border : props.theme.bg.inactive};
  border-radius: 10px;
  padding: 18px 12px;
  box-shadow: none;
  ${props =>
    props.type === 'checkbox' &&
    css`
      flex: initial;
      width: initial;
      margin-right: 0.5em;
    `} &::placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &::-webkit-input-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:-moz-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:-ms-input-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:focus {
    border-color: ${props => props.theme.brand.default};
    outline: none;
  }
  &[type='file'] {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: hidden;
  }
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
`;

const StyledInput2 = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: initial;
  background: ${props =>
    props.disabled ? props.theme.bg.wash : props.theme.bg.default};
  font-weight: 400;
  font-size: 16px;
  border: 1px solid
    ${props =>
      props.disabled ? props.theme.bg.border : props.theme.bg.inactive};
  border-radius: 10px;
  padding: 18px 12px;
  box-shadow: none;
  ${props =>
    props.type === 'checkbox' &&
    css`
      flex: initial;
      width: initial;
      margin-right: 0.5em;
    `} &::placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &::-webkit-input-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:-moz-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:-ms-input-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:focus {
    border-color: ${props => props.theme.brand.default};
    outline: none;
  }
  &[type='file'] {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: hidden;
  }
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
`;

export const MaskInput = props => (
  <InputMask {...props}>
    {inputProps => <StyledInput {...inputProps} {...(props || {}).inputStyle}/>}
  </InputMask>
);

export const MaskInput2 = props => (
  <InputMask {...props}>
    {inputProps => <StyledInput2 {...inputProps}/>}
  </InputMask>
);
