import React from 'react';

export const DbSVG = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7.205C16.418 7.205 20 6.04 20 4.603C20 3.165 16.418 2 12 2C7.582 2 4 3.165 4 4.603C4 6.04 7.582 7.205 12 7.205ZM12 22C16.963 22 20 20.314 20 19.397V14.993C19.948 15.025 19.888 15.053 19.835 15.083C19.5936 15.225 19.3449 15.3541 19.09 15.47C18.8967 15.558 18.6967 15.6423 18.49 15.723C18.428 15.7477 18.365 15.772 18.301 15.796C16.2722 16.4813 14.1422 16.8187 12.001 16.794C9.866 16.821 7.741 16.484 5.701 15.796L5.512 15.723C5.22237 15.6119 4.93806 15.4875 4.66 15.35C4.4926 15.2668 4.32816 15.1777 4.167 15.083C4.114 15.053 4.054 15.025 4.002 14.993V19.397C4 20.315 7.037 22 12 22ZM19.09 8.072C18.8928 8.16282 18.6927 8.2472 18.49 8.325C18.428 8.35033 18.365 8.375 18.301 8.399C16.2722 9.08431 14.1422 9.42173 12.001 9.397C9.866 9.424 7.741 9.087 5.701 8.399L5.512 8.325C5.22239 8.21428 4.93808 8.09014 4.66 7.953C4.49258 7.86946 4.32815 7.78007 4.167 7.685C4.112 7.655 4.052 7.627 4 7.595V12C4 12.917 7.037 14.603 12 14.603C16.963 14.603 20 12.917 20 12V7.596C19.948 7.627 19.888 7.655 19.835 7.686C19.5936 7.82761 19.3449 7.95645 19.09 8.072Z"
        fill="#4ECB71"
      />
    </svg>
  );
};

export const StripeSVG = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_104_2)">
        <path
          d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0L2 0ZM8.226 5.385C7.642 5.385 7.289 5.549 7.289 5.978C7.289 6.446 7.896 6.652 8.649 6.908C9.877 7.323 11.493 7.871 11.5 9.901C11.5 11.868 9.924 13 7.63 13C6.59512 12.9957 5.57174 12.7828 4.621 12.374V9.758C5.547 10.264 6.716 10.638 7.631 10.638C8.248 10.638 8.689 10.473 8.689 9.967C8.689 9.449 8.031 9.212 7.236 8.926C6.026 8.49 4.5 7.94 4.5 6.11C4.5 4.165 5.988 3 8.226 3C9.16127 2.99163 10.0894 3.16308 10.96 3.505V6.088C10.122 5.638 9.064 5.385 8.226 5.385Z"
          fill="#2182CD"
        />
      </g>
      <defs>
        <clipPath id="clip0_104_2">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AthenaSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <script xmlns="" />
      <g id="Group">
        <g id="Wordmark">
          <path
            id="Vector"
            d="M22.291 11.697C22.291 12.7052 22.6181 13.6263 23.2153 14.2953C23.8125 14.9644 24.6797 15.3814 25.7598 15.3814C26.7981 15.3814 27.6655 14.9853 28.2733 14.3267C28.8812 13.6681 29.2294 12.747 29.2294 11.697C29.2294 10.6465 28.8812 9.72523 28.2733 9.06652C27.6655 8.40781 26.7981 8.01171 25.7598 8.01171C24.6797 8.01171 23.8125 8.42871 23.2153 9.09786C22.6181 9.76701 22.291 10.6883 22.291 11.697ZM31.5818 17.4463H30.3275H29.0732V16.756V16.0658C28.6771 16.5759 28.1819 16.9961 27.5817 17.2887C26.9815 17.5812 26.2762 17.7463 25.4598 17.7463C23.8515 17.7463 22.4142 17.1131 21.3789 16.0403C20.3437 14.9675 19.7104 13.4552 19.7104 11.697C19.7104 9.93832 20.3437 8.42577 21.3789 7.35289C22.4142 6.28001 23.8515 5.64679 25.4598 5.64679C26.2762 5.64679 26.9815 5.81497 27.5817 6.11069C28.1819 6.40641 28.6771 6.82967 29.0732 7.33983V6.64332V5.9468H30.3275H31.5818V11.6965V17.4463Z"
            fill="white"
          />
          <path
            id="Vector_2"
            d="M41.9091 8.22759H39.7067H37.5043V10.7304V13.2332C37.5043 14.0074 37.7082 14.5446 38.0382 14.8881C38.3682 15.2317 38.8242 15.3817 39.3286 15.3817C39.7184 15.3817 40.1264 15.2948 40.5225 15.1537C40.9186 15.0127 41.3028 14.8176 41.645 14.6012L42.1431 15.6035L42.6411 16.6059C42.179 16.918 41.6569 17.2032 41.0703 17.4103C40.4836 17.6175 39.8325 17.7466 39.1121 17.7466C37.8097 17.7466 36.7775 17.3954 36.0708 16.6616C35.3642 15.9278 34.9831 14.8114 34.9831 13.2808V10.7542V8.2276H33.837H32.6909V7.08735V5.94711H33.837H34.9831V3.82259V1.69806H36.2437H37.5043V3.82258V5.94711H39.7067H41.9091V7.08735V8.22759H41.9091Z"
            fill="white"
          />
          <path
            id="Vector_3"
            d="M46.1629 7.31541C46.625 6.65525 47.2102 6.23805 47.8254 5.9859C48.4405 5.73374 49.0856 5.64662 49.6677 5.64662C51.0361 5.64662 52.2035 6.11793 53.0288 6.92528C53.8541 7.73262 54.3373 8.87601 54.3373 10.2202V13.8331V17.4461H53.0767H51.8162V14.2472V11.0483C51.8162 10.0823 51.5671 9.32916 51.108 8.81753C50.6489 8.3059 49.9798 8.03578 49.1395 8.03578C48.3294 8.03578 47.5852 8.30277 47.0436 8.81586C46.502 9.32895 46.1629 10.0881 46.1629 11.0725V14.2593V17.4461H44.9028H43.6426V9.56815V1.69019H44.9028H46.1629V4.5028V7.31541Z"
            fill="white"
          />
          <path
            id="Vector_4"
            d="M58.6555 10.7128H61.6384H64.6212C64.4834 9.81821 64.1533 9.10999 63.6581 8.62542C63.163 8.14084 62.5029 7.87991 61.7048 7.87991C60.8106 7.87991 60.1143 8.168 59.6085 8.66616C59.1028 9.16431 58.7875 9.87253 58.6555 10.7128ZM67.1901 11.6846C67.1901 11.8468 67.184 12.0087 67.175 12.1661C67.166 12.3235 67.1541 12.4764 67.1424 12.6206H62.8931H58.6438C58.8117 13.653 59.2588 14.3853 59.868 14.8594C60.4772 15.3335 61.2485 15.5496 62.065 15.5496C62.6407 15.5496 63.2349 15.4355 63.8037 15.2269C64.3724 15.0183 64.9158 14.7152 65.39 14.337L66.0139 15.2316L66.6377 16.1262C65.9357 16.7262 65.1887 17.1313 64.3995 17.3863C63.6103 17.6414 62.779 17.7465 61.9087 17.7465C60.186 17.7465 58.7157 17.1463 57.676 16.0899C56.6363 15.0336 56.0273 13.521 56.0273 11.6963C56.0273 9.89593 56.6123 8.3836 57.6204 7.32137C58.6285 6.25915 60.0598 5.64703 61.7524 5.64703C63.3607 5.64703 64.7171 6.25915 65.673 7.31991C66.6289 8.38068 67.1842 9.89008 67.1901 11.6846Z"
            fill="white"
          />
          <path
            id="Vector_5"
            d="M79.6659 10.2203V13.8332V17.4462H78.4058H77.1456V14.2473V11.0484C77.1456 10.082 76.8966 9.32864 76.4374 8.81691C75.9782 8.30517 75.3088 8.03504 74.4682 8.03504C73.658 8.03504 72.9138 8.30224 72.3722 8.81554C71.8306 9.32885 71.4915 10.0883 71.4915 11.0727V14.2594V17.4462H70.2314H68.9712V11.6969V5.94758H70.2197H71.4681V6.64953V7.35148C71.9298 6.67334 72.5177 6.24715 73.1373 5.9905C73.7568 5.73385 74.408 5.64673 74.9963 5.64673C76.3647 5.64673 77.5321 6.11804 78.3574 6.9254C79.1827 7.73275 79.6659 8.87613 79.6659 10.2203Z"
            fill="white"
          />
          <path
            id="Vector_6"
            d="M83.8908 11.697C83.8908 12.7052 84.218 13.6263 84.8152 14.2953C85.4123 14.9644 86.2795 15.3814 87.3596 15.3814C88.3979 15.3814 89.2654 14.9853 89.8732 14.3267C90.481 13.6681 90.8293 12.747 90.8293 11.697C90.8293 10.6465 90.481 9.72523 89.8732 9.06652C89.2653 8.40781 88.3979 8.01171 87.3596 8.01171C86.2795 8.01171 85.4123 8.42871 84.8152 9.09786C84.218 9.76701 83.8908 10.6883 83.8908 11.697ZM93.1817 17.4463H91.9273H90.673V16.756V16.0658C90.2769 16.5759 89.7818 16.9961 89.1816 17.2887C88.5814 17.5812 87.8761 17.7463 87.0596 17.7463C85.4514 17.7463 84.0141 17.1131 82.9788 16.0403C81.9435 14.9675 81.3103 13.4552 81.3103 11.697C81.3103 9.93832 81.9435 8.42577 82.9788 7.35289C84.0141 6.28001 85.4514 5.64679 87.0596 5.64679C87.8761 5.64679 88.5814 5.81497 89.1816 6.11069C89.7818 6.40641 90.2769 6.82967 90.673 7.33983V6.64332V5.9468H91.9273H93.1817V11.6965V17.4463Z"
            fill="white"
          />
          <path
            id="Vector_7"
            d="M97.1321 8.10762C97.5763 7.36347 98.1945 6.82929 98.8952 6.48113C99.5959 6.13298 100.379 5.97086 101.153 5.97086C102.57 5.97086 103.758 6.40895 104.593 7.19666C105.427 7.98437 105.907 9.1217 105.907 10.5202V13.9831V17.4461H105.223H104.539V14.1933V10.9405C104.539 9.80025 104.208 8.88207 103.59 8.24896C102.972 7.61585 102.066 7.2678 100.913 7.2678C99.8509 7.2678 98.9056 7.64886 98.2259 8.30746C97.5462 8.96605 97.1321 9.9022 97.1321 11.0124V14.2292V17.4461H96.4481H95.7642V9.56815V1.69019H96.4481H97.1321V4.8989V8.10762Z"
            fill="white"
          />
          <path
            id="Vector_8"
            d="M109.244 11.1209H113.283H117.322C117.22 9.95016 116.77 8.96576 116.08 8.27405C115.39 7.58234 114.459 7.18332 113.397 7.18332C112.251 7.18332 111.278 7.59446 110.56 8.29223C109.841 8.99 109.376 9.9744 109.244 11.1209ZM118.715 11.7326C118.715 11.8346 118.712 11.9336 118.707 12.0281C118.703 12.1227 118.697 12.2127 118.69 12.2967H113.955H109.22C109.322 13.6112 109.835 14.6466 110.612 15.3533C111.389 16.0601 112.431 16.4382 113.589 16.4382C114.375 16.4382 115.081 16.2821 115.714 16.003C116.347 15.7239 116.908 15.3218 117.406 14.8296L117.772 15.298L118.139 15.7664C117.424 16.4921 116.674 16.9572 115.898 17.2408C115.123 17.5244 114.322 17.6265 113.505 17.6265C111.855 17.6265 110.435 17.0322 109.429 16.0072C108.422 14.9823 107.828 13.5268 107.828 11.8045C107.828 10.1002 108.431 8.64175 109.432 7.60939C110.432 6.57704 111.831 5.97076 113.422 5.97076C114.928 5.97076 116.242 6.56805 117.184 7.58693C118.126 8.6058 118.697 10.0463 118.715 11.7326Z"
            fill="white"
          />
          <path
            id="Vector_9"
            d="M121.34 11.8047C121.34 13.077 121.799 14.2112 122.579 15.0273C123.36 15.8435 124.461 16.3415 125.746 16.3415C127.024 16.3415 128.122 15.8554 128.901 15.0452C129.679 14.235 130.139 13.1009 130.139 11.8047C130.139 10.5086 129.679 9.37131 128.901 8.558C128.122 7.7447 127.024 7.25543 125.746 7.25543C124.449 7.25543 123.348 7.75368 122.57 8.57148C121.793 9.38927 121.34 10.5266 121.34 11.8047ZM131.423 17.4463H130.745H130.067V16.3599V15.2736C129.61 15.9818 129.004 16.5701 128.255 16.9813C127.507 17.3926 126.615 17.6268 125.589 17.6268C124.005 17.6268 122.594 17.0115 121.58 15.9761C120.566 14.9408 119.948 13.4853 119.948 11.8047C119.948 10.1242 120.566 8.66581 121.58 7.6275C122.594 6.58919 124.005 5.97101 125.589 5.97101C126.615 5.97101 127.507 6.20792 128.255 6.62053C129.004 7.03313 129.61 7.62144 130.067 8.32423V7.23745V6.15067H130.745H131.423V11.7985V17.4463Z"
            fill="white"
          />
          <path id="Vector_10" d="M135.59 17.4463H134.905H134.221V9.5683V1.69031H134.905H135.59V9.5683V17.4463Z" fill="white" />
          <path
            id="Vector_11"
            d="M146.086 7.39938H143.481H140.876V10.4784V13.5574C140.876 14.5113 141.128 15.2074 141.568 15.665C142.008 16.1226 142.635 16.3418 143.386 16.3418C143.872 16.3418 144.337 16.2578 144.763 16.1078C145.189 15.9578 145.576 15.7418 145.906 15.4777L146.23 15.9879L146.554 16.498C146.152 16.834 145.672 17.116 145.123 17.3141C144.573 17.5121 143.955 17.6262 143.277 17.6262C142.101 17.6262 141.158 17.299 140.51 16.6298C139.862 15.9605 139.508 14.9492 139.508 13.5808V10.4901V7.39938H138.212H136.915V6.77515V6.15092H138.212H139.508V3.92449V1.69806H140.192H140.876V3.92449V6.15092H143.481H146.086V6.77515V7.39938Z"
            fill="white"
          />
          <path
            id="Vector_12"
            d="M149.226 8.10762C149.67 7.36347 150.288 6.82929 150.989 6.48113C151.689 6.13298 152.473 5.97086 153.247 5.97086C154.663 5.97086 155.852 6.40895 156.686 7.19666C157.52 7.98437 158 9.1217 158 10.5202V13.9831V17.4461H157.316H156.631V14.1933V10.9405C156.631 9.80025 156.301 8.88207 155.683 8.24896C155.066 7.61585 154.159 7.2678 153.007 7.2678C151.945 7.2678 150.999 7.64886 150.32 8.30746C149.64 8.96605 149.226 9.9022 149.226 11.0124V14.2292V17.4461H148.541H147.857V9.56815V1.69019H148.541H149.226V4.8989V8.10762Z"
            fill="white"
          />
        </g>
        <g id="Leaf">
          <path
            id="Vector_13"
            d="M9.5918 5.56089C9.5918 5.86896 9.71672 6.14776 9.91858 6.34954C10.1204 6.55132 10.3992 6.67608 10.707 6.67608C11.0147 6.67608 11.2937 6.55132 11.4957 6.34954C11.6978 6.14776 11.8228 5.86896 11.8228 5.56089C11.8228 5.25314 11.6978 4.97416 11.4957 4.77213C11.2937 4.5701 11.0147 4.44501 10.707 4.44501C10.3992 4.44501 10.1204 4.5701 9.91858 4.77213C9.71672 4.97416 9.5918 5.25314 9.5918 5.56089Z"
            fill="#7A9A01"
          />
          <path
            id="Vector_14"
            d="M5.22974 4.445C5.22974 5.26096 5.39299 6.03864 5.68848 6.74745C5.98397 7.45625 6.41169 8.09617 6.94064 8.63664C7.46959 8.09617 7.89732 7.45625 8.1928 6.74745C8.48829 6.03864 8.65154 5.26096 8.65154 4.445C8.65154 3.62903 8.48829 2.85135 8.1928 2.14255C7.89732 1.43374 7.46959 0.793818 6.94064 0.253357C6.41169 0.793818 5.98397 1.43374 5.68848 2.14255C5.39299 2.85135 5.22974 3.62903 5.22974 4.445Z"
            fill="#9BC620"
          />
          <path
            id="Vector_15"
            d="M9.31296 10.027C8.48157 11.1593 7.91581 12.4049 7.6038 13.6897C7.29179 14.9744 7.23352 16.2983 7.41711 17.5874C8.70205 17.3763 9.94783 16.9241 11.0802 16.2416C12.2126 15.5591 13.2316 14.6462 14.063 13.5138C14.8944 12.3815 15.4601 11.1359 15.7722 9.85112C16.0842 8.56637 16.1424 7.24246 15.9588 5.95343C14.6739 6.16446 13.4281 6.61667 12.2957 7.29921C11.1633 7.98175 10.1443 8.89462 9.31296 10.027Z"
            fill="#7A9A01"
          />
          <path
            id="Vector_16"
            d="M5.06952 8.13422C4.44499 7.267 3.67959 6.56302 2.82907 6.03152C1.97854 5.50001 1.04289 5.14097 0.0778439 4.96362C-0.0598853 5.93514 -0.0159702 6.93636 0.218511 7.91151C0.452991 8.88666 0.878035 9.83573 1.50256 10.703C2.06218 11.4801 2.73501 12.1256 3.48084 12.6334C4.22668 13.1413 5.04552 13.5114 5.89716 13.7376V15.5004V17.2632C5.89716 17.3526 5.93352 17.4336 5.99231 17.4923C6.05111 17.5511 6.13233 17.5874 6.22204 17.5874C6.31142 17.5874 6.39248 17.5511 6.45119 17.4923C6.5099 17.4336 6.54627 17.3526 6.54627 17.2632V15.3784V13.4935C6.54659 13.4785 6.54659 13.462 6.54651 13.4445C6.54643 13.4269 6.54627 13.4083 6.54627 13.3891L6.54601 13.3891L6.54576 13.3892C6.61677 12.4904 6.53415 11.5744 6.29056 10.6843C6.04697 9.79408 5.6424 8.92973 5.06952 8.13422Z"
            fill="#9BC620"
          />
        </g>
      </g>
    </svg>
  );
};
