import React from 'react';
import { Query } from 'react-apollo';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { CURRENT_USER } from '../graphql';
import Header from '../components/Header';
import FullLayout from '../components/FullLayout';
import PleaseWait from '../components/PleaseWait';
import { RotatingText } from './components/RotatingText';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
`;

const withSession = Component => props => (
  <Query query={CURRENT_USER} notifyOnNetworkStatusChange >
    {({ data, loading, refetch, error }) => {
      // console.log({
      //   loading, data
      // })
      if (loading) {
        return (
          <FullLayout>
            <Container>
              <Flex flex={1}>
                <Header dark={false} />
              </Flex>
              <PleaseWait
                text=" "
                spinner={{
                  sColor: '#ECEFF2',
                  pColor: '#909FB5',
                }}
              >
                <RotatingText />
              </PleaseWait>
            </Container>
          </FullLayout>
        );
      }
      if (error) {
        if (error) {
          console.log('err curr', error);
        }
        return null;
      }
      return (
        <Component {...props} session={data.currentUser} refetch={()=>{
          console.log('refetch')
          refetch()
        }} />
      );
    }}
  </Query>
);

export default withSession;
