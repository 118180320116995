import React, { Component, useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { Formik, Form } from 'formik';
import TextInput from '../components/TextInput';
import { FormField } from '../components/FormField';
import { ADMIN_GET_USER_BY_ID, ADMIN_MAP_LOCALDB_ACCOUNT_TO_ATHENA_ID, ADMIN_REMAP_PWN } from '../graphql';
import { compose, Query, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';
import PleaseWait from '../components/PleaseWait';
import ConfirmModal from '../components/ConfirmModal';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;
const ModalInner = styled(Box)`
  max-width: 800px;
  width: 100%;
`;

const RemapInner = ({ oldId, newId, cancel, remap }) => {
  return (
    <Flex flex={1} flexDirection="column">
      <Text>User from:</Text>
      <Query
        query={ADMIN_GET_USER_BY_ID}
        variables={{
          id: oldId,
        }}
      >
        {({ loading, data, error }) => {
          if (!!loading) {
            return <PleaseWait />;
          }
          if (error) {
            return <Text>An error occured</Text>;
          }

          return (
            <>
              {data.adminGetUserById.map((x, i) => {
                return (
                  <Flex style={{ opacity: i === 0 ? 1 : 0.5 }}>
                    <Text>{x.athenaId}</Text>
                    <Text ml={3}>
                      {x.firstName} {x.lastName}
                    </Text>
                    <Text ml={3}>{x.email}</Text>
                    <Text ml={3}>{x.ordersCount} orders</Text>
                  </Flex>
                );
              })}
            </>
          );
        }}
      </Query>

      <Text>User to:</Text>
      <Query
        query={ADMIN_GET_USER_BY_ID}
        variables={{
          id: newId,
        }}
      >
        {({ loading, data, error }) => {
          if (!!loading) {
            return <PleaseWait />;
          }
          if (error) {
            return <Text>An error occured</Text>;
          }

          return (
            <>
              {data.adminGetUserById.map((x, i) => {
                return (
                  <Flex style={{ opacity: i === 0 ? 1 : 0.5 }}>
                    <Text>{x.athenaId}</Text>
                    <Text ml={3}>
                      {x.firstName} {x.lastName}
                    </Text>
                    <Text ml={3}>{x.email}</Text>
                    <Text ml={3}>{x.ordersCount} orders</Text>
                  </Flex>
                );
              })}
            </>
          );
        }}
      </Query>

      <Flex mt={4}>
        <Button variant={'white'} type="button" mr={4} onClick={cancel}>
          Cancel
        </Button>
        <Button variant={'primary'} type="button" mr={4} onClick={remap}>
          Remap
        </Button>
      </Flex>
    </Flex>
  );
};

const RemapPwn = ({ toastManager, client }) => {
  const [oldId, setOldId] = useState();
  const [newId, setNewId] = useState();
  const [formActions, setFormActions] = useState();
  const [isModalVisible, setIsModalVisible] = useState();

  const cancel = () => {
    setFormActions(null);
    setIsModalVisible(false);
  };
  const submitChange = async () => {
    formActions.setSubmitting(true);

    try {
      const res = await client.mutate({
        mutation: ADMIN_REMAP_PWN,
        variables: {
          oldId,
          newId,
        },
      });

      if (res && res.data && res.data.adminRemapPwn) {
        if (res.data.adminRemapPwn.count === 0) {
          formActions.resetForm();
          toastManager.add('No PWN orders found to update', {
            appearance: 'warning',
          });
        } else if (res.data.adminRemapPwn.count === res.data.adminRemapPwn.updatedCount) {
          formActions.resetForm();
          toastManager.add(`${res.data.adminRemapPwn.updatedCount} PWN Orders successfully updated`, {
            appearance: 'success',
          });
        } else {
          formActions.resetForm();
          toastManager.add('Some orders not updated, check logs:' + `toupdate: ${res.data.adminRemapPwn.count}, updated: ${res.data.adminRemapPwn.updatedCount}`, { appearance: 'error' });
          console.log('Some Orders not updated: ', { toupdate: res.data.adminRemapPwn.count, updated: res.data.adminRemapPwn.updatedCount });
        }
      } else {
        formActions.resetForm();
        formActions.setSubmitting(false);
        toastManager.add('Error updating, please try again', {
          appearance: 'error',
        });
      }

      formActions.setSubmitting(false);
      //   toastManager.add('Error submitting intake form', { appearance: 'error' });
    } catch (err) {
      console.log('Remap PWN Err', err);
      formActions.setSubmitting(false);
      toastManager.add('Error updating, please try again', {
        appearance: 'error',
      });
    }
  };
  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    setOldId(values.oldId);
    setNewId(values.newId);
    setFormActions(actions);
    setIsModalVisible(true);
  };

  return (
    <Container>
      <Flex m={[3, 5]} flex={1} flexDirection="column">
        <Heading mb={4} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
          Remap PWN Orders to different user
        </Heading>
        <Card mb={4} p={[3, 4]}>
          <Flex flexDirection={'column'} flex={1} alignItems="center">
            <Formik
              initialValues={{ oldId: '', newId: '' }}
              // validationSchema={schema}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
              render={({ errors, touched, isValid, isSubmitting, handleChange, handleBlur, values }) => (
                <Form style={{ width: '300px' }}>
                  <Flex flexDirection="column" flex={1}>
                    <Heading mb={4}>Old Athena ID user</Heading>
                  </Flex>
                  <FormField mb={4} error={touched.email && errors.email}>
                    <TextInput placeholder="From Athena ID user" name="oldId" />
                  </FormField>
                  <Flex flexDirection="column" flex={1}>
                    <Heading mb={4}>TO</Heading>
                  </Flex>
                  <FormField mb={4} error={touched.athenaId && errors.athenaId}>
                    <TextInput placeholder="To Athena Id user" name="newId" />
                  </FormField>

                  <Button mt={4} mb={4} variant="primary" width={1 / 2} disabled={!isValid || isSubmitting} type="submit">
                    Change
                  </Button>
                </Form>
              )}
            />
          </Flex>
        </Card>
      </Flex>

      {isModalVisible && (
        <ConfirmModal
          isOpen={true}
          onClose={() => cancel()}
          onBackgroundClick={() => cancel()}
          onEscapeKeydown={() => cancel()}
          onConfirm={() => {}}
          confirmLabel="Save"
          cancelLabel="Remove"
          confirmStyle={{
            width: '100%',
          }}
          cancelStyle={{
            width: '100%',
            marginTop: '18px',
          }}
          childrenManaged
          cancelVariant="outline"
          buttonsDirection="column"
          displayX={true}
          cardStyle={{
            width: '500px',
            maxWidth: 'calc(100vw - 40px)',
          }}
        >
          <ModalInner>
            <Heading mb={4} textAlign="center" fontWeight="bold">
              Confirm Details
            </Heading>

            <RemapInner oldId={oldId} newId={newId} cancel={cancel} remap={submitChange} />
          </ModalInner>
        </ConfirmModal>
      )}
    </Container>
  );
};

export default compose(
  withApollo,
  withToastManager
)(RemapPwn);
