import React from 'react';
import { Flex, Button } from 'rebass';
import { Mutation } from 'react-apollo';
import 'react-table-v6/react-table.css';
import {
  ADMIN_GET_MOBILE_COURSE_JOURNEYS,
  ADD_MOBILE_COURSE_JOURNEY,
} from '../../../graphql';
import CheckboxInput from '../../../components/CheckboxInput';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import TextInput from '../../../components/TextInput';

class AddJourneyModalInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      isActive: true,
      isPro: false,
    };
  }

  render() {
    const { onClose, onSave } = this.props;

    return (
      <Mutation
        mutation={ADD_MOBILE_COURSE_JOURNEY}
        refetchQueries={[{ query: ADMIN_GET_MOBILE_COURSE_JOURNEYS }]}
        onCompleted={() => {
          onSave();
        }}
      >
        {(createJourney, { loading }) => (
          <Formik
            isInitialValid={
              ({ initialValues }) => true
              //   schema.isValidSync(initialValues)
            }
            initialValues={{
              title: '',
              description: '',
              isActive: true,
              isPro: false,
            }}
            // validationSchema={schema}
            onSubmit={async (values, actions) => {
              await createJourney({
                variables: {
                  title: values.title,
                  description: values.description,
                  isActive: values.isActive,
                  isPro: values.isPro,
                },
              });
              // submitCheck(values, actions)
            }}
            render={({
              touched,
              errors,
              isValid,
              handleChange,
              handleBlur,
              isSubmitting,
              values,
            }) => (
              <Form>
                <Flex flexDirection="column" width="400px">
                  <FormField mb={4} error={touched.phone && errors.phone}>
                    <label for="title">Title</label>
                    <TextInput name="title" placeholder="Title" />
                  </FormField>
                  <FormField mb={4} error={touched.phone && errors.phone}>
                    <label for="description">Description</label>
                    <TextInput name="description" placeholder="Description" />
                  </FormField>
                  <FormField mb={4} error={touched.phone && errors.phone}>
                    <label for="isPro">Require Pro subscription</label>
                    <CheckboxInput
                      name="isPro"
                      styles={{
                        checked: {
                          Box: {
                            backgroundColor: 'rgb(54, 79, 121)',
                          },
                        },
                      }}
                    />
                  </FormField>
                  <FormField mb={4} error={touched.phone && errors.phone}>
                    <label for="isActive">Is Active</label>
                    <CheckboxInput
                      name="isActive"
                      styles={{
                        checked: {
                          Box: {
                            backgroundColor: 'rgb(54, 79, 121)',
                          },
                        },
                      }}
                    />
                  </FormField>

                  <Flex
                    mt={4}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Button
                      style={{
                        background: 'white',
                        color: '#344f79',
                        border: '1px solid #344f79',
                      }}
                      type="button"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      // disabled={!isValid}
                      style={{ background: '#344f79' }}
                      onClick={() => {
                        // createCategory();
                      }}
                    >
                      Save
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            )}
          />
        )}
      </Mutation>
    );
  }
}

export default AddJourneyModalInner;
