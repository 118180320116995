import React, { useState } from 'react';
import { CarouselCard } from '../YearlyReview';
import { PlateHeart } from '../icons/PlateHeart';
import { Completed, CompletedBackground } from '../pages/Completed';

const SECTION_BACKGROUND = '#FDF5F2';
const SECTION_NAME = 'Your time matters';

const getStories = (onRestart) => {
  return [
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#FDF5F2',
      duration: 1000000,
      content: <Completed onRestart={onRestart} />,
      background: <CompletedBackground />,
      show: true,
      analytics: {
        section: 'Completed',
        screen: 'Completed',
      },
    },
  ];
};

const CareSectionInner = ({ isActive, setActive, data, active, onAllStoriesEnd, onClose, onRestart }) => {
  const [stories, setStories] = useState(getStories(onRestart).filter((x) => !!x.show));

  return (
    <CarouselCard
      onClose={onClose}
      hideIndicators
      sectionNameColor="white"
      stories={stories}
      sectionName={SECTION_NAME}
      section={{ inactiveIcon: <PlateHeart /> }}
      isActive={isActive}
      backgroundColor={SECTION_BACKGROUND}
      onAllStoriesEnd={onAllStoriesEnd}
    />
  );
};

export const CompletedSection = ({ isActive, setActive, active, onAllStoriesEnd, onClose, onRestart }) => {
  if (!isActive) return null;
  return (
    <CareSectionInner
      isActive={isActive}
      active={active}
      setActive={() => {
        setActive(0);
      }}
      onAllStoriesEnd={onAllStoriesEnd}
      onClose={onClose}
      onRestart={onRestart}
    />
  );
};
