import React from 'react';
import { Text, Card, Button } from 'rebass';
import PropTypes from 'prop-types';

const ClaimKitConfirmed = ({ kitPreviouslyClaimed, claimInformation }) => {
  return (
    <Card p={4}>
      {kitPreviouslyClaimed ? (
        <>
          <Text
            fontSize="38px"
            fontWeight={600}
            mb={4}
            textAlign="center"
            color="#344f79"
            lineHeight="44px"
            style={{ fontFamily: 'Playfair Display' }}
          >
            Oooops!
          </Text>
          <Text
            textAlign="center"
            lineHeight="24px"
            fontSize="18px"
            fontWeight={200}
            color="#344f79"
            mb="36px"
          >
            This Kit has been claimed already
            <br />
            <br />
            If you think this is an error, please contact us
          </Text>
          <Button
          variant='primary'
            onClick={() => {
              if (window.Beacon) {
                window.Beacon('open');
              }
            }}
          >
            Contact Us
          </Button>
        </>
      ) : (
        <>
          <Text
            fontSize="38px"
            fontWeight={600}
            mb={4}
            textAlign="center"
            color="#344f79"
            lineHeight="44px"
            style={{ fontFamily: 'Playfair Display' }}
          >
            Your Kit is Claimed!
          </Text>
          <Text
            textAlign="center"
            lineHeight="24px"
            fontSize="18px"
            fontWeight={200}
            color="#344f79"
            mb="36px"
          >
            You will receive your kit at home soon.
            <br />
            <br />
            In honor of January Thyroid Awareness Month, Paloma Health is
            committed to raise awareness for thyroid conditions. Thank you for
            trusting us with your health.
            <br />
            <br />
            {claimInformation && claimInformation.purchaserName
              ? `Remember to thank ${claimInformation.purchaserName} for this gift.`
              : null}
          </Text>
          <Button
          variant='primary'
            onClick={() =>
              (window.location.href = 'http://www.palomahealth.com/tam')
            }
          >
            Learn More
          </Button>
        </>
      )}
    </Card>
  );
};

ClaimKitConfirmed.propTypes = {
  kitPreviouslyClaimed: PropTypes.bool,
};

ClaimKitConfirmed.defaultProps = {
  kitPreviouslyClaimed: false,
};

export default ClaimKitConfirmed;
