import React from 'react';
import {
  Flex,
  Box,
  Text,
  Heading,
  Button,
} from 'rebass';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import moment from 'moment';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';

import { GET_MY_REFERRALS_HISTORY } from '../../graphql';
import PleaseWait from '../../components/PleaseWait';

const ModalInner = styled(Box)`
  max-width: 800px;
  width: 100%;
`;

const getColumnWidth = (rows, accessor, headerText, dataType = '') => {
  const maxWidth = 400;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...rows.map(
      row =>
        (
          `${
            dataType === 'money'
              ? `$ ${row[accessor]}`
              : dataType === 'date'
              ? moment(row[accessor]).format('MM-DD-YYYY hh:mm a')
              : row[accessor]
          }` || ''
        ).length
    ),
    headerText.length + 4
  );
  return Math.min(maxWidth, cellLength * magicSpacing);
};

const ReferralsHistoryModalInner = props => {
  const { onClose } = props;
  return (
    <ModalInner>
      <Heading mb={4} mx={3} textAlign="center">
        Your Referral History
      </Heading>
      <Query query={GET_MY_REFERRALS_HISTORY}>
        {({ loading, data: { getMyReferralsHistory }, error }) => {
          if (loading) {
            return <PleaseWait />;
          }

        const columns = [
          {
            Header: 'date',
            accessor: 'date',
            Cell: row => (
              <Text textAlign="left">
                {moment(row.value).format('MM-DD-YYYY hh:mm a')}
              </Text>
            ),
            width: getColumnWidth(
              getMyReferralsHistory,
              'date',
              'date',
              'date'
            ),
          },
          {
            Header: 'description',
            accessor: 'description',
          },
          {
            Header: 'amount',
            accessor: 'centisizedAmount',
            Cell: row => (
              <Text
                textAlign="right"
                style={{ color: row.value < 0 ? 'rgb(237, 183, 168)' : '' }}
              >{`$ ${((row.value || 0) / 100).toFixed(2)}`}</Text>
            ),
            width: getColumnWidth(
              getMyReferralsHistory,
              'centisizedAmount',
              'amount',
              'money'
            ),
          }]
          return (<ReactTable
          data={getMyReferralsHistory}
          columns={columns}
          defaultPageSize={
            getMyReferralsHistory &&
            getMyReferralsHistory.length > 3
              ? Math.min(getMyReferralsHistory.length, 15)
              : 3
          }
          showPagination={
            getMyReferralsHistory &&
            getMyReferralsHistory > 15
          }
        ></ReactTable>)
        }}
      </Query>
      <Flex
        flexDirection={['column', 'column', 'row']}
        mt={4}
        justifyContent="center"
      >
        <Button variant="primary" ml={[0, 0, 3]} onClick={onClose}>
          Close
        </Button>
      </Flex>
    </ModalInner>
  );
};

export default ReferralsHistoryModalInner;
