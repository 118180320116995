import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass';

import Logo from './Logo';
import SpinLoader from './SpinLoader';
import PleaseWait from './PleaseWait';
import FullLayout from './FullLayout'

const ScreenWrapper = styled.div`
  position: ${props =>
    props.position
      ? props.position
      : 'absolute'};
  min-height: 100vh;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: ${props =>
    props.bg
      ? props.bg
      : 'linear-gradient(to bottom, #344f79 39%,#6b7f9d 100%)'};
  opacity: ${props => (props.loading ? 1 : 0)};
  visibility: ${props => (props.loading ? 'visible' : 'hidden')};
  transition: opacity 0.4s, visibility -0.3s linear 0.5s;
  color: #dadfe6;
`;

const LoadingComponents = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
`;

const LogoContainer = styled(Box)`
  max-width: 200px;
`;

const LoadableData = styled.div`
  display: ${props => (props.loading ? 'none' : 'block')};
`;

const LoadingScreen = props => {
  const { children, bg, color, loading, text, textWeight="900", showLogo = true, layoutStyle={} } = props;

  return (

    <FullLayout loading={loading} style={{
  opacity: `${props => (props.loading ? 1 : 0)}`,
  visibility: `${props => (props.loading ? 'visible' : 'hidden')}`,
  transition: 'opacity 0.4s, visibility -0.3s linear 0.5s',
  ...layoutStyle}}>
    <Fragment>
        <LoadingComponents>
          {showLogo && (
            <LogoContainer mb={4} mx="auto">
              <Logo dark={false} />
            </LogoContainer>
          )}
          <PleaseWait  text={text || ' '} spinner={{
            sColor:"#ECEFF2", pColor:"#909FB5"
          }} />
          {/* <Flex justifyContent="center">
            <SpinLoader sColor="#ECEFF2" pColor="#909FB5" />
          </Flex>
          {text && (
            <Text mt={4} fontSize={3} fontWeight={textWeight} color={color}>
              {text}
            </Text>
          )} */}
        </LoadingComponents>
      {children && <LoadableData loading={loading}>{children}</LoadableData>}
    </Fragment>
      </FullLayout>
  );
};

LoadingScreen.propTypes = {
  bg: PropTypes.string,
  spinnerColor: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

export default LoadingScreen;
