import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';

const Inner = styled(Flex)`
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 480px) {
    max-height: 100%;
  }
`;

const MobileView = styled(Flex)`
  flex: 1;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const InnerChildren = styled(Flex)`
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 52em) {
    margin-top: 32px;
  }

  @media screen and (max-width: 480px) {
    overflow: auto;
    height: calc(100% - 20px);
    max-height: calc(100% - 20px);
    margin-top: 16px;
    padding-bottom: 40px;
  }
`;

const A = styled.a`
  display: flex;
  text-decoration: none;

  &:visited {
    color: #344f79;
  }
`;

const SidebarStyled = styled(Flex)`
  width: 250px;
  height: 100%;
  min-height: 100vh;
  background-color: #fdf5f2;
  box-shadow: ${(props) => (props.open && props.showShadow ? 'inset -8px 0 9px -3px rgb(0 0 0 / 5%)' : '')};
  position: fixed;
  z-index: 2;
  flex: 'none';
  transform: ${(props) => (props.open ? 'translateX(0)' : 'translateX(-3000%)')};
  padding-top: 80px;
  transition: 0.3s;

  @media screen and (min-width: 52em) {
    transform: ${(props) => (props.forceClose ? 'none' : 'translateX(-3000%)')};
    box-shadow: ${(props) => (props.forceClose && props.showShadow ? 'inset -8px 0 9px -3px rgb(0 0 0 / 5%)' : '')};
    transition: none;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    background: #fdf5f2;
    min-height: 100%;
  }
`;

const Sidebar = ({ children, ...props }) => {
  return (
    <SidebarStyled flexDirection="column" {...props}>
      <Inner flex={1} flexDirection="column">
        <InnerChildren flexDirection="column" zIndex={2}>
          {children}
        </InnerChildren>
      </Inner>
    </SidebarStyled>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
};

Sidebar.defaultProps = {
  open: false,
};

export default Sidebar;
