import React, { Component, Fragment } from 'react';
import { Flex, Box, Button, Text, Heading, Card } from 'rebass';
import styled from 'styled-components';
import { compose, withApollo } from 'react-apollo';
import { withWizard } from 'react-albus';
import { withToastManager } from 'react-toast-notifications';

import LoadingScreen from '../../components/LoadingScreen';

import { CHARTS, CURRENT_USER } from '../../graphql';
import moment from 'moment';
import withSession from '../../lib/withSession';
import { sleep } from '../../utils/sleep';
import ScrollAnimation from 'react-animate-on-scroll';
import PleaseWait from '../PleaseWait';
import { bounceIn } from '../../utils/animationsStyleBuilder';

const Skip = styled(Text)`
  cursor: pointer;
`;

class Information extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    console.log('CDM');
    this.handleSubmit();
  }

  handleSubmit = async () => {
    console.log('HandleSubmit');
    const { client, toastManager, data, refetch } = this.props;
    this.setState({ loading: true });
    
    // console.log('Submit', this.props.userData)

    try {
      // console.log(this.props.userData)
      // if (!this.props.userData.length && data.status !== 'completed') {
      //   this.setState({ loading: false });
      // }
      // console.log({
      //   toSend: {
      //     data: this.props.userData,
      //     intakeStatus: data.status,
      //     intakeDate:
      //       data.status === 'completed' ? moment().format('MM-DD-YYYY') : null,
      //       intakeType: this.props.intakeType || 'hypo'
      //   }
      // })
      // this.setState({ loading: false });
      // return

      await client.mutate({
        mutation: CHARTS,
        variables: {
          data: this.props.userData,
          intakeStatus: data.status,
          intakeDate: data.status === 'completed' ? moment().format('MM-DD-YYYY') : null,
          intakeType: this.props.intakeType || 'hypo',
        },
        refetchQueries: [{ query: CURRENT_USER }],
        awaitRefetchQueries: true,
      });
    } catch (err) {
      console.log('Error submission', err);
      this.setState({ loading: false });
      toastManager.add('Error submitting intake form', { appearance: 'error' });
      return;
    }
    try {
      // await refetch();
      await this.props.resetState();
      // this.handleRoute(skip);

      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  handleRoute = (skip) => {
    console.log('clk')
    const { data, wizard, moveToNext } = this.props;
    if (data.status === 'completed') {
      wizard.history.push(data.link);
    } else {
      if(skip) {
        wizard.push(skip)
        return
      }
      console.log('clk nxt', wizard)
      wizard.next()

      // skip ? wizard.push(skip) : wizard.next();
    }
  };

  render() {
    const { data, wizard } = this.props;
    const { loading } = this.state;

    if (loading) {
      return <PleaseWait />;
    }

    return (
      <Fragment>
        <Flex justifyContent="center" style={{ zIndex: 1 }}>
          <Flex width="600px" p={3}>
            <Card style={{...bounceIn()}}>
              <Flex flexDirection="column">
                <Box p={2} pt={4}>
                  <Text fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
                    {data.title}
                  </Text>
                </Box>

                <Box p={3} alignSelf="center">
                  {data.text.map((el, ind) => (
                    <Fragment key={ind}>
                      <Text lineHeight={1.6} textAlign="center">
                        {el}
                      </Text>
                    </Fragment>
                  ))}
                </Box>
              </Flex>

              <Flex alignItems="center" flexDirection="column" p={3}>
                <Button type="button" variant="pink" width={[1, 1 / 2]} mb={3} onClick={() => this.handleRoute()}>
                  {data.buttonText || 'Next'}
                </Button>

                {/* {data.skip && (
                  <Skip mb={3} onClick={() => this.handleRoute(data.skip)}>
                    Skip
                  </Skip>
                )} */}
              </Flex>
            </Card>
          </Flex>
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager,
  withSession
)(Information);
