import React from 'react';
import { Button, Card, Flex, Text } from 'rebass';
import { getLogoSource } from '../../../scheduleDoctor/helpers/insuranceLogos';

export const OnFileInsurance = ({ insurance, useOnFileInsurance, wizard, session, InsuranceMemberChanged, insuranceMemberLost }) => {
  return (
    insurance &&
    insurance.length > 0 &&
    insurance[0].insuranceIdNumber !== null &&
    insurance[0].insuranceIdNumber !== '' && (
      <Card p={4} mb={4}>
        <Flex mb={4} flexDirection="column">
          <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
            <Flex flexDirection={'column'}>
              <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                <div>
                  <img src={getLogoSource(insurance[0].insuranceCompany)} alt="" width="220px" />
                </div>
              </Flex>
              {insurance[0].insuranceIdNumber === 'TmpIns-1' ? (
                <Text textAlign="center" mt={4}>
                  Your insurance information is under review by our team
                </Text>
              ) : (
                <>
                  <Text fontWeight="600" textAlign="center" mt={4} fontSize="24px">
                    We Have an Insurance on File
                  </Text>
                  <Text textAlign="center" mt={4} fontSize="18px">
                    {`Member ID: ${insurance[0].insuranceIdNumber}`}
                  </Text>
                </>
              )}

              <Button
                variant="pink"
                mt={4}
                onClick={() => {
                  useOnFileInsurance(insurance[0], wizard);
                }}
              >
                {insurance[0].insuranceIdNumber === 'TmpIns-1' ? 'Continue' : 'Continue with this insurance'}
              </Button>
            </Flex>
          </Flex>
          {session &&
            session.isMember &&
            session.membershipSpecific === 'insurance' && (
              <>
                <div
                  style={{
                    height: '1px',
                    backgroundColor: '#ccc',
                    margin: '40px -32px',
                  }}
                />
                <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column" textAlign="center">
                  <Button
                    mb={3}
                    variant={'primary'}
                    onClick={() => {
                      if (InsuranceMemberChanged) InsuranceMemberChanged();
                    }}
                  >
                    My insurance has changed
                  </Button>
                  <Button
                    variant={'primary'}
                    onClick={() => {
                      if (insuranceMemberLost) insuranceMemberLost();
                    }}
                  >
                    I lost my coverage
                  </Button>
                </Flex>
              </>
            )}
        </Flex>
      </Card>
    )
  );
};
