import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { compose, withApollo, Mutation } from 'react-apollo';
import SpinLoader from '../components/SpinLoader';
import Dropzone from 'react-dropzone';
import addFile from '../static/addFile.png';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';
import { ADMIN_BATCH_MIGRATE_PATIENTS_TO_NEW_PROVIDER_GROUP } from '../graphql';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;
const StyledText = styled(Text)`
  line-height: 1.6;
  border-bottom: 1px solid;
  color: white;
  text-align: center;

  &:hover {
    cursor: pointer;
    font-weight: 600;
  }
`;

const UploadedRecord = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  align-items: center;
  grid-template-areas: 'icon content button';
`;

const Inner = styled.section`
  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;
class BatchMapToNewProviderGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentFile: null,
      res: null,
    };
  }

  fileAccepted = (f, callback) => {
    const element = f;
  
    const reader = new FileReader();
    reader.onload = event => {
      let fls = f;
      fls = {
        fileName: element.name,
        mimeType: element.type,
        data: event.target.result,
      };
  
      callback(fls);
    };
    reader.onerror = e => {
      console.log('Err', e);
    };
    reader.readAsDataURL(element);
  };

  handleRejected = ([file]) => {
    const { toastManager } = this.props;
    toastManager.add('Only PDF files are supported', {
      appearance: 'error',
    });
  };

  handleError = error => {
    const { toastManager, deleteFile } = this.props;
    console.log(error);
    toastManager.add('Unable to upload file, please try again', {
      appearance: 'error',
    });

    this.setState({
      currentFile: {},
    });
  };
  render() {
    const { currentFile, res } = this.state;
    return (
      <Container>
        <Flex m={[3, 5]} flex={1} flexDirection="column">
          <Heading mb={4} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
            Batch Map Local DB patient account to Athena ID
          </Heading>
          <Card mb={4} p={[3, 4]}>
            <Mutation
              mutation={ADMIN_BATCH_MIGRATE_PATIENTS_TO_NEW_PROVIDER_GROUP}
              onError={this.handleError}
              onCompleted={res => {
                // uploadFile(
                //   this.state.currentFile,
                //   res.uploadLabResult.labResultId
                // );
                console.log(res);

                this.setState({
                  currentFile: {},
                  res: res.batchMigratePatientsToNewProviderGroup,
                });
              }}
            >
              {(mutate, { loading }) => (
                <Dropzone
                  id="dropzone"
                  multiple={false}
                  accept="text/csv"
                  onDropRejected={(e,a)=>{
                    console.log({e,a})
                  }}
                  onDropAccepted={([file]) => {

                    
                this.fileAccepted(file, async ff => {
                  await mutate({ variables: { file: ff.data } });
                });
                    
                    
                    // startUploadFileProcess(file);
                    this.setState(
                      {
                        currentFile: file,
                      },
                    );
                  }}
                  maxSize={10000000}
                  style={{ width: '100%', height: '100%' }}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <Inner>
                      <div
                        {...getRootProps()}
                        className={`dropzone ${
                          isDragActive ? 'dropzone--isActive' : ''
                        }`}
                      >
                        <input type="file" {...getInputProps()} />
                        <img src={addFile} height={36} alt="" />
                        {loading ? (
                          <Fragment>
                            <div
                              style={{
                                textAlign: 'center',
                                overflowX: 'hidden',
                                overflowY: 'hidden',
                              }}
                            >
                              <Text
                                fontSize={3}
                                fontWeight={500}
                                mr={2}
                                style={{
                                  marginTop: '36px',
                                  marginBottom: '18px',
                                }}
                              >
                                Uploading your file
                              </Text>
                              {currentFile &&
                                currentFile.name && (
                                  <Text
                                    fontSize={3}
                                    fontWeight={300}
                                    mr={2}
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      marginBottom: '36px',
                                    }}
                                  >
                                    {currentFile.name}
                                  </Text>
                                )}
                              <SpinLoader
                                width={32}
                                height={32}
                                style={{ margin: '0 auto' }}
                              />
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Text fontSize={[2]} style={{ marginTop: '24px' }}>
                              Drop your file here. Process will automatically start on drop
                            </Text>
                            {/* {showUpload && ( */}
                            {/* <Button
                              variant="pink"
                              width={[1, 1 / 2, 1 / 4]}
                              style={{ marginTop: '36px' }}
                              type="button"
                              onClick={() => {}}
                            >
                              Upload
                            </Button> */}
                            {/* )} */}
                          </Fragment>
                        )}
                      </div>
                    </Inner>
                  )}
                </Dropzone>
              )}
            </Mutation>
          </Card>
          {res ? (
            <>
              <div>success: {res.ok.toString()}</div>
              {res.customError && <div>error: {res.customError.errorMessage}<br /> {res.customError.userMessage}</div>}
              {res.result && (
                <div>
                  {res.result.map(x => {
                    return (
                      <>
                        <div>{x.record}</div>
                        <div>result: {x.result}</div>
                        <div style={{ marginBottom: '18px' }}>
                          reason: {x.reason}
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </>
          ) : null}
        </Flex>
      </Container>
    );
  }
}
export default compose(
  withApollo,
  withToastManager
)(BatchMapToNewProviderGroup);
