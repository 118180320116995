import React, { useEffect, useState } from 'react';
import { Mutation } from 'react-apollo';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import ConfirmModal from '../components/ConfirmModal';
import FullLayout2 from '../components/FullLayout';
import PleaseWait from '../components/PleaseWait';
import { ACTIVATE_B2B_ELIGIBILITY, CURRENT_USER } from '../graphql';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;
const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;
const Confirm = ({ mutate }) => {
  useEffect(() => {
    mutate();
  }, []);
  return <div />;
};
const EligibilityConfirmation = ({ history, match: { params } }) => {
  const [result, setResult] = useState();
  return (
    <Mutation
      mutation={ACTIVATE_B2B_ELIGIBILITY}
      refetchQueries={[{ query: CURRENT_USER }]}
      awaitRefetchQueries={true}
      variables={{
        uid: params.uid,
      }}
      onCompleted={(data) => {
        setResult(data.activateEligibility);
      }}
    >
      {(mutate, { loading, error }) => {
        return (
          <>
            {!!loading && (
              <FullLayout2 style={{ zIndex: 10 }}>
                <Flex flex={1} justifyContent="center" alignItems={'center'}>
                  <Container>
                    <PleaseWait
                      text=" "
                      spinner={{
                        sColor: '#ECEFF2',
                        pColor: '#909FB5',
                      }}
                    />
                  </Container>
                </Flex>
              </FullLayout2>
            )}
            {!!result && (
              <ConfirmModal
                childrenManaged
                confirmLabel="OK"
                isOpen={!!result}
                okOnly
                cardStyle={{
                  maxHeight: '80vh',
                  position: 'relative',
                  overflowY: 'auto',
                }}
                onClose={() => {}}
                onConfirm={() => {}}
                onBackgroundClick={() => {}}
                onEscapeKeydown={() => {}}
              >
                <ModalInner>
                  <Flex flexDirection={'column'}>
                    {result.ok === true ? (
                      <>
                        <Heading mb={4} mx={3} textAlign="center">
                          You're In!
                        </Heading>

                        <Text textAlign='center' mb={3}>Your eligibility is confirmed and your account is active. You are now able to use Paloma Health under the terms defined by your sponsoring organization.</Text>

                        <Button variant={'primary'} onClick={() => history.push('/')} mt={3}>
                          Go To Dashboard
                        </Button>
                      </>
                    ) : (
                      <>
                        <Heading mb={4} mx={3} textAlign="center">
                          Error
                        </Heading>

                        <Text>{(result||{}).customError || 'An error occured'}</Text>

                        <Button variant={'primary'} onClick={() => history.goBack()} mt={3}>
                          Cancel
                        </Button>
                        <Button variant="outline" onClick={mutate}>
                          Retry
                        </Button>
                      </>
                    )}
                  </Flex>
                </ModalInner>
              </ConfirmModal>
            )}
            <Confirm mutate={mutate} />
          </>
        );
      }}
    </Mutation>
  );
};

export default withRouter(EligibilityConfirmation);
