import React, { Fragment } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';
import { compose, withApollo } from 'react-apollo';
import * as Yup from 'yup';

import { TextInput } from '../../components/TextInput';
import { FormField } from '../../components/FormField';
import { MaskInput } from '../../components/MaskInput';
import { withToastManager } from 'react-toast-notifications';

class Profile extends React.Component {
  constructor(props) {
    super(props);

    const hasAddress =
      this.props.values &&
      this.props.values.street1 &&
      this.props.values.street1.length > 0;
    this.state = {
      isFormExtended: hasAddress,
    };
  }

  submitCheck = async (values, actions) => {
    actions.setSubmitting(false);
    this.props.onSubmit(
      {
        street1: values.street1,
        street2: values.street2,
        zip: values.zip,
        city: values.city,
      },
      actions,
      this.props.wizard
    );
  };

  render() {
    const schema = Yup.object().shape({
      street1: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      zip: Yup.string().required('Required'),
    });

    return (
      <Flex flexDirection="column" alignItems="center">
        <Flex width={[1, 1, 3 / 4]}>
          <Card p={4}>
            <Flex flexDirection="column">
              <Box mb={4}>
                <Heading textAlign="center" fontWeight={550} mb={3}>
                  Set Up Your Profile
                </Heading>

                <Text textAlign="center">
                  Let's get started with some basic information.
                </Text>
              </Box>
              <Formik
                isInitialValid={({ initialValues }) =>
                  schema.isValidSync(initialValues)
                }
                initialValues={{
                  firstName: this.props.values.firstName,
                  lastName: this.props.values.lastName,
                  state: this.props.values.state,
                  phone: this.props.values.phone,
                }}
                validationSchema={schema}
                onSubmit={(values, actions) =>
                  this.submitCheck(values, actions)
                }
                render={({
                  touched,
                  errors,
                  isValid,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  values,
                  setFieldValue,
                  setFieldTouched,
                }) => {
                  let errStreet1
                  if(touched.street1 && errors.street1){
                    errStreet1=errors.street1
                  }else if(values && values.street1 && values.street1.indexOf('@')>-1){
                    errStreet1='incorrect street address'
                  }else{
                    errStreet1=null
                  }
                  return (
                    <Form>
                      <Fragment>
                        <Flex flexDirection={['column', 'column', 'row']}>
                          <FormField
                            flex={[1, 1, 1 / 2]}
                            mb={3}
                            mr={[0, 0, 3]}
                            error={touched.firstName && errors.firstName}
                          >
                            <TextInput
                              disabled
                              placeholder="First Name"
                              type="text"
                              name="firstName"
                              id="input_firstname"
                            />
                          </FormField>
                          <FormField
                            flex={[1, 1, 1 / 2]}
                            mb={3}
                            error={touched.lastName && errors.lastName}
                          >
                            <TextInput
                              disabled
                              placeholder="Last Name"
                              type="text"
                              name="lastName"
                              id="input_lastname"
                            />
                          </FormField>
                        </Flex>

                        <>
                          <FormField
                            mb={3}
                            error={errStreet1}
                          >
                            <TextInput
                              placeholder="Address"
                              type="text"
                              name="street1"
                            />
                          </FormField>

                          <FormField
                            mb={3}
                            error={touched.street2 && errors.street2}
                          >
                            <TextInput
                              placeholder="Apartment, Suite, Floor (Optional)"
                              type="text"
                              name="street2"
                            />
                          </FormField>

                          <Flex flexDirection={['column', 'column', 'row']}>
                            <FormField
                              width={[1, 1, 1 / 3]}
                              mr={[0, 0, 3]}
                              mb={3}
                              error={touched.city && errors.city}
                            >
                              <TextInput
                                placeholder="City"
                                type="text"
                                name="city"
                              />
                            </FormField>

                            <FormField
                              width={[1, 1, 1 / 3]}
                              mr={[0, 0, 3]}
                              mb={3}
                              error={touched.state && errors.state}
                            >
                              <TextInput
                                placeholder="State"
                                type="text"
                                name="state"
                                disabled
                                value={this.props.values.state.label}
                              />
                              {/* <RegionSelect
                                name="state"
                                disabled={true}
                                value={this.props.values.state.value}
                              /> */}
                            </FormField>

                            <FormField
                              width={[1, 1, 1 / 3]}
                              mb={3}
                              error={touched.zip && errors.zip}
                            >
                              <TextInput
                                placeholder="Zip"
                                type="text"
                                name="zip"
                              />
                            </FormField>
                          </Flex>
                        </>

                        <FormField
                          mt={3}
                          mb={4}
                          error={touched.phone && errors.phone}
                        >
                          <MaskInput
                            name="phone"
                            placeholder="Mobile Phone Number"
                            mask="999-999-9999"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="input_phone"
                          />
                        </FormField>

                        <Flex justifyContent="center">
                          <Button
                            disabled={!isValid || isSubmitting || !values || !values.street1 || values.street1.indexOf('@')>-1}
                            variant="primary"
                            width={[1 / 2, 1 / 3]}
                            type="submit"
                            id="btn_next"
                          >
                            Next
                          </Button>
                        </Flex>
                      </Fragment>
                    </Form>
                  );
                }}
              />
            </Flex>
          </Card>
        </Flex>
      </Flex>
    );
  }
}

export default compose(
  withApollo,
  withToastManager,
  withWizard
)(Profile);
