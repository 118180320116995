import React from 'react';
import { Flex, Text, Box } from 'rebass';
import { GraphPink } from '../icons/GraphPink';
import { InnerRightHormone } from '../icons/InnerRightHormone';
import styled, { keyframes } from 'styled-components';
const slideLeft = keyframes`
  0% {
    /* opacity: 0; */
    transform: translateX(-350px);
  };
  100% {
    /* opacity: 1; */
    transform: translateX(0);
  };
`;

const Opaciter = keyframes`
  0% {
    opacity: 0;
    /* transform: translateX(-350px); */
  };
  100% {
    opacity: 1;
    /* transform: translateX(0); */
  };
`;

const Bar = styled(Flex)`
  padding: 8px 16px;
  border-radius: 0 80px 80px 0;
  min-height: 60px;
  background-color: #344f7a;
  animation: 1s 1 forwards ease-in ${slideLeft};
  justify-content: flex-end;
`;

const Item = styled(Text)`
  opacity: 0;
  animation: 1s 1 forwards ease-in ${Opaciter};
  animation-delay: ${(props) => props.delay};
  margin-left: 12px;
  font-size: 22px;
`;

export const TopCategoriesBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <InnerRightHormone
        style={{
          position: 'absolute',
          right: 0,
          bottom: 120,
        }}
      />
      <GraphPink width={700} style={{ position: 'absolute', bottom: 0, left: 0 }} stroke="#C7DDFC" />
    </Flex>
  );
};

export const TopCategories = ({ categories = [] }) => {
  return (
    <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', flexDirection: 'column', zIndex: 100 }}>
      <Text style={{ fontSize: window.matchMedia('(min-width: 52em)').matches?36:26, fontWeight: 700, textAlign: 'center', lineHeight: '90px', marginBottom: '36px' }}>Your top categories were</Text>
      {categories.map((x, i) => {
        return (
          <Flex key={`topCat${i}`} style={{ width: 'calc(100% + 18px)', marginLeft: '-18px', overflow: 'hidden', marginTop: '24px', alignItems: 'center' }}>
            <Bar style={{ width: `min(calc(50% - ${i * 30}px),${350 - i * 50}px)` }}>
              <Text style={{ color: '#F8A294', fontFamily: 'Playfair Display', fontSize: 50, marginTop: '-14px' }}>{`#${i + 1}`}</Text>
            </Bar>
            <Item delay={`${700 + (i + 1) * 100}ms`}>{x}</Item>
          </Flex>
        );
      })}
      {/* <Flex style={{ width: 'calc(100% + 18px)', marginLeft: '-18px', overflow: 'hidden' }}>
        <Bar style={{ width: '350px' }}>
          <Text style={{ color: '#F8A294', fontFamily: 'Playfair Display', fontSize: 50, marginTop: '-14px' }}>#1</Text>
          <Item delay={``}>Hashi</Item>
        </Bar>
      </Flex>
      <Flex style={{ width: 'calc(100% + 18px)', marginLeft: '-18px', overflow: 'hidden', marginTop: '20px' }}>
        <Bar style={{ width: '250px' }}>
          <Text style={{ color: '#F8A294', fontFamily: 'Playfair Display', fontSize: 50, marginTop: '-14px' }}>#2</Text>
        </Bar>
      </Flex>
      <Flex style={{ width: 'calc(100% + 18px)', marginLeft: '-18px', overflow: 'hidden', marginTop: '20px' }}>
        <Bar style={{ width: '150px' }}>
          <Text style={{ color: '#F8A294', fontFamily: 'Playfair Display', fontSize: 50, marginTop: '-14px' }}>#3</Text>
        </Bar>
      </Flex> */}
    </Flex>
  );
};
