import React from 'react';

export const Clock = () => {
  return (
    <svg width="158" height="155" viewBox="0 0 317 311" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M151 332C242.679 332 317 257.679 317 166C317 74.321 242.679 0 151 0C59.321 0 -15 74.321 -15 166C-15 257.679 59.321 332 151 332Z"
        fill="#9AB3DA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M151 43.8481C156.19 43.8481 160.396 39.6414 160.396 34.4519C160.396 29.2624 156.19 25.0557 151 25.0557C145.81 25.0557 141.604 29.2624 141.604 34.4519C141.604 39.6414 145.81 43.8481 151 43.8481Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4528 175.398C24.6427 175.398 28.8491 171.192 28.8491 166.002C28.8491 160.812 24.6427 156.605 19.4528 156.605C14.263 156.605 10.0566 160.812 10.0566 166.002C10.0566 171.192 14.263 175.398 19.4528 175.398Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M282.547 175.398C287.737 175.398 291.943 171.192 291.943 166.002C291.943 160.812 287.737 156.605 282.547 156.605C277.357 156.605 273.151 160.812 273.151 166.002C273.151 171.192 277.357 175.398 282.547 175.398Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M151 306.944C156.19 306.944 160.396 302.737 160.396 297.548C160.396 292.358 156.19 288.151 151 288.151C145.81 288.151 141.604 292.358 141.604 297.548C141.604 302.737 145.81 306.944 151 306.944Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M151 53.248L157.264 162.871H144.736L151 53.248Z" fill="#354E79" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M206.434 221.432L148.851 172.709L157.709 163.852L206.434 221.432Z" fill="#354E79" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M151 178.529C157.919 178.529 163.528 172.92 163.528 166.001C163.528 159.082 157.919 153.473 151 153.473C144.081 153.473 138.472 159.082 138.472 166.001C138.472 172.92 144.081 178.529 151 178.529Z"
        fill="#A1C5FF"
      />
    </svg>
  );
};
