import React from 'react';
import { withRouter } from 'react-router';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';

import withSession from '../lib/withSession';
import FullLayout from '../components/FullLayout';
import ChatDoctor from '../static/your_doctor.png';
import ScheduleCall from '../static/ScheduleCall.png';
import { isToggleActive } from '../components/featureToggle/toggles';

const Wrapper = styled.div`
  padding-left: 60px;
  padding-top: 40px;
  @media screen and (max-width: 52em) {
    padding-left: 12px;
  }
`;

const Container = styled(Flex)`
  flex: 1;
  background: white;
  border-radius: 8px 8px 8px 8px;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0px 2.41029px 24.1029px rgba(0, 0, 0, 0.1);
`;

const Container2 = styled(Flex)`
  flex: 1;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  padding: 12px 20px 20px;
  border-bottom: 1px solid #d1d1d1;
`;
const Entry = styled(Flex)`
  flex: 1;
  flex-direction: row;
`;

const EntryWrapper = styled(Flex)`
  flex: 1;
  flex-direction: column;
  background-color: white;
  margin: 6px 12px;
`;

const TitleRow = styled(Flex)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 22px;
`;

const PriceText = styled(Text)`
  font-weight: 600;
  color: #f6a293;
`;

const MyCareTeam = ({ session: { isMember, dedicatedCareManager }, history }) => {
  if (!isMember) {
    history.push('/');
  }

  return (
    <Wrapper>
      <Container>
        <Container2
          onClick={() => {
            history.push('/chat-care');
          }}
        >
          <Entry>
            <img
              style={{ height: '68px', width: '78px' }}
              height="68px"
              width="78px"
              src={ChatDoctor}
              alt=""
            />
            <EntryWrapper>
              <TitleRow>
                <Title>Chat With Your Care Team</Title>
              </TitleRow>
              <Text style={{ fontWeight: 300 }}>
                Refills, scheduling, prescription,... {dedicatedCareManager ? dedicatedCareManager.firstName :'Brittany'} is here to help
                you with all your requests
              </Text>
            </EntryWrapper>
          </Entry>
        </Container2>
        <Container2
          onClick={() => {
            history.push('/schedule-care');
          }}
        >
          <Entry>
            <img
              style={{ height: '68px', width: '78px' }}
              height="68px"
              width="78px"
              src={ScheduleCall}
              alt=""
            />
            <EntryWrapper>
              <TitleRow>
                <Title>Schedule a call with your Care Manager</Title>
              </TitleRow>
              <Text style={{ fontWeight: 300 }}>
                Bigger question for your care manager? Schedule a 15’ phone call
              </Text>
            </EntryWrapper>
          </Entry>
        </Container2>
      </Container>
      <FullLayout />
    </Wrapper>
  );
};

export default withRouter(withSession(MyCareTeam));
