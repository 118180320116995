import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Stethoscope from '../../components/icons/Stethoscope';
import Beaker from '../../components/icons/Beaker';
import TextInput, { Input } from '../../components/TextInput';
import { Mutation, Query, withApollo } from 'react-apollo';
import { SEND_OTP_CODE, VERIFY_OTP_CODE } from '../../graphql';
import OtpInput from '../../components/OtpInput';
import SpinLoader from '../../components/SpinLoader';
import { withWizard } from 'react-albus';
import { Form, Formik } from 'formik';
import FormField from '../../components/FormField';
import { MaskInput } from '../../components/MaskInput';
import gql from 'graphql-tag';
import * as Yup from 'yup';

const FakeLink = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
  color: #44a5ff;
  margin-top: 24px;
`;
const phoneRegExp = /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

const PHONE_AVAILABLE = gql`
  query PhoneAvailable($phone: String!) {
    phoneAvailable(phone: $phone) {
      ok
    }
  }
`;

const EditPhoneNumber = ({ phoneNumber, onSubmit, client }) => {
  const [phoneTmp, setPhoneTmp] = useState(phoneNumber);
  const schema = Yup.object().shape({
    phone: Yup.string()
      .trim()
      .required('Required')
      .matches(phoneRegExp, 'Phone number is not valid'),
  });

  const submitCheck = async (values, actions) => {
    if (actions) {
      actions.setSubmitting(true);
    }
    setPhoneTmp(values.phone);
    try {
      const { data } = await client.query({
        query: PHONE_AVAILABLE,
        variables: {
          phone: values.phone,
        },
      });

      actions.setSubmitting(false);

      if (!data.phoneAvailable.ok) {
        actions.setFieldError(
          'phone',
          'Incorrect phone number. Error code 2077'
        );
        // throw Error('Number already in use');
      } else {
        onSubmit(values.phone);
      }
    } catch (error) {
      actions.setSubmitting(false);
      actions.setFieldError('phone', error.message);
    }
  };

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex>
        <Flex flexDirection="column">
          <Formik
            isInitialValid={({ initialValues }) =>
              schema.isValidSync(initialValues)
            }
            initialValues={{
              phone: phoneNumber,
            }}
            validationSchema={schema}
            onSubmit={(values, actions) => submitCheck(values, actions)}
            render={({
              touched,
              errors,
              isValid,
              handleChange,
              handleBlur,
              isSubmitting,
              values,
            }) => {
              // console.log('errors', errors);
              return (
                <Form>
                  <Flex flexDirection="row">
                    <FormField mb={4} error={touched.phone && errors.phone}>
                      <MaskInput
                        name="phone"
                        placeholder="Mobile Phone Number"
                        mask="999-999-9999"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="input_phone"
                        disabled={isSubmitting}
                        style={{
                          borderRadius: '8px 0 0 8px',
                        }}
                      />
                    </FormField>
                    <Button
                      disabled={!isValid || isSubmitting}
                      variant="primary"
                      type="submit"
                      id="btn_next"
                      style={{
                        marginTop: 0,
                        height: '63px',
                        borderRadius: '0 8px 8px 0',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        padding: '16px',
                      }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.7823 3.21751C20.681 3.11677 20.5532 3.04701 20.4137 3.01646C20.2742 2.9859 20.1288 2.9958 19.9948 3.04501L3.49476 9.04501C3.35246 9.09898 3.22995 9.19497 3.14349 9.32023C3.05704 9.44548 3.01074 9.59407 3.01074 9.74626C3.01074 9.89845 3.05704 10.047 3.14349 10.1723C3.22995 10.2975 3.35246 10.3935 3.49476 10.4475L10.6948 13.3275L13.5748 20.5275C13.6289 20.6636 13.7216 20.7808 13.8416 20.8648C13.9615 20.9487 14.1034 20.9957 14.2498 21C14.4013 20.9969 14.5484 20.9479 14.6716 20.8596C14.7947 20.7712 14.8882 20.6476 14.9398 20.505L20.9398 4.00501C20.9909 3.87232 21.0032 3.72783 20.9753 3.5884C20.9474 3.44897 20.8805 3.32034 20.7823 3.21751ZM14.2498 18.15L12.1573 12.9L15.7498 9.30751L14.6923 8.25001L11.0698 11.8725L5.84976 9.75001L18.9973 5.00251L14.2498 18.15Z"
                          fill="white"
                        />
                      </svg>
                    </Button>
                  </Flex>
                </Form>
              );
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const OtpInner = ({ mutate, verified, phone, changePhone, client }) => {
  const [value, setValue] = useState();
  const [isEditPhoneNumberMode, setIsEditPhoneNumberMode] = useState(false);
  useEffect(() => {
    mutate();
  }, []);

  return (
    <Mutation
      mutation={VERIFY_OTP_CODE}
      onCompleted={(e, a) => {
        setValue();
        // console.log({ e, a });
        if (
          !!e &&
          !!e.verifyOptCode &&
          !!e.verifyOptCode.ok &&
          !e.verifyOptCode.customError
        ) {
          verified();
        }
      }}
    >
      {(verify, { loading, error, data }) => {
        return (
          <Flex
            flexDirection="column"
            width={[1, 1, 1 / 2]}
            style={{ margin: '0 auto' }}
            alignSelf="center"
          >
            <Card mb={4} p={4}>
              <Flex mb={4} flexDirection="column">
                <Box
                  px={2}
                  style={{
                    width: '100%',
                  }}
                >
                  {!loading ? (
                    <>
                      <Heading textAlign="center" mb={3}>
                        {!isEditPhoneNumberMode
                          ? 'We sent you an SMS code'
                          : 'Update your phone number'}
                      </Heading>
                      {isEditPhoneNumberMode ? (
                        <EditPhoneNumber
                          phoneNumber={phone}
                          onSubmit={phone => {
                            changePhone(phone);
                            setIsEditPhoneNumberMode(false);
                          }}
                          client={client}
                        />
                      ) : (
                        <Flex
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Text mr={3} textAlign="center">
                            {`on number ${phone}`}
                          </Text>

                          <FakeLink
                            style={{ marginTop: 0 }}
                            onClick={() => setIsEditPhoneNumberMode(true)}
                          >
                            edit
                          </FakeLink>
                        </Flex>
                      )}
                    </>
                  ) : (
                    <Text>Verifying...</Text>
                  )}
                </Box>
                {loading ? (
                  <Flex
                    px={2}
                    flexDirection="column"
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '40px',
                    }}
                  >
                    <SpinLoader />
                  </Flex>
                ) : (
                  <Flex px={2} flexDirection="column">
                    <Text
                      mb={2}
                      textAlign="center"
                      mt={3}
                      style={{ fontWeight: 600 }}
                    >
                      Enter your code here
                    </Text>
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Input
                        style={{
                          maxWidth: '250px',
                          fontSize: '28px',
                          letterSpacing: '16px',
                          textAlign: 'center',
                        }}
                        placeholder="••••••"
                        autoComplete="one-time-code"
                        onChange={r => {
                          setValue(r.target.value);
                          if (r.target.value.length === 6) {
                            verify({
                              variables: {
                                phone: phone.replace(/-/g, ''),
                                otp: r.target.value,
                              },
                            });
                          }
                        }}
                        value={value}
                      />
                      {/* <OtpInput
                        onChange={r => {
                          setValue(r);
                          if (r.length === 6) {
                            verify({
                              variables: {
                                phone: phone.replace(/-/g, ''),
                                otp: r,
                              },
                            });
                          }
                        }}
                        numInputs={6}
                        separator={<span>&nbsp;</span>}
                        value={value}
                      /> */}
                      {!!data &&
                      !!data.verifyOptCode &&
                      !!data.verifyOptCode.customError ? (
                        <Text textAlign="center" color="red" mb={2} mt={3}>
                          {data.verifyOptCode.customError}
                        </Text>
                      ) : null}
                      {!!error ? (
                        <Text textAlign="center" color="red" mb={2} mt={3}>
                          An Error Occured
                        </Text>
                      ) : null}

                      <FakeLink onClick={mutate}>resend code</FakeLink>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Card>
          </Flex>
        );
      }}
    </Mutation>
  );
};

const Otp = ({ values, onSubmit, wizard, changePhone, client }) => {
  if (!values.phone) {
    wizard.previous();
    return null;
  }
  const phone = values.phone.replace(/-/g, '');
  return (
    <Mutation mutation={SEND_OTP_CODE} variables={{ phone }}>
      {(mutate, { loading }) => (
        <OtpInner
          changePhone={phone => {
            changePhone(phone);
            mutate({
              variables: {
                phone,
              },
            });
          }}
          mutate={mutate}
          phone={values.phone}
          verified={() => {
            onSubmit({ ...values, mfa: 'ok' }, null, wizard);
          }}
          client={client}
        />
      )}
    </Mutation>
  );
};

export default withWizard(withApollo(Otp));
