const identifyDuplicates = (options) => {
  const r = [];

  for (let index = 0; index < options.length; index++) {
    const element = options[index];
    const newId = options.findIndex((x) => x.label === element.label);
    if (newId !== index) {
      if (r.findIndex((xx) => xx === newId) < 0) {
        r.push(element.label);
      }
    }
  }
  return r;
};

export const getIsInError = (properties) => {
  if (!properties.key)
    return {
      error: true,
      errors: ['Missing Key'],
    };

  switch (properties.key) {
    case 'Header':
    case 'Label':
    case 'Paragraph':
    case 'Range':
    case 'Rating':
    case 'TextArea':
    case 'TextInput':
    case 'NumberInput':
    case 'Email':
    case 'Date':
      if (!properties.params || !properties.params.question) {
        return {
          error: true,
          errors: ['Missing question'],
        };
      }
      break;
    case 'Dropdown':
    case 'Checkboxes':
      const errs = {
        error: false,
        errors: [],
      };
      if (!properties.params || !properties.params.question) {
        errs.error = true;
        errs.errors.push('Missing question');
      }
      if (!properties.params || !properties.params.ctrlProps || !properties.params.ctrlProps.options || properties.params.ctrlProps.options.length < 1) {
        errs.error = true;
        errs.errors.push('Missing options');
      } else {
        const dups = identifyDuplicates(properties.params.ctrlProps.options);
        if (dups && dups.length > 0) {
          errs.error = true;
          errs.errors.push('Duplicated options');
        }
      }
      return errs;

    default:
      break;
  }
};
