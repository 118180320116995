import { Form, Formik } from 'formik';
import React from 'react';
import { Mutation } from 'react-apollo';
import Select from 'react-select';
import { Button, Flex, Box, Text } from 'rebass';
import FormField from '../../components/FormField';
import TextInput from '../../components/TextInput';
import { ADMIN_GET_BROKERS_LIST, ADMIN_UPSERT_BROKER } from '../../graphql';
import CheckboxInput from '../../components/CheckboxInput';
import { B2BExpiresAfterPeriods, convertExpiresAfterObjectToFriendlyString, convertFriendlyObjectToExpiresAfter } from '../../utils/b2bUtils';

const AddBroker = ({ onSave, onClose, broker }) => {
  return (
    <Mutation
      mutation={ADMIN_UPSERT_BROKER}
      refetchQueries={[{ query: ADMIN_GET_BROKERS_LIST }]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        onSave();
      }}
    >
      {(upsertBroker, { loading }) => (
        <Formik
          isInitialValid={
            ({ initialValues }) => true
            //   schema.isValidSync(initialValues)
          }
          initialValues={{
            ...(broker || {}),
            kit: ((broker || {}).defaultFreeness || {}).kit,
            vitamins: ((broker || {}).defaultFreeness || {}).vitamins,
            membership: ((broker || {}).defaultFreeness || {}).membership,
            visits: ((broker || {}).defaultFreeness || {}).visits,
            async: ((broker || {}).defaultFreeness || {}).async,
            nutritionist:((broker || {}).defaultFreeness || {}).nutritionist,
            period:B2BExpiresAfterPeriods.find(x=>x.value===convertFriendlyObjectToExpiresAfter(((broker || {}).defaultFreeness || {}).expiresAfter).period) ,
            duration: {
              label: convertFriendlyObjectToExpiresAfter(((broker || {}).defaultFreeness || {}).expiresAfter).duration,
              value: convertFriendlyObjectToExpiresAfter(((broker || {}).defaultFreeness || {}).expiresAfter).duration
            },
          }}
          // validationSchema={schema}
          onSubmit={async (values) => {
            let vars = {
              id: (broker || {}).id,
              name: values.name,
              friendlyName: values.friendlyName,
              iconUrl: values.iconUrl,
              kit: values.kit,
              vitamins: values.vitamins,
              membership: values.membership,
              visits: values.visits,
              async: values.async,
              nutritionist: values.nutritionist,
              expiresAfter: convertExpiresAfterObjectToFriendlyString((values.duration || {}).value, (values.period || {}).value),
              isActive: values.isActive,
            };

            await upsertBroker({
              variables: {
                ...vars,
              },
            });
          }}
          render={({ touched, errors, values, setFieldValue, setFieldTouched }) => (
            <Form>
              <Flex flexDirection="column" width="600px" style={{ maxHeight: '900px' }}>
                <FormField mb={4} error={touched.name && errors.name}>
                  <label for="name">Name *</label>
                  <TextInput name="name" placeholder="Name" />
                </FormField>
                <FormField mb={4} error={touched.friendlyName && errors.friendlyName}>
                  <label for="friendlyName">Friendly Name</label>
                  <TextInput name="friendlyName" placeholder="friendlyName" />
                </FormField>
                <FormField mb={4} error={touched.iconUrl && errors.iconUrl}>
                  <label for="iconUrl">Icon Url</label>
                  <Flex>
                    <Box flex={1}>
                      <TextInput name="iconUrl" placeholder="Icon URL" />
                    </Box>
                    <img src={values.iconUrl} alt="iconUrl" style={{ width: '60px', height: '60px' }} />
                  </Flex>
                </FormField>
                <FormField mb={4} error={touched.kit && errors.kit}>
                  <label for="kit">free kits</label>
                  <CheckboxInput
                    name="kit"
                    styles={{
                      checked: {
                        Box: {
                          backgroundColor: 'rgb(54, 79, 121)',
                        },
                      },
                    }}
                  />
                </FormField>
                <FormField mb={4} error={touched.vitamins && errors.vitamins}>
                  <label for="vitamins">free vitamins</label>
                  <CheckboxInput
                    name="vitamins"
                    styles={{
                      checked: {
                        Box: {
                          backgroundColor: 'rgb(54, 79, 121)',
                        },
                      },
                    }}
                  />
                </FormField>
                <FormField mb={4} error={touched.membership && errors.membership}>
                  <label for="membership">free membership</label>
                  <CheckboxInput
                    name="membership"
                    styles={{
                      checked: {
                        Box: {
                          backgroundColor: 'rgb(54, 79, 121)',
                        },
                      },
                    }}
                  />
                </FormField>
                <FormField mb={4} error={touched.visits && errors.visits}>
                  <label for="visits">free visits</label>
                  <CheckboxInput
                    name="visits"
                    styles={{
                      checked: {
                        Box: {
                          backgroundColor: 'rgb(54, 79, 121)',
                        },
                      },
                    }}
                  />
                </FormField>
                <FormField mb={4} error={touched.async && errors.async}>
                  <label for="async">free async visits</label>
                  <CheckboxInput
                    name="async"
                    styles={{
                      checked: {
                        Box: {
                          backgroundColor: 'rgb(54, 79, 121)',
                        },
                      },
                    }}
                  />
                </FormField>
                <FormField mb={4} error={touched.nutritionist && errors.nutritionist}>
                  <label for="nutritionist">free nutritionist visits</label>
                  <CheckboxInput
                    name="nutritionist"
                    styles={{
                      checked: {
                        Box: {
                          backgroundColor: 'rgb(54, 79, 121)',
                        },
                      },
                    }}
                  />
                </FormField>

                <Text>Expires after (set duration to empty or 0 to remove expiration):</Text>
                <Flex>
                  <Flex style={{ minWidth: '180px' }}>
                    <FormField style={{ minWidth: '180px' }} mb={4} error={touched.duration && errors.duration}>
                      <label for="duration">duration</label>
                      <Select
                        classNamePrefix="region-select"
                        options={[...Array(365).keys()].map((x) => {
                          return { label: x, value: x };
                        })}
                        name="duration"
                        value={values.duration}
                        placeholder="select duration"
                        onChange={(option) => {
                          setFieldValue('duration', option);
                        }}
                        onBlur={() => setFieldTouched('duration', true)}
                        isSearchable={true}
                      />
                    </FormField>
                  </Flex>
                  <Flex style={{ minWidth: '250px' }}>
                    <FormField style={{ minWidth: '250px' }} ml={2} mb={4} error={touched.async && errors.async}>
                      <label for="period">period</label>
                      <Select
                        classNamePrefix="region-select"
                        options={B2BExpiresAfterPeriods}
                        name="period"
                        value={values.period}
                        placeholder="select period"
                        onChange={(option) => {
                          setFieldValue('period', option);
                        }}
                        onBlur={() => setFieldTouched('period', true)}
                        isSearchable={true}
                      />
                    </FormField>
                  </Flex>
                </Flex>

                <FormField mb={4} error={touched.isActive && errors.isActive}>
                  <label for="isActive">Is Active</label>
                  <CheckboxInput
                    name="isActive"
                    styles={{
                      checked: {
                        Box: {
                          backgroundColor: 'rgb(54, 79, 121)',
                        },
                      },
                    }}
                  />
                </FormField>

                <Flex mt={4} flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Button
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button disabled={!!loading} style={{ background: '#344f79' }}>
                    {(broker || {}).id ? 'Update' : 'Save'}
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )}
        />
      )}
    </Mutation>
  );
};

export default AddBroker;
