import React from 'react';

const ProgressWrapper = (props) => <div style={{ ...styles.progress, ...getProgressWrapperStyle(props) }}>{props.children}</div>;

const getProgressWrapperStyle = ({ width, pause, bufferAction }) => ({
  width: `${width * 100}%`,
  opacity: pause && !bufferAction ? 0 : 1,
});

const styles = {
  progress: {
    height: 4,
    maxWidth: '100%',
    background: '#DBDBDB',
    margin: 2,
    borderRadius: 2,
    transition: 'opacity 400ms ease-in-out',
  },
};

export default ProgressWrapper;
