import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { FOLLOWUP_SURVEYS_ID } from '../../../utils/followupSurveysUtils';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { ADMIN_RESET_FOLLOWUP_SURVEY } from '../../../graphql';
import styled from 'styled-components';
import ConfirmModal from '../../../components/ConfirmModal';
import { Mutation } from 'react-apollo';
import FullLayout from '../../../components/FullLayout';
import { MaskInput } from '../../../components/MaskInput';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

export const SurveysView = ({ data, refetch }) => {
  const [isShowAthena, setIsShowAthena] = useState(false);
  const [isAddingable] = useState(data.baselineSurvey && data.baselineSurvey.status && data.baselineSurvey.status === 'complete');
  const [missingSurveys, setMissingSurveys] = useState();
  const [isResetSurvey, setResetSurvey] = useState();
  const [isCreateReminderSurvey, setCreateReminderSurvey] = useState();
  const [resetOn, setResetOn] = useState(null);

  useEffect(() => {
    let toAdd = [];
    if (data.baselineSurvey && data.baselineSurvey.status && data.baselineSurvey.status === 'complete') {
      for (let index = 0; index < FOLLOWUP_SURVEYS_ID.filter((x) => x.isActive === true).length; index++) {
        const element = FOLLOWUP_SURVEYS_ID[index];
        if ((data.surveysStatus || []).findIndex((x) => x.name === element.name) < 0) {
          toAdd.push(element);
        }
      }
    }
    setMissingSurveys(toAdd);
  }, []);

  useEffect(
    () => {
      let toAdd = [];
      if (data.baselineSurvey && data.baselineSurvey.status && data.baselineSurvey.status === 'complete') {
        for (let index = 0; index < FOLLOWUP_SURVEYS_ID.filter((x) => x.isActive === true).length; index++) {
          const element = FOLLOWUP_SURVEYS_ID[index];
          if ((data.surveysStatus || []).findIndex((x) => x.name === element.name) < 0) {
            toAdd.push(element);
          }
        }
      }
      setMissingSurveys(toAdd);
    },
    [data]
  );
  const reset = () => {};
  const edit = () => {};
  const add = () => {};
  const missingSurveysColumns = [
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'adminText',
      accessor: 'adminText',
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div title="add" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => setCreateReminderSurvey(row.original)}>
              +
            </div>
          </Flex>
        );
      },
    },
  ];

  const currentSurveysColumns = [
    {
      Header: 'name',
      accessor: 'name',
      Cell: (row, rest) => {
        const el = FOLLOWUP_SURVEYS_ID.find((x) => x.name === row.original.name);
        return <Text>{el.title}</Text>;
      },
    },
    {
      Header: 'status',
      accessor: 'status',
      Cell: (row) => {
        return <div>{row.original.status || 'not started'}</div>;
      },
    },
    {
      Header: 'triggers on',
      accessor: 'triggersOn',
      Cell: (row) => {
        return <div>{row.original.triggersOn ? moment(row.original.triggersOn.triggersOn).format('MM/DD/YYYY HH:mm:ss') : ''}</div>;
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div
              title="reset"
              style={{ cursor: 'pointer', marginLeft: '12px' }}
              onClick={() => {
                setResetSurvey(row.original);
              }}
            >
              ✎
            </div>
          </Flex>
        );
      },
    },
  ];
  const handleCloseModalConfirmationResetSurvey = () => {
    setResetSurvey(null);
  };
  const handleCloseModalConfirmationCreateReminderSurvey = () => {
    setCreateReminderSurvey(null);
    setResetOn(null);
  };

  if (isResetSurvey) {
    return (
      <Mutation
        mutation={ADMIN_RESET_FOLLOWUP_SURVEY}
        variables={{
          id: (data || {}).athenaId,
          surveyName: isResetSurvey.name,
        }}
        onCompleted={async () => {
          await refetch();
          handleCloseModalConfirmationResetSurvey(null);
        }}
      >
        {(mutate, { loading }) => (
          <Fragment>
            <ConfirmModal
              isOpen={!!isResetSurvey}
              onClose={handleCloseModalConfirmationResetSurvey}
              onBackgroundClick={handleCloseModalConfirmationResetSurvey}
              onEscapeKeydown={handleCloseModalConfirmationResetSurvey}
              childrenManaged
            >
              <ModalInner>
                <Heading mb={4} textAlign="center">
                  Warning
                </Heading>

                <Text fontSize={3} textAlign="center" mb={4}>
                  Resetting the survey status will request the user to fill it.
                </Text>

                <Flex justifyContent={'center'}>
                  <Button
                    disabled={!!loading}
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={handleCloseModalConfirmationResetSurvey}
                  >
                    Cancel
                  </Button>
                  <Button
                    ml={4}
                    disabled={!!loading}
                    style={{ background: '#F6A293' }}
                    onClick={() => {
                      mutate();
                    }}
                  >
                    Reset Survey Status
                  </Button>
                </Flex>
              </ModalInner>
            </ConfirmModal>
            <FullLayout />
          </Fragment>
        )}
      </Mutation>
    );
  }

  if (isCreateReminderSurvey) {
    return (
      <Mutation
        mutation={ADMIN_RESET_FOLLOWUP_SURVEY}
        variables={{
          id: (data || {}).athenaId,
          surveyName: isCreateReminderSurvey.name,
          date: resetOn ? new Date(resetOn) : null,
        }}
        onCompleted={async () => {
          await refetch();
          handleCloseModalConfirmationCreateReminderSurvey(null);
        }}
      >
        {(mutate, { loading }) => (
          <Fragment>
            <ConfirmModal
              isOpen={!!isCreateReminderSurvey}
              onClose={handleCloseModalConfirmationCreateReminderSurvey}
              onBackgroundClick={handleCloseModalConfirmationCreateReminderSurvey}
              onEscapeKeydown={handleCloseModalConfirmationCreateReminderSurvey}
              childrenManaged
            >
              <ModalInner>
                <Heading mb={4} textAlign="center">
                  Warning
                </Heading>

                <Text fontSize={3} textAlign="center" mb={4}>
                  This will request the user to complete the survey.
                </Text>

                <Text textAlign="center">
                  The trigger on date will be the date starting when the patient will see the survey in the todo list. If empty, it will be starting next time they log in.
                </Text>
                <MaskInput
                  name="triggersOn"
                  placeholder="Triggers on (MM/DD/YYYY)"
                  mask="99/99/9999"
                  value={resetOn}
                  onChange={(e) => {
                    setResetOn(e.target.value);
                  }}
                  id="input_triggersOn"
                />

                <Flex justifyContent={'center'}>
                  <Button
                    disabled={!!loading}
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={handleCloseModalConfirmationCreateReminderSurvey}
                  >
                    Cancel
                  </Button>
                  <Button
                    ml={4}
                    disabled={!!loading}
                    style={{ background: '#F6A293' }}
                    onClick={() => {
                      mutate();
                    }}
                  >
                    Create reminder
                  </Button>
                </Flex>
              </ModalInner>
            </ConfirmModal>
            <FullLayout />
          </Fragment>
        )}
      </Mutation>
    );
  }
  // surveysStatus
  return (
    <Flex flexDirection={'column'} pl={3} style={{ borderLeftColor: 'blue', borderWidth: 2 }} mt={3}>
      <Flex
        onClick={() => {
          setIsShowAthena(!isShowAthena);
        }}
      >
        <Text fontWeight={500} mb={3} flex={1}>
          Follow-up surveys
        </Text>
        <FontAwesomeIcon size="1x" color="black" icon={isShowAthena ? faChevronUp : faChevronRight} />
      </Flex>
      {!!isShowAthena && (
        <>
          <ReactTable
            data={data.surveysStatus}
            columns={currentSurveysColumns}
            defaultPageSize={data.surveysStatus && data.surveysStatus.length > 3 ? Math.min(data.surveysStatus.length, 25) : 3}
            showPagination={data.surveysStatus && data.surveysStatus.length > 24}
          />
          <Box height="24px">&nbsp;</Box>
          {missingSurveys &&
            missingSurveys.length > 0 && (
              <ReactTable
                data={missingSurveys}
                columns={missingSurveysColumns}
                defaultPageSize={missingSurveys && missingSurveys.length > 3 ? Math.min(missingSurveys.length, 25) : 3}
                showPagination={missingSurveys && missingSurveys.length > 24}
              />
            )}
        </>
      )}
    </Flex>
  );
};
