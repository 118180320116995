import React from 'react';
import { Elements } from 'react-stripe-elements';
import withSession from '../../lib/withSession';
import B2bPurchase from './B2bPurchase';
import PurchaseComponent from './purchaseComponent';

const Purchase = ({ session: { b2b } }, rest) => {
  if (!!b2b && !!b2b.isActive && !b2b.mustVerify && !b2b.pendingApproval) {
    return (
      <Elements>
        <B2bPurchase />
      </Elements>
    );
  }

  return (
    <Elements>
      <PurchaseComponent {...rest} />
    </Elements>
  );
};

export default withSession(Purchase);
