

import React from 'react';
import {
    Elements,
} from 'react-stripe-elements';

import TestPaymentWallet from './TestPaymentWallet';

class TestPaymentWalletContainer extends React.Component {
  render() {

    return (
      <Elements><TestPaymentWallet></TestPaymentWallet>
      </Elements>
    );
  }
}

export default TestPaymentWalletContainer;
