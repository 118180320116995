import React, { useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import { Box, Button, Flex, Text } from 'rebass';
import * as Yup from 'yup';
import PleaseWait from '../../components/PleaseWait';
import TextInput, { Input } from '../../components/TextInput';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import gql from 'graphql-tag';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import { withToastManager } from 'react-toast-notifications';
import CheckboxInput from '../../components/CheckboxInput';
import ConfirmModal from '../../components/ConfirmModal';
import styled from 'styled-components';
import { REGISTERED_LIST } from './ModalChangeRelatedEmployee';
import moment from 'moment';
import Select from 'react-select';
import { MaskInput } from '../../components/MaskInput';

const ModalInner = styled(Box)`
  max-width: 600px;
  min-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;

const minDob = moment()
  .subtract(18, 'years')
  .toDate();

const maxDob = moment()
  .subtract(125, 'years')
  .toDate();
let schema = Yup.object().shape({
  // firstName: Yup.string()
  //   .trim()
  //   .required('Required'),
  // lastName: Yup.string()
  //   .trim()
  //   .required('Required'),
  // primaryFirstName: Yup.string()
  //   .trim()
  //   .required('Required'),
  // primaryLastName: Yup.string()
  //   .trim()
  //   .required('Required'),
  // dob: Yup.date()
  //   .transform((value) => {
  //     return moment(value).toDate();
  //   })
  //   .min(maxDob, 'Must be younger than 125 years')
  //   .max(minDob, 'Must be 18 years or older')
  //   .required('Required'),
});
const ADD_ELIGIBILITY = gql`
  mutation adminAddEligibilityEntry(
    $companyId: String!
    $firstName: String
    $lastName: String
    $dob: DateTime
    $employeeEmail: String
    $employeeId: String
    $memberId: String
    $subscriberId: String
    $relationshipToEmployer: String
    $primaryFirstName: String
    $primaryLastName: String
    $metadata: String
    $uniqueCode: String
    $zip: String
  ) {
    adminAddEligibilityEntry(
      companyId: $companyId
      firstName: $firstName
      lastName: $lastName
      dob: $dob
      employeeEmail: $employeeEmail
      employeeId: $employeeId
      relationshipToEmployer: $relationshipToEmployer
      primaryFirstName: $primaryFirstName
      primaryLastName: $primaryLastName
      metadata: $metadata
      memberId: $memberId
      subscriberId: $subscriberId
      uniqueCode: $uniqueCode
      zip: $zip
    ) {
      ok
      customError
    }
  }
`;

const ModalAddEligibilityEntry = ({ companyId, onClose, toastManager }) => {
  if (!companyId) return null;

  return (
    <Flex flexDirection={'column'}>
      <Mutation
        mutation={ADD_ELIGIBILITY}
        refetchQueries={[{ query: REGISTERED_LIST, variables: { companyId } }]}
        onCompleted={(data) => {
          if (!!data.adminAddEligibilityEntry) {
            if (!!data.adminAddEligibilityEntry.ok) {
              toastManager.add('Eligibility added', { appearance: 'success' });
              onClose();
              return;
            } else {
              toastManager.add(data.adminAddEligibilityEntry.error || 'An error occured', { appearance: 'error' });
            }
          }
        }}
      >
        {(mutate, { loading }) => {
          return (
            <Formik
              isInitialValid={({ initialValues }) => schema.isValidSync(initialValues)}
              initialValues={{
                companyId,
                firstName: '',
                lastName: '',
                dob: null,
                employeeEmail: '',
                employeeId: null,
                memberId: null,
                subscriberId: null,
                relationshipToEmployer: {
                  label: 'employee',
                  value: 'self',
                },
                primaryFirstName: '',
                primaryLastName: '',
                metadata: null,
                uniqueCode: null,
              }}
              validationSchema={schema}
              onSubmit={(values) =>
                mutate({
                  variables: {
                    ...values,
                    dob: moment(values.dob).toDate(),
                    relationshipToEmployer: values.relationshipToEmployer.value,
                  },
                })
              }
              render={({ isValid, errors, touched, values, handleChange, handleBlur, setFieldValue, setFieldTouched }) => {
                const dobError = errors.dob && errors.dob !== 'Invalid Date' && errors.dob.indexOf('dob') !== 0 ? errors.dob : null;

                return (
                  <Form>
                    <Text color="orange" fontWeight={600} mt={3} mb={4}>
                      Warning: if this entry is not part of the next company eligibility file upload, it will be deleted
                    </Text>
                    <FormField width={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.firstName && errors.firstName}>
                      <TextInput placeholder="First name" type="text" name="firstName" />
                    </FormField>
                    <FormField width={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.lastName && errors.lastName}>
                      <TextInput placeholder="Last name" type="text" name="lastName" />
                    </FormField>
                    <FormField mb={4} error={touched.dob && dobError}>
                      <MaskInput name="dob" placeholder="User DoB (MM/DD/YYYY)" mask="99/99/9999" value={values.dob} onChange={handleChange} onBlur={handleBlur} id="input_dob" />
                    </FormField>
                    <Flex style={{ minWidth: '250px' }}>
                      <FormField style={{ minWidth: '250px' }} ml={2} mb={4} error={touched.async && errors.async}>
                        <label for="relationshipToEmployer">Relationship to employer</label>
                        <Select
                          classNamePrefix="region-select"
                          options={[
                            {
                              label: 'employee',
                              value: 'self',
                            },
                            {
                              label: 'related',
                              value: 'related',
                            },
                          ]}
                          name="relationshipToEmployer"
                          value={values.relationshipToEmployer}
                          placeholder="select relationship To Employer"
                          onChange={(option) => {
                            setFieldValue('relationshipToEmployer', option);
                          }}
                          onBlur={() => setFieldTouched('relationshipToEmployer', true)}
                        />
                      </FormField>
                    </Flex>

                    <FormField width={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.primaryFirstName && errors.primaryFirstName}>
                      <TextInput placeholder="Employee First name" type="text" name="primaryFirstName" />
                    </FormField>
                    <FormField width={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.primaryLastName && errors.primaryLastName}>
                      <TextInput placeholder="Employee Last name" type="text" name="primaryLastName" />
                    </FormField>
                    <FormField width={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.employeeEmail && errors.employeeEmail}>
                      <TextInput placeholder="Employee email" type="text" name="employeeEmail" />
                    </FormField>
                    <FormField width={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.employeeId && errors.employeeId}>
                      <TextInput placeholder="employee Id" type="text" name="employeeId" />
                    </FormField>
                    <FormField width={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.memberId && errors.memberId}>
                      <TextInput placeholder="member Id" type="text" name="memberId" />
                    </FormField>
                    <FormField width={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.subscriberId && errors.subscriberId}>
                      <TextInput placeholder="subscriber Id" type="text" name="subscriberId" />
                    </FormField>
                    <FormField width={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.uniqueCode && errors.uniqueCode}>
                      <TextInput placeholder="unique code" type="text" name="uniqueCode" />
                    </FormField>
                    
                    <Flex justifyContent="center" mt={4}>
                      <Button disabled={!isValid || !!loading} variant="pink" width={[1, 1 / 2, 1 / 3]} type="submit">
                        Confirm
                      </Button>
                      <Button ml={3} style={{ width: 'auto !important' }} disabled={!!loading} variant="pink" width={[1, 1 / 2, 1 / 3]} type="button" onClick={onClose}>
                        Cancel
                      </Button>
                    </Flex>
                  </Form>
                );
              }}
            />
          );
        }}
      </Mutation>
    </Flex>
  );
};

export default withToastManager(ModalAddEligibilityEntry);
