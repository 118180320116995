import React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../components/FullLayout';
import Header from '../components/Header';
import PleaseWait from '../components/PleaseWait';
import { GET_MEMBERSHIPS, MEMBERSHIP_UPDATE_PAYMENT_METHOD } from '../graphql';
import { useMount } from '../utils/hooks/lifecycle';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
`;
const SubscriptionUpdated = ({ client, match, history }) => {
  useMount(async () => {
    const id = match.params.id;
    await client.mutate({
      mutation: MEMBERSHIP_UPDATE_PAYMENT_METHOD,
      variables: {
        sessionId: id,
      },
      refetchQueries: [{ query: GET_MEMBERSHIPS }],
      awaitRefetchQueries: true,
    });

    history.push('/my-subscriptions');
  });
  return (
    <FullLayout2 style={{ zIndex: 11 }}>
      <Container>
        <Flex flex={1}>
          <Header dark={false} />
        </Flex>
        <PleaseWait
          text=" "
          spinner={{
            sColor: '#ECEFF2',
            pColor: '#909FB5',
          }}
        />
      </Container>
    </FullLayout2>
  );
};

export default withRouter(withApollo(SubscriptionUpdated));
