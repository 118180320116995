import React, { Component, Fragment } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Mutation } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import gql from 'graphql-tag';
import styled from 'styled-components';

import TextInput from '../components/TextInput';
import FormField from '../components/FormField';
import FullLayout from '../components/FullLayout';
import Header from '../components/Header';
import withAnalytics from '../lib/withAnalytics';
import { RESEND_UNLOCK_ACCOUNT_EMAIL } from '../graphql';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('invalid email address')
    .required('Required'),
  password: Yup.string()
    .trim()
    .required(),
});

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        accountLocked
      }
    }
  }
`;

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const CardContainer = styled(Flex)`
  max-width: 400px;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  color: #999;
  text-align: right;
`;

class AccountLocked extends Component {
  componentDidMount() {
    this.props.analytics.page();
    localStorage.removeItem('initialToken');
  }

  handleSubmit = async (email, login) => {
    const { toastManager } = this.props;

    try {
      await login({ variables: { email } });
      toastManager.add('Email Sent', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      if (!error.graphQLErrors) {
        return;
      }

      error.graphQLErrors.forEach(error => {
        console.log(error);
        toastManager.add(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
    }
  };

  render() {
    let email = '';
    
    if (this.props.location && this.props.location.state) {
      email = this.props.location.state.email;
    }

    return (
      <Fragment>
        <Container>
          <Flex flex={1}>
            <Header dark={false} />
          </Flex>
          <Flex flex={1} alignItems="center" justifyContent="center">
            <CardContainer flex={1} px={3}>
              <Card p={4}>
                <Flex flexDirection="column">
                  <Box mb={4}>
                    <Heading textAlign="center" mb={4}>
                      Security Information
                    </Heading>
                    <Text textAlign="center">
                      Your account has been locked for security purposes:
                      <br />
                      <br />
                      <span
                        style={{ color: 'rgb(237, 183, 168)', fontWeight: 700 }}
                      >
                        5 incorrect password attempts
                      </span>
                    </Text>
                    <Text textAlign="center" mt={4}>
                      An email has been sent to {email} with instructions to
                      unlock your account.
                    </Text>
                    <Text textAlign="center" mt={2}>
                      If you did not receive it, you can request another email
                      below.
                    </Text>
                    <Text textAlign="center" mt={2}>
                      Please allow 5 minutes for the email to reach your
                      mailbox.
                    </Text>
                  </Box>
                  <Mutation mutation={RESEND_UNLOCK_ACCOUNT_EMAIL}>
                    {(login, { loading }) => {
                      return (
                        <Fragment>
                          <Button
                            variant="primary"
                            onClick={() => {
                              this.handleSubmit(email, login);
                            }}
                          >
                            Resend
                          </Button>
                        </Fragment>
                      );
                    }}
                  </Mutation>
                </Flex>
              </Card>
            </CardContainer>
          </Flex>
        </Container>
        <FullLayout />
      </Fragment>
    );
  }
}

export default withAnalytics(withToastManager(AccountLocked));
