import React, { useEffect, useState } from 'react';
import { Query, withApollo } from 'react-apollo';
import { Redirect, withRouter } from 'react-router';
import { Box, Button, Flex } from 'rebass';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import SelectPlan, { SelectPlanNoWizard } from './scheduleDoctor2/SelectPlan2';
import withSession from '../lib/withSession';
import { CURRENT_USER, DELETE_INSURANCE, GET_INSURANCE_IN_STATE, GET_INSURANCE_ON_FILE, MEMBERSHIP_CREATE_SESSION, QUADPAY_SUBSCRIBED } from '../graphql';
import PleaseWait, { SubscriptionRegistrationComplete } from '../components/PleaseWait';
import withAnalytics from '../lib/withAnalytics';
import { Step, Steps, Wizard } from 'react-albus';
import SelectInsurance from './selectPlan/SelectInsurance';
import gql from 'graphql-tag';
import InsuranceBenefitsCheck from './selectPlan/insuranceBenefitsCheck';
import withIsAdmin from '../lib/withIsAdmin';
import { sleep } from '../utils/sleep';
import { CheckOnFileFlowWrapper } from './asyncIntake/screens/components/InsuranceOnFile';
import { isToggleActive } from '../components/featureToggle/toggles';
// import { CheckOnFileFlowWrapper } from './asyncIntake/screens/components/InsuranceOnFile';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
  @media screen and (max-width: 52em) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const InnerContainer = styled(Flex)`
  max-width: 850px;
  margin: 0 auto;
  flex-direction: column;
`;

const DETERMINE_PAYER_ACCEPTED_IN_STATE = gql`
  query determineInsuranceAcceptedInState($payer: String!) {
    determineInsuranceAcceptedInState(payer: $payer) {
      isEligible
    }
  }
`;

const FlowNoInsurance = ({ setQuadPayData, vitaminsCombine, hasTrial, onSubscribe, history }) => {
  return (
    <SelectPlanNoWizard
      setQuadPayData={setQuadPayData}
      continueWithQuadPay={() => {
        let freq = 'ma';
        history.push(!vitaminsCombine ? `/subscribed/call/${freq}${hasTrial ? '?q=trial' : ''}` : `/subscribed/vitamins/${freq}${hasTrial ? '?q=trial' : ''}`);
      }}
      onSubscribe={(frequency) => onSubscribe(frequency)}
    />
  );
};

const FlowInsurance = ({ setQuadPayData, vitaminsCombine, hasTrial, onSubscribe, session, client, history, isAdmin, onSubscribeOnFile }) => {
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedProviderNonSupported, setSelectedProviderNonSupported] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [insuranceInfo, setInsuranceInfo] = useState();
  const [savedInsuranceCard, setSavedInsuranceCard] = useState();
  const [useOnFile, setUseOnFile] = useState(false);
  const onProviderSelected = async (provider, wizard) => {
    setIsProcessing(true);

    const { data } = await client.query({
      query: DETERMINE_PAYER_ACCEPTED_IN_STATE,
      variables: {
        payer: provider.value,
      },
    });

    setIsProcessing(false);

    if (data && data.determineInsuranceAcceptedInState && data.determineInsuranceAcceptedInState.isEligible) {
      setSelectedProvider(provider);
      wizard.next();
    } else {
      setSelectedProviderNonSupported(provider);
      wizard.push('InsuranceBenefitsCheck');
    }
  };
  const bypassInsurance = (wizard) => {
    wizard.push('selectPlanNoInsurance');
  };

  const onNonSupportedProviderSelected = (selectedProvider, wizard) => {
    setSelectedProviderNonSupported(selectedProvider);
    wizard.push('selectPlanNoInsurance');
  };

  const setInsuranceCard = async (selectedPlanType, wizard, email, primarySubscriberFullName, dob) => {
    setSavedInsuranceCard({
      selectedPlanType,
      email,
      primarySubscriberFullName,
      dob,
    });
    // await sleep(200)
    if (selectedProvider) {
      console.log();
      onSubscribe(
        'insurance',
        {
          selectedPlanType,
          email,
          primarySubscriberFullName,
          dob,
        },
        selectedProvider
      );
      // wizard.next();
    } else {
      wizard.push('selectPlanNoInsurance');
    }
  };

  const reinitFlow = (wizard) => {
    console.log('reinitFlow', { wizard });
  };

  const onCoverageChecked = (val) => {
    // console.log('onCoverageChecked', { val });
  };

  const useOnFileInsurance = async (wizard) => {
    setUseOnFile(true);
    await sleep(300);
    // wizard.push('selectPlanInsurance');
    onSubscribeOnFile('insurance');
  };

  const deleteOnFileInsurance = async (wizard) => {
    await client.mutate({
      mutation: DELETE_INSURANCE,
      refetchQueries: [{ query: GET_INSURANCE_ON_FILE }],
    });
    wizard.next();
  };

  const noInsurance = (wizard) => {
    wizard.next();
  };

  return (
    <Wizard
      // ref={wizard}
      history={history}
      basename="/subscribe-membership"
      render={({ step, ...p }, a) => {
        return (
          <Steps>
            <Step id="OnFile">
              <CheckOnFileFlowWrapper useOnFileInsurance={useOnFileInsurance} deleteOnFileInsurance={deleteOnFileInsurance} noInsurance={noInsurance} />
            </Step>
            <Step id="SelectInsurance">
              <SelectInsurance bypassInsurance={bypassInsurance} onNonSupportedProviderSelected={onNonSupportedProviderSelected} onProviderSelected={onProviderSelected} />
            </Step>
            <Step id="InsuranceBenefitsCheck">
              <InsuranceBenefitsCheck
                departmentInitials={session.address.state}
                setInsuranceCard={setInsuranceCard}
                isAdmin={isAdmin}
                // onFileInsurance={onFileInsurance}
                reinitFlow={reinitFlow}
                selectedProvider={selectedProvider}
                insuranceInfo={insuranceInfo}
                selectedProviderNonSupported={selectedProviderNonSupported}
                patientEmail={session.email}
                onCoverageChecked={onCoverageChecked}
                bypassInsurance={bypassInsurance}
                nonAcceptedText="continue"
              />
            </Step>
            <Step id="selectPlanInsurance">
              <SelectPlan insuranceMode={true} onSubscribe={(frequency) => onSubscribe(frequency, savedInsuranceCard, selectedProvider)} />
            </Step>
            <Step id="selectPlanNoInsurance">
              <SelectPlan
                setQuadPayData={setQuadPayData}
                continueWithQuadPay={() => {
                  let freq = 'ma';
                  history.push(!vitaminsCombine ? `/subscribed/call/${freq}${hasTrial ? '?q=trial' : ''}` : `/subscribed/vitamins/${freq}${hasTrial ? '?q=trial' : ''}`);
                }}
                onSubscribe={(frequency) => onSubscribe(frequency, savedInsuranceCard)}
              />
            </Step>
          </Steps>
        );
      }}
    />
  );
};

const SelectPlanPage = ({ session, client, location, isJoinFlow, history, analytics, isAdmin }) => {
  const { vitaminsCombine } = location.state || {};
  const [quadPaySubscribedData, setQuadPaySubscribedData] = useState();
  const [hasTrial, setHasTrial] = useState(false);

  if (session.isMember) {
    return <Redirect to="/" />;
  }

  useEffect(() => {
    const ht = location && location.search && location.search.indexOf('q=trial') > -1;
    setHasTrial(ht);
  }, []);

  const setQuadPayData = async (accountNumber, cvc, expMonth, expYear, quadpayOrderReference) => {
    setQuadPaySubscribedData({
      accountNumber,
      cvc,
      expMonth,
      expYear,
      quadpayOrderReference,
    });
    await client.mutate({
      mutation: QUADPAY_SUBSCRIBED,
      variables: {
        accountNumber,
        cvc,
        expMonth,
        expYear,
        quadpayOrderReference,
      },
      refetchQueries: [{ query: CURRENT_USER }],
    });

    analytics.track('QuadPay - Membership', {
      orderReference: quadpayOrderReference,
    });
    let freq = 'ma';

    history.push(!vitaminsCombine ? `/subscribed/call/${freq}${hasTrial ? '?q=trial' : ''}` : `/subscribed/vitamins/${freq}${hasTrial ? '?q=trial' : ''}`);
  };

  const onSubscribeOnFile = async (frequency) => {
    let priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_MONTHLY;
    let freq = frequency === 'annualy' ? 'ma' : 'mm';

    if (frequency === 'insurance') {
      priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_INSURANCE;
      freq = 'mi';
    } else {
      const ft_membership_price_2024 = isToggleActive('MEMBERSHIP_PRICE_2024');
      if (frequency === 'annualy') {
        if (ft_membership_price_2024) {
          priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL_2024;
        } else {
          priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL;
        }
      } else {
        priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_MONTHLY;
      }
    }

    let forwardUrl = !vitaminsCombine ? `subscribed/call/${freq}${hasTrial ? '?q=trial' : ''}` : `subscribed/vitamins/${freq}${hasTrial ? '?q=trial' : ''}`;

    if (freq === 'mi') {
      forwardUrl = `subscribed/${!!vitaminsCombine ? 'vitamins' : 'call'}`;
    }

    const createSession = await client.mutate({
      mutation: MEMBERSHIP_CREATE_SESSION,
      variables: {
        priceId: priceId,
        forwardUrl: forwardUrl, //!vitaminsCombine ? `subscribed/call/${freq}${hasTrial ? '?q=trial' : ''}` : `subscribed/vitamins/${freq}${hasTrial ? '?q=trial' : ''}`,
        cancel_url: isJoinFlow ? `join-membership${hasTrial ? '?q=trial' : ''}` : `subscribe-membership${hasTrial ? '?q=trial' : ''}`,
        trial: hasTrial || false,
      },
    });

    const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_KEY);
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({
      sessionId: createSession.data.createCheckoutSession.sessionId,
    });
  };

  const onSubscribe = async (frequency, savedInsuranceCard, selectedProvider, selectedPlanType) => {
    let priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_MONTHLY;
    let freq = frequency === 'annualy' ? 'ma' : 'mm';

    if (frequency === 'insurance') {
      priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_INSURANCE;
      freq = 'mi';
    } else {
      const ft_membership_price_2024 = isToggleActive('MEMBERSHIP_PRICE_2024');
      if (frequency === 'annualy') {
        if (ft_membership_price_2024) {
          priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL_2024;
        } else {
          priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL;
        }
      } else {
        priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_MONTHLY;
      }
    }

    let forwardUrl = !vitaminsCombine ? `subscribed/call/${freq}${hasTrial ? '?q=trial' : ''}` : `subscribed/vitamins/${freq}${hasTrial ? '?q=trial' : ''}`;

    if (freq === 'mi') {
      console.log({
        selectedProvider,
        ...savedInsuranceCard,
      });
      forwardUrl = `subscribed/insurance/${!!vitaminsCombine ? 'vitamins' : 'call'}/${encodeURI(
        JSON.stringify({
          frequency,
          savedInsuranceCard: {
            selectedProvider: selectedProvider.value,
            // selectedPlanType:,
            ...savedInsuranceCard,
          },
          fForward: `${freq}${hasTrial ? '?q=trial' : ''}`,
        })
      )}`;
    }

    const createSession = await client.mutate({
      mutation: MEMBERSHIP_CREATE_SESSION,
      variables: {
        priceId: priceId,
        forwardUrl: forwardUrl, //!vitaminsCombine ? `subscribed/call/${freq}${hasTrial ? '?q=trial' : ''}` : `subscribed/vitamins/${freq}${hasTrial ? '?q=trial' : ''}`,
        cancel_url: isJoinFlow ? `join-membership${hasTrial ? '?q=trial' : ''}` : `subscribe-membership${hasTrial ? '?q=trial' : ''}`,
        trial: hasTrial || false,
      },
    });

    const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_KEY);
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({
      sessionId: createSession.data.createCheckoutSession.sessionId,
    });
  };
  const t = isToggleActive('INSURANCE_MEMBERSHIP_WIND_DOWN');
  if (!!t) {
    return (
      <Container>
        <InnerContainer>
          <FlowNoInsurance setQuadPayData={setQuadPayData} vitaminsCombine={vitaminsCombine} hasTrial={hasTrial} onSubscribe={onSubscribe} history={history} />
        </InnerContainer>
      </Container>
    );
  }

  return (
    <Container>
      <InnerContainer>
        <Query query={GET_INSURANCE_IN_STATE}>
          {({ data, loading }) => {
            if (loading) {
              return <PleaseWait text="Initializing" />;
            }
            if (data && data.getInsurancesAcceptedInState && data.getInsurancesAcceptedInState.length > 0) {
              return (
                <FlowInsurance
                  vitaminsCombine={vitaminsCombine}
                  hasTrial={hasTrial}
                  session={session}
                  onSubscribe={onSubscribe}
                  onSubscribeOnFile={onSubscribeOnFile}
                  history={history}
                  client={client}
                  isAdmin={isAdmin}
                  setQuadPayData={setQuadPayData}
                />
              );
            } else {
              return <FlowNoInsurance setQuadPayData={setQuadPayData} vitaminsCombine={vitaminsCombine} hasTrial={hasTrial} onSubscribe={onSubscribe} history={history} />;
            }
          }}
        </Query>
      </InnerContainer>
    </Container>
  );
};

export default withSession(withApollo(withRouter(withAnalytics(withIsAdmin(SelectPlanPage)))));
