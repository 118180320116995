import React from 'react';
import { Text } from 'rebass';

export const ParagraphCtrl = ({ properties }) => {
  return (
    <>
      <Text fontWeight={300}>{`${(properties || {}).question || 'Paragraph'}`}</Text>
    </>
  );
};
