import React, { useState } from 'react';
import { Box, Button, Flex, Text } from 'rebass';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import PleaseWait from '../../../components/PleaseWait';
import { Query } from 'react-apollo';
import { GET_HEALTHCOACH_FORM_TARGETS } from '../../../graphql';
import Modal from 'styled-react-modal';
import { SpecialModalBackground } from '../../adminReorderPhysicianConvo/shared';
import FormTargetsAddEdit from './FormTargetsAddEdit';

const FormTargets = () => {
  const [isAddNew, setIsAddNew] = useState();
  const [isEdit, setIsEdit] = useState();
  const columns = [
    {
      Header: 'Name',
      accessor: 'target',
    },
    {
      Header: 'Can Have Multiple Forms',
      accessor: 'canHaveMultiple',
      Cell: (row) => {
        return <div>{row.original.canHaveMultiple ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'Active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div>{row.original.isActive ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'Deprecated',
      accessor: 'isDeprecated',
      Cell: (row) => {
        return <div>{row.original.isDeprecated ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'Deleted',
      accessor: 'isDeleted',
      Cell: (row) => {
        return <div>{row.original.isDeleted ? '✓' : ''}</div>;
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div title="edit" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => onEdit(row.original)}>
              ✎
            </div>
            <div title="delete" style={{ cursor: 'pointer' }} onClick={() => onDelete(row.original.id)}>
              🗑
            </div>
          </Flex>
        );
      },
    },
  ];

  const onDelete = (id) => {};

  const onEdit = (itm) => {
    setIsEdit(itm)
  };

  return (
    <Flex flexDirection={'column'} mb={4}>
      <Flex mb={3}>
        <Text flex={1} style={{ fontWeight: 500, fontSize: '20px' }}>
          Form Targets
        </Text>
        <Button variant={'primary'} style={{ padding: '4px 16px' }} onClick={()=>setIsAddNew(true)}>
          Add Target
        </Button>
      </Flex>

      <Query query={GET_HEALTHCOACH_FORM_TARGETS}>
        {({ data, loading, error }) => {
          if (!!loading) {
            return <PleaseWait />;
          }
          if (!data || !data.getHealthCoachFormTargets || !!error) {
            return <Text textAlign={'center'}>An error occured</Text>;
          }
          return (
            <ReactTable
              data={data.getHealthCoachFormTargets}
              columns={columns}
              defaultPageSize={data.getHealthCoachFormTargets && data.getHealthCoachFormTargets.length > 3 ? Math.min(data.getHealthCoachFormTargets.length, 25) : 3}
              showPagination={data.getHealthCoachFormTargets && data.getHealthCoachFormTargets.length > 24}
            />
          );
        }}
      </Query>
      {!!isAddNew && (
        <Modal isOpen={true} onBackgroundClick={() => {}} onEscapeKeydown={() => {}}>
          <SpecialModalBackground>
            <Box
              style={{
                margin: '0 auto',
                alignSelf: 'center',
                borderRadius: '20px',
                position: 'relative',
                backgroundColor: 'white',
                padding: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                maxWidth: '95vw',
                minWidth: 'initial',
                maxHeight: '95vh',
              }}
            >
              <Flex flex={1} flexDirection={'column'} width={'600px'}>
                <FormTargetsAddEdit editItem={null} close={() => setIsAddNew(false)} />
              </Flex>
            </Box>
          </SpecialModalBackground>
        </Modal>
      )}
      {!!isEdit && (
        <Modal isOpen={true} onBackgroundClick={() => {}} onEscapeKeydown={() => {}}>
          <SpecialModalBackground>
            <Box
              style={{
                margin: '0 auto',
                alignSelf: 'center',
                borderRadius: '20px',
                position: 'relative',
                backgroundColor: 'white',
                padding: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                maxWidth: '95vw',
                minWidth: 'initial',
                maxHeight: '95vh',
              }}
            >
              <Flex flex={1} flexDirection={'column'} width={'600px'}>
                <FormTargetsAddEdit editItem={isEdit} close={() => setIsEdit(false)} />
              </Flex>
            </Box>
          </SpecialModalBackground>
        </Modal>
      )}
    </Flex>
  );
};

export default FormTargets;
