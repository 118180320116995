import moment from 'moment';
import * as Yup from 'yup';
import { phoneRegExp, MedicalConditionsFlower } from './utils';

export const AsyncOnly = [
  {
    id: 'welcome',
    type: 'letstalk',
    hideCountText: true,
    klaviyoEvent: 'Async Intake',
  },
  {
    id: 'previouslyDiagnosed',
    type: 'single',
    endpoint: 'medicalHistory',
    onlyFirstTime: true,
    sendToAthena: true,
    prompt: 'When were you diagnosed with hypothyroidism?',
    isHPI: true,
    hpiIndex: 0,
    savingSection: 'diagnosed',
    isSaveTemp: true,
    options: [
      {
        questionId: '19',
        value: 'Select a year',
        answer: 'Y',
        giveDetails: true,
        inputType: 'number',
        min: 1900,
        max: parseInt(moment().format('YYYY'), 10),
        step: 1,
      },
      {
        questionId: '19',
        value: 'I never got diagnosed',
        answer: 'N',
        goToPage: 'testFirst',
      },
    ],
    hpiComputer: (selectedValues, session) => {
      let age = moment().diff(session.dob, 'years');
      const initialString = `${session.firstName} is a ${age} years old ${
        session.gender.toLowerCase() === 'f' ? 'woman' : 'man'
      }`;
      if (!selectedValues || selectedValues.answer.toLowerCase === 'no') {
        return initialString + ' who never got diagnosed. ';
      }
      let year = `on ${selectedValues.value}`;
      try {
        year = `in ${selectedValues.value.substring(0, 4)}`;
      } catch {}

      return `${initialString}, diagnosed with hypothyroidism ${year}. `;
    },
    preCheckDisplay: session => {
      if (
        session &&
        !!session.diagnoseFull &&
        !!session.diagnoseFull.hasHypo &&
        !!session.diagnoseFull.hypoNotes
      ) {
        return false;
      }

      return true;
    },
  },
  {
    id: 'cancerhistory',
    type: 'single',
    endpoint: 'medicalHistory',
    onlyFirstTime: true,
    sendToAthena: true,
    savingSection: 'cancerHistory',
    prompt: 'Do you or did you ever have a history of thyroid cancer?',
    options: [
      {
        questionId:
          window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 ||
          window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1
            ? '297'
            : '301',
        value: 'No',
        answer: 'N',
      },
      {
        questionId:
          window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 ||
          window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1
            ? '297'
            : '301',
        value: 'Yes',
        answer: 'Y',
        goToPage: 'disqualifiedcancer',
        placeholder: 'Tell us more',
        giveDetails: true,
        giveDetailsEnforce: true,
      },
    ],
  },
  {
    id: 'cause',
    type: 'multiple',
    endpoint: 'medicalHistory',
    onlyFirstTime: true,
    isHPI: true,
    hpiIndex: 1,
    savingSection: 'cause',
    isSaveTemp: true,
    prompt: 'What is your understanding of the cause of your hypothyroidism?',
    options: [
      {
        questionId: '281',
        value: 'Hashimoto’s (autoimmune, most common)',
        answer: 'Y',
      },
      {
        questionId: '282',
        value: 'Post-surgical due to partial or total thyroid removal',
        answer: 'Y',
      },
      {
        questionId: '18',
        answer: 'Y',
        value:
          'Post-procedural due to hyperthyroid (Graves’ disease) that caused damage to thyroid',
      },
      {
        questionId: '340010',
        answer: 'Y',
        value:
          'Central hypothyroidism (thyroid control problem at level of hypothalamus or pituitary)',
      },
      {
        questionId: '340093',
        value: 'Other or unsure',
        answer: 'Y',
      },
      {
        questionId: '340677',
        answer: 'Y',
        value: 'Subclinical',
      },
    ],
    hpiComputer: (selectedValues, session) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }
      let initialString = `The cause of hypothyroidism ${
        selectedValues.length > 1 ? 'are' : 'is'
      }`;

      return `${initialString} ${selectedValues
        .map(x => x.value)
        .join(', ')}. `;
    },
    preCheckDisplay: session => {
      if (session && !!session.diagnoseFull && !!session.diagnoseFull.reason) {
        return false;
      }

      return true;
    },
  },
  {
    id: 'pregnancy',
    type: 'multiple',
    endpoint: 'obgynHistory',
    onlyFirstTime: false,
    prompt: 'Do any of the following currently apply to you?',
    isHPI: true,
    hpiIndex: 10,
    savingSection: 'pregnancy',
    isSaveTemp: true,
    cannotSkip: true,
    options: [
      {
        questionId: 'LOCAL.83',
        value: 'Pregnant',
        goToPage: 'disqualifiedpregnant',
        answer: 'Currently pregnant',
      },
      {
        questionId: 'LOCAL.83',
        answer: 'Planning to be pregnant',
        value: 'Planning to become pregnant',
        // goto: 'livePregnant',
      },
      {
        questionId: 'LOCAL.83',
        answer: 'Had a baby in the last 6 weeks',
        value: 'Had a baby in the last 6 weeks',
        goToPage: 'disqualifiedpregnant',
      },
      {
        questionId: 'LOCAL.83',
        answer: 'Not pregnant',
        value: "No, I don't have any of these",
        clearOther: true,
      },
    ],
    onSubmitHandler: selectedOptions => {
      if (selectedOptions.filter(x => !!x.goToPage).length > 0) {
        return 'disqualifiedpregnant';
      }

      return null;
    },
    preCheckDisplay: session => {
      if (!session || session.gender !== 'F') {
        return false;
      }

      return true;
    },
    hpiComputer: (selectedValues, session) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }

      if (
        selectedValues.filter(x => x.value === `No, I don't have any of these`)
          .length > 0
      ) {
        return `<br /><br />${
          session.gender.toLowerCase() === 'f' ? 'She' : 'He'
        } is not pregrant.`;
      }

      return `${
        session.gender.toLowerCase() === 'f' ? 'She' : 'He'
      } is ${selectedValues.map(x => x.value).join(', ')}. `;
    },
  },
  {
    id: 'hypomed',
    skip: 'disqualifiedt3',
    type: 'medicationList',
    endpoint: 'medications',
    title: 'Hypothyroidism Medications',
    nothingText: `I don't take any hypothyroid medication`,
    prompt: 'Please list your hypothyroidism medication',
    isHPI: true,
    hpiIndex: 2,
    onlyFirstTime: false,
    savingSection: 'meds',
    other: { questionId: '367151' },
    isInputOnly: false,
    dosePlaceholder: 'Dose in mcg',
    onSubmitHandler: selectedOptions => {
      if (!selectedOptions || selectedOptions.length < 1) {
        return 'disqualifiedt3';
      }
      if (selectedOptions.filter(x => !!x.goToPage).length > 0) {
        return 'disqualifiedt3';
      }

      return null;
    },
    options: [
      {
        questionId: '299900',
        value: 'Westhroid',
        goToPage: 'disqualifiedt3',
      },
      {
        questionId: '313430',
        value: 'Unithroid',
      },
      {
        questionId: '303622',
        value: 'Triostat',
        goToPage: 'disqualifiedt3',
      },
      {
        questionId: '308071',
        value: 'Thyrolar-1',
        goToPage: 'disqualifiedt3',
      },
      {
        questionId: '307953',
        value: 'Thyrolar-2',
        goToPage: 'disqualifiedt3',
      },
      {
        questionId: '303601',
        value: 'Thyrolar-3',
        goToPage: 'disqualifiedt3',
      },
      {
        questionId: '310408',
        value: 'Thyrogen',
        goToPage: 'disqualifiedt3',
      },
      {
        questionId: '301146',
        value: 'Liotrix',
        goToPage: 'disqualifiedt3',
      },
      {
        questionId: '302000',
        value: 'Liothyronine',
        goToPage: 'disqualifiedt3',
      },
      {
        questionId: '300534',
        value: 'Levoxyl',
      },
      {
        questionId: '307279',
        value: 'Levo-T',
      },
      {
        questionId: '299187',
        value: 'Euthyrox',
      },
      {
        questionId: '301371',
        value: 'Synthroid',
      },
      {
        questionId: '355544',
        value: 'Tirosint',
      },
      {
        questionId: '296025',
        value: 'Levothyroxine',
      },
      {
        questionId: '301999',
        value: 'Cytomel',
        goToPage: 'disqualifiedt3',
      },
      {
        questionId: '298245',
        value: 'Armour Thyroid',
        goToPage: 'disqualifiedt3',
      },
      {
        questionId: '371624',
        value: 'WP Thyroid',
        goToPage: 'disqualifiedt3',
      },
      {
        questionId: '311558',
        value: 'Nature-Throid',
        goToPage: 'disqualifiedt3',
      },
      {
        questionId: '357795',
        value: 'NP Thyroid',
        goToPage: 'disqualifiedt3',
      },
    ],
    hpiComputer: (selectedValues, session) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }
      let initialString = `<br /><br />${session.firstName} takes`;

      return `${initialString} ${selectedValues
        .map(x => `${x.value} ${x.dose || ''} ${x.frequency || ''}`)
        .join(',')}. `;
    },
  },
  {
    id: 'treatmentsatisfaction',
    type: 'single',
    endpoint: 'locmedicationssatisfaction',
    onlyFirstTime: false,
    isHPI: true,
    hpiIndex: 4,
    savingSection: 'meds',
    prompt:
      'How would you describe your satisfaction with your current treatment and dose?',
    options: [
      {
        questionId: 'ansWell',
        value: 'Doing well on current dose and not in need of change',
        answer: 'Doing well on current dose and not in need of change',
        hpiAnswer: gender =>
          `${
            gender.toLowerCase() === 'f' ? 'She' : 'He'
          } is doing well on current dose and not in need of change`,
      },
      {
        questionId: 'ansSympt',
        value: 'Symptomatic, but feel like dose is not in need of change',
        answer: 'Symptomatic, but feel like dose is not in need of change',
        hpiAnswer: gender =>
          `${
            gender.toLowerCase() === 'f' ? 'She' : 'He'
          } is feeling symptomatic, but feels like dose is not in need of change`,
        giveDetails: true,
        placeholder: 'Tell us more about your symptoms',
      },
      {
        questionId: 'ansSideEff',
        value: 'I think I am having side effects from my medication',
        answer: 'I think I am having side effects from my medication',
        hpiAnswer: gender =>
          `${gender.toLowerCase() === 'f' ? 'She' : 'He'} thinks ${
            gender.toLowerCase() === 'f' ? 'she' : 'he'
          } is having side effects from my medication`,
        giveDetails: true,
        placeholder: 'Tell us more about your side effects',
        goToPage: 'disqualifiedsymptoms',
      },
      {
        questionId: 'ansChange',
        value:
          'Symptomatic and would like to consider change in dose or formulation (manufacturer)',
        answer:
          'Symptomatic and would like to consider change in dose or formulation (manufacturer)',
        hpiAnswer: gender =>
          `${
            gender.toLowerCase() === 'f' ? 'She' : 'He'
          } is symptomatic and would like to consider change in dose or formulation (manufacturer)`,
        goToPage: 'disqualifiedsymptoms',
      },
    ],
    hpiComputer: (selectedValues, session, hpiAnswer) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }

      return `${hpiAnswer(session.gender)}. ${
        selectedValues.value !== selectedValues.answer
          ? `${
              session.gender.toLowerCase() === 'f' ? 'Her' : 'His'
            } symptoms are: "${selectedValues.value}". `
          : ''
      }`;
    },
  },
  {
    id: 'howLong',
    type: 'single',
    endpoint: 'lochowlongmeds',
    isHPI: true,
    hpiIndex: 3,
    onlyFirstTime: false,
    savingSection: 'meds',
    isSaveTemp: true,
    prompt: 'For how long have you been taking this treatment and dose?',
    options: [
      {
        questionId: 'ansMore2years',
        value: 'More than 2 years',
        answer: 'More than 2 years',
        goToPage: 'tshlong',
      },
      {
        questionId: 'ansMore2months',
        value: 'More than 2 months',
        answer: 'More than 2 months',
        goToPage: 'howLongWeeks',
      },
      {
        questionId: 'ansLess2months',
        value: 'Less than 2 months',
        answer: 'Less than 2 months',
        goToPage: 'howLongWeeks',
      },
    ],
    hpiComputer: (selectedValues, session) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }
      const initialString = `${
        session.gender.toLowerCase() === 'f' ? 'She' : 'He'
      } has been taking this treatment for`;

      return `${initialString} ${selectedValues.value}. `;
    },
  },
  {
    id: 'liveRefill',
    type: 'liveRefill',
    endpoint: 'loc',
    prerequisites: ['hypomed'],
    variant: 1,
  },
  {
    id: 'liveDose',
    type: 'liveRefill',
    endpoint: 'loc',
    variant: 2,
  },
  {
    id: 'liveInsurance',
    type: 'liveRefill',
    endpoint: 'loc',
    variant: 3,
  },
  {
    id: 'liveNoMeds',
    type: 'liveRefill',
    endpoint: 'loc',
    variant: 4,
  },
  {
    id: 'livePregnant',
    type: 'liveRefill',
    endpoint: 'loc',
    variant: 5,
  },
  {
    id: 'liveTsh',
    type: 'liveRefill',
    endpoint: 'loc',
    variant: 6,
  },

  {
    id: 'tshlong',
    type: 'valuetsh',
    endpoint: 'loc',
    onlyFirstTime: true,
    savingSection: 'labs',
    unit: 'mU/L',
    title: 'What was your last TSH level?',
    subTitle:
      'TSH levels typically fall between 0.4 and 4.0 milliunits per liter (mU/L) according to the American Thyroid Association',
    fakeDelayer: true,
    isHPI: true,
    hpiIndex: 7,
    schema: Yup.object().shape({
      tsh: Yup.string().required('Required'),
    }),
    hpiComputer: (selectedValues, session) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }

      return ` and ${
        session.gender.toLowerCase() === 'f' ? 'her' : 'his'
      } TSH level came back at ${selectedValues[0].answer} mU/L. `;
    },
    onSubmitHandler: vals => {
      if (vals && vals.length > 0) {
        const v = vals.find(x => x.questionId === 'tsh');
        if (v) {
          if (v.answer < 0.5 || v.answer > 4.7) {
            return 'disqualifiedtsh';
          }
        }
      }
    },
  },

  {
    id: 'lastchecklong',
    type: 'lastlevelcheck',
    endpoint: 'loc',
    savingSection: 'labs',
    onlyFirstTime: true,
    title: 'When was your last thyroid level taken?',
    fakeDelayer: true,
    isHPI: true,
    hpiIndex: 6,
    onSubmitHandler: vals => {
      // const v = vals.find(x=>x.questionId==='tsh')
      const dt = moment(vals[0].answer);
      if (
        moment()
          .add(-12, 'months')
          .isBefore(dt)
      ) {
        return 'uploadlabslong';
      } else {
        return 'updatelevelswithkit';
      }
    },
    hpiComputer: (selectedValues, session, hpiAnswer, values, flowMarkers) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }

      const force30 =
        flowMarkers &&
        flowMarkers.length > 0 &&
        flowMarkers.filter(x => x.id === '30day').length > 0;
      let when = false;
      try {
        when = moment()
          .add(-12, 'months')
          .isBefore(selectedValues[0].answer);
      } catch (error) {}
      return `<br /><br />${session.firstName}'s last TSH level was on ${
        selectedValues[0].answer
      }. ${
        !!when
          ? !force30
            ? `<br /><br />Based on the date of ${
                session.gender.toLowerCase() === 'f' ? 'her' : 'him'
              } last test we could give ${
                session.gender.toLowerCase() === 'f' ? 'her' : 'him'
              } 90 days. (if patient is eligible)`
            : `<br /><br />${
                session.gender.toLowerCase() === 'f' ? 'She' : 'he'
              } wasn't able to upload ${
                session.gender.toLowerCase() === 'f' ? 'her' : 'his'
              } lab results so we can give ${
                session.gender.toLowerCase() === 'f' ? 'her' : 'him'
              } only a 30 days refill`
          : ''
      }`;
    },
  },

  {
    id: 'uploadlabslong',
    type: 'uploadlabs',
    savingSection: 'labs',
    isSaveTemp: true,
    endpoint: 'loc',
    skip: 'updatelevelswithkit',
    onSubmitHandler: vals => {
      return 'doctormanaginghypo';
    },
  },

  {
    id: 'updatelevelswithkit',
    type: 'updatelevelswithkit',
    endpoint: 'loc',
    hideCountText: true,
    savingSection: 'labs',
    isHPI: true,
    hpiIndex: 8,
    hpiComputer: (selectedValues, session) => {
      return '';
      //  `Based on the date of ${
      //   session.gender.toLowerCase() === 'f' ? 'her' : 'his'
      // } last test we can give ${
      //   session.gender.toLowerCase() === 'f' ? 'her' : 'him'
      // } only 30 days refill. `;
    },
  },

  {
    id: 'whatexpect',
    type: 'whatexpect30days',
    endpoint: 'loc',
    savingSection: 'labs',
    hideCountText: true,
  },
  {
    id: 'howLongWeeks',
    // type: 'single',
    endpoint: 'loc',
    onlyFirstTime: false,
    prompt: 'For how many weeks have you been taking this dose?',
    isHPI: true,
    hpiIndex: 5,
    savingSection: 'labs',
    type: 'input',
    options: [
      {
        questionId: 'ansWeeks',
        placeholder: '',
        mask: '',
        inputType: 'number',
      },
    ],
    schema: Yup.object().shape({
      ansWeeks: Yup.string().required('Required'),
    }),
    hpiComputer: (selectedValues, session) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }

      return `(${selectedValues[0].answer} week${
        selectedValues[0].answer > 1 ? 's' : ''
      })`;
    },
  },

  {
    id: 'lastcheckshort',
    type: 'lastlevelcheck',
    endpoint: 'loc',
    savingSection: 'labs',
    onlyFirstTime: true,
    title: 'When was your last thyroid level taken?',
    fakeDelayer: true,
    isHPI: true,
    hpiIndex: 6,
    onSubmitHandler: (vals, getState) => {
      const st = getState();
      console.log({
        vals,
        st,
      });
      const weeksTaken = st.values.find(x => x.id === 'howLongWeeks');
      if (!weeksTaken || !weeksTaken.values) {
        return 'howLongWeeks';
      }
      const weeksTakenValue = weeksTaken.values.find(
        x => x.questionId === 'ansWeeks'
      );

      if (!weeksTakenValue || !weeksTakenValue.answer) {
        return 'howLongWeeks';
      }

      const dt = moment(vals[0].answer);
      if (
        moment()
          .add(weeksTakenValue.answer * -1, 'weeks')
          .add(6, 'weeks')
          .isAfter(dt)
      ) {
        return 'updatelevelsnewformulation';
      }

      return 'tshshort';
    },
    hpiComputer: (selectedValues, session) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }

      return `<br /><br />${session.firstName}'s last TSH level was on ${
        selectedValues.value
      }`;
    },
  },
  {
    id: 'updatelevelsnewformulation',
    type: 'updatelevelsnewformulation',
    endpoint: 'loc',
    hideCountText: true,
  },

  {
    id: 'tshshort',
    type: 'valuetsh',
    endpoint: 'loc',
    onlyFirstTime: true,
    savingSection: 'labs',
    unit: 'mU/L',
    title: 'What was your last TSH level?',
    isHPI: true,
    hpiIndex: 7,
    schema: Yup.object().shape({
      tsh: Yup.string().required('Required'),
    }),
    subTitle:
      'TSH levels typically fall between 0.4 and 4.0 milliunits per liter (mU/L) according to the American Thyroid Association',
    fakeDelayer: true,
    onSubmitHandler: vals => {
      if (vals[0].answer > 4.7 || vals[0].answer < 0.5) {
        return 'disqualifiedtsh';
      }
      return null;
    },
    hpiComputer: (selectedValues, session) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }

      return ` and ${
        session.gender.toLowerCase() === 'f' ? 'her' : 'his'
      } TSH level came back at ${selectedValues.value} mU/L.`;
    },
  },

  {
    id: 'uploadlabsshort',
    type: 'uploadlabs',
    endpoint: 'loc',
  },

  {
    id: 'doctormanaginghypo',
    type: 'input',
    savingSection: 'doctor',
    isSaveTemp: true,
    endpoint: 'locDoctor',
    onlyFirstTime: false,
    skip: 'conditions',
    prompt:
      'What is the name and phone number of the provider who is managing you with hypothyroidism?',
    options: [
      {
        questionId: 'ansDoctorName',
        placeholder: "Provider's name",
        mask: '',
      },
      {
        questionId: 'ansPhoneNumber',
        placeholder: 'Provider phone number',
        mask: '999-999-9999',
      },
    ],
    schema: Yup.object().shape({
      ansDoctorName: Yup.string().required('Required'),
      ansPhoneNumber: Yup.string()
        .trim()
        .required('Required')
        .matches(phoneRegExp, 'Phone number is not valid'),
    }),
  },
  {
    id: 'conditions',
    type: 'multiple',
    endpoint: 'loc',
    onlyFirstTime: false,
    // skip: 'concerns',
    isHPI: true,
    savingSection: 'conditions',
    hpiIndex: 9,
    prompt: 'Have you had any of the following conditions?',
    cannotSkip: true,
    options: [
      // {
      //   questionId: window._env_.REACT_APP_QUESTIONID_CELIAC,
      //   value: 'Celiac Disease',
      //   flowOrder: 10,
      //   goto: 'othermedical',
      // },
      {
        questionId: 'ansGraves',
        value: 'Graves Disease',
        flowOrder: 2,
        goto: 'graves',
      },
      {
        questionId: 'ansHyperthyroidism',
        value: 'Hyperthyroidism',
        flowOrder: 3,
        goto: 'hyperdiagnoseddate',
      },
      {
        questionId: 'ansPulmonary',
        value: 'Pulmonary Embolism',
        flowOrder: 5,
        goto: 'pulmonaryembolism',
      },
      {
        questionId: 'ansHeartAttack',
        value: 'Heart Attack',
        flowOrder: 6,
        goto: 'heartattack',
      },
      // {
      //   questionId: 'ansCOPD',
      //   value: 'COPD',
      //   flowOrder: 7,
      //   goto: 'oxygentank',
      // },
      // {
      //   questionId: 'ansHypoparathyroidism',
      //   value: 'Hypoparathyroidism or hyperparathyroidism',
      //   isDisqualifying: true,
      //   flowOrder: 8,
      // },
      {
        skip: 'othermedical',
        value: `I've never had any of these`,
        flowOrder: 9,
        clearOther: true,
      },
    ],
    onSubmitHandler: (values, getState) => {
      if (!values || !values.values) {
        return null;
      }

      if (
        values.filter(x => x.answer === 'Y' && x.isDisqualifying === true)
          .length > 0
      ) {
        return 'disqualified';
      }
      if (
        values.filter(x => x.answer === 'Y' && x.isLive === true).length > 0
      ) {
        return 'disqualifiedtsh';
      }
    },
    onPostSubmitHandler: (values, getState) => {
      const f = MedicalConditionsFlower(values, 0, getState, AsyncOnly);
      return f;
    },
    hpiComputer: (selectedValues, session, _, allValues) => {
      if (
        !selectedValues ||
        selectedValues.length < 1 ||
        selectedValues.filter(x => x.value === `I've never had any of these`)
          .length > 0
      ) {
        return '';
      }

      let details = [];

      for (let index = 0; index < selectedValues.length; index++) {
        const element = selectedValues[index];

        if (
          element.questionId !== 'ansPulmonary' &&
          element.questionId !== 'ansHeartAttack'
        ) {
          details.push(element.value);
        } else if (element.questionId === 'ansPulmonary') {
          const detAns = allValues.find(x => x.id === 'pulmonaryembolism');
          if (
            !!detAns &&
            detAns.values &&
            detAns.values.value.indexOf('Not') > -1
          ) {
            details.push(`${element.value} (${detAns.values.value})`);
          } else {
            details.push(element.value);
          }
        } else if (element.questionId === 'ansHeartAttack') {
          const detAns = allValues.find(x => x.id === 'heartattack');
          if (
            !!detAns &&
            detAns.values &&
            detAns.values.value.indexOf('Not') > -1
          ) {
            details.push(`${element.value} (${detAns.values.value})`);
          } else {
            details.push(element.value);
          }
        }
      }

      return `<br /><br />${
        session.gender.toLowerCase() === 'f' ? 'She' : 'He'
      } has ${details.join(', ')}. `;
    },
  },
  {
    id: 'graves',
    type: 'input',
    endpoint: 'medicalHistory',
    prompt:
      'Tell us more about your Graves Disease including when it was diagnosed.',
    defaults: {
      [`${window._env_.REACT_APP_QUESTIONID_GRAVES.toString()}`]: '',
    },
    onlyFirstTime: true,
    isHPI: true,
    extend: true,
    savingSection: 'conditions',
    schema: Yup.object().shape({
      [`${window._env_.REACT_APP_QUESTIONID_GRAVES.toString()}`]: Yup.string().required(
        'Required'
      ),
    }),
    bypassIfLabDiagnosed: true,
    options: [
      {
        questionId: [`${window._env_.REACT_APP_QUESTIONID_GRAVES.toString()}`],
      },
    ],
    onPostSubmitHandler: (values, getState) => {
      const f = MedicalConditionsFlower(values, 2, getState, AsyncOnly);
      return f;
    },
  },

  {
    id: 'hyperdiagnoseddate',
    type: 'lastlevelcheck',
    endpoint: 'medicalHistory',
    savingSection: 'conditions',
    onlyFirstTime: true,
    isYearOnly: true,
    isHyper: true,
    title:
      'When have you been diagnosed with hyperthyroid (over-active thyroid)?',
    fakeDelayer: true,
  },
  {
    id: 'thyroidremoved',
    type: 'single',
    endpoint: 'medicalHistory',
    savingSection: 'conditions',
    onlyFirstTime: false,
    prompt: 'Was your Thyroid radiated or removed?',
    options: [
      {
        questionId: '283',
        value: 'Radiated',
        answer: 'Radiated',
      },
      {
        questionId: '282',
        value: 'Removed',
        answer: 'Removed',
      },
      {
        questionId: '282',
        value: 'Not Sure / Other',
        answer: 'Not Sure / Other',
      },
      {
        questionId: '282',
        value: 'No',
        answer: 'No',
      },
    ],
  },
  //QID
  {
    id: 'hypertreated',
    type: 'input',
    endpoint: 'medicalHistory',
    savingSection: 'conditions',
    prompt:
      'Tell us more about how your hyperthyroidism was diagnosed and treated.',
    defaults: { '18': '' },
    onlyFirstTime: true,
    schema: Yup.object().shape({
      '18': Yup.string().required('Required'),
    }),
    extend: true,
    options: [{ questionId: '18' }],
    onPostSubmitHandler: (values, getState) => {
      const f = MedicalConditionsFlower(values, 3, getState, AsyncOnly);
      return f;
    },
  },
  {
    id: 'pulmonaryembolism',
    type: 'single',
    endpoint: 'medicalHistory',
    savingSection: 'conditions',
    onlyFirstTime: false,
    prompt: 'Did you have a pulmonary embolism within the last 6 months?',
    options: [
      {
        questionId: '292',
        value: 'Yes',
        answer: 'Y',
        goToPage: 'disqualifiedembolism',
      },
      {
        questionId: '292',
        value: 'No',
        suffix: 't in the last 6 month',
        answer: 'Y',
      },
    ],
    onPostSubmitHandler: (values, getState) => {
      if (values && values.length > 0) {
        const v = values.find(x => x.id === 'pulmonaryembolism');
        if (v.values && v.values.goToPage) {
          return v.values.goToPage;
        }
      }
      const f = MedicalConditionsFlower(values, 5, getState, AsyncOnly);
      return f;
    },
  },
  {
    id: 'heartattack',
    type: 'single',
    endpoint: 'medicalHistory',
    savingSection: 'conditions',
    onlyFirstTime: false,
    prompt: 'Did you have a heart attack within the last 6 months?',
    options: [
      {
        questionId: window._env_.REACT_APP_QUESTIONID_HEART_ATTACK,
        value: 'Yes',
        answer: 'Y',
        goToPage: 'disqualifiedheartattack',
      },
      {
        questionId: window._env_.REACT_APP_QUESTIONID_HEART_ATTACK,
        value: 'No',
        suffix: 't in the last 6 month',
        answer: 'Y',
      },
    ],
    onPostSubmitHandler: (values, getState) => {
      if (values && values.length > 0) {
        const v = values.find(x => x.id === 'heartattack');
        if (v.values && v.values.goToPage) {
          return v.values.goToPage;
        }
      }
      const f = MedicalConditionsFlower(values, 6, getState, AsyncOnly);
      return f;
    },
  },
  //QID
  {
    id: 'oxygentank',
    type: 'single',
    endpoint: 'loc',
    savingSection: 'conditions',
    onlyFirstTime: false,
    prompt: 'Do you use an oxygen tank?',
    options: [
      {
        questionId: 'ansYes',
        value: 'Yes',
        answer: 'Yes',
        goToPage: 'disqualified',
      },
      {
        questionId: 'ansNo',
        value: 'No',
        answer: 'No',
      },
    ],
    onPostSubmitHandler: (values, getState) => {
      if (values && values.length > 0) {
        const v = values.find(x => x.id === 'heartattack');
        if (v.values && v.values.goToPage) {
          return v.values.goToPage;
        }
      }
      const f = MedicalConditionsFlower(values, 7, getState, AsyncOnly);
      return f;
    },
  },
  {
    id: 'othermedical',
    type: 'single',
    endpoint: 'medicalHistory',
    onlyFirstTime: false,
    savingSection: 'conditions',
    isSaveTemp: true,
    prompt: 'Do you have any other medical conditions?',
    isHPI: true,
    hpiIndex: 11,
    options: [
      {
        questionId: window._env_.REACT_APP_QUESTIONID_OTHER_CONDITIONS,
        value: 'Yes',
        answer: 'Y',
        placeholder: 'What other medical conditions do you have?',
        giveDetails: true,
        giveDetailsEnforce: true,
      },
      {
        questionId: window._env_.REACT_APP_QUESTIONID_OTHER_CONDITIONS,
        value: "No, I don't have any other medical conditions",
        answer: 'N',
      },
    ],
    hpiComputer: (selectedValues, session) => {
      if (
        !selectedValues ||
        selectedValues.length < 1 ||
        selectedValues.answer ===
          `No, I don't have any other medical conditions` ||
        selectedValues.value === `No, I don't have any other medical conditions`
      ) {
        return '';
      }

      return `<br /><br />${
        session.firstName
      } has had the following conditions ${selectedValues.value}`;
    },
  },
  {
    id: 'surgeries',
    type: 'single',
    endpoint: 'surgicalHistory',
    savingSection: 'surgeries',
    isSaveTemp: true,
    onlyFirstTime: false,
    prompt: 'Have you ever had a surgery or hospitalization?',
    options: [
      {
        questionId: '80050',
        value: 'Yes',
        answer: 'Y',
        placeholder:
          'Please list all your prior surgeries and hospitalizations',
        giveDetails: true,
        giveDetailsEnforce: true,
        extend: true,
      },
      {
        questionId: '80050',
        value: 'No, never had surgery or been hospitalized',
        answer: 'N',
      },
    ],
  },
  {
    id: 'othermedicines',
    type: 'single',
    endpoint: 'medications',
    savingSection: 'othermeds',
    onlyFirstTime: false,
    isHPI: true,
    prompt: 'Do you currently take any medications, herbals, or supplements?',
    options: [
      {
        questionId: 'ans294886',
        value: 'Yes',
        answer: 'Y',
      },
      {
        value: 'No',
        answer: 'N',
        goToPage: 'otc',
      },
    ],
  },
  {
    id: 'othermedications',
    skip: 'otc',
    type: 'medicationList',
    savingSection: 'othermeds',
    isHPI: true,
    endpoint: 'medications',
    title: 'Please list the other prescription medications you are taking',
    prompt: '',
    nothingText: `I don't take any other prescription medication`,
    onlyFirstTime: false,
    other: { questionId: '301410' },
    isInputOnly: false,
    useUsaRx: true,
    onSubmitHandler: selectedOptions => {
      console.log('sel opts', selectedOptions);
      if (!selectedOptions || selectedOptions.length < 1) {
        return 'otc';
      }

      return null;
    },
  },
  {
    id: 'othermedicationstime',
    skip: 'otc',
    type: 'input',
    savingSection: 'othermeds',
    isHPI: true,
    endpoint: 'medicationsTime',
    prompt:
      'At what time do you take your over-the-counter medication? And do you take them at the same time as your thyroid medications?',
    // prompt: '',
    onlyFirstTime: false,
    extend: true,
    options: [{ questionId: '301410', extend: true }],
  },
  {
    id: 'otc',
    type: 'single',
    endpoint: 'medications',
    savingSection: 'othermeds',
    isSaveTemp: true,
    onlyFirstTime: false,
    prompt:
      'In past month, have you started or stopped any over-the-counter or Rx medicine; birth control, hormone replacement, antidepressant (sertraline), seizure (phenytoin, carbamazepine, phenobarbital), antibiotic (rifampin), heartburn (omeprazole) that you take daily?',
    options: [
      {
        questionId: '294886',
        value: 'Yes',
        answer: 'Y',
        placeholder: 'Tell us more',
        giveDetails: true,
      },
      {
        questionId: 'ans294886',
        value: 'No',
        answer: 'N',
      },
    ],
    hpiComputer: (selectedValues, session) => {
      if (
        !selectedValues ||
        selectedValues.length < 1 ||
        selectedValues.answer === `No` ||
        selectedValues.value === `No`
      ) {
        return '';
      }

      return `<br /><br />New OTC or Rx medicine: ${selectedValues.value}`;
    },
  },
  {
    isSaveTemp: true,
    id: 'allergies',
    type: 'allergiesList',
    endpoint: 'allergies',
    savingSection: 'othermeds',
    hpiIndex: 13,
    onlyFirstTime: false,
    title: 'Do you have any allergies to medications or other substances?',
    options: [
      {
        questionId: '12990',
        prefix: 'Allergies to medications / other substances -',
        answer: 'Y',
        value: 'Yes',
        placeholder: 'Tell us more',
        giveDetails: true,
        giveDetailsEnforce: true,
      },
      {
        questionId: 'ans12990',
        answer: 'NKDA',
        value: 'No',
      },
    ],
    hpiComputer: (selectedValues, session) => {
      if (
        !selectedValues ||
        selectedValues.length < 1 ||
        selectedValues.answer === `No` ||
        selectedValues.value === `No`
      ) {
        return '';
      }

      return `<br /><br />${
        session.firstName
      } has the following allergies: ${selectedValues
        .map(x => `${x.label}`)
        .join(', ')}`;
    },
  },

  {
    id: 'heightweight',
    type: 'heightweight',
    endpoint: 'socialHistory',
    savingSection: 'heightweight',
    isSaveTemp: true,
    onlyFirstTime: true,
    fakeDelayer: true,
  },
  {
    id: 'runinfamily',
    type: 'multiple',
    endpoint: 'familyHistory',
    savingSection: 'runsfamily',
    isSaveTemp: true,
    onlyFirstTime: false,
    prompt: 'Does hypothyroidism run in the family?',
    cannotSkip: true,
    options: [
      { questionId: '1', answer: 'Has hypothyroidism', value: 'Mother' },
      { questionId: '2', answer: 'Has hypothyroidism', value: 'Father' },
      { questionId: '3', answer: 'Has hypothyroidism', value: 'Brother' },
      { questionId: '4', answer: 'Has hypothyroidism', value: 'Sister' },
      // {
      //   questionId: '5',
      //   value: 'Other relative',
      //   isExtended: true,
      //   giveDetails: true,
      //   placeholder: 'tell us more...',
      // },
      {
        questionId: 'ans',
        value: 'No',
        answer: 'No',
        clearOther: true,
      },
    ],
  },
  {
    id: 'anythingelse',
    type: 'input',
    endpoint: 'loc',
    prompt: 'Is there anything else we should know?',
    defaults: { ansElse: '' },
    savingSection: 'anythingelse',
    skip: 'firstmessage',
    isSaveTemp: true,
    isHPI: true,
    hpiIndex: 14,
    extend: true,
    options: [{ questionId: 'ansElse', extend: true }],
    hpiComputer: (selectedValues, session) => {
      if (
        !selectedValues ||
        selectedValues.length < 1 ||
        !selectedValues[0].answer
      ) {
        return '';
      }

      return `<br /><br />${
        session.firstName
      } also thinks we should know the following: ${selectedValues[0].answer}`;
    },
  },
  {
    id: 'firstmessage',
    type: 'input',
    endpoint: 'loc',
    prompt: 'Say Hi!',
    isHPI: true,
    isHelpscout: true,
    hpiIndex: 15,
    savingSection: 'firstmessage',
    isSaveTemp: true,
    subTitle:
      'Here is your first message to your clinician. Please introduce yourself and ask the clinician any questions you have about the treatment. Feel free to include anything else you want them to know about your condition.',
    defaults: { ansFirstMessage: '' },
    extend: true,
    schema: Yup.object().shape({
      ansFirstMessage: Yup.string().required('Required'),
    }),
    options: [{ questionId: 'ansFirstMessage', extend: true }],
    hpiComputer: (selectedValues, session) => {
      if (
        !selectedValues ||
        selectedValues.length < 1 ||
        !selectedValues[0].answer
      ) {
        return '';
      }

      return `<br /><br />And wrote you the following message that you can respond from Helpscout: <br />${
        selectedValues[0].answer
      }`;
    },
  },
  {
    id: 'medicationBottlePicture',
    type: 'medicationBottlePicture',
    endpoint: 'loc',
    useUsaRx: true,
    title:
      'Upload a picture of your current pill bottle of your hypothyroid medication',
    subTitle:
      'For this visit, we need a few specific photos to help your provider accurately assess your medical condition',
    fakeDelayer: true,
    isHPI: true,
    hpiIndex: 100,
    hpiComputer: (selectedValues, session) => {
      return `<br /><br />Please check ${session.firstName}'s ID and photo`;
    },
  },
  {
    id: 'pharmacy',
    type: 'pharmacy',
    endpoint: 'loc',
  },
  // {
  //   id: 'insurancedisclaimer',
  //   type: 'insurancedisclaimer',
  //   endpoint: 'loc',
  // },
  {
    id: 'idVerif',
    type: 'idVerif',
    endpoint: 'loc',
    hideCountText: true,
    onlyFirstTime: true,
    title: 'Treatment Eligibility',
    subTitle: 'Let’s confirm that you are eligible for this service',
    fakeDelayer: true,
  },
  {
    id: 'chooseprice',
    type: 'chooseprice',
    fakeDelayer: true,
    endpoint: 'loc',
  },
  {
    id: 'favorites',
    type: 'favorites',
    fakeDelayer: true,
    endpoint: 'loc',
  },
  {
    id: 'finish',
    type: 'finish',
    endpoint: 'loc',
    klaviyoEvent: 'Async Checkout',
  },
  {
    id: 'whatsnext',
    type: 'whathappensnext',
    hideCountText: true,
  },
  {
    id: 'disqualified',
    type: 'disqualified',
    hideCountText: true,
    endpoint: 'disqualified',
    onlyFirstTime: false,
    prompt: 'disqualified',
  },
  {
    id: 'disqualifiedembolism',
    type: 'disqualified',
    hideCountText: true,
    endpoint: 'disqualified',
    onlyFirstTime: false,
    prompt: 'disqualified',
    variant: 'embolism',
  },
  {
    id: 'disqualifiedheartattack',
    type: 'disqualified',
    hideCountText: true,
    endpoint: 'disqualified',
    onlyFirstTime: false,
    prompt: 'disqualified',
    variant: 'heartattack',
  },
  {
    id: 'disqualifiedcancer',
    type: 'disqualified',
    hideCountText: true,
    endpoint: 'disqualified',
    onlyFirstTime: false,
    prompt: 'disqualified',
    variant: 'cancer',
  },
  {
    id: 'disqualifiedpregnant',
    type: 'disqualifiedpregnant',
    hideCountText: true,
    endpoint: 'disqualified',
    onlyFirstTime: false,
    prompt: 'disqualified',
  },
  {
    id: 'disqualifiedt3',
    type: 'disqualifiedt3',
    hideCountText: true,
    endpoint: 'disqualified',
    onlyFirstTime: false,
    prompt: 'disqualified',
  },
  {
    id: 'disqualifiedsymptoms',
    type: 'disqualifiedsymptoms',
    hideCountText: true,
    endpoint: 'disqualified',
    onlyFirstTime: false,
    prompt: 'disqualified',
  },
  {
    id: 'disqualifiedtsh',
    type: 'disqualifiedtsh',
    hideCountText: true,
    endpoint: 'disqualified',
    onlyFirstTime: false,
    prompt: 'disqualified',
  },
  {
    id: 'testFirst',
    hideCountText: true,
    type: 'testFirst',
  },
];
