import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading, Text } from 'rebass';
import { Formik, Form } from 'formik';
import { withWizard } from 'react-albus';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Mailcheck from 'react-mailcheck';

import { TextInput } from './TextInput';
import { FormField } from './FormField';
import TextArea from './TextArea';

const MailcheckWrapper = styled.div`
  text-align: right;
  @media screen and (max-width: 52em) {
    text-align: left;
  }
`;

const MailcheckButton = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  font-size: inherit;
  font-style: italic;
  color: rgb(237, 183, 168);
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;

  &:focus {
    outline: none;
    color: rgb(237, 183, 168);
  }

  &:hover {
    color: rgb(237, 183, 168);
  }

  &:active {
    color: rgb(237, 183, 168);
  }
`;

const schema = Yup.object().shape({
  firstNameFriend: Yup.string()
    .trim()
    .required('Required'),
  lastNameFriend: Yup.string()
    .trim()
    .required('Required'),
  emailFriend: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

const GiveKitToFriend = props => {
  return (
    <Fragment>
      <Flex flex={1}>
        <Card p={4}>
          <Flex flexDirection="column">
            <Heading textAlign="center" fontWeight={550} mb={4}>
              Buy One Give One,
            </Heading>
            <Text textAlign="center" mb={4}>
              January is National Thyroid Awareness Month.
              <br /> <br />
              This month only, for every kit purchased, we are giving one kit to
              one of your friends. Enter your friend’s information below and
              we’ll send her an email so she can claim her free kit.
            </Text>

            <Formik
              isInitialValid={({ initialValues }) =>
                schema.isValidSync(initialValues)
              }
              initialValues={props.values}
              validationSchema={schema}
              onSubmit={values => {
                // console.log(values);
                props.onSubmit('giveToFriend', values, props.wizard);
              }}
              render={({ isValid, errors, touched, values, setFieldValue }) => (
                <Form>
                  <Flex flexDirection={['column', 'column', 'row']}>
                    <FormField
                      width={[1, 1, 1 / 2]}
                      mb={3}
                      mr={[0, 0, 3]}
                      error={touched.firstNameFriend && errors.firstNameFriend}
                    >
                      <TextInput
                        placeholder="Friend's First Name"
                        type="text"
                        name="firstNameFriend"
                      />
                    </FormField>
                    <FormField
                      width={[1, 1, 1 / 2]}
                      mb={3}
                      error={touched.lastNameFriend && errors.lastNameFriend}
                    >
                      <TextInput
                        placeholder="Friend's Last Name"
                        type="text"
                        name="lastNameFriend"
                      />
                    </FormField>
                  </Flex>

                  <FormField
                    mb={3}
                    error={touched.emailFriend && errors.emailFriend}
                  >
                    <Mailcheck email={values.emailFriend}>
                      {suggestion => (
                        <>
                          <TextInput
                            placeholder="Friend's Email Address"
                            type="email"
                            name="emailFriend"
                          />
                          {suggestion && (
                            <MailcheckWrapper className="mailcheck">
                              Did you mean{' '}
                              <MailcheckButton
                                type="button"
                                onClick={() =>
                                  setFieldValue('emailFriend', suggestion.full)
                                }
                              >
                                {suggestion.full}
                              </MailcheckButton>
                              ?
                            </MailcheckWrapper>
                          )}
                        </>
                      )}
                    </Mailcheck>
                  </FormField>

                  <FormField
                    mb={3}
                    error={
                      touched.personalMessageFriend &&
                      errors.personalMessageFriend
                    }
                  >
                    <TextArea
                      placeholder="Personal Message (optional)"
                      type="text"
                      name="personalMessageFriend"
                    />
                  </FormField>
                  <Text textAlign="center" mb={4}>
                    Not for people living in NY, NJ or RI. Participants must be
                    18 years old or older
                  </Text>

                  <Flex
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Button
                      disabled={!isValid}
                      variant="pink"
                      width={[1, 1 / 2, 1 / 3]}
                      type="submit"
                    >
                      Continue to checkout
                    </Button>
                  </Flex>
                  <div
                    style={{
                      height: '1px',
                      backgroundColor: '#ccc',
                      margin: '40px -32px',
                    }}
                  />
                  <Flex
                    flex={1}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    textAlign="center"
                  >
                    <Text
                      textAlign="center"
                      mb={1}
                      fontSize="18px"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        props.wizard.push(props.onSkipMoveTo);
                      }}
                    >
                      <span style={{ color: '#364f79' }}>Skip to checkout</span>
                      <span style={{ color: '#f9a394', fontWeight: '600' }}>
                        {' >'}
                      </span>
                    </Text>
                  </Flex>
                </Form>
              )}
            />
          </Flex>
        </Card>
      </Flex>
    </Fragment>
  );
};

GiveKitToFriend.propTypes = {
  wizard: PropTypes.object.isRequired,
  values: PropTypes.object,
  onSubmit: PropTypes.func,
  onSkipMoveTo: PropTypes.string.isRequired,
};

GiveKitToFriend.defaultProps = {
  onSubmit: () => {},
};

export default withWizard(GiveKitToFriend);
