import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { Formik, Form } from 'formik';
import { FormField } from '../components/FormField';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';
import gql from 'graphql-tag';
import { MaskInput } from '../components/async/SharedComponents';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;

const MUTATION = gql`
  mutation adminGenerateOtpForPatient($phone: String!) {
    adminGenerateOtpForPatient(phone: $phone) {
      otp
    }
  }
`;

const AdminGenerateOtp = (props) => {
  const [otp, setOtp] = useState('');
  const handleSubmit = async (values, actions) => {
    setOtp('');
    actions.setSubmitting(true);
    try {
      const res = await props.client.mutate({
        mutation: MUTATION,
        variables: {
          phone: values.phone,
        },
      });
      console.log('res', res);

      if (res && res.data && res.data.adminGenerateOtpForPatient) {
        setOtp(res.data.adminGenerateOtpForPatient.otp);
        actions.setSubmitting(false);
      } else {
        actions.setSubmitting(false);
        // console.log(re)
        props.toastManager.add('Error generating, please try again', {
          appearance: 'error',
        });
      }

      actions.setSubmitting(false);
      //   toastManager.add('Error submitting intake form', { appearance: 'error' });
    } catch (err) {
      actions.setSubmitting(false);
      props.toastManager.add('Error generating, please try again', {
        appearance: 'error',
      });
    }
  };

  return (
    <Container>
      <Flex m={[3, 5]} flex={1} flexDirection="column">
        <Heading mb={4} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
          Generate OTP code for phone number
        </Heading>
        <Card mb={4} p={[3, 4]}>
          <Flex flexDirection={'column'} flex={1} alignItems="center">
            <Formik
              initialValues={{ phone: '' }}
              // validationSchema={schema}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
              render={({ errors, touched, isValid, isSubmitting, handleChange, handleBlur, values }) => (
                <Form style={{ width: '300px' }}>
                  <Flex flexDirection="column" flex={1}>
                    <Heading mb={4}>Patient's Phone</Heading>
                  </Flex>
                  <FormField mb={4} error={touched.phone && errors.phone}>
                    <MaskInput style={{textAlign:'center'}} name="phone" placeholder="Mobile Phone Number" mask="999-999-9999" value={values.phone} onChange={handleChange} onBlur={handleBlur} id="input_phone" />
                  </FormField>

                  <Button mt={4} mb={4} variant="primary" width={1 / 2} disabled={!isValid || isSubmitting} type="submit">
                    Generate
                  </Button>
                </Form>
              )}
            />
            {!!otp && (
              <Flex flexDirection={'row'} justifyContent={'center'} alignItems={'baseline'}>
                <Text>{'OTP code: '}</Text>
                <Heading ml={3}>{otp}</Heading>
              </Flex>
            )}
          </Flex>
        </Card>
      </Flex>
    </Container>
  );
};

export default compose(
  withApollo,
  withToastManager
)(AdminGenerateOtp);
