import React from 'react';
import styled from 'styled-components';
import { Heading, Button, Box } from 'rebass';

import ConfirmModal from '../../ConfirmModal';
import TextArea from '../../TextArea';
import Dropzone from 'react-dropzone';
import { ToBeUploadedFilesMiniaturesLarge, attachmentToUploadDisplay } from './AttachmentDisplay';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const AddFileButton = styled(Button)`
  padding: 8px;
  align-self: flex-start;
  margin-top: 4px;
  font-weight: 400;
`;

const AttachmentModal = ({
  confirmModalForm,
  values,
  close,
  files,
  addFiles,
  onFileRemove,
  isSubmitting,
}) => {
  const fileAccepted = f => {
    for (let index = 0; index < f.length; index++) {
      const element = f[index];

      const reader = new FileReader();
      reader.onload = event => {
        const fls = files;
        fls.push({
          fileName: element.name,
          mimeType: element.type,
          data: event.target.result,
        });
        addFiles(fls);
      };
      reader.readAsDataURL(element);
    }
  };

  return (
    <ConfirmModal
      disabled={isSubmitting}
      isOpen={true}
      onClose={close}
      onBackgroundClick={close}
      onEscapeKeydown={close}
      onConfirm={confirmModalForm}
      confirmLabel="Upload"
      cancelLabel="Cancel"
      confirmStyle={{
        width: 'auto',
      }}
      cancelStyle={{
        width: 'auto',
      }}
      cancelVariant="outline"
      buttonsDirection="row-reverse"
      buttonsFlexStyle={{
        justifyContent: 'space-between',
      }}
      displayX={true}
      cardStyle={{
        width: '700px',
        maxWidth: 'calc(100vw - 40px)',
      }}
    >
      <ModalInner>
        <Heading mb={4} textAlign="center" fontWeight="bold">
          Upload a File
        </Heading>
        <TextArea
          style={{
            border: '1px solid rgb(134,134,134)',
            marginBottom: '12px',
            background: 'white',
          }}
          placeholder={`Add a message about the file${
            files.length > 1 ? 's' : ''
          }`}
          name="msgTxt"
          value={values.msgTxt}
        />
        <Dropzone
          noClick={true}
          id="dropzoneModal"
          multiple={true}
          onDropRejected={() => {}}
          onDropAccepted={f => {
            fileAccepted(f);
          }}
          maxSize={10000000}
          style={{ width: '100%', height: '100%' }}
        >
          {({ getRootProps, getInputProps, isDragActive, open }) => (
            <>
              <input type="file" {...getInputProps()} />
              {files.length > 1 ? (
                <ToBeUploadedFilesMiniaturesLarge
                  files={files}
                  onFileRemove={onFileRemove}
                />
              ) : (
                attachmentToUploadDisplay(files[0])
              )}

              <AddFileButton
                type="button"
                variant="white"
                onClick={() => {
                  open();
                }}
              >
                Add Files
              </AddFileButton>
            </>
          )}
        </Dropzone>
      </ModalInner>
    </ConfirmModal>
  );
};

export { AttachmentModal };
