import React, { Component } from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { compose, withApollo } from 'react-apollo';
import { Redirect, withRouter } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import withSession from '../lib/withSession';
import withAnalytics from '../lib/withAnalytics';
import {Complete} from './scheduleDoctor/complete';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
  @media screen and (max-width: 52em) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const InnerContainer = styled(Flex)`
  max-width: 850px;
  margin: 0 auto;
  flex-direction: column;
`;
class ScheduleNewPatientComplete extends Component {
  render() {
    if (!this.props.location || !this.props.location.state) {
      return <Redirect to="/" />;
    }

    const {
      startTime,
      date,
      couldntConfirmCard,
      prompt,
    } = this.props.location.state;
    const slot = {
      startTime,
      date,
    };
    // console.log('complete', {
    //   startTime,
    //   date,
    //   couldntConfirmCard,
    //   prompt,
    //   slot,
    // });
    return (
      <Flex flex={1} flexDirection="column" style={{ height: '100vh' }}>
        <Container>
          <InnerContainer p={3}>
            <Complete
                      currentIntakeStatus={""}
              couldntConfirmCard={couldntConfirmCard}
              prompt={prompt}
              slot={slot}
              reinitFlow={() => {}}
              history={this.props.history}
            />
          </InnerContainer>
        </Container>
      </Flex>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  withToastManager,
  withAnalytics
)(withSession(ScheduleNewPatientComplete));
