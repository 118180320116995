import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { TransitionGroup } from 'react-transition-group';

const StyledContainer = styled(Flex)`
  flex-direction: column;
  box-sizing: border-box;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px;
  pointer-events: auto;
  position: fixed;
  z-index: 9999;
  ${props => placements[props.placement]}

  @media screen and (max-width: 46em) {
    top: 0;
    padding: 0;
  }
`;

const placements = {
  'top-left': { top: '20px', left: 0 },
  'top-center': { top: '20px', left: '50%', transform: 'translateX(-50%)' },
  'top-right': { top: '20px', right: 0 },
  'bottom-left': { bottom: 0, left: 0 },
  'bottom-center': { bottom: 0, left: '50%', transform: 'translateX(-50%)' },
  'bottom-right': { bottom: 0, right: 0 },
};

export const ToastContainer = props => (
  <StyledContainer {...props}>
    <TransitionGroup component={null}>{props.children}</TransitionGroup>
  </StyledContainer>
);
