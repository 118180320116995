import React, { useEffect, useRef, useState } from 'react';
import { Flex } from 'rebass';

const SlideSectionContentElement = ({ children }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <Flex
      flexDirection={'column'}
      ref={ref}
      maxHeight={'calc(100vh * 0.8)'}
      style={{
        maxHeight: height > window.innerHeight * 0.8 ? `${window.innerHeight * 0.8}px` : '',
        overflowY: height > window.innerHeight * 0.8 ? `scroll` : '',
      }}
    >
      {children}
    </Flex>
  );
};

export default SlideSectionContentElement;
