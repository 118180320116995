import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import CommunicatorWrapper from '../components/memberCommunicator/careTeam/CommunicatorWrapper';
import withSession from '../lib/withSession';

const Wrap = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow-y: hidden;
`;
const Container = styled(Box)`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    max-width: 100vw;
    padding-left: 6px;
    padding-right: 6px;
  }
`;

const Wrapper = styled(Flex)`
  flex: 1;
  height: 100vh;
`;

const MobileChatCareTeam = ({ history, session: { isMember } }) => {
  if (!isMember) {
    history.push('/become-member');

    return <div />;
  }

  useEffect(() => {
    if (window && window.Beacon) {
      window.Beacon('destroy');
    }
  });

  return (
    <Wrapper>
      <>
        <Container>
          <CommunicatorWrapper noBottomPadding mobileApp/>
        </Container>
      </>
    </Wrapper>
  );
};

export default withRouter(withSession(MobileChatCareTeam));
