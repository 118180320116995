import React from 'react';
import styled from 'styled-components';
import { Flex, Button, Text } from 'rebass';

import chatWithUsIcon from '../../../../static/chatWithUsIcon.png';

const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;

const ChatWithUs = props => {
  const chat = () => {
    window.open('mailto:contact@palomahealth.com', '_blank');
    // window.location.href = "";

    // if (window.Beacon) {
    //   window.Beacon('open');
    // }
  };
  return (
    <Wrapper>
      <img src={chatWithUsIcon} height="32px" alt="" />
      <Text margin='12px 0' fontWeight="400">You have a question?</Text>
      <Button width='172px' variant="primary" onClick={() => chat()}>
        Chat With Us
      </Button>
    </Wrapper>
  );
};

export default ChatWithUs;
