import React, { Fragment, useState } from 'react';
import { Flex, Box, Button, Heading, Card, Text } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BoxButton } from '../async/SharedComponents';
import AllergySelector from '../allergySelector/AllergySelector';
import { useMount } from '../../utils/hooks/lifecycle';
import { SelectedAllergyCard } from '../../pages/asyncIntake/screens/components/medicationLists/SelectedAllergyCard';
import { GET_INTAKE_PREVIOUS_VALUES } from '../../graphql';
import SpinLoader from '../SpinLoader';
import ScrollAnimation from 'react-animate-on-scroll';
import { fadeInDown } from '../../utils/animationsStyleBuilder';

const AllergiesList = ({ onSubmit, data, getState, wizard, client }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);

  useMount(async () => {
    // try {
    //   const { data } = await client.query({ query: GET_INTAKE_PREVIOUS_VALUES });

    //   if (data && data.getCurrentIntakeValues) {
    //     const als = data.getCurrentIntakeValues.allergies;
    //     setSelectedOptions(
    //       als.map((x) => {
    //         return {
    //           answer: 'Y',
    //           questionId: x.allergenid.toString(),
    //           value: x.allergenname,
    //           label: x.allergenname,
    //         };
    //       })
    //     );
    //   }
    // } catch (error) {}
    setHasLoaded(true);
  });

  const handleSubmit = () => {
    const sels = {
      endpoint: data.endpoint,
      values: selectedOptions.map((x) => ({
        answer: 'Y',
        questionId: x.questionId,
        value: x.label,
      })),
    };
    onSubmit(sels, null, wizard, data);
  };
  const noAllergystyle = {
    background: '#FFF2F2',
    border: '2px solid #F6A293',
    borderRadius: '20px',
    margin: '24px auto',
    color: '#344f79',
  };
  const allergyStyle = {
    margin: '24px auto',
  };
  return (
    <Box
      p={3}
      pt={0}
      mb={5}
      mx={3}
      style={{
        zIndex: 1,
        maxWidth: '450px',
        justifyContent: 'center',
        display: 'flex',
        margin: '0 auto',
      }}
    >
      <Flex flexDirection="column" justifyContent="center">
        <Flex flex={1} flexDirection="column">
          <Box m={2} mb={3} style={{ ...fadeInDown() }}>
              <Text fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
                {data.prompt}
              </Text>
          </Box>
          {!hasLoaded ? (
            <Flex justifyContent={'center'} alignItems="center">
              <SpinLoader width={100} height={100} />
            </Flex>
          ) : (
            <>
              {/* <ScrollAnimation animateIn="fadeIn" delay={150} animateOnce={false}> */}
                <AllergySelector
                  onChange={(option) => {
                    if (!option || !option.value) return;
                    option.questionId = option.value.toString();
                    setSelectedOptions((previous) => [...previous, option]);
                  }}
                />
              {/* </ScrollAnimation> */}
              {/* <TransitionGroup className="items-section__list"> */}
                {selectedOptions.map((x) => {
                  return (
                    <CSSTransition timeout={500} classNames="moveList">
                      <SelectedAllergyCard key={x.value} name={x.label} onClick={() => setSelectedOptions(selectedOptions.filter((y) => y.value !== x.value))} />
                    </CSSTransition>
                  );
                })}
              {/* </TransitionGroup> */}

              {/* <BoxButton className="visible"> */}
              {/* <ScrollAnimation animateIn="fadeInUpFrom20" delay={150} animateOnce={false} width='100%' style={{display: 'flex'}}> */}
                <Button
                  variant={!selectedOptions || selectedOptions.length < 1 ? 'primary' : 'primary'}
                  style={!selectedOptions || selectedOptions.length < 1 ? noAllergystyle : allergyStyle}
                  my={4}
                  mt={4}
                  type="button"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {!selectedOptions || selectedOptions.length < 1 ? `I don't have any allergies` : `Continue`}
                </Button>
              {/* </ScrollAnimation> */}
              {/* </BoxButton> */}
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(AllergiesList);
