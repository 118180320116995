import React from 'react';
import { Flex, Button } from 'rebass';
import 'react-table-v6/react-table.css';
import Select from 'react-select';
import { Input } from '../../../../../components/TextInput';

class AddQuizzButtonsModalInner extends React.Component {
  constructor(props) {
    super(props);
    const {currentEditingButtonData} = this.props
    this.state = {
      title: currentEditingButtonData ? currentEditingButtonData.title : null,
      variant: currentEditingButtonData
        ? currentEditingButtonData.variant
        : null,
      goTo: currentEditingButtonData ? currentEditingButtonData.goTo : null,
    };
  }
  render() {
    const { title, variant, goTo } = this.state;
    const { onClose, onSave } = this.props;
    const variants = [
      { value: 'primary', label: 'primary' },
      { value: 'secondary', label: 'secondary' },
      { value: 'white', label: 'white' },
    ];
    return (
      <Flex flexDirection="column">
        <label htmlFor="title" style={{marginTop: '12px'}}>Button Text</label>
        <Input
          placeholder="Button Text"
          type="text"
          name="title"
          id="input_title"
          value={title}
          onChange={v => {
            this.setState({
              title: v.target.value,
            });
          }}
        />
        <label htmlFor="btnVariant" style={{marginTop: '12px'}}>Button Style</label>
        <Select
          classNamePrefix="region-select"
          options={variants}
          name="btnVariant"
          value={variant}
          placeholder="select style"
          onChange={option => {
            this.setState({
              variant: option,
            });
          }}
          isSearchable={true}
          
        />
        <label htmlFor="goTo" style={{marginTop: '12px'}}>On Click Go To</label>
        <label htmlFor="goTo">(leave empty for next screen)</label>
        <Input
          placeholder="On Click Go To"
          type="number"
          name="goTo"
          id="input_goTo"
          value={goTo}
          onChange={v => {
            this.setState({
              goTo: v.target.value,
            });
          }}
        />

        <Flex
          mt={4}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            style={{
              background: 'white',
              color: '#344f79',
              border: '1px solid #344f79',
            }}
            type="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="button"
            // disabled={!isValid}
            style={{ background: '#344f79' }}
            onClick={() => {
              onSave({
                ...this.state,
              });
            }}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    );
  }
}

export default AddQuizzButtonsModalInner;
