import React from 'react';
import ConfirmModal from './ConfirmModal';
import styled from 'styled-components';
import { Text, Box } from 'rebass';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const ConfirmOrderIsNotDuplicateModal = ({
  isOpen,
  onCancel,
  onProceed,
  minutesAgo,
}) => {
  // let minuteText = '';

  // if (minutesAgo > 1) {
  //   minuteText = `${minutesAgo} minutes`;
  // } else if (minutesAgo > 0.9) {
  //   minuteText = `a minute`;
  // } else {
  //   const seconds = minutesAgo / 100;

  //   if (seconds > 1) {
  //     minuteText = `${seconds} seconds`;
  //   } else {
  //     minuteText = `a second`;
  //   }
  // }

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onCancel}
      onBackgroundClick={onCancel}
      onEscapeKeydown={onCancel}
      onConfirm={onProceed}
      confirmLabel="Proceed"
      cancelLabel="Cancel"
      confirmStyle={{
        width: '100%',
      }}
      cancelStyle={{
        width: '100%',
        marginTop: '18px',
      }}
      cancelVariant="outline"
      buttonsDirection="column"
      displayX={true}
      cardStyle={{
        width: '500px',
        maxWidth: 'calc(100vw - 40px)',
      }}
    >
      <ModalInner>
        <Text fontSize={3} textAlign="center" mb={4}>
        Our records show you just ordered a kit. Do you want to
          complete this new order?
        </Text>
      </ModalInner>
    </ConfirmModal>
  );
};

export default ConfirmOrderIsNotDuplicateModal;
