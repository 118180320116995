import React, { useState } from 'react';
import { withWizard } from 'react-albus';
import { withRouter } from 'react-router';
import { Button, Card, Heading, Text, Flex } from 'rebass';
import styled from 'styled-components';
import withAnalytics from '../../../../lib/withAnalytics';
import smileySad from '../../../../static/smileySad.png';
import { CheckRadio } from '../../components/CheckRadio';

const A = styled(Text)`
  text-decoration: underline;
  margin-top: 28px;
  text-align: center;
  cursor: pointer;
`;

const rs = [
  {
    label: `I don't have hypothyroidism`,
    isChecked: false,
  },
  {
    label: `Can't afford it anymore`,
    isChecked: false,
  },
  {
    label: `Going for another company`,
    isChecked: false,
  },
  {
    label: `Don’t like the service`,
    isChecked: false,
  },
  {
    label: `I am not feeling better`,
    isChecked: false,
  },
  {
    label: `Poor experience with customer service`,
    isChecked: false,
  },
  {
    label: `Other`,
    isChecked: false,
  },
];

const rsKit = [
  {
    label: `I don't have hypothyroidism`,
    isChecked: false,
  },
  {
    label: `Can't afford it anymore`,
    isChecked: false,
  },
  {
    label: `Going for another company`,
    isChecked: false,
  },
  {
    label: `Don’t like the product`,
    isChecked: false,
  },
  {
    label: `Kit arrived too late`,
    isChecked: false,
  },
  {
    label: `Poor experience with customer service`,
    isChecked: false,
  },
  {
    label: `Other`,
    isChecked: false,
  },
];

const Reason = ({ confirm, wizard, viewOffers, hasOffers, history, type, analytics }) => {
  const [reasons, setReasons] = useState(
    type === 'subscription_vitamin' ? rs : rsKit
  );
  return (
    <Flex flexDirection="column">
      <Card
        style={{
          flexDirection: 'column',
          textAlign: 'center',
          padding: '32px',
        }}
      >
        <Heading>Help us Understand The Reason</Heading>

        <Flex justifyContent="center" mb={4} mt={4}>
          <Text textAlign="center">We are sorry to see you go!</Text>

          <img src={smileySad} style={{ marginLeft: '12px' }} alt='' />
        </Flex>
        <Flex
          flexDirection="column"
          style={{
            margin: '0 auto',
            display: 'table',
          }}
        >
          {reasons.map((x, ind) => (
            <CheckRadio
              isChecked={x.isChecked}
              key={x.label}
              onClick={() => {
                const r = JSON.parse(JSON.stringify(reasons));
                r[ind].isChecked = !x.isChecked;
                setReasons(r);
              }}
            >
              {x.label}
            </CheckRadio>
          ))}
        </Flex>
        {hasOffers && (
          <Button
            style={{ marginTop: '28px', width: 'auto' }}
            variant="outline"
            onClick={() => viewOffers(wizard)}
          >
            See Our Exclusive Offers
          </Button>
        )}

        <A onClick={() => {
            analytics.track('membership cancel', {
              subscriptionType: type === 'subscription_vitamin' ? 'vitamins' : 'kit',
              reasons: (JSON.parse(JSON.stringify(reasons)) || [])
                .filter(x => x.isChecked)
                .map(x => x.label)
                .join(', '),
            });
            confirm(wizard)}}>Cancel Subscription</A>
      </Card>
      <A
        onClick={() => {
          history.goBack();
        }}
      >
        Go Back
      </A>
    </Flex>
  );
};

export default withAnalytics(withRouter(withWizard(Reason)));
