import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styled from 'styled-components';
import { Box } from 'rebass';
import Select from 'react-select';

const states = [
  {
    label: 'Alabama',
    value: 'AL',
    level: 'full',
    id: 100,
  },
  {
    label: 'Alaska',
    value: 'AK',
    level: 'full',
    id: 98,
  },
  {
    label: 'Arizona',
    value: 'AZ',
    level: 'full',
    id: 7,
  },
  {
    label: 'Arkansas',
    value: 'AR',
    level: 'kit',
    id: 101,
  },
  {
    label: 'California',
    value: 'CA',
    level: 'full',
    id: 1,
  },
  {
    label: 'Colorado',
    value: 'CO',
    level: 'full',
    id: 5,
  },
  {
    label: 'Connecticut',
    value: 'CT',
    level: 'kit',
    id: 102,
  },
  {
    label: 'Delaware',
    value: 'DE',
    level: 'kit',
    id: 103,
  },
  {
    label: 'District of Columbia',
    value: 'DC',
    level: 'kit',
    id: 64,
  },
  {
    label: 'Florida',
    value: 'FL',
    level: 'full',
    id: 59,
  },
  {
    label: 'Georgia',
    value: 'GA',
    level: 'full',
    id: 65,
  },
  {
    label: 'Hawaii',
    value: 'HI',
    level: 'full',
    id: 99,
  },
  {
    label: 'Idaho',
    value: 'ID',
    level: 'full',
    id: 66,
  },
  {
    label: 'Illinois',
    value: 'IL',
    level: 'full',
    id: 2,
  },
  {
    label: 'Indiana',
    value: 'IN',
    level: 'full',
    id: 67,
  },
  {
    label: 'Iowa',
    value: 'IA',
    level: 'full',
    id: 68,
  },
  {
    label: 'Kansas',
    value: 'KS',
    level: 'kit',
    id: 16,
  },
  {
    label: 'Kentucky',
    value: 'KY',
    level: 'kit',
    id: 69,
  },
  {
    label: 'Louisiana',
    value: 'LA',
    level: 'full',
    id: 70,
  },
  {
    label: 'Maine',
    value: 'ME',
    level: 'kit',
    id: 71,
  },
  {
    label: 'Maryland',
    value: 'MD',
    level: 'full',
    id: 72,
  },
  {
    label: 'Massachusetts',
    value: 'MA',
    level: 'full',
    id: 73,
  },
  {
    label: 'Michigan',
    value: 'MI',
    level: 'full',
    id: 74,
  },
  {
    label: 'Minnesota',
    value: 'MN',
    level: 'full',
    id: 75,
  },
  {
    label: 'Mississippi',
    value: 'MS',
    level: 'kit',
    id: 76,
  },
  {
    label: 'Missouri',
    value: 'MO',
    level: 'full',
    id: 77,
  },
  {
    label: 'Montana',
    value: 'MT',
    level: 'full',
    id: 78,
  },
  {
    label: 'Nebraska',
    value: 'NE',
    level: 'full',
    id: 79,
  },
  {
    label: 'Nevada',
    value: 'NV',
    level: 'full',
    id: 80,
  },
  {
    label: 'New Hampshire',
    value: 'NH',
    level: 'full',
    id: 81,
  },
  {
    label: 'New Jersey',
    value: 'NJ',
    level: 'visit',
    id: 111,
  },
  {
    label: 'New Mexico',
    value: 'NM',
    level: 'full',
    id: 82,
  },
  {
    label: 'New York',
    value: 'NY',
    level: 'visit',
    id: 108,
    // id: 33,
  },
  {
    label: 'North Carolina',
    value: 'NC',
    level: 'kit',
    id: 34,
  },
  {
    label: 'North Dakota',
    value: 'ND',
    level: 'full',
    id: 83,
  },
  {
    label: 'Ohio',
    value: 'OH',
    level: 'full',
    id: 84,
  },
  {
    label: 'Oklahoma',
    value: 'OK',
    level: 'full',
    id: 85,
  },
  {
    label: 'Oregon',
    value: 'OR',
    level: 'full',
    id: 86,
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
    level: 'full',
    id: 87,
  },
  {
    label: 'Rhode Island',
    value: 'RI',
    level: 'none',
    id: 40,
  },
  {
    label: 'South Carolina',
    value: 'SC',
    level: 'full',
    id: 88,
  },
  {
    label: 'South Dakota',
    value: 'SD',
    level: 'full',
    id: 89,
  },
  {
    label: 'Tennessee',
    value: 'TN',
    level: 'full',
    id: 90,
  },
  {
    label: 'Texas',
    value: 'TX',
    level: 'full',
    id: 3,
  },
  {
    label: 'Utah',
    value: 'UT',
    level: 'full',
    id: 91,
  },
  {
    label: 'Vermont',
    value: 'VT',
    level: 'kit',
    id: 92,
  },
  {
    label: 'Virginia',
    value: 'VA',
    level: 'full',
    id: 93,
  },
  {
    label: 'Washington',
    value: 'WA',
    level: 'full',
    id: 94,
  },
  {
    label: 'West Virginia',
    value: 'WV',
    level: 'kit',
    id: 95,
  },
  {
    label: 'Wisconsin',
    value: 'WI',
    level: 'full',
    id: 96,
  },
  {
    label: 'Wyoming',
    value: 'WY',
    level: 'full',
    id: 97,
  },
];

const SelectStyled = styled(Box)`
  .region-select__control {
    border: 1px solid rgba(53, 78, 121, 0.1);
    border-radius: 10px;
    padding: 12px;
    background: #f8f8f8;

    &:hover {
      border-color: #eee;
    }

    &--is-focused {
      box-shadow: none;
    }

    .region-select__indicator-separator {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const SelectWrapper = (props) => {
  const { field, form, placeholder, value, id, onSelectionChange } = props;
  return (
    <SelectStyled>
      <Select
        classNamePrefix="region-select"
        options={states}
        name={field.name}
        value={states.filter((element) => element.value === value)}
        placeholder={placeholder}
        onChange={(option) => {
          form.setFieldValue(field.name, option.value);
          if (onSelectionChange) {
            onSelectionChange();
          }
        }}
        onBlur={() => form.setFieldTouched(field.name, true)}
        isSearchable={true}
        id={id}
      />
    </SelectStyled>
  );
};

export const getRegionByAbbreviation = (abbreviation) => {
  return states.find((x) => x.value.toLowerCase() === abbreviation.toLowerCase());
};

export const getRegionById = (id) => {
  return states.find((x) => x.id.toString() === (id || '').toString());
};

export const getRegionByName = (name) => {
  return states.find((x) => x.label.replace(/ /g, '').toLowerCase() === name.toLowerCase());
};

export const RegionSelectNotForm = (props) => {
  const { value, onSelectionChange } = props;

  return (
    <Select
      {...props}
      classNamePrefix="region-select"
      options={states}
      value={states.filter((element) => element.value === value)}
      onChange={(option) => onSelectionChange(option.value)}
      isSearchable={true}
      theme={(base) => ({
        ...base,
        colors: {
          ...base.colors,
          primary: '#364f79',
          primary50: '#dae6fa',
        },
      })}
    />
  );
};

export const RegionSelect = (props) => {
  return <Field {...props} component={SelectWrapper} />;
};

RegionSelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

RegionSelect.defaultProps = {
  name: 'state',
  placeholder: 'Your State',
};

export default RegionSelect;
