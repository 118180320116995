import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Box, Card, Flex, Heading, Text, Button } from 'rebass';

import { CrossSearch } from '../components/icons';
import LabResultsQuestion from '../static/LabResultsQuestion.png';
import PharmacySearch from '../components/PharmacySearch';
import withSession from '../lib/withSession';
import LabCenterSearch from '../components/LabCenterSearch';
import PleaseWait from '../components/PleaseWait';

const Container = styled(Box)`
  max-width: 1024px;
`;

const Icon = styled(Flex)`
  @media screen and (max-width: 52em) {
    display: none;
  }
`;

const LabCenter = props => {
  const [isSaving, setIsSaving] = useState(false)
  const {
    session: { labCenter },
    continueIntake,
  } = props;

  return (
    <Flex flexDirection="column" p={[3, 5]} flex={1} style={{ zIndex: 1 }}>
      <Container>
        <Flex>
          <Card mb={4} p={[3, 4]}>
            <Flex flexDirection={['column', 'row']}>
              <Icon mr={[0, 4]} mb={[4, 0]}>
                {/* <CrossSearch width={100} /> */}
                <img
                  src={LabResultsQuestion}
                  alt=""
                  height="140px"
                  style={{ height: '140px' }}
                />
              </Icon>
              <Flex flex={1} flexDirection="column">
                <Heading mb={4}>Your Preferred Laboratory</Heading>
                {!!isSaving && (
                  <PleaseWait />
                )}
                {!labCenter && !isSaving && (
                  <Text>
                    Your Paloma provider might recommend that you get additional
                    testing done. We will submit the lab order form for those
                    tests to our integrated partner Quest Diagnostics. Please
                    select a Quest location below
                  </Text>
                )}
                {labCenter && !isSaving && (
                  <Fragment>
                    <Text fontSize={3} fontWeight={500} mb={3}>
                      {labCenter.name}
                    </Text>
                    <Text mb={2}>{labCenter.formattedAddress}</Text>
                    <Text>{labCenter.phone}</Text>
                    {continueIntake && (
                      <Button
                        variant="pink"
                        style={{ width: '220px' }}
                        mt={4}
                        onClick={continueIntake}
                      >
                        Continue
                      </Button>
                    )}
                  </Fragment>
                )}
              </Flex>
            </Flex>
          </Card>
        </Flex>

        <LabCenterSearch setIsSaving={setIsSaving} isIntake={!!continueIntake}/>
      </Container>
    </Flex>
  );
};

export default withSession(LabCenter);
