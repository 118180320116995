import React, { useState } from 'react';
import TextInput, { Input } from '../components/TextInput';
import TextArea from '../components/TextArea';
import styled from 'styled-components';
import Select from 'react-select';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import gql from 'graphql-tag';
import { Form, Formik } from 'formik';
import FormField from '../components/FormField';
import { Mutation } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import ConfirmModal from '../components/ConfirmModal';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;
const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;

const MUTATION = gql`
  mutation adminBulkActivateSurveyFlag($ids: String!, $survey: String) {
    adminBulkActivateSurveyFlag(ids: $ids, survey: $survey) {
      notFound {
        id
        error
      }
      success {
        id
        error
      }
      alreadyFlagged {
        id
        error
      }
      errored {
        id
        error
      }
    }
  }
`;
const SURVEYS = [
  {
    label: 'Follow up 1',
    value: 'FOLLOWUP1',
  },
  {
    label: 'Follow up 2',
    value: 'FOLLOWUP2',
  },
  {
    label: 'Follow up 3',
    value: 'FOLLOWUP3',
  },
];
const AdminBulkSetSurveyFlag = ({ toastManager }) => {
  const [results, setResults] = useState();
  const [showResults, setShowResults] = useState(false);
  const [showErrors, setShowErrors] = useState(null);
  return (
    <>
      <Mutation
        mutation={MUTATION}
        onCompleted={(data) => {
          if (data && data.adminBulkActivateSurveyFlag) {
            console.log({ data });
            toastManager.add('Bulk edits successful', {
              appearance: 'success',
            });
            setResults(data.adminBulkActivateSurveyFlag);
            setShowResults(true);
          } else {
            toastManager.add('An error occured, please try again', {
              appearance: 'error',
            });
          }
        }}
      >
        {(send, { loading }) => (
          <Container>
            <Flex m={[3, 5]} flex={1} flexDirection="column">
              <Heading mb={4} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
                Activate follow up survey flag
              </Heading>
              <Card mb={4} p={[3, 4]}>
                <Flex flexDirection={'column'} flex={1} alignItems="center">
                  <Formik
                    initialValues={{ ids: '', survey: SURVEYS.find((x) => x.value === 'FOLLOWUP2') }}
                    // validationSchema={schema}
                    onSubmit={(values, actions) => {
                      if (!values || !values.ids) {
                        setShowErrors({
                          error: 'No Ids entered',
                          details: [],
                        });
                        return;
                      }
                      const sp = values.ids.split(';');
                      const errs = [];
                      for (let index = 0; index < sp.length; index++) {
                        const element = sp[index];
                        if (element.indexOf(' ') > -1) {
                          errs.push({
                            key: "Ids can't contain spaces",
                            value: element,
                          });
                        } else {
                          try {
                            if (isNaN(element)) {
                              errs.push({
                                key: 'ID must be numerical',
                                value: element,
                              });
                            } else {
                              const num = parseInt(element);
                              if (num > 1000000) {
                                errs.push({
                                  key: 'Invalid ID',
                                  value: element,
                                });
                              }
                            }
                          } catch (error) {
                            errs.push({
                              key: 'ID must be numerical',
                              value: element,
                            });
                          }
                        }
                      }

                      if (errs.length > 0) {
                        const formatted = [];
                        for (let index = 0; index < errs.length; index++) {
                          const element = errs[index];
                          const ind = formatted.findIndex((x) => x.key === element.key);
                          if (ind > -1) {
                            formatted[ind].value.push(element.value);
                          } else {
                            formatted.push({
                              key: element.key,
                              value: [element.value],
                            });
                          }
                        }

                        setShowErrors({
                          error: 'Error with ids',
                          details: formatted,
                        });
                        return;
                      }

                      send({
                        variables: {
                          ids: values.ids,
                          survey: values.survey.value,
                        },
                      });
                    }}
                    render={({ errors, touched, isValid, isSubmitting, handleChange, handleBlur, values, setFieldValue, setFieldTouched }) => (
                      <Form style={{ width: '300px' }}>
                        <FormField mb={3} error={touched.survey && errors.survey}>
                          <Select
                            classNamePrefix="region-select"
                            options={SURVEYS}
                            name="survey"
                            value={values.survey}
                            placeholder="select survey"
                            onChange={(option) => {
                              setFieldValue('survey', option);
                            }}
                            onBlur={() => setFieldTouched('survey', true)}
                            isSearchable={true}
                          />
                        </FormField>
                        <FormField mb={4} error={touched.phone && errors.phone}>
                          <TextArea name="ids" placeholder="Athena ids separated with ;" value={values.ids} onChange={handleChange} onBlur={handleBlur} />
                        </FormField>

                        <Button mt={4} mb={4} variant="primary" width={1 / 2} disabled={(values.ids || '').length < 1 || !!loading} type="submit">
                          Send
                        </Button>
                      </Form>
                    )}
                  />
                </Flex>
              </Card>
            </Flex>
          </Container>
        )}
      </Mutation>
      {!!showResults && (
        <ConfirmModal
          isOpen={showResults}
          confirmLabel="ok"
          onClose={() => {
            setShowResults(false);
          }}
          onBackgroundClick={() => {
            setShowResults(false);
          }}
          onEscapeKeydown={() => {
            setShowResults(false);
          }}
          onConfirm={() => {
            setShowResults(false);
          }}
        >
          <ModalInner>
            <Heading fontSize="20px" mb={'20px'}>
              Bulk insert results
            </Heading>
            {!results || (results.notFound.length < 1 && results.success.length < 1 && results.alreadyFlagged.length < 1 && results.errored.length < 1) ? (
              <Text mt={3} mb={4} textAlign={'center'}>
                No Results
              </Text>
            ) : (
              <Flex flexDirection={'column'}>
                <Text>Success:</Text>
                <Text ml={3} maxWidth={'500px'} mb={3}>
                  {results.success.map((x) => x.id).join(';')}
                </Text>
                <Text>User not found:</Text>
                <Text ml={3} maxWidth={'500px'} mb={3}>
                  {results.notFound.map((x) => x.id).join(';')}
                </Text>
                <Text>Already Flagged:</Text>
                <Text ml={3} maxWidth={'500px'} mb={3}>
                  {results.alreadyFlagged.map((x) => x.id).join(';')}
                </Text>
                <Text>Errored:</Text>
                <Text ml={3} maxWidth={'500px'} mb={3}>
                  {results.errored.map((x) => x.id).join(';')}
                </Text>
              </Flex>
            )}
          </ModalInner>
        </ConfirmModal>
      )}

      {!!showErrors && (
        <ConfirmModal
          isOpen={showErrors}
          confirmLabel="ok"
          okOnly={true}
          onClose={() => {
            setShowErrors(false);
          }}
          onBackgroundClick={() => {
            setShowErrors(false);
          }}
          onEscapeKeydown={() => {
            setShowErrors(false);
          }}
          onConfirm={() => {
            setShowErrors(false);
          }}
        >
          <ModalInner>
            <Heading fontSize="20px" mb={'20px'}>
              {showErrors.error}
            </Heading>
            {!showErrors.details || showErrors.details.length < 1 ? (
              <Text mt={3} mb={4} textAlign={'center'} />
            ) : (
              <Flex flexDirection={'column'}>
                {showErrors.details.map((x) => {
                  return (
                    <Flex key={x.key} mt={3} flexDirection={'column'}>
                      <Text>{x.key}</Text>
                      <Flex flexWrap={'wrap'}>
                        {x.value.map((xx) => {
                          return (
                            <Text style={{ background: '#f0f0f0' }} mr={2} key={xx}>
                              {xx}
                            </Text>
                          );
                        })}
                      </Flex>
                    </Flex>
                  );
                })}
              </Flex>
            )}
          </ModalInner>
        </ConfirmModal>
      )}
    </>
  );
};

export default withToastManager(AdminBulkSetSurveyFlag);
