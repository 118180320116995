import React from 'react';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';
import your_Doctor from '../static/your_doctor.png';
import your_Lab from '../static/your_lab.png';
import your_Pharmacy from '../static/your_Pharmacy.png';
import your_insurance from '../static/your_insurance.png'

import withSession from '../lib/withSession';

const Wrapper = styled.div`
  padding-left: 60px;
  padding-top: 40px;
  @media screen and (max-width: 52em) {
    padding-left: 12px;
  }
`;

const Container = styled(Flex)`
  flex: 1;
  border-radius: 8px 8px 8px 8px;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
`;

const Container2 = styled(Flex)`
  flex: 1;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  padding: 12px 20px 20px;
`;
const Entry = styled(Flex)`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const EntryWrapper = styled(Flex)`
  flex: 1;
  flex-direction: column;
  margin: 6px 12px;
`;

const TitleRow = styled(Flex)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 22px;
`;

const Arrow = styled(Text)`
  font-weight: 600;
  margin-left: 8px;
  font-size: 28px;
`;

const MyCare = ({ history, session: { eligibility, intakeStatus, primaryProviderId } }) => {
  return (
    <Wrapper>
      <Container>
        {eligibility &&
          (eligibility.pharmacy || eligibility.canBookAsync) &&
          intakeStatus !== 'disqualified' && (
            <Container2
              onClick={() => {
                history.push('/pharmacy');
              }}
            >
              <Entry>
                <img style={{ height: '68px', width: '78px' }} height="68px" width="78px" src={your_Pharmacy} alt="" />
                <EntryWrapper>
                  <TitleRow>
                    <Title>Preferred Pharmacy</Title>
                  </TitleRow>
                  <Text style={{ fontWeight: 300 }}>Select your preferred pharmacy if your provider writes you a prescription after your visit</Text>
                </EntryWrapper>
                <Arrow>{'>'}</Arrow>
              </Entry>
            </Container2>
          )}

        {eligibility &&
          eligibility.labCenter &&
          intakeStatus !== 'disqualified' && (
            <Container2
              onClick={() => {
                history.push('/lab-center');
              }}
            >
              <Entry>
                <img style={{ height: '68px', width: '78px' }} height="68px" width="78px" src={your_Lab} alt="" />
                <EntryWrapper>
                  <TitleRow>
                    <Title>Preferred Quest Lab</Title>
                  </TitleRow>
                  <Text style={{ fontWeight: 300 }}>Select your preferred quest lab if your provider decides to order additional labs</Text>
                </EntryWrapper>
                <Arrow>{'>'}</Arrow>
              </Entry>
            </Container2>
          )}

        {eligibility &&
          eligibility.practitioner &&
          intakeStatus !== 'disqualified' && (
            <Container2
              onClick={() => {
                history.push('/practitioner');
              }}
            >
              <Entry>
                <img style={{ height: '68px', width: '78px' }} height="68px" width="78px" src={your_Doctor} alt="" />
                <EntryWrapper>
                  <TitleRow>
                    <Title>{primaryProviderId ? 'Your clinician' : 'Our clinicians'}</Title>
                  </TitleRow>
                  <Text style={{ fontWeight: 300 }}>Learn more about your clinician.</Text>
                </EntryWrapper>
                <Arrow>{'>'}</Arrow>
              </Entry>
            </Container2>
          )}
        <Container2
          onClick={() => {
            history.push('/my-insurances');
          }}
        >
          <Entry>
            <img style={{ height: '68px', width: '78px' }} height="68px" width="78px" src={your_insurance} alt="" />
            <EntryWrapper>
              <TitleRow>
                <Title>Your insurance</Title>
              </TitleRow>
              <Text style={{ fontWeight: 300 }}>Review or add your insurance to see if some of our providers are in network with your plan</Text>
            </EntryWrapper>
            <Arrow>{'>'}</Arrow>
          </Entry>
        </Container2>
      </Container>
    </Wrapper>
  );
};

export default withSession(MyCare);
