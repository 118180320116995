import React, { Fragment, Component } from 'react';
import { Button, Flex, Box, Text } from 'rebass';

import { Formik, Form } from 'formik';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import * as card from 'card-validator';

import { FormField } from '../../../components/FormField';
import { TextInput } from '../../../components/TextInput';

import { ATHENA_CARD, UPDATE_ATHENA_CARD } from '../../../graphql';
import withAnalytics from '../../../lib/withAnalytics';
import CheckboxInput from '../../../components/CheckboxInput';
import withSession from '../../../lib/withSession';
import PlacesAutocomplete from '../../../components/addressAutoComplete/PlacesAutocomplete';
import InformationSecuredDisclaimer from '../../../components/InformationSecuredDisclaimer';
import { MaskInput } from '../../../components/MaskInput';

class ConsultsContent extends Component {
  constructor(props) {
    super(props);
    this.state= {
      billingSameAsShiping: true
    }
  }

  updatePayment = async (values, actions) => {
    const { client, toastManager, onSubmit } = this.props;

    try {
      const expiration = values.expirationdate.split('/');
      if (expiration.length !== 2) {
        toastManager.add('Invalid Expiration Date.', {
          appearance: 'error',
        });
        actions.setSubmitting(false);
        return;
      }
      if (expiration[1].toString().length < 2) {
        toastManager.add('Invalid Expiration Date.', {
          appearance: 'error',
        });
        actions.setSubmitting(false);
        return;
      }
      if (
        expiration[0].toString().length < 1 ||
        expiration[0].toString().length > 2
      ) {
        toastManager.add('Invalid Expiration Date.', {
          appearance: 'error',
        });
        actions.setSubmitting(false);
        return;
      }

      const res = await client.mutate({
        mutation: UPDATE_ATHENA_CARD,
        variables: {
          accountNumber: values.cardnumber,
          cvc: values.cvc2,
          expMonth:
            expiration[0].length < 2 ? `0${expiration[0]}` : expiration[0],
          expYear: expiration[1].toString().substr(expiration[1].length - 2),
          zip: values.zip || null,
          street1: values.street1 || null,
        },
        refetchQueries: [{ query: ATHENA_CARD }],
      });

      if (
        res &&
        ((res.data &&
          res.data.updateAthenaCard &&
          res.data.updateAthenaCard.ok) ||
          res.ok)
      ) {
        this.props.analytics.track('Card Updated');
        onSubmit()
      } else {
        toastManager.add('Invalid Credit Card.', {
          appearance: 'error',
        });
        actions.setSubmitting(false);
      }
    } catch (error) {
      console.log(error);
      actions.setSubmitting(false);
    }
  };

  render() {
    const {
      selectedAppointment,
      isAdmin,
      enforcePatientId,
      isNewPatientFlow,
      duration,
    } = this.props;

    return (
      <Fragment>
        <Flex flexDirection={['column', 'column', 'row']} px={3} mt={4}>
          {!isAdmin && (
            <Box mr={[0, 0, 4]} mb={4}>
                <Formik
                  initialValues={{
                    nameoncard: '',
                    cardnumber: '',
                    cvc2: '',
                    expirationdate: '',
                    zip: '',
                    billingSameAsShiping: true,
                  }}
                  // validationSchema={
                  //   this.state.billingSameAsShiping
                  //     ? schema
                  //     : schemaWithBilling
                  // }
                  validate={(values, props) => {
                    // console.log('validate', { values, props });
                    let errors = {};
                    if (!values.cardnumber || values.cardnumber === '') {
                      errors.cardnumber = 'Required';
                    } else {
                      if (!card.number(values.cardnumber).isValid) {
                        errors.cardnumber = 'Credit Card number is invalid';
                      }
                    }
                    if (!values.cvc2 || values.cvc2 === '') {
                      errors.cvc2 = 'Required';
                    } else {
                      if (
                        card.number(values.cvc2) &&
                        card.number(values.cvc2).card &&
                        card.number(values.cvc2).card.code &&
                        card.number(values.cvc2).card.code.size
                      ) {
                        if (
                          !card.cvv(
                            values.cvc2,
                            card.number(values.cardnumber).card.code.size
                          )
                        ) {
                          errors.cardnumber = 'CVC is invalid';
                        }
                      } else {
                        if (!card.cvv(values.cvc2, 3)) {
                          errors.cardnumber = 'CVC is invalid';
                        }
                      }
                    }

                    if (
                      !values.expirationdate ||
                      values.expirationdate === ''
                    ) {
                      errors.expirationdate = 'Required';
                    } else {
                      if (!card.expirationDate(values.expirationdate).isValid) {
                        errors.expirationdate = 'Expiration date is invalid';
                      }

                      if (
                        values.expirationdate.length < 3 ||
                        values.expirationdate.indexOf('/') < 1
                      ) {
                        errors.expirationdate = 'Expiration date is invalid';
                      }
                    }

                    if (!this.state.billingSameAsShiping || isNewPatientFlow) {
                      if (
                        !values.originalAddress ||
                        values.originalAddress === ''
                      ) {
                        errors.street1 = 'Required';
                      }
                      if (!values.street1 || values.street1 === '') {
                        errors.street1 = 'Required';
                      }
                    }

                    return errors;
                  }}
                  onSubmit={async (values, actions) => {
                    const aa = document.getElementsByClassName('pac-container');
                    // const bb = document.getElementsById('pac-container')
                    // console.log(aa);
                    if (aa) {
                      for (let index = 0; index < aa.length; index++) {
                        const element = aa[index];
                        // console.log(element.style);
                        if (element.style && element.style.display !== 'none') {
                          actions.setSubmitting(false);
                          return;
                        }
                      }
                    }
                    this.updatePayment(values, actions);
                  }}
                  render={({
                    values,
                    errors,
                    touched,
                    isValid,
                    isSubmitting,
                    handleChange,
                    validateForm,
                    handleBlur
                  }) => {
                    // console.log(values)
                    return(
                    <Form>
                      <Flex flexDirection="column">
                        <FormField
                          error={touched.cardnumber && errors.cardnumber}
                          mb={3}
                        >
                          <label for="cardnumber">Credit Card Number</label>
                          <TextInput name="cardnumber" />
                        </FormField>

                        <Flex
                          flexDirection={['column', 'column', 'row']}
                          mb={4}
                        >
                          <FormField
                            width={[1, 1, 1 / 3]}
                            mr={[0, 0, 3]}
                            error={
                              touched.expirationdate && errors.expirationdate
                            }
                          >
                            <label for="expirationdate">Expiration Date</label>
                            <MaskInput
                                name="expirationdate"
                                mask="99/99"
                                value={values.expirationdate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id='expirationdate'
                              />
                            {/* <TextInput
                              name="expirationdate"
                              placeholder={`e.g.: ${moment().month() +
                                1}/${moment()
                                .year()
                                .toString()
                                .substr(moment().year().length - 2)}`}
                            /> */}
                          </FormField>

                          <FormField
                            width={[1, 1, 1 / 3]}
                            mr={[0, 0, 3]}
                            error={touched.cvc2 && errors.cvc2}
                          >
                            <label for="cvc2">CVC</label>
                            <TextInput name="cvc2" />
                          </FormField>
                        </Flex>
                      </Flex>

                      <label for="billingSameAsShiping">Billing Address</label>
                      <FormField flexDirection="row" mb={4}>
                        <CheckboxInput
                          name="billingSameAsShiping"
                          onChanged={option => {
                            this.setState({
                              billingSameAsShiping: !option,
                            });
                          }}
                          styles={{
                            checked: {
                              Box: {
                                backgroundColor: 'rgb(54, 79, 121)',
                              },
                            },
                          }}
                        />
                        <Text fontSize={1}>Same as shipping address</Text>
                      </FormField>
                      {!this.state.billingSameAsShiping && (
                        <FormField
                          mb={3}
                          error={
                            touched.address &&
                            (errors.street1 ||
                              errors.city ||
                              errors.zip ||
                              errors.state)
                              ? errors.street1 ||
                                errors.city ||
                                errors.zip ||
                                errors.state ||
                                ''
                              : null
                          }
                        >
                          <PlacesAutocomplete
                            fieldname="address"
                            handleChange={handleChange}
                            street1FieldName="street1"
                            cityFieldName="city"
                            zipFieldName="zip"
                            stateFieldName="state"
                          />
                        </FormField>
                      )}

                      <Flex alignItems="center" flexDirection="column">
                        <Button
                          disabled={!isValid || isSubmitting}
                          variant="pink"
                          width={[1, 1 / 2]}
                          type="submit"
                        >
                          Update Card
                        </Button>
                      </Flex>

                      <InformationSecuredDisclaimer />
                    </Form>
                  )}}
                />
            </Box>
          )}
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withToastManager,
  withAnalytics,
  withSession
)(ConsultsContent);
