import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { StripeProvider } from 'react-stripe-elements';
import { BrowserRouter as Router } from 'react-router-dom';
// import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import StripeScriptLoader from 'react-stripe-script-loader';
import client from './client';
import App from './AppV2';
import * as serviceWorker from './serviceWorker';
import 'animate.css/animate.min.css';
import PleaseWait from './components/PleaseWait';
import { Flex } from 'rebass';

ReactDOM.render(
  <ApolloProvider client={client}>
  {/* <ApolloHooksProvider client={client}> */}
      <StripeScriptLoader uniqueId="stripeJSScript" script="https://js.stripe.com/v3/" loader={
        <Flex style={{height: '100vh', width: '100vw', justifyContent: 'center', alignItems: 'center'}}>
          <PleaseWait />
        </Flex>
      }>
        <StripeProvider apiKey={window._env_.REACT_APP_STRIPE_KEY}>
          <Router>
            <App />
          </Router>
        </StripeProvider>
      </StripeScriptLoader>
   {/* </ApolloHooksProvider> */}
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
