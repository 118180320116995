import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Card, Flex, Text } from 'rebass';
import { CarouselCard } from '../YearlyReview';
import { PlateHeart } from '../icons/PlateHeart';
import { MarkerEvolution, MarkerEvolutionBackground } from '../pages/MarkerEvolution';
import { TrackedSymptomsCount, TrackedSymptomsCountBackground } from '../pages/TrackedSymptomsCount';
import { AverageSymptoms, AverageSymptomsBackground } from '../pages/AverageSymptoms';
import { ImpactingSymptoms, ImpactingSymptomsBackground } from '../pages/ImpactingSymptoms';
import { ImprovedSymptoms, ImprovedSymptomsBackground } from '../pages/ImprovedSymptoms';
import { TestCount, TestCountBackground } from '../pages/TestCount';
import { hormoneName } from '../../LabDetail';
import moment from 'moment';
import PleaseWait from '../../../components/PleaseWait';
import { DoctorsVisitsCount, DoctorsVisitsCountBackground } from '../pages/DoctorsVisitsCount';
import { TimeSaved, TimeSavedBackground } from '../pages/TimeSaved';
import { TimeWithDoctor, TimeWithDoctorBackground } from '../pages/TimeWithDoctor';

const GET_YEARLY_CARE = gql`
  query getMemberYearlyReviewCare($from: String, $to: String) {
    getMemberYearlyReviewCare(from: $from, to: $to) {
      id
      trackedSymptomsCount
      minutesTravelSaved
      minutesWaitingSaved
      minutesSpentAppointment
      avgTracking {
        date
        average
      }
      impacted {
        marker
        variation
      }
      improved {
        marker
        variation
      }
      testCount
      testsLevels {
        tpo {
          id
          date
          value
          isPaloma
          isSelfEntered
        }
        tsh {
          id
          date
          value
          isPaloma
          isSelfEntered
        }
        ft3 {
          id
          date
          value
          isPaloma
          isSelfEntered
        }
        ft4 {
          id
          date
          value
          isPaloma
          isSelfEntered
        }
        rT3 {
          id
          date
          value
          isPaloma
          isSelfEntered
        }
        vitD {
          id
          date
          value
          isPaloma
          isSelfEntered
        }
      }
      appointments
      uniqueProviders {
        id
        picture
      }
    }
  }
`;

const SECTION_BACKGROUND = '#E9F3FF';
const SECTION_NAME = 'Your time matters';

const getStories = (data) => {
  return [
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#E9F3FF',
      duration: 5000,
      content: <TimeSaved minutesTravelSaved={data.minutesTravelSaved} minutesWaiting={data.minutesWaitingSaved} />,
      background: <TimeSavedBackground />,
      show: true,
      analytics: {
        section: 'Time matters',
        screen: 'TimeSaved',
        metadata: {
          minutesTravelSaved: data.minutesTravelSaved || 0,
          minutesWaiting: data.minutesWaitingSaved || 0,
        },
      },
    },
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#E9F3FF',
      duration: 5000,
      content: <TimeWithDoctor minutesSpentAppointment={data.minutesSpentAppointment} />,
      background: <TimeWithDoctorBackground />,
      show: true,
      analytics: {
        section: 'Time matters',
        screen: 'TimeWithDoctor',
        metadata: {
          minutesSpentAppointment: data.minutesSpentAppointment || 0,
        },
      },
    },
  ];
};

const CareSectionInner = ({ isActive, setActive, data, active, onAllStoriesEnd, onClose }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [stories, setStories] = useState();
  useEffect(
    () => {
      if (!!isLoaded) return;
      setStories(getStories(data).filter((x) => !!x.show));
      setIsLoaded(true);
    },
    [data, isLoaded]
  );

  if (!isLoaded) return <div />;

  return (
    <CarouselCard
      onClose={onClose}
      stories={stories}
      sectionName={SECTION_NAME}
      section={{ inactiveIcon: <PlateHeart /> }}
      isActive={isActive}
      backgroundColor={SECTION_BACKGROUND}
      onAllStoriesEnd={onAllStoriesEnd}
    />
  );
};

export const TimeMattersSection = ({ isActive, setActive, active, onAllStoriesEnd, onClose }) => {
  if (!isActive)
    return (
      <Card style={{ height: '100vh', width: '100%', backgroundColor: '#c4c8d3', borderRadius: '30px', padding: '18px' }}>
        <Flex
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <PlateHeart />
          <Text style={{ fontSize: '32px', color: 'white' }}>{SECTION_NAME}</Text>
        </Flex>
      </Card>
    );
  return (
    // <Flex style={{ flex: 1, width: '100%', height: '100%', background: 'red' }}>
    <Query query={GET_YEARLY_CARE}>
      {({ loading, data, error }) => {
        if (loading)
          return (
            <Card style={{ height: '100vh', width: '100%', backgroundColor: SECTION_BACKGROUND, borderRadius: '30px', padding: '18px' }}>
              <Flex
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  height: '100%',
                  flexDirection: 'column',
                }}
              >
                <PleaseWait
                  textStyle={{
                    color: '#344F7A',
                  }}
                  spinner={{
                    pColor: '#c4c8d3',
                    sColor: '#344F7A',
                  }}
                  text="Loading your time information"
                />
              </Flex>
            </Card>
          );

        return <CareSectionInner onClose={onClose} isActive={isActive} active={active} setActive={setActive} data={(data || {}).getMemberYearlyReviewCare} onAllStoriesEnd={onAllStoriesEnd} />;
      }}
    </Query>
    // </Flex>
  );
};
