import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading } from 'rebass';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { TextInput } from '../../../components/TextInput';
import { FormField } from '../../../components/FormField';
import RegionSelect from '../../../components/RegionSelect';

const eligibility = value => {
  
  return true
};

const schema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('Required'),
  lastName: Yup.string()
    .trim()
    .required('Required'),
  street1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string()
    .required('Required')
    .test(
      'state',
      'Kits are currently not available in this state',
      eligibility
    ),
  zip: Yup.string().required('Required'),
});

const Address = props => {
  return (
    <Fragment>
      <Flex flex={1}>
        <Card p={4}>
          <Flex flexDirection="column">
            <Heading textAlign="center" fontWeight={550} mb={4}>
              Your Shipping Address
            </Heading>

            <Formik
              isInitialValid={({ initialValues }) =>
                 schema.isValidSync(initialValues)
              }
              initialValues={props.values}
              validationSchema={schema}
              onSubmit={values =>
                props.onSubmit(values)
              }
              render={({ isValid, errors, touched, values }) => {
                let errStreet1
                if(touched.street1 && errors.street1){
                  errStreet1=errors.street1
                }else if(values && values.street1 && values.street1.indexOf('@')>-1){
                  errStreet1='incorrect street address'
                }else{
                  errStreet1=null
                }
                return (
                <Form>
                  <Flex flexDirection={['column', 'column', 'row']}>
                    <FormField
                      width={[1, 1, 1 / 2]}
                      mb={3}
                      mr={[0, 0, 3]}
                      error={touched.firstName && errors.firstName}
                    >
                      <TextInput
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                      />
                    </FormField>
                    <FormField
                      width={[1, 1, 1 / 2]}
                      mb={3}
                      error={touched.lastName && errors.lastName}
                    >
                      <TextInput
                        placeholder="Last Name"
                        type="text"
                        name="lastName"
                      />
                    </FormField>
                  </Flex>

                  <FormField mb={3} error={errStreet1}>
                    <TextInput
                      placeholder="Address"
                      type="text"
                      name="street1"
                    />
                  </FormField>

                  <FormField mb={3} error={touched.street2 && errors.street2}>
                    <TextInput
                      placeholder="Apartment, Suite, Floor (Optional)"
                      type="text"
                      name="street2"
                    />
                  </FormField>

                  <Flex mb={4} flexDirection={['column', 'column', 'row']}>
                    <FormField
                      width={[1, 1, 1 / 3]}
                      mb={3}
                      mr={[0, 0, 3]}
                      error={touched.city && errors.city}
                    >
                      <TextInput placeholder="City" type="text" name="city" />
                    </FormField>

                    <FormField
                      width={[1, 1, 1 / 3]}
                      mb={3}
                      mr={[0, 0, 3]}
                      error={touched.state && errors.state}
                    >
                      <RegionSelect name="state" value={values.state} />
                    </FormField>

                    <FormField
                      width={[1, 1, 1 / 3]}
                      mb={3}
                      error={touched.zip && errors.zip}
                    >
                      <TextInput placeholder="Zip" type="text" name="zip" />
                    </FormField>
                  </Flex>

                  <Flex justifyContent="center">
                    <Button
                      disabled={!isValid || !!props.isSubmitting || !values || !values.street1 || values.street1.indexOf('@')>-1}
                      variant="pink"
                      width={[1, 1 / 2, 1 / 3]}
                      type="submit"
                    >
                      Confirm
                    </Button>
                  </Flex>
                </Form>
              )}}
            />
          </Flex>
        </Card>
      </Flex>
    </Fragment>
  );
};

export default Address;
