import { aetna } from './aetna';
import { blueCross } from './bc';
import { blueShield } from './bs';
import { blueShieldCa } from './bsCA';
import { blueCrossBlueShield } from './bcbs';
import { cigna } from './cigna';
import { unitedHealthcare } from './unitedHealthcare';
import { anthem } from './anthem';

export const formalizeInsuranceName = (insuranceCompany) => {
  // console.log('formalizeInsuranceName',insuranceCompany)
  let insname = insuranceCompany.toLowerCase();
  if (insname.indexOf('bcbs') > -1 || insname.indexOf('blue cross blue shield') > -1) {
    insname = 'bcbs';
  }
  if (insname.indexOf('healthpartners') > -1) {
    insname = 'cigna';
  }
  switch (insname) {
    case 'aetna':
      return 'aetna';
    case 'blue cross':
    case 'bluecross':
      return 'blue cross';
    case 'blue shield':
    case 'blueshield':
      return 'blue shield';
    case 'blue shield of california':
    case 'blue shield ca':
    case 'blue shield of ca':
      return 'blue shield ca';
    case 'blue cross blue shield':
    case 'bcbs':
      return 'blue cross blue shield';
    case 'cigna':
      return 'cigna';
    case 'united healthcare':
    case 'unitedhealthcare':
    case 'united healthone':
    case 'united health one':
    case 'unitedhealthone':
      return 'united healthcare';
    case 'anthem':
      return 'anthem';
    case 'multiplan':
      return 'multiplan';
    case 'humana':
      return 'humana';
      case 'priority health':
      case 'priorityhealth':
        return 'priority health';

    default:
      return '';
  }
};

export const getPayerIds = (insuranceCompany, state) => {
  const formalizedInsurance = formalizeInsuranceName(insuranceCompany);

  let list = [];
  const res = [];
  switch (formalizedInsurance) {
    case 'aetna':
      list = aetna;
      break;
    case 'blue cross':
      list = blueCross;
      break;
    case 'blue shield ca':
      list = blueShieldCa;
      break;
    case 'blue shield':
      list = blueShield;
      break;
    case 'blue cross blue shield':
      list = blueCrossBlueShield;
      break;
    case 'cigna':
      list = cigna;
      break;
    case 'united healthcare':
      list = unitedHealthcare;
      break;
    case 'anthem':
      list = anthem;
      break;
    default:
      list = [...aetna, ...blueCross, ...blueShieldCa, ...blueShield, ...blueCrossBlueShield, ...cigna, ...unitedHealthcare];
      break;
  }

  for (let index = 0; index < list.length; index++) {
    const element = list[index];
    if (element.isNotSupported) continue;
    if (element.stateOnly) {
      if (element.stateOnly === state) {
        res.push(element);
      }
    } else {
      res.push(element);
    }
  }

  return [...new Set(res.map((x) => x.payerId))];
};

export const getPayerIdsString = (insuranceCompany, state) => {
  const payerIds = getPayerIds((insuranceCompany || '').replace('-', ''), state);
  return payerIds.join(', ');
};

export const getFormalizedInsuranceNameString = (insuranceCompany) => {
  const formalizedInsurance = formalizeInsuranceName((insuranceCompany || '').replace('-', ''));
  return formalizedInsurance;
};
