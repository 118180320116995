import React from 'react';
import IconContainer from './IconContainer';

const CrossSearch = props => (
  <IconContainer {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501.27 502.74">
      <path
        d="M198.57,60.54A60.53,60.53,0,0,1,259.11,0h0a60.53,60.53,0,0,1,60.54,60.54h0V423.78a60.53,60.53,0,0,1-60.54,60.54h0a60.53,60.53,0,0,1-60.54-60.54h0"
        fill="#f9a394"
      />
      <path
        d="M77.49,302.7A60.53,60.53,0,0,1,17,242.16h0a60.53,60.53,0,0,1,60.54-60.54H440.73a60.53,60.53,0,0,1,60.54,60.54h0a60.53,60.53,0,0,1-60.54,60.54h0"
        fill="#f9a394"
      />
      <path
        d="M247.11,231.83l-36.67-17.27c-9.69-4.57-19.53-9.18-29.9-11.06-12.25-2.22-24.64-.51-36.24,2.78a129.12,129.12,0,0,0-88.15,88.13c-6.27,22.09-6.53,47.15,4.22,68.72,7.42,14.89,19.51,27.13,31.91,38.42,9.73,8.87,19.9,17.44,31.41,23.64,26.35,14.19,58.08,14.49,84.52,4.31s47.84-30,63.5-53.33c8-11.93,14.72-25,18-39.22,9.33-40.52-13.31-88.34-51.65-109.07"
        fill="#e2e3ed"
      />
      <path
        d="M231.17,250.25l-29-13.66c-7.66-3.61-15.44-7.26-23.65-8.75-9.68-1.76-19.48-.4-28.65,2.2a102.1,102.1,0,0,0-69.71,69.69c-5,17.46-5.17,37.28,3.33,54.34,5.87,11.78,15.43,21.45,25.23,30.38,7.69,7,15.73,13.79,24.84,18.7,20.84,11.22,45.93,11.46,66.83,3.41s37.83-23.74,50.22-42.17c6.34-9.43,11.64-19.75,14.24-31,7.38-32-10.53-69.86-40.84-86.25"
        fill="#fff"
      />
      <path
        d="M224,247.13l7.16,3.12-29-13.66c-7.66-3.61-15.44-7.26-23.65-8.75-9.68-1.76-19.48-.4-28.65,2.2a102.1,102.1,0,0,0-69.71,69.69A86.84,86.84,0,0,0,76.84,328H175.5v84a84.09,84.09,0,0,0,24.9-5.42c20.9-8,37.83-23.74,50.22-42.17,6.34-9.43,11.64-19.75,14.23-31C272.23,301.33,254.33,263.52,224,247.13Z"
        fill="#f9a394"
      />
      <path
        d="M263.24,301.21l.15,8.42c-3.61-10.05-5.68-21.65-9.3-31.71-2.87-8-7.33-14.51-12.08-21.36-1-1.42-9.69-2.78-10.81-4.07C201,285.77,152.51,346.88,112,386.64a83.8,83.8,0,0,0,19.07,11.77c20.47,9.09,42.38,12.67,64.17,8.39,11.16-2.19,26.06-5.73,35.86-11.87C259,377.5,273.08,334.24,263.24,301.21Z"
        fill="#c2c2ff"
        opacity="0.37"
      />
      <path
        d="M71.14,393.6,12,462.09c-3.56,4.12-7.3,8.68-7.59,14.11-.22,4.25,1.78,8.38,4.6,11.58s6.39,5.61,9.93,8c1.89,1.27,3.93,2.59,6.2,2.62,3.44.05,6.17-2.76,8.44-5.34,22.71-25.82,42.92-50.87,67.51-74.91"
        fill="#e2e3ed"
      />
      <path
        d="M33.48,454.35c4.05-5.32,9.42-9.77,14.21-14.41,5.25-5.09,10.48-10.18,15.62-15.37l-11-4.56a26.45,26.45,0,0,0,1.77,12l3-7.34a76.35,76.35,0,0,0-12.14,11c-4.2,4.52-8.49,9-12.61,13.56-2.55,2.84-4.28,6.51-2.25,10.24,1.31,2.41,3.7,4.36,5.61,6.29C40,470.17,45.5,473.87,52,472a6.58,6.58,0,0,0,3.89-9.55c-1.16-2.15-3.78-6.27-2.59-8.7L43,455.11c.54.49.42.18,0-.09s-.21-.68,0,.31c.14.77.27,1.55.41,2.32a11.59,11.59,0,0,0,2.83,6c4.85,5.08,12.12,2.21,16.16-2.29,3.47-3.87,5.43-8.61,8.18-13A47.72,47.72,0,0,1,81,437c2.2-1.79,2.36-5.58,1-7.88a6.72,6.72,0,0,0-7.34-3,54.92,54.92,0,0,0-13.76,5.05l9.55,7.34a6.68,6.68,0,0,1,1.49-2.91l-10.21-7.88a158,158,0,0,0-14,22.71l11.88,5a16.41,16.41,0,0,0,.93-6.59,6.65,6.65,0,0,0-6.5-6.5c-3.35,0-6.72,3-6.5,6.5A6.58,6.58,0,0,1,47,452c-2.51,6.9,8.57,11.57,11.88,5a158,158,0,0,1,14-22.71c1.9-2.57.68-6.63-1.67-8.39s-6.33-1.94-8.54.51a20.83,20.83,0,0,0-4.83,8.65c-1.55,5.67,4.61,9.91,9.55,7.34a42,42,0,0,1,10.66-3.74l-6.33-10.87c-7.12,5.78-11.8,13-16.27,20.84a18.84,18.84,0,0,1-2.87,4.11c-1,1-1.43.65.19.64q3,.31,3.57,2.64a6.77,6.77,0,0,1-.22-.77c-.62-3.45-1.18-6.89-3.92-9.39s-8.28-2.62-10.21,1.32c-3.69,7.53-1.17,14.88,2.59,21.83l3.89-9.55s1.66,1.08-.48-.18a11.74,11.74,0,0,1-2.16-1.75c-.95-.91-1.85-1.87-2.76-2.81l-1.43-1.46c-.84-.85-1-1-.35-.37l.89,3.28v-.71l-.89,3.28c.83-1.58,3.64-4,5.23-5.76l6.13-6.59c3.43-3.69,6.8-7.65,11-10.54,2.14-1.49,4.12-4.59,3-7.34A17.75,17.75,0,0,1,65.2,420c.42-5.65-7.29-8.39-11-4.56-5.46,5.64-10.79,11.39-16.13,17.13C33,438,26.72,443.32,23.94,450.33c-1,2.38.84,5.28,3,6.16,2.61,1,4.9,0,6.52-2.14Z"
        fill="#fff8cc"
      />
      <path
        d="M64.39,415.41c2.29-1.93,5.19-3.42,7.68-5.13s5.15-3.47,7.62-5.35l-8.4-8.4L62.2,408.66c-1.76,2.36-3.39,5-2.54,8.1a7.5,7.5,0,0,0,7.15,5.59c4.27.16,7.79-1.82,11-4.45,1.25-1,2.43-2.15,3.63-3.23A8.21,8.21,0,0,1,84,413l-6.33-10.87a148.43,148.43,0,0,0-12.83,17c-2.29,3.56,0,8.47,3.89,9.55,10.83,3,17.3-8.8,25.93-12.88l-7.88-10.21A114.64,114.64,0,0,0,77.07,420a6.65,6.65,0,0,0,1,7.88C85.24,435,94,424.46,99.29,420l-9.2-9.2-14.63,14c-6.08,5.78,3.13,15,9.2,9.2l14.65-14a6.6,6.6,0,0,0,0-9.2c-2.65-2.65-6.51-2.28-9.2,0l-4.78,4.06c-.8.68-1.61,1.34-2.39,2-1.68,1.49-.46-.1,1.88.54l2.87,1.67-.4-.4,1,7.88A92.67,92.67,0,0,1,96,414.85c2-2.56,2.69-5.8.51-8.54s-5.37-3.1-8.39-1.67a44.49,44.49,0,0,0-9.92,6.84c-1.37,1.18-2.64,2.51-4.07,3.61a14.19,14.19,0,0,1-1.93,1.11l3.89,9.55a125.27,125.27,0,0,1,10.8-14.41c4-4.61.27-12.93-6.33-10.87-3.7,1.15-6.54,3.37-9.37,5.94-.6.54-1.19,1.1-1.81,1.64-.91.79.26-.06-.8.65-.17.11-1.73.93-.72.53a7.39,7.39,0,0,1-1.22.35c.59-.1.56-.15-.07-.15l3.26.88-.44-.2,2.31,2.31-.28-.61.65,5a23.56,23.56,0,0,1,1.76-2.55l2.79-4,5.27-7.51c1.74-2.47.69-6.22-1.58-7.92-2.61-2-5.61-1.5-8.06.48a50.36,50.36,0,0,0-7.27,7.4c-2.22,2.74-3.89,5.76-6,8.57-2.48,3.33,2.62,6.48,5.39,4.16Z"
        fill="#b1b0cc"
      />
      <path
        d="M248.27,229.84c-6.82-3.78-13.46-7.86-20.41-11.4-6.55-3.33-13.08-6.73-19.72-9.87-13-6.13-26.33-11-40.88-10.67-24.15.6-48.34,11.08-67.43,25.47a134.94,134.94,0,0,0-42.12,52.07c-10.55,23.45-15,50.76-7.8,75.84,7.54,26.34,28.36,46.32,48.83,63.33,10.93,9.08,22.72,16.8,36.16,21.61a105.26,105.26,0,0,0,38.26,5.88c26-.76,50.19-10.8,70.41-26.81A155.83,155.83,0,0,0,290,354.07c8.93-21.54,9.44-45.2,2.31-67.34-6.86-21.3-20-41-38.17-54.24a92,92,0,0,0-13.82-8.43c-5.08-2.47-9.39,4.64-4.47,7.65,33.44,20.42,57,62,49,101.61-4.73,23.55-19.3,45.26-35.92,62.21-16.2,16.53-36.45,29-59.31,33.58-23.63,4.73-48.59.67-69-12.19-11-6.93-20.79-15.75-30.19-24.7-9-8.54-17.46-17.73-23.54-28.6C54.79,342.05,55,315.86,62,292.72a123.53,123.53,0,0,1,32.47-52.55A121.65,121.65,0,0,1,149.4,209.7c13.19-3.27,26.48-3.57,39.49.64s25.26,10.85,37.87,16.1c6.51,2.71,13.15,5,19.71,7.61,2.45,1,4-3,1.78-4.21Z"
        fill="#424575"
      />
      <path
        d="M241,257.39a90.6,90.6,0,0,0-22.86-18.67,148.35,148.35,0,0,0-23.93-11,82.29,82.29,0,0,0-17.77-4.83c-11.81-1.51-23.94.94-35,5-38,14-66.82,49.59-69.89,90.34a74.2,74.2,0,0,0,5.07,33.83c4.47,10.79,11.65,20,19.8,28.28,16.46,16.7,35.34,32.16,59.12,36.08a91.55,91.55,0,0,0,63.88-13.27c18.28-11.59,33.46-29,43.26-48.23,4.91-9.65,8.35-20,9.09-30.85a77.85,77.85,0,0,0-3.51-27.95c-5.66-18.4-17-35.93-32.8-47.21a75.14,75.14,0,0,0-11.7-7.06c-5.1-2.41-9.37,4.57-4.47,7.65,27.8,17.45,48.88,53.5,39.15,86.57-5.64,19.16-18.91,36.77-34,49.64-14.79,12.64-33.39,20.55-53,20.85A73.09,73.09,0,0,1,141.77,401c-9.8-4.19-18.28-10.81-26.19-17.83-7.73-6.85-15.56-14-21.6-22.45a61.44,61.44,0,0,1-11.33-28.54c-4.54-37.07,18.74-74,51.11-90.77,15.2-7.88,33-12.38,49.76-7.54a174.75,174.75,0,0,1,42.93,19.27,100.56,100.56,0,0,1,11.3,7.55,2.31,2.31,0,0,0,3.26-3.26Z"
        fill="#424575"
      />
      <path
        d="M70.46,392.43c-14.6,11.19-26.32,26-38.32,39.83l-18.24,21C8.86,459,2.12,465.14.41,472.93s2.16,15.42,8,20.59C13.72,498.23,21.74,505,29.3,502c3.52-1.39,6-4.22,8.49-7q4.47-5.08,8.9-10.22c5.7-6.6,11.37-13.22,17.08-19.81,13-15,26.28-29.79,40.48-43.7,4.13-4-2.21-10.37-6.34-6.34-22.8,22.32-43.13,46.92-64.07,70.94-2.11,2.43-4.39,6-7.31,7.56-2.55,1.33-5.63-1.75-7.61-3.12C14.19,487.1,8,482.19,8.85,475.69c.38-2.9,2.24-5.59,4-7.84,2.49-3.2,5.27-6.21,7.91-9.29l16.34-19c12.32-14.35,26-28.6,35.23-45.24.6-1.09-.71-2.73-1.86-1.86Z"
        fill="#424575"
      />
      <path
        d="M54.56,410.74c-1.8,2.81.52,6.5,2.14,8.86a36.62,36.62,0,0,0,7.72,8,75.63,75.63,0,0,0,9.08,6.08c3.28,1.9,7.07,4.61,11,4.68a3.44,3.44,0,0,0,2.41-5.81c-2.49-2.51-6.06-4.35-9-6.4S72,422,69.14,419.74s-5.38-4.47-8-6.85c-1.24-1.15-5-4.72-6.64-2.15Z"
        fill="#424575"
      />
      <path
        d="M21,448.76c-1,3.53,1,7,3,9.86A94.77,94.77,0,0,0,31.7,468a25.2,25.2,0,0,0,9.79,6.93c3.48,1.26,9,1.82,11.09-1.9a3.1,3.1,0,0,0-1.08-4.13c-1.23-.86-2.49-.81-3.91-1.12a18.87,18.87,0,0,1-4-1.36,34.74,34.74,0,0,1-7.77-5.15A89,89,0,0,1,29.35,455c-2.05-2.17-3.38-4.8-5.31-7a1.85,1.85,0,0,0-2.63,0,1.93,1.93,0,0,0-.45.77Z"
        fill="#424575"
      />
    </svg>
  </IconContainer>
);

export default CrossSearch;
