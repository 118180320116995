import React from 'react';
import { Flex, Text } from 'rebass';
import { Input } from '../../../../components/TextInput';

export const TextInputCtrl = ({ properties }) => {
  return (
    <>
      <Flex flexDirection={'column'} flex={1 / 4}>
        <Text onClick={() => { }} fontWeight={500}>
          {`${(properties || {}).question || 'Question'}${((properties || {}).ctrlProps || {}).required ? '*' : ''}`}
        </Text>
        <Text fontSize={'12px'} fontWeight={300}>
          {(properties || {}).description || 'Description'}
        </Text>
      </Flex>
      <Input {...(properties || {}).ctrlProps} style={{ height: 'fit-content' }} />
    </>
  );
};
