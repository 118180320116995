import React from 'react';
import { Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';

import ItemCollection from '../../components/icons/ItemCollection';
import { DateMobile } from './LabResults2';

const Icon = styled(Flex)`
  display: none;
  @media screen and (min-width: 52em) {
    display: block;
  }
`;
export const LabStep1 = ({date}) => {
  return (
    <Flex flexDirection={['column', 'row']} flex={1}>
      <Flex flexDirection="column" flex={1}>
        <DateMobile mb={3}>{date}</DateMobile>
        <Text mb={3} fontWeight={600}>
          Your kit is now registered
        </Text>
        <Text lineHeight={1.6} fontSize={'14px'}>
          You can now collect your sample and send back your sample to our lab using the return shipping label inside your kit.
        </Text>
      </Flex>
      <Icon mr={[0, 4]} mb={[3, 0]} alignItems="flex-start" justifyContent="center">
        <ItemCollection width={90} />
      </Icon>
    </Flex>
  );
};
