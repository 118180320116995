import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { useCSVReader, lightenDarkenColor, formatFileSize } from 'react-papaparse';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Button } from 'rebass';
import { ADMIN_UPSERT_B2B_ELIGIBLES } from '../../graphql';

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
const GREY_DIM = '#686868';

const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};
const ModalUploadEligible = ({ company, onComplete }) => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);
  const [isViewResults, setIsViewResults] = useState(false);
  const [resultData, setResultData] = useState(null);
  const CATEGORIES_COLUMNS = [
    {
      Header: 'first Name',
      accessor: 'firstName',
    },
    {
      Header: 'last Name',
      accessor: 'lastName',
    },
    {
      Header: 'dob',
      accessor: 'dob',
    },
    {
      Header: 'employee Email',
      accessor: 'employeeEmail',
    },
    {
      Header: 'employee Id',
      accessor: 'employeeId',
    },
    {
      Header: 'relationship Employer',
      accessor: 'relationshipToEmployer',
    },
    {
      Header: 'first Name Primary',
      accessor: 'primaryFirstName',
    },
    {
      Header: 'last Name Primary',
      accessor: 'primaryLastName',
    },
    {
      Header: 'subscriber Id',
      accessor: 'subscriberId',
    },
    {
      Header: 'member Id',
      accessor: 'memberId',
    },
    {
      Header: 'unique code',
      accessor: 'uniqueCode',
    },
  ];

  const convert = (results) => {
    if (!results || !results.data || results.data.length < 1) return;

    let firstnameColId = -1;
    let lastnameColId = -1;
    let dobColId = -1;
    let employeeEmailColId = -1;
    let memberIdColId = -1;
    let employeeIdColId = -1;
    let subscriberIdColId = -1;
    let relationshipColId = -1;
    let firstnamePrimaryColId = -1;
    let lastnamePrimaryColId = -1;
    let uniqueCodeColId = -1;
    const headers = results.data[0];
    for (let index = 0; index < headers.length; index++) {
      const element = (headers[index] || '').toLowerCase();
      console.log({element})
      switch (element) {
        case 'first name':
        case 'firstname':
          firstnameColId = index;
          break;
        case 'last name':
        case 'lastname':
          lastnameColId = index;
          break;

        case 'dob':
        case 'birth date':
        case 'date of birth':
          dobColId = index;
          break;

        case 'employee email':
          employeeEmailColId = index;
          break;
        case 'member id':
          memberIdColId = index;
          break;
        case 'employee id':
          employeeIdColId = index;
          break;
        case 'subscriber id':
          subscriberIdColId = index;
          break;
        case 'primary first name':
          firstnamePrimaryColId = index;
          break;
        case 'primary last name':
          lastnamePrimaryColId = index;
          break;
        case 'unique code':
        case 'coupon code':
          uniqueCodeColId = index;
          break;
        default:
          break;
      }
      if (element.indexOf('relationship to ') > -1) {
        relationshipColId = index;
      }
    }
    const res = [];
    for (let index = 1; index < results.data.length; index++) {
      const element = results.data[index];
      res.push({
        firstName: element[firstnameColId],
        lastName: element[lastnameColId],
        dob: element[dobColId],
        employeeEmail: (element[employeeEmailColId] || '').toLowerCase(),
        employeeId: element[employeeIdColId],
        memberId: element[memberIdColId],
        subscriberId: element[subscriberIdColId],
        relationshipToEmployer: element[relationshipColId],
        primaryFirstName: element[firstnamePrimaryColId],
        primaryLastName: element[lastnamePrimaryColId],
        uniqueCode: element[uniqueCodeColId],
      });
    }
    setResultData(res);
    console.log({ res });
  };

  const prepareForUpload = () => {
    const final = [];

    for (let index = 0; index < resultData.length; index++) {
      const element = resultData[index];
      final.push({
        ...element,
        dob: new Date(element.dob),
      });
    }

    return final;
  };

  if (!isViewResults && !!resultData) {
    return (
      <Button variant={'primary'} onClick={() => setIsViewResults(true)}>
        View Parsed Data
      </Button>
    );
  }

  if (!!isViewResults) {
    return (
      <>
        <ReactTable
          data={resultData || []}
          columns={CATEGORIES_COLUMNS}
          defaultPageSize={resultData && resultData.length > 3 ? Math.min(resultData.length, 25) : 3}
          pageSize={resultData && resultData.length > 3 ? resultData.length : 3}
          showPagination={false}
        />
        <Mutation
          mutation={ADMIN_UPSERT_B2B_ELIGIBLES}
          onCompleted={(data) => {
            onComplete(data.upsertAdminB2BEligibles);
          }}
        >
          {(mutate, { loading, error }) => {
            return (
              <Button
                variant={'primary'}
                // disabled={loading}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  console.log('Mutate');
                  const dt = prepareForUpload()
                  mutate({
                    variables: {
                      companyId: company.id,
                      data: dt,
                    },
                  });
                }}
              >
                {!!loading ? 'Processing' : 'Upload'}
              </Button>
            );
          }}
        </Mutation>
      </>
    );
  }

  return (
    <CSVReader
      onUploadAccepted={(results) => {
        // console.log('---------------------------');
        // console.log(results);
        // console.log('---------------------------');
        convert(results);
        setZoneHover(false);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setZoneHover(false);
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }) => (
        <>
          <div {...getRootProps()} style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}>
            {acceptedFile ? (
              <>
                <div style={styles.file}>
                  <div style={styles.info}>
                    <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span>
                    <span style={styles.name}>{acceptedFile.name}</span>
                  </div>
                  <div style={styles.progressBar}>
                    <ProgressBar />
                  </div>
                  <div
                    {...getRemoveFileProps()}
                    style={styles.remove}
                    onMouseOver={(event) => {
                      event.preventDefault();
                      setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                    }}
                    onMouseOut={(event) => {
                      event.preventDefault();
                      setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                    }}
                  >
                    <Remove color={removeHoverColor} />
                  </div>
                </div>
              </>
            ) : (
              'Drop CSV file here or click to upload'
            )}
          </div>
        </>
      )}
    </CSVReader>
  );
};

export default ModalUploadEligible;
