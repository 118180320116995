import React from 'react';
import { Flex, Button } from 'rebass';
import { Query, Mutation } from 'react-apollo';
import 'react-table-v6/react-table.css';
import Select from 'react-select';
import {
  ADMIN_GET_MOBILE_COURSE_SCREEN_FORMATS,
  ADMIN_GET_MOBILE_COURSE_SCREENS,
  ADMIN_ADD_MOBILE_COURSE_SCREEN,
} from '../../../graphql';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import TextInput from '../../../components/TextInput';
import StoryFormScreen from './formContent/courseScreens/story';
import JournalFormScreen from './formContent/courseScreens/journal';
import QuizzFormScreen from './formContent/courseScreens/quizz';
import OptionsFormScreen from './formContent/courseScreens/options';
import TrackingFormScreen from './formContent/courseScreens/tracking';
import SleepMeditationStoryFormScreen from './formContent/courseScreens/sleepMeditationStory';
import Simulators from './formContent/simulators/simulators';
import SleepMeditationAudioFormScreen from './formContent/courseScreens/sleepMeditationAudio';
import AudioFormScreen from './formContent/courseScreens/audioFormScreen';

const calculateY = offsetY => {
  switch (offsetY) {
    case 0:
      return (330 / 2) * -1; // -(SCREEN_HEIGHT-659/2) / 3;
    case 1:
      return 400 - 330 / 2; // (SCREEN_HEIGHT) / 3;
    case 2:
      return ((400 - 330 / 2) / 3) * 2 * -1;
    case 3:
      return 400 / 3;
    default:
      break;
  }
};

class AddCourseScreenModalInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      isActive: true,
      isPro: false,
    };
  }

  render() {
    const { onClose, onSave, original, editingData } = this.props;
    console.log({ editingData });
    let initialButtons = null;
    let initialOptions = null;
    console.log('editingData', editingData);
    if (editingData) {
      if (editingData.buttons) {
        let btns = [];
        let tmpArray = JSON.parse(editingData.buttons);
        console.log('tmpArray', tmpArray);
        for (let index = 0; index < tmpArray.length; index++) {
          const element = tmpArray[index];
          btns.push({
            ...element,
            variant: {
              value: element.variant,
              label: element.variant,
            },
          });
        }
        initialButtons = btns;
      }
      if (editingData.options) {
        let opts = [];
        let tmpArray = JSON.parse(editingData.options);
        console.log('tmpArray', tmpArray);
        for (let index = 0; index < tmpArray.length; index++) {
          const element = tmpArray[index];
          opts.push({
            ...element,
            variant: {
              value: element.variant,
              label: element.variant,
            },
          });
        }
        initialOptions = opts;
      }
    }
    const hormoneYValues = [...Array(4).keys()].map(i => {
      return {
        value: i,
        label: i + 1,
      };
    });
    return (
      <Query query={ADMIN_GET_MOBILE_COURSE_SCREEN_FORMATS}>
        {({ data, loading, error }) => {
          if (loading || error) return <div />;
          console.log(
            'data.adminGetMobileCourseScreenFormats',
            data.adminGetMobileCourseScreenFormats
          );
          const formats = data.adminGetMobileCourseScreenFormats
            .filter(x => {
              return (
                x.name !== 'Food Journaling' &&
                x.name !== 'Food Tracking' //&&
                // x.name !== 'Sleep Meditation Story' &&
                // x.name !== 'Sleep Meditation Audio'
              );
            })
            .map(x => {
              return {
                value: x.id,
                label: x.name,
              };
            });
          return (
            <Mutation
              mutation={ADMIN_ADD_MOBILE_COURSE_SCREEN}
              refetchQueries={[
                {
                  query: ADMIN_GET_MOBILE_COURSE_SCREENS,
                  variables: {
                    courseId: original.id,
                  },
                },
              ]}
              // variables={{
              //   title,
              //   description,
              //   isActive,
              //   isPro,
              // }}
              onCompleted={() => {
                onSave();
              }}
            >
              {(createJourney, { loading }) => (
                <Formik
                  isInitialValid={
                    ({ initialValues }) => true
                    //   schema.isValidSync(initialValues)
                  }
                  initialValues={
                    !editingData
                      ? {
                          title: null,
                          Content: null,
                          Tags: '',
                          ScreenFormat: null,
                          isActive: true,
                          isPro: false,
                          // order: 0,
                          hormoneY: hormoneYValues[0],
                          textStyle: '',
                          buttons: null,
                          options: null,
                          textColor: '#ffffff',
                          textFontSize: 24,
                          titleColor: '#ffffff',
                          titleFontSize: 24,
                          nextOverride: null,
                        }
                      : {
                          ...editingData,
                          ScreenFormat: formats.find(
                            x => x.value === editingData.ScreenFormat.id
                          ),
                          textColor: editingData.textStyle
                            ? JSON.parse(editingData.textStyle).color
                            : '#ffffff',
                          textFontSize: editingData.textStyle
                            ? JSON.parse(editingData.textStyle).fontSize
                            : 24,
                          titleColor: editingData.titleStyle
                            ? JSON.parse(editingData.titleStyle).color
                            : '#ffffff',
                          titleFontSize: editingData.titleStyle
                            ? JSON.parse(editingData.titleStyle).fontSize
                            : 24,
                          hormoneY: hormoneYValues.find(
                            x => x.value === editingData.hormoneY
                          ),
                          buttons: initialButtons,
                          options: initialOptions,
                          name: editingData.trackingConfig
                            ? editingData.trackingConfig.title
                            : null,
                          question: editingData.trackingConfig
                            ? editingData.trackingConfig.title
                            : null,
                          // title: editingData.trackingConfig
                          //   ? editingData.trackingConfig.title
                          //   : null,
                          labelGroupId: editingData.trackingConfig
                            ? editingData.trackingConfig.labelGroupId
                            : null,
                          negativeColor: editingData.trackingConfig
                            ? editingData.trackingConfig.negativeColor
                            : null,
                          positiveColor: editingData.trackingConfig
                            ? editingData.trackingConfig.positiveColor
                            : null,
                          trackColor: editingData.trackingConfig
                            ? editingData.trackingConfig.trackColor
                            : null,
                          knobColor: editingData.trackingConfig
                            ? editingData.trackingConfig.knobColor
                            : null,
                          // titleColor: editingData.trackingConfig
                          //   ? editingData.trackingConfig.titleColor
                          //   : null,
                          // textColor: editingData.trackingConfig
                          //   ? editingData.trackingConfig.textColor
                          //   : null,
                        }
                  }
                  // validationSchema={schema}
                  onSubmit={async (values, actions) => {
                    console.log('VALS', values);
                    let textStyle = null;
                    let titleStyle = null;
                    if (values.textColor || values.textFontSize) {
                      textStyle = JSON.stringify({
                        color: values.textColor || '#ffffff',
                        fontSize: values.textFontSize || 24,
                      });
                    }
                    if (values.titleColor || values.titleFontSize) {
                      titleStyle = JSON.stringify({
                        color: values.titleColor || '#ffffff',
                        fontSize: values.titleFontSize || 24,
                      });
                    }
                    let buttons;

                    if (values.buttons) {
                      buttons = [];
                      buttons = values.buttons.map(x => {
                        return {
                          title: x.title,
                          variant: x.variant.value,
                          goTo: x.goTo,
                        };
                      });
                    }
                    let options;

                    if (values.options) {
                      options = [];
                      options = values.options.map(x => {
                        return {
                          title: x.title,
                          variant: x.variant.value,
                          isOption: true,
                        };
                      });
                    }
                    let trackingConfig;
                    if (
                      values.ScreenFormat.label === 'Food Tracking' ||
                      values.ScreenFormat.label === 'Tracking'
                    ) {
                      trackingConfig = {
                        id: editingData ? editingData.id : null,
                        name: values.title,
                        question: values.title,
                        title: values.title,
                        labelsGroupId: values.labelGroupId,
                        negativeColor: values.negativeColor,
                        positiveColor: values.positiveColor,
                        trackColor: values.trackColor,
                        knobColor: values.knobColor,
                        titleColor: values.titleColor,
                        textColor: values.textColor,
                      };
                    }

                    console.log('tracking config', trackingConfig);
let nextOverride = null
if(values.nextOverride) {
  try {
nextOverride=parseInt(values.nextOverride, 10)
  }catch{

  }
}
                    await createJourney({
                      variables: {
                        id:
                          editingData && editingData.id ? editingData.id : null,
                        courseId: original.id,
                        Tags: values.tags ? values.tags.split(';') : null,
                        ScreenFormatId: values.ScreenFormat.value,
                        Content: values.Content,
                        isActive: values.isActive,
                        isPro: values.isPro,
                        order: values.order ? values.order : null,
                        title: values.title,
                        hormoneY: values.hormoneY.value,
                        textStyle,
                        titleStyle,
                        buttons: buttons ? JSON.stringify(buttons) : null,
                        options: options ? JSON.stringify(options) : null,
                        webflowArticleId: values.webflowArticleId,
                        externalArticleLink: values.externalArticleLink,
                        nextOverride,
                        trackingConfig,
                      },
                    });
                    // submitCheck(values, actions)
                  }}
                  render={({
                    touched,
                    errors,
                    isValid,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    values,
                  }) => {
                    console.log(values.ScreenFormat);
                    return (
                      <Form>
                        <Flex flexDirection="row" width="800px">
                          <Flex
                            flexDirection="column"
                            flex={1}
                            style={{
                              paddingRight: '12px',
                              // overflowY: 'auto',
                              maxHeight: '600px',
                            }}
                          >
                            <FormField
                              mt={4}
                              mb={4}
                              error={touched.phone && errors.phone}
                            >
                              <label for="screenFormat">Screen Format</label>
                              <Select
                                classNamePrefix="region-select"
                                options={formats}
                                name="ScreenFormat"
                                value={values.ScreenFormat}
                                placeholder="select screen format"
                                onChange={option => {
                                  setFieldValue('ScreenFormat', option);
                                }}
                                onBlur={() =>
                                  setFieldTouched('ScreenFormat', true)
                                }
                                isSearchable={true}
                              />
                            </FormField>

                            {values.ScreenFormat &&
                              values.ScreenFormat.label === 'Story' && (
                                <StoryFormScreen
                                  touched={touched}
                                  errors={errors}
                                  hormoneYValues={hormoneYValues}
                                  values={values}
                                  setFieldTouched={setFieldTouched}
                                  setFieldValue={setFieldValue}
                                />
                              )}

                            {values.ScreenFormat &&
                              values.ScreenFormat.label === 'Quizz' && (
                                <QuizzFormScreen
                                  touched={touched}
                                  errors={errors}
                                  hormoneYValues={hormoneYValues}
                                  values={values}
                                  setFieldTouched={setFieldTouched}
                                  setFieldValue={setFieldValue}
                                />
                              )}

                            {values.ScreenFormat &&
                              (values.ScreenFormat.label === 'Journal' ||
                                values.ScreenFormat.label ===
                                  'Food Journaling') && (
                                <JournalFormScreen
                                  touched={touched}
                                  errors={errors}
                                  hormoneYValues={hormoneYValues}
                                  values={values}
                                  setFieldTouched={setFieldTouched}
                                  setFieldValue={setFieldValue}
                                />
                              )}

                            {values.ScreenFormat &&
                              values.ScreenFormat.label === 'Options' && (
                                <OptionsFormScreen
                                  touched={touched}
                                  errors={errors}
                                  hormoneYValues={hormoneYValues}
                                  values={values}
                                  setFieldTouched={setFieldTouched}
                                  setFieldValue={setFieldValue}
                                />
                              )}

                            {values.ScreenFormat &&
                              (values.ScreenFormat.label === 'Food Tracking' ||
                                values.ScreenFormat.label === 'Tracking') && (
                                <TrackingFormScreen
                                  touched={touched}
                                  errors={errors}
                                  hormoneYValues={hormoneYValues}
                                  values={values}
                                  setFieldTouched={setFieldTouched}
                                  setFieldValue={setFieldValue}
                                />
                              )}

                            {values.ScreenFormat &&
                              values.ScreenFormat.label ===
                                'Sleep Meditation Story' && (
                                <SleepMeditationStoryFormScreen
                                  touched={touched}
                                  errors={errors}
                                  hormoneYValues={hormoneYValues}
                                  values={values}
                                  setFieldTouched={setFieldTouched}
                                  setFieldValue={setFieldValue}
                                />
                              )}

                            {values.ScreenFormat &&
                              values.ScreenFormat.label ===
                                'Sleep Meditation Audio' && (
                                <SleepMeditationAudioFormScreen
                                  touched={touched}
                                  errors={errors}
                                  hormoneYValues={hormoneYValues}
                                  values={values}
                                  setFieldTouched={setFieldTouched}
                                  setFieldValue={setFieldValue}
                                />
                              )}

                              {values.ScreenFormat &&
                                values.ScreenFormat.label ===
                                  'Audio' && (
                                  <AudioFormScreen
                                    touched={touched}
                                    errors={errors}
                                    hormoneYValues={hormoneYValues}
                                    values={values}
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                  />
                                )}
                            <FormField
                              mb={3}
                              error={touched.phone && errors.phone}
                            >
                              <label htmlFor="nextOverride">
                                Go To Screen index next
                              </label>
                              <label htmlFor="nextOverride">
                                (leave empty for next screen in order)
                              </label>
                              <TextInput
                                name="nextOverride"
                                placeholder="Next screen index"
                              />
                            </FormField>

                            <Flex
                              mt={4}
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Button
                                style={{
                                  background: 'white',
                                  color: '#344f79',
                                  border: '1px solid #344f79',
                                }}
                                type="button"
                                onClick={onClose}
                              >
                                Cancel
                              </Button>
                              <Button
                                // disabled={!isValid}
                                style={{ background: '#344f79' }}
                                onClick={() => {
                                  // createCategory();
                                }}
                              >
                                Save
                              </Button>
                            </Flex>
                          </Flex>
                          <Simulators
                            values={values}
                            original={original}
                            calculateY={calculateY}
                          />
                        </Flex>
                      </Form>
                    );
                  }}
                />
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default AddCourseScreenModalInner;
