import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withWizard } from 'react-albus';
import { compose, withApollo } from 'react-apollo';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import PleaseWait from '../../components/PleaseWait';
import ConfirmModal from '../../components/ConfirmModal';
import { B2B_PRECHECK, B2B_PRECHECK_EMPLOYEEID_NAMES, B2B_PRECHECK_MEMBERID_NAMES, B2B_PRECHECK_SUBSCRIBERID_NAMES, B2B_PRECHECK_UNIQUE_CODE } from '../../graphql';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;
const Precheck = ({ client, wizard, values, successContinue, failContinue, failEditInformation, relationship, companyId, successContinueWithValues, failContinueWithValues }) => {
  console.log({ companyId });
  const [modalFail, setModalFail] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setModalFail(false);
    setModalSuccess(false);

    const precheck = async () => {
      console.log({ values });
      if (!values.method || values.method.toLowerCase() === 'default') {
        const success = await client.mutate({
          mutation: B2B_PRECHECK,
          variables: {
            data: {
              firstName: values.firstName,
              lastName: values.lastName,
              dob: moment(values.dob).toDate(),
              companyId: companyId,
              relationship: relationship,
              employeeEmail: values.employeeEmail,
              employeeDob: moment(values.employeeDob).toDate(),
              employeeFirstName: values.employeeFirstName,
              employeeLastName: values.employeeLastName,
            },
          },
        });
        if (!success || !success.data || !success.data.b2bEligibilityPrecheck || !success.data.b2bEligibilityPrecheck.ok) {
          setModalFail(true);
        } else {
          setModalSuccess(true);
        }
      } else if (values.method.toLowerCase() === 'subscriberid;names') {
        const success = await client.mutate({
          mutation: B2B_PRECHECK_SUBSCRIBERID_NAMES,
          variables: {
            data: {
              firstName: values.firstName,
              lastName: values.lastName,
              dob: moment(values.dob).toDate(),
              companyId: companyId,
              relationship: relationship,
              subscriberId: values.subscriberId,
            },
          },
        });
        if (!success || !success.data || !success.data.b2bEligibilityPrecheckSubscriberIdNames || !success.data.b2bEligibilityPrecheckSubscriberIdNames.ok) {
          setModalFail(true);
        } else {
          if (successContinueWithValues) {
            successContinueWithValues(values, wizard);
          } else {
            successContinue(wizard);
          }
        }
      } else if (values.method.toLowerCase() === 'memberid;names') {
        const success = await client.mutate({
          mutation: B2B_PRECHECK_MEMBERID_NAMES,
          variables: {
            data: {
              firstName: values.firstName,
              lastName: values.lastName,
              dob: moment(values.dob).toDate(),
              companyId: companyId,
              relationship: relationship,
              memberId: values.memberId,
            },
          },
        });
        if (!success || !success.data || !success.data.b2bEligibilityPrecheckMemberIdNames || !success.data.b2bEligibilityPrecheckMemberIdNames.ok) {
          setModalFail(true);
        } else {
          if (successContinueWithValues) {
            successContinueWithValues(values, wizard);
          } else {
            successContinue(wizard);
          }
        }
      } else if (values.method.toLowerCase() === 'employeeid;names') {
        const success = await client.mutate({
          mutation: B2B_PRECHECK_EMPLOYEEID_NAMES,
          variables: {
            data: {
              firstName: values.firstName,
              lastName: values.lastName,
              dob: moment(values.dob).toDate(),
              companyId: companyId,
              relationship: relationship,
              employeeId: values.employeeId,
            },
          },
        });
        if (!success || !success.data || !success.data.b2bEligibilityPrecheckEmployeeIdNames || !success.data.b2bEligibilityPrecheckEmployeeIdNames.ok) {
          setModalFail(true);
        } else {
          if (successContinueWithValues) {
            successContinueWithValues(values, wizard);
          } else {
            successContinue(wizard);
          }
        }
      } else if (values.method.toLowerCase() === 'uniquecode') {
        const success = await client.mutate({
          mutation: B2B_PRECHECK_UNIQUE_CODE,
          variables: {
            data: {
              firstName: values.firstName,
              lastName: values.lastName,
              dob: moment(values.dob).toDate(),
              companyId: companyId,
              relationship: relationship,
              uniqueCode: values.uniqueCode,
            },
          },
        });
        if (!success || !success.data || !success.data.b2bEligibilityPrecheckUniqueCode || !success.data.b2bEligibilityPrecheckUniqueCode.ok) {
          setModalFail(true);
        } else {
          if (successContinueWithValues) {
            successContinueWithValues(values, wizard);
          } else {
            successContinue(wizard);
          }
        }
      }
      setIsLoading(false);
    };

    precheck();
  }, []);

  if (!!isLoading) return <PleaseWait text={'Checking eligibility'} />;
  console.log({ values, modalFail, modalSuccess });
  return (
    <div>
      {!!modalFail && (
        <ConfirmModal
          childrenManaged
          confirmLabel="OK"
          isOpen={modalFail}
          okOnly
          cardStyle={{
            maxHeight: '80vh',
            position: 'relative',
            overflowY: 'auto',
          }}
          onClose={() => {}}
          onConfirm={() => {}}
          onBackgroundClick={() => {}}
          onEscapeKeydown={() => {}}
        >
          <ModalInner>
            <Flex flexDirection={'column'}>
              <Heading mb={4} mx={3} textAlign="center">
                Is this information correct?
              </Heading>
              {!values.method || values.method.toLowerCase() === 'default' ? (
                <Flex flexDirection={'row'}>
                  <Text fontWeight={500}>Employee Work Email:</Text>
                  <Text fontWeight={300} ml={2}>
                    {values.employeeEmail}
                  </Text>
                </Flex>
              ) : values.method.toLowerCase() === 'subscriberid;names' ? (
                <Flex flexDirection={'row'}>
                  <Text fontWeight={500}>Subscriber Id:</Text>
                  <Text fontWeight={300} ml={2}>
                    {values.subscriberId}
                  </Text>
                </Flex>
              ) : values.method.toLowerCase() === 'memberid;names' ? (
                <Flex flexDirection={'row'}>
                  <Text fontWeight={500}>Member Id:</Text>
                  <Text fontWeight={300} ml={2}>
                    {values.memberId}
                  </Text>
                </Flex>
              ) : values.method.toLowerCase() === 'employeeid;names' ? (
                <Flex flexDirection={'row'}>
                  <Text fontWeight={500}>Employee Id:</Text>
                  <Text fontWeight={300} ml={2}>
                    {values.employeeId}
                  </Text>
                </Flex>
              ) : values.method.toLowerCase() === 'uniquecode' ? (
                <Flex flexDirection={'row'}>
                  <Text fontWeight={500}>Unique Code:</Text>
                  <Text fontWeight={300} ml={2}>
                    {values.uniqueCode}
                  </Text>
                </Flex>
              ) : null}
              {(values.method || '').toLowerCase() !== 'uniquecode' && (
                <Flex flexDirection={'row'}>
                  <Text fontWeight={500}>Employee date of birth:</Text>
                  <Text fontWeight={300} ml={2}>
                    {(values.employeeDob || '').toString()}
                  </Text>
                </Flex>
              )}

              <Button
                variant={'primary'}
                onClick={() => {
                  if (failContinueWithValues) {
                    failContinueWithValues(values, wizard);
                  } else {
                    failContinue(wizard);
                  }
                }}
                mt={3}
              >
                Submit
              </Button>
              <Button variant="outline" onClick={() => failEditInformation(wizard)} mt={3}>
                Edit Information
              </Button>
            </Flex>
          </ModalInner>
        </ConfirmModal>
      )}
      {!!modalSuccess && (
        <ConfirmModal
          childrenManaged
          confirmLabel="OK"
          isOpen={modalSuccess}
          okOnly
          cardStyle={{
            maxHeight: '80vh',
            position: 'relative',
            overflowY: 'auto',
          }}
          onClose={() => {}}
          onConfirm={() => {}}
          onBackgroundClick={() => {}}
          onEscapeKeydown={() => {}}
        >
          <ModalInner>
            <Flex flexDirection={'column'}>
              <Heading mb={4} mx={3} textAlign="center">
                Almost There!
              </Heading>
              <Text mb={3} textAlign="center">
                An email will be sent to the work email address once your registration is completed . Don’t forget to click on the link in this email to confirm your eligibility.{' '}
              </Text>
              <Button variant={'primary'} onClick={() => successContinue(wizard)}>
                Continue
              </Button>
            </Flex>
          </ModalInner>
        </ConfirmModal>
      )}
    </div>
  );
};

export default compose(
  withApollo,
  withWizard
)(Precheck);
