import React from 'react';
import { Query } from 'react-apollo';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import TextTransition, { presets } from 'react-text-transition';

import Header from '../components/Header';
import FullLayout from '../components/FullLayout';
import LoadingScreen from '../components/LoadingScreen';
import Logo from '../components/Logo';
import PleaseWait from '../components/PleaseWait';

import { CURRENT_USER, GET_MEMBERSHIPS } from '../graphql';
import useUnmount from '../utils/hooks/lifecycle/useUnmount';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
`;
const TEXTS = [
  "Ain't no tired like thyroid tired.\nTake a 30 second power nap while we access your file.",
  'Did you know hydration is a pillar of thyroid health?\nPour a drink of water while you wait.',
  'Close your eyes & breathe while we access your file.\nYour adrenals will thank you!',
  'Please hold while we access your info\nso you can start feeling better faster than this page loads.',
  'Say something nice to yourself while you wait.\nSelf-compassion is part of a thyroid-healthy lifestyle.',
  'Time for a 30-second dance party!\nGet those endorphins going and blood flowing.',
  'Text a friend while you wait!\nStudies show that positive relationships are good for our health.',
];
const RotatingText = props => {
  const [index, setIndex] = React.useState(0);
  const [intervalId, setIntervalId] = React.useState(null);
  const [arr, setArr] = React.useState(
    TEXTS.map(a => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map(a => a.value)
  );

  React.useEffect(() => {
    const handle = setInterval(
      () => setIndex(index => index + 1),
      3000 // every 3 seconds
    );
    setIntervalId(handle);
  }, []);

  useUnmount(() => {
    // console.log('unmount', intervalId);
    if (intervalId) {
      clearInterval(intervalId);
    }
  });

  return (
    <Box
      style={{
        whiteSpace: 'nowrap',
        marginTop: '40px',
        width: 'min(400px, 80vw)',
        maxWidth: 'min(400px, 80vw)',
        justifyContent: 'center',
      }}
    >
      <TextTransition
        noOverflow
        style={{
          whiteSpace: 'nowrap',
          wordWrap: 'break-word',
        }}
        text={arr[index % arr.length]}
        springConfig={presets.default}
      />
    </Box>
  );
};
const withSubscriptions = Component => props => (
  <Query query={GET_MEMBERSHIPS}>
    {({ data, loading, refetch, error }) => {
      //console.log({data})
      if (loading) {
        return (
          <Flex>
            <Container>
              <Flex flex={1}>
                <Header dark={false} />
              </Flex>
              <PleaseWait style={{marginBottom: '20px'}}
                text=" "
                spinner={{
                  sColor: '#ECEFF2',
                  pColor: '#909FB5',
                }}
              >
              </PleaseWait>
            </Container>
          </Flex>
        );
      }
      if (error) {
        if (error) {
          console.log('err curr', error);
        }
        return null;
      }

      const membershipData = (
        (data.getMemberships || {}).memberships || []
      ).find(x => x.subscriptionTarget === 'SUBSCRIPTION_MEMBERSHIP');
      const kitData = ((data.getMemberships || {}).memberships || []).find(
        x => x.subscriptionTarget === 'SUBSCRIPTION_KIT'
      );
      const vitaminsData = ((data.getMemberships || {}).memberships || []).find(
        x => x.subscriptionTarget === 'SUBSCRIPTION_VITAMINS'
      );

      return (
        <Component
          {...props}
          subscriptions={{
            membership: membershipData,
            kit: kitData,
            vitamins: vitaminsData,
          }}
          refetchSubscriptions={refetch}
        />
      );
    }}
  </Query>
);

export default withSubscriptions;
