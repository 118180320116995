import React from 'react';
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ComposedChart,
  Area,
  ResponsiveContainer,
  CartesianGrid,
  Label,
  ReferenceArea,
} from 'recharts';

const CustomDot = ({ cx, cy, stroke, payload, value, ...props }) => {
  // console.log('Dot', {
  //   cx,
  //   cy,
  //   stroke,
  //   payload,
  //   value,
  //   ...props,
  // });
  if (
    value[value.length - 1] > props.normalTop ||
    value[value.length - 1] < props.normalBottom
  ) {
    return (
      <svg x={cx - 4} y={cy - 4} height="8" width="8">
        <circle cx="4" cy="4" r="4" fill="#F8A294" />
      </svg>
    );
  }
  return (
    <svg x={cx - 4} y={cy - 4} height="8" width="8">
      <circle cx="4" cy="4" r="4" fill="#3bb496" />
    </svg>
  );
};
const CustomDotLine = ({ cx, cy, stroke, payload, value, ...props }) => {
  // console.log('Dot', {
  //   cx,
  //   cy,
  //   stroke,
  //   payload,
  //   value,
  //   ...props,
  // });
  if (
    value[value.length - 1] > props.normalTop ||
    value[value.length - 1] < props.normalBottom
  ) {
    return <svg x={cx - 4} y={cy - 4} height="8" width="8"></svg>;
  }
  return <svg x={cx - 4} y={cy - 4} height="8" width="8"></svg>;
};

class LabResultsTrend extends React.Component {
  gradientOffset = (dataMax, dataMin, normalTop, normalBottom) => {
    const t = ((Math.min(normalTop, dataMax) - 0) * 100) / dataMax;
    const b = ((normalBottom - 0) * 100) / dataMax;

    const res = {
      normalTop: 1 - t / 100,
      normalBottom: 1 - b / 100,
    };

    return res;
  };

  gradientOffset2 = (dataMax, dataMin, normalTop, normalBottom) => {
    const low = ((dataMin - 0) * 100) / dataMax;
    const high = ((Math.min(normalTop, dataMax) - 0) * 100) / dataMax;
    const t =
      ((Math.min(normalTop, dataMax) - 0 - dataMin * (1 - high / 100)) * 100) /
      dataMax;
    const b = ((normalBottom - 0 - dataMin * (1 - low / 100)) * 100) / dataMax;

    const res = {
      normalTop: 1 - t / 100,
      normalBottom: 1 - b / 100,
    };

    return res;
  };

  render() {
    const { data, value } = this.props;

    const dataMax = Math.max(...data.map(i => i.value));
    const dataMin = Math.min(...data.map(i => i.value));
    const normalBottom = data[0].normalRangeLow;
    const normalTop = data[0].normalRangeHigh;
    const yAxisMinTop = data[0].yAxisMinTop;
    

    const off = this.gradientOffset(dataMax, dataMin, normalTop, normalBottom);
    const off2 = this.gradientOffset2(
      dataMax,
      dataMin,
      normalTop,
      normalBottom
    );

    let LinearGradientStroke = <linearGradient id="splitColor2" />;
    let LinearGradientStrokeStops = [];

    if (dataMax > normalTop) {
      LinearGradientStrokeStops.push(
        <stop offset={0} stopColor="#F8A294" stopOpacity={1} />
      );
      LinearGradientStrokeStops.push(
        <stop offset={off2.normalTop} stopColor="#F8A294" stopOpacity={1} />
      );
    }
    if (dataMax >= normalBottom && dataMin <= normalTop) {
      LinearGradientStrokeStops.push(
        <stop offset={off2.normalTop} stopColor="#3bb496" stopOpacity={1} />
      );
      LinearGradientStrokeStops.push(
        <stop offset={off2.normalBottom} stopColor="#3bb496" stopOpacity={1} />
      );
    }
    if (dataMin < normalBottom) {
      LinearGradientStrokeStops.push(
        <stop offset={off2.normalBottom} stopColor="#F8A294" stopOpacity={1} />
      );
      LinearGradientStrokeStops.push(
        <stop offset={1} stopColor="#F8A294" stopOpacity={1} />
      );
    }
    LinearGradientStroke = (
      <linearGradient id="splitColor2" x1="0" y1="0" x2="0" y2="1">
        {LinearGradientStrokeStops.map(x => {
          return x;
        })}
      </linearGradient>
    );
    // console.log('LinearGradientStroke', LinearGradientStroke);

    return (
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          data={data}
          margin={{
            top: 5,
            bottom: 5,
            left: -5,
          }}
        >
          <Tooltip />
          <defs>
            {LinearGradientStroke}
            <linearGradient id="splitColor3" x1="0" y1="0" x2="0" y2="1">
              <stop offset={0} stopColor="#6B7F9D" stopOpacity={0.4} />
              <stop offset={1} stopColor="#465F85" stopOpacity={1} />
            </linearGradient>
          </defs>
          <ReferenceArea
            y1={normalBottom}
            y2={normalTop}
            style={{
              fill: 'rgba(59, 180, 150, 0.45)',
            }}
            // label="Normal Range"
          >
            <Label
              value="Normal Range"
              position="insideBottomLeft"
              style={{
                fontSize: '12px',
              }}
            />
          </ReferenceArea>
          <Area
            type="monotone"
            dataKey="value"
            stroke="url(#splitColor2)"
            strokeWidth={2}
            fill="url(#splitColor3)"
            legendType="none"
            name="Value"
            dot={
              <CustomDot normalTop={normalTop} normalBottom={normalBottom} />
            }
          />
          <XAxis
            dy={5}
            padding={{ left: 28, right: 12 }}
            dataKey="created"
            axisLine={false}
            tick={{ fontSize: '12px' }}
          />
          <YAxis
            dx={-5}
            allowDecimals={false}
            domain={[
              0,
              dataMax =>
                dataMax < yAxisMinTop
                  ? yAxisMinTop
                  : Number(Math.round(dataMax * 1.1 + 'e2') + 'e-2').toFixed(2),
            ]}
            type="number"
            axisLine={true}
            tick={{ fontSize: '12px' }}
          />
          {normalBottom > 0 && (
            <ReferenceLine y={normalBottom} stroke="transparent">
              <Label
                value={normalBottom}
                position="insideLeft"
                style={{
                  fill: '#3bb496',
                  fontSize: '12px',
                }}
              />
            </ReferenceLine>
          )}
          <ReferenceLine y={normalTop} stroke="transparent">
            <Label
              value={normalTop}
              style={{ fill: '#3bb496', fontSize: '12px' }}
              position="insideLeft"
            />
          </ReferenceLine>
          {/* {normalBottom > 0 && ( */}
          <Line
            dataKey="normalRangeLow"
            stroke="#3bb496"
            dot={false}
            legendType="none"
            name="Normal Range Low"
          ></Line>
          {/* )} */}
          <Line
            dataKey="normalRangeHigh"
            stroke="#3bb496"
            dot={false}
            legendType="none"
            name="Normal Range High"
          ></Line>
          <CartesianGrid vertical={false} />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

export default LabResultsTrend;
