import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Box, Heading, Text, Flex, Button } from 'rebass';
import { Query, Mutation, withApollo } from 'react-apollo';
import styled from 'styled-components';
import { withToastManager } from 'react-toast-notifications';
import gql from 'graphql-tag';
import TextInput, { Input } from '../components/TextInput';

const GET_REGISTERED_TOKENS = gql`
  query adminGetRegisteredNotificationTokens {
    adminGetRegisteredNotificationTokens {
      users {
        id
        email
        notificationToken
      }
    }
  }
`;

const SEND_NOTIFICATION = gql`
  mutation adminSendNotification($userId: String!, $route: String!) {
    adminSendNotification(userId: $userId, route: $route) {
      ok
    }
  }
`;

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;

const AdminTestNotifications = ({ client, toastManager }) => {
  const [route, setRoute] = React.useState('');
  const [id, setId] = React.useState('');

  return (
    <Mutation
      mutation={SEND_NOTIFICATION}
      onCompleted={() => {
        toastManager.add('Notification sent', {
          appearance: 'success',
          autoDismissTimeout: 5000,
        });
      }}
      onError={(err) => {
        console.log(err);
        toastManager.add('An error occured. See console', {
          appearance: 'error',
          autoDismissTimeout: 5000,
        });
      }}
    >
      {(sendNotification, { loading }) => (
        <Container>
          <Heading style={{ marginBottom: '32px' }}>Send Test Notifications (PREVIEW ONLY!!!!!!)</Heading>
          <Input
            placeholder="Route"
            onChange={(r) => {
              setRoute(r.target.value);
            }}
            value={route}
          />
          <Flex flexDirection="row" my="12px">
            <Input
              placeholder="Enter id"
              onChange={(r) => {
                setId(r.target.value);
              }}
              value={id}
            />
            <Button
              onClick={() =>
                sendNotification({
                  variables: {
                    userId: id,
                    route,
                  },
                })
              }
              disabled={!id || !route || loading}
              style={{ borderRadius: '8px' }}
              variant="primary"
            >
              Send
            </Button>
          </Flex>
          <Query query={GET_REGISTERED_TOKENS}>
            {({ data, loading, error }) => {
              if (loading || error) return <div />;

              const columns = [
                {
                  Header: 'email',
                  accessor: 'email',
                },
                {
                  Header: 'token',
                  accessor: 'notificationToken',
                },
                {
                  Header: '',
                  Cell: (row) => {
                    return (
                      <Flex flexDirection="row">
                        <div
                          title="delete"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setId(row.original.id);
                            sendNotification({
                              variables: {
                                userId: id,
                                route,
                              },
                            });
                          }}
                        >
                          Send
                        </div>
                      </Flex>
                    );
                  },
                },
              ];
              return (
                <>
                  <ReactTable data={data.adminGetRegisteredNotificationTokens.users} columns={columns} />
                </>
              );
            }}
          </Query>
        </Container>
      )}
    </Mutation>
  );
};

export default withToastManager(withApollo(AdminTestNotifications));
