import React, { Component, Fragment } from 'react';
import { Flex, Box, Button, Heading, Text, Card } from 'rebass';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import TextArea, { TextAreaNoForm } from '../../../components/TextArea';
import { TextInput } from '../../../components/async/SharedComponents';
import { Form, Formik } from 'formik';

const StyledText = styled(Text)`
  line-height: 1.6;
  border-bottom: 1px solid;
  /* color: white; */
  text-align: center;

  &:hover {
    cursor: pointer;
    font-weight: 600;
  }
`;

const SelectedMarkerOut = styled(Flex)`
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #354e79;
  transition: background 150ms ease-in-out;
  width: 16px;
  height: 16px;
  margin-right: 20px;
  margin-top: 2px;
  justify-content: center;
  align-items: center;
  &.isChecked {
    background: #354e79;
  }
`;

const formatSubmit = values => {
  let payload = [];

  for (const k in values) {
    if (values[k].chosen === 'Y') {
      payload.push({
        questionId: values[k].questionId,
        answer: values[k].answer || '',
        value: values[k].value,
        flowOrder: values[k].flowOrder,
        goto: values[k].goto,
        goToPage: values[k].goToPage,
      });
    }
  }

  return payload;
};

class Multiple extends Component {
  constructor(props) {
    super(props);

    const { getState = () => {}, data } = props;

    let defaults;
    let sel;
    let isDisplay;

    const state = getState();
    if (state && state.values) {
      const stateValues = state.values.find(x => x.id === data.id);
      defaults = this.unFormatSubmit((stateValues || {}).values, data.id);

      if (stateValues && stateValues.values) {
        for (let index = 0; index < stateValues.values.length; index++) {
          const element = stateValues.values[index];
          sel = data.options.find(
            x =>
              x.answer === element.answer || x.questionId === element.questionId
          );
          if (sel && sel.placeholder) {
            isDisplay = true;
          }
        }
      }
    } else {
      defaults = (data || {}).defaults;
    }

    this.state = {
      loading: false,
      isInitialized: true,
      defaults,
      display: isDisplay,
      selected: sel || {},
      interacted: false,
    };
  }

  handleChosen = element => {
    const { wizard, data } = this.props;

    if (element.skip) {
      return wizard.push(element.skip);
    }
    try {
      let sel = data.options.find(x => x.value === element.value);
      // console.log({
      //   sel,
      //   opts: data.options,
      //   element,
      // });
      if (sel.clearOther) {
        for (let index = 0; index < data.options.length; index++) {
          const ee = data.options[index];
          const ii = this.state[ee.value];
          if (ii) {
            ii.chosen = 'N';
            this.setState({ [ee.value]: ii });
          }
        }
      } else {
        for (let index = 0; index < data.options.length; index++) {
          const ee = data.options[index];
          const ii = this.state[ee.value];
          if (ii && ee.clearOther) {
            ii.chosen = 'N';
            this.setState({ [ee.value]: ii });
          }
        }
      }
    } catch (error) {}
    element.chosen === 'Y' ? (element.chosen = 'N') : (element.chosen = 'Y');
    if (element.chosen === 'N') {
      element.answer = null;
    }
    this.setState({ [element.value]: element, interacted: true });
  };

  unFormatSubmit = (values, qId) => {
    const { data } = this.props;
    let payload = {};

    if (!values) return payload;
    for (let index = 0; index < values.length; index++) {
      const element = values[index];

      const questionId = element.questionId;
      let answer;
      let val;

      answer = element.answer;
      let sel = data.options.find(
        x => x.answer === answer || x.questionId === element.questionId
      );
      if (sel && sel.prefix) {
        val = element.value.replace(`${sel.prefix} `, '');
      } else {
        val = element.value;
      }

      payload[`${questionId.toString()}`] = answer;
      payload[`${qId.toString()}`] = val;
    }

    return payload;
  };

  render() {
    const { data, onSubmit, wizard } = this.props;

    return (
      <Fragment>
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          <Box m={2}>
            <Heading textAlign="center">{data.prompt}</Heading>
          </Box>

          <Flex my={3} flexDirection="column">
            {data.options.map(el => (
              <Card
                key={el.questionId || el.value}
                p="12px"
                mb={3}
                onClick={() => this.handleChosen(el)}
                style={{
                  border: `1px solid ${
                    el.chosen === 'Y' ? '#354E79' : 'white'
                  }`,
                  backgroundColor: el.chosen === 'Y' ? '#E7F0FF' : 'white',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Flex flexDirection="row">
                  <SelectedMarkerOut
                    className={el.chosen === 'Y' ? 'isChecked' : ''}
                  >
                    {el.chosen === 'Y' && (
                      <svg
                        width="38px"
                        height="38px"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.0866 6.84591C14.2627 6.67827 14.497 6.58559 14.7401 6.58743C14.9832 6.58927 15.2161 6.68548 15.3897 6.85577C15.5632 7.02605 15.6638 7.25709 15.6702 7.50012C15.6766 7.74316 15.5884 7.97919 15.4241 8.15841L10.4366 14.3959C10.3508 14.4883 10.2473 14.5624 10.1323 14.6139C10.0172 14.6653 9.89294 14.693 9.76691 14.6954C9.64089 14.6977 9.51569 14.6746 9.39879 14.6274C9.2819 14.5803 9.17572 14.51 9.0866 14.4209L5.7791 11.1134C5.68699 11.0276 5.61311 10.9241 5.56187 10.8091C5.51063 10.6941 5.48308 10.5699 5.48086 10.4441C5.47864 10.3182 5.50179 10.1931 5.54895 10.0764C5.5961 9.95967 5.66628 9.85363 5.7553 9.76461C5.84432 9.67558 5.95037 9.6054 6.0671 9.55825C6.18384 9.5111 6.30887 9.48795 6.43475 9.49017C6.56063 9.49239 6.68477 9.51994 6.79977 9.57118C6.91477 9.62242 7.01827 9.6963 7.1041 9.78841L9.7216 12.4047L14.0628 6.87341C14.0707 6.86378 14.079 6.8546 14.0878 6.84591H14.0866Z"
                          fill="white"
                        />
                      </svg>
                    )}
                  </SelectedMarkerOut>

                  <Flex flexDirection="column" flex={1}>
                    <Text fontSize="14px">{el.value}</Text>

                    {el.giveDetails && el.chosen === 'Y' ? (
                      el.isExtended ? (
                        // <TextAreaNoForm
                        //   value={values[data.id]}
                        //   onChange={v => {
                        //     setFieldValue(data.id, v.target.value);
                        //   }}
                        // />

                        <TextAreaNoForm
                          containerStyle={{ marginTop: '16px' }}
                          flex={1}
                          name={el.questionId}
                          placeholder={el.placeholder}
                          value={el.answer}
                          onChange={v => {
                            el.answer = v.target.value;
                          }}
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        />
                      ) : (
                        <TextInput
                          name={el.questionId}
                          style={{ marginTop: '24px', resize: 'vertical' }}
                          flex={1}
                          type={el.inputType || 'text'}
                          placeholder={el.placeholder}
                          onClick={e => {
                            e.stopPropagation();
                          }}
                          value={el.answer}
                          onChange={v => {
                            el.answer = v.target.value;
                          }}
                        />
                      )
                    ) : null}
                  </Flex>
                </Flex>
              </Card>
            ))}
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mx={[3, 3, 0]}
            my={[2, 3]}
          >
            <Button
              disabled={
                data &&
                !!data.cannotSkip &&
                data.options.filter(el => el.chosen === 'Y').length < 1
              }
              variant="pink"
              width={[1, 1 / 2, 1 / 4]}
              onClick={() => {
                const formatted = formatSubmit(data.options);
                // console.log({ formatted });
                onSubmit(
                  formatted, //{ values: formatted, endpoint: data.endpoint },
                  null,
                  wizard,
                  data
                );
              }}
            >
              Next
            </Button>

            {data.skip && (
              <StyledText mt={4} onClick={() => wizard.push(data.skip)}>
                Skip
              </StyledText>
            )}
          </Flex>
        </Flex>
      </Fragment>
    );
  }
}

export default withWizard(Multiple);
