import React from 'react';
import IconContainer from './IconContainer';

const Beaker = props => (
  <IconContainer {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.69 187.95">
      <path
        d="M127.27,4.76c6.92,1.91,13.55,5.72,19.82,9.23,6,3.37,10.46,7.93,15.61,12.32a49.14,49.14,0,0,1,6.86,7.12c3.88,5,8.09,9.42,11,15.12,4.25,7.4,6.63,15.3,9.67,23.22q.6,1.82,1.17,3.63l-4.56.1c0,1.22,0,2.44,0,3.67l3.8.25c.23,1.34.45,2.68.68,4,2.38,12.43.08,26-3.5,38-6,19.62-18.74,36.6-35.29,48.62-7,4.51-13.57,8.9-21.59,11.52-6.63,2.47-13.61,2.75-20.09,5.67,0-1.22,0-2.44-.07-3.66,2.31-.75,5.12.28,5.22-3a43.36,43.36,0,0,0-4.74-1.48c-.73-1.64-.85-6.55-3.59-4.07-.45,1.35-.72,2.7-1,4.1-2.17.73-4.34.13-5,2.81a22.5,22.5,0,0,0,5.36,1.72c.08,1.41.16,2.82.23,4.24-10.6-.6-20.62-.61-30.91-3.19A92.26,92.26,0,0,1,46,170.83q41.66,0,83.32,0c3.58-.13,7.23-.29,9.15-3.82,1.67-3.39-.11-6.61-1.77-9.57-9.76-16.42-19.32-33-29.12-49.37-2.25-4.77-7.27-9.83-6.64-15.24.09-17.67,0-35.35.06-53a59.27,59.27,0,0,0-.42-8.21C99.35,30.4,98,28.89,96.3,28.45a20.48,20.48,0,0,0-5.6.62,46.85,46.85,0,0,1-18.5-.3c-2.25-.41-4.27-.73-6.2.79-2.39,1.86-2.38,4.46-2.46,7.22.05,19,0,38,0,57.07a10.6,10.6,0,0,1-2,6.31c-11,18.56-21.88,37.27-32.92,55.84-2.86-4-6-7.82-8.75-11.87-3.8-5.54-5.6-11.43-8.44-17.62a51.24,51.24,0,0,1-4.89-21,28.66,28.66,0,0,0,3.59.13c1.18-1.43,1.08-3.83,1.45-5.59,2.38-.64,6.1.35,6-3.23-1-2-4-1.52-6-2a42.63,42.63,0,0,0-.83-4.78c-.76-1.55-2.55-1.47-3.68-.39a37.93,37.93,0,0,0-.91,5.09l-1.14.16a85.35,85.35,0,0,1,13.5-48.78c3.05-5,5.79-9.84,9.88-14.1,5.3-5.51,10.64-10.7,17-15,5.82-4.07,12.07-7,18.33-10.24,1.66,1.88,3.27,3.8,4.89,5.72A15.3,15.3,0,0,0,71.17,9.6,9.47,9.47,0,0,0,74,7.26c-.85-.86-1.8-1.62-2.71-2.41-.55-.58-1.1-1.17-1.66-1.74,6.21.6,11.68-1.28,17.71-2.21C100.43-1.37,114.64.94,127.27,4.76Z"
        fill="#f6f6f6"
      />
      <path
        d="M68.41,3.06l1.2,0c.56.57,1.11,1.16,1.66,1.74.91.79,1.86,1.55,2.71,2.41A9.47,9.47,0,0,1,71.17,9.6a15.3,15.3,0,0,1-2.46,2.92c-1.62-1.92-3.23-3.84-4.89-5.72.93-.49,1.85-1,2.76-1.48C67.18,4.56,67.79,3.8,68.41,3.06Z"
        fill="#fbafbc"
      />
      <path
        d="M72.2,28.77a46.85,46.85,0,0,0,18.5.3,20.48,20.48,0,0,1,5.6-.62c1.68.44,3,1.95,4.24,3.16l-4.09.44c-5.21.57-10.24,1.86-15.54,1.83a96.17,96.17,0,0,1-12.18-1.81,9.74,9.74,0,0,0-1.41,5.73c.07,19,0,38,.05,57,.18,3.51-1.62,5.9-3.29,8.76-4.8,7.87-9.27,15.94-14.13,23.77,3.56-.47,7.54-1.05,11.08-.33,4.1,1.13,7.35,5.07,11.72,6.09A17.41,17.41,0,0,0,80.9,133c5.15-2.66,8.34-6.75,13.65-8.9s11.62-1.56,17.17-1.17c-.33-.75-.66-1.5-1-2.24-1.13-4.17-2-8.41-3.18-12.57,9.8,16.4,19.36,33,29.12,49.37,1.66,3,3.44,6.18,1.77,9.57-1.92,3.53-5.57,3.69-9.15,3.82q-41.64,0-83.32,0c-4.87-.12-9.87.32-14.69-.33a6.64,6.64,0,0,1-5.69-4.88c-.81-3.42,1.51-6.77,3.12-9.62,11-18.57,21.87-37.28,32.92-55.84a10.6,10.6,0,0,0,2-6.31c0-19,0-38,0-57.07.08-2.76.07-5.36,2.46-7.22C67.93,28,70,28.36,72.2,28.77Z"
        fill="#024d6a"
      />
      <path
        d="M96.45,32.05c1.14,4.33.71,9.29.75,13.77,0,16.29,0,32.74,0,49A10.56,10.56,0,0,0,99,101c3.9,6.54,7.73,13.12,11.69,19.63.34.74.67,1.49,1,2.24-5.55-.39-11.9-1-17.17,1.17s-8.5,6.24-13.65,8.9q-.2-49.53,0-99.07C86.21,33.91,91.24,32.62,96.45,32.05Z"
        fill="#f9a394"
      />
      <path
        d="M96.45,32.05l4.09-.44a59.27,59.27,0,0,1,.42,8.21c-.06,17.66,0,35.34-.06,53-.63,5.41,4.39,10.47,6.64,15.24,1.16,4.16,2,8.4,3.18,12.57-4-6.51-7.79-13.09-11.69-19.63a10.56,10.56,0,0,1-1.84-6.17c0-16.28,0-32.73,0-49C97.16,41.34,97.59,36.38,96.45,32.05Z"
        fill="#004762"
      />
      <path
        d="M68.73,32.07a96.17,96.17,0,0,0,12.18,1.81q-.24,49.53,0,99.07a17.41,17.41,0,0,1-8.15.18c-4.37-1-7.62-5-11.72-6.09-3.54-.72-7.52-.14-11.08.33,4.86-7.83,9.33-15.9,14.13-23.77,1.67-2.86,3.47-5.25,3.29-8.76,0-19,0-38.05-.05-57A9.74,9.74,0,0,1,68.73,32.07Z"
        fill="#fbafbc"
      />
      <path
        d="M126.6,37.33c4.59.8,9,2,13.66,2,5.42.19,10.47-1,15.73-2.1.44,5.46.24,11.06.27,16.55q0,32,0,64c0,3.6.19,7.41-.44,11a14.87,14.87,0,0,1-26.06,6.54c-2.79-3.29-3.52-7.28-3.52-11.48q0-36.51,0-73A116.64,116.64,0,0,1,126.6,37.33Z"
        fill="#024d6a"
      />
      <path
        d="M130,41.82A100.78,100.78,0,0,0,140.54,43q.17,7.92.11,15.84c-.07,7.41.51,14.52-.8,21.88-3.68-2.33-6.21-.9-9.79.43l-.15-4.49a44.4,44.4,0,0,0,9.06-.82,49.36,49.36,0,0,0-9-.87c0-1.43,0-2.85,0-4.28a47.35,47.35,0,0,0,9-.85,45.74,45.74,0,0,0-9-.85v-4.3a46.62,46.62,0,0,0,9-.85,45.84,45.84,0,0,0-9-.84V58.67a46.7,46.7,0,0,0,9-.85,46.7,46.7,0,0,0-9-.85C130,51.92,130,46.87,130,41.82Z"
        fill="#fbafbc"
      />
      <path
        d="M152.57,41.8c0,14.1,0,28.19,0,42.28-2.53.56-5.68,1.72-8.22.94-1.84-.78-3.18-2.9-4.53-4.32,1.31-7.36.73-14.47.8-21.88q0-7.92-.11-15.84A85.09,85.09,0,0,0,152.57,41.8Z"
        fill="#f9a394"
      />
      <path
        d="M190.23,71.77l4.38-.42c.14,1.14.27,2.29.41,3.43,2.78,1.21,3.5,1.43,4.67,4.32l-4.73.32-.21,4-3.39,0c-.23-1.34-.45-2.68-.68-4l-3.8-.25c0-1.23,0-2.45,0-3.67l4.56-.1Q190.83,73.58,190.23,71.77Z"
        fill="#f9a394"
      />
      <path
        d="M10.84,90.05a42.63,42.63,0,0,1,.83,4.78c1.91.45,5-.06,6,2,.13,3.58-3.59,2.59-6,3.23-.37,1.76-.27,4.16-1.45,5.59a28.66,28.66,0,0,1-3.59-.13c0-1.79-.06-3.57-.07-5.36C4,99.57,1.72,100.1,0,97.82c.73-3,2.66-2.34,5.11-2.91l1.14-.16a37.93,37.93,0,0,1,.91-5.09C8.29,88.58,10.08,88.5,10.84,90.05Z"
        fill="#487488"
      />
      <path
        d="M107.71,175.08c2.74-2.48,2.86,2.43,3.59,4.07a43.36,43.36,0,0,1,4.74,1.48c-.1,3.27-2.91,2.24-5.22,3,0,1.22.05,2.44.07,3.66-.57.22-1.15.43-1.72.63l-2,0c-.07-1.42-.15-2.83-.23-4.24a22.5,22.5,0,0,1-5.36-1.72c.7-2.68,2.87-2.08,5-2.81C107,177.78,107.26,176.43,107.71,175.08Z"
        fill="#487488"
      />
    </svg>
  </IconContainer>
);

export default Beaker;
