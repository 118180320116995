import React from 'react';
import { Card, Flex, Box, Heading, Button } from 'rebass';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import TextInput from '../../components/TextInput';
import { MaskInput } from '../../components/MaskInput';
import RegionSelect from '../../components/RegionSelect';
import withSession from '../../lib/withSession';
import { UPDATE_PROFILE } from '../../graphql';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';

const CardBox = styled(Card)`
  width: 100%;
  padding: 32px;
`;
const ContactInfoForm = props => {
  // console.log('SES', props.session);
  const handleUpdate = async (values, actions) => {
    const { toastManager, refetch } = props;
    // console.log('PROPS VAL', values);
    actions.setSubmitting(true);
    try {
      if (values.email !== props.email) {
        await props.client.mutate({
          mutation: UPDATE_PROFILE,
          variables: {
            firstName: values.firstName,
            lastName: values.lastName,
            street1: values.address.street1,
            street2: values.address.street2,
            city: values.address.city,
            state: values.address.state,
            zip: values.address.zip,
            phone: values.phone,
          },
        });
      }
      await refetch();
      actions.setSubmitting(false);
      //   actions.resetForm();
      toastManager.add('Updated successfully', { appearance: 'success' });
    } catch (err) {
      console.log('ERR', err);
      actions.setSubmitting(false);
      toastManager.add('Error updating, please try again', {
        appearance: 'error',
      });
    }
  };
  return (
    <CardBox mb={4}>
      <Flex flexDirection="column">
        <Box>
          <Heading textAlign="left" mb={4}>
            Contact Information
          </Heading>
          <Formik
            initialValues={{
              ...props.session,
            }}
            // validationSchema={schema}
            onSubmit={(values, actions) => handleUpdate(values, actions)}
            render={({
              touched,
              errors,
              isValid,
              values,
              isSubmitting,
              handleChange,
              handleBlur,
            }) => (
              <Form>
                <FormField mb={3} error={touched.firstName && errors.firstName}>
                  <TextInput
                    placeholder="First Name"
                    type="text"
                    name="firstName"
                  />
                </FormField>
                <FormField mb={3} error={touched.lastName && errors.lastName}>
                  <TextInput
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                  />
                </FormField>

                <FormField mb={3} error={touched.street1 && errors.street1}>
                  <TextInput
                    placeholder="Street 1"
                    type="text"
                    name="address.street1"
                  />
                </FormField>

                <FormField mb={3} error={touched.street2 && errors.street2}>
                  <TextInput
                    placeholder="Street 2"
                    type="text"
                    name="address.street2"
                  />
                </FormField>

                <FormField mb={3} error={touched.city && errors.city}>
                  <TextInput
                    placeholder="City"
                    type="text"
                    name="address.city"
                  />
                </FormField>

                <FormField mb={3} error={touched.state && errors.state}>
                  <RegionSelect
                    name="address.state"
                    value={values.address.state}
                  />
                </FormField>

                <FormField mb={3} error={touched.zip && errors.zip}>
                  <TextInput placeholder="Zip" type="text" name="address.zip" />
                </FormField>

                <FormField mb={4} error={touched.phone && errors.phone}>
                  <MaskInput
                    name="phone"
                    placeholder="Mobile Phone Number"
                    mask="999-999-9999"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>

                <Flex alignItems="center" flexDirection="column">
                  <Button
                    disabled={!isValid || isSubmitting}
                    variant="primary"
                    width="200px"
                    type="submit"
                  >
                    Update
                  </Button>
                </Flex>
              </Form>
            )}
          />
        </Box>
      </Flex>
    </CardBox>
  );
};

export default compose(
  withApollo,
  withSession,
  withToastManager
)(ContactInfoForm);
