import React, { Component } from 'react';
import { Box, Flex, Text, Link as ExternalLink } from 'rebass';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';

import ChangePassword from './account/ChangePassword';
import Profile from './account/Profile';
import withSession from '../lib/withSession';
import MedicationHistoryForm from './profileSections/medicationHistory'

const Container = styled(Box)`
  width: 100%;
  max-width: 850px;
`;

const StyledLink = styled(ExternalLink)`
  color: #000;
`;

class Account extends Component {
  handleUpdate = async (values, actions, mutation) => {
    const { toastManager, refetch } = this.props;
    actions.setSubmitting(true);

    try {
      await this.props.client.mutate({
        mutation,
        variables: {
          ...values,
        },
      });

      await refetch();
      actions.resetForm();
      toastManager.add('Updated successfully', { appearance: 'success' });
    } catch (err) {
      actions.setSubmitting(false);
      toastManager.add('Error updating, please try again', {
        appearance: 'error',
      });
    }
  };

  render() {
    const { session } = this.props;

    return (
      <Flex m={[3, 3, 5]} flex={1} flexDirection="column" alignItems="center">
        <Container>
          <Flex mb={4}>
            <ChangePassword onSubmit={this.handleUpdate} />
          </Flex>

          <Flex mb={4}>
            <Profile session={session} />
          </Flex>

          <Text textAlign="center" mb={4}>
            If you need to change your address, please&nbsp;
            <StyledLink href="mailto:contact@palomahealth.com">
              contact us
            </StyledLink>
          </Text>
          <MedicationHistoryForm />
        </Container>
      </Flex>
    );
  }
}

export default compose(
  withApollo,
  withToastManager
)(withSession(Account));
