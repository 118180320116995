import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import SpinLoader from './SpinLoader';
import FullLayout2 from './FullLayout';
import PleaseWait from './PleaseWait';
import useUnmount from '../utils/hooks/lifecycle/useUnmount';
import TextTransition, { presets } from 'react-text-transition';
import Header from './Header';

const blink = keyframes`
  50% {color: transparent;}
`;

export const Dot = styled.span`
  animation: 1s ${blink} infinite;
  &:nth-child(1) {
    animation-delay: 0ms;
  }
  &:nth-child(2) {
    animation-delay: 250ms;
  }
  &:nth-child(3) {
    animation-delay: 500ms;
  }
`;
const Container = styled(Box)`
  max-width: 1024px;
  width: 75%;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
`;
const TEXTS = [
  "1/5 Creating your refill request",
  "2/5 Building profile of your thyroid health history",
  "3/5 Saving your medication history",
  "4/5 Uploading your lab results",
  "5/5 Validating your preferred pharmacy and insurance information",
  ];
const RotatingText = props => {
  const [index, setIndex] = React.useState(0);
  const [intervalId, setIntervalId] = React.useState(null);
  const [arr, setArr] = React.useState(
    TEXTS
  );

  React.useEffect(() => {
    const handle = setInterval(
      () => setIndex(index => index + 1),
      10000 // every 10 seconds
    );
    setIntervalId(handle);
  }, []);

  useUnmount(() => {
    // console.log('unmount', intervalId);
    if (intervalId) {
      clearInterval(intervalId);
    }
  });

  return (
    <Box
      style={{
        whiteSpace: 'nowrap',
        marginTop: '40px',
        // width: '100%',
        maxWidth: '350px',
        justifyContent: 'center',
      }}
    >
      <TextTransition
        noOverflow
        style={{
          whiteSpace: 'nowrap',
          wordWrap: 'break-word',
        }}
        text={index<arr.length?arr[index]:'Finalizing...'}
        springConfig={presets.default}
      />
    </Box>
  );
};

export const PleaseWaitRotatingText = ({ text, ...props }) => {
  return (
    <FullLayout2
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
      }}
    >
      <Container>
        <Flex flex={1}>
          <Header dark={false} />
        </Flex>
        {/* <Text fontSize="18px" fontWeight="500" textAlign="center" mt="-32px" mb="32px" style={{maxWidth: '80px'}}>
          {text}
        </Text> */}
        <PleaseWait
          text={text}
          spinner={{
            sColor: '#ECEFF2',
            pColor: '#909FB5',
          }}
        >
          <RotatingText />
        </PleaseWait>
      </Container>
    </FullLayout2>
  );
};

PleaseWaitRotatingText.propTypes = {
  text: PropTypes.string,
};

PleaseWaitRotatingText.defaultProps = {
  text: 'Please Wait',
};

export default PleaseWaitRotatingText;
