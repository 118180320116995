import React, { Component } from 'react';
import { Button, Card, Flex, Box, Heading, Text } from 'rebass';
import moment from 'moment';
import styled from 'styled-components';

import { Formik, Form } from 'formik';
import { withToastManager } from 'react-toast-notifications';
import { withWizard } from 'react-albus';
import * as card from 'card-validator';

import { FormField } from '../../components/FormField';
import { TextInput } from '../../components/TextInput';
import CheckboxInput from '../../components/CheckboxInput';
import PlacesAutocomplete from '../../components/addressAutoComplete/PlacesAutocomplete';
import InformationSecuredDisclaimer from '../../components/InformationSecuredDisclaimer';
import { MaskInput } from '../../components/MaskInput';

class CreditCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      billingSameAsShiping: true,
    };
  }
  render() {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Box mr={[0, 0, 0]} mb={4} style={{ maxWidth: '600px' }}>
          <Card p={4} style={{ maxWidth: '600px' }}>
            <Flex flexDirection="column" mb={4}>
              <Heading textAlign="center" style={{ fontWeight: 'bold' }} mb={2}>
                Payment Method
              </Heading>
              <Text textAlign="center" fontWeight={300}>
                We accept HSA and FSA cards
                <br />
                Entering payment information holds your spot.
              </Text>
            </Flex>

            <Formik
              initialValues={{
                nameoncard: '',
                cardnumber: '',
                cvc2: '',
                expirationdate: '',
                zip: '',
                billingSameAsShiping: true,
              }}
              // validationSchema={
              //   this.state.billingSameAsShiping
              //     ? schema
              //     : schemaWithBilling
              // }
              validate={(values, props) => {
                // console.log('validate', { values, props });
                let errors = {};
                if (!values.cardnumber || values.cardnumber === '') {
                  errors.cardnumber = 'Required';
                } else {
                  if (!card.number(values.cardnumber).isValid) {
                    errors.cardnumber = 'Credit Card number is invalid';
                  }
                }
                if (!values.cvc2 || values.cvc2 === '') {
                  errors.cvc2 = 'Required';
                } else {
                  if (
                    card.number(values.cvc2) &&
                    card.number(values.cvc2).card &&
                    card.number(values.cvc2).card.code &&
                    card.number(values.cvc2).card.code.size
                  ) {
                    if (
                      !card.cvv(
                        values.cvc2,
                        card.number(values.cardnumber).card.code.size
                      )
                    ) {
                      errors.cardnumber = 'CVC is invalid';
                    }
                  } else {
                    if (!card.cvv(values.cvc2, 3)) {
                      errors.cardnumber = 'CVC is invalid';
                    }
                  }
                }

                if (!values.expirationdate || values.expirationdate === '') {
                  errors.expirationdate = 'Required';
                } else {
                  if (!card.expirationDate(values.expirationdate).isValid) {
                    errors.expirationdate = 'Expiration date is invalid';
                  }

                  if (
                    values.expirationdate.length < 3 ||
                    values.expirationdate.indexOf('/') < 1
                  ) {
                    errors.expirationdate = 'Expiration date is invalid';
                  }
                }

                if (!this.state.billingSameAsShiping) {
                  if (
                    !values.originalAddress ||
                    values.originalAddress === ''
                  ) {
                    errors.street1 = 'Required';
                  }
                  if (!values.street1 || values.street1 === '') {
                    errors.street1 = 'Required';
                  }
                }

                return errors;
              }}
              onSubmit={async (values, actions) => {
                //   const aa = document.getElementsByClassName('pac-container');
                //   if (aa) {
                //     for (let index = 0; index < aa.length; index++) {
                //       const element = aa[index];
                //       // console.log(element.style);
                //       if (element.style && element.style.display !== 'none') {
                //         actions.setSubmitting(false);
                //         return;
                //       }
                //     }
                //   }
                // console.log('OnSubmit');
                this.props.onComplete(values, actions);
              }}
              render={({
                values,
                errors,
                touched,
                isValid,
                isSubmitting,
                handleChange,
                validateForm,
                handleBlur
              }) => (
                <Form>
                  <Flex flexDirection="column">
                    <FormField
                      error={touched.cardnumber && errors.cardnumber}
                      mb={3}
                    >
                      <label for="cardnumber">Credit Card Number</label>
                      <TextInput name="cardnumber" />
                    </FormField>

                    <Flex flexDirection={['column', 'column', 'row']} mb={4}>
                      <FormField
                        width={[1, 1, 1 / 2]}
                        mr={[0, 0, 3]}
                        error={touched.expirationdate && errors.expirationdate}
                      >
                        <label for="expirationdate">Expiration Date</label>
                        {/* <TextInput
                          name="expirationdate"
                          placeholder={`e.g.: ${moment().month() + 1}/${moment()
                            .year()
                            .toString()
                            .substr(moment().year().length - 2)}`}
                        /> */}
                        <MaskInput
                            name="expirationdate"
                            mask="99/99"
                            value={values.expirationdate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id='expirationdate'
                          />
                      </FormField>

                      <FormField
                        width={[1, 1, 1 / 2]}
                        mr={[0, 0, 0]}
                        error={touched.cvc2 && errors.cvc2}
                      >
                        <label for="cvc2">CVC</label>
                        <TextInput name="cvc2" />
                      </FormField>
                    </Flex>
                  </Flex>

                  <label for="billingSameAsShiping">Billing Address</label>
                  <FormField flexDirection="row" mb={4}>
                    <CheckboxInput
                      name="billingSameAsShiping"
                      onChanged={option => {
                        this.setState({
                          billingSameAsShiping: !option,
                        });
                      }}
                      styles={{
                        checked: {
                          Box: {
                            backgroundColor: 'rgb(54, 79, 121)',
                          },
                        },
                      }}
                    />
                    <Text fontSize={1}>Same as shipping address</Text>
                  </FormField>
                  {!this.state.billingSameAsShiping && (
                    <FormField
                      mb={3}
                      error={
                        touched.address &&
                        (errors.street1 ||
                          errors.city ||
                          errors.zip ||
                          errors.state)
                          ? errors.street1 ||
                            errors.city ||
                            errors.zip ||
                            errors.state ||
                            ''
                          : null
                      }
                    >
                      <PlacesAutocomplete
                        fieldname="address"
                        handleChange={handleChange}
                        street1FieldName="street1"
                        cityFieldName="city"
                        zipFieldName="zip"
                        stateFieldName="state"
                      />
                    </FormField>
                  )}

                  <Text textAlign="center" fontWeight={300} mb={3}>
                    We will not charge you until after your appointment
                    completes.
                  </Text>
                  <Flex alignItems="center" flexDirection="column">
                    <Button
                      disabled={!isValid || isSubmitting}
                      variant="pink"
                      width={[1, 1 / 2]}
                      type="submit"
                    >
                      Save Card
                    </Button>
                  </Flex>

                  <InformationSecuredDisclaimer />
                </Form>
              )}
            />
          </Card>
        </Box>
      </Flex>
    );
  }
}

export default CreditCard;
