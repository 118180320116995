import React from 'react';
import { Query } from 'react-apollo';
import { Box, Button, Flex, Heading } from 'rebass';
import styled from 'styled-components';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import WorkoutAuthors from './adminWorkout/WorkoutAuthors';
import Workouts from './adminWorkout/Workouts';
import WorkoutCategories from './adminWorkout/WorkoutCategories';
import WorkoutDifficulties from './adminWorkout/WorkoutDifficulties';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;
const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;
const AdminWorkouts = () => {
  return (
    <Container>
      <Heading flex={1}>WORKOUTS MANAGEMENT</Heading>
      <WorkoutCategories />
      <WorkoutDifficulties />
      <WorkoutAuthors />
      <Workouts />
    </Container>
  );
};

export default AdminWorkouts;
