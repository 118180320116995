import React, { Fragment, Component } from 'react';
import { Flex, Text, Button } from 'rebass';
import styled, { css } from 'styled-components';
import { Route, NavLink as NavLinkRouter, withRouter, Redirect } from 'react-router-dom';
import moment from 'moment-timezone';
import { Query, compose } from 'react-apollo';

import { APPOINTMENTS, ADMIN_GET_APPOINTMENTS_LIST } from '../graphql';
import SpinLoader from '../components/SpinLoader';
import ConsultationDetail from './ConsultationDetail';
import { Stethoscope } from '../components/icons';
import PillIcon from '../static/pillsIcon.png';
import ConsultationDetailAdmin from './ConsultationDetailAdmin';
import NoAppointment from './consultations/NoAppointment';
import withSession from '../lib/withSession';
import { isToggleActive } from '../components/featureToggle/toggles';

const ListContainer = styled(Flex)`
  min-width: 300px;
  border-right: 1px solid #ccc;
  width: 100%;
  overflow-y: auto;
  &.selected {
    display: none;
  }

  @media screen and (min-width: 68.8125em) {
    width: auto;
    &.selected {
      display: flex;
    }
  }
`;

const DetailContainer = styled(Flex)`
  display: none;
  overflow-y: auto;
  /* padding-bottom: 64px; */
  &.selected {
    display: flex;
  }

  @media screen and (min-width: 68.8125em) {
    display: flex;
  }
`;

const Inner = styled(Flex)`
  max-width: 650px;
`;

const NavLink = styled(NavLinkRouter)`
  text-decoration: none;
  color: #355078;
  display: flex;
  align-items: center;
  background: #fdf5f2;
  width: 100%;
  min-height: 100px;
  transition: all 300ms ease;
  &.active {
    background: rgba(180, 205, 244, 0.5);
  }
  &:hover {
    background: rgba(180, 205, 244, 0.5);
  }
`;
const NoNavLink = styled.div`
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  background: transparent;
  width: 100%;
  min-height: 50px;
  transition: all 300ms ease;
`;
const Section = styled.div`
  color: #000;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  transition: all 300ms ease;
  &.active {
    background: rgba(180, 205, 244, 0.5);
  }
`;

const ListItem = styled(Flex)`
  border-bottom: 1px solid #ccc;
`;

const Avatar = styled(Flex)`
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1 0 auto;
  background: ${(props) => (props.disabled ? props.theme.bg.wash : 'white')};
  font-weight: 400;
  font-size: 16px;
  border: 1px solid ${(props) => (props.disabled ? props.theme.bg.border : props.theme.bg.inactive)};
  border-radius: 10px;
  padding: 18px 12px;
  box-shadow: none;
  ${(props) =>
    props.type === 'checkbox' &&
    css`
      flex: initial;
      width: initial;
      margin-right: 0.5em;
    `} &::placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:-moz-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:-ms-input-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:focus {
    border-color: ${(props) => props.theme.brand.default};
    outline: none;
  }
  &[type='file'] {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: hidden;
  }
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
`;

class Consultations extends Component {
  state = {
    searchPatientId: '',
  };
  extractTzFromAppt = (appointment) => {
    const splt = appointment.inviteLocation.split(' ');
    return splt.find((x) => x.startsWith('America'));
  };

  render() {
    const {
      session: { email, diagnoseFull, eligibility, adm },
      history,
    } = this.props;
    const { searchPatientId } = this.state;
    const t = isToggleActive('PERSONAL_ADMIN');

    const selected = !this.props.match.isExact ? 'selected' : null;

    if (!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm) {
      return (
        <Query query={ADMIN_GET_APPOINTMENTS_LIST} fetchPolicy="cache-and-network">
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <Flex flex={1} justifyContent="center" alignItems="center">
                  <SpinLoader />
                </Flex>
              );
            }
            if (error) {
              return <Flex flex={1} justifyContent="center" alignItems="center" />;
            }
            console.log('DUMP APPOINTMENTS', { data });
            return (
              <Flex flex={1} style={{ overflowY: 'hidden' }}>
                <ListContainer className={selected} flexDirection="column">
                  <Fragment>
                    <StyledInput
                      placeholder="Search by patient id"
                      style={{ flex: 0 }}
                      value={searchPatientId}
                      type="search"
                      onChange={(x) => {
                        // console.log('X', x);
                        this.setState({
                          searchPatientId: x.target.value,
                        });
                      }}
                    />
                    {data.adminListAppointments
                      .filter((x) => {
                        if (!searchPatientId) return true;

                        return searchPatientId.toString() === x.patientId.toString();
                      })
                      .sort((a, b) => {
                        if (moment(a.startDate).isBefore(b.startDate)) return 1;
                        if (moment(a.startDate).isAfter(b.startDate)) return -1;

                        return 0;
                      })
                      .map((appointment) => (
                        <ListItem key={appointment.appointmentId}>
                          <NavLink to={`/consultations/${appointment.appointmentId}`}>
                            <Flex p={3} alignItems="center" style={{ position: 'relative', width: '100%' }}>
                              <Flex mr={3}>
                                <Avatar>
                                  {appointment.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                                  appointment.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID ? (
                                    <Stethoscope height={45} width={45} />
                                  ) : (
                                    <img src={PillIcon} height="45px" width="45px" alt="" />
                                  )}
                                </Avatar>
                              </Flex>
                              <Flex flexDirection="column">
                                <Text mb={1} fontSize={2}>
                                  {`Patient: ${appointment.patientId}`}
                                </Text>
                                <Text mb={1} fontSize={2}>
                                  {`Provider: ${appointment.providerId}`}
                                </Text>
                                <Text fontSize={1} color="grey">
                                  {`${moment.tz(appointment.startDate, this.extractTzFromAppt(appointment)).format('ddd MMM Do YYYY')} ${moment
                                    .tz(appointment.startDate, this.extractTzFromAppt(appointment))
                                    .format('h:mma')}`}
                                </Text>
                              </Flex>
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '0px',
                                  right: '0px',
                                  background: appointment.mustCompleteRegistration ? '#b00412' : '#344f79',
                                  display: appointment.isZocDoc ? '' : 'none',
                                }}
                              >
                                <Text
                                  fontSize="12px"
                                  color="white"
                                  style={{
                                    margin: '4px',
                                    color: 'white',
                                  }}
                                >
                                  <span>ZocDoc</span>
                                </Text>
                              </div>
                            </Flex>
                          </NavLink>
                        </ListItem>
                      ))}
                  </Fragment>
                </ListContainer>
                <DetailContainer flex={1} justifyContent="center" className={selected}>
                  <Inner flex={1} flexDirection="column" pt={[0, 0, 0]}>
                    <Route path="/consultations/:id" component={(props) => <ConsultationDetailAdmin bypassPatientId={true} appointments={data.adminListAppointments} {...props} />} />
                  </Inner>
                </DetailContainer>
              </Flex>
            );
          }}
        </Query>
      );
    }

    return (
      <Query query={APPOINTMENTS} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <Flex flex={1} justifyContent="center" alignItems="center">
                <SpinLoader />
              </Flex>
            );
          }

          if (error) {
            return (
              <Flex flex={1} justifyContent="center" alignItems="center">
                <Text fontSize={5} fontWeight={600} textAlign="center">
                  Unable to retrieve visit. Please try again.
                </Text>
              </Flex>
            );
          }

          const { appointments } = data;

          if (appointments.length === 0) {
            if (this.props.location.pathname !== '/consultations') {
              return <Redirect to={{ pathname: '/consultations' }} />;
            }
            return <NoAppointment {...this.props} />;
          }
          const futureAppointments = appointments.filter((item) => {
            if (item.status && (item.status === 'f')) {
              const dt = moment(`${item.date} ${item.startTime}`, 'MM/DD/YYYY HH:mm').tz(item.timezoneName);
              if (item.appointmentTypeId === window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID || item.appointmentTypeId === window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID) {
                return true;
              }

              return dt.isAfter(moment().add(-120, 'minutes'), 'minute');
            }
            return false;
          });
          const pendingAppointments = appointments.filter((item) => {
            if (item.status && (item.status === '2')) {
              if (item.appointmentTypeId === window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID || item.appointmentTypeId === window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID) {
                return false;
              }
              const isInFutureIndex = futureAppointments.findIndex((x) => x.id === item.id);

              return isInFutureIndex < 0;
            }
            return false;
          });
          return (
            <Flex style={{ overflowY: 'hidden' }} flex={1}>
              <ListContainer className={selected} flexDirection="column">
                {!diagnoseFull.hasAppointment ? (
                  <>
                    {futureAppointments.length < 1 && pendingAppointments.length<1 && (eligibility.consultation || eligibility.canBookAsync) ? (
                      <Button variant="primary" style={{ margin: '20px 20px' }} onClick={() => history.push('/schedule-split')}>
                        Book a Visit
                      </Button>
                    ) : (
                      <>
                        {futureAppointments.filter((item) => {
                          return (
                            item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                            item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID
                          );
                        }).length < 1 && pendingAppointments.filter((item) => {
                          return (
                            item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                            item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID
                          );
                        }).length < 1 && eligibility.consultation ? (
                          <Button variant="primary" style={{ margin: '20px 20px' }} onClick={() => history.push('/schedule')}>
                            Book a Visit
                          </Button>
                        ) : null}
                      </>
                    )}
                    {!!futureAppointments.length > 0 ? (
                      <Section>
                        <Text mb={3} fontSize="18px" fontWeight="500" ml="20px" color="#344f79">
                          Upcoming Visits
                        </Text>
                      </Section>
                    ) : null}
                    {!!futureAppointments.length > 0 &&
                      futureAppointments
                        .sort((a, b) => {
                          if (moment(`${a.date} ${a.startTime}`, 'MM/DD/YYYY HH:mm').isBefore(`${b.date} ${b.startTime}`, 'MM/DD/YYYY HH:mm')) return 1;
                          if (moment(`${a.date} ${a.startTime}`, 'MM/DD/YYYY HH:mm').isAfter(`${b.date} ${b.startTime}`, 'MM/DD/YYYY HH:mm')) return -1;

                          return 0;
                        })
                        .map((appointment) => {
                          let tiz;
                          try {
                            tiz = moment(`${appointment.date} ${appointment.startTime}`, 'MM/DD/YYYY HH:mm')
                              .tz(appointment.timezoneName)
                              .zoneAbbr();
                          } catch (error) {}
                          return (
                            <ListItem key={appointment.id}>
                              <NavLink to={`/consultations/${appointment.id}`}>
                                <Flex p={3} alignItems="center">
                                  <Flex mr={3}>
                                    <Avatar>
                                      {appointment.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                                      appointment.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID ? (
                                        <Stethoscope height={45} width={45} />
                                      ) : (
                                        <img src={PillIcon} height="45px" width="45px" alt="" />
                                      )}
                                    </Avatar>
                                  </Flex>

                                  <Flex flexDirection="column">
                                    <Text mb={1} fontSize={'16px'} color="#344f79">
                                      {appointment.provider.displayName}
                                    </Text>
                                    <Text fontSize={1} color="grey">
                                      {appointment.appointmentTypeId === window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID ||
                                      appointment.appointmentTypeId === window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID
                                        ? 'Pending Review'
                                        : `${moment(appointment.date, 'MM/DD/YYYY').format('ddd MMM Do YYYY')} ${tiz} ${moment(appointment.startTime, 'HH:mm').format('h:mma')} ${tiz}`}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </NavLink>
                            </ListItem>
                          );
                        })}
                  </>
                ) : null}

                {!!pendingAppointments &&
                  pendingAppointments.length > 0 && (
                    <>
                      <Section>
                        <Text mb={1} fontSize="18px" fontWeight="500" ml="20px" color="#344f79">
                          Pending Visits
                        </Text>
                      </Section>
                      {pendingAppointments
                        .sort((a, b) => {
                          if (moment(`${a.date} ${a.startTime}`, 'MM/DD/YYYY HH:mm').isBefore(`${b.date} ${b.startTime}`, 'MM/DD/YYYY HH:mm')) return 1;
                          if (moment(`${a.date} ${a.startTime}`, 'MM/DD/YYYY HH:mm').isAfter(`${b.date} ${b.startTime}`, 'MM/DD/YYYY HH:mm')) return -1;

                          return 0;
                        })
                        .map((appointment) => {
                          let tiz;
                          try {
                            tiz = moment(`${appointment.date} ${appointment.startTime}`, 'MM/DD/YYYY HH:mm')
                              .tz(appointment.timezoneName)
                              .zoneAbbr();
                          } catch (error) {}
                          return (
                            <ListItem key={appointment.id}>
                              <NavLink to={`/consultations/${appointment.id}`}>
                                <Flex p={3} alignItems="center">
                                  <Flex mr={3}>
                                    <Avatar>
                                      {appointment.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                                      appointment.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID ? (
                                        <Stethoscope height={45} width={45} />
                                      ) : (
                                        <img alt="" src={PillIcon} height="45px" width="45px" />
                                      )}
                                    </Avatar>
                                  </Flex>
                                  <Flex flexDirection="column">
                                    <Text mb={1} fontSize={'16px'} color="#344f79">
                                      {appointment.provider.displayName}
                                    </Text>
                                    <Text fontSize={1} color="grey">
                                      {appointment.appointmentTypeId === window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID ||
                                      appointment.appointmentTypeId === window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID
                                        ? `${moment(appointment.date, 'MM/DD/YYYY').format('ddd MMM Do YYYY')}`
                                        : `${moment(appointment.date, 'MM/DD/YYYY').format('ddd MMM Do YYYY')} ${moment(appointment.startTime, 'HH:mm').format('h:mma')} ${tiz}`}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </NavLink>
                            </ListItem>
                          );
                        })}
                    </>
                  )}

                <Section>
                  <Text mb={1} fontSize="18px" fontWeight="500" ml="20px" color="#344f79">
                    Past Visits
                  </Text>
                </Section>
                {appointments.filter((item) => {
                  return item.status && (item.status === '4' || item.status === '3');
                }).length < 1 ? (
                  <NoNavLink>
                    <Text mb={1} fontSize="18px" fontWeight="400" color="#8e8e8e" ml="20px">
                      no past visit
                    </Text>
                  </NoNavLink>
                ) : (
                  appointments
                    .filter((item) => {
                      return item.status && (item.status === '4' || item.status === '3');
                    })
                    .sort((a, b) => {
                      if (moment(`${a.date} ${a.startTime}`, 'MM/DD/YYYY HH:mm').isBefore(`${b.date} ${b.startTime}`, 'MM/DD/YYYY HH:mm')) return 1;
                      if (moment(`${a.date} ${a.startTime}`, 'MM/DD/YYYY HH:mm').isAfter(`${b.date} ${b.startTime}`, 'MM/DD/YYYY HH:mm')) return -1;

                      return 0;
                    })
                    .map((appointment) => {
                      let tiz;
                      try {
                        tiz = moment(`${appointment.date} ${appointment.startTime}`, 'MM/DD/YYYY HH:mm')
                          .tz(appointment.timezoneName)
                          .zoneAbbr();
                      } catch (error) {}
                      return (
                        <ListItem key={appointment.id}>
                          <NavLink to={`/consultations/${appointment.id}`}>
                            <Flex p={3} alignItems="center">
                              <Flex mr={3}>
                                <Avatar>
                                  {appointment.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                                  appointment.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID ? (
                                    <Stethoscope height={45} width={45} />
                                  ) : (
                                    <img alt="" src={PillIcon} height="45px" width="45px" />
                                  )}
                                </Avatar>
                              </Flex>
                              <Flex flexDirection="column">
                                <Text mb={1} fontSize={'16px'} color="#344f79">
                                  {appointment.provider.displayName}
                                </Text>
                                <Text fontSize={1} color="grey">
                                  {appointment.appointmentTypeId === window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID ||
                                  appointment.appointmentTypeId === window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID
                                    ? `${moment(appointment.date, 'MM/DD/YYYY').format('ddd MMM Do YYYY')}`
                                    : `${moment(appointment.date, 'MM/DD/YYYY').format('ddd MMM Do YYYY')} ${moment(appointment.startTime, 'HH:mm').format('h:mma')} ${tiz}`}
                                </Text>
                              </Flex>
                            </Flex>
                          </NavLink>
                        </ListItem>
                      );
                    })
                )}
              </ListContainer>
              <DetailContainer flex={1} justifyContent="center" className={selected} id="testContainer">
                <Inner flex={1} flexDirection="column" pt={[0, 0, 0]}>
                  <Route path="/consultations/:id" component={(props) => <ConsultationDetail appointments={appointments} {...props} />} />
                </Inner>
              </DetailContainer>
            </Flex>
          );
        }}
      </Query>
    );
  }
}

export default compose(
  withSession,
  withRouter
)(Consultations);
