import React from 'react';
import { withRouter } from 'react-router';
import { Box, Text } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../../../components/FullLayout';
import Logo from '../../../components/Logo';
import PausePeriod from './stepsSubscriptions/PausePeriod';
import { Query } from 'react-apollo';
import { GET_MEMBERSHIPS } from '../../../graphql';
import PleaseWait from '../../../components/PleaseWait';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
`;

const LogoContainer = styled(Box)`
  max-width: 200px;
  margin: 0 auto 32px auto;
`;

const PauseVitamins = ({ history }) => {
  return (
    <FullLayout2 style={{ zIndex: 10 }}>
      <Container>
        <LogoContainer>
          <Logo dark={false} />
        </LogoContainer>
        <Query query={GET_MEMBERSHIPS} fetchPolicy="network-only">
          {({ loading, data, error }) => {
            if (loading) return <PleaseWait style={{ position: 'absolute', left: '50%', top: '50%' }} />;

            if (!!error) {
              return <Text>An error occured</Text>;
            }

            if (!data || !data.getMemberships.memberships || data.getMemberships.memberships.length < 1) {
              return <Text>No Subscription found</Text>;
            }

            const vitaminsData = data.getMemberships.memberships.find((x) => x.subscriptionTarget === 'SUBSCRIPTION_VITAMINS');

            if (!vitaminsData) {
              history.push('/my-subscriptions');
              return null;
            }

            return <PausePeriod subscriptionId={vitaminsData.id} type={'subscription_vitamin'} />;
          }}
        </Query>
      </Container>
    </FullLayout2>
  );
};

const PauseSubscription = ({ location, match, history }) => {
  const typeSub = (location.state || {}).type;
  const subid = (location.state || {}).id;

  if (!subid && !typeSub) {
    return <PauseVitamins history={history} />;
  }

  return (
    <FullLayout2 style={{ zIndex: 10 }}>
      <Container>
        <LogoContainer>
          <Logo dark={false} />
        </LogoContainer>
        <PausePeriod subscriptionId={subid} type={typeSub} />
      </Container>
    </FullLayout2>
  );
};

export default withRouter(PauseSubscription);
