import React from 'react';
import IconContainer from './IconContainer';

const Mail = props => (
  <IconContainer {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16">
      <path
        fill={props.fill ? props.fill : '#000'}
        d="M18,0H2A2,2,0,0,0,0,2V14a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V2A2,2,0,0,0,18,0Zm0,14H2V4l8,5,8-5ZM10,7,2,2H18Z"
      />
    </svg>
  </IconContainer>
);

export default Mail;
