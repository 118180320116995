import React from 'react';
import { withRouter } from 'react-router';
import ScheduleStepper from './ScheduleStepper';
import { Flex } from 'rebass';
import styled from 'styled-components';

const MinWidther = styled(Flex)`
  display: none;

  @media screen and (min-width: 1200px) {
    display: flex;
    flex:1;
    max-width: 650px;
  }
`;
const TopHeaderContent = ({ history }) => {
  // console.log({HH:history})
  if (!history || !history.location || !history.location.pathname) return <div />;
  if (history.location.pathname.indexOf('/schedule/') > -1) {
    return (
      <MinWidther>
        <ScheduleStepper />
      </MinWidther>
    );
  }

  return <div />;
};

export default withRouter(TopHeaderContent);
