import React, { useState } from 'react';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import { Query, Mutation, withApollo } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';
import Select from 'react-select';
import {
  GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES_UT,
  ADD_SYMPTOM_TRACKER_CATEGORY_UT,
  ADD_SYMPTOM_TRACKER_MARKER_UT,
  DELETE_SYMPTOM_TRACKER_MARKER_UT,
  GET_MOBILE_SYMPTOM_TRACKERS_UT,
  ADD_SYMPTOM_TRACKER_LABELS_GROUP_UT,
  GET_SYMPTOM_TRACKER_LABELS_GROUPS_UT,
} from '../graphql';
import ConfirmModal from '../components/ConfirmModal';
import { Input } from '../components/TextInput';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const categoriesColumns = [
  {
    Header: 'name',
    accessor: 'name',
  },
  {
    Header: 'description',
    accessor: 'description',
  },
  {
    Header: 'is active',
    accessor: 'isActive',
  },
  {
    Header: 'color',
    accessor: 'color',
    Cell: row => {
      console.log('ROW', row);
      return (
        <div
          style={{
            backgroundColor: row.value,
            border: '1px solid black',
            width: '24px',
            height: '24px',
            borderRadius: '12px',
            margin: '0 auto',
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      );
    },
  },
  {
    Header: '',
    Cell: (row, rest) => {
      return (
        <Flex flexDirection="row">
          <div
            title="edit"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              console.log({
                row,
                rest,
              });
              row.tdProps.rest.edit(row.original);
            }}
          >
            ✎
          </div>
        </Flex>
      );
    },
  },
];

const labelsColumns = [
  {
    Header: 'labels',
    accessor: 'labels',
    Cell: row => {
      return (
        <div>
          {row.original.labels
            .sort((a, b) => {
              if (a.value < b.value) return -1;
              if (a.value > b.value) return 1;

              return 0;
            })
            .map(x => `${x.value}% ${x.label}`)
            .join(' - ')}
        </div>
      );
    },
  },
  {
    Header: '',
    Cell: (row, rest) => {
      return (
        <Flex flexDirection="row">
          <div
            title="edit"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              console.log({
                row,
                rest,
              });
              row.tdProps.rest.edit(row.original);
            }}
          >
            ✎
          </div>
        </Flex>
      );
    },
  },
];

const LabelsModal = ({ editingItem, onClose, onSave }) => {
  console.log({ editingItem });
  const [topPct0, setTopPct0] = useState(
    editingItem && editingItem.labels && editingItem.labels.length > 0
      ? editingItem.labels[0].value
      : null
  );
  const [topPct1, setTopPct1] = useState(
    editingItem && editingItem.labels && editingItem.labels.length > 1
      ? editingItem.labels[1].value
      : null
  );
  const [topPct2, setTopPct2] = useState(
    editingItem && editingItem.labels && editingItem.labels.length > 2
      ? editingItem.labels[2].value
      : null
  );
  const [topPct3, setTopPct3] = useState(
    editingItem && editingItem.labels && editingItem.labels.length > 3
      ? editingItem.labels[3].value
      : null
  );
  const [topPct4, setTopPct4] = useState(
    editingItem && editingItem.labels && editingItem.labels.length > 4
      ? editingItem.labels[4].value
      : null
  );
  const [topPct5, setTopPct5] = useState(
    editingItem && editingItem.labels && editingItem.labels.length > 5
      ? editingItem.labels[5].value
      : null
  );

  const [label0, setLabel0] = useState(
    editingItem && editingItem.labels && editingItem.labels.length > 0
      ? editingItem.labels[0].label
      : null
  );
  const [label1, setLabel1] = useState(
    editingItem && editingItem.labels && editingItem.labels.length > 1
      ? editingItem.labels[1].label
      : null
  );
  const [label2, setLabel2] = useState(
    editingItem && editingItem.labels && editingItem.labels.length > 2
      ? editingItem.labels[2].label
      : null
  );
  const [label3, setLabel3] = useState(
    editingItem && editingItem.labels && editingItem.labels.length > 3
      ? editingItem.labels[3].label
      : null
  );
  const [label4, setLabel4] = useState(
    editingItem && editingItem.labels && editingItem.labels.length > 4
      ? editingItem.labels[4].label
      : null
  );
  const [label5, setLabel5] = useState(
    editingItem && editingItem.labels && editingItem.labels.length > 5
      ? editingItem.labels[5].label
      : null
  );

  const vars = [];
  if (topPct0 && label0) {
    vars.push({
      label: label0,
      value: parseInt(topPct0, 10),
    });
  }
  if (topPct1 && label1) {
    vars.push({
      label: label1,
      value: parseInt(topPct1, 10),
    });
  }
  if (topPct2 && label2) {
    vars.push({
      label: label2,
      value: parseInt(topPct2, 10),
    });
  }
  if (topPct3 && label3) {
    vars.push({
      label: label3,
      value: parseInt(topPct3, 10),
    });
  }
  if (topPct4 && label4) {
    vars.push({
      label: label4,
      value: parseInt(topPct4, 10),
    });
  }
  if (topPct5 && label5) {
    vars.push({
      label: label5,
      value: parseInt(topPct5, 10),
    });
  }
  return (
    <Mutation
      mutation={ADD_SYMPTOM_TRACKER_LABELS_GROUP_UT}
      refetchQueries={[{ query: GET_SYMPTOM_TRACKER_LABELS_GROUPS_UT }]}
      awaitRefetchQueries={true}
      variables={{
        id: (editingItem || {}).id,
        labels: vars,
      }}
      onCompleted={() => {
        setTopPct0(null);
        setTopPct1(null);
        setTopPct2(null);
        setTopPct3(null);
        setTopPct4(null);
        setTopPct5(null);

        setLabel0('');
        setLabel1('');
        setLabel2('');
        setLabel3('');
        setLabel4('');
        setLabel5('');

        onSave();
      }}
    >
      {(createCategory, { loading }) => (
        <Flex flexDirection="column" width="400px">
          <Flex flexDirection="row">
            <Input
              placeholder="Top Pct"
              value={topPct0}
              type="number"
              onChange={val => {
                setTopPct0(val.target.value);
              }}
            />
            <Input
              placeholder="Label"
              value={label0}
              onChange={val => {
                setLabel0(val.target.value)
              }}
            />
          </Flex>

          <Flex flexDirection="row">
            <Input
              placeholder="Top Pct"
              value={topPct1}
              type="number"
              onChange={val => {
                setTopPct1(val.target.value);
              }}
            />
            <Input
              placeholder="Label"
              value={label1}
              onChange={val => {
                setLabel1(val.target.value);
              }}
            />
          </Flex>

          <Flex flexDirection="row">
            <Input
              placeholder="Top Pct"
              value={topPct2}
              onChange={val => {
                setTopPct2(val.target.value);
              }}
            />
            <Input
              placeholder="Label"
              value={label2}
              onChange={val => {
                setLabel2(val.target.value);
              }}
            />
          </Flex>

          <Flex flexDirection="row">
            <Input
              placeholder="Top Pct"
              type="number"
              value={topPct3}
              onChange={val => {
                setTopPct3(val.target.value);
              }}
            />
            <Input
              placeholder="Label"
              value={label3}
              onChange={val => {
                setLabel3(val.target.value);
              }}
            />
          </Flex>

          <Flex flexDirection="row">
            <Input
              placeholder="Top Pct"
              value={topPct4}
              type="number"
              onChange={val => {
                setTopPct4(val.target.value);
              }}
            />
            <Input
              placeholder="Label"
              value={label4}
              onChange={val => {
                setLabel4(val.target.value);
              }}
            />
          </Flex>

          <Flex flexDirection="row">
            <Input
              placeholder="Top Pct"
              value={topPct5}
              type="number"
              onChange={val => {
                setTopPct5(val.target.value);
              }}
            />
            <Input
              placeholder="Label"
              value={label5}
              onChange={val => {
                setLabel5(val.target.value);
              }}
            />
          </Flex>

          <Flex
            mt={4}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              style={{
                background: 'white',
                color: '#344f79',
                border: '1px solid #344f79',
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              // disabled={!isValid}
              style={{ background: '#344f79' }}
              onClick={() => {
                createCategory();
              }}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      )}
    </Mutation>
  );
};

const CategoryModal = ({
  editingItem,
  existingCategories,
  onClose,
  onSave,
}) => {
  const [name, setName] = useState(editingItem ? editingItem.name : '');
  const [description, setDescription] = useState(
    editingItem ? editingItem.description : ''
  );
  const [color, setColor] = useState(editingItem ? editingItem.color : '');
  const [textLineColor, setTextLineColor] = useState(
    editingItem ? editingItem.textLineColor || '' : ''
  );

  const validate = () => {
    if (!name || !name.trim() || !color) return false;
    if (
      existingCategories.filter(
        x =>
          x.name.toUpperCase() === (name || '').trim().toUpperCase() &&
          x.id !== (editingItem || {}).id
      ).length > 0
    ) {
      return false;
    }

    return true;
  };

  const isValid = validate();
  return (
    <Mutation
      mutation={ADD_SYMPTOM_TRACKER_CATEGORY_UT}
      refetchQueries={[{ query: GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES_UT }]}
      variables={{
        id: (editingItem || {}).id,
        name: name.trim(),
        description: description.trim(),
        color: color.trim(),
        textLineColor: textLineColor.trim(),
        isActive: true,
      }}
      onCompleted={() => {
        setName('');
        setDescription('');
        setColor('');
        setTextLineColor('');

        onSave();
      }}
    >
      {(createCategory, { loading }) => (
        <Flex flexDirection="column" width="400px">
          <Text>Name</Text>
          <Input
            placeholder="Category name*"
            value={name}
            onChange={val => {
              setName(val.target.value);
            }}
          />
          <Text mt={2}>Description</Text>
          <Input
            placeholder="Category description"
            value={description}
            onChange={val => {
              setDescription(val.target.value);
            }}
          />
          <Text mt={2}>Color</Text>
          <Input
            placeholder="Category color*"
            value={color}
            onChange={val => {
              setColor(val.target.value);
            }}
          />
          <Text mt={2}>Text/Line Color</Text>
          <Input
            placeholder="Text/Line Color*"
            value={textLineColor}
            onChange={val => {
              setTextLineColor(val.target.value);
            }}
          />

          <Flex
            mt={4}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              style={{
                background: 'white',
                color: '#344f79',
                border: '1px solid #344f79',
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={!isValid}
              style={{ background: '#344f79' }}
              onClick={() => {
                createCategory();
              }}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      )}
    </Mutation>
  );
};

const TrackerModal = ({
  onClose,
  onSave,
  labelsSelect,
  categoriesSelect,
  editingItem,
}) => {
  const [name, setName] = useState(editingItem ? editingItem.name : '');
  const [question, setQuestion] = useState(
    editingItem ? editingItem.question : ''
  );
  const [config, setConfig] = useState(
    editingItem && editingItem.config
      ? {
          labelGroupId: (editingItem.config.labelsGroup || {}).id,
          negativeColor: editingItem.config.negativeColor,
          positiveColor: editingItem.config.positiveColor,
          trackColor: editingItem.config.trackColor,
          knobColor: editingItem.config.knobColor,
          titleColor: editingItem.config.titleColor,
          textColor: editingItem.config.textColor,
          lastValuesIndicatorColor: editingItem.config.lastValuesIndicatorColor,
        }
      : {}
  );
  const [category, setCategory] = useState(
    editingItem ? (editingItem.category || {}).id : null
  );

  return (
    <Mutation
      mutation={ADD_SYMPTOM_TRACKER_MARKER_UT}
      refetchQueries={[{ query: GET_MOBILE_SYMPTOM_TRACKERS_UT }]}
      variables={{
        data: {
          id: (editingItem || {}).id,
          categoryId: category,
          name: name,
          question: question,
          title: name,
          labelsGroupId: config.labelGroupId,
          negativeColor: config.negativeColor,
          positiveColor: config.positiveColor,
          trackColor: config.trackColor,
          knobColor: config.knobColor,
          titleColor: config.titleColor,
          textColor: config.textColor,
          lastValuesIndicatorColor: config.lastValuesIndicatorColor,
        },
      }}
      onCompleted={() => {
        setName('');
        setQuestion('');
        setConfig({});
        setCategory(null);
        onSave();
      }}
    >
      {(createCategory, { loading }) => (
        <Flex flexDirection="row" width="600px">
          <Flex flexDirection="column" flex={1}>
            <Flex
              flexDirection="column"
              style={{ overflowY: 'scroll', height: '400px' }}
            >
              <Text>Name</Text>
              <Input
                placeholder="Tracker name*"
                value={name}
                onChange={val => {
                  setName(val.target.value);
                }}
              />
              <Text>Question</Text>
              <Input
                placeholder="Question*"
                value={question}
                onChange={val => {
                  setQuestion(val.target.value);
                }}
              />
              <Text>Title Color</Text>
              <Input
                placeholder="#cccccc"
                value={config.titleColor}
                onChange={val => {
                  setConfig({
                    ...config,
                    titleColor: val.target.value,
                  });
                }}
              />
              <Text>Text Color</Text>
              <Input
                placeholder="#cccccc"
                value={config.textColor}
                onChange={val => {
                  setConfig({
                    ...config,
                    textColor: val.target.value,
                  });
                }}
              />
              <Text>Main Background Color</Text>
              <Input
                placeholder="#cccccc"
                value={config.negativeColor}
                onChange={val => {
                  setConfig({
                    ...config,
                    negativeColor: val.target.value,
                  });
                }}
              />
              <Text>Progress Background Color</Text>
              <Input
                placeholder="#cccccc"
                value={config.positiveColor}
                onChange={val => {
                  setConfig({
                    ...config,
                    positiveColor: val.target.value,
                  });
                }}
              />
              <Text>Track Background Color</Text>
              <Input
                placeholder="#cccccc"
                value={config.trackColor}
                onChange={val => {
                  setConfig({
                    ...config,
                    trackColor: val.target.value,
                  });
                }}
              />
              <Text>Knob Background Color</Text>
              <Input
                placeholder="#cccccc"
                value={config.knobColor}
                onChange={val => {
                  setConfig({
                    ...config,
                    knobColor: val.target.value,
                  });
                }}
              />
              <Text>Previous Indicator Color</Text>
              <Input
                placeholder="#cccccc"
                value={config.lastValuesIndicatorColor}
                onChange={val => {
                  setConfig({
                    ...config,
                    lastValuesIndicatorColor: val.target.value,
                  });
                }}
              />
              <Text>Labels</Text>
              <Select
                classNamePrefix="region-select"
                options={labelsSelect}
                value={labelsSelect.filter(
                  element => element.value === config.labelGroupId
                )}
                onChange={option =>
                  setConfig({
                    ...config,
                    labelGroupId: option.value,
                  })
                }
                isSearchable={true}
                theme={base => ({
                  ...base,
                  colors: {
                    ...base.colors,
                    primary: '#364f79',
                    primary50: '#dae6fa',
                  },
                })}
              />
              <Text>Category</Text>
              <Select
                classNamePrefix="region-select"
                options={categoriesSelect}
                value={categoriesSelect.filter(
                  element => element.value === category
                )}
                onChange={option => setCategory(option.value)}
                isSearchable={true}
                theme={base => ({
                  ...base,
                  colors: {
                    ...base.colors,
                    primary: '#364f79',
                    primary50: '#dae6fa',
                  },
                })}
              />
            </Flex>

            <Flex
              mt={4}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                style={{
                  background: 'white',
                  color: '#344f79',
                  border: '1px solid #344f79',
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                // disabled={!isValid}
                style={{ background: '#344f79' }}
                onClick={() => {
                  createCategory();
                }}
              >
                Save
              </Button>
            </Flex>
          </Flex>

          <Box
            style={{
              backgroundColor: '#ccc',
              padding: '20px',
              width: '250px',
              height: '100%',
            }}
          >
            <Flex
              flexDirection="column"
              style={{ minHeight: '400px', position: 'relative' }}
            >
              <Flex
                flex="1"
                style={{
                  backgroundColor: config.negativeColor,
                  padding: '6px',
                }}
              >
                <Text style={{ color: config.titleColor || '' }}>
                  {question}
                </Text>
              </Flex>
              <Flex
                flex="1"
                style={{
                  backgroundColor: config.positiveColor,
                  alignItems: 'flex-end',
                  padding: '6px',
                }}
              >
                <Text style={{ color: config.textColor || '' }}>label</Text>
              </Flex>
              <Box
                style={{
                  position: 'absolute',
                  right: 20,
                  top: 40,
                  bottom: 40,
                  width: 6,
                  backgroundColor: config.trackColor || 'white',
                }}
              />
              <Box
                style={{
                  position: 'absolute',
                  right: 13,
                  borderRadius: 10,
                  bottom: 150,
                  width: 20,
                  height: 40,
                  backgroundColor: config.knobColor || 'white',
                }}
              />
            </Flex>
          </Box>
        </Flex>
      )}
    </Mutation>
  );
};

const MobileSymptomTrackersUnithroid = props => {
  const [isAddNewCategoryVisible, setIsAddNewCategoryVisible] = useState(false);
  const [isAddNewTrackerVisible, setIsAddNewTrackerVisible] = useState(false);
  const [isAddNewLabelsVisible, setIsAddNewLabelsVisible] = useState(false);
  const [editingItem, setEditingItem] = useState(undefined);

  const showAddNewCategory = () => {
    setIsAddNewCategoryVisible(true);
  };

  const cancelAddNewCategory = () => {
    setIsAddNewCategoryVisible(false);
    setEditingItem(undefined);
  };

  const showAddNewTracker = () => {
    setIsAddNewTrackerVisible(true);
  };

  const cancelAddNewTracker = () => {
    setIsAddNewTrackerVisible(false);
    setEditingItem(undefined);
  };

  const showAddNewLabels = () => {
    setIsAddNewLabelsVisible(true);
  };

  const cancelAddNewLabels = () => {
    setIsAddNewLabelsVisible(false);
    setEditingItem(undefined);
  };

  const editTracker = tracker => {
    console.log(tracker);
    setEditingItem(tracker);
    showAddNewTracker();
  };

  const editCategory = category => {
    console.log(category);
    setEditingItem(category);
    showAddNewCategory();
  };

  const editLabel = label => {
    console.log({ label });
    setEditingItem(label);
    showAddNewLabels();
  };

  const deleteOnClick = async id => {
    const { client } = props;

    await client.mutate({
      mutation: DELETE_SYMPTOM_TRACKER_MARKER_UT,
      variables: {
        id,
      },
      refetchQueries: [{ query: GET_MOBILE_SYMPTOM_TRACKERS_UT }],
      awaitRefetchQueries: true,
    });
  };

  const trackersColumns = [
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'category',
      accessor: 'category.name',
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div
              title="delete"
              style={{ cursor: 'pointer' }}
              onClick={() => deleteOnClick(row.original.id)}
            >
              🗑
            </div>
          </Flex>
        );
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div
              title="edit"
              style={{ cursor: 'pointer' }}
              onClick={() => editTracker(row.original)}
            >
              ✎
            </div>
          </Flex>
        );
      },
    },
  ];
  return (
    <Container>
      <Heading>SYMPTOM TRACKERS</Heading>
      <Flex
        flexDirection="row"
        style={{ justifyContent: 'space-between' }}
        mt={4}
        mb={3}
      >
        <Heading fontSize="20px">Categories</Heading>
        <Button style={{ background: '#344f79' }} onClick={showAddNewCategory}>
          Add Category
        </Button>
      </Flex>
      <Query query={GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES_UT}>
        {({ data, loading, error }) => {
          if (loading || error) return <div />;
          console.log('GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES', data);
          return (
            <Box>
              <ReactTable
                data={data.getSymptomTrackerCategoriesUt}
                columns={categoriesColumns}
                defaultPageSize={
                  data.getSymptomTrackerCategoriesUt &&
                  data.getSymptomTrackerCategoriesUt.length > 3
                    ? Math.min(data.getSymptomTrackerCategoriesUt.length, 25)
                    : 3
                }
                getProps={() => ({
                  edit: editCategory,
                })}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    edit: editCategory,
                  };
                }}
                showPagination={
                  data.getSymptomTrackerCategoriesUt &&
                  data.getSymptomTrackerCategoriesUt.length > 24
                }
              />

              <ConfirmModal
                childrenManaged
                isOpen={isAddNewCategoryVisible}
                confirmLabel="create"
                onClose={cancelAddNewCategory}
                onBackgroundClick={cancelAddNewCategory}
                onEscapeKeydown={cancelAddNewCategory}
                onConfirm={cancelAddNewCategory}
              >
                <ModalInner>
                  <Heading fontSize="20px">Create Category</Heading>
                  <CategoryModal
                    existingCategories={data.getSymptomTrackerCategoriesUt}
                    onClose={cancelAddNewCategory}
                    onSave={cancelAddNewCategory}
                    editingItem={editingItem}
                  />
                </ModalInner>
              </ConfirmModal>
            </Box>
          );
        }}
      </Query>

      <Flex
        flexDirection="row"
        style={{ justifyContent: 'space-between' }}
        mt={4}
        mb={3}
      >
        <Heading fontSize="20px">Label Groups</Heading>
        <Button style={{ background: '#344f79' }} onClick={showAddNewLabels}>
          Add Label groups
        </Button>
      </Flex>
      <Query query={GET_SYMPTOM_TRACKER_LABELS_GROUPS_UT}>
        {({ data, loading, error }) => {
          if (loading || error) return <div />;
          console.log('GET_SYMPTOM_TRACKER_LABELS_GROUPS', data);
          return (
            <Box>
              <ReactTable
                data={data.getSymptomTrackerLabelsGroupsUt}
                columns={labelsColumns}
                defaultPageSize={
                  data.getSymptomTrackerLabelsGroupsUt &&
                  data.getSymptomTrackerLabelsGroupsUt.length > 3
                    ? Math.min(data.getSymptomTrackerLabelsGroupsUt.length, 25)
                    : 3
                }
                showPagination={
                  data.getSymptomTrackerLabelsGroupsUt &&
                  data.getSymptomTrackerLabelsGroupsUt.length > 24
                }
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    edit: editLabel,
                  };
                }}
              />
            </Box>
          );
        }}
      </Query>

      <Flex
        flexDirection="row"
        style={{ justifyContent: 'space-between' }}
        mt={4}
        mb={3}
      >
        <Heading fontSize="20px">Trackers</Heading>
        <Button style={{ background: '#344f79' }} onClick={showAddNewTracker}>
          Add Tracker
        </Button>
      </Flex>
      <Query query={GET_MOBILE_SYMPTOM_TRACKERS_UT}>
        {({ data, loading, error }) => {
          if (loading || error) return <div />;
          console.log('GET_MOBILE_SYMPTOM_TRACKERS', data);
          return (
            <Box mb={6}>
              <ReactTable
                data={data.getSymptomTrackersUt}
                columns={trackersColumns}
                defaultPageSize={
                  data.getSymptomTrackersUt &&
                  data.getSymptomTrackersUt.length > 3
                    ? Math.min(data.getSymptomTrackersUt.length, 25)
                    : 3
                }
                showPagination={
                  data.getSymptomTrackersUt &&
                  data.getSymptomTrackersUt.length > 24
                }
              />
            </Box>
          );
        }}
      </Query>

      <ConfirmModal
        childrenManaged
        isOpen={isAddNewLabelsVisible}
        confirmLabel="create"
        onClose={cancelAddNewLabels}
        onBackgroundClick={cancelAddNewLabels}
        onEscapeKeydown={cancelAddNewLabels}
        onConfirm={cancelAddNewLabels}
      >
        <ModalInner>
          <Heading fontSize="20px">
            {!!editingItem ? 'Edit' : 'Create'} Labels
          </Heading>
          <LabelsModal
            onClose={cancelAddNewLabels}
            onSave={cancelAddNewLabels}
            editingItem={editingItem}
          />
        </ModalInner>
      </ConfirmModal>
      <ConfirmModal
        childrenManaged
        isOpen={isAddNewTrackerVisible}
        confirmLabel="create"
        onClose={cancelAddNewTracker}
        onBackgroundClick={cancelAddNewTracker}
        onEscapeKeydown={cancelAddNewTracker}
        onConfirm={cancelAddNewTracker}
      >
        <ModalInner>
          <Heading fontSize="20px">
            {!!editingItem ? 'Edit' : 'Create'} Category
          </Heading>
          <Query query={GET_SYMPTOM_TRACKER_LABELS_GROUPS_UT}>
            {({ data, loading, error }) => {
              if (loading || error) return <div />;
              const labelsSelect = [];

              for (
                let index = 0;
                index < data.getSymptomTrackerLabelsGroupsUt.length;
                index++
              ) {
                const element = data.getSymptomTrackerLabelsGroupsUt[index];
                labelsSelect.push({
                  value: element.id,
                  label: element.labels
                    .map(x => `${x.value}% ${x.label}`)
                    .join(' - '),
                });
              }
              return (
                <Query query={GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES_UT}>
                  {({ data, loading, error }) => {
                    console.log({ data });
                    const categoriesSelect = [];

                    for (
                      let index = 0;
                      index < data.getSymptomTrackerCategoriesUt.length;
                      index++
                    ) {
                      const element = data.getSymptomTrackerCategoriesUt[index];
                      categoriesSelect.push({
                        value: element.id,
                        label: element.name,
                      });
                    }
                    return (
                      <TrackerModal
                        onClose={cancelAddNewTracker}
                        onSave={cancelAddNewTracker}
                        labelsSelect={labelsSelect}
                        categoriesSelect={categoriesSelect}
                        editingItem={editingItem}
                      />
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        </ModalInner>
      </ConfirmModal>
    </Container>
  );
};

export default withApollo(MobileSymptomTrackersUnithroid);
