import React from 'react';
import { Flex, Card, Box, Text } from 'rebass';
import styled from 'styled-components';
import AddFeatureRow from './oneTimeCoupons/AddOneTimeCoupon';
import FeatureRowHeaders from './oneTimeCoupons/FeatureRowHeaders';
import { Query } from 'react-apollo';
import FeatureRow from './oneTimeCoupons/OneTimeCouponRow';
import { GET_ONE_TIME_COUPONS_LIST } from '../graphql';

const Container = styled(Box)`
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
`;

class OneTimeCouponsManagement extends React.Component {
  render() {
    return (
      <div style={{ width: '100%', overflowY: 'auto' }}>
        <Container>
          <Flex
            flex={1}
            textAlign="center"
            alignSelf="center"
            justifyContent="center"
          >
            <Text
              fontSize="38px"
              fontWeight={600}
              textAlign="center"
              mb={3}
              style={{ fontFamily: 'Playfair Display' }}
              color="#344f79"
            >
              One-Time Coupons Management
            </Text>
          </Flex>
          <Card p={[3, 4]} mb={4}>
            <Flex flexDirection="column">
              <FeatureRowHeaders />
              <Query query={GET_ONE_TIME_COUPONS_LIST}>
                {({ loading, error, data }) => {
                  if (loading) return <div />;
                  if (error) {
                    console.log(error);
                    return <div />;
                  }
                  if (
                    !data.getOneTimeCouponsList ||
                    data.getOneTimeCouponsList.length < 1
                  ) {
                    return <div />;
                  }
                  // console.log('data', data);
                  return data.getOneTimeCouponsList.map(x => {
                    return <FeatureRow key={x.id} toggle={x} id={x.name} />;
                  });
                }}
              </Query>
              <AddFeatureRow />
            </Flex>
          </Card>
        </Container>
      </div>
    );
  }
}

export default OneTimeCouponsManagement;
