import React from 'react';
import { withWizard } from 'react-albus';
import { Query } from 'react-apollo';
import { GET_B2B_COMPANY_ELIGIBILITY_CHECK_METHOD } from '../../graphql';
import { Flex, Text } from 'rebass';
import SpinLoader from '../../components/SpinLoader';
import B2bInformationDefault from './additionalInfo/Default';
import B2bInformationSubscriberIdNames from './additionalInfo/SubscriberIdNames';
import B2bInformationMemberIdNames from './additionalInfo/MemberIdNames';
import B2bInformationEmployeeIdNames from './additionalInfo/EmployeeIdNames'
import B2bInformationUniqueCode from './additionalInfo/UniqueCode'

const EligibilityCheckMethodDispatcher = ({ wizard, values, onSubmit, selectedCompany, method, whereToFind }) => {
  if (!method || method.toLowerCase() === 'default') {
    return <B2bInformationDefault wizard={wizard} values={values} onSubmit={onSubmit} />;
  }

  if (method.toLowerCase() === 'subscriberid;names') {
    return <B2bInformationSubscriberIdNames wizard={wizard} values={values} onSubmit={onSubmit} whereToFind={whereToFind} method={method}/>;
  }

  if (method.toLowerCase() === 'memberid;names') {
    return <B2bInformationMemberIdNames wizard={wizard} values={values} onSubmit={onSubmit} whereToFind={whereToFind} method={method}/>;
  }

  if (method.toLowerCase() === 'employeeid;names') {
    return <B2bInformationEmployeeIdNames wizard={wizard} values={values} onSubmit={onSubmit} whereToFind={whereToFind} method={method}/>;
  }

  if (method.toLowerCase() === 'uniquecode') {
    return <B2bInformationUniqueCode wizard={wizard} values={values} onSubmit={onSubmit} whereToFind={whereToFind} method={method}/>;
  }
  
};

const B2bInformation = ({ wizard, values, onSubmit, selectedCompany }) => {
  console.log({ values, selectedCompany });

  // return null;
  return (
    <Query
      query={GET_B2B_COMPANY_ELIGIBILITY_CHECK_METHOD}
      variables={{
        id: selectedCompany.id,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
              <SpinLoader width={100} height={100} />
              <Text
                style={{
                  color: '#486288',
                  fontSize: '18px',
                  marginTop: '40px',
                }}
              >
                Please Wait
              </Text>
            </Flex>
          );
        }
        if(!!error || !data || !data.getB2BCompanyEligibilityCheckMethod) {
          return <Text textAlign={'center'} mt={4}>An error occured</Text>
        }
        console.log({ data });
        return <EligibilityCheckMethodDispatcher wizard={wizard} values={values} onSubmit={onSubmit} whereToFind={data.getB2BCompanyEligibilityCheckMethod.whereToFindActivationMethodText} method={data.getB2BCompanyEligibilityCheckMethod.eligibilityCheckMethod} />;
      }}
    </Query>
  );
  // if ((values || {}).relationship === 'self') {
  //   return <B2bSelf wizard={wizard} onSubmit={onSubmit} values={values} />;
  // }
  // if ((values || {}).relationship !== 'self') {
  //   return <B2bPartner wizard={wizard} onSubmit={onSubmit} values={values} />;
  // }
};

export default withWizard(B2bInformation);
