import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { Formik, Form } from 'formik';
import { FormField } from '../components/FormField';
import { compose, withApollo } from 'react-apollo';
import * as Yup from 'yup';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';
import gql from 'graphql-tag';
import TextInput from '../components/TextInput';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;

const schema = Yup.object().shape({
  email: Yup.string()
    .email('invalid email address')
    .required('Required'),
});

const MUTATION = gql`
  mutation adminResetUserToWeek1($email: String!) {
    adminResetUserToWeek1(email: $email) {
      ok
    }
  }
`;

const AdminResetUserWeek = (props) => {
  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      const res = await props.client.mutate({
        mutation: MUTATION,
        variables: {
          email: values.email,
        },
      });

      if (res && res.data && res.data.adminResetUserToWeek1) {
        props.toastManager.add('Reset successful', {
          appearance: 'success',
        });
        actions.setSubmitting(false);
      } else {
        actions.setSubmitting(false);
        props.toastManager.add('an error occured', {
          appearance: 'error',
        });
      }

      actions.setSubmitting(false);
    } catch (err) {
      actions.setSubmitting(false);
      props.toastManager.add('an error occured', {
        appearance: 'error',
      });
    }
  };

  return (
    <Container>
      <Flex m={[3, 5]} flex={1} flexDirection="column">
        <Heading mb={4} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
          Reset mobile user to week 1
        </Heading>
        <Card mb={4} p={[3, 4]}>
          <Flex flexDirection={'column'} flex={1} alignItems="center">
            <Text>WARNING: All mobile courses tracking for this user will be deleted. This is irreversible</Text>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={schema}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
              render={({ errors, touched, isValid, isSubmitting, handleChange, handleBlur, values }) => (
                <Form style={{ width: '300px' }}>
                  <Flex flexDirection="column" flex={1}>
                    <Heading mb={4}>Patient's Phone</Heading>
                  </Flex>
                  <FormField mb={4} error={touched.email && errors.email}>
                    <TextInput style={{ textAlign: 'center' }} name="email" placeholder="Mobile user email" value={values.email} onChange={handleChange} onBlur={handleBlur} id="input_email" />
                  </FormField>

                  <Button mt={4} mb={4} variant="primary" width={1 / 2} disabled={!isValid || isSubmitting} type="submit">
                    Reset
                  </Button>
                </Form>
              )}
            />
          </Flex>
        </Card>
      </Flex>
    </Container>
  );
};

export default compose(
  withApollo,
  withToastManager
)(AdminResetUserWeek);
