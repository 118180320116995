import React from 'react';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import { Query } from 'react-apollo';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';
import { ADMIN_GET_MOBILE_COURSE_SCREEN_FORMATS } from '../../graphql';
import ConfirmModal from '../../components/ConfirmModal';
import AddScreenFormatModalInner from '../adminMobileCourse/modals/AddScreenFormatModalInner';
import { isToggleActive } from '../../components/featureToggle/toggles';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

class ScreenFormatList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddNewJourneyVisible: false,
    };
  }
  render() {
    const { isAddNewJourneyVisible } = this.state;
    return (
      <div>
        <Flex
          flexDirection="row"
          style={{ justifyContent: 'space-between' }}
          mt={4}
          mb={3}
        >
          <Heading fontSize="20px">Screen Formats</Heading>
          {isToggleActive('ADD_SCREEN_FORMAT') && (
            <Button
              style={{ background: '#344f79' }}
              onClick={() => {
                this.setState({ isAddNewJourneyVisible: true });
              }}
            >
              Add Format
            </Button>
          )}
        </Flex>
        <Query query={ADMIN_GET_MOBILE_COURSE_SCREEN_FORMATS}>
          {({ data, loading, error }) => {
            if (loading || error) return <div />;
            console.log('GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES', data);
            return (
              <Box>
                <Flex flexDirection="row" flexWrap="wrap">
                  {data.adminGetMobileCourseScreenFormats.map(x => {
                    return (
                      <Box
                        key={x.id}
                        style={{
                          padding: '6px 18px',
                          marginRight: '24px',
                          marginBottom: '24px',
                          backgroundColor: x.color,
                        }}
                      >
                        <Text>{x.name}</Text>
                      </Box>
                    );
                  })}
                </Flex>

                <ConfirmModal
                  childrenManaged
                  isOpen={isAddNewJourneyVisible}
                  confirmLabel="create"
                  onClose={() => {
                    this.setState({ isAddNewJourneyVisible: false });
                  }}
                  onBackgroundClick={() => {
                    this.setState({ isAddNewJourneyVisible: false });
                  }}
                  onEscapeKeydown={() => {
                    this.setState({ isAddNewJourneyVisible: false });
                  }}
                  onConfirm={() => {
                    this.setState({ isAddNewJourneyVisible: false });
                  }}
                >
                  <ModalInner>
                    <Heading fontSize="20px">Create Category</Heading>
                    <AddScreenFormatModalInner
                      onClose={() => {
                        this.setState({ isAddNewJourneyVisible: false });
                      }}
                      onSave={() => {
                        this.setState({ isAddNewJourneyVisible: false });
                      }}
                    />
                  </ModalInner>
                </ConfirmModal>
              </Box>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default ScreenFormatList;
