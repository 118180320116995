import React, { Fragment } from 'react';
import { Flex, Box, Button, Heading, Text, Link } from 'rebass';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';
import { withApollo, compose } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import * as Yup from 'yup';
import Mailcheck from 'react-mailcheck';
import { withRouter } from 'react-router';

import FormField from '../../../components/FormField';
import {
  BoxButton,
  TextInput,
} from '../../../components/async/SharedComponents';
import CheckboxInput from '../../../components/CheckboxInput';
import thyroid2 from '../../../static/thyroid2.png';
import { EMAIL_AVAILABLE } from '../../../graphql';

const StyledLink = styled(Link)`
  color: #354e79;
`;

const MailcheckWrapper = styled.div`
  text-align: right;
  @media screen and (max-width: 52em) {
    text-align: left;
  }
`;

const MailcheckButton = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  font-size: inherit;
  font-style: italic;
  color: rgb(237, 183, 168);
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;

  &:focus {
    outline: none;
    color: rgb(237, 183, 168);
  }

  &:hover {
    color: rgb(237, 183, 168);
  }

  &:active {
    color: rgb(237, 183, 168);
  }
`;

const Description = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
`;

const Welcome = ({ data, onSubmit, client, savedData, history }) => {
  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    password: Yup.string()
      .trim()
      .min(8, 'Password must be greater than 8 characters')
      .required('Required'),
    consent: Yup.bool()
      .test(
        'consent',
        'You must agree to the Terms and Conditions',
        val => val === true
      )
      .required('You must agree to the Terms and Conditions'),
    consenthipaa: Yup.bool()
      .test(
        'consenthipaa',
        'You must agree to the HIPAA Terms',
        val => val === true
      )
      .required('You must agree to the HIPAA Terms'),
      consentshare: Yup.bool()
        .test(
          'consentshare',
          'You must consent to share your medical information with our physicians',
          val => val === true
        )
        .required('You must consent to share your medical information with our physicians'),
  });
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
        <img src={thyroid2} alt="" width="140px" style={{ margin: '-30px' }} />
        <Box m={2}>
          <Heading textAlign="left">Welcome to Paloma Health</Heading>
        </Box>
        <Box m={2} mt="0">
          <Description>Let’s complete your new patient profile</Description>
        </Box>

        <Formik
          initialValues={savedData}
          validationSchema={schema}
          enableReinitialize={true}
          isInitialValid={(aaa, bbb) => {
            try {
              const aa = schema.validateSync({});

              // const bb = await aa.isValid()
              if (aa.errors && aa.errors.length) {
                return false;
              }
              return true;
            } catch (err) {
              return false;
            }
          }}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            try {
              const { data } = await client.query({
                query: EMAIL_AVAILABLE,
                variables: {
                  email: values.email,
                },
              });

              actions.setSubmitting(false);
              if (!data.emailAvailable.ok) {
                if(!!data.emailAvailable.mobile){
                  history.push('/account-exists')
                  return
                }
                throw Error('Email already in use');
              } else {
                onSubmit(values, actions);
                actions.setSubmitting(false);
              }
            } catch (error) {
              actions.setSubmitting(false);
              actions.setFieldError('email', error.message);
            }
          }}
          render={({
            isValid,
            errors,
            touched,
            values,
            isSubmitting,
            setFieldValue,
            handleBlur,
            handleChange,
          }) => {
            return (
              <Form>
                <Flex my={3} flex={1} flexDirection="column">
                  <FormField
                    width={1}
                    px={2}
                    mb={3}
                    error={touched['email'] && errors['email']}
                    alignSelf="center"
                  >
                    <Mailcheck email={values.email}>
                      {suggestion => (
                        <>
                          <TextInput
                            style={{ border: '1px solid #D7D7D7' }}
                            name={'email'}
                            placeholder={'Email Address'}
                            type={'text'}
                          />
                          {suggestion && (
                            <MailcheckWrapper className="mailcheck">
                              Did you mean{' '}
                              <MailcheckButton
                                type="button"
                                onClick={() =>
                                  setFieldValue('email', suggestion.full)
                                }
                              >
                                {suggestion.full}
                              </MailcheckButton>
                              ?
                            </MailcheckWrapper>
                          )}
                        </>
                      )}
                    </Mailcheck>
                  </FormField>
                  <FormField
                    width={1}
                    px={2}
                    mb={3}
                    error={touched['password'] && errors['password']}
                    alignSelf="center"
                  >
                    <TextInput
                      name={'password'}
                      placeholder={'Password'}
                      type={'password'}
                    />
                  </FormField>

                  <ul style={{ marginTop: 0 }}>
                    <li>
                      <Text fontSize="14px" fontWeight="300" lineHeight="21px">
                        Password must be at least 8 characters long
                      </Text>
                    </li>
                    <li>
                      <Text fontSize="14px" fontWeight="300" lineHeight="21px">
                        Include at least one uppercase letter or number or
                        symbol
                      </Text>
                    </li>
                  </ul>

                  <FormField
                    flexDirection="row"
                    mb={3}
                    pl="21px"
                    error={touched.consent && errors.consent}
                  >
                    <CheckboxInput name="consent" />
                    <Text fontSize={1}>
                      I agree to Paloma Health's&nbsp;
                      <StyledLink
                        href="https://www.palomahealth.com/legal-doc/paloma-health-terms-of-use"
                        target="_blank"
                      >
                        Terms of Service
                      </StyledLink>{' '}
                      and{' '}
                      <StyledLink
                        href="https://www.palomahealth.com/legal-doc/consent-to-telehealth"
                        target="_blank"
                      >
                        Informed Consent
                      </StyledLink>
                    </Text>
                  </FormField>

                  <FormField
                    flexDirection="row"
                    mb={3}
                    pl="21px"
                    error={touched.consenthipaa && errors.consenthipaa}
                  >
                    <CheckboxInput name="consenthipaa" />
                    <Text fontSize={1}>
                      I have read and agree to the terms of Paloma
                      Health's&nbsp;
                      <StyledLink
                        href="https://www.palomahealth.com/legal-doc/notice-of-privacy-practices"
                        target="_blank"
                      >
                        HIPAA Notice
                      </StyledLink>
                    </Text>
                  </FormField>

                  <FormField
                    flexDirection="row"
                    mb={3}
                    pl="21px"
                    error={touched.consentshare && errors.consentshare}
                  >
                    <CheckboxInput name="consentshare" />
                    <Text fontSize={1}>
                      Consent to share medical information with our physicians
                    </Text>
                  </FormField>
                </Flex>
                <BoxButton className={isValid ? 'visible' : ''}>
                  <Button
                    disabled={!isValid || isSubmitting}
                    variant="primary"
                    style={{
                      marginLeft: '12px',
                      marginRight: '12px',
                    }}
                    width={[1, 1 / 2, 1 / 4]}
                    type="submit"
                  >
                    Continue
                  </Button>
                </BoxButton>
              </Form>
            );
          }}
        />
      </Flex>
    </Fragment>
  );
};

export default compose(
  withRouter,
  withApollo,
  withWizard,
  withToastManager
)(Welcome);
