import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import Modal, { BaseModalBackground } from 'styled-react-modal';
import FullLayout2 from '../../components/FullLayout';
import { LogoHeader } from '../../components/Logo';
import PleaseWait from '../../components/PleaseWait';
import withSession from '../../lib/withSession';
import britCare from '../../static/Brittany.jpeg';
import { FOLLOWUP_SURVEYS_ID } from '../../utils/followupSurveysUtils';

const Header = styled(Flex)`
  height: 80px;
  max-height: 80px;
  background: #fdf5f2;
  width: 100%;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.08);
  z-index: 10;
  max-width: 100vw;
`;

const HeaderSub = styled(Flex)`
  max-width: 100vw;
  width: 100%;
  padding-left: 0;
  @media screen and (min-width: 52em) {
    padding-left: 30px;
  }
`;

const HeaderContent = styled(Flex)`
  height: 80px;
  max-height: 80px;
  width: 100vw;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  margin-top: -40px;
  @media screen and (max-width: 52em) {
    margin-top: 12px;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SpecialModalBackground = styled(BaseModalBackground)`
  backdrop-filter: blur(5px);
`;

const EstablishFollowUp = ({ history, location, match, session: { dedicatedCareManager } }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [survey, setSurvey] = useState();

  useEffect(() => {
    const surveyId = ((match || {}).params || {}).id;
    if (!surveyId) {
      setIsLoading(false);
      return;
    }

    const element = FOLLOWUP_SURVEYS_ID.find((x) => x.id.toLowerCase() === surveyId.toLowerCase());

    setSurvey(element);
    setIsLoading(false)
  }, []);

  return (
    <FullLayout2 style={{ zIndex: 1 }}>
      <Flex flex={1} flexDirection={'column'}>
        <Header flex={1}>
          <HeaderSub>
            <HeaderContent>
              <Flex flex={[1, 1, 0]} px="30px" alignItems="center" justifyContent={['center', 'center', 'flex-start']}>
                <Link to="/">
                  <LogoHeader height="40px" />
                </Link>
              </Flex>
            </HeaderContent>
          </HeaderSub>
        </Header>
        <Flex justifyContent={'center'} flexDirection="column" alignItems="center" style={{ margin: '0 auto', zIndex: 10, maxWidth: '80vw', width: '420px' }}>
          <ImageWrapper>
            <Img src={dedicatedCareManager && dedicatedCareManager.thumbnail ? dedicatedCareManager.thumbnail : britCare} />
          </ImageWrapper>
          {!isLoading && !!survey ? (
            <>
              <Heading mt={4} textAlign={'center'}>
                {survey.title}
              </Heading>
              <Text mt={3} textAlign="center">
                {survey.description.split('\n').map(str => <p>{str}</p>)}
              </Text>
              <Button mt={4} variant={'primary'} onClick={() => history.push(`/followup-survey/${match.params.id}`)}>
                Let's do it!
              </Button>
            </>
          ) : 
          <>
            <Heading mt={4} textAlign={'center'}>
              Oooops
            </Heading>
            <Text mt={3} textAlign="center">
              There seems to be a glitch. This survey doesn't exist
            </Text>
            <Button mt={4} variant={'primary'} onClick={() => history.push(`/`)}>
              Go back to dashboard
            </Button>
          </>}
        </Flex>
      </Flex>

      {!!isLoading && (
        <Modal isOpen={true} onBackgroundClick={() => false} onEscapeKeydown={() => false}>
          {/* <SpecialModalBackground> */}
            <Box
              style={{
                margin: '0 auto',
                alignSelf: 'center',
                borderRadius: '20px',
                position: 'relative',
                backgroundColor: 'white',
                padding: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <PleaseWait />
            </Box>
          {/* </SpecialModalBackground> */}
        </Modal>
      )}
    </FullLayout2>
  );
};

export default withRouter(withSession(EstablishFollowUp));
