import React, { useState } from 'react';
import { Box, Flex } from 'rebass';

export const CostIcon = ({ cost }) => {
  const [color] = useState(cost === 0 ? '#80b180' : cost === 1 ? '#f3bf61' : cost === 2 ? '#fb8080' : 'gray');

  return (
    <Flex style={{height:'16px'}}>
      <Flex flexDirection={'column'} style={{ width: '3px', marginRight: '3px' }}>
        <Box flex={2 / 3} />
        <Box flex={1 / 3} style={{ border: `1px solid ${color}`, background: color, borderRadius: '2px' }} />
      </Flex>
      <Flex flexDirection={'column'} style={{ width: '3px', marginRight: '3px', opacity: cost<1?0.5:1 }}>
        <Box flex={1 / 3} />
        <Box flex={2 / 3} style={{ border: `1px solid ${color}`, background: cost!==0?color:'transparent', borderRadius: '2px' }} />
      </Flex>
      <Flex flexDirection={'column'} style={{ width: '3px', marginRight: '2px', opacity: cost<2?0.5:1  }}>
        <Box flex={1} style={{ border: `1px solid ${color}`, background: cost===2?color:'transparent', borderRadius: '2px' }} />
      </Flex>
    </Flex>
  );
};
