import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import ConfirmModal from '../../components/ConfirmModal';
import Former from '../../components/former/Former';
import LoadingScreen from '../../components/LoadingScreen';
import { GET_BASELINE_SURVEY_ANSWERS, RESET_BASELINE, SAVE_BASELINE_TEMP_DATA } from '../../graphql';
import { sleep } from '../../utils/sleep';
import { QUESTIONS } from './Questions';

export const LOCAL_STORAGE_BASELINE_KEY = 'BaselineSurvey';

export const getPastBaselingSurveyData = async (client, redirectIfCompletedUrl, history) => {
  if (localStorage.getItem(LOCAL_STORAGE_BASELINE_KEY)) {
    const pastData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_BASELINE_KEY));
    return pastData;
  }
  const { data } = await client.query({
    query: GET_BASELINE_SURVEY_ANSWERS,
  });
  if (data.getBaselineSurveyAnswers && data.getBaselineSurveyAnswers.status === 'complete' && redirectIfCompletedUrl) {
    history.push(redirectIfCompletedUrl);
    return []
  }
  if (data && data.getBaselineSurveyAnswers && data.getBaselineSurveyAnswers.values) {
    const dt = JSON.parse(data.getBaselineSurveyAnswers.values);
    console.log({ data: dt });
    return dt;
  }
  return [];
};

const BaselineSurvey = ({ client, history }) => {
  const [isResume, setIsResume] = useState();
  const [prechecksDone, setPrechecksDone] = useState(false);
  const [pastData, setPastData] = useState([]);

  useEffect(() => {
    const rem = async () => {
      await sleep(500);
      if (window.Beacon) {
        window.Beacon('on', 'ready', () => window.Beacon('destroy'));
      } else {
        await sleep(3000);
        if (window.Beacon) {
          window.Beacon('on', 'ready', () => window.Beacon('destroy'));
        }
      }
      await sleep(5000);
      try {
        window.Beacon('destroy')
      } catch (error) {
        
      }
    };

    rem();
    checkStoredValues();
  }, []);

  const checkStoredValues = async () => {
    const past = await getPastBaselingSurveyData(client, '/baseline-complete', history);
    if (past && past.length > 0) {
      setPastData(past);
      setIsResume(true);
    } else {
      setIsResume(false);
    }
    setPrechecksDone(true);
  };

  const onCompleted = () => {
    history.push('/baseline-complete');
  };

  const initTempData = async (acceptResume) => {
    if (!acceptResume) {
      localStorage.removeItem(LOCAL_STORAGE_BASELINE_KEY);
      await client.mutate({
        mutation: RESET_BASELINE,
        refetchQueries: [{ query: GET_BASELINE_SURVEY_ANSWERS }],
      });
      setPastData([]);
    }
    setIsResume(false);
  };

  const onNext = async (data) => {
    await client.mutate({
      mutation: SAVE_BASELINE_TEMP_DATA,
      variables: {
        baselineData: data,
      },
      refetchQueries: [{ query: GET_BASELINE_SURVEY_ANSWERS }],
    });
  };

  if (!prechecksDone) {
    return <LoadingScreen />;
  }

  if (!!isResume) {
    return (
      <ConfirmModal
        isOpen={isResume}
        onClose={() => initTempData(false)}
        onBackgroundClick={() => initTempData(false)}
        onEscapeKeydown={() => initTempData(false)}
        childrenManaged={true}
        cancelVariant="outline"
        buttonsDirection="column"
        displayX={true}
        cardStyle={{
          width: '500px',
          maxWidth: 'calc(100vw - 40px)',
        }}
      >
        <Box
          style={{
            maxWidth: '600px',
            width: '100%',
          }}
        >
          <Heading textAlign="center">Resume</Heading>
          <Text mt={3} mb={4} textAlign="center">
            Do you want to resume where you left off?
          </Text>

          <Flex flexDirection="row" justifyContent="center">
            <Button variant="primary" mr={2} onClick={() => initTempData(true)}>
              Yes
            </Button>
            <Button variant="white" ml={2} onClick={() => initTempData(false)}>
              No
            </Button>
          </Flex>
        </Box>
      </ConfirmModal>
    );
  }

  return <Former questions={QUESTIONS} getPastData={pastData} localStorageVariable={LOCAL_STORAGE_BASELINE_KEY} pageName="Baseline" onCompleted={onCompleted} onNext={onNext} />;
};

export default withRouter(withApollo(BaselineSurvey));
