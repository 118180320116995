import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Card, Flex, Text } from 'rebass';
import { ArticlesRead, ArticlesReadBackground } from '../pages/ArticlesRead';
import { TopCategories, TopCategoriesBackground } from '../pages/TopCategories';
import { CoursesCount, CoursesCountBackground } from '../pages/CoursesCount';
import { AipProgress, AipProgressBackground } from '../pages/AipProgress';
import { CarouselCard } from '../YearlyReview';
import { PlateHeart } from '../icons/PlateHeart';
import { MarkerEvolution, MarkerEvolutionBackground } from '../pages/MarkerEvolution';
import { TrackedSymptomsCount, TrackedSymptomsCountBackground } from '../pages/TrackedSymptomsCount';
import { AverageSymptoms, AverageSymptomsBackground } from '../pages/AverageSymptoms';
import { ImpactingSymptoms, ImpactingSymptomsBackground } from '../pages/ImpactingSymptoms';
import { ImprovedSymptoms, ImprovedSymptomsBackground } from '../pages/ImprovedSymptoms';
import { TestCount, TestCountBackground } from '../pages/TestCount';
import { hormoneName } from '../../LabDetail';
import moment from 'moment';
import PleaseWait from '../../../components/PleaseWait';
import { DoctorsVisitsCount, DoctorsVisitsCountBackground } from '../pages/DoctorsVisitsCount';

const GET_YEARLY_CARE = gql`
  query getMemberYearlyReviewCare($from: String, $to: String) {
    getMemberYearlyReviewCare(from: $from, to: $to) {
      id
      trackedSymptomsCount
      minutesTravelSaved
      minutesWaitingSaved
      minutesSpentAppointment
      avgTracking {
        date
        average
      }
      impacted {
        marker
        variation
      }
      improved {
        marker
        variation
      }
      testCount
      testsLevels {
        tpo {
          id
          date
          value
          isPaloma
          isSelfEntered
        }
        tsh {
          id
          date
          value
          isPaloma
          isSelfEntered
        }
        ft3 {
          id
          date
          value
          isPaloma
          isSelfEntered
        }
        ft4 {
          id
          date
          value
          isPaloma
          isSelfEntered
        }
        rT3 {
          id
          date
          value
          isPaloma
          isSelfEntered
        }
        vitD {
          id
          date
          value
          isPaloma
          isSelfEntered
        }
      }
      appointments
      uniqueProviders {
        id
        picture
      }
    }
  }
`;

const SECTION_BACKGROUND = '#344F7A';
const SECTION_NAME = 'Your care';

const getStories = (data) => {
  const progress = [];
  for (let index = 0; index < 12; index++) {
    const element = (data.byWeeks || []).find((x) => x.week === index + 1);
    progress.push(Math.round((((element || {}).progress || []).reduce((a, b) => a + b, 0) || 0) / (((element || {}).progress || []).length || 1)));
  }
  const averageSymptomsData = (data.avgTracking || []).map((x) => {
    return {
      x: moment(x.date).format('MM/DD/YYYY'),
      y: x.average,
    };
  });
  return [
    {
      progressStyle: {
        foreground: '#A1C5FF',
      },
      backgroundColor: '#344F7A',
      duration: 5000,
      content: <TrackedSymptomsCount trackedCount={data.trackedSymptomsCount} />,
      background: <TrackedSymptomsCountBackground />,
      show: true,
      analytics:{
        section: 'Care',
        screen: 'TrackedSymptomsCount',
        metadata:{
          trackCount: data.trackedSymptomsCount || 0
        }
      }
    },
    {
      progressStyle: {
        foreground: '#A1C5FF',
      },
      backgroundColor: '#354E79',
      duration: 5000,
      content: <AverageSymptoms data={averageSymptomsData} />,
      background: <AverageSymptomsBackground />,
      show: (data.trackedSymptomsCount || 0) > 0,
      analytics:{
        section: 'Care',
        screen: 'AvgSymptoms'
      }
    },
    // {
    //   progressStyle: {
    //     foreground: '#A1C5FF',
    //   },
    //   backgroundColor: '#354E79',
    //   duration: 5000,
    //   content: <SymptomScore score={95} />,
    //   background: <SymptomScoreBackground />,
    // },
    {
      progressStyle: {
        foreground: '#A1C5FF',
      },
      backgroundColor: '#354E79',
      duration: 5000,
      content: (
        <ImpactingSymptoms
          symptoms={(data.impacted || []).map((x) => {
            return {
              symptom: x.marker,
              value: x.variation,
            };
          })}
        />
      ),
      background: <ImpactingSymptomsBackground />,
      show: (data.trackedSymptomsCount || 0) > 0,
      analytics:{
        section: 'Care',
        screen: 'ImpactedSymptoms',
        metadata:{
          impacted: (data.impacted || []).map(x=>x.marker).join(', ')
        }
      }
    },
    {
      progressStyle: {
        foreground: '#A1C5FF',
      },
      backgroundColor: '#354E79',
      duration: 5000,
      content: (
        <ImprovedSymptoms
          symptoms={(data.improved || []).map((x) => {
            return {
              symptom: x.marker,
              value: x.variation,
            };
          })}
        />
      ),
      background: <ImprovedSymptomsBackground />,
      show: (data.trackedSymptomsCount || 0) > 0,
      analytics:{
        section: 'Care',
        screen: 'ImprovedSymptoms',
        metadata:{
          improved: (data.improved || []).map(x=>x.marker).join(', ')
        }
      }
    },
    {
      progressStyle: {
        foreground: '#A1C5FF',
      },
      backgroundColor: '#354E79',
      duration: 5000,
      content: <TestCount testCount={data.testCount || 0} />,
      background: <TestCountBackground />,
      show: (data.trackedSymptomsCount || 0) > 0,
      analytics:{
        section: 'Care',
        screen: 'LabTestsCount',
        metadata:{
          testsCount: data.testCount || 0
        }
      }
    },
    {
      progressStyle: {
        foreground: '#A1C5FF',
      },
      backgroundColor: '#354E79',
      duration: 5000,
      content: (
        <MarkerEvolution
          tests={((data.testsLevels || {}).tsh || []).map((x) => {
            return {
              created: (x.date||'').indexOf('-')>-1 || (x.date||'').indexOf('/')>-1?moment(x.date).format('MM/DD/YYYY'):  moment(new Date((x.date * 100) / 100)).format('MM/DD/YYYY'),
              value: x.value / 100,
            };
          })}
          markerName="TSH"
          normalRangeLow={hormoneName['TSH'].min}
          normalRangeHigh={hormoneName['TSH'].max}
          yAxisMinTop={hormoneName['TSH'].max * 1.3}
        />
      ),
      background: <MarkerEvolutionBackground />,
      show:((data.testsLevels || {}).tsh || []).length > 0,
      analytics:{
        section: 'Care',
        screen: 'LabTshLevels',
      },
    },
    {
      progressStyle: {
        foreground: '#A1C5FF',
      },
      backgroundColor: '#344F7A',
      duration: 5000,
      content: <DoctorsVisitsCount visitCount={(data.appointments||[]).length} uniqueProviders={data.uniqueProviders} />,
      background: <DoctorsVisitsCountBackground />,
      show: true,
      analytics:{
        section: 'Care',
        screen: 'DoctorVisitsCount',
        metadata:{
          doctorVisitsCount: (data.appointments || []).length,
          uniqueProviders: (data.uniqueProviders || [])
        }
      }
    },
    // {
    //   progressStyle: {
    //     foreground: '#344F7A',
    //   },
    //   backgroundColor: '#FDF5F2',
    //   duration: 2000,
    // },
    // {
    //   progressStyle: {
    //     foreground: '#344F7A',
    //   },
    //   backgroundColor: '#FDF5F2',
    //   duration: 2000,
    // },
    // {
    //   progressStyle: {
    //     foreground: '#344F7A',
    //   },
    //   backgroundColor: '#DBE6F9',
    //   duration: 2000,
    // },
    // {
    //   progressStyle: {
    //     foreground: '#344F7A',
    //   },
    //   backgroundColor: '#DBE6F9',
    //   duration: 2000,
    // },
  ];
};

const CareSectionInner = ({ isActive, setActive, data, active, onAllStoriesEnd , onClose}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [stories, setStories] = useState();
  useEffect(
    () => {
      if (!!isLoaded) return;
      setStories(getStories(data).filter(x=>!!x.show));
      setIsLoaded(true);
    },
    [data, isLoaded]
  );

  if (!isLoaded) return <div />;

  return (
    <CarouselCard onClose={onClose} sectionNameColor='white' stories={stories} sectionName={SECTION_NAME} section={{ inactiveIcon: <PlateHeart /> }} isActive={isActive} backgroundColor={SECTION_BACKGROUND} onAllStoriesEnd={onAllStoriesEnd} />
  );
};

export const CareSection = ({ isActive, setActive, active, onAllStoriesEnd, onClose }) => {
  if (!isActive)
    return (
      <Card style={{ height: '100vh', width: '100%', backgroundColor: '#c4c8d3', borderRadius: '30px', padding: '18px' }}>
        <Flex
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <PlateHeart />
          <Text style={{ fontSize: '32px', color: 'white' }}>{SECTION_NAME}</Text>
        </Flex>
      </Card>
    );
  return (
    // <Flex style={{ flex: 1, width: '100%', height: '100%', background: 'red' }}>
    <Query query={GET_YEARLY_CARE}>
      {({ loading, data, error }) => {
        if (loading)
          return (
            <Card style={{ height: '100vh', width: '100%', backgroundColor: SECTION_BACKGROUND, borderRadius: '30px', padding: '18px' }}>
              <Flex
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  height: '100%',
                  flexDirection: 'column',
                }}
              >
                <PleaseWait
                textStyle={{
                  color: 'white'
                }}
                  spinner={{
                    pColor: '#c4c8d3',
                    sColor: '#A1C5FF',
                  }}
                  text="Loading your care"
                />
              </Flex>
            </Card>
          );

        return <CareSectionInner onClose={onClose} isActive={isActive} active={active} setActive={setActive} data={(data || {}).getMemberYearlyReviewCare} onAllStoriesEnd={onAllStoriesEnd} />;
      }}
    </Query>
    // </Flex>
  );
};
