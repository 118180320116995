import React from 'react';
import { Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';

import ItemCollection from '../../components/icons/ItemCollection';

const Icon = styled(Flex)`
  display: none;
  @media screen and (min-width: 52em) {
    display: block;
  }
`;
export const LabStep5 = currentStep => {
  return (
    <Flex flexDirection={['column', 'row']} flex={1}>
      <Icon
        mr={[0, 4]}
        mb={[3, 0]}
        alignItems="flex-start"
        justifyContent="center"
      >
        <ItemCollection width={90} />
      </Icon>

      <Flex flexDirection="column" flex={1}>
        <Heading mb={4}>
            Your lab results are available
        </Heading>
        <Text lineHeight={1.6}>
          The wait is over. Please review your results below.<br /> <br />
          Your results contain your levels of each marker tested, providing you insight into how they compare to the appropriate standards. Easily download your results so you can share them directly with your physician. If you book a visit with one of our clinicians, your results will be automatically shared with her/him.
        </Text>
      </Flex>
    </Flex>
  );
};
