import React, { useState } from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';

const MAX_VISIBILITY = 3;
const CAROUSEL_CARD_WIDTH = 700;
const CarouselWrapper = styled.div`
  position: relative;
  width: min(700px, 100vw);
  height: 100%;
  perspective: 500px;
  transform-style: preserve-3d;
`;

const CardContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out;
`;

export const Carousel = ({ children, active, setActive }) => {
  const count = React.Children.count(children);

  return (
    <CarouselWrapper>
      {React.Children.map(children, (child, i) => {
        return(
        <CardContainer onClick={()=>{
          if(active !==i)
          setActive(i)
        }}
          style={{
            transform: `translateZ(calc(${Math.abs(active - i) / 3} * -40rem)) translateX(calc(${active - i} * -780px))`,
            // filter: `blur(calc(${Math.abs(active - i) / 3} * 1rem))`,
            opacity: Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
            display: Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
          }}
        >
          <Flex style={{position: 'relative', width: '100%', maxWidth: '100%'}}>
          {child}
          </Flex>
        </CardContainer>
      )})}
    </CarouselWrapper>
  );
};
