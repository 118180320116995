import React, { useState, useEffect } from 'react';
import { Flex, Text, Box } from 'rebass';
import moment from 'moment';
import { Area, ComposedChart, ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import { InnerRightHormone, LeftMediumHormone, TopRightHormone } from '../icons/InnerRightHormone';
import styled, { keyframes } from 'styled-components';
import { WorkoutsIcon } from '../icons/WorkoutsIcon';
const slideLeft = keyframes`
  0% {
    /* opacity: 0; */
    transform: translateX(-350px);
  };
  100% {
    /* opacity: 1; */
    transform: translateX(0);
  };
`;

const Opaciter = keyframes`
  0% {
    opacity: 0;
    /* transform: translateX(-350px); */
  };
  100% {
    opacity: 1;
    /* transform: translateX(0); */
  };
`;

const Bar = styled(Flex)`
  padding: 8px 16px;
  border-radius: 0 80px 80px 0;
  min-height: 60px;
  background-color: #344f7a;
  animation: 1s 1 forwards ease-in ${slideLeft};
  justify-content: flex-end;
`;

const Item = styled(Text)`
  opacity: 0;
  animation: 1s 1 forwards ease-in ${Opaciter};
  animation-delay: ${(props) => props.delay};
  margin-left: 12px;
  font-size: 22px;
`;

export const AverageSymptomsBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <TopRightHormone />
      <InnerRightHormone
        style={{
          position: 'absolute',
          right: 0,
          bottom: 60,
        }}
      />

      <LeftMediumHormone
        style={{
          position: 'absolute',
          left: 0,
          bottom: 260,
        }}
      />
    </Flex>
  );
};

export const AverageSymptoms = ({data}) => {
  // const [loaded, setLoaded] = useState(false);
  // const [data, setData] = useState();
  // useEffect(() => {
  //   const dta = [];
  //   let items = [1, 3.1, 4.2, 5, 1.75];
  //   for (let index = 0; index > -12; index--) {
  //     let val = items[Math.floor(Math.random() * items.length)];
  //     dta.push({
  //       x: moment()
  //         .add(index, 'month')
  //         .format('MM/DD/YYYY'),
  //       y: val,
  //     });
  //   }
  //   setData(dta);
  //   setLoaded(true);
  // }, []);
  return (
    <Flex style={{ alignItems: 'center', height: '100%', flexDirection: 'column', marginTop: window.matchMedia('(min-width: 52em)').matches?80:0, zIndex: 100 }}>
    <Flex style={{justifyContent:'flex-end', flex: 1/3, flexDirection: 'column'}}>
      <Text style={{ fontSize: window.matchMedia('(min-width: 52em)').matches?36:26, fontWeight: 700, textAlign: 'center', marginBottom: '24px', color: 'white' }}>See how you did!</Text>

      <Text style={{ fontSize: window.matchMedia('(min-width: 52em)').matches?20:16, textAlign: 'center', marginBottom: '36px', color: 'white' }}>This is your average symptom score over the past 12 months</Text>
      </Flex>
      {/* {!!loaded && ( */}
        <Flex style={{ width: 'calc(100% + 36px)', flex: 2/3, alignItems: 'flex-start', marginLeft: '-18px', marginRight: '-18px' }}>
        <ResponsiveContainer width="100%" height={300}>
            <LineChart width={500} height={300} data={data}>
              <XAxis dataKey="x" tick={{ fill: 'white' }} />
              {/* <YAxis /> */}
              <CartesianGrid stroke="#CBCBCD" strokeDasharray="2 5" />
              <Line type="monotone" dataKey="y" stroke="#FCE3D9" dot={false} strokeWidth={4} />
            </LineChart>
          </ResponsiveContainer>
        </Flex>
      {/* )} */}
    </Flex>
  );
};
