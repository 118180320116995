import React, { useState } from 'react';
import { Button, Card, Flex, Text } from 'rebass';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';

import Dropzone from 'react-dropzone';
import PleaseWait from '../../../components/PleaseWait';

const columns = [
  {
    Header: 'Patient ID',
    accessor: 'patientid',
  },
  {
    Header: 'patient age',
    accessor: 'patientage',
  },
  {
    Header: 'patient city',
    accessor: 'patientcity',
  },
  {
    Header: 'Hypothyroidism',
    accessor: 'Hypothyroidism',
    Cell: row => {
      return <div>{!!row.original.Hypothyroidism ? '✓' : ''}</div>;
    },
  },
  {
    Header: 'Hashimoto',
    accessor: 'Hashimoto',
    Cell: row => {
      return <div>{!!row.original.Hashimoto ? '✓' : ''}</div>;
    },
  },
  {
    Header: 'patientsex',
    accessor: 'patientsex',
  },
  {
    Header: 'patient state',
    accessor: 'patientstate',
  },
  {
    Header: 'patient zip',
    accessor: 'patientzip',
  },
  {
    Header: 'patient mobile no',
    accessor: 'patientmobileno',
  },
  {
    Header: 'patientdob',
    accessor: 'patientdob',
    // },
    // {
    //   Header: 'Success',
    //   accessor: 'success',
    //   Cell: row => {
    //     return <div>{!!row.original.success ? '✓' : ''}</div>;
    //   },
  },
];

const typeMap = [
  'int',
  'int',
  'string',
  'bool',
  'bool',
  'string',
  'string',
  'string',
  'string',
  'string',
];

const PAGE_LENGTH = 10;
export const Identify = ({ handleSubmit, fileAccepted, preChecks }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [data, setData] = useState([]);
  const [processed, setProcessed] = useState(0);

  const processUpload = async () => {
    if (isProcessing) return;
    if (!preChecks()) return;
    setIsProcessing(true);
    // console.log('data', data);

    let page = 0;

    while (page * PAGE_LENGTH < data.length) {
      // data.length) {
      const dtaCopy = [];
      for (
        let index = page * PAGE_LENGTH;
        index < Math.min((page + 1) * PAGE_LENGTH, data.length);
        index++
      ) {
        const element = data[index];
        dtaCopy.push(JSON.stringify(element));
      }

      await handleSubmit(dtaCopy, result => {
        setProcessed((page + 1) * PAGE_LENGTH - 1);
      });

      page++;
    }

    setIsProcessing(false);
    alert('Upload complete');
  };

  return (
    <Card p={4} width="80%">
      <Flex flexDirection="column" style={{ position: 'relative' }}>
        <Dropzone
          noClick={true}
          multiple={false}
          onDropRejected={() => {}}
          onDropAccepted={f => {
            fileAccepted(f, data => setData(data), typeMap);
          }}
          maxSize={10000000}
          style={{
            width: '100%',
            marginBottom: 36,
          }}
        >
          {({ getRootProps, getInputProps, isDragActive, open }) => (
            <Flex
              flexDirection="column"
              style={{
                width: '100%',
                cursor: 'pointer',
              }}
              {...getRootProps()}
              onClick={() => {
                if (data && data.length > 0) return;
                open();
              }}
            >
              <ReactTable defaultPageSize={10} data={data} columns={columns} />
              <Text
                style={{
                  fontWeight: 300,
                  textAlign: 'center',
                }}
                mb={2}
              >
                Drag or click to insert the csv file
              </Text>

              <input type="file" {...getInputProps()} />
            </Flex>
          )}
        </Dropzone>
      </Flex>
      <Flex
        flexDirection="row"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '32px',
        }}
      >
        <Button
          disabled={!data || data.length < 1 || isProcessing}
          variant="primary"
          style={{ marginRight: 24 }}
          onClick={processUpload}
        >
          Upload
        </Button>
        <Button
          variant="pink"
          onClick={() => {
            setData([]);
          }}
        >
          Clear
        </Button>
      </Flex>
      {isProcessing && (
        <Flex
          alignItems="center"
          justifyContent="center"
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: 'rgba(255,255,255,0.3)',
          }}
        >
          <Card>
            <PleaseWait />
            <Text textAlign="center">{`Processed: ${processed}/${
              data.length
            }`}</Text>
          </Card>
        </Flex>
      )}
    </Card>
  );
};

export default Identify;
