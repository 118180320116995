import React from 'react';
import IconContainer from './IconContainer';

const ItemCollection = props => (
  <IconContainer {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 957.26 493.99">
      <path
        d="M161.15.24c5.75-.06,12.33-1,17.75,1.2,9.07,3.12,19,4.13,28.46,5.61q83.94,12.39,168,24.3c25.66,3.33,51.35,6.4,77,9.88,17.24,2.25,34.46,4.87,51.73,6.84,0,.89-.05,1.79-.09,2.69-.32,4.75-.6,9.47-.63,14.24a925.32,925.32,0,0,1-18.93,130c-2.53,12.39-5.51,24.67-8.38,37-12.21.13-24.49-1.31-36.67-2.11-12.43-1.39-24.89-2.55-37.33-4,10.86,33.67,22.24,67.14,33.9,100.54,2.34,6.92,5.29,14.31,6.34,21.55.66,3.87-1.59,7.12-4.29,9.63-4.19,4.1-9.76,6.33-15,8.95-13.22,5.79-26.44,11.6-39.69,17.33-63.85,26-128.45,50.3-193.85,72.16-21.93,7.35-43.91,14.47-66.24,20.58-2.85.73-5.42,1.81-8.28,1.74-3.7-.11-6.86,1.36-10.5,1.65a49,49,0,0,1-8.81-.19c-1.93-1-5.27-1.77-6-4-1.9-4.49-4.24-8.78-5.85-13.37-1.38-4.19-3.69-7.9-4.71-12.23C75,438.48,70.19,427,66,415.3c-2.82-8.05-6.32-15.83-9.15-23.88a53.52,53.52,0,0,1-3.75-9.3c-1.94-6.75-5.69-12.62-7.57-19.37-1.66-3.8-2.49-7.84-5-11-2.33-4.06-2.5-9.23-4.4-13.57C24,306.83,11.56,275.74,0,244.1c1.11-3.31,2-6.4,4.45-9,1.92-2.15,4.31-2.94,6.67-4.41,5.31-4.74,13.85-6.12,20.44-8.46Q87,203.71,142.26,184c1.77-19.32,3-38.7,4.93-58C150.85,84.17,155.57,41.84,161.15.24Z"
        fill="#5c5f8f"
      />
      <path
        d="M163.31,1.56c9.93,1.94,19.85,4.44,29.85,6,5.68.95,11.35,1.95,17,2.88C280.49,21,350.85,31.24,421.32,40.57c26.12,3.39,52.05,7.64,78.3,10.07-2.94,35.63-8.09,71.14-14.28,106.35-3.85,23.15-8.12,46.22-12.53,69.28-19-.79-37.92-1.83-56.88-2.73l-.05-2.8c-.68-5.39-2.66-10.72-4.2-15.93-7.29-20.51-15.65-40.65-22.85-61.2A112.83,112.83,0,0,0,377.22,117c-3.5-.16-6.67-.65-9.91,1l-2,.75c-2.79-1.76-5.06-3.69-8.4-4.31-5.37-1.07-11.33,1.44-16.4,3.07-64.25,21.45-128.84,43-193,65.11,2.68-30.53,5.08-61.09,7.65-91.63C157.88,61.18,160.68,31.37,163.31,1.56Z"
        fill="#fff"
      />
      <path
        d="M819.35,26.9c19.95,5.17,38.36,15.45,52,31.11,6.57,6.8,11.46,14.65,16.7,22.44,5.68-2.23,10.86-5.24,16.67-7.09a166.55,166.55,0,0,1,30.69-8.23c3.45-.53,6.74-1.77,10.11-.35,5.26,1.67,7.1,6.49,10.53,10.58,1.77,2,1.08,4.15,1.25,6.63-.16,2.39.5,4.85-1.13,6.78-3.06,4.12-6.11,8.79-10.16,11.89s-8.2,4.55-12.76,6.18c-11.26,4.29-22.61,8.32-33.94,12.41-.46,4.24-.48,8.5-.81,12.74-1.61,16.65-6.47,33.5-15.29,47.8-4.87,8.59-11.35,15.74-18.42,22.55A127.21,127.21,0,0,1,832.37,222c-15.34,5.77-31.68,8.36-48,6.49-13.58-1.36-26.11-6-38.1-12.39-21.88-12.09-39.18-32-47.33-55.72a106,106,0,0,1-4-52.58c3.25-15.3,9.33-29.92,18.87-42.39C723.45,51.62,737,41.09,752,33.67c9.68-4.45,19.68-7.72,30.31-8.86C794.45,22.91,807.42,24.38,819.35,26.9Z"
        fill="#5c5f8f"
      />
      <path
        d="M803.36,31.68c13,1.42,26.33,5,37.74,11.52,9.51,4.53,17.06,11.21,24.4,18.63a97.76,97.76,0,0,1,13.08,17.89c6.32,12.11,11.13,24.47,12,38.26,2.75,32.16-11.64,66.26-38.6,84.64-6.87,5-14.73,9.06-22.4,12.71-14.72,6.53-30.26,7-46,5.14-12.84-1.19-25.64-6.67-36.91-12.75a98.75,98.75,0,0,1-21.45-17.56c-16.91-17.94-25.36-43.76-24.37-68.19,1.25-19.48,7.64-38.38,19.72-53.82a141.93,141.93,0,0,1,24.74-22.29A106.11,106.11,0,0,1,767.5,35.17C779.26,31,791.07,31.07,803.36,31.68Z"
        fill="#eaeaf2"
      />
      <path
        d="M226,42.17c-.09,20.34-1.72,40.58-3.56,60.82a107.85,107.85,0,0,1-4.52,24.43c-4.48-4.1-3.57-10.5-2.79-15.91,3.55-22.7,5.24-45.58,7.59-68.42C222.39,41.69,225.2,42.44,226,42.17Z"
        fill="#5c5f8f"
      />
      <path
        d="M267.76,43.6C269,66.1,265.53,89.78,262,112c-.36,2.49-.81,5-1.37,7.4-2.41-1.25-4.8-2.54-7.12-3.93,1.36-16.41,6-32.14,7.77-48.48C262.39,58.7,262.14,50.39,267.76,43.6Z"
        fill="#5c5f8f"
      />
      <path
        d="M196.42,45.59c1.16,28.93-3.21,58.74-7.18,87.37-.94,6.38-1.47,12.86-3,19a11,11,0,0,1-2.57,5.32c-.94,1.13-3.17.77-4.5,1,0-4.46-.36-8.79.35-13.09q1.88-11.61,3.86-23.19c3.51-21.89,5.93-43.92,7.75-66A15.05,15.05,0,0,1,196.42,45.59Z"
        fill="#5c5f8f"
      />
      <path
        d="M800.33,50.15a93.17,93.17,0,0,1,21.48,4.33c14.47,5.39,27.52,15,36.28,27.76,8.24,11.58,13.47,25.48,14,39.76.9,9.36-.95,19-3.58,28-4.82,16-15.77,30.14-29.33,39.8a82.07,82.07,0,0,1-28.33,11.76,80,80,0,0,1-34.5-1.4c-13.22-3.36-25.54-9.1-35.14-19-13.47-12.83-21.46-31.69-22.42-50.17A78.8,78.8,0,0,1,759.4,59,83.17,83.17,0,0,1,800.33,50.15Z"
        fill="#5c5f8f"
      />
      <path
        d="M504,50.76a48.16,48.16,0,0,0,3.33,4.32c3.84,3.57,11,3.58,13.72,8.25a16.21,16.21,0,0,1,1.86,11c-1.92,10-2.76,20.76-5.49,30.61-2.63,10-4.84,20.2-7.77,30.15-2.29,8.12-3.57,16.56-5.34,24.82-3.34,15.64-4.91,31.44-7.57,47.18-1.13,7.5-1.76,14.91-3.95,22.23-1.27,4.69-3.64,10.35-7.77,13.21-5.54,3.32-12.1,6.15-18.62,6.46-13-.83-25-4.54-38-5-.18,5.06,2,8.9,3.41,13.62,4.83,15.05,10.57,29.82,16.47,44.48a257.88,257.88,0,0,1,9.27,24.77c2.69,7.35,5.36,14.11,5,22.12,0,7-3,12.7-6.64,18.42-4.56,6.67-10.8,10.32-17.49,14.52-6.28,3.75-13.24,5.86-19.62,9.42a567.49,567.49,0,0,1-58.46,23.52c-24.36,9-49.41,16.24-73.77,25.28-26.3,8.58-52.12,18.49-78.12,27.84-17.35,7-34.75,13.9-52.11,20.87-2.79,1.09-5.85,2.87-8.86,2.89-4,.11-8-.06-12,0-5.85.13-11.21-1.19-17-1.92-3.8-.48-6.52-2.48-10-3.83-2.86-1.13-5.7-1.37-8.16-3.4l4.16-1.33c-2.89-.6-6-.44-8.79-1.45a49,49,0,0,0,8.81.19c3.64-.29,6.8-1.76,10.5-1.65,2.86.07,5.43-1,8.28-1.74,22.33-6.11,44.31-13.23,66.24-20.58,65.4-21.86,130-46.14,193.85-72.16,13.25-5.73,26.47-11.54,39.69-17.33,5.29-2.62,10.86-4.85,15-8.95,2.7-2.51,5-5.76,4.29-9.63-1.05-7.24-4-14.63-6.34-21.55-11.66-33.4-23-66.87-33.9-100.54,12.44,1.42,24.9,2.58,37.33,4,12.18.8,24.46,2.24,36.67,2.11,2.87-12.31,5.85-24.59,8.38-37A925.32,925.32,0,0,0,503.32,65C503.35,60.23,503.63,55.51,504,50.76Z"
        fill="#eaeaf2"
      />
      <path
        d="M303.71,52.24c-.13,10.58.37,21.17.34,31.75-.25,9.87-.67,19.74-.95,29.61-2.24-.93-4.5-1.81-6.76-2.69-.93-9.21,0-18.3,1.23-27.43.75-8,.81-16.28,2.1-24.34C300.27,56.47,302,54.31,303.71,52.24Z"
        fill="#5c5f8f"
      />
      <path
        d="M811.16,59.31c5.49,1.56,10.93,3.09,15.94,5.93,9.2,4.23,16,10.89,22.51,18.46A77.6,77.6,0,0,1,861,104.15,80.21,80.21,0,0,1,864.47,128a66.71,66.71,0,0,1-19.15,46c-9.67,10.86-23.83,17.89-38,20.69-12.79,2.55-26-.07-38-4.68-11.21-3.8-20.92-11.52-28.4-20.55-10.14-12.95-15.69-30-14.31-46.44,1.47-28.38,22.06-54.87,49.7-62.21C787.61,58.13,799.69,57.09,811.16,59.31Z"
        fill="#f3e1cb"
      />
      <path
        d="M431.66,69.43a14.32,14.32,0,0,1,12.18,4c3.46,4.58,4.55,10.93,3.65,16.53-.66,6-3.22,12.36-8.75,15.39-3.62,2.17-7.39,1.91-11.43,1.53-5.05-.33-9.39-4.1-11.22-8.7a23.87,23.87,0,0,1,1.73-20.75C420.6,72.89,426.65,70.48,431.66,69.43Z"
        fill="#f3e1cb"
      />
      <path
        d="M469.92,73.29c.37,13,.84,29.22-6.32,40.66-1-7.74-.25-15.28-1.12-23-.25-1.17-.16-3.11-1.4-3.71a55.86,55.86,0,0,1-6.18-4.13A35,35,0,0,1,469.92,73.29Z"
        fill="#f3e1cb"
      />
      <path
        d="M947.57,75a75.59,75.59,0,0,1-.77,9.86,13.35,13.35,0,0,1-5.4,8.25c-12.85,8.24-28.54,10.86-43.31,15.39a231.94,231.94,0,0,0-8.53-25.29c13.29-.56,25.71-5.77,38.76-8A63.07,63.07,0,0,1,947.57,75Z"
        fill="#eaeaf2"
      />
      <path
        d="M426.71,75.46c4.69-1,11.55-.87,14.08,4.06,3.81,7.19.6,15.71-5,20.92-2.08,2.24-6,1.73-8.56.74a19.9,19.9,0,0,1-7.67-6.39c-1.66-3.82-.61-8.82,1.13-12.44C422,79.81,424,76.68,426.71,75.46Z"
        fill="#fff"
      />
      <path
        d="M354.4,114.8c4.64,5.73,7.55,13.27,9.93,20.18,6.86,21.16,14.16,42.21,20.85,63.43-4.74,8.69-9,18.09-16.81,24.65-5,4.44-11.18,6.85-17.67,8.29-9.52,1.47-19.82.32-29.37-.71-4.38-.27-8.65-2.3-12.83-3.83a101.12,101.12,0,0,1-18.87-10.07c-6.13-4-11-9.55-16.73-14.32-3.94-3.6-8.87-6.83-11.83-11.19a56.4,56.4,0,0,1-4.78-8.2c-1.89-3.95-2.44-8.72-2.94-13-.87-7.38,1.14-14.58,2.05-22.21,10.09-3.55,20.35-6.66,30.41-10.31C308.5,129.32,331.22,121.45,354.4,114.8Z"
        fill="#f9d0cb"
      />
      <path
        d="M377.22,117a112.83,112.83,0,0,1,11.61,26.65c7.2,20.55,15.56,40.69,22.85,61.2,1.54,5.21,3.52,10.54,4.2,15.93q-.12,1.33-.27,2.67-7-.57-13.93-1.11c-4.52-7.32-6.55-15.24-9.32-23.3q-13.12-39.93-27-79.6l2-1.4C370.55,116.31,373.72,116.8,377.22,117Z"
        fill="#eaeaf2"
      />
      <path
        d="M255.4,147.78c-.91,7.63-2.92,14.83-2.05,22.21.5,4.32,1.05,9.09,2.94,13a56.4,56.4,0,0,0,4.78,8.2c3,4.36,7.89,7.59,11.83,11.19,5.71,4.77,10.6,10.3,16.73,14.32a101.12,101.12,0,0,0,18.87,10.07c4.18,1.53,8.45,3.56,12.83,3.83,9.55,1,19.85,2.18,29.37.71,6.49-1.44,12.63-3.85,17.67-8.29,7.77-6.56,12.07-16,16.81-24.65,8.31,24.73,16.48,49.13,24.77,73.8,2.76,7.65,5.24,15.39,8.24,23,4.88,13.06,10.19,25.82,13.89,39.31A49.39,49.39,0,0,1,434.85,350c-.86,4.66-5.76,6-9.39,7.93-18.59,9.51-37.74,18-57,26.06-16,6.67-32,13.55-48.32,19.52-7.11,3-14.55,4.76-21.8,7.28-7.53,2.31-14.79,5.32-22.27,7.75a168.23,168.23,0,0,0-12.64-26.73,29.33,29.33,0,0,0-2.69-3.7c.15-.59.31-1.19.48-1.78,4-11.55,6.12-24.81,3.41-36.89-2.95-8.8-11.15-15.23-19.84-17.86-11.63-3.42-26.24-1-35.05,7.73-4.9,5-5.51,11.27-6.18,17.89-11.07.31-22.14.11-33.21.2-17.21,1-34.08,4.59-49.81,11.78-6.13,3.2-12.31,6.1-18.06,10-12,8-23.78,17.55-31.92,29.65-2-4.49-3.56-9.12-5.36-13.7C48.29,350.31,30.47,306,13,261.32c-3.36-8.56-7-18.12-6.86-27.42,4.42-1.12,8.5-3.14,13-4.07,5-1.07,9.08-4,14-5.09s9.3-3.24,14-4.79C116.75,196.13,186.11,171.72,255.4,147.78Z"
        fill="#fff"
      />
      <path
        d="M578.36,272.69a45.14,45.14,0,0,1,17.19,8.07c7.21,5.09,13.81,10.87,20.71,16.35,4.59,4,8.9,8,11,13.93,1.39,3.36-.24,7.11-1.64,10.21-3,5.71-6.35,11.49-10.72,16.28-5.09,5.74-8,12.16-13.56,17.42-4.66,4.38-9.26,8.41-15.95,9a62.13,62.13,0,0,0-4.49,7.64c-11.49,20-26.31,37.66-40.44,56.48-7.68,10.53-14.78,20.73-25.1,28.94-5.12,4.11-11.44,7.41-18,8.4-7.86-.12-14.86-4.74-19.52-10.87a36.23,36.23,0,0,1-6.14-22.67c.15-9.13,3.27-17.69,8.15-25.32,5.66-8.83,12.38-17,18.38-25.62,12.71-17.59,25.68-35,38.41-52.63,1.81-2.21,1.31-4.6,1.75-7.31.72-6.37,3.63-11.54,7.08-16.79a110.36,110.36,0,0,0,12.85-19.25,75.27,75.27,0,0,1,9.8-9.17C570.94,273.63,574.78,271.81,578.36,272.69Z"
        fill="#5c5f8f"
      />
      <path
        d="M754.3,282.07c5.41,3,9.81,8.35,14.23,12.74,5.58,6.44,10.67,13.33,16.11,19.9,2.57,2.94,5.49,5.47,7.64,8.78-9.46-.06-17.85,3.29-26.33,7.07A107.33,107.33,0,0,0,748,342.68c-3.41,3.26-6.63,6.72-10,10-5.51,5.26-8.79,12.39-13.61,18-2.39-2.54-4.52-5.27-6.73-8-6.58-8.05-13.53-15.05-19.11-23.91-2.82-4.26-4.44-9.51-5.6-14.45-1.16-5.25-.29-10.09.55-15.29A35.58,35.58,0,0,1,702,291.66C714.13,276.67,737.69,272.2,754.3,282.07Z"
        fill="#b4cdf4"
      />
      <path
        d="M577.7,278.25c.34.53.67,1.07,1,1.61a12.9,12.9,0,0,0,4.41,5.44c3.19,1.44,6.12-1,8.4,1.53,8.27,7.22,17.32,12.94,25.32,20.66,2.27,2.38,4,5.72,3,9.07-1,2.68-3,5.06-4.49,7.45-6,9.18-12.65,18-19.31,26.7-1.88,2-4.4,5.76-7.56,4.91-2.44-.72-4.61-2.66-6.73-4.05a279,279,0,0,1-37.51-30.82c8.72-12.9,16.2-26.79,25.84-39C572.08,279.14,574.65,278.78,577.7,278.25Z"
        fill="#fee663"
      />
      <path
        d="M578.62,278.2c5.31-.18,8.43,2.84,10.75,7.25a50.33,50.33,0,0,1-6.26-.15,12.9,12.9,0,0,1-4.41-5.44Z"
        fill="#eaeaf2"
      />
      <path
        d="M558.31,284.91a110.36,110.36,0,0,1-12.85,19.25A36.38,36.38,0,0,1,558.31,284.91Z"
        fill="#f0de80"
      />
      <path
        d="M580.14,294.22a53.51,53.51,0,0,1-6.09,11.48c-3.33,4.81-6.71,9.39-11.46,12.9C567.26,309.47,572.2,301,580.14,294.22Z"
        fill="#5c5f8f"
      />
      <path
        d="M591.46,302a42,42,0,0,1-4.63,9.53c-3.46,5.43-7.27,10.49-12.55,14.28A90.61,90.61,0,0,1,591.46,302Z"
        fill="#838084"
      />
      <path
        d="M601.59,311.79c-2.65,8.38-8.53,16-15.05,21.82C589.51,325.34,595.35,317.88,601.59,311.79Z"
        fill="#868284"
      />
      <path
        d="M400.36,323c2.79,2.27,3.33,6.61,5.17,9.64l3,.12c-1.45,1.54-2.8,2.77-4.94,3.17l-1.47-2.45c-1.19,2.75-2.5,5-5.77,5.2-2.81.33-4.32-2.84-3.47-5.23.92-2.65,4.36-4.39,6.54-5.92-1.14-1.76-1.83-3.44-4.07-4-.85.4-1.68.82-2.5,1.26l1.8,2.29c-.81.84-1.81,3-3.19,1.88-1.88-1-1.55-2.81-.47-4.36C393.06,322.07,397.69,320.5,400.36,323Z"
        fill="#365178"
      />
      <path
        d="M792.28,323.49l.59.08c-1.89,2.62-5.58,3.84-8.22,5.74-3.77,2.63-7.89,4.69-11.62,7.39-9.29,6.75-18.06,13.61-25.71,22.22-6,7.93-11.83,16.31-18.69,23.49q-.54-1.28-1-2.58c-.54-3.34-1-6.47-3.18-9.23,4.82-5.56,8.1-12.69,13.61-18,3.4-3.25,6.62-6.71,10-10A107.33,107.33,0,0,1,766,330.56C774.43,326.78,782.82,323.43,792.28,323.49Z"
        fill="#5c5f8f"
      />
      <path
        d="M792.87,323.57l.22,0c7.72,2.83,14.63,8.5,20.5,14.15,11.75,11,21.35,25.18,30.58,38.35,5.53,8.59,11,17.31,15.72,26.37-4.33,2.1-6.84,5.66-10.41,8.67-7.2,6.78-13.64,14.58-21.58,20.42a258.72,258.72,0,0,1-34.07,22.52c-2.35-2.76-5.69-3.61-8.81-5.76a138.68,138.68,0,0,1-25.25-21.77c-7.94-9.08-15.55-18.48-22.92-28-4-5.41-8.81-11.75-9.27-18.69q.51,1.31,1,2.58c6.86-7.18,12.71-15.56,18.69-23.49,7.65-8.61,16.42-15.47,25.71-22.22,3.73-2.7,7.85-4.76,11.62-7.39C787.29,327.41,791,326.19,792.87,323.57Z"
        fill="#b4cdf4"
      />
      <path
        d="M384.24,328c2.49,3,3.73,7.26,5.24,10.84l3.19.43c-1.43,2.37-5.33,2.82-7.72,3.87a4.74,4.74,0,0,1,1.14-3c-1.49-3.48-2.31-6.82-4.77-9.78a6.4,6.4,0,0,0-3.17,2c-.52-.47-1-.94-1.53-1.42C378.8,328.58,380.8,326.59,384.24,328Z"
        fill="#365178"
      />
      <path
        d="M400.11,328.81c1.4,3.36,1.24,7.61-3.45,7.06-.27-1-.51-1.95-.73-2.94C396.29,330.78,398.36,329.72,400.11,328.81Z"
        fill="#fff"
      />
      <path
        d="M244.78,331.62c8.69,2.63,16.89,9.06,19.84,17.86,2.71,12.08.61,25.34-3.41,36.89a23.2,23.2,0,0,1-9.55,9.94c-9.1,4.59-19.24,6.72-29.37,5a19.37,19.37,0,0,1-8.6-4.65c-4-3.69-5.83-9-8.39-13.63-1.42-2.45-1.64-5.22-2.13-8a55.87,55.87,0,0,1,.38-17.74c.67-6.62,1.28-12.89,6.18-17.89C218.54,330.6,233.15,328.2,244.78,331.62Z"
        fill="#f4a295"
      />
      <path
        d="M540.32,329a155.24,155.24,0,0,0,16,13.94c8,5.86,15.85,12,23.9,17.81A548.56,548.56,0,0,1,537,421.58c-8.7,10.61-15.68,22.31-26.34,31.7-6.8,5.52-17.42,6.75-24.41.86s-9.6-16.31-8.6-25.15,4.73-16.7,9.9-23.85c16.57-24.93,34.12-49.15,51.14-73.77C539.2,330.58,539.76,329.8,540.32,329Z"
        fill="#eaeaf2"
      />
      <path
        d="M375.76,332.56h2c-.71,3.48,1.47,6.83,2.68,10l2.94.59c-2,2.11-5.25,2.86-8,3.6A9.44,9.44,0,0,1,377,344c-1.19-2.93-2.09-6.21-3.87-8.84-1.83-2.25-5.05.59-4.69,2.86.38,2.85,1.9,5.6,2.91,8.27l2.77.34c-1.39,2.44-5.72,3.17-8.25,3.84.66-1,1.33-2.06,2-3.07-1.38-3.39-2.76-6.78-4.06-10.2l-2.77.19c-.6-2.19,3.1-2.41,4.43-3.27.54.78,1.07,1.56,1.6,2.35a18.42,18.42,0,0,1,2.06-3.62A4.62,4.62,0,0,1,375.76,332.56Z"
        fill="#365178"
      />
      <path
        d="M338.09,338.58c2.44,6.32,5.06,12.57,7.48,18.89l2.9,0-.39,1.23a24.75,24.75,0,0,1-8.34,2.91c.83-1,1.66-1.94,2.51-2.89-2.21-5.46-4.43-10.92-6.54-16.41a3.4,3.4,0,0,1-3.24-1.38C334.34,340.11,336.22,339.34,338.09,338.58Z"
        fill="#365178"
      />
      <path
        d="M353.53,339.17c5.39-1.27,9,5.27,8.84,9.84-.11,4.8-6,7.35-10.1,6.48-4.2-1.8-6.53-7.43-5.23-11.74C347.88,341.08,351,339.76,353.53,339.17Z"
        fill="#365178"
      />
      <path
        d="M351.85,341.62c1.57-.62,3-.74,4.08.76,1.75,2.51,3.41,6.06,2.94,9.18-1.1,1.64-3.71,3-5.37,1.33A16.53,16.53,0,0,1,350,345,3,3,0,0,1,351.85,341.62Z"
        fill="#fff"
      />
      <path
        d="M332.11,351.19c2.5,2.25,3.19,6.49,4.82,9.41a26.17,26.17,0,0,0,2.8,0c-1,1.94-2.63,2.6-4.42,3.59-.56-.9-1.12-1.79-1.67-2.69-1.19,2.56-2.36,4.81-5.47,5.15-3.09.68-4.9-2.94-3.72-5.47s4.39-4.16,6.56-5.85c-2-2.74-3.07-5-6.78-2.55.63.77,1.27,1.54,1.91,2.3-.81.84-1.42,2.34-2.75,2.21a2.76,2.76,0,0,1-1.17-4.23C324.26,350,329.22,348.39,332.11,351.19Z"
        fill="#365178"
      />
      <path
        d="M312.31,350.41c4.95-.72,8.33,4.7,6.67,9.13-.89,3.23-5,3.86-7.69,5,.61,1.85,1.3,3.66,2,5.45l3.34-.32-.27,1.42a57.62,57.62,0,0,1-8.37,3.42,4.62,4.62,0,0,1,1.81-3.22c-1.94-5-4-9.95-5.93-14.94-1.77,0-2.88-.45-3.35-1.48C304.48,353.43,308.28,351.5,312.31,350.41Z"
        fill="#365178"
      />
      <path
        d="M312.19,353.23c2.55.85,4.53,4.15,3,6.65-.6,1.73-3.18,2.2-4.68,3-1.18-2.83-2.3-5.68-3.25-8.59C308.77,353.83,310.59,353,312.19,353.23Z"
        fill="#fff"
      />
      <path
        d="M789.48,355.72c2.54,1.33,3.08,3.65,1.91,6.16-1.41.55-2.82,1.12-4.22,1.71-1-1.24-3.56-2.8-3.18-4.59C783.46,356.56,787.52,354.31,789.48,355.72Z"
        fill="#5c5f8f"
      />
      <path
        d="M331.37,356.59c.88,2.26,1.65,4.28.05,6.46a2.12,2.12,0,0,1-3.85-1.18C326.92,359.25,329.69,358,331.37,356.59Z"
        fill="#fff"
      />
      <path
        d="M170.34,357.44c11.07-.09,22.14.11,33.21-.2a55.87,55.87,0,0,0-.38,17.74c.49,2.8.71,5.57,2.13,8,2.56,4.65,4.42,9.94,8.39,13.63a19.37,19.37,0,0,0,8.6,4.65c10.13,1.75,20.27-.38,29.37-5a23.2,23.2,0,0,0,9.55-9.94c-.17.59-.33,1.19-.48,1.78a29.33,29.33,0,0,1,2.69,3.7,168.23,168.23,0,0,1,12.64,26.73c-25.81,8.88-51.47,18.2-77.35,26.85-2.89.93-5.11,2.95-8,3.93q-15.84,4.74-31.63,9.68l-.42.12-.3.32c-7.18,1.48-14.13,3.73-21.21,5.62l-.45.09-.32.33c-4.72,1.1-9.37,2-13.91,3.72-2.11.89-4.26.95-6.5,1.29-5.13.7-10.51,3.08-15.68,3.25-3.49-.45-5.19-3.17-6.81-6a71,71,0,0,0-4.33-6.88,29.93,29.93,0,0,0-2.68-8.14c-4.29-8.75-6.46-17.93-10-27-2.06-5.63-3.53-11.54-6-17,8.14-12.1,19.9-21.61,31.92-29.65,5.75-3.89,11.93-6.79,18.06-10C136.26,362,153.13,358.44,170.34,357.44Z"
        fill="#355079"
      />
      <path
        d="M774.24,365.61c2.53-.26,5.84,3.8,4,6.16-.93,1.12-2.39,3-4,2.6a26.84,26.84,0,0,1-4.47-4.38A26.84,26.84,0,0,1,774.24,365.61Z"
        fill="#5c5f8f"
      />
      <path
        d="M800.46,368.35a20.08,20.08,0,0,1,3.18,3.53c.58,2.61-1.19,4.17-3.19,5.4-3.27.75-6.57-2.94-5.16-6.16A19.35,19.35,0,0,1,800.46,368.35Z"
        fill="#5c5f8f"
      />
      <path
        d="M762.38,376.63c1.92-.26,3.21,2.08,4.45,3.23-1.12,1.65-1.92,4.25-4.07,4.68-1.5.75-3.42-.82-4.53-1.7a20,20,0,0,1-.09-4.77C759.59,377,760.5,376.46,762.38,376.63Z"
        fill="#5c5f8f"
      />
      <path
        d="M791.39,385.91c-2.6.88-5,2.79-7.09-.12-.39-1.78-.89-4.87,1.28-5.67C789.27,377.36,793.62,382.12,791.39,385.91Z"
        fill="#5c5f8f"
      />
      <path
        d="M810.56,382.06c1.26.05,2.52,2.15,3.5,2.9L811.13,388c-1-.36-1.93-.76-2.89-1.18C807.29,384.43,809,383.5,810.56,382.06Z"
        fill="#5c5f8f"
      />
      <path
        d="M777.18,391c.64,1.38,1.23,2.77,1.84,4.17-1.71,1.37-3.44,3.54-5.8,2-2.55-1.09-2.46-2.61-2-5.08C773.31,390.41,774.63,389.63,777.18,391Z"
        fill="#5c5f8f"
      />
      <path
        d="M798.25,391.6c1.88-.19,3.52,1.92,4.5,3.28.34,2.76-2.83,3.27-4.57,4.65a22.26,22.26,0,0,1-3.22-3.44C794.52,394.27,797,392.54,798.25,391.6Z"
        fill="#5c5f8f"
      />
      <path
        d="M822.34,393.64c2.73-.33,4.31,3,2.89,5.11a34.54,34.54,0,0,1-2.65,2.76c-1.07-.59-2.16-1.18-3.24-1.75C817.74,397.44,819.36,393.32,822.34,393.64Z"
        fill="#5c5f8f"
      />
      <path
        d="M785.25,402.6c1.9-.23,3.35,2.06,4.63,3.19q-1.35,2.22-2.65,4.47c-1,0-2,0-3,0-2.08-.64-2.48-2.4-2.22-4.38A10,10,0,0,1,785.25,402.6Z"
        fill="#5c5f8f"
      />
      <path
        d="M859.89,402.46a39.05,39.05,0,0,0,4.54,6.84c-5.25,7-10.25,14-16.13,20.59-3.5,5-8.46,8.92-12.62,13.44-9.37,8.72-19.94,15.76-32.12,20l-1.11-1.57c-3.19-1-6.53-1.66-9.79-2.37.36-1.77.74-3.53,1.17-5.28a258.72,258.72,0,0,0,34.07-22.52c7.94-5.84,14.38-13.64,21.58-20.42C853.05,408.12,855.56,404.56,859.89,402.46Z"
        fill="#5c5f8f"
      />
      <path
        d="M810.45,405.58c.84.69,2.9,2,2.81,3.16-.85,1-1.78,1.89-2.72,2.82-1.26-.79-2.52-1.56-3.78-2.34C807.82,407.8,808.2,405.31,810.45,405.58Z"
        fill="#5c5f8f"
      />
      <path
        d="M864.43,409.3c7.45,8.08,15.88,16.8,21,26.57,7.93,15.81,3.15,36.67-10,48.18a50.15,50.15,0,0,1-26.07,9.85,40.7,40.7,0,0,1-19.25-3.72c-7.19-3.65-12.63-9.12-17.61-15.36-3-3.86-6.2-7.47-8.93-11.53,12.18-4.2,22.75-11.24,32.12-20,4.16-4.52,9.12-8.43,12.62-13.44C854.18,423.34,859.18,416.32,864.43,409.3Z"
        fill="#b4cdf4"
      />
      <path
        d="M796.54,416.13a24.29,24.29,0,0,1,3,.87c0,1.32.05,2.63.05,3.94l-3.07,1-2.9-3C794.56,418,795.51,417,796.54,416.13Z"
        fill="#5c5f8f"
      />
      <path d="M158.71,459.16l.42-.12-.29.35-.43.09Z" fill="#9395b5" />
      <path d="M136.75,465.19l.45-.09-.32.34-.45.08Z" fill="#969ab8" />
    </svg>
  </IconContainer>
);

export default ItemCollection;
