import React, { Fragment } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import { BoxButton } from '../../../components/async/SharedComponents';
import PillsBottle from '../../../static/PillsBottle.png';

const WhiteBullet = styled(Flex)`
  background: #ffffff;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 20px;
  color: #354e79;
  justify-content: center;
  align-items: center;
`;

const WhatExpect30days = ({ onSubmit, onLearnMore }) => {
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }} pb="60px">
        <img
          src={PillsBottle}
          width="112px"
          style={{ margin: '12px auto' }}
          alt=""
        />

        <Text
          textAlign="left"
          fontSize="14px"
          fontWeight="600"
          lineHeight="21px"
          mt={3}
          color="#F6A293"
        >
          What to expect?
        </Text>
        <Box m={2} mt={3} mb={3}>
          <Heading textAlign="left">
            One-time 30 day supply of medication
          </Heading>
        </Box>

        <Flex flexDirection="row" alignItems="center" mb={3}>
          <WhiteBullet>1</WhiteBullet>
          <Text style={{ flex: 1 }}>
            Complete health questionnaire.
          </Text>
        </Flex>

        <Flex flexDirection="row" alignItems="center" mb={3}>
          <WhiteBullet>2</WhiteBullet>
          <Text style={{ flex: 1 }}>
          Your provider will reach out with additional questions or to recommend the best solution for you.
          </Text>
        </Flex>

        <Text fontWeight="600">How to get 90 day supply?</Text>
        <Text>
          You will need to get lab work done. You can use Paloma’s{' '}
          <span
            onClick={() => {
              onLearnMore('athomekit');
            }}
            style={{
              color: '#F6A293',
              fontWeight: 600,
              textDecoration: 'underline',
            }}
          >
            at home thyroid test kit
          </span>{' '}
          to update your thyroid levels and be eligibile for a 90 day
          prescription
        </Text>

        <Text
          mt={3}
          textAlign="left"
          fontSize="12px"
          fontWeight="300"
          lineHeight="24px"
          onClick={() => onLearnMore('athomekit')}
          style={{
            textTransform: 'uppercase',
            textDecoration: 'underline',
            textAlign: 'right',
          }}
        >
          learn more
        </Text>
      </Flex>

      <BoxButton className={'visible'}>
        <Button
          variant="primary"
          onClick={() => {
            onSubmit(null);
          }}
          width={[1, 1 / 2, 1 / 4]}
          style={{
            marginLeft: '12px',
            marginRight: '12px',
          }}
        >
          Continue
        </Button>
      </BoxButton>
    </Fragment>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(WhatExpect30days);
