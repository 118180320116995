import React, { Component, Fragment } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';

import { TextInput } from '../../../components/TextInput';
import { FormField } from '../../../components/FormField';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import { sleep } from '../../../utils/sleep';
import { BoxButton } from '../../../components/async/SharedComponents';

const Btn = styled(Button)`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: 1px solid #354e79;
  font-size: 14px;
  font-weight: 300;
  color: #354e79;

  transition: background 300ms ease-in;
  &.selected {
    background: #354e79;
    border: 1px solid #354e79;
    box-sizing: border-box;
    color: white;
  }
`;

class DobSex extends Component {
  constructor(props) {
    super(props);
    if (props.data && props.data.skipIfNotQuestionId) {
      let isFound = false;
      for (let index = 0; index < props.userData.length; index++) {
        const element = props.userData[index];
        for (let index = 0; index < element.values.length; index++) {
          const val = element.values[index];
          if (
            val.questionId &&
            val.questionId === props.data.skipIfNotQuestionId
          ) {
            isFound = true;
            break;
          }
        }
      }
      if (!isFound) {
        props.wizard.next();
      }
    }

    let defaults;
    let sel;
    let isDisplay;
    if (props.savedData) {
      defaults = this.unFormatSubmit(props.savedData, props.data.id);
      sel=defaults.sex
    } else {
      defaults = (props.data || {}).defaults;
    }

    this.state = {
      loading: false,
      isInitialized: true,
      defaults,
      display: isDisplay,
      selected: sel || '',
    };
  }

  unFormatSubmit = (values, qId) => {
    let payload = {};

    for (const k in values) {
payload={...values[k].value}
    }

    return payload;
  };

  handleClick = async element => {
    const { data, onSubmit, wizard } = this.props;
    this.setState({ selected: element });

    await sleep(550);
    if (element.isDisqualifying) {
      const dta = JSON.parse(JSON.stringify(this.props.userData));
      if (element.questionId) {
        const payload = {
          values: [{ questionId: element.questionId, answer: element.answer }],
          endpoint: data.endpoint,
        };
        dta.push(payload);
      }
    } else {
      if (element.placeholder) {
        this.setState({ display: true });
      } else {
        const payload = { questionId: element.questionId, answer: element.answer };

        onSubmit(payload, data.id, wizard, element);
      }
    }
  };

  onSelectSex = element => {
    this.setState({ selected: element });
  };

  shiftView = () => {
    setTimeout(() => {
      window.scrollTo(0, this.inputRef.offsetTop);
    }, 150);
  };

  render() {
    const { data, onSubmit, wizard } = this.props;
    const { selected, loading, defaults, isInitialized } = this.state;
    if (!isInitialized) {
      return <div />;
    }

    return (
      <Fragment>
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          {data.title ? (
            <Box m={2} mt="0" mb={3}>
              <Heading textAlign="left">{data.title}</Heading>
            </Box>
          ) : null}
          {data.subTitle ? (
            <Box m={2} mt="0" mb={3}>
              <Text
                textAlign="left"
                fontSize="16px"
                fontWeight="300"
                lineHeight="24px"
              >
                {data.subTitle}
              </Text>
            </Box>
          ) : null}
          <Box m={2} mt="0">
            <Heading textAlign="left" fontSize="20px">
              {data.prompt}
            </Heading>
          </Box>

          <Formik
            initialValues={defaults}
            validationSchema={data.schema || ''}
            onSubmit={(values, actions) => {
              if(!values ||!values.dob ||!selected){
                return
              }
              let value = {
                dob: values.dob,
                sex: selected,
              };

              const payload = [
                {
                  value,
                },
              ];
              onSubmit(payload, actions, wizard, selected);
            }}
            render={({ values, isValid, errors, touched, submitForm }) => {
              return (
                <Form style={{ display: 'flex', flex: 1 }}>
                  <Flex flex={1} flexDirection="column">
                    <Flex flex={1} flexDirection="column" m={2}>
                      <Text
                        style={{
                          fontStyle: 'normal',
                          fontWeight: '600',
                          fontSize: '20px',
                          lineHeight: '30px',
                          color: '#354E79',
                        }}
                      >
                        Date of Birth
                      </Text>
                      <Flex my={3} flexWrap="wrap" >
                        <FormField
                          width={[1, 1 / 2]}
                          mb={3}
                          error={touched['dob'] && errors['dob']}
                          // alignSelf="center"
                        >
                          <TextInput
                            style={{
                              border: '1px solid rgba(53, 78, 121, 0.1)',
                            }}
                            name={'dob'}
                            placeholder="MM/DD/YYYY"
                            type={'date'}
                          />
                        </FormField>
                      </Flex>

                      <Text
                        style={{
                          fontStyle: 'normal',
                          fontWeight: '600',
                          fontSize: '20px',
                          lineHeight: '30px',
                          color: '#354E79',
                        }}
                      >
                        Legal sex
                      </Text>
                      <Flex my={3} justifyContent="center" flexDirection="row">
                        <FormField
                          width={[1 / 2]}
                          error={touched['female'] && errors['female']}
                        >
                          <Btn
                            variant="white"
                            type="button"
                            onClick={() => this.onSelectSex('female')}
                            style={{
                              borderRadius: '10px 0 0 10px',
                            }}
                            className={
                              selected === 'female' ? 'selected' : 'white'
                            }
                          >
                            Female
                          </Btn>
                        </FormField>
                        <FormField
                          width={[1 / 2]}
                          error={touched['male'] && errors['male']}
                        >
                          <Btn
                            variant="white"
                            type="button"
                            style={{
                              borderRadius: '0 10px 10px 0',
                            }}
                            onClick={() => this.onSelectSex('male')}
                            className={
                              selected === 'male' ? 'selected' : 'white'
                            }
                          >
                            Male
                          </Btn>
                        </FormField>
                      </Flex>
                    </Flex>
                    {/* <Flex justifyContent="center">
                    </Flex> */}
                  </Flex>
          <BoxButton
            className={
              !loading &&
              values &&
              values.dob &&
              selected ? 'visible' : ''
            }
          >
          <Button
            disabled={
              (errors && errors.length) ||
              loading ||
              !values ||
              !values.dob ||
              !selected
            }
            variant="primary"
            width={[1, 1 / 2, 1 / 4]}
            type="submit"
          >
            Continue
          </Button>
            </BoxButton>
                </Form>
              );
            }}
          />
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(DobSex);
// export default withWizard(Single);
