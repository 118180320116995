import React, { Fragment, useState } from 'react';
import { Box, Flex, Heading } from 'rebass';
import Select from 'react-select';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { csvStringToArray } from '../../utils/csv';
import Identify from './backfills/Identify';
import { BATCH_BACKFILL } from '../../graphql';
import Header from '../../components/Header';
import FullLayout2 from '../../components/FullLayout';
import { Checkbox } from '../../components/CheckboxInput';
import { OrderCompletedKits } from './backfills/OrderCompletedKits';
import OrderCompletedAppointment from './backfills/OrderCompletedAppointment';
import OrderCompletedNutritionists from './backfills/OrderCompletedNutritionists';
import OrderCompletedSupplements from './backfills/OrderCompletedSupplements';
import OrderCompletedMemberships from './backfills/OrderCompletedMemberships';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const CardContainer = styled(Flex)`
  max-width: 100%;
`;

const markers = [
  {
    label: 'Backfill - Identity',
    value: 'Identity',
  },
  {
    label: 'Backfill - Order Completed - Kits',
    value: 'OrderCompletedKits',
  },
  {
    label: 'Backfill - Order Completed - Supplements',
    value: 'OrderCompletedSupplements',
  },
  {
    label: 'Backfill - Order Completed - Memberships',
    value: 'OrderCompletedMemberships',
  },
  {
    label: 'Backfill - Order Completed - Appointments',
    value: 'OrderCompletedAppointments',
  },
  {
    label: 'Backfill - Order Completed - Nutritionists',
    value: 'OrderCompletedNutritionists',
  },
];

const BigQueryBackfill = ({ client }) => {
  const [selectedMarker, setSelectedMarker] = useState();
  const [sendToMp, setSendToMp] = useState(false);
  const [sendToBq, setSendToBq] = useState(false);

  const preChecks = () => {
    if (!sendToBq && !sendToMp) {
      alert('You must select at least 1 destination');
      return false;
    }

    return true;
  };
  const handleSubmit = async (values, callback) => {
    try {
      const res = await client.mutate({
        mutation: BATCH_BACKFILL,
        variables: {
          origin: selectedMarker,
          sendToMixPanel: sendToMp,
          sendToBigQuery: sendToBq,
          data: values,
        },
      });

      callback(res.data.batchBackfill);
    } catch (error) {
      alert('an error occured see console');
      console.log('ERR', error);
    }
  };

  const fileAccepted = (f, callback, typeMap) => {
    for (let index = 0; index < f.length; index++) {
      const element = f[index];

      const reader = new FileReader();
      reader.onload = event => {
        var decodedString = atob(
          event.target.result.replace('data:text/csv;base64,', '')
        );
        const aaa = csvStringToArray(decodedString, true, typeMap);
        console.log({ aaa });
        if (callback) {
          callback(aaa);
        }
      };
      reader.readAsDataURL(element);
    }
  };

  return (
    <Fragment>
      <Container>
        <Flex flex={1}>
          <Header dark={false} />
        </Flex>
        <Box mb={4}>
          <Heading textAlign="center">BigQuery Backfill</Heading>
        </Box>
        <Flex justifyContent="center" alignItems="center">
          <Box style={{ width: '400px', minWidth: '400px', margin: '0 auto' }}>
            <Flex flexDirection="column">
              <Select
                classNamePrefix="region-select"
                options={markers}
                value={markers.filter(
                  element => element.value === selectedMarker
                )}
                onChange={option => {
                  setSelectedMarker(option.value);
                }}
                theme={base => ({
                  ...base,
                  colors: {
                    ...base.colors,
                    primary: '#364f79',
                    primary50: '#dae6fa',
                  },
                })}
              />
              <Flex flexDirection="row" mt={4} mb={4} justifyContent='space-between'>
                <Checkbox
                  name="exportToMp"
                  onClick={option => {
                    setSendToMp(!sendToMp);
                  }}
                  isChecked={sendToMp}
                  styles={{
                    checked: {
                      Box: {
                        backgroundColor: 'rgb(54, 79, 121)',
                      },
                    },
                  }}
                  label="Send To Mixpanel"
                />
                <Checkbox
                  name="exportToBq"
                  onClick={option => {
                    setSendToBq(!sendToBq);
                  }}
                  isChecked={sendToBq}
                  styles={{
                    checked: {
                      Box: {
                        backgroundColor: 'rgb(54, 79, 121)',
                      },
                    },
                  }}
                  label="Send To BigQuery"
                />
              </Flex>
            </Flex>
          </Box>
        </Flex>
        <Flex flex={1}>
          <CardContainer flex={1} px={3}>
            {selectedMarker === 'Identity' && (
              <Identify
                handleSubmit={handleSubmit}
                fileAccepted={fileAccepted}
                preChecks={preChecks}
              />
            )}
            {selectedMarker === 'OrderCompletedKits' && (
              <OrderCompletedKits
                preChecks={preChecks}
                handleSubmit={handleSubmit}
                fileAccepted={fileAccepted}
              />
            )}
            {selectedMarker === 'OrderCompletedAppointments' && (
              <OrderCompletedAppointment
                preChecks={preChecks}
                handleSubmit={handleSubmit}
                fileAccepted={fileAccepted}
              />
            )}
            {selectedMarker === 'OrderCompletedNutritionists' && (
              <OrderCompletedNutritionists
                preChecks={preChecks}
                handleSubmit={handleSubmit}
                fileAccepted={fileAccepted}
              />
            )}
            {selectedMarker === 'OrderCompletedSupplements' && (
              <OrderCompletedSupplements
                preChecks={preChecks}
                handleSubmit={handleSubmit}
                fileAccepted={fileAccepted}
              />
            )}
            {selectedMarker === 'OrderCompletedMemberships' && (
              <OrderCompletedMemberships
                preChecks={preChecks}
                handleSubmit={handleSubmit}
                fileAccepted={fileAccepted}
              />
            )}
          </CardContainer>
        </Flex>
      </Container>
      <FullLayout2 />
    </Fragment>
  );
};

export default withApollo(BigQueryBackfill);
