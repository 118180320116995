import React from 'react';
import { Button, Text, Flex } from 'rebass';
import styled from 'styled-components';
import { BoxButton } from '../../../components/async/SharedComponents';
import Imge from '../../../static/your_lab.png';
import { AddonCard } from './OtherCustomersFavourites';
import palomaKit from '../../../static/palomaKit.png';
import { useMount } from '../../../utils/hooks/lifecycle';
import { ASYNC_SOFT_DISQUALIFY } from '../../../graphql';

const BlueHeading = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #354e79;
`;

const TestFirstNoHypo = ({ onSubmit, onLearnMore, client, onSkip }) => {
  return (
    <Flex flex={1} flexDirection="column" pb="60px">
      <Flex flex={1} flexDirection="column" p="16px">
        <img src={Imge} alt="" style={{ width: '50%', margin: '0 auto', maxWidth: '150px' }} />

        <BlueHeading mb={3} mt={3}>
          Update your levels with a thyroid test kit
        </BlueHeading>

        <Text mb={3}>
          Considering that your thyroid test results are more than four months old, it is necessary to conduct updated thyroid lab tests to ensure accurate assessment, diagnostic and management
        </Text>
        <Text mb={4}>Test your TSH, free T4, Free T3 and TPO antibodies from the comfort of your own home with an at home thyroid test kit and get your results in just a few days.</Text>
        <AddonCard
          title="At-Home Thyroid Test Kit"
          description="Test your TSH, free T4, Free T3 and TPO antibodies from the comfort of your own home"
          price={'$99'}
          discounted={''}
          image={palomaKit}
          onClick={onSubmit}
          containerStyle={{
            flex: 'initial',
          }}
          hidebox={true}
          showContinue={!client}
        />
        <Text
          fontWeight="300"
          fontSize="12px"
          mb={4}
          style={{
            textDecoration: 'underline',
            textTransform: 'uppercase',
            cursor: 'pointer',
            textAlign: 'right',
            marginTop: '8px',
          }}
          onClick={() => onLearnMore('athomekit')}
        >
          LEARN MORE
        </Text>
        {!!onSkip && (
          <Flex mt={3} justifyContent="center" alignItems="center" flexDirection="column" textAlign="center">
            <Text textAlign="center" mb={1} fontSize="18px" style={{ cursor: 'pointer' }} onClick={() => onSkip()}>
              <span style={{ color: '#364f79' }}>Skip</span>
              <span style={{ color: '#f9a394', fontWeight: '600' }}>{' >'}</span>
            </Text>
          </Flex>
        )}
      </Flex>
      {!onSkip && (
        <BoxButton className="visible">
          <Button
            variant="primary"
            onClick={onSubmit}
            style={{
              marginLeft: '12px',
              marginRight: '12px',
            }}
            width={[1, 1 / 2, 1 / 4]}
          >
            Continue
          </Button>
        </BoxButton>
      )}
    </Flex>
  );
};

export default TestFirstNoHypo;
