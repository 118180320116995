import React from 'react';
import FoodTrackingSim from './foodTrackingSim';
import DefaultSim from './defaultSim';
import SleepMeditationStorySim from './sleepMeditationStorySim';
import SleepMeditationAudioSim from './sleepMeditationAudioSim';

const Simulators = ({ values, original, calculateY }) => {
  if (!values.ScreenFormat || !values.ScreenFormat.label) {
    return null;
  }

  if (values.ScreenFormat.label === 'Food Tracking') {
    return <FoodTrackingSim values={values} />;
  }

  if (values.ScreenFormat.label === 'Sleep Meditation Story') {
    return (
      <SleepMeditationStorySim
        values={values}
        original={original}
        calculateY={calculateY}
      />
    );
  }

  if (values.ScreenFormat.label === 'Sleep Meditation Audio') {
    return (
      <SleepMeditationAudioSim
        values={values}
        original={original}
        calculateY={calculateY}
      />
    );
  }

  return (
    <DefaultSim values={values} original={original} calculateY={calculateY} />
  );
};

export default Simulators;
