import React, { useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import SpinLoader from '../../components/SpinLoader';
import { Input } from '../../components/TextInput';
import { WhiteCard } from './components/WhiteCard';
import { Summary } from './components/Summary';
import { ConfirmAppointment } from './components/ConfirmAppointment';
import CancelAppointment from './components/Cancellation';
import RescheduleAppointment from './components/Reschedule'
import { CardHeaderLoading } from './components/CardHeader';
import { Skeleton } from '../../components/loadingSkeleton/Skeleton';

export const GET_DETAILS_APPOINTMENT_CONFIRM = gql`
  query getAppointmentConfirmationDetails($id: String!, $appointmentId: String!, $token: String!) {
    getAppointmentConfirmationDetails(id: $id, appointmentId: $appointmentId, token: $token) {
      id
      mustCompleteIntake
      providerName
      provider
      providerPicture
      appointmentDate
      appointmentTime
      tz
      isConfirmed
    }
  }
`;

const AppointmentConfirmationInner = ({ token, appointmentId, athenaId, history, match, location }) => {
  return (
    <Flex flexDirection="column" alignItems={'center'} flex={1} style={{ zIndex: 1 }}>
      <Query
        query={GET_DETAILS_APPOINTMENT_CONFIRM}
        variables={{
          id: athenaId,
          appointmentId,
          token,
        }}
      >
        {({ loading, data, error }) => {
          if (!!loading) {
            return (
              <>
                <Heading>Appointment</Heading>

                <Flex alignItems={'center'} mt={'22px'}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clipPath="url(#clip0_1_101)">
                      <path
                        d="M8.515 1.019C8.34363 1.00635 8.17184 1.00001 8 1V0C8.19654 9.58276e-05 8.393 0.00743414 8.589 0.022L8.515 1.019ZM10.519 1.469C10.1984 1.3453 9.86922 1.24537 9.534 1.17L9.753 0.194C10.136 0.28 10.513 0.394 10.879 0.536L10.519 1.469ZM11.889 2.179C11.746 2.08365 11.5996 1.99359 11.45 1.909L11.943 1.039C12.2849 1.23274 12.6121 1.45132 12.922 1.693L12.307 2.482C12.1714 2.37623 12.032 2.2755 11.889 2.18V2.179ZM13.723 3.969C13.5252 3.68798 13.3069 3.42192 13.07 3.173L13.794 2.483C14.064 2.768 14.314 3.073 14.541 3.393L13.723 3.969ZM14.467 5.321C14.4014 5.16246 14.33 5.00636 14.253 4.853L15.146 4.403C15.3226 4.75409 15.473 5.11774 15.596 5.491L14.646 5.804C14.5923 5.64087 14.5326 5.47976 14.467 5.321ZM14.997 7.828C14.9889 7.48434 14.9555 7.14174 14.897 6.803L15.882 6.633C15.949 7.019 15.988 7.411 15.998 7.803L14.998 7.828H14.997ZM14.866 9.366C14.899 9.196 14.926 9.027 14.947 8.856L15.94 8.979C15.892 9.36915 15.8151 9.75521 15.71 10.134L14.746 9.867C14.792 9.702 14.832 9.535 14.866 9.366ZM13.914 11.745C14.098 11.455 14.26 11.151 14.4 10.837L15.314 11.242C15.154 11.602 14.969 11.948 14.759 12.28L13.914 11.745ZM12.95 12.95C13.072 12.828 13.189 12.702 13.3 12.572L14.058 13.225C13.9296 13.3738 13.7959 13.5179 13.657 13.657L12.95 12.95Z"
                        fill="#4E658A"
                      />
                      <path
                        d="M7.99995 1C6.84883 1.00008 5.71549 1.28405 4.70033 1.82674C3.68516 2.36943 2.8195 3.1541 2.18001 4.11125C1.54052 5.0684 1.14694 6.16848 1.03414 7.31406C0.921345 8.45964 1.0928 9.61536 1.53333 10.6789C1.97385 11.7423 2.66985 12.6808 3.55968 13.4111C4.4495 14.1413 5.50569 14.6409 6.63468 14.8655C7.76368 15.0901 8.93064 15.0328 10.0322 14.6987C11.1338 14.3645 12.1359 13.7639 12.95 12.95L13.6569 13.657C12.7267 14.5878 11.5812 15.2747 10.322 15.657C9.06278 16.0393 7.72872 16.105 6.43801 15.8485C5.14731 15.5919 3.93981 15.021 2.9225 14.1862C1.90519 13.3515 1.10949 12.2787 0.605884 11.0629C0.102284 9.84711 -0.0936637 8.52588 0.0354041 7.21627C0.164472 5.90666 0.61457 4.6491 1.34582 3.55502C2.07707 2.46094 3.06689 1.56411 4.22758 0.944003C5.38827 0.323895 6.684 -0.000348732 7.99995 2.81458e-07V1Z"
                        fill="#4E658A"
                      />
                      <path
                        d="M7.5 3C7.63261 3 7.75979 3.05268 7.85355 3.14645C7.94732 3.24021 8 3.36739 8 3.5V8.71L11.248 10.566C11.3598 10.6334 11.4408 10.7419 11.4736 10.8683C11.5065 10.9946 11.4886 11.1288 11.4238 11.2422C11.3591 11.3556 11.2525 11.4391 11.127 11.4749C11.0014 11.5108 10.8669 11.4961 10.752 11.434L7.252 9.434C7.17547 9.39029 7.11186 9.32712 7.06761 9.25091C7.02335 9.17469 7.00003 9.08813 7 9V3.5C7 3.36739 7.05268 3.24021 7.14645 3.14645C7.24021 3.05268 7.36739 3 7.5 3Z"
                        fill="#4E658A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_101">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <Box flex={1} ml={2}>
                    <Skeleton width="226px" height="21px" />
                  </Box>
                </Flex>

                <WhiteCard justifyContent={'center'}>
                  <CardHeaderLoading />
                  <Flex style={{ cursor: 'pointer', width: '100%' }} mb={4} alignItems={'center'} onClick={() => history.push(`${token}/confirm`)}>
                    <svg width="40" height="36" style={{ opacity: 0.35 }} viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M37.6666 27.9003C36.0203 29.9174 33.9648 31.2741 31.7667 32.4054C26.4698 35.1317 20.8905 35.7803 15.0696 34.5706C13.8864 34.3247 12.6774 34.195 11.4761 34.0466C9.32792 33.7808 7.35005 33.0786 5.73624 31.6119C3.89968 29.9426 2.58259 27.8864 1.69226 25.5706C-0.047105 21.0456 -0.557288 16.4226 0.68848 11.6808C1.1295 10.0014 1.99363 8.52402 2.88678 7.04847C4.21365 4.85534 6.03396 3.15649 8.06623 1.67164C9.32278 0.75368 10.8228 0.392312 12.3471 0.236466C18.2964 -0.370466 24.1377 0.174495 29.8243 2.06208C30.8553 2.40435 31.9541 2.80975 32.7623 3.49394C33.9497 4.53431 35.0209 5.70073 35.957 6.97271C37.5708 9.09606 38.4525 11.575 39.0088 14.1657C39.6025 16.9329 40.0913 19.7183 39.7785 22.5662C39.5591 24.559 38.7379 26.3261 37.6666 27.9003Z"
                        fill="#E7F0FF"
                      />
                    </svg>

                    <Box flex={1} ml={2}>
                      <Skeleton width="102px" height="16px" />
                    </Box>
                    <Skeleton width="8px" height="13px" />
                  </Flex>

                  <Flex style={{ cursor: 'pointer', width: '100%' }} mb={4} alignItems={'center'} onClick={() => history.push(`${token}/confirm`)}>
                    <svg width="40" height="36" viewBox="0 0 40 36" style={{ opacity: 0.35 }} fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M37.6666 27.9003C36.0203 29.9174 33.9648 31.2741 31.7667 32.4054C26.4698 35.1317 20.8905 35.7803 15.0696 34.5706C13.8864 34.3247 12.6774 34.195 11.4761 34.0466C9.32792 33.7808 7.35005 33.0786 5.73624 31.6119C3.89968 29.9426 2.58259 27.8864 1.69226 25.5706C-0.047105 21.0456 -0.557288 16.4226 0.68848 11.6808C1.1295 10.0014 1.99363 8.52402 2.88678 7.04847C4.21365 4.85534 6.03396 3.15649 8.06623 1.67164C9.32278 0.75368 10.8228 0.392312 12.3471 0.236466C18.2964 -0.370466 24.1377 0.174495 29.8243 2.06208C30.8553 2.40435 31.9541 2.80975 32.7623 3.49394C33.9497 4.53431 35.0209 5.70073 35.957 6.97271C37.5708 9.09606 38.4525 11.575 39.0088 14.1657C39.6025 16.9329 40.0913 19.7183 39.7785 22.5662C39.5591 24.559 38.7379 26.3261 37.6666 27.9003Z"
                        fill="#E7F0FF"
                      />
                    </svg>

                    <Box flex={1} ml={2}>
                      <Skeleton width="94px" height="16px" />
                    </Box>
                    <Skeleton width="8px" height="13px" />
                  </Flex>

                  <Flex style={{ cursor: 'pointer', width: '100%' }} alignItems={'center'} onClick={() => history.push(`${token}/confirm`)}>
                    <svg width="40" height="36" viewBox="0 0 40 36" style={{ opacity: 0.35 }} fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M37.6666 27.9003C36.0203 29.9174 33.9648 31.2741 31.7667 32.4054C26.4698 35.1317 20.8905 35.7803 15.0696 34.5706C13.8864 34.3247 12.6774 34.195 11.4761 34.0466C9.32792 33.7808 7.35005 33.0786 5.73624 31.6119C3.89968 29.9426 2.58259 27.8864 1.69226 25.5706C-0.047105 21.0456 -0.557288 16.4226 0.68848 11.6808C1.1295 10.0014 1.99363 8.52402 2.88678 7.04847C4.21365 4.85534 6.03396 3.15649 8.06623 1.67164C9.32278 0.75368 10.8228 0.392312 12.3471 0.236466C18.2964 -0.370466 24.1377 0.174495 29.8243 2.06208C30.8553 2.40435 31.9541 2.80975 32.7623 3.49394C33.9497 4.53431 35.0209 5.70073 35.957 6.97271C37.5708 9.09606 38.4525 11.575 39.0088 14.1657C39.6025 16.9329 40.0913 19.7183 39.7785 22.5662C39.5591 24.559 38.7379 26.3261 37.6666 27.9003Z"
                        fill="#E7F0FF"
                      />
                    </svg>

                    <Box flex={1} ml={2}>
                      <Skeleton width="92px" height="16px" />
                    </Box>
                    <Skeleton width="8px" height="13px" />
                  </Flex>
                </WhiteCard>
              </>
            );
          }

          if (error) {
            return (
              <>
                <Heading>Appointment</Heading>

                <WhiteCard>
                  <svg xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.23, margin: '0 auto' }} width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path d="M27.456 2H12.544L2 12.544V27.456L12.544 38H27.456L38 27.456V12.544L27.456 2ZM22 30H18V26H22V30ZM22 22H18V10H22V22Z" fill="#F24E1E" />
                  </svg>
                  {error.graphQLErrors ? (
                    <Text textAlign={'center'} fontWeight={500} mt={4} style={{opacity: 0.65}}>
                      {error.graphQLErrors
                        .map((x) => ((x.message || '').toUpperCase().indexOf('JWT') > -1 ? 'Invalid parameters' : (x.message || '').replace('Error: ', '') || 'Unable to retrieve appointment'))
                        .join('\n')}
                    </Text>
                  ) : (
                    <Text textAlign={'center'} fontWeight={500} mt={4} style={{opacity: 0.65}}>
                      An error occured
                    </Text>
                  )}
                </WhiteCard>
              </>
            );
          }
          return (
            <>
              <Route
                path="/appointment_confirm/:appointmentId/:athenaId/:token"
                exact
                component={(props) => <Summary appointmentId={appointmentId} athenaId={athenaId} token={token} history={history} details={data.getAppointmentConfirmationDetails} />}
              />
              <Route
                path="/appointment_confirm/:appointmentId/:athenaId/:token/confirm"
                component={(props) => <ConfirmAppointment history={history} token={token} appointmentId={appointmentId} athenaId={athenaId} details={data.getAppointmentConfirmationDetails} />}
              />
              <Route
                path="/appointment_confirm/:appointmentId/:athenaId/:token/cancel"
                component={(props) => <CancelAppointment history={history} token={token} appointmentId={appointmentId} athenaId={athenaId} details={data.getAppointmentConfirmationDetails} />}
              />
              <Route
                path="/appointment_confirm/:appointmentId/:athenaId/:token/reschedule"
                component={(props) => <RescheduleAppointment history={history} token={token} appointmentId={appointmentId} athenaId={athenaId} details={data.getAppointmentConfirmationDetails} />}
              />
            </>
          );

          // <Flex>
          //   <Button onClick={() => history.push('main')}>main</Button>
          //   <Button onClick={() => history.push('cancel')}>cancel</Button>
          // </Flex>
        }}
      </Query>
    </Flex>
  );
};
export default withRouter(AppointmentConfirmationInner);
