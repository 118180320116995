import React from 'react';
import './draggableChild.css';

const DraggableChildComponent = ({ dragStart, dragEnter, dragEnd, children, inv, onMouseDown }) => {
  return (
    <div
      draggable
      onMouseDown={(e) => {
        e.stopPropagation();
        onMouseDown(inv);
      }}
      onDragStart={dragStart}
      onDragEnter={dragEnter}
      onDragEnd={dragEnd}
      className="grabbable"
    >
      {children}
    </div>
  );
};

export default DraggableChildComponent;
