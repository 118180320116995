import React from 'react';
import {
  Elements,
} from 'react-stripe-elements';
import OrderKitComponent from './OrderKitComponent';


class OrderKitWrapper extends React.Component {
  render(){
    return (
        <Elements>
          <OrderKitComponent {...this.props} />
        </Elements>
    );
  }
};

export default OrderKitWrapper;
