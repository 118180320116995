import momentTimezone from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Mutation, Query, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import ConfirmModal from '../../components/ConfirmModal';
import { Skeleton } from '../../components/loadingSkeleton/Skeleton';
import { GENERATE_CIRCLE_SO_CALLBACK, GET_SURVEY_REMINDER, HAS_UNREGISTERED_KITS, SEND_MOBILE_APP_DOWNLOAD_LINK } from '../../graphql';
import withSession from '../../lib/withSession';
import { FOLLOWUP_SURVEYS_ID } from '../../utils/followupSurveysUtils';

const Circle = styled(Box)`
  width: 16.78px;
  height: 16.78px;
  border-radius: 50%;

  background: #ffffff;
  border: 1px solid #15d89d;
  margin-right: 10px;
`;

const ModalInner = styled(Flex)`
  max-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ToDoEntry = ({ text, onClick, isLoading, disabled }) => {
  return (
    <Flex alignItems={'center'} style={{ cursor: !isLoading && !disabled ? 'pointer' : '' }} mt="16px" onClick={onClick}>
      {!isLoading && <Circle />}
      <Text fontSize={'14px'} flex={1} fontWeight="300" color={disabled ? 'grey' : ''}>
        {text}
      </Text>
      {!isLoading && (
        <svg style={{ marginLeft: '10px' }} width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.8743 7.71726L1.79342 13.6516C1.59874 13.8401 1.28392 13.8401 1.08924 13.6516L0.269063 12.8577C0.074375 12.6692 0.074375 12.3645 0.269063 12.1761L5.17767 7.37644L0.269063 2.57682C0.074375 2.38836 0.074375 2.08362 0.269063 1.89517L1.08924 1.10125C1.28392 0.91279 1.59874 0.91279 1.79342 1.10125L7.8743 7.03561C8.06899 7.22407 8.06899 7.52881 7.8743 7.71726Z"
            fill="#354E79"
          />
        </svg>
      )}
    </Flex>
  );
};

const CardWrap = styled(Card)`
  width: 100%;
  flex: initial;
  padding: 12px;
  border-radius: 10px;
  @media screen and (max-width: 1300px) {
    padding-left: 20px;
    padding-right: 20px;
    background-color: transparent;
    box-shadow: initial;
    margin-top: 32px;
  }
`;

const Title = styled(Text)`
  @media screen and (max-width: 52em) {
    display: none;
  }
`;

const TitleMobile = styled(Heading)`
  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const ToDo = ({
  history,
  analytics,
  client,
  session: {
    eligibility,
    intakeStatus,
    isMember,
    diagnoseFull,
    intakeDate,
    isMobileAppUser,
    isCommunityUser,
    phone,
    mustCompleteIntake,
    mustCompleteBaselineSurvey,
    mustScheduleOnboardingCall,
    baselineStatus,
  },
  hasFutureAppointments,
  loadingAppointments,
  setShowCompleteBaselineRibbon,
  setShowCompleteFURibbon,
}) => {
  const [isLinkSent, setIsLinkSent] = useState(false);
  let canBeMember = true;
  if (!diagnoseFull || (!diagnoseFull.hasHypo && !diagnoseFull.hasHashi) || !eligibility || !eligibility.consultation || eligibility.status === 'no_service') {
    canBeMember = false;
  }

  const onCommunityClick = async () => {
    const circleSo = await client.mutate({
      mutation: GENERATE_CIRCLE_SO_CALLBACK,
    });

    if (circleSo && circleSo.data && circleSo.data.generateCircleSoCallback) {
      if (!!circleSo.data.generateCircleSoCallback.callback) {
        analytics.track('Open community');
        window.open(circleSo.data.generateCircleSoCallback.callback, '_blank');
      } else if (!!circleSo.data.generateCircleSoCallback.mustAcceptTerms) {
        history.push({
          pathname: '/community-terms',
          state: { sameWindow: false },
        });
      }
    }
  };

  return (
    <Query
      query={GET_SURVEY_REMINDER}
      onCompleted={(d) => {
        // // if (!!mustCompleteBaselineSurvey && (baselineStatus || '').toLowerCase() !== 'complete') {
        //   setShowCompleteBaselineRibbon(true);
        // // }
        // if (d && d.data && d.data.dataReminders && d.data.dataReminders.surveyReminder) {
        //   setShowCompleteFURibbon(d.data.dataReminders.surveyReminder);
        // }
      }}
    >
      {({ data: dataReminders, loading: loadingReminders }) => {
        let reminderSurvey;
        if (dataReminders && dataReminders.surveyReminder) {
          reminderSurvey = FOLLOWUP_SURVEYS_ID.find((x) => x.id === dataReminders.surveyReminder);
        }
        return (
          <Query query={HAS_UNREGISTERED_KITS}>
            {({ data, loading: loadingUnregistered, error }) => {
              if (
                !!isCommunityUser &&
                !!isMember &&
                !!isMobileAppUser &&
                !loadingUnregistered &&
                !loadingAppointments &&
                !mustCompleteIntake &&
                (eligibility.status === 'no_service' || (!data.checkHasUnregisteredKit || !data.checkHasUnregisteredKit.hasUnregisteredKits)) &&
                (!mustCompleteBaselineSurvey || (baselineStatus || '').toLowerCase() == 'complete') &&
                !reminderSurvey &&
                !mustScheduleOnboardingCall
              ) {
                return null;
              }
              return (
                <Mutation mutation={SEND_MOBILE_APP_DOWNLOAD_LINK} onCompleted={() => setIsLinkSent(true)}>
                  {(mutate, { loading }) => {
                    return (
                      <>
                        <CardWrap mb="20px">
                          <Flex flexDirection={'column'}>
                            <Title>To-dos for you today</Title>
                            <TitleMobile>To-dos for you today</TitleMobile>
                            {!isCommunityUser && (
                              <ToDoEntry
                                text={'Join the community'}
                                onClick={() => {
                                  onCommunityClick();
                                  // history.push('/community-terms');
                                }}
                              />
                            )}
                            {!isMember &&
                              !!canBeMember && (
                                <ToDoEntry
                                  text={'Join membership'}
                                  onClick={() => {
                                    history.push('/become-member');
                                  }}
                                />
                              )}
                            {!isMobileAppUser && (
                              <ToDoEntry
                                disabled={loading}
                                text={'Download the app'}
                                onClick={() => {
                                  if (!!loading) return;
                                  mutate();
                                }}
                              />
                            )}
                            {!!isMember &&
                              !!mustScheduleOnboardingCall && (
                                <ToDoEntry
                                  text={'Schedule welcome call'}
                                  onClick={() => {
                                    history.push('/schedule-care');
                                  }}
                                />
                              )}

                            {!!mustCompleteBaselineSurvey &&
                              (baselineStatus || '').toLowerCase() !== 'complete' && (
                                <ToDoEntry
                                  text={!baselineStatus ? 'Establish your baseline' : 'Complete your baseline'}
                                  onClick={() => {
                                    history.push(!baselineStatus ? '/establish-baseline' : '/baseline');
                                  }}
                                />
                              )}
                            {!!loadingReminders ? (
                              <ToDoEntry text={<Skeleton width="100px" height="16px" />} isLoading={true} onClick={() => {}} />
                            ) : (
                              <>
                                {reminderSurvey && (
                                  <ToDoEntry
                                    text={`Complete ${reminderSurvey.title}`}
                                    onClick={() => {
                                      history.push(`/establish-followup-survey/${reminderSurvey.id}`);
                                    }}
                                  />
                                )}
                              </>
                            )}
                            {!!loadingAppointments && <ToDoEntry text={<Skeleton width="100px" height="16px" />} isLoading={true} onClick={() => {}} />}
                            {!!mustCompleteIntake && (
                              <ToDoEntry
                                text={'Complete intake form'}
                                onClick={() => {
                                  history.push('/intake');
                                }}
                              />
                            )}

                            {eligibility.status !== 'no_service' &&
                              !loadingUnregistered &&
                              data.checkHasUnregisteredKit &&
                              data.checkHasUnregisteredKit.hasUnregisteredKits && (
                                <ToDoEntry
                                  text={'Register test kit'}
                                  onClick={() => {
                                    history.push('/register-kit');
                                  }}
                                />
                              )}
                          </Flex>
                        </CardWrap>
                        <ConfirmModal
                          confirmLabel="OK"
                          childrenManaged
                          isOpen={isLinkSent}
                          okOnly
                          onClose={() => {
                            setIsLinkSent(false);
                          }}
                          onConfirm={() => {
                            setIsLinkSent(false);
                          }}
                          onBackgroundClick={() => {
                            setIsLinkSent(false);
                          }}
                          onEscapeKeydown={() => {
                            setIsLinkSent(false);
                          }}
                        >
                          <ModalInner>
                            <Heading mb={4} mx={3} textAlign="center">
                              We sent you an SMS
                            </Heading>
                            <Text textAlign="center">On number {phone}</Text>
                            <Button variant={'primary'} mt={4} onClick={() => setIsLinkSent(false)}>
                              Got It
                            </Button>
                          </ModalInner>
                        </ConfirmModal>
                      </>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default withApollo(withSession(withRouter(ToDo)));
