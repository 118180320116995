import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Flex, Text } from 'rebass';

const CompleteIntakeInner = ({history}) => {
  return (
    <Flex flexDirection={'column'}>
      <Text mb={4} fontWeight={500} textAlign={'center'}>
        Complete your intake form
      </Text>
      <Text mb={2} fontWeight={300}>
        Thank you, your visit is confirmed and we are looking forward to seeing you soon.
      </Text>
      <Text mb={4} fontWeight={300}>
        Please take 5 minutes to fill out your intake form, your clinician cannot see you without one and <span style={{textDecoration: 'underline', fontWeight: 600}}>we may have to cancel your appointment</span>. (Fees may apply)
      </Text>

      <Button variant={'pink'} onClick={()=>history.push('/intake')}>
        <Flex justifyContent={'center'} alignItems={'center'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
              d="M19.4219 11H18.2188C18.1242 11 18.0469 11.0773 18.0469 11.1719V18.0469H3.95312V3.95312H10.8281C10.9227 3.95312 11 3.87578 11 3.78125V2.57812C11 2.48359 10.9227 2.40625 10.8281 2.40625H3.09375C2.71348 2.40625 2.40625 2.71348 2.40625 3.09375V18.9062C2.40625 19.2865 2.71348 19.5938 3.09375 19.5938H18.9062C19.2865 19.5938 19.5938 19.2865 19.5938 18.9062V11.1719C19.5938 11.0773 19.5164 11 19.4219 11Z"
              fill="#4E658A"
            />
            <path
              d="M7.64643 11.492L7.60561 14.0465C7.60346 14.2377 7.75815 14.3945 7.94936 14.3945H7.95796L10.4931 14.3322C10.5361 14.3301 10.5791 14.3129 10.6091 14.2828L19.5445 5.3668C19.6111 5.3002 19.6111 5.19062 19.5445 5.12402L16.874 2.45566C16.8396 2.42129 16.7966 2.40625 16.7515 2.40625C16.7064 2.40625 16.6634 2.42344 16.6291 2.45566L7.69585 11.3717C7.66482 11.4041 7.64717 11.4471 7.64643 11.492ZM9.01069 11.999L16.7515 4.27539L17.7226 5.24434L9.97749 12.9723L8.99565 12.9959L9.01069 11.999Z"
              fill="#F8A294"
            />
          </svg>

          <Text ml={2}>Complete your intake form</Text>
        </Flex>
      </Button>
    </Flex>
  );
};

export const CompleteIntake = withRouter(CompleteIntakeInner)
