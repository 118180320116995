import React from 'react';
import { Text, Flex } from 'rebass';

export const MembershipBulletItem = ({ text, showBullet = true, style={}, textStyle={} }) => (
  <Flex
    flexDirection="row"
    style={{ marginBottom: '16px', alignItems: 'center', ...style }}
  >
    <svg
      width={showBullet ? '22' : '0'}
      style={{maxWidth: showBullet ? '22' : '0',minWidth: showBullet ? '22' : '0'}}
      
      height="20"
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.1704 19.6072L0.39539 10.8907C-0.131797 10.367 -0.131797 9.51795 0.39539 8.99423L2.30453 7.09776C2.83172 6.57403 3.68655 6.57403 4.21373 7.09776L10.125 12.9696L22.7863 0.392753C23.3135 -0.130918 24.1683 -0.130918 24.6955 0.392753L26.6046 2.28922C27.1318 2.81289 27.1318 3.66197 26.6046 4.18569L11.0796 19.6073C10.5524 20.1309 9.69759 20.1309 9.1704 19.6072Z"
        fill="#EBB6A7"
        fillOpacity="0.74"
      />
    </svg>

    <Text style={{ marginLeft: '16px', fontWeight: 300, ...textStyle }}>{text}</Text>
  </Flex>
);
