import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import styled from 'styled-components';
import withSession from '../../../lib/withSession';
import { isToggleActive } from '../../featureToggle/toggles';
import Sidebar from '../../Sidebar';

const NavLink = styled(RouterNavLink)`
  color: #344f79;
  text-decoration: none;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 700;
  /* margin-bottom: 20px; */

  @media screen and (max-width: 52em) {
    margin-bottom: 0;
  }
  @media screen and (max-width: 480px) {
    color: #344f79;
    padding: 10px 30px;
  }
  &.active {
    background-color: #f9a394;
    color: white;
    @media screen and (max-width: 480px) {
      color: white;
    }
  }
`;

const SubNavLink = styled(RouterNavLink)`
  color: #344f79;
  text-decoration: none;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 500;
  /* margin-bottom: 20px; */

  @media screen and (max-width: 52em) {
    margin-bottom: 0;
  }
  @media screen and (max-width: 480px) {
    color: #344f79;
    padding: 10px 30px;
  }
  &.active {
    background-color: #f9a394;
    color: white;
    @media screen and (max-width: 480px) {
      color: white;
    }
  }
`;

const POSSIBILITIES = (adm, toggleDisabled) => {
  const rights = [
    {
      category: 'PATIENT',
      link: '/admin-impersonate-patient',
      title: 'Impersonate',
      allowed: !!toggleDisabled || (!!adm && !!adm.imp),
    },
    {
      category: 'PATIENT',
      link: '/generate-otp',
      title: 'Generate OTP',
      allowed: !!toggleDisabled || (!!adm && !!adm.canGenerateOtp),
    },
    {
      category: 'PATIENT',
      link: '/consultations',
      title: 'Video Visits',
      allowed: !!toggleDisabled || !!adm,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/feature-toggles-management',
      title: 'Feature Toggles Management',
      allowed: !!toggleDisabled || (!!adm && !!adm.canFeatureTogglesManagement),
    },
    {
      category: 'PATIENT',
      link: '/pending-registration',
      title: 'Pending Registrations',
      allowed: !!toggleDisabled || !!adm,
    },
    {
      category: 'PATIENT',
      link: '/unregister-kit',
      title: 'Unregister Kits',
      allowed: !!toggleDisabled || !!adm,
    },
    {
      category: 'PATIENT',
      link: '/reset-intake-status',
      title: 'Reset Intake Status',
      allowed: !!toggleDisabled || !!adm,
    },
    {
      category: 'PATIENT',
      link: '/remap-account',
      title: 'remap account',
      allowed: !!toggleDisabled || (!!adm && !!adm.canRemapAccount),
    },
    {
      category: 'PATIENT',
      link: '/remap-associated-user-to-mobile',
      title: 'remap associated user to mobile',
      allowed: !!toggleDisabled || (!!adm && !!adm.canRemapAccount),
    },
    {
      category: 'PATIENT',
      link: '/admin-care-managers',
      title: 'Care Managers',
      allowed: !!toggleDisabled || !!adm,
    },
    {
      category: 'PATIENT',
      link: '/admin-search-patients',
      title: "Search Patient's accounts",
      allowed: !!toggleDisabled || !!adm,
    },
    {
      category: 'PATIENT',
      link: '/remap-pwn',
      title: 'remap pwn orders',
      allowed: !!toggleDisabled || (!!adm && !!adm.canRemapAccount),
    },
    {
      category: 'PATIENT',
      link: '/change-email',
      title: 'change email',
      allowed: !!toggleDisabled || (!!adm && !!adm.canChangeEmail),
    },
    {
      category: 'PATIENT',
      link: '/admin-patient-stripe-change',
      title: 'change Stripe Id',
      allowed: !!toggleDisabled || (!!adm && !!adm.canStripeUserManagement),
    },
    {
      category: 'PATIENT',
      link: '/admin-patient-helpscout-change',
      title: 'change Helpscout Id',
      allowed: !!toggleDisabled || (!!adm && !!adm.canStripeUserManagement),
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-mobile-push-notification',
      title: 'Send Push Notification',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
    },

    {
      category: 'MOBILE_MANAGEMENT',
      link: '/course-editor',
      title: 'Course Editor',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-mobile-symptom-tracker',
      title: 'Symptom Tracker',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-mobile-webflow-articles',
      title: 'Articles',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-mobile-recipes',
      title: 'Recipes',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-mobile-motivation-quotes',
      title: 'Motivation Quotes',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-mobile-courses',
      title: 'AIP Program',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-preset-plans',
      title: 'Preset Plans',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-workouts',
      title: 'Workouts',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
    },
    {
      category: 'UNITHROID',
      link: '/admin-mobile-symptom-tracker-unithroid',
      title: 'Symptom Trackers',
      allowed: !!toggleDisabled || (!!adm && !!adm.canUnithroid),
    },

    {
      category: 'MOBILE_USERS',
      link: '/admin-mobile-change-user-week',
      title: 'Change User Week',
      allowed: !!toggleDisabled || (!!adm && !!adm.canMobileUserManagement),
    },
    {
      category: 'MOBILE_USERS',
      link: '/admin-delete-mobile-user',
      title: 'Delete User',
      allowed: !!toggleDisabled || (!!adm && !!adm.canMobileUserManagement),
    },
    {
      category: 'MOBILE_USERS',
      link: '/admin-reset-mobile-user-week',
      title: 'Reset User to Week 1',
      allowed: !!toggleDisabled || (!!adm && !!adm.canMobileUserManagement),
    },
    {
      category: 'MOBILE_USERS',
      link: '/admin-update-mobile-profile',
      title: 'Edit mobile profile',
      allowed: !!toggleDisabled || (!!adm && !!adm.canMobileUserManagement),
    },

    {
      category: 'SUPER_ADMIN',
      link: '/admin-reports',
      title: 'Reporting',
      allowed: !!toggleDisabled || (!!adm && !!adm.canReporting),
    },
    {
      category: 'SUPER_ADMIN',
      link: '/admin-backfill',
      title: 'Big Query Backfill',
      allowed: !!toggleDisabled || (!!adm && !!adm.canReporting),
    },
    {
      category: 'SUPER_ADMIN',
      link: '/batch-migrate-provider-group',
      title: 'Migrate Prividers Group',
      allowed: !!toggleDisabled || (!!adm && !!adm.canBatchMigrateProviderGroups),
    },
    {
      category: 'SUPER_ADMIN',
      link: '/one-time-coupons-management',
      title: 'One Time Coupons',
      allowed: !!toggleDisabled || (!!adm && !!adm.canOneTimeCouponManagement),
    },
    {
      category: 'SUPER_ADMIN',
      link: '/admin-batch-slots',
      title: 'Batch Slots Creation',
      allowed: !!toggleDisabled || !!adm,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/AdminBannerFilterList',
      title: 'Filtered Banners Management',
      allowed: !!toggleDisabled || (!!adm && !!adm.canFeatureTogglesManagement),
    },
  ];

  return rights;
};

const AdminHamburgerMenu = ({ handleSidebar, forceClose, open, session: { adm } }) => {
  const activeTogglePersonalAdmin = isToggleActive('PERSONAL_ADMIN');
  const possibilities = POSSIBILITIES(adm, !activeTogglePersonalAdmin)
    .filter((x) => !!x.allowed)
    .slice(0, 7);
  return (
    <Sidebar open={open} forceClose={forceClose}>
      <NavLink id="link_yourDashboard" exact to="/" onClick={handleSidebar}>
        Dashboard
      </NavLink>

      {possibilities.map((p) => {
        return (
          <SubNavLink exact to={p.link} onClick={handleSidebar}>
            {p.title}
          </SubNavLink>
        );
      })}
    </Sidebar>
  );
};

export default withSession(AdminHamburgerMenu);
