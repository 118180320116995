import React from 'react';

export const InnerRightHormone = ({ height = 231, style = {}, fill = '#C7DDFC' }) => {
  return (
    <svg width={height * 0.71} height={height} viewBox="0 0 164 231" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        opacity="0.2"
        d="M82.3242 4.67348C110.934 -1.01976 138.892 -0.618574 166.734 7.5703C174.991 10.097 183.372 12.2081 191.845 13.8957C224.16 19.9621 241.78 41.5774 251.195 70.3838C261.161 100.884 261.866 132.123 250.469 162.515C239.627 191.423 219.291 213.266 191.162 225.795C179.363 231.05 164.355 230.97 150.898 230.531C120.891 229.553 92.07 222.196 63.7201 212.239C36.2704 202.591 20.0459 183.069 10.5994 157.341C0.967943 131.117 -1.06364 103.661 2.4923 76.1519C5.94806 49.4223 22.0044 30.9856 46.0573 19.4544C57.8 13.8208 70.2143 9.55558 82.3242 4.67348Z"
        fill={fill}
      />
    </svg>
  );
};

export const InnerLeftHormone = ({ style = {} }) => {
  return (
    <svg width="71" height="165" viewBox="0 0 71 165" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M-31.0768 159.343C-40.2045 153.938 -46.9147 146.489 -52.8011 138.33C-66.9865 118.669 -72.9598 96.6181 -71.5812 72.4748C-71.301 67.567 -71.5048 62.6259 -71.6289 57.7042C-71.8488 48.903 -70.206 40.5236 -65.2595 33.1473C-59.6299 24.7528 -52.1267 18.2143 -43.3197 13.2358C-26.1107 3.50917 -7.7667 -1.33243 12.1129 0.817341C19.1536 1.57794 25.6337 4.16233 32.1239 6.86457C41.77 10.8784 49.7188 17.1753 56.9318 24.4538C61.3911 28.9542 63.7514 34.7698 65.2971 40.8068C71.3248 64.3711 72.6402 88.1953 68.4461 112.208C67.6855 116.561 66.711 121.226 64.437 124.89C60.9541 130.294 56.8927 135.306 52.3242 139.839C44.7287 147.611 35.2583 152.653 25.1411 156.454C14.3346 160.512 3.39118 164.159 -8.2865 164.619C-16.458 164.939 -24.0812 162.702 -31.0768 159.343Z"
        fill="#7E91B0"
      />
    </svg>
  );
};

export const BottomLeftHormone = ({ style = {} }) => {
  return (
    <svg
      width="257"
      viewBox="0 0 257 305"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        maxWidth: 'calc(100% / 3)'
      }}
    >
      <path
        d="M41.557 6.18981C90.8503 19.7572 134.298 42.4063 171.396 77.2245C182.32 87.7046 193.768 97.6285 205.695 106.958C251.474 141.956 261.937 189.861 253.859 242.625C245.305 298.49 221.667 348.226 179.755 387.096C139.887 424.067 90.7557 442.44 36.8004 440.012C14.168 438.993 -9.26238 427.048 -29.9799 415.759C-76.1771 390.59 -115.466 356.31 -151.958 318.308C-187.286 281.503 -197.222 237.993 -191.633 190.05C-185.94 141.18 -167.375 96.3564 -140.021 55.8492C-113.441 16.4903 -73.7054 0.110049 -26.9208 0.898606C-4.0773 1.27721 18.7337 4.33891 41.557 6.18981Z"
        fill="#FBE2D9"
      />
    </svg>
  );
};

export const HugeInnerHormone = ({ style }) => {
  return (
    <svg width="524" height="469" viewBox="0 0 524 469" fill="none" xmlns="http://www.w3.org/2000/svg" style={{maxWidth: '100%',...style}}>
      <path
        opacity="0.4"
        d="M165.472 7.81659C223.581 -3.79903 280.367 -2.98052 336.918 13.7268C353.688 18.8818 370.711 23.1891 387.921 26.6321C453.554 39.0092 489.343 83.1097 508.465 141.882C528.708 204.109 530.14 267.846 506.991 329.853C484.969 388.832 443.666 433.396 386.533 458.958C362.568 469.681 332.084 469.517 304.752 468.621C243.806 466.627 185.267 451.616 127.686 431.301C71.9326 411.618 38.979 371.788 19.7921 319.296C0.229685 265.792 -3.89666 209.776 3.3258 153.65C10.3448 99.1152 42.9567 61.4998 91.8107 37.9733C115.661 26.4794 140.876 17.7773 165.472 7.81659Z"
        fill="#C7DDFC"
      />
    </svg>
  );
};

export const TopRightHormone = () => {
  return (
    <svg
      width="257"
      height="325"
      viewBox="0 0 257 325"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
      }}
    >
      <g opacity="0.2">
        <path
          d="M197.279 -47.3696C238.654 -35.7299 275.122 -16.2989 306.261 13.5722C315.43 22.5632 325.039 31.077 335.05 39.0809C373.476 69.1064 382.258 110.205 375.478 155.472C368.298 203.399 348.457 246.068 313.278 279.416C279.814 311.134 238.575 326.895 193.286 324.813C174.289 323.939 154.623 313.691 137.233 304.006C98.4567 282.413 65.4791 253.003 34.8488 220.401C5.19556 188.826 -3.14427 151.497 1.54729 110.367C6.32582 68.4402 21.9085 29.9856 44.8683 -4.76603C67.1783 -38.5327 100.531 -52.5855 139.801 -51.909C158.975 -51.5842 178.122 -48.9575 197.279 -47.3696Z"
          fill="#4E658A"
        />
        <path
          d="M197.279 -47.3696C238.654 -35.7299 275.122 -16.2989 306.261 13.5722C315.43 22.5632 325.039 31.077 335.05 39.0809C373.476 69.1064 382.258 110.205 375.478 155.472C368.298 203.399 348.457 246.068 313.278 279.416C279.814 311.134 238.575 326.895 193.286 324.813C174.289 323.939 154.623 313.691 137.233 304.006C98.4567 282.413 65.4791 253.003 34.8488 220.401C5.19556 188.826 -3.14427 151.497 1.54729 110.367C6.32582 68.4402 21.9085 29.9856 44.8683 -4.76603C67.1783 -38.5327 100.531 -52.5855 139.801 -51.909C158.975 -51.5842 178.122 -48.9575 197.279 -47.3696Z"
          fill="#4E658A"
        />
        <path
          d="M197.279 -47.3696C238.654 -35.7299 275.122 -16.2989 306.261 13.5722C315.43 22.5632 325.039 31.077 335.05 39.0809C373.476 69.1064 382.258 110.205 375.478 155.472C368.298 203.399 348.457 246.068 313.278 279.416C279.814 311.134 238.575 326.895 193.286 324.813C174.289 323.939 154.623 313.691 137.233 304.006C98.4567 282.413 65.4791 253.003 34.8488 220.401C5.19556 188.826 -3.14427 151.497 1.54729 110.367C6.32582 68.4402 21.9085 29.9856 44.8683 -4.76603C67.1783 -38.5327 100.531 -52.5855 139.801 -51.909C158.975 -51.5842 178.122 -48.9575 197.279 -47.3696Z"
          fill="#4E658A"
        />
      </g>
    </svg>
  );
};

export const LeftMediumHormone = ({ style }) => {
  return (
    <svg width="270" height="284" viewBox="0 0 270 284" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g opacity="0.2">
        <path
          d="M134.264 3.68397C165.362 12.4325 192.772 27.037 216.176 49.4884C223.068 56.2462 230.29 62.6452 237.814 68.661C266.695 91.2286 273.296 122.119 268.2 156.142C262.803 192.164 247.89 224.235 221.45 249.299C196.298 273.139 165.302 284.986 131.263 283.42C116.985 282.763 102.203 275.061 89.1327 267.781C59.9879 251.552 35.2015 229.447 12.1795 204.943C-10.1082 181.211 -16.3765 153.155 -12.8503 122.24C-9.25868 90.7279 2.45347 61.825 19.7103 35.7052C36.4787 10.3259 61.5472 -0.236377 91.0626 0.272098C105.474 0.516233 119.865 2.49047 134.264 3.68397Z"
          fill="#4E658A"
        />
        <path
          d="M134.264 3.68397C165.362 12.4325 192.772 27.037 216.176 49.4884C223.068 56.2462 230.29 62.6452 237.814 68.661C266.695 91.2286 273.296 122.119 268.2 156.142C262.803 192.164 247.89 224.235 221.45 249.299C196.298 273.139 165.302 284.986 131.263 283.42C116.985 282.763 102.203 275.061 89.1327 267.781C59.9879 251.552 35.2015 229.447 12.1795 204.943C-10.1082 181.211 -16.3765 153.155 -12.8503 122.24C-9.25868 90.7279 2.45347 61.825 19.7103 35.7052C36.4787 10.3259 61.5472 -0.236377 91.0626 0.272098C105.474 0.516233 119.865 2.49047 134.264 3.68397Z"
          fill="#4E658A"
        />
        <path
          d="M134.264 3.68397C165.362 12.4325 192.772 27.037 216.176 49.4884C223.068 56.2462 230.29 62.6452 237.814 68.661C266.695 91.2286 273.296 122.119 268.2 156.142C262.803 192.164 247.89 224.235 221.45 249.299C196.298 273.139 165.302 284.986 131.263 283.42C116.985 282.763 102.203 275.061 89.1327 267.781C59.9879 251.552 35.2015 229.447 12.1795 204.943C-10.1082 181.211 -16.3765 153.155 -12.8503 122.24C-9.25868 90.7279 2.45347 61.825 19.7103 35.7052C36.4787 10.3259 61.5472 -0.236377 91.0626 0.272098C105.474 0.516233 119.865 2.49047 134.264 3.68397Z"
          fill="#4E658A"
        />
      </g>
    </svg>
  );
};

export const SmallHormone = ({ style }) => {
  return (
    <svg width="95" height="85" viewBox="0 0 95 85" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        opacity="0.2"
        d="M29.9998 1.89022C40.5347 -0.190189 50.8298 -0.0435892 61.0824 2.94877C64.1229 3.87206 67.2091 4.6435 70.3292 5.26017C82.2283 7.47695 88.7168 15.3755 92.1835 25.9019C95.8535 37.0471 96.1132 48.4626 91.9163 59.5683C87.9238 70.1318 80.4356 78.1135 70.0776 82.6918C65.7327 84.6121 60.2062 84.5829 55.2508 84.4224C44.2014 84.0653 33.5885 81.3767 23.1491 77.7382C13.0412 74.2129 7.0668 67.0792 3.58827 57.6777C0.0416416 48.0948 -0.706455 38.062 0.60296 28.0097C1.87549 18.2422 7.78796 11.5051 16.6451 7.29142C20.9691 5.23281 25.5405 3.67422 29.9998 1.89022Z"
        fill="#DBE6F9"
      />
    </svg>
  );
};

export const MediumHormone = ({ style }) => {
  return (
    <svg width="180" height="161" viewBox="0 0 180 161" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        opacity="0.4"
        d="M56.8417 2.68331C76.8025 -1.30414 96.3092 -1.02316 115.735 4.71219C121.496 6.48182 127.344 7.96043 133.255 9.14237C155.801 13.3912 168.095 28.5302 174.663 48.7057C181.617 70.0672 182.109 91.9471 174.157 113.233C166.592 133.48 152.404 148.778 132.779 157.553C124.546 161.234 114.075 161.178 104.686 160.87C83.75 160.185 63.6413 155.032 43.8615 148.059C24.7097 141.302 13.3897 127.629 6.79883 109.609C0.0788997 91.2421 -1.33855 72.0125 1.14245 52.7456C3.55356 34.0246 14.7561 21.1119 31.538 13.0356C39.731 9.08994 48.3925 6.10265 56.8417 2.68331Z"
        fill="#C7DDFC"
      />
    </svg>
  );
};

export const BigRightHormone = ({ style = {} }) => {
  return (
    <svg width="267" viewBox="0 0 267 441" fill="none" xmlns="http://www.w3.org/2000/svg" style={{maxWidth: 'calc(100% / 3)',...style}}>
      <path
        d="M235.557 6.18981C284.85 19.7572 328.298 42.4063 365.396 77.2245C376.32 87.7046 387.768 97.6284 399.695 106.958C445.474 141.956 455.937 189.861 447.859 242.625C439.305 298.49 415.666 348.226 373.755 387.096C333.887 424.067 284.756 442.44 230.8 440.012C208.168 438.993 184.738 427.048 164.02 415.759C117.823 390.59 78.534 356.31 42.0419 318.308C6.71373 281.503 -3.22214 237.993 2.36727 190.05C8.06031 141.18 26.6252 96.3564 53.9789 55.8492C80.5585 16.4903 120.294 0.110049 167.079 0.898604C189.923 1.27721 212.734 4.33891 235.557 6.18981Z"
        fill="#FBE2D9"
      />
    </svg>
  );
};
