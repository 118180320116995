/* eslint-disable import/prefer-default-export, no-unsafe-finally */

/**
 * Return true if nested object is valid or false if nested contains null or undefined
 *
 * @param fn - function.
 *
 * const a = {
 *   b: {
 *     c: 'tt'
 *   }
 * }
 *
 * objectHas(()=>{return a.b.c}) === true
 *
 *
 *
 *
 * const a = {
 *   b: {
 *   }
 * }
 *
 * objectHas(()=>{return a.b.c}) === false
 */
export const objectHas = fn => {
  if (typeof fn !== 'function') {
    console.error(
      `function "has" invalid parameter type fn ("${typeof fn}") expected "function"`
    );
    return false;
  }
  let value;
  try {
    value = fn();
  } catch (e) {
    value = undefined;
  } finally {
    return value !== undefined;
  }
};

export const parseStringify = val => {
  return JSON.parse(JSON.stringify(val))
}
