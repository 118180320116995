import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Flex, Heading, Image, Text } from 'rebass';
import styled from 'styled-components';
import { GET_AIP_RECIPES_BY_FILTER } from '../graphql';
import { withRouter } from 'react-router';
import { CleanDom } from '../utils/domCleanup';
import { SearchInput } from '../components/TextInput';
import { RecipeCardLoading } from './landing/Recipes';
import { Query } from 'react-apollo';
import PleaseWait from '../components/PleaseWait';
import Advocado from '../static/Advocado.png';

const Container = styled(Flex)`
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 24px;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100vw;
  }
`;

const BlurredContainer = styled(Flex)`
  box-shadow: inset 0 0 0 110px rgba(253, 245, 242, 0.65);
  backdrop-filter: blur(6px);
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;
  padding: 12px;
  justify-content: center;
  align-items: center;
`;

const Img = styled(Image)`
  transition: 0.3s all ease-in-out;
  object-fit: cover;
  &:hover {
    transform: scale(1.1);
  }
`;

const RecipeCardContent = styled(Card)`
  cursor: pointer;
  margin-right: 24px;
  &:hover ${Img} {
    transform: scale(1.15);
  }
  @media screen and (max-width: 480px) {
    &.individual {
      margin-right: 0px;
    }
  }
`;

const ArticleCardContainer = styled(Flex)`
  transition: 0.3s all ease-in-out;
  & > * {
    transition: 0.3s all ease-in-out;
  }
  &:hover > * {
    opacity: 0.75;
  }
  &:hover > :hover {
    opacity: 1;
  }
`;

const CategoryTag = styled(Text)`
  transition: 0.3s all ease-in-out;
  text-decoration: underline, 
  cursor: pointer,
 font-weight: 300;
 color: #F8A294;

&:hover {
 font-weight: 500;
 }
`;

const CategoryTagMobile = styled(Box)`
  transition: 0.3s all ease-in-out;
  border-radius: 24px;
  padding: 4px 8px;
  color: #7992b3;
  border: 1px solid #7992b3;
  margin-right: 18px;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 300;

  &.isSelected {
    font-weight: 500;
    color: rgb(53, 78, 121);
    border: 1px solid rgb(53, 78, 121);
  }
`;

const BoxRight = styled(Box)`
  width: 254px;

  @media screen and (max-width: 1300px) {
    display: none;
  }
`;

const BoxRightMobile = styled(Flex)`
  flex-direction: column;
  @media screen and (min-width: 1300px) {
    display: none;
  }
`;

const FilterButton = ({ title, isSelected, onPress }) => {
  return (
    <Flex onClick={onPress} mb={2} style={{ cursor: 'pointer' }} alignItems="center">
      <Flex style={{ height: 16, width: 16, border: '1px solid #F8A294', background: isSelected ? '#F8A294' : 'transparent', justifyContent: 'center', alignItems: 'center' }} mr={2}>
        {!!isSelected && (
          <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.6925 0L8.8315 0.799L3.893 7.922H2.754L0 4.063L1.139 3.0005L3.3235 5.0405L7.6925 0Z" fill="white" />
          </svg>
        )}
      </Flex>
      <CategoryTag style={{ fontWeight: isSelected ? 600 : '' }}>{title}</CategoryTag>
    </Flex>
  );
};

const Title = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const RecipeCard = ({ recipe, onClick, className }) => {
  return (
    <RecipeCardContent className={className} onClick={onClick} style={{ minWidth: '200px', width: '200px', height: '260px', overflow: 'hidden', marginBottom: '32px', borderRadius: '10px' }}>
      <Flex flexDirection={'column'} style={{ position: 'relative', height: '100%' }}>
        <Img src={`${recipe.image}`} style={{ flex: 1, width: '100%', objectFit: 'cover' }} />
        <Flex style={{ height: '92px', left: 0, right: 0, bottom: 0, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', position: 'absolute', padding: '6px' }}>
          <Title textAlign={'center'}>{recipe.name}</Title>
        </Flex>
      </Flex>
    </RecipeCardContent>
  );
};

const RelatedPosts = ({ history }) => {
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [excludeAllergens, setExcludeAllergens] = useState([]);
  const [filter, setFilter] = useState(null);
  const [filterTmp, setFilterTmp] = useState(null);

  const excludeAllergensPressHandler = (name) => {
    let allergens = [];
    if (!name) {
      allergens = [];
      setExcludeAllergens([]);
    } else {
      if (name === 'FAVORITES') {
        if (excludeAllergens.indexOf(name) < 0) {
          allergens = [name];
          setExcludeAllergens([name]);
        }
      } else if (excludeAllergens.indexOf(name) < 0) {
        allergens = [...excludeAllergens.filter((x) => x !== 'FAVORITES'), name];
        setExcludeAllergens(allergens);
      } else {
        const filt = excludeAllergens.filter((x) => x !== name && x !== 'FAVORITES');
        allergens = filt;
        // console.log({filt})
        setExcludeAllergens(filt);
      }
    }
    setIsFetchingMore(false);
  };

  const onScroll = (e, data, fetchMore) => {
    if (!!data && data.getAipRecipesByFilterFromWeb && !isFetchingMore) {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
        if (data.getAipRecipesByFilterFromWeb.length % 24 !== 0) return;
        setTimeout(() => {
          onFetchMore(data, fetchMore);
        }, 300);

        return;
      }
    }
  };

  const onFetchMore = (data, fetchMore) => {
    if (!data || !data.getAipRecipesByFilterFromWeb || data.getAipRecipesByFilterFromWeb.length < 1) return;
    setIsFetchingMore(true);
    fetchMore({
      variables: {
        excludedAllergens: excludeAllergens,
        filterText: filter,
        idsToExclude: [...data.getAipRecipesByFilterFromWeb.map((x) => x.id)],
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setIsFetchingMore(false);
        if (!fetchMoreResult || !fetchMoreResult.getAipRecipesByFilterFromWeb || fetchMoreResult.getAipRecipesByFilterFromWeb.length < 1) {
          return previousResult;
        }
        return Object.assign({}, previousResult, {
          getAipRecipesByFilterFromWeb: [...previousResult.getAipRecipesByFilterFromWeb, ...fetchMoreResult.getAipRecipesByFilterFromWeb],
        });
      },
    });
  };

  return (
    <Query
      query={GET_AIP_RECIPES_BY_FILTER}
      variables={{
        excludedAllergens: excludeAllergens,
        filterText: filter,
        idsToExclude: [],
      }}
      notifyOnNetworkStatusChange={true}
      fetchPolicy="cache-and-network"
      nextFetchPolicy="cache-first"
    >
      {({ data, loading, error, fetchMore, refetch, called }) => {
        if (error) return null;
        return (
          <Container flex={1} onScroll={(e) => onScroll(e, data, fetchMore)} style={{ maxHeight: 'calc(100vh - 90px)', overflow: 'auto' }} id="ctn">
            <Flex flexDirection={'column'}>
              <Flex flexDirection={'column'} style={{ width: '100%' }}>
                <Flex flex={1}>
                  <Flex flex={1}>
                    <Flex flexDirection={'column'} style={{ maxWidth: 'min(calc(100vw - 40px), 700px)' }}>
                      <Heading textAlign={'center'} mt={'40px'} mb={'24px'}>
                        Thyroid Friendly Recipes
                      </Heading>
                      <BoxRightMobile>
                        <SearchInput
                          type="search"
                          placeholder="Search"
                          value={filterTmp}
                          onEnter={() => {
                            setFilter(filterTmp);
                          }}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setFilter(null);
                              setFilterTmp(null);
                              return;
                            }
                            setFilterTmp(e.target.value);
                          }}
                        />
                        {!!filterTmp &&
                          filterTmp !== filter && (
                            <Text mt={2} style={{ fontSize: '12px', fontWeight: 300 }}>
                              Press <span style={{ fontWeight: 500 }}>Enter</span> to search
                            </Text>
                          )}

                        <Box style={{ maxWidth: 'calc(100% + 40px)', overflow: 'auto', marginLeft: '-20px', marginRight: '-20px' }}>
                          <Flex style={{ paddingTop: '24px', paddingBottom: '24px', paddingLeft: '20px', paddingRight: '20px' }}>
                            <CategoryTagMobile
                              title="All"
                              className={excludeAllergens.length < 1 ? 'isSelected' : ''}
                              isSelected={excludeAllergens.length < 1}
                              onClick={() => excludeAllergensPressHandler(null)}
                            >
                              All
                            </CategoryTagMobile>
                            <CategoryTagMobile
                              title="Dairy Free"
                              className={excludeAllergens.indexOf('dairy-free') > -1 ? 'isSelected' : ''}
                              isSelected={excludeAllergens.indexOf('dairy-free') > -1}
                              onClick={() => excludeAllergensPressHandler('dairy-free')}
                            >
                              Dairy Free
                            </CategoryTagMobile>

                            <CategoryTagMobile
                              title="Gluten Free"
                              className={excludeAllergens.indexOf('gluten-free') > -1 ? 'isSelected' : ''}
                              isSelected={excludeAllergens.indexOf('gluten-free') > -1}
                              onClick={() => excludeAllergensPressHandler('gluten-free')}
                            >
                              Gluten Free
                            </CategoryTagMobile>

                            <CategoryTagMobile
                              title="Sugar Free"
                              className={excludeAllergens.indexOf('sugar-free') > -1 ? 'isSelected' : ''}
                              isSelected={excludeAllergens.indexOf('sugar-free') > -1}
                              onClick={() => excludeAllergensPressHandler('sugar-free')}
                            >
                              Sugar Free
                            </CategoryTagMobile>

                            <CategoryTagMobile
                              title="Egg Free"
                              className={excludeAllergens.indexOf('egg-free') > -1 ? 'isSelected' : ''}
                              isSelected={excludeAllergens.indexOf('egg-free') > -1}
                              onClick={() => excludeAllergensPressHandler('egg-free')}
                            >
                              Egg Free
                            </CategoryTagMobile>

                            <CategoryTagMobile
                              title="Peanut Free"
                              className={excludeAllergens.indexOf('peanut-free') > -1 ? 'isSelected' : ''}
                              isSelected={excludeAllergens.indexOf('peanut-free') > -1}
                              onClick={() => excludeAllergensPressHandler('peanut-free')}
                            >
                              Peanut Free
                            </CategoryTagMobile>

                            <CategoryTagMobile
                              title="Tree-Nut Free"
                              className={excludeAllergens.indexOf('treenut-free') > -1 ? 'isSelected' : ''}
                              isSelected={excludeAllergens.indexOf('treenut-free') > -1}
                              onClick={() => excludeAllergensPressHandler('treenut-free')}
                            >
                              Tree-Nut Free
                            </CategoryTagMobile>

                            <CategoryTagMobile
                              title="Soy Free"
                              i
                              className={excludeAllergens.indexOf('soy-free') > -1 ? 'isSelected' : ''}
                              sSelected={excludeAllergens.indexOf('soy-free') > -1}
                              onClick={() => excludeAllergensPressHandler('soy-free')}
                            >
                              Soy Free
                            </CategoryTagMobile>

                            <CategoryTagMobile
                              title="Nightshade Free"
                              className={excludeAllergens.indexOf('nightshade-free') > -1 ? 'isSelected' : ''}
                              isSelected={excludeAllergens.indexOf('nightshade-free') > -1}
                              onClick={() => excludeAllergensPressHandler('nightshade-free')}
                            >
                              Nightshade Free
                            </CategoryTagMobile>
                          </Flex>
                        </Box>
                      </BoxRightMobile>
                      <ArticleCardContainer height={'100%'} style={{ height: '100%', overflowX: 'auto' }} flexWrap={'wrap'}>
                        {!!loading && !isFetchingMore && (!data || !data.getAipRecipesByFilterFromWeb) ? (
                          <>
                            {[...Array(24)].map((x, i) => {
                              return <RecipeCardLoading className="individual" key={`loadingRecipeCat${i}`} />;
                            })}
                          </>
                        ) : (
                          <>
                            {((data || {}).getAipRecipesByFilterFromWeb || []).map((x) => {
                              return (
                                <RecipeCard
                                  key={x.id}
                                  recipe={x}
                                  tag={x.id}
                                  className="individual"
                                  onClick={() =>
                                    history.push({
                                      pathname: `/recipes/${encodeURI(x.name)}`,
                                      state: {
                                        ...x,
                                      },
                                    })
                                  }
                                />
                              );
                            })}
                            {!!data && !!data.getAipRecipesByFilterFromWeb && data.getAipRecipesByFilterFromWeb.length > 0 &&
                              data.getAipRecipesByFilterFromWeb.length % 3 > 0 && (
                                <>
                                  {[...Array(3 - (data.getAipRecipesByFilterFromWeb.length % 3)).keys()].map((x, i) => {
                                    return (
                                      <Flex
                                        key={`RecipePlaceholder${i}`}
                                        style={{ minWidth: '200px', width: '200px', height: '240px', marginRight: '24px', overflow: 'hidden', marginBottom: '32px', borderRadius: '10px' }}
                                      />
                                    );
                                  })}
                                </>
                              )}

                            {!!data && !!data.getAipRecipesByFilterFromWeb && data.getAipRecipesByFilterFromWeb.length === 0 && (
                              <Flex style={{ flexDirection: 'column', alignItems: 'center' }}>
                                <img src={Advocado} style={{maxWidth: '120px'}} />
                                <Text my={3}>We did not find any recipes that match your search</Text>
                                <Button
                                  variant={'primary'}
                                  onClick={() => {
                                    setExcludeAllergens([]);
                                    setFilter(null);
                                    setFilterTmp(null);
                                  }}
                                >
                                  Reset Search
                                </Button>
                              </Flex>
                            )}
                          </>
                        )}
                        {isFetchingMore && (
                          <BlurredContainer>
                            <PleaseWait
                              text={' '}
                              spinner={{
                                sColor: '#ECEFF2',
                                pColor: '#909FB5',
                              }}
                            />
                          </BlurredContainer>
                        )}
                      </ArticleCardContainer>
                    </Flex>
                  </Flex>

                  <BoxRight>
                    <Flex style={{ position: 'fixed' }} flexDirection={'column'}>
                      <SearchInput
                        type="search"
                        placeholder="Search"
                        value={filterTmp}
                        onEnter={() => {
                          setFilter(filterTmp);
                        }}
                        onChange={(e) => {
                          if (!e.target.value) {
                            setFilter(null);
                            setFilterTmp(null);
                            return;
                          }
                          setFilterTmp(e.target.value);
                        }}
                      />
                      {!!filterTmp &&
                        filterTmp !== filter && (
                          <Text mt={2} style={{ fontSize: '12px', fontWeight: 300 }}>
                            Press <span style={{ fontWeight: 500 }}>Enter</span> to search
                          </Text>
                        )}

                      <Text style={{ fontWeight: 500 }} mt={4} mb={3}>
                        Categories
                      </Text>
                      <FilterButton title="All" isSelected={excludeAllergens.length < 1} onPress={() => excludeAllergensPressHandler(null)} />
                      <FilterButton title="Dairy Free" isSelected={excludeAllergens.indexOf('dairy-free') > -1} onPress={() => excludeAllergensPressHandler('dairy-free')} />

                      <FilterButton title="Gluten Free" isSelected={excludeAllergens.indexOf('gluten-free') > -1} onPress={() => excludeAllergensPressHandler('gluten-free')} />

                      <FilterButton title="Sugar Free" isSelected={excludeAllergens.indexOf('sugar-free') > -1} onPress={() => excludeAllergensPressHandler('sugar-free')} />

                      <FilterButton title="Egg Free" isSelected={excludeAllergens.indexOf('egg-free') > -1} onPress={() => excludeAllergensPressHandler('egg-free')} />

                      <FilterButton title="Peanut Free" isSelected={excludeAllergens.indexOf('peanut-free') > -1} onPress={() => excludeAllergensPressHandler('peanut-free')} />

                      <FilterButton title="Tree-Nut Free" isSelected={excludeAllergens.indexOf('treenut-free') > -1} onPress={() => excludeAllergensPressHandler('treenut-free')} />

                      <FilterButton title="Soy Free" isSelected={excludeAllergens.indexOf('soy-free') > -1} onPress={() => excludeAllergensPressHandler('soy-free')} />

                      <FilterButton title="Nightshade Free" isSelected={excludeAllergens.indexOf('nightshade-free') > -1} onPress={() => excludeAllergensPressHandler('nightshade-free')} />
                    </Flex>
                  </BoxRight>
                </Flex>
              </Flex>
            </Flex>
          </Container>
        );
      }}
    </Query>
  );
};

const ViewRecipes = ({ history }) => {
  useEffect(() => {
    CleanDom();
  }, []);
  return <RelatedPosts history={history} />;
};

export default withRouter(ViewRecipes);
