import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import PractitionerTag from '../../components/PractitionerTag';
import StarRatings from '../../components/ratingStar/star-rating';
import ReadMore from '../../components/readMore/readmore';
import CalendarGrid from './calendarGrid';
import { getMemberVisitPrice } from '../../utils/pricing';
import { isToggleActive } from '../../components/featureToggle/toggles';

const Inner = styled(Flex)`
  max-width: 650px;
`;
const Wrapper = styled(Flex)`
  max-width: 650px;
`;

const BackButton = styled.div`
  background: #d8e1f3;
  width: 100%;
  color: #333;
  padding: 6px 12px;
  margin-left: -8px;
  margin-right: -8px;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const MobileCTA = styled(Flex)`
  display: none;
  @media screen and (max-width: 52em) {
    margin-left: -8px;
    margin-right: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #2c456d;
    padding: 8px;
  }
`;

const FlexHideFromMedium = styled(Flex)`
  display: flex;
  @media screen and (max-width: 1399px) {
    display: none;
  }
`;

const BoxHideFromMedium = styled(Box)`
  display: flex;
  @media screen and (max-width: 1399px) {
    display: none;
  }
`;

const MidSizeCalendar = styled(Card)`
  display: none;

  @media screen and (max-width: 1399px) and (min-width: 53em) {
    display: flex;
  }
`;

const Avatar = styled(Flex)`
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  &.small {
    width: 40px;
    height: 40px;
    & > img {
      width: 40px;
    }
  }
  @media screen and (min-width: 52em) {
    &.small {
      width: 60px;
      height: 60px;
      & > img {
        width: 60px;
      }
    }
  }
`;

const InnerDetails = ({ reschedulingAppointment, selectedDoc, onSlotSelected, departmentId, timeDoesntWorkOpen, resetSelectedDoctor, isNotRegisteredFlow, isMeno, hasLabs }) => {
  const aboutRef = useRef();
  const educationRef = useRef();
  const faqRef = useRef();
  const reviewCard = useRef();
  const [isSelectMobileSlot, setIsSelectMobileSlot] = useState(false);
  const [reviewsPagination, setReviewsPagination] = useState(1);
  const [reviews, setReviews] = useState((selectedDoc || {}).reviews || []);
  useEffect(() => {
    setReviews((selectedDoc || {}).reviews || [])
    var images = document.getElementsByTagName('img');
    for (let index = 0; index < images.length; index++) {
      const element = images[index];

      if (element.currentSrc && element.currentSrc.indexOf('https://s.amazon-adsystem') > -1) {
        element.style.position = 'absolute';
        element.style.top = '0';
      }
    }

  }, []);
  
  useEffect(() => {
    setReviews((selectedDoc || {}).reviews || [])
  }, [selectedDoc]);

  const isMobileApp = !!window && !!window.ReactNativeWebView;

  if (!!isSelectMobileSlot) {
    return (
      <Flex
        flexDirection={'column'}
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: 'white',
          overflowY: 'auto',
          padding: '20px',
        }}
      >
        <Flex flexDirection="row">
          <Text fontWeight="600" fontSize="20px" mb={3} flex={1}>
            Schedule a video visit
          </Text>
          <Text style={{ opacity: 0.75 }} fontWeight="600" fontSize="20px" mb={3} onClick={() => setIsSelectMobileSlot(false)}>
            X
          </Text>
        </Flex>
        <CalendarGrid
          isNotRegisteredFlow={isNotRegisteredFlow}
          nextAppointmentAvailable={selectedDoc.nextAppointmentAvailable}
          reschedulingAppointment={reschedulingAppointment && reschedulingAppointment.provider && reschedulingAppointment.provider.id === selectedDoc.id ? reschedulingAppointment : null}
          doctorName={selectedDoc.patientFacingName}
          providerId={selectedDoc.id}
          onSlotSelected={(sl) => {
            onSlotSelected(sl);
            setIsSelectMobileSlot(false);
          }}
          hasLabs={hasLabs}
          enforceDepartmentId={departmentId}
          showShortDay={true}
          isMeno={isMeno}
        />

        <Text
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            textAlign: 'center',
          }}
          mt={3}
          color="#0075FF"
          mb={4}
          onClick={() => {
            setIsSelectMobileSlot(false);
          }}
        >
          Cancel
        </Text>
      </Flex>
    );
  }
  
  return (
    <Flex flexDirection="row" pl={[2, 3]} pr={[2, 3]}>
      <Inner flex={1} flexDirection="column" pt={[0, 0, '12px']}>
        <Flex
          style={{
            position: isMobileApp ? 'fixed' : 'initial',
            width: '100%',
            zIndex: 1,
          }}
        >
          <BackButton onClick={resetSelectedDoctor}>{'<  Doctors'}</BackButton>
        </Flex>
        <Flex flexDirection={['column', 'row']} style={{ flex: 'none' }} mb={4} p={4} pt={!!isMobileApp ? 5 : 4}>
          <Flex flexDirection="column" mr={[0, 4]} alignItems={['center', 'flex-start']}>
            <Avatar style={{ marginBottom: '20px' }}>
              <img src={selectedDoc.picture} alt="" style={{ height: '80px' }} />
            </Avatar>
          </Flex>
          <Flex flexDirection="column">
            <Flex alignItems="center" mb={4}>
              <Flex mr={3}>
                <Text
                  style={{
                    fontWeight: '700',
                    fontFamily: 'Playfair Display',
                    fontSize: '22px',
                  }}
                  textAlign={['center', 'left']}
                >
                  {selectedDoc.patientFacingName}
                </Text>
              </Flex>
            </Flex>
            {selectedDoc.tags ? (
              <Flex flexDirection="row" flexWrap="wrap">
                {selectedDoc.tags.map((tag) => {
                  return <PractitionerTag tag={tag} />;
                })}
              </Flex>
            ) : null}
          </Flex>
        </Flex>
        {/* <Flex flexDirection="column" style={{position: 'sticky', top: 0, background: '#fdf5f2', zIndex: 10}} mb={4}> */}
        <Flex flexDirection="column" mb={4} style={{ marginLeft: '-8px' }}>
          <Box style={{ width: '100%', height: '1px', background: '#2C456D', opacity: 0.3 }} />
          <Flex flexDirection="row" style={{ overflowX: 'auto', marginTop: '8px', marginBottom: '8px', maxWidth: '100vw', paddingLeft: '16px', paddingRight: '16px' }}>
            <Text
              style={{ display: 'inline-block', cursor: 'pointer' }}
              mr={'30px'}
              fontWeight="600"
              fontSize="20px"
              onClick={() => {
                if (aboutRef && aboutRef.current) {
                  aboutRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                  });
                }
              }}
            >
              About
            </Text>
            <Text
              style={{ display: 'inline-block', cursor: 'pointer' }}
              mr={'30px'}
              fontWeight="600"
              fontSize="20px"
              onClick={() => {
                if (educationRef && educationRef.current) {
                  educationRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                  });
                }
              }}
            >
              Education
            </Text>
            {reviews.length > 0 && (
              <Text
                style={{ display: 'inline-block', cursor: 'pointer' }}
                mr={'30px'}
                fontWeight="600"
                fontSize="20px"
                onClick={() => {
                  if (reviewCard && reviewCard.current) {
                    reviewCard.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'nearest',
                    });
                  }
                }}
              >
                Reviews
              </Text>
            )}
            <Text
              style={{ display: 'inline-block', cursor: 'pointer' }}
              mr={'30px'}
              fontWeight="600"
              fontSize="20px"
              onClick={() => {
                if (faqRef && faqRef.current) {
                  faqRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                  });
                }
              }}
            >
              FAQs
            </Text>
          </Flex>
          <Box style={{ width: '100%', height: '1px', background: '#2C456D', opacity: 0.3 }} />
        </Flex>
        <Flex flexDirection={['column', 'column', 'row']}>
          <Flex flexDirection="column" alignItems="center">
            <Text fontWeight="600" fontSize="20px" mb={3}>
              Overall rating
            </Text>
            <Heading fontSize={'32px'}>{selectedDoc.rating.rate / 100}</Heading>
            <StarRatings rating={selectedDoc.rating.rate / 100} starRatedColor="#f9a394" numberOfStars={5} starDimension="20px" name="rating" starSpacing="0px" />

            <Text
              fontSize={1}
              color="#0075FF"
              ml="2"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => {
                reviewCard.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'nearest',
                });
              }}
            >
              {selectedDoc.rating.count} reviews
            </Text>
          </Flex>

          <BoxHideFromMedium style={{ width: '1px', background: '#2C456D', opacity: 0.3 }} ml={4} />

          {reviews.length > 0 && (
            <Flex flexDirection="column" flex={1} ml={[0, 3]}>
              <Text fontWeight="600" fontSize="20px" mb={3}>
                Recent reviews
              </Text>
              {JSON.parse(JSON.stringify(reviews))
                .splice(0, 2)
                .map((x) => {
                  return (
                    <Flex flexDirection="column" mb={3}>
                      <ReadMore
                        text={x.review || ''}
                        min={50}
                        ideal={80}
                        max={120}
                        readMoreText="Show more"
                        textStyle={{ display: 'inherit' }}
                        readMoreStyle={{ color: '#0075FF', textDecoration: 'underline', marginLeft: '6px', fontWeight: 300, fontSize: '12px', display: 'inherit' }}
                      />
                      <Text style={{ opacity: 0.5 }} mt={2}>
                        {x.firstName}
                      </Text>
                    </Flex>
                  );
                })}

              <Button
                onClick={() => {
                  reviewCard.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                  });
                }}
                style={{ width: 'auto', alignSelf: 'flex-start', paddingTop: '8px', paddingBottom: '8px', fontSize: '14px', fontWeight: 300, border: '1px solid #344F79' }}
                variant={'outline'}
              >
                Read more reviews
              </Button>
            </Flex>
          )}
        </Flex>
        <BoxHideFromMedium style={{ width: '100%', height: '1px', background: '#2C456D', opacity: 0.3, minHeight: '1px' }} mt={3} />

        <MidSizeCalendar p={3} mt={4}>
          <Flex flexDirection="column" style={{ width: '100%' }}>
            <Text fontWeight="600" fontSize="20px" mb={3}>
              Schedule a video visit
            </Text>
            <CalendarGrid
              isNotRegisteredFlow={isNotRegisteredFlow}
              nextAppointmentAvailable={selectedDoc.nextAppointmentAvailable}
              reschedulingAppointment={reschedulingAppointment && reschedulingAppointment.provider && reschedulingAppointment.provider.id === selectedDoc.id ? reschedulingAppointment : null}
              doctorName={selectedDoc.patientFacingName}
              providerId={selectedDoc.id}
              onSlotSelected={onSlotSelected}
              enforceDepartmentId={departmentId}
              canWaitlist={isToggleActive('VISIT_WAITLIST') && !isNotRegisteredFlow}
              showAddWaitlist={timeDoesntWorkOpen}
              isMeno={isMeno}
              hasLabs={hasLabs}
            />
          </Flex>
        </MidSizeCalendar>
        <Text fontWeight="600" fontSize="20px" mt={3} ref={aboutRef}>
          About {selectedDoc.patientFacingName}
        </Text>
        <ReadMore
          text={selectedDoc.bio || ''}
          min={50}
          ideal={80}
          max={120}
          readMoreText="Read more"
          textStyle={{ display: 'inline' }}
          readMoreStyle={{ color: '#0075FF', textDecoration: 'underline', marginLeft: '6px', fontWeight: 300, fontSize: '12px', display: 'inherit' }}
        />

        {!isMeno && selectedDoc.question1 ? (
          <>
            <Text color="#344F79" fontWeight="500" mt={3}>
              Why I am passionate about treating patients with hypothyroidism:
            </Text>
            <Text
              color="#344F79"
              dangerouslySetInnerHTML={{
                __html: `${selectedDoc.question1}`,
              }}
            />
          </>
        ) : null}
        {!isMeno && selectedDoc.question2 ? (
          <>
            <Text color="#344F79" fontWeight="500" mt={3}>
              My approach to treating hypothyroidism is:
            </Text>
            <Text
              color="#344F79"
              dangerouslySetInnerHTML={{
                __html: `${selectedDoc.question2}`,
              }}
            />
          </>
        ) : null}
        <BoxHideFromMedium style={{ width: '100%', height: '1px', background: '#2C456D', opacity: 0.3, minHeight: '1px' }} mt={3} mb={3} />
        <Text fontWeight="600" fontSize="20px" mb={4} ref={educationRef}>
          Education and background
        </Text>
        <Text color="#344F79" fontWeight="500">
          Board Certifications
        </Text>
        <Text
          color="#344F79"
          mb={4}
          dangerouslySetInnerHTML={{
            __html: `${selectedDoc.certification}`,
          }}
        />
        <Text color="#344F79" fontWeight="500">
          Education and Training
        </Text>
        <Text
          color="#344F79"
          mb={4}
          dangerouslySetInnerHTML={{
            __html: `${selectedDoc.education}`,
          }}
        />
        <Text color="#344F79" fontWeight="500">
          Experience
        </Text>
        <Text
          color="#344F79"
          mb={4}
          dangerouslySetInnerHTML={{
            __html: `${selectedDoc.experience}`,
          }}
        />
        <Text color="#344F79" fontWeight="500">
          Years of experience treating hypothyroid patients:
        </Text>
        <Text color="#344F79">{moment().diff(`${selectedDoc.startingYear}-01-01`, 'years', false)}</Text>

        <div ref={reviewCard}>
          {reviews.length > 0 && (
            <>
              <BoxHideFromMedium style={{ width: '100%', height: '1px', background: '#2C456D', opacity: 0.3, minHeight: '1px' }} mt={4} />

              <Text fontWeight="600" fontSize="20px" mt={3} mb={3}>
                Patient reviews
              </Text>
              {JSON.parse(JSON.stringify(reviews))
                .splice(0, 10 * (reviewsPagination || 1))
                .map((x) => {
                  return (
                    <Flex mb={3} flexDirection="column">
                      <>
                        <StarRatings rating={x.rating / 100} starRatedColor="#f9a394" numberOfStars={5} starDimension="20px" name="rating" starSpacing="0px" />
                        <Text fontSize={2}>{x.review}</Text>
                        <Text style={{ opacity: 0.5 }} mt={2}>
                          {x.firstName}
                        </Text>
                      </>
                    </Flex>
                  );
                })}
              <>
                {reviews.length > 10 * (reviewsPagination || 1) && (
                  <Button
                    onClick={() => {
                      setReviewsPagination(reviewsPagination + 1);
                    }}
                    style={{ width: 'auto', alignSelf: 'flex-start', paddingTop: '8px', paddingBottom: '8px', fontSize: '14px', fontWeight: 300, border: '1px solid #344F79' }}
                    variant={'outline'}
                  >
                    Read more reviews
                  </Button>
                )}
              </>
            </>
          )}
        </div>
        <Flex flexDirection="column" ref={faqRef} mt={4}>
          <Text fontWeight="600" fontSize="20px" mb={4} ref={faqRef}>
            Frequently Asked Questions
          </Text>
          <Text color="#344F79" fontWeight="600" mb={2}>
            How do live visits work?
          </Text>
          <Text color="#344F79" mb={4}>
            Booking a consultation is very easy. Once you have created your account, you will be able to book a consultation in no time with one of our providers. We'll ask you to answer a few
            questions about your medical history. If you have recent lab results you will be able to upload them so that our healthcare professionals can review them. <br />
            <br />A few minutes before your appointment, you will receive an email with a link to connect with your provider online! And that's it. <br />
            <br />
            After your consultation, your provider will share with you their notes and recommendations
          </Text>
          <Text color="#344F79" fontWeight="600" mb={2}>
            What is the Membership cancellation policy?
          </Text>
          <Text color="#344F79" mb={4}>
            If you cancel your annual membership before your first video visit, you will receive a refund less a <strong>$15 </strong>
            service fee.&nbsp;
            <br />
            &zwj;
            <br />
            You can cancel your membership anytime and it will not renew after the annual period. (Example: If you start membership on 1/1/2022 and cancel it on 5/2/2022 it will be active and
            available for use until 1/1/2023). Your membership fee for the period in which you cancel will not be refunded and you will maintain access to all member services and benefits until the
            end of that term.
            <br />
          </Text>
          <Text color="#344F79" fontWeight="600" mb={2}>
            Are consultations covered by insurance?
          </Text>
          <Text color="#344F79" mb={4}>
            Consultations with Paloma Health providers are covered by most major insurers. We are happy to run an insurance eligibility check before your appointment and let you know the exact cost.
            You will not be charged until after your appointment.
            <br />
            &zwj;
            <br />
            In the case that we are not in-network, we can provide a super bill that you can submit for out of network reimbursements.
          </Text>
          <Text color="#344F79" fontWeight="600" mb={2}>
            If my medication dose changes, who prescribes the new or adjusted meds for me?
          </Text>
          <Text color="#344F79" mb={4}>
            Your Paloma physician will communicate any dose changes to your pharmacy of choice. Most of the time, your medication will be ready for pick up at your local retail pharmacy same-day.
          </Text>
          <Text color="#344F79" fontWeight="600" mb={2}>
            How will I interact with my Paloma Health physician?
          </Text>
          <Text color="#344F79" mb={4}>
            Through a video call.
            <br />
            <br />A video call is an amazing way to have a great interaction with your physician.
            <br />
            <br />
            The only thing you need is a smartphone or a computer with WIFI or a strong signal.
          </Text>
          <Text color="#344F79" fontWeight="600" mb={2}>
            What makes Paloma Health different?
          </Text>
          <Text color="#344F79" mb={4}>
            We believe the way you feel is more important than your lab numbers.
            <br />
            <br />
            We believe the thyroid gland is one of the most important contributors to your wellbeing and if not closely monitored can make you feel at best, "not your best" and at worst, worn out,
            depressed, struggling with weight, unable to function well at your work, and stressed about all of this.
            <br />
            <br />
            We make it easy to get expert, personalized treatment for hypothyroidism––all from the comfort of home. Delivered via telemedicine by expert, caring doctors, NPs and nutritionists, we
            combine evidence-based treatment approaches with at-home lab testing to deliver the best care and experience available.
          </Text>
          <Text color="#344F79" fontWeight="600" mb={2}>
            Who can benefit from Paloma Health treatment?
          </Text>
          <Text color="#344F79" mb={4}>
            Paloma health is a practice for anyone suffering with hypothyroidism or managing their care after the removal of their thyroid over the age of 18.We offer at home test kits and the best
            thyroid physicians and life coaches to help you monitor your condition and feel at your best.
          </Text>
          <Text color="#344F79" fontWeight="600" mb={2}>
            Where is the Paloma Health treatment available?
          </Text>
          <Text color="#344F79" mb={4}>
            Our providers are available in the following states: Alabama, Alaska, Arizona, California, Colorado, Florida, Georgia, Hawaii, Idaho, Illinois, Indiana, Iowa, Maryland, Massachusetts,
            Michigan, Minnesota, Missouri, Montana, Nevada, New Hampshire, New Mexico, North Dakota, Ohio, Oklahoma, Oregon, Pennsylvania, South Carolina, South Dakota, Tennessee, Texas, Utah,
            Virginia, Washington, Wisconsin and Wyoming.
          </Text>
          <Text color="#344F79" fontWeight="600" mb={2}>
            What kind of providers will I see?
          </Text>
          <Text color="#344F79" mb={4}>
            You pick the provider you want to meet with. Our dedicated Care Teams are led by highly-skilled endocrinologists, functional medicine doctors and NPs. If you want to be match with the best
            provider for you, fill out the form above!
          </Text>
          <Text color="#344F79" fontWeight="600" mb={2}>
            Will Paloma practitioners see me for other conditions besides hypothyroidism?
          </Text>
          <Text color="#344F79" mb={4}>
            We are a practice solely dedicated to providing the best care for hypothyroidism. We want to hear about all of your symptoms and other conditions and will advise on the best treatment plan
            to improve your overall well being with respect to your thyroid.
          </Text>

          <Text color="#344F79" fontWeight="600" mb={2}>
            Can I use my own pharmacy?
          </Text>

          <Text color="#344F79" mb={4}>
            Yes. We recognize patient freedom of choice and always allow patients to select their own preferred pharmacy if they choose to no longer use Paloma. If you prefer to have your prescription
            sent to a specific pharmacy, simply email us at:{' '}
            <a href="mailto:contact@palomahealth.com" class="link very-small">
              contact@palomahealth.com
            </a>{' '}
            for help.
            <br />
            <br />
            Note: we cannot guarantee the prices of other pharmacies, and they may be more expensive than when you have your prescription filled through our partner pharmacy.
          </Text>

          <Text color="#344F79" fontWeight="600" mb={2}>
            Can I get a copy of my prescription?
          </Text>

          <Text color="#344F79" mb={4}>
            While we don’t provide a physical copy of your prescription, if you would like to have your prescription sent to a different pharmacy your Paloma provider can do that for you! You can
            contact us by emailing us directly:{' '}
            <a href="mailto:contact@palomahealth.com" class="link very-small">
              contact@palomahealth.com
            </a>
            .
          </Text>

          <Text color="#344F79" fontWeight="600" mb={2}>
            Visit Cancellations and No Shows Policy
          </Text>
          <Text color="#344F79" mb={4}>
            <strong>Visit cancellation</strong>
            <br />
            Members will not be charged if the visit is canceled or rescheduled at least 4 hours before the scheduled start time.
            <br />
            <br />
            <strong>No Shows</strong>
            <br />
            Paloma Health will charge a no-show fee of ${getMemberVisitPrice()}. If you are more than 10 min late to your visit, you will be charged the no-show fee as well.
            <br />
            <br />
            <strong>How to Reschedule/Cancel</strong>
            <br />
            Visit your{' '}
            <a href="https://app.palomahealth.com/consultations" target="_blank" class="link very-small">
              Provider Visits page
            </a>{' '}
            to reschedule or cancel your upcoming visit at least 24 hours in advance. You may also message your care manager or email{' '}
            <a href="mailto:care@palomahealth" class="link very-small">
              care@palomahealth
            </a>{' '}
            for support
          </Text>
        </Flex>
        <Flex
          mt={4}
          mb={6}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{ cursor: 'pointer', flex: 'none' }}
          onClick={() => {
            if (window.Beacon) {
              window.Beacon('open');
            }
          }}
        >
          <Text fontSize={2}>Have a question? </Text>
          <Text fontSize={2} mb={4}>
            Send a message before booking
          </Text>
        </Flex>
      </Inner>
      <FlexHideFromMedium style={{ alignItems: 'flex-start', width: '400px', maxWidth: '400px' }} ml={4}>
        <Card
          style={{
            flex: 0,
            padding: '20px',
            position: 'fixed',
            maxHeight: 'calc(100vh - 120px)',
            overflowY: 'auto',
            marginTop: '40px',
          }}
          mb={4}
        >
          <Flex style={{ width: '360px', maxWidth: '360px' }} flexDirection="column">
            <Text fontWeight="600" fontSize="20px" mb={3}>
              Schedule a video visit
            </Text>
            <CalendarGrid
              isNotRegisteredFlow={isNotRegisteredFlow}
              nextAppointmentAvailable={selectedDoc.nextAppointmentAvailable}
              reschedulingAppointment={reschedulingAppointment && reschedulingAppointment.provider && reschedulingAppointment.provider.id === selectedDoc.id ? reschedulingAppointment : null}
              doctorName={selectedDoc.patientFacingName}
              providerId={selectedDoc.id}
              onSlotSelected={onSlotSelected}
              enforceDepartmentId={departmentId}
              showShortDay={true}
              canWaitlist={isToggleActive('VISIT_WAITLIST') && !isNotRegisteredFlow}
              showAddWaitlist={timeDoesntWorkOpen}
              isMeno={isMeno}
              hasLabs={hasLabs}
            />
          </Flex>
        </Card>
      </FlexHideFromMedium>
      <MobileCTA>
        <Button style={{ minWidth: '50%' }} variant="pink" onClick={() => setIsSelectMobileSlot(true)}>
          Book a visit
        </Button>
      </MobileCTA>
    </Flex>
  );
};

export default InnerDetails;
