import React, { Fragment } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';
import { withApollo, compose } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import * as Yup from 'yup';

import FormField from '../../../components/FormField';
import TextInput from '../../../components/TextInput';

const StyledText = styled(Text)`
  line-height: 1.6;
  border-bottom: 1px solid;
  text-align: left;

  &:hover {
    cursor: pointer;
    font-weight: 600;
  }
`;

class HeightWeight extends React.Component {
  constructor(props) {
    super(props);

    let defaults;

    if (props.savedData) {
      defaults = this.unFormatSubmit(props.savedData);
    } else {
      defaults = (props.data || {}).defaults;
    }

    this.state = {
      loading: false,
      isInitialized: true,
      defaults,
    };
  }

  formatSubmit = values => {
    const { data } = this.props;

    const regex = /_/g;
    let payload = [];
    payload.push(
      {
        questionId: 'LOCAL_121',
        answer: `${values.feet}'${values.inches}"`,
      },
      {
        questionId: 'LOCAL_122',
        answer: `${values.pounds}`,
      }
    );
    for (const k in values) {
      const questionId = k.replace(regex, '.');
      let answer;

      if (data.prefix) {
        answer = `${data.prefix} ${values[k]}`;
      } else {
        answer = values[k];
      }

      payload.push({ questionId, answer });
    }

    return payload;
  };

  unFormatSubmit = values => {
    const { data } = this.props;
    let payload = {};

    for (const k in values) {
      const questionId = values[k].questionId;
      let answer;

      if (data.prefix) {
        answer = values[k].answer.replace(`${data.prefix} `, '');
      } else {
        answer = values[k].answer;
      }

      payload[`${questionId.toString()}`] = answer;
    }
    return payload;
  };

  render() {
    const { data, onSubmit, wizard, savedData } = this.props;
    const { defaults, isInitialized } = this.state;
    if (!isInitialized) {
      return <div />;
    }

    const schema = Yup.object().shape({
      feet: Yup.number()
        .required()
        .positive()
        .integer(),
      inches: Yup.number()
        .required()
        .moreThan(-1)
        .integer(),
      pounds: Yup.number()
        .required()
        .positive()
        .integer(),
    });
    return (
      <Fragment>
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          <Box m={2}>
            <Heading textAlign="left">
              Please provide your height and weight
            </Heading>
          </Box>

          <Formik
            initialValues={defaults}
            validationSchema={schema}
            enableReinitialize={true}
            isInitialValid={(aaa, bbb) => {
              try {
                const aa = schema.validateSync(defaults);

                // const bb = await aa.isValid()
                if (aa.errors && aa.errors.length) {
                  return false;
                }
                return true;
              } catch (err) {
                return false;
              }
            }}
            onSubmit={(values, actions) => {
              const formatted = this.formatSubmit(values);
              onSubmit(formatted, data.id, actions, wizard, data);
            }}
            render={({ isValid, errors, touched, values }) => {
              return (
                <Form>
                  <Flex my={3} flexDirection="row" alignItems="center">
                    <FormField
                      containerStyle={{ flex: 'initial' }}
                      style={{ maxWidth: '80px', flex: 'initial' }}
                      error={touched['feet'] && errors['feet']}
                      alignSelf="center"
                    >
                      <TextInput
                        containerStyle={{ flex: 'initial' }}
                        style={{ maxWidth: '80px', flex: 'initial' }}
                        isAsyncMode={true}
                        name={'feet'}
                        type={'number'}
                        error={errors}
                      />
                    </FormField>
                    <Text ml={2} mr={4}>{` FEET`}</Text>

                    <FormField
                      containerStyle={{ flex: 'initial' }}
                      style={{ maxWidth: '80px', flex: 'initial' }}
                      error={touched['inches'] && errors['inches']}
                      alignSelf="center"
                    >
                      <TextInput
                        containerStyle={{ flex: 'initial' }}
                        style={{ maxWidth: '80px', flex: 'initial' }}
                        isAsyncMode={true}
                        name={'inches'}
                        type={'number'}
                        error={errors}
                      />
                    </FormField>
                    <Text ml={2}>{` INCHES`}</Text>
                  </Flex>

                  <Flex my={4} flexDirection="row" alignItems="center">
                    <FormField
                      containerStyle={{ flex: 'initial' }}
                      style={{ maxWidth: '80px', flex: 'initial' }}
                      error={touched['pounds'] && errors['pounds']}
                      alignSelf="center"
                    >
                      <TextInput
                        containerStyle={{ flex: 'initial' }}
                        style={{ maxWidth: '100px', flex: 'initial' }}
                        isAsyncMode={true}
                        name={'pounds'}
                        type={'number'}
                        error={errors}
                      />
                    </FormField>
                    <Text ml={2}>{` POUNDS`}</Text>

                  </Flex>

                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    mx={[3, 3, 0]}
                    my={[2, 3]}
                  >
                    <Button
                      disabled={!isValid || this.state.loading}
                      variant="primary"
                      width={[1, 1 / 2, 1 / 4]}
                      type="submit"
                    >
                      Continue
                    </Button>
                    {data.skip && (
                      <StyledText
                        mt={4}
                        onClick={() => {
                          wizard.push(data.skip);
                        }}
                      >
                        Skip
                      </StyledText>
                    )}
                  </Flex>
                </Form>
              );
            }}
          />
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(HeightWeight);
