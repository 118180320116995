import { Heading, Text } from "rebass";
import styled from "styled-components";

export const CText = styled(Heading)`
  font-size: 46px;

  @media screen and (max-width: 52em) {
    font-size: 32px;
  }
`;

export const CTextWithCount = styled(Heading)`
  font-size: 46px;
  line-height: 90px;

  @media screen and (max-width: 52em) {
    font-size: 32px;
    line-height: 50px;
  }
`;

export const CDescription = styled(Text)`
  font-size: 26px;

  @media screen and (max-width: 52em) {
    font-size: 16px;
  }
`;