import React from 'react';
import { Flex, Text } from 'rebass';

const LabDocumentViewer = ({ doc }) => {
  if (!doc || doc.length < 1) {
    return (
      <Flex width={'100%'} height="100%" justifyContent={'center'} alignItems="center" flex={1}>
        <Text>No Document</Text>
      </Flex>
    );
  }

  return (
    <Flex width={'100%'} height="100%" flexDirection={'column'} flex={1}>
      <Flex justifyContent={'center'} alignItems="center" width={'calc(100% + 24px)'} marginLeft="-24px" padding={1} backgroundColor="#acacac" flex={1}>
        <embed type={'application/pdf'} style={{ width: '100%', height: '100%' }} src={`data:application/pdf;base64,${doc}`} />
      </Flex> 
    </Flex>
  );
};

export default LabDocumentViewer;
