import React from 'react';
import IconContainer from './IconContainer';

const Stethoscope = props => (
  <IconContainer {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.11 250.07">
      <path
        d="M47,0c47.29.06,94.71,0,142,0a38.85,38.85,0,0,1,28.38,12.61,37.28,37.28,0,0,1,9.83,25.4c0,9.37,0,18.75,0,28.13,10.65.24,21.32.08,32,.08.39,20.93.08,41.88.16,62.81a23.81,23.81,0,0,0,.31,3.28c8.79.54,17.67-.13,26.48.31,0,3.77,0,7.55-.07,11.32-45.06.18-90.12.1-135.18,0-.08-3.19-.14-6.39-.22-9.59-8.25,0-16.5.06-24.75,0-.33,20.49-.1,41-.12,61.51,13.59,1.44,26.42,6.38,36,16.39,3.2,3.16,5.16,7.13,8,10.55,3.48-1.16,6.57-2.86,10.25-3.52a33.7,33.7,0,0,1,20.45,2.27c8.8,4,15.1,11.63,18.41,20.58.85,2.66,2.16,5.23.6,7.89H219c-1.88-6.93-4.23-13.44-9.13-18.86A30.84,30.84,0,0,0,183,221.05a39.35,39.35,0,0,0-12.21,3.9,76.49,76.49,0,0,1,4.82,14.66,10.49,10.49,0,0,1-.49,5.11c-2.43-4.72-2.79-9.94-4.86-14.81a48.19,48.19,0,0,0-20.68-24.41,58.45,58.45,0,0,0-23.65-7.32c-.15,6.94-.08,13.93-.06,20.88,5.08.37,9.88.72,14.72,2.47,1.57.47,2.18,1.7,3.21,2.87-7.54-1.21-14-3.89-21.81-2.46-6.88.95-12.2,4.87-16,10.54,3.64,2.69,7.73,5.48,10.53,9.07a4.42,4.42,0,0,1,.09,2.82c-7.16-6.51-13.95-12.51-23.39-15.48a32.16,32.16,0,0,0-25,2.4c-3.74,2-6.31,5.29-10.33,6.86,1.71-4.73,5.73-7,9.87-9.33,4.73-2.52,9.75-3.33,15-4,0-8.77-.08-17.54,0-26.31C72,201.15,61.4,206.66,54.55,215.6c-2.49,3.11-3.67,6.82-5.76,10.13l-1.63,0c.44-2.95,1.66-5.39,2.83-8.08-8.44-1.15-16.8-1.25-25,1.4A39.41,39.41,0,0,0,6.87,231c-2.25,2.57-3.51,5.37-6.87,6.72V236c4.38-6,9.11-11.5,15.69-15.18,11.06-6.43,23.52-7,35.9-4.93,7.56-10.78,18.91-16.45,31.35-19.74,0-20.55.11-41.11-.07-61.67-26.39,0-52.77.08-79.15-.06-.1-32.1-.05-64.22,0-96.32C3.54,22.67,13.56,8.36,27.84,2.81,34,.32,40.42-.13,47,0Z"
        fill="#3da2ee"
      />
      <path
        d="M50,2.08H158c5.72,0,11.44,0,17.16.15A72.31,72.31,0,0,0,164.73,8.8a37.41,37.41,0,0,0-13.91,28.27c-.07,31.85.09,63.71-.08,95.56q-66.87.09-133.74,0c-3.72,0-7.58.26-11.26-.29-.47-3.71-.2-7.56-.23-11.3q0-40,0-80c0-6,.65-11.82,3.17-17.31A36.82,36.82,0,0,1,33,3.17C38.39,1.7,44.43,2.12,50,2.08Z"
        fill="#dcefff"
      />
      <path
        d="M190,2.3a36.42,36.42,0,0,1,32.39,22.37c2.58,5.92,2.84,12,2.75,18.4-.08,7.68.05,15.36-.11,23.05-18.27.1-36.57.2-54.84-.06-1.69-7.41-2.77-15.05-4.93-22.33-4-.84-8.32-.34-12.4-.48-.22-6.68.14-13.15,3-19.31A36.41,36.41,0,0,1,190,2.3Z"
        fill="#98d1f6"
      />
      <path
        d="M152.79,45.32c3.41.07,6.81.09,10.21,0-1.5,4.27-1,8.35-1,12.77.09,24.81-.09,49.63.09,74.44-3,.11-6.31.4-9.27-.24-.44-6.38,0-12.81-.16-19.2C152.74,90.49,152.42,67.89,152.79,45.32Z"
        fill="#dcefff"
      />
      <path
        d="M163,45.3a70.91,70.91,0,0,1,2.71,10.76c5.27,25.45,10.75,50.86,16,76.33-6.53,0-13.08-.15-19.61.12-.18-24.81,0-49.63-.09-74.44C162,53.65,161.5,49.57,163,45.3Z"
        fill="#fff"
      />
      <path
        d="M21.32,48.56A105.8,105.8,0,0,1,33,48.33q45,0,90,0c3.81,0,7.74-.25,11.53.26a24,24,0,0,1,.47,4.48c-.1,21.38,0,42.81-.06,64.19-5,.25-10,.21-14.94.19H36c-5,0-10,0-15-.16,0-21.4,0-42.82,0-64.22A32.27,32.27,0,0,1,21.32,48.56Z"
        fill="#3da2ee"
      />
      <path
        d="M23.43,50.63c.84-.09,1.69-.15,2.54-.17,35.51,0,71.2,0,106.73,0,.12,21.58,0,43.15.08,64.72-4.93.23-9.85.22-14.78.18H38c-4.94,0-9.88,0-14.81-.16.06-17.38,0-34.76,0-52.14C23.25,59,23,54.73,23.43,50.63Z"
        fill="#98d1f6"
      />
      <path
        d="M171.1,68.62c8.6-.32,17.23.06,25.83-.15.35,21.27.08,42.56.13,63.84-4.34.11-8.68.1-13-.06-.34-1.74-.7-3.49-1-5.23l9-.11a138.31,138.31,0,0,0,.07-14c-4-.1-8-.09-12,0-2.39-11.31-4.75-22.62-7.15-33.92C172.2,75.65,171.24,72.1,171.1,68.62Z"
        fill="#b7edfa"
      />
      <path
        d="M199.55,68.67c19.09-.42,38.38.06,57.5-.22.42,21.27.09,42.59.15,63.87-19.29,0-38.6.26-57.88-.14C199.64,111.07,199.11,89.75,199.55,68.67Z"
        fill="#b7edfa"
      />
      <path
        d="M180.05,113c4-.12,8-.13,12,0a138.31,138.31,0,0,1-.07,14l-9,.11C182,122.34,181,117.66,180.05,113Z"
        fill="#98d1f6"
      />
      <path
        d="M205.13,121.18c2,.26,5.7-1.4,4.79,2a24.46,24.46,0,0,1-4.84,0Z"
        fill="#98d1f6"
      />
      <path
        d="M204.94,126.17a72,72,0,0,1,16.14,0l-.11,1.73a78.3,78.3,0,0,1-16,0Z"
        fill="#98d1f6"
      />
      <path
        d="M85.25,134.59c6-.39,11.94,0,17.9-.2.37,12.33.19,24.73.09,37.07-6.37-3.17-11.18-8.33-17.37-11.78-.6,11.77-.12,23.6-.27,35.39.07,10-.18,19.91.16,29.86l-.63-.14C85,196.91,85.19,169,85,141.07A48.31,48.31,0,0,1,85.25,134.59Z"
        fill="#dcefff"
      />
      <path
        d="M123.39,134.72a27.43,27.43,0,0,1,.36,4.34c-.09,26.71,0,53.44-.29,80.14-.48-8-.19-16.09-.24-24.13-.07-3.79.45-8-.52-11.65a120.46,120.46,0,0,0-17.34-9.58c-.15-13,0-26-.07-39A113.93,113.93,0,0,1,123.39,134.72Z"
        fill="#dcefff"
      />
      <path
        d="M153.17,134.8c3.92-.31,7.9-.06,11.83-.1,39.6,0,79.2,0,118.8,0l.06,7.13q-65.52,0-131,0A43.23,43.23,0,0,1,153.17,134.8Z"
        fill="#fff"
      />
      <path
        d="M85.87,159.68c6.19,3.45,11,8.61,17.37,11.78-.13,19.7,0,39.39-.07,59.09a50.67,50.67,0,0,0-17.41-5.62c-.34-10-.09-19.91-.16-29.86C85.75,183.28,85.27,171.45,85.87,159.68Z"
        fill="#fff"
      />
      <path
        d="M105.36,173.84a120.46,120.46,0,0,1,17.34,9.58c1,3.68.45,7.86.52,11.65,0,8-.24,16.11.24,24.13-3.31.7-6.48,1.24-9.52,2.78-3.28,1.65-5.7,4-8.45,6.38C105.38,210.19,105.64,192,105.36,173.84Z"
        fill="#fff"
      />
    </svg>
  </IconContainer>
);

export default Stethoscope;
