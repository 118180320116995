import React from 'react';
import { Card, Flex, Heading, Link, Text } from 'rebass';
import FullLayout2 from '../../components/FullLayout';
import withSession from '../../lib/withSession';
import withAnalytics from '../../lib/withAnalytics';
import VitaminsBox from '../../static/vitaminsBox.png';
import { Query } from 'react-apollo';
import { GET_SESSION } from '../../graphql';
import SpinLoader from '../../components/SpinLoader';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  font-size: 14px;
  color: #999;
  text-decoration: underline;
  align-self: center;
`;
const VitaminsOrderConfirmationB2B = ({
  address
}) => {
  return (
    <Flex style={{ margin: '0 auto' }}>
      <Card p={4} id="receiptcontainer" alignSelf="center">
        <Heading textAlign="center" mb={4}>
          Your Order Confirmation
        </Heading>

        <>
          <Flex flexDirection={['column', 'column', 'row']}>
            <Flex
              flex={1}
              pr={2}
              pl={2}
              style={{ border: '1px solid rgba(0,0,0,0.25)' }}
              mb={2}
            >
              <div style={{ textAlign: 'center', width: '100%' }}>
                <Flex mb={4} justifyContent="left" />
                <Text
                  textAlign="center"
                  mb={4}
                  mt={2}
                  lineHeight={1.6}
                  fontSize="22px"
                  fontWeight={600}
                  color="#344f79"
                >
                  Customer Information
                </Text>
                <div
                  style={{
                    borderBottom: '1px solid rgba(0,0,0,0.25)',
                    width: 'calc(100% + 16px)',
                    marginLeft: '-8px',
                    marginBottom: '32px',
                  }}
                />

                <Flex
                  flexDirection={'column'}
                  style={{
                    justifyContent: 'start',
                    alignItems: 'start',
                    textAlign: 'left',
                  }}
                >
                  <Flex flexDirection="column" style={{ textAlign: 'left' }}>
                    <Text fontWeight={600}>Shipping Address</Text>

                    <Text>
                      {address.firstName} {address.lastName}
                    </Text>
                    <Text>{address.street1}</Text>
                    <Text>{address.street2}</Text>
                    <Text>
                      {address.city}, {address.state} {address.zip}
                    </Text>
                  </Flex>
                </Flex>
                <div
                  style={{
                    borderBottom: '1px solid rgba(0,0,0,0.25)',
                    width: 'calc(100% + 16px)',
                    marginLeft: '-8px',
                    marginBottom: '32px',
                  }}
                />
              </div>
            </Flex>
            <Flex
              flex={1}
              pr={2}
              pl={2}
              style={{
                border: '1px solid rgba(0,0,0,0.25)',
                // borderLeftWidth: 0,
              }}
              flexDirection="column"
              mb={2}
            >
              <div style={{ textAlign: 'left' }}>
                <Flex mb={4} justifyContent="left" />
                <Text
                  textAlign="center"
                  mb={4}
                  mt={2}
                  lineHeight={1.6}
                  fontSize="22px"
                  fontWeight={600}
                  color="#344f79"
                >
                  Your Order
                </Text>
                <div
                  style={{
                    borderBottom: '1px solid rgba(0,0,0,0.25)',
                    width: 'calc(100% + 16px)',
                    marginLeft: '-8px',
                    marginBottom: '32px',
                  }}
                />

                <Flex mb={3} justifyContent="center">
                  <img src={VitaminsBox} alt="" height="200px" />
                </Flex>
              </div>
            </Flex>
          </Flex>
        </>
      </Card>
      <FullLayout2 />
    </Flex>
  );
};

export default VitaminsOrderConfirmationB2B;
