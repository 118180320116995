import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import { Box, Flex, Button, Card, Text } from 'rebass';
import { Mutation, compose, withApollo } from 'react-apollo';
import { Formik, Form } from 'formik';
import { withToastManager } from 'react-toast-notifications';

import withSession from '../lib/withSession';
import TextInput from './TextInput';
import FormField from './FormField';
import SpinLoader from './SpinLoader';
import { CURRENT_USER, SET_PHARMACY } from '../graphql';
import { getRegionByAbbreviation } from './RegionSelect';

const styles = {
  map: {
    position: 'relative',
    borderRadius: '5px',
    minHeight: '350px',
    maxHeight: '350px',
    height: '100%',
    width: '100%',
  },
  container: {
    position: 'relative',
  },
  button: {
    borderRadius: '45px',
    backgroundColor: '#344f79',
    border: 'none',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '25px',
    paddingRight: '25px',
    cursor: 'pointer',
  },
};

class PharmacySearch extends Component {
  state = {
    center: {
      lat: 40.7128,
      lng: -74.006,
    },
    places: [],
    selected: {},
    selectedInfo: {},
    active: {},
    loading: false,
    showInfo: false,
    loadMap: false,
  };

  componentDidMount = async () => {
    // console.log('CDM', { navigator });
    try {
      navigator.geolocation.getCurrentPosition();
      // console.log('CDM  nor catch')

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { coords } = position;
            this.setState({
              center: {
                lat: coords.latitude,
                lng: coords.longitude,
              },
              loadMap: true,
            });
          },
          () => {
            this.setState({ loadMap: true });
          }
        );
      } else {
        this.setState({ loadMap: true });
      }
    } catch (error) {
      this.setState({ loadMap: true });
    }
  };

  fetchPlaces = (map, query = '') => {
    const service = new this.props.google.maps.places.PlacesService(map);
    const request = {
      query: query,
      type: ['pharmacy'],
    };

    if (!query.length) {
      request.location = map.center;
      request.bounds = map.getBounds();
    }

    service.textSearch(request, (results, status) => {
      const bounds = new this.props.google.maps.LatLngBounds();

      results.forEach((place) => {
        bounds.extend(
          new this.props.google.maps.LatLng({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          })
        );
      });

      this.setState(this.state.map ? { places: results, bounds } : { places: results, bounds, map });
    });
  };

  fetchDetails = (placeId) => {
    const service = new this.props.google.maps.places.PlacesService(this.state.map);

    const request = {
      placeId,
      fields: ['formatted_address', 'website', 'formatted_phone_number', 'address_components'],
    };

    return new Promise((resolve, reject) => {
      service.getDetails(request, (results, status) => {
        if (status === this.props.google.maps.places.PlacesServiceStatus.OK) {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
  };

  handleSearch = (query) => {
    this.fetchPlaces(this.state.map, query);
  };

  handleMarkerClick = async (props, marker, e) => {
    this.setState({
      selected: props,
      active: marker,
      showInfo: true,
    });

    const details = await this.fetchDetails(props.id);
    this.setState({ selectedInfo: details });
  };

  handleClose = () => {
    if (this.state.showInfo) {
      this.setState({
        showInfo: false,
        active: {},
        loading: false,
        selectedInfo: {},
      });
    }
  };

  handleSetPharmacy = async (place, setPharmacy) => {
    const { toastManager, onSelected = () => {}, setIsSaving, isIntake } = this.props;
    if(!!setIsSaving) {
      setIsSaving(true)
    }
    this.setState({ loading: true });
    const placeId = place.place_id || place.id;
    const selected = await this.fetchDetails(placeId);

    let pharmacyState = null;
    try {
      const administrative_area_level_1s = selected.address_components.filter((x) => x.types && x.types.indexOf('administrative_area_level_1') > -1);

      if (administrative_area_level_1s) {
        const stateObject = administrative_area_level_1s.find((x) => x.short_name && x.short_name.length === 2);
        if (stateObject && stateObject.short_name) {
          pharmacyState = stateObject.short_name;
        }
      }
    } catch (error) {}
    try {
      await setPharmacy({
        variables: {
          placeId,
          name: place.name,
          formattedAddress: selected.formatted_address,
          phone: selected.formatted_phone_number || 'Phone not found',
          pharmacyState,
        },
      });

      this.handleClose();
      await this.props.refetch();
      window.scroll(0, 0);
      this.setState({ loading: false });

      if(!!setIsSaving) {
        setIsSaving(false)
      }
      if (onSelected) {
        onSelected();
      }

      if (window.ReactNativeWebView && !isIntake) {
        window.ReactNativeWebView.postMessage('closeMobilePane');
      }
    } catch (error) {
      if(!!setIsSaving) {
        setIsSaving(false)
      }
      this.setState({ loading: false });
      toastManager.add(error.message, { appearance: 'error' });
    }
  };

  renderButton = (setPharmacy) => {
    const { selected } = this.state;

    const button = (
      <Button style={styles.button} my={2} onClick={() => this.handleSetPharmacy(selected, setPharmacy)}>
        Select This Pharmacy
      </Button>
    );

    ReactDOM.render(React.Children.only(button), document.getElementById('set-pharmacy'));
  };

  render() {
    const { forceOrientation = ['column', 'row'], forceWidth = [1, 1, 1 / 3] } = this.props;
    const { center, active, showInfo, selected, selectedInfo, places, loadMap, bounds, loading } = this.state;

    if (!loadMap) {
      return (
        <Flex justifyContent="center">
          <SpinLoader />
        </Flex>
      );
    }

    return (
      <Mutation mutation={SET_PHARMACY} refetchQueries={[{query: CURRENT_USER}]}>
        {(setPharmacy, { loading: loadingMutation }) => (
          <Fragment>
            <Flex mb={[1, 1, 4]} flexDirection={forceOrientation}>
              <Flex width={forceWidth} mr={[0, 0, 4]} flexDirection="column">
                <Box>
                  <Card p={[3, 4]} mb={4}>
                    <Text style={{ fontFamily: 'Playfair Display' }} fontSize={3} fontWeight={600} mb={3}>
                      Find Your Pharmacy
                    </Text>
                    <Formik
                      initialValues={{
                        query: '',
                      }}
                      onSubmit={(values) => this.handleSearch(values.query)}
                      render={() => (
                        <Form>
                          <FormField mb={3}>
                            <TextInput placeholder="Address or Zip Code" type="text" name="query" />
                          </FormField>

                          <Flex justifyContent="center" flex={1}>
                            <Button disabled={loading || loadingMutation} variant="primary" type="submit" flex={1}>
                              Search
                            </Button>
                          </Flex>
                        </Form>
                      )}
                    />
                  </Card>
                </Box>
              </Flex>
              <Flex flexDirection="column" flex={1} mb={[4, 4, 0]}>
                <Map google={this.props.google} zoom={15} style={styles.map} containerStyle={styles.container} onReady={(props, map) => this.fetchPlaces(map)} initialCenter={center} bounds={bounds}>
                  {places.filter(x=>!x.business_status || x.business_status !=='CLOSED_PERMANENTLY').map((place) => (
                    <Marker
                      id={place.place_id}
                      key={place.place_id}
                      name={place.name}
                      position={{
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                      }}
                      onClick={this.handleMarkerClick}
                    />
                  ))}

                  <InfoWindow marker={active} visible={showInfo} onOpen={() => this.renderButton(setPharmacy)} onClose={this.handleClose}>
                    <Flex flexDirection="column">
                      <Text mb={3} fontSize={3} fontWeight={500}>
                        {selected.name}
                      </Text>

                      <Text fontSize={2} mb={2}>
                        {selectedInfo.formatted_address}
                      </Text>
                      <Text fontSize={2} mb={2}>
                        {selectedInfo.formatted_phone_number}
                      </Text>

                      <Flex id="set-pharmacy" />
                    </Flex>
                  </InfoWindow>
                </Map>
              </Flex>
            </Flex>
            <Flex flexDirection="column">
              {places.filter(x=>!x.business_status || x.business_status !=='CLOSED_PERMANENTLY').map((place) => {
                return (
                  <Card key={place.place_id} p={[3, 4]} mb={4}>
                    <Flex flex={1} flexDirection={['column', 'row']}>
                      <Flex flexDirection="column" flex={1} mb={[3, 0]}>
                        <Text fontSize={3} fontWeight={500} mb={3}>
                          {place.name}
                        </Text>
                        <Text fontWeight={300}>{place.formatted_address}</Text>
                      </Flex>
                      <Flex flexDirection="column" justifyContent="center">
                        <Button
                          variant="primary"
                          onClick={() => {
                            this.handleSetPharmacy(place, setPharmacy);
                          }}
                          disabled={loading||loadingMutation}
                        >
                          {loadingMutation || loading ? 'Saving Selection' : 'Select This Pharmacy'}
                        </Button>
                      </Flex>
                    </Flex>
                  </Card>
                );
              })}
            </Flex>
          </Fragment>
        )}
      </Mutation>
    );
  }
}

export default compose(
  GoogleApiWrapper({
    apiKey: window._env_.REACT_APP_GOOGLE_API,
  }),
  withSession,
  withToastManager
)(PharmacySearch);
