import React from 'react';
import { Flex, Heading, Text } from 'rebass';
import moment from 'moment'
import styled from 'styled-components';

import ItemCollection from '../../components/icons/ItemCollection';
import { DateMobile } from './LabResults2';

const Icon = styled(Flex)`
  display: none;
  @media screen and (min-width: 52em) {
    display: block;
  }
`;
export const LabStep4 = ({currentStep, date}) => {
  console.log({currentStep})
  return (
    <Flex flexDirection={['column', 'row']} flex={1}>

      <Flex flexDirection="column" flex={1}>
        <DateMobile mb={3}>{date}</DateMobile>
        <Text mb={3} fontWeight={600}>
            Your kit is being processed
        </Text>
        <Text lineHeight={1.6} fontSize={'14px'}>
          Your results are almost ready. We expect them to be available around {moment(currentStep.eta, 'YYYY-MM-DD').format('MMMM Do')}. You will receive an email and a text message once they are ready. Stay Tuned!
        </Text>
      </Flex>
      <Icon
        mr={[0, 4]}
        mb={[3, 0]}
        alignItems="flex-start"
        justifyContent="center"
      >
        <ItemCollection width={90} />
      </Icon>
    </Flex>
  );
};
