import { Form, Formik } from 'formik';
import React from 'react';
import { Button, Card, Flex, Heading, Text } from 'rebass';
import FormField from '../../components/FormField';
import TextInput from '../../components/TextInput';

const SearchView = ({ search, onSearch }) => {
  return (
    <Card mb={4} p={[3, 4]}>
      <Flex flexDirection={'column'} flex={1} alignItems="center">
        <Formik
          initialValues={{ email: search }}
          onSubmit={(values, actions) => onSearch(values.email)}
          render={({ errors, touched, isValid, values }) => (
            <Form style={{ width: '300px' }}>
              <Flex flexDirection="column" flex={1}>
                <Heading mb={4}>Patient's Email, Athena ID, or Phone #</Heading>
              </Flex>
              <FormField mb={4} error={touched.email && errors.email}>
                <TextInput placeholder="Email Address" name="email" />
              </FormField>

              <Button mt={4} mb={4} variant="primary" width={1 / 2} disabled={!values.email} type="submit">
                Search
              </Button>
            </Form>
          )}
        />
      </Flex>
    </Card>
  );
};

export const SearchViewDashboard = ({ search, onSearch }) => {
  return (
    <Flex flexDirection={'column'} flex={1}>
      <Formik
        initialValues={{ email: search }}
        onSubmit={(values, actions) => onSearch(values.email)}
        render={({ errors, touched, isValid, values }) => (
          <Form style={{ width: '100%' }}>
            <Flex flexDirection="column" flex={1}>
              <Text style={{fontSize: '20px', fontWeight: 500, textAlign: 'left'}}>Search Patient</Text>
            </Flex>
            <Flex justifyContent={'center'} alignItems='center'>
              <Flex flex={1}>
                <FormField mb={4} error={touched.email && errors.email} style={{width: '100%'}}>
                  <TextInput autofocus placeholder="Email, Athena ID, or phone #" name="email" mr={3} style={{width: '100%'}}/>
                </FormField>
              </Flex>

              <Button ml={3} mb={4} variant="primary" disabled={!values.email} type="submit">
                Search
              </Button>
            </Flex>
          </Form>
        )}
      />
    </Flex>
  );
};

export default SearchView;
