import React from 'react';
import { withWizard } from 'react-albus';
import { Mutation, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { Button, Card, Heading, Text, Flex } from 'rebass';
import styled from 'styled-components';
import PleaseWait from '../../../../components/PleaseWait';
import { APPLY_CANCELLATION_COUPON, GET_MEMBERSHIPS } from '../../../../graphql';
import plant from '../../../../static/plant.png';

const A = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: #44a5ff;
  text-decoration: underline;
  cursor: pointer;
  margin: 36px auto 0 auto;
`;

const OfferWrapper = styled(Flex)`
  border-radius: 10px;
  background: #d9e6f8;
  flex-direction: column;
  padding: 30px 10px;
  margin-bottom: 18px;
  cursor: pointer;
`;

const Content = styled(Flex)`
  flex-direction: row;
`;

const OfferTextWrapper = styled(Flex)`
  flex-direction: column;
  margin-left: 16px;
  text-align: left;
`;

const Offers = ({ subscriptionId, wizard, history, subscriptionType }) => {
  return (
    <Query query={GET_MEMBERSHIPS} fetchPolicy="network-only">
      {({ loading, data, error }) => {
        if (loading) return <PleaseWait style={{ position: 'absolute', left: '50%', top: '50%' }} />;
        const vitaminsData = data.getMemberships.memberships.find((x) => x.subscriptionTarget === 'SUBSCRIPTION_VITAMINS');
        let price = '';
        if (vitaminsData && vitaminsData.price) {
          price = ((vitaminsData.price / 100) * 0.75).toFixed(2);
        }
        return (
          <Mutation
            mutation={APPLY_CANCELLATION_COUPON}
            refetchQueries={[{ query: GET_MEMBERSHIPS }]}
            awaitRefetchQueries
            variables={{
              subscriptionId,
              subscriptionType,
            }}
            onCompleted={() => {
              history.push({
                pathname: '/subscription-updated',
                state: {
                  title: 'Your Subscription Has Been Updated',
                  text: 'The coupon has been added',
                },
              });
            }}
          >
            {(applyCoupon, { loading }) => {
              return loading ? (
                <PleaseWait />
              ) : (
                <Flex flexDirection="column">
                  <Card
                    style={{
                      flexDirection: 'column',
                      textAlign: 'center',
                      padding: '32px',
                    }}
                  >
                    <Heading mb={4}>We Have Exclusive Offers for You!</Heading>

                    <Flex
                      flexDirection="column"
                      style={{
                        margin: '0 auto',
                        display: 'table',
                      }}
                    >
                      <OfferWrapper >
                        <Content>
                          <img src={plant} alt="" />

                          <OfferTextWrapper>
                            <Text fontWeight="600">Enjoy 25% off your next 4 months</Text>
                            {!!price && <Text fontWeight="300">{`$${price} / month`}</Text>}
                          </OfferTextWrapper>
                        </Content>
                        <Button
                          variant="primary"
                          mt={4}
                          width="150px"
                          onClick={applyCoupon}
                          style={{
                            marginTop: '12px',
                            padding: '6px',
                            marginLeft: '88px',
                          }}
                        >
                          Select
                        </Button>
                      </OfferWrapper>
                      {/* <OfferWrapper>
            <img src={plant} alt="" />

            <OfferTextWrapper>
              <Text fontWeight="600">Pay for the year and get 50% off</Text>
              <Text fontWeight="300">$10 / month</Text>
            </OfferTextWrapper>
          </OfferWrapper> */}
                    </Flex>
                    {/* <Button
                style={{ marginTop: '28px', width: 'auto' }}
                variant="primary"
                onClick={() => history.push('/')}
              >
                Back to Dashboard
              </Button> */}

                    {/* <A onClick={() => confirm(wizard)}>Cancel Subscription</A> */}
                  </Card>
                  <A
                    onClick={() => {
                      history.push('/');
                    }}
                  >
                    Back to Dashboard
                  </A>
                  {/* <A onClick={goBack}>Bo Back</A> */}
                </Flex>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default withRouter(withWizard(Offers));
