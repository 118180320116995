import React, { Fragment, useState } from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import styled, { keyframes } from 'styled-components';
import { isMobile } from 'react-device-detect';

import { useMount } from '../../../utils/hooks/lifecycle';
import CheckoutForm from './components/CheckoutForm';
import DocWithSample from '../../../static/DocWithSample.png';
import { APPOINTMENTS, SEND_KLAVIYO_STATUS } from '../../../graphql';
import { withRouter } from 'react-router';
import PleaseWait from '../../../components/PleaseWait';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
`;
const mainBgAnimate = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

const WhenBox = styled(Box)`
  background: white;
  position: ${() => (isMobile ? 'fixed' : 'sticky')};
  bottom: 0;
  z-index: 1;
  width: ${() => (isMobile ? '100vw' : '100%')};
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  left: ${() => (isMobile ? '0' : '')};
  animation: ${() => mainBgAnimate} 300ms ease-in forwards;
`;

const OrderItemComponent = ({ orderItem, remove = () => {}, isLast = false }) => {
  return (
    <Flex flexDirection="column" flex={1}>
      <Flex flexDirection="row">
        {orderItem.image ? (
          <img
            src={orderItem.image}
            alt=""
            style={{
              maxHeight: '59px',
              maxWidth: '81px',
              width: '81px',
              objectFit: 'contain',
            }}
          />
        ) : null}

        <Flex flexDirection="column" flex={1}>
          <Flex flexDirection="row">
            <Text fontWeight="400" fontSize="14px" flex="1">
              {orderItem.title}
            </Text>
            <Text fontWeight="600" fontSize="14px">
              ${orderItem.price}
            </Text>
          </Flex>
          <Text fontWeight="300" mt={2} fontSize="12px">
            {orderItem.description}
          </Text>
          {orderItem.canRemove ? (
            <Text
              mt={2}
              fontWeight="600"
              fontSize="12px"
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={remove}
            >
              Remove
            </Text>
          ) : null}
        </Flex>
      </Flex>
      {!isLast && (
        <Box
          mt={3}
          mb={3}
          flex={1}
          style={{
            borderTop: '1px solid rgba(53, 78, 121, 0.15)',
            height: '1px',
          }}
        />
      )}
    </Flex>
  );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Poppins',
    },
  ],
};
const PaymentForm = ({ addons, values, hpiContent, getState, onSubmit }) => {
  const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_KEY);
  return (
    <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
      <CheckoutForm addons={addons} values={values} hpiContent={hpiContent} getState={getState} onSubmit={onSubmit} />
    </Elements>
  );
};

const Finish = ({ onSubmit, getState, initiateOrder, updateOrder, removeFromOrder, data, client, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState();
  const [values, setValues] = useState();
  const [hpi, setHpi] = useState();
  const [whenShown, setWhenShown] = useState(false);

  useMount(async () => {
    const appts = await client.query({
      query: APPOINTMENTS,
      fetchPolicy: 'network-only',
    });
    if (!!appts && !!appts.data && appts.data.appointments && appts.data.appointments.length > 0) {
      const futureAsync = appts.data.appointments.filter((item) => {
        if (
          item.status &&
          (item.status === 'f' || item.status === '2') &&
          (item.appointmentTypeId === window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID || item.appointmentTypeId === window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID)
        ) {
          return true;
        }
        return false;
      });
      if (!!futureAsync && futureAsync.length > 0) {
        history.push('/');
        return;
      }
    }

    const order = await initiateOrder();
    const st = getState();
    setOrder(order);
    setValues(st.values);
    const { hpiContent } = st;
    let finalHpi = hpiContent
      .sort((a, b) => {
        if (a.index < b.index) return -1;
        if (b.index < a.index) return 1;
        return 0;
      })
      .map((x) => x.value)
      .join('');

    setHpi(finalHpi);

    if (data && data.klaviyoEvent) {
      try {
        await client.mutate({
          mutation: SEND_KLAVIYO_STATUS,
          variables: {
            step: data.klaviyoEvent,
          },
        });
      } catch (error) {}
    }
    setIsLoading(false);
  });

  if (!!isLoading) {
    return (
      <Container>
        <PleaseWait
          text=" "
          spinner={{
            sColor: '#ECEFF2',
            pColor: '#909FB5',
          }}
        />
      </Container>
    );
  }

  return (
    <Fragment>
      <Flex m={2} flex={1} flexDirection="column" style={{ zIndex: 1 }} pb={5}>
        <Box mt="0" mb={3}>
          <Heading textAlign="left">Finish Your Visit</Heading>
        </Box>
        {order && (
          <>
            <Box mt="0" mb={3}>
              {order.items
                ? order.items.map((x, i) => (
                    <OrderItemComponent
                      key={x.item}
                      isLast={i === order.items.length - 1}
                      remove={async () => {
                        setIsLoading(true)
                        const order = await removeFromOrder(x.item);

                        setOrder(order);
                        setIsLoading(false)
                      }}
                      orderItem={x}
                    />
                  ))
                : null}
            </Box>
            {order.memberSavingsAddons ? (
              <Flex
                flexDirection="row"
                pt={3}
                mb={3}
                style={{
                  borderTop: '1px solid rgba(53, 78, 121, 0.15)',
                }}
              >
                <Text fontWeight="400" fontSize="14px" flex="1">
                  You Saved as a Member
                </Text>
                <Text fontWeight="600" fontSize="14px" color="rgba(246, 162, 147, 1)">
                  - ${(order.memberSavingsAddons || 0) / 100}
                </Text>
              </Flex>
            ) : null}
            {order.registeringForMembership ? (
              <Flex
                flexDirection="row"
                pt={3}
                style={{
                  borderTop: '1px solid rgba(53, 78, 121, 0.15)',
                }}
              >
                <Text fontWeight="400" fontSize="14px" flex="1">
                  Paloma Membership
                </Text>
                <Text fontWeight="600" fontSize="14px" color="rgba(246, 162, 147, 1)">
                  1st month FREE
                </Text>
              </Flex>
            ) : null}

            <Box
              mt={3}
              mb={3}
              style={{
                borderTop: '3px solid rgba(53, 78, 121, 1)',
                height: '1px',
              }}
            />

            <Flex flexDirection="row">
              <Text flex={1} fontWeight="400" fontSize="14px">
                Total due if prescribed
              </Text>
              <Text fontSize="14px" fontWeight="600">
                ${(order.totalDue || 0) / 100}
              </Text>
            </Flex>
            <Text fontWeight="300" fontSize="12px" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setWhenShown(true)}>
              When am I charged?
            </Text>
            <Box
              mt={3}
              mb={4}
              style={{
                borderTop: '1px solid rgba(53, 78, 121, 0.4)',
                height: '1px',
              }}
            />

            <Flex flexDirection="row" mb={3}>
              <Text color="rgba(246, 162, 147, 1)" fontSize="18px" fontWeight="600" flex={1}>
                Due today:
              </Text>
              <Text color="rgba(246, 162, 147, 1)" fontSize="18px" fontWeight="600">
                ${(order.dueToday || 0) / 100}
              </Text>
            </Flex>
            {/* <PromoCode /> */}
            <PaymentForm getState={getState} hpiContent={hpi} values={values} onSubmit={onSubmit} addons={[...order.items.map((x) => x.item), order.registeringForMembership ? 'membership' : '']} />
          </>
        )}
      </Flex>
      {whenShown ? (
        <>
          <Box
            style={{
              position: 'fixed',
              background: 'rgba(0,0,0,0.15)',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
            onClick={() => setWhenShown(false)}
          />
          <WhenBox>
            <Flex flexDirection="column" flex={1} p="28px">
              <Text textAlign="right" style={{ cursor: 'pointer' }} onClick={() => setWhenShown(false)}>
                X
              </Text>

              <img src={DocWithSample} height="102px" width="111px" style={{ margin: '0 auto' }} />

              <Text fontWeight="600" fontSize="16px" mt={3} mb={3}>
                When do we charge for your visit?
              </Text>
              <Text fontWeight="300" fontSize="14px" mt={3} mb={3}>
                An experienced healthcare provider will evaluate your condition and discuss treatment options. The healthcare provider will determine if a prescription is right for your health
                condition. You won’t be charged until this review is completed and you have been approved for treatment. This review typically occurs within 48 hours.
              </Text>
            </Flex>
          </WhenBox>
        </>
      ) : null}
    </Fragment>
  );
};

export default compose(
  withRouter,
  withApollo,
  withWizard,
  withToastManager
)(Finish);
