import React, { Component, Fragment } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { insuranceCompaniesList } from '../../scheduleDoctor2/lists/insuranceCompaniesList';
import Select from 'react-select';
import { BoxButton } from '../../../components/async/SharedComponents';

const NoOptionMessage = ({ bypassInsurance, wizard }) => {
  return (
    <div style={{ padding: '12px 24px' }}>
      <Text textAlign="left" mb={2} fontSize="16px" fontWeight="400">
        Sorry, we don't recognize this insurance
      </Text>
      <Text textAlign="left" mb={2} fontSize="16px" fontWeight="400">
        1) Check your spelling
        <br />
        2) Check for abbreviations
        <br />
        3) Your insurance is not supported
      </Text>
      <Text textAlign="left" mb={2} fontSize="16px" fontWeight="400">
        Insurance is not required to see a doctor.
        <br />
        You can add your insurance details later.
      </Text>
      <Text
        textAlign="center"
        mb={1}
        fontSize="18px"
        style={{ cursor: 'pointer' }}
        onClick={() => bypassInsurance(wizard)}
      >
        <span style={{ color: '#364f79' }}>Continue</span>
        <span style={{ color: '#f9a394', fontWeight: '600' }}>{' >'}</span>
      </Text>
    </div>
  );
};

class InsuranceSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isInitialized: true,
      selected: null,
    };
  }

  unFormatSubmit = values => {
    let payload = {};

    if (values && values.values) {
      payload = values.values;
    }

    return payload;
  };

  handleClick = async element => {
    const { data, onSubmit } = this.props;
    this.setState({ selected: element });

    const payload = { questionId: element.questionId, answer: element.value };
    let handler;
    if (data.onSubmitHandler) {
      handler = data.onSubmitHandler(element);
    }
    // console.log({ data, handler, element });
    onSubmit(payload, handler);
  };

  shiftView = () => {
    setTimeout(() => {
      window.scrollTo(0, this.inputRef.offsetTop);
    }, 150);
  };

  render() {
    const { data } = this.props;
    const { selected, isInitialized } = this.state;

    if (!isInitialized) {
      return <div />;
    }

    return (
      <Fragment>
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          {data.title ? (
            <Box m={2} mt="0" mb={3}>
              <Heading textAlign="left">{data.title}</Heading>
            </Box>
          ) : null}
          <Box m={2} mt="0" mb={4}>
            <Text
              textAlign="left"
              fontSize="16px"
              fontWeight="300"
              lineHeight="24px"
            >
              Search for your insurance provider to see if you are covered
            </Text>
          </Box>
          <Select
            classNamePrefix="region-select"
            options={insuranceCompaniesList}
            placeholder="Search"
            isSearchable={false}
            value={selected}
            onChange={v =>
              this.setState({
                selected: v,
              })
            }
            noOptionsMessage={() => <NoOptionMessage />}
            theme={base => ({
              ...base,
              colors: {
                ...base.colors,
                primary: '#364f79',
                primary50: '#dae6fa',
              },
            })}
          />
          <BoxButton className={selected ? 'visible' : ''}>
            <Button
              variant="primary"
              onClick={() => {
                this.handleClick(selected);
              }}
              width={[1, 1 / 2, 1 / 4]}
              style={{
                marginLeft: '12px',
                marginRight: '12px',
              }}
            >
              Continue
            </Button>
          </BoxButton>
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager,
)(InsuranceSelector);
// export default withWizard(Single);
