import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { withRouter } from 'react-router';
import { withToastManager } from 'react-toast-notifications';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../../components/FullLayout';
import Header from '../../components/Header';
import LoadingScreen from '../../components/LoadingScreen';
import { COMPLETE_WEB_REGISTRATION, CURRENT_USER, GET_ANALYTICS_ID } from '../../graphql';
import withAnalytics from '../../lib/withAnalytics';
import withSession from '../../lib/withSession';
import Otp from '../registration/Otp';
import Basic from './Basic';
import Extended from './Extended';
import Profile from './Profile';
import moment from 'moment';
import { getRegionByAbbreviation } from '../../components/RegionSelect';
import { isToggleActive } from '../../components/featureToggle/toggles';
import { withApollo } from 'react-apollo';
import PreferredPronouns from './PreferredPronouns';
import GenderIdentity from './GenderIdentity';
import LegalSex from './LegalSex';
import BirthSex from './BirthSex';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const CompleteProfile = ({ history, toastManager, analytics, location, client, session: { firstName, dob } }) => {
  const wizard = useRef();
  const [currentPage, setCurrentPage] = useState();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    phone: '',
    gender: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    consent: false,
    diagnosed: null,
    dob: '',
  });

  const handleSubmit = (vals, actions) => {
    setValues({ ...values, ...vals });
    if (!!actions) {
      actions.setSubmitting(false);
    }
    console.log({ values });
    wizard.current.next();
  };

  const handleComplete = async (vals, actions, wizard) => {
    setValues({ ...values, ...vals });
    const variables = { ...values, ...vals };
    console.log({ variables });

    try {
      const { data } = await client.mutate({
        mutation: COMPLETE_WEB_REGISTRATION,
        variables: {
          ...variables,
        },
        refetchQueries: [{ query: CURRENT_USER }],
        awaitRefetchQueries: true,
      });

      const aa = await client.query({
        query: GET_ANALYTICS_ID,
        fetchPolicy: 'network-only',
      });
      let age = 0;
      try {
        age = moment().diff(moment(variables.dob, 'MM/DD/YYYY'), 'years');
      } catch (error) {}
      if (aa && aa.data && aa.data.getAnalyticsId) {
        analytics.Alias(aa.data.getAnalyticsId);
        analytics.Identify(aa.data.getAnalyticsId, {
          age,
          gender: variables.gender,
          state: variables.state,
          diagnosed: variables.diagnosed,
        });
      }

      analytics.track('Account Created', {
        age,
        gender: values.gender,
        // city: variables.city,
        // zip: variables.zip,
        // departmentId: (data.signup.user || {}).departmentId,
        diagnosed: values.diagnosed,
        state: values.state,
      });

      let priceId = 'Paloma_Health_Membership_202786869790172_1000_20';
      // let freq = frequency === 'annualy' ? 'ma' : 'mm';

      const ft_membership_price_2024 = isToggleActive('MEMBERSHIP_PRICE_2024');
      if (ft_membership_price_2024) {
        priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL_2024;
      } else {
        priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL;
      }
      // if (frequency === 'annualy') {
      // } else {
      //   priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_MONTHLY;
      // }
      const dept = getRegionByAbbreviation(values.state);

      if (isToggleActive('MEMBERSHIP') && dept.level !== 'none' && !!variables.diagnosed) {
        history.push(`/become-member`);
      } else {
        history.push('/');
      }
    } catch (error) {
      setLoading(false);

      actions.setSubmitting(false);
      console.log(error);
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((error) => {
          toastManager.add(error.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
      }
    }
  };

  useEffect(
    () => {
      const page = location.pathname;

      if (!currentPage || currentPage !== page) {
        setCurrentPage(page);
        let tag = '';

        if (page.indexOf('basic') > -1) {
          tag = 'basic';
        } else if (page.indexOf('profile') > -1) {
          tag = 'profile';
        } else if (page.indexOf('extended') > -1) {
          tag = 'extended';
        }
        if (tag) {
          analytics.page(tag, '/completeProfileFromMobile');
        }
      }
    },
    [location]
  );

  if (loading) {
    return <LoadingScreen loading={true} />;
  }

  return (
    <Fragment>
      <Container flex={1} px={3}>
        <Flex flex={1}>
          <Header dark={false}>
            {location.pathname.indexOf('basic') > -1 && (
              <Text color="#486288" fontSize={3} fontWeight={500} textAlign="center">
                Hi {firstName || 'there'}! We need a little more information.
              </Text>
            )}
          </Header>
        </Flex>
        {!!isToggleActive('OTP_VERIFICATION') ? (
          <Wizard history={history} basename="/profile-completion" ref={wizard}>
            <Steps>
              <Step id="basic">
                <Basic onSubmit={handleSubmit} values={values} />
              </Step>
              <Step id="profile">
                <Profile onSubmit={handleSubmit} values={values} />
              </Step>
              <Step id="otp">
                <Otp
                  onSubmit={handleSubmit}
                  values={values}
                  changePhone={(phone) => {
                    setValues({ ...values, phone });
                  }}
                />
              </Step>
              <Step id="birthsex">
                <BirthSex onSubmit={handleSubmit} values={values} />
              </Step>
              <Step id="legalsex">
                <LegalSex onSubmit={handleSubmit} values={values} />
              </Step>
              <Step id="genderIdentity">
                <GenderIdentity onSubmit={handleSubmit} values={values} />
              </Step>
              <Step id="pronouns">
                <PreferredPronouns onSubmit={handleSubmit} values={values} />
              </Step>
              <Step id="extended">
                <Extended onSubmit={handleComplete} values={values} />
              </Step>
            </Steps>
          </Wizard>
        ) : (
          <Wizard history={history} basename="/profile-completion" ref={wizard}>
            <Steps>
              <Step id="basic">
                <Basic onSubmit={handleSubmit} values={values} />
              </Step>
              <Step id="profile">
                <Profile onSubmit={handleSubmit} values={values} />
              </Step>
              <Step id="birthsex">
                <BirthSex onSubmit={handleSubmit} values={values} />
              </Step>
              <Step id="legalsex">
                <LegalSex onSubmit={handleSubmit} values={values} />
              </Step>
              <Step id="genderIdentity">
                <GenderIdentity onSubmit={handleSubmit} values={values} />
              </Step>
              <Step id="pronouns">
                <PreferredPronouns onSubmit={handleSubmit} values={values} />
              </Step>
              <Step id="extended">
                <Extended onSubmit={handleComplete} values={values} />
              </Step>
            </Steps>
          </Wizard>
        )}
      </Container>
      <FullLayout2 />
    </Fragment>
  );
};

export default withSession(withRouter(withAnalytics(withToastManager(withApollo(CompleteProfile)))));
