import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Flex, Heading, Text } from 'rebass';
import Select from 'react-select';
import { insuranceCompaniesList } from '../../lists/insuranceCompaniesList';

const NoOptionMessage = ({ bypassInsurance, wizard }) => {
  return (
    <div style={{ padding: '12px 24px' }}>
      <Text textAlign="left" mb={2} fontSize="16px" fontWeight="400">
        Sorry, we don't recognize this insurance
      </Text>
      <Text textAlign="left" mb={2} fontSize="16px" fontWeight="400">
        1) Check your spelling
        <br />
        2) Check for abbreviations
        <br />
        3) Your insurance is not supported
      </Text>
      <Text textAlign="left" mb={2} fontSize="16px" fontWeight="400">
        Insurance is not required to see a doctor.
        <br />
        You can add your insurance details later.
      </Text>
      <Text
        textAlign="center"
        mb={1}
        fontSize="18px"
        style={{ cursor: 'pointer' }}
        onClick={() => bypassInsurance(wizard)}
      >
        <span style={{ color: '#364f79' }}>Continue</span>
        <span style={{ color: '#f9a394', fontWeight: '600' }}>{' >'}</span>
      </Text>
    </div>
  );
};

export const InsuranceListSelector = ({
  selectedProvider,
  bypassInsurance,
  wizard,
  onNonSupportedProviderSelected,
  onProviderSelected,
  onProviderSelectionChange,
  setOnFileInsurance,
  onFileInsuranceData,
  isParentValid,
}) => {
  return (
    <Card p={4} mb={5}>
      <Flex mb={4} flexDirection="column">
        <Flex
          flex={1}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Heading textAlign="center" mb={4}>
            Pick Your Insurance Provider
          </Heading>
          <Text textAlign="center" mb={4} fontSize="18px">
            Search for your insurance provider to see if you are covered
          </Text>
        </Flex>
        <div style={{ maxWidth: '500px', alignSelf: 'center', width: '100%' }}>
          <Select
            classNamePrefix="region-select"
            options={insuranceCompaniesList}
            placeholder="Search"
            isSearchable={false}
            value={selectedProvider}
            onChange={onProviderSelectionChange}
            noOptionsMessage={() => (
              <NoOptionMessage
                bypassInsurance={bypassInsurance}
                wizard={wizard}
              />
            )}
            theme={base => ({
              ...base,
              colors: {
                ...base.colors,
                primary: '#364f79',
                primary50: '#dae6fa',
              },
            })}
          />
        </div>
        <Text
          textAlign="left"
          mb={4}
          fontSize="14px"
          fontWeight={200}
          color="#7c7c7c"
          style={{ maxWidth: '500px', alignSelf: 'center', width: '100%' }}
        >
          e.g. Aetna, United Healthcare
        </Text>
        <Flex
          flex={1}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Button
            variant="pink"
            width={[1 / 2, 1 / 3]}
            type="submit"
            disabled={!selectedProvider || !isParentValid}
            onClick={() => {
              if (onFileInsuranceData && onFileInsuranceData.length > 0) {
                setOnFileInsurance(onFileInsuranceData[0], false);
              }
              if (selectedProvider.support !== 'full') {
                onNonSupportedProviderSelected(selectedProvider, wizard);
              } else {
                onProviderSelected(selectedProvider, wizard);
              }
            }}
          >
            Next
          </Button>
        </Flex>
        <div
          style={{
            height: '1px',
            backgroundColor: '#ccc',
            margin: '40px -32px',
          }}
        />
        <Flex
          flex={1}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          <Text
            textAlign="center"
            mb={1}
            fontSize="18px"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (onFileInsuranceData && onFileInsuranceData.length > 0) {
                setOnFileInsurance(onFileInsuranceData[0], false);
              }
              bypassInsurance(wizard);
            }}
          >
            <span style={{ color: '#364f79' }}>Skip Insurance</span>
            <span style={{ color: '#f9a394', fontWeight: '600' }}>{' >'}</span>
          </Text>
          <Text
            textAlign="center"
            fontSize="16px"
            fontWeight={200}
            color="#7c7c7c"
          >
            Insurance is not required to see a doctor.
            <br />
            You can add your insurance details later.
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

InsuranceListSelector.propTypes = {
  isParentValid: PropTypes.bool,
};

InsuranceListSelector.defaultProps = {
  isParentValid: true,
};
