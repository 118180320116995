import React, { Fragment } from 'react';
import { Card, Flex, Box, Heading, Text } from 'rebass';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import moment from 'moment';

import { Pill, InfoBubble } from '../../components/icons';
import { isMobile, isTablet } from 'react-device-detect';
import { GET_CLINICAL_PROVIDER } from '../../graphql';
import Communicator from '../../pages/consultations/Communicator';
import SpinLoader from '../../components/SpinLoader';
import fleche from '../../static/fleche.png';

const Icon = styled(Flex)`
  @media screen and (max-width: 480px) {
    display: none;
  }
`;
const Icon2 = styled(Flex)`
  @media screen and (max-width: 480px) {
    display: none;
  }
`;
const BackButton = styled.div`
  background: rgba(180, 205, 244, 0.5);
  width: 100%;
  color: #333;
  padding: 6px 12px;
  margin-bottom: 160px;
  margin-left: -12px;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const CommunicatorFlex = styled(Flex)`
  width: 100%;
  flex-direction: column;
  @media screen and (min-width: 52em) {
    width: calc(100% - 80px);
  }
`;

const CommunicatorChoiceDesktopOnly = styled.div`
  @media screen and (max-width: 52em) {
    display: none;
  }
  max-height: calc(100% - 80px);
`;

const CommunicatorChoiceMobileOnly = styled.div`
  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const Avatar = styled(Flex)`
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  margin-right: 6px;
  & > img {
    width: 40px;
  }
`;

const createMarkup = html => {
  return { __html: html };
};

const ConsultationPast = props => {
  const { session, prescription, summary, appointment, history, isAsync=false } = props;

  return (
    <Fragment>
      <Card p={[3, 3, 4]} mb={4}>
        <Flex>
          <Icon mr={[0, 4, 4]}>
            <InfoBubble width={50} />
          </Icon>
          <Flex flexDirection="column">
            <Heading mb={3}>Patient</Heading>
            <Text>
              {session.firstName} {session.lastName}
            </Text>
          </Flex>
        </Flex>
      </Card>

      {prescription && (
        <Card p={[3, 3, 4]} mb={4}>
          <Flex>
            <Icon mr={[0, 4, 4]}>
              <Pill width={50} />
            </Icon>
            <Flex flexDirection="column">
              <Heading mb={2}>Prescriptions</Heading>
              {prescription.map(element => (
                <Fragment key={element.prescriptionId}>
                  <Box mt={2} py={2}>
                    <Text fontWeight={500}>{element.description}</Text>
                    <Text>{element.instructions}</Text>
                    {element.patientNote && <Text>{element.patientNote}</Text>}
                    <Text fontSize={1}>
                      Prescribed {moment(element.rxDate).format('LL')}
                    </Text>
                  </Box>
                </Fragment>
              ))}
            </Flex>
          </Flex>
        </Card>
      )}

      {summary && (
        <Card p={[3, 3, 4]} mb={4}>
          <Flex>
            <Icon mr={[0, 4, 4]}>
              <InfoBubble width={50} />
            </Icon>

            <Flex flexDirection="column">
              <Heading mb={2}>Consultation Summary</Heading>

              <Box mt={2} py={2}>
                <Text fontWeight={500}>Patient Instructions</Text>

                {summary.instructions && (
                  <Box style={{wordBreak: 'break-word'}}
                    dangerouslySetInnerHTML={createMarkup(summary.instructions)}
                  />
                )}

                {!summary.instructions && <Text>None</Text>}
              </Box>

              <Box mt={2} py={2}>
                <Text fontWeight={500}>Patient Goals</Text>

                {summary.goals && (
                  <Box>
                    <Text style={{wordBreak: 'break-word'}}>{summary.goals}</Text>
                  </Box>
                )}

                {!summary.goals && <Text>None</Text>}
              </Box>
            </Flex>
          </Flex>
        </Card>
      )}
      {isMobile && !isTablet ? (
        <Card p={[3, 3, 4]} style={{ marginBottom: '64px' }}>
          <Flex>
            <Icon2 mr={[0, '12px', '12px']}>
              <img src={fleche} height={70} width={70} alt='' />
            </Icon2>

            <CommunicatorFlex id="t1">
              <Heading mb={4}>A question for your clinician?</Heading>
              <CommunicatorChoiceMobileOnly id="t2">
                <Query
                  query={GET_CLINICAL_PROVIDER}
                  variables={{
                    athenaProviderId: appointment.provider.id.toString(),
                  }}
                >
                  {({ loading, data, error }) => {
                    if (loading || (!data && !data.getClinicalProvider)) {
                      return (
                        <div
                          style={{
                            marginLeft: 'calc(50% - 25px)',
                            marginBottom: '12px',
                          }}
                        >
                          <SpinLoader />
                        </div>
                      );
                    }

                    if (error) {
                      return <div />;
                    }

                    return (
                      <div
                        id="t2"
                        style={{
                          border: '1px solid rgb(52, 79, 121)',
                          padding: '6px',
                          borderRadius: '4px',
                          boxShadow:
                            '6px 6px 5px 0px rgba(52, 79, 121, 0.35)',
                          marginBottom: '12px',
                        }}
                        onClick={() => {
                          history.push({
                            pathname: `/consultations-communicator/${
                              appointment.id
                            }`,
                            state: { isAsync },
                          });
                        }}
                        // onClick={() => {
                        //   this.props.history.push(
                        //     `/consultations-communicator/${appointment.id}`
                        //   );
                        // }}
                      >
                        <Flex flexDirection="row" id="t4">
                          <Avatar style={{ alignSelf: 'center' }}>
                            <img
                              alt=""
                              src={data.getClinicalProvider.picture}
                            />
                          </Avatar>
                          <Flex flexDirection="column" id="t5">
                            <Text fontWeight="500">
                              Chat with{' '}
                              {` ${
                                data.getClinicalProvider.patientFacingName
                              }`}
                            </Text>
                            <Text fontWeight="300" fontSize="14px">
                                {`usually reply within 2 business days`}
                            </Text>
                          </Flex>
                        </Flex>
                      </div>
                    );
                  }}
                </Query>
              </CommunicatorChoiceMobileOnly>
            </CommunicatorFlex>
          </Flex>
        </Card>
      ) : (
        <Card p={[3, 3, 4]} style={{ marginBottom: '64px' }}>
          <Flex>
            <Icon2 mr={[0, 4, 4]}>
              <img src={fleche} height={50} width={50} alt='' />
            </Icon2>

            <CommunicatorFlex id="t1" style={{maxHeight: '600px', position: 'relative'}}>
              <Heading mb={4}>A question for your clinician?</Heading>
              <CommunicatorChoiceDesktopOnly>
                <Communicator
                  appointmentId={appointment.id}
                  appointment={appointment}
                  prescription={prescription}
                  summary={summary}
                  isAsync={isAsync}
                />
              </CommunicatorChoiceDesktopOnly>
            </CommunicatorFlex>
          </Flex>
        </Card>
      )}
    </Fragment>
  );
};

export default ConsultationPast;
