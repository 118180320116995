import React, { Fragment } from 'react';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import { withWizard } from 'react-albus';
import { withApollo, compose } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';

import withSession from '../../../lib/withSession';
import { BoxButton } from '../../../components/async/SharedComponents';

const InsuranceDisclaimer = ({ onNext }) => {
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1, overflowY: 'scroll' }}>
        <Box m={2}>
          <Heading textAlign="left">Agreement for Self-Payment of Services</Heading>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Text
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            Last Updated on July 7, 2021
          </Text>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Text
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            The entities in the Medical Group (as defined in Paloma Health Terms of Use) (collectively, the “Group”) are committed to providing the best quality healthcare services. By agreeing to this form, you acknowledge that: 1) you do not have any health insurance through a PPO, HMO, Medicaid or Medicare or any other insurance plan; or 2) you have health insurance but you do not want to use any insurance benefit for these services, acknowledging that the service you requested is not likely to be covered by your insurance policy.
          </Text>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Text
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            Your insurance policy is a contract between you and your insurance company. It is your responsibility to know your benefits, and how they will apply to your benefit payments, and we take no responsibility to understand or be bound by the terms and conditions of such insurance. By agreeing to this form, you are electing to purchase services that may or may not be covered by your insurance if you obtained those services from a different provider. You have selected services for purchase from us on a self-pay basis. In other words, you have directed us to treat your purchase of these services as if you are an uninsured patient and you agree to be 100% responsible for full payment of the listed price of the services. There is no guarantee your insurance company will make any payment on the cost of the services you have purchased.
          </Text>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Text
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            The Group has provided you with the charges, in advance, for the services you have requested. You agree to pay these charges in full as a self-pay patient, electing not to use an insurance policy benefit. You have been given a choice of different services, along with their costs. You have selected the services and are willing to accept full financial responsibility for payment.
          </Text>
        </Box>
      </Flex>
      <BoxButton className="visible">
        <Button
          variant="primary"
          width={[1, 1 / 2, 1 / 4]}
          style={{
            marginLeft: '12px',
            marginRight: '12px',
          }}
          onClick={onNext}
        >
          Continue
        </Button>
      </BoxButton>
    </Fragment>
  );
};

export default compose(
  withSession,
  withApollo,
  withWizard,
  withToastManager
)(InsuranceDisclaimer);
