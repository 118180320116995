import React, { useState } from 'react';
import { Heading, Text, Flex, Card, Button, Box } from 'rebass';
import { Link, Redirect, withRouter } from 'react-router-dom';
import doctor from '../../static/Doctor_Female_White_shirt_2.png';
import discoverPossibleCauses from '../../static/Character_2.png';
import telemedicine from '../../static/telemedicine.png';
import { MembershipBulletItem } from '../../components/MembershipBulletItem';
import { isToggleActive } from '../../components/featureToggle/toggles';
import { Constants } from '../../utils/constants';

const NoServiceState = ({ patientState }) => {
  const [isRecorded, setIsRecorded] = useState(false);
  const [regionLevel, setRegionLevel] = useState();

  if (!patientState || !patientState.value) {
    return <Redirect to={'/book-appointment/pickProvider'} />;
  }
  // if (isRecorded && (regionLevel || '').toUpperCase() !== 'NONE') {
  //   return <Redirect to={{ pathname: '/buykit', state: { patientState } }} />;
  // }
  return (
    <Flex flexDirection="column">
      <Heading textAlign="center">
        Our providers are not yet in {patientState.label}
      </Heading>

      <Text textAlign="center" style={{ marginTop: '24px' }}>
        Explore our membership and join the Paloma community in the meantime
      </Text>

      <Flex flexDirection="row" style={{ marginTop: '32px' }}>
        <Card
          padding="14px"
          style={{
            position: 'relative',
            padding: '14px',
            margin: '12px',
            paddingBottom: '24px',
            // cursor: 'pointer',
            zIndex: 1,
          }}
        >
          {/* <Link
            style={{ flex: 1 }}
            to={{
              pathname: '/register',
              // search: "?sort=name",
              // hash: "#the-hash",
              state: { subscribe: true },
            }}
          > */}
          <>
            <Heading>Become a Member</Heading>
            <Text mt="8px" fontWeight="300">
            <span style={{fontWeight: 600}}>{`$${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}`}</span> billed annually
            </Text>
        <quadpay-widget-v3 amount={`${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}`} merchantId={window._env_.REACT_APP_QUADPAY_MERCHANTID} alignment="left" widgetVerbiage="4-easy-payments" />
{/* <zip-payment-widget amount= "192" merchantId={window._env_.REACT_APP_QUADPAY_MERCHANTID} > </zip-payment-widget> */}
            {/* <quadpay-widget-v3
              amount="192"
              alignment="left"
              widgetVerbiage="4-easy-payments"
            /> */}
            <Text mt="16px" fontWeight="300">
              The hypothyroid membership loaded with perks and discounts
            </Text>
            <Text
              style={{
                fontWeight: 500,
                fontSize: '16px',
                marginTop: '28px',
                marginBottom: '16px',
              }}
            >
              And get access to:
            </Text>
            <MembershipBulletItem
              style={{ width: '90%', marginBottom: '22px' }}
              text="Exclusive discounts on all our services and products"
            />
            <MembershipBulletItem
              style={{ width: '90%', marginBottom: '22px' }}
              text="Members-only resources and community"
            />
            <MembershipBulletItem
              style={{ width: '90%', marginBottom: '22px' }}
              text="Symptom tracker application"
            />

            <Link
              style={{ flex: 1 }}
              to={{
                pathname: '/register',
                // search: "?sort=name",
                // hash: "#the-hash",
                state: { subscribe: true, continueWith: '/' },
              }}
            >
              <Button
                style={{
                  width: '90%',
                  margin: '0 auto',
                  display: 'block',
                }}
                variant="primary"
              >
                Join Now
              </Button>
            </Link>
          </>
        </Card>
        <Card
          // onClick={() => {
          //   onContinue(wizard);
          // }}
          padding="14px"
          style={{
            position: 'relative',
            background: 'transparent',
            padding: '14px',
            margin: '12px',
            paddingBottom: '24px',
            // cursor: 'pointer',
            boxShadow: 'none',
          }}
        >
          <Text
            style={{
              fontWeight: 500,
              fontSize: '16px',
              marginBottom: '16px',
            }}
          >
            Also included in your membership:
          </Text>
          <MembershipBulletItem
            showBullet={false}
            text="•  The Paloma private community"
          />
          <MembershipBulletItem showBullet={false} text="•  Live doctor Q&A" />
          <MembershipBulletItem
            showBullet={false}
            text="• Online courses on thyroid health"
          />
          <MembershipBulletItem
            showBullet={false}
            text="• Monthly Speaker Series "
          />
          <MembershipBulletItem showBullet={false} text="• Cancel anytime" />

          <img
            src={discoverPossibleCauses}
            style={{
              position: 'absolute',
              bottom: '12px',
              left: '-76px',
              width: '270px',
            }}
          />
        </Card>
      </Flex>
      <Flex flexDirection="row" style={{ marginTop: '32px' }}>
        <Box style={{ flex: 1, textAlign: 'center' }}>
          <Link to="/">LEARN MORE</Link>
        </Box>
        <Box style={{ flex: 1 }} />
      </Flex>
    </Flex>
  );
};

export default withRouter(NoServiceState);
