import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Heading, Text } from 'rebass';
import withSession from '../../lib/withSession';
import UploadInsuranceCardNew from '../myInsurances/UploadInsuranceCardNew';
import { withRouter } from 'react-router';
import { sleep } from '../../utils/sleep';
import { Mutation, withApollo } from 'react-apollo';
import { ADD_NEW_INSURANCE, GET_HAS_INSURANCE_CARD_ON_DOCS, GET_INSURANCE_ON_FILE, INSURANCE_MEMBER_SAVE_TMP_INS_DATA, CURRENT_USER } from '../../graphql';
import PleaseWait from '../../components/PleaseWait';
import InsuranceInfo from '../myInsurances/InsuranceInfo';

const Wrapper = styled(Flex)`
  padding-left: ${(props) => (!!window && !!window.ReactNativeWebView ? '0' : '60px')};
  padding-top: 40px;
  flex: 1;
  border-radius: 8px 8px 8px 8px;
  flex-direction: column;
  width: 100%;

  max-width: ${(props) => (!!window && !!window.ReactNativeWebView ? 'calc(100vw - 24px)' : '700px')};
  margin: ${(props) => (!!window && !!window.ReactNativeWebView ? '12px' : '0')};
  padding-bottom: 80px;
  @media screen and (max-width: 480px) {
    max-width: calc(100vw - 24px);
    margin: 12px;
    padding-left: 0;
  }
`;

const SubscribedContinueInsurance = ({ history, match, location, client, ...rest }) => {
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedPlanType, setSelectedPlanType] = useState();
  const [isDependant, setIsDependant] = useState();
  const [insuranceInfo, setInsuranceInfo] = useState();
  const [isDetailsSubmitted, setIsDetailsSubmitted] = useState(false);

  const isPrimary = true;
  useEffect(() => {
    const f = match.params.f;
    const data = JSON.parse(decodeURI(f));
    // console.log({
    //   data,
    // });
    setSelectedProvider(data.savedInsuranceCard.selectedProvider);
    setSelectedPlanType(data.savedInsuranceCard.selectedPlanType);
    sendInitialInsurance(`${data.savedInsuranceCard.selectedProvider} - ${data.savedInsuranceCard.selectedPlanType}`);
    // console.log({ rest });
  }, []);

  const sendInitialInsurance = async (planType) => {
    await client.mutate({
      mutation: INSURANCE_MEMBER_SAVE_TMP_INS_DATA,
      variables: {
        planType,
      },
      refetchQueries: [{query: CURRENT_USER}]
    });
  };

  useEffect(
    () => {
      async function delay() {
        await sleep(300);
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      }
      if (!!isDependant || !!isDetailsSubmitted) {
        delay();
      }
    },
    [isDependant, isDetailsSubmitted]
  );

  return (
    <Mutation
      mutation={ADD_NEW_INSURANCE}
      refetchQueries={[{ query: GET_INSURANCE_ON_FILE }, { query: GET_HAS_INSURANCE_CARD_ON_DOCS }, {query: CURRENT_USER}]}
      awaitRefetchQueries
      onCompleted={() => {
        if (!!window && !!window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage('closeMobilePane');
          return;
        } else {
          const f = match.params.f;
          const data = JSON.parse(decodeURI(f));
          if (match.path.indexOf('insurance/vitamins') > -1) {
            history.push(`/subscribed/vitamins/${data.fForward}`);
          } else {
            history.push(`/subscribed/call/${data.fForward}`);
          }
        }
      }}
    >
      {(mutate, { loading }) => {
        if (!!loading) {
          return <PleaseWait style={{ position: 'absolute', left: '50%', top: '50%' }} />;
        }
        return (
          <Wrapper>
            <Heading mb={'48px'}>{`Insurance details`}</Heading>

            {!!selectedProvider &&
              (!!selectedPlanType || (selectedProvider.value && selectedProvider.value === 'Medicare')) && (
                <InsuranceInfo
                  isDependant={isDependant}
                  setIsDependant={(value) => {
                    setIsDependant(value);
                    if (!value) {
                      setIsDetailsSubmitted(true);
                    }
                  }}
                  submitInsuranceInfo={(info) => {
                    setInsuranceInfo(info);
                    setIsDetailsSubmitted(true);
                  }}
                  selectedProvider={selectedProvider}
                  initialValues={{}}
                  isDetailsSubmitted={isDetailsSubmitted}
                  unsubmitDetails={() => {
                    setIsDetailsSubmitted(false);
                  }}
                />
              )}
            {!!isDetailsSubmitted && (
              <UploadInsuranceCardNew
                onSubmit={async (front, back) => {
                  const text = `please add this patient's insurance in athena. The insurance information have been added to the patient's admin documents under the Insurance Card section`;
                  const attachments = [];
                  if (!!front) {
                    attachments.push({
                      ...front,
                      data: front.data.substr(front.data.indexOf(',') + 1),
                    });
                  }
                  if (!!back) {
                    attachments.push({
                      ...back,
                      data: back.data.substr(back.data.indexOf(',') + 1),
                    });
                  }

                  const vars = {
                    subscriberFullName: insuranceInfo ? `${insuranceInfo.subscriberFullName}` : null,
                    subscriberDob: insuranceInfo ? `${insuranceInfo.dob}` : null,
                    thread: {
                      text,
                      attachments,
                    },
                    isPrimary,
                    planType: `${selectedProvider} - ${selectedPlanType}`,
                  };

                  await mutate({ variables: vars });
                }}
              />
            )}
          </Wrapper>
        );
      }}
    </Mutation>
  );
};

export default withRouter(withSession(withApollo(SubscribedContinueInsurance)));
