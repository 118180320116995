import React from 'react';
import { Flex } from 'rebass';
import moment from 'moment';
import { ResponseBubbleStyled, FromStyled, MessageTimeStyled, MessageTextStyled, MessageAvatarStyled } from '../styled/bubbles';
import { attachmentThreadDisplay } from './AttachmentDisplay';

const UserBubble = ({ thread, key, mobileApp = false }) => {
  const isMobileAppEmbed = !!window && !!window.ReactNativeWebView;
  //   const interceptClickEvent=(e)=> {
  //     var href;
  //     var target = e.target || e.srcElement;
  //     console.log({e})
  //     if (target.tagName === 'A' || target.tagName === 'a') {
  //         href = target.getAttribute('href');
  //         //put your logic here...
  //         if (true) {

  //            //tell the browser not to respond to the link click
  //            e.preventDefault();
  //         }
  //     }
  // }
  const clickHandler = (e) => {
    // `target` is the element the click was on (the div we hooked or an element
    // with in it), `currentTarget` is the div we hooked the event on
    // if(!!isMobileAppEmbed){
    // console.log({e })
    // const el = e.target.closest("A");
    // if(!!el){
    // console.log({el })
    // if(el.href)
    // window.ReactNativeWebView.postMessage(`callDeepLink::${el.href}`);
    //   e.preventDefault()
    // }
    // }
  };
  return (
    <Flex
      justifyContent="flex-start"
      flexDirection="row"
      style={{
        maxWidth: '90%',
        marginBottom: '24px',
        paddingLeft: '6px',
      }}
    >
      {!!thread.createdBy.photoUrl && <MessageAvatarStyled photoUrl={thread.createdBy.photoUrl} />}

      <ResponseBubbleStyled className="conversationbubble" mobileApp={mobileApp}>
        <Flex flexDirection="row" style={{ marginBottom: '16px' }}>
          <FromStyled>
            {thread.createdBy.first} {thread.createdBy.last}
          </FromStyled>
          <MessageTimeStyled>{moment(thread.createdAt).fromNow()}</MessageTimeStyled>
        </Flex>

        {thread._embedded && thread._embedded.attachments ? (
          <>
            {thread._embedded.attachments.map((x, index) => {
              return attachmentThreadDisplay(x, `${key}_${index}`);
            })}
          </>
        ) : null}
        {thread.body && (
          <MessageTextStyled
            onClick={clickHandler}
            dangerouslySetInnerHTML={{
              __html: thread.body.replace(/<a/gi, `<a style="color: ${isMobileAppEmbed &&!mobileApp ? '#344F79' : 'white'}"`).replace(/<img/gi, '<img width="250px"'),
            }}
          />
        )}
      </ResponseBubbleStyled>
    </Flex>
  );
};

export { UserBubble };
