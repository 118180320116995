import React from 'react';
import { Flex, Box, Heading, Button } from 'rebass';
import { Query } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';
import { ADMIN_GET_MOBILE_COURSE_JOURNEYS } from '../../graphql';
import AddJourneyModalInner from './modals/AddJourneyModalInner';
import ConfirmModal from '../../components/ConfirmModal';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const journeyColumns = [
  {
    Header: 'title',
    accessor: 'title',
  },
  {
    Header: 'description',
    accessor: 'description',
  },
  {
    Header: 'Pro',
    accessor: 'isPro',
    Cell: row => {
      return <div>{row.original.isPro ? '✓' : ''}</div>;
    },
  },
  {
    Header: 'active',
    accessor: 'isActive',
    Cell: row => {
      return <div>{row.original.isActive ? '✓' : ''}</div>;
    },
  },
];

class JourneysList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddNewJourneyVisible: false,
    };
  }
  render() {
    const { isAddNewJourneyVisible } = this.state;
    return (
      <div>
        <Flex
          flexDirection="row"
          style={{ justifyContent: 'space-between' }}
          mt={4}
          mb={3}
        >
          <Heading fontSize="20px">Journeys</Heading>
          <Button
            style={{ background: '#344f79' }}
            onClick={() => {
              this.setState({ isAddNewJourneyVisible: true });
            }}
          >
            Add Journey
          </Button>
        </Flex>
        <Query query={ADMIN_GET_MOBILE_COURSE_JOURNEYS}>
          {({ data, loading, error }) => {
            if (loading || error) return <div />;
            console.log('GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES', data);
            return (
              <Box>
                <ReactTable
                  data={data.adminGetMobileCourseJourneys}
                  columns={journeyColumns}
                  defaultPageSize={
                    data.adminGetMobileCourseJourneys &&
                    data.adminGetMobileCourseJourneys.length > 3
                      ? Math.min(data.adminGetMobileCourseJourneys.length, 25)
                      : 3
                  }
                  showPagination={
                    data.adminGetMobileCourseJourneys &&
                    data.adminGetMobileCourseJourneys.length > 24
                  }
                />

                <ConfirmModal
                  childrenManaged
                  isOpen={isAddNewJourneyVisible}
                  confirmLabel="create"
                  onClose={() => {
                    this.setState({ isAddNewJourneyVisible: false });
                  }}
                  onBackgroundClick={() => {
                    this.setState({ isAddNewJourneyVisible: false });
                  }}
                  onEscapeKeydown={() => {
                    this.setState({ isAddNewJourneyVisible: false });
                  }}
                  onConfirm={() => {
                    this.setState({ isAddNewJourneyVisible: false });
                  }}
                >
                  <ModalInner>
                    <Heading fontSize="20px">Create Category</Heading>
                    <AddJourneyModalInner
                      onClose={() => {
                        this.setState({ isAddNewJourneyVisible: false });
                      }}
                      onSave={() => {
                        this.setState({ isAddNewJourneyVisible: false });
                      }}
                    />
                  </ModalInner>
                </ConfirmModal>
              </Box>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default JourneysList;
