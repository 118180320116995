import React from 'react';
import { Flex, Box, Text } from 'rebass';

const FoodTrackingSim = ({values}) => {
  return (
    <Box
      style={{
        backgroundColor: '#ccc',
        padding: '20px',
        width: '250px',
        height: '100%',
      }}
    >
      <Flex
        flexDirection="column"
        style={{
          minHeight: '400px',
          position: 'relative',
        }}
      >
        <Flex
          flex="1"
          style={{
            backgroundColor: values.negativeColor,
            padding: '6px',
          }}
        >
          <Text style={{ color: values.titleColor || '' }}>{values.title}</Text>
        </Flex>
        <Flex
          flex="1"
          style={{
            backgroundColor: values.positiveColor,
            alignItems: 'flex-end',
            padding: '6px',
          }}
        />
        <Box
          style={{
            position: 'absolute',
            right: 20,
            top: 40,
            bottom: 40,
            width: 6,
            backgroundColor: values.trackColor || 'white',
          }}
        />
        <Box
          style={{
            position: 'absolute',
            right: 13,
            borderRadius: 10,
            bottom: 150,
            width: 20,
            height: 40,
            backgroundColor: values.knobColor || 'white',
          }}
        />
      </Flex>
    </Box>
  );
};

export default FoodTrackingSim
