import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { Box, Button, Flex, Heading } from 'rebass';
import styled from 'styled-components';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { ADMIN_GET_THYROID_PROGRAMS } from '../graphql';
import ConfirmModal from '../components/ConfirmModal';
import AddPresetPlan from './adminPresetPlan/modals/AddPresetPlan';
import { withRouter } from 'react-router';
import DuplicateAipProgram from './adminPresetPlan/modals/DuplicateAipProgram';
import DuplicateProgram from './adminPresetPlan/modals/DuplicateProgram';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;
const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;

const AdminPresetPlans = ({ history }) => {
  const [isAddNewPlan, setIsAddNewPlan] = useState(false);
  const [isDuplicateAip, setIsDuplicateAip] = useState(false);
  const [isDuplicateProgram, setIsDuplicateProgram] = useState(false);
  const [list, setList] = useState(null);

  const presetPlanColumns = [
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'description',
      accessor: 'description',
    },
    {
      Header: 'custom assignment',
      accessor: 'assignedOnly',
      Cell: (row) => {
        return <div>{row.original.assignedOnly ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div>{row.original.isActive ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'is deleted',
      accessor: 'isArchived',
      Cell: (row) => {
        return <div>{row.original.isArchived ? '✓' : ''}</div>;
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div title="edit" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => history.push(`/admin-preset-plan/${row.original.id}`)}>
              ✎
            </div>
          </Flex>
        );
      },
    },
  ];
  return (
    <Container>
      <Flex flexDirection="row" style={{ justifyContent: 'space-between' }} mt={4} mb={3}>
        <Heading flex={1}>PRESET PLANS</Heading>
        {list &&
          list.length > 0 && (
            <Button
              style={{ background: '#819cc4' }}
              mr={3}
              onClick={() => {
                setIsDuplicateProgram(true);
              }}
            >
              Duplicate Program
            </Button>
          )}
        <Button
          style={{ background: '#819cc4' }}
          mr={3}
          onClick={() => {
            setIsDuplicateAip(true);
          }}
        >
          Duplicate AIP
        </Button>
        <Button
          style={{ background: '#344f79' }}
          onClick={() => {
            setIsAddNewPlan(true);
          }}
        >
          Add Plan
        </Button>
      </Flex>

      <Query
        query={ADMIN_GET_THYROID_PROGRAMS}
        onCompleted={(data) => {
          if (data && data.getAdminThyroidPrograms && data.getAdminThyroidPrograms.length > 0) {
            setList(data.getAdminThyroidPrograms);
          }
        }}
      >
        {({ data, loading, error }) => {
          if (loading || error) return <div />;
          return (
            <Box>
              <ReactTable
                data={data.getAdminThyroidPrograms}
                columns={presetPlanColumns}
                defaultPageSize={data.getAdminThyroidPrograms && data.getAdminThyroidPrograms.length > 3 ? Math.min(data.getAdminThyroidPrograms.length, 25) : 3}
                showPagination={data.getAdminThyroidPrograms && data.getAdminThyroidPrograms.length > 24}
              />
            </Box>
          );
        }}
      </Query>

      <ConfirmModal
        childrenManaged
        isOpen={isAddNewPlan}
        confirmLabel="create"
        onClose={() => {
          setIsAddNewPlan(false);
        }}
        onBackgroundClick={() => {
          setIsAddNewPlan(false);
        }}
        onEscapeKeydown={() => {
          setIsAddNewPlan(false);
        }}
        onConfirm={() => {
          setIsAddNewPlan(false);
        }}
      >
        <ModalInner>
          <Heading fontSize="20px" mb={'20px'}>
            Create Preset Plan
          </Heading>
          <AddPresetPlan
            onClose={() => {
              setIsAddNewPlan(false);
            }}
            onSave={() => {
              setIsAddNewPlan(false);
            }}
          />
        </ModalInner>
      </ConfirmModal>

      <ConfirmModal
        childrenManaged
        isOpen={isDuplicateAip}
        confirmLabel="create"
        onClose={() => {
          setIsDuplicateAip(false);
        }}
        onBackgroundClick={() => {
          setIsDuplicateAip(false);
        }}
        onEscapeKeydown={() => {
          setIsDuplicateAip(false);
        }}
        onConfirm={() => {
          setIsDuplicateAip(false);
        }}
      >
        <ModalInner>
          <Heading fontSize="20px" mb={'20px'}>
            Duplicate AIP Program
          </Heading>
          <DuplicateAipProgram
            onClose={() => {
              setIsDuplicateAip(false);
            }}
            onSave={() => {
              setIsDuplicateAip(false);
            }}
          />
        </ModalInner>
      </ConfirmModal>

      <ConfirmModal
        childrenManaged
        isOpen={isDuplicateProgram}
        confirmLabel="create"
        onClose={() => {
          setIsDuplicateProgram(false);
        }}
        onBackgroundClick={() => {
          setIsDuplicateProgram(false);
        }}
        onEscapeKeydown={() => {
          setIsDuplicateProgram(false);
        }}
        onConfirm={() => {
          setIsDuplicateProgram(false);
        }}
      >
        <ModalInner>
          <Heading fontSize="20px" mb={'20px'}>
            Duplicate AIP Program
          </Heading>
          <DuplicateProgram
            list={list}
            onClose={() => {
              setIsDuplicateProgram(false);
            }}
            onSave={() => {
              setIsDuplicateProgram(false);
            }}
          />
        </ModalInner>
      </ConfirmModal>
    </Container>
  );
};

export default withRouter(AdminPresetPlans);
