import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { Button, Flex, Heading, Text } from 'rebass';
import { Input } from '../../components/TextInput';
import { EligibilityUsersList } from './ModalViewEmployees';

const USERS_LIST = gql`
  query getAdminFindUserEligibility($search: String!) {
    getAdminFindUserEligibility(search: $search) {
      id
      employeeEmail
      employeeDoB
      employeeFirstName
      employeeLastName
      mustVerify
      employeeId
      verificationUID
      verificationSentDate
      verificationSent
      isActive
      activationDate
      needAdminApproval
      companyId
      user {
        id
        email
        phone
        firstName
        lastName
        intakeStatus
        athenaId
        accountLocked
        mustResetPassword
        mustCompleteProfile
        profileCompletionUid
        intakeDate
      }
    }
  }
`;

const ModalSearchUser = ({toastManager}) => {
  const [search, setSearch] = useState();
  const [tmpSearch, setTmpSearch] = useState();

  const handleSearch = () => {
    setSearch(tmpSearch);
  };

  if (!search) {
    return (
      <Flex flexDirection={'column'} justifyContent="center" alignItems={'center'} style={{ minWidth: '600px' }}>
        <Heading mb={4}>Search User</Heading>

        <Input
          placeholder="Athena ID or Email Address"
          name="search"
          type="search"
          id="input_search"
          value={tmpSearch}
          onChange={(v) => {
            setTmpSearch(v.target.value);
          }}
          style={{minWidth: '600px'}}
          onEnter={()=>{
            handleSearch()
          }}
        />

        <Button mt={3} variant={'primary'} onClick={handleSearch}>
          Search
        </Button>
      </Flex>
    );
  }

  return (
    <Query
      query={USERS_LIST}
      variables={{
        search,
      }}
    >
      {({ data, loading }) => {
        return (
          <Flex flexDirection={'column'}>
            <Flex>
              <Text mb={3}>Search Results for {search}</Text>
              <Text mb={3} ml={3} onClick={() => setSearch(null)}>
                X
              </Text>
            </Flex>
            <EligibilityUsersList data={data.getAdminFindUserEligibility} toastManager={toastManager}/>
          </Flex>
        );
      }}
    </Query>
  );
};

export default withToastManager(ModalSearchUser);
