import React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Box, Button, Card, Heading, Text } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../../components/FullLayout';
import Logo from '../../components/Logo';
import { CURRENT_USER, EMPTY_CALL } from '../../graphql';
import withAnalytics from '../../lib/withAnalytics';
import { useMount } from '../../utils/hooks/lifecycle';
import { isToggleActive } from '../../components/featureToggle/toggles';
import { Constants } from '../../utils/constants';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 40px auto;
  flex-direction: column;
  /* align-self: center; */
`;

const LogoContainer = styled(Box)`
  max-width: 200px;
  margin: 0 auto 32px auto;
`;

const SubscribedBook = ({ history, client, match, analytics }) => {
  const hasTrial =
    history &&
    history.location &&
    history.location.search &&
    history.location.search.indexOf('q=trial') > -1;
  useMount(async () => {
    analytics.track('membership sign up', {
      frequency: (match.params || {}).f,
      content_ids: (match.params || {}).f,
      value: !!hasTrial
        ? 0
        : (match.params || {}).f === 'mm'
          ? '20.00'
          : `${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}.00`,
      trial: hasTrial || false,
      trialLength: !!hasTrial ? window._env_.REACT_APP_MEMBERSHIP_TRIAL_LENGTH : '',
    });
    // analytics.logRevenue(
    //   (match.params || {}).f === 'mm' ? '20.00' : (match.params || {}).f === 'mi'?'96.00':'192.00',
    //   (match.params || {}).f === 'mm' ? '20.00' : (match.params || {}).f === 'mi'?'96.00':'192.00',
    //   0,
    //   '',
    //   [
    //     {
    //       productId: 'Membership',
    //       name: 'Membership',
    //       price: (match.params || {}).f === 'mm' ? '20.00' : (match.params || {}).f === 'mi'?'96.00':'192.00',
    //       quantity: 1,
    //       category: 'Membership',
    //       trial: hasTrial || false,
    //       trialLength: !!hasTrial ? window._env_.REACT_APP_MEMBERSHIP_TRIAL_LENGTH : '',
    //     }
    //   ],
    //   '',
    // )
    await client.mutate({
      mutation: EMPTY_CALL,
      refetchQueries: [{ query: CURRENT_USER }],
    });
  });

  return (
    <FullLayout2 style={{ zIndex: 10 }}>
      <Container>
        <LogoContainer>
          <Logo dark={false} />
        </LogoContainer>
        <Card
          style={{
            flexDirection: 'column',
            textAlign: 'center',
            padding: '32px',
          }}
        >
          <Heading>Welcome to Your Paloma Membership!</Heading>

          <Text
            textAlign="center"
            style={{ maxWidth: '900px', margin: '32px auto 0 auto' }}
          >
            Your account is now created and you can now book your visit
            and enjoy an exclusive member discount.
          </Text>

          <Button
            style={{ marginTop: '28px' }}
            variant="primary"
            onClick={() => {
              history.push('/schedule-split');
            }}
          >
            Book Visit
          </Button>
        </Card>
      </Container>
    </FullLayout2>
  );
};

export default withAnalytics(withRouter(withApollo(SubscribedBook)));
