import React, { Component } from 'react';
import PropTypes from 'prop-types';

import helpersClass from './helpers';
import { IconApple, IconGmail, IconIcs, IconOutlookCom, IconYahoo } from './helpers/icons';
import { Box } from 'rebass';
const helpers = new helpersClass();

export class ReactAddToCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsOpen: props.optionsOpen || false,
      isCrappyIE: false,
    };

    this.toggleCalendarDropdown = this.toggleCalendarDropdown.bind(this);
    this.handleDropdownLinkClick = this.handleDropdownLinkClick.bind(this);
  }

  componentWillMount() {
    // polyfill for startsWith to fix IE bug
    if (!String.prototype.startsWith) {
      String.prototype.startsWith = function(searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
      };
    }

    let isCrappyIE = false;
    if (typeof window !== 'undefined' && window.navigator.msSaveOrOpenBlob && window.Blob) {
      isCrappyIE = true;
    }

    this.setState({ isCrappyIE: isCrappyIE });
  }

  toggleCalendarDropdown() {
    let showOptions = !this.state.optionsOpen;

    if (showOptions) {
      document.addEventListener('click', this.toggleCalendarDropdown, false);
    } else {
      document.removeEventListener('click', this.toggleCalendarDropdown);
    }

    this.setState({ optionsOpen: showOptions });
  }

  handleDropdownLinkClick(e) {
    e.preventDefault();
    let url = e.currentTarget.getAttribute('href');

    if (!helpers.isMobile() && (url.startsWith('data') || url.startsWith('BEGIN'))) {
      let filename = 'download.ics';
      let blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

      if (this.state.isCrappyIE) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        /****************************************************************
        // many browsers do not properly support downloading data URIs
        // (even with "download" attribute in use) so this solution
        // ensures the event will download cross-browser
        ****************************************************************/
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        // if (window.ReactNativeWebView) {
        //   window.ReactNativeWebView.postMessage({type:'calendar', v:'this is a test'});
        // } else {
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        // }
      }
    } else {
      // if (window.ReactNativeWebView) {
      //   window.ReactNativeWebView.postMessage({type:'calendar', v:'this is a test'});
      // } else {
        window.open(url, '_blank');
      // }
    }

    this.toggleCalendarDropdown();
  }

  renderDropdown() {
    let self = this;

    let items = this.props.listItems.map((listItem) => {
      let currentItem = Object.keys(listItem)[0];
      let currentLabel = listItem[currentItem];

      let icon = null;
      if (self.props.displayItemIcons) {
        switch (currentItem) {
          case 'outlook':
          case 'outlookcom':
            icon = <IconOutlookCom />;
            break;
          case 'apple':
            icon = <IconApple />;
            break;
          case 'google':
            icon = <IconGmail />;
            break;
          case 'yahoo':
            icon = <IconYahoo />;
            break;
          case 'ICS':
            icon = <IconIcs />;
            break;

          default:
            break;
        }
        // icon = <i className={'fa fa-' + currentIcon} />;
      }

      return (
        <li key={helpers.getRandomKey()}>
          <a className={currentItem + '-link'} onClick={self.handleDropdownLinkClick} href={helpers.buildUrl(self.props.event, currentItem, self.state.isCrappyIE)} target="_blank">
            {icon}
            {currentLabel}
          </a>
        </li>
      );
    });

    return (
      <div className={this.props.dropdownClass}>
        <ul>{items}</ul>
      </div>
    );
  }

  renderButton() {
    let buttonLabel = this.props.buttonLabel;
    let buttonIcon = null;
    let template = Object.keys(this.props.buttonTemplate);

    if (template[0] !== 'textOnly') {
      const iconPlacement = this.props.buttonTemplate[template];
      const buttonClassPrefix = this.props.buttonIconClass === 'react-add-to-calendar__icon--' ? `${this.props.buttonIconClass}${iconPlacement}` : this.props.buttonIconClass;
      const iconPrefix = this.props.useFontAwesomeIcons ? 'fa fa-' : '';

      const mainButtonIconClass = template[0] === 'caret' ? (this.state.optionsOpen ? 'caret-up' : 'caret-down') : template[0];

      let buttonIconClass = `${buttonClassPrefix} ${iconPrefix}${mainButtonIconClass}`;

      buttonIcon = <i className={buttonIconClass} />;
      buttonLabel =
        iconPlacement === 'right' ? (
          <span>
            {buttonLabel + ' '}
            {buttonIcon}
          </span>
        ) : (
          <span>
            {buttonIcon}
            {' ' + buttonLabel}
          </span>
        );
    }

    let buttonClass = this.state.optionsOpen ? this.props.buttonClassClosed + ' ' + this.props.buttonClassOpen : this.props.buttonClassClosed;

    return (
      <div className={this.props.buttonWrapperClass}>
        <a className={buttonClass} style={{ display: 'flex', alignItems: 'center' }} onClick={this.toggleCalendarDropdown}>
          <svg width={'20px'} style={{ marginRight: '12px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200.016">
            <path d="M132.829 7.699c0-4.248 4.199-7.699 9.391-7.699s9.391 3.451 9.391 7.699v33.724c0 4.248-4.199 7.699-9.391 7.699s-9.391-3.451-9.391-7.699zm-5.941 123.747c2.979 0 5.404 2.425 5.404 5.404s-2.425 5.404-5.404 5.404l-21.077-.065-.065 21.045c0 2.979-2.425 5.404-5.404 5.404s-5.404-2.425-5.404-5.404l.065-21.061-21.045-.081c-2.979 0-5.404-2.425-5.404-5.404s2.425-5.404 5.404-5.404l21.061.065.065-21.045c0-2.979 2.425-5.404 5.404-5.404s5.404 2.425 5.404 5.404l-.065 21.077 21.061.065zM48.193 7.699C48.193 3.451 52.393 0 57.585 0s9.391 3.451 9.391 7.699v33.724c0 4.248-4.199 7.699-9.391 7.699s-9.391-3.451-9.391-7.699zM10.417 73.763h179.167V34.945c0-1.302-.537-2.49-1.4-3.369-.863-.863-2.051-1.4-3.369-1.4h-17.171c-2.881 0-5.208-2.327-5.208-5.208s2.327-5.208 5.208-5.208h17.171c4.183 0 7.975 1.709 10.726 4.46S200 30.762 200 34.945v44.043 105.843c0 4.183-1.709 7.975-4.46 10.726s-6.543 4.46-10.726 4.46H15.186c-4.183 0-7.975-1.709-10.726-4.46C1.709 192.79 0 188.997 0 184.814V78.988 34.945c0-4.183 1.709-7.975 4.46-10.726s6.543-4.46 10.726-4.46h18.343c2.881 0 5.208 2.327 5.208 5.208s-2.327 5.208-5.208 5.208H15.186c-1.302 0-2.49.537-3.369 1.4-.863.863-1.4 2.051-1.4 3.369zm179.167 10.433H10.417v100.618c0 1.302.537 2.49 1.4 3.369.863.863 2.051 1.4 3.369 1.4h169.629c1.302 0 2.49-.537 3.369-1.4.863-.863 1.4-2.051 1.4-3.369zM82.08 30.176c-2.881 0-5.208-2.327-5.208-5.208s2.327-5.208 5.208-5.208h34.977c2.881 0 5.208 2.327 5.208 5.208s-2.327 5.208-5.208 5.208z" />
          </svg>
          {buttonLabel}
        </a>
      </div>
    );
  }

  render() {
    let options = null;
    if (this.state.optionsOpen) {
      options = this.renderDropdown();
    }

    let addToCalendarBtn = null;
    if (this.props.event) {
      addToCalendarBtn = this.renderButton();
    }

    return (
      <>
        {!!this.state.optionsOpen && (
          <Box
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              animationName: 'fadeIn',
              animationDuration: '0.3s',
              height: '100vh',
              background: 'rgba(20 20 20 / 25%)',
              backdropFilter: 'blur(2px)',
              zIndex: 1,
              pointerEvents: 'none',
            }}
          />
        )}
        <div className={this.props.rootClass} style={{ zIndex: 2 }}>
          {addToCalendarBtn}
          {options}
        </div>
      </>
    );
  }
}

ReactAddToCalendar.displayName = 'Add To Calendar';

ReactAddToCalendar.propTypes = {
  buttonClassClosed: PropTypes.string,
  buttonClassOpen: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonTemplate: PropTypes.object,
  buttonIconClass: PropTypes.string,
  useFontAwesomeIcons: PropTypes.bool,
  buttonWrapperClass: PropTypes.string,
  displayItemIcons: PropTypes.bool,
  optionsOpen: PropTypes.bool,
  dropdownClass: PropTypes.string,
  event: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }).isRequired,
  listItems: PropTypes.arrayOf(PropTypes.object),
  rootClass: PropTypes.string,
};

ReactAddToCalendar.defaultProps = {
  buttonClassClosed: 'react-add-to-calendar__button',
  buttonClassOpen: 'react-add-to-calendar__button--light',
  buttonLabel: 'Add to Calendar',
  buttonTemplate: { caret: 'right' },
  buttonIconClass: 'react-add-to-calendar__icon--',
  useFontAwesomeIcons: true,
  buttonWrapperClass: 'react-add-to-calendar__wrapper',
  displayItemIcons: true,
  optionsOpen: false,
  dropdownClass: 'react-add-to-calendar__dropdown',
  event: {
    title: 'Sample Event',
    description: 'This is the sample event provided as an example only',
    location: 'Portland, OR',
    startTime: '2016-09-16T20:15:00-04:00',
    endTime: '2016-09-16T21:45:00-04:00',
  },
  listItems: [
    { apple: 'Apple Calendar' },
    { google: 'Google' },
    // { outlook: "Outlook" },
    { outlookcom: 'Outlook.com' },
    { yahoo: 'Yahoo' },
  ],
  rootClass: 'react-add-to-calendar',
};
