import React, { useState } from 'react';
import { PlateHeart } from '../../YearlyReview/icons/PlateHeart';
import { GlobalImprovements, GlobalImprovementsBackground } from '../pages/GlobalImprovements';
// import { CarouselCard } from '../Summary';
import { ThankYou, ThankYouBackground } from '../pages/ThankYou';
import { Query } from 'react-apollo';
import { GET_BASELINE_SURVEY_ANSWERS } from '../../../graphql';
import { GenericPageA, GenericPageABackground } from '../pages/GenericPageA';
import { GenericPageB, GenericPageBBackground } from '../pages/GenericPageB';
import { CarouselCard } from '../BaselineOutroSummary';
import { QUESTIONS } from '../Questions';
import { Completed, CompletedBackground } from '../pages/Completed';

const SECTION_BACKGROUND = '#FDF5F2';
const SECTION_NAME = '';

const calcAvg = (arr) => {
  console.log({ calcAvg: arr });
  if (!arr || !Array.isArray(arr) || arr.filter((x) => x !== null && !isNaN(x)).length < 1) return null;

  return arr.filter((x) => x !== null && !isNaN(x)).reduce((a, b) => a + b, 0) / arr.filter((x) => x !== null && !isNaN(x)).length;
};
const identifyLowests = (data) => {
  const answersParsed = JSON.parse(data.values);

  const questionnaire = QUESTIONS;

  const answer_satisfied_thyroid_care = questionnaire.find((x) => x.id === 'HowHappyLastProvider');
  const answer_cold_sensitivity = questionnaire.find((x) => x.id === 'SensitiveCold');
  const answer_Energy = questionnaire.find((x) => x.id === 'Energetic');
  const answer_Fatigue = questionnaire.find((x) => x.id === 'Tired');
  const answer_difficulty_remembering = questionnaire.find((x) => x.id === 'DifficultiesRemembering');
  const answer_slow_thinking = questionnaire.find((x) => x.id === 'SlowThinking');
  const answer_anxious = questionnaire.find((x) => x.id === 'AfraidAnxious');
  const answer_everything_takes_longer = questionnaire.find((x) => x.id === 'EverythingTakesLonger');
  const answer_difficulty_motivated = questionnaire.find((x) => x.id === 'DifficultiesMotivated');
  const answer_difficulty_managing_daily_life = questionnaire.find((x) => x.id === 'DifficultiesManagingDailyLife');
  const answer_dry_skin = questionnaire.find((x) => x.id === 'DrySkin');
  const answer_thyroid_negative_impact = questionnaire.find((x) => x.id === 'ThyroidNegativeImpactQualityOfLife');
  const answer_overall_wellbeing = questionnaire.find((x) => x.id === 'OverallWellBeing');
  
  const score_SatisfiedThyroidCare = answer_satisfied_thyroid_care.options.find(
    (x) => x.value === (answersParsed.find((x) => x.id === 'HowHappyLastProvider') || { selection: [{ value: null }] }).selection[0].value
  );
  const score_SensitiveCold = answer_cold_sensitivity.options.find((x) => x.value === (answersParsed.find((x) => x.id === 'SensitiveCold') || { selection: [{ value: null }] }).selection[0].value);
  const score_DrySkin = answer_dry_skin.options.find((x) => x.value === (answersParsed.find((x) => x.id === 'DrySkin') || { selection: [{ value: null }] }).selection[0].value);
  const score_Tired = answer_Fatigue.options.find((x) => x.value === (answersParsed.find((x) => x.id === 'Tired') || { selection: [{ value: null }] }).selection[0].value);
  const score_DifficultiesMotivated = answer_difficulty_motivated.options.find(
    (x) => x.value === (answersParsed.find((x) => x.id === 'DifficultiesMotivated') || { selection: [{ value: null }] }).selection[0].value
  );
  const score_Energetic = answer_Energy.options.find((x) => x.value === (answersParsed.find((x) => x.id === 'Energetic') || { selection: [{ value: null }] }).selection[0].value);
  const score_DifficultiesRemembering = answer_difficulty_remembering.options.find(
    (x) => x.value === (answersParsed.find((x) => x.id === 'DifficultiesRemembering') || { selection: [{ value: null }] }).selection[0].value
  );
  const score_SlowThinking = answer_slow_thinking.options.find((x) => x.value === (answersParsed.find((x) => x.id === 'SlowThinking') || { selection: [{ value: null }] }).selection[0].value);
  const score_AfraidAnxious = answer_anxious.options.find((x) => x.value === (answersParsed.find((x) => x.id === 'AfraidAnxious') || { selection: [{ value: null }] }).selection[0].value);
  const score_DifficultiesManagingDailyLife = answer_difficulty_managing_daily_life.options.find(
    (x) => x.value === (answersParsed.find((x) => x.id === 'DifficultiesManagingDailyLife') || { selection: [{ value: null }] }).selection[0].value
  );
  const score_EverythingTakesLonger = answer_everything_takes_longer.options.find(
    (x) => x.value === (answersParsed.find((x) => x.id === 'EverythingTakesLonger') || { selection: [{ value: null }] }).selection[0].value
  );
  const score_ThyroidNegativeImpactQualityOfLife = answer_thyroid_negative_impact.options.find(
    (x) => x.value === (answersParsed.find((x) => x.id === 'ThyroidNegativeImpactQualityOfLife') || { selection: [{ value: null }] }).selection[0].value
  );
  const score_OverallWellBeing = answer_overall_wellbeing.options.find(
    (x) => x.value === (answersParsed.find((x) => x.id === 'OverallWellBeing') || { selection: [{ value: null }] }).selection[0].value
  );

  const overallQoLvalues = [
    score_OverallWellBeing ? 4 - score_OverallWellBeing.score : null,
    score_ThyroidNegativeImpactQualityOfLife ? 4 - score_ThyroidNegativeImpactQualityOfLife.score : null,
  ].filter((x) => x !== null);
  const wellBeingvalues = [score_OverallWellBeing ? 4 - score_OverallWellBeing.score : null].filter((x) => x !== null);
  const satisfiedThyroidCarevalues = [score_SatisfiedThyroidCare ? 4 - score_SatisfiedThyroidCare.score : null].filter((x) => x !== null);
  const drySkinvalues = [score_DrySkin ? 4 - score_DrySkin.score : null].filter((x) => x !== null);
  const coldSensitivityvalues = [score_SensitiveCold ? 4 - score_SensitiveCold.score : null].filter((x) => x !== null);
  const tiredvalues = [score_Tired ? 4 - score_Tired.score : null].filter((x) => x !== null);
  const motivationvalues = [score_DifficultiesMotivated ? 4 - score_DifficultiesMotivated.score : null].filter((x) => x !== null);
  const energyvalues = [score_Energetic ? 4 - score_Energetic.score : null].filter((x) => x !== null);
  const difficultyManagevalues = [score_DifficultiesManagingDailyLife ? 4 - score_DifficultiesManagingDailyLife.score : null].filter((x) => x !== null);
  const takesLongervalues = [score_EverythingTakesLonger ? 4 - score_EverythingTakesLonger.score : null].filter((x) => x !== null);
  const negativeImpactvalues = [score_ThyroidNegativeImpactQualityOfLife ? 4 - score_ThyroidNegativeImpactQualityOfLife.score : null].filter((x) => x !== null);

  const res = [
    { category: 'overallQol', value: calcAvg(overallQoLvalues), title: 'Overall wellbeing', description: 'of patients significantly improved their wellbeing when using Paloma Health', count: 68 },
    { category: 'wellBeing', value: calcAvg(wellBeingvalues), title: 'Quality of life', description: 'of patients reported improvement in the quality of life when using Paloma Health', count: 92 },
    {
      category: 'satisfiedThyroidCare',
      value: calcAvg(satisfiedThyroidCarevalues),
      title: 'Thyroid care satisfaction',
      description: 'of patients reported increased satisfaction with thyroid care  (0 patients showed decrease, meaning that 13% of patients were already well satisfied)',
      count: 87,
    },
    { category: 'drySkin', value: calcAvg(drySkinvalues), title: 'Dry skin and/or brittle nails', description: 'of patients improved when using Paloma Health', count: 48 },
    { category: 'coldSensitivity', value: calcAvg(coldSensitivityvalues), title: 'Cold sensitivity', description: 'of patients improved when using Paloma Health ', count: 57 },
    { category: 'tired', value: calcAvg(tiredvalues), title: 'Feeling tired', description: 'of patients improved when using Paloma Health', count: 61 },
    { category: 'motivation', value: calcAvg(motivationvalues), title: 'Feeling motivated', description: 'of patients improved motivation when using Paloma Health', count: 65 },
    { category: 'energy', value: calcAvg(energyvalues), title: 'Energy', description: 'of patients improved when using Paloma Health', count: 56 },
    { category: 'difficultyManage', value: calcAvg(difficultyManagevalues), title: 'Difficult to manage daily life', description: 'of patients improved when using Paloma Health', count: 56 },
    { category: 'takesLonger', value: calcAvg(takesLongervalues), title: 'Everything takes longer to do', description: 'of patients improved when using Paloma Health', count: 58 },
    { category: 'negativeImpact', value: calcAvg(negativeImpactvalues), title: 'Thyroid has a negative impact to life', description: 'reduced negative impact when using Paloma Health', count: 68 },
  ];
  console.log({ res });

  const y = res.filter((x) => x.value !== null).sort((a, b) => {
    if (a.value >  b.value) return -1;
    if (a.value <  b.value) return 1;
    return 0;
  });
  console.log({ y });
  const lowests = [];
  for (let index = 0; index < Math.min(2, y.length); index++) {
    const element = y[index];
    lowests.push(element);
  }
  console.log(lowests);
  if (lowests.length < 2) {
    lowests.push({ category: 'wellBeing', value: -1, title: 'Quality of life', description: 'of patients reported improvement in the quality of life (default)', count: 92 });
  }
  if (lowests.length < 2) {
    lowests.push({
      category: 'satisfiedThyroidCare',
      value: -1,
      title: 'Thyroid care satisfaction',
      description: 'of patients reported increased satisfaction with thyroid care  (0 patients showed decrease, meaning that 13% of patients were already well satisfied)',
      count: 87,
    });
  }

  return lowests;
};
const getStories = (data, onRestart) => {
  console.log({ data });
  // const dta = {
  //   id: 'cleojt5wi0ahp0d608qqny1z0',
  //   status: 'complete',
  //   values:
  //     '[{"id":"OverallWellBeing","selection":[{"value":"OK"}]},{"id":"AgreeThyroidConditionsAssessesSymptoms","selection":[{"value":"I somewhat agree with the statement"}]},{"id":"PalomaExpectations","selection":[{"value":"To get vetted information on hypothyroidism"}]},{"id":"DoctorsVisitsPast12MonthsRelatedHealthConcerns","selection":[{"value":"3-5 times"}]},{"id":"TypeDoctorCurrentlyManagesCare","selection":[{"value":"Naturopath"}]},{"id":"ProviderVisitsPast12Months","selection":[{"value":"Twice"}]},{"id":"WhyVisitThyroidProvider","selection":[{"value":"To figure out my symptoms"}]},{"id":"HowManyThyroidMedsAdjustments","selection":[{"value":"Twice"}]},{"id":"HowHappyLastProvider","selection":[{"value":"Not satisfied, not unsatisfied"}]},{"id":"EmergencyMedicalHelp","selection":[{"value":"Yes, more than once"}]},{"id":"SensitiveCold","selection":[{"value":"Fully"}]},{"id":"SwollenHandsFeet","selection":[{"value":"Quite a lot"}]},{"id":"DrySkin","selection":[{"value":"A bit"}]},{"id":"Tired","selection":[{"value":"Somewhat"}]},{"id":"DifficultiesMotivated","selection":[{"value":"Quite a lot"}]},{"id":"Energetic","selection":[{"value":"Fully"}]},{"id":"DifficultiesRemembering","selection":[{"value":"Not at all"}]},{"id":"SlowThinking","selection":[{"value":"Quite a lot"}]},{"id":"AfraidAnxious","selection":[{"value":"Fully"}]},{"id":"DifficultiesManagingDailyLife","selection":[{"value":"Somewhat"}]},{"id":"EverythingTakesLonger","selection":[{"value":"Quite a lot"}]},{"id":"ThyroidNegativeImpactQualityOfLife","selection":[{"value":"A bit"}]}]',
  //   updatedAt: '2023-02-28T17:54:59.182Z',
  //   __typename: 'SurveyAnswersOutput',
  // };

  const lows = identifyLowests(data);

  return [
    {
      progressStyle: {
        foreground: '#A1C5FF',
      },
      backgroundColor: '#344F7A',
      duration: 5000,
      content: <ThankYou />,
      background: <ThankYouBackground />,
      show: true,
      analytics: {
        section: 'Baseline Outro Page',
        screen: 'Intro Page',
      },
    },
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#FDF5F2',
      duration: 5000,
      content: <GlobalImprovements />,
      background: <GlobalImprovementsBackground />,
      show: true,
      analytics: {
        section: 'Baseline Outro Page',
        screen: 'GlobalImprovements Page',
      },
    },
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#C7DDFC',
      duration: 5000,
      content: <GenericPageA title={lows[0].title} description={lows[0].description} score={lows[0].count} />,
      background: <GenericPageABackground />,
      show: true,
      analytics: {
        section: 'Baseline Outro Page',
        screen: 'GlobalImprovements Page',
      },
    },
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#C7DDFC',
      duration: 7000,
      content: <GenericPageB title={lows[1].title} description={lows[1].description} score={lows[1].count} />,
      background: <GenericPageBBackground />,
      show: true,
      analytics: {
        section: 'Baseline Outro Page',
        screen: 'GlobalImprovements Page',
      },
    },
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#C7DDFC',
      duration: 7000,
      content: <Completed onRestart={onRestart} />,
      background: <CompletedBackground />,
      show: true,
      analytics: {
        section: 'Baseline Outro Page',
        screen: 'Complete Page',
      },
    },
  ];
};

const SummarySectionInner = ({ isActive, setActive, data, active, onAllStoriesEnd, onClose, onRestart,restartCount }) => {
  const [stories, setStories] = useState(getStories(data, onRestart).filter((x) => !!x.show));

  return (
    <CarouselCard
      onClose={onClose}
      hideIndicators={false}
      sectionNameColor="white"
      stories={stories}
      sectionName={SECTION_NAME}
      section={{ inactiveIcon: <PlateHeart /> }}
      isActive={isActive}
      backgroundColor={SECTION_BACKGROUND}
      onAllStoriesEnd={onAllStoriesEnd}
      onRestart={onRestart}
      restartCount={restartCount}
    />
  );
};

export const SummarySectionSection = ({ isActive, setActive, active, onAllStoriesEnd, onClose, onRestart }) => {
  const [restartCount,setrestartCount] =useState(0)
  console.log('SummarySectionSection');
  const onReplay=()=>{
    setrestartCount(restartCount+1)
    onRestart()
  }
  // if (!isActive) return null;
  return (
    <Query query={GET_BASELINE_SURVEY_ANSWERS}>
      {({ data, loading, error }) => {
        if (loading) return null;
        console.log({ data });
        return <SummarySectionInner data={data.getBaselineSurveyAnswers} restartCount={restartCount} onRestart={onReplay} isActive={true} active={active} setActive={setActive} onAllStoriesEnd={onAllStoriesEnd} onClose={onClose} />;
      }}
    </Query>
  );
};
