import React, { useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { Mutation } from 'react-apollo';
import { Box } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../../../components/FullLayout';
import Logo from '../../../components/Logo';
import PleaseWait from '../../../components/PleaseWait';
import { CANCEL_SUBSCRIPTION, GET_MEMBERSHIPS } from '../../../graphql';
import SubscriptionCancelled from '../commonSteps/SubscriptionCancelled';
import Confirm from './stepsSubscriptions/Confirm';
import Offers from './stepsSubscriptions/Offers';
import Reason from './stepsSubscriptions/Reason';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
  padding-top: 64px;
`;

const LogoContainer = styled(Box)`
  max-width: 200px;
  margin: 0 auto 32px auto;
`;

export const CancelSubscription = ({ location, history }) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const subid = (location.state || {}).id;
  const subInterval = (location.state || {}).interval;
  const canPause = (location.state || {}).canPause;
  const type = (location.state || {}).type;

  return (
    <FullLayout2 style={{ zIndex: 10, overflow: 'auto' }}>
      <Container>
        <LogoContainer>
          <Logo dark={false} />
        </LogoContainer>
        <Mutation
          mutation={CANCEL_SUBSCRIPTION}
          refetchQueries={[{ query: GET_MEMBERSHIPS }]}
          awaitRefetchQueries
          variables={{
            subscriptionId: subid,
            isMembership: false,
          }}
          onCompleted={() => {
            setIsCompleted(true);
          }}
        >
          {(cancelSubscription, { loading }) => {
            return loading ? (
              <PleaseWait />
            ) : isCompleted ? (
              <SubscriptionCancelled title="Your Subscription Has Been Cancelled" />
            ) : (
              <Wizard
                basename="/cancel-subscription"
                render={({ step, ...p }, a) => {
                  return (
                    <Steps>
                      <Step
                        id="confirm"
                        render={({ next, push }) => (
                          <Confirm
                            goBack={() => {
                              history.push('/my-subscriptions');
                            }}
                            confirm={next}
                            type={type}
                            subscriptionId={subid}
                            canPause={canPause}
                          />
                        )}
                      />
                      <Step
                        id="reason"
                        render={({ next, previous }) => (
                          <Reason
                            goBack={previous}
                            viewOffers={next}
                            type={type}
                            confirm={() => cancelSubscription()}
                            hasOffers={subInterval !== 'year'}
                          />
                        )}
                      />
                      <Step
                        id="offers"
                        render={({ next, previous }) => (
                          <Offers
                            goBack={previous}
                            confirm={() => alert('cancel')}
                            subscriptionId={subid}
                            subscriptionType='vitamins'
                          />
                        )}
                      />
                    </Steps>
                  );
                }}
              />
            );
          }}
        </Mutation>
      </Container>
    </FullLayout2>
  );
};
