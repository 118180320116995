import React from 'react';
import profileAccount from '../../static/profileAccount.png'

import styled from 'styled-components';

const Img = styled.img`
  height: 40px;
  @media screen and (max-width: 480px) {
    height: 28px;
  }
`;
const Account = props => (
  <Img alt='' src={profileAccount} />
);

export default Account;
