import React from 'react';
import { isMobile } from 'react-device-detect';
import Div100vh from 'react-div-100vh';
import styled from 'styled-components';


const FullLayoutBg = styled(Div100vh)`
  position: absolute;
  flex: 1;
  height: 100%;
  right: 0;
  left: 0;
  background-color: #fdf5f2;
  z-index: -1;
`;

const FullLayout2 = props => {
  let stl = {
    position: 'absolute',
    left: '25%',
    top: 20,
    zIndex: -1,
  };
  if (isMobile) {
    stl = {
      position: 'absolute',
      right: '0%',
      top: 20,
      zIndex: -1,
    };
  }
  return (
    <FullLayoutBg style={props.style || {}}>
      {!props.hideBubbles && (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="301"
            height="267"
            viewBox="0 0 301 267"
            fill="none"
            style={stl}
          >
            <path
              opacity="0.2"
              d="M95.0519 4.44996C128.431 -2.16277 161.05 -1.6968 193.535 7.81462C203.168 10.7494 212.947 13.2015 222.832 15.1616C260.534 22.2078 281.092 47.314 292.076 80.7728C303.704 116.198 304.527 152.484 291.23 187.784C278.58 221.361 254.854 246.731 222.035 261.283C208.269 267.387 190.758 267.295 175.058 266.784C140.049 265.649 106.422 257.103 73.3461 245.538C41.32 234.333 22.3906 211.658 11.3691 181.774C0.131938 151.314 -2.23835 119.424 1.91043 87.4726C5.94234 56.4259 24.6755 35.0116 52.7386 21.6181C66.439 15.0746 80.923 10.1205 95.0519 4.44996Z"
              fill="#F8A294"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="281"
            height="248"
            viewBox="0 0 281 248"
            fill="none"
            style={{
              position: 'absolute',
              right: '25%',
              bottom: 20,
              zIndex: -1,
            }}
          >
            <path
              d="M265.431 196.828C253.829 211.058 239.345 220.629 223.855 228.61C186.528 247.844 147.212 252.419 106.193 243.885C97.8552 242.151 89.3359 241.235 80.8704 240.188C65.7323 238.313 51.7946 233.36 40.4223 223.012C27.4804 211.236 18.1991 196.73 11.9251 180.393C-0.331942 148.471 -3.92712 115.857 4.85161 82.4045C7.9594 70.5568 14.0488 60.1343 20.3427 49.7248C29.6929 34.2529 42.5203 22.2681 56.8414 11.7929C65.6961 5.31699 76.2666 2.76764 87.0077 1.6682C128.932 -2.61353 170.094 1.23101 210.167 14.5474C217.432 16.9619 225.175 19.8219 230.871 24.6487C239.238 31.9882 246.786 40.2169 253.383 49.1903C264.755 64.1699 270.968 81.6579 274.888 99.9347C279.073 119.456 282.517 139.107 280.313 159.198C278.766 173.256 272.98 185.723 265.431 196.828Z"
              fill="#E7F0FF"
              fillOpacity="0.5"
            />
          </svg>
        </>
      )}
      {props.children}
    </FullLayoutBg>
  );
};

export default FullLayout2;
