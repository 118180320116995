import React from 'react';
import { Flex, Box, Text, Button } from 'rebass';

const DefaultSim = ({ values, original, calculateY }) => {
  return (
    <Box
      style={{
        backgroundColor: '#ccc',
        padding: '20px',
        width: '250px',
        height: '100%',
      }}
    >
      <Flex
        flexDirection="column"
        style={{
          height: '400px',
          width: '100%',
          position: 'relative',
          backgroundColor: original.screensColor,
        }}
      >
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <Box
              style={{
                position: 'absolute',
                top: calculateY(values.hormoneY ? values.hormoneY.value : 0),
                left: '-200px',
                bottom: 0,
                right: 0,
                overflow: 'hidden',
              }}
            >
              <svg width="700" height="330" viewBox="0 0 85 75" fill="none">
                <path
                  d="M26.8419 1.24999C36.2679 -0.607521 45.4793 -0.476628 54.6527 2.19512C57.3731 3.01948 60.1344 3.70828 62.9261 4.25887C73.5727 6.23814 79.3782 13.2905 82.48 22.689C85.7637 32.64 85.996 42.8325 82.2409 52.7483C78.6686 62.18 71.9687 69.3065 62.701 73.3942C58.8135 75.1088 53.8687 75.0828 49.4349 74.9395C39.5486 74.6206 30.0529 72.2201 20.7124 68.9714C11.6685 65.8238 6.32293 59.4544 3.21056 51.0602C0.0372584 42.5041 -0.632091 33.5462 0.53949 24.5709C1.67807 15.85 6.96817 9.83472 14.893 6.07249C18.7618 4.23444 22.852 2.84285 26.8419 1.24999Z"
                  fill={original.hormoneColor}
                />
              </svg>
            </Box>
          </div>
        </Box>
        <Flex
          flex="1"
          style={{
            padding: '6px',
            textAlign: 'center',
            zIndex: 9,
            color: 'white',
            maxHeight: '400px',
            overflowY: 'auto',
          }}
        >
          {values.ScreenFormat &&
            values.ScreenFormat.label === 'Story' && (
              <Flex flexDirection="column">
                <Box flex={1} />
                <Box flex={1}>
                  <Flex flexDirection="column">
                    <Text
                      style={{
                        fontWeight: 500,
                        color: values.titleColor,
                      }}
                      mb={3}
                    >
                      {values.title}
                    </Text>
                    <Text
                      style={{
                        fontWeight: 300,
                        color: values.textColor,
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {values.Content}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            )}
          {values.ScreenFormat &&
            values.ScreenFormat.label === 'Audio' && (
              <Flex flexDirection="column" alignItems="center" justifyContent="center" width={'100%'}>
                <Box flex={1} />
                <Box flex={1}>
                  <Flex flexDirection="column" alignItems="center" justifyContent="center">
                    <Text
                      style={{
                        fontWeight: 500,
                        color: values.titleColor,
                      }}
                      mb={3}
                    >
                      {values.title}
                    </Text>
                    <Flex flexDirection="column" style={{ height: '241px' }} alignItems="center">
                      <svg width="81" height="92" viewBox="0 0 375 628" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M219.288 77.9127C274.005 92.9729 322.232 118.114 363.413 156.763C375.539 168.397 388.246 179.412 401.485 189.768C452.301 228.617 463.916 281.794 454.949 340.363C445.454 402.374 419.214 457.583 372.692 500.73C328.437 541.769 273.9 562.163 214.008 559.468C188.885 558.337 162.877 545.078 139.88 532.546C88.5993 504.608 44.9876 466.556 4.48036 424.373C-34.735 383.519 -45.7641 335.221 -39.5597 282.003C-33.2402 227.755 -12.6327 178 17.7307 133.036C47.2348 89.3465 91.343 71.164 143.275 72.0393C168.632 72.4596 193.953 75.8582 219.288 77.9127Z"
                          fill="#FEFCFB"
                        />
                      </svg>

                      <Text
                        style={{
                          fontWeight: 300,
                          color: 'white',
                          margin: '12px',
                        }}
                      >
                        00:15
                      </Text>
                      <Text
                        style={{
                          fontWeight: 300,
                          color: 'white',
                          padding: '2px 12px',
                          borderRadius: '24px',
                          borderColor: 'white',
                          border: '1px solid white',
                        }}
                      >
                        15:15
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            )}
          {values.ScreenFormat &&
            (values.ScreenFormat.label === 'Journal' || values.ScreenFormat.label === 'Food Journaling') && (
              <Flex flexDirection="column">
                <Box flex={1}>
                  <Flex flexDirection="column">
                    <Text
                      style={{
                        fontWeight: 500,
                        color: values.titleColor,
                      }}
                      mb={3}
                    >
                      {values.title}
                    </Text>
                    <Text
                      style={{
                        fontWeight: 300,
                        color: values.textColor,
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {values.Content}
                    </Text>
                  </Flex>
                </Box>
                <Box flex={1}>
                  <Box
                    style={{
                      borderBottom: `1px solid ${values.textColor}`,
                      height: '36px',
                      width: '100%',
                    }}
                  />
                </Box>
              </Flex>
            )}
          {values.ScreenFormat &&
            values.ScreenFormat.label === 'Quizz' && (
              <Flex flexDirection="column" style={{ width: '100%' }}>
                <Box flex={1}>
                  <Text
                    style={{
                      fontWeight: 500,
                      color: values.titleColor,
                    }}
                    mb={3}
                  >
                    {values.title}
                  </Text>
                  <Text
                    style={{
                      fontWeight: 300,
                      color: values.textColor,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {values.Content}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Flex flexDirection="column">
                    <Flex flexDirection="column" alignItems="center" justifyContent="center">
                      {values.buttons &&
                        values.buttons.map((x) => {
                          return (
                            <Button
                              variant={!x.variant ? 'primary' : x.variant.value === 'secondary' ? 'pink' : x.variant.value === 'white' ? 'outline' : 'primary'}
                              style={{
                                width: '70%',
                                margin: '0 auto',
                                marginBottom: '12px',
                                backgroundColor: x.variant && x.variant.value === 'white' ? 'white' : null,
                              }}
                            >
                              {x.title}
                            </Button>
                          );
                        })}
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            )}

          {values.ScreenFormat &&
            values.ScreenFormat.label === 'Options' && (
              <Flex flexDirection="column" style={{ width: '100%' }}>
                <Box flex={1}>
                  <Text
                    style={{
                      fontWeight: 500,
                      color: values.titleColor,
                    }}
                    mb={3}
                  >
                    {values.title}
                  </Text>
                  <Text
                    style={{
                      fontWeight: 300,
                      color: values.textColor,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {values.Content}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Flex flexDirection="column">
                    <Flex flexDirection="column" alignItems="center" justifyContent="center">
                      {values.options &&
                        values.options.map((x) => {
                          return (
                            <Button
                              variant={!x.variant ? 'primary' : x.variant.value === 'secondary' ? 'pink' : x.variant.value === 'white' ? 'outline' : 'primary'}
                              style={{
                                width: '70%',
                                margin: '0 auto',
                                marginBottom: '12px',
                                backgroundColor: x.variant && x.variant.value === 'white' ? 'white' : null,
                              }}
                            >
                              {x.title}
                            </Button>
                          );
                        })}

                      {values.buttons &&
                        values.buttons.map((x) => {
                          return (
                            <Button
                              variant={!x.variant ? 'primary' : x.variant.value === 'secondary' ? 'pink' : x.variant.value === 'white' ? 'outline' : 'primary'}
                              style={{
                                width: '70%',
                                margin: '0 auto',
                                marginBottom: '12px',
                                backgroundColor: x.variant && x.variant.value === 'white' ? 'white' : null,
                              }}
                            >
                              {x.title}
                            </Button>
                          );
                        })}
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default DefaultSim;
