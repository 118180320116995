import React from 'react';
import { Flex, Text } from 'rebass';
import { BigRightHormone, InnerLeftHormone, InnerRightHormone, MediumHormone, SmallHormone } from '../../YearlyReview/icons/InnerRightHormone';
import styled from 'styled-components';
import { Symptom } from '../../YearlyReview/pages/ImprovedSymptoms';
import { CDescription, CText } from '../../baselineSurvey/pages/Components/Texts';

export const ImprovedSymptomsBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <BigRightHormone
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          opacity: 0.1,
        }}
      />
      <MediumHormone style={{ position: 'absolute', top: 120, right: 150 }} />
      <InnerLeftHormone style={{ position: 'absolute', bottom: 120, left: 0 }} />
      <SmallHormone style={{ position: 'absolute', bottom: 200, right: 0 }} />
    </Flex>
  );
};

export const ImprovedSymptoms = ({ improvedSymptoms }) => {
  return (
    <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', zIndex: 100, flexDirection: 'column' }}>
      <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', maxWidth: '670px', flexDirection: 'column' }}>
        <CText style={{ fontWeight: 700, textAlign: 'center', color: 'white' }}>Your symptoms that improved the most</CText>

        <Flex mt={5}  style={{ flexDirection: 'row', width: '100%', alignItems:'center', justifyContent:'center' }}>
          {improvedSymptoms.map((x) => (
            <Symptom symptom={x} key={x.symptom} />
          ))}
        </Flex>
        <CDescription style={{color: 'white', textAlign: 'center', fontWeight: 300 }} mt={5}>
          This data will be shared with your provider and care manager so that we can suggest a personalized plan.
        </CDescription>
      </Flex>
    </Flex>
  );
};
