import { useEffect } from 'react';

/**
 * The function is called right after the component mount
 * @param {*} callback 
 */
export const useMount = (callback = () => {}) => {
  useEffect(() => {
    callback();
  }, []);
};