import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { PREVIEW_LIMIT, getWarnings } from './shared';

export const InnerMainTree = ({ tree, helpscoutId }) => {
  if (!tree) return null;
  return (
    <Flex flex={1}>
      <Droppable droppableId="characters">
        {(provided2, snapshot) => {
          return (
            <div ref={provided2.innerRef} {...provided2.droppableProps} id={'toto'} style={{ minHeight: '100%', width: '100%', padding: '12px', border: snapshot.isDraggingOver?'1px dashed rgb(134, 180, 134)': '1px solid #d7d7d7' }}>
              {!tree ||
                (tree.length < 1 && (
                  <Flex style={{ width: '100%', height: '100%', background:'#f7f7f7' }} justifyContent={'center'} alignItems={'center'} flex={1} id="rtt">
                    <Text fontWeight={500} fontSize={'20px'} style={{opacity: 0.3}}>
                      Drag here to add
                    </Text>
                  </Flex>
                ))}
              {tree.map((x, i) => {
                const errs = getWarnings(x, helpscoutId, tree);
                return (
                  <Draggable key={x.id} draggableId={x.id} index={i}>
                    {(provided, snapshot) => (
                      <div key={i} ref={provided.innerRef} isDragging={snapshot.isDragging} {...provided.dragHandleProps} {...provided.draggableProps}>
                        <Flex
                          flexDirection={'row'}
                          style={{
                            marginLeft: i === 0 ? '0' : `${i * 26}px`,
                          }}
                          mb={2}
                        >
                          {i === 0 ? (
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.525 18.0248C12.1917 18.2415 11.8543 18.2542 11.513 18.0628C11.1717 17.8715 11.0007 17.5755 11 17.1748V12.9998H3C2.71667 12.9998 2.479 12.9038 2.287 12.7118C2.095 12.5198 1.99934 12.2825 2 11.9998C2 11.7165 2.096 11.4788 2.288 11.2868C2.48 11.0948 2.71734 10.9992 3 10.9998H11V6.82485C11 6.42485 11.171 6.12885 11.513 5.93685C11.855 5.74485 12.1923 5.75751 12.525 5.97485L20.675 11.1498C20.9917 11.3498 21.15 11.6332 21.15 11.9998C21.15 12.3665 20.9917 12.6498 20.675 12.8498L12.525 18.0248Z"
                                fill="green"
                              />
                            </svg>
                          ) : (
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14 21L12.6 19.575L16.175 16H5V4H7V14H16.175L12.575 10.4L14 8.975L20 15L14 21Z" fill="#717171" />
                            </svg>
                          )}
                          <Box
                            style={{
                              marginLeft: `8px`,
                              background: 'white',
                              border: `1px solid ${!errs || errs.length < 1 ? '#717171' : 'orange'}`,
                              padding: '8px 12px',
                              borderRadius: '4px',
                            }}
                          >
                            <Flex flexDirection={'column'}>
                              <Text
                                fontWeight={500}
                                style={{ textDecoration: 'underline', cursor: 'pointer', color: '#07c' }}
                                onClick={() => {
                                  window.open(`https://secure.helpscout.net/conversation/${x.id}`, '_blank');
                                }}
                              >
                                #{x.id}
                              </Text>
                              <Text fontWeight={300}>Last user update: {(x.details || {}).lastUserUpdate}</Text>
                              <Text>{((x.details || {}).preview || '').length > PREVIEW_LIMIT ? (x.details || {}).preview.substring(0, PREVIEW_LIMIT) + '...' : (x.details || {}).preview}</Text>
                              {errs.map((er) => {
                                return (
                                  <Text fontWeight={300} color={'orange'}>
                                    - {er}
                                  </Text>
                                );
                              })}
                            </Flex>
                          </Box>
                        </Flex>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          );
        }}
      </Droppable>
    </Flex>
  );
};
