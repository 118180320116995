import React from 'react';
import { Flex, Text } from 'rebass';
import { GraphPink } from '../../YearlyReview/icons/GraphPink';
import { InnerRightHormone } from '../../YearlyReview/icons/InnerRightHormone';
import Odometer from '../../YearlyReview/components/RollingNumbers';
import styled from 'styled-components';
import { CDescription, CText } from '../../baselineSurvey/pages/Components/Texts';



export const RememberBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <InnerRightHormone
        style={{
          position: 'absolute',
          right: 0,
          bottom: 120,
        }}
      />
      <GraphPink width={700} style={{ position: 'absolute', top: 80, left: 0 }} />
    </Flex>
  );
};

export const Remember = () => {
  return (
    <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', zIndex: 100 }} flexDirection={'column'}>
      <CText style={{ fontWeight: 700, textAlign: 'center' }}>
        Remember the road to better <span style={{ color: '#F8A294' }}>thyroid health</span> is a long one.
      </CText>
      <CDescription mt={4} mb={4} fontWeight={300} textAlign={'center'}
        >
        It is completely normal to see some symptoms worsening and some symptoms improving.
      </CDescription>
      <CText style={{ fontWeight: 700, textAlign: 'center' }}>Let's celebrate all the small wins in this journey</CText>
    </Flex>
  );
};
