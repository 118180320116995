import React from 'react';
import { Mutation } from 'react-apollo';
import { Flex, Button } from 'rebass';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import FormField from '../../components/FormField';
import TextInput from '../../components/TextInput';
import { GET_ONE_TIME_COUPONS_LIST, UPDATE_ONE_TIME_COUPON } from '../../graphql';

const schema = Yup.object().shape({
  friendlyName: Yup.string().required('Required'),
  name: Yup.string()
    .trim()
    .required('Required'),
});
class OneTimeCouponRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: { ...props.toggle },
      isLoaded: true,
    };
  }
  render() {
    const { isLoaded, initialValues } = this.state;
    if (!isLoaded) return null;
    // console.log('initial vals', initialValues)
    return (
      <Mutation
        mutation={UPDATE_ONE_TIME_COUPON}
        refetchQueries={[{ query: GET_ONE_TIME_COUPONS_LIST }]}
      >
        {(mutate, { loading }) => (
          <Formik
            id={initialValues.name}
            //   isInitialValid={({ initialValues }) =>
            //     schema.isValidSync(initialValues)
            //   }
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              // console.log(values);
              await mutate({
                variables: {
                  ...values,
                },
              });
              this.setState({
                initialValues: { ...values },
              });
              resetForm()
            }}
            render={({ isValid, errors, touched, values }) => {
              // console.log('Diff', errors)
              return(
              <Form>
                <Flex flexDirection="row">
                  <FormField
                    width={1}
                    mb={3}
                    mr={[1]}
                    error={touched.firstNameFriend && errors.firstNameFriend}
                  >
                    <TextInput
                      placeholder="Note"
                      type="text"
                      name="friendlyName"
                    />
                  </FormField>
                  <FormField
                    width={1}
                    mb={3}
                    mr={[1]}
                    error={touched.firstNameFriend && errors.firstNameFriend}
                  >
                    <TextInput
                      disabled
                      placeholder="Stripe ID"
                      type="text"
                      name="name"
                    />
                  </FormField>
                  <FormField
                    width="250px"
                    flexDirection="row"
                    mb={3}
                    style={{
                      alignContent: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    error={touched.consent && errors.consent}
                  >
                    <TextInput
                      type="checkbox"
                      checked={values.isActive}
                      id={`isActive_${initialValues.name}`}
                      name="isActive"
                    />
                    {/* <CheckboxInput id={`isActive_${props.toggle.name}`} name="isActive" /> */}
                  </FormField>

                  <Flex justifyContent="center" width={0.5}>
                    {(values.name !== initialValues.name || values.isActive !== initialValues.isActive) && (
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={errors && errors.name}
                        id="btn_next"
                        width="100%"
                        style={{ height: '63px', minHeight: '63px' }}
                      >
                        Save
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </Form>
            )}}
          />
        )}
      </Mutation>
    );
  }
}

export default OneTimeCouponRow;
