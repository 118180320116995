const biomarkers = [
  {
    id: 'reverseT3',
    title: 'Reverse T3',
    sku: window._env_.REACT_APP_REVERSET3_SKU || 'sku_IVRcC8tY1oKamu',
    description:
      'Reverse T3 (rT3) is the inactive form of the thyroid hormone, triiodothyronine (T3). It is incapable of the metabolic activity normally carried out by T3. The clinical relevance of rT3 is unclear at this time. However, it may be a valuable marker if a person is undergoing extreme stress, trauma, surgery, or malnutrition.',
    price: 64,
    active: 'KIT_REVERSE_T3',
  },
  {
    id: 'vitaminD',
    title: 'Vitamin D',
    sku:window._env_.REACT_APP_VITD_SKU || 'sku_IVSGlPq8Tspd5r',
    description:
      'Recent evidence has demonstrated an association between low vitamin D status and autoimmune thyroid diseases such as Hashimoto’s thyroiditis and Graves’ disease, and impaired vitamin D signaling has been reported in thyroid cancers.',
    price: 53,
    active: 'KIT_VITAMIN_D',
  },
];


export const additionalBiomarkersHelper = {
    biomarkers
}