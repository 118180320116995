import React from 'react';
import { Text } from 'rebass';

const PractitionerTag = ({ tag, textStyle, containerStyle }) => {
  let bg = '#eeeeee';

  switch (tag.toLowerCase()) {
    case 'endocrinology':
      bg = '#fddce5';
      break;
    case 'Medication Management'.toLowerCase():
      bg = '#d0e0ff';
      break;
    case 'Symptoms alleviation'.toLowerCase():
      bg = '#d0f0fd';
      break;
    case 'Nutritional and plant-based endocrinologist'.toLowerCase():
      bg = '#c2f5e9';
      break;
    case 'Personalized health plans'.toLowerCase():
      bg = '#d1f7c4';
      break;
    case 'Weight management'.toLowerCase():
      bg = '#feeab6';
      break;
    case 'healthy lifestyle'.toLowerCase():
      bg = '#fde2d5';
      break;
    case 'Holistic approach'.toLowerCase():
      bg = '#fddbf6';
      break;
    case 'Integrative and Functional approach'.toLowerCase():
      bg = '#ede3fe';
      break;
    case 'Complex Thyroid disorders'.toLowerCase():
      bg = '#d0e0ff';
      break;

    default:
      break;
  }

  return (
    <div
      style={{
        borderRadius: '28px',
        padding: '2px 18px',
        marginRight: '8px',
        marginBottom: '12px',
        background: bg,
        ...containerStyle
      }}
    >
      <Text style={{...textStyle}}>{tag}</Text>
    </div>
  );
};

export default PractitionerTag;
