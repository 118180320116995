import React, { Fragment } from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import vitaminsBottle from '../../../static/vitaminsBottle.png';
import palomaKit from '../../../static/palomaKit.png';
import { withRouter } from 'react-router';
import yourLab from '../../../static/your_lab.png';
import { useMount } from '../../../utils/hooks/lifecycle';
import {
  ASYNC_SOFT_DISQUALIFY,
} from '../../../graphql';
import withSession from '../../../lib/withSession';
import { AddonCard } from './Disqualified';

const DisqualifiedPregnant = ({
  onLearnMore,
  session: { isMember },
  history,
  client,
}) => {
  useMount(async () => {
    if (client) {
      await client.mutate({
        mutation: ASYNC_SOFT_DISQUALIFY,
        variables: {
          reason: 'PREGNANT',
        },
      });
    }
  });
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }} pb="60px">
        <img
          src={yourLab}
          alt=""
          width="140px"
          style={{ alignSelf: 'center' }}
        />
        <Box m={2} mt={4} mb={3}>
          <Heading textAlign="left">We are very sorry</Heading>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Text
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            We're sorry to share that we cannot treat you through an online
            visit. Paloma Health is not yet fully operational in your state. For
            now, we can only do simple refills of existing prescriptions. More
            comprehensive thyroid care is coming soon to you, we promise!
          </Text>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Heading textAlign="left">How we can help</Heading>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Text
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            We can still support you with some of our best sellers:
          </Text>
        </Box>
        <Flex flexDirection="column">
          <AddonCard
            title="Daily Thyroid Care"
            description="Vitamin Support to support thyroid health"
            price={!isMember ? '$34/month' : '$27/month'}
            discounted={!isMember ? '' : ' ($7 member discount applied)'}
            image={vitaminsBottle}
            onClick={() => {
              history.push('/order-vitamins');
            }}
            showLearnMore
          />
          <AddonCard
            title="At-Home Thyroid Test Kit"
            description="Test your TSH, free T4, Free T3 and TPO antibodies from the comfort of your own home"
            price={!isMember ? '$99' : '$75'}
            discounted={!isMember ? '' : ' ($24 member discount applied)'}
            image={palomaKit}
            onClick={() => {
              // history.push('/purchase');
              window.location = 'https://palomahealth.com/kit';
            }}
            showLearnMore
          />
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default compose(
  withRouter,
  withSession,
  withApollo,
  withWizard,
  withToastManager
)(DisqualifiedPregnant);
