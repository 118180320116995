export const getDefaultValueSingle = (defaultValue) => {
  if (defaultValue && defaultValue.selection && defaultValue.selection.length > 0 && defaultValue.selection[0].value) {
    return defaultValue.selection[0].value;
  }
  return null;
};

export const getDefaultValueMultiple = (defaultValue) => {
  if (defaultValue && defaultValue.selection && defaultValue.selection.length > 0) {
    return defaultValue.selection.map((x) => x.value);
  }
  return [];
};

export const getDefaultValueMultipleDetails = (defaultValue) => {
  try {
    if (defaultValue && defaultValue.selection && defaultValue.selection.length > 0) {
      const details = defaultValue.selection.filter((x) => !!x && !!x.details);
      if (!!details) {
        return details[0].details;
      }
      return '';
    }
  } catch (error) {}
  return '';
};

export const getDefaultValueFreeText = (defaultValue) => {
  try {
    if (defaultValue && defaultValue.selection && defaultValue.selection.length > 0) {
      const details = defaultValue.selection.filter((x) => !!x && !!x.details);
      if (!!details) {
        return details[0].details;
      }
      return '';
    }
  } catch (error) {}
  return '';
};

export const getDefaultShowDetails = (defaultValue, options) => {
  if (defaultValue && defaultValue.selection && defaultValue.selection.length > 0) {
    for (let index = 0; index < defaultValue.selection.length; index++) {
      const element = defaultValue.selection[index];
      if ((options.find((x) => x.value === (element.value || '')) || {}).hasDetails) {
        return true;
      }
    }

    return false;
  }
  return false;
};
