import moment from 'moment';
import { PROPERTIES } from '../pages/adminBannerFilterBuilder/FilterEdit';

const string = (userPropertyValue, value, filter) => {
  switch (filter) {
    case '!':
      return !userPropertyValue;
    case '!!':
      return !!userPropertyValue;
    case '!%': //doesn't contains
      return (userPropertyValue || '').toLowerCase().indexOf(value.toLowerCase()) < 0;
    case '!%s':
      return (userPropertyValue || '').toLowerCase().indexOf(value.toLowerCase()) !== 0;
    case '!=':
      return (userPropertyValue || '').toLowerCase() !== value.toLowerCase();
    case '!in':
      const sp = value.split(';');
      let found = false;
      for (let index = 0; index < sp.length; index++) {
        const element = sp[index];
        if (element.toLowerCase() === (userPropertyValue || '').toLowerCase()) {
          found = true;
          break;
        }
      }
      return !found;
    case '!s%':
      const startIndex = (userPropertyValue || '').length - value.length;
      let doesEnd = (userPropertyValue || '').substring(startIndex, (userPropertyValue || '').length).toLowerCase() === value.toLowerCase();
      return !doesEnd;
    case '%':
      return (userPropertyValue || '').toLowerCase().indexOf(value.toLowerCase()) > -1;
    case '%s':
      return (userPropertyValue || '').toLowerCase().indexOf(value.toLowerCase()) === 0;
    case '=':
      return (userPropertyValue || '').toLowerCase() === value.toLowerCase();
    case 'in':
      const sp2 = value.split(';');
      let found2 = false;
      for (let index = 0; index < sp2.length; index++) {
        const element = sp2[index];
        if (element.toLowerCase() === (userPropertyValue || '').toLowerCase()) {
          found2 = true;
          break;
        }
      }
      return !!found2;
    case 's%':
      const startIndex2 = (userPropertyValue || '').length - value.length;
      let doesEnd2 = (userPropertyValue || '').substring(startIndex2, (userPropertyValue || '').length).toLowerCase() === value.toLowerCase();
      return doesEnd2;
    default:
      return false;
  }
};

function testStringFunction() {
  const testCases = [
    // Case for '!'
    { userPropertyValue: null, value: '', filter: '!', expectedTrue: true, expectedFalse: false },
    { userPropertyValue: 'null', value: '', filter: '!', expectedTrue: false, expectedFalse: true },
    // Case for '!!'
    { userPropertyValue: 'hello', value: '', filter: '!!', expectedTrue: true, expectedFalse: false },
    { userPropertyValue: null, value: '', filter: '!!', expectedTrue: false, expectedFalse: true },
    // Case for '!%'
    { userPropertyValue: 'Hello, world!%', value: 'world', filter: '!%', expectedTrue: false, expectedFalse: true },
    { userPropertyValue: 'Hello, world!%', value: 'WORLd', filter: '!%', expectedTrue: false, expectedFalse: true },
    { userPropertyValue: 'Hello, world!%', value: 'toto', filter: '!%', expectedTrue: true, expectedFalse: false },
    // Case for '!%s'
    { userPropertyValue: 'Hello, world!%s', value: 'world', filter: '!%s', expectedTrue: true, expectedFalse: false },
    { userPropertyValue: 'Hello, world!%s', value: 'hello', filter: '!%s', expectedTrue: false, expectedFalse: true },
    // Case for '!='
    { userPropertyValue: 'HELLO', value: 'lol', filter: '!=', expectedTrue: true, expectedFalse: false },
    { userPropertyValue: 'HELLO', value: 'hello', filter: '!=', expectedTrue: false, expectedFalse: true },
    // Case for '!in'
    { userPropertyValue: 'orang', value: 'apples;oranges;bananas', filter: '!in', expectedTrue: true, expectedFalse: false },
    { userPropertyValue: 'oranges', value: 'apples;oranges;bananas', filter: '!in', expectedTrue: false, expectedFalse: true },
    // Case for '!s%'
    { userPropertyValue: 'Hello, world!', value: 'world', filter: '!s%', expectedTrue: true, expectedFalse: false },
    { userPropertyValue: 'Hello, world!', value: 'world!', filter: '!s%', expectedTrue: false, expectedFalse: true },
    // Case for '%'
    { userPropertyValue: 'Hello, world!', value: 'world', filter: '%', expectedTrue: true, expectedFalse: false },
    { userPropertyValue: 'Hello, world!', value: 'toto', filter: '%', expectedTrue: false, expectedFalse: true },
    // Case for '%s'
    { userPropertyValue: 'Hello, world!', value: 'hello', filter: '%s', expectedTrue: true, expectedFalse: false },
    { userPropertyValue: 'Hello, world!', value: 'ello', filter: '%s', expectedTrue: false, expectedFalse: true },
    // Case for '='
    { userPropertyValue: 'HELLO', value: 'hello', filter: '=', expectedTrue: true, expectedFalse: false },
    { userPropertyValue: 'HELLO', value: 'hallo', filter: '=', expectedTrue: false, expectedFalse: true },
    // Case for 'in'
    { userPropertyValue: 'oranges', value: 'apples;oranges;bananas', filter: 'in', expectedTrue: true, expectedFalse: false },
    { userPropertyValue: 'orang', value: 'apples;oranges;bananas', filter: 'in', expectedTrue: false, expectedFalse: true },
    // Case for 's%'
    { userPropertyValue: 'Hello, world!', value: 'world!', filter: 's%', expectedTrue: true, expectedFalse: false },
    { userPropertyValue: 'Hello, world!', value: 'world', filter: 's%', expectedTrue: false, expectedFalse: true },
    // Invalid case
    { userPropertyValue: 'Hello, world!', value: '', filter: 'invalid', expectedTrue: false, expectedFalse: true },
  ];

  // Iterate through test cases
  testCases.forEach((testCase, index) => {
    const resultTrue = string(testCase.userPropertyValue, testCase.value, testCase.filter);
    const resultFalse = string(testCase.userPropertyValue, testCase.value, testCase.filter) === false; // ensure it's exactly false
    // if (testCase.expectedTrue !== resultTrue || testCase.expectedFalse !== resultFalse) {
    console.log(`Test Case ${index + 1}:`);
    console.log(`userPropertyValue: ${testCase.userPropertyValue}`);
    console.log(`value: ${testCase.value}`);
    console.log(`filter: ${testCase.filter}`);
    console.log(`${testCase.expectedTrue === resultTrue ? '✅' : '🛑'}Expected True: ${testCase.expectedTrue}, Result True: ${resultTrue}`);
    console.log(`${testCase.expectedFalse === resultFalse ? '✅' : '🛑'}Expected False: ${testCase.expectedFalse}, Result False: ${resultFalse}`);
    console.log('-----------------------------');
    // }
  });
}

export const TestsMatchQueryFilters = () => {
  testStringFunction();
};
const number = (userPropertyValue, value, filter) => {
  switch (filter) {
    case '=':
      return userPropertyValue === value;
    case '!=':
      return userPropertyValue !== value;
    case '<':
      return userPropertyValue < value;
    case '<=':
      return userPropertyValue <= value;
    case '>=':
      return userPropertyValue >= value;
    case '>':
      return userPropertyValue > value;
    default:
      break;
  }
};
const boolean = (userPropertyValue, value, filter) => {
  let boolValue = filter === 'true';
  if (!!boolValue) {
    let res = !!userPropertyValue;

    return res;
  } else {
    let res = !userPropertyValue;

    return res;
  }
};
const date = (userPropertyValue, value, filter) => {
  try {
    const dt = moment(userPropertyValue);
    if (!dt.isValid()) {
      return false;
    }

    switch (filter) {
      case 'lastN':
        const val = value;
        if (!val || !val.value || !val.unit) return false;
        let vNum = parseInt(val.value);
        if (isNaN(vNum)) return false;

        return dt.clone().isSameOrAfter(moment().add(vNum * -1, val.unit)) && dt.clone().isBefore(moment(), val.unit);
      case 'atLeastN':
        const val2 = value;
        if (!val2 || !val2.value || !val2.unit) return false;
        let vNum2 = parseInt(val2.value);
        if (isNaN(vNum2)) return false;

        return dt.clone().isSameOrAfter(moment().add(vNum2 * -1, val2.unit));
      case 'between':
        return false;
      case 'inNextN':
        const val3 = value;
        if (!val3 || !val3.value || !val3.unit) return false;
        let vNum3 = parseInt(val3.value);
        if (isNaN(vNum3)) return false;

        return dt.clone().isSameOrBefore(moment().add(vNum3, val3.unit)) && dt.clone().isSameOrAfter(moment(), val3.unit);
      case 'before':
        return dt.clone().isBefore(moment(value), 'day');
      case 'after':
        return dt.clone().isAfter(moment(value), 'day');
      case 'today':
        return dt.clone().isSame(moment(), 'day');
      case 'dayInLastN':
        const val4 = value;
        if (!val4 || !val4.value || !val4.unit) return false;
        let vNum4 = parseInt(val4.value);
        if (isNaN(vNum4)) return false;

        return dt.clone().isSameOrAfter(moment().add(vNum4 * -1, val4.unit), 'day') && dt.clone().isBefore(moment(), 'day');
      case 'dayInNextN':
        const val5 = value;
        if (!val5 || !val5.value || !val5.unit) return false;
        let vNum5 = parseInt(val5.value);
        if (isNaN(vNum5)) return false;

        return dt.clone().isSameOrBefore(moment().add(vNum5 * -1, val5.unit), 'day') && dt.clone().isAfter(moment(), 'day');
      case 'dayIsInMonth':
        return dt.clone().isSame(moment(), 'month');
      case 'dayIsInMonthN':
        const val6 = value;
        const month = dt.clone().month();

        return month + 1 == val6.unit;
      default:
        break;
    }
  } catch (error) {}
};

function matchQuery(query, user) {
  if (query.datatype === 'grouping') {
    const operator = query.operator || query.data.operator;
    if (!operator) {
      const content = query.data && query.data.content ? query.data.content : [];
      for (let i = 0; i < content.length; i++) {
        if (matchQuery(content[i], user)) {
          return true;
        }
      }
      return false;
    } else {
      if (operator === 'AND') {
        const content = query.data && query.data.content ? query.data.content : [];
        for (let i = 0; i < content.length; i++) {
          if (!matchQuery(content[i], user)) {
            return false;
          }
        }
        return true;
      } else if (operator === 'OR') {
        const content = query.data && query.data.content ? query.data.content : [];
        for (let i = 0; i < content.length; i++) {
          const res = matchQuery(content[i], user);
          
          if (res) {
            return true;
          }
        }
        return false;
      }
    }
  } else if (query.datatype === 'content') {
    const propertyLabel = query.property && query.property.label;
    const propertyValue = query.property && query.property.value;
    const originalProperty = PROPERTIES.find((x) => x.value === propertyValue);

    if (propertyLabel && propertyValue) {
      const sanitizedPropertyValue = propertyValue
        .replace('db.', '')
        .replace('athena.', '')
        .replace('stripe.', '');

      const userPropertyValue = user[sanitizedPropertyValue];

      switch (originalProperty.inputType) {
        case 'string':
          const res = string(userPropertyValue, query.value, query.filter);
          return res;
        case 'number':
          const res2 = number(userPropertyValue, query.value, query.filter);
          return res2;
        case 'date':
          const res3 = date(userPropertyValue, query.value, query.filter);
          return res3;
        case 'boolean':
          const res4 = boolean(userPropertyValue, query.value, query.filter);
          return res4;
        default:
          break;
      }

      if (query.filter === '=') {
        let res = userPropertyValue === query.value;
        return res;
      } else if (query.filter === 'true' || query.filter === 'false') {
        let boolValue = query.filter === 'true';
        if (!!boolValue) {
          let res = !!userPropertyValue;
          return res;
        } else {
          let res = !userPropertyValue;
          return res;
        }
      } else if (query.filter === 'atLeastN') {
        const value = query.value;
        if (value && value.unit === 'hours' && userPropertyValue instanceof Date) {
          const currentTime = new Date();
          const diffMilliseconds = currentTime.getTime() - userPropertyValue.getTime();
          const diffHours = diffMilliseconds / (1000 * 60 * 60);
          return diffHours >= parseInt(value.value, 10);
        }
        return false;
      }
    }
  }

  return false;
}

const flattenUser = (currentUser) => {
  if (!currentUser) return null;

  let userObject = currentUser;
  userObject.hasHypo = (currentUser.diagnoseFull || {}).hasHypo;
  userObject.hypoNotes = (currentUser.diagnoseFull || {}).hypoNotes;
  userObject.hasHashi = (currentUser.diagnoseFull || {}).hasHashi;
  userObject.hashiNotes = (currentUser.diagnoseFull || {}).hashiNotes;
  userObject.hasAppointment = (currentUser.diagnoseFull || {}).hasAppointment;
  userObject.hasAppointmentNutritionist = (currentUser.diagnoseFull || {}).hasAppointmentNutritionist;
  userObject.reason = (currentUser.diagnoseFull || {}).reason;
  userObject.departmentId = (currentUser.department || {}).departmentId;
  userObject.timeZoneOffset = (currentUser.department || {}).timeZoneOffset;
  userObject.timeZoneName = (currentUser.department || {}).timeZoneName;
  userObject.tzAbbr = (currentUser.department || {}).tzAbbr;
  userObject.addressCity = (currentUser.address || {}).city;
  userObject.addressState = (currentUser.address || {}).state;
  userObject.addressZip = (currentUser.address || {}).zip;
  userObject.cardBrand = (currentUser.card || {}).brand;
  userObject.cardExpMonth = (currentUser.card || {}).expMonth;
  userObject.cardExpYear = (currentUser.card || {}).expYear;
  userObject.eligibilitycanBook = (currentUser.eligibility || {}).canBook;
  userObject.eligibilitycanPurchase = (currentUser.eligibility || {}).canPurchase;
  userObject.eligibilityconsultation = (currentUser.eligibility || {}).consultation;
  userObject.eligibilitypurchase = (currentUser.eligibility || {}).purchase;
  userObject.eligibilitypharmacy = (currentUser.eligibility || {}).pharmacy;
  userObject.eligibilitylabCenter = (currentUser.eligibility || {}).labCenter;
  userObject.eligibilitylab = (currentUser.eligibility || {}).lab;
  userObject.eligibilitypractitioner = (currentUser.eligibility || {}).practitioner;
  userObject.eligibilitycanBookNutritionist = (currentUser.eligibility || {}).canBookNutritionist;
  userObject.eligibilitycanBookAsync = (currentUser.eligibility || {}).canBookAsync;
  userObject.eligibilitycanBookPerimenopause = (currentUser.eligibility || {}).canBookPerimenopause;
  userObject.eligibilitycanBookPostmenopause = (currentUser.eligibility || {}).canBookPostmenopause;
  userObject.pharmacyPlaceId = (currentUser.pharmacy || {}).placeId;
  userObject.pharmacyName = (currentUser.pharmacy || {}).name;
  userObject.labCenterPlaceId = (currentUser.labCenter || {}).placeId;
  userObject.labCenterName = (currentUser.labCenter || {}).name;
  userObject.b2bpendingApproval = (currentUser.labCenter || {}).pendingApproval;
  userObject.b2bisActive = (currentUser.labCenter || {}).isActive;
  userObject.b2bmustVerify = (currentUser.labCenter || {}).mustVerify;
  userObject.b2bverificationSent = (currentUser.labCenter || {}).verificationSent;
  userObject.b2bverificationSentDate = (currentUser.labCenter || {}).verificationSentDate;
  userObject.b2bkit = (currentUser.labCenter || {}).kit;
  userObject.b2bvitamins = (currentUser.labCenter || {}).vitamins;
  userObject.b2bmembership = (currentUser.labCenter || {}).membership;
  userObject.b2bvisits = (currentUser.labCenter || {}).visits;
  userObject.b2basync = (currentUser.labCenter || {}).async;
  userObject.b2bnutritionist = (currentUser.labCenter || {}).nutritionist;
  userObject.b2bexpiresAfter = (currentUser.labCenter || {}).expiresAfter;
  userObject.dedicatedCareManagerId = (currentUser.dedicatedCareManager || {}).id;
  userObject.b2bUser = !!currentUser.b2b && !!currentUser.b2b.isActive;
  userObject.age = !!currentUser.dob ? moment().diff(currentUser.dob, 'year') : -1;
  userObject.isInsuranceMember = currentUser.membershipSpecific === 'insurance';

  return userObject;
};

function getAthenaIdSpecific(query) {
  if (query.datatype === 'grouping') {
    const operator = query.operator || query.data.operator;
    if (!operator) {
      const content = query.data && query.data.content ? query.data.content : [];
      for (let i = 0; i < content.length; i++) {
        const res = getAthenaIdSpecific(content[i]);
        if (res && res.length > 0) {
          return res;
        }
      }
      return [];
    } else {
      if (operator === 'AND') {
        const content = query.data && query.data.content ? query.data.content : [];
        for (let i = 0; i < content.length; i++) {
          const res = getAthenaIdSpecific(content[i]);
          if (res && res.length > 0) {
            return res;
          }
        }
        return [];
      } else if (operator === 'OR') {
        const content = query.data && query.data.content ? query.data.content : [];
        for (let i = 0; i < content.length; i++) {
          const res = getAthenaIdSpecific(content[i]);
          if (res && res.length > 0) {
            return res;
          }
        }
        return [];
      }
    }
  } else if (query.datatype === 'content') {
    const propertyLabel = query.property && query.property.label;
    const propertyValue = query.property && query.property.value;

    if (propertyLabel && propertyValue) {
      const sanitizedPropertyValue = propertyValue
        .replace('db.', '')
        .replace('athena.', '')
        .replace('stripe.', '');
      if (sanitizedPropertyValue !== 'athenaId') return null;
      const arr = query.value.split(';')
      return [...arr];
    }
  }

  return [];
}

export const queryMatchFindAthenaIds = (data) => {
  const m = getAthenaIdSpecific(data);

  return m;
};

export const queryMatch = (filters, currentUser) => {
  const userObject = flattenUser(currentUser);
  if (!userObject) return false;

  const isMatch = matchQuery(filters, userObject);
  
  return isMatch;
};
