import React, { useState } from 'react';
import { Button, Text, Flex, Card, Box } from 'rebass';
import styled from 'styled-components';
import { BoxButton } from '../../../components/async/SharedComponents';
import Imge from '../../../static/telehealthConsult.png';
import FurtherTesting from '../../../static/your_Account.png';
import HormoneSpecialist from '../../../static/HormoneSpecialist.png';
import Monitoring from '../../../static/Subscriptions.png';

const BlueHeading = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #354e79;
`;

const SelectedMarkerOut = styled(Flex)`
  background: #ffffff;
  border: 2px solid #354e79;
  transition: background 150ms ease-in-out;
  width: 16px;
  height: 16px;
  margin-right: 20px;
  margin-top: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  &.isChecked {
    background: #354e79;
  }
`;

const CannotBeSeen = ({ onSubmit }) => {
  return (
    <Flex flex={1} flexDirection="column" pb="60px">
      <Flex flexDirection="column" p="16px">
        <Box style={{ width: '50%', margin: '0 auto', maxWidth: '100px', position: 'relative' }}>
          <img src={Imge} alt="" />
          <svg width="30" height="30" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, right: 0 }}>
            <path
              d="M37.0432 29.9957L58.5224 8.5634C59.463 7.62266 59.9915 6.34674 59.9915 5.01632C59.9915 3.68591 59.463 2.40999 58.5224 1.46925C57.5818 0.528504 56.3061 0 54.9759 0C53.6456 0 52.3699 0.528504 51.4293 1.46925L30 22.9515L8.57071 1.46925C7.6301 0.528504 6.35436 -9.91233e-09 5.02414 0C3.69391 9.91234e-09 2.41817 0.528504 1.47756 1.46925C0.536954 2.40999 0.00852477 3.68591 0.00852476 5.01632C0.00852475 6.34674 0.536954 7.62266 1.47756 8.5634L22.9568 29.9957L1.47756 51.4281C1.00937 51.8925 0.637763 52.4451 0.384165 53.0538C0.130566 53.6626 0 54.3156 0 54.9751C0 55.6347 0.130566 56.2877 0.384165 56.8965C0.637763 57.5052 1.00937 58.0578 1.47756 58.5222C1.94193 58.9905 2.4944 59.3621 3.10311 59.6158C3.71182 59.8694 4.36471 60 5.02414 60C5.68356 60 6.33646 59.8694 6.94516 59.6158C7.55387 59.3621 8.10634 58.9905 8.57071 58.5222L30 37.0399L51.4293 58.5222C51.8937 58.9905 52.4461 59.3621 53.0548 59.6158C53.6635 59.8694 54.3164 60 54.9759 60C55.6353 60 56.2882 59.8694 56.8969 59.6158C57.5056 59.3621 58.0581 58.9905 58.5224 58.5222C58.9906 58.0578 59.3622 57.5052 59.6158 56.8965C59.8694 56.2877 60 55.6347 60 54.9751C60 54.3156 59.8694 53.6626 59.6158 53.0538C59.3622 52.4451 58.9906 51.8925 58.5224 51.4281L37.0432 29.9957Z"
              fill="#FF0000"
            />
          </svg>
        </Box>
        <BlueHeading mb={4} mt={3} textAlign={'center'}>
          Sorry, Our Providers Cannot See You at This Time
        </BlueHeading>
      </Flex>
      <Flex flex={1} flexDirection="column" p="16px" style={{ background: 'white' }}>
        <Text mb={3} color={'#F6A293'} fontWeight={'600'}>
          Why cannot I be seen?
        </Text>
        <Text mb={3} fontSize={'14px'} fontWeight={300}>Given that your TSH levels are within the optimal range, a virtual visit may not be beneficial at this time.</Text>
        <Text mb={3} fontSize={'14px'} fontWeight={300}>
          We understand that it may feel disheartening to have 'normal' results while still experiencing symptoms. We are here to support you and explore every possible avenue to improve your
          well-being.
        </Text>
        <Text mb={4} fontSize={'14px'} fontWeight={300}>Here are some recommended next steps:</Text>

        <Text mb={3} color={'#F6A293'} fontWeight={'600'}>
          Recommended next steps:
        </Text>
        <Flex alignItems={'flex-start'} mb={4}>
          <img width="65px" style={{ objectFit: 'scale-down' }} src={FurtherTesting} alt="" />
          <Flex ml={3} flexDirection={'column'} flex={1}>
            <Text mb={2} fontWeight={'600'}>
              Further Testing
            </Text>
            <Text mb={2} fontSize={'14px'} fontWeight={300}>Consider additional testing to explore other potential causes. We offer discounted rates for specific panels through Let's Get Checked.</Text>
            <Text fontSize={'14px'} color="#0000FF" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={()=>{
              window.open('https://letsgetchecked.7no9.net/c/2464186/523760/8695', '_blank', 'noreferrer');
            }}>{`>> Explore`}</Text>
          </Flex>
        </Flex>
        <Flex alignItems={'flex-start'} mb={4}>
          <img width="65px" style={{ objectFit: 'scale-down' }} src={HormoneSpecialist} alt="" />
          <Flex ml={3} flexDirection={'column'} flex={1}>
            <Text mb={2} fontWeight={'600'}>
              Hormone specialist consultation
            </Text>
            <Text mb={2} fontSize={'14px'} fontWeight={300}>If you wish to explore potential hormonal imbalances, you can find certified hormone specialists online.</Text>
            <Text fontSize={'14px'} color="#0000FF" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={()=>{
              window.open('https://www.a4m.com/find-a-doctor.html', '_blank', 'noreferrer');
            }}>{`>> Explore`}</Text>
          </Flex>
        </Flex>
        <Flex alignItems={'flex-start'} mb={4}>
          <img width="65px" style={{ objectFit: 'scale-down' }} src={Monitoring} alt="" />
          <Flex ml={3} flexDirection={'column'} flex={1}>
            <Text mb={2} fontWeight={'600'}>
              Regular monitoring
            </Text>
            <Text mb={2} fontSize={'14px'} fontWeight={300}>
              Thyroid hormone levels fluctuate due to various factors. We recommend rechecking your thyroid health every 6-12 months using our At-Home Test Kit. Should any abnormalities arise, our
              team at Paloma Health is ready to assist you.
            </Text>
            <Text fontSize={'14px'} color="#0000FF" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={()=>{
              window.open('https://www.palomahealth.com/kit-subscriptions', '_blank', 'noreferrer');
            }}>{`>> Explore`}</Text>
          </Flex>
        </Flex>
        <BoxButton className="visible">
          <Button
            variant="primary"
            onClick={onSubmit}
            style={{
              marginLeft: '12px',
              marginRight: '12px',
            }}
            width={[1, 1 / 2, 1 / 4]}
          >
            Go Back to Dashboard
          </Button>
        </BoxButton>
      </Flex>
    </Flex>
  );
};

export default CannotBeSeen;
