import React from 'react';
import { Card, Flex, Box, Heading, Button } from 'rebass';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import FormField from '../components/FormField';
import TextInput from '../components/TextInput';
import withSession from '../lib/withSession';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';
import { ADMIN_UPDATE_MOBILE_PROFILE } from '../graphql';

const CardBox = styled(Card)`
  width: 100%;
  padding: 32px;
`;
const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;
const AdminMobileContactInfo = (props) => {
  // console.log('SES', props.session);
  const handleUpdate = async (values, actions) => {
    const { toastManager, refetch } = props;
    // console.log('PROPS VAL', values);
    actions.setSubmitting(true);
    try {
      const res = await props.client.mutate({
        mutation: ADMIN_UPDATE_MOBILE_PROFILE,
        variables: {
          firstName: values.firstName,
          lastName: values.lastName,
          currentEmail: values.currentEmail,
          newEmail: values.newEmail,
        },
      });
      if (res && res.data && res.data.adminUpdateMobileProfile) {
        if (res.data.adminUpdateMobileProfile.ok === true) {
          actions.resetForm();
          if (!res.data.adminUpdateMobileProfile.customError) {
            props.toastManager.add('Updated successfully', {
              appearance: 'success',
            });
          } else {
            props.toastManager.add(res.data.adminUpdateMobileProfile.customError.userMessage || 'Warning updating check console', { appearance: 'warning' });
            console.log('Unable to update : ', res.data.adminUpdateMobileProfile.customError.errorMessage);
          }
        } else {
          props.toastManager.add(res.data.adminUpdateMobileProfile.customError.userMessage || 'Error updating', { appearance: 'error' });
          console.log('Unable to update : ', res.data.adminUpdateMobileProfile.customError.errorMessage);
        }
      } else {
        actions.setSubmitting(false);
        props.toastManager.add('Error updating, please try again', {
          appearance: 'error',
        });
      }

      actions.setSubmitting(false);
      //   actions.resetForm();
    } catch (err) {
      console.log('ERR', err);
      actions.setSubmitting(false);
      toastManager.add('Error updating, please try again', {
        appearance: 'error',
      });
    }
  };
  return (
    <Container>
    <CardBox mb={4}>
      <Flex flexDirection="column">
        <Box>
          <Heading textAlign="left" mb={4}>
            Change Mobile User Information
          </Heading>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              currentEmail: '',
              newEmail: '',
            }}
            // validationSchema={schema}
            onSubmit={(values, actions) => handleUpdate(values, actions)}
            render={({ touched, errors, isValid, values, isSubmitting, handleChange, handleBlur }) => (
              <Form>
                <FormField mb={3} error={touched.currentEmail && errors.currentEmail}>
                  <TextInput placeholder="Current Email" type="text" name="currentEmail" />
                </FormField>
                <FormField mb={3} error={touched.firstName && errors.firstName}>
                  <TextInput placeholder="First Name" type="text" name="firstName" />
                </FormField>
                <FormField mb={3} error={touched.lastName && errors.lastName}>
                  <TextInput placeholder="Last Name" type="text" name="lastName" />
                </FormField>

                <FormField mb={3} error={touched.newEmail && errors.newEmail}>
                  <TextInput placeholder="New email" type="text" name="newEmail" />
                </FormField>

                <Flex alignItems="center" flexDirection="column">
                  <Button disabled={!isValid || isSubmitting} variant="primary" width="200px" type="submit">
                    Update
                  </Button>
                </Flex>
              </Form>
            )}
          />
        </Box>
      </Flex>
    </CardBox>
    </Container>
  );
};

export default compose(
  withApollo,
  withSession,
  withToastManager
)(AdminMobileContactInfo);
