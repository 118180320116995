import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Box, Card, Flex, Heading, Text, Button } from 'rebass';

import { CrossSearch } from '../components/icons';
import PharmacySearch from '../components/PharmacySearch';
import withSession from '../lib/withSession';
import PleaseWait from '../components/PleaseWait';

const Container = styled(Box)`
  max-width: 1024px;
`;

const Icon = styled(Flex)`
  @media screen and (max-width: 52em) {
    display: none;
  }
`;

const Pharmacy = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const {
    session: { pharmacy },
    continueIntake,
    displayDisclaimerCareManager
  } = props;

  return (
    <Flex flexDirection="column" p={[3, 5]} flex={1} style={{ zIndex: 1 }}>
      <Container>
        <Flex>
          <Card mb={4} p={[3, 4]}>
            <Flex flexDirection={['column', 'row']}>
              <Icon mr={[0, 4]} mb={[4, 0]}>
                <CrossSearch width={100} />
              </Icon>
              <Flex flex={1} flexDirection="column">
                <Heading mb={4}>Your Preferred Pharmacy</Heading>
                {!!isSaving && <PleaseWait />}
                {!pharmacy &&
                  !isSaving && (
                    <>
                      <Text>Select your preferred pharmacy and any prescription you might get through Paloma Health will be sent there.</Text>
                      {displayDisclaimerCareManager && <Text>if you don't see your pharmacy listed here, please contact your Care Manager</Text>}
                    </>
                  )}
                {pharmacy &&
                  !isSaving && (
                    <Fragment>
                      <Text fontSize={3} fontWeight={500} mb={3}>
                        {pharmacy.name}
                      </Text>
                      <Text mb={2}>{pharmacy.formattedAddress}</Text>
                      <Text>{pharmacy.phone}</Text>
                      {continueIntake && (
                        <Button variant="pink" style={{ width: '220px' }} mt={4} onClick={continueIntake}>
                          Continue
                        </Button>
                      )}
                    </Fragment>
                  )}
              </Flex>
            </Flex>
          </Card>
        </Flex>

        <PharmacySearch setIsSaving={setIsSaving} isIntake={!!continueIntake}/>
      </Container>
    </Flex>
  );
};

export default withSession(Pharmacy);
