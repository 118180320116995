import React from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

const BoxEditOverlay = styled(Box)`
  z-index: 10;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: rgb(0, 0, 0);
  opacity: 0.4;
  display: none;
  transition-duration: 150ms;
  &.isOpen {
    display: block;
  }
`;

const BoxEdit = styled(Flex)`
  z-index: 11;
  position: fixed;
  top: 0;
  right: 0;
  width: 500px;
  background-color: white;
  height: 100vh;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  transform: translate3d(100%, 0, 0);
  visibility: hidden;
  transition-duration: 300ms;
  &.isOpen {
    visibility: visible;
    transform: translate3d(0, 0, 0) !important;
  }
`;

export const Drawer = ({ isOpen, onClose, children }) => {
  return (
    <>
      <BoxEditOverlay className={isOpen ? 'isOpen' : ''} onClick={onClose} />
      <BoxEdit
        className={isOpen ? 'isOpen' : ''}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {isOpen && <>{children}</>}
      </BoxEdit>
    </>
  );
};
