import React from 'react';
import 'react-table-v6/react-table.css';
import Select from 'react-select';
import FormField from '../../../../../components/FormField';
import TextInput from '../../../../../components/TextInput';
import TextArea from '../../../../../components/TextArea';

const StoryFormScreen = ({
  touched,
  errors,
  hormoneYValues,
  values,
  setFieldTouched,
  setFieldValue,
}) => {
  return (
    <div style={{ overflowY: 'auto' }}>
      <FormField mb={3} error={touched.phone && errors.phone}>
        <label htmlFor="title">Title</label>
        <TextInput name="title" placeholder="Title" />
      </FormField>

      <FormField mb={3} error={touched.phone && errors.phone}>
        <label htmlFor="content">Content</label>
        <TextArea name="Content" placeholder="Content" />
      </FormField>

      <FormField mb={3} error={touched.phone && errors.phone}>
        <label htmlFor="tags">Tags (separate with ;)</label>
        <TextInput name="tags" placeholder="Tags" />
      </FormField>

      <FormField mb={3} error={touched.phone && errors.phone}>
        <label htmlFor="titleColor">Title Color</label>
        <TextInput name="titleColor" placeholder="Title Color" />
      </FormField>

      <FormField mb={3} error={touched.phone && errors.phone}>
        <label htmlFor="titleFontSize">Text Font Size</label>
        <TextInput
          type="number"
          name="titleFontSize"
          placeholder="Title Font Size"
        />
      </FormField>

      <FormField mb={3} error={touched.phone && errors.phone}>
        <label htmlFor="textColor">Text Color</label>
        <TextInput name="textColor" placeholder="Text Color" />
      </FormField>

      <FormField mb={3} error={touched.phone && errors.phone}>
        <label htmlFor="textFontSize">Text Font Size</label>
        <TextInput
          type="number"
          name="textFontSize"
          placeholder="Text Font Size"
        />
      </FormField>

      <FormField mb={4} error={touched.phone && errors.phone}>
        <label htmlFor="hormoneY">Hormone Y</label>
        <Select
          classNamePrefix="region-select"
          options={hormoneYValues}
          name="hormoneY"
          value={values.hormoneY}
          placeholder="select hormone Y"
          onChange={option => {
            setFieldValue('hormoneY', option);
          }}
          onBlur={() => setFieldTouched('hormoneY', true)}
          isSearchable={true}
        />
      </FormField>
    </div>
  );
};

export default StoryFormScreen;
