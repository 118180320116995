import React from 'react';
import { Box, Button, Card, Flex, Image, Text } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../../components/FullLayout';
import withSession from '../../lib/withSession';
import Header from '../asyncIntake/Header';
import Paymentinformation from '../../static/Paymentinformation.png';
import nutritionistVisit from '../../static/nutritionistVisit.png';
import subscriptionsKit from '../../static/subscriptionsKit.png';
import subscriptionsVitamins from '../../static/subscriptionsVitamins.png';
import Kit_Extra_BG_2 from '../../static/Kit_Extra_BG_2.png';
import { withRouter } from 'react-router';
import { getMemberVisitPrice } from '../../utils/pricing';

const Container = styled(Box)`
  max-width: 400px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 1;
`;

const ImgOverlay = styled(Image)`
  position: absolute;
  top: 130px;
  left: calc(100vw / 2 - 550px);
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

const AsyncPostRegister = ({ session, history }) => {
  const { consultation, canBookNutritionist, purchase, canBookPerimenopause, canBookPostmenopause } = session.eligibility;
  return (
    <FullLayout2 style={{ zIndex: 11 }} pb={5}>
      <Container>
        <Header previousPage={() => {}} hideBack />
        <ImgOverlay src={Kit_Extra_BG_2} />
        <Flex flexDirection={'column'} mt={'80px'} mx={3}>
          <Text fontWeight={600} fontSize="18px" color="#F6A293">
            YOUR ACCOUNT IS CREATED
          </Text>
          <Text mt={4} fontWeight={500}>
            Here are the options available to you based on your answers
          </Text>
          {!!consultation && (
            <Card
              height="64px"
              p="12px 8px"
              style={{ borderRadius: '12px', cursor: 'pointer' }}
              mt={3}
              onClick={() => {
                history.push({
                  pathname: '/scheduler',
                  state: {
                    isnew: true,
                  },
                });
              }}
            >
              <Flex alignItems={'center'}>
                <Image src={Paymentinformation} mr={3} width="68px" height="68px" />
                <Flex flexDirection={'column'} flex={1} mr={2}>
                  <Text fontWeight={500}>Thyroid video visit</Text>
                  <Text fontWeight={300}>{`$${getMemberVisitPrice()} as a Paloma member`}</Text>
                </Flex>
                <svg width="9" height="12" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.01339 5.11472L1.49243 9.52675C1.34768 9.66686 1.11363 9.66686 0.968883 9.52675L0.359108 8.93649C0.214363 8.79638 0.214363 8.56982 0.359108 8.42971L4.00852 4.86133L0.359108 1.29294C0.214363 1.15283 0.214363 0.926269 0.359108 0.786157L0.968883 0.195899C1.11363 0.0557873 1.34768 0.0557873 1.49243 0.195899L6.01339 4.60793C6.15813 4.74804 6.15813 4.97461 6.01339 5.11472Z"
                    fill="#354E79"
                  />
                </svg>
              </Flex>
            </Card>
          )}
          {!!canBookNutritionist && (
            <Card height="64px" p="12px 8px" style={{ borderRadius: '12px', cursor: 'pointer' }} mt={3} onClick={() => history.push('/consultations-nutritionist')}>
              <Flex alignItems={'center'}>
                <Image src={nutritionistVisit} mr={3} width="68px" height="68px" />
                <Flex flexDirection={'column'} flex={1} mr={2}>
                  <Text fontWeight={500}>Nutritionist visits</Text>
                  <Text fontWeight={300}>$75 for a 30-minute visit</Text>
                </Flex>
                <svg width="9" height="12" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.01339 5.11472L1.49243 9.52675C1.34768 9.66686 1.11363 9.66686 0.968883 9.52675L0.359108 8.93649C0.214363 8.79638 0.214363 8.56982 0.359108 8.42971L4.00852 4.86133L0.359108 1.29294C0.214363 1.15283 0.214363 0.926269 0.359108 0.786157L0.968883 0.195899C1.11363 0.0557873 1.34768 0.0557873 1.49243 0.195899L6.01339 4.60793C6.15813 4.74804 6.15813 4.97461 6.01339 5.11472Z"
                    fill="#354E79"
                  />
                </svg>
              </Flex>
            </Card>
          )}
          {(!!canBookPerimenopause || !!canBookPostmenopause) && (
            <Card
              height="64px"
              p="12px 8px"
              style={{ borderRadius: '12px', cursor: 'pointer' }}
              mt={3}
              onClick={() => {
                history.push({
                  pathname: '/schedulemeno',
                  state: {
                    isnew: true,
                  },
                });
              }}
            >
              <Flex alignItems={'center'}>
                <Image src={Paymentinformation} mr={3} width="68px" height="68px" />
                <Flex flexDirection={'column'} flex={1} mr={2}>
                  <Text fontWeight={500}>{`${canBookPerimenopause ? 'Peri-menopause' : 'Post-menopause'} video visit`}</Text>
                  <Text fontWeight={300}>{`$${getMemberVisitPrice()} as a Paloma member`}</Text>
                </Flex>
                <svg width="9" height="12" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.01339 5.11472L1.49243 9.52675C1.34768 9.66686 1.11363 9.66686 0.968883 9.52675L0.359108 8.93649C0.214363 8.79638 0.214363 8.56982 0.359108 8.42971L4.00852 4.86133L0.359108 1.29294C0.214363 1.15283 0.214363 0.926269 0.359108 0.786157L0.968883 0.195899C1.11363 0.0557873 1.34768 0.0557873 1.49243 0.195899L6.01339 4.60793C6.15813 4.74804 6.15813 4.97461 6.01339 5.11472Z"
                    fill="#354E79"
                  />
                </svg>
              </Flex>
            </Card>
          )}
          {!!purchase && (
            <Card height="64px" p="12px 8px" style={{ borderRadius: '12px', cursor: 'pointer' }} mt={3} onClick={() => history.push('/purchase')}>
              <Flex alignItems={'center'}>
                <Image src={subscriptionsKit} mr={3} width="68px" height="68px" />
                <Flex flexDirection={'column'} flex={1} mr={2}>
                  <Text fontWeight={500}>Order a thyroid test</Text>
                  <Text fontWeight={300}>Complete at-home blood test kit - $99</Text>
                </Flex>
                <svg width="9" height="12" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.01339 5.11472L1.49243 9.52675C1.34768 9.66686 1.11363 9.66686 0.968883 9.52675L0.359108 8.93649C0.214363 8.79638 0.214363 8.56982 0.359108 8.42971L4.00852 4.86133L0.359108 1.29294C0.214363 1.15283 0.214363 0.926269 0.359108 0.786157L0.968883 0.195899C1.11363 0.0557873 1.34768 0.0557873 1.49243 0.195899L6.01339 4.60793C6.15813 4.74804 6.15813 4.97461 6.01339 5.11472Z"
                    fill="#354E79"
                  />
                </svg>
              </Flex>
            </Card>
          )}
          <Card height="64px" p="12px 8px" style={{ borderRadius: '12px', cursor: 'pointer' }} mt={3} onClick={() => history.push('/order-vitamins')}>
            <Flex alignItems={'center'}>
              <Image src={subscriptionsVitamins} mr={3} width="68px" height="68px" />
              <Flex flexDirection={'column'} flex={1} mr={2}>
                <Text fontWeight={500}>Daily Thyroid Care</Text>
                <Text fontWeight={300}>Multivitamin for a healthy thyroid</Text>
              </Flex>
              <svg width="9" height="12" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.01339 5.11472L1.49243 9.52675C1.34768 9.66686 1.11363 9.66686 0.968883 9.52675L0.359108 8.93649C0.214363 8.79638 0.214363 8.56982 0.359108 8.42971L4.00852 4.86133L0.359108 1.29294C0.214363 1.15283 0.214363 0.926269 0.359108 0.786157L0.968883 0.195899C1.11363 0.0557873 1.34768 0.0557873 1.49243 0.195899L6.01339 4.60793C6.15813 4.74804 6.15813 4.97461 6.01339 5.11472Z"
                  fill="#354E79"
                />
              </svg>
            </Flex>
          </Card>

          <Button mt={4} variant="primary" onClick={() => history.push('/')} mb={6}>
            Visit my Account
          </Button>
        </Flex>
      </Container>
    </FullLayout2>
  );
};

export default withRouter(withSession(AsyncPostRegister));
