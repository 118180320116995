import React, { Component, Fragment, useRef, useState } from 'react';
import { Flex, Box, Button, Heading, Text, Card } from 'rebass';
import { withWizard } from 'react-albus';

import { Query, compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { Icon } from '@iconify/react';
import magnifier from '@iconify/icons-entypo/magnifying-glass';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BoxButton, BoxButtonNonFixed } from '../async/SharedComponents';
import { isMobile } from 'react-device-detect';
import ConfirmModal from '../ConfirmModal';
import { useMount } from '../../utils/hooks/lifecycle';
import MedicationSelector from '../medicationSelector/MedicationSelector';
import { SelectedMedicationCard } from '../../pages/asyncIntake/screens/components/medicationLists/SelectedMedicationCard';
import { MobileModal } from '../../pages/asyncIntake/screens/components/medicationLists/MobileModal';
import { MedicationDetailsContent } from '../../pages/asyncIntake/screens/components/medicationLists/MedicationDetailsContent';
import { GET_MEDICATIONS_ON_FILE } from '../../graphql';
import PleaseWait from '../PleaseWait';
import ScrollAnimation from 'react-animate-on-scroll';
import { fadeInDown, fadeInUp } from '../../utils/animationsStyleBuilder';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const BorderCurrMed = styled(Box)`
  background-color: ${(props) => (props.isSelected ? 'white' : 'transparent')};
  cursor: pointer;
  &:hover {
    background-color: white;
  }
`;

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <span>
        <Icon
          icon={magnifier}
          style={{
            color: 'rgb(53, 78, 121)',
            fontSize: '24px',
            alignSelf: 'center',
            marginLeft: '6px',
            opacity: 0.7,
          }}
        />
      </span>
      {children}
    </components.Control>
  );
};

const MedicationList = ({ onSubmit, data, getState, wizard, currentMeds }) => {
  const [currMeds, setCurrMeds] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedForEdit, setSelectedForEdit] = useState();
  const [inputValue, setInputValue] = useState('');
  const [val, setVal] = useState(null);
  const asyncSelect = useRef();

  useMount(() => {
    // const state = getState();
    // if (state && state.values) {
    //   const meds = state.values.find(x => x.id === data.id);
    //   setSelectedOptions((meds || {}).values || []);
    // }
    const arr = [];
    if (!currentMeds || !currentMeds.getMedicationOnFile || currentMeds.getMedicationOnFile.length < 1) {
      setShowDropDown(true);
      return;
    }

    for (let index = 0; index < currentMeds.getMedicationOnFile.length; index++) {
      const element = currentMeds.getMedicationOnFile[index];
      if (!element || !element.medicationId) continue;
      arr.push({
        dose: element.dose,
        frequency: element.frequency,
        label: element.medication,
        questionId: element.medicationId.toString(),
        value: element.medicationId.toString(),
        isChecked: false,
      });
    }

    setCurrMeds(arr);
  });

  const handleInputChange = (newValue) => {
    const inputValue = newValue; //.replace(/\W/g, '');

    setInputValue(inputValue);
    return inputValue;
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      height: '64px',
      borderRadius: '10px',
      border: `${state.focused ? '3' : '1'}px solid #44a5ff`,
    }),
  };

  return (
    <Fragment>
      <Flex
        flex={1}
        flexDirection="column"
        style={{
          zIndex: 1,
          maxWidth: '450px',
          justifyContent: 'center',
          margin: '0 auto',
          padding: '0 12px',
        }}
      >
        {data.title ? (
          <Box m={2} mt="0" mb={3} style={{ ...fadeInDown() }}>
            <Text fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
              {data.title}
            </Text>
          </Box>
        ) : null}

        <Text mb={4} color="hsl(0,0%,50%)" textAlign="center" style={{ ...fadeInDown('1s', '150ms') }}>
          {currMeds && currMeds.length > 0 ? 'Please confirm the medications and supplements you are currently taking by checking the boxes:' : data.prompt}
        </Text>
        {currMeds && (
          <>
            {currMeds
              .sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;

                return 0;
              })
              .map((item, i, arr) => {
                return (
                  <Flex key={item.value + `_i_${i}`} style={{ ...fadeInUp(20, '1s', `${i * 100}ms`) }}>
                    <BorderCurrMed
                      isSelected={item.isChecked}
                      flex={1}
                      style={{
                        margin: '6px 0',
                        border: '1px solid #344f77',
                        padding: '12px 12px',
                        textAlign: 'left',
                        borderRadius: '6px',
                        // background: item.isChecked ? 'white' : 'transparent',
                      }}
                      onClick={() => {
                        if (item.isChecked) {
                          const array = JSON.parse(JSON.stringify(arr));
                          array[i].isChecked = false;
                          setCurrMeds(array);
                        } else {
                          const array = JSON.parse(JSON.stringify(arr));
                          array[i].isChecked = true;
                          setCurrMeds(array);
                        }
                      }}
                    >
                      <Flex alignItems={'center'}>
                        <Flex flexDirection={'column'} flex={1}>
                          <Text fontWeight="bold">{item.label}</Text>
                          <Text fontWeight={200} mt={3}>
                            {!item.dose && !item.frequency ? 'ADD DOSE AND FREQUENCY' : `${item.dose} dose${item.dose > 1 ? 's' : ''} ${item.frequency}`}
                          </Text>
                        </Flex>

                        {item.isChecked ? (
                          <svg style={{ cursor: 'pointer' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                              fill="#354e79"
                            />
                          </svg>
                        ) : (
                          <svg style={{ cursor: 'pointer' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                              fill="#354e79"
                            />
                          </svg>
                        )}
                      </Flex>
                    </BorderCurrMed>
                  </Flex>
                );
              })}
          </>
        )}
        {showDropDown ? (
          <>
            {data.useUsaRx ? (
              <MedicationSelector
                onChange={(option) => {
                  if (!option || !option.value) return;
                  option.questionId = option.value.toString();
                  setSelectedOptions((previous) => [...previous, option]);
                  setSelectedForEdit(option);
                }}
              />
            ) : (
              <>
                <Select
                  ref={asyncSelect}
                  styles={customStyles}
                  cacheOptions
                  isClearable
                  options={data.options.map((x) => {
                    return {
                      label: x.value,
                      value: x.value,
                      questionId: x.questionId,
                      goToPage: x.goToPage,
                    };
                  })}
                  components={{ Control }}
                  value={val}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  noOptionsMessage={() => (inputValue ? (inputValue.length > 2 ? 'No Medication Found' : 'Minimum 2 characters to search') : 'Minimum 2 characters to search')}
                  onChange={(option) => {
                    if (!option || !option.value) return;
                    setSelectedOptions((previous) => [...previous, option]);
                    setSelectedForEdit(option);
                  }}
                />
              </>
            )}
          </>
        ) : (
          <Text
            textAlign={'center'}
            onClick={() => {
              setShowDropDown(true);
            }}
            color={'#0088EB'}
            style={{ cursor: 'pointer', ...fadeInUp(20, '1s', `150ms`) }}
          >
            + Add more medications
          </Text>
        )}
        <TransitionGroup className="items-section__list">
          {selectedOptions.map((x) => {
            return (
              <CSSTransition timeout={500} classNames="moveList">
                <SelectedMedicationCard isInvalid={!x || !x.dose || !x.frequency} key={x.value} name={x.label} dose={x.dose} freq={x.frequency} onClick={() => setSelectedForEdit(x)} />
              </CSSTransition>
            );
          })}
        </TransitionGroup>

        {(!selectedOptions || selectedOptions.length < 1) && (!currMeds || currMeds.filter((x) => x.isChecked).length < 1) ? (
          <Button
            my={4}
            variant="white"
            style={{
              background: '#FFF2F2',
              border: '2px solid #F6A293',
              borderRadius: '20px',
            }}
            onClick={() => {
              console.log({ selectedOptions });
              const sels = {
                endpoint: data.endpoint,
                values: [],
              };
              onSubmit(sels, null, wizard, data);
            }}
          >
            {data.nothingText}
          </Button>
        ) : null}

        <BoxButtonNonFixed mt={4} className={((selectedOptions && selectedOptions.length > 0) || (currMeds && currMeds.filter((x) => x.isChecked).length > 0)) && !selectedForEdit ? 'visible' : ''}>
          <Button
            disabled={selectedOptions.filter((x) => !x.dose || !x.frequency).length > 0}
            variant="primary"
            style={{
              marginLeft: '12px',
              marginRight: '12px',
            }}
            type="button"
            onClick={() => {
              console.log({ selectedOptions });
              const vals = [];
              for (let index = 0; index < selectedOptions.length; index++) {
                const element = selectedOptions[index];
                vals.push({
                  questionId: element.questionId,
                  answer: `${!!element.dose ? element.dose : 'dosage not specified'} - ${!!element.frequency ? element.frequency : 'frequency not specified'}`,
                });
              }
              if (currMeds && currMeds.length > 0) {
                for (let index = 0; index < currMeds.length; index++) {
                  const element = currMeds[index];
                  if (!element.isChecked) continue;
                  vals.push({
                    questionId: element.questionId,
                    answer: `${element.dose}${!!element.frequency ? ` - ${element.frequency}` : ''}`,
                  });
                }
              }

              const sels = {
                endpoint: data.endpoint,
                values: vals,
              };
              onSubmit(sels, null, wizard, data);
            }}
          >
            Continue
          </Button>
        </BoxButtonNonFixed>
      </Flex>
      {selectedForEdit ? (
        isMobile ? (
          <MobileModal title={selectedForEdit.label} onClose={() => setSelectedForEdit(null)}>
            <MedicationDetailsContent
              dosePlaceholder={data.dosePlaceholder}
              dose={selectedForEdit.dose}
              frequency={selectedForEdit.frequency}
              onSave={(values) => {
                const opts = [];
                for (let index = 0; index < selectedOptions.length; index++) {
                  const element = JSON.parse(JSON.stringify(selectedOptions[index]));
                  if (element.value === selectedForEdit.value) {
                    element.dose = values.dose;
                    element.frequency = values.frequency;
                  }
                  opts.push(element);
                }
                setSelectedOptions(opts);

                setSelectedForEdit(null);
              }}
              onDelete={() => {
                setSelectedOptions(selectedOptions.filter((x) => x.value !== selectedForEdit.value));
                setSelectedForEdit(null);
              }}
            />
          </MobileModal>
        ) : (
          <ConfirmModal
            isOpen={true}
            onClose={() => {
              setSelectedForEdit(null);
            }}
            onBackgroundClick={() => setSelectedForEdit(null)}
            onEscapeKeydown={() => setSelectedForEdit(null)}
            onConfirm={() => {
              this.confirmModalForm();
            }}
            confirmLabel="Save"
            cancelLabel="Remove"
            confirmStyle={{
              width: '100%',
            }}
            cancelStyle={{
              width: '100%',
              marginTop: '18px',
            }}
            cancelVariant="outline"
            buttonsDirection="column"
            displayX={true}
            childrenManaged={true}
            cardStyle={{
              width: '500px',
              maxWidth: 'calc(100vw - 40px)',
            }}
          >
            <ModalInner>
              <MedicationDetailsContent
                dosePlaceholder={data.dosePlaceholder}
                dose={selectedForEdit.dose}
                frequency={selectedForEdit.frequency}
                onSave={(values) => {
                  const opts = [];
                  for (let index = 0; index < selectedOptions.length; index++) {
                    const element = JSON.parse(JSON.stringify(selectedOptions[index]));
                    if (element.value === selectedForEdit.value) {
                      element.dose = values.dose;
                      element.frequency = values.frequency;
                    }
                    // console.log({ element });
                    opts.push(element);
                  }
                  setSelectedOptions(opts);

                  setSelectedForEdit(null);
                }}
                onDelete={() => {
                  setSelectedOptions(selectedOptions.filter((x) => x.value !== selectedForEdit.value));
                  setSelectedForEdit(null);
                }}
              />
            </ModalInner>
          </ConfirmModal>
        )
      ) : null}
    </Fragment>
  );
};
class MedicationListWrap extends Component {
  render() {
    const { data, bypassLoad } = this.props;
    if (bypassLoad) {
      return <MedicationList {...this.props} />;
    }
    return (
      <Query query={GET_MEDICATIONS_ON_FILE}>
        {({ loading, data: dataMeds }) => {
          console.log({ dataMeds });
          if (loading) {
            return (
              <Card p={3} mb={5} mx={3} style={{ zIndex: 1 }}>
                <Fragment>
                  <Flex flex={1} flexDirection="column">
                    <Box m={2}>
                      <Heading color="#344f77" textAlign="center">
                        {data.title}
                      </Heading>
                    </Box>
                    <PleaseWait />
                  </Flex>
                </Fragment>
              </Card>
            );
          }

          return <MedicationList {...this.props} currentMeds={dataMeds} />;
        }}
      </Query>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(MedicationListWrap);
