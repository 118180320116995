import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass';
import trimText from './trimtext';

export default class ReadMore extends React.Component {
  constructor(props) {
    super(props);

    let args = [
      this.props.text,
      this.props.min,
      this.props.ideal,
      this.props.max,
    ];

    const [primaryText, secondaryText] = trimText(...args);
    this.state = {
      displaySecondary: false,
      primaryText,
      secondaryText,
      readMoreText: this.props.readMoreText,
    };
  }

  componentWillReceiveProps(next) {
    let args = [next.text, next.min, next.ideal, next.max];

    const [primaryText, secondaryText] = trimText(...args);
    this.setState({
      displaySecondary: false,
      primaryText,
      secondaryText,
      readMoreText: this.props.readMoreText,
    });
  }

  setStatus() {
    let display = !this.state.displaySecondary;
    this.setState({ displaySecondary: display });
  }

  render() {
    let displayText;
    if (!this.state.secondaryText) {
      displayText = (
        <div className="display-text-group">
          <span className="displayed-text">
            <Text color="#344f79" style={{...this.props.textStyle}}
          dangerouslySetInnerHTML={{
            __html: `${this.state.primaryText} ${this.state.secondaryText}`,
          }}>
              {/* {`${this.state.primaryText} ${this.state.secondaryText}`} */}
            </Text>
          </span>
        </div>
      );
    } else if (this.state.displaySecondary) {
      displayText = (
        <div className="display-text-group">
          <span className="displayed-text" onClick={this.setStatus.bind(this)}>
            <Text color="#344f79" style={{...this.props.textStyle}}
          dangerouslySetInnerHTML={{
            __html: `${this.state.primaryText} ${this.state.secondaryText}`,
          }}>
              {/* {`${this.state.primaryText} ${this.state.secondaryText}`} */}
            </Text>
          </span>
        </div>
      );
    } else {
      displayText = (
        <div className="display-text-group">
          <span className="displayed-text" style={{ color: '#333' }}>
            <Text color="#344f79" style={{...this.props.textStyle}}
          dangerouslySetInnerHTML={{
            __html: `${this.state.primaryText && this.state.primaryText.endsWith('.')? `${this.state.primaryText}..` : `${this.state.primaryText}...`}`,
          }}>
            {/* {this.state.primaryText && this.state.primaryText.endsWith('.')? `${this.state.primaryText}..` : `${this.state.primaryText}...`} */}
            </Text>
            {/* <span style={{ display: 'none' }}>{this.state.secondaryText}</span> */}
            <div
              className="read-more-button"
              style={{ color: '#344f79', fontWeight: 500, marginTop: '4px', cursor: 'pointer', ...this.props.readMoreStyle }}
              onClick={this.setStatus.bind(this)}
            >
              {this.state.readMoreText}
            </div>
          </span>
        </div>
      );
    }

    return displayText;
  }
}

ReadMore.propTypes = {
  text: PropTypes.string.isRequired,
  min: PropTypes.number,
  ideal: PropTypes.number,
  max: PropTypes.number,
  readMoreText: PropTypes.string,
  readMoreStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

ReadMore.defaultProps = {
  readMoreText: 'read more',
  readMoreStyle: {},
  textStyle: {}
};
