import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { Text, Flex, Box } from 'rebass';
import styled from 'styled-components';
import PleaseWait from '../../../components/PleaseWait';
import { ASYNC_ELIGIBILITY_CHECK } from '../../../graphql';
import { useMount } from '../../../utils/hooks/lifecycle';
import { sleep } from '../../../utils/sleep';

const PinkHeading = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
  margin-top: 36px;
  color: #f6a293;
`;

const BlueHeading = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #354e79;
`;

const InsuranceEligibilityCheck = ({
  onSubmit,
  values,
  step,
  gotoStep,
  setCanLive,
  setOneTimeInsurance,
}) => {
  // return (<div />)
  // const vals = values().values
  const [vals, setVals] = useState(values());
  useMount(() => {
    const state = values();

    setVals(state);
  });
  // console.log('vals?');
  if (!vals) {
    return <div />;
  }
  // console.log('vals!', { vals });

  // const vals = values();
  if (
    !vals ||
    !vals.values ||
    !vals.values.find(x => x.id === 'insurancecompany') ||
    !vals.values.find(x => x.id === 'insurancecompany').values ||
    !vals.values.find(x => x.id === 'insurancecompany').values.answer
  ) {
    // gotoStep('insurance');
    return <div />;
  }

  const payer = vals.values.find(x => x.id === 'insurancecompany').values
    .answer;
  const plan =
    vals.values
      .find(x => x.id === 'insurancecompany')
      .values.answer.toLowerCase() === 'medicare' ||
    !!vals.values.find(x => x.id === 'insurancecompany').values.forceQuestionId
      ? 'EPO'
      : vals.values.find(x => x.id === 'insurancetype').values.answer;

  // console.log({ vals });
  return (
    <Query
      query={ASYNC_ELIGIBILITY_CHECK}
      fetchPolicy='network-only'
      variables={{
        payer,
        plan,
      }}
      onCompleted={async data => {
        await sleep(300);
        setCanLive(
          data &&
            data.asyncEligibilityCheck &&
            data.asyncEligibilityCheck.canLive
        );
        if (plan && payer) {
          setOneTimeInsurance({
            type: plan,
            provider: payer,
          });
        }
        if (
          !data ||
          !data.asyncEligibilityCheck ||
          !data.asyncEligibilityCheck.isEligible
        ) {
          onSubmit('howToHelp');
        } else {
          onSubmit('liveInsurance');
        }
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <Flex flex={1} flexDirection="column">
              <Flex flex={1} flexDirection="column" p="16px">
                <PinkHeading mb={3}>Please Wait</PinkHeading>
                <BlueHeading mb={3}>Insurance Eligibility Check</BlueHeading>

                <Text mb={4}>
                  Please wait while we are checking for your insurance
                  eligibility...
                </Text>

                <PleaseWait text="" />
              </Flex>
            </Flex>
          );
        }
        return <Box />;
      }}
    </Query>
  );
};

export default InsuranceEligibilityCheck;
