import styled from 'styled-components';
import React from 'react';

import InternalContext from './InternalContext';

const Root = styled.div`
  height: 100%;
  overflow-y: auto;
  width: 100%;
`;

const Panel = ({ children, className, setTarget, onScroll }) => (
  <Root
    ref={setTarget}
    id="PnlRt"
    onScroll={(e) => {
      if (onScroll) {
        onScroll(e);
      }
    }}
  >
    {children}
  </Root>
);

export default (props) => <InternalContext.Consumer>{({ setTarget }) => <Panel setTarget={setTarget} {...props} />}</InternalContext.Consumer>;
