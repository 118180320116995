// const tagRE = /(?:<!--[\S\s]*?-->|<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>)/g;

// See https://regexr.com/6p8p0
// const attrRE = /(?:\s(?<boolean>[^/\s><=]+?)(?=[\s/>]))|(?:(?<name>\S+?)(?:\s*=\s*(?:(['"])(?<quotedValue>[\s\S]*?)\3|(?<unquotedValue>[^\s>]+))))/g

const lookup = {
  area: true,
  base: true,
  br: true,
  col: true,
  embed: true,
  hr: true,
  img: true,
  input: true,
  keygen: true,
  link: true,
  menuitem: true,
  meta: true,
  param: true,
  source: true,
  track: true,
  wbr: true,
};
const htmlVoidElements = [
  'area',
  'base',
  'basefont',
  'bgsound',
  'br',
  'col',
  'command',
  'embed',
  'frame',
  'hr',
  'image',
  'img',
  'input',
  'isindex',
  'keygen',
  'link',
  'menuitem',
  'meta',
  'nextid',
  'param',
  'source',
  'track',
  'wbr',
];
const attrRE = /\s([^'"/\s><]+?)[\s/>]|([^\s=]+)=\s?(".*?"|'.*?')/g;
const tagRE = /<[a-zA-Z0-9\-\!\/](?:"[^"]*"|'[^']*'|[^'">])*>/g;
const whitespaceRE = /^\s*$/;
const empty = Object.create(null);
export const parseTag = (tag) => {
  const res = {
    type: 'tag',
    name: '',
    voidElement: false,
    attrs: {},
    children: [],
  };

  const tagMatch = tag.match(/<\/?([^\s]+?)[/\s>]/);
  if (tagMatch) {
    res.name = tagMatch[1];
    res.voidElement = htmlVoidElements.includes(tagMatch[1]) || tag.charAt(tag.length - 2) === '/';

    // handle comment tag
    if (res.name.startsWith('!--')) {
      const endIndex = tag.indexOf('-->');
      return {
        type: 'comment',
        comment: endIndex !== -1 ? tag.slice(4, endIndex) : '',
      };
    }
  }

  const reg = new RegExp(attrRE);
  let result = null;
  for (;;) {
    result = reg.exec(tag);

    if (result === null) {
      break;
    }

    if (!result[0].trim()) {
      continue;
    }

    if (result[1]) {
      const attr = result[1].trim();
      let arr = [attr, ''];

      if (attr.indexOf('=') > -1) {
        arr = attr.split('=');
      }

      res.attrs[arr[0]] = arr[1];
      reg.lastIndex--;
    } else if (result[2]) {
      res.attrs[result[2]] = result[3].trim().substring(1, result[3].length - 1);
    }
  }

  return res;
};

// function parseTag(/**@type {string}*/tag) {
//   let i = 0;
//   const res = {
//     type: 'tag',
//     name: '',
//     voidElement: false,
//     attrs: [],
//     children: []
//   };
//   const tagMatch = tag.match(/<\/?([^\s]+?)[/\s>]/)
//   if (tagMatch) {
//     res.name = tagMatch[1]
//     if (
//       lookup[tagMatch[1].toLowerCase()] ||
//       tag.charAt(tag.length - 2) === '/'
//     ) {
//       res.voidElement = true
//     }

//     // handle comment tag
//     if (res.name.startsWith('!--')) {
//       const endIndex = tag.indexOf('-->')
//       return {
//         type: 'comment',
//         comment: endIndex !== -1 ? tag.slice(4, endIndex) : '',
//       }
//     }
//   }

//   const reg = new RegExp(attrRE)

//   for (const match of tag.matchAll(reg)) {
//     // TODO named groups method not working yet, groups is undefined in tests (maybe not out in Node.js yet)
//     // const groups = match.groups
//     // res.attrs[groups.boolean || groups.name] = groups.value1 || groups.value2 || ""
//     if ((match[1] || match[2]).startsWith('use:')) {
//       res.attrs.push({ type: 'directive', name: match[1] || match[2], value: match[4] || match[5] || '' });
//     } else {
//       res.attrs.push({ type: 'attr', name: match[1] || match[2], value: match[4] || match[5] || '' });
//     }
//   }

//   return res
// };

// common logic for pushing a child node onto a list
function pushTextNode(list, html, start) {
  // calculate correct end of the content slice in case there's
  // no tag after the text node.
  const end = html.indexOf('<', start);
  const content = html.slice(start, end === -1 ? void 0 : end);
  if (!/^\s*$/.test(content)) {
    list.push({
      type: 'text',
      content: content,
    });
  }
}

function pushCommentNode(list, tag) {
  // calculate correct end of the content slice in case there's
  // no tag after the text node.
  const content = tag.replace('<!--', '').replace('-->', '');
  if (!/^\s*$/.test(content)) {
    list.push({
      type: 'comment',
      content: content,
    });
  }
}

export function parse(html, options = {}) {
  const result = [];
  const arr = [];
  let current;
  let level = -1;
  let inComponent = false;

  // handle text at top level
  if (html.indexOf('<') !== 0) {
    var end = html.indexOf('<');
    result.push({
      type: 'text',
      content: end === -1 ? html : html.substring(0, end),
    });
  }
  // @ts-ignore
  html.replace(tagRE, function(tag, index) {
    if (inComponent) {
      if (tag !== '</' + current.name + '>') {
        return '';
      } else {
        inComponent = false;
      }
    }
    const isOpen = tag.charAt(1) !== '/';
    const isComment = tag.startsWith('<!--');
    const start = index + tag.length;
    const nextChar = html.charAt(start);

    let parent;

    if (isComment) {
      const comment = parseTag(tag);

      // if we're at root, push new base node
      if (level < 0) {
        result.push(comment);
        return result;
      }
      parent = arr[level];
      if (parent && parent.children && Array.isArray(parent.children)) {
        parent.children.push(comment);
      }
      return result;
    }

    if (isOpen) {
      level++;

      current = parseTag(tag);
      if (current.type === 'tag' && current.name && options.components && options.components[current.name]) {
        current.type = 'component';
        inComponent = true;
      }

      if (!current.voidElement && !inComponent && nextChar && nextChar !== '<' && Array.isArray(current.children)) {
        current.children.push({
          type: 'text',
          content: html.slice(start, html.indexOf('<', start)),
        });
      }

      // if we're at root, push new base node
      if (level === 0) {
        result.push(current);
      }

      parent = arr[level - 1];

      if (parent && parent.children) {
        parent.children.push(current);
      }

      arr[level] = current;
    }

    if (!isOpen || current.voidElement) {
      if (level > -1 && (current.voidElement || current.name === tag.slice(2, -1))) {
        level--;
        // move current up a level to match the end tag
        current = level === -1 ? result : arr[level];
      }
      if (!inComponent && nextChar !== '<' && nextChar) {
        // trailing text node
        // if we're at the root, push a base text node. otherwise add as
        // a child to the current node.
        parent = level === -1 ? result : arr[level].children;

        // calculate correct end of the content slice in case there's
        // no tag after the text node.
        const end = html.indexOf('<', start);
        let content = html.slice(start, end === -1 ? undefined : end);
        // if a node is nothing but whitespace, collapse it as the spec states:
        // https://www.w3.org/TR/html4/struct/text.html#h-9.1
        if (whitespaceRE.test(content)) {
          content = ' ';
        }
        // don't add whitespace-only text nodes if they would be trailing text nodes
        // or if they would be leading whitespace-only text nodes:
        //  * end > -1 indicates this is not a trailing text node
        //  * leading node is when level is -1 and parent has length 0
        if ((end > -1 && level + parent.length >= 0) || content !== ' ') {
          if (parent && Array.isArray(parent)) {
            parent.push({
              type: 'text',
              content: content,
            });
          }
        }
      }
    }
  });

  return result;
}

// function attrString(attrs) {
//   const buff = [];
//   if(!attrs) return ''
//   try {
//     for (const attr of attrs) {
//       buff.push(attr.name + '="' + attr.value.replace(/"/g, '&quot;') + '"');
//     }
//     if (!buff.length) {
//       return '';
//     }
//     return ' ' + buff.join(' ');
//   } catch (error) {
//     return attrs
//   }
// };

// function stringifier(buff, doc) {
//   switch (doc.type) {
//     case 'text':
//       return buff + doc.content;
//     case 'tag':
//       buff += '<' + doc.name + (doc.attrs ? attrString(doc.attrs) : '') + (doc.voidElement ? '/>' : '>');
//       if (doc.voidElement) {
//         return buff;
//       }
//       return buff + doc.children.reduce(stringifier, '') + '</' + doc.name + '>';
//     case 'comment':
//       return buff += '<!--' + doc.content + '-->';
//   }
// };

function attrString(attrs) {
  const buff = [];
  for (let key in attrs) {
    buff.push(key + '="' + attrs[key] + '"');
  }
  if (!buff.length) {
    return '';
  }
  return ' ' + buff.join(' ');
}

function _stringify(buff, doc) {
  switch (doc.type) {
    case 'text':
      return buff + doc.content;
    case 'tag':
      buff += '<' + doc.name + (doc.attrs ? attrString(doc.attrs) : '') + (doc.voidElement ? '/>' : '>');
      if (doc.voidElement) {
        return buff;
      }
      return buff + doc.children.reduce(_stringify, '') + '</' + doc.name + '>';
    case 'comment':
      buff += '<!--' + doc.comment + '-->';
      return buff;
    default:
      return '';
  }
}

export function stringify(doc) {
  console.log({ doc });
  try {
    let c = doc
    if(!Array.isArray(doc)){
      c= parse(doc)
    }
    return c.reduce(function(token, rootEl) {
      return token + _stringify('', rootEl);
    }, '');
  } catch (error) {}
}
