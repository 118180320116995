import React, { Component, Fragment } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import moment from 'moment-timezone';

import ConfirmModal from '../components/ConfirmModal';
import SchedulePayment from './SchedulePayment';
import { Steps, Step, Wizard } from 'react-albus';
import { loadStripe } from '@stripe/stripe-js';

import {
  APPOINTMENTS,
  BOOK_APPT_MENO,
  RESCHED_APPT_MENO,
  CURRENT_USER,
  RESCHED_APPT_FOR_PATIENT_MENO,
  UPCOMING_APPOINTMENTS,
  ADMIN_UPCOMING_APPOINTMENTS_FOR_PATIENT,
  GET_PATIENT_BY_ID,
  ADMIN_GET_APPOINTMENTS_LIST,
  NOTIFY_COULDNT_CONFIRM_CARD,
  SEND_INSURANCE,
  ADMIN_APPOINTMENTS_HISTORY_FOR_PATIENT,
  APPOINTMENTS_HISTORY,
  MEMBERSHIP_CREATE_SESSION,
  SEND_KLAVIYO_STATUS,
  SEND_INSURANCE_INITIAL,
  UPDATE_ATHENA_CARD,
  ATHENA_CARD,
  QUADPAY_SUBSCRIBED,
  INSURANCE_MEMBER_LOST_COVERAGE,
  INSURANCE_MEMBER_SAVE_TMP_INS_DATA,
  GET_MY_WAITLIST,
} from '../graphql';
import withSession from '../lib/withSession';
import withAnalytics from '../lib/withAnalytics';
import PickInsuranceProvider from './scheduleDoctor2/index';
import InsuranceBenefitsCheck from './scheduleDoctor2/insuranceBenefitsCheck';
import { Stepper } from './scheduleDoctor/stepper';
import Complete from './scheduleDoctor/complete';
import SpinLoader from '../components/SpinLoader';
import withIsAdmin from '../lib/withIsAdmin';
import { formalizeInsuranceName } from '../insuranceConstants/payers/helpers';
import { isToggleActive } from '../components/featureToggle/toggles';
import SelectMembership from './scheduleDoctor2/selectMembership';
import SelectPlan from './scheduleDoctor2/SelectPlan2';
import { isMobile } from 'react-device-detect';
import UploadInsuranceCard from './scheduleDoctor2/UploadInsuranceCardNew';
import QuadpayComplete from './scheduleDoctor2/QuadpayComplete';
import { getMemberVisitPrice, getMemberVisitPriceNumber } from '../utils/pricing';
import PickDoctorMeno from './scheduleDoctor2/pickDoctorMeno';
import { Constants } from '../utils/constants';

const initalState = {
  loading: false,
  isConfirmOpen: false,
  slot: {},
  step: 'pickProvider',
  athenaPackageId: undefined,
  isAlreadyBookedAppointmentLoaded: false,
  onFileInsurance: null,
  selectedProvider: null,
};

const MobileButton = styled(Box)`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  justify-content: center;
  z-index: 1;
`;

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
  @media screen and (max-width: 52em) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const DesktopOnly = styled(Flex)`
  max-width: 800px;
  margin: 40px auto 0;
  width: 100%;
  flex: none;
  @media screen and (max-width: 52em) {
    display: none;
  }
  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const InnerContainer = styled(Flex)`
  max-width: 850px;
  margin: 0 auto;
  flex-direction: column;
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const InfoBox = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${(props) => props.theme.color.darkBluw};
  justify-content: center;
  max-width: 550px;
`;
const cipher = (salt) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return (text) =>
    text
      .split('')
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join('');
};

const decipher = (salt) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return (encoded) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join('');
};
class ScheduleMeno extends Component {
  constructor(props, context) {
    super(props, context);
    if(props && props.session && props.session.gender!=='F') {
      props.history.push('/')
    }
    let locationState = undefined;
    this.wizard = React.createRef();
    let canSkip = false;
    let hasLabs = false
    if (this.props.location && this.props.location.search && this.props.location.search.indexOf('?skp=true') > -1) {
      canSkip = true;
    }
    if (this.props.session && this.props.session.otbex && this.props.session.otbex === 999) {
      canSkip = true;
    }
    if (this.props.location && this.props.location.state && this.props.location.state.version === 'reschedule') {
      locationState = this.props.location.state;
      canSkip = true;
    }
    if (this.props.location && this.props.location.search && this.props.location.search.indexOf('hasLabs=true') > -1) {
      hasLabs = true;
    }

    let ins = {};
    if (this.props.location && this.props.location.state && !!this.props.location.state.forceUseOnFile) {
      ins = {
        onFileInsurance: {
          ...this.props.location.state.onFileOnsurance,
          use: true,
        },
        selectedProvider: {
          value: this.props.location.state.onFileOnsurance.insuranceCompany,
        },
      };
    }
    if (this.props.location && this.props.location.state && !!this.props.location.state.forceNewInsurance) {
      console.log('use on file', this.props.location.state.onFileOnsurance);
      ins = {
        newInsurance: {
          planType: `${this.props.location.state.newInsurance.provider} - ${this.props.location.state.newInsurance.planType}`,
          email: this.props.session.email,
          primarySubscriberFullName: `${this.props.session.firstName} ${this.props.session.lastName}`,
          dob: this.props.session.dob,
          conversationId: this.props.location.state.newInsurance.conversationId,
        },
        selectedProvider: {
          value: this.props.location.state.selectedProvider.value,
        },
      };
    }
    
    this.state = {
      ...initalState,
      locationState,
      isAdmin: this.props.isAdmin || false,
      hasPastAppointments: false,
      pastHistory: null,
      addMembership: null,
      ...ins,
      quadPaySubscribedData: undefined,
      pleaseWait: false,
      canSkip,
      hasLabs,
      hasInNetwork: undefined,
      startedAsMember: this.props.session.isMember,
      needCompletion: !!this.props.session && !!this.props.session.isMember && this.props.session.membershipSpecific === 'insurance' && this.props.session.needInsuranceCompletion,
    };
    this.loadExistingAppointment();
  }

  componentDidMount = async () => {
    try {
      await this.props.client.mutate({
        mutation: SEND_KLAVIYO_STATUS,
        variables: {
          step: 'Browsing Schedule',
        },
      });
    } catch (error) {}
  };

  heartbeat = () => {
    try {
      if (this.props.heartbeat) {
        this.props.heartbeat();
      }
    } catch (error) {}
  };

  setInsuranceCard = (planType, cardFront, cardBack, wizard, email, primarySubscriberFullName, dob, conversationId) => {
    this.setState({
      newInsurance: {
        planType,
        cardBack,
        cardFront,
        email,
        primarySubscriberFullName,
        dob,
        conversationId,
      },
    });

    this.wizard.current.push('pickDoctor');
  };

  storeCard = (values) => {
    this.setState({
      storedCard: {
        ...values,
      },
    });
  };

  setQuadPayData = (accountNumber, cvc, expMonth, expYear, quadpayOrderReference, callback) => {
    this.setState(
      {
        quadPaySubscribedData: {
          accountNumber,
          cvc,
          expMonth,
          expYear,
          quadpayOrderReference,
        },
        pleaseWait: true,
      },
      async () => {
        if (!!this.state.quadPaySubscribedData) {
          await this.props.client.mutate({
            mutation: QUADPAY_SUBSCRIBED,
            variables: {
              ...this.state.quadPaySubscribedData,
            },
            refetchQueries: [{ query: CURRENT_USER }],
          });

          this.props.analytics.track('QuadPay - Membership', {
            orderReference: quadpayOrderReference,
          });
        }

        if (!!callback) {
          callback();
        }
      }
    );
  };

  loadHasPastAppointments = async () => {
    const { isAdmin, enforcePatientId } = this.state;
    let hasPast = false;
    let pastHistory;
    if (isToggleActive('USE_FOLLOW_UP_15')) {
      try {
        const history = await this.props.client.query({
          query: isAdmin ? ADMIN_APPOINTMENTS_HISTORY_FOR_PATIENT : APPOINTMENTS_HISTORY,
          variables: isAdmin
            ? {
                patientId: enforcePatientId,
              }
            : null,
        });
        if (isAdmin && history.data && history.data.adminGetPatientAppointmentsHistory) {
          pastHistory = history.data.adminGetPatientAppointmentsHistory.filter((x) => 
          x.status &&
           (x.status === '4' || x.status === '3' || x.status === '2')
           && x.appointmentTypeId && (x.appointmentTypeId === window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID || x.appointmentTypeId === window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID));
          hasPast = pastHistory.length > 0;
        }
        if (!isAdmin && history.data && history.data.getPatientAppointmentsHistory) {
          
          pastHistory = history.data.getPatientAppointmentsHistory.filter((x) => 
          x.status &&
           (x.status === '4' || x.status === '3' || x.status === '2')
           && x.appointmentTypeId && (x.appointmentTypeId === window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID || x.appointmentTypeId === window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID));
          
          hasPast = pastHistory.length > 0;
          this.setState({ canSkip: !!this.state.canSkip });
        }

        // hasPast = true;
      } catch {}
    }
    this.setState({ hasPastAppointments: hasPast, pastHistory });
  };

  loadExistingAppointment = async () => {
    const { location, history, isAdmin, session } = this.props;
    if (isAdmin) {
      const locationState = this.state.locationState || this.props.location.state;
      if (!locationState) {
        history.push('/consultations/');
        return;
      } else {
        const upcomingAppts = await this.props.client.query({
          query: ADMIN_UPCOMING_APPOINTMENTS_FOR_PATIENT,
          variables: {
            patientId: locationState.patientId,
          },
        });

        if (upcomingAppts && upcomingAppts.data && upcomingAppts.data.upcomingAppointmentsForPatient && upcomingAppts.data.upcomingAppointmentsForPatient.length > 0) {
          let reschedulingAppointment;
          for (let index = 0; index < upcomingAppts.data.upcomingAppointmentsForPatient.length; index++) {
            const element = upcomingAppts.data.upcomingAppointmentsForPatient[index];
            if (element.id.toString() === locationState.oldId.toString()) {
              reschedulingAppointment = element;
            }
          }

          if (!reschedulingAppointment) {
            history.push('/consultations/');
          }
          await this.loadImpersonatedPatient(reschedulingAppointment, locationState.patientId);

          await this.loadHasPastAppointments();
        } else {
          history.push('/consultations/');
        }
      }

      return;
    }

    // if (session.intakeStatus === 'disqualified') {
    //   history.push(`/`);
    //   return;
    // }

    const a = await this.props.client.query({
      query: UPCOMING_APPOINTMENTS,
      fetchPolicy: 'network-only',
    });

    if (
      a &&
      a.data &&
      a.data.upcomingAppointments &&
      a.data.upcomingAppointments.filter(
        (x) => x.appointmentTypeId === window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID && x.appointmentTypeId === window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID
      ).length > 0
    ) {
      if (!location || !location.state || !location.state.oldId || location.state.version !== 'reschedule') {
        history.push(`/consultations/${a.data.upcomingAppointments[0].id}`);
      }
    }

    this.setState({
      isAlreadyBookedAppointmentLoaded: true,
      reschedulingAppointment: a && a.data && a.data.upcomingAppointments && a.data.upcomingAppointments.length > 0 ? a.data.upcomingAppointments[0] : undefined,
    });

    await this.loadHasPastAppointments();
  };

  loadImpersonatedPatient = async (reschedulingAppointment, patientId) => {
    const impersonatedPatient = await this.props.client.query({
      query: GET_PATIENT_BY_ID,
      variables: {
        patientId: patientId,
      },
    });

    this.setState({
      isAlreadyBookedAppointmentLoaded: true,
      reschedulingAppointment,
      impersonatedPatient: impersonatedPatient.data.getPatientById,
      enforcePatientId: patientId,
    });
  };

  handleSelect = (slot) => {
    this.setState({
      isConfirmOpen: true,
      slot: slot,
    });
  };

  parseCustomDate = (date, time, timezoneOffset) => {
    var dateParts = date.split('/');

    var st = dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1];
    var st2 = `${st}T${time}:00.000${this.timezoneOffsetFormatter(timezoneOffset)}00`;

    var dt = new Date(st2);

    return dt;
  };

  parseCustomDateLocal = (date, time, timeZoneName) => {
    var dateParts = date.split('/');

    var st = dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1];
    var st2 = `${st} ${time}`;

    return moment.tz(st2, timeZoneName);
  };

  timezoneOffsetFormatter = (timezoneOffset) => {
    if (timezoneOffset === 0) return '+00';
    if (timezoneOffset < 0) {
      const t = timezoneOffset * -1;
      if (timezoneOffset > -10) {
        return `-0${t}`;
      }
      return `-${t}`;
    }

    if (timezoneOffset > 0) {
      if (timezoneOffset < 10) {
        return `+0${timezoneOffset * 1}`;
      }
      return `+${timezoneOffset * 1}`;
    }

    return '+00';
  };

  addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
  };

  handlePayment = async (wizard, callback, couldntConfirmPayment = false, subscribedQuadPay = false) => {
    await this.handleConfirm(wizard, callback, couldntConfirmPayment, subscribedQuadPay);
  };

  generateInitiateConversationVariables = (
    appointmentDate,
    planType,
    patientState,
    doctor,
    email,
    // appointmentId,
    thread,
    subscriberFullName,
    subscriberDob,
    conversationId
  ) => {
    return {
      planType,
      thread: {
        ...thread,
      },
      state: patientState,
      doctor,
      email,
      // appointmentId,
      appointmentDate,

      subscriberFullName,
      subscriberDob,
      conversationId,
    };
  };

  sendInsuranceInfo = async (patientId) => {
    // console.log('STATE sendInsuranceInfo', this.state);
    this.setState({ processing: true });
    const appointmentDate = moment(`${this.state.selectedAppointment.slot.date} ${this.state.selectedAppointment.slot.startTime}`, 'MM/DD/YYYY HH:mm').format('MM-DD-YYYY hh:mma');
    const preVars = {
      cardFront: this.state.newInsurance.cardFront,
      cardBack: this.state.newInsurance.cardBack,
      planType: this.state.newInsurance.planType,
      subscriberFullName: this.state.newInsurance.primarySubscriberFullName,
      subscriberDob: this.state.newInsurance.dob,
      provider: (this.state.selectedProvider || {}).value,
      patientId,
      patientState: this.props.session.address.state, //id
      patientEmail: this.props.session.email,
      doctor: `${this.state.selectedAppointment.doctor.firstName} ${this.state.selectedAppointment.doctor.lastName}`,
      consultationDate: appointmentDate,
      conversationId: this.state.newInsurance.conversationId,
    };

    const attachments = [];

    if (this.state.newInsurance.cardFront) {
      attachments.push({
        ...this.state.newInsurance.cardFront,
        data: this.state.newInsurance.cardFront.data.substr(this.state.newInsurance.cardFront.data.indexOf(',') + 1),
      });
    }

    if (this.state.newInsurance.cardBack) {
      attachments.push({
        ...this.state.newInsurance.cardBack,
        data: this.state.newInsurance.cardBack.data.substr(this.state.newInsurance.cardBack.data.indexOf(',') + 1),
      });
    }
    let thread = {
      text: `Insurance information ${attachments.length > 0 ? 'following:' : 'not sent'}`,
      attachments: attachments,
    };

    const vars = this.generateInitiateConversationVariables(
      preVars.consultationDate,
      `${preVars.provider} - ${preVars.planType}`,
      preVars.patientState,
      preVars.doctor,
      preVars.patientEmail,
      thread,
      preVars.subscriberFullName,
      preVars.subscriberDob,
      preVars.conversationId
    );

    try {
      await this.props.client.mutate({
        mutation: SEND_INSURANCE,
        variables: {
          ...vars,
        },
        refetchQueries: [{ query: CURRENT_USER }],
      });
      this.setState({ processing: false });
      this.jumpToStep('complete');
      // console.log('send insurance res', res);
    } catch (error) {
      this.setState({ processing: false });
      console.log(error);
    }
  };

  handleConfirm = async (wizard, callback, couldntConfirmPayment, subscribedQuadPay) => {
    const { selectedAppointment, locationState, enforcePatientId, impersonatedPatient, storedCard, addMembership, quadPaySubscribedData, hasLabs } = this.state;
    console.log('handle confirm', quadPaySubscribedData);
    const { toastManager, session, isAdmin, client } = this.props;
    if (!!storedCard) {
      const res = await client.mutate({
        mutation: UPDATE_ATHENA_CARD,
        variables: {
          accountNumber: storedCard.accountNumber,
          cvc: storedCard.cvc,
          expMonth: storedCard.expMonth,
          expYear: storedCard.expYear,
          zip: storedCard.zip || null,
          street1: storedCard.street1 || null,
          addMembership: !!addMembership && !subscribedQuadPay ? addMembership : null,
        },
        refetchQueries: [{ query: ATHENA_CARD }],
      });
    }

    let hasPast = false;

    // TODO Meno: Check if tickler for 30 minutes
    if (isToggleActive('USE_FOLLOW_UP_15')) {
      try {
        const history = await this.props.client.query({
          query: isAdmin ? ADMIN_APPOINTMENTS_HISTORY_FOR_PATIENT : APPOINTMENTS_HISTORY,
          variables: isAdmin
            ? {
                patientId: enforcePatientId,
              }
            : null,
        });
        const correctedProviderId = selectedAppointment.slot.providerId.toString() === '11' ? '10' : selectedAppointment.slot.providerId.toString();
        if (isAdmin && history.data && history.data.adminGetPatientAppointmentsHistory) {
          hasPast =
            history.data.adminGetPatientAppointmentsHistory.filter(
              (x) => x.status && (x.status === '4' || x.status === '3') && x.provider && (x.provider.id.toString() === '11' ? '10' : x.provider.id.toString()) === correctedProviderId.toString()
            ).length > 0;
        }
        if (!isAdmin && history.data && history.data.getPatientAppointmentsHistory) {
          hasPast =
            history.data.getPatientAppointmentsHistory.filter(
              (x) => x.status && (x.status === '4' || x.status === '3') && x.provider && (x.provider.id.toString() === '11' ? '10' : x.provider.id.toString()) === correctedProviderId.toString()
            ).length > 0;
        }
      } catch {}
    }

    let athenaInsuranceId;

    let payload = {};
    let duration = 15;

    if (isToggleActive('USE_FOLLOW_UP_15')) {
      duration = hasPast ? 15 : 30;
    }

    var dateParts = selectedAppointment.slot.date.split('/');

    var st = dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1];
    const date2 = moment.tz(`${st} ${selectedAppointment.slot.startTime}`, session.department.timeZoneName);

    // todo FD > The Add x minutes should be based on the appt duration
    const date3 = moment.tz(`${st} ${selectedAppointment.slot.startTime}`, session.department.timeZoneName).add(duration, 'minutes');

    const startDateUTC = date2.utc();
    const endDateUTC = date3.utc();

    const departmentId = isAdmin ? (impersonatedPatient ? impersonatedPatient.departmentId : 1) : session ? session.department.departmentId : 1;
    // return;
    // console.log('reschedule', enforcePatientId);
    if (locationState && locationState.version === 'reschedule') {
      const addr = isAdmin ? (impersonatedPatient ? impersonatedPatient.address : {}) : session ? session.address : {};
      payload = {
        mutation: isAdmin ? RESCHED_APPT_FOR_PATIENT_MENO : RESCHED_APPT_MENO,
        variables: {
          id: locationState.oldId.toString(),
          newId: selectedAppointment.slot.id.toString(),
          patientId: isAdmin ? parseInt(enforcePatientId.toString(), 10) : null,
          appointmentDetails: {
            startDate: startDateUTC.format('YYYY-MM-DD HH:mm'),
            endDate: endDateUTC.format('YYYY-MM-DD HH:mm'),
            subject: `Paloma ${addr ? addr.state + ' ' : ''} patient`,
            htmlMessage: '',
            inviteSubject: `Paloma ${addr ? addr.state + ' ' : ''} patient`,
            inviteDescription: '',
            inviteLocation: `<a href="https://app.palomahealth.com/visit-room/${selectedAppointment.slot.providerId}">${
              (selectedAppointment.doctor || '').zocdocVideoLink.indexOf('whereby.com') < 0 ? 'ZocDoc' : 'Whereby'
            } ${session.address ? session.address.state + ' ' : ''} ${session.department.timeZoneName}</a>`,
            providerId: selectedAppointment.slot.providerId,
            departmentId: parseInt(departmentId.toString(), 10),
          },
        },
      };
    } else {
      payload = {
        mutation: BOOK_APPT_MENO,
        variables: {
          id: selectedAppointment.slot.id.toString(),
          appointmentDetails: {
            startDate: startDateUTC.format('YYYY-MM-DD HH:mm'),
            endDate: endDateUTC.format('YYYY-MM-DD HH:mm'),
            subject: `Paloma ${session.address ? session.address.state + ' ' : ''} patient`,
            htmlMessage: '',
            inviteSubject: `Paloma ${session.address ? session.address.state + ' ' : ''} patient`,
            inviteDescription: '',
            inviteLocation: `<a href="https://app.palomahealth.com/visit-room/${selectedAppointment.slot.providerId}">${
              (selectedAppointment.doctor || '').zocdocVideoLink.indexOf('whereby.com') < 0 ? 'ZocDoc' : 'Whereby'
            } ${session.address ? session.address.state + ' ' : ''} ${session.department.timeZoneName}</a>`,
            providerId: selectedAppointment.slot.providerId,
            departmentId: parseInt(session.department.departmentId, 10),
            force30: hasLabs
          },
        },
      };
    }

    this.setState({
      loading: true,
      isConfirmOpen: false,
    });

    let refetchQueries = [];

    if (isAdmin) {
      refetchQueries = [{ query: ADMIN_GET_APPOINTMENTS_LIST }, { query: CURRENT_USER }];
    } else {
      refetchQueries = [{ query: APPOINTMENTS }, { query: UPCOMING_APPOINTMENTS }, { query: GET_MY_WAITLIST }, { query: CURRENT_USER }];
    }
    try {
      const mut = await this.props.client.mutate({
        ...payload,
        refetchQueries,
        awaitRefetchQueries: true,
      });

      let appointmentTypeId = 0;
      let appointmentId = 0;
      try {
        if (locationState && locationState.version === 'reschedule') {
          if (isAdmin) {
            appointmentTypeId = mut.data.rescheduleAppointmentForPatientMeno.appointmentTypeId;
            appointmentId = mut.data.rescheduleAppointmentForPatientMeno.id;
          } else {
            appointmentTypeId = (mut.data.rescheduleAppointmentMeno || mut.data.ReschedApptMeno).appointmentTypeId;
            appointmentId = (mut.data.rescheduleAppointmentMeno || mut.data.ReschedApptMeno).id;
          }
        } else {
          appointmentTypeId = mut.data.bookAppointmentMeno.appointmentTypeId;
          appointmentId = mut.data.bookAppointmentMeno.appointmentId;
        }
      } catch (error) {}
      try {
        if (addMembership) {
          const ft_membership_price_2024 = isToggleActive('MEMBERSHIP_PRICE_2024');
          this.props.analytics.track('membership sign up', {
            frequency: addMembership === 'mi' ? 'mi' : 'ma',
            content_ids: addMembership === 'mi' ? 'mi' : 'ma',
            value: addMembership === 'mi' ? '96.00' : `${!ft_membership_price_2024 ? Constants.MEMBERSHIP_PRICE : Constants.MEMBERSHIP_PRICE_2024}.00`,
            trial: false,
          });
        }
      } catch (error) {}
      try {
        const hasNewPriceFeature = isToggleActive('USE_NEW_PRICING');
        const opts = {
          providerId: selectedAppointment.slot.providerId,
          departmentId: departmentId.toString(),
          startDate: startDateUTC.format('YYYY-MM-DD HH:mm'),
          endDate: endDateUTC.format('YYYY-MM-DD HH:mm'),
          cost: (session && session.isMember) || addMembership || !!this.state.quadPaySubscribedData ? `$${getMemberVisitPrice()}.00` : hasNewPriceFeature ? '$110.00' : '$99.00',
          copay: athenaInsuranceId && this.state.costEstimates ? `$${(this.state.costEstimates.costEstimate || 0) * 100}` : '',
          doctorName: `${(selectedAppointment.doctor || '').firstName} ${(selectedAppointment.doctor || '').lastName}`,
          appointmentTypeId,
          appointmentId,
        };
        this.props.analytics.track(locationState && locationState.version === 'reschedule' ? 'Appointment Rescheduled' : 'Appointment Booked', { ...opts });

        if (!locationState || locationState.version !== 'reschedule') {
          this.props.analytics.logRevenue(
            (session && session.isMember) || addMembership || !!this.state.quadPaySubscribedData ? getMemberVisitPriceNumber() : hasNewPriceFeature ? 110 : 99,
            (session && session.isMember) || addMembership || !!this.state.quadPaySubscribedData ? getMemberVisitPriceNumber() : hasNewPriceFeature ? 110 : 99,
            0,
            '',
            [
              {
                productId: 'Appointment Meno',
                name: 'Appointment Meno',
                price: (session && session.isMember) || addMembership || !!this.state.quadPaySubscribedData ? getMemberVisitPriceNumber() : hasNewPriceFeature ? 110 : 99,
                quantity: 1,
                category: 'Appointment Meno',
                departmentId: departmentId.toString(),
                startDate: startDateUTC.format('YYYY-MM-DD HH:mm'),
                doctorName: `${(selectedAppointment.doctor || '').firstName} ${(selectedAppointment.doctor || '').lastName}`,
                appointmentTypeId,
                appointmentId,
              },
            ],
            appointmentId
          );
        }
      } catch (error) {}

      if (couldntConfirmPayment) {
        await this.props.client.mutate({
          mutation: NOTIFY_COULDNT_CONFIRM_CARD,
          variables: {
            patientId: isAdmin ? enforcePatientId.toString() : null,
          },
        });
      }
      this.setState(
        {
          // ...initalState,
          slot: selectedAppointment.slot,
          isConfirm: true,
        },
        () => {
          if (!!this.state.newInsurance) {
            this.heartbeat();

            if (this.state.addMembership === 'mi') {
              this.props.client.mutate({
                mutation: INSURANCE_MEMBER_SAVE_TMP_INS_DATA,
                variables: {
                  planType: `${(this.state.selectedProvider || {}).value} - ${this.state.newInsurance.planType}`,
                },
                refetchQueries: [{ query: CURRENT_USER }],
              });
            }

            this.wizard.current.push('uploadInsuranceCard');
            // this.jumpToStep('uploadInsuranceCard');
            return;
          } else {
            this.jumpToStep('complete');
            return;
          }
          // !!newInsurance ? 'uploadCard' :
          // this.nextStep(wizard);
        }
      );
    } catch (error) {
      console.log('ERROR BKG', error);
      toastManager.add('Error booking appointment', { appearance: 'error' });
      if (callback) {
        setTimeout(() => {
          callback();
        }, 300);
      }
    }
  };

  handleClose = () => {
    const { isConfirmOpen } = this.state;

    this.setState({
      isConfirmOpen: !isConfirmOpen,
      slot: {},
    });
  };

  onProviderSelected = (provider, wizard) => {
    this.heartbeat();
    // console.log('provider', provider);
    this.setState(
      {
        isInNetworkProviderSelected: true,
        selectedProvider: provider,
        insuranceInfo: undefined,
      },
      () => {
        this.nextStep(wizard);
      }
    );
  };

  onNonSupportedProviderSelected = (provider, wizard) => {
    this.heartbeat();
    this.setState(
      {
        isInNetworkProviderSelected: false,
        selectedProviderNonSupported: provider,
        insuranceInfo: undefined,
      },
      () => {
        wizard.push('insuranceBenefitsCheck');
      }
    );
  };

  onSubmitInsuranceInfo = (values, wizard) => {
    this.heartbeat();
    this.setState(
      {
        insuranceInfo: {
          ...values,
        },
      },
      () => {
        this.nextStep(wizard);
      }
    );
  };

  onCoverageChecked = (coverage, athenaPackageId, wizard) => {
    this.heartbeat();
    this.setState(
      {
        coverage,
        athenaPackageId,
      },
      this.nextStep(wizard)
    );
  };

  onAppointmentSelection = (doctor, slot, isInNetwork, wizard, hasInNetwork) => {
    this.setState(
      {
        selectedAppointment: {
          doctor,
          slot,
          isInNetwork,
          hasInNetwork,
        },
      },
      () => {
        if ((this.props.session && this.props.session.isMember) || !!this.state.canSkip) {
          this.heartbeat();
          this.wizard.current.push('payment');
        } else {
          this.jumpToStep('selectMemberships', wizard);
        }
      }
    );
  };

  nextStep = (wizard) => {
    this.heartbeat();
    wizard.next();
  };

  previousStep = (wizard) => {
    this.heartbeat();
    wizard.previous();
  };

  reinitFlow = (wizard) => {
    this.setState(
      {
        selectedProvider: null,
        selectedProviderNonSupported: null,
        selectedAppointment: null,
        coverage: null,
      },
      () => {
        this.heartbeat();
        wizard.replace('pickProvider');
      }
    );
  };

  bypassInsurance = (wizard) => {
    this.heartbeat();
    wizard.push('pickDoctor');
    this.setState({
      selectedProvider: null,
      selectedProviderNonSupported: null,
      onFileInsurance: {
        ...this.state.onFileInsurance,
        use: false,
      },
    });
  };

  bypassInsuranceKeepProvider = (wizard) => {
    this.setState({
      selectedProviderNonSupported: null,
      onFileInsurance: {
        ...this.state.onFileInsurance,
        use: false,
      },
    });
    this.heartbeat();
    wizard.push('pickDoctor');
  };

  jumpToStep = (step, wizard) => {
    this.heartbeat();
    if (isNaN(step)) {
      this.wizard.current.push(step);
    }
    switch (step) {
      case 0:
        wizard.push('pickProvider');
        break;
      case 1:
        wizard.push('pickDoctor');
        break;
      default:
        break;
    }
  };

  reinitData = () => {
    this.setState({
      selectedProvider: null,
      selectedProviderNonSupported: null,
      selectedAppointment: null,
      coverage: null,
    });
  };

  setOnFileInsurance = (onFileInsurance, use) => {
    this.setState({
      onFileInsurance: {
        ...onFileInsurance,
        use,
      },
    });
  };

  useOnFileInsurance = (onFileInsurance, wizard) => {
    // console.log('useOnFileInsurance onfileIns', onFileInsurance);
    this.setState(
      {
        onFileInsurance: {
          ...onFileInsurance,
          use: true,
        },
        selectedProvider: {
          value: onFileInsurance.insuranceCompany,
        },
      },
      () => {
        this.heartbeat();
        // console.log('useOnFileInsurance w');
        if (wizard) {
          wizard.push('pickDoctor');
        }
      }
    );
  };

  setCostEstimates = (costEstimates) => {
    this.setState({
      costEstimates,
    });
  };

  createSubscription = async (frequency) => {
    let priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_MONTHLY;
    let freq = frequency === 'annualy' ? 'ma' : frequency === 'insurance' ? 'mi' : 'mm';

    const ft_membership_price_2024 = isToggleActive('MEMBERSHIP_PRICE_2024');
    if (frequency === 'annualy') {
      if (ft_membership_price_2024) {
        priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL_2024;
      } else {
        priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL;
      }
    } else if (frequency === 'insurance') {
      priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_INSURANCE;
    } else {
      priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_MONTHLY;
    }

    const createSession = await this.props.client.mutate({
      mutation: MEMBERSHIP_CREATE_SESSION,
      variables: {
        priceId: priceId,
        forwardUrl: `subscribed/book/success/${freq}`,
        cancel_url: 'schedule',
      },
    });

    const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_KEY);
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({
      sessionId: createSession.data.createCheckoutSession.sessionId,
    });
  };

  InsuranceMemberChanged = async () => {
    const { history } = this.props;
    history.push('/insurance-member/change-insurance');
  };
  insuranceMemberLost = async () => {
    const { client, history } = this.props;
    const res = await client.mutate({
      mutation: INSURANCE_MEMBER_LOST_COVERAGE,
    });

    history.push('/insurance-member/will-be-in-touch');
  };

  pickInsuranceHasLoaded = () => {
    this.setState({
      startedWithPickProvider: true,
    });
  };

  render() {
    const {
      isConfirmOpen,
      slot,
      selectedAppointment,
      selectedProvider,
      insuranceInfo,
      selectedProviderNonSupported,
      coverage,
      athenaPackageId,
      onFileInsurance,
      isConfirm,
      isAlreadyBookedAppointmentLoaded,
      reschedulingAppointment,
      locationState,
      impersonatedPatient,
      hasPastAppointments,
      pastHistory,
      newInsurance,
      canSkip,
      hasLabs,
      startedWithPickProvider,
      needCompletion,
    } = this.state;
    const { session, isAdmin, history } = this.props;
    const t = isToggleActive('INSURANCE_MEMBERSHIP_WIND_DOWN');
    const prompt = session.intakeStatus === 'completed' && !isAdmin;
    console.log({ session, startedWithPickProvider });

    const enforcePatientId = isAdmin && locationState ? locationState.patientId : null;

    let duration = 15;

    // TODO Meno: Check ticklers
    // if (isToggleActive('USE_FOLLOW_UP_15')) {
    //   if (hasPastAppointments && selectedAppointment && selectedAppointment.doctor) {
    //     let hasPast =
    //       pastHistory.filter(
    //         (x) =>
    //           x.provider &&
    //           (x.provider.id.toString() === '11' ? '10' : x.provider.id.toString()) === (selectedAppointment.doctor.id.toString() === '11' ? '10' : selectedAppointment.doctor.id.toString())
    //       ).length > 0;
    //     duration = hasPast ? 15 : 30;
    //   }
    // }

    if (!isAlreadyBookedAppointmentLoaded && !isConfirm) {
      return (
        <>
          <Container>
            <InnerContainer p={3} mt={2}>
              <Flex flex={1} justifyContent="center" alignItems="center" flexDirection={'column'} mt={4}>
                <SpinLoader />
                <Text textAlign="center" mt={4} mb={4} fontSize="18px">
                  Initialization
                </Text>
              </Flex>
            </InnerContainer>
          </Container>
        </>
      );
    }

    if (!!needCompletion && !startedWithPickProvider) {
      return (
        <>
          <Container>
            <InnerContainer p={3} mt={2}>
              <Card p={4} mb={4}>
                <Flex mb={4} flexDirection="column">
                  <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                    <Flex flexDirection={'column'}>
                      <Heading mt={4} mb={4} textAlign={'center'}>
                        Let's get your insurance information
                      </Heading>
                      <Text textAlign="center">You are benefiting from a reduced membership fee for using your insurance on doctor visits. Please add your insurance details on the next screen</Text>

                      <Button variant="pink" mt={4} onClick={() => history.push('/my-insurances/add')}>
                        Continue
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </InnerContainer>
          </Container>
        </>
      );
    }

    const stepsVal = [
      {
        comp: (
          <Step id="pickProvider" key="pickProvider">
            <Container>
              <InnerContainer>
                <PickInsuranceProvider
                  reinitData={this.reinitData}
                  bypassInsurance={this.bypassInsurance}
                  selectedProvider={this.state.selectedProvider}
                  onProviderSelected={this.onProviderSelected}
                  setOnFileInsurance={this.setOnFileInsurance}
                  useOnFileInsurance={this.useOnFileInsurance}
                  onNonSupportedProviderSelected={this.onNonSupportedProviderSelected}
                  isAdmin={isAdmin}
                  enforcePatientId={enforcePatientId}
                  session={session}
                  InsuranceMemberChanged={this.InsuranceMemberChanged}
                  insuranceMemberLost={this.insuranceMemberLost}
                  hasLoaded={this.pickInsuranceHasLoaded}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'pickProvider',
        display: !session.b2b || (!session.b2b.visits || !session.b2b.isActive),
      },
      {
        comp: (
          <Step id="insuranceBenefitsCheck" key="insuranceBenefitsCheck">
            <Container>
              <InnerContainer>
                <InsuranceBenefitsCheck
                  departmentInitials={this.props.session.address.state}
                  setInsuranceCard={this.setInsuranceCard}
                  isAdmin={isAdmin}
                  enforcePatientId={enforcePatientId}
                  impersonatedPatient={impersonatedPatient}
                  onFileInsurance={onFileInsurance}
                  reinitFlow={this.reinitFlow}
                  selectedProvider={selectedProvider}
                  insuranceInfo={insuranceInfo}
                  selectedProviderNonSupported={selectedProviderNonSupported}
                  patientEmail={this.props.session.email}
                  nextStep={this.nextStep}
                  previousStep={this.previousStep}
                  onCoverageChecked={this.onCoverageChecked}
                  bypassInsurance={this.bypassInsurance}
                  bypassInsuranceKeepProvider={this.bypassInsuranceKeepProvider}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'insuranceBenefitsCheck',
        display: !session.b2b || (!session.b2b.visits || !session.b2b.isActive),
      },
      {
        comp: (
          <Step id="pickDoctor" key="pickDoctor">
            <PickDoctorMeno
              {...this.props}
              selectedInsurance={
                onFileInsurance && onFileInsurance.use && (onFileInsurance.insuranceCompany || onFileInsurance.ircName)
                  ? onFileInsurance.insuranceCompany || formalizeInsuranceName((onFileInsurance.ircName || '').toLowerCase().replace('-', ' '))
                  : this.state.selectedProvider
                    ? this.state.selectedProvider.value
                    : null
              }
              hasLabs={hasLabs}
              isMenoFlow={true}
              selectedProvider={this.state.selectedProvider}
              reschedulingAppointment={reschedulingAppointment}
              onAppointmentSelection={this.onAppointmentSelection}
              isAdmin={isAdmin}
              coverage={this.state.coverage}
              enforcePatientId={enforcePatientId}
              impersonatedPatient={impersonatedPatient}
              duration={duration}
            />
          </Step>
        ),
        key: 'pickDoctor',
        display: true,
      },
      {
        comp: (
          <Step id="selectMemberships" key="selectMemberships">
            {/* <div>A</div> */}
            <Container>
              <InnerContainer>
                <SelectMembership
                  canSkip={canSkip}
                  insuranceAccepted={(!!onFileInsurance && !!onFileInsurance.use) || !!newInsurance}
                  onSubscribe={(wizard) => {
                    if (this.state && this.state.selectedAppointment && this.state.selectedAppointment.isInNetwork && this.state.selectedAppointment.hasInNetwork) {
                      this.setState({
                        addMembership: !t?'mi':'ma',
                      });
                      this.heartbeat();
                      this.wizard.current.push('payment');
                    } else {
                      this.heartbeat();
                      this.wizard.current.next();
                    }
                  }}
                  onGoBack={(wizard) => {
                    this.previousStep(wizard);
                  }}
                  isInNetwork={this.state && this.state.selectedAppointment && this.state.selectedAppointment.isInNetwork}
                  hasInNetwork={this.state && this.state.selectedAppointment && this.state.selectedAppointment.hasInNetwork}
                  onContinue={(wizard) => {
                    this.setState({
                      addMembership: null,
                    });
                    this.heartbeat();
                    this.wizard.current.push('payment');
                  }}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'selectMemberships',
        display: !canSkip && !session.isMember && isToggleActive('MEMBERSHIP'),
      },
      {
        comp: (
          <Step id="selectPlan" key="selectPlan">
            {/* <div>A</div> */}
            <Container>
              <InnerContainer>
                <SelectPlan
                  isInNetwork={this.state && this.state.selectedAppointment && this.state.selectedAppointment.isInNetwork}
                  hasInNetwork={this.state && this.state.selectedAppointment && this.state.selectedAppointment.hasInNetwork}
                  reinitFlow={this.reinitFlow}
                  selectedProvider={this.state.selectedAppointment}
                  setQuadPayData={this.setQuadPayData}
                  onSubscribe={(frequency) => {
                    if (this.state && this.state.selectedAppointment && this.state.selectedAppointment.isInNetwork && this.state.selectedAppointment.hasInNetwork) {
                      this.setState({
                        addMembership: !t?'mi':'ma',
                      });
                      this.heartbeat();
                      this.wizard.current.push('payment');
                    } else {
                      this.setState({
                        addMembership: !frequency ? null : frequency === 'monthly' ? 'mm' : frequency === 'insurance' && !t ? 'mi' : 'ma',
                      });
                      this.heartbeat();
                      this.wizard.current.push('payment');
                    }
                    // this.createSubscription(frequency);
                  }}
                  onContinue={(wizard) => {
                    this.setState({
                      addMembership: null,
                    });
                    this.wizard.current.push('payment');
                  }}
                  continueWithQuadPay={() => {
                    this.wizard.current.next();
                  }}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'selectPlan',
        display: !session.isMember && isToggleActive('MEMBERSHIP'),
      },
      {
        comp: (
          <Step id="welcomemembership" key="welcomemembership">
            <QuadpayComplete
              continueWithQuadPay={() => {
                this.heartbeat();
                this.wizard.current.push('payment');
              }}
            />
          </Step>
        ),
        key: 'welcomemembership',
        display: true,
      },
      {
        comp: (
          <Step id="payment" key="payment">
            <Container>
              <InnerContainer>
                <SchedulePayment
                  newInsurance={newInsurance}
                  athenaPackageId={athenaPackageId}
                  addMembership={this.state.addMembership}
                  {...this.props}
                  handlePayment={this.handlePayment}
                  selectedAppointment={selectedAppointment}
                  coverage={coverage}
                  insuranceInfo={insuranceInfo}
                  selectedProvider={selectedProvider}
                  reinitFlow={this.reinitFlow}
                  setCostEstimates={this.setCostEstimates}
                  onFileInsurance={onFileInsurance}
                  isAdmin={isAdmin}
                  enforcePatientId={enforcePatientId}
                  impersonatedPatient={impersonatedPatient}
                  duration={duration}
                  storeCard={this.storeCard}
                  quadPaySubscribedData={this.state.quadPaySubscribedData}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'payment',
        display: true,
      },
      {
        comp: (
          <Step id="uploadInsuranceCard" key="uploadInsuranceCard">
            <Container>
              <InnerContainer>
                <UploadInsuranceCard
                  processing={this.state.processing}
                  onSubmit={(front, back) =>
                    this.setState(
                      {
                        newInsurance: {
                          ...this.state.newInsurance,
                          cardFront: front,
                          cardBack: back,
                        },
                      },
                      async () => {
                        if (this.state.newInsurance) {
                          await this.sendInsuranceInfo(session.id);
                        }
                      }
                    )
                  }
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'uploadInsuranceCard',
        display: true,
      },
      {
        comp: (
          <Step id="complete" key="complete">
            <Container>
              <InnerContainer p={3}>
                <Complete
                  intakeDate={session.menoIntakeDate}
                  intakeStatus={session.menointakeStatus}
                  currentIntakeStatus={session.menointakeStatus}
                  isMeno={true}
                  prompt={prompt}
                  slot={selectedAppointment ? selectedAppointment.slot : null}
                  reinitFlow={this.reinitFlow}
                  history={this.props.history}
                  showInsuranceDisclaimer={this.state && this.state.selectedAppointment && this.state.selectedAppointment.isInNetwork && this.state.addMembership === 'mi'}
                  isInNetwork={this.state && this.state.selectedAppointment && this.state.selectedAppointment.isInNetwork}
                  hasInNetwork={this.state && this.state.selectedAppointment && this.state.selectedAppointment.hasInNetwork}
                />
              </InnerContainer>
            </Container>
          </Step>
        ),
        key: 'complete',
        display: true,
      },
    ];

    // console.log({ onFileInsurance });
    return (
      <Flex flex={1} flexDirection="column">
        <Wizard
          ref={this.wizard}
          history={history}
          basename="/schedulemeno"
          render={({ step, ...p }, a) => {
            return (
              <Fragment>
                {(step.id !== 'pickDoctor' || isConfirm) &&
                  step.id !== 'selectMemberships' &&
                  step.id !== 'selectPlan' &&
                  step.id !== 'welcomemembership' && (
                    <DesktopOnly>
                      <Stepper lockJump={isConfirm} wizard={p} jumpToStep={this.jumpToStep} currentStep={step.id} />
                    </DesktopOnly>
                  )}

                {step.id !== 'complete' &&
                  step.id !== 'uploadInsuranceCard' &&
                  isConfirm && (
                    <Container>
                      <InnerContainer p={3}>
                        <Complete
                          intakeDate={session.intakeDate}
                          intakeStatus={session.intakeStatus}
                          currentIntakeStatus={session.intakeStatus}
                          showInsuranceDisclaimer={this.state && this.state.selectedAppointment && this.state.selectedAppointment.isInNetwork && this.state.addMembership === 'mi'}
                          prompt={prompt}
                          saveInsCard={async (front, back, callback) => {
                            this.setState(
                              {
                                newInsurance: {
                                  ...this.state.newInsurance,
                                  cardFront: front,
                                  cardBack: back,
                                },
                              },
                              async () => {
                                // await this.sendInsuranceInfo(session.id);
                                callback();
                              }
                            );
                          }}
                          slot={selectedAppointment ? selectedAppointment.slot : null}
                          reinitFlow={this.reinitFlow}
                          history={this.props.history}
                        />
                      </InnerContainer>
                    </Container>
                  )}
                {((step.id !== 'complete' && !isConfirm) || step.id === 'complete' || step.id === 'uploadInsuranceCard') && <Steps>{stepsVal.filter((x) => x.display).map((x) => x.comp)}</Steps>}
              </Fragment>
            );
          }}
        />
        <Fragment>
          <ConfirmModal
            isOpen={isConfirmOpen}
            onClose={this.handleClose}
            onConfirm={() => {
              this.setState({ step: 'payment', isConfirmOpen: false });
            }}
            onBackgroundClick={this.handleClose}
            onEscapeKeydown={this.handleClose}
          >
            <ModalInner>
              <Heading mb={4} mx={3} textAlign="center">
                Confirm Your Visit
              </Heading>
              <InfoBox bg="#D9E6F9" mb={4}>
                <Text fontSize={3} fontWeight={700} textAlign="center">
                  {`${moment(slot.startTime, 'HH:mm').format('h:mma')} on 
                ${moment(slot.date, 'MM/DD/YYYY').format('dddd MMMM Do')}`}
                </Text>
              </InfoBox>
            </ModalInner>
          </ConfirmModal>
        </Fragment>
      </Flex>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  withToastManager,
  withAnalytics,
  withIsAdmin
)(withSession(ScheduleMeno));
