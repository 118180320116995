import React from 'react';
import Steps, { Step } from 'rc-steps';

const CustomStep = ({
  status,
  current,
  onClick,
  currentStep,
  canJumpBack,
  ...props
}) => {
  const type = current >= currentStep ? 'process' : 'wait';
  return (
    <Step
      style={{
        cursor: canJumpBack ? 'pointer' : 'not-allowed',
      }}
      onClick={() => {
        if (current >= currentStep && onClick && canJumpBack) {
          onClick();
        }
      }}
      status={type}
      currentStep={currentStep}
      {...props}
    />
  );
};

export const Stepper = props => {
  const { currentStep, jumpToStep, wizard, lockJump } = props;
  let curr = 0;
  console.log({ currentStep });
  switch (currentStep) {
    case 'pickProvider':
    case 'insurance':
    case 'insuranceBenefitsCheck':
      curr = 0;
      break;
    case 'pickDoctor':
      curr = 1;
      break;
    case 'payment':
    case 'selectMembership':
    case 'selectMemberships':
      curr = 2;
      break;
    case 'complete':
    case 'basic':
    case 'profile':
    case 'extended':
    case 'uploadInsuranceCard':
    case 'birthsex':
    case 'legalsex':
    case 'genderIdentity':
    case 'pronouns':
      case 'otp':
      curr = 3;
      break;

    default:
      break;
  }
  return (
    <Steps currentStep={curr} labelPlacement="vertical">
      <CustomStep
        onClick={() => jumpToStep(0, wizard)}
        canJumpBack={!lockJump && curr > 0}
        current={curr}
        currentStep={0}
        title="Insurance"
      />
      <CustomStep
        onClick={() => jumpToStep(1, wizard)}
        canJumpBack={!lockJump && curr > 1}
        current={curr}
        currentStep={1}
        title="Pick Clinician"
      />
      <CustomStep
        current={curr}
        currentStep={2}
        canJumpBack={!lockJump && curr > 2}
        title="Pay"
      />
      <CustomStep
        current={curr}
        currentStep={3}
        canJumpBack={false}
        title="Confirmation"
      />
    </Steps>
  );
};
