import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { Mutation, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Box, Button, Card, Heading, Text } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../../components/FullLayout';
import Logo from '../../components/Logo';
import {
  CREATE_SESSION_VITAMINS_SUBSCRIPTIONS_CHECKOUT_SESSION,
  CURRENT_USER,
  EMPTY_CALL,
} from '../../graphql';
import withAnalytics from '../../lib/withAnalytics';
import { useMount } from '../../utils/hooks/lifecycle';
import { isToggleActive } from '../../components/featureToggle/toggles';
import { Constants } from '../../utils/constants';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  padding-top: 40px;
  /* align-self: center; */
`;

const LogoContainer = styled(Box)`
  max-width: 200px;
  margin: 0 auto 32px auto;
`;

const SubscribedContinueVits = ({ history, client, match, analytics }) => {
  const hasTrial =
    history &&
    history.location &&
    history.location.search &&
    history.location.search.indexOf('q=trial') > -1;
  useMount(async () => {
    if (!match.params) return;
    analytics.track('membership sign up', {
      frequency: (match.params || {}).f,
      content_ids: (match.params || {}).f,
      value: !!hasTrial
        ? 0
        : (match.params || {}).f === 'mm'
          ? '20.00'
          : `${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}.00`,
      trial: hasTrial || false,
      trialLength: !!hasTrial ? window._env_.REACT_APP_MEMBERSHIP_TRIAL_LENGTH : '',
    });
    // analytics.logRevenue(
    //   (match.params || {}).f === 'mm' ? '20.00' : (match.params || {}).f === 'mi'?'96.00':'192.00',
    //   (match.params || {}).f === 'mm' ? '20.00' : (match.params || {}).f === 'mi'?'96.00':'192.00',
    //   0,
    //   '',
    //   [
    //     {
    //       productId: 'Membership',
    //       name: 'Membership',
    //       price: (match.params || {}).f === 'mm' ? '20.00' : (match.params || {}).f === 'mi'?'96.00':'192.00',
    //       quantity: 1,
    //       category: 'Membership',
    //       trial: hasTrial || false,
    //       trialLength: !!hasTrial ? window._env_.REACT_APP_MEMBERSHIP_TRIAL_LENGTH : '',
    //     }
    //   ],
    //   '',
    // )
    await client.mutate({
      mutation: EMPTY_CALL,
      refetchQueries: [{ query: CURRENT_USER }],
    });
  });

  return (
    <FullLayout2 style={{ zIndex: 10 }}>
      <Mutation
        mutation={CREATE_SESSION_VITAMINS_SUBSCRIPTIONS_CHECKOUT_SESSION}
        variables={{
          forwardUrl: 'my-subscriptions',
          cancel_url: 'subscribed/vitamins',
        }}
        onCompleted={async res => {
          let sessionId =
            res.createVitaminsSubscriptionsCheckoutSession.sessionId;

          if (!sessionId) {
            return;
          }
          const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_KEY);
          const stripe = await stripePromise;
          await stripe.redirectToCheckout({
            sessionId: sessionId,
          });
        }}
      >
        {(createSession, { loading }) => (
          <Container>
            <LogoContainer>
              <Logo dark={false} />
            </LogoContainer>
            <Card
              style={{
                flexDirection: 'column',
                textAlign: 'center',
                padding: '32px',
              }}
            >
              <Heading>Welcome to the Club!</Heading>

              <Text
                textAlign="center"
                style={{ maxWidth: '900px', margin: '32px auto 0 auto' }}
              >
                Now, as a Paloma Health Member, you can save $7 per month on
                your vitamin subscription.
              </Text>

              <Button
                disabled={loading}
                style={{ marginTop: '28px' }}
                variant="primary"
                onClick={createSession}
              >
                Checkout
              </Button>
            </Card>
          </Container>
        )}
      </Mutation>
    </FullLayout2>
  );
};

export default withAnalytics(withRouter(withApollo(SubscribedContinueVits)));
