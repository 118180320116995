import styled from 'styled-components';
import { Flex, Button } from 'rebass';

export const ButtonWithSelectionCircle = styled(Button)`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 300;

  box-sizing: border-box;
  transition: background 300ms ease-in;
  &:disabled {
    background-color: #E9E8E8;
    background: #E9E8E8;
  }
  &.selected {
    background: #e7f0ff;
    border: 1px solid #354e79;
  }
`;

export const ButtonWithSelectionCircleSelectedMarkerOut = styled(Flex)`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 2px solid #354e79;
  border-radius: 12px;
  margin-right: 12px;
`;

export const ButtonWithSelectionCircleSelectedMarkerIn = styled(Flex)`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #354e79;
  transition: all 450ms ease-in;
`;
