import React from 'react';
import IconContainer from './IconContainer';

const Check = props => (
  <IconContainer {...props}>
    <svg
      data-name="CheckIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 125.79 93.81"
    >
      <path
        d="M114.06,5.15a15,15,0,0,1,13.22,5.45c4.69,5.61,4.48,14.67-.74,19.86C105.1,51.88,83.36,73.62,62,95.05c-5.76,5.54-15.77,5-21.24-.76C30.48,84.18,20.35,73.93,10.15,63.78,5.87,59.65,3.64,53.53,5.52,47.71c1.94-7.5,10-12.2,17.51-10.54,3.5.56,6.3,2.53,8.76,5,6.68,6.74,13.43,13.41,20.1,20.16Q78.23,35.9,104.61,9.55A14.83,14.83,0,0,1,114.06,5.15Z"
        transform="translate(-4.85 -5.1)"
        fill={props.fill || '#fff'}
      />
    </svg>
  </IconContainer>
);

export default Check;
