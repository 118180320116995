import React from 'react';
import { Mutation } from 'react-apollo';
import { Flex, Button } from 'rebass';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormField from '../../components/FormField';
import TextInput from '../../components/TextInput';
import { ADD_FEATURE_TOGGLE, GET_FEATURE_TOGGLES_LIST } from '../../graphql';
import CheckboxInput from '../../components/CheckboxInput';

const schema = Yup.object().shape({
    friendlyName: Yup.string()
      .required('Required'),
    name: Yup.string()
      .trim()
      .required('Required'),
  });
const AddFeatureRow = props => {
  return (
    <Mutation
      mutation={ADD_FEATURE_TOGGLE}
      refetchQueries={[{ query: GET_FEATURE_TOGGLES_LIST }]}
    >
      {(mutate, { loading }) => (
        <Formik
          //   isInitialValid={({ initialValues }) =>
          //     schema.isValidSync(initialValues)
          //   }
          //   initialValues={props.values}
            validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            // console.log(values);
            mutate({variables: {
                ...values
            }})
            resetForm()
          }}
          render={({ isValid, errors, touched, values }) => (
            <Form>
              <Flex flexDirection="row">
                <FormField
                  width={1}
                  mb={3}
                  mr={[1]}
                  error={touched.firstNameFriend && errors.firstNameFriend}
                >
                  <TextInput
                    placeholder="Friendly Name"
                    type="text"
                    name="friendlyName"
                  />
                </FormField>
                <FormField
                  width={1}
                  mb={3}
                  mr={[1]}
                  error={touched.firstNameFriend && errors.firstNameFriend}
                >
                  <TextInput placeholder="Name" type="text" name="name" />
                </FormField>
                <FormField
                  width="250px"
                  flexDirection="row"
                  mb={3}
                  style={{ alignContent: 'center', justifyContent: 'center' }}
                  error={touched.consent && errors.consent}
                >
                  <CheckboxInput name="isActive" />
                </FormField>

                <Flex justifyContent="center" width={0.5}>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!isValid || loading}
                    id="btn_next"
                    width="100%"
                    style={{ height: '63px', minHeight: '63px' }}
                  >
                    Add
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )}
        />
      )}
    </Mutation>
  );
};

export default AddFeatureRow;
