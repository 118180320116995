import React from 'react';
import { Flex } from 'rebass';

export const LineBreakCtrl = ({ properties }) => {
  return (
    <>
      <Flex style={{ width: '100%', height: '2px', backgroundColor: '#344f79' }} />
    </>
  );
};
