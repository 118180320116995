import React from 'react';
import { Flex, Text } from 'rebass';

export const RangeCtrl = ({ properties }) => {
  return (
    <>
      <Flex flexDirection={'column'} flex={1 / 4}>
        <Text onClick={() => { }} fontWeight={500}>
          {`${(properties || {}).question || 'Question'}${((properties || {}).ctrlProps || {}).required ? '*' : ''}`}
        </Text>
        <Text fontSize={'12px'} fontWeight={300}>
          {(properties || {}).description || 'Description'}
        </Text>
      </Flex>
      <Flex flexDirection={'column'}>
        <input {...(properties || {}).ctrlProps} />
        <Flex>
          <Text>Min: {((properties || {}).ctrlProps || {}).min}</Text>
          <Text ml={3}>Max: {((properties || {}).ctrlProps || {}).max}</Text>
        </Flex>
      </Flex>
    </>
  );
};
