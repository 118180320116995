import React from 'react';
import { Button, Card, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { getLogoSource } from '../../../scheduleDoctor/helpers/insuranceLogos';
import { Query } from 'react-apollo';
import PleaseWait from '../../../../components/PleaseWait';
import { GET_INSURANCE_ON_FILE } from '../../../../graphql';
import { withWizard } from 'react-albus';

const FakeLink = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
  color: #44a5ff;
`;
export const InsuranceOnFile = ({ insurance, useOnFileInsurance, deleteOnFileInsurance }) => {
  return (
    insurance &&
    insurance.length > 0 &&
    insurance[0].insuranceIdNumber !== null &&
    insurance[0].insuranceIdNumber !== '' && (
      <Card p={4} mb={4}>
        <Flex mb={4} flexDirection="column">
          <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
            <Flex flexDirection={'column'}>
              <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                <div>
                  <img src={getLogoSource(insurance[0].insuranceCompany)} alt="" width="220px" />
                </div>
              </Flex>
              {insurance[0].insuranceIdNumber === 'TmpIns-1' ? (
                <Text fontWeight="600" textAlign="center" mt={4} fontSize="24px">
                  Your insurance information is under review by our team
                </Text>
              ) : (
                <>
                  <Text fontWeight="600" textAlign="center" mt={4} fontSize="24px">
                    We Have an Insurance on File
                  </Text>
                  <Text textAlign="center" mt={4} fontSize="18px">
                    {`Member ID: ${insurance[0].insuranceIdNumber}`}
                  </Text>
                </>
              )}

              <Button
                variant="pink"
                mt={4}
                onClick={() => {
                  useOnFileInsurance(insurance[0]);
                }}
              >
                {insurance[0].insuranceIdNumber === 'TmpIns-1' ? 'Continue' : 'Continue with this insurance'}
              </Button>

              {!!deleteOnFileInsurance && (
                <FakeLink mt={4} onClick={deleteOnFileInsurance}>
                  Change Insurance
                </FakeLink>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Card>
    )
  );
};

const CheckOnFile = ({ wizard, useOnFileInsurance, deleteOnFileInsurance, noInsurance }) => {
  console.log({ wizard });
  return (
    <Query query={GET_INSURANCE_ON_FILE}>
      {({ data, loading }) => {
        if (loading) {
          return <PleaseWait text="Initializing" />;
        }

        if (
          !data ||
          !data.getInsurancesOnFile ||
          data.getInsurancesOnFile.length < 1 ||
          data.getInsurancesOnFile.filter((x) => x.insuranceIdNumber !== 'TmpIns-3' && x.insuranceIdNumber !== 'TmpIns-1') < 1
        ) {
          noInsurance(wizard);

          return null;
        }

        return <InsuranceOnFile insurance={data.getInsurancesOnFile} useOnFileInsurance={useOnFileInsurance} deleteOnFileInsurance={deleteOnFileInsurance} wizard={wizard} />;
      }}
    </Query>
  );
};

export const CheckOnFileFlowWrapper = withWizard(CheckOnFile);
