import React from 'react';
import {
  Flex,
  Box,
  Text,
  Heading,
  Button,
} from 'rebass';
import styled from 'styled-components';
import {  Mutation } from 'react-apollo';
import {
  ADMIN_ADJUST_REFERRER_CREDITS,
  ADMIN_GET_REFERRAL_LIST,
  ADMIN_GET_REFERRAL_HISTORY,
} from '../../graphql';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import TextInput from '../../components/TextInput';

const schema = Yup.object().shape({
  amount: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

const ModalInner = styled(Box)`
  max-width: 500px;
  width: 100%;
`;

const AdminReferralAdjustmentModalInner = props => {
  const { onClose, referralLink } = props;
  return (
    <ModalInner>
      <Heading mb={4} mx={3} textAlign="center">
        Adjust {referralLink.name} Balance
      </Heading>
      <Text>
        Enter positive amount for increasing credits and negative to lower
        credits
      </Text>
      <Mutation
        mutation={ADMIN_ADJUST_REFERRER_CREDITS}
        refetchQueries={[
          { query: ADMIN_GET_REFERRAL_LIST },
          {
            query: ADMIN_GET_REFERRAL_HISTORY,
            variables: { referralId: referralLink.id },
          },
        ]}
        onCompleted={onClose}
      >
        {(mutate, { loading }) => (
          <Formik
            initialValues={{
              amount: null,
              description: '',
            }}
            validationSchema={schema}
            onSubmit={values => {
              // console.log(values);
              mutate({
                variables: {
                  centisizedAmount: values.amount * 100,
                  referralLinkId: referralLink.id,
                  description: values.description,
                },
              });
            }}
            render={({
              isValid,
              errors,
              touched,
              values,
              setFieldValue,
              submitForm,
            }) => (
              <Form>
                <Flex flexDirection={['column']} alignItems="center" mt={4}>
                  <FormField
                    width={[1, 1, 1 / 2]}
                    mb={3}
                    mr={[0, 0, 3]}
                    error={touched.description && errors.description}
                  >
                    <Text fontSize="14px" fontWeight={300} mb={2}>
                      Description:
                    </Text>
                    <TextInput
                      placeholder="Description"
                      type="text"
                      name="description"
                    />
                  </FormField>
                  <FormField
                    width={[1, 1, 1 / 2]}
                    mb={3}
                    error={touched.amount && errors.amount}
                  >
                    <Text fontSize="14px" fontWeight={300} mb={2}>
                      Amount (in Dollars):
                    </Text>
                    <TextInput
                      placeholder="Adjustement amount"
                      type="number"
                      name="amount"
                    />
                  </FormField>
                  <Flex
                    flexDirection={['column', 'column', 'row']}
                    mt={4}
                    justifyContent="center"
                  >
                    <Button
                      variant="outline"
                      ml={[0, 0, 3]}
                      type="button"
                      onClick={onClose}
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      ml={[0, 0, 3]}
                      onClick={submitForm}
                      type="button"
                    >
                      Save
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            )}
          />
        )}
      </Mutation>
    </ModalInner>
  );
};

export default AdminReferralAdjustmentModalInner;
