
export const recursiveChangeOperator = (obj, id, operator) => {
  if (!obj.data && !obj.id) {
    return obj;
  }

  if (obj.id === id) {
    obj.operator = operator;
    return obj;
  }

  if (obj.data) {
    if (Array.isArray(obj.data)) {
      obj.data = obj.data.map((y) => {
        if (!y.content) {
          return y;
        }
        return {
          ...y,
          content: y.content.map((x) => {
            return recursiveChangeOperator(x, id, operator);
          }),
        };
      });
    } else {
      if (obj.data.id === id) {
        obj.data.operator = operator;
        return obj;
      }
      if (obj.data.content) {
        obj.data.content = obj.data.content.map((x) => {
          return recursiveChangeOperator(x, id, operator);
        });
      }
    }
  }

  return obj;
};

export const recursiveChangeContent = (obj, id, content) => {
  if (!obj.data && !obj.id) {
    return obj;
  }

  if (obj.id === id) {
    obj.content = content;
    return obj;
  }

  if (obj.data) {
    if (Array.isArray(obj.data)) {
      obj.data = obj.data.map((y) => {
        if (!y.content) {
          return y;
        }
        return {
          ...y,
          content: y.content.map((x) => {
            return recursiveChangeContent(x, id, content);
          }),
        };
      });
    } else {
      if (obj.data.id === id) {
        obj.data.content = content;
        return obj;
      }
      if (obj.data.content) {
        obj.data.content = obj.data.content.map((x) => {
          return recursiveChangeContent(x, id, content);
        });
      }
    }
  }

  return obj;
};

export const recursiveAddContent = (obj, id, content) => {
  if (!obj.data && !obj.id) {
    return obj;
  }

  if (obj.id === id) {
    obj.content = { ...obj.content, content };
    return obj;
  }

  if (obj.data) {
    if (Array.isArray(obj.data)) {
      obj.data = obj.data.map((y) => {
        if (!y.content) {
          return y;
        }

        return {
          ...y,
          content: y.content.map((x) => {
            return recursiveAddContent(x, id, content);
          }),
        };
      });
    } else {
      if (obj.data.id === id) {
        obj.data.content = [...obj.data.content, content];
        return obj;
      }
      if (obj.data.content) {
        obj.data.content = obj.data.content.map((x) => {
          return recursiveAddContent(x, id, content);
        });
      }
    }
  }

  return obj;
};

export const recursiveReplaceContent = (obj, id, content) => {
  if (!obj.data && !obj.id) {
    return obj;
  }

  if (obj.id === id) {
    obj = content;
    return obj;
  }

  if (obj.data) {
    if (Array.isArray(obj.data)) {
      obj.data = obj.data.map((y) => {
        if (!y.content) {
          return y;
        }
        return {
          ...y,
          content: y.content.map((x) => {
            return recursiveReplaceContent(x, id, content);
          }),
        };
      });
    } else {
      if (obj.data.id === id) {
        obj.data = content;
        return obj;
      }
      if (obj.data.content) {
        obj.data.content = obj.data.content.map((x) => {
          return recursiveReplaceContent(x, id, content);
        });
      }
    }
  }

  return obj;
};

export const recursiveRemoveContent = (obj, id) => {
  if (!obj.data && !obj.id) {
    return obj;
  }

  if (obj.id === id) {
    return obj;
  }

  if (obj.data) {
    if (Array.isArray(obj.data)) {
      obj.data = obj.data.map((y) => {
        if (!y.content) {
          return y;
        }
        return {
          ...y,
          content: y.content
            .map((x) => {
              return recursiveRemoveContent(x, id);
            })
            .filter((xxx) => xxx.id !== id),
        };
      });
    } else {
      if (obj.data.id === id) {
        return obj;
      }
      if (obj.data.content) {
        obj.data.content = obj.data.content
          .map((x) => {
            return recursiveRemoveContent(x, id);
          })
          .filter((xxx) => xxx.id !== id);
      }
    }
  }

  return obj;
};

export const recursiveRemoveGroup = (obj, id) => {
  if (!obj.data && !obj.id) {
    return obj;
  }

  if (obj.id === id) {
    return obj;
  }
  
  if (obj.data) {
    if (obj.data.content) {
      const a = []
      
      obj.data.content = (obj.data.content || [])
        .filter((xxx) => xxx.id !== id && (xxx.data||{}).id !==id)
        .map((x) => {
          return recursiveRemoveGroup(x, id);
        })
        .filter((xxx) => xxx.id !== id && (xxx.data||{}).id !==id);
    }
  }

  return obj;
};