import React from 'react';
import { Query } from 'react-apollo';
import { Box } from 'rebass';
import styled from 'styled-components';
import PleaseWait from '../../components/PleaseWait';
import { withToastManager } from 'react-toast-notifications';
import { CONVO_TREE, ALL_CONVO_TREE } from './shared';
import { InnerMain } from './InnerMain';

const Container = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding: 20px 16px 40px 16px;
  min-height: 80vh;
  max-height: 95vh;
  overflow-y: auto;
  @media screen and (max-width: 52em) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const AdminReorderPhysicianConvo = ({ userId, close, helpscoutId, toastManager }) => {
  return (
    <Container>
      <Query query={CONVO_TREE} variables={{ userId: userId }}>
        {({ loading, data }) => {
          return (
            <Query query={ALL_CONVO_TREE} variables={{ userId: userId }}>
              {({ loading: loadingAvailables, data: dataAvailables }) => {
                if (!!loading || !!loadingAvailables) {
                  return <PleaseWait />;
                }
                return (
                  <InnerMain
                    userId={userId}
                    data={data.adminGetUserPhysicianConversationsTree}
                    dataAvailables={dataAvailables.adminGetPhysicianConversationByAthenaId}
                    helpscoutId={helpscoutId}
                    close={close}
                    toastManager={toastManager}
                  />
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Container>
  );
};

export default withToastManager(AdminReorderPhysicianConvo);
