import React, { useEffect, useState } from 'react';
import { Mutation, withApollo } from 'react-apollo';
import { useCSVReader, lightenDarkenColor, formatFileSize } from 'react-papaparse';
import { Box, Button, Flex, Text } from 'rebass';
import SpinLoader from '../../components/SpinLoader';
import gql from 'graphql-tag';
import moment from 'moment';

const DELETE_B2B_ELIGIBLES = gql`
  mutation deleteAdminB2BEligibles($companyId: String!, $data: [AdminUpsertB2BEligilitiesInput]!, $isLast: Boolean) {
    deleteAdminB2BEligibles(companyId: $companyId, data: $data, isLast: $isLast) {
      ok
    }
  }
`;

const ADD_B2B_ELIGIBLES = gql`
  mutation addAdminB2BEligibles($companyId: String!, $data: [AdminUpsertB2BEligilitiesInput]!, $isLast: Boolean) {
    addAdminB2BEligibles(companyId: $companyId, data: $data, isLast: $isLast) {
      ok
    }
  }
`;

const ADD_AFTER_WIPE_B2B_ELIGIBLES = gql`
  mutation addAfterWipeAdminB2BEligibles($companyId: String!, $data: [AdminUpsertB2BEligilitiesInput]!, $isLast: Boolean) {
    addAfterWipeAdminB2BEligibles(companyId: $companyId, data: $data, isLast: $isLast) {
      ok
    }
  }
`;

const WIPE_B2B_ELIGIBLES = gql`
  mutation wipeAdminB2BEligibles($companyId: String!) {
    wipeAdminB2BEligibles(companyId: $companyId) {
      ok
    }
  }
`;

const GET_B2B_ELIGIBLES = gql`
  mutation getAdminCompanyCurrentEligibilities($companyId: String!) {
    getAdminCompanyCurrentEligibilities(companyId: $companyId) {
      ok
    }
  }
`;

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
const GREY_DIM = '#686868';

const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};
const CHUNK_SIZE = 100; // items per chunk

const ModalUploadEligibleDiff = ({ company, onComplete, eligibilityCheckMethod, client }) => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);
  const [isViewResults, setIsViewResults] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [resultDiffToAdd, setResultDiffToAdd] = useState(null);
  const [resultDiffToRemove, setResultDiffToRemove] = useState(null);
  const [resultOldData, setResultOldData] = useState(null);
  const [isProcessingDiffs, setIsProcessingDiffs] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [diffProcess, setDiffProcess] = useState(null);
  const [batchRemoveSuccess, setBatchRemoveSuccess] = useState([]);
  const [batchRemoveProcessTimes, setRemoveBatchProcessTimes] = useState([]);
  const [batchRemoveProcessRemainingTime, setBatchRemoveProcessRemainingTime] = useState('');
  const [batchAddSuccess, setBatchAddSuccess] = useState([]);
  const [batchAddProcessTimes, setAddBatchProcessTimes] = useState([]);
  const [batchAddProcessRemainingTime, setBatchAddProcessRemainingTime] = useState('');
  const [isWipeOut, setIsWipeout] = useState(false);
  const [diffIdentificationProcessDescription, setDiffIdentificationProcessDescription] = useState('');
  const [diffIdentificationProcessDescriptionToAdd, setDiffIdentificationProcessDescriptionToAdd] = useState('');
  const [wipeOutProcessStatus, setWipeOutProcessStatus] = useState(-1); // -1: not started; 0: processing; 1: done; 2: failed
  const [isComplete, setIsComplete] = useState(false);
  const batchAddSuccessTmp = [];
  const batchRemoveSuccessTmp = [];

  const calculateAverage = (data) => {
    var array = data.reduce(function(r, e, i) {
      if (data[i + 1]) r.push(Number((data[i + 1] - e).toFixed(2)));
      return r;
    }, []);
    const sum = array.reduce((acc, curr) => acc + curr, 0);
    const average = sum / array.length;
    return average;
  };

  const getEstimatedRemainingTime = (batchesRemaining, processTimes) => {
    if (processTimes.length < 2) return 'estimating remaining time...';

    const avg = calculateAverage(processTimes);
    const total = avg * batchesRemaining;

    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 3600) % 24);

    return `${hours > 0 ? `${hours} hours` : ''} ${minutes > 0 ? `${minutes} minutes` : ''} ${seconds > 0 ? `${seconds} seconds` : ''} remaining`;
  };

  useEffect(
    () => {
      if (!resultDiffToAdd || resultDiffToAdd.length < 1 || !batchAddSuccess || batchAddSuccess.length < 1) return;

      const res = getEstimatedRemainingTime(resultDiffToAdd.length / CHUNK_SIZE + 1 - batchAddSuccess.length, batchAddProcessTimes);
      setBatchAddProcessRemainingTime(res);
    },
    [batchAddProcessTimes, batchAddSuccess, resultDiffToAdd]
  );

  useEffect(
    () => {
      if (!resultDiffToRemove || resultDiffToRemove.length < 1 || !batchRemoveSuccess || batchRemoveSuccess.length < 1) return;

      const res = getEstimatedRemainingTime(resultDiffToRemove.length / CHUNK_SIZE + 1 - batchRemoveSuccess.length, batchRemoveProcessTimes);
      setBatchRemoveProcessRemainingTime(res);
    },
    [batchRemoveProcessTimes, batchRemoveSuccess, resultDiffToRemove]
  );

  const identifyDiff = () => {
    setIsWipeout(false);
    setIsProcessingDiffs(true);
    const worker = new window.Worker('eligibility-diff.js');
    worker.postMessage({
      resultOldData,
      resultData,
      eligibilityCheckMethod,
    });
    worker.onerror = (err) => console.log(err);
    worker.onmessage = (e) => {
      const { olds, news, intermediateProcessToRemove, intermediateProcessToAdd } = e.data;

      if (!!intermediateProcessToRemove || !!intermediateProcessToAdd) {
        if (!!intermediateProcessToRemove) {
          setDiffIdentificationProcessDescription(intermediateProcessToRemove);
        }
        if (!!intermediateProcessToAdd) {
          setDiffIdentificationProcessDescriptionToAdd(intermediateProcessToAdd);
        }

        return;
      }
      setResultDiffToRemove(olds);
      setResultDiffToAdd(news);

      const chunksOld = [];
      for (let i = 0; i < olds.length; i += CHUNK_SIZE) {
        const chunk = olds.slice(i, i + CHUNK_SIZE);
        chunksOld.push({ uploaded: false, chunks: chunk, uploadedSuccess: null, isUploading: false });
      }
      const chunksNew = [];
      for (let i = 0; i < news.length; i += CHUNK_SIZE) {
        const chunk = news.slice(i, i + CHUNK_SIZE);
        chunksNew.push({ uploaded: false, chunks: chunk, uploadedSuccess: null, isUploading: false });
      }
      // console.log({
      //   toRemove: chunksOld,
      //   toAdd: chunksNew,
      // });
      setDiffProcess({
        toRemove: chunksOld,
        toAdd: chunksNew,
      });
      console.log({ diffProcess });
      setIsViewResults(true);

      setIsProcessingDiffs(false);
    };
  };

  const convert = (results, isNewFile) => {
    if (!results || !results.data || results.data.length < 1) return;

    let firstnameColId = -1;
    let lastnameColId = -1;
    let dobColId = -1;
    let employeeEmailColId = -1;
    let memberIdColId = -1;
    let employeeIdColId = -1;
    let subscriberIdColId = -1;
    let relationshipColId = -1;
    let firstnamePrimaryColId = -1;
    let lastnamePrimaryColId = -1;
    let uniqueCodeColId = -1;
    const headers = results.data[0];
    for (let index = 0; index < headers.length; index++) {
      const element = (headers[index] || '').toLowerCase();
      // console.log({ element });
      switch (element) {
        case 'first name':
        case 'firstname':
          firstnameColId = index;
          break;
        case 'last name':
        case 'lastname':
          lastnameColId = index;
          break;

        case 'dob':
        case 'birth date':
        case 'date of birth':
          dobColId = index;
          break;

        case 'employee email':
          employeeEmailColId = index;
          break;
        case 'member id':
          memberIdColId = index;
          break;
        case 'employee id':
          employeeIdColId = index;
          break;
        case 'subscriber id':
          subscriberIdColId = index;
          break;
        case 'primary first name':
          firstnamePrimaryColId = index;
          break;
        case 'primary last name':
          lastnamePrimaryColId = index;
          break;
        case 'unique code':
        case 'coupon code':
          uniqueCodeColId = index;
          break;
        default:
          break;
      }
      if (element.indexOf('relationship to ') > -1) {
        relationshipColId = index;
      }
    }
    const res = [];
    for (let index = 1; index < results.data.length; index++) {
      const element = results.data[index];
      res.push({
        firstName: element[firstnameColId],
        lastName: element[lastnameColId],
        dob: element[dobColId], //?moment(element[dobColId],'MM/DD/YYYY').toDate(): '',
        employeeEmail: (element[employeeEmailColId] || '').toLowerCase(),
        employeeId: element[employeeIdColId],
        memberId: element[memberIdColId],
        subscriberId: element[subscriberIdColId],
        relationshipToEmployer: element[relationshipColId],
        primaryFirstName: element[firstnamePrimaryColId],
        primaryLastName: element[lastnamePrimaryColId],
        uniqueCode: element[uniqueCodeColId],
      });
    }
    console.log({ res });
    if (!!isNewFile) {
      setResultData(res);
    } else {
      setResultOldData(res);
    }
    // console.log({ res });
  };

  const prepareForUpload = (arr) => {
    const final = [];

    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      final.push({
        ...element,
        dob: new Date(element.dob),
      });
    }

    return final;
  };

  const processUploads = async () => {
    setIsUploading(true);

    const tmpTimesRemove = [];
    for (let index = 0; index < diffProcess.toRemove.length; index++) {
      const element = diffProcess.toRemove[index];
      const a = JSON.parse(JSON.stringify(diffProcess));
      a.toRemove[index].isUploading = true;
      setDiffProcess(a);
      const input = prepareForUpload(element.chunks);
      const { data } = await client.mutate({
        mutation: DELETE_B2B_ELIGIBLES,
        variables: {
          companyId: company.id,
          data: input,
          isLast: index === diffProcess.toRemove.length - 1,
        },
      });

      a.toRemove[index].isUploading = false;
      if (data.deleteAdminB2BEligibles && data.deleteAdminB2BEligibles.ok) {
        batchRemoveSuccessTmp.push(true);
        setBatchRemoveSuccess(batchRemoveSuccessTmp);
      } else {
        batchRemoveSuccessTmp.push(false);
        setBatchRemoveSuccess(batchRemoveSuccessTmp);
      }
      const currentTime = moment().valueOf();
      tmpTimesRemove.push(currentTime);
      setRemoveBatchProcessTimes([...tmpTimesRemove]);
      setDiffProcess(a);
    }

    if (isWipeOut && (!diffProcess.toRemove || diffProcess.toRemove.length < 1)) {
      setWipeOutProcessStatus(0);
      const { data } = await client.mutate({
        mutation: WIPE_B2B_ELIGIBLES,
        variables: {
          companyId: company.id,
        },
      });
      if (!data || !data.wipeAdminB2BEligibles || !data.wipeAdminB2BEligibles.ok) {
        setWipeOutProcessStatus(2);
        return;
      } else {
        setWipeOutProcessStatus(1);
      }
      const tmpTimesAdd = [];
      for (let index = 0; index < diffProcess.toAdd.length; index++) {
        const element = diffProcess.toAdd[index];
        const a = JSON.parse(JSON.stringify(diffProcess));
        a.toAdd[index].isUploading = true;
        setDiffProcess(a);
        const input = prepareForUpload(element.chunks);
        const { data } = await client.mutate({
          mutation: ADD_AFTER_WIPE_B2B_ELIGIBLES,
          variables: {
            companyId: company.id,
            data: input,
            isLast: index === diffProcess.toAdd.length - 1,
          },
        });

        a.toAdd[index].isUploading = false;
        if (data.addAfterWipeAdminB2BEligibles && data.addAfterWipeAdminB2BEligibles.ok) {
          batchAddSuccessTmp.push(true);
          setBatchAddSuccess(batchAddSuccessTmp);
        } else {
          batchAddSuccessTmp.push(false);
          setBatchAddSuccess(batchAddSuccessTmp);
        }

        const currentTime = moment().valueOf();
        tmpTimesAdd.push(currentTime);
        setAddBatchProcessTimes([...tmpTimesAdd]);
        setDiffProcess(a);
      }
    } else {
      const tmpTimesAdd = [];
      for (let index = 0; index < diffProcess.toAdd.length; index++) {
        const element = diffProcess.toAdd[index];
        const a = JSON.parse(JSON.stringify(diffProcess));
        a.toAdd[index].isUploading = true;
        setDiffProcess(a);
        const input = prepareForUpload(element.chunks);
        const { data } = await client.mutate({
          mutation: ADD_B2B_ELIGIBLES,
          variables: {
            companyId: company.id,
            data: input,
            isLast: index === diffProcess.toAdd.length - 1,
          },
        });

        a.toAdd[index].isUploading = false;
        if (data.addAdminB2BEligibles && data.addAdminB2BEligibles.ok) {
          batchAddSuccessTmp.push(true);
          setBatchAddSuccess(batchAddSuccessTmp);
        } else {
          batchAddSuccessTmp.push(false);
          setBatchAddSuccess(batchAddSuccessTmp);
        }
        const currentTime = moment().valueOf();
        tmpTimesAdd.push(currentTime);
        setAddBatchProcessTimes([...tmpTimesAdd]);
        setDiffProcess(a);
      }
    }

    setIsUploading(false);
    setIsComplete(true);
  };

  if (isProcessingDiffs) {
    return (
      <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        <SpinLoader />
        <Text mt={4}>Identifying diffs</Text>
        <Text mt={3} fontWeight={300}>
          {diffIdentificationProcessDescription}
        </Text>
        <Text mt={1} fontWeight={300}>
          {diffIdentificationProcessDescriptionToAdd}
        </Text>
      </Flex>
    );
  }

  if (!isViewResults && !!resultData && !!resultOldData) {
    return (
      <Button variant={'primary'} onClick={() => identifyDiff()}>
        Generate diffs files
      </Button>
    );
  }

  if (!isViewResults && !!resultData && !resultOldData) {
    return (
      <>
        <Button
          variant={'primary'}
          onClick={() => {
            setResultDiffToRemove([]);
            setResultDiffToAdd(resultData);
            const chunksNew = [];
            for (let i = 0; i < resultData.length; i += CHUNK_SIZE) {
              const chunk = resultData.slice(i, i + CHUNK_SIZE);
              chunksNew.push({ uploaded: false, chunks: chunk, uploadedSuccess: null, isUploading: false });
            }
            setDiffProcess({
              toRemove: [],
              toAdd: chunksNew,
            });
            console.log({ diffProcess });
            setIsViewResults(true);

            setIsProcessingDiffs(false);
          }}
        >
          Prepare upload for append
        </Button>

        <Text color="#f50" mb={4}>
          Warning: No duplicate check will be done. Use this in case of interruption on previous upload. Use with caution
        </Text>

        <Button
          variant={'pink'}
          onClick={() => {
            setIsWipeout(true);
            setResultDiffToRemove([]);
            setResultDiffToAdd(resultData);
            const chunksNew = [];
            for (let i = 0; i < resultData.length; i += CHUNK_SIZE) {
              const chunk = resultData.slice(i, i + CHUNK_SIZE);
              chunksNew.push({ uploaded: false, chunks: chunk, uploadedSuccess: null, isUploading: false });
            }
            setDiffProcess({
              toRemove: [],
              toAdd: chunksNew,
            });
            console.log({ diffProcess });
            setIsViewResults(true);

            setIsProcessingDiffs(false);
          }}
        >
          Prepare upload for replace all
        </Button>

        <Text color="#f50">
          Warning: all existing eligibility will be wiped out and no analytics
          <br />
          on benefits loss. Use with caution
        </Text>
      </>
    );
  }

  if (!!isViewResults) {
    return (
      <>
        {!isWipeOut && (
          <>
            <Text>To Remove: {resultDiffToRemove.length}</Text>
            <Text fontWeight={300} fontSize={'12px'}>
              {batchRemoveSuccess && batchRemoveSuccess.length > 0 ? `Completed ${batchRemoveSuccess.length} batch / ${(resultDiffToRemove.length / CHUNK_SIZE + 1).toFixed(0)}` : ''}{' '}
              {batchRemoveSuccess && batchRemoveSuccess.length > 0 ? `(${(((batchRemoveSuccess.length * CHUNK_SIZE) / resultDiffToRemove.length) * 100).toFixed(2)}%)` : '(0%)'}
              <br />
              {batchRemoveProcessRemainingTime}
            </Text>
            <Flex width={'100%'} backgroundColor={'#f0f0f0'} alignItems={'center'}>
              {((diffProcess || {}).toRemove || []).map((x, i) => {
                if (!!x.isUploading) {
                  return <progress max="100" style={{ flex: 1, height: '24px' }} key={`toRemoveBatchProgress${i}`} />;
                } else {
                  return (
                    <Box
                      key={`toRemoveBatch${i}`}
                      flex={1}
                      style={{
                        height: '12px',
                        background:
                          !x.isUploading && !x.uploaded && (!batchRemoveSuccess || i + 1 > (batchRemoveSuccess || []).length)
                            ? 'transparent'
                            : x.isUploading
                              ? '#337ab7'
                              : batchRemoveSuccess[i] === true
                                ? '#bea'
                                : batchRemoveSuccess[i] === false
                                  ? '#f50'
                                  : '#be6464',
                      }}
                    />
                  );
                }
              })}
            </Flex>
          </>
        )}

        {isWipeOut &&
          (!resultDiffToRemove || resultDiffToRemove.length < 1) && (
            <>
              <Text mt={3}>Eligibilities wipe out</Text>
              {wipeOutProcessStatus === -1 ? (
                <Text ml={3} fontWeight={300}>
                  not started
                </Text>
              ) : wipeOutProcessStatus === 0 ? (
                <progress max="100" style={{ flex: 1, height: '24px' }} key={`toRemoveBatchProgress`} />
              ) : (
                <Box
                  key={`toRemoveBatch`}
                  flex={1}
                  style={{
                    height: '12px',
                    background: wipeOutProcessStatus === 1 ? '#bea' : '#f50',
                  }}
                />
              )}
            </>
          )}

        <Text mt={3}>To Add: {resultDiffToAdd.length}</Text>
        <Text fontWeight={300} fontSize={'12px'}>
          {batchAddSuccess && batchAddSuccess.length > 0 ? `Completed ${batchAddSuccess.length} batch / ${(resultDiffToAdd.length / CHUNK_SIZE + 1).toFixed(0)}` : ''}{' '}
          {batchAddSuccess && batchAddSuccess.length > 0 ? `(${(((batchAddSuccess.length * CHUNK_SIZE) / resultDiffToAdd.length) * 100).toFixed(2)}%)` : '(0%)'}
          <br />
          {batchAddProcessRemainingTime}
        </Text>
        <Flex width={'100%'} backgroundColor={'#f0f0f0'} alignItems={'center'}>
          {((diffProcess || {}).toAdd || []).map((x, i) => {
            if (!!x.isUploading) {
              return <progress max="100" style={{ flex: 1, height: '24px' }} key={`toAddBatchProgress${i}`} />;
            } else {
              return (
                <Box
                  key={`toAddBatch${i}`}
                  flex={1}
                  style={{
                    height: '12px',
                    background:
                      !x.isUploading && !x.uploaded && (!batchAddSuccess || i + 1 > (batchAddSuccess || []).length)
                        ? 'transparent'
                        : x.isUploading
                          ? '#337ab7'
                          : batchAddSuccess[i] === true
                            ? '#bea'
                            : batchAddSuccess[i] === false
                              ? '#f50'
                              : '#be6464',
                  }}
                />
              );
            }
          })}
        </Flex>

        <Button
          mt={3}
          variant={'primary'}
          disabled={isUploading}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isComplete) {
              onComplete();
              return;
            }
            processUploads();
          }}
        >
          {isComplete ? 'Process complete' : !!isUploading ? 'Processing' : 'Upload'}
        </Button>
      </>
    );
  }

  return (
    <Flex flexDirection={'column'}>
      <Mutation
        mutation={GET_B2B_ELIGIBLES}
        variables={{
          companyId: company.id,
        }}
      >
        {(mutate, { loading: loadingGetEligibles, data: dataGetEligibles }) => {
          if (!!loadingGetEligibles) {
            return (
              <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <SpinLoader />
                <Text>Getting current eligibilities. You will receive an email once completed</Text>
              </Flex>
            );
          }
          if (!!dataGetEligibles && !!dataGetEligibles.getAdminCompanyCurrentEligibilities) {
            return (
              <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Text>Process completed {dataGetEligibles.getAdminCompanyCurrentEligibilities.ok ? 'succesfully' : 'with error'}</Text>
              </Flex>
            );
          }

          return (
            <>
              <CSVReader
                onUploadAccepted={(results) => {
                  convert(results, false);
                  setZoneHover(false);
                }}
                onDragOver={(event) => {
                  event.preventDefault();
                  setZoneHover(true);
                }}
                onDragLeave={(event) => {
                  event.preventDefault();
                  setZoneHover(false);
                }}
              >
                {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }) => (
                  <>
                    <div {...getRootProps()} style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}>
                      {acceptedFile ? (
                        <>
                          <div style={styles.file}>
                            <div style={styles.info}>
                              <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span>
                              <span style={styles.name}>{acceptedFile.name}</span>
                            </div>
                            <div style={styles.progressBar}>
                              <ProgressBar />
                            </div>
                            <div
                              {...getRemoveFileProps()}
                              style={styles.remove}
                              onMouseOver={(event) => {
                                event.preventDefault();
                                setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                              }}
                              onMouseOut={(event) => {
                                event.preventDefault();
                                setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                              }}
                            >
                              <Remove color={removeHoverColor} />
                            </div>
                          </div>
                        </>
                      ) : (
                        'Drop last uploaded eligibility CSV file here or click to upload'
                      )}
                    </div>
                  </>
                )}
              </CSVReader>
              <Box mt={2} mb={2} />
              <CSVReader
                onUploadAccepted={(results) => {
                  convert(results, true);
                  setZoneHover(false);
                }}
                onDragOver={(event) => {
                  event.preventDefault();
                  setZoneHover(true);
                }}
                onDragLeave={(event) => {
                  event.preventDefault();
                  setZoneHover(false);
                }}
              >
                {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }) => (
                  <>
                    <div {...getRootProps()} style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}>
                      {acceptedFile ? (
                        <>
                          <div style={styles.file}>
                            <div style={styles.info}>
                              <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span>
                              <span style={styles.name}>{acceptedFile.name}</span>
                            </div>
                            <div style={styles.progressBar}>
                              <ProgressBar />
                            </div>
                            <div
                              {...getRemoveFileProps()}
                              style={styles.remove}
                              onMouseOver={(event) => {
                                event.preventDefault();
                                setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                              }}
                              onMouseOut={(event) => {
                                event.preventDefault();
                                setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                              }}
                            >
                              <Remove color={removeHoverColor} />
                            </div>
                          </div>
                        </>
                      ) : (
                        'Drop new eligibility CSV file here or click to upload'
                      )}
                    </div>
                  </>
                )}
              </CSVReader>

              <Button mt={4} variant={'primary'} onClick={mutate}>
                Retrieve current in database eligibilities
              </Button>
            </>
          );
        }}
      </Mutation>
    </Flex>
  );
};

export default withApollo(ModalUploadEligibleDiff);
