import * as React from 'react';

const map = new Map();

function setRef(key) {
  if (!key) return console.warn(`useDynamicRefs: Cannot set ref without key `);
  const ref = React.createRef();
  map.set(key, ref);
  return ref;
}

function getRef(key) {
  if (!key) return console.warn(`useDynamicRefs: Cannot get ref without key`);
  return map.get(key);
}

function getRefs() {
  return map
}

function useDynamicRefs() {
  return [getRef, setRef, getRefs];
}

export default useDynamicRefs;
