import React, { useState } from 'react';
import { Box, Button, Flex, Text } from 'rebass';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import PleaseWait from '../../../components/PleaseWait';
import { Query } from 'react-apollo';
import { GET_HEALTHCOACH_FORM_TEMPLATES } from '../../../graphql';
import Modal from 'styled-react-modal';
import { SpecialModalBackground } from '../../adminReorderPhysicianConvo/shared';
import FormTargetsAddEdit from './FormTargetsAddEdit';

const TemplateDetailRow = ({ row }) => {
  const [isAddNew, setIsAddNew] = useState();
  const [isEdit, setIsEdit] = useState();
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Creator',
      Cell: (row) => {
        return <div>{row.original.creator ? `${row.original.creator.displayName}` : ''}</div>;
      },
    },
    {
      Header: 'Active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div>{row.original.isActive ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'Deprecated',
      accessor: 'isDeprecated',
      Cell: (row) => {
        return <div>{row.original.isDeprecated ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'Deleted',
      accessor: 'isDeleted',
      Cell: (row) => {
        return <div>{row.original.isDeleted ? '✓' : ''}</div>;
      },
    },
    // {
    //   Header: '',
    //   Cell: (row, rest) => {
    //     return (
    //       <Flex flexDirection="row">
    //         <div title="edit" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => onEdit(row.original)}>
    //           ✎
    //         </div>
    //         <div title="delete" style={{ cursor: 'pointer' }} onClick={() => onDelete(row.original.id)}>
    //           🗑
    //         </div>
    //       </Flex>
    //     );
    //   },
    // },
  ];
  const columnsSub = [
    {
      Header: 'Owner',
      accessor: 'name',
    },
    {
      Header: 'Favorite',
      accessor: 'isFavorite',
      Cell: (row) => {
        return <div>{row.original.favorite ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'Active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div>{row.original.active ? '✓' : ''}</div>;
      },
    },
  ];

  const onDelete = (id) => {};

  const onEdit = (itm) => {
    setIsEdit(itm);
  };
  console.log({ row });
  return (
    <Flex ml={3}>
      <ReactTable
        data={(row.original || {}).associatedForms || []}
        columns={columns}
        style={{
          width: 'calc(100% - 7px)',
        }}
        SubComponent={(rowSub) => {
          console.log({ rowSub });
          return (
            <Flex flexDirection={'column'}>
              {!rowSub.original || !rowSub.original.inLibraryOf || rowSub.original.inLibraryOf.length < 1 ? (
                <Text>No health coach has it in library</Text>
              ) : (
                <>
                  <Flex ml={3}>
                    <ReactTable
                      style={{
                        width: 'calc(100% - 7px)',
                      }}
                      data={rowSub.original.inLibraryOf || []}
                      columns={columnsSub}
                      defaultPageSize={rowSub.original.inLibraryOf.length > 2 ? Math.min(rowSub.original.inLibraryOf.length, 25) : 2}
                      showPagination={rowSub.original.inLibraryOf.length > 24}
                    />
                    {/* {rowSub.original.inLibraryOf.map((xxx) => {
                return (
                  <Flex key={xxx.id}>
                    <Text>{xxx.name}</Text>
                    {xxx.isFavorite && <Box ml={3}>favorite</Box>}
                    {xxx.isActive && <Box ml={3}>active</Box>}
                  </Flex>
                );
              })} */}
                  </Flex>
                </>
              )}
            </Flex>
          );
        }}
        defaultPageSize={row.associatedForms && row.associatedForms.length > 3 ? Math.min(row.associatedForms.length, 25) : 3}
        showPagination={row.associatedForms && row.associatedForms.length > 24}
      />
    </Flex>
  );
};

export default TemplateDetailRow;
