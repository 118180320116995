import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, Flex, Link, Text } from 'rebass';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import Account from './icons/Account';
import { withApollo } from 'react-apollo';

const AccountMenu = styled(Card)`
  position: absolute;
  top: 80px;
  right: 0;
  width: 100vw;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
  @media screen and (min-width: 52em) {
    right: 20px;
    width: 300px;
  }
  @media screen and (max-width: 480px) {
    height: calc(100vh - 80px);
    box-shadow: none;
    border-top: 1px solid rgb(224, 224, 224);
  }
`;

const NavLink = styled(RouterLink)`
  color: #344f79;
  text-decoration: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 25px;
  display: block;
  &.active {
    background-color: #f9a394;
  }
  :last-child {
    margin-bottom: 10px;
  }
`;

const NavLinkPink = styled(NavLink)`
  color: #f9a394;
  &.active {
    background-color: #f9a394;
  }
`;

const A = styled.a`
  display: flex;
  text-decoration: none;

  &:visited {
    color: #344f79;
  }
`;

const ActionContainer = styled(Flex)`
  height: 80px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const HeadingText = styled(Flex)`
  display: none;
  @media screen and (min-width: 52em) {
    display: block;
  }
`;

const AccountStyled = styled.div`
  display: block;
  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const MobileView = styled(Flex)`
  flex: 1;
  justify-content: center;
  position: absolute;
  bottom: 140px;
  left: 0;
  right: 0;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

class HeaderAccount extends React.Component {
  state = {
    isAccountOpen: false,
  };

  handleAccountClose = () => {
    if (!this.state.isAccountOpen) return;

    const { onClose } = this.props;

    if (isMobile) {
      onClose();
    }
    this.setState({
      isAccountOpen: false,
    });
  };

  handleAccountCloseRedirect = () => {
    const { onClose } = this.props;

    this.setState({
      isAccountOpen: false,
    });

    onClose();
  };

  forceClose = () => {
    this.setState({ isAccountOpen: false });
  };

  handleAccountHover = () => {
    if (!isMobile) {
      this.setState({ isAccountOpen: true });
    }
  };

  handleAccountLeave = () => {
    if (!isMobile) {
      this.setState({ isAccountOpen: false });
    }
  };

  handleAccount = () => {
    if (isMobile) {
      if (window.Beacon) {
        window.Beacon('destroy');
      }
    }
    this.setState({
      isAccountOpen: true,
    });
  };

  render() {
    const { session } = this.props;
    const { isAccountOpen } = this.state;

    return (
      <div
        onMouseEnter={this.handleAccountHover}
        onMouseLeave={this.handleAccountLeave}
      >
        <OutsideClickHandler
          onOutsideClick={() => {
            if (isAccountOpen && !isMobile) {
              this.handleAccountClose();
            }
          }}
        >
          <Flex alignItems="center">
            <ActionContainer
              pr={3}
              onClick={() => {
                if (!isAccountOpen) {
                  this.handleAccount();
                } else {
                  this.handleAccountClose();
                }
              }}
            >
              <HeadingText>
                <Text fontSize={3} fontWeight="400">
                  {session.firstName} {session.lastName}
                </Text>
              </HeadingText>
              <AccountStyled>
                <Account height={40} />
              </AccountStyled>
            </ActionContainer>
            {isAccountOpen && (
              <AccountMenu px={4} py={3}>
                <NavLink
                  to="/account"
                  onClick={this.handleAccountCloseRedirect}
                >
                  Your Account
                </NavLink>
                {session.eligibility &&
                  session.intakeStatus !== 'disqualified' && (
                    <NavLink
                      to="/my-care"
                      onClick={this.handleAccountCloseRedirect}
                    >
                      Your Care
                    </NavLink>
                  )}
                  
                <NavLinkPink
                  to="/invite-friends"
                  onClick={this.handleAccountCloseRedirect}
                >
                  Invite Friends
                </NavLinkPink>
                <NavLink
                  to="/logout"
                  onClick={async () => {
                    await localStorage.clear();
                    await this.props.client.clearStore();
                  }}
                >
                  Sign Out
                </NavLink>

                <MobileView>
                  <A href="tel:434-248-7508">
                    <div
                      style={{
                        width: '30px',
                        marginRight: '12px',
                        transform: 'scaleX(-1)',
                      }}
                    >
                      <FontAwesomeIcon
                        size="2x"
                        color="#344f79"
                        icon={faPhone}
                      />
                    </div>
                    <Text fontSize="22px">434-248-7508</Text>
                  </A>
                </MobileView>
              </AccountMenu>
            )}
          </Flex>
        </OutsideClickHandler>
      </div>
    );
  }
}

export default withApollo(HeaderAccount, { withRef: true });
