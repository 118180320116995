import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { Flex, Text, Button } from 'rebass';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import { Formik, Form } from 'formik';
import Div100vh from 'react-div-100vh';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { GET_APPOINTMENT_CONVERSATION, APPOINTMENT_INITIATE_CONVERSATION, CONVERSATION_THREAD_REPLY, GET_PERPETUAL_PHYSICIAN_CONVERSATION, CURRENT_USER } from '../../graphql';
import SpinLoader from '../../components/SpinLoader';
import withSession from '../../lib/withSession';
import TextArea from '../../components/TextArea';
import AttachmentIcon from '../../static/attachment.png';
import SendIcon from '../../static/send.png';
import FormField from '../../components/FormField';
import BasicScrollToBottom, { StateContext } from '../../components/scrollToBottomAuto/index';
import { CustomerBubble, UserBubble, BotBubbleStyled, ToBeUploadedFilesMiniatures, AttachmentModal } from '../../components/communicator';
import withAnalytics from '../../lib/withAnalytics';
import { Link } from 'react-router-dom';

const Inner = styled.section`
  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 52em) {
    overflow-y: hidden;
  }
  @media screen and (min-width: 52em) {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: 100%;
  }
`;
const PhantomMargin = styled.div`
  @media screen and (max-width: 52em) {
    height: 10px;
  }
`;

const FormWrapper = styled.div`
  @media screen and (max-width: 52em) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0px -9px 23px -16px rgb(0, 0, 0);
    max-width: 99vw;
  }
`;

const generateInitiateConversationVariables = (appointmentId, appointment, prescription, summary, thread, isAsync = false) => {
  const appointmentDate = moment(`${appointment.date} ${appointment.startTime}`, 'MM/DD/YYYY HH:mm').format('MM/DD/YYYY hh:mma');
  let prescriptionText = '';
  if (prescription && prescription.length > 0) {
    for (let index = 0; index < prescription.length; index++) {
      const element = prescription[index];
      prescriptionText = prescriptionText + `${element.description}\n${element.instructions}\n${element.patientNote ? element.patientNote : ''}\n`;
    }
  }
  return {
    assignedToEmail: appointment.provider.id.toString(),
    thread: {
      ...thread,
    },
    appointmentDate: appointmentDate,
    appointmentId: appointmentId,
    prescription: prescriptionText,
    assessmentAndPlan: (summary || {}).assessment,
    patientGoal: (summary || {}).goals,
    patientInstructions: (summary || {}).instructions,
    discussionNotes: (summary || {}).discussionNotes,
    isAsync,
  };
};

const Wrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 52em) {
    max-width: 100vw;
    width: 100vw;
    margin-top: 37px;
    overflow-y: hidden;
    position: relative;
  }
`;

const MessagesDiv = styled(Div100vh)`
  cursor: auto;
  overflow-y: hidden;
  @media screen and (max-width: 52em) {
    overflow-y: hidden;
    padding-bottom: 100px;
  }
  /* @media screen and (min-width: 52em) {
    height: auto !important;
  } */
`;
class Communicator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      msg: '',
      pollInterval: 5000,
    };
    this.input = React.createRef();
    this.threadContainer = React.createRef();
  }
  render() {
    const { appointmentId, appointment, prescription, summary, loadingWrapper, isAsync = false, isShowOnlyDesktop = false, blockSend = false } = this.props;
    const { files, pollInterval, isSendingFirstMessage } = this.state;
    // console.log('Communicator appt', { appointment, prescription, summary });
    return (
      <Query
        query={GET_APPOINTMENT_CONVERSATION}
        variables={{ appointmentId: appointmentId.toString(), isAsync }}
        notifyOnNetworkStatusChange={true}
        fetchPolicy="cache-and-network"
        pollInterval={pollInterval}
        onCompleted={(data) => {
          if (data && data.getAppointmentConversation && data.getAppointmentConversation.length > 0) {
            if (pollInterval < 30000) {
              this.setState({
                pollInterval: 30000,
                isSendingFirstMessage: false,
              });
            } else if (isSendingFirstMessage) {
              this.setState({
                isSendingFirstMessage: false,
              });
            }
          }
        }}
      >
        {({ loading, error, data }) => {
          // console.log('GET_APPOINTMENT_CONVERSATION DATA', data);
          if (loading && (!data || !data.getAppointmentConversation)) {
            if (loadingWrapper) {
              return (
                <div
                  style={{
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '50%',
                  }}
                >
                  <loadingWrapper>
                    <SpinLoader />
                  </loadingWrapper>
                </div>
              );
            } else {
              return (
                <Flex flex={1} justifyContent="center" alignItems="center">
                  <SpinLoader />
                </Flex>
              );
            }
          }
          if (error) {
            if (loadingWrapper) {
              return (
                <div
                  style={{
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '50%',
                  }}
                >
                  {' '}
                  <loadingWrapper>Couldn't load messages {error}</loadingWrapper>
                </div>
              );
            } else {
              return (
                <Flex flex={1} justifyContent="center" alignItems="center">
                  Couldn't load messages
                </Flex>
              );
            }
          }
          if (data && data.getAppointmentConversation) {
            return (
              <>
                {!isShowOnlyDesktop ? (
                  <Wrapper
                    style={{
                      flex: '1 0 0',
                      overflowY: 'hidden',
                      maxHeight: 'calc(100% - 1px)',
                      minHeight: !isShowOnlyDesktop ? 'calc(100% - 1px)' : 'initial',
                      height: !isShowOnlyDesktop ? '' : 'initial !important',
                      background: !isShowOnlyDesktop ? '' : 'white',
                      marginTop: (!!window && !!window.ReactNativeWebView) || !!isShowOnlyDesktop ? '0px' : '37px',
                    }}
                  >
                    <Dropzone
                      noClick={true}
                      id="dropzone"
                      multiple={true}
                      onDrop={(files) => {
                        // console.log('OnDrop', files);
                      }}
                      onDropRejected={this.handleRejected}
                      onDropAccepted={(f) => {
                        for (let index = 0; index < f.length; index++) {
                          const element = f[index];
                          // console.log('fff', element);
                          const reader = new FileReader();
                          reader.onload = (event) => {
                            const fls = files;
                            fls.push({
                              fileName: element.name,
                              mimeType: element.type,
                              data: event.target.result,
                            });
                            this.setState({ files: fls });
                          };
                          reader.readAsDataURL(element);
                        }
                      }}
                      maxSize={10000000}
                      style={{ width: '100%', height: '100%' }}
                    >
                      {({ getRootProps, getInputProps, isDragActive, open }) => (
                        <Inner>
                          <MessagesDiv
                            style={{
                              display: 'flex',
                              flex: 1,
                              maxHeight: '100%',
                            }}
                            {...getRootProps()}
                            noClick={true}
                            id="messagesdiv"
                            className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            ref={this.threadContainer}
                          >
                            <BasicScrollToBottom>
                              <StateContext.Consumer>
                                {({ sticky }) => (
                                  <>
                                    <input type="file" {...getInputProps()} />
                                    {/* <div style={{maxHeight: 1000000, overflowY: 'hidden'}}> */}
                                    {/* <img src={addFile} height={36} alt="" /> */}
                                    {data &&
                                    data.getAppointmentConversation &&
                                    (data.getAppointmentConversation.length > 0 &&
                                      data.getAppointmentConversation[0].threads.length > 0 &&
                                      data.getAppointmentConversation[0].threads.filter(
                                        (xx) => xx.body && (xx.body || '').toLowerCase() !== 'initiate_1' && (xx.type || '').toString().toLowerCase() !== 'note'
                                      ).length > 0) ? (
                                      data.getAppointmentConversation[0].threads
                                        .filter((x) => {
                                          return x.body && x.body.toLowerCase() !== 'initiate_1' && (x.type || '').toString().toLowerCase() !== 'note';
                                        })
                                        .reverse()
                                        .map((thread, index) => {
                                          if (thread.createdBy.id.toString() === this.props.session.helpscoutId || thread.createdBy.type === 'customer') {
                                            return (
                                              <React.Fragment key={thread.id}>
                                                <div
                                                  style={{
                                                    width: '100%',
                                                    height: '12px',
                                                    display: index === 0 ? '' : 'none',
                                                  }}
                                                />
                                                <CustomerBubble thread={thread} key={thread.id} />
                                              </React.Fragment>
                                            );
                                          } else {
                                            return (
                                              <React.Fragment key={thread.id}>
                                                <div
                                                  style={{
                                                    width: '100%',
                                                    height: '12px',
                                                    display: index === 0 ? '' : 'none',
                                                  }}
                                                />
                                                <UserBubble thread={thread} key={thread.id} />
                                              </React.Fragment>
                                            );
                                          }
                                        })
                                    ) : (
                                      <Flex
                                        justifyContent="flex-start"
                                        style={{
                                          marginBottom: '24px',
                                          alignItems: isSendingFirstMessage ? 'center' : '',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        {isSendingFirstMessage ? (
                                          <>
                                            <SpinLoader />
                                            <br />
                                            Sending Your 1st Message
                                          </>
                                        ) : (
                                          <BotBubbleStyled>
                                            <Flex flexDirection="row" style={{ marginBottom: '16px' }}>
                                              <Text fontSize="16px" fontWeight="600">
                                                Paloma Health Team
                                              </Text>
                                            </Flex>

                                            <Text
                                              style={{
                                                wordWrap: 'break-word',
                                                whiteSpace: 'pre-line',
                                              }}
                                            >
                                              Our medical team can help with questions related to symptoms, treatments or lab values. For any other question, please
                                              <Link style={{ color: 'white' }} to="/chat-care" id="link_chatcare">
                                                {` message your care manager >`}
                                              </Link>
                                            </Text>
                                          </BotBubbleStyled>
                                        )}
                                      </Flex>
                                    )}

<Text textAlign='center' opacity={0.5} mt={3} style={{opacity: 0.5}}>usually replies within 3 to 5 business days</Text>
                                    <PhantomMargin />
                                  </>
                                )}
                              </StateContext.Consumer>
                            </BasicScrollToBottom>
                          </MessagesDiv>

                          <div
                            style={{
                              background: 'black',
                              opacity: 0.5,
                              height: '1px',
                              width: '100%',
                            }}
                          />
                          {/* {!blockSend ? ( */}
                          <Mutation
                            mutation={data && data.getAppointmentConversation && data.getAppointmentConversation.length > 0 ? CONVERSATION_THREAD_REPLY : APPOINTMENT_INITIATE_CONVERSATION}
                            onCompleted={() => {
                              this.props.analytics.track('Send Provider Message');
                            }}
                          >
                            {(sendEmailReminder, { loading }) => {
                              return (
                                <Formik
                                  initialValues={{ msgTxt: '' }}
                                  validate={(values, props) => {
                                    let errors = {};
                                    if (files.length > 0 && !values.msgTxt) {
                                      // console.log('VALIDATE INV');
                                      errors.msgTxt('A message is required to send');
                                    }
                                    return errors;
                                  }}
                                  render={({ isValid, errors, touched, submitForm, setSubmitting, values, resetForm, isSubmitting }) => (
                                    <FormWrapper>
                                      <Form>
                                        <Flex flexDirection="column">
                                          <Flex flexDirection="row">
                                            <Button
                                              type="button"
                                              style={{
                                                padding: '8px',
                                                alignSelf: 'flex-start',
                                                marginTop: '10px',
                                              }}
                                              variant="white"
                                              onClick={() => {
                                                open();
                                              }}
                                            >
                                              <img src={AttachmentIcon} width="20px" style={{ opacity: 0.75 }} alt="attach" />
                                            </Button>
                                            <FormField
                                              style={{
                                                flexGrow: 1,
                                                maxHeight: '150px',
                                                minHeight: '59px',
                                                height: 'auto',
                                              }}
                                              error={errors.msgTxt}
                                            >
                                              <TextArea
                                                disabled={isSubmitting}
                                                name="msgTxt"
                                                style={{
                                                  flexGrow: 1,
                                                  background: 'white',
                                                  border: '0px solid white',
                                                  maxHeight: '150px',
                                                  minHeight: '59px',
                                                  height: 'auto',
                                                }}
                                                placeholder="type your message..."
                                                id="autocomplete"
                                                className="input-field"
                                                type="text"
                                              />
                                            </FormField>
                                            <Button
                                              disabled={(!values.msgTxt && (!files || files.length < 1)) || isSubmitting}
                                              style={{
                                                padding: '8px',
                                                alignSelf: 'flex-start',
                                                marginTop: '10px',
                                                marginRight: '6px',
                                              }}
                                              type="button"
                                              variant="white"
                                              onClick={async () => {
                                                setSubmitting(true);
                                                const attachments = [];
                                                if (files) {
                                                  for (let index = 0; index < files.length; index++) {
                                                    const element = files[index];
                                                    attachments.push({
                                                      ...element,
                                                      data: element.data.substr(element.data.indexOf(',') + 1),
                                                    });
                                                  }
                                                }
                                                if (!data || !data.getAppointmentConversation || data.getAppointmentConversation.length < 1) {
                                                  // if (values.msgTxt) {
                                                  const vars = generateInitiateConversationVariables(
                                                    appointmentId,
                                                    appointment,
                                                    prescription,
                                                    summary,
                                                    {
                                                      text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                      attachments: attachments,
                                                    },
                                                    isAsync
                                                  );
                                                  await sendEmailReminder({
                                                    variables: vars,
                                                    refetchQueries: [
                                                      {
                                                        query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                                        variables: {
                                                          treeIndex: 0,
                                                        },
                                                      },
                                                      {
                                                        query: CURRENT_USER,
                                                      },
                                                    ],
                                                    awaitRefetchQueries: true,
                                                  });
                                                  resetForm({ msgTxt: '' });
                                                  this.setState({
                                                    files: [],
                                                    pollInterval: 2000,
                                                    isSendingFirstMessage: true,
                                                  });
                                                } else {
                                                  await sendEmailReminder({
                                                    variables: {
                                                      conversationId: data.getAppointmentConversation[0].id.toString(),
                                                      text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                      attachments: attachments,
                                                      isAsync,
                                                    },
                                                    refetchQueries: [
                                                      {
                                                        query: GET_APPOINTMENT_CONVERSATION,
                                                        variables: {
                                                          appointmentId: appointmentId.toString(),
                                                          isAsync,
                                                        },
                                                      },
                                                    ],
                                                  });

                                                  resetForm({ msgTxt: '' });
                                                  this.setState({
                                                    files: [],
                                                  });
                                                }

                                                setSubmitting(false);
                                              }}
                                            >
                                              <img src={SendIcon} width="20px" style={{ opacity: 0.75 }} alt="send" />
                                            </Button>
                                          </Flex>
                                          {files && files.length > 0 ? (
                                            <>
                                              {isMobile ? (
                                                <ToBeUploadedFilesMiniatures
                                                  files={files}
                                                  onFileRemove={(index) => {
                                                    const fls = files;
                                                    fls.splice(index, 1);
                                                    this.setState({ files: fls });
                                                  }}
                                                />
                                              ) : (
                                                <AttachmentModal
                                                  isSubmitting={isSubmitting}
                                                  onFileRemove={(index) => {
                                                    const fls = files;
                                                    fls.splice(index, 1);
                                                    this.setState({ files: fls });
                                                  }}
                                                  confirmModalForm={async () => {
                                                    setSubmitting(true);
                                                    const attachments = [];
                                                    if (files) {
                                                      for (let index = 0; index < files.length; index++) {
                                                        const element = files[index];
                                                        attachments.push({
                                                          ...element,
                                                          data: element.data.substr(element.data.indexOf(',') + 1),
                                                        });
                                                      }
                                                    }
                                                    if (!data || !data.getAppointmentConversation || data.getAppointmentConversation.length < 1) {
                                                      // if (values.msgTxt) {
                                                      const vars = generateInitiateConversationVariables(
                                                        appointmentId,
                                                        appointment,
                                                        prescription,
                                                        summary,
                                                        {
                                                          text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                          attachments: attachments,
                                                        },
                                                        isAsync
                                                      );
                                                      await sendEmailReminder({
                                                        variables: vars,
                                                        refetchQueries: [
                                                          {
                                                            query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                                            variables: {
                                                              treeIndex: 0,
                                                            },
                                                          },
                                                          {
                                                            query: CURRENT_USER,
                                                          },
                                                        ],
                                                        awaitRefetchQueries: true,
                                                      });
                                                      resetForm({ msgTxt: '' });
                                                      this.setState({
                                                        files: [],
                                                        pollInterval: 2000,
                                                        isSendingFirstMessage: true,
                                                      });
                                                    } else {
                                                      await sendEmailReminder({
                                                        variables: {
                                                          conversationId: data.getAppointmentConversation[0].id.toString(),
                                                          text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                          attachments: attachments,
                                                          isAsync,
                                                        },
                                                        refetchQueries: [
                                                          {
                                                            query: GET_APPOINTMENT_CONVERSATION,
                                                            variables: {
                                                              appointmentId: appointmentId.toString(),
                                                              isAsync,
                                                            },
                                                          },
                                                        ],
                                                      });

                                                      resetForm({ msgTxt: '' });
                                                      this.setState({
                                                        files: [],
                                                      });
                                                    }

                                                    setSubmitting(false);
                                                  }}
                                                  close={() => {
                                                    this.setState({
                                                      files: [],
                                                    });
                                                  }}
                                                  files={files}
                                                  values={values}
                                                  addFiles={(files) => {
                                                    // console.log('FilsAdd', files);
                                                    this.setState({ files });
                                                  }}
                                                />
                                              )}
                                            </>
                                          ) : null}
                                        </Flex>
                                      </Form>
                                    </FormWrapper>
                                  )}
                                />
                              );
                            }}
                          </Mutation>
                          {/* ) : (
                            <FormWrapper>
                              <Text textAlign={'center'}>Please use your upcoming or most recent visit to chat with your provider</Text>
                            </FormWrapper>
                          )} */}
                        </Inner>
                      )}
                    </Dropzone>
                  </Wrapper>
                ) : (
                  <Flex
                    style={{
                      flex: '1 0 0',
                      overflowY: 'hidden',
                      maxHeight: 'calc(100% - 1px)',
                      minHeight: !isShowOnlyDesktop ? 'calc(100% - 1px)' : 'initial',
                      flexDirection: 'column',
                      background: '#fdf5f1',
                      marginTop: (!!window && !!window.ReactNativeWebView) || !!isShowOnlyDesktop ? '0px' : '37px',
                    }}
                  >
                    <Dropzone
                      noClick={true}
                      id="dropzone"
                      multiple={true}
                      onDrop={(files) => {
                        // console.log('OnDrop', files);
                      }}
                      onDropRejected={this.handleRejected}
                      onDropAccepted={(f) => {
                        for (let index = 0; index < f.length; index++) {
                          const element = f[index];
                          // console.log('fff', element);
                          const reader = new FileReader();
                          reader.onload = (event) => {
                            const fls = files;
                            fls.push({
                              fileName: element.name,
                              mimeType: element.type,
                              data: event.target.result,
                            });
                            this.setState({ files: fls });
                          };
                          reader.readAsDataURL(element);
                        }
                      }}
                      maxSize={10000000}
                      style={{ width: '100%', height: '100%' }}
                    >
                      {({ getRootProps, getInputProps, isDragActive, open }) => (
                        <Inner>
                          <MessagesDiv
                            style={{
                              display: 'flex',
                              flex: 1,
                              maxHeight: '100%',
                            }}
                            {...getRootProps()}
                            noClick={true}
                            id="messagesdiv"
                            className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            ref={this.threadContainer}
                          >
                            <BasicScrollToBottom>
                              <StateContext.Consumer>
                                {({ sticky }) => (
                                  <>
                                    <input type="file" {...getInputProps()} />
                                    {/* <div style={{maxHeight: 1000000, overflowY: 'hidden'}}> */}
                                    {/* <img src={addFile} height={36} alt="" /> */}
                                    {data &&
                                    data.getAppointmentConversation &&
                                    (data.getAppointmentConversation.length > 0 &&
                                      data.getAppointmentConversation[0].threads.length > 0 &&
                                      data.getAppointmentConversation[0].threads.filter(
                                        (xx) => xx.body && (xx.body || '').toLowerCase() !== 'initiate_1' && (xx.type || '').toString().toLowerCase() !== 'note'
                                      ).length > 0) ? (
                                      data.getAppointmentConversation[0].threads
                                        .filter((x) => {
                                          if(x.body && x.body.toLowerCase() === 'initiate_1') return false
                                          if(x.state !== 'published') return false
                                          if((x.type || '').toString().toLowerCase() !== 'message' && (x.type || '').toString().toLowerCase() !== 'customer') return false
                                          if(!x.body && !x._embedded && !x._embedded.attachments && x._embedded.attachments.length<1) return false
                                          return true
                                        })
                                        .reverse()
                                        .map((thread, index) => {
                                          if (thread.createdBy.id.toString() === this.props.session.helpscoutId || thread.createdBy.type === 'customer') {
                                            return (
                                              <React.Fragment key={thread.id}>
                                                <div
                                                  style={{
                                                    width: '100%',
                                                    height: '12px',
                                                    display: index === 0 ? '' : 'none',
                                                  }}
                                                />
                                                <CustomerBubble thread={thread} key={thread.id} />
                                              </React.Fragment>
                                            );
                                          } else {
                                            return (
                                              <React.Fragment key={thread.id}>
                                                <div
                                                  style={{
                                                    width: '100%',
                                                    height: '12px',
                                                    display: index === 0 ? '' : 'none',
                                                  }}
                                                />
                                                <UserBubble thread={thread} key={thread.id} />
                                              </React.Fragment>
                                            );
                                          }
                                        })
                                    ) : (
                                      <Flex
                                        justifyContent="flex-start"
                                        style={{
                                          marginBottom: '24px',
                                          alignItems: isSendingFirstMessage ? 'center' : '',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        {isSendingFirstMessage ? (
                                          <>
                                            <SpinLoader />
                                            <br />
                                            Sending Your 1st Message
                                          </>
                                        ) : (
                                          <BotBubbleStyled>
                                            <Flex flexDirection="row" style={{ marginBottom: '16px' }}>
                                              <Text fontSize="16px" fontWeight="600">
                                                Paloma Health Team
                                              </Text>
                                            </Flex>

                                            <Text
                                              style={{
                                                wordWrap: 'break-word',
                                                whiteSpace: 'pre-line',
                                              }}
                                            >
                                              Our medical team can help with questions related to symptoms, treatments or lab values. For any other question, please
                                              <Link style={{ color: 'white' }} to="/chat-care" id="link_chatcare">
                                                {` message your care manager >`}
                                              </Link>
                                            </Text>
                                          </BotBubbleStyled>
                                        )}
                                      </Flex>
                                    )}

<Text textAlign='center' opacity={0.5} mt={3} style={{opacity: 0.5}}>usually replies within 3 to 5 business days</Text>
                                    <PhantomMargin />
                                  </>
                                )}
                              </StateContext.Consumer>
                            </BasicScrollToBottom>
                          </MessagesDiv>

                          <div
                            style={{
                              background: 'black',
                              opacity: 0.5,
                              height: '1px',
                              width: '100%',
                            }}
                          />
                          {/* {!blockSend ? ( */}
                          <Mutation
                            mutation={data && data.getAppointmentConversation && data.getAppointmentConversation.length > 0 ? CONVERSATION_THREAD_REPLY : APPOINTMENT_INITIATE_CONVERSATION}
                            onCompleted={() => {
                              this.props.analytics.track('Send Provider Message');
                            }}
                          >
                            {(sendEmailReminder, { loading }) => {
                              return (
                                <Formik
                                  initialValues={{ msgTxt: '' }}
                                  validate={(values, props) => {
                                    let errors = {};
                                    if (files.length > 0 && !values.msgTxt) {
                                      // console.log('VALIDATE INV');
                                      errors.msgTxt('A message is required to send');
                                    }
                                    return errors;
                                  }}
                                  render={({ isValid, errors, touched, submitForm, setSubmitting, values, resetForm, isSubmitting }) => (
                                    <FormWrapper>
                                      <Form>
                                        <Flex flexDirection="column">
                                          <Flex flexDirection="row">
                                            <Button
                                              type="button"
                                              style={{
                                                padding: '8px',
                                                alignSelf: 'flex-start',
                                                marginTop: '10px',
                                              }}
                                              variant="white"
                                              onClick={() => {
                                                open();
                                              }}
                                            >
                                              <img src={AttachmentIcon} width="20px" style={{ opacity: 0.75 }} alt="attach" />
                                            </Button>
                                            <FormField
                                              style={{
                                                flexGrow: 1,
                                                maxHeight: '150px',
                                                minHeight: '59px',
                                                height: 'auto',
                                              }}
                                              error={errors.msgTxt}
                                            >
                                              <TextArea
                                                disabled={isSubmitting}
                                                name="msgTxt"
                                                style={{
                                                  flexGrow: 1,
                                                  background: 'white',
                                                  border: '0px solid white',
                                                  maxHeight: '150px',
                                                  minHeight: '59px',
                                                  height: 'auto',
                                                }}
                                                placeholder="type your message..."
                                                id="autocomplete"
                                                className="input-field"
                                                type="text"
                                              />
                                            </FormField>
                                            <Button
                                              disabled={(!values.msgTxt && (!files || files.length < 1)) || isSubmitting}
                                              style={{
                                                padding: '8px',
                                                alignSelf: 'flex-start',
                                                marginTop: '10px',
                                                marginRight: '6px',
                                              }}
                                              type="button"
                                              variant="white"
                                              onClick={async () => {
                                                setSubmitting(true);
                                                const attachments = [];
                                                if (files) {
                                                  for (let index = 0; index < files.length; index++) {
                                                    const element = files[index];
                                                    attachments.push({
                                                      ...element,
                                                      data: element.data.substr(element.data.indexOf(',') + 1),
                                                    });
                                                  }
                                                }
                                                if (!data || !data.getAppointmentConversation || data.getAppointmentConversation.length < 1) {
                                                  // if (values.msgTxt) {
                                                  const vars = generateInitiateConversationVariables(
                                                    appointmentId,
                                                    appointment,
                                                    prescription,
                                                    summary,
                                                    {
                                                      text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                      attachments: attachments,
                                                    },
                                                    isAsync
                                                  );
                                                  await sendEmailReminder({
                                                    variables: vars,
                                                    refetchQueries: [
                                                      {
                                                        query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                                        variables: {
                                                          treeIndex: 0,
                                                        },
                                                      },
                                                      {
                                                        query: CURRENT_USER,
                                                      },
                                                    ],
                                                    awaitRefetchQueries: true,
                                                  });
                                                  resetForm({ msgTxt: '' });
                                                  this.setState({
                                                    files: [],
                                                    pollInterval: 2000,
                                                    isSendingFirstMessage: true,
                                                  });
                                                } else {
                                                  await sendEmailReminder({
                                                    variables: {
                                                      conversationId: data.getAppointmentConversation[0].id.toString(),
                                                      text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                      attachments: attachments,
                                                      isAsync,
                                                    },
                                                    refetchQueries: [
                                                      {
                                                        query: GET_APPOINTMENT_CONVERSATION,
                                                        variables: {
                                                          appointmentId: appointmentId.toString(),
                                                          isAsync,
                                                        },
                                                      },
                                                    ],
                                                  });

                                                  resetForm({ msgTxt: '' });
                                                  this.setState({
                                                    files: [],
                                                  });
                                                }

                                                setSubmitting(false);
                                              }}
                                            >
                                              <img src={SendIcon} width="20px" style={{ opacity: 0.75 }} alt="send" />
                                            </Button>
                                          </Flex>
                                          {files && files.length > 0 ? (
                                            <>
                                              {isMobile ? (
                                                <ToBeUploadedFilesMiniatures
                                                  files={files}
                                                  onFileRemove={(index) => {
                                                    const fls = files;
                                                    fls.splice(index, 1);
                                                    this.setState({ files: fls });
                                                  }}
                                                />
                                              ) : (
                                                <AttachmentModal
                                                  isSubmitting={isSubmitting}
                                                  onFileRemove={(index) => {
                                                    const fls = files;
                                                    fls.splice(index, 1);
                                                    this.setState({ files: fls });
                                                  }}
                                                  confirmModalForm={async () => {
                                                    setSubmitting(true);
                                                    const attachments = [];
                                                    if (files) {
                                                      for (let index = 0; index < files.length; index++) {
                                                        const element = files[index];
                                                        attachments.push({
                                                          ...element,
                                                          data: element.data.substr(element.data.indexOf(',') + 1),
                                                        });
                                                      }
                                                    }
                                                    if (!data || !data.getAppointmentConversation || data.getAppointmentConversation.length < 1) {
                                                      // if (values.msgTxt) {
                                                      const vars = generateInitiateConversationVariables(
                                                        appointmentId,
                                                        appointment,
                                                        prescription,
                                                        summary,
                                                        {
                                                          text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                          attachments: attachments,
                                                        },
                                                        isAsync
                                                      );
                                                      await sendEmailReminder({
                                                        variables: vars,
                                                        refetchQueries: [
                                                          {
                                                            query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                                            variables: {
                                                              treeIndex: 0,
                                                            },
                                                          },
                                                          {
                                                            query: CURRENT_USER,
                                                          },
                                                        ],
                                                        awaitRefetchQueries: true,
                                                      });
                                                      resetForm({ msgTxt: '' });
                                                      this.setState({
                                                        files: [],
                                                        pollInterval: 2000,
                                                        isSendingFirstMessage: true,
                                                      });
                                                    } else {
                                                      await sendEmailReminder({
                                                        variables: {
                                                          conversationId: data.getAppointmentConversation[0].id.toString(),
                                                          text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                          attachments: attachments,
                                                          isAsync,
                                                        },
                                                        refetchQueries: [
                                                          {
                                                            query: GET_APPOINTMENT_CONVERSATION,
                                                            variables: {
                                                              appointmentId: appointmentId.toString(),
                                                              isAsync,
                                                            },
                                                          },
                                                        ],
                                                      });

                                                      resetForm({ msgTxt: '' });
                                                      this.setState({
                                                        files: [],
                                                      });
                                                    }

                                                    setSubmitting(false);
                                                  }}
                                                  close={() => {
                                                    this.setState({
                                                      files: [],
                                                    });
                                                  }}
                                                  files={files}
                                                  values={values}
                                                  addFiles={(files) => {
                                                    // console.log('FilsAdd', files);
                                                    this.setState({ files });
                                                  }}
                                                />
                                              )}
                                            </>
                                          ) : null}
                                        </Flex>
                                      </Form>
                                    </FormWrapper>
                                  )}
                                />
                              );
                            }}
                          </Mutation>
                          {/* ) : (
                            <FormWrapper>
                              <Text textAlign={'center'}>Please use your upcoming or most recent visit to chat with your provider</Text>
                            </FormWrapper>
                          )} */}
                        </Inner>
                      )}
                    </Dropzone>
                  </Flex>
                )}
              </>
            );
          }
          return <div />;
        }}
      </Query>
    );
  }
}

export default withSession(withAnalytics(Communicator));
