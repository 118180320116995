import React, {  } from 'react';
import { Box, Card, Text } from 'rebass';
import styled from 'styled-components';
import { withToastManager } from 'react-toast-notifications';
import { WebView } from './views/WebView';
import { MobileView } from './views/MobileView';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const ResultsView = ({ results, refetch, toastManager, isEditable }) => {
  console.log({ results });

  return (
    <>
      {results.web && results.web.length > 0 ? (
        <>
          <Text fontSize={3} fontWeight={500}>
            Web Accounts ({results.web.length})
          </Text>
          {!isEditable && (
          <Text textAlign={'center'} mb={3}>
            Edition is available only on search by email
          </Text>
          )}
          {results.web.map((x) => {
            console.log({ x });
            return (
              <Card p={'18px'}>
                <WebView data={x} refetch={refetch} toastManager={toastManager} isEditable={isEditable} />
              </Card>
            );
          })}
        </>
      ) : (
        <Text>No web account found</Text>
      )}

      {results.mobile && results.mobile.length > 0 ? (
        <>
          <Text fontSize={3} fontWeight={500} mt={4}>
            Mobile Accounts ({results.mobile.length})
          </Text>
          {results.mobile.map((x) => {
            return (
              <Card p={'18px'}>
                <MobileView data={x} refetch={refetch} toastManager={toastManager} isEditable={isEditable} />
              </Card>
            );
          })}
        </>
      ) : (
        <Text>No mobile account found</Text>
      )}
    </>
  );
};

export default withToastManager(ResultsView);
