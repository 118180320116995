import React from 'react';
import { Card, Flex, Heading, Link, Text } from 'rebass';
import FullLayout2 from '../../components/FullLayout';
import withSession from '../../lib/withSession';
import withAnalytics from '../../lib/withAnalytics';
import VitaminsBox from '../../static/vitaminsBox.png';
import { Query } from 'react-apollo';
import { GET_SESSION } from '../../graphql';
import SpinLoader from '../../components/SpinLoader';
import styled from 'styled-components';
import { uuidv4 } from '../../utils/uuid';

const StyledLink = styled(Link)`
  font-size: 14px;
  color: #999;
  text-decoration: underline;
  align-self: center;
`;
const VitaminsOrderConfirmation = ({
  analytics,
  match,
  session: { email, firstName, lastName, address },
}) => {
  return (
    <Flex style={{ margin: '0 auto' }}>
      <Card p={4} id="receiptcontainer" alignSelf="center">
        <Heading textAlign="center" mb={4}>
          Your Order Confirmation
        </Heading>

        <>
          <Flex flexDirection={['column', 'column', 'row']}>
            <Flex
              flex={1}
              pr={2}
              pl={2}
              style={{ border: '1px solid rgba(0,0,0,0.25)' }}
              mb={2}
            >
              <div style={{ textAlign: 'center', width: '100%' }}>
                <Flex mb={4} justifyContent="left" />
                <Text
                  textAlign="center"
                  mb={4}
                  mt={2}
                  lineHeight={1.6}
                  fontSize="22px"
                  fontWeight={600}
                  color="#344f79"
                >
                  Customer Information
                </Text>
                <div
                  style={{
                    borderBottom: '1px solid rgba(0,0,0,0.25)',
                    width: 'calc(100% + 16px)',
                    marginLeft: '-8px',
                    marginBottom: '32px',
                  }}
                />

                <Flex
                  flexDirection={'column'}
                  style={{
                    justifyContent: 'start',
                    alignItems: 'start',
                    textAlign: 'left',
                  }}
                >
                  <Flex flexDirection="column" style={{ textAlign: 'left' }}>
                    <Text fontWeight={600}>Email</Text>
                    <Text mb={2}>{email}</Text>
                  </Flex>
                  <Flex flexDirection="column" style={{ textAlign: 'left' }}>
                    <Text fontWeight={600}>Shipping Address</Text>

                    <Text>
                      {address.firstName} {address.lastName}
                    </Text>
                    <Text>{address.street1}</Text>
                    <Text>{address.street2}</Text>
                    <Text>
                      {address.city}, {address.state} {address.zip}
                    </Text>
                  </Flex>
                </Flex>
                <div
                  style={{
                    borderBottom: '1px solid rgba(0,0,0,0.25)',
                    width: 'calc(100% + 16px)',
                    marginLeft: '-8px',
                    marginBottom: '32px',
                  }}
                />
              </div>
            </Flex>
            <Flex
              flex={1}
              pr={2}
              pl={2}
              style={{
                border: '1px solid rgba(0,0,0,0.25)',
                // borderLeftWidth: 0,
              }}
              flexDirection="column"
              mb={2}
            >
              <div style={{ textAlign: 'left' }}>
                <Flex mb={4} justifyContent="left" />
                <Text
                  textAlign="center"
                  mb={4}
                  mt={2}
                  lineHeight={1.6}
                  fontSize="22px"
                  fontWeight={600}
                  color="#344f79"
                >
                  Your Order
                </Text>
                <div
                  style={{
                    borderBottom: '1px solid rgba(0,0,0,0.25)',
                    width: 'calc(100% + 16px)',
                    marginLeft: '-8px',
                    marginBottom: '32px',
                  }}
                />

                <Flex mb={3} justifyContent="center">
                  <img src={VitaminsBox} alt="" height="200px" />
                </Flex>

                <Flex flex={1} mb={3} flexDirection="column">
                  <Query
                    query={GET_SESSION}
                    variables={{ sessionId: (match.params || {}).session_id }}
                    onCompleted={data => {
                      analytics.logRevenue(
                        (data.getSession.amountTotal || 0) / 100,
                        (data.getSession.amountTotal || 0) / 100,
                        0,
                        '',
                        [
                          {
                            productId: 'kit',
                            name: 'supplements one off',
                            price: (data.getSession.amountTotal || 0) / 100,
                            quantity: 1,
                            category: 'Supplements',
                          },
                        ],
                        data.getSession.paymentIntentId || uuidv4()
                      );
                    }}
                  >
                    {({ loading, data, error }) => {
                      if (loading) return <SpinLoader />;
                      if (!data.getSession) {
                        return <Text>An error occured</Text>;
                      }
                      return (
                        <>
                          {data.getSession.items.map(x => {
                            return(<Flex flex={1} key={x.id}>
                              <Flex flex={1}>
                                <Text fontWeight={300}>{x.description}</Text>
                              </Flex>
                              <Flex justifyContent="flex-end">
                              ${x.amountSubtotal ? (x.amountSubtotal / 100).toFixed(2) : '0.00'}
                              </Flex>
                            </Flex>);
                          })}
                          <Flex flex={1}>
                            <Flex flex={1}>
                              <Text fontWeight={300}>Taxes (included)</Text>
                            </Flex>
                            <Flex justifyContent="flex-end">$0.00</Flex>
                          </Flex>
                          <Flex flex={1}>
                            <Flex flex={1}>
                              <Text fontWeight={300}>Free shipping</Text>
                            </Flex>
                            <Flex justifyContent="flex-end">$0.00</Flex>
                          </Flex>
                          <Text
                            fontSize={3}
                            fontWeight={500}
                            textAlign="center"
                            mt={3}
                            mb={4}
                          >
                         Total ${data.getSession.amountSubtotal ? (data.getSession.amountSubtotal / 100).toFixed(2) : '0.00'}
                          </Text>
                          {!!data.getSession.receipt_url ? (
                            <StyledLink
                            href={data.getSession.receipt_url}
                            target="_blank"
                          >
                              view receipt
                            </StyledLink>
                          ) : null}
                        </>
                      );
                    }}
                  </Query>
                </Flex>
              </div>
            </Flex>
          </Flex>
        </>
      </Card>
      <FullLayout2 />
    </Flex>
  );
};

export default withAnalytics(withSession(VitaminsOrderConfirmation));
