import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import withSession from '../../lib/withSession';
import { getLogoSource } from '../scheduleDoctor/helpers/insuranceLogos';
import logoloader2 from '../../static/logoloader2.gif';
import Select from 'react-select';
import { insuranceCompaniesList } from '../scheduleDoctor2/lists/insuranceCompaniesList';
import UploadInsuranceCardNew from './UploadInsuranceCardNew';
import { withRouter } from 'react-router';
import InsuranceInfo from './InsuranceInfo';
import { sleep } from '../../utils/sleep';
import ongoingAdvice from '../../static/ongoingAdvice.png';
import { Mutation } from 'react-apollo';
import { ADD_NEW_INSURANCE, CURRENT_USER, GET_HAS_INSURANCE_CARD_ON_DOCS, GET_INSURANCE_ON_FILE } from '../../graphql';
import PleaseWait from '../../components/PleaseWait';
import { getMemberVisitPrice } from '../../utils/pricing';

const Wrapper = styled(Flex)`
  padding-left: ${(props) => (!!window && !!window.ReactNativeWebView ? '0' : '60px')};
  padding-top: 40px;
  flex: 1;
  border-radius: 8px 8px 8px 8px;
  flex-direction: column;
  width: 100%;

  max-width: ${(props) => (!!window && !!window.ReactNativeWebView ? 'calc(100vw - 24px)' : '700px')};
  margin: ${(props) => (!!window && !!window.ReactNativeWebView ? '12px' : '0')};
  padding-bottom: 80px;
  @media screen and (max-width: 480px) {
    max-width: calc(100vw - 24px);
    margin: 12px;
    padding-left: 0;
  }
`;
const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
`;

const SelectProvider = ({ selectedProvider, onProviderSelectionChange }) => {
  return (
    <>
      <Flex flexDirection="column">
        <Title mb={2}>Pick Your Insurance Provider</Title>
        <Text mb={4} fontSize="18px">
          Search for your insurance provider to see if you are covered
        </Text>
      </Flex>
      <div style={{ maxWidth: '500px', width: '100%', marginBottom: '12px' }}>
        <Select
          classNamePrefix="region-select"
          options={insuranceCompaniesList}
          placeholder="Search"
          isSearchable={true}
          value={selectedProvider}
          onChange={onProviderSelectionChange}
          // noOptionsMessage={() => (
          //   <NoOptionMessage
          //     bypassInsurance={bypassInsurance}
          //     wizard={wizard}
          //   />
          // )}
          theme={(base) => ({
            ...base,
            colors: {
              ...base.colors,
              primary: '#364f79',
              primary50: '#dae6fa',
            },
          })}
        />
      </div>
    </>
  );
};

const SelectPlanType = ({ selectedPlanType, onPlanTypeSelected }) => {
  const isEmbed = !!window && !!window.ReactNativeWebView;
  return (
    <Box style={{ zIndex: 0, marginBottom: '12px' }}>
      <Title mt={36} mb={16}>
        Select Your Plan Type
      </Title>

      <Flex flexDirection={['row']} flexWrap="wrap" mb={32} alignItems="center">
        <Box
          onClick={() => onPlanTypeSelected('PPO')}
          style={{
            backgroundColor: selectedPlanType && selectedPlanType === 'PPO' ? '#344f79' : 'white',
            borderRadius: 12,
            padding: '4px 8px',
            color: selectedPlanType && selectedPlanType === 'PPO' ? 'white' : '#344f79',
            border: '1px solid #344f79',
            margin: 6,
            marginLeft: !isEmbed ? 0 : 6,
            cursor: 'pointer',
            width: '140px',
            borderRadius: '50px',
            textAlign: 'center',
          }}
        >
          <Text>PPO</Text>
        </Box>
        <Box
          onClick={() => onPlanTypeSelected('EPO')}
          style={{
            backgroundColor: selectedPlanType && selectedPlanType === 'EPO' ? '#344f79' : 'white',
            borderRadius: 12,
            padding: '4px 8px',
            color: selectedPlanType && selectedPlanType === 'EPO' ? 'white' : '#344f79',
            border: '1px solid #344f79',
            margin: 6,
            cursor: 'pointer',
            width: '140px',
            borderRadius: '50px',
            textAlign: 'center',
          }}
        >
          <Text>EPO / POS</Text>
        </Box>
        <Box
          onClick={() => onPlanTypeSelected('HMO')}
          style={{
            backgroundColor: selectedPlanType && selectedPlanType === 'HMO' ? '#344f79' : 'white',
            borderRadius: 12,
            padding: '4px 8px',
            color: selectedPlanType && selectedPlanType === 'HMO' ? 'white' : '#344f79',
            border: '1px solid #344f79',
            margin: 6,
            cursor: 'pointer',
            width: '140px',
            borderRadius: '50px',
            textAlign: 'center',
          }}
        >
          <Text>HMO</Text>
        </Box>
        <Box
          onClick={() => onPlanTypeSelected(`I don't know`)}
          style={{
            backgroundColor: selectedPlanType && selectedPlanType === `I don't know` ? '#344f79' : 'white',
            borderRadius: 12,
            padding: '4px 8px',
            color: selectedPlanType && selectedPlanType === `I don't know` ? 'white' : '#344f79',
            border: '1px solid #344f79',
            margin: 6,
            cursor: 'pointer',
            width: '140px',
            borderRadius: '50px',
            textAlign: 'center',
          }}
        >
          <Text>I don't know</Text>
        </Box>
      </Flex>
    </Box>
  );
};

const AddNewInsurance = ({ history, ...rest }) => {
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedPlanType, setSelectedPlanType] = useState();
  const [isDependant, setIsDependant] = useState();
  const [insuranceInfo, setInsuranceInfo] = useState();
  const [isDetailsSubmitted, setIsDetailsSubmitted] = useState(false);
  const [isNonAccepted, setNonAccepted] = useState(false);

  const isPrimary = !rest || !rest.location || !rest.location.pathname || rest.location.pathname.indexOf('secondary') <0

  useEffect(
    () => {
      async function delay() {
        await sleep(300);
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      }
      if (!!isDependant || !!isDetailsSubmitted) {
        delay();
      }
    },
    [isDependant, isDetailsSubmitted]
  );

  if (!!isNonAccepted) {
    return (
      <Wrapper>
        <Heading mb={'48px'}>{`Add New ${!!isPrimary ? 'Primary' : 'Secondary'} Insurance`}</Heading>
        <Box style={{ background: '#E7F0FF', borderRadius: '5px', padding: '24px 12px' }}>
          <Flex flexDirection={'row'}>
            <img src={ongoingAdvice} alt="" height="60px" />
            <Flex flexDirection={'column'} flex={1} ml="8px">
              <Text fontWeight={300}>Though we are currently out of network with your insurance plan, our services are intentionally designed to be affordable to everyone.</Text>
              <Text mt={3} fontWeight={300}>
                The cash price for a video visit is ${getMemberVisitPrice()} as a member.{' '}
              </Text>
              <Button
                onClick={() => {
                  if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage('closeMobilePane');
                  } else {
                    history.push('/my-care');
                  }
                }}
                variant={'primary'}
                mt={4}
                alignSelf="flex-start"
              >
                Continue
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Wrapper>
    );
  }

  return (
    <Mutation
      mutation={ADD_NEW_INSURANCE}
      refetchQueries={[{ query: GET_INSURANCE_ON_FILE }, { query: GET_HAS_INSURANCE_CARD_ON_DOCS }, {query: CURRENT_USER}]}
      awaitRefetchQueries
      onCompleted={() => {
        if (!!window && !!window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage('closeMobilePane');
          return;
        } else {
          history.push('/my-insurances');
        }
      }}
    >
      {(mutate, { loading }) => {
        if (!!loading) {
          return <PleaseWait style={{ position: 'absolute', left: '50%', top: '50%' }} />;
        }
        return (
          <Wrapper>
          <Heading mb={'48px'}>{`Add New ${!!isPrimary ? 'Primary' : 'Secondary'} Insurance`}</Heading>
            <SelectProvider
              selectedProvider={selectedProvider}
              onProviderSelectionChange={(provider) => {
                setSelectedProvider(provider);
                setSelectedPlanType(undefined);
                if (!!provider && !!provider.value && provider.value === 'Other') {
                  setNonAccepted(true);
                }
              }}
            />
            {!!selectedProvider &&
              selectedProvider.value !== 'Medicare' && (
                <SelectPlanType
                  selectedPlanType={selectedPlanType}
                  onPlanTypeSelected={(planType) => {
                    setSelectedPlanType(planType);
                    if (planType === 'HMO' || planType === 'EPO / POS') {
                      setNonAccepted(true);
                    }
                  }}
                />
              )}
            {!!selectedProvider &&
              (!!selectedPlanType || (selectedProvider.value && selectedProvider.value === 'Medicare')) && (
                <InsuranceInfo
                  isDependant={isDependant}
                  setIsDependant={(value) => {
                    setIsDependant(value);
                    if (!value) {
                      setIsDetailsSubmitted(true);
                    }
                  }}
                  submitInsuranceInfo={(info) => {
                    setInsuranceInfo(info);
                    setIsDetailsSubmitted(true);
                  }}
                  selectedProvider={selectedProvider}
                  initialValues={{}}
                  isDetailsSubmitted={isDetailsSubmitted}
                  unsubmitDetails={() => {
                    setIsDetailsSubmitted(false);
                  }}
                />
              )}
            {!!isDetailsSubmitted && (
              <UploadInsuranceCardNew
                onSubmit={async (front, back) => {
                  const text = `please add this patient's insurance in athena. The insurance information have been added to the patient's admin documents under the Insurance Card section`;
                  const attachments = [];
                  if (!!front) {
                    attachments.push({
                      ...front,
                      data: front.data.substr(front.data.indexOf(',') + 1),
                    });
                  }
                  if (!!back) {
                    attachments.push({
                      ...back,
                      data: back.data.substr(back.data.indexOf(',') + 1),
                    });
                  }

                  const vars = {
                    subscriberFullName: insuranceInfo ? `${insuranceInfo.subscriberFullName}` : null,
                    subscriberDob: insuranceInfo ? `${insuranceInfo.dob}` : null,
                    thread: {
                      text,
                      attachments,
                    },
                    isPrimary,
                    planType: `${(selectedProvider || {}).value} - ${selectedPlanType}`
                  };

                  await mutate({ variables: vars });
                }}
              />
            )}
          </Wrapper>
        );
      }}
    </Mutation>
  );
};

export default withRouter(withSession(AddNewInsurance));
