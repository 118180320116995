import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Mutation } from 'react-apollo';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';
import PleaseWait from '../../components/PleaseWait';
import ConfirmModal from '../../components/ConfirmModal';
import Modal from 'styled-react-modal';
import { CONVO_TREE, SAVE_TREE, INITIATE_CONVERSATION, SpecialModalBackground, ALL_CONVO_TREE } from './shared';
import { AddByHelpscoutIDModal } from './AddByHelpscoutIDModal';
import { InnerMainTree } from './InnerMainTree';
import { Availables } from './Availables';

const BoxRight = styled(Flex)`
  width: 33vw;
  max-width: 400px;
  justify-content: flex-end;
  padding: 0 8px;
`;

export const InnerMain = ({ data, dataAvailables, helpscoutId, close, userId, toastManager }) => {
  const [tree, setTree] = useState(data);
  const [availables, setAvailables] = useState(dataAvailables.filter((x) => data.findIndex((xx) => xx.id === x.id) < 0));
  const [hasChanged, setHasChanged] = useState(false);
  const [showAddById, setShowAddById] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [historic, setHistoric] = useState([]);
  const stateRef = useRef();
  // const historicRef = useRef();
  stateRef.current = hasChanged;
  // historicRef.current = historic;

  useEffect(
    () => {
      console.log({ data });
      setTree(data);
    },
    [data]
  );

  useEffect(
    () => {
      setAvailables(dataAvailables.filter((x) => data.findIndex((xx) => xx.id === x.id) < 0));
    },
    [dataAvailables]
  );

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      console.log('preventEsc', stateRef.current);
      event.stopPropagation();

      if (!!stateRef && !!stateRef.current) {
        setConfirmClose(true);
        return;
      }

      close();
    } else {
      // if (event.keyCode === 90 && (event.ctrlKey || event.metaKey)) {
      //   event.stopPropagation();
      //   console.log('CmdZ', historicRef);
      //   if (historicRef && historicRef.current && historicRef.current.length > 0) {
      //     setTree(historicRef.current[historicRef.current.length - 1].tree);
      //     setAvailables(historicRef.current[historicRef.current.length - 1].availables);
      //     let h = JSON.parse(JSON.stringify(h));
      //     h.pop();
      //     setHistoric(h);
      //   }
      // }
    }
  }, []);

  useEffect(
    () => {
      document.addEventListener('keydown', escFunction, true);

      return () => {
        document.removeEventListener('keydown', escFunction, true);
      };
    },
    [escFunction]
  );

  useEffect(
    () => {
      console.log('hasChanged', { tree, data });
      if (data.length !== tree.length) {
        setHasChanged(true);
        return;
      }
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element.id !== tree[index].id) {
          setHasChanged(true);
          return;
        }
      }

      setHasChanged(false);
    },
    [tree]
  );
  const handleOnDragEnd = (result) => {
    // console.log({ result });
    if (!result.source.droppableId) return;
    if (result.source.droppableId === 'characters' && (!result.destination || result.destination.droppableId === 'availables')) {
      // const h = JSON.parse(JSON.stringify(historic));
      // h.push({
      //   tree,
      //   availables,
      // });
      // setHistoric(h);
      const items = Array.from(tree);
      const [reorderedItem] = items.splice(result.source.index, 1);

      setTree(items);
      setAvailables([...availables, reorderedItem]);
      return;
    }
    if (!result.destination || !result.destination.droppableId) return;
    if (result.source.droppableId === result.destination.droppableId) {
      // const h = JSON.parse(JSON.stringify(historic));
      // h.push({
      //   tree,
      //   availables,
      // });
      // setHistoric(h);
      const items = Array.from(tree);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setTree(items);
    } else if (result.source && result.destination) {
      // const h = JSON.parse(JSON.stringify(historic));
      // h.push({
      //   tree,
      //   availables,
      // });
      // setHistoric(h);
      const items = Array.from(tree);
      const [el] = availables.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, el);

      setTree(items);
    }
  };
  const handleCloseAddByID = () => {
    setShowAddById(false);
  };

  if (!tree) return null;
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Flex flexDirection={'column'}>
        <Flex mb={4} style={{ borderBottom: '1px solid #344f79', paddingBottom: '12px' }}>
          <Heading flex={1}>Physician Conversations Order</Heading>
          {(!tree || tree.length < 1) && !hasChanged && (
            <Mutation
              mutation={INITIATE_CONVERSATION}
              refetchQueries={[{ query: CONVO_TREE, variables: { userId } }, { query: ALL_CONVO_TREE, variables: { userId } }]}
              awaitRefetchQueries={true}
              onCompleted={() => {}}
              onError={() => {
                if (!!toastManager) {
                  toastManager.add('An error occured', {
                    appearance: 'error',
                    autoDismissTimeout: 10000,
                  });
                }
              }}
            >
              {(save, { loading: isSaving }) => {
                return (
                  <Button
                    variant={'pink'}
                    disabled={!!isSaving}
                    style={{
                      padding: '4px 12px',
                      alignSelf: 'center',
                      marginRight: '12px',
                    }}
                    onClick={() => {
                      save({
                        variables: {
                          userId,
                        },
                      });
                    }}
                  >
                    Initiate Convo and tree
                  </Button>
                );
              }}
            </Mutation>
          )}
          {!hasChanged ? (
            <Button
              variant={'primary'}
              style={{
                padding: '4px 12px',
                alignSelf: 'center',
              }}
              onClick={close}
            >
              Close
            </Button>
          ) : (
            <>
              <Mutation
                mutation={SAVE_TREE}
                refetchQueries={[{ query: CONVO_TREE, variables: { userId } }]}
                awaitRefetchQueries={true}
                onCompleted={() => {
                  if (!!toastManager) {
                    toastManager.add('Conversations order saved', {
                      appearance: 'success',
                      autoDismissTimeout: 10000,
                    });
                  }

                  close();
                }}
                onError={() => {
                  if (!!toastManager) {
                    toastManager.add('An error occured', {
                      appearance: 'error',
                      autoDismissTimeout: 10000,
                    });
                  }
                }}
              >
                {(save, { loading: isSaving }) => {
                  return (
                    <>
                      <Button
                        variant={'primary'}
                        style={{
                          padding: '4px 12px',
                          alignSelf: 'center',
                          marginRight: '12px',
                        }}
                        onClick={() => {
                          const tr = tree.map((x) => x.id);
                          save({
                            variables: {
                              userId,
                              tree: tr,
                            },
                          });
                        }}
                      >
                        Save & Close
                      </Button>
                      {!!isSaving && (
                        <Modal isOpen={true} onBackgroundClick={() => {}} onEscapeKeydown={() => {}}>
                          <SpecialModalBackground>
                            <Box
                              style={{
                                margin: '0 auto',
                                alignSelf: 'center',
                                borderRadius: '20px',
                                position: 'relative',
                                backgroundColor: 'white',
                                padding: '24px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                maxWidth: '95vw',
                                minWidth: 'initial',
                                maxHeight: '95vh',
                              }}
                            >
                              <PleaseWait />
                            </Box>
                          </SpecialModalBackground>
                        </Modal>
                      )}
                    </>
                  );
                }}
              </Mutation>
              <Button
                variant={'pink'}
                style={{
                  padding: '4px 12px',
                  alignSelf: 'center',
                }}
                onClick={close}
              >
                Discard & Close
              </Button>
            </>
          )}
        </Flex>
        <Flex flex={1} style={{ height: '100%', minHeight: '80vh', width: '100%' }}>
          <Flex flexDirection={'column'} flex={1} style={{ height: '100%', minHeight: '80vh' }}>
            <Text fontWeight={500} fontSize={'18px'}>
              Physician conversation tree
            </Text>
            <Text fontWeight={300}>Ordered from newest (top) to oldest (bottom)</Text>
            <Text fontWeight={300} mb={4}>
              Drag and drop within the list to reorder or drag from the right list to add or to the right list to remove
            </Text>
            <InnerMainTree tree={tree} helpscoutId={helpscoutId} />
          </Flex>
          <BoxRight>
            <Flex style={{ width: '100%' }} flexDirection={'column'}>
              <Text fontWeight={500} fontSize={'18px'}>
                Other conversations found with Patient's Athena ID
              </Text>
              <Flex mb={4}>
                <Text mr={2}>or</Text>
                <Button
                  onClick={() => setShowAddById(true)}
                  variant={'outline'}
                  style={{
                    width: 'auto',
                    padding: '4px 12px',
                    alignSelf: 'center',
                    marginRight: '12px',
                  }}
                >
                  search by HS ID
                </Button>
              </Flex>
              <Flex id="ttxx">
                <Availables availables={availables} helpscoutId={helpscoutId} tree={tree} />
              </Flex>
            </Flex>
          </BoxRight>
        </Flex>
        {!!confirmClose && (
          <Modal
            isOpen={true}
            onBackgroundClick={() => {
              setConfirmClose(false);
            }}
            onEscapeKeydown={() => {}}
          >
            <SpecialModalBackground>
              <Box
                style={{
                  margin: '0 auto',
                  alignSelf: 'center',
                  borderRadius: '20px',
                  position: 'relative',
                  backgroundColor: 'white',
                  padding: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  maxWidth: '95vw',
                  minWidth: 'initial',
                  maxHeight: '95vh',
                }}
              >
                <Flex flex={1} flexDirection={'column'}>
                  <Heading textAlign={'center'}>Warning</Heading>
                  <Text mt={3} textAlign={'center'}>
                    You have unsaved changes. Do you want to proceed?
                  </Text>
                  <Flex mt={4} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                    <Flex flex={1} alignItems={'center'} justifyContent={'center'}>
                      <Button
                        variant={'outline'}
                        style={{
                          alignSelf: 'center',
                          marginRight: '12px',
                          width: 'auto',
                        }}
                        onClick={() => {
                          setConfirmClose(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Flex>
                    <Mutation
                      mutation={SAVE_TREE}
                      refetchQueries={[{ query: CONVO_TREE, variables: { userId } }]}
                      awaitRefetchQueries={true}
                      onCompleted={() => {
                        if (!!toastManager) {
                          toastManager.add('Conversations order saved', {
                            appearance: 'success',
                            autoDismissTimeout: 10000,
                          });
                        }

                        close();
                      }}
                      onError={() => {
                        if (!!toastManager) {
                          toastManager.add('An error occured', {
                            appearance: 'error',
                            autoDismissTimeout: 10000,
                          });
                        }
                      }}
                    >
                      {(save, { loading: isSaving }) => {
                        return (
                          <>
                            <Button
                              variant={'primary'}
                              style={{
                                alignSelf: 'center',
                                marginRight: '12px',
                              }}
                              onClick={() => {
                                const tr = tree.map((x) => x.id);
                                save({
                                  variables: {
                                    userId,
                                    tree: tr,
                                  },
                                });
                              }}
                            >
                              Save
                            </Button>
                            {!!isSaving && (
                              <Modal isOpen={true} onBackgroundClick={() => {}} onEscapeKeydown={() => {}}>
                                <SpecialModalBackground>
                                  <Box
                                    style={{
                                      margin: '0 auto',
                                      alignSelf: 'center',
                                      borderRadius: '20px',
                                      position: 'relative',
                                      backgroundColor: 'white',
                                      padding: '24px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flexDirection: 'column',
                                      maxWidth: '95vw',
                                      minWidth: 'initial',
                                      maxHeight: '95vh',
                                    }}
                                  >
                                    <PleaseWait />
                                  </Box>
                                </SpecialModalBackground>
                              </Modal>
                            )}
                          </>
                        );
                      }}
                    </Mutation>
                    <Button
                      variant={'pink'}
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={close}
                    >
                      Discard
                    </Button>
                  </Flex>
                </Flex>
              </Box>
            </SpecialModalBackground>
          </Modal>
        )}
      </Flex>
      {!!showAddById && (
        <ConfirmModal isOpen={showAddById} onClose={handleCloseAddByID} onBackgroundClick={handleCloseAddByID} onEscapeKeydown={handleCloseAddByID} childrenManaged>
          <AddByHelpscoutIDModal
            helpscoutId={helpscoutId}
            close={handleCloseAddByID}
            tree={tree}
            add={(e) => {
              tree.push(e);
              setHasChanged(true);

              handleCloseAddByID();
            }}
          />
        </ConfirmModal>
      )}
    </DragDropContext>
  );
};
