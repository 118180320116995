import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faFilePdf,
  faFileWord,
  faFilePowerpoint,
  faFileExcel,
  faFileCsv,
  faFileAudio,
  faFileVideo,
  faFileArchive,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';

export const GetComponentIcon = props => {
  switch (props.mimeType) {
    case 'application/pdf':
      return <FontAwesomeIcon {...props} icon={faFilePdf} />;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <FontAwesomeIcon {...props} icon={faFileWord} />;
    case 'application/mspowerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return <FontAwesomeIcon {...props} icon={faFilePowerpoint} />;
    case 'application/msexcel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <FontAwesomeIcon {...props} icon={faFileExcel} />;
    case 'text/csv':
      return <FontAwesomeIcon {...props} icon={faFileCsv} />;
    case 'audio/aac':
    case 'audio/wav':
    case 'audio/mpeg':
    case 'audio/mp3':
    case 'audio/ogg':
      return <FontAwesomeIcon {...props} icon={faFileAudio} />;
    case 'video/x-msvideo':
    case 'video/mpeg':
    case 'video/mp4':
    case 'video/ogg':
    case 'video/quicktime':
    case 'video/webm':
      return <FontAwesomeIcon {...props} icon={faFileVideo} />;
    case 'application/gzip':
    case 'application/zip':
    case 'application/rar':
      return <FontAwesomeIcon {...props} icon={faFileArchive} />;
    case 'text/plain':
    case 'text/richtext':
    case 'text/rtf':
      return <FontAwesomeIcon {...props} icon={faFileAlt} />;
    default:
      return <FontAwesomeIcon {...props} icon={faFile} />;
  }
};
