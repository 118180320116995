import React, { useState } from 'react';
import { Box, Button, Flex, Text } from 'rebass';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import PleaseWait from '../../../components/PleaseWait';
import { Query, withApollo } from 'react-apollo';
import { GET_HEALTHCOACH_FORM_TEMPLATES } from '../../../graphql';
import Modal from 'styled-react-modal';
import { SpecialModalBackground } from '../../adminReorderPhysicianConvo/shared';
import FormTargetsAddEdit from './FormTargetsAddEdit';
import TemplateDetailRow from './TemplateDetailRow';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { withToastManager } from 'react-toast-notifications';

const DELETE_TEMPLATE = gql`
  mutation deleteDeprecateHealthCoachTemplate($id: String!) {
    deleteDeprecateHealthCoachTemplate(id: $id) {
      newStatus
    }
  }
`;

const Templates = ({ history, client, toastManager }) => {
  const [isAddNew, setIsAddNew] = useState();
  const [isEdit, setIsEdit] = useState();
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Creator',
      Cell: (row) => {
        return <div>{row.original.creator ? `${row.original.creator.firstName}` : ''}</div>;
      },
    },
    {
      Header: 'Active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div>{row.original.isActive ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'Deprecated',
      accessor: 'isDeprecated',
      Cell: (row) => {
        return <div>{row.original.isDeprecated ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'Deleted',
      accessor: 'isDeleted',
      Cell: (row) => {
        return <div>{row.original.isDeleted ? '✓' : ''}</div>;
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div title="edit" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => onEdit(row.original.id)}>
              ✎
            </div>
            <div title="delete" style={{ cursor: 'pointer' }} onClick={() => onDelete(row.original.id)}>
              🗑
            </div>
          </Flex>
        );
      },
    },
  ];

  const onDelete = async (id) => {
    const { data } = await client.mutate({
      mutation: DELETE_TEMPLATE,
      variables: {
        id,
      },
      refetchQueries: [{ query: GET_HEALTHCOACH_FORM_TEMPLATES }],
      awaitRefetchQueries: true
    });

    if (data && data.deleteDeprecateHealthCoachTemplate && data.deleteDeprecateHealthCoachTemplate.newStatus) {
      if (data.deleteDeprecateHealthCoachTemplate.newStatus === 'DELETED') {
        toastManager.add('Template marked as deleted', {
          appearance: 'success',
          autoDismissTimeout: 10000,
        });
        return;
      } else if (data.deleteDeprecateHealthCoachTemplate.newStatus === 'DEPRECATED') {
        toastManager.add('Template marked as deprecated because some forms use it already', {
          appearance: 'success',
          autoDismissTimeout: 10000,
        });
        return;
      }
    }
    toastManager.add('An error occured', {
      appearance: 'error',
      autoDismissTimeout: 5000,
    });
  };

  const onEdit = (id) => {
    history.push(`/hc-template-builder?${id}`);
  };

  return (
    <Flex flexDirection={'column'} mb={4}>
      <Flex mb={3}>
        <Text flex={1} style={{ fontWeight: 500, fontSize: '20px' }}>
          Form Templates
        </Text>
        <Button variant={'primary'} style={{ padding: '4px 16px' }} onClick={() => history.push('/hc-template-builder')}>
          Add Template
        </Button>
      </Flex>

      <Query query={GET_HEALTHCOACH_FORM_TEMPLATES}>
        {({ data, loading, error }) => {
          if (!!loading) {
            return <PleaseWait />;
          }
          if (!data || !data.getHealthCoachFormTemplates || !!error) {
            return <Text textAlign={'center'}>An error occured</Text>;
          }
          return (
            <ReactTable
              data={data.getHealthCoachFormTemplates}
              columns={columns}
              SubComponent={(row) => <TemplateDetailRow row={row} />}
              defaultPageSize={data.getHealthCoachFormTemplates && data.getHealthCoachFormTemplates.length > 3 ? Math.min(data.getHealthCoachFormTemplates.length, 25) : 3}
              showPagination={data.getHealthCoachFormTemplates && data.getHealthCoachFormTemplates.length > 24}
            />
          );
        }}
      </Query>
    </Flex>
  );
};

export default withRouter(withApollo(withToastManager(Templates)));
