import moment from 'moment';
import * as Yup from 'yup';

export const LiveNoHypo = [
  {
    id: 'welcome',
    type: 'letstalk',
    hideCountText: true,
  },
  {
    id: 'previouslyDiagnosed',
    type: 'single',
    endpoint: 'medicalHistory',
    sendToAthena: true,
    prompt: 'When were you diagnosed with hypothyroidism?',
    isHPI: true,
    hpiIndex: 0,
    savingSection: 'diagnosed',
    isSaveTemp: true,
    options: [
      {
        questionId: '19',
        value: 'Select a year',
        answer: 'Y',
        giveDetails: true,
        inputType: 'number',
        min: 1900,
        max: parseInt(moment().format('YYYY'), 10),
        step: 1,
      },
      {
        questionId: '19',
        value: 'I never got diagnosed',
        answer: 'N',
      },
    ],
    // onSubmitHandler: selectedOptions => {
    //   console.log({selectedOptions})
    //   if (selectedOptions.answer!=='N') {
    //     return '/schedule';
    //   }

    //   return null;
    // },
    hpiComputer: (selectedValues, session) => {
      let age = moment().diff(session.dob, 'years');
      const initialString = `${session.firstName} is a ${age} years old ${session.gender.toLowerCase() === 'f' ? 'woman' : 'man'}`;
      if (!selectedValues || selectedValues.answer.toLowerCase === 'no') {
        return initialString + ' who never got diagnosed. ';
      }
      let year = `on ${selectedValues.value}`;
      try {
        year = `in ${selectedValues.value.substring(0, 4)}`;
      } catch {}

      return `${initialString}, diagnosed with hypothyroidism ${year}. `;
    },
    preCheckDisplay: (session, history) => {
      if (session && !!session.diagnoseFull && !!session.diagnoseFull.hasHypo && !!session.diagnoseFull.hypoNotes) {
        history.push('/schedule');
      }

      return true;
    },
  },
  {
    id: 'cancerhistory',
    type: 'single',
    endpoint: 'medicalHistory',
    sendToAthena: true,
    savingSection: 'cancerHistory',
    prompt: 'Do you or did you ever have a history of thyroid cancer?',
    options: [
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '297' : '301',
        value: 'No',
        answer: 'N',
        // goTo:'/schedule'
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '297' : '301',
        value: 'Yes',
        answer: 'Y',
        goToPage: 'disqualifiedcancer',
        placeholder: 'Tell us more',
        giveDetails: true,
        giveDetailsEnforce: true,
      },
    ],
    onSubmitHandler: (selectedOptions) => {
      console.log({ selectedOptions });
      // if (selectedOptions.answer!=='N') {
      //   return '/schedule';
      // }

      return null;
    },
  },
  {
    id: 'currentPregnant',
    type: 'single',
    endpoint: 'obgyn',
    onlyFirstTime: false,
    prompt: 'Are you currently pregnant or trying to be in next 12 months?',
    options: [
      { questionId: 'LOCAL.83', answer: 'Currently pregnant', value: 'Yes' },
      // {
      //   questionId: 'LOCAL.83',
      //   answer: 'Planning to be pregnant',
      //   value: 'Trying to conceive or planning to be pregnant in the next 12 months',
      // },
      { questionId: 'LOCAL.83', answer: 'Not pregnant', value: 'No' },
    ],
  },

  {
    id: 'p_tshshort',
    type: 'valuetsh',
    endpoint: 'loc',
    onlyFirstTime: false,
    savingSection: 'labs',
    unit: 'mU/L',
    title: 'What was your last TSH level?',
    isHPI: true,
    hpiIndex: 7,
    schema: Yup.object().shape({
      tsh: Yup.string().required('Required'),
    }),
    subTitle: 'TSH levels typically fall between 0.4 and 4.0 milliunits per liter (mU/L)',
    fakeDelayer: true,
    onSubmitHandler: (vals) => {
      // if (vals[0].answer > 4.7 || vals[0].answer < 0.5) {
      //   return 'disqualifiedtsh';
      // }
      // if (vals[0].answer < 2.5) {
      //   console.log('TOO LOW');
      //   return 'cannot_be_seen';
      // }
      return null;
    },
    hpiComputer: (selectedValues, session) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }

      return ` and ${session.gender.toLowerCase() === 'f' ? 'her' : 'his'} TSH level came back at ${selectedValues.value} mU/L.`;
    },
  },
  {
    id: 'p_lastcheckshort',
    type: 'lastlevelcheck',
    endpoint: 'loc',
    savingSection: 'labs',
    onlyFirstTime: false,
    title: 'When was your last thyroid level taken?',
    fakeDelayer: true,
    isHPI: true,
    hpiIndex: 6,
    onSubmitHandler: (vals, getState) => {
      const st = getState();
      
      const tshVal = st.values.find((x) => x.id === 'p_tshshort');

      if (!tshVal || !tshVal.values || tshVal.values.length < 1 || tshVal.values[0].answer === null || tshVal.values[0].answer === undefined) {
        return 'p_tshshort';
      }
      const dt = moment(vals[0].answer);
      if (moment().isAfter(dt.add(120, 'days'))) {
        return 'testFirst';
      }

      if (tshVal.values[0].answer < 2.5) {
        console.log('TOO LOW');
        return 'cannot_be_seen';
      }
      if (tshVal.values[0].answer > 4.4) {
        return `/schedule`;
      }

      return 'educational_visit'; //'https://localhost:3000/schedule?skp=true';
    },
    hpiComputer: (selectedValues, session) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }

      return `<br /><br />${session.firstName}'s last TSH level was on ${selectedValues.value}`;
    },
  },

  {
    id: 'np_tshshort',
    type: 'valuetsh',
    endpoint: 'loc',
    onlyFirstTime: false,
    savingSection: 'labs',
    unit: 'mU/L',
    title: 'What was your last TSH level?',
    isHPI: true,
    hpiIndex: 7,
    schema: Yup.object().shape({
      tsh: Yup.string().required('Required'),
    }),
    subTitle: 'TSH levels typically fall between 0.4 and 4.0 milliunits per liter (mU/L)',
    fakeDelayer: true,
    onSubmitHandler: (vals) => {
      console.log('np tshshort next')
      // if (vals[0].answer < 3) {
      //   console.log('TOO LOW');
      //   return 'cannot_be_seen';
      // }
      return null;
    },
    hpiComputer: (selectedValues, session) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }

      return ` and ${session.gender.toLowerCase() === 'f' ? 'her' : 'his'} TSH level came back at ${selectedValues.value} mU/L.`;
    },
  },
  {
    id: 'np_lastcheckshort',
    type: 'lastlevelcheck',
    endpoint: 'loc',
    savingSection: 'labs',
    onlyFirstTime: false,
    title: 'When was your last thyroid level taken?',
    fakeDelayer: true,
    isHPI: true,
    hpiIndex: 6,
    onSubmitHandler: (vals, getState) => {
      const st = getState();
      
      const tshVal = st.values.find((x) => x.id === 'np_tshshort');
      console.log({ tshVal });
      if (!tshVal || !tshVal.values || tshVal.values.length < 1 || tshVal.values[0].answer === null || tshVal.values[0].answer === undefined) {
        return 'np_tshshort';
      }
      
      const dt = moment(vals[0].answer);
      if (moment().isAfter(dt.add(120, 'days'))) {
        console.log('TOO OLD');
        return 'testFirst';
      }

      if (tshVal.values[0].answer > 4.4) {
        return `/schedule`;
      }

      if(tshVal.values[0].answer <3) {
        return 'cannot_be_seen';
      }

      return 'educational_visit'; //'https://localhost:3000/schedule?skp=true';
    },
    hpiComputer: (selectedValues, session) => {
      if (!selectedValues || selectedValues.length < 1) {
        return '';
      }

      return `<br /><br />${session.firstName}'s last TSH level was on ${selectedValues.value}`;
    },
  },
  {
    id: 't_currentPregnant',
    type: 'single',
    endpoint: 'obgyn',
    onlyFirstTime: false,
    prompt: 'Are you currently pregnant or trying to be in next 12 months?',
    options: [
      { questionId: 'LOCAL.83', answer: 'Currently pregnant', value: 'Yes' },
      // {
      //   questionId: 'LOCAL.83',
      //   answer: 'Planning to be pregnant',
      //   value: 'Trying to conceive or planning to be pregnant in the next 12 months',
      // },
      { questionId: 'LOCAL.83', answer: 'Not pregnant', value: 'No' },
    ],
  },

  {
    id: 'educational_visit',
    type: 'educationalVisit',
  },

  {
    id: 'cannot_be_seen',
    type: 'cannotBeSeen',
  },

  {
    id: 'testFirst',
    hideCountText: true,
    type: 'testFirst',
  },
  {
    id: 'disqualifiedcancer',
    type: 'disqualified',
    hideCountText: true,
    endpoint: 'disqualified',
    onlyFirstTime: false,
    prompt: 'disqualified',
    variant: 'cancer',
  },
];
