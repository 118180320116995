import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import Select, { Creatable } from 'react-select';
import { boolean } from 'yup';
import { Input } from '../../components/TextInput';

const SelectStyled = styled(Box)`
  .region-select__control {
    border: 1px solid rgba(53, 78, 121, 0.1);
    border-radius: 10px;
    padding: 0px 12px;
    background: #fff;
    min-width: 150px;
    font-size: 12px;

    &:hover {
      border-color: #eee;
    }

    &--is-focused {
      box-shadow: none;
    }

    .region-select__indicator-separator {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

export const PROPERTIES = [
  {
    label: 'age',
    value: 'athena.age',
    inputType: 'number',
    acceptedComparisons: ['<', '<=', '=', '>=', '<', '!='],
  },
  {
    label: 'is member',
    value: 'stripe.isMember',
    inputType: 'boolean',
  },
  {
    label: 'is insurance member',
    value: 'stripe.isInsuranceMember',
    inputType: 'boolean',
  },
  {
    label: 'is B2B',
    value: 'db.b2bUser',
    inputType: 'boolean',
  },
  {
    label: 'is mobile user',
    value: 'db.isMobileUser',
    inputType: 'boolean',
  },
  // {
  //   label: 'email',
  //   value: 'db.email',
  //   inputType: 'string',
  // },
  // {
  //   label: 'test date',
  //   value: 'db.dtt',
  //   inputType: 'date',
  // },
  {
    label: 'department id',
    value: 'athena.departmentId',
    inputType: 'string',
  },
  {
    label: 'gender',
    value: 'athena.gender',
    inputType: 'string',
    note: `(Write "F" for female ; "M" for male without quotes)`,
  },
  {
    label: 'is diagnosed',
    value: 'athena.isDiagnosed',
    inputType: 'boolean',
  },
  {
    label: 'is Mobile App User',
    value: 'db.isMobileAppUser',
    inputType: 'boolean',
  },
  {
    label: 'Date of birth',
    value: 'db.dob',
    inputType: 'date',
  },
  {
    label: 'has hypo',
    value: 'athena.hasHypo',
    inputType: 'boolean',
  },
  {
    label: 'has hashi',
    value: 'athena.hasHashi',
    inputType: 'boolean',
  },
  // ##################### NEW
  {
    label: 'user id (not Athena ID)',
    value: 'db.id',
    inputType: 'string',
    note: `(This is the local database user id not the Athena ID.)`,
  },
  {
    label: 'intake status',
    value: 'db.intakeStatus',
    inputType: 'string',
  },
  {
    label: 'intake date',
    value: 'db.intakeDate',
    inputType: 'date',
  },
  {
    label: 'meno Intake Status',
    value: 'db.menoIntakeStatus',
    inputType: 'string',
  },
  {
    label: 'meno Intake Date',
    value: 'db.menoIntakeDate',
    inputType: 'date',
  },
  {
    label: 'provider Id',
    value: 'athena.providerId',
    inputType: 'string',
  },
  {
    label: 'primary Provider Id',
    value: 'athena.primaryProviderId',
    inputType: 'string',
  },
  {
    label: 'medication History Consent Verified',
    value: 'athena.medicationHistoryConsentVerified',
    inputType: 'boolean',
  },
  {
    label: 'can schedule OON',
    value: 'db.oonSchedulable',
    inputType: 'boolean',
  },
  {
    label: 'hypo Date',
    value: 'athena.hypoDate',
    inputType: 'date',
  },
  {
    label: 'must Complete Profile',
    value: 'db.mustCompleteProfile',
    inputType: 'boolean',
  },
  // {
  //   label: 'must Merge Account',
  //   value: 'db.mustMergeAccount',
  //   inputType: 'boolean',
  // },
  {
    label: 'is Community User',
    value: 'db.isCommunityUser',
    inputType: 'boolean',
  },
  {
    label: 'Athena ID',
    value: 'db.athenaId',
    inputType: 'stringAthenaId',
    note: `Warning: Will be handled on backend and will always be treated as IN array! DO NOT MIX WITH OTHER FILTERS!!! Any other filters will be ignored. User testing won't be correct`,
  },
  {
    label: 'must Complete Baseline Survey',
    value: 'db.mustCompleteBaselineSurvey',
    inputType: 'boolean',
  },
  {
    label: 'baseline Status',
    value: 'db.baselineStatus',
    inputType: 'string',
  },
  {
    label: 'must Schedule Onboarding Call',
    value: 'db.mustScheduleOnboardingCall',
    inputType: 'boolean',
  },
  {
    label: 'need Insurance Completion',
    value: 'db.needInsuranceCompletion',
    inputType: 'boolean',
  },

  {
    label: 'hypo Notes',
    value: 'athena.hypoNotes',
    inputType: 'string',
  },

  {
    label: 'hashi Notes',
    value: 'athena.hashiNotes',
    inputType: 'string',
  },

  {
    label: 'has Appointment',
    value: 'athena.hasAppointment',
    inputType: 'boolean',
  },

  {
    label: 'has Appointment Nutritionist',
    value: 'athena.hasAppointmentNutritionist',
    inputType: 'boolean',
  },

  // {
  //   label: 'reason',
  //   value: 'db.reason',
  //   inputType: 'string',
  // },

  {
    label: 'timeZone Name',
    value: 'athena.timeZoneName',
    inputType: 'string',
  },

  {
    label: 'timeZone Abbreviation',
    value: 'athena.tzAbbr',
    inputType: 'string',
  },

  {
    label: 'address City',
    value: 'athena.addressCity',
    inputType: 'string',
  },

  {
    label: 'address State',
    value: 'athena.addressState',
    inputType: 'string',
  },

  {
    label: 'address Zip',
    value: 'athena.addressZip',
    inputType: 'string',
  },

  {
    label: 'card Brand',
    value: 'athena.cardBrand',
    inputType: 'string',
  },

  {
    label: 'card Exp Month',
    value: 'athena.cardExpMonth',
    inputType: 'number',
  },

  {
    label: 'card Exp Year',
    value: 'athena.cardExpYear',
    inputType: 'number',
  },

  {
    label: 'eligibility can Book',
    value: 'db.eligibilitycanBook',
    inputType: 'boolean',
  },

  {
    label: 'eligibility can Purchase',
    value: 'db.eligibilitycanPurchase',
    inputType: 'boolean',
  },

  {
    label: 'eligibility consultation',
    value: 'db.eligibilityconsultation',
    inputType: 'boolean',
  },

  {
    label: 'eligibility purchase',
    value: 'db.eligibilitypurchase',
    inputType: 'boolean',
  },

  {
    label: 'eligibility pharmacy',
    value: 'db.eligibilitypharmacy',
    inputType: 'boolean',
  },

  {
    label: 'eligibility lab Center',
    value: 'db.eligibilitylabCenter',
    inputType: 'boolean',
  },

  {
    label: 'eligibility practitioner',
    value: 'db.eligibilitypractitioner',
    inputType: 'boolean',
  },

  {
    label: 'eligibility can Book Nutritionist',
    value: 'db.eligibilitycanBookNutritionist',
    inputType: 'boolean',
  },

  {
    label: 'eligibility can Book Async',
    value: 'db.eligibilitycanBookAsync',
    inputType: 'boolean',
  },

  {
    label: 'eligibility can Book Perimenopause',
    value: 'db.eligibilitycanBookPerimenopause',
    inputType: 'boolean',
  },

  {
    label: 'eligibility can Book Postmenopause',
    value: 'db.eligibilitycanBookPostmenopause',
    inputType: 'boolean',
  },

  {
    label: 'pharmacy Place Id',
    value: 'db.pharmacyPlaceId',
    inputType: 'string',
  },

  {
    label: 'pharmacy Name',
    value: 'db.pharmacyName',
    inputType: 'string',
  },

  {
    label: 'labCenter Place Id',
    value: 'db.labCenterPlaceId',
    inputType: 'string',
  },

  {
    label: 'lab Center Name',
    value: 'db.labCenterName',
    inputType: 'string',
  },

  {
    label: 'b2b pending Approval',
    value: 'db.b2bpendingApproval',
    inputType: 'boolean',
  },
  {
    label: 'b2b must Verify',
    value: 'db.b2bmustVerify',
    inputType: 'boolean',
  },

  {
    label: 'b2b verification Sent',
    value: 'db.b2bverificationSent',
    inputType: 'boolean',
  },

  {
    label: 'b2b verification Sent Date',
    value: 'db.b2bverificationSentDate',
    inputType: 'date',
  },

  {
    label: 'b2b kit',
    value: 'db.b2bkit',
    inputType: 'boolean',
  },

  {
    label: 'b2b vitamins',
    value: 'db.b2bvitamins',
    inputType: 'boolean',
  },

  {
    label: 'b2b membership',
    value: 'db.b2bmembership',
    inputType: 'boolean',
  },

  {
    label: 'b2b visits',
    value: 'db.b2bvisits',
    inputType: 'boolean',
  },

  {
    label: 'b2b async',
    value: 'db.b2basync',
    inputType: 'boolean',
  },

  {
    label: 'b2b nutritionist',
    value: 'db.b2bnutritionist',
    inputType: 'boolean',
  },

  {
    label: 'dedicated Care Manager Id',
    value: 'db.dedicatedCareManagerId',
    inputType: 'string',
  },
];

export const OPERATORS = {
  stringAthenaId: [
    {
      label: `is in`,
      value: 'in',
    },
  ],
  string: [
    {
      label: 'equals',
      value: '=',
    },
    {
      label: 'not equals',
      value: '!=',
    },
    {
      label: 'contains',
      value: '%',
    },
    {
      label: `doesn't contains`,
      value: '!%',
    },
    {
      label: `is in`,
      value: 'in',
    },
    {
      label: `is not in`,
      value: '!in',
    },
    {
      label: `starts with`,
      value: '%s',
    },
    {
      label: `doesn't start with`,
      value: '!%s',
    },
    {
      label: `ends with`,
      value: 's%',
    },
    {
      label: `doesn't end with`,
      value: '!s%',
    },
    {
      label: `is set`,
      value: '!!',
    },
    {
      label: `is not set`,
      value: '!',
    },
  ],
  number: [
    {
      label: 'equals',
      value: '=',
    },
    {
      label: 'not equals',
      value: '!=',
    },
    {
      label: 'less than',
      value: '<',
    },
    {
      label: `less or equals to`,
      value: '<=',
    },
    {
      label: `greater or equals to`,
      value: '>=',
    },
    {
      label: `greater than`,
      value: '>',
    },
  ],
  boolean: [
    {
      label: 'is true',
      value: 'true',
    },
    {
      label: 'is false',
      value: 'false',
    },
  ],
  date: [
    {
      label: 'is in the last',
      value: 'lastN',
    },
    {
      label: 'is at least',
      value: 'atLeastN',
    },
    {
      label: 'is between',
      value: 'between',
    },
    {
      label: 'is in the next',
      value: 'inNextN',
    },
    {
      label: 'is before',
      value: 'before',
    },
    {
      label: 'is after',
      value: 'after',
    },
    {
      label: 'is today',
      value: 'today',
    },
    {
      label: 'day is in the last',
      value: 'dayInLastN',
    },
    {
      label: 'day is in the next',
      value: 'dayInNextN',
    },
    {
      label: 'day is in this month',
      value: 'dayIsInMonth',
    },
    {
      label: 'day is in month of',
      value: 'dayIsInMonthN',
    },
  ],
};

const createOption = (label) => ({
  label,
  value: label,
});

const DATE_UNITS = [
  {
    label: 'hours',
    value: 'hours',
  },
  {
    label: 'days',
    value: 'days',
  },
  {
    label: 'weeks',
    value: 'weeks',
  },
  {
    label: 'months',
    value: 'months',
  },
  {
    label: 'years',
    value: 'years',
  },
];

const MONTHS_LIST = [
  {
    label: 'January',
    value: '1',
  },
  {
    label: 'February',
    value: '2',
  },
  {
    label: 'March',
    value: '3',
  },
  {
    label: 'April',
    value: '4',
  },
  {
    label: 'May',
    value: '5',
  },
  {
    label: 'June',
    value: '6',
  },
  {
    label: 'July',
    value: '7',
  },
  {
    label: 'August',
    value: '8',
  },
  {
    label: 'September',
    value: '9',
  },
  {
    label: 'October',
    value: '10',
  },
  {
    label: 'November',
    value: '11',
  },
  {
    label: 'December',
    value: '12',
  },
];

const DateOperation = ({ operator, value, setValue }) => {
  if (['before', 'after'].indexOf(operator) > -1) {
    return <Input type="date" value={value} onChange={(e) => setValue(e.target.value)} />;
  }

  if (['lastN', 'atLeastN', 'inNextN', 'dayInNextN', 'dayInLastN'].indexOf(operator) > -1) {
    // date input + dropdown select unit
    return (
      <Flex alignItems={'center'}>
        <Input
          containerStyle={{ flex: 'initial' }}
          type="number"
          value={(value || {}).value}
          onChange={(e) =>
            setValue({
              ...value,
              value: e.target.value,
            })
          }
        />
        <SelectStyled ml={2}>
          <Select
            classNamePrefix="region-select"
            options={DATE_UNITS}
            name="primary_sex"
            value={DATE_UNITS.find((x) => x && x.value === (value || {}).unit)}
            placeholder="select property"
            onChange={(option) => {
              setValue({
                ...value,
                unit: option.value,
              });
            }}
            isSearchable={true}
          />
        </SelectStyled>
        {operator === 'atLeastN' && <Text ml={2}>ago</Text>}
      </Flex>
    );
  }

  if (['today', 'dayIsInMonth'].indexOf(operator) > -1) {
    // nothing
    return null;
  }

  if (['dayIsInMonthN'].indexOf(operator) > -1) {
    // dropdown select month
    return (
      <SelectStyled style={{ minWidth: '100%' }}>
        <Select
          classNamePrefix="region-select"
          options={MONTHS_LIST}
          name="primary_sex"
          value={MONTHS_LIST.find((x) => x && x.value === value)}
          placeholder="select property"
          onChange={(option) => {
            setValue(option.value);
          }}
          isSearchable={true}
        />
      </SelectStyled>
    );
  }
};

const loadGroupedProperties = () => {
  const gp = [
    {
      label: 'Database',
      options: [],
    },
    {
      label: 'Athena',
      options: [],
    },
    {
      label: 'Stripe',
      options: [],
    },
  ];
  for (let index = 0; index < PROPERTIES.length; index++) {
    const element = PROPERTIES[index];
    if (element.value.indexOf('db.') > -1) {
      gp[gp.findIndex((x) => x.label === 'Database')].options.push(element);
    } else if (element.value.indexOf('stripe.') > -1) {
      gp[gp.findIndex((x) => x.label === 'Stripe')].options.push(element);
    } else if (element.value.indexOf('athena.') > -1) {
      gp[gp.findIndex((x) => x.label === 'Athena')].options.push(element);
    } else {
      gp[gp.findIndex((x) => x.label === 'Database')].options.push(element);
    }
  }

  for (let index = 0; index < gp.length; index++) {
    const element = gp[index];
    element.options = element.options.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  }

  return gp;
};

export const FilterEdit = ({ parentId, edit, onSave }) => {
  const [groupedProperties] = useState(loadGroupedProperties());
  const [property, setProperty] = useState();
  const [operator, setOperator] = useState();
  const [value, setValue] = useState();
  const [isValid, setIsValid] = useState();
  const [multiInputValue, setmultiInputValue] = React.useState('');
  const [multivalue, setmultiValue] = React.useState([]);

  useEffect(() => {
    if (!edit || !edit.property) return;
    const prop = PROPERTIES.find((x) => x.value === edit.property.value);
    if (!prop) return;
    const operator = OPERATORS[prop.inputType].find((x) => x && x.value === edit.filter);
    const val = edit.value;
    if (edit.filter === 'in' || edit.filter === '!in') {
      let l = edit.value.split(';').map((x) => {
        return createOption(x);
      });
      setmultiValue(l);
    }

    setProperty(prop);
    setOperator(operator);
    setValue(val);
  }, []);

  useEffect(
    () => {
      setIsValid(false);

      console.log({ property, operator });
      if (!property || !property.value || !operator || !operator.value) {
        return;
      }

      if (property.inputType === 'boolean') {
        setIsValid(true);
        return;
      }

      if (operator.value === 'in' || operator.value === '!in') {
        setIsValid(multivalue.length > 0);
        return;
      }

      if (property.inputType === 'date') {
        if (['today', 'dayIsInMonth'].indexOf(operator.value) > -1) {
          setIsValid(true);
          return;
        }

        if (['lastN', 'atLeastN', 'inNextN', 'dayInNextN', 'dayInLastN'].indexOf(operator.value) > -1) {
          console.log({ value });

          setIsValid(!!value && value.value && value.unit && value.unit.length > 0);
          return;
        }

        setIsValid(!!value);
        return;
      }

      setIsValid((!!value || (value !== null && value < 1)) && value.length > 0);
    },
    [property, operator, value, multivalue, (value || {}).value, (value || {}).unit]
  );

  const pasteMulti = (e) => {
    if (e && e.clipboardData) {
      const d = e.clipboardData.getData('text');
      if (!!d) {
        const l = d.split(';').map((x) => {
          return createOption(x);
        });
        setmultiValue(l);
      }
    }
  };

  const submit = () => {
    let v = value;

    if (operator.value === 'in' || operator.value === '!in') {
      v = multivalue.map((x) => x.value).join(';');
    }

    // if (['lastN', 'atLeastN', 'inNextN', 'dayInNextN', 'dayInLastN'].indexOf(operator.value) > -1) {
    //   v = JSON.stringify(value);
    // }

    const res = {
      datatype: 'content',
      id: edit && edit.id ? edit.id : `tmp_${new Date()}`,
      property: {
        label: property.label,
        value: property.value,
      },
      filter: operator.value,
      value: v,
    };

    // onSave(parent group id, id to edit (if edit mode), data)
    onSave(parentId, edit && edit.id ? edit.id : null, res);
  };
  const handleKeyDown = (event) => {
    if (!multiInputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setmultiValue((prev) => [...prev, createOption(multiInputValue)]);
        setmultiInputValue('');
        event.preventDefault();
    }
  };

  return (
    <Flex flexDirection={'column'} flex={1} m={3}>
      <Heading mb={4}>{!edit ? 'New' : 'Edit'} filter condition</Heading>
      <Text fontSize={'13px'} mb={2}>
        Property
      </Text>
      <SelectStyled style={{ minWidth: '100%' }}>
        <Select
          classNamePrefix="region-select"
          options={groupedProperties}
          name="primary_sex"
          value={PROPERTIES.find((x) => x && x.value === (property || {}).value)}
          placeholder="select property"
          onChange={(option) => {
            setOperator(null);
            setProperty(option);
            setValue(null);
          }}
          isSearchable={true}
        />
      </SelectStyled>

      <Box mt={3} />

      {!!property && (
        <>
          <Text fontSize={'13px'} mb={2} mt={3}>
            Operator
          </Text>
          <SelectStyled style={{ minWidth: '100%' }}>
            <Select
              classNamePrefix="region-select"
              options={OPERATORS[property.inputType]}
              name="primary_sex"
              value={!!operator && operator.value ? OPERATORS[property.inputType].find((x) => x && x.value === (operator || {}).value) : null}
              placeholder="select operator"
              mul
              onChange={(option) => {
                setValue(null);
                setOperator(option);
              }}
              isSearchable={true}
            />
          </SelectStyled>
        </>
      )}
      {!!property &&
        !!property.note && (
          <Text mt={3} fontWeight={300}>
            {property.note}
          </Text>
        )}

      {!!property &&
        !!operator &&
        property.inputType &&
        operator.value && (
          <>
            <Text fontSize={'13px'} mb={2} mt={3}>
              Value
            </Text>
            <Flex flexDirection={'column'}>
              {property.inputType === 'string' || property.inputType === 'stringAthenaId' ? (
                <>
                  {operator.value !== 'in' && operator.value !== '!in' ? (
                    <Input onChange={(e) => setValue(e.target.value)} />
                  ) : (
                    <SelectStyled style={{ minWidth: '100%' }} onPaste={pasteMulti}>
                      <Creatable
                        classNamePrefix="region-select"
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        inputValue={multiInputValue}
                        onChange={(newValue) => setmultiValue(newValue)}
                        onInputChange={(newValue) => setmultiInputValue(newValue)}
                        onKeyDown={handleKeyDown}
                        placeholder="Type something and press enter..."
                        value={multivalue}
                      />
                    </SelectStyled>
                  )}
                </>
              ) : property.inputType === 'number' ? (
                <>
                  <Input type="number" value={value} onChange={(e) => setValue(e.target.value)} />
                </>
              ) : property.inputType === 'date' ? (
                <>
                  <DateOperation value={value} setValue={setValue} operator={operator.value} />
                </>
              ) : null}
            </Flex>
          </>
        )}

      <Flex mt={4}>
        <Button variant={'primary'} disabled={!isValid} onClick={submit}>
          {!edit ? 'Add' : 'Save changes'}
        </Button>
      </Flex>

      <Flex flex={1} />
    </Flex>
  );
};
