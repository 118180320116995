import React, { useReducer, useRef } from 'react';
import { Box, Card, Flex, Heading, Image, Text } from 'rebass';
import { Line, XAxis, YAxis, Tooltip, ReferenceLine, ComposedChart, Area, ResponsiveContainer, CartesianGrid, Label, ReferenceArea, LineChart } from 'recharts';
import { GET_RANDOM_ARTICLES } from '../../graphql';
import styled from 'styled-components';
import { sleep } from '../../utils/sleep';
import { withRouter } from 'react-router';
import PleaseWait from '../../components/PleaseWait';
import { Skeleton } from '../../components/loadingSkeleton/Skeleton';
import { Query } from 'react-apollo';

const NoScrollBarContainer = styled(Flex)`
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Container = styled(Flex)`
  width: 100%;
  max-width: 700px;

  @media screen and (max-width: 480px) {
    width: 100vw;
    max-width: 100vw;
    padding: 0 20px;
    overflow-x: hidden;
  }
`;

const ChartScrollButtons = styled(Box)`
  /* position: absolute; */
  opacity: 0.75;
  top: calc(50% - 30px);
  background: #ffffff;
  border-radius: 14px;
  align-self: center;

  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #344f;
  transition: all 0.3s ease;
  z-index: 100;

  &:hover {
    opacity: 1;
  }
`;

const Img = styled(Image)`
  transition: 0.3s all ease-in-out;
`;

const ArticleCardContent = styled(Card)`
  margin-right: 24px;
  cursor: pointer;
  &:hover ${Img} {
    transform: scale(1.1);
  }
  @media screen and (max-width: 480px) {
    &.individual {
      margin-right: 0;
    }
  }
`;

const ArticleCardContainer = styled(Flex)`
  transition: 0.3s all ease-in-out;
  & > * {
    transition: 0.3s all ease-in-out;
  }
  &:hover > * {
    opacity: 0.5;
  }
  &:hover > :hover {
    opacity: 1;
  }

  @media screen and (max-width: 480px) {
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 24px;
    padding-left: 40px;
    padding-right: 20px;
    &:hover > * {
      opacity: 1;
    }
  }
`;

const Title = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ServicesContainer = styled(Flex)`
  @media screen and (max-width: 480px) {
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 24px;
  }
`;

const VirtualPadder = styled(Box)`
  width: 20px;
  min-width: 20px;
  @media screen and (min-width: 480px) {
    display: none;
  }
`;

const FlexScrollButtonWrapper = styled(Flex)`
  background: ${(props) => (props.isLeft ? ' linear-gradient(to left, rgba(253, 245, 242, 0), rgb(253 245 242))' : ' linear-gradient(to right, rgba(253, 245, 242, 0), rgb(253 245 242))')};
  z-index: 10;
  display: ${(props) => props.toDisplay};
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const ArticleCard = ({ article, onClick, className }) => {
  return (
    <ArticleCardContent className={className} onClick={onClick} style={{ minWidth: '200px', width: '200px', height: '240px', overflow: 'hidden', marginBottom: '32px', borderRadius: '10px' }}>
      <Flex flexDirection={'column'} style={{ position: 'relative', height: '100%' }}>
        <Img src={`${article.thumbnailUrl}`} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
        <Flex style={{ height: '84px', left: 0, right: 0, bottom: 0, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', position: 'absolute', padding: '6px' }}>
          <Title textAlign={'center'}>{article.title}</Title>
        </Flex>
      </Flex>
    </ArticleCardContent>
  );
};

export const ArticleCardLoading = ({ className }) => {
  return (
    <ArticleCardContent className={className} style={{ minWidth: '200px', width: '200px', height: '240px', overflow: 'hidden', marginBottom: '32px', borderRadius: '10px' }}>
      <Flex flexDirection={'column'} style={{ position: 'relative', height: '100%', paddingTop: '70px' }}>
        <PleaseWait
          text={' '}
          spinner={{
            sColor: '#ECEFF2',
            pColor: '#909FB5',
          }}
        />
        <Flex style={{ height: '84px', left: 0, right: 0, bottom: 0, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', position: 'absolute', padding: '6px' }}>
          <Skeleton width="75px" height="16px" />
        </Flex>
      </Flex>
    </ArticleCardContent>
  );
};

const Articles = ({ history }) => {
  const refBox = useRef(null);
  const intervalRef = React.useRef(null);
  const [, forceupdate] = useReducer((x) => x + 1, 0);

  const scrollWidth = refBox.current ? refBox.current.scrollWidth : null;
  const scrollLeft = refBox.current ? refBox.current.scrollLeft : null;
  const clientWidth = refBox.current ? refBox.current.clientWidth : null;

  const scroll = (scrollOffset) => {
    if (refBox && refBox.current)
      if (scrollWidth && scrollLeft && clientWidth && scrollLeft + clientWidth >= scrollWidth && scrollOffset > 0) {
        stopCounter();
        return;
      } else if (scrollOffset < 0 && scrollLeft <= 0) {
        stopCounter();
        return;
      }
    refBox.current.scrollLeft += scrollOffset;
  };

  React.useEffect(() => {
    async function scrollToRight() {
      await sleep(300);
      if (refBox.current) {
        refBox.current.scrollLeft = refBox.current.scrollWidth - refBox.current.clientWidth;
      }
      forceupdate();
    }

    // scrollToRight();
    return () => stopCounter(); // when App is unmounted we should stop counter
  }, []);

  const startCounter = (orientation) => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      scroll(orientation === 'LEFT' ? -20 : 20);
    }, 10);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      forceupdate();
    }
  };
  return (
    <Query
      query={GET_RANDOM_ARTICLES}
      variables={{
        howMany: 11,
      }}
    >
      {({ data, loading, error }) => {
        return (
          <Container flexDirection="column" mt="32px" style={{ position: 'relative' }}>
            <Heading mb="32px">Learn More About Hypothyroidism</Heading>
            <ServicesContainer>
              <FlexScrollButtonWrapper isLeft toDisplay={scrollWidth && (scrollLeft !== null && scrollLeft !== undefined) && clientWidth && scrollLeft <= 0 ? 'none' : ''}>
                <ChartScrollButtons style={{ marginRight: '24px' }} onMouseDown={() => startCounter('LEFT')} onMouseUp={stopCounter} onMouseLeave={stopCounter}>
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.1405 21.6667L16.1329 21.6667C16.0333 21.667 15.9349 21.645 15.8449 21.6023L15.6306 22.0541L15.8449 21.6023C15.7552 21.5598 15.6761 21.4977 15.6134 21.4206C15.6132 21.4204 15.6131 21.4202 15.6129 21.42L9.9791 14.4215L9.97912 14.4215L9.97588 14.4175C9.87782 14.2982 9.82422 14.1486 9.82422 13.9942C9.82422 13.8402 9.87749 13.6911 9.97496 13.572L15.8071 6.57343L15.8075 6.57294C15.9207 6.43667 16.0835 6.35097 16.2599 6.3347C16.4364 6.31844 16.6121 6.37293 16.7484 6.48619L17.0639 6.10654L16.7484 6.48619C16.8846 6.59946 16.9703 6.76222 16.9866 6.93867L17.4845 6.89276L16.9866 6.93867C17.0028 7.11497 16.9485 7.29052 16.8354 7.42675C16.8353 7.42685 16.8352 7.42696 16.8351 7.42707L11.6206 13.6798L11.3581 13.9946L11.6153 14.3138L16.6553 20.5671L16.6553 20.5671L16.6605 20.5734C16.742 20.6712 16.7938 20.7904 16.8097 20.9168C16.8256 21.0431 16.805 21.1714 16.7503 21.2864C16.6956 21.4014 16.6091 21.4984 16.501 21.5658C16.3929 21.6332 16.2678 21.6682 16.1405 21.6667L16.1405 21.6667Z"
                      fill="black"
                      stroke="#344F7A"
                    />
                  </svg>
                </ChartScrollButtons>
              </FlexScrollButtonWrapper>

              <NoScrollBarContainer
                style={{
                  marginRight: scrollWidth && scrollLeft && clientWidth && scrollLeft + clientWidth >= scrollWidth ? 0 : -62,
                  marginLeft: scrollWidth && (scrollLeft !== null && scrollLeft !== undefined) && clientWidth && scrollLeft <= 0 ? 0 : -62,
                }}
                ref={refBox}
              >
                {!!loading ? (
                  <ArticleCardContainer flexDirection={'row'} style={{ width: 12 * 224 }}>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((x) => {
                      return <ArticleCardLoading />;
                    })}
                  </ArticleCardContainer>
                ) : (
                  <ArticleCardContainer flexDirection={'row'} style={{ width: (((data || {}).getRandomArticlesFromWeb || []).length + 1) * 224 }}>
                    {/* <VirtualPadder /> */}
                    {((data || {}).getRandomArticlesFromWeb || []).map((x) => {
                      return (
                        <ArticleCard
                          article={x}
                          key={x.id}
                          onClick={() =>
                            history.push({
                              pathname: `/articles/${encodeURIComponent(x.title)}`,
                              state: {
                                ...x,
                              },
                            })
                          }
                        />
                      );
                    })}
                    <Flex
                      onClick={() => history.push('/articles')}
                      style={{
                        minWidth: '200px',
                        width: '200px',
                        height: '240px',
                        marginRight: '24px',
                        overflow: 'hidden',
                        marginBottom: '32px',
                        borderRadius: '10px',
                        padding: '12px',
                        backgroundColor: 'transparent',
                        flexDirection: 'column',
                        cursor: 'pointer',
                      }}
                    >
                      <Flex flex={1} alignItems="center" justifyContent={'center'}>
                        <Flex alignItems="center" justifyContent={'center'} style={{ background: 'white', height: '64px', width: '64px', borderRadius: '50%' }}>
                          <svg width="32" height="32" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M869 487.8L491.2 159.9C488.3 157.4 484.6 156 480.7 156H392.2C384.8 156 381.4 165.2 387 170L737.2 474H152C147.6 474 144 477.6 144 482V542C144 546.4 147.6 550 152 550H737.1L386.9 854C381.3 858.9 384.7 868 392.1 868H483.6C485.5 868 487.4 867.3 488.8 866L869 536.2C872.462 533.19 875.238 529.472 877.14 525.297C879.042 521.122 880.026 516.588 880.026 512C880.026 507.412 879.042 502.878 877.14 498.703C875.238 494.528 872.462 490.81 869 487.8V487.8Z"
                              fill="#344f79"
                            />
                          </svg>
                        </Flex>
                      </Flex>

                      <Text textAlign={'center'} style={{ textDecoration: 'underline', fontWeight: 400 }} mb={4}>
                        View All
                      </Text>
                    </Flex>
                    {/* <VirtualPadder /> */}
                  </ArticleCardContainer>
                )}
              </NoScrollBarContainer>
              <FlexScrollButtonWrapper toDisplay={scrollWidth && scrollLeft && clientWidth && scrollLeft + clientWidth >= scrollWidth ? 'none' : ''}>
                <ChartScrollButtons
                  style={{ marginLeft: '24px', display: scrollWidth && scrollLeft && clientWidth && scrollLeft + clientWidth >= scrollWidth ? 'none' : '' }}
                  onMouseDown={() => startCounter('RIGHT')}
                  onMouseUp={stopCounter}
                  onMouseLeave={stopCounter}
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.8595 6.33329L11.8595 6.33335L11.8671 6.33332C11.9667 6.33299 12.0651 6.35497 12.1551 6.39765C12.2448 6.4402 12.3238 6.50225 12.3865 6.57927C12.3867 6.57951 12.3869 6.57975 12.3871 6.57999L18.0209 13.5785L18.0209 13.5785L18.0241 13.5825C18.1222 13.7018 18.1758 13.8514 18.1758 14.0058C18.1758 14.1598 18.1225 14.3089 18.0251 14.428L12.1929 21.4266L12.1925 21.4271C12.0793 21.5633 11.9165 21.649 11.7401 21.6653C11.5636 21.6816 11.3879 21.6271 11.2516 21.5138C11.1154 21.4005 11.0297 21.2378 11.0134 21.0613C10.9972 20.885 11.0516 20.7095 11.1647 20.5732C11.1647 20.5731 11.1648 20.573 11.1649 20.5729L16.3794 14.3202L16.6419 14.0054L16.3847 13.6862L11.3447 7.4329L11.3447 7.43286L11.3395 7.42662C11.258 7.32877 11.2062 7.20961 11.1903 7.08324C11.1744 6.95688 11.195 6.82859 11.2497 6.71358C11.3044 6.59856 11.3909 6.50162 11.499 6.43422C11.6071 6.36683 11.7322 6.3318 11.8595 6.33329Z"
                      fill="black"
                      stroke="#344F7A"
                    />
                  </svg>
                </ChartScrollButtons>
              </FlexScrollButtonWrapper>
            </ServicesContainer>
          </Container>
        );
      }}
    </Query>
  );
};

export default withRouter(Articles);
