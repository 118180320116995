import React, { Component, Fragment } from 'react';
import { BaseModalBackground, ModalProvider } from 'styled-react-modal';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter, Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ToastProvider } from 'react-toast-notifications';
import * as Sentry from '@sentry/browser';
import { withApollo } from 'react-apollo';

import Dashboard from './pages/DashboardV2';
import Registration from './pages/registration/Registration';
import Intake from './pages/Intake';
import Purchase from './pages/purchase/Purchase';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import theme from './styles/theme';
import { StyledToast } from './components/StyledToast';
import { ToastContainer } from './components/ToastContainer';
import ErrorBoundary from './components/ErrorBoundary';
import OrderKit from './pages/orderKit/OrderKit';

import { TOUCH, GET_FEATURE_TOGGLES_LIST } from './graphql/index';

import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import ScheduleNewPatient from './pages/ScheduleNewPatient2';
import BuyAKitPage from './pages/scheduleDoctor/newPatientOnly/buyAKitPage';
import ClaimKit from './pages/claimKit';
import TestPaymentWalletContainer from './pages/TestPaymentWalletContainer';
import { ToggleValues } from './components/featureToggle/toggles';
import AccountLocked from './pages/AccountLocked';
import UnlockAccount from './pages/UnlockAccount';
import ReferralRedirect from './pages/referralRedirect';
import ReferralRedirectUnithroid from './pages/referralRedirectUnithroid';
import CompleteRegistration from './pages/CompleteRegistration';
import ResetPasswordMobile from './pages/ResetPasswordMobile';
import deepLinksRedirect from './pages/deepLinksRedirect';
import becomeMember, { JoinMember, JoinMemberSubscribe } from './pages/becomeMember';
import AsyncPrequalification from './pages/asyncPrequal/AsyncPrequalification';
import AsyncRegister from './pages/asyncRegister/AsyncRegister';
import EmailExistOtherPlatform from './pages/EmailExistOtherPlatform';
import CommunityTerms from './pages/CommunityTerms';
import LoginFromMobile from './pages/loginFromMobile';
import MobileChatCareTeam from './pages/MobileChatCareTeam';
import MobileRescheduleAppointment, { MobileRescheduleMenoAppointment } from './pages/MobileRescheduleAppointment';
import BncLinkTranscript from './pages/BncLinkTranscript';
import { MobileAppProviderMessaging } from './pages/mobileAppProviderMessaging';
import SessionTimeout from './components/SessionTimeout';
import PartnerSignup, { PartnerSignupNoBroker } from './pages/partnerSignup/PartnerSignup';
import ReferralRedirectBoost from './pages/referralRedirectBoost';
import ReferralRedirectBoostKit from './pages/referralRedirectBoostKit';
import { UnauthenticatedVisitVisioNutritionist } from './pages/visitVisio/VisitVisioNutritionist';
import YearlyReview from './pages/YearlyReview/YearlyReview';
import BaselineSurvey from './pages/baselineSurvey/BaselineSurvey';
import BaselineCompleted from './pages/baselineSurvey/BaselineCompleted';
import EstablishBaseline from './pages/baselineSurvey/EstablishBaseline';
import EstablishFollowUp from './pages/followUpSurvey/EstablishFollowUp';
import FollowupSurvey from './pages/followUpSurvey/FollowupSurvey';
import FollowUpCompleted from './pages/followUpSurvey/FollowUpCompleted';
import BaselineChart from './pages/baselineSurvey/BaselineChart';
import InsuranceMemberEditsAcknowledge from './pages/InsuranceMemberEditsAcknowledge';
import BaselineOutroSummary from './pages/baselineSurvey/BaselineOutroSummary';
import FollowUpOutroSummary from './pages/followUpSurvey/FollowUpOutroSummary';
import StandaloneLabDetail from './pages/StandaloneLabDetail';
import LoginFromHC from './pages/loginFromHC';
import AppointmentConfirmation, { AppointmentConfirmationSelection } from './pages/AppointmentConfirmation';
import ReferralRedirectNP from './pages/referralRedirectNP';
import IntakeMenopause from './pages/IntakeMenopause';
import AdminBannerFilterBuilder from './pages/AdminBannerFilterBuilder';
import AdminBannerFilterList from './pages/AdminBannerFilterList';

const GlobalStyle = createGlobalStyle`
  body {
    color: #344f79;
    padding: 0;
    margin: 0;
    font-family: Poppins, sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: #fdf5f2;
  }
  input {
    font-family: Poppins, sans-serif;
  }
  a {
    color: #000;
  }

  .custom_zindex {
  z-index: 33 !important;
}
  .text-transition_inner {
    white-space: pre-wrap;
    text-align: center;
    height: 250px !important;
  }



  .Select-input > input {
  caret-color: transparent;
  width: 100%;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 14px;
  /* For IE 8 compatibility */
  padding: 8px 0 12px;
  /* For IE 8 compatibility */
  -webkit-appearance: none;
}

.dropzone {
  &:focus {
    outline: none;
  }
}

.conversationbubble >a{
  color: white;
}

.slide-enter {
    transform: translateX(100vw);
    position: absolute;
    
}

/* Declare transition properties */
.slide-enter.slide-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 300ms linear 300ms;
}

/* EXIT TRANSITION */
.slide-exit {
    transform: translateX(0);
    opacity: 1;
    
}

.slide-exit.slide-exit-active {
    transform: translateX(-100vw);
    opacity: 0;
    transition: all 300ms linear
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  /* @media only screen and (min-width: 768px){ */
    position: absolute;
    width: 100%;
    top: -2px;
    left: auto;
    right: 109%;
    text-align: right;
    color: rgb(52, 79, 121);
  @media screen and (max-width: 52em) {
    display: none !important;
  }
  /* } */
}
.vertical-timeline-element-content {
  margin-left: 60px;
  margin-right: 20px;
  @media screen and (max-width: 52em) {
    margin-left: 40px !important;
  }

}
.vertical-timeline-element-date {
    font-weight: 300 !important;
    font-size: 10px !important;
    top: 5px !important;
}
.vertical-timeline-element {
    margin: 1em 0 !important;
  @media screen and (min-width: 1170px) {
    margin: 2em 0 !important;
  }
}
.vertical-timeline-element--consults  .vertical-timeline-element-date {
    display: none !important;
}    
.vertical-timeline-element-content {
  box-shadow: 0 4px 8px #ddd !important;
}
.vertical-timeline {
  max-width: 680px !important;
  height: fit-content;
}
.vertical-timeline-consults {
  margin: 0 !important;
}
header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid #eee;
  background: #fff;
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: #1a8fff;
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}


/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: #fff;
  border: 1px solid #eee;
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid #eee;
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
  color: #f9a394;
}

.calendar .header .icon:hover {
  /* transform: scale(1.75); */
  transition: .25s ease-out;
  color: #344f79;
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  /* color: #ccc; */
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid #eee;
}

.calendar .body .cell {
  position: relative;
  height: 5em;
  border-right: 1px solid #eee;
  overflow: hidden;
  cursor: pointer;
  background: #fff;
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  background: #f9f9f9;
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 1px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid #eee;
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 14px;
  top: 3px;
  right: .75em;
  font-weight: 400;
  @media screen and (max-width: 52em) {
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
  }
}

.calendar .body .disabled {
  color: #ccc;
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: #1a8fff;
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7 - 1px);
  width: calc(100% / 7 - 1px);
}

.pac-container {
   top: 100%;
   background-color: hsl(0, 0%, 100%);
   border-radius: 4px;
   box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
   margin-bottom: 8px;
   margin-top: 8px;
   position: absolute;
   box-sizing: border-box;
   position: absolute!important;
   z-index: 1000;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
   max-height: 175px;
   padding-bottom: 4px;
   padding-top: 4px;
   overflow-y: auto;
   -webkit-overflow-scrolling: touch;
}

.pac-item {
   background-color: transparent;
   color: inherit;
   cursor: default;
   display: block;
   font-size: inherit;
   /* padding: 8px 12px 8px 0; */
   width: 100%;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   box-sizing: border-box;
   &.active {
     background-color: #deebff;
   }

   cursor: default;
    padding: 8px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    opacity: 0.75;
}

.pac-item-query {
   background-color: transparent;
   color: inherit;
   cursor: default;
   /* display: block; */
   font-size: inherit;
   /* padding: 8px 12px; */
   width: 100%;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   box-sizing: border-box;
   &.active {
     background-color: #deebff;
   }

   cursor: default;
    /* padding: 0 4px 0 0; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


  .calendly-inline-widget,
.calendly-inline-widget *,
.calendly-badge-widget,
.calendly-badge-widget *,
.calendly-overlay,
.calendly-overlay * {
	font-size: 16px;
	line-height: 1.2em
}

.calendly-inline-widget iframe,
.calendly-badge-widget iframe,
.calendly-overlay iframe {
	display: inline;
	width: 100%;
	height: 100%;
}

.calendly-popup-content {
	position: relative
}

.calendly-popup-content.mobile {
	-webkit-overflow-scrolling: touch;
	overflow-y: auto
}

.calendly-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 9999;
	background-color: #a5a5a5;
	background-color: rgba(31, 31, 31, 0.4)
}

.calendly-overlay .calendly-close-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0
}

.calendly-overlay .calendly-popup {
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translateY(-50%) translateX(-50%);
	transform: translateY(-50%) translateX(-50%);
	width: auto;
	min-width: 350px;
	max-width: 1000px;
	height: 90%;
	max-height: 680px;
	/* background-color: #fff */
}

@media (max-width: 975px) {
	.calendly-overlay .calendly-popup {
		position: fixed;
		top: 50px;
		left: 0;
		right: 0;
		bottom: 0;
		-webkit-transform: none;
		transform: none;
		width: 100%;
	width: 100%;
		height: auto;
		min-width: 0;
		max-height: none
	}
}

.calendly-overlay .calendly-popup .calendly-popup-content {
	height: 100%
}

@media (max-width: 975px) {
	.calendly-overlay .calendly-popup .calendly-popup-content {
		background-color: #fff
	}
}

.calendly-overlay .calendly-popup .calendly-popup-close {
	position: absolute;
	top: 0;
	right: -24px;
	color: #fff;
	font-size: 24px;
	cursor: pointer
}

.calendly-overlay .calendly-popup .calendly-popup-close::before {
	content: "×"
}

@media (max-width: 975px) {
	.calendly-overlay .calendly-popup .calendly-popup-close {
		top: -50px;
		right: 14px;
		font-size: 40px
	}
}

.calendly-badge-widget {
	position: fixed;
	right: 20px;
	bottom: 15px;
	z-index: 9998
}

html.is-opaque {
  background: transparent !important;
}

.calendly-badge-widget .calendly-badge-content {
	display: table-cell;
	width: auto;
	height: 45px;
	padding: 0 30px;
	border-radius: 25px;
	box-shadow: rgba(0, 0, 0, 0.25) 0 2px 5px;
	font-family: sans-serif;
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	font-size: 14px;
	color: #fff;
	cursor: pointer
}

.calendly-badge-widget .calendly-badge-content.white {
	color: #666a73
}

.calendly-badge-widget .calendly-badge-content span {
	display: block;
	font-size: 12px
}

.spinner {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	text-align: center;
	z-index: -1
}

.spinner>div {
	display: inline-block;
	width: 18px;
	height: 18px;
	background-color: #e1e1e1;
	border-radius: 50%;
	vertical-align: middle;
	-webkit-animation: bouncedelay 1.4s infinite ease-in-out;
	animation: bouncedelay 1.4s infinite ease-in-out;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s
}

@-webkit-keyframes bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
}

@keyframes bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
}
  .rc-steps-item-process .rc-steps-item-icon {
    background: #344f79 !important;
    border-color: #344f79 !important;
}

.rc-steps-item-title {
  font-weight: 500 !important;
}
a {
  color: #344f79;
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(0.7, 0.7, 0.7);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.04, 1.03, 1.03);
  }

  80% {
    transform: scale3d(1.02, 1.02, 1.02);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(0.7, 0.7, 0.7);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.04, 1.03, 1.03);
  }

  80% {
    transform: scale3d(1.02, 1.02, 1.02);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-duration: 1s;
  animation-name: bounceIn;
  -webkit-animation-name: bounceIn;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

  @keyframes fadeInUpFrom20 {
    from {
      opacity: 0;
      transform: translate3d(0, 20%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInUpFrom20 {
    animation-name: fadeInUpFrom20;
  }

@keyframes fadeInDownFrom20 {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDownFrom20 {
  animation-name: fadeInDownFrom20;
}

.fadeInDownFrom20Cls {
  animation-duration:0.2s;
  animation-name: fadeInDownFrom20;
}

  @keyframes fadeInUpFrom30 {
    from {
      opacity: 0;
      transform: translate3d(0, 30%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInUpFrom30 {
    animation-name: fadeInUpFrom30;
  }

  @keyframes fadeInUpFrom40 {
    from {
      opacity: 0;
      transform: translate3d(0, 40%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

@keyframes learnMoreBgFrames {
  from {background-color: transparent;}
  to {background-color: blue;}
}

  .fadeInUpFrom40 {
    animation-name: fadeInUpFrom40;
  }

.example-steps {
  position: absolute;
}

.example-steps2 {
  position: absolute;
  width: min(100%, 680px) !important;
  max-width: min(100%,680px) !important
}

  @keyframes EnterFrames {
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
.example-enter {
  opacity: 0.5;
  transform: translate3d(50%, 0, 0);
}
.example-enter.example-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-in;
  
}

.example-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.example-exit.example-exit-active {
  opacity: 0;
  transform: translate3d(-50%, 0, 0);
  transition: all 300ms ease-in;
}

.example-back-enter {
  opacity: 0;
  transform: translate3d(-50%, 0, 0);
}
.example-back-enter.example-back-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-in;
}

.example-back-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.example-back-exit.example-back-exit-active {
  opacity: 0;
  transform: translate3d(50%, 0, 0);
  transition: all 300ms ease-in;
}


.moveList-enter {
    opacity: 0.01;
    transform: translate(-40px, 0)
}

.moveList-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 300ms ease-in;
}

.moveList-exit {
    opacity: 1;
    transform: translate(0, 0)
}

.moveList-exit-active {
    opacity: 0.01;
    transform: translate(40px, 0);
    transition: all 200ms ease-in;
}



@keyframes react-loading-skeleton {
    100% {
        transform: translateX(100%);
    }
}

.react-loading-skeleton {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block; /* Enable animation */

    background-color: var(--base-color);

    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;

    position: relative;
    overflow: hidden;
    z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.react-loading-skeleton::after {
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        90deg,
        var(--base-color),
        var(--highlight-color),
        var(--base-color)
    );
    transform: translateX(-100%);

    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.page-piling {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}   

.page-piling-section {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    overflow-y: auto;
}

.page-piling-section > * {
    min-width: 100%;
    min-height: 100%;
}

.react-add-to-calendar {
	 -webkit-font-smoothing: antialiased;
	 text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
	 position: relative;
	 display: inline-block;
	 margin: 0 auto;
}
 .react-add-to-calendar__wrapper {
	 zoom: 1;
	 cursor: pointer;
}
 .react-add-to-calendar__button {
	 padding: 10px;
	 background-color: #f9f9f9;
	 border: 1px solid #aab9d4;
	 border-radius: 3px;
   border-radius: 30px;
	 color: #344F7A;
}
 .react-add-to-calendar__button--light {
   border-radius: 30px;
	 background-color: #fff;
   &::before{
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 100%;
    border: 0 solid transparent;
    border-bottom: none;
    border-top-color: white;
    border-width: .35em;
   animation-name: fadeInDownFrom20;
   animation-duration: .3s ;
   }
}
 .react-add-to-calendar__icon--right {
	 padding-left: 5px;
}
 .react-add-to-calendar__icon--left {
	 padding-right: 5px;
}
 .react-add-to-calendar__dropdown {
	 position: absolute;
	 top: 55px;
	 left: 3.5%;
	 width: 93%;
   border-radius: 12px;
	 padding: 0;
	 box-shadow: 1px 3px 6px rgba(0, 0, 0, .25);
	 border: 1px solid #a8a8a8;
	 background-color: #fff;
	 text-align: left;
   overflow: hidden;
   animation-name: fadeInUpFrom40;
   animation-duration: .5s ;
   /* transition: all 0.5s fadeInDownFrom20 .5s; */
}
 .react-add-to-calendar__dropdown ul {
	 list-style: none;
	 margin: 0 !important;
	 padding: 0 !important;
}
 .react-add-to-calendar__dropdown ul li {
  padding: 12px ;
  display: flex;
  &:hover{
    background: #c6d7f2;
  }
}
 .react-add-to-calendar__dropdown ul li a {
	 color: #344F7A;
	 text-decoration: none;
   width:100% ;
   display: flex;
   align-items: center;
}
 .react-add-to-calendar__dropdown ul li a i {
	 padding-right: 10px;
}
`;

if (window._env_.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment: window._env_.REACT_APP_SENTRY_ENV,
    dsn: window._env_.REACT_APP_SENTRY_DSN,
  });
}
const SpecialModalBackground = styled(BaseModalBackground)`
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3000;
`;
class App extends Component {
  timeout = null;
  timeoutsAt = null;
  tabHeartbeat = null;

  constructor(props) {
    super(props);
    window.quadpay.virtualCheckout.updateEnvironment(window._env_.REACT_APP_QUADPAY_ENV);

    this.state = {
      isFeatureToggleLoaded: false,
    };
    // this.tabHeartbeat = setInterval(() => {
    //   if (this.timeoutsAt) {
    //     if (new Date() > this.timeoutsAt) {
    //       this.timeoutLogout();
    //     }
    //   }
    // }, 5000);
    this.loadFeatureToggles();
  }

  componentDidMount() {
    const loaderElement = document.querySelector('.preloader-container');
    if (loaderElement) {
      loaderElement.remove();
    }
  }

  loadFeatureToggles = async () => {
    const res = await this.props.client.query({
      query: GET_FEATURE_TOGGLES_LIST,
    });

    if (res && res.data && res.data.getFeatureTogglesList) {
      ToggleValues.list = res.data.getFeatureTogglesList;
    }

    this.setState({
      isFeatureToggleLoaded: true,
    });
  };

  heartbeat = async () => {
    const { data } = await this.props.client.mutate({
      mutation: TOUCH,
    });

    if (!data || !data.touch || !data.touch.token) {
      const initialToken = localStorage.getItem('initialToken');

      localStorage.removeItem('token');
      if (initialToken === 'aa00') {
        localStorage.removeItem('initialToken');
        window.close();
      } else {
        window.location = '/logout';
      }
      return false;
    }

    localStorage.setItem('token', data.touch.token);
    return true;
  };

  timeoutLogout = () => {
    this.timeoutsAt = null;
    this.tabHeartbeat = null;
    localStorage.removeItem('token');
    const initialToken = localStorage.getItem('initialToken');
    if (initialToken === 'aa00') {
      localStorage.removeItem('initialToken');
      window.close();
      return;
    }
    if (this.props.location && this.props.location.pathname) {
      localStorage.setItem('route', this.props.location.pathname);
    } else {
      localStorage.removeItem('route');
    }
    this.props.history.push('/logout');
  };

  render() {
    const { isFeatureToggleLoaded } = this.state;
    if (!isFeatureToggleLoaded) return <div />;
    const token = localStorage.getItem('token');
    return (
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <ModalProvider backgroundComponent={SpecialModalBackground}>
            <CookiesProvider>
              <>
                <BrowserRouter>
                  <Fragment>
                    <ToastProvider
                      components={{ Toast: StyledToast, ToastContainer }}
                      // placement="top-center"
                      autoDismiss={true}
                      autoDismissTimeout={2500}
                    >
                      <Switch>
                        <ProtectedRoute exact path="/baseline-chart" component={BaselineChart} heartbeat={this.heartbeat} />
                        {/* <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/intakev2" component={IntakeV3} /> */}
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/establish-baseline" component={EstablishBaseline} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/baseline" component={BaselineSurvey} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/baseline-complete" component={BaselineCompleted} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/baseline-outro" component={BaselineOutroSummary} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/establish-followup-survey/:id" component={EstablishFollowUp} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/followup-survey/:id" component={FollowupSurvey} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/followup-survey-complete/:id" component={FollowUpCompleted} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/followup-survey-outro/:id" component={FollowUpOutroSummary} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/become-member" component={becomeMember} />
                        <Route path="/visio-nutritionist/:id" component={UnauthenticatedVisitVisioNutritionist} />
                        <PublicRoute path="/registration" component={Registration} />
                        <PublicRoute path="/register" component={Registration} />
                        <PublicRoute path="/partner-signup/:partner" component={PartnerSignup} />
                        <PublicRoute path="/partner-signup" component={PartnerSignupNoBroker} />
                        <PublicRoute path="/login" component={Login} />
                        <PublicRoute path="/account-locked" component={AccountLocked} />
                        <PublicRoute path="/unlock/:uid" component={UnlockAccount} />

                        <PublicRoute path="/complete-registration/:id" component={CompleteRegistration} />
                        <PublicRoute path="/signup" component={AsyncRegister} />
                        <PublicRoute exact path="/recovery" component={ForgotPassword} />
                        <PublicRoute path="/recovery/:id" component={ResetPassword} />
                        <PublicRoute path="/mobile-recovery/:id" component={ResetPasswordMobile} />

                        <PublicRoute path="/book/:state" component={ScheduleNewPatient} />

                        <PublicRoute path="/book-appointment/:state" component={ScheduleNewPatient} />

                        <PublicRoute path="/book-appointment/" component={ScheduleNewPatient} />

                        <PublicRoute path="/account-exists/" component={EmailExistOtherPlatform} />

                        <PublicRoute path="/deeplink/:env/:linktype/:email/:id" component={deepLinksRedirect} />
                        <PublicRoute path="/appointment_confirm/:appointmentId/:athenaId/:token" component={AppointmentConfirmationSelection} />
                        <PublicRoute path="/aconfirm/:appointmentId/:athenaId" exact component={AppointmentConfirmation} />

                        <PublicRoute path="/test-payment-wallet" component={TestPaymentWalletContainer} />

                        <PublicRoute path="/prequalification" component={AsyncPrequalification} />

                        <PublicRoute path="/claim-kit/:id" component={ClaimKit} />
                        <PublicRoute path="/buykit" component={BuyAKitPage} />
                        <PublicRoute path="/ccc" component={MobileAppProviderMessaging} />

                        <Route path="/logout" render={() => <Logout client={this.props.client} />} />

                        <PublicRoute path="/referral-np/:coupon" component={ReferralRedirectNP} />
                        {/* render={() => <ReferralRedirectNP client={this.props.client} />} /> */}

                        <Route path="/referral/:coupon" render={() => <ReferralRedirect client={this.props.client} />} />

                        <Route path="/newreferral/:coupon" render={() => <ReferralRedirectUnithroid client={this.props.client} />} />

                        <Route path="/referral-boost/:coupon" render={() => <ReferralRedirectBoost client={this.props.client} />} />

                        <Route path="/referral-kit-boost/:coupon" render={() => <ReferralRedirectBoostKit client={this.props.client} />} />

                        <Route path="/transcript" render={() => <BncLinkTranscript />} />

                        <Route path="/order-kit/:email" render={() => <OrderKit client={this.props.client} />} />

                        <Route path="/mobile/autosign/sso/:token/:redirect" render={() => <LoginFromMobile client={this.props.client} />} />
                        <Route path="/hc/autosign/sso/:token/:impersonate" render={() => <LoginFromHC client={this.props.client} />} />

                        <Route path="/order-kit" render={() => <OrderKit client={this.props.client} />} />
                        {/* <Route path='/AdminBannerFilterBuilder/:id/:versionId' render={()=><AdminBannerFilterBuilder client={this.props.client} />} />
                        <Route path='/AdminBannerFilterBuilder/:id' render={()=><AdminBannerFilterBuilder client={this.props.client} />} />
                        <Route path='/AdminBannerFilterBuilder' render={()=><AdminBannerFilterBuilder client={this.props.client} />} />
                        <Route path='/AdminBannerFilterList' render={()=><AdminBannerFilterList client={this.props.client} />} /> */}

                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/AdminBannerFilterBuilder/:id/:versionId" component={AdminBannerFilterBuilder} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/AdminBannerFilterBuilder/:id" component={AdminBannerFilterBuilder} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/AdminBannerFilterBuilder" component={AdminBannerFilterBuilder} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/AdminBannerFilterList" component={AdminBannerFilterList} />

                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/insurance-member/will-be-in-touch/:flow" component={InsuranceMemberEditsAcknowledge} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/insurance-member/will-be-in-touch" component={InsuranceMemberEditsAcknowledge} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/yearlyReview" component={YearlyReview} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/intake" component={Intake} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/intake-menopause" component={IntakeMenopause} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/community-terms" component={CommunityTerms} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/purchase" component={Purchase} />
                        <ProtectedRouteToRegister logout={this.timeoutLogout} path="/purchase-kit" component={Purchase} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/join-membership" component={JoinMemberSubscribe} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/join-member" component={JoinMember} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/mobile-chat-care" component={MobileChatCareTeam} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/mobile-appointment-reschedule/:appointmentId" component={MobileRescheduleAppointment} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/mobile-meno-appointment-reschedule/:appointmentId" component={MobileRescheduleMenoAppointment} />
                        <ProtectedRoute logout={this.timeoutLogout} heartbeat={this.heartbeat} path="/labs/:id" exact component={StandaloneLabDetail} />
                        <ProtectedRoute bypassShowTimeout={true} heartbeat={this.heartbeat} path="/" component={Dashboard} />
                      </Switch>

                      <GlobalStyle />
                    </ToastProvider>
                  </Fragment>
                </BrowserRouter>
              </>
            </CookiesProvider>
          </ModalProvider>
        </ErrorBoundary>
      </ThemeProvider>
    );
  }
}

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const token = localStorage.getItem('token');
      if (token) {
        document.body.style.backgroundColor = '#FDF5F2';
        return (
          <>
            <Component
              {...props}
              heartbeat={() => {
                rest.heartbeat();
              }}
            />
            {!rest.bypassShowTimeout && (
              <SessionTimeout
                heartbeat={rest.heartbeat}
                client={rest.client}
                logout={() => {
                  rest.logout();
                }}
              />
            )}
          </>
        );
      } else {
        document.body.style.backgroundColor = '#FDF5F2';
        // console.log(props.location)
        let rte = props.location.pathname;
        if (props.location && props.location.search) {
          if (props.location.search === '?skp=true') {
            rte += props.location.search;
          }
        }
        localStorage.setItem('route', rte);
        return <Redirect to={{ pathname: '/login' }} />;
      }
    }}
  />
);

const ProtectedRouteToRegister = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const token = localStorage.getItem('token');
      if (token) {
        document.body.style.backgroundColor = '#FDF5F2';
        return (
          <>
            <Component {...props} />
            <SessionTimeout
              heartbeat={rest.heartbeat}
              client={rest.client}
              logout={() => {
                rest.logout();
              }}
            />
          </>
        );
      } else {
        document.body.style.backgroundColor = '#FDF5F2';
        localStorage.setItem('route', props.location.pathname);
        return <Redirect to={{ pathname: '/prequalification' }} />;
      }
    }}
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const token = localStorage.getItem('token');
      if (!token) {
        document.body.style.backgroundColor = '#FDF5F2';
        return <Component {...props} />;
      } else {
        document.body.style.backgroundColor = '#FDF5F2';
        return <Redirect to={{ pathname: '/' }} />;
      }
    }}
  />
);

// TODO: Refactor to hooks, update React
class Logout extends Component {
  componentDidMount = async () => {
    const initialToken = localStorage.getItem('initialToken');
    if (initialToken === 'aa00') {
      localStorage.removeItem('initialToken');
      localStorage.clear();
      this.props.client.cache.data.clear();
      await this.props.client.clearStore();
      window.close();
      return;
    }
    localStorage.clear();
    this.props.client.cache.data.clear();
    await this.props.client.clearStore();
  };

  render() {
    document.body.style.backgroundColor = '#FDF5F2';
    return <Redirect to="/login" />;
  }
}

export default withRouter(withApollo(App));
