const RearrangeSegmentNoise = () =>{
  var images = document.getElementsByTagName('img'); 
  for (let index = 0; index < images.length; index++) {
    const element = images[index];
    // console.log({element})
    if(element.currentSrc && element.currentSrc.indexOf('https://s.amazon-adsystem')>-1){
      element.style.position='absolute'
      element.style.top='0'
    }
  }
}

export const CleanDom = () => {
  RearrangeSegmentNoise()
}