import { Flex } from 'rebass';
import styled from 'styled-components';

export const WhiteCard = styled(Flex)`
  flex-direction: column;
  padding: 40px 26px;
  border-radius: 30px;
  background-color: white;
  margin-top: 36px;
  min-width: 426px;
  max-width: 600px;
  position:relative ;

  /* &::before {
    content: '';
    background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="55" height="69" viewBox="0 0 55 69" fill="none"><path d="M24.3157 1.14999C32.8545 -0.558919 41.199 -0.438498 49.509 2.01951C51.9734 2.77792 54.4748 3.41161 57.0038 3.91816C66.6484 5.73909 71.9074 12.2272 74.7173 20.8739C77.692 30.0288 77.9024 39.4059 74.5007 48.5285C71.2647 57.2056 65.1953 63.7619 56.7999 67.5227C53.2783 69.1001 48.7988 69.0761 44.7824 68.9443C35.8265 68.6509 27.2245 66.4425 18.7631 63.4537C10.5704 60.5579 5.72795 54.6981 2.90851 46.9754C0.0338737 39.1037 -0.572478 30.8625 0.488837 22.6053C1.52026 14.582 6.31247 9.04794 13.4914 5.58669C16.9961 3.89569 20.7013 2.61542 24.3157 1.14999Z" fill="rgb(184, 214, 245)"/></svg>');
    background-size: 69px 55px;
    background-repeat: no-repeat;
    right: 0;
    bottom: 40px;
    opacity: 0.15;
    position: absolute;
    height: 69px;
    width: 55px;
  } */
  @media screen and (max-width: 52em) {
    border-radius: 30px 30px 0 0;
    min-width: 100vw;
    width: 100vw;
    flex: 1;
  }
`;
