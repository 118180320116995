import React from 'react';
import IconContainer from './IconContainer';

const Help = props => (
  <IconContainer {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fill={props.fill ? props.fill : '#000'}
        d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm1,17H9V15h2Zm2.07-7.75-.9.92A3.4,3.4,0,0,0,11,13H9v-.5a4,4,0,0,1,1.17-2.83l1.24-1.26A2,2,0,0,0,12,7,2,2,0,0,0,8,7H6a4,4,0,0,1,8,0A3.18,3.18,0,0,1,13.07,9.25Z"
      />
    </svg>
  </IconContainer>
);

export default Help;
