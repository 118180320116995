import React from 'react';
import { getIn, Field } from 'formik';
import styled from 'styled-components';
import { Flex } from 'rebass';

const Label = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  align-self: center;
  color: ${props => props.theme.color.greyDark};
`;

const Box = styled.span`
  flex: none;
  width: ${props => props.theme.px(2)};
  height: ${props => props.theme.px(2)};
  margin-right: ${props => props.theme.px(1)};
  border-width: 1px;
  border-style: solid;
  border-radius: ${props => props.theme.borderRadius};
`;

const Tick = styled(({ className }) => (
  <svg className={className} fillRule="evenodd" viewBox="0 0 12 9">
    <path d="M4.1 6.1L1.4 3.4 0 4.9 4.1 9l7.6-7.6L10.3 0z" />
  </svg>
))`
  fill: ${props => props.theme.color.white};
  width: 100%;
  height: 100%;
  padding: 0 1px;
  display: none;
`;

const styles = {
  checked: {
    Box: {
      borderColor(props) {
        if (props.disabled) {
          return props.theme.color.greyLight;
        }
        if (props.error) {
          return props.theme.color.red;
        }
        return props.theme.color.darkBlue;
      },
      backgroundColor(props) {
        if (props.disabled) {
          return props.theme.color.greyLight;
        }
        if (props.error) {
          return props.theme.color.red;
        }
        return props.theme.color.darkBlue;
      },
    },
  },
  Box: {
    borderColor(props) {
      if (props.disabled) {
        return props.theme.color.greyLight;
      }
      if (props.error) {
        return props.theme.color.red;
      }
      return props.theme.color.greyLight;
    },
    backgroundColor(props) {
      if (props.disabled) {
        return props.theme.color.greyLighter;
      }
      if (props.error) {
        return props.theme.color.redLighter;
      }
      return props.theme.color.white;
    },
  },
};

const StyledInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(1px 0 0 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
  + ${/* sc-selector */ Box} {
    border-color: ${styles.Box.borderColor};
    background-color: ${styles.Box.backgroundColor};
  }
  &:focus {
    + ${/* sc-selector */ Box} {
      border-color: ${props => props.theme.color.darkBlue};
      background-color: ${props => props.theme.color.white};
    }
  }
  &:checked {
    + ${/* sc-selector */ Box} {
      border-color: ${styles.checked.Box.borderColor};
      background-color: ${styles.checked.Box.backgroundColor};
      ${Tick} {
        display: block;
      }
    }
  }
`;

const Input = props => {
  const { field, form } = props;
  return (
    <Flex alignItems="center" {...props}>
      <Label htmlFor={field.name}>
        <StyledInput
          id={field.name}
          type="checkbox"
          {...field}
          {...props}
          checked={getIn(form.values, field.name)}
          onClick={() => {
            form.setFieldValue(field.name, !!getIn(form.values, field.name));
            if (props.onChanged) {
              props.onChanged(getIn(form.values, field.name));
            }
          }}
        />
        <Box>
          <Tick />
        </Box>
        {props.label}
      </Label>
    </Flex>
  );
};

export const CheckboxInput = props => {
  return <Field {...props} type="checkbox" component={Input} />;
};

export const Checkbox = ({name, isChecked, onClick,label, ...props}) => {
  return (
    <Flex alignItems="center" {...props}>
      <Label htmlFor={name} style={{color: '#344f79'}}>
        <StyledInput
          id={name}
          type="checkbox"
          checked={!!isChecked}
          onClick={() => {
            onClick()
          }}
        />
        <Box>
          <Tick />
        </Box>
        {label}
      </Label>
    </Flex>
  );
};

export default CheckboxInput;
