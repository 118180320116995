import React from 'react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Box, Card, Flex, Text } from 'rebass';
import { Area, ComposedChart, ResponsiveContainer, Scatter, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { GET_LAB_CHART_VALUES } from '../../graphql';
import RadioButton from '../../components/RadioButton';
import PleaseWait from '../../components/PleaseWait';
import { Query } from 'react-apollo';

const ToolTip = styled(Box)`
  padding: 0.8rem;
  background: #4e658a;
  border-radius: 10px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
`;

const CustomizedTick = ({ x, y, payload }) => {
  return (
    <text style={{ fontSize: '10px', fill: '#A3A3A3' }} x={x} y={y} textAnchor="top" dominantBaseline="hanging">
      {moment(new Date((payload.value * 100) / 100)).format('MM/DD/YYYY')}
    </text>
  );
};
const CustomizedTick2 = ({ x, y, payload }) => {
  // console.log(props)
  return (
    <text style={{ fontSize: '10px', fill: '#A3A3A3' }} x={x - 24} y={y - 6} textAnchor="top" dominantBaseline="hanging">
      {payload.value}
    </text>
  );
};

const getValues = (output, selectedMarker) => {
  const data = [];
  const labTestsPoints = [];

  let lastAvg2 = null;
  let max = 1;
  // useEffect(()=>{

  let vals = [];
  let unit;
  switch (selectedMarker) {
    case 'tpo':
      vals = output.getLabChartValues.tpo || [];
      unit = 'IU/mL';
      break;
    case 'ft3':
      vals = output.getLabChartValues.ft3 || [];
      unit = 'pg/mL';
      break;
    case 'ft4':
      vals = output.getLabChartValues.ft4 || [];
      unit = 'ng/dL';
      break;
    case 'rt3':
      vals = output.getLabChartValues.rT3 || [];
      unit = 'IU/mL';
      break;
    case 'vitD':
      vals = output.getLabChartValues.vitD || [];
      unit = 'ng/L';
      break;
    case 'TSH':
    default:
      vals = output.getLabChartValues.tsh || [];
      unit = 'uU/mL';
      break;
  }
  const sorted = JSON.parse(JSON.stringify(vals)).sort((a, b) => {
    if (!a || !a.date) return -1;
    if (!b || !b.date) return 1;
    let dta = a.date.indexOf('/') > -1 ? moment(a.date).format('x') : a.date;
    let dtB = b.date.indexOf('/') > -1 ? moment(b.date).format('x') : b.date;
    if (dta < dtB) return -1;
    return 0;
  });

  for (let index = 0; index < sorted.length; index++) {
    const element = sorted[index];
    if (!element) continue;
    if (element.value) {
      lastAvg2 = (element.value !== null && element.value !== undefined ? element.value : 0) / 100;
    }
    data.push({
      x: element.date,
      y: element.value !== null && element.value !== undefined ? element.value : null,
    });
    max = Math.max(lastAvg2 || 1, max);

    if (element.value) {
      labTestsPoints.push({
        x: element.date.indexOf('-') > -1 || element.date.indexOf('/') > -1 ? moment(element.date).format('x') : element.date,
        y: (element.value !== null && element.value !== undefined ? element.value : 0) / 100,
        isPaloma: element.isPaloma,
        unit: unit,
      });
    }
  }

  return { labTestsPoints, max, unit };
};

const CustomizedTooltip = ({ active, payload, forceOpen }) => {
  if (active || forceOpen) {
    return (
      <ToolTip>
        <Flex flexDirection={'column'}>
          <Text fontWeight={300} color="white">
            {!!payload && payload.length > 0 ? moment(new Date((payload[0].payload.x * 100) / 100)).format('MMM DD YYYY') : ''}
          </Text>
          <Flex alignItems={'baseline'}>
            <Text fontWeight={500} color="white">
              {!!payload && payload.length > 0 && (!!payload[0].payload.y || payload[0].payload.y === 0) ? payload[0].payload.y : ''}
            </Text>
            <Text fontWeight={300} fontSize="10px" color="white">
              {!!payload && payload.length > 0 && !!payload[0].payload.unit ? payload[0].payload.unit : ''}
            </Text>
          </Flex>
        </Flex>
      </ToolTip>
    );
  }

  return null;
};

const LabsChartInner = ({ data, loading, titleInCard, onMinimize }) => {
  const [selected, setSelected] = useState('TSH');
  const [values, setValues] = useState(null);
  const [max, setMax] = useState(1);
  const [, setUnit] = useState('');

  useEffect(
    () => {
      if (loading || !data || !data.getLabChartValues) {
        setValues(null);
        return;
      }
      const vls = getValues(data, selected);
      setValues(vls.labTestsPoints);
      setUnit(vls.unit);
      setMax(vls.max);
    },
    [data, loading, selected]
  );

  if (
    !loading &&
    (!data || !data.getLabChartValues || !data.getLabChartValues.tsh || data.getLabChartValues.tsh.length < 1) &&
    (!data || !data.getLabChartValues || !data.getLabChartValues.ft4 || data.getLabChartValues.ft4.length < 1) &&
    (!data || !data.getLabChartValues || !data.getLabChartValues.ft3 || data.getLabChartValues.ft3.length < 1) &&
    (!data || !data.getLabChartValues || !data.getLabChartValues.tpo || data.getLabChartValues.tpo.length < 1) &&
    (!data || !data.getLabChartValues || !data.getLabChartValues.rT3 || data.getLabChartValues.rT3.length < 1) &&
    (!data || !data.getLabChartValues || !data.getLabChartValues.vitD || data.getLabChartValues.vitD.length < 1)
  ) {
    return null;
    // <Flex flexDirection={'column'}>
    //   <Text mb="20px">Lab Results</Text>
    //   <Card style={{ width: '100%', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '30px', minHeight: '200px' }} mb={4}>
    //     <Flex>
    //       <Flex flexDirection={'column'} flex={1}>
    //         <Box flex={1}>
    //           <Text textAlign={'center'} style={{ opacity: 0.3 }} mb={4}>
    //             No lab results
    //           </Text>
    //         </Box>
    //       </Flex>
    //     </Flex>
    //   </Card>
    // </Flex>
  }

  return (
    <Flex flexDirection={'column'}>
      {!titleInCard && <Text mb="20px">Lab Results</Text>}
      <Card style={{ width: '100%', flex: 'initial', borderRadius: '30px', minHeight: '350px' }} mb={4}>
        <Flex style={{ height: '350px' }}>
          <Flex flexDirection={'column'} flex={1}>
            {!!titleInCard && (
              <Flex style={{ paddingLeft: '20px', paddingRight: '12px', paddingTop: '12px' }}>
                <Text mb="20px" fontWeight={600} flex={1}>
                  {titleInCard}
                </Text>
                {!!onMinimize && (
                  <svg width="17" style={{ cursor: 'pointer' }} onClick={onMinimize} height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.4583 6.37501H11.6308L13.9612 4.04459C14.0946 3.91121 14.1696 3.73031 14.1696 3.54168C14.1696 3.35305 14.0946 3.17214 13.9612 3.03876C13.8279 2.90538 13.647 2.83044 13.4583 2.83044C13.2697 2.83044 13.0888 2.90538 12.9554 3.03876L10.625 5.36209V3.54168C10.625 3.35381 10.5504 3.17365 10.4175 3.04081C10.2847 2.90797 10.1045 2.83334 9.91666 2.83334C9.7288 2.83334 9.54864 2.90797 9.4158 3.04081C9.28296 3.17365 9.20833 3.35381 9.20833 3.54168V7.08334C9.20833 7.2712 9.28296 7.45137 9.4158 7.58421C9.54864 7.71705 9.7288 7.79168 9.91666 7.79168H13.4583C13.6462 7.79168 13.8264 7.71705 13.9592 7.58421C14.092 7.45137 14.1667 7.2712 14.1667 7.08334C14.1667 6.89548 14.092 6.71531 13.9592 6.58248C13.8264 6.44964 13.6462 6.37501 13.4583 6.37501ZM7.08333 9.20834H3.54166C3.3538 9.20834 3.17364 9.28297 3.0408 9.41581C2.90796 9.54865 2.83333 9.72881 2.83333 9.91668C2.83333 10.1045 2.90796 10.2847 3.0408 10.4175C3.17364 10.5504 3.3538 10.625 3.54166 10.625H5.36208L3.03875 12.9554C2.97236 13.0213 2.91966 13.0996 2.8837 13.1859C2.84774 13.2723 2.82922 13.3648 2.82922 13.4583C2.82922 13.5519 2.84774 13.6444 2.8837 13.7308C2.91966 13.8171 2.97236 13.8954 3.03875 13.9613C3.1046 14.0276 3.18294 14.0803 3.26926 14.1163C3.35557 14.1523 3.44816 14.1708 3.54166 14.1708C3.63517 14.1708 3.72776 14.1523 3.81407 14.1163C3.90039 14.0803 3.97873 14.0276 4.04458 13.9613L6.375 11.6308V13.4583C6.375 13.6462 6.44963 13.8264 6.58246 13.9592C6.7153 14.092 6.89547 14.1667 7.08333 14.1667C7.27119 14.1667 7.45136 14.092 7.5842 13.9592C7.71704 13.8264 7.79166 13.6462 7.79166 13.4583V9.91668C7.79166 9.72881 7.71704 9.54865 7.5842 9.41581C7.45136 9.28297 7.27119 9.20834 7.08333 9.20834Z"
                      fill="#344F7A"
                    />
                  </svg>
                )}
              </Flex>
            )}
            <Box flex={1}>
              {!!loading || !values || values.length < 1 ? (
                <Flex alignItems={'center'} justifyContent="center" flex={1} style={{ height: '100%' }}>
                  <PleaseWait
                    text={' '}
                    spinner={{
                      sColor: '#ECEFF2',
                      pColor: '#909FB5',
                    }}
                  />
                </Flex>
              ) : (
                // <Skeleton width={'calc(100% - 24px)'} height='calc(100% - 12px)' style={{ borderRadius: '0 0 30px 30px', margin: '0 12px 12px 12px' }} />
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    data={values || []}
                    margin={{
                      top: 10,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <defs>
                      <linearGradient id="colorUvLabsTSH" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="rgb(30, 70, 212) " stopOpacity={0.55} />
                        <stop offset="95%" stopColor="rgb(30, 70, 212) " stopOpacity={0.05} />
                      </linearGradient>
                      <linearGradient id="colorUvLabsft4" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#344F7A " stopOpacity={0.55} />
                        <stop offset="95%" stopColor="#344F7A " stopOpacity={0.05} />
                      </linearGradient>
                      <linearGradient id="colorUvLabsft3" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#BE05C1 " stopOpacity={0.55} />
                        <stop offset="95%" stopColor="#BE05C1 " stopOpacity={0.05} />
                      </linearGradient>
                      <linearGradient id="colorUvLabstpo" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#F7BE2B " stopOpacity={0.55} />
                        <stop offset="95%" stopColor="#F7BE2B " stopOpacity={0.05} />
                      </linearGradient>
                      <linearGradient id="colorUvLabsrt3" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#9B4A01 " stopOpacity={0.55} />
                        <stop offset="95%" stopColor="#9B4A01 " stopOpacity={0.05} />
                      </linearGradient>
                      <linearGradient id="colorUvLabsvitD" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#000000 " stopOpacity={0.55} />
                        <stop offset="95%" stopColor="#000000 " stopOpacity={0.05} />
                      </linearGradient>
                    </defs>
                    <Tooltip content={<CustomizedTooltip forceOpen={values.length === 1} />} />
                    <XAxis dataKey="x" tick={CustomizedTick} />
                    <YAxis
                      tick={CustomizedTick2}
                      // ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                      interval={0}
                      domain={[0, Math.ceil(max * 1.1)]}
                    />
                    <Area
                      type="monotone"
                      dataKey="y"
                      stroke={
                        selected === 'TSH'
                          ? '#1E46D4'
                          : selected === 'ft4'
                            ? '#344F7A'
                            : selected === 'ft3'
                              ? '#BE05C1'
                              : selected === 'tpo'
                                ? '#F7BE2B'
                                : selected === 'rt3'
                                  ? '#9B4A01'
                                  : selected === 'vitD'
                                    ? '#000000'
                                    : '#1E46D4'
                      }
                      fill={`url(#colorUvLabs${selected})`}
                    />
                    <Scatter
                      dataKey={'y'}
                      fill="white"
                      stroke={
                        selected === 'TSH'
                          ? '#1E46D4'
                          : selected === 'ft4'
                            ? '#344F7A'
                            : selected === 'ft3'
                              ? '#BE05C1'
                              : selected === 'tpo'
                                ? '#F7BE2B'
                                : selected === 'rt3'
                                  ? '#9B4A01'
                                  : selected === 'vitD'
                                    ? '#000000'
                                    : '#1E46D4'
                      }
                      strokeWidth={2}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              )}
            </Box>
          </Flex>
          <Box height={'236px'} width="1px" backgroundColor={'rgba(52, 79, 122, 0.41)'} style={{ backgroundColor: 'rgba(52, 79, 122, 0.41)' }} />

          <Flex flexDirection={'column'} p="24px 24px" justifyContent={'center'}>
            <RadioButton
              disabled={
                !data || !data.getLabChartValues || !data.getLabChartValues.tsh || data.getLabChartValues.tsh.length < 1 || data.getLabChartValues.tsh.filter((x) => x.value !== null).length < 1
              }
              label="TSH"
              value={selected === 'TSH'}
              onChange={() => {
                setSelected('TSH');
              }}
            />
            <RadioButton
              disabled={
                !data || !data.getLabChartValues || !data.getLabChartValues.ft4 || data.getLabChartValues.ft4.length < 1 || data.getLabChartValues.ft4.filter((x) => x.value !== null).length < 1
              }
              label="Free T4"
              value={selected === 'ft4'}
              onChange={() => {
                setSelected('ft4');
              }}
            />
            <RadioButton
              disabled={
                !data || !data.getLabChartValues || !data.getLabChartValues.ft3 || data.getLabChartValues.ft3.length < 1 || data.getLabChartValues.ft3.filter((x) => x.value !== null).length < 1
              }
              label="Free T3"
              value={selected === 'ft3'}
              onChange={() => {
                setSelected('ft3');
              }}
            />
            <RadioButton
              disabled={
                !data || !data.getLabChartValues || !data.getLabChartValues.tpo || data.getLabChartValues.tpo.length < 1 || data.getLabChartValues.tpo.filter((x) => x.value !== null).length < 1
              }
              label="TPO antibodies"
              value={selected === 'tpo'}
              onChange={() => {
                setSelected('tpo');
              }}
            />
            <RadioButton
              disabled={
                !data || !data.getLabChartValues || !data.getLabChartValues.rT3 || data.getLabChartValues.rT3.length < 1 || data.getLabChartValues.rT3.filter((x) => x.value !== null).length < 1
              }
              label="Reverse T3"
              value={selected === 'rt3'}
              onChange={() => {
                setSelected('rt3');
              }}
            />
            <RadioButton
              disabled={
                !data || !data.getLabChartValues || !data.getLabChartValues.vitD || data.getLabChartValues.vitD.length < 1 || data.getLabChartValues.vitD.filter((x) => x.value !== null).length < 1
              }
              label="Vitamin D"
              value={selected === 'vitD'}
              onChange={() => {
                setSelected('vitD');
              }}
            />
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

const LabsChart = ({ titleInCard, onMinimize }) => {
  return (
    <Query query={GET_LAB_CHART_VALUES}>
      {({ data, loading }) => {
        return <LabsChartInner data={data} loading={loading} titleInCard={titleInCard} onMinimize={onMinimize} />;
      }}
    </Query>
  );
};

export default LabsChart;
