import React from 'react';
import { Card, Flex, Text } from 'rebass';
import DoctorIcon from '../../../../static/doctor.png';
import ComputerLamp from '../../../../static/computerLamp.png';

export const WhatsCovered = () => {
  return (
    <Flex flexDirection={'column'}>
      <Card style={{ background: '#FCE2DD', borderRadius: '20px', padding: '12px', minHeight: '176px', display: 'flex', justifyContent: 'center' }} mt={5}>
        <Flex>
          <img src={DoctorIcon} style={{ maxWidth: '18%', objectFit: 'contain' }} />
          <Flex flexDirection={'column'} justifyContent='center'>
            <Text fontSize={'18px'} fontWeight={600}>
              What Is Covered By Insurance?
            </Text>
            <Text mt={3} fontWeight={400}>
              Doctor visits are covered by insurance. We accept insurance carriers in select states. Check your coverage to see if we accept your insurance
            </Text>
          </Flex>
        </Flex>
      </Card>

      <Card style={{ background: '#FCE2DD', borderRadius: '20px', padding: '12px', minHeight: '176px', display: 'flex', justifyContent: 'center' }} mt={4}>
        <Flex>
          <img src={ComputerLamp} style={{ maxWidth: '18%', objectFit: 'contain' }} />
          <Flex flexDirection={'column'} justifyContent='center'>
            <Text fontSize={'18px'} fontWeight={600}>
              What Is FSA/HSA Eligible?
            </Text>
            <Text mt={3} fontWeight={400}>
              Doctor visits are covered by insurance. We accept insurance carriers in select states. Check your coverage to see if we accept your insurance
            </Text>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};
