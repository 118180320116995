import React, {useState} from 'react'

/**
 * Constructor like Hook. 
 * WARNING! must be at the top of the functional component. Before any other code
 * @param {*} callBack 
 */
export const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
};
