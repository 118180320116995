import React from 'react';
import { withRouter } from 'react-router';
import { Box, Card, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { Skeleton } from '../../components/loadingSkeleton/Skeleton';
import withSession from '../../lib/withSession';
import Brittany from '../../static/Brittany.jpeg';

const AvatarImg = styled(Flex)`
  background: #ffe3d8;
  border-radius: 50%;
  border: 0px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  min-width: 38px;
  & > img {
    width: 38px;
  }
`;

const ToDoEntry = ({ text, children, isLoading, onClick }) => {
  return (
    <Flex alignItems={'center'} mt="16px" onClick={onClick} style={{ cursor: 'pointer' }}>
      {children}
      <Text fontSize={'14px'} flex={1} fontWeight="300">
        {text}
      </Text>
      {!isLoading && (
        <svg style={{ marginLeft: '10px' }} width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.8743 7.71726L1.79342 13.6516C1.59874 13.8401 1.28392 13.8401 1.08924 13.6516L0.269063 12.8577C0.074375 12.6692 0.074375 12.3645 0.269063 12.1761L5.17767 7.37644L0.269063 2.57682C0.074375 2.38836 0.074375 2.08362 0.269063 1.89517L1.08924 1.10125C1.28392 0.91279 1.59874 0.91279 1.79342 1.10125L7.8743 7.03561C8.06899 7.22407 8.06899 7.52881 7.8743 7.71726Z"
            fill="#354E79"
          />
        </svg>
      )}
    </Flex>
  );
};

const Chat = ({ history, session: { eligibility, intakeStatus, isMember, diagnoseFull, dedicatedCareManager, perpetuousPhysicianConversationId }, pastAppointment, upcomingAppointment, loadingAppointments }) => {
  return (
    <Card style={{ width: '100%', flex: 'initial', padding: '12px', borderRadius: '10px' }}>
      <Flex flexDirection={'column'}>
        <Text>Chat with your care team</Text>
        {!!isMember && (
          <ToDoEntry text={'Message care manager'} onClick={() => history.push('/chat-care')}>
            <AvatarImg style={{ alignSelf: 'center', marginRight: '6px' }}>
              <img height="38px" alt="" style={{ objectFit: 'cover' }} src={dedicatedCareManager ? dedicatedCareManager.thumbnail : Brittany} />
            </AvatarImg>
          </ToDoEntry>
        )}
        {!!loadingAppointments ? (
          <ToDoEntry text={<Skeleton width="100px" height="16px" />} isLoading={true} onClick={() => {}} />
        ) : (
          <>
            {!!pastAppointment &&
              !perpetuousPhysicianConversationId && (
                <ToDoEntry
                  text={'Message your provider'}
                  onClick={() =>
                    history.push({
                      pathname: `/consultations-communicator/${pastAppointment.id}`,
                      state: { isDesktopForced: true },
                    })
                  }
                >
                  <AvatarImg style={{ alignSelf: 'center', marginRight: '6px' }}>
                    <img height="38px" alt="" style={{ objectFit: 'cover' }} src={pastAppointment.provider.picture} />
                  </AvatarImg>
                </ToDoEntry>
              )}
              {!!perpetuousPhysicianConversationId && (!!pastAppointment || !!upcomingAppointment) && (
                <ToDoEntry
                  text={'Message your provider'}
                  onClick={() =>
                    history.push({
                      pathname: `/consultations-communicator/${(upcomingAppointment || pastAppointment).id}`,
                      state: { isDesktopForced: true },
                    })
                  }
                >
                  <AvatarImg style={{ alignSelf: 'center', marginRight: '6px' }}>
                    <img height="38px" alt="" style={{ objectFit: 'cover' }} src={(upcomingAppointment || pastAppointment).provider.picture} />
                  </AvatarImg>
                </ToDoEntry>

              )}
          </>
        )}
      </Flex>
    </Card>
  );
};

export default withRouter(withSession(Chat));
