import { Form, Formik } from 'formik';
import React from 'react';
import { Mutation } from 'react-apollo';
import Select from 'react-select';
import { Button, Flex, Box, Text } from 'rebass';
import FormField from '../../components/FormField';
import TextInput from '../../components/TextInput';
import { ADMIN_GET_B2B_COMPANIES_LIST, ADMIN_GET_BROKERS_LIST, ADMIN_UPSERT_B2B_COMPANY, ADMIN_UPSERT_BROKER } from '../../graphql';
import CheckboxInput from '../../components/CheckboxInput';
import { B2BExpiresAfterPeriods, convertExpiresAfterObjectToFriendlyString, convertFriendlyObjectToExpiresAfter } from '../../utils/b2bUtils';

const eligibilityMethods = [
  {
    label: 'Email / names + dob for dependants (default and legacy method)',
    value: 'default',
  },
  {
    label: 'Subscriber ID + names + dob',
    value: 'subscriberId;names',
  },
  {
    label: 'Member ID + names + dob',
    value: 'memberId;names',
  },
  {
    label: 'Employee ID + names + dob',
    value: 'employeeId;names',
  },
  {
    label: 'Unique code',
    value: 'uniqueCode',
  },
  {
    label: 'Names + DoB + Zip',
    value: 'names;dob;zip',
  },
];
const AddB2BCompany = ({ onSave, onClose, company, brokers }) => {
  const broks = ((brokers || {}).getAdminBrokersList || []).map((x) => {
    return {
      label: x.friendlyName || x.name,
      value: x.id,
    };
  });
  return (
    <Mutation
      mutation={ADMIN_UPSERT_B2B_COMPANY}
      refetchQueries={[{ query: ADMIN_GET_BROKERS_LIST }, { query: ADMIN_GET_B2B_COMPANIES_LIST }]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        onSave();
      }}
    >
      {(upsertBroker, { loading }) => (
        <Formik
          isInitialValid={
            ({ initialValues }) => true
            //   schema.isValidSync(initialValues)
          }
          initialValues={{
            ...(company || {}),
            broker: !!company && company.broker ? broks.find((x) => x.value === company.broker.id) : null,
            selectedFreeness:
              company && company.broker && (!company.freeness || company.freeness.id === company.broker.defaultFreeness.id)
                ? {
                    label: 'Broker',
                    value: 'BROKER',
                  }
                : {
                    label: 'Custom',
                    value: 'CUSTOM',
                  },
            kit: company && company.broker && (!company.freeness || company.freeness.id === company.broker.defaultFreeness.id) ? null : ((company || {}).freeness || {}).kit,
            vitamins: company && company.broker && (!company.freeness || company.freeness.id === company.broker.defaultFreeness.id) ? null : ((company || {}).freeness || {}).vitamins,
            membership: company && company.broker && (!company.freeness || company.freeness.id === company.broker.defaultFreeness.id) ? null : ((company || {}).freeness || {}).membership,
            visits: company && company.broker && (!company.freeness || company.freeness.id === company.broker.defaultFreeness.id) ? null : ((company || {}).freeness || {}).visits,
            async: company && company.broker && (!company.freeness || company.freeness.id === company.broker.defaultFreeness.id) ? null : ((company || {}).freeness || {}).async,
            nutritionist: company && company.broker && (!company.freeness || company.freeness.id === company.broker.defaultFreeness.id) ? null : ((company || {}).freeness || {}).nutritionist,
            period:
              company && company.broker && (!company.freeness || company.freeness.id === company.broker.defaultFreeness.id)
                ? null
                : B2BExpiresAfterPeriods.find((x) => x.value === convertFriendlyObjectToExpiresAfter(((company || {}).freeness || {}).expiresAfter).period),
            duration:
              company && company.broker && (!company.freeness || company.freeness.id === company.broker.defaultFreeness.id)
                ? null
                : {
                    label: convertFriendlyObjectToExpiresAfter(((company || {}).freeness || {}).expiresAfter).duration,
                    value: convertFriendlyObjectToExpiresAfter(((company || {}).freeness || {}).expiresAfter).duration,
                  },
                  eligibilityCheckMethod: company && company.eligibilityCheckMethod ? eligibilityMethods.find((x) => x.value === company.eligibilityCheckMethod) : eligibilityMethods.find((x) => x.value === 'default'),
            whereToFindActivationMethodText: company && company.whereToFindActivationMethodText ? company.whereToFindActivationMethodText : `Look for it on the sponsored individual's health insurance card (it may be called "Member ID" or "ID")`,
            // autoActivation: company && company.autoActivation ? company.autoActivation : false,
          }}
          onSubmit={async (values) => {
            if (!values.broker && (!values.selectedFreeness.value || values.selectedFreeness.value === 'BROKER')) {
              alert('If no broker selected, the selected freeness cannot be set to "Broker"');
              return;
            }

            console.log({
              values
            })
            let vars = {
              id: (company || {}).id,
              name: values.name,
              friendlyName: values.friendlyName,
              iconUrl: values.iconUrl,
              freeness:
                !values.selectedFreeness.value || values.selectedFreeness.value === 'BROKER'
                  ? null
                  : {
                      kit: values.kit,
                      vitamins: values.vitamins,
                      membership: values.membership,
                      visits: values.visits,
                      async: values.async,
                      expiresAfter: convertExpiresAfterObjectToFriendlyString((values.duration || {}).value, (values.period || {}).value),
                      nutritionist: values.nutritionist,
                    },
              isActive: values.isActive,
              brokerId: values.broker && values.broker.value ? values.broker.value : null,
              freenessId: !values.selectedFreeness.value || values.selectedFreeness.value === 'BROKER' ? null : ((company || {}).freeness || {}).id,
              eligibilityCheckMethod: !values.eligibilityCheckMethod || !values.eligibilityCheckMethod.value || values.eligibilityCheckMethod.value === 'default' ? null : values.eligibilityCheckMethod.value,
              whereToFindActivationMethodText:
                !values.whereToFindActivationMethodText || !values.eligibilityCheckMethod || !values.eligibilityCheckMethod.value || values.eligibilityCheckMethod.value === 'default'
                  ? null
                  : values.whereToFindActivationMethodText,
            };

            await upsertBroker({
              variables: {
                value: { ...vars },
              },
            });
          }}
          render={({ touched, errors, values, setFieldValue, setFieldTouched }) => {
            console.log({ values });
            return (
              <Form>
                <Flex flexDirection="column" width="600px" style={{ maxHeight: '900px' }}>
                  <FormField style={{ minWidth: '180px' }} mb={4} error={touched.broker && errors.broker}>
                    <label for="broker">broker</label>
                    <Select
                      isClearable
                      classNamePrefix="region-select"
                      options={broks}
                      name="selectedFreeness"
                      value={values.broker}
                      placeholder="select broker (facultative)"
                      onChange={(option) => {
                        setFieldValue('broker', option);
                      }}
                      onBlur={() => setFieldTouched('broker', true)}
                      isSearchable={true}
                    />
                  </FormField>
                  <FormField mb={4} error={touched.name && errors.name}>
                    <label for="name">Name *</label>
                    <TextInput name="name" placeholder="Name" />
                  </FormField>
                  <FormField mb={4} error={touched.friendlyName && errors.friendlyName}>
                    <label for="friendlyName">Friendly Name</label>
                    <TextInput name="friendlyName" placeholder="friendlyName" />
                  </FormField>
                  <FormField mb={4} error={touched.iconUrl && errors.iconUrl}>
                    <label for="iconUrl">Icon Url</label>
                    <Flex>
                      <Box flex={1}>
                        <TextInput name="iconUrl" placeholder="Icon URL" />
                      </Box>
                      <img src={values.iconUrl} alt="iconUrl" style={{ width: '60px', height: '60px' }} />
                    </Flex>
                  </FormField>
                  <FormField style={{ minWidth: '180px' }} mb={4} error={touched.selectedFreeness && errors.selectedFreeness}>
                    <label for="selectedFreeness">freeness</label>
                    <Select
                      classNamePrefix="region-select"
                      options={[
                        {
                          label: 'Broker',
                          value: 'BROKER',
                        },
                        {
                          label: 'Custom',
                          value: 'CUSTOM',
                        },
                      ]}
                      name="selectedFreeness"
                      value={values.selectedFreeness}
                      placeholder="select freeness"
                      onChange={(option) => {
                        setFieldValue('selectedFreeness', option);
                      }}
                      onBlur={() => setFieldTouched('selectedFreeness', true)}
                      isSearchable={true}
                    />
                  </FormField>

                  {values.selectedFreeness &&
                    values.selectedFreeness.value === 'CUSTOM' && (
                      <>
                        <FormField mb={4} error={touched.kit && errors.kit}>
                          <label for="kit">free kits</label>
                          <CheckboxInput
                            name="kit"
                            styles={{
                              checked: {
                                Box: {
                                  backgroundColor: 'rgb(54, 79, 121)',
                                },
                              },
                            }}
                          />
                        </FormField>
                        <FormField mb={4} error={touched.vitamins && errors.vitamins}>
                          <label for="vitamins">free vitamins</label>
                          <CheckboxInput
                            name="vitamins"
                            styles={{
                              checked: {
                                Box: {
                                  backgroundColor: 'rgb(54, 79, 121)',
                                },
                              },
                            }}
                          />
                        </FormField>
                        <FormField mb={4} error={touched.membership && errors.membership}>
                          <label for="membership">free membership</label>
                          <CheckboxInput
                            name="membership"
                            styles={{
                              checked: {
                                Box: {
                                  backgroundColor: 'rgb(54, 79, 121)',
                                },
                              },
                            }}
                          />
                        </FormField>
                        <FormField mb={4} error={touched.visits && errors.visits}>
                          <label for="visits">free visits</label>
                          <CheckboxInput
                            name="visits"
                            styles={{
                              checked: {
                                Box: {
                                  backgroundColor: 'rgb(54, 79, 121)',
                                },
                              },
                            }}
                          />
                        </FormField>
                        <FormField mb={4} error={touched.async && errors.async}>
                          <label for="async">free async visits</label>
                          <CheckboxInput
                            name="async"
                            styles={{
                              checked: {
                                Box: {
                                  backgroundColor: 'rgb(54, 79, 121)',
                                },
                              },
                            }}
                          />
                        </FormField>
                        <FormField mb={4} error={touched.nutritionist && errors.nutritionist}>
                          <label for="nutritionist">free nutritionist visits</label>
                          <CheckboxInput
                            name="nutritionist"
                            styles={{
                              checked: {
                                Box: {
                                  backgroundColor: 'rgb(54, 79, 121)',
                                },
                              },
                            }}
                          />
                        </FormField>

                        <Text>Expires after (set duration to empty or 0 to remove expiration):</Text>
                        <Flex>
                          <Flex style={{ minWidth: '180px' }}>
                            <FormField style={{ minWidth: '180px' }} mb={4} error={touched.duration && errors.duration}>
                              <label for="duration">duration</label>
                              <Select
                                classNamePrefix="region-select"
                                options={[...Array(365).keys()].map((x) => {
                                  return { label: x, value: x };
                                })}
                                name="duration"
                                value={values.duration}
                                placeholder="select duration"
                                onChange={(option) => {
                                  setFieldValue('duration', option);
                                }}
                                onBlur={() => setFieldTouched('duration', true)}
                                isSearchable={true}
                              />
                            </FormField>
                          </Flex>
                          <Flex style={{ minWidth: '250px' }}>
                            <FormField style={{ minWidth: '250px' }} ml={2} mb={4} error={touched.async && errors.async}>
                              <label for="period">period</label>
                              <Select
                                classNamePrefix="region-select"
                                options={B2BExpiresAfterPeriods}
                                name="period"
                                value={values.period}
                                placeholder="select period"
                                onChange={(option) => {
                                  setFieldValue('period', option);
                                }}
                                onBlur={() => setFieldTouched('period', true)}
                                isSearchable={true}
                              />
                            </FormField>
                          </Flex>
                        </Flex>
                      </>
                    )}
                  <FormField style={{ minWidth: '180px' }} mb={4} error={touched.eligibilityCheckMethod && errors.eligibilityCheckMethod}>
                    <label for="eligibilityCheckMethod">Eligibility Check Method</label>
                    <Select
                      classNamePrefix="region-select"
                      options={eligibilityMethods}
                      name="eligibilityCheckMethod"
                      value={values.eligibilityCheckMethod}
                      placeholder="select Eligibility Check Method"
                      onChange={(option) => {
                        setFieldValue('eligibilityCheckMethod', option);
                      }}
                      onBlur={() => setFieldTouched('eligibilityCheckMethod', true)}
                      isSearchable={true}
                    />
                  </FormField>
                  {!!values.eligibilityCheckMethod &&
                    values.eligibilityCheckMethod.value &&
                    values.eligibilityCheckMethod.value !== 'default' && (
                      <FormField mb={4} error={touched.whereToFindActivationMethodText && errors.whereToFindActivationMethodText}>
                        <label for="whereToFindActivationMethodText">Description where to find identifier (member id, subscriber id...)</label>
                        <TextInput name="whereToFindActivationMethodText" placeholder="where to find activation method description" />
                      </FormField>
                    )}

                  <FormField mb={4} error={touched.isActive && errors.isActive}>
                    <label for="isActive">Is Active</label>
                    <CheckboxInput
                      name="isActive"
                      styles={{
                        checked: {
                          Box: {
                            backgroundColor: 'rgb(54, 79, 121)',
                          },
                        },
                      }}
                    />
                  </FormField>

                  <Flex mt={4} flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Button
                      style={{
                        background: 'white',
                        color: '#344f79',
                        border: '1px solid #344f79',
                      }}
                      type="button"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button disabled={!!loading} style={{ background: '#344f79' }}>
                      {(company || {}).id ? 'Update' : 'Save'}
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            );
          }}
        />
      )}
    </Mutation>
  );
};

export default AddB2BCompany;
