import { Form, Formik } from 'formik';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Button, Flex, Box } from 'rebass';
import FormField from '../../../components/FormField';
import TextInput from '../../../components/TextInput';
import { ADMIN_GET_WORKOUTS, ADMIN_GET_WORKOUTS_AUTHORS, ADMIN_UPSERT_WORKOUT_AUTHOR } from '../../../graphql';

const AddAuthor = ({ onSave, onClose, author }) => {
  if (!author) return null;
  return (
    <Mutation
      mutation={ADMIN_UPSERT_WORKOUT_AUTHOR}
      refetchQueries={[{ query: ADMIN_GET_WORKOUTS_AUTHORS }, { query: ADMIN_GET_WORKOUTS }]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        onSave();
      }}
    >
      {(upsertCategory, { loading }) => (
        <Formik
          isInitialValid={
            ({ initialValues }) => true
            //   schema.isValidSync(initialValues)
          }
          initialValues={{
            ...author,
          }}
          // validationSchema={schema}
          onSubmit={async (values) => {
            let vars = {
              id: author.id,
              name: values.name,
              bio: values.bio,
              avatarUrl: values.avatarUrl,
              instagramLink: values.instagramLink,
              facebookLink: values.facebookLink,
              twitterLink: values.twitterLink,
              websiteLink: values.websiteLink,
              youtubeChannelLink: values.youtubeChannelLink,
            };

            await upsertCategory({
              variables: {
                ...vars,
              },
            });
          }}
          render={({ touched, errors, values }) => (
            <Form>
              <Flex flexDirection="column" width="400px" style={{ maxHeight: '900px' }}>
                <FormField mb={4} error={touched.name && errors.name}>
                  <label for="name">Name *</label>
                  <TextInput name="name" placeholder="Name" />
                </FormField>
                <FormField mb={4} error={touched.name && errors.name}>
                  <label for="bio">Bio</label>
                  <TextInput name="bio" placeholder="Bio" />
                </FormField>
                <FormField mb={4} error={touched.avatarUrl && errors.avatarUrl}>
                  <label for="avatarUrl">Avatar Url</label>
                  <Flex>
                    <Box flex={1}>
                      <TextInput name="avatarUrl" placeholder="Avatar Url" />
                    </Box>
                    <img src={values.avatarUrl} alt="Avatar" style={{ borderRadius: '50%', width: '60px', height: '60px' }} />
                  </Flex>
                </FormField>
                <FormField mb={4} error={touched.instagramLink && errors.instagramLink}>
                  <label for="instagramLink">Instagram Link</label>
                  <TextInput name="instagramLink" placeholder="Instagram Link" />
                </FormField>
                <FormField mb={4} error={touched.facebookLink && errors.facebookLink}>
                  <label for="facebookLink">Facebook Link</label>
                  <TextInput name="facebookLink" placeholder="Instagram Link" />
                </FormField>
                <FormField mb={4} error={touched.twitterLink && errors.twitterLink}>
                  <label for="twitterLink">Twitter Link</label>
                  <TextInput name="twitterLink" placeholder="Twitter Link" />
                </FormField>
                <FormField mb={4} error={touched.websiteLink && errors.websiteLink}>
                  <label for="websiteLink">Website Link</label>
                  <TextInput name="websiteLink" placeholder="Website Link" />
                </FormField>
                <FormField mb={4} error={touched.youtubeChannelLink && errors.youtubeChannelLink}>
                  <label for="youtubeChannelLink">Youtube Channel Link</label>
                  <TextInput name="youtubeChannelLink" placeholder="Youtube Channel Link" />
                </FormField>

                <Flex mt={4} flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Button
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button disabled={!!loading} style={{ background: '#344f79' }}>
                    {author.id ? 'Update' : 'Save'}
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )}
        />
      )}
    </Mutation>
  );
};

export default AddAuthor;
