import React from 'react';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import styled from 'styled-components';
import { withToastManager } from 'react-toast-notifications';
import * as Yup from 'yup';

import { CURRENT_USER, SET_UNIVERSAL_PASSWORD } from '../../graphql';
import withSession from '../../lib/withSession';
import ConfirmModal from '../ConfirmModal';
import { Mutation } from 'react-apollo';
import { Form, Formik } from 'formik';
import FormField from '../FormField';
import TextInput from '../TextInput';
import withAnalytics from '../../lib/withAnalytics';
import { isMobile } from 'react-device-detect';

const schema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(8, 'Password must be greater than 8 characters')
    .required('Required'),
});

const InfoBox = styled(Flex)`
  flex: 1;
  background: ${props => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${props => props.theme.color.darkBlue};
  justify-content: center;
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const MergeAccountsModal = ({
  session: { mustMergeAccount },
  analytics,
  toastManager,
}) => {
  return (
    <Mutation
      mutation={SET_UNIVERSAL_PASSWORD}
      refetchQueries={[{ query: CURRENT_USER }]}
      awaitRefetchQueries
      onCompleted={data => {
        if (
          !data ||
          !data.setUniversalPassword ||
          !data.setUniversalPassword.ok
        ) {
          toastManager.add('An error occured, please try again', {
            appearance: 'error',
          });
          return;
        }
        toastManager.add('Accounts merged successfully', {
          appearance: 'success',
        });
        analytics.track('Accounts merged from web');
      }}
    >
      {(setPwd, { loading }) => (
        <ConfirmModal
          isOpen={!!mustMergeAccount}
          onClose={() => {}}
          onBackgroundClick={() => {}}
          onConfirm={() => {}}
          childrenManaged
          style={{
            margin: isMobile ? '0' : '20px',
            padding: isMobile ? '0' : '20px',
          }}
          cardStyle={{
            maxHeight: '100vh',
            overflowY: 'auto'
          }}
        >
          <ModalInner>
            <Heading mb={4} textAlign="center">
              Your Experience Just Got Better!
            </Heading>
            <InfoBox mb={4}>
              <Text textAlign="center">
                Your web experience and mobile experience are slowly merging
                together. you have 2 accounts that need to be merged.
              </Text>
            </InfoBox>
            <Text mb={3} textAlign="center">
              Please enter the password you want to use on both platforms moving
              forward.
            </Text>
            <Formik
              initialValues={{
                password: '',
              }}
              validationSchema={schema}
              onSubmit={values =>
                setPwd({
                  variables: {
                    pwd: values.password,
                  },
                })
              }
              render={({ errors, touched, isValid }) => (
                <Form style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormField mb={3} error={touched.password && errors.password}>
                    <TextInput
                      disabled={!!loading}
                      placeholder="Password"
                      type="password"
                      name="password"
                    />
                  </FormField>

                  <Button
                    style={{ alignSelf: 'center' }}
                    variant="primary"
                    type="submit"
                    disabled={!!loading || !isValid}
                  >
                    {loading ? 'Updating...' : 'Update'}
                  </Button>
                </Form>
              )}
            />
          </ModalInner>
        </ConfirmModal>
      )}
    </Mutation>
  );
};

export default withSession(withAnalytics(withToastManager(MergeAccountsModal)));
