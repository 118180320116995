import styled from 'styled-components';

const ButtonLink = styled.a`
  box-sizing: border-box;
  margin: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: inherit;
  color: white;
  background-color: blue;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  text-align: center;
  line-height: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-weight: bold;
  border: 0;
  border-radius: 4px;
  border-radius: 45px;
  background-color: ${props => props.bg ? props.bg : '#344f79'};
  border: none;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  width: 100%;

  @media screen and (min-width: 52em) {
    width: auto;
  }
`;

export default ButtonLink;