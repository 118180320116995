const theme = {
  fonts: {
    sans: 'Poppin, sans-serif',
    serif: 'Playfair Display, serif',
  },
  color: {
    primary: '#000',
    danger: 'red',
    information: '#d9e6f9',
    white: '#fff',
    darkBlue: '#344f79',
    lightBlue: '#d9e6f9',
    red: 'red',
    grey: '#999',
    greyLight: '#ccc',
    greyDark: '#333',
    pink: '#f9a394',
  },
  Card: {
    flex: 1,
    borderRadius: '5px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.10)',
    background: 'white',
  },
  bg: {
    default: '#f8f8f8',
    border: '#eee',
    inactive: '#eee',
    success: '#344f77',
    error: '#f9a49c',
    info: '#fff',
  },
  toast: {
    success: '#d9e6f9',
    warning: '#f9a394',
    error: '#f9a49c',
    info: '#344f77',
  },
  toastText: {
    info: '#fff',
    warning: '#fff',
    error: '#fff',
    success: '#344f77',
  },
  text: {
    placeholder: '#888',
    success: '#fff',
    error: '#fff',
    info: 'black',
  },
  brand: {
    default: '#344f79',
  },
  Button: {
    cursor: 'pointer',
    ':focus': {
      outline: 'none',
    },
    ':disabled': {
      backgroundColor: '#ccc',
      borderColor: '#ccc',
      color: '#999',
      cursor: '',
    },
  },
  errorTooltip: {
    backgroundColor: 'red',
    borderColor: 'red',
    color: '#fff',
  },
  buttons: {
    primary: {
      borderRadius: '45px',
      backgroundColor: '#344f79',
      border: 'none',
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '25px',
      paddingRight: '25px',
    },
    secondary: {
      borderRadius: '45px',
      backgroundColor: '#344f79',
      border: 0,
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '25px',
      paddingRight: '25px',
    },
    outline: {
      borderRadius: '25px',
      backgroundColor: 'transparent',
      color: '#344F79',
      border: '2px solid #344F79',
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '25px',
      paddingRight: '25px',
      width: '100%',
      ':hover': {
        backgroundColor: '#344F79',
        color: 'white',
      },
    },
    dim: {
      backgroundColor: '#d9e6f9',
      color: '#344f79',
      border: 'none',
      borderRadius: '45px',
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '25px',
      paddingRight: '25px',
    },
    pink: {
      backgroundColor: '#f9a394',
      border: 'none',
      borderRadius: '45px',
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '25px',
      paddingRight: '25px',
    },
    white: {
      borderRadius: '25px',
      backgroundColor: 'white',
      color: '#344f79',
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '25px',
      paddingRight: '25px',
    },
    white2: {
      borderRadius: '25px',
      backgroundColor: 'white',
      color: '#344f79',
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '25px',
      paddingRight: '25px',
      ':disabled': {
        backgroundColor: 'white !important',
        opacity: 0.3,
      },
    },
    selected: {
      borderRadius: '25px',
      backgroundColor: '#dae6fa',
      color: '#344f79',
      border: 'none',
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '25px',
      paddingRight: '25px',
    },
    disabled: {
      borderRadius: '25px',
      fontWeight: '400',
      backgroundColor: '#4467e2',
      filter: 'grayscale(100%)',
    },
    borderOnlyWhite: {
      borderRadius: '25px',
      backgroundColor: 'transparent',
      color: 'white',
      border: '2px solid white',
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '25px',
      paddingRight: '25px',
      width: '100%',
      ':hover': {
        backgroundColor: 'white',
        color: '#344F79',
      },
    },
    rectangle: {
      backgroundColor: '#4467e2',
    },
    danger: {
      borderRadius: '45px',
      backgroundColor: '#dc0000',
      color:'white',
      border: 'none',
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '25px',
      paddingRight: '25px',
      ':hover': {
        backgroundColor: '#AC0000',
        color: 'white',
      },
    },
  },
  Heading: {
    fontFamily: 'Playfair Display, serif',
  },
  pxScale: 8,
};

theme.px = value => {
  const values = [].concat(value);
  return values
    .map(v => (typeof v === 'string' ? v : `${v * theme.pxScale}px`))
    .join(' ');
};

export default theme;
