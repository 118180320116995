import React, { useState } from 'react';
import { Query } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { ADMIN_GET_B2B_COMPANIES_LIST } from '../../graphql';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import { convertExpiresAfterToFriendlyString } from '../../utils/b2bUtils';
import ConfirmModal from '../../components/ConfirmModal';
import styled from 'styled-components';
import AddB2BCompany from './AddB2BCompany';
import ModalUploadEligible from './ModalUpload';
import ModalUploadEligibilityResults from './ModalUploadEligibilityResults';
import ModalViewEmployees from './ModalViewEmployees';
import { withToastManager } from 'react-toast-notifications';
import ModalUploadEligibleDiff from './ModalUploadDiff';

const ModalInner = styled(Box)`
  max-width: 600px;
  min-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;
const DetailRows = ({ rows, setIsAdd }) => {
  console.log({ rows });
  if (rows.type !== 'BROKER') return null;
  const deleteOnClick = (id) => {};
  const CATEGORIES_COLUMNS = [
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'friendly name',
      accessor: 'friendlyName',
    },
    {
      Header: 'active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div style={{ textAlign: 'center', color: row.original.isActive ? 'green' : 'red' }}>{row.original.isActive ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'freeness',
      Cell: (row) => {
        if (rows.type === 'COMPANY') {
          return <Text style={{ textAlign: 'center' }}>Custom</Text>;
        } else {
          if (!!row.original.freeness && row.original.freeness.id !== rows.defaultFreeness.id) {
            return <Text style={{ textAlign: 'center' }}>Custom</Text>;
          } else {
            return <Text style={{ textAlign: 'center' }}>Broker</Text>;
          }
        }
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        if (rows.type === 'BROKER') {
          return (
            <Flex flexDirection="row">
              <Flex flexDirection="row">
                <div title="delete" style={{ cursor: 'pointer' }} onClick={() => deleteOnClick(row.original.id)}>
                  🗑
                </div>
                <div title="edit" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => setIsAdd(row.original)}>
                  ✎
                </div>
              </Flex>
            </Flex>
          );
        } else {
          return <div />;
        }
      },
    },
  ];

  return (
    <ReactTable
      data={rows.companies}
      columns={CATEGORIES_COLUMNS}
      defaultPageSize={rows.companies && rows.companies.length > 3 ? Math.min(rows.companies.length, 25) : 3}
      pageSize={rows.companies && rows.companies.length > 3 ? rows.companies.length : 3}
      style={{ marginLeft: 24, marginBottom: 24 }}
      showPagination={rows.companies && rows.companies.length > 24}
    />
  );
};

const Companies = ({ data, toastManager }) => {
  const [isAdd, setIsAdd] = useState(null);
  const [isDrillBroker, setIsDrillBroker] = useState(false);
  const [isUploadEligibility, setIsUploadEligibility] = useState(null);
  const [uploadResults, setUploadResults] = useState(null);
  const [viewRegisteredEmployees, setViewRegisteredEmployees] = useState(null);

  const CATEGORIES_COLUMNS = [
    {
      Header: 'type',
      accessor: 'type',
    },
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'friendly name',
      accessor: 'friendlyName',
    },
    {
      Header: 'active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div style={{ textAlign: 'center', color: row.original.isActive ? 'green' : 'red' }}>{row.original.isActive ? '✓' : ''}</div>;
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        if (row.original.type === 'COMPANY') {
          return (
            <Flex flexDirection="row">
              <Flex flexDirection="row">
                <div title="delete" style={{ cursor: 'pointer' }} onClick={() => deleteOnClick(row.original.id)}>
                  🗑
                </div>
                <div title="edit" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => setIsAdd(row.original)}>
                  ✎
                </div>
              </Flex>
            </Flex>
          );
        } else {
          return <div />;
        }
      },
    },
  ];

  const CATEGORIES_COLUMNS2 = [
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'friendly name',
      accessor: 'friendlyName',
    },
    {
      Header: 'active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div style={{ textAlign: 'center', color: row.original.isActive ? 'green' : 'red' }}>{row.original.isActive ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'kit',
      Cell: (row) => {
        return (
          <div style={{ textAlign: 'center', color: (row.original.freeness || ((row.original.broker || {}).defaultFreeness || {}) || {}).kit ? 'green' : 'red' }}>
            {(row.original.freeness || ((row.original.broker || {}).defaultFreeness || {}) || {}).kit ? '✓' : '∅'}
          </div>
        );
      },
    },
    {
      Header: 'vitamins',
      Cell: (row) => {
        return (
          <div style={{ textAlign: 'center', color: (row.original.freeness || ((row.original.broker || {}).defaultFreeness || {}) || {}).vitamins ? 'green' : 'red' }}>
            {(row.original.freeness || {}).vitamins || ((row.original.broker || {}).defaultFreeness || {}).vitamins ? '✓' : '∅'}
          </div>
        );
      },
    },
    {
      Header: 'membership',
      Cell: (row) => {
        return (
          <div style={{ textAlign: 'center', color: (row.original.freeness || ((row.original.broker || {}).defaultFreeness || {}) || {}).membership ? 'green' : 'red' }}>
            {(row.original.freeness || {}).membership || ((row.original.broker || {}).defaultFreeness || {}).membership ? '✓' : '∅'}
          </div>
        );
      },
    },
    {
      Header: 'visits',
      Cell: (row) => {
        return (
          <div style={{ textAlign: 'center', color: (row.original.freeness || ((row.original.broker || {}).defaultFreeness || {}) || {}).visits ? 'green' : 'red' }}>
            {(row.original.freeness || {}).visits || ((row.original.broker || {}).defaultFreeness || {}).visits ? '✓' : '∅'}
          </div>
        );
      },
    },
    {
      Header: 'async',
      Cell: (row) => {
        return (
          <div style={{ textAlign: 'center', color: (row.original.freeness || ((row.original.broker || {}).defaultFreeness || {}) || {}).async ? 'green' : 'red' }}>
            {(row.original.freeness || {}).async || ((row.original.broker || {}).defaultFreeness || {}).async ? '✓' : '∅'}
          </div>
        );
      },
    },
    {
      Header: 'nutritionist',
      Cell: (row) => {
        return (
          <div style={{ textAlign: 'center', color: (row.original.freeness || ((row.original.broker || {}).defaultFreeness || {}) || {}).nutritionist ? 'green' : 'red' }}>
            {(row.original.freeness || {}).nutritionist || ((row.original.broker || {}).defaultFreeness || {}).nutritionist ? '✓' : '∅'}
          </div>
        );
      },
    },
    {
      Header: 'expires after',
      Cell: (row) => {
        return <div style={{ textAlign: 'center' }}>{convertExpiresAfterToFriendlyString((row.original.freeness || ((row.original.broker || {}).defaultFreeness || {}) || {}).expiresAfter)}</div>;
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <Flex flexDirection="row">
              <div title="upload file" style={{ cursor: 'pointer' }} onClick={() => setIsUploadEligibility(row.original)}>
                &#128206;
                {/* 🗑 */}
              </div>
              <div title="edit" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => setIsAdd(row.original)}>
                ✎
              </div>
              <div title="eligibility list" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => setViewRegisteredEmployees(row.original)}>
                👤
              </div>
            </Flex>
          </Flex>
        );
      },
    },
  ];

  const deleteOnClick = (id) => {
    setIsUploadEligibility(id);
  };

  const onUploadFileCompleted = (results) => {
    console.log({ resultsUpload: results });
    let final = [];
    const inserts = results.insert || [];
    inserts.map((x) => {
      if (!!x.error) {
        final.push({
          ...x,
          type: '⚠',
        });
      } else {
        final.push({
          ...x,
          type: '+',
        });
      }
    });
    const deletes = results.delete || [];
    deletes.map((x) => {
      if (x.employeeId) {
        let index = (final || []).findIndex((xx) => xx.employeeId === x.employeeId);
        if (index > -1 && final[index].type !== '⚠') {
          final[index].type = '';
        } else {
          if (!!x.error) {
            final.push({
              ...x,
              type: '⚠',
            });
          } else {
            final.push({
              ...x,
              type: '-',
            });
          }
        }
      } else if (x.employeeEmail) {
        let index = (final || []).findIndex((xx) => xx.employeeEmail === x.employeeEmail);
        if (index > -1 && final[index].type !== '⚠') {
          final[index].type = '';
        } else {
          if (!!x.error) {
            final.push({
              ...x,
              type: '⚠',
            });
          } else {
            final.push({
              ...x,
              type: '-',
            });
          }
        }
      } else {
        let index = (final || []).findIndex(
          (xx) =>
            (xx.firstName &&
              x.firstName &&
              xx.lastName &&
              x.lastName &&
              xx.dob &&
              x.dob &&
              xx.firstName.toLowerCase() === x.firstName.toLowerCase() &&
              xx.lastName.toLowerCase() === x.lastName.toLowerCase() &&
              xx.dob === x.dob) ||
            (x.employeeId && xx.employeeId && x.employeeId.toLowerCase() === xx.employeeId.toLowerCase()) ||
            (x.memberId && xx.memberId && x.memberId.toLowerCase() === xx.memberId.toLowerCase()) ||
            (x.subscriberId && xx.subscriberId && x.subscriberId.toLowerCase() === xx.subscriberId.toLowerCase()) ||
            (x.uniqueCode && xx.uniqueCode && x.uniqueCode.toLowerCase() === xx.uniqueCode.toLowerCase())
        );
        if (index > -1 && final[index].type !== '⚠') {
          final[index].type = '';
        } else {
          if (!!x.error) {
            final.push({
              ...x,
              type: '⚠',
            });
          } else {
            final.push({
              ...x,
              type: '-',
            });
          }
        }
      }
    });
    setIsUploadEligibility(null);
    setUploadResults(final);
  };
  return (
    <Query query={ADMIN_GET_B2B_COMPANIES_LIST}>
      {({ data: dataCompanies, loading, error }) => {
        if (!!loading) return null;
        console.log({ dataCompanies });
        const set = new Set(
          (dataCompanies.getAdminB2BCompanies || []).filter((x) => !!x.broker).map((x) => {
            return x.broker.id;
          })
        );
        console.log({ set });
        let result = [];
        set.forEach((x) => {
          const brokers = (data.getAdminBrokersList || []).find((xx) => xx.id === x);
          // for (let index = 0; index < brokers.length; index++) {
          //   const element = brokers[index];
          const bCompanies = ((dataCompanies || {}).getAdminB2BCompanies || []).filter((xx) => !!xx.broker && xx.broker.id === brokers.id);

          result.push({
            ...brokers,
            companies: bCompanies,
            type: 'BROKER',
          });
          // }
        });

        (dataCompanies.getAdminB2BCompanies || []).filter((x) => !x.broker).map((x) => {
          result.push({
            ...x,
            type: 'COMPANY',
          });
        });
        console.log({ result });
        return (
          <div>
            <Flex flexDirection="row" style={{ justifyContent: 'space-between' }} mt={4} mb={3}>
              <Heading fontSize="20px">Companies</Heading>
              <Text
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  setIsDrillBroker(!isDrillBroker);
                }}
              >
                {isDrillBroker ? 'view regular format' : 'format broker drill'}
              </Text>
              <Button
                style={{ background: '#344f79' }}
                onClick={() => {
                  setIsAdd({});
                }}
              >
                Add Company
              </Button>
            </Flex>
            {!!isDrillBroker ? (
              <ReactTable
                data={result.sort((a, b) => {
                  if ((a.friendlyName || a.name) > (b.friendlyName || b.name)) return 1;
                  return -1;
                })}
                columns={CATEGORIES_COLUMNS}
                defaultPageSize={result && result.length > 3 ? Math.min(result.length, 25) : 3}
                pageSize={result && result.length > 3 ? result.length : 3}
                showPagination={result && result.length > 24}
                SubComponent={(row) => {
                  return <DetailRows rows={row.original} setIsAdd={setIsAdd} />;
                }}
              />
            ) : (
              <ReactTable
                data={(dataCompanies.getAdminB2BCompanies || []).sort((a, b) => {
                  if ((a.friendlyName || a.name) > (b.friendlyName || b.name)) return 1;
                  return -1;
                })}
                columns={CATEGORIES_COLUMNS2}
                defaultPageSize={dataCompanies.getAdminB2BCompanies && dataCompanies.getAdminB2BCompanies.length > 3 ? Math.min(dataCompanies.getAdminB2BCompanies.length, 25) : 3}
                pageSize={dataCompanies.getAdminB2BCompanies && dataCompanies.getAdminB2BCompanies.length > 3 ? dataCompanies.getAdminB2BCompanies.length : 3}
                showPagination={dataCompanies.getAdminB2BCompanies && dataCompanies.getAdminB2BCompanies.length > 24}
              />
            )}

            <ConfirmModal
              childrenManaged
              isOpen={!!isAdd}
              confirmLabel="create"
              onClose={() => {
                setIsAdd(null);
              }}
              onBackgroundClick={() => {
                setIsAdd(null);
              }}
              onEscapeKeydown={() => {
                setIsAdd(null);
              }}
              onConfirm={() => {
                setIsAdd(null);
              }}
            >
              <ModalInner>
                <Heading fontSize="20px" mb={3}>
                  {!!isAdd && !!isAdd.id ? 'Update Company' : 'Add Company'}
                </Heading>
                <AddB2BCompany
                  brokers={data}
                  company={isAdd}
                  onClose={() => {
                    setIsAdd(null);
                  }}
                  onSave={() => {
                    setIsAdd(null);
                  }}
                />
              </ModalInner>
            </ConfirmModal>

            <ConfirmModal
              childrenManaged
              isOpen={!!isUploadEligibility}
              confirmLabel="create"
              onClose={() => {
                setIsUploadEligibility(null);
              }}
              onBackgroundClick={() => {
                setIsUploadEligibility(null);
              }}
              onEscapeKeydown={() => {
                setIsUploadEligibility(null);
              }}
              onConfirm={() => {
                setIsUploadEligibility(null);
              }}
            >
              <ModalInner>
                <Heading fontSize="20px" mb={3}>
                  {!!isUploadEligibility && !!isUploadEligibility.id ? `Upload ${isUploadEligibility.name} eligibility file` : ''}
                </Heading>
                {/* <ModalUploadEligible company={isUploadEligibility} onComplete={onUploadFileCompleted} /> */}
                {!!isUploadEligibility && (
                  <ModalUploadEligibleDiff eligibilityCheckMethod={isUploadEligibility.eligibilityCheckMethod} company={isUploadEligibility} onComplete={()=>setIsUploadEligibility(null)} />
                )}
              </ModalInner>
            </ConfirmModal>

            <ConfirmModal
              childrenManaged
              isOpen={!!uploadResults}
              confirmLabel="create"
              onClose={() => {
                setUploadResults(null);
              }}
              onBackgroundClick={() => {
                setUploadResults(null);
              }}
              onEscapeKeydown={() => {
                setUploadResults(null);
              }}
              onConfirm={() => {
                setUploadResults(null);
              }}
              displayX
            >
              <ModalInner>
                <Heading fontSize="20px" mb={3}>
                  Upload results
                </Heading>
                <ModalUploadEligibilityResults results={uploadResults} />
              </ModalInner>
            </ConfirmModal>
            {!!viewRegisteredEmployees && (
              <ConfirmModal
                childrenManaged
                isOpen={!!viewRegisteredEmployees}
                confirmLabel="create"
                onClose={() => {
                  setViewRegisteredEmployees(null);
                }}
                onBackgroundClick={() => {
                  setViewRegisteredEmployees(null);
                }}
                onEscapeKeydown={() => {
                  setViewRegisteredEmployees(null);
                }}
                onConfirm={() => {
                  setViewRegisteredEmployees(null);
                }}
                displayX
              >
                <ModalInner
                  style={{
                    maxWidth: '80vw',
                    minWidth: '80vw',
                    width: '80vw',
                    maxHeight: '80vh',
                    minHeight: '80vh',
                    height: '80vh',
                  }}
                >
                  <Heading fontSize="20px" mb={3}>
                    Registered employees
                  </Heading>
                  <ModalViewEmployees companyId={viewRegisteredEmployees.id} />
                </ModalInner>
              </ConfirmModal>
            )}
          </div>
        );
      }}
    </Query>
  );
};

export default withToastManager(Companies);
