import React from 'react';
import { Flex, Button } from 'rebass';
import { Query, Mutation } from 'react-apollo';
import Select from 'react-select';
import 'react-table-v6/react-table.css';
import { ADMIN_GET_MOBILE_COURSE_JOURNEYS, ADMIN_GET_MOBILE_COURSES, ADMIN_ADD_MOBILE_COURSE, ADMIN_GET_THYROID_PROGRAMS_SECTIONS, ADMIN_ADD_THYROID_PROGRAM_COURSE, ADMIN_GET_THYROID_PROGRAM_COURSES } from '../../../graphql';
import CheckboxInput from '../../../components/CheckboxInput';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import TextInput from '../../../components/TextInput';
//bg:#659B96
// hormone: #84AFA4
const StandardDetails = ({ courseIcons, setFieldTouched, setFieldValue, values }) => {
  return (
    <>
      {/* <FormField mb={4}>
        <label for="screensColor">Screens Color</label>
        <TextInput name="screensColor" placeholder="Screens Color" />
      </FormField>

      <FormField mb={4}>
        <label for="hormoneColor">Hormone Color</label>
        <TextInput name="hormoneColor" placeholder="Hormone Color" />
      </FormField> */}

      <FormField mb={4}>
        <label for="icon">Course Icon</label>
        <Select
          classNamePrefix="region-select"
          options={courseIcons}
          name="icon"
          value={values.icon}
          placeholder="select icon"
          onChange={(option) => {
            setFieldValue('icon', option);
          }}
          onBlur={() => setFieldTouched('icon', true)}
          isSearchable={true}
        />
      </FormField>
    </>
  );
};

const FoodJournalingDetails = ({ courseIcons, setFieldTouched, setFieldValue, values }) => {
  return (
    <>
      <FormField mb={4}>
        <label for="icon">Course Icon</label>
        <Select
          classNamePrefix="region-select"
          options={courseIcons}
          name="icon"
          value={values.icon}
          placeholder="select icon"
          onChange={(option) => {
            setFieldValue('icon', option);
          }}
          onBlur={() => setFieldTouched('icon', true)}
          isSearchable={true}
        />
      </FormField>
    </>
  );
};

const WebflowArticleDetails = ({ setFieldTouched, setFieldValue, values }) => {
  return (
    <>
      <FormField mb={4}>
        <label for="webflowArticleId">Webflow Article Id</label>
        <TextInput name="webflowArticleId" placeholder="Webflow Article Id" />
      </FormField>
    </>
  );
};

const WebPageDetails = ({ setFieldTouched, setFieldValue, values }) => {
  return (
    <>
      <FormField mb={4}>
        <label for="webflowArticleId">External Article URL</label>
        <TextInput name="externalArticleLink" placeholder="External Article URL" />
      </FormField>
    </>
  );
};

const AddProgramCourseModalInner = ({ programId, onClose, onSave, editingData }) => {
  const weekValues = [...Array(20).keys()].map((i) => {
    return {
      value: i + 1,
      label: i + 1,
    };
  });
  const ordersValues = [...Array(20).keys()].map((i) => {
    return {
      value: i + 1,
      label: i + 1,
    };
  });
  const courseIcons = [
    {
      value: 'PLANT',
      label: 'Plant',
    },
    { value: '2pills', label: '2pills' },
    { value: 'Avocado', label: 'Avocado' },
    { value: 'Chat_bubble', label: 'Chat_bubble' },
    { value: 'Female_doctor', label: 'Female_doctor' },
    { value: 'Fingerprick', label: 'Fingerprick' },
    { value: 'Icon_cloud-1', label: 'Icon_cloud-1' },
    { value: 'Icon_cloud', label: 'Icon_cloud' },
    { value: 'Icon_moon', label: 'Icon_moon' },
    { value: 'Male_doctor', label: 'Male_doctor' },
    { value: 'Medication_tube_single', label: 'Medication_tube_single' },
    { value: 'Multiple_medications', label: 'Multiple_medications' },
    { value: 'Paper_Airplane', label: 'Paper_Airplane' },
    { value: 'Plate', label: 'Plate' },
    { value: 'Telemedicine_consult', label: 'Telemedicine_consult' },
    { value: 'computer_chart', label: 'computer_chart' },
    { value: 'doc_with_labs', label: 'doc_with_labs' },
    { value: 'files', label: 'files' },
    { value: 'handshake', label: 'handshake' },
    { value: 'icon_card', label: 'icon_card' },
    { value: 'icon_drop', label: 'icon_drop' },
    { value: 'icon_kit', label: 'icon_kit' },
    { value: 'icon_medicine', label: 'icon_medicine' },
    { value: 'icon_plate', label: 'icon_plate' },
    { value: 'icon_scale', label: 'icon_scale' },
    { value: 'icon_tablets', label: 'icon_tablets' },
    { value: 'kit_3d', label: 'kit_3d' },
    { value: 'report', label: 'report' },
    { value: 'thyroid', label: 'thyroid' },
  ];
  const courseTypes = [
    {
      label: 'Standard',
      value: 'Standard',
    },
    {
      label: 'Webflow Article',
      value: 'WebflowArticle',
    },
    {
      label: 'Web Page',
      value: 'WebPage',
    },
    {
      label: 'Food Journaling',
      value: 'FoodJournaling',
    },
  ];
  return (
    <Query query={ADMIN_GET_THYROID_PROGRAMS_SECTIONS} variables={{ programId: programId }}>
      {({ data, loading, error }) => {
        if (loading || error) return <div />;
        const journeys = data.getAdminProgramSectionWithCourses.map((x) => {
          return {
            value: x.id,
            label: x.name,
          };
        });
        console.log({editingData, journeys})
        return (
          <Mutation
            mutation={ADMIN_ADD_THYROID_PROGRAM_COURSE}
            refetchQueries={[{ query: ADMIN_GET_THYROID_PROGRAMS_SECTIONS, variables: { programId: programId } }, {query: ADMIN_GET_THYROID_PROGRAM_COURSES, variables: {programId}}]}
            onCompleted={() => {
              onSave();
            }}
          >
            {(createJourney, { loading }) => (
              <Formik
                isInitialValid={
                  ({ initialValues }) => true
                  //   schema.isValidSync(initialValues)
                }
                initialValues={
                  !editingData
                    ? {
                        title: '',
                        description: '',
                        isActive: true,
                        isPro: false,
                        programId: programId,
                        sectionId: null,
                        week: null,
                        order: null,
                        bgMainColor: '#63799E',
                        bgBubblesColor: '#4E658A',
                        titleColor: '#ffffff',
                        descriptionColor: '#ffffff',
                        icon: '',
                        webflowArticleId: null,
                        externalArticleLink: null,
                        isFoodJournaling: null,
                        courseType: courseTypes.find((x) => x.value === 'Standard'),
                      }
                    : {
                        ...editingData,
                        programId: programId,
                        section: journeys.find((x) => x.value === editingData.programSection.id),
                        week: {
                          value: editingData.week,
                          label: editingData.week,
                        },
                        order: {
                          value: editingData.order,
                          label: editingData.order,
                        },
                        icon: courseIcons.find((x) => x.value === editingData.icon),
                        courseType: editingData.isFoodJournaling
                          ? 'FoodJournaling'
                          : editingData.webflowArticleId
                            ? courseTypes.find((x) => x.value === 'WebflowArticle')
                            : editingData.externalArticleLink
                              ? courseTypes.find((x) => x.value === 'WebPage')
                              : courseTypes.find((x) => x.value === 'Standard'),
                      }
                }
                // validationSchema={schema}
                onSubmit={(values, actions) => {
                  console.log({values})
                  let vars = {};
                  switch (values.courseType.value) {
                    case 'Standard':
                      vars = {
                        id: editingData && editingData.id ? editingData.id : null,
                        title: values.title,
                        description: values.description,
                        isActive: values.isActive,
                        isPro: values.isPro,
                        programId: programId,
                        sectionId: values.section.value,
                        week: null,
                        order: values.order.value,
                        bgMainColor: values.bgMainColor,
                        bgBubblesColor: values.bgBubblesColor,
                        titleColor: values.titleColor,
                        descriptionColor: values.descriptionColor,
                        icon: values.icon.value,
                        webflowArticleId: null,
                        externalArticleLink: null,
                        isFoodJournaling: false,
                      };
                      break;
                    case 'WebflowArticle':
                      vars = {
                        id: editingData && editingData.id ? editingData.id : null,
                        title: values.title,
                        description: values.description,
                        isActive: values.isActive,
                        isPro: values.isPro,
                        programId: programId,
                        sectionId: values.section.value,
                        week: null,
                        order: values.order.value,
                        bgMainColor: values.bgMainColor,
                        bgBubblesColor: values.bgBubblesColor,
                        titleColor: values.titleColor,
                        descriptionColor: values.descriptionColor,
                        icon: 'PLANT',
                        webflowArticleId: values.webflowArticleId,
                        externalArticleLink: null,
                        isFoodJournaling: false,
                      };
                      break;
                    case 'WebPage':
                      vars = {
                        id: editingData && editingData.id ? editingData.id : null,
                        title: values.title,
                        description: values.description,
                        isActive: values.isActive,
                        isPro: values.isPro,
                        programId: programId,
                        sectionId: values.section.value,
                        week: null,
                        order: values.order.value,
                        bgMainColor: values.bgMainColor,
                        bgBubblesColor: values.bgBubblesColor,
                        titleColor: values.titleColor,
                        descriptionColor: values.descriptionColor,
                        icon: 'PLANT',
                        webflowArticleId: null,
                        externalArticleLink: values.externalArticleLink,
                        isFoodJournaling: false,
                      };
                      break;
                    case 'FoodJournaling':
                      vars = {
                        id: editingData && editingData.id ? editingData.id : null,
                        title: values.title,
                        description: values.description,
                        isActive: values.isActive,
                        isPro: values.isPro,
                        programId: programId,
                        sectionId: values.section.value,
                        week: null,
                        order: values.order.value,
                        bgMainColor: values.bgMainColor,
                        bgBubblesColor: values.bgBubblesColor,
                        titleColor: values.titleColor,
                        descriptionColor: values.descriptionColor,
                        icon: values.icon.value,
                        webflowArticleId: null,
                        externalArticleLink: null,
                        isFoodJournaling: true,
                      };
                      break;

                    default:
                      break;
                  }
                  createJourney({
                    variables: vars,
                  });
                  // submitCheck(values, actions)
                }}
                render={({ touched, errors, isValid, handleChange, handleBlur, isSubmitting, setFieldValue, setFieldTouched, values }) => (
                  <Form>
                    <Flex flexDirection="column" width="400px" style={{ maxHeight: '900px' }} mt={3}>
                      <FormField mb={4} error={touched.phone && errors.phone}>
                        <label for="title">Title</label>
                        <TextInput name="title" placeholder="Title" />
                      </FormField>
                      <FormField mb={4} error={touched.phone && errors.phone}>
                        <label for="description">Description</label>
                        <TextInput name="description" placeholder="Description" />
                      </FormField>

                      <FormField mb={4} error={touched.section && errors.section}>
                        <label for="journey">Section</label>
                        <Select
                          classNamePrefix="region-select"
                          options={journeys}
                          name="section"
                          value={values.section}
                          placeholder="select section"
                          onChange={(option) => {
                            setFieldValue('section', option);
                          }}
                          onBlur={() => setFieldTouched('section', true)}
                          isSearchable={true}
                        />
                      </FormField>

                      <FormField mb={4} error={touched.phone && errors.phone}>
                        <label for="order">Order</label>
                        <Select
                          classNamePrefix="region-select"
                          options={ordersValues}
                          name="order"
                          value={values.order}
                          placeholder="select order"
                          onChange={(option) => {
                            setFieldValue('order', option);
                          }}
                          onBlur={() => setFieldTouched('order', true)}
                          isSearchable={true}
                        />
                      </FormField>

                      <FormField mb={4} error={touched.phone && errors.phone}>
                        <label for="week">Course Type</label>
                        <Select
                          classNamePrefix="region-select"
                          options={courseTypes}
                          name="week"
                          value={values.courseType}
                          placeholder="Course Type"
                          onChange={(option) => {
                            setFieldValue('courseType', option);
                          }}
                          onBlur={() => setFieldTouched('courseType', true)}
                        />
                      </FormField>

                      <FormField mb={4} error={touched.bgMainColor && errors.bgMainColor}>
                        <label for="bgMainColor">Screen Background Color</label>
                        <TextInput name="bgMainColor" placeholder="Screen Background Color" />
                      </FormField>

                      <FormField mb={4} error={touched.bgBubblesColor && errors.bgBubblesColor}>
                        <label for="bgBubblesColor">Hormones Background Color</label>
                        <TextInput name="bgBubblesColor" placeholder="Hormones Background Color" />
                      </FormField>

                      <FormField mb={4} error={touched.titleColor && errors.titleColor}>
                        <label for="titleColor">Title Color</label>
                        <TextInput name="titleColor" placeholder="Title Color" />
                      </FormField>

                      <FormField mb={4} error={touched.titleColor && errors.titleColor}>
                        <label for="descriptionColor">Description Color</label>
                        <TextInput name="descriptionColor" placeholder="Description Color" />
                      </FormField>

                      {values.courseType.value === 'Standard' && <StandardDetails courseIcons={courseIcons} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} values={values} />}

                      {values.courseType.value === 'FoodJournaling' && (
                        <FoodJournalingDetails courseIcons={courseIcons} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} values={values} />
                      )}

                      {values.courseType.value === 'WebflowArticle' && <WebflowArticleDetails setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} values={values} />}

                      {values.courseType.value === 'WebPage' && <WebPageDetails setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} values={values} />}

                      <FormField mb={4} error={touched.phone && errors.phone}>
                        <label for="isActive">Is Active</label>
                        <CheckboxInput
                          name="isActive"
                          styles={{
                            checked: {
                              Box: {
                                backgroundColor: 'rgb(54, 79, 121)',
                              },
                            },
                          }}
                        />
                      </FormField>

                      <Flex mt={4} flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Button
                          style={{
                            background: 'white',
                            color: '#344f79',
                            border: '1px solid #344f79',
                          }}
                          type="button"
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          style={{ background: '#344f79' }}
                          onClick={() => {
                          }}
                        >
                          Save
                        </Button>
                      </Flex>
                    </Flex>
                  </Form>
                )}
              />
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default AddProgramCourseModalInner;
