import React from 'react';
import { Flex, Text } from 'rebass';
import { GraphPink } from '../icons/GraphPink';
import { InnerRightHormone } from '../icons/InnerRightHormone';
import Odometer from '../components/RollingNumbers';
import styled from 'styled-components';

const CText = styled(Text)`
  font-size: 50px;
  line-height: 90px;

  @media screen and (max-width: 52em) {
    font-size: 32px;
  line-height: 50px;
  }
`

export const CoursesCountBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <InnerRightHormone
        style={{
          position: 'absolute',
          right: 0,
          bottom: 120,
        }}
      />
      <GraphPink width={700} style={{ position: 'absolute', top: 80, left: 0 }} />
    </Flex>
  );
};

export const CoursesCount = ({ coursesCount=0 }) => {
  return (
    <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', zIndex: 100 }}>
      <CText style={{ fontWeight: 700, textAlign: 'center' }}>
        You went through{' '}
        <span>
          <Odometer delay={0} startNumber={0} to={coursesCount} style={{ color: '#F8A294', fontSize: window.matchMedia('(min-width: 52em)').matches?70:42, fontWeight: 'bold', width: '100%' }} />
        </span>{' '}
        courses from the AIP program
      </CText>
    </Flex>
  );
};
