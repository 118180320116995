import React from 'react';
import { Flex, Button, Text } from 'rebass';

import styled from 'styled-components';
import Imge from '../../../static/DoctorFemale.gif';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';

const BlueHeading = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #354e79;
`;

const WhiteBullet = styled(Flex)`
  background: #ffffff;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 20px;
  color: #354e79;
  justify-content: center;
  align-items: center;
`;

const WhatHappensNext = ({ history }) => {
  return (
    <Flex flex={1} flexDirection="column" p="16px" pt="0" pb="60px">
      <svg
        width="933"
        height="885"
        viewBox="0 0 933 885"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: 'fixed',
          top: '-700px',
          left: '50%',
          marginLeft: isMobile?'-130%':'-85%',
        }}
      >
        <path
          d="M401.471 0.0802843C507.485 7.6728 605.329 36.0609 695.248 91.7136C721.819 108.563 749.227 124.071 777.368 138.18C885.047 190.686 926.088 283.673 931.354 394.021C936.926 510.854 909.244 621.352 839.858 717.192C773.855 808.35 681.32 865.511 570.393 882.412C523.863 889.5 471.209 874.737 424.352 860.208C319.868 827.818 225.709 774.143 135.715 711.785C48.5849 651.385 10.4277 567.103 2.07628 467.545C-6.44565 366.064 12.9232 267.589 51.9751 174.317C89.9226 83.6883 164.136 34.3709 259.778 17.0446C306.474 8.57175 354.209 5.55709 401.471 0.0802843Z"
          fill="#ECB7AA"
          fillOpacity="0.2"
        />
      </svg>
      <img src={Imge} alt="" style={{ width: '146px', margin: '0 auto' }} />
      <Flex flex={1} flexDirection="column" mt={2}>
        <BlueHeading
          mb={4}
          textAlign="center"
          fontSize="18px"
          style={{ margin: '0 auto', marginBottom: '36px' }}
        >
          Great! Everything is ready for your provider to review!
        </BlueHeading>
        <BlueHeading mb={3}>
          What Happens Next?
        </BlueHeading>

        <Flex flexDirection="row" alignItems="flex-start" mb={3}>
          <WhiteBullet>1</WhiteBullet>
          <Text style={{ flex: 1 }}>A provider licensed in your state will review your answers.</Text>
        </Flex>

        <Flex flexDirection="row" alignItems="flex-start" mb={3}>
          <WhiteBullet>2</WhiteBullet>
          <Text style={{ flex: 1 }}>
          Your provider will reach out with additional questions or to recommend the best solution for you.
          </Text>
        </Flex>

        <Flex flexDirection="row" alignItems="flex-start">
          <WhiteBullet>3</WhiteBullet>
          <Text style={{ flex: 1 }}>The prescription will be ready for you to pick up at your preferred pharmacy.</Text>
        </Flex>
      </Flex>

      <Button mt={3} variant="primary" onClick={()=>history.push('/')} style={{ borderRadius: '20px' }}>
        Go To Dashboard
      </Button>
    </Flex>
  );
};

export default withRouter(WhatHappensNext)
