import React from 'react';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';
import Select from 'react-select';
import FormField from '../../../../../components/FormField';
import TextInput from '../../../../../components/TextInput';
import ConfirmModal from '../../../../../components/ConfirmModal';
import AddQuizzButtonsModalInner from './AddQuizzButtonsModalInner';
import TextArea from '../../../../../components/TextArea';
import AddOptionButtonsModalInner from './AddOptionButtonsModalInner';

const ModalInner = styled(Box)`
  max-width: 850px;
  width: 100%;
  max-height: 600px;
`;

class OptionsFormScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isManageButtonModalVisible: false,
      currentEditingButtonIndex: null,
      currentEditingButtonData: null,
      isManageOptionModalVisible: false,
      currentEditingOptionIndex: null,
      currentEditingOptionData: null,
    };
  }
  render() {
    const {
      isManageButtonModalVisible,
      currentEditingButtonIndex,
      currentEditingButtonData,
      isManageOptionModalVisible,
      currentEditingOptionIndex,
      currentEditingOptionData,
    } = this.state;
    const {
      touched,
      errors,
      hormoneYValues,
      values,
      setFieldTouched,
      setFieldValue,
    } = this.props;
    return (
      <div style={{ overflowY: 'auto' }}>
        <FormField mb={3} error={touched.phone && errors.phone}>
          <label htmlFor="title">Title</label>
          <TextInput name="title" placeholder="Title" />
        </FormField>

        <FormField mb={3} error={touched.phone && errors.phone}>
          <label htmlFor="content">Content</label>
          <TextArea name="Content" placeholder="Content" />
        </FormField>

        <FormField mb={3} error={touched.phone && errors.phone}>
          <label htmlFor="tags">Tags (separate with ;)</label>
          <TextInput name="tags" placeholder="Tags" />
        </FormField>

        <FormField mb={3} error={touched.phone && errors.phone}>
          <label htmlFor="titleColor">Title Color</label>
          <TextInput name="titleColor" placeholder="Title Color" />
        </FormField>

        <FormField mb={3} error={touched.phone && errors.phone}>
          <label htmlFor="titleFontSize">Text Font Size</label>
          <TextInput
            type="number"
            name="titleFontSize"
            placeholder="Title Font Size"
          />
        </FormField>

        <FormField mb={3} error={touched.phone && errors.phone}>
          <label htmlFor="textColor">Text Color</label>
          <TextInput name="textColor" placeholder="Text Color" />
        </FormField>

        <FormField mb={3} error={touched.phone && errors.phone}>
          <label htmlFor="textFontSize">Text Font Size</label>
          <TextInput
            type="number"
            name="textFontSize"
            placeholder="Text Font Size"
          />
        </FormField>

        {values.options && (
          <Flex flexDirection="column" mt={3}>
            <Flex flexDirection="row">
              <Text flex={1}>Options</Text>
            </Flex>
            {values.options
              .map((x, index) => {
                return (
                  <Flex flexDirection="row" mb={3}>
                    <Box flex={1}>
                      <Button
                        style={{ width: 'calc(100% / 3)' }}
                        variant={
                          !x.variant
                            ? 'primary'
                            : x.variant.value === 'secondary'
                              ? 'pink'
                              : x.variant.value === 'white'
                                ? 'outline'
                                : 'primary'
                        }
                      >
                        {x.title}
                      </Button>
                    </Box>
                    <Box>
                      <div
                        title="delete"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          var r = window.confirm(
                            'Confirm Delete. (All previously logged choice by user will remain)'
                          );
                          if (r == true) {
                            const btns = [];
                            for (
                              let index2 = 0;
                              index2 < values.options.length;
                              index2++
                            ) {
                              const element = values.options[index2];
                              if (index2 !== index) {
                                btns.push(element);
                              }
                            }
                            setFieldValue('options', btns);
                          }
                        }}
                      >
                        🗑
                      </div>
                    </Box>
                    <Box>
                      <div
                        title="delete"
                        style={{ cursor: 'pointer', marginLeft: '12px' }}
                        onClick={() => {
                          var r = window.confirm(
                            'All previously logged choice by user will remain as the current value. Continue?'
                          );
                          if (r == true) {
                            console.log('edit index', index);
                            this.setState({
                              isManageButtonModalVisible: true,
                              currentEditingButtonIndex: index,
                              currentEditingButtonData: x,
                            });
                          }
                        }}
                      >
                        ✎
                      </div>
                    </Box>
                  </Flex>
                );
              })}
          </Flex>
        )}
        <Button
          type="button"
          onClick={() => {
            this.setState({
              isManageOptionModalVisible: true,
            });
          }}
          style={{ margin: '0 auto' }}
          variant="primary"
          mt={4}
        >
          Manage Options
        </Button>

        {values.buttons && (
          <Flex flexDirection="column" mt={3}>
            <Flex flexDirection="row">
              <Text flex={1}>Button</Text>
              <Text flex={1}>On click go to</Text>
            </Flex>
            {values.buttons
              .filter(x => {
                return !x.isOption;
              })
              .map((x, index) => {
                return (
                  <Flex flexDirection="row" mb={3}>
                    <Box flex={1}>
                      <Button
                        style={{ width: 'calc(100% / 3)' }}
                        variant={
                          !x.variant
                            ? 'primary'
                            : x.variant.value === 'secondary'
                              ? 'pink'
                              : x.variant.value === 'white'
                                ? 'outline'
                                : 'primary'
                        }
                      >
                        {x.title}
                      </Button>
                    </Box>
                    <Box flex={1}>{x.goTo || 'next screen'}</Box>
                    <Box>
                      <div
                        title="delete"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          var r = window.confirm(
                            'Confirm Delete. (All previously logged choice by user will remain)'
                          );
                          if (r == true) {
                            const btns = [];
                            for (
                              let index2 = 0;
                              index2 < values.buttons.length;
                              index2++
                            ) {
                              const element = values.buttons[index2];
                              if (index2 !== index) {
                                btns.push(element);
                              }
                            }
                            setFieldValue('buttons', btns);
                          }
                        }}
                      >
                        🗑
                      </div>
                    </Box>
                    <Box>
                      <div
                        title="edit"
                        style={{ cursor: 'pointer', marginLeft: '12px' }}
                        onClick={() => {
                          var r = window.confirm(
                            'All previously logged choice by user will remain as the current value. Continue?'
                          );
                          if (r == true) {
                            console.log('edit index', index);
                            this.setState({
                              isManageButtonModalVisible: true,
                              currentEditingButtonIndex: index,
                              currentEditingButtonData: x,
                            });
                          }
                        }}
                      >
                        ✎
                      </div>
                    </Box>
                  </Flex>
                );
              })}
          </Flex>
        )}
        <Button
          type="button"
          onClick={() => {
            this.setState({
              isManageButtonModalVisible: true,
            });
          }}
          style={{ margin: '0 auto' }}
          variant="primary"
          mt={4}
        >
          Manage Button
        </Button>

        <FormField mb={4} error={touched.phone && errors.phone}>
          <label htmlFor="hormoneY">Hormone Y</label>
          <Select
            classNamePrefix="region-select"
            options={hormoneYValues}
            name="hormoneY"
            value={values.hormoneY}
            placeholder="select hormone Y"
            onChange={option => {
              setFieldValue('hormoneY', option);
            }}
            onBlur={() => setFieldTouched('hormoneY', true)}
            isSearchable={true}
          />
        </FormField>

        <ConfirmModal
          childrenManaged
          isOpen={isManageButtonModalVisible}
          confirmLabel="create"
          onClose={() => {
            this.setState({
              isManageButtonModalVisible: false,
              currentEditingButtonIndex: null,
              currentEditingButtonData: null,
            });
          }}
          onBackgroundClick={() => {
            this.setState({
              isManageButtonModalVisible: false,
              currentEditingButtonIndex: null,
              currentEditingButtonData: null,
            });
          }}
          onEscapeKeydown={() => {
            this.setState({
              isManageButtonModalVisible: false,
              currentEditingButtonIndex: null,
              currentEditingButtonData: null,
            });
          }}
          onConfirm={() => {
            this.setState({
              isManageButtonModalVisible: false,
              currentEditingButtonIndex: null,
              currentEditingButtonData: null,
            });
          }}
        >
          <ModalInner>
            <Heading fontSize="20px">Buttons</Heading>
            <AddQuizzButtonsModalInner
              currentEditingButtonData={currentEditingButtonData}
              onClose={() => {
                this.setState({
                  isManageButtonModalVisible: false,
                  currentEditingButtonIndex: null,
                  currentEditingButtonData: null,
                });
              }}
              onSave={vals => {
                const btns = values.buttons
                  ? values.buttons.filter(x => {
                      return !x.isOption;
                    })
                  : [];

                console.log('onsave', {
                  currentEditingButtonData,
                  currentEditingButtonIndex,
                });

                if (currentEditingButtonIndex !== null) {
                  btns[currentEditingButtonIndex] = {
                    title: vals.title,
                    variant: vals.variant,
                    goTo: vals.goTo,
                  };
                  // console.log('btns', btns);
                } else {
                  btns.push({
                    title: vals.title,
                    variant: vals.variant,
                    goTo: vals.goTo,
                  });
                }
                setFieldValue('buttons', btns);
                this.setState({
                  isManageButtonModalVisible: false,
                  currentEditingButtonIndex: null,
                  currentEditingButtonData: null,
                });
              }}
            />
          </ModalInner>
        </ConfirmModal>

        <ConfirmModal
          childrenManaged
          isOpen={isManageOptionModalVisible}
          confirmLabel="create"
          onClose={() => {
            this.setState({
              isManageOptionModalVisible: false,
              currentEditingOptionIndex: null,
              currentEditingOptionData: null,
            });
          }}
          onBackgroundClick={() => {
            this.setState({
              isManageOptionModalVisible: false,
              currentEditingOptionIndex: null,
              currentEditingOptionData: null,
            });
          }}
          onEscapeKeydown={() => {
            this.setState({
              isManageOptionModalVisible: false,
              currentEditingOptionIndex: null,
              currentEditingOptionData: null,
            });
          }}
          onConfirm={() => {
            this.setState({
              isManageOptionModalVisible: false,
              currentEditingOptionIndex: null,
              currentEditingOptionData: null,
            });
          }}
        >
          <ModalInner>
            <Heading fontSize="20px">Option</Heading>
            <AddOptionButtonsModalInner
              currentEditingButtonData={currentEditingOptionIndex}
              onClose={() => {
                this.setState({
                  isManageOptionModalVisible: false,
                  currentEditingOptionIndex: null,
                  currentEditingOptionData: null,
                });
              }}
              onSave={vals => {
                const btns = values.options || [];

                if (currentEditingButtonIndex !== null) {
                  btns[currentEditingButtonIndex] = {
                    title: vals.title,
                    variant: vals.variant,
                    goTo: vals.goTo,
                    isOption: true,
                  };
                  // console.log('btns', btns);
                } else {
                  btns.push({
                    title: vals.title,
                    variant: vals.variant,
                    goTo: vals.goTo,
                    isOption: true,
                  });
                }
                setFieldValue('options', btns);
                this.setState({
                  isManageOptionModalVisible: false,
                  currentEditingOptionIndex: null,
                  currentEditingOptionData: null,
                });
              }}
            />
          </ModalInner>
        </ConfirmModal>
      </div>
    );
  }
}

export default OptionsFormScreen;
