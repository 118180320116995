import React, { useState } from 'react';
import { Flex, Text } from 'rebass';
import StarRatings from '../../../../components/ratingStar/star-rating';

export const RatingCtrl = ({ properties }) => {
  const [valu, setValu] = useState(2.5);
  return (
    <>
      <Flex flexDirection={'column'} flex={1 / 4}>
        <Text onClick={() => { }} fontWeight={500}>
          {`${(properties || {}).question || 'Question'}${((properties || {}).ctrlProps || {}).required ? '*' : ''}`}
        </Text>
        <Text fontSize={'12px'} fontWeight={300}>
          {(properties || {}).description || 'Description'}
        </Text>
      </Flex>
      <Flex flex={1}>
        <StarRatings starSpacing="0px" starRatedColor="#f9a394" rating={valu} numberOfStars={5} changeRating={(e) => setValu(e)} starDimension="18px" />
      </Flex>
    </>
  );
};
