import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { Box, Button, Heading } from 'rebass';
import styled from 'styled-components';
import ConfirmModal from '../components/ConfirmModal';
import PleaseWait from '../components/PleaseWait';
import { ADMIN_GET_BROKERS_LIST } from '../graphql';
import BrokersList from './adminB2B/BrokersList';
import Companies from './adminB2B/Companies';
import ModalSearchUser from './adminB2B/ModalSearchUser';
import AthenaCustomFields from './adminB2B/AthenaCustomFields';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;
const ModalInner = styled(Box)`
  max-width: 80vw;
  min-width: 80vw;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;

const AdminB2BManagement = () => {
  const [findUser, setFindUser] = useState(false);
  return (
    <Container>
      <Heading flex={1}>B2B MANAGEMENT</Heading>
      <AthenaCustomFields />
      <Query query={ADMIN_GET_BROKERS_LIST}>
        {({ data, loading, error }) => {
          if (!!loading) return <PleaseWait />;
          return (
            <>
              <BrokersList data={data} />
              <Companies data={data} />
              <Button variant={'primary'} mt={4} onClick={() => setFindUser(true)}>
                Find User
              </Button>

              <ConfirmModal
                childrenManaged
                isOpen={!!findUser}
                confirmLabel="create"
                onClose={() => {
                  setFindUser(false);
                }}
                onBackgroundClick={() => {
                  setFindUser(false);
                }}
                onEscapeKeydown={() => {
                  setFindUser(false);
                }}
                onConfirm={() => {
                  setFindUser(false);
                }}
              >
                <ModalInner>
                  <ModalSearchUser />
                </ModalInner>
              </ConfirmModal>
            </>
          );
        }}
      </Query>
    </Container>
  );
};

export default AdminB2BManagement;
