import React, { useEffect, useState } from 'react';
import { Query, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import moment from 'moment';
import withSession from '../../lib/withSession';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';
import { isMobile } from 'react-device-detect';
import withOnFileInsurance from '../../lib/withOnFileInsurance';
import { APPOINTMENTS, GET_CLINICAL_PROVIDERS_DOCTOR } from '../../graphql';
import SpinLoader from '../../components/SpinLoader';
import PleaseWait from '../../components/PleaseWait';
import { ButtonWithSelectionCircle, ButtonWithSelectionCircleSelectedMarkerIn, ButtonWithSelectionCircleSelectedMarkerOut } from '../../components/intake/components/ButtonWithSelectionCircle';

const Container = styled(Box)`
  max-width: 600px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 1;
  overflow-y: ${(props) => (isMobile ? 'hidden' : '')};
`;

const InnerContainerInformation = styled(Flex)`
  max-width: ${(props) => (isMobile ? 'calc(100% - 12px)' : '850px')};
  height: auto;
  margin: 0 auto;
  max-height: 100%;
  flex-direction: column;
  overflow-y: ${(props) => (isMobile ? 'auto' : '')};
`;

const InnerWithMenopause = ({ ...props }) => {
  const { canBookNoHypo, canBookVisit } = props;

  return (
    <Query query={APPOINTMENTS}>
      {({ data: dataUpcomings, loading: loadingUpcoming }) => {
        return (
          <Query
            query={GET_CLINICAL_PROVIDERS_DOCTOR}
            variables={{
              departmentId: props.session.department.departmentId,
            }}
          >
            {({ data: dataProviders, loading: loadingProviders }) => {
              if (!!loadingProviders || !!loadingUpcoming)
                return (
                  <Container alignItems={'center'} justifyContent={'center'} id="pplpl">
                    <PleaseWait />
                  </Container>
                );

              if (!props.isDeepLoaded) {
                props.onLoaded();
              }
              
              const upcomings = ((dataUpcomings || {}).appointments || [])
                .filter((item) => {
                  if (
                    item.status &&
                    item.status === 'f' &&
                    item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                    item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID &&
                    item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID &&
                    item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID &&
                    item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_NEW_NUTRITIONIST_30
                  ) {
                    return true;
                  }
                  return false;
                })
                .sort((a, b) => {
                  const ma = moment(`${a.date} ${a.startTime}`, 'MM/DD/YYYY HH:mm');
                  const mb = moment(`${b.date} ${b.startTime}`, 'MM/DD/YYYY HH:mm');
                  if (ma.isBefore(mb)) return -1;
                  return 1;
                });

              const upcomingsMeno = ((dataUpcomings || {}).appointments || [])
                .filter((item) => {
                  if (
                    item.status &&
                    item.status === 'f' &&
                    (item.appointmentTypeId === window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID || item.appointmentTypeId === window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID)
                  ) {
                    return true;
                  }
                  return false;
                })
                .sort((a, b) => {
                  const ma = moment(`${a.date} ${a.startTime}`, 'MM/DD/YYYY HH:mm');
                  const mb = moment(`${b.date} ${b.startTime}`, 'MM/DD/YYYY HH:mm');
                  if (ma.isBefore(mb)) return -1;
                  return 1;
                });

              if (!canBookNoHypo && !canBookVisit && (!props.session.eligibility || (!props.session.eligibility.canBookPerimenopause && !props.session.eligibility.canBookPostmenopause))) {
                props.history.push('/');
                return null;
              }
              if (!canBookNoHypo && !canBookVisit) {
                if (!dataProviders || !dataProviders.getClinicalProvidersDoctorsList || dataProviders.getClinicalProvidersDoctorsList.length < 1) {
                  props.history.push('/');
                  return null;
                }
                if (props.session.eligibility && props.session.eligibility.canBookPerimenopause) {
                  if (dataProviders.getClinicalProvidersDoctorsList.filter((x) => !!x.periMenopause).length < 1) {
                    props.history.push('/');
                    return null;
                  }
                } else if (props.session.eligibility && props.session.eligibility.canBookPostmenopause) {
                  if (dataProviders.getClinicalProvidersDoctorsList.filter((x) => !!x.postMenopause).length < 1) {
                    props.history.push('/');
                    return null;
                  }
                }
              } else {
                if (!dataProviders || !dataProviders.getClinicalProvidersDoctorsList || dataProviders.getClinicalProvidersDoctorsList.length < 1) {
                  props.history.push('/scheduler');
                  return null;
                }
                if (props.session.eligibility && props.session.eligibility.canBookPerimenopause) {
                  if (dataProviders.getClinicalProvidersDoctorsList.filter((x) => !!x.periMenopause).length < 1) {
                    props.history.push('/scheduler');
                    return null;
                  }
                } else if (props.session.eligibility && props.session.eligibility.canBookPostmenopause) {
                  if (dataProviders.getClinicalProvidersDoctorsList.filter((x) => !!x.postMenopause).length < 1) {
                    props.history.push('/scheduler');
                    return null;
                  }
                }
              }

              if (dataProviders && dataProviders.getClinicalProvidersDoctorsList && dataProviders.getClinicalProvidersDoctorsList.length > 0) {
                return (
                  <>
                    {props.session.eligibility && props.session.eligibility.canBookPerimenopause ? (
                      <>
                        {dataProviders.getClinicalProvidersDoctorsList.filter((x) => !!x.periMenopause).length > 0 && (
                          <ButtonWithSelectionCircle type="button" variant={'white'} mb={4} onClick={() => props.history.push('/scheduler-meno')} disabled={!!upcomingsMeno && upcomingsMeno.length > 0}>
                            <Flex
                              flex={1}
                              flexDirection="column"
                            >
                              <Text textAlign="left" mt="4px">
                                Book peri-menopause visit
                              </Text>
                              {!!upcomingsMeno &&
                                upcomingsMeno.length > 0 && (
                                  <Text textAlign="left" mt="4px">
                                  Visit scheduled for {upcomingsMeno[0].date} {moment(upcomingsMeno[0].startTime, 'HH:mm').format('hh:mm A')}
                                  </Text>
                                )}
                            </Flex>
                          </ButtonWithSelectionCircle>
                        )}
                      </>
                    ) : props.session.eligibility && props.session.eligibility.canBookPostmenopause ? (
                      <>
                        {dataProviders.getClinicalProvidersDoctorsList.filter((x) => !!x.postMenopause).length > 0 && (
                          <ButtonWithSelectionCircle type="button" variant={'white'} mb={4} onClick={() => props.history.push('/scheduler-meno')} disabled={!!upcomingsMeno && upcomingsMeno.length > 0}>
                            <Flex
                              flex={1}
                              flexDirection="row"
                            >
                              <Text textAlign="left" mt="4px">
                                Book menopause visit
                              </Text>
                              {!!upcomingsMeno &&
                                upcomingsMeno.length > 0 && (
                                  <Text textAlign="left" mt="4px">
                                    Visit scheduled for {upcomingsMeno[0].date} {moment(upcomingsMeno[0].startTime, 'HH:mm').format('hh:mm A')}
                                  </Text>
                                )}
                            </Flex>
                          </ButtonWithSelectionCircle>
                        )}
                      </>
                    ) : null}
                    {canBookVisit && (
                      <ButtonWithSelectionCircle type="button" variant={'white'} onClick={() => props.history.push('/scheduler')} disabled={!!upcomings && upcomings.length > 0}>
                        <Flex
                          flex={1}
                          flexDirection="row"
                        >
                          <Text textAlign="left" mt="4px">
                            Book thyroid visit
                          </Text>
                        </Flex>
                      </ButtonWithSelectionCircle>
                    )}
                    {canBookNoHypo && (
                      <ButtonWithSelectionCircle type="button" variant={'white'} onClick={() => props.history.push('/scheduling')} disabled={!!upcomings && upcomings.length > 0}>
                        <Flex
                          flex={1}
                          flexDirection="row"
                        >
                          <Text textAlign="left" mt="4px">
                          Book thyroid visit
                          </Text>
                        </Flex>
                      </ButtonWithSelectionCircle>
                    )}
                  </>
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
      }}
    </Query>
  );
};
const ScheduleSplitInner = (props) => {
  const [isDeepLoaded, setIsDeepLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [canBookNoHypo, setCanBookNoHypo] = useState(false);
  const [canBookVisit, setCanBookVisit] = useState(false);
  useEffect(
    () => {
      if (!props.session) {
        props.history.push('/');
        return;
      }
      const { history, dataUpcomings } = props;
      const { eligibility, intakeStatus, isMember, diagnoseFull } = props.session;
      const upcomings = ((dataUpcomings || {}).appointments || [])
        .filter((item) => {
          if (
            item.status &&
            item.status === 'f' &&
            item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
            item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID &&
            item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID &&
            item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID
          ) {
            return true;
          }
          return false;
        })
        .sort((a, b) => {
          const ma = moment(`${a.date} ${a.startTime}`, 'MM/DD/YYYY HH:mm');
          const mb = moment(`${b.date} ${b.startTime}`, 'MM/DD/YYYY HH:mm');
          if (ma.isBefore(mb)) return -1;
          return 1;
        });

      let canBookVisit = intakeStatus !== 'disqualified' && (!upcomings || upcomings.length < 1) && eligibility.consultation;
      let canHypo = false;
      let canHypoNo = false;
      if (!canBookVisit) {
        if (!diagnoseFull || !diagnoseFull.hasHypo) {
          canHypoNo = true;
          setCanBookNoHypo(true);
          setCanBookVisit(false);
        }
      } else {
        if (!diagnoseFull || !diagnoseFull.hasHypo) {
          canHypoNo = true;
          setCanBookNoHypo(true);
          setCanBookVisit(false);
        } else {
          canHypo = true;
          setCanBookNoHypo(false);
          setCanBookVisit(true);
        }
      }

      // console.log({
      //   upcomings,
      //   canHypo,
      //   canHypoNo,
      //   canmenopause: props.session.eligibility.canBookPerimenopause, // || props.session.eligibility.canBookPostmenopause,
      //   canBookVisit,
      //   intakeStatus,
      // });
      if (props.session.gender === 'M') {
        if (canHypo) {
          history.push('/scheduler');
          return;
        } else if (canHypoNo) {
          history.push('/scheduling');
          return;
        } else {
          history.push('/');
          return;
        }
      }

      if (!props.session.eligibility || (!props.session.eligibility.canBookPerimenopause && !props.session.eligibility.canBookPostmenopause)) {
        if (canHypo) {
          // history.push('/scheduler');
          // return;
        } else if (canHypoNo) {
          // history.push('/scheduling');
          // return;
        } else {
          history.push('/');
          return;
        }
      }
      setIsLoading(false);
    },
    [props]
  );
  if (isLoading) {
    return <PleaseWait />;
  }
  // if (props.session && props.session.eligibility && (props.session.eligibility.canBookPerimenopause || props.session.eligibility.canBookPostmenopause)) {
  return (
    <>
      <Container>
        <InnerContainerInformation p={3} mt={2} height={'auto'} style={{ height: 'auto !important' }} alignItems={'center'}>
          <Flex p={4} mb={4} style={{ background: 'transparent', backgroundColor: 'transparent', border: 0 }}>
            <Flex mb={4} flexDirection="column">
              <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                <Flex flexDirection={'column'}>
                  {!!isDeepLoaded && (
                    <Heading mt={4} mb={4} textAlign={'center'}>
                      What appointment do you want to book today?
                    </Heading>
                  )}

                  <InnerWithMenopause
                    {...props}
                    canBookNoHypo={canBookNoHypo}
                    canBookVisit={canBookVisit}
                    onLoaded={() => {
                      setIsDeepLoaded(true);
                    }}
                    isDeepLoaded={isDeepLoaded}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </InnerContainerInformation>
      </Container>
    </>
  );
  // }

  return null;
};
const ScheduleSplit = (props) => {
  return (
    <Query query={APPOINTMENTS}>
      {({ data: dataUpcomings, loading: loadingUpcoming }) => {
        if (loadingUpcoming) {
          return (
            <Container alignItems={'center'} justifyContent={'center'}>
              <PleaseWait />
            </Container>
          );
        }

        return <ScheduleSplitInner {...props} dataUpcomings={dataUpcomings} />;
      }}
    </Query>
  );
};

export default withApollo(withRouter(withSession(withOnFileInsurance(withToastManager(ScheduleSplit)))));
