import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { Field } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';


export const StyledInput = styled.textarea`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1 0 auto;
  background: ${props =>
    props.disabled ? props.theme.bg.wash : props.theme.bg.default};
  font-weight: 400;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  border: 1px solid
    ${props =>
    props.disabled ? props.theme.bg.border : props.theme.bg.inactive};
  border-radius: 10px;
  padding: 18px 12px;
  box-shadow: none;
  &::-webkit-input-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:-moz-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:-ms-input-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:focus {
    border-color: ${props => props.theme.brand.default};
    outline: none;
  }
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const Input = props => {
  return (
    <Flex flex={1} style={props.containerStyle}>
      <StyledInput {...props.field} {...props} />
    </Flex>
  );
};

export const TextArea = props => {
  return <Field {...props} component={Input} />;
};

export const AutoExtendTextArea = props => {
  return <TextareaAutosize {...props} />
  // return <Field {...props} component={TextareaAutosize} />;
};

export const TextAreaNoForm = props => {
  return <Input {...props} />;
};

export default TextArea;
