import React from 'react';
import { Image, Flex, Button, Box } from 'rebass';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import { Intro } from './sections/Intro';
import { Pricings } from './sections/Pricings';
import { ThinkCare } from './sections/ThinkCare';
import SoManyPerks from '../../static/SoManyPerks.png';
import SelectPlanPage from '../selectPlanPage';
import { WhatsCovered } from './sections/components/WhatsCovered';
import { isToggleActive } from '../../components/featureToggle/toggles';

const Container = styled(Box)`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 0;
  @media screen and (max-width: 480px) {
    max-width: 100vw;
    padding-left: 6px;
    padding-right: 6px;
  }
`;

const Wrapper = styled(Flex)`
  flex: 1;
`;

const BecomeMember = ({ history }) => {
  const subscribe = () => {
    history.push('/subscribe-membership');
  };

  return (
    <>
      <Container>
        <Intro subscribe={subscribe} />
        <Pricings subscribe={subscribe} />
        <WhatsCovered />
        <ThinkCare subscribe={subscribe} />
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* <Heading style={{ fontStyle: 'italic' }} mt={5} mb={4}>
            + so many more exclusive perks
          </Heading> */}

          <Image alt="" mt={5} mb={4} src={SoManyPerks} maxWidth="80%" />
          <Button variant="primary" onClick={subscribe}>
            Join Today
          </Button>
        </Flex>
      </Container>
    </>
  );
};

export const JoinMember = ({ history }) => {
  const subscribe = () => {
    history.push('/join-membership');
  };
  const skip = () => {
    history.push('/');
  };

  return (
    <Wrapper>
      <>
        <Container>
          <Intro subscribe={subscribe} skip={skip} />
        </Container>
      </>
    </Wrapper>
  );
};

export const JoinMemberSubscribe = ({ history }) => {
  return (
    <Wrapper>
      <SelectPlanPage isJoinFlow={true} location={(history || {}).location} />
    </Wrapper>
  );
};

export default withRouter(BecomeMember);
