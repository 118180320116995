import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import balanced from '../../static/allergens/balanced.png'
import coffefree from '../../static/allergens/coffefree.png'
import nightshade from '../../static/allergens/nightshade.png'
import high_fiber from '../../static/allergens/high_fiber.png'
import high_protein from '../../static/allergens/high_protein.png'
import low_carb from '../../static/allergens/low_carb.png'
import low_fat from '../../static/allergens/low_fat.png'
import low_sodium from '../../static/allergens/low_sodium.png'
import alcohol from '../../static/allergens/alcohol.png'
import immune from '../../static/allergens/immune.png'
import celery from '../../static/allergens/celery.png'
import crustacena from '../../static/allergens/crustacena.png'
import dairy_free from '../../static/allergens/dairy_free.png'
import eggs from '../../static/allergens/eggs.png'
import fish_free from '../../static/allergens/fish_free.png'
import fodmap_free  from '../../static/allergens/fodmap_free.png'
import gluten  from '../../static/allergens/gluten.png'
import keto  from '../../static/allergens/keto.png'
import kidney_friendly  from '../../static/allergens/kidney_friendly.png'
import kosher  from '../../static/allergens/kosher.png'
import potassium  from '../../static/allergens/potassium.png'
import lupin  from '../../static/allergens/lupin.png'
import mustard  from '../../static/allergens/mustard.png'
import oil  from '../../static/allergens/oil.png'
import low_sugar  from '../../static/allergens/low_sugar.png'
import paleo  from '../../static/allergens/paleo.png'
import peanut  from '../../static/allergens/peanut.png'
import pork  from '../../static/allergens/pork.png'
import pescatarian  from '../../static/allergens/pescatarian.png'
import red_meat  from '../../static/allergens/red_meat.png'
import sesame  from '../../static/allergens/sesame.png'
import Shellfish  from '../../static/allergens/Shellfish.png'
import soya  from '../../static/allergens/soya.png'
import sugar_conscious  from '../../static/allergens/sugar_conscious.png'
import treenut  from '../../static/allergens/treenut.png'
import vegan  from '../../static/allergens/vegan.png'
import vegetarian  from '../../static/allergens/vegetarian.png'
import wheat  from '../../static/allergens/wheat.png'





export const conversionTable = [
  {
    allergen: 'Balanced',
    image: balanced,
  },
  {
    allergen: 'Coffee-Free',
    image: coffefree
  },
  {
    allergen: 'Nightshade',
    image:nightshade
  },
  {
    allergen: 'High-Fiber',
    image: high_fiber
  },
  {
    allergen: 'High-Protein',
    image: high_protein
  },
  {
    allergen: 'Low-Carb',
    image: low_carb
  },
  {
    allergen: 'Low-Fat',
    image: low_fat
  },
  {
    allergen: 'Low-Sodium',
    image: low_sodium,
  },
  {
    allergen: 'Alcohol-Free',
    image: alcohol
  },
  {
    allergen: 'Immune-Supportive',
    image: immune,
  },
  {
    allergen: 'Celery-Free',
    image: celery,
  },
  {
    allergen: 'Crustcean-Free',
    image: crustacena,
  },
  {
    allergen: 'Dairy-Free',
    image: dairy_free
  },
  {
    allergen: 'Egg-Free',
    image: eggs,
  },
  {
    allergen: 'Fish-Free',
    image: fish_free,
  },
  {
    allergen: 'FODMAP-Free',
    image: fodmap_free,
  },
  {
    allergen: 'Fodmap-Free',
    image: fodmap_free,
  },
  {
    allergen: 'Gluten-Free',
    image: gluten,
  },
  {
    allergen: 'Keto',
    image: keto,
  },
  {
    allergen: 'Keto-Friendly',
    image: keto,
  },
  {
    allergen: 'Kidney-Friendly',
    image: kidney_friendly,
  },
  {
    allergen: 'Kosher',
    image: kosher,
  },
  {
    allergen: 'Low-Potassium',
    image: potassium,
  },
  {
    allergen: 'Lupine-Free',
    image: lupin,
  },
  {
    allergen: 'Mustard-Free',
    image: mustard,
  },
  {
    allergen: 'No oil added',
    image: oil,
  },
  {
    allergen: 'No-Sugar',
    image: low_sugar,
  },
  {
    allergen: 'Low-Sugar',
    image: low_sugar,
  },
  {
    allergen: 'Low Sugar',
    image: low_sugar,
  },
  {
    allergen: 'Paleo',
    image: paleo,
  },
  {
    allergen: 'Peanut-Free',
    image: peanut,
  },
  {
    allergen: 'Pork-Free',
    image: pork,
  },
  {
    allergen: 'Pescatarian',
    image: pescatarian
  },
  {
    allergen: 'Red-Meat-Free',
    image: red_meat
  },
  {
    allergen: 'Sesame-Free',
    image: sesame
  },
  {
    allergen: 'Shellfish-Free',
    image: Shellfish
  },
  {
    allergen: 'Soy-Free',
    image: soya
  },
  {
    allergen: 'Sugar-Conscious',
    image: sugar_conscious
  },
  {
    allergen: 'Tree-Nut-Free',
    image: treenut
  },
  {
    allergen: 'Vegan',
    image: vegan
  },
  {
    allergen: 'Vegetarian',
    image: vegetarian
  },
  {
    allergen: 'Wheat-Free',
    image: wheat
  },
  {
    allergen: 'Mollusk-Free',
    image: Shellfish
  },
  {
    allergen: 'Crustacean-Free',
    image: Shellfish
  },
];
const Wrap = styled(Flex)`
  width: 86px;
  min-width: 86px;
  max-width: 86px;
  height: 156px;
  background: #fce3d9;
  border-radius: 84px;
  flex-direction: column;
  margin-right: 12px;
  align-items: center;
  padding: 7px;
`;

const IconContainer = styled(Flex)`
  width: 73px;
  height: 73px;
  border-radius: 73px;
  background-color: #fffefe;
  justify-content: center;
  align-items: center;
`;

const ChildrenContainer = styled(Flex)`
  flex: 1;
  align-items: center;
`;

const RecipeIndicatorBase = ({indicatorValue, children}) => {
  return (
    <Wrap>
      <IconContainer>
        {indicatorValue}
      </IconContainer>
      <ChildrenContainer>
        {children}
      </ChildrenContainer>
    </Wrap>
  );
};

export default RecipeIndicatorBase;
