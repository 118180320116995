import React from 'react';
import { Box, Heading, Text, Flex, Button } from 'rebass';
import styled from 'styled-components';
import { Mutation, Query, withApollo } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { debounce } from 'throttle-debounce';

import {
  ADMIN_GET_REFERRAL_LIST,
  ADMIN_GET_REFERRAL_HISTORY,
  ADMIN_SEND_REF_HISTO,
} from '../graphql';
import ConfirmModal from '../components/ConfirmModal';
import AdminReferralAdjustmentModalInner from './referral/adminReferralAdjustmentModalInner';
import { Input } from '../components/TextInput';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;

const getColumnWidth = (rows, accessor, headerText, dataType = '') => {
  const maxWidth = 400;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...rows.map(
      row =>
        (
          `${
            dataType === 'money'
              ? `$ ${row[accessor]}`
              : dataType === 'date'
                ? moment(row[accessor]).format('MM-DD-YYYY hh:mm a')
                : row[accessor]
          }` || ''
        ).length
    ),
    headerText.length + 4
  );
  return Math.min(maxWidth, cellLength * magicSpacing);
};

const DetailsRowHistory = props => {
  const { original } = props;
  return (
    <Query
      query={ADMIN_GET_REFERRAL_HISTORY}
      variables={{ referralId: original.id }}
    >
      {({ data, loading, error }) => {
        if (loading) return <div />;

        const columns = [
          {
            Header: '',
            accessor: 'flag',
            width: 40,
            Cell: row => {
              if (!row.value || row.value === 'OK' || row.value === 'COMPLETE')
                return ' ';
              return (
                <span
                  data-tip={
                    (row.value || '') === 'Same IP' ? 'Same IP address' : null
                  }
                  data-type={(row.value || '') === 'Same IP' ? 'warning' : null}
                >
                  {' '}
                  <FontAwesomeIcon
                    size="1x"
                    color={(row.value || '') === 'Same IP' ? 'red' : '#0cac0c'}
                    icon={faFlag}
                  />
                </span>
              );
            },
          },
          {
            Header: 'date',
            accessor: 'date',
            Cell: row => (
              <Text textAlign="left">
                {moment(row.value).format('MM-DD-YYYY hh:mm a')}
              </Text>
            ),
            width: getColumnWidth(
              data.adminGetReferralHistory,
              'date',
              'date',
              'date'
            ),
          },
          {
            Header: 'description',
            accessor: 'description',
          },
          {
            Header: 'amount',
            accessor: 'centisizedAmount',
            Cell: row => (
              <Text
                textAlign="right"
                style={{ color: row.value < 0 ? 'rgb(237, 183, 168)' : '' }}
              >{`$ ${((row.value || 0) / 100).toFixed(2)}`}</Text>
            ),
            width: getColumnWidth(
              data.adminGetReferralHistory,
              'centisizedAmount',
              'amount',
              'money'
            ),
          },
        ];
        return (
          <ReactTable
            data={data.adminGetReferralHistory}
            columns={columns}
            defaultPageSize={
              data.adminGetReferralHistory &&
              data.adminGetReferralHistory.length > 3
                ? Math.min(data.adminGetReferralHistory.length, 5)
                : 3
            }
            showPagination={
              data.adminGetReferralHistory &&
              data.adminGetReferralHistory.length > 5
            }
          />
        );
      }}
    </Query>
  );
};

class DetailsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdjusting: false,
      adjustingLink: null,
    };
  }
  adjustBalance = referralId => {
    this.setState({
      isAdjusting: true,
      adjustingLink: referralId,
    });
  };

  render() {
    const { isAdjusting, adjustingLink } = this.state;
    const {
      row: { original },
    } = this.props;

    return (
      <div>
        <Flex flexDirection="column" alignItems="center">
          <Text fontSize="20px" fontWeight="bold" mb={3} mt={4}>
            {original.name}
          </Text>
          <Flex flexDirection={['column', 'row']} justifyContent="center">
            <Box style={{ border: '1px solid #ddd' }} mr={[0, 2]}>
              <Flex flexDirection="column">
                <div
                  style={{
                    background: '#344f79',
                    color: 'white',
                    padding: '0 6px',
                  }}
                >
                  Collected Credits
                </div>
                <Text textAlign="center" fontWeight="bold" pt={2} pb={2}>{`$ ${(
                  (original.centisizedCollectedCredits || 0) / 100
                ).toFixed(2)}`}</Text>
              </Flex>
            </Box>
            <Box style={{ border: '1px solid #ddd' }} mr={[0, 2]}>
              <Flex flexDirection="column">
                <div
                  style={{
                    background: '#344f79',
                    color: 'white',
                    padding: '0 6px',
                  }}
                >
                  Used Credits
                </div>
                <Text textAlign="center" fontWeight="bold" pt={2} pb={2}>{`$ ${(
                  (original.centisizedUsedCredits || 0) / 100
                ).toFixed(2)}`}</Text>
              </Flex>
            </Box>
            <Box style={{ border: '1px solid #ddd' }}>
              <Flex flexDirection="column">
                <div
                  style={{
                    background: '#344f79',
                    color: 'white',
                    padding: '0 6px',
                  }}
                >
                  Remaining Balance
                </div>
                <Text textAlign="center" fontWeight="bold" pt={2} pb={2}>{`$ ${(
                  (original.centisizedBalance || 0) / 100
                ).toFixed(2)}`}</Text>
              </Flex>
            </Box>
          </Flex>
          <Box
            style={{
              border: '1px solid #ddd',
              width: '100%',
              maxWidth: '452px',
            }}
            mt={4}
          >
            <Flex flexDirection="column">
              <div
                style={{
                  textAlign: 'center',
                  background: 'rgb(237, 183, 168)',
                  color: 'white',
                  padding: '0 6px',
                }}
              >
                Referred
              </div>
              <Text textAlign="center" fontWeight="bold" pt={2} pb={2}>
                {original.referreesCount}
              </Text>
            </Flex>
          </Box>
          <Box width="100%" p={4}>
            <Text mb={2}>History</Text>
            <DetailsRowHistory original={original} />
          </Box>
          <Flex justifyContent="center">
            <Button
              variant="outline"
              mb={4}
              onClick={() => this.adjustBalance(original)}
            >
              Adjust Balance
            </Button>
          </Flex>
        </Flex>
        {isAdjusting &&
          !!adjustingLink && (
            <ConfirmModal
              childrenManaged
              confirmLabel="OK"
              isOpen={isAdjusting}
              okOnly
              displayX
              cardStyle={{
                maxHeight: '100vh',
                position: 'relative',
                overflowY: 'auto',
              }}
              onClose={() => {
                this.setState({ adjustingLink: null, isAdjusting: false });
              }}
              onConfirm={() => {}}
              onBackgroundClick={() => {
                this.setState({ adjustingLink: null, isAdjusting: false });
              }}
              onEscapeKeydown={() => {
                this.setState({ adjustingLink: null, isAdjusting: false });
              }}
            >
              <AdminReferralAdjustmentModalInner
                referralLink={adjustingLink}
                onClose={() => {
                  this.setState({ adjustingLink: null, isAdjusting: false });
                }}
              />
            </ConfirmModal>
          )}
      </div>
    );
  }
}

class AdminReferralList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdjusting: false,
      adjustingLink: null,
      realSearch: '',
      searchText: '',
      data: [],
      loading: true,
      pages: -1,
      pageSize: 20,
      currentPage: 1,
      guidFilter:0
    };
    this.searchThrottled = debounce(500, this.findPcp);
  }
  findPcp = async (val) => {
    this.setState({
      searchText:val,
      guidFilter: Math.random()*10000
    })
  }

  adjustBalance = referralId => {
    this.setState({
      isAdjusting: true,
      adjustingLink: referralId,
    });
  };
  download = (filename, text) => {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };
  generateCsv = async rows => {
    const finalRows = 'date;p1;p2;amount;description;\n' + rows.join('\n');
    console.log(finalRows);
    this.download('paloma.csv', finalRows);
  };
  render() {
    const { searchText, realSearch } = this.state;

    const columns = [
      {
        Header: '',
        accessor: 'flag',
        width: 40,
        Cell: row => (
          <span
            data-tip={
              (row.value || '') === 'Same IP' ? 'Same IP address' : null
            }
            data-type={(row.value || '') === 'Same IP' ? 'warning' : null}
          >
            {' '}
            <FontAwesomeIcon
              size="1x"
              color={(row.value || '') === 'Same IP' ? 'red' : '#0cac0c'}
              icon={faFlag}
            />
          </span>
        ),
      },
      {
        Header: 'user ID',
        accessor: 'userId',
        // width: getColumnWidth(dta, 'userId', 'user ID'),
      },
      {
        Header: 'email',
        accessor: 'email',
      },
      {
        Header: 'coupon',
        accessor: 'coupon',
        // width: getColumnWidth(dta, 'coupon', 'coupon', 'money'),
      },
      //   {
      //     Header: 'collected',
      //     accessor: 'centisizedCollectedCredits',
      //     Cell: row => (
      //       <Text textAlign="right">{`$ ${((row.value || 0) / 100).toFixed(
      //         2
      //       )}`}</Text>
      //     ),
      //   },
      //   {
      //     Header: 'used',
      //     accessor: 'centisizedUsedCredits',
      //     Cell: row => (
      //       <Text textAlign="right">{`$ ${((row.value || 0) / 100).toFixed(
      //         2
      //       )}`}</Text>
      //     ),
      //   },
      {
        Header: 'balance',
        accessor: 'centisizedBalance',
        // width: getColumnWidth(
        //   dta,
        //   'centisizedBalance',
        //   'balance',
        //   'money'
        // ),
        Cell: row => (
          <Text textAlign="right">{`$ ${((row.value || 0) / 100).toFixed(
            2
          )}`}</Text>
        ),
      },
      {
        Header: 'referred',
        accessor: 'referreesCount',
        // width: getColumnWidth(dta, 'referreesCount', 'referred'),
        Cell: row => <Text textAlign="right">{row.value}</Text>,
      },
    ];
    const filt = (searchText || '').length>2?[{id: 'email', value:[searchText]}]:[]
    return (
      <Container>
        <Heading>Referrals List</Heading>
        <>
          <Flex flexDirection="row" mt={4} mb={4} alignItems="center">
            <Text mr={3}>Search:</Text>
            <Input
              type="search"
              value={realSearch}
              placeholder="Start Typing to Search..."
              onChange={v => {
                this.searchThrottled(realSearch)
                this.setState({
                  realSearch: v.target.value.toString(),
                });
              }}
            />
          </Flex>
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            data={this.state.data}
            columns={columns}
            // filterable={true}
            filtered={searchText}
            SubComponent={row => {
              return (
                <DetailsRow row={row} adjustBalance={this.adjustBalance} />
              );
            }}
            loading={this.state.loading}
            pages={this.state.pages}
            manual // informs React Table that you'll be handling sorting and pagination server-side
            onFetchData={async (state, instance) => {
              // show the loading overlay
              this.setState({loading: true})
              console.log(searchText);
              const { data } = await this.props.client.query({
                query: ADMIN_GET_REFERRAL_LIST,
                variables: {
                  from: state.page,
                  take: state.pageSize,
                  filter: realSearch
                },
              });
              // console.log({ data });
              this.setState({
                data: data.adminGetReferralList.data,
                pages: data.adminGetReferralList.pageCount,
                pageSize: state.pageSize,
                currentPage: state.page,
                loading: false,
              });
              // fetch your data
              console.log({ state });
            }}
            onPageChange={x => {
              console.log('page'.x);
            }}
            // defaultPageSize={1
            // data.adminGetReferralHistory &&
            // data.adminGetReferralHistory.length > 3
            //   ? Math.min(data.adminGetReferralHistory.length, 5)
            //   : 3
            // }
            showPagination={
              true
              // data.adminGetReferralHistory &&
              // data.adminGetReferralHistory.length > 5
            }
            showPageSizeOptions={true}
            pageSizeOptions={[20, 25, 50, 100]}
            defaultPageSize={20}
            // defaultPageSize={
            //   dta && dta.length > 3 ? Math.min(dta.length, 20) : 3
            // }
            // showPagination={dta && dta.length > 20}
          />
        </>
        <Mutation mutation={ADMIN_SEND_REF_HISTO}>
          {(mutate, { loading }) => (
            <Button
              disabled={loading}
              onClick={async () => {
                const res = await mutate();
                console.log({ res });
                if (res && res.data && res.data.adminSendReferralsHisto) {
                  this.generateCsv(res.data.adminSendReferralsHisto);
                }
              }}
            >
              Send History
            </Button>
          )}
        </Mutation>
      </Container>
    );
  }
}

export default withApollo(AdminReferralList);
