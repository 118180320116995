import React, { Component } from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { compose, withApollo, Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Steps, Step, Wizard } from 'react-albus';
import moment from 'moment';

import Header from '../components/Header';
import Single from '../components/intake/Single';
import Multiple from '../components/intake/Multiple';
import InputField from '../components/intake/InputField';
import Survey from '../components/intake/Survey';
import SmokePick from '../components/intake/Smoke';
import Information from '../components/intake/Information';
import UploadLabResults from '../components/intake/UploadLabResults';
import Disqualified from '../components/intake/Disqualified';
import withSession from '../lib/withSession';

import { medicalHistoryMenopause } from './form/medicalHistoryMenopause';
import Pharmacy from './Pharmacy';
import LabCenter from './LabCenter';
import { APPOINTMENTS, APPOINTMENTS_HISTORY, CHARTS, CURRENT_USER, GET_ATHENA_DIAGNOSED, MEMBER_UPDATE_NEW_SYMPTOMS_HELPSCOUT } from '../graphql';
import withAnalytics from '../lib/withAnalytics';
import SpinLoader from '../components/SpinLoader';
import MedicationPick from '../components/intake/MedicationPick';
import AlcoholPick from '../components/intake/Alcohol';
import PcpPick from '../components/intake/PcpPick';
import SurveyNewSymptoms from '../components/intake/SurveyNewSymptoms';
import FullLayout2 from '../components/FullLayout';
import Allergies from '../components/intake/Allergies';
import MedicationList from '../components/intake/MedicationList';
import ReturnIntakeChanges from '../components/intake/ReturnIntakeChanges';
import HeightWeight from '../components/intake/HeightWeight';
import PleaseWait from '../components/PleaseWait';
import MultipleConcatString from '../components/intake/MultipleConcatString';
import { sleep } from '../utils/sleep';

const SuperContainer = styled(Flex)`
  width: 100vw;
  justify-content: center;
  z-index: 1;
  overflow-y: auto;
`;

const Container = styled(Box)`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  overflow-y: none;
  margin-bottom: 36px ;
`;

const FullLayout = styled(Flex)`
  flex: 1;
  /* overflow: hidden; */
  background-color: #344f77;
  min-height: 100vh;
`;

class IntakeInner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intake: [],
      selectedNewSymptoms: [],
      returningItemsBypass: null,
      uploadedLabResults: [],
      isInitializing: true,
      isReturningCustomer: null,
      canViewGroupedQuestions: false,
    };
    this.ctnrRef = React.createRef(null);
  }

  onSubmit = async (value, actions, wizard, element) => {
    try {
      if (this.props.heartbeat) {
        this.props.heartbeat();
      }
    } catch (error) {}

    // console.log('onSubmit', value);
    // await this.props.client.mutate({
    //   mutation: CHARTS,
    //   variables: {
    //     data: value,
    //     intakeStatus: '',
    //   },
    // });
    // return
    console.log({value})
    let previous = this.state.intake;
    previous.push({ ...value });

    this.setState({ intake: previous });
    if (element.check) {
      const eligible = element.check(this.props.session);

      return eligible ? wizard.next() : wizard.push(element.failRoute);
    }

    if (element.nextQuestion) {
      wizard.push(element.nextQuestion);
    } else {
      wizard.next();
    }
  };

  onSubmitArray = async (value, actions, wizard, element) => {
    try {
      if (this.props.heartbeat) {
        this.props.heartbeat();
      }
    } catch (error) {}

    // console.log('onSubmit', value);
    // await this.props.client.mutate({
    //   mutation: CHARTS,
    //   variables: {
    //     data: value,
    //     intakeStatus: '',
    //   },
    // });
    // return
    let previous = this.state.intake;
    for (let index = 0; index < value.length; index++) {
      const element = value[index];
      previous.push({ ...element });
    }

    this.setState({ intake: previous });
    if (element.check) {
      const eligible = element.check(this.props.session);

      return eligible ? wizard.next() : wizard.push(element.failRoute);
    }

    if (element.nextQuestion) {
      wizard.push(element.nextQuestion);
    } else {
      wizard.next();
    }
  };

  convertHeight = async (value) => {};

  onSubmitToHelpscoutMembers = async (value) => {
    const { session, client, analytics } = this.props;
    analytics.track('Last Reported Symptoms', {
      symptoms: value || [],
    });
    if (!session.isMember) return;

    await client.mutate({
      mutation: MEMBER_UPDATE_NEW_SYMPTOMS_HELPSCOUT,
      variables: {
        symptoms: value || [],
      },
    });
  };

  onSubmitReplace = async (value, actions, wizard, element, endpointToReplace) => {
    try {
      if (this.props.heartbeat) {
        this.props.heartbeat();
      }
    } catch (error) {}
    let previous = [];
    for (let index = 0; index < this.state.intake.length; index++) {
      const element = this.state.intake[index];
      if (element.endpoint !== endpointToReplace) {
        previous.push(element);
      }
    }

    previous.push({ ...value });

    this.setState({ intake: previous });
    if (element.check) {
      const eligible = element.check(this.props.session);

      return eligible ? wizard.next() : wizard.push(element.failRoute);
    }

    if (element.nextQuestion) {
      wizard.push(element.nextQuestion);
    } else {
      wizard.next();
    }
  };

  moveToNext = async (wizard, element) => {
    try {
      if (this.props.heartbeat) {
        this.props.heartbeat();
      }
    } catch (error) {}
    if (element.check) {
      const eligible = element.check(this.props.session);

      return eligible ? wizard.next() : wizard.push(element.failRoute);
    }

    if (element.nextQuestion) {
      wizard.push(element.nextQuestion);
    } else {
      wizard.next();
    }
  };

  resetState = () => {
    this.setState({ intake: [] });
  };

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;
    if (currentPage !== prevPage) {
      const split = currentPage.split('/');
      let tag = '';

      tag = split[split.length - 1];
      if (tag) {
        this.props.analytics.page(tag, 'intake meno');
      }
    }
  }

  initialize = async () => {
    const { session, history, client } = this.props;

    const response = await client.query({
      query: APPOINTMENTS_HISTORY,
      fetchPolicy: 'network-only',
    });
    // console.log('RESPONSE HIST', response);
    if (response && response.data && response.data.getPatientAppointmentsHistory) {
      const future = response.data.getPatientAppointmentsHistory.find((item) => {
        if (
          item.status &&
          item.status === 'f' &&
          item.appointmentTypeId &&
          (item.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID.toString() ||
            item.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID.toString())
        ) {
          const dt = moment(`${item.date} ${item.startTime}`, 'MM/DD/YYYY HH:mm');
          return dt.isAfter(moment(), 'minute');
        }
      });
      const completed = response.data.getPatientAppointmentsHistory.find(
        (item) =>
          item.status &&
          (item.status.toString() === '4' || item.status.toString() === '3') &&
          item.appointmentTypeId &&
          (item.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID.toString() ||
            item.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID.toString())
      );

      if (future && completed) {
        //   // means the patient already had an appointment in the past
        //   // and has one booked in the future
        if (session.menoIntakeStatus && session.menoIntakeStatus === 'completed') {
          // we now check if the intake status is completed as we won't reset if not
          // as it might mean the intake was already restarted
          await client.mutate({
            mutation: CHARTS,
            variables: {
              data: this.state.intake,
              intakeStatus: '',
              intakeType: 'meno',
            },
            refetchQueries: [{ query: CURRENT_USER }],
            awaitRefetchQueries: true,
          });
          history.push(`/intake-menopause/`);
          this.setState({
            isInitializing: false,
            isReturningCustomer: true,
            canViewGroupedQuestions: true,
          });
          return;
        }

        this.setState({
          isReturningCustomer: true,
        });
      } else {
        this.setState({
          isReturningCustomer: false,
        });
      }
    } else {
      this.setState({
        isReturningCustomer: false,
      });
    }

    // Allows everyone to do their intake no matter the status
    // if (session.intakeStatus === 'completed') {
    //   history.push('/');
    // } else
    if (session.menoIntakeStatus) {
      history.push(`/intake-menopause/${session.menoIntakeStatus}`);
      this.setState({
        // isReturningCustomer: true, //todo REMOVE
        isInitializing: false,
      });
    } else {
      // history.push(`/intake/smoke`);
      this.setState({
        // isReturningCustomer: true, //todo REMOVE
        isInitializing: false,
      });
    }
  };

  uploadFile = (file, labResultId) => {
    const files = this.state.uploadedLabResults.filter((x) => {
      return x.labResultId.toString() !== '-1';
    });

    files.push({
      date: new Date(),
      filename: file.name,
      labResultId: labResultId,
    });
    this.props.analytics.track('Lab result uploaded');

    this.setState({
      uploadedLabResults: files,
    });
  };

  startUploadFileProcess = (file) => {
    const files = this.state.uploadedLabResults;

    files.push({
      date: new Date(),
      filename: file.name,
      labResultId: -1,
    });

    this.setState({
      uploadedLabResults: files,
    });
  };

  deleteFile = (labResultId) => {
    const files = this.state.uploadedLabResults.filter((x) => {
      return x.labResultId.toString() !== labResultId.toString();
    });

    this.setState({
      uploadedLabResults: files,
    });
  };

  render() {
    const { uploadedLabResults, isReturningCustomer, isInitializing, returningItemsBypass, canViewGroupedQuestions } = this.state;
    const QUESTIONS = medicalHistoryMenopause.filter((x) => {
      let res = true;
      if (this.props.session.intakeStatus && this.props.session.intakeDate) {
        const diff = moment(this.props.session.intakeDate, 'MM-DD-YYYY').diff(moment(), 'week');
        if (x.section === 'symptoms' && diff > -4) {
          if (this.props.session.intakeStatus === 'completed' || this.props.session.intakeStatus === 'vitals') {
            return false;
          }
        }
        if (x.section === 'vitals' && diff > -4) {
          if (this.props.session.intakeStatus === 'completed') {
            return false;
          }
        }
      }

      if (isReturningCustomer) {
        res = !x.onlyFirstTime;
      } else {
        res = !x.onlyFollowingTimes;
      }
      if (!!res && returningItemsBypass && returningItemsBypass.length > 0 && returningItemsBypass.indexOf(x.id) > -1) {
        res = false;
      }
      if (x.id === 'diagnosed') {
        if (this.props.isPreviouslyDiagnosedAthena === 'N') {
          return false;
        }
      }
      const { diagnoseFull } = this.props.session;
      if (x.bypassIfLabDiagnosed && res && diagnoseFull) {
        if (diagnoseFull.hasHypo) {
          if (diagnoseFull.hypoNotes) {
            res = false;
          }
        } else {
          if (diagnoseFull.hasHashi) {
            res = false;
          }
        }
      }
      if (x.onlyOver) {
        if (moment(this.props.session.dob).diff(moment(), 'year') > x.onlyOver) {
          return true;
        } else {
          return false;
        }
      }
      return res;
    });

    if (isInitializing || isReturningCustomer === null) {
      return (
        <FullLayout2 pb={5} style={{ alignItems: 'center' }}>
          <Container>
            <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center">
              <SpinLoader width={100} height={100} />
              <Text
                style={{
                  color: '#486288',
                  fontSize: '18px',
                  marginTop: '40px',
                }}
              >
                Initializing The Intake Form
              </Text>
            </Flex>
          </Container>
        </FullLayout2>
      );
    }
    if (!!isReturningCustomer && !returningItemsBypass && !!canViewGroupedQuestions) {
      return (
        <FullLayout2 pb={5}>
          <Container>
            <ReturnIntakeChanges
              onSubmit={(bypass) => {
                this.setState({
                  returningItemsBypass: bypass,
                });
              }}
            />
          </Container>
        </FullLayout2>
      );
    }

    const currentIndex = QUESTIONS.filter((x) => x.type !== 'disqualified').findIndex((x) => x.id === this.props.history.location.pathname.replace('/intake-menopause/', '')) + 1;
    return (
      <FullLayout2 pb={5}>
        <Flex width={'100%'} style={{ height: '4px', width: '100%', position: 'absolute', top: 0, right: 0, left: 0 }} id="prgrs">
          <Flex
            style={{
              background: 'rgb(121, 146, 179)',
              flex: currentIndex,
              height: '100%',
              transition: 'all 1s ease-in-out .3s',
            }}
          />
          <Flex
            style={{
              flex: QUESTIONS.filter((x) => x.type !== 'disqualified').length - currentIndex,
              height: '100%',
              transition: 'all 1s ease-in-out .3s',
            }}
          />
        </Flex>
        <SuperContainer id="CtnrIntake" ref={this.ctnrRef}>
          <Container>
            <Query query={APPOINTMENTS} fetchPolicy="network-only">
              {({ loading, error, data }) => {
                if (loading)
                  return (
                    <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                      <SpinLoader width={100} height={100} />
                      <Text
                        style={{
                          color: '#486288',
                          fontSize: '18px',
                          marginTop: '40px',
                        }}
                      >
                        Initializing The Intake Form
                      </Text>
                    </Flex>
                  );

                return (
                  <Box>
                    <Flex flex={1}>
                      <Header dark={false} mb={3} />
                    </Flex>
                    <Wizard history={this.props.history} basename="/intake-menopause">
                      <Steps>
                        {QUESTIONS.map((el) => {
                          if (el.type === 'allergies') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <Allergies data={el} onSubmit={this.onSubmit} userData={this.state.intake} />
                              </Step>
                            );
                          } else if (el.type === 'single') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <Single data={el} onSubmit={this.onSubmit} userData={this.state.intake} next={this.moveToNext} />
                              </Step>
                            );
                          } else if (el.type === 'multiple') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <Multiple data={el} onSubmit={this.onSubmit} />
                              </Step>
                            );
                          } else if (el.type === 'multiSelect') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <MultipleConcatString data={el} onSubmit={this.onSubmit} />
                              </Step>
                            );
                          } else if (el.type === 'pcp') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <PcpPick data={el} onSubmit={this.onSubmit} moveToNext={this.moveToNext} />
                              </Step>
                            );
                          } else if (el.type === 'medicationPick') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <MedicationPick
                                  data={el}
                                  mode="meno"
                                  onSubmit={this.onSubmit}
                                  autoSkipNoMeds={this.props.isPreviouslyDiagnosedAthena === 'N'}
                                  appointmentId={
                                    data &&
                                    data.appointments &&
                                    data.appointments.filter((xxx) => {
                                      const m = moment(xxx.date).isSameOrAfter(moment(), 'day');
                                      return m;
                                    }).length > 0
                                      ? data.appointments.filter((xxx) => {
                                          const m = moment(xxx.date).isSameOrAfter(moment(), 'day');
                                          return m;
                                        })[0].id
                                      : null
                                  }
                                  onDisqualify={(wizard) => wizard.push('disqualified')}
                                  onCancel={() => this.props.history.push('/')}
                                />
                              </Step>
                            );
                          } else if (el.type === 'medicationList') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <MedicationList data={el} onSubmit={this.onSubmit} />
                              </Step>
                            );
                          } else if (el.type === 'medicationList2') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <MedicationList data={el} onSubmit={this.onSubmit} bypassLoad={true} />
                              </Step>
                            );
                          } else if (el.type === 'alcoholPick') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <AlcoholPick data={el} onSubmit={this.onSubmit} />
                              </Step>
                            );
                          } else if (el.type === 'smokePick') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <SmokePick data={el} onSubmit={this.onSubmit} />
                              </Step>
                            );
                          } else if (el.type === 'disqualified') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <Disqualified appointments={data ? data.appointments : null} data={el} onSubmit={this.onSubmit} history={this.props.history} />
                              </Step>
                            );
                          } else if (el.type === 'information') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <Information data={el} userData={this.state.intake} resetState={this.resetState} refetch={this.props.refetch} intakeType={'meno'} />
                              </Step>
                            );
                          } else if (el.type === 'survey') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <Survey data={el} onSubmit={this.onSubmit} selectedSymptoms={this.state.selectedNewSymptoms} onSubmitToHelpscoutMembers={this.onSubmitToHelpscoutMembers} />
                              </Step>
                            );
                          } else if (el.type === 'surveyNewSymptoms') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <SurveyNewSymptoms
                                  data={el}
                                  onSubmit={(formatted, _null, wizard, data) => {
                                    this.setState({ selectedNewSymptoms: (formatted || {}).values || [] }, async () => {
                                      try {
                                        if (this.props.heartbeat) {
                                          this.props.heartbeat();
                                        }
                                      } catch (error) {}
                                      if (this.ctnrRef && this.ctnrRef.current) {
                                        this.ctnrRef.current.scrollTo(0, 0);
                                      }
                                      wizard.next();
                                    });
                                  }}
                                  relatedData={medicalHistoryMenopause.find((x) => x.id === el.relatedDataId)}
                                  userData={this.state.intake}
                                />
                              </Step>
                            );
                          } else if (el.type === 'input') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <InputField data={el} onSubmit={this.onSubmit} userData={this.state.intake} />
                              </Step>
                            );
                          } else if (el.type === 'HeightWeight') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <HeightWeight data={el} onSubmit={this.onSubmit} userData={this.state.intake} />
                              </Step>
                            );
                          } else if (el.type === 'uploadLabResults') {
                            return (
                              <Step key={el.id} id={el.id}>
                                <UploadLabResults
                                  isMeno={true}
                                  data={el}
                                  onSubmit={this.onSubmit}
                                  uploadedLabResults={uploadedLabResults}
                                  startUploadFileProcess={this.startUploadFileProcess}
                                  uploadFile={this.uploadFile}
                                  deleteFile={this.deleteFile}
                                />
                              </Step>
                            );
                          } else if (el.type === 'pharmacy') {
                            return <Step key={el.id} id={el.id} render={({ next }) => <Pharmacy data={el} onSubmit={this.onSubmit} continueIntake={next} displayDisclaimerCareManager />} />;
                          } else if (el.type === 'labCenter') {
                            return <Step key={el.id} id={el.id} render={({ next }) => <LabCenter data={el} onSubmit={this.onSubmit} continueIntake={next} />} />;
                          } else {
                            console.log(el);
                            return null;
                          }
                        })}
                      </Steps>
                    </Wizard>
                  </Box>
                );
              }}
            </Query>
          </Container>
        </SuperContainer>
      </FullLayout2>
    );
  }
}

const IntakeMenopause = (props) => {
  return (
    <Query query={GET_ATHENA_DIAGNOSED}>
      {({ data, loading }) => {
        if (loading || !data) {
          return (
            <FullLayout2 pb={5} style={{ alignItems: 'center' }}>
              <Container>
                <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center">
                  <SpinLoader width={100} height={100} />
                  <Text
                    style={{
                      color: '#486288',
                      fontSize: '18px',
                      marginTop: '40px',
                    }}
                  >
                    Initializing The Intake Form
                  </Text>
                </Flex>
              </Container>
            </FullLayout2>
          );
        }

        return <IntakeInner {...props} isPreviouslyDiagnosedAthena={(data || {}).getAthenaIsDiagnosed.diagnosed} />;
      }}
    </Query>
  );
};

export default compose(
  withRouter,
  withApollo,
  withAnalytics
)(withSession(IntakeMenopause));
