import React from 'react';
import { Flex, Card, Box, Text } from 'rebass';
import styled from 'styled-components';
import AddFeatureRow from './featureToggles/AddFeatureRow';
import FeatureRowHeaders from './featureToggles/FeatureRowHeaders';
import { Query } from 'react-apollo';
import FeatureRow from './featureToggles/FeatureRow';
import { GET_FEATURE_TOGGLES_LIST } from '../graphql';

const Container = styled(Box)`
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
`;

class FeatureTogglesManagement extends React.Component {
  render() {
    return (
      <div style={{ width: '100%', overflowY: 'auto' }}>
        <Container>
        <Flex flex={1} textAlign="center" alignSelf='center' justifyContent='center'>
            <Text
              fontSize="38px"
              fontWeight={600}
              textAlign="center"
              mb={3}
              style={{ fontFamily: 'Playfair Display' }}
              color="#344f79"
            >
              Feature Toggle Management
            </Text>
        </Flex>
          <Card p={[3, 4]} mb={4}>
            <Flex flexDirection="column">
              <FeatureRowHeaders />
              <Query query={GET_FEATURE_TOGGLES_LIST}>
                {({ loading, error, data }) => {
                  if (loading) return <div />;
                  if (error) {
                    console.log(error);
                    return <div />;
                  }
                  if (
                    !data.getFeatureTogglesList ||
                    data.getFeatureTogglesList.length < 1
                  ) {
                    return <div />;
                  }
                  // console.log('data', data);
                  return data.getFeatureTogglesList.map(x => {
                    return <FeatureRow toggle={x} id={x.name} />;
                  });
                }}
              </Query>
              <AddFeatureRow />
            </Flex>
          </Card>
        </Container>
      </div>
    );
  }
}

export default FeatureTogglesManagement;
