import React from 'react';
import { Text } from 'rebass';
import Dropzone from 'react-dropzone';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';
import addFile from '../../../static/addFile.png';

const Inner = styled.section`
  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const csvStringToArray = (strData, header = true) => {
  //const objPattern = new RegExp(("(\\,|\\r?\\n|\\r|^)(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|([^\\,\\r\\n]*))"),"gi");
  const objPattern = new RegExp(
    '(\\,|\\r?\\n|\\r|^)(?:"((?:\\\\.|""|[^\\\\"])*)"|([^\\,"\\r\\n]*))',
    'gi'
  );
  let arrMatches = null,
    arrData = [[]];
  while ((arrMatches = objPattern.exec(strData))) {
    if (arrMatches[1].length && arrMatches[1] !== ',') arrData.push([]);
    arrData[arrData.length - 1].push(
      arrMatches[2]
        ? arrMatches[2].replace(new RegExp('[\\\\"](.)', 'g'), '$1')
        : arrMatches[3]
    );
  }
  if (header) {
    let hData = arrData.shift();
    let hashData = arrData.map(row => {
      let i = 0;
      return hData.reduce((acc, key) => {
        acc[key] = i === 1 ? row[i++] : row[i++];
        return acc;
      }, {});
    });
    return hashData;
  } else {
    return arrData;
  }
};

class UploadCoursesScreenFromCsv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      isActive: true,
      isPro: false,
    };
  }
  handleRejected = () => {
    console.log('rejected');
  };

  startUploadFileProcess = f => {
    console.log('startUploadFileProcess', f);
    const element = f;

    const reader = new FileReader();
    reader.onload = event => {
      var decodedString = atob(
        event.target.result.replace('data:text/csv;base64,', '')
      );
      //   console.log()
      const aaa = csvStringToArray(decodedString, true);
      console.log(aaa); // Outputs: "Hello World!"
    };

    reader.readAsDataURL(element);
  };

  render() {
    return (
      <Dropzone
        id="dropzone"
        multiple={false}
        onDropRejected={this.handleRejected}
        onDropAccepted={([file]) => {
          this.startUploadFileProcess(file);
        }}
        maxSize={10000000}
        style={{ width: '100%', height: '100%' }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <Inner>
            <div
              {...getRootProps()}
              className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
            >
              <input type="file" {...getInputProps()} />
              <img src={addFile} height={36} alt="" />

              <>
                <Text fontSize={[2]} style={{ marginTop: '24px' }}>
                  Upload your lab results here
                </Text>
              </>
            </div>
          </Inner>
        )}
      </Dropzone>
    );
  }
}

export default UploadCoursesScreenFromCsv;
