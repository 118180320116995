export const csvStringToArray = (
  strData,
  header = true,
  typeMap = undefined
) => {
  //const objPattern = new RegExp(("(\\,|\\r?\\n|\\r|^)(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|([^\\,\\r\\n]*))"),"gi");
  const objPattern = new RegExp(
    '(\\,|\\r?\\n|\\r|^)(?:"((?:\\\\.|""|[^\\\\"])*)"|([^\\,"\\r\\n]*))',
    'gi'
  );
  let arrMatches = null,
    arrData = [[]];

  while ((arrMatches = objPattern.exec(strData))) {
    if (arrMatches[1].length && arrMatches[1] !== ',') arrData.push([]);
    arrData[arrData.length - 1].push(
      arrMatches[2]
        ? arrMatches[2].replace(new RegExp('[\\\\"](.)', 'g'), '$1')
        : arrMatches[3]
    );
  }

  if (header) {
    let hData = arrData.shift();
    let hashData = arrData.map(row => {
      let i = 0;
      return hData.reduce((acc, key) => {
        let index = i;
        let val = row[i++];
        if (typeMap) {
          switch (typeMap[index]) {
            case 'int':
              val = !!val ? parseInt(val, 10) : null;
              break;
            case 'float':
              val = !!val ? parseFloat(val, 10) : null;
              break;
            case 'bool':
              val = !!val
                ? val.toString().toLowerCase() === 'true'
                  ? true
                  : false
                : null;
              break;
            case 'string':
              val = !!val ? val.toString() : null;
              break;

            default:
              break;
          }
        }
        acc[key.replace(/\s/g, '')] = val;
        return acc;
      }, {});
    });
    return hashData;
  } else {
    return arrData;
  }
};
