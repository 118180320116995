import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import Modal, { BaseModalBackground } from 'styled-react-modal';
import PleaseWait from '../components/PleaseWait';
import ResultsView from './adminSearchPatient/ResultsView';
import SearchView, { SearchViewDashboard } from './adminSearchPatient/SearchView';
import { withRouter } from 'react-router';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;

const SpecialModalBackground = styled(BaseModalBackground)`
  backdrop-filter: blur(5px);
`;
const SEARCH_PATIENT = gql`
  query adminGetUser($search: String!) {
    adminGetUser(search: $search) {
      web {
        id
        email
        phone
        firstName
        lastName
        intakeStatus
        athenaId
        helpscoutId
        stripeId
        createdAt
        accountLocked
        recoveryUid
        mustResetPassword
        mustCompleteProfile
        profileCompletionUid
        isMember
        membershipSpecific
        oonSchedulable
        intakeDate
        menoIntakeStatus
        menoIntakeDate
        isZocDoc
        careManager
        mustScheduleOnboardingCall
        hasOneTimeExceptionBook {
          id
          expiresOn
        }
        baselineSurvey {
          mustComplete
          status
        }
        surveysStatus {
          id
          name
          status
          triggerOn
        }
        athenaData {
          patientId
          email
          lastName
          firstName
          address1
          address2
          city
          state
          zip
          countryCode
          mobilePhone
          homePhone
          industrycode
          sex
          status
          departmentId
          privacyInformationVerified
          primaryDepartmentId
          emailExists
          patientPhoto
          registrationDate
          guarantorCountryCode
          dob
          guarantorRelationshipToPatient
          consentToText
          countryCode3166
          guarantorCountryCode3166
          primaryProviderId
          lastAppointment
          medicationHistoryConsentVerified
        }
        mobileUser {
          id
          email
          firstName
          lastName
          birthYear
          wasDiagnosedHypothyroidism
          wasDiagnosedHashimoto
          isActive
          createdAt
          accountLocked
          loginAttempts
          recoveryUid
          mustResetPassword
          mustConfirmEmail
          mustCompleteProfile
          state
        }
      }
      mobile {
        id
        email
        firstName
        lastName
        birthYear
        wasDiagnosedHypothyroidism
        wasDiagnosedHashimoto
        isActive
        createdAt
        accountLocked
        loginAttempts
        recoveryUid
        mustResetPassword
        mustConfirmEmail
        mustCompleteProfile
        state
        webUser {
          id
          email
          phone
          firstName
          lastName
          intakeStatus
          athenaId
          helpscoutId
          stripeId
          createdAt
          accountLocked
          recoveryUid
          mustResetPassword
          mustCompleteProfile
          profileCompletionUid
          isMember
          membershipSpecific
          oonSchedulable
          intakeDate
          isZocDoc
          careManager
          baselineSurvey {
            mustComplete
            status
          }
          athenaData {
            patientId
            email
            lastName
            firstName
            address1
            address2
            city
            state
            zip
            countryCode
            mobilePhone
            homePhone
            industrycode
            sex
            status
            departmentId
            privacyInformationVerified
            primaryDepartmentId
            emailExists
            patientPhoto
            registrationDate
            guarantorCountryCode
            dob
            guarantorRelationshipToPatient
            consentToText
            countryCode3166
            guarantorCountryCode3166
            primaryProviderId
            lastAppointment
            medicationHistoryConsentVerified
          }
        }
      }
    }
  }
`;

const AdminSearchPatient = () => {
  const [search, setSearch] = useState('');
  return (
    <Container>
      <Flex m={2} flex={1} flexDirection="column">
        <Heading mb={4} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
          Search patient's accounts
        </Heading>
        {!search ? (
          <SearchView search={search} onSearch={setSearch} />
        ) : (
          <Query query={SEARCH_PATIENT} variables={{ search }}>
            {({ loading, data, refetch }) => {
              if (!!loading) {
                return <PleaseWait />;
              }

              return (
                <>
                  <Flex mb={4}>
                    <Text fontSize={3} fontWeight={500} mt={4}>
                      Search results for {search}
                    </Text>
                    <Button
                      variant={'primary'}
                      onClick={() => setSearch('')}
                      style={{
                        padding: '8px 12px',
                        alignSelf: 'end',
                        marginLeft: '18px',
                      }}
                    >
                      X
                    </Button>
                  </Flex>
                  <ResultsView results={data.adminGetUser} refetch={refetch} isEditable={(search || '').indexOf('@') > -1} />
                </>
              );
            }}
          </Query>
        )}
      </Flex>
    </Container>
  );
};

const AdminSearchPatientDashboardInner = ({ location, history }) => {
  const [search, setSearch] = useState(location && location.search && location.search.indexOf('?vp=') > -1 ? location.search.replace('?vp=', '') : '');
  const handleClose = () => {
    setSearch('');
    history.push('/');
  };
  return (
    <Container>
      <Flex m={2} flex={1} flexDirection="column">
        {!search ? (
          <SearchViewDashboard search={search} onSearch={setSearch} />
        ) : (
          <Query query={SEARCH_PATIENT} variables={{ search }}>
            {({ loading, data, refetch }) => {
              return (
                <Modal isOpen={true} onBackgroundClick={handleClose} onEscapeKeydown={handleClose}>
                  {/* <SpecialModalBackground> */}
                  <Box
                    style={{
                      margin: '0 auto',
                      alignSelf: 'center',
                      borderRadius: '20px',
                      position: 'relative',
                      backgroundColor: 'white',
                      padding: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      maxWidth: '95vw',
                      minWidth: !loading ? '95vw' : 'initial',
                      maxHeight: '95vh',
                    }}
                  >
                    {!!loading ? (
                      <PleaseWait />
                    ) : (
                      <>
                        <Flex mb={4}>
                          <Text fontSize={3} fontWeight={500} mt={4}>
                            Search results for {search}
                          </Text>
                        </Flex>
                        <Flex flex={1} style={{ overflowY: 'scroll', width: '100%', flexDirection: 'column' }} id="searchhContainer">
                          <ResultsView results={data.adminGetUser} refetch={refetch} isEditable={(search || '').indexOf('@') > -1} />
                        </Flex>
                      </>
                    )}

                    <Box
                      onClick={handleClose}
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '-10px',
                        right: '-10px',
                        height: '34px',
                        width: '34px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#F8A294',
                        padding: '6px',
                        borderRadius: '50%',
                        color: 'white',
                      }}
                    >
                      X
                    </Box>
                  </Box>
                  {/* </SpecialModalBackground> */}
                </Modal>
              );
            }}
          </Query>
        )}
      </Flex>
    </Container>
  );
};
export const AdminSearchPatientDashboard = withRouter(AdminSearchPatientDashboardInner);
export default withRouter(AdminSearchPatient);
