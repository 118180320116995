import React from 'react';
import { Box, Card, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Stethoscope from '../../components/icons/Stethoscope';
import Beaker from '../../components/icons/Beaker';

const CardFlex = styled(Card)({
  display: 'flex',
  flex: '1 1 auto',
  alignItems: 'center',
  justifyContent: 'center',
});

const Aside = () => (
  <Flex flexDirection="column" width={[1, 1, 1 / 3]}>
    <Card mb={4} p={4}>
      <Flex mb={4}>
        <Box mr={3}>
          <Stethoscope width={80} height={80} />
        </Box>

        <Box px={2}>
          <Text>
            See the best thyroid provider. A good thyroid-focused provider can change your
            life.
          </Text>
        </Box>
      </Flex>

      <Flex>
        <Box mr={3}>
          <Beaker width={80} />
        </Box>

        <Box px={2}>
          <Text>
            Check your thyroid at home with our complete and easy blood test
            kit.
          </Text>
        </Box>
      </Flex>
    </Card>

    <CardFlex p={4}>
      <Flex flexDirection="column" alignItems="center">
        <Text mb={2} style={{ textAlign: 'center' }}>
          Already have an account?
        </Text>
        <Box>
          <Link to="/login">Login</Link>
        </Box>
      </Flex>
    </CardFlex>
  </Flex>
);

export default Aside;
