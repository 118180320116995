import React from 'react';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';
import your_Account from '../static/your_Account.png';
import your_Payment_information from '../static/your_Payment_information.png';
import your_Subscriptions from '../static/your_Subscriptions.png';
import your_Support from '../static/your_Support.png';
import your_Legal from '../static/your_Legal.png';
import your_savings_card from '../static/your_savings_card.png'
import withSession from '../lib/withSession';
import { isToggleActive } from '../components/featureToggle/toggles';

const Wrapper = styled.div`
  padding-left: 60px;
  padding-top: 40px;
  @media screen and (max-width: 52em) {
    padding-left: 12px;
  }
`;

const Container = styled(Flex)`
  flex: 1;
  /* background: white;
  border-radius: 8px 8px 8px 8px; */
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  /* box-shadow: 0px 2.41029px 24.1029px rgba(0, 0, 0, 0.1); */
`;

const Container2 = styled(Flex)`
  flex: 1;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  padding: 12px 20px 20px;
  /* border-bottom: 1px solid #d1d1d1; */
`;
const Entry = styled(Flex)`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const EntryWrapper = styled(Flex)`
  flex: 1;
  flex-direction: column;
  /* background-color: white; */
  margin: 6px 12px;
`;

const TitleRow = styled(Flex)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 22px;
`;

const Arrow = styled(Text)`
  font-weight: 600;
  margin-left: 8px;
  font-size: 28px;
`;

const MyAccount = ({
  history,
  session: { isMember, isDiagnosed, diagnoseFull, b2b },
}) => {
  return (
    <Wrapper>
      <Container>
        <Container2
          onClick={() => {
            history.push('/my-account');
          }}
        >
          <Entry>
            <img
              style={{ height: '68px', width: '78px' }}
              height="68px"
              width="78px"
              src={your_Account}
              alt=""
            />
            <EntryWrapper>
              <TitleRow>
                <Title>Account Information</Title>
              </TitleRow>
              <Text style={{ fontWeight: 300 }}>
                Change your password or contact information
              </Text>
            </EntryWrapper>
            <Arrow>{'>'}</Arrow>
          </Entry>
        </Container2>

        <Container2
          onClick={() => {
            history.push('/my-payment-methods');
          }}
        >
          <Entry>
            <img
              style={{ height: '68px', width: '78px' }}
              height="68px"
              width="78px"
              src={your_Payment_information}
              alt=""
            />
            <EntryWrapper>
              <TitleRow>
                <Title>Payment Methods</Title>
              </TitleRow>
              <Text style={{ fontWeight: 300 }}>
                Edit your payment method for each single service you use
              </Text>
            </EntryWrapper>
            <Arrow>{'>'}</Arrow>
          </Entry>
        </Container2>
        {((isToggleActive('MEMBERSHIP') || isMember) && (!b2b || !b2b.isActive)) && (
          <Container2
            onClick={() => {
              history.push('/my-subscriptions');
            }}
          >
            <Entry>
              <img
                style={{ height: '68px', width: '78px' }}
                height="68px"
                width="78px"
                src={your_Subscriptions}
                alt=""
              />
              <EntryWrapper>
                <TitleRow>
                  <Title>Your Subscriptions</Title>
                </TitleRow>
                <Text style={{ fontWeight: 300 }}>
                  Manage your memberships, your supplements and your kit
                  subscription. Update your payment method or view your last
                  bill...
                </Text>
              </EntryWrapper>
              <Arrow>{'>'}</Arrow>
            </Entry>
          </Container2>
        )}
        {!b2b && (
          <Container2
            onClick={() => {
              history.push('/my-eligibility');
            }}
          >
            <Entry>
              <img
                style={{ height: '68px', width: '78px' }}
                height="68px"
                width="78px"
                src={your_Account}
                alt=""
              />
              <EntryWrapper>
                <TitleRow>
                  <Title>Check Eligibility</Title>
                </TitleRow>
                <Text style={{ fontWeight: 300 }}>
                  Check if you are eligible through your employer or a relative's employer...
                </Text>
              </EntryWrapper>
              <Arrow>{'>'}</Arrow>
            </Entry>
          </Container2>
        )}

        {(isToggleActive('USRX') && isMember) && (
          <Container2
            onClick={() => {
              history.push('/my-saving-card');
            }}
          >
            <Entry>
              <img
                style={{ height: '68px', width: '78px' }}
                height="68px"
                width="78px"
                src={your_savings_card}
                alt=""
              />
              <EntryWrapper>
                <TitleRow>
                  <Title>Your Member Drug Savings Card</Title>
                </TitleRow>
                <Text style={{ fontWeight: 300 }}>
                  Show this card to your pharmacist to save money on drugs
                </Text>
              </EntryWrapper>
              <Arrow>{'>'}</Arrow>
            </Entry>
          </Container2>
        )}

        <Container2
          onClick={() => {
            history.push('/support');
          }}
        >
          <Entry>
            <img
              style={{ height: '68px', width: '78px' }}
              height="68px"
              width="78px"
              src={your_Support}
              alt=""
            />
            <EntryWrapper>
              <TitleRow>
                <Title>Support</Title>
              </TitleRow>
              <Text style={{ fontWeight: 300 }}>
                Need help? we are here for you. Browse our FAQ or reach out to
                us to get the answers you need
              </Text>
            </EntryWrapper>
            <Arrow>{'>'}</Arrow>
          </Entry>
        </Container2>

        <Container2
          onClick={() => {
            window.location = 'https://palomahealth.com/legal/legal';
          }}
        >
          <Entry>
            <img
              style={{ height: '68px', width: '78px' }}
              height="68px"
              width="78px"
              src={your_Legal}
              alt=""
            />
            <EntryWrapper>
              <TitleRow>
                <Title>Legal</Title>
              </TitleRow>
              <Text style={{ fontWeight: 300 }}>
                HIPAA Privacy Policy, Terms and Conditions, Notice of Privacy
                Practices....
              </Text>
            </EntryWrapper>
            <Arrow>{'>'}</Arrow>
          </Entry>
        </Container2>
      </Container>
    </Wrapper>
  );
};

export default withSession(MyAccount);
