import React, { Component } from 'react';
import { Flex, Text, Button, Box } from 'rebass';
import styled from 'styled-components';
import NutritionistIcon from '../../static/nutritionist.png';
import withSession from '../../lib/withSession';
import withAnalytics from '../../lib/withAnalytics';

const Container = styled(Box)`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
`;

class ScheduleNewNutritionist extends Component {
  state = {
    prompt: false,
  };

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    script.onload = () => this.scriptLoaded();

    document.body.appendChild(script);
  }

  scriptLoaded = () => {
    // console.log('Window', window);
  };

  componentWillUnmount() {
    var tags = document.getElementsByTagName('script');
    try {
      window.removeEventListener('message', this.windowMessageReceived);
    } catch (error) {}
    for (var i = tags.length; i >= 0; i--) {
      if (tags[i] && tags[i].getAttribute('src') != null && tags[i].getAttribute('src').indexOf('https://assets.calendly.com/assets/external/widget.js') > -1) tags[i].parentNode.removeChild(tags[i]); //remove element by calling parentNode.removeChild()
    }
  }
  render() {
    const { session } = this.props;

    const name = encodeURI(`${session.firstName} ${session.lastName}`);
    return (
      <Container>
        {/* <link href='https://assets.calendly.com/assets/external/widget.css' rel='stylesheet' /> */}
        <Flex
          flex={1}
          flexDirection="column"
          style={{
            textAlign: 'center',
            alignItems: 'center',
            paddingTop: '48px',
          }}
        >
          <div style={{ maxWidth: '650px' }}>
            <Text fontSize="32px" fontWeight={600} mb={4} color="#344f79" lineHeight="44px" style={{ fontFamily: 'Playfair Display' }}>
              Personalized Dietary Guidance & Health Coaching
            </Text>

            <img src={NutritionistIcon} alt="" style={{ maxWidth: '180px', alignSelf: 'center' }} />

            <Text textAlign="center" lineHeight="24px" fontSize="18px" fontWeight={200} color="#344f79" mb={3} mt={3}>
              Your Paloma Health Coach is your on-demand nutritionist, accountability partner, and cheerleader all in one! <br />
              <br />
              Whether you need help jumpstarting stalled weight loss, understanding your protein requirements, or just need a bit more encouragement, we are here to guide you to success.
            </Text>
          </div>
          <Button
            variant="primary"
            height="48px"
            style={{ margin: '12px', marginBottom: '36px' }}
            onClick={() => {
              window.Calendly.initPopupWidget({
                url:
                  !session || !session.b2b || !session.b2b.nutritionist
                    ? `https://calendly.com/paloma-video-visit/paloma-health-consultation?hide_event_type_details=1&name=${name}&email=${session.email}`
                    : `https://calendly.com/paloma-video-visit/paloma-health-consultation-fringe?hide_event_type_details=1&name=${name}&email=${session.email}`,
              });
              this.props.analytics.track('Book nutritionist consultation');
            }}
          >
            Book a Visit
          </Button>
        </Flex>
      </Container>
    );
  }
}

export default withAnalytics(withSession(ScheduleNewNutritionist));
