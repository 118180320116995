import React from 'react';
import { compose } from 'react-apollo';
import { GoogleApiWrapper } from 'google-maps-react';
import { Input } from '../TextInput';
import { Field } from 'formik';

class PlacesAutocompleteField extends React.Component {
  input = React.createRef();
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.autocomplete = null;
  }

  componentDidMount() {
    this.autocomplete = new this.props.google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {}
    );

    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  initialState() {
    return {
      name: '',
      street_address: '',
      city: '',
      state: '',
      zip_code: '',
      googleMapLink: '',
    };
  }

  parseAddress = address => {
    let street = '';
    let city = '';
    let state = '';
    let zip = '';
    for (let index = 0; index < (address ? address.length : 0); index++) {
      const element = address[index];
      for (let index2 = 0; index2 < element.types.length; index2++) {
        const types = element.types[index2];
        switch (types) {
          case 'street_number':
          case 'route':
            if (!street) {
              street = element.long_name;
            } else {
              street += ' ' + element.long_name;
            }
            break;
          case 'locality':
            city = element.long_name;
            break;
          case 'administrative_area_level_1':
            state = element.short_name;
            break;
          case 'postal_code':
            zip = element.short_name;
            break;

          default:
            break;
        }
      }
    }

    return {
      street,
      city,
      state,
      zip,
    };
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.clearForm();
  }

  handlePlaceSelect() {
    const {
      isFieldType,
      street1FieldName,
      cityFieldName,
      zipFieldName,
      stateFieldName,
      form,
      fieldname,
      onSelected,
    } = this.props;
    const addressObject = this.autocomplete.getPlace();
    const ad = addressObject.address_components;
    const address = this.parseAddress(ad);
    // console.log('Address Selected', address);
    this.setState(
      {
        name: addressObject.name,
        street_address: address.street,
        city: address.city,
        state: address.state,
        zip_code: address.zip,
        googleMapLink: addressObject.url,
      },
      () => {
        if (isFieldType) {
          // console.log('street1FieldName', {
          //   addressObject,
          // });
          form.setFieldValue(street1FieldName, address.street);
          form.setFieldValue(cityFieldName, address.city);
          form.setFieldValue(zipFieldName, address.zip);
          form.setFieldValue(stateFieldName, address.state);
          form.setFieldTouched(fieldname, true);
          if (onSelected) {
            // console.log('onSelected')
            onSelected(address);
          }
        }
      }
    );
  }

  render() {
    const { name } = this.state;
    // console.log('name', name);
    const { form, fieldname, onTextChanged, onBlur, isAsyncMode } = this.props;
    return (
      <Input
        isAsyncMode={isAsyncMode}
        placeholder="Enter your address"
        id="autocomplete"
        className="input-field"
        innerRef={this.input}
        type="text"
        onChange={vvv => {
          form.setFieldValue(
            'originalAddress',
            vvv && vvv.target ? vvv.target.value : ''
          );
          if (onTextChanged) {
            onTextChanged(vvv && vvv.target ? vvv.target.value : '');
          }
        }}
        onBlur={e => {
          // console.log('name', e);
          form.setFieldTouched(fieldname, true);
          if (!name) {
            if (onBlur) {
              onBlur(e.target.value);
            }
          }
        }}
      />
    );
  }
}

const PlacesAutocomplete = props => {
  if (props.unfield) {
    return <PlacesAutocompleteField {...props} />;
  } else
    return (
      <Field
        {...props}
        component={PlacesAutocompleteField}
        isFieldType={true}
      />
    );
};

export default compose(
  GoogleApiWrapper({
    apiKey: window._env_.REACT_APP_GOOGLE_API,
  })
)(PlacesAutocomplete);
