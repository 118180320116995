import React, { useState } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Button, Text, Flex, Box } from 'rebass';
import styled from 'styled-components';
import { BoxButton } from '../../../components/async/SharedComponents';
import {
  ASYNC_SOFT_DISQUALIFY,
  SEND_INSURANCE_INITIAL,
} from '../../../graphql';
import withSession from '../../../lib/withSession';
import Imge from '../../../static/Kit_Extra_BG_2.png';
import { useMount } from '../../../utils/hooks/lifecycle';
import doctorImg from '../../../static/Doctor_Female_White_shirt_2.png';
import { getMemberVisitPrice } from '../../../utils/pricing';

const BlueHeading = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #354e79;
`;

const textWithInsurance = {
  text1: `We still need to confirm your eligibility but some of our providers are likely to be in-network with your plan`,
  text2: `You will be charged for any co-insurance, co-pays, and deductibles. Most patients pay only their co-pay.`,
};
const textWithoutInsurance = {
  text1: `Our providers are not in network with your plan.`,// We will provide you with the superbill necessary for an out-of-network reimbursement`,
  text2: `Live visits cost $${getMemberVisitPrice()} as a Paloma member`,
};
const InsuranceTextBubble = ({ withInsurance }) => {
  if (withInsurance === null) return <div />;
  return (
    <Flex width="100%" flexDirection="row" mb={7}>
      <Box
        p="16px"
        style={{
          background: '#E7F0FF',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '15px 15px 0px 15px',
          marginBottom: '24px',
        }}
      >
        <Flex flexDirection="row">
          {withInsurance ? (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="20" height="19.1444" rx="9.57219" fill="#354E79" />
              <path
                d="M13.4456 6.10463C13.6184 5.91645 13.8483 5.81242 14.0869 5.81448C14.3255 5.81655 14.5541 5.92455 14.7244 6.1157C14.8947 6.30685 14.9934 6.5662 14.9997 6.83902C15.006 7.11184 14.9194 7.37679 14.7582 7.57797L9.86357 14.5798C9.7794 14.6835 9.67782 14.7667 9.5649 14.8245C9.45198 14.8822 9.33003 14.9133 9.20636 14.916C9.08268 14.9186 8.95981 14.8927 8.8451 14.8397C8.73038 14.7868 8.62618 14.7079 8.53872 14.6079L5.29283 10.8951C5.20243 10.7987 5.12993 10.6825 5.07965 10.5534C5.02936 10.4244 5.00232 10.285 5.00014 10.1437C4.99796 10.0024 5.02069 9.86204 5.06696 9.731C5.11323 9.59996 5.18211 9.48092 5.26947 9.38099C5.35684 9.28106 5.4609 9.20228 5.57547 9.14935C5.69003 9.09642 5.81273 9.07042 5.93627 9.07292C6.0598 9.07541 6.18163 9.10634 6.29449 9.16386C6.40734 9.22138 6.50892 9.30431 6.59315 9.4077L9.16189 12.3445L13.4223 6.1355C13.4299 6.1247 13.4381 6.11439 13.4468 6.10463H13.4456Z"
                fill="#E7F0FF"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8125 2.62793C7.50469 2.62793 4 6.13262 4 10.4404C4 14.7482 7.50469 18.2529 11.8125 18.2529C16.1203 18.2529 19.625 14.7482 19.625 10.4404C19.625 6.13262 16.1203 2.62793 11.8125 2.62793ZM11.8125 5.83105C12.0134 5.83105 12.2097 5.89062 12.3768 6.00222C12.5438 6.11382 12.6739 6.27244 12.7508 6.45802C12.8277 6.6436 12.8478 6.84781 12.8086 7.04482C12.7694 7.24183 12.6727 7.4228 12.5307 7.56484C12.3886 7.70687 12.2077 7.8036 12.0106 7.84279C11.8136 7.88198 11.6094 7.86187 11.4238 7.785C11.2383 7.70812 11.0796 7.57795 10.968 7.41093C10.8564 7.24391 10.7969 7.04755 10.7969 6.84668C10.7969 6.57732 10.9039 6.31899 11.0943 6.12852C11.2848 5.93806 11.5431 5.83105 11.8125 5.83105ZM13.6875 14.6592H10.25C10.0842 14.6592 9.92527 14.5933 9.80806 14.4761C9.69085 14.3589 9.625 14.1999 9.625 14.0342C9.625 13.8684 9.69085 13.7094 9.80806 13.5922C9.92527 13.475 10.0842 13.4092 10.25 13.4092H11.3438V9.97168H10.7188C10.553 9.97168 10.394 9.90583 10.2768 9.78862C10.1596 9.67141 10.0938 9.51244 10.0938 9.34668C10.0938 9.18092 10.1596 9.02195 10.2768 8.90474C10.394 8.78753 10.553 8.72168 10.7188 8.72168H11.9688C12.1345 8.72168 12.2935 8.78753 12.4107 8.90474C12.5279 9.02195 12.5938 9.18092 12.5938 9.34668V13.4092H13.6875C13.8533 13.4092 14.0122 13.475 14.1294 13.5922C14.2467 13.7094 14.3125 13.8684 14.3125 14.0342C14.3125 14.1999 14.2467 14.3589 14.1294 14.4761C14.0122 14.5933 13.8533 14.6592 13.6875 14.6592Z"
                fill="#354E79"
              />
            </svg>
          )}
          <Flex flex={1} flexDirection="column" ml="16px">
            <Text fontWeight='500'>
              {withInsurance
                ? textWithInsurance.text1
                : textWithoutInsurance.text1}
            </Text>
            <Text mt={4}>
              {withInsurance
                ? textWithInsurance.text2
                : textWithoutInsurance.text2}
            </Text>
          </Flex>
        </Flex>
      </Box>
      <img
        src={doctorImg}
        style={{ alignSelf: 'flex-end' }}
        width="52px"
        height="52px"
        alt=""
      />
    </Flex>
  );
};

const ScheduleLiveTelemedicine = ({
  onSubmit,
  data,
  history,
  getState,
  gotoStep,
  client,
  session,
}) => {
  const [withInsurance, setWithInsurance] = useState(null);
  useMount(async () => {
    const state = (getState() || {});
    if (data && data.prerequisites) {
      for (let index = 0; index < data.prerequisites.length; index++) {
        const element = data.prerequisites[index];
        if ((state || {}).values.filter(x => x.id === element).length < 1) {
          gotoStep(element);
          return;
        }
      }
    }
    setWithInsurance(!!state || !!state.canLive);

    if (data.variant) {
      let reason = '';

      switch (data.variant) {
        case 1:
          reason = 'T4_T3_PRESCRIPTIONS';
          break;
        case 2:
          reason = 'SIDE_EFFECTS_SYMPTOMS';
          break;
        case 4:
          reason = 'NO_THYROIDS_MEDS';
          break;
        case 5:
          reason = 'PREGNANT';
          break;
        case 6:
          reason = 'ABNORMAL_TSH';
          break;

        default:
          break;
      }
      if (!reason) return;
      try {
        await client.mutate({
          mutation: ASYNC_SOFT_DISQUALIFY,
          variables: {
            reason,
          },
        });
      } catch (error) {}
    }
  });
  let meds = [];

  if (data.variant === 1) {
    const state = getState().values;
    if (state) {
      // console.log({ stateMeds: state });
      const hypomeds = (state || []).filter(x => x.id === 'hypomed');
      if (hypomeds && hypomeds.length) {
        meds = (hypomeds[0].values || []).map(x => x.value);
      }
    }
  }
  return (
    <Flex flex={1} flexDirection="column">
      <Flex flex={1} flexDirection="column" p="16px">
        {data.variant === 3 ? (
          <>
            <img src={Imge} alt="" style={{ width: '50%', margin: '0 auto' }} />
            <BlueHeading mt={3} mb={3}>
              Good News! Video visits are reimbursed under your plan!
            </BlueHeading>
            {/* <Text mb={3}>Schedule your live visit</Text> */}

            {/* <Text mb={3}>
              Our doctors will complete a thorough evaluation of all symptoms
              you are experiencing. They synthesize your symptoms with your labs
              results. That's the combination for happiness and healthiness.
            </Text> */}

            <InsuranceTextBubble withInsurance={withInsurance} />
          </>
        ) : data.variant === 5 ? (
          <>
            <img src={Imge} alt="" style={{ width: '50%', margin: '0 auto' }} />
            <BlueHeading mt={3} mb={3}>
              Let’s chat live!
            </BlueHeading>
            <Text mb={3}>
              Schedule a live video visit so that we can talk about your
              pregnancy.
            </Text>

            <Text mb={2}>
              We’ll match you with an experienced provider to craft the right
              treatment plan.
            </Text>
          </>
        ) : data.variant === 4 ? (
          <>
            <img src={Imge} alt="" style={{ width: '50%', margin: '0 auto' }} />
            <BlueHeading mt={3} mb={3}>
              Schedule a live video visits. We’ll match you with an experienced
              provider to craft the right treatment plan.
            </BlueHeading>
            {/* <Text mb={3}>
              Our doctors will complete a thorough evaluation of all symptoms
              you are experiencing. They synthesize your symptoms with your labs
              results. That’s the combination for happiness and healthiness.
            </Text> */}

            <InsuranceTextBubble withInsurance={withInsurance} />
          </>
        ) : data.variant === 6 ? (
          <>
            <img src={Imge} alt="" style={{ width: '50%', margin: '0 auto' }} />
            <BlueHeading mt={3} mb={3}>
              Schedule a live telemedicine visit.
            </BlueHeading>
            <Text mb={3}>
              Your last TSH test being abnormal, we need to see you in a live
              video visit.
            </Text>
            {/* <Text mb={3}>
              Our doctors will complete a thorough evaluation of all symptoms
              you are experiencing. They synthesize your symptoms with your labs
              results. That’s the combination for happiness and healthiness.
            </Text> */}

            <InsuranceTextBubble withInsurance={withInsurance} />
          </>
        ) : (
          <>
            <BlueHeading mb={3}>
              Schedule a live telemedicine visit.
            </BlueHeading>
            {data.variant === 1 && (
              <Text mb={3}>
                We can only refill {meds.join(', ')} prescription through a live
                video visit.
              </Text>
            )}
            {data.variant === 2 && (
              <Text mb={3}>
                For dose changes and new formulations, we need to see you in a
                live video visit.
              </Text>
            )}

            {/* <Text mb={3}>
              Our doctors will comple a thorough evaluation of all symptoms you
              are experiencing. They synthesize your symptoms with your labs
              results. That's the combination for happiness and healthiness.
            </Text> */}

            <InsuranceTextBubble withInsurance={withInsurance} />

            {/* <img src={Imge} alt="" style={{ width: '50%', margin: '0 auto' }} /> */}
          </>
        )}
      </Flex>
      <BoxButton className="visible">
        <Button
          variant="primary"
          onClick={async () => {
            const state = getState() || {};
            // console.log({ state });
            if (state.onFileOnsurance && state.canLive) {
              history.push({
                pathname: '/schedule/pickDoctor',
                state: {
                  forceUseOnFile: true,
                  onFileOnsurance: state.onFileOnsurance,
                },
              });
            } else {
              if (!!state.oneTimeInsurance && state.canLive) {
                const { firstName, lastName, dob } = session;

                history.push({
                  pathname: '/schedule/pickDoctor',
                  state: {
                    forceNewInsurance: true,
                    newInsurance: {
                      subscriberFullName: `${firstName} ${lastName}`,
                      subscriberDob: dob,
                      planType: state.oneTimeInsurance.type,
                    },
                    selectedProvider: {
                      value: state.oneTimeInsurance.provider,
                    },
                  },
                });
                return;
              }
              if (data.variant !== 5) {
                history.push('/schedule/pickDoctor');
              } else {
                history.push('/schedule');
              }
            }
          }}
          width={[1, 1 / 2, 1 / 3]}
        >
          Schedule a live video visit
        </Button>
      </BoxButton>
    </Flex>
  );
};

export default withApollo(withRouter(withSession(ScheduleLiveTelemedicine)));
