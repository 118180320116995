import React, { Fragment } from 'react';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import { Query, compose, withApollo } from 'react-apollo';

import { GET_CLAIM_KIT_INFORMATION, CLAIM_KIT } from '../graphql';
import palomaLogo from '../static/palomaTransparent.png';
import ClaimingForm from './claimKit/ClaimingForm';
import PleaseWait from '../components/PleaseWait';
import OoopsError from './claimKit/OoopsError';
import ClaimKitConfirmed from './claimKit/claimKitConfirmed';
import { withToastManager } from 'react-toast-notifications';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 96px;
`;

class ClaimKit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      claimingStatus: 0,
      loading: false,
      claimed: false,
    };
  }

  claimKit = async (id, values) => {
    const { client, toastManager } = this.props;
    this.setState({ loading: true });

    const variables = {
      id: id,
      firstName: values.firstName,
      lastName: values.lastName,
      street1: values.street1,
      street2: values.street2,
      city: values.city,
      state: values.state,
      zip: values.zip,
      email: values.email,
    };

    try {
      const result = await client.mutate({
        mutation: CLAIM_KIT,
        variables,
        refetchQueries: [
          {
            query: GET_CLAIM_KIT_INFORMATION,
            variables: {
              id: id,
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      if (
        result &&
        result.data &&
        result.data.claimKit &&
        result.data.claimKit.ok
      ) {
        this.setState({
          loading: false,
          claimingStatus: 1,
          claimed: true,
        });
      } else {
        this.setState({
          loading: false,
          claimingStatus: 1,
        });
        toastManager.add(result.data.error || 'Error claiming kit', {
          appearance: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
      toastManager.add('Error claiming kit', { appearance: 'error' });
    }
  };

  render() {
    const { claimingStatus, claimed } = this.state;
    const airtableId = this.props.match.params.id;

    return (
      <Fragment>
        <Container flex={1} px={3}>
          <Flex flex={1} mr={[0, 0, 0]} mb={3} flexDirection="column">
            <div style={{ textAlign: 'center' }}>
              <img
                alt=""
                src={palomaLogo}
                style={{
                  width: '300px',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  marginBottom: '24px',
                }}
              />
            </div>
            <Query
              query={GET_CLAIM_KIT_INFORMATION}
              variables={{
                id: airtableId,
              }}
            >
              {({ data, loading, error }) => {
                if (loading) {
                  return (
                    <PleaseWait
                      text="Retrieving the information"
                      style={{ marginTop: '40px', opacity: 0.85 }}
                    />
                  );
                }

                if (error) {
                  return <OoopsError />;
                }

                if (
                  data &&
                  data.getClaimKitInformation &&
                  data.getClaimKitInformation.error
                ) {
                  return (
                    <OoopsError message={data.getClaimKitInformation.error} />
                  );
                }

                if (claimed) {
                  return (
                    <ClaimKitConfirmed
                      claimInformation={data.getClaimKitInformation}
                    />
                  );
                }

                if (data.getClaimKitInformation.kitClaimed) {
                  return (
                    <ClaimKitConfirmed
                      kitPreviouslyClaimed={claimingStatus === 0}
                    />
                  );
                }

                return (
                  <ClaimingForm
                    onSubmit={this.claimKit}
                    claimInformation={data.getClaimKitInformation}
                  />
                );
              }}
            </Query>
          </Flex>
        </Container>
      </Fragment>
    );
  }
}

export default compose(withApollo, withToastManager)(ClaimKit);
