import React, { useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import PleaseWait from '../../components/PleaseWait';
import TextInput, { Input } from '../../components/TextInput';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import Select from 'react-select';
import gql from 'graphql-tag';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import { withToastManager } from 'react-toast-notifications';
import CheckboxInput from '../../components/CheckboxInput';
import ConfirmModal from '../../components/ConfirmModal';
import styled from 'styled-components';
import ModalAddEligibilityEntry from './ModalAddEligibilityEntry';
import { ADMIN_GET_B2B_COMPANIES_LIST } from '../../graphql';

const ModalInner = styled(Box)`
  max-width: 600px;
  min-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;
export const REGISTERED_LIST = gql`
  query getAdminCompanyEligibleEmployees($companyId: String!, $search: String!) {
    getAdminCompanyEligibleEmployees(companyId: $companyId, search: $search) {
      id
      firstName
      lastName
      firstNameLowerCaseNoSpace
      lastNameLowerCaseNoSpace
      dob
      employeeEmail
      employeeId
      relationshipToEmployer
      primaryFirstName
      primaryLastName
      primaryFirstNameLowerCaseNoSpace
      primaryLastNameLowerCaseNoSpace
      metadata
      isActive
      createdAt
      updatedAt
      memberId
      subscriberId
      uniqueCode
    }
  }
`;

const REMAP_ELIGIBILITY = gql`
  mutation remapB2BEligibility($detailsId: String!, $eligibilityId: String!, $autoVerify: Boolean, $employeeEmail: String!) {
    remapB2BEligibility(detailsId: $detailsId, eligibilityId: $eligibilityId, autoVerify: $autoVerify, employeeEmail: $employeeEmail) {
      ok
      customError
    }
  }
`;

const ADD_ELIGIBILITY = gql`
  mutation adminAddNewEligibility($companyId: String!, $userId: String!, $eligibilityId: String!, $activeDefault: Boolean!, $employeeEmail: String!) {
    adminAddNewEligibility(companyId: $companyId, userId: $userId, eligibilityId: $eligibilityId, activeDefault: $activeDefault, employeeEmail: $employeeEmail) {
      ok
      customError
    }
  }
`;

const RemapEligibility = ({ detailsId, eligibilityId, onClose, onCompleted, toastManager, companyId, employeeEmail, search }) => {
  return (
    <Flex flexDirection={'column'}>
      <Mutation
        mutation={REMAP_ELIGIBILITY}
        refetchQueries={search ? [{ query: REGISTERED_LIST, variables: { companyId, search } }] : []}
        onCompleted={(data) => {
          if (!!data.remapB2BEligibility) {
            if (!!data.remapB2BEligibility.ok) {
              toastManager.add('User eligibility remapped', { appearance: 'success' });
              onCompleted();
              return;
            } else {
              toastManager.add(data.remapB2BEligibility.error || 'An error occured', { appearance: 'error' });
            }
          }
        }}
      >
        {(mutate, { loading }) => {
          return (
            <Formik
              isInitialValid={({ initialValues }) => !!employeeEmail && !!detailsId && !!eligibilityId}
              initialValues={{
                detailsId,
                eligibilityId,
                autoVerify: false,
                employeeEmail: employeeEmail || '',
              }}
              onSubmit={(values) =>
                mutate({
                  variables: {
                    ...values,
                  },
                })
              }
              render={({ isValid, errors, touched, values }) => (
                <Form>
                  {!employeeEmail && (
                    <FormField width={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.employeeEmail && errors.employeeEmail}>
                      <Flex flexDirection={'column'}>
                        <Text>Enter identificator based on check method (employee email or member ID or subscriber ID or employee ID)</Text>
                        <TextInput placeholder="" type="text" name="employeeEmail" />
                      </Flex>
                    </FormField>
                  )}
                  <FormField mb={4} error={touched.autoVerify && errors.autoVerify}>
                    <label for="isActive">Set as verified by default (if checked, no confirmation email will be sent)</label>
                    <CheckboxInput
                      name="autoVerify"
                      styles={{
                        checked: {
                          Box: {
                            backgroundColor: 'rgb(54, 79, 121)',
                          },
                        },
                      }}
                    />
                  </FormField>

                  <Flex justifyContent="center" mt={4}>
                    <Button disabled={!isValid || !!loading || !values.employeeEmail} variant="pink" width={[1, 1 / 2, 1 / 3]} type="submit">
                      Confirm
                    </Button>
                    <Button ml={3} style={{ width: 'auto !important' }} disabled={!!loading} variant="outline" width={[1, 1 / 2, 1 / 3]} type="button" onClick={onClose}>
                      Cancel
                    </Button>
                  </Flex>
                </Form>
              )}
            />
          );
        }}
      </Mutation>
    </Flex>
  );
};

const CreateEligibility = ({ eligibilityId, onClose, onCompleted, toastManager, companyId, employeeEmail, user, search }) => {
  console.log({
    eligibilityId,
    user,
  });
  return (
    <Flex flexDirection={'column'}>
      <Mutation
        mutation={ADD_ELIGIBILITY}
        refetchQueries={search ? [{ query: REGISTERED_LIST, variables: { companyId, search } }] : []}
        // refetchQueries={[{ query: REGISTERED_LIST, variables: { companyId } }]}
        onCompleted={(data) => {
          if (!!data.adminAddNewEligibility) {
            if (!!data.adminAddNewEligibility.ok) {
              toastManager.add('User eligibility remapped', { appearance: 'success' });
              onCompleted();
              return;
            } else {
              toastManager.add(data.adminAddNewEligibility.error || 'An error occured', { appearance: 'error' });
            }
          }
        }}
      >
        {(mutate, { loading }) => {
          return (
            <Formik
              isInitialValid={({ initialValues }) => !!employeeEmail && !!eligibilityId}
              initialValues={{
                eligibilityId,
                activeDefault: false,
                employeeEmail: employeeEmail || '',
                userId: user.id,
                companyId: companyId,
              }}
              onSubmit={(values) =>
                mutate({
                  variables: {
                    ...values,
                  },
                })
              }
              render={({ isValid, errors, touched, values }) => (
                <Form>
                  {!employeeEmail && (
                    <FormField width={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.employeeEmail && errors.employeeEmail}>
                      <Flex flexDirection={'column'}>
                        <Text>Enter identificator based on check method (employee email or member ID or subscriber ID or employee ID)</Text>
                        <TextInput placeholder="" type="text" name="employeeEmail" />
                      </Flex>
                    </FormField>
                  )}
                  <FormField mb={4} error={touched.autoVerify && errors.autoVerify}>
                    <label for="isActive">Set as verified by default (if checked, no confirmation email will be sent)</label>
                    <CheckboxInput
                      name="activeDefault"
                      styles={{
                        checked: {
                          Box: {
                            backgroundColor: 'rgb(54, 79, 121)',
                          },
                        },
                      }}
                    />
                  </FormField>

                  <Flex justifyContent="center" mt={4}>
                    <Button disabled={!isValid || !!loading || !values.employeeEmail} variant="pink" width={[1, 1 / 2, 1 / 3]} type="submit">
                      Confirm
                    </Button>
                    <Button ml={3} style={{ width: 'auto !important' }} disabled={!!loading} variant="outline" width={[1, 1 / 2, 1 / 3]} type="button" onClick={onClose}>
                      Cancel
                    </Button>
                  </Flex>
                </Form>
              )}
            />
          );
        }}
      </Mutation>
    </Flex>
  );
};

const ModalChangeRelatedEmployee = ({ companyId, toLink, onClose, toastManager }) => {
  const [selectedLink, setSelectedLink] = useState();
  const [filter, setFilter] = useState();
  const [search, setSearch] = useState();
  const [remap, setRemap] = useState();
  const [addElibility, setAddEligibility] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(companyId);
  const CATEGORIES_COLUMNS = [
    {
      Header: 'first Name',
      accessor: 'firstName',
    },
    {
      Header: 'last Name',
      accessor: 'lastName',
    },
    {
      Header: 'DoB',
      accessor: 'dob',
    },
    {
      Header: 'employee Email',
      accessor: 'employeeEmail',
    },
    {
      Header: 'relationship To Employer',
      accessor: 'relationshipToEmployer',
    },
    {
      Header: 'Employee First Name',
      accessor: 'primaryFirstName',
    },
    {
      Header: 'Employee Last Name',
      accessor: 'primaryLastName',
    },
    {
      Header: 'Employee ID',
      accessor: 'employeeId',
    },
    {
      Header: 'Member ID',
      accessor: 'memberId',
    },
    {
      Header: 'Subscriber ID',
      accessor: 'subscriberId',
    },
    {
      Header: 'Unique Code',
      accessor: 'uniqueCode',
    },

    {
      Header: '',
      Cell: (row) => {
        return (
          <Text style={{ textAlign: 'center', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setSelectedLink(row.original)}>
            select
          </Text>
        );
      },
    },
  ];
  console.log({ selectedCompany, toLink, selectedLink });
  if (!selectedCompany) {
    console.log('no company');
    return (
      <Flex flexDirection={'column'} style={{ minHeight: '400px' }}>
        <Heading mb={4}>Select Company</Heading>
        <Query query={ADMIN_GET_B2B_COMPANIES_LIST}>
          {({ data: dataCompanies, loading, error }) => {
            if (!!loading) {
              return <PleaseWait />;
            }
            return (
              <Select
                isClearable
                classNamePrefix="region-select"
                options={(dataCompanies.getAdminB2BCompanies || []).map((x) => {
                  return {
                    label: x.friendlyName || x.name,
                    value: x.id,
                  };
                })}
                name="selectedFreeness"
                value={(dataCompanies.getAdminB2BCompanies || [])
                  .map((x) => {
                    return {
                      label: x.friendlyName || x.name,
                      value: x.id,
                    };
                  })
                  .find((x) => x.value === selectedCompany)}
                placeholder="select company"
                onChange={(option) => {
                  setSelectedCompany(option.value);
                }}
                isSearchable={true}
              />
            );
          }}
        </Query>
      </Flex>
    );
  }
  if (!!selectedLink && !!selectedCompany) {
    return (
      <Flex flexDirection={'column'}>
        <Text>Link user:</Text>
        <Flex>
          <Text>{(toLink || {}).user.firstName}</Text>
          <Text ml={2}>{(toLink || {}).user.lastName}</Text>
        </Flex>
        <Text>{(toLink || {}).user.email}</Text>
        <Text mt={3}>With eligible:</Text>
        <Flex>
          <Text>{selectedLink.firstName}</Text>
          <Text ml={2}>{selectedLink.lastName}</Text>
        </Flex>
        <Text>{selectedLink.employeeEmail}</Text>

        <Flex style={{ margin: '0 auto' }}>
          <Button
            variant="primary"
            onClick={() =>
              setRemap({
                detailsId: toLink.id,
                eligibilityId: selectedLink.id,
                employeeEmail: selectedLink.employeeEmail,
              })
            }
          >
            Continue
          </Button>
          <Button ml={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </Flex>
        {!!remap && (
          <ConfirmModal
            childrenManaged
            isOpen={!!remap}
            confirmLabel="create"
            onClose={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onBackgroundClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onEscapeKeydown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onConfirm={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ModalInner>
              {!!toLink && !!toLink.id ? (
                <RemapEligibility
                  {...remap}
                  companyId={selectedCompany}
                  onClose={() => setRemap(undefined)}
                  onCompleted={() => {
                    setRemap(undefined);
                    onClose();
                  }}
                  search={search}
                  toastManager={toastManager}
                />
              ) : (
                <CreateEligibility
                  {...remap}
                  companyId={selectedCompany}
                  onClose={() => setRemap(undefined)}
                  onCompleted={() => {
                    setRemap(undefined);
                    onClose();
                  }}
                  search={search}
                  user={toLink.user}
                  toastManager={toastManager}
                />
              )}
            </ModalInner>
          </ConfirmModal>
        )}
      </Flex>
    );
  }
  return (
    <Flex flexDirection={'column'} style={{ minHeight: '80vh' }}>
      <Text>Search (by last name, member ID, employee ID, or subscriber ID)</Text>
      <Flex mb={4}>
        <Input type="search" onChange={(e) => setFilter(e.target.value)} placeholder="search" mr={3} style={{ flex: 1 }} />
        <Button
          variant={'primary'}
          disabled={!filter}
          onClick={() => {
            setSearch(filter);
          }}
          ml={2}
        >
          Search
        </Button>
        <Button
          variant={'pink'}
          ml={3}
          onClick={() => {
            setAddEligibility(true);
          }}
        >
          Add eligibility
        </Button>
      </Flex>
      {!!search && (
        <Query
          query={REGISTERED_LIST}
          variables={{
            companyId: selectedCompany,
            search,
          }}
        >
          {({ data, loading, error }) => {
            if (!!loading) return <PleaseWait text={' '} />;
            const resultData = (data || {}).getAdminCompanyEligibleEmployees || [];
            // !!filter && filter.length > 0
            //   ? ((data || {}).getAdminCompanyEligibleEmployees || []).filter((x) => {
            //       if (
            //         (x.employeeEmail || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
            //         (x.employeeEmail || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
            //         (x.employeeFirstName || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
            //         (x.employeeLastName || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
            //         (x.firstName || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
            //         (x.lastName || '').toLowerCase().indexOf(filter.toLowerCase()) > -1
            //       ) {
            //         return true;
            //       }
            //       return false;
            //     })
            //   : (data || {}).getAdminCompanyEligibleEmployees || [];
            return (
              <ReactTable
                data={resultData || []}
                columns={CATEGORIES_COLUMNS}
                defaultPageSize={resultData && resultData.length > 3 ? Math.min(resultData.length, 100) : 3}
                pageSize={resultData && resultData.length > 3 ? resultData.length : 3}
              />
            );
          }}
        </Query>
      )}
      {!!addElibility && (
        <ConfirmModal
          childrenManaged
          isOpen={!!addElibility}
          confirmLabel="create"
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onBackgroundClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onEscapeKeydown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onConfirm={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ModalInner
            style={{
              height: '70vh',
              maxHeight: '70vh',
            }}
          >
            <ModalAddEligibilityEntry
              companyId={companyId}
              onClose={() => setAddEligibility(false)}
              onCompleted={() => {
                setAddEligibility(false);
              }}
              toastManager={toastManager}
            />
          </ModalInner>
        </ConfirmModal>
      )}
    </Flex>
  );
};

export default withToastManager(ModalChangeRelatedEmployee);
