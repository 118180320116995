import React from 'react';
import styled from 'styled-components';

const InnerBackground = styled.div`
  @media screen and (max-width: 440px) {
    display: none;
  }
`;
const BackgroundShape = props => (
  <InnerBackground>
    <svg
      data-name="BackgroundShape"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 294.27 306.38"
      {...props}
    >
      <path
        d="M148.24,0a231.1,231.1,0,0,1,23.2,2.09c6.23,1.1,12.09,3.48,18.33,4.7,4.1.77,7.57,2.65,11.17,4.64C211.72,17.49,220,26,228.06,35.28c11.16,13.57,22.17,27.52,31.5,42.43a360.57,360.57,0,0,1,27,54.92c3.89,10.45,7.21,21.3,7.67,32.5-.08,1.35-.27,2.67-.39,4-1.3,17.49-5.8,35.08-13.95,50.67-11.87,23.26-30.14,43.23-49.41,60.59-6.94,6.32-14.16,12.13-22.56,16.43a92.52,92.52,0,0,1-39.72,9.55c-18.73-.42-35.18-6.9-52.17-14.09-38.42-17.88-69.95-49.15-88.78-87-2.16-4.54-4-9.19-6.62-13.52C12.88,177.88,4.3,166,1.33,149.92.62,144.4-.23,138.65.06,133.11A114.1,114.1,0,0,1,12.22,89c10-20.56,24.88-38.86,42-53.86C72.09,19.6,94,7,117.44,2.75A223.93,223.93,0,0,1,148.24,0Z"
        fill={props.fill}
      />
    </svg>
  </InnerBackground>
);

BackgroundShape.defaultProps = {
  fill: '#6B7F9D',
};

export default BackgroundShape;
