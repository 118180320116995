import React, { useEffect } from 'react';
import { Query } from 'react-apollo';
import { GET_CLINICAL_PROVIDER } from '../../graphql';
import { useState } from 'react';
import { ReactAddToCalendar } from './ReactAddToCalendar';
import SpinLoader from '../SpinLoader';
import momentTimezone from 'moment-timezone';

export const AppointmentAddToCalendar = ({ appointment }) => {
  const [event, setEvent] = useState();

useEffect(()=>{
  const startDateTime = momentTimezone.tz(`${appointment.date} ${appointment.startTime}`, 'MM/DD/YYYY HH:mm', appointment.timezoneName)
  const endDateTime = startDateTime.clone().add(appointment.duration, 'minutes')
  console.log({startDateTime, date:appointment.date, time: appointment.startTime})
  console.log(startDateTime.format())
  let ev={
    title: `Your ${
      appointment.isMeno ||(appointment.appointmentTypeId &&(
      appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID.toString() ||
      appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID.toString()))
        ? 'menopause'
        : 'thyroid'
    } appointment with Paloma health`,
    description: `You may enter the waiting room 15 minutes before your appointment`,
    location: `https://app.palomahealth.com/visit-visio`,
    startTime: startDateTime.format(),//'2024-09-16T20:15:00-04:00',
    endTime: endDateTime.format(),
  }
  setEvent(ev)
},[])
if(!event || (!!window && !!window.ReactNativeWebView)) return null
return <ReactAddToCalendar event={event} />;
//   return (
//     <Query
//       query={GET_CLINICAL_PROVIDER}
//       variables={{
//         athenaProviderId: appointment.provider.id.toString(),
//       }}
//       onCompleted={(dta) => {
//         let ev = {
//           title: `Your ${
//             appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID.toString() ||
//             appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID.toString()
//               ? 'menopause'
//               : 'thyroid'
//           } appointment with Paloma health`,
//           description: `Your ${
//             appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID.toString() ||
//             appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID.toString()
//               ? 'menopause'
//               : 'thyroid'
//           } appointment with ${dta.getClinicalProvider.patientFacingName}`,
//           location: `https://app.palomahealth.com/visit-room/${appointment.provider.id.toString()}`,
//           startTime: '2024-09-16T20:15:00-04:00',
//           endTime: '2024-09-16T21:45:00-04:00',
//         };
// console.log({event})
//         setEvent(ev);
//       }}
//     >
//       {({ loading, data, error }) => {
//         if (loading || (!data && !data.getClinicalProvider)) {
//           return (null
//           );
//         }

//         if (error || !event) {
//           return <div />;
//         }

//       }}
//     </Query>
//   );
};
