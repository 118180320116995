export const QUESTIONS = [
  {
    id: 'OverallWellBeing',
    title: 'How would you describe your overall well-being?',
    type: 'PICKONE',
    options: [
      {
        text: 'Great',
        value: 'Great',
      },
      {
        text: 'Good',
        value: 'Good',
      },
      {
        text: 'OK',
        value: 'OK',
      },
      {
        text: 'Not so good',
        value: 'Not so good',
      },
      {
        text: 'Bad',
        value: 'Bad',
      },
    ],
    cantBypass: true,
    nextTitle: `Continue`,
  },
  {
    id: 'AgreeThyroidConditionsAssessesSymptoms',
    title: 'How well would you agree with this statement: “I understand my thyroid condition and can assess my symptoms well”?',
    type: 'PICKONE',
    options: [
      {
        text: 'I fully agree with the statement',
        value: 'I fully agree with the statement',
      },
      {
        text: 'I somewhat agree with the statement',
        value: 'I somewhat agree with the statement',
      },
      {
        text: 'I rather disagree with the statement',
        value: 'I rather disagree with the statement',
      },
      {
        text: 'I fully disagree with the statement',
        value: 'I fully disagree with the statement',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'PalomaExpectations',
    title: 'What are your expectations from Paloma Health?',
    type: 'PICKMULTIPLEOTHER',
    options: [
      {
        text:"To find a good thyroid-focused provider",// 'To find a good thyroid doctor',
        value: 'To find a good thyroid doctor',
      },
      {
        text: 'To find the appropriate treatment for my hypothyroidism',
        value: 'To find the appropriate treatment for my hypothyroidism',
      },
      {
        text: 'To monitor my thyroid function',
        value: 'To monitor my thyroid function',
      },
      {
        text: 'To figure out how to adjust my nutrition + lifestyle',
        value: 'To figure out how to adjust my nutrition + lifestyle',
      },
      {
        text: 'To get vetted information on hypothyroidism',
        value: 'To get vetted information on hypothyroidism',
      },
      {
        text: 'To track and understand my symptoms',
        value: 'To track and understand my symptoms',
      },
      {
        text: 'To improve my symptoms',
        value: 'To improve my symptoms',
      },
      {
        text: 'Other',
        value: 'Other',
        hasDetails: true,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'DoctorsVisitsPast12MonthsRelatedHealthConcerns',
    title: 'How often did you visit your doctor in the past 12 months related to any health concern you might have had?',
    type: 'PICKONE',
    options: [
      {
        text: 'I did not visit my doctor in the past twelve months',
        value: 'I did not visit my doctor in the past twelve months',
      },
      {
        text: 'Once',
        value: 'Once',
      },
      {
        text: 'Twice',
        value: 'Twice',
      },
      {
        text: '3-5 times',
        value: '3-5 times',
      },
      {
        text: '6 times or more',
        value: '6 times or more',
      },
    ],
    nextTitle: `Continue`,
  },
  {
    id: 'TypeDoctorCurrentlyManagesCare',
    title: 'What type of doctor currently manages your thyroid care?',
    type: 'PICKONEOTHER',
    options: [
      {
        text: 'Primary care physician',
        value: 'Primary care physician',
      },
      {
        text: 'Endocrinologist',
        value: 'Endocrinologist',
      },
      {
        text: 'Nurse practitioner',
        value: 'Nurse practitioner',
      },
      {
        text: 'OB/GYN',
        value: 'OB/GYN',
      },
      {
        text: 'Naturopath',
        value: 'Naturopath',
      },
      {
        text: 'None',
        value: 'None',
      },
      {
        text: 'Other',
        value: 'Other',
        hasDetails: true,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'ProviderVisitsPast12Months',
    title: 'How often did you visit the provider that manages your thyroid in the past 12 months?',
    type: 'PICKONE',
    options: [
      {
        text: 'I did not visit my provider in the past twelve months',
        value: 'I did not visit my provider in the past twelve months',
      },
      {
        text: 'Once',
        value: 'Once',
      },
      {
        text: 'Twice',
        value: 'Twice',
      },
      {
        text: '3-5 times',
        value: '3-5 times',
      },
      {
        text: '6 times or more',
        value: '6 times or more',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'WhyVisitThyroidProvider',
    title: 'Why did you visit your thyroid provider?',
    type: 'PICKMULTIPLEOTHER',
    options: [
      {
        text: 'To refill my medications',
        value: 'To refill my medications',
      },
      {
        text: 'To do blood tests',
        value: 'To do blood tests',
      },
      {
        text: 'To adjust my medication dose',
        value: 'To adjust my medication dose',
      },
      {
        text: 'To figure out my symptoms',
        value: 'To figure out my symptoms',
      },
      {
        text: 'Other',
        value: 'Other',
        hasDetails: true,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'HowManyThyroidMedsAdjustments',
    title: 'How many times did you adjust your thyroid medication (either dose or brand) within the past 12 months?',
    type: 'PICKONE',
    options: [
      {
        text: 'I did not adjust my thyroid medication in the past 12 months',
        value: 'I did not adjust my thyroid medication in the past 12 months',
      },
      {
        text: 'Once',
        value: 'Once',
      },
      {
        text: 'Twice',
        value: 'Twice',
      },
      {
        text: '3-5 times',
        value: '3-5 times',
      },
      {
        text: '6 times or more',
        value: '6 times or more',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'HowManyLabTests',
    title: 'How many times did you test your thyroid levels within the past 12 months?',
    type: 'PICKONE',
    options: [
      {
        text: `I didn't test my thyroid levels in the past 12 months`,
        value: `I didn't test my thyroid levels in the past 12 months`
      },
      {
        text: 'Once',
        value: 'Once',
      },
      {
        text: 'Twice',
        value: 'Twice',
      },
      {
        text: '3-5 times',
        value: '3-5 times',
      },
      {
        text: '6 times or more',
        value: '6 times or more',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'HowOftenSkipMedication',
    title: 'How often do you skip taking your thyroid medication?',
    type: 'PICKONE',
    options: [
      {
        text: `Few times a week`,
        value: `Few times a week`
      },
      {
        text: 'Once a week',
        value: 'Once a week',
      },
      {
        text: 'Once every two weeks',
        value: 'Once every two weeks',
      },
      {
        text: 'Once a month',
        value: 'Once a month',
      },
      {
        text: 'Once every few months',
        value: 'Once every few months',
      },
      {
        text: 'Never or almost never',
        value: 'Never or almost never',
      },
      {
        text: 'I do not take thyroid medication',
        value: 'I do not take thyroid medication',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'HowHappyLastProvider',
    title: 'How happy were you with your last provider interaction?',
    type: 'PICKONE',
    options: [
      {
        text: 'Completely satisfied',
        value: 'Completely satisfied',
        score: 4,
      },
      {
        text: 'Somewhat satisfied',
        value: 'Somewhat satisfied',
        score: 3,
      },
      {
        text: 'Not satisfied, not unsatisfied',
        value: 'Not satisfied, not unsatisfied',
        score: 2,
      },
      {
        text: 'Somewhat unsatisfied',
        value: 'Somewhat unsatisfied',
        score: 1,
      },
      {
        text: 'Completely not satisfied',
        value: 'Completely not satisfied',
        score: 0,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'EmergencyMedicalHelp',
    title: 'In the past 12 months, have you had to seek emergency medical help because of your thyroid?',
    type: 'PICKONEOTHER',
    options: [
      {
        text: 'No',
        value: 'No',
      },
      {
        text: 'I visited ED/ER, but I do not think it was because of my thyroid',
        value: 'I visited ED/ER, but I do not think it was because of my thyroid',
      },
      {
        text: 'Yes, once',
        value: 'Yes, once',
      },
      {
        text: 'Yes, more than once',
        value: 'Yes, more than once',
      },
      {
        text: 'Yes, more than three times',
        value: 'Yes, more than three times',
      },
      {
        text: 'Yes, more than five times',
        value: 'Yes, more than five times',
      },
      {
        text: 'Other',
        value: 'Other',
        hasDetails: true,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'SensitiveCold',
    title: 'In the past 4 weeks have you been sensitive to cold?',
    type: 'PICKONE',
    chartTitle: 'Cold sensitivity',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
        score: 4,
      },
      {
        text: 'A bit',
        value: 'A bit',
        score: 3,
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
        score: 2,
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
        score: 1,
      },
      {
        text: 'Fully',
        value: 'Fully',
        score: 0,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  // {
  //   id: 'SwollenHandsFeet',
  //   title: 'In the past 4 weeks have you had swollen hands or feet?',
  //   type: 'PICKONE',
  //   chartTitle: 'Swollen hands',
  //   options: [
  //     {
  //       text: 'Not at all',
  //       value: 'Not at all',
  //       score: 4,
  //     },
  //     {
  //       text: 'A bit',
  //       value: 'A bit',
  //       score: 3,
  //     },
  //     {
  //       text: 'Somewhat',
  //       value: 'Somewhat',
  //       score: 2,
  //     },
  //     {
  //       text: 'Quite a lot',
  //       value: 'Quite a lot',
  //       score: 1,
  //     },
  //     {
  //       text: 'Fully',
  //       value: 'Fully',
  //       score: 0,
  //     },
  //   ],
  //   nextTitle: `Continue`,
  //   cantBypass: true,
  // },
  {
    id: 'DrySkin',
    title: 'In the past 4 weeks have you had dry skin and/or brittle nails?',
    type: 'PICKONE',
    chartTitle: 'Dry skin',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
        score: 4,
      },
      {
        text: 'A bit',
        value: 'A bit',
        score: 3,
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
        score: 2,
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
        score: 1,
      },
      {
        text: 'Fully',
        value: 'Fully',
        score: 0,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'Tired',
    title: 'In the past four weeks have you been tired?',
    type: 'PICKONE',
    chartTitle: 'Tiredness',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
        score: 4,
      },
      {
        text: 'A bit',
        value: 'A bit',
        score: 3,
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
        score: 2,
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
        score: 1,
      },
      {
        text: 'Fully',
        value: 'Fully',
        score: 0,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'DifficultiesMotivated',
    title: 'In the past four weeks did you have difficulties being motivated to do things?',
    type: 'PICKONE',
    chartTitle: 'Motivation',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
        score: 4,
      },
      {
        text: 'A bit',
        value: 'A bit',
        score: 3,
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
        score: 2,
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
        score: 1,
      },
      {
        text: 'Fully',
        value: 'Fully',
        score: 0,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'Energetic',
    title: 'In the past four weeks did you feel energetic?',
    type: 'PICKONE',
    chartTitle: 'Energy',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
        score: 4,
      },
      {
        text: 'A bit',
        value: 'A bit',
        score: 3,
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
        score: 2,
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
        score: 1,
      },
      {
        text: 'Fully',
        value: 'Fully',
        score: 0,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'DifficultiesRemembering',
    title: 'In the past four weeks have you had difficulties remembering?',
    type: 'PICKONE',
    chartTitle: 'Memory',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
        score: 4,
      },
      {
        text: 'A bit',
        value: 'A bit',
        score: 3,
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
        score: 2,
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
        score: 1,
      },
      {
        text: 'Fully',
        value: 'Fully',
        score: 0,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'SlowThinking',
    title: 'In the past four weeks have you had slow or unclear thinking?',
    type: 'PICKONE',
    chartTitle: 'Slow thinking',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
        score: 4,
      },
      {
        text: 'A bit',
        value: 'A bit',
        score: 3,
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
        score: 2,
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
        score: 1,
      },
      {
        text: 'Fully',
        value: 'Fully',
        score: 0,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'AfraidAnxious',
    title: 'In the past four weeks have you felt afraid or anxious?',
    type: 'PICKONE',
    chartTitle: 'Anxiousness',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
        score: 4,
      },
      {
        text: 'A bit',
        value: 'A bit',
        score: 3,
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
        score: 2,
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
        score: 1,
      },
      {
        text: 'Fully',
        value: 'Fully',
        score: 0,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'DifficultiesManagingDailyLife',
    title: 'In the past four weeks, did you feel you had difficulties managing your daily life?',
    type: 'PICKONE',
    chartTitle: 'Difficulties managing',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
        score: 4,
      },
      {
        text: 'A bit',
        value: 'A bit',
        score: 3,
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
        score: 2,
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
        score: 1,
      },
      {
        text: 'Fully',
        value: 'Fully',
        score: 0,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'EverythingTakesLonger',
    title: 'In the past four weeks, did you feel everything takes longer to do?',
    type: 'PICKONE',
    chartTitle: 'Everything takes longer',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
        score: 4,
      },
      {
        text: 'A bit',
        value: 'A bit',
        score: 3,
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
        score: 2,
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
        score: 1,
      },
      {
        text: 'Fully',
        value: 'Fully',
        score: 0,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'ThyroidNegativeImpactQualityOfLife',
    title: 'In the past four weeks, has your thyroid had a negative impact on your quality of life?',
    type: 'PICKONE',
    chartTitle: 'Negative impact',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
        score: 4,
      },
      {
        text: 'A bit',
        value: 'A bit',
        score: 3,
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
        score: 2,
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
        score: 1,
      },
      {
        text: 'Fully',
        value: 'Fully',
        score: 0,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
];
