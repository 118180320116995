import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Toolbox } from './ToolBox';
import { Item } from './Item';
import Modal from 'styled-react-modal';
import Select from 'react-select';
import * as Yup from 'yup';
import { SpecialModalBackground } from '../../adminReorderPhysicianConvo/shared';
import { Form, Formik } from 'formik';
import FormField from '../../../components/FormField';
import { TextAreaWithLabel, TextInputWithLabel } from '../../../components/TextInput';
import { PreviewHcForm } from './Preview';
import withSession from '../../../lib/withSession';
import { parseStringify } from '../../../utils/helpers';
import { Mutation, Query } from 'react-apollo';
import { GET_HEALTHCOACH_FORM_TARGETS, GET_HEALTHCOACH_FORM_TEMPLATES } from '../../../graphql';
import { withRouter } from 'react-router';
import PleaseWait from '../../../components/PleaseWait';
import { GetAvailables } from './common/availableToolboxItems';
import { withToastManager } from 'react-toast-notifications';
import gql from 'graphql-tag';

const UPSERT_FORM_TEMPLATE = gql`
  mutation upsertHealthCoachFormTemplate(
    $id: String
    $tree: String!
    $name: String!
    $description: String
    $version: String!
    $target: String!
    $isActive: Boolean
    $isDeleted: Boolean
    $isDeprecated: Boolean
    $basedOnTemplate: String
  ) {
    upsertHealthCoachFormTemplate(
      id: $id
      tree: $tree
      name: $name
      description: $description
      version: $version
      target: $target
      isActive: $isActive
      isDeleted: $isDeleted
      isDeprecated: $isDeprecated
      basedOnTemplate: $basedOnTemplate
    ) {
      ok
    }
  }
`;
const BoxRight = styled(Flex)`
  width: 33vw;
  max-width: 400px;
  justify-content: flex-end;
  padding: 0 8px;
`;

const Wrapper = styled(Flex)`
  overflow: auto;
  width: 800px;
  padding-right: 20px;
  margin-right: 20px;
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #6c89b7;
    border-radius: 5px;
  }
  &::-webkit-scrollbar {
    width: 7px;
    border: 1px solid transparent;
  }
`;

export const EditModalButtons = ({ save, close, isValid }) => {
  return (
    <Flex mt={4} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
      <Flex flex={1} alignItems={'center'} justifyContent={'center'}>
        {!!close && (
          <Button
            variant={'outline'}
            style={{
              alignSelf: 'center',
              marginRight: '12px',
              width: 'auto',
            }}
            onClick={close}
          >
            Cancel
          </Button>
        )}
        <Button
          variant={'primary'}
          style={{
            alignSelf: 'center',
          }}
          disabled={!isValid}
          onClick={save}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

const schema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Question must be greater than 1 character')
    .required('Required'),
});

const HealthCoachFormBuilder = ({ session, history, toastManager, initialMeta = null, initialContent = [] }) => {
  const [tree, setTree] = useState(initialContent);
  const [initialTree, setInitialTree] = useState(initialContent);
  // const [tree, setTree] = useState([{ id: "Header_1682965375431", key: "Header", name: "Title", icon: "fa fa-header", order: 1, params: { ctrlProps: {}, question: "Very 1st template" } }, { id: "Paragraph_1682965387682", key: "Paragraph", name: "Paragraph", icon: "fa fa-paragraph", order: 3, params: { ctrlProps: {}, question: "this is the very first template created" } }, { id: "Label_1682965412755", key: "Label", name: "Section", icon: "fa fa-font", order: 2, params: { ctrlProps: {}, question: "1st section" } }, { id: "TextInput_1682965425326", key: "TextInput", name: "Text Input", icon: "fa fa-font", order: 9, params: { ctrlProps: { required: true }, question: "What is the 1st question?", description: "This question is the 1st one and must be inherited", researchId: "firstQuestion", mustBeInherited: true } }, { id: "Email_1682965477488", key: "Email", name: "Email", icon: "fa fa-at", order: 15, params: { ctrlProps: { type: "email", placeholder: "email@email.com", required: true }, question: "patient fav email" } }, { id: "Rating_1682965495572", key: "Rating", name: "Rating", icon: "fa fa-star", order: 12, params: { ctrlProps: {}, question: "How would they rate their interaction" } }]);
  // const [initialTree, setInitialTree] = useState([{ id: "Header_1682965375431", key: "Header", name: "Title", icon: "fa fa-header", order: 1, params: { ctrlProps: {}, question: "Very 1st template" } }, { id: "Paragraph_1682965387682", key: "Paragraph", name: "Paragraph", icon: "fa fa-paragraph", order: 3, params: { ctrlProps: {}, question: "this is the very first template created" } }, { id: "Label_1682965412755", key: "Label", name: "Section", icon: "fa fa-font", order: 2, params: { ctrlProps: {}, question: "1st section" } }, { id: "TextInput_1682965425326", key: "TextInput", name: "Text Input", icon: "fa fa-font", order: 9, params: { ctrlProps: { required: true }, question: "What is the 1st question?", description: "This question is the 1st one and must be inherited", researchId: "firstQuestion", mustBeInherited: true } }, { id: "Email_1682965477488", key: "Email", name: "Email", icon: "fa fa-at", order: 15, params: { ctrlProps: { type: "email", placeholder: "email@email.com", required: true }, question: "patient fav email" } }, { id: "Rating_1682965495572", key: "Rating", name: "Rating", icon: "fa fa-star", order: 12, params: { ctrlProps: {}, question: "How would they rate their interaction" } }]);
  const [hasChanged, setHasChanged] = useState(false);
  const [availables, setAvailables] = useState(GetAvailables());
  const [showPreview, setShowPreview] = useState(false);
  const [duplicatesResearchId, setDuplicatesResearchId] = useState();
  const [meta, setMeta] = useState(initialMeta);
  const stateRef = useRef();
  stateRef.current = hasChanged;

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      console.log('preventEsc', stateRef.current);
      event.stopPropagation();
    }
  }, []);

  useEffect(
    () => {
      document.addEventListener('keydown', escFunction, true);

      return () => {
        document.removeEventListener('keydown', escFunction, true);
      };
    },
    [escFunction]
  );

  useEffect(
    () => {
      if ((initialTree || []).length !== (tree || []).length) {
        setHasChanged(true);
        return;
      }
      for (let index = 0; index < initialTree.length; index++) {
        const element = (initialTree || [])[index];
        if (index > (tree || []).length) {
          setHasChanged(true);
          return;
        }
        if (element.id !== (tree || [])[index].id) {
          setHasChanged(true);
          return;
        }
      }

      setHasChanged(false);
    },
    [tree]
  );
  const handleOnDragEnd = (result) => {
    if (!result.source.droppableId) return;
    if (result.source.droppableId === 'characters' && (!result.destination || result.destination.droppableId === 'availables')) {
      return;
    }
    if (!result.destination || !result.destination.droppableId) return;
    if (result.source.droppableId === result.destination.droppableId) {
      if (result.source.droppableId === 'availables') return;
      console.log('12', { result });
      const items = Array.from(tree);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setTree(items);
    } else if (result.source && result.destination) {
      console.log('13');

      const items = Array.from(tree);
      const destClone = Array.from(availables);
      const [el] = destClone.splice(result.source.index, 1);
      const newOne = JSON.parse(JSON.stringify(el));
      newOne.id = `${newOne.id}_${new Date().getTime()}`;

      if (newOne.key === 'NumberInput') {
        newOne.params = {
          ctrlProps: {
            type: 'number',
          },
        };
      } else if (newOne.key === 'Email') {
        newOne.params = {
          ctrlProps: {
            type: 'email',
            placeholder: 'email@email.com',
          },
        };
      } else if (newOne.key === 'Date') {
        newOne.params = {
          ctrlProps: {
            type: 'date',
            placeholder: 'MM/DD/YYYY',
          },
        };
      } else if (newOne.key === 'Range') {
        newOne.params = {
          ctrlProps: {
            type: 'range',
            min: 0,
            max: 100,
          },
        };
      } else {
        newOne.params = {
          // researchId: '',
          ctrlProps: {},
        };
      }
      items.splice(result.destination.index, 0, newOne);
      destClone.splice(result.destination.index, 0, el);
      setAvailables(destClone);

      setTree(items);
    }
  };
  // const resTree = parseStringify(tree);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Flex flexDirection={'column'} flex={1} p={4}>
        <Flex mb={2} style={{ borderBottom: '1px solid #344f79', paddingBottom: '12px' }}>
          <Heading flex={1}>
            {(meta || {}).name}
            {!!hasChanged ? '*' : ''}
          </Heading>
          <Mutation
            mutation={UPSERT_FORM_TEMPLATE}
            refetchQueries={[{ query: GET_HEALTHCOACH_FORM_TEMPLATES }]}
            awaitRefetchQueries={true}
            onCompleted={(e) => {
              if (!!e.upsertHealthCoachFormTemplate.ok) {
                toastManager.add(`Form created successfully`, {
                  appearance: 'success',
                  autoDismissTimeout: 10000,
                });
                history.push('/hc-template-list');
              } else {
                toastManager.add('An error occured', {
                  appearance: 'error',
                  autoDismissTimeout: 10000,
                });
              }
            }}
            onError={() => {
              toastManager.add('An error occured', {
                appearance: 'error',
                autoDismissTimeout: 10000,
              });
            }}
          >
            {(mutate, { loading }) => {
              if (!!loading) {
                return <PleaseWait />;
              }
              return (
                <Button
                  variant={'primary'}
                  style={{
                    padding: '4px 12px',
                    alignSelf: 'center',
                    marginRight: '12px',
                  }}
                  onClick={() => {
                    const dups = [];

                    for (let index = 0; index < tree.length; index++) {
                      const element = tree[index];
                      if (!element || !element.params || !element.params.researchId) continue;
                      const ind = dups.findIndex((xx) => xx.researchId === element.params.researchId);

                      if (ind < 0) {
                        dups.push({
                          researchId: element.params.researchId,
                          foundIn: [element.params.question],
                        });
                      } else {
                        dups[ind].foundIn.push(element.params.question);
                      }
                    }
                    if (dups && dups.filter((xx) => (xx.foundIn || []).length > 1).length > 0) {
                      console.log({
                        dups: dups.filter((xx) => (xx.foundIn || []).length > 1),
                      });
                      setDuplicatesResearchId(dups.filter((xx) => (xx.foundIn || []).length > 1));
                      return;
                    }
                    let params = {
                      id: initialMeta && initialMeta.id ? initialMeta.id : null,
                      tree: parseStringify(tree),
                      user: session.id,
                      name: meta.name,
                      description: meta.description,
                      version: meta.version,
                      target: meta.target,
                    };
                    console.log({ params });
                    params = {
                      id: initialMeta && initialMeta.id ? initialMeta.id : null,
                      tree: JSON.stringify(tree),
                      user: session.id,
                      name: meta.name,
                      description: meta.description,
                      version: meta.version.toString(),
                      target: meta.target,
                    };

                    mutate({
                      variables: params,
                    });
                  }}
                >
                  Save
                </Button>
              );
            }}
          </Mutation>

          <Button
            variant={'pink'}
            style={{
              padding: '4px 12px',
              alignSelf: 'center',
              marginRight: '12px',
            }}
            onClick={() => {
              setShowPreview(true);
            }}
          >
            Show preview
          </Button>
        </Flex>
        <Text>Target: {(meta || {}).targetName}</Text>
        <Flex mb={4}>
          <Text fontSize={'12px'} style={{ opacity: 0.5 }} mr={4}>
            Version: 1
          </Text>
          <Text fontSize={'12px'} style={{ opacity: 0.5 }}>
            Created By: {session.firstName} ({session.email})
          </Text>
        </Flex>
        <Flex flex={1} style={{ height: '100%', minHeight: '80vh', width: '100%' }}>
          <Flex flexDirection={'column'} flex={1} style={{ height: '100%', minHeight: '80vh' }}>
            <Text fontWeight={300} mb={4} style={{ whiteSpace: 'pre-line' }}>
              {(meta || {}).description}
            </Text>
            <Flex flex={1}>
              <Droppable droppableId="characters">
                {(provided2, snapshot) => {
                  return (
                    <div
                      ref={provided2.innerRef}
                      {...provided2.droppableProps}
                      id={'toto'}
                      style={{ minHeight: '100%', width: '100%', padding: '12px', border: snapshot.isDraggingOver ? '1px dashed rgb(134, 180, 134)' : '1px solid #d7d7d7' }}
                    >
                      {!tree ||
                        (tree.length < 1 && (
                          <Flex style={{ width: '100%', height: '100%', background: '#f7f7f7' }} justifyContent={'center'} alignItems={'center'} flex={1} id="rtt">
                            <Text fontWeight={500} fontSize={'20px'} style={{ opacity: 0.3 }}>
                              Drag here to add
                            </Text>
                          </Flex>
                        ))}
                      {tree.map((x, i) => {
                        return (
                          <Draggable key={x.id} draggableId={x.id} index={i}>
                            {(provided, snapshot) => (
                              <div key={i} ref={provided.innerRef} isDragging={snapshot.isDragging} {...provided.dragHandleProps} {...provided.draggableProps}>
                                <Flex flexDirection={'row'} mb={2}>
                                  <Item
                                    treeItem={x}
                                    saveProperties={(p) => {
                                      const ind = tree.findIndex((xx) => xx.id === x.id);
                                      const tr = JSON.parse(JSON.stringify(tree));
                                      tr[ind].params = p;
                                      setTree(tr);
                                    }}
                                    onDelete={() => {
                                      const t = JSON.parse(JSON.stringify(tree));
                                      if (i > -1) {
                                        t.splice(i, 1);
                                      }
                                      setTree(t);
                                    }}
                                  />
                                </Flex>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  );
                }}
              </Droppable>
            </Flex>
          </Flex>
          <BoxRight>
            <Flex style={{ width: '100%' }} flexDirection={'column'}>
              <Text fontWeight={500} fontSize={'18px'} ml={'20px'}>
                ToolBox
              </Text>
              <Flex id="ttxx">
                <Toolbox availables={availables} />
              </Flex>
            </Flex>
          </BoxRight>
        </Flex>

        {(!meta || !meta.name) && (
          <Modal isOpen={true} onBackgroundClick={() => {}} onEscapeKeydown={() => {}}>
            <SpecialModalBackground>
              <Box
                style={{
                  margin: '0 auto',
                  alignSelf: 'center',
                  borderRadius: '20px',
                  position: 'relative',
                  backgroundColor: 'white',
                  padding: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  maxWidth: '95vw',
                  minWidth: 'initial',
                  maxHeight: '95vh',
                }}
              >
                <Flex flex={1} flexDirection={'column'} width={'600px'}>
                  <Flex>
                    <Heading textAlign={'center'} mb={4} flex={1}>
                      New Health Coach Form
                    </Heading>
                  </Flex>
                  <Query query={GET_HEALTHCOACH_FORM_TARGETS}>
                    {({ data, loading, error }) => {
                      if (!!loading) {
                        return <PleaseWait />;
                      }
                      if (!data || !data.getHealthCoachFormTargets || !!error) {
                        return <Text textAlign={'center'}>An error occured</Text>;
                      }
                      if (data.getHealthCoachFormTargets.length < 1) {
                        return (
                          <Flex flexDirection={'column'}>
                            <Text textAlign={'center'}>You must create form targets first</Text>
                            <Button
                              variant="primary"
                              onClick={() => {
                                history.push('/hc-template-list');
                              }}
                            >
                              Bring me there
                            </Button>
                          </Flex>
                        );
                      }
                      return (
                        <Formik
                          initialValues={{ name: (meta || {}).name, event: null, description: '' }}
                          isInitialValid={() => {
                            return !!(meta || {}).name;
                          }}
                          validationSchema={schema}
                          onSubmit={(values, actions) =>
                            setMeta({
                              ...values,
                              target: values.event.value,
                              targetName: values.event.label,
                              createdBy: `${session.firstName} (${session.email})`,
                              createdByUser: session.id,
                              version: 1,
                            })
                          }
                          render={({ touched, errors, isValid, values, submitForm, setFieldValue }) => (
                            <Form>
                              <FormField mb={3} error={touched.name && errors.name}>
                                <TextInputWithLabel placeholder="Form name *" name="name" id="input_name" />
                              </FormField>
                              <FormField mb={3} error={touched.description && errors.description}>
                                <TextAreaWithLabel
                                  value={values.description}
                                  onChange={(e) => {
                                    setFieldValue('description', e.target.value);
                                  }}
                                  placeholder="description"
                                  name="description"
                                  id="input_description"
                                />
                              </FormField>
                              <div style={{ maxWidth: '100%', alignSelf: 'center', width: '100%' }}>
                                <Select
                                  classNamePrefix="region-select"
                                  options={data.getHealthCoachFormTargets.map((x) => {
                                    return {
                                      label: x.target,
                                      value: x.id,
                                    };
                                  })}
                                  style={{
                                    minWidth: '400px',
                                  }}
                                  placeholder="Template for *"
                                  value={values.event}
                                  onChange={(option) => {
                                    setFieldValue('event', option);
                                  }}
                                  noOptionsMessage={() => (
                                    <div style={{ padding: '12px 24px' }}>
                                      <Text textAlign="left" mb={2} fontSize="16px" fontWeight="400">
                                        No options set
                                      </Text>
                                    </div>
                                  )}
                                  theme={(base) => ({
                                    ...base,
                                    colors: {
                                      ...base.colors,
                                      primary: '#364f79',
                                      primary50: '#dae6fa',
                                    },
                                  })}
                                />
                              </div>
                              <Text fontSize={'12px'} style={{ opacity: 0.5 }} mt={4}>
                                Version: 1
                              </Text>
                              <Text fontSize={'12px'} style={{ opacity: 0.5 }}>
                                Created By: {session.firstName} ({session.email})
                              </Text>
                              <EditModalButtons save={submitForm} isValid={isValid && (values.event || {} || {}).value} />
                            </Form>
                          )}
                        />
                      );
                    }}
                  </Query>
                </Flex>
              </Box>
            </SpecialModalBackground>
          </Modal>
        )}
        {duplicatesResearchId &&
          duplicatesResearchId.length > 0 && (
            <Modal
              isOpen={true}
              onBackgroundClick={() => {
                setShowPreview(false);
              }}
              onEscapeKeydown={() => {
                setShowPreview(false);
              }}
            >
              <SpecialModalBackground
                onClick={() => {
                  setShowPreview(false);
                }}
              >
                <Box
                  style={{
                    margin: '0 auto',
                    alignSelf: 'center',
                    borderRadius: '20px',
                    position: 'relative',
                    background: 'linear-gradient(180deg, #BBD4ED 0%, #FDF5F2 100%)',
                    padding: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    maxWidth: '95vw',
                    minWidth: 'initial',
                    maxHeight: '95vh',
                    minHeight: 'auto',
                    overflowY: 'hidden',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Wrapper flex={1} flexDirection={'column'} width={'800px'} style={{ overflowY: 'auto' }}>
                    <Flex flexDirection={'column'}>
                      <Heading textAlign={'center'} mb={4}>
                        Duplicate research Id found:
                      </Heading>
                      <Text mb={3}>Research Ids must be unique across a form. Here are the duplicates identified:</Text>
                      {duplicatesResearchId.map((x) => {
                        return (
                          <Flex key={x.researchId} mb={2}>
                            <Text fontWeight={'500'}>- "{x.researchId}"</Text>
                            <Text ml={2} mr={2}>
                              found in:
                            </Text>
                            <Text>"{x.foundIn.join('", "')}"</Text>
                          </Flex>
                        );
                      })}
                      <Button variant={'primary'} mt={4} alignSelf={'center'} onClick={() => setDuplicatesResearchId(null)}>
                        OK
                      </Button>
                    </Flex>
                  </Wrapper>
                </Box>
              </SpecialModalBackground>
            </Modal>
          )}
        {showPreview && (
          <Modal
            isOpen={true}
            onBackgroundClick={() => {
              setShowPreview(false);
            }}
            onEscapeKeydown={() => {
              setShowPreview(false);
            }}
          >
            <SpecialModalBackground
              onClick={() => {
                setShowPreview(false);
              }}
            >
              <Box
                style={{
                  margin: '0 auto',
                  alignSelf: 'center',
                  borderRadius: '20px',
                  position: 'relative',
                  background: 'linear-gradient(180deg, #BBD4ED 0%, #FDF5F2 100%)',
                  padding: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  maxWidth: '95vw',
                  minWidth: 'initial',
                  maxHeight: '95vh',
                  minHeight: '95vh',
                  overflowY: 'hidden',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Wrapper flex={1} flexDirection={'column'} width={'800px'} style={{ overflowY: 'auto' }}>
                  <PreviewHcForm tree={tree} meta={meta} close={() => setShowPreview(false)} />
                </Wrapper>
              </Box>
            </SpecialModalBackground>
          </Modal>
        )}
      </Flex>
    </DragDropContext>
  );
};

export default withRouter(withSession(withToastManager(HealthCoachFormBuilder)));
