import gql from 'graphql-tag';

const EMPTY_CALL = gql`
  mutation emptyCall {
    emptyCall {
      ok
    }
  }
`;

const EMAIL_AVAILABLE = gql`
  query EmailAvailable($email: String!) {
    emailAvailable(email: $email) {
      ok
      mobile
    }
  }
`;

const PHONE_AVAILABLE = gql`
  query PhoneAvailable($phone: String!) {
    phoneAvailable(phone: $phone) {
      ok
    }
  }
`;

const CURRENT_USER = gql`
  query {
    currentUser {
      id
      email
      firstName
      lastName
      gender
      phone
      intakeStatus
      intakeDate
      menoIntakeStatus
      menoIntakeDate
      departmentId
      dob
      primaryProviderId
      isLab
      helpscoutId
      medicationHistoryConsentVerified
      accountLocked
      isDiagnosed
      isMember
      membershipSpecific
      oonSchedulable
      mustCompleteProfile
      mustMergeAccount
      isMobileAppUser
      isCommunityUser
      mustCompleteBaselineSurvey
      baselineStatus
      mustScheduleOnboardingCall
      needInsuranceCompletion
      otbex
      perpetuousPhysicianConversationId
      diagnoseFull {
        hasHypo
        hypoNotes
        hasHashi
        hashiNotes
        hasAppointment
        hasAppointmentNutritionist
        reason
      }
      # isAthenaActive
      department {
        state
        departmentId
        timeZoneOffset
        timeZoneName
        tzAbbr
      }
      featureToggles {
        name
        isActive
      }
      address {
        street1
        street2
        city
        state
        zip
      }
      card {
        id
        brand
        last4
        expMonth
        expYear
        pm
      }
      eligibility {
        status
        canBook
        canPurchase
        consultation
        purchase
        pharmacy
        labCenter
        lab
        practitioner
        canBookNutritionist
        canBookAsync
        canBookPerimenopause
        canBookPostmenopause
      }
      pharmacy {
        id
        placeId
        name
        formattedAddress
        phone
      }
      labCenter {
        id
        placeId
        name
        formattedAddress
        phone
      }
      adm {
        imp
        mobileMan
        canFeatureTogglesManagement
        passwordExpired
        daysBeforePasswordExpires
        canUnithroid
        canStripeUserManagement
        canReporting
        canRemapAccount
        canChangeEmail
        canMobileUserManagement
        canGenerateOtp
        canBatchMigrateProviderGroups
        canOneTimeCouponManagement
        canViewAdminsLogs
        canAdminHealthCoachApp
        canAdminProvidersApp
        canB2BManagement
      }
      b2b {
        pendingApproval
        isActive
        mustVerify
        verificationSent
        verificationSentDate
        kit
        vitamins
        membership
        visits
        async
        nutritionist
        expiresAfter
      }
      dedicatedCareManager {
        id
        firstName
        lastName
        fullName
        bio
        thumbnail
        calendlyLink
      }
    }
  }
`;

const GET_PROFILE_COMPLETION_INFO_BY_UID = gql`
  query getProfileCompletionInfoByUid($uid: String!) {
    getProfileCompletionInfoByUid(uid: $uid) {
      id
      email
      firstName
      lastName
      gender
      phone
      address {
        street1
        street2
        city
        state
        zip
      }
      departmentId
      providerId
      primaryProviderId
      dob
      accountLocked
    }
  }
`;

const GET_PATIENT_BY_ID = gql`
  query getPatientById($patientId: Int!) {
    getPatientById(patientId: $patientId) {
      email
      firstName
      lastName
      gender
      phone
      intakeStatus
      departmentId
      providerId
      department {
        departmentId
        timeZoneOffset
        timeZoneName
      }
      address {
        street1
        street2
        city
        state
        zip
      }
      card {
        id
        brand
        last4
        expMonth
        expYear
      }
      pharmacy {
        id
        placeId
        name
        formattedAddress
        phone
      }
      labCenter {
        id
        placeId
        name
        formattedAddress
        phone
      }
    }
  }
`;

const CHECK_ELIGIBLE_COVERAGE_UNREGISTERED = gql`
  query checkCoverageUnregistered(
    $formalizedInsuranceName: String
    $payerId: String!
    $memberId: String!
    $memberFirstName: String!
    $memberLastName: String!
    $memberStreet1: String!
    $memberStreet2: String
    $memberZip: String!
    $memberCity: String!
    $memberState: String!
    $subscriberFirstName: String
    $subscriberLastName: String
    $subscriberStreet1: String
    $subscriberStreet2: String
    $subscriberZip: String
    $subscriberCity: String
    $subscriberState: String
    $groupId: String
    $isSubscriber: Boolean
    $department: String
  ) {
    checkCoverageUnregistered(
      coverageInput: {
        formalizedInsuranceName: $formalizedInsuranceName
        payerId: $payerId
        memberId: $memberId
        memberFirstName: $memberFirstName
        memberLastName: $memberLastName
        memberStreet1: $memberStreet1
        memberStreet2: $memberStreet2
        memberZip: $memberZip
        memberCity: $memberCity
        memberState: $memberState
        subscriberFirstName: $subscriberFirstName
        subscriberLastName: $subscriberLastName
        subscriberStreet1: $subscriberStreet1
        subscriberStreet2: $subscriberStreet2
        subscriberZip: $subscriberZip
        subscriberCity: $subscriberCity
        subscriberState: $subscriberState
        groupId: $groupId
        isSubscriber: $isSubscriber
        department: $department
      }
    ) {
      payerId
      planType {
        code
        accepted
        error
      }
      eligible
      groupName
      planName
      reason
      error {
        code
        description
      }
    }
  }
`;

const CHECK_ELIGIBLE_COVERAGE = gql`
  query checkCoverage(
    $formalizedInsuranceName: String
    $payerId: String
    $memberId: String!
    $memberFirstName: String!
    $memberLastName: String!
    # $memberDoB: String!
    $isSubscriber: Boolean!
    $subscriberFirstName: String
    $subscriberLastName: String
    $subscriberStreet1: String
    $subscriberStreet2: String
    $subscriberCity: String
    $subscriberState: String
    $subscriberZip: String
    $userRelation: String
    $groupId: String
  ) {
    checkCoverage(
      coverageInput: {
        formalizedInsuranceName: $formalizedInsuranceName
        payerId: $payerId
        memberId: $memberId
        memberFirstName: $memberFirstName
        memberLastName: $memberLastName
        # memberDoB: $memberDoB
        isSubscriber: $isSubscriber
        insuranceSubscriber: {
          firstName: $subscriberFirstName
          lastName: $subscriberLastName
          address: { street1: $subscriberStreet1, street2: $subscriberStreet2, city: $subscriberCity, state: $subscriberState, zip: $subscriberZip }
          relation: $userRelation
        }
        groupId: $groupId
      }
    ) {
      payerId
      planType {
        code
        accepted
        error
      }
      eligible
      reason
      groupName
      planName
      error {
        code
        description
      }
    }
  }
`;

const CHECK_ELIGIBLE_COVERAGE_FOR_PATIENT = gql`
  query checkCoverageForPatient(
    $formalizedInsuranceName: String
    $patientId: Int!
    $payerId: String
    $memberId: String!
    $memberFirstName: String!
    $memberLastName: String!
    # $memberDoB: String!
    $isSubscriber: Boolean!
    $subscriberFirstName: String
    $subscriberLastName: String
    $subscriberStreet1: String
    $subscriberStreet2: String
    $subscriberCity: String
    $subscriberState: String
    $subscriberZip: String
    $userRelation: String
    $groupId: String
  ) {
    checkCoverageForPatient(
      patientId: $patientId
      coverageInput: {
        formalizedInsuranceName: $formalizedInsuranceName
        payerId: $payerId
        memberId: $memberId
        memberFirstName: $memberFirstName
        memberLastName: $memberLastName
        # memberDoB: $memberDoB
        isSubscriber: $isSubscriber
        insuranceSubscriber: {
          firstName: $subscriberFirstName
          lastName: $subscriberLastName
          address: { street1: $subscriberStreet1, street2: $subscriberStreet2, city: $subscriberCity, state: $subscriberState, zip: $subscriberZip }
          relation: $userRelation
        }
        groupId: $groupId
      }
    ) {
      payerId
      planType {
        code
        accepted
        error
      }
      eligible
      reason
      error {
        code
        description
      }
    }
  }
`;

const GET_COST_ESTIMATES_UNREGISTERED = gql`
  query getCostEstimatesUnregistered(
    $payerId: String!
    $providerNpi: String
    $memberId: String!
    $providerPrice: String
    $network: String!
    $memberFirstName: String!
    $memberLastName: String!
    $memberStreet1: String!
    $memberStreet2: String
    $memberZip: String!
    $memberCity: String!
    $memberState: String!
    $subscriberFirstName: String
    $subscriberLastName: String
    $subscriberStreet1: String
    $subscriberStreet2: String
    $subscriberZip: String
    $subscriberCity: String
    $subscriberState: String
  ) {
    getCostEstimatesUnregistered(
      costEstimatesInput: {
        payerId: $payerId
        providerNpi: $providerNpi
        memberId: $memberId
        providerPrice: $providerPrice
        network: $network
        memberFirstName: $memberFirstName
        memberLastName: $memberLastName
        memberStreet1: $memberStreet1
        memberStreet2: $memberStreet2
        memberZip: $memberZip
        memberCity: $memberCity
        memberState: $memberState
        subscriberFirstName: $subscriberFirstName
        subscriberLastName: $subscriberLastName
        subscriberStreet1: $subscriberStreet1
        subscriberStreet2: $subscriberStreet2
        subscriberZip: $subscriberZip
        subscriberCity: $subscriberCity
        subscriberState: $subscriberState
      }
    ) {
      costEstimate
      isCertReq
      error {
        code
        description
      }
    }
  }
`;

const GET_COST_ESTIMATES = gql`
  query getCostEstimates(
    $payerId: String!
    $providerNpi: String
    $memberId: String!
    $network: String!
    $isSubscriber: Boolean!
    $subscriberFirstName: String
    $subscriberLastName: String
    $subscriberStreet1: String
    $subscriberStreet2: String
    $subscriberCity: String
    $subscriberState: String
    $subscriberZip: String
    $userRelation: String
  ) {
    getCostEstimates(
      costEstimatesInput: {
        payerId: $payerId
        providerNpi: $providerNpi
        memberId: $memberId
        network: $network
        isSubscriber: $isSubscriber
        insuranceSubscriber: {
          firstName: $subscriberFirstName
          lastName: $subscriberLastName
          address: { street1: $subscriberStreet1, street2: $subscriberStreet2, city: $subscriberCity, state: $subscriberState, zip: $subscriberZip }
          relation: $userRelation
        }
      }
    ) {
      costEstimate
      isCertReq
      error {
        code
        description
      }
    }
  }
`;

const GET_COST_ESTIMATES_FOR_PATIENT = gql`
  query getCostEstimatesForPatient(
    $patientId: Int!
    $payerId: String!
    $providerNpi: String
    $memberId: String!
    $network: String!
    $isSubscriber: Boolean!
    $subscriberFirstName: String
    $subscriberLastName: String
    $subscriberStreet1: String
    $subscriberStreet2: String
    $subscriberCity: String
    $subscriberState: String
    $subscriberZip: String
    $userRelation: String
  ) {
    getCostEstimatesForPatient(
      patientId: $patientId
      costEstimatesInput: {
        payerId: $payerId
        providerNpi: $providerNpi
        memberId: $memberId
        network: $network
        isSubscriber: $isSubscriber
        insuranceSubscriber: {
          firstName: $subscriberFirstName
          lastName: $subscriberLastName
          address: { street1: $subscriberStreet1, street2: $subscriberStreet2, city: $subscriberCity, state: $subscriberState, zip: $subscriberZip }
          relation: $userRelation
        }
      }
    ) {
      costEstimate
      isCertReq
      error {
        code
        description
      }
    }
  }
`;

const GET_REQUISITION_FORM = gql`
  query getRequisitionFormPdf($serial: String!) {
    getRequisitionFormPdf(serial: $serial) {
      req
      customError {
        code
        userMessage
        errorMessage
      }
    }
  }
`;

const GET_REQUISITION_FORM_DETAILS = gql`
  query getRequisitionFormDetails($serial: String!) {
    getRequisitionFormDetails(serial: $serial) {
      id
      firstName
      lastName
      dob
      age
      gender
      testName
      address
      physiciansName
      physiciansNpi
      kitId
      customError {
        code
        userMessage
        errorMessage
      }
    }
  }
`;

const REPORT_LAB_ISSUE = gql`
  mutation reportLabIssue($serial: String!, $issue: String!, $details: String, $file: String) {
    reportLabIssue(serial: $serial, issue: $issue, details: $details, file: $file) {
      ok
    }
  }
`;

const CHECK_COUPON_EXISTS = gql`
  query checkCouponExists($coupon: String!) {
    checkCouponExists(coupon: $coupon) {
      found
      firstName
    }
  }
`;

const MOBILE_WALLET_PAYMENT = gql`
  mutation mobileWalletPayment($orderId: String!, $token: String!, $address: AddressInput, $name: String, $payerEmail: String, $payerPhone: String, $addBuyOneGiveOneKit: AddBuyOneGiveOneKit) {
    mobileWalletPayment(orderId: $orderId, token: $token, address: $address, name: $name, payerEmail: $payerEmail, payerPhone: $payerPhone, addBuyOneGiveOneKit: $addBuyOneGiveOneKit) {
      ok
    }
  }
`;

const GET_INSURANCE_PACKAGE = gql`
  query getInsurancePackage($insurancePlanName: String!, $insuranceZip: String, $memberId: String!, $productTypeId: String!, $stateOfCoverage: String) {
    getInsurancePackage(insurancePlanName: $insurancePlanName, insuranceZip: $insuranceZip, memberId: $memberId, productTypeId: $productTypeId, stateOfCoverage: $stateOfCoverage) {
      affiliation
      insurancePackageId
    }
  }
`;

const GET_APPOINTMENT_CONVERSATION = gql`
  query getAppointmentConversation($appointmentId: String!, $isAsync: Boolean) {
    getAppointmentConversation(appointmentId: $appointmentId, isAsync: $isAsync) {
      id
      number
      thread
      type
      folderId
      status
      state
      subject
      preview
      mailboxId
      assignee {
        id
        type
        first
        last
        photoUrl
        email
      }
      createdBy {
        id
        type
        first
        last
        photoUrl
        email
      }
      createdAt
      closedBy
      closedByUser {
        id
        type
        first
        last
        photoUrl
        email
      }
      userUpdatedAt
      customerWaitingSince {
        time
        friendly
      }
      source {
        type
        via
      }
      # tags:
      cc {
        id
        type
        first
        last
        photoUrl
        email
      }
      bcc {
        id
        type
        first
        last
        photoUrl
        email
      }
      primaryCustomer {
        id
        type
        first
        last
        photoUrl
        email
      }
      customFields {
        id
        name
        value
        text
      }
      threads {
        id
        type
        status
        state
        body
        savedReplyId
        createdAt
        openedAt
        _embedded {
          attachments {
            id
            filename
            mimeType
            _links {
              web {
                href
              }
            }
          }
        }
        bcc {
          id
          type
          first
          last
          photoUrl
          email
        }
        cc {
          id
          type
          first
          last
          photoUrl
          email
        }
        to {
          id
          type
          first
          last
          photoUrl
          email
        }
        assignedTo {
          id
          type
          first
          last
          photoUrl
          email
        }
        createdBy {
          id
          type
          first
          last
          photoUrl
          email
        }
        customer {
          id
          type
          first
          last
          photoUrl
          email
        }
        source {
          type
          via
        }
      }
    }
  }
`;

const GET_PERPETUAL_PHYSICIAN_CONVERSATION = gql`
  query getPerpetualPhysicianConversation($treeIndex: Int) {
    getPerpetualPhysicianConversation(treeIndex: $treeIndex) {
      id
      number
      thread
      type
      folderId
      status
      state
      subject
      preview
      mailboxId
      convoTree {
        tree
        parent
        returnedTreeIndex
        next
      }
      assignee {
        id
        type
        first
        last
        photoUrl
        email
      }
      createdBy {
        id
        type
        first
        last
        photoUrl
        email
      }
      createdAt
      closedBy
      closedByUser {
        id
        type
        first
        last
        photoUrl
        email
      }
      userUpdatedAt
      customerWaitingSince {
        time
        friendly
      }
      source {
        type
        via
      }
      # tags:
      cc {
        id
        type
        first
        last
        photoUrl
        email
      }
      bcc {
        id
        type
        first
        last
        photoUrl
        email
      }
      primaryCustomer {
        id
        type
        first
        last
        photoUrl
        email
      }
      customFields {
        id
        name
        value
        text
      }
      threads {
        id
        type
        status
        state
        body
        savedReplyId
        createdAt
        openedAt
        _embedded {
          attachments {
            id
            filename
            mimeType
            _links {
              web {
                href
              }
            }
          }
        }
        bcc {
          id
          type
          first
          last
          photoUrl
          email
        }
        cc {
          id
          type
          first
          last
          photoUrl
          email
        }
        to {
          id
          type
          first
          last
          photoUrl
          email
        }
        assignedTo {
          id
          type
          first
          last
          photoUrl
          email
        }
        createdBy {
          id
          type
          first
          last
          photoUrl
          email
        }
        customer {
          id
          type
          first
          last
          photoUrl
          email
        }
        source {
          type
          via
        }
      }
    }
  }
`;

const GET_CARE_TEAM_CONVERSATION = gql`
  query getCareTeamConversation {
    getCareTeamConversation {
      id
      number
      thread
      type
      folderId
      status
      state
      subject
      preview
      mailboxId
      assignee {
        id
        type
        first
        last
        photoUrl
        email
      }
      createdBy {
        id
        type
        first
        last
        photoUrl
        email
      }
      createdAt
      closedBy
      closedByUser {
        id
        type
        first
        last
        photoUrl
        email
      }
      userUpdatedAt
      customerWaitingSince {
        time
        friendly
      }
      source {
        type
        via
      }
      # tags:
      cc {
        id
        type
        first
        last
        photoUrl
        email
      }
      bcc {
        id
        type
        first
        last
        photoUrl
        email
      }
      primaryCustomer {
        id
        type
        first
        last
        photoUrl
        email
      }
      customFields {
        id
        name
        value
        text
      }
      threads {
        id
        type
        status
        state
        body
        savedReplyId
        createdAt
        openedAt
        _embedded {
          attachments {
            id
            filename
            mimeType
            _links {
              web {
                href
              }
            }
          }
        }
        bcc {
          id
          type
          first
          last
          photoUrl
          email
        }
        cc {
          id
          type
          first
          last
          photoUrl
          email
        }
        to {
          id
          type
          first
          last
          photoUrl
          email
        }
        assignedTo {
          id
          type
          first
          last
          photoUrl
          email
        }
        createdBy {
          id
          type
          first
          last
          photoUrl
          email
        }
        customer {
          id
          type
          first
          last
          photoUrl
          email
        }
        source {
          type
          via
        }
      }
    }
  }
`;

const GET_INSURANCE_ON_FILE = gql`
  query getInsurancesOnFile {
    getInsurancesOnFile {
      insurancePolicyHolderCountryCode
      sequenceNumber
      insurancePolicyHolderlastname
      insuredEntityTypeId
      insuranceIdNumber
      insurancePolicyHolderstate
      insurancePolicyHolderzip
      relationshipToInsured
      eligibilityStatus
      policyNumber
      insurancePolicyHolderaddress1
      insurancePackageAddress1
      insurancePolicyHoldersex
      eligibilityReason
      ircName
      insurancePlanName
      insuranceType
      insurancePhone
      insurancePackageState
      insurancePackageCity
      relationshipToInsuredId
      insuranceId
      insurancePolicyHolder
      eligibilityLastChecked
      insurancePolicyHolderFirstName
      insurancePackageId
      insurancePolicyHolderCountryIso3166
      insurancePlanDisplayName
      insurancePolicyHoldercity
      insurancePackageZip
      insuranceCompany
      groupId
      payerId
    }
  }
`;

const GET_REGION_ELIGIBILITY = gql`
  query getRegionEligibility($state: String!) {
    getRegionEligibility(state: $state)
  }
`;

const GET_INSURANCE_ON_FILE_FOR_PATIENT = gql`
  query getInsurancesOnFileForPatient($patientId: Int!) {
    getInsurancesOnFileForPatient(patientId: $patientId) {
      insurancePolicyHolderCountryCode
      sequenceNumber
      insurancePolicyHolderlastname
      insuredEntityTypeId
      insuranceIdNumber
      insurancePolicyHolderstate
      insurancePolicyHolderzip
      relationshipToInsured
      eligibilityStatus
      policyNumber
      insurancePolicyHolderaddress1
      insurancePackageAddress1
      insurancePolicyHoldersex
      eligibilityReason
      ircName
      insurancePlanName
      insuranceType
      insurancePhone
      insurancePackageState
      insurancePackageCity
      relationshipToInsuredId
      insuranceId
      insurancePolicyHolder
      eligibilityLastChecked
      insurancePolicyHolderFirstName
      insurancePackageId
      insurancePolicyHolderCountryIso3166
      insurancePlanDisplayName
      insurancePolicyHoldercity
      insurancePackageZip
      insuranceCompany
      groupId
      payerId
    }
  }
`;

const ADD_NEW_INSURANCE = gql`
  mutation addInsurance($subscriberFullName: String, $subscriberDob: String, $thread: InitiateAppointmentConversationThread, $isPrimary: Boolean, $planType: String) {
    addInsurance(data: { subscriberFullName: $subscriberFullName, subscriberDob: $subscriberDob, thread: $thread, isPrimary: $isPrimary, planType: $planType }) {
      ok
    }
  }
`;

const INSURANCE_MEMBER_CHANGE_INSURANCE = gql`
  mutation insuranceMemberChangeInsurance($subscriberFullName: String, $subscriberDob: String, $thread: InitiateAppointmentConversationThread, $isPrimary: Boolean) {
    insuranceMemberChangeInsurance(data: { subscriberFullName: $subscriberFullName, subscriberDob: $subscriberDob, thread: $thread, isPrimary: $isPrimary }) {
      ok
    }
  }
`;

const SEND_INSURANCE = gql`
  mutation sendInsurance(
    $planType: String!
    $state: String!
    $booked: Boolean
    $doctor: String!
    $email: String!
    $appointmentId: String
    $appointmentDate: String!
    $subscriberFullName: String
    $subscriberDob: String
    $thread: InitiateAppointmentConversationThread
    $conversationId: String
  ) {
    sendInsurance(
      data: {
        planType: $planType
        state: $state
        booked: $booked
        doctor: $doctor
        email: $email
        appointmentId: $appointmentId
        appointmentDate: $appointmentDate
        thread: $thread
        subscriberFullName: $subscriberFullName
        subscriberDob: $subscriberDob
        conversationId: $conversationId
      }
    ) {
      ok
    }
  }
`;

const SEND_INSURANCE_INITIAL = gql`
  mutation sendInsuranceInitial($planType: String!, $state: String!, $email: String!, $subscriberFullName: String, $subscriberDob: String, $thread: InitiateAppointmentConversationThread) {
    sendInsuranceInitial(data: { planType: $planType, state: $state, email: $email, thread: $thread, subscriberFullName: $subscriberFullName, subscriberDob: $subscriberDob }) {
      conversationId
    }
  }
`;

const GET_HAS_INSURANCE_CARD_ON_DOCS = gql`
  query getHasInsuranceCardOnAdminDocs {
    getHasInsuranceCardOnAdminDocs {
      hasInsuranceDoc
    }
  }
`;

const INSURANCE_MEMBER_LOST_COVERAGE = gql`
  mutation insuranceMemberLostCoverage {
    insuranceMemberLostCoverage {
      ok
    }
  }
`;

const APPOINTMENT_INITIATE_CONVERSATION = gql`
  mutation initiateConversation(
    $subject: String
    $assignedToEmail: String
    $thread: InitiateAppointmentConversationThread
    $appointmentDate: String
    $appointmentId: String
    $prescription: String
    $assessmentAndPlan: String
    $patientGoal: String
    $patientInstructions: String
    $discussionNotes: String
    $followUp: String
    $isAsync: Boolean
  ) {
    initiateConversation(
      data: {
        subject: $subject
        assignedToEmail: $assignedToEmail
        thread: $thread
        appointmentDate: $appointmentDate
        appointmentId: $appointmentId
        prescription: $prescription
        assessmentAndPlan: $assessmentAndPlan
        patientGoal: $patientGoal
        patientInstructions: $patientInstructions
        discussionNotes: $discussionNotes
        followUp: $followUp
        isAsync: $isAsync
      }
    ) {
      ok
    }
  }
`;

const MEMBER_CONVERSATION_THREAD_REPLY = gql`
  mutation replyToMemberConversationThread($conversationId: String, $text: String, $attachments: [ConversationThreadReplyAttachments], $isMemberConvo: Boolean) {
    replyToMemberConversationThread(data: { conversationId: $conversationId, text: $text, attachments: $attachments, isMemberConvo: $isMemberConvo }) {
      ok
    }
  }
`;

const CONVERSATION_THREAD_REPLY = gql`
  mutation replyToConversationThread($conversationId: String, $text: String, $attachments: [ConversationThreadReplyAttachments], $isAsync: Boolean) {
    replyToConversationThread(data: { conversationId: $conversationId, text: $text, attachments: $attachments, isAsync: $isAsync }) {
      ok
    }
  }
`;

const PERPETUAL_CONVERSATION_THREAD_REPLY = gql`
  mutation replyToPerpetualConversationThread($conversationId: String, $text: String, $attachments: [ConversationThreadReplyAttachments], $isAsync: Boolean) {
    replyToPerpetualConversationThread(data: { conversationId: $conversationId, text: $text, attachments: $attachments, isAsync: $isAsync }) {
      ok
    }
  }
`;

const ADD_INSURANCE = gql`
  mutation setInsurance(
    $insurancePackageId: Int!
    $insuranceIdNumber: String
    $expirationDate: String
    $insurancePolicyHolderFirstname: String
    $insurancePolicyHolderlastname: String
    $insurancePolicyHolderstate: String
    $relationshipToInsuredid: Int
    $insurancePolicyHolderaddress1: String
    $insurancePolicyHolderaddress2: String
    $insurancePolicyHoldercity: String
    $insurancePolicyHolderzip: String
    $insurancepolicyHolderSex: String
    $groupId: String
    $insuranceCompanyName: String
    $copayAmount: Int
    $copayType: Int
    $payerId: String
  ) {
    setInsurance(
      insurancePackageId: $insurancePackageId
      insuranceIdNumber: $insuranceIdNumber
      expirationDate: $expirationDate
      insurancePolicyHolderFirstname: $insurancePolicyHolderFirstname
      insurancePolicyHolderlastname: $insurancePolicyHolderlastname
      insurancePolicyHolderstate: $insurancePolicyHolderstate
      relationshipToInsuredid: $relationshipToInsuredid
      insurancePolicyHolderaddress1: $insurancePolicyHolderaddress1
      insurancePolicyHolderaddress2: $insurancePolicyHolderaddress2
      insurancePolicyHoldercity: $insurancePolicyHoldercity
      insurancePolicyHolderzip: $insurancePolicyHolderzip
      insurancepolicyHolderSex: $insurancepolicyHolderSex
      groupId: $groupId
      insuranceCompanyName: $insuranceCompanyName
      copayAmount: $copayAmount
      copayType: $copayType
      payerId: $payerId
    ) {
      ok
      insuranceId
    }
  }
`;

const ADD_INSURANCE_FOR_PATIENT = gql`
  mutation setInsuranceForPatient(
    $patientId: Int!
    $insurancePackageId: Int!
    $insuranceIdNumber: String
    $expirationDate: String
    $insurancePolicyHolderFirstname: String
    $insurancePolicyHolderlastname: String
    $insurancePolicyHolderstate: String
    $relationshipToInsuredid: Int
    $insurancePolicyHolderaddress1: String
    $insurancePolicyHolderaddress2: String
    $insurancePolicyHoldercity: String
    $insurancePolicyHolderzip: String
    $insurancepolicyHolderSex: String
    $groupId: String
    $insuranceCompanyName: String
    $copayAmount: Int
    $copayType: Int
    $payerId: String
  ) {
    setInsuranceForPatient(
      patientId: $patientId
      insurancePackageId: $insurancePackageId
      insuranceIdNumber: $insuranceIdNumber
      expirationDate: $expirationDate
      insurancePolicyHolderFirstname: $insurancePolicyHolderFirstname
      insurancePolicyHolderlastname: $insurancePolicyHolderlastname
      insurancePolicyHolderstate: $insurancePolicyHolderstate
      relationshipToInsuredid: $relationshipToInsuredid
      insurancePolicyHolderaddress1: $insurancePolicyHolderaddress1
      insurancePolicyHolderaddress2: $insurancePolicyHolderaddress2
      insurancePolicyHoldercity: $insurancePolicyHoldercity
      insurancePolicyHolderzip: $insurancePolicyHolderzip
      insurancepolicyHolderSex: $insurancepolicyHolderSex
      groupId: $groupId
      insuranceCompanyName: $insuranceCompanyName
      copayAmount: $copayAmount
      copayType: $copayType
      payerId: $payerId
    ) {
      ok
      insuranceId
    }
  }
`;

const ASSIGN_INSURANCE_TO_APPOINTMENT = gql`
  mutation assignInsuranceToAppointment($appointmentId: Int!, $insuranceId: Int!) {
    assignInsuranceToAppointment(appointmentId: $appointmentId, insuranceId: $insuranceId) {
      success
      errorMessage
    }
  }
`;

const DELETE_INSURANCE = gql`
  mutation deleteInsurance {
    deleteInsurance {
      ok
    }
  }
`;

const REQUEST_DELETE_INSURANCE = gql`
  mutation requestDeleteInsurance($insuranceId: String!) {
    requestDeleteInsurance(insuranceId: $insuranceId) {
      ok
    }
  }
`;

const DELETE_INSURANCE_FOR_PATIENT = gql`
  mutation deleteInsuranceForPatient($patientId: Int!) {
    deleteInsuranceForPatient(patientId: $patientId) {
      ok
    }
  }
`;

const GET_ASSIGNED_DOCTOR = gql`
  query getAssignedDoctor($providerId: String) {
    getAssignedDoctor(providerId: $providerId) {
      id
      departmentId
      firstName
      lastName
      patientFacingName
      email
      bio
      picture
      doctorType
      question1
      question2
      tags
    }
  }
`;

const GET_CLINICAL_PROVIDER = gql`
  query getClinicalProvider($athenaProviderId: String) {
    getClinicalProvider(athenaProviderId: $athenaProviderId) {
      id
      departmentId
      firstName
      lastName
      patientFacingName
      email
      bio
      picture
      doctorType
      networks
      education
      certification
      experience
      startingYear
      npi
      question1
      question2
      tags
    }
  }
`;

const GET_CLINICAL_PROVIDERS_DOCTOR = gql`
  query getClinicalProvidersDoctorsList($departmentId: String, $selectedInsurance: String, $specialization: String) {
    getClinicalProvidersDoctorsList(departmentId: $departmentId, selectedInsurance: $selectedInsurance, specialization: $specialization) {
      id
      departmentId
      primaryId
      firstName
      lastName
      patientFacingName
      email
      bio
      picture
      doctorType
      networks
      education
      certification
      experience
      startingYear
      npi
      question1
      question2
      tags
      zocdocVideoLink
      archived
      periMenopause
      postMenopause
      nextAppointmentAvailable {
        id
        startTime
        date
        duration
        providerId
        departmentId
      }
      rating {
        rate
        count
      }
      reviews {
        firstName
        review
        rating
      }
    }
  }
`;

const SEND_INTAKE_REMINDER_EMAIL = gql`
  mutation sendPatientRequestToCompleteIntake($patientId: Int!) {
    sendPatientRequestToCompleteIntake(patientId: $patientId) {
      ok
    }
  }
`;

const ATHENA_CARD = gql`
  query AthenaCard {
    athenaCard {
      id
      brand
      last4
      expMonth
      expYear
    }
  }
`;

const ATHENA_CARD_FOR_PATIENT = gql`
  query athenaCardForPatient($patientId: Int!) {
    athenaCardForPatient(patientId: $patientId) {
      id
      brand
      last4
      expMonth
      expYear
    }
  }
`;

const DELETE_ATHENA_CARD_FOR_PATIENT = gql`
  mutation deleteAthenaCard($cardId: String!, $athenaId: String!) {
    deleteAthenaCard(cardId: $cardId, athenaId: $athenaId) {
      ok
    }
  }
`;

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        accountLocked
      }
    }
  }
`;
const RESEND_UNLOCK_ACCOUNT_EMAIL = gql`
  mutation resendUnlockEmail($email: String!) {
    resendUnlockEmail(email: $email) {
      ok
    }
  }
`;

const CHECK_UNLOCK_VALUES = gql`
  mutation checkUnlockValues($uid: String!, $email: String!, $dob: String!) {
    checkUnlockValues(uid: $uid, email: $email, dob: $dob) {
      ok
    }
  }
`;

const CHECK_LOCKED_ACCOUNT_UID = gql`
  mutation checkLockedAccountUid($uid: String!) {
    checkLockedAccountUid(uid: $uid) {
      ok
    }
  }
`;

const UNLOCK_ACCOUNT = gql`
  mutation unlockAccount($uid: String!, $email: String!, $password: String!, $dob: String!) {
    unlockAccount(uid: $uid, email: $email, password: $password, dob: $dob) {
      ok
    }
  }
`;

const IMPERSONATE = gql`
  mutation impersonate($email: String!, $password: String!, $emailToImpersonate: String!) {
    impersonate(email: $email, password: $password, emailToImpersonate: $emailToImpersonate) {
      token
    }
  }
`;

const TOUCH = gql`
  mutation touch {
    touch {
      token
      id
    }
  }
`;

const ADMIN_GET_LOCAL_DB_KITS = gql`
  query adminGetLocalDbKits {
    adminGetLocalDbKits {
      customerId
      stripeOrderId
      serialCode
      labResultId
      status
    }
  }
`;

const SIGNUP = gql`
  mutation signup(
    $email: String!
    $phone: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $street1: String!
    $street2: String
    $city: String!
    $state: String!
    $zip: String!
    $dob: String!
    $gender: String!
    $diagnosed: Boolean!
    $medicationHistoryConsentVerified: Boolean
    $genderidentity: String
    $genderidentityother: String
    $preferredpronouns: String
    $assignedsexatbirth: String
    $isNPThyroid: Boolean
  ) {
    signup(
      data: {
        email: $email
        phone: $phone
        password: $password
        firstName: $firstName
        lastName: $lastName
        street1: $street1
        street2: $street2
        city: $city
        state: $state
        zip: $zip
        dob: $dob
        gender: $gender
        diagnosed: $diagnosed
        medicationHistoryConsentVerified: $medicationHistoryConsentVerified
        genderidentity: $genderidentity
        genderidentityother: $genderidentityother
        preferredpronouns: $preferredpronouns
        assignedsexatbirth: $assignedsexatbirth
        isNPThyroid: $isNPThyroid
      }
    ) {
      token
      user {
        id
        departmentId
      }
    }
  }
`;

const SIGNUP_B2B_SUBSCRIBERID_NAMES = gql`
  mutation signupEligibilitySubscriberIdNames(
    $email: String!
    $phone: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $street1: String!
    $street2: String
    $city: String!
    $state: String!
    $zip: String!
    $dob: String!
    $gender: String!
    $diagnosed: Boolean!
    $medicationHistoryConsentVerified: Boolean
    $companyId: String!
    $relationship: String!
    $subscriberId: String!
    $employeeDob: DateTime!
    $employeeFirstName: String!
    $employeeLastName: String!
    $genderidentity: String
    $genderidentityother: String
    $preferredpronouns: String
    $assignedsexatbirth: String
  ) {
    signupEligibilitySubscriberIdNames(
      data: {
        email: $email
        phone: $phone
        password: $password
        firstName: $firstName
        lastName: $lastName
        street1: $street1
        street2: $street2
        city: $city
        state: $state
        zip: $zip
        dob: $dob
        gender: $gender
        diagnosed: $diagnosed
        medicationHistoryConsentVerified: $medicationHistoryConsentVerified
        companyId: $companyId
        relationship: $relationship
        subscriberId: $subscriberId
        employeeDob: $employeeDob
        employeeFirstName: $employeeFirstName
        employeeLastName: $employeeLastName
        genderidentity: $genderidentity
        genderidentityother: $genderidentityother
        preferredpronouns: $preferredpronouns
        assignedsexatbirth: $assignedsexatbirth
      }
    ) {
      token
      user {
        id
        departmentId
      }
    }
  }
`;

const SIGNUP_B2B_MEMBERID_NAMES = gql`
  mutation signupEligibilityMemberIdNames(
    $email: String!
    $phone: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $street1: String!
    $street2: String
    $city: String!
    $state: String!
    $zip: String!
    $dob: String!
    $gender: String!
    $diagnosed: Boolean!
    $medicationHistoryConsentVerified: Boolean
    $companyId: String!
    $relationship: String!
    $memberId: String!
    $employeeDob: DateTime!
    $employeeFirstName: String!
    $employeeLastName: String!
    $genderidentity: String
    $genderidentityother: String
    $preferredpronouns: String
    $assignedsexatbirth: String
  ) {
    signupEligibilityMemberIdNames(
      data: {
        email: $email
        phone: $phone
        password: $password
        firstName: $firstName
        lastName: $lastName
        street1: $street1
        street2: $street2
        city: $city
        state: $state
        zip: $zip
        dob: $dob
        gender: $gender
        diagnosed: $diagnosed
        medicationHistoryConsentVerified: $medicationHistoryConsentVerified
        companyId: $companyId
        relationship: $relationship
        memberId: $memberId
        employeeDob: $employeeDob
        employeeFirstName: $employeeFirstName
        employeeLastName: $employeeLastName
        genderidentity: $genderidentity
        genderidentityother: $genderidentityother
        preferredpronouns: $preferredpronouns
        assignedsexatbirth: $assignedsexatbirth
      }
    ) {
      token
      user {
        id
        departmentId
      }
    }
  }
`;

const SIGNUP_B2B_EMPLOYEEID_NAMES = gql`
  mutation signupEligibilityEmployeeIdNames(
    $email: String!
    $phone: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $street1: String!
    $street2: String
    $city: String!
    $state: String!
    $zip: String!
    $dob: String!
    $gender: String!
    $diagnosed: Boolean!
    $medicationHistoryConsentVerified: Boolean
    $companyId: String!
    $relationship: String!
    $employeeId: String!
    $employeeDob: DateTime!
    $employeeFirstName: String!
    $employeeLastName: String!
    $genderidentity: String
    $genderidentityother: String
    $preferredpronouns: String
    $assignedsexatbirth: String
  ) {
    signupEligibilityEmployeeIdNames(
      data: {
        email: $email
        phone: $phone
        password: $password
        firstName: $firstName
        lastName: $lastName
        street1: $street1
        street2: $street2
        city: $city
        state: $state
        zip: $zip
        dob: $dob
        gender: $gender
        diagnosed: $diagnosed
        medicationHistoryConsentVerified: $medicationHistoryConsentVerified
        companyId: $companyId
        relationship: $relationship
        employeeId: $employeeId
        employeeDob: $employeeDob
        employeeFirstName: $employeeFirstName
        employeeLastName: $employeeLastName
        genderidentity: $genderidentity
        genderidentityother: $genderidentityother
        preferredpronouns: $preferredpronouns
        assignedsexatbirth: $assignedsexatbirth
      }
    ) {
      token
      user {
        id
        departmentId
      }
    }
  }
`;

const SIGNUP_B2B_UNIQUE_CODE = gql`
  mutation signupEligibilityUniqueCode(
    $email: String!
    $phone: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $street1: String!
    $street2: String
    $city: String!
    $state: String!
    $zip: String!
    $dob: String!
    $gender: String!
    $diagnosed: Boolean!
    $medicationHistoryConsentVerified: Boolean
    $companyId: String!
    $relationship: String!
    $uniqueCode: String!
    $employeeDob: DateTime!
    $employeeFirstName: String!
    $employeeLastName: String!
    $genderidentity: String
    $genderidentityother: String
    $preferredpronouns: String
    $assignedsexatbirth: String
  ) {
    signupEligibilityUniqueCode(
      data: {
        email: $email
        phone: $phone
        password: $password
        firstName: $firstName
        lastName: $lastName
        street1: $street1
        street2: $street2
        city: $city
        state: $state
        zip: $zip
        dob: $dob
        gender: $gender
        diagnosed: $diagnosed
        medicationHistoryConsentVerified: $medicationHistoryConsentVerified
        companyId: $companyId
        relationship: $relationship
        uniqueCode: $uniqueCode
        employeeDob: $employeeDob
        employeeFirstName: $employeeFirstName
        employeeLastName: $employeeLastName
        genderidentity: $genderidentity
        genderidentityother: $genderidentityother
        preferredpronouns: $preferredpronouns
        assignedsexatbirth: $assignedsexatbirth
      }
    ) {
      token
      user {
        id
        departmentId
      }
    }
  }
`;

const SIGNUP_B2B = gql`
  mutation signupEligibility(
    $email: String!
    $phone: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $street1: String!
    $street2: String
    $city: String!
    $state: String!
    $zip: String!
    $dob: String!
    $gender: String!
    $diagnosed: Boolean!
    $medicationHistoryConsentVerified: Boolean
    $companyId: String!
    $relationship: String!
    $employeeEmail: String!
    $employeeDob: DateTime!
    $employeeFirstName: String!
    $employeeLastName: String!
    $genderidentity: String
    $genderidentityother: String
    $preferredpronouns: String
    $assignedsexatbirth: String
  ) {
    signupEligibility(
      data: {
        email: $email
        phone: $phone
        password: $password
        firstName: $firstName
        lastName: $lastName
        street1: $street1
        street2: $street2
        city: $city
        state: $state
        zip: $zip
        dob: $dob
        gender: $gender
        diagnosed: $diagnosed
        medicationHistoryConsentVerified: $medicationHistoryConsentVerified
        companyId: $companyId
        relationship: $relationship
        employeeEmail: $employeeEmail
        employeeDob: $employeeDob
        employeeFirstName: $employeeFirstName
        employeeLastName: $employeeLastName
        genderidentity: $genderidentity
        genderidentityother: $genderidentityother
        preferredpronouns: $preferredpronouns
        assignedsexatbirth: $assignedsexatbirth
      }
    ) {
      token
      user {
        id
        departmentId
      }
    }
  }
`;

const COMPLETE_WEB_REGISTRATION = gql`
  mutation completeWebRegistration(
    $dob: String!
    $phone: String!
    $gender: String!
    $street1: String!
    $street2: String
    $city: String!
    $state: String!
    $zip: String!
    $diagnosed: Boolean!
    $medicationHistoryConsentVerified: Boolean
    $genderidentity: String
    $genderidentityother: String
    $preferredpronouns: String
    $assignedsexatbirth: String
  ) {
    completeWebRegistration(
      data: {
        gender: $gender
        dob: $dob
        phone: $phone
        street1: $street1
        street2: $street2
        city: $city
        state: $state
        zip: $zip
        diagnosed: $diagnosed
        medicationHistoryConsentVerified: $medicationHistoryConsentVerified
        genderidentity: $genderidentity
        genderidentityother: $genderidentityother
        preferredpronouns: $preferredpronouns
        assignedsexatbirth: $assignedsexatbirth
      }
    ) {
      ok
    }
  }
`;

const SET_AS_INACTIVE = gql`
  mutation setAsInactive($disqualifyingReason: String, $bypassKlaviyo: Boolean) {
    setAsInactive(disqualifyingReason: $disqualifyingReason, bypassKlaviyo: $bypassKlaviyo) {
      ok
    }
  }
`;

const CHANGE_MEDICATION_CONSENT = gql`
  mutation changeMedicationHistoryConsent($consents: Boolean!) {
    changeMedicationHistoryConsent(consents: $consents) {
      ok
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation UpdateProfile($firstName: String!, $lastName: String!, $street1: String!, $street2: String, $city: String!, $state: String!, $zip: String!, $phone: String!) {
    updateProfile(data: { firstName: $firstName, lastName: $lastName, street1: $street1, street2: $street2, city: $city, state: $state, zip: $zip, phone: $phone }) {
      ok
    }
  }
`;

const ADMIN_UPDATE_MOBILE_PROFILE = gql`
  mutation adminUpdateMobileProfile($firstName: String!, $lastName: String!, $currentEmail: String!, $newEmail: String!) {
    adminUpdateMobileProfile(data: { firstName: $firstName, lastName: $lastName, currentEmail: $currentEmail, newEmail: $newEmail }) {
      ok
      customError
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      ok
    }
  }
`;

const CHANGE_EMAIL = gql`
  mutation ChangeEmail($newEmail: String!) {
    changeEmail(newEmail: $newEmail) {
      ok
    }
  }
`;

const SAVE_EMAIL_UNCOMPLETE_ORDER = gql`
  mutation saveEmailUncompleteOrder($email: String!) {
    saveEmailUncompleteOrder(email: $email) {
      ok
    }
  }
`;

const CREATE_ORDER = gql`
  mutation CreateOrder($sku: String!, $name: String!, $address: AddressInput!) {
    createOrder(data: { sku: $sku, name: $name, address: $address }) {
      id
      amount
      status
      items {
        type
        parent
        amount
        description
      }
    }
  }
`;

const CANCEL_ALL_ORDERS = gql`
  mutation cancelAllOrders {
    cancelAllOrders {
      ok
    }
  }
`;

const CREATE_ORDER_ACTIVATION = gql`
  mutation createOrderActivation($sku: String!) {
    createOrderActivation(sku: $sku) {
      id
      amount
      status
      items {
        type
        parent
        amount
        description
      }
    }
  }
`;

const CREATE_ORDER_ACTIVATION_BIOMARKERS = gql`
  mutation createOrderActivationBiomarkers($skus: [String!]!, $isWelcomeKit: Boolean) {
    createOrderActivationBiomarkers(skus: $skus, isWelcomeKit: $isWelcomeKit) {
      id
      amount
      status
      items {
        type
        parent
        amount
        description
      }
    }
  }
`;

const ADD_BIOMARKERS_ORDER = gql`
  mutation addBiomarkerToOrder($biomarkers: [String], $orderId: String!, $sku: String!, $name: String!, $address: AddressInput!) {
    addBiomarkerToOrder(biomarkers: $biomarkers, orderId: $orderId, currentOrder: { sku: $sku, name: $name, address: $address }) {
      id
      amount
      status
      items {
        type
        parent
        amount
        description
      }
    }
  }
`;

const ADD_BIOMARKERS_ORDER_UNAUTHENTICATED = gql`
  mutation addBiomarkerToOrderUnauthenticated($biomarkers: [String], $orderId: String!, $sku: String!, $name: String!, $email: String!, $source: String, $address: AddressInput!) {
    addBiomarkerToOrderUnauthenticated(biomarkers: $biomarkers, orderId: $orderId, currentOrder: { sku: $sku, name: $name, email: $email, source: $source, address: $address }) {
      id
      amount
      status
      items {
        type
        parent
        amount
        description
      }
      cardInfo {
        id
        brand
        last4
        expYear
        expMonth
      }
    }
  }
`;
const CREATE_ORDER_UNAUTHENTICATED = gql`
  mutation createOrderUnauthenticated($sku: String!, $name: String!, $email: String!, $source: String, $address: AddressInput!) {
    createOrderUnauthenticated(data: { sku: $sku, name: $name, email: $email, source: $source, address: $address }) {
      id
      amount
      status
      items {
        type
        parent
        amount
        description
      }
      cardInfo {
        id
        brand
        last4
        expYear
        expMonth
      }
    }
  }
`;

const COMPLETE_ORDER = gql`
  mutation CompleteOrder($id: String!, $addBuyOneGiveOneKit: AddBuyOneGiveOneKit, $referralBenefitsCoupon: String, $referralBenefitsCentisizedBalance: Int) {
    completeOrder(id: $id, addBuyOneGiveOneKit: $addBuyOneGiveOneKit, referralBenefitsCoupon: $referralBenefitsCoupon, referralBenefitsCentisizedBalance: $referralBenefitsCentisizedBalance) {
      ok
    }
  }
`;

const COMPLETE_ORDER_UNAUTHENTICATED = gql`
  mutation completeOrderUnauthenticated($id: String!, $addBuyOneGiveOneKit: AddBuyOneGiveOneKit) {
    completeOrderUnauthenticated(id: $id, addBuyOneGiveOneKit: $addBuyOneGiveOneKit) {
      ok
    }
  }
`;

const APPLY_COUPON = gql`
  mutation ApplyCoupon($id: String!, $coupon: String!, $email: String) {
    applyCoupon(id: $id, coupon: $coupon, email: $email) {
      amount
      status
      items {
        type
        parent
        amount
        description
      }
      isReferralCoupon
    }
  }
`;

const MEMBER_UPDATE_NEW_SYMPTOMS_HELPSCOUT = gql`
  mutation memberUpdateNewSymptomsHelpscout($symptoms: [String]) {
    memberUpdateNewSymptomsHelpscout(symptoms: $symptoms) {
      ok
    }
  }
`;

const APPLY_CANCELLATION_COUPON = gql`
  mutation applyCancellationCoupon($subscriptionId: String!, $subscriptionType: String!, $reason: String) {
    applyCancellationCoupon(subscriptionId: $subscriptionId, subscriptionType: $subscriptionType, reason: $reason) {
      ok
    }
  }
`;

const APPLY_CANCELLATION_FREE_VISIT = gql`
  mutation applyCancellationFreeVisit($subscriptionId: String!, $subscriptionType: String!, $reason: String) {
    applyCancellationFreeVisit(subscriptionId: $subscriptionId, subscriptionType: $subscriptionType, reason: $reason) {
      ok
    }
  }
`;

const OPEN_ORDER = gql`
  query OpenOrder {
    openOrder {
      id
      amount
      status
      address {
        street1
        street2
        city
        state
        zip
      }
      items {
        type
        parent
        amount
        description
      }
    }
  }
`;

const CHECK_SERIAL_AVAILABLE = gql`
  query checkSerialAvailable($serial: String!) {
    checkSerialAvailable(serial: $serial) {
      ok
      reason
      additionalBiomarkers
      billOnActivate
    }
  }
`;

const CANCEL_ORDER = gql`
  mutation CancelOrder($id: String!) {
    cancelOrder(id: $id) {
      ok
    }
  }
`;

// const GET_DEPARTMENT = gql`
//   query GetDepartment($id: String) {
//     openAppointments(data: { startDate: $startDate, endDate: $endDate }) {
//       id
//       startTime
//       date
//       dayOfWeek
//       shortDate
//       duration
//       providerId
//       departmentId
//     }
//   }
// `;

const ADMIN_GET_APPOINTMENTS_LIST = gql`
  query GetAdminListAppointments {
    adminListAppointments {
      startDate
      endDate
      subject
      htmlMessage
      inviteSubject
      inviteDescription
      inviteLocation
      providerId
      patientId
      departmentId
      appointmentId
      isZocDoc
      mustCompleteRegistration
    }
  }
`;

const OPEN_APPTS = gql`
  query OpenAppts($startDate: String, $endDate: String) {
    openAppointments(data: { startDate: $startDate, endDate: $endDate }) {
      id
      startTime
      date
      dayOfWeek
      shortDate
      duration
      providerId
      departmentId
      canWaitlist
    }
  }
`;

const APPOINTMENTS = gql`
  query Appointments($includeNut: Boolean) {
    appointments(includeNut: $includeNut) {
      id
      date
      startTime
      status
      appointmentTypeId
      timezoneName
      duration
      provider {
        id
        displayName
        zocdocVideoLink
        picture
      }
    }
  }
`;

const GET_APPOINTMENT_ENCOUNTER_STATE = gql`
  query getAppointmentEncounterState($appointmentId: String!) {
    getAppointmentEncounterState(appointmentId: $appointmentId) {
      encounterState
    }
  }
`;

const UPCOMING_APPOINTMENTS = gql`
  query upcomingAppointments {
    upcomingAppointments {
      id
      date
      startTime
      status
      appointmentTypeId
      provider {
        id
        displayName
      }
    }
  }
`;

const ADMIN_UPCOMING_APPOINTMENTS_FOR_PATIENT = gql`
  query upcomingAppointmentsForPatient($patientId: Int!) {
    upcomingAppointmentsForPatient(patientId: $patientId) {
      id
      date
      startTime
      status
      provider {
        id
        displayName
      }
    }
  }
`;

const APPOINTMENTS_HISTORY = gql`
  query getPatientAppointmentsHistory {
    getPatientAppointmentsHistory {
      id
      date
      startTime
      status
      appointmentTypeId
      provider {
        id
        displayName
      }
    }
  }
`;

const ADMIN_APPOINTMENTS_HISTORY_FOR_PATIENT = gql`
  query adminGetPatientAppointmentsHistory($patientId: String!) {
    adminGetPatientAppointmentsHistory(patientId: $patientId) {
      id
      date
      startTime
      status
      provider {
        id
        displayName
      }
    }
  }
`;

const APPOINTMENTS_NUTRITIONIST = gql`
  query appointmentsNutritionist {
    appointmentsNutritionist {
      id
      date
      startTime
      status
      appointmentTypeId
      timezoneName
      provider {
        id
        displayName
        zocdocVideoLink
        picture
      }
    }
  }
`;

const ADD_TO_STATE_WAITING_LIST = gql`
  mutation addToStateWaitingList($email: String!, $state: String!) {
    addToStateWaitingList(email: $email, state: $state) {
      ok
      regionLevel
    }
  }
`;

const BOOK_APPT = gql`
  mutation BookAppt($id: String!, $appointmentDetails: AppointmentInput) {
    bookAppointment(id: $id, appointmentDetails: $appointmentDetails) {
      ok
      appointmentId
      appointmentTypeId
    }
  }
`;

const BOOK_APPT_MENO = gql`
  mutation bookAppointmentMeno($id: String!, $appointmentDetails: AppointmentInput) {
    bookAppointmentMeno(id: $id, appointmentDetails: $appointmentDetails) {
      ok
      appointmentId
      appointmentTypeId
    }
  }
`;

const TIME_DOESNT_WORK = gql`
  mutation timeDoesntWork($weekMoment: [String], $weekDays: [String], $momentOfDay: [String], $personalNote: String, $email: String, $doctorName: String!, $state: String!, $hasRecentLabs: String) {
    timeDoesntWork(
      weekMoment: $weekMoment
      weekDays: $weekDays
      momentOfDay: $momentOfDay
      personalNote: $personalNote
      email: $email
      doctorName: $doctorName
      state: $state
      hasRecentLabs: $hasRecentLabs
    ) {
      ok
    }
  }
`;

const CANCEL_APPT = gql`
  mutation CancelAppt($id: String!, $enforcePatientId: Int, $bypassReasonEmail: Boolean, $byDisqualification: Boolean) {
    cancelAppointment(id: $id, enforcePatientId: $enforcePatientId, bypassReasonEmail: $bypassReasonEmail, byDisqualification: $byDisqualification) {
      ok
    }
  }
`;

const CANCEL_APPT_MENO = gql`
  mutation CancelApptMeno($id: String!, $enforcePatientId: Int, $bypassReasonEmail: Boolean) {
    cancelAppointmentMeno(id: $id, enforcePatientId: $enforcePatientId, bypassReasonEmail: $bypassReasonEmail) {
      ok
    }
  }
`;

const FORCE_CANCEL_APPT_LOCAL = gql`
  mutation forceCancelAppointmentLocal($id: String!, $patientId: Int) {
    forceCancelAppointmentLocal(id: $id, patientId: $patientId) {
      ok
    }
  }
`;

const RESCHED_APPT = gql`
  mutation ReschedAppt($id: String!, $newId: String!, $appointmentDetails: AppointmentInput) {
    rescheduleAppointment(id: $id, newId: $newId, appointmentDetails: $appointmentDetails) {
      id
      appointmentTypeId
    }
  }
`;

const RESCHED_APPT_FOR_PATIENT = gql`
  mutation rescheduleAppointmentForPatient($id: String!, $newId: String!, $patientId: Int!, $appointmentDetails: AppointmentInput) {
    rescheduleAppointmentForPatient(id: $id, newId: $newId, patientId: $patientId, appointmentDetails: $appointmentDetails) {
      id
      appointmentTypeId
    }
  }
`;

const RESCHED_APPT_MENO = gql`
  mutation ReschedApptMeno($id: String!, $newId: String!, $appointmentDetails: AppointmentInput) {
    rescheduleAppointmentMeno(id: $id, newId: $newId, appointmentDetails: $appointmentDetails) {
      id
      appointmentTypeId
    }
  }
`;

const RESCHED_APPT_FOR_PATIENT_MENO = gql`
  mutation rescheduleAppointmentForPatientMeno($id: String!, $newId: String!, $patientId: Int!, $appointmentDetails: AppointmentInput) {
    rescheduleAppointmentForPatientMeno(id: $id, newId: $newId, patientId: $patientId, appointmentDetails: $appointmentDetails) {
      id
      appointmentTypeId
    }
  }
`;

const ADMIN_UPDATE_CUSTOMER_EMAIL = gql`
  mutation AdminUpdateCustomerEmail($oldEmail: String!, $newEmail: String!) {
    adminUpdateCustomerEmail(oldEmail: $oldEmail, newEmail: $newEmail) {
      ok
      customError {
        code
        userMessage
        errorMessage
      }
    }
  }
`;

const ADMIN_MAP_LOCALDB_ACCOUNT_TO_ATHENA_ID = gql`
  mutation mapAccountLocalDbToAthenaId($email: String!, $athenaId: String!) {
    mapAccountLocalDbToAthenaId(email: $email, athenaId: $athenaId) {
      ok
      customError {
        code
        userMessage
        errorMessage
      }
    }
  }
`;

const ADMIN_REMAP_MOBILEUSER_ASSOCIATED_USER = gql`
  mutation remapMobileUserAssociatedUser($email: String!) {
    remapMobileUserAssociatedUser(email: $email) {
      ok
      customError
    }
  }
`;

const ADMIN_CHANGE_STRIPE_ID = gql`
  mutation changeStripeId($stripeId: String!, $athenaId: String!) {
    changeStripeId(stripeId: $stripeId, athenaId: $athenaId) {
      ok
      customError {
        code
        userMessage
        errorMessage
      }
    }
  }
`;

const ADMIN_CHANGE_HELPSCOUT_ID = gql`
  mutation changeHelpscoutId($helpscoutId: String!, $athenaId: String!) {
    changeHelpscoutId(helpscoutId: $helpscoutId, athenaId: $athenaId) {
      ok
      customError {
        code
        userMessage
        errorMessage
      }
    }
  }
`;

const ADMIN_DELETE_MOBILE_USER = gql`
  mutation deleteMobileUser($email: String!) {
    deleteMobileUser(email: $email) {
      ok
    }
  }
`;

const ADMIN_BATCH_MIGRATE_PATIENTS_TO_NEW_CARE_MANAGER = gql`
  mutation batchMigratePatientsToNewCareManager($file: String!) {
    batchMigratePatientsToNewCareManager(file: $file) {
      ok
      customError {
        code
        userMessage
        errorMessage
      }
      result {
        record
        result
        reason
      }
    }
  }
`;

const ADMIN_BATCH_MIGRATE_PATIENTS_TO_NEW_PROVIDER_GROUP = gql`
  mutation batchMigratePatientsToNewProviderGroup($file: String!) {
    batchMigratePatientsToNewProviderGroup(file: $file) {
      ok
      customError {
        code
        userMessage
        errorMessage
      }
      result {
        record
        result
        reason
      }
    }
  }
`;

const RESET_PATIENT_INTAKE_STATUS = gql`
  mutation resetPatientIntakeStatus($patientId: String!, $targetType: String) {
    resetPatientIntakeStatus(patientId: $patientId, targetType: $targetType) {
      ok
    }
  }
`;

const ADMIN_UNREGISTER_KIT_LOCALDB = gql`
  mutation adminUnregisterKitLocalDb($serial: String!) {
    adminUnregisterKitLocalDb(serial: $serial) {
      ok
      customError {
        code
        userMessage
        errorMessage
      }
    }
  }
`;

const GET_FEATURE_TOGGLES_LIST = gql`
  query getFeatureTogglesList {
    getFeatureTogglesList {
      isActive
      name
      friendlyName
    }
  }
`;

const GET_ONE_TIME_COUPONS_LIST = gql`
  query getOneTimeCouponsList {
    getOneTimeCouponsList {
      id
      isActive
      name
      friendlyName
    }
  }
`;

const ADMIN_LIST_RECIPES = gql`
  query adminListRecipes {
    adminListRecipes {
      id
      airtableId
      name
      image
      serving
      activeTimeMinutes
      totalTimeMinutes
      calories
      ingredients
      instructions
      options
      author
      authorBio
      authorImage
      instagramLink
      authorWebsite
      highProtein
      balanced
      lowFat
      highFiber
      lowCarb
      lowSodium
      vegan
      vegetarian
      paleo
      dairyFree
      glutenFree
      wheatFree
      fatFree
      lowSugar
      eggFree
      nightshadeFree
      alcoholFree
      caffeineFree
      peanutFree
      treeNutFree
      soyFree
      fishFree
      shellfishFree
      breakfast
      lunch
      dinner
      snack
      active
    }
  }
`;

const ADD_FEATURE_TOGGLE = gql`
  mutation addFeatureToggle($name: String!, $friendlyName: String!, $isActive: Boolean) {
    addFeatureToggle(name: $name, friendlyName: $friendlyName, isActive: $isActive) {
      ok
    }
  }
`;

const UPDATE_FEATURE_TOGGLE = gql`
  mutation updateFeatureToggle($name: String!, $friendlyName: String!, $isActive: Boolean) {
    updateFeatureToggle(name: $name, friendlyName: $friendlyName, isActive: $isActive) {
      ok
    }
  }
`;

const ADD_ONE_TIME_COUPON = gql`
  mutation addOneTimeCoupon($name: String!, $friendlyName: String!, $isActive: Boolean) {
    addOneTimeCoupon(name: $name, friendlyName: $friendlyName, isActive: $isActive) {
      ok
    }
  }
`;

const UPDATE_ONE_TIME_COUPON = gql`
  mutation updateOneTimeCoupon($name: String!, $friendlyName: String!, $isActive: Boolean) {
    updateOneTimeCoupon(name: $name, friendlyName: $friendlyName, isActive: $isActive) {
      ok
    }
  }
`;

const REQUEST_FOR_SUPERBILL = gql`
  mutation requestForSuperbill($appointmentId: String!, $providerName: String!, $appointmentDateTime: String!) {
    requestForSuperbill(appointmentId: $appointmentId, providerName: $providerName, appointmentDateTime: $appointmentDateTime) {
      ok
    }
  }
`;

const REQUEST_FOR_REFILL = gql`
  mutation requestForRefill($details: String) {
    requestForRefill(details: $details) {
      ok
    }
  }
`;

const REQUEST_FOR_ITEMIZED_RECEIPT = gql`
  mutation requestItemizedReceipt($orderId: String!) {
    requestItemizedReceipt(orderId: $orderId) {
      ok
    }
  }
`;

const LABS = gql`
  query Labs {
    labs {
      id
      status
      created
      resultedAt
      orderId
      orderDate
      results {
        pdf
        outcome {
          hormone
          value
          unit
          referenceRange
          abnormal
        }
      }
      currentStep {
        step
        eta
        returnStatus
        delivered
      }
      biomarkers
    }
  }
`;

const GET_MY_WAITLIST = gql`
  query getMyWaitlist {
    getMyWaitlist {
      id
      providerName
      providerPicture
    }
  }
`;

const GET_LAB_ORDERS_LIST = gql`
  query getLabOrdersReady {
    getLabOrdersReady {
      id
      encounterId
      ordered
      createdOn
      orderIds
    }
  }
`;

const GET_LAB_ORDER = gql`
  query getLabOrder($encounterId: String!) {
    getLabOrder(encounterId: $encounterId) {
      patient {
        athenaId
        dob
        email
        lastName
        firstName
        address {
          street1
          street2
          zip
          city
          state
        }
        phone
        sex
      }
      palomaAccount {
        header
        accountNumber
        accountName
        address
        city
        zip
        state
        phone
        fax
      }
      order {
        requisitionNumber
        orderingProvider
        collectionDate
        collectionTime
        npi
        signedOn
        generatedOn
        signature
      }
      clinicalLab {
        clinicalprovidername
        address
        zip
        city
        state
      }
      diagnosis {
        diagnosisicd {
          description
          code
          codeset
        }
        orders {
          description
          code
        }
        diagnosis
        diagnosissnomed
      }
    }
  }
`;

const GET_LABS_LIST = gql`
  query patientGetLabLists {
    patientGetLabLists {
      released {
        date
        labResultId
        attachmentExists
        lab
        status
        description
        pwnId
        orderId
        orderDate
        pdf
        analytes {
          analyte
          abnormalflag
          value
          unit
          status
          isManualUpload
          resultStatus
          analyteid
          referenceRange
          id
        }
      }
      pendings {
        id
        status
        created
        resultedAt
        orderId
        orderDate
        results {
          pdf
          outcome {
            hormone
            value
            unit
            referenceRange
            abnormal
          }
        }
        currentStep {
          step
          eta
          returnStatus
          delivered
        }
        biomarkers
      }
    }
  }
`;

const IS_DIAGNOSED = gql`
  query isDiagnosed {
    isDiagnosed
  }
`;

const ADMIN_REPORTS_STATUS = gql`
  query getAdminReportStatus {
    getAdminReportStatus {
      id
      friendlyName
      constantName
      isActive
      link
      envVariable
      status
      lastProcessed
      processStartedAt
      description
      category
      subCategory
      canViewData
    }
  }
`;

const RESET_ADMIN_REPORT = gql`
  mutation resetAdminReport($id: String!) {
    resetAdminReport(id: $id) {
      ok
    }
  }
`;

const GENERATE_ADMIN_REPORT = gql`
  mutation generateAdminReport($id: String!, $startDate: String, $endDate: String) {
    generateAdminReport(id: $id, startDate: $startDate, endDate: $endDate) {
      ok
    }
  }
`;

const ADMIN_BATCH_CREATE_SLOTS = gql`
  mutation adminBatchCreateSlots {
    adminBatchCreateSlots {
      count
    }
  }
`;

const UPDATE_CARD = gql`
  mutation UpdateCard($source: String!) {
    updateCard(source: $source) {
      ok
    }
  }
`;

const UPDATE_ATHENA_CARD = gql`
  mutation UpdateAthenaCard($accountNumber: String!, $cvc: String!, $expMonth: String!, $expYear: String!, $zip: String, $street1: String, $addMembership: String) {
    updateAthenaCard(data: { accountNumber: $accountNumber, cvc: $cvc, expMonth: $expMonth, expYear: $expYear, zip: $zip, street1: $street1, addMembership: $addMembership }) {
      ok
    }
  }
`;

const QUADPAY_SUBSCRIBED = gql`
  mutation quadPaySubscribed($accountNumber: String!, $cvc: String!, $expMonth: Int!, $expYear: Int!, $quadpayOrderReference: String!) {
    quadPaySubscribed(data: { accountNumber: $accountNumber, cvc: $cvc, expMonth: $expMonth, expYear: $expYear, quadpayOrderReference: $quadpayOrderReference }) {
      ok
    }
  }
`;

const COMPLETE_PROFILE_ADD_CARD = gql`
  mutation completeProfileAddCard($patientId: String!, $accountNumber: String!, $cvc: String!, $expMonth: String!, $expYear: String!, $zip: String, $street1: String) {
    completeProfileAddCard(data: { patientId: $patientId, accountNumber: $accountNumber, cvc: $cvc, expMonth: $expMonth, expYear: $expYear, zip: $zip, street1: $street1 }) {
      ok
    }
  }
`;

const COMPLETE_PROFILE_REGISTRATION = gql`
  mutation completeProfileRegistration(
    $uid: String!
    $email: String!
    $phone: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $street1: String!
    $street2: String
    $city: String!
    $state: String!
    $zip: String!
    $dob: String!
    $gender: String!
    $diagnosed: Boolean!
    $medicationHistoryConsentVerified: Boolean
    $genderidentity: String
    $genderidentityother: String
    $preferredpronouns: String
    $assignedsexatbirth: String
  ) {
    completeProfileRegistration(
      data: {
        uid: $uid
        email: $email
        phone: $phone
        password: $password
        firstName: $firstName
        lastName: $lastName
        street1: $street1
        street2: $street2
        city: $city
        state: $state
        zip: $zip
        dob: $dob
        gender: $gender
        diagnosed: $diagnosed
        medicationHistoryConsentVerified: $medicationHistoryConsentVerified
        genderidentity: $genderidentity
        genderidentityother: $genderidentityother
        preferredpronouns: $preferredpronouns
        assignedsexatbirth: $assignedsexatbirth
      }
    ) {
      token
      user {
        id
      }
    }
  }
`;

const GET_PENDING_PROFILE_COMPLETION_USERS = gql`
  query getPendingProfileCompletionUsers {
    getPendingProfileCompletionUsers {
      id
      athenaId
      email
      firstName
      lastName
      departmentId
      providerId
      primaryProviderId
      profileCompletionUid
      appointmentProviderId
      appointmentDate
      collateralFrozenSlotsCount
    }
  }
`;

const RESEND_COMPLETE_PROFILE_EMAIL = gql`
  mutation resendCompleteProfileEmail($profileCompletionUid: String!) {
    resendCompleteProfileEmail(profileCompletionUid: $profileCompletionUid) {
      ok
    }
  }
`;

const CHARTS = gql`
  mutation Charts($data: [ChartInput!]!, $intakeStatus: String, $intakeDate: String, $intakeType: String) {
    charts(data: $data, intakeStatus: $intakeStatus, intakeDate: $intakeDate, intakeType: $intakeType) {
      ok
    }
  }
`;

const PRESCRIPTION = gql`
  query Prescription($id: String!) {
    prescription(id: $id) {
      encounterId
      orderType
      prescriptionId
      providerId
      description
      instructions
      patientNote
      rxDate
      quantityValue
      dosageQuantity
      usarx {
        name
        slug
        strength
        quantity
        price {
          npi
          name
          independent
          currency
          price
          longitude
          latitude
          zipcode
          state
          city
          phone
          fax
          address1
          address2
          coupon
        }
      }
    }
  }
`;

const SET_PHARMACY = gql`
  mutation SetPharmacy($placeId: String!, $name: String!, $formattedAddress: String!, $phone: String!, $pharmacyState: String) {
    setPharmacy(pharmacyState: $pharmacyState, data: { placeId: $placeId, name: $name, formattedAddress: $formattedAddress, phone: $phone }) {
      ok
    }
  }
`;

const SET_LAB_CENTER = gql`
  mutation SetLabCenter($placeId: String!, $name: String!, $formattedAddress: String!, $phone: String!) {
    setLabCenter(data: { placeId: $placeId, name: $name, formattedAddress: $formattedAddress, phone: $phone }) {
      ok
    }
  }
`;

const KIT_PRICE = gql`
  query KitPrice {
    kitPrice {
      price
    }
  }
`;

const KIT_PRICE_ACTIVATION = gql`
  query kitPriceActivation {
    kitPriceActivation {
      price
    }
  }
`;

const KIT_PRICE_UNAUTHENTICATED = gql`
  query kitPriceUnauthenticated {
    kitPriceUnauthenticated {
      price
    }
  }
`;

const GET_CLAIM_KIT_INFORMATION = gql`
  query getClaimKitInformation($id: String!) {
    getClaimKitInformation(id: $id) {
      id
      purchaserName
      purchaserEmail
      refereeName
      refereeEmail
      personalMessage
      kitClaimed
      error
    }
  }
`;

const GET_LAST_ORDER_MINUTES_DIFF = gql`
  query getLastOrderMinutesDif($email: String!) {
    getLastOrderMinutesDif(email: $email) {
      elapsedMinutes
    }
  }
`;

const GET_MY_REFERRAL_INFORMATION = gql`
  query getMyReferralInformation {
    getMyReferralInformation {
      link
      coupon
      centisizedCollectedCredits
      centisizedUsedCredits
      centisizedRemainingCredits
      referreeCount
    }
  }
`;

const GET_MY_REFERRALS_HISTORY = gql`
  query getMyReferralsHistory {
    getMyReferralsHistory {
      id
      date
      isAdminAction
      description
      centisizedAmount
      status
      flag
    }
  }
`;

const CHECK_PURCHASE_REFERRAL_BENEFITS = gql`
  query checkPurchaseReferralBenefits($skus: [String]) {
    checkPurchaseReferralBenefits(skus: $skus) {
      referralCoupon
      centisizedBalance
    }
  }
`;

const ADMIN_GET_REFERRAL_LIST = gql`
  query adminGetReferralList($from: Int, $take: Int, $filter: String) {
    adminGetReferralList(from: $from, take: $take, filter: $filter) {
      pageCount
      data {
        id
        userId
        email
        coupon
        link
        name
        centisizedCollectedCredits
        centisizedUsedCredits
        centisizedBalance
        referreesCount
        isActive
        deactivationReason
        flag
        historyEventsCount
      }
    }
  }
`;

const ADMIN_SEND_REF_HISTO = gql`
  mutation adminSendReferralsHisto {
    adminSendReferralsHisto
  }
`;

const ADMIN_GET_REFERRAL_HISTORY = gql`
  query adminGetReferralHistory($referralId: String!) {
    adminGetReferralHistory(referralId: $referralId) {
      id
      date
      isAdminAction
      description
      centisizedAmount
      status
      flag
      ip
    }
  }
`;

const ADMIN_ADJUST_REFERRER_CREDITS = gql`
  mutation adminAdjustReferrerCredits($centisizedAmount: Int!, $referralLinkId: String!, $description: String!) {
    adminAdjustReferrerCredits(centisizedAmount: $centisizedAmount, referralLinkId: $referralLinkId, description: $description) {
      ok
    }
  }
`;

const GENERATE_REFERRAL_LINK = gql`
  mutation generateReferralLink {
    generateReferralLink {
      link
      coupon
      centisizedCollectedCredits
      centisizedUsedCredits
      centisizedRemainingCredits
      referreeCount
    }
  }
`;

const REFERRAL_SEND_FRIEND_EMAIL = gql`
  mutation referralSendFriendEmail($email: String!) {
    referralSendFriendEmail(email: $email) {
      ok
    }
  }
`;

const ADD_NEW_REFERRAL = gql`
  mutation addNewReferral($coupon: String!, $email: String, $orderId: String) {
    addNewReferral(coupon: $coupon, email: $email, orderId: $orderId) {
      ok
    }
  }
`;

const BATCH_UPLOAD_REFERRALS = gql`
  mutation batchUploadReferrals($patientId: String!, $centisizedAmount: Int!, $description: String!) {
    batchUploadReferrals(patientId: $patientId, centisizedAmount: $centisizedAmount, description: $description) {
      newOrUpdate
      result
      patientId
    }
  }
`;

const CLAIM_KIT = gql`
  mutation claimKit($id: String!, $firstName: String, $lastName: String!, $street1: String!, $street2: String, $city: String!, $state: String!, $zip: String!, $email: String!) {
    claimKit(id: $id, firstName: $firstName, lastName: $lastName, street1: $street1, street2: $street2, city: $city, state: $state, zip: $zip, email: $email) {
      ok
      reason
    }
  }
`;

const GET_ADMIN_REPORTS_DATA = gql`
  query getAdminReportData($id: String!) {
    getAdminReportData(id: $id)
  }
`;

const APPT_SUMMARY = gql`
  query ApptSummary($id: String!) {
    appointmentSummary(id: $id) {
      instructions
      goals
      discussionNotes
      assessment
    }
  }
`;

const REGISTER_KIT = gql`
  mutation RegisterKit($serial: String!, $biomarkers: [String]) {
    registerKit(serial: $serial, biomarkers: $biomarkers) {
      ok
    }
  }
`;

const REMOVE_PCP = gql`
  mutation removePcp {
    removePcp {
      ok
    }
  }
`;

const SET_PCP = gql`
  mutation SetPcp(
    $practice: String
    $address: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $county: String
    $phone: String
    $fax: String
    $firstname: String
    $lastname: String
    $fullname: String
    $email: String
    $gender: String
    $title: String
    $specialty: String
    $sicCode: String
    $locationAddress: String
    $specialty2: String
    $specialty3: String
    $specialty4: String
    $specialty5: String
    $npi: String
    $internalId: String
  ) {
    setPcp(
      data: {
        practice: $practice
        address: $address
        address2: $address2
        city: $city
        state: $state
        zip: $zip
        county: $county
        phone: $phone
        fax: $fax
        firstname: $firstname
        lastname: $lastname
        fullname: $fullname
        email: $email
        gender: $gender
        title: $title
        specialty: $specialty
        sicCode: $sicCode
        locationAddress: $locationAddress
        specialty2: $specialty2
        specialty3: $specialty3
        specialty4: $specialty4
        specialty5: $specialty5
        npi: $npi
        internalId: $internalId
      }
    ) {
      ok
    }
  }
`;

const GET_USER_PCP = gql`
  query getPcp {
    getPcp {
      id
      practice
      address
      address2
      city
      state
      zip
      county
      phone
      fax
      firstname
      lastname
      fullname
      email
      gender
      title
      specialty
      sicCode
      locationAddress
      specialty2
      specialty3
      specialty4
      specialty5
      npi
      internalId
    }
  }
`;

const SEARCH_PCPS = gql`
  query findPcp($filter: String!) {
    findPcp(filter: $filter) {
      id
      practice
      address
      address2
      city
      state
      zip
      county
      phone
      fax
      firstname
      lastname
      fullname
      email
      gender
      title
      specialty
      sicCode
      locationAddress
      specialty2
      specialty3
      specialty4
      specialty5
      npi
      internalId
    }
  }
`;

const NOTIFY_COULDNT_CONFIRM_CARD = gql`
  query notifyCouldntConfirmCard($patientId: String) {
    notifyCouldntConfirmCard(patientId: $patientId) {
      ok
    }
  }
`;

const GENERATE_REPORT_STRIPE_CONSOLIDATION = gql`
  query stripeOrdersConsolidationReport {
    stripeOrdersConsolidationReport {
      ok
    }
  }
`;

const GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES = gql`
  query getSymptomTrackerCategories {
    getSymptomTrackerCategories {
      id
      archived
      active
      name
      description
      color
    }
  }
`;

const ADD_SYMPTOM_TRACKER_MARKER = gql`
  mutation addSymptomTrackerMarker($data: SymptomsTrackerMarkerInput!) {
    addSymptomTrackerMarker(data: $data) {
      ok
    }
  }
`;

const DELETE_SYMPTOM_TRACKER_MARKER = gql`
  mutation deleteSymptomTrackerMarker($id: String!) {
    deleteSymptomTrackerMarker(id: $id) {
      ok
    }
  }
`;

const ADD_SYMPTOM_TRACKER_CATEGORY = gql`
  mutation addSymptomTrackerCategory($name: String!, $color: String!, $description: String, $isActive: Boolean!, $textLineColor: String!) {
    addSymptomTrackerCategory(name: $name, color: $color, description: $description, textLineColor: $textLineColor, isActive: $isActive) {
      ok
    }
  }
`;

const ADD_SYMPTOM_TRACKER_CATEGORY_UT = gql`
  mutation addSymptomTrackerCategoryUt($id: String, $name: String!, $color: String!, $description: String, $isActive: Boolean!, $textLineColor: String!) {
    addSymptomTrackerCategoryUt(id: $id, name: $name, color: $color, description: $description, textLineColor: $textLineColor, isActive: $isActive) {
      ok
    }
  }
`;

const DELETE_SYMPTOM_TRACKER_MARKER_UT = gql`
  mutation deleteSymptomTrackerMarkerUt($id: String!) {
    deleteSymptomTrackerMarkerUt(id: $id) {
      ok
    }
  }
`;

const ADD_SYMPTOM_TRACKER_MARKER_UT = gql`
  mutation addSymptomTrackerMarkerUt($data: SymptomsTrackerMarkerInput!) {
    addSymptomTrackerMarkerUt(data: $data) {
      ok
    }
  }
`;

const GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES_UT = gql`
  query getSymptomTrackerCategoriesUt {
    getSymptomTrackerCategoriesUt {
      id
      archived
      active
      name
      description
      textLineColor
      color
    }
  }
`;

const ADD_MOBILE_COURSE_JOURNEY = gql`
  mutation addMobileCourseJourney($title: String!, $description: String!, $isActive: Boolean, $isPro: Boolean) {
    addMobileCourseJourney(course: { title: $title, description: $description, isActive: $isActive, isPro: $isPro }) {
      ok
    }
  }
`;

const ADD_MOBILE_COURSE_SCREEN_FORMAT = gql`
  mutation addMobileCourseScreenFormat($name: String!, $color: String!) {
    addMobileCourseScreenFormat(screenFormat: { name: $name, color: $color }) {
      ok
    }
  }
`;

const ADMIN_GET_MOBILE_COURSE_JOURNEYS = gql`
  query adminGetMobileCourseJourneys {
    adminGetMobileCourseJourneys {
      id
      title
      description
      isActive
      isPro
    }
  }
`;

const ADMIN_GET_MOBILE_COURSE_SCREEN_FORMATS = gql`
  query adminGetMobileCourseScreenFormats {
    adminGetMobileCourseScreenFormats {
      id
      name
      color
    }
  }
`;

const ADMIN_GET_MOBILE_COURSES = gql`
  query adminGetMobileCourses {
    adminGetMobileCourses {
      id
      title
      description
      isActive
      isPro
      journey {
        id
        title
        description
        isActive
        isPro
      }
      week
      order
      screensColor
      hormoneColor
      icon
      webflowArticleId
      externalArticleLink
      isFoodJournaling
    }
  }
`;

const ADMIN_ADD_MOBILE_COURSE = gql`
  mutation adminAddMobileCourse(
    $id: String
    $title: String!
    $description: String
    $isActive: Boolean!
    $isPro: Boolean!
    $journeyId: String!
    $week: Int!
    $order: Int!
    $screensColor: String!
    $hormoneColor: String!
    $icon: String!
    $webflowArticleId: String
    $externalArticleLink: String
    $isFoodJournaling: Boolean
  ) {
    adminAddMobileCourse(
      value: {
        id: $id
        title: $title
        description: $description
        isActive: $isActive
        isPro: $isPro
        journeyId: $journeyId
        week: $week
        order: $order
        screensColor: $screensColor
        hormoneColor: $hormoneColor
        icon: $icon
        webflowArticleId: $webflowArticleId
        externalArticleLink: $externalArticleLink
        isFoodJournaling: $isFoodJournaling
      }
    ) {
      ok
    }
  }
`;

const ADMIN_DELETE_MOBILE_COURSE = gql`
  mutation adminDeleteCourse($courseId: String!) {
    adminDeleteCourse(courseId: $courseId) {
      ok
    }
  }
`;

const ADMIN_MOBILE_CHANGE_ORDER_COURSE = gql`
  mutation adminChangeOrderCourse($courseId: String!, $direction: String!) {
    adminChangeOrderCourse(courseId: $courseId, direction: $direction) {
      ok
    }
  }
`;

const ADMIN_GET_MOBILE_COURSE_SCREENS = gql`
  query adminGetMobileCourseScreens($courseId: String!) {
    adminGetMobileCourseScreens(courseId: $courseId) {
      id
      Tags
      ScreenFormat {
        id
        name
        color
      }
      Content
      isActive
      isPro
      order
      title
      nextOverride
      hormoneY
      textStyle
      titleStyle
      buttons
      options
      formulaDisplay
      webflowArticleId
      externalArticleLink
      trackingConfig {
        id
        name
        title
        labelsGroupId
        negativeColor
        positiveColor
        trackColor
        knobColor
        titleColor
        textColor
        lastValuesIndicatorColor
      }
    }
  }
`;

const ADMIN_ADD_MOBILE_COURSE_SCREEN = gql`
  mutation adminAddMobileCourseScreen(
    $id: String
    $courseId: String!
    $Tags: [String]
    $ScreenFormatId: String
    $Content: String
    $isActive: Boolean!
    $isPro: Boolean!
    $order: Int
    $nextOverride: Int
    $title: String
    $hormoneY: Int!
    $formula: String
    $textStyle: String
    $titleStyle: String
    $buttons: String
    $options: String
    $icon: String
    $webflowArticleId: String
    $externalArticleLink: String
    $trackingConfig: CourseScreenTrackerMarkerInput
  ) {
    adminAddMobileCourseScreen(
      value: {
        id: $id
        courseId: $courseId
        Tags: $Tags
        ScreenFormatId: $ScreenFormatId
        Content: $Content
        isActive: $isActive
        isPro: $isPro
        order: $order
        title: $title
        hormoneY: $hormoneY
        nextOverride: $nextOverride
        textStyle: $textStyle
        titleStyle: $titleStyle
        buttons: $buttons
        options: $options
        formula: $formula
        icon: $icon
        webflowArticleId: $webflowArticleId
        externalArticleLink: $externalArticleLink
        trackingConfig: $trackingConfig
      }
    ) {
      ok
    }
  }
`;

const ADMIN_SET_MOBILE_USER_WEEK = gql`
  mutation adminSetMobileUserWeek($email: String!, $weeknumber: Int!, $insertFakeSymptomsTrackValues: Boolean) {
    adminSetMobileUserWeek(email: $email, weeknumber: $weeknumber, insertFakeSymptomsTrackValues: $insertFakeSymptomsTrackValues) {
      ok
    }
  }
`;

const ADMIN_DELETE_MOBILE_COURSE_SCREEN = gql`
  mutation adminDeleteCourseScreen($screenId: String!) {
    adminDeleteCourseScreen(screenId: $screenId) {
      ok
    }
  }
`;

const ADMIN_GET_WEBFLOW_ARTICLES = gql`
  query adminGetArticlesList {
    adminGetArticlesList {
      id
      archived
      draft
      thumbnailUrl
      tag {
        id
        name
      }
      title
      author
      slug
      categories {
        id
        name
      }
      webflowCid
      webflowId
      featured
      week1
      week2
      week3
      week4
      week5
      week6
      week7
      week8
      week9
      week10
      week11
      week12
    }
  }
`;

const ADMIN_TRIGGER_WEBFLOW_ARTICLE_UPDATE = gql`
  mutation adminTriggerWebflowArticlesUpdate {
    adminTriggerWebflowArticlesUpdate {
      ok
    }
  }
`;

const ADMIN_TRIGGER_RECIPES_UPDATE = gql`
  mutation UpdateFromAirtable {
    UpdateFromAirtable {
      ok
    }
  }
`;

const ADMIN_GET_MOTIVATIONAL_QUOTES = gql`
  query getMotivationalQuotes {
    getMotivationalQuotes {
      id
      text
      author
    }
  }
`;

const ADMIN_ADD_MOTIVATIONAL_QUOTES = gql`
  mutation addMotivationalQuote($text: String!, $author: String) {
    addMotivationalQuote(text: $text, author: $author) {
      ok
    }
  }
`;

const ADMIN_COURSE_SCREEN_REMAP_HORMONE = gql`
  mutation adminCourseScreensRemapHormone($courseId: String!) {
    adminCourseScreensRemapHormone(courseId: $courseId) {
      ok
    }
  }
`;

const ADMIN_MOBILE_CHANGE_ORDER_COURSE_SCREEN = gql`
  mutation adminChangeOrderCourseScreen($screenId: String!, $direction: String!) {
    adminChangeOrderCourseScreen(screenId: $screenId, direction: $direction) {
      ok
    }
  }
`;

const ADD_SYMPTOM_TRACKER_LABELS_GROUP = gql`
  mutation addSymptomTrackerGroup($labels: [SymptomTrackerLabelInput]!) {
    addSymptomTrackerGroup(labels: $labels) {
      ok
    }
  }
`;

const GET_SYMPTOM_TRACKER_LABELS_GROUPS = gql`
  query getSymptomTrackerLabelsGroups {
    getSymptomTrackerLabelsGroups {
      id
      labels {
        id
        label
        value
      }
    }
  }
`;

const GET_MOBILE_SYMPTOM_TRACKERS = gql`
  query getSymptomTrackers {
    getSymptomTrackers {
      id
      archived
      active
      question
      config {
        id
        title
        labelsGroup {
          id
          labels {
            id
            label
            value
          }
        }
        negativeColor
        positiveColor
        trackColor
        knobColor
        titleColor
        textColor
        lastValuesIndicatorColor
      }
      category {
        id
        archived
        active
        name
        description
        color
      }
      name
    }
  }
`;

const ADD_SYMPTOM_TRACKER_LABELS_GROUP_UT = gql`
  mutation addSymptomTrackerGroupUt($id: String, $labels: [SymptomTrackerLabelInput]!) {
    addSymptomTrackerGroupUt(id: $id, labels: $labels) {
      ok
    }
  }
`;

const GET_SYMPTOM_TRACKER_LABELS_GROUPS_UT = gql`
  query getSymptomTrackerLabelsGroupsUt {
    getSymptomTrackerLabelsGroupsUt {
      id
      labels {
        id
        label
        value
      }
    }
  }
`;

const GET_MOBILE_SYMPTOM_TRACKERS_UT = gql`
  query getSymptomTrackersUt {
    getSymptomTrackersUt {
      id
      archived
      active
      question
      config {
        id
        title
        labelsGroup {
          id
          labels {
            id
            label
            value
          }
        }
        negativeColor
        positiveColor
        trackColor
        knobColor
        titleColor
        textColor
        lastValuesIndicatorColor
      }
      category {
        id
        archived
        active
        name
        description
        color
      }
      name
    }
  }
`;

const GET_MOBILE_COURSE_BY_ID = gql`
  query getMobileCourseById($courseId: String!) {
    getMobileCourseById(courseId: $courseId) {
      template {
        id
        background {
          idPage
          isFullScreen
          fullbackgroundColor
          blueLeft
          blueRight
          blueTop
          blueBottom
          pinkLeft
          pinkRight
          pinkTop
          pinkBottom
        }
      }
      content {
        idPage
        type
        content {
          type
          value
          label
          style
          content {
            type
            value
            label
            style
          }
        }
      }
    }
  }
`;

const MEMBERSHIP_CREATE_SESSION = gql`
  mutation createCheckoutSession($priceId: String!, $forwardUrl: String!, $cancel_url: String, $combineVitamins: Boolean, $trial: Boolean) {
    createCheckoutSession(priceId: $priceId, forwardUrl: $forwardUrl, cancel_url: $cancel_url, combineVitamins: $combineVitamins, trial: $trial) {
      sessionId
    }
  }
`;

const CREATE_SESSION_VITAMINS_CHECKOUT_SESSION = gql`
  mutation createVitaminsCheckoutSession($forwardUrl: String!, $cancel_url: String) {
    createVitaminsCheckoutSession(forwardUrl: $forwardUrl, cancel_url: $cancel_url) {
      sessionId
    }
  }
`;

const NOTIFY_VITAMINS_BACK_IN_STOCK = gql`
  mutation notifyBackInStock {
    notifyBackInStock {
      ok
    }
  }
`;

const GET_SESSION = gql`
  query getSession($sessionId: String!) {
    getSession(sessionId: $sessionId) {
      id
      items {
        id
        amountSubtotal
        amountTotal
        description
        discounts {
          amount
        }
      }
      amountSubtotal
      amountTotal
      receipt_url
      paymentIntentId
    }
  }
`;

const CREATE_SESSION_VITAMINS_SUBSCRIPTIONS_CHECKOUT_SESSION = gql`
  mutation createVitaminsSubscriptionsCheckoutSession($forwardUrl: String!, $cancel_url: String) {
    createVitaminsSubscriptionsCheckoutSession(forwardUrl: $forwardUrl, cancel_url: $cancel_url) {
      sessionId
    }
  }
`;

const MEMBERSHIP_UPDATE_PAYMENT_CREATE_SESSION = gql`
  mutation createUpdatePaymentMethodSession($subscriptionId: String!, $customerId: String!, $forwardUrl: String!, $forwardUrlCancel: String!) {
    createUpdatePaymentMethodSession(subscriptionId: $subscriptionId, customerId: $customerId, forwardUrl: $forwardUrl, forwardUrlCancel: $forwardUrlCancel) {
      sessionId
    }
  }
`;

const DONT_CANCEL_MEMBERSHIP = gql`
  mutation dontCancelSubscription($id: String!) {
    dontCancelSubscription(id: $id) {
      ok
    }
  }
`;

const MEMBERSHIP_UPDATE_PAYMENT_METHOD = gql`
  mutation updatePaymentMethodSubscription($sessionId: String!) {
    updatePaymentMethodSubscription(sessionId: $sessionId) {
      ok
    }
  }
`;

const GET_MEMBERSHIPS = gql`
  query getMemberships {
    getMemberships {
      memberships {
        id
        isPaused
        pausedSubscription {
          resumesAt
        }
        subscriptionTarget
        hasCancellationCoupon {
          durationInMonths
          timesRedeemed
          discountPercent
        }
        price
        interval
        intervalCount
        customer
        card {
          id
          brand
          last4
        }
        nextBill {
          amount
          date
        }
        cancels {
          cancelsAt
          cancelAtPeriodEnd
        }
        receipts {
          id
          date
          reference
          amount
          link
        }
      }
    }
  }
`;

const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($subscriptionId: String!, $isMembership: Boolean) {
    cancelSubscription(subscriptionId: $subscriptionId, isMembership: $isMembership) {
      ok
    }
  }
`;

const PAUSE_SUBSCRIPTION = gql`
  mutation pauseSubscription($subscriptionId: String!, $monthsPause: Int!) {
    pauseSubscription(subscriptionId: $subscriptionId, monthsPause: $monthsPause) {
      ok
    }
  }
`;

const RESUME_SUBSCRIPTION = gql`
  mutation resumeSubscription($subscriptionId: String!) {
    resumeSubscription(subscriptionId: $subscriptionId) {
      ok
    }
  }
`;

const GET_ANALYTICS_ID = gql`
  query getAnalyticsId {
    getAnalyticsId
  }
`;

const VIEWED_PAGE = gql`
  mutation viewedPage($screen: String!) {
    viewedPage(screen: $screen) {
      ok
    }
  }
`;

const BATCH_BACKFILL = gql`
  mutation batchBackfill($origin: String!, $data: [String]!, $sendToMixPanel: Boolean, $sendToBigQuery: Boolean) {
    batchBackfill(origin: $origin, data: $data, sendToMixPanel: $sendToMixPanel, sendToBigQuery: $sendToBigQuery) {
      index
      success
      errorText
    }
  }
`;

const GET_ASYNC_TEMPORARY_RESPONSES = gql`
  query getTemporaryResponses {
    getTemporaryResponses {
      responses
      updatedAt
    }
  }
`;

const LOAD_PREVIOUS_INTAKE = gql`
  query loadPreviousIntake {
    loadPreviousIntake {
      question
      answer
      note
      questionId
    }
  }
`;

const RESET_ASYNC_TEMPORARY_RESPONSES = gql`
  mutation resetTemporaryResponses {
    resetTemporaryResponses {
      ok
    }
  }
`;

const SAVE_ASYNC_TEMPORARY_RESPONSES = gql`
  mutation saveTemporaryResponses($responses: String!) {
    saveTemporaryResponses(responses: $responses) {
      ok
    }
  }
`;

const UPLOAD_DRIVER_LICENSE = gql`
  mutation uploadDriversLicense($file: String!) {
    uploadDriversLicense(file: $file) {
      ok
    }
  }
`;

const UPLOAD_PHOTO = gql`
  mutation uploadPhoto($file: String!) {
    uploadPhoto(file: $file) {
      ok
    }
  }
`;

const UPLOAD_MEDICATION_BOTTLE_PHOTO = gql`
  mutation uploadMedicationBottlePhoto($file: String!) {
    uploadMedicationBottlePhoto(file: $file) {
      ok
    }
  }
`;

const ASYNC_ELIGIBILITY_CHECK = gql`
  query asyncEligibilityCheck($payer: String!, $plan: String!) {
    asyncEligibilityCheck(payer: $payer, plan: $plan) {
      isEligible
      canLive
    }
  }
`;
const SCHEDULER_ORDER = gql`
  mutation schedulerOrder($addons: [String]) {
    schedulerOrder(addons: $addons) {
      ok
    }
  }
`;
const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($addons: [String], $pm: String!, $values: String!, $hpiContent: String, $sourceId: String) {
    createPaymentIntent(addons: $addons, pm: $pm, values: $values, hpiContent: $hpiContent, sourceId: $sourceId) {
      clientSecret
    }
  }
`;
const GET_ASYNC_ORDER_DETAILS = gql`
  query getAsyncOrderDetails($addons: [String], $coupon: String) {
    getAsyncOrderDetails(addons: $addons, coupon: $coupon) {
      items {
        type
        price
      }
      dueToday
      totalDue
      memberSavingsAddons
      discountCoupon
      registeringForMembership
    }
  }
`;

const ASYNC_SOFT_DISQUALIFY = gql`
  mutation asyncSoftDisqualify($reason: SoftDisqualifyingReasons!) {
    asyncSoftDisqualify(reason: $reason) {
      ok
    }
  }
`;

const ASYNC_HARD_DISQUALIFY = gql`
  mutation asyncHardDisqualify($reason: HardDisqualifyingReasons!) {
    asyncHardDisqualify(reason: $reason) {
      ok
    }
  }
`;

const SEND_KLAVIYO_STATUS = gql`
  mutation sendKlaviyoAsyncStatusEvent($step: String!) {
    sendKlaviyoAsyncStatusEvent(step: $step) {
      ok
    }
  }
`;

const SEARCH_ATHENA_MEDICATION = gql`
  query searchAthenaMedication($search: String!) {
    searchAthenaMedication(search: $search) {
      medication
      medicationId
    }
  }
`;

const SEARCH_ATHENA_ALLERGY = gql`
  query searchAthenaAllergies($search: String!) {
    searchAthenaAllergies(search: $search) {
      allergyId
      allergy
      allergenName
      allergenId
    }
  }
`;

const SEND_OTP_CODE = gql`
  mutation sendOtpCode($phone: String!) {
    sendOtpCode(phone: $phone) {
      ok
    }
  }
`;

const VERIFY_OTP_CODE = gql`
  mutation verifyOptCode($phone: String!, $otp: String!) {
    verifyOptCode(phone: $phone, otp: $otp) {
      ok
      customError
    }
  }
`;

const GET_HAD_ASYNC_VISITS = gql`
  query hadAsyncVisitPast {
    hadAsyncVisitPast {
      hadVisits
    }
  }
`;

const SET_UNIVERSAL_PASSWORD = gql`
  mutation setUniversalPassword($pwd: String!) {
    setUniversalPassword(pwd: $pwd) {
      ok
    }
  }
`;

const GENERATE_CIRCLE_SO_CALLBACK = gql`
  mutation generateCircleSoCallback {
    generateCircleSoCallback {
      callback
      mustAcceptTerms
    }
  }
`;

const LOGIN_FROM_MOBILE = gql`
  mutation loginFromMobile($token: String!) {
    loginFromMobile(token: $token) {
      token
    }
  }
`;

const GET_INTAKE_PREVIOUS_VALUES = gql`
  query getCurrentIntakeValues {
    getCurrentIntakeValues {
      medicalhistory {
        question
        answer
        note
        questionId
      }
      gynhistory {
        question
        answer
        note
        questionId
      }
      allergies {
        rxnormcode
        allergenid
        allergenname
        note
      }
      familyHistory {
        relation
        problems {
          problemId
          snomedcode
          note
        }
      }
      socialHistory {
        question
        answer
        note
        questionId
      }
      surgicalHistory {
        description
        procedureid
        note
        procedurecode
      }
    }
  }
`;

const GET_MEDICATIONS_ON_FILE = gql`
  query getMedicationOnFile {
    getMedicationOnFile {
      medicationId
      medication
      dose
      frequency
    }
  }
`;

const GET_HYPO_MEDICATIONS_ON_FILE = gql`
  query getHypothyroidismMedicationOnFile {
    getHypothyroidismMedicationOnFile {
      medicationId
      medication
      dose
      frequency
    }
  }
`;

const CREATE_PROVIDER_USER = gql`
  mutation createProviderUser($email: String!, $phone: String!, $helpscoutId: Int!) {
    createProviderUser(email: $email, phone: $phone, helpscoutId: $helpscoutId) {
      ok
    }
  }
`;

const NOTIFY_NEED_CONFIRM_HYPO = gql`
  mutation notifyNeedConfirmHypo {
    notifyNeedConfirmHypo {
      ok
    }
  }
`;

const GET_MOBILE_TRACKED_SYMPTOMS = gql`
  query getMobileTrackedSymptomsFromWeb {
    getMobileTrackedSymptomsFromWeb {
      global {
        date
        hasLabs
        average
        foodTracked {
          id
          date
          time
          rating
          foodCategory
          foodItem
          symptoms
        }
      }
      details {
        id
        name
        color
        textLineColor
        values {
          id
          name
          previousAvg
          values {
            id
            date
            values
          }
        }
      }
    }
  }
`;

const GET_RANDOM_ARTICLES = gql`
  query getRandomArticlesFromWeb($howMany: Int) {
    getRandomArticlesFromWeb(howMany: $howMany) {
      title
      thumbnailUrl
      id
      tag {
        id
        name
      }
      categories {
        id
        name
      }
    }
  }
`;

const GET_ARTICLES_FOR_CATEGORY = gql`
  query getArticlesInCategoryFromWeb($categoryId: String, $take: Int, $skip: Int, $filter: String, $favorites: Boolean, $idsToExclude: [String]) {
    getArticlesInCategoryFromWeb(categoryId: $categoryId, take: $take, skip: $skip, filter: $filter, favorites: $favorites, idsToExclude: $idsToExclude) {
      title
      thumbnailUrl
      id
      content
      quickPostSummary
      readMore
      tag {
        id
        name
      }
      categories {
        id
        name
      }
    }
  }
`;

const GET_AIP_RECIPES_BY_FILTER = gql`
  query getAipRecipesByFilterFromWeb($filterText: String, $filterCategory: FilterMealTypes, $idsToExclude: [String], $excludedAllergens: [String]) {
    getAipRecipesByFilterFromWeb(filterText: $filterText, filterCategory: $filterCategory, idsToExclude: $idsToExclude, excludedAllergens: $excludedAllergens) {
      name
      image
      id
      serving
      totalTimeMinutes
      isFavorite
    }
  }
`;

const GET_ARTICLES_CATEGORIES = gql`
  query getArticleCategories {
    getArticleCategories {
      id
      name
    }
  }
`;

const GET_RANDOM_ARTICLES_WITH_TAG = gql`
  query getArticlesWithTag($limit: Int, $tag: String!) {
    getArticlesWithTag(limit: $limit, tag: $tag) {
      title
      thumbnailUrl
      id
      tag {
        id
        name
      }
      categories {
        id
        name
      }
    }
  }
`;

const GET_ARTICLE_BY_ID = gql`
  query getArticleByIdFromWeb($id: String!) {
    getArticleByIdFromWeb(id: $id) {
      title
      thumbnailUrl
      id
      content
      quickPostSummary
      readMore
      isFavorite
      tag {
        id
        name
      }
      categories {
        id
        name
      }
    }
  }
`;

const GET_LAB_CHART_VALUES = gql`
  query getLabChartValues {
    getLabChartValues {
      tpo {
        id
        date
        value
        isPaloma
      }
      tsh {
        id
        date
        value
        isPaloma
      }
      ft3 {
        id
        date
        value
        isPaloma
      }
      ft4 {
        id
        date
        value
        isPaloma
      }
      vitD {
        id
        date
        value
        isPaloma
      }
      rT3 {
        id
        date
        value
        isPaloma
      }
    }
  }
`;

const GET_AIP_RECIPES = gql`
  query getAipRecipesFromWeb {
    getAipRecipesFromWeb {
      name
      image
      id
      serving
      totalTimeMinutes
    }
  }
`;

const GET_AIP_RECIPE = gql`
  query getAipRecipeFromWeb($id: String!) {
    getAipRecipeFromWeb(id: $id) {
      id
      airtableId
      name
      image
      serving
      activeTimeMinutes
      totalTimeMinutes
      calories
      ingredients
      instructions
      options
      author
      authorBio
      authorImage
      instagramLink
      authorWebsite
      highProtein
      balanced
      lowFat
      highFiber
      lowCarb
      lowSodium
      vegan
      vegetarian
      paleo
      dairyFree
      glutenFree
      wheatFree
      fatFree
      lowSugar
      eggFree
      nightshadeFree
      alcoholFree
      caffeineFree
      peanutFree
      treeNutFree
      soyFree
      fishFree
      shellfishFree
      breakfast
      lunch
      dinner
      snack
      active
      isFavorite
    }
  }
`;

const GET_SPEAKER_SERIES = gql`
  query getSpeakerSeries {
    getSpeakerSeries {
      name
      slug
      publishedOn
      smallDescription
      duration
      videoLink
      descriptionCopy
      image
      description
      speaker_1
      codeFromHttpsAppSistrixComEnVideoSnippetGenerator
      transcript
      embedUrl
      videoLinkMp4Mov
      speaker_2
      itemId
      createdOn
      speaker_3
      updatedOn
      thumbnailUrl
      rid
      collectionId
      airtableId
      id
    }
  }
`;

const GET_THYROID_GUIDES = gql`
  query getThyroidGuides {
    getThyroidGuides {
      name
      slug
      collectionId
      itemId
      guidePdfLink
      title
      summary
      bgColor
      image
      subtitle
      description
      included1
      included1Image
      included1Description
      included2
      included2Image
      included2Description
      included3
      included3Image
      included3Description
      included4
      included4Image
      included4Description
      order
      airtableId
      id
    }
  }
`;

const SEND_MOBILE_APP_DOWNLOAD_LINK = gql`
  mutation sendMeMobileAppDownloadLink {
    sendMeMobileAppDownloadLink {
      ok
    }
  }
`;

const HAS_UNREGISTERED_KITS = gql`
  query checkHasUnregisteredKit {
    checkHasUnregisteredKit {
      hasUnregisteredKits
    }
  }
`;

const ADMIN_GET_THYROID_PROGRAM_BY_ID = gql`
  query getAdminThyroidProgramsById($id: String!) {
    getAdminThyroidProgramsById(id: $id) {
      id
      name
      description
      icon
      bgMainColor
      bgBubblesColor
      titleColor
      descriptionColor
      isActive
      isArchived
      isPro
      memberOnly
      createdAt
      updatedAt
      assignedOnly
    }
  }
`;

const ADMIN_GET_THYROID_PROGRAMS = gql`
  query getAdminThyroidPrograms {
    getAdminThyroidPrograms {
      id
      name
      description
      icon
      bgMainColor
      bgBubblesColor
      titleColor
      descriptionColor
      isActive
      isArchived
      isPro
      memberOnly
      createdAt
      updatedAt
      assignedOnly
    }
  }
`;

const ADMIN_GET_THYROID_PROGRAMS_SECTIONS = gql`
  query getAdminProgramSectionWithCourses($programId: String!) {
    getAdminProgramSectionWithCourses(programId: $programId) {
      id
      name
      description
      isActive
      isArchived
      isPro
      memberOnly
      createdAt
      updatedAt
      order
    }
  }
`;

const ADMIN_CREATE_THYROID_PROGRAMS = gql`
  mutation createUpdateThyroidPrograms(
    $id: String
    $name: String!
    $description: String
    $icon: String
    $bgMainColor: String!
    $bgBubblesColor: String!
    $titleColor: String!
    $descriptionColor: String!
    $isActive: Boolean
    $isArchived: Boolean
    $isPro: Boolean
    $memberOnly: Boolean
    $assignedOnly: Boolean
  ) {
    createUpdateThyroidPrograms(
      id: $id
      name: $name
      description: $description
      icon: $icon
      bgMainColor: $bgMainColor
      bgBubblesColor: $bgBubblesColor
      titleColor: $titleColor
      descriptionColor: $descriptionColor
      isActive: $isActive
      isArchived: $isArchived
      isPro: $isPro
      memberOnly: $memberOnly
      assignedOnly: $assignedOnly
    ) {
      id
    }
  }
`;

const ADMIN_CREATE_UPDATE_THYROID_PROGRAM_SECTION = gql`
  mutation createUpdateThyroidProgramSection($id: String, $name: String!, $programId: String!, $description: String, $isActive: Boolean, $isArchived: Boolean) {
    createUpdateThyroidProgramSection(id: $id, name: $name, description: $description, programId: $programId, isActive: $isActive, isArchived: $isArchived) {
      ok
    }
  }
`;

const ADMIN_MOBILE_CHANGE_ORDER_PROGRAM_SECTION = gql`
  mutation adminChangeOrderThyroidProgramSection($sectionId: String!, $direction: String!) {
    adminChangeOrderThyroidProgramSection(sectionId: $sectionId, direction: $direction) {
      ok
    }
  }
`;

const ADMIN_MOBILE_DELETE_PROGRAM_SECTION = gql`
  mutation adminChangeDeleteThyroidProgramSection($sectionId: String!) {
    adminChangeDeleteThyroidProgramSection(sectionId: $sectionId) {
      ok
    }
  }
`;

const ADMIN_GET_THYROID_PROGRAM_COURSES = gql`
  query getAdminThyroidProgramCourses($programId: String!) {
    getAdminThyroidProgramCourses(programId: $programId) {
      id
      title
      description
      bgMainColor
      bgBubblesColor
      titleColor
      descriptionColor
      order
      week
      icon
      webflowArticleId
      externalArticleLink
      isFoodJournaling
      isActive
      isArchived
      isPro
      memberOnly
      createdAt
      updatedAt
      program {
        id
        name
        description
        icon
        bgMainColor
        bgBubblesColor
        titleColor
        descriptionColor
        isActive
        isArchived
        isPro
        memberOnly
        createdAt
        updatedAt
        order
      }
      programSection {
        id
        name
        description
        isActive
        isArchived
        isPro
        memberOnly
        createdAt
        updatedAt
        order
      }
    }
  }
`;

const ADMIN_ADD_THYROID_PROGRAM_COURSE = gql`
  mutation adminAddThyroidProgramCourse(
    $id: String
    $title: String!
    $description: String
    $isActive: Boolean
    $isPro: Boolean
    $programId: String!
    $sectionId: String!
    $week: Int
    $order: Int!
    $bgMainColor: String
    $bgBubblesColor: String
    $titleColor: String
    $descriptionColor: String
    $icon: String
    $webflowArticleId: String
    $externalArticleLink: String
    $isFoodJournaling: Boolean
  ) {
    adminAddThyroidProgramCourse(
      value: {
        id: $id
        title: $title
        description: $description
        isActive: $isActive
        isPro: $isPro
        programId: $programId
        sectionId: $sectionId
        week: $week
        order: $order
        bgMainColor: $bgMainColor
        bgBubblesColor: $bgBubblesColor
        titleColor: $titleColor
        descriptionColor: $descriptionColor
        icon: $icon
        webflowArticleId: $webflowArticleId
        externalArticleLink: $externalArticleLink
        isFoodJournaling: $isFoodJournaling
      }
    ) {
      ok
    }
  }
`;

const ADMIN_DELETE_THYROID_PROGRAM_COURSE = gql`
  mutation adminDeleteThyroidProgramCourse($courseId: String!, $direction: String!) {
    adminDeleteThyroidProgramCourse(courseId: $courseId, direction: $direction) {
      ok
    }
  }
`;

const ADMIN_THYROID_PROGRAM_CHANGE_ORDER_COURSE = gql`
  mutation adminChangeThyroidProgramOrderCourse($courseId: String!, $direction: String!) {
    adminChangeThyroidProgramOrderCourse(courseId: $courseId, direction: $direction) {
      ok
    }
  }
`;

const ADMIN_GET_THYROID_PROGRAM_COURSE_SCREENS = gql`
  query getadminThyroidProgramCourseScreens($courseId: String!) {
    getadminThyroidProgramCourseScreens(courseId: $courseId) {
      id
      screenFormat {
        id
        name
        color
      }
      content
      isActive
      isPro
      order
      title
      nextOverride
      hormoneY
      textStyle
      titleStyle
      buttons
      options
      formulaDisplay
      webflowArticleId
      externalArticleLink
      trackingConfig {
        id
        name
        title
        labelsGroupId
        negativeColor
        positiveColor
        trackColor
        knobColor
        titleColor
        textColor
        lastValuesIndicatorColor
      }
    }
  }
`;

const ADMIN_DELETE_THYROID_PROGRAM_COURSE_SCREEN = gql`
  mutation adminDeleteThyroidProgramCourseScreen($screenId: String!) {
    adminDeleteThyroidProgramCourseScreen(screenId: $screenId) {
      ok
    }
  }
`;

const ADMIN_THYROID_PROGRAM_CHANGE_ORDER_COURSE_SCREEN = gql`
  mutation adminChangeThyroidProgramOrderCourseScreen($screenId: String!, $direction: String!) {
    adminChangeThyroidProgramOrderCourseScreen(screenId: $screenId, direction: $direction) {
      ok
    }
  }
`;

const ADMIN_ADD_THYROID_PROGRAM_COURSE_SCREEN = gql`
  mutation adminAddProgramCourseScreen(
    $id: String
    $courseId: String!
    $Tags: [String]
    $ScreenFormatId: String
    $Content: String
    $isActive: Boolean!
    $isPro: Boolean!
    $order: Int
    $nextOverride: Int
    $title: String
    $hormoneY: Int!
    $formula: String
    $textStyle: String
    $titleStyle: String
    $buttons: String
    $options: String
    $icon: String
    $webflowArticleId: String
    $externalArticleLink: String
    $trackingConfig: CourseScreenTrackerMarkerInput
  ) {
    adminAddProgramCourseScreen(
      value: {
        id: $id
        courseId: $courseId
        Tags: $Tags
        ScreenFormatId: $ScreenFormatId
        Content: $Content
        isActive: $isActive
        isPro: $isPro
        order: $order
        title: $title
        hormoneY: $hormoneY
        nextOverride: $nextOverride
        textStyle: $textStyle
        titleStyle: $titleStyle
        buttons: $buttons
        options: $options
        formula: $formula
        icon: $icon
        webflowArticleId: $webflowArticleId
        externalArticleLink: $externalArticleLink
        trackingConfig: $trackingConfig
      }
    ) {
      ok
    }
  }
`;

const ADMIN_THYROID_PROGRAM_COURSE_SCREEN_REMAP_HORMONE = gql`
  mutation adminProgramCourseScreensRemapHormone($courseId: String!) {
    adminProgramCourseScreensRemapHormone(courseId: $courseId) {
      ok
    }
  }
`;

const ADMIN_GET_WORKOUTS = gql`
  query getAdminWorkouts {
    getAdminWorkouts {
      id
      name
      description
      icon
      bgMainColor
      bgBubblesColor
      titleColor
      descriptionColor
      order
      workoutBgImgLink
      isActive
      isArchived
      memberOnly
      createdAt
      updatedAt
      # relatedArticles
    }
  }
`;

const ADMIN_GET_WORKOUTS_CATEGORIES = gql`
  query getAdminWorkoutCategories {
    getAdminWorkoutCategories {
      id
      name
      description
    }
  }
`;

const ADMIN_GET_WORKOUTS_DIFFICULTIES = gql`
  query getAdminWorkoutDifficulties {
    getAdminWorkoutDifficulties {
      id
      name
    }
  }
`;

const ADMIN_GET_WORKOUTS_AUTHORS = gql`
  query getAdminWorkoutAuthors {
    getAdminWorkoutAuthors {
      id
      name
      bio
      avatarUrl
      instagramLink
      facebookLink
      twitterLink
      websiteLink
      youtubeChannelLink
    }
  }
`;

const ADMIN_GET_WORKOUTS_ELEMENTS = gql`
  query getAdminWorkoutElements($workoutId: String!) {
    getAdminWorkoutElements(workoutId: $workoutId) {
      id
      title
      fileLink
      description
      duration
      bgMainColor
      bgBubblesColor
      titleColor
      descriptionColor
      order
      isActive
      isArchived
      memberOnly
      thumbnailUrl
      workout {
        id
        name
      }
      category {
        id
        name
      }
      difficulty {
        id
        name
      }
      author {
        id
        name
      }
    }
  }
`;

const ADMIN_UPSERT_WORKOUT = gql`
  mutation adminCreateUpdateWorkout(
    $id: String
    $name: String!
    $description: String
    $icon: String!
    $bgMainColor: String!
    $bgBubblesColor: String!
    $titleColor: String!
    $descriptionColor: String!
    $order: Int!
    $isActive: Boolean
    $memberOnly: Boolean
    $workoutBgImgLink: String!
    $relatedArticles: [String]
  ) {
    adminCreateUpdateWorkout(
      value: {
        id: $id
        name: $name
        description: $description
        icon: $icon
        bgMainColor: $bgMainColor
        bgBubblesColor: $bgBubblesColor
        titleColor: $titleColor
        descriptionColor: $descriptionColor
        order: $order
        isActive: $isActive
        memberOnly: $memberOnly
        workoutBgImgLink: $workoutBgImgLink
        relatedArticles: $relatedArticles
      }
    ) {
      ok
    }
  }
`;

const ADMIN_UPSERT_WORKOUT_CATEGORY = gql`
  mutation adminCreateUpdateWorkoutCategory($id: String, $name: String!, $description: String) {
    adminCreateUpdateWorkoutCategory(value: { id: $id, name: $name, description: $description }) {
      ok
    }
  }
`;

const ADMIN_UPSERT_WORKOUT_DIFFICULTY = gql`
  mutation adminCreateUpdateWorkoutDifficulty($id: String, $name: String!) {
    adminCreateUpdateWorkoutDifficulty(value: { id: $id, name: $name }) {
      ok
    }
  }
`;

const ADMIN_UPSERT_WORKOUT_AUTHOR = gql`
  mutation adminCreateUpdateWorkoutAuthor(
    $id: String
    $name: String!
    $bio: String
    $instagramLink: String
    $facebookLink: String
    $twitterLink: String
    $websiteLink: String
    $youtubeChannelLink: String
    $avatarUrl: String
  ) {
    adminCreateUpdateWorkoutAuthor(
      value: {
        id: $id
        name: $name
        bio: $bio
        instagramLink: $instagramLink
        facebookLink: $facebookLink
        twitterLink: $twitterLink
        websiteLink: $websiteLink
        youtubeChannelLink: $youtubeChannelLink
        avatarUrl: $avatarUrl
      }
    ) {
      ok
    }
  }
`;

const ADMIN_UPSERT_WORKOUT_ELEMENT = gql`
  mutation adminCreateUpdateWorkoutElement(
    $id: String
    $title: String!
    $fileLink: String!
    $description: String
    $duration: String
    $bgMainColor: String!
    $bgBubblesColor: String!
    $titleColor: String!
    $descriptionColor: String!
    $order: Int!
    $isActive: Boolean
    $isArchived: Boolean
    $memberOnly: Boolean
    $workout: String!
    $category: [String]
    $difficulty: String
    $author: String!
    $thumbnailUrl: String!
  ) {
    adminCreateUpdateWorkoutElement(
      value: {
        id: $id
        title: $title
        fileLink: $fileLink
        description: $description
        duration: $duration
        bgMainColor: $bgMainColor
        bgBubblesColor: $bgBubblesColor
        titleColor: $titleColor
        descriptionColor: $descriptionColor
        order: $order
        isActive: $isActive
        isArchived: $isArchived
        memberOnly: $memberOnly
        workout: $workout
        category: $category
        difficulty: $difficulty
        author: $author
        thumbnailUrl: $thumbnailUrl
      }
    ) {
      ok
    }
  }
`;

const ADMIN_CHANGE_ORDER_WORKOUT = gql`
  mutation adminChangeWorkoutOrder($id: String!, $direction: String!) {
    adminChangeWorkoutOrder(id: $id, direction: $direction) {
      ok
    }
  }
`;

const ADMIN_CHANGE_ORDER_WORKOUT_ELEMENT = gql`
  mutation adminChangeWorkoutElementOrder($id: String!, $direction: String!) {
    adminChangeWorkoutElementOrder(id: $id, direction: $direction) {
      ok
    }
  }
`;

const ADMIN_DELETE_ORDER_WORKOUT = gql`
  mutation adminArchiveWorkout($id: String!) {
    adminArchiveWorkout(id: $id) {
      ok
    }
  }
`;

const ADMIN_DELETE_WORKOUT_ELEMENT = gql`
  mutation adminArchiveElementWorkout($id: String!) {
    adminArchiveElementWorkout(id: $id) {
      ok
    }
  }
`;

const ADMIN_REMAP_PWN = gql`
  mutation adminRemapPwn($oldId: String!, $newId: String!) {
    adminRemapPwn(oldId: $oldId, newId: $newId) {
      count
      updatedCount
    }
  }
`;

const ADMIN_GET_USER_BY_ID = gql`
  query adminGetUserById($id: String!) {
    adminGetUserById(id: $id) {
      id
      lastName
      firstName
      email
      athenaId
      ordersCount
    }
  }
`;

const ADMIN_GET_BROKERS_LIST = gql`
  query getAdminBrokersList {
    getAdminBrokersList {
      id
      name
      friendlyName
      iconUrl
      defaultFreeness {
        id
        kit
        vitamins
        membership
        visits
        async
        expiresAfter
        nutritionist
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;

const ADMIN_UPSERT_BROKER = gql`
  mutation upsertAdminBroker(
    $id: String
    $name: String!
    $friendlyName: String
    $iconUrl: String
    $isActive: Boolean
    $kit: Boolean
    $vitamins: Boolean
    $membership: Boolean
    $visits: Boolean
    $async: Boolean
    $expiresAfter: String
    $nutritionist: Boolean
  ) {
    upsertAdminBroker(
      value: {
        id: $id
        name: $name
        friendlyName: $friendlyName
        iconUrl: $iconUrl
        isActive: $isActive
        kit: $kit
        vitamins: $vitamins
        membership: $membership
        visits: $visits
        async: $async
        expiresAfter: $expiresAfter
        nutritionist: $nutritionist
      }
    ) {
      ok
    }
  }
`;

const ADMIN_UPSERT_B2B_COMPANY = gql`
  mutation upsertAdminB2BCompany($value: AdminUpsertB2BCompanyInput!) {
    upsertAdminB2BCompany(value: $value) {
      ok
    }
  }
`;

const ADMIN_GET_B2B_COMPANIES_LIST = gql`
  query getAdminB2BCompanies {
    getAdminB2BCompanies {
      id
      name
      friendlyName
      iconUrl
      freeness {
        id
        kit
        vitamins
        membership
        visits
        async
        expiresAfter
        nutritionist
      }
      broker {
        id
        name
        friendlyName
        iconUrl
        defaultFreeness {
          id
          kit
          vitamins
          membership
          visits
          async
          expiresAfter
          nutritionist
        }
        isActive
        createdAt
        updatedAt
      }
      isActive
      createdAt
      updatedAt
      eligibilityCheckMethod
      whereToFindActivationMethodText
    }
  }
`;

const ADMIN_UPSERT_B2B_ELIGIBLES = gql`
  mutation upsertAdminB2BEligibles($companyId: String!, $data: [AdminUpsertB2BEligilitiesInput]!) {
    upsertAdminB2BEligibles(companyId: $companyId, data: $data) {
      insert {
        id
        firstName
        lastName
        dob
        employeeEmail
        primaryFirstName
        primaryLastName
        memberId
        employeeId
        subscriberId
        uniqueCode
        zip
        error
      }
      delete {
        id
        firstName
        lastName
        dob
        employeeEmail
        primaryFirstName
        primaryLastName
        memberId
        employeeId
        subscriberId
        uniqueCode
        zip
        error
      }
    }
  }
`;

const GET_B2B_COMPANIES = gql`
  query getB2BCompanies {
    getB2BCompanies {
      id
      name
      friendlyName
      iconUrl
      isActive
    }
  }
`;

const GET_B2B_COMPANY_ELIGIBILITY_CHECK_METHOD = gql`
  query getB2BCompanyEligibilityCheckMethod($id: String!) {
    getB2BCompanyEligibilityCheckMethod(id: $id) {
      id
      eligibilityCheckMethod
      whereToFindActivationMethodText
    }
  }
`;

const GET_B2B_BROKERS = gql`
  query getBrokersList {
    getBrokersList {
      id
      name
      friendlyName
      iconUrl
      isActive
      companies {
        id
        name
        friendlyName
        iconUrl
        isActive
      }
    }
  }
`;

const B2B_PRECHECK = gql`
  mutation b2bEligibilityPrecheck($data: B2BEligibilityPreCheckInput!) {
    b2bEligibilityPrecheck(data: $data) {
      ok
    }
  }
`;

const B2B_PRECHECK_SUBSCRIBERID_NAMES = gql`
  mutation b2bEligibilityPrecheckSubscriberIdNames($data: B2BEligibilityPreCheckSubscriberIdNamesInput!) {
    b2bEligibilityPrecheckSubscriberIdNames(data: $data) {
      ok
    }
  }
`;

const B2B_PRECHECK_MEMBERID_NAMES = gql`
  mutation b2bEligibilityPrecheckMemberIdNames($data: B2BEligibilityPreCheckMemberIdNamesInput!) {
    b2bEligibilityPrecheckMemberIdNames(data: $data) {
      ok
    }
  }
`;

const B2B_PRECHECK_EMPLOYEEID_NAMES = gql`
  mutation b2bEligibilityPrecheckEmployeeIdNames($data: B2BEligibilityPreCheckEmployeeIdNamesInput!) {
    b2bEligibilityPrecheckEmployeeIdNames(data: $data) {
      ok
    }
  }
`;

const B2B_PRECHECK_UNIQUE_CODE = gql`
  mutation b2bEligibilityPrecheckUniqueCode($data: B2BEligibilityPreCheckUniqueCodeInput!) {
    b2bEligibilityPrecheckUniqueCode(data: $data) {
      ok
    }
  }
`;

const B2B_RESEND_VERIF_EMAIL = gql`
  mutation resendEligibilityCheckEmail {
    resendEligibilityCheckEmail {
      ok
    }
  }
`;

const ACTIVATE_B2B_ELIGIBILITY = gql`
  mutation activateEligibility($uid: String!) {
    activateEligibility(uid: $uid) {
      ok
      customError
    }
  }
`;

const B2B_ORDER_KIT = gql`
  mutation b2bOrderKit($biomarkers: [String], $address: AddressInput!) {
    b2bOrderKit(biomarkers: $biomarkers, address: $address) {
      ok
    }
  }
`;

const B2B_ORDER_VITAMINS = gql`
  mutation b2bOrderVitamins($address: AddressInput!) {
    b2bOrderVitamins(address: $address) {
      ok
    }
  }
`;

const ADMIN_GET_CARE_MANAGERS_LIST = gql`
  query adminGetCareManagersList {
    adminGetCareManagersList {
      id
      fullName
      firstName
      lastName
      bio
      thumbnail
      helpscoutId
      acceptNew
      isActive
      isFallback
      calendlyLink
      patientCount
    }
  }
`;

const ADMIN_GET_CARE_MANAGER_USERS_LIST = gql`
  query adminGetCareManagerUsersList($helpscoutId: String!) {
    adminGetCareManagerUsersList(helpscoutId: $helpscoutId) {
      id
      firstName
      lastName
      email
      athenaId
    }
  }
`;

const ADMIN_GET_HEALTH_COACHES_LIST = gql`
  query adminGetHealthCoachUsersList {
    adminGetHealthCoachUsersList {
      id
      email
      mustCompleteRegistration
      helpscoutId
      phone
      isActive
      displayName
      calendlyUserId
      calendlyUserEmail
    }
  }
`;

const GET_CALENDLY_USER_BY_EMAIL = gql`
  query adminGetCalendlyUserByEmail($email: String) {
    adminGetCalendlyUserByEmail(email: $email) {
      uri
      role
      organization
      name
      slug
      email
      schedulingUrl
      timezone
      avatarUrl
      createdAt
      updatedAt
      currentOrganization
    }
  }
`;

const CREATE_HEALTHCOACH_USER = gql`
  mutation adminCreateHealthCoachUser(
    $email: String!
    $password: String!
    $helpscoutId: Int
    $phone: String!
    $displayName: String!
    $mentionTag: String
    $calendlyUserId: String
    $calendlyEmail: String
    $forceDuplicateCalendly: Boolean
  ) {
    adminCreateHealthCoachUser(
      email: $email
      password: $password
      helpscoutId: $helpscoutId
      phone: $phone
      displayName: $displayName
      mentionTag: $mentionTag
      calendlyUserId: $calendlyUserId
      calendlyEmail: $calendlyEmail
      forceDuplicateCalendly: $forceDuplicateCalendly
    ) {
      ok
      customError
    }
  }
`;

const UPDATE_HEALTHCOACH_USER = gql`
  mutation adminUpdateHealthCoachUser($id: String!, $helpscoutId: Int, $phone: String!, $displayName: String!, $calendlyUserId: String, $calendlyUserEmail: String) {
    adminUpdateHealthCoachUser(id: $id, helpscoutId: $helpscoutId, phone: $phone, displayName: $displayName, calendlyUserId: $calendlyUserId, calendlyUserEmail: $calendlyUserEmail) {
      ok
      customError
    }
  }
`;

const DELETE_HEALTHCOACH_USER = gql`
  mutation adminDeleteHealthCoach($id: String!) {
    adminDeleteHealthCoach(id: $id) {
      ok
      customError
    }
  }
`;

const GET_GENDER_IDENTITIES = gql`
  query getGenderIdentities {
    getGenderIdentities
  }
`;

const GET_PREFERRED_PRONOUNS = gql`
  query getPreferredPronouns {
    getPreferredPronouns
  }
`;

const GET_BASELINE_SURVEY_ANSWERS = gql`
  query getBaselineSurveyAnswers {
    getBaselineSurveyAnswers {
      id
      status
      values
      updatedAt
    }
  }
`;

const GET_FOLLOWUP_SURVEY_ANSWERS = gql`
  query getFollowUpSurveyAnswers($surveyName: String!) {
    getFollowUpSurveyAnswers(surveyName: $surveyName) {
      id
      status
      values
      updatedAt
    }
  }
`;

const GET_SHARE_AGREEMENT_SURVEY = gql`
  query getSurveyShareResearchAgreement {
    getSurveyShareResearchAgreement {
      id
      agreeTerms
      agreedOn
    }
  }
`;

const RESET_BASELINE = gql`
  mutation resetBaseline {
    resetBaseline {
      ok
    }
  }
`;

const ADMIN_RESET_BASELINE = gql`
  mutation adminResetBaseline($id: String!) {
    adminResetBaseline(id: $id) {
      ok
    }
  }
`;

const RESET_FOLLOWUP_SURVEY = gql`
  mutation resetFollowupSurvey($surveyName: String!) {
    resetFollowupSurvey(surveyName: $surveyName) {
      ok
    }
  }
`;

const ADMIN_RESET_FOLLOWUP_SURVEY = gql`
  mutation adminResetFollowupSurvey($id: String!, $surveyName: String!, $date: DateTime) {
    adminResetFollowupSurvey(id: $id, surveyName: $surveyName, date: $date) {
      ok
    }
  }
`;

const SAVE_BASELINE_TEMP_DATA = gql`
  mutation saveBaselineTempData($baselineData: String!) {
    saveBaselineTempData(baselineData: $baselineData) {
      ok
    }
  }
`;

const MARK_BASELINE_COMPLETED = gql`
  mutation markBaselineCompleted($baselineData: String!) {
    markBaselineCompleted(baselineData: $baselineData) {
      ok
    }
  }
`;

const SAVE_FOLLOWUP_SURVEY_TEMP_DATA = gql`
  mutation saveFollowupSurveyTempData($data: String!, $surveyName: String!) {
    saveFollowupSurveyTempData(data: $data, surveyName: $surveyName) {
      ok
    }
  }
`;

const GET_SURVEY_REMINDER = gql`
  query surveyReminder {
    surveyReminder
  }
`;

const MARK_FOLLOWUP_SURVEY_COMPLETED = gql`
  mutation markFollowupSurveyCompleted($data: String!, $surveyName: String!) {
    markFollowupSurveyCompleted(data: $data, surveyName: $surveyName) {
      ok
    }
  }
`;

const SET_SURVEY_SHARE_RESEARCH_ACCEPTANCE = gql`
  mutation setSurveyShareDataResearchTermsAcceptance($accepted: Boolean!) {
    setSurveyShareDataResearchTermsAcceptance(accepted: $accepted) {
      ok
    }
  }
`;

const CARE_MANAGER_ONBOARDING_CALL_BOOKED = gql`
  mutation onboardingCallBooked {
    onboardingCallBooked {
      ok
    }
  }
`;

const ADMIN_ALLOW_ONE_TIME_VISIT_SKIP = gql`
  mutation adminAllowOneTimeVisitSkip($id: String!) {
    adminAllowOneTimeVisitSkip(id: $id) {
      ok
    }
  }
`;

const ADMIN_REMOVE_ONE_TIME_VISIT_SKIP = gql`
  mutation adminRemoveOneTimeVisitSkip($id: String!) {
    adminRemoveOneTimeVisitSkip(id: $id) {
      ok
    }
  }
`;

const ADMIN_CHANGE_USER_BOOLEAN_FLAG_VALUE = gql`
  mutation adminChangeUserBooleanFlagValue($id: String!, $flagName: String!, $value: Boolean!) {
    adminChangeUserBooleanFlagValue(id: $id, flagName: $flagName, value: $value) {
      ok
    }
  }
`;

const ADMIN_CHANGE_MOBILE_USER_BOOLEAN_FLAG_VALUE = gql`
  mutation adminChangeMobileUserBooleanFlagValue($id: String!, $flagName: String!, $value: Boolean!) {
    adminChangeMobileUserBooleanFlagValue(id: $id, flagName: $flagName, value: $value) {
      ok
    }
  }
`;

const ADMIN_CHANGE_USER_STRING_VALUE = gql`
  mutation adminChangeUserStringValue($id: String!, $flagName: String!, $value: String!) {
    adminChangeUserStringValue(id: $id, flagName: $flagName, value: $value) {
      ok
      customError
    }
  }
`;

const ADMIN_CHANGE_MOBILE_USER_STRING_VALUE = gql`
  mutation adminChangeMobileUserStringValue($id: String!, $flagName: String!, $value: String!) {
    adminChangeMobileUserStringValue(id: $id, flagName: $flagName, value: $value) {
      ok
      customError
    }
  }
`;

const GET_INSURANCE_IN_STATE = gql`
  query getInsurancesAcceptedInState {
    getInsurancesAcceptedInState
  }
`;

const INSURANCE_MEMBER_SAVE_TMP_INS_DATA = gql`
  mutation insuranceMemberSaveTmpData($planType: String!) {
    insuranceMemberSaveTmpData(planType: $planType) {
      ok
    }
  }
`;

const GET_HEALTHCOACH_FORM_TARGETS = gql`
  query getHealthCoachFormTargets {
    getHealthCoachFormTargets {
      id
      target
      canHaveMultiple
      isActive
      isDeprecated
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

const GET_HEALTHCOACH_FORM_TEMPLATES = gql`
  query getHealthCoachFormTemplates {
    getHealthCoachFormTemplates {
      id
      creator {
        id
        email
        firstName
      }
      version
      content
      target {
        id
        target
      }
      name
      description
      basedOnTemplate {
        id
        name
      }
      isActive
      isDeprecated
      isDeleted
      createdAt
      updatedAt
      associatedForms {
        id
        creator {
          id
          email
          displayName
        }
        version
        content
        target {
          id
          target
        }
        name
        description
        basedOnTemplate {
          id
          name
        }
        isActive
        isDeprecated
        isDeleted
        createdAt
        updatedAt
        inLibraryOf {
          id
          name
          email
          isFavorite
          isActive
        }
      }
    }
  }
`;

const SURVEY_ANSWERS_SCORES = gql`
  query surveyAnswersScores {
    surveyAnswersScores {
      survey
      values {
        SensitiveCold
        Energetic
        Tired
        DifficultiesRemembering
        SlowThinking
        AfraidAnxious
        EverythingTakesLonger
        DifficultiesMotivated
        DifficultiesManagingDailyLife
        DrySkin
        ThyroidNegativeImpactQualityOfLife
        OverallWellBeing
      }
    }
  }
`;

const GET_ATHENA_DIAGNOSED = gql`
  query getAthenaIsDiagnosed {
    getAthenaIsDiagnosed {
      diagnosed
    }
  }
`;

const GET_FILTER_BANNERS = gql`
  query getFilterBanners {
    getFilterBanners {
      bannerId
      id
      filter
      force
    }
  }
`;

const HIDE_FILTER_BANNER = gql`
  mutation hideFilterBanner($id: String!) {
    hideFilterBanner(id: $id) {
      ok
    }
  }
`;

const GET_FILTER_BANNER_TEMPLATE = gql`
  query getFilterBannerTemplate($id: String!) {
    getFilterBannerTemplate(id: $id) {
      id
      templateTitle
      templateBackground
      templateDescription
      templateReadMore
    }
  }
`;

export {
  APPOINTMENTS,
  APPOINTMENTS_HISTORY,
  ADMIN_APPOINTMENTS_HISTORY_FOR_PATIENT,
  APPOINTMENTS_NUTRITIONIST,
  UPCOMING_APPOINTMENTS,
  ADMIN_UPCOMING_APPOINTMENTS_FOR_PATIENT,
  ADMIN_GET_APPOINTMENTS_LIST,
  GET_REGION_ELIGIBILITY,
  CURRENT_USER,
  GET_PROFILE_COMPLETION_INFO_BY_UID,
  SEND_INTAKE_REMINDER_EMAIL,
  GET_PATIENT_BY_ID,
  ATHENA_CARD,
  ATHENA_CARD_FOR_PATIENT,
  LOGIN,
  SIGNUP,
  APPLY_COUPON,
  SAVE_EMAIL_UNCOMPLETE_ORDER,
  CREATE_ORDER,
  CREATE_ORDER_UNAUTHENTICATED,
  CREATE_ORDER_ACTIVATION,
  OPEN_ORDER,
  CANCEL_ORDER,
  COMPLETE_ORDER,
  COMPLETE_ORDER_UNAUTHENTICATED,
  OPEN_APPTS,
  BOOK_APPT,
  BOOK_APPT_MENO,
  CANCEL_APPT,
  RESCHED_APPT,
  RESCHED_APPT_FOR_PATIENT,
  RESCHED_APPT_MENO,
  RESCHED_APPT_FOR_PATIENT_MENO,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  CHANGE_EMAIL,
  GET_ANALYTICS_ID,
  LABS,
  GET_REQUISITION_FORM,
  GET_REQUISITION_FORM_DETAILS,
  UPDATE_CARD,
  UPDATE_ATHENA_CARD,
  COMPLETE_PROFILE_ADD_CARD,
  COMPLETE_PROFILE_REGISTRATION,
  GET_PENDING_PROFILE_COMPLETION_USERS,
  RESEND_COMPLETE_PROFILE_EMAIL,
  CHARTS,
  SET_PHARMACY,
  SET_LAB_CENTER,
  PRESCRIPTION,
  KIT_PRICE,
  KIT_PRICE_UNAUTHENTICATED,
  KIT_PRICE_ACTIVATION,
  GET_CLAIM_KIT_INFORMATION,
  GET_LAST_ORDER_MINUTES_DIFF,
  CLAIM_KIT,
  APPT_SUMMARY,
  GET_ASSIGNED_DOCTOR,
  GET_CLINICAL_PROVIDERS_DOCTOR,
  SET_AS_INACTIVE,
  FORCE_CANCEL_APPT_LOCAL,
  REGISTER_KIT,
  ADMIN_UPDATE_CUSTOMER_EMAIL,
  CHANGE_MEDICATION_CONSENT,
  GET_COST_ESTIMATES,
  GET_COST_ESTIMATES_FOR_PATIENT,
  GET_COST_ESTIMATES_UNREGISTERED,
  CHECK_ELIGIBLE_COVERAGE,
  CHECK_ELIGIBLE_COVERAGE_FOR_PATIENT,
  CHECK_ELIGIBLE_COVERAGE_UNREGISTERED,
  GET_INSURANCE_PACKAGE,
  ADD_INSURANCE,
  ADD_INSURANCE_FOR_PATIENT,
  ASSIGN_INSURANCE_TO_APPOINTMENT,
  DELETE_INSURANCE,
  DELETE_INSURANCE_FOR_PATIENT,
  GET_INSURANCE_ON_FILE,
  GET_INSURANCE_ON_FILE_FOR_PATIENT,
  TOUCH,
  ADD_TO_STATE_WAITING_LIST,
  SEARCH_PCPS,
  SET_PCP,
  GET_USER_PCP,
  REMOVE_PCP,
  ADMIN_GET_LOCAL_DB_KITS,
  NOTIFY_COULDNT_CONFIRM_CARD,
  ADMIN_MAP_LOCALDB_ACCOUNT_TO_ATHENA_ID,
  ADMIN_BATCH_MIGRATE_PATIENTS_TO_NEW_PROVIDER_GROUP,
  ADMIN_UNREGISTER_KIT_LOCALDB,
  IMPERSONATE,
  GET_CARE_TEAM_CONVERSATION,
  GET_APPOINTMENT_CONVERSATION,
  GET_APPOINTMENT_ENCOUNTER_STATE,
  CONVERSATION_THREAD_REPLY,
  APPOINTMENT_INITIATE_CONVERSATION,
  GET_CLINICAL_PROVIDER,
  RESET_PATIENT_INTAKE_STATUS,
  CHECK_SERIAL_AVAILABLE,
  MOBILE_WALLET_PAYMENT,
  ADD_FEATURE_TOGGLE,
  UPDATE_FEATURE_TOGGLE,
  UPDATE_ONE_TIME_COUPON,
  ADD_ONE_TIME_COUPON,
  GET_FEATURE_TOGGLES_LIST,
  GET_ONE_TIME_COUPONS_LIST,
  REQUEST_FOR_REFILL,
  RESEND_UNLOCK_ACCOUNT_EMAIL,
  CHECK_UNLOCK_VALUES,
  UNLOCK_ACCOUNT,
  CHECK_LOCKED_ACCOUNT_UID,
  GENERATE_REPORT_STRIPE_CONSOLIDATION,
  ADMIN_REPORTS_STATUS,
  GENERATE_ADMIN_REPORT,
  GET_ADMIN_REPORTS_DATA,
  TIME_DOESNT_WORK,
  IS_DIAGNOSED,
  GET_MY_REFERRAL_INFORMATION,
  GENERATE_REFERRAL_LINK,
  BATCH_UPLOAD_REFERRALS,
  GET_MY_REFERRALS_HISTORY,
  CHECK_PURCHASE_REFERRAL_BENEFITS,
  ADD_NEW_REFERRAL,
  ADMIN_GET_REFERRAL_LIST,
  ADMIN_GET_REFERRAL_HISTORY,
  ADMIN_ADJUST_REFERRER_CREDITS,
  GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES,
  GET_SYMPTOM_TRACKER_LABELS_GROUPS,
  GET_MOBILE_SYMPTOM_TRACKERS,
  ADD_SYMPTOM_TRACKER_CATEGORY,
  ADD_SYMPTOM_TRACKER_LABELS_GROUP,
  ADD_SYMPTOM_TRACKER_MARKER,
  ADMIN_DELETE_MOBILE_USER,
  ADD_MOBILE_COURSE_JOURNEY,
  ADD_MOBILE_COURSE_SCREEN_FORMAT,
  ADMIN_GET_MOBILE_COURSE_JOURNEYS,
  ADMIN_GET_MOBILE_COURSE_SCREEN_FORMATS,
  ADMIN_GET_MOBILE_COURSES,
  ADMIN_ADD_MOBILE_COURSE,
  ADMIN_DELETE_MOBILE_COURSE,
  ADMIN_MOBILE_CHANGE_ORDER_COURSE,
  ADMIN_GET_MOBILE_COURSE_SCREENS,
  ADMIN_ADD_MOBILE_COURSE_SCREEN,
  ADMIN_DELETE_MOBILE_COURSE_SCREEN,
  ADMIN_MOBILE_CHANGE_ORDER_COURSE_SCREEN,
  ADMIN_COURSE_SCREEN_REMAP_HORMONE,
  ADMIN_GET_MOTIVATIONAL_QUOTES,
  ADMIN_ADD_MOTIVATIONAL_QUOTES,
  ADMIN_SET_MOBILE_USER_WEEK,
  GET_MOBILE_COURSE_BY_ID,
  SEND_INSURANCE,
  SEND_INSURANCE_INITIAL,
  ADMIN_GET_WEBFLOW_ARTICLES,
  ADMIN_TRIGGER_WEBFLOW_ARTICLE_UPDATE,
  DELETE_SYMPTOM_TRACKER_MARKER,
  ADMIN_LIST_RECIPES,
  ADMIN_TRIGGER_RECIPES_UPDATE,
  ADMIN_SEND_REF_HISTO,
  CHECK_COUPON_EXISTS,
  REFERRAL_SEND_FRIEND_EMAIL,
  ADD_BIOMARKERS_ORDER_UNAUTHENTICATED,
  ADD_BIOMARKERS_ORDER,
  MEMBERSHIP_CREATE_SESSION,
  MEMBERSHIP_UPDATE_PAYMENT_CREATE_SESSION,
  MEMBERSHIP_UPDATE_PAYMENT_METHOD,
  GET_MEMBERSHIPS,
  CANCEL_SUBSCRIPTION,
  PAUSE_SUBSCRIPTION,
  RESUME_SUBSCRIPTION,
  EMPTY_CALL,
  MEMBER_UPDATE_NEW_SYMPTOMS_HELPSCOUT,
  APPLY_CANCELLATION_COUPON,
  MEMBER_CONVERSATION_THREAD_REPLY,
  CREATE_ORDER_ACTIVATION_BIOMARKERS,
  CANCEL_ALL_ORDERS,
  VIEWED_PAGE,
  BATCH_BACKFILL,
  RESET_ADMIN_REPORT,
  CREATE_SESSION_VITAMINS_CHECKOUT_SESSION,
  CREATE_SESSION_VITAMINS_SUBSCRIPTIONS_CHECKOUT_SESSION,
  GET_SESSION,
  DONT_CANCEL_MEMBERSHIP,
  GET_ASYNC_TEMPORARY_RESPONSES,
  SAVE_ASYNC_TEMPORARY_RESPONSES,
  UPLOAD_DRIVER_LICENSE,
  UPLOAD_PHOTO,
  UPLOAD_MEDICATION_BOTTLE_PHOTO,
  EMAIL_AVAILABLE,
  PHONE_AVAILABLE,
  ADMIN_CHANGE_STRIPE_ID,
  ASYNC_ELIGIBILITY_CHECK,
  SCHEDULER_ORDER,
  CREATE_PAYMENT_INTENT,
  GET_ASYNC_ORDER_DETAILS,
  ASYNC_SOFT_DISQUALIFY,
  ASYNC_HARD_DISQUALIFY,
  RESET_ASYNC_TEMPORARY_RESPONSES,
  SEND_KLAVIYO_STATUS,
  SEARCH_ATHENA_MEDICATION,
  SEARCH_ATHENA_ALLERGY,
  ADD_SYMPTOM_TRACKER_CATEGORY_UT,
  DELETE_SYMPTOM_TRACKER_MARKER_UT,
  ADD_SYMPTOM_TRACKER_MARKER_UT,
  GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES_UT,
  ADD_SYMPTOM_TRACKER_LABELS_GROUP_UT,
  GET_SYMPTOM_TRACKER_LABELS_GROUPS_UT,
  GET_MOBILE_SYMPTOM_TRACKERS_UT,
  QUADPAY_SUBSCRIBED,
  SEND_OTP_CODE,
  VERIFY_OTP_CODE,
  LOAD_PREVIOUS_INTAKE,
  GET_HAD_ASYNC_VISITS,
  COMPLETE_WEB_REGISTRATION,
  SET_UNIVERSAL_PASSWORD,
  GENERATE_CIRCLE_SO_CALLBACK,
  LOGIN_FROM_MOBILE,
  ADMIN_CHANGE_HELPSCOUT_ID,
  GET_HAS_INSURANCE_CARD_ON_DOCS,
  ADD_NEW_INSURANCE,
  REQUEST_DELETE_INSURANCE,
  ADMIN_BATCH_CREATE_SLOTS,
  GET_INTAKE_PREVIOUS_VALUES,
  GET_MEDICATIONS_ON_FILE,
  GET_HYPO_MEDICATIONS_ON_FILE,
  CREATE_PROVIDER_USER,
  GET_MOBILE_TRACKED_SYMPTOMS,
  GET_LAB_CHART_VALUES,
  GET_RANDOM_ARTICLES,
  GET_ARTICLE_BY_ID,
  GET_RANDOM_ARTICLES_WITH_TAG,
  GET_ARTICLES_FOR_CATEGORY,
  GET_ARTICLES_CATEGORIES,
  GET_AIP_RECIPES,
  GET_AIP_RECIPE,
  GET_AIP_RECIPES_BY_FILTER,
  GET_THYROID_GUIDES,
  GET_SPEAKER_SERIES,
  SEND_MOBILE_APP_DOWNLOAD_LINK,
  HAS_UNREGISTERED_KITS,
  ADMIN_GET_THYROID_PROGRAMS,
  ADMIN_CREATE_THYROID_PROGRAMS,
  ADMIN_GET_THYROID_PROGRAM_BY_ID,
  ADMIN_GET_THYROID_PROGRAMS_SECTIONS,
  ADMIN_CREATE_UPDATE_THYROID_PROGRAM_SECTION,
  ADMIN_MOBILE_CHANGE_ORDER_PROGRAM_SECTION,
  ADMIN_MOBILE_DELETE_PROGRAM_SECTION,
  ADMIN_GET_THYROID_PROGRAM_COURSES,
  ADMIN_ADD_THYROID_PROGRAM_COURSE,
  ADMIN_DELETE_THYROID_PROGRAM_COURSE,
  ADMIN_THYROID_PROGRAM_CHANGE_ORDER_COURSE,
  ADMIN_GET_THYROID_PROGRAM_COURSE_SCREENS,
  ADMIN_DELETE_THYROID_PROGRAM_COURSE_SCREEN,
  ADMIN_THYROID_PROGRAM_CHANGE_ORDER_COURSE_SCREEN,
  ADMIN_ADD_THYROID_PROGRAM_COURSE_SCREEN,
  ADMIN_THYROID_PROGRAM_COURSE_SCREEN_REMAP_HORMONE,
  ADMIN_GET_WORKOUTS,
  ADMIN_GET_WORKOUTS_CATEGORIES,
  ADMIN_GET_WORKOUTS_DIFFICULTIES,
  ADMIN_GET_WORKOUTS_AUTHORS,
  ADMIN_GET_WORKOUTS_ELEMENTS,
  ADMIN_UPSERT_WORKOUT,
  ADMIN_UPSERT_WORKOUT_CATEGORY,
  ADMIN_UPSERT_WORKOUT_DIFFICULTY,
  ADMIN_UPSERT_WORKOUT_AUTHOR,
  ADMIN_UPSERT_WORKOUT_ELEMENT,
  ADMIN_CHANGE_ORDER_WORKOUT,
  ADMIN_CHANGE_ORDER_WORKOUT_ELEMENT,
  ADMIN_DELETE_ORDER_WORKOUT,
  ADMIN_DELETE_WORKOUT_ELEMENT,
  ADMIN_GET_USER_BY_ID,
  ADMIN_REMAP_PWN,
  REQUEST_FOR_SUPERBILL,
  ADMIN_GET_BROKERS_LIST,
  ADMIN_UPSERT_BROKER,
  ADMIN_GET_B2B_COMPANIES_LIST,
  ADMIN_UPSERT_B2B_COMPANY,
  ADMIN_UPSERT_B2B_ELIGIBLES,
  GET_B2B_COMPANIES,
  GET_B2B_COMPANY_ELIGIBILITY_CHECK_METHOD,
  GET_B2B_BROKERS,
  B2B_PRECHECK,
  SIGNUP_B2B,
  B2B_RESEND_VERIF_EMAIL,
  ACTIVATE_B2B_ELIGIBILITY,
  B2B_ORDER_KIT,
  B2B_ORDER_VITAMINS,
  ADMIN_GET_CARE_MANAGERS_LIST,
  ADMIN_GET_CARE_MANAGER_USERS_LIST,
  GET_GENDER_IDENTITIES,
  GET_PREFERRED_PRONOUNS,
  ADMIN_REMAP_MOBILEUSER_ASSOCIATED_USER,
  ADMIN_UPDATE_MOBILE_PROFILE,
  GET_BASELINE_SURVEY_ANSWERS,
  SAVE_BASELINE_TEMP_DATA,
  MARK_BASELINE_COMPLETED,
  RESET_BASELINE,
  ADMIN_RESET_BASELINE,
  SET_SURVEY_SHARE_RESEARCH_ACCEPTANCE,
  CARE_MANAGER_ONBOARDING_CALL_BOOKED,
  ADMIN_CHANGE_USER_BOOLEAN_FLAG_VALUE,
  ADMIN_CHANGE_USER_STRING_VALUE,
  ADMIN_CHANGE_MOBILE_USER_BOOLEAN_FLAG_VALUE,
  ADMIN_CHANGE_MOBILE_USER_STRING_VALUE,
  GET_FOLLOWUP_SURVEY_ANSWERS,
  RESET_FOLLOWUP_SURVEY,
  ADMIN_RESET_FOLLOWUP_SURVEY,
  SAVE_FOLLOWUP_SURVEY_TEMP_DATA,
  MARK_FOLLOWUP_SURVEY_COMPLETED,
  GET_SHARE_AGREEMENT_SURVEY,
  GET_SURVEY_REMINDER,
  REPORT_LAB_ISSUE,
  GET_INSURANCE_IN_STATE,
  INSURANCE_MEMBER_LOST_COVERAGE,
  INSURANCE_MEMBER_CHANGE_INSURANCE,
  INSURANCE_MEMBER_SAVE_TMP_INS_DATA,
  ADMIN_ALLOW_ONE_TIME_VISIT_SKIP,
  ADMIN_REMOVE_ONE_TIME_VISIT_SKIP,
  REQUEST_FOR_ITEMIZED_RECEIPT,
  NOTIFY_VITAMINS_BACK_IN_STOCK,
  GET_PERPETUAL_PHYSICIAN_CONVERSATION,
  PERPETUAL_CONVERSATION_THREAD_REPLY,
  ADMIN_GET_HEALTH_COACHES_LIST,
  GET_CALENDLY_USER_BY_EMAIL,
  CREATE_HEALTHCOACH_USER,
  DELETE_HEALTHCOACH_USER,
  GET_HEALTHCOACH_FORM_TARGETS,
  GET_HEALTHCOACH_FORM_TEMPLATES,
  ADMIN_BATCH_MIGRATE_PATIENTS_TO_NEW_CARE_MANAGER,
  B2B_PRECHECK_SUBSCRIBERID_NAMES,
  B2B_PRECHECK_MEMBERID_NAMES,
  B2B_PRECHECK_EMPLOYEEID_NAMES,
  B2B_PRECHECK_UNIQUE_CODE,
  SIGNUP_B2B_SUBSCRIBERID_NAMES,
  SIGNUP_B2B_EMPLOYEEID_NAMES,
  SIGNUP_B2B_MEMBERID_NAMES,
  SIGNUP_B2B_UNIQUE_CODE,
  SURVEY_ANSWERS_SCORES,
  UPDATE_HEALTHCOACH_USER,
  GET_LABS_LIST,
  APPLY_CANCELLATION_FREE_VISIT,
  GET_ATHENA_DIAGNOSED,
  GET_MY_WAITLIST,
  GET_LAB_ORDERS_LIST,
  GET_LAB_ORDER,
  NOTIFY_NEED_CONFIRM_HYPO,
  DELETE_ATHENA_CARD_FOR_PATIENT,
  CANCEL_APPT_MENO,
  GET_FILTER_BANNERS,
  GET_FILTER_BANNER_TEMPLATE,
  HIDE_FILTER_BANNER,
};
