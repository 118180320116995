import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const MobileRescheduleAppointment = ({ match, history }) => {
  useEffect(() => {
    if (!match) return;
    if (!match || !match.params || !match.params.appointmentId) {
      return;
    }

    async function Identify() {
      let redirectTo = '/';
      if (!!match.params.appointmentId) {
        history.push({
          pathname: '/schedule',
          state: { oldId: match.params.appointmentId, version: 'reschedule' },
        });
        return
      }

      history.push(redirectTo);
    }
    Identify();
  }, []);
  return <div />;
};
export const MobileRescheduleMenoAppointment = ({ match, history }) => {
  useEffect(() => {
    if (!match) return;
    if (!match || !match.params || !match.params.appointmentId) {
      return;
    }

    async function Identify() {
      let redirectTo = '/';
      if (!!match.params.appointmentId) {
        history.push({
          pathname: '/schedulemeno',
          state: { oldId: match.params.appointmentId, version: 'reschedule' },
        });
        return
      }

      history.push(redirectTo);
    }
    Identify();
  }, []);
  return <div />;
};

export default withRouter(MobileRescheduleAppointment);
