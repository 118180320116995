import React, { Fragment } from 'react';
import { Flex, Box, Text, Heading, Button, Card } from 'rebass';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';

import RadioInput from '../../components/RadioInput';
import Sticky from '../sticky/Sticky';
import { useIntersection } from '../../lib/useIntersection';
import { fadeInDown } from '../../utils/animationsStyleBuilder';

const MobileView = styled(Flex)`
  flex: 1;
  justify-content: center;
  background-color: white;
  z-index: 1;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const RegularView = styled(Flex)`
  flex: 1;
  justify-content: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;

  @media screen and (max-width: 52em) {
    display: none;
  }
`;

const Survey = (props) => {
  const formatSubmit = (values) => {
    let payload = [];

    for (const k in values) {
      if (values[k] !== 'Never') {
        payload.push({ questionId: k, answer: values[k] });
      }
    }

    return payload;
  };

  const formatSubmitForHS = (values, originals) => {
    // console.log({values, originals})
    let payload = [];

    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      const or = props.selectedSymptoms.find((x) => x.questionId === element.questionId);
      payload.push(`${or.prompt} - ${element.answer}`);
    }

    // for (const k in values) {
    //   if (values[k] && values[k] !== 'Never') {
    //     payload.push(`${values[element.questionId].prompt} - ${element.answer}`)
    //   }
    // }
    // console.log({payload})

    return payload;
  };
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
        <Box m={4} style={{ ...fadeInDown() }}>
          <Text mx={3} fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
            {props.data.prompt}
          </Text>
        </Box>

        <MobileView style={{ backgroundColor: 'transparent' }}>
          <Text mx={3} mb={4} textAlign="center">
            (N = Never, O = Occasionally, F = Frequent, C = Constant)
          </Text>
        </MobileView>

        <Card p={3} mb={5} mx={3} className="blockstick">
          <Sticky stickyStyle={{ zIndex: 10 }} boundaryElement=".blockstick">
            {/* <header> */}
            <MobileView>
              <Flex flex={1} my={3} ml={[0, 0, 5]}>
                <Box width={[1 / 2, 1 / 2, 1 / 3]} ml={3} />

                <Flex flex={1}>
                  <Box width={1 / 4}>
                    <Text textAlign="center">N</Text>
                  </Box>
                  <Box width={1 / 4}>
                    <Text textAlign="center">O</Text>
                  </Box>
                  <Box width={1 / 4}>
                    <Text textAlign="center">F</Text>
                  </Box>
                  <Box width={1 / 4}>
                    <Text textAlign="center">C</Text>
                  </Box>
                </Flex>
              </Flex>
            </MobileView>
            {/* </header> */}
          </Sticky>

          <Sticky stickyStyle={{ zIndex: 10 }} boundaryElement=".blockstick">
            <RegularView>
              <Flex flex={1} my={3} ml={[0, 0, 5]}>
                <Box width={1 / 3} ml={3} />

                <Flex flex={1}>
                  <Box width={1 / 4}>
                    <Text textAlign="center">Never</Text>
                  </Box>
                  <Box width={1 / 4}>
                    <Text textAlign="center">Occasionally</Text>
                  </Box>
                  <Box width={1 / 4}>
                    <Text textAlign="center">Frequent</Text>
                  </Box>
                  <Box width={1 / 4}>
                    <Text textAlign="center">Constant</Text>
                  </Box>
                </Flex>
              </Flex>
            </RegularView>
          </Sticky>

          <Formik
            onSubmit={(values, actions) => {
              const formatted = formatSubmit(values);

              const forHelpscout = formatSubmitForHS(values);

              props.onSubmitToHelpscoutMembers(forHelpscout);
              props.onSubmit(
                {
                  values: formatted,
                  endpoint: props.data.endpoint,
                },
                actions,
                props.wizard,
                props.data
              );
            }}
            render={({ isValid }) => (
              <Form>
                {props.selectedSymptoms.map((el) => (
                  <Fragment key={el.questionId}>
                    <Flex flex={1} mb={[4, 4, 5]} ml={[0, 0, 5]}>
                      <Box width={[1 / 2, 1 / 2, 1 / 3]}>
                        <Text textAlign={['center', 'right', 'right']} fontSize="18px">
                          {el.answer}
                        </Text>
                      </Box>

                      <Flex flex={1} alignItems="center" ml={3}>
                        <Box width={1 / 4}>
                          <RadioInput name={el.questionId} value="Never" ml={2} justifyContent="center" />
                        </Box>
                        <Box width={1 / 4}>
                          <RadioInput name={el.questionId} value="Occasional" ml={2} justifyContent="center" />
                        </Box>
                        <Box width={1 / 4}>
                          <RadioInput name={el.questionId} value="Frequent" ml={2} justifyContent="center" />
                        </Box>
                        <Box width={1 / 4}>
                          <RadioInput name={el.questionId} value="Constant" ml={2} justifyContent="center" />
                        </Box>
                      </Flex>
                    </Flex>
                  </Fragment>
                ))}
                <Flex justifyContent="center" mb={4}>
                  <Button disabled={!isValid} variant="pink" width={[1 / 2, 1 / 3]} type="submit">
                    Next
                  </Button>
                </Flex>
              </Form>
            )}
          />
        </Card>
      </Flex>
    </Fragment>
  );
};

export default withWizard(Survey);
