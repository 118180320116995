import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Text } from 'rebass';
import { Mutation, Query } from 'react-apollo';

import { DELETE_INSURANCE, GET_HAS_INSURANCE_CARD_ON_DOCS, GET_INSURANCE_ON_FILE, GET_MEMBERSHIPS, INSURANCE_MEMBER_LOST_COVERAGE, REQUEST_DELETE_INSURANCE } from '../../graphql';
import PleaseWait from '../../components/PleaseWait';
import { withRouter } from 'react-router';
import withSession from '../../lib/withSession';
import { getLogoSource } from '../scheduleDoctor/helpers/insuranceLogos';
import logoloader2 from '../../static/logoloader2.gif';
import ConfirmModal from '../../components/ConfirmModal';

const Wrapper = styled(Flex)`
  flex: 1;
  border-radius: 8px 8px 8px 8px;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  @media screen and (max-width: 480px) {
    max-width: calc(100vw - 24px);
    margin: 12px;
  }
`;
const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const AdminDocInsurance = ({ history }) => {
  return (
    <Query query={GET_HAS_INSURANCE_CARD_ON_DOCS}>
      {({ loading, data, error }) => {
        if (!!loading) {
          return <PleaseWait style={{ position: 'absolute', left: '50%', top: '50%' }} />;
        }
        if (!!data && !!data.getHasInsuranceCardOnAdminDocs && !data.getHasInsuranceCardOnAdminDocs.hasInsuranceDoc) {
          return (
            <Button onClick={() => history.push('/my-insurances/add')} variant={'primary'}>
              + Add an insurance
            </Button>
          );
        }
        return (
          <Box style={{ background: 'white', width: '100%', padding: '24px 18px', borderRadius: '6px', marginRight: '16px' }}>
            <Flex flexDirection={['column', 'row']} style={{ position: 'relative' }}>
              <img src={logoloader2} alt="" width="84px" />
              <Flex flexDirection={'column'} style={{ flex: 1, marginLeft: '12px' }}>
                <Text style={{ fontWeight: 600, marginBottom: '6px' }}>Your insurance is being verified</Text>
                <Text>Please allow up to 72 hours for us to review your insurance and upload it into your chart</Text>
              </Flex>
            </Flex>
          </Box>
        );
      }}
    </Query>
  );
};

const Insurances = ({ history, session, ...props }) => {
  const [isPickInsuranceSequenceModalVisible, setIsPickInsuranceSequenceModalVisible] = useState(false);
  return (
    <Mutation mutation={REQUEST_DELETE_INSURANCE} refetchQueries={[{ query: GET_INSURANCE_ON_FILE }]}>
      {(mutate, { loading: loadingDelete }) => (
        <Mutation
          mutation={INSURANCE_MEMBER_LOST_COVERAGE}
          refetchQueries={[{ query: GET_INSURANCE_ON_FILE }, { query: GET_HAS_INSURANCE_CARD_ON_DOCS }]}
          awaitRefetchQueries={true}
          onCompleted={() => {
            history.push('/insurance-member/will-be-in-touch/delete');
          }}
        >
          {(mutateInsuranceMemberLostCoverage, { loading: loadingLostCoverage }) => (
            <Query query={GET_INSURANCE_ON_FILE}>
              {({ loading, data, error }) => {
                if (!!loading || !!loadingDelete || !!loadingLostCoverage) {
                  return <PleaseWait style={{ position: 'absolute', left: '50%', top: '50%' }} />;
                }
                return (
                  <Wrapper>
                    {!!data && !!data.getInsurancesOnFile && data.getInsurancesOnFile.length > 0 ? (
                      <>
                        {data.getInsurancesOnFile.map((x) => (
                          <>
                            {!x.insuranceIdNumber ||
                              (x.insuranceIdNumber !== 'TmpIns-1' &&
                                x.insuranceIdNumber !== 'TmpIns-3' && (
                                  <Text style={{ fontFamily: 'Poppins Medium', fontSize: 20, marginBottom: 16 }}>{x.sequenceNumber === 1 ? 'Primary Insurance:' : 'Secondary Insurance'}</Text>
                                ))}
                            <Box style={{ background: 'white', width: '100%', padding: '24px 18px', borderRadius: '6px', marginBottom: '24px', marginRight: '16px' }}>
                              <Flex flexDirection={['column', 'row']} style={{ position: 'relative' }}>
                                <img src={getLogoSource(x.insuranceCompany)} alt="" width="82px" style={{ objectFit: 'contain', marginRight: '12px' }} />
                                <Flex flexDirection={'column'} style={{ flex: 1 }}>
                                  <Text style={{ fontSize: '22px', fontWeight: 600 }}>{x.insuranceCompany}</Text>
                                  {x.insuranceIdNumber !== 'TmpIns-1' && x.insuranceIdNumber !== 'TmpIns-3' ? (
                                    <Text>{`Member ID: ${x.insuranceIdNumber}`}</Text>
                                  ) : x.insuranceIdNumber === 'TmpIns-1' ? (
                                    <Text>Insurance under review by our team</Text>
                                  ) : (
                                    <>
                                      <Text>We need more information</Text>
                                      <Button variant={'primary'} mt={3} onClick={() => history.push('/my-insurances/add')}>
                                        Complete
                                      </Button>
                                    </>
                                  )}
                                </Flex>
                                <svg
                                  onClick={() => {
                                    if (!!loading || !!loadingDelete || !!loadingLostCoverage) return;

                                    if (session && session.isMember && session.membershipSpecific === 'insurance') {
                                      mutateInsuranceMemberLostCoverage();
                                    } else {
                                      mutate({
                                        variables: {
                                          insuranceId: x.insuranceId,
                                        },
                                      });
                                    }
                                  }}
                                  width="28"
                                  height="28"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ position: 'absolute', top: -38, right: -32, zIndex: 1 }}
                                >
                                  <path
                                    d="M14.0002 24.6654C19.8912 24.6654 24.6668 19.8897 24.6668 13.9987C24.6668 8.10766 19.8912 3.33203 14.0002 3.33203C8.10912 3.33203 3.3335 8.10766 3.3335 13.9987C3.3335 19.8897 8.10912 24.6654 14.0002 24.6654Z"
                                    stroke="#EB001B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path d="M10 10L18 18" stroke="#EB001B" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M18 10L10 18" stroke="#EB001B" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </Flex>
                            </Box>
                            {/*  */}

                            <Text fontWeight={300} mt="18px">
                              Please note that any insurance update or change might take up to 72 hours to be reflected.
                            </Text>
                          </>
                        ))}
                        {data.getInsurancesOnFile.filter((x) => !!x.insuranceIdNumber && (x.insuranceIdNumber === 'TmpIns-1' || x.insuranceIdNumber === 'TmpIns-3')).length < 1 && (
                          <Button variant={'primary'} style={{ alignSelf: 'flex-start' }} onClick={() => setIsPickInsuranceSequenceModalVisible(true)}>
                            + Add another insurance
                          </Button>
                        )}
                      </>
                    ) : (
                      <AdminDocInsurance history={history} />
                    )}
                    <ConfirmModal
                      isOpen={isPickInsuranceSequenceModalVisible}
                      okOnly
                      childrenManaged
                      onClose={() => {
                        setIsPickInsuranceSequenceModalVisible(false);
                      }}
                      onConfirm={() => {
                        setIsPickInsuranceSequenceModalVisible(false);
                      }}
                      onBackgroundClick={() => {
                        setIsPickInsuranceSequenceModalVisible(false);
                      }}
                      onEscapeKeydown={() => {
                        setIsPickInsuranceSequenceModalVisible(false);
                      }}
                    >
                      <ModalInner>
                        <Flex flexDirection={'column'}>
                          <Button onClick={() => history.push('/my-insurances/add')} variant="primary">
                            New Primary Insurance
                          </Button>
                          <Button onClick={() => history.push('/my-insurances/add-secondary')} mt={3} variant="primary">
                            New Secondary Insurance
                          </Button>
                        </Flex>
                      </ModalInner>
                    </ConfirmModal>
                  </Wrapper>
                );
              }}
            </Query>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default withRouter(withSession(Insurances));
