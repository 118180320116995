import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading, Text, Box } from 'rebass';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { Check, VideoCamera } from '../../components/icons';

const Icon2 = styled(Flex)`
  @media screen and (max-width: 440px) {
    display: none;
  }
`;
const InfoBox = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${(props) => props.theme.color.darkBlue};
  justify-content: center;
`;

const InfoCard = styled(Card)`
  background: ${(props) => props.theme.color.pink};
  color: ${(props) => props.theme.color.darkBlue};
`;

const Icon = styled(Flex)`
  @media screen and (max-width: 440px) {
    display: none;
  }
`;
const BlueHeading = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #354e79;
`;

const WhiteBullet = styled(Flex)`
  background: #eff0f7;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 20px;
  color: #354e79;
  justify-content: center;
  align-items: center;
`;
const BackButton = styled.div`
  background: rgba(180, 205, 244, 0.5);
  width: 100%;
  color: #333;
  padding: 6px 12px;
  margin-bottom: 160px;
  margin-left: -12px;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const CommunicatorFlex = styled(Flex)`
  width: 100%;
  flex-direction: column;
  @media screen and (min-width: 52em) {
    width: calc(100% - 80px);
  }
`;

const CommunicatorChoiceDesktopOnly = styled.div`
  @media screen and (max-width: 52em) {
    display: none;
  }
  max-height: calc(100% - 80px);
`;

const CommunicatorChoiceMobileOnly = styled.div`
  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const Avatar = styled(Flex)`
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  margin-right: 6px;
  & > img {
    width: 40px;
  }
`;

export const Consult30Future = (props) => {
  let tiz;
  try {
    tiz = moment(`${props.appointment.date} ${props.appointment.startTime}`, 'MM/DD/YYYY HH:mm')
      .tz(props.appointment.timezoneName)
      .zoneAbbr();
  } catch (error) {}
  return (
    <>
      <Card p={[3, 3, 4]} mb={4}>
        <InfoBox>
          <Icon mr={[0, 4, 4]} pt={1}>
            <Check width={40} />
          </Icon>
          <Flex flex={1} flexDirection="column">
            <Heading mb={3} textAlign={['center', 'center', 'left']}>
              Your visit is confirmed
            </Heading>
            <Text fontWeight={700} textAlign={['center', 'center', 'left']}>
              {`${moment(props.appointment.startTime, 'HH:mm').format('h:mma')} ${tiz} on 
                  ${moment(props.appointment.date, 'MM/DD/YYYY').format('dddd MMMM Do')}`}
            </Text>
          </Flex>
        </InfoBox>
      </Card>

      <Card p={[3, 3, 4]}>
        <Flex>
          <Flex flex={1} flexDirection="column">
            <Flex flexDirection="row">
              <Icon mr={[0, 4, 4]} ml="6px">
                <VideoCamera fill="#9BBDF9" width={50} />
              </Icon>
              <Heading mb={3}>Video Visit Information</Heading>
            </Flex>
            <Flex flexDirection="row" mt={3}>
              <Box
                style={{
                  background: '#FDF1EF',
                  width: '40px',
                  minWidth: '40px',
                  height: '40px',
                  borderRadius: '30px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '24px',
                  display: 'flex',
                }}
              >
                <Text>1</Text>
              </Box>
              <Text mb={3}>You can use your mobile or laptop/desktop computer with a supported web browser (Chrome, Safari, or Firefox). Make sure your computer is equipped with a webcam.</Text>
            </Flex>
            <Flex flexDirection="row">
              <Box
                style={{
                  background: '#FDF1EF',
                  width: '40px',
                  minWidth: '40px',
                  height: '40px',
                  borderRadius: '30px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '24px',
                  display: 'flex',
                }}
              >
                <Text>2</Text>
              </Box>
              <Text mb={3}>
                You may enter the waiting room 15 minutes before your appointment{' '}
                <span
                  style={{ color: '#2296bd', textDecoration: 'underline' }}
                  onClick={() => {
                    props.history.push(`/visio-nutritionist/${props.appointment.provider.id}`);
                  }}
                >
                  here
                </span>
                .
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      <Card p={[3, 3, 4]} mt={4}>
        <Flex>
          <Flex flex={1} flexDirection="column">
            <Flex flexDirection="row" alignItems="center">
              <Icon mr={[0, 4, 4]} ml="6px">
                <svg width="50" height="55" viewBox="0 0 55 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M27.5 6.66955L48.8889 16.1409V30.4545C48.8889 44.22 39.7833 56.9195 27.5 60.6959C15.2167 56.9195 6.11111 44.22 6.11111 30.4545V16.1409L27.5 6.66955ZM27.5 0L0 12.1818V30.4545C0 47.3568 11.7333 63.1627 27.5 67C43.2667 63.1627 55 47.3568 55 30.4545V12.1818L27.5 0ZM24.4444 18.2727H30.5556V24.3636H24.4444V18.2727ZM24.4444 30.4545H30.5556V48.7273H24.4444V30.4545Z"
                    fill="#9BBDF9"
                  />
                </svg>
              </Icon>
              <Heading mb={3}>Tips for a better experience</Heading>
            </Flex>
            <Flex flexDirection="row" mt={3}>
              <Box
                style={{
                  background: '#FDF1EF',
                  width: '40px',
                  minWidth: '40px',
                  height: '40px',
                  borderRadius: '30px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '24px',
                  display: 'flex',
                }}
              >
                <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                    <path
                      d="M17.0381 20.6252C18.0736 20.6252 18.9131 19.7858 18.9131 18.7502C18.9131 17.7147 18.0736 16.8752 17.0381 16.8752C16.0026 16.8752 15.1631 17.7147 15.1631 18.7502C15.1631 19.7858 16.0026 20.6252 17.0381 20.6252Z"
                      fill="#344F7A"
                    />
                    <path
                      d="M11.8535 13.3438L13.1791 14.6685C14.2181 13.6791 15.5973 13.1266 17.032 13.125C18.4666 13.1235 19.8471 13.673 20.8882 14.66L22.2129 13.3353C20.8205 11.9962 18.9632 11.249 17.0313 11.2507C15.0995 11.2525 13.2435 12.0031 11.8535 13.3447V13.3438Z"
                      fill="#344F7A"
                    />
                    <path
                      d="M7.87793 9.36898L9.20355 10.6937C11.296 8.64813 14.1055 7.50203 17.0316 7.50028C19.9578 7.49853 22.7687 8.64126 24.8636 10.6843L26.1882 9.35961C23.7422 6.96453 20.4546 5.62398 17.0312 5.62573C13.6078 5.62749 10.3215 6.97141 7.87793 9.36898V9.36898Z"
                      fill="#344F7A"
                    />
                    <path
                      d="M30.1631 5.38245C26.6652 1.93352 21.9503 0 17.0381 0C12.1258 0 7.41097 1.93352 3.91309 5.38245V5.40401L5.22934 6.72026C8.374 3.61766 12.6134 1.87736 17.031 1.8756C21.4486 1.87385 25.6894 3.61079 28.8365 6.71089L30.1631 5.38433V5.38245Z"
                      fill="#344F7A"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Box>
              <Text mb={3}>Choose a well-lit space with a steady internet connection and minimal background noise.</Text>
            </Flex>
            <Flex flexDirection="row">
              <Box
                style={{
                  background: '#FDF1EF',
                  width: '40px',
                  minWidth: '40px',
                  height: '40px',
                  borderRadius: '30px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '24px',
                  display: 'flex',
                }}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.9998 5V13.55C11.0598 13.01 9.89977 12.8 8.66977 13.23C7.32977 13.71 6.29977 14.9 6.05977 16.3C5.94881 16.9299 5.99028 17.5773 6.1807 18.1879C6.37112 18.7986 6.70496 19.3547 7.15431 19.81C7.60366 20.2652 8.15545 20.6062 8.76356 20.8046C9.37168 21.0029 10.0184 21.0528 10.6498 20.95C12.6098 20.64 13.9998 18.84 13.9998 16.85V7H15.9998C17.0998 7 17.9998 6.1 17.9998 5C17.9998 3.9 17.0998 3 15.9998 3H13.9998C12.8998 3 11.9998 3.9 11.9998 5Z"
                    fill="#344F7A"
                  />
                </svg>
              </Box>
              <Text mb={3}>When you join the video visit, check that your video and audio are working correctly.</Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </>
  );
};

export const Consult30Today = (props) => {
  let tiz;
  try {
    tiz = moment(`${props.appointment.date} ${props.appointment.startTime}`, 'MM/DD/YYYY HH:mm')
      .tz(props.appointment.timezoneName)
      .zoneAbbr();
  } catch (error) {}
  return (
    <>
      <Card p={[3, 3, 4]} mb={4}>
        <InfoBox mb={4}>
          <Icon mr={[0, 4, 4]} pt={1}>
            <Check width={40} />
          </Icon>
          <Flex flex={1} flexDirection="column">
            <Heading mb={3} textAlign={['center', 'center', 'left']}>
              Your visit is today
            </Heading>
            <Text fontWeight={700} textAlign={['center', 'center', 'left']}>
              {`${moment(props.appointment.startTime, 'HH:mm').format('h:mma')} ${tiz}`}
            </Text>
          </Flex>
        </InfoBox>
        <Flex alignItems="center" justifyContent="center">
          <Button
            variant="primary"
            width={[1, 1 / 2]}
            mb={[3, 0, 0]}
            onClick={() => {
              props.history.push(`/visio-nutritionist/${props.appointment.provider.id}`);
            }}
          >
            Start Video Visit
          </Button>
        </Flex>
      </Card>

      <Card p={[3, 3, 4]}>
        <Flex>
          <Flex flex={1} flexDirection="column">
            <Flex flexDirection="row">
              <Icon mr={[0, 4, 4]} ml="6px">
                <VideoCamera fill="#9BBDF9" width={50} />
              </Icon>
              <Heading mb={3}>Video Visit Information</Heading>
            </Flex>
            <Flex flexDirection="row" mt={3}>
              <Box
                style={{
                  background: '#FDF1EF',
                  width: '40px',
                  minWidth: '40px',
                  height: '40px',
                  borderRadius: '30px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '24px',
                  display: 'flex',
                }}
              >
                <Text>1</Text>
              </Box>
              <Text mb={3}>You can use your mobile or laptop/desktop computer with a supported web browser (Chrome, Safari, or Firefox). Make sure your computer is equipped with a webcam.</Text>
            </Flex>
            <Flex flexDirection="row">
              <Box
                style={{
                  background: '#FDF1EF',
                  width: '40px',
                  minWidth: '40px',
                  height: '40px',
                  borderRadius: '30px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '24px',
                  display: 'flex',
                }}
              >
                <Text>2</Text>
              </Box>
              <Text mb={3}>
                You may enter the waiting room 15 minutes before your appointment{' '}
                <span
                  style={{ color: '#2296bd', textDecoration: 'underline' }}
                  onClick={() => {
                    props.history.push(`/visio-nutritionist/${props.appointment.provider.id}`);
                  }}
                >
                  here
                </span>
                .
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      <Card p={[3, 3, 4]} mt={4}>
        <Flex>
          <Flex flex={1} flexDirection="column">
            <Flex flexDirection="row">
              <Icon mr={[0, 4, 4]} ml="6px">
                <svg width="50" height="55" viewBox="0 0 55 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M27.5 6.66955L48.8889 16.1409V30.4545C48.8889 44.22 39.7833 56.9195 27.5 60.6959C15.2167 56.9195 6.11111 44.22 6.11111 30.4545V16.1409L27.5 6.66955ZM27.5 0L0 12.1818V30.4545C0 47.3568 11.7333 63.1627 27.5 67C43.2667 63.1627 55 47.3568 55 30.4545V12.1818L27.5 0ZM24.4444 18.2727H30.5556V24.3636H24.4444V18.2727ZM24.4444 30.4545H30.5556V48.7273H24.4444V30.4545Z"
                    fill="#9BBDF9"
                  />
                </svg>
              </Icon>
              <Heading mb={3}>Tips for a better experience</Heading>
            </Flex>
            <Flex flexDirection="row" mt={3}>
              <Box
                style={{
                  background: '#FDF1EF',
                  width: '40px',
                  minWidth: '40px',
                  height: '40px',
                  borderRadius: '30px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '24px',
                  display: 'flex',
                }}
              >
                <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                    <path
                      d="M17.0381 20.6252C18.0736 20.6252 18.9131 19.7858 18.9131 18.7502C18.9131 17.7147 18.0736 16.8752 17.0381 16.8752C16.0026 16.8752 15.1631 17.7147 15.1631 18.7502C15.1631 19.7858 16.0026 20.6252 17.0381 20.6252Z"
                      fill="#344F7A"
                    />
                    <path
                      d="M11.8535 13.3438L13.1791 14.6685C14.2181 13.6791 15.5973 13.1266 17.032 13.125C18.4666 13.1235 19.8471 13.673 20.8882 14.66L22.2129 13.3353C20.8205 11.9962 18.9632 11.249 17.0313 11.2507C15.0995 11.2525 13.2435 12.0031 11.8535 13.3447V13.3438Z"
                      fill="#344F7A"
                    />
                    <path
                      d="M7.87793 9.36898L9.20355 10.6937C11.296 8.64813 14.1055 7.50203 17.0316 7.50028C19.9578 7.49853 22.7687 8.64126 24.8636 10.6843L26.1882 9.35961C23.7422 6.96453 20.4546 5.62398 17.0312 5.62573C13.6078 5.62749 10.3215 6.97141 7.87793 9.36898V9.36898Z"
                      fill="#344F7A"
                    />
                    <path
                      d="M30.1631 5.38245C26.6652 1.93352 21.9503 0 17.0381 0C12.1258 0 7.41097 1.93352 3.91309 5.38245V5.40401L5.22934 6.72026C8.374 3.61766 12.6134 1.87736 17.031 1.8756C21.4486 1.87385 25.6894 3.61079 28.8365 6.71089L30.1631 5.38433V5.38245Z"
                      fill="#344F7A"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Box>
              <Text mb={3}>Choose a well-lit space with a steady internet connection and minimal background noise.</Text>
            </Flex>
            <Flex flexDirection="row">
              <Box
                style={{
                  background: '#FDF1EF',
                  width: '40px',
                  minWidth: '40px',
                  height: '40px',
                  borderRadius: '30px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '24px',
                  display: 'flex',
                }}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.9998 5V13.55C11.0598 13.01 9.89977 12.8 8.66977 13.23C7.32977 13.71 6.29977 14.9 6.05977 16.3C5.94881 16.9299 5.99028 17.5773 6.1807 18.1879C6.37112 18.7986 6.70496 19.3547 7.15431 19.81C7.60366 20.2652 8.15545 20.6062 8.76356 20.8046C9.37168 21.0029 10.0184 21.0528 10.6498 20.95C12.6098 20.64 13.9998 18.84 13.9998 16.85V7H15.9998C17.0998 7 17.9998 6.1 17.9998 5C17.9998 3.9 17.0998 3 15.9998 3H13.9998C12.8998 3 11.9998 3.9 11.9998 5Z"
                    fill="#344F7A"
                  />
                </svg>
              </Box>
              <Text mb={3}>When you join the video visit, check that your video and audio are working correctly.</Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>

      {/* <Flex alignItems="center" flexDirection={['column', 'row', 'row']} mt={4}>
        <Button
          variant="pink"
          width={[1, 1 / 2, 1]}
          mb={[3, 0, 0]}
          onClick={() =>
            props.history.push({
              pathname: '/schedule',
              state: { oldId: props.appointment.id, version: 'reschedule' },
            })
          }
        >
          Reschedule
        </Button>
        <Button variant="dim" width={[1, 1 / 2, 1]} ml={[0, 3, 3]} onClick={props.onClick} id="btn-consultationsFuture-cancel">
          Cancel
        </Button>
      </Flex> */}
    </>
  );
};

