import React, { useState } from 'react';
import { Query } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Flex } from 'rebass';
import PleaseWait from '../../components/PleaseWait';
import { ADMIN_GET_CARE_MANAGER_USERS_LIST } from '../../graphql';

const DetailRows = ({ row, helpscoutId }) => {
  const [changeLinked, setChangeLinked] = useState();
  const CATEGORIES_COLUMNS = [
    {
      Header: 'Athena Id',
      accessor: 'athenaId',
    },
    {
      Header: 'email',
      accessor: 'email',
    },
    {
      Header: 'first name',
      accessor: 'firstName',
    },
    {
      Header: 'last name',
      accessor: 'lastName',
    },
  ];
  return (
    <Query
      query={ADMIN_GET_CARE_MANAGER_USERS_LIST}
      variables={{
        helpscoutId,
      }}
    >
      {({ data, loading, error }) => {
        if (!!loading) return <PleaseWait />;
        return (
          <Flex flexDirection={'column'} style={{ maxWidth: '700px' }} mx={4}>
            {data &&
              data.adminGetCareManagerUsersList && (
                <ReactTable
                  data={data.adminGetCareManagerUsersList}
                  columns={CATEGORIES_COLUMNS}
                  defaultPageSize={data.adminGetCareManagerUsersList && data.adminGetCareManagerUsersList.length > 3 ? Math.min(data.adminGetCareManagerUsersList.length, 25) : 3}
                  pageSize={data.adminGetCareManagerUsersList && data.adminGetCareManagerUsersList.length > 3 ? data.adminGetCareManagerUsersList.length : 3}
                  showPagination={false}
                />
              )}
          </Flex>
        );
      }}
    </Query>
  );
};
const CareManagersList = ({ data }) => {
  const [isAdd, setIsAdd] = useState(null);
  const CATEGORIES_COLUMNS = [
    {
      Header: 'full name',
      accessor: 'fullName',
    },
    {
      Header: 'helpscout Id',
      accessor: 'helpscoutId',
    },
    {
      Header: 'patients count',
      accessor: 'patientCount',
    },
    {
      Header: 'active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div style={{ textAlign: 'center', color: row.original.isActive ? 'green' : 'red' }}>{row.original.isActive ? '✓' : 'x'}</div>;
      },
    },
    {
      Header: 'accept new',
      accessor: 'acceptNew',
      Cell: (row) => {
        return <div style={{ textAlign: 'center', color: row.original.acceptNew ? 'green' : 'red' }}>{row.original.acceptNew ? '✓' : 'x'}</div>;
      },
    },
    {
      Header: 'is fallback',
      accessor: 'isFallback',
      Cell: (row) => {
        return <div style={{ textAlign: 'center', color: row.original.isFallback ? 'green' : 'red' }}>{row.original.isFallback ? '✓' : 'x'}</div>;
      },
    },
    {
      Header: 'calendly link',
      accessor: 'calendlyLink',
    },
    // {
    //   Header: '',
    //   Cell: (row, rest) => {
    //     return (
    //       <Flex flexDirection="row">
    //         <Flex flexDirection="row">
    //           <div title="delete" style={{ cursor: 'pointer' }} onClick={() => deleteOnClick(row.original.id)}>
    //             🗑
    //           </div>
    //           <div title="edit" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => setIsAdd(row.original)}>
    //             ✎
    //           </div>
    //         </Flex>
    //       </Flex>
    //     );
    //   },
    // },
  ];

  const deleteOnClick = (id) => {};
  return (
    <div>
      {data &&
        data.adminGetCareManagersList && (
          <ReactTable
            data={data.adminGetCareManagersList}
            columns={CATEGORIES_COLUMNS}
            defaultPageSize={data.adminGetCareManagersList && data.adminGetCareManagersList.length > 3 ? Math.min(data.getAdminBrokersList.length, 25) : 3}
            pageSize={data.adminGetCareManagersList && data.adminGetCareManagersList.length > 3 ? data.adminGetCareManagersList.length : 3}
            showPagination={false}
            SubComponent={(row) => {
              console.log({ row });
              return <DetailRows row={row} helpscoutId={row.original.helpscoutId} />;
            }}
          />
        )}
    </div>
  );
};

export default CareManagersList;
