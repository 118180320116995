import React, { Fragment, useEffect, useState } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import check from '@iconify/icons-entypo/check';
import Dropzone from 'react-dropzone';
import { BoxButton } from '../../../components/async/SharedComponents';
import { isMobile } from 'react-device-detect';
import PhotoTaker from '../../../components/asyncIntake/PhotoTaker';
import gql from 'graphql-tag';
import moment from 'moment';
import { MaskInput, MaskInput2 } from '../../../components/MaskInput';
import ConfirmModal from '../../../components/ConfirmModal';
import SpinLoader from '../../../components/SpinLoader';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;
const LabToUpload = styled(Flex)`
  background: #ffffff;
  border: 1px solid rgba(53, 78, 121, 0.5);
  border-radius: 10px;
  padding: 12px;
  position: relative;
  flex: 1;
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
`;

const Description = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
`;

const CancelButton = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  text-align: center;
  text-decoration-line: underline;
  color: #44a5ff;
  margin-top: 24px;
  margin-bottom: 12px;
`;

const EmptyStateFlexWrapper = styled(Flex)`
  cursor: pointer;
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
  &:focus:not(.focus-visible) {
    outline: 0 !important;
    box-shadow: none !important;
  }
`;

const FakeLink = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
  color: #44a5ff;
`;

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const PdfMiniature = ({ file, onFileRemove, onConfirm }) => {
  const [collectionDate, setCollectionDate] = useState();
  const [errorDate, setErrorDate] = useState();

  useEffect(
    () => {
      checkDate();
    },
    [collectionDate]
  );

  const checkDate = () => {
    if (!collectionDate || collectionDate.indexOf('_') > -1) {
      setErrorDate('Required');
      return false;
    }
    try {
      const d = moment(collectionDate, 'MM/DD/YYYY');
      if (d.isAfter(moment(), 'day')) {
        setErrorDate('Date must be in the past');
        return false;
      }
      setErrorDate(null);
      return true;
    } catch (error) {
      setErrorDate('Invalid date');
      return false;
    }
  };
  return (
    <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
      <Box mt="0" mb={4}>
        <Heading textAlign="left">Date of collection</Heading>
      </Box>

      <Text>Please indicate the collection date</Text>
      <MaskInput2
        inputStyle={{
          style: {
            flex: 'initial',
          },
        }}
        name="dob"
        placeholder="Collection Date (MM/DD/YYYY)"
        mask="99/99/9999"
        value={collectionDate}
        onChange={(e) => {
          console.log(e.target.value);
          setCollectionDate(e.target.value);
        }}
        id="input_dob"
      />

      {!!errorDate && (
        <Text fontSize={'12px'} color="red">
          {errorDate}
        </Text>
      )}

      <Button
        disabled={!collectionDate || !!errorDate}
        onClick={() => onConfirm(collectionDate)}
        variant="primary"
        mt={4}
        style={{
          marginLeft: '12px',
          marginRight: '12px',
        }}
      >
        Confirm
      </Button>
    </Flex>
  );
};

const PictureMiniature = ({ file, onFileRemove, onConfirm }) => {
  const [collectionDate, setCollectionDate] = useState();
  const [errorDate, setErrorDate] = useState();

  useEffect(
    () => {
      checkDate();
    },
    [collectionDate]
  );

  const checkDate = () => {
    if (!collectionDate || collectionDate.indexOf('_') > -1) {
      setErrorDate('Required');
      return false;
    }
    try {
      const d = moment(collectionDate, 'MM/DD/YYYY');
      if (d.isAfter(moment(), 'day')) {
        setErrorDate('Date must be in the past');
        return false;
      }
      setErrorDate(null);
      return true;
    } catch (error) {
      setErrorDate('Invalid date');
      return false;
    }
  };
  return (
    <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
      <Box mt="0" mb={4}>
        <Heading textAlign="left">Review and Confirm</Heading>
      </Box>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        style={{
          width: 'calc(100vw / 2)',
          maxWidth: '400px',
          borderRadius: '20px',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
          position: 'relative',
        }}
      >
        <img
          src={(file || {}).data}
          alt="No Preview Available"
          width="100%"
          style={{
            maxHeight: '300px',
            borderRadius: '20px',
            objectFit: 'contain',
          }}
        />
        <Button onClick={onFileRemove} variant="white" style={{ position: 'absolute', bottom: '6px', right: '6px' }}>
          Change
        </Button>
      </Flex>

      <Box mt={3} mb={3}>
        <Title textAlign="left">Ensure that...</Title>
      </Box>
      <Description>
        <span>
          <Icon
            icon={check}
            style={{
              color: 'rgb(53, 78, 121)',
              fontSize: '18px',
              alignSelf: 'center',
            }}
          />
        </span>{' '}
        The photo is not blurry or dark
      </Description>
      <Description>
        <span>
          <Icon
            icon={check}
            style={{
              color: 'rgb(53, 78, 121)',
              fontSize: '18px',
              alignSelf: 'center',
            }}
          />
        </span>{' '}
        We can clearly read the text
      </Description>

      <Box mt={3} mb={3}>
        <Title textAlign="left">Date of collection</Title>
      </Box>
      <Text>Please indicate the collection date</Text>
      <MaskInput2
        inputProps={{
          style: {
            flex: 'initial',
          },
        }}
        name="dob"
        placeholder="Collection Date (MM/DD/YYYY)"
        mask="99/99/9999"
        value={collectionDate}
        onChange={(e) => {
          console.log(e.target.value);
          setCollectionDate(e.target.value);
        }}
        id="input_dob"
      />

      {!!errorDate && (
        <Text fontSize={'12px'} color="red">
          {errorDate}
        </Text>
      )}

      <Button
        disabled={!collectionDate || !!errorDate}
        onClick={() => onConfirm(collectionDate)}
        variant="primary"
        mt={4}
        style={{
          marginLeft: '12px',
          marginRight: '12px',
        }}
      >
        Confirm
      </Button>
    </Flex>
  );
};

const UPLOAD_LAB_RESULTS = gql`
  mutation($file: String!, $collectionDate: String) {
    uploadLabResultAsync(file: $file, collectionDate: $collectionDate) {
      ok
    }
  }
`;

const EmptyState = ({ onDropAccepted, takeDesktopPhoto = () => {}, toastManager, disabled }) => {
  return (
    <Dropzone
      disableClick={disabled}
      disabled={disabled}
      noClick={false}
      multiple={false}
      onDropRejected={() => {
        toastManager.add('Invalid file: Make sure your file is a pdf or a jpeg or a png and less than 9mb', {
          appearance: 'error',
          autoDismissTimeout: 10000,
        });
      }}
      maxSize={9000000}
      onDropAccepted={onDropAccepted}
      accept={'image/jpeg, image/png, application/pdf'}
      style={{
        marginBottom: 36,
      }}
    >
      {({ getRootProps, getInputProps, isDragActive, open }) => (
        <EmptyStateFlexWrapper
          flexDirection="column"
          {...getRootProps()}
          onClick={() => {
            open();
          }}
        >
          {isMobile ? (
            <Button variant="pink" disabled={disabled}>
              + Upload Lab
            </Button>
          ) : (
            <Flex flexDirection="row" justifyContent="center">
              <Button variant="pink" mr={2} disabled={disabled}>
                + Upload File
              </Button>
              <Button
                variant="pink"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  takeDesktopPhoto();
                }}
                ml={2}
                disabled={disabled}
              >
                + Take Photo
              </Button>
            </Flex>
          )}

          <input type="file" {...getInputProps()} />
        </EmptyStateFlexWrapper>
      )}
    </Dropzone>
  );
};

const onDesktopPhotoCapture = async (dta, callback) => {
  var reader = new FileReader();
  reader.readAsDataURL(dta);
  reader.onloadend = async () => {
    var base64data = reader.result;

    const fls = {
      fileName: `${uuidv4()}.jpg`,
      mimeType: 'image/jpeg',
      data: base64data,
    };

    callback(fls);
  };
};

const fileAccepted = (f, callback) => {
  for (let index = 0; index < f.length; index++) {
    const element = f[index];

    const reader = new FileReader();
    reader.onload = (event) => {
      let fls = f;
      fls = {
        fileName: element.name,
        mimeType: element.type,
        data: event.target.result,
      };

      callback(fls);
    };
    reader.readAsDataURL(element);
  }
};

const UploadLab = ({ onSubmit, data, gotoStep, client, toastManager }) => {
  const [labs, setLabs] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isCardFrontTaking, setIsCardFrontTaking] = useState(false);
  const [isCardFrontConfirming, setIsCardFrontConfirming] = useState(false);
  const [isPdfConfirming, setIsPdfConfirming] = useState(false);

  if (isCardFrontTaking) {
    return (
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
        <PhotoTaker
          onCapture={(f) =>
            onDesktopPhotoCapture(f, (ff) => {
              setLabs((oldArr) => [...oldArr, ff]);
              setIsCardFrontConfirming(true);
              setIsCardFrontTaking(false);
            })
          }
        />
      </Flex>
    );
  }

  return (
    <Fragment>
      {!isCardFrontConfirming && !isPdfConfirming ? (
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          <Box mt="0" mb={3}>
            <Title textAlign="left">Please select labs to upload</Title>
          </Box>

          <Box mt="0" mb={4}>
            <Description>Prepare for your visit by uploading</Description>
            <Description style={{ maxWidth: '500px', marginTop: '16px' }}>
              <ul>
                <li>Labs within 4 months of your visit</li>
                <li>
                  Ensure the docume displays&nbsp;
                  <span style={{ fontStyle: 'italic' }}>both</span>
                  &nbsp;the lab name and your name
                </li>
                <li>{`Preferably upload as PDFs; avoid screenshots from your phone`}</li>
              </ul>
            </Description>
            <Description mt={3}>This ensures your doctor can prescribe effectively. Thank you.</Description>
            <Description mt={3}>
              If you are having any trouble uploading your labs, please email them to:&nbsp;
              <a href="mailto:care@palomahealth.com">care@palomahealth.com</a>.<br />
              PDFs are preferable. Please do not upload screenshot from your mobile device.
            </Description>
          </Box>

          <Flex flexDirection="column">
            {labs.map((x, i) => {
              return (
                <Flex key={`uploadLab${i}-${x.name}`} mb={3}>
                  <LabToUpload>
                    <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.11702 7.0367V5.6055C6.11702 5.47431 6.22713 5.36697 6.3617 5.36697H18.1064C18.241 5.36697 18.3511 5.47431 18.3511 5.6055V7.0367C18.3511 7.16789 18.241 7.27523 18.1064 7.27523H6.3617C6.22713 7.27523 6.11702 7.16789 6.11702 7.0367ZM18.1064 9.66055C18.241 9.66055 18.3511 9.76789 18.3511 9.89908V11.3303C18.3511 11.4615 18.241 11.5688 18.1064 11.5688H12.4787C12.3441 11.5688 12.234 11.4615 12.234 11.3303V9.89908C12.234 9.76789 12.3441 9.66055 12.4787 9.66055H18.1064ZM6.60638 13.1193C10.2552 13.1193 13.2128 16.0025 13.2128 19.5596C13.2128 23.1167 10.2552 26 6.60638 26C2.95758 26 0 23.1167 0 19.5596C0 16.0025 2.95758 13.1193 6.60638 13.1193ZM3.31848 22.7649C4.19628 23.6206 5.36463 24.0917 6.60638 24.0917C7.84814 24.0917 9.01649 23.6206 9.89428 22.7649C10.7721 21.9092 11.2553 20.7702 11.2553 19.5596C11.2553 18.3491 10.7721 17.2101 9.89428 16.3544C9.01649 15.4986 7.84814 15.0275 6.60638 15.0275C5.36463 15.0275 4.19628 15.4986 3.31848 16.3544C2.44069 17.2101 1.95745 18.3491 1.95745 19.5596C1.95745 20.7702 2.44069 21.9092 3.31848 22.7649ZM3.88431 17.2936H5.23923C5.31875 17.2936 5.39215 17.3294 5.43803 17.392L7.38019 20.0099L8.0867 19.0587C8.10933 19.028 8.1392 19.003 8.1738 18.9859C8.2084 18.9688 8.24671 18.96 8.28551 18.9603H9.63431C9.83311 18.9603 9.94934 19.181 9.83311 19.339L7.57593 22.3833C7.47806 22.5145 7.27925 22.5145 7.18138 22.3833L3.68856 17.6722C3.56928 17.5142 3.6855 17.2936 3.88431 17.2936ZM13.7021 23.1376H20.7979V2.14679H3.67021V12.4037C3.67021 12.5349 3.56011 12.6422 3.42553 12.6422H1.71277C1.57819 12.6422 1.46808 12.5349 1.46808 12.4037V0.954128C1.46808 0.426376 1.90545 0 2.44681 0H22.0213C22.5626 0 23 0.426376 23 0.954128V24.3303C23 24.858 22.5626 25.2844 22.0213 25.2844H13.7021C13.5676 25.2844 13.4574 25.1771 13.4574 25.0459V23.3761C13.4574 23.245 13.5676 23.1376 13.7021 23.1376Z"
                        fill="#354E79"
                        fillOpacity="0.7"
                      />
                    </svg>

                    <Text ml={2} fontSize="14px" fontWeight="300">
                      {x.fileName}
                    </Text>

                    <svg
                      onClick={() => {
                        if (isSending) return;
                        const temp = [...labs];

                        temp.splice(i, 1);
                        setLabs(temp);
                      }}
                      style={{
                        position: 'absolute',
                        right: -11,
                        top: -11,
                        cursor: isSending ? 'not-allowed' : 'pointer',
                      }}
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.3398 8.32205C15.4358 8.23249 15.5133 8.12488 15.5678 8.00538C15.6223 7.88587 15.6527 7.75681 15.6573 7.62555C15.6619 7.4943 15.6406 7.36342 15.5946 7.2404C15.5486 7.11737 15.4788 7.00461 15.3893 6.90855C15.2997 6.81249 15.1921 6.73501 15.0726 6.68053C14.9531 6.62605 14.824 6.59565 14.6928 6.59105C14.5615 6.58646 14.4306 6.60776 14.3076 6.65374C14.1846 6.69972 14.0718 6.76949 13.9758 6.85905L11.0498 9.58705L8.32176 6.66005C8.13923 6.47309 7.89078 6.36501 7.62956 6.35891C7.36834 6.35282 7.11512 6.44921 6.92407 6.62745C6.73302 6.8057 6.61932 7.05164 6.6073 7.31265C6.59529 7.57366 6.6859 7.829 6.85977 8.02405L9.58777 10.95L6.66077 13.6781C6.56131 13.7667 6.48057 13.8743 6.4233 13.9946C6.36602 14.1149 6.33336 14.2454 6.32723 14.3785C6.3211 14.5116 6.34163 14.6446 6.38761 14.7696C6.43359 14.8947 6.5041 15.0093 6.59498 15.1067C6.68587 15.2041 6.79531 15.2824 6.91687 15.3369C7.03843 15.3915 7.16966 15.4212 7.30286 15.4243C7.43605 15.4274 7.56853 15.4038 7.6925 15.355C7.81647 15.3062 7.92944 15.2331 8.02476 15.14L10.9508 12.413L13.6788 15.3391C13.7668 15.4403 13.8744 15.5229 13.9951 15.5817C14.1157 15.6405 14.247 15.6745 14.381 15.6815C14.5151 15.6885 14.6492 15.6685 14.7753 15.6226C14.9015 15.5767 15.0171 15.5058 15.1153 15.4143C15.2134 15.3228 15.2922 15.2124 15.3468 15.0898C15.4014 14.9672 15.4308 14.8348 15.4332 14.7006C15.4356 14.5664 15.4109 14.4331 15.3607 14.3086C15.3104 14.1841 15.2356 14.071 15.1408 13.976L12.4138 11.05L15.3398 8.32205Z"
                        fill="#354E79"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11ZM11 20C9.8181 20 8.64778 19.7672 7.55585 19.3149C6.46392 18.8626 5.47177 18.1997 4.63604 17.364C3.80031 16.5282 3.13738 15.5361 2.68508 14.4442C2.23279 13.3522 2 12.1819 2 11C2 9.8181 2.23279 8.64778 2.68508 7.55585C3.13738 6.46392 3.80031 5.47177 4.63604 4.63604C5.47177 3.80031 6.46392 3.13738 7.55585 2.68508C8.64778 2.23279 9.8181 2 11 2C13.3869 2 15.6761 2.94821 17.364 4.63604C19.0518 6.32387 20 8.61305 20 11C20 13.3869 19.0518 15.6761 17.364 17.364C15.6761 19.0518 13.3869 20 11 20Z"
                        fill="#354E79"
                      />
                    </svg>
                  </LabToUpload>
                </Flex>
              );
            })}
          </Flex>

          <EmptyState
            disabled={isSending}
            toastManager={toastManager}
            onDropAccepted={(f) => {
              fileAccepted(f, (ff) => {
                setLabs((oldArr) => [...oldArr, ff]);
                if (
                  ff &&
                  ff.mimeType &&
                  ff.mimeType
                    .toString()
                    .toLowerCase()
                    .startsWith('image/')
                ) {
                  setIsCardFrontConfirming(true);
                }
              });
            }}
            takeDesktopPhoto={() => {
              setIsCardFrontTaking(true);
            }}
          />
          {/* <Box mt={4} height="1px" />*/}
          {data.skip ? (
            <Box
              // style={{ borderTop: '1px solid rgba(0,0,0,0.3)' }}
              mt={4}
              p="12px"
              onClick={() => gotoStep(data.skip)}
            >
              <FakeLink>
                Skip
                <span style={{ color: '#F6A293' }}>{' >'}</span>
              </FakeLink>
            </Box>
          ) : null}

          <BoxButton className={labs && labs.length ? 'visible' : ''}>
            <Button
              disabled={isSending}
              variant="primary"
              style={{
                marginLeft: '12px',
                marginRight: '12px',
              }}
              width={[1, 1 / 2, 1 / 4]}
              onClick={async () => {
                setIsSending(true);
                for (let index = 0; index < labs.length; index++) {
                  const element = labs[index];
                  await client.mutate({
                    mutation: UPLOAD_LAB_RESULTS,
                    variables: { file: element.data, collectionDate: element.collectionDate },
                  });
                }
                setIsSending(false);
                onSubmit();
              }}
            >
              {isSending ? 'Uploading...' : `Continue`}
            </Button>
          </BoxButton>
        </Flex>
      ) : isCardFrontConfirming ? (
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          <PictureMiniature
            file={labs[labs.length - 1]}
            onConfirm={(collectionDate) => {
              labs[labs.length - 1].collectionDate = collectionDate;
              setIsCardFrontConfirming(false);
            }}
            onFileRemove={() => {
              const temp = [...labs];
              temp.pop();
              setLabs(temp);
              setIsCardFrontConfirming(false);
            }}
          />
        </Flex>
      ) : isPdfConfirming ? (
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          <PdfMiniature
            file={labs[labs.length - 1]}
            onConfirm={(collectionDate) => {
              labs[labs.length - 1].collectionDate = collectionDate;
              setIsPdfConfirming(false);
            }}
            onFileRemove={() => {
              const temp = [...labs];
              temp.pop();
              setLabs(temp);
              setIsPdfConfirming(false);
            }}
          />
        </Flex>
      ) : null}
      {isSending && (
        <ConfirmModal
          isOpen={true}
          childrenManaged
          onClose={() => {}}
          onBackgroundClick={() => {}}
          onEscapeKeydown={() => {}}
          onConfirm={async () => {
            
            // wizard.next();
          }}
          confirmLabel="Save"
          cancelLabel="Remove"
          confirmStyle={{
            width: '100%',
          }}
          cancelStyle={{
            width: '100%',
            marginTop: '18px',
          }}
          cancelVariant="outline"
          buttonsDirection="column"
          displayX={true}
          cardStyle={{
            maxWidth: 'calc(100vw - 40px)',
          }}
        >
          <ModalInner>
            <Flex flexDirection={'column'} p={3} alignItems={'center'} justifyContent={'center'}>
              <SpinLoader />
              <Text mt={3}>Uploading your file</Text>
              <Text mb={3}>Depending on the file it can take a while</Text>
            </Flex>
          </ModalInner>
        </ConfirmModal>
      )}
    </Fragment>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(UploadLab);
