import React from 'react';
import { Query } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import PleaseWait from '../../components/PleaseWait';
import { Flex, Heading } from 'rebass';
import gql from 'graphql-tag';

const ADMIN_GET_ATHENA_B2B_CUSTOMFIELDS = gql`
  query getAthenaB2BCustomFields {
    getAthenaB2BCustomFields {
      partners {
        optionid
        orderingid
        optionvalue
      }
      brokers {
        optionid
        orderingid
        optionvalue
      }
    }
  }
`;
const AthenaCustomFields = ({ data }) => {
  const COLUMNS = [
    {
      Header: 'option value',
      accessor: 'optionvalue',
    },
    {
      Header: 'option id',
      accessor: 'optionid',
    },
  ];

  return (
    <Query query={ADMIN_GET_ATHENA_B2B_CUSTOMFIELDS}>
      {({ data, loading, error }) => {
        if (!!loading) return <PleaseWait />;
        return (
          <>
            <div>
              <Flex flexDirection="row" style={{ justifyContent: 'space-between' }} mt={4} mb={3}>
                <Heading fontSize="20px">Athena Custom Field Brokers</Heading>
              </Flex>
              {data &&
                data.getAthenaB2BCustomFields &&
                data.getAthenaB2BCustomFields.brokers && (
                  <ReactTable
                    data={data.getAthenaB2BCustomFields.brokers}
                    columns={COLUMNS}
                    defaultPageSize={data.getAthenaB2BCustomFields.brokers && data.getAthenaB2BCustomFields.brokers.length > 3 ? Math.min(data.getAthenaB2BCustomFields.brokers.length, 25) : 3}
                    pageSize={data.getAthenaB2BCustomFields.brokers && data.getAthenaB2BCustomFields.brokers.length > 3 ? data.getAthenaB2BCustomFields.brokers.length : 3}
                    showPagination={data.getAthenaB2BCustomFields.brokers && data.getAthenaB2BCustomFields.brokers.length > 24}
                  />
                )}
            </div>

            <div>
              <Flex flexDirection="row" style={{ justifyContent: 'space-between' }} mt={4} mb={3}>
                <Heading fontSize="20px">Athena Custom Field Partners</Heading>
              </Flex>
              {data &&
                data.getAthenaB2BCustomFields &&
                data.getAthenaB2BCustomFields.partners && (
                  <ReactTable
                    data={data.getAthenaB2BCustomFields.partners}
                    columns={COLUMNS}
                    defaultPageSize={data.getAthenaB2BCustomFields.partners && data.getAthenaB2BCustomFields.partners.length > 3 ? Math.min(data.getAthenaB2BCustomFields.partners.length, 25) : 3}
                    pageSize={data.getAthenaB2BCustomFields.partners && data.getAthenaB2BCustomFields.partners.length > 3 ? data.getAthenaB2BCustomFields.partners.length : 3}
                    showPagination={data.getAthenaB2BCustomFields.partners && data.getAthenaB2BCustomFields.partners.length > 24}
                  />
                )}
            </div>
          </>
        );
      }}
    </Query>
  );
};

export default AthenaCustomFields;
