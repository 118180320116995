import React from 'react';
import { Flex, Text } from 'rebass';
import { GetComponentIcon } from '..';

const attachmentThreadDisplay = (file, key) => {
  if (file.mimeType.indexOf('image') > -1) {
    return (
      <img
        key={key}
        src={(((file._links||{}).web||{}).href || '').replace(/%20/g, '+')}
        alt="No Preview Available"
        width="200px"
      />
    );
  } else {
    return (
      <Flex
        key={key}
        flexDirection="row"
        style={{ marginBottom: '16px' }}
        onClick={() => {
          window.open((((file._links||{}).web||{}).href || '').replace(/%20/g, '+'), '_blank');
        }}
      >
        <GetComponentIcon mimeType={file.mimeType} size="6x" />
        <div>
          <Text ml="12px" fontWeight="300">
            {file.filename}
          </Text>
          <Text ml="12px" fontWeight="300">
            (click to download)
          </Text>
        </div>
      </Flex>
    );
  }
};

const attachmentToUploadDisplay = file => {
  if (file.mimeType.indexOf('image') > -1) {
    return (
      <Flex
        flexDirection="column"
        style={{
          marginBottom: '16px',
          alignItems: 'center',
          border: '1px solid rgb(221,221,221)',
          padding: '12px',
        }}
      >
        <img src={file.data} alt="No Preview Available" width="200px" />
        <div>
          <Text ml="12px" fontWeight="300">
            {file.fileName}
          </Text>
        </div>
      </Flex>
    );
  } else {
    return (
      <Flex
        flexDirection="column"
        style={{
          marginBottom: '16px',
          alignItems: 'center',
          border: '1px solid rgb(221,221,221)',
          padding: '12px',
        }}
      >
        <GetComponentIcon mimeType={file.mimeType} size="6x" />
        <div>
          <Text ml="12px" fontWeight="300">
            {file.fileName}
          </Text>
        </div>
      </Flex>
    );
  }
};
const attachmentToUploadDisplayMiniature = file => {
  if (file.mimeType.indexOf('image') > -1) {
    return (
      <img
        src={file.data}
        alt="No Preview Available"
        width="48px"
        height="48px"
        style={{ objectFit: 'fill' }}
      />
    );
  } else {
    return (
      <GetComponentIcon
        mimeType={file.mimeType}
        size="6X"
        style={{ width: '48px', height: '48px' }}
      />
    );
  }
};
const attachmentToUploadDisplayMiniatureLarge = file => {
  if (file.mimeType.indexOf('image') > -1) {
    return (
      <img
        src={file.data}
        alt="No Preview Available"
        width="62px"
        height="62px"
        style={{ objectFit: 'fill' }}
      />
    );
  } else {
    return (
      <Flex
        flexDirection="row"
        style={{
          minWidth: '200px',
          maxWidth: '600px',
          border: '1px solid #344F79',
          padding: '12px',
        }}
      >
        <GetComponentIcon
          mimeType={file.mimeType}
          size="6X"
          style={{ width: '36px', height: '36px' }}
        />
        <Flex flexDirection="column">
          <Text
            style={{
              padding: '4px 0',
              margin: '-4px 0',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '150px',
              lineClamp: 2,
            }}
          >
            {file.fileName}
          </Text>
        </Flex>
      </Flex>
    );
  }
};

const ToBeUploadedFiles = ({ files, onFileRemove }) => {
  return (
    <Flex
      justifyContent="flex-end"
      style={{ marginBottom: '24px', textAlign: 'right' }}
    >
      <div
        style={{
          background: 'white',
          border: '1px solid #344f79',
          borderRadius: '12px 12px 0 12px',
          padding: '12px',
          maxWidth: '90%',
        }}
      >
        {files.map((file, index) => {
          return (
            <div style={{ position: 'relative' }}>
              {attachmentToUploadDisplay(file)}
              <div
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onFileRemove(index);
                }}
                style={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '50%',
                  background: '#f9a394',
                  color: 'white',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  fontSize: '10px',
                  textAlign: 'center',
                  paddingTop: '3px',
                  paddingLeft: '1px',
                }}
              >
                X
              </div>
            </div>
          );
        })}
      </div>
    </Flex>
  );
};
const ToBeUploadedFilesMiniatures = ({ files, onFileRemove }) => {
  return (
    <Flex flexDirection="row" flexWrap="wrap" style={{ margin: '2px' }}>
      {files.map((file, index) => {
        return (
          <div
            style={{
              position: 'relative',
              margin: '3px',
              height: '48px',
              width: '48px',
            }}
          >
            {attachmentToUploadDisplayMiniature(file)}
            <div
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onFileRemove(index);
              }}
              style={{
                height: '20px',
                width: '20px',
                borderRadius: '50%',
                background: '#f9a394',
                color: 'white',
                position: 'absolute',
                top: 0,
                right: 0,
                fontSize: '10px',
                textAlign: 'center',
                paddingTop: '3px',
                paddingLeft: '1px',
              }}
            >
              X
            </div>
          </div>
        );
      })}
    </Flex>
  );
};
const ToBeUploadedFilesMiniaturesLarge = ({ files, onFileRemove }) => {
  return (
    <Flex
      flexDirection="row"
      flexWrap="wrap"
      style={{
        border: '1px solid rgb(221,221,221)',
        padding: '12px',
      }}
    >
      {files.map((file, index) => {
        return (
          <div
            style={{
              position: 'relative',
              margin: '3px',
              minWidth: '48px',
            }}
          >
            {attachmentToUploadDisplayMiniatureLarge(file)}
            <div
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onFileRemove(index);
              }}
              style={{
                height: '20px',
                width: '20px',
                borderRadius: '50%',
                background: '#f9a394',
                color: 'white',
                position: 'absolute',
                top: 0,
                right: 0,
                fontSize: '10px',
                textAlign: 'center',
                paddingTop: '3px',
                paddingLeft: '1px',
              }}
            >
              X
            </div>
          </div>
        );
      })}
    </Flex>
  );
};

export {
  attachmentThreadDisplay,
  attachmentToUploadDisplay,
  attachmentToUploadDisplayMiniature,
  attachmentToUploadDisplayMiniatureLarge,
  ToBeUploadedFiles,
  ToBeUploadedFilesMiniatures,
  ToBeUploadedFilesMiniaturesLarge,
};
