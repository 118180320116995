import React, { useState } from 'react';
import { Text, Flex, Box } from 'rebass';
import moment from 'moment';
import styled from 'styled-components';
import SectionDetails from './SectionDetails';

const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 12px 20px 20px;
  border-bottom: 1px solid #d1d1d1;
`;
const Wrapper = styled(Flex)`
  flex: 1;
  flex-direction: row;
  cursor: pointer;
`;

const ContentWrapper = styled(Flex)`
  flex: 1;
  flex-direction: column;
  background-color: white;
  margin: 6px 12px;
`;

const TitleRow = styled(Flex)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: baseline;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 22px;
`;

const PriceText = styled(Text)`
  font-weight: 600;
  color: #f6a293;
`;

const CancelsBadge = styled(Box)`
  background-color: #e3e8ee;
  padding: 1px 6px;
  border-radius: 4px;
    display: flex;
    align-items: center;
`;

export const Section = ({
  imageSource,
  title,
  description,
  priceText,
  data,
  type,
  subscribe,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  return (
    <Container
      onClick={() => {
        if (!data) {
          subscribe();
        }
      }}
    >
      <Wrapper onClick={() => setIsExpanded(!isExpanded)}>
        <img
          style={{ height: '68px', width: '78px' }}
          height="68px"
          width="78px"
          src={imageSource}
          alt=""
        />
        <ContentWrapper>
          <Flex flexDirection="row">
            <TitleRow>
              <Title>{title}</Title>
              {(!!data && !!data.cancels && !!data.cancels.cancelsAt) ? (
                <CancelsBadge>
                  <svg
                    aria-hidden="true"
                    height="12"
                    width="12"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm1-8.577V4a1 1 0 1 0-2 0v4a1 1 0 0 0 .517.876l2.581 1.49a1 1 0 0 0 1-1.732z"
                      fill-rule="evenodd"
                      fill="#344f79"
                    />
                  </svg>
                  <Text color='#344f79' ml='6px' fontSize='12px'>{`Cancels ${moment
      .unix(data.cancels.cancelsAt)
      .format('MMM D, YYYY')}`}</Text>
                </CancelsBadge>
              ) : (
                <PriceText style={{ cursor: !data ? 'pointer' : '' }}>
                  {!!data ? priceText : `Learn more`}
                </PriceText>
              )}
            </TitleRow>
            <PriceText
              style={{
                marginLeft: '8px',
                fontSize: '28px',
                alignSelf: 'end',
                transform: `rotate(${isExpanded ? '-90' : '90'}deg)`,
              }}
            >
              {!!data ? '>' : ``}
            </PriceText>
          </Flex>
          <Text style={{ fontWeight: 300 }}>{description}</Text>
        </ContentWrapper>
      </Wrapper>
      {isExpanded && <SectionDetails data={data} type={type} />}
    </Container>
  );
};
