import html3pdf from 'html3pdf';
// import './App.css';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Flex, Text } from 'rebass';
import JsBarcode from 'jsbarcode';
import PoweredBy from '../../static/poweredBy.png';
import { Query } from 'react-apollo';
import { GET_LAB_ORDER } from '../../graphql';
import SpinLoader from '../../components/SpinLoader';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

const Content = ({ encounterId, patient, palomaAccount, order, diagnosis, clinicalLab, onClose }) => {
  const [isReady, setIsReady] = useState(false);
  const signature = order.signature;
  // 'https://v5.airtableusercontent.com/v3/u/25/25/1706832000000/-Kw1e2eA_Tf71pD4umR-cg/Z09CRJDcL8ceYZkB6Mo58akDwVFBaH1V_o_wbdcJ6t67LisNZ8QmFf4MvOo6zT7NIrwY0AmykkOBw1U7ZxNA-0RO0FJ_k_EqI8GtLmvDs96K0AuWlX7lx4HWwH49TqKkQHtgRoff5XYij01kUNLWTg/qO8f_SpXbMdRiCtWGlyq4GuyjuQo-j0V0_NEUixi9Ag';
  // 'https://v5.airtableusercontent.com/v3/u/25/25/1706817600000/jK91kh2GtRBV8z_m0DFJCQ/g10IHojmfbnCU6gxx77GJpgOEVLH_T8BL6qA_QPxVCv223WkbLASALqC7T-Y1dHdIqROBcuiFZMsP8p1kZR-jM5f1CVOx6-RoTjBhIb0P2CZ8j5OOXCGzMUAdtkOc2OQ_G0L8NBxlm8EK7MBXwUnQA/OQUfJnbj1q-J4jsDwRmojkUFmcCGTtvMvxuJM2OAAvY';

  useEffect(() => {
    if (clinicalLab && clinicalLab.clinicalprovidername && clinicalLab.clinicalprovidername.toLowerCase().indexOf('quest') > -1) {
      JsBarcode(`#barcode${encounterId}`, `${palomaAccount.accountNumber}-${order.requisitionNumber}`, {
        format: 'CODE39',
        width: 1,
        height: 60,
        textAlign: 'left',
        fontSize: '12px',
        fontOptions: 'bold',
      });
    }

    const insertSignature = async () => {
      const img = await fetch(signature);
      const imageBlob = await img.blob();
      const blobUrl = URL.createObjectURL(imageBlob);
      const el = document.getElementById(`sigImg${encounterId}`);
      el.src = blobUrl;
      setIsReady(true);
    };

    insertSignature();
  }, []);

  useEffect(
    () => {
      if (!!isReady) {
        viewOrder();
      }
    },
    [isReady]
  );

  const downloadOrder = () => {
    const el = document.getElementById(`testing${encounterId}`);
    const clone = el.cloneNode(true);
    clone.style.display = 'flex';

    html3pdf()
      .set({
        margin: 4,
      })
      .from(clone)
      .save(`${patient.firstName[0]}_${patient.lastName}_-_${clinicalLab.clinicalprovidername}.pdf`);
  };

  const viewOrder = async () => {
    const el = document.getElementById(`testing${encounterId}`);
    const clone = el.cloneNode(true);
    clone.style.display = 'flex';
    let pdf;
    if (!!isMobile) {
      pdf = await html3pdf()
        .set({
          margin: 4,
          pagebreak: {
            mode: ['avoid-all', 'css', 'legacy'],
          },
        })
        .from(clone)
        .toPdf()
        .get('pdf')
        .then(function(pdf) {
          var totalPages = pdf.internal.getNumberOfPages();
          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(9);
            pdf.setTextColor(100);
            pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() / 2.3, pdf.internal.pageSize.getHeight() - 0.8);
          }
        })
        .outputPdf()
        .save();
    } else {
      pdf = await html3pdf()
        .set({
          margin: 4,
          pagebreak: {
            mode: ['avoid-all', 'css', 'legacy'],
          },
        })
        .from(clone)
        .toPdf()
        .get('pdf')
        .then(function(pdf) {
          var totalPages = pdf.internal.getNumberOfPages();
          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(9);
            pdf.setTextColor(100);
            pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() / 2.3, pdf.internal.pageSize.getHeight() - 0.8);
          }
        })
        .outputPdf(); 
    }
    // const ifr = document.getElementById('pdfIframe');
    // ifr.src = `data:application/pdf;base64,${btoa(pdf)}`;
    // ifr.style.display = 'block';

    var byteCharacters = pdf;
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var fileURL = URL.createObjectURL(file);
    onClose();
    window.open(fileURL, '_blank');
  };

  return (
    <>
      <div style={{ fontSize: '13px', display: 'none' }}>
        <header>
          <Flex>
            <button onClick={() => downloadOrder()} disabled={!isReady}>
              download
            </button>
            <button onClick={() => viewOrder()} disabled={!isReady}>
              view
            </button>
          </Flex>

          <Flex id={`testing${encounterId}`} style={{ display: 'flex', flexDirection: 'column', width: '100%', display: 'none' }}>
            <Text style={{ width: '100%', borderBottom: '2px solid #999a66', textAlign: 'left', letterSpacing: '2px', fontSize: '12px', color: '#999a66' }}>{palomaAccount.header}</Text>
            {/* style={{display: 'none'}}> */}
            <Flex style={{ display: 'flex' }}>
              <Text style={{ fontWeight: 600, flex: 1, textAlign: 'left' }}>
                {patient.lastName.toUpperCase()}, {patient.firstName} (id #{patient.athenaId}, dob: {patient.dob})
              </Text>
              <img src={PoweredBy} style={{ height: '20px' }} />
            </Flex>
            <Flex alignItems={'center'}>
              <svg id={`barcode${encounterId}`} />
              <Text style={{ flex: 1, textAlign: 'right', fontWeight: 600, alignItems: 'center', fontSize: '24px' }}>
                {clinicalLab.clinicalprovidername === 'Quest Diagnostics PSC' ? 'Quest Diagnostics Incorporated' : clinicalLab.clinicalprovidername}
              </Text>
            </Flex>
            <Flex style={{ marginTop: '24px' }}>
              <Flex flexDirection="column" flex={1}>
                <Text style={{ fontWeight: 'bold', fontSize: '13px' }}>Account #: {palomaAccount.accountNumber}</Text>
                <Text style={{ fontSize: '13px' }}>Account Name: {palomaAccount.accountName}</Text>
                <Text style={{ fontSize: '13px' }}>Address: {palomaAccount.address}</Text>
                <Text style={{ fontSize: '13px' }}>
                  City, State Zip: {palomaAccount.city.toUpperCase()}, {palomaAccount.state.toUpperCase()} {palomaAccount.zip.toUpperCase()}
                </Text>
                <Text style={{ fontSize: '13px' }}>Phone: {palomaAccount.phone}</Text>
                <Text style={{ fontSize: '13px' }}>Fax: {palomaAccount.fax}</Text>
              </Flex>
              <Flex alignItems={'center'} justifyContent={'center'} style={{ margin: '0 24px', fontSize: '13px' }}>
                For lab use only
              </Flex>
              <table style={{ border: '1px solid black' }}>
                <th style={{ border: '1px solid black', textAlign: 'left', fontSize: '13px' }}>Patient Information</th>
                <tr style={{ border: '1px solid black' }}>
                  <td style={{ border: '1px solid black', fontSize: '13px' }}>
                    {patient.lastName.toUpperCase()}, {patient.firstName.toUpperCase()}
                    <br />
                    {patient.email}
                    <br />
                    {patient.address.street1.toUpperCase()}
                    <br />
                    {patient.address.street2 && (
                      <>
                        {patient.address.street2.toUpperCase()}
                        <br />
                      </>
                    )}
                    {patient.address.city.toUpperCase()}, {patient.address.state.toUpperCase()} {patient.address.zip}
                    <br />
                    {patient.phone}
                    <br />
                  </td>
                </tr>
              </table>
            </Flex>

            <Text style={{ marginTop: '24px', fontSize: '13px' }}>
              To {clinicalLab.clinicalprovidername.toUpperCase()} {clinicalLab.address}, {clinicalLab.city} {clinicalLab.state} {clinicalLab.zip}
            </Text>
            <table style={{ border: '1px solid black', width: '100%' }}>
              <tr>
                <th style={{ border: '1px solid black', textAlign: 'left', fontWeight: 400, fontSize: '13px' }}>
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '13px' }}>Requisition #:</span> {order.requisitionNumber}
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '13px' }}>Collection Date: Time:</span>
                  </div>
                </th>
                <th style={{ border: '1px solid black', textAlign: 'left', fontWeight: 400, fontSize: '13px' }}>
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '13px' }}>Pat ID:</span> {patient.athenaId}
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold', fontSize: '13px' }}>DOB:</span> {patient.dob} <span style={{ fontWeight: 'bold' }}>Sex:</span> {patient.sex}
                  </div>
                </th>
              </tr>
              <tr style={{ border: '1px solid black' }}>
                <td style={{ border: '1px solid black', fontSize: '13px', verticalAlign: 'top' }}>
                  <span style={{ fontWeight: 'bold' }}>Ordering Provider:</span> {order.orderingProvider}
                  <br />
                  <span style={{ fontWeight: 'bold' }}>NPI:</span> {order.npi}
                  <br />
                  <br />
                  <span style={{ fontWeight: 'bold' }}>Responsible Party:</span> {patient.lastName.toUpperCase()}, {patient.firstName.toUpperCase()}
                  <br />
                  <span style={{ fontWeight: 'bold' }}>Phone:</span> {patient.phone}
                  <br />
                  {patient.address.street1.toUpperCase()}
                  <br />
                  {patient.address.street2 && (
                    <>
                      {patient.address.street2.toUpperCase()}
                      <br />
                    </>
                  )}
                  {patient.address.city.toUpperCase()}, {patient.address.state.toUpperCase()} {patient.address.zip}
                  <br />
                </td>
                <td style={{ border: '1px solid black', fontSize: '13px', verticalAlign: 'top' }}>
                  <span style={{ fontWeight: 'bold' }}>Bill Type:</span> Third Party
                  <br />
                  <br />
                  {/* <span style={{ fontWeight: 'bold' }}>Primary Insurance:</span>
                <br />
                <span style={{ fontWeight: 'bold' }}>Insurance #:</span>
                <br />
                <span style={{ fontWeight: 'bold' }}>Group: Relation: DOB:</span>
                <br />
                <span style={{ fontWeight: 'bold' }}>Address:</span> , , , ,
                <br />
                <br />
                <span style={{ fontWeight: 'bold' }}>Secondary Insurance:</span>
                <br />
                <span style={{ fontWeight: 'bold' }}>Insurance #:</span>
                <br />
                <span style={{ fontWeight: 'bold' }}>Group: Relation: DOB:</span>
                <br />
                <span style={{ fontWeight: 'bold' }}>Address:</span> , , , ,
                <br /> */}
                </td>
              </tr>
            </table>

            <Flex style={{ pageBreakInside: 'avoid', flexDirection: 'column' }}>
              <Text style={{ opacity: 0.5, textAlign: 'center', fontWeight: 700, marginTop: '24px' }}>Profiles/Tests</Text>
              <Text style={{ marginTop: '24px', fontSize: '13px' }}>Order generated: {new Date().toLocaleString()}</Text>
              <br />
              <Text style={{ fontSize: '13px' }}>Orders included: {diagnosis.reduce((accumulator, currentValue) => accumulator + currentValue.orders.length, 0)}</Text>
              {diagnosis.map((x, i) => {
                return (
                  <Fragment key={`diagnosis_${i}`}>
                    <br />
                    <Text style={{ fontSize: '13px' }}>
                      <span style={{ fontWeight: 'bold' }}>{x.diagnosis}</span>{' '}
                      {`| ${x.diagnosisicd.codeset}: ${x.diagnosisicd.code}
                  : ${x.diagnosisicd.description}`}
                    </Text>
                    <ul>
                      {x.orders.map((xx) => {
                        return (
                          <li style={{ fontSize: '13px' }} key={`facCode_${i}_${xx.code}`}>
                            <span style={{ fontWeight: 'bold' }}>
                              {xx.code} | {xx.description}
                            </span>{' '}
                            | Bill: Third Party
                          </li>
                        );
                      })}
                    </ul>
                  </Fragment>
                );
              })}
              <br />
              <Text style={{ fontSize: '13px' }}>{order.signedOn ? moment(order.signedOn).format('MM/DD/YYYY') : ''}</Text>
              <br />
              <Text style={{ fontSize: '13px', textAlign: 'center' }}>Electronically Signed by: {order.orderingProvider}</Text>
              <br />
              <img style={{ maxWidth: '250px', alignSelf: 'center' }} src={signature} id={`sigImg${encounterId}`} />
              <Text style={{ fontSize: '13px', textAlign: 'center' }}>{order.signedOn}</Text>
              <br />
              <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '12px' }}>End of Requisition</Text>
            </Flex>
            <br />
            <br />
          </Flex>
        </header>

        {/* <iframe id="pdfIframe" style={{ width: '100%', height: '600px', display: 'none' }}></iframe> */}
      </div>
      <Flex flexDirection={'column'} style={{ height: '100%', width: '100%' }} alignItems={'center'} justifyContent={'center'}>
        <Text mt={3}>You can close this view now</Text>
        <Button variant={'primary'} onClick={onClose} mt={4}>
          Close
        </Button>
      </Flex>
      <Flex width={'100%'} height="100%" flexDirection={'column'} flex={1}>
        <Flex justifyContent={'center'} alignItems="center" width={'calc(100% + 24px)'} marginLeft="-24px" padding={1} backgroundColor="#acacac" flex={1}>
          <embed type={'application/pdf'} id="pdfIframe" style={{ width: '100%', height: '100%' }} />
        </Flex>
      </Flex>
    </>
  );
};

export const LabOrderHiddenForm = ({ encounterId, onClose }) => {
  return (
    <Query
      query={GET_LAB_ORDER}
      variables={{
        encounterId,
      }}
    >
      {({ loading, data }) => {
        if (!!loading) {
          return (
            <Flex flexDirection={'column'} style={{ height: '100%', width: '100%' }} alignItems={'center'} justifyContent={'center'}>
              <SpinLoader />
              <Text mt={3}>Your lab order is being generated</Text>
              <Text mb={4}>Please Wait</Text>
              <Button variant={'primary'} onClick={onClose} mt={4}>
                Close
              </Button>
            </Flex>
          );
        }

        return (
          <Flex flexDirection={'column'} style={{ height: '100%', width: '100%' }}>
            <Content
              patient={data.getLabOrder.patient}
              palomaAccount={data.getLabOrder.palomaAccount}
              diagnosis={data.getLabOrder.diagnosis}
              clinicalLab={data.getLabOrder.clinicalLab}
              order={data.getLabOrder.order}
              encounterId={encounterId}
              onClose={onClose}
            />
            <Button variant={'primary'} style={{ alignSelf: 'center' }} onClick={onClose} mt={2} mb={3}>
              Close
            </Button>
          </Flex>
        );
      }}
    </Query>
  );
};
