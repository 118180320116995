import React, { Component, Fragment } from 'react';
import { Flex, Box, Button, Heading, Card, Text } from 'rebass';
import { withWizard } from 'react-albus';
import styled, { css } from 'styled-components';
import { Formik, Form } from 'formik';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import ScrollAnimation from 'react-animate-on-scroll';
import { fadeIn, fadeInDown } from '../../utils/animationsStyleBuilder';
export const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1 0 auto;
  background: ${(props) => (props.disabled ? props.theme.bg.wash : props.theme.bg.default)};
  font-weight: 400;
  font-size: 16px;
  border: 1px solid ${(props) => (props.disabled ? props.theme.bg.border : props.theme.bg.inactive)};
  border-radius: 10px;
  padding: 18px 12px;
  box-shadow: none;
  margin-bottom: 18px;
  text-align: center;
  ${(props) =>
    props.type === 'checkbox' &&
    css`
      flex: initial;
      width: initial;
      margin-right: 0.5em;
    `} &::placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:-moz-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:-ms-input-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:focus {
    border-color: ${(props) => props.theme.brand.default};
    outline: none;
  }
  &[type='file'] {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: hidden;
  }
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
`;
const Input = (props) => {
  return (
    <Flex flex={1}>
      <StyledInput {...props} onKeyDown={({ key }) => (key === 'Enter' ? props.submitForm() : null)} />
    </Flex>
  );
};
class AlcoholPick extends Component {
  state = {
    display: false,
    selected: [],
    active: undefined,
    tempValues: {},
  };

  constructor(props) {
    super(props);

    if (props.data && props.data.skipIfNotQuestionId) {
      let isFound = false;
      for (let index = 0; index < props.userData.length; index++) {
        const element = props.userData[index];
        for (let index = 0; index < element.values.length; index++) {
          const val = element.values[index];
          try {
            if (val && val.questionId && val.questionId === props.data.skipIfNotQuestionId) {
              isFound = true;
              break;
            }
          } catch (error) {}
        }
      }
      if (!isFound) {
        props.wizard.next();
      }
    }
  }

  handleChosen = (item) => {
    this.setState({
      active: item,
    });
  };

  formatSubmit = (values) => {
    const { data } = this.props;
    const { active } = this.state;
    let payload = [];
    // console.log('Data', data)
    const questionId = data.questionId;
    payload.push({ questionId, answer: active.value });

    return payload;
  };

  shiftView = () => {
    setTimeout(() => {
      window.scrollTo(0, this.inputRef.offsetTop);
    }, 150);
  };

  render() {
    const { data, onSubmit, wizard } = this.props;
    const { selected, loading, active } = this.state;
    // console.log('selected', selected);

    return (
      <Card p={3} mb={5} mx={3} style={{ zIndex: 1 }}>
        <Fragment>
          <Flex flex={1} flexDirection="column">
            <Box m={2} style={{ ...fadeInDown() }}>
              <Text fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
                {data.prompt}
              </Text>
            </Box>

            {/* <ScrollAnimation animateIn="fadeIn" delay={100} animateOnce={false}> */}
            <Formik
              initialValues={{ [data.id]: '' }}
              validationSchema={data.schema || ''}
              onSubmit={(values, actions) => {
                const formatted = this.formatSubmit(selected);

                onSubmit(
                  {
                    values: formatted,
                    endpoint: data.endpoint,
                  },
                  actions,
                  wizard,
                  data
                );
              }}
              render={({ isValid, errors, touched, submitForm }) => (
                <Form style={{ ...fadeIn('1.1s', '150ms') }}>
                  <Flex flex={1} flexDirection="column">
                    <Flex my={3} flexWrap="wrap" justifyContent="center">
                      <div
                        style={{
                          maxWidth: '500px',
                          alignSelf: 'center',
                          width: '100%',
                          margin: '0 auto',
                        }}
                      >
                        {/* <Text
                          mt={3}
                          mb="24px"
                          color="hsl(0,0%,50%)"
                          textAlign="center"
                        >
                          {data.prompt}
                        </Text> */}

                        <div style={{ width: '100%', marginBottom: '24px' }} />
                        <Input
                          placeholder="How many servings"
                          name="servings"
                          value={active}
                          submitForm={() => {}}
                          onChange={(val) => {
                            this.setState({
                              active: val.target.value,
                            });
                          }}
                        />
                        <Text mt={3} mb="24px" color="hsl(0,0%,50%)" textAlign="left">
                          A serving contains roughly 14 grams of pure alcohol, which is found in:
                        </Text>
                        <Text mt={2} mb="24px" color="hsl(0,0%,50%)" textAlign="left">
                          <li>12 ounces of regular beer, which is usually about 5% alcohol</li>
                          <li>5 ounces of wine, which is typically about 12% alcohol</li>
                          <li>1.5 ounces of distilled spirits, which is about 40% alcohol</li>
                        </Text>
                      </div>
                    </Flex>

                    <div
                      style={{
                        height: '1px',
                        backgroundColor: '#ccc',
                        margin: '40px -16px 20px',
                      }}
                    />

                    <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column" textAlign="center">
                      {!active ? (
                        <Text textAlign="center" mb={1} fontSize="18px" style={{ cursor: 'pointer' }} onClick={() => wizard.push(data.skip)}>
                          <span style={{ color: '#364f79' }}>Skip</span>
                          <span style={{ color: '#f9a394', fontWeight: '600' }}>{' >'}</span>
                        </Text>
                      ) : (
                        <Button disabled={loading} variant="pink" width={[1 / 2, 1 / 3]} type="submit">
                          {loading ? 'Processing' : 'Next'}
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                </Form>
              )}
            />
            {/* </ScrollAnimation> */}
          </Flex>
        </Fragment>
      </Card>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(AlcoholPick);
// export default withWizard(Single);
