import React, { Component, Fragment } from 'react';
import { Flex, Box, Button, Heading, Card, Text } from 'rebass';
import { withWizard } from 'react-albus';
import styled, { css } from 'styled-components';
import { debounce } from 'throttle-debounce';
import { Formik, Form } from 'formik';

import { TextInput } from '../../../../components/TextInput';
import { FormField } from '../../../../components/FormField';
import Select, { components } from 'react-select';
import {
  SEARCH_PCPS,
  SET_PCP,
  GET_USER_PCP,
} from '../../../../graphql';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import RegionSelect from '../../../RegionSelect';
import { MaskInput } from '../../../MaskInput';

const blankPcpObject = {
  practice: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  county: '',
  phone: '',
  fax: '',
  firstname: '',
  lastname: '',
  fullname: '',
  email: '',
  gender: '',
  title: '',
  specialty: '',
  sicCode: '',
  locationAddress: '',
  specialty2: '',
  specialty3: '',
  specialty4: '',
  specialty5: '',
  npi: '',
  internalId: '',
};

export const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1 0 auto;
  background: ${props =>
    props.disabled ? props.theme.bg.wash : props.theme.bg.default};
  font-weight: 400;
  font-size: 16px;
  border: 1px solid
    ${props =>
      props.disabled ? props.theme.bg.border : props.theme.bg.inactive};
  border-radius: 10px;
  padding: 18px 12px;
  box-shadow: none;
  margin-bottom: 18px;
  ${props =>
    props.type === 'checkbox' &&
    css`
      flex: initial;
      width: initial;
      margin-right: 0.5em;
    `} &::placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &::-webkit-input-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:-moz-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:-ms-input-placeholder {
    color: ${props => props.theme.text.placeholder};
  }
  &:focus {
    border-color: ${props => props.theme.brand.default};
    outline: none;
  }
  &[type='file'] {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: hidden;
  }
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
`;

class PcpPickForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      selected: [],
      active: undefined,
      tempValues: {},
      results: [],
      dataset: [],
      filter: '',
      selectedReal: this.props.currentPcp || {},
      isUntouched: true,
    };

    if (props.data && props.data.skipIfNotQuestionId) {
      let isFound = false;
      for (let index = 0; index < props.userData.length; index++) {
        const element = props.userData[index];
        for (let index = 0; index < element.values.length; index++) {
          const val = element.values[index];
          if (
            val.questionId &&
            val.questionId === props.data.skipIfNotQuestionId
          ) {
            isFound = true;
            break;
          }
        }
      }
      if (!isFound) {
        props.wizard.next();
      }
    }

    this.autocompleteSearchThrottled = debounce(500, this.findPcp);
  }

  findPcp = async () => {
    const { filter } = this.state;
    if (!filter) {
      this.setState({
        loadingOptions: false,
        results: [],
      });
      return;
    }
    if (filter.length < 3) return;
    const res = await this.props.client.query({
      query: SEARCH_PCPS,
      variables: {
        filter: filter.trim(),
      },
    });
    const results = [];
    const dataset = [];
    if (res && res.data && res.data.findPcp) {
      for (let index = 0; index < res.data.findPcp.length; index++) {
        const element = res.data.findPcp[index];
        results.push({
          value: element.id,
          label: element.fullname,
          address: `${element.city}, ${element.state}`,
        });
        dataset.push(element);
      }
    }

    this.setState({
      results,
      loadingOptions: false,
      dataset,
    });
  };

  confirmModalForm = props => {
    const { selected, active, tempValues } = this.state;
    const current = JSON.parse(JSON.stringify(active));
    current.dose = tempValues.dose;
    current.frequency = tempValues.frequency;

    const sel = selected.filter(x => x.value !== active.value);
    sel.push(current);

    this.setState({ active: undefined, selected: sel });
  };

  submitPcp = async (formatted, setSubmitting) => {
    const { onSaved } = this.props;
    try {
      await this.props.client.mutate({
        mutation: SET_PCP,
        refetchQueries: [{ query: GET_USER_PCP }],
        awaitRefetchQueries: true,
        variables: {
          ...formatted,
        },
      });
      setSubmitting(false);
      onSaved();
    } catch (error) {
      setSubmitting(false);
      console.log('ERROR;', error);
    }
  };

  formatSubmit = (values, selected) => {
    try {
      if (selected && selected.internalId) {
        if (
          (values.firstname || '').toString().toUpperCase() ===
            (selected.firstname || '').toString().toUpperCase() &&
          (values.lastname || '').toString().toUpperCase() ===
            (selected.lastname || '').toString().toUpperCase() &&
          (values.city || '').toString().toUpperCase() ===
            (selected.city || '').toString().toUpperCase() &&
          (values.state || '').toString().toUpperCase() ===
            (selected.state || '').toString().toUpperCase() &&
          (values.practice || '').toString().toUpperCase() ===
            (selected.practice || '').toString().toUpperCase() &&
          (values.phone || '').toString().toUpperCase() ===
            (selected.phone || '').toString().toUpperCase() &&
          (values.fax || '').toString().toUpperCase() ===
            (selected.fax || '').toString().toUpperCase() &&
          (values.email || '').toString().toUpperCase() ===
            (selected.email || '').toString().toUpperCase()
        ) {
          return selected;
        }
      }

      return {
        firstname: (values.firstname || '').toString(),
        lastname: (values.lastname || '').toString(),
        city: (values.city || '').toString(),
        state: (values.state || '').toString(),
        practice: (values.practice || '').toString(),
        phone: (values.phone || '').toString(),
        fax: (values.fax || '').toString(),
        email: (values.email || '').toString(),
      };
    } catch (error) {
      console.log('Format Submit error', error);
    }
  };

  shiftView = () => {
    setTimeout(() => {
      window.scrollTo(0, this.inputRef.offsetTop);
    }, 150);
  };

  Menu = props => {
    return (
      <components.Menu {...props}>
        {props.children}
        {props.options.length > 99 && (
          <div
            style={{
              padding: '6px 12px',
              opacity: 0.65,
              fontSize: '14px',
              textAlign: 'center',
              borderTop: '1px solid #344f79',
            }}
          >
            There are more than 100 matches. Please refine your search.
          </div>
        )}
      </components.Menu>
    );
  };

  CustomOption = props => {
    return (
      <components.Option {...props}>
        <div style={{ padding: '6px 12px' }}>
          <Text fontSize="16px">{props.label}</Text>
          <Text fontSize="14px" fontWeight="300" color="#696969">
            {props.data.address}
          </Text>
        </div>
      </components.Option>
    );
  };
  filterOptions = (candidate, input) => {
    return true;
  };

  render() {
    const { currentPcp, onCanceled } = this.props;
    const {
      loading,
      results,
      dataset,
      selectedReal,
      loadingOptions,
      filter,
      isUntouched,
    } = this.state;

    return (
      <Card p={3} mb={5} mx={3}>
        <Fragment>
          <Flex flex={1} flexDirection="column">
            <Box m={2}>
              <Heading color="#344f77" textAlign="center">
                {currentPcp
                  ? 'Change Primary Care Provider'
                  : 'Add a Primary Care Provider'}
              </Heading>
            </Box>
            <Formik
              initialValues={selectedReal}
              validate={values => {
                if (isUntouched) {
                  this.setState({ isUntouched: false });
                }
              }}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                const formatted = this.formatSubmit(values, selectedReal);
                this.submitPcp(formatted, actions.setSubmitting);
              }}
              render={({
                touched,
                errors,
                isValid,
                handleChange,
                handleBlur,
                isSubmitting,
                values,
                setErrors,
              }) => {
                return (
                  <Form>
                    <Flex flex={1} flexDirection="column">
                      <Flex my={3} flexWrap="wrap" justifyContent="center">
                        <div
                          style={{
                            maxWidth: '500px',
                            alignSelf: 'center',
                            width: '100%',
                            margin: '0 auto',
                          }}
                        >
                          <Text fontSize="18px" mt={3} mb="24px">
                            Search By Name
                          </Text>
                          <Select
                            isClearable={true}
                            components={{
                              Option: this.CustomOption,
                              DropdownIndicator: () => null,
                              Menu: this.Menu,
                            }}
                            isLoading={
                              filter && filter.length > 2 && loadingOptions
                            }
                            style={{ width: '100%' }}
                            classNamePrefix="region-select"
                            options={results}
                            isMulti={false}
                            placeholder="Type to search for PCP"
                            inputValue={this.state.filter}
                            filterOption={this.filterOptions}
                            onInputChange={v => {
                              this.setState(
                                {
                                  filter: v,
                                  loadingOptions: true,
                                },
                                () => {
                                  this.findPcp(v.trim());
                                }
                              );
                            }}
                            onChange={option => {
                              const sel = option;
                              
                              this.setState({
                                isUntouched: false,
                                active: option || {},
                                selected: sel,
                                tempValues: {},
                                selectedReal: sel
                                  ? dataset.find(x => x.id === sel.value)
                                  : {
                                      ...blankPcpObject,
                                    },
                              });
                            }}
                            noOptionsMessage={({ inputValue }) => {
                              if (!inputValue.trim()) return null;
                              if (inputValue.length < 3) {
                                return (
                                  <div style={{ padding: '12px 24px' }}>
                                    <Text
                                      textAlign="center"
                                      mb={1}
                                      fontSize="18px"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <span style={{ color: '#364f79' }}>
                                        Please enter at least 3 characters
                                      </span>
                                    </Text>
                                  </div>
                                );
                              }
                              if (loadingOptions) {
                                return (
                                  <div style={{ padding: '12px 24px' }}>
                                    <Text
                                      textAlign="center"
                                      mb={1}
                                      fontSize="18px"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <span
                                        style={{
                                          color: '#364f79',
                                          opacity: 0.65,
                                        }}
                                      >
                                        Loading
                                      </span>
                                    </Text>
                                  </div>
                                );
                              }
                              return (
                                <div style={{ padding: '12px 24px' }}>
                                  <Text
                                    textAlign="center"
                                    mb={1}
                                    fontSize="18px"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <span style={{ color: '#364f79' }}>
                                      No Provider Found
                                    </span>
                                  </Text>
                                </div>
                              );
                            }}
                            isSearchable={true}
                          />
                          <div
                            style={{
                              height: '1px',
                              backgroundColor: '#ccc',
                              margin: '24px -16px 36px',
                            }}
                          />
                          <Text fontSize="18px" mt={3} mb="24px">
                            Your Provider's Information
                          </Text>
                          <Flex flexDirection={['column', 'column', 'row']}>
                            <FormField
                              flex={[1, 1, 1 / 2]}
                              mb={3}
                              mr={[0, 0, 3]}
                              error={
                                touched.firstname && !values.firstname
                                  ? 'Required'
                                  : null
                              }
                            >
                              <TextInput
                                placeholder="First Name"
                                type="text"
                                name="firstname"
                                id="input_firstname"
                              />
                            </FormField>
                            <FormField
                              flex={[1, 1, 1 / 2]}
                              mb={3}
                              error={
                                touched.lastname && !values.lastname
                                  ? 'Required'
                                  : null
                              }
                            >
                              <TextInput
                                placeholder="Last Name"
                                type="text"
                                name="lastname"
                                id="input_lastname"
                              />
                            </FormField>
                          </Flex>
                          <Flex flexDirection={['column', 'column', 'row']}>
                            <FormField
                              flex={[1, 1, 1 / 2]}
                              mb={3}
                              mr={[0, 0, 3]}
                              error={
                                touched.city && errors.city ? errors.city : null
                              }
                            >
                              <TextInput
                                placeholder="City"
                                type="text"
                                name="city"
                                id="input_city"
                              />
                            </FormField>

                            <FormField
                              flex={[1, 1, 1 / 2]}
                              mb={3}
                              error={
                                touched.state && errors.state
                                  ? errors.state
                                  : null
                              }
                            >
                              <RegionSelect
                                name="state"
                                value={values.state}
                                id="select_state"
                              />
                            </FormField>
                          </Flex>
                          <Flex flexDirection={['column', 'column', 'row']}>
                            <FormField
                              flex={[1, 1, 1 / 2]}
                              mb={3}
                              mr={[0, 0, 3]}
                              error={
                                touched.practice && errors.practice
                                  ? errors.practice
                                  : null
                              }
                            >
                              <TextInput
                                placeholder="Practice Name"
                                type="text"
                                name="practice"
                                id="input_practicename"
                              />
                            </FormField>
                            <FormField
                              flex={[1, 1, 1 / 2]}
                              mb={3}
                              error={
                                touched.phone
                                  ? !values.phone
                                    ? 'Required'
                                    : values.phone.indexOf('_') > -1
                                    ? 'Invalid'
                                    : null
                                  : null
                              }
                            >
                              <MaskInput
                                name="phone"
                                placeholder="Phone Number"
                                mask="999-999-9999"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="input_phone"
                              />
                            </FormField>
                          </Flex>
                          <Flex flexDirection={['column', 'column', 'row']}>
                            <FormField
                              flex={[1, 1, 1 / 2]}
                              mb={3}
                              mr={[0, 0, 3]}
                              error={
                                touched.email && errors.email
                                  ? errors.email
                                  : null
                              }
                            >
                              <TextInput
                                placeholder="Email"
                                type="text"
                                name="email"
                                id="input_email"
                              />
                            </FormField>
                            <FormField
                              flex={[1, 1, 1 / 2]}
                              mb={3}
                              error={
                                touched.fax && errors.fax ? errors.fax : null
                              }
                            >
                              <MaskInput
                                name="fax"
                                placeholder="Fax"
                                mask="999-999-9999"
                                value={values.fax}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="input_fax"
                              />
                            </FormField>
                          </Flex>
                        </div>
                      </Flex>

                      <div
                        style={{
                          height: '1px',
                          backgroundColor: '#ccc',
                          margin: '40px -16px 20px',
                        }}
                      />

                      <Flex
                        flex={1}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                      >
                        {!currentPcp ? (
                          !values.firstname ||
                          !values.lastname ||
                          (!values.phone || values.phone.indexOf('_') > -1) ? (
                            <Text
                              textAlign="center"
                              mb={1}
                              fontSize="18px"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                onCanceled();
                              }}
                            >
                              <span
                                style={{ color: '#f9a394', fontWeight: '600' }}
                              >
                                {'< '}
                              </span>
                              <span style={{ color: '#364f79' }}>Cancel</span>
                            </Text>
                          ) : (
                            <Button
                              disabled={loading || isSubmitting}
                              variant="pink"
                              width={[1 / 2, 1 / 3]}
                              type="submit"
                            >
                              {loading || isSubmitting ? 'Saving...' : 'Save'}
                            </Button>
                          )
                        ) : isUntouched ? (
                          <Text
                            textAlign="center"
                            mb={1}
                            fontSize="18px"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              onCanceled();
                            }}
                          >
                            <span
                              style={{ color: '#f9a394', fontWeight: '600' }}
                            >
                              {'< '}
                            </span>
                            <span style={{ color: '#364f79' }}>Cancel</span>
                          </Text>
                        ) : (
                          <Flex
                            flexDirection={['column', 'column', 'row']}
                            alignItems="baseline"
                            width={['initial', 'initial', '100%']}
                          >
                            <Text
                              textAlign="center"
                              mb={1}
                              fontSize="18px"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                onCanceled();
                              }}
                            >
                              <span
                                style={{ color: '#f9a394', fontWeight: '600' }}
                              >
                                {'< '}
                              </span>
                              <span style={{ color: '#364f79' }}>Cancel</span>
                            </Text>
                            <div style={{ flex: 1, textAlign: 'right' }}>
                              <Button
                                disabled={loading || isSubmitting}
                                variant="pink"
                                type="submit"
                              >
                                {loading || isSubmitting
                                  ? 'Saving...'
                                  : 'Save'}
                              </Button>
                            </div>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  </Form>
                );
              }}
            />
          </Flex>
        </Fragment>
      </Card>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(PcpPickForm);
