import React from 'react';
import { Flex, Box, Text, Button } from 'rebass';
import { Mutation } from 'react-apollo';
import 'react-table-v6/react-table.css';
import {
  ADMIN_GET_MOBILE_COURSE_SCREEN_FORMATS,
  ADD_MOBILE_COURSE_SCREEN_FORMAT,
} from '../../../graphql';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import TextInput from '../../../components/TextInput';

class AddScreenFormatModalInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      isActive: true,
      isPro: false,
    };
  }

  render() {
    const { onClose, onSave } = this.props;

    return (
      <Mutation
        mutation={ADD_MOBILE_COURSE_SCREEN_FORMAT}
        refetchQueries={[{ query: ADMIN_GET_MOBILE_COURSE_SCREEN_FORMATS }]}
        onCompleted={() => {
          onSave();
        }}
      >
        {(createJourney, { loading }) => (
          <Formik
            isInitialValid={
              ({ initialValues }) => true
              //   schema.isValidSync(initialValues)
            }
            initialValues={{
              name: '',
              color: '',
            }}
            // validationSchema={schema}
            onSubmit={async (values, actions) => {
              await createJourney({
                variables: {
                  name: values.name,
                  color: values.color,
                },
              });
              // submitCheck(values, actions)
            }}
            render={({
              touched,
              errors,
              isValid,
              handleChange,
              handleBlur,
              isSubmitting,
              values,
            }) => (
              <Form>
                <Flex flexDirection="column" width="400px">
                  <FormField mb={4} error={touched.phone && errors.phone}>
                    <label for="title">Name</label>
                    <TextInput name="name" placeholder="Name" />
                  </FormField>
                  <FormField mb={4} error={touched.phone && errors.phone}>
                    <label for="color">Color</label>
                    <TextInput name="color" placeholder="Color" />
                  </FormField>

                  <Text mt={4} mb={3}>
                    Preview:
                  </Text>
                  <Box
                    style={{
                      padding: '6px 18px',
                      backgroundColor: values.color,
                    }}
                  >
                    <Text>{values.name}</Text>
                  </Box>
                  <Flex
                    mt={4}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Button
                      style={{
                        background: 'white',
                        color: '#344f79',
                        border: '1px solid #344f79',
                      }}
                      type="button"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      // disabled={!isValid}
                      style={{ background: '#344f79' }}
                      onClick={() => {
                        // createCategory();
                      }}
                    >
                      Save
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            )}
          />
        )}
      </Mutation>
    );
  }
}

export default AddScreenFormatModalInner;
