import React, { useState } from 'react';
import { Button, Flex, Text } from 'rebass';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import SpinLoader from '../../components/SpinLoader';

const GET_LAB_RESULT_DOCUMENTS = gql`
  query patientGetLabResultDocuments($labResultId: Int!) {
    patientGetLabResultDocuments(labResultId: $labResultId) {
      pageordering
      pageid
      contenttype
    }
  }
`;

const GET_LAB_RESULT_DOCUMENTS_PAGE = gql`
  query patientGetLabResultDocumentsPage($labResultId: Int!, $pageId: String!) {
    patientGetLabResultDocumentsPage(labResultId: $labResultId, pageId: $pageId) {
      attachement
      mimeType
    }
  }
`;

const Page = ({ labResultId, pageId }) => {
  const [imgHeightRatio, setImgHeightRatio] = useState(1);

  return (
    <Query
      query={GET_LAB_RESULT_DOCUMENTS_PAGE}
      variables={{
        labResultId,
        pageId,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <Flex width={'100%'} height="100%" justifyContent={'center'} alignItems="center">
              <SpinLoader />
            </Flex>
          );
        }
        if (!data || !data.patientGetLabResultDocumentsPage) {
          return (
            <Flex width={'100%'} height="100%" justifyContent={'center'} alignItems="center">
              <Text>No Page</Text>
            </Flex>
          );
        }
        let mimeType = data.patientGetLabResultDocumentsPage.mimeType;
        if ((mimeType || '').indexOf(';') > -1) {
          mimeType = (mimeType || '').split(';')[0];
        }

        if ((mimeType || '').indexOf('pdf') > -1) {
          return <embed type={mimeType} style={{ width: '100%', height: '100%' }} src={`data:${mimeType};base64,${data.patientGetLabResultDocumentsPage.attachement}`} />;
        }

        const img = new Image();
        img.onload = () => {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          setImgHeightRatio(imgHeight / imgWidth);
          // console.log('imgWidth: ', imgWidth);
          // console.log('imgHeight: ', imgHeight);
        };
        img.src = `data:${mimeType};base64,${data.patientGetLabResultDocumentsPage.attachement}`;

        return (
          <Flex
            width={'100%'}
            height="100%"
            flexDirection={'column'}
            style={{
              overflowY: 'scroll',
            }}
          >
            <Button variant={'primary'} style={{alignSelf: 'center'}} mt={'8px'} onClick={()=>{
              var byteCharacters = atob(`${data.patientGetLabResultDocumentsPage.attachement}`);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var file = new Blob([byteArray], { type: `image/${mimeType};base64` });
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);
            }}>download this page</Button>
            <Flex
              width={'100%'}
              style={{
                backgroundImage: `url(data:${mimeType};base64,${data.patientGetLabResultDocumentsPage.attachement})`,
                backgroundSize: 'contain',
                height: `calc(700px * ${imgHeightRatio})`,
              }}
            />
          </Flex>
        );
      }}
    </Query>
  );
};

const LabDocumentViewerReleased = ({ labResultId }) => {
  const [pageIndex, setPageIndex] = useState(0);
  if (!labResultId) {
    return (
      <Flex width={'100%'} height="100%" justifyContent={'center'} alignItems="center">
        <Text>No Document</Text>
      </Flex>
    );
  }
  return (
    <Query query={GET_LAB_RESULT_DOCUMENTS} variables={{ labResultId: parseInt(labResultId, 10) }}>
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <Flex width={'100%'} height="100%" justifyContent={'center'} alignItems="center">
              <SpinLoader />
            </Flex>
          );
        }
        if (!data || data.patientGetLabResultDocuments.length < 1) {
          return (
            <Flex width={'100%'} height="100%" justifyContent={'center'} alignItems="center">
              <Text>No Document</Text>
            </Flex>
          );
        }
        return (
          <Flex width={'100%'} height="100%" flexDirection={'column'}>
            <Flex justifyContent={'center'} alignItems="center" width={'calc(100% + 24px)'} marginLeft="-24px" padding={1} backgroundColor="#acacac">
              <Flex
                style={{
                  borderRadius: '16px',
                  height: '24px',
                  width: '24px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: pageIndex > 0 ? '#344f79' : '#bfbfc0',
                  color: 'white',
                  cursor: pageIndex > 0 ? 'pointer' : '',
                }}
                onClick={() => setPageIndex(Math.max(pageIndex - 1, 0))}
              >
                {'<'}
              </Flex>
              <Text mx={3}>{`Document  ${pageIndex + 1} / ${data.patientGetLabResultDocuments.length}`}</Text>
              <Flex
                style={{
                  borderRadius: '16px',
                  height: '24px',
                  width: '24px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: pageIndex < data.patientGetLabResultDocuments.length - 1 ? '#344f79' : '#bfbfc0',
                  color: 'white',
                  cursor: pageIndex < data.patientGetLabResultDocuments.length - 1 ? 'pointer' : '',
                }}
                onClick={() => setPageIndex(Math.min(pageIndex + 1, data.patientGetLabResultDocuments.length - 1))}
              >
                {'>'}
              </Flex>
            </Flex>
            <Page labResultId={labResultId} pageId={data.patientGetLabResultDocuments[pageIndex].pageid} />
          </Flex>
        );
      }}
    </Query>
  );
};

export default LabDocumentViewerReleased;
