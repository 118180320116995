import React from 'react';
import { withWizard } from 'react-albus';
import { Mutation, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { Button, Card, Heading, Text, Flex } from 'rebass';
import styled from 'styled-components';
import PleaseWait from '../../../../components/PleaseWait';
import { APPLY_CANCELLATION_COUPON, GET_MEMBERSHIPS } from '../../../../graphql';
import plant from '../../../../static/plant.png';
import Legacy from './offers/Legacy';
import { rs } from './Reason';
import NotBetter from './offers/NotBetter';
import FeelGood from './offers/FeelGood';
import PoorExperienceDoctor from './offers/PoorExperienceDoctor';
import Other from './offers/Other';
import GoingOtherCompany from './offers/GoingOtherCompany';
import CannotAfford from './offers/CannotAfford';
import BackInPerson from './offers/BackInPerson';

const A = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: #44a5ff;
  text-decoration: underline;
  cursor: pointer;
  margin: 36px auto 0 auto;
`;

const OfferWrapper = styled(Flex)`
  border-radius: 10px;
  background: #d9e6f8;
  flex-direction: column;
  padding: 30px 10px;
  margin-bottom: 18px;
  cursor: pointer;
`;

const Content = styled(Flex)`
  flex-direction: row;
`;

const OfferTextWrapper = styled(Flex)`
  flex-direction: column;
  margin-left: 16px;
  text-align: left;
`;

const Offers = ({ subscriptionId, wizard, history, subscriptionType, reason, flow, confirm }) => {
  if (!flow || flow === 'LEGACY') {
    return <Legacy subscriptionId={subscriptionId} subscriptionType={subscriptionType} flow={flow} reason={reason} confirm={confirm}/>;
  }
  if (reason === 'I am not feeling better') {
    return <NotBetter subscriptionId={subscriptionId} subscriptionType={subscriptionType} flow={flow} reason={reason}  confirm={confirm} />;
  }
  if (reason === 'I feel good') {
    return <FeelGood subscriptionId={subscriptionId} subscriptionType={subscriptionType} flow={flow} reason={reason}  confirm={confirm}/>;
  }
  if (reason === 'Poor experience with the Paloma Doctor') {
    return <PoorExperienceDoctor subscriptionId={subscriptionId} subscriptionType={subscriptionType} flow={flow} reason={reason}  confirm={confirm}/>;
  }
  if (reason === 'Going for another company') {
    return <GoingOtherCompany subscriptionId={subscriptionId} subscriptionType={subscriptionType} flow={flow} reason={reason}  confirm={confirm}/>;
  }
  if (reason === 'Other') {
    return <Other subscriptionId={subscriptionId} subscriptionType={subscriptionType} flow={flow} reason={reason}  confirm={confirm}/>;
  }
  if (reason === `Can't afford it anymore`) {
    return <CannotAfford subscriptionId={subscriptionId} subscriptionType={subscriptionType} flow={flow} reason={reason}  confirm={confirm}/>;
  }
  if (reason === `Going back to in person visits`) {
    return <BackInPerson subscriptionId={subscriptionId} subscriptionType={subscriptionType} flow={flow} reason={reason}  confirm={confirm}/>;
  }
  return <Other subscriptionId={subscriptionId} subscriptionType={subscriptionType} flow={flow} reason={reason}  confirm={confirm}/>;
};

export default withRouter(withWizard(Offers));
