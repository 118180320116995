import React, { Fragment } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { FormField } from '../../components/FormField';
import RadioInput from '../../components/RadioInput';

const schema = Yup.object().shape({
  // gender: Yup.string().required('Required'),
  diagnosed: Yup.string().required('Required'),
});

const schemaCa = Yup.object().shape({
  // gender: Yup.string().required('Required'),
  diagnosed: Yup.string().required('Required'),
  medicationHistoryConsentVerified: Yup.string().required('Required'),
});

const Extended = props => {
  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex width={[1, 1, 3 / 4]} justifyContent='center'>
        {/* <Card p={4}> */}
          <Flex flexDirection="column">
            <Box mb={4}>
              <Heading textAlign="center" mb={3}>
              Last Questions
              </Heading>
            </Box>
            <Formik
              isInitialValid={({ initialValues }) =>
                schema.isValidSync(initialValues)
              }
              initialValues={{
                ...props.values,
                diagnosed: props.isNewPatientFlow ? true : undefined,
                medicationHistoryConsentVerified:
                  props.values && props.values.state !== 'CA'
                    ? true
                    : undefined,
              }}
              validationSchema={
                props.values && props.values.state !== 'CA' ? schema : schemaCa
              }
              onSubmit={(values, actions) =>{
                props.onSubmit(values, actions, props.wizard)}
              }
              render={({
                touched,
                errors,
                isValid,
                isSubmitting,
                setFieldValue,
                values,
                handleBlur,
                handleChange,
              }) => (
                <Form>
                  <Fragment>
                    {/* <Text fontSize={3} mb={1} textAlign="center">
                      What is your biological sex?
                    </Text>
                    <Text
                      textAlign="center"
                      fontWeight={300}
                      fontSize={2}
                      color="#aaa"
                      mb={3}
                    >
                      Biological sex is important for making medical decisions.
                    </Text>
                    <FormField
                      flexDirection="row"
                      justifyContent="center"
                      mb={4}
                      error={touched.gender && errors.gender}
                    >
                      <RadioInput
                        name="gender"
                        value="F"
                        label="Female"
                        mr={5}
                      />
                      <RadioInput name="gender" value="M" label="Male" />
                    </FormField> */}

                    {/* {!props.isNewPatientFlow && ( */}
                      {/* <> */}
                        <Text mb={3} fontSize={3} textAlign="center">
                          Have you been diagnosed with hypothyroidism?
                        </Text>
                        <FormField
                          flexDirection="row"
                          justifyContent="center"
                          mb={4}
                          error={touched.diagnosed && errors.diagnosed}
                        >
                          {/* <ToggleSwitch
                        name="diagnosed"
                        value={values.diagnosed}
                        onBlur={handleBlur}
                        id="toggle_diagnosed"
                        onChange={value => {
                          setFieldValue('diagnosed', value);
                        }}
                      /> */}
                          <RadioInput
                            disabled={props.isNewPatientFlow}
                            name="diagnosed"
                            value={true}
                            label="Yes"
                            mr={5}
                          />
                          <RadioInput
                            disabled={props.isNewPatientFlow}
                            name="diagnosed"
                            value={false}
                            label="No"
                          />
                        </FormField>
                      {/* </> */}
                    {/* )} */}
                    {props.values && props.values.state === 'CA' && (
                      <Fragment>
                        <Text mb={3} fontSize={3} textAlign="center">
                          Medication history consent
                        </Text>
                        <Text
                          textAlign="center"
                          fontWeight={300}
                          fontSize={2}
                          color="#aaa"
                          mb={3}
                        >
                          Your medication history gives our physicians vital
                          information to treat you.
                        </Text>
                        <FormField
                          flexDirection="row"
                          justifyContent="center"
                          mb={4}
                          error={
                            touched.medicationHistoryConsentVerified &&
                            errors.medicationHistoryConsentVerified
                          }
                        >
                          <RadioInput
                            name="medicationHistoryConsentVerified"
                            value={true}
                            label="Yes"
                            mr={5}
                          />
                          <RadioInput
                            name="medicationHistoryConsentVerified"
                            value={false}
                            label="No"
                          />
                          {/* <ToggleSwitch
                            name="medicationHistoryConsentVerified"
                            value={values.medicationHistoryConsentVerified}
                            onBlur={handleBlur}
                            id="toggle_consent"
                            // value={this.state.medicationHistoryConsentVerified}
                            onChange={value => {
                              setFieldValue(
                                'medicationHistoryConsentVerified',
                                value
                              );
                            }}
                          /> */}
                        </FormField>
                      </Fragment>
                    )}

                    <Flex justifyContent="center">
                      <Button
                        disabled={!isValid || isSubmitting}
                        variant="primary"
                        width={[1 / 2, 1 / 3]}
                        type="submit"
                        id="btn_complete"
                      >
                        Complete
                      </Button>
                    </Flex>
                  </Fragment>
                </Form>
              )}
            />
          </Flex>
        {/* </Card> */}
      </Flex>
    </Flex>
  );
};

export default withWizard(Extended);
