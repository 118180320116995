import React, { useEffect, useState } from 'react';
import { Button, Card, Flex, Heading, Text } from 'rebass';
import _ from 'lodash';
import { Input, TextAreaWithLabel } from '../../components/TextInput';
import SwitchSelector from '../../components/switchSelector/SwitchSelector';
import { Checkbox } from '../../components/CheckboxInput';
import { Drawer } from './Drawer';
import { stringify } from '../../utils/htmlStringParser/htmlStringParser';

const options = [
  {
    label: 'Card',
    value: 'Card',
  },
  {
    label: 'Read More',
    value: 'ReadMore',
  },
];

const BannerTemplateReadMore = ({ readMore, setReadMore, setPreviewReadMore }) => {
  return (
    <>
      <Text mt={3} fontWeight={300} fontSize={'13px'}>
        Read More Content:
      </Text>
      <TextAreaWithLabel style={{ fontSize: '13px', padding: '12px', minHeight: '200px' }} value={readMore} onChange={(e) => setReadMore(e.target.value)} />

      <Flex mt={4} alignItems={'center'} justifyContent={'center'}>
        <Button onClick={() => setPreviewReadMore(true)} variant={'primary'} style={{ padding: '8px 16px', fontSize: '12px', fontWeight: 300 }}>
          Preview
        </Button>
      </Flex>
    </>
  );
};

const BannerTemplateCard = ({ title, description, background, hasReadMore, setBackground, setTitle, setDescription, setHasReadMore, setPreviewReadMore }) => {
  return (
    <>
      <Text fontWeight={300} fontSize={'13px'}>
        Preview:
      </Text>
      <Card p={3} m={3} style={{ backgroundColor: background || 'white' }}>
        <Flex flexDirection={'column'}>
          <Text fontWeight={600}>{title}</Text>
          <Text
            mt={3}
            dangerouslySetInnerHTML={{
              __html: `${description}`,
            }}
          />
          {!!hasReadMore && (
            <Text textAlign={'right'} style={{ width: '100%', textDecoration: 'underline', cursor: 'pointer' }} mt={3} onClick={() => setPreviewReadMore(true)}>
              read more
            </Text>
          )}
        </Flex>
      </Card>
      <Text fontWeight={300} fontSize={'13px'}>
        Banner background
      </Text>
      <Input style={{ fontSize: '13px', padding: '12px' }} value={background} onChange={(e) => setBackground(e.target.value)} placeholder={'Color name or hex code (default white)'} />
      <Text mt={3} fontWeight={300} fontSize={'13px'}>
        Title
      </Text>
      <Input style={{ fontSize: '13px', padding: '12px' }} value={title} onChange={(e) => setTitle(e.target.value)} />
      <Text mt={3} fontWeight={300} fontSize={'13px'}>
        Description <span style={{ color: 'red' }}>*</span>
      </Text>
      <TextAreaWithLabel style={{ fontSize: '13px', padding: '12px' }} value={description} onChange={(e) => setDescription(e.target.value)} />
      <Checkbox
        name="recentLabs"
        isChecked={hasReadMore}
        onClick={() => {
          setHasReadMore(!hasReadMore);
        }}
        style={{ marginTop: '24px' }}
        label="Include read more"
      />
    </>
  );
};

export const BannerTemplate = ({ template, onSave, onCancel }) => {
  const [mode, setMode] = useState();
  const [hasChanged, setHasChanged] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [background, setBackground] = useState('white');
  const [title, setTitle] = useState((template || {}).title || '');
  const [description, setDescription] = useState(stringify((template || {}).description || '') || '');
  const [hasReadMore, setHasReadMore] = useState(!!(template || {}).readMore || false);
  const [readMore, setReadMore] = useState(stringify((template || {}).readMore || '') || '');
  const [previewReadMore, setPreviewReadMore] = useState(false);
  const [isReadMoreEdit, setIsReadMoreEdit] = useState(false);

  useEffect(() => {
    if (!template || Object.keys(template).length === 0 || !template.id) {
      setMode('NEW');
      return;
    }
    setMode('EDIT');
  }, []);

  useEffect(
    () => {
      const obj = {
        background,
        title,
        description,
        readMore,
      };
      const isEqual = _.isEqual(template, obj);
      setHasChanged(!isEqual);

      // if(!title || !description){
      //   setIsValid(false)
      // }else if(!!hasReadMore && !readMore)
    },
    [background, title, description, hasReadMore, readMore]
  );

  return (
    <Flex flexDirection={'column'}>
      <Flex>
        <Heading flex={1}>Banner Template</Heading>
        <Button variant={'outline'} style={{ flex: 'initial', width: 'fit-content', padding: '8px 16px', fontSize: '12px', fontWeight: 300 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant={'primary'}
          disabled={!hasChanged}
          style={{ padding: '8px 16px', width: 'fit-content', padding: '8px 16px', fontSize: '12px', fontWeight: 300 }}
          ml={3}
          onClick={() => {
            const obj = {
              background,
              title,
              description,
              readMore,
            };
            onSave(obj);
          }}
        >
          OK
        </Button>
      </Flex>
      {!!hasReadMore && (
        <div className="your-required-wrapper" style={{ width: '300px', height: 38, margin: '0 auto', marginTop: 24 }}>
          <SwitchSelector
            fontSize={12}
            options={options}
            initialSelectedIndex={options.findIndex((x) => x.value === 'Card')}
            backgroundColor="#344F7A"
            selectedBackgroundColor="white"
            selectedFontColor="#344F7A"
            fontColor="white"
            onChange={(v) => {
              setIsReadMoreEdit(v === 'ReadMore');
            }}
            optionStyle={{ fontSize: '12px !important' }}
          />
        </div>
      )}
      {!isReadMoreEdit ? (
        <BannerTemplateCard
          background={background}
          title={title}
          description={description}
          hasReadMore={hasReadMore}
          setBackground={setBackground}
          setTitle={setTitle}
          setDescription={setDescription}
          setHasReadMore={setHasReadMore}
          setPreviewReadMore={setPreviewReadMore}
        />
      ) : (
        <BannerTemplateReadMore readMore={readMore} setReadMore={setReadMore} setPreviewReadMore={setPreviewReadMore} />
      )}
      <Drawer isOpen={previewReadMore} onClose={() => setPreviewReadMore(false)}>
        <Flex style={{ overflowY: 'auto' }}>
          <Text dangerouslySetInnerHTML={{ __html: readMore }} />
        </Flex>
      </Drawer>
    </Flex>
  );
};
