import React, { Fragment } from 'react';
import { Box, Button, Card, Flex, Heading, Link, Text } from 'rebass';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import FormField from '../../components/FormField';
import CheckboxInput from '../../components/CheckboxInput';
import moment from 'moment';
import { MaskInput } from '../../components/MaskInput';

const StyledLink = styled(Link)`
  color: #000;
`;
const minDob = moment()
  .subtract(18, 'years')
  .toDate();

const maxDob = moment()
  .subtract(125, 'years')
  .toDate();

const schema = Yup.object().shape({
  dob: Yup.date()
    .transform(value => {
      return moment(value).toDate();
    })
    .min(maxDob, 'Must be younger than 125 years')
    .max(minDob, 'Must be 18 years or older')
    .required('Required'),
  consent: Yup.bool()
    .test(
      'consent',
      'You must agree to the Terms and Conditions',
      val => val === true
    )
    .required('You must agree to the Terms and Conditions'),
  consenthipaa: Yup.bool()
    .test(
      'consenthipaa',
      'You must agree to the HIPAA Terms',
      val => val === true
    )
    .required('You must agree to the HIPAA Terms'),
});

const Basic = ({ values, onSubmit }) => {
  const submitCheck = async (values, actions) => {
    actions.setSubmitting(false);
    onSubmit(
      {
        consent: values.consent,
        consenthipaa: values.consenthipaa,
        dob: values.dob
      },
      actions
    );
  };

  return (
    <Fragment>
      <Flex mb={4} flex={1} flexDirection={['column', 'column', 'row']}>
        <Flex flex={1} mr={[0, 0, 4]} mb={[4, 4, 0]}>
          <Card p={4} style={{ maxWidth: '650px', margin: '0 auto' }}>
            <Flex flexDirection="column">
              <Box mb={4}>
                <Heading textAlign="center" mb={3}>
                  Get Started
                </Heading>
                {/* <Text textAlign="center">Creating an account is free!</Text> */}
              </Box>
              <Formik
                isInitialValid={({ initialValues }) =>
                  schema.isValidSync(initialValues)
                }
                initialValues={values}
                validationSchema={schema}
                onSubmit={(values, actions) => submitCheck(values, actions)}
                render={({
                  isValid,
                  errors,
                  handleChange,
                  handleBlur,
                  touched,
                  values,
                }) => {
                  const dobError =
                    errors.dob &&
                    errors.dob !== 'Invalid Date' &&
                    errors.dob.indexOf('dob') !== 0
                      ? errors.dob
                      : null;
                  return (
                    <Form>
                      <Box>
                        <FormField mb={4} error={touched.dob && dobError}>
                          <MaskInput
                            name="dob"
                            placeholder="Your Date of Birth (MM/DD/YYYY)"
                            mask="99/99/9999"
                            value={values.dob}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="input_dob"
                          />
                        </FormField>
                        <FormField
                          flexDirection="row"
                          mb={4}
                          error={touched.consent && errors.consent}
                        >
                          <CheckboxInput name="consent" />
                          <Text fontSize={1}>
                            I agree to Paloma Health's&nbsp;
                            <StyledLink
                              href="https://www.palomahealth.com/legal-doc/paloma-health-terms-of-use"
                              target="_blank"
                            >
                              Terms of Service
                            </StyledLink>{' '}
                            and{' '}
                            <StyledLink
                              href="https://www.palomahealth.com/legal-doc/consent-to-telehealth"
                              target="_blank"
                            >
                              Informed Consent
                            </StyledLink>
                          </Text>
                        </FormField>

                        <FormField
                          flexDirection="row"
                          mb={4}
                          error={touched.consenthipaa && errors.consenthipaa}
                        >
                          <CheckboxInput name="consenthipaa" />
                          <Text fontSize={1}>
                            I have read and agree to the terms of Paloma
                            Health's&nbsp;
                            <StyledLink
                              href="https://www.palomahealth.com/legal-doc/notice-of-privacy-practices"
                              target="_blank"
                            >
                              HIPAA Notice
                            </StyledLink>
                          </Text>
                        </FormField>
                      </Box>

                      <Flex justifyContent="center">
                        <Button
                          variant="primary"
                          width={[1 / 2, 1 / 3]}
                          type="submit"
                          disabled={!isValid}
                          id="btn_next"
                        >
                          Next
                        </Button>
                      </Flex>
                    </Form>
                  );
                }}
              />
            </Flex>
          </Card>
        </Flex>
        {/* <Aside /> */}
      </Flex>
    </Fragment>
  );
};

export default Basic;
