import React, { Fragment, useState } from 'react';
import { Flex, Box, Button, Heading } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BoxButton } from '../../../components/async/SharedComponents';
import { useMount } from '../../../utils/hooks/lifecycle';
import AllergySelector from '../../../components/allergySelector/AllergySelector';
import { SelectedAllergyCard } from './components/medicationLists/SelectedAllergyCard';

const AllergiesList = ({ onSubmit, data, getState }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useMount(() => {
    const state = getState();
    if (state && state.values) {
      const meds = state.values.find(x => x.id === data.id);
      setSelectedOptions((meds || {}).values || []);
    }
  });
  const noAllergystyle = {
    background: '#FFF2F2',
    border: '2px solid #F6A293',
    borderRadius: '20px',
    marginLeft: '12px',
    marginRight: '12px',
    color: '#344f79'
  };
  const allergyStyle = {
    marginLeft: '12px',
    marginRight: '12px',
  };
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
        {data.title ? (
          <Box m={2} mt="0" mb={4}>
            <Heading textAlign="left">{data.title}</Heading>
          </Box>
        ) : null}
        <AllergySelector
          onChange={option => {
            if (!option || !option.value) return;
            option.questionId = option.value.toString();
            setSelectedOptions(previous => [...previous, option]);
          }}
        />
        <TransitionGroup className="items-section__list">
          {selectedOptions.map(x => {
            return (
              <CSSTransition timeout={500} classNames="moveList">
                <SelectedAllergyCard
                  key={x.value}
                  name={x.label}
                  onClick={() =>
                    setSelectedOptions(
                      selectedOptions.filter(y => y.value !== x.value)
                    )
                  }
                />
              </CSSTransition>
            );
          })}
        </TransitionGroup>

        {/* {!selectedOptions || selectedOptions.length < 1 ? (
          <Button
            my={4}
            variant="white"
            style={{
              background: '#FFF2F2',
              border: '2px solid #F6A293',
              borderRadius: '20px',
            }}
            onClick={() => onSubmit([])}
          >
            {data.nothingText}
          </Button>
        ) : null} */}

        <BoxButton className="visible">
          <Button
            variant={
              !selectedOptions || selectedOptions.length < 1
                ? 'primary'
                : 'primary'
            }
            style={!selectedOptions || selectedOptions.length < 1?noAllergystyle:allergyStyle}
            width={[1, 1 / 2, 1 / 4]}
            type="button"
            onClick={() => {
              onSubmit(selectedOptions);
            }}
          >
            {!selectedOptions || selectedOptions.length < 1?`I don't have any allergies`:`Continue`}
          </Button>
        </BoxButton>
      </Flex>
    </Fragment>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(AllergiesList);
