import React, { useState } from 'react';
import { Query } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { ADMIN_GET_BROKERS_LIST } from '../../graphql';
import PleaseWait from '../../components/PleaseWait';
import { Box, Button, Flex, Heading } from 'rebass';
import { convertExpiresAfterToFriendlyString } from '../../utils/b2bUtils';
import ConfirmModal from '../../components/ConfirmModal';
import AddBroker from './AddBroker';
import styled from 'styled-components';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;
const BrokersList = ({ data }) => {
  const [isAdd, setIsAdd] = useState(null);
  const CATEGORIES_COLUMNS = [
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'friendly name',
      accessor: 'friendlyName',
    },
    {
      Header: 'active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div>{row.original.isActive ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'kit free',
      Cell: (row) => {
        return <div>{(row.original.defaultFreeness || {}).kit ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'vitamins free',
      Cell: (row) => {
        return <div>{(row.original.defaultFreeness || {}).vitamins ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'membership free',
      Cell: (row) => {
        return <div>{(row.original.defaultFreeness || {}).membership ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'visits free',
      Cell: (row) => {
        return <div>{(row.original.defaultFreeness || {}).visits ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'async free',
      Cell: (row) => {
        return <div>{(row.original.defaultFreeness || {}).async ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'nutritionist free',
      Cell: (row) => {
        return <div>{(row.original.defaultFreeness || {}).nutritionist ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'expires after',
      Cell: (row) => {
        return <div>{convertExpiresAfterToFriendlyString((row.original.defaultFreeness || {}).expiresAfter)}</div>;
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <Flex flexDirection="row">
              <div title="delete" style={{ cursor: 'pointer' }} onClick={() => deleteOnClick(row.original.id)}>
                🗑
              </div>
              <div title="edit" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => setIsAdd(row.original)}>
                ✎
              </div>
            </Flex>
          </Flex>
        );
      },
    },
  ];

  const deleteOnClick = (id) => {};
  return (
    <div>
      <Flex flexDirection="row" style={{ justifyContent: 'space-between' }} mt={4} mb={3}>
        <Heading fontSize="20px">Brokers</Heading>
        <Button
          style={{ background: '#344f79' }}
          onClick={() => {
            setIsAdd({});
          }}
        >
          Add Broker
        </Button>
      </Flex>
      {data &&
        data.getAdminBrokersList && (
          <ReactTable
            data={data.getAdminBrokersList}
            columns={CATEGORIES_COLUMNS}
            defaultPageSize={data.getAdminBrokersList && data.getAdminBrokersList.length > 3 ? Math.min(data.getAdminBrokersList.length, 25) : 3}
            pageSize={data.getAdminBrokersList && data.getAdminBrokersList.length > 3 ? data.getAdminBrokersList.length : 3}
            showPagination={data.getAdminBrokersList && data.getAdminBrokersList.length > 24}
          />
        )}

      <ConfirmModal
        childrenManaged
        isOpen={!!isAdd}
        confirmLabel="create"
        onClose={() => {
          setIsAdd(null);
        }}
        onBackgroundClick={() => {
          setIsAdd(null);
        }}
        onEscapeKeydown={() => {
          setIsAdd(null);
        }}
        onConfirm={() => {
          setIsAdd(null);
        }}
      >
        <ModalInner>
          <Heading fontSize="20px" mb={3}>
            {!!isAdd && !!isAdd.id ? 'Update Broker' : 'Add Broker'}
          </Heading>
          <AddBroker
            broker={isAdd}
            onClose={() => {
              setIsAdd(null);
            }}
            onSave={() => {
              setIsAdd(null);
            }}
          />
        </ModalInner>
      </ConfirmModal>
    </div>
  );
};

export default BrokersList;
