import moment from 'moment';
import momentTimezone from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { Skeleton } from '../../components/loadingSkeleton/Skeleton';
import withSession from '../../lib/withSession';
import ThyroidWithBg from '../../static/ThyroidWithBg.png';
import ConfirmModal from '../../components/ConfirmModal';
import RemovingFromWaitlistModalInner from './RemovingFromWaitlistModalInner';

const AvatarImg = styled(Flex)`
  background: #ffe3d8;
  border-radius: 50%;
  border: 0px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 69px;
  height: 69px;
  min-width: 69px;
  margin-right: 24px;
  & > img {
    height: 69px;
  }
`;

const CardWrap = styled(Flex)`
  flex-direction: row-reverse;
  width: 100%;
  max-width: 700px;
  justify-content: flex-end;

  @media screen and (max-width: 480px) {
    flex-direction: column-reverse;
  }
`;

const DetailsWrap = styled(Flex)`
  height: 100%;
  width: 50%;
  background-color: #e7f0ff;
  padding: 8px;
  padding-left: 42px;
  border-radius: 16px;
  align-items: center;
  box-shadow: initial;

  @media screen and (max-width: 480px) {
    background-color: transparent;
    height: auto;
    width: 100%;
    padding-left: 8px;
  }

  &:hover ${CardMain} {
    /* .canClick { */
    box-shadow: 0px 4px 20px rgb(0 0 0 / 30%);
    /* } */
  }
`;

const CardMain = styled(Flex)`
  border-radius: 16px;
  padding: 8px;
  width: 50%;
  margin-right: -20px;
  height: 100%;
  background: white;
  transition: all 0.3s ease;

  @media screen and (max-width: 480px) {
    margin-right: 0;
    width: 100%;
  }

  &:hover {
    &.canClick {
      box-shadow: 0px 4px 20px rgb(0 0 0 / 30%);
    }
  }
`;

const UpcomingCard = ({ children, text, onClick, noDescription = false, visitType = '' }) => {
  return (
    <CardWrap alignItems="center" mt={'22px'}>
      {children.length > 2 && !noDescription ? <DetailsWrap>{children[2]}</DetailsWrap> : <DetailsWrap style={{ padding: 0, paddingLeft: 0, backgroundColor: 'transparent' }} />}
      <CardMain
        style={{ cursor: !!onClick ? 'pointer' : '', zIndex: 1 }}
        onClick={() => {
          if (!!onClick) {
            onClick();
          }
        }}
        className={!!onClick ? 'canClick' : ''}
      >
        <Flex alignItems={'center'} style={{ height: '100%' }}>
          {children[0]}
          <Flex flexDirection={'column'} justifyContent="center">
            {!!visitType && <Text style={{ textTransform: 'uppercase', fontWeight: 300, fontSize: '12px' }}>{visitType} visit</Text>}
            <Text style={{ fontWeight: 500 }}>{text}</Text>
            {children[1]}
          </Flex>
        </Flex>
      </CardMain>
    </CardWrap>
  );
};

const WaitlistCard = ({ children, text, waitlistId }) => {
  const [isRemovingOpened, setIsRemovingOpened] = useState(false);
  return (
    <CardWrap alignItems="center" mt={'22px'}>
      <DetailsWrap>
        <Flex flexDirection={'column'}>
          <Text fontWeight={300} fontSize={'14px'}>
            You'll be alerted via SMS when a slot becomes available.
          </Text>
          <Text color="#1E5BD4" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setIsRemovingOpened(true)} fontSize={'14px'}>{`Remove me from waitlist`}</Text>
        </Flex>
      </DetailsWrap>

      <CardMain style={{ zIndex: 1 }} className={''}>
        <Flex alignItems={'center'} style={{ height: '100%' }}>
          {children[0]}
          <Flex flexDirection={'column'} justifyContent="center">
            <Text style={{ fontWeight: 500 }}>{text}</Text>
            {children[1]}
          </Flex>
        </Flex>
      </CardMain>
      <ConfirmModal
        childrenManaged
        confirmLabel="OK"
        isOpen={isRemovingOpened}
        okOnly
        displayX
        cardStyle={{
          maxHeight: '100vh',
          position: 'relative',
          overflowY: 'auto',
        }}
        onClose={() => {
          setIsRemovingOpened(false);
        }}
        onConfirm={() => {}}
        onBackgroundClick={() => {
          setIsRemovingOpened(false);
        }}
        onEscapeKeydown={() => {
          setIsRemovingOpened(false);
        }}
      >
        <RemovingFromWaitlistModalInner
          waitlistId={waitlistId}
          onClose={() => {
            setIsRemovingOpened(false);
          }}
        />
      </ConfirmModal>
    </CardWrap>
  );
};

const LoadingUpcomings = () => {
  return (
    <>
      {[1, 2, 3].map((x) => {
        return (
          <UpcomingCard>
            <Flex />
            <Flex flexDirection={'column'} mb={'9px'} mt={'5px'}>
              <Skeleton width="240px" height="27px" />
              <Skeleton width="140px" height="18px" />
            </Flex>
            <Flex />
          </UpcomingCard>
        );
      })}
    </>
  );
};

const Container = styled(Flex)`
  width: 100%;
  max-width: 700px;

  @media screen and (max-width: 480px) {
    width: 100vw;
    max-width: 100vw;
    padding: 0 20px;
  }
`;

const UpcomingVisitLabs = ({
  history,
  session: { eligibility, intakeStatus, isMember, diagnoseFull, intakeDate },
  mustCompleteIntake,
  hasFutureAppointments,
  upcomingLabs,
  loadingAppointments,
  labsLoading,
  hasFutureAppointmentsNutritionist,
  waitlistData,
  waitlistLoading,
  hasFutureAppointmentsMeno,
  mustCompleteIntakeMeno,
}) => {
  const [futureVisit, setFutureVisit] = useState();
  const [futureVisitMeno, setFutureVisitMeno] = useState();
  const [futureVisitNutritionist, setFutureVisitNutritionist] = useState();

  useEffect(
    () => {
      if (!!hasFutureAppointments) {
        let tiz;
        try {
          tiz = momentTimezone(`${hasFutureAppointments.date} ${hasFutureAppointments.startTime}`, 'MM/DD/YYYY HH:mm').tz(hasFutureAppointments.timezoneName);
        } catch (error) {}
        setFutureVisit({
          ...hasFutureAppointments,
          localDate: hasFutureAppointments.date,
          localTime: moment(hasFutureAppointments.startTime, 'HH:mm').format('hh:mmA'),
          tzAbbr: tiz.zoneAbbr(),
          isDoctor: true,
        });
      }
    },
    [hasFutureAppointments]
  );
  useEffect(
    () => {
      if (!!hasFutureAppointmentsMeno) {
        let tiz;
        try {
          tiz = momentTimezone(`${hasFutureAppointmentsMeno.date} ${hasFutureAppointmentsMeno.startTime}`, 'MM/DD/YYYY HH:mm').tz(hasFutureAppointmentsMeno.timezoneName);
        } catch (error) {}
        setFutureVisitMeno({
          ...hasFutureAppointmentsMeno,
          localDate: hasFutureAppointmentsMeno.date,
          localTime: moment(hasFutureAppointmentsMeno.startTime, 'HH:mm').format('hh:mmA'),
          tzAbbr: tiz.zoneAbbr(),
          isDoctor: true,
        });
      }
    },
    [hasFutureAppointmentsMeno]
  );
  useEffect(
    () => {
      if (!!hasFutureAppointmentsNutritionist) {
        let tiz;
        try {
          tiz = momentTimezone(`${hasFutureAppointmentsNutritionist.date} ${hasFutureAppointmentsNutritionist.startTime}`, 'MM/DD/YYYY HH:mm').tz(hasFutureAppointmentsNutritionist.timezoneName);
        } catch (error) {}
        setFutureVisitNutritionist({
          ...hasFutureAppointmentsNutritionist,
          localDate: hasFutureAppointmentsNutritionist.date,
          localTime: moment(hasFutureAppointmentsNutritionist.startTime, 'HH:mm').format('hh:mmA'),
          tzAbbr: tiz.zoneAbbr(),
          isDoctor: true,
        });
      }
    },
    [hasFutureAppointmentsNutritionist]
  );

  const labDescription = (lab) => {
    if (lab.status === 'scannedByLab') {
      const eta = momentTimezone((lab.currentStep || {}).eta, 'YYYY-MM-DD').format('MMMM Do');
      return `Sample is being processed, your results should be available on ${eta}`;
    }
    if (!lab.currentStep || !lab.currentStep.step || (lab.currentStep || {}).step === 1) {
      return `You can now collect your sample and send back your sample to our lab using the return shipping label inside your kit`;
    }

    if (lab.currentStep.step === 2) {
      return `Your sample is en route to our lab`;
    }
    if (lab.currentStep.step === 3) {
      return `USPS indicates that your sample was delivered`;
    }
  };

  const labSubtitle = (lab) => {
    if (lab.status === 'scannedByLab') {
      return `Processing by lab`;
    }
    if (!lab.currentStep || !lab.currentStep.step || (lab.currentStep || {}).step === 1) {
      return `Your kit is now registered`;
    }

    if (lab.currentStep.step === 2) {
      return `Congrats on collecting your sample`;
    }
    if (lab.currentStep.step === 3) {
      return `Your kit has arrived at our lab`;
    }
  };
  
  if (!labsLoading && !loadingAppointments && (!futureVisit || !futureVisit.isDoctor) && (!futureVisitMeno || !futureVisitMeno.isDoctor) && !futureVisitNutritionist && (!upcomingLabs || upcomingLabs.length < 1)) return null;

  return (
    <Container flexDirection="column">
      <Heading mb="10px">Upcoming Visits and Labs</Heading>
      {!!labsLoading || !!loadingAppointments || !!waitlistLoading ? (
        <>
          <LoadingUpcomings />
        </>
      ) : (
        <>
          {!!waitlistData &&
            waitlistData.length > 0 && (
              <>
                {waitlistData.map((x) => {
                  return (
                    <WaitlistCard key={`waitlist_${x.id}`} waitlistId={x.id} text={x.providerName}>
                      <AvatarImg style={{ alignSelf: 'center' }}>
                        <img alt="" src={x.providerPicture} />
                      </AvatarImg>

                      <Flex alignItems={'center'}>
                        <Text mr={3} fontWeight={300} fontSize={'14px'}>
                          {`You're on the waitlist`}
                        </Text>
                      </Flex>
                    </WaitlistCard>
                  );
                })}
              </>
            )}

          {!!futureVisit && (
            <>
              {!!futureVisit.isDoctor && (
                <UpcomingCard visitType="thyroid" text={futureVisit.provider.displayName} noDescription={!mustCompleteIntake} onClick={() => history.push(`/consultations/${futureVisit.id}`)}>
                  <AvatarImg style={{ alignSelf: 'center' }}>
                    <img alt="" src={futureVisit.provider.picture} />
                  </AvatarImg>

                  <Flex alignItems={'center'}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5156 5.34375C12.5156 5.42109 12.4523 5.48438 12.375 5.48438H11.3906C11.3133 5.48438 11.25 5.42109 11.25 5.34375V4.5H6.75V5.34375C6.75 5.42109 6.68672 5.48438 6.60938 5.48438H5.625C5.54766 5.48438 5.48438 5.42109 5.48438 5.34375V4.5H3.23438V6.89062H14.7656V4.5H12.5156V5.34375Z"
                        fill="#FFE3D8"
                      />
                      <path
                        d="M15.4688 3.23438H12.5156V2.10938C12.5156 2.03203 12.4523 1.96875 12.375 1.96875H11.3906C11.3133 1.96875 11.25 2.03203 11.25 2.10938V3.23438H6.75V2.10938C6.75 2.03203 6.68672 1.96875 6.60938 1.96875H5.625C5.54766 1.96875 5.48438 2.03203 5.48438 2.10938V3.23438H2.53125C2.22012 3.23438 1.96875 3.48574 1.96875 3.79688V15.4688C1.96875 15.7799 2.22012 16.0312 2.53125 16.0312H15.4688C15.7799 16.0312 16.0312 15.7799 16.0312 15.4688V3.79688C16.0312 3.48574 15.7799 3.23438 15.4688 3.23438ZM14.7656 14.7656H3.23438V8.08594H14.7656V14.7656ZM14.7656 6.89062H3.23438V4.5H5.48438V5.34375C5.48438 5.42109 5.54766 5.48438 5.625 5.48438H6.60938C6.68672 5.48438 6.75 5.42109 6.75 5.34375V4.5H11.25V5.34375C11.25 5.42109 11.3133 5.48438 11.3906 5.48438H12.375C12.4523 5.48438 12.5156 5.42109 12.5156 5.34375V4.5H14.7656V6.89062Z"
                        fill="#4E658A"
                      />
                    </svg>

                    <Text ml={1} mr={3} fontWeight={300} fontSize={'14px'}>
                      {futureVisit.localDate}
                    </Text>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.515 1.019C8.34363 1.00635 8.17184 1.00001 8 1V0C8.19654 9.58276e-05 8.393 0.00743414 8.589 0.022L8.515 1.019ZM10.519 1.469C10.1984 1.3453 9.86922 1.24537 9.534 1.17L9.753 0.194C10.136 0.28 10.513 0.394 10.879 0.536L10.519 1.469ZM11.889 2.179C11.746 2.08365 11.5996 1.99359 11.45 1.909L11.943 1.039C12.2849 1.23274 12.6121 1.45132 12.922 1.693L12.307 2.482C12.1714 2.37623 12.032 2.2755 11.889 2.18V2.179ZM13.723 3.969C13.5252 3.68798 13.3069 3.42192 13.07 3.173L13.794 2.483C14.064 2.768 14.314 3.073 14.541 3.393L13.723 3.969ZM14.467 5.321C14.4014 5.16246 14.33 5.00636 14.253 4.853L15.146 4.403C15.3226 4.75409 15.473 5.11774 15.596 5.491L14.646 5.804C14.5923 5.64087 14.5326 5.47976 14.467 5.321ZM14.997 7.828C14.9889 7.48434 14.9555 7.14174 14.897 6.803L15.882 6.633C15.949 7.019 15.988 7.411 15.998 7.803L14.998 7.828H14.997ZM14.866 9.366C14.899 9.196 14.926 9.027 14.947 8.856L15.94 8.979C15.892 9.36915 15.8151 9.75521 15.71 10.134L14.746 9.867C14.792 9.702 14.832 9.535 14.866 9.366ZM13.914 11.745C14.098 11.455 14.26 11.151 14.4 10.837L15.314 11.242C15.154 11.602 14.969 11.948 14.759 12.28L13.914 11.745ZM12.95 12.95C13.072 12.828 13.189 12.702 13.3 12.572L14.058 13.225C13.9296 13.3738 13.7959 13.5179 13.657 13.657L12.95 12.95Z"
                        fill="#4E658A"
                      />
                      <path
                        d="M8.00019 1C6.84907 1.00008 5.71574 1.28405 4.70057 1.82674C3.68541 2.36943 2.81974 3.1541 2.18025 4.11125C1.54076 5.0684 1.14719 6.16848 1.03439 7.31406C0.921589 8.45964 1.09304 9.61536 1.53357 10.6789C1.9741 11.7423 2.6701 12.6808 3.55992 13.4111C4.44974 14.1413 5.50593 14.6409 6.63493 14.8655C7.76393 15.0901 8.93089 15.0328 10.0325 14.6987C11.134 14.3645 12.1362 13.7639 12.9502 12.95L13.6572 13.657C12.7269 14.5878 11.5814 15.2747 10.3222 15.657C9.06302 16.0393 7.72896 16.105 6.43826 15.8485C5.14755 15.5919 3.94005 15.021 2.92274 14.1862C1.90543 13.3515 1.10973 12.2787 0.606129 11.0629C0.102528 9.84711 -0.0934196 8.52588 0.0356482 7.21627C0.164716 5.90666 0.614814 4.6491 1.34606 3.55502C2.07731 2.46094 3.06713 1.56411 4.22782 0.944003C5.38851 0.323895 6.68424 -0.000348732 8.00019 2.81458e-07V1Z"
                        fill="#4E658A"
                      />
                      <path
                        d="M7.5 3C7.63261 3 7.75979 3.05268 7.85355 3.14645C7.94732 3.24021 8 3.36739 8 3.5V8.71L11.248 10.566C11.3598 10.6334 11.4408 10.7419 11.4736 10.8683C11.5065 10.9946 11.4886 11.1288 11.4238 11.2422C11.3591 11.3556 11.2525 11.4391 11.127 11.4749C11.0014 11.5108 10.8669 11.4961 10.752 11.434L7.252 9.434C7.17547 9.39029 7.11186 9.32712 7.06761 9.25091C7.02335 9.17469 7.00003 9.08813 7 9V3.5C7 3.36739 7.05268 3.24021 7.14645 3.14645C7.24021 3.05268 7.36739 3 7.5 3Z"
                        fill="#4E658A"
                      />
                    </svg>
                    <Text ml={1} fontWeight={300} fontSize={'14px'}>
                      {futureVisit.localTime}
                    </Text>
                    <Text ml={1} fontWeight={300} fontSize={'14px'}>
                      {futureVisit.tzAbbr}
                    </Text>
                  </Flex>

                  {!!mustCompleteIntake ? (
                    <Flex flexDirection={'column'}>
                      <Text fontWeight={300} fontSize={'14px'}>
                        Don’t forget to complete your intake form
                      </Text>
                      <Text color="#1E5BD4" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => history.push('/intake')} fontSize={'14px'}>{`Complete >>`}</Text>
                    </Flex>
                  ) : null}
                </UpcomingCard>
              )}
            </>
          )}

          {!!futureVisitMeno && (
            <>
              {!!futureVisitMeno.isDoctor && (
                <UpcomingCard
                  visitType="menopause"
                  text={futureVisitMeno.provider.displayName}
                  noDescription={!mustCompleteIntakeMeno}
                  onClick={() => history.push(`/consultations/${futureVisitMeno.id}`)}
                >
                  <AvatarImg style={{ alignSelf: 'center' }}>
                    <img alt="" src={futureVisitMeno.provider.picture} />
                  </AvatarImg>

                  <Flex alignItems={'center'}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5156 5.34375C12.5156 5.42109 12.4523 5.48438 12.375 5.48438H11.3906C11.3133 5.48438 11.25 5.42109 11.25 5.34375V4.5H6.75V5.34375C6.75 5.42109 6.68672 5.48438 6.60938 5.48438H5.625C5.54766 5.48438 5.48438 5.42109 5.48438 5.34375V4.5H3.23438V6.89062H14.7656V4.5H12.5156V5.34375Z"
                        fill="#FFE3D8"
                      />
                      <path
                        d="M15.4688 3.23438H12.5156V2.10938C12.5156 2.03203 12.4523 1.96875 12.375 1.96875H11.3906C11.3133 1.96875 11.25 2.03203 11.25 2.10938V3.23438H6.75V2.10938C6.75 2.03203 6.68672 1.96875 6.60938 1.96875H5.625C5.54766 1.96875 5.48438 2.03203 5.48438 2.10938V3.23438H2.53125C2.22012 3.23438 1.96875 3.48574 1.96875 3.79688V15.4688C1.96875 15.7799 2.22012 16.0312 2.53125 16.0312H15.4688C15.7799 16.0312 16.0312 15.7799 16.0312 15.4688V3.79688C16.0312 3.48574 15.7799 3.23438 15.4688 3.23438ZM14.7656 14.7656H3.23438V8.08594H14.7656V14.7656ZM14.7656 6.89062H3.23438V4.5H5.48438V5.34375C5.48438 5.42109 5.54766 5.48438 5.625 5.48438H6.60938C6.68672 5.48438 6.75 5.42109 6.75 5.34375V4.5H11.25V5.34375C11.25 5.42109 11.3133 5.48438 11.3906 5.48438H12.375C12.4523 5.48438 12.5156 5.42109 12.5156 5.34375V4.5H14.7656V6.89062Z"
                        fill="#4E658A"
                      />
                    </svg>

                    <Text ml={1} mr={3} fontWeight={300} fontSize={'14px'}>
                      {futureVisitMeno.localDate}
                    </Text>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.515 1.019C8.34363 1.00635 8.17184 1.00001 8 1V0C8.19654 9.58276e-05 8.393 0.00743414 8.589 0.022L8.515 1.019ZM10.519 1.469C10.1984 1.3453 9.86922 1.24537 9.534 1.17L9.753 0.194C10.136 0.28 10.513 0.394 10.879 0.536L10.519 1.469ZM11.889 2.179C11.746 2.08365 11.5996 1.99359 11.45 1.909L11.943 1.039C12.2849 1.23274 12.6121 1.45132 12.922 1.693L12.307 2.482C12.1714 2.37623 12.032 2.2755 11.889 2.18V2.179ZM13.723 3.969C13.5252 3.68798 13.3069 3.42192 13.07 3.173L13.794 2.483C14.064 2.768 14.314 3.073 14.541 3.393L13.723 3.969ZM14.467 5.321C14.4014 5.16246 14.33 5.00636 14.253 4.853L15.146 4.403C15.3226 4.75409 15.473 5.11774 15.596 5.491L14.646 5.804C14.5923 5.64087 14.5326 5.47976 14.467 5.321ZM14.997 7.828C14.9889 7.48434 14.9555 7.14174 14.897 6.803L15.882 6.633C15.949 7.019 15.988 7.411 15.998 7.803L14.998 7.828H14.997ZM14.866 9.366C14.899 9.196 14.926 9.027 14.947 8.856L15.94 8.979C15.892 9.36915 15.8151 9.75521 15.71 10.134L14.746 9.867C14.792 9.702 14.832 9.535 14.866 9.366ZM13.914 11.745C14.098 11.455 14.26 11.151 14.4 10.837L15.314 11.242C15.154 11.602 14.969 11.948 14.759 12.28L13.914 11.745ZM12.95 12.95C13.072 12.828 13.189 12.702 13.3 12.572L14.058 13.225C13.9296 13.3738 13.7959 13.5179 13.657 13.657L12.95 12.95Z"
                        fill="#4E658A"
                      />
                      <path
                        d="M8.00019 1C6.84907 1.00008 5.71574 1.28405 4.70057 1.82674C3.68541 2.36943 2.81974 3.1541 2.18025 4.11125C1.54076 5.0684 1.14719 6.16848 1.03439 7.31406C0.921589 8.45964 1.09304 9.61536 1.53357 10.6789C1.9741 11.7423 2.6701 12.6808 3.55992 13.4111C4.44974 14.1413 5.50593 14.6409 6.63493 14.8655C7.76393 15.0901 8.93089 15.0328 10.0325 14.6987C11.134 14.3645 12.1362 13.7639 12.9502 12.95L13.6572 13.657C12.7269 14.5878 11.5814 15.2747 10.3222 15.657C9.06302 16.0393 7.72896 16.105 6.43826 15.8485C5.14755 15.5919 3.94005 15.021 2.92274 14.1862C1.90543 13.3515 1.10973 12.2787 0.606129 11.0629C0.102528 9.84711 -0.0934196 8.52588 0.0356482 7.21627C0.164716 5.90666 0.614814 4.6491 1.34606 3.55502C2.07731 2.46094 3.06713 1.56411 4.22782 0.944003C5.38851 0.323895 6.68424 -0.000348732 8.00019 2.81458e-07V1Z"
                        fill="#4E658A"
                      />
                      <path
                        d="M7.5 3C7.63261 3 7.75979 3.05268 7.85355 3.14645C7.94732 3.24021 8 3.36739 8 3.5V8.71L11.248 10.566C11.3598 10.6334 11.4408 10.7419 11.4736 10.8683C11.5065 10.9946 11.4886 11.1288 11.4238 11.2422C11.3591 11.3556 11.2525 11.4391 11.127 11.4749C11.0014 11.5108 10.8669 11.4961 10.752 11.434L7.252 9.434C7.17547 9.39029 7.11186 9.32712 7.06761 9.25091C7.02335 9.17469 7.00003 9.08813 7 9V3.5C7 3.36739 7.05268 3.24021 7.14645 3.14645C7.24021 3.05268 7.36739 3 7.5 3Z"
                        fill="#4E658A"
                      />
                    </svg>
                    <Text ml={1} fontWeight={300} fontSize={'14px'}>
                      {futureVisitMeno.localTime}
                    </Text>
                    <Text ml={1} fontWeight={300} fontSize={'14px'}>
                      {futureVisitMeno.tzAbbr}
                    </Text>
                  </Flex>

                  {!!mustCompleteIntakeMeno ? (
                    <Flex flexDirection={'column'}>
                      <Text fontWeight={300} fontSize={'14px'}>
                        Don’t forget to complete your intake form
                      </Text>
                      <Text color="#1E5BD4" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => history.push('/intake-menopause')} fontSize={'14px'}>{`Complete >>`}</Text>
                    </Flex>
                  ) : null}
                </UpcomingCard>
              )}
            </>
          )}
          {!!futureVisitNutritionist && (
            <>
              <UpcomingCard text={futureVisitNutritionist.provider.displayName} noDescription onClick={() => history.push(`/consultations-nutritionist/${futureVisitNutritionist.id}`)}>
                <AvatarImg style={{ alignSelf: 'center' }}>
                  <img alt="" src={futureVisitNutritionist.provider.picture} />
                </AvatarImg>

                <Flex alignItems={'center'}>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.5156 5.34375C12.5156 5.42109 12.4523 5.48438 12.375 5.48438H11.3906C11.3133 5.48438 11.25 5.42109 11.25 5.34375V4.5H6.75V5.34375C6.75 5.42109 6.68672 5.48438 6.60938 5.48438H5.625C5.54766 5.48438 5.48438 5.42109 5.48438 5.34375V4.5H3.23438V6.89062H14.7656V4.5H12.5156V5.34375Z"
                      fill="#FFE3D8"
                    />
                    <path
                      d="M15.4688 3.23438H12.5156V2.10938C12.5156 2.03203 12.4523 1.96875 12.375 1.96875H11.3906C11.3133 1.96875 11.25 2.03203 11.25 2.10938V3.23438H6.75V2.10938C6.75 2.03203 6.68672 1.96875 6.60938 1.96875H5.625C5.54766 1.96875 5.48438 2.03203 5.48438 2.10938V3.23438H2.53125C2.22012 3.23438 1.96875 3.48574 1.96875 3.79688V15.4688C1.96875 15.7799 2.22012 16.0312 2.53125 16.0312H15.4688C15.7799 16.0312 16.0312 15.7799 16.0312 15.4688V3.79688C16.0312 3.48574 15.7799 3.23438 15.4688 3.23438ZM14.7656 14.7656H3.23438V8.08594H14.7656V14.7656ZM14.7656 6.89062H3.23438V4.5H5.48438V5.34375C5.48438 5.42109 5.54766 5.48438 5.625 5.48438H6.60938C6.68672 5.48438 6.75 5.42109 6.75 5.34375V4.5H11.25V5.34375C11.25 5.42109 11.3133 5.48438 11.3906 5.48438H12.375C12.4523 5.48438 12.5156 5.42109 12.5156 5.34375V4.5H14.7656V6.89062Z"
                      fill="#4E658A"
                    />
                  </svg>

                  <Text ml={1} mr={3} fontWeight={300} fontSize={'14px'}>
                    {futureVisitNutritionist.localDate}
                  </Text>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.515 1.019C8.34363 1.00635 8.17184 1.00001 8 1V0C8.19654 9.58276e-05 8.393 0.00743414 8.589 0.022L8.515 1.019ZM10.519 1.469C10.1984 1.3453 9.86922 1.24537 9.534 1.17L9.753 0.194C10.136 0.28 10.513 0.394 10.879 0.536L10.519 1.469ZM11.889 2.179C11.746 2.08365 11.5996 1.99359 11.45 1.909L11.943 1.039C12.2849 1.23274 12.6121 1.45132 12.922 1.693L12.307 2.482C12.1714 2.37623 12.032 2.2755 11.889 2.18V2.179ZM13.723 3.969C13.5252 3.68798 13.3069 3.42192 13.07 3.173L13.794 2.483C14.064 2.768 14.314 3.073 14.541 3.393L13.723 3.969ZM14.467 5.321C14.4014 5.16246 14.33 5.00636 14.253 4.853L15.146 4.403C15.3226 4.75409 15.473 5.11774 15.596 5.491L14.646 5.804C14.5923 5.64087 14.5326 5.47976 14.467 5.321ZM14.997 7.828C14.9889 7.48434 14.9555 7.14174 14.897 6.803L15.882 6.633C15.949 7.019 15.988 7.411 15.998 7.803L14.998 7.828H14.997ZM14.866 9.366C14.899 9.196 14.926 9.027 14.947 8.856L15.94 8.979C15.892 9.36915 15.8151 9.75521 15.71 10.134L14.746 9.867C14.792 9.702 14.832 9.535 14.866 9.366ZM13.914 11.745C14.098 11.455 14.26 11.151 14.4 10.837L15.314 11.242C15.154 11.602 14.969 11.948 14.759 12.28L13.914 11.745ZM12.95 12.95C13.072 12.828 13.189 12.702 13.3 12.572L14.058 13.225C13.9296 13.3738 13.7959 13.5179 13.657 13.657L12.95 12.95Z"
                      fill="#4E658A"
                    />
                    <path
                      d="M8.00019 1C6.84907 1.00008 5.71574 1.28405 4.70057 1.82674C3.68541 2.36943 2.81974 3.1541 2.18025 4.11125C1.54076 5.0684 1.14719 6.16848 1.03439 7.31406C0.921589 8.45964 1.09304 9.61536 1.53357 10.6789C1.9741 11.7423 2.6701 12.6808 3.55992 13.4111C4.44974 14.1413 5.50593 14.6409 6.63493 14.8655C7.76393 15.0901 8.93089 15.0328 10.0325 14.6987C11.134 14.3645 12.1362 13.7639 12.9502 12.95L13.6572 13.657C12.7269 14.5878 11.5814 15.2747 10.3222 15.657C9.06302 16.0393 7.72896 16.105 6.43826 15.8485C5.14755 15.5919 3.94005 15.021 2.92274 14.1862C1.90543 13.3515 1.10973 12.2787 0.606129 11.0629C0.102528 9.84711 -0.0934196 8.52588 0.0356482 7.21627C0.164716 5.90666 0.614814 4.6491 1.34606 3.55502C2.07731 2.46094 3.06713 1.56411 4.22782 0.944003C5.38851 0.323895 6.68424 -0.000348732 8.00019 2.81458e-07V1Z"
                      fill="#4E658A"
                    />
                    <path
                      d="M7.5 3C7.63261 3 7.75979 3.05268 7.85355 3.14645C7.94732 3.24021 8 3.36739 8 3.5V8.71L11.248 10.566C11.3598 10.6334 11.4408 10.7419 11.4736 10.8683C11.5065 10.9946 11.4886 11.1288 11.4238 11.2422C11.3591 11.3556 11.2525 11.4391 11.127 11.4749C11.0014 11.5108 10.8669 11.4961 10.752 11.434L7.252 9.434C7.17547 9.39029 7.11186 9.32712 7.06761 9.25091C7.02335 9.17469 7.00003 9.08813 7 9V3.5C7 3.36739 7.05268 3.24021 7.14645 3.14645C7.24021 3.05268 7.36739 3 7.5 3Z"
                      fill="#4E658A"
                    />
                  </svg>
                  <Text ml={1} fontWeight={300} fontSize={'14px'}>
                    {futureVisitNutritionist.localTime}
                  </Text>
                  <Text ml={1} fontWeight={300} fontSize={'14px'}>
                    {futureVisitNutritionist.tzAbbr}
                  </Text>
                </Flex>
              </UpcomingCard>
            </>
          )}
          {upcomingLabs && (
            <>
              {upcomingLabs.map((x) => {
                let title = `Thyroid Test`;
                if (x && x.biomarkers && x.biomarkers.length > 0) {
                  if (x.biomarkers.indexOf('rT3') > -1) {
                    title += ` + rT3`;
                  }
                  if (x.biomarkers.indexOf('Vit. D') > -1) {
                    title += ` + Vit. D`;
                  }
                }
                return (
                  <UpcomingCard
                    text={title}
                    noDescription={!labDescription(x)}
                    onClick={() => {
                      history.push(`/labs/${x.id}`);
                    }}
                  >
                    <img alt="" src={ThyroidWithBg} style={{ marginRight: '10px', marginLeft: '-11px' }} />

                    <Flex>
                      <Text fontWeight={300} fontSize={'14px'}>
                        {labSubtitle(x)}
                      </Text>
                    </Flex>

                    <Flex flexDirection={'column'}>
                      <Text fontWeight={300} fontSize={'14px'}>
                        {labDescription(x)}
                      </Text>
                    </Flex>
                  </UpcomingCard>
                );
              })}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default withSession(withRouter(UpcomingVisitLabs));
