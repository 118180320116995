import React, { Fragment } from 'react';
import { Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import moment from 'moment';

import { Check, VideoCamera } from '../icons';
import { Consult30Future, Consult30Today } from './ConsultationFutureWhereby';

const InfoBox = styled(Flex)`
  flex: 1;
  background: ${props => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${props => props.theme.color.darkBlue};
  justify-content: center;
`;

const Icon = styled(Flex)`
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const ConsultationFutureConsult30 = (props) => {
  if (moment(props.appointment.date, 'MM/DD/YYYY').isAfter(moment(), 'day')) {
    return <Consult30Future {...props} />;
  } else {
    return <Consult30Today {...props} />;
  }
};

const ConsultationFuture = props => {

  if(!!props.isWhereby) {
    return (<ConsultationFutureConsult30 {...props} />)
  }
  return (
    <Fragment>

      <Card p={[3, 3, 4]} mb={4}>
        <InfoBox mb={4}>
          <Icon mr={[0, 4, 4]} pt={1}>
            <Check width={40} />
          </Icon>
          <Flex flex={1} flexDirection="column">
            <Heading mb={3} textAlign={['center', 'center', 'left']}>
              Your visit is confirmed
            </Heading>
            <Text fontWeight={700} textAlign={['center', 'center', 'left']}>
              {`${moment(props.appointment.startTime, 'HH:mm').format(
                'h:mma'
              )} on 
                  ${moment(props.appointment.date, 'MM/DD/YYYY').format(
                    'dddd MMMM Do'
                  )}`}
            </Text>
          </Flex>
        </InfoBox>
      </Card>

      <Card p={[3, 3, 4]}>
        <Flex>
          <Icon mr={[0, 4, 4]}>
            <VideoCamera fill="#9BBDF9" width={50} />
          </Icon>
          <Flex flex={1} flexDirection="column">
            <Heading mb={3}>How it works?</Heading>
            <Text mb={3} fontWeight={600} lineHeight={1.6}>
              You will receive an email and a text message 5 minutes before the
              start of your visit.
            </Text>
            <Text lineHeight={1.6}>
              Just click on the link in that email and follow the screens. Your
              provider will see you in no time.
            </Text>
          </Flex>
        </Flex>
      </Card>
    </Fragment>
  );
};

export default ConsultationFuture;
