import React from 'react';
import { Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';

import ItemCollection from '../../components/icons/ItemCollection';


const Icon = styled(Flex)`
  display: none;
  @media screen and (min-width: 52em) {
    display: block;
  }
`;
export const LabStep1 = props => {
  return (
    <Flex flexDirection={['column', 'row']} flex={1}>
      <Icon
        mr={[0, 4]}
        mb={[3, 0]}
        alignItems="flex-start"
        justifyContent="center"
      >
        <ItemCollection width={90} />
      </Icon>

      <Flex flexDirection="column" flex={1}>
        <Heading mb={4}>
            Your kit is now registered
        </Heading>
        <Text lineHeight={1.6}>
          You can now collect your sample and send back your sample to our lab using the return shipping label inside your kit.
        </Text>
      </Flex>
    </Flex>
  );
};
