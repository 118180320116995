import React from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Text, Card } from 'rebass';
import { withApollo, Query, Mutation } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import {
  GENERATE_REPORT_STRIPE_CONSOLIDATION,
  ADMIN_REPORTS_STATUS,
  GENERATE_ADMIN_REPORT,
  GET_ADMIN_REPORTS_DATA,
  RESET_ADMIN_REPORT,
} from '../graphql';
import moment from 'moment';

const Container = styled(Box)`
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
`;
const ReportRow = styled(Flex)`
  margin-left: 18px;
  align-items: center;
  margin-bottom: 12px;
  background: white;
  padding: 4px 8px;
  &:hover {
    background: #fafafa;
  }
`;
class AdminReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  getAdminReportData = async id => {
    return;
    const res = await this.props.client.query({
      query: GET_ADMIN_REPORTS_DATA,
      variables: {
        id,
      },
    });

    console.log(res);
  };
  render() {
    return (
      <div style={{ width: '100%', overflowY: 'auto' }}>
        <Container>
          <Flex
            flex={1}
            textAlign="center"
            alignSelf="center"
            justifyContent="center"
          >
            <Text
              fontSize="38px"
              fontWeight={600}
              textAlign="center"
              mb={3}
              style={{ fontFamily: 'Playfair Display' }}
              color="#344f79"
            >
              Report Generation
            </Text>
          </Flex>
          <Query query={ADMIN_REPORTS_STATUS} pollInterval={5000}>
            {({ data, loading }) => {
              console.log('data', data);
              if (loading) {
                return <div />;
              }

              const distinctCategories = [
                ...new Set(data.getAdminReportStatus.map(x => x.category)),
              ];

              return distinctCategories
                .sort((a, b) => {
                  if (a < b) return -1;
                  if (a > b) return 1;

                  return 0;
                })
                .map(category => {
                  const distinctSubCategories = [
                    ...new Set(
                      data.getAdminReportStatus
                        .filter(x => x.category === category)
                        .map(x => x.subCategory)
                    ),
                  ];

                  console.log('distinctSubCategories', distinctSubCategories);

                  return (
                    <React.Fragment>
                      <Card p={3} mb={3}>
                        <Text
                          fontSize="24px"
                          fontWeight={500}
                          textAlign="left"
                          mb={3}
                          color="#344f79"
                        >
                          {category}
                        </Text>

                        {distinctSubCategories
                          .sort((a, b) => {
                            if (a < b) return -1;
                            if (a > b) return 1;

                            return 0;
                          })
                          .map(subCategory => {
                            const catReports = data.getAdminReportStatus.filter(
                              x =>
                                x.category === category &&
                                x.subCategory === subCategory
                            );
                            return (
                              <>
                                <Text
                                  fontSize="20px"
                                  fontWeight={500}
                                  textAlign="left"
                                  mb="0px"
                                  ml="18px"
                                  color="#344f79"
                                >
                                  {subCategory}
                                </Text>
                                {catReports.map(x => {
                                  return (
                                    <Mutation mutation={RESET_ADMIN_REPORT}>
                                      {cancelMutate => {
                                        return (
                                          <Mutation
                                            mutation={GENERATE_ADMIN_REPORT}
                                          >
                                            {(generateMutate, { loading }) => {
                                              return (
                                                <ReportRow
                                                  id={`idReport-${x.id}`}
                                                >
                                                  {/* {x.canViewData ? (
                                              <div
                                                style={{ marginRight: '8px' }}
                                                onClick={()=>{
                                                  this.setState({
                                                    [`isExanded-${x.id}`]: !this.state[`isExanded-${x.id}`]
                                                  })
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  size="1x"
                                                  color="black"
                                                  icon={this.state[`isExanded-${x.id}`]?faChevronUp:faChevronRight}
                                                />
                                              </div>
                                            ) : (
                                              <div />
                                            )} */}

                                                  <Text
                                                    flex={1}
                                                    style={{
                                                      cursor:
                                                        x.status === 'COMPLETE'
                                                          ? 'pointer'
                                                          : 'not-allowed',
                                                    }}
                                                    onClick={() => {
                                                      if (
                                                        x.link &&
                                                        window &&
                                                        x.status === 'COMPLETE'
                                                      ) {
                                                        window.open(
                                                          x.link,
                                                          '_blank'
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    {x.friendlyName}
                                                  </Text>
                                                  <Text
                                                    onClick={() =>
                                                      this.getAdminReportData(
                                                        x.id
                                                      )
                                                    }
                                                    style={{
                                                      paddingRight: '12px',
                                                    }}
                                                  >
                                                    {x.status === 'PROCESSING'
                                                      ? `started on ${moment(
                                                          x.processStartedAt
                                                        ).format(
                                                          'MM-DD-YYYY hh:mma'
                                                        )}`
                                                      : `last processed on ${moment(
                                                          x.lastProcessed
                                                        ).format(
                                                          'MM-DD-YYYY hh:mma'
                                                        )}`}
                                                  </Text>
                                                  <Button
                                                    variant="primary"
                                                    disabled={
                                                      loading ||
                                                      x.status === 'PROCESSING'
                                                    }
                                                    onClick={() => {
                                                      if (
                                                        x.constantName ===
                                                        'ATHENA_RETENTION_PATIENTS'
                                                      ) {
                                                        var start = window.prompt(
                                                          'Start Date',
                                                          moment()
                                                            .add(-7, 'days')
                                                            .format(
                                                              'MM/DD/YYYY'
                                                            )
                                                        );
                                                        var end = window.prompt(
                                                          'End Date',
                                                          moment().format(
                                                            'MM/DD/YYYY'
                                                          )
                                                        );

                                                        if (!start || !end)
                                                          return;

                                                        generateMutate({
                                                          variables: {
                                                            id: x.id,
                                                            startDate: start,
                                                            endDate: end,
                                                          },
                                                          refetchQueries: [
                                                            {
                                                              query: ADMIN_REPORTS_STATUS,
                                                            },
                                                          ],
                                                          awaitRefetchQueries: true,
                                                        });
                                                      } else {
                                                        generateMutate({
                                                          variables: {
                                                            id: x.id,
                                                          },
                                                          refetchQueries: [
                                                            {
                                                              query: ADMIN_REPORTS_STATUS,
                                                            },
                                                          ],
                                                          awaitRefetchQueries: true,
                                                        });
                                                      }
                                                    }}
                                                  >
                                                    {loading ||
                                                    x.status === 'PROCESSING'
                                                      ? 'Generating'
                                                      : x.status === 'ERROR'
                                                        ? 'Errored'
                                                        : 'Generate'}
                                                  </Button>
                                                  <Button
                                                    variant="primary"
                                                    style={{
                                                      opacity: 0,
                                                      width: '15px',
                                                      maxWidth: '15px',
                                                    }}
                                                    onClick={() => {
                                                      cancelMutate({
                                                        variables: {
                                                          id: x.id,
                                                        },
                                                        refetchQueries: [
                                                          {
                                                            query: ADMIN_REPORTS_STATUS,
                                                          },
                                                        ],
                                                        awaitRefetchQueries: true,
                                                      });
                                                    }}
                                                  >
                                                    Reset
                                                  </Button>
                                                </ReportRow>
                                              );
                                            }}
                                          </Mutation>
                                        );
                                      }}
                                    </Mutation>
                                  );
                                })}
                              </>
                            );
                          })}
                      </Card>
                    </React.Fragment>
                  );
                });
            }}
          </Query>
        </Container>
      </div>
    );
  }
}

export default withApollo(AdminReports);
