import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from 'rebass';
import Modal from 'styled-react-modal';
import { SpecialModalBackground } from '../../adminReorderPhysicianConvo/shared';
import { RangeInputEdit } from './controls/RangeInputEdit';
import { CheckBoxesCtrl } from './controls/CheckBoxesCtrl';
import { CheckBoxesEdit } from './controls/CheckBoxesEdit';
import { TextInputEdit } from './controls/TextInputEdit';
import { TextAreaCtrl } from './controls/TextAreaCtrl';
import { TextAreaEdit } from './controls/TextAreaEdit';
import { HeadingCtrl } from './controls/HeadingCtrl';
import { HeadingEdit } from './controls/HeadingEdit';
import { LabelCtrl } from './controls/LabelCtrl';
import { LabelEdit } from './controls/LabelEdit';
import { ParagraphCtrl } from './controls/ParagraphCtrl';
import { ParagraphEdit } from './controls/ParagraphEdit';
import { RatingCtrl } from './controls/RatingCtrl';
import { RatingEdit } from './controls/RatingEdit';
import { RangeCtrl } from './controls/RangeCtrl';
import { TextInputCtrl } from './controls/TextInputCtrl';
import { DropDownEdit } from './controls/DropDownEdit';
import { DropdownCtrl } from './controls/DropdownCtrl';
import { LineBreakCtrl } from './controls/LineBreakCtrl';
import { getIsInError } from './helpers/ctrlValidation';
import styled from 'styled-components';

const Errormarker = styled.i`
  display: inline-block;
  position: relative;
`;
export const Item = ({ treeItem, saveProperties, onDelete }) => {
  const [editBarVisible, setEditBarVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isInError, setIsInError] = useState(getIsInError(treeItem));
  useEffect(
    () => {
      setIsInError(getIsInError(treeItem));
    },
    [treeItem]
  );
  return (
    <Flex
      flexDirection={'column'}
      width={'100%'}
      flex={1}
      style={{
        position: 'relative',
        padding: treeItem.key === 'Header' || treeItem.key === 'Label' || treeItem.key === 'LineBreak' ? (isInError ? '28px 12px' : '28px 0') : isInError ? '28px 12px 28px 32px' : '28px 0 28px 32px',
        borderBottom: isInError ? '1px solid #b13c3c' : '1px solid #e8e8e8',
        border: isInError ? '1px solid #b13c3c' : '',
      }}
    >
      <Flex width={'100%'} onMouseEnter={() => setEditBarVisible(true)} onMouseLeave={() => setEditBarVisible(false)}>
        {isInError &&
          !!isInError.error && (
            <>
              <Errormarker className="fas fa-exclamation-triangle" style={{ color: '#ff9800', marginRight: '12px', alignSelf: 'center' }} />
            </>
          )}
        {treeItem.key === 'TextInput' || treeItem.key === 'NumberInput' || treeItem.key === 'Email' || treeItem.key === 'Date' ? <TextInputCtrl properties={treeItem.params || {}} /> : null}
        {treeItem.key === 'Range' ? <RangeCtrl properties={treeItem.params || {}} /> : null}
        {treeItem.key === 'Header' ? <HeadingCtrl properties={treeItem.params || {}} /> : null}
        {treeItem.key === 'TextArea' ? <TextAreaCtrl properties={treeItem.params || {}} /> : null}
        {treeItem.key === 'Dropdown' ? <DropdownCtrl properties={treeItem.params || {}} /> : null}
        {treeItem.key === 'Label' ? <LabelCtrl properties={treeItem.params || {}} /> : null}
        {treeItem.key === 'LineBreak' ? <LineBreakCtrl properties={treeItem.params || {}} /> : null}
        {treeItem.key === 'Rating' ? <RatingCtrl properties={treeItem.params || {}} /> : null}
        {treeItem.key === 'Paragraph' ? <ParagraphCtrl properties={treeItem.params || {}} /> : null}
        {treeItem.key === 'Checkboxes' ? <CheckBoxesCtrl properties={treeItem.params || {}} /> : null}
        {}
        <Flex flex={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, width: '100%', height: '28px', paddingLeft: treeItem.key === 'Header' ? '0' : '32px' }} alignItems={'center'}>
          {(treeItem.params || {}).mustBeInherited && (
            <>
              <Box mr={3} style={{ background: '#d78b01', borderRadius: '8px', padding: '2px 8px', width: 'auto' }}>
                <Text fontSize={'12px'} color="white">
                  Must be inherited
                </Text>
              </Box>
            </>
          )}
          {(treeItem.params || {}).ctrlProps.required && (
            <>
              <Box mr={3} style={{ background: '#ba6262', borderRadius: '8px', padding: '2px 8px', width: 'auto' }}>
                <Text fontSize={'12px'} color="white">
                  Required
                </Text>
              </Box>
            </>
          )}
          {editBarVisible && (
            <Flex flex={1} alignItems={'center'}>
              <Flex flex={1} justifyContent={'flex-start'}>
                <Box style={{ background: '#6c757d', borderRadius: '8px', padding: '2px 8px' }}>
                  <Text fontSize={'12px'} color="white">
                    {treeItem.name}
                  </Text>
                </Box>
              </Flex>
              {treeItem.key !== 'LineBreak' && (
                <Box style={{ padding: '2px 8px', cursor: 'pointer' }} onClick={() => setIsEdit(true)}>
                  <i className="fa fa-edit" />
                </Box>
              )}
              <Box style={{ padding: '2px 8px', cursor: 'pointer' }} ml={2} onClick={onDelete}>
                <i className="fa fa-trash" />
              </Box>
            </Flex>
          )}
        </Flex>
        {isEdit && (
          <Modal
            isOpen={true}
            onBackgroundClick={() => {
              setIsEdit(false);
            }}
            onEscapeKeydown={() => {
              setIsEdit(false);
            }}
          >
            <SpecialModalBackground>
              <Box
                style={{
                  margin: '0 auto',
                  alignSelf: 'center',
                  borderRadius: '20px',
                  position: 'relative',
                  backgroundColor: 'white',
                  padding: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  maxWidth: '95vw',
                  minWidth: 'initial',
                  maxHeight: '95vh',
                }}
              >
                <>
                  {(treeItem.key === 'TextInput' || treeItem.key === 'NumberInput' || treeItem.key === 'Date' || treeItem.key === 'Email') && (
                    <TextInputEdit
                      properties={{ ...(treeItem.params || {}) }}
                      close={() => {
                        setIsEdit(false);
                      }}
                      save={(p) => {
                        saveProperties(p);
                        setIsEdit(false);
                      }}
                    />
                  )}
                  {treeItem.key === 'Range' && (
                    <RangeInputEdit
                      properties={{ ...(treeItem.params || {}) }}
                      close={() => {
                        setIsEdit(false);
                      }}
                      save={(p) => {
                        saveProperties(p);
                        setIsEdit(false);
                      }}
                    />
                  )}
                  {treeItem.key === 'Checkboxes' && (
                    <CheckBoxesEdit
                      properties={{ ...(treeItem.params || {}) }}
                      close={() => {
                        setIsEdit(false);
                      }}
                      save={(p) => {
                        saveProperties(p);
                        setIsEdit(false);
                      }}
                    />
                  )}
                  {treeItem.key === 'Paragraph' && (
                    <ParagraphEdit
                      properties={{ ...(treeItem.params || {}) }}
                      close={() => {
                        setIsEdit(false);
                      }}
                      save={(p) => {
                        saveProperties(p);
                        setIsEdit(false);
                      }}
                    />
                  )}
                  {treeItem.key === 'Header' && (
                    <HeadingEdit
                      properties={{ ...(treeItem.params || {}) }}
                      close={() => {
                        setIsEdit(false);
                      }}
                      save={(p) => {
                        saveProperties(p);
                        setIsEdit(false);
                      }}
                    />
                  )}
                  {treeItem.key === 'Label' && (
                    <LabelEdit
                      properties={{ ...(treeItem.params || {}) }}
                      close={() => {
                        setIsEdit(false);
                      }}
                      save={(p) => {
                        saveProperties(p);
                        setIsEdit(false);
                      }}
                    />
                  )}
                  {treeItem.key === 'TextArea' && (
                    <TextAreaEdit
                      properties={{ ...(treeItem.params || {}) }}
                      close={() => {
                        setIsEdit(false);
                      }}
                      save={(p) => {
                        saveProperties(p);
                        setIsEdit(false);
                      }}
                    />
                  )}
                  {treeItem.key === 'Dropdown' && (
                    <DropDownEdit
                      properties={{ ...(treeItem.params || {}) }}
                      close={() => {
                        setIsEdit(false);
                      }}
                      save={(p) => {
                        saveProperties(p);
                        setIsEdit(false);
                      }}
                    />
                  )}
                  {treeItem.key === 'Rating' && (
                    <RatingEdit
                      properties={{ ...(treeItem.params || {}) }}
                      close={() => {
                        setIsEdit(false);
                      }}
                      save={(p) => {
                        saveProperties(p);
                        setIsEdit(false);
                      }}
                    />
                  )}
                </>
              </Box>
            </SpecialModalBackground>
          </Modal>
        )}
      </Flex>
      {isInError &&
        !!isInError.error &&
        !!editBarVisible && (
          <Flex style={{ background: '#ff9800', marginTop: '12px', padding: '12px 24px' }} flexDirection={'column'}>
            <Text style={{ color: 'white' }}>Errors found:</Text>
            {isInError.errors.map((x) => {
              return <Text style={{ color: 'white' }}>- {x}</Text>;
            })}
          </Flex>
        )}
    </Flex>
  );
};
