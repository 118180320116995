import React from 'react';
import { Button, Flex, Heading, Text } from 'rebass';
import * as Yup from 'yup';
import PleaseWait from '../../../components/PleaseWait';
import { Mutation, Query } from 'react-apollo';
import { GET_HEALTHCOACH_FORM_TARGETS } from '../../../graphql';
import { Form, Formik } from 'formik';
import FormField from '../../../components/FormField';
import TextInput, { TextInputWithLabel } from '../../../components/TextInput';
import { EditModalButtons } from '../builder/Builder';
import gql from 'graphql-tag';
import { withToastManager } from 'react-toast-notifications';

const UPSERT_FORM_TARGET = gql`
  mutation upsertHealthCoachFormTargets($id: String, $target: String!, $canHaveMultiple: Boolean!, $isActive: Boolean!, $isDeprecated: Boolean!, $isDeleted: Boolean!) {
    upsertHealthCoachFormTargets(id: $id, target: $target, canHaveMultiple: $canHaveMultiple, isActive: $isActive, isDeprecated: $isDeprecated, isDeleted: $isDeleted) {
      ok
    }
  }
`;

const schema = Yup.object().shape({
  target: Yup.string()
    .trim()
    .min(2, 'Target name must be greater than 2 characters')
    .max(50, 'Target name must be lower than 51 characters')
    .required('Required'),
});

const FormTargetsAddEdit = ({ editItem, close, toastManager }) => {
  return (
    <Flex flexDirection={'column'} mb={4}>
      <Flex mb={3}>
        <Heading>{editItem && editItem.id ? 'Edit' : 'Add'} Target</Heading>
      </Flex>

      <Mutation
        mutation={UPSERT_FORM_TARGET}
        refetchQueries={[{ query: GET_HEALTHCOACH_FORM_TARGETS }]}
        awaitRefetchQueries={true}
        onCompleted={(e) => {
          if (!!e.upsertHealthCoachFormTargets.ok) {
            toastManager.add(`Form target ${editItem && editItem.id ? 'edited' : 'added'} successfully`, {
              appearance: 'success',
              autoDismissTimeout: 10000,
            });
            close();
          } else {
            toastManager.add('An error occured', {
              appearance: 'error',
              autoDismissTimeout: 10000,
            });
          }
        }}
        onError={() => {
          toastManager.add('An error occured', {
            appearance: 'error',
            autoDismissTimeout: 10000,
          });
        }}
      >
        {(mutate, { loading }) => {
          if (!!loading) {
            return <PleaseWait />;
          }

          return (
            <Formik
              initialValues={
                editItem
                  ? {
                      ...editItem,
                    }
                  : {
                      target: '',
                      canHaveMultiple: false,
                      isActive: false,
                      isDeprecated: false,
                      isDeleted: false,
                    }
              }
              isInitialValid={() => {
                return editItem && editItem.target;
              }}
              validationSchema={schema}
              onSubmit={(values, actions) =>
                mutate({
                  variables: {
                    ...values,
                  },
                })
              }
              render={({ touched, errors, isValid, values, submitForm }) => (
                <Form>
                  <FormField mb={3} error={touched.target && errors.target}>
                    <TextInputWithLabel placeholder="Target Name *" name="target" id="input_target" />
                  </FormField>
                  <FormField
                    flexDirection="row"
                    mb={3}
                    style={{
                      alignContent: 'center',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                    error={touched.canHaveMultiple && errors.canHaveMultiple}
                  >
                    <TextInput type="checkbox" checked={values.canHaveMultiple} id={`canHaveMultiple`} name="canHaveMultiple" />
                    <label htmlFor="canHaveMultiple" style={{ marginLeft: '16px' }}>
                      Health coach can have multiple forms for this target
                    </label>
                  </FormField>
                  <FormField
                    flexDirection="row"
                    mb={3}
                    style={{
                      alignContent: 'center',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                    error={touched.isActive && errors.isActive}
                  >
                    <TextInput type="checkbox" checked={values.isActive} id={`isActive`} name="isActive" />
                    <label htmlFor="isActive" style={{ marginLeft: '16px' }}>
                      Active (Target will be selectable for templates and forms creation)
                    </label>
                  </FormField>
                  <FormField
                    flexDirection="row"
                    mb={3}
                    style={{
                      alignContent: 'center',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                    error={touched.isDeprecated && errors.isDeprecated}
                  >
                    <TextInput type="checkbox" checked={values.isDeprecated} id={`isDeprecated`} name="isDeprecated" />
                    <label htmlFor="isDeprecated" style={{ marginLeft: '16px' }}>
                      Deprecated (Target is useable but not advised)
                    </label>
                  </FormField>
                  <FormField
                    flexDirection="row"
                    mb={3}
                    style={{
                      alignContent: 'center',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                    error={touched.isDeleted && errors.isDeleted}
                  >
                    <TextInput type="checkbox" checked={values.isDeleted} id={`isDeleted`} name="isDeleted" />
                    <label htmlFor="isDeleted" style={{ marginLeft: '16px' }}>
                      Marked as deleted. Won't be able to be used for future forms or template
                    </label>
                  </FormField>

                  <EditModalButtons close={close} save={submitForm} isValid={isValid} />
                </Form>
              )}
            />
          );
        }}
      </Mutation>
    </Flex>
  );
};

export default withToastManager(FormTargetsAddEdit);
