import React from 'react';
import { Flex, Box, Heading, Button } from 'rebass';
import { Query, Mutation } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';

import {
  ADMIN_GET_WEBFLOW_ARTICLES,
  ADMIN_TRIGGER_WEBFLOW_ARTICLE_UPDATE,
} from '../graphql';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;

const articleColumns = [
  {
    Header: 'title',
    accessor: 'title',
  },
  {
    Header: 'tag',
    accessor: 'tag',
    Cell: row => {
      if (!row.original.tag) return null;
      return (
        <div >
          {row.original.tag.name}
        </div>
      );
    },
  },
  {
    Header: 'url',
    accessor: 'slug',
  },
  {
    Header: 'author',
    accessor: 'author',
  },
  {
    Header: 'draft',
    accessor: 'draft',
    Cell: row => {
      return <div>{row.original.draft ? '✓' : ''}</div>;
    },
  },
  {
    Header: 'archived',
    accessor: 'archived',
    Cell: row => {
      return <div>{row.original.archived ? '✓' : ''}</div>;
    },
  },
  {
    Header: 'webflow Id',
    accessor: 'webflowId'
  },
  {
    Header: 'featured',
    accessor: 'archived',
    Cell: row => {
      return <div>{row.original.featured ? '✓' : ''}</div>;
    },
  },
  {
    Header: 'week',
    accessor: 'week',
    Cell: row => {
      let ws = [];
      if (row.original.week1) ws.push('1');
      if (row.original.week2) ws.push('2');
      if (row.original.week3) ws.push('3');
      if (row.original.week4) ws.push('4');
      if (row.original.week5) ws.push('5');
      if (row.original.week6) ws.push('6');
      if (row.original.week7) ws.push('7');
      if (row.original.week8) ws.push('8');
      if (row.original.week9) ws.push('9');
      if (row.original.week10) ws.push('10');
      if (row.original.week11) ws.push('11');
      if (row.original.week12) ws.push('12');
      return <div>{ws.join(', ')}</div>;
    },
  },
  {
    Header: 'categories',
    accessor: 'categories',
    Cell: row => {
      if (!row.original.categories) return null;
      return (
        <div style={{ flexWrap: 'wrap' }}>
          {row.original.categories.map(x => {
            return <div key={x.id}>{x.name}</div>
          })}
        </div>
      );
    },
  },
];

class MobileWebflowArticles extends React.Component {
  render() {
    return (
      <Container>
        <Heading>WEBFLOW ARTICLES</Heading>
        <Flex justifyContent="flex-end">
        <Mutation
          mutation={ADMIN_TRIGGER_WEBFLOW_ARTICLE_UPDATE}
          refetchQueries={[{query: ADMIN_GET_WEBFLOW_ARTICLES}]}
          awaitRefetchQueries={true}
        >
          {(up, { loading }) => (
            <Button style={{marginTop: '24px', marginBottom: '24px'}} variant="primary" type="button" disabled={loading} onClick={up}>{loading?'Updating...':'Update From Webflow'}</Button>
          )}
        </Mutation>
        </Flex>
        <Query query={ADMIN_GET_WEBFLOW_ARTICLES}>
          {({ data, loading, error }) => {
            if (loading || error) return <div />;
            console.log('GET_MOBILE_SYMPTOM_TRACKER_CATEGORIES', data);
            return (
              <Box>
                <ReactTable
                  data={data.adminGetArticlesList}
                  columns={articleColumns}
                  defaultPageSize={
                    data.adminGetArticlesList &&
                    data.adminGetArticlesList.length > 3
                      ? Math.min(data.adminGetArticlesList.length, 25)
                      : 3
                  }
                  showPagination={
                    data.adminGetArticlesList &&
                    data.adminGetArticlesList.length > 24
                  }
                />
              </Box>
            );
          }}
        </Query>
      </Container>
    );
  }
}

export default MobileWebflowArticles;
