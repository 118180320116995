import React, { Fragment, useState } from 'react';
import { Button, Flex } from 'rebass';

import { Formik, Form } from 'formik';
import { injectStripe } from 'react-stripe-elements';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import * as Yup from 'yup';

import { StripeInput } from '../../../components/StripeInput';
import { FormField } from '../../../components/FormField';
import { CURRENT_USER, UPDATE_CARD } from '../../../graphql';
import InformationSecuredDisclaimer from '../../../components/InformationSecuredDisclaimer';

const schema = Yup.object().shape({
  number: Yup.string().required('Required'),
  cvc: Yup.string().required('Required'),
  expire: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
});

const KitsContent = ({ stripe, client, toastManager, onSubmit }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const createSource = async actions => {
    try {
      const { source } = await stripe.createSource({ type: 'card' });

      await client.mutate({
        mutation: UPDATE_CARD,
        variables: {
          source: source.id,
        },
        refetchQueries: [{ query: CURRENT_USER }],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      actions.setSubmitting(false);
      toastManager.add('There was an error with the payment method.', {
        appearance: 'error',
      });
    }
  };

  return (
    <Fragment>
      <Flex flexDirection="column" flex={1} mt={4}>
        <Formik
          initialValues={{ number: '', cvc: '', expire: '', zip: '' }}
          validationSchema={schema}
          onSubmit={async (values, actions) => {
            setIsProcessing(true);
            await createSource(actions);
            await onSubmit();
            setIsProcessing(false);
          }}
          render={({
            errors,
            touched,
            isValid,
            isSubmitting,
            setSubmitting,
          }) => (
            <Form>
              <Flex flexDirection="column">
                <FormField error={touched.number && errors.number} mb={3}>
                  <StripeInput type="number" name="number" />
                </FormField>

                <Flex flexDirection={['column', 'column', 'row']} mb={4}>
                  <FormField
                    width={[1, 1, 1 / 3]}
                    mr={[0, 0, 3]}
                    error={touched.expire && errors.expire}
                  >
                    <StripeInput type="expire" name="expire" />
                  </FormField>

                  <FormField
                    width={[1, 1, 1 / 3]}
                    mr={[0, 0, 3]}
                    error={touched.cvc && errors.cvc}
                  >
                    <StripeInput type="cvc" name="cvc" />
                  </FormField>

                  <FormField
                    width={[1, 1, 1 / 3]}
                    error={touched.zip && errors.zip}
                  >
                    <StripeInput type="zip" name="zip" />
                  </FormField>
                </Flex>
              </Flex>

              <Flex alignItems="center" flexDirection="column">
                <Button
                  disabled={!isValid || isSubmitting || isProcessing}
                  variant="pink"
                  width={[1 / 2, 1 / 3]}
                  type="submit"
                >
                  Update
                </Button>
              </Flex>

              <InformationSecuredDisclaimer />
            </Form>
          )}
        />
      </Flex>
    </Fragment>
  );
};

export default injectStripe(
  compose(
    withApollo,
    withToastManager
  )(KitsContent)
);
