import React, { useState } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../../components/FullLayout';
import Logo, { LogoHeader } from '../../components/Logo';
import { CARE_MANAGER_ONBOARDING_CALL_BOOKED, CURRENT_USER } from '../../graphql';
import withAnalytics from '../../lib/withAnalytics';
import withSession from '../../lib/withSession';
import britCare from '../../static/Brittany.jpeg';
import { isCalendlyEvent } from '../../utils/calendly';
import { useMount } from '../../utils/hooks/lifecycle';
import useUnmount from '../../utils/hooks/lifecycle/useUnmount';

const Container = styled(Box)`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
  align-content: center;
  text-align: center;
  z-index: 11;
`;

// const ImageWrapper = styled.div`
//   position: relative;
//   width: 100px;
//   height: 100px;
//   overflow: hidden;
//   border-radius: 50%;
//   margin: 0 auto;
// `;

// const Img = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// `;

const LogoContainer = styled(Box)`
  max-width: 200px;
  margin: 0 auto 32px auto;
`;

const Header = styled(Flex)`
  height: 80px;
  max-height: 80px;
  background: #fdf5f2;
  width: 100%;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.08);
  z-index: 10;
  max-width: 100vw;
`;

const HeaderSub = styled(Flex)`
  max-width: 100vw;
  width: 100%;
  padding-left: 0;
  @media screen and (min-width: 52em) {
    padding-left: 30px;
  }
`;

const HeaderContent = styled(Flex)`
  height: 80px;
  max-height: 80px;
  width: 100vw;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: -40px;
  @media screen and (max-width: 52em) {
    margin-top: 12px;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ScheduleCareManager = ({ session, history, analytics, client }) => {
  const { email, firstName, lastName, dedicatedCareManager, mustScheduleOnboardingCall } = session;
  const [canContinue, setCanContinue] = useState(false);
  const name = encodeURI(`${firstName} ${lastName}`);
  const scriptLoaded = () => {
    // setIsLoaded(true);
  };

  useMount(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    script.onload = () => scriptLoaded();

    document.body.appendChild(script);
    window.addEventListener('message', windowMessageReceived);
  });

  useUnmount(() => {
    var tags = document.getElementsByTagName('script');
    try {
      window.removeEventListener('message', windowMessageReceived);
    } catch (error) {}
    for (var i = tags.length; i >= 0; i--) {
      if (tags[i] && tags[i].getAttribute('src') != null && tags[i].getAttribute('src').indexOf('https://assets.calendly.com/assets/external/widget.js') > -1) tags[i].parentNode.removeChild(tags[i]); //remove element by calling parentNode.removeChild()
    }
  });

  const windowMessageReceived = async (e) => {
    if (isCalendlyEvent(e)) {
      if (e.data && e.data.event === 'calendly.event_scheduled') {
        analytics.track('Book Care Manager call');
        if (client) {
          try {
            await client.mutate({
              mutation: CARE_MANAGER_ONBOARDING_CALL_BOOKED,
              refetchQueries: [{ query: CURRENT_USER }],
            });
          } catch (error) {}
        }
        if (!!history.location && !!history.location.state && !!history.location.state.redirectToBaselineSurvey) {
          history.push('/establish-baseline');
        } else {
          setCanContinue(true);
        }
      }
    } else {
      // console.log('NOT CALENDLY', e);
    }
  };

  return (
    <FullLayout2 style={{ zIndex: 10 }}>
      <Flex flex={1} flexDirection={'column'}>
        <Header flex={1}>
          <HeaderSub>
            <HeaderContent>
              <Flex flex={[1, 1, 0]} px="30px" alignItems="center" justifyContent={['center', 'center', 'flex-start']}>
                <Link to="/">
                  <LogoHeader height="40px" />
                </Link>
              </Flex>
            </HeaderContent>
          </HeaderSub>
        </Header>
        <Container>
          <ImageWrapper>
            <Img src={dedicatedCareManager ? dedicatedCareManager.thumbnail : britCare} />
          </ImageWrapper>
          <Text fontSize="20px" textAlign="center" mb="24px" mt="24px" fontWeight="400">
            Hi, I am {dedicatedCareManager ? dedicatedCareManager.firstName : 'Brittany'}, your dedicated care manager with Paloma Health.
          </Text>
          <Text fontSize="14px" textAlign="center" mb="24px" mt="24px" fontWeight="300">
            I'm here to support you in your thyroid journey and guide you through your experience with Paloma Health.
          </Text>
          <Text fontSize="14px" textAlign="center" mb="24px" mt="24px" fontWeight="300">
            Let's schedule your 15 minutes onboarding call. You can schedule this call before your first provider visit to get prepared or after you establish care to discuss next steps and membership benefits available to you.
          </Text>
          <div
            className="calendly-inline-widget"
            data-url={
              dedicatedCareManager && dedicatedCareManager.calendlyLink
                ? `${dedicatedCareManager.calendlyLink}?hide_event_type_details=1&name=${name}&email=${email}&text_color=344f79&primary_color=f9a394`
                : `https://calendly.com/paloma-onboarding/paloma-membership-onboarding-brittany?hide_event_type_details=1&name=${name}&email=${email}&text_color=344f79&primary_color=f9a394`
            }
            style={{
              minWidth: '320px',
              width: '350px',
              margin: '24px auto',
              height: '600px',
            }}
          />
          {!!canContinue ? (
            <Button
              variant="primary"
              height="48px"
              style={{ margin: '32px auto' }}
              onClick={() => {
                history.push('/');
              }}
            >
              Go to dashboard
            </Button>
          ) : (
            <Text
              onClick={() => {
                analytics.track('Bypass Care Manager call');
                if (!!history.location && !!history.location.state && !!history.location.state.redirectToBaselineSurvey) {
                  history.push('/establish-baseline');
                  return;
                }

                history.push('/');
              }}
              style={{ margin: '32px auto', display: 'block', color: '#44A5FF', textDecorationLine: 'underline', textAlign: 'center', cursor: 'pointer', fontSize: '14px' }}
              fontWeight="600"
            >
              Skip for now
            </Text>
          )}
          {/*  */}

          {/* <Button
            variant="white"
            height="48px"
            style={{ margin: '32px auto', display: 'block' }}
            onClick={() => {
              analytics.track('Bypass Care Manager call');
              history.push('/');
            }}
          >
            Skip for now
          </Button> */}
        </Container>
      </Flex>
    </FullLayout2>
  );
};

export default withRouter(withSession(withAnalytics(withApollo(ScheduleCareManager))));
