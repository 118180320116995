import React, { Component, Fragment } from 'react';
import { Flex, Image, Text } from 'rebass';
import styled from 'styled-components';
import { Route, Link, NavLink as RouterNavLink, Redirect, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import { LogoHeader } from '../components/Logo';
import Sidebar from '../components/SidebarV2';
import Menu from '../components/icons/Menu';
import HeaderAccount from '../components/HeaderAccount';
import Landing from './LandingV2';
import Account from './Account';
import MyAccount from './MyAccount';
import Consultations from './Consultations';
import Pharmacy from './Pharmacy';
import LabCenter from './LabCenter';
import Labs from './Labs';
import Practitioner from './Practitioner';
import Support from './Support';
import withSession from '../lib/withSession';
import withAnalytics from '../lib/withAnalytics';
import UploadLabResultsPage from './UploadLabResults';
import RegisterKit from './RegisterKit';
import RegisteredKit from './registerKit/registeredKit';
import LabScan from './LabScan';
import ViewRequisitionForm from './requisitionForms/viewRequisitionForm';
import ChangeEmail from './ChangeEmail';
import MapLocalAccountToAthenaId from './MapLocalAccountToAthenaId';
import ScheduleNutritionist from './ScheduleNutritionist';
import Schedule from './Schedule2';
import UnregisterKitLocalDb from './UnregisterKitLocalDb';
import ImpersonatingChange from './ImpersonatingChange';
import LoadingScreen from '../components/LoadingScreen';
import { withApollo, Query } from 'react-apollo';
import { CURRENT_USER, ATHENA_CARD, APPOINTMENTS, LABS, GET_USER_PCP, GENERATE_CIRCLE_SO_CALLBACK, GET_LAB_ORDERS_LIST } from '../graphql';
import ScheduleNewPatientComplete from './ScheduleNewPatientComplete';
import MobileCommunicatorPage from './perpetualCommunicator/MobileCommunicatorPage';
import MobileAsyncCommunicatorPage from './perpetualCommunicator/MobileCommunicatorPage';
// import MobileCommunicatorPage from './consultations/MobileCommunicatorPage';
import Div100vh from 'react-div-100vh';
import ResetPatientIntakeStatus from './ResetPatientIntakeStatus';
import registerKitPayment from './registerKit/registerKitPayment';
import ProfilePage from './Profile';
import FeatureTogglesManagement from './FeatureToggles';
import OneTimeCouponsManagement from './OneTimeCoupons';
import { isMobile, MobileOnlyView } from 'react-device-detect';
import AdminReports from './AdminReports';
import LabResultsTrendPage from './labs/labResultsTrendPage';
import Referral from './referral';
import AdminReferralList from './AdminReferralList';
import MobileCourseDesigner from './mobileCourseDesigner';
import MobileSymptomTrackers from './MobileSymptomTrackers';
import BatchBalanceAdjustment from './BatchBalanceAdjustment';
import BatchMapToNewProviderGroup from './batchMapToNewPatientId';
import PendingProfileCompletionUsers from './pendingProfileCompletionUsers';
import MobileCourses from './MobileCourses';
import MobileMotivationQuotes from './MobileMotivationQuotes';
import AdminSetMobileUserWeek from './AdminSetMobileUserWeek';
import MobileWebflowArticles from './MobileWebflowArticles';
import MobileRecipes from './MobileRecipes';
import { MySubscriptions } from './MySubscriptions';
import SubscribedBook from './subscribedLogin/SubscribedBook';
import { CancelMembership } from './mySubscriptions/cancellations/CancelMembership';
import SubscribedCall from './subscribedLogin/SubscribedCall';
import ScheduleCareManager from './scheduleCareManager/ScheduleCareManager';
import BecomeMember from './becomeMember/index';
import SubscriptionUpdated from './SubscriptionUpdated';
import SelectPlanPage from './selectPlanPage';
import { CancelSubscription } from './mySubscriptions/cancellations/CancelSubscription';
import PauseSubscription from './mySubscriptions/cancellations/PauseSubscription';
import MyCareTeam from './MyCareTeam';
import ChatCareTeam from './ChatCareTeam';
import SubscriptionChangesConfirm from './mySubscriptions/SubscriptionChangesConfirm';
import SubscribedPurchase from './subscribedLogin/SubscribedPurchase';
import MyPaymentMethods from './MyPaymentMethods';
import { isToggleActive } from '../components/featureToggle/toggles';
import MyCare from './MyCare';
import RegisterKitAdditionalBiomarkers from './registerKit/RegisterKitAdditionalBiomarkers';
import BigQueryBackfill from './bigQueryBackfill/BigQueryBackfill';
import orderVitamins from './orderVitamins';
import VitaminsOrderConfirmation from './orderVitamins/VitaminsOrderConfirmation';
import SubscribedContinueVits from './subscribedLogin/SubscribedContinueVits';
import MemberDrugSavingCard from './MemberDrugSavingCard';
import TmpTest from './TmpTest';
import DeleteMobileUser from './DeleteMobileUser';
import ChangeStripeId from './ChangeStripeId';
import BookConsultation from './bookConsultation/BookConsultation';
import MobileSymptomTrackersUnithroid from './MobileSymptomTrackersUnithroid';
import UploadLabResultsSuccess from './UploadLabResultsSuccess';
import CompleteProfile from './completeProfileFromMobile/CompleteProfile';
import ConfirmModal from '../components/ConfirmModal';
import MergeAccountsModal from '../components/mergeAccounts/MergeAccountsModal';
import CommunityTerms from './CommunityTerms';
import AdminTestNotifications from './AdminTestNotifications';
import AdminPasswordChange from './AdminPasswordChange';
import AdminGenerateOtp from './AdminGenerateOtp';
import LandingAdmin from './LandingAdmin';
import AdminHamburgerMenu from '../components/admin/hamburgerMenu/AdminHamburgerMenu';
import AdminViewMyLogs from './AdminViewMyLogs';
import AdminViewAdminLogs from './AdminViewAdminLogs';
import AdminSendMobileNotifications from './AdminSendMobileNotifications';
import ChangeHelpscoutId from './ChangeHelpscoutId';
import { MyInsurances } from './MyInsurances';
import AddNewInsurance from './myInsurances/AddNewInsurance';
import AdminBatchCreateSlots from './AdminBatchCreateSlots';
import AdminRunPr from './AdminRunPr';
import VisitVisio from './visitVisio/VisitVisio';
import CreateProviderUser from './CreateProviderUser';
import AsyncPostRegister from './asyncRegister/AsyncPostRegister';
import AdminResetUserWeek from './AdminResetUserWeek';
import CollapsibleNavLinkSection from '../components/CollapsibleNavLinkSection';
import doctorIcon from '../static/doctorIcon.png';
import nutritionistIcon from '../static/getSupportNutritionist.png';
import kitIcon from '../static/kitIcon.png';
import computerIcon from '../static/Computer.png';
import refillIcon from '../static/refillIcon.png';
import guidesIcon from '../static/reportIcon.png';
import communityIcon from '../static/shareImage.png';
import articlesIcon from '../static/refillIcon.png';
import recipesIcon from '../static/AdvocadoSmall.png';
import inviteFriendsIcon from '../static/nutritionistDashIcon.png';
import ViewArticle from './ViewArticle';
import ViewArticles from './ViewArticles';
import ViewRecipe from './ViewRecipe';
import ViewRecipes from './ViewRecipes';
import SpeakerSeries from './SpeakerSeries';
import ThyroidGuides from './ThyroidGuides';
import SpeakerSeriesIcon from '../static/icon_play.png';
import ongoingAdvice from '../static/ongoingAdvice.png';
import { Skeleton } from '../components/loadingSkeleton/Skeleton';
import moment from 'moment';
import { CleanDom } from '../utils/domCleanup';
import AdminPresetPlans from './AdminPresetPlans';
import AdminPresetPlan from './AdminPresetPlan';
import AdminWorkouts from './AdminWorkouts';
import RemapPwn from './RemapPwn';
import AdminB2BManagement from './AdminB2BManagement';
import EligibilityConfirmation from './EligibilityConfirmation';
import MyEligibility from './MyEligibility';
import SessionTimeout from '../components/SessionTimeout';
import AdminCareManagers from './adminCareManagers';
import RemapMobileUserAssociatedUser from './RemapMobileUserAssociatedUser';
import AdminSearchPatient from './adminSearchPatient';
import AdminMobileContactInfo from './AdminMobileContactInfo';
import TopHeaderContent from './dashboard/TopHeaderContent';
import YearlyReview from './YearlyReview/YearlyReview';
import SubscribedContinueInsurance from './selectPlan/SubscribedContinueInsurance';
import ChangeInsuranceMemberInsurance from './myInsurances/ChangeInsuranceMemberInsurance';
import SubscribedConfirm from './subscribedLogin/SubscribedConfirm';
import AdminBulkSetSurveyFlag from './AdminBulkSetSurveyFlag';
import AdminReorderPhysicianConvo from './adminReorderPhysicianConvo/AdminReorderPhysicianConvo';
import AdminIdentifyDups from './AdminIdentifyDups';
import CreateHealthCoachUser from './CreateHealthCoachUser';
import AdminHealthCoaches from './AdminHealthCoaches';
import HealthCoachFormBuilderWrap from './adminHealthCoachFormsTemplate/HealtCoachFormBuilder';
import HealthCoachFormsList from './adminHealthCoachFormsTemplate/HealthCoachFormsList';
import BaselineOutroSummary from './baselineSurvey/BaselineOutroSummary';
import LabResults2 from './labResults2/LabResults2';
import StandaloneLabDetail from './StandaloneLabDetail';
import ConsultationsV3 from './ConsultationsV3';
import BookConsultationNoHypo from './bookConsultation/BookConsultationNoHypo';
import LabOrders from './LabOrders';
import ScheduleSplit from './bookConsultation/ScheduleSplit';
import ScheduleMeno from './ScheduleMeno';
import BookConsultationMeno from './bookConsultation/BookConsultationMeno';
import AdminBannerFilterBuilder from './AdminBannerFilterBuilder';
import AdminBannerFilterList from './AdminBannerFilterList';
// import ScheduleNoHypo from './ScheduleNoHypo';

const Container = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  /* height: 100vh;
  max-height: 100vh; */
`;

const Outlet = styled(Flex)`
  &.centerContent {
    @media screen and (min-width: 1200px) {
      width: 1200px;
      max-width: 100vw;
      margin: 0 auto;
    }
  }
`;

const Header = styled(Flex)`
  height: 80px;
  max-height: 80px;
  position: fixed;
  background: #fdf5f2;
  width: 100%;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.08);
  z-index: 10;
  max-width: 100vw;
`;

const HeaderSub = styled(Flex)`
  max-width: 100vw;
  width: 100%;
  @media screen and (min-width: 1200px) {
    padding-left: 30px;
  }
`;

const HeaderContent = styled(Flex)`
  height: 80px;
  max-height: 80px;
  width: 100vw;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

const HeaderMenu = styled(Flex)`
  border-left: none;
  /* position: absolute; */
  right: 0;

  @media screen and (min-width: 52em) {
    display: flex;
    min-width: 100px;
  }
`;

const DesktopOnly = styled(Flex)`
  @media screen and (max-width: 52em) {
    display: none;
  }
`;

const NavLink = styled(RouterNavLink)`
  color: #344f79;
  stroke: #344f79;
  fill: #344f79;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  padding: 4px 14px;
  margin-left: 28px;
  margin-right: 28px;
  font-weight: 300;
  /* margin-bottom: 20px; */

  @media screen and (max-width: 52em) {
    margin-bottom: 0;
  }
  @media screen and (max-width: 480px) {
    color: #344f79;
    /* padding: 10px 30px; */
  }
  &.active {
    background-color: #7992b3;
    color: white;
    stroke: white;
    fill: white;
    font-weight: 600;
    @media screen and (max-width: 480px) {
      color: white;
    }
  }
  &:hover {
    background-color: rgba(121, 146, 179, 0.25);
  }
`;

const FakeNavLink = styled(Text)`
  color: #344f79;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  border-radius: 5px;
  padding: 4px 14px;
  margin-left: 28px;
  margin-right: 28px;
  cursor: pointer;
  /* margin-bottom: 20px; */

  @media screen and (max-width: 52em) {
    margin-bottom: 0;
  }
  @media screen and (max-width: 480px) {
    color: #344f79;
    /* padding: 10px 30px; */
  }
  &:hover {
    background-color: rgba(121, 146, 179, 0.25);
  }
`;

const A = styled.a`
  display: flex;
  text-decoration: none;

  &:visited {
    color: #344f79;
  }
`;

const NavLinkSub = styled(RouterNavLink)`
  color: #344f79;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  border-radius: 5px;
  padding: 4px 14px;
  margin-left: 28px;
  margin-right: 28px;
  @media screen and (max-width: 480px) {
    color: #344f79;
  }
  &.active {
    background-color: #7992b3 !important;
    color: white;
    @media screen and (max-width: 480px) {
      color: white;
    }
  }
  &:hover {
    background-color: rgba(121, 146, 179, 0.25);
  }
`;

const NavButton = styled.button`
  display: block;
  position: absolute;
  top: 20px;
  left: 16px;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const SHOW_SHADOW_PAGES = [];
const MAXED_UP_WIDTH_PAGES = ['/consultations-nutritionist', '/register-kit', '/thyroid-guides', '/articles', '/recipes', '/speaker-series', '/chat-care', '/account', '/my-care'];

class Dashboard extends Component {
  state = {
    isSidebarOpen: false,
    isAccountOpen: false,
    isExitingImpersonation: false,
    isLabsSectionOpened: true,
    isResourcesSectionOpened: true,
    isMessagesSectionOpened: true,
    showBoxShadow: false,
    centerColumn: false,
  };

  profileRef = React.createRef();

  componentDidMount() {
    if (this.props.location.pathname.indexOf('purchase') < 0) {
      this.props.analytics.page();
    }
    if (this.props.session.isMember) {
      if (window.Beacon) {
        window.Beacon('destroy');
      }
    }
    this.setShowShadow();
    this.setCenterColumn();
    CleanDom();
  }

  setShowShadow = () => {
    const currentPage = this.props.location.pathname;
    const { showBoxShadow } = this.state;
    for (let index = 0; index < SHOW_SHADOW_PAGES.length; index++) {
      const element = SHOW_SHADOW_PAGES[index];
      if (currentPage.indexOf(element) > -1) {
        if (!showBoxShadow) {
          this.setState({
            showBoxShadow: true,
          });
        }
        return;
      }
    }
    if (!!showBoxShadow) {
      this.setState({
        showBoxShadow: false,
      });
    }
  };

  setCenterColumn = () => {
    const currentPage = this.props.location.pathname;
    const { centerColumn } = this.state;
    for (let index = 0; index < MAXED_UP_WIDTH_PAGES.length; index++) {
      const element = MAXED_UP_WIDTH_PAGES[index];
      if (currentPage.indexOf(element) > -1) {
        if (!centerColumn) {
          this.setState({
            centerColumn: true,
          });
        }
        return;
      }
    }
    // console.log({ currentPage });
    if (currentPage === '/') {
      this.setState({
        centerColumn: true,
      });
      return;
    }
    if (!!centerColumn) {
      this.setState({
        centerColumn: false,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;
    if (currentPage !== prevPage) {
      // console.log({ currentPage });
      this.setShowShadow();
      this.setCenterColumn();
      window.scroll(0, 0);
      if (currentPage.indexOf('purchase') < 0) {
        this.props.analytics.page();
      }
      CleanDom();
    }

    if (prevProps.session.isMember !== this.props.session.isMember) {
      if (this.props.session.isMember) {
        if (window.Beacon) {
          window.Beacon('destroy');
        }
      }
    }
  }

  toggleMenu = () => {
    if (this.profileRef && this.profileRef.current) {
      try {
        this.profileRef.current.forceClose();
      } catch {
        try {
          this.profileRef.current.wrappedInstance.forceClose();
        } catch (error) {}
      }
    }
    if (!this.state.isSidebarOpen) {
      if (window.Beacon) {
        window.Beacon('destroy');
      }
    } else {
      if (window.Beacon && (!this.props.session || !this.props.session.isMember)) {
        window.Beacon('init', '828e0d09-171b-4d1e-9d3c-dc4f59c81115');
      }
    }
    this.setState({ isSidebarOpen: !this.state.isSidebarOpen });
  };

  handleSidebar = () => {
    this.setState({ isSidebarOpen: !this.state.isSidebarOpen });
  };

  handleAccount = () => {
    if (isMobile) {
      if (window.Beacon) {
        window.Beacon('destroy');
      }
    }
    this.setState({
      isAccountOpen: true,
      isSidebarOpen: false,
    });
  };

  handleAccountClose = () => {
    if (isMobile) {
      if (window.Beacon && !this.props.session.isMember) {
        // console.log('window.onbeforeunload Beacon found');
        window.Beacon('init', '828e0d09-171b-4d1e-9d3c-dc4f59c81115');
      }
    }
    this.setState({
      isAccountOpen: false,
      isSidebarOpen: false,
    });
  };

  handleAccountHover = () => {
    this.setState({ isAccountOpen: true });
  };

  handleAccountLeave = () => {
    this.setState({ isAccountOpen: false });
  };

  exitImpersonationMode = async () => {
    const { client, refetch } = this.props;
    this.setState({
      isExitingImpersonation: true,
    });
    const initialToken = localStorage.getItem('initialToken');
    // console.log('Initial Tok', initialToken);
    localStorage.setItem('token', initialToken);
    localStorage.setItem('initialToken', '');

    if (initialToken === 'aa00') {
      window.close();
    }
    await client.clearStore();
    await refetch();
    // await client.query({
    //   query: CURRENT_USER,
    //   // refetchQueries: [{ query: ATHENA_CARD }, { query: APPOINTMENTS }, { query: LABS }, { query: GET_USER_PCP }],
    //   // fetchPolicy: 'network-only',
    //   // awaitRefetchQueries: true,
    // });
    this.setState(
      {
        isExitingImpersonation: false,
      },
      () => this.props.history.push('/')
    );
  };

  onCommunityClick = async () => {
    const { client, history } = this.props;

    const circleSo = await client.mutate({
      mutation: GENERATE_CIRCLE_SO_CALLBACK,
    });

    if (circleSo && circleSo.data && circleSo.data.generateCircleSoCallback) {
      if (!!circleSo.data.generateCircleSoCallback.callback) {
        this.props.analytics.track('Open community');
        window.open(circleSo.data.generateCircleSoCallback.callback, '_blank');
      } else if (!!circleSo.data.generateCircleSoCallback.mustAcceptTerms) {
        history.push({
          pathname: '/community-terms',
          state: { sameWindow: false },
        });
      }
    }
  };

  render() {
    const currentPage = this.props.location.pathname;
    const { isSidebarOpen, isAccountOpen, isExitingImpersonation, showBoxShadow, centerColumn } = this.state;
    const {
      session: { eligibility, intakeStatus, isLab, adm, email, isDiagnosed, diagnoseFull, isMember, mustCompleteProfile, mustMergeAccount, perpetuousPhysicianConversationId },
    } = this.props;
    // console.log({ th: this.props });
    const t = isToggleActive('PERSONAL_ADMIN');

    if (!!mustCompleteProfile) {
      return <CompleteProfile />;
    }

    if (isExitingImpersonation) {
      return <LoadingScreen text="Exiting Impersonation Mode" />;
    }
    // console.log('Eligibility', {
    //   eligibility,
    //   s: this.props.session,
    // });
    const isSchedulePage = this.props.location && this.props.location.pathname && this.props.location.pathname.startsWith('/schedule');
    const isMobileCourseEditor = this.props.location && this.props.location.pathname && this.props.location.pathname.startsWith('/course-editor');
    const isHealthCoachFormEditor = this.props.location && this.props.location.pathname && this.props.location.pathname.startsWith('/hc-template-builder');
    // console.log({ adm });
    if (adm && adm.passwordExpired) {
      // || (adm.daysBeforePasswordExpires>0 && adm.daysBeforePasswordExpires<=3)){
      return (
        <Container flex={1}>
          <AdminPasswordChange />
        </Container>
      );
    }
    if (isLab) {
      return (
        <Container flex={1}>
          <Header flex={1}>
            <NavButton alignItems="center" onClick={this.toggleMenu}>
              <Menu />
            </NavButton>
            <Flex flex={1} px="30px" alignItems="center" justifyContent={['center', 'center', 'flex-start']}>
              <Link to="/">
                <LogoHeader />
              </Link>
            </Flex>
            <HeaderMenu pl={3}>
              <HeaderAccount
                session={this.props.session}
                ref={this.profileRef}
                open={isAccountOpen}
                onClick={() => {
                  this.handleAccount();
                }}
                onClose={this.handleAccountClose}
                onHover={this.handleAccountHover}
                onLeave={this.handleAccountLeave}
              />
            </HeaderMenu>
          </Header>
          <Flex flex={1} bg="#FDF5F2">
            <Flex flex={1} mt="80px">
              <Switch>
                <Route exact path="/" component={LabScan} {...this.props} />
                <Route path="/requisition/:id" component={ViewRequisitionForm} {...this.props} />
                <Route path="*" component={LabScan} {...this.props} />
              </Switch>
            </Flex>
          </Flex>
        </Container>
      );
    }

    const initTok = localStorage.getItem('initialToken');

    const globalEligibility = eligibility.status !== 'no_service' || eligibility.canBookNutritionist || eligibility.canBookAsync;

    const visitsEligibility =
      ((eligibility.consultation || eligibility.canBookNutritionist || (eligibility.canBookAsync && intakeStatus !== 'disqualified')) &&
        (eligibility.canBookAsync ||
          // eligibility.status !== 'new_patient' &&
          (isDiagnosed || (diagnoseFull && diagnoseFull.hasHashi) || (diagnoseFull && (diagnoseFull.hasAppointment || (diagnoseFull && diagnoseFull.hasAppointmentNutritionist)))))) ||
      eligibility.canBookPerimenopause ||
      eligibility.canBookPostmenopause;

    const doctorVisit =
      (((eligibility.consultation && intakeStatus !== 'disqualified') || (eligibility.canBookAsync && intakeStatus !== 'disqualified')) &&
        (isDiagnosed || (diagnoseFull && diagnoseFull.hasAppointment)) &&
        (eligibility.status !== 'new_patient' || (diagnoseFull && diagnoseFull.hasAppointment))) ||
      eligibility.canBookPerimenopause ||
      eligibility.canBookPostmenopause;

    const nutritionistVisit = eligibility.canBookNutritionist && (isDiagnosed || (diagnoseFull && (diagnoseFull.hasHashi || (diagnoseFull && diagnoseFull.hasAppointmentNutritionist))));
    // console.log({visitsEligibility, doctorVisit})
    return (
      <Container id="cont" flex={1}>
        {!window.ReactNativeWebView && (
          <Header flex={1}>
            <HeaderSub>
              <HeaderContent>
                <NavButton alignItems="center" onClick={this.toggleMenu}>
                  <Menu />
                </NavButton>
                <Flex flex={[1, 1, 0]} px="30px" mr={['-46px', '', '']} alignItems="center" justifyContent={['center', 'center', 'flex-start']}>
                  <Link to="/">
                    <LogoHeader height="40px" />
                  </Link>
                </Flex>
                <Flex flex={[0, 0, 1]} justifyContent="center" alignItems={'center'}>
                  <TopHeaderContent />
                </Flex>
                <HeaderMenu pl={3}>
                  <DesktopOnly>
                    {currentPage.indexOf('/schedule/') < 0 && (
                      <Flex alignItems="center" mr="16px" pr="16px" style={{ borderRight: '1px solid #e0e0e0' }}>
                        <A href="tel:434-248-7508" style={{ marginLeft: '24px' }}>
                          <div
                            style={{
                              width: '30px',
                              transform: 'scaleX(-1)',
                              marginRight: '12px',
                            }}
                          >
                            <FontAwesomeIcon size="1x" color="#344f79" icon={faPhone} />
                          </div>
                          <Text style={{ fontWeight: 500 }}>434-248-7508</Text>
                        </A>
                      </Flex>
                    )}
                  </DesktopOnly>
                  <HeaderAccount
                    session={this.props.session}
                    ref={this.profileRef}
                    onClick={() => {
                      this.handleAccount();
                    }}
                    onClose={this.handleAccountClose}
                    onHover={this.handleAccountHover}
                    onLeave={this.handleAccountLeave}
                    open={isAccountOpen}
                  />
                </HeaderMenu>

                {initTok !== null &&
                  initTok !== '' && (
                    <div
                      style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        height: '26px',
                        background: 'red',
                      }}
                      id="impersonateBanner"
                    >
                      <div
                        style={{
                          color: 'white',
                          fontWeight: '500',
                          textAlign: 'center',
                        }}
                      >
                        Impersonating Mode Active
                        {' - '}
                        <span style={{ textDecoration: 'underline' }} onClick={() => this.exitImpersonationMode()}>
                          Exit
                        </span>
                      </div>
                    </div>
                  )}
              </HeaderContent>
            </HeaderSub>
            {/* </div> */}
          </Header>
        )}
        <Outlet id="toMaxUp" className={currentPage.indexOf('/pickDoctor') > -1 || currentPage.indexOf('/hc-template-builder') > -1 ? '' : 'centerContent'} flex={1} bg="#FDF5F2">
          {(!t ? (
            window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email
          ) : (
            !!adm
          )) ? (
            <AdminHamburgerMenu open={isSidebarOpen} forceClose={!isSchedulePage && !isMobileCourseEditor && !isHealthCoachFormEditor} handleSidebar={this.handleSidebar} />
          ) : (
            <Sidebar showShadow={showBoxShadow} open={isSidebarOpen} forceClose={!isSchedulePage && !isMobileCourseEditor}>
              <NavLink id="link_yourDashboard" exact to="/" onClick={this.handleSidebar}>
                <Flex flexDirection={'row'} alignItems="center" style={{ minHeight: '29px' }}>
                  <svg width="12" height="12" viewBox="0 0 12 12" style={{ marginRight: '8px' }} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_366_636)">
                      <path d="M4.5 7.5V11.25H1.5V4.5L6 0.75L10.5 4.5V11.25H7.5V7.5H4.5Z" stroke="inherit" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_366_636">
                        <rect width="12" height="12" fill="inherit" />
                      </clipPath>
                    </defs>
                  </svg>
                  Your Dashboard
                </Flex>
              </NavLink>
              {!!globalEligibility && (
                <Fragment>
                  {!!visitsEligibility ? (
                    (!!doctorVisit || !!nutritionistVisit) && (
                      <CollapsibleNavLinkSection text="Video Visits">
                        <Fragment>
                          {!!doctorVisit ? (
                            <NavLinkSub id="link_consultationsDoctor" to="/consultations" onClick={this.handleSidebar}>
                              <Flex flexDirection={'row'} alignItems="center">
                                <Image src={doctorIcon} width="29px" height="29px" mr="10px" />
                                Clinician
                              </Flex>
                            </NavLinkSub>
                          ) : (
                            <Query query={APPOINTMENTS}>
                              {({ data: dataAppointments, loading: loadingAppointments }) => {
                                let pastAppointment;
                                if (!!loadingAppointments) return null;
                                if (dataAppointments && dataAppointments.appointments) {
                                  const { appointments } = dataAppointments;
                                  pastAppointment = appointments
                                    .sort((a, b) => {
                                      const ma = moment(`${a.date} ${a.startTime}`, 'MM/DD/YYYY HH:mm');
                                      const mb = moment(`${b.date} ${b.startTime}`, 'MM/DD/YYYY HH:mm');
                                      if (ma.isAfter(mb)) return -1;
                                      return 1;
                                    })
                                    .filter((item) => {
                                      if (
                                        item.status &&
                                        item.status !== 'x' &&
                                        item.status !== 'f' &&
                                        item.status !== 'o' &&
                                        item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                                        item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID
                                      ) {
                                        const dt = moment(`${item.date} ${item.startTime}`, 'MM/DD/YYYY HH:mm');
                                        return dt.isBefore(moment(), 'minute');
                                      }
                                      return false;
                                    });
                                }
                                if (!pastAppointment || pastAppointment.length < 1) return null;

                                return (
                                  <>
                                    {pastAppointment.filter(
                                      (item) =>
                                        item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                                        item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID &&
                                        item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_NEW_NUTRITIONIST_30
                                    ).length > 0 && (
                                      <NavLinkSub id="link_consultationsDoctor" to="/consultations" onClick={this.handleSidebar}>
                                        <Flex flexDirection={'row'} alignItems="center">
                                          <Image src={doctorIcon} width="29px" height="29px" mr="10px" />
                                          Clinician
                                        </Flex>
                                      </NavLinkSub>
                                    )}
                                  </>
                                );
                              }}
                            </Query>
                          )}
                          {!!nutritionistVisit ? (
                            <NavLinkSub
                              id="link_consultationsNutritionist"
                              to="/consultations-nutritionist"
                              onClick={this.handleSidebar}
                              style={{
                                marginBottom: '20px !important',
                              }}
                            >
                              <Flex flexDirection={'row'} alignItems="center">
                                <Image src={nutritionistIcon} width="29px" height="29px" mr={2} />
                                Nutritionist
                              </Flex>
                            </NavLinkSub>
                          ) : null}
                        </Fragment>
                      </CollapsibleNavLinkSection>
                    )
                  ) : (
                    <Query query={APPOINTMENTS}>
                      {({ data: dataAppointments, loading: loadingAppointments }) => {
                        let pastAppointment;
                        if (!!loadingAppointments) return null;
                        if (dataAppointments && dataAppointments.appointments) {
                          const { appointments } = dataAppointments;
                          pastAppointment = appointments
                            .sort((a, b) => {
                              const ma = moment(`${a.date} ${a.startTime}`, 'MM/DD/YYYY HH:mm');
                              const mb = moment(`${b.date} ${b.startTime}`, 'MM/DD/YYYY HH:mm');
                              if (ma.isAfter(mb)) return -1;
                              return 1;
                            })
                            .filter((item) => {
                              if (
                                item.status &&
                                item.status !== 'x' &&
                                item.status !== 'f' &&
                                item.status !== 'o' &&
                                item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                                item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID
                              ) {
                                const dt = moment(`${item.date} ${item.startTime}`, 'MM/DD/YYYY HH:mm');
                                return dt.isBefore(moment(), 'minute');
                              }
                              return false;
                            });
                        }
                        if (!pastAppointment || pastAppointment.length < 1) return null;

                        return (
                          <>
                            {pastAppointment.filter(
                              (item) =>
                                item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                                item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID &&
                                item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_NEW_NUTRITIONIST_30
                            ).length > 0 && (
                              <NavLinkSub id="link_consultationsDoctor" to="/consultations" onClick={this.handleSidebar}>
                                <Flex flexDirection={'row'} alignItems="center">
                                  <Image src={doctorIcon} width="29px" height="29px" mr="10px" />
                                  Clinician
                                </Flex>
                              </NavLinkSub>
                            )}
                            {pastAppointment.filter((item) => item.appointmentTypeId === window._env_.REACT_APP_ATHENA_NEW_NUTRITIONIST_30).length > 0 && (
                              <NavLinkSub
                                id="link_consultationsNutritionist"
                                to="/consultations-nutritionist"
                                onClick={this.handleSidebar}
                                style={{
                                  marginBottom: '20px !important',
                                }}
                              >
                                <Flex flexDirection={'row'} alignItems="center">
                                  <Image src={nutritionistIcon} width="29px" height="29px" mr={2} />
                                  Nutritionist
                                </Flex>
                              </NavLinkSub>
                            )}
                          </>
                        );
                      }}
                    </Query>
                  )}

                  {eligibility.lab ? (
                    <CollapsibleNavLinkSection text="Labs">
                      <Fragment>
                        <NavLinkSub id="link_registerKit" to="/register-kit" onClick={this.handleSidebar}>
                          <Flex flexDirection={'row'} alignItems="center">
                            <Image src={kitIcon} width="29px" height="29px" mr={2} />
                            Register Your Kit
                          </Flex>
                        </NavLinkSub>
                        <NavLinkSub id="link_labs" to="/labs" onClick={this.handleSidebar}>
                          <Flex flexDirection={'row'} alignItems="center">
                            <Image src={computerIcon} width="29px" height="29px" mr={2} />
                            Your Lab Results
                          </Flex>
                        </NavLinkSub>
                        {isToggleActive('LABORDERS') && (
                          <Query query={GET_LAB_ORDERS_LIST}>
                            {({ data: dataLabOrders, loading: loadingLabOrders }) => {
                              if (!!loadingLabOrders) {
                                return (
                                  <FakeNavLink id="link_registerKit" onClick={() => {}}>
                                    <Flex flexDirection={'row'} alignItems="center">
                                      <Image src={ongoingAdvice} width="29px" height="29px" mr={2} />
                                      <Skeleton height="14px" width="75px" />
                                    </Flex>
                                  </FakeNavLink>
                                );
                              }
                              if (!dataLabOrders || !dataLabOrders.getLabOrdersReady || dataLabOrders.getLabOrdersReady.length < 1) {
                                return null;
                              }
                              return (
                                <NavLinkSub id="link_labs" to="/lab-orders" onClick={this.handleSidebar}>
                                  <Flex flexDirection={'row'} alignItems="center">
                                    <Image src={refillIcon} width="29px" height="29px" mr={2} />
                                    Your Lab Orders
                                  </Flex>
                                </NavLinkSub>
                              );
                            }}
                          </Query>
                        )}
                      </Fragment>
                    </CollapsibleNavLinkSection>
                  ) : (
                    <>
                      {isToggleActive('LABORDERS') && (
                        <Query query={GET_LAB_ORDERS_LIST}>
                          {({ data: dataLabOrders, loading: loadingLabOrders }) => {
                            if (!!loadingLabOrders) {
                              return (
                                <FakeNavLink id="link_registerKit" onClick={() => {}}>
                                  <Flex flexDirection={'row'} alignItems="center">
                                    <Image src={ongoingAdvice} width="29px" height="29px" mr={2} />
                                    <Skeleton height="14px" width="75px" />
                                  </Flex>
                                </FakeNavLink>
                              );
                            }
                            if (!dataLabOrders || !dataLabOrders.getLabOrdersReady || dataLabOrders.getLabOrdersReady.length < 1) {
                              return null;
                            }
                            return (
                              <CollapsibleNavLinkSection text="Labs">
                                <NavLinkSub id="link_labs" to="/lab-orders" onClick={this.handleSidebar}>
                                  <Flex flexDirection={'row'} alignItems="center">
                                    <Image src={refillIcon} width="29px" height="29px" mr={2} />
                                    Your Lab Orders
                                  </Flex>
                                </NavLinkSub>
                              </CollapsibleNavLinkSection>
                            );
                          }}
                        </Query>
                      )}
                    </>
                  )}

                  <CollapsibleNavLinkSection text="Resources" defaultExpanded={false}>
                    <Fragment>
                      <NavLinkSub id="link_registerKit" to="/thyroid-guides" onClick={this.handleSidebar}>
                        <Flex flexDirection={'row'} alignItems="center">
                          <Image src={guidesIcon} width="29px" height="29px" mr={2} />
                          Thyroid Guides
                        </Flex>
                      </NavLinkSub>
                      <FakeNavLink id="link_labs" onClick={this.onCommunityClick}>
                        <Flex flexDirection={'row'} alignItems="center">
                          <Image src={communityIcon} width="29px" height="29px" mr={2} />
                          Community
                        </Flex>
                      </FakeNavLink>
                      <NavLinkSub id="link_labs" to="/articles" onClick={this.handleSidebar}>
                        <Flex flexDirection={'row'} alignItems="center">
                          <Image src={articlesIcon} width="29px" height="29px" mr={2} />
                          Articles
                        </Flex>
                      </NavLinkSub>
                      <NavLinkSub id="link_recipes" to="/recipes" onClick={this.handleSidebar}>
                        <Flex flexDirection={'row'} alignItems="center">
                          <Image src={recipesIcon} width="29px" height="29px" mr={2} />
                          Recipes
                        </Flex>
                      </NavLinkSub>
                      <NavLinkSub id="link_videos" to="/speaker-series" onClick={this.handleSidebar}>
                        <Flex flexDirection={'row'} alignItems="center" style={{ minHeight: '28px' }}>
                          <Image src={SpeakerSeriesIcon} width="20px" height="20px" mr={'18px'} />
                          Video Library
                        </Flex>
                      </NavLinkSub>
                    </Fragment>
                  </CollapsibleNavLinkSection>

                  <Query query={APPOINTMENTS}>
                    {({ data: dataAppointments, loading: loadingAppointments }) => {
                      let pastAppointment;
                      if (dataAppointments && dataAppointments.appointments) {
                        const { appointments } = dataAppointments;
                        pastAppointment = appointments
                          .sort((a, b) => {
                            const ma = moment(`${a.date} ${a.startTime}`, 'MM/DD/YYYY HH:mm');
                            const mb = moment(`${b.date} ${b.startTime}`, 'MM/DD/YYYY HH:mm');
                            if (ma.isAfter(mb)) return -1;
                            return 1;
                          })
                          .find((item) => {
                            if (
                              item.status &&
                              item.status !== 'x' &&
                              item.status !== 'f' &&
                              item.status !== 'o' &&
                              item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                              item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID
                            ) {
                              const dt = moment(`${item.date} ${item.startTime}`, 'MM/DD/YYYY HH:mm');
                              return dt.isBefore(moment(), 'minute');
                            }
                            return false;
                          });
                      }
                      if (!loadingAppointments && !pastAppointment && !isMember) return null;
                      return (
                        <CollapsibleNavLinkSection text="Messages">
                          <Fragment>
                            {!loadingAppointments ? (
                              <>
                                {!!pastAppointment ? (
                                  <NavLinkSub
                                    id="link_registerKit"
                                    to={{
                                      pathname: `/consultations-communicator/${pastAppointment.id}`,
                                      state: { isDesktopForced: true },
                                    }}
                                    onClick={this.handleSidebar}
                                  >
                                    <Flex flexDirection={'row'} alignItems="center">
                                      <Image src={ongoingAdvice} width="29px" height="29px" mr={2} />
                                      Provider
                                    </Flex>
                                  </NavLinkSub>
                                ) : null}
                              </>
                            ) : (
                              <FakeNavLink id="link_registerKit" onClick={() => {}}>
                                <Flex flexDirection={'row'} alignItems="center">
                                  <Image src={ongoingAdvice} width="29px" height="29px" mr={2} />
                                  <Skeleton height="14px" width="75px" />
                                </Flex>
                              </FakeNavLink>
                            )}
                            {isMember && (
                              <NavLinkSub id="link_registerKit" to={`/chat-care`} onClick={this.handleSidebar}>
                                <Flex flexDirection={'row'} alignItems="center">
                                  <Image src={communityIcon} width="29px" height="29px" mr={2} />
                                  Care Manager
                                </Flex>
                              </NavLinkSub>
                            )}
                          </Fragment>
                        </CollapsibleNavLinkSection>
                      );
                    }}
                  </Query>

                  <NavLink id="link_yourDashboard" exact to="/invite-friends" onClick={this.handleSidebar} style={{ marginTop: '16px' }}>
                    <Flex flexDirection={'row'} alignItems="center" style={{ minHeight: '29px' }}>
                      <Image src={inviteFriendsIcon} width="29px" height="29px" mr={2} />
                      Invite Friends
                    </Flex>
                  </NavLink>
                  {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canChangeEmail) && (
                    <>
                      <NavLink to="/change-email" onClick={this.handleSidebar}>
                        Change Email
                      </NavLink>
                      {/* <FakeNavLink>Community</FakeNavLink> */}
                    </>
                  )}
                </Fragment>
              )}
              {/* {isMember ? (
                <>
                  <NavLink id="link_careTeam" exact to="/my-care-team" onClick={this.handleSidebar}>
                    Your Care Team
                  </NavLink>

                  <FakeNavLink id="link_community" onClick={this.onCommunityClick}>
                    Community
                  </FakeNavLink>
                </>
              ) : (
                <>
                  {isDiagnosed &&
                    isToggleActive('MEMBERSHIP') && (
                      <NavLink id="link_inviteFriends" exact to="/become-member" onClick={this.handleSidebar}>
                        Become a Member
                      </NavLink>
                    )}
                  <FakeNavLink id="link_community" onClick={this.onCommunityClick}>
                    Community
                  </FakeNavLink>
                  <DesktopOnly>
                    <NavLink id="link_inviteFriends" exact to="/invite-friends" onClick={this.handleSidebar}>
                      Invite Your Friends
                    </NavLink>
                  </DesktopOnly>
                </>
              )} */}
            </Sidebar>
          )}
          <Flex
            flex={1}
            mt={!window.ReactNativeWebView ? '80px' : '0px'}
            ml={['0', '0', isSchedulePage || isMobileCourseEditor || isHealthCoachFormEditor ? '0' : '250px']}
            onClick={() => (isSidebarOpen ? this.setState({ isSidebarOpen: false }) : null)}
          >
            {(!t ? (
              window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email
            ) : (
              !!adm
            )) ? (
              <Route exact path="/" component={LandingAdmin} heartbeat={this.props.heartbeat} />
            ) : (
              <Route exact path="/" component={Landing} heartbeat={this.props.heartbeat} />
            )}
            {/* <Route path="/yearlyReview" component={YearlyReview} /> */}
            <Route exact path="/my-care-team" component={MyCareTeam} heartbeat={this.props.heartbeat} />
            <Route path="/tmp-test" component={TmpTest} heartbeat={this.props.heartbeat} />
            <Route exact path="/my-saving-card" component={MemberDrugSavingCard} heartbeat={this.props.heartbeat} />
            <Route exact path="/invite-friends" component={Referral} heartbeat={this.props.heartbeat} />
            <Route path="/account" component={MyAccount} heartbeat={this.props.heartbeat} />
            <Route path="/my-care" component={MyCare} heartbeat={this.props.heartbeat} />
            <Route path="/my-account" component={Account} heartbeat={this.props.heartbeat} />
            <Route path="/my-payment-methods" component={MyPaymentMethods} heartbeat={this.props.heartbeat} />
            <Route path="/subscription/update/success/:id" component={SubscriptionUpdated} heartbeat={this.props.heartbeat} />
            <Route path="/eligibility-confirmation/:uid" component={EligibilityConfirmation} heartbeat={this.props.heartbeat} />
            <Route path="/my-subscriptions" component={MySubscriptions} heartbeat={this.props.heartbeat} />
            <Route path="/my-eligibility" component={MyEligibility} heartbeat={this.props.heartbeat} />
            <Route path="/insurance-member/change-insurance" component={ChangeInsuranceMemberInsurance} heartbeat={this.props.heartbeat} />
            <Route path="/my-insurances/add" exact component={AddNewInsurance} isPrimary={true} heartbeat={this.props.heartbeat} />
            <Route path="/my-insurances/add-secondary" exact component={AddNewInsurance} isPrimary={false} heartbeat={this.props.heartbeat} />
            <Route path="/my-insurances" exact component={MyInsurances} heartbeat={this.props.heartbeat} />
            <Route path="/subscription-updated" component={SubscriptionChangesConfirm} heartbeat={this.props.heartbeat} />
            <Route path="/cancel-membership" component={CancelMembership} heartbeat={this.props.heartbeat} />
            <Route path="/cancel-subscription" component={CancelSubscription} heartbeat={this.props.heartbeat} />
            <Route path="/pause-subscription" component={PauseSubscription} heartbeat={this.props.heartbeat} />
            <Route path="/labs-trend" component={LabResultsTrendPage} heartbeat={this.props.heartbeat} />
            <Route path="/subscribed/book/success/:f" component={SubscribedBook} heartbeat={this.props.heartbeat} />
            <Route path="/subscribed/confirm/:f" component={SubscribedConfirm} heartbeat={this.props.heartbeat} />
            <Route path="/subscribed/purchase/success/:f" component={SubscribedPurchase} heartbeat={this.props.heartbeat} />
            <Route path="/subscribed/call/:f" component={SubscribedCall} heartbeat={this.props.heartbeat} />
            <Route path="/subscribed/vitamins/:f" component={SubscribedContinueVits} heartbeat={this.props.heartbeat} />
            <Route path="/subscribed/insurance/vitamins/:f" component={SubscribedContinueInsurance} heartbeat={this.props.heartbeat} />
            <Route path="/subscribed/insurance/call/:f" component={SubscribedContinueInsurance} heartbeat={this.props.heartbeat} />
            <Route path="/subscribe-membership" component={SelectPlanPage} heartbeat={this.props.heartbeat} />
            <Route path="/schedule-care" component={ScheduleCareManager} heartbeat={this.props.heartbeat} />
            <Route path="/chat-care" component={ChatCareTeam} heartbeat={this.props.heartbeat} />
            <Route path="/post-register" component={AsyncPostRegister} heartbeat={this.props.heartbeat} />
            <Route heartbeat={this.props.heartbeat} path="/labs" component={LabResults2} />
            <NotDisqualifiedRoute heartbeat={this.props.heartbeat} path="/consultations-communicator/:id" component={MobileCommunicatorPage} />
            <NotDisqualifiedRoute heartbeat={this.props.heartbeat} path="/consultations-communicator-async/:id" component={MobileAsyncCommunicatorPage} />

            <RouteSection path="/consultations-summary" redirectTo="/consultations" heartbeat={this.props.heartbeat} />
            <RouteSection path="/labs-summary" redirectTo="/labs" heartbeat={this.props.heartbeat} />
            <Route path="/order-vitamins" component={orderVitamins} heartbeat={this.props.heartbeat} />
            <Route path="/order-vitamins-confirm/:session_id" component={VitaminsOrderConfirmation} heartbeat={this.props.heartbeat} />
            <DiagnosedHypoRouteWithSessionRoute heartbeat={this.props.heartbeat} path="/consultations" component={ConsultationsV3} />
            <DiagnosedHypoOrHashiRouteWithSessionRoute heartbeat={this.props.heartbeat} path="/consultations-nutritionist" component={ScheduleNutritionist} />
            {/* <Route path="/labs" component={Labs} heartbeat={this.props.heartbeat} /> */}
            <Route path="/uploadLabResults" component={UploadLabResultsPage} heartbeat={this.props.heartbeat} />
            <Route path="/uploadLabResultsSuccess" component={UploadLabResultsSuccess} />
            <Route path="/schedule-complete" component={ScheduleNewPatientComplete} heartbeat={this.props.heartbeat} />
            <NotDisqualifiedRoute path="/pharmacy" component={Pharmacy} heartbeat={this.props.heartbeat} />
            <NotDisqualifiedRoute path="/lab-center" component={LabCenter} heartbeat={this.props.heartbeat} />
            <NotDisqualifiedRoute heartbeat={this.props.heartbeat} path="/practitioner" component={Practitioner} />
            <Route path="/support" component={Support} heartbeat={this.props.heartbeat} />
            <Route path="/register-kit" component={RegisterKit} heartbeat={this.props.heartbeat} />
            <Route path="/registered-kit" component={RegisteredKit} heartbeat={this.props.heartbeat} />
            <Route path="/register-kit-payment" component={registerKitPayment} heartbeat={this.props.heartbeat} />
            <Route path="/register-kit-addons" component={RegisterKitAdditionalBiomarkers} heartbeat={this.props.heartbeat} />
            <Route path="/lab-orders" component={LabOrders} heartbeat={this.props.heartbeat} />
            <Route path="/change-me-account" component={ProfilePage} />
            <Route path="/articles/:id" exact component={ViewArticle} />
            <Route path="/articles" exact component={ViewArticles} />
            <Route path="/recipes/:id" exact component={ViewRecipe} />
            <Route path="/recipes" exact component={ViewRecipes} />
            <Route path="/speaker-series" exact component={SpeakerSeries} />
            <Route path="/thyroid-guides" exact component={ThyroidGuides} />
            <Route path="/visit-visio" component={VisitVisio} heartbeat={this.props.heartbeat} />
            <Route path="/visit-room" component={VisitVisio} heartbeat={this.props.heartbeat} />
            <Route path="/visit-room/:id" component={VisitVisio} heartbeat={this.props.heartbeat} />
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canChangeEmail) && <AdminRoute path="/change-email" component={ChangeEmail} heartbeat={this.props.heartbeat} />}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.mobileMan) && (
              <>
                {/* <AdminRoute path="/course-editor" component={MobileCourseDesigner} heartbeat={this.props.heartbeat} /> */}
                <AdminRoute path="/admin-mobile-symptom-tracker" component={MobileSymptomTrackers} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-mobile-webflow-articles" component={MobileWebflowArticles} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-mobile-recipes" component={MobileRecipes} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-mobile-motivation-quotes" component={MobileMotivationQuotes} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-mobile-courses" component={MobileCourses} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-preset-plans" component={AdminPresetPlans} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-preset-plan/:id" component={AdminPresetPlan} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-workouts" component={AdminWorkouts} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-mobile-push-notification" component={AdminSendMobileNotifications} heartbeat={this.props.heartbeat} />
              </>
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canUnithroid) && (
              <>
                <AdminRoute path="/admin-mobile-symptom-tracker-unithroid" component={MobileSymptomTrackersUnithroid} heartbeat={this.props.heartbeat} />
              </>
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canAdminProvidersApp) && (
              <>
                <AdminRoute path="/admin-create-provider-user" component={CreateProviderUser} heartbeat={this.props.heartbeat} />
                {/* <AdminRoute path="/admin-create-healthcoach-user" component={CreateHealthCoachUser} heartbeat={this.props.heartbeat} /> */}
                <AdminRoute path="/admin-view-healthcoaches" component={AdminHealthCoaches} heartbeat={this.props.heartbeat} />
              </>
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canB2BManagement) && (
              <>
                <AdminRoute path="/admin-b2b-management" component={AdminB2BManagement} heartbeat={this.props.heartbeat} />
              </>
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canMobileUserManagement) && (
              <>
                <AdminRoute path="/admin-mobile-change-user-week" component={AdminSetMobileUserWeek} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-delete-mobile-user" component={DeleteMobileUser} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-reset-mobile-user-week" component={AdminResetUserWeek} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-update-mobile-profile" component={AdminMobileContactInfo} heartbeat={this.props.heartbeat} />
              </>
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canRemapAccount) && (
              <>
                <AdminRoute path="/remap-account" component={MapLocalAccountToAthenaId} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/remap-associated-user-to-mobile" component={RemapMobileUserAssociatedUser} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/remap-pwn" component={RemapPwn} heartbeat={this.props.heartbeat} />
              </>
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canStripeUserManagement) && (
              <>
                <AdminRoute path="/admin-patient-stripe-change" component={ChangeStripeId} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-patient-helpscout-change" component={ChangeHelpscoutId} heartbeat={this.props.heartbeat} />
              </>
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.imp) && (
              <>
                <AdminRoute path="/admin-impersonate-patient/:id" exact component={ImpersonatingChange} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-impersonate-patient" exact component={ImpersonatingChange} heartbeat={this.props.heartbeat} />
              </>
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canReporting) && (
              <>
                <AdminRoute path="/admin-reports" component={AdminReports} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/admin-backfill" component={BigQueryBackfill} heartbeat={this.props.heartbeat} />
              </>
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canFeatureTogglesManagement) && (
              <>
                <AdminRoute path="/feature-toggles-management" component={FeatureTogglesManagement} heartbeat={this.props.heartbeat} />
              </>
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canBatchMigrateProviderGroups) && (
              <>
                <AdminRoute path="/batch-migrate-provider-group" component={BatchMapToNewProviderGroup} heartbeat={this.props.heartbeat} />
                <AdminRoute path="/run-pr" component={AdminRunPr} heartbeat={this.props.heartbeat} />
              </>
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canOneTimeCouponManagement) && (
              <AdminRoute path="/one-time-coupons-management" component={OneTimeCouponsManagement} heartbeat={this.props.heartbeat} />
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canGenerateOtp) && (
              <AdminRoute path="/generate-otp" component={AdminGenerateOtp} heartbeat={this.props.heartbeat} />
            )}
            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canViewAdminsLogs) && (
              <AdminRoute path="/admin-logs" component={AdminViewAdminLogs} heartbeat={this.props.heartbeat} />
            )}

            {(!t ? window._env_.REACT_APP_PALOMA_ADMIN_EMAIL === email : !!adm && !!adm.canAdminHealthCoachApp) && (
              <>
                <AdminRoute path="/hc-template-builder" component={HealthCoachFormBuilderWrap} />
                <AdminRoute path="/hc-template-list" component={HealthCoachFormsList} />
              </>
            )}

            <AdminRoute path="/reorder-physician-convo" component={AdminReorderPhysicianConvo} />
            <AdminRoute path="/admin-bulk-activate-survey-flag" component={AdminBulkSetSurveyFlag} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/referrals-management" component={AdminReferralList} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/batch-balance" component={BatchBalanceAdjustment} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/pending-registration" component={PendingProfileCompletionUsers} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/unregister-kit" component={UnregisterKitLocalDb} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/reset-intake-status" component={ResetPatientIntakeStatus} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/test-mobile-notifications-preview" component={AdminTestNotifications} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/my-logs" component={AdminViewMyLogs} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/duplicated-ids" component={AdminIdentifyDups} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/admin-batch-slots" component={AdminBatchCreateSlots} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/admin-care-managers" component={AdminCareManagers} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/admin-search-patients" component={AdminSearchPatient} heartbeat={this.props.heartbeat} />
            {/* <AdminRoute path="/AdminBannerFilterBuilder/:id/:versionId" component={AdminBannerFilterBuilder} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/AdminBannerFilterBuilder/:id" component={AdminBannerFilterBuilder} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/AdminBannerFilterBuilder" component={AdminBannerFilterBuilder} heartbeat={this.props.heartbeat} />
            <AdminRoute path="/AdminBannerFilterList" component={AdminBannerFilterList} heartbeat={this.props.heartbeat} /> */}

            {/* <Route
              heartbeat={this.heartbeat}
              exact
              path="/schedule/doc/:id"
              component={Schedule}
            /> */}
            <Route heartbeat={this.props.heartbeat} path="/schedule" component={Schedule} />
            <Route heartbeat={this.props.heartbeat} path="/scheduling" component={BookConsultationNoHypo} />
            <Route heartbeat={this.props.heartbeat} path="/scheduler" component={BookConsultation} />
            <Route heartbeat={this.props.heartbeat} path="/scheduler-meno" component={BookConsultationMeno} />
            <Route heartbeat={this.props.heartbeat} path="/schedule-split" component={ScheduleSplit} />
            <Route heartbeat={this.props.heartbeat} path="/schedulemeno" component={ScheduleMeno} />
          </Flex>
        </Outlet>

        <MergeAccountsModal />
        <>
          <SessionTimeout
            heartbeat={() => {
              // console.log('HB init')
              this.props.heartbeat();
            }}
            client={this.props.client}
            logout={() => {
              localStorage.removeItem('token');
              if (this.props.location && this.props.location.pathname) {
                localStorage.setItem('route', this.props.location.pathname);
              } else {
                localStorage.removeItem('route');
              }
              this.props.history.push('/logout');
            }}
          />
        </>
      </Container>
    );
  }
}

const RouteSection = ({ session, redirectTo, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const token = localStorage.getItem('token');

      if (token) {
        // rest.heartbeat()
        // return <Component {...props} />;
        // if (!rest.heartbeat()) {
        //   localStorage.setItem('route', props.location.pathname);
        //   return <Redirect to={{ pathname: '/login' }} />;
        // } else {
        return <Redirect to={{ pathname: redirectTo }} />;
        // }
      } else {
        localStorage.setItem('route', props.location.pathname);
        return <Redirect to={{ pathname: '/login' }} />;
      }
    }}
  />
);
const AdminRouteWithSession = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const token = localStorage.getItem('token');
      if (token) {
        const t = isToggleActive('PERSONAL_ADMIN');
        if (!rest.session || (!t ? rest.session.email !== window._env_.REACT_APP_PALOMA_ADMIN_EMAIL : !rest.session.adm)) {
          return <Redirect to={{ pathname: rest.fallback || '/' }} />;
        }
        // rest.heartbeat()
        // return <Component {...props} />;
        // if (!rest.heartbeat()) {
        //   localStorage.setItem('route', props.location.pathname);
        //   return <Redirect to={{ pathname: '/login' }} />;
        // } else {
        return <Component {...props} isAdminView />;
        // }
      } else {
        localStorage.setItem('route', props.location.pathname);
        return <Redirect to={{ pathname: '/login' }} />;
      }
    }}
  />
);

const AdminRoute = withSession(AdminRouteWithSession);

const NotDisqualifiedRouteWithSession = ({ component: Component, session, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { intakeStatus } = session;
      if (intakeStatus !== 'disqualified') {
        const token = localStorage.getItem('token');
        if (token) {
          // rest.heartbeat()
          // return <Component {...props} />;
          // if (!rest.heartbeat()) {
          //   localStorage.setItem('route', props.location.pathname);
          //   return <Redirect to={{ pathname: '/login' }} />;
          // } else {
          return <Component {...props} />;
          // }
        } else {
          localStorage.setItem('route', props.location.pathname);
          return <Redirect to={{ pathname: '/login' }} />;
        }
      } else {
        return <Redirect to={{ pathname: '/' }} />;
      }
    }}
  />
);

const DiagnosedHypoRouteWithSession = ({ component: Component, session, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      // const { intakeStatus, diagnoseFull } = session;
      // if (intakeStatus !== 'disqualified' && ((diagnoseFull && diagnoseFull.hasHypo) || (diagnoseFull && diagnoseFull.hasAppointment))) {

      const { intakeStatus, diagnoseFull, eligibility } = session;
      // if (intakeStatus !== 'disqualified' && diagnoseFull && (diagnoseFull.hasHypo || diagnoseFull.hasHashi || diagnoseFull.hasAppointmentNutritionist)) {
      if (diagnoseFull || eligibility.canBookPerimenopause || eligibility.canBookPostmenopause) {
        const token = localStorage.getItem('token');
        // console.log({ token, ...rest });
        if (token) {
          // rest.heartbeat()
          // return <Component {...props} />;
          // const hb= aw
          // if (!rest.heartbeat()) {
          //   console.log('no heartbeat')
          //   localStorage.setItem('route', props.location.pathname);
          //   return <Redirect to={{ pathname: '/login' }} />;
          // } else {
          return <Component {...props} />;
          // }
        } else {
          localStorage.setItem('route', props.location.pathname);
          return <Redirect to={{ pathname: '/login' }} />;
        }
      } else {
        return <Redirect to={{ pathname: '/' }} />;
      }
    }}
  />
);

const DiagnosedHypoOrHashiRouteWithSession = ({ component: Component, session, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { intakeStatus, diagnoseFull, eligibility } = session;
      if (intakeStatus !== 'disqualified' && diagnoseFull && (diagnoseFull.hasHypo || diagnoseFull.hasHashi || diagnoseFull.hasAppointmentNutritionist)) {
        // if (diagnoseFull || eligibility.canBookPerimenopause || eligibility.canBookPostmenopause) {
        const token = localStorage.getItem('token');
        if (token) {
          // rest.heartbeat()
          // return <Component {...props} />;
          // if (!rest.heartbeat()) {
          //   localStorage.setItem('route', props.location.pathname);
          //   return <Redirect to={{ pathname: '/login' }} />;
          // } else {
          return <Component {...props} />;
          // }
        } else {
          localStorage.setItem('route', props.location.pathname);
          return <Redirect to={{ pathname: '/login' }} />;
        }
      } else {
        return <Redirect to={{ pathname: '/' }} />;
      }
    }}
  />
);

const NutritionistWithSession = ({ component: Component, session, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { intakeStatus, diagnoseFull } = session;
      if (intakeStatus !== 'disqualified' && diagnoseFull && (diagnoseFull.hasHypo || diagnoseFull.hasHashi || diagnoseFull.hasAppointmentNutritionist)) {
        const token = localStorage.getItem('token');
        if (token) {
          // rest.heartbeat()
          // return <Component {...props} />;
          // if (!rest.heartbeat()) {
          //   localStorage.setItem('route', props.location.pathname);
          //   return <Redirect to={{ pathname: '/login' }} />;
          // } else {
          return <Component {...props} />;
          // }
        } else {
          localStorage.setItem('route', props.location.pathname);
          return <Redirect to={{ pathname: '/login' }} />;
        }
      } else {
        return <Redirect to={{ pathname: '/' }} />;
      }
    }}
  />
);

const NotDisqualifiedRoute = withSession(NotDisqualifiedRouteWithSession);
const DiagnosedHypoRouteWithSessionRoute = withSession(DiagnosedHypoRouteWithSession);
const DiagnosedHypoOrHashiRouteWithSessionRoute = withSession(DiagnosedHypoOrHashiRouteWithSession);

export default withApollo(withAnalytics(withSession(Dashboard)));
