import React, { useEffect, useState } from 'react';
import { Box, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import LabsChart from './LabsChart';
import LabsChartMobile from './LabsChartMobile';
import SymptomsTracked from './SymptomsTracked';

const SymptomsTrackedWrapMobile = styled(Box)`
  @media screen and (min-width: 480px) {
    width: 0px;
    height: 0px;
    /* display: none; */
  }
`;
const SymptomsTrackedWrap = styled(Box)`
  /* @media screen and (max-width: 480px) {
    width: 100%;
    height: 0;
    display: none;
  } */
`;

const Container = styled(Flex)`
  width: 100%;
  max-width: 700px;

  @media screen and (max-width: 480px) {
    width: 100vw;
    max-width: 100vw;
    /* padding: 0 20px; */
    overflow-x: hidden;
  }
`;

const H = styled(Heading)`
  @media screen and (max-width: 480px) {
    padding: 0 20px;
  }
`;

const YourHealthReport = () => {
  const [isMobileSize, setIsMobileSize]=useState(null)
  useEffect(() => {
    if (window.innerWidth > 425) {
      setIsMobileSize(false);
    } else {
      setIsMobileSize(true);
    }

    const updateMedia = () => {
      if (window.innerWidth > 425) {
        setIsMobileSize(false);
      } else {
        setIsMobileSize(true);
      }
    };
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);
  return (
    <Container flexDirection="column" mt="32px">
      <H mb="32px">Your Health Report</H>
      <SymptomsTracked />
      {isMobileSize === null?null: !isMobileSize?<LabsChart /> : <LabsChartMobile />}
    </Container>
  );
};

export default YourHealthReport;
