import React from 'react';
import { Flex, Text } from 'rebass';
import { GraphPink } from '../icons/GraphPink';
import { BigRightHormone, InnerLeftHormone, InnerRightHormone, MediumHormone, SmallHormone } from '../icons/InnerRightHormone';
import Odometer from '../components/RollingNumbers';
import styled from 'styled-components';
import { Bike } from '../icons/Bike';
import { Waiting } from '../icons/Waiting';
import { Clock } from '../icons/Clock';

const CText = styled(Text)`
  font-size: 50px;
  line-height: 90px;

  @media screen and (max-width: 52em) {
    font-size: 32px;
    line-height: 50px;
  }
`;

function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
}

export const TimeSavedBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>

<svg width='90%' viewBox="0 0 640 724" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', right: 0, top: 48 }}>
        <path d="M3.72974 361.339C21.9137 281.018 55.1433 209.432 108.424 147.162C124.479 128.809 139.6 109.655 153.729 89.773C206.657 13.5214 282.966 -6.85915 368.465 2.23461C458.988 11.8661 540.767 46.2223 606.413 110.785C668.852 172.2 702.05 249.897 702.135 336.895C702.171 373.388 684.683 411.973 668.051 446.144C630.97 522.342 578.718 588.097 520.27 649.627C463.662 709.196 394.385 728.403 316.828 722.956C237.771 717.411 164.273 690.855 97.0701 649.838C31.7719 609.982 2.47491 547.255 0.282278 471.917C-0.798641 435.132 2.44013 398.2 3.72974 361.339Z" fill="#FEFCFB" fill-opacity="0.3" />
      </svg>
      {window.matchMedia('(min-width: 52em)').matches ? (
        <Flex style={{ position: 'absolute', bottom: 0, left: 0 }}>
          <Clock />
        </Flex>
      ) : null}
    </Flex>
  );
};

export const TimeSaved = ({ minutesTravelSaved = 0, minutesWaiting = 0 }) => {
  const { minutes, hours } = toHoursAndMinutes(minutesTravelSaved + minutesWaiting);
  return (
    <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', zIndex: 100, flexDirection: 'column' }}>
      <CText style={{ fontWeight: 700, textAlign: 'center' }}>
        You have saved{' '}
        <span>
          <Odometer delay={0} startNumber={0} to={hours} style={{ color: '#F8A294', fontSize: window.matchMedia('(min-width: 52em)').matches ? 70 : 42, fontWeight: 'bold', width: '100%' }} />
        </span>{' '}hour      
        {hours > 1 ? 's' : ''}{' '}
        <span>
          <Odometer delay={0} startNumber={0} to={minutes} style={{ color: '#F8A294', fontSize: window.matchMedia('(min-width: 52em)').matches ? 70 : 42, fontWeight: 'bold', width: '100%' }} />
        </span>{' '}
        minute
        {minutes > 1 ? 's' : ''} using Paloma Health
      </CText>

      <Flex mt={4} style={{ width: '100%', alignItems:'center' }}>
        <Flex style={{ width: '80px' }} justifyContent={'center'} alignItems="center">
          <Bike />
        </Flex>
        <Text flex={1}>{`${minutesTravelSaved} minute${minutesTravelSaved > 1 ? 's' : ''} saved on travel`}</Text>
      </Flex>

      <Flex mt={3} style={{ width: '100%', alignItems:'center' }}>
        <Flex style={{ width: '80px' }} justifyContent={'center'} alignItems="center">
          <Waiting />
        </Flex>
        <Text flex={1}>{`${minutesWaiting} minute${minutesWaiting > 1 ? 's' : ''} saved on waiting, interacting with staff, or completing paperwork`}</Text>
      </Flex>
    </Flex>
  );
};
