import React, { useState } from 'react';
import { Section } from './components/Section';
import Paymentinformation from '../../static/Paymentinformation.png';
import ConsultsContent from './editing/ConsultsContent';
import { Query } from 'react-apollo';
import { ATHENA_CARD } from '../../graphql';
import { withToastManager } from 'react-toast-notifications';

const DC = ({toastManager}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Query query={ATHENA_CARD}>
      {({ data, loading, error }) => {
        if (loading) return null;

        return (
          <Section
            card={data.athenaCard}
            title="Visit"
            icon={Paymentinformation}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          >
            <ConsultsContent
              onSubmit={() => {
                setIsExpanded(false);
                toastManager.add('Card updated.', {
                  appearance: 'success',
                });
              }}
            />
          </Section>
        );
      }}
    </Query>
  );
};

export const DoctorsConsult = withToastManager(DC)