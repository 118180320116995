import React from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { Chat, Help, Phone, Fax } from '../components/icons';
import withSession from '../lib/withSession';
import { withRouter } from 'react-router-dom';

const Container = styled(Box)`
  width: 100%;
  max-width: 850px;
`;

const Icon = styled(Flex)`
  display: none;
  @media screen and (min-width: 52em) {
    display: block;
  }
`;

const Support = (props) => {
  const handleMessageSupport = () => {
    window.open('mailto:care@palomahealth.com');
  };

  return (
    <Container p={[3, 5]}>
      <Card mb={4} p={[3, 4]}>
        <Flex flexDirection={['column', 'row']} flex={1}>
          <Icon mr={[0, 4]} mb={[3, 0]} alignItems="flex-start" justifyContent="center">
            <Help fill="#394F76" width="40px" />
          </Icon>
          <Flex flexDirection="column" flex={1}>
            <Heading mb={4}>Get Help Quickly</Heading>
            <Text mb={4} lineHeight={1.6}>
              Browse our most frequently asked questions (FAQs) for quick answers about Paloma Health and our services.
            </Text>
            <Box>
              <Button variant="secondary" onClick={() => window.open('https://palomahealth.com/faq-2/faq-home')}>
                Browse FAQ
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Card>
      {!props.session || !props.session.isMember ? (
        <Card mb={4} p={[3, 4]}>
          <Flex flexDirection={['column', 'row']} flex={1}>
            <Icon mr={[0, 4]} mb={[3, 0]} alignItems="flex-start" justifyContent="center">
              <Chat fill="#394F76" width="40px" />
            </Icon>
            <Flex flexDirection="column" flex={1}>
              <Heading mb={4}>Message Support</Heading>
              <Text mb={4} lineHeight={1.6}>
                Email or chat with our support team. We will get back to you in less than 24 hours.
              </Text>
              <Flex>
                <Button variant="secondary" onClick={handleMessageSupport}>
                  Message Support
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      ) : (
        <Card mb={4} p={[3, 4]}>
          <Flex flexDirection={['column', 'row']} flex={1}>
            <Icon mr={[0, 4]} mb={[3, 0]} alignItems="flex-start" justifyContent="center">
              <Chat fill="#394F76" width="40px" />
            </Icon>
            <Flex flexDirection="column" flex={1}>
              <Heading mb={4}>Need Assistance?</Heading>
              <Text mb={4} lineHeight={1.6}>
                Your dedicated Care Manager is ready to help. Connect with us through the chat for personalized support and answers to all your questions.
              </Text>
              <Flex>
                <Button variant="secondary" onClick={() => props.history.push('/chat-care')}>
                  Chat with Care manager
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      )}
      <Card mb={4} p={[3, 4]}>
        <Flex flexDirection={['column', 'row']} flex={1}>
          <Icon mr={[0, 4]} mb={[3, 0]} alignItems="flex-start" justifyContent="center">
            <Phone fill="#394F76" width="40px" />
          </Icon>
          <Flex flexDirection="column" flex={1}>
            <Heading mb={4}>Call Our Support Team</Heading>
            <Text mb={4} lineHeight={1.6}>
              Need immediate support that isn’t included in our FAQs? Call our member support team, and they’ll be happy to help.
            </Text>
            <Flex>
              <Text>(434) 248-7508</Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      <Card mb={4} p={[3, 4]}>
        <Flex flexDirection={['column', 'row']} flex={1}>
          <Icon mr={[0, 4]} mb={[3, 0]} alignItems="flex-start" justifyContent="center">
            <Fax fill="#394F76" width="40px" />
          </Icon>
          <Flex flexDirection="column" flex={1}>
            <Heading mb={4}>Our Fax number</Heading>
            <Text mb={4} lineHeight={1.6}>
              Here is our fax number to provide your previous lab results and medical history.
            </Text>
            <Flex>
              <Text>(213) 340-5870</Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      <Text>
        If you are experiencing a crisis or an emergency, please don't use this tool and dial 911 or go to your nearest emergency room. You may also access the free, 24-hour National Suicide
        Prevention Lifeline at 1-800-273-8255 (1-800-273-TALK).
      </Text>
    </Container>
  );
};

export default withSession(withRouter(Support));
