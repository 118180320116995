import React from 'react';
import { withRouter } from 'react-router';
import { Box, Button, Card, Flex, Text } from 'rebass';
import styled from 'styled-components';

const Container = styled(Box)`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 0;
  @media screen and (max-width: 480px) {
    max-width: 100vw;
    padding-left: 6px;
    padding-right: 6px;
  }
`;

const Wrapper = styled(Flex)`
  flex: 1;
`;
const InsuranceMemberEditsAcknowledge = ({ history, match }) => {
  const flow = match && match.params && match.params.flow ? match.params.flow : '';
  return (
    <Wrapper>
      <>
        <Container>
          <Card p={4} style={{ borderRadius: '20px', minHeight: '176px', display: 'flex', justifyContent: 'center' }} mt={5}>
            <Flex>
              <Flex flexDirection={'column'} justifyContent="center">
                <Text fontSize={'23px'} fontWeight={600} textAlign="center">
                  {flow === 'delete' ? 'We received your insurance deletion request' : 'Your submission has been received'}
                </Text>
                {flow === 'delete' ? (
                  <Text mt={4} mb={4} fontWeight={400} textAlign="center">
                    Our team will process this change and will be in touch shortly.
                  </Text>
                ) : (
                  <Text mt={4} mb={4} fontWeight={400} textAlign="center">
                    Our team will review your insurance details and will be in touch shortly.
                    <br />
                    <br />
                    If your request is urgent, please reach out to your care manager
                  </Text>
                )}

                <Button
                  variant={'primary'}
                  onClick={() => {
                    if (!!window && !!window.ReactNativeWebView) {
                      window.ReactNativeWebView.postMessage('closeMobilePane');
                      return;
                    } else {
                      history.push('/');
                    }
                  }}
                >
                  Back to dashboard
                </Button>
              </Flex>
            </Flex>
          </Card>
        </Container>
      </>
    </Wrapper>
  );
};

export default withRouter(InsuranceMemberEditsAcknowledge);
