import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Button, Card, Flex, Heading } from 'rebass';
import { Formik, Form } from 'formik';
import TextInput from '../components/TextInput';
import { FormField } from '../components/FormField';
import { ADMIN_UPDATE_CUSTOMER_EMAIL } from '../graphql';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;

class ChangeEmail extends Component {
  handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const res = await this.props.client.mutate({
        mutation: ADMIN_UPDATE_CUSTOMER_EMAIL,
        variables: {
          oldEmail: values.oldemail,
          newEmail: values.newemail,
        },
      });

      if (res && res.data && res.data.adminUpdateCustomerEmail) {
        if (res.data.adminUpdateCustomerEmail.ok === true) {
          actions.resetForm();
          if (!res.data.adminUpdateCustomerEmail.customError) {
            this.props.toastManager.add('Updated successfully', {
              appearance: 'success',
            });
          }else{
            this.props.toastManager.add(res.data.adminUpdateCustomerEmail.customError.userMessage || 'Warning updating check console', { appearance: 'warning' });
            console.log('Unable to update email: ', res.data.adminUpdateCustomerEmail.customError.errorMessage);
          }
        } else {
          this.props.toastManager.add(res.data.adminUpdateCustomerEmail.customError.userMessage || 'Error updating', { appearance: 'error' });
          console.log('Unable to update email: ', res.data.adminUpdateCustomerEmail.customError.errorMessage);
        }
      } else {
        actions.setSubmitting(false);
        this.props.toastManager.add('Error updating, please try again', {
          appearance: 'error',
        });
      }

      actions.setSubmitting(false);
      //   toastManager.add('Error submitting intake form', { appearance: 'error' });
    } catch (err) {
      console.log('update email Err', err);
      actions.setSubmitting(false);
      this.props.toastManager.add('Error updating, please try again', {
        appearance: 'error',
      });
    }
  };

  render() {
    return (
      <Container>
        <Flex m={[3, 5]} flex={1} flexDirection="column">
          <Heading mb={4} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
            Change Patient Email
          </Heading>
          <Card mb={4} p={[3, 4]}>
            <Flex flexDirection={'column'} flex={1} alignItems="center">
              <Formik
                initialValues={{ oldemail: '', newemail: '' }}
                // validationSchema={schema}
                onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                render={({ errors, touched, isValid, isSubmitting, handleChange, handleBlur, values }) => (
                  <Form style={{ width: '300px' }}>
                    <Flex flexDirection="column" flex={1}>
                      <Heading mb={4}>Patient's Old Email</Heading>
                    </Flex>
                    <FormField mb={4} error={touched.email && errors.email}>
                      <TextInput placeholder="Old Email Address" type="email" name="oldemail" />
                    </FormField>
                    <Flex flexDirection="column" flex={1}>
                      <Heading mb={4}>Patient's New Email</Heading>
                    </Flex>
                    <FormField mb={4} error={touched.email && errors.email}>
                      <TextInput placeholder="New Email Address" type="email" name="newemail" />
                    </FormField>

                    <Button mt={4} mb={4} variant="primary" width={1 / 2} disabled={!isValid || isSubmitting} type="submit">
                      Change
                    </Button>
                  </Form>
                )}
              />
            </Flex>
          </Card>
        </Flex>
      </Container>
    );
  }
}

export default compose(
  withApollo,
  withToastManager
)(ChangeEmail);
