import React from 'react';
import { Flex } from 'rebass';
import moment from 'moment';
import {
  CustomerBubbleStyled,
  FromStyled,
  MessageTimeStyled,
  MessageTextStyled,
} from '../styled/bubbles';
import { attachmentThreadDisplay } from './AttachmentDisplay';

const CustomerBubble = ({ thread, key, mobileApp=false }) => {
  return (
    <Flex
      justifyContent="flex-end"
      style={{ marginBottom: '24px', marginRight: '20px' }}
    >
      <CustomerBubbleStyled mobileApp={mobileApp}>
        <Flex flexDirection="row" style={{ marginBottom: '16px' }}>
          <FromStyled fontSize="16px" fontWeight="600">
            You
          </FromStyled>
          <MessageTimeStyled>
            {moment(thread.createdAt).fromNow()}
          </MessageTimeStyled>
        </Flex>
        {thread._embedded && thread._embedded.attachments ? (
          <>
            {thread._embedded.attachments.map((x,index) => {
              return attachmentThreadDisplay(x, `${key}_${index}`);
            })}
          </>
        ) : null}
        <MessageTextStyled>
          {thread.body.replace(/<br\s*[\/]?>/gi, '\n')}
        </MessageTextStyled>
      </CustomerBubbleStyled>
    </Flex>
  );
};

export { CustomerBubble };
