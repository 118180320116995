import React from 'react';
import IconContainer from './IconContainer';

const Exclamation = props => (
  <IconContainer {...props}>
    <svg
      viewBox="0 0 4 18"
      height={props.height || '30px'}
      width={props.width || '30px'}
    >
      <circle cx="2" cy="16" r="2" fill={props.fill || '#fff'} />
      <path d="M0,0H4V12H0Z" fill={props.fill || '#fff'} />
    </svg>
  </IconContainer>
);

export default Exclamation;
