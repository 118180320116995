import React from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import Check from '../../../../components/icons/Check';
import moment from 'moment';
import { AppointmentAddToCalendar } from '../../../../components/addToCalendarButton/AppointmentAddToCalendar';

const InfoBox = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${(props) => props.theme.color.darkBluw};
  justify-content: center;
  max-width: 550px;
`;

const Icon = styled(Box)`
  display: block;
  margin: auto 0;
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const Confirmation = (props) => {
  return (
    <>
      <Card p={4} mb={5}>
        <Heading mb={4} textAlign="center">
          Appointment Details
        </Heading>

        <Flex justifyContent="center">
          <InfoBox mb={4}>
            <Icon>
              <Check width="50px" />
            </Icon>

            <Flex flexDirection="column" ml={[0, 4, 4]}>
              <Heading mb={2} textAlign="center">
                Your visit is confirmed
              </Heading>
              <Text fontWeight={700} textAlign="center" mb={4}>
                {`${moment(props.slot.startTime, 'HH:mm').format('h:mma')} on 
                        ${moment(props.slot.date, 'MM/DD/YYYY').format('dddd MMMM Do')}`}
              </Text>
              <AppointmentAddToCalendar appointment={{
                date: props.slot.date,
                startTime: props.slot.startTime,
                timezoneName: props.slot.timezoneName,
                duration: props.slot.duration,
                isMeno: props.isMeno,
              }} />
            </Flex>

            {props.couldntConfirmCard && (
              <Flex flexDirection="column" mb={4}>
                <Text style={{ fontWeight: 'bold' }}>Payment</Text>
                <Text>We could not confirm your credit card but your visit is booked. A care manager will reach out to confirm your payment details</Text>
              </Flex>
            )}
          </InfoBox>
        </Flex>
        <Text mb={4} fontSize={2} textAlign="center">
          You can cancel your appointment up to 4 hours before the start time without being charged
        </Text>

        <Flex flex={1} justifyContent="center" mb={[3, 0, 0]}>
          <Button
            variant="primary"
            width={[1, 1 / 2, 1 / 3]}
            onClick={() => {
              if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage('closeMobilePane');
              } else {
                props.history.push('/');
              }
            }}
          >
            Your Dashboard
          </Button>
        </Flex>
      </Card>
    </>
  );
};

export default Confirmation;
