import React, { Fragment } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';
import { compose, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import * as Yup from 'yup';

import { TextInput } from '../../components/TextInput';
import { FormField } from '../../components/FormField';
import { MaskInput } from '../../components/MaskInput';
import PlacesAutocomplete from '../../components/addressAutoComplete/PlacesAutocomplete';
import { withToastManager } from 'react-toast-notifications';
import RegionSelect from '../../components/RegionSelect';

const phoneRegExp = /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

const PHONE_AVAILABLE = gql`
  query PhoneAvailable($phone: String!) {
    phoneAvailable(phone: $phone) {
      ok
    }
  }
`;

class Profile extends React.Component {
  constructor(props) {
    super(props);

    const hasAddress =
      this.props.values &&
      this.props.values.street1 &&
      this.props.values.street1.length > 0;
    this.state = {
      isFormExtended: hasAddress,
    };
  }

  submitCheck = async (values, actions) => {
    const {
      isNewPatientFlow,
      enforceDepartment,
      toastManager,
      client,
      onSubmit,
      wizard,
    } = this.props;

    if (isNewPatientFlow && enforceDepartment) {
      if (values.state !== enforceDepartment.value) {
        toastManager.add(
          'Your address state differs from the state you selected in step 1',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        );
        actions.setSubmitting(false);
        return;
      }
    }
    try {
      const { data } = await client.query({
        query: PHONE_AVAILABLE,
        variables: {
          phone: values.phone,
        },
      });

      const resetMfa = values.phone !== this.props.values.phone;
      const vals = {
        ...values,
      };
      let force=''
      if (resetMfa) {
        vals.mfa = null;
        force='extended'
      }
      console.log(vals)
      actions.setSubmitting(false);

      if (!data.phoneAvailable.ok) {
        throw Error('Number already in use');
      } else {
        onSubmit(vals, actions, wizard);
      }
    } catch (error) {
      actions.setSubmitting(false);
      actions.setFieldError('phone', error.message);
    }
  };

  render() {
    const { isFormExtended } = this.state;
    const schema = Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .min(2)
        .required('Required'),
      lastName: Yup.string()
        .trim()
        .min(2)
        .required('Required'),
      street1: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      zip: Yup.string().required('Required'),
      phone: Yup.string()
        .trim()
        .required('Required')
        .matches(phoneRegExp, 'Phone number is not valid'),
    });

    return (
      <Flex flexDirection="column" alignItems="center">
        <Flex width={[1, 1, 3 / 4]}>
          <Card p={4}>
            <Flex flexDirection="column">
              <Box mb={4}>
                <Heading textAlign="center" fontWeight={550} mb={3}>
                  Set Up Your Profile
                </Heading>

                <Text textAlign="center">
                  Let's get started with some basic information.
                </Text>
              </Box>
              <Formik
                isInitialValid={({ initialValues }) =>
                  schema.isValidSync(initialValues)
                }
                initialValues={this.props.values}
                validationSchema={schema}
                onSubmit={(values, actions) =>
                  this.submitCheck(values, actions)
                }
                render={({
                  touched,
                  errors,
                  isValid,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  values,
                  setFieldValue,
                  setFieldTouched,
                }) => {
                  let errStreet1
                  if(touched.street1 && errors.street1){
                    errStreet1=errors.street1
                  }else if(values && values.street1 && values.street1.indexOf('@')>-1){
                    errStreet1='incorrect street address'
                  }else{
                    errStreet1=null
                  }
                  return (
                    <Form>
                      <Fragment>
                        <Flex flexDirection={['column', 'column', 'row']}>
                          <FormField
                            flex={[1, 1, 1 / 2]}
                            mb={3}
                            mr={[0, 0, 3]}
                            error={touched.firstName && errors.firstName}
                          >
                            <TextInput
                              placeholder="First Name"
                              type="text"
                              name="firstName"
                              id="input_firstname"
                            />
                          </FormField>
                          <FormField
                            flex={[1, 1, 1 / 2]}
                            mb={3}
                            error={touched.lastName && errors.lastName}
                          >
                            <TextInput
                              placeholder="Last Name"
                              type="text"
                              name="lastName"
                              id="input_lastname"
                            />
                          </FormField>
                        </Flex>

                        <div
                          style={{
                            maxHeight:
                              values.street1 || values.city || values.zip
                                ? '0px'
                                : '',
                            opacity:
                              values.street1 || values.city || values.zip
                                ? '0'
                                : '1',
                          }}
                        >
                          <FormField mb={3}>
                            <PlacesAutocomplete
                              fieldname="address"
                              onTextChanged={val => {
                                if (val && val.length > 0) {
                                  if (!isFormExtended) {
                                    this.setState({ isFormExtended: true });
                                  }
                                } else {
                                  if (
                                    !values.street1 &&
                                    !values.street2 &&
                                    !values.city &&
                                    !values.zip
                                  ) {
                                    this.setState({ isFormExtended: false });
                                  }
                                }
                              }}
                              onSelected={val => {
                                setFieldValue('street1', val.street);
                                setFieldValue('city', val.city);
                                setFieldValue('zip', val.zip);
                                setFieldValue('state', val.state);
                                setFieldTouched('street1');
                                setFieldTouched('city');
                                setFieldTouched('zip');
                                setFieldTouched('state');
                              }}
                              onBlur={val => {
                                // console.log('OnBlur');
                                if (!isFormExtended) {
                                  this.setState({
                                    isFormExtended: true,
                                    forceHideAutocomplete: true,
                                  });
                                }
                                if (
                                  !values.street1 &&
                                  !values.street2 &&
                                  !values.city &&
                                  !values.zip
                                ) {
                                  setFieldValue('street1', val);
                                  setFieldTouched('street1', true);
                                }
                              }}
                              street1FieldName="street1"
                              cityFieldName="city"
                              zipFieldName="zip"
                              stateFieldName="state"
                            />
                          </FormField>
                        </div>
                        {/* )} */}
                        {isFormExtended && (
                          <>
                            <FormField
                              mb={3}
                              error={errStreet1}
                            >
                              <TextInput
                                placeholder="Address"
                                type="text"
                                name="street1"
                              />
                            </FormField>

                            <FormField
                              mb={3}
                              error={touched.street2 && errors.street2}
                            >
                              <TextInput
                                placeholder="Apartment, Suite, Floor (Optional)"
                                type="text"
                                name="street2"
                              />
                            </FormField>

                            <Flex flexDirection={['column', 'column', 'row']}>
                              <FormField
                                width={[1, 1, 1 / 3]}
                                mr={[0, 0, 3]}
                                mb={3}
                                error={touched.city && errors.city}
                              >
                                <TextInput
                                  placeholder="City"
                                  type="text"
                                  name="city"
                                />
                              </FormField>

                              <FormField
                                width={[1, 1, 1 / 3]}
                                mr={[0, 0, 3]}
                                mb={3}
                                error={touched.state && errors.state}
                              >
                                <RegionSelect
                                  name="state"
                                  value={values.state}
                                />
                              </FormField>

                              <FormField
                                width={[1, 1, 1 / 3]}
                                mb={3}
                                error={touched.zip && errors.zip}
                              >
                                <TextInput
                                  placeholder="Zip"
                                  type="text"
                                  name="zip"
                                />
                              </FormField>
                            </Flex>
                          </>
                        )}

                        <FormField
                          mt={3}
                          mb={4}
                          error={touched.phone && errors.phone}
                        >
                          <MaskInput
                            name="phone"
                            placeholder="Mobile Phone Number"
                            mask="999-999-9999"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="input_phone"
                          />
                        </FormField>

                        <Flex justifyContent="center">
                          <Button
                            disabled={!isValid || isSubmitting  || !values || !values.street1 || values.street1.indexOf('@')>-1}
                            variant="primary"
                            width={[1 / 2, 1 / 3]}
                            type="submit"
                            id="btn_next"
                          >
                            Next
                          </Button>
                        </Flex>
                      </Fragment>
                    </Form>
                  );
                }}
              />
            </Flex>
          </Card>
        </Flex>
      </Flex>
    );
  }
}

export default compose(
  withApollo,
  withToastManager,
  withWizard
)(Profile);
