import React from 'react';
import { isMobile } from 'react-device-detect';

import AutoHideFollowButton from './ScrollToBottom/AutoHideFollowButton';
import Composer from './ScrollToBottom/Composer';
import Panel from './ScrollToBottom/Panel';

export default ({ checkInterval, children, debounce, followButtonClassName, mode, scrollViewClassName, onScroll, forwardRef, forwardRefPnl, paddingBottom = '100%' }) => {
  let stl = { position: 'relative', width: '100%' };
  if (isMobile) {
    stl = { position: 'relative', width: '100%', overflowY: 'auto', padding: '0px 6px', paddingBottom };
  }
  return (
    <Composer checkInterval={checkInterval} debounce={debounce} mode={mode === 'top' ? 'top' : 'bottom'} forwardRef={forwardRef} ref={forwardRefPnl}>
      <div style={stl}>
        <Panel
          // ref={forwardRefPnl}
          className={scrollViewClassName}
          onScroll={(e) => {
            if (onScroll) {
              onScroll(e);
            }
          }}
        >
          {children}
        </Panel>
        <AutoHideFollowButton className={followButtonClassName} />
      </div>
    </Composer>
  );
};
