import React from 'react';
import { Flex } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, Mutation, Query, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import Single from './Single';
import { InsuranceOnFile } from './components/InsuranceOnFile';
import { DELETE_INSURANCE, GET_INSURANCE_ON_FILE } from '../../../graphql';
import PleaseWait from '../../../components/PleaseWait';
import { RotatingText } from '../../../lib/components/RotatingText';
import { withRouter } from 'react-router';

const InsuranceHas2 = (props) => {
  const { onFileInsurance, useOnFileInsurance, deleteOnFileInsurance } = props;

  if (onFileInsurance && onFileInsurance.length > 0 && onFileInsurance.filter((x) => x.insuranceIdNumber === 'TmpIns-3').length < 1) {
    return (
      <Flex id="wrp">
        <InsuranceOnFile insurance={onFileInsurance} useOnFileInsurance={useOnFileInsurance} deleteOnFileInsurance={deleteOnFileInsurance} />
      </Flex>
    );
  }

  return <Single {...props} />;
};

const InsuranceHas = (props) => {
  const useOnFileInsurance = (onFileInsurance) => {
    const payload = {
      questionId: 'insurancecompany',
      answer: onFileInsurance.insuranceCompany,
      value: onFileInsurance.insuranceCompany,
      goToPage: 'insuranceeligibilitycheck',
      forceQuestionId: 'insurancecompany',
    };
    props.saveOnFileInsurance(onFileInsurance);
    props.onSubmit(payload, 'insurancecompany');
  };
  return (
    <Mutation mutation={DELETE_INSURANCE} refetchQueries={[{ query: GET_INSURANCE_ON_FILE }]} awaitRefetchQueries={true}>
      {(mutate, mutationProps) => (
        <Query query={GET_INSURANCE_ON_FILE}>
          {({ data, loading, error }) => {
            if (loading || mutationProps.loading) {
              return (
                <PleaseWait
                  text=" "
                  spinner={{
                    sColor: '#ECEFF2',
                    pColor: '#909FB5',
                  }}
                >
                  <RotatingText />
                </PleaseWait>
              );
            }
            if (error) {
              if (error) {
                console.log('err curr', error);
              }
              return null;
            }

            return (
              <InsuranceHas2
                {...props}
                onFileInsurance={data.getInsurancesOnFile}
                deleteOnFileInsurance={() => {
                  // console.log({ props });
                  if (props.session && props.session.isMember && props.session.membershipSpecific === 'insurance') {
                    props.history.push('/my-insurances/add');
                  } else {
                    mutate();
                  }
                }}
                useOnFileInsurance={useOnFileInsurance}
              />
            );
          }}
        </Query>
      )}
    </Mutation>
  );
};

export default compose(
  withRouter,
  withApollo,
  withWizard,
  withToastManager
)(InsuranceHas);
