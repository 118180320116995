import React, { Fragment, useState } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';

const WithMembershipCard = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px solid white;

  transition: background 150ms ease-in-out;
  &.selected {
    background: #e7f0ff;
    border: 1px solid #354e79;
    box-sizing: border-box;
  }
`;

const SelectedMarkerOut = styled(Flex)`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 2px solid #354e79;
  border-radius: 12px;
  margin-right: 12px;
`;

const SelectedMarkerIn = styled(Flex)`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #354e79;
  transition: transform 100ms ease-in-out;
  transform: scale(0);
  &.selected {
    transform: scale(1);
  }
`;

const ChoosePrice = ({ onSubmit }) => {
  const [selectedPrice, setSelectedPrice] = useState('MEMBERSHIP');
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1, paddingRight: '12px' }} pb="60px">
        <Box m={2} mt="0" mb={3}>
          <Heading textAlign="left">Choose your Price</Heading>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Text
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            Care now, pay later. You won't be charged until your Rx is sent to your pharmacy.
          </Text>
        </Box>
        <Flex flexDirection="column">
          <WithMembershipCard
            className={selectedPrice === 'MEMBERSHIP' ? 'selected' : ''}
            onClick={() => setSelectedPrice('MEMBERSHIP')}
          >
            <Flex
              flexDirection="column"
              ml="22px"
              mt="18px"
              mr="12px"
              mb="18px"
            >
              <Flex flexDirection="row">
                <Text
                  flex={1}
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="27px"
                >
                  With Paloma Membership
                </Text>
                <Text
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="27px"
                  color="#F6A293"
                >
                  $19
                </Text>
              </Flex>

              <ul>
                <li>
                  <Text fontWeight="300" fontSize="14px" lineHeight="20px">
                    Complete your visit and start saving - the membership pays
                    for itself in one visit.
                  </Text>
                </li>
                <li>
                  <Text fontWeight="300" fontSize="14px" lineHeight="20px">
                    Save up to 60% on future provider visits, supplements and
                    at-home test kits.
                  </Text>
                </li>
              </ul>

              <Text fontWeight="600" fontSize="12px" lineHeight="18px">
                First month free, $20/mo thereafter. Cancel anytime.
              </Text>
            </Flex>
            {selectedPrice === 'MEMBERSHIP' && (
              <Box
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  display: 'flex',
                }}
              >
                <svg
                  width="53"
                  height="43"
                  viewBox="0 0 53 43"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-0.337207 -7.98997L52.4171 -7.98984L24.1032 18.0524L-0.337166 42.9217L-0.337207 -7.98997Z"
                    fill="#354E79"
                  />
                </svg>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ position: 'absolute', top: '0', left: '0' }}
                >
                  <path
                    d="M14.0866 6.84591C14.2627 6.67827 14.497 6.58559 14.7401 6.58743C14.9832 6.58927 15.2161 6.68548 15.3897 6.85577C15.5632 7.02605 15.6638 7.25709 15.6702 7.50012C15.6766 7.74316 15.5884 7.97919 15.4241 8.15841L10.4366 14.3959C10.3508 14.4883 10.2473 14.5624 10.1323 14.6139C10.0172 14.6653 9.89294 14.693 9.76691 14.6954C9.64089 14.6977 9.51569 14.6746 9.39879 14.6274C9.2819 14.5803 9.17572 14.51 9.0866 14.4209L5.7791 11.1134C5.68699 11.0276 5.61311 10.9241 5.56187 10.8091C5.51063 10.6941 5.48308 10.5699 5.48086 10.4441C5.47864 10.3182 5.50179 10.1931 5.54895 10.0764C5.5961 9.95967 5.66628 9.85363 5.7553 9.76461C5.84432 9.67558 5.95037 9.6054 6.0671 9.55825C6.18384 9.5111 6.30887 9.48795 6.43475 9.49017C6.56063 9.49239 6.68477 9.51994 6.79977 9.57118C6.91477 9.62242 7.01827 9.6963 7.1041 9.78841L9.7216 12.4047L14.0628 6.87341C14.0707 6.86378 14.079 6.8546 14.0878 6.84591H14.0866Z"
                    fill="white"
                  />
                </svg>
              </Box>
            )}
          </WithMembershipCard>

          <Flex mt={3} flexDirection="column">
            <WithMembershipCard
              p="12px"
              onClick={() => setSelectedPrice('NONMEMBER')}
              className={selectedPrice === 'NONMEMBER' ? 'selected' : ''}
            >
              <Flex flexDirection="row">
                <SelectedMarkerOut>
                  <SelectedMarkerIn
                    className={selectedPrice === 'NONMEMBER' ? 'selected' : ''}
                  />
                </SelectedMarkerOut>

                <Flex flexDirection="column" flex={1}>
                  <Flex flexDirection="row">
                    <Text
                      fontSize="12px"
                      fontWeight="300"
                      mr="8px"
                      flex={1}
                      alignSelf="center"
                    >
                      Non-Member Price
                    </Text>
                    <Text fontWeight="600" fontSize="16px" lineHeight="27px">
                      $39
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </WithMembershipCard>
          </Flex>
        </Flex>

        <Button
          variant="primary"
          onClick={() => {
            onSubmit(selectedPrice);
          }}
          width={[1, 1 / 2, 1 / 4]}
          mt={4}
          alignSelf="center"
        >
          Continue
        </Button>

        {/* <Text fontSize="14px" fontWeight="300" mt={4}>
          * see{' '}
          <span
            style={{
              color: '#F6A293',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            here
          </span>{' '}
          for money back guarantee terms
        </Text> */}
      </Flex>
    </Fragment>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(ChoosePrice);
// export default withWizard(Single);
