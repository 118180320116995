import React from 'react';
import { Flex, Text } from 'rebass';

import styled from 'styled-components';
import { Icon } from '@iconify/react';
import editFilled from '@iconify/icons-ant-design/edit-filled';

const SelectedMedicationCardStyle = styled(Flex)`
  background-color: white;
  width: 100%;
  flex-direction: row;
  padding: 16px 20px;
  border: 1px solid #e7f0ff;
  border-radius: 10px;
`;

const SelectedMedicationCardName = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #354e79;
`;

const SelectedMedicationCardDescription = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
`;

export const SelectedMedicationCard = ({
  name,
  dose,
  freq,
  onClick,
  isInvalid = false,
}) => {
  return (
    <SelectedMedicationCardStyle
      mt={3}
      onClick={onClick}
      style={{
        borderColor: isInvalid ? 'red' : '#e7f0ff',
      }}
    >
      <Flex flexDirection="column" flex="1">
        <SelectedMedicationCardName>{name}</SelectedMedicationCardName>
        {!dose && !freq ? (
          <SelectedMedicationCardDescription>
            Add dose and frequency
          </SelectedMedicationCardDescription>
        ) : (
          <SelectedMedicationCardDescription>
            {!dose || !freq
              ? `Missing: ${!dose ? 'Dose' : 'Frequency'}`
              : `${dose || '?'} - ${freq || '?'}`}
          </SelectedMedicationCardDescription>
        )}
      </Flex>

      <Icon icon={editFilled} style={{ color: '#354e79', fontSize: '30px' }} />
    </SelectedMedicationCardStyle>
  );
};
