import React, { Fragment } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { Redirect, withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Mutation, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import gql from 'graphql-tag';
import styled from 'styled-components';

import TextInput from '../components/TextInput';
import FormField from '../components/FormField';
import FullLayout from '../components/FullLayout';
import Header from '../components/Header';
import withSession from '../lib/withSession';
import { CHANGE_PASSWORD, CURRENT_USER } from '../graphql';

const schema = Yup.object().shape({
  oldPassword: Yup.string()
    .trim()
    .required('Required'),
  newPassword: Yup.string()
    .trim()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.])(?=.{8,24})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  passwordConfirm: Yup.string()
    .trim()
    .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
    .required('Required'),
});

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const CardContainer = styled(Flex)`
  max-width: 400px;
`;

const AdminPasswordChange = ({ history, match, session: { adm }, toastManager }) => {
  const handleSubmit = async (values, passwordReset) => {
    const { newPassword, oldPassword, passwordConfirm } = values;
    if (newPassword !== passwordConfirm) {
      toastManager.add("Passwords don't match", { appearance: 'error' });
    }
    try {
      await passwordReset({ variables: { oldPassword, newPassword } });
      history.push('/')
    } catch (error) {
      error.graphQLErrors.forEach((error) => {
        toastManager.add(error.message, { appearance: 'error' });
      });
    }
  };

  return (
    <Fragment>
      <Container>
        <Flex flex={1}>
          <Header dark={false} />
        </Flex>
        <Flex flex={1} alignItems="center" justifyContent="center" mb={5}>
          <CardContainer flex={1} px={3}>
            <Card p={4}>
              <Flex flexDirection="column">
                <Box mb={4}>
                  <Heading textAlign="center" fontWeight={500} mb={3}>
                    Password Expiration
                  </Heading>

                  <Text>{adm.passwordExpired ? 'Your password is expired. Passwords are expiring after 90 days' : `Your password will expire in ${adm.daysBeforePasswordExpires} days`}</Text>
                </Box>
                <Mutation mutation={CHANGE_PASSWORD} refetchQueries={[{ query: CURRENT_USER }]} awaitRefetchQueries>
                  {(passwordReset, { loading, data }) => {
                    if (data) {
                      return <Redirect to="/login" />;
                    }

                    return (
                      <Formik
                        initialValues={{
                          oldPassword: '',
                          newPassword: '',
                          passwordConfirm: '',
                        }}
                        validationSchema={schema}
                        onSubmit={(values) => handleSubmit(values, passwordReset)}
                        render={({ touched, errors, isValid }) => (
                          <Form>
                            <FormField mb={3} error={touched.oldPassword && errors.oldPassword}>
                              <TextInput placeholder="Current Password" type="password" name="oldPassword" />
                            </FormField>

                            <FormField mb={3} error={touched.newPassword && errors.newPassword}>
                              <TextInput placeholder="New Password" type="password" name="newPassword" />
                            </FormField>

                            <FormField mb={4} error={touched.passwordConfirm && errors.passwordConfirm}>
                              <TextInput placeholder="Re-enter New Password" type="password" name="passwordConfirm" />
                            </FormField>

                            <Flex justifyContent="center" flexDirection={'row'} alignItems={'center'}>
                              <Button disabled={!isValid || loading} variant="primary" type="submit">
                                Change Password
                              </Button>
                              {!adm.passwordExpired && (
                                <Button onClick={() => history.push('/')} ml={3} disabled={!isValid || loading} variant="outline" type="button">
                                  Not Now
                                </Button>
                              )}
                            </Flex>
                          </Form>
                        )}
                      />
                    );
                  }}
                </Mutation>
              </Flex>
            </Card>
          </CardContainer>
        </Flex>
      </Container>

      <FullLayout />
    </Fragment>
  );
};

export default withRouter(withApollo(withToastManager(withSession(AdminPasswordChange))));
