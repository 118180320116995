import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Card, Flex, Text } from 'rebass';
import { ArticlesRead, ArticlesReadBackground } from '../pages/ArticlesRead';
import { TopCategories, TopCategoriesBackground } from '../pages/TopCategories';
import { CoursesCount, CoursesCountBackground } from '../pages/CoursesCount';
import { AipProgress, AipProgressBackground } from '../pages/AipProgress';
import { CarouselCard } from '../YearlyReview';
import { PlateHeart } from '../icons/PlateHeart';
import PleaseWait from '../../../components/PleaseWait';

const GET_EDUCATION = gql`
  query getMemberYearlyReviewEducation($from: String, $to: String) {
    getMemberYearlyReviewEducation(from: $from, to: $to) {
      id
      articlesReadCount
      topCategories {
        category
        times
      }
      recipesTriedCount
      byWeeks {
        week
        progress
      }
      wentThroughCourses
    }
  }
`;

const SECTION_BACKGROUND = '#FDF5F2';
const SECTION_NAME = 'Education';

const getStories = (data) => {
  const progress = [];
  for (let index = 0; index < 12; index++) {
    const element = (data.byWeeks || []).find((x) => x.week === index + 1);
    progress.push(Math.round((((element || {}).progress || []).reduce((a, b) => a + b, 0) || 0) / (((element || {}).progress || []).length || 1)));
  }
  return [
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#FDF5F2',
      duration: 3000,
      content: <ArticlesRead articlesCount={data.articlesReadCount || 0} recipesCount={data.recipesTriedCount || 0} />,
      background: <ArticlesReadBackground />,
      show: (data.articlesReadCount || 0) + (data.recipesTriedCount || 0) > 4,
      analytics:{
        section: 'Education',
        screen: 'ArticlesReadCount',
        metadata:{
          articleReadCount: data.articlesReadCount || 0,
        }
      }
    },
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#FDF5F2',
      duration: 5000,
      content: <TopCategories categories={(data.topCategories || []).slice(0, 4).map((x) => x.category)} />,
      background: <TopCategoriesBackground />,
      show: (data.articlesReadCount || 0) > 4,
      analytics:{
        section: 'Education',
        screen: 'TopCategories',
        metadata:{
          topCategories: (data.topCategories || []).slice(0, 4).map(x => x.category).join(', '),
        }
      }
    },
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#FDF5F2',
      duration: 3000,
      content: <CoursesCount coursesCount={data.wentThroughCourses || 0} />,
      background: <CoursesCountBackground />,
      show: true,
      analytics:{
        section: 'Education',
        screen: 'CoursesCount',
        metadata:{
          coursesCount: data.wentThroughCourses || 0,
        }
      }
    },
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#FDF5F2',
      duration: 5000,
      content: <AipProgress progress={progress} />,
      background: <AipProgressBackground />,
      show: true,
      analytics:{
        section: 'Education',
        screen: 'ProgressAIP',
        metadata:{
          progress: progress.map((x,i)=>{return `Week ${i + 1} - ${x}%`}).join(', '),
        }
      }
    },
    // {
    //   progressStyle: {
    //     foreground: '#344F7A',
    //   },
    //   backgroundColor: '#DBE6F9',
    //   duration: 4000,
    //   content: <ExercisesCount exerciseCount={23} />,
    //   background: <ExercisesCountBackground />,
    // },
    // {
    //   progressStyle: {
    //     foreground: '#344F7A',
    //   },
    //   backgroundColor: '#DBE6F9',
    //   duration: 5000,
    //   content: <WorkoutsCount yogaCount={12} workoutsCount={6} />,
    //   background: <WorkoutsCountBackground />,
    // },
    // {
    //   progressStyle: {
    //     foreground: '#344F7A',
    //   },
    //   backgroundColor: '#FDF5F2',
    //   duration: 4000,
    //   content: <MeditationsCount breathingExerciseCount={8} meditationsCount={15} />,
    //   background: <MeditationsCountBackground />,
    // },
    // {
    //   progressStyle: {
    //     foreground: '#344F7A',
    //   },
    //   backgroundColor: '#DBE6F9',
    //   duration: 5000,
    //   content: <EducationScore score={95} />,
    //   background: <EducationScoreBackground />,
    // },
  ];
};

const EducationSectionInner = ({ isActive, setActive, data, active, onAllStoriesEnd, onClose }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [stories, setStories] = useState();
  useEffect(
    () => {
      if (!!isLoaded) return;
      setStories(getStories(data).filter((x) => !!x.show));
      setIsLoaded(true);
    },
    [data, isLoaded]
  );

  if (!isLoaded) return <div />;

  return (
    <CarouselCard
      onClose={onClose}
      stories={stories}
      sectionName={SECTION_NAME}
      section={{ inactiveIcon: <PlateHeart /> }}
      isActive={isActive}
      backgroundColor={SECTION_BACKGROUND}
      onAllStoriesEnd={onAllStoriesEnd}
    />
  );
};

export const EducationSection = ({ isActive, setActive, active, onAllStoriesEnd, onClose }) => {
  if (!isActive)
    return (
      <Card style={{ height: '100vh', width: '100%', backgroundColor: '#c4c8d3', borderRadius: '30px', padding: '18px' }}>
        <Flex
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <PlateHeart />
          <Text style={{ fontSize: '32px', color: 'white' }}>{SECTION_NAME}</Text>
        </Flex>
      </Card>
    );
  return (
    // <Flex style={{ flex: 1, width: '100%', height: '100%', background: 'red' }}>
    <Query query={GET_EDUCATION}>
      {({ loading, data, error }) => {
        if (loading)
          return (
            <Card style={{ height: '100vh', width: '100%', backgroundColor: SECTION_BACKGROUND, borderRadius: '30px', padding: '18px' }}>
              <Flex
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  height: '100%',
                  flexDirection: 'column',
                }}
              >
                <PleaseWait
                  textStyle={{
                    color: '#344F7A',
                  }}
                  spinner={{
                    pColor: '#c4c8d3',
                    sColor: '#344F7A',
                  }}
                  text="Loading your education"
                />
              </Flex>
            </Card>
          );

        return (
          <EducationSectionInner onClose={onClose} isActive={isActive} active={active} setActive={setActive} data={(data || {}).getMemberYearlyReviewEducation} onAllStoriesEnd={onAllStoriesEnd} />
        );
      }}
    </Query>
    // </Flex>
  );
};
