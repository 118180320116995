import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { Query, withApollo } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import moment from 'moment';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';
import gql from 'graphql-tag';
import { Form, Formik } from 'formik';
import FormField from '../components/FormField';
import TextInput from '../components/TextInput';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('invalid email address')
    .required('Required'),
});

const GET_ADMIN_LOGS = gql`
  query getAdminOtherAdminLogs($email: String, $start: String, $end: String) {
    getAdminOtherAdminLogs(email: $email, start: $start, end: $end) {
      id
      createdAt
      description
      action
      actionType
      ip
      email
    }
  }
`;

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;

const SaveList = ({ list, title }) => {
  // set up local state for generating the download link
  const [downloadLink, setDownloadLink] = useState('');

  // function for generating file and set download link
  const makeTextFile = () => {
    const items = list.map(
      x => ({
        date: x.createdAt,
        action: x.action,
        actionType: x.actionType,
        description: x.description,
        ip: x.ip,
        email: x.email,
      })
    );
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    const csv = [
      header.join(','), // header row first
      ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')),
    ].join('\r\n');

    console.log(csv);
    // This creates the file.
    // In my case, I have an array, and each item in
    // the array should be on a new line, which is why
    // I use .join('\n') here.
    const data = new Blob([csv], { type: 'text/plain' });

    // this part avoids memory leaks
    if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink);

    // update the download link state
    setDownloadLink(window.URL.createObjectURL(data));
  };

  // Call the function if list changes
  useEffect(
    () => {
      makeTextFile();
    },
    [list, title]
  );

  return (
    <a
      // this attribute sets the filename
      download={`${title}.csv`}
      // link to the download URL
      href={downloadLink}
    >
      download
    </a>
  );
};

const AdminViewAdminLogs = () => {
  const [adminEmail, setAdminEmail] = useState('');
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [sd, setSD] = useState();
  const [ed, setED] = useState();

  useEffect(
    () => {
      const a = moment(selectedDate, 'YYYY-MM-DD');
      const start = a.startOf('day').utc();
      const b = moment(selectedDate, 'YYYY-MM-DD');
      const end = b.endOf('day').utc();
      setSD(start.toISOString());
      setED(end.toISOString());
      console.log({ s: start.toISOString(), e: end.toISOString() });
    },
    [selectedDate]
  );
  const columns = [
    // {
    //   Header: '',
    //   accessor: 'flag',
    //   width: 40,
    //   Cell: (row) => (
    //     <span data-tip={(row.value || '') === 'Same IP' ? 'Same IP address' : null} data-type={(row.value || '') === 'Same IP' ? 'warning' : null}>
    //       {' '}
    //       <FontAwesomeIcon size="1x" color={(row.value || '') === 'Same IP' ? 'red' : '#0cac0c'} icon={faFlag} />
    //     </span>
    //   ),
    // },
    {
      Header: 'action',
      accessor: 'action',
      filterMethod: (filter, row) => row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1,
    },
    {
      Header: 'actionType',
      accessor: 'actionType',
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: '100%', height: '100%' }} value={filter ? filter.value : 'all'}>
          <option value="all">Show All</option>
          <option value="INFORMATION">INFORMATION</option>
          <option value="SECURITY">SECURITY</option>
          <option value="ACTION">ACTION</option>
        </select>
      ),
    },
    {
      Header: 'description',
      accessor: 'description',
      filterMethod: (filter, row) => row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1,
    },
    {
      Header: 'date',
      accessor: 'createdAt',
      Cell: (row) => <Text textAlign="left">{moment(row.value).format('MM-DD-YYYY hh:mm a')}</Text>,
      filterable: false,
    },
    {
      Header: 'ip',
      accessor: 'ip',
      filterable: false,
    },
    {
      Header: 'email',
      accessor: 'email',
    },
  ];

  return (
    <Container>
      <Heading mb={4} textAlign={'center'}>
        View Other Admin Logs
      </Heading>
      <input
        type="date"
        id="start"
        name="trip-start"
        value={selectedDate}
        onSelect={(a) => console.log(a)}
        onChange={(e) => {
          setSelectedDate(e.target.value);
          console.log(e.target.value);
        }}
      />
      {!!sd &&
        !!ed && (
          <Query query={GET_ADMIN_LOGS} fetchPolicy="network-only" variables={{ email: adminEmail, start: sd, end: ed }}>
            {({ data: { getAdminOtherAdminLogs }, loading, error }) => {
              if (!!error) return null;
              return (
                <>
                  {/* <Formik
                    initialValues={{
                      email: '',
                    }}
                    validationSchema={schema}
                    onSubmit={(values) => {
                      setAdminEmail(values.email);
                    }}
                    render={({ isValid, errors, touched, values, setFieldValue }) => (
                      <Form>
                        <Flex flexDirection={['column', 'row']} mt={4} mb={3}>
                        <FormField width={[1, 1 / 2]} mr={[0, 3]} error={touched.email && errors.email}>
                          <TextInput placeholder="Admin's email" type="text" name="email" />
                        </FormField>

                        <Flex justifyContent="center" flexDirection="column" alignItems="center">
                          <Button disabled={!isValid} variant="pink" type="submit">
                            Filter
                          </Button>
                        </Flex>
                        </Flex>
                      </Form>
                    )}
                  /> */}
                    {!!getAdminOtherAdminLogs && <SaveList list={getAdminOtherAdminLogs} title={`adminlogs-${selectedDate}`} />}
                <ReactTooltip place="left" type="success" effect="solid" />
                  <ReactTable
                  style={{marginTop: '28px'}}
                    data={getAdminOtherAdminLogs}
                    columns={columns}
                    filterable={true}
                    loading={loading}
                    onPageChange={(x) => {
                      console.log('page'.x);
                    }}
                    showPagination={true}
                    showPageSizeOptions={true}
                    pageSizeOptions={[20, 25, 50, 100]}
                    defaultPageSize={100}
                  />
                </>
              );
            }}
          </Query>
        )}
    </Container>
  );
};

export default withApollo(AdminViewAdminLogs);
