import React from 'react';
import { Box, Heading, Flex, Button, Text } from 'rebass';
import { Mutation, withApollo } from 'react-apollo';
import styled from 'styled-components';
import { withToastManager } from 'react-toast-notifications';
import gql from 'graphql-tag';
import { Input } from '../components/TextInput';

const SEND_NOTIFICATION = gql`
  mutation adminSendBatchPushNotification($subtitle: String, $title: String, $body: String, $route: String!) {
    adminSendBatchPushNotification(subtitle: $subtitle, title: $title, body: $body, route: $route) {
      ok
    }
  }
`;

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;

const AdminSendMobileNotifications = ({ toastManager }) => {
  const [route, setRoute] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [externalUrl, setExternalUrl] = React.useState('');
  const [subtitle, setSubtitle] = React.useState('');
  const [body, setBody] = React.useState('');
  const [id, setId] = React.useState('');

  return (
    <Mutation
      mutation={SEND_NOTIFICATION}
      onCompleted={() => {
        toastManager.add('Notification sent', {
          appearance: 'success',
          autoDismissTimeout: 5000,
        });
      }}
      onError={(err) => {
        console.log(err);
        toastManager.add('An error occured. See console', {
          appearance: 'error',
          autoDismissTimeout: 5000,
        });
      }}
    >
      {(sendNotification, { loading }) => (
        <Container>
          <Heading style={{ marginBottom: '32px' }}>Send Mobile Notifications</Heading>
          <Input
            placeholder="Title"
            onChange={(r) => {
              setTitle(r.target.value);
            }}
            value={title}
          />
          <Input
            style={{ marginTop: '16px' }}
            placeholder="Body"
            onChange={(r) => {
              setBody(r.target.value);
            }}
            value={body}
          />
          <Input
            style={{ marginTop: '16px' }}
            placeholder="Route"
            onChange={(r) => {
              setRoute(r.target.value);
            }}
            value={route}
          />
          <Box style={{ border: '1px solid #cdcdcd', padding: '24px', backgroundColor: 'white' }} mt={3}>
            <Text mb={2}>Some routes examples:</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Profile`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Profile`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}trackerManager`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}trackerManager`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Symptoms`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Symptoms`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Home`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Home`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Settings`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Settings`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}careMessage`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}careMessage`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}community`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}community`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}book/doctor`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}book/doctor`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}book/nutritionist`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}book/nutritionist`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}VideoVisits`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}VideoVisits`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}viewArticle/:article?`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}viewArticle/:article?`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}viewRecipe/:recipe?`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}viewRecipe/:recipe?`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}registerKit`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}registerKit`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Articles`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Articles`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Recipes`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Recipes`}</Text>
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}MedicationReminders`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}MedicationReminders`}</Text>
            
            <Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}plan/:title`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}plan/:title`}</Text>

<Text
              style={{ cursor: 'pointer' }}
              color="#939393"
              ml={3}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}boostActivity/:title`)}
            >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}boostActivity/:title`}</Text>

            <Text
                          style={{ cursor: 'pointer' }}
                          color="#939393"
                          ml={3}
                          onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Meditations`)}
                        >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Meditations`}</Text>

                        <Text
                                      style={{ cursor: 'pointer' }}
                                      color="#939393"
                                      ml={3}
                                      onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}BreathingExercises`)}
                                    >{`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}BreathingExercises`}</Text>

            <Text
              style={{ cursor: 'pointer' }}
              mt={4}
              onClick={() => setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Home`)}
            >{`If no specific redirection needed, use: ${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}Home`}</Text>
          </Box>
          <Box style={{ border: '1px solid #cdcdcd', padding: '24px', backgroundColor: 'white' }} mt={3}>
            <Text mb={2}>Redirect to external link:</Text>
            <Input
              placeholder="External url"
              onChange={(r) => {
                setExternalUrl(r.target.value);
              }}
              value={externalUrl}
            />
            <Button
              type="button"
              onClick={() => {
                const u = encodeURIComponent(externalUrl);
                setRoute(`${window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile://' : 'palomamobilestaging://'}redirectto/${u}`);
              }}
              disabled={!externalUrl}
              style={{ borderRadius: '8px' }}
              variant="pink"
            >
              Get Route
            </Button>
          </Box>

          <Flex justifyContent={'center'} alignItems={'center'} my="12px">
            <Button
              onClick={() =>
                sendNotification({
                  variables: {
                    body,
                    title,
                    subtitle,
                    route,
                  },
                })
              }
              disabled={!route || (!body && !title) || loading}
              style={{ borderRadius: '8px' }}
              variant="primary"
            >
              Send
            </Button>
          </Flex>
        </Container>
      )}
    </Mutation>
  );
};

export default withToastManager(withApollo(AdminSendMobileNotifications));
