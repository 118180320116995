import React from 'react';
import { withWizard } from 'react-albus';
import { withRouter } from 'react-router';
import { Button, Card, Heading, Text, Flex } from 'rebass';
import styled from 'styled-components';

const A = styled(Text)`
  text-decoration: underline;
  margin-top: 28px;
  text-align: center;
  cursor: pointer;
`;

const Confirm = ({
  wizard,
  goBack,
  confirm,
  type,
  canPause,
  history,
  subscriptionId,
}) => {
  const text =
    type === 'subscription_vitamin'
      ? `Too many leftover pills? You don’t have to cancel, you can pause your subscription and take over when you are ready.`
      : `You don’t have to cancel, you can pause your subscription and take over when you are ready. `;
  return (
    <Flex flexDirection="column">
      <Card
        style={{
          flexDirection: 'column',
          textAlign: 'center',
          padding: '32px',
        }}
      >
        <Heading>Are You Sure?</Heading>

        <Text
          textAlign="center"
          style={{ maxWidth: '400px', margin: '32px auto 0 auto' }}
        >
          {text}
        </Text>
        {canPause && (
          <Button
            style={{ marginTop: '28px' }}
            variant="primary"
            onClick={() => {
              history.push({
                pathname: '/pause-subscription',
                state: {
                  id: subscriptionId,
                  type
                },
              });
            }}
          >
            Pause Subscription
          </Button>
        )}

        <A onClick={() => confirm(wizard)}>Cancel Subscription</A>
      </Card>
      <A onClick={goBack}>Go Back</A>
    </Flex>
  );
};

export default withRouter(withWizard(Confirm));
