import React, { useEffect, useState } from 'react';
import { Button, Flex, Text } from 'rebass';
import { sleep } from '../../../utils/sleep';
import { TextAreaNoForm } from '../../TextArea';
import { SelectButton } from '../components/Shared';
import { getDefaultShowDetails, getDefaultValueMultipleDetails, getDefaultValueSingle } from '../utils/extractBackResponse';

const OpenTextLayout = ({ options, onNext, nextTitle, defaultValue, onSetWaitingUserInput, isActive, id, isMandatory }) => {
  const [selected, setSelected] = useState(getDefaultValueSingle(defaultValue));
  const [detailsValue, setDetailsValue] = useState(getDefaultValueMultipleDetails(defaultValue));

  useEffect(
    () => {
      if (!isActive) return;
      
      if (!!detailsValue && detailsValue.length > 1) {
        onSetWaitingUserInput(false);
      } else {
        onSetWaitingUserInput(true);
      }
    },
    [detailsValue, selected, isActive]
  );

  return (
    <>
      <TextAreaNoForm
        multiline
        containerStyle={{ display: 'block', flex: 'initial' }}
        style={{
          fontSize: '18px',
          width: '100%',
          minHeight: '100px',
        }}
        placeholder="Enter details here"
        onChange={(r) => {
          setDetailsValue(r.target.value);
        }}
        value={detailsValue}
      />
      <Button
        style={{ alignSelf: 'flex-start' }}
        mt={4}
        disabled={!!isMandatory && (!detailsValue || detailsValue.length < 2)}
        variant={'primary'}
        onClick={() => {
          const selection = [];

          selection.push({
            value: 'free text',
            details: detailsValue,
          });

          onNext({
            id,
            selection,
          });
        }}
      >
        {nextTitle || `Let's do it!`}
      </Button>
    </>
  );
};

export default OpenTextLayout;
