export const phoneRegExp = /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

export const MedicalConditionsFlower = (
  values,
  currentStep,
  getState,
  BookFlowQuestions
) => {
  const state = getState();

  const vals = [...state.values];

  const flowOrdered = vals
    .find(x => x.id === 'conditions')
    .values.filter(x => x.flowOrder > currentStep)
    .sort((a, b) => {
      if (a.flowOrder < b.flowOrder) return -1;
      if (a.flowOrder > b.flowOrder) return 1;

      return 0;
    });

  if (!flowOrdered || flowOrdered.length < 1) {
    return 'othermedical';
  }

  const questions = BookFlowQuestions.find(x => x.id === 'conditions');

  const nextQuestion = questions.options.find(
    x => x.questionId === flowOrdered[0].questionId
  );
  
  return nextQuestion.goto;
};
