import React, { Fragment, useState } from 'react';
import { Flex, Box, Button, Text, Card } from 'rebass';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import ScrollAnimation from 'react-animate-on-scroll';
import { fadeInDown, fadeInUp } from '../../utils/animationsStyleBuilder';

const StyledText = styled(Text)`
  line-height: 1.6;
  border-bottom: 1px solid;
  /* color: white; */
  text-align: center;

  &:hover {
    cursor: pointer;
    font-weight: 600;
  }
`;

const SelectedMarkerOut = styled(Flex)`
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #354e79;
  transition: background 150ms ease-in-out;
  width: 16px;
  height: 16px;
  margin-right: 20px;
  margin-top: 2px;
  justify-content: center;
  align-items: center;
  &.isChecked {
    background: #354e79;
  }
`;

const ItemWrap = styled(Box)`
  border-right: none;
  border-bottom: ${(props) => (props.ii + 1 === props.arr.length ? '' : `1px solid #b9ceef`)};
  background-color: ${(props) => (props.chosen ? '#E7F0FF' : 'rgba(255,255,255,0.45)')};
  @media screen and (min-width: 52em) {
    border-right: ${(props) => (props.ii % 2 === 1 ? 'none' : `1px solid #b9ceef`)};
    border-bottom: ${(props) =>
      props.arr.length % 2 == 0
        ? props.ii + 1 === props.arr.length || props.ii + 2 === props.arr.length
          ? 'none'
          : `1px solid #b9ceef`
        : props.ii + 1 === props.arr.length
          ? 'none'
          : `1px solid #b9ceef`};
  }
`;

const FlexItemContainer = styled(Flex)`
  margin: 24px 0;
  @media screen and (max-width: 52em) {
    margin: 24px 12px;
  }
`;

const formatSubmit = (values) => {
  console.log({ values });
  return values.map((x) => x).join(', ');
};

const MultipleConcatString = ({ data, onSubmit, wizard }) => {
  const [selected, setSelected] = useState([]);
  const [interacted, setInteracted] = useState(false);

  const handleChosen = (element) => {
    if (element.skip) {
      return wizard.push(element.skip);
    }

    if (selected.findIndex((x) => x === element.answer) > -1) {
      setSelected(selected.filter((x) => x !== element.answer));
    } else {
      const a = [...selected, element.answer];
      setSelected(a);
    }

    setInteracted(true);
  };

  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
        <Box m={2} style={{ ...fadeInDown() }}>
          <Text fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
            {data.prompt}
          </Text>
        </Box>

        <FlexItemContainer
          mt={3}
          mb={3}
          my={3}
          flex={1}
          flexDirection="row"
          flexWrap={'wrap'}
          style={{
            border: `1px solid #b9ceef`,
            overflow: 'hidden',
            borderRadius: '12px',
            ...fadeInUp(20, '1s', '0ms'),
          }}
        >
          {data.options.map((el, ii, arr) => (
            <ItemWrap
              key={el.questionId || el.value}
              p="12px"
              width={['100%', '50%']}
              onClick={() => handleChosen(el)}
              ii={ii}
              arr={arr}
              chosen={selected.indexOf(el.answer) > -1}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Flex flexDirection="row" alignSelf={'flex-start'}>
                <SelectedMarkerOut className={selected.indexOf(el.answer) > -1 ? 'isChecked' : ''}>
                  {selected.indexOf(el.answer) > -1 && (
                    <svg width="38px" height="38px" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.0866 6.84591C14.2627 6.67827 14.497 6.58559 14.7401 6.58743C14.9832 6.58927 15.2161 6.68548 15.3897 6.85577C15.5632 7.02605 15.6638 7.25709 15.6702 7.50012C15.6766 7.74316 15.5884 7.97919 15.4241 8.15841L10.4366 14.3959C10.3508 14.4883 10.2473 14.5624 10.1323 14.6139C10.0172 14.6653 9.89294 14.693 9.76691 14.6954C9.64089 14.6977 9.51569 14.6746 9.39879 14.6274C9.2819 14.5803 9.17572 14.51 9.0866 14.4209L5.7791 11.1134C5.68699 11.0276 5.61311 10.9241 5.56187 10.8091C5.51063 10.6941 5.48308 10.5699 5.48086 10.4441C5.47864 10.3182 5.50179 10.1931 5.54895 10.0764C5.5961 9.95967 5.66628 9.85363 5.7553 9.76461C5.84432 9.67558 5.95037 9.6054 6.0671 9.55825C6.18384 9.5111 6.30887 9.48795 6.43475 9.49017C6.56063 9.49239 6.68477 9.51994 6.79977 9.57118C6.91477 9.62242 7.01827 9.6963 7.1041 9.78841L9.7216 12.4047L14.0628 6.87341C14.0707 6.86378 14.079 6.8546 14.0878 6.84591H14.0866Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </SelectedMarkerOut>
                <Text fontSize="14px">{el.answer}</Text>
              </Flex>
            </ItemWrap>
          ))}
        </FlexItemContainer>
        <Flex style={{ ...fadeInUp(40, '1.1s', '200ms') }} justifyContent="center" alignItems="center" flexDirection="column" mx={[3, 3, 0]} my={[2, 3]}>
          <Button
            disabled={!interacted}
            variant="pink"
            width={[1, 1 / 2, 1 / 4]}
            onClick={() => {
              const formatted = formatSubmit(selected);
              if (!formatted) {
                onSubmit({ values: [{ questionId: data.questionId, answer: 'N', value: '' }], endpoint: data.endpoint }, null, wizard, data);
                // if (data.skip) {
                //   wizard.push(data.skip);
                // } else {
                //   wizard.next();
                // }
                return;
              }

              onSubmit({ values: [{ questionId: data.questionId, answer: 'Y', value: formatted }], endpoint: data.endpoint }, null, wizard, data);
            }}
          >
            Next
          </Button>

          {data.skip && (
            <StyledText mt={4} mb={8} onClick={() => wizard.push(data.skip)}>
              Skip
            </StyledText>
          )}
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default withWizard(MultipleConcatString);
