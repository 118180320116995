import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Query, Mutation } from 'react-apollo';
import { Flex, Text, Button } from 'rebass';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import { Formik, Form } from 'formik';
import Div100vh from 'react-div-100vh';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { GET_PERPETUAL_PHYSICIAN_CONVERSATION, APPOINTMENT_INITIATE_CONVERSATION, PERPETUAL_CONVERSATION_THREAD_REPLY, CURRENT_USER } from '../../graphql';
import SpinLoader from '../../components/SpinLoader';
import withSession from '../../lib/withSession';
import TextArea, { AutoExtendTextArea } from '../../components/TextArea';
import AttachmentIcon from '../../static/attachment.png';
import SendIcon from '../../static/send.png';
import FormField from '../../components/FormField';
import BasicScrollToBottom, { StateContext } from '../../components/scrollToBottomAuto/index';
import { CustomerBubble, UserBubble, BotBubbleStyled, ToBeUploadedFilesMiniatures, AttachmentModal } from '../../components/communicator';
import withAnalytics from '../../lib/withAnalytics';
import { Link } from 'react-router-dom';
import PleaseWait from '../../components/PleaseWait';
import useDynamicRefs from '../../lib/useDynamicRefs';
import { sleep } from '../../utils/sleep';
import Desktop from './components/Desktop';

const Inner = styled.section`
  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 52em) {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: hidden;
  }
  @media screen and (min-width: 52em) {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: 100%;
  }
`;
const PhantomMargin = styled.div`
  @media screen and (max-width: 52em) {
    height: 10px;
  }
`;

const BlurredContainer = styled(Flex)`
  box-shadow: inset 0 0 0 110px rgba(253, 245, 242, 0.65);
  backdrop-filter: blur(6px);
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;
  padding: 12px;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div`
  @media screen and (max-width: 52em) {
    /* position: absolute; */
    /* bottom: 0; */
    /* left: 0; */
    /* right: 0; */
    background: white;
    box-shadow: 0px -9px 23px -16px rgb(0, 0, 0);
    max-width: 99vw;
    height: auto;
  }
`;

const MobileOnly = styled.div`
  display: none;
  @media screen and (max-width: 52em) {
    display: block;
  }
`;

const DesktopOnly = styled.div`
  display: none;
  @media screen and (min-width: 52em) {
    display: block;
  }
`;

const GrowWrap = styled.div`
  display: grid;
  &::after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
    border: 0px solid white;
    font: inherit;
    grid-area: 1 / 1 / 2 / 2;
    max-height: 400px;
    min-height: 59px;
  }
`;

const generateInitiateConversationVariables = (appointmentId, appointment, prescription, summary, thread, isAsync = false) => {
  const appointmentDate = moment(`${appointment.date} ${appointment.startTime}`, 'MM/DD/YYYY HH:mm').format('MM/DD/YYYY hh:mma');
  let prescriptionText = '';
  if (prescription && prescription.length > 0) {
    for (let index = 0; index < prescription.length; index++) {
      const element = prescription[index];
      prescriptionText = prescriptionText + `${element.description}\n${element.instructions}\n${element.patientNote ? element.patientNote : ''}\n`;
    }
  }
  return {
    assignedToEmail: appointment.provider.id.toString(),
    thread: {
      ...thread,
    },
    appointmentDate: appointmentDate,
    appointmentId: appointmentId,
    prescription: prescriptionText,
    assessmentAndPlan: (summary || {}).assessment,
    patientGoal: (summary || {}).goals,
    patientInstructions: (summary || {}).instructions,
    discussionNotes: (summary || {}).discussionNotes,
    isAsync,
  };
};

const Wrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 52em) {
    max-width: 100vw;
    width: 100vw;
    margin-top: 37px;
    overflow-y: hidden;
    position: relative;
  }
`;

const MessagesDiv = styled(Div100vh)`
  cursor: auto;
  overflow-y: hidden;
  @media screen and (max-width: 52em) {
    overflow-y: hidden;
    padding-bottom: 10px;
    flex: 1
  }
`;

const Communicator = (props) => {
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [conversation, setConversation] = useState();
  const [nextPage, setNextPage] = useState();
  const [files, setFiles] = useState([]);
  const [msg, setMsg] = useState('');
  const [isSendingFirstMessage, setIsSendingFirstMessage] = useState();
  const [pollInterval, setPollInterval] = useState(5000);
  const input = useRef();
  const threadContainer = useRef();
  const [targ, setTarg] = useState();
  const [scrollBackTo, setScrollBackTo] = useState();
  const [isAwaitingRescroll, setIsAwaitingRescroll] = useState(false);
  const [getRef, setRef, getRefs] = useDynamicRefs();
  const [lastScrollHeight, setLastScrollHeight] = useState();
  const forwardRefPnl = useRef();

  const { appointmentId, appointment, prescription, summary, loadingWrapper, analytics, session, isAsync = false, isShowOnlyDesktop = false, blockSend = false } = props;

  const handleRejected = () => {};

  const onScroll = (e, data, fetchMore) => {
    if (!!conversation && !isFetchingMore) {
      if (e.target.scrollTop < 1) {
        console.log('onSCROLL');
        setTimeout(() => {
          const height = forwardRefPnl.current.getRealCurrentHeight();
          console.log('onSCROLL', { height });
          setLastScrollHeight(height);
          onFetchMore(data, fetchMore);
        }, 300);

        return;
      }
    }
  };

  const scrollItThere = async () => {
    const timer = setTimeout(() => {
      const height = forwardRefPnl.current.getRealCurrentHeight();
      targ.scrollTo({
        top: height - lastScrollHeight,
      });
      clearTimeout(timer);
    }, 0);
  };

  useLayoutEffect(
    () => {
      if (lastScrollHeight && !isFetchingMore && !!isAwaitingRescroll) {
        setIsAwaitingRescroll(false);
        scrollItThere();
        setScrollBackTo(null);
      }
    },
    [conversation, lastScrollHeight, isFetchingMore, isAwaitingRescroll]
  );

  useEffect(
    () => {
      console.log('UE Convos');
    },
    [conversation]
  );

  const onFetchMore = (data, fetchMore) => {
    if (
      isNaN(nextPage) ||
      !conversation ||
      !conversation.threads ||
      conversation.threads.length < 1 ||
      !!isFetchingMore ||
      !conversation.convoTree ||
      !conversation.convoTree.tree ||
      conversation.convoTree.tree.length < 1
    ) {
      return;
    }
    if (nextPage > ((conversation || {}).convoTree || {}).tree.length - 1) {
      return;
    }
    setIsFetchingMore(true);
    try {
      fetchMore({
        variables: {
          treeIndex: nextPage,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsFetchingMore(false);
          if (
            !fetchMoreResult ||
            !fetchMoreResult.getPerpetualPhysicianConversation ||
            !fetchMoreResult.getPerpetualPhysicianConversation.threads ||
            fetchMoreResult.getPerpetualPhysicianConversation.threads.length < 1
          ) {
            return previousResult;
          }
          const dta = JSON.parse(JSON.stringify(conversation));
          const filtered = fetchMoreResult.getPerpetualPhysicianConversation.threads; //.filter((x) => dta.threads.indexOf((xx) => x.id === xx.id) < 0);

          const threads = [];
          for (let index = 0; index < dta.threads.length; index++) {
            const element = dta.threads[index];
            threads.push(element);
          }
          for (let index = 0; index < filtered.length; index++) {
            const element = filtered[index];
            threads.push(element);
          }
          dta.threads = threads;
          setNextPage(((data.getPerpetualPhysicianConversation || {}).convoTree || {}).returnedTreeIndex + 1);
          setIsAwaitingRescroll(true);
          setConversation(dta);
        },
      });
    } catch (error) {
      setIsFetchingMore(false);
    }
  };

  return (
    <Query
      query={GET_PERPETUAL_PHYSICIAN_CONVERSATION}
      variables={{ treeIndex: 0 }}
      notifyOnNetworkStatusChange={true}
      fetchPolicy="cache-and-network"
      nextFetchPolicy="cache-first"
      pollInterval={pollInterval}
      onCompleted={(data) => {
        if (!conversation) {
          if (data && data.getPerpetualPhysicianConversation) {
            setConversation(data.getPerpetualPhysicianConversation);
            setNextPage(((data.getPerpetualPhysicianConversation || {}).convoTree || {}).returnedTreeIndex + 1);
          }
        } else {
          if (data && data.getPerpetualPhysicianConversation) {
            const newThread = data.getPerpetualPhysicianConversation.threads;
            let thr = JSON.parse(JSON.stringify(conversation.threads));
            let toAdd = newThread.filter((x) => thr.findIndex((xx) => xx.id === x.id) < 0);
            for (let index = 0; index < toAdd.length; index++) {
              const element = toAdd[index];
              thr.unshift(element);
            }

            setConversation({
              ...conversation,
              threads: thr,
              convoTree: { ...data.getPerpetualPhysicianConversation.convoTree },
            });
            setNextPage(((data.getPerpetualPhysicianConversation || {}).convoTree || {}).returnedTreeIndex + 1);
          }
        }
        if (data && data.getPerpetualPhysicianConversation && data.getPerpetualPhysicianConversation.threads && data.getPerpetualPhysicianConversation.threads.length > 0) {
          if (pollInterval < 30000) {
            setPollInterval(30000);
            setIsSendingFirstMessage(false);
          } else if (isSendingFirstMessage) {
            setIsSendingFirstMessage(false);
          }
        }
      }}
    >
      {({ loading, error, data, fetchMore }) => {
        if (loading && (!data || !data.getPerpetualPhysicianConversation)) {
          if (loadingWrapper) {
            return (
              <div
                style={{
                  width: '100vw',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '50%',
                }}
              >
                <loadingWrapper>
                  <SpinLoader />
                </loadingWrapper>
              </div>
            );
          } else {
            return (
              <Flex flex={1} justifyContent="center" alignItems="center">
                <SpinLoader />
              </Flex>
            );
          }
        }
        if (error) {
          if (loadingWrapper) {
            return (
              <div
                style={{
                  width: '100vw',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '50%',
                }}
              >
                {' '}
                <loadingWrapper>Couldn't load messages {error}</loadingWrapper>
              </div>
            );
          } else {
            return (
              <Flex flex={1} justifyContent="center" alignItems="center">
                Couldn't load messages
              </Flex>
            );
          }
        }
        if (conversation) {
          return (
            <>
              {!isShowOnlyDesktop ? (
                <Wrapper
                  style={{
                    flex: '1 0 0',
                    overflowY: 'hidden',
                    maxHeight: 'calc(100% - 1px)',
                    minHeight: !isShowOnlyDesktop ? 'calc(100% - 1px)' : 'initial',
                    height: !isShowOnlyDesktop ? '' : 'initial !important',
                    background: !isShowOnlyDesktop ? '' : 'white',
                    marginTop: (!!window && !!window.ReactNativeWebView) || !!isShowOnlyDesktop ? '0px' : '37px',
                  }}
                >
                  <Dropzone
                    noClick={true}
                    id="dropzone"
                    multiple={true}
                    onDrop={(files) => {}}
                    onDropRejected={handleRejected}
                    onDropAccepted={(f) => {
                      for (let index = 0; index < f.length; index++) {
                        const element = f[index];
                        const reader = new FileReader();
                        reader.onload = (event) => {
                          const fls = files;
                          fls.push({
                            fileName: element.name,
                            mimeType: element.type,
                            data: event.target.result,
                          });
                          setFiles(fls);
                        };
                        reader.readAsDataURL(element);
                      }
                    }}
                    maxSize={10000000}
                    style={{ width: '100%', height: '100%' }}
                  >
                    {({ getRootProps, getInputProps, isDragActive, open }) => (
                      <Inner>
                        <MessagesDiv
                          style={{
                            display: 'flex',
                            flex: 1,
                            maxHeight: '100%',
                          }}
                          {...getRootProps()}
                          noClick={true}
                          id="messagesdiv"
                          className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          ref={threadContainer}
                        >
                          <BasicScrollToBottom
                            forwardRef={(t) => {
                              setTarg(t);
                            }}
                            paddingBottom='0px'
                            forwardRefPnl={forwardRefPnl}
                            onScroll={(e) => {
                              onScroll(e, conversation, fetchMore);
                            }}
                          >
                            <StateContext.Consumer>
                              {({ sticky }) => (
                                <>
                                  <input type="file" {...getInputProps()} />
                                  {conversation &&
                                  conversation.threads.length > 0 &&
                                  conversation.threads.filter((xx) => xx.body && (xx.body || '').toLowerCase() !== 'initiate_1' && (xx.type || '').toString().toLowerCase() !== 'note').length > 0 ? (
                                    conversation.threads
                                      .filter((x) => {
                                        return x.body && x.body.toLowerCase() !== 'initiate_1' && (x.type || '').toString().toLowerCase() !== 'note';
                                      })
                                      .reverse()
                                      .map((thread, index) => {
                                        if (thread.createdBy.id.toString() === session.helpscoutId || thread.createdBy.type === 'customer') {
                                          return (
                                            <React.Fragment key={thread.id}>
                                              <div
                                                id={thread.id}
                                                ref={setRef(thread.id.toString())}
                                                data-item="true"
                                                style={{
                                                  width: '100%',
                                                  height: '12px',
                                                  display: index === 0 ? '' : 'none',
                                                }}
                                              />
                                              <CustomerBubble thread={thread} key={thread.id} />
                                            </React.Fragment>
                                          );
                                        } else {
                                          return (
                                            <React.Fragment key={thread.id}>
                                              <div
                                                id={thread.id}
                                                ref={setRef(thread.id.toString())}
                                                data-item="true"
                                                style={{
                                                  width: '100%',
                                                  height: '12px',
                                                  display: index === 0 ? '' : 'none',
                                                }}
                                              />
                                              <UserBubble thread={thread} key={thread.id} />
                                            </React.Fragment>
                                          );
                                        }
                                      })
                                  ) : (
                                    <Flex
                                      justifyContent="flex-start"
                                      style={{
                                        marginBottom: '24px',
                                        alignItems: isSendingFirstMessage ? 'center' : '',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      {isSendingFirstMessage ? (
                                        <>
                                          <SpinLoader />
                                          <br />
                                          Sending Your 1st Message
                                        </>
                                      ) : (
                                        <BotBubbleStyled>
                                          <Flex flexDirection="row" style={{ marginBottom: '16px' }}>
                                            <Text fontSize="16px" fontWeight="600">
                                              Paloma Health Team
                                            </Text>
                                          </Flex>

                                          <Text
                                            style={{
                                              wordWrap: 'break-word',
                                              whiteSpace: 'pre-line',
                                            }}
                                          >
                                            Our medical team can help with questions related to symptoms, treatments or lab values. For any other question, please
                                            <Link style={{ color: 'white' }} to="/chat-care" id="link_chatcare">
                                              {` message your care manager >`}
                                            </Link>
                                          </Text>
                                        </BotBubbleStyled>
                                      )}
                                    </Flex>
                                  )}
                                  {/* <Text textAlign={'center'} style={{opacity: 0.5}} mt={4}>usually reply within 3 to 5 business days</Text> */}
                                  <Text textAlign="center" opacity={0.5} mt={3} style={{ opacity: 0.5 }}>
                                    usually replies within 3 to 5 business days
                                  </Text>
                                  <PhantomMargin />
                                </>
                              )}
                            </StateContext.Consumer>
                          </BasicScrollToBottom>
                        </MessagesDiv>

                        {/* <div
                          style={{
                            background: 'black',
                            opacity: 0.5,
                            height: '1px',
                            width: '100%',
                          }}
                        /> */}

                        <Mutation
                          mutation={conversation && (conversation.id || conversation.number) ? PERPETUAL_CONVERSATION_THREAD_REPLY : APPOINTMENT_INITIATE_CONVERSATION}
                          onCompleted={() => {
                            analytics.track('Send Provider Message');
                          }}
                        >
                          {(sendEmailReminder, { loading }) => {
                            return (
                              <Formik
                                initialValues={{ msgTxt: '' }}
                                validate={(values, props) => {
                                  let errors = {};
                                  if (files.length > 0 && !values.msgTxt) {
                                    errors.msgTxt('A message is required to send');
                                  }
                                  return errors;
                                }}
                                render={({ isValid, errors, touched, submitForm, setSubmitting, values, resetForm, isSubmitting, setFieldValue }) => (
                                  <FormWrapper>
                                    <Form>
                                      <Flex flexDirection="column" pb={[4, 2]}>
                                        <MobileOnly>
                                          <Flex flexDirection="column">
                                            <Flex flexDirection={'row'} justifyContent={'space-between'}>
                                              <Button
                                                type="button"
                                                style={{
                                                  padding: '8px',
                                                  alignSelf: 'flex-start',
                                                  marginTop: '10px',
                                                  display: 'flex',
                                                }}
                                                variant="white"
                                                onClick={() => {
                                                  open();
                                                }}
                                              >
                                                <img src={AttachmentIcon} width="20px" style={{ opacity: 0.75 }} alt="attach" />
                                                <Text fontWeight={'300'}>Add file</Text>
                                              </Button>

                                              <Button
                                                disabled={(!values.msgTxt && (!files || files.length < 1)) || isSubmitting}
                                                style={{
                                                  padding: '8px',
                                                  alignSelf: 'flex-start',
                                                  marginTop: '10px',
                                                  marginRight: '6px',
                                                  display: 'flex',
                                                }}
                                                type="button"
                                                variant="white"
                                                onClick={async () => {
                                                  setSubmitting(true);
                                                  const attachments = [];
                                                  if (files) {
                                                    for (let index = 0; index < files.length; index++) {
                                                      const element = files[index];
                                                      attachments.push({
                                                        ...element,
                                                        data: element.data.substr(element.data.indexOf(',') + 1),
                                                      });
                                                    }
                                                  }
                                                  if (!conversation || !conversation.threads || conversation.length < 1) {
                                                    // if (values.msgTxt) {
                                                    const vars = generateInitiateConversationVariables(
                                                      appointmentId,
                                                      appointment,
                                                      prescription,
                                                      summary,
                                                      {
                                                        text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                        attachments: attachments,
                                                      },
                                                      isAsync
                                                    );
                                                    await sendEmailReminder({
                                                      notifyOnNetworkStatusChange: true,
                                                      variables: vars,
                                                      refetchQueries: [
                                                        {
                                                          query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                                          variables: {
                                                            treeIndex: 0,
                                                          },
                                                        },
                                                        { query: CURRENT_USER },
                                                      ],
                                                      awaitRefetchQueries: true,
                                                    });
                                                    resetForm({ msgTxt: '' });
                                                    setFiles([]);
                                                    setPollInterval(2000);
                                                    setIsSendingFirstMessage(true);
                                                  } else {
                                                    await sendEmailReminder({
                                                      notifyOnNetworkStatusChange: true,
                                                      variables: {
                                                        conversationId: conversation.id.toString(),
                                                        text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                        attachments: attachments,
                                                        isAsync,
                                                      },
                                                      refetchQueries: [
                                                        {
                                                          query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                                          variables: {
                                                            treeIndex: 0,
                                                          },
                                                        },
                                                      ],
                                                    });

                                                    resetForm({ msgTxt: '' });
                                                    setFiles([]);
                                                  }

                                                  setSubmitting(false);
                                                }}
                                              >
                                                <MobileOnly>
                                                  <Text fontWeight={'300'}>send</Text>
                                                </MobileOnly>
                                                <img src={SendIcon} width="20px" style={{ opacity: 0.75 }} alt="send" />
                                              </Button>
                                            </Flex>
                                            {/* <FormField
                                              style={{
                                                flexGrow: 1,
                                                maxHeight: '40vh',
                                                minHeight: '59px',
                                                height: 'auto',
                                              }}
                                              error={errors.msgTxt}
                                            > */}
                                              {/* <GrowWrap> */}
                                                <AutoExtendTextArea
                                                  disabled={isSubmitting}
                                                  name="msgTxt"
                                                  style={{
                                                    flexGrow: 1,
                                                    background: 'white',
                                                    border: '0px solid white',
                                                    maxHeight: '40vh',
                                                    minHeight: '59px',
                                                    font: 'inherit',
                                                    padding: '4px 12px',
                                                    outlineStyle: 'none',
                                                    boxShadow: 'none'
                                                  }}
                                                  placeholder="type your message..."
                                                  id="autocomplete"
                                                  className="input-field"
                                                  type="text"
                                                  value={values.msgTxt || ''}
                                                  onChange={e=>{setFieldValue('msgTxt',e.target.value)}}
                                                />
                                              {/* </GrowWrap> */}
                                            {/* </FormField> */}
                                          </Flex>
                                        </MobileOnly>

                                        <DesktopOnly>
                                          <Flex flexDirection="row">
                                            <Flex flexDirection={['column', 'row']} flex={1}>
                                              <Button
                                                type="button"
                                                style={{
                                                  padding: '8px',
                                                  alignSelf: 'flex-start',
                                                  marginTop: '10px',
                                                  display: 'flex',
                                                }}
                                                variant="white"
                                                onClick={() => {
                                                  open();
                                                }}
                                              >
                                                <img src={AttachmentIcon} width="20px" style={{ opacity: 0.75 }} alt="attach" />
                                                <MobileOnly>
                                                  <Text fontWeight={'300'}>Add file</Text>
                                                </MobileOnly>
                                              </Button>
                                              {/* <FormField
                                                style={{
                                                  flexGrow: 1,
                                                  maxHeight: '40vh',
                                                  minHeight: '59px',
                                                  height: 'auto',
                                                }}
                                                error={errors.msgTxt}
                                              > */}
                                                {/* <TextArea
                                                  disabled={isSubmitting}
                                                  name="msgTxt"
                                                  style={{
                                                    flexGrow: 1,
                                                    background: 'white',
                                                    border: '0px solid white',
                                                    maxHeight: '400px',
                                                    minHeight: '59px',
                                                    height: 'auto',
                                                  }}
                                                  placeholder="type your message..."
                                                  id="autocomplete"
                                                  className="input-field"
                                                  type="text"
                                                /> */}
                                                <AutoExtendTextArea
                                                  disabled={isSubmitting}
                                                  id="AETA"
                                                  name="msgTxt"
                                                  style={{
                                                    flexGrow: 1,
                                                    background: 'white',
                                                    border: '0px solid white',
                                                    maxHeight: '40vh',
                                                    minHeight: '59px',
                                                    font: 'inherit',
                                                    padding: '4px 12px',
                                                    outlineStyle: 'none',
                                                    boxShadow: 'none'
                                                  }}
                                                  placeholder="type your message..."
                                                  value={values.msgTxt || ''}
                                                  onChange={e=>{setFieldValue('msgTxt',e.target.value)}}
                                                />
                                              {/* </FormField> */}
                                            </Flex>
                                            <Button
                                              disabled={(!values.msgTxt && (!files || files.length < 1)) || isSubmitting}
                                              style={{
                                                padding: '8px',
                                                alignSelf: 'flex-start',
                                                marginTop: '10px',
                                                marginRight: '6px',
                                                display: 'flex',
                                              }}
                                              type="button"
                                              variant="white"
                                              onClick={async () => {
                                                setSubmitting(true);
                                                const attachments = [];
                                                if (files) {
                                                  for (let index = 0; index < files.length; index++) {
                                                    const element = files[index];
                                                    attachments.push({
                                                      ...element,
                                                      data: element.data.substr(element.data.indexOf(',') + 1),
                                                    });
                                                  }
                                                }
                                                if (!conversation || !conversation.threads || conversation.length < 1) {
                                                  // if (values.msgTxt) {
                                                  const vars = generateInitiateConversationVariables(
                                                    appointmentId,
                                                    appointment,
                                                    prescription,
                                                    summary,
                                                    {
                                                      text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                      attachments: attachments,
                                                    },
                                                    isAsync
                                                  );
                                                  await sendEmailReminder({
                                                    notifyOnNetworkStatusChange: true,
                                                    variables: vars,
                                                    refetchQueries: [
                                                      {
                                                        query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                                        variables: {
                                                          treeIndex: 0,
                                                        },
                                                      },
                                                      { query: CURRENT_USER },
                                                    ],
                                                    awaitRefetchQueries: true,
                                                  });
                                                  resetForm({ msgTxt: '' });
                                                  setFiles([]);
                                                  setPollInterval(2000);
                                                  setIsSendingFirstMessage(true);
                                                } else {
                                                  await sendEmailReminder({
                                                    notifyOnNetworkStatusChange: true,
                                                    variables: {
                                                      conversationId: conversation.id.toString(),
                                                      text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                      attachments: attachments,
                                                      isAsync,
                                                    },
                                                    refetchQueries: [
                                                      {
                                                        query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                                        variables: {
                                                          treeIndex: 0,
                                                        },
                                                      },
                                                    ],
                                                  });

                                                  resetForm({ msgTxt: '' });
                                                  setFiles([]);
                                                }

                                                setSubmitting(false);
                                              }}
                                            >
                                              <MobileOnly>
                                                <Text fontWeight={'300'}>send</Text>
                                              </MobileOnly>
                                              <img src={SendIcon} width="20px" style={{ opacity: 0.75 }} alt="send" />
                                            </Button>
                                          </Flex>
                                        </DesktopOnly>
                                        {files && files.length > 0 ? (
                                          <>
                                            {isMobile ? (
                                              <ToBeUploadedFilesMiniatures
                                                files={files}
                                                onFileRemove={(index) => {
                                                  const fls = files;
                                                  fls.splice(index, 1);
                                                  setFiles(fls);
                                                }}
                                              />
                                            ) : (
                                              <AttachmentModal
                                                isSubmitting={isSubmitting}
                                                onFileRemove={(index) => {
                                                  const fls = files;
                                                  fls.splice(index, 1);
                                                  setFiles(fls);
                                                }}
                                                confirmModalForm={async () => {
                                                  setSubmitting(true);
                                                  const attachments = [];
                                                  if (files) {
                                                    for (let index = 0; index < files.length; index++) {
                                                      const element = files[index];
                                                      attachments.push({
                                                        ...element,
                                                        data: element.data.substr(element.data.indexOf(',') + 1),
                                                      });
                                                    }
                                                  }
                                                  if (!conversation || !conversation.threads || conversation.threads.length < 1) {
                                                    const vars = generateInitiateConversationVariables(
                                                      appointmentId,
                                                      appointment,
                                                      prescription,
                                                      summary,
                                                      {
                                                        text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                        attachments: attachments,
                                                      },
                                                      isAsync
                                                    );
                                                    await sendEmailReminder({
                                                      notifyOnNetworkStatusChange: true,
                                                      variables: vars,
                                                      refetchQueries: [
                                                        { query: CURRENT_USER },
                                                        {
                                                          query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                                          variables: {
                                                            treeIndex: 0,
                                                          },
                                                        },
                                                      ],
                                                      awaitRefetchQueries: true,
                                                    });
                                                    resetForm({ msgTxt: '' });
                                                    setFiles([]);
                                                    setPollInterval(2000);
                                                    setIsSendingFirstMessage(true);
                                                  } else {
                                                    await sendEmailReminder({
                                                      notifyOnNetworkStatusChange: true,
                                                      variables: {
                                                        conversationId: conversation.id.toString(),
                                                        text: values.msgTxt || `See attachment${files.length > 1 ? 's' : ''}`,
                                                        attachments: attachments,
                                                        isAsync,
                                                      },
                                                      refetchQueries: [
                                                        {
                                                          query: GET_PERPETUAL_PHYSICIAN_CONVERSATION,
                                                          variables: {
                                                            treeIndex: 9,
                                                          },
                                                        },
                                                      ],
                                                    });

                                                    resetForm({ msgTxt: '' });
                                                    setFiles([]);
                                                  }

                                                  setSubmitting(false);
                                                }}
                                                close={() => {
                                                  setFiles([]);
                                                }}
                                                files={files}
                                                values={values}
                                                addFiles={(files) => {
                                                  setFiles(files);
                                                }}
                                              />
                                            )}
                                          </>
                                        ) : null}
                                      </Flex>
                                    </Form>
                                  </FormWrapper>
                                )}
                              />
                            );
                          }}
                        </Mutation>
                      </Inner>
                    )}
                  </Dropzone>
                </Wrapper>
              ) : (
                <Flex
                  style={{
                    flex: '1 0 0',
                    overflowY: 'hidden',
                    maxHeight: 'calc(100% - 1px)',
                    minHeight: !isShowOnlyDesktop ? 'calc(100% - 1px)' : 'initial',
                    flexDirection: 'column',
                    background: '#fdf5f1',
                    marginTop: (!!window && !!window.ReactNativeWebView) || !!isShowOnlyDesktop ? '0px' : '37px',
                  }}
                >
                  <Desktop
                    isShowOnlyDesktop={isShowOnlyDesktop}
                    handleRejected={handleRejected}
                    setFiles={setFiles}
                    files={files}
                    threadContainer={threadContainer}
                    setTarg={setTarg}
                    forwardRefPnl={forwardRefPnl}
                    onScroll={onScroll}
                    conversation={conversation}
                    fetchMore={fetchMore}
                    session={session}
                    isSendingFirstMessage={isSendingFirstMessage}
                    scrollItThere={scrollItThere}
                    generateInitiateConversationVariables={generateInitiateConversationVariables}
                    setPollInterval={setPollInterval}
                    setIsSendingFirstMessage={setIsSendingFirstMessage}
                    setRef={setRef}
                    analytics={analytics}
                    appointmentId={appointmentId}
                    appointment={appointment}
                    prescription={prescription}
                    summary={summary}
                    isAsync={isAsync}
                  />
                </Flex>
              )}
              {isFetchingMore && (
                <BlurredContainer>
                  <PleaseWait
                    text={' '}
                    spinner={{
                      sColor: '#ECEFF2',
                      pColor: '#909FB5',
                    }}
                  />
                </BlurredContainer>
              )}
            </>
          );
        }
        return <div />;
      }}
    </Query>
  );
};

export default withSession(withAnalytics(Communicator));
