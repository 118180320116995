import gql from 'graphql-tag';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import { Query, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Box, Button, Card, Flex, Text } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../../components/FullLayout';
import PleaseWait from '../../components/PleaseWait';
import { APPOINTMENTS } from '../../graphql';
import withSession from '../../lib/withSession';

const DELAY_TO_INACTIVE_APPT_MINS = 720;

const GET_INTAKE_STATUS = gql`
  query getVisitIntakeStatus {
    getVisitIntakeStatus {
      ok
    }
  }
`;

const Avatar = styled(Flex)`
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  & > img {
    width: 100px;
  }
  @media screen and (min-width: 52em) {
    &.small {
      width: 60px;
      height: 60px;
      & > img {
        width: 60px;
      }
    }
  }
`;
const VisitVisio = ({ client, match, history, session: { firstName, lastName } }) => {
  const [appt, setAppt] = useState();
  const [intakeStatus, setIntakeStatus] = useState();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasLoadedIntakeStatus, setHasLoadedIntakeStatus] = useState(false);
  const [start, setStart] = useState(false);

  useEffect(() => {
    const loadAppointments = async () => {
      const response = await client.query({
        query: APPOINTMENTS,
        fetchPolicy: 'network-only',
      });
      if (response && response.data && response.data.appointments) {
        let closestAppt;
        const appts = response.data.appointments.filter((item) => {
          if (
            item.status &&
            (item.status === 'f' || item.status === '2') &&
            item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
            item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID
          ) {
            return true;
          }
          return false;
        });
        for (let index = 0; index < appts.length; index++) {
          const element = appts[index];
          if (!closestAppt) {
            closestAppt = element;
          } else {
            const el = moment(momentTimezone(`${element.date} ${element.startTime}`, 'MM/DD/YYYY HH:mm').tz(element.timezoneName));
            const closest = moment(momentTimezone(`${closestAppt.date} ${closestAppt.startTime}`, 'MM/DD/YYYY HH:mm').tz(closestAppt.timezoneName));
            const durationEl = moment.duration(moment().diff(el));
            const durationClosest = moment.duration(moment().diff(closest));
            const elDiff = Math.abs(durationEl.asMinutes());
            const closestDiff = Math.abs(durationClosest.asMinutes());
            console.log({ elDiff, closestDiff });
            if (elDiff < closestDiff) {
              closestAppt = element;
            }
          }
        }

        setAppt(closestAppt);
        console.log({ closestAppt });
      }

      setHasLoaded(true);
    };
    const loadIntakeStatus = async () => {
      const response = await client.query({
        query: GET_INTAKE_STATUS,
        fetchPolicy: 'network-only',
      });
      if (response && response.data && response.data.getVisitIntakeStatus) {
        setIntakeStatus(response.data.getVisitIntakeStatus.ok);
      }

      setHasLoadedIntakeStatus(true);
    };
    loadAppointments();
    loadIntakeStatus();
  }, []);

  let isIn = -10000;

  try {
    if (!!appt) {
      const closest = moment(momentTimezone(`${appt.date} ${appt.startTime}`, 'MM/DD/YYYY HH:mm').tz(appt.timezoneName));
      const durationClosest = moment.duration(moment().diff(closest));
      isIn = durationClosest.asMinutes();
    }
  } catch (error) {}

  return (
    <FullLayout2 style={{ zIndex: 10 }}>
      {start ? (
        <Box id="visioWrap" style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, height: '100vh' }}>
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            allow="camera; microphone; fullscreen; speaker; display-capture"
            src={`https://paloma.whereby.com/${appt.provider.zocdocVideoLink.replace('https://paloma.whereby.com/', '')}?displayName=${encodeURI(`${firstName} ${lastName}`)}`}
          />
        </Box>
      ) : (
        <Flex style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          {!hasLoaded || !hasLoadedIntakeStatus ? (
            <PleaseWait />
          ) : (
            <Flex flexDirection={'column'}>
              {!appt ? (
                <>
                  <Card style={{ flex: 'initial', padding: 20 }}>
                    <Flex justifyContent="center" flexDirection="column">
                      <Text textAlign="center" fontSize={3} fontWeight={700}>
                        We could not find any appointment scheduled for you today
                      </Text>

                      <Button variant={'primary'} mt={4} onClick={() => history.push('/')} style={{ alignSelf: 'center' }}>
                        Go to dashboard
                      </Button>
                    </Flex>
                  </Card>
                </>
              ) : (
                <>
                  {!intakeStatus && (
                    <Card style={{ flex: 'initial', padding: 20, backgroundColor: '#FCE3D9' }} mb={5}>
                      <Flex mb={3} justifyContent="center" flexDirection="column">
                        <Text mb={3} textAlign="center" fontSize={3} fontWeight={700}>
                          Please complete your intake form before your visit
                        </Text>
                        <Text mb={3}>
                          Completing your intake form is&nbsp;
                          <span style={{ textDecoration: 'underline' }}>required</span>
                          &nbsp;in order to prescribe medication or discuss potential treatment plans.
                        </Text>
                        <Button variant={'primary'} mt={4} onClick={() => history.push('/intake')} style={{ alignSelf: 'center' }}>
                          Complete Intake
                        </Button>
                      </Flex>
                    </Card>
                  )}
                  {Math.abs(isIn) > DELAY_TO_INACTIVE_APPT_MINS ? (
                    <Card style={{ flex: 'initial', padding: 20 }}>
                      <Flex mb={4} justifyContent="center">
                        {appt && appt.provider && appt.provider.picture ? (
                          <Avatar>
                            <img src={appt.provider.picture} alt="" />
                          </Avatar>
                        ) : null}
                      </Flex>

                      <Flex mb={3} justifyContent="center" flexDirection="column">
                        <Text textAlign="center" fontSize={3} fontWeight={700}>
                          <span style={{ fontWeight: 400 }}>Your</span> {appt.date} {moment(appt.startTime, 'HH:mm').format('hh:mmA')}{' '}
                          {momentTimezone()
                            .tz(appt.timezoneName)
                            .format('z')}{' '}
                          <span style={{ fontWeight: 400 }}>video visit with</span>
                          <br />
                          {appt && appt.provider && appt.provider.displayName ? appt.provider.displayName : 'your Thyroid Doctor'}
                          <br />
                          <br />
                          <span style={{ color: '#f7c1ad' }}>{isIn > 0 ? 'ended' : 'is not ready yet'}</span>
                        </Text>

                        <Button variant={'primary'} mt={4} onClick={() => history.push('/')} style={{ alignSelf: 'center' }}>
                          Go to dashboard
                        </Button>
                      </Flex>
                    </Card>
                  ) : (
                    <Card style={{ flex: 'initial', padding: 20 }}>
                      <Flex mb={4} justifyContent="center">
                        {appt && appt.provider && appt.provider.picture ? (
                          <Avatar>
                            <img src={appt.provider.picture} alt="" />
                          </Avatar>
                        ) : null}
                      </Flex>

                      <Flex mb={3} justifyContent="center" flexDirection="column">
                        <Text textAlign="center" fontSize={3} fontWeight={700}>
                          <span style={{ fontWeight: 400 }}>Your</span> {appt.date} {moment(appt.startTime, 'HH:mm').format('hh:mmA')}{' '}
                          {momentTimezone()
                            .tz(appt.timezoneName)
                            .format('z')}{' '}
                          <span style={{ fontWeight: 400 }}>video visit with</span>
                          <br />
                          {appt && appt.provider && appt.provider.displayName ? appt.provider.displayName : 'your Thyroid Doctor'}
                        </Text>

                        <Button variant={'primary'} mt={4} onClick={() => setStart(true)} style={{ alignSelf: 'center' }}>
                          Continue
                        </Button>
                      </Flex>
                    </Card>
                  )}
                </>
              )}
            </Flex>
          )}
        </Flex>
      )}
    </FullLayout2>
  );
};

export default withRouter(withApollo(withSession(VisitVisio)));
