import React, { useState } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import { RadioInputNotForm } from '../../../../components/RadioInput';
import withSubscriptions from '../../../../lib/withSubscriptions';
import { NOTIFY_VITAMINS_BACK_IN_STOCK } from '../../../../graphql';
import { Mutation } from 'react-apollo';
import ConfirmModal from '../../../../components/ConfirmModal';
import styled from 'styled-components';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;
const FormVitamins = ({ isMember, isDiagnosed, history, isSubscribeAndSave, createSession, acquisitionType, setAcquisitionType, loading, subscriptions: { vitamins } }) => {
  const [sendNotifyVisible, setSendNotifyVisible] = useState(false);
  return (
    <>
      <RadioInputNotForm
        disabled={loading}
        style={{
          fontWeight: acquisitionType === 'ONCE' ? '500' : '',
          marginLeft: '24px',
          color: '#344f79',
        }}
        color="#344f79"
        onChecked={() => {
          setAcquisitionType('ONCE');
        }}
        checked={acquisitionType === 'ONCE'}
        name="acquisitionType"
        label="Buy Once: $40.00"
      />
      {!vitamins && (
        <>
          <RadioInputNotForm
            disabled={loading}
            style={{
              marginTop: '20px',
              marginLeft: '24px',
              fontWeight: acquisitionType === 'SUBSCRIBE' ? '500' : '',
            }}
            color="#344f79"
            onChecked={() => {
              setAcquisitionType('SUBSCRIBE');
            }}
            checked={acquisitionType === 'SUBSCRIBE'}
            label={isMember ? 'Subscribe and Save: $27.00' : 'Subscribe and Save: $34.00/month'}
          />
          <Text mt={2} ml="50px" fontSize="14px">
            Receive every month. Pause or cancel anytime
          </Text>
          {!isMember &&
            isDiagnosed && (
              <Text
                mt={3}
                fontSize="16px"
                fontStyle="italic"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push('/become-member');
                }}
              >
                or&nbsp;
                <span
                  style={{
                    fontWeight: 500,
                    color: '#F8A294',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  Membership price
                </span>
                : $27/month
              </Text>
            )}
        </>
      )}

      {/* <Text mt={3}>Out of stock. Estimated back in stock April 5th, 2023</Text> */}

      <Flex>
        <Button
          // disabled={true}
          mt={4}
          mb={4}
          alignSelf={['center', 'flex-start']}
          style={{ width: '150px' }}
          variant="primary"
          onClick={() => {
            if (!isMember && isDiagnosed && acquisitionType === 'SUBSCRIBE') {
              isSubscribeAndSave();
              return;
            }
            createSession();
          }}
        >
          Checkout
        </Button>
        {/* <Mutation
          mutation={NOTIFY_VITAMINS_BACK_IN_STOCK}
          onCompleted={(data) => {
            if (!!data && data.notifyBackInStock && data.notifyBackInStock.ok) {
              setSendNotifyVisible(true);
            }
          }}
        >
          {(mutate, { loading }) => (
            <Button disabled={loading} mt={4} ml={3} mb={4} alignSelf={['center', 'flex-start']} style={{ width: '150px' }} variant="primary" onClick={mutate}>
              Notify me
            </Button>
          )}
        </Mutation> */}
      </Flex>
      <Text mb={4} textAlign={['center', 'left']} fontWeight="300" ml={['0', '50px']} fontSize="14px">
        Free Shipping • Free Return
      </Text>
      {sendNotifyVisible && (
        <ConfirmModal
          isOpen={sendNotifyVisible}
          okOnly
          confirmLabel='Close'
          onClose={() => {
            setSendNotifyVisible(false);
          }}
          onConfirm={() => {
            setSendNotifyVisible(false);
          }}
          onBackgroundClick={() => {
            setSendNotifyVisible(false);
          }}
          onEscapeKeydown={() => {
            setSendNotifyVisible(false);
          }}
        >
          <ModalInner>
            <Flex flexDirection={'column'}>
              <Heading mb={4} mx={3} textAlign="center">
                We got your request!
              </Heading>
              <Text mb={4} textAlign="center">
                An email will be sent to you when our vitamins get back in stock.
              </Text>
            </Flex>
          </ModalInner>
        </ConfirmModal>
      )}
    </>
  );
};

export default withSubscriptions(FormVitamins);
