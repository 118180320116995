import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading } from 'rebass';

import { Formik, Form } from 'formik';
import { withWizard } from 'react-albus';
import * as Yup from 'yup';
import moment from 'moment';
import styled from 'styled-components';
import Mailcheck from 'react-mailcheck';

import { TextInput } from '../../components/TextInput';
import { FormField } from '../../components/FormField';
import { MaskInput } from '../../components/MaskInput';

const MailcheckButton = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  font-size: inherit;
  font-style: italic;
  color: rgb(237, 183, 168);
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;

  &:focus {
    outline: none;
    color: rgb(237, 183, 168);
  }

  &:hover {
    color: rgb(237, 183, 168);
  }

  &:active {
    color: rgb(237, 183, 168);
  }
`;

const MailcheckWrapper = styled.div`
  text-align: right;
  @media screen and (max-width: 52em) {
    text-align: left;
  }
`;

const minDob = moment()
  .subtract(18, 'years')
  .toDate();

const maxDob = moment()
  .subtract(125, 'years')
  .toDate();

const schema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('Required'),
  lastName: Yup.string()
    .trim()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  dob: Yup.date()
    .transform(value => {
      return moment(value).toDate();
    })
    .min(maxDob, 'Must be younger than 125 years')
    .max(minDob, 'Must be 18 years or older')
    .required('Required'),
});

const Profile = props => {
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column">
        <Card p={4}>
          <Flex flexDirection="column">
            <Heading textAlign="center" fontWeight={550} mb={4}>
              Your Personal Information
            </Heading>

            <Formik
              isInitialValid={({ initialValues }) =>
                schema.isValidSync(initialValues)
              }
              initialValues={props.values}
              validationSchema={schema}
              onSubmit={values =>
                props.onSubmit('address', values, props.wizard)
              }
              render={({
                isValid,
                errors,
                touched,
                values,
                handleChange,
                setFieldValue,
                handleBlur,
              }) => {
                const dobError =
                  errors.dob &&
                  errors.dob !== 'Invalid Date' &&
                  errors.dob.indexOf('dob') !== 0
                    ? errors.dob
                    : null;
                return (
                  <Form>
                    <Flex flexDirection={['column', 'column', 'row']}>
                      <FormField
                        width={[1, 1, 1 / 2]}
                        mb={3}
                        mr={[0, 0, 3]}
                        error={touched.firstName && errors.firstName}
                      >
                        <TextInput
                          placeholder="First Name"
                          type="text"
                          name="firstName"
                        />
                      </FormField>
                      <FormField
                        width={[1, 1, 1 / 2]}
                        mb={3}
                        error={touched.lastName && errors.lastName}
                      >
                        <TextInput
                          placeholder="Last Name"
                          type="text"
                          name="lastName"
                        />
                      </FormField>
                    </Flex>

                    <FormField mb={3} error={touched.email && errors.email}>
                      <Mailcheck email={values.email}>
                        {suggestion => (
                          <>
                            <TextInput
                              placeholder="Email Address"
                              type="email"
                              name="email"
                              defaultValue={props.emailAddress}
                            />
                            {suggestion && (
                              <MailcheckWrapper
                                className="mailcheck"
                              >
                                Did you mean{' '}
                                <MailcheckButton
                                  type="button"
                                  onClick={() =>
                                    setFieldValue('email', suggestion.full)
                                  }
                                >
                                  {suggestion.full}
                                </MailcheckButton>
                                ?
                              </MailcheckWrapper>
                            )}
                          </>
                        )}
                      </Mailcheck>
                    </FormField>

                    <FormField mb={4} error={touched.dob && dobError}>
                      <MaskInput
                        name="dob"
                        placeholder="Your Date of Birth (MM/DD/YYYY)"
                        mask="99/99/9999"
                        value={values.dob}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormField>

                    <Flex justifyContent="center">
                      <Button
                        disabled={!isValid}
                        variant="pink"
                        width={[1, 1 / 2, 1 / 3]}
                        type="submit"
                      >
                        Confirm
                      </Button>
                    </Flex>
                  </Form>
                );
              }}
            />
          </Flex>
        </Card>
      </Flex>
    </Fragment>
  );
};

export default withWizard(Profile);
