import React, { useState } from 'react';
import { InsuranceListSelector } from '../scheduleDoctor2/components/pickInsurance/insuranceListSelector';
import withSession from '../../lib/withSession';
import { withWizard } from 'react-albus';

const SelectInsurance = ({ onNonSupportedProviderSelected, wizard, bypassInsurance, onProviderSelected }) => {
  const [selectedProvider, setSelectedProvider] = useState();

  const onProviderSelectionChange = (provider) => {
    setSelectedProvider(provider);
  };

  return (
    <InsuranceListSelector
      onProviderSelected={onProviderSelected}
      wizard={wizard}
      bypassInsurance={bypassInsurance}
      onNonSupportedProviderSelected={onNonSupportedProviderSelected}
      selectedProvider={selectedProvider}
      onProviderSelectionChange={onProviderSelectionChange}
    />
  );
};

export default withSession(withWizard(SelectInsurance));
