import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Flex } from 'rebass';
import styled from 'styled-components';

import { Icon } from '@iconify/react';
import phone from '@iconify/icons-entypo/phone';
import mail from '@iconify/icons-entypo/mail';
import usrxSmall from '../../static/usrxSmall.png';

const A = styled.a`
  display: flex;
  text-decoration: underline;
  color: #44A5FF;

  &:visited {
    color: #44A5FF;
  }
`;

const Container = styled(Box)`
  background: #ffffff;
`;

const Wrapper = styled(Box)`
  background: #ffffff;
  max-width: 380px;
  margin: 32px auto;
  border: 1px solid #e0dede;
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 272px;
  padding: 14px;
`;

const Divider = styled(Box)`
  border: 1px solid #e0dede;
  height: 1px;
  width: 100%;
  margin: 12px 0;
`;

const Title = styled(Text)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;

  color: #355078;
`;

const Price = styled(Text)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #07933f;
`;

const Txt = styled(Text)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;

  color: #355078;
`;

const TxtNonActive = styled(Text)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;

  color: #355078;
  filter: blur(4px);
`;

const NumberCircle = styled(Box)`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: white;
  display: flex;
`;

export const USRX_MEMBER_ID = 'PALOMA1256';
export const USRX_GROUP = 'USA349';
export const USRX_BIN = '610306';
export const USRX_PCN = 'DCAE1';

export const BlurriableText = ({ children, canSee }) => {
  if (canSee) {
    return <Txt>{children}</Txt>;
  }
  return <TxtNonActive>{children}</TxtNonActive>;
};
export const UsrxCard = ({
  medicationCommon,
  medicationTitle,
  medicationPrice,
  pharmacyName,
  canSee,
  showDisclaimer,
}) => {
  return (
    <Container>
      <Wrapper>
        <Flex flexDirection="column">
          <Flex flexDirection="row" marginY="4px">
            <Box flex={1}>
              <Flex flex={1}>
                <Title>{medicationTitle}</Title>
                {medicationCommon ? (
                  <Txt>{` (${medicationCommon})`}</Txt>
                ) : null}
              </Flex>
            </Box>
            <Price>${medicationPrice}</Price>
          </Flex>

          <Divider />
          <Flex flexDirection="row" marginY="4px">
            <Box flex={1}>
              <Flex flex={1}>
                <Title>Member ID</Title>
              </Flex>
            </Box>
            <BlurriableText canSee={canSee}>{USRX_MEMBER_ID}</BlurriableText>
          </Flex>
          <Flex flexDirection="row" marginY="4px">
            <Box flex={1}>
              <Flex flex={1}>
                <Title>Group</Title>
              </Flex>
            </Box>
            <BlurriableText canSee={canSee}>{USRX_GROUP}</BlurriableText>
          </Flex>
          <Flex flexDirection="row" marginY="4px">
            <Box flex={1}>
              <Flex flex={1}>
                <Title>Bin</Title>
              </Flex>
            </Box>
            <BlurriableText canSee={canSee}>{USRX_BIN}</BlurriableText>
          </Flex>
          <Flex flexDirection="row" marginY="4px">
            <Box flex={1}>
              <Flex flex={1}>
                <Title>PCN</Title>
              </Flex>
            </Box>
            <BlurriableText canSee={canSee}>{USRX_PCN}</BlurriableText>
          </Flex>
          <Divider />

          <Flex flexDirection="row" marginY="4px">
            <Flex flex={1}>
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0)">
                  <path
                    d="M9 9.76295C10.2426 9.76295 11.25 8.7556 11.25 7.51295C11.25 6.27031 10.2426 5.26295 9 5.26295C7.75736 5.26295 6.75 6.27031 6.75 7.51295C6.75 8.7556 7.75736 9.76295 9 9.76295Z"
                    stroke="#44A5FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 1.51295C7.4087 1.51295 5.88258 2.1451 4.75736 3.27031C3.63214 4.39553 3 5.92166 3 7.51295C3 8.93195 3.3015 9.86045 4.125 10.888L9 16.513L13.875 10.888C14.6985 9.86045 15 8.93195 15 7.51295C15 5.92166 14.3679 4.39553 13.2426 3.27031C12.1174 2.1451 10.5913 1.51295 9 1.51295V1.51295Z"
                    stroke="#44A5FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect
                      width="18"
                      height="18"
                      fill="white"
                      transform="translate(0 0.0129547)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <Txt>{pharmacyName}</Txt>
            </Flex>
            <img src={usrxSmall} alt="" width="46px" />
          </Flex>
        </Flex>
      </Wrapper>
      {showDisclaimer &&
        canSee && (
          <Box style={{maxWidth: '400px', margin: '0 auto'}}>
            <Text textAlign="center" mt="36px" fontWeight="600" flex={1}>
              Phamacists: this is a FREE program to our cardholders. Please
              process their prescription using the above BIN/PCN/Group
              information.
            </Text>
            <Text textAlign="center" mt="36px" fontWeight="600" flex={1}>
              This is not insurance
            </Text>
            <Text textAlign="center" flex={1}>
              and cannot be combined with insurance. Certain terms and
              conditions apply. Void where prohibited. Discounts available only
              at participating pharmacies. Process all prescriptions
              electronically. ADMINISTERED BY Envision Savings.
            </Text>

            <Text
              fontWeight="600"
              width="75%"
              maxWidth="75%"
              m="36px auto"
              fontSize="20px"
            >
              Need Help?
            </Text>

            <Flex flexDirection="row" alignItems="center">
              <NumberCircle>
                <Icon icon={phone} />
              </NumberCircle>
              <Text textAlign="left" ml="16px" flex={1}>
                Customer Support
              </Text>
              <A href="tel:1-877-684-0032">
                <Text>1-877-684-0032</Text>
              </A>
            </Flex>
            <Flex flexDirection="row" mt="24px" alignItems="center">
              <NumberCircle>
                <Icon icon={phone} />
              </NumberCircle>
              <Text textAlign="left" ml="16px" flex={1}>
                Pharmacy Support
              </Text>
              <A href="tel:1-877-684-0032">
                <Text>1-877-684-0032</Text>
              </A>
            </Flex>
            <Flex flexDirection="row" mt="24px" alignItems="center">
              <NumberCircle>
                <Icon icon={mail} />
              </NumberCircle>
              <Text textAlign="left" ml="16px" flex={1}>
                Email:
              </Text>
              <A href="mailto:contact@palomahealth.com">
                <Text>contact@palomahealth.com</Text>
              </A>
            </Flex>
          </Box>
        )}
    </Container>
  );
};

UsrxCard.propTypes = {
  medicationTitle: PropTypes.string.isRequired,
  medicationCommon: PropTypes.string.isRequired,
  medicationPrice: PropTypes.string,
  canSee: PropTypes.bool,
};

UsrxCard.defaultProps = {
  canSee: false,
};
