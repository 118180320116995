import styled from "styled-components";

export const SwitchSelectorWrapper = styled.div`
    display: flex;
    border-radius: ${(props) => props.wrapperBorderRadius}px;
    border: ${(props) => props.border};
    background: ${(props) => props.backgroundColor};
    width: 100%;
    height: 100%;
    position: relative;

    ::before {
        top: 50%;
        left: ${(props) => props.selectedIndex===0?'4px':'calc(50% - 4px)'};
        right: ${(props) => props.selectedIndex===1?'4px':0};
        content: "";
        position: absolute;
        height: calc(100% - ${(props) => 4 * props.selectionIndicatorMargin}px);
        width: 50%; //calc(
            /* ${(props) => (1 / props.optionsAmount) * 100}% -
                ${(props) => 2 * props.selectionIndicatorMargin}px
        ); */
        border-radius: ${(props) => props.optionBorderRadius}px;
        /* border: ${(props) => props.selectionIndicatorMargin}px solid
            ${(props) => props.backgroundColor}; */
        background: white;//${(props) => props.selectedBackgroundColor};
        transition: left 0.1s linear, background 0.1s linear;
        transform: translateY(-50%);
        z-index: 1;
        box-sizing: content-box;
    }
`;

export const OptionItem = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: ${(props) => (1 / props.optionsAmount) * 100}%;
    border-radius: ${(props) => props.optionBorderRadius}px;
`;

export const OptionItemLabel = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    font-weight: 600;
    transition: color 0.1s linear;
    cursor: pointer;
    font-size: 18px;// ${(props) => (props.isRawText ? props.fontSize + "px" : "unset")};
    color: ${(props) =>
        props.isRawText ? (props.selected ? props.selectedFontColor : props.fontColor) : "unset"};
`;

export const OptionItemLabelSmall = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    font-weight: 600;
    transition: color 0.1s linear;
    cursor: pointer;
    font-size: 13px;// ${(props) => (props.isRawText ? props.fontSize + "px" : "unset")};
    color: ${(props) =>
        props.isRawText ? (props.selected ? props.selectedFontColor : props.fontColor) : "unset"};
`;

export const OptionInput = styled.input`
    width: 0;
    height: 0;
    opacity: 0;
    z-index: -1;
    position: absolute;
    pointer-events: none;
`;