import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

const linkToWebAppRoute = (history) => {
  const dl = history.location.pathname.replace('/transcript/', '');
  const params = history.location.search;

  switch (dl.toLowerCase()) {
    case 'labtestslist':
      if (!!params) return `labs/${params.replace('?labid=', '')}`;

      return 'labs/';

    default:
      return '';
  }
};

const BncLinkTranscript = ({ history }) => {
  useEffect(() => {
    if (!history || !history.location || !history.location.pathname) history.push('/');

    const dl = history.location.pathname.replace('/transcript/', '');
    const params = history.location.search;
    const target = window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'https://app.palomahealth.com' : 'https://preview-app.paloma.network';
    const targetMobile = window._env_.REACT_APP_SENTRY_ENV === 'production' ? 'palomamobile' : 'palomamobilestaging';
    console.log({ target, targetMobile });
    
    const bncLink = `https://bnc.lt/a/key_live_dfTZJYr6Knue7X0IXBfrcmcnxAjy9Z2s?$ios_deeplink_path=${targetMobile}://${dl}${params}&$android_deeplink_path=${targetMobile}://${dl}${params}&$fallback_url=${target}/${linkToWebAppRoute(
      history
    )}`;

    window.location.replace(bncLink);
  });

  return <div />;
};

export default withRouter(BncLinkTranscript);
