import React from 'react';
import { Flex, Text } from 'rebass';
import { GraphPink } from '../../YearlyReview/icons/GraphPink';
import DocWithSample from '../../../static/character.png';
import Odometer from '../../YearlyReview/components/RollingNumbers';
import styled from 'styled-components';

const CText = styled(Text)`
  font-size: 50px;
  line-height: 90px;
  margin-bottom: 24px;

  @media screen and (max-width: 52em) {
    font-size: 32px;
    line-height: 50px;
  }
`;
export const GenericPageBBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <GraphPink width={700} style={{ position: 'absolute', top: 80, left: 0 }} />
      <img
        src={DocWithSample}
        height={'25%'}
        style={{
          margin: '0 auto',
          height: 'calc(100vh / 4)',
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </Flex>
  );
};

export const GenericPageB = ({ score = 0, title = '', description = '' }) => {
  return (
    <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', flexDirection: 'column', zIndex:11 }} mt={'-100px'} >
      <Text style={{ fontSize: 36, fontWeight: 700, textAlign: 'center', lineHeight: '90px', marginBottom: '42px' }}>{title}</Text>

      <Flex style={{ width: '400px', borderRadius: '90px', height: '300px', backgroundColor: '#344F7A', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
        <Text style={{ fontSize: 70, fontWeight: 700, textAlign: 'center', lineHeight: '90px', color: 'white', fontFamily: 'Playfair Display' }}>
          <span>
            <Odometer delay={0} startNumber={0} to={score} style={{ color: 'white', fontSize: 90, fontWeight: 'bold', width: '100%', fontFamily: 'Playfair Display' }} />
          </span>{' '}
        </Text>
        <CText style={{ color: 'white', fontWeight: 700, textAlign: 'center', fontSize: 90 }}>%</CText>
      </Flex>

      <Text style={{ marginTop: '42px', textAlign: 'center' }}>{description}</Text>
    </Flex>
  );
};
