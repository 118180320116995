import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../../components/FullLayout';
import LoadingScreen from '../../components/LoadingScreen';
import { LogoHeader } from '../../components/Logo';
import { GET_SHARE_AGREEMENT_SURVEY, GET_SURVEY_REMINDER, MARK_BASELINE_COMPLETED, MARK_FOLLOWUP_SURVEY_COMPLETED, SET_SURVEY_SHARE_RESEARCH_ACCEPTANCE } from '../../graphql';
import withAnalytics from '../../lib/withAnalytics';
import withSession from '../../lib/withSession';
import britCare from '../../static/Brittany.jpeg';
import { FOLLOWUP_SURVEYS_ID } from '../../utils/followupSurveysUtils';
import { getPastBaselingSurveyData } from './FollowupSurvey';

const Header = styled(Flex)`
  height: 80px;
  max-height: 80px;
  background: #fdf5f2;
  width: 100%;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.08);
  z-index: 10;
  max-width: 100vw;
`;

const HeaderSub = styled(Flex)`
  max-width: 100vw;
  width: 100%;
  padding-left: 0;
  @media screen and (min-width: 52em) {
    padding-left: 30px;
  }
`;

const HeaderContent = styled(Flex)`
  height: 80px;
  max-height: 80px;
  width: 100vw;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  margin-top: -40px;
  @media screen and (max-width: 52em) {
    margin-top: 12px;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const FollowUpCompleted = ({ analytics, history, client, match, session: { dedicatedCareManager, isMobileAppUser } }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAgreeStep, setIsAgreeStep] = useState(false);
  const [survey, setSurvey] = useState();
  const [mustAcceptTerms, setMustAcceptTerms] = useState();

  useEffect(() => {
    setIsLoaded(false);
    if (!match || !match.params || !match.params.id) {
      setIsLoaded(true);
      return;
    }

    const element = FOLLOWUP_SURVEYS_ID.find((x) => x.id.toLowerCase() === match.params.id.toLowerCase());
    if (!element) {
      console.log('no element');
      setIsLoaded(true);
      return;
    }
    setSurvey(element);
    const getData = async (surv) => {
      const data = await getPastBaselingSurveyData(client, null, history, surv.localStorageKey, surv.name);
      console.log({ data });
      if (!data) {
        console.log('complete no data');
        history.push(`/followup-survey/${match.params.id}`);
        return;
      }
      const filtered = element.questions.filter((x) => {
        if (!!x.mobileUserOnly && !isMobileAppUser) return false;

        return true;
      });
      console.log({ filtered });
      for (let index = 0; index < filtered.length; index++) {
        const el = filtered[index];
        if (!el.cantBypass) continue;

        // if (data.findIndex((x) => x.id === el.id) < 0) {
        //   console.log('missing ', el)
        //   history.push(`/followup-survey/${match.params.id}`);
        // }
      }

      //TODO: Store to server + remove localStorage
      try {
        const res = await client.mutate({
          mutation: MARK_FOLLOWUP_SURVEY_COMPLETED,
          variables: {
            data: JSON.stringify(data),
            surveyName: surv.name,
          },
          refetchQueries: [{ query: GET_SURVEY_REMINDER }],
          awaitRefetchQueries: true,
        });
        if (res && res.data && res.data.markFollowupSurveyCompleted && res.data.markFollowupSurveyCompleted.ok) {
          localStorage.removeItem(surv.localStorageKey);
        }
        console.log('marked as complete ');
      } catch (error) {}
      try {
        const res = await client.query({
          query: GET_SHARE_AGREEMENT_SURVEY,
        });
        if (res && res.data && res.data.getSurveyShareResearchAgreement && !!res.data.getSurveyShareResearchAgreement.agreeTerms) {
          setMustAcceptTerms(false);
          history.push(`/followup-survey-outro/${match.params.id}`);
        } else {
          setMustAcceptTerms(true);
        }
      } catch (error) {
      }

      setIsLoaded(true);
    };

    getData(element);
  }, []);

  const saveTermsAcceptation = async (accepted) => {
    try {
      const res = await client.mutate({
        mutation: SET_SURVEY_SHARE_RESEARCH_ACCEPTANCE,
        variables: {
          accepted,
        },
      });

      if (res && res.data && res.data.setSurveyShareDataResearchTermsAcceptance) {
        history.push(`/followup-survey-outro/${match.params.id}`);
      }
    } catch (error) {}
  };
  const onAgree = async () => {
    await saveTermsAcceptation(true);
    history.push('/');
  };

  const decline = async () => {
    await saveTermsAcceptation(false);
    history.push('/');
  };
  if (!isLoaded) {
    return <LoadingScreen loading />;
  }

  return (
    <FullLayout2 style={{ zIndex: 1 }}>
      <Flex flex={1} flexDirection={'column'}>
        <Header flex={1}>
          <HeaderSub>
            <HeaderContent>
              <Flex flex={[1, 1, 0]} px="30px" alignItems="center" justifyContent={['center', 'center', 'flex-start']}>
                <Link to="/">
                  <LogoHeader height="40px" />
                </Link>
              </Flex>
            </HeaderContent>
          </HeaderSub>
        </Header>
        <Flex justifyContent={'center'} flexDirection="column" alignItems="center" style={{ margin: '0 auto', zIndex: 10, maxWidth: '80vw', width: '420px' }}>
          <ImageWrapper>
            <Img src={dedicatedCareManager && dedicatedCareManager.thumbnail ? dedicatedCareManager.thumbnail : britCare} />
          </ImageWrapper>
          {!!survey ? (
            <Flex flex={1} flexDirection={'column'} justifyContent={'center'} alignItems="center">
              <Heading textAlign={'center'}>Thank you for completing your {survey.title}</Heading>
              {!!mustAcceptTerms ? (
                <>
                  <Text mt={4} textAlign={'center'}>
                    I am excited to be part of this journey with you! I’d love to invite you to our research program!
                    <br />
                    <br />
                    Beyond our clinical services, we are dedicated to improve hypothyroid care and treatments through research. Your anonymized answers would help us do that.
                    <br />
                    <br />
                    Would you be willing to share your answers for research purposes (we will never share your name or your email address)
                  </Text>
                  {!!isAgreeStep ? (
                    <>
                      <Text mt={4} textAlign={'center'}>
                        Please agree to the following
                      </Text>
                      <Box style={{ backgroundColor: 'white', border: '1px solid #E6E6E6', height: '202px', maxHeight: '202px', padding: '0 8px', overflowY: 'scroll', overflowX: 'hidden' }}>
                        <Text fontSize="10px" fontWeight="300">
                          <br />
                          {`We are conducting a research survey to better understand how Paloma Health is making an impact.Your participation will help us to build a better solution for patients like you. Thank you!
Please read the information carefully before you begin.

This study aims to measure the impact of Paloma Health on your health and your quality of life. Your participation will help us understand how to further improve Paloma Health, and to further research on hypothyroidism and Hashimoto’s. This survey should take about 10 to 15 minutes to complete. Your name, or your email will not be used any further than to contact you if we have any follow-up questions. Your anonymized answers will be analyzed and potentially shared with research partners at an academic institution and may be used in academic publication. Your name and email address will never be shared with any other entities.
General Information:
The aim of this study is to measure the impact of Paloma Health services on your health and your quality of life.
You have been invited to participate in this study as you have recently subscribed to use Paloma Health services. Please read through these terms before you agree to participate by ticking “Yes, I agree to take part” box in the survey.
You can ask any research-related questions before you start with the survey, by emailing us at research@palomahealth.com
Please note that you can participate in this survey only if you are 18 years of age or older, and if you have been diagnosed with hypothyroidism or Hashimoto’s Thyroiditis by a physician.
This study is led by Paloma Health. The principal investigator (PI) is Marina Tarasova, and she conducts the study together with Dr. Vedrana Högqvist Tabor. The study is funded by Paloma Health.
What do you need to do?
You will be asked to answer a maximum of 45 questions based on your socioeconomic background, and your daily experiences with hypothyroidism. It should take you maximum 15 minutes, and no previous knowledge of hypothyroidism is needed.
Do you have to take part?
Your participation is voluntary. You may withdraw at any point in the survey, before submitting your answers, for any reason.
Your participation will help us create a better experience for you.
How will your data be used?
Your personal data (your name and your email) will not be used further than to contact you if we have any additional questions. Your name and your email will never be shared with the collaborating academic institutions.
Your anonymized and aggregated data will be stored in a password-protected file and may be used in academic publications.
Who will have access to your data?
Data scientist, PI and a director of Health Economics and Outcomes Research at Paloma Health will have access to your data. Read our privacy policy here.
Paloma Health will share the aggregated and anonymized survey data (data without your name or your email) with academic partners, for the purpose of research and publication of results in peer-reviewed academic journals or conferences. Please note that responsible members of the academic research partner institution may be given access to data for monitoring and/or audit of the study to ensure it complies with the guidelines, or as otherwise required by law.
If you give us your authorization, anonymized data and results will be published in open science platforms.
What if there is a problem?
If you have concerns about any aspect of this research, please contact us at research@palomahealth.com, and we will do our best to answer your query. We will acknowledge your concern within 30 working days and give you an answer or an indication of how we intend to deal with your inquiry/request.`}

                          <br />
                        </Text>
                      </Box>
                      <Button mt={4} variant={'primary'} onClick={onAgree} width="80%">
                        Yes, I agree
                      </Button>
                      <Button mt={4} variant={'white'} onClick={decline} width="80%">
                        Not at the moment
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button mt={4} variant={'primary'} onClick={() => setIsAgreeStep(true)} width="80%">
                        Yes, of course
                      </Button>
                      <Button mt={4} variant={'white'} onClick={decline} width="80%">
                        Not at the moment
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <Button mt={4} variant={'primary'} onClick={() => history.push(`/`)}>
                  Go back to dashboard
                </Button>
              )}
            </Flex>
          ) : (
            <>
              <Heading mt={4} textAlign={'center'}>
                Oooops
              </Heading>
              <Text mt={3} textAlign="center">
                There seems to be a glitch. This survey doesn't exist
              </Text>
              <Button mt={4} variant={'primary'} onClick={() => history.push(`/`)}>
                Go back to dashboard
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    </FullLayout2>
  );
};

export default withRouter(withAnalytics(withApollo(withSession(FollowUpCompleted))));
