import React from 'react';
import { Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';

const MobilePhone = styled.div`
  margin: auto;
  padding: 10px 10px 30px;
  width: 350px;
  height: 800px;
  box-shadow: 0 0 20px #e2e2e2;
  border-radius: 30px;
  background: black;
`;

const Brove = styled.div`
  width: 150px;
  height: 20px;
  background: black;
  position: absolute;
  margin: 0 100px;
  border-radius: 0 0 20px 20px;
`;

const Speaker = styled.span`
  width: 60px;
  height: 5px;
  background: #d2d2d2;
  display: block;
  margin: auto;
  margin-top: 5px;
  border-radius: 20px;
`;

const Screen = styled.div`
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  border-radius: 30px;
  overflow-y: auto;
  position: relative;
`;

const MobilePhonePreview = ({ program }) => {
  return (
    <MobilePhone>
      <Brove>
        <Speaker />
      </Brove>
      <Screen style={{ background: program.bgMainColor }}>
        <svg width={'100%'} viewBox="0 0 375 488" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 22, left: 0, right: 0 }}>
          <path
            d="M219.287 5.91273C274.004 20.9729 322.232 46.1141 363.412 84.7633C375.538 96.3966 388.245 107.412 401.485 117.768C452.301 156.617 463.915 209.794 454.949 268.363C445.453 330.374 419.214 385.583 372.691 428.73C328.437 469.769 273.899 490.163 214.007 487.468C188.885 486.337 162.876 473.078 139.879 460.546C88.5988 432.608 44.9871 394.556 4.47987 352.373C-34.7355 311.519 -45.7646 263.221 -39.5601 210.003C-33.2407 155.755 -12.6332 106 17.7302 61.0361C47.2343 17.3465 91.3425 -0.835997 143.275 0.0393262C168.632 0.459598 193.953 3.85816 219.287 5.91273Z"
            fill={program.bgBubblesColor}
          />
        </svg>
        <svg width="110" height="139" viewBox="0 0 110 139" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', bottom: 98, left: 0, right: 0 }}>
          <path
            opacity="0.2"
            d="M3.26277 2.31664C20.5622 -1.12594 37.4679 -0.883351 54.3038 4.06829C59.2965 5.59611 64.3644 6.87267 69.4879 7.8931C89.0276 11.5614 99.6823 24.6317 105.375 42.0503C111.402 60.4928 111.828 79.3829 104.936 97.7602C98.3801 115.24 86.0838 128.448 69.0748 136.024C61.9401 139.202 52.8649 139.153 44.7276 138.888C26.5834 138.297 9.15582 133.848 -7.98674 127.827C-24.585 121.993 -34.3956 110.189 -40.1077 94.6316C-45.9316 78.7742 -47.1601 62.1723 -45.0099 45.5381C-42.9202 29.3753 -33.2113 18.227 -18.667 11.2543C-11.5665 7.84784 -4.05986 5.26874 3.26277 2.31664Z"
            fill={program.bgBubblesColor}
          />
        </svg>
        <Flex style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, overflowY: 'auto', zIndex: 10, paddingTop: 40 }} px='12px' flexDirection="column">
          <Heading color={program.titleColor}>{program.name}</Heading>
          <Text mt={3} mb={3} color={program.descriptionColor}>{program.description}</Text>
        </Flex>
      </Screen>
    </MobilePhone>
  );
};

export default MobilePhonePreview;
