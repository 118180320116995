import React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass'
import styled from 'styled-components'

const Circle = styled(Box)`
width: 16px;
height: 16px;
border: 1px solid #E8E8E8; 
border-radius: 50%; 
display: flex; 
justify-content: center; 
align-items: center
background-color:white;
  transition: all 200ms ease-in;
&.isChecked {
border: 1px solid #1E46D4; 
background-color:rgba(30, 70, 212, 0.4);
};
&.isHovered {
border: 1px solid #1E46D4; 
}
`
const CircleInner = styled(Box)`
width: 6px;
height: 6px;
box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
background-color:white;
border-radius: 50%; 
align-self: center;
opacity: 0;
  transition: all 300ms ease-in;
&.isChecked {
  opacity: 1;
};
`

const CircleChecked = () => {
  return (
    <Box width='16px' height={'16px'} backgroundColor='rgba(30, 70, 212, 0.4)' style={{ border: '1px solid #1E46D4', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
      <Box style={{ background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '50%' }} height='6px' width={'6px'} />
    </Box>
  )
}
const CircleUnchecked = () => {
  return (
    <Box width='16px' height={'16px'} backgroundColor='white' style={{ border: '1px solid #E8E8E8', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >

    </Box>
  )
}
const CircleCheckUncheck = ({ isChecked, isHovered }) => {
  return (
    <Circle className={isChecked ? 'isChecked' : isHovered ? 'isHovered' : ''}>
      <CircleInner className={isChecked ? 'isChecked' : ''} />
    </Circle>
  )
}
const RadioButton = ({ label, value, onChange, disabled = false }) => {
  const [isMouseOver, setIsMouseOver] = useState(false)
  return (
    <Flex disabled={disabled} style={{opacity:disabled?0.3:1, cursor: disabled ? 'not-allowed' : 'pointer', alignItems: 'center' }} onMouseEnter={() => {
      if (disabled) return
      setIsMouseOver(true)
    }} onMouseLeave={() => setIsMouseOver(false)} onClick={() => {
      if (disabled) return
      onChange()
    }} mb={2}>
      <CircleCheckUncheck isChecked={!!value} isHovered={isMouseOver} />
      <Text fontWeight={300} ml={3} fontSize='14px'>{label}</Text>
    </Flex>
  )
}

export const RbCircledChecked = CircleChecked
export const RbCircledUnchecked = CircleUnchecked


RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  disabled: false,
};
export default RadioButton