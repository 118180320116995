import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import Mailcheck from 'react-mailcheck';

import { TextInput } from '../../../components/TextInput';
import { FormField } from '../../../components/FormField';
import { MaskInput } from '../../../components/MaskInput';
import styled from 'styled-components';

const MailcheckWrapper = styled.div`
  text-align: right;
  @media screen and (max-width: 52em) {
    text-align: left;
  }
`;

const MailcheckButton = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  font-size: inherit;
  font-style: italic;
  color: rgb(237, 183, 168);
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;

  &:focus {
    outline: none;
    color: rgb(237, 183, 168);
  }

  &:hover {
    color: rgb(237, 183, 168);
  }

  &:active {
    color: rgb(237, 183, 168);
  }
`;
const minDob = moment()
  .subtract(18, 'years')
  .toDate();

const maxDob = moment()
  .subtract(125, 'years')
  .toDate();

let schemaSelf = Yup.object().shape({
  uniqueCode: Yup.string()
    .required('Required'),
});

let schemaPartner = Yup.object().shape({
  uniqueCode: Yup.string()
    .required('Required'),
});

export const B2bSelf = ({ wizard, onSubmit, values, whereToFind, method }) => {
  return (
    <Flex mb={4} flex={1} flexDirection={['column', 'column', 'row']}>
      <Flex flex={1} mr={[0, 0, 4]} mb={[4, 4, 0]}>
        <Card p={4}>
          <Flex flexDirection="column">
            <Box mb={4}>
              <Heading textAlign="center" mb={3}>
                One Last Step
              </Heading>
              <Text textAlign="center">Please enter your Unique Code</Text>
            </Box>
            <Formik
              isInitialValid={({ initialValues }) => schemaSelf.isValidSync(initialValues)}
              initialValues={values}
              validationSchema={schemaSelf}
              onSubmit={(values, actions) =>
                onSubmit(
                  {
                    ...values,
                    employeeDob: values.dob,
                    employeeFirstName: values.firstName,
                    employeeLastName: values.lastName,
                    uniqueCode: values.uniqueCode,
                    method
                  },
                  actions,
                  wizard
                )
              }
              render={({ isValid, errors, touched, handleChange, handleBlur, setFieldValue, values }) => {
                // console.log({ values, isValid });
                return (
                  <Form>
                    <Box>
                      <FormField mb={3} error={touched.uniqueCode && errors.uniqueCode}>
                        <TextInput
                          placeholder="Your Unique Code"
                          name="uniqueCode"
                          id="input_uniqueCode"
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue('uniqueCode', e.target.value);
                          }}
                        />
                      </FormField>
                      {!!whereToFind && (
                        <Text mb={4} fontWeight={300}>
                          <span style={{fontWeight: 500}}>Where to find it - </span>
                          {whereToFind}
                        </Text>
                      )}
                    </Box>

                    <Flex justifyContent="center">
                      <Button variant="primary" width={[1 / 2, 1 / 3]} type="submit" disabled={!isValid} id="btn_next">
                        Submit
                      </Button>
                    </Flex>
                  </Form>
                );
              }}
            />
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

const B2bInformationUniqueCode = ({ wizard, values, onSubmit, whereToFind, method }) => {
  if ((values || {}).relationship === 'self') {
    return <B2bSelf wizard={wizard} onSubmit={onSubmit} values={values} whereToFind={whereToFind} method={method}/>;
  }
  if ((values || {}).relationship !== 'self') {
    return <B2bSelf wizard={wizard} onSubmit={onSubmit} values={values} whereToFind={whereToFind} method={method}/>;
  }
};

export default withWizard(B2bInformationUniqueCode);
