import React, { Fragment, useState } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Mutation, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import gql from 'graphql-tag';
import moment from 'moment';
import styled from 'styled-components';

import TextInput, { Input } from '../components/TextInput';
import FormField from '../components/FormField';
import Header from '../components/Header';
import withAnalytics from '../lib/withAnalytics';
import { CURRENT_USER, GENERATE_CIRCLE_SO_CALLBACK, GET_ANALYTICS_ID } from '../graphql';
import { useMount } from '../utils/hooks/lifecycle/useMount';
import LoadingScreen from '../components/LoadingScreen';
import FullLayout2 from '../components/FullLayout';
import SpinLoader from '../components/SpinLoader';
import { LOCAL_STORAGE_BASELINE_KEY } from './baselineSurvey/BaselineSurvey';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('invalid email address')
    .required('Required'),
  password: Yup.string()
    .trim()
    .required(),
});

const LOGIN = gql`
  mutation Login($email: String!, $password: String!, $otp: String) {
    login(email: $email, password: $password, otp: $otp) {
      token
      user {
        id
        accountLocked
      }
    }
  }
`;

const SEND_BY_EMAIL = gql`
  mutation sendOtpCodeViaEmail($email: String!) {
    sendOtpCodeViaEmail(email: $email) {
      ok
    }
  }
`;

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const CardContainer = styled(Flex)`
  max-width: 400px;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  color: #999;
  text-align: right;
`;

const OtpInner = ({ mutate, loading, sendByEmail }) => {
  const [value, setValue] = useState();

  return (
    <Flex mb={4} flexDirection="column">
      <Box
        px={2}
        style={{
          width: '100%',
        }}
      >
        {!loading ? (
          <>
            <Heading textAlign="center" mb={3}>
              We sent you an SMS code
            </Heading>
          </>
        ) : (
          <Text>Verifying...</Text>
        )}
      </Box>
      {loading ? (
        <Flex
          px={2}
          flexDirection="column"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '40px',
          }}
        >
          <SpinLoader />
        </Flex>
      ) : (
        <Flex px={2} flexDirection="column">
          <Text mb={2} textAlign="center" mt={3} style={{ fontWeight: 600 }}>
            Enter your code here
          </Text>
          <Flex justifyContent="center" alignItems="center" flexDirection="column">
            <Input
              style={{
                maxWidth: '250px',
                fontSize: '28px',
                letterSpacing: '16px',
                textAlign: 'center',
              }}
              placeholder="••••••"
              autoComplete="one-time-code"
              onChange={(r) => {
                setValue(r.target.value);
                if (r.target.value.length === 6) {
                  mutate(r.target.value);
                }
              }}
              autoFocus
              value={value}
            />

            <Text onClick={() => sendByEmail()} style={{ textDecoration: 'underline', cursor: 'pointer', textAlign: 'center' }} mt={4}>
              Send new code by email
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

const Login = ({ toastManager, analytics, client, history, location }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mustEnterOtp, setMustEnterOtp] = useState(false);
  const [storedData, setStoredData] = useState(null);

  useMount(() => {
    analytics.page();
    localStorage.removeItem('initialToken');
    localStorage.removeItem(LOCAL_STORAGE_BASELINE_KEY);
  });

  const sendByEmail = async () => {
    const aa = await client.mutate({
      mutation: SEND_BY_EMAIL,
      variables: {
        email: storedData.email,
      },
    });

    if (aa && aa.data && aa.data.sendOtpCodeViaEmail) {
      toastManager.add('Email Sent', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      toastManager.add('An error occured', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleSubmit = async (values, login, actions) => {
    setIsLoading(true);
    try {
      if (!values.otp) {
        setStoredData({ ...values });
      }
      const { data } = await login({ variables: { ...values } });

      // console.log({ error });
      const attemptedRoute = localStorage.getItem('route');
      localStorage.setItem('token', data.login.token);

      const aa = await client.query({
        query: GET_ANALYTICS_ID,
        fetchPolicy: 'network-only',
      });

      if (aa && aa.data && aa.data.getAnalyticsId) {
        analytics.Alias(aa.data.getAnalyticsId);
        try {
          const pt = await client.query({
            query: CURRENT_USER,
            fetchPolicy: 'network-only',
          });
          let age;
          let city;
          let state;
          let zip;
          let departmentId;
          let hashimoto;
          try {
            age = moment().diff(pt.data.currentUser.dob, 'years');
            if (pt.data.currentUser.address) {
              city = pt.data.currentUser.address.city;
              zip = pt.data.currentUser.address.zip;
              state = pt.data.currentUser.address.state;
            }
            if (pt.data.currentUser.department) {
              departmentId = pt.data.currentUser.department.departmentId;
            }
            if (pt.data.currentUser.diagnoseFull) {
              hashimoto = pt.data.currentUser.diagnoseFull.hasHashi;
            }
          } catch (error) {}

          analytics.Identify(aa.data.getAnalyticsId, {
            age,
            gender: pt.data.currentUser.gender,
            // city,
            // zip,
            state,
            departmentId,
            diagnosed: pt.data.currentUser.isDiagnosed,
            hashimoto: !!hashimoto,
            // dob: pt.data.currentUser.dob,
            // phone: pt.data.currentUser.phone
          });
        } catch (error) {
          // console.log('err id', error)
        }
      }

      if (location && location.search && location.search.indexOf('community') > -1) {
        const circleSo = await client.mutate({
          mutation: GENERATE_CIRCLE_SO_CALLBACK,
        });

        if (circleSo && circleSo.data && circleSo.data.generateCircleSoCallback) {
          if (!!circleSo.data.generateCircleSoCallback.callback) {
            window.open(circleSo.data.generateCircleSoCallback.callback, '_blank');
          } else if (!!circleSo.data.generateCircleSoCallback.mustAcceptTerms) {
            history.push({
              pathname: '/community-terms',
              state: { sameWindow: true },
            });
            return;
          }
        }
      }

      //

      const finalRoute = attemptedRoute ? (attemptedRoute.indexOf('/scheduler') > -1 ? '/scheduler' : attemptedRoute.indexOf('/schedule-split') > -1 ? '/schedule-split':attemptedRoute) : '/';
      setStoredData(null);
      history.push(finalRoute);
    } catch (error) {
      setIsLoading(false);

      if (!error.graphQLErrors) {
        return;
      }

      error.graphQLErrors.forEach((error) => {
        if (error.message.toLowerCase() === 'error: otp req') {
          setMustEnterOtp(true);
          return;
        }
        if (error.message.toLowerCase() === 'error: account locked') {
          history.push({
            pathname: '/account-locked',
            state: { email: values.email },
          });
        } else {
          toastManager.add(error.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <FullLayout2 style={{ overflow: 'auto', zIndex: 10 }}>
      <Fragment>
        <Container>
          <Flex flex={1}>
            <Header dark={false} />
          </Flex>
          <Flex flex={1} alignItems="center" justifyContent="center">
            <CardContainer flex={1} px={3}>
              <Card p={4}>
                <Flex flexDirection="column">
                  <Box mb={4}>
                    <Heading textAlign="center" mb={3}>
                      Login To Your Account
                    </Heading>
                    <Text textAlign="center">
                      Don't have an account?{' '}
                      <Link
                        to={{
                          pathname: '/prequalification',
                          state: { continueWith: location && location.search && location.search.indexOf('community') > -1 ? '/community-terms' : null },
                        }}
                        id="link_signup"
                      >
                        Sign Up!
                      </Link>
                    </Text>
                  </Box>
                  <Mutation mutation={LOGIN}>
                    {(login, { loading }) => {
                      return (
                        <Fragment>
                          <Formik
                            initialValues={{
                              email: '',
                              password: '',
                            }}
                            validationSchema={schema}
                            onSubmit={(values, actions) => handleSubmit(values, login, actions)}
                            render={({ touched, errors, isValid, values }) => {
                              return (
                                <>
                                  {!mustEnterOtp ? (
                                    <Form>
                                      <FormField mb={3} error={touched.email && errors.email}>
                                        <TextInput placeholder="Your email address" type="email" name="email" id="input_email" />
                                      </FormField>

                                      <FormField mb={4} error={touched.phone && errors.phone}>
                                        <TextInput placeholder="Password" type="password" name="password" id="input_password" />
                                        <StyledLink to="/recovery" id="link_forgotPassword">
                                          Forgot Password?
                                        </StyledLink>
                                      </FormField>
                                      <Flex alignItems="center" flexDirection="column">
                                        <Button disabled={!isValid || loading || isLoading} variant="primary" width={1 / 2} type="submit" id="btn_login">
                                          Login
                                        </Button>
                                      </Flex>
                                    </Form>
                                  ) : (
                                    <OtpInner
                                      mutate={(otp) => {
                                        handleSubmit({ ...storedData, otp }, login, null);
                                      }}
                                      sendByEmail={sendByEmail}
                                      loading={!!isLoading || !!loading}
                                    />
                                  )}
                                </>
                              );
                            }}
                          />
                        </Fragment>
                      );
                    }}
                  </Mutation>
                </Flex>
              </Card>
            </CardContainer>
          </Flex>
        </Container>
      </Fragment>
    </FullLayout2>
  );
};

export default withApollo(withAnalytics(withToastManager(Login)));
