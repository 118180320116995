import React from 'react';
import { Box, Heading, Text, Flex, Button } from 'rebass';
import styled from 'styled-components';
import { Query, withApollo } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';

// import ConfirmModal from '../components/ConfirmModal';
import {
  ADMIN_GET_MOBILE_COURSE_SCREENS,
  ADMIN_DELETE_MOBILE_COURSE_SCREEN,
  ADMIN_MOBILE_CHANGE_ORDER_COURSE_SCREEN,
  ADMIN_COURSE_SCREEN_REMAP_HORMONE,
  ADMIN_GET_THYROID_PROGRAM_COURSE_SCREENS,
  ADMIN_THYROID_PROGRAM_CHANGE_ORDER_COURSE_SCREEN,
  ADMIN_DELETE_THYROID_PROGRAM_COURSE_SCREEN,
  ADMIN_THYROID_PROGRAM_COURSE_SCREEN_REMAP_HORMONE,
} from '../../graphql';
// import UploadCoursesScreenFromCsv from './modals/uploadCoursesScreenFromCsv';
import ConfirmModal from '../../components/ConfirmModal';
import AddProgramCourseScreenModalInner from './modals/addProgramCourseScreenModalInner';

const ModalInner = styled(Box)`
  max-width: 850px;
  width: 100%;
  max-height: 600px;
`;

class ProgramCoursesListScreenDetailRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddScreenModalOpen: false,
      editingData: null,
      isCsvModalOpen: false,
    };
  }

  deleteOnClick = async id => {
    const { client, original } = this.props;
    var r = window.confirm(
      'Confirm Delete. (Any screen logged by user will be marked as inactive instead)'
    );
    if (r === true) {
      await client.mutate({
        mutation: ADMIN_DELETE_THYROID_PROGRAM_COURSE_SCREEN,
        variables: {
          screenId: id,
        },
        refetchQueries: [
          {
            query: ADMIN_GET_THYROID_PROGRAM_COURSE_SCREENS,
            variables: {
              courseId: original.id,
            },
          },
        ],
      });
    } else {
      return;
    }
  };

  changeDirectionOnClick = async (id, direction) => {
    const { client, original } = this.props;
    await client.mutate({
      mutation: ADMIN_THYROID_PROGRAM_CHANGE_ORDER_COURSE_SCREEN,
      variables: {
        screenId: id,
        direction,
      },
      refetchQueries: [
        {
          query: ADMIN_GET_THYROID_PROGRAM_COURSE_SCREENS,
          variables: {
            courseId: original.id,
          },
        },
      ],
    });
  };

  coursesScreensColumns = [
    {
      Header: 'title',
      accessor: 'title',
      Cell: row => {
        return <div title={row.original.title}>{row.original.title}</div>;
      },
    },
    {
      Header: 'Content',
      accessor: 'content',
      Cell: row => {
        return <div title={row.original.content}>{row.original.content}</div>;
      },
    },
    {
      Header: 'Screen Format',
      accessor: 'screenFormat',
      Cell: row => {
        return (
          <div
            style={{
              textAlign: 'center',
              backgroundColor: row.original.screenFormat
                ? row.original.screenFormat.color
                : 'white',
            }}
          >
            {row.original.screenFormat ? row.original.screenFormat.name : ''}
          </div>
        );
      },
    },
    {
      Header: 'Order',
      accessor: 'order',
      Cell: row => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {(row.original.order || 0) + 1}
          </div>
        );
      },
    },
    {
      Header: 'active',
      accessor: 'isActive',
      Cell: row => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.original.isActive ? '✓' : ''}
          </div>
        );
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div
              title="delete"
              style={{ cursor: 'pointer' }}
              onClick={() => this.deleteOnClick(row.original.id)}
            >
              🗑
            </div>
            <div
              title="move order up"
              style={{ cursor: 'pointer', marginLeft: '12px' }}
              onClick={() => this.changeDirectionOnClick(row.original.id, 'UP')}
            >
              ↑
            </div>
            <div
              title="move order down"
              style={{ cursor: 'pointer', marginLeft: '12px' }}
              onClick={() =>
                this.changeDirectionOnClick(row.original.id, 'DOWN')
              }
            >
              ↓
            </div>
            <div
              title="edit"
              style={{ cursor: 'pointer', marginLeft: '12px' }}
              onClick={() =>
                this.setState({
                  isAddScreenModalOpen: true,
                  editingData: row.original,
                })
              }
            >
              ✎
            </div>
          </Flex>
        );
      },
    },
  ];
  render() {
    const { original } = this.props;
    const { isAddScreenModalOpen, editingData, isCsvModalOpen } = this.state;
    return (
      <>
        <Query
          query={ADMIN_GET_THYROID_PROGRAM_COURSE_SCREENS}
          variables={{ courseId: original.id }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) return <div />;

            return (
              <div style={{ margin: '12px' }}>
                <Flex flexDirection="row" mb={4} alignItems="center">
                  <Text style={{ flex: 1 }}>Screens</Text>

                  <Button
                    style={{ background: '#344f79' }}
                    onClick={() => {
                      this.setState({
                        isAddScreenModalOpen: true,
                      });
                    }}
                  >
                    Add Screen
                  </Button>
                  <Text
                    style={{
                      textDecoration: 'underline',
                      marginLeft: '12px',
                      cursor: 'pointer',
                    }}
                    onClick={async () => {
                      var r = window.confirm(
                        'Will recompute the hormone location based on the order. Continue?'
                      );
                      if (r === true) {
                        await this.props.client.mutate({
                          mutation: ADMIN_THYROID_PROGRAM_COURSE_SCREEN_REMAP_HORMONE,
                          variables: {
                            courseId: original.id,
                          },
                          refetchQueries: [
                            {
                              query: ADMIN_GET_THYROID_PROGRAM_COURSE_SCREENS,
                              variables: { courseId: original.id },
                            },
                          ],
                        });

                        alert('Remap complete');
                      }
                    }}
                  >
                    Remap hormone location
                  </Text>
                </Flex>
                <ReactTable
                  data={data.getadminThyroidProgramCourseScreens}
                  columns={this.coursesScreensColumns}
                  defaultPageSize={
                    data.getadminThyroidProgramCourseScreens &&
                    data.getadminThyroidProgramCourseScreens.length > 3
                      ? data.getadminThyroidProgramCourseScreens.length
                      : 3
                  }
                  pageSize={
                    data.getadminThyroidProgramCourseScreens &&
                    data.getadminThyroidProgramCourseScreens.length > 3
                      ? data.getadminThyroidProgramCourseScreens.length
                      : 3
                  }
                  getProps={() => ({ someFunc: id => alert('clicked ' + id) })}
                  showPagination={false}
                  // showPagination={
                  //   data.adminGetMobileCourseScreens &&
                  //   data.adminGetMobileCourseScreens.length > 5
                  // }
                />
              </div>
            );
          }}
        </Query>

        <ConfirmModal
          childrenManaged
          isOpen={isAddScreenModalOpen}
          confirmLabel="create"
          onClose={() => {
            this.setState({ isAddScreenModalOpen: false, editingData: null });
          }}
          onBackgroundClick={() => {
            this.setState({ isAddScreenModalOpen: false, editingData: null });
          }}
          onEscapeKeydown={() => {
            this.setState({ isAddScreenModalOpen: false, editingData: null });
          }}
          onConfirm={() => {
            this.setState({ isAddScreenModalOpen: false, editingData: null });
          }}
        >
          <ModalInner>
            <Heading fontSize="20px">
              Create Screen for the {original.title} course
            </Heading>
            <AddProgramCourseScreenModalInner
              editingData={editingData}
              original={original}
              onClose={() => {
                this.setState({
                  isAddScreenModalOpen: false,
                  editingData: null,
                });
              }}
              onSave={() => {
                this.setState({
                  isAddScreenModalOpen: false,
                  editingData: null,
                });
              }}
            />
          </ModalInner>
        </ConfirmModal>

        <ConfirmModal
          childrenManaged
          isOpen={isCsvModalOpen}
          confirmLabel="create"
          onClose={() => {
            this.setState({ isCsvModalOpen: false, editingData: null });
          }}
          onBackgroundClick={() => {
            this.setState({ isCsvModalOpen: false, editingData: null });
          }}
          onEscapeKeydown={() => {
            this.setState({ isCsvModalOpen: false, editingData: null });
          }}
          onConfirm={() => {
            this.setState({ isCsvModalOpen: false, editingData: null });
          }}
        >
          <ModalInner>
            <Heading fontSize="20px">
              Create Screen for the {original.title} course
            </Heading>
            {/* <UploadCoursesScreenFromCsv /> */}
          </ModalInner>
        </ConfirmModal>
      </>
    );
  }
}

export default withApollo(ProgramCoursesListScreenDetailRow);
