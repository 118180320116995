import React from 'react';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';
import { Skeleton } from '../../../components/loadingSkeleton/Skeleton';

const Avatar = styled(Flex)`
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;
export const CardHeader = ({ details }) => {
  return (
    <>
      <Flex alignItems="center">
        {details.providerPicture && (
          <Avatar>
            <img src={details.providerPicture} alt="" height="60px" width="60px" />
          </Avatar>
        )}

        <Flex ml={3} flexDirection={'column'}>
          <Text mb={1} fontSize={'16px'} color="#344f79" fontWeight={500}>
            {details.providerName}
          </Text>
          <Text fontSize={'14px'} color="grey">
            {details.provider === 'doctor' ? 'Thyroid Doctor' : ''}
          </Text>
        </Flex>
      </Flex>

      <div style={{ width: '100%', height: '1px', background: '#FFE3D8', marginTop: '28px', marginBottom: '28px' }} />
    </>
  );
};

export const CardHeaderLoading = () => {
  return (
    <>
      <Flex alignItems="center">
        <Skeleton width="60px" height="60px" circle />

        <Flex ml={3} flexDirection={'column'}>
          <Skeleton width="180px" height="16px" />
          <Skeleton width="60px" height="14px" styleProp={{ marginTop: '4px' }} />
        </Flex>
      </Flex>

      <div style={{ width: '100%', height: '1px', background: '#FFE3D8', marginTop: '28px', marginBottom: '28px' }} />
    </>
  );
};
