import React, { Fragment } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';
import { withApollo, compose } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';

import TextInput from '../../../components/TextInput';
import moment from 'moment';
import FormField from '../../../components/FormField';

const StyledText = styled(Text)`
  line-height: 1.6;
  border-bottom: 1px solid;
  text-align: left;

  &:hover {
    cursor: pointer;
    font-weight: 600;
  }
`;

class LastLevelCheck extends React.Component {
  constructor(props) {
    super(props);

    let defaults;

    if (props.savedData) {
      defaults = this.unFormatSubmit(props.savedData);
    } else {
      defaults = (props.data || {}).defaults;
    }

    this.state = {
      loading: false,
      isInitialized: true,
      defaults,
    };
  }

  handleClick = async element => {
    const { data, wizard, client, toastManager, userData } = this.props;
  };

  formatSubmit = values => {
    const { data } = this.props;
    const regex = /_/g;
    let payload = [];
    if (data.isHyper) {
      payload.push({
        questionId: '18',
        answer: `${values.lastChecked}"`,
        value: 'Y',
      });
    }

    for (const k in values) {
      const questionId = k.replace(regex, '.');
      let answer;

      if (data.prefix) {
        answer = `${data.prefix} ${values[k]}`;
      } else {
        answer = values[k];
      }

      payload.push({ questionId, answer });
    }
    // console.log({ payload });

    return payload;
  };

  unFormatSubmit = values => {
    const { data } = this.props;
    let payload = {};

    for (const k in values) {
      const questionId = values[k].questionId;
      let answer;
      // console.log({ k, values });

      if (data.prefix) {
        answer = values[k].answer.replace(`${data.prefix} `, '');
      } else {
        answer = values[k].answer;
      }

      payload[`${questionId.toString()}`] = answer;
    }
    // console.log({ payload });
    return payload;
  };

  render() {
    const { data, onSubmit, wizard, savedData } = this.props;
    const { defaults, isInitialized } = this.state;
    if (!isInitialized) {
      return <div />;
    }
    return (
      <Fragment>
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          <Box m={2}>
            <Heading textAlign="left">{data.title}</Heading>
          </Box>

          <Formik
            initialValues={defaults}
            validationSchema={data.schema || ''}
            enableReinitialize={true}
            isInitialValid={(aaa, bbb) => {
              // console.log(data.schema);
              if (!data.schema) return true;

              let schema = data.schema;
              // console.log(schema);
              try {
                const aa = schema.validateSync(defaults);

                // const bb = await aa.isValid()
                if (aa.errors && aa.errors.length) {
                  return false;
                }
                return true;
              } catch (err) {
                return false;
              }
            }}
            onSubmit={(values, actions) => {
              if (!data.isYearOnly) {
                try {
                  const dt = moment(values.lastChecked);
                  if (dt.isAfter(moment())) {
                    actions.setFieldError(
                      'lastChecked',
                      'Date cannot be in the future'
                    );
                    return;
                  }
                } catch (error) {}
              } else {
                try {
                  const da = moment(values.lastChecked.toString(), 'YYYY');
                  if (da.isAfter(moment())) {
                    actions.setFieldError(
                      'lastChecked',
                      'Year cannot be in the future'
                    );
                    return;
                  }
                } catch (error) {}
              }
              const formatted = this.formatSubmit(values);
              // console.log({ formatted });
              onSubmit(formatted, data.id, actions, wizard, data);
            }}
            render={({ isValid, touched, errors }) => {
              return (
                <Form>
                  <Flex my={3} flexDirection="row" alignItems="center">
                    <FormField
                      mt={3}
                      mb={4}
                      error={touched['lastChecked'] && errors['lastChecked']}
                    >
                      {!!data.isYearOnly ? (
                        <TextInput
                          containerStyle={{ flex: 'initial' }}
                          isAsyncMode={true}
                          name={'lastChecked'}
                          type="number"
                          min={1900}
                          max={parseInt(moment().format('YYYY'), 10)}
                          step={1}
                          placeholder="YYYY"
                          pattern="[0-9]*" 
                        />
                      ) : (
                        <TextInput
                          containerStyle={{ flex: 'initial', minWidth: '200px' }}
                          isAsyncMode={true}
                          name={'lastChecked'}
                          type="date"
                          placeholder="MM/DD/YYYY"
                        />
                      )}
                    </FormField>
                  </Flex>

                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    mx={[3, 3, 0]}
                    my={[2, 3]}
                  >
                    <Button
                      disabled={!isValid || this.state.loading}
                      variant="primary"
                      width={[1, 1 / 2, 1 / 4]}
                      type="submit"
                    >
                      Continue
                    </Button>
                    {data.skip && (
                      <StyledText
                        mt={4}
                        onClick={() => {
                          wizard.push(data.skip);
                        }}
                      >
                        Skip
                      </StyledText>
                    )}
                  </Flex>
                </Form>
              );
            }}
          />
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(LastLevelCheck);
