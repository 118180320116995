import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Flex, Text } from 'rebass';
import { sleep } from '../../../utils/sleep';
import { SelectButton } from '../components/Shared';
import { FormerConstants } from '../utils/constants';
import { getDefaultValueSingle } from '../utils/extractBackResponse';

const ConsentLayout = ({ text, onNext, defaultValue, id }) => {
  const [selected, setSelected] = useState(getDefaultValueSingle(defaultValue));

  const onSelection = async (selected) => {
    setSelected(selected);
    await sleep(FormerConstants.WAIT_AFTER_SELECTION);
    onNext({
      id,
      selection: [
        {
          value: selected,
        },
      ],
    });
  };

  return (
    <>
      <Text fontSize={isMobile ? '14px' : '16px'} fontWeight="300" dangerouslySetInnerHTML={{ __html: text }} />
      <Flex flexDirection="row" flexWrap={'wrap'} mt={4}>
        <SelectButton
          text="Yes"
          isSelected={selected === 'Yes'}
          hasSelection={!!selected}
          onClick={() => {
            onSelection('Yes');
          }}
        />
        <SelectButton
          text="No"
          isSelected={selected === 'No'}
          hasSelection={!!selected}
          onClick={() => {
            onSelection('No');
          }}
        />
      </Flex>
    </>
  );
};

export default ConsentLayout