import React from 'react';
import { withRouter } from 'react-router';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';

import FullLayout2 from '../../components/FullLayout';

const Wrapper = styled.div`
  padding-left: 60px;
  padding-top: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex: 1;

  @media screen and (max-width: 52em) {
    padding-left: 0;
  }
`;

const Head = styled(Heading)`
  margin-bottom: 36px;
  text-align: center;
`;

const Card = styled(Flex)`
  flex: 1;
  background: white;
  border-radius: 8px 8px 8px 8px;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 2.41029px 24.1029px rgba(0, 0, 0, 0.1);
  padding: 24px;
`;

const BlueCard = styled(Box)`
  background: #d9e6f8;
  display: flex;
  padding: 16px;
  margin: 0 auto 24px auto;
`;

const SubscriptionChangesConfirm = ({ history, location }) => {
  const { title, text } = location.state || {};

  const goToDash = () => {
    history.push('/');
  };

  if (!title || !text) {
    goToDash();
  }

  return (
    <Wrapper>
      <Card>
        <Head>{title}</Head>
        <BlueCard>
          <svg width="36" height="27" viewBox="0 0 36 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.2272 26.4697L0.527186 14.7024C-0.175729 13.9955 -0.175729 12.8492 0.527186 12.1422L3.07271 9.58197C3.77563 8.87495 4.91539 8.87495 5.61831 9.58197L13.5 17.5089L30.3817 0.530217C31.0846 -0.176739 32.2244 -0.176739 32.9273 0.530217L35.4728 3.09045C36.1757 3.79741 36.1757 4.94366 35.4728 5.65068L14.7728 26.4698C14.0698 27.1767 12.9301 27.1767 12.2272 26.4697Z"
              fill="white"
            />
          </svg>

          <Text ml="32px">{text}</Text>
        </BlueCard>

        <Button style={{ margin: '0 auto 24px auto' }} variant="primary" onClick={goToDash}>
          Back to Dashboard
        </Button>
      </Card>
      <FullLayout2 />
    </Wrapper>
  );
};

export default withRouter(SubscriptionChangesConfirm);
