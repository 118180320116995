import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const Container = styled(Box)`
  max-width: 600px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 1;
  overflow-y: ${(props) => (isMobile ? 'hidden' : '')};
`;

const InnerContainerInformation = styled(Flex)`
  max-width: ${(props) => (isMobile ? 'calc(100% - 12px)' : '1200px')};
  height: auto;
  margin: 0 auto;
  max-height: 100%;
  flex-direction: column;
  overflow-y: ${(props) => (isMobile ? 'auto' : '')};
`;

const BookConsultationMeno = ({ ...props }) => {
  const [skp, setSkp] = useState(props.location && props.location.search && props.location.search.indexOf('?skp=true') > -1);
  return (
    <>
      <Container>
        <InnerContainerInformation p={3} mt={2} height={'auto'} style={{ height: 'auto !important' }}>
          {/* <Card p={4} mb={4}> */}
            <Flex mb={4} flexDirection="column">
              <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                <Flex flexDirection={'column'}>
                  <Heading mt={4} mb={4} textAlign={'center'}>
                    Do you have recent lab results you can upload?
                  </Heading>
                  <Text textAlign="center">
                    Uploading recent lab results can help our providers provide more personalized care during your menopause visit. Examples of helpful biomarkers include estrogen, progesterone, LH,
                    and FSH.
                  </Text>
                  <Text textAlign="center" mt={3}>
                    <span style={{ fontWeight: 600 }}>If you don't have recent lab results, you can still schedule your visit. </span>
                    Your provider will order the necessary labs for you during the consultation.
                  </Text>

                  <Button variant="primary" mt={4} onClick={() => props.history.push(`/schedulemeno?${!!skp ? 'skp=true&' : ''}hasLabs=true`)}>
                    Yes, I have recent lab results to upload
                  </Button>
                  <Button variant="pink" mt={4} onClick={() => props.history.push(`/schedulemeno${!!skp ? '?skp=true' : ''}`)}>
                    No, I don't have recent lab results
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          {/* </Card> */}
        </InnerContainerInformation>
      </Container>
    </>
  );
  // }

  // return <BookConsultationInner {...props} />;
};

export default withRouter(BookConsultationMeno);
