import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Box, Button, Card, Flex, Heading } from 'rebass';
import { Redirect } from 'react-router-dom';
import { Query } from 'react-apollo';
import { GET_REQUISITION_FORM } from '../../graphql';
import SpinLoader from '../../components/SpinLoader';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;

const ViewRequisitionForm = props => {
  if (!props.match.params.id) {
    return <Redirect to={{ pathname: '/' }} />;
  }
  // console.log(props.match.params.id);
  return (
    <Fragment>
      <Container>
        <Card mb={4} p={[3, 4]} style={{ height: 'calc(100vh - 170px)' }}>
          <Query
            query={GET_REQUISITION_FORM}
            variables={{ serial: props.match.params.id }}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return (
                  <div
                    style={{
                      height: 'calc(100% - 130px)',
                      width: '100%',
                      marginBottom: '16px',
                    }}
                  >
                    <Flex
                      flex={1}
                      justifyContent="center"
                      alignItems="center"
                      style={{ height: '100%' }}
                    >
                      <SpinLoader />
                    </Flex>
                  </div>
                );
              }
              if (
                !data ||
                !data.getRequisitionFormPdf ||
                !data.getRequisitionFormPdf.req
              ) {
                return (
                  <div
                    style={{
                      height: 'calc(100% - 130px)',
                      width: '100%',
                      marginBottom: '16px',
                    }}
                  >
                    <Flex
                      flex={1}
                      justifyContent="center"
                      alignItems="center"
                      style={{ height: '100%' }}
                    >
                      {data &&
                      data.getRequisitionFormPdf &&
                      data.getRequisitionFormPdf.customError &&
                      data.getRequisitionFormPdf.customError.userMessage ? (
                        <Heading mb={4} fontSize={3}>
                          {data.getRequisitionFormPdf.customError.userMessage}
                        </Heading>
                      ) : (
                        <Heading
                          mb={4}
                          fontSize={3}
                        >{`No Requisition Form found for serial ${
                          props.match.params.id
                        }`}</Heading>
                      )}
                    </Flex>
                  </div>
                );
              }
              // console.log('DATA', data);
              return (
                <Fragment>
                  <iframe
                    style={{
                      height: 'calc(100% - 130px)',
                      width: '100%',
                      marginBottom: '16px',
                    }}
                    title="reqForm"
                    src={`data:application/pdf;base64, ${
                      data.getRequisitionFormPdf.req
                    }`}
                  />
                  <span>
                    Not Displaying correctly?&nbsp;
                    <a
                      href={`data:application/pdf;base64, ${
                        data.getRequisitionFormPdf.req
                      }`}
                      download={`Requisition Form -- ${
                        props.match.params.id
                      }.pdf`}
                    >
                      Click here to download
                    </a>
                  </span>
                  <br />
                </Fragment>
              );
            }}
          </Query>
          <Button
            mt={4}
            mb={4}
            variant="primary"
            width={1 / 2}
            type="submit"
            onClick={() => props.history.push('/')}
          >
            Scan New Serial
          </Button>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ViewRequisitionForm;
