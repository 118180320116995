import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import ConfirmModal from '../../components/ConfirmModal';
import PleaseWait from '../../components/PleaseWait';
import { Input } from '../../components/TextInput';
import ModalReassignCareManager from './ModalReassignCareManager';
import BatchMapToNewCareManager from './batchMapToNewCareManager';

export const SEARCH_USER_FROM_CARE_MANAGERS = gql`
  query searchUserFromCareManagers($athenaId: String!) {
    searchUserFromCareManagers(athenaId: $athenaId) {
      id
      email
      phone
      firstName
      lastName
      athenaId
      careManager
      helpscoutId
      stripeId
      isMember
    }
  }
`;
const ModalInner = styled(Box)`
  max-width: 60vw;
  min-width: 60vw;
  width: 100%;
  max-height: 80vh;
  /* overflow-y: auto; */
`;

const Results = ({ data, toastManager, careManagers, search }) => {
  const [selectedForChange, setSelectedForChange] = useState();
  if (!data || data.length < 1) {
    return (
      <Text textAlign={'center'} style={{ opacity: 0.7 }} m={4}>
        No Results
      </Text>
    );
  }
  const getCareManager = (user) => {
    const cm = careManagers.find((x) => x.helpscoutId === user.careManager);
    if (user.careManager) {
      if (!!cm) {
        return `${cm.fullName} (${cm.helpscoutId})`;
      } else {
        if (!user.isMember) {
          return `(not a member)`;
        } else {
          if (!user.careManager) {
            return careManagers.find((x) => !!x.isFallback).fullName + ' (defaulted)';
          } else {
            return `Care manager not found Helpscout ID ${user.careManager}`;
          }
        }
      }
    }
    if (!user.isMember) {
      return `(not a member)`;
    } else {
      if (!user.careManager) {
        return careManagers.find((x) => !!x.isFallback).fullName + ' (defaulted)';
      } else {
        return `Care manager not found Helpscout ID ${user.careManager}`;
      }
    }
  };
  return (
    <Flex flexDirection={'column'}>
      {data.length > 1 && (
        <Text color="red" mb={3}>
          ⚠ multiple users found with this search. Please report
        </Text>
      )}
      {data.map((x) => {
        return (
          <Box key={x.id} width={'100%'} p={3} background="#f6f6f6" style={{ border: '1px solid #5d5d5d' }}>
            <Flex flexDirection={'row'}>
              <Flex flexDirection={'column'} flex={1}>
                <Flex flexDirection={'row'} mb={2}>
                  <Flex flexDirection={'column'} flex={1}>
                    <Text fontWeight={300}>email:</Text>
                    <Text fontWeight={500}>{(x || {}).email}</Text>
                  </Flex>
                  <Flex flexDirection={'column'} flex={1}>
                    <Text fontWeight={300}>athenaId:</Text>
                    <Text fontWeight={500}>{(x || {}).athenaId}</Text>
                  </Flex>
                </Flex>
                <Flex flexDirection={'row'} mb={2}>
                  <Flex flexDirection={'column'} flex={1}>
                    <Text fontWeight={300}>first name:</Text>
                    <Text fontWeight={500}>{(x || {}).firstName}</Text>
                  </Flex>
                  <Flex flexDirection={'column'} flex={1}>
                    <Text fontWeight={300}>last name:</Text>
                    <Text fontWeight={500}>{(x || {}).lastName}</Text>
                  </Flex>
                </Flex>
                <Flex flexDirection={'row'} mb={2}>
                  <Flex flexDirection={'column'} flex={1}>
                    <Text fontWeight={300}>Is member:</Text>
                    <Text fontWeight={500}>{(x || {}).isMember ? 'Yes' : 'No'}</Text>
                  </Flex>
                  <Flex flexDirection={'column'} flex={1}>
                    <Text fontWeight={300}>Care manager:</Text>
                    <Text fontWeight={500}>{getCareManager(x)}</Text>
                  </Flex>
                </Flex>
                <Flex flexDirection={'row'} mb={2}>
                  <Flex flexDirection={'column'} flex={1}>
                    <Text fontWeight={300}>helpscout Id:</Text>
                    <Text fontWeight={500}>{(x || {}).helpscoutId}</Text>
                  </Flex>
                  <Flex flexDirection={'column'} flex={1}>
                    <Text fontWeight={300}>stripe Id:</Text>
                    <Text fontWeight={500}>{(x || {}).stripeId}</Text>
                  </Flex>
                </Flex>
              </Flex>

              <Flex flex={'initial'} justifyContent="center" alignItems={'center'}>
                <Button
                  variant={'primary'}
                  style={{ alignSelf: 'center' }}
                  onClick={() => {
                    setSelectedForChange(x);
                  }}
                >
                  Change Care Manager
                </Button>
              </Flex>
            </Flex>
          </Box>
        );
      })}
      {!!selectedForChange && (
        <ConfirmModal
          childrenManaged
          isOpen={!!setSelectedForChange}
          confirmLabel="create"
          onClose={() => {
            setSelectedForChange(false);
          }}
          onBackgroundClick={() => {
            setSelectedForChange(false);
          }}
          onEscapeKeydown={() => {
            setSelectedForChange(false);
          }}
          onConfirm={() => {
            setSelectedForChange(false);
          }}
        >
          <ModalInner>
            <ModalReassignCareManager
              careManagers={careManagers}
              user={selectedForChange}
              onClose={() => {
                setSelectedForChange(false);
              }}
              search={search}
            />
          </ModalInner>
        </ConfirmModal>
      )}
    </Flex>
  );
};

const ModalSearchUser = ({ toastManager, careManagers }) => {
  const [search, setSearch] = useState();
  const [tmpSearch, setTmpSearch] = useState();

  const handleSearch = () => {
    setSearch(tmpSearch);
  };

  if (!search) {
    return (
      <Flex flexDirection={'column'} justifyContent="center" alignItems={'center'} style={{ minWidth: '600px' }}>
        {/* <Heading mb={4}>Search User</Heading>

        <Input
          placeholder="Athena ID or Email Address"
          name="search"
          type="search"
          id="input_search"
          value={tmpSearch}
          onChange={(v) => {
            setTmpSearch(v.target.value);
          }}
          style={{ minWidth: '600px' }}
          onEnter={() => {
            handleSearch();
          }}
        />

        <Button mt={3} variant={'primary'} onClick={handleSearch}>
          Search
        </Button> */}
        <BatchMapToNewCareManager />
      </Flex>
    );
  }

  return (
    <Query
      query={SEARCH_USER_FROM_CARE_MANAGERS}
      variables={{
        athenaId: search,
      }}
    >
      {({ data, loading }) => {
        if (!!loading) return <PleaseWait />;
        return (
          <>
            <Flex flexDirection={'column'}>
              <Flex>
                <Text mb={3}>
                  Search Results for {(search || '').indexOf('@') > -1 ? 'email:' : 'Athena ID:'} {search}
                </Text>
                <Text mb={3} ml={3} onClick={() => setSearch(null)}>
                  X
                </Text>
              </Flex>
              <Results data={data.searchUserFromCareManagers} search={search} toastManager={toastManager} careManagers={careManagers} />
            </Flex>
          </>
        );
      }}
    </Query>
  );
};

export default withToastManager(ModalSearchUser);
