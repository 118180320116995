import React, { useState, Fragment } from 'react';
import { withWizard } from 'react-albus';
import { Query } from 'react-apollo';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import PleaseWait from '../../../components/PleaseWait';
import { Input } from '../../../components/TextInput';
import { GET_GENDER_IDENTITIES } from '../../../graphql';

const Btn = styled(Button)`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 300;

  transition: background 300ms ease-in;
  &.selected {
    background: #e7f0ff;
    border: 1px solid #354e79;
    box-sizing: border-box;
  }
`;
const SelectedMarkerOut = styled(Flex)`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 2px solid #354e79;
  border-radius: 12px;
  margin-right: 12px;
`;
const SelectedMarkerIn = styled(Flex)`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #354e79;
  transition: all 450ms ease-in;
`;

const GenderIdentity = ({ values, onSubmit, wizard }) => {
  const [selected, setSelected] = useState((values || {}).genderidentity);
  const [otherSpecify, setOtherSpecify] = useState((values || {}).genderidentityother);
  return (
    <Query query={GET_GENDER_IDENTITIES}>
      {({ loading, data }) => {
        if (!!loading) {
          return <PleaseWait />;
        }

        return (
          <Flex flex={1} flexDirection="column" style={{ maxWidth: '600px', margin: '0 auto' }}>
            <Box>
              <Heading textAlign="center">What is your gender identity?</Heading>
            </Box>
            <Flex my={3} flexWrap="wrap" justifyContent="center" flexDirection="row">
              {data.getGenderIdentities &&
                data.getGenderIdentities.map((el) => (
                  <Box key={el} width={['100%', '50%', '50%']} style={{ display: 'flex' }}>
                    <Btn
                      disabled={loading}
                      type="button"
                      variant={'white'}
                      style={{ flex: 1 }}
                      // width={[1]}
                      m={3}
                      onClick={() => {
                        if (el !== selected) {
                          setOtherSpecify();
                          setSelected(el);
                        }
                      }}
                      className={selected === el ? 'selected' : 'white'}
                    >
                      <Flex flex={1} flexDirection="row">
                        <SelectedMarkerOut>{selected === el && <SelectedMarkerIn />}</SelectedMarkerOut>
                        <Flex flex={1} flexDirection="column" style={{ textAlign: 'left' }}>
                          <Text textAlign="left" mt="4px">
                            {el}
                          </Text>
                          {(el || '').indexOf('specify') > -1 && selected === el ? (
                            <Input style={{ marginTop: '24px' }} flex={1} placeholder={'Specify'} type={'text'} value={otherSpecify} onChange={(e) => setOtherSpecify(e.target.value)} />
                          ) : null}
                        </Flex>
                      </Flex>
                    </Btn>
                  </Box>
                ))}
            </Flex>

            <Flex justifyContent="center">
              <Button
                mt={3}
                disabled={!selected || ((selected || '').indexOf('specify') > -1 && !otherSpecify)}
                variant="primary"
                width={[1 / 2, 1 / 3]}
                type="submit"
                onClick={() => {
                  onSubmit(
                    {
                      ...values,
                      genderidentity: selected || null,
                      genderidentityother: otherSpecify || null,
                    },
                    null,
                    wizard
                  );
                }}
              >
                Continue
              </Button>
            </Flex>

            <Flex justifyContent="center" mt={3} mb={4}>
              <Text
                textAlign="center"
                style={{ color: '#0075FF', textDecoration: 'underline' }}
                width={[1 / 2, 1 / 3]}
                onClick={() => {
                  onSubmit(
                    {
                      ...values,
                      genderidentity: null,
                      genderidentityother: null,
                    },
                    null,
                    wizard
                  );
                }}
              >
                Skip
              </Text>
            </Flex>
          </Flex>
        );
      }}
    </Query>
  );
};

export default withWizard(GenderIdentity);
