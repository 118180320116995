import React from 'react';
import { withWizard } from 'react-albus';
import { Button, Card, Heading, Text, Flex } from 'rebass';
import styled from 'styled-components';

const A = styled(Text)`
    text-decoration: underline;
    margin-top: 28px;
    text-align: center;
    cursor: pointer;
`

const Confirm = ({ wizard, goBack, confirm }) => {
  return (
    <Flex flexDirection='column'>
      <Card style={{flexDirection:'column', textAlign: 'center', padding: '32px'}}>
        <Heading>Are You Sure?</Heading>

        <Text textAlign="center" style={{maxWidth: '400px', margin: '32px auto 0 auto'}}>
          If you cancel your membership, you won’t be able to resubscribe for a
          full calendar year
        </Text>

        <Button style={{marginTop: '28px'}} variant="primary" onClick={goBack}>
          Nevermind
        </Button>

        <A onClick={() => confirm(wizard)}>
          Cancel Subscription
        </A>
      </Card>
      <A onClick={goBack}>Go Back</A>
    </Flex>
  );
};

export default withWizard(Confirm);
