import React, { Fragment, useState } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';
import { withApollo, compose } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import * as Yup from 'yup';

import FormField from '../../../components/FormField';
import {
  TextInput,
  MaskInput,
  BoxButton,
} from '../../../components/async/SharedComponents';
import PlacesAutocomplete from '../../../components/addressAutoComplete/PlacesAutocomplete';
import RegionSelect from '../../../components/RegionSelect';
import Spinner from '../../../components/Spinner';

const Description = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
`;

const phoneRegExp = /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

const Profile = ({ data, onSubmit, savedData, submitCheck }) => {
  const [isFormExtended, setIsFormExtended] = useState(
    savedData && savedData.street1 && savedData.street1.length
  );

  const [forceHideAutocomplete, setForceHideAutocomplete] = useState(false);

  const schema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .min(2, 'Please enter your real name')
      .required('Required'),
    lastName: Yup.string()
      .trim()
      .min(2, 'Please enter your real name')
      .required('Required'),
    street1: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
    phone: Yup.string()
      .trim()
      .required('Required')
      .matches(phoneRegExp, 'Phone number is not valid'),
  });

  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
        <Box m={2}>
          <Heading textAlign="left">Set up your Profile</Heading>
        </Box>
        <Box m={2} mb={3} mt="0">
          <Description>
            Let's get started with some basic information.
          </Description>
        </Box>

        <Formik
          isInitialValid={({ initialValues }) =>
            schema.isValidSync(initialValues)
          }
          initialValues={savedData}
          validationSchema={schema}
          onSubmit={(values, actions) => onSubmit(values, actions)}
          render={({
            touched,
            errors,
            isValid,
            handleChange,
            handleBlur,
            isSubmitting,
            values,
            setFieldValue,
            setFieldTouched,
          }) => {
            let errStreet1
            if(touched.street1 && errors.street1){
              errStreet1=errors.street1
            }else if(values && values.street1 && values.street1.indexOf('@')>-1){
              errStreet1='incorrect street address'
            }else{
              errStreet1=null
            }
            return (
              <Form>
                <Box pb="80px">
                  <Flex flexDirection={['column', 'column', 'row']}>
                    <FormField
                      flex={[1, 1, 1 / 2]}
                      mb={3}
                      mr={[0, 0, 3]}
                      error={touched.firstName && errors.firstName}
                    >
                      <TextInput
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                        id="input_firstname"
                      />
                    </FormField>
                    <FormField
                      flex={[1, 1, 1 / 2]}
                      mb={3}
                      error={touched.lastName && errors.lastName}
                    >
                      <TextInput
                        placeholder="Last Name"
                        type="text"
                        name="lastName"
                        id="input_lastname"
                      />
                    </FormField>
                  </Flex>

                  <div
                    style={{
                      maxHeight:
                        values.street1 || values.city || values.zip
                          ? '0px'
                          : '',
                      opacity:
                        values.street1 || values.city || values.zip ? '0' : '1',
                    }}
                  >
                    <FormField mb={3}>
                      <PlacesAutocomplete
                        isAsyncMode={true}
                        fieldname="address"
                        onTextChanged={val => {
                          if (val && val.length > 0) {
                            if (!isFormExtended) {
                              setIsFormExtended(true);
                            }
                          } else {
                            if (
                              !values.street1 &&
                              !values.street2 &&
                              !values.city &&
                              !values.zip
                            ) {
                              setIsFormExtended(false);
                            }
                          }
                        }}
                        onSelected={val => {
                          setFieldValue('street1', val.street);
                          setFieldValue('city', val.city);
                          setFieldValue('zip', val.zip);
                          setFieldValue('state', val.state);
                          setFieldTouched('street1');
                          setFieldTouched('city');
                          setFieldTouched('zip');
                          setFieldTouched('state');
                        }}
                        onBlur={val => {
                          if (!isFormExtended && val) {
                            setIsFormExtended(true);
                            setForceHideAutocomplete(true);
                          }
                          if (
                            !values.street1 &&
                            !values.street2 &&
                            !values.city &&
                            !values.zip
                          ) {
                            setFieldValue('street1', val);
                            setFieldTouched('street1', true);
                          }
                        }}
                        street1FieldName="street1"
                        cityFieldName="city"
                        zipFieldName="zip"
                        stateFieldName="state"
                      />
                    </FormField>
                  </div>

                  {isFormExtended && (
                    <>
                      <FormField
                        mb={3}
                        error={errStreet1}
                      >
                        <TextInput
                          placeholder="Address"
                          type="text"
                          name="street1"
                        />
                      </FormField>

                      <FormField
                        mb={3}
                        error={touched.street2 && errors.street2}
                      >
                        <TextInput
                          placeholder="Apartment, Suite, Floor (Optional)"
                          type="text"
                          name="street2"
                        />
                      </FormField>

                      <Flex flexDirection={['column', 'column', 'row']}>
                        <FormField
                          width={[1, 1, 1 / 3]}
                          mr={[0, 0, 3]}
                          mb={3}
                          error={touched.city && errors.city}
                        >
                          <TextInput
                            placeholder="City"
                            type="text"
                            name="city"
                          />
                        </FormField>

                        <FormField
                          width={[1, 1, 1 / 3]}
                          mr={[0, 0, 3]}
                          mb={3}
                          error={touched.state && errors.state}
                        >
                          <RegionSelect name="state" value={values.state} />
                        </FormField>

                        <FormField
                          width={[1, 1, 1 / 3]}
                          mb={3}
                          error={touched.zip && errors.zip}
                        >
                          <TextInput placeholder="Zip" type="text" name="zip" />
                        </FormField>
                      </Flex>
                    </>
                  )}

                  <FormField
                    mt={3}
                    mb={4}
                    error={touched.phone && errors.phone}
                  >
                    <MaskInput
                      name="phone"
                      placeholder="Mobile Phone Number"
                      mask="999-999-9999"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="input_phone"
                    />
                  </FormField>
                </Box>
                <BoxButton className={isValid ? 'visible' : ''}>
                  <Button
                    disabled={!isValid || isSubmitting || !values || !values.street1 || values.street1.indexOf('@')>-1}
                    variant="primary"
                    style={{
                      marginLeft: '12px',
                      marginRight: '12px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    width={[1, 1 / 2, 1 / 4]}
                    type="submit"
                  >
                    {isSubmitting ? (
                      <>
                        Processing
                        <Spinner
                          style={{
                            marginLeft: '12px',
                            height: '20px',
                            width: '20px',
                          }}
                        />
                      </>
                    ) : (
                      'Continue'
                    )}
                  </Button>
                </BoxButton>
              </Form>
            );
          }}
        />
      </Flex>
    </Fragment>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(Profile);
