import { height, width } from 'styled-system';
import styled from 'styled-components';
import tag from 'clean-tag';

const IconContainer = styled(tag)`
  ${height}
  ${width}
  svg {
    ${height}
    ${width}
  }
`;

IconContainer.propTypes = {
  ...height.propTypes,
  ...width.propTypes,
};

export default IconContainer;
