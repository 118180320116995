import React from 'react';
import { withWizard } from 'react-albus';
import { Mutation, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { Button, Card, Heading, Text, Flex } from 'rebass';
import styled from 'styled-components';
import PleaseWait from '../../../../../components/PleaseWait';
import { APPLY_CANCELLATION_COUPON, GET_MEMBERSHIPS } from '../../../../../graphql';
import plant from '../../../../../static/plant.png';
import withAnalytics from '../../../../../lib/withAnalytics';

const A = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: #44a5ff;
  text-decoration: underline;
  margin: 36px auto 0 auto;
  cursor: pointer;
`;

const OfferWrapper = styled(Flex)`
  border-radius: 10px;
  background: #d9e6f8;
  flex-direction: column;
  padding: 30px 10px;
  margin-bottom: 18px;
`;

const Content = styled(Flex)`
  flex-direction: row;
`;

const OfferTextWrapper = styled(Flex)`
  flex-direction: column;
  margin-left: 16px;
  text-align: left;
`;

const NotBetter = ({ subscriptionId, wizard, history, subscriptionType, confirm, flow, reason, analytics }) => {
  return (
    <Query query={GET_MEMBERSHIPS} fetchPolicy="network-only">
      {({ loading, data, error }) => {
        if (loading) return <PleaseWait style={{ position: 'absolute', left: '50%', top: '50%' }} />;
        return (
          <Mutation
            mutation={APPLY_CANCELLATION_COUPON}
            refetchQueries={[{ query: GET_MEMBERSHIPS }]}
            awaitRefetchQueries
            variables={{
              subscriptionId,
              subscriptionType,
              reason
            }}
            onCompleted={() => {
              history.push({
                pathname: '/subscription-updated',
                state: {
                  title: 'Your free visit awaits!',
                  text: 'As a thank-you for staying with us, enjoy a complimentary provider visit on us. Details of your free visit will be sent to your email soon.',
                },
              });
            }}
          >
            {(applyCoupon, { loading }) => {
              return loading ? (
                <PleaseWait />
              ) : (
                <Flex flexDirection="column">
                  <Card
                    style={{
                      flexDirection: 'column',
                      textAlign: 'center',
                      padding: '32px',
                    }}
                  >
                    <Heading mb={4}>Have you tried a different doctor?</Heading>

                    <Flex
                      flexDirection="column"
                      style={{
                        margin: '0 auto',
                        display: 'table',
                      }}
                    >
                      <OfferWrapper >
                        <Content>
                          {/* <img src={plant} alt="" /> */}

                          <OfferTextWrapper>
                            <Text fontWeight="600">Thanks to our doctor fit guarantee, get a second consultation with a different doctor on us!</Text>
                            <br />
                            <Text fontWeight="300">Our #1 priority at Paloma Health is helping you feel better and we know how important it is to find the right doctor!</Text>
                          </OfferTextWrapper>
                        </Content>
                        <Flex mt={4} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                          <Button
                            variant="primary"
                            minWidth="150px"
                            
                            onClick={() => {
                              analytics.track('membership retained', {
                                coupon: 'Free visit',
                                reason: 'Not feeling better'
                              });
                              applyCoupon();
                            }}
                          >
                            Schedule another visit for free
                          </Button>

                          <A
                            onClick={() => {
                              confirm(wizard);
                            }}
                            style={{
                              marginTop: '24px',
                              padding: '6px',
                            }}
                          >
                            Cancel Membership
                          </A>
                        </Flex>
                        
                      </OfferWrapper>
                    </Flex>
                  </Card>
                  <A
                    onClick={() => {
                      history.push('/');
                    }}
                  >
                    Back to Dashboard
                  </A>
                </Flex>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default withRouter(withWizard(withAnalytics(NotBetter)));
