import React, { Fragment, useState } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import { BoxButton } from '../../../components/async/SharedComponents';
import FingerPrick from '../../../static/FingerPrick.png';


const options = [
  {
    label: 'Get a 30 day prescription',
    id: '30day',
    isFlowMarker: true,
  },
  {
    label: 'Buy a thyroid test kit',
    id: 'kits',
    isUpsellValue: true,
  },
];

const UpdateLevelsWithKitNewFormulation = ({ data, onSubmit, onLearnMore }) => {
  const [elements, setElements] = useState(options);

  // console.log({ elementsC: elements.filter(x => x.chosen === 'Y').length });
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }} pb="60px">
        <img
          src={FingerPrick}
          width="112px"
          style={{ margin: '12px auto' }}
          alt=""
        />
        <Box m={2} mt={4} mb={3}>
          <Heading textAlign="left">
            Update your levels with a thyroid test kit
          </Heading>
        </Box>
        <Box m={2} mt="0">
          <Text
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            Given that this formulation is new to you, we need to double check
            how your thyroid is doing on the current dosage before being able to
            prescribe.
          </Text>
          <Text
            mt={3}
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            Test your TSH, free T4, Free T3 and TPO antibodies from the comfort
            of your own home with an at home thyroid test kit and get your
            results in just a few days
          </Text>
          <Text
            mt={3}
            textAlign="left"
            fontSize="12px"
            fontWeight="300"
            lineHeight="24px"
            onClick={() => onLearnMore('athomekit')}
            style={{
              textTransform: 'uppercase',
              textDecoration: 'underline',
              textAlign: 'right',
            }}
          >
            learn more
          </Text>
        </Box>
      </Flex>
      <BoxButton className={'visible'}>
        <Button
          variant="primary"
          onClick={() => {
            onSubmit(null);
          }}
          width={[1, 1 / 2, 1 / 4]}
          style={{
            marginLeft: '12px',
            marginRight: '12px',
          }}
        >
          Continue
        </Button>
      </BoxButton>
    </Fragment>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(UpdateLevelsWithKitNewFormulation);
// export default withWizard(Single);
