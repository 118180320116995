import React, { useEffect, useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { compose, Query, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { withToastManager } from 'react-toast-notifications';
import { Box, Text, Flex } from 'rebass';
import styled from 'styled-components';
import LoadingScreen from '../../components/LoadingScreen';
import { GET_ANALYTICS_ID, GET_B2B_BROKERS, GET_B2B_COMPANIES, SIGNUP, SIGNUP_B2B, SIGNUP_B2B_EMPLOYEEID_NAMES, SIGNUP_B2B_MEMBERID_NAMES, SIGNUP_B2B_SUBSCRIBERID_NAMES, SIGNUP_B2B_UNIQUE_CODE } from '../../graphql';
import withAnalytics from '../../lib/withAnalytics';
import Basic from './Basic';
import Header from '../../components/Header';
import FullLayout from '../../components/FullLayout';
import Profile from './Profile';
import Otp from './Otp';
import Relationship from './Relationship';
import SelectCompany from './SelectCompany';
import B2bInformation from './b2bInformation';
import Precheck from './Precheck';
import Extended from './Extended';
import BirthSex from './BirthSex';
import LegalSex from './LegalSex';
import GenderIdentity from './GenderIdentity';
import PreferredPronouns from './PreferredPronouns';
import moment from 'moment';
import FullLayout2 from '../../components/FullLayout';
import PleaseWait from '../../components/PleaseWait';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;
const PartnerSignupFlow = ({ location, analytics, toastManager, client, history, companies, partner, selectedCompany, setSelectedCompany }) => {
  console.log('tada', { location, history, companies, partner, selectedCompany, setSelectedCompany });
  const [prevRoute, setPrevRoute] = useState((location || {}).pathname);
  const [values, setValues] = useState(JSON.parse(window.sessionStorage.getItem('tmppartner')));
  const [registering, setRegistering] = useState(false);

  useEffect(() => {
    if ((location || {}).pathname !== prevRoute) {
      let tag = '';

      if (location.pathname.indexOf('basic') > -1) {
        tag = 'basic';
      } else if (location.pathname.indexOf('profile') > -1) {
        tag = 'profile';
      } else if (location.pathname.indexOf('extended') > -1) {
        tag = 'extended';
      }
      if (tag) {
        analytics.page(tag, '/partner-signup');
      }
      setPrevRoute((location || {}).pathname);
    }
  });

  const handleComplete = async (vals) => {
    setRegistering(true);

    window.sessionStorage.setItem(
      'tmppartner',
      JSON.stringify({
        ...vals,
      })
    );
    const variables = {
      ...vals,
      companyId: selectedCompany.id,
    };

    try {
      let res;

      if (!vals.method || vals.method === 'default') {
        const { data } = await client.mutate({
          mutation: SIGNUP_B2B,
          variables: {
            ...variables,
          },
        });
        res = data.signupEligibility;
      }else if(vals.method.toLowerCase() === 'subscriberid;names') {
        const { data } = await client.mutate({
          mutation: SIGNUP_B2B_SUBSCRIBERID_NAMES,
          variables: {
            ...variables,
          },
        });
        res = data.signupEligibilitySubscriberIdNames;
      }else if(vals.method.toLowerCase() === 'memberid;names') {
        const { data } = await client.mutate({
          mutation: SIGNUP_B2B_MEMBERID_NAMES,
          variables: {
            ...variables,
          },
        });
        res = data.signupEligibilityMemberIdNames;
      }else if(vals.method.toLowerCase() === 'uniquecode') {
        const { data } = await client.mutate({
          mutation: SIGNUP_B2B_UNIQUE_CODE,
          variables: {
            ...variables,
          },
        });
        res = data.signupEligibilityUniqueCode;
      }else if(vals.method.toLowerCase() === 'employeeid;names') {
        const { data } = await client.mutate({
          mutation: SIGNUP_B2B_EMPLOYEEID_NAMES,
          variables: {
            ...variables,
          },
        });
        res = data.signupEligibilityEmployeeIdNames;
      }
      const attemptedRoute = '/';
      localStorage.setItem('token', res.token);
      const aa = await client.query({
        query: GET_ANALYTICS_ID,
        fetchPolicy: 'network-only',
      });

      let age = 0;
      try {
        age = moment().diff(moment(variables.dob, 'MM/DD/YYYY'), 'years');
      } catch (error) {}
      if (aa && aa.data && aa.data.getAnalyticsId) {
        analytics.Alias(aa.data.getAnalyticsId);
        analytics.Identify(aa.data.getAnalyticsId, {
          age,
          gender: variables.gender,
          state: variables.state,
          diagnosed: variables.diagnosed,
        });
      }

      analytics.track('Account Created', {
        age,
        gender: variables.gender,
        diagnosed: variables.diagnosed,
        state: variables.state,
      });
      window.sessionStorage.removeItem('tmppartner');
      window.sessionStorage.removeItem('tmppartnercomp');
      window.sessionStorage.removeItem('tmppartnerpart');
      history.push(attemptedRoute || '/');
    } catch (error) {
      setRegistering(false);
      if (!!error && !!error.graphQLErrors) {
        error.graphQLErrors.forEach((error) => {
          toastManager.add(error.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
      }
    }
  };

  const handleSubmit = (values, actions, wizard, force) => {
    console.log('handleSubmit', { values });
    setValues({
      ...values,
    });

    window.sessionStorage.setItem(
      'tmppartner',
      JSON.stringify({
        ...values,
      })
    );
    if (!!actions && !!actions.setSubmitting) {
      actions.setSubmitting(false);
    }
    if (!!force) {
      wizard.push(force);
    } else {
      wizard.next();
    }
  };

  let stepsVal = [
    {
      comp: (
        <Step id="basic">
          <Basic onSubmit={handleSubmit} values={values} />
        </Step>
      ),
      key: 'basic',
      display: true,
    },
    {
      comp: (
        <Step id="profile">
          <Profile onSubmit={handleSubmit} values={values} />
        </Step>
      ),
      key: 'profile',
      display: true,
    },
    {
      comp: (
        <Step id="otp">
          <Otp
            onSubmit={handleSubmit}
            values={values}
            changePhone={(phone, mutate) => {
              setValues({
                values,
                ...phone,
              });
            }}
          />
        </Step>
      ),
      key: 'otp',
      display: true,
    },
    {
      comp: (
        <Step id="relationship">
          <Relationship onSubmit={handleSubmit} values={values} />
        </Step>
      ),
      key: 'relationship',
      display: true,
    },
    {
      comp: (
        <Step id="employer">
          <SelectCompany
            values={values}
            companiesList={partner && partner.broker ? partner.broker.companies : companies}
            selectedCompany={selectedCompany}
            onSubmit={(selectedCompany, actions, wizard) => {
              console.log({ selectedCompany });
              setSelectedCompany(selectedCompany);
              actions.setSubmitting(false);
              wizard.next();
            }}
            pushNext={(wizard) => {
              wizard.next();
            }}
            relationship={(values || {}).relationship}
          />
        </Step>
      ),
      key: 'employer',
      display: true,
    },
    {
      comp: (
        <Step id="additional">
          <B2bInformation onSubmit={handleSubmit} values={values} selectedCompany={selectedCompany} />
        </Step>
      ),
      key: 'additional',
      display: true,
    },
    {
      comp: (
        <Step id="precheck">
          <Precheck
            values={values}
            companyId={(selectedCompany || {}).id}
            successContinue={(wizard) => {
              wizard.next();
            }}
            failContinue={(wizard) => {
              wizard.next();
            }}
            failEditInformation={(wizard) => {
              wizard.push('basic');
            }}
            relationship={(values || {}).relationship}
          />
        </Step>
      ),
      key: 'precheck',
      display: true,
    },
    {
      comp: (
        <Step id="birthsex">
          <BirthSex onSubmit={handleSubmit} values={values} />
        </Step>
      ),
      key: 'basic',
      display: true,
    },
    {
      comp: (
        <Step id="legalsex">
          <LegalSex onSubmit={handleSubmit} values={values} />
        </Step>
      ),
      key: 'basic',
      display: true,
    },
    {
      comp: (
        <Step id="genderIdentity">
          <GenderIdentity onSubmit={handleSubmit} values={values} />
        </Step>
      ),
      key: 'basic',
      display: true,
    },
    {
      comp: (
        <Step id="pronouns">
          <PreferredPronouns onSubmit={handleSubmit} values={values} />
        </Step>
      ),
      key: 'basic',
      display: true,
    },
    {
      comp: (
        <Step id="finalize">
          <Extended onSubmit={handleComplete} values={values} />
        </Step>
      ),
      key: 'additional',
      display: true,
    },
  ];
  // if (!!selectedCompany) {
  //   stepsVal = stepsVal.filter(x=>x.key!=='employer');
  // }

  if (!!registering) {
    return (
      <FullLayout2 style={{ zIndex: 10 }}>
        <Flex flex={1} justifyContent="center" alignItems={'center'}>
          <Container>
            <PleaseWait
              text=" "
              spinner={{
                sColor: '#ECEFF2',
                pColor: '#909FB5',
              }}
            />
          </Container>
        </Flex>
      </FullLayout2>
    );
  }
  const encoded = encodeURIComponent(partner ? (partner.company ? partner.company.name : partner.broker ? partner.broker.name : '') : '');
  return (
    <Wizard history={history} basename={`/partner-signup/${encoded}`}>
      <Steps>{stepsVal.map((x) => x.comp)}</Steps>
    </Wizard>
  );
};

const PartnerSignupFlowWithProps = compose(
  withApollo,
  withRouter,
  withToastManager,
  withAnalytics
)(PartnerSignupFlow);

const PartnerSignup = ({ location, analytics, toastManager, client, history, match: { params } }) => {
  const [selectedPartner, setSelectedPartner] = useState(JSON.parse(window.sessionStorage.getItem('tmppartnerpart')));
  const [selectedCompany, setSelectedCompany] = useState(JSON.parse(window.sessionStorage.getItem('tmppartnercomp')));
  const onSelectCompany = (company) => {
    setSelectedCompany(company);
    window.sessionStorage.setItem('tmppartnercomp', JSON.stringify(company));
  };
  return (
    <Query
      query={GET_B2B_BROKERS}
      onCompleted={(data) => {
        if (!!data && data.getBrokersList && data.getBrokersList.length > 0) {
          if (!!window.sessionStorage.getItem('tmppartnerpart')) return;
          for (let index = 0; index < data.getBrokersList.length; index++) {
            const element = data.getBrokersList[index];
            if (element.name.toLowerCase() === decodeURIComponent((params.partner || '').toLowerCase())) {
              setSelectedPartner({
                broker: element,
                found: true,
              });
              window.sessionStorage.setItem(
                'tmppartnerpart',
                JSON.stringify({
                  broker: element,
                  found: true,
                })
              );
            }
          }
        }
      }}
    >
      {({ loading }) => {
        if (loading) {
          return <LoadingScreen loading={true} />;
        }
        return (
          <Query
            query={GET_B2B_COMPANIES}
            onCompleted={(data) => {
              if (!!data && data.getB2BCompanies && data.getB2BCompanies.length > 0) {
                if (!!window.sessionStorage.getItem('tmppartner')) return;
                for (let index = 0; index < data.getB2BCompanies.length; index++) {
                  const element = data.getB2BCompanies[index];
                  if (element.name.toLowerCase() === decodeURIComponent((params.partner || '').toLowerCase())) {
                    setSelectedPartner({
                      company: element,
                      found: true,
                    });
                    setSelectedCompany(element);
                    window.sessionStorage.setItem(
                      'tmppartnercomp',
                      JSON.stringify({
                        ...element,
                      })
                    );
                    window.sessionStorage.setItem(
                      'tmppartnerpart',
                      JSON.stringify({
                        company: element,
                        found: true,
                      })
                    );
                  }
                }
              }
            }}
          >
            {({ data, loading, error }) => {
              if (loading || selectedPartner === undefined) {
                return <LoadingScreen loading={true} />;
              }
              console.log({
                data,
                selectedPartner,
              });
              return (
                <>
                  <Container flex={1} px={3}>
                    <Flex flex={1}>
                      <Header dark={false}>
                        {/* <Text color="#486288" fontSize={3} fontWeight={500} textAlign="center">
                          Hi there! Let's get to know each other.
                        </Text> */}
                      </Header>
                    </Flex>
                    <PartnerSignupFlowWithProps companies={data.getB2BCompanies} partner={selectedPartner} selectedCompany={selectedCompany} setSelectedCompany={onSelectCompany} />
                  </Container>
                  <FullLayout />
                </>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

const PartnerSignupAny = ({ location, analytics, toastManager, client, history, match: { params } }) => {
  const [selectedPartner, setSelectedPartner] = useState(JSON.parse(window.sessionStorage.getItem('tmppartnerpart')));
  const [selectedCompany, setSelectedCompany] = useState(JSON.parse(window.sessionStorage.getItem('tmppartnercomp')));
  const onSelectCompany = (company) => {
    setSelectedCompany(company);
    window.sessionStorage.setItem('tmppartnercomp', JSON.stringify(company));
  };
  return (
    <Query
      query={GET_B2B_COMPANIES}
      onCompleted={(data) => {
        if (!!data && data.getB2BCompanies && data.getB2BCompanies.length > 0) {
          if (!!window.sessionStorage.getItem('tmppartner')) return;
          for (let index = 0; index < data.getB2BCompanies.length; index++) {
            const element = data.getB2BCompanies[index];
            if (element.name.toLowerCase() === decodeURIComponent((params.partner || '').toLowerCase())) {
              setSelectedPartner({
                company: element,
                found: true,
              });
              setSelectedCompany(element);
              window.sessionStorage.setItem(
                'tmppartnercomp',
                JSON.stringify({
                  ...element,
                })
              );
              window.sessionStorage.setItem(
                'tmppartnerpart',
                JSON.stringify({
                  company: element,
                  found: true,
                })
              );
            }
          }
        }
      }}
    >
      {({ data, loading, error }) => {
        if (loading || selectedPartner === undefined) {
          return <LoadingScreen loading={true} />;
        }
        console.log({
          data,
          selectedPartner,
        });
        return (
          <>
            <Container flex={1} px={3}>
              <Flex flex={1}>
                <Header dark={false}>
                  {/* <Text color="#486288" fontSize={3} fontWeight={500} textAlign="center">
                          Hi there! Let's get to know each other.
                        </Text> */}
                </Header>
              </Flex>
              <PartnerSignupFlowWithProps companies={data.getB2BCompanies} partner={selectedPartner} selectedCompany={selectedCompany} setSelectedCompany={onSelectCompany} />
            </Container>
            <FullLayout />
          </>
        );
      }}
    </Query>
  );
};

export const PartnerSignupNoBroker = compose(
  withApollo,
  withRouter,
  withToastManager,
  withAnalytics
)(PartnerSignupAny);

export default compose(
  withApollo,
  withRouter,
  withToastManager,
  withAnalytics
)(PartnerSignup);
