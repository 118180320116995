import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Flex, Text } from 'rebass';
import Modal from 'styled-react-modal';

const StyledModal = Modal.styled`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #fff;
  margin: 20px;
  padding: 20px;
  background: transparent;
  z-index:3000;
`;

const ConfirmModal = ({
  children,
  onConfirm,
  onClose,
  confirmLabel,
  cancelLabel,
  confirmProps,
  cancelProps,
  okOnly,
  disabled = false,
  confirmStyle,
  cancelStyle,
  cancelVariant,
  buttonsDirection = 'row',
  cardStyle,
  displayX = false,
  buttonsFlexStyle = {},
  childrenManaged = false,
  ...props
}) => {
  return (
    <StyledModal {...props} style={{
      overlay: {
      zIndex: 3100,
    }}}>
      <Card style={{ position: 'relative', ...cardStyle }} p={4}>
        {children || 'Do you want to proceed?'}
        {!childrenManaged && (
          <Flex mt={3} flexDirection={buttonsDirection} justifyContent="center" style={{ ...buttonsFlexStyle }}>
            <Button variant="primary" mr={3} id="btn-confirmModal-confirm" disabled={disabled} onClick={onConfirm} {...confirmProps} style={{ ...confirmStyle }}>
              {confirmLabel}
            </Button>
            {!okOnly && (
              <Button variant={cancelVariant || 'primary'} onClick={onClose} disabled={disabled} transparent {...cancelProps} style={{ ...cancelStyle }}>
                {cancelLabel}
              </Button>
            )}
          </Flex>
        )}
        {displayX && (
          <Text
            style={{
              position: 'absolute',
              top: '12px',
              right: '20px',
              fontSize: '20px',
              opacity: 0.65,
              cursor: 'pointer',
            }}
            onClick={props.onBackgroundClick}
          >
            X
          </Text>
        )}
      </Card>
    </StyledModal>
  );
};

ConfirmModal.propTypes = {
  children: PropTypes.node,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onBackgroundClick: PropTypes.func,
  onEscapeKeydown: PropTypes.func,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmProps: PropTypes.object,
  cancelProps: PropTypes.object,
};

ConfirmModal.defaultProps = {
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  confirmProps: {},
  cancelProps: {},
  onBackgroundClick: () => {},
};

export default ConfirmModal;
