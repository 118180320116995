import React from 'react';
import { Text, Flex } from 'rebass';
import styled from 'styled-components';
import Imge from '../../../static/thyroid2.png';
import { useMount } from '../../../utils/hooks/lifecycle';
import { sleep } from '../../../utils/sleep';

const PinkHeading = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
  margin-top: 36px;
  color: #f6a293;
`;

const BlueHeading = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #354e79;
`;

const NotAlone = ({ onSubmit }) => {
  useMount(async () => {
    await sleep(2000);
    onSubmit();
  });

  return (
    <Flex flex={1} flexDirection="column">
      <Flex flex={1} flexDirection="column" p="16px">
        <img src={Imge} alt="" style={{ width: '50%', margin: '0 auto', maxWidth: '200px' }} />

        <PinkHeading mb={3} mt={4} textAlign="center">
          You are not alone!
        </PinkHeading>
        <BlueHeading mb={3} textAlign="center">
          Hypothyroidism affects more than 25M people in the U.S.
        </BlueHeading>
      </Flex>
    </Flex>
  );
};

export default NotAlone;
