import React from 'react';
import { Query } from 'react-apollo';
import { isToggleActive } from '../components/featureToggle/toggles';

import { CURRENT_USER } from '../graphql';

const withIsAdmin = (Component) => (props) => (
  <Query query={CURRENT_USER}>
    {({ data, loading, refetch, error }) => {
      if (loading || error) {
        return null;
      }
      const t = isToggleActive('PERSONAL_ADMIN');

      // console.log('DATATT', data);

      return (
        <Component
          {...props}
          isAdmin={data.currentUser && 
            (!t ? data.currentUser.email === window._env_.REACT_APP_PALOMA_ADMIN_EMAIL : 
              ((data.currentUser.email || '').indexOf('+admin@palomahealth.com')>-1 || data.currentUser.email === window._env_.REACT_APP_PALOMA_ADMIN_EMAIL) && !!data.currentUser.adm)}
        />
      );
    }}
  </Query>
);

export default withIsAdmin;
