import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading } from 'rebass';

import { Formik, Form } from 'formik';
import { withWizard } from 'react-albus';
import * as Yup from 'yup';

import { TextInput } from '../../components/TextInput';
import { FormField } from '../../components/FormField';
import RegionSelect from '../../components/RegionSelect';
import PlacesAutocomplete from '../../components/addressAutoComplete/PlacesAutocomplete';

const eligibility = value => {
  const ineligible = ['NJ', 'NY', 'RI'];
  return !ineligible.includes(value);
};

const schema = Yup.object().shape({
  street1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string()
    .required('Required')
    .test(
      'state',
      'Kits are currently not available in this state',
      eligibility
    ),
  zip: Yup.string().required('Required'),
});

class Address extends React.Component {
  constructor(props) {
    super(props);
    const hasAddress =
      this.props.values &&
      this.props.values.street1 &&
      this.props.values.street1.length > 0;
    this.state = {
      isFormExtended: hasAddress,
    };
  }
  render() {
    const { wizard, onSubmit } = this.props;
    const { isFormExtended } = this.state;
    const propsValues = this.props.values;

    if (!propsValues.firstName || propsValues.firstName.length < 1) {
      wizard.push('profile');
    }
    return (
      <Fragment>
        <Flex flex={1} style={{ alignSelf: 'end' }}>
          <Card p={4}>
            <Flex flexDirection="column">
              <Heading textAlign="center" fontWeight={550} mb={4}>
                Your Shipping Address
              </Heading>

              <Formik
                isInitialValid={({ initialValues }) =>
                  schema.isValidSync(initialValues)
                }
                initialValues={propsValues}
                validationSchema={schema}
                onSubmit={values => onSubmit('address', values, wizard)}
                render={({
                  isValid,
                  errors,
                  touched,
                  values,
                  handleChange,
                  handleBlur,
                  setFieldTouched,
                  setFieldValue,
                }) => {
                  let errStreet1
                  if(touched.street1 && errors.street1){
                    errStreet1=errors.street1
                  }else if(values && values.street1 && values.street1.indexOf('@')>-1){
                    errStreet1='incorrect street address'
                  }else{
                    errStreet1=null
                  }
                  return (
                    <Form>
                      {/* {!values.street1 &&
                        !values.zip &&
                        !values.city &&
                        !values.street2 && ( */}
                        <div style={{
                          maxHeight: values.street1 || values.city || values.zip?'0px':'',
                          opacity: values.street1 || values.city || values.zip?'0':'1'
                        }}>
                          <FormField mb={3}>
                            <PlacesAutocomplete
                              fieldname="address"
                              onTextChanged={val => {
                                if (val && val.length > 0) {
                                  if (!isFormExtended) {
                                    this.setState({ isFormExtended: true });
                                  }
                                } else {
                                  if (
                                    !values.street1 &&
                                    !values.street2 &&
                                    !values.city &&
                                    !values.zip
                                  ) {
                                    this.setState({ isFormExtended: false });
                                  }
                                }
                              }}
                              onSelected={val => {
                                setFieldValue('street1', val.street);
                                setFieldValue('city', val.city);
                                setFieldValue('zip', val.zip);
                                setFieldValue('state', val.state);
                                setFieldTouched('street1');
                                setFieldTouched('city');
                                setFieldTouched('zip');
                                setFieldTouched('state');
                              }}
                              onBlur={val => {
                                // console.log('OnBlur')
                                if (!isFormExtended) {
                                  this.setState({ isFormExtended: true, forceHideAutocomplete:true });
                                }
                                  if (
                                    !values.street1 &&
                                    !values.street2 &&
                                    !values.city &&
                                    !values.zip
                                  ) {
                                    setFieldValue('street1', val);
                                    setFieldTouched('street1', true)
                                  }
                              }}
                              street1FieldName="street1"
                              cityFieldName="city"
                              zipFieldName="zip"
                              stateFieldName="state"
                            />
                          </FormField>
                          </div>
                        {/* )} */}
                      {isFormExtended && (
                        <>
                          <FormField
                            mb={3}
                            error={errStreet1}
                          >
                            <TextInput
                              placeholder="Address"
                              type="text"
                              name="street1"
                            />
                          </FormField>

                          <FormField
                            mb={3}
                            error={touched.street2 && errors.street2}
                          >
                            <TextInput
                              placeholder="Apartment, Suite, Floor (Optional)"
                              type="text"
                              name="street2"
                            />
                          </FormField>

                          <Flex flexDirection={['column', 'column', 'row']}>
                            <FormField
                              width={[1, 1, 1 / 3]}
                              mr={[0, 0, 3]}
                              mb={3}
                              error={touched.city && errors.city}
                            >
                              <TextInput
                                placeholder="City"
                                type="text"
                                name="city"
                              />
                            </FormField>

                            <FormField
                              width={[1, 1, 1 / 3]}
                              mr={[0, 0, 3]}
                              mb={3}
                              error={touched.state && errors.state}
                            >
                              <RegionSelect name="state" value={values.state} />
                            </FormField>

                            <FormField
                              width={[1, 1, 1 / 3]}
                              mb={3}
                              error={touched.zip && errors.zip}
                            >
                              <TextInput
                                placeholder="Zip"
                                type="text"
                                name="zip"
                              />
                            </FormField>
                          </Flex>
                        </>
                      )}

                      <Flex justifyContent="center">
                        <Button
                          disabled={!isValid || !values || !values.street1 || values.street1.indexOf('@')>-1}
                          variant="pink"
                          width={[1, 1 / 2, 1 / 3]}
                          type="submit"
                        >
                          Confirm
                        </Button>
                      </Flex>
                    </Form>
                  );
                }}
              />
            </Flex>
          </Card>
        </Flex>
      </Fragment>
    );
  }
}

export default withWizard(Address);
