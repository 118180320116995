import React, { Component, Fragment } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';

import { TextInput } from '../../../components/TextInput';
import { TextArea } from '../../../components/TextArea';
import { FormField } from '../../../components/FormField';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import { sleep } from '../../../utils/sleep';

const Btn = styled(Button)`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 300;

  transition: background 300ms ease-in;
  &.selected {
    background: #e7f0ff;
    border: 1px solid #354e79;
    box-sizing: border-box;
  }
`;
const SelectedMarkerOut = styled(Flex)`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 2px solid #354e79;
  border-radius: 12px;
  margin-right: 12px;
`;
const SelectedMarkerIn = styled(Flex)`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #354e79;
  transition: all 450ms ease-in;
`;
class Single extends Component {
  constructor(props) {
    super(props);
    const { getState = () => {} } = props;
    if (props.data && props.data.skipIfNotQuestionId) {
      let isFound = false;
      for (let index = 0; index < props.userData.length; index++) {
        const element = props.userData[index];
        for (let index = 0; index < element.values.length; index++) {
          const val = element.values[index];
          if (
            val.questionId &&
            val.questionId === props.data.skipIfNotQuestionId
          ) {
            isFound = true;
            break;
          }
        }
      }
      if (!isFound) {
        props.wizard.next();
      }
    }

    let defaults;
    let sel;
    let isDisplay;

    const state = getState();
    if (state && state.values) {
      const stateValues = state.values.find(x => x.id === props.data.id);
      defaults = this.unFormatSubmit((stateValues || {}).values, props.data.id);
      if (stateValues && stateValues.values) {
        const element = stateValues.values;
        sel = props.data.options.find(x => x.value === element.answer);
      }
    } else {
      defaults = (props.data || {}).defaults;
    }

    this.state = {
      loading: false,
      isInitialized: true,
      defaults,
      display: isDisplay,
      selected: sel || {},
    };
  }
  // componentDidMount=()=>{
  //   window.scrollTo(0, 0)
  // }

  unFormatSubmit = (values, qId) => {
    const { data } = this.props;
    let payload = {};
    // for (let index = 0; index < values.length; index++) {
    if (!values) return payload;
    const element = values;
    try {
      const questionId = values.questionId;
      let answer;
      let val;
      // console.log({ k, values });
      answer = element.answer;
      let sel = data.options.find(x => x.value === element.answer);
      if (sel.prefix) {
        val = element.value.replace(`${sel.prefix} `, '');
      }else if (sel.suffix) {
        val = element.value.replace(`${sel.suffix}`, '');
      } else {
        val = element.value;
      }
      if (questionId) {
        payload[`${questionId.toString()}`] = answer;
      }
      payload[element.id] = val;
      payload[`${qId.toString()}`] = val;
    } catch (error) {
      console.log('err un', error);
    }

    // console.log({ payload });
    return payload;
  };

  handleClick = async element => {
    const { data, onSubmit, wizard } = this.props;
    this.setState({ selected: element });

    let val;
    let sel = data.options.find(x => x.value === element.value);
    if (sel.prefix) {
      val = `${sel.prefix} ${element.value}`;
    }else if (sel.suffix) {
      val = `${element.value}${sel.suffix}`;
    } else {
      val = element.value;
    }
    await sleep(550);
    if (element.isDisqualifying) {
      const dta = JSON.parse(JSON.stringify(this.props.userData));
      // console.log({ k, values });
      // answer = element.answer;
      if (element.questionId) {
        const payload = {
          questionId: element.questionId,
          answer: element.answer || element.value,
          value: val,
          goTo: element.goTo,
          goToPage: element.goToPage,
          hpiAnswer: element.hpiAnswer,
        };
        dta.push(payload);
      }
      // console.log({ dta });
    } else {
      if (element.placeholder || element.giveDetails) {
        // this.setState({ display: true });
      } else {
        const payload = {
          questionId: element.questionId,
          answer: element.answer || element.value,
          value: val,
          goTo: element.goTo,
          goToPage: element.goToPage,
          hpiAnswer: element.hpiAnswer,
        };

        onSubmit(payload, data.id, wizard, element);
      }
    }
  };

  shiftView = () => {
    setTimeout(() => {
      window.scrollTo(0, this.inputRef.offsetTop);
    }, 150);
  };

  render() {
    const { data, onSubmit, wizard } = this.props;
    const { selected, display, loading, defaults, isInitialized } = this.state;
    if (!isInitialized) {
      return <div />;
    }

    return (
      <Fragment>
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          {data.title ? (
            <Box m={2} mt="0" mb={3}>
              <Heading textAlign="left">{data.title}</Heading>
            </Box>
          ) : null}
          {data.subTitle ? (
            <Box m={2} mt="0" mb={3}>
              <Text
                textAlign="left"
                fontSize="16px"
                fontWeight="300"
                lineHeight="24px"
              >
                {data.subTitle}
              </Text>
            </Box>
          ) : null}
          <Box m={2} mt="0">
            <Heading textAlign="left" fontSize="20px">
              {data.prompt}
            </Heading>
          </Box>

          <Formik
            initialValues={defaults}
            validationSchema={data.schema || ''}
            onSubmit={(values, actions) => {
              let value;
              // console.log({ valSubmit: values });

              if (selected.prefix) {
                value = `${selected.prefix} ${values[data.id]}`;
              }else if(selected.suffix){
                value = `${values[data.id]}${selected.suffix}`
              } else {
                value = values[data.id];
              }

              const payload = {
                questionId: selected.questionId,
                answer: selected.value,
                value,
                goTo: selected.goTo,
                goToPage: selected.goToPage,
                hpiAnswer: selected.hpiAnswer,
              };
              // console.log('AAA', { payload });
              onSubmit(payload, actions, wizard, selected);
            }}
            render={({
              values,
              isValid,
              errors,
              touched,
              submitForm,
              setFieldValue,
            }) => {
              // console.log({selected, values})
              return (
                <Form>
                  <Flex flex={1} flexDirection="column">
                    <Flex my={3} flexWrap="wrap" justifyContent="center">
                      {data.options &&
                        data.options.map(el => (
                          <Fragment key={el.value}>
                            <Btn
                              disabled={loading}
                              type="button"
                              variant={
                                'white'
                                // selected.value === el.value
                                //   ? 'selected'
                                //   : 'white'
                              }
                              width={[1]}
                              m={3}
                              onClick={() => {
                                if (el.value !== selected.value) {
                                  values[data.id] = '';
                                }
                                this.handleClick(el);
                              }}
                              className={
                                selected.value === el.value
                                  ? 'selected'
                                  : 'white'
                              }
                            >
                              <Flex
                                flex={1}
                                flexDirection="row"
                                // alignItems="center"
                              >
                                <SelectedMarkerOut>
                                  {selected.value === el.value && (
                                    <SelectedMarkerIn />
                                  )}
                                </SelectedMarkerOut>
                                <Flex
                                  flex={1}
                                  flexDirection="column"
                                  style={{ textAlign: 'left' }}
                                >
                                  <Text textAlign="left" mt="4px">{el.value}</Text>

                                  {el.giveDetails &&
                                  selected.value === el.value ? (
                                    el.isExtended ? (
                                      // <TextAreaNoForm
                                      //   value={values[data.id]}
                                      //   onChange={v => {
                                      //     setFieldValue(data.id, v.target.value);
                                      //   }}
                                      // />

                                      <TextArea
                                        containerStyle={{ marginTop: '16px' }}
                                        flex={1}
                                        name={data.id}
                                        placeholder={el.placeholder || ''}
                                      />
                                    ) : (
                                      <TextInput
                                        name={data.id}
                                        style={{ marginTop: '24px' }}
                                        flex={1}
                                        placeholder={el.placeholder || ''}
                                        type={el.inputType || 'text'}
                                        min={el.min || null}
                                        max={el.max || null}
                                        step={el.step || null}
                                      />
                                    )
                                  ) : null}
                                </Flex>
                              </Flex>
                            </Btn>
                          </Fragment>
                        ))}
                    </Flex>

                    <Flex
                      flex={1}
                      my={3}
                      justifyContent="center"
                      ref={ref => (this.inputRef = ref)}
                      onClick={this.shiftView}
                    >
                      <FormField
                        width={[1, 1 / 2]}
                        px={3}
                        error={touched[data.id] && errors[data.id]}
                        style={{ display: display ? '' : 'none' }}
                      >
                        {!data.extend && (
                          <TextInput
                            name={data.id}
                            placeholder={selected.placeholder}
                          />
                        )}

                        {data.extend && (
                          <TextArea
                            name={data.id}
                            placeholder={selected.placeholder}
                          />
                        )}
                      </FormField>
                    </Flex>

                    <Flex
                      justifyContent="center"
                      style={{ display: selected.giveDetails ? '' : 'none' }}
                    >
                      <Button
                        disabled={(errors && errors.length) || loading || (selected.giveDetails && selected.giveDetailsEnforce && !values[data.id])}
                        variant="primary"
                        width={[1 / 2, 1 / 3]}
                        type="submit"
                      >
                        {loading ? 'Processing' : 'Next'}
                      </Button>
                    </Flex>
                  </Flex>
                </Form>
              );
            }}
          />
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(Single);
// export default withWizard(Single);
