import { AsyncOnly } from './questions/AsyncOnly';
import { LiveAsync } from './questions/LiveAsync';
import { LiveOnly } from './questions/LiveOnly';

export const BookFlowQuestions = (session) => {
  const {
    b2b,
    eligibility: { consultation, canBookAsync },
  } = session;
  if (!!b2b && !b2b.mustVerify && b2b.isActive) {
    return LiveOnly;
  }

  if (canBookAsync && consultation) {
    return LiveAsync;
  }
  if (canBookAsync) {
    return AsyncOnly;
  }

  return LiveOnly;
};
