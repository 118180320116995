import React from 'react';
import { Card, Flex, Heading, Image, Link, Text } from 'rebass';
import { compose, withApollo, Query } from 'react-apollo';
import styled from 'styled-components';

import Mail from '../components/icons/Mail';
import ButtonLink from '../components/ButtonLink';
import withSession from '../lib/withSession';
import { GET_ASSIGNED_DOCTOR } from '../graphql';
import PractitionerTag from '../components/PractitionerTag';

const PractitionerImage = styled(Image)`
  width: 150px;
  height: 150px;
  max-width: inherit;
  border-radius: 50%;
`;

const Container = styled(Flex)`
  max-width: 1050px;
`;

const Practitioner = props => (
  <Flex p={[3, 5]} flexDirection="column">
    <Container flexDirection="column">
      <Heading mb={4} fontSize={[4, 5]}>
        {props.session && props.session.primaryProviderId
          ? 'Your Clinician'
          : 'Our Clinician'}
      </Heading>
      <Query
        query={GET_ASSIGNED_DOCTOR}
        variables={{
          providerId:
            props.session && props.session.primaryProviderId
              ? props.session.primaryProviderId || ''
              : '',
        }}
      >
        {({ data, loading, refetch, error }) => {
          // console.log('SESSION', { a: props.session });
          if (loading) return 'loading';
          const doctors = [];
          for (let index = 0; index < data.getAssignedDoctor.length; index++) {
            const element = data.getAssignedDoctor[index];
            if (doctors.find(x => x.email === element.email)) continue;
            doctors.push(element);
          }
          return doctors.map(x => {
            return (
              <Card p={4} mb={4}>
                <Flex flexDirection={['column', 'row']} mb={4}>
                  <Flex
                    flexDirection="column"
                    mr={[0, 4]}
                    alignItems={['center', 'flex-start']}
                  >
                    <PractitionerImage src={x.picture} mb={4} />
                  </Flex>
                  <Flex flexDirection="column">
                    <Flex alignItems="center" mb={4}>
                      <Flex mr={3}>
                        <Text
                          fontWeight={600}
                          fontSize={3}
                          textAlign={['center', 'left']}
                        >
                          {x.patientFacingName}
                        </Text>
                      </Flex>
                      <Flex>
                        <Link href={`mailto:contact@palomahealth.com`}>
                          <Mail height={24} />
                        </Link>
                      </Flex>
                    </Flex>

                    {x.tags ? (
                      <Flex
                        flexDirection="row"
                        flexWrap="wrap"
                        style={{ marginBottom: '18px' }}
                      >
                        {x.tags.map(tag => {
                          return <PractitionerTag tag={tag} />;
                        })}
                      </Flex>
                    ) : null}
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: `${x.bio}`,
                      }}
                    >
                      {/* {x.bio} */}
                    </Text>

                    {x.question1 ? (
                      <>
                        <Text
                          color="#344F79"
                          fontSize={2}
                          mt={4}
                          fontWeight="600"
                        >
                          Why I am passionate about treating patients with
                          hypothyroidism:
                        </Text>
                        <Text
                          color="#344F79"
                          fontSize={2}
                          mb={4}
                          dangerouslySetInnerHTML={{
                            __html: `${x.question1}`,
                          }}
                        ></Text>
                      </>
                    ) : null}
                    {x.question2 ? (
                      <>
                        <Text color="#344F79" fontSize={2} fontWeight="600">
                          My approach to treating hypothyroidism is:
                        </Text>
                        <Text
                          color="#344F79"
                          fontSize={2}
                          mb={2}
                          dangerouslySetInnerHTML={{
                            __html: `${x.question2}`,
                          }}
                        ></Text>
                      </>
                    ) : null}
                  </Flex>
                </Flex>
                <Flex justifyContent="center">
                  <ButtonLink
                    bg="#f9a394"
                    href={`mailto:contact@palomahealth.com`}
                  >
                    Contact Your Provider
                  </ButtonLink>
                </Flex>
              </Card>
            );
          });
        }}
      </Query>
    </Container>
  </Flex>
);

export default compose(withApollo)(withSession(Practitioner));
