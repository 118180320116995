import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import BackgroundShape from './BackgroundShape';
import { Absolute } from './Position';

const Inner = styled(Flex)`
  position: relative;
  overflow: hidden;

  /* @media screen and (min-width: 52em) {
  margin-top: 32px;
} */
`;

const MobileView = styled(Flex)`
  flex: 1;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const InnerChildren = styled(Flex)`
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 52em) {
    margin-top: 32px;
  }
`;

const A = styled.a`
  display: flex;
  text-decoration: none;

  &:visited {
    color: #344f79;
  }
`;

const SidebarStyled = styled(Flex)`
  width: 250px;
  height: 100%;
  min-height: 100vh;
  background-color: #FDF5F2;
  box-shadow: ${props =>
    props.open ? 'inset 1px 1px 20px 0 rgba(0, 0, 0, 0.1)' : ''};
  position: fixed;
  z-index: 2;
  flex: 'none';
  transform: ${props => (props.open ? 'translateX(0)' : 'translateX(-100%)')};
  padding-top: 80px;
  transition: 0.3s;

  @media screen and (min-width: 52em) {
    transform: ${props => (props.forceClose ? 'none' : 'translateX(-100%)')};
    box-shadow: ${props =>
      props.forceClose ? 'inset 1px 1px 20px 0 rgba(0, 0, 0, 0.1)' : ''};
    transition: none;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    background: #FDF5F2;
  }
`;

const Sidebar = props => (
  <SidebarStyled flexDirection="column" {...props}>
    <Inner flex={1} flexDirection="column">
      <Absolute top="0px" right="-200px">
        <BackgroundShape height="248px" fill='#F8A294' style={{opacity: 0.2}}/>
      </Absolute>
      <Absolute top="calc(80% - 248px)" left="-140px">
        <BackgroundShape height="248px" fill='rgba(231, 240, 255, 0.5)' />
      </Absolute>
      <Absolute top="calc(100% - 40px)" right="-140px">
        <BackgroundShape height="248px"  fill='#354E79' />
      </Absolute>
      <InnerChildren flexDirection="column" zIndex={2}>
        {props.children}
      </InnerChildren>

      <MobileView>
        <A href="tel:434-248-7508">
          <div style={{ width: '30px', marginRight: '12px', transform: 'scaleX(-1)' }}>
            <FontAwesomeIcon size="2x" color="#344f79" icon={faPhone} />
          </div>
          <Text fontSize="22px">434-248-7508</Text>
        </A>
      </MobileView>
    </Inner>
  </SidebarStyled>
);

Sidebar.propTypes = {
  open: PropTypes.bool,
};

Sidebar.defaultProps = {
  open: false,
};

export default Sidebar;
