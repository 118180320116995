import React, { useState } from 'react';
import { Box, Flex, Text } from 'rebass';
import styled, { keyframes } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { sleep } from '../../../utils/sleep';
import { LearnMores } from '../LearnMores';

const mainBgAnimate = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
        /* background-color: #E7F0FF; */
    }
`;
const mainBgAnimateDesk = keyframes`
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;
const mainBgAnimateClose = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;
const mainBgAnimateCloseDesk = keyframes`
from {
        opacity: 1;
    transform: translate3d(0, 0, 0);
}
to {
        opacity: 0;
    transform: translate3d(100%, 0, 0);
}
`;
const ContentSlideAnimate = keyframes`
    from {
        transform: translate3d(0, 100%, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
`;
const ContentSlideAnimateClose = keyframes`
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, 100%, 0);
    }
`;

const ParagrapheSlideAnimate = keyframes`
    from {
        opacity: 0.1;
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
`;

const XAnimate = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
const Backdrop = styled(Box)`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.35);
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  animation: ${mainBgAnimate} 150ms ease-in forwards;
  &.closing {
    animation: ${mainBgAnimateClose} 300ms ease-out forwards;
  }
`;
const Wrapper = styled(Box)`
  background-color: ${props => props.bg || '#e7f0ff'};
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${props => (isMobile ? 0 : '')};
  width: ${props => (isMobile ? '' : '25%')};
  min-width: ${props => (isMobile ? '' : '400px')};
  right: 0;
  z-index: 3;
  animation: ${props => (isMobile ? mainBgAnimate : mainBgAnimateDesk)} 300ms
    ease-in forwards;
  &.closing {
    animation-delay: 150ms;
    animation: ${mainBgAnimateClose} 300ms ease-in forwards;
  }
`;

const ContentWrap = styled(Box)`
  background-color: white;
  animation-delay: 280ms;
  animation: ${ContentSlideAnimate} 300ms ease-in forwards;
  flex: 1;
  padding: 12px;
  padding-bottom: 60px;
  &.closing {
    animation-delay: 0ms;
    animation: ${ContentSlideAnimateClose} 150ms ease-in forwards;
  }
`;

const ImageContainer = styled(Flex)`
  height: ${isMobile ? '50%' : 'calc(100vh / 3)'};
  background-color: transparent;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: ${isMobile ? '80%' : ' calc(100vw / 3)'};

  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
`;

const OrangeQuestion = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 12px;
  color: #f6a293;
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-transform: capitalize;
  color: #354e79;
  margin-bottom: 16px;
`;

const Description = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #354e79;
  margin-bottom: 16px;
`;

const ParagrapheTitle = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #354e79;
  margin-bottom: 16px;
`;

const Paragraphe = styled(Box)`
  transform: translate3d(100%, 0, 0);
  opacity: 0.1;
  animation: ${ParagrapheSlideAnimate} 400ms ease-in forwards;
  animation-delay: ${props => props.delay};
`;
const X = styled(Box)`
  position: absolute;
  left: 20px;
  top: 20px;
  opacity: 0;
  animation: ${XAnimate} 400ms ease-in forwards;
  animation-delay: 650ms;
`;

export const LearnMoreModal = ({ learnMoreId, onClose }) => {
  const [isClosing, setIsClosing] = useState(false);
  const learnMoreContent = LearnMores.find(x => x.id === learnMoreId);

  const close = async () => {
    setIsClosing(true);

    await sleep(500);
    onClose();
  };

  return (
    <>
      <Backdrop onClick={close} className={isClosing ? 'closing' : ''} />
      <Wrapper
        id="mod"
        className={isClosing ? 'closing' : ''}
        bg={learnMoreContent.background}
      >
        <Flex
          flexDirection="column"
          style={{
            height: isMobile?'100vh':'100%',
            overflowY: 'auto',
            paddingBottom: isMobile?'80px':'',
            overscrollBehavior: 'contain',
          }}
        >
          <ImageContainer>
            <Image src={learnMoreContent.image} alt="" />
            <X onClick={close}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7417 9.50648L18.55 2.71398C18.8482 2.41583 19.0157 2.01146 19.0157 1.58981C19.0157 1.16817 18.8482 0.763793 18.55 0.465645C18.2519 0.167498 17.8475 0 17.4258 0C17.0042 0 16.5998 0.167498 16.3017 0.465645L9.50918 7.27398L2.71668 0.465645C2.41853 0.167498 2.01416 -3.14149e-09 1.59251 0C1.17087 3.1415e-09 0.766495 0.167498 0.468347 0.465645C0.1702 0.763793 0.00270212 1.16817 0.00270212 1.58981C0.00270211 2.01146 0.1702 2.41583 0.468347 2.71398L7.27668 9.50648L0.468347 16.299C0.319944 16.4462 0.202153 16.6213 0.12177 16.8142C0.0413859 17.0072 0 17.2141 0 17.4231C0 17.6322 0.0413859 17.8391 0.12177 18.0321C0.202153 18.225 0.319944 18.4001 0.468347 18.5473C0.615539 18.6957 0.790657 18.8135 0.983601 18.8939C1.17654 18.9743 1.3835 19.0157 1.59251 19.0157C1.80153 19.0157 2.00848 18.9743 2.20143 18.8939C2.39437 18.8135 2.56949 18.6957 2.71668 18.5473L9.50918 11.739L16.3017 18.5473C16.4489 18.6957 16.624 18.8135 16.8169 18.8939C17.0099 18.9743 17.2168 19.0157 17.4258 19.0157C17.6349 19.0157 17.8418 18.9743 18.0348 18.8939C18.2277 18.8135 18.4028 18.6957 18.55 18.5473C18.6984 18.4001 18.8162 18.225 18.8966 18.0321C18.977 17.8391 19.0184 17.6322 19.0184 17.4231C19.0184 17.2141 18.977 17.0072 18.8966 16.8142C18.8162 16.6213 18.6984 16.4462 18.55 16.299L11.7417 9.50648Z"
                  fill="#354E79"
                />
              </svg>
            </X>
          </ImageContainer>

          <ContentWrap className={isClosing ? 'closing' : ''}>
            {learnMoreContent.orangeWhatIsThisText && (
              <OrangeQuestion>
                {learnMoreContent.orangeWhatIsThisText}
              </OrangeQuestion>
            )}
            <Title>{learnMoreContent.title}</Title>
            <Description>{learnMoreContent.descriptionText}</Description>
            {(learnMoreContent.paragraphs || []).map((p, i) => {
              return (
                <Paragraphe key={p.question} delay={`${i * 100 + 300}ms`}>
                  <ParagrapheTitle>{p.question}</ParagrapheTitle>
                  <Description>{p.answer}</Description>
                  {p.video ? (
                    <iframe
                      title="vimeo-player"
                      src={p.video}
                      width="100%"
                      frameBorder="0"
                      allowFullScreen="true"
                    />
                  ) : null}
                  {p.image ? (
                    <img src={p.image} style={{ maxWidth: '100%' }} />
                  ) : null}
                </Paragraphe>
              );
            })}
          </ContentWrap>
        </Flex>
      </Wrapper>
    </>
  );
};
