import React from 'react';
import IconContainer from './IconContainer';

const Chat = props => (
  <IconContainer {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fill={props.fill ? props.fill : '#000'}
        d="M18,0H2A2,2,0,0,0,0,2V20l4-4H18a2,2,0,0,0,2-2V2A2,2,0,0,0,18,0ZM4,7H16V9H4Zm8,5H4V10h8Zm4-6H4V4H16Z"
      />
    </svg>
  </IconContainer>
);

export default Chat;
