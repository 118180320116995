import React from 'react';
import { Flex, Heading, Text } from 'rebass';
import moment from 'moment';
import styled from 'styled-components';

import ItemCollection from '../../components/icons/ItemCollection';

const Icon = styled(Flex)`
  display: none;
  @media screen and (min-width: 52em) {
    display: block;
  }
`;
export const LabStep2 = ({ currentStep }) => {
  return (
    <Flex flexDirection={['column', 'row']} flex={1}>
      <Icon
        mr={[0, 4]}
        mb={[3, 0]}
        alignItems="flex-start"
        justifyContent="center"
      >
        <ItemCollection width={90} />
      </Icon>

      <Flex flexDirection="column" flex={1}>
        <Heading mb={4}>Congrats on collecting your sample</Heading>
        <Text lineHeight={1.6}>
        Your sample is en route to our lab
          {currentStep.returnStatus
            ? `. ${currentStep.returnStatus}`
            : ` and USPS tracking shows it's expected to arrive ${moment(currentStep.eta).format(
                'MMMM Do, YYYY'
              )}.`}{' '}
          Hold Tight!
        </Text>
      </Flex>
    </Flex>
  );
};
