import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, ResponsiveContainer, Legend } from 'recharts';
import { GET_BASELINE_SURVEY_ANSWERS, GET_FOLLOWUP_SURVEY_ANSWERS } from '../../graphql';
import { QUESTIONS } from './Questions';
import { getDefaultValueSingle } from '../../components/former/utils/extractBackResponse';
import { FOLLOWUP_SURVEYS_ID } from '../../utils/followupSurveysUtils';
import FullLayout2 from '../../components/FullLayout';

const BaselineRadar = ({ data, dataFollowUp }) => {
  const [chartData, setChartData] = useState();

  useEffect(() => {
    if (!data || !data.values) return;
    let dta = [];
    const dt = JSON.parse(data.values);
    const dtFu = JSON.parse(dataFollowUp.values);

    console.log({ dt });
    for (let index = 0; index < dt.length; index++) {
      const element = dt[index];
      const questionnaire = QUESTIONS.find((x) => x.id === element.id);
      if (!questionnaire) continue;
      if (questionnaire.type === 'PICKONE') {
        const val = getDefaultValueSingle(element);
        if (!!val) {
          const fu = dtFu.find((x) => x.id === element.id);
          let valFu;
          if (fu) {
            valFu = getDefaultValueSingle(fu);
          }
          const opt = questionnaire.options.find((x) => x.value === val);
          let optFu;
          if (valFu) {
            optFu = questionnaire.options.find((x) => x.value === valFu);
          }
          if (!isNaN(opt.score))
            dta.push({
              question: questionnaire.chartTitle,
              key: 4 - opt.score,
              keyFu: optFu ? 4 - optFu.score : null,
            });
        }
      }
    }
    console.log({ dta });
    setChartData(dta);
  }, []);

  console.log({ chartData });
  if (!chartData) return null;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={chartData}>
        <PolarGrid />
        <PolarAngleAxis dataKey="question" />
        <PolarRadiusAxis angle={30} domain={[0, 4]} />
        <Radar name="Baseline" dataKey="key" stroke="#8884d8" fill="#8884d8" fillOpacity={0.4} />
        <Radar name="Follow up 1" dataKey="keyFu" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
        <Legend />
      </RadarChart>
    </ResponsiveContainer>
  );
};

const BaselineChart = () => {
  return (
    <FullLayout2 style={{ zIndex: 1 }}>
      <Query query={GET_BASELINE_SURVEY_ANSWERS}>
        {({ loading, data }) => {
          if (loading) return null;
          return (
            <Query
              query={GET_FOLLOWUP_SURVEY_ANSWERS}
              variables={{
                surveyName: FOLLOWUP_SURVEYS_ID[0].name,
              }}
            >
              {({ loading: loadingFollowUp, data: dataFollowUp }) => {
                if (loadingFollowUp) return null;
                return <BaselineRadar data={data.getBaselineSurveyAnswers} dataFollowUp={dataFollowUp.getFollowUpSurveyAnswers} />;
              }}
            </Query>
          );
        }}
      </Query>
    </FullLayout2>
  );
};

export default BaselineChart;
