import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, Heading, Image, Text } from 'rebass';
import styled from 'styled-components';
import { GET_ARTICLES_CATEGORIES, GET_ARTICLES_FOR_CATEGORY } from '../graphql';
import { ArticleCard, ArticleCardLoading } from './landing/Articles';
import { withRouter } from 'react-router';
import { CleanDom } from '../utils/domCleanup';
import { SearchInput } from '../components/TextInput';
import { Skeleton } from '../components/loadingSkeleton/Skeleton';
import { Query } from 'react-apollo';
import PleaseWait from '../components/PleaseWait';
import doctorIcon from '../static/doctorIcon.png';

const Container = styled(Flex)`
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 24px;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const BlurredContainer = styled(Flex)`
  box-shadow: inset 0 0 0 110px rgba(253, 245, 242, 0.65);
  backdrop-filter: blur(6px);
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;
  padding: 12px;
  justify-content: center;
  align-items: center;
`;

const Img = styled(Image)`
  transition: 0.3s all ease-in-out;
  object-fit: cover;
  &:hover {
    transform: scale(1.1);
  }
`;

const CategoryTag = styled(Text)`
  transition: 0.3s all ease-in-out;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 300;

  &:hover {
    font-weight: 500;
  }
`;

const CategoryTagMobile = styled(Box)`
  transition: 0.3s all ease-in-out;
  border-radius: 24px;
  padding: 4px 8px;
  color: #7992b3;
  border: 1px solid #7992b3;
  margin-right: 18px;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 300;

  &.isSelected {
    font-weight: 500;
    color: rgb(53, 78, 121);
    border: 1px solid rgb(53, 78, 121);
  }
`;
const NoScrollBarContainer = styled(Flex)`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ArticleCardContainer = styled(Flex)`
  transition: 0.3s all ease-in-out;
  & > * {
    transition: 0.3s all ease-in-out;
  }
  &:hover > * {
    opacity: 0.75;
  }
  &:hover > :hover {
    opacity: 1;
  }
`;
const BoxRight = styled(Box)`
  width: 254px;

  @media screen and (max-width: 1300px) {
    display: none;
  }
`;
const BoxRightMobile = styled(Flex)`
  flex-direction: column;
  @media screen and (min-width: 1300px) {
    display: none;
  }
`;

const RelatedPosts = ({ history, errorCats, loadingCats, dataCats }) => {
  const containerRef = useRef(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filter, setFilter] = useState(null);
  const [filterTmp, setFilterTmp] = useState(null);
  // const { data, loading, error, fetchMore, refetch, called } = useQuery(GET_ARTICLES_FOR_CATEGORY, {
  //   variables: {
  //     categoryId: selectedCategory,
  //     take: 24,
  //     skip: 0,
  //     filter: filter,
  //     favorites: false,
  //   },
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: 'cache-and-network',
  //   nextFetchPolicy: 'cache-first',
  // });

  useEffect(
    () => {
      containerRef.current.scrollTo(0, 0);
    },
    [selectedCategory]
  );

  const onScroll = (e, data, fetchMore) => {
    if (!!data && data.getArticlesInCategoryFromWeb && !isFetchingMore) {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
        if (data.getArticlesInCategoryFromWeb.length % 24 !== 0) return;

        setTimeout(() => {
          onFetchMore(data, fetchMore);
        }, 300);

        return;
      }
    }
  };

  const onFetchMore = (data, fetchMore) => {
    if (!data || !data.getArticlesInCategoryFromWeb || data.getArticlesInCategoryFromWeb.length < 1 || !!isFetchingMore) return;
    setIsFetchingMore(true);
    try {
      fetchMore({
        variables: {
          categoryId: selectedCategory,
          take: 24,
          filter: filter,
          favorites: false,
          skip: ((data||{}).getArticlesInCategoryFromWeb || []).length,
          idsToExclude: ((data||{}).getArticlesInCategoryFromWeb || []).map((xx) => xx.id),
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsFetchingMore(false);
          if (!fetchMoreResult || !fetchMoreResult.getArticlesInCategoryFromWeb || fetchMoreResult.getArticlesInCategoryFromWeb.length < 1) {
            return previousResult;
          }
          return Object.assign({}, previousResult, {
            getArticlesInCategoryFromWeb: [
              ...previousResult.getArticlesInCategoryFromWeb,
              ...fetchMoreResult.getArticlesInCategoryFromWeb.filter((x) => previousResult.getArticlesInCategoryFromWeb.findIndex((y) => y.id === x.id) < 0),
            ],
          });
        },
      });
    } catch (error) {
      setIsFetchingMore(false);
    }
  };

  return (
    <Query
      query={GET_ARTICLES_FOR_CATEGORY}
      variables={{
        categoryId: selectedCategory,
        take: 24,
        skip: 0,
        filter: filter,
        favorites: false,
        idsToExclude: [],
      }}
      notifyOnNetworkStatusChange={true}
      fetchPolicy="cache-and-network"
      nextFetchPolicy="cache-first"
    >
      {({ data, loading, error, fetchMore, refetch, called }) => {
        if (error) return null;
        return (
          <Container ref={containerRef} flex={1} onScroll={(e) => onScroll(e, data, fetchMore)} style={{ maxHeight: 'calc(100vh - 90px)', overflow: 'auto' }}>
            <Flex flexDirection={'column'}>
              <Flex flexDirection={'column'} style={{ width: '100%' }}>
                <Flex flex={1}>
                  <Flex flex={1}>
                    <Flex flexDirection={'column'} style={{ maxWidth: '700px' }}>
                      <Heading textAlign={'center'} mb={'24px'}>
                        {!selectedCategory
                          ? 'How You Feel Tomorrow Starts Today'
                          : (((dataCats || {}).getArticleCategories || []).find((x) => x.id === selectedCategory) || {}).name || 'How You Feel Tomorrow Starts Today'}
                      </Heading>

                      <BoxRightMobile>
                        <SearchInput
                          type="search"
                          placeholder="Search"
                          value={filterTmp}
                          onEnter={() => {
                            setFilter(filterTmp);
                          }}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setFilter(null);
                              setFilterTmp(null);
                              return;
                            }
                            setFilterTmp(e.target.value);
                          }}
                        />
                        {!!filterTmp &&
                          filterTmp !== filter && (
                            <Text mt={2} style={{ fontSize: '12px', fontWeight: 300 }}>
                              Press <span style={{ fontWeight: 500 }}>Enter</span> to search
                            </Text>
                          )}
                        <Box style={{ maxWidth: '100vw', overflow: 'auto', width: '100vw', marginLeft: '-20px', marginRight: '-20px', paddingLeft: '20px', paddingRight: '20px' }}>
                          <Flex style={{ paddingTop: '24px', paddingBottom: '24px' }}>
                            {!loadingCats ? (
                              <>
                                {dataCats.getArticleCategories.map((x) => {
                                  return (
                                    <CategoryTagMobile
                                      className={selectedCategory === x.id ? 'isSelected' : ''}
                                      key={x.id}
                                      onClick={() => {
                                        setSelectedCategory(x.id);
                                      }}
                                      mb={2}
                                      isSelected={selectedCategory === x.id}
                                    >
                                      {x.name}
                                    </CategoryTagMobile>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                {[...Array(10)].map((x, i) => {
                                  return (
                                    <CategoryTagMobile>
                                      <Skeleton height="14px" width="48px" key={`loadingArtiCat${i}`} />
                                    </CategoryTagMobile>
                                  );
                                })}
                              </>
                            )}
                          </Flex>
                        </Box>
                      </BoxRightMobile>
                      <ArticleCardContainer height={'100%'} style={{ height: '100%', overflowX: 'auto' }} flexWrap={'wrap'}>
                        {!!loading && !isFetchingMore && (!data || !data.getArticleCategoriesFromWeb) ? (
                          <>
                            {[...Array(24)].map((x, i) => {
                              return <ArticleCardLoading className="individual" key={`loadingArtiCat${i}`} />;
                            })}
                          </>
                        ) : (
                          <>
                            {(data.getArticlesInCategoryFromWeb || []).map((x) => {
                              return (
                                <ArticleCard
                                  key={x.id}
                                  article={x}
                                  tag={x.id}
                                  className="individual"
                                  onClick={() =>
                                    history.push({
                                      pathname: `/articles/${encodeURIComponent(x.title)}`,
                                      state: {
                                        ...x,
                                      },
                                    })
                                  }
                                />
                              );
                            })}
                            {!!data && !!data.getArticlesInCategoryFromWeb && data.getArticlesInCategoryFromWeb.length > 0 &&
                              data.getArticlesInCategoryFromWeb.length % 3 > 0 && (
                                <>
                                  {[...Array(3 - (data.getArticlesInCategoryFromWeb.length % 3)).keys()].map((x, i) => {
                                    return (
                                      <Flex
                                        key={`ArtiPlaceholder${i}`}
                                        style={{ minWidth: '200px', width: '200px', height: '240px', marginRight: '24px', overflow: 'hidden', marginBottom: '32px', borderRadius: '10px' }}
                                      />
                                    );
                                  })}
                                </>
                              )}
                            {!isFetchingMore && ((data||{}).getArticlesInCategoryFromWeb || []).length === 0 && (
                              <Flex style={{ flexDirection: 'column', alignItems: 'center' }}>
                                <img src={doctorIcon} style={{ maxWidth: '120px' }} />
                                <Text my={3}>We did not find any article that match your search</Text>
                                <Button
                                  variant={'primary'}
                                  onClick={() => {
                                    setSelectedCategory(null);
                                    setFilter(null);
                                    setFilterTmp(null);
                                  }}
                                >
                                  Reset Search
                                </Button>
                              </Flex>
                            )}
                          </>
                        )}
                        {isFetchingMore && (
                          <BlurredContainer>
                            <PleaseWait
                              text={' '}
                              spinner={{
                                sColor: '#ECEFF2',
                                pColor: '#909FB5',
                              }}
                            />
                          </BlurredContainer>
                        )}
                      </ArticleCardContainer>
                    </Flex>
                  </Flex>

                  <BoxRight>
                    <Flex style={{ position: 'fixed' }} flexDirection={'column'}>
                      <SearchInput
                        type="search"
                        placeholder="Search"
                        value={filterTmp}
                        onEnter={() => {
                          setFilter(filterTmp);
                        }}
                        onChange={(e) => {
                          if (!e.target.value) {
                            setFilter(null);
                            setFilterTmp(null);
                            return;
                          }
                          setFilterTmp(e.target.value);
                        }}
                      />
                      {!!filterTmp &&
                        filterTmp !== filter && (
                          <Text mt={2} style={{ fontSize: '12px', fontWeight: 300 }}>
                            Press <span style={{ fontWeight: 500 }}>Enter</span> to search
                          </Text>
                        )}

                      <Text style={{ fontWeight: 500 }} mt={4} mb={3}>
                        Categories
                      </Text>
                      {!loadingCats ? (
                        <>
                          {dataCats.getArticleCategories
                            .filter((x) => {
                              return x.name.toLowerCase() !== '#diet' && x.name.toLowerCase() !== '#recipes';
                            })
                            .map((x) => {
                              return (
                                <CategoryTag
                                  key={x.id}
                                  onClick={() => {
                                    setSelectedCategory(x.id);
                                  }}
                                  mb={2}
                                  color="#F8A294"
                                  style={{ fontWeight: selectedCategory === x.id ? 600 : '', cursor: 'pointer' }}
                                >
                                  {x.name}
                                </CategoryTag>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {[...Array(10)].map((x, i) => {
                            return <Skeleton height="18px" width="180px" key={`loadingArtiCat${i}`} />;
                          })}
                        </>
                      )}
                    </Flex>
                  </BoxRight>
                </Flex>
              </Flex>
            </Flex>
          </Container>
        );
      }}
    </Query>
  );
};
const ViewArticleInnerWrapper = ({ location, articleId, history }) => {
  return (
    <Query
      query={GET_ARTICLES_CATEGORIES}
      variables={{
        id: articleId,
      }}
    >
      {({ data: dataCats, loading: loadingCats, error: errorCats }) => {
        return <RelatedPosts dataCats={dataCats} loadingCats={loadingCats} errorCats={errorCats} location={location} articleId={articleId} history={history} />;
      }}
    </Query>
  );
};

const ViewArticles = ({ history }) => {
  useEffect(() => {
    CleanDom();
  }, []);
  return <ViewArticleInnerWrapper history={history} />;
};

export default withRouter(ViewArticles);
