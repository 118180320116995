import React, { Fragment, useState } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import camera from '@iconify/icons-entypo/camera';
import check from '@iconify/icons-entypo/check';
import Dropzone from 'react-dropzone';
import { BoxButton } from '../../../components/async/SharedComponents';
import { UPLOAD_MEDICATION_BOTTLE_PHOTO } from '../../../graphql';
import PhotoTaker from '../../../components/asyncIntake/PhotoTaker';
import { isMobile } from 'react-device-detect';

const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
`;

const Description = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
`;

const CancelButton = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  text-align: center;
  text-decoration-line: underline;
  color: #44a5ff;
  margin-top: 24px;
  margin-bottom: 12px;
`;

const FakeButton = styled(Flex)`
  cursor: pointer;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #354e79;
  width: 124px;
`;

const CameraIconBox = styled(Flex)`
  width: 68px;
  height: 68px;
  background: #e7f0ff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
`;

const PictureMiniature = ({ file, onFileRemove, onConfirm }) => {
  // console.log(file);
  return (
    <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
      <Box mt="0" mb={3}>
        <Heading textAlign="left">Review and Confirm</Heading>
      </Box>

      <Box mt="0" mb={4}>
        <Description>
          To access treatment we need a photo of your current pill bottle.
        </Description>
      </Box>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        style={{
          width: 'calc(100vw / 3)',
          borderRadius: '20px',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
          position: 'relative',
        }}
      >
        <img
          src={file.data}
          alt="No Preview Available"
          width="100%"
          style={{
            maxHeight: '300px',
            borderRadius: '20px',
            objectFit: 'contain',
          }}
        />
        <Button
          onClick={onFileRemove}
          variant="white"
          style={{ position: 'absolute', bottom: '6px', right: '6px' }}
        >
          Change
        </Button>
      </Flex>

      <Box mt={3} mb={3}>
        <Title textAlign="left">Ensure that...</Title>
      </Box>
      <Description>
        <span>
          <Icon
            icon={check}
            style={{
              color: 'rgb(53, 78, 121)',
              fontSize: '18px',
              alignSelf: 'center',
            }}
          />
        </span>{' '}
        The photo is not blurry or dark
      </Description>
      <Description>
        <span>
          <Icon
            icon={check}
            style={{
              color: 'rgb(53, 78, 121)',
              fontSize: '18px',
              alignSelf: 'center',
            }}
          />
        </span>{' '}
        We can read clearly the label
      </Description>
      <Description>
        <span>
          <Icon
            icon={check}
            style={{
              color: 'rgb(53, 78, 121)',
              fontSize: '18px',
              alignSelf: 'center',
            }}
          />
        </span>{' '}
        We can see your name and the dosage
      </Description>
      <Button
        onClick={onConfirm}
        variant="primary"
        mt={4}
        style={{
          marginLeft: '12px',
          marginRight: '12px',
        }}
      >
        Confirm
      </Button>
    </Flex>
  );
};

const EmptyState = ({
  onDropAccepted,
  toastManager,
  takeDesktopPhoto = () => {},
  disabled,
}) => {
  return (
    <Dropzone
      noClick={false}
      multiple={false}
      onDropRejected={() => {
        toastManager.add('Invalid file: Make sure your file is a jpeg and less than 10mb', {
          appearance: 'error',
          autoDismissTimeout: 10000,
        });
      }}
      maxSize={10000000}
      onDropAccepted={onDropAccepted}
      accept={'image/jpeg'}
      style={{
        marginBottom: 36,
      }}
    >
      {({ getRootProps, getInputProps, isDragActive, open }) => (
        <Flex
          flexDirection="column"
          style={{
            cursor: 'pointer',
          }}
          {...getRootProps()}
          onClick={() => {
            open();
          }}
        >
          <Flex flexDirection="row">
            <Flex flexDirection="column" flex={1}>
              <Title>Current Pill Bottle</Title>
              <Description mb="16px" mt="16px">
                Please take a picture of your current pill bottle
              </Description>

              {isMobile ? (
                <Button variant="pink" disabled={disabled}>
                  + Upload Photo
                </Button>
              ) : (
                <Flex flexDirection="row" justifyContent="center">
                  <Button variant="pink" mr={2} disabled={disabled}>
                    + Upload File
                  </Button>
                  <Button
                    variant="pink"
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      takeDesktopPhoto();
                    }}
                    ml={2}
                    disabled={disabled}
                  >
                    + Take Photo
                  </Button>
                </Flex>
              )}
            </Flex>
            <CameraIconBox
                onClick={e => {
                  if (!isMobile) {
                    e.stopPropagation();
                    e.preventDefault();
                    takeDesktopPhoto();
                  }
                }}>
              <Icon
                icon={camera}
                style={{
                  color: 'rgb(53, 78, 121)',
                  fontSize: '24px',
                  alignSelf: 'center',
                }}
              />
            </CameraIconBox>
          </Flex>

          <input type="file" {...getInputProps()} />
        </Flex>
      )}
    </Dropzone>
  );
};

const MedicationBottlePicture = ({
  onSubmit,
  data,
  onSkip,
  client,
  toastManager,
}) => {
  const [file, setFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [isCardFrontTaking, setIsCardFrontTaking] = useState(false);
  const [isCardFrontConfirming, setIsCardFrontConfirming] = useState(false);
  const onDesktopPhotoCapture = async (dta, callback) => {
    // console.log({dta})
    var reader = new FileReader();
    reader.readAsDataURL(dta);
    reader.onloadend = async () => {
      var base64data = reader.result;

      const fls = {
        fileName: 'medBottle.jpg',
        mimeType: 'image/jpeg',
        data: base64data,
      };

      callback(fls);
    };
  };

  const fileAccepted = f => {
    for (let index = 0; index < f.length; index++) {
      const element = f[index];

      const reader = new FileReader();
      reader.onload = event => {
        let fls = f;
        fls = {
          fileName: element.name,
          mimeType: element.type,
          data: event.target.result,
        };

        setFile(fls);
        setIsCardFrontConfirming(true);
      };
      reader.readAsDataURL(element);
    }
  };

  const SubmitPicture = async () => {
    const payload = [];
    payload.push({ questionId: 'medicationBottle', answer: file.data });
    onSubmit(payload);
    return;
    setUploading(true);
    try {
      const { data } = await client.mutate({
        mutation: UPLOAD_MEDICATION_BOTTLE_PHOTO,
        variables: {
          file: file.data,
        },
      });

      setUploading(false);
      if (
        !data ||
        !data.uploadMedicationBottlePhoto ||
        !data.uploadMedicationBottlePhoto.ok
      ) {
        toastManager.add('An error occured while uploading your file', {
          appearance: 'error',
          autoDismissTimeout: 5000,
        });
      } else {
        const payload = [];
        payload.push({ questionId: 'medicationBottle', answer: file.data });
        onSubmit(payload);
      }
    } catch (error) {}
  };
  if (isCardFrontTaking) {
    return (
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
        <PhotoTaker
          onCapture={f =>
            onDesktopPhotoCapture(f, ff => {
              setFile(ff);
              setIsCardFrontConfirming(true);
              setIsCardFrontTaking(false);
            })
          }
        />
      </Flex>
    );
  }

  return !isCardFrontConfirming ? (
    <Fragment>
      {!file ? (
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          {data.title ? (
            <Box mt="0" mb={3}>
              <Title textAlign="left">{data.title}</Title>
            </Box>
          ) : null}

          {data.subTitle ? (
            <Box mt="0" mb={4}>
              <Description>{data.subTitle}</Description>
            </Box>
          ) : null}
          <EmptyState
            onDropAccepted={fileAccepted}
            toastManager={toastManager}
            takeDesktopPhoto={() => {
              setIsCardFrontTaking(true);
              setIsCardFrontConfirming(false);
            }}
            disabled={uploading}
          />
          <BoxButton className="visible">
            {!file ? (
              <Button
                my={4}
                variant="white"
                style={{
                  background: '#FFF2F2',
                  border: '2px solid #F6A293',
                  marginLeft: '12px',
                  marginRight: '12px',
                }}
                width={[1, 1 / 2, 1 / 4]}
                onClick={onSkip}
              >
                I don’t take thyroid medications
              </Button>
            ) : (
              <Button
                variant="primary"
                disabled={!file || !uploading}
                style={{
                  marginLeft: '12px',
                  marginRight: '12px',
                }}
                width={[1, 1 / 2, 1 / 4]}
              >
                Continue
              </Button>
            )}
          </BoxButton>
        </Flex>
      ) : (
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          <PictureMiniature
            onConfirm={() => {
              setIsCardFrontConfirming(false);
              setIsCardFrontTaking(false);
              SubmitPicture();
            }}
            file={file}
            onFileRemove={() => setFile(null)}
          />
          <BoxButton
            className="visible"
            style={{ height: !file ? '60px' : 'auto' }}
          >
            {!file ? (
              <Button
                my={4}
                variant="white"
                style={{
                  background: '#FFF2F2',
                  border: '2px solid #F6A293',
                  marginLeft: '12px',
                  marginRight: '12px',
                }}
                width={[1, 1 / 2, 1 / 4]}
              >
                I don’t take thyroid medications
              </Button>
            ) : (
              <Flex flexDirection="column" flex={1} alignItems="center">
                <Button
                  variant="primary"
                  style={{
                    marginLeft: '12px',
                    marginRight: '12px',
                    flex: 1,
                  }}
                  width={[1, 1 / 2, 1 / 4]}
                  onClick={SubmitPicture}
                >
                  Continue
                </Button>

                <CancelButton onClick={() => setFile(null)}>
                  Retake photo
                </CancelButton>
              </Flex>
            )}
          </BoxButton>
        </Flex>
      )}
    </Fragment>
  ) : (
    <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
      <PictureMiniature
        file={file}
        onConfirm={() => {
          setIsCardFrontConfirming(false);
          setIsCardFrontTaking(false);
          SubmitPicture();
        }}
        onFileRemove={() => {
          setFile(null);
          setIsCardFrontConfirming(false);
        }}
      />
    </Flex>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(MedicationBottlePicture);
