import moment from 'moment';

export const PrequalificationQuestions = [
  {
    id: 'previouslyDiagnosed',
    type: 'single',
    endpoint: 'medicalHistory',
    countText: "Let's get started",
    onlyFirstTime: true,
    prompt: 'Were you previously diagnosed with hypothyroidism?',
    fakeDelayer: true,
    options: [
      { questionId: '19', value: 'Yes', answer: 'Yes' },
      { questionId: '19', value: 'No', answer: 'No', goToPage: 'testFirst' },
      {
        questionId: '19',
        value: 'I have abnormal labs I can upload',
        answer: 'Yes',
      },
      // { questionId: '19', value: "I don't know", answer: 'DontKnow' },
    ],
  },
  {
    id: 'notAlone',
    type: 'notAlone',
    hideCountText: true,
    fakeDelayer: true,
  },
  // {
  //   id: 'states',
  //   hideCountText: true,
  //   type: 'statePicker',
  //   endpoint: 'medicalHistory',
  //   onlyFirstTime: true,
  //   prompt: '',
  //   title: 'Where Are You Today?',
  //   subTitle:
  //     'We’ll match you to a local healthcare provider licensed in your state',
  //   fakeDelayer: true,
  // },
  {
    id: 'dobSex',
    type: 'dobSex',
    endpoint: 'medicalHistory',
    hideCountText: true,
    onlyFirstTime: true,
    title: 'Treatment Eligibility',
    subTitle: 'Let’s confirm that you are eligible for this service',
    fakeDelayer: true,
    onSubmitHandler: value => {
      if (!value || value.length < 1) {
        return 'reset123';
      }

      try {
        const dob = value[0].value['dob'];

        const age = moment().diff(moment(dob, 'YYYY-MM-DD'), 'years');
        if (age < 18) {
          return 'disqualifiedDob';
        }
      } catch (error) {
        return 'reset123';
      }

      return null;
    },
  },
  {
    id: 'eligibleStart',
    hideCountText: true,
    type: 'eligibleStart',
  },
  {
    id: 'testFirst',
    hideCountText: true,
    type: 'testFirst',
  },
  {
    id: 'disqualifiedDob',
    type: 'DisqualifiedDob',
    hideCountText: true,
  },
];
