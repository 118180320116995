import React from 'react';
import { Card, Flex, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import PromptIntake from './components/complete/promptIntake';
import Confirmation from './components/complete/confirmation';
import { Query } from 'react-apollo';
import { APPOINTMENTS } from '../../graphql';
import SpinLoader from '../../components/SpinLoader';
import moment from 'moment';
import withSession from '../../lib/withSession';

const InsuranceDisclaimer = (props) => {
  return (
    <Card p={4} mb={5} style={{ borderRadius: '20px', background: '#496387', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Flex justifyContent="center" maxWidth={'80%'} flexDirection={'column'} alignItems={'center'}>
        <Heading color={'white'} mb={2} textAlign="center">
          We are reviewing your insurance details
        </Heading>
        <Text textAlign={'center'} mt="12px" color={'white'}>
          Please note that if you are eligible for the insurance membership, you will have to use your insurance for these visits.
        </Text>
      </Flex>
    </Card>
  );
};
export class Complete extends React.Component {
  constructor(props) {
    super(props);

    const { prompt, session } = this.props;
    this.state = {
      promptIntake: prompt,
    };
  }

  render() {
    const { reinitFlow, slot, wizard, currentIntakeStatus, showInsuranceDisclaimer } = this.props;
    const { promptIntake } = this.state;

    if (wizard && (!slot || !slot.startTime)) {
      reinitFlow(wizard);
      return <div />;
    }

    if (!currentIntakeStatus || currentIntakeStatus !== 'completed') {
      return (
        <>
          {showInsuranceDisclaimer && <InsuranceDisclaimer />}
          <PromptIntake {...this.props} />;
        </>
      );
    } else if (promptIntake) {
      return (
        <Query query={APPOINTMENTS}>
          {({ loading, data, error }) => {
            if (loading) {
              return (
                <Flex flex={1} justifyContent="center" alignItems="center">
                  <SpinLoader />
                </Flex>
              );
            }

            if (error) {
              return (
                <>
                  {showInsuranceDisclaimer && <InsuranceDisclaimer />}
                  <Confirmation {...this.props} />
                </>
              );
            }
            const { appointments } = data;

            let mustCompleteIntake;
            if (this.props.intakeStatus !== 'completed') {
              mustCompleteIntake = true;
            } else {
              if (!this.props.intakeDate) {
                mustCompleteIntake = true;
              } else {
                if (appointments) {
                  const past = appointments.filter((x) => x.status === '3' || x.status === '4');
                  if (past && past.length > 0) {
                    const pastSorted = past.sort((a, b) => {
                      const aa = moment(a.date, 'MM/DD/YYYY');
                      const bb = moment(b.date, 'MM/DD/YYYY');

                      if (aa.isBefore(bb)) return 1;
                      if (aa.isAfter(bb)) return -1;

                      return 0;
                    });
                    mustCompleteIntake = moment(this.props.intakeDate, 'MM-DD-YYYY').isSameOrBefore(moment(pastSorted[0].date, 'MM/DD/YYYY'), 'day');
                  } else {
                    mustCompleteIntake = false;
                  }
                }
              }
            }

            if (mustCompleteIntake) {
              return (
                <>
                  {showInsuranceDisclaimer && <InsuranceDisclaimer />}
                  <PromptIntake {...this.props} />;
                </>
              );
            } else {
              return (
                <>
                  {showInsuranceDisclaimer && <InsuranceDisclaimer />}
                  <Confirmation {...this.props} />;
                </>
              );
            }
          }}
        </Query>
      );
    } else {
      return (
        <>
          {showInsuranceDisclaimer && <InsuranceDisclaimer />}
          <Confirmation {...this.props} />
        </>
      );
    }
  }
}

export default withWizard(withSession(Complete));
