import React from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../asyncIntake/screens/components/FullLayout';
import { PrequalificationQuestions } from './PrequalificationQuestions';
import Header from '../asyncIntake/Header';
import Single from '../asyncIntake/screens/Single';
import { Waiter } from '../asyncIntake/screens/Waiter';
import DobSex from '../asyncIntake/screens/DobSex';
import Eligible from '../asyncIntake/screens/Eligible';
import { LearnMoreModal } from '../asyncIntake/screens/LearnMoreModal';
import TestFirst from '../asyncIntake/screens/TestFirst';
import NotAlone from '../asyncIntake/screens/NotAlone';
import DisqualifiedDob from '../asyncIntake/screens/DisqualifiedDob';
import { isMobile } from 'react-device-detect';
import Div100vh, { measureHeight } from 'react-div-100vh';
import withAnalytics from '../../lib/withAnalytics';

const Container = styled(Div100vh)`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  overflow-y: ${(props) => (isMobile ? 'hidden' : '')};
`;

const InnerContainer = styled(Flex)`
  max-width: ${(props) => (isMobile ? 'calc(100% - 12px)' : '850px')};
  height: 100%;
  margin: 0 auto;
  max-height: 100%;
  /* margin-right:  ${(props) => (isMobile ? '12px' : '850px')}; */
  flex-direction: column;
  overflow-y: ${(props) => (isMobile ? 'auto' : '')};
`;

const BlueQuestionIndex = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;

  color: #354e79;
`;

class AsyncPrequalification extends React.Component {
  constructor(props) {
    super(props);
    let bypassOtp = false;
    if (this.props.location && (this.props.location.search || '').indexOf('bpotp=true') > -1) {
      bypassOtp = true;
    }

let forwardToCommunity = false
    if (this.props.location && (this.props.location.search || '').indexOf('community') > -1) {
      forwardToCommunity=true
    }
    this.props.analytics.page();
    this.state = {
      values: [],
      values2: [],
      steps: null,
      isLoading: true,
      stepPreviousHistory: null,
      direction: 1,
      learnMoreModal: null,
      bypassOtp,
      forwardToCommunity
    };
    window.Beacon('on', 'ready', () => window.Beacon('destroy'));
    this.wizard = React.createRef();
  }
  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const { stepPreviousHistory } = this.state;
    
    if (!history || !history.location || !history.location.pathname) {
      return 1;
    }
    if (!stepPreviousHistory) {
      this.setState({
        stepPreviousHistory: history.location.pathname.replace('/prequalification/', ''),
        direction: 1,
      });
      return;
    }

    if (stepPreviousHistory === history.location.pathname.replace('/prequalification/', '')) {
      return;
    }
    const previousIndex = PrequalificationQuestions.findIndex((x) => x.id === stepPreviousHistory);

    const page = history.location.pathname.replace('/prequalification/', '');
    const newIndex = PrequalificationQuestions.findIndex((x) => x.id.toUpperCase() === page.toUpperCase());

    this.setState({
      stepPreviousHistory: history.location.pathname.replace('/prequalification/', ''),
      direction: newIndex < previousIndex ? -1 : 1,
    });
  }

  componentDidMount() {
    window.Beacon('destroy');

    const steps = this.stepsInitializer([]);

    this.setState({
      values: [],
      values2: [],
      steps,
      isLoading: false,
      allowNotDiagnosed: false
    });
  }

  componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;
    if (currentPage !== prevPage) {
      const split = currentPage.split('/');
      let tag = '';

      tag = split[split.length - 1];
      if (tag) {
        this.props.analytics.page(tag, 'intake');
      }
    }
  }

  showLearnMore = (learnMoreId) => {
    this.setState({
      learnMoreModal: learnMoreId,
    });
  };

  stepsInitializer = (savedValues) => {
    // const values = JSON.parse(JSON.stringify(this.state.values));
    const stps = [];
    for (let index = 0; index < PrequalificationQuestions.length; index++) {
      const element = PrequalificationQuestions[index];
      switch (element.type) {
        case 'single':
          stps.push({
            comp: (
              <Step id={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <Single
                      data={element}
                      onSubmit={(vals) => {
                        this.nextQuestion(vals, element.id, element.endpoint);
                      }}
                      savedData={(this.state.values.find((x) => x.id === element.id) || {}).values}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: 'complete',
            display: true,
          });
          break;
        case 'dobSex':
          stps.push({
            comp: (
              <Step id={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <DobSex
                      data={element}
                      onSubmit={(vals) => {
                        let submitHandler;

                        if (element.onSubmitHandler) {
                          submitHandler = element.onSubmitHandler(vals, () => this.state);
                        }
                        this.nextQuestion(vals, element.id, element.endpoint, submitHandler);
                      }}
                      savedData={(this.state.values.find((x) => x.id === element.id) || {}).values}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: 'complete',
            display: true,
          });
          break;
        case 'disqualifiedDob':
          stps.push({
            comp: (
              <Step id={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <DisqualifiedDob data={element} />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: 'complete',
            display: true,
          });
          break;
        // case 'statePicker':
        //   stps.push({
        //     comp: (
        //       <Step id={element.id}>
        //         <Container>
        //           <InnerContainer p={3}>
        //             <StateSelector
        //               data={element}
        //               onSubmit={vals => {
        //                 this.nextQuestion(vals, element.id, element.endpoint);
        //               }}
        //               savedData={
        //                 (this.state.values.find(x => x.id === element.id) || {})
        //                   .values
        //               }
        //             />
        //           </InnerContainer>
        //         </Container>
        //       </Step>
        //     ),
        //     key: 'complete',
        //     display: true,
        //   });
        //   break;
        case 'eligibleStart':
          stps.push({
            comp: (
              <Step id={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <Eligible
                      onSubmit={() => {
                        let vls = this.state.values;
                        if (this.state.bypassOtp) {
                          vls.push({ id: 'bpotp', values: true });
                        }
                        if(this.state.allowNotDiagnosed){
                          vls.push({ id: 'allowNotDiagnosed', values: 'yes' });
                        }
                        if(this.state.forwardToCommunity){
                          vls.push({id: 'forwardToCommunity', values: true})
                        }
                        this.props.history.push({
                          pathname: '/signup',
                          state: vls,
                        });
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: 'complete',
            display: true,
          });
          break;
        case 'testFirst':
          stps.push({
            comp: (
              <Step id={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <TestFirst
                      onSubmit={() => {
                        this.props.history.push({
                          pathname: '/order-kit',
                        });
                      }}
                      onSkip={() => {
                        this.setState({
                          allowNotDiagnosed: true
                        })
                        this.wizard.current.push('dobSex');
                      }}
                      onLearnMore={this.showLearnMore}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: 'testFirst',
            display: true,
          });
          break;
        case 'notAlone':
          stps.push({
            comp: (
              <Step id={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <NotAlone
                      onSubmit={(vals) => {
                        this.wizard.current.next();
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: 'notAlone',
            display: true,
          });
          break;

        default:
          break;
      }
    }

    return stps;
  };

  nextQuestion = async (val, id, endpoint, submitHandler) => {
    const Vs = JSON.parse(JSON.stringify(this.state.values));
    const vals = [];
    let found = false;

    for (let index = 0; index < Vs.length; index++) {
      const element = Vs[index];
      if (element.id === id) {
        vals.push({ id, values: val, endpoint });
        found = true;
      } else {
        vals.push(element);
      }
    }

    if (!found) {
      vals.push({ id, values: val });
    }

    this.setState({
      values: vals,
    });

    if (submitHandler) {
      if (submitHandler.indexOf('/') > -1) {
        this.props.history.push(submitHandler);
      } else {
        this.wizard.current.push(submitHandler);
      }
      return;
    }
    if (val.goToPage) {
      this.wizard.current.push(val.goToPage);
      return;
    }

    // return
    this.wizard.current.next();
  };

  previousQuestion = () => {
    const newPageIndex = this.wizard.current.state.steps.findIndex((x) => x.id === this.wizard.current.state.step.id);

    this.wizard.current.push(this.wizard.current.state.steps[newPageIndex - 1].id);
  };

  render() {
    const { history } = this.props;
    const steps = this.stepsInitializer();
    const { isLoading, direction, fakeDelaying, learnMoreModal } = this.state;
    if (!steps || !steps.length || isLoading) return <div />;

    const vh100 = `${measureHeight()}px` || '100vh';
    return (
      <FullLayout2 style={{ zIndex: 11, overflowY: isMobile ? 'hidden' : 'auto', maxHeight: vh100 }} pb={5}>
        <Container>
          <Header previousPage={this.previousQuestion} />
          <Box
            mt="60px"
            p="8px"
            style={{
              height: `calc(${vh100} - ${isMobile ? '60px' : '120px'})`,
              position: 'relative',
              overflowY: isMobile ? 'auto' : '',
            }}
          >
            {fakeDelaying ? (
              <Waiter />
            ) : (
              <Wizard
                ref={this.wizard}
                basename="/prequalification"
                history={history}
                render={({ step, ...p }, a) => {
                  const stp = PrequalificationQuestions.filter((xx) => xx.id === step.id);

                  return (
                    <>
                      {stp && stp.length && !stp[0].hideCountText ? (
                        <Box
                          style={{
                            maxWidth: '1024px',
                            width: '100%',
                            margin: '16px auto 0 auto',
                            paddingLeft: '24px',
                            paddingRight: '24px ',
                          }}
                        >
                          <BlueQuestionIndex>{stp[0].countText || `question ${1} of ${steps.filter((x) => x.display).map((x) => x.comp).length}`}</BlueQuestionIndex>
                        </Box>
                      ) : (
                        <Box
                          style={{
                            maxWidth: '1024px',
                            width: '100%',
                            margin: '16px auto 0 auto',
                            paddingLeft: '24px',
                            paddingRight: '24px ',
                          }}
                        >
                          <BlueQuestionIndex />
                        </Box>
                      )}
                      <TransitionGroup>
                        <CSSTransition key={step.id} timeout={{ enter: 500, exit: 500 }} classNames={direction < 0 ? 'example-back' : 'example'}>
                          <div className="example-steps" style={{ height: '100%', width: '100%' }}>
                            <Steps step={step}>{steps.filter((x) => x.display).map((x) => x.comp)}</Steps>
                          </div>
                        </CSSTransition>
                      </TransitionGroup>
                    </>
                  );
                }}
              />
            )}
          </Box>
        </Container>
        {learnMoreModal && <LearnMoreModal learnMoreId={learnMoreModal} onClose={() => this.setState({ learnMoreModal: null })} />}
      </FullLayout2>
    );
  }
}

export default withAnalytics(withApollo(withRouter(AsyncPrequalification)));
