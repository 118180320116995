import React, { Fragment } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';

import { TextInput } from '../TextInput';
import { FormField } from '../FormField';
import TextArea from '../TextArea';
import { withApollo, compose } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { APPOINTMENTS, CHARTS, CURRENT_USER, SET_AS_INACTIVE } from '../../graphql';
import ScrollAnimation from 'react-animate-on-scroll';
import { fadeInDown, fadeInUp } from '../../utils/animationsStyleBuilder';

const StyledText = styled(Text)`
  line-height: 1.6;
  border-bottom: 1px solid;
  /* color: white; */
  text-align: center;

  &:hover {
    cursor: pointer;
    font-weight: 600;
  }
`;

class InputField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  handleClick = async (element) => {
    const { data, wizard, client, toastManager, userData } = this.props;

    this.setState({ loading: true });
    const dta = JSON.parse(JSON.stringify(userData));

    try {
      await client.mutate({
        mutation: CHARTS,
        variables: {
          data: dta,
          intakeStatus: 'disqualified',
        },
      });
      await client.mutate({
        mutation: SET_AS_INACTIVE,
        variables: {
          disqualifyingReason: data.disqualifyingText || data.id,
        },
        refetchQueries: [{ query: CURRENT_USER }, { query: APPOINTMENTS }],
        awaitRefetchQueries: true,
      });

      wizard.history.push('disqualified');
    } catch (err) {
      console.log('ERR', err);
      this.setState({ loading: false });
      toastManager.add('Error submitting intake form', {
        appearance: 'error',
      });
    }
    // }
  };

  formatSubmit = (values) => {
    const { data } = this.props;
    const regex = /_/g;
    let payload = [];

    for (const k in values) {
      if (!data.answer) {
        const questionId = k.replace(regex, '.');
        let answer;

        if (data.prefix) {
          answer = `${data.prefix} ${values[k]}`;
        } else {
          answer = values[k];
        }

        payload.push({ questionId, answer });
      } else {
        const questionId = k.replace(regex, '.');
        let value;

        if (data.prefix) {
          value = `${data.prefix} ${values[k]}`;
        } else {
          value = values[k];
        }

        payload.push({ questionId, answer: data.answer, value });
      }
    }

    return payload;
  };

  onIdontKnow = (values) => {
    const regex = /_/g;
    let payload = [];

    for (const k in values) {
      const questionId = k.replace(regex, '.');
      let answer = 'N';
      const value = `not sure`;

      payload.push({ questionId, answer, value });
    }

    return payload;
  };

  render() {
    const { data, onSubmit, wizard } = this.props;

    return (
      <Fragment>
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          <Box m={2} style={{ ...fadeInDown() }}>
            <Text fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
              {data.prompt}
            </Text>
          </Box>

          {data.description && (
            <Text fontWeight={300} color="#344f77" textAlign="center" mt={3} fontSize={'14px'} style={{ ...fadeInDown(20, '1s', '200ms') }}>
              {data.description}
            </Text>
          )}
          <Formik
            initialValues={data.defaults}
            validationSchema={data.schema || ''}
            onSubmit={(values, actions) => {
              const formatted = this.formatSubmit(values);
              onSubmit(
                {
                  values: formatted,
                  endpoint: data.endpoint,
                },
                actions,
                wizard,
                data
              );
            }}
            render={({ isValid, errors, touched, submitForm, values, actions }) => (
              <Form>
                <Flex my={3} flex={1} flexDirection="column">
                  {data.options.map((el, ii) => (
                    <Fragment key={el.questionId}>
                      <FormField style={{ ...fadeInUp(20, '1.1s', `${ii * 100}ms`) }} width={[1, 1 / 2]} px={3} mb={3} error={touched[el.questionId] && errors[el.questionId]} alignSelf="center">
                        {!data.extend && (
                          <TextInput name={el.questionId} placeholder={el.placeholder} type={el.inputType || 'text'} inputType={el.inputmode || 'text'} inputMode={el.inputmode || 'text'} />
                        )}

                        {data.extend && <TextArea name={el.questionId} placeholder={el.placeholder} />}
                      </FormField>
                    </Fragment>
                  ))}
                </Flex>

                {data.footer && (
                  <Text fontWeight={300} color="#344f77" textAlign="center" fontSize={'14px'} style={{ ...fadeInUp(40, '1.1s', '300ms') }}>
                    {data.footer}
                  </Text>
                )}

                <Flex justifyContent="center" alignItems="center" flexDirection="column" mx={[3, 3, 0]} my={[2, 3]} style={{ ...fadeInUp(40, '1.3s', '300ms') }}>
                  <Button disabled={!isValid || this.state.loading} variant="pink" width={[1, 1 / 2, 1 / 4]} type="submit">
                    Next
                  </Button>
                  {data.hasIdontKnow && (
                    <Button
                      disabled={this.state.loading}
                      variant="dim"
                      width="auto"
                      type="button"
                      mt={4}
                      onClick={() => {
                        const r = this.onIdontKnow(values);

                        onSubmit(
                          {
                            values: r,
                            endpoint: data.endpoint,
                          },
                          actions,
                          wizard,
                          data
                        );
                      }}
                    >
                      I don't know
                    </Button>
                  )}

                  {data.hasSecondOption && (
                    <Button disabled={this.state.loading} variant="dim" width="auto" type="button" mt={4} onClick={() => this.handleClick(data.options[0])}>
                      {data.secondOptionText}
                    </Button>
                  )}

                  {data.skip && (
                    <StyledText
                      mt={4}
                      onClick={() => {
                        wizard.push(data.skip);
                      }}
                      mb={4}
                    >
                      {data.skipText || 'Skip'}
                    </StyledText>
                  )}
                </Flex>
              </Form>
            )}
          />
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(InputField);
