import React, { Fragment, Component } from 'react';
import { Button, Card, Flex, Heading, Text } from 'rebass';
// import moment from 'moment';
import PricingInfoModal from '../pricingInfoModal/pricingInfoModal';
import { isToggleActive } from '../../../../components/featureToggle/toggles';
import moment from 'moment-timezone';
import { Constants } from '../../../../utils/constants';

class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      confirmText: 'Confirm',
      prompt: null,
    };
  }
  componentDidMount() {
    this.props.analytics.page('Review', '/schedule');
  }

  render() {
    const { athenaCard, handlePayment, selectedAppointment, copayCost, regularCost, wizard, couldntConfirmCard, copayError, forTheNext, addMembership, session } = this.props;
    let tiz;
    const isB2bFreeVisits = !!session && session.b2b && session.b2b.visits && session.b2b.isActive && !session.b2b.mustVerify;
    try {
      tiz = moment(`${selectedAppointment.slot.date} ${selectedAppointment.slot.startTime}`, 'MM/DD/YYYY HH:mm')
        .tz(selectedAppointment.slot.timezoneName)
        .zoneAbbr();
    } catch (error) {}
    // console.log({a})
    return (
      <>
        <Flex flex={1} justifyContent="center">
          <Flex width={[1, 1 / 2, 1 / 2]} mx={3} mb={3} flexDirection="column">
            <Card p={4}>
              <Heading textAlign="center" mb={4} style={{ fontWeight: 'bold' }}>
                Review Your Visit
              </Heading>

              <Flex justifyContent="center" flexDirection="column">
                {forTheNext !== '-1' ? (
                  <Text textAlign="center" mb={3}>
                    We are holding this slot for the next
                    <br />
                    {forTheNext} minutes.
                  </Text>
                ) : (
                  <Text textAlign="center" mb={3}>
                    This slot is no longer held.
                  </Text>
                )}
                <Text textAlign="center">Your requested visit:</Text>

                <Text textAlign="center">
                  {`${moment(selectedAppointment.slot.startTime, 'HH:mm').format('h:mma')} ${tiz} on 
                    ${moment(selectedAppointment.slot.date, 'MM/DD/YYYY').format('dddd MMMM Do')}`}
                </Text>
                {!isB2bFreeVisits && (
                  <Flex flexDirection="column" my={3}>
                    <Text style={{ fontWeight: 'bold' }}>Your estimated Payment*</Text>

                    <Text mb={2}>
                      {copayError ? (
                        <Fragment>
                          <span style={{ fontWeight: '400' }}>{`Your Copay`}</span>
                          {/* {`$${copayCost}`} */}
                        </Fragment>
                      ) : (
                        `$${regularCost}`
                      )}
                    </Text>
                    {!!isToggleActive('MEMBERSHIP_ONLY') &&
                      !!addMembership && (
                        <Text textAlign="left" fontSize={1} fontWeight={200} mb={2}>
                          {`Your ${addMembership === 'mi' ? '$96' : `$${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}`} annual membership fee will be charged today.`}
                        </Text>
                      )}

                    {copayError && (
                      <Text textAlign="left" fontSize={1} mb={4} fontWeight={200}>
                        <>
                          <span>
                            Please note it can take up to 6 weeks for the claim to be completed. You will be charged for any co-insurance, co-pays, and deductibles. Most patients pay only their
                            co-pay.
                          </span>
                          {((this.props.session &&
                            this.props.session.isMember &&
                            this.props.session.membershipSpecific === 'insurance') || (addMembership && addMembership === 'mi')) && (
                              <span>
                                <br />
                                If your insurance cannot be billed for this visit, your cash pay price will be $110.
                              </span>
                            )}
                        </>
                      </Text>
                    )}
                  </Flex>
                )}

                {athenaCard &&
                  !couldntConfirmCard && (
                    <Flex flexDirection="column" mb={4}>
                      <Text style={{ fontWeight: 'bold' }}>Payment</Text>
                      <Text>
                        {athenaCard.brand} **
                        {athenaCard.last4}
                      </Text>
                      <Text>
                        Expires {athenaCard.expMonth}/{athenaCard.expYear}
                      </Text>
                    </Flex>
                  )}

                {!athenaCard &&
                  couldntConfirmCard && (
                    <Flex flexDirection="column" mb={4}>
                      <Text style={{ fontWeight: 'bold' }}>Payment</Text>
                      <Text>We could not confirm your credit card but you can still book your visit. A care manager will reach out to confirm your payment details</Text>
                    </Flex>
                  )}

                <Flex justifyContent="center">
                  <Button
                    variant="pink"
                    width={[1, 1 / 2]}
                    onClick={() => {
                      this.setState({ isProcessing: true });
                      handlePayment(
                        wizard,
                        () => {
                          this.setState({
                            isProcessing: false,
                            confirmText: 'Retry',
                          });
                        },
                        !athenaCard && couldntConfirmCard
                      );
                    }}
                    id="btn-reviewConsultation-confirm"
                    disabled={this.state.isProcessing}
                  >
                    {this.state.isProcessing ? 'Booking...' : this.state.confirmText}
                  </Button>
                </Flex>

                <div
                  style={{
                    height: '1px',
                    backgroundColor: '#ccc',
                    margin: '40px -32px',
                    width: 'calc(100% + 64px)',
                  }}
                />
                <Text textAlign="center" mb={0}>
                  You can cancel your appointment up to 4 hours before the start time without being charged.
                </Text>
              </Flex>
            </Card>
            {!isB2bFreeVisits && (
              <Text textAlign="center" style={{ marginTop: '24px' }} onClick={() => this.setState({ prompt: true })}>
                *includes your plan's co-pay or co-insurance, and any minimum deductible. <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>Learn More</span>
              </Text>
            )}
          </Flex>
        </Flex>
        <PricingInfoModal
          isOpen={this.state.prompt}
          close={() => {
            this.setState({ prompt: null });
          }}
        />
      </>
    );
  }
}

export default Review;
