import React, { Component } from 'react';
import DraggableChildComponent from './draggableChildren';
import { Box } from 'rebass';

class DraggableComponent extends Component {
  state = {
    divs: [],
    currentDiv: null,
    toDiv: null,
    inv: this.props.inv,
    currentInv: null,
    currentDown: null,
  };
  componentDidMount = () => {
    this.setState({ divs: this.props.children });
  };

  insertElementBefore = () => {
    const { inv } = this.props;
    const { toDiv, currentInv, currentDown } = this.state;
    if (inv !== currentDown) return;
    let divs = [...this.state.divs];
    let currentDiv = this.state.currentDiv;
    let currentEle;
    if (currentDiv !== toDiv) {
      currentEle = { ...divs[currentDiv] };
      divs = divs.filter((val, idx) => {
        return idx !== currentDiv;
      });
      divs.splice(toDiv, 0, currentEle);
      // This is a hack - Initialize the state as blank and then reset the state
      // With only 1 setState, the entire component doesnot get rerendered.
      this.setState({ divs: [], currentDiv: null, toDiv: null, currentInv: null }, () => {
        this.setState({ divs });
      });
    }
    if (this.props.onPosChange) this.props.onPosChange(currentDiv, toDiv, currentEle);
  };

  dragStart = (idx, inv) => {
    this.setState({ currentDiv: idx, currentInv: inv });
  };

  dragEnter = (idx) => {
    this.setState({ toDiv: idx });
  };

  dragDrop = () => {
    this.insertElementBefore();
  };

  render() {
    const { inv } = this.props;
    const { toDiv, currentDiv, currentDown } = this.state;
    let ele = [];
    
    for (let i = 0; i < this.state.divs.length; i++) {
      ele.push(
        <DraggableChildComponent
          onMouseDown={(i) => {
            this.setState({ currentDown: i });
          }}
          inv={inv}
          dragStart={(e) => {
            this.dragStart(i, inv);
          }}
          dragEnter={() => this.dragEnter(i)}
          dragEnd={this.dragDrop}
          key={`${inv}_${i}`}
        >
          {i === toDiv &&
            toDiv < currentDiv &&
            currentDown === inv && (
              <Box
                mt={3}
                style={{ padding: '8px 16px', border: '1px solid #d8e1e6', backgroundColor: '#d8e1e6', opacity:0.5, borderRadius: '18px', color: 'transparent' }}
              >
                S
              </Box>
            )}
          {this.state.divs[i]}
          {i === toDiv &&
            toDiv >= currentDiv &&
            currentDown === inv && (
              <Box
                mt={3}
                style={{ padding: '8px 16px', border: '1px solid #d8e1e6', backgroundColor: '#d8e1e6', opacity:0.5, borderRadius: '18px', color: 'transparent' }}
              >
                S
              </Box>
            )}
        </DraggableChildComponent>
      );
    }
    return <React.Fragment>{ele}</React.Fragment>;
  }
}

export default DraggableComponent;
