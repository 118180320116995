export const insuranceCompaniesList = [
  {
    label: 'Aetna',
    value: 'Aetna',
    support: 'full',
  },
  {
    label: 'Cigna',
    value: 'Cigna',
    support: 'full',
  },
  {
    label: 'First Health',
    value: 'First Health',
    support: 'full',
  },
  // {
  //   label: 'Anthem',
  //   value: 'Anthem',
  //   support: 'none',
  // },
  // {
  //   label: 'Blue Cross',
  //   value: 'Blue Cross',
  //   support: 'none',
  // },
  // {
  //   label: 'Blue Shield',
  //   value: 'Blue Shield',
  //   support: 'none',
  // },
  // {
  //   label: 'Blue Cross',
  //   value: 'Blue Cross',
  //   support: 'full',
  // },
  // {
  //   label: 'Blue Shield',
  //   value: 'Blue Shield',
  //   support: 'none',
  // },
  // {
  //   label: 'Blue Cross of CA',
  //   value: 'Blue Cross of CA',
  //   support: 'full',
  // },
  {
    label: 'Blue Shield of CA',
    value: 'Blue Shield of CA',
    support: 'full',
  },
  {
    label: 'Blue Cross Blue Shield',
    value: 'Blue Cross Blue Shield',
    support: 'full',
  },
  {
    label: 'Medicare',
    value: 'Medicare',
    support: 'full',
  },
  {
    label: 'MultiPlan',
    value: 'MultiPlan',
    support: 'full',
  },
  // {
  //   label: 'Centene',
  //   value: 'Centene',
  //   support: 'none',
  // },
  // {
  //   label: 'Cigna',
  //   value: 'Cigna',
  //   support: 'full',
  // },
  {
    label: 'Humana',
    value: 'Humana',
    support: 'full',
  },
  // {
  //   label: 'Kaiser Permanente',
  //   value: 'Kaiser Permanente',
  //   support: 'none',
  // },
  // {
  //   label: 'Molina Healthcare',
  //   value: 'Molina Healthcare',
  //   support: 'none',
  // },
  {
    label: 'UnitedHealthcare',
    value: 'UnitedHealthcare',
    support: 'full',
  },
  {
    label: 'Priority Health',
    value: 'Priority Health',
    support: 'full',
  },
  // {
  //   label: 'WellCare Health Plans',
  //   value: 'WellCare Health Plans',
  //   support: 'none',
  // },
  {
    label: 'Other',
    value: 'Other',
    support: 'noneOther',
  },
];
