import React, { Fragment, useState } from 'react';
import { Flex, Box, Button, Heading, Text, Card } from 'rebass';
import { withWizard } from 'react-albus';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import { BoxButton } from '../../../components/async/SharedComponents';
import { withRouter } from 'react-router';
import withSession from '../../../lib/withSession';
import { useMount } from '../../../utils/hooks/lifecycle';

const SelectedMarkerOut = styled(Flex)`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-top: 1px;

  background: #ffffff;
  border: 2px solid #354e79;
  border-radius: 12px;
  margin-right: 12px;
`;

const SelectedMarkerIn = styled(Flex)`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #354e79;
  transition: transform 100ms ease-in;
  transform: scale(0);
  &.selected {
    transform: scale(1);
  }
`;

export const ChoiceCard = ({ title, text, icon, isSelected, onSelect, learnMore, onLearnMore, subTextUnderlined, insuranceText }) => {
  return (
    <Flex mb={3} flexDirection="column" pb="20px">
      <Card
        p="12px"
        onClick={onSelect}
        style={{
          background: isSelected ? '#E7F0FF' : 'white',
          border: isSelected ? '1px solid #354E79' : '1px solid white',
          borderRadius: '10px',
        }}
      >
        <Flex flexDirection="row">
          <SelectedMarkerOut>
            <SelectedMarkerIn className={isSelected ? 'selected' : ''} />
          </SelectedMarkerOut>

          <Flex flexDirection="column" flex={1}>
            <Text fontSize="18px" fontWeight="600" mb="8px">
              {title}
            </Text>
            <Flex flexDirection="row">
              <Text fontSize="14px" fontWeight="300" mr="8px">
                {text}
              </Text>
            </Flex>
            {!!subTextUnderlined ? (
              <Flex flexDirection="row">
                <Text fontSize="14px" mt={3} style={{ textDecoration: 'underline' }} fontWeight="300" mr="8px">
                  {subTextUnderlined}
                </Text>
              </Flex>
            ) : null}
          </Flex>
          {icon && (
            <Flex
              flexDirection="column"
              ml={3}
              style={{
                maxWidth: '100px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={icon} width="100px" alt="" style={{ objectFit: 'contain', flex: 1 }} />

              <Box
                flex={1}
                justifyContent="center"
                alignItems="center"
                // textAlign="center"
              >
                <Text fontWeight="600" fontSize="12px">
                  {insuranceText}
                </Text>
              </Box>
            </Flex>
          )}
        </Flex>
      </Card>
      {learnMore && (
        <Text
          fontWeight="300"
          fontSize="12px"
          style={{
            textDecoration: 'underline',
            textTransform: 'uppercase',
            cursor: 'pointer',
            textAlign: 'right',
            marginTop: '8px',
          }}
          onClick={onLearnMore}
        >
          LEARN MORE
        </Text>
      )}
    </Flex>
  );
};

const DualChoice = ({ onSubmit, data, onLearnMore, getState, history, session }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOther, setIsOther] = useState(null);
  const [withInsurance, setWithInsurance] = useState(null);
  useMount(() => {
    console.log('Mount Visit Choice');
    const state = getState();
    // console.log('Mount Visit Choice', state);
    // if (session && session.isMember && session.membershipSpecific === 'insurance') {
    //   setWithInsurance(true);
    //   setIsOther(false);
    // } else {
      if (
        !state ||
        !state.values ||
        !state.values.find((x) => x.id === 'insurancecompany') ||
        !state.values.find((x) => x.id === 'insurancecompany').values ||
        !state.values.find((x) => x.id === 'insurancecompany').values.answer
      ) {
        setWithInsurance(false);
        setIsOther(false);
        return;
      } else {
        setIsOther(state && state.values.find((x) => x.id === 'insurancecompany').values.answer.toLowerCase() === 'other');
        setWithInsurance(state.canLive);
      }
    // }
    // setWithInsurance(!!state || !!state.canLive);
  });
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
        {data.title ? (
          <Box m={2} mt="0" mb={3}>
            <Heading textAlign="left">{data.title}</Heading>
          </Box>
        ) : null}
        {data.subTitle ? (
          <Box m={2} mt="0" mb={3}>
            <Text textAlign="left" fontSize="16px" fontWeight="300" lineHeight="24px">
              {data.subTitle}
            </Text>
          </Box>
        ) : null}
        <Box m={2} mt="0">
          <Heading textAlign="left" fontSize="20px">
            {data.prompt}
          </Heading>
        </Box>

        {data.options
          .filter((x) => {
            if (!!x.hideIfMember && !!session.isMember) return false;

            return true;
          })
          .map((option) => {
            return (
              <ChoiceCard
                key={option.value}
                onSelect={() => setSelectedOption(option.value)}
                isSelected={selectedOption === option.value}
                title={option.title}
                text={option.text}
                icon={option.icon}
                subTextUnderlined={option.subTextUnderlined}
                learnMore={option.learnMore}
                onLearnMore={() => onLearnMore(option.learnMore)}
                insuranceText={withInsurance && !isOther ? option.withInsuranceText : option.withoutInsuranceText}
              />
            );
          })}

        <Box style={{ flex: 1 }} mt={3} />

        <BoxButton className={selectedOption ? 'visible' : ''}>
          <Button
            variant="primary"
            onClick={() => {
              const opt = data.options.find((x) => x.value === selectedOption);
              const state = getState() || {};
              console.log({
                o: opt.goTo,
                oti: state.oneTimeInsurance,
                l: state.canLive,
              });
              if (opt.goTo) {
                if (opt.goTo === '/schedule') {
                  if (state.onFileOnsurance) {
                    history.push({
                      pathname: '/schedule/pickDoctor',
                      state: { forceUseOnFile: true, onFileOnsurance: state.onFileOnsurance },
                    });
                    return;
                  } else {
                    if (!!state.oneTimeInsurance && state.canLive) {
                      const { firstName, lastName, dob } = session;
                      history.push({
                        pathname: '/schedule/pickDoctor',
                        state: {
                          forceNewInsurance: true,
                          newInsurance: {
                            subscriberFullName: `${firstName} ${lastName}`,
                            subscriberDob: dob,
                            planType: state.oneTimeInsurance.type,
                            // conversationId:
                            //   res.data.sendInsuranceInitial.conversationId,
                          },
                          selectedProvider: {
                            value: state.oneTimeInsurance.provider,
                          },
                        },
                      });
                      return;
                    }
                    history.push({
                      pathname: '/schedule/pickDoctor',
                    });
                    return;
                  }
                } else {
                  window.location = opt.goTo;
                }
                return;
              }
              onSubmit(selectedOption);
            }}
            width={[1, 1 / 2, 1 / 4]}
            style={{
              marginLeft: '12px',
              marginRight: '12px',
            }}
          >
            Continue
          </Button>
        </BoxButton>
      </Flex>
    </Fragment>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager,
  withRouter,
  withSession
)(DualChoice);
