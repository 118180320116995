import { Form, Formik } from 'formik';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Button, Flex } from 'rebass';
import CheckboxInput from '../../../components/CheckboxInput';
import FormField from '../../../components/FormField';
import TextInput from '../../../components/TextInput';
import { ADMIN_GET_THYROID_PROGRAMS } from '../../../graphql';
import gql from 'graphql-tag';


const ADMIN_CREATE_THYROID_PROGRAMS = gql`
  mutation adminDuplicateAipProgram(
    $name: String!
    $description: String
    $icon: String
    $bgMainColor: String!
    $bgBubblesColor: String!
    $titleColor: String!
    $descriptionColor: String!
    $isActive: Boolean
    $isArchived: Boolean
    $isPro: Boolean
    $memberOnly: Boolean
    $assignedOnly: Boolean
  ) {
    adminDuplicateAipProgram(
      name: $name
      description: $description
      icon: $icon
      bgMainColor: $bgMainColor
      bgBubblesColor: $bgBubblesColor
      titleColor: $titleColor
      descriptionColor: $descriptionColor
      isActive: $isActive
      isArchived: $isArchived
      isPro: $isPro
      memberOnly: $memberOnly
      assignedOnly: $assignedOnly
    ) {
      id
    }
  }
`;


const DuplicateAipProgram = ({ onSave, onClose, initialValues }) => {
  return (
    <Mutation
      mutation={ADMIN_CREATE_THYROID_PROGRAMS}
      refetchQueries={[{ query: ADMIN_GET_THYROID_PROGRAMS }]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        onSave();
      }}
    >
      {(createPlan, { loading }) => (
        <Formik
          isInitialValid={
            ({ initialValues }) => true
            //   schema.isValidSync(initialValues)
          }
          initialValues={{...initialValues}}
          // validationSchema={schema}
          onSubmit={async (values, actions) => {
            let vars = {
              name: values.name,
              description: values.description,
              icon: values.icon,
              bgMainColor: values.bgMainColor,
              bgBubblesColor: values.bgBubblesColor,
              titleColor: values.titleColor,
              descriptionColor: values.descriptionColor,
              isActive: values.isActive,
              isArchived: values.isArchived,
              isPro: values.isPro,
              memberOnly: values.memberOnly,
              assignedOnly: values.assignedOnly
            };

            await createPlan({
              variables: {
                ...vars,
              },
            });
            // submitCheck(values, actions)
          }}
          render={({ touched, errors, isValid, handleChange, handleBlur, isSubmitting, setFieldValue, setFieldTouched, values }) => (
            <Form>
              <Flex flexDirection="column" width="400px" style={{ maxHeight: '900px' }}>
                <FormField mb={4} error={touched.name && errors.name}>
                  <label for="name">Name</label>
                  <TextInput name="name" placeholder="Name" />
                </FormField>
                <FormField mb={4} error={touched.description && errors.description}>
                  <label for="description">Description</label>
                  <TextInput name="description" placeholder="Description" />
                </FormField>

                <FormField mb={4} error={touched.phone && errors.phone}>
                  <label for="icon">Icon</label>
                  <TextInput name="icon" placeholder="Icon" />
                </FormField>

                <FormField mb={4} error={touched.bgMainColor && errors.bgMainColor}>
                  <label for="bgMainColor">Screen Background Color</label>
                  <TextInput name="bgMainColor" placeholder="Screen Background Color" />
                </FormField>

                <FormField mb={4} error={touched.bgBubblesColor && errors.bgBubblesColor}>
                  <label for="bgBubblesColor">Hormones Background Color</label>
                  <TextInput name="bgBubblesColor" placeholder="Hormones Background Color" />
                </FormField>

                <FormField mb={4} error={touched.titleColor && errors.titleColor}>
                  <label for="titleColor">Title Color</label>
                  <TextInput name="titleColor" placeholder="Title Color" />
                </FormField>

                <FormField mb={4} error={touched.titleColor && errors.titleColor}>
                  <label for="descriptionColor">Description Color</label>
                  <TextInput name="descriptionColor" placeholder="Description Color" />
                </FormField>

                <FormField mb={4} error={touched.phone && errors.phone}>
                  <label for="assignedOnly">Accessible only to patients assigned by HC</label>
                  <CheckboxInput
                    name="assignedOnly"
                    styles={{
                      checked: {
                        Box: {
                          backgroundColor: 'rgb(54, 79, 121)',
                        },
                      },
                    }}
                  />
                </FormField>
                <FormField mb={4} error={touched.phone && errors.phone}>
                  <label for="isActive">Is Active</label>
                  <CheckboxInput
                    name="isActive"
                    styles={{
                      checked: {
                        Box: {
                          backgroundColor: 'rgb(54, 79, 121)',
                        },
                      },
                    }}
                  />
                </FormField>

                <Flex mt={4} flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Button
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    // disabled={!isValid}
                    disabled={!values || !values.name || !values.bgMainColor || !values.bgBubblesColor || !values.titleColor || !values.descriptionColor}
                    style={{ background: '#344f79' }}
                    onClick={() => {
                      // createCategory();
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )}
        />
      )}
    </Mutation>
  );
};

export default DuplicateAipProgram;
