import React from 'react';
import { Button, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import visaIcon from '@iconify/icons-logos/visa';
import mcIcon from '@iconify/icons-logos/mastercard';
import amexIcon from '@iconify/icons-logos/amex';
import discoverIcon from '@iconify/icons-logos/discover';
import creditCard from '@iconify/icons-entypo/credit-card';
import { isMobile } from 'react-device-detect';
import { Mutation } from 'react-apollo';
import { MEMBERSHIP_UPDATE_PAYMENT_CREATE_SESSION } from '../../../graphql';
import { loadStripe } from '@stripe/stripe-js';

const Wrapper = styled(Flex)`
  flex-direction: column;
  flex: 1;

  padding: 12px 24px;
`;
const Header = styled(Flex)`
  flex-direction: row;
  flex: 1;
`;
const ActionsWrapper = styled(Flex)`
  flex-direction: column;
`;
const ChildrenContainer = styled(Flex)``;

export const Section = ({
  title,
  icon,
  card,
  children,
  isExpanded,
  setIsExpanded,
data,
  isMembership,
}) => {
  const last4 = (card || {}).last4;
  const cardBrand = (card || {}).brand;
  let logo = creditCard;
  switch ((cardBrand || '').toUpperCase()) {
    case 'VISA':
      logo = visaIcon;
      break;
    case 'MC':
    case 'MASTERCARD':
    case 'MASTER CARD':
      logo = mcIcon;
      break;
    case 'AX':
    case 'AMEX':
    case 'AMERICANEXPRESS':
    case 'AMERICAN EXPRESS':
      logo = amexIcon;
      break;
    case 'DISCO':
    case 'DISCOVER':
      logo = discoverIcon;
      break;

    default:
      logo = creditCard;
      break;
  }
  return (
    <Wrapper>
      <Header>
        <img src={icon} style={{ width: '79px', display: isMobile ? 'none' : '', marginRight: '24px' }} alt="" />
        <Text fontSize="18px" fontWeight="600" flex={1} style={{ alignItems: 'center', display: 'flex' }}>
          {title}
        </Text>
        <ActionsWrapper>
          {last4 ? (
            <Flex flexDirection="row" alignItems="center">
              <Icon icon={logo} />
              <Text fontSize="18px" ml="12px" fontWeight="300">
                {last4}
              </Text>
            </Flex>
          ) : (
            <Flex flexDirection="row" alignItems="center">
              <Text fontSize="18px" ml="12px" fontWeight="300">
                No card
              </Text>
            </Flex>
          )}
          {!!isMembership ? (
            <Mutation
              mutation={MEMBERSHIP_UPDATE_PAYMENT_CREATE_SESSION}
              variables={{
                subscriptionId: data.id,
                customerId: data.customer,
                forwardUrl: 'subscription/update/success',
                forwardUrlCancel: 'my-payment-methods',
              }}
              onCompleted={async (res) => {
                const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_KEY);
                const stripe = await stripePromise;
                await stripe.redirectToCheckout({
                  sessionId: res.createUpdatePaymentMethodSession.sessionId,
                });
              }}
            >
              {(createSession, { loading }) => (
                <Button
                  style={{ padding: '10px', minWidth: '128px' }}
                  variant="primary"
                  onClick={() => {
                    if (loading) return;
                    createSession();
                  }}
                >
                  Edit
                </Button>
              )}
            </Mutation>
          ) : (
            <Button style={{ padding: '10px', minWidth: '128px' }} variant="primary" onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? 'Cancel' : 'Edit'}
            </Button>
          )}
        </ActionsWrapper>
      </Header>
      {isExpanded && <ChildrenContainer>{children}</ChildrenContainer>}
    </Wrapper>
  );
};
