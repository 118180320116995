import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal, { BaseModalBackground } from 'styled-react-modal';
import { Box, Button, Heading, Text, Flex } from 'rebass';
import styled from 'styled-components';
import { useIdleTimer } from 'react-idle-timer';
import { Constants } from '../utils/constants';
import { withRouter } from 'react-router';

const SpecialModalBackground = styled(BaseModalBackground)`
  backdrop-filter: blur(5px);
`;
const SessionTimeout = ({ logout, heartbeat, history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [lastTouch, setLastTouch] = useState();
  const [isAutoBot, setIsAutobot] = useState(false);
  const [startAutobotTime, setStartAutoBotTime] = useState(null);
  const [intervalCount, setIntervalCount] = useState(null);
  let interval = null;
  useEffect(() => {
    // console.log('UE', window.location.href);
    if (window.location.href.indexOf('/visit-') > -1) {
      // console.log('UE in', history);
      setIsAutobot(true);
    } else {
      // console.log('Clear1')
      setIsAutobot(false);
      setStartAutoBotTime(null);
    }
    return () => {
      if (interval) {
        // console.log('Clear2')
        clearInterval(interval);
        setStartAutoBotTime(null);
      }
    };
  }, []);
  useEffect(
    () => {
      if (history.location.pathname.indexOf('/visit-') > -1) {
        // console.log('UE in', history);
        setIsAutobot(true);
      } else {
        // console.log('Clear1')
        setIsAutobot(false);
        setStartAutoBotTime(null);
      }
    },
    [history.location]
  );

  useEffect(
    () => {
      if (isAutoBot) {
        // Console.log('ue isAutoBot');
        setStartAutoBotTime(moment().toDate());
        touching();
        interval = setInterval(()=>{
          // console.log('elapsed')
          setIntervalCount(moment())
          
        }, 5 * 3 * 1000);
      } else {
        // console.log('Clear')
        clearInterval(interval);
        setStartAutoBotTime(null);
      }
    },
    [isAutoBot]
  );

  useEffect(
    () => {
      // console.log({intervalCount})
      if(!intervalCount) return
      touching();
    },
    [intervalCount]
  );

  const handleOnIdle = (event) => {
    if (!isAutoBot) {
      setIsOpen(true);
    }
  };

  const handleOnActive = (event) => {};

  const touching = async () => {
    // console.log('touching', startAutobotTime);
    if (!!startAutobotTime && moment().isAfter(moment(startAutobotTime).add(45, 'm'))) {
      // console.log('touching after', startAutobotTime);
      setIsOpen(true);
      return;
    }
    await heartbeat();
    setLastTouch(moment().unix());
  };

  const handleOnAction = async (event) => {
    if (!lastTouch || moment.unix(lastTouch).isBefore(moment().add(-5, 'minute'))) {
      await heartbeat();
      setLastTouch(moment().unix());

      if (isAutoBot) {
        setStartAutoBotTime(moment().toDate());
      }
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * Constants.TIMEOUT_SESSION_WARNING_IN_MINUTES,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true,
    },
  });
  const handleClose = async () => {
    setIsOpen(false);
    await heartbeat();
    setLastTouch(moment().unix());
    if (isAutoBot) {
      setStartAutoBotTime(moment().toDate());
    }
  };

  if (!isOpen) {
    return null;
  }

  return <ModalTimeout handleClose={handleClose} logout={logout} />;
};

const ModalTimeout = ({ logout, handleClose }) => {
  useEffect(() => {
    let to = setTimeout(() => {
      logout();
    }, 1000 * 60 * (Constants.TIMEOUT_SESSION_DURATION_IN_MINUTES - Constants.TIMEOUT_SESSION_WARNING_IN_MINUTES));

    return () => clearTimeout(to);
  }, []);

  return (
    <Modal isOpen={true} onBackgroundClick={handleClose} onEscapeKeydown={handleClose}>
      {/* <SpecialModalBackground> */}
        <Box
          style={{
            margin: '0 auto',
            alignSelf: 'center',
            borderRadius: '20px',
            position: 'relative',
            backgroundColor: 'white',
            padding: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Heading mb={3}>Are you still here?</Heading>
          <Text mb={4}>Your session is about to expire. Close this modal to stay active.</Text>
          <Flex flexDirection={'row'} justifyContent="space-between" width={'100%'}>
            <Button variant={'pink'} onClick={logout} mt={4}>
              Log Me Out
            </Button>
            <Button variant={'primary'} onClick={handleClose} mt={4}>
              Continue
            </Button>
          </Flex>
          <Box
            onClick={handleClose}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: '-10px',
              right: '-10px',
              height: '34px',
              width: '34px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#F8A294',
              padding: '6px',
              borderRadius: '50%',
              color: 'white',
            }}
          >
            X
          </Box>
        </Box>
      {/* </SpecialModalBackground> */}
    </Modal>
  );
};

export default withRouter(SessionTimeout);
