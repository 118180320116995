import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from './ControlProvider';
import { animatedScrollTo } from '../utils/animated-scroll-to';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import { sleep } from '../../../utils/sleep';

const Progress = styled(Box)`
  transition: all 0.3s ease;
`;

class FullPage extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    initialSlide: PropTypes.number,
    snap: PropTypes.bool,
    duration: PropTypes.number,
    beforeChange: PropTypes.func,
    afterChange: PropTypes.func,
    offset: PropTypes.number,
    maxViewedSlide: PropTypes.number,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        cantBypass: PropTypes.bool,
      })
    ),
    hideProgressBar: PropTypes.bool,
  };

  static defaultProps = {
    className: 'snap-container',
    initialSlide: 0,
    snap: true,
    duration: 700,
    offset: 0,
    sensitivity: 5,
    maxViewedSlide: 0,
    beforeChange: ({ from, to }) => {},
    afterChange: ({ from, to }) => {},
    questions: [],
    hideProgressBar: false,
  };

  constructor(props) {
    super(props);
    this.myRef = null;
    this.newTouch = false;
    this.scrolling = false;
    this.slidesCount = React.Children.toArray(props.children).filter((child) => {
      return child.type.displayName !== 'ControlledComponent';
    }).length;
    this.slideElements = React.Children.toArray(props.children).filter((child) => {
      return child.type.displayName !== 'ControlledComponent';
    });
    this.touchStart = 0;

    this._isNewScrollAction = true;
    this._wheel = { increasing: true, lastDeltaY: 0 };
    this.state = {
      activeSlide: props.initialSlide,
      isWaitingInput: false,
    };
  }

  componentDidMount() {
    document.addEventListener('touchmove', this.onTouchMove, { passive: false });
    document.addEventListener('touchstart', this.onTouchStart, { passive: false });
    document.addEventListener('touchend', this.onTouchEnd);
    document.addEventListener('wheel', this.onScroll, { passive: false });
    window.addEventListener('resize', this.setHeights);
    document.addEventListener('keydown', this.onKeyPress);
    this.setHeights();
    document.getElementsByTagName('html')[0].style = 'overscroll-behavior-y: contain';
    document.getElementsByTagName('body')[0].style = 'overscroll-behavior-y: contain';

    this.resumeInitial();
  }

  componentWillUnmount() {
    document.removeEventListener('touchmove', this.onTouchMove, { passive: false });
    document.removeEventListener('touchstart', this.onTouchStart, { passive: false });
    document.removeEventListener('touchend', this.onTouchEnd);
    document.removeEventListener('wheel', this.onScroll, { passive: false });
    window.removeEventListener('resize', this.setHeights);

    document.removeEventListener('keydown', this.onKeyPress);
    document.getElementsByTagName('html')[0].style = 'overscroll-behavior-y: auto';
    document.getElementsByTagName('body')[0].style = 'overscroll-behavior-y: auto';
  }

  resumeInitial = async () => {
    await sleep(110);
    if (this.props.initialSlide) {
      this.setState(
        {
          activeSlide: this.props.initialSlide,
        },
        this.scrollToSlide(this.props.initialSlide)
      );
    }
  };

  setHeights = () => {
    const { offset } = this.props;
    if (!this.scrolling) {
      this.setState({ height: window.innerHeight - offset });
    } else {
      setTimeout(() => {
        this.setHeights();
      }, 90);
    }
  };

  scrollToSlide(slide) {
    const { duration, beforeChange, afterChange } = this.props;
    const { activeSlide, height } = this.state;
    if (!this.scrolling && slide >= 0 && slide < this.slidesCount) {
      this.newTouch = false;
      const currentSlide = activeSlide;
      beforeChange({ from: currentSlide, to: slide });
      this.setState({ activeSlide: slide });

      this.scrolling = true;
      animatedScrollTo(height * slide, duration, this.myRef, () => {
        this.scrolling = false;
        afterChange({ from: currentSlide, to: slide });
      });
    }
  }

  onTouchStart = (e) => {
    const { snap } = this.props;
    if (snap) {
      this.touchStart = e.touches[0].clientY;
    }
  };
  onTouchEnd = () => {
    if (this.props.snap) {
      this.newTouch = true;
    }
  };

  onTouchMove = (e) => {
    const { sensitivity, snap } = this.props;
    if (snap) {
      if (!!isMobile || !this.getCanBypass()) return;
      e.preventDefault();
      const touchEnd = e.changedTouches[0].clientY;
      if (!this.scrolling && this.newTouch) {
        if (this.touchStart > touchEnd + sensitivity) {
          this.scrollToSlide(this.state.activeSlide + 1);
        } else if (this.touchStart < touchEnd - sensitivity) {
          this.scrollToSlide(this.state.activeSlide - 1);
        }
      }
    }
  };

  onKeyPress = (e) => {
    if ((e.keyCode === 38 || e.keyCode === 40) && this.props.snap) {
      e.preventDefault();
      if (e.keyCode === 38) {
        this.scrollToSlide(this.state.activeSlide - 1);
      }
      if (e.keyCode === 40) {
        this.scrollToSlide(this.state.activeSlide + 1);
      }
    }
  };

  onScroll = (e) => {
    if (!this.props.snap) {
      return;
    }
    if (!!isMobile) return;
    e.preventDefault();
    if (this.scrolling || !this.getCanBypass()) {
      return false;
    }

    const deltaY = Math.abs(e.deltaY);
    if (deltaY > this._wheel.lastDeltaY && !this._wheel.increasing) {
      this._wheel.increasing = true;
      this._isNewScrollAction = true;
    } else {
      this._isNewScrollAction = false;
      if (deltaY < this._wheel.lastDeltaY) {
        this._wheel.increasing = false;
      }
    }
    this._wheel.lastDeltaY = deltaY;

    if (!this._isNewScrollAction) {
      return;
    }

    const scrollDown = (e.wheelDelta || -e.deltaY || -e.detail) < 0;
    let activeSlide = this.state.activeSlide;

    if (scrollDown) {
      activeSlide++;
    } else {
      activeSlide--;
    }
    this.scrollToSlide(activeSlide);
  };

  waitingInput(value) {
    this.setState({
      isWaitingInput: value,
    });
  }

  scrollNext() {
    this.scrollToSlide(this.state.activeSlide + 1);
  }

  scrollPrev() {
    this.scrollToSlide(this.state.activeSlide - 1);
  }

  getSlidesCount() {
    return this.slidesCount;
  }

  getCurrentIndex() {
    return this.state.activeSlide;
  }

  getCanBypass() {
    const { questions, maxViewedSlide, data } = this.props;
    const { isWaitingInput } = this.state;
    return !isWaitingInput
      ? !(questions[this.state.activeSlide] || {}).cantBypass || (data || []).findIndex((x) => x.id === (questions[this.state.activeSlide] || {}).id) > -1 || maxViewedSlide > this.state.activeSlide
      : false;
  }

  render() {
    const controls = {
      scrollToSlide: this.scrollToSlide.bind(this),
      scrollNext: this.scrollNext.bind(this),
      scrollPrev: this.scrollPrev.bind(this),
      getSlidesCount: this.getSlidesCount.bind(this),
      getCurrentIndex: this.getCurrentIndex.bind(this),
    };

    const { questions, hideProgressBar, offset, maxViewedSlide } = this.props;

    const canBypass = this.getCanBypass();

    return (
      <>
        <Provider {...controls}>
          <div className={this.props.className} ref={(ref) => (this.myRef = ref)} style={{ height: this.state.height, width: '100%', position: 'fixed', overflow: 'hidden', top: offset }}>
            {this.props.children}
          </div>
        </Provider>
        {!hideProgressBar && (
          <Flex
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
            }}
          >
            <Flex style={{ width: '100%', height: '6px', position: 'relative' }}>
              <Progress
                style={{
                  width: `${((maxViewedSlide + 1) / this.slidesCount) * 100}%`,
                  height: '6px',
                  background: 'rgb(95, 118, 154)',
                  position: 'absolute',
                  opacity: 0.3,
                  left: 0,
                  top: 0,
                }}
              />
              <Progress
                style={{
                  width: `${((this.state.activeSlide + 1) / this.slidesCount) * 100}%`,
                  height: '6px',
                  background: 'rgb(95, 118, 154)',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                }}
              />
              <Flex style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, opacity: 0.3}}>
                <Flex style={{position: 'relative', width: '100%', height:'6px'}}>
                {questions.map((x, i)=>{
                  return (
                    <Flex
                    key={`q-${i}`} flex={1} style={{borderRight: '2px solid #fdf5f2', height: '6px', cursor: i<=maxViewedSlide?'pointer':'not-allowed'}} data-tip={`Question ${i+1}/${questions.length}`} data-type={null} onClick={()=>{
                      if(i<=maxViewedSlide) {
                        this.scrollToSlide(i)
                      }
                    }}/>
                  )
                })}
                </Flex>
              </Flex>
            </Flex>
          <ReactTooltip place="bottom" type="light" effect="float" />
          </Flex>
        )}
        <Flex
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
          }}
        >
          {this.state.activeSlide > 0 && (
            <Box
              style={{ border: '1px solid #22272e', background: '#e5e5e5', padding: '8px 12px', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={() => controls.scrollPrev()}
            >
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z" fill="black" />
              </svg>
            </Box>
          )}
          {this.state.activeSlide < questions.length - 1 && (
            <Box
              style={{
                border: '1px solid #22272e',
                background: '#e5e5e5',
                padding: '8px 12px',
                cursor: canBypass ? 'pointer' : 'not-allowed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: canBypass ? 1 : 0.33,
              }}
              onClick={() => {
                if (canBypass) {
                  controls.scrollNext();
                }
              }}
            >
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.41 8.57999L12 13.17L16.59 8.57999L18 9.99999L12 16L6 9.99999L7.41 8.57999Z" fill="black" />
              </svg>
            </Box>
          )}
        </Flex>
      </>
    );
  }
}

export default FullPage;
