import React, { useState } from 'react';
import { Box, Card, Flex, Heading, Button, Text } from 'rebass';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import FormField from '../components/FormField';
import TextInput from '../components/TextInput';
import { MaskInput } from '../components/MaskInput';
import withSession from '../lib/withSession';
import { compose, Query, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { CREATE_PROVIDER_USER } from '../graphql';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import gql from 'graphql-tag';
import ConfirmModal from '../components/ConfirmModal';

const CardBox = styled(Card)`
  width: 100%;
  padding: 32px;
`;
const Container = styled(Box)`
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
  padding-top: 20px;
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;
const InfoBox = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${(props) => props.theme.color.darkBlue};
  justify-content: center;
`;

const GET_PROVIDER_USERS = gql`
  query getProviderUsersFromAdmin {
    getProviderUsersFromAdmin {
      id
      email
      phone
      helpscoutId
      isOnboarded
    }
  }
`;

const EDIT_PROVIDER_USER = gql`
  mutation adminProviderEdit($id:String!, $email: String!, $phone: String!, $helpscoutId: Int!) {
    adminProviderEdit(id:$id, email: $email, phone:  $phone, helpscoutId: $helpscoutId) {
      ok
    }
  }
`;

const DELETE_PROVIDER_USER = gql`
  mutation adminProviderDelete($id:String!) {
    adminProviderDelete(id:$id) {
      ok
    }
  }
`;

const CreateProviderUser = ({ toastManager, client }) => {
  const [deleteModal, setDeleteModal] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false)
  const [editModal, setEditModal] = useState(null);
  const handleUpdate = async (values, actions) => {
    actions.setSubmitting(true);
    let helpscoutId;
    try {
      helpscoutId = parseInt(values.helpscoutId);
    } catch (error) {
      toastManager.add('Helpscout Id must be an integer', {
        appearance: 'error',
      });
      return;
    }
    if (!helpscoutId) {
      toastManager.add('Helpscout Id must be an integer', {
        appearance: 'error',
      });
      return;
    }
    console.log({});
    try {
      await client.mutate({
        mutation: CREATE_PROVIDER_USER,
        variables: {
          email: values.email,
          phone: values.phone,
          helpscoutId: helpscoutId,
        },
      });

      actions.setSubmitting(false);
      //   actions.resetForm();
      toastManager.add('Provider created successfully', { appearance: 'success' });
    } catch (err) {
      console.log('ERR', err);
      actions.setSubmitting(false);
      toastManager.add('Error creating, please try again', {
        appearance: 'error',
      });
    }
  };

  const editUser = (user) => {
    setDeleteModal(null);
    setEditModal(user);
  };

  const deleteUser = (user) => {
    setDeleteModal(user);
    setEditModal(null);
  };

  const handleEdit = async(values, actions) => {
    actions.setSubmitting(true);
    let helpscoutId;
    try {
      helpscoutId = parseInt(values.helpscoutId);
    } catch (error) {
      toastManager.add('Helpscout Id must be an integer', {
        appearance: 'error',
      });
      return;
    }
    if (!helpscoutId) {
      toastManager.add('Helpscout Id must be an integer', {
        appearance: 'error',
      });
      return;
    }
    console.log({});
    try {
      await client.mutate({
        mutation: EDIT_PROVIDER_USER,
        variables: {
          id: editModal.id,
          email: values.email,
          phone: values.phone,
          helpscoutId: helpscoutId,
        },
        refetchQueries: [{ query: GET_PROVIDER_USERS }],
        awaitRefetchQueries: true,
      });

      actions.setSubmitting(false);
      //   actions.resetForm();
      toastManager.add('Provider created successfully', { appearance: 'success' });
      setEditModal(null)
    } catch (err) {
      console.log('ERR edit', err);
      actions.setSubmitting(false);
      toastManager.add('Error editing, please try again', {
        appearance: 'error',
      });
    }
  };

  const handleDelete = async() => {
    setIsDeleting(true);
    try {
      await client.mutate({
        mutation: DELETE_PROVIDER_USER,
        variables: {
          id: deleteModal.id,
        },
        refetchQueries: [{ query: GET_PROVIDER_USERS }],
        awaitRefetchQueries: true,
      });

      setIsDeleting(false);
      //   actions.resetForm();
      toastManager.add('Provider deleted', { appearance: 'success' });
      setDeleteModal(null)
    } catch (err) {
      console.log('ERR edit', err);
      setIsDeleting(false);
      toastManager.add('Error deleting, please try again', {
        appearance: 'error',
      });
    }
  };

  const userColumns = [
    {
      Header: 'email',
      accessor: 'email',
    },
    {
      Header: 'phone',
      accessor: 'phone',
    },
    {
      Header: 'helpscout Id',
      accessor: 'helpscoutId',
    },
    {
      Header: 'is onboarded',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div title="delete" style={{ cursor: 'pointer' }}>
              {row.original.isOnboarded ? 'Yes' : 'No'}
            </div>
          </Flex>
        );
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div title="delete" style={{ cursor: 'pointer' }} onClick={() => editUser(row.original)}>
              ✎
            </div>
            <div title="delete" style={{ cursor: 'pointer', marginLeft: '16px' }} onClick={() => deleteUser(row.original)}>
              🗑
            </div>
          </Flex>
        );
      },
    },
  ];
  return (
    <Container>
      <CardBox mb={4}>
        <Flex flexDirection="column">
          <Box>
            <Heading textAlign="left" mb={4}>
              Create Provider User
            </Heading>
            <Formik
              initialValues={{
                email: '',
                phone: '',
                helpscoutId: null,
              }}
              // validationSchema={schema}
              onSubmit={(values, actions) => handleUpdate(values, actions)}
              render={({ touched, errors, isValid, values, isSubmitting, handleChange, handleBlur }) => (
                <Form>
                  <FormField mb={3} error={touched.email && errors.email}>
                    <TextInput placeholder="Email" type="email" name="email" />
                  </FormField>

                  <FormField mb={4} error={touched.phone && errors.phone}>
                    <MaskInput name="phone" placeholder="Mobile Phone Number" mask="999-999-9999" value={values.phone} onChange={handleChange} onBlur={handleBlur} />
                  </FormField>

                  <FormField mb={3} error={touched.helpscoutId && errors.helpscoutId}>
                    <TextInput placeholder="helpscoutId" type="text" name="helpscoutId" />
                  </FormField>

                  <Flex alignItems="center" flexDirection="column">
                    <Button mb={4} disabled={!isValid || isSubmitting} variant="primary" width="200px" type="submit">
                      Create
                    </Button>
                  </Flex>
                </Form>
              )}
            />
          </Box>
          <Query query={GET_PROVIDER_USERS}>
            {({ loading, error, data }) => {
              if (loading) return null;
              return <ReactTable data={data.getProviderUsersFromAdmin} columns={userColumns} />;
            }}
          </Query>
        </Flex>
      </CardBox>
      {!!deleteModal && (
        <ConfirmModal isOpen={!!deleteModal} 
        onClose={() => setDeleteModal(null)} 
        onBackgroundClick={() => setDeleteModal(null)} 
        onConfirm={handleDelete}
        disabled={isDeleting}
        confirmLabel="Delete">
          <ModalInner>
            <Heading mb={4} textAlign="center">
              Delete Provider User
            </Heading>

            <InfoBox bg="#D9E6F9" mb={4}>
              <Text fontSize={3} fontWeight={700} textAlign="center">
                Are you sure you want to delete {deleteModal.email}
              </Text>
            </InfoBox>
          </ModalInner>
        </ConfirmModal>
      )}
      {!!editModal && (
        <ConfirmModal childrenManaged={true}
        displayX={true} isOpen={!!editModal} onClose={() => setEditModal(null)} onBackgroundClick={() => setEditModal(null)} onConfirm={async () => {}}>
          <ModalInner>
            <Heading mb={4} textAlign="center">
              Edit Provider User
            </Heading>

            <InfoBox bg="#D9E6F9" mb={4} style={{ minWidth: '450px' }}>
              <Formik
                initialValues={{
                  email: editModal.email,
                  phone: editModal.phone,
                  helpscoutId: editModal.helpscoutId,
                }}
                // validationSchema={schema}
                onSubmit={(values, actions) => handleEdit(values, actions)}
                render={({ touched, errors, isValid, values, isSubmitting, handleChange, handleBlur }) => (
                  <Form style={{ width: '100%' }}>
                    <FormField mb={3} error={touched.email && errors.email}>
                      <TextInput placeholder="Email" type="email" name="email" />
                    </FormField>

                    <FormField mb={4} error={touched.phone && errors.phone}>
                      <MaskInput name="phone" placeholder="Mobile Phone Number" mask="999-999-9999" value={values.phone} onChange={handleChange} onBlur={handleBlur} />
                    </FormField>

                    <FormField mb={3} error={touched.helpscoutId && errors.helpscoutId}>
                      <TextInput placeholder="helpscoutId" type="text" name="helpscoutId" />
                    </FormField>

                    <Flex alignItems="center" flexDirection="column">
                      <Button mb={4} disabled={!isValid || isSubmitting} variant="primary" width="200px" type="submit">
                        Update
                      </Button>
                    </Flex>
                  </Form>
                )}
              />
            </InfoBox>
          </ModalInner>
        </ConfirmModal>
      )}
    </Container>
  );
};

export default compose(
  withApollo,
  withSession,
  withToastManager
)(CreateProviderUser);
