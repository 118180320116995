import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { Query } from 'react-apollo';

import { GET_MEMBERSHIPS } from '../../graphql';
import { Section } from './components/Section';
import subscriptionsKit from '../../static/subscriptionsKit.png';
import subscriptionsVitamin from '../../static/subscriptionsVitamins.png';
import subscriptionsPaloma from '../../static/subscriptionsPaloma.png';
import PleaseWait from '../../components/PleaseWait';
import { withRouter } from 'react-router';
import withSession from '../../lib/withSession';
import { isToggleActive } from '../../components/featureToggle/toggles';

const Wrapper = styled(Flex)`
  flex: 1;
  background: white;
  border-radius: 8px 8px 8px 8px;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0px 2.41029px 24.1029px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 480px) {
    max-width: calc(100vw - 24px);
    margin: 12px;
  }
`;

const Subscriptions = ({ history, session: { isDiagnosed }, ...props }) => {
  const hasNewPriceFeature = isToggleActive('USE_NEW_PRICING');
  return (
    <Query query={GET_MEMBERSHIPS} fetchPolicy="network-only">
      {({ loading, data, error }) => {
        if (loading)
          return (
            <PleaseWait
              style={{ position: 'absolute', left: '50%', top: '50%' }}
            />
          );

        const membershipData = data.getMemberships.memberships.find(
          x => x.subscriptionTarget === 'SUBSCRIPTION_MEMBERSHIP'
        );
        const kitData = data.getMemberships.memberships.find(
          x => x.subscriptionTarget === 'SUBSCRIPTION_KIT'
        );
        const vitaminsData = data.getMemberships.memberships.find(
          x => x.subscriptionTarget === 'SUBSCRIPTION_VITAMINS'
        );

        return (
          <Wrapper>
            {isDiagnosed && (
              <Section
                type="membership"
                isFirst={true}
                imageSource={subscriptionsPaloma}
                title="Paloma Membership"
                priceText={
                  membershipData
                    ? `$${membershipData.price / 100}/${
                        membershipData.interval
                      }`
                    : '$20/month'
                }
                description="Exclusive perks and discounts across Paloma Health products and services"
                data={membershipData}
                subscribe={() => {
                  history.push('/become-member');
                }}
              />
            )}
            <Section
              type="subscription_vitamin"
              imageSource={subscriptionsVitamin}
              title="Vitamin Supplements Subscription"
              priceText={
                vitaminsData
                  ? `$${vitaminsData.price / 100}/month`
                  : 'Learn More'
              }
              description="Our reinvented multivitamins for hypothyroidism and hashimoto’s"
              data={vitaminsData}
              subscribe={() => {
                if (hasNewPriceFeature) {
                  history.push({
                    pathname: '/order-vitamins',
                    state: { defaultSelectSubscribe: true },
                  });
                } else {
                  window.location =
                    'https://www.palomahealth.com/product/thyroid-supplements';
                }
              }}
            />
            <Section
              type="subscription_kit"
              isLast={true}
              imageSource={subscriptionsKit}
              title="Kit subscriptions"
              priceText="$70/quarter"
              description="We help you stay on top of your care for you. Subscribe and save"
              data={kitData}
              subscribe={() => {
                window.location =
                  'https://www.palomahealth.com/kit-subscriptions';
              }}
            />
          </Wrapper>
        );
      }}
    </Query>
  );
};

export default withRouter(withSession(Subscriptions));
