import React, { Children, useState } from 'react';
import { withWizard } from 'react-albus';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Box, Button, Flex, Heading, Link, Text } from 'rebass';
import styled from 'styled-components';
import PleaseWait, { SubscriptionRegistrationComplete } from '../../components/PleaseWait';
import { VIEWED_PAGE } from '../../graphql';
import { useMount } from '../../utils/hooks/lifecycle/useMount';
import { uuidv4 } from '../../utils/uuid';
import { isToggleActive } from '../../components/featureToggle/toggles';
import { Constants } from '../../utils/constants';

const ButtonWrap = styled(Flex)`
  flex-direction: row;
  border-radius: 46px;
  width: 100%;
  margin-bottom: 24px;
  border: 2px solid ${(props) => (props.isChecked ? '#F6A293' : 'rgba(248, 162, 148, 0.3)')};
  padding: 10px;
  cursor: pointer;
`;

const CheckWrap = styled(Box)`
  height: 28px;
  width: 28px;
  border-radius: 14px;
  border: 1px solid ${(props) => (props.isChecked ? '#F6A293' : 'rgba(246, 162, 147, 0.5)')};
  background: ${(props) => (props.isChecked ? '#F6A293' : '#FDF5F2')};
  justify-content: center;
  align-items: center;
  display: flex;
  align-self: center;
  margin-right: 12px;
`;

const BadgeDiscount = styled(Box)`
  width: 120px;
  border-radius: 40px;
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  background: ${(props) => (props.showBadgeDiscount ? '#E7F0FF' : 'transparent')};
`;

const PlanSelectionButton = ({ isChecked, onCheckChange, text, priceText, badgeDiscountText, children }) => (
  <ButtonWrap isChecked={isChecked} onClick={onCheckChange}>
    <CheckWrap isChecked={isChecked}>
      <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.2789 0.357422L14.0209 1.57942L6.46791 12.4734H4.72592L0.513916 6.57142L2.25592 4.94642L5.59691 8.06642L12.2789 0.357422Z" fill="#fdf5f2" />
      </svg>
    </CheckWrap>
    <Flex flexDirection="column" flex={1}>
      {children}
    </Flex>
    {/* <BadgeDiscount showBadgeDiscount={!!badgeDiscountText}>
      <Text style={{ fontWeight: 600, fontSize: '16px' }}>
        {badgeDiscountText}
      </Text>
    </BadgeDiscount> */}
  </ButtonWrap>
);

const SelectPlan = ({
  onSubscribe,
  initialPlan,
  history,
  client,
  setQuadPayData,
  continueWithQuadPay,
  isNewPatientFlow,
  selectedProvider,
  reinitFlow,
  wizard,
  isInNetwork,
  hasInNetwork,
  insuranceMode,
}) => {
  const t = isToggleActive('INSURANCE_MEMBERSHIP_WIND_DOWN');
  const [quadpayOrderReference, setQ] = useState(uuidv4());
  const [isProcessing, setIsProcessing] = useState(false);
  const [subscribedToQuadpay, setSubscribedToQuadpay] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState((isInNetwork && !t ? 'insurance' : initialPlan) || 'annualy');
  useMount(async () => {
    console.log({ selectedProvider, isInNetwork });
    if (!selectedProvider && !!reinitFlow && !!wizard) {
      reinitFlow(wizard);
      return null;
    }
    try {
      window.quadpay.virtualCheckout.onComplete((result) => {
        console.log({result})
        if (!isNewPatientFlow) {
          setIsProcessing(true);
        }
        setQuadPayData(result.card.number, result.card.cvc, result.card.expirationMonth, result.card.expirationYear, quadpayOrderReference, async () => {
          if (!isNewPatientFlow) {
            await continueWithQuadPay();
            setIsProcessing(false);
            // setSubscribedToQuadpay(true);
          }
        });
      });
    } catch (error) {}
    await client.mutate({
      mutation: VIEWED_PAGE,
      variables: {
        screen: 'Membership',
        insuranceLocked: !!isInNetwork,
      },
    });
  });

  if (isProcessing) {
    return <PleaseWait text="Adding your membership" />;
  }

  if (!!subscribedToQuadpay) {
    return (
      <SubscriptionRegistrationComplete>
        <Button variant="primary" onClick={continueWithQuadPay}>
          Continue
        </Button>
      </SubscriptionRegistrationComplete>
    );
  }

  return (
    <Flex flexDirection="column" width="320px" style={{ margin: '0 auto' }}>
      <Heading textAlign="center" style={{ marginBottom: '24px' }}>
        Choose how you'd like to pay
      </Heading>
      {!!insuranceMode && !t ? (
        <>
          <PlanSelectionButton isChecked={selectedPlan === 'insurance'} onCheckChange={() => setSelectedPlan('insurance')}>
            <Text style={{ fontSize: '16px' }}>
              <span style={{ fontWeight: 600 }}>$96</span> upfront with credit card
            </Text>
          </PlanSelectionButton>
          <Button
            mt="24px"
            variant="primary"
            disabled={selectedPlan !== 'annualy' && selectedPlan !== 'monthly' && selectedPlan !== 'insurance'}
            onClick={() => {
              if (onSubscribe) {
                onSubscribe(selectedPlan);
              } else {
              }
            }}
          >
            Next
          </Button>
        </>
      ) : (
        <>
          {!!isInNetwork && !t ? (
            <PlanSelectionButton isChecked={selectedPlan === 'annualy' || isInNetwork} onCheckChange={() => setSelectedPlan('insurance')}>
              <Text style={{ fontSize: '16px' }}>
                <span style={{ fontWeight: 600 }}>$96</span> upfront with credit card
              </Text>
            </PlanSelectionButton>
          ) : (
            <>
              <PlanSelectionButton isChecked={selectedPlan === 'annualy'} onCheckChange={() => setSelectedPlan('annualy')} text="Bill Annually" priceText="$16 / month" badgeDiscountText="Save 20%">
                <Text style={{ fontSize: '16px' }}>
                  <span style={{ fontWeight: 600 }}>{`$${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}`}</span> upfront with credit card
                </Text>
              </PlanSelectionButton>
              <PlanSelectionButton isChecked={selectedPlan === 'monthly'} onCheckChange={() => setSelectedPlan('monthly')} text="" priceText="">
{/* <zip-payment-widget amount= "192" merchantId={window._env_.REACT_APP_QUADPAY_MERCHANTID} > </zip-payment-widget> */}
<quadpay-widget-v3 amount={`${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}`} merchantId={window._env_.REACT_APP_QUADPAY_MERCHANTID} alignment="left" widgetVerbiage="4-easy-payments" />
                {/* <quadpay-widget-v3 amount="192" alignment="left" widgetVerbiage="4-easy-payments" /> */}
              </PlanSelectionButton>
            </>
          )}

          {(selectedPlan === 'annualy' || isInNetwork) && (
            <Button
              mt="24px"
              variant="primary"
              disabled={selectedPlan !== 'annualy' && selectedPlan !== 'monthly' && selectedPlan !== 'insurance'}
              onClick={() => {
                if (onSubscribe) {
                  onSubscribe(selectedPlan);
                } else {
                  // history.push({
                  //   pathname: '/register',
                  //   state: {
                  //     subscribe: true,
                  //     frequency: selectedPlan,
                  //     continueWith: '/schedule',
                  //   },
                  // });
                }
              }}
            >
              Next
            </Button>
          )}

          {selectedPlan === 'monthly' && (
            <div style={{ marginTop: '24px' }}>
              <Quadpay-button id="QPButton" merchantId={window._env_.REACT_APP_QUADPAY_MERCHANTID} merchantReference={quadpayOrderReference} amount={`${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}`} currency="USD" />
            </div>
          )}
        </>
      )}
    </Flex>
    // </Link>
  );
};
export const SelectPlanNoWizard = withRouter(withApollo(SelectPlan));

export default withRouter(withApollo(withWizard(SelectPlan)));
