import { QUESTIONS_FOLLOWUP_SURVEYS_1 } from '../pages/followUpSurvey/surveyQuestions/followUp1';
import { QUESTIONS_FOLLOWUP_SURVEYS_2 } from '../pages/followUpSurvey/surveyQuestions/followUp2';
import { QUESTIONS_FOLLOWUP_SURVEYS_3 } from '../pages/followUpSurvey/surveyQuestions/followUp3';
import { QUESTIONS_FOLLOWUP_SURVEYS_4 } from '../pages/followUpSurvey/surveyQuestions/followUp4';

export const FOLLOWUP_SURVEYS_ID = [
  {
    name: 'FollowUpSurvey1',
    id: '65d9cdf8-b029-415d-84a5-d8fcf91b471a',
    title: 'Care Check In #1',
    description:
      'We are committed to continually improving the personalization of your care by regularly checking in on your progress. Please complete your Care Check-in so we can ensure that we are caring for you in the best way possible.',
    localStorageKey: 'fus1',
    questions: QUESTIONS_FOLLOWUP_SURVEYS_1,
    isActive: true,
    adminText: 'Follow-up survey usually triggered after 1st appointment',
  },
  {
    name: 'FollowUpSurvey2',
    id: '1302131f-834e-42de-a4d8-e4e3a2cf9260',
    title: 'Care Check In #2',
    questions: QUESTIONS_FOLLOWUP_SURVEYS_2,
    description:
      'We are committed to continually improving the personalization of your care by regularly checking in on your progress. Please complete your Care Check-in so we can ensure that we are caring for you in the best way possible.',
    isActive: true,
    adminText: 'Follow-up survey usually triggered after 12 weeks',
  },
  {
    name: 'FollowUpSurvey3',
    id: 'ccb3685f-38eb-410d-914b-327600838d88',
    title: 'Care Check In #3',
    questions: QUESTIONS_FOLLOWUP_SURVEYS_3,
    description:
      'We are committed to continually improving the personalization of your care by regularly checking in on your progress. Please complete your Care Check-in so we can ensure that we are caring for you in the best way possible.',
    isActive: true,
    adminText: 'Follow-up survey usually triggered after 6 months',
  },
  {
    name: 'FollowUpSurvey4',
    id: 'f1b78698-543c-45f1-9183-905e04745b26',
    title: 'Care Check In #4',
    questions: QUESTIONS_FOLLOWUP_SURVEYS_4,
    description:
      'We are committed to continually improving the personalization of your care by regularly checking in on your progress. Please complete your Care Check-in so we can ensure that we are caring for you in the best way possible.',
    isActive: true,
    adminText: 'Follow-up survey usually triggered after 12 months',
  },
];
