import React from 'react';
import { Heading, Text, Flex, Card, Box } from 'rebass';
import { Link } from 'react-router-dom';
import doctor from '../../static/icon_kit.png';
import discoverPossibleCauses from '../../static/Character_2.png';
import { MembershipBulletItem } from '../../components/MembershipBulletItem';
import { withWizard } from 'react-albus';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Constants } from '../../utils/constants';
import { isToggleActive } from '../../components/featureToggle/toggles';

const A = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: #44a5ff;
  text-decoration: underline;
  cursor: pointer;
`;

const FakeButton = styled(Box)`
  height: 40px;
  background: #354e79;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  margin-top: 24px;
`;

const BecomeAMember = () => {
  return (
    <>
      <Heading>Become a Member</Heading>
      <Text mt="8px" fontWeight="300">
      <span style={{fontWeight: 600}}>{`$${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}`}</span> billed annually
      </Text>
        <quadpay-widget-v3 amount={`${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}`} merchantId={window._env_.REACT_APP_QUADPAY_MERCHANTID} alignment="left" widgetVerbiage="4-easy-payments" />
{/* <zip-payment-widget amount= "192" merchantId={window._env_.REACT_APP_QUADPAY_MERCHANTID} > </zip-payment-widget> */}
      {/* <quadpay-widget-v3
        amount="192"
        alignment="left"
        widgetVerbiage="4-easy-payments"
      /> */}
      <Flex flexDirection="row" style={{ marginTop: '12px', alignItems: 'center' }}>
        <img src={doctor} height="44px" style={{marginRight: '24px'}} alt="" />
        <Flex flexDirection="column" justifyContent="flex-end">
          <Text style={{ fontWeight: 600, fontSize: '18px' }}>Thyroid test kit</Text>
          <Flex flexDirection="row" alignItems="baseline">
            <Text
              style={{
                fontWeight: 600,
                textDecoration: 'line-through',
                marginRight: '6px',
              }}
            >
              $99
            </Text>
            <Text style={{ fontWeight: 600, fontSize: '20px' }}>$75</Text>
          </Flex>
        </Flex>
      </Flex>
      <Text
        style={{
          fontWeight: 500,
          fontSize: '16px',
          marginTop: '28px',
          marginBottom: '16px',
        }}
      >
        And get access to:
      </Text>
      <MembershipBulletItem text="Video visits and ongoing care with the medical team"  style={{maxWidth: '280px'}} />
      <MembershipBulletItem text="Dedicated Care Manager" />
      <MembershipBulletItem text="Unlimited Doctor Chats"  style={{maxWidth: '220px'}} />
      <MembershipBulletItem text="Easy Refills " style={{maxWidth: '150px'}}  />

      <img
        alt=""
        src={discoverPossibleCauses}
        style={{
          position: 'absolute',
          bottom: '62px',
          right: '12px',
          width: '140px',
        }}
      />
      {!isMobile && <FakeButton>Join Now</FakeButton>}
    </>
  );
};

const SelectMembership = ({ wizard, onContinue, onSubscribe }) => {
  return (
    <Flex flexDirection="column" alignItems='center' pb='95px' mt='20px'>
      <Heading textAlign="center">Unlock your Membership for an Immediate Discount</Heading>

      <Text textAlign="center" style={{ marginTop: '24px' }}>
      Best-in-class hypothyroid care loaded with perks and discounts
      </Text>

      {/* <Flex flexDirection="row" style={{ marginTop: '32px' }}> */}
        <Card
          padding="14px"
          style={{
            position: 'relative',
            padding: '14px',
            margin: '12px auto',
            cursor: 'pointer',
            zIndex: 1,
            width: '310px'
          }}
          onClick={() => {
            if (onSubscribe) {
              onSubscribe(wizard);
            }
          }}
        >
        <svg
          width="216"
          height="193"
          viewBox="0 0 216 193"
          fill="none"
          style={{position: 'absolute', zIndex: -1, top:0, left:0}}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M200.431 141.828C188.829 156.058 174.345 165.629 158.855 173.61C121.528 192.844 82.2117 197.419 41.1933 188.885C32.8552 187.151 24.3359 186.235 15.8704 185.188C0.732343 183.313 -13.2054 178.36 -24.5777 168.012C-37.5196 156.236 -46.8009 141.73 -53.0749 125.393C-65.3319 93.4707 -68.9271 60.8566 -60.1484 27.4045C-57.0406 15.5568 -50.9512 5.13435 -44.6573 -5.27518C-35.3071 -20.7471 -22.4797 -32.7319 -8.15858 -43.2071C0.696121 -49.683 11.2666 -52.2324 22.0077 -53.3318C63.932 -57.6135 105.094 -53.769 145.167 -40.4526C152.432 -38.0381 160.175 -35.1781 165.871 -30.3513C174.238 -23.0118 181.786 -14.7831 188.383 -5.80966C199.755 9.16994 205.968 26.6579 209.888 44.9347C214.073 64.4562 217.517 84.1066 215.313 104.198C213.766 118.256 207.98 130.723 200.431 141.828Z"
            fill="#E7F0FF"
            fill-opacity="0.35"
          />
        </svg>
          {!onSubscribe ? (
            <Link
              style={{ flex: 1, textDecoration: 'none' }}
              to={{
                pathname: '/register',
                // search: "?sort=name",
                // hash: "#the-hash",
                state: { subscribe: true, continueWith: '/schedule' },
              }}
            >
              <BecomeAMember />
            </Link>
          ) : (
            <BecomeAMember />
          )}
        </Card>
      <A
        onClick={() => {
          onContinue(wizard);
        }}
      >
        Not Interested
      </A>
    </Flex>
  );
};

export default withWizard(SelectMembership);
