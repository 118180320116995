import React, { Component } from 'react';
import { Card, Flex, Box, Text } from 'rebass';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from 'recharts';

const Icon = styled(Flex)`
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const DetailBar = styled(Flex)`
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const normalizeAnalyteName = (analyte) => {
  switch (analyte.toLowerCase().replace(/ /g, '')) {
    case 'ft3':
    case 'ft3-bloodspot':
    case 'freet3':
    case 'freet3uptake':
    case 't3,free':
    case 't3free':
    case 't3free(ml)':
      return 'fT3';
    case 'ft4':
    case 'ft4-bloodspot':
    case 'freet4':
    case 'freet4(direct)':
    case 'freet4index':
    case 'freet4index(t7)':
    case 'ft4':
    case 't4free':
    case 't4,free':
    case 't4,free,directdialysis':
      return 'fT4';
    case 'tpo':
    case 'tpo-bloodspot':
    case 'tpoab':
    case 'tpoanitbody':
    case 'tpoantibodies':
    case 'tpoantibody':
    case 'thyroidperoxidaseantibodies':
      return 'TPO';
    case 'tsh':
    case 'tsh-bloodspot':
      return 'TSH';
    case 'rt3':
    case 'rt3-bloodspot':
    case 'reverset3':
    case 't3reverse':
    case 't3reverse,lc/ms/ms':
    case 't3,reverse':
      return 'rT3';
    case 'vitamind':
    case 'vitamind-bloodspot':
    case 'vitamind,25-oh,total':
    case 'vitamind,25-hydroxy':
    case 'vitamind,25-ohtotal':
      return 'vitD';
    default:
      return analyte;
  }
};
class LabResultDetails extends Component {
  state = {
    open: false,
  };

  refMin = (data, hormone) => {
    if(!hormone) return 0
    if (!data.referenceRange) return hormone.min;
    if (data.referenceRange.indexOf('-') < 0) return hormone.min;

    const sp = data.referenceRange.replace(/ /g, '').split('-');
    if (sp.length < 2) return hormone.min;

    const min = parseFloat(sp[1]);
    if (isNaN(min)) return hormone.min;
    return min;
  };

  refMax = (data, hormone) => {
    if(!hormone) return 0
    if (!data.referenceRange) return hormone.max;
    if (data.referenceRange.indexOf('-') < 0) return hormone.max;

    const sp = data.referenceRange.replace(/ /g, '').split('-');
    if (sp.length < 2) return hormone.max;

    const max = parseFloat(sp[1]);
    if (isNaN(max)) return hormone.max;
    return max;
  };

  constructor(props) {
    super(props);
    const { data, hormoneName } = props;
    let d = data.analyte;
    if (!d) {
      d = data.hormone;
    }
    const hormone = hormoneName[normalizeAnalyteName(data.analyte || data.hormone)];
    if (!hormone) {
      this.state = {
        open: false,
        hormoneNotFound: true,
      };
      return
    }
    this.state = {
      open: false,
      min: this.refMin(data, hormone),
      max: this.refMax(data, hormone),
      hormoneNotFound: false,
    };
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { data, hormoneName } = this.props;
    const { open, min, max,hormoneNotFound } = this.state;
    const hormone = hormoneName[normalizeAnalyteName(data.analyte || data.hormone)];
    const isQns = data.value && data.value.toLowerCase() === 'qns';
    const m = max + 10;

    return (
      <Card p={3} mb={4}>
        <Flex flexDirection="column">
          <DetailBar m={2} onClick={this.handleClick}>
            <Icon mr={2} pt={1}>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                <circle cx="5.2585" cy="5.2585" r="4.2068" fill="white" stroke={!!isQns || data.abnormal || (data.status && data.status !== 'normal') ? '#fbafbc' : '#82ca9d'} stroke-width="2.1034" />
              </svg>
            </Icon>

            <Flex flex={1} flexDirection={['column', 'row']}>
              <Flex flex={1} px={3} justifyContent="space-between" alignItems="center" flexDirection={['column', 'row']}>
                <Text textAlign="center" fontWeight={600} mb={[2, 0]}>
                  {hormone ? hormone.name : data.analyte || data.hormone}
                </Text>
                {isQns ? (
                  <Text mb={[2, 0]}>Quantity Not Sufficient</Text>
                ) : (
                  <Text mb={[2, 0]}>
                    {data.value} {data.unit}
                  </Text>
                )}
              </Flex>

              <Flex ml={2} pt={1} justifyContent="center">
                {/* <Arrow width="30px" rotate={open ? 'rotate(180)' : undefined} /> */}
                <svg data-name="Arrow" width="30px" viewBox="0 0 146 85" transform={open ? 'rotate(180)' : undefined}>
                  <path
                    d="M 0.00 0.00 L 146.00 0.00 L 146.00 85.00 L 0.00 85.00 L 0.00 0.00 Z M 75.20 61.99 C 58.17 44.97 41.12 27.95 24.12 10.88 C 21.78 8.63 19.45 5.54 15.95 5.33 C 11.42 4.86 7.31 8.49 7.22 13.04 C 7.09 15.78 8.45 17.83 10.30 19.69 C 28.98 38.33 47.66 57.02 66.29 75.71 C 68.86 78.18 71.09 81.26 74.99 81.30 C 79.30 81.47 81.62 78.09 84.42 75.42 C 102.24 57.58 120.05 39.72 137.92 21.92 C 140.10 19.65 142.96 17.43 143.18 14.03 C 143.65 9.30 139.79 5.17 135.05 5.30 C 131.11 5.25 128.66 8.63 126.09 11.09 C 109.14 28.07 92.20 45.07 75.20 61.99 Z"
                    fill="#ffffff"
                  />
                  <path
                    d="M 15.95 5.33 C 19.45 5.54 21.78 8.63 24.12 10.88 C 41.12 27.95 58.17 44.97 75.20 61.99 C 92.20 45.07 109.14 28.07 126.09 11.09 C 128.66 8.63 131.11 5.25 135.05 5.30 C 139.79 5.17 143.65 9.30 143.18 14.03 C 142.96 17.43 140.10 19.65 137.92 21.92 C 120.05 39.72 102.24 57.58 84.42 75.42 C 81.62 78.09 79.30 81.47 74.99 81.30 C 71.09 81.26 68.86 78.18 66.29 75.71 C 47.66 57.02 28.98 38.33 10.30 19.69 C 8.45 17.83 7.09 15.78 7.22 13.04 C 7.31 8.49 11.42 4.86 15.95 5.33 Z"
                    fill="#7992B3"
                  />
                </svg>
              </Flex>
            </Flex>
          </DetailBar>

          {open && (
            <>
              {isQns ? (
                <Flex mt={3} flexDirection="column" justifyContent="center">
                  <Flex flexDirection={('column', 'row')}>
                    <Text fontSize={2} fontWeight={500} mr={1} mb={2}>
                      There is not enough specimen for the lab tests ordered to be performed.
                    </Text>

                    <Text fontSize={2}>Please let us know if our team has not already reached out to offer a set of replacement materials.</Text>
                  </Flex>
                </Flex>
              ) : (
                <Flex mt={3} flexDirection="column" justifyContent="center">
                  <Flex flexDirection="column" mx={2}>
                    <Flex justifyContent="space-between">
                      <Box mb={3}>
                        <Text mb={1} fontWeight={200}>
                          Your Results
                        </Text>

                        <Text fontWeight={500}>
                          {data.value} {data.unit}
                        </Text>
                      </Box>

                      <Box mb={3}>
                        <Text mb={1} mr={3} fontWeight={200}>
                          Normal Range
                        </Text>
                        <Text fontWeight={500}>
                          {hormone ? data.referenceRange || `${hormone.min} - ${hormone.max}` : data.referenceRange} {data.unit}
                        </Text>
                      </Box>
                    </Flex>

                    {hormone && !hormoneNotFound && (
                      <Flex flex={1} mr={3}>
                        <ResponsiveContainer height={125} width="100%">
                          <BarChart data={[{ name: 0, result: [0, data.value] }]} layout="vertical">
                            <XAxis type="number" domain={[0, data.value > m ? data.value + 10 : m + 10]} unit={` ${data.unit}`} />
                            <YAxis type="category" hide={true} />
                            <Bar dataKey="result" fill={data.abnormal || (data.status && data.status !== 'normal') ? '#f9a394' : '#82ca9d'} />

                            <ReferenceLine x={min} isFront={true} />
                            <ReferenceLine x={max} isFront={true} />
                          </BarChart>
                        </ResponsiveContainer>
                      </Flex>
                    )}

                    <Flex flexDirection={('column', 'row')}>
                      <Text fontSize={2} fontWeight={500} mr={1} mb={2}>
                        Your {data.hormone} has returned as{' '}
                      </Text>

                      <Text fontSize={2} fontWeight={500} color={data.abnormal || (data.status || '') === 'abnormal' ? '#f13b2f' : ''}>
                        {data.abnormal || (data.status && data.status !== 'normal') ? 'Out of the normal range' : 'Normal'}
                      </Text>
                    </Flex>
                    {!hormoneNotFound && hormone &&
                      hormone.description &&
                      hormone.description.split('\n').map((item, i) => {
                        return (
                          <Text mt={2} key={`${hormone.name}${i}`}>
                            {item}
                          </Text>
                        );
                      })}
                    {/* <Text>{hormone ? hormone.description : ''}</Text> */}
                  </Flex>
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Card>
    );
  }
}

export default LabResultDetails;
