import React, { useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import PleaseWait from '../../components/PleaseWait';
import { Input } from '../../components/TextInput';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import gql from 'graphql-tag';
import ConfirmModal from '../../components/ConfirmModal';
import ModalChangeRelatedEmployee from './ModalChangeRelatedEmployee';
import styled from 'styled-components';
import { withToastManager } from 'react-toast-notifications';
import ModalAddEligibilityEntry from './ModalAddEligibilityEntry';

const ModalInner = styled(Box)`
  max-width: 600px;
  min-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;
const REGISTERED_LIST = gql`
  query getAdminCompanyRegisteredEmployees($companyId: String!) {
    getAdminCompanyRegisteredEmployees(companyId: $companyId) {
      id
      employeeEmail
      employeeDoB
      employeeFirstName
      employeeLastName
      mustVerify
      # internalReferenceID
      employeeId
      verificationUID
      verificationSentDate
      verificationSent
      isActive
      activationDate
      needAdminApproval
      user {
        id
        email
        phone
        firstName
        lastName
        intakeStatus
        athenaId
        accountLocked
        mustResetPassword
        mustCompleteProfile
        profileCompletionUid
        intakeDate
      }
    }
  }
`;
export const ELIGIBLE_LIST = gql`
  query getAdminCompanyEligibleEmployees($companyId: String!, $search: String!) {
    getAdminCompanyEligibleEmployees(companyId: $companyId, search: $search) {
      id
      firstName
      lastName
      firstNameLowerCaseNoSpace
      lastNameLowerCaseNoSpace
      dob
      employeeEmail
      employeeId
      relationshipToEmployer
      primaryFirstName
      primaryLastName
      primaryFirstNameLowerCaseNoSpace
      primaryLastNameLowerCaseNoSpace
      metadata
      isActive
      createdAt
      updatedAt
      memberId
      subscriberId
      uniqueCode
    }
  }
`;
const SET_AS_VERIFIED = gql`
  mutation adminSetUserEligibilityAsVerified($detailsId: String!) {
    adminSetUserEligibilityAsVerified(detailsId: $detailsId) {
      ok
    }
  }
`;
const REMOVE_B2B_ELIGIBILITY = gql`
  mutation adminRemoveB2bEligibility($detailsId: String!) {
    adminRemoveB2bEligibility(detailsId: $detailsId) {
      ok
    }
  }
`;
const DetailRows = ({ row, companyId, toastManager }) => {
  const [changeLinked, setChangeLinked] = useState();
  return (
    <Flex flexDirection={'column'} style={{ maxWidth: '700px' }} mx={4}>
      <Text>User:</Text>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>email:</Text>
          <Text fontWeight={500}>{((row.original || {}).user || {}).email}</Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>phone:</Text>
          <Text fontWeight={500}>{((row.original || {}).user || {}).phone}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>athenaId:</Text>
          <Text fontWeight={500}>{((row.original || {}).user || {}).athenaId}</Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>mustCompleteProfile:</Text>
          <Text fontWeight={500}>{!!((row.original || {}).user || {}).mustCompleteProfile}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>profileCompletionUid:</Text>
          <Text fontWeight={500}>{((row.original || {}).user || {}).profileCompletionUid}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>intakeStatus:</Text>
          <Text fontWeight={500}>{((row.original || {}).user || {}).intakeStatus}</Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>intakeDate:</Text>
          <Text fontWeight={500}>{((row.original || {}).user || {}).intakeDate}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>accountLocked:</Text>
          <Text fontWeight={500}>{!!((row.original || {}).user || {}).accountLocked}</Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>mustResetPassword:</Text>
          <Text fontWeight={500}>{!!((row.original || {}).user || {}).mustResetPassword}</Text>
        </Flex>
      </Flex>
      <Text mt={4}>Employee:</Text>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>employeeEmail:</Text>
          <Text fontWeight={500}>{(row.original || {}).employeeEmail}</Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>employeeDoB:</Text>
          <Text fontWeight={500}>{(row.original || {}).employeeDoB}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>employeeFirstName:</Text>
          <Text fontWeight={500}>{(row.original || {}).employeeFirstName}</Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>employeeDoB:</Text>
          <Text fontWeight={500}>{(row.original || {}).employeeLastName}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>verification Sent:</Text>
          <Text fontWeight={500}>{!!(row.original || {}).verificationSent}</Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>verification Sent Date:</Text>
          <Text fontWeight={500}>{(row.original || {}).verificationSentDate}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>verification UID:</Text>
          <Text fontWeight={500}>{(row.original || {}).verificationUID}</Text>
        </Flex>
      </Flex>

      <Flex flexWrap={'wrap'} mt={4}>
        <Button variant={'primary'} mr={2} onClick={() => setChangeLinked(row.original)}>
          Change linked eligibility
        </Button>
        {!!(row.original || {}).mustVerify &&
          !(row.original || {}).needAdminApproval && (
            <Mutation
              mutation={SET_AS_VERIFIED}
              refetchQueries={[{ query: REGISTERED_LIST, variables: { companyId } }]}
              awaitRefetchQueries={true}
              onCompleted={(data) => {
                if ((data.adminSetUserEligibilityAsVerified || {}).ok) {
                  toastManager.add('Eligibility verified', { appearance: 'success' });
                } else {
                  toastManager.add('Eligibility not verified. An error occured', { appearance: 'error' });
                }
              }}
            >
              {(mutate, { loading }) => {
                return (
                  <Button
                    disabled={loading}
                    onClick={() => {
                      mutate({ variables: { detailsId: row.original.id } });
                    }}
                    variant={'pink'}
                    ml={2}
                    mr={2}
                  >
                    Set as verified
                  </Button>
                );
              }}
            </Mutation>
          )}
        <Box style={{ flex: 1 }} />
        {!!(row.original || {}).id && (
          <Mutation
            mutation={REMOVE_B2B_ELIGIBILITY}
            refetchQueries={[{ query: REGISTERED_LIST, variables: { companyId } }]}
            awaitRefetchQueries={true}
            onCompleted={(data) => {
              if ((data.adminRemoveB2bEligibility || {}).ok) {
                toastManager.add('Eligibility removed', { appearance: 'success' });
              } else {
                toastManager.add('Eligibility not removed. An error occured', { appearance: 'error' });
              }
            }}
          >
            {(mutate, { loading }) => {
              return (
                <Button
                  disabled={loading}
                  onClick={() => {
                    mutate({ variables: { detailsId: row.original.id } });
                  }}
                  variant={'pink'}
                  ml={2}
                  mr={2}
                >
                  Remove eligibility
                </Button>
              );
            }}
          </Mutation>
        )}
      </Flex>
      {!!changeLinked && (
        <ConfirmModal
          childrenManaged
          isOpen={!!changeLinked}
          confirmLabel="create"
          onClose={() => {
            setChangeLinked(null);
          }}
          onBackgroundClick={() => {
            setChangeLinked(null);
          }}
          onEscapeKeydown={() => {
            setChangeLinked(null);
          }}
          onConfirm={() => {
            setChangeLinked(null);
          }}
        >
          <ModalInner
            style={{
              minWidth: '70vw',
              maxWidth: '70vw',
              width: '70vw',
            }}
          >
            <Heading fontSize="20px" mb={3}>
              Change Linked Eligibility
            </Heading>
            <ModalChangeRelatedEmployee
              companyId={companyId}
              toLink={row.original}
              onClose={() => {
                setChangeLinked(null);
              }}
            />
          </ModalInner>
        </ConfirmModal>
      )}
    </Flex>
  );
};

export const EligibilityUsersList = ({ data, companyId, toastManager }) => {
  const CATEGORIES_COLUMNS = [
    {
      Header: 'Athena ID',
      accessor: 'user.athenaId',
    },
    {
      Header: 'first Name',
      accessor: 'user.firstName',
    },
    {
      Header: 'last Name',
      accessor: 'user.lastName',
    },
    {
      Header: 'is Active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div style={{ textAlign: 'center', color: !row.original.id ? '' : row.original.isActive ? 'green' : 'red' }}>{!row.original.id ? 'N/A' : row.original.isActive ? '✓' : '∅'}</div>;
      },
    },
    {
      Header: 'must Verify',
      accessor: 'mustVerify',
      Cell: (row) => {
        return <div style={{ textAlign: 'center', color: !row.original.id ? '' : row.original.mustVerify ? 'red' : 'green' }}>{!row.original.id ? 'N/A' : row.original.mustVerify ? '✓' : '∅'}</div>;
      },
    },
    {
      Header: 'need Admin Approval',
      accessor: 'needAdminApproval',
      Cell: (row) => {
        return (
          <div style={{ textAlign: 'center', color: !row.original.id ? '' : row.original.needAdminApproval ? 'red' : 'green' }}>
            {!row.original.id ? 'N/A' : row.original.needAdminApproval ? '✓' : '∅'}
          </div>
        );
      },
    },
  ];
  return (
    <ReactTable
      data={data || []}
      columns={CATEGORIES_COLUMNS}
      defaultPageSize={data && data.length > 3 ? Math.min(data.length, 100) : 3}
      pageSize={data && data.length > 3 ? data.length : 3}
      SubComponent={(row) => {
        console.log({ row });
        return <DetailRows row={row} companyId={companyId || row.original.companyId} toastManager={toastManager} />;
      }}
    />
  );
};

const ModalViewEmployees = ({ companyId, toastManager }) => {
  const [addElibility, setAddEligibility] = useState(false);
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState();
  const CATEGORIES_COLUMNS = [
    {
      Header: 'first Name',
      accessor: 'firstName',
    },
    {
      Header: 'last Name',
      accessor: 'lastName',
    },
    {
      Header: 'DoB',
      accessor: 'dob',
    },
    {
      Header: 'employee Email',
      accessor: 'employeeEmail',
    },
    {
      Header: 'relationship To Employer',
      accessor: 'relationshipToEmployer',
    },
    {
      Header: 'Employee First Name',
      accessor: 'primaryFirstName',
    },
    {
      Header: 'Employee Last Name',
      accessor: 'primaryLastName',
    },
    {
      Header: 'Employee ID',
      accessor: 'employeeId',
    },
    {
      Header: 'Member ID',
      accessor: 'memberId',
    },
    {
      Header: 'Subscriber ID',
      accessor: 'subscriberId',
    },
    {
      Header: 'Unique Code',
      accessor: 'uniqueCode',
    },

    // {
    //   Header: '',
    //   Cell: (row) => {
    //     return (
    //       <Text style={{ textAlign: 'center', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setSelectedLink(row.original)}>
    //         select
    //       </Text>
    //     );
    //   },
    // },
  ];
  if (!companyId) return null;
  return (
    <Query
      query={REGISTERED_LIST}
      variables={{
        companyId,
      }}
    >
      {({ data, loading, error }) => {
        if (!!loading) return <PleaseWait text={' '} />;
        const resultData =
          !!filter && filter.length > 0
            ? ((data || {}).getAdminCompanyRegisteredEmployees || []).filter((x) => {
                if (
                  (x.employeeEmail || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                  (x.employeeEmail || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                  (x.employeeFirstName || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                  (x.employeeLastName || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                  // (x.internalReferenceID || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                  ((x.user || {}).firstName || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                  ((x.user || {}).lastName || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                  ((x.user || {}).athenaId || '').toLowerCase().indexOf(filter.toLowerCase()) > -1
                ) {
                  return true;
                }
                return false;
              })
            : (data || {}).getAdminCompanyRegisteredEmployees || [];
        return (
          <Flex flexDirection={'column'}>
            <Flex alignItems={'center'} mb={3}>
              <Flex flexDirection={'column'} mr={4} flex={1}>
                <Text>Search</Text>
                <Input type="search" onChange={(e) => setFilter(e.target.value)} placeholder="search" mb={4} />
              </Flex>
              <Button disabled={!filter} variant={'pink'} style={{ borderRadius: '8px' }} mr={3} onClick={() => {
                setSearch(filter)}}>
                Search
              </Button>
              <Button variant={'primary'} style={{ borderRadius: '8px' }} onClick={() => setAddEligibility(true)}>
                Add new
              </Button>
            </Flex>
            {!!search ? (
              <Query
                query={ELIGIBLE_LIST}
                fetchPolicy={'network-only'}
                variables={{
                  companyId: companyId,
                  search,
                }}
              >
                {({ data, loading, error }) => {
                  if (!!loading) return <PleaseWait text={' '} />;
                  const resultData = (data || {}).getAdminCompanyEligibleEmployees || [];

                  return (
                    <ReactTable 
                      data={resultData || []}
                      columns={CATEGORIES_COLUMNS}
                      defaultPageSize={resultData && resultData.length > 3 ? Math.min(resultData.length, 100) : 3}
                      pageSize={resultData && resultData.length > 3 ? resultData.length : 3}
                    />
                  );
                }}
              </Query>
            ) : (
              <>
                <EligibilityUsersList data={resultData} companyId={companyId} toastManager={toastManager} />
              </>
            )}
            {!!addElibility && (
              <ConfirmModal
                childrenManaged
                isOpen={!!addElibility}
                confirmLabel="create"
                onClose={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onBackgroundClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onEscapeKeydown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onConfirm={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <ModalInner
                  style={{
                    height: '70vh',
                    maxHeight: '70vh',
                  }}
                >
                  <ModalAddEligibilityEntry
                    companyId={companyId}
                    onClose={() => setAddEligibility(false)}
                    onCompleted={() => {
                      setAddEligibility(false);
                    }}
                    toastManager={toastManager}
                  />
                </ModalInner>
              </ConfirmModal>
            )}
          </Flex>
        );
      }}
    </Query>
  );
};

export default withToastManager(ModalViewEmployees);
