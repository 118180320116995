import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Box, Heading, Text, Flex, Button } from 'rebass';
import { Query, Mutation } from 'react-apollo';
import styled from 'styled-components';
import {
  GET_PENDING_PROFILE_COMPLETION_USERS,
  RESEND_COMPLETE_PROFILE_EMAIL,
} from '../graphql';
import { withToastManager } from 'react-toast-notifications';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;

class DetailsRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      row: { original },
    } = this.props;

    return (
      <div>
        <Flex flexDirection="column" alignItems="center">
          <Text fontSize="20px" fontWeight="bold" mb={3} mt={4}>
            {original.firstName}
            &nbsp;
            {original.lastName}
          </Text>
          <Text
            textAlign="center"
            pt={2}
            pb={2}
            mb={3}
            style={{ margin: '0 8px' }}
          >{`${original.email}`}</Text>
          <Flex flexDirection={['column', 'row']} justifyContent="center">
            <Box style={{ border: '1px solid #ddd' }} mr={[0, 2]}>
              <Flex flexDirection="column">
                <div
                  style={{
                    background: '#344f79',
                    color: 'white',
                    padding: '0 6px',
                  }}
                >
                  profile completion UID
                </div>
                <Text
                  textAlign="center"
                  fontWeight="bold"
                  pt={2}
                  pb={2}
                  style={{ margin: '0 8px' }}
                >{`${original.profileCompletionUid}`}</Text>
                <Mutation
                  mutation={RESEND_COMPLETE_PROFILE_EMAIL}
                  variables={{
                    profileCompletionUid: original.profileCompletionUid,
                  }}
                  onCompleted={() => {
                    if (this.props.toastManager) {
                      this.props.toastManager.add('Email Sent', {
                        appearance: 'success',
                        autoDismiss: true,
                      });
                    }
                  }}
                >
                  {(resendEmail, { loading }) => (
                    <Button
                      variant="pink"
                      style={{ margin: '24px auto' }}
                      onClick={resendEmail}
                    >
                      Resend email
                    </Button>
                  )}
                </Mutation>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </div>
    );
  }
}
class PendingProfileCompletionUsers extends Component {
  render() {
    return (
      <Container>
        <Heading>ZocDoc Patients Pending Registration</Heading>
        <Query query={GET_PENDING_PROFILE_COMPLETION_USERS}>
          {({ data, loading, error }) => {
            if (loading || error) return <div />;

            // console.log('data', data)
            // const {
            //   id,
            //   athenaId,
            //   email,
            //   firstName,
            //   lastName,
            //   departmentId,
            //   providerId,
            //   primaryProviderId,
            //   dob,
            //   profileCompletionUid,
            //   appointmentProviderId,
            //   appointmentDate,
            //   collateralFrozenSlotsCount,
            // } = data.getPendingProfileCompletionUsers;
            const columns = [
              {
                Header: 'Patient ID',
                accessor: 'athenaId',
              },
              {
                Header: 'Email',
                accessor: 'email',
              },
              // {
              //   Header: 'Dept ID',
              //   accessor: 'departmentId',
              // },
              // {
              //   Header: 'appointment provider',
              //   accessor: 'appointmentProviderId',
              // },
              // {
              //   Header: 'appointment date',
              //   accessor: 'appointmentDate',
              // },
            ];
            return (
              <>
                <ReactTable
                  data={data.getPendingProfileCompletionUsers}
                  columns={columns}
                  SubComponent={row => {
                    return (
                      <DetailsRow
                        row={row}
                        toastManager={this.props.toastManager}
                      />
                    );
                  }}
                  
                  defaultPageSize={
                    data.getPendingProfileCompletionUsers &&
                    data.getPendingProfileCompletionUsers.length > 3
                      ? Math.min(
                          data.getPendingProfileCompletionUsers.length,
                          20
                        )
                      : 3
                  }
                  showPagination={
                    data.getPendingProfileCompletionUsers &&
                    data.getPendingProfileCompletionUsers.length > 20
                  }
                />
              </>
            );
          }}
        </Query>
      </Container>
    );
  }
}

export default withToastManager(PendingProfileCompletionUsers);
