import React, { useState } from 'react';
import { PlateHeart } from '../../YearlyReview/icons/PlateHeart';
import { GlobalImprovements, GlobalImprovementsBackground } from '../pages/GlobalImprovements';
// import { CarouselCard } from '../Summary';
import { ThankYou, ThankYouBackground } from '../pages/ThankYou';
import { Query } from 'react-apollo';
import { GET_BASELINE_SURVEY_ANSWERS, SURVEY_ANSWERS_SCORES } from '../../../graphql';
import { GenericPageA, GenericPageABackground } from '../pages/GenericPageA';
import { GenericPageB, GenericPageBBackground } from '../pages/GenericPageB';
// import { CarouselCard } from '../BaselineOutroSummary';
// import { QUESTIONS } from '../Questions';
import { Completed, CompletedBackground } from '../pages/Completed';
import { CarouselCard } from '../FollowUpOutroSummary';
import { ImprovedSymptoms, ImprovedSymptomsBackground } from '../pages/ImprovedSymptoms';
import { Remember, RememberBackground } from '../pages/Remember';

const SECTION_BACKGROUND = '#FDF5F2';
const SECTION_NAME = '';

const calcAvg = (arr) => {
  console.log({ calcAvg: arr });
  if (!arr || !Array.isArray(arr) || arr.filter((x) => x !== null && !isNaN(x)).length < 1) return null;

  return arr.filter((x) => x !== null && !isNaN(x)).reduce((a, b) => a + b, 0) / arr.filter((x) => x !== null && !isNaN(x)).length;
};
const identifyImproved = (data, surveyId) => {
  console.log({data})
  const baseline = data.find((x) => x.survey === 'BASELINE');
  const survey = data.find((x) => x.survey === surveyId);
  console.log({baseline, survey})

  const pond = [];
  for (const property in baseline.values) {
    // console.log(`${property}: ${object[property]}`);
    const bVal = baseline.values[property];
    const sVal = survey.values[property];
    console.log({bVal, sVal})

    if (bVal === null || bVal === undefined || sVal === null || sVal === undefined || isNaN(bVal) || isNaN(sVal)) continue;
    pond.push({
      question: property,
      ponderation: sVal - bVal,
    });
  }

  console.log({pond})
  return pond;
};
const getImprovedText = (symptoms) => {
  console.log({symptoms})
  if (!symptoms || !Array.isArray(symptoms) || symptoms.length < 1) return [];

  const res = [];

  for (let index = 0; index < symptoms.length; index++) {
    const element = symptoms[index];
    console.log({elementText: element})
    console.log({elementTextQ: element.question})
    switch (element.question) {
      case 'SensitiveCold':
        res.push({ symptom: 'Cold Sensitivity' });
        break;
      case 'Energetic':
        res.push({ symptom: 'Energy' });
        break;
      case 'Tired':
        res.push({ symptom: 'Tiredness' });
        break;
      case 'DifficultiesRemembering':
        res.push({ symptom: 'Memory' });
        break;
      case 'SlowThinking':
        res.push({ symptom: 'Slow Thinking' });
        break;
      case 'AfraidAnxious':
        res.push({ symptom: 'Anxiety' });
        break;
      case 'EverythingTakesLonger':
        res.push({ symptom: 'Taking longer' });
        break;
      case 'DifficultiesMotivated':
        res.push({ symptom: 'Motivation' });
        break;
      case 'DifficultiesManagingDailyLife':
        res.push({ symptom: 'Managing life' });
        break;
      case 'DrySkin':
        res.push({ symptom: 'Dry Skin' });
        break;
      case 'ThyroidNegativeImpactQualityOfLife':
        res.push({ symptom: 'Negative Impact' });
        break;
      case 'OverallWellBeing':
        res.push({ symptom: 'Well Being' });
        break;

      default:
        break;
    }
  }
  console.log({symptomsRes: res})
  if (res.length > 3) {
    return res.slice(0, 3);
  }
  return res;
};
const getStories = (data, surveyId, onRestart) => {
  const highs = identifyImproved(data, surveyId);
  console.log({ highs });
  const ordered = highs.sort((a, b) => {
    if (a.ponderation < b.ponderation) return 1;
    if (a.ponderation > b.ponderation) return -1;
    return 0;
  });
  const tops = ordered.filter((x) => x.ponderation > 0);
  const arr= [
    {
      progressStyle: {
        foreground: '#A1C5FF',
      },
      backgroundColor: '#344F7A',
      duration: 5000,
      content: <ThankYou />,
      background: <ThankYouBackground />,
      show: true,
      analytics: {
        section: 'FU Survey Outro Page',
        screen: 'Intro Page',
      },
    },
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#FDF5F2',
      duration: 5000,
      content: <Remember />,
      background: <RememberBackground />,
      show: true,
      analytics: {
        section: 'FU Survey Outro Page',
        screen: 'GlobalImprovements Page',
      },
    },
    {
      progressStyle: {
        foreground: '#A1C5FF',
      },
      backgroundColor: '#344F7A',
      duration: 5000,
      content: <ImprovedSymptoms improvedSymptoms={getImprovedText(tops)} />,
      background: <ImprovedSymptomsBackground />,
      show: !!tops && tops.length > 0,
      analytics: {
        section: 'FU Survey Outro Page',
        screen: 'SymptomsImprovements Page',
      },
    },
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#FDF5F2',
      duration: 5000,
      content: <GlobalImprovements />,
      background: <GlobalImprovementsBackground />,
      show: !tops || tops.length < 1,
      analytics: {
        section: 'FU Survey Outro Page',
        screen: 'GlobalImprovements Page',
      },
    },
    // {
    //   progressStyle: {
    //     foreground: '#344F7A',
    //   },
    //   backgroundColor: '#C7DDFC',
    //   duration: 7000,
    //   content: <GenericPageB title={lows[1].title} description={lows[1].description} score={lows[1].count} />,
    //   background: <GenericPageBBackground />,
    //   show: true,
    //   analytics: {
    //     section: 'Baseline Outro Page',
    //     screen: 'GlobalImprovements Page',
    //   },
    // },
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#C7DDFC',
      duration: 70000,
      content: <Completed onRestart={onRestart} />,
      background: <CompletedBackground />,
      show: true,
      onRestart:onRestart,
      analytics: {
        section: 'FU Survey Outro Page',
        screen: 'Complete Page',
      },
    },
  ];

  return arr.filter(x=>!!x.show)
};

const SummarySectionInner = ({ isActive, setActive, data, active, onAllStoriesEnd, onClose, surveyId, onRestart, restartCount }) => {
  const [stories, setStories] = useState(getStories(data, surveyId, onRestart).filter((x) => !!x.show));

  return (
    <CarouselCard
      onClose={onClose}
      hideIndicators={false}
      sectionNameColor="white"
      stories={stories}
      sectionName={SECTION_NAME}
      section={{ inactiveIcon: <PlateHeart /> }}
      isActive={isActive}
      backgroundColor={SECTION_BACKGROUND}
      onAllStoriesEnd={onAllStoriesEnd}
      onRestart={onRestart}
      restartCount={restartCount}
    />
  );
};

export const SummarySectionSection = ({ isActive, setActive, active, onAllStoriesEnd, onClose, surveyId, onRestart }) => {
  const [restartCount,setrestartCount] =useState(0)
  console.log('SummarySectionSection');
  const onReplay=()=>{
    setrestartCount(restartCount+1)
    onRestart()
  }
  // if (!isActive) return null;
  return (
    <Query query={SURVEY_ANSWERS_SCORES}>
      {({ data, loading, error }) => {
        if (loading) return null;
        console.log({ data });
        return <SummarySectionInner data={data.surveyAnswersScores} restartCount={restartCount} onRestart={onReplay} surveyId={surveyId} isActive={true} active={active} setActive={setActive} onAllStoriesEnd={onAllStoriesEnd} onClose={onClose} />;
      }}
    </Query>
  );
};
