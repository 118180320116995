import React from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../../components/FullLayout';
import Logo, { LogoHeader } from '../../components/Logo';
import { CURRENT_USER, EMPTY_CALL } from '../../graphql';
import withAnalytics from '../../lib/withAnalytics';
import { useMount } from '../../utils/hooks/lifecycle';
import Imge from '../../static/Character22.png';
import { Link } from 'react-router-dom';
import { isToggleActive } from '../../components/featureToggle/toggles';
import { Constants } from '../../utils/constants';

const Header = styled(Flex)`
  height: 80px;
  max-height: 80px;
  background: #fdf5f2;
  width: 100%;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.08);
  z-index: 10;
  max-width: 100vw;
`;

const HeaderSub = styled(Flex)`
  max-width: 100vw;
  width: 100%;
  padding-left: 0;
  @media screen and (min-width: 52em) {
    padding-left: 30px;
  }
`;

const HeaderContent = styled(Flex)`
  height: 80px;
  max-height: 80px;
  width: 100vw;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

const Container = styled(Flex)`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
  padding-top: 40px;
  @media screen and (max-width: 52em) {
    flex-direction: column;
  }

  /* align-self: center; */
`;

const LogoContainer = styled(Box)`
  max-width: 200px;
  margin: 0 auto 32px auto;
`;

const ImgContainer = styled(Flex)`
  display: flex;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const WhiteBullet = styled(Flex)`
  background: #ffffff;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 20px;
  color: #354e79;
  justify-content: center;
  align-items: center;
`;

const SubscribedCall = ({ history, client, match, analytics }) => {
  const hasTrial = history && history.location && history.location.search && history.location.search.indexOf('q=trial') > -1;
  useMount(async () => {
    analytics.track('membership sign up', {
      frequency: (match.params || {}).f,
      content_ids: (match.params || {}).f,
      value: !!hasTrial ? 0 : (match.params || {}).f === 'mm' ? '20.00' : `${isToggleActive('MEMBERSHIP_PRICE_2024')?Constants.MEMBERSHIP_PRICE_2024:Constants.MEMBERSHIP_PRICE}.00`,
      trial: hasTrial || false,
      trialLength: !!hasTrial ? window._env_.REACT_APP_MEMBERSHIP_TRIAL_LENGTH : '',
    });
    // analytics.logRevenue(
    //   (match.params || {}).f === 'mm' ? '20.00' : (match.params || {}).f === 'mi'?'96.00':'192.00',
    //   (match.params || {}).f === 'mm' ? '20.00' : (match.params || {}).f === 'mi'?'96.00':'192.00',
    //   0,
    //   '',
    //   [
    //     {
    //       productId: 'Membership',
    //       name: 'Membership',
    //       price: (match.params || {}).f === 'mm' ? '20.00' : (match.params || {}).f === 'mi'?'96.00':'192.00',
    //       quantity: 1,
    //       category: 'Membership',
    //       trial: hasTrial || false,
    //       trialLength: !!hasTrial ? window._env_.REACT_APP_MEMBERSHIP_TRIAL_LENGTH : '',
    //     }
    //   ],
    //   '',
    // )
    await client.mutate({
      mutation: EMPTY_CALL,
      refetchQueries: [{ query: CURRENT_USER }],
    });
  });

  return (
    <FullLayout2 style={{ zIndex: 10, flexDirection: 'column' }}>
      <Header flex={1}>
        <HeaderSub>
          <HeaderContent>
            <Flex flex={[1, 1, 0]} px="30px" alignItems="center" justifyContent={['center', 'center', 'flex-start']}>
              <Link to="/">
                <LogoHeader height="40px" />
              </Link>
            </Flex>
          </HeaderContent>
        </HeaderSub>
      </Header>

      <Container>
        <ImgContainer flex={1} justifyContent="center" alignItems="center">
          <img src={Imge} alt="" style={{ width: '100%', margin: '0 auto', maxWidth: '330px' }} />
        </ImgContainer>
        <Flex flex={1} flexDirection="column">
          <Heading mb={4}>It's official, you are part of the club!</Heading>

          <Flex flexDirection="row" alignItems="center" mb={4}>
            {/* <WhiteBullet>1</WhiteBullet> */}
            <Text style={{ flex: 1, fontWeight: 300 }}>Your membership is now active and you can start enjoying exclusive discounts and perks</Text>
          </Flex>
{/* 
          <Flex flexDirection="row" alignItems="center" mb={4}>
            <WhiteBullet>2</WhiteBullet>
            <Text style={{ flex: 1, fontWeight: 300 }}>Establish your thyroid baseline to get personalized thyroid care</Text>
          </Flex> */}

          <Button
            variant="primary"
            onClick={() => {
              // history.push({
              //   pathname: '/schedule-care',
              //   state: {
              //     redirectToBaselineSurvey: true
              //   },
              // });
              history.push('/establish-baseline');
            }}
          >
            Continue
          </Button>
        </Flex>
      </Container>

      {/* <Container>
        <LogoContainer>
          <Logo dark={false} />
        </LogoContainer>
        <Card
          style={{
            flexDirection: 'column',
            textAlign: 'center',
            padding: '32px',
          }}
        >
          <Heading>Welcome to Your Paloma Membership!</Heading>

          <Text textAlign="center" style={{ maxWidth: '900px', margin: '32px auto 0 auto' }}>
            Now that your membership is active, the next step is to choose a time for your onboarding call with your Dedicated Care Manager. On this call, your Care Manager will listen {'&'} ask
            questions about your health goals and needs so we can tailor your membership to be most supportive of your thyroid health journey.
          </Text>

          <Button
            style={{ marginTop: '28px' }}
            variant="primary"
            onClick={() => {
              history.push('/schedule-care');
            }}
          >
            Schedule a Call
          </Button>
        </Card>
      </Container> */}
    </FullLayout2>
  );
};

export default withAnalytics(withRouter(withApollo(SubscribedCall)));
