import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Flex, Text } from 'rebass';
import { sleep } from '../../../utils/sleep';
import { TextAreaNoForm } from '../../TextArea';
import { SelectButton } from '../components/Shared';
import { FormerConstants } from '../utils/constants';
import { getDefaultShowDetails, getDefaultValueMultiple, getDefaultValueMultipleDetails } from '../utils/extractBackResponse';

const PickMultipleOtherLayout = ({ options, onNext, nextTitle, defaultValue, onSetWaitingUserInput, isActive, id, isMandatory }) => {
  const [selected, setSelected] = useState(getDefaultValueMultiple(defaultValue));
  const [detailsValue, setDetailsValue] = useState(getDefaultValueMultipleDetails(defaultValue) || '');
  const [showDetails, setShowDetails] = useState(getDefaultShowDetails(defaultValue, options) || false);

  const onSelection = async (s) => {
    const finalSelection = [...selected];
    const ind = finalSelection.findIndex((x) => x === s);
    if (ind > -1) {
      finalSelection.splice(ind, 1);
    } else {
      finalSelection.push(s);
    }
    setSelected(finalSelection);
    const needsDetails = finalSelection.filter((x) => (options.find((xx) => xx.value === x) || {}).hasDetails).length > 0;
    setShowDetails(needsDetails);
    onSetWaitingUserInput(!!needsDetails && (detailsValue || '').length < 2);

    await sleep(FormerConstants.WAIT_AFTER_SELECTION);
  };

  useEffect(
    () => {
      if (!isActive) return;
      if (!selected) {
        onSetWaitingUserInput(false);
        return;
      }

      if (!(options.find((x) => x.value === selected) || {}).hasDetails) {
        onSetWaitingUserInput(false);
        return;
      }

      if (!!detailsValue && (detailsValue || '').length > 1) {
        onSetWaitingUserInput(false);
      } else {
        onSetWaitingUserInput(true);
      }
    },
    [detailsValue, selected, isActive]
  );

  return (
    <>
      <Flex flexDirection={isMobile ? 'column' : 'row'} flexWrap={'wrap'}>
        {options.map((x) => {
          return (
            <SelectButton
              key={`slide-${id}-opt${x.value}`}
              isSelected={selected.indexOf(x.value) > -1}
              text={x.text}
              hasSelection={!!selected.length > 0}
              onClick={() => {
                onSelection(x.value);
              }}
            />
          );
        })}
      </Flex>
      {!!showDetails && (
        <>
          <Text mt={2} mb={2}>
            Give us some details:
          </Text>
          <TextAreaNoForm
            multiline
            containerStyle={{ display: 'block', flex: 'initial' }}
            style={{
              fontSize: '18px',
              width: '100%',
              minHeight: '100px',
            }}
            placeholder="Enter details here"
            onChange={(r) => {
              setDetailsValue(r.target.value);
            }}
            value={detailsValue}
          />
        </>
      )}
      <Button
        style={{ alignSelf: 'flex-start' }}
        mt={4}
        disabled={(!!isMandatory && selected.length<1) || (!!showDetails && (!detailsValue || detailsValue.length < 2))}
        variant={'primary'}
        onClick={() => {
          if (onNext) {
            const selection = [];
            for (let index = 0; index < selected.length; index++) {
              const element = selected[index];

              const needsDetails = (options.find((xx) => xx.value === element) || {}).hasDetails;

              if (needsDetails) {
                selection.push({
                  value: element,
                  details: detailsValue,
                });
              } else {
                selection.push({
                  value: element,
                });
              }
            }

            onNext({
              id,
              selection,
            });
          }
        }}
      >
        {nextTitle || `Let's do it!`}
      </Button>
    </>
  );
};

export default PickMultipleOtherLayout;
