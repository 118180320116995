import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import FullLayout2 from '../../components/FullLayout';
import { LogoHeader } from '../../components/Logo';
import withSession from '../../lib/withSession';
import britCare from '../../static/Brittany.jpeg';
import { sleep } from '../../utils/sleep';

const Header = styled(Flex)`
  height: 80px;
  max-height: 80px;
  background: #fdf5f2;
  width: 100%;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.08);
  z-index: 10;
  max-width: 100vw;
`;

const HeaderSub = styled(Flex)`
  max-width: 100vw;
  width: 100%;
  padding-left: 0;
  @media screen and (min-width: 52em) {
    padding-left: 30px;
  }
`;

const HeaderContent = styled(Flex)`
  height: 80px;
  max-height: 80px;
  width: 100vw;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  margin-top: -40px;
  @media screen and (max-width: 52em) {
    margin-top: 12px;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

`;
const EstablishBaseline = ({ history, session: { dedicatedCareManager } }) => {
  useEffect(()=>{
    const rem = async () => {
      await sleep(500);
      if (window.Beacon) {
        window.Beacon('on', 'ready', () => window.Beacon('destroy'));
      } else {
        await sleep(3000);
        if (window.Beacon) {
          window.Beacon('on', 'ready', () => window.Beacon('destroy'));
        }
      }
      await sleep(5000)
      try {
        window.Beacon('destroy')
      } catch (error) {
        
      }
    };
    if (window.Beacon) {
      window.Beacon('on', 'ready', () => window.Beacon('destroy'));
      return
    }
    rem();
  },[])
  return (
    <FullLayout2 style={{ zIndex: 1 }}>
      <Flex flex={1} flexDirection={'column'}>
        <Header flex={1}>
          <HeaderSub>
            <HeaderContent>
              <Flex flex={[1, 1, 0]} px="30px" alignItems="center" justifyContent={['center', 'center', 'flex-start']}>
                <Link to="/">
                  <LogoHeader height="40px" />
                </Link>
              </Flex>
            </HeaderContent>
          </HeaderSub>
        </Header>
        <Flex justifyContent={'center'} flexDirection='column' alignItems="center" style={{margin: '0 auto', zIndex: 10, maxWidth:'80vw', width: '420px' }}>
          <ImageWrapper>
            <Img src={dedicatedCareManager && dedicatedCareManager.thumbnail ? dedicatedCareManager.thumbnail : britCare} />
          </ImageWrapper>

          <Heading mt={4} textAlign={'center'}>Let’s establish your thyroid baseline!</Heading>
          <Text mt={3} textAlign='center'>
            Thanks to our proprietary baseline questionnaire, we can track and monitor your thyroid health over time, allowing us to provide you with even more personalized Thyroid care
          </Text>
          <Button mt={4} variant={'primary'} onClick={() => history.push('/baseline')}>
            Let's do it!
          </Button>
        </Flex>
      </Flex>
    </FullLayout2>
  );
};

export default withRouter(withSession(EstablishBaseline));
