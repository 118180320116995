import moment from 'moment';
import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Button, Flex, Heading, Text } from 'rebass';
import { WhiteCard } from './WhiteCard';
import { CardHeader } from './CardHeader';
import { withRouter } from 'react-router-dom';
import { CheckRadio } from '../../mySubscriptions/components/CheckRadio';
import { GET_DETAILS_APPOINTMENT_CONFIRM } from '../AppointmentConfirmationInner';
import SpinLoader from '../../../components/SpinLoader';
import { isToggleActive } from '../../../components/featureToggle/toggles';
import withAnalytics from '../../../lib/withAnalytics';

const CANCEL_APPOINTMENT = gql`
  mutation cancelAppointmentFromConfirm($id: String!, $appointmentId: String!, $token: String!) {
    cancelAppointmentFromConfirm(id: $id, appointmentId: $appointmentId, token: $token) {
      ok
    }
  }
`;

const rs = [
  {
    label: `I am waiting for lab results`,
    isChecked: false,
  },
  {
    label: `I found another doctor / in-person`,
    isChecked: false,
  },
  {
    label: `I have a scheduling conflict`,
    isChecked: false,
  },
  {
    label: `Appointment too far out`,
    isChecked: false,
  },
  {
    label: `My insurance is not accepted`,
    isChecked: false,
  },
  {
    label: `Too expensive`,
    isChecked: false,
  },
  {
    label: `The intake form is too long`,
    isChecked: false,
  },
  {
    label: `Other`,
    isChecked: false,
  },
];

const CancelAppointment = ({ appointmentId, athenaId, token, details, history, analytics }) => {
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [reasons, setReasons] = useState(rs);

  return (
    <>
      <Heading>{details.isConfirmed ? 'Upcoming Video Visit' : 'Confirm Your Video Visit'}</Heading>

      <Flex alignItems={'center'} mt={'22px'}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <g clipPath="url(#clip0_1_101)">
            <path
              d="M8.515 1.019C8.34363 1.00635 8.17184 1.00001 8 1V0C8.19654 9.58276e-05 8.393 0.00743414 8.589 0.022L8.515 1.019ZM10.519 1.469C10.1984 1.3453 9.86922 1.24537 9.534 1.17L9.753 0.194C10.136 0.28 10.513 0.394 10.879 0.536L10.519 1.469ZM11.889 2.179C11.746 2.08365 11.5996 1.99359 11.45 1.909L11.943 1.039C12.2849 1.23274 12.6121 1.45132 12.922 1.693L12.307 2.482C12.1714 2.37623 12.032 2.2755 11.889 2.18V2.179ZM13.723 3.969C13.5252 3.68798 13.3069 3.42192 13.07 3.173L13.794 2.483C14.064 2.768 14.314 3.073 14.541 3.393L13.723 3.969ZM14.467 5.321C14.4014 5.16246 14.33 5.00636 14.253 4.853L15.146 4.403C15.3226 4.75409 15.473 5.11774 15.596 5.491L14.646 5.804C14.5923 5.64087 14.5326 5.47976 14.467 5.321ZM14.997 7.828C14.9889 7.48434 14.9555 7.14174 14.897 6.803L15.882 6.633C15.949 7.019 15.988 7.411 15.998 7.803L14.998 7.828H14.997ZM14.866 9.366C14.899 9.196 14.926 9.027 14.947 8.856L15.94 8.979C15.892 9.36915 15.8151 9.75521 15.71 10.134L14.746 9.867C14.792 9.702 14.832 9.535 14.866 9.366ZM13.914 11.745C14.098 11.455 14.26 11.151 14.4 10.837L15.314 11.242C15.154 11.602 14.969 11.948 14.759 12.28L13.914 11.745ZM12.95 12.95C13.072 12.828 13.189 12.702 13.3 12.572L14.058 13.225C13.9296 13.3738 13.7959 13.5179 13.657 13.657L12.95 12.95Z"
              fill="#4E658A"
            />
            <path
              d="M7.99995 1C6.84883 1.00008 5.71549 1.28405 4.70033 1.82674C3.68516 2.36943 2.8195 3.1541 2.18001 4.11125C1.54052 5.0684 1.14694 6.16848 1.03414 7.31406C0.921345 8.45964 1.0928 9.61536 1.53333 10.6789C1.97385 11.7423 2.66985 12.6808 3.55968 13.4111C4.4495 14.1413 5.50569 14.6409 6.63468 14.8655C7.76368 15.0901 8.93064 15.0328 10.0322 14.6987C11.1338 14.3645 12.1359 13.7639 12.95 12.95L13.6569 13.657C12.7267 14.5878 11.5812 15.2747 10.322 15.657C9.06278 16.0393 7.72872 16.105 6.43801 15.8485C5.14731 15.5919 3.93981 15.021 2.9225 14.1862C1.90519 13.3515 1.10949 12.2787 0.605884 11.0629C0.102284 9.84711 -0.0936637 8.52588 0.0354041 7.21627C0.164472 5.90666 0.61457 4.6491 1.34582 3.55502C2.07707 2.46094 3.06689 1.56411 4.22758 0.944003C5.38827 0.323895 6.684 -0.000348732 7.99995 2.81458e-07V1Z"
              fill="#4E658A"
            />
            <path
              d="M7.5 3C7.63261 3 7.75979 3.05268 7.85355 3.14645C7.94732 3.24021 8 3.36739 8 3.5V8.71L11.248 10.566C11.3598 10.6334 11.4408 10.7419 11.4736 10.8683C11.5065 10.9946 11.4886 11.1288 11.4238 11.2422C11.3591 11.3556 11.2525 11.4391 11.127 11.4749C11.0014 11.5108 10.8669 11.4961 10.752 11.434L7.252 9.434C7.17547 9.39029 7.11186 9.32712 7.06761 9.25091C7.02335 9.17469 7.00003 9.08813 7 9V3.5C7 3.36739 7.05268 3.24021 7.14645 3.14645C7.24021 3.05268 7.36739 3 7.5 3Z"
              fill="#4E658A"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_101">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <Text ml={2} fontWeight={400} fontSize={'14px'}>
          {`${moment(details.appointmentDate).format('dddd, MMMM Do')} ${moment(details.appointmentTime, 'HH:mm').format('hh:mm A')} ${details.tz}`}
        </Text>
      </Flex>
      <WhiteCard>
        <CardHeader details={details} />

        {!hasConfirmed ? (
          <Flex flexDirection={'column'}>
            <Text fontWeight={400} mb={4} textAlign={'center'} fontSize={'14px'}>
              Are you sure you want to cancel this visit?
            </Text>

            <Button onClick={() => setHasConfirmed(true)} variant={'primary'} style={{ width: 'fit-content', alignSelf: 'center', padding: '6px 40px', fontSize: '14px' }} mb={4}>
              Yes
            </Button>

            <Text onClick={() => history.goBack()} fontSize={'14px'} fontWeight={500} textAlign={'center'} style={{ textDecoration: 'underline', color: '#007CD6', cursor: 'pointer' }}>
              Go Back
            </Text>
          </Flex>
        ) : (
          <Mutation
            mutation={CANCEL_APPOINTMENT}
            variables={{
              id: athenaId,
              token,
              appointmentId,
            }}
            // refetchQueries={[
            //   {
            //     query: GET_DETAILS_APPOINTMENT_CONFIRM,
            //     variables: {
            //       id: athenaId,
            //       appointmentId,
            //       token,
            //     },
            //   },
            // ]}
            awaitRefetchQueries={true}
            onCompleted={(e) => {
              const hasNewPriceFeature = isToggleActive('USE_NEW_PRICING');
              const reason = reasons
                .filter((x) => x.isChecked)
                .map((x) => x.label)
                .join(', ');
              console.log({ cancel: reason });
              // analytics.logCancel(
              //   hasNewPriceFeature ? -110 : -99,
              //   hasNewPriceFeature ? -110 : -99,
              //   0,
              //   '',
              //   [
              //     {
              //       productId: 'Appointment Cancel',
              //       name: 'Appointment',
              //       price: hasNewPriceFeature ? -110 : -99,
              //       quantity: 1,
              //       category: 'Appointment',
              //     },
              //   ],
              //   '',
              //   null,
              //   reason || ''
              // );
            }}
          >
            {(mutate, { loading, data, error }) => {
              if (!!loading) {
                return (
                  <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Text mb={4} fontWeight={400}>
                      Cancelling your appointment
                    </Text>
                    <SpinLoader />
                  </Flex>
                );
              } else if (error) {
                if (error.graphQLErrors) {
                  return (
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.23, margin: '0 auto' }} width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path d="M27.456 2H12.544L2 12.544V27.456L12.544 38H27.456L38 27.456V12.544L27.456 2ZM22 30H18V26H22V30ZM22 22H18V10H22V22Z" fill="#F24E1E" />
                      </svg>
                      <Text textAlign={'center'} fontWeight={500} mt={4} style={{ opacity: 0.65 }}>
                        {error.graphQLErrors
                          .map((x) => ((x.message || '').toUpperCase().indexOf('JWT') > -1 ? 'Invalid parameters' : (x.message || '').replace('Error: ', '') || 'Unable to retrieve appointment'))
                          .join('\n')}
                      </Text>
                    </>
                  );
                } else {
                  return (
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.23, margin: '0 auto' }} width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path d="M27.456 2H12.544L2 12.544V27.456L12.544 38H27.456L38 27.456V12.544L27.456 2ZM22 30H18V26H22V30ZM22 22H18V10H22V22Z" fill="#F24E1E" />
                      </svg>
                      <Text textAlign={'center'} fontWeight={500} mt={4} style={{ opacity: 0.65 }}>
                        An error occured
                      </Text>
                    </>
                  );
                }
              } else if (data && data.cancelAppointmentFromConfirm) {
                if (data.cancelAppointmentFromConfirm.ok) {
                  return (
                    <>
                      <Text textAlign={'center'}>Your appointment has been cancelled</Text>
                    </>
                  );
                } else {
                  return (
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.23, margin: '0 auto' }} width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path d="M27.456 2H12.544L2 12.544V27.456L12.544 38H27.456L38 27.456V12.544L27.456 2ZM22 30H18V26H22V30ZM22 22H18V10H22V22Z" fill="#F24E1E" />
                      </svg>
                      <Text textAlign={'center'} fontWeight={500} mt={4} style={{ opacity: 0.63 }}>
                        An error occured
                      </Text>
                    </>
                  );
                }
              } else {
                return (
                  <Flex flexDirection={'column'}>
                    <Text fontWeight={400} mb={3} textAlign={'center'} fontSize={'14px'}>
                      Please select a reason for cancelling your visit:
                    </Text>
                    <Flex
                      flexDirection="column"
                      style={{
                        margin: '0 auto',
                        display: 'table',
                      }}
                    >
                      {reasons.map((x, ind) => (
                        <CheckRadio
                          labelStyle={{
                            fontSize: '14px',
                          }}
                          isChecked={x.isChecked}
                          key={x.label}
                          onClick={() => {
                            const r = JSON.parse(JSON.stringify(reasons));
                            r[ind].isChecked = !x.isChecked;
                            setReasons(r);
                          }}
                        >
                          {x.label}
                        </CheckRadio>
                      ))}
                    </Flex>

                    <Button
                      disabled={reasons.filter((x) => !!x.isChecked).length < 1}
                      onClick={() => mutate()}
                      variant={'primary'}
                      style={{ width: 'fit-content', alignSelf: 'center', padding: '6px 40px', fontSize: '14px' }}
                      mb={4}
                    >
                      Submit
                    </Button>
                    <Text onClick={() => mutate()} fontWeight={500} textAlign={'center'} fontSize={'14px'} style={{ textDecoration: 'underline', color: '#007CD6', cursor: 'pointer' }}>
                      Skip
                    </Text>
                  </Flex>
                );
              }
            }}
          </Mutation>
        )}
      </WhiteCard>
    </>
  );
};

export default withRouter(withAnalytics(CancelAppointment));
