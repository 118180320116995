import * as Yup from 'yup';


export const schemaInsuranceInfo = Yup.object().shape({
  memberId: Yup.string().required('Required'),
  // zip: Yup.string()
  //   .trim()
  //   .required('Required')
  //   .test('zip', 'The zip code must be 10 chars', zipLengthCheck)
  //   .test('zip', 'The zip code must be digits only', zipNumbersOnlyCheck),
});

export const schemaInsuranceInfoDependant = Yup.object().shape({
  memberId: Yup.string().required('Required'),
  // zip: Yup.string()
  //   .trim()
  //   .required('Required')
  //   .test('zip', 'The zip code must be 10 chars', zipLengthCheck)
  //   .test('zip', 'The zip code must be digits only', zipNumbersOnlyCheck),
  relationship: Yup.string().required(),
  primary_firstName: Yup.string()
    .trim()
    .required('Required'),
  primary_lastName: Yup.string()
    .trim()
    .required('Required'),
  primary_street1: Yup.string().required('Required'),
  primary_city: Yup.string().required('Required'),
  primary_state: Yup.string().required('Required'),
  primary_zip: Yup.string().required('Required'),
  primary_sex: Yup.string().required('Required'),
});
export const schemaInsuranceInfoBypassMemberId = Yup.object().shape({
});

export const schemaInsuranceInfoDependantBypassMemberId = Yup.object().shape({
  primary_firstName: Yup.string()
    .trim()
    .required('Required'),
  primary_lastName: Yup.string()
    .trim()
    .required('Required'),
  primary_street1: Yup.string().required('Required'),
  primary_city: Yup.string().required('Required'),
  primary_state: Yup.string().required('Required'),
  primary_zip: Yup.string().required('Required'),
  primary_sex: Yup.string().required('Required'),
});

export const schemaInsuranceInfoUnregistered = Yup.object().shape({
  memberId: Yup.string().required('Required'),
  // zip: Yup.string()
  //   .trim()
  //   .required('Required')
  //   .test('zip', 'The zip code must be 10 chars', zipLengthCheck)
  //   .test('zip', 'The zip code must be digits only', zipNumbersOnlyCheck),
  patient_firstName: Yup.string()
    .trim()
    .required('Required'),
  patient_lastName: Yup.string()
    .trim()
    .required('Required'),
  patient_street1: Yup.string().required('Required'),
  patient_city: Yup.string().required('Required'),
  patient_state: Yup.string().required('Required'),
  patient_zip: Yup.string().required('Required'),
  patient_sex: Yup.string().required('Required'),
});

export const schemaInsuranceInfoDependantUnregistered = Yup.object().shape({
  memberId: Yup.string().required('Required'),
  // zip: Yup.string()
  //   .trim()
  //   .required('Required')
  //   .test('zip', 'The zip code must be 10 chars', zipLengthCheck)
  //   .test('zip', 'The zip code must be digits only', zipNumbersOnlyCheck),
  relationship: Yup.string().required(),
  primary_firstName: Yup.string()
    .trim()
    .required('Required'),
  primary_lastName: Yup.string()
    .trim()
    .required('Required'),
  primary_street1: Yup.string().required('Required'),
  primary_city: Yup.string().required('Required'),
  primary_state: Yup.string().required('Required'),
  primary_zip: Yup.string().required('Required'),
  primary_sex: Yup.string().required('Required'),
  patient_firstName: Yup.string()
    .trim()
    .required('Required'),
  patient_lastName: Yup.string()
    .trim()
    .required('Required'),
  patient_street1: Yup.string().required('Required'),
  patient_city: Yup.string().required('Required'),
  patient_state: Yup.string().required('Required'),
  patient_zip: Yup.string().required('Required'),
  patient_sex: Yup.string().required('Required'),
});
