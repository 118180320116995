import React, { Fragment, useState } from 'react';
import { Box, Button, Card, Flex, Heading, Link, Text } from 'rebass';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormField from '../../components/FormField';
import TextInput from '../../components/TextInput';
import { MaskInput } from '../../components/MaskInput';
import styled from 'styled-components';
import CheckboxInput from '../../components/CheckboxInput';
import RegionSelect from '../../components/RegionSelect';
import PlacesAutocomplete from '../../components/addressAutoComplete/PlacesAutocomplete';

const StyledLink = styled(Link)`
  color: #000;
`;

const schema = Yup.object().shape({
  street1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
});

const Profile = ({ values, onSubmit }) => {
  const [isFormExtended, setisFormExtended] = useState(
    values && values.street1 && values.zip && values.state && values.city
  );
  const submitCheck = async (values, actions) => {
    actions.setSubmitting(false);
    onSubmit(
      {
        // consent: values.consent,
        // consenthipaa: values.consenthipaa,
        street1: values.street1,
        street2: values.street2,
        zip: values.zip,
        city: values.city,
        phone: values.phone,
        state: values.state,
      },
      actions
    );
  };

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex width={[1, 1, 3 / 4]}>
        <Card p={4}>
          <Flex flexDirection="column">
            <Box mb={4}>
              <Heading textAlign="center" fontWeight={550} mb={3}>
                Set Up Your Profile
              </Heading>

              <Text textAlign="center">
                Let's get started with some basic information.
              </Text>
            </Box>
            <Formik
              isInitialValid={({ initialValues }) =>
                schema.isValidSync(initialValues)
              }
              initialValues={{
                state: values.state,
                phone: values.phone,
              }}
              validationSchema={schema}
              onSubmit={(values, actions) => submitCheck(values, actions)}
              render={({
                touched,
                errors,
                isValid,
                handleChange,
                handleBlur,
                isSubmitting,
                values,
                setFieldValue,
                setFieldTouched,
              }) => {
                let errStreet1
                if(touched.street1 && errors.street1){
                  errStreet1=errors.street1
                }else if(values && values.street1 && values.street1.indexOf('@')>-1){
                  errStreet1='incorrect street address'
                }else{
                  errStreet1=null
                }
                return (
                  <Form>
                    <Fragment>
                      <div
                        style={{
                          maxHeight:
                            values.street1 || values.city || values.zip
                              ? '0px'
                              : '',
                          opacity:
                            values.street1 || values.city || values.zip
                              ? '0'
                              : '1',
                        }}
                      >
                        <FormField mb={3}>
                          <PlacesAutocomplete
                            fieldname="address"
                            onTextChanged={val => {
                              if (val && val.length > 0) {
                                if (!isFormExtended) {
                                  setisFormExtended(true);
                                }
                              } else {
                                if (
                                  !values.street1 &&
                                  !values.street2 &&
                                  !values.city &&
                                  !values.zip
                                ) {
                                  setisFormExtended(false);
                                }
                              }
                            }}
                            onSelected={val => {
                              setFieldValue('street1', val.street);
                              setFieldValue('city', val.city);
                              setFieldValue('zip', val.zip);
                              setFieldValue('state', val.state);
                              setFieldTouched('street1');
                              setFieldTouched('city');
                              setFieldTouched('zip');
                              setFieldTouched('state');
                            }}
                            onBlur={val => {
                              // console.log('OnBlur');
                              if (!isFormExtended) {
                                setisFormExtended(true);
                              }
                              if (
                                !values.street1 &&
                                !values.street2 &&
                                !values.city &&
                                !values.zip
                              ) {
                                setFieldValue('street1', val);
                                setFieldTouched('street1', true);
                              }
                            }}
                            street1FieldName="street1"
                            cityFieldName="city"
                            zipFieldName="zip"
                            stateFieldName="state"
                          />
                        </FormField>
                      </div>
                      {/* )} */}
                      {isFormExtended && (
                        <>
                          <FormField
                            mb={3}
                            error={errStreet1}
                          >
                            <TextInput
                              placeholder="Address"
                              type="text"
                              name="street1"
                            />
                          </FormField>

                          <FormField
                            mb={3}
                            error={touched.street2 && errors.street2}
                          >
                            <TextInput
                              placeholder="Apartment, Suite, Floor (Optional)"
                              type="text"
                              name="street2"
                            />
                          </FormField>

                          <Flex flexDirection={['column', 'column', 'row']}>
                            <FormField
                              width={[1, 1, 1 / 3]}
                              mr={[0, 0, 3]}
                              mb={3}
                              error={touched.city && errors.city}
                            >
                              <TextInput
                                placeholder="City"
                                type="text"
                                name="city"
                              />
                            </FormField>

                            <FormField
                              width={[1, 1, 1 / 3]}
                              mr={[0, 0, 3]}
                              mb={3}
                              error={touched.state && errors.state}
                            >
                              <RegionSelect name="state" value={values.state} />
                            </FormField>

                            <FormField
                              width={[1, 1, 1 / 3]}
                              mb={3}
                              error={touched.zip && errors.zip}
                            >
                              <TextInput
                                placeholder="Zip"
                                type="text"
                                name="zip"
                              />
                            </FormField>
                          </Flex>
                        </>
                      )}

                      <FormField
                        mt={3}
                        mb={4}
                        error={touched.phone && errors.phone}
                      >
                        <MaskInput
                          name="phone"
                          placeholder="Mobile Phone Number"
                          mask="999-999-9999"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="input_phone"
                        />
                      </FormField>

                      {/* <FormField
                        flexDirection="row"
                        mb={4}
                        error={touched.consent && errors.consent}
                      >
                        <CheckboxInput name="consent" />
                        <Text fontSize={1}>
                          I agree to Paloma Health's&nbsp;
                          <StyledLink
                            href="https://www.palomahealth.com/legal-doc/paloma-health-terms-of-use"
                            target="_blank"
                          >
                            Terms of Service
                          </StyledLink>{' '}
                          and{' '}
                          <StyledLink
                            href="https://www.palomahealth.com/legal-doc/consent-to-telehealth"
                            target="_blank"
                          >
                            Informed Consent
                          </StyledLink>
                        </Text>
                      </FormField>

                      <FormField
                        flexDirection="row"
                        mb={4}
                        error={touched.consenthipaa && errors.consenthipaa}
                      >
                        <CheckboxInput name="consenthipaa" />
                        <Text fontSize={1}>
                          I have read and agree to the terms of Paloma
                          Health's&nbsp;
                          <StyledLink
                            href="https://www.palomahealth.com/legal-doc/notice-of-privacy-practices"
                            target="_blank"
                          >
                            HIPAA Notice
                          </StyledLink>
                        </Text>
                      </FormField> */}

                      <Flex justifyContent="center">
                        <Button
                          disabled={!isValid || isSubmitting || !values || !values.street1 || values.street1.indexOf('@')>-1}
                          variant="primary"
                          width={[1 / 2, 1 / 3]}
                          type="submit"
                          id="btn_next"
                        >
                          Next
                        </Button>
                      </Flex>
                    </Fragment>
                  </Form>
                );
              }}
            />
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

export default Profile;
