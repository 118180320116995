import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Flex } from 'rebass';
import { sleep } from '../../../utils/sleep';
import { SelectButton } from '../components/Shared';
import { FormerConstants } from '../utils/constants';
import { getDefaultValueSingle } from '../utils/extractBackResponse';

const PickOneLayout = ({ options, onNext, defaultValue, id }) => {
  const [selected, setSelected] = useState(getDefaultValueSingle(defaultValue));

  const onSelection = async (selected) => {
    setSelected(selected);
    await sleep(FormerConstants.WAIT_AFTER_SELECTION);
    onNext({
      id,
      selection: [
        {
          value: selected,
        },
      ],
    });
  };

  return (
    <Flex flexDirection={isMobile ? 'column' : 'row'} flexWrap={'wrap'}>
      {options.map((x) => {
        return (
          <SelectButton
            key={`slide-${id}-opt${x.value}`}
            isSelected={selected === x.value}
            text={x.text}
            hasSelection={!!selected}
            onClick={() => {
              onSelection(x.value);
            }}
          />
        );
      })}
    </Flex>
  );
};

export default PickOneLayout;
