import ongoingAdvice from '../../static/ongoingAdvice.png';
import telehealthConsult from '../../static/telemedicineDoctor.png';
import palomaKit from '../../static/palomaKit.png';
import facts from '../../static/facts.png';
import vitaminsBottle2 from '../../static/vitaminsBottle2.png';

export const LearnMores = [
  {
    id: 'onlineConsult',
    image: ongoingAdvice,
    background: '#E7F0FF',
    title: 'Online Visit for Levothyroxine Refills',
    orangeWhatIsThisText: 'What is this?',
    descriptionText:
      'Answer some simple medical questions and chat with a licensed healthcare provider if necessary. In less than 48 hours, a licensed healthcare professional will contact you directly with a status update on your treatment request. Some states also require a phone or video chat with your doctor or nurse practitioner.',
    paragraphs: [
      {
        question: 'How thorough is it?',
        answer:
          'The online visit is extremely comprehensive and often provides the licensed doctor or nurse practitioner with more detailed information than they would be able to collect in person.',
      },
      {
        question: 'Ongoing care',
        answer:
          'Your healthcare professional on the Paloma Health platform is here to help. Message them any time to discuss options.',
      },
    ],
  },
  {
    id: 'liveConsult',
    background: '#FFF9F7',
    image: telehealthConsult,
    title: 'In-Depth Live Telemedicine Visit',
    orangeWhatIsThisText: 'What is this?',
    descriptionText:
      'Chat with a thyroid provider live. 30 minutes for your first appointment, 15 minutes for the following.',
    paragraphs: [
      {
        question: 'We only treat hypothyroidism',
        answer:
          'Our providers will complete a thorough evaluation of all symptoms you are experiencing. They synthesize your symptoms with your labs results. That’s the combination for happiness and healthiness.',
      },
      {
        question: 'Our clinicians will consider all medication options',
        answer:
          "We want you to feel at your absolute best. Our providers will explore all possible thyroid treatments based on your symptoms and history. They'll work with you closely to find the optimal functioning of your thyroid.",
      },
    ],
  },
  {
    id: 'selfie',
    background: '#FFF9F7',
    image: telehealthConsult,
    title: 'Why do you need a photo of my face?',
    orangeWhatIsThisText: 'Identity Verification',
    descriptionText:
      'We use a photo of your face to verify your identity. Telemedicine laws require us to verify this information before prescribing medication.',
    paragraphs: [
      {
        question: 'Who sees this?',
        answer:
          'The photo of your face will be stored securely and will only be shared with the patient support team through our identity verification platform.',
      },
    ],
  },
  {
    id: 'id',
    background: '#FFF9F7',
    image: telehealthConsult,
    title: 'Why do you need a photo of my ID?',
    orangeWhatIsThisText: 'Identity Verification',
    descriptionText:
      'We use your ID photo of your face to verify your identity. Telemedicine laws require us to verify this information before prescribing medication.',
    paragraphs: [
      {
        question: 'Who sees this?',
        answer:
          'The photo of your ID will be stored securely and will only be shared with the patient support team through our identity verification platform.',
      },
      {
        question: 'What types of ID do you accept?',
        answer:
          'We accept any government issued ID that includes your name, date of birth and photo. For example: Driver’s license, passport, Consular ID',
      },
    ],
  },
  {
    id: 'athomekit',
    background: '#E7F0FF',
    image: palomaKit,
    title: 'At home thyroid test kit',
    orangeWhatIsThisText: 'What is this?',
    descriptionText:
      "This at-home test can help you understand how your thyroid is working and can identify the presence of hyperthyroidism, Hashimoto's or hypothyroidism. Expect your results in one week. Test takers must be 18+ and reside in the US",
    paragraphs: [
      {
        question: 'What does it test?',
        answer:
          'While many labs only look at Thyroid Stimulating Hormone (TSH) to assess thyroid function, we believe it is critical  to also measure Free Triiodothyronine (fT3), Free Thyroxine (fT4) and TPO antibodies. Reverse T3 and Vitamin D are available as add-ons',
      },
      {
        question: 'How does it work?',
        answer:
          'Watch this quick 2’ video to understand how to collect a sample',
        video: 'https://player.vimeo.com/video/351018081',
        // <iframe title="vimeo-player" src="" width="640" height="360" frameborder="0" allowfullscreen></iframe>
      },
    ],
  },
  {
    id: 'vitamins',
    background: '#FFF9F7',
    image: vitaminsBottle2,
    title: 'Daily Thyroid Care Supplement',
    orangeWhatIsThisText: 'What is this?',
    descriptionText:
      'Daily Thyroid Care is an iodine-free combination of vitamins, minerals, and herbs that provides essential nutrients required for optimal thyroid gland function.',
    paragraphs: [
      {
        question: '9 essential nutrients',
        answer:
          'With 9 essential nutrients and backed by clinical research, the Daily Thyroid Care formulation helps to minimize hypothyroid symptoms by supporting thyroid hormone production, promoting proper immune function and calming inflammation.',
      },
      {
        question: 'View Supplements Fact',

        image: facts,
        // <iframe title="vimeo-player" src="" width="640" height="360" frameborder="0" allowfullscreen></iframe>
      },
    ],
  },
];
