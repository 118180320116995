import React from 'react';
import { Flex, Text } from 'rebass';

import styled from 'styled-components';
import { Icon } from '@iconify/react';
import editFilled from '@iconify/icons-ant-design/delete-outlined';

const SelectedMedicationCardStyle = styled(Flex)`
  background-color: white;
  width: 100%;
  flex-direction: row;
  padding: 16px 20px;
  border: 1px solid #e7f0ff;
  border-radius: 10px;
`;

const SelectedMedicationCardName = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  color: #354e79;
  flex: 1;
`;

export const SelectedAllergyCard = ({ name, onClick }) => {
  return (
    <SelectedMedicationCardStyle mt={3} onClick={onClick}>
      <SelectedMedicationCardName>{name}</SelectedMedicationCardName>

      <Icon icon={editFilled} style={{ color: '#354e79', fontSize: '30px' }} />
    </SelectedMedicationCardStyle>
  );
};
