import React from 'react';
import IconContainer from './IconContainer';

const Stethoscope = props => (
  <IconContainer {...props}>
    <svg data-name="Stethoscope" viewBox="0 0 112.85 176.27">
      <path
        d="M68.6 0c2.82-.21 6.34 1.83 6 5 .25 2-1.18 2.62-2.29 3.94a15.06 15.06 0 0 1-3.74.45c-3.89-.21-7.17.94-10-2.35-4.09 1.58-4.93 6.05-5 10-.69 5.74-.29 11.25-.27 17v27a25.14 25.14 0 0 0 .54 4.75 47.38 47.38 0 0 0 9.77 15.24c3.64 3.54 8.7 6.67 13.84 7.13 6.3 1.53 13.44-.83 18.35-4.92A43.69 43.69 0 0 0 108.39 63c0-13.32.27-26.67-.21-40-.83-4.71-.22-11.63-3.28-15.5a5.34 5.34 0 0 1-4.3 1.87c-2.93-.05-6.08.29-8.91-.42a29.73 29.73 0 0 1-2.1-2.24c-.81-4 1-6.29 4.94-6.71 3.17.26 8-1 10.17 1.87 1.28 1.23 3.1 1.21 4.07 2.93 2.86 4.2 3.23 10.38 3.74 15.26.6 17.65.21 35.33.31 53a32.51 32.51 0 0 1-11.13 25.16c-3.7 3.42-8.14 5.09-12.64 7.05-.31 4.23-2.64 6-5.89 8.08-.54 15.46-2.4 32.82-9.46 46.79-3.54 6.61-8.71 12.69-16.07 15-3.73 1.38-8.18 1.22-12.09.92-9.45-1.1-17-7.46-21.33-15.67-5.78-10.75-7.09-23.14-7.42-35.17-2.38 0-4.73-.23-5.66-2.77-.85-1.32-.5-2.58-.6-4A19.2 19.2 0 0 1 3.2 90.66c4.14-6.48 13-10.08 20.41-7.74 8.34 2 14.62 10.62 14 19.14-.12 7.12-4.44 13.13-10.56 16.6-.3 1.36-.23 2.77-.54 4.13-.74.76-1.51 1.5-2.3 2.21q-1.32.12-2.64.21c-.46 10.82 1.3 22.06 5.89 31.91 3.38 7 9.09 12.55 16.91 14.06a20.36 20.36 0 0 0 18.32-4c5.18-4.35 8.36-11.3 10.45-17.59a103.73 103.73 0 0 0 4.65-24.38c.84-3.93.66-8.17.73-12.19-3.67-1.63-5.48-3.91-6.09-7.91-1.32-.92-2.9-1-4.26-1.72a33.24 33.24 0 0 1-13.65-11.3c-4.15-6-5.79-12.83-5.94-20v-50c-.11-3.61.66-7.21 1.21-10.76 1.24-4.35 3.75-8 8.59-8.44C61.26-.66 64.54.09 68.6 0z"
        fill="#024c69"
      />
      <path
        d="M62.63 2.2c2.61 0 5.24-.07 7.85 0a2.77 2.77 0 0 1 1.61 4.35c-1.78 1.3-3.48 1-5.53 1s-3.85.39-5.67-.89c-.77-1.9-.61-3.98 1.74-4.46zM92.84 2.31a69.18 69.18 0 0 1 8.64-.11c3 .45 2.78 5.08-.29 5.41-2.92-.06-6 .33-8.88-.36-1.26-1.63-1.59-3.85.53-4.94zM53.28 70.23c3.91 7 8.43 13.56 15.71 17.4 5.88 3.23 12.72 3.54 19.13 2 8.4-2.62 14.12-9.21 18.23-16.7.47-.49 1.15-2 1.75-2.2 1.46 8.27-2.45 17.58-8.53 23.27-5.39 5.08-13.58 8.44-21 7.31-10.89-.59-20.9-8.93-24-19.28-1.47-3.83-1.02-7.76-1.29-11.8zM22.07 87c7 1.48 11.55 8.12 11.3 15.1a14.82 14.82 0 0 1-8.95 12.81c-5.58 2.61-12.75.6-16.58-4.13-3.36-3.8-3.79-8.57-2.94-13.4 1.59-5.28 6-9.8 11.67-10.35 1.94-1.14 3.43-.18 5.5-.03z"
        fill="#faa9a9"
      />
      <path
        d="M17.74 94.55c5.28-1.14 9.85 5.18 7.18 9.87a7.1 7.1 0 0 1-9.05 3.31 7.1 7.1 0 0 1-3.72-8.21 6.13 6.13 0 0 1 5.59-4.97z"
        fill="#024c69"
      />
    </svg>
  </IconContainer>
);

export default Stethoscope;
