import React from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { PREVIEW_LIMIT, getWarnings } from './shared';

const Container = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding: 20px 16px 40px 16px;
  min-height: 80vh;
  max-height: 95vh;
  overflow-y: auto;
  @media screen and (max-width: 52em) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const Availables = ({ availables, helpscoutId, tree }) => {
  if (!availables) return null;
  return (
    <Flex flex={1}>
      <Droppable droppableId="availables">
        {(provided2, snapshot) => {
          return (
            <div ref={provided2.innerRef} {...provided2.droppableProps} style={{ minHeight: '100%', width: '100%', padding: '12px', border: snapshot.isDraggingOver?'2px dashed rgb(227, 117, 117)': '2px solid rgba(255,255,255,0)' }}>
              {availables.map((x, i) => {
                const errs = getWarnings(x, helpscoutId);
                return (
                  <Draggable key={`avail${x.id}`} draggableId={`avail${x.id}`} index={i}>
                    {(provided, snapshot) => (
                      <div key={i} ref={provided.innerRef} isDragging={snapshot.isDragging} {...provided.dragHandleProps} {...provided.draggableProps}>
                        <Flex flexDirection={'row'} mb={2}>
                          <Box
                            style={{
                              marginLeft: `8px`,
                              background: 'white',
                              border: `1px solid ${!errs || errs.length < 1 ? '#717171' : 'orange'}`,
                              padding: '8px 12px',
                              borderRadius: '4px',
                            }}
                          >
                            <Flex flexDirection={'column'}>
                              <Flex flexDirection={'column'}>
                                <Text
                                  fontWeight={500}
                                  style={{ textDecoration: 'underline', cursor: 'pointer', color: '#07c' }}
                                  onClick={() => {
                                    window.open(`https://secure.helpscout.net/conversation/${x.id}`, '_blank');
                                  }}
                                >
                                  #{x.id}
                                </Text>
                                <Text fontWeight={300}>Last user update: {(x.details || {}).lastUserUpdate}</Text>
                                <Text>{((x.details || {}).preview || '').length > PREVIEW_LIMIT ? (x.details || {}).preview.substring(0, PREVIEW_LIMIT) + '...' : (x.details || {}).preview}</Text>
                                {errs.map((er) => {
                                  return (
                                    <Text fontWeight={300} color={'orange'}>
                                      - {er}
                                    </Text>
                                  );
                                })}
                              </Flex>
                            </Flex>
                          </Box>
                        </Flex>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          );
        }}
      </Droppable>
    </Flex>
  );
};
