import React from 'react';
import { Flex, Heading } from 'rebass';
import * as Yup from 'yup';
import TextInput, { TextInputWithLabel } from '../../../../components/TextInput';
import { Form, Formik } from 'formik';
import FormField from '../../../../components/FormField';
import { EditModalButtons } from '../Builder';

export const RatingEdit = ({ close, save, properties }) => {
  const schema = Yup.object().shape({
    question: Yup.string()
      .trim()
      .min(2, 'Question must be greater than 1 character')
      .required('Required'),
    researchId: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9_]+$/, 'No special characters or space allowed except underscore (_)')
      .nullable(true),
  });
  return (
    <Flex flex={1} flexDirection={'column'} width={'600px'}>
      <Heading textAlign={'center'} mb={4}>
        Edit Rating
      </Heading>
      <Formik
        initialValues={{ ...properties }}
        isInitialValid={() => {
          return properties && properties.question && properties.question.length > 1;
        }}
        validationSchema={schema}
        onSubmit={(values, actions) => save(values, actions)}
        render={({ touched, errors, isValid, values, submitForm }) => (
          <Form>
            <FormField mb={3} error={touched.question && errors.question}>
              <TextInputWithLabel placeholder="Question *" name="question" id="input_question" />
            </FormField>
            <FormField mb={3} error={touched.description && errors.description}>
              <TextInputWithLabel placeholder="description" name="description" id="input_description" />
            </FormField>
            <FormField mb={3} error={touched.researchId && errors.researchId}>
              <TextInputWithLabel placeholder="research Id (if set, data can be used for data analysis)" name="researchId" id="input_researchId" />
            </FormField>
            <FormField
              flexDirection="row"
              mb={3}
              style={{
                alignContent: 'center',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
              error={touched.mustBeInherited && errors.mustBeInherited}
            >
              <TextInput type="checkbox" checked={values.mustBeInherited} id={`mustBeInherited`} name="mustBeInherited" />
              <label htmlFor="mustBeInherited" style={{ marginLeft: '16px' }}>
                must be inherited (templates created from this one will mandatorily have this item)
              </label>
            </FormField>
            <EditModalButtons close={close} save={submitForm} isValid={isValid} />
          </Form>
        )}
      />
    </Flex>
  );
};
