import React, { Component, Fragment } from 'react';
import { Box, Button, Card, Flex, Text, Heading } from 'rebass';
import * as Yup from 'yup';
import { Mutation, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';

import FullLayout from '../components/FullLayout';
import Header from '../components/Header';
import withAnalytics from '../lib/withAnalytics';
import { BATCH_UPLOAD_REFERRALS } from '../graphql';
import Dropzone from 'react-dropzone';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('invalid email address')
    .required('Required'),
  password: Yup.string()
    .trim()
    .required(),
});

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const CardContainer = styled(Flex)`
  max-width: 100%;
`;

const csvStringToArray = (strData, header = true) => {
  //const objPattern = new RegExp(("(\\,|\\r?\\n|\\r|^)(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|([^\\,\\r\\n]*))"),"gi");
  const objPattern = new RegExp(
    '(\\,|\\r?\\n|\\r|^)(?:"((?:\\\\.|""|[^\\\\"])*)"|([^\\,"\\r\\n]*))',
    'gi'
  );
  let arrMatches = null,
    arrData = [[]];
  while ((arrMatches = objPattern.exec(strData))) {
    if (arrMatches[1].length && arrMatches[1] !== ',') arrData.push([]);
    arrData[arrData.length - 1].push(
      arrMatches[2]
        ? arrMatches[2].replace(new RegExp('[\\\\"](.)', 'g'), '$1')
        : arrMatches[3]
    );
  }
  if (header) {
    let hData = arrData.shift();
    let hashData = arrData.map(row => {
      let i = 0;
      return hData.reduce((acc, key) => {
        acc[key] = i === 1 ? parseInt(row[i++] * 100, 10) : row[i++];
        return acc;
      }, {});
    });
    return hashData;
  } else {
    return arrData;
  }
};

const getColumnWidth = (rows, accessor, headerText, dataType = '') => {
  const maxWidth = 400;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...rows.map(
      row =>
        (
          `${
            dataType === 'money'
              ? `$ ${row[accessor]}`
              : // : dataType === 'date'
                // ? moment(row[accessor]).format('MM-DD-YYYY hh:mm a')
                row[accessor]
          }` || ''
        ).length
    ),
    headerText.length + 4
  );
  return Math.min(maxWidth, cellLength * magicSpacing);
};

class BatchBalanceAdjustment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isProcessing: false,
      currentProcessingIndex: 0,
    };
  }
  componentDidMount() {
    this.props.analytics.page();
  }

  processUpload = async () => {
    const { data } = this.state;
    this.setState({
      isProcessing: true,
    });
    console.log('data', data);
    const dtaCopy = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      dtaCopy.push(JSON.parse(JSON.stringify(element)));
    }
    for (let index = 0; index < dtaCopy.length; index++) {
      const element = dtaCopy[index];
      this.setState({
        currentProcessingIndex: index,
      });
      await this.handleSubmit(element, index);
    }
    this.setState(
      {
        isProcessing: false,
      },
      () => alert('Upload complete')
    );
  };

  handleSubmit = async (values, idx) => {
    const { toastManager, client } = this.props;
    const { data } = this.state;
    try {
      const res = await client.mutate({
        mutation: BATCH_UPLOAD_REFERRALS,
        variables: {
          patientId: values.patientId,
          centisizedAmount: values.amount,
          description: values.description,
        },
      });
      const dta = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (index === idx) {
          dta.push({
            ...element,
            newOrUpdate: res.data.batchUploadReferrals.newOrUpdate,
            success: res.data.batchUploadReferrals.result,
          });
        } else {
          dta.push(element);
        }
      }
      this.setState({ data: dta });
      console.log('RES Up', res);
    } catch (error) {
      console.log('ERR', error);
      // error.graphQLErrors.forEach(error => {
      //   toastManager.add(error.message, {
      //     appearance: 'error',
      //     autoDismiss: true,
      //   });
      // });
    }
  };

  fileAcceptedFront = f => {
    for (let index = 0; index < f.length; index++) {
      const element = f[index];

      const reader = new FileReader();
      reader.onload = event => {
        let fls = f;
        console.log('fls', event.target);
        var decodedString = atob(
          event.target.result.replace('data:text/csv;base64,', '')
        );
        const aaa = csvStringToArray(decodedString, true);
        console.log(aaa); // Outputs: "Hello World!"

        this.setState({
          data: aaa,
        });
      };
      reader.readAsDataURL(element);
    }
  };

  render() {
    const { data, currentProcessingIndex, isProcessing } = this.state;
    const columns = [
      {
        Header: 'Patient ID',
        accessor: 'patientId',
        Cell: row => {
          console.log('ROWval', row.value);
          return <Text textAlign="right">{row.value}</Text>;
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: row => {
          console.log('ROW', row);
          return (
            <Text
              textAlign="right"
              style={{ color: row.value < 0 ? 'rgb(237, 183, 168)' : '' }}
            >{`$ ${((row.value || 0) / 100).toFixed(2)}`}</Text>
          );
        },
        width: getColumnWidth(data, 'centisizedAmount', 'amount', 'money'),
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'New / Update',
        accessor: 'newOrUpdate',
      },
      {
        Header: 'Success',
        accessor: 'success',
      },
    ];

    return (
      <Fragment>
        <Container>
          <Flex flex={1}>
            <Header dark={false} />
          </Flex>
          <Flex flex={1}>
            <CardContainer flex={1} px={3}>
              <Card p={4} width="80%">
                <Flex flexDirection="column" style={{ position: 'relative' }}>
                  <Box mb={4}>
                    <Heading textAlign="center">
                      Batch Balance Adjustment
                    </Heading>
                  </Box>

                  <Dropzone
                    noClick={true}
                    multiple={false}
                    onDropRejected={() => {}}
                    onDropAccepted={f => {
                      this.fileAcceptedFront(f);
                    }}
                    maxSize={10000000}
                    style={{
                      width: '100%',
                      marginBottom: 36,
                    }}
                  >
                    {({ getRootProps, getInputProps, isDragActive, open }) => (
                      <Flex
                        flexDirection="column"
                        style={{
                          width: '100%',
                          cursor: 'pointer',
                        }}
                        {...getRootProps()}
                        onClick={() => {
                          if (data && data.length > 0) return;
                          open();
                        }}
                      >
                        <ReactTable
                          defaultPageSize={10}
                          data={data}
                          columns={columns}
                        ></ReactTable>
                        <Text
                          style={{
                            fontWeight: 300,
                            textAlign: 'center',
                          }}
                          mb={2}
                        >
                          Drag or click to insert the csv file
                        </Text>

                        <input type="file" {...getInputProps()} />
                      </Flex>
                    )}
                  </Dropzone>
                  {isProcessing && (
                    <Card
                      style={{
                        width: '50%',
                        position: 'absolute',
                        top: 'calc(50% - 6px)',
                        left: '25%',
                      }}
                    >
                      <Flex flexDirection="row">
                        <Box
                          style={{
                            flex: currentProcessingIndex,
                            background: '#344f79',
                            height: '12px',
                          }}
                        ></Box>
                        <Box
                          style={{
                            flex: data.length - currentProcessingIndex,
                            background: '#d9e6f9',
                            height: '12px',
                          }}
                        ></Box>
                      </Flex>
                      <Flex
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 12,
                        }}
                      >
                        <Text>
                          completed: {currentProcessingIndex} / {data.length}
                        </Text>
                      </Flex>
                    </Card>
                  )}
                </Flex>
                <Flex
                  flexDirection="row"
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '32px',
                  }}
                >
                  <Button
                    disabled={!data || data.length < 1}
                    variant="primary"
                    style={{ marginRight: 24 }}
                    onClick={this.processUpload}
                  >
                    Upload
                  </Button>
                  <Button
                    variant="pink"
                    onClick={() => {
                      this.setState({
                        data: [],
                      });
                    }}
                  >
                    Clear
                  </Button>
                </Flex>
              </Card>
            </CardContainer>
          </Flex>
        </Container>
        <FullLayout />
      </Fragment>
    );
  }
}

export default withApollo(
  withAnalytics(withToastManager(BatchBalanceAdjustment))
);
