import React from 'react';
import { Heading } from 'rebass';

export const HeadingCtrl = ({ properties }) => {
  return (
    <>
      <Heading>{`${(properties || {}).question || 'Title'}`}</Heading>
    </>
  );
};
