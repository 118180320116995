import React, { Fragment } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormField from '../../components/FormField';
import RadioInput from '../../components/RadioInput';

const schema = Yup.object().shape({
  diagnosed: Yup.string().required('Required'),
});

const schemaCa = Yup.object().shape({
  diagnosed: Yup.string().required('Required'),
  medicationHistoryConsentVerified: Yup.string().required('Required'),
});

const Extended = ({values, onSubmit}) => {
  return (
    <Flex flexDirection="column" alignItems="center">
    <Flex width={[1, 1, 3 / 4]} justifyContent='center'>
        {/* <Card p={4}> */}
          <Flex flexDirection="column">
            <Box mb={4}>
              <Heading textAlign="center" mb={3}>
                Set Up Your Profile
              </Heading>
            </Box>
            <Formik
              isInitialValid={({ initialValues }) =>
                schema.isValidSync(initialValues)
              }
              initialValues={{
                ...values,
                diagnosed: undefined,
                medicationHistoryConsentVerified:
                values && values.state !== 'CA'
                    ? true
                    : undefined,
              }}
              validationSchema={
                values && values.state !== 'CA' ? schema : schemaCa
              }
              onSubmit={(values, actions) =>{
                onSubmit(values, actions)}
              }
              render={({
                touched,
                errors,
                isValid,
                isSubmitting,
                setFieldValue,
                values,
                handleBlur,
                handleChange,
              }) => (
                <Form>
                  <Fragment>
                    
                      <>
                        <Text mb={3} fontSize={3} textAlign="center">
                          Have you been diagnosed with hypothyroidism?
                        </Text>
                        <FormField
                          flexDirection="row"
                          justifyContent="center"
                          mb={4}
                          error={touched.diagnosed && errors.diagnosed}
                        >
                          <RadioInput
                            name="diagnosed"
                            value={true}
                            label="Yes"
                            mr={5}
                          />
                          <RadioInput
                            name="diagnosed"
                            value={false}
                            label="No"
                          />
                        </FormField>
                      </>
                    
                    {values && values.state === 'CA' && (
                      <Fragment>
                        <Text mb={3} fontSize={3} textAlign="center">
                          Medication history consent
                        </Text>
                        <Text
                          textAlign="center"
                          fontWeight={300}
                          fontSize={2}
                          color="#aaa"
                          mb={3}
                        >
                          Your medication history gives our physicians vital
                          information to treat you.
                        </Text>
                        <FormField
                          flexDirection="row"
                          justifyContent="center"
                          mb={4}
                          error={
                            touched.medicationHistoryConsentVerified &&
                            errors.medicationHistoryConsentVerified
                          }
                        >
                          <RadioInput
                            name="medicationHistoryConsentVerified"
                            value={true}
                            label="Yes"
                            mr={5}
                          />
                          <RadioInput
                            name="medicationHistoryConsentVerified"
                            value={false}
                            label="No"
                          />
                        </FormField>
                      </Fragment>
                    )}

                    <Flex justifyContent="center">
                      <Button
                        disabled={!isValid || isSubmitting}
                        variant="primary"
                        width={[1 / 2, 1 / 3]}
                        type="submit"
                        id="btn_complete"
                      >
                        Complete
                      </Button>
                    </Flex>
                  </Fragment>
                </Form>
              )}
            />
          </Flex>
        {/* </Card> */}
      </Flex>
    </Flex>
  );
}

export default Extended