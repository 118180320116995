import React from 'react';
import { withRouter } from 'react-router';
import { Box, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';

import FullLayout from '../components/FullLayout';
import withIsAdmin from '../lib/withIsAdmin';
import withSession from '../lib/withSession';
import { compose, withApollo } from 'react-apollo';
import { DoctorsConsult } from './updatePaymentMethods/DoctorsConsult';
import { Kits } from './updatePaymentMethods/Kits';
import {Membership} from './updatePaymentMethods/Membership'
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
  padding-left: 60px;
  padding-top: 40px;
  width: 100%;
  @media screen and (max-width: 480px) {
    padding-left: 0;
    padding-top: 0;
    padding: 12px;
  }
`;

const Head = styled(Heading)`
  margin-bottom: 36px;
  @media screen and (max-width: 480px) {
    text-align: center;
  margin-top: 36px;
  }
`;

const Container = styled(Flex)`
  flex: 1;
  background: white;
  border-radius: 8px 8px 8px 8px;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 2.41029px 24.1029px rgba(0, 0, 0, 0.1);
`;

const Divider = styled(Box)`
  height: 0px;
  width: 100%;
  border-bottom: 1px solid #d1d1d1;
  margin: 6px 0;
`;

const MyPaymentMethods = ({ history, session }) => {
  const isMobileAppEmbed = !!window && !!window.ReactNativeWebView
  return(
  <Wrapper style={{paddingLeft: !!isMobileAppEmbed?'0': ''}}>
    <Head style={{paddingLeft: !!isMobileAppEmbed?'12px': '0'}}>Your payment methods</Head>
    <Container>
      <DoctorsConsult />
      <Divider />
      <Kits session={session} />
      <Divider />
      <Membership session={session} />
    </Container>

    <Text mt={4} style={{textAlign: isMobile?'center':'left'}}>
      You can also update your payment method for your subscriptions&nbsp;
      <span
        onClick={() => {
          history.push('/my-subscriptions');
        }}
        style={{
          fontWeight: 500,
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        here
      </span>
    </Text>

    <FullLayout />
  </Wrapper>
)};

export default compose(
  withRouter,
  withSession,
  withIsAdmin,
  withApollo
)(MyPaymentMethods);
