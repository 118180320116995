import React, { Component, Fragment, useState } from 'react';
import { Flex, Box, Button, Heading, Card, Text } from 'rebass';
import { withWizard } from 'react-albus';
import styled, { css } from 'styled-components';
import { Formik, Form } from 'formik';
import { Mutation } from 'react-apollo';

import Select from 'react-select';
import { Query, compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import ConfirmModal from '../ConfirmModal';
import { APPOINTMENTS, CANCEL_APPT, CURRENT_USER, GET_HYPO_MEDICATIONS_ON_FILE, NOTIFY_NEED_CONFIRM_HYPO } from '../../graphql';
import PleaseWait from '../PleaseWait';
import { Icon } from '@iconify/react';
import editFilled from '@iconify/icons-ant-design/edit-filled';
import ScrollAnimation from 'react-animate-on-scroll';
import { fadeInDown } from '../../utils/animationsStyleBuilder';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
`;
export const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1 0 auto;
  background: ${(props) => (props.disabled ? props.theme.bg.wash : props.theme.bg.default)};
  font-weight: 400;
  font-size: 16px;
  border: 1px solid ${(props) => (props.disabled ? props.theme.bg.border : props.theme.bg.inactive)};
  border-radius: 10px;
  padding: 18px 12px;
  box-shadow: none;
  margin-bottom: 18px;
  ${(props) =>
    props.type === 'checkbox' &&
    css`
      flex: initial;
      width: initial;
      margin-right: 0.5em;
    `} &::placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:-moz-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:-ms-input-placeholder {
    color: ${(props) => props.theme.text.placeholder};
  }
  &:focus {
    border-color: ${(props) => props.theme.brand.default};
    outline: none;
  }
  &[type='file'] {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: hidden;
  }
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
`;
const Input = (props) => {
  return (
    <Flex flex={1}>
      <StyledInput {...props} onKeyDown={({ key }) => (key === 'Enter' ? props.submitForm() : null)} />
    </Flex>
  );
};

const T3OnlyModalConfirm = ({ onClose, onCancel, onContinue, appointmentId }) => {
  const [currentStep, setCurrentStep] = useState('CONFIRM_MONOTHERAPY');
  const [isCancelling, setIsCancelling] = useState(false);

  if (currentStep === 'CONFIRM_MONOTHERAPY') {
    return (
      <Flex flexDirection={'column'}>
        <Text fontSize={'20px'} mb={4} fontWeight={400} color="#344f77" textAlign="center">
          Confirm Your Current Thyroid Medication
        </Text>
        <Text mb={3}>We noticed you've selected T3 as your only thyroid medication. Are you currently on T3 monotherapy (using T3 as your sole thyroid medication)?</Text>
        <Text mb={4}>Please select:</Text>

        <Button variant={'pink'} mb={2} onClick={() => setCurrentStep('MONOTHERAPY')}>
          Yes, I'm only using T3
        </Button>
        <Button variant={'primary'} onClick={() => setCurrentStep('NOT_MONOTHERAPY')}>
          No, I use other thyroid medications
        </Button>
      </Flex>
    );
  }
  if (currentStep === 'NOT_MONOTHERAPY') {
    return (
      <Flex flexDirection={'column'}>
        <Text fontSize={'20px'} mb={4} fontWeight={400} color="#344f77" textAlign="center">
          Review Your Thyroid Medication List
        </Text>
        <Text mb={3}>It seems you’re not on T3 monotherapy. To ensure we provide the best care tailored to your needs, please review and update your list of current thyroid medications.</Text>
        <Text mb={4}>This helps us understand your treatment history and plan your care effectively.</Text>

        <Button variant={'primary'} onClick={onClose}>
          OK
        </Button>
      </Flex>
    );
  }
  if (currentStep === 'MONOTHERAPY') {
    return (
      <Flex flexDirection={'column'}>
        <Text fontSize={'20px'} mb={4} fontWeight={400} color="#344f77" textAlign="center">
          Important Notice Regarding T3 Monotherapy
        </Text>
        <Text mb={3}>
          You have indicated that you are currently on T3 monotherapy. At Paloma Health, our priority is to provide safe and effective thyroid care based on the latest evidence-based clinical
          protocols.
        </Text>
        <Text mb={4}>
          We want to inform you that we do not support T3 as the sole medication for thyroid treatment. Instead, we focus on comprehensive treatment plans that may include T4 and T3 combination
          therapies, tailored to your individual health needs.
        </Text>
        {!!appointmentId ? (
          <Mutation
            mutation={CANCEL_APPT}
            variables={{
              id: appointmentId,
              bypassReasonEmail: true,
            }}
            refetchQueries={[{ query: APPOINTMENTS }, { query: CURRENT_USER }]}
            awaitRefetchQueries
            onCompleted={(e) => {
              setIsCancelling(false);
              onCancel();
            }}
            onError={() => {
              setIsCancelling(false);
              // onCancel()
            }}
          >
            {(cancelAppointment, { loading }) => (
              <>
                <Button mb={2} disabled={loading || !!isCancelling} variant={'primary'} onClick={onContinue}>
                  Continue
                </Button>
                <Button
                  disabled={loading || !!isCancelling}
                  variant={'pink'}
                  onClick={() => {
                    setIsCancelling(true);
                    cancelAppointment();
                  }}
                >
                  Cancel Visit
                </Button>
              </>
            )}
          </Mutation>
        ) : (
          <>
            <Button variant={'primary'} mb={2} onClick={onContinue}>
              Continue
            </Button>
            <Button variant={'pink'} onClick={() => onCancel()}>
              Go back to dashboard
            </Button>
          </>
        )}
      </Flex>
    );
  }
  if (currentStep === 'CONFIRM_CANCEL') {
    return (
      <Flex flexDirection={'column'}>
        <Text fontSize={'20px'} mb={4} fontWeight={400} color="#344f77" textAlign="center">
          Cancellation Confirmation
        </Text>
        <Text mb={4}>Are you sure you want to cancel your visit?</Text>

        <Button variant={'pink'} mb={2} onClick={onCancel}>
          Yes
        </Button>
        <Button variant={'primary'} onClick={onContinue}>
          No
        </Button>
      </Flex>
    );
  }

  return <Flex />;
};
class MedicationPick extends Component {
  constructor(props) {
    super(props);
    this.selects = React.createRef();
    this.noOption = React.createRef();

    if (props.data && props.data.skipIfNotQuestionId) {
      let isFound = false;
      for (let index = 0; index < props.userData.length; index++) {
        const element = props.userData[index];
        for (let index = 0; index < element.values.length; index++) {
          const val = element.values[index];
          if (val.questionId && val.questionId === props.data.skipIfNotQuestionId) {
            isFound = true;
            break;
          }
        }
      }
      if (!isFound) {
        props.wizard.next();
        return;
      }
    }

    const { currentMeds } = this.props;
    const arr = [];
    if (!currentMeds || !currentMeds.getHypothyroidismMedicationOnFile || currentMeds.getHypothyroidismMedicationOnFile.length < 1) {
      this.state = {
        display: false,
        selected: [],
        active: undefined,
        confirmNoMedications: false,
        tempValues: {},
        currentMeds: [],
        showDropDown: true,
        confirmMonotherapy: false,
      };

      return;
    }

    for (let index = 0; index < currentMeds.getHypothyroidismMedicationOnFile.length; index++) {
      const element = currentMeds.getHypothyroidismMedicationOnFile[index];
      arr.push({
        dose: element.dose,
        frequency: element.frequency,
        label: element.medication,
        questionId: element.medicationId.toString(),
        value: element.medicationId.toString(),
        isChecked: false,
      });
    }

    this.state = {
      display: false,
      selected: [],
      active: undefined,
      confirmNoMedications: false,
      tempValues: {},
      currentMeds: arr,
      showDropDown: false,
      confirmMonotherapy: false,
    };
  }

  componentDidMount = () => {};

  confirmModalForm = (props) => {
    const { selected, active, tempValues } = this.state;
    const current = JSON.parse(JSON.stringify(active));
    if (!active.hasDosage) {
      current.dose = tempValues.dose;
    }
    current.frequency = tempValues.frequency;

    const sel = selected.filter((x) => x.value !== active.value);
    sel.push(current);

    this.setState({ active: undefined, selected: sel });
  };

  isT3Monotherapy = (values) => {
    const { currentMeds } = this.state;
    const sel = [];
    console.log({ currentMeds });
    if (currentMeds) {
      for (let index = 0; index < currentMeds.length; index++) {
        const element = currentMeds[index];
        if (!element.isChecked) continue;
        sel.push(element.label);
      }
    }
    if (values) {
      // console.log({values})
      for (const k in values) {
        // console.log({k})
        if (values[k].label) {
          sel.push(values[k].label);
        }
      }
    }

    const t3Meds = sel.filter((x) => x.toLowerCase().indexOf('liothyronine') > -1 || x.toLowerCase().indexOf('cytomel') > -1 || x.toLowerCase().indexOf('triostat') > -1);
    const t4Meds = sel.filter((x) => x.toLowerCase().indexOf('liothyronine') < 0 && x.toLowerCase().indexOf('cytomel') < 0 && x.toLowerCase().indexOf('triostat') < 0);

    if (t3Meds.length > 0 && t4Meds.length < 1) {
      return true;
    }

    return false;
  };

  formatSubmit = (values) => {
    const { data } = this.props;
    const { currentMeds } = this.state;
    let payload = [];

    const others = [];

    for (const k in values) {
      let answer;

      if (values[k].questionId === data.other.questionId) {
        others.push(`${values[k].label} - ${values[k].dose} dose${values[k].dose && values[k].dose > 1 ? 's' : ''} ${values[k].frequency}`);
      } else {
        if (values[k].dose) {
          answer = `${values[k].dose} dose${values[k].dose && values[k].dose > 1 ? 's' : ''} ${values[k].frequency}`;
        } else if (values[k].frequency) {
          answer = `${values[k].frequency}`;
        } else {
          answer = '';
        }

        payload.push({ questionId: values[k].questionId.toString(), answer });
      }
    }

    if (others && others.length > 0) {
      payload.push({
        questionId: data.other.questionId.toString(),
        answer: `${data.prefix || ''} ${others.join(' ; ')}`,
      });
    }

    for (let index = 0; index < currentMeds.length; index++) {
      const element = currentMeds[index];
      if (!element.isChecked) continue;
      const answer = `${element.dose} ${element.frequency}`;

      payload.push({ questionId: element.questionId.toString(), answer });
    }
    console.log({ payload });
    return payload;
  };

  shiftView = () => {
    setTimeout(() => {
      window.scrollTo(0, this.inputRef.offsetTop);
    }, 150);
  };

  render() {
    const { data, onSubmit, wizard, appointmentId, onCancel, autoSkipNoMeds, onDisqualify } = this.props;
    const { selected, loading, active, tempValues, confirmNoMedications, currentMeds, showDropDown, confirmMonotherapy } = this.state;
    // console.log('selected', selected);
    const list = data.options
      .sort((a, b) => {
        if (a.value < b.value) return -1;
        if (a.value > b.value) return 1;
        return 0;
      })
      .filter((x) => {
        return !selected.find((y) => y.value === x.questionId);
      })
      .map((x) => {
        return {
          value: x.questionId,
          questionId: x.questionId,
          hasDosage: x.hasDosage,
          label: x.value,
        };
      });
    return (
      <Card flexDirection={'column'} p={3} mb={'50px'} mx={3} style={{ zIndex: 1 }}>
        <Fragment>
          <Flex flex={1} flexDirection="column">
            <Box m={2} style={{ ...fadeInDown() }}>
                <Text fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
                  {data.title}
                </Text>
            </Box>

            <Formik
              initialValues={{ [data.id]: '' }}
              validationSchema={data.schema || ''}
              onSubmit={(values, actions) => {
                if (this.noOption.current) return;

                const { active } = this.state;
                if (active) return;
                const isMonotherapy = this.isT3Monotherapy(selected);
                if (isMonotherapy) {
                  this.setState({
                    confirmMonotherapy: true,
                  });

                  return;
                }
                const formatted = this.formatSubmit(selected);

                onSubmit(
                  {
                    values: formatted,
                    endpoint: data.endpoint,
                  },
                  actions,
                  wizard,
                  data
                );
              }}
              render={({ isValid, errors, touched, submitForm, values }) => {
                return (
                  <Form>
                    <Flex flex={1} flexDirection="column">
                      <Flex my={3} flexWrap="wrap" justifyContent="center">
                        <div
                          style={{
                            maxWidth: '500px',
                            alignSelf: 'center',
                            width: '100%',
                            margin: '0 auto',
                          }}
                        >
                          <Text mt={3} mb="24px" color="hsl(0,0%,50%)" textAlign="center">
                            {currentMeds && currentMeds.length > 0
                              ? 'Please confirm the prescription medications you are currently taking by checking the boxes:'
                              : 'Please list all the prescription medications you are currently taking'}
                          </Text>
                          {currentMeds
                            .sort((a, b) => {
                              if (a.label < b.label) return -1;
                              if (a.label > b.label) return 1;

                              return 0;
                            })
                            .map((item, i, arr) => {
                              return (
                                <Flex key={item.value + `_i_${i}`}>
                                  <Box
                                    flex={1}
                                    style={{
                                      margin: '0 0 12px 0',
                                      border: '1px solid #344f77',
                                      padding: '12px 12px',
                                      textAlign: 'left',
                                      borderRadius: '6px',
                                    }}
                                  >
                                    <Flex alignItems={'center'}>
                                      <Flex flexDirection={'column'} flex={1}>
                                        <Text fontWeight="bold">{item.label}</Text>
                                        <Text fontWeight={200} mt={3}>
                                          {!item.dose && !item.frequency ? 'ADD DOSE AND FREQUENCY' : `${item.dose} dose${item.dose > 1 ? 's' : ''} ${item.frequency}`}
                                        </Text>
                                      </Flex>

                                      {item.isChecked ? (
                                        <svg
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            const array = JSON.parse(JSON.stringify(arr));
                                            array[i].isChecked = false;
                                            this.setState({
                                              currentMeds: array,
                                            });
                                          }}
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                            fill="#354e79"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            const array = JSON.parse(JSON.stringify(arr));
                                            array[i].isChecked = true;
                                            this.setState({
                                              currentMeds: array,
                                            });
                                          }}
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                            fill="#354e79"
                                          />
                                        </svg>
                                      )}
                                    </Flex>
                                  </Box>
                                </Flex>
                              );
                            })}
                          {showDropDown ? (
                            <Select
                              ref={this.selects}
                              components={data.isInputOnly ? { DropdownIndicator: () => null } : {}}
                              style={{ width: '100%' }}
                              classNamePrefix="region-select"
                              options={list}
                              placeholder="Pick a medication"
                              autoFocusOption
                              onChange={(option) => {
                                const sel = selected;
                                sel.push(option);
                                this.setState({
                                  active: option,
                                  selected: sel,
                                  tempValues: {},
                                });
                              }}
                              onKeyDown={(e) => {
                                if (e.keyCode === 13 && !active) {
                                  setTimeout(() => {
                                    if (!this.noOption.current) return;
                                    const inputValue = this.selects.current.select.inputRef.value;

                                    const sel = selected;
                                    const uniqueCount = selected.filter((x) => {
                                      return x.value.toString().startsWith(data.other.questionId.toString());
                                    }).length;
                                    const option = {
                                      value: `${data.other.questionId}-${uniqueCount}`,
                                      questionId: data.other.questionId,
                                      label: inputValue,
                                    };
                                    sel.push(option);
                                    this.setState({
                                      active: option,
                                      selected: sel,
                                      tempValues: {},
                                    });
                                  }, 300);
                                }
                              }}
                              noOptionsMessage={({ inputValue }) => {
                                if (!inputValue) return null;
                                return (
                                  <NoOptionMessage
                                    reff={this.noOption}
                                    inputValue={inputValue}
                                    onAdd={() => {
                                      // const sel = selected;
                                      // const uniqueCount = selected.filter((x) => {
                                      //   return x.value.toString().startsWith(data.other.questionId.toString());
                                      // }).length;
                                      // const option = {
                                      //   value: `${data.other.questionId}-${uniqueCount}`,
                                      //   questionId: data.other.questionId,
                                      //   label: inputValue,
                                      // };
                                      // sel.push(option);
                                      // this.setState({
                                      //   active: option,
                                      //   selected: sel,
                                      //   tempValues: {},
                                      // });
                                    }}
                                  />
                                );
                              }}
                              value={active ? list.filter((element) => element.value === active.value) : null}
                              isSearchable={true}
                            />
                          ) : (
                            <Text
                              textAlign={'center'}
                              onClick={() => {
                                this.setState({
                                  showDropDown: true,
                                });
                              }}
                              color={'#0088EB'}
                              style={{ cursor: 'pointer' }}
                            >
                              + Add more medications
                            </Text>
                          )}
                          <div style={{ width: '100%', marginBottom: '24px' }} />
                          {(!selected || selected.length < 1) && (
                            <Text color="#ccc" fontWeight="500" fontSize="18px" textAlign="center">
                              No Medication Added
                            </Text>
                          )}
                          {selected
                            .sort((a, b) => {
                              if (a.label < b.label) return -1;
                              if (a.label > b.label) return 1;

                              return 0;
                            })
                            .map((item, i) => {
                              return (
                                <Flex key={item.value + `_i_${i}`}>
                                  <Button
                                    type="button"
                                    variant="outline"
                                    flex={1}
                                    onClick={() =>
                                      this.setState({
                                        active: item,
                                        tempValues: {
                                          dose: item.dose,
                                          frequency: item.frequency,
                                          hasDosage: item.hasDosage,
                                        },
                                      })
                                    }
                                    style={{
                                      margin: '6px 0',
                                      border: '1px solid #344f77',
                                      padding: '12px 12px',
                                      textAlign: 'left',
                                      borderRadius: '6px',
                                    }}
                                  >
                                    <Flex alignItems={'center'}>
                                      <Flex flexDirection={'column'} flex={1}>
                                        <Text fontWeight="bold">{item.label}</Text>
                                        <Text fontWeight={200} mt={3}>
                                          {!!item.hasDosage
                                            ? !item.frequency
                                              ? 'ADD FREQUENCY'
                                              : item.frequency
                                            : !item.dose && !item.frequency
                                              ? 'ADD DOSE AND FREQUENCY'
                                              : `${item.dose} dose${item.dose > 1 ? 's' : ''} ${item.frequency}`}
                                        </Text>
                                      </Flex>
                                      <Icon icon={editFilled} style={{ color: '#354e79', fontSize: '30px' }} />
                                    </Flex>
                                  </Button>
                                </Flex>
                              );
                            })}
                        </div>
                      </Flex>

                      <div
                        style={{
                          height: '1px',
                          backgroundColor: '#ccc',
                          margin: '40px -16px 20px',
                        }}
                      />

                      <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column" textAlign="center">
                        {(!selected || selected.length < 1) && (!currentMeds || currentMeds.filter((x) => !!x.isChecked).length < 1) ? (
                          <Text
                            textAlign="center"
                            mb={1}
                            fontSize="18px"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (autoSkipNoMeds || this.props.mode === 'meno') {
                                wizard.push(data.skip);
                                return;
                              }
                              this.setState({
                                confirmNoMedications: true,
                              });
                            }}
                          >
                            <span style={{ color: '#364f79' }}>Skip</span>
                            <span style={{ color: '#f9a394', fontWeight: '600' }}>{' >'}</span>
                          </Text>
                        ) : (
                          <Button disabled={loading} variant="pink" width={[1 / 2, 1 / 3]} type="submit">
                            {loading ? 'Processing' : 'Next'}
                          </Button>
                        )}
                      </Flex>

                      {/* <Flex
                      justifyContent="center"
                      style={{ display: display ? '' : 'none' }}
                    >
                    </Flex> */}
                    </Flex>
                  </Form>
                );
              }}
            />
          </Flex>
        </Fragment>
        {confirmMonotherapy && (
          <ConfirmModal
            isOpen={true}
            onClose={() =>
              this.setState({
                confirmMonotherapy: false,
              })
            }
            onBackgroundClick={() => this.setState({ confirmMonotherapy: false })}
            onEscapeKeydown={() => this.setState({ confirmMonotherapy: false })}
            childrenManaged
            onConfirm={() => {
              // this.confirmModalForm();
            }}
            confirmLabel="Save"
            cancelLabel="Remove"
            confirmStyle={{
              width: '100%',
            }}
            cancelStyle={{
              width: '100%',
              marginTop: '18px',
            }}
            cancelVariant="outline"
            buttonsDirection="column"
            displayX={true}
            cardStyle={{
              width: '500px',
              maxWidth: 'calc(100vw - 40px)',
            }}
          >
            <ModalInner>
              <T3OnlyModalConfirm
                onClose={() => this.setState({ confirmMonotherapy: false })}
                onContinue={() => {
                  if (this.noOption.current) return;

                  const { active } = this.state;
                  if (active) return;

                  const formatted = this.formatSubmit(selected);

                  onSubmit(
                    {
                      values: formatted,
                      endpoint: data.endpoint,
                    },
                    null,
                    wizard,
                    data
                  );
                }}
                appointmentId={appointmentId}
                onCancel={onCancel}
              />
            </ModalInner>
          </ConfirmModal>
        )}
        {active && (
          <ConfirmModal
            isOpen={true}
            onClose={() =>
              this.setState({
                active: undefined,
                selected: selected.filter((x) => x.value !== active.value),
              })
            }
            onBackgroundClick={() => this.setState({ active: undefined })}
            onEscapeKeydown={() => this.setState({ active: undefined })}
            onConfirm={() => {
              this.confirmModalForm();
            }}
            confirmLabel="Save"
            cancelLabel="Remove"
            confirmStyle={{
              width: '100%',
            }}
            cancelStyle={{
              width: '100%',
              marginTop: '18px',
            }}
            cancelVariant="outline"
            buttonsDirection="column"
            displayX={true}
            cardStyle={{
              width: '500px',
              maxWidth: 'calc(100vw - 40px)',
            }}
          >
            {active && (
              <ModalInner>
                <Heading mb={4} textAlign="center" fontWeight="bold">
                  Edit Medication Details
                </Heading>

                <Text
                  style={{
                    fontSize: '22px',
                    textAlign: 'center',
                    marginBottom: '38px',
                  }}
                >
                  {active.label}
                </Text>
                {!active.hasDosage && (
                  <Input
                    placeholder="Dose"
                    name="dose"
                    value={tempValues.dose}
                    submitForm={this.confirmModalForm}
                    onChange={(val) => {
                      this.setState({
                        tempValues: {
                          ...tempValues,
                          dose: val.target.value,
                        },
                      });
                    }}
                  />
                )}
                <Input
                  placeholder="Frequency"
                  name="frequency"
                  value={tempValues.frequency}
                  submitForm={this.confirmModalForm}
                  onChange={(val) => {
                    this.setState({
                      tempValues: {
                        ...tempValues,
                        frequency: val.target.value,
                      },
                    });
                  }}
                />
              </ModalInner>
            )}
          </ConfirmModal>
        )}

        {confirmNoMedications && (
          <ConfirmModal
            isOpen={true}
            childrenManaged
            onClose={() =>
              this.setState({
                confirmNoMedications: false,
              })
            }
            onBackgroundClick={() => this.setState({ confirmNoMedications: false })}
            onEscapeKeydown={() => this.setState({ confirmNoMedications: false })}
            onConfirm={() => {
              this.setState(
                {
                  confirmNoMedications: false,
                },
                () => {
                  wizard.push(data.skip);
                }
              );
            }}
            confirmLabel="Save"
            cancelLabel="Remove"
            confirmStyle={{
              width: '100%',
            }}
            cancelStyle={{
              width: '100%',
              marginTop: '18px',
            }}
            cancelVariant="outline"
            buttonsDirection="column"
            displayX={true}
            cardStyle={{
              width: '500px',
              maxWidth: 'calc(100vw - 40px)',
            }}
          >
            {confirmNoMedications && (
              <ModalInner>
                <Heading mb={4} textAlign="center" fontWeight="bold">
                  Please confirm that you don't currently take thyroid medication
                </Heading>
                <Mutation
                  mutation={NOTIFY_NEED_CONFIRM_HYPO}
                  onCompleted={() => {
                    this.setState(
                      {
                        confirmNoMedications: false,
                      },
                      () => {
                        wizard.push(data.skip);
                      }
                    );
                  }}
                >
                  {(sendNotif, { loading: loadingNotify }) => {
                    return (
                      <>
                        <Button
                          disabled={!!loadingNotify}
                          variant={'outline'}
                          onClick={() => {
                            this.setState(
                              {
                                confirmNoMedications: false,
                              },
                              () => {
                                wizard.push(data.skip);
                              }
                            );
                          }}
                        >
                          I confirm and I was diagnosed by a medical professional
                        </Button>

                        <Button
                          disabled={!!loadingNotify}
                          variant={'outline'}
                          mt={2}
                          onClick={() => {
                            sendNotif();
                          }}
                        >
                          I confirm and I was not yet diagnosed by a professional
                        </Button>
                        <Button
                          disabled={!!loadingNotify}
                          variant={'outline'}
                          mt={2}
                          onClick={() => {
                            this.setState({
                              confirmNoMedications: false,
                            });
                          }}
                        >
                          I am taking thyroid medications
                        </Button>

                        <Flex style={{ justifyContent: 'center' }}>
                          <Button
                            disabled={!!loadingNotify}
                            variant={'pink'}
                            mt={4}
                            onClick={() => {
                              this.setState({
                                confirmNoMedications: false,
                              });
                            }}
                          >
                            Cancel
                          </Button>
                        </Flex>
                      </>
                    );
                  }}
                </Mutation>
              </ModalInner>
            )}
          </ConfirmModal>
        )}
      </Card>
    );
  }
}

const NoOptionMessage = ({ inputValue, onAdd, reff }) => {
  // console.log(inputValue);
  return (
    <div style={{ padding: '12px 24px' }} ref={reff}>
      <Text textAlign="center" mb={1} fontSize="18px" style={{ cursor: 'not-allowed' }} onClick={onAdd}>
        <span style={{ color: '#364f79' }}> {inputValue}</span>
        <span style={{ color: '#f9a394', fontWeight: '600' }}>{' Not found'}</span>
      </Text>
    </div>
  );
};

// class DosageModal extends React.Component {
//   render() {}
// }
class MedicationPickWrap extends Component {
  render() {
    const { data } = this.props;
    return (
      <Query query={GET_HYPO_MEDICATIONS_ON_FILE}>
        {({ loading, data: dataMeds }) => {
          console.log({ dataMeds });
          if (loading) {
            return (
              <Card p={3} mb={5} mx={3} style={{ zIndex: 1 }}>
                <Fragment>
                  <Flex flex={1} flexDirection="column">
                    <Box m={2}>
                      <Heading color="#344f77" textAlign="center">
                        {data.title}
                      </Heading>
                    </Box>
                    <PleaseWait />
                  </Flex>
                </Fragment>
              </Card>
            );
          }
          return <MedicationPick {...this.props} currentMeds={dataMeds} />;
        }}
      </Query>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(MedicationPickWrap);
// export default withWizard(Single);
