import React, { Component, Fragment } from 'react';
import { Flex, Box, Text, Card, Heading, Button } from 'rebass';
import styled from 'styled-components';
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { Steps, Step, Wizard } from 'react-albus';
import { compose, withApollo } from 'react-apollo';
import { injectStripe } from 'react-stripe-elements';
import { withCookies } from 'react-cookie';

import Address from './Address';
import Payment from './Payment';
import Header from '../../components/Header';
import FullLayout from '../../components/FullLayout';
import LoadingScreen from '../../components/LoadingScreen';
import GiveKitToFriend from '../../components/GiveKitToFriend';
import TruckIcon from '../../static/truckIcon.png';
import CliaIcon from '../../static/clia.png';
import KitIcon from '../../static/kitIcon.png';

import {
  OPEN_ORDER,
  CANCEL_ORDER,
  KIT_PRICE_UNAUTHENTICATED,
  CREATE_ORDER_UNAUTHENTICATED,
  COMPLETE_ORDER_UNAUTHENTICATED,
  SAVE_EMAIL_UNCOMPLETE_ORDER,
  MOBILE_WALLET_PAYMENT,
  APPLY_COUPON,
  ADD_NEW_REFERRAL,
  GET_LAST_ORDER_MINUTES_DIFF,
  ADD_BIOMARKERS_ORDER_UNAUTHENTICATED,
} from '../../graphql';
import PaymentReceived from './PaymentReceived';
import withAnalytics from '../../lib/withAnalytics';
import Profile from './Profile';
import ConfirmModal from '../../components/ConfirmModal';
import { isToggleActive } from '../../components/featureToggle/toggles';
import TextInput from '../../components/TextInput';
import { checkBlackListed, BlackListedCouponsOnBogo } from '../../utils/blacklistedCoupons';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import PleaseWait from '../../components/PleaseWait';
import ConfirmOrderIsNotDuplicateModal from '../../components/ConfirmOrderIsNotDuplicateModal';
import AdditionalBiomarkers from './AdditionalBiomarkers';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const InfoBox = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${(props) => props.theme.color.darkBlue};
  justify-content: center;
`;
const DesktopOnly = styled(Flex)`
  @media screen and (max-width: 52em) {
    display: none;
  }
`;

const MobileView = styled(Flex)`
  flex: 1;
  justify-content: center;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const initialState = {
  dob: '',
  email: '',
  order: {
    id: '',
    amount: '',
    items: [],
  },
  address: {
    firstName: '',
    lastName: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
  },
  prompt: false,
  ready: false,
  loading: false,
  sourceId: undefined,
  coupon: undefined,
  giveToFriend: undefined,
  isCouponBOGOdisabledModal: undefined,
  canMakePayment: false,
  canMakePaymentApplePay: undefined,
  paymentRequest: undefined,
  isPaymentThroughWallet: false,
  wizard: undefined,
  isInitializingOrder: false,
  isApplyingCoupon: false,
  usesRefCoupon: null,
  isConfirmNotDuplicateModalOpen: false,
  proceedState: {},
  preOrder: {},
  biomarkers: [],
  additionalBiomarkersFeature: false,
  reverseT3Feature: false,
  vitaminDFeature: false,
};

class OrderKit extends Component {
  constructor(props, context) {
    super(props, context);

    this.couponInputRef = React.createRef();

    const biom = {
      additionalBiomarkersFeature: isToggleActive('KIT_ADDITIONAL_BIOMARKERS'),
      reverseT3Feature: isToggleActive('KIT_REVERSE_T3'),
      vitaminDFeature: isToggleActive('KIT_VITAMIN_D'),
    };

    if (isToggleActive('MOBILE_WALLET')) {
      this.state = {
        ...initialState,
        ...biom,
      };

      // console.log(this.state);
      this.setPaymentRequest();
    } else {
      this.state = {
        ...initialState,
        ...biom,
        canMakePayment: false,
      };
    }
    // console.log(this.state);
  }

  setPaymentRequest = (centisizedAmount = 9900) => {
    const { order } = this.state;
    const pr = this.props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
      total: {
        label: 'Kit Order',
        amount: order && order.amount ? order.amount : centisizedAmount,
      },
    });

    pr.on('token', async ({ complete, token, ...data }) => {
      this.setState({
        expressCheckoutData: {
          ...data,
        },
        expressCheckoutToken: {
          ...token,
        },
      });
      if (!this.state.useExpressCheckout && this.props.location.pathname.indexOf('profile') < 0) {
        this.mobileWalletPayment(token);
      }
      complete('success');
    });

    pr.canMakePayment().then((result) => {
      this.setState({
        canMakePayment: !!result,
        canMakePaymentApplePay: result && result.applePay,
      });
    });
    this.setState({
      paymentRequest: pr,
    });
  };

  updatePaymentRequest = (centisizedAmount = 9900) => {
    const { paymentRequest, order } = this.state;
    if (!paymentRequest) return;

    paymentRequest.update({
      total: {
        label: 'Kit Order',
        amount: order && order.amount ? order.amount : centisizedAmount,
      },
    });
  };

  mobileWalletPayment = async (token) => {
    const { order, giveToFriend, address, email, wizard } = this.state;

    try {
      let addBuyOneGiveOneKit = null;
      if (giveToFriend) {
        addBuyOneGiveOneKit = {
          purchaserFirstName: address.firstName,
          purchaserLastName: address.lastName,
          refereeFirstName: giveToFriend.firstNameFriend,
          refereeLastName: giveToFriend.lastNameFriend,
          purchaserEmail: email,
          refereeEmail: giveToFriend.emailFriend,
          personalMessage: giveToFriend.personalMessageFriend,
        };
      }
      await this.props.client.mutate({
        mutation: MOBILE_WALLET_PAYMENT,
        variables: {
          orderId: order.id,
          token: token.id,
          name: token.card.name,
          payerEmail: email,
          payerPhone: token.card.phone,
          address: {
            street1: address.street1,
            street2: address.street2,
            city: address.city,
            zip: address.zip,
            state: address.state,
          },
          addBuyOneGiveOneKit,
        },
      });

      try {
        let discount = 0;
        let couponCode = '';
        const opts = {
          cost: order && order.amount ? parseFloat((order.amount / 100).toFixed(2)) : 0,
        };
        for (let index = 0; index < order.items.length; index++) {
          const element = order.items[index];
          if (element.amount && element.amount < 0) {
            discount += element.amount;
            opts[element.description] = (element.amount / 100).toFixed(2);
            couponCode = element.parent;
          }
        }
        this.props.analytics.track('Kit Ordered', { ...opts });
        this.props.analytics.logRevenue(
          parseFloat(((order.amount + discount * -1) / 100).toFixed(2)),
          opts.cost,
          parseFloat((discount / 100).toFixed(2)),
          couponCode,
          [
            {
              productId: 'kit',
              name: 'Thyroid Test Kit',
              price: opts.cost,
              quantity: 1,
              category: 'kit',
            },
          ],
          order.id,
          'Marketing Website'
        );
      } catch (error) {}

      try {
        const { cookies } = this.props;
        const referralCoupon = cookies.get('referralCoupon');
        if ((referralCoupon || this.state.referralCoupon) && isToggleActive('REFERRAL_PROGRAM')) {
          await this.props.client.mutate({
            mutation: ADD_NEW_REFERRAL,
            variables: {
              coupon: this.state.referralCoupon,
              orderId: order.id,
              email,
            },
          });
        }
        cookies.remove('referralCoupon');
      } catch (error) {
        console.log('Err couponCookie mobileWalletPayment', error);
      }

      this.setState(
        {
          loading: false,
          ready: true,
          card: {
            brand: token.card.brand,
            last4: token.card.last4,
            expMonth: token.card.exp_month,
            expYear: token.card.exp_year,
          },
        },
        () => {
          wizard.next();
        }
      );
    } catch (error) {
      console.log('mobileWalletPayment err', error);
    }
  };

  componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;
    if (this.props.location.pathname.indexOf('buyonegiveone') > -1 && !isToggleActive('BUY_ONE_GIVE_ONE_KIT')) {
      this.props.history.push('/order-kit/profile');
    }
    if (currentPage !== prevPage) {
      let tag = '';
      window.scrollTo(0, 0);

      if (currentPage.indexOf('address') > -1) {
        tag = 'address';
      } else if (currentPage.indexOf('profile') > -1) {
        tag = 'profile';
      } else if (currentPage.indexOf('address') > -1) {
        tag = 'address';
      } else if (currentPage.indexOf('additionalBiomarkers') > -1) {
        tag = 'additionalBiomarkers';
      } else if (currentPage.indexOf('paymentReceived') > -1) {
        tag = 'paymentReceived';
      } else if (currentPage.indexOf('payment') > -1) {
        tag = 'payment';
      } else if (currentPage.indexOf('confirm') > -1) {
        tag = 'confirm';
      }
      if (tag) {
        this.props.analytics.page(tag, 'orderKit');
      }
    }
  }

  componentDidMount = async () => {
    const { toastManager } = this.props;
    let tag = '';

    let email = this.props.match.params.email && this.validateEmailProps(this.props.match.params.email) ? this.props.match.params.email : '';

    if (this.props.location.pathname.indexOf('buyonegiveone') > -1 && !isToggleActive('BUY_ONE_GIVE_ONE_KIT')) {
      this.props.history.push('/order-kit/profile');
    }

    if (email === '') {
      if (this.props.location.pathname.indexOf('address') > -1) {
        tag = 'address';
      } else if (this.props.location.pathname.indexOf('paymentReceived') > -1) {
        tag = 'paymentReceived';
      } else if (this.props.location.pathname.indexOf('payment') > -1) {
        tag = 'payment';
      } else if (this.props.location.pathname.indexOf('confirm') > -1) {
        tag = 'confirm';
      }
      if (tag) {
        this.props.analytics.page(tag, 'orderKit');
      }
    }

    try {
      const amount = await this.fetchKitPrice();

      const { cookies } = this.props;
      const referralCoupon = cookies.get('referralCoupon');
      let usesRefCoupon = null;
      if (referralCoupon && isToggleActive('REFERRAL_PROGRAM')) {
        usesRefCoupon = referralCoupon;
      }
      this.setState({
        email,
        usesRefCoupon,
        order: {
          id: '',
          amount,
          items: [],
        },
        address: {
          firstName: '',
          lastName: '',
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: '',
          email,
        },
        ready: true,
      });
    } catch (error) {
      toastManager.add(error.message, { appearance: 'error' });
      this.setState({ ready: true });
    }
  };

  validateEmailProps = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  setSourceId = (sourceId) => {
    this.setState({ sourceId });
  };

  handleSubmit = (key, values, wizard) => {
    this.setState({ [key]: { ...values } }, () => {
      if (this.state.canMakePayment && key === 'order') {
        this.updatePaymentRequest();
      }
    });

    if (wizard) {
      this.setState({
        wizard,
      });
      wizard.next();
    }
  };

  handleSubmitGiveToFriend = async (key, values, wizard) => {
    this.setState({ [key]: { ...values } });

    if (wizard) {
      wizard.next();
    }
  };

  handleSubmitAddress = async (key, values, wizard) => {
    this.setState({ [key]: { ...values }, wizard }, () => {
      this.props.client.mutate({
        mutation: SAVE_EMAIL_UNCOMPLETE_ORDER,
        variables: {
          email: this.state.address.email,
        },
      });
    });

    if (wizard) {
      wizard.next();
    }
  };

  createOrder = async (actions, values, sourceId, setSubmitting) => {
    const { firstName, lastName, street1, street2, city, state, zip, email, dob } = values;

    try {
      const res = await this.props.client.mutate({
        mutation: CREATE_ORDER_UNAUTHENTICATED,
        variables: {
          sku: window._env_.REACT_APP_STRIPE_SKU,
          name: `${firstName} ${lastName}`,
          email,
          source: sourceId,
          address: {
            street1,
            street2,
            city,
            state,
            zip,
          },
        },
      });

      this.setState({
        card: res.data.createOrderUnauthenticated.cardInfo,
        email,
        dob,
      });

      return res.data.createOrderUnauthenticated;
    } catch (error) {
      console.log('error str', error);
      if (actions) {
        actions.setSubmitting(false);
      }
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((error) => {
          throw Error((error.message || '').replace(/Error:/g, '').trim());
        });
      } else {
        throw Error('An error occured');
      }
    }
  };

  addBiomarkerToOrder = async (biomarkers) => {
    const { client } = this.props;
    const { address, order, sourceId, email, couponcode } = this.state;
    // console.log({ order });
    try {
      const res = await client.mutate({
        mutation: ADD_BIOMARKERS_ORDER_UNAUTHENTICATED,
        variables: {
          sku: window._env_.REACT_APP_STRIPE_SKU,
          name: `${address.firstName} ${address.lastName}`,
          email,
          source: sourceId,
          address: {
            street1: address.street1,
            street2: address.street2,
            city: address.city,
            state: address.state,
            zip: address.zip,
          },
          biomarkers: biomarkers,
          orderId: order.id,
        },
      });
      this.setState({
        order: res.data.addBiomarkerToOrderUnauthenticated,
      });
      await this.handleCoupon(couponcode, null, true);
    } catch (error) {}
  };

  fetchKitPrice = async () => {
    try {
      const { data } = await this.props.client.query({
        query: KIT_PRICE_UNAUTHENTICATED,
      });
      return data.kitPriceUnauthenticated.price;
    } catch (error) {
      throw Error('Error fetching kit price, please try again.');
    }
  };

  handlePayment = async (actions, wizard, sourceId) => {
    const { address, order } = this.state;
    const { toastManager } = this.props;

    try {
      if (!order.id) {
        const response = await this.createOrder(actions, address, sourceId);
        this.setState(
          {
            isPaymentThroughWallet: false,
          },
          () => this.handleSubmit('order', response, wizard)
        );
      } else {
        wizard.next();
      }
    } catch (error) {
      toastManager.add(error.message, { appearance: 'error' });
    }
  };

  continuePayWithWallet = async (actions, wizard, sourceId) => {
    const { address, order } = this.state;
    // console.log('continuePayWithWallet 0', order);
    if (!order.id) {
      const response = await this.createOrder(actions, address, sourceId);
      // console.log('continuePayWithWallet 1', response);
      this.setState(
        {
          isPaymentThroughWallet: true,
        },
        () => this.handleSubmit('order', response, wizard)
      );
    } else {
      wizard.next();
    }
  };

  createInitialOrder = async () => {
    const { address, order } = this.state;
    // console.log({ address, order });
    if (!address || !address.street1) return;
    if (!order.id) {
      this.setState({ isInitializingOrder: true });
      const response = await this.createOrder(null, address, null);

      this.setState(
        {
          order: { ...response },
          isInitializingOrder: false,
        },
        async () => {
          const { cookies } = this.props;
          const referralCoupon = cookies.get('referralCoupon');
          if (referralCoupon && isToggleActive('REFERRAL_PROGRAM')) {
            await this.handleCoupon(referralCoupon);
            this.setState({
              referralCoupon,
            });
          }
        }
      );
    }
  };
  handlePaymentNow = async (actions, wizard, sourceId) => {
    const { client } = this.props;
    const { email } = this.state;

    const { data } = await client.query({
      query: GET_LAST_ORDER_MINUTES_DIFF,
      variables: {
        email: email,
      },
      fetchPolicy: 'network-only',
    });

    // console.log('DTA', data);
    if (data.getLastOrderMinutesDif && data.getLastOrderMinutesDif.elapsedMinutes) {
      this.setState({
        isConfirmNotDuplicateModalOpen: true,
        proceedState: {
          actions,
          wizard,
          sourceId,
        },
      });
      return;
    } else {
      await this.proceedWithPurchase(actions, wizard, sourceId);
    }
  };

  proceedWithPurchase = async (actions, wizard, sourceId) => {
    const { address, order, couponcode, email, biomarkers } = this.state;
    const { toastManager, client } = this.props;
    this.setState({ loading: true, isConfirmNotDuplicateModalOpen: false });
    try {
      // const response = await this.createOrder(null, address, sourceId, actions);
      const res = await client.mutate({
        mutation: ADD_BIOMARKERS_ORDER_UNAUTHENTICATED,
        variables: {
          sku: window._env_.REACT_APP_STRIPE_SKU,
          name: `${address.firstName} ${address.lastName}`,
          email,
          source: sourceId,
          address: {
            street1: address.street1,
            street2: address.street2,
            city: address.city,
            state: address.state,
            zip: address.zip,
          },
          biomarkers: biomarkers,
          orderId: order.id,
        },
      });

      this.setState(
        {
          isPaymentThroughWallet: false,
          isConfirmNotDuplicateModalOpen: false,
          ['order']: { ...res.data.addBiomarkerToOrderUnauthenticated },
        },
        async () => {
          if (couponcode) {
            await this.handleCoupon(couponcode, null, true);
          }
          this.handleConfirmPurchaseNoCoupon(wizard);
        }
      );
    } catch (error) {
      this.setState({ loading: false });
      toastManager.add(error.message, { appearance: 'error' });
    }
  };

  handleClose = async () => {
    const amount = await this.fetchKitPrice();

    await this.props.client.mutate({
      mutation: CANCEL_ORDER,
      variables: { id: this.state.order.id },
      refetchQueries: [
        {
          query: OPEN_ORDER,
        },
      ],
    });

    this.setState({
      ...initialState,
      order: {
        id: '',
        amount,
        items: [],
      },
      ready: true,
    });

    this.props.history.push('/order-kit/address');
  };

  handleConfirmPurchase = async (wizard) => {
    const { order, giveToFriend, email, address } = this.state;
    const { toastManager } = this.props;

    this.setState({ loading: true });
    const vars = {
      id: order.id,
    };

    if (giveToFriend) {
      vars.addBuyOneGiveOneKit = {
        purchaserFirstName: address.firstName,
        purchaserLastName: address.lastName,
        refereeFirstName: giveToFriend.firstNameFriend,
        refereeLastName: giveToFriend.lastNameFriend,
        purchaserEmail: email,
        refereeEmail: giveToFriend.emailFriend,
        personalMessage: giveToFriend.personalMessageFriend,
      };
    }

    try {
      await this.props.client.mutate({
        mutation: COMPLETE_ORDER_UNAUTHENTICATED,
        variables: vars,
      });

      let discount = 0;
      let couponCode = '';
      const opts = {
        cost: order && order.amount ? parseFloat((order.amount / 100).toFixed(2)) : 0,
      };
      for (let index = 0; index < order.items.length; index++) {
        const element = order.items[index];
        if (element.amount && element.amount < 0) {
          // console.log({element})
          discount += element.amount;
          opts[element.description] = (element.amount / 100).toFixed(2);
          couponCode = element.parent;
        }
      }

      this.props.analytics.track('Kit Ordered', { ...opts });
      this.props.analytics.logRevenue(
        parseFloat(((order.amount + discount * -1) / 100).toFixed(2)),
        opts.cost,
        parseFloat((discount / 100).toFixed(2)),
        couponCode,
        [
          {
            productId: 'kit',
            name: 'Thyroid Test Kit',
            price: opts.cost,
            quantity: 1,
            category: 'kit',
          },
        ],
        order.id,
        'Marketing Website'
      );
      // console.log('handleConfirmPurchase pre ref');
      try {
        const { cookies } = this.props;
        const referralCoupon = cookies.get('referralCoupon');
        // console.log('handleConfirmPurchaseNoCoupon pre coupon', {
        //   referralCoupon,
        //   referralCouponState: this.state.referralCoupon,
        //   isToggleActive: isToggleActive('REFERRAL_PROGRAM'),
        // });
        if ((referralCoupon || this.state.referralCoupon) && isToggleActive('REFERRAL_PROGRAM')) {
          // console.log('ADD_NEW_REFERRAL');
          await this.props.client.mutate({
            mutation: ADD_NEW_REFERRAL,
            variables: {
              coupon: this.state.referralCoupon,
              orderId: order.id,
              email,
            },
          });
        }

        cookies.remove('referralCoupon');
      } catch (error) {
        console.log('Err couponCookie referralCoupon', error);
      }
      this.setState(
        {
          loading: false,
          ready: true,
        },
        () => {
          wizard.next();
        }
      );
    } catch (err) {
      this.setState({ loading: false, ready: true });
      toastManager.add('Error confirming purchase, please try again', {
        appearance: 'error',
      });
    }
  };

  handleCoupon = async (coupon, actions, hideMessages) => {
    const { order, address } = this.state;
    try {
      if (!coupon) return;

      if (isToggleActive('BUY_ONE_GIVE_ONE_KIT')) {
        const isBlackListedCoupon = checkBlackListed(BlackListedCouponsOnBogo, coupon || '');
        if (isBlackListedCoupon) {
          this.setState({
            isCouponBOGOdisabledModal: true,
            couponcode: null,
          });

          return;
        }
      }
      this.setState({ isApplyingCoupon: !hideMessages });
      const { data } = await this.props.client.mutate({
        mutation: APPLY_COUPON,
        variables: {
          id: order.id,
          coupon,
          email: address.email,
        },
      });

      this.setState(
        {
          couponcode: coupon,
          isApplyingCoupon: false,
          coupon,
          referralCoupon: data.applyCoupon.isReferralCoupon ? coupon : null,
          order: {
            id: order.id,
            couponcode: coupon,
            ...data.applyCoupon,
          },
        },
        () => this.updatePaymentRequest()
      );
      if (!hideMessages) {
        this.props.toastManager.add('Applied coupon successfully', {
          appearance: 'success',
        });
      }
    } catch (error) {
      this.setState({ isApplyingCoupon: false, couponcode: null });
      try {
        const err = JSON.parse(JSON.stringify(error));
        this.props.toastManager.add(err.graphQLErrors[0].message || 'Unable to apply coupon', {
          appearance: 'error',
        });
      } catch (error2) {
        this.props.toastManager.add('Unable to apply coupon', {
          appearance: 'error',
        });
      }
    }
  };

  handleConfirmPurchaseNoCoupon = async (wizard) => {
    const { order, giveToFriend, email, address } = this.state;
    const { toastManager } = this.props;

    this.setState({ loading: true });
    const vars = {
      id: order.id,
    };
    if (giveToFriend) {
      vars.addBuyOneGiveOneKit = {
        purchaserFirstName: address.firstName,
        purchaserLastName: address.lastName,
        refereeFirstName: giveToFriend.firstNameFriend,
        refereeLastName: giveToFriend.lastNameFriend,
        purchaserEmail: email,
        refereeEmail: giveToFriend.emailFriend,
        personalMessage: giveToFriend.personalMessageFriend,
      };
    }

    try {
      await this.props.client.mutate({
        mutation: COMPLETE_ORDER_UNAUTHENTICATED,
        variables: vars,
      });

      try {
        let discount = 0;
        let couponCode = '';
        const opts = {
          cost: order && order.amount ? parseFloat((order.amount / 100).toFixed(2)) : 0,
        };
        for (let index = 0; index < order.items.length; index++) {
          const element = order.items[index];
          if (element.amount && element.amount < 0) {
            discount += element.amount;
            opts[element.description] = (element.amount / 100).toFixed(2);
            couponCode = element.parent;
          }
        }
        this.props.analytics.track('Kit Ordered', { ...opts });
        this.props.analytics.logRevenue(
          parseFloat(((order.amount + discount * -1) / 100).toFixed(2)),
          opts.cost,
          parseFloat((discount / 100).toFixed(2)),
          couponCode,
          [
            {
              productId: 'kit',
              name: 'Thyroid Test Kit',
              price: opts.cost,
              quantity: 1,
              category: 'kit',
            },
          ],
          order.id
        );
      } catch (error) {}
      const { cookies } = this.props;
      const referralCoupon = cookies.get('referralCoupon');

      try {
        if ((referralCoupon || this.state.referralCoupon) && isToggleActive('REFERRAL_PROGRAM')) {
          await this.props.client.mutate({
            mutation: ADD_NEW_REFERRAL,
            variables: {
              coupon: this.state.referralCoupon,
              orderId: order.id,
              email,
            },
          });
        }
        cookies.remove('referralCoupon');
      } catch (error) {
        console.log('err couponCookie handleConfirmPurchaseNoCoupon', error);
      }

      // console.log('NEXT', referralCoupon)
      this.setState(
        {
          loading: false,
          ready: true,
        },
        () => {
          this.props.history.push('/order-kit/paymentReceived');
        }
      );
    } catch (err) {
      this.setState({ loading: false, ready: true });
      toastManager.add('Error confirming purchase, please try again', {
        appearance: 'error',
      });
    }
  };

  render() {
    const { history, refetch, toastManager } = this.props;
    const {
      order,
      address,
      ready,
      loading,
      card,
      dob,
      coupon,
      giveToFriend,
      isCouponBOGOdisabledModal,
      canMakePayment,
      canMakePaymentApplePay,
      paymentRequest,
      isInitializingOrder,
      isApplyingCoupon,
      usesRefCoupon,
      isConfirmNotDuplicateModalOpen,
      proceedState,
      biomarkers,
      additionalBiomarkersFeature,
      wizard,
    } = this.state;
    // console.log('ppp', this.state);
    if (!ready || loading) {
      return <LoadingScreen loading={true} />;
    }

    const stepsVal = [
      {
        comp: (
          <Step id="buyonegiveone" key="buyonegiveone">
            <GiveKitToFriend values={giveToFriend} onSubmit={this.handleSubmitGiveToFriend} onSkipMoveTo="profile" />
          </Step>
        ),
        key: 'buyonegiveone',
        display: isToggleActive('BUY_ONE_GIVE_ONE_KIT'),
      },
      {
        comp: (
          <Step id="profile" key="profile">
            <Profile emailAddress={this.state.email} values={address} onSubmit={this.handleSubmitAddress} />
          </Step>
        ),
        key: 'profile',
        display: true,
      },
      {
        comp: (
          <Step id="address" key="address">
            <Address emailAddress={this.state.email} values={address} onSubmit={this.handleSubmitAddress} />
          </Step>
        ),
        key: 'address',
        display: true,
      },
      {
        comp: (
          <Step id="additionalBiomarkers" key="additionalBiomarkers">
            <AdditionalBiomarkers
              createInitialOrder={this.createInitialOrder}
              order={order}
              address={address}
              selectedBiomarkers={biomarkers || []}
              addItemToOrder={(itm) => {
                const bms = [...(biomarkers || []), itm];
                this.setState({
                  biomarkers: bms,
                });
                this.addBiomarkerToOrder(bms);
              }}
              removeItemFromOrder={(itm) => {
                const bms = biomarkers.filter((x) => x !== itm);

                this.setState({
                  biomarkers: bms,
                });
                this.addBiomarkerToOrder(bms);
              }}
              onNext={(wizard) => {
                if (wizard) {
                  wizard.next();
                }
              }}
            />
          </Step>
        ),
        key: 'additionalBiomarkers',
        display: additionalBiomarkersFeature,
      },
      {
        comp: (
          <Step id="payment" key="payment">
            <Payment
              createInitialOrder={this.createInitialOrder}
              address={address}
              order={order}
              refetch={refetch}
              onSubmit={this.handlePayment}
              onPayNow={this.handlePaymentNow}
              setSourceId={this.setSourceId}
              canMakePayment={canMakePayment}
              canMakePaymentApplePay={canMakePaymentApplePay}
              continuePayWithWallet={this.continuePayWithWallet}
              paymentRequest={paymentRequest}
              additionalBiomarkersFeature={additionalBiomarkersFeature}
            />
          </Step>
        ),
        key: 'payment',
        display: true,
      },
      {
        comp: (
          <Step id="paymentReceived" key="paymentReceived">
            <PaymentReceived emailAddress={this.state.email} address={address} order={order} history={this.props.history} source={card} dob={dob} />
          </Step>
        ),
        key: 'paymentReceived',
        display: true,
      },
    ];

    return (
      <Fragment>
        {usesRefCoupon ? (
          <Flex
            style={{
              backgroundColor: '#f9a394',
              color: 'white',
              padding: '4px 8px',
              width: '100%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text color="white" fontWeight={500}>
              Your coupon will be applied at the last step of the checkout
            </Text>
          </Flex>
        ) : null}
        <Container flex={1} px={3}>
          <Flex flexDirection="column">
            <Flex flex={1}>
              <Header dark={false}>
                <Heading color="#486288" fontSize={3} fontWeight={600} textAlign="center">
                  {history.location.pathname !== '/order-kit/paymentReceived' ? (
                    'Purchase a Thyroid Blood Test Kit'
                  ) : giveToFriend && giveToFriend.refereeFirstName ? (
                    <>
                      Your order is confirmed, thank you!
                      <br />
                      {`${giveToFriend.refereeFirstName} ${giveToFriend.refereeLastName} will receive an email with information on how to claim your gifted kit.`}
                    </>
                  ) : (
                    'Your order is confirmed, thank you!'
                  )}
                </Heading>
              </Header>
            </Flex>
            <Flex
              flexDirection={
                history.location.pathname !== '/order-kit/address' && history.location.pathname !== '/order-kit/additionalBiomarkers'
                  ? ['column-reverse', 'column-reverse', 'row']
                  : ['column', 'column', 'row']
              }
            >
              <Flex flex={1} mr={[0, 0, 4]} mb={4}>
                <Wizard history={history} basename="/order-kit">
                  <Steps>{stepsVal.filter((x) => x.display).map((x) => x.comp)}</Steps>
                </Wizard>
              </Flex>
              {history.location.pathname !== '/order-kit/complete' &&
                history.location.pathname !== '/order-kit/paymentReceived' &&
                order && (
                  <Flex width={[1, 1, 1 / 3]} mb={[4, 4, 0]} flexDirection="column">
                    <DesktopOnly>
                      <Box>
                        <Card p={4}>
                          <Flex flexDirection="column">
                            <Flex mb={3} justifyContent="center">
                              <img
                                alt=""
                                src={KitIcon}
                                width="184px"
                                style={{
                                  marginTop: '-48px',
                                  marginBottom: '-48px',
                                }}
                                height="184px"
                              />
                            </Flex>

                            {isInitializingOrder ? (
                              <PleaseWait text="Creating Order" />
                            ) : (
                              <Flex flex={1} mb={3} flexDirection="column">
                                {((order || {}).items || []).map((item, i) => (
                                  <Flex flex={1} key={i}>
                                    <Flex flex={1}>
                                      <Text fontWeight={300}>{item.description}</Text>
                                    </Flex>
                                    <Flex justifyContent="flex-end">${(item.amount / 100).toFixed(2)}</Flex>
                                  </Flex>
                                ))}
                                {!!order.id && !coupon ? (
                                  <Formik
                                    initialValues={{
                                      coupon: '',
                                    }}
                                    validate={(values, props) => {
                                      let errors = {};
                                      if (!values.coupon) {
                                        errors.coupon('Required');
                                      }
                                      return errors;
                                    }}
                                    onSubmit={async (values, actions) => {}}
                                    render={({ errors, touched, isValid, isSubmitting, actions, setSubmitting, values, resetForm }) => {
                                      return (
                                        <Form>
                                          <Flex flexDirection="row" style={{ marginTop: '8px' }}>
                                            <FormField style={{ flex: 1 }}>
                                              <TextInput placeholder="Coupon" style={{ padding: '6px' }} name="coupon" />
                                            </FormField>
                                            <Button
                                              variant="primary"
                                              style={{
                                                minWidth: '50px',
                                                width: '75px',
                                                padding: '8px',
                                                borderRadius: '10px',
                                                marginLeft: '8px',
                                              }}
                                              disabled={!isValid}
                                              type="button"
                                              onClick={() => {
                                                if ((order.amount || 0) / 100 < 100 && values.coupon === 'THYROIDMAMA') {
                                                  toastManager.add('To use this coupon code, you need to add at least one add-on to your purchase.', { appearance: 'error' });
                                                  resetForm();
                                                  return;
                                                }
                                                this.handleCoupon(values.coupon);
                                                resetForm();
                                              }}
                                            >
                                              Apply
                                            </Button>
                                          </Flex>
                                        </Form>
                                      );
                                    }}
                                  />
                                ) : null}
                                <Text fontSize={3} fontWeight={500} textAlign="center" mt={3}>
                                  {order.items.length > 0 ? 'Total' : 'Complete Thyroid Test'} ${order.amount ? (order.amount / 100).toFixed(2) : '0.00'}
                                </Text>
                              </Flex>
                            )}

                            <Text fontWeight={300} textAlign="center">
                              You'll collect your sample at home.
                            </Text>

                            <Flex flexDirection="column" style={{ margin: '0 auto' }}>
                              <Flex
                                flexDirection="row"
                                style={{
                                  alignItems: 'center',
                                  marginTop: '16px',
                                }}
                              >
                                <img src={TruckIcon} alt="" width="36px" />

                                <Text fontWeight={300} ml="12px">
                                  FREE 3-day shipping
                                </Text>
                              </Flex>
                              <Flex
                                flexDirection="row"
                                style={{
                                  alignItems: 'center',
                                  marginTop: '16px',
                                  marginBottom: '16px',
                                }}
                              >
                                <img src={CliaIcon} alt="" width="36px" />

                                <Text fontWeight={300} ml="12px">
                                  CLIA - certified lab
                                </Text>
                              </Flex>
                            </Flex>

                            {/* <StyledLink
                              mt={3}
                              href="https://palomahealth.com/kit"
                            >
                              Learn More
                            </StyledLink> */}

                            {history.location.pathname === '/order-kit/additionalBiomarkers' ? (
                              <Button
                                style={{
                                  alignSelf: 'center',
                                  width: '160px',
                                  maxWidth: '160px',
                                  marginTop: '16px',
                                }}
                                variant="pink"
                                onClick={() => {
                                  wizard.next();
                                }}
                              >
                                Checkout
                              </Button>
                            ) : null}
                          </Flex>
                        </Card>
                      </Box>
                    </DesktopOnly>

                    <MobileView>
                      <Box style={{ flex: 1 }}>
                        <Card p={4}>
                          {isInitializingOrder ? (
                            <PleaseWait text="Creating Order" />
                          ) : (
                            <>
                              {!order || !order.id || !order.items || order.items.length < 1 ? (
                                <Flex flexDirection="row">
                                  <img
                                    alt=""
                                    src={KitIcon}
                                    width="144px"
                                    style={{
                                      marginTop: '-24px',
                                      marginBottom: '-48px',
                                      marginLeft: '-24px',
                                    }}
                                    height="144px"
                                  />
                                  <Flex flexDirection="column" style={{ marginTop: '-24px' }}>
                                    <Text fontSize={3} fontWeight={500} textAlign="center" mt={3}>
                                      Complete Thyroid Test
                                    </Text>
                                    <Text fontSize={3} fontWeight={500} textAlign="center" mt={3}>
                                      $99.00
                                    </Text>
                                  </Flex>
                                </Flex>
                              ) : (
                                <Flex flexDirection="column">
                                  <Flex mb={3} justifyContent="center">
                                    <img
                                      alt=""
                                      src={KitIcon}
                                      width="184px"
                                      style={{
                                        marginTop: '-48px',
                                        marginBottom: '-48px',
                                      }}
                                      height="184px"
                                    />
                                  </Flex>

                                  {isInitializingOrder ? (
                                    <PleaseWait text="Creating Order" />
                                  ) : (
                                    <Flex flex={1} mb={3} flexDirection="column">
                                      {order.items.map((item, i) => (
                                        <Flex flex={1} key={i}>
                                          <Flex flex={1}>
                                            <Text fontWeight={300}>{item.description}</Text>
                                          </Flex>
                                          <Flex justifyContent="flex-end">${(item.amount / 100).toFixed(2)}</Flex>
                                        </Flex>
                                      ))}
                                      {!!order.id && !coupon ? (
                                        <Formik
                                          initialValues={{
                                            coupon: '',
                                          }}
                                          validate={(values, props) => {
                                            let errors = {};
                                            if (!values.coupon) {
                                              errors.coupon('Required');
                                            }
                                            return errors;
                                          }}
                                          onSubmit={async (values, actions) => {}}
                                          render={({ errors, touched, isValid, isSubmitting, actions, setSubmitting, values, resetForm }) => {
                                            return (
                                              <Form>
                                                <Flex flexDirection="row" style={{ marginTop: '8px' }}>
                                                  <FormField style={{ flex: 1 }}>
                                                    <TextInput placeholder="Coupon" style={{ padding: '6px' }} name="coupon" />
                                                  </FormField>
                                                  <Button
                                                    variant="primary"
                                                    style={{
                                                      minWidth: '50px',
                                                      width: '75px',
                                                      padding: '8px',
                                                      borderRadius: '10px',
                                                      marginLeft: '8px',
                                                    }}
                                                    disabled={!isValid}
                                                    type="button"
                                                    onClick={() => {
                                                      if ((order.amount || 0) / 100 < 100 && values.coupon === 'THYROIDMAMA') {
                                                        toastManager.add('To use this coupon code, you need to add at least one add-on to your purchase.', { appearance: 'error' });
                                                        resetForm()
                                                        return;
                                                      }
                                                      this.handleCoupon(values.coupon);
                                                      resetForm();
                                                    }}
                                                  >
                                                    Apply
                                                  </Button>
                                                </Flex>
                                              </Form>
                                            );
                                          }}
                                        />
                                      ) : null}
                                      <Text fontSize={3} fontWeight={500} textAlign="center" mt={3}>
                                        {order.items.length > 0 ? 'Total' : 'Complete Thyroid Test'} ${order.amount ? (order.amount / 100).toFixed(2) : '0.00'}
                                      </Text>

                                      {history.location.pathname === '/order-kit/additionalBiomarkers' ? (
                                        <Button
                                          style={{
                                            alignSelf: 'center',
                                            width: '160px',
                                            maxWidth: '160px',
                                            marginTop: '16px',
                                          }}
                                          variant="pink"
                                          onClick={() => {
                                            wizard.next();
                                          }}
                                        >
                                          Checkout
                                        </Button>
                                      ) : null}
                                    </Flex>
                                  )}
                                </Flex>
                              )}
                            </>
                          )}
                        </Card>
                      </Box>
                    </MobileView>
                  </Flex>
                )}
            </Flex>
            <MobileView>
              <Flex flexDirection="column" style={{ paddingBottom: '104px' }}>
                <Text fontWeight={300} textAlign="center">
                  You'll collect your sample at home.
                </Text>

                <Flex flexDirection="column" style={{ margin: '0 auto' }}>
                  <Flex
                    flexDirection="row"
                    style={{
                      alignItems: 'center',
                      marginTop: '16px',
                    }}
                  >
                    <img src={TruckIcon} alt="" width="36px" />

                    <Text fontWeight={300} ml="12px">
                      FREE 3-day shipping
                    </Text>
                  </Flex>
                  <Flex
                    flexDirection="row"
                    style={{
                      alignItems: 'center',
                      marginTop: '16px',
                      marginBottom: '16px',
                    }}
                  >
                    <img src={CliaIcon} alt="" width="36px" />

                    <Text fontWeight={300} ml="12px">
                      CLIA - certified lab
                    </Text>
                  </Flex>
                </Flex>

                {/* <StyledLinkWhite mt={3} href="https://palomahealth.com/kit">
                  Learn More
                </StyledLinkWhite> */}
              </Flex>
            </MobileView>
          </Flex>
          <Fragment>
            <ConfirmModal
              confirmLabel="OK"
              isOpen={isCouponBOGOdisabledModal}
              okOnly
              onClose={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
              onConfirm={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
              onBackgroundClick={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
              onEscapeKeydown={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
            >
              <ModalInner>
                <Heading mb={4} mx={3} textAlign="center">
                  January is National Thyroid Awareness Month – Buy a Kit, Give a Kit FREE
                </Heading>
                <InfoBox bg="#D9E6F9" mb={4}>
                  <Text fontSize={3} fontWeight={700} textAlign="center">
                    Paloma is committed to elevating and spreading access to great thyroid care. In the spirit of Thyroid Awareness Month, we are giving a free kit for every kit purchased. Instead of
                    using a promo code, in January only, Paloma is providing the ability to gift a friend or loved one a free thyroid test to.
                  </Text>
                </InfoBox>
              </ModalInner>
            </ConfirmModal>
          </Fragment>
        </Container>

        <ConfirmOrderIsNotDuplicateModal
          isOpen={isConfirmNotDuplicateModalOpen}
          onCancel={() => {
            this.setState({
              isConfirmNotDuplicateModalOpen: false,
              loading: false,
            });
            window.location.href = 'https://www.palomahealth.com/';
          }}
          onProceed={() => {
            this.proceedWithPurchase(proceedState.actions, proceedState.wizard, proceedState.sourceId);
          }}
        />
        <FullLayout />
        {isApplyingCoupon ? <LoadingScreen loading={true} style={{ opacity: 0.75 }} position="fixed" text="Applying Your Coupon" /> : null}
      </Fragment>
    );
  }
}

export default injectStripe(
  compose(
    withAnalytics,
    withApollo,
    withRouter,
    withToastManager,
    withCookies
  )(OrderKit)
);
