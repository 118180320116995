import React, { useState } from 'react';
import { Box, Card, Flex, Heading, Image } from 'rebass';
import parse, { domToReact, htmlToDOM, Element } from 'html-react-parser';
import styled from 'styled-components';
import { GET_ARTICLE_BY_ID, GET_RANDOM_ARTICLES_WITH_TAG } from '../graphql';
import { ArticleCard } from './landing/Articles';
import { withRouter } from 'react-router';
import { Query } from 'react-apollo';

const Container = styled(Flex)`
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 24px;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Img = styled(Image)`
  transition: 0.3s all ease-in-out;
  object-fit: cover;
  &:hover {
    transform: scale(1.1);
  }
`;

const ArticleCardContainer = styled(Flex)`
  transition: 0.3s all ease-in-out;
  & > * {
    transition: 0.3s all ease-in-out;
  }
  &:hover > * {
    opacity: 0.5;
  }
  &:hover > :hover {
    opacity: 1;
  }
`;

const ViewArticleInner = ({ location, articleId, history, data, loading }) => {
  console.log({ aid: articleId });

  const dta = (((data || {}).getArticleByIdFromWeb || {}).content || '')
    .replace(/(<p><\/p>)/g, '')
    .replace(/(<p><br><\/p>)/g, '')
    .replace(/(<p>&nbsp;<\/p>)/g, '')
    .replace(/<[^/>][^>]*><\/[^>]+>/gim, '');

  const prs = parse(dta, {
    replace: (domNode) => {
      console.dir(domNode, { depth: null });
      if (domNode && domNode.name === 'a') {
        if (domNode.attribs) {
          if (domNode.attribs.href && domNode.attribs.href[0] === '/') {
            return <a href={`/articles/${encodeURIComponent(domNode.attribs.href.replace('/', ''))}`}>{domToReact(domNode.children)}</a>;
          }
        }
      }
    },
  });

  return (
    <Flex flexDirection={'column'}>
      <Card
        style={{
          borderRadius: '10px',
          maxWidth: '700px',
          flex: 'initial',
          overflow: 'hidden',
        }}
      >
        <Box style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '290px' }}>
          <Img height={290} width={'100%'} src={(location.state || {}).thumbnailUrl || ((data || {}).getArticleByIdFromWeb || {}).thumbnailUrl} />
        </Box>
        <Flex flexDirection={'column'} p="44px">
          <Heading textAlign={'center'}>{(location.state || {}).title || ((data || {}).getArticleByIdFromWeb || {}).title}</Heading>
          {!loading && !!data && <>{prs}</>}
        </Flex>
      </Card>
      {!loading &&
        data.getArticleByIdFromWeb &&
        data.getArticleByIdFromWeb.tag &&
        data.getArticleByIdFromWeb.tag.id && (
          <Flex flexDirection={'column'} style={{ maxWidth: '700px' }}>
            <Heading textAlign={'center'} mt={'40px'} mb={'24px'}>
              Related Posts
            </Heading>
            <RelatedPosts history={history} tag={data.getArticleByIdFromWeb.tag.id} />
          </Flex>
        )}
    </Flex>
  );
};

const RelatedPosts = ({ tag, history }) => {
  return (
    <Query
      query={GET_RANDOM_ARTICLES_WITH_TAG}
      variables={{
        limit: 12,
        tag,
      }}
      fetchPolicy='network-only'
    >
      {({ data, loading, error }) => {
        if (!!loading) return null;
        if (error) return null;
        return (
          <ArticleCardContainer flexWrap={'wrap'}>
            {data.getArticlesWithTag.map((x) => {
              return (
                <ArticleCard
                  article={x}
                  tag={x.id}
                  onClick={() =>
                    history.push({
                      pathname: `/articles/${encodeURIComponent(x.title)}`,
                      state: {
                        ...x,
                      },
                    })
                  }
                />
              );
            })}
          </ArticleCardContainer>
        );
      }}
    </Query>
  );
};

const ViewArticleInnerWrapper = ({ location, articleId, history }) => {
  return (
    <Query
      query={GET_ARTICLE_BY_ID}
      variables={{
        id: articleId,
      }}
    >
      {({ data, loading }) => {
        return <ViewArticleInner data={data} loading={loading} location={location} articleId={articleId} history={history} />;
      }}
    </Query>
  );
};

const ViewArticle = ({ match, location, history }) => {
  const articleId = match && match.params && match.params.id ? match.params.id : '';
  return (
    <Container flex={1}>
      <ViewArticleInnerWrapper match={match} location={location} articleId={articleId} history={history} />
    </Container>
  );
};

export default withRouter(ViewArticle);
