import React, { useState } from 'react';
import { CarouselCard } from '../YearlyReview';
import { PlateHeart } from '../icons/PlateHeart';
import { FirstPage, FirstPageBackground } from '../pages/FirstPage';

const SECTION_BACKGROUND = '#FDF5F2';
const SECTION_NAME = 'Your time matters';

const getStories = (data) => {
  return [
    {
      progressStyle: {
        foreground: '#344F7A',
      },
      backgroundColor: '#344F7A',
      duration: 5000,
      content: <FirstPage />,
      background: <FirstPageBackground />,
      show: true,
      analytics:{
        section: 'Cover Page',
        screen: 'CoverPage'
      }
    },
  ];
};

const CoverPageInner = ({ isActive, setActive, data, active, onAllStoriesEnd, onClose }) => {
  const [stories, setStories] = useState(getStories(data).filter((x) => !!x.show));

  return (
    <CarouselCard onClose={onClose} hideIndicators sectionNameColor='white' stories={stories} sectionName={SECTION_NAME} section={{ inactiveIcon: <PlateHeart /> }} isActive={isActive} backgroundColor={SECTION_BACKGROUND} onAllStoriesEnd={onAllStoriesEnd} />
  );
};

export const CoverPageSection = ({ isActive, setActive, active, onAllStoriesEnd, onClose }) => {
  if (!isActive) return null;
  return <CoverPageInner isActive={isActive} active={active} setActive={setActive} onAllStoriesEnd={onAllStoriesEnd} onClose={onClose}/>;
};
