import React from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Text } from 'rebass';
import { withApollo, Mutation } from 'react-apollo';
import { ADMIN_BATCH_CREATE_SLOTS } from '../graphql';

const Container = styled(Flex)`
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
  flex-direction: column;
`;
const ReportRow = styled(Flex)`
  margin-left: 18px;
  align-items: center;
  margin-bottom: 12px;
  background: white;
  padding: 4px 8px;
  &:hover {
    background: #fafafa;
  }
`;
const AdminBatchCreateSlots = () => {
  return (
    <div style={{ width: '100%', overflowY: 'auto' }}>
      <Container>
        <Flex flexDirection={'column'} flex={1} textAlign="center" alignSelf="center" justifyContent="center">
          <Text fontSize="38px" fontWeight={600} textAlign="center" mb={3} style={{ fontFamily: 'Playfair Display' }} color="#344f79">
            Batch Slots Provider
          </Text>

          <Text fontSize="18px" fontWeight={300} textAlign="center" mb={3} style={{ fontFamily: 'Poppins' }} color="#344f79">
            Will create the slots based on the Airtable Doctors availability. Ensure Start and End dates are in NY timezone and the End is exclusive
          </Text>
        </Flex>
        <Mutation
          mutation={ADMIN_BATCH_CREATE_SLOTS}
          onCompleted={(res) => {
            if (!res) {
              alert('Slots creation completed');
              return;
            }
            if (!!res && !!res.data && !!res.data.adminBatchCreateSlots) {
              alert(`${res.data.adminBatchCreateSlots.count} Slots created`);
              return;
            }
            if (!!res && !!res && !!res.adminBatchCreateSlots) {
              alert(`${res.adminBatchCreateSlots.count} Slots created`);
              return;
            }
            alert('Slots creation completed');
          }}
        >
          {(generateMutate, { loading }) => {
            return (
              <Button
                disabled={loading}
                variant="primary"
                style={{
                  alignSelf: 'center',
                  marginTop: '48px',
                }}
                onClick={() => {
                  generateMutate();
                }}
              >
                Create Slots
              </Button>
            );
          }}
        </Mutation>
      </Container>
    </div>
  );
};

export default withApollo(AdminBatchCreateSlots);
