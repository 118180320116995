import React from 'react';
import { Flex, Text, Heading, Button } from 'rebass';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import styled from 'styled-components';
import SpinLoader from '../../../../components/SpinLoader';
import { withRouter } from 'react-router-dom';
import { APPOINTMENTS, GET_MY_WAITLIST } from '../../../../graphql';

const ADD_TO_WAITLIST = gql`
  mutation addToWaitlist($providerId: String!, $departmentId: String!, $appointmentDuration: Int!, $reschedulingId: String) {
    addToWaitlist(providerId: $providerId, departmentId: $departmentId, appointmentDuration: $appointmentDuration, reschedulingId: $reschedulingId) {
      ok
    }
  }
`;

const ModalInner = styled(Flex)`
  max-width: 800px;
  width: 100%;
  flex-direction: column;
`;

const AddToWaitlistModalInner = ({ selectedDoctor, departmentId, onClose, history, appointmentDuration, reschedulingAppointment }) => {
  return (
    <ModalInner>
      <Mutation
        mutation={ADD_TO_WAITLIST}
        refetchQueries={[{ query: GET_MY_WAITLIST }, { query: APPOINTMENTS }]}
        awaitRefetchQueries={true}
        variables={{
          providerId: selectedDoctor.id,
          departmentId,
          appointmentDuration,
          reschedulingId: reschedulingAppointment && reschedulingAppointment.id ? reschedulingAppointment.id : null,
        }}
      >
        {(mutate, { loading, data, error }) => {
          if (!!loading) {
            return (
              <Flex flex={1} flexDirection={'column'} justifyContent={'column'} alignItems={'center'}>
                <Heading mb={4} mx={3} textAlign="center">
                  Activate Waitlist Notification
                </Heading>

                <SpinLoader />
              </Flex>
            );
          }

          if (!!error || (!!data && data.addToWaitlist && !data.addToWaitlist.ok)) {
            return (
              <>
                <Heading mb={4} mx={3} textAlign="center">
                  Activate Waitlist Notification
                </Heading>

                <Text fontWeight={400} textAlign="center">
                  An error occured
                </Text>
                <Button variant={'primary'} mt={4} style={{ alignSelf: 'center', width: 'fit-content' }} onClick={onClose}>
                  close
                </Button>
              </>
            );
          }

          if (!!data && data.addToWaitlist && !!data.addToWaitlist.ok) {
            return (
              <>
                <Heading mb={4} mx={3} textAlign="center">
                  {`You've been added to ${selectedDoctor.patientFacingName}'s waitlist`}
                </Heading>

                <Text fontWeight={400} textAlign="center" mb={3}>
                  We'll promptly notify you via SMS as soon as a spot becomes available, typically due to a cancellation.
                  <br />
                  It's important to note that if you go ahead and schedule a visit, you'll be automatically removed from the waitlist. This ensures we can efficiently manage availability and provide
                  timely service to everyone.
                </Text>

                <Button variant={'primary'} mt={4} style={{ alignSelf: 'center', width: 'fit-content' }} onClick={() => history.push('/')}>
                  Go back to dashboard
                </Button>
              </>
            );
          }

          if (!!reschedulingAppointment) {
            return (
              <>
                <Heading mb={4} mx={3} textAlign="center">
                  Join Our Waitlist: Important Information
                </Heading>

                <Text fontWeight={400} textAlign="center">
                  If you are unable to find a suitable time and would like to join our waitlist, please be aware that we will automatically cancel your existing appointment. This ensures that we can
                  efficiently manage our schedule and offer timely services to all our patients.
                </Text>

                <Button variant={'primary'} mt={4} style={{ alignSelf: 'center', width: 'fit-content' }} onClick={mutate}>
                  Confirm and Join Waitlist
                </Button>
              </>
            );
          }

          return (
            <>
              <Heading mb={4} mx={3} textAlign="center">
                Activate Waitlist Notification
              </Heading>

              <Text fontWeight={400} textAlign="center">
                None of the times work for you?
                <br />
                No worries we can add you to this provider's waitlist and let you know when a spot with this provider opens up
              </Text>

              <Button variant={'primary'} mt={4} style={{ alignSelf: 'center', width: 'fit-content' }} onClick={mutate}>
                Notify me
              </Button>
            </>
          );
        }}
      </Mutation>
    </ModalInner>
  );
};

export default withRouter(AddToWaitlistModalInner);
