import React, { useState } from 'react';
import { Button, Flex, Heading } from 'rebass';
import Select from 'react-select';
import { withToastManager } from 'react-toast-notifications';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { SEARCH_USER_FROM_CARE_MANAGERS } from './ModalSearchUser';
import { ADMIN_GET_CARE_MANAGERS_LIST } from '../../graphql';

const REASSIGN_CARE_MANAGER = gql`
  mutation reassignCareManager($userId: String!, $helpscoutId: String!) {
    reassignCareManager(userId: $userId, helpscoutId: $helpscoutId) {
      ok
      customError
    }
  }
`;

const ModalReassignCareManager = ({ user, careManagers, toastManager, onClose, search, closeAnyway }) => {
  const [newCareManager, setNewCareManager] = useState();

  return (
    <Mutation
      mutation={REASSIGN_CARE_MANAGER}
      refetchQueries={[{ query: SEARCH_USER_FROM_CARE_MANAGERS, variables: { athenaId: search } }, { query: ADMIN_GET_CARE_MANAGERS_LIST }]}
      onCompleted={(data) => {
        if (data && data.reassignCareManager) {
          if (data.reassignCareManager.ok) {
            toastManager.add(data.reassignCareManager.customError, {
              appearance: 'success',
              autoDismiss: true,
            });
            onClose();
          } else {
            toastManager.add(data.reassignCareManager.customError, {
              appearance: 'error',
              autoDismiss: true,
            });
            if(!!closeAnyway) {
              closeAnyway()
            }
          }
        }
      }}
    >
      {(reassign, { loading }) => {
        return (
          <Flex flexDirection={'column'}>
            <Heading mb={4}>Reassign Care Manager</Heading>

            <Select
              classNamePrefix="region-select"
              options={careManagers.filter((x) => x.helpscoutId !== user.careManager).map((x) => {
                return {
                  label: x.fullName,
                  value: x.helpscoutId,
                };
              })}
              placeholder="select new Care Manager"
              onChange={(option) => {
                console.log({ option });
                setNewCareManager(option.value);
              }}
              isSearchable={true}
            />

            <Button
              mt={3}
              disabled={!!loading}
              variant={'primary'}
              style={{ alignSelf: 'center' }}
              onClick={() => {
                reassign({
                  variables: {
                    userId: user.id,
                    helpscoutId: newCareManager,
                  },
                });
              }}
            >
              Reassign
            </Button>
          </Flex>
        );
      }}
    </Mutation>
  );
};

export default withToastManager(ModalReassignCareManager);
