import React from 'react';
import { Box, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { Query, withApollo } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import gql from 'graphql-tag';

const GET_MY_LOGS = gql`
  query getAdminMyLogs {
    getAdminMyLogs {
      id
      createdAt
      description
      action
      actionType
      ip
    }
  }
`;

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;

const AdminViewMyLogs = () => {
  const columns = [
    // {
    //   Header: '',
    //   accessor: 'flag',
    //   width: 40,
    //   Cell: (row) => (
    //     <span data-tip={(row.value || '') === 'Same IP' ? 'Same IP address' : null} data-type={(row.value || '') === 'Same IP' ? 'warning' : null}>
    //       {' '}
    //       <FontAwesomeIcon size="1x" color={(row.value || '') === 'Same IP' ? 'red' : '#0cac0c'} icon={faFlag} />
    //     </span>
    //   ),
    // },
    {
      Header: 'action',
      accessor: 'action',
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase())>-1
    },
    {
      Header: 'actionType',
      accessor: 'actionType',filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        return row[filter.id]===filter.value
      },
        Filter: ({ filter, onChange }) =>
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%", height: '100%' }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="INFORMATION">INFORMATION</option>
            <option value="SECURITY">SECURITY</option>
            <option value="ACTION">ACTION</option>
          </select>
    },
    {
      Header: 'description',
      accessor: 'description',
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase())>-1
    },
    {
      Header: 'date',
      accessor: 'createdAt',
      Cell: row => (
        <Text textAlign="left">
          {moment(row.value).format('MM-DD-YYYY hh:mm a')}
        </Text>
      ),
      filterable: false
    },
    {
      Header: 'ip',
      accessor: 'ip',
      filterable: false
    },
    
  ];

  return (
    <Container>
      <Heading mb={4} textAlign={'center'}>My Logs</Heading>
      <Query query={GET_MY_LOGS} fetchPolicy='network-only'>
        {({ data:{getAdminMyLogs}, loading }) => {
          return (
            <>
              <ReactTooltip place="left" type="success" effect="solid" />
              <ReactTable
                data={getAdminMyLogs}
                columns={columns}
                filterable={true}
                loading={loading}
                onPageChange={(x) => {
                  console.log('page'.x);
                }}
                showPagination={true}
                showPageSizeOptions={true}
                
                pageSizeOptions={[20, 25, 50, 100]}
                defaultPageSize={100}
              />
            </>
          );
        }}
      </Query>
    </Container>
  );
};

export default withApollo(AdminViewMyLogs);
