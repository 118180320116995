import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { compose, withApollo } from 'react-apollo';
import Select from 'react-select';

import { withToastManager } from 'react-toast-notifications';
import FormField from '../../components/FormField';
import { Form, Formik } from 'formik';
import styled from 'styled-components';

const SelectStyled = styled(Box)`
  .region-select__control {
    border: 1px solid rgba(53, 78, 121, 0.1);
    border-radius: 10px;
    padding: 12px;
    background: #fff;

    &:hover {
      border-color: #eee;
    }

    &--is-focused {
      box-shadow: none;
    }

    .region-select__indicator-separator {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const SelectCompany = ({ onSubmit, wizard, companiesList, selectedCompany, relationship, pushNext }) => {
  const [companies, setCompanies] = useState(
    companiesList.map((x) => {
      return {
        label: x.friendlyName || x.name,
        value: x.id,
      };
    })
  );
  useEffect(()=>{
if(!!selectedCompany){
  pushNext(wizard)
}
  },[])

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex width={[1, 1, 3 / 4]}>
        <Card p={4}>
          <Flex flexDirection="column">
            <Box mb={4}>
              <Heading textAlign="center" fontWeight={550} mb={3}>
                Which organization sponsors your Paloma Health membership?
              </Heading>

              <Text textAlign="center">{relationship === 'self' ? 'This may be your employer or health insurance company.' : "This may be your partner's employer or health insurance company"}</Text>
            </Box>
            <Formik
              isInitialValid={() => !!selectedCompany}
              initialValues={{
                selectedCompany: selectedCompany,
              }}
              onSubmit={(values, actions) => {
                onSubmit(companiesList.find((x) => x.id === values.selectedCompany.value), actions, wizard);
              }}
              render={({ setFieldValue, setFieldTouched, values }) => (
                <Form>
                  <FormField mb={4}>
                    <SelectStyled>
                      <Select
                        classNamePrefix="region-select"
                        options={companies}
                        name="difficulty"
                        value={values.selectedCompany}
                        placeholder="Organization name"
                        onChange={(option) => {
                          setFieldValue('selectedCompany', option);
                        }}
                        onBlur={() => setFieldTouched('selectedCompany', true)}
                        style={{ minHeight: '60px' }}
                        isSearchable={true}
                      />
                    </SelectStyled>
                  </FormField>
                  <Flex justifyContent="center">
                    <Button
                      variant={'primary'}
                      mt={3}
                      alignSelf="center"
                      style={{ minWidth: '250px', margin: '0 auto' }}
                      type="submit"
                      disabled={!values.selectedCompany || !values.selectedCompany.value}
                    >
                      Submit
                    </Button>
                  </Flex>
                </Form>
              )}
            />
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

export default compose(
  withApollo,
  withToastManager,
  withWizard
)(SelectCompany);
