import React, { Fragment, useEffect } from 'react';
import { Button, Card, Flex, Heading, Text, Box } from 'rebass';
import { withWizard } from 'react-albus';
import { isToggleActive } from '../../components/featureToggle/toggles';
import { additionalBiomarkersHelper } from '../../utils/additionalBiomarkersUtils';

const AdditionalBiomarkers = (props) => {
  const { address, wizard, onNext, order, createInitialOrder, addItemToOrder, removeItemFromOrder, selectedBiomarkers, isComputingBiomarker, isB2b } = props;

  useEffect(() => {
    if (!address.street1) {
      wizard.push('profile');
    }
    if (!isB2b && (!order || !order.id)) {
      createInitialOrder();
    }
  }, []);

  const isLoaded = !!order && order.id && order.items && order.items.length > 1;
  return (
    <Fragment>
      <Flex flex={1} style={{ alignSelf: 'end' }}>
        <Flex flexDirection="column">
          <Heading textAlign="center" color="#486288" fontWeight={550} mb={4}>
            Optional Add-ons
          </Heading>
          <Text mb="28px" textAlign="center" color="#486288">
            In addition to the four markers already included in your test kit, the following add-ons are optionally available for even more comprehensive testing, still only requiring one finger
            prick.
          </Text>
          {additionalBiomarkersHelper.biomarkers
            .filter((x) => {
              return isToggleActive(x.active);
            })
            .map((biomarker) => {
              return (
                <Card p={4} key={biomarker.id} mb={28}>
                  <Flex flexDirection="column">
                    <Flex flexDirection="row" style={{ alignItems: 'baseline' }}>
                      <Heading textAlign="center" fontWeight={550} mb={4} mr="16px">
                        {biomarker.title}
                      </Heading>

                      <Text>• ${biomarker.price}</Text>
                    </Flex>

                    <Box
                      style={{
                        border: '1px solid rgba(53, 80, 120, 0.3)',
                        borderLeft: '0',
                        borderRight: '0',
                        padding: '12px 0',
                      }}
                    >
                      {biomarker.description}
                    </Box>
                    {selectedBiomarkers.filter((x) => x === biomarker.sku).length < 1 ? (
                      <Button
                        style={{
                          alignSelf: 'flex-end',
                          width: '160px',
                          maxWidth: '160px',
                          marginTop: '16px',
                        }}
                        variant="pink"
                        onClick={() => {
                          addItemToOrder(biomarker.sku);
                        }}
                        disabled={isComputingBiomarker || !isLoaded}
                      >
                        {!isLoaded ? 'Initializing' : 'Add'}
                      </Button>
                    ) : (
                      <Button
                        style={{
                          alignSelf: 'flex-end',
                          width: '160px',
                          maxWidth: '160px',
                          marginTop: '16px',
                        }}
                        variant="outline"
                        disabled={isComputingBiomarker || !isLoaded}
                        onClick={() => {
                          removeItemFromOrder(biomarker.sku);
                        }}
                      >
                        {!isLoaded ? 'Initializing' : 'Remove'}
                      </Button>
                    )}
                  </Flex>
                </Card>
              );
            })}

          <Button
            style={{
              alignSelf: 'center',
              width: '160px',
              maxWidth: '160px',
              marginTop: '16px',
            }}
            variant="pink"
            disabled={isComputingBiomarker || !isLoaded}
            onClick={() => {
              onNext(wizard);
            }}
          >
            {!isLoaded ? 'Initializing' : 'Next'}
          </Button>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default withWizard(AdditionalBiomarkers);
