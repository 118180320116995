import React from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';

const ModalUploadEligibilityResults = ({ results }) => {
  const CATEGORIES_COLUMNS = [
    {
      Header: '',
      accessor: 'type',
    },
    {
      Header: 'first Name',
      accessor: 'firstName',
    },
    {
      Header: 'last Name',
      accessor: 'lastName',
    },
    {
      Header: 'dob',
      accessor: 'dob',
    },
    {
      Header: 'employee Email',
      accessor: 'employeeEmail',
    },
    {
      Header: 'employee Id',
      accessor: 'employeeId',
    },
    {
      Header: 'subscriberId',
      accessor: 'subscriberId',
    },
    {
      Header: 'uniqueCode',
      accessor: 'uniqueCode',
    },
    {
      Header: 'memberId',
      accessor: 'memberId',
    },
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: 'error',
      accessor: 'error',
    },
  ];

  return (

    <ReactTable
    data={results || []}
    columns={CATEGORIES_COLUMNS}
    defaultPageSize={results && results.length > 3 ? Math.min(results.length, 25) : 3}
    pageSize={results && results.length > 3 ? results.length : 3}
    showPagination={false}
  />
  )
}

export default ModalUploadEligibilityResults