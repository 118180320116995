export const QUESTIONS_FOLLOWUP_SURVEYS_2 = [
  {
    id:'OverallWellBeing',
    title: 'How would you describe your overall well-being?',
    type: 'PICKONE',
    options: [
      {
        text: 'Great',
        value: 'Great',
      },
      {
        text: 'Good',
        value: 'Good',
      },
      {
        text: 'OK',
        value: 'OK',
      },
      {
        text: 'Not so good',
        value: 'Not so good',
      },
      {
        text: 'Bad',
        value: 'Bad',
      },
    ],
    cantBypass: true,
    nextTitle: `Continue`,
  },
  {
    id:'SatisfiedPalomaCare', 
    title: 'How satisfied are you with your care at Paloma Health?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not satisfied at all',
        value: 'Not satisfied at all',
      },
      {
        text: 'Somewhat dissatisfied',
        value: 'Somewhat dissatisfied',
      },
      {
        text: 'A bit dissatisfied',
        value: 'A bit dissatisfied',
      },
      {
        text: 'Not satisfied, not dissatisfied',
        value: 'Not satisfied, not dissatisfied',
      },
      {
        text: 'A bit satisfied',
        value: 'A bit satisfied',
      },
      {
        text: 'Somewhat satisfied',
        value: 'Somewhat satisfied',
      },
      {
        text: 'Fully satisfied',
        value: 'Fully satisfied',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id: 'HowOftenSkipMedication',
    title: 'How often do you skip taking your thyroid medication?',
    type: 'PICKONE',
    options: [
      {
        text: `Few times a week`,
        value: `Few times a week`
      },
      {
        text: 'Once a week',
        value: 'Once a week',
      },
      {
        text: 'Once every two weeks',
        value: 'Once every two weeks',
      },
      {
        text: 'Once a month',
        value: 'Once a month',
      },
      {
        text: 'Once every few months',
        value: 'Once every few months',
      },
      {
        text: 'Never or almost never',
        value: 'Never or almost never',
      },
      {
        text: 'I do not take thyroid medication',
        value: 'I do not take thyroid medication',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  // {
  //   id:'DetailsLastDoctorVisit',
  //   title: 'Would you like to share anything with us about your last visit?',
  //   type: 'OPENTEXT',
  //   nextTitle: `Continue`,
  // },
  {
    id:'SensitiveCold',
    title: 'In the past 4 weeks have you been sensitive to cold?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  // {
  //   id:'SwollenHandsFeet',
  //   title: 'In the past 4 weeks have you had swollen hands or feet?',
  //   type: 'PICKONE',
  //   options: [
  //     {
  //       text: 'Not at all',
  //       value: 'Not at all',
  //     },
  //     {
  //       text: 'A bit',
  //       value: 'A bit',
  //     },
  //     {
  //       text: 'Somewhat',
  //       value: 'Somewhat',
  //     },
  //     {
  //       text: 'Quite a lot',
  //       value: 'Quite a lot',
  //     },
  //     {
  //       text: 'Fully',
  //       value: 'Fully',
  //     },
  //   ],
  //   nextTitle: `Continue`,
  //   cantBypass: true,
  // },
  {
    id:'DrySkin',
    title: 'In the past 4 weeks have you had dry skin and/or brittle nails?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'Tired',
    title: 'In the past four weeks have you been tired?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'DifficultiesMotivated',
    title: 'In the past four weeks did you have difficulties being motivated to do things?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'Energetic',
    title: 'In the past four weeks did you feel energetic?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'DifficultiesRemembering',
    title: 'In the past four weeks have you had difficulties remembering?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'SlowThinking',
    title: 'In the past four weeks have you had slow or unclear thinking?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'AfraidAnxious',
    title: 'In the past four weeks have you felt afraid or anxious?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'DifficultiesManagingDailyLife',
    title: 'In the past four weeks, did you feel you had difficulties managing your daily life?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'EverythingTakesLonger',
    title: 'In the past four weeks, did you feel everything takes longer to do?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'ThyroidNegativeImpactQualityOfLife',
    title: 'In the past four weeks, has your thyroid had a negative impact on your quality of life?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },


  {
    id: 'LifestyleChanges',
    title: 'Since starting Paloma Health, I have also implemented the following lifestyle/health changes:',
    type: 'PICKMULTIPLEOTHER',
    options: [
      {
        text: 'Modified my workout habits',
        value: 'Modified my workout habits',
      },
      {
        text: 'Modified my nutritional habits',
        value: 'Modified my nutritional habits',
      },
      {
        text: 'Modified my sleep habits',
        value: 'Modified my sleep habits',
      },
      {
        text: 'Modified my supplementation/vitamins',
        value: 'Modified my supplementation/vitamins',
      },
      {
        text: 'Began or changed another medical treatment',
        value: 'Began or changed another medical treatment',
      },
      {
        text: 'None of the above',
        value: 'None of the above',
      },
      {
        text: 'Other',
        value: 'Other',
        hasDetails: true,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },


  {
    id:'UsedAipProgram',
    title: 'Have you used Paloma Health AIP (autoimmune protocol) program in the Paloma app?',
    type: 'PICKONE',
    options: [
      {
        text: 'Yes, I have finished the whole programme',
        value: 'Yes, I have finished the whole programme',
      },
      {
        text: 'I’ve started it, but am not done yet',
        value: 'I’ve started it, but am not done yet',
      },
      {
        text: 'No, I am not interested in AIP',
        value: 'No, I am not interested in AIP',
      },
      {
        text: 'I’ve started it, but I gave up',
        value: 'I’ve started it, but I gave up',
      },
      {
        text: 'I did not find the programme',
        value: 'I did not find the programme',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
    mobileUserOnly: true
  },
  {
    id:'MobileAppOtherParts',
    title: 'Have you used any other parts of Paloma app?',
    type: 'PICKMULTIPLEOTHER',
    options: [
      {
        text: 'I booked my doctor or nutritionist appointment through the app',
        value: 'I booked my doctor or nutritionist appointment through the app',
      },
      {
        text: 'I tracked my symptoms',
        value: 'I tracked my symptoms',
      },
      {
        text: 'I ordered my hormone test kit through the app',
        value: 'I ordered my hormone test kit through the app',
      },
      {
        text: 'I checked recipe library',
        value: 'I checked recipe library',
      },
      {
        text: 'I read blog articles',
        value: 'I read blog articles',
      },
      {
        text: 'I did breathing exercises',
        value: 'I did breathing exercises',
      },
      {
        text: 'I prepared my doctor report',
        value: 'I prepared my doctor report',
      },
      {
        text: 'I renewed my medication prescription',
        value: 'I renewed my medication prescription',
      },
      {
        text: 'I ordered supplements',
        value: 'I ordered supplements',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
    mobileUserOnly: true
  },
  {
    id:'RecommendOthers',
    title: 'How likely are you to recommend someone else with hypothyroidism to use Paloma Health?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not likely at all',
        value: 'Not likely at all',
      },
      {
        text: 'Somewhat not likely',
        value: 'Somewhat not likely',
      },
      {
        text: 'Not likely, and not unlikely',
        value: 'Not likely, and not unlikely',
      },
      {
        text: 'Somewhat likely',
        value: 'Somewhat likely',
      },
      {
        text: 'Very likely',
        value: 'Very likely',
      },
    ],
    cantBypass: true,
    nextTitle: `Continue`,
  },
];
