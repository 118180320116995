import React from 'react';
import {
  Flex,
  Box,
  Text,
  Heading,
  Button,
} from 'rebass';
import Mailcheck from 'react-mailcheck';
import styled from 'styled-components';
import Select from 'react-select';
import { TextAreaNoForm } from '../../../../components/TextArea';
import { Input } from '../../../../components/TextInput';
import { Mutation } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { TIME_DOESNT_WORK } from '../../../../graphql';
import { Checkbox } from '../../../../components/CheckboxInput';

const ModalInner = styled(Box)`
  max-width: 800px;
  width: 100%;
`;

const MailcheckWrapper = styled.div`
  text-align: right;
  @media screen and (max-width: 52em) {
    text-align: left;
  }
`;

const MailcheckButton = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  font-size: inherit;
  font-style: italic;
  color: rgb(237, 183, 168);
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;

  &:focus {
    outline: none;
    color: rgb(237, 183, 168);
  }

  &:hover {
    color: rgb(237, 183, 168);
  }

  &:active {
    color: rgb(237, 183, 168);
  }
`;

const weekMoment = [
  {
    label: 'weekday',
    value: 'weekday',
  },
  {
    label: 'weekend',
    value: 'weekend',
  },
];

const weekdays = [
  {
    label: 'Sunday',
    value: 'Sunday',
    showIf: 'weekend',
  },
  {
    label: 'Monday',
    value: 'Monday',
    showIf: 'weekday',
  },
  {
    label: 'Tuesday',
    value: 'Tuesday',
    showIf: 'weekday',
  },
  {
    label: 'Wednesday',
    value: 'Wednesday',
    showIf: 'weekday',
  },
  {
    label: 'Thursday',
    value: 'Thursday',
    showIf: 'weekday',
  },
  {
    label: 'Friday',
    value: 'Friday',
    showIf: 'weekday',
  },
  {
    label: 'Saturday',
    value: 'Saturday',
    showIf: 'weekend',
  },
];

const timeOfDay = [
  {
    label: 'early morning (before 10AM)',
    value: 'early morning',
  },
  {
    label: 'late morning (10am- noon)',
    value: 'late morning',
  },
  {
    label: 'early afternoon (noon -4pm)',
    value: 'early afternoon',
  },
  {
    label: 'late afternoon (4pm - 6pm)',
    value: 'late afternoon',
  },
  {
    label: 'evening (after 6pm)',
    value: 'evening',
  },
];

class TimeDoesntWorkModalInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMoment: null,
      selectedDays: null,
      selectedTimeOfDay: null,
      personalNote: '',
      email: null,
      hasRecentLabs: false,
    };
  }
  render() {
    const { onClose, isNewPatient } = this.props;
    const {
      selectedMoment,
      selectedDays,
      selectedTimeOfDay,
      personalNote,
      email,
    } = this.state;
    // console.log('Selected Moment', selectedMoment);
    let weekdaysFiltered = [];
    if (selectedMoment && selectedMoment.length) {
      const m = [];
      for (let index = 0; index < weekdays.length; index++) {
        const element = weekdays[index];
        if (element.showIf) {
          if (
            selectedMoment.filter(x => x.value === element.showIf).length > 0
          ) {
            m.push(element);
          }
        } else {
          m.push(element);
        }
      }
      weekdaysFiltered = m;
    } else {
      weekdaysFiltered = weekdays;
    }
    return (
      <ModalInner>
        <Heading mb={4} mx={3} textAlign="center">
          None of these times work for you?
        </Heading>
        <Text fontWeight={400} textAlign="center" mb={3}>
          As we keep improving our product, we love to get insights from our
          patients. To better serve you, let us know what times are ideal for
          you.
        </Text>

        <Flex flexDirection={['column', 'column', 'row']} mb={4}>
          <Flex
            flexDirection={['column']}
            flex={1}
            mr={[0, 0, 2]}
            mb={[3, 3, 0]}
          >
            <Text>Weekday/Weekend</Text>
            <Select
              classNamePrefix="region-select"
              options={weekMoment}
              placeholder=""
              isSearchable={false}
              value={selectedMoment}
              onChange={opt => {
                let days = [];
                if (selectedDays) {
                  for (let index = 0; index < selectedDays.length; index++) {
                    const element = selectedDays[index];
                    if (element.showIf) {
                      if (
                        opt.filter(x => x.value === element.showIf).length > 0
                      ) {
                        days.push(element);
                      }
                    } else {
                      days.push(element);
                    }
                  }
                }
                this.setState({ selectedMoment: opt, selectedDays: days });
              }}
              isMulti
              closeMenuOnSelect={false}
            />
          </Flex>
          <Flex
            flexDirection={['column']}
            flex={1}
            mr={[0, 0, 2]}
            mb={[3, 3, 0]}
          >
            <Text>Days</Text>
            <Select
              classNamePrefix="region-select"
              options={weekdaysFiltered}
              placeholder=""
              value={selectedDays}
              onChange={opt => this.setState({ selectedDays: opt })}
              isSearchable={false}
              isMulti
              closeMenuOnSelect={false}
            />
          </Flex>
          <Flex flexDirection={['column']} flex={1} mr={[0, 0, 2]}>
            <Text>Time of Day</Text>
            <Select
              classNamePrefix="region-select"
              options={timeOfDay}
              placeholder=""
              value={selectedTimeOfDay}
              onChange={opt => this.setState({ selectedTimeOfDay: opt })}
              isSearchable={false}
              isMulti
              closeMenuOnSelect={false}
            />
          </Flex>
        </Flex>

        <Text>Any other comments?</Text>
        <TextAreaNoForm
          value={personalNote}
          onChange={v => {
            this.setState({
              personalNote: v.target.value,
            });
          }}
        />

        <Text mt={4}>Enter your email if you want to hear back from us.</Text>
        <Mailcheck email={email}>
          {suggestion => (
            <>
              <Input
                placeholder="Your Email Address"
                type="email"
                name="email"
                id="input_email"
                value={email}
                onChange={v => {
                  this.setState({
                    email: v.target.value,
                  });
                }}
              />
              {suggestion && (
                <MailcheckWrapper className="mailcheck">
                  Did you mean{' '}
                  <MailcheckButton
                    type="button"
                    onClick={() =>
                      this.setState({
                        email: suggestion.full,
                      })
                    }
                  >
                    {suggestion.full}
                  </MailcheckButton>
                  ?
                </MailcheckWrapper>
              )}
            </>
          )}
        </Mailcheck>
        {isNewPatient && (
          <Checkbox
            name="recentLabs"
            isChecked={this.state.hasRecentLabs}
            onClick={() => {
              this.setState({
                hasRecentLabs: !this.state.hasRecentLabs,
              });
            }}
            style={{ marginTop: '24px' }}
            label="I have recent lab results (less than 4 months old)"
          />
        )}
        <Flex
          flexDirection={['column', 'column', 'row']}
          mt={4}
          justifyContent="center"
        >
          <Button variant="dim" onClick={onClose} mb={[2, 2, 0]}>
            Cancel
          </Button>
          <Mutation
            mutation={TIME_DOESNT_WORK}
            variables={{
              weekMoment: selectedMoment
                ? selectedMoment.map(x => x.value)
                : [],
              weekDays: selectedDays ? selectedDays.map(x => x.value) : [],
              momentOfDay: selectedTimeOfDay
                ? selectedTimeOfDay.map(x => x.value)
                : [],
              personalNote: personalNote,
              email: email,
              doctorName: this.props.doctorName,
              state: this.props.departmentId,
              hasRecentLabs: isNewPatient
                ? this.state.hasRecentLabs
                  ? 'I have recent labs'
                  : "I don't have recent labs"
                : null,
            }}
            onCompleted={() => {
              const { toastManager } = this.props;
              toastManager.add('Thank you for your insight.', {
                appearance: 'success',
              });
              onClose();
            }}
          >
            {(mutate, { loading }) => {
              return (
                <Button
                  disabled={
                    loading ||
                    ((!selectedMoment || selectedMoment.length < 1) &&
                      (!selectedDays || selectedDays.length < 1) &&
                      (!selectedTimeOfDay || selectedTimeOfDay.length < 1) &&
                      !personalNote)
                  }
                  variant="primary"
                  ml={[0, 0, 3]}
                  onClick={mutate}
                >
                  Submit
                </Button>
              );
            }}
          </Mutation>
        </Flex>
      </ModalInner>
    );
  }
}

export default withToastManager(TimeDoesntWorkModalInner);
