import React from 'react';
import styled from 'styled-components'

import FunctionContext from './FunctionContext';
import StateContext from './StateContext';

const Btn = styled.button`
background-color: 'rgba(0, 0, 0, .2)';
border-radius: 10;
border-width: 0;
bottom: 5;
cursor: pointer;
height: 20;
outline: 0;
position: absolute;
right: 20;
width: 20;

&:hover {
  background-color: 'rgba(0, 0, 0, .4)'
}

&:active {
  background-color: 'rgba(0, 0, 0, .6)'
}
`

export default ({ children, className }) =>
  <StateContext.Consumer>
    { ({ sticky }) => !sticky &&
      <FunctionContext.Consumer>
        { ({ scrollToEnd }) =>
          <Btn
            onClick={ scrollToEnd }
          >
            { children }
          </Btn>
        }
      </FunctionContext.Consumer>
    }
  </StateContext.Consumer>
