

export const GetAvailables = () => {
  return [
    {
      id: 'Header',
      key: 'Header',
      name: 'Title',
      icon: 'fa fa-header',
      order: 1,
    },
    {
      id: 'Label',
      key: 'Label',
      name: 'Section',
      icon: 'fa fa-font',
      order: 2,
    },
    {
      id: 'Paragraph',
      key: 'Paragraph',
      name: 'Paragraph',
      icon: 'fa fa-paragraph',
      order: 3,
    },
    {
      id: 'LineBreak',
      key: 'LineBreak',
      name: 'Divider',
      icon: 'fa fa-arrows-h',
      order: 4,
    },
    {
      id: 'Dropdown',
      key: 'Dropdown',
      name: 'Dropdown',
      icon: 'fa fa-caret-square-down',
      order: 5,
    },
    // {
    //   id: 'Tags',
    //   key: 'Tags',
    //   name: 'Tags',
    //   icon: 'fa fa-tags',
    //   order: 6,
    // },
    {
      id: 'Checkboxes',
      key: 'Checkboxes',
      name: 'Checkboxes',
      icon: 'fa fa-check-square',
      order: 7,
    },
    // {
    //   id: 'RadioButtons',
    //   key: 'RadioButtons',
    //   name: 'Multiple Choice',
    //   icon: 'fa fa-dot-circle',
    //   order: 8,
    // },
    {
      id: 'TextInput',
      key: 'TextInput',
      name: 'Text Input',
      icon: 'fa fa-font',
      order: 9,
    },
    {
      id: 'NumberInput',
      key: 'NumberInput',
      name: 'Number Input',
      icon: 'fa fa-plus',
      order: 10,
    },
    {
      id: 'TextArea',
      key: 'TextArea',
      name: 'Multi-line Input',
      icon: 'fa fa-text-height',
      order: 11,
    },
    {
      id: 'Rating',
      key: 'Rating',
      name: 'Rating',
      icon: 'fa fa-star',
      order: 12,
    },
    // {
    //   id: 'HyperLink',
    //   key: 'HyperLink',
    //   name: 'Web site',
    //   icon: 'fa fa-link',
    //   order: 13,
    // },
    {
      id: 'Range',
      key: 'Range',
      name: 'Range',
      icon: 'fa fa-sliders',
      order: 14,
    },
    {
      id: 'Email',
      key: 'Email',
      name: 'Email',
      icon: 'fa fa-at',
      order: 15,
    },
    {
      id: 'Date',
      key: 'Date',
      name: 'Date',
      icon: 'fa fa-calendar',
      order: 16,
    },
    // {
    //   id: 'Signature',
    //   key: 'Signature',
    //   name: 'Signature',
    //   icon: 'fa fa-edit',
    //   order: 17,
    // },
  ];
};