import React from 'react';
import { Card, Flex, Heading, Text } from 'rebass';

import doctorIcon from '../../../static/doctorIcon.png';
import reportIcon from '../../../static/reportIcon.png';
import nutritionistDashIcon from '../../../static/nutritionistDashIcon.png';
import ongoingAdvice from '../../../static/ongoingAdvice.png';
import medicationManagement from '../../../static/medicationManagement.png';
import symptomTracker from '../../../static/symptomTracker.png';

const Item = ({ imageSrc, title, text }) => (
  <Card style={{
    margin: '12px',
    padding: '24px 12px',
  }}>
    <Flex flexDirection="row" alignItems='flex-start'>
      <img src={imageSrc} alt='' width="40" />

      <Flex flexDirection="column" ml="16px">
        <Text fontWeight="600" fontSize="20px">
          {title}
        </Text>
        <Text fontWeight="400" mt={3}>{text}</Text>
      </Flex>
    </Flex>
  </Card>
);

export const ThinkCare = () => {
  return (
    <Flex flexDirection="column">
      <Heading fontSize='32px' textAlign='center' style={{ maxWidth: '80%', margin: '76px auto 40px auto' }}>
        You Will Never have to Think About Your Thyroid Care Again
      </Heading>
      <Flex flexDirection={['column',"row"]}>
        <Item
          imageSrc={doctorIcon}
          title="On-Demand Care Team"
          text="Your Care Team is always available to answer questions and give recommendations."
        />
        <Item
          imageSrc={ongoingAdvice}
          title="Unlimited Clinician Messaging"
          text="Stay on track with your health plan with unlimited direct messages with your provider between visits."
        />
      </Flex>
      <Flex flexDirection={['column',"row"]}>
        <Item
          imageSrc={reportIcon}
          title="Customized Health Plan"
          text="Achieve your specific health goals with a customized health plan."
        />
        <Item
          imageSrc={medicationManagement}
          title="Membership Perks"
          text="Get free access to our private member community, meetups, mobile app, education, and more."
        />
      </Flex>
      <Flex flexDirection={['column',"row"]}>
        <Item
          imageSrc={nutritionistDashIcon}
          title="Priority Scheduling"
          text="Priority on our waitlist, reminders from your care mangers,... Your thyroid will never have to wait again "
        />
        <Item
          imageSrc={symptomTracker}
          title="Discounted Products & Consults"
          text="Members receive discounts on all products and services sitewide."
        />
      </Flex>
    </Flex>
  );
};
