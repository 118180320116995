import React, { useState } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import { PricingAsYouGo } from './components/PricingAsYouGo';
import { PricingSubscribe } from './components/PricingSubscribe';
import { isMobile } from 'react-device-detect';

const Radio = ({ text, onClick, isChecked = false }) => {
  return (
    <Flex style={{ cursor: 'pointer' }} mb={3} mr={[4, 0]} onClick={onClick} alignItems="center">
      <Box style={{ width: '14px', height: '14px', borderRadius: '50%', padding: '3px', border: '1px solid #344F7A' }}>
        {isChecked && <Box style={{ width: '100%', height: '100%', borderRadius: '50%', backgroundColor: '#344F7A' }} />}
      </Box>
      <Text ml={3} fontWeight={300}>
        {text}
      </Text>
    </Flex>
  );
};

const Selector = ({ selected, onSelectionChanged, subscribe }) => {
  return (
    <Flex flexDirection={['column', 'column']}>
      <Heading fontSize="28px" textAlign={['center', 'left']} mb={3}>
        Get Discounts and Perks
      </Heading>
      <Flex flexDirection={['row', 'column']} alignItems={['baseline', 'initial']}>
        <Radio
          isChecked={selected === 'member'}
          text="Member"
          onClick={() => {
            onSelectionChanged('member');
          }}
        />
        <Radio
          isChecked={selected !== 'member'}
          text="Non Member"
          onClick={() => {
            onSelectionChanged('nonMember');
          }}
        />
        {!isMobile && (
          <Button variant={'primary'} onClick={subscribe}>
            Join Now
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export const Pricings = ({ subscribe }) => {
  const [selected, setSelected] = useState('member');

  return (
    <Flex flexDirection="column" mt="76px">
      <Flex flexDirection={['column', 'row']}>
        <Flex flex={[1, 1 / 4]} mr={[0, 3]} paddingLeft={['14px', 0]} paddingRight={['14px', 0]} justifyContent={['center', 'initial']}>
          <Selector selected={selected} onSelectionChanged={setSelected} subscribe={subscribe} />
        </Flex>

        {selected === 'member' ? <PricingSubscribe subscribe={subscribe} /> : <PricingAsYouGo subscribe={subscribe} />}
      </Flex>
    </Flex>
  );
};
