import React, { useState, Fragment } from 'react';
import { withWizard } from 'react-albus';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';

const Btn = styled(Button)`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 300;

  transition: background 300ms ease-in;
  &.selected {
    background: #e7f0ff;
    border: 1px solid #354e79;
    box-sizing: border-box;
  }
`;

const SelectedMarkerOut = styled(Flex)`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 2px solid #354e79;
  border-radius: 12px;
  margin-right: 12px;
`;

const SelectedMarkerIn = styled(Flex)`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #354e79;
  transition: all 450ms ease-in;
`;

const BirthSex = ({ values, onSubmit, wizard }) => {
  const [selected, setSelected] = useState((values || {}).assignedsexatbirth);
  return (
    <Flex flex={1} flexDirection="column" style={{maxWidth: '420px', margin: '0 auto'}}>
      <Box>
        <Heading textAlign="center">What was your sex assigned at birth?</Heading>
      </Box>
      <Text textAlign="center" fontWeight={300} fontSize={2} color="#747373" mt={2}>
        Biological sex is important for making medical decisions.
      </Text>
      <Flex my={3} flexWrap="wrap" justifyContent="center">
        {[{ label: 'Female', value: 'F' }, { label: 'Male', value: 'M' }].map((el) => (
          <Fragment key={el.value}>
            <Btn
              type="button"
              variant={'white'}
              width={[1]}
              m={3}
              onClick={() => {
                if (el.value !== selected) {
                  setSelected(el.value);
                }
              }}
              className={selected === el.value ? 'selected' : 'white'}
            >
              <Flex flex={1} flexDirection="row">
                <SelectedMarkerOut>{el.value === selected && <SelectedMarkerIn />}</SelectedMarkerOut>
                <Flex flex={1} flexDirection="column" style={{ textAlign: 'left' }}>
                  <Text textAlign="left" mt="4px">
                    {el.label}
                  </Text>
                </Flex>
              </Flex>
            </Btn>
          </Fragment>
        ))}
      </Flex>

      <Flex justifyContent="center">
        <Button
          mt={3}
          disabled={!selected}
          variant="primary"
          width={[1 / 2, 1 / 3]}
          type="submit"
          onClick={() => {
            onSubmit(
              {
                ...values,
                assignedsexatbirth: selected,
              },
              null,
              wizard
            );
          }}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
};

export default withWizard(BirthSex);
