import React from 'react';
import { Flex, Text, Box } from 'rebass';
import { GraphPink } from '../icons/GraphPink';
import { InnerRightHormone } from '../icons/InnerRightHormone';
import styled, { keyframes } from 'styled-components';
const slideLeft = keyframes`
  0% {
    /* opacity: 0; */
    transform: translateX(-350px);
  };
  100% {
    /* opacity: 1; */
    transform: translateX(0);
  };
`;

const Opaciter = keyframes`
  0% {
    opacity: 0;
    /* transform: translateX(-350px); */
  };
  100% {
    opacity: 1;
    /* transform: translateX(0); */
  };
`;

const Bar = styled(Flex)`
  padding: 8px 16px;
  border-radius: 0 80px 80px 0;
  background-color: #344f7a;
  animation: 1s 1 forwards ease-in ${slideLeft};
  animation-delay: ${(props) => props.delay};
  transform: translateX(-350px);
  justify-content: flex-start;
`;

const Item = styled(Text)`
  opacity: 0;
  animation: 1s 1 forwards ease-in ${Opaciter};
  animation-delay: ${(props) => props.delay};
  margin-left: 12px;
  font-size: 22px;
`;

export const AipProgressBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <InnerRightHormone
        style={{
          position: 'absolute',
          right: 0,
          bottom: 120,
        }}
      />
      <GraphPink width={700} style={{ position: 'absolute', bottom: 0, left: 0 }} stroke="#C7DDFC" />
    </Flex>
  );
};

export const AipProgress = ({ progress = [] }) => {
  return (
    <Flex style={{ height: '100%', flexDirection: 'column', zIndex: 100, maxHeight: 'calc(100% - 70px)', overflowY: 'auto', marginLeft: '-18px', marginRight: '-18px'  }}>
      <Text style={{ fontSize: window.matchMedia('(min-width: 52em)').matches?36:26, fontWeight: 700, textAlign: 'center', marginBottom: '16px' }}>Your progress with AIP</Text>
      <Flex style={{flexDirection:'column'}}>
      {progress.map((x, i) => {
        const pc = (250 * (x/100))
        return (
          <Flex key={`topprogress${i}`} style={{ width: '100%', overflow: 'hidden', marginTop: '16px', alignItems: 'center' }}>
            {/* <Bar style={{ width: `${pc}px` }} delay={`${(i + 1) * 100}ms`}> */}
            <Bar style={{ width: `calc(100px + ${pc}px)` }} delay={`${(i + 1) * 100}ms`}>
              <Text style={{ color: 'white' }}>{`Week ${i + 1}`}</Text>
            </Bar>
            <Item delay={`${700 + (i + 1) * 100}ms`} style={{ color: '#F8A294', fontFamily: 'Playfair Display', fontWeight: 700, fontSize: 26, marginTop: '-8px' }}>{x}%</Item>
          </Flex>
        );
      })}
      </Flex>
      {/* <Flex style={{ width: 'calc(100% + 18px)', marginLeft: '-18px', overflow: 'hidden' }}>
        <Bar style={{ width: '350px' }}>
          <Text style={{ color: '#F8A294', fontFamily: 'Playfair Display', fontSize: 50, marginTop: '-14px' }}>#1</Text>
          <Item delay={``}>Hashi</Item>
        </Bar>
      </Flex>
      <Flex style={{ width: 'calc(100% + 18px)', marginLeft: '-18px', overflow: 'hidden', marginTop: '20px' }}>
        <Bar style={{ width: '250px' }}>
          <Text style={{ color: '#F8A294', fontFamily: 'Playfair Display', fontSize: 50, marginTop: '-14px' }}>#2</Text>
        </Bar>
      </Flex>
      <Flex style={{ width: 'calc(100% + 18px)', marginLeft: '-18px', overflow: 'hidden', marginTop: '20px' }}>
        <Bar style={{ width: '150px' }}>
          <Text style={{ color: '#F8A294', fontFamily: 'Playfair Display', fontSize: 50, marginTop: '-14px' }}>#3</Text>
        </Bar>
      </Flex> */}
    </Flex>
  );
};
