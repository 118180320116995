import React, { Fragment, useRef, useState } from 'react';
import { Flex, Box, Button, Heading } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { Icon } from '@iconify/react';
import magnifier from '@iconify/icons-entypo/magnifying-glass';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { MobileModal } from './components/medicationLists/MobileModal';
import { MedicationDetailsContent } from './components/medicationLists/MedicationDetailsContent';
import { SelectedMedicationCard } from './components/medicationLists/SelectedMedicationCard';
import { BoxButton } from '../../../components/async/SharedComponents';
import { isMobile } from 'react-device-detect';
import ConfirmModal from '../../../components/ConfirmModal';
import { useMount } from '../../../utils/hooks/lifecycle';
import MedicationSelector from '../../../components/medicationSelector/MedicationSelector';
import { SEND_KLAVIYO_STATUS } from '../../../graphql';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <span>
        <Icon
          icon={magnifier}
          style={{
            color: 'rgb(53, 78, 121)',
            fontSize: '24px',
            alignSelf: 'center',
            marginLeft: '6px',
            opacity: 0.7,
          }}
        />
      </span>
      {children}
    </components.Control>
  );
};

const MedicationList = ({
  onSubmit,
  data,
  getState,
  client
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedForEdit, setSelectedForEdit] = useState();
  const [inputValue, setInputValue] = useState('');
  const [val, setVal] = useState(null);
  const asyncSelect = useRef();
  useMount(async() => {
    if (data && data.klaviyoEvent) {
      try {
        await client.mutate({
          mutation: SEND_KLAVIYO_STATUS,
          variables: {
            step: data.klaviyoEvent,
          },
        });
      } catch (error) {}
    }
    const state = getState();
    if (state && state.values) {
      const meds = state.values.find(x => x.id === data.id);
      setSelectedOptions((meds || {}).values || []);
    }
  });

  const handleInputChange = newValue => {
    const inputValue = newValue; //.replace(/\W/g, '');
    setInputValue(inputValue);
    return inputValue;
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      height: '64px',
      borderRadius: '10px',
      border: `${state.focused ? '3' : '1'}px solid #44a5ff`,
    }),
  };

  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
        {data.title ? (
          <Box m={2} mt="0" mb={4}>
            <Heading textAlign="left">{data.title}</Heading>
          </Box>
        ) : null}
        {data.useUsaRx ? (
          <MedicationSelector
            onChange={option => {
              if (!option || !option.value) return;
              option.questionId = option.value.toString();
              setSelectedOptions(previous => [...previous, option]);
              setSelectedForEdit(option);
            }}
          />
        ) : (
          <Select
            ref={asyncSelect}
            styles={customStyles}
            cacheOptions
            isClearable
            options={data.options.map(x => {
              return {
                label: x.value,
                value: x.value,
                questionId: x.questionId,
                goToPage: x.goToPage,
              };
            })}
            components={{ Control }}
            value={val}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            noOptionsMessage={() =>
              inputValue
                ? inputValue.length > 2
                  ? 'No Medication Found'
                  : 'Minimum 2 characters to search'
                : 'Minimum 2 characters to search'
            }
            onChange={option => {
              if (!option || !option.value) return;
              setSelectedOptions(previous => [...previous, option]);
              setSelectedForEdit(option);
            }}
          />
        )}
        <TransitionGroup className="items-section__list">
          {selectedOptions.map(x => {
            return (
              <CSSTransition timeout={500} classNames="moveList">
                <SelectedMedicationCard
                  isInvalid={!x || !x.dose || !x.frequency}
                  key={x.value}
                  name={x.label}
                  dose={x.dose}
                  freq={x.frequency}
                  onClick={() => setSelectedForEdit(x)}
                />
              </CSSTransition>
            );
          })}
        </TransitionGroup>

        {!selectedOptions || selectedOptions.length < 1 ? (
          <Button
            my={4}
            variant="white"
            style={{
              background: '#FFF2F2',
              border: '2px solid #F6A293',
              borderRadius: '20px',
            }}
            onClick={() => onSubmit([])}
          >
            {data.nothingText}
          </Button>
        ) : null}

        <BoxButton
          className={
            selectedOptions && selectedOptions.length > 0 && !selectedForEdit
              ? 'visible'
              : ''
          }
        >
          <Button
            disabled={
              selectedOptions.filter(x => !x.dose || !x.frequency).length > 0
            }
            variant="primary"
            style={{
              marginLeft: '12px',
              marginRight: '12px',
            }}
            width={[1, 1 / 2, 1 / 4]}
            type="button"
            onClick={() => {
              onSubmit(selectedOptions);
            }}
          >
            Continue
          </Button>
        </BoxButton>
      </Flex>
      {selectedForEdit ? (
        isMobile ? (
          <MobileModal
            title={selectedForEdit.label}
            onClose={() => setSelectedForEdit(null)}
          >
            <MedicationDetailsContent
              dosePlaceholder={data.dosePlaceholder}
              dose={selectedForEdit.dose}
              frequency={selectedForEdit.frequency}
              onSave={values => {
                const opts = [];
                for (let index = 0; index < selectedOptions.length; index++) {
                  const element = JSON.parse(
                    JSON.stringify(selectedOptions[index])
                  );
                  if (element.value === selectedForEdit.value) {
                    element.dose = values.dose;
                    element.frequency = values.frequency;
                  }
                  opts.push(element);
                }
                setSelectedOptions(opts);

                setSelectedForEdit(null);
              }}
              onDelete={() => {
                setSelectedOptions(
                  selectedOptions.filter(x => x.value !== selectedForEdit.value)
                );
                setSelectedForEdit(null);
              }}
            />
          </MobileModal>
        ) : (
          <ConfirmModal
            isOpen={true}
            onClose={() => {
              setSelectedForEdit(null);
            }}
            onBackgroundClick={() => setSelectedForEdit(null)}
            onEscapeKeydown={() => setSelectedForEdit(null)}
            onConfirm={() => {
              this.confirmModalForm();
            }}
            confirmLabel="Save"
            cancelLabel="Remove"
            confirmStyle={{
              width: '100%',
            }}
            cancelStyle={{
              width: '100%',
              marginTop: '18px',
            }}
            cancelVariant="outline"
            buttonsDirection="column"
            displayX={true}
            childrenManaged={true}
            cardStyle={{
              width: '500px',
              maxWidth: 'calc(100vw - 40px)',
            }}
          >
            <ModalInner>
              <MedicationDetailsContent
                dosePlaceholder={data.dosePlaceholder}
                dose={selectedForEdit.dose}
                frequency={selectedForEdit.frequency}
                onSave={values => {
                  const opts = [];
                  for (let index = 0; index < selectedOptions.length; index++) {
                    const element = JSON.parse(
                      JSON.stringify(selectedOptions[index])
                    );
                    if (element.value === selectedForEdit.value) {
                      element.dose = values.dose;
                      element.frequency = values.frequency;
                    }
                    // console.log({ element });
                    opts.push(element);
                  }
                  setSelectedOptions(opts);

                  setSelectedForEdit(null);
                }}
                onDelete={() => {
                  setSelectedOptions(
                    selectedOptions.filter(
                      x => x.value !== selectedForEdit.value
                    )
                  );
                  setSelectedForEdit(null);
                }}
              />
            </ModalInner>
          </ConfirmModal>
        )
      ) : null}
    </Fragment>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(MedicationList);
