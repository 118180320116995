import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import check from '@iconify/icons-entypo/check';
import { Icon } from '@iconify/react';
import { Box, Flex, Text } from 'rebass';
import SpinLoader from './SpinLoader';

const blink = keyframes`
  50% {color: transparent;}
`;

export const Dot = styled.span`
  animation: 1s ${blink} infinite;
  &:nth-child(1) {
    animation-delay: 0ms;
  }
  &:nth-child(2) {
    animation-delay: 250ms;
  }
  &:nth-child(3) {
    animation-delay: 500ms;
  }
`;

const CompleteCheckMark = styled.div`
  display: flex;
  border-radius: 50%;
  background-color: #354e79;
  height: ${(props) => (props.height ? `${props.height}px` : '68px')};
  width: ${(props) => (props.width ? `${props.width}px` : '68px')};
  justify-content: center;
  align-items: center;
`;

export const PleaseWait = ({ text, ...props }) => {
  return (
    <Flex flexDirection="column" alignItems="center" style={props.style}>
      <SpinLoader {...props.spinner} />
      {text && text !== ' ' ? (
        <Text mt="24px" textAlign="center" style={{...props.textStyle}}>
          {text}
          <Dot>.</Dot>
          <Dot>.</Dot>
          <Dot>.</Dot>
        </Text>
      ) : null}
      {props.children}
    </Flex>
  );
};

PleaseWait.propTypes = {
  text: PropTypes.string,
};

PleaseWait.defaultProps = {
  text: 'Please Wait',
};

export const SubscriptionRegistrationComplete = ({ text, hideText, title, ...props }) => {
  return (
    <Flex flexDirection="column" alignItems="center" style={props.style}>
      <CompleteCheckMark>
        <Icon
          icon={check}
          style={{
            color: 'white',
            fontSize: '32px',
            alignSelf: 'center',
          }}
        />
      </CompleteCheckMark>
      <Text mt="36px" style={{ fontWeight: 600, fontSize: '24px' }} textAlign="center">
        {title || 'Welcome to your Paloma Health membership'}
      </Text>
      {!hideText ? (
        <Text mt="24px" mb="36px" textAlign="center">
          {text || 'Press Continue to finalize your visit booking'}
        </Text>
      ):<Box mb='36px'/>}
      {props.children}
    </Flex>
  );
};

PleaseWait.propTypes = {
  text: PropTypes.string,
};

PleaseWait.defaultProps = {
  text: 'Please Wait',
};

export default PleaseWait;
