import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import Select from 'react-select';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import CheckboxInput from '../../../components/CheckboxInput';
import ConfirmModal from '../../../components/ConfirmModal';
import FormField from '../../../components/FormField';
import TextInput from '../../../components/TextInput';
import { ADMIN_GET_WEBFLOW_ARTICLES, ADMIN_GET_WORKOUTS, ADMIN_UPSERT_WORKOUT } from '../../../graphql';
import PickRelatedArticles from './PickRelatedArticles';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
  max-height: 800px;
  overflow: auto;
`;
const AddWorkout = ({ onSave, onClose, workout, workoutLength }) => {
  const [isAddArticles, setIsAddArticles] = useState(false);
  const [relatedArticles, setRelatedArticles] = useState((workout || {}).relatedArticles || []);
  if (!workout) return null;
  const courseIcons = [
    {
      value: 'Plant',
      label: 'Plant',
    },
    { value: '2pills', label: '2pills' },
    { value: 'Avocado', label: 'Avocado' },
    { value: 'Chat_bubble', label: 'Chat_bubble' },
    { value: 'Female_doctor', label: 'Female_doctor' },
    { value: 'Fingerprick', label: 'Fingerprick' },
    { value: 'Icon_cloud-1', label: 'Icon_cloud-1' },
    { value: 'Icon_cloud', label: 'Icon_cloud' },
    { value: 'Icon_moon', label: 'Icon_moon' },
    { value: 'Male_doctor', label: 'Male_doctor' },
    { value: 'Medication_tube_single', label: 'Medication_tube_single' },
    { value: 'Multiple_medications', label: 'Multiple_medications' },
    { value: 'Paper_Airplane', label: 'Paper_Airplane' },
    { value: 'Plate', label: 'Plate' },
    { value: 'Telemedicine_consult', label: 'Telemedicine_consult' },
    { value: 'computer_chart', label: 'computer_chart' },
    { value: 'doc_with_labs', label: 'doc_with_labs' },
    { value: 'files', label: 'files' },
    { value: 'handshake', label: 'handshake' },
    { value: 'icon_card', label: 'icon_card' },
    { value: 'icon_drop', label: 'icon_drop' },
    { value: 'icon_kit', label: 'icon_kit' },
    { value: 'icon_medicine', label: 'icon_medicine' },
    { value: 'icon_plate', label: 'icon_plate' },
    { value: 'icon_scale', label: 'icon_scale' },
    { value: 'icon_tablets', label: 'icon_tablets' },
    { value: 'kit_3d', label: 'kit_3d' },
    { value: 'report', label: 'report' },
    { value: 'thyroid', label: 'thyroid' },
    { value: 'Yoga', label: 'Yoga' },
    { value: 'Sleep', label: 'Sleep' },
    { value: 'Workouts', label: 'Workouts' },
    { value: 'Breathing', label: 'Breathing' },
  ];
  return (
    <Query query={ADMIN_GET_WEBFLOW_ARTICLES}>
      {({ data: dataArticles, loading: loadingArticles }) => {
        if (loadingArticles) return null;
        const selectedArticles = dataArticles.adminGetArticlesList.filter((x) => (workout.relatedArticles || []).filter((y) => decodeURIComponent(y) === decodeURIComponent(x.title)).length > 0);
        return (
          <Mutation
            mutation={ADMIN_UPSERT_WORKOUT}
            refetchQueries={[{ query: ADMIN_GET_WORKOUTS }]}
            awaitRefetchQueries={true}
            onCompleted={() => {
              onSave();
            }}
          >
            {(upsertCategory, { loading }) => (
              <Formik
                isInitialValid={
                  ({ initialValues }) => true
                  //   schema.isValidSync(initialValues)
                }
                initialValues={{
                  ...workout,
                  icon: courseIcons.find((x) => x.value === workout.icon),
                }}
                // validationSchema={schema}
                onSubmit={async (values) => {
                  let vars = {
                    id: workout.id,
                    name: values.name,
                    description: values.description,
                    icon: values.icon.value,
                    bgMainColor: values.bgMainColor,
                    bgBubblesColor: values.bgBubblesColor,
                    titleColor: values.titleColor,
                    descriptionColor: values.descriptionColor,
                    order: workout.id ? workout.order : workoutLength,
                    isActive: values.isActive,
                    memberOnly: values.memberOnly,
                    workoutBgImgLink: values.workoutBgImgLink,
                    relatedArticles: relatedArticles.filter(x=>!!x.title).map(x=>x.title),
                  };

                  await upsertCategory({
                    variables: {
                      ...vars,
                    },
                  });
                }}
                render={({ touched, errors, setFieldValue, setFieldTouched, values }) => (
                  <Form>
                    <Flex flexDirection="column" width="400px" style={{ maxHeight: '900px' }}>
                      <FormField mb={4} error={touched.name && errors.name}>
                        <label for="name">Name *</label>
                        <TextInput name="name" placeholder="Name" />
                      </FormField>
                      <FormField mb={4} error={touched.description && errors.description}>
                        <label for="description">Description</label>
                        <TextInput name="description" placeholder="Description" />
                      </FormField>

                      <FormField mb={4}>
                        <label for="icon">Icon *</label>
                        <Select
                          classNamePrefix="region-select"
                          options={courseIcons}
                          name="icon"
                          value={values.icon}
                          placeholder="select icon"
                          onChange={(option) => {
                            setFieldValue('icon', option);
                          }}
                          onBlur={() => setFieldTouched('icon', true)}
                          isSearchable={true}
                        />
                      </FormField>
                      <FormField mb={4} error={touched.bgMainColor && errors.bgMainColor}>
                        <label for="bgMainColor">Screen Background Color</label>
                        <TextInput name="bgMainColor" placeholder="Screen Background Color" />
                      </FormField>

                      <FormField mb={4} error={touched.bgBubblesColor && errors.bgBubblesColor}>
                        <label for="bgBubblesColor">Hormones Background Color</label>
                        <TextInput name="bgBubblesColor" placeholder="Hormones Background Color" />
                      </FormField>

                      <FormField mb={4} error={touched.titleColor && errors.titleColor}>
                        <label for="titleColor">Title Color</label>
                        <TextInput name="titleColor" placeholder="Title Color" />
                      </FormField>

                      <FormField mb={4} error={touched.titleColor && errors.titleColor}>
                        <label for="descriptionColor">Description Color</label>
                        <TextInput name="descriptionColor" placeholder="Description Color" />
                      </FormField>

                      <FormField mb={4} error={touched.workoutBgImgLink && errors.workoutBgImgLink}>
                        <label for="workoutBgImgLink">Background Image *</label>
                        <TextInput name="workoutBgImgLink" placeholder="Background Image" />
                      </FormField>
                      <Flex>
                        <Text>Related Articles:</Text>
                        <Button type="button" variant={'primary'} onClick={()=>setIsAddArticles(true)}>
                          Edit
                        </Button>
                      </Flex>
                      <Box p={3}>
                        {relatedArticles.map((x) => {
                          return <Text key={x.id}>{x.title}</Text>;
                        })}
                      </Box>
                      <FormField mb={4} error={touched.isActive && errors.isActive}>
                        <label for="isActive">Is Active</label>
                        <CheckboxInput
                          name="isActive"
                          styles={{
                            checked: {
                              Box: {
                                backgroundColor: 'rgb(54, 79, 121)',
                              },
                            },
                          }}
                        />
                      </FormField>
                      <Flex mt={4} flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Button
                          style={{
                            background: 'white',
                            color: '#344f79',
                            border: '1px solid #344f79',
                          }}
                          type="button"
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                        <Button disabled={!!loading} style={{ background: '#344f79' }}>
                          {workout.id ? 'Update' : 'Save'}
                        </Button>
                      </Flex>
                    </Flex>
                    {isAddArticles && (
                      <ConfirmModal
                        childrenManaged
                        isOpen={!!isAddArticles}
                        confirmLabel="create"
                        onClose={() => {
                          setIsAddArticles(false);
                        }}
                        onBackgroundClick={() => {
                          setIsAddArticles(false);
                        }}
                        onEscapeKeydown={() => {
                          setIsAddArticles(false);
                        }}
                        onConfirm={() => {
                          setIsAddArticles(false);
                        }}
                      >
                        <ModalInner>
                          <Heading fontSize="20px" mb={3}>
                            {'Edit Related Articles'}
                          </Heading>
                          <PickRelatedArticles
                            workout={isAddArticles}
                            articlesList={dataArticles.adminGetArticlesList}
                            relatedArticles={relatedArticles}
                            onClose={() => {
                              setIsAddArticles(false);
                            }}
                            onSave={(lst) => {
                              setRelatedArticles(lst);
                              setIsAddArticles(false);
                            }}
                          />
                        </ModalInner>
                      </ConfirmModal>
                    )}
                  </Form>
                )}
              />
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default AddWorkout;
