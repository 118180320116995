import React from 'react';
import { Mutation } from 'react-apollo';
import { withRouter } from 'react-router';
import moment from 'moment';

import { Text, Flex, Button } from 'rebass';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Icon } from '@iconify/react';
import visaIcon from '@iconify/icons-logos/visa';
import mcIcon from '@iconify/icons-logos/mastercard';
import amexIcon from '@iconify/icons-logos/amex';
import discoverIcon from '@iconify/icons-logos/discover';
import creditCard from '@iconify/icons-entypo/credit-card';

import {
  DONT_CANCEL_MEMBERSHIP,
  GET_MEMBERSHIPS,
  MEMBERSHIP_UPDATE_PAYMENT_CREATE_SESSION,
  RESUME_SUBSCRIPTION,
} from '../../../graphql';
import { isMobile } from 'react-device-detect';

const ContentWrapper = styled(Flex)`
  flex: 1;
  flex-direction: column;
  background-color: white;
  margin: 24px 12px;
`;

const CardWrapperRow = styled(Flex)`
  flex: 1;
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const CardWrapperText = styled(Flex)`
  flex: 1;
  flex-direction: column;
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 24px;
`;

const PriceText = styled(Text)`
  font-weight: 600;
  color: #f6a293;
`;

const ActionButton = styled(Button)`
  height: 40px;
  padding: 0 24px;
  width: auto;
`;

const ActionsContainer = styled(Flex)`
  margin-top: 24px;
  margin-right: 16px;
`;

const A = styled.a`
  display: flex;
  text-decoration: none;
  color: #f6a293;

  &:visited {
    color: #f6a293;
  }
`;

const TableRow = styled(Flex)`
  flex: 1;
  flex-direction: row;
  max-width: 700px;
  background: ${props => (props.odd ? 'transparent' : '#F4F4F4')};
`;

const TableHeaderCell = styled(Text)`
  flex: 0.25;
  font-weight: 400;
`;

const TableHeaderCellRest = styled(Text)`
  flex: 1;
  font-weight: 400;
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const TableCell = styled(Text)`
  flex: 0.25;
  font-weight: 400;
`;

const TableCellRest = styled(Text)`
  flex: 1;
  font-weight: 400;
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}

const SectionDetails = ({ data, type, history }) => {
  if (!data) return <div />;
  let memberPricing = `Your next bill is for $${(data.nextBill.amount || 0) /
    100} on ${getFormattedDate(new Date(data.nextBill.date * 1000))}`;
  if (data) {
    if (data.hasCancellationCoupon) {
      memberPricing = `A ${
        data.hasCancellationCoupon.discountPercent
      }% discount will be applied to your next bill on ${getFormattedDate(
        new Date(data.nextBill.date * 1000)
      )}`;
    }
  }

  let priceText = memberPricing;

  if (data.pausedSubscription && data.pausedSubscription.resumesAt) {
    priceText = `Your subscription is paused until ${moment
      .unix(data.pausedSubscription.resumesAt)
      .format('MMMM Do YYYY')}`;
  }

  if (data.cancels && data.cancels.cancelsAt) {
    priceText = `Your ${
      type === 'membership' ? 'membership' : 'subscription'
    } will remain active until ${moment
      .unix(data.cancels.cancelsAt)
      .format('MMMM Do YYYY')}`;
  }

  let logo = creditCard;
  if (data && data.card) {
    switch ((data.card.brand || '').toUpperCase()) {
      case 'VISA':
        logo = visaIcon;
        break;
      case 'MC':
      case 'MASTERCARD':
      case 'MASTER CARD':
        logo = mcIcon;
        break;
      case 'AX':
      case 'AMEX':
      case 'AMERICANEXPRESS':
      case 'AMERICAN EXPRESS':
        logo = amexIcon;
        break;
      case 'DISCO':
      case 'DISCOVER':
        logo = discoverIcon;
        break;

      default:
        logo = creditCard;
        break;
    }
  }

  return (
    <ContentWrapper>
      <Title>Payment</Title>
      <Text>{priceText}</Text>

      <CardWrapperRow>
        {data &&
          data.card &&
          data.card.last4 && (
            <Icon icon={logo} style={{ marginRight: '8px' }} />
          )}
        <CardWrapperText>
          <Text>{`${data.card.brand} ending in ${data.card.last4}`}</Text>
          <Mutation
            mutation={MEMBERSHIP_UPDATE_PAYMENT_CREATE_SESSION}
            variables={{
              subscriptionId: data.id,
              customerId: data.customer,
              forwardUrl: 'subscription/update/success',
              forwardUrlCancel: 'my-subscriptions',
            }}
            onCompleted={async res => {
              const stripePromise = loadStripe(
                window._env_.REACT_APP_STRIPE_KEY
              );
              const stripe = await stripePromise;
              await stripe.redirectToCheckout({
                sessionId: res.createUpdatePaymentMethodSession.sessionId,
              });
            }}
          >
            {(createSession, { loading }) => (
              <PriceText
                onClick={() => {
                  if (loading) return;
                  createSession();
                }}
              >
                Update
              </PriceText>
            )}
          </Mutation>
        </CardWrapperText>
      </CardWrapperRow>

      <Title>Receipts</Title>

      <TableRow odd={true}>
        <TableHeaderCell
          style={{
            width: isMobile ? '90px' : '',
            minWidth: isMobile ? '90px' : '',
          }}
        >
          Date
        </TableHeaderCell>
        <TableHeaderCellRest>Reference</TableHeaderCellRest>
        <TableHeaderCell>Price</TableHeaderCell>
        <TableHeaderCell />
      </TableRow>
      {data.receipts.map((r, i) => {
        return (
          <TableRow key={r.id} odd={i % 2 !== 0}>
            <TableCell
              style={{
                width: isMobile ? '90px' : '',
                minWidth: isMobile ? '90px' : '',
              }}
            >
              {getFormattedDate(new Date(r.date * 1000))}
            </TableCell>
            <TableCellRest>{r.reference}</TableCellRest>
            <TableCell>${r.amount / 100}</TableCell>
            <TableCell>
              <A href={r.link} target="_blank">
                view
              </A>
            </TableCell>
          </TableRow>
        );
      })}
      {data.cancels &&
        data.cancels.cancelsAt && (
          <ActionsContainer>
            <Mutation
              mutation={DONT_CANCEL_MEMBERSHIP}
              variables={{
                id: data.id,
              }}
              refetchQueries={[{query: GET_MEMBERSHIPS}]}
            >
              {(dontCancelSubscription, { loading }) => (
                <ActionButton
                  variant="outline"
                  onClick={() => dontCancelSubscription()}
                >
                  Don't Cancel Membership
                </ActionButton>
              )}
            </Mutation>
          </ActionsContainer>
          //dontCancelSubscription
        )}
      {(!data.cancels || !data.cancels.cancelsAt) && (
        <>
          {type === 'membership' ? (
            <ActionsContainer>
              <ActionButton
                variant="outline"
                onClick={() =>
                  history.push({
                    pathname: '/cancel-membership',
                    state: {
                      id: data.id,
                      interval: data.interval,
                    },
                  })
                }
              >
                Cancel Membership
              </ActionButton>
            </ActionsContainer>
          ) : (
            <Flex>
              {data.pausedSubscription && data.pausedSubscription.resumesAt ? (
                <ActionsContainer>
                  <Mutation
                    mutation={RESUME_SUBSCRIPTION}
                    refetchQueries={[{ query: GET_MEMBERSHIPS }]}
                    variables={{
                      subscriptionId: data.id,
                    }}
                  >
                    {(resumeSubscription, { loading }) => (
                      <ActionButton
                        variant="outline"
                        onClick={() => resumeSubscription()}
                      >
                        Resume Subscription
                      </ActionButton>
                    )}
                  </Mutation>
                </ActionsContainer>
              ) : (
                <ActionsContainer>
                  <ActionButton
                    variant="outline"
                    onClick={() =>
                      history.push({
                        pathname: '/pause-subscription',
                        state: {
                          id: data.id,
                          type: type,
                        },
                      })
                    }
                  >
                    Pause Subscription
                  </ActionButton>
                </ActionsContainer>
              )}
              <ActionsContainer>
                <ActionButton
                  variant="outline"
                  onClick={() =>
                    history.push({
                      pathname: '/cancel-subscription',
                      state: {
                        id: data.id,
                        type: type,
                        canPause:
                          !data.pausedSubscription &&
                          !data.pausedSubscription.resumesAt,
                      },
                    })
                  }
                >
                  Cancel Subscription
                </ActionButton>
              </ActionsContainer>
            </Flex>
          )}
        </>
      )}
    </ContentWrapper>
  );
};

export default withRouter(SectionDetails);
