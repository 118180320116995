import React from 'react';
import { Flex, Box } from 'rebass';
import { Query } from 'react-apollo';

class MobileCourseDesigner extends React.Component {
  render() {
    return (
      <Query query="">
        {(loading, data) => {
          if (loading) return null;
          return (
            <Flex flexDirection="row" style={{ height: '100%', width: '100%' }}>
              <Box
                style={{
                  backgroundColor: 'white',
                  width: '240px',
                  boxShadow: '5px 0 5px -5px rgba(0,0,0,0.5)',
                  zIndex: 900,
                }}
              ></Box>
              <Box flex={3} style={{ backgroundColor: 'rgba(0,0,0,0.05)' }}>
                <Flex flexDirection="row"></Flex>
              </Box>
              <Box flex={1} style={{ backgroundColor: 'green' }}></Box>
            </Flex>
          );
        }}
      </Query>
    );
  }
}

export default MobileCourseDesigner;
