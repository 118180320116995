import React, { Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Flex, Text, Card, Heading, Button } from 'rebass';
import { withWizard } from 'react-albus';
import { InsuranceListSelector } from './components/pickInsurance/insuranceListSelector';
import { Query, withApollo } from 'react-apollo';
import { RegionSelectNotForm } from '../../components/RegionSelect';
import { RadioInputNotForm } from '../../components/RadioInput';
import { GET_REGION_ELIGIBILITY, GET_INSURANCE_ON_FILE_FOR_PATIENT, GET_INSURANCE_ON_FILE } from '../../graphql';
import SpinLoader from '../../components/SpinLoader';
import { OnFileInsurance } from './components/pickInsurance/onFileInsurance';
import PlacesAutocomplete from '../../components/addressAutoComplete/PlacesAutocomplete';
import { Form, Formik } from 'formik';
import FormField from '../../components/FormField';

class PickInsuranceProvider extends React.Component {
  constructor(props) {
    super(props);

    this.cardDiagnosed = React.createRef();
    this.cardSelectInsurance = React.createRef();

    this.state = {
      selectedProvider: props.selectedProvider,
      patientState: '',
    };
  }

  onProviderSelectionChange = (provider) => {
    this.setState({
      selectedProvider: provider,
    });
  };

  componentDidMount() {
    this.props.reinitData();
    if (this.props.hasLoaded) {
      this.props.hasLoaded();
    }
  }

  checkStateEligibility = async () => {
    const { client, nonServiceStateSelected, wizard } = this.props;
    const { patientState } = this.state;

    try {
      const stateEligibility = await client.query({
        query: GET_REGION_ELIGIBILITY,
        variables: {
          state: patientState,
        },
      });
      // console.log(
      //   'stateEligibility',
      //   stateEligibility.data.getRegionEligibility
      // );
      if (
        !stateEligibility ||
        !stateEligibility.data ||
        !stateEligibility.data.getRegionEligibility ||
        (stateEligibility.data.getRegionEligibility !== 'full' && 
        stateEligibility.data.getRegionEligibility !== 'visit' && stateEligibility.data.getRegionEligibility !== 'kit')
      ) {
        this.setState(
          {
            isNotServicedState: true,
            regionEligibility: !stateEligibility || !stateEligibility.data || !stateEligibility.data.getRegionEligibility ? 'none' : stateEligibility.data.getRegionEligibility,
          },
          () => {
            nonServiceStateSelected(wizard);
          }
        );
      } else {
        this.setState(
          {
            regionEligibility: !stateEligibility || !stateEligibility.data || !stateEligibility.data.getRegionEligibility ? 'none' : stateEligibility.data.getRegionEligibility,
          },
          () => {
            setTimeout(() => {
              if (this.cardDiagnosed && this.cardDiagnosed.current) {
                this.cardDiagnosed.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center',
                });
              }
            }, 300);
          }
        );
      }
    } catch (error) {
      console.log('ELIGIBI err', error);
    }
  };

  onProviderSelected = async (selectedProvider, wizard) => {
    const { onProviderSelected, client, nonServiceStateSelected } = this.props;
    const { patientState } = this.state;

    try {
      const stateEligibility = await client.query({
        query: GET_REGION_ELIGIBILITY,
        variables: {
          state: patientState,
        },
      });
      if (!stateEligibility || !stateEligibility.data || 
        !stateEligibility.data.getRegionEligibility 
        || (stateEligibility.data.getRegionEligibility !== 'full' && stateEligibility.data.getRegionEligibility !== 'visit')) {
        this.setState(
          {
            isNotServicedState: true,
          },
          () => {
            nonServiceStateSelected(wizard);
          }
        );
      }
      onProviderSelected(selectedProvider, wizard);
    } catch (error) {
      console.log('ELIGIBI err', error);
    }
  };

  render() {
    const { wizard, onNonSupportedProviderSelected, bypassInsurance, isNewPatientFlow, notDetected, nonServiceStateSelected, onStateSelected } = this.props;

    const { selectedProvider, patientState, detected, regionEligibility } = this.state;
    // console.log('Pick provider', bypassInsurance)
    if (!isNewPatientFlow) {
      return <NotNewPatient {...this.props} bypassInsurance={bypassInsurance} onProviderSelectionChange={this.onProviderSelectionChange} selectedProvider={selectedProvider} />;
    } else {
      return (
        <>
          <Card p={4} mb="42px">
            <Flex mb={4} flexDirection="column">
              <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                <Heading textAlign="center" mb={4}>
                  In which state are you located?
                </Heading>
                <div
                  style={{
                    maxWidth: '500px',
                    alignSelf: 'center',
                    width: '100%',
                  }}
                >
                  <RegionSelectNotForm
                    value={this.state.patientState}
                    style={{ width: '100%' }}
                    onSelectionChange={(val) => {
                      onStateSelected(val);
                      this.setState(
                        {
                          patientState: val,
                        },
                        () => this.checkStateEligibility()
                      );
                    }}
                  />
                </div>
              </Flex>
            </Flex>
          </Card>
          <span style={{ textAlign: 'center', marginBottom: '42px' }}>
            <Text style={{ display: 'inline' }}>Already have an account? &nbsp;</Text>
            <Link to="/schedule" id="link_login">
              Sign In
            </Link>
          </span>
          {patientState && (
            <Card p={4} mb={5} ref={this.cardDiagnosed}>
              <Flex mb={4} flexDirection="column">
                <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                  <Heading textAlign="center" mb={4}>
                    Have you been diagnosed with hypothyroidism?
                  </Heading>
                  <Flex flexDirection="row">
                    <RadioInputNotForm
                      onChecked={() => {
                        if (regionEligibility !== 'full' && regionEligibility !=='visit') {
                          this.setState(
                            {
                              detected: true,
                              isNotServicedState: true,
                            },
                            () => {
                              nonServiceStateSelected(wizard);
                            }
                          );
                        } else {
                          this.setState(
                            {
                              detected: true,
                            },
                            () => {
                              setTimeout(() => {
                                if (this.cardDiagnosed && this.cardDiagnosed.current) {
                                  this.cardDiagnosed.current.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start',
                                    inline: 'nearest',
                                  });
                                }
                              }, 300);
                            }
                          );
                        }
                      }}
                      checked={this.state.detected === true}
                      label="Yes"
                      style={{ marginRight: '24px' }}
                    />
                    <RadioInputNotForm
                      onChecked={() => {
                        notDetected(wizard);
                      }}
                      checked={this.state.detected === false}
                      label="No"
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          )}
          {patientState &&
            detected === true && (
              <div ref={this.cardSelectInsurance}>
                <InsuranceListSelector
                  //   setOnFileInsurance={setOnFileInsurance}
                  //   onFileInsuranceData={undefined}
                  onProviderSelected={this.onProviderSelected}
                  bypassInsurance={bypassInsurance}
                  wizard={wizard}
                  onNonSupportedProviderSelected={onNonSupportedProviderSelected}
                  //   bypassInsurance={bypassInsurance}
                  selectedProvider={selectedProvider}
                  onProviderSelectionChange={this.onProviderSelectionChange}
                  //   isParentValid={this.state.patientState}
                />
              </div>
            )}
        </>
      );
    }
  }
}

const NotNewPatient = (props) => {
  const {
    onProviderSelected,
    wizard,
    onNonSupportedProviderSelected,
    bypassInsurance,
    setOnFileInsurance,
    useOnFileInsurance,
    isAdmin,
    enforcePatientId,
    onProviderSelectionChange,
    selectedProvider,
    session,
    InsuranceMemberChanged,
    insuranceMemberLost,
    history,
  } = props;

  // this.props.location &&
  // this.props.location.state &&
  // this.props.location.state.
  return (
    <Query
      query={isAdmin ? GET_INSURANCE_ON_FILE_FOR_PATIENT : GET_INSURANCE_ON_FILE}
      variables={
        isAdmin
          ? {
              patientId: enforcePatientId,
            }
          : null
      }
    >
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <React.Fragment>
              <Flex flex={1} justifyContent="center" alignItems="center" flexDirection={'column'} mt={4}>
                <SpinLoader />
                <Text textAlign="center" mt={4} mb={4} fontSize="18px">
                  Loading Insurances
                </Text>
              </Flex>
            </React.Fragment>
          );
        }

        if (error) {
          return <Text>An error occured</Text>;
        }

        let insurance;
        if (!loading && !error && data) {
          insurance = isAdmin ? data.getInsurancesOnFileForPatient : data.getInsurancesOnFile;
        }
        return (
          <Fragment>
            {!loading &&
              !error && (
                <React.Fragment>
                  {!insurance || insurance.length < 1 || insurance[0].insuranceIdNumber === null || insurance[0].insuranceIdNumber === '' ? (
                    <>
                      {!!session && session.isMember && session.membershipSpecific === 'insurance' ? (
                      // {true === true ? (
                        <Card p={4} mb={4}>
                          <Flex mb={4} flexDirection="column">
                            <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                              <Flex flexDirection={'column'}>
                                <Heading mt={4} mb={4} textAlign={'center'}>
                                  Let’s Get Your Insurance Information!
                                </Heading>
                                <Text textAlign="center" mb={3}>
                                  Before scheduling your next visit, please upload pictures of your insurance card so that we can confirm your eligibility.
                                </Text>

                                <Button style={{ margin: '0 auto' }} variant="pink" mt={4} onClick={() => history.push('/my-insurances/add')}>
                                  Continue
                                </Button>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Card>
                      ) : (
                        <Flex />
                      )}
                    </>
                  ) : (
                    <OnFileInsurance
                      insurance={insurance}
                      useOnFileInsurance={useOnFileInsurance}
                      wizard={wizard}
                      session={session}
                      InsuranceMemberChanged={InsuranceMemberChanged}
                      insuranceMemberLost={InsuranceMemberChanged}
                    />
                  )}
                </React.Fragment>
              )}
            {!!session && session.isMember && session.membershipSpecific === 'insurance' ? (
              <Flex />
            ) : (
              <InsuranceListSelector
                setOnFileInsurance={setOnFileInsurance}
                onFileInsuranceData={insurance}
                onProviderSelected={onProviderSelected}
                wizard={wizard}
                onNonSupportedProviderSelected={onNonSupportedProviderSelected}
                bypassInsurance={bypassInsurance}
                selectedProvider={selectedProvider}
                onProviderSelectionChange={onProviderSelectionChange}
              />
            )}
          </Fragment>
        );
      }}
    </Query>
  );
};

export default withRouter(withApollo(withWizard(PickInsuranceProvider)));
