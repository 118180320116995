import React, { Fragment, Component } from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';

import SpinLoader from '../SpinLoader';
import ConsultationFuture from './ConsultationFuture';
import ConsultationFuture2 from '../ConsultationFuture';
import withSession from '../../lib/withSession';
import { PRESCRIPTION, APPT_SUMMARY } from '../../graphql';
import ConsultationPast from './ConsultationPast';

const DetailNavigation = styled.div`
  flex: 1;
  margin-bottom: 36px;
  margin-left: -16px;
  margin-top: -16px;
  @media screen and (min-width: 52em) {
    display: none;
    margin-bottom: 0px;
    margin-left: 0;
    margin-top: 0;
  }
`;

const BackButton = styled.div`
  background: rgba(180, 205, 244, 0.5);
  width: 100%;
  color: #333;
  padding: 6px 12px;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const initialState = {
  prompt: false,
  loading: false,
  ready: false,
  appointment: {},
  prescription: '',
  summary: '',
};

class ConsultationNutritionistDetails extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = initialState;
  }

  componentDidMount = async () => {
    const { appointments, match, client, toastManager, bypassPatientId } = this.props;
    const appointment = appointments.find((item) => {
      if (bypassPatientId) {
        return item.appointmentId.toString() === match.params.id.toString();
      }
      return item.id === match.params.id;
    });

    try {
      if (appointment.status === '3' || appointment.status === '4') {
        const { data } = await client.query({
          query: PRESCRIPTION,
          variables: {
            id: appointment.id,
            bypassPatientId: bypassPatientId ? appointment.patientId : null,
          },
        });

        const response = await client.query({
          query: APPT_SUMMARY,
          variables: {
            id: appointment.id,
          },
        });

        this.setState({
          appointment,
          prescription: data.prescription,
          summary: response.data.appointmentSummary,
          ready: true,
        });
      } else {
        this.setState({ appointment, ready: true });
      }
    } catch (error) {
      this.setState({ ready: true });
      toastManager.add('Unable to fetch consultation, try again!', {
        appearance: 'error',
      });
    }
  };

  handleModal = () => {
    this.setState({ prompt: !this.state.prompt });
  };

  render() {
    const { appointments, history, session, bypassPatientId } = this.props;
    const { appointment, ready, prescription, summary } = this.state;

    if (!appointments || !appointments.length || !appointment) {
      return null;
    }

    if (!ready) {
      return (
        <Flex flex={1} justifyContent="center" alignItems="center">
          <SpinLoader />
        </Flex>
      );
    }

    return (
      <Fragment>
        <Flex p={3} flexDirection="column" mb={5}>
          <DetailNavigation mb={4} mt={3}>
            <BackButton onClick={() => history.push('/consultations-nutritionist')}>{`<  Your Visits`}</BackButton>
          </DetailNavigation>

          {(appointment.status === 'f' || appointment.status === '2' || bypassPatientId) && (
            <>
              {!appointment.appointmentTypeId || !window._env_.REACT_APP_ATHENA_NEW_NUTRITIONIST_30 || appointment.appointmentTypeId !== window._env_.REACT_APP_ATHENA_NEW_NUTRITIONIST_30 ? (
                <ConsultationFuture
                  appointment={appointment}
                  history={history}
                  intakeStatus={session.intakeStatus}
                  menoIntakeStatus={session.menoIntakeStatus}
                  intakeDate={session.intakeDate}
                  menoIntakeDate={session.menoIntakeDate}
                  onClick={this.handleModal}
                />
              ) : (
                <ConsultationFuture
                  appointment={appointment}
                  history={history}
                  intakeStatus={session.intakeStatus}
                  menoIntakeStatus={session.menoIntakeStatus}
                  intakeDate={session.intakeDate}
                  menoIntakeDate={session.menoIntakeDate}
                  onClick={this.handleModal}
                  isWhereby={true}
                />
              )}
            </>
          )}

          {(appointment.status === '3' || appointment.status === '4') && <ConsultationPast appointment={appointment} prescription={prescription} summary={summary} session={session} />}
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  withToastManager
)(withSession(ConsultationNutritionistDetails));
