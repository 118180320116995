import React, { useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Box, Flex, Heading, Text } from 'rebass';
import SpinLoader from '../../components/SpinLoader';
import { Input } from '../../components/TextInput';
import { WhiteCard } from './components/WhiteCard';

const REQUEST_OTP = gql`
  query generateOtpConfirmAppointment($id: String!, $appointmentId: String!) {
    generateOtpConfirmAppointment(id: $id, appointmentId: $appointmentId) {
      ok
    }
  }
`;

export const OtpInner = ({ mutate, loading, appointmentId, athenaId }) => {
  const [value, setValue] = useState();

  return (
    <Flex flexDirection="column" alignItems={'center'} flex={1}>
      <Heading>Verify Code</Heading>
      <WhiteCard>
        <Query
          query={REQUEST_OTP}
          variables={{
            id: athenaId,
            appointmentId,
          }}
        >
          {({ loading: loadingRequestOtp, data }) => {
            if (!!data && !!data.generateOtpConfirmAppointment && data.generateOtpConfirmAppointment.ok === false) {
              return (
                <Text textAlign="center" mb={3}>
                  An error occured
                </Text>
              );
            }
            return (
              <>
                <Box
                  px={2}
                  style={{
                    width: '100%',
                  }}
                >
                  {!loading && !loadingRequestOtp ? (
                    <>
                      <Text textAlign="center" mb={3}>
                        We sent a one time passcode to your phone.
                      </Text>
                    </>
                  ) : (
                    <Text>{loadingRequestOtp ? 'Generating...' : 'Verifying...'}</Text>
                  )}
                </Box>
                {loading || !!loadingRequestOtp ? (
                  <Flex
                    px={2}
                    flexDirection="column"
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '40px',
                    }}
                  >
                    <SpinLoader />
                  </Flex>
                ) : (
                  <Flex px={2} flexDirection="column">
                    <Text mb={2} textAlign="center" mt={3} style={{ fontWeight: 600 }}>
                      Enter your code here
                    </Text>
                    <Flex justifyContent="center" alignItems="center" flexDirection="column">
                      <Input
                        style={{
                          maxWidth: '250px',
                          fontSize: '28px',
                          letterSpacing: '16px',
                          textAlign: 'center',
                        }}
                        placeholder="••••••"
                        autoComplete="one-time-code"
                        onChange={(r) => {
                          setValue(r.target.value);
                          if (r.target.value.length === 6) {
                            mutate(r.target.value);
                          }
                        }}
                        autoFocus
                        value={value}
                      />
                    </Flex>
                  </Flex>
                )}
              </>
            );
          }}
        </Query>
      </WhiteCard>
    </Flex>
  );
};
