import React from 'react';
import IconContainer from './IconContainer';

const Pill = props => (
  <IconContainer {...props}>
    <svg data-name="Pill" viewBox="0 0 46 45">
      <path
        d="M 0.00 0.00 L 46.00 0.00 L 46.00 45.00 L 0.00 45.00 L 0.00 0.00 Z M 17.57 11.56 C 14.77 14.42 11.85 17.17 9.10 20.08 C 5.14 23.81 5.20 31.12 9.21 34.79 C 12.88 38.80 20.19 38.86 23.92 34.90 C 28.14 30.80 32.26 26.58 36.43 22.43 C 38.51 20.35 39.87 18.04 39.77 15.01 C 40.34 9.11 34.89 3.66 28.99 4.23 C 23.75 4.02 21.00 8.37 17.57 11.56 Z"
        fill="#ffffff"
      />
      <path
        d="M 28.99 4.23 C 34.89 3.66 40.34 9.11 39.77 15.01 C 39.87 18.04 38.51 20.35 36.43 22.43 C 32.26 26.58 28.14 30.80 23.92 34.90 C 20.19 38.86 12.88 38.80 9.21 34.79 C 5.20 31.12 5.14 23.81 9.10 20.08 C 11.85 17.17 14.77 14.42 17.57 11.56 C 21.00 8.37 23.75 4.02 28.99 4.23 Z M 11.52 33.24 C 12.11 30.88 12.09 27.71 13.92 25.94 C 16.11 23.50 18.55 21.22 20.91 18.93 C 23.34 21.31 25.76 23.71 28.13 26.15 C 31.01 23.79 33.84 21.06 36.06 18.08 C 37.23 15.34 37.06 11.43 34.63 9.37 C 32.24 6.54 26.71 6.52 24.24 9.23 C 20.63 12.62 17.24 16.25 13.68 19.69 C 11.55 21.98 9.18 23.56 9.27 27.02 C 9.03 29.54 10.19 31.27 11.52 33.24 Z"
        fill="#9BBDF9"
      />
      <path
        d="M 34.63 9.37 C 37.06 11.43 37.23 15.34 36.06 18.08 C 33.84 21.06 31.01 23.79 28.13 26.15 C 25.76 23.71 23.34 21.31 20.91 18.93 C 18.55 21.22 16.11 23.50 13.92 25.94 C 12.09 27.71 12.11 30.88 11.52 33.24 C 10.19 31.27 9.03 29.54 9.27 27.02 C 9.18 23.56 11.55 21.98 13.68 19.69 C 17.24 16.25 20.63 12.62 24.24 9.23 C 26.71 6.52 32.24 6.54 34.63 9.37 Z"
        fill="#ffffff"
      />
    </svg>
  </IconContainer>
);

export default Pill;
