import React, { useCallback, useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { Box, Button, Flex, Heading, Image, Text } from 'rebass';
import styled from 'styled-components';
import PleaseWait from '../../components/PleaseWait';
import hsUrl from '../../static/HS_identifyConvo.png';
import { SearchInput } from '../../components/TextInput';
import { FIND_ONE_CONVO, getWarnings } from './shared';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

export const AddByHelpscoutIDModal = ({ helpscoutId, close, add, tree }) => {
  const [result, setResult] = useState();
  const [filter, setFilter] = useState(null);
  const [filterTmp, setFilterTmp] = useState(null);
  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      close();
    }
  }, []);

  useEffect(
    () => {
      document.addEventListener('keydown', escFunction, true);

      return () => {
        document.removeEventListener('keydown', escFunction, true);
      };
    },
    [escFunction]
  );
  return (
    <ModalInner>
      <Flex flexDirection={'column'}>
        <Heading mb={4} textAlign="center">
          Find Conversation By ID
        </Heading>

        <Text fontSize={3} textAlign="center" mb={4}>
          You can find the id by opening the conversation on HS and copying the numbers located like below in the URL
        </Text>
        <Image src={hsUrl} maxHeight={'76px'} mb={4} />

        {!result &&
          !filter && (
            <>
              <SearchInput
                type="search"
                placeholder="Helpscout Conversation ID"
                value={filterTmp}
                onEnter={() => {
                  if (!!isNaN(filterTmp) || !!isNaN(parseFloat(filterTmp))) return;
                  setFilter(filterTmp);
                }}
                onChange={(e) => {
                  if (!e.target.value) {
                    setFilter(null);
                    setFilterTmp(null);
                    return;
                  }
                  setFilterTmp(e.target.value);
                }}
              />
              <Flex justifyContent={'center'} alignItems={'center'}>
                <Button
                  mt={4}
                  mr={3}
                  alignSelf={'center'}
                  variant={'pink'}
                  onClick={close}
                  style={{
                    padding: '4px 12px',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  mt={4}
                  alignSelf={'center'}
                  variant={'primary'}
                  onClick={() => {
                    setFilter(filterTmp);
                  }}
                  style={{
                    padding: '4px 12px',
                  }}
                  disabled={!!isNaN(filterTmp) || !!isNaN(parseFloat(filterTmp))}
                >
                  Search
                </Button>
              </Flex>
            </>
          )}

        {!result &&
          !!filter && (
            <>
              {tree.findIndex((x) => x.id === filter) > -1 ? (
                <>
                  <Flex mb={3}>
                    <Text>Search for :</Text>
                    <Box
                      ml={2}
                      style={{ backgroundColor: '#d7d7d7', padding: '2px 6px', cursor: 'pointer' }}
                      onClick={() => {
                        setFilterTmp();
                        setFilter();
                      }}
                    >
                      <Flex>
                        <Text>{filter}</Text>
                        <Text ml={2}>X</Text>
                      </Flex>
                    </Box>
                  </Flex>
                  <Text color={'red'} textAlign={'center'}>
                    Conversation already in patient's conversation tree
                  </Text>

                  <Flex mt={4} justifyContent={'center'} alignItems={'center'}>
                    <Button
                      variant={'pink'}
                      style={{
                        padding: '4px 12px',
                        alignSelf: 'center',
                      }}
                      onClick={close}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </>
              ) : (
                <Query query={FIND_ONE_CONVO} variables={{ conversationId: filter }}>
                  {({ loading, data }) => {
                    if (!!loading) {
                      return <PleaseWait />;
                    }

                    return (
                      <>
                        <Flex mb={3}>
                          <Text>Search for :</Text>
                          <Box
                            ml={2}
                            style={{ backgroundColor: '#d7d7d7', padding: '2px 6px', cursor: 'pointer' }}
                            onClick={() => {
                              setFilterTmp();
                              setFilter();
                            }}
                          >
                            <Flex>
                              <Text>{filter}</Text>
                              <Text ml={2}>X</Text>
                            </Flex>
                          </Box>
                        </Flex>
                        {!data && (
                          <Flex>
                            <Text textAlign={'center'} color={'red'}>
                              An error occured
                            </Text>

                            <Flex mt={4} justifyContent={'center'} alignItems={'center'}>
                              <Button
                                variant={'pink'}
                                style={{
                                  padding: '4px 12px',
                                  alignSelf: 'center',
                                }}
                                onClick={close}
                              >
                                Cancel
                              </Button>
                            </Flex>
                          </Flex>
                        )}
                        {!data.adminGetPhysicianConversationByConvoId ||
                          (data.adminGetPhysicianConversationByConvoId.length < 1 && (
                            <Flex>
                              <Text textAlign={'center'} color={'red'}>
                                No Conversation found
                              </Text>

                              <Flex mt={4} justifyContent={'center'} alignItems={'center'}>
                                <Button
                                  variant={'pink'}
                                  style={{
                                    padding: '4px 12px',
                                    alignSelf: 'center',
                                  }}
                                  onClick={close}
                                >
                                  Cancel
                                </Button>
                              </Flex>
                            </Flex>
                          ))}
                        {data.adminGetPhysicianConversationByConvoId.map((x) => {
                          const errs = getWarnings(x, helpscoutId);
                          return (
                            <Box
                              style={{
                                marginLeft: `8px`,
                                background: 'white',
                                border: `1px solid ${!errs || errs.length < 1 ? '#717171' : 'orange'}`,
                                padding: '8px 12px',
                                borderRadius: '4px',
                              }}
                            >
                              <Flex flexDirection={'column'}>
                                <Text
                                  fontWeight={500}
                                  style={{ textDecoration: 'underline', cursor: 'pointer', color: '#07c' }}
                                  onClick={() => {
                                    window.open(`https://secure.helpscout.net/conversation/${x.id}`, '_blank');
                                  }}
                                >
                                  #{x.id}
                                </Text>
                                <Text fontWeight={300}>Last user update: {(x.details || {}).lastUserUpdate}</Text>
                                <Text>{(x.details || {}).preview}</Text>
                                {errs.map((er) => {
                                  return (
                                    <Text fontWeight={300} color={'orange'}>
                                      - {er}
                                    </Text>
                                  );
                                })}
                                <Flex mt={4} justifyContent={'center'} alignItems={'center'}>
                                  <Button
                                    variant={'pink'}
                                    style={{
                                      padding: '4px 12px',
                                      alignSelf: 'center',
                                      marginRight: '12px',
                                    }}
                                    onClick={close}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant={'primary'}
                                    style={{
                                      padding: '4px 12px',
                                      alignSelf: 'center',
                                    }}
                                    onClick={() => {
                                      add(x);
                                    }}
                                  >
                                    Add
                                  </Button>
                                </Flex>
                              </Flex>
                            </Box>
                          );
                        })}
                      </>
                    );
                  }}
                </Query>
              )}
            </>
          )}
      </Flex>
    </ModalInner>
  );
};