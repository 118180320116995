import React, { useState } from 'react';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import { Query, withApollo } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';
import { ADMIN_CHANGE_ORDER_WORKOUT, ADMIN_DELETE_ORDER_WORKOUT, ADMIN_GET_WORKOUTS } from '../../graphql';
import ConfirmModal from '../../components/ConfirmModal';
import AddWorkout from './modalContent/AddWorkout';
import WorkoutElements from './WorkoutElements';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
  max-height: 800px;
  overflow: auto;
`;
const Workouts = ({ client }) => {
  const [isAdd, setIsAdd] = useState(null);

  const deleteOnClick = async (id) => {
    var r = window.confirm('Confirm Delete. (Any screen logged by user will be marked as inactive instead)');
    if (r === true) {
      await client.mutate({
        mutation: ADMIN_DELETE_ORDER_WORKOUT,
        variables: {
          screenId: id,
        },
        refetchQueries: [
          {
            query: ADMIN_GET_WORKOUTS,
          },
        ],
      });
    } else {
      return;
    }
  };

  const changeDirectionOnClick = async (id, direction) => {
    await client.mutate({
      mutation: ADMIN_CHANGE_ORDER_WORKOUT,
      variables: {
        id: id,
        direction,
      },
      refetchQueries: [
        {
          query: ADMIN_GET_WORKOUTS,
        },
      ],
    });
  };

  const CATEGORIES_COLUMNS = [
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'description',
      accessor: 'description',
    },
    {
      Header: 'colors',
      Cell: (row) => {
        return (
          <Flex flexDirection="row">
            <Box
              title="screen color"
              style={{
                height: '24px',
                width: '24px',
                marginRight: '12px',
                border: '1px solid #777',
                backgroundColor: row.original.bgMainColor,
              }}
            />
            <Box
              title="hormone color"
              style={{
                height: '24px',
                width: '24px',
                marginRight: '12px',
                border: '1px solid #777',
                backgroundColor: row.original.bgBubblesColor,
              }}
            />
          </Flex>
        );
      },
    },
    {
      Header: 'Member Only',
      accessor: 'memberOnly',
      Cell: (row) => {
        return <div>{row.original.memberOnly ? '✓' : ''}</div>;
      },
    },
    {
      Header: 'active',
      accessor: 'isActive',
      Cell: (row) => {
        return <div>{row.original.isActive ? '✓' : ''}</div>;
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <Flex flexDirection="row">
              <div title="delete" style={{ cursor: 'pointer' }} onClick={() => deleteOnClick(row.original.id)}>
                🗑
              </div>
              <div title="move order up" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => changeDirectionOnClick(row.original.id, 'UP')}>
                ↑
              </div>
              <div title="move order down" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => changeDirectionOnClick(row.original.id, 'DOWN')}>
                ↓
              </div>
              <div title="edit" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => setIsAdd(row.original)}>
                ✎
              </div>
            </Flex>
          </Flex>
        );
      },
    },
  ];
  return (
    <div>
      <Flex flexDirection="row" style={{ justifyContent: 'space-between' }} mt={4} mb={3}>
        <Heading fontSize="20px">Workouts</Heading>
        <Button
          style={{ background: '#344f79' }}
          onClick={() => {
            setIsAdd({});
          }}
        >
          Add Workout
        </Button>
      </Flex>
      <Query query={ADMIN_GET_WORKOUTS}>
        {({ data, loading, error }) => {
          if (loading || error) return <div />;
          console.log('ADMIN_GET_WORKOUTS_DIFFICULTIES', data);
          return (
            <Box>
              <ReactTable
                data={data.getAdminWorkouts}
                columns={CATEGORIES_COLUMNS}
                defaultPageSize={data.getAdminWorkouts && data.getAdminWorkouts.length > 3 ? Math.min(data.getAdminWorkouts.length, 25) : 3}
                pageSize={data.getAdminWorkouts && data.getAdminWorkouts.length > 3 ? data.getAdminWorkouts.length : 3}
                showPagination={data.getAdminWorkouts && data.getAdminWorkouts.length > 24}
                SubComponent={(row) => {
                  return <WorkoutElements original={row.original} />;
                }}
              />

              <ConfirmModal
                childrenManaged
                isOpen={!!isAdd}
                confirmLabel="create"
                onClose={() => {
                  setIsAdd(null);
                }}
                onBackgroundClick={() => {
                  setIsAdd(null);
                }}
                onEscapeKeydown={() => {
                  setIsAdd(null);
                }}
                onConfirm={() => {
                  setIsAdd(null);
                }}
              >
                <ModalInner>
                  <Heading fontSize="20px" mb={3}>
                    {!!isAdd && !!isAdd.id ? 'Update Workout' : 'Add Workout'}
                  </Heading>
                  <AddWorkout
                    workout={isAdd}
                    workoutLength={data.getAdminWorkouts.length}
                    onClose={() => {
                      setIsAdd(null);
                    }}
                    onSave={() => {
                      setIsAdd(null);
                    }}
                  />
                </ModalInner>
              </ConfirmModal>
            </Box>
          );
        }}
      </Query>
    </div>
  );
};

export default withApollo(Workouts);
