import React from 'react';
import { Flex, Heading } from 'rebass';
import * as Yup from 'yup';
import TextInput, { TextInputWithLabel } from '../../../../components/TextInput';
import { Form, Formik } from 'formik';
import FormField from '../../../../components/FormField';
import { EditModalButtons } from '../Builder';

export const RangeInputEdit = ({ close, save, properties }) => {
  const schema = Yup.object().shape({
    question: Yup.string()
      .trim()
      .min(2, 'Question must be greater than 1 character')
      .required('Required'),
    researchId: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9_]+$/, 'No special characters or space allowed except underscore (_)')
      .nullable(true),
  });
  return (
    <Flex flex={1} flexDirection={'column'} width={'600px'}>
      <Heading textAlign={'center'} mb={4}>
        Edit Range
      </Heading>
      <Formik
        initialValues={{ ...properties }}
        isInitialValid={() => {
          return properties && properties.question && properties.question.length > 1 && !isNaN((properties.ctrlProps || {}).min) && !isNaN((properties.ctrlProps || {}).max);
        }}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          // let min =0
          // if()
          save(values, actions);
        }}
        render={({ touched, errors, isValid, values, submitForm }) => {
          return (
            <Form>
              <FormField mb={3} error={touched.question && errors.question}>
                <TextInputWithLabel placeholder="Question *" name="question" id="input_question" />
              </FormField>
              <FormField mb={3} error={touched.description && errors.description}>
                <TextInputWithLabel placeholder="description" name="description" id="input_description" />
              </FormField>
              <FormField mb={3} error={(touched.ctrlProps || {}).min && (errors.ctrlProps || {}).min}>
                <TextInputWithLabel type="number" placeholder="minimum value" name="ctrlProps.min" id="input_min" />
              </FormField>
              <FormField mb={3} error={(touched.ctrlProps || {}).max && (errors.ctrlProps || {}).max}>
                <TextInputWithLabel type="number" placeholder="maximum value" name="ctrlProps.max" id="input_max" />
              </FormField>
              <FormField mb={3} error={touched.researchId && errors.researchId}>
                <TextInputWithLabel placeholder="research Id (if set, data can be used for data analysis)" name="researchId" id="input_researchId" />
              </FormField>
              <FormField
                flexDirection="row"
                mb={3}
                style={{
                  alignContent: 'center',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
                error={touched.mustBeInherited && errors.mustBeInherited}
              >
                <TextInput type="checkbox" checked={values.mustBeInherited} id={`mustBeInherited`} name="mustBeInherited" />
                <label htmlFor="mustBeInherited" style={{ marginLeft: '16px' }}>
                  must be inherited (templates created from this one will mandatorily have this item)
                </label>
              </FormField>
              <EditModalButtons
                close={close}
                save={submitForm}
                isValid={
                  isValid &&
                  !isNaN(((values.ctrlProps || {}) || {}).min) &&
                  !isNaN(((values.ctrlProps || {}) || {}).max) &&
                  (values.ctrlProps || {}).min < (values.ctrlProps || {}).max
                }
              />
            </Form>
          );
        }}
      />
    </Flex>
  );
};
