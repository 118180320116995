import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading, Text, Box } from 'rebass';
import { withWizard } from 'react-albus';
import { isToggleActive } from '../../../../components/featureToggle/toggles';
import { additionalBiomarkersHelper } from '../../../../utils/additionalBiomarkersUtils';
import styled from 'styled-components';

const CancelsBadge = styled(Box)`
  background-color: #f2f2f9;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;
const AdditionalBiomarkers = ({
  wizard,
  onNext,
  onSkip,
  addItemToOrder,
  removeItemFromOrder,
  selectedBiomarkers,
  alreadyBiomarkers,
}) => {
  return (
    <Fragment>
      <Flex flex={1} style={{ alignSelf: 'end' }} mt={4}>
        <Flex flexDirection="column">
          {/* <Heading textAlign="center" color="#486288" fontWeight={550} mb={4}>
            Optional Add-ons
          </Heading>
          <Text mb="28px" textAlign="center" color="#486288">
            In addition to the four markers already included in your test kit,
            the following add-ons are optionally available for even more
            comprehensive testing, still only requiring one finger prick.
          </Text> */}
          {additionalBiomarkersHelper.biomarkers
            .filter(x => {
              return isToggleActive(x.active);
            })
            .map(biomarker => {
              return (
                <Card p={4} key={biomarker.id} mb={28}>
                  <Flex flexDirection="column">
                    <Flex flexDirection={['column', 'row']}
                        style={{ alignItems: 'baseline' }}>
                      <Flex
                        flexDirection="row"
                        flex={1}
                        style={{ alignItems: 'baseline' }}
                      >
                        <Heading
                          textAlign="center"
                          fontWeight={550}
                          mb={4}
                          mr="16px"
                        >
                          {biomarker.title}
                        </Heading>

                        <Text>• ${biomarker.price}</Text>
                      </Flex>
                      {!(alreadyBiomarkers || []).find(
                        y => y === biomarker.sku
                      ) ? null : (
                        <CancelsBadge>
                          <Text color="#344f79" fontSize="12px">
                            Already Purchased
                          </Text>
                        </CancelsBadge>
                      )}
                    </Flex>

                    <Box
                      style={{
                        border: '1px solid rgba(53, 80, 120, 0.3)',
                        borderLeft: '0',
                        borderRight: '0',
                        padding: '12px 0',
                      }}
                    >
                      {biomarker.description}
                    </Box>
                    {!(alreadyBiomarkers || []).find(
                      y => y === biomarker.sku
                    ) ? (
                      <>
                        {selectedBiomarkers.filter(x => x === biomarker.sku)
                          .length < 1 ? (
                          <Button
                            style={{
                              alignSelf: 'flex-end',
                              width: '160px',
                              maxWidth: '160px',
                              marginTop: '16px',
                            }}
                            variant="pink"
                            onClick={() => {
                              addItemToOrder(biomarker.sku);
                            }}
                          >
                            Add
                          </Button>
                        ) : (
                          <Button
                            style={{
                              alignSelf: 'flex-end',
                              width: '160px',
                              maxWidth: '160px',
                              marginTop: '16px',
                            }}
                            variant="outline"
                            onClick={() => {
                              removeItemFromOrder(biomarker.sku);
                            }}
                          >
                            Remove
                          </Button>
                        )}
                      </>
                    ) : null}
                  </Flex>
                </Card>
              );
            })}
          <Flex
            flexDirection={['column', 'row']}
            alignItems="center"
            justifyContent="center"
          >
            <Button
              style={{
                alignSelf: 'center',
                width: '160px',
                maxWidth: '160px',
                margin: '6px',
                marginTop: '16px',
              }}
              variant="white"
              onClick={() => {
                onSkip(wizard);
              }}
            >
              Skip
            </Button>
            <Button
              style={{
                alignSelf: 'center',
                width: '160px',
                maxWidth: '160px',
                margin: '6px',
                marginTop: '16px',
              }}
              variant="pink"
              disabled={!selectedBiomarkers || selectedBiomarkers.length < 1}
              onClick={() => {
                onNext(wizard);
              }}
            >
              Next
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default withWizard(AdditionalBiomarkers);
