import React, { useState } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Mutation, withApollo } from 'react-apollo';
import Header from '../components/Header';
import styled from 'styled-components';
import FullLayout from '../components/FullLayout';
import gql from 'graphql-tag';
import { GENERATE_CIRCLE_SO_CALLBACK } from '../graphql';
import { withRouter } from 'react-router-dom';

const ACCEPT_TERMS = gql`
  mutation acceptCommunityTerms {
    acceptCommunityTerms {
      ok
    }
  }
`;

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const CardContainer = styled(Flex)`
  max-width: 400px;
`;

const Check = ({ isAccepted }) => {
  return (
    <Flex
      style={{
        width: 40,
        height: 40,
        borderRadius: 40,
        border: '1px solid #354E79',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 18,
        backgroundColor: isAccepted ? '#354E79' : 'transparent',
      }}
    >
      {!!isAccepted && <FontAwesomeIcon size="l" color="white" icon={faCheck} />}
    </Flex>
  );
};

const CommunityTerms = ({ client, history, location }) => {
  const [acceptSpace, setAcceptSpace] = useState(false);
  const [acceptGuidelines, setAcceptGuidelines] = useState(false);

  console.log({location, history})

  return (
    <>
      <Container>
        <Flex flex={1}>
          <Header dark={false} />
        </Flex>
        <Flex flex={1} alignItems="center" justifyContent="center">
          <CardContainer flex={1} px={3}>
            <Card p={4}>
              <Flex flexDirection="column">
                <Box mb={4}>
                  <Heading textAlign="center" mb={3}>
                    Come, meet other thyroid patients who get it.
                  </Heading>
                  <Text textAlign="center">Link your account with the Paloma Health Community for peer support and expert resources.</Text>
                </Box>
                <Mutation
                  mutation={ACCEPT_TERMS}
                  onCompleted={async () => {
                    const c = await client.mutate({
                      mutation: GENERATE_CIRCLE_SO_CALLBACK,
                    });
                    if (!!location && !!location.state && !!location.state.sameWindow) {
                      window.open(c.data.generateCircleSoCallback.callback, '_self');
                    } else {
                      window.open(c.data.generateCircleSoCallback.callback, '_blank');
                      history.push('/')
                    }
                  }}
                >
                  {(accept, { loading }) => {
                    return (
                      <>
                        <Flex flexDirection="row" onClick={() => setAcceptSpace(true)} style={{ alignItems: 'center' }}>
                          <Check isAccepted={acceptSpace} />
                          <Text style={{ flex: 1 }}>I understand this is a space for people with a thyroid condition.</Text>
                        </Flex>
                        <Flex mt={3} flexDirection="row" onClick={() => setAcceptGuidelines(true)} style={{ alignItems: 'center' }}>
                          <Check isAccepted={acceptGuidelines} />
                          <Text style={{ flex: 1 }}>I agree to the Paloma Health community guidelines.</Text>
                        </Flex>

                        <Button mt={4} variant="primary" disabled={!acceptGuidelines || !acceptSpace} onClick={accept}>
                          Continue
                        </Button>

                        <Flex mt={4} flexDirection="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
                          <FontAwesomeIcon size="xs" color="#344f79" icon={faLock} />
                          <Text style={{ fontSize: '12px', fontWeight: 300 }}>Your data is always private.</Text>
                        </Flex>
                        <Text style={{ flex: 1, fontSize: '12px', fontWeight: 300, textAlign: 'center' }}>The Paloma Health Community only accesses your name and email.</Text>
                      </>
                    );
                  }}
                </Mutation>
              </Flex>
            </Card>
          </CardContainer>
        </Flex>
      </Container>
      <FullLayout />
    </>
  );
};

export default withRouter(withApollo(CommunityTerms));
