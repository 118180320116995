import styled from 'styled-components';
import {Text} from 'rebass'

const ResponseBubbleStyled = styled.div`
background:  ${props => props.mobileApp?'#FFEFEC':'#344f79'};
color: ${props => props.mobileApp?'#344f79 !important':'white !important'};
  /* background: #344f79;
  color: white !important; */
  border-radius: 0 12px 12px 12px;
  padding: 12px;
  max-width: calc(100% - 74px);
  box-shadow: rgba(52, 79, 121, 0.45) 0px 3px 16px;
  & > a {
    color: white !important;
  }
`;

const CustomerBubbleStyled = styled.div`
  background:  ${props => props.mobileApp?'#344f79':'#d6e2f6'};
  color: ${props => props.mobileApp?'white':'#344f79'};
  border-radius: 12px 12px 0 12px;
  padding: 12px;
  max-width: 80%;
  box-shadow: rgba(52, 79, 121, 0.45) 0px 3px 16px;
  & > a {
    color: white !important;
  }
`;

const BotBubbleStyled = styled.div`
  background: #f9a394;
  color: white;
  border-radius: 0 12px 12px 12px;
  padding: 12px;
  max-width: 90%;
  box-shadow: rgba(52, 79, 121, 0.45) 6px 6px 16px;
  & > a {
    color: white !important;
  }
`;

const FromStyled = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

const MessageTimeStyled = styled(Text)`
  font-size: 12px;
  align-self: center;
  flex-grow: 1;
  text-align: right;
  margin-left: 12px;
`;

const MessageTextStyled = styled(Text)`
  word-wrap: break-word;
  white-space: pre-line;
`;

const MessageAvatarStyled = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-right: 12px;
  background-image: ${props => `url(${props.photoUrl})` || ''};
  background-size: cover;
`;

export {
  ResponseBubbleStyled,
  CustomerBubbleStyled,
  BotBubbleStyled,
  FromStyled,
  MessageTimeStyled,
  MessageTextStyled,
  MessageAvatarStyled
};
