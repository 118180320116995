import React, { Component, Fragment } from 'react';
import {
  Button,
  Flex,
  Box,
  Text,
  Heading,
  Card,
  Link as ExternalLink,
} from 'rebass';
import styled from 'styled-components';
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { Steps, Step, Wizard } from 'react-albus';
import { compose, withApollo } from 'react-apollo';

import LoadingScreen from '../../../components/LoadingScreen';
import Payment from './payment';
import { injectStripe } from 'react-stripe-elements';

import {
  COMPLETE_ORDER,
  LABS,
  REGISTER_KIT,
  CHECK_SERIAL_AVAILABLE,
  MOBILE_WALLET_PAYMENT,
  CREATE_ORDER_ACTIVATION_BIOMARKERS,
  CHECK_PURCHASE_REFERRAL_BENEFITS,
  APPLY_COUPON,
} from '../../../graphql';
import withAnalytics from '../../../lib/withAnalytics';
import kitIcon from '../../../static/kitIcon.png';
import ConfirmationWithCoupon from './confirmationWithCoupon';
import withSession from '../../../lib/withSession';
import ConfirmModal from '../../../components/ConfirmModal';
import AdditionalBiomarkers from './AdditionalBiomarkersFlow/AdditionalBiomarkers';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;
const Video = styled.iframe`
  width: 640px;
  height: 360px;
  margin: 0 auto;
  margin-bottom: 25px;

  @media screen and (max-width: 950px) {
    width: 400px;
    height: 200px;
  }
`;

const Container = styled(Box)`
  /* max-width: 750px; */
  width: 100%;
  margin: 0 auto;
`;

const StyledLink = styled(ExternalLink)`
  font-size: 14px;
  color: #999;
  text-decoration: none;
  align-self: center;
`;
const InfoBox = styled(Flex)`
  flex: 1;
  background: ${props => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${props => props.theme.color.darkBlue};
  justify-content: center;
`;

const initialState = {
  dob: '',
  email: '',
  order: {
    id: '',
    amount: '',
    items: [],
  },
  address: {
    firstName: '',
    lastName: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
  },
  prompt: false,
  ready: false,
  loading: false,
  sourceId: undefined,
  coupon: undefined,
  giveToFriend: undefined,
  isCouponBOGOdisabledModal: undefined,
  canMakePayment: false,
  canMakePaymentApplePay: undefined,
  paymentRequest: undefined,
  isPaymentThroughWallet: false,
  wizard: undefined,
  serial: undefined,
  selectedBiomarkers: [],
  alreadyBiomarkers: [],
};

class RegisterKitAdditionalBiomarkersComponent extends Component {
  constructor(props, context) {
    super(props, context);
    let serial = this.props.serial;

    this.state = {
      ...initialState,
      serial,
      canMakePayment: false,
    };
  }

  setPaymentRequest = (centisizedAmount = 9900) => {
    const { order } = this.state;

    const pr = this.props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
      total: {
        label: 'Kit Order',
        amount: order && order.amount ? order.amount : centisizedAmount,
      },
    });

    pr.on('token', async ({ complete, token, ...data }) => {
      this.mobileWalletPayment(token);
      complete('success');
    });

    pr.canMakePayment().then(result => {
      this.setState({
        canMakePayment: !!result,
        canMakePaymentApplePay: result && result.applePay,
      });
    });
    this.state = {
      paymentRequest: pr,
    };
  };

  updatePaymentRequest = (centisizedAmount = 9900) => {
    const { paymentRequest, order } = this.state;
    if (!paymentRequest) return;

    paymentRequest.update({
      total: {
        label: 'Kit Order',
        amount: order && order.amount ? order.amount : centisizedAmount,
      },
    });
  };

  mobileWalletPayment = async token => {
    const {
      order,
      giveToFriend,
      address,
      email,
      selectedBiomarkers,
    } = this.state;

    try {
      let addBuyOneGiveOneKit = null;
      if (giveToFriend) {
        addBuyOneGiveOneKit = {
          purchaserFirstName: address.firstName,
          purchaserLastName: address.lastName,
          refereeFirstName: giveToFriend.firstNameFriend,
          refereeLastName: giveToFriend.lastNameFriend,
          purchaserEmail: email,
          refereeEmail: giveToFriend.emailFriend,
          personalMessage: giveToFriend.personalMessageFriend,
        };
      }

      await this.props.client.mutate({
        mutation: MOBILE_WALLET_PAYMENT,
        variables: {
          orderId: order.id,
          token: token.id,
          name: token.card.name,
          payerEmail: token.card.email,
          payerPhone: token.card.phone,
          address: {
            street1: token.card.address_line1,
            street2: token.card.address_line2,
            city: token.card.address_city,
            zip: token.card.address_zip,
            state: token.card.address_state,
          },
          addBuyOneGiveOneKit,
        },
      });

      let discount = 0;
      let couponCode = '';
      const opts = {
        cost:
          order && order.amount
            ? parseFloat((order.amount / 100).toFixed(2))
            : 0,
        biomarkers: selectedBiomarkers.join(', '),
      };
      for (let index = 0; index < order.items.length; index++) {
        const element = order.items[index];
        if (element.amount && element.amount < 0) {
          discount += element.amount;
          opts[element.parent] = (element.amount / 100).toFixed(2);
          couponCode = element.parent;
        }
      }

      this.props.analytics.track('Biomarkers Purchased', { ...opts });
      this.props.analytics.logRevenue(
        parseFloat(((order.amount + discount * -1) / 100).toFixed(2)),
        opts.cost,
        parseFloat((discount / 100).toFixed(2)),
        couponCode,
        [
          {
            productId: 'kit',
            name: 'Add Biomarkers',
            price: opts.cost,
            quantity: 1,
            category: 'kit',
          },
        ],
        order.id
      );
      this.registerKit();
    } catch (error) {
      console.log('mobileWalletPayment err', error);
    }
  };

  componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;

    if (currentPage !== prevPage) {
      let tag = '';
      window.scrollTo(0, 0);

      if (currentPage.indexOf('paymentReceived') > -1) {
        tag = 'paymentReceived';
      } else if (currentPage.indexOf('payment') > -1) {
        tag = 'payment';
      } else if (currentPage.indexOf('confirm') > -1) {
        tag = 'confirm';
      }
      if (tag) {
        // todo uncomment
        // this.props.analytics.page(tag, 'orderKit');
      }
    }
  }

  componentDidMount = async () => {
    const { toastManager ,mustPayOnActivationKit, serial} = this.props;
    if (this.state.ready) return;

    try {
      const validSerial = await this.checkSerialAvailability();
      if (!validSerial) {
        this.setState({
          serial: this.props.serial,
          ready: true,
        });
        if(!!mustPayOnActivationKit &&serial.indexOf('723-')===0){
          await this.createOrder()
        }
        return;
      }
    } catch (error) {
      toastManager.add(error.message, { appearance: 'error' });
      this.setState({ ready: true });
    }

    try {
      this.setState({
        order: {
          id: '',
          amount: 0,
          items: [],
        },
        ready: true,
      });
      if(!!mustPayOnActivationKit &&serial.indexOf('723-')===0){
        await this.createOrder()
      }
    } catch (error) {
      toastManager.add(error.message, { appearance: 'error' });
      this.setState({ ready: true });
    }
  };

  checkSerialAvailability = async () => {
    const { serial } = this.props;
    try {
      const { data } = await this.props.client.query({
        query: CHECK_SERIAL_AVAILABLE,
        variables: {
          serial: serial,
        },
      });
      if (!data || !data.checkSerialAvailable) {
        this.setState({
          invalidSerial: 'An error occured. Please retry',
        });
      } else if (!data.checkSerialAvailable.ok) {
        this.setState({
          invalidSerial: data.checkSerialAvailable.reason || 'Invalid Serial',
        });
      } else {
        if (
          data.checkSerialAvailable.additionalBiomarkers &&
          data.checkSerialAvailable.additionalBiomarkers.length > 0
        ) {
          if (data.checkSerialAvailable.additionalBiomarkers.length === 2) {
            await this.registerNoBiomarkers();
          }
          this.setState({
            alreadyBiomarkers: data.checkSerialAvailable.additionalBiomarkers,
          });
        }
        return true;
      }
      return false;
    } catch (error) {
      console.log('Err fetch serial Availability', error);
      throw Error('Error fetching serial availability, please try again.');
    }
  };

  createOrder = async (actions, values, sourceId, setSubmitting) => {
    const { selectedBiomarkers } = this.state;
    const {
      client,
      session: { email, isMember },
      mustPayOnActivationKit,
    } = this.props;
    this.setState({ loading: true });
    let skus = selectedBiomarkers;
    try {
      if (mustPayOnActivationKit) {
        skus = skus.filter(
          x =>
            x !== window._env_.REACT_APP_STRIPE_SKU &&
            x !== window._env_.REACT_APP_STRIPE_SKU_KIT_MEMBER
        );
        if (isMember) {
          skus.push(window._env_.REACT_APP_STRIPE_SKU_KIT_MEMBER);
        } else {
          skus.push(window._env_.REACT_APP_STRIPE_SKU);
        }
      }
      const res = await client.mutate({
        mutation: CREATE_ORDER_ACTIVATION_BIOMARKERS,
        variables: {
          skus: skus,
          source: sourceId,
          isWelcomeKit: !!mustPayOnActivationKit
        },
      });

      this.setState({
        card: res.data.createOrderActivationBiomarkers.cardInfo,
        order: res.data.createOrderActivationBiomarkers,
      });

      let referralBenefits;
      try {
        const { data } = await client.query({
          query: CHECK_PURCHASE_REFERRAL_BENEFITS,
          variables: {
            skus: skus,
          },
          fetchPolicy: 'network-only',
        });
        if (
          data &&
          data.checkPurchaseReferralBenefits &&
          data.checkPurchaseReferralBenefits.referralCoupon
        ) {
          referralBenefits = {
            referralCoupon: data.checkPurchaseReferralBenefits.referralCoupon,
            centisizedBalance:
              data.checkPurchaseReferralBenefits.centisizedBalance,
          };
        }

        const applyCoupon = await client.mutate({
          mutation: APPLY_COUPON,
          variables: {
            id: res.data.createOrderActivationBiomarkers.id,
            coupon: referralBenefits.referralCoupon,
            email: email,
          },
        });

        this.setState({
          referralBenefits,
          order: {
            ...res.data.createOrderActivationBiomarkers,
            ...applyCoupon.data.applyCoupon,
          },
        });
      } catch (error) {}
    } catch (error) {
      console.log(error);
      try {
        if (actions) {
          actions.setSubmitting(false);
        }
        if (!actions && setSubmitting) {
          setSubmitting(false);
        }
      } catch (error) {}

      throw Error('There was an error creating your order, please try again.');
    }
    this.setState({ loading: false });
  };

  handlePaymentNow = async (actions, wizard, sourceId) => {
    const { order } = this.state;
    const { toastManager } = this.props;

    try {
      if (!order.id) {
        const response = await this.createOrder(
          actions,
          null,
          sourceId,
          actions,
          null
        );
        this.setState({ ['order']: { ...response } });
        this.handleConfirmPurchaseNoCoupon(wizard);
        // this.handleSubmit('order', response, wizard);
      } else {
        this.handleConfirmPurchaseNoCoupon(wizard);
      }
    } catch (error) {
      toastManager.add(error.message, { appearance: 'error' });
    }
  };

  handleSubmit = (key, values, wizard) => {
    this.setState({ [key]: { ...values } }, () => {
      if (this.state.canMakePayment && key === 'order') {
        this.updatePaymentRequest();
      }
    });

    if (wizard) {
      this.setState({
        wizard,
      });
      wizard.next();
    }
  };

  addCoupon = async (actions, wizard, sourceId, forceNow) => {
    const { order } = this.state;
    const { toastManager } = this.props;

    try {
      if (!order.id) {
        // console.log('AddCoupon if');
        const response = await this.createOrder(actions, null, sourceId);
        this.updatePaymentRequest();
        this.handleSubmit('order', response, wizard);
      } else {
        // console.log('AddCoupon else');
        this.updatePaymentRequest();
        if (forceNow) {
          this.handleConfirmPurchase();
        } else {
          wizard.next();
        }
      }
    } catch (error) {
      toastManager.add(error.message, { appearance: 'error' });
    }
  };

  handleConfirmPurchase = async () => {
    const { order, selectedBiomarkers, referralBenefits } = this.state;
    const { mustPayOnActivationKit, toastManager } = this.props;

    this.setState({ loading: true });

    try {
      const vars = {
        id: order.id,
      };
      if (referralBenefits && referralBenefits.referralCoupon) {
        vars.referralBenefitsCoupon = referralBenefits.referralCoupon;

        vars.referralBenefitsCentisizedBalance =
          referralBenefits.centisizedBalance;
      }

      await this.props.client.mutate({
        mutation: COMPLETE_ORDER,
        variables: vars,
        refetchQueries: [{ query: LABS }],
        awaitRefetchQueries: true,
      });

      // await this.props.refetch();

      let discount = 0;
      let couponCode = '';
      const opts = {
        cost:
          order && order.amount
            ? parseFloat((order.amount / 100).toFixed(2))
            : 0,
        biomarkers: selectedBiomarkers.join(', '),
      };
      for (let index = 0; index < order.items.length; index++) {
        const element = order.items[index];
        if (element.amount && element.amount < 0) {
          discount += element.amount;
          opts[element.parent] = (element.amount / 100).toFixed(2);
          couponCode = element.parent;
        }
      }

      if (mustPayOnActivationKit) {
        this.props.analytics.track('Kit Activated', { ...opts });
        this.props.analytics.logRevenue(
          parseFloat(((order.amount + discount * -1) / 100).toFixed(2)),
          opts.cost,
          parseFloat((discount / 100).toFixed(2)),
          couponCode,
          [
            {
              productId: 'kit',
              name: 'Thyroid Test Kit',
              price: opts.cost,
              quantity: 1,
              category: 'kit',
            },
          ],
          order.id
        );
      } else {
        this.props.analytics.track('Biomarkers Purchased', { ...opts });
        this.props.analytics.logRevenue(
          parseFloat(((order.amount + discount * -1) / 100).toFixed(2)),
          opts.cost,
          parseFloat((discount / 100).toFixed(2)),
          couponCode,
          [
            {
              productId: 'kit',
              name: 'Add Biomarkers',
              price: opts.cost,
              quantity: 1,
              category: 'kit',
            },
          ],
          order.id
        );
      }

      this.registerKit();

      //   this.props.history.push('/register-kit-payment/complete');
    } catch (err) {
      this.setState({ loading: false, ready: true });
      console.log('Error purchase', err);
      toastManager.add('Error confirming purchase, please try again', {
        appearance: 'error',
      });
    }
  };

  handleConfirmPurchaseNoCoupon = async wizard => {
    const { order, selectedBiomarkers, referralBenefits } = this.state;
    const { toastManager } = this.props;

    this.setState({ loading: true });

    try {
      const vars = {
        id: order.id,
      };
      if (referralBenefits && referralBenefits.referralCoupon) {
        vars.referralBenefitsCoupon = referralBenefits.referralCoupon;

        vars.referralBenefitsCentisizedBalance =
          referralBenefits.centisizedBalance;
      }
      await this.props.client.mutate({
        mutation: COMPLETE_ORDER,
        variables: vars,
      });

      let discount = 0;
      let couponCode = '';
      const opts = {
        cost:
          order && order.amount
            ? parseFloat((order.amount / 100).toFixed(2))
            : 0,
        biomarkers: selectedBiomarkers.join(', '),
      };
      for (let index = 0; index < order.items.length; index++) {
        const element = order.items[index];
        if (element.amount && element.amount < 0) {
          discount += element.amount;
          opts[element.parent] = (element.amount / 100).toFixed(2);
          couponCode = element.parent;
        }
      }

      this.props.analytics.track('Biomarkers Purchased', { ...opts });
      this.props.analytics.logRevenue(
        parseFloat(((order.amount + discount * -1) / 100).toFixed(2)),
        opts.cost,
        parseFloat((discount / 100).toFixed(2)),
        couponCode,
        [
          {
            productId: 'kit',
            name: 'Add Biomarkers',
            price: opts.cost,
            quantity: 1,
            category: 'kit',
          },
        ],
        order.id
      );
      this.registerKit();
    } catch (err) {
      this.setState({ loading: false, ready: true });
      toastManager.add('Error confirming purchase, please try again', {
        appearance: 'error',
      });
    }
  };

  handleSubmitGiveToFriend = async (key, values, wizard) => {
    this.setState({ [key]: { ...values } });

    if (wizard) {
      wizard.next();
    }
  };

  registerNoBiomarkers = async () => {
    const { client, toastManager, serial } = this.props;
    this.setState({ loading: true });
    try {
      await client.mutate({
        mutation: REGISTER_KIT,
        variables: {
          serial: serial,
        },
        refetchQueries: [{ query: LABS }],
        awaitRefetchQueries: true,
      });

      const opts = {
        serial: serial,
      };
      this.props.analytics.track('Kit Registered', { ...opts });
      this.setState(
        {
          // ...initialState,
          loading: false,
          ready: true,
        },
        () => {
          this.props.history.push('/register-kit-addons/complete');
        }
      );
    } catch (error) {
      this.setState({
        // ...initialState,
        loading: false,
        ready: true,
      });
      //   actions.setSubmitting(false);

      if (!error.graphQLErrors) {
        toastManager.add(error.message || 'Unable to register the kit', {
          appearance: 'error',
        });
      }

      error.graphQLErrors.forEach(error => {
        toastManager.add(error.message, {
          appearance: 'error',
        });
      });
    }
  };

  registerKit = async actions => {
    const { selectedBiomarkers } = this.state;
    const { client, toastManager, serial } = this.props;

    try {
      await client.mutate({
        mutation: REGISTER_KIT,
        variables: {
          serial: serial,
          biomarkers: selectedBiomarkers,
        },
        refetchQueries: [{ query: LABS }],
        awaitRefetchQueries: true,
      });

      const opts = {
        serial: serial,
      };
      this.props.analytics.track('Kit Registered', { ...opts });
      this.setState(
        {
          // ...initialState,
          loading: false,
          ready: true,
        },
        () => {
          this.props.history.push('/register-kit-addons/complete');
        }
      );
    } catch (error) {
      this.setState({
        // ...initialState,
        loading: false,
        ready: true,
      });
      //   actions.setSubmitting(false);

      if (!error.graphQLErrors) {
        toastManager.add(error.message || 'Unable to register the kit', {
          appearance: 'error',
        });
      }

      error.graphQLErrors.forEach(error => {
        toastManager.add(error.message, {
          appearance: 'error',
        });
      });
    }
  };

  continuePayWithWallet = async (actions, wizard, sourceId) => {
    const { address, order } = this.state;
    if (!order.id) {
      const response = await this.createOrder(actions, address, sourceId);
      this.setState(
        {
          isPaymentThroughWallet: true,
        },
        () => this.handleSubmit('order', response, wizard)
      );
    } else {
      wizard.next();
    }
  };

  render() {
    const { history, refetch, session,serial } = this.props;
    const {
      order,
      ready,
      loading,
      coupon,
      invalidSerial,
      isCouponBOGOdisabledModal,
      canMakePayment,
      paymentRequest,
      isPaymentThroughWallet,
      selectedBiomarkers,
      referralBenefits,
      alreadyBiomarkers,
    } = this.state;

    if (!ready || loading) {
      return (
        <LoadingScreen
          layoutStyle={{
            zIndex: 11,
          }}
          text="Please Wait"
          loading={true}
          bg="#fafafa"
          color="#344f79"
          showLogo={false}
        />
      );
    }

    if (invalidSerial) {
      return (
        <Fragment>
          <Container flex={1} px={3}>
            <Box mt={3} flex={1} flexDirection="column">
              <Card p={4}>
                <Flex flexDirection="column">
                  <Heading textAlign="center" fontWeight={500} mb={4}>
                    Invalid Serial
                  </Heading>

                  <Text textAlign="center" mb={4}>
                    {invalidSerial}
                  </Text>

                  <Flex justifyContent="center">
                    <Button
                      variant="pink"
                      width={[1 / 2, 1 / 3]}
                      onClick={() => history.push('/register-kit')}
                    >
                      Go Back
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </Box>
          </Container>
        </Fragment>
      );
    }

    const stepsVal = [
      {
        comp: (
          <Step id="additionalBiomarkers" key="additionalBiomarkers">
            <AdditionalBiomarkers
              alreadyBiomarkers={alreadyBiomarkers}
              selectedBiomarkers={selectedBiomarkers || []}
              isComputingBiomarker={false}
              addItemToOrder={itm => {
                const bms = [...(selectedBiomarkers || []), itm];
                this.setState({
                  selectedBiomarkers: bms,
                });
              }}
              onSkip={async wizard => {
                if (wizard && !!this.props.mustPayOnActivationKit) {
                  await this.createOrder();
                  wizard.next();
                } else {
                  await this.registerNoBiomarkers();
                }
              }}
              removeItemFromOrder={itm => {
                const bms = selectedBiomarkers.filter(x => x !== itm);

                this.setState({
                  selectedBiomarkers: bms,
                });
              }}
              onNext={async wizard => {
                if (wizard) {
                  await this.createOrder();
                  wizard.next();
                }
              }}
            />
          </Step>
        ),
        key: 'additionalBiomarkers',
        display: serial.indexOf('723-')!==0,
      },
      {
        comp: (
          <Step id="payment" key="payment">
            <Payment
              onSubmit={this.addCoupon}
              defaultCard={session.card}
              setSourceId={sourceId => {
                this.setState({ sourceId });
              }}
              onPayNow={this.handlePaymentNow}
              // onPayNow={() => console.log('AA')}
              // onPayNow={()=>console.log('AA')}
              canMakePayment={false}
              canMakePaymentApplePay={false}
              continuePayWithWallet={this.continuePayWithWallet}
              // continuePayWithWallet={() => console.log('AA')}
            />
          </Step>
        ),
        key: 'payment',
        display: true,
      },
      {
        comp: (
          <Step id="coupon" key="coupon">
            {/* <Elements> */}
            <ConfirmationWithCoupon
              isPaymentThroughWallet={isPaymentThroughWallet}
              canMakePayment={canMakePayment}
              paymentRequest={paymentRequest}
              bogoInvalidCoupon={() => {
                this.setState({
                  isCouponBOGOdisabledModal: true,
                });
              }}
              referralBenefits={referralBenefits}
              order={order}
              refetch={refetch}
              source={session.card}
              onSubmit={this.handleSubmit}
              onConfirmPurchase={this.handleConfirmPurchase}
              couponState={coupon}
              session={session}
              setCouponState={val => this.setState({ coupon: val })}
            />
            {/* </Elements> */}
          </Step>
        ),
        key: 'coupon',
        display: true,
      },
      {
        comp: (
          <Step id="complete" key="complete">
            <Box flex={1} flexDirection="column">
              <Card p={4}>
                <Flex flexDirection="column">
                  <Heading
                    textAlign="center"
                    style={{ fontWeight: 'bold' }}
                    mb={4}
                  >
                    How to collect your sample
                  </Heading>

                  <Video
                    title="vimeo-player"
                    src="https://player.vimeo.com/video/351018081"
                    frameBorder="0"
                    allowFullScreen
                  />

                  <Flex justifyContent="center">
                    <Button
                      variant="pink"
                      width={[1 / 2, 1 / 3]}
                      onClick={() => history.push('/')}
                    >
                      Your Dashboard
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </Box>
          </Step>
        ),
        key: 'complete',
        display: true,
      },
    ];

    let title = 'Choose Payment Method';

    // if (selectedBiomarkers && selectedBiomarkers.length > 0) {
    //   if (selectedBiomarkers.length === 1) {
    //     switch (selectedBiomarkers[0]) {
    //       case window._env_.REACT_APP_REVERSET3_SKU:
    //         title = 'Reverse T3 Add-on';
    //         break;
    //       default:
    //         title = 'Vitamin D Add-on';
    //         break;
    //     }
    //   } else {
    //     title = 'rT3 + Vitamin D Add-on';
    //   }
    // }

    return (
      <Fragment>
        <Container
          flex={1}
          px={3}
          style={{
            maxWidth:
              history.location.pathname !==
              '/register-kit-addons/additionalBiomarkers'
                ? '1024px'
                : '750px',
          }}
        >
          <Wizard
            history={history}
            basename="/register-kit-addons"
            render={({ step, ...p }, a) => {
              return (
                <>
                  <Flex flex={1} textAlign="center" flexDirection="column">
                    <Text
                      fontSize="38px"
                      fontWeight={600}
                      textAlign="center"
                      mb={2}
                      style={{
                        fontFamily: 'Playfair Display',
                        margin: '24px auto',
                      }}
                      color="#344f79"
                    >
                      {step.id === 'complete'
                        ? 'Your kit is activated'
                        : step.id === 'additionalBiomarkers'
                          ? 'Last chance to upgrade your kit'
                          : title || 'Kit Add-ons'}
                    </Text>
                    {step.id !== 'payment' &&
                      step.id !== 'coupon' && (
                        <Text
                          fontSize="18px"
                          fontWeight={400}
                          textAlign="center"
                          mb={3}
                          color="#344f79"
                        >
                          {step.id === 'complete'
                            ? 'You can now collect your sample and send it back to our lab for processing. Once our lab get your sample, your results should be available within 5 business days.'
                            : 'Upgrade your kit to also have your Vitamin D and/or Reverse T3 tested. Once your kit is activated you won’t be able to add those.'}
                        </Text>
                      )}
                  </Flex>
                  <Flex flexDirection={['column', 'column', 'row']}>
                    <Flex
                      flex={1}
                      mr={[
                        0,
                        0,
                        history.location.pathname !==
                        '/register-kit-addons/additionalBiomarkers'
                          ? 4
                          : 0,
                      ]}
                      mb={4}
                    >
                      <Steps>
                        {stepsVal.filter(x => x.display).map(x => x.comp)}
                      </Steps>
                    </Flex>
                    {history.location.pathname !==
                      '/register-kit-addons/additionalBiomarkers' &&
                      history.location.pathname !==
                        '/register-kit-addons/complete' &&
                      history.location.pathname !==
                        '/register-kit-addons/paymentReceived' && (
                        <Flex
                          width={[1, 1, 1 / 3]}
                          mb={[4, 4, 0]}
                          flexDirection="column"
                        >
                          <Box>
                            <Card p={4}>
                              <Flex flexDirection="column">
                                <Flex mb={3} justifyContent="center">
                                  <img
                                    src={kitIcon}
                                    alt=""
                                    width="200px"
                                    height="200px"
                                  />
                                </Flex>

                                <Flex flex={1} mb={3} flexDirection="column">
                                  {order && order.items
                                    ? order.items.map((item, i) => (
                                        <Flex flex={1} key={i}>
                                          <Flex flex={1}>
                                            <Text fontWeight={300}>
                                              {item.description}
                                            </Text>
                                          </Flex>
                                          <Flex justifyContent="flex-end">
                                            ${(item.amount / 100).toFixed(2)}
                                          </Flex>
                                        </Flex>
                                      ))
                                    : null}
                                  <Text
                                    fontSize={3}
                                    fontWeight={500}
                                    textAlign="center"
                                    mt={3}
                                  >
                                    {order.items.length > 0
                                      ? 'Total'
                                      : 'Complete Thyroid Test'}{' '}
                                    $
                                    {order.amount
                                      ? (order.amount / 100).toFixed(2)
                                      : '0.00'}
                                  </Text>
                                </Flex>

                                <Text fontWeight={300} textAlign="center">
                                  Get your thyroid levels updated
                                </Text>

                                <StyledLink
                                  mt={3}
                                  target="_blank"
                                  href="https://palomahealth.com/kit"
                                >
                                  Learn More
                                </StyledLink>
                              </Flex>
                            </Card>
                          </Box>
                        </Flex>
                      )}
                  </Flex>
                </>
              );
            }}
          />
          <Fragment>
            <ConfirmModal
              confirmLabel="OK"
              isOpen={isCouponBOGOdisabledModal}
              okOnly
              onClose={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
              onConfirm={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
              onBackgroundClick={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
              onEscapeKeydown={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
            >
              <ModalInner>
                <Heading mb={4} mx={3} textAlign="center">
                  January is National Thyroid Awareness Month – Buy a Kit, Give
                  a Kit FREE
                </Heading>
                <InfoBox bg="#D9E6F9" mb={4}>
                  <Text fontSize={3} fontWeight={700} textAlign="center">
                    Paloma is committed to elevating and spreading access to
                    great thyroid care. In the spirit of Thyroid Awareness
                    Month, we are giving a free kit for every kit purchased.
                    Instead of using a promo code, in January only, Paloma is
                    providing the ability to gift a friend or loved one a free
                    thyroid test to.
                  </Text>
                </InfoBox>
              </ModalInner>
            </ConfirmModal>
          </Fragment>
        </Container>
      </Fragment>
    );
  }
}

export default injectStripe(
  compose(
    withAnalytics,
    withApollo,
    withRouter,
    withSession,
    withToastManager
  )(RegisterKitAdditionalBiomarkersComponent)
);
