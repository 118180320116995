import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading, Text } from 'rebass';
import { Formik, Form } from 'formik';
import { Query, compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withWizard } from 'react-albus';
import { withToastManager } from 'react-toast-notifications';
import { PaymentRequestButtonElement } from 'react-stripe-elements';
import { debounce } from 'lodash';

import { TextInput } from '../../components/TextInput';
import { FormField } from '../../components/FormField';

import { APPLY_COUPON, CURRENT_USER } from '../../graphql';
import { checkBlackListed, BlackListedCouponsOnBogo } from '../../utils/blacklistedCoupons';
import { isToggleActive } from '../../components/featureToggle/toggles';
import SpinLoader from '../../components/SpinLoader';

const Confirmation = (props) => {
  const {
    address,
    client,
    source,
    order,
    onSubmit,
    toastManager,
    onConfirmPurchase,
    wizard,
    couponState,
    setCouponState,
    bogoInvalidCoupon,
    isPaymentThroughWallet,
    paymentRequest,
    referralBenefits,
    session,
  } = props;
  const delayedonConfirmPurchase = debounce((q) => onConfirmPurchase(q), 300);

  if (!order.id) {
    wizard.push('address');
  }

  const handleCoupon = async (coupon, actions) => {
    if (!coupon) return;

    if (isToggleActive('BUY_ONE_GIVE_ONE_KIT')) {
      const isBlackListedCoupon = checkBlackListed(BlackListedCouponsOnBogo, coupon || '');
      if (isBlackListedCoupon) {
        bogoInvalidCoupon();

        return;
      }
    }

    try {
      const { data } = await client.mutate({
        mutation: APPLY_COUPON,
        variables: {
          id: order.id,
          coupon,
        },
      });

      actions.resetForm();
      onSubmit('order', { id: order.id, ...data.applyCoupon });
      setCouponState(coupon);
      toastManager.add('Applied coupon successfully', {
        appearance: 'success',
      });
    } catch (error) {
      try {
        const err = JSON.parse(JSON.stringify(error));
        toastManager.add(err.graphQLErrors[0].message || 'Unable to apply coupon', {
          appearance: 'error',
        });
      } catch (error2) {
        actions.setSubmitting(false);
        toastManager.add('Unable to apply coupon', { appearance: 'error' });
      }
      actions.resetForm();
    }
  };

  return (
    <Fragment>
      <Flex flexDirection="column" flex={1} mb={[4, 4, 5]}>
        {/* <Card p={4} mb={4}> */}
          {couponState ? (
        <Card p={4} mb={4}>
            <>
              <Heading textAlign="center" style={{ fontWeight: 'bold' }} mb={4}>
                Coupon Code Added!
              </Heading>
              <Text fontSize="22px" textAlign="center" mb={3}>
                {couponState}
              </Text>
            </>
            </Card>
          ) 
          : referralBenefits && (referralBenefits.centisizedBalance || 0) > 0 ? (
        <Card p={4} mb={4}>
            <>
              <Heading textAlign="center" style={{ fontWeight: 'bold' }} mb={4}>
                Your credits will be used on this purchase
              </Heading>
              <Text fontSize="16px" style={{ fontFamily: 'Poppins', fontWeight: 500 }} textAlign="center" mb={3}>
                {`$${((referralBenefits.centisizedBalance || 0) / 100).toFixed(2)}`}
              </Text>
            </>
          </Card>
          ) 
          : (
            <>
              {!session || !session.isMember ? (
        <Card p={4} mb={4}>
                <Formik
                  initialValues={{ coupon: '' }}
                  onSubmit={(values, actions) => {
                    if ((order.amount || 0) / 100 < 100 && values.coupon === 'THYROIDMAMA') {
                      toastManager.add('To use this coupon code, you need to add at least one add-on to your purchase.', { appearance: 'error' });
                      actions.resetForm();
                      return;
                    }
                    handleCoupon(values.coupon, actions);
                  }}
                  render={({ isValid, errors, touched, isSubmitting }) => (
                    <Form>
                      <Flex flexDirection="column">
                        <Heading textAlign="center" style={{ fontWeight: 'bold' }} mb={4}>
                          Apply a coupon code!
                        </Heading>

                        <FormField error={touched.coupon && errors.coupon} mb={4}>
                          <TextInput placeholder="Coupon code" type="text" name="coupon" />
                        </FormField>

                        <Flex justifyContent="center">
                          <Button disabled={!isValid || isSubmitting} variant="pink" type="submit" width={[1 / 2, 1 / 3]}>
                            Apply
                          </Button>
                        </Flex>
                      </Flex>
                    </Form>
                  )}
                /></Card>
              ) : null}
            </>
          )}
        {/* </Card> */}

        <Card p={4}>
          <Flex flexDirection="column">
            <Heading textAlign="center" style={{ fontWeight: 'bold' }} mb={4}>
              Review Your Order
            </Heading>

            <Flex flexDirection={['column', 'column', 'row']} alignItems="flex-start">
              <Flex flex={1} flexDirection="column" mr={[0, 0, 4]} mb={4}>
                <Text fontWeight={600} mb={3}>
                  Ship To
                </Text>
                <Text>
                  {address.firstName} {address.lastName}
                </Text>
                <Text>{address.street1}</Text>
                <Text>{address.street2}</Text>
                <Text>
                  {address.city}, {address.state} {address.zip}
                </Text>
              </Flex>
              <Flex flex={1} flexDirection="column" mb={4}>
                <Query query={CURRENT_USER} fetchPolicy={'network-only'}>
                  {({ loading, error, data }) => {
                    // console.log('ConsultationFutureAdmin DATA', data);
                    if (loading) {
                      return (
                        <Flex flex={1} justifyContent="center" alignItems="center">
                          <SpinLoader />
                        </Flex>
                      );
                    }
                    if (error) {
                      return <Flex flex={1} justifyContent="center" alignItems="center" />;
                    }
                    return (
                      <Fragment>
                        <Text fontWeight={600} mb={3}>
                          Payment Method
                        </Text>
                        <Text>
                          {data.currentUser.card.brand ? data.currentUser.card.brand.toUpperCase() : ''} ****
                          {data.currentUser.card.last4}
                        </Text>
                        <Text>
                          Expires {data.currentUser.card.expMonth} / {data.currentUser.card.expYear}
                        </Text>
                      </Fragment>
                    );
                  }}
                </Query>
                {/* {source && (
                  <Fragment>
                    <Text fontWeight={600} mb={3}>
                      Payment Method
                    </Text>
                    <Text>
                      {source.brand ? source.brand.toUpperCase() : ''} ****
                      {source.last4}
                    </Text>
                    <Text>
                      Expires {source.expMonth} / {source.expYear}
                    </Text>
                  </Fragment>
                )} */}
              </Flex>
            </Flex>
          </Flex>

          {isPaymentThroughWallet ? (
            <PaymentRequestButtonElement
              paymentRequest={paymentRequest}
              className="PaymentRequestButton"
              value="Pay Now"
              style={{
                // For more details on how to style the Payment Request Button, see:
                // https://stripe.com/docs/elements/payment-request-button#styling-the-element
                paymentRequestButton: {
                  theme: 'dark',
                  height: '48px',
                  width: '100%',
                  borderRadius: '20px',
                  marginBottom: '16px',
                  flex: 1,
                  minWidth: '300px',
                },
              }}
            />
          ) : (
            <Flex justifyContent="center" pt={3}>
              <Button variant="pink" width={[1 / 2, 1 / 3]} onClick={delayedonConfirmPurchase}>
                Purchase
              </Button>
            </Flex>
          )}
        </Card>
      </Flex>
    </Fragment>
  );
};

export default compose(
  withApollo,
  withRouter,
  withToastManager,
  withWizard
)(Confirmation);
