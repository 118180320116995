import React, { useState } from 'react';
import { Flex, Heading, Text, Box } from 'rebass';
import RichTextEditor from 'react-rte';
import Select from 'react-select';
import TextInput, { Input } from '../../../components/TextInput';
import StarRatings from '../../../components/ratingStar/star-rating';
import { Form, Formik } from 'formik';
import FormField from '../../../components/FormField';
import * as Yup from 'yup';
import { EditModalButtons } from './Builder';
import { parseStringify } from '../../../utils/helpers';

const PreviewInput = ({ properties, name, touched, errors }, props) => {
  return (
    <Flex flex={1}>
      <Flex flexDirection={'column'} flex={1 / 4}>
        <Text onClick={() => {}} fontWeight={500}>
          {`${((properties || {}).params || {}).question || 'Question'}${(((properties || {}).params || {}).ctrlProps || {}).required ? '*' : ''}`}
        </Text>
        <Text fontSize={'12px'} fontWeight={300}>
          {((properties || {}).params || {}).description || 'Description'}
        </Text>
      </Flex>
      <FormField flex={1} mb={0} error={touched[name] && errors[name]}>
        <TextInput name={name} {...((properties || {}).params || {}).ctrlProps} {...props} style={{ height: 'fit-content' }} />
      </FormField>
    </Flex>
  );
};

const PreviewHeadingCtrl = ({ properties }) => {
  return (
    <>
      <Heading>{`${((properties || {}).params || {}).question || 'Title'}`}</Heading>
    </>
  );
};

const PreviewCheckBoxesCtrl = ({ properties, touched, errors, values, setFieldValue }) => {
  return (
    <Flex flex={1}>
      <Flex flexDirection={'column'} flex={1 / 4}>
        <Text onClick={() => {}} fontWeight={500}>
          {`${((properties || {}).params || {}).question || 'Question'}${(((properties || {}).params || {}).ctrlProps || {}).required ? '*' : ''}`}
        </Text>
        <Text fontSize={'12px'} fontWeight={300}>
          {((properties || {}).params || {}).description || 'Description'}
        </Text>
      </Flex>
      <Flex flex={1} justifyContent={'flex-start'} flexDirection={'column'}>
        {((((properties || {}).params || {}).ctrlProps || {}).options || []).map((x) => {
          return (
            <Flex mb={2}>
              <Input
                type="checkbox"
                checked={values[`${properties.id}__${x.value}`]}
                onClick={(e) => setFieldValue(`${properties.id}__${x.value}`, e.target.checked)}
                id={`${properties.id}_${x.value}`}
                name={`${properties.id}_${x.value}`}
              />
              <label htmlFor={`${properties.id}__${x.value}`} style={{ marginLeft: '16px' }}>
                {x.label}
              </label>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

const PreviewLineBreakCtrl = ({ properties }) => {
  return (
    <>
      <Flex style={{ width: '100%', height: '2px', backgroundColor: '#344f79' }} />
    </>
  );
};

const PreviewRangeCtrl = ({ properties, name, setFieldValue, values }) => {
  return (
    <Flex flex={1}>
      <Flex flexDirection={'column'} flex={1 / 4}>
        <Text onClick={() => {}} fontWeight={500}>
          {`${((properties || {}).params || {}).question || 'Question'}${(((properties || {}).params || {}).ctrlProps || {}).required ? '*' : ''}`}
        </Text>
        <Text fontSize={'12px'} fontWeight={300}>
          {((properties || {}).params || {}).description || 'Description'}
        </Text>
      </Flex>
      <Flex flexDirection={'row'} justifyContent={'center'}>
        <input
          {...((properties || {}).params || {}).ctrlProps}
          value={values[name]}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
        />
        <Text ml={3} alignSelf={'center'}>
          {values[name]}
        </Text>
      </Flex>
    </Flex>
  );
};
const PreviewTextAreaCtrl = ({ properties, values, setFieldValue, name }) => {
  const [valu, setValu] = useState(RichTextEditor.createEmptyValue());
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' }, { label: 'Italic', style: 'ITALIC' }, { label: 'Underline', style: 'UNDERLINE' }],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' },
    ],
    BLOCK_TYPE_BUTTONS: [{ label: 'UL', style: 'unordered-list-item' }, { label: 'OL', style: 'ordered-list-item' }],
  };
  return (
    <Flex flex={1}>
      <Flex flexDirection={'column'} flex={1 / 4}>
        <Text onClick={() => {}} fontWeight={500}>
          {`${((properties || {}).params || {}).question || 'Question'}${(((properties || {}).params || {}).ctrlProps || {}).required ? '*' : ''}`}
        </Text>
        <Text fontSize={'12px'} fontWeight={300}>
          {((properties || {}).params || {}).description || 'Description'}
        </Text>
      </Flex>
      <Flex flex={1} height={'200px'}>
        <RichTextEditor rootStyle={{ width: '100%', flex: 1, height: '200px' }} toolbarConfig={toolbarConfig} value={values[name]} onChange={(e) => setFieldValue(name, e)} />
      </Flex>
    </Flex>
  );
};

const PreviewDropdownCtrl = ({ properties, values, setFieldValue, name, touched, errors, setFieldTouched }) => {
  return (
    <Flex flex={1}>
      <Flex flexDirection={'column'} flex={1 / 4}>
        <Text onClick={() => {}} fontWeight={500}>
          {`${((properties || {}).params || {}).question || 'Question'}${(((properties || {}).params || {}).ctrlProps || {}).required ? '*' : ''}`}
        </Text>
        <Text fontSize={'12px'} fontWeight={300}>
          {((properties || {}).params || {}).description || 'Description'}
        </Text>
      </Flex>
      <Flex flex={1} justifyContent={'flex-start'}>
        <div style={{ maxWidth: '500px', alignSelf: 'center', width: '100%' }}>
          <FormField flex={1} mb={0} error={touched[name] && errors[name]}>
            <Select
              className="custom_zindex"
              classNamePrefix="region-select"
              {...((properties || {}).params || {}).ctrlProps}
              style={{
                minWidth: '400px',
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              onBlur={() => {
                if (!values[name]) setFieldValue(name, '');
                setFieldTouched(name, true);
              }}
              value={((((properties || {}).params || {}).ctrlProps || {}).options || []).find((x) => x.label === values[name])}
              onChange={(e) => setFieldValue(name, e.label)}
              noOptionsMessage={() => (
                <div style={{ padding: '12px 24px' }}>
                  <Text textAlign="left" mb={2} fontSize="16px" fontWeight="400">
                    No options set
                  </Text>
                </div>
              )}
              theme={(base) => ({
                ...base,
                colors: {
                  ...base.colors,
                  primary: '#364f79',
                  primary50: '#dae6fa',
                },
              })}
            />
          </FormField>
        </div>
      </Flex>
    </Flex>
  );
};
const PreviewLabelCtrl = ({ properties }) => {
  console.log({ properties });
  return (
    <>
      <Text fontWeight={500}>{`${((properties || {}).params || {}).question || 'Section'}`}</Text>
    </>
  );
};
const PreviewRatingCtrl = ({ properties, values, setFieldValue, name, setFieldTouched }) => {
  return (
    <Flex flex={1}>
      <Flex flexDirection={'column'} flex={1 / 4}>
        <Text onClick={() => {}} fontWeight={500}>
          {`${((properties || {}).params || {}).question || 'Question'}${(((properties || {}).params || {}).ctrlProps || {}).required ? '*' : ''}`}
        </Text>
        <Text fontSize={'12px'} fontWeight={300}>
          {((properties || {}).params || {}).description || 'Description'}
        </Text>
      </Flex>
      <Flex flex={1} height={'200px'}>
        <StarRatings starEmptyColor="white" starSpacing="0px" starRatedColor="#f9a394" rating={values[name]} numberOfStars={5} changeRating={(e) => setFieldValue(name, e)} starDimension="18px" />
      </Flex>
    </Flex>
  );
};
const PreviewParagraphCtrl = ({ properties }) => {
  console.log({ properties });
  return (
    <>
      <Text fontWeight={300}>{`${((properties || {}).params || {}).question || 'Paragraph'}`}</Text>
    </>
  );
};

const getInitialData = (tree) => {
  console.log({ tree });
  const data = {};

  for (let index = 0; index < tree.length; index++) {
    const element = tree[index];
    if (element.key === 'LineBreak' || element.key === 'Header' || element.key === 'Label' || element.key === 'Paragraph') continue;
    if (element.key === 'TextInput' || element.key === 'Email' || element.key === 'Date') {
      data[element.id] = '';
      continue;
    }
    if (element.key === 'Range') {
      data[element.id] = ((element.params || {}).ctrlProps || {}).min || 0;
      continue;
    }
    if (element.key === 'Dropdown') {
      data[element.id] = '';
      continue;
    }
    if (element.key === 'Rating') {
      data[element.id] = 0;
      continue;
    }
    if (element.key === 'NumberInput') {
      data[element.id] = null;
      continue;
    }
    if (element.key === 'TextArea') {
      data[element.id] = RichTextEditor.createEmptyValue(); //.createValueFromString(`<p>aaa <strong>aaa</strong> aaa</p>`,'html')
      continue;
    }

    if (element.key === 'Checkboxes') {
      for (let i2 = 0; i2 < (((element.params || {}).ctrlProps || {}).options || []).length; i2++) {
        const e2 = (((element.params || {}).ctrlProps || {}).options || [])[i2];
        data[`${element.id}__${e2.value}`] = e2.isChecked || false;
      }
    }
  }

  console.log({ data });
  return data;
};
const getSchema = (tree) => {
  let schema = {};

  for (let index = 0; index < tree.length; index++) {
    const element = tree[index];
    console.log('is required', ((element.params || {}).ctrlProps || {}).required)
    if (((element.params || {}).ctrlProps || {}).required) {
      if (element.key === 'Email') {
        schema[element.id] = Yup.string()
          .email('Invalid email')
          .required('Required');
      } else if (element.key === 'Date') {
        schema[element.id] = Yup.date('Invalid email').required('Required');
      } else {
        schema[element.id] = Yup.string()
          .trim()
          .min(2, 'Question must be greater than 1 character')
          .required('Required');
      }
    }
  }
  return Yup.object().shape({ ...schema });
};
export const PreviewHcForm = ({ meta, tree, close, save }) => {
  const [data, setData] = useState(getInitialData(tree));
  const [schema] = useState(getSchema(tree));
  const [outputResult, setOutputResult] = useState(null);
  console.log(schema);
  return (
    <Flex style={{}} flexDirection={'column'}>
      <Heading mt={4} mb={3}>
        {meta.name}
      </Heading>
      <Text mb={4}>{meta.description}</Text>
      <PreviewLineBreakCtrl />
      <Box mb={4} />
      {!outputResult ? (
        <Formik
          initialValues={{ ...getInitialData(tree) }}
          validationSchema={getSchema(tree)}
          isInitialValid={(aaa, bbb) => {
            try {
              const aa = schema.validateSync({ ...getInitialData(tree) });

              // const bb = await aa.isValid()
              if (aa.errors && aa.errors.length) {
                return false;
              }
              return true;
            } catch (err) {
              return false;
            }
          }}
          onSubmit={(values, actions) => {
            console.log({ values });
            let res = {};
            const resTree = parseStringify(tree);

            for (const key in values) {
              if (key.indexOf('Checkboxes_') > -1) {
                const sp = key.split('__');
                if (!!sp && sp.length > 1) {
                  const a2 = resTree.findIndex((x) => x.id === sp[0]);
                  if (!resTree[a2].values) {
                    resTree[a2].values = [];
                  }
                  resTree[a2].values.push({
                    [sp[1]]: values[key],
                  });
                }
                continue;
              }
              const a = resTree.findIndex((x) => x.id === key);

              if (key.indexOf('TextArea_') < 0) {
                res[key] = values[key];
                resTree[a].value = values[key];
              } else {
                const s = values[key].toString('html');
                res[key] = s;
                resTree[a].value = s;
              }
            }
            console.log({ resTree });
            setOutputResult(JSON.stringify(resTree, null, 4));
          }}
          render={({ touched, errors, isValid, values, submitForm, setFieldValue, setFieldTouched }) => {
            console.log({ errors, touched, values });
            return (
              <Form>
                <>
                  {tree.map((treeItem) => (
                    <Flex
                      width={'100%'}
                      style={{
                        padding: treeItem.key === 'Header' ? '12px 0 20px 0' : treeItem.key === 'Label' ? '18px 0 12px' : treeItem.key === 'LineBreak' ? '6px 0' : '6px 0 12px 32px',
                      }}
                    >
                      {treeItem.key === 'TextInput' || treeItem.key === 'NumberInput' || treeItem.key === 'Email' || treeItem.key === 'Date' ? (
                        <PreviewInput properties={treeItem} name={treeItem.id} touched={touched} errors={errors} />
                      ) : null}
                      {treeItem.key === 'LineBreak' ? <PreviewLineBreakCtrl properties={treeItem} /> : null}
                      {treeItem.key === 'Checkboxes' ? <PreviewCheckBoxesCtrl properties={treeItem} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} /> : null}
                      {treeItem.key === 'Header' ? <PreviewHeadingCtrl properties={treeItem} /> : null}
                      {treeItem.key === 'Range' ? <PreviewRangeCtrl name={treeItem.id} values={values} setFieldValue={setFieldValue} properties={treeItem} /> : null}
                      {treeItem.key === 'TextArea' ? <PreviewTextAreaCtrl name={treeItem.id} values={values} setFieldValue={setFieldValue} properties={treeItem} /> : null}
                      {treeItem.key === 'Dropdown' ? (
                        <PreviewDropdownCtrl
                          name={treeItem.id}
                          values={values}
                          setFieldTouched={setFieldTouched}
                          setFieldValue={setFieldValue}
                          properties={treeItem}
                          touched={touched}
                          errors={errors}
                        />
                      ) : null}
                      {treeItem.key === 'Label' ? <PreviewLabelCtrl properties={treeItem} /> : null}
                      {treeItem.key === 'Rating' ? <PreviewRatingCtrl name={treeItem.id} values={values} setFieldValue={setFieldValue} properties={treeItem} /> : null}
                      {treeItem.key === 'Paragraph' ? <PreviewParagraphCtrl properties={treeItem} /> : null}
                    </Flex>
                  ))}
                  <EditModalButtons save={submitForm} close={close} isValid={isValid} />
                </>
              </Form>
            );
          }}
        />
      ) : (
        <div><pre>{outputResult}</pre></div>
      )}
    </Flex>
  );
};
