export const intakeCommonTrunks = [
  {
    id: 'symptoms',
    section: 'symptoms',
    type: 'surveyNewSymptoms',
    endpoint: 'problems',
    relatedDataId: 'symptoms',
    prompt: 'Which of these symptoms have you experienced within the past 3 months?',
    onlyFirstTime: false,
    skip: 'great',
    options: [
      { prompt: 'Agitation', questionId: '274647009' },
      { prompt: 'Anxiety', questionId: '197480006' },
      { prompt: 'abdominal/pelvic Bloating', questionId: '116289008' },
      { prompt: 'puffy face or gags under the eyes', questionId: '278528006' },
      { prompt: 'irregular periods', questionId: '80182007' },
      { prompt: 'Decreased Energy', questionId: '248274002' },
      { prompt: 'Depression', questionId: '35489007' },
      { prompt: 'Poor Sleep', questionId: '193462001' },
      { prompt: 'Thinking is slow ', questionId: '26628009' },
      { prompt: 'Forgetful', questionId: '55533009' },
      { prompt: 'Abnormal uterine bleeding', questionId: '44991000119100' },
      { prompt: 'Hair Loss', questionId: '278040002' },
      { prompt: 'Hot Flashes', questionId: '198436008' },
      { prompt: 'Difficulty losing weight', questionId: '83421005' },
      { prompt: 'Joint stiffness and pain', questionId: '57676002' },
      { prompt: 'Mood Swings', questionId: '286641001' },
      { prompt: 'Night Sweats', questionId: '42984000' },
      { prompt: 'Palpitations', questionId: '80313002' },
      { prompt: 'Poor concentration', questionId: '26329005' },
      { prompt: 'Tender Breasts', questionId: '55222007' },
      { prompt: 'pain with intercourse', questionId: '71315007' },
      { prompt: 'Vaginal Dryness', questionId: '297147009' },
      { prompt: 'low sex drive', questionId: '8357008' },
      { prompt: 'Bowel movements less than daily or with difficulty', questionId: '14760008' },
      { prompt: 'Do not sweat as much, even when exercising', questionId: '45004005' },
      { prompt: 'Feel colder than most other people', questionId: '724233009' },
      { prompt: 'Headaches, often worse in the morning', questionId: '162310002' },
    ],
  },
  {
    id: 'symptomsNew',
    section: 'symptoms',
    type: 'survey',
    endpoint: 'problems',
    prompt: 'How bad were these symptoms?',
    onlyFirstTime: false,
  },
  {
    id: 'great',
    section: 'vitals',
    type: 'information',
    title: `You're almost done!`,
    status: 'vitals',
    skip: 'pharmacy',
    onlyFirstTime: false,
    text: ['We have a few more questions about your symptoms and lifestyle. Please take a moment to fill out the following questions, they will help us deliver the best care.'],
  },
  {
    id: 'general',
    section: 'vitals',
    type: 'input',
    endpoint: 'socialHistory',
    prompt: 'General Information',
    onlyFirstTime: false,
    defaults: { EMPLOYMENT: '', LOCAL_110: '' },
    options: [
      { questionId: 'EMPLOYMENT', type: 'text', placeholder: 'Profession' },
      {
        questionId: 'LOCAL_110',
        type: 'text',
        placeholder: 'Highest Education Level',
      },
    ],
  },
  {
    id: 'smoke',
    section: 'vitals',
    type: 'smokePick',
    endpoint: 'socialHistory',
    title: 'How many cigarettes do you smoke per day?',
    onlyFirstTime: false,
    questionId: 'LOCAL.92',
    options: [
      {
        isDefault: true,
        answer: '0',
        value: '0',
      },
      {
        answer: '1 to 5',
        value: '1-5',
      },
      {
        answer: '6 to 10',
        value: '6-10',
      },
      {
        answer: '11 to 20',
        value: '11-20',
      },
      {
        answer: 'more than 20',
        value: '20+',
      },
    ],
  },
  {
    id: 'alcohol',
    section: 'vitals',
    type: 'alcoholPick',
    endpoint: 'socialHistory',
    prompt: 'How much alcohol do you drink in a week ?',
    onlyFirstTime: false,
    questionId: 'LOCAL.94',
    options: [],
  },
  {
    id: 'drugs',
    section: 'vitals',
    type: 'input',
    endpoint: 'socialHistory',
    prompt: 'What kind of recreational drug have you used in the past year?',
    onlyFirstTime: false,
    defaults: { LOCAL_97: '' },
    extend: true,
    skip: 'caffeine',
    options: [
      {
        questionId: 'LOCAL_97',
        prefix: 'Recreational drugs used -',
        type: 'text',
        placeholder: 'What drugs do you use?',
      },
    ],
  },
  {
    id: 'caffeine',
    section: 'vitals',
    type: 'input',
    endpoint: 'socialHistory',
    prompt: 'How many caffeinated beverages or cups of coffee do you drink per day?',
    onlyFirstTime: false,
    defaults: { CAFFEINEPERDAY: '' },
    extend: false,
    options: [
      {
        questionId: 'CAFFEINEPERDAY',
        prefix: 'Cups per day - ',
        type: 'text',
        placeholder: 'Cups a day',
        inputmode: 'decimal',
      },
    ],
  },
  {
    id: 'cardio',
    section: 'vitals',
    type: 'smokePick',
    endpoint: 'socialHistory',
    title: 'How many days a week do you engage in moderate physical activities?',
    prompt: '(makes you slightly breathless)',
    onlyFirstTime: false,
    questionId: 'LOCAL.98',
    options: [
      {
        isDefault: true,
        answer: '0',
        value: '0',
      },
      {
        answer: '1',
        value: '1',
      },
      {
        answer: '2',
        value: '2',
      },
      {
        answer: '3',
        value: '3',
      },
      {
        answer: '4',
        value: '4',
      },
      {
        answer: '5',
        value: '5',
      },
      {
        answer: '6',
        value: '6',
      },
      {
        answer: '7',
        value: '7',
      },
    ],
  },
  {
    id: 'exerciseType',
    section: 'vitals',
    type: 'input',
    endpoint: 'socialHistory',
    prompt: 'What kind of exercises do you engage in?',
    onlyFirstTime: false,
    defaults: { LOCAL_99: '' },
    extend: true,
    skip: 'sleepMeds',
    options: [{ questionId: 'LOCAL_99', type: 'text', placeholder: 'Input exercises' }],
  },
  {
    id: 'sleepMeds',
    section: 'vitals',
    type: 'single',
    endpoint: 'socialHistory',
    prompt: 'Do you take medication/supplements to fall asleep?',
    onlyFirstTime: false,
    options: [
      {
        questionId: 'LOCAL.106',
        prefix: 'Medications for sleep -',
        answer: 'Y',
        value: 'Yes',
        placeholder: 'What do you take?',
      },
      { questionId: 'LOCAL.106', answer: 'N', value: 'No' },
    ],
  },
  {
    id: 'quality',
    section: 'vitals',
    type: 'input',
    endpoint: 'socialHistory',
    onlyFirstTime: false,
    prompt: 'How would you rate the overall quality of your sleep? (Out of 10, 1 being bad and 10 being great)',
    defaults: { LOCAL_108: '' },
    options: [{ questionId: 'LOCAL_108', type: 'text', placeholder: 'Overall quality', inputmode: 'decimal' }],
  },
  {
    id: 'hours',
    section: 'vitals',
    type: 'input',
    endpoint: 'socialHistory',
    prompt: 'How many hours of sleep do you get at night?',
    onlyFirstTime: false,
    defaults: { LOCAL_109: '' },
    options: [{ questionId: 'LOCAL_109', placeholder: 'Hours of sleep', inputmode: 'decimal' }],
  },
  {
    id: 'pharmacy',
    section: 'vitals',
    type: 'pharmacy',
    endpoint: 'pharmacy',
    onlyFirstTime: false,
  },
  {
    id: 'labCenter',
    section: 'vitals',
    type: 'labCenter',
    endpoint: 'labCenter',
    onlyFirstTime: false,
  },
  {
    id: 'finished',
    type: 'information',
    title: 'You Are All Set',
    onlyFirstTime: false,
    text: ['Your appointment is scheduled an you will receive an email with instructions on how to complete your online visit.'],
    buttonText: 'My Dashboard',
    status: 'completed',
    link: '/',
  },
  {
    id: 'disqualified',
    type: 'disqualified',
    status: 'disqualified',
    onlyFirstTime: false,
    link: '/',
  },
];
