import React from 'react';
import { withWizard } from 'react-albus';
import { Mutation, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { Button, Card, Heading, Text, Flex } from 'rebass';
import styled from 'styled-components';
import PleaseWait from '../../../../../components/PleaseWait';
import { APPLY_CANCELLATION_COUPON, GET_MEMBERSHIPS } from '../../../../../graphql';
import plant from '../../../../../static/plant.png';
import withAnalytics from '../../../../../lib/withAnalytics';

const A = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: #44a5ff;
  text-decoration: underline;
  margin: 36px auto 0 auto;
  cursor: pointer;
`;

const OfferWrapper = styled(Flex)`
  border-radius: 10px;
  background: #d9e6f8;
  flex-direction: column;
  padding: 30px 10px;
  margin-bottom: 18px;
`;

const Content = styled(Flex)`
  flex-direction: row;
`;

const OfferTextWrapper = styled(Flex)`
  flex-direction: column;
  margin-left: 16px;
  text-align: left;
`;

const GoingOtherCompany = ({ subscriptionId, wizard, history, subscriptionType, confirm, flow, reason, analytics }) => {
  let title = 'Unlock Exclusive 25% Savings on Your Renewal!';
  let description1 =
    'We understand that investing in a membership can feel substantial, especially as you start feeling healthier and more empowered. Your journey towards better health has always been our top priority.';
  let description2 = `To show our appreciation for your commitment and to support your continued wellness journey, we're excited to offer you an exclusive 25% discount on your membership renewal. This is our way of saying thank you for being a valued member of our community and to encourage your ongoing dedication to health and wellness.`;
  let buttonText = `Renew Now with 25% Off`;
  let retain = '25% off';
  if (flow === 'INSURANCE') {
    title = `Your Membership  On Us!`;
    description1 = `We've got great news! To support your wellness journey, we're waiving your next membership fee. That's right, your renewal is completely on us.`;
    description2 = `Stay with us, stay healthy, and enjoy your membership for free for a year!`;
    buttonText = `Renew for Free`;
    retain = '100% off';
  } else if (flow === 'SELFPAY_INSURANCE') {
    title = `Unlock Exclusive 50% Savings on Your Renewal!`;
    description2 = `To show our appreciation for your commitment and to support your continued wellness journey, we're excited to offer you an exclusive 50% discount on your membership renewal. This is our way of saying thank you for being a valued member of our community and to encourage your ongoing dedication to health and wellness.`;
    buttonText = `Renew Now with 50% Off`;
    retain = '50% off';
  }
  return (
    <Query query={GET_MEMBERSHIPS} fetchPolicy="network-only">
      {({ loading, data, error }) => {
        if (loading) return <PleaseWait style={{ position: 'absolute', left: '50%', top: '50%' }} />;
        const vitaminsData = data.getMemberships.memberships.find((x) => x.subscriptionTarget === 'SUBSCRIPTION_VITAMINS');
        let price = '';
        if (vitaminsData && vitaminsData.price) {
          price = ((vitaminsData.price / 100) * 0.75).toFixed(2);
        }
        return (
          <Mutation
            mutation={APPLY_CANCELLATION_COUPON}
            refetchQueries={[{ query: GET_MEMBERSHIPS }]}
            awaitRefetchQueries
            variables={{
              subscriptionId,
              subscriptionType,
              reason,
            }}
            onCompleted={() => {
              history.push({
                pathname: '/subscription-updated',
                state: {
                  title: 'Your discount has been applied!',
                  text: "Great news! Your membership continues with a special discount. We're thrilled to have you with us.",
                },
              });
            }}
          >
            {(applyCoupon, { loading }) => {
              return loading ? (
                <PleaseWait />
              ) : (
                <Flex flexDirection="column">
                  <Card
                    style={{
                      flexDirection: 'column',
                      textAlign: 'center',
                      padding: '32px',
                    }}
                  >
                    <Heading mb={4}>{title}</Heading>

                    <Flex
                      flexDirection="column"
                      style={{
                        margin: '0 auto',
                        display: 'table',
                      }}
                    >
                      <OfferWrapper >
                        <Content>
                          {/* <img src={plant} alt="" /> */}

                          <OfferTextWrapper>
                            <Text fontWeight="600">{description1}</Text>
                            <br />
                            <Text fontWeight="300">{description2}</Text>
                          </OfferTextWrapper>
                        </Content>
                        <Flex mt={4} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                          <Button variant="primary" minWidth="150px" 
                            onClick={() => {
                              analytics.track('membership retained', {
                                coupon: retain,
                                reason: 'Going to other company'
                              });
                              applyCoupon();
                            }}>
                            {buttonText}
                          </Button>

                          <A
                            onClick={() => {
                              confirm(wizard);
                            }}
                            style={{
                              marginTop: '24px',
                              padding: '6px',
                            }}
                          >
                            Cancel Membership
                          </A>
                        </Flex>
                      </OfferWrapper>
                    </Flex>
                  </Card>
                  <A
                    onClick={() => {
                      history.push('/');
                    }}
                  >
                    Back to Dashboard
                  </A>
                </Flex>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default withRouter(withWizard(withAnalytics(GoingOtherCompany)));
