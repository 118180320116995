import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
} from 'rebass';
import { Formik, Form } from 'formik';
import TextInput from '../components/TextInput';
import { FormField } from '../components/FormField';
import { ADMIN_DELETE_MOBILE_USER } from '../graphql';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;

class DeleteMobileUser extends Component {
  handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const res = await this.props.client.mutate({
        mutation: ADMIN_DELETE_MOBILE_USER,
        variables: {
          email: values.email,
        },
      });
      
      if (res && res.data && res.data.deleteMobileUser) {
        if (res.data.deleteMobileUser.ok === true) {
          actions.resetForm();
          this.props.toastManager.add('Updated successfully', {
            appearance: 'success',
          });
        } else {
          this.props.toastManager.add(
              'Error deleting',
            { appearance: 'error' }
          );
        }
      } else {
        actions.setSubmitting(false);
        this.props.toastManager.add('Error deleting, please try again', {
          appearance: 'error',
        });
      }

      actions.setSubmitting(false);
      //   toastManager.add('Error submitting intake form', { appearance: 'error' });
    } catch (err) {
      console.log('ADMIN_MAP_LOCALDB_ACCOUNT_TO_ATHENA_ID Err', err);
      actions.setSubmitting(false);
      this.props.toastManager.add('Error updating, please try again', {
        appearance: 'error',
      });
    }
  };
  
  render() {
    return (
      <Container>
        <Flex m={[3, 5]} flex={1} flexDirection="column">
          <Heading mb={4} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
            Delete Mobile User
          </Heading>
          <Card mb={4} p={[3, 4]}>
            <Flex flexDirection={'column'} flex={1} alignItems="center">
              <Formik
                initialValues={{ email: '' }}
                // validationSchema={schema}
                onSubmit={(values, actions) =>
                  this.handleSubmit(values, actions)
                }
                render={({
                  errors,
                  touched,
                  isValid,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  values,
                }) => (
                  <Form style={{ width: '300px' }}>
                    <Flex flexDirection="column" flex={1}>
                      <Heading mb={4}>User Email</Heading>
                    </Flex>
                    <FormField mb={4} error={touched.email && errors.email}>
                      <TextInput
                        placeholder="Email Address"
                        type="email"
                        name="email"
                      />
                    </FormField>

                    <Button
                      mt={4}
                      mb={4}
                      variant="primary"
                      width={1 / 2}
                      disabled={!isValid || isSubmitting}
                      type="submit"
                    >
                      Delete
                    </Button>
                  </Form>
                )}
              />
            </Flex>
          </Card>
        </Flex>
      </Container>
    );
  }
}

export default compose(
  withApollo,
  withToastManager
)(DeleteMobileUser);
