import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { withRouter } from 'react-router';
import { Button, Card, Heading, Text, Flex } from 'rebass';
import styled from 'styled-components';
import PleaseWait from '../../../../components/PleaseWait';
import { GET_MEMBERSHIPS, PAUSE_SUBSCRIPTION } from '../../../../graphql';
import withAnalytics from '../../../../lib/withAnalytics';

const A = styled(Text)`
  text-decoration: underline;
  margin-top: 28px;
  text-align: center;
  cursor: pointer;
`;

const Confirm = ({ subscriptionId, history, type, analytics }) => {
  const [pauseDuration, setPauseDuration] = useState(1);
  
  return (
    <Mutation
      mutation={PAUSE_SUBSCRIPTION}
      refetchQueries={[{ query: GET_MEMBERSHIPS }]}
      awaitRefetchQueries
      onCompleted={() => {
        analytics.track('pause subscription', {
          subscriptionType: type === 'subscription_vitamin' ? 'vitamins' : 'kit',
          duration: pauseDuration,
        });
        history.push({
          pathname: '/subscription-updated',
          state: {
            title: 'Your Subscription Has Been Paused',
            text:
              pauseDuration < 2
                ? 'Paused for a month'
                : `Paused for ${pauseDuration} months`,
          },
        });
      }}
    >
      {(pauseSubscription, { loading }) => {
        return loading ? (
          <PleaseWait />
        ) : (
          <Flex flexDirection="column">
            <Card
              style={{
                flexDirection: 'column',
                textAlign: 'center',
                padding: '32px',
              }}
            >
              <Heading>Let's Pause Your Vitamin Subscription</Heading>

              <Text
                textAlign="center"
                style={{ maxWidth: '400px', margin: '32px auto 0 auto' }}
              >
                Need a break? For how long do you want us to pause your
                subscription?
              </Text>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  style={{
                    marginTop: '28px',
                    width: 'auto',
                    maxWidth: '380px',
                  }}
                  variant="outline"
                  onClick={() => {
                    setPauseDuration(1);
                    pauseSubscription({
                      variables: {
                        subscriptionId,
                        monthsPause: 1,
                      },
                    });
                  }}
                >
                  1 month
                </Button>

                <Button
                  style={{ marginTop: '28px', width: 'auto', maxWidth: '80%' }}
                  variant="outline"
                  onClick={() => {
                    setPauseDuration(2);
                    pauseSubscription({
                      variables: {
                        subscriptionId,
                        monthsPause: 2,
                      },
                    });
                  }}
                >
                  2 months
                </Button>

                <Button
                  style={{ marginTop: '28px', width: 'auto', maxWidth: '80%' }}
                  variant="outline"
                  onClick={() => {
                    setPauseDuration(3);
                    pauseSubscription({
                      variables: {
                        subscriptionId,
                        monthsPause: 3,
                      },
                    });
                  }}
                >
                  3 months
                </Button>
              </Flex>
            </Card>
            <A onClick={() => history.push('/my-subscriptions')}>Go Back</A>
          </Flex>
        );
      }}
    </Mutation>
  );
};

export default withAnalytics(withRouter(Confirm));
