import React, { Component } from 'react';
import { Card, Flex, Box, Text } from 'rebass';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from 'recharts';

import Check from './icons/Check';
import Arrow from './icons/Arrow';
import Exclamation from './icons/Exclamation';
import Question from './icons/Question';

const Icon = styled(Flex)`
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const DetailBar = styled(Flex)`
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

class LabResult extends Component {
  state = {
    open: false,
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { data, hormoneName } = this.props;
    const { open } = this.state;
    const hormone = hormoneName[data.hormone];
    const isQns = data.value && data.value.toLowerCase() === 'qns';

    return (
      <Card p={3} mb={4}>
        <Flex flexDirection="column">
          <DetailBar m={2} onClick={this.handleClick}>
            <Icon mr={2} pt={1}>
              {isQns ? <Question width="30px" fill="#fbafbc" /> : data.abnormal ? <Exclamation width="30px" fill="#fbafbc" /> : <Check width="30px" fill="#82ca9d" />}
            </Icon>

            <Flex flex={1} flexDirection={['column', 'row']}>
              <Flex flex={1} px={3} justifyContent="space-between" alignItems="center" flexDirection={['column', 'row']}>
                <Text textAlign="center" fontWeight={600} mb={[2, 0]}>
                  {hormone ? hormone.name : ''}
                </Text>
                {isQns ? (
                  <Text mb={[2, 0]}>
                    Quantity Not Sufficient
                  </Text>
                ) : (
                  <Text mb={[2, 0]}>
                    {data.value} {data.unit}
                  </Text>
                )}
              </Flex>

              <Flex ml={2} pt={1} justifyContent="center">
                <Arrow width="30px" rotate={open ? 'rotate(180)' : undefined} />
              </Flex>
            </Flex>
          </DetailBar>

          {open && (
            <>
            {isQns?(
            <Flex mt={3} flexDirection="column" justifyContent="center">
            <Flex flexDirection={('column', 'row')}>
              <Text fontSize={2} fontWeight={500} mr={1} mb={2}>
              There is not enough specimen for the lab tests ordered to be performed.
              </Text>

              <Text fontSize={2} >
              Please let us know if our team has not already reached out to offer  a set of replacement materials.
              </Text>
            </Flex>

            </Flex>):(
            <Flex mt={3} flexDirection="column" justifyContent="center">
              <Flex flexDirection="column" mx={2}>
                <Flex justifyContent="space-between">
                  <Box mb={3}>
                    <Text mb={1} fontWeight={200}>
                      Your Results
                    </Text>

                    <Text fontWeight={500}>
                      {data.value} {data.unit}
                    </Text>
                  </Box>

                  <Box mb={3}>
                    <Text mb={1} mr={3} fontWeight={200}>
                      Normal Range
                    </Text>
                    <Text fontWeight={500}>
                      {hormone ? `${hormone.min} - ${hormone.max}` : data.referenceRange} {data.unit}
                    </Text>
                  </Box>
                </Flex>

                {hormone && (
                  <Flex flex={1} mr={3}>
                    <ResponsiveContainer height={125} width="100%">
                      <BarChart data={[{ name: 0, result: [0, data.value] }]} layout="vertical">
                        <XAxis type="number" domain={[0, hormone.max + 10]} unit={` ${data.unit}`} />
                        <YAxis type="category" hide={true} />
                        <Bar dataKey="result" fill={data.abnormal ? '#f9a394' : '#82ca9d'} />

                        <ReferenceLine x={hormone.min} isFront={true} />
                        <ReferenceLine x={hormone.max} isFront={true} />
                      </BarChart>
                    </ResponsiveContainer>
                  </Flex>
                )}

                <Flex flexDirection={('column', 'row')}>
                  <Text fontSize={2} fontWeight={500} mr={1} mb={2}>
                    Your {data.hormone} has returned as{' '}
                  </Text>

                  <Text fontSize={2} fontWeight={500} color={data.abnormal ? '#f13b2f' : ''}>
                    {data.abnormal ? 'Out of the normal range' : 'Normal'}
                  </Text>
                </Flex>
                {hormone &&
                  hormone.description &&
                  hormone.description.split('\n').map((item, i) => {
                    return (
                      <Text mt={2} key={`${hormone.name}${i}`}>
                        {item}
                      </Text>
                    );
                  })}
                {/* <Text>{hormone ? hormone.description : ''}</Text> */}
              </Flex>
            </Flex>)}
            </>
          )}
        </Flex>
      </Card>
    );
  }
}

export default LabResult;
