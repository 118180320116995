import React, { useState, useEffect } from 'react';
import { Card, Flex, Text } from 'rebass';
import { Carousel } from '../../components/3dCarousel/3dCarousel';
import { ProgressArray } from '../../components/3dCarousel/ProgressArray';
import FullLayout2 from '../../components/FullLayout';
import { useMount } from '../../utils/hooks/lifecycle';
// import { CoverPageSection } from './sections/CoverPageSection';
import withSession from '../../lib/withSession';
import { withRouter } from 'react-router';
import withAnalytics from '../../lib/withAnalytics';
import { SummarySectionSection } from './sections/SummarySection';

const CarouselCardInner = ({ children, sectionName, isActive, backgroundColor, stories, onAllStoriesEnd, section, hideIndicators, sectionNameColor, onClose, analytics, restartCount }) => {
  const [currentId, setCurrentId] = useState(0);
  const track = (index) => {
    if (isActive) {
      analytics.track(`Yearly Review - ${stories[currentId].analytics.section} - ${stories[currentId].analytics.screen}`, {
        ...(stories[currentId].analytics.metadata || {}),
        section: stories[currentId].analytics.section,
        screen: stories[currentId].analytics.screen,
      });
    }
  };

  useEffect(()=>{
setCurrentId(0)
  },[restartCount])

  useEffect(
    () => {
      track(currentId);
    },
    [currentId]
  );

  useEffect(
    () => {
      setCurrentId(0);
    },
    [isActive]
  );

  return (
    <Card style={{ height: '100vh', width: '100%', backgroundColor: isActive ? stories[currentId].backgroundColor || backgroundColor : '#c4c8d3', borderRadius: '30px', padding: '18px' }}>
      {isActive ? stories[currentId].background || null : null}
      {isActive ? (
        <Flex style={{ zIndex: 100, height: '100%', flexDirection: 'column' }}>
          <ProgressArray
            hideIndicators={hideIndicators}
            currentId={currentId}
            next={() => {
              if (currentId === stories.length - 1) return;
              setCurrentId(currentId + 1);
            }}
            stories={stories}
            defaultInterval={3000}
            onAllStoriesEnd={() => {
              onAllStoriesEnd();
            }}
          />
          <Flex mt={'16px'} style={{ position: 'relative' }}>
            <Text style={{ opacity: !hideIndicators ? 1 : 0, color: sectionNameColor || '#4E658A' }}>{sectionName}</Text>

            <svg
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
              width="20"
              height="34"
              style={{ position: 'absolute', right: 20, zIndex: 10000,cursor:'pointer' }}
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.0357 4.01033C33.8769 3.16932 33.8769 1.80576 33.0357 0.964744C32.1949 0.123731 30.8312 0.123731 29.9904 0.964744L17 13.9551L4.00963 0.964744C3.16858 0.123731 1.80502 0.123731 0.964011 0.964744C0.122999 1.80576 0.122999 3.16932 0.964011 4.01033L13.9544 17.0007L0.964011 29.991C0.122999 30.8322 0.122999 32.1955 0.964011 33.0367C1.80502 33.8775 3.16858 33.8775 4.00963 33.0367L17 20.0463L29.9904 33.0367C30.8312 33.8775 32.1949 33.8775 33.0357 33.0367C33.8769 32.1955 33.8769 30.8322 33.0357 29.991L20.0455 17.0007L33.0357 4.01033Z"
                fill={sectionNameColor || '#4E658A'}
              />
            </svg>
          </Flex>
          {stories[currentId].content || null}
        </Flex>
      ) : (
        <Flex
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
          }}
        >
          {section.inactiveIcon ? section.inactiveIcon : null}
          <Text style={{ fontSize: '32px', color: 'white' }}>{sectionName}</Text>
        </Flex>
      )}
    </Card>
  );
};

export const CarouselCard = withAnalytics(CarouselCardInner);

// const SECTIONS = [
//   {
//     sectionType: 'CoverPage',
//     sectionName: '',
//     backgroundColor: '#FDF5F2',
//     component: <CoverPageSection />,
//   },
// ];

const FollowUpOutroSummary = ({ history, match, session: { isMobileAppUser } }) => {
  const [active, setActive] = useState(0);

  useEffect(()=>{
if(active===-1){
  setActive(0)
}
  }, [active])

  useMount(() => {
    if (window.Beacon) {
      window.Beacon('destroy');
    }
  }, []);

  const onClose = () => {
    history.push('/');
  };
  const arr = ['Summary'];
  return (
    <FullLayout2>
      <Flex
        style={{
          width: '100vw',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          backgroundColor: '#001637',
        }}
      >
        <Carousel active={active} setActive={setActive}>
          {arr.map((x, i) => {
            if (x === 'Summary') {
              return (
                <SummarySectionSection
                  onClose={onClose}
                  surveyId={match.params.id.toLowerCase()}//'1302131f-834e-42de-a4d8-e4e3a2cf9260'
                  active={active}
                  setActive={setActive}
                  isActive={active===0}
                  onAllStoriesEnd={() => {
                    onClose();
                  }}
                  onRestart={() => {
                    console.log('replay!')
                    setActive(-1);
                  }}
                />
              );
            } else {
              return null;
            }
          })}
        </Carousel>
      </Flex>
    </FullLayout2>
  );
};

export default withRouter(withSession(FollowUpOutroSummary));
