import React from 'react';
import { Button, Flex, Card, Heading } from 'rebass';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { TextInput } from '../../components/TextInput';
import { FormField } from '../../components/FormField';

import { CHANGE_PASSWORD } from '../../graphql';
import withSession from '../../lib/withSession';

const schemaReg = Yup.object().shape({
  oldPassword: Yup.string()
    .trim()
    .required('Required'),
  newPassword: Yup.string()
    .trim()
    .min(8, 'Password must be greater than 8 characters')
    .required('Required'),
});

const schemaAdmin = Yup.object().shape({
  oldPassword: Yup.string()
    .trim()
    .required('Required'),
  newPassword: Yup.string()
    .trim()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.])(?=.{8,24})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

const ChangePassword = (props) => {
  const { adm } = props.session;
  let schema = schemaReg;
  if (!!adm) {
    schema = schemaAdmin;
  }
  return (
    <Card p={4}>
      <Heading mb={4}>Change Password</Heading>

      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
        }}
        validationSchema={schema}
        onSubmit={(values, actions) => props.onSubmit(values, actions, CHANGE_PASSWORD)}
        render={({ errors, touched, isValid, isSubmitting }) => (
          <Form>
            <FormField mb={3} error={touched.oldPassword && errors.oldPassword}>
              <TextInput placeholder="Current Password" type="password" name="oldPassword" />
            </FormField>
            <FormField mb={3} error={touched.newPassword && errors.newPassword}>
              <TextInput placeholder="New Password" type="password" name="newPassword" />
            </FormField>

            <Flex justifyContent="center" mt={4}>
              <Button disabled={!isValid || isSubmitting} variant="secondary" width={1 / 2} type="submit">
                Update
              </Button>
            </Flex>
          </Form>
        )}
      />
    </Card>
  );
};

export default withSession(ChangePassword);
