import React, { Component } from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';
import ContactInfoForm from './profileSections/contactInfo';

const Container = styled(Box)`
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
  padding-top: 20px;
`;

class ProfilePage extends Component {
  render() {
    return (
      <Container>
        {/* <EmailPasswordForm {...this.props}/> */}
        <ContactInfoForm />
        {/* <MedicationHistoryForm /> */}
      </Container>
    );
  }
}

export default ProfilePage;
