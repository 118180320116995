import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Box, Flex } from 'rebass';
import FullLayout2 from '../../components/FullLayout';
import PleaseWait from '../../components/PleaseWait';
import withSession from '../../lib/withSession';

const GET_ROOM = gql`
  query getProviderRoomInfoFromId($id: String!) {
    getProviderRoomInfoFromId(id: $id) {
      id
      room
    }
  }
`;

const VisitVisioNutritionist = ({ session: { firstName, lastName } }) => {
  return (
    <FullLayout2 style={{ zIndex: 10 }}>
      <Box id="visioWrap" style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, height: '100vh' }}>
        <iframe
          width="100%"
          height="100%"
          frameborder="0"
          allow="camera; microphone; fullscreen; speaker; display-capture"
          src={`https://paloma.whereby.com/ngermanwvedma?displayName=${encodeURI(`${firstName} ${lastName}`)}`}
        />
      </Box>
    </FullLayout2>
  );
};

export const UnauthenticatedVisitVisioNutritionistDet = ({ client, match, history }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [provider, setProvider] = useState();
  const [providerId, setProviderId] = useState(match && match.params && match.params.id ? match.params.id : '');
  useEffect(
    () => {
      if (!providerId) return;
      const loadProviders = async () => {
        const response = await client.query({
          query: GET_ROOM,
          fetchPolicy: 'network-only',
          variables: {
            id: providerId.toString(),
          },
        });
        if (response && response.data && response.data.getProviderRoomInfoFromId) {
          setProvider(response.data.getProviderRoomInfoFromId);
        }

        setHasLoaded(true);
      };
      loadProviders();
    },
    [providerId]
  );
  return (
    <FullLayout2 style={{ zIndex: 10 }}>
      <Flex style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        {!hasLoaded ? (
          <PleaseWait />
        ) : (
          <Box id="visioWrap" style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, height: '100vh' }}>
            <iframe width="100%" height="100%" frameborder="0" allow="camera; microphone; fullscreen; speaker; display-capture" src={`${provider.room}`} />
          </Box>
        )}
      </Flex>
    </FullLayout2>
  );
};

export default withRouter(withApollo(withSession(VisitVisioNutritionist)));
export const UnauthenticatedVisitVisioNutritionist = withRouter(withApollo(UnauthenticatedVisitVisioNutritionistDet));
