import React from 'react';
import FullLayout2 from '../../../components/FullLayout';
import ByAKit from './buyAKit';

class BuyAKitPage extends React.Component {
  constructor(props) {
    super(props);

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.patientState
    ) {
      this.state = {
        patientState: this.props.location.state.patientState,
        history: this.props.history,
        isReady: true,
      };
    } else {
      this.props.history.push('/purchase-kit');
    }
  }

  render() {
    const { patientState, history, isReady } = this.state;

    if (!isReady) {
      return <div />;
    }

    return (
      <FullLayout2
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
        }}
      >
        <ByAKit patientState={patientState} history={history} />
      </FullLayout2>
    );
  }
}

export default BuyAKitPage;
