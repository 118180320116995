import React from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';

const Circle = styled(Box)`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  max-width: 60px;
  max-height: 60px;
  background-color: #344f79;
  color: white;
  font-size: 24px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
`;

const Abbr = styled(Text)`
  color: white;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Block = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
  min-width: 160px;
`;

export const Diets = () => {
  return (
    <Flex flex="row" flexWrap="wrap" mt={5}>
      <Block>
        <Circle>
          <Abbr>Vg</Abbr>
        </Circle>
        <Text fontWeight="700">Vegetarian</Text>
      </Block>
      <Block>
        <Circle>
          <Abbr>GF</Abbr>
        </Circle>
        <Text fontWeight="700">Gluten-free</Text>
      </Block>
      <Block>
        <Circle>
          <Abbr>NON GMO</Abbr>
        </Circle>
        <Text fontWeight="700">GMO-free</Text>
      </Block>
      <Block>
        <Circle>
          <Abbr>DF</Abbr>
        </Circle>
        <Text fontWeight="700">Dairy-free</Text>
      </Block>
      <Block>
        <Circle>
          <Abbr>IF</Abbr>
        </Circle>
        <Text fontWeight="700">Iodine-free</Text>
      </Block>
    </Flex>
  );
};

export default Diets;
