import React from 'react';
import { Card, Flex, Heading, Text } from 'rebass';

import painFree from '../../../../static/painFree.png';
import doctorIcon from '../../../../static/doctorIcon.png';
import pillsIcon from '../../../../static/Pills.png';
import styled from 'styled-components';


const Crd = styled(Card)`

@media screen and (max-width: 52em) {
  min-width: 80vw;
  width: 80vw;
}
`
export const PricingAsYouGo = () => {
  return (
    <Flex flexDirection="column" style={{maxWidth: '100%'}}>
      <Flex style={{overflowX: 'auto'}}>
        <Crd
          m={['0 8px 0 8px']}
          style={{
            background: '#DEEAFF',
            minHeight: '400px',
            borderRadius: '20px',
            padding: '20px',
          }}
        >
          <Flex flexDirection="column">
            <Heading textAlign="center" fontSize={'18px'}>
              At Home Thyroid Kits
            </Heading>
            <img src={painFree} height="80" alt="" style={{ margin: '20px auto' }} key='painFree' />
            <Flex flexDirection="row" alignItems="baseline" mt="20px" justifyContent="center" mb="20px">
              <Text style={{ fontWeight: 600, fontSize: '20px' }}>$99</Text>
            </Flex>
            <Text fontWeight={300} textAlign="center">
              Test your thyroid levels from the comfort of your home with a complete panel.
            </Text>
          </Flex>
        </Crd>
        <Crd
          m={['0 8px 0 8px']}
          style={{
            background: '#E2E1EC',
            minHeight: '400px',
            borderRadius: '20px',
            padding: '20px',
          }}
        >
          <Flex flexDirection="column">
            <Heading textAlign="center" fontSize={'18px'}>
              Online Doctor Visits
            </Heading>
            <img src={doctorIcon} height="80" alt="" style={{ margin: '20px auto' }} key='doctorIcon' />
            
            <Text mt={'20px'}
                style={{
                  fontWeight: 600,
                  fontSize: '16px',
                  color: '#F8A294',
                  textAlign: 'center',
                }}
              >
                PALOMA MEMBERS ONLY
              </Text>
            <Text fontWeight={300} mt={'20px'}  textAlign="center">
              Get A Long-Term Partner Dedicated To Your Thyroid Health.
            </Text>
          </Flex>
        </Crd>
        <Crd
          m={['0 8px 0 8px']}
          style={{
            background: '#FCE2DD',
            minHeight: '400px',
            borderRadius: '20px',
            padding: '20px',
          }}
        >
          <Flex flexDirection="column">
            <Heading textAlign="center" fontSize={'18px'}>
              Vitamin Supplements
            </Heading>
            <img src={pillsIcon} height="80" alt="" style={{ margin: '20px auto' }} key='pillsIcon'/>

            <Flex flexDirection="row" alignItems="baseline" mt="20px" justifyContent="center">
            <Text style={{ fontWeight: 600, fontSize: '20px' }}>
                $40
                {/* <span style={{fontWeight: 300, fontSize: '13px'}}>
                  /month
                </span> */}
              </Text>
              
            </Flex>
            <Text fontWeight={300} mt={'20px'} textAlign="center">
              The reinvented multivitamin for your thyroid health journey.
            </Text>
          </Flex>
        </Crd>
      </Flex>
    </Flex>
  );
};
