import React from 'react';
import IconContainer from './IconContainer';

const Phone = props => (
  <IconContainer {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <path
        fill={props.fill ? props.fill : '#000'}
        d="M3.62,7.79a15.15,15.15,0,0,0,6.59,6.59l2.2-2.2a1,1,0,0,1,1-.24,11.41,11.41,0,0,0,3.57.57,1,1,0,0,1,1,1V17a1,1,0,0,1-1,1A17,17,0,0,1,0,1,1,1,0,0,1,1,0H4.5a1,1,0,0,1,1,1,11.36,11.36,0,0,0,.57,3.57,1,1,0,0,1-.25,1Z"
      />
    </svg>
  </IconContainer>
);

export default Phone;
