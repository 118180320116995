import React from 'react';
import { Box, Heading, Text } from 'rebass';
import Facts from '../../../static/facts.png';

export const Essentials = () => {
  return (
    <Box mt={6}>
      <Heading textAlign="center">9 Essential Nutrients</Heading>
      <Text textAlign="center" fontWeight="500" mt={4}>
        Daily Thyroid Care is an iodine-free combination of vitamins, minerals,
        and herbs that provides essential nutrients required for optimal thyroid
        gland function.
      </Text>

      <img
        src={Facts}
        alt=""
        style={{
          display: 'block',
          margin: '0 auto',
          marginTop: '36px',
          width: '75%',
          maxWidth: '600px',
        }}
      />
    </Box>
  );
};

export default Essentials;
