export const QUESTIONS_FOLLOWUP_SURVEYS_1 = [
  {
    id:'OverallWellBeing',
    title: 'How would you describe your overall well-being?',
    type: 'PICKONE',
    options: [
      {
        text: 'Great',
        value: 'Great',
      },
      {
        text: 'Good',
        value: 'Good',
      },
      {
        text: 'OK',
        value: 'OK',
      },
      {
        text: 'Not so good',
        value: 'Not so good',
      },
      {
        text: 'Bad',
        value: 'Bad',
      },
    ],
    cantBypass: true,
    nextTitle: `Continue`,
  },
  // {
  //   id:'ExerciseMore30',
  //   title: 'How often do you exercise (break a sweat) for more than 30 min?',
  //   type: 'PICKONE',
  //   options: [
  //     {
  //       text: 'I do not exercise',
  //       value: 'I do not exercise',
  //     },
  //     {
  //       text: 'Less than once a week',
  //       value: 'Less than once a week',
  //     },
  //     {
  //       text: 'Once a week',
  //       value: 'Once a week',
  //     },
  //     {
  //       text: '1-3 times a week',
  //       value: '1-3 times a week',
  //     },
  //     {
  //       text: 'Every day',
  //       value: 'Every day',
  //     },
  //   ],
  //   nextTitle: `Continue`,
  // },
  // {
  //   id:'ParticipateThyroidSelfHelpGroups',
  //   title: 'Do you participate in thyroid self-help groups?',
  //   type: 'PICKONE',
  //   options: [
  //     {
  //       text: 'Daily',
  //       value: 'Daily',
  //     },
  //     {
  //       text: 'Several times a week',
  //       value: 'Several times a week',
  //     },
  //     {
  //       text: 'Once a week',
  //       value: 'Once a week',
  //     },
  //     {
  //       text: 'Once every few weeks',
  //       value: 'Once every few weeks',
  //     },
  //     {
  //       text: 'Few times a year',
  //       value: 'Few times a year',
  //     },
  //     {
  //       text: 'I do not participate in thyroid self help groups',
  //       value: 'I do not participate in thyroid self help groups',
  //     },
  //   ],
  //   nextTitle: `Continue`,
  // },
  {
    id:'HowManyHealthApps',
    title: 'How many health apps do you currently use to manage your health?',
    type: 'PICKONE',
    options: [
      {
        text: 'none',
        value: 'none',
      },
      {
        text: '1',
        value: '1',
      },
      {
        text: '2-4',
        value: '2-4',
      },
      {
        text: '5-8',
        value: '5-8',
      },
      {
        text: '9 or more',
        value: '9 or more',
      },
    ],
    nextTitle: `Continue`,
  },
  {
    id:'SatisfiedLastDoctorVisit',
    title: "How satisfied are you with your last Paloma video visit?",//'How satisfied are you with your last Paloma doctor visit?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not satisfied at all',
        value: 'Not satisfied at all',
      },
      {
        text: 'Somewhat dissatisfied',
        value: 'Somewhat dissatisfied',
      },
      {
        text: 'A bit dissatisfied',
        value: 'A bit dissatisfied',
      },
      {
        text: 'Not satisfied, not dissatisfied',
        value: 'Not satisfied, not dissatisfied',
      },
      {
        text: 'A bit satisfied',
        value: 'A bit satisfied',
      },
      {
        text: 'Somewhat satisfied',
        value: 'Somewhat satisfied',
      },
      {
        text: 'Fully satisfied',
        value: 'Fully satisfied',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'DetailsLastDoctorVisit',
    title: 'Anything to share with us about your last visit?',
    type: 'OPENTEXT',
    nextTitle: `Continue`,
    // cantBypass: true,
  },
  // {
  //   id:'ConvenientTreatment',
  //   title: 'How convenient have you found your treatment lately? (talking to your doctor, getting the right medication, getting prescriptions, doing lab tests)?',
  //   type: 'PICKONE',
  //   options: [
  //     {
  //       text: 'Significantly less practical than before',
  //       value: 'Significantly less practical than before',
  //     },
  //     {
  //       text: 'Somewhat less practical than before',
  //       value: 'Somewhat less practical than before',
  //     },
  //     {
  //       text: 'A bit less practical than before',
  //       value: 'A bit less practical than before',
  //     },
  //     {
  //       text: 'Equal as before',
  //       value: 'Equal as before',
  //     },
  //     {
  //       text: 'A bit more practical than before',
  //       value: 'A bit more practical than before',
  //     },
  //     {
  //       text: 'Somewhat more practical than before',
  //       value: 'Somewhat more practical than before',
  //     },
  //     {
  //       text: 'Quite a lot more practical than before',
  //       value: 'Quite a lot more practical than before',
  //     },
  //   ],
  //   nextTitle: `Continue`,
  //   cantBypass: true,
  // },
  // {
  //   id:'SatisfiedTreatment',
  //   title: 'How well do you think the treatment is controlling symptoms of hypothyroidism?',
  //   type: 'PICKONE',
  //   options: [
  //     {
  //       text: 'Much worse',
  //       value: 'Much worse',
  //     },
  //     {
  //       text: 'Somewhat worse',
  //       value: 'Somewhat worse',
  //     },
  //     {
  //       text: 'A bit worse',
  //       value: 'A bit worse',
  //     },
  //     {
  //       text: 'Equal as before',
  //       value: 'Equal as before',
  //     },
  //     {
  //       text: 'A bit better',
  //       value: 'A bit better',
  //     },
  //     {
  //       text: 'Somewhat better',
  //       value: 'Somewhat better',
  //     },
  //     {
  //       text: 'Much better',
  //       value: 'Much better',
  //     },
  //   ],
  //   nextTitle: `Continue`,
  //   cantBypass: true,
  // },
  {
    id: 'HowOftenSkipMedication',
    title: 'How often do you skip taking your thyroid medication?',
    type: 'PICKONE',
    options: [
      {
        text: `Few times a week`,
        value: `Few times a week`
      },
      {
        text: 'Once a week',
        value: 'Once a week',
      },
      {
        text: 'Once every two weeks',
        value: 'Once every two weeks',
      },
      {
        text: 'Once a month',
        value: 'Once a month',
      },
      {
        text: 'Once every few months',
        value: 'Once every few months',
      },
      {
        text: 'Never or almost never',
        value: 'Never or almost never',
      },
      {
        text: 'I do not take thyroid medication',
        value: 'I do not take thyroid medication',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'SensitiveCold',
    title: 'In the past 4 weeks have you been sensitive to cold?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  // {
  //   id:'SwollenHandsFeet',
  //   title: 'In the past 4 weeks have you had swollen hands or feet?',
  //   type: 'PICKONE',
  //   options: [
  //     {
  //       text: 'Not at all',
  //       value: 'Not at all',
  //     },
  //     {
  //       text: 'A bit',
  //       value: 'A bit',
  //     },
  //     {
  //       text: 'Somewhat',
  //       value: 'Somewhat',
  //     },
  //     {
  //       text: 'Quite a lot',
  //       value: 'Quite a lot',
  //     },
  //     {
  //       text: 'Fully',
  //       value: 'Fully',
  //     },
  //   ],
  //   nextTitle: `Continue`,
  //   cantBypass: true,
  // },
  {
    id:'DrySkin',
    title: 'In the past 4 weeks have you had dry skin and/or brittle nails?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'Tired',
    title: 'In the past four weeks have you been tired?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'DifficultiesMotivated',
    title: 'In the past four weeks did you have difficulties being motivated to do things?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'Energetic',
    title: 'In the past four weeks did you feel energetic?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'DifficultiesRemembering',
    title: 'In the past four weeks have you had difficulties remembering?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'SlowThinking',
    title: 'In the past four weeks have you had slow or unclear thinking?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'AfraidAnxious',
    title: 'In the past four weeks have you felt afraid or anxious?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'DifficultiesManagingDailyLife',
    title: 'In the past four weeks, did you feel you had difficulties managing your daily life?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  // {
  //   id:'ParticipateLifeAround',
  //   title: 'In the past four weeks, have you been able to participate in life around you?',
  //   type: 'PICKONE',
  //   options: [
  //     {
  //       text: 'Not at all',
  //       value: 'Not at all',
  //     },
  //     {
  //       text: 'A bit',
  //       value: 'A bit',
  //     },
  //     {
  //       text: 'Somewhat',
  //       value: 'Somewhat',
  //     },
  //     {
  //       text: 'Quite a lot',
  //       value: 'Quite a lot',
  //     },
  //     {
  //       text: 'Fully',
  //       value: 'Fully',
  //     },
  //   ],
  //   nextTitle: `Continue`,
  //   cantBypass: true,
  // },
  {
    id:'EverythingTakesLonger',
    title: 'In the past four weeks, did you feel everything takes longer to do?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  {
    id:'ThyroidNegativeImpactQualityOfLife',
    title: 'In the past four weeks, has your thyroid had a negative impact on your quality of life?',
    type: 'PICKONE',
    options: [
      {
        text: 'Not at all',
        value: 'Not at all',
      },
      {
        text: 'A bit',
        value: 'A bit',
      },
      {
        text: 'Somewhat',
        value: 'Somewhat',
      },
      {
        text: 'Quite a lot',
        value: 'Quite a lot',
      },
      {
        text: 'Fully',
        value: 'Fully',
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
  // {
  //   id:'HighestEducation',
  //   title: 'What is your highest completed level of education?',
  //   type: 'PICKONEOTHER',
  //   options: [
  //     {
  //       text: 'Elementary school',
  //       value: 'Elementary school',
  //     },
  //     {
  //       text: 'High school',
  //       value: 'High school',
  //     },
  //     {
  //       text: 'College or university',
  //       value: 'College or university',
  //     },
  //     {
  //       text: 'Postgraduate (doctoral studies)',
  //       value: 'Postgraduate (doctoral studies)',
  //     },
  //     {
  //       text: 'Prefer not to say',
  //       value: 'Prefer not to say',
  //     },
  //     {
  //       text: 'Other',
  //       value: 'Other',
  //       hasDetails: true,
  //     },
  //   ],
  //   nextTitle: `Continue`,
  //   cantBypass: true,
  // },
  {
    id:'IdentifyAs',
    title: 'Do you identify yourself as?',
    type: 'PICKONEOTHER',
    options: [
      {
        text: 'Black',
        value: 'Black',
      },
      {
        text: 'Asian',
        value: 'Asian',
      },
      {
        text: 'Hispanic',
        value: 'Hispanic',
      },
      {
        text: 'Native American',
        value: 'Native American',
      },
      {
        text: 'White',
        value: 'White',
      },
      {
        text: 'Prefer not to say',
        value: 'Prefer not to say',
      },
      {
        text: 'Other',
        value: 'Other',
        hasDetails: true,
      },
    ],
    nextTitle: `Continue`,
    cantBypass: true,
  },
];
