import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading, Text } from 'rebass';

import { Formik, Form } from 'formik';
import { injectStripe } from 'react-stripe-elements';
import { compose, withApollo } from 'react-apollo';
import { withWizard } from 'react-albus';
import { withToastManager } from 'react-toast-notifications';
import * as Yup from 'yup';

import { StripeInput } from '../../components/StripeInput';
import { FormField } from '../../components/FormField';
import { UPDATE_CARD } from '../../graphql';
import InformationSecuredDisclaimer from '../../components/InformationSecuredDisclaimer';

const schema = Yup.object().shape({
  number: Yup.string().required('Required'),
  cvc: Yup.string().required('Required'),
  expire: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
});

const Payment = props => {
  const {
    stripe,
    client,
    toastManager,
    refetch,
    defaultCard,
    address,
    onSubmit,
    wizard,
    canMakePayment,
    canMakePaymentApplePay,
    continuePayWithWallet,
  } = props;

  if (!address.street1) {
    wizard.push('address');
  }

  const createSourceWallet = async actions => {
    // console.log('createSourceWallet');
    try {
      // const { source } = await stripe.createSource({ type: 'card' });
      // console.log('createSourceWallet', source)
      // this.props.setSourceId(source.id);

      await continuePayWithWallet(actions, wizard, null);
    } catch (error) {
      console.log('ERR', error);
      actions.setSubmitting(false);
      toastManager.add('There was an error with the payment method.', {
        appearance: 'error',
      });
    }
  };

  const createSource = async actions => {
    try {
      const { source } = await stripe.createSource({ type: 'card' });

      await client.mutate({
        mutation: UPDATE_CARD,
        variables: {
          source: source.id,
        },
      });

      // await refetch();
      return true
    } catch (error) {
      actions.setSubmitting(false);
      console.log({error})
      if(error && error.graphQLErrors && error.graphQLErrors.length > 0) {
        toastManager.add(error.graphQLErrors[0].message, {
          appearance: 'error'
        })
        return false
      }
      toastManager.add('There was an error with the payment method.', {
        appearance: 'error',
      });
      return false
    }
  };

  return (
    <Fragment>
      <Flex flexDirection="column" flex={1}>
        {defaultCard ? (
          <Card p={4} mb={4}>
            <Flex flexDirection="column" alignItems="center" mb={4}>
              <Heading style={{ fontWeight: 'bold' }} mb={4}>
                Default Payment Method
              </Heading>
              <Text>
                {defaultCard.brand.toUpperCase()} **{defaultCard.last4}
              </Text>
              <Text>
                Expires {defaultCard.expMonth}/{defaultCard.expYear}
              </Text>
            </Flex>

            <Flex justifyContent="center">
              <Button
                variant="pink"
                width={[1 / 2, 1 / 3]}
                onClick={() => onSubmit(null, wizard)}
              >
                Review Order
              </Button>
            </Flex>
          </Card>
        ) : null}

        <Card p={4}>
          <Flex flexDirection="column" mb={4}>
            <Heading textAlign="center" style={{ fontWeight: 'bold' }} mb={2}>
              Payment Method
            </Heading>
            <Text textAlign="center" fontWeight={300}>
              We accept HSA and FSA cards
              <br />
              Entering payment information holds your spot.
            </Text>
          </Flex>

          <Formik
            initialValues={{ number: '', cvc: '', expire: '', zip: '' }}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
              const res=  await createSource(actions);
              if(!res) {
                return
              }
              await onSubmit(actions, wizard);
            }}
            render={({ errors, touched, isValid, isSubmitting, setSubmitting }) => (
              <Form>
                <Flex flexDirection="column">
                  <FormField error={touched.number && errors.number} mb={3}>
                    <StripeInput type="number" name="number" />
                  </FormField>

                  <Flex flexDirection={['column', 'column', 'row']} mb={4}>
                    <FormField
                      width={[1, 1, 1 / 3]}
                      mr={[0, 0, 3]}
                      error={touched.expire && errors.expire}
                    >
                      <StripeInput type="expire" name="expire" />
                    </FormField>

                    <FormField
                      width={[1, 1, 1 / 3]}
                      mr={[0, 0, 3]}
                      error={touched.cvc && errors.cvc}
                    >
                      <StripeInput type="cvc" name="cvc" />
                    </FormField>

                    <FormField
                      width={[1, 1, 1 / 3]}
                      error={touched.zip && errors.zip}
                    >
                      <StripeInput type="zip" name="zip" />
                    </FormField>
                  </Flex>
                </Flex>

                {/* <Text textAlign="center" fontWeight={300} mb={3}>
                  We will not charge you until after your appointment completes
                  and you will receive an email with a secure payment link.
                </Text> */}
                <Flex alignItems="center" flexDirection="column">
                  <Button
                    disabled={!isValid || isSubmitting}
                    variant="pink"
                    width={[1 / 2, 1 / 3]}
                    type="submit"
                  >
                    Review Order
                  </Button>
                </Flex>
                {canMakePayment ? (
                  <Flex alignItems="center" flexDirection={'column'}>
                    <Flex flexDirection="row" alignItems="center" mb="12px">
                      <div
                        style={{
                          height: '1px',
                          background: 'black',
                          opacity: 0.5,
                          width: '48px',
                          marginRight: '12px',
                        }}
                      ></div>
                      <Text>OR</Text>
                      <div
                        style={{
                          height: '1px',
                          background: 'black',
                          opacity: 0.5,
                          width: '48px',
                          marginLeft: '12px',
                        }}
                      ></div>
                    </Flex>
                    <Button
                      variant="pink"
                      width="auto"
                      disabled={isSubmitting}
                      type="button"
                      onClick={async () => {
                        setSubmitting(true);
                        await createSourceWallet({ setSubmitting });
                      }}
                    >
                      Pay with{' '}
                      {canMakePaymentApplePay ? 'Apple Pay' : 'Google Wallet'}
                    </Button>
                  </Flex>
                ) : null}

                <InformationSecuredDisclaimer />
              </Form>
            )}
          />
        </Card>
      </Flex>
    </Fragment>
  );
};

export default injectStripe(
  compose(withWizard, withApollo, withToastManager)(Payment)
);
