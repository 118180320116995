import moment from 'moment';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { CURRENT_USER, GET_ANALYTICS_ID, LOGIN_FROM_MOBILE } from '../graphql';
import withAnalytics from '../lib/withAnalytics';

const LoginFromMobile = ({ match, client, history, analytics }) => {
  useEffect(() => {
    console.log(match);

    if (!match) return;
    if (!match || !match.params || !match.params.token) {
      return;
    }

    async function Identify() {
      const res = await client.mutate({
        mutation: LOGIN_FROM_MOBILE,
        variables: {
          token: match.params.token,
        },
      });

      if (res && res.data && res.data.loginFromMobile && res.data.loginFromMobile.token) {
        localStorage.setItem('token', res.data.loginFromMobile.token);
        const aa = await client.query({
          query: GET_ANALYTICS_ID,
          fetchPolicy: 'network-only',
        });

        if (aa && aa.data && aa.data.getAnalyticsId) {
          analytics.Alias(aa.data.getAnalyticsId);
          try {
            const pt = await client.query({
              query: CURRENT_USER,
              fetchPolicy: 'network-only',
            });
            let age;
            let city;
            let state;
            let zip;
            let departmentId;
            let hashimoto;
            try {
              age = moment().diff(pt.data.currentUser.dob, 'years');
              if (pt.data.currentUser.address) {
                city = pt.data.currentUser.address.city;
                zip = pt.data.currentUser.address.zip;
                state = pt.data.currentUser.address.state;
              }
              if (pt.data.currentUser.department) {
                departmentId = pt.data.currentUser.department.departmentId;
              }
              if (pt.data.currentUser.diagnoseFull) {
                hashimoto = pt.data.currentUser.diagnoseFull.hasHashi;
              }
            } catch (error) {}

            analytics.Identify(aa.data.getAnalyticsId, {
              age,
              gender: pt.data.currentUser.gender,
              // city,
              // zip,
              state,
              departmentId,
              diagnosed: pt.data.currentUser.isDiagnosed,
              hashimoto: !!hashimoto,
              // dob: pt.data.currentUser.dob,
              // phone: pt.data.currentUser.phone
            });
          } catch (error) {
            // console.log('err id', error)
          }
        }
        let redirectTo = '/';
        if (match.params.redirect) {
          redirectTo = decodeURIComponent(match.params.redirect);
        }
        console.log({ redirectTo });
        history.push(redirectTo);
      }
    }
    Identify();
  }, []);
  return <div />;
};

export default withRouter(withAnalytics(LoginFromMobile));
