import React from 'react';
import { Box, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { Query, withApollo } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import gql from 'graphql-tag';
import PleaseWait from '../components/PleaseWait';

const GET_MY_LOGS = gql`
  query getDuplicatesAthenaIdHelpscoutId {
    getDuplicatesAthenaIdHelpscoutId {
      athena {
        id
        elements {
          id
          email
        }
      }
      helpscout {
        id
        elements {
          id
          email
        }
      }
    }
  }
`;

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;

const AdminIdentifyDups = () => {
  const columns = [
    // {
    //   Header: '',
    //   accessor: 'flag',
    //   width: 40,
    //   Cell: (row) => (
    //     <span data-tip={(row.value || '') === 'Same IP' ? 'Same IP address' : null} data-type={(row.value || '') === 'Same IP' ? 'warning' : null}>
    //       {' '}
    //       <FontAwesomeIcon size="1x" color={(row.value || '') === 'Same IP' ? 'red' : '#0cac0c'} icon={faFlag} />
    //     </span>
    //   ),
    // },
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: 'count',
      accessor: 'element',
      Cell: (row) => {
        return <div>{row.original.elements.length}</div>;
      },
    },
  ];

  return (
    <Container>
      <Heading mb={4} textAlign={'center'}>
        Duplicates IDs
      </Heading>
      <Query query={GET_MY_LOGS} fetchPolicy="network-only">
        {({ data, loading }) => {
          console.log({ data });
          if (!!loading || !data || !data.getDuplicatesAthenaIdHelpscoutId) return <PleaseWait />;
          return (
            <>
              <ReactTooltip place="left" type="success" effect="solid" />
              <Text>Athena Duplicates:</Text>
              <ReactTable
                data={data.getDuplicatesAthenaIdHelpscoutId.athena}
                columns={columns}
                loading={loading}
                showPagination={true}
                showPageSizeOptions={true}
                pageSizeOptions={[20, 25, 50, 100]}
                defaultPageSize={Math.min(5,data.getDuplicatesAthenaIdHelpscoutId.athena.length)}
                SubComponent={(row) => {
                  return (
                    <Flex mt={3} mb={3} ml={2} flexDirection={'column'}>
                      {row.original.elements.map((x) => {
                        return (
                          <Text ml={3}>
                            {x.email}
                            &nbsp;
                            {' - '} &nbsp;
                            {x.id}
                          </Text>
                        );
                      })}
                    </Flex>
                  );
                }}
              />
              <Text mt={4}>Helpscout Duplicates:</Text>
              <ReactTable
                data={data.getDuplicatesAthenaIdHelpscoutId.helpscout}
                columns={columns}
                loading={loading}
                showPagination={true}
                showPageSizeOptions={true}
                pageSizeOptions={[20, 25, 50, 100]}
                defaultPageSize={Math.min(5,data.getDuplicatesAthenaIdHelpscoutId.helpscout.length)}
                SubComponent={(row) => {
                  return (
                    <Flex mt={3} mb={3} ml={2} flexDirection={'column'}>
                      {row.original.elements.map((x) => {
                        return (
                          <Text ml={3}>
                            {x.email}
                            &nbsp;
                            {' - '} &nbsp;
                            {x.id}
                          </Text>
                        );
                      })}
                    </Flex>
                  );
                }}
              />
            </>
          );
        }}
      </Query>
    </Container>
  );
};

export default withApollo(AdminIdentifyDups);
