import { Form, Formik } from 'formik';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import Select from 'react-select';
import { Button, Flex } from 'rebass';
import CheckboxInput from '../../../components/CheckboxInput';
import FormField from '../../../components/FormField';
import TextInput from '../../../components/TextInput';
import {
  ADMIN_GET_WORKOUTS_AUTHORS,
  ADMIN_GET_WORKOUTS_CATEGORIES,
  ADMIN_GET_WORKOUTS_DIFFICULTIES,
  ADMIN_GET_WORKOUTS_ELEMENTS,
  ADMIN_UPSERT_WORKOUT_ELEMENT,
} from '../../../graphql';

const AddWorkoutElement = ({ onSave, onClose, workoutElement, workoutElementLength, workoutId }) => {
  if (!workoutElement) return null;
  return (
    <Query query={ADMIN_GET_WORKOUTS_AUTHORS}>
      {({ data: dataAuthors, loading: loadingAuthors, error: errorAuthors }) => {
        if (loadingAuthors || errorAuthors) return <div />;
        const authors = dataAuthors.getAdminWorkoutAuthors.map((x) => {
          return {
            value: x.id,
            label: x.name,
          };
        });
        return (
          <Query query={ADMIN_GET_WORKOUTS_CATEGORIES}>
            {({ data: dataCategories, loading: loadingCategories, error: errorCategories }) => {
              if (loadingCategories || errorCategories) return <div />;
              const categories = dataCategories.getAdminWorkoutCategories.map((x) => {
                return {
                  value: x.id,
                  label: x.name,
                };
              });
              return (
                <Query query={ADMIN_GET_WORKOUTS_DIFFICULTIES}>
                  {({ data: dataDifficulties, loading: loadingDifficulties, error: errorDifficulties }) => {
                    if (loadingDifficulties || errorDifficulties) return <div />;
                    const difficulties = dataDifficulties.getAdminWorkoutDifficulties.map((x) => {
                      return {
                        value: x.id,
                        label: x.name,
                      };
                    });
                    console.log({workoutElement, authors, categories, difficulties})
                    return (
                      <Mutation
                        mutation={ADMIN_UPSERT_WORKOUT_ELEMENT}
                        refetchQueries={[{ query: ADMIN_GET_WORKOUTS_ELEMENTS, variables: { workoutId } }]}
                        awaitRefetchQueries={true}
                        onCompleted={() => {
                          onSave();
                        }}
                      >
                        {(upsertCategory, { loading }) => (
                          <Formik
                            isInitialValid={
                              ({ initialValues }) => true
                              //   schema.isValidSync(initialValues)
                            }
                            initialValues={{
                              ...workoutElement,
                              workout: workoutId,
                              difficulty: difficulties.find((x) => x.value === (workoutElement.difficulty || {}).id),
                              author: authors.find((x) => x.value === (workoutElement.author || {}).id),
                              category: categories.filter((x) => (workoutElement.category || []).map((y) => y.id).indexOf(x.value) > -1),
                            }}
                            // validationSchema={schema}
                            onSubmit={async (values) => {
                              let vars = {
                                id: workoutElement.id,
                                title: values.title,
                                fileLink: values.fileLink,
                                description: values.description,
                                duration: values.duration,
                                bgMainColor: values.bgMainColor,
                                bgBubblesColor: values.bgBubblesColor,
                                titleColor: values.titleColor,
                                descriptionColor: values.descriptionColor,
                                order: workoutElement.id ? workoutElement.order : workoutElementLength,
                                isActive: values.isActive,
                                isArchived: values.isArchived,
                                memberOnly: values.memberOnly,
                                workout: workoutId,
                                category: (values.category || []).map((x) => x.value),
                                difficulty: values.difficulty.value,
                                author: values.author.value,
                                thumbnailUrl: values.thumbnailUrl,
                              };

                              await upsertCategory({
                                variables: {
                                  ...vars,
                                },
                              });
                            }}
                            render={({ touched, errors, setFieldValue, setFieldTouched, values }) => (
                              <Form>
                                <Flex flexDirection="column" width="400px" style={{ maxHeight: '900px' }}>
                                  <FormField mb={4} error={touched.name && errors.name}>
                                    <label for="title">Title *</label>
                                    <TextInput name="title" placeholder="Title" />
                                  </FormField>
                                  <FormField mb={4} error={touched.description && errors.description}>
                                    <label for="description">Description</label>
                                    <TextInput name="description" placeholder="Description" />
                                  </FormField>
                                  <FormField mb={4} error={touched.duration && errors.duration}>
                                    <label for="duration">Duration</label>
                                    <TextInput name="duration" placeholder="Duration" />
                                  </FormField>
                                  <FormField mb={4} error={touched.fileLink && errors.fileLink}>
                                    <label for="fileLink">File Link*</label>
                                    <TextInput name="fileLink" placeholder="File Link" />
                                  </FormField>

                                  <FormField mb={4}>
                                    <label for="difficulty">Difficulty *</label>
                                    <Select
                                      classNamePrefix="region-select"
                                      options={difficulties}
                                      name="difficulty"
                                      value={values.difficulty}
                                      placeholder="select difficulty"
                                      onChange={(option) => {
                                        setFieldValue('difficulty', option);
                                      }}
                                      onBlur={() => setFieldTouched('difficulty', true)}
                                      isSearchable={true}
                                    />
                                  </FormField>

                                  <FormField mb={4}>
                                    <label for="author">Author *</label>
                                    <Select
                                      classNamePrefix="region-select"
                                      options={authors}
                                      name="author"
                                      value={values.author}
                                      placeholder="select author"
                                      onChange={(option) => {
                                        setFieldValue('author', option);
                                      }}
                                      onBlur={() => setFieldTouched('author', true)}
                                      isSearchable={true}
                                    />
                                  </FormField>

                                  <FormField mb={4}>
                                    <label for="category">Categories</label>
                                    <Select
                                      isMulti
                                      classNamePrefix="region-select"
                                      options={categories}
                                      name="category"
                                      value={values.category}
                                      placeholder="select category"
                                      onChange={(option, a) => {
                                        console.log({ option, a });
                                        setFieldValue('category', option);
                                      }}
                                      onBlur={() => setFieldTouched('category', true)}
                                      isSearchable={true}
                                    />
                                  </FormField>
                                  <FormField mb={4} error={touched.bgMainColor && errors.bgMainColor}>
                                    <label for="bgMainColor">Screen Background Color</label>
                                    <TextInput name="bgMainColor" placeholder="Screen Background Color" />
                                  </FormField>

                                  <FormField mb={4} error={touched.bgBubblesColor && errors.bgBubblesColor}>
                                    <label for="bgBubblesColor">Hormones Background Color</label>
                                    <TextInput name="bgBubblesColor" placeholder="Hormones Background Color" />
                                  </FormField>

                                  <FormField mb={4} error={touched.titleColor && errors.titleColor}>
                                    <label for="titleColor">Title Color</label>
                                    <TextInput name="titleColor" placeholder="Title Color" />
                                  </FormField>

                                  <FormField mb={4} error={touched.titleColor && errors.titleColor}>
                                    <label for="descriptionColor">Description Color</label>
                                    <TextInput name="descriptionColor" placeholder="Description Color" />
                                  </FormField>

                                  <FormField mb={4} error={touched.thumbnailUrl && errors.thumbnailUrl}>
                                    <label for="thumbnailUrl">Thumbnail Url</label>
                                    <TextInput name="thumbnailUrl" placeholder="Thumbnail Url" />
                                  </FormField>

                                  <FormField mb={4} error={touched.isActive && errors.isActive}>
                                    <label for="isActive">Is Active</label>
                                    <CheckboxInput
                                      name="isActive"
                                      styles={{
                                        checked: {
                                          Box: {
                                            backgroundColor: 'rgb(54, 79, 121)',
                                          },
                                        },
                                      }}
                                    />
                                  </FormField>
                                  <Flex mt={4} flexDirection="row" alignItems="center" justifyContent="space-between">
                                    <Button
                                      style={{
                                        background: 'white',
                                        color: '#344f79',
                                        border: '1px solid #344f79',
                                      }}
                                      type="button"
                                      onClick={onClose}
                                    >
                                      Cancel
                                    </Button>
                                    <Button disabled={!!loading} style={{ background: '#344f79' }}>
                                      {workoutElement.id ? 'Update' : 'Save'}
                                    </Button>
                                  </Flex>
                                </Flex>
                              </Form>
                            )}
                          />
                        )}
                      </Mutation>
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default AddWorkoutElement;
