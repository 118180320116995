import React, {  } from 'react';
import { withApollo } from 'react-apollo';

const TmpTest = ({ onCapture, onClear, client }) => {
  return (
    <>
      <div />
    </>
  );
};

export default withApollo(TmpTest);
