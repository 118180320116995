import React, { Component, Fragment } from 'react';
import { Button, Flex, Box, Text, Heading, Card, Link as ExternalLink } from 'rebass';
import styled from 'styled-components';
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { Steps, Step, Wizard } from 'react-albus';
import { compose, withApollo } from 'react-apollo';
import { injectStripe } from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js';

import Address from './Address';
import Payment from './Payment';
import Confirmation from './Confirmation';
import Header from '../../components/Header';
import FullLayout from '../../components/FullLayout';
import ConfirmModal from '../../components/ConfirmModal';
import Mailbox from '../../components/icons/Mailbox';
import LoadingScreen from '../../components/LoadingScreen';
import withSession from '../../lib/withSession';
import withAnalytics from '../../lib/withAnalytics';

import {
  OPEN_ORDER,
  CANCEL_ORDER,
  CREATE_ORDER,
  COMPLETE_ORDER,
  LABS,
  KIT_PRICE,
  MOBILE_WALLET_PAYMENT,
  CHECK_PURCHASE_REFERRAL_BENEFITS,
  APPLY_COUPON,
  GET_LAST_ORDER_MINUTES_DIFF,
  ADD_BIOMARKERS_ORDER,
  MEMBERSHIP_CREATE_SESSION,
  CANCEL_ALL_ORDERS,
  SEND_KLAVIYO_STATUS,
  QUADPAY_SUBSCRIBED,
  CURRENT_USER,
} from '../../graphql';
import GiveKitToFriend from '../../components/GiveKitToFriend';
import { isToggleActive } from '../../components/featureToggle/toggles';
import ConfirmOrderIsNotDuplicateModal from '../../components/ConfirmOrderIsNotDuplicateModal';
import AdditionalBiomarkers from './AdditionalBiomarkers';
import { additionalBiomarkersHelper } from '../../utils/additionalBiomarkersUtils';
import SelectPlan from '../scheduleDoctor2/SelectPlan2';
import SelectMembership from './selectMembership';
import { isMobile } from 'react-device-detect';
import KlaviyoEventer from './KlaviyoEventer';
import QuadpayComplete from '../scheduleDoctor2/QuadpayComplete';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const MobileButton = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% + 32px);
  /* height: 40px; */
  justify-content: center;
  z-index: 1;
`;

const StyledLink = styled(ExternalLink)`
  font-size: 14px;
  color: #999;
  text-decoration: none;
  align-self: center;
`;
const InfoBox = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${(props) => props.theme.color.darkBlue};
  justify-content: center;
`;

const initialState = {
  order: {
    id: '',
    amount: '',
    items: [],
  },
  address: {
    firstName: '',
    lastName: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
  },
  prompt: false,
  ready: false,
  loading: false,
  coupon: undefined,
  giveToFriend: undefined,
  isCouponBOGOdisabledModal: undefined,
  canMakePayment: false,
  canMakePaymentApplePay: undefined,
  paymentRequest: undefined,
  isPaymentThroughWallet: false,
  wizard: undefined,
  isConfirmNotDuplicateModalOpen: false,
  biomarkers: [],
  additionalBiomarkersFeature: false,
  reverseT3Feature: false,
  vitaminDFeature: false,
  isComputingBiomarker: false,
  quadPaySubscribedData: undefined,
  isInitializingOrder: false,
};

class PurchaseComponent extends Component {
  constructor(props, context) {
    super(props, context);
    this.wizard = React.createRef();

    const biom = {
      additionalBiomarkersFeature: isToggleActive('KIT_ADDITIONAL_BIOMARKERS'),
      reverseT3Feature: isToggleActive('KIT_REVERSE_T3'),
      vitaminDFeature: isToggleActive('KIT_VITAMIN_D'),
    };

    this.state = {
      ...initialState,
      ...biom,
    };
  }

  setPaymentRequest = (centisizedAmount = 9900) => {
    const { order } = this.state;
    const pr = this.props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
      total: {
        label: 'Kit Order',
        amount: order && order.amount ? order.amount : centisizedAmount,
      },
    });

    // console.log('PAYMENT REQ', paymentRequest);

    pr.on('token', async ({ complete, token, ...data }) => {
      this.mobileWalletPayment(token);
      complete('success');
    });

    pr.canMakePayment().then((result) => {
      this.setState({
        canMakePayment: !!result,
        canMakePaymentApplePay: result && result.applePay,
      });
    });
    this.setState({
      paymentRequest: pr,
    });
  };

  updatePaymentRequest = (centisizedAmount = 9900) => {
    const { paymentRequest, order } = this.state;
    if (!paymentRequest) return;

    paymentRequest.update({
      total: {
        label: 'Kit Order',
        amount: order && order.amount ? order.amount : centisizedAmount,
      },
    });
  };
  mobileWalletPayment = async (token) => {
    const { order, giveToFriend, address, email, paymentRequest, wizard } = this.state;
    try {
      let addBuyOneGiveOneKit = null;
      if (giveToFriend) {
        addBuyOneGiveOneKit = {
          purchaserFirstName: address.firstName,
          purchaserLastName: address.lastName,
          refereeFirstName: giveToFriend.firstNameFriend,
          refereeLastName: giveToFriend.lastNameFriend,
          purchaserEmail: email,
          refereeEmail: giveToFriend.emailFriend,
          personalMessage: giveToFriend.personalMessageFriend,
        };
      }
      const a = await this.props.client.mutate({
        mutation: MOBILE_WALLET_PAYMENT,
        variables: {
          orderId: order.id,
          token: token.id,
          name: token.card.name,
          payerEmail: token.card.email,
          payerPhone: token.card.phone,
          address: {
            street1: token.card.address_line1,
            street2: token.card.address_line2,
            city: token.card.address_city,
            zip: token.card.address_zip,
            state: token.card.address_state,
          },
          addBuyOneGiveOneKit,
        },
      });

      let discount = 0;
      let couponCode = '';
      const opts = {
        cost: order && order.amount ? parseFloat((order.amount / 100).toFixed(2)) : 0,
      };
      for (let index = 0; index < order.items.length; index++) {
        const element = order.items[index];
        if (element.amount && element.amount < 0) {
          discount += element.amount;
          opts[element.description] = (element.amount / 100).toFixed(2);
          couponCode = element.parent;
        }
      }

      this.props.analytics.track('Kit Ordered', { ...opts });
      this.props.analytics.logRevenue(
        parseFloat(((order.amount + discount * -1) / 100).toFixed(2)),
        opts.cost,
        parseFloat((discount / 100).toFixed(2)),
        couponCode,
        [
          {
            productId: 'kit',
            name: 'Thyroid Test Kit',
            price: opts.cost,
            quantity: 1,
            category: 'kit',
          },
        ],
        order.id,
        'Marketing Website'
      );
      this.setState(
        {
          loading: false,
          ready: true,
          card: {
            brand: token.card.brand,
            last4: token.card.last4,
            expMonth: token.card.exp_month,
            expYear: token.card.exp_year,
          },
        },
        () => {
          wizard.next();
        }
      );
    } catch (error) {
      console.log('mobileWalletPayment err', error);
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((error) => {
          throw Error((error.message || '').replace(/Error:/g, '').trim());
        });
      } else {
        throw Error('An error occured');
      }
    }
  };

  initializeOrderProcess = async () => {
    const {
      client,
      toastManager,
      session: { firstName, lastName, address, email, isMember },
    } = this.props;
    this.setState({
      isInitializingOrder: true,
    });
    try {
      await client.mutate({ mutation: CANCEL_ALL_ORDERS });
      const amount = await this.fetchKitPrice();

      let referralBenefits;
      try {
        const { data } = await client.query({
          query: CHECK_PURCHASE_REFERRAL_BENEFITS,
          fetchPolicy: 'network-only',
        });
        if (data && data.checkPurchaseReferralBenefits && data.checkPurchaseReferralBenefits.referralCoupon) {
          referralBenefits = {
            referralCoupon: data.checkPurchaseReferralBenefits.referralCoupon,
            centisizedBalance: data.checkPurchaseReferralBenefits.centisizedBalance,
          };
        }
      } catch (error) {}
      let order = {
        id: '',
        amount,
        items: [],
      };

      if (referralBenefits) {
        const createOrderRes = await this.createOrder(null, {
          firstName,
          lastName,
          street1: address.street1 || '',
          street2: address.street2 || '',
          city: address.city || '',
          state: address.state || '',
          zip: address.zip || '',
        });

        const { data } = await this.props.client.mutate({
          mutation: APPLY_COUPON,
          variables: {
            id: createOrderRes.id,
            coupon: referralBenefits.referralCoupon,
            email: email,
          },
        });

        order = {
          ...createOrderRes,
          ...data.applyCoupon,
        };
      }

      this.setState({
        referralBenefits,
        order,
        address: {
          firstName,
          lastName,
          street1: address.street1 || '',
          street2: address.street2 || '',
          city: address.city || '',
          state: address.state || '',
          zip: address.zip || '',
        },
        ready: true,
      });
    } catch (error) {
      toastManager.add(error.message, { appearance: 'error' });
      this.setState({ ready: true });
    }

    try {
      await this.props.client.mutate({
        mutation: SEND_KLAVIYO_STATUS,
        variables: {
          step: 'Browsing Kit',
        },
      });
    } catch (error) {}
    this.setState({ isInitializingOrder: false });
  };

  componentDidMount = async () => {
    const {
      session: { isMember, isDiagnosed },
    } = this.props;

    let tag = '';

    if (this.props.location.pathname.indexOf('address') > -1) {
      tag = 'address';
    } else if (this.props.location.pathname.indexOf('complete') > -1) {
      tag = 'complete';
    } else if (this.props.location.pathname.indexOf('payment') > -1) {
      tag = 'payment';
    } else if (this.props.location.pathname.indexOf('confirm') > -1) {
      tag = 'confirm';
    }
    if (tag) {
      this.props.analytics.page(tag, 'purchaseKit');
    }

    // if (isMember || !isDiagnosed) {
      await this.initializeOrderProcess();
    // }
    this.setState({ ready: true });
  };

  componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;
    if (currentPage !== prevPage) {
      let tag = '';
      window.scrollTo(0, 0);
      if (currentPage.indexOf('address') > -1) {
        tag = 'address';
      } else if (currentPage.indexOf('complete') > -1) {
        tag = 'complete';
      } else if (currentPage.indexOf('payment') > -1) {
        tag = 'payment';
      } else if (currentPage.indexOf('confirm') > -1) {
        tag = 'confirm';
      } else if (currentPage.indexOf('selectMembership') > -1) {
        tag = 'selectMembership';
      } else if (currentPage.indexOf('selectPlan') > -1) {
        tag = 'selectPlan';
      } else if (currentPage.indexOf('additionalBiomarkers') > -1) {
        tag = 'additionalBiomarkers';
      } else if (currentPage.indexOf('address') > -1) {
        tag = 'address';
      }
      if (tag) {
        this.props.analytics.page(tag, 'purchaseKit');
      }
    }
  }

  handleSubmit = (key, values, wizard) => {
    this.setState({ [key]: { ...values } }, () => {
      if (this.state.canMakePayment && key === 'order') {
        this.updatePaymentRequest();
      }
    });

    if (wizard) {
      this.setState({
        wizard,
      });
      wizard.next();
    }
  };

  createInitialOrder = async (actions, values) => {
    // address: {
    //   firstName: '',
    //   lastName: '',
    //   street1: '',
    //   street2: '',
    //   city: '',
    //   state: '',
    //   zip: '',
    // },
    const {
      address: { firstName, lastName, street1, street2, city, state, zip },
    } = this.state;
    const { session } = this.props;
    let isMember = false;
    if (session && session.isMember) {
      isMember = true;
    }
    try {
      const { data } = await this.props.client.mutate({
        mutation: CREATE_ORDER,
        variables: {
          sku: isMember ? window._env_.REACT_APP_STRIPE_SKU_KIT_MEMBER : window._env_.REACT_APP_STRIPE_SKU,
          name: `${firstName} ${lastName}`,
          address: {
            street1,
            street2,
            city,
            state,
            zip,
          },
        },
      });
      this.setState({
        order: data.createOrder,
      });
      return data.createOrder;
    } catch (error) {
      if (actions) {
        actions.setSubmitting(false);
      }
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((error) => {
          throw Error((error.message || '').replace(/Error:/g, '').trim());
        });
      } else {
        throw Error('An error occured');
      }
    }
  };

  createOrder = async (actions, values) => {
    const { firstName, lastName, street1, street2, city, state, zip } = values;
    const { session } = this.props;
    let isMember = false;
    if (session && session.isMember) {
      isMember = true;
    }
    try {
      const { data } = await this.props.client.mutate({
        mutation: CREATE_ORDER,
        variables: {
          sku: !isMember ? window._env_.REACT_APP_STRIPE_SKU : window._env_.REACT_APP_STRIPE_SKU_KIT_MEMBER,
          name: `${firstName} ${lastName}`,
          address: {
            street1,
            street2,
            city,
            state,
            zip,
          },
        },
      });

      return data.createOrder;
    } catch (error) {
      if (actions) {
        actions.setSubmitting(false);
      }
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((error) => {
          throw Error((error.message || '').replace(/Error:/g, '').trim());
        });
      } else {
        throw Error('An error occured');
      }
    }
  };

  addBiomarkerToOrder = async (biomarkers) => {
    const {
      client,
      session: { email, isMember },
    } = this.props;
    const { address, order, couponcode, isComputingBiomarker, referralBenefits } = this.state;
    if (isComputingBiomarker) return;
    this.setState({ isComputingBiomarker: true });
    try {
      const res = await client.mutate({
        mutation: ADD_BIOMARKERS_ORDER,
        variables: {
          sku: isMember ? window._env_.REACT_APP_STRIPE_SKU_KIT_MEMBER : window._env_.REACT_APP_STRIPE_SKU,
          name: `${address.firstName} ${address.lastName}`,
          address: {
            street1: address.street1,
            street2: address.street2,
            city: address.city,
            state: address.state,
            zip: address.zip,
          },
          biomarkers: biomarkers,
          orderId: order.id,
        },
      });
      let coupn = referralBenefits && referralBenefits.referralCoupon ? referralBenefits.referralCoupon : couponcode;
      // console.log('coupn', coupn);

      if (coupn) {
        // await this.handleCoupon(couponcode, null, true);

        const { data } = await this.props.client.mutate({
          mutation: APPLY_COUPON,
          variables: {
            id: res.data.addBiomarkerToOrder.id,
            coupon: coupn,
            email: email,
          },
        });
        this.setState({
          order: {
            ...res.data.addBiomarkerToOrder,
            ...data.applyCoupon,
          },
          isComputingBiomarker: false,
        });
      } else {
        this.setState({
          order: res.data.addBiomarkerToOrder,
          isComputingBiomarker: false,
        });
      }
    } catch (error) {}
  };

  fetchKitPrice = async () => {
    try {
      const { data } = await this.props.client.query({ query: KIT_PRICE });
      return data.kitPrice.price;
    } catch (error) {
      throw Error('Error fetching kit price, please try again.');
    }
  };

  handleSubmitGiveToFriend = async (key, values, wizard) => {
    this.setState({ [key]: { ...values } });

    if (wizard) {
      wizard.next();
    }
  };

  handlePayment = async (actions, wizard) => {
    const { address, order } = this.state;
    const { toastManager } = this.props;

    try {
      if (!order.id) {
        const response = await this.createOrder(actions, address);
        this.setState(
          {
            isPaymentThroughWallet: false,
          },
          () => this.handleSubmit('order', response, wizard)
        );
      } else {
        wizard.next();
      }
    } catch (error) {
      toastManager.add(error.message, { appearance: 'error' });
    }
  };

  continuePayWithWallet = async (actions, wizard, sourceId) => {
    const { address, order } = this.state;
    // if (!order.id) {
    const response = await this.createOrder(actions, address, sourceId);
    this.setState(
      {
        isPaymentThroughWallet: true,
      },
      () => this.handleSubmit('order', response, wizard)
    );
    // } else {
    // wizard.next();
    // }
  };

  handleClose = async () => {
    const amount = await this.fetchKitPrice();

    await this.props.client.mutate({
      mutation: CANCEL_ORDER,
      variables: { id: this.state.order.id },
      refetchQueries: [
        {
          query: OPEN_ORDER,
        },
      ],
    });

    this.setState({
      ...initialState,
      order: {
        id: '',
        amount,
        items: [],
      },
      ready: true,
    });

    this.props.history.push('/purchase/address');
  };

  handleConfirm = () => {
    const { additionalBiomarkersFeature } = this.state;
    this.setState({ prompt: false });

    this.props.history.push(additionalBiomarkersFeature ? '/purchase/additionalBiomarkers' : '/purchase/payment');
  };

  handleConfirmPurchase = async () => {
    // console.log('CLICKED');
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { client } = this.props;
    const { order, giveToFriend, referralBenefits } = this.state;
    const {
      session: { firstName, lastName, email },
      toastManager,
    } = this.props;

    const { data } = await client.query({
      query: GET_LAST_ORDER_MINUTES_DIFF,
      variables: {
        email: email,
      },
      fetchPolicy: 'network-only',
    });

    // console.log('DTA', data);

    if (data.getLastOrderMinutesDif && data.getLastOrderMinutesDif.elapsedMinutes) {
      // console.log('CONFIRM');
      this.setState({
        isConfirmNotDuplicateModalOpen: true,
        loading: false,
      });
      return;
    } else {
      console.log('NO CONFIRM');
      await this.proceedWithPurchase();
    }
  };

  proceedWithPurchase = async () => {
    this.setState({
      isConfirmNotDuplicateModalOpen: false,
      loading: true,
    });
    const { order, giveToFriend, referralBenefits } = this.state;
    const {
      session: { firstName, lastName, email },
      toastManager,
    } = this.props;

    const vars = {
      id: order.id,
    };

    if (referralBenefits && referralBenefits.referralCoupon) {
      vars.referralBenefitsCoupon = referralBenefits.referralCoupon;

      vars.referralBenefitsCentisizedBalance = referralBenefits.centisizedBalance;
    }

    if (giveToFriend) {
      vars.addBuyOneGiveOneKit = {
        purchaserFirstName: firstName,
        purchaserLastName: lastName,
        refereeFirstName: giveToFriend.firstNameFriend,
        refereeLastName: giveToFriend.lastNameFriend,
        purchaserEmail: email,
        refereeEmail: giveToFriend.emailFriend,
        personalMessage: giveToFriend.personalMessageFriend,
      };
    }
    try {
      await this.props.client.mutate({
        mutation: COMPLETE_ORDER,
        variables: vars,
        refetchQueries: [{ query: LABS }, {query: CURRENT_USER}],
        awaitRefetchQueries: true,
      });

      // await this.props.refetch();

      let discount = 0;
      let couponCode = '';
      const opts = {
        cost: order && order.amount ? parseFloat((order.amount / 100).toFixed(2)) : 0,
      };
      for (let index = 0; index < order.items.length; index++) {
        const element = order.items[index];
        if (element.amount && element.amount < 0) {
          discount += element.amount;
          opts[element.description] = (element.amount / 100).toFixed(2);
          couponCode = element.parent;
        }
      }

      this.props.analytics.track('Kit Purchased', { ...opts });
      this.props.analytics.logRevenue(
        parseFloat(((order.amount + discount * -1) / 100).toFixed(2)),
        opts.cost,
        parseFloat((discount / 100).toFixed(2)),
        couponCode,
        [
          {
            productId: 'kit',
            name: 'Thyroid Test Kit',
            price: opts.cost,
            quantity: 1,
            category: 'kit',
          },
        ],
        order.id
      );
      this.setState({
        ...initialState,
        ready: true,
        loading: false,
      });

      this.props.history.push('/purchase/complete');
    } catch (err) {
      this.setState({ loading: false, ready: true });
      console.log('Error purchase', err);
      toastManager.add('Error confirming purchase, please try again', {
        appearance: 'error',
      });
    }
  };

  createSubscription = async (frequency) => {
    let priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_MONTHLY;
    let freq = frequency === 'annualy' ? 'ma' : 'mm';

    const ft_membership_price_2024 = isToggleActive('MEMBERSHIP_PRICE_2024');
    if (frequency === 'annualy') {
      if (ft_membership_price_2024) {
        priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL_2024;
      } else {
        priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_ANNUAL;
      }
    } else {
      priceId = window._env_.REACT_APP_MEMBERSHIP_SKU_MONTHLY;
    }

    const createSession = await this.props.client.mutate({
      mutation: MEMBERSHIP_CREATE_SESSION,
      variables: {
        priceId: priceId,
        forwardUrl: `subscribed/purchase/success/${freq}`,
        cancel_url: 'purchase',
      },
    });

    const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_KEY);
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({
      sessionId: createSession.data.createCheckoutSession.sessionId,
    });
  };

  setQuadPayData = (accountNumber, cvc, expMonth, expYear, quadpayOrderReference, callback) => {
    // console.log('setQuadPayData', {
    //   accountNumber,
    //   cvc,
    //   expMonth,
    //   expYear,
    //   quadpayOrderReference,
    // });
    this.setState(
      {
        quadPaySubscribedData: {
          accountNumber,
          cvc,
          expMonth,
          expYear,
          quadpayOrderReference,
        },
        // pleaseWait: true,
      },
      async () => {
        if (!!this.state.quadPaySubscribedData) {
          await this.props.client.mutate({
            mutation: QUADPAY_SUBSCRIBED,
            variables: {
              ...this.state.quadPaySubscribedData,
            },
            refetchQueries: [{ query: CURRENT_USER }],
          });

          this.props.analytics.track('QuadPay - Membership', {
            orderReference: quadpayOrderReference,
          });
        }

        if (!!callback) {
          callback();
        }
      }
    );
  };

  render() {
    const { history, session, refetch } = this.props;
    const {
      order,
      prompt,
      address,
      ready,
      loading,
      coupon,
      giveToFriend,
      isCouponBOGOdisabledModal,
      canMakePayment,
      canMakePaymentApplePay,
      paymentRequest,
      isPaymentThroughWallet,
      referralBenefits,
      isConfirmNotDuplicateModalOpen,
      biomarkers,
      additionalBiomarkersFeature,
      wizard,
      isComputingBiomarker,
      isInitializingOrder,
    } = this.state;

    if (!ready || loading || !!isInitializingOrder) {
      return <LoadingScreen loading={true} text="Please Wait" />;
    }

    if (prompt) {
      return (
        <Fragment>
          <ConfirmModal isOpen={prompt} onClose={this.handleClose} onBackgroundClick={this.handleClose} onEscapeKeydown={this.handleClose} onConfirm={this.handleConfirm}>
            <ModalInner>
              <Heading mb={4} textAlign="center" fontWeight={500}>
                Existing Order Found
              </Heading>

              <Text fontSize={3} textAlign="center" mb={4}>
                Would you like to continue where you left off?
              </Text>
            </ModalInner>
          </ConfirmModal>
          <FullLayout />
        </Fragment>
      );
    }

    const stepsVal = [
      // {
      //   comp: (
      //     <Step id="selectMembership">
      //       {/* <div>A</div> */}
      //       {/* <Container> */}
      //       {/* <InnerContainer> */}
      //       <Flex justifyContent="center" alignItems="center" flex={1} mr={[0, '-32px']}>
      //         <SelectMembership
      //           onSubscribe={(wizard) => {
      //             if (wizard) {
      //               wizard.push('selectPlan');
      //             }
      //           }}
      //           onContinue={async (wizard) => {
      //             await this.initializeOrderProcess();
      //             if (wizard) {
      //               wizard.push('address');
      //             }
      //           }}
      //         />
      //       </Flex>
      //       {/* </InnerContainer> */}
      //       {/* </Container> */}
      //     </Step>
      //   ),
      //   key: 'selectMembership',
      //   display: (!session || !session.isMember) && session.isDiagnosed && isToggleActive('MEMBERSHIP'),
      // },
      // {
      //   comp: (
      //     <Step id="selectPlan">
      //       {/* <Container> */}
      //       {/* <InnerContainer> */}
      //       <Flex justifyContent="center" alignItems="center" flex={1} mr="-32px">
      //         <SelectPlan
      //           setQuadPayData={this.setQuadPayData}
      //           onSubscribe={async (frequency) => {
      //             await this.initializeOrderProcess();
      //             this.createSubscription(frequency);
      //           }}
      //           continueWithQuadPay={async () => {
      //             await this.initializeOrderProcess();
      //             this.wizard.current.next();
      //           }}
      //         />
      //       </Flex>
      //       {/* </InnerContainer> */}
      //       {/* </Container> */}
      //     </Step>
      //   ),
      //   key: 'selectPlan',
      //   display: (!session || !session.isMember) && session.isDiagnosed && isToggleActive('MEMBERSHIP'),
      // },
      // {
      //   comp: (
      //     <Step id="welcomemembership" key="welcomemembership">
      //       <Box style={{ margin: '0 auto' }}>
      //         <QuadpayComplete
      //         title={'Your member price has been applied'}
      //           // text="You can now enjoy member pricing on your kit purchase"
      //           hideText={true}
      //           continueWithQuadPay={async () => {
      //             this.wizard.current.push('address');
      //           }}
      //         />
      //       </Box>
      //     </Step>
      //   ),
      //   key: 'welcomemembership',
      //   display: true
      // },
      {
        comp: (
          <Step id="buyonegiveone" key="buyonegiveone">
            <GiveKitToFriend values={giveToFriend} onSubmit={this.handleSubmitGiveToFriend} onSkipMoveTo="address" />
          </Step>
        ),
        key: 'buyonegiveone',
        display: isToggleActive('BUY_ONE_GIVE_ONE_KIT'),
      },
      {
        comp: (
          <Step id="address" key="address">
            <Address values={address} onSubmit={this.handleSubmit} />
          </Step>
        ),
        key: 'address',
        display: true,
      },
      {
        comp: (
          <Step id="additionalBiomarkers" key="additionalBiomarkers">
            <AdditionalBiomarkers
              createInitialOrder={this.createInitialOrder}
              order={order}
              address={address}
              selectedBiomarkers={biomarkers || []}
              isComputingBiomarker={isComputingBiomarker}
              addItemToOrder={(itm) => {
                const bms = [...(biomarkers || []), itm];
                // console.log({ bms });
                this.setState({
                  biomarkers: bms,
                });
                this.addBiomarkerToOrder(bms);
              }}
              removeItemFromOrder={(itm) => {
                const bms = biomarkers.filter((x) => x !== itm);

                this.setState({
                  biomarkers: bms,
                });
                this.addBiomarkerToOrder(bms);
              }}
              onNext={(wizard) => {
                if (isToggleActive('MOBILE_WALLET')) {
                  this.setPaymentRequest();
                } else {
                  this.state = {
                    canMakePayment: false,
                  };
                }
                if (wizard) {
                  wizard.next();
                }
              }}
            />
          </Step>
        ),
        key: 'additionalBiomarkers',
        display: additionalBiomarkersFeature,
      },
      {
        comp: (
          <Step id="payment" key="payment">
            {/* <Elements> */}
            <Payment
              address={address}
              order={order}
              refetch={refetch}
              defaultCard={session.card}
              onSubmit={this.handlePayment}
              // setSourceId={this.setSourceId}
              canMakePayment={canMakePayment}
              canMakePaymentApplePay={canMakePaymentApplePay}
              continuePayWithWallet={this.continuePayWithWallet}
            />
            {/* </Elements> */}
          </Step>
        ),
        key: 'payment',
        display: true,
      },
      {
        comp: (
          <Step id="confirm" key="confirm">
            <Confirmation
              isPaymentThroughWallet={isPaymentThroughWallet}
              canMakePayment={canMakePayment}
              paymentRequest={paymentRequest}
              address={address}
              order={order}
              refetch={refetch}
              source={session.card}
              onSubmit={this.handleSubmit}
              onConfirmPurchase={this.handleConfirmPurchase}
              couponState={coupon}
              referralBenefits={referralBenefits}
              setCouponState={(val) => this.setState({ coupon: val })}
              session={session}
              bogoInvalidCoupon={() => {
                this.setState({
                  isCouponBOGOdisabledModal: true,
                });
              }}
            />
          </Step>
        ),
        key: 'confirm',
        display: true,
      },
      {
        comp: (
          <Step id="complete" key="complete">
            <Box flex={1} flexDirection="column">
              {!!isToggleActive('HOLIDAY_DELAYS') && (
                <Card
                  p={4}
                  mb={4}
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#d9e6f9',
                  }}
                >
                  <Heading textAlign="center" mb={4} color="#344f79">
                  Temporary Lab Closure for Thanksgiving
                  </Heading>
                  <Text textAlign="center" mb={4} color="#344f79">
                  Our laboratory will be closed on Thursday 11/23 and Friday 11/24 in observance of the Thanksgiving holiday. There will be a slight delay in the processing and delivery of your test kit results. As soon as our lab receive your sample we will be able to share an exact ETA. Please take these closure dates into consideration if you decide to book a visit before your results are released.
                  </Text>
                </Card>
              )}
              <KlaviyoEventer event="Kit Ordered" />
              <Card p={4}>
                <Flex flexDirection="column">
                  <Heading textAlign="center" fontWeight={600} mb={4}>
                    Your Order is Confirmed
                  </Heading>

                  <Text textAlign="center" mb={4}>
                    Your order has been placed and you should receive a kit soon.
                    {giveToFriend && giveToFriend.refereeFirstName ? (
                      <>
                        <br />
                        {`${giveToFriend.refereeFirstName} ${giveToFriend.refereeLastName} will receive an email with information on how to claim your gifted kit.`}
                      </>
                    ) : (
                      ''
                    )}
                  </Text>

                  <Flex justifyContent="center">
                    <Button variant="pink" width={[1, 1 / 3]} onClick={() => history.push('/')}>
                      Your Dashboard
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </Box>
          </Step>
        ),
        key: 'complete',
        display: true,
      },
    ];

    return (
      <Fragment>
        <Container flex={1} px={3}>
          {history.location.pathname !== '/purchase/selectMembership' && (
            <Flex flex={1}>
              <Header dark={false}>
                <Text color="#486288" fontSize={3} fontWeight={500} textAlign="center">
                  Purchase a Thyroid Blood Test Kit
                </Text>
              </Header>
            </Flex>
          )}
          <Flex
            flexDirection={
              history.location.pathname !== '/purchase/address' && history.location.pathname !== '/purchase/additionalBiomarkers'
                ? ['column-reverse', 'column-reverse', 'row']
                : ['column', 'column', 'row']
            }
          >
            <Flex flex={1} mr={[0, 0, 4]} mb={4}>
              <Wizard ref={this.wizard} history={history} basename="/purchase">
                <Steps>{stepsVal.filter((x) => x.display).map((x) => x.comp)}</Steps>
              </Wizard>
            </Flex>
            {history.location.pathname !== '/purchase/complete' &&
              history.location.pathname !== '/purchase/selectMembership' &&
              history.location.pathname !== '/purchase/selectPlan' &&
              history.location.pathname !== '/purchase/welcomemembership' && (
                <Flex width={[1, 1, 1 / 3]} mb={[4, 4, 0]} flexDirection="column">
                  <Box>
                    <Card p={4}>
                      <Flex flexDirection="column">
                        <Flex mb={3} justifyContent="center">
                          <Mailbox width={184} />
                        </Flex>

                        <Flex flex={1} mb={3} flexDirection="column">
                          {order.items.map((item, i) => (
                            <Flex flex={1} key={i}>
                              <Flex flex={1}>
                                <Text fontWeight={300}>{(item.description || '').startsWith('REF_') ? 'Referral credits' : item.description}</Text>
                              </Flex>
                              <Flex justifyContent="flex-end">${(item.amount / 100).toFixed(2)}</Flex>
                            </Flex>
                          ))}
                          <Text fontSize={3} fontWeight={500} textAlign="center" mt={3}>
                            {order.items.length > 0 ? 'Total' : 'Complete Thyroid Test'} ${order.amount ? (order.amount / 100).toFixed(2) : '0.00'}
                          </Text>
                        </Flex>

                        <Text fontWeight={300} textAlign="center">
                          You'll collect your sample at home.
                        </Text>

                        {/* <StyledLink mt={3} href="https://palomahealth.com/kit">
                        Learn More
                      </StyledLink> */}

                        {history.location.pathname === '/purchase/additionalBiomarkers' ? (
                          <Button
                            style={{
                              alignSelf: 'center',
                              width: '160px',
                              maxWidth: '160px',
                              marginTop: '16px',
                            }}
                            variant="pink"
                            onClick={() => {
                              wizard.next();
                            }}
                          >
                            Checkout
                          </Button>
                        ) : null}
                      </Flex>
                    </Card>
                  </Box>
                </Flex>
              )}
          </Flex>
          <Fragment>
            <ConfirmModal
              confirmLabel="OK"
              isOpen={isCouponBOGOdisabledModal}
              okOnly
              onClose={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
              onConfirm={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
              onBackgroundClick={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
              onEscapeKeydown={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
            >
              <ModalInner>
                <Heading mb={4} mx={3} textAlign="center">
                  January is National Thyroid Awareness Month – Buy a Kit, Give a Kit FREE
                </Heading>
                <InfoBox bg="#D9E6F9" mb={4}>
                  <Text fontSize={3} fontWeight={700} textAlign="center">
                    Paloma is committed to elevating and spreading access to great thyroid care. In the spirit of Thyroid Awareness Month, we are giving a free kit for every kit purchased. Instead of
                    using a promo code, in January only, Paloma is providing the ability to gift a friend or loved one a free thyroid test to.
                  </Text>
                </InfoBox>
              </ModalInner>
            </ConfirmModal>
          </Fragment>
        </Container>
        {/* </FeatureToggleProvider> */}
        <ConfirmOrderIsNotDuplicateModal
          isOpen={isConfirmNotDuplicateModalOpen}
          onCancel={() => {
            this.setState({
              isConfirmNotDuplicateModalOpen: false,
              loading: false,
            });
            this.props.history.push('/');
          }}
          onProceed={this.proceedWithPurchase}
        />
        {isComputingBiomarker ? <LoadingScreen bg={'rgba(0,0,0,0.33)'} loading={true} showLogo={false} text="Adding Biomarker" /> : null}
        <FullLayout />

        {isMobile &&
          history.location.pathname === '/purchase/selectMembership' && (
            <MobileButton
              onClick={() => {
                history.push('/purchase/selectPlan');
              }}
            >
              <Box
                style={{
                  background: '#354E79',
                  color: 'white',
                  width: '100%',
                  padding: '20px',
                  textAlign: 'center',
                  fontSize: '20px',
                }}
              >
                Join Now
              </Box>
            </MobileButton>
          )}
      </Fragment>
    );
  }
}

export default injectStripe(
  compose(
    withApollo,
    withRouter,
    withToastManager,
    withSession,
    withAnalytics
  )(PurchaseComponent)
);
