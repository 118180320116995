import React, {  } from 'react';
import { Button } from 'rebass';
import {
  SubscriptionRegistrationComplete,
} from '../../components/PleaseWait';


const QuadpayComplete = ({
  continueWithQuadPay,
text,
title,
hideText
}) => {  
    return (
      <SubscriptionRegistrationComplete text={text} hideText={hideText} title={title}>
        <Button variant="primary" onClick={continueWithQuadPay}>
          Continue
        </Button>
      </SubscriptionRegistrationComplete>
    );
};
export default QuadpayComplete;
