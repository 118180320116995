import React, { useState } from 'react';
import { Box, Heading, Text, Flex, Button } from 'rebass';
import styled from 'styled-components';
import { Query, withApollo } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {
  ADMIN_GET_WORKOUTS_ELEMENTS,
  ADMIN_CHANGE_ORDER_WORKOUT_ELEMENT,
  ADMIN_DELETE_WORKOUT_ELEMENT,
} from '../../graphql';
import AddWorkoutElement from './modalContent/AddWorkoutElement';
import ConfirmModal from '../../components/ConfirmModal';

const ModalInner = styled(Box)`
  max-width: 850px;
  width: 100%;
  max-height: 600px;
  overflow: auto;
`;

const WorkoutElements = ({ original, client }) => {
  const [isAdd, setIsAdd] = useState(null);

  const deleteOnClick = async id => {
    var r = window.confirm(
      'Confirm Delete. (Any screen logged by user will be marked as inactive instead)'
    );
    if (r === true) {
      await client.mutate({
        mutation: ADMIN_DELETE_WORKOUT_ELEMENT,
        variables: {
          screenId: id,
        },
        refetchQueries: [
          {
            query: ADMIN_GET_WORKOUTS_ELEMENTS,
            variables: {
              workoutId: original.id,
            },
          },
        ],
      });
    } else {
      return;
    }
  };

  const changeDirectionOnClick = async (id, direction) => {
    await client.mutate({
      mutation: ADMIN_CHANGE_ORDER_WORKOUT_ELEMENT,
      variables: {
        id: id,
        direction,
      },
      refetchQueries: [
        {
          query: ADMIN_GET_WORKOUTS_ELEMENTS,
          variables: {
            workoutId: original.id,
          },
        },
      ],
    });
  };

  const coursesScreensColumns = [
    {
      Header: 'title',
      accessor: 'title',
      Cell: (row) => {
        return <div title={row.original.title}>{row.original.title}</div>;
      },
    },
    {
      Header: 'file Link',
      accessor: 'fileLink',
    },
    {
      Header: 'Order',
      accessor: 'order',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {(row.original.order || 0) + 1}
          </div>
        );
      },
    },
    {
      Header: 'Author',
      accessor: 'author',
      Cell: (row) => {
        return <div>{row.original.author.name}</div>;
      },
    },
    {
      Header: 'Difficulty',
      accessor: 'difficulty',
      Cell: (row) => {
        return <div>{row.original.difficulty.name}</div>;
      },
    },
    {
      Header: 'Categories',
      accessor: 'category',
      Cell: (row) => {
        return <div>{(row.original.category || []).map((x) => x.name).join(', ')}</div>;
      },
    },
    {
      Header: 'active',
      accessor: 'isActive',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.original.isActive ? '✓' : ''}
          </div>
        );
      },
    },
    {
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            <div title="delete" style={{ cursor: 'pointer' }} onClick={() => deleteOnClick(row.original.id)}>
              🗑
            </div>
            <div title="move order up" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => changeDirectionOnClick(row.original.id, 'UP')}>
              ↑
            </div>
            <div title="move order down" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => changeDirectionOnClick(row.original.id, 'DOWN')}>
              ↓
            </div>
            <div title="edit" style={{ cursor: 'pointer', marginLeft: '12px' }} onClick={() => setIsAdd(row.original)}>
              ✎
            </div>
          </Flex>
        );
      },
    },
  ];
  return (
    <>
      <Query query={ADMIN_GET_WORKOUTS_ELEMENTS} variables={{ workoutId: original.id }} fetchPolicy="network-only">
        {({ data, loading, error }) => {
          if (loading) return <div />;

          return (
            <div style={{ margin: '12px' }}>
              <Flex flexDirection="row" mb={4} alignItems="center">
                <Text style={{ flex: 1 }}>Series</Text>

                <Button
                  style={{ background: '#344f79' }}
                  onClick={() => {
                    setIsAdd({});
                  }}
                >
                  Add Series
                </Button>
              </Flex>
              <ReactTable
                data={data.getAdminWorkoutElements}
                columns={coursesScreensColumns}
                defaultPageSize={data.getAdminWorkoutElements && data.getAdminWorkoutElements.length > 3 ? data.getAdminWorkoutElements.length : 3}
                pageSize={data.getAdminWorkoutElements && data.getAdminWorkoutElements.length > 3 ? data.getAdminWorkoutElements.length : 3}
                getProps={() => ({ someFunc: (id) => alert('clicked ' + id) })}
                showPagination={false}
              />

              <ConfirmModal
                childrenManaged
                isOpen={!!isAdd}
                confirmLabel="create"
                onClose={() => {
                  setIsAdd(null);
                }}
                onBackgroundClick={() => {
                  setIsAdd(null);
                }}
                onEscapeKeydown={() => {
                  setIsAdd(null);
                }}
                onConfirm={() => {
                  setIsAdd(null);
                }}
              >
                <ModalInner>
                  <Heading fontSize="20px" mb={3}>
                    {!!isAdd && !!isAdd.id ? 'Update Difficulty' : 'Add Difficulty'}
                  </Heading>
                  <AddWorkoutElement
                    workoutElement={isAdd}
                    workoutElementLength={data.getAdminWorkoutElements.length}
                    workoutId={original.id}
                    onClose={() => {
                      setIsAdd(null);
                    }}
                    onSave={() => {
                      setIsAdd(null);
                    }}
                  />
                </ModalInner>
              </ConfirmModal>
            </div>
          );
        }}
      </Query>
    </>
  );
};

export default withApollo(WorkoutElements);
