import React from 'react';
import IconContainer from './IconContainer';

const Thyroid = props => (
  <IconContainer {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.53 220.49">
      <path
        d="M62.7.55c3.58-1.18,6.73-.24,10.15.86C68,2.86,62.65,3.65,58.16,5.91,53.72,8,51.85,13.74,52.51,18.29c1.58,7.78,6.73,15.11,7.53,23-.52,4.91-5.48,9.36-8.16,13.36-2.71-13-3.31-26.62-3.75-39.87C50.61,8.38,56.08,2.63,62.7.55Z"
        fill="#9BBDF9"
      />
      <path
        d="M48.13,14.78c.44,13.25,1,26.86,3.75,39.87a66.47,66.47,0,0,0,3.78,9.83c.41,2.72.67,5.46.89,8.2a57.32,57.32,0,0,0,2.26,18.74c1.45,5.88,5.18,9.9,9.62,13.76a82.66,82.66,0,0,0,8.29,6.74c4.37,3.23,9.22,5,14.27,6.83l-.58,2.34c-7.92-2.24-15.31-5-22.08-9.79.67,5.83,1,11.68,1.18,17.54.47,17.52.38,35.1,0,52.61l0,1.58-.36,1.87a99.81,99.81,0,0,1-3.21-14.82c-15.72,4.19-27.12,15.21-42.55,19.77A77.83,77.83,0,0,1,0,193.52c7.14-3,14.62-4.94,21.71-8.08C32.1,181.25,40.58,174.58,51.25,171a48.17,48.17,0,0,1,14.39-2.5A232.33,232.33,0,0,1,65,124.9c.43-5,.9-10,1.36-15A66.18,66.18,0,0,1,55.91,99.36c-1.55.11-3.09.26-4.64.32a31.82,31.82,0,0,1-14.91-29.4c.7-7.3,2.75-14.51,4.9-21.5.77-2.41,2.83-3.06,4.77-4.36A144.59,144.59,0,0,1,46.5,23.3,28.66,28.66,0,0,1,48.13,14.78Z"
        fill="#9BBDF9"
      />
      <path
        d="M192.05,14.71a192.07,192.07,0,0,1-1.76,31.87c1.7.14,4.06-.88,5.15.8,1.33,1.81,1.21,4.74,1.59,6.89.75,7.36,3,15.51,1.84,22.8a35.48,35.48,0,0,1-10.43,20.12c-2.35,2.32-5.4-.42-7.48-1.76-2.8,5.64-7.57,8.51-12.28,12.44-6.38,4.93-13.29,9-20.08,13.26l-1-2.47c9.1-7,18.86-14.18,26.65-22.67,4.94-7.43,4.45-19.19,5-27.75.24-5.8,3.72-9.57,5.08-15,1.9-7.21,3.06-14.64,4.44-22C189.88,25.79,190.41,20,192.05,14.71Z"
        fill="#9BBDF9"
      />
      <path
        d="M45.9,46.61c.76,4.47.93,9,2.3,13.16,1,3.26,2.53,6,2.73,9.5.28,4.69-.12,9.32.55,14,.56,4.94,2.13,9.55,3.13,14.39a16.06,16.06,0,0,1-4.34-3.14,34.16,34.16,0,0,1-8.33-25.28c.31-4,1-8,1.45-12C43.93,53.48,43.69,50,45.9,46.61Z"
        fill="#fff"
      />
      <path
        d="M193,48.82c.35,2.77,0,5.66,0,8.45-.12,7,1.24,13.1.18,20s-3.85,12.92-7.48,18.76l-4.58-1.29a56.23,56.23,0,0,0,3.32-18.48c.18-4.72-.4-9,1.67-13.4,2-4,2.78-8.21,3.77-12.52C190.61,48.9,191.43,46.55,193,48.82Z"
        fill="#fff"
      />
      <path
        d="M58.22,55.64c2.06,9.79,2.19,19.87,4.48,29.66,1.13,6.91,4.61,13,5.73,19.88-4.44-3.86-8.17-7.88-9.62-13.76.78-1.35,1.81-2.14,1.51-3.82-.5-5.06-2.23-10.13-3.77-14.92-.22-2.74-.48-5.48-.89-8.2A31.79,31.79,0,0,1,58.22,55.64Z"
        fill="#9BBDF9"
      />
      <path
        d="M64,60.73c1.08,6.18,1.38,12.39,2.67,18.52,1,4.94,2.61,9.69,3.67,14.62A23.55,23.55,0,0,1,65.64,87C62.39,78.62,61.35,69.47,64,60.73Z"
        fill="#9BBDF9"
      />
      <path
        d="M56.55,72.68c1.54,4.79,3.27,9.86,3.77,14.92.3,1.68-.73,2.47-1.51,3.82A57.32,57.32,0,0,1,56.55,72.68Z"
        fill="#9BBDF9"
      />
      <path
        d="M51.27,99.68c1.55-.06,3.09-.21,4.64-.32A66.18,66.18,0,0,0,66.35,110c-.46,5-.93,10-1.36,15a31,31,0,0,1-5.75-6.3C55.57,112.74,52.57,106.53,51.27,99.68Z"
        fill="#9BBDF9"
      />
      <path
        d="M76.48,106.17c5.79,5.35,13.56,8.76,21,11.14L95,116.86c4.76,4.57,10.59,7.24,16.78,9.29,10.53,3.41,23.26.83,31.12-7.1l4.77-.39,1,2.47c6.79-4.3,13.7-8.33,20.08-13.26-.84,2.27-1.67,4.37-3.53,6-6.72,6.15-14.63,11.72-23.27,14.77-8.84,3.21-18.94,3.09-28.19,2.23C99,129,84.85,125.11,72.24,117.2c2.19,3.5,4.87,6.84,5.39,11.06,1,6.9.57,14.05.68,21,.26,13.61.35,27.23.11,40.83-3-2.38-6.07-4.61-9-7.07l0-1.58q3.52,2.76,7.24,5.23c-.07-18.24.59-36.62-.7-54.83l-6.51-3c-.16-5.86-.51-11.71-1.18-17.54,6.77,4.77,14.16,7.55,22.08,9.79l.58-2.34c-5-1.84-9.9-3.6-14.27-6.83C76.65,110,76.57,108.08,76.48,106.17Z"
        fill="#9BBDF9"
      />
      <path
        d="M173.34,105.5c-4.51,26.24.81,52.68-5.39,79.45-1.38-6-1.25-12.55-1.62-18.7-.8-17.64-1.68-35,2.9-52.24A22.37,22.37,0,0,1,173.34,105.5Z"
        fill="#9BBDF9"
      />
      <path
        d="M95,116.86l2.51.45.75.18c9.34,2.24,17.57,5.21,27.35,4.4,6.86-.3,12.79-2.81,19.15-5l-1.86,2.21c-7.86,7.93-20.59,10.51-31.12,7.1C105.56,124.1,99.73,121.43,95,116.86Z"
        fill="#9BBDF9"
      />
      <path
        d="M69.51,128.84l6.51,3c1.29,18.21.63,36.59.7,54.83q-3.72-2.48-7.24-5.23C69.89,163.94,70,146.36,69.51,128.84Z"
        fill="#fff"
      />
      <path
        d="M97.84,158.53a9.81,9.81,0,0,1,10.43,3c4.53,5.47,6.84,11.88,8.57,18.66,3.74.53,7.44,1.18,11.11,2-4.47.38-8.93.31-13.41.26-1.65-5.89-3.44-13-7.41-17.77-2-2.32-4.75-4-7.83-2.7-4.06,1.68-6.93,6.39-9,10.09-4.63,8.59-5.08,18.23-3,27.64-.12,3.72-.53,7.45-.93,11.16-6.51-12.95-6.41-29.36.69-42.07C89.63,164.27,92.41,159.84,97.84,158.53Z"
        fill="#9BBDF9"
      />
      <path
        d="M107.13,164.69c4,4.79,5.76,11.88,7.41,17.77,4.48,0,8.94.12,13.41-.26,1.59-6.35,3.35-12.57,7.55-17.77-2.59,6.39-5.32,12.55-6.49,19.4-5.36.37-10.72.64-16.09.57-1.89-6.27-4.08-14.93-9.42-19.15-2.2-1.75-3.77.11-5.4,1.57a33.3,33.3,0,0,0-8.21,24.48c.39,6,2.31,11.59,4.76,17,4.49-.61,8.68-1,12.75-3.23,2.83-1.34,5.3-3.62,8.55-3.47,4.14.08,8.44,0,12.52.75,2.66,1.09,4.93,3.1,7.51,4.4,4.16,2.22,8.46,2.77,13.07,3.34,1.32-2.41,2.31-6,4.83-7.36A55.26,55.26,0,0,1,150,211.9c-5.25-.35-10.18-.78-15-3-2.52-1.1-4.72-2.82-7.24-3.91-4.34-.52-8.81-.16-13.18-.21-6.5,5-13.82,7-22,7.18-.94-1.77-1.89-3.53-2.75-5.34a53.11,53.11,0,0,1-2.47-6.91c-2-9.41-1.6-19.05,3-27.64,2-3.7,4.91-8.41,9-10.09C102.38,160.68,105.15,162.37,107.13,164.69Z"
        fill="#9BBDF9"
      />
      <path
        d="M141.36,161.59a10.48,10.48,0,0,1,6.09,3.68A39.94,39.94,0,0,1,156.33,186a45.31,45.31,0,0,1-2.45,16.74c-2.52,1.35-3.51,4.95-4.83,7.36-4.61-.57-8.91-1.12-13.07-3.34-2.58-1.3-4.85-3.31-7.51-4.4-4.08-.76-8.38-.67-12.52-.75-3.25-.15-5.72,2.13-8.55,3.47-4.07,2.21-8.26,2.62-12.75,3.23-2.45-5.46-4.37-11-4.76-17a33.3,33.3,0,0,1,8.21-24.48c1.63-1.46,3.2-3.32,5.4-1.57,5.34,4.22,7.53,12.88,9.42,19.15,5.37.07,10.73-.2,16.09-.57,1.17-6.85,3.9-13,6.49-19.4A6.7,6.7,0,0,1,141.36,161.59Z"
        fill="#9BBDF9"
      />
      <path
        d="M194.79,171.94c7.88,1.65,15.61,5.74,23,9.08,8.15,3.93,16.64,7.54,24.23,12.47l-2.21-.17a122.38,122.38,0,0,1-14.47-3.73l-3-.89A179.27,179.27,0,0,1,204,180.76a75.71,75.71,0,0,0-18.49-6.5c-4.85-1.14-9.76-1.29-14.58-2.67A55.73,55.73,0,0,1,194.79,171.94Z"
        fill="#9BBDF9"
      />
      <path
        d="M156.33,186a41.25,41.25,0,0,1,0,18.2c-.86,3.57-1.8,7.84-4.19,10.72-3.13,1.49-7.27,1.21-10.66,1.13-8.5-.23-15.75-6-24.52-4-5,1.88-9.28,4.48-14.9,4.45,1-1,2-2,3-3-4.34,1-10,2.31-14.27.61a38.45,38.45,0,0,1-2.59-4.6l1.57-2.87c.86,1.81,1.81,3.57,2.75,5.34,8.14-.23,15.46-2.15,22-7.18,4.37.05,8.84-.31,13.18.21,2.52,1.09,4.72,2.81,7.24,3.91,4.87,2.21,9.8,2.64,15,3a55.26,55.26,0,0,0,3.93-9.12A45.31,45.31,0,0,0,156.33,186Z"
        fill="#9BBDF9"
      />
      <path
        d="M222.36,188.7l3,.89a122.38,122.38,0,0,0,14.47,3.73c7,2.76,8,9.05,9.73,15.55v8.31l-3.37,3.31c.39-6.11.89-11.8-1.17-17.7-1.14-3-2.4-6-5.75-7C233.22,193.85,227.39,192.89,222.36,188.7Z"
        fill="#9BBDF9"
      />
    </svg>
  </IconContainer>
);

export default Thyroid;
