import React, { Component, Fragment } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';

import { FormField } from '../../../components/FormField';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import { sleep } from '../../../utils/sleep';
import RegionSelect from '../../../components/RegionSelect';

class StateSelector extends Component {
  constructor(props) {
    super(props);

    if (props.data && props.data.skipIfNotQuestionId) {
      let isFound = false;
      for (let index = 0; index < props.userData.length; index++) {
        const element = props.userData[index];
        for (let index = 0; index < element.values.length; index++) {
          const val = element.values[index];
          if (
            val.questionId &&
            val.questionId === props.data.skipIfNotQuestionId
          ) {
            isFound = true;
            break;
          }
        }
      }
      if (!isFound) {
        props.wizard.next();
      }
    }

    let defaults;
    let sel;
    let isDisplay;
    if (props.savedData) {
      defaults = this.unFormatSubmit(props.savedData, props.data.id);
      for (let index = 0; index < props.savedData.length; index++) {
        const element = props.savedData[index];
        sel = props.data.options.find(x => x.answer === element.answer);
        if (sel.placeholder) {
          isDisplay = true;
        }
        // if (element.value) {
        //   defaults[props.data.id] = element.value;
        // }
      }
    } else {
      defaults = (props.data || {}).defaults;
    }

    this.state = {
      loading: false,
      isInitialized: true,
      defaults,
      display: isDisplay,
      selected: sel || '',
    };
  }

  unFormatSubmit = (values, qId) => {
    let payload = {};

    if (values && values.values) {
      payload = values.values;
    }

    return payload;
  };

  handleClick = async element => {
    const { data, onSubmit, wizard } = this.props;
    this.setState({ selected: element });

    await sleep(550);
    if (element.isDisqualifying) {
      const dta = JSON.parse(JSON.stringify(this.props.userData));
      if (element.questionId) {
        const payload = {
          questionId: element.questionId,
          answer: element.answer || element.value,
          value: element.answer,
        };
        dta.push(payload);
      }
    } else {
      if (element.placeholder) {
        this.setState({ display: true });
      } else {
        const payload = {
          questionId: element.questionId,
          answer: element.answer || element.value,
          value: element.answer,
        };

        onSubmit(payload, data.id, wizard, element);
      }
    }
  };

  onSelectSex = element => {
    this.setState({ selected: element });
  };

  shiftView = () => {
    setTimeout(() => {
      window.scrollTo(0, this.inputRef.offsetTop);
    }, 150);
  };

  render() {
    const { data, onSubmit, wizard } = this.props;
    const { selected, loading, defaults, isInitialized } = this.state;
    if (!isInitialized) {
      return <div />;
    }

    return (
      <Fragment>
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          {data.title ? (
            <Box m={2} mt="0" mb={3}>
              <Heading textAlign="left">{data.title}</Heading>
            </Box>
          ) : null}
          {data.subTitle ? (
            <Box m={2} mt="0" mb={3}>
              <Text
                textAlign="left"
                fontSize="16px"
                fontWeight="300"
                lineHeight="24px"
              >
                {data.subTitle}
              </Text>
            </Box>
          ) : null}
          <Box m={2} mt="0">
            <Heading textAlign="left" fontSize="20px">
              {data.prompt}
            </Heading>
          </Box>

          <Formik
            initialValues={defaults}
            validationSchema={data.schema || ''}
            onSubmit={(values, actions) => {
              const payload = {
                values,
              };
              onSubmit(payload, actions, wizard, selected);
            }}
            render={({ values, isValid, errors, touched, submitForm }) => {
              return (
                <Form style={{ display: 'flex', flex: 1 }}>
                  <Flex flex={1} flexDirection="column">
                    <Flex flex={1} flexDirection="column" m={2}>
                      <FormField
                        width={[1, 1, 1 / 2]}
                        error={touched.state && errors.state}
                      >
                        <RegionSelect
                          name="state"
                          value={values.state}
                          placeholder="Pick your state"
                        />
                      </FormField>
                    </Flex>

                    <Flex justifyContent="center">
                      <Button
                        disabled={
                          (errors && errors.length) ||
                          loading ||
                          !values ||
                          !values.state
                        }
                        variant="primary"
                        width={[1, 1 / 2]}
                        type="submit"
                      >
                        Continue
                      </Button>
                    </Flex>
                  </Flex>
                </Form>
              );
            }}
          />
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(StateSelector);
// export default withWizard(Single);
