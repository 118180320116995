import React, { Fragment } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import withSession from '../../lib/withSession';
import { GET_ASYNC_TEMPORARY_RESPONSES, RESET_ASYNC_TEMPORARY_RESPONSES } from '../../graphql';
import FullLayout2 from '../../components/FullLayout';
import Header from '../asyncIntake/Header';
import Disqualified from '../asyncIntake/screens/Disqualified';
import DobSex from '../asyncIntake/screens/DobSex';
import DualChoice from '../asyncIntake/screens/DualChoice';
import Eligible from '../asyncIntake/screens/Eligible';
import IdVerification from '../asyncIntake/screens/IdVerification';
import InputField from '../asyncIntake/screens/InputField';
import InsuranceEligibilityCheck from '../asyncIntake/screens/InsuranceEligibilityCheck';
import InsuranceSelector from '../asyncIntake/screens/InsuranceSelector';
import LastLevelCheck from '../asyncIntake/screens/LastLevelCheck';
import { LearnMoreModal } from '../asyncIntake/screens/LearnMoreModal';
import LetsTalk from '../asyncIntake/screens/LetsTalk';
import MedicationBottlePicture from '../asyncIntake/screens/MedicationBottlePicture';
import MedicationList from '../asyncIntake/screens/MedicationList';
import Multiple from '../asyncIntake/screens/Multiple';
import ScheduleLiveTelemedicine from '../asyncIntake/screens/ScheduleLiveTelemedicine';
import Single from '../asyncIntake/screens/Single';
import StateSelector from '../asyncIntake/screens/StateSelector';
import UpdateLevelsWithKit from '../asyncIntake/screens/UpdateLevelsWithKit';
import UpdateLevelsWithKitNewFormulation from '../asyncIntake/screens/UpdateLevelsWithKitNewFormulation';
import UploadLab from '../asyncIntake/screens/UploadLab';
import ValueTsh from '../asyncIntake/screens/ValueTsh';
import WhatExpect30days from '../asyncIntake/screens/WhatExpect30days';
import HeightWeight from '../asyncIntake/screens/HeightWeight';
import { BookFlowQuestions } from './BookFlowQuestions';
import Pharmacy from '../asyncIntake/screens/Pharmacy';
import InsuranceDisclaimer from '../asyncIntake/screens/InsuranceDisclaimer';
import ChoosePrice from '../asyncIntake/screens/ChoosePrice';
import OtherCustomersFavourites from '../asyncIntake/screens/OtherCustomersFavourites';
import Finish from '../asyncIntake/screens/Finish';
import PalomaKit from '../../static/palomaKit.png';
import vitaminsBottle from '../../static/vitaminsBottle.png';
import { GET_ASYNC_ORDER_DETAILS, SAVE_ASYNC_TEMPORARY_RESPONSES } from '../../graphql';
import LoadingScreen from '../../components/LoadingScreen';
import ConfirmModal from '../../components/ConfirmModal';
import TestFirst from '../asyncIntake/screens/TestFirst';
import WhatHappensNext from '../asyncIntake/screens/WhatHappensNext';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';
import DisqualifiedPregnant from '../asyncIntake/screens/DisqualifiedPregnant';
import DisqualifiedT3 from '../asyncIntake/screens/DisqualifiedT3';
import DisqualifiedSymptomatic from '../asyncIntake/screens/DisqualifiedSymptomatic';
import DisqualifiedTsh from '../asyncIntake/screens/DisqualifiedTsh';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { measureHeight } from 'react-div-100vh';
import AllergiesList from '../asyncIntake/screens/AllergiesList';
import InsuranceHas from '../asyncIntake/screens/InsuranceHas';
import withOnFileInsurance from '../../lib/withOnFileInsurance';

const Container = styled(Box)`
  max-width: 600px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 1;
  overflow-y: ${(props) => (isMobile ? 'hidden' : '')};
`;

const InnerContainer = styled(Flex)`
  max-width: ${(props) => (isMobile ? 'calc(100% - 12px)' : '850px')};
  height: 100%;
  margin: 0 auto;
  max-height: 100%;
  flex-direction: column;
  overflow-y: ${(props) => (isMobile ? 'auto' : '')};
`;

const InnerContainerInformation = styled(Flex)`
  max-width: ${(props) => (isMobile ? 'calc(100% - 12px)' : '850px')};
  height: auto;
  margin: 0 auto;
  max-height: 100%;
  flex-direction: column;
  overflow-y: ${(props) => (isMobile ? 'auto' : '')};
`;

const BlueQuestionIndex = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;

  color: #354e79;
`;

const DEFAULT_ROUTE = '/scheduler';
class BookConsultationInner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: [],
      values2: [],
      steps: null,
      upsellSelection: [],
      flowMarkers: [],
      isLoading: true,
      stepPreviousHistory: null,
      direction: 1,
      learnMoreModal: null,
      hpiContent: [],
      isInitializingTempData: true,
      isResumeQuestion: false,
    };
    const hasFutureAppointments =
      props.appointments &&
      props.appointments.find((item) => {
        if (item.status && (item.status === 'f' || item.status === '2' || item.status === 'o')) {
          const dt = moment(`${item.date} ${item.startTime}`, 'MM/DD/YYYY HH:mm');
          return dt.isAfter(moment(), 'minute');
        }
        return false;
      });

    if (hasFutureAppointments) {
      this.props.history.push('/consultations');
    }

    if (!!this.props.session && !!this.props.session.b2b && !!this.props.session.b2b.isActive && !this.props.session.b2b.mustVerify && !!this.props.session.b2b.visits) {
      console.log('should leave');
      this.props.history.push('/schedule');
    }
    this.wizard = React.createRef();
  }
  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const { stepPreviousHistory } = this.state;
    if (!history || !history.location || !history.location.pathname) {
      return 1;
    }
    if (!stepPreviousHistory) {
      this.setState({
        stepPreviousHistory: history.location.pathname.replace(DEFAULT_ROUTE + '/', ''),
        direction: 1,
      });
      return;
    }

    if (stepPreviousHistory === history.location.pathname.replace(DEFAULT_ROUTE + '/', '')) {
      return;
    }
    const previousIndex = BookFlowQuestions(this.props.session).findIndex((x) => x.id === stepPreviousHistory);

    const page = history.location.pathname.replace(DEFAULT_ROUTE + '/', '');
    const newIndex = BookFlowQuestions(this.props.session).findIndex((x) => x.id.toUpperCase() === page.toUpperCase());

    this.setState({
      stepPreviousHistory: history.location.pathname.replace(DEFAULT_ROUTE + '/', ''),
      direction: newIndex < previousIndex ? -1 : 1,
    });
  }

  async componentDidMount() {
    const {
      session: { intakeStatus },
      history,
    } = this.props;
    if (intakeStatus === 'disqualified') {
      history.replace('/');
    }
    // await this.props.client.query({
    //   query: GET_ASYNC_TEMPORARY_RESPONSES,
    // });
    await this.initTempData();
    if (window.Beacon) {
      window.Beacon('destroy');
    }
  }

  heartbeat = () => {
    try {
      if (this.props.heartbeat) {
        this.props.heartbeat();
      }
    } catch (error) {}
  };

  initTempData = async (acceptResume = false, bypassTempData = false) => {
    // console.log('Init temp data', { bypassTempData });
    let dta = [];
    let forwardToStep = '';
    if (!bypassTempData) {
      const res = await this.props.client.query({
        query: GET_ASYNC_TEMPORARY_RESPONSES,
      });
      // console.log('Init temp data res', { res });
      if (res && res.data && res.data.getTemporaryResponses && res.data.getTemporaryResponses.responses) {
        if (!acceptResume) {
          this.setState({
            isResumeQuestion: true,
          });
          return;
        }
        dta = JSON.parse(res.data.getTemporaryResponses.responses);
        const last = dta[dta.length - 1].id;
        const next = BookFlowQuestions(this.props.session).findIndex((x) => x.id === last);
        forwardToStep = BookFlowQuestions(this.props.session)[next].id;
      }
    }
    if (acceptResume && bypassTempData) {
      await this.props.client.mutate({
        mutation: RESET_ASYNC_TEMPORARY_RESPONSES,
        refetchQueries: [{ query: GET_ASYNC_TEMPORARY_RESPONSES }],
        awaitRefetchQueries: true,
      });
      // this.props.history.push('/scheduler');
      forwardToStep = BookFlowQuestions(this.props.session)[0].id;
    }
    // const bypassReturns = [];
    // try {
    //   const prevIntakeData = await this.props.client.query({
    //     query: LOAD_PREVIOUS_INTAKE,
    //   });

    //   if (
    //     prevIntakeData &&
    //     prevIntakeData.data &&
    //     prevIntakeData.data.loadPreviousIntake
    //   ) {
    //     for (
    //       let index = 0;
    //       index < prevIntakeData.data.loadPreviousIntake.length;
    //       index++
    //     ) {
    //       const element = prevIntakeData.data.loadPreviousIntake[index];
    //       if (!element.questionId) continue;
    //       if (
    //         element.questionId.toString() ===
    //           window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 ||
    //         window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1
    //           ? '297'
    //           : '301'
    //       ) {
    //         if ((element.answer || '') !== 'Y') {
    //           bypassReturns.push('cancer');
    //         }
    //       } else if()
    //     }
    //   }
    // } catch (error) {}
    this.setState(
      {
        values: dta,
        isResumeQuestion: false,
      },
      () => {
        const steps = this.stepsInitializer(dta);

        this.setState(
          {
            isInitializingTempData: false,
            steps,
            isLoading: false,
          },
          () => {
            if ((window._env_.REACT_APP_ASYNC_RESUME_SAME_SCREEN || '').toString() === 'true') {
              return;
            }
            if (forwardToStep) {
              this.heartbeat();
              for (let index = 0; index < this.state.values.length; index++) {
                const element = this.state.values[index];
                this.wizard.current.push(element.id);
              }
              this.wizard.current.push(forwardToStep);
            }
          }
        );
      }
    );
  };

  showLearnMore = (learnMoreId) => {
    this.setState({
      learnMoreModal: learnMoreId,
    });
  };

  wizardJumpTo = (endpoint) => {
    this.heartbeat();
    this.wizard.current.push(endpoint);
  };

  getOrder = async (addons) => {
    const { client } = this.props;
    let order = {};

    const { data } = await client.query({
      query: GET_ASYNC_ORDER_DETAILS,
      variables: {
        addons: (addons || []).map((x) => x),
      },
      fetchPolicy: 'network-only',
    });

    if (data && data.getAsyncOrderDetails) {
      order = {
        dueToday: data.getAsyncOrderDetails.dueToday,
        totalDue: data.getAsyncOrderDetails.totalDue,
        memberSavingsAddons: data.getAsyncOrderDetails.memberSavingsAddons,
        discountCoupon: data.getAsyncOrderDetails.discountCoupon,
        items: [],
        registeringForMembership: data.getAsyncOrderDetails.registeringForMembership,
      };

      for (let index = 0; index < (data.getAsyncOrderDetails.items || []).length; index++) {
        const element = data.getAsyncOrderDetails.items[index];
        switch (element.type) {
          case 'visit':
            order.items.push({
              item: 'visit',
              sku: 'oeirwu8',
              price: (element.price / 100).toString(),
              canRemove: false,
              title: 'Online Provider Visit',
              description: 'An experienced healthcare provider will evaluate your condition and discuss treatment options. Your card will only be charged if/when Rx written.',
            });
            break;
          case 'kits':
            order.items.push({
              item: 'kits',
              sku: 'oeirwu8',
              price: (element.price / 100).toString(),
              canRemove: true,
              title: 'At-home Thyroid Test Kit',
              description: 'TSH, Free T4, Free T3 and TPO',
              image: PalomaKit,
            });
            break;
          case 'vitamins':
            order.items.push({
              item: 'vitamins',
              sku: 'oeirwu8',
              price: (element.price / 100).toString() + '/mo',
              canRemove: true,
              title: 'Daily Thyroid Care Vitamins',
              description: 'Reinvented multivitamins',
              image: vitaminsBottle,
            });
            break;

          default:
            break;
        }
      }
    }

    return order;
  };

  recordHpiContent = (elementId, values) => {
    const question = BookFlowQuestions(this.props.session).find((x) => x.id === elementId);

    if (!question || !question.hpiComputer) return;

    let hpiAnswer;
    try {
      hpiAnswer = (question.options || []).find((x) => x.answer === values.answer).hpiAnswer;
    } catch (error) {}

    const { hpiContent } = this.state;
    const { session } = this.props;
    const computed = question.hpiComputer(values, session, hpiAnswer, this.state.values, this.state.flowMarkers);
    // const newContent = [...hpiContent];
    // const currentContent = hpiContent.findIndex(x => x.id === elementId);
    return {
      id: elementId,
      value: computed,
      index: question.hpiIndex,
    };
  };

  initiateHpi = () => {
    const res = [];
    for (let index = 0; index < this.state.values.length; index++) {
      const element = this.state.values[index];
      let r = this.recordHpiContent(element.id, element.values);
      if (r) {
        res.push(r);
      }
    }
    return res;
  };

  initiateOrder = async () => {
    const { upsellSelection } = this.state;
    const order = await this.getOrder(upsellSelection);
    const hpiContent = this.initiateHpi();

    this.setState({ order, hpiContent });
    return order;
  };

  removeFromOrder = async (id) => {
    // console.log('Remove from order', id);
    const { upsellSelection } = this.state;
    console.log('Remove from order addons pre', upsellSelection);
    const addons = upsellSelection.filter((x) => (x.id ? x.id !== id : x !== id));
    console.log('Remove from order addons', addons);
    this.setState({ upsellSelection: addons });
    // ,()=>{
    const order = await this.getOrder(addons);

    return order;
    // }
  };

  stepsInitializer = (savedValues) => {
    const { session, onFileInsurance } = this.props;
    const values = JSON.parse(JSON.stringify(this.state.values));
    const stps = [];
    for (let index = 0; index < BookFlowQuestions(this.props.session).length; index++) {
      const element = BookFlowQuestions(this.props.session)[index];
      switch (element.type) {
        case 'whathappensnext':
          // console.log('whathappensnext');
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <WhatHappensNext onSubmit={async (vals) => {}} />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'finish':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <Finish
                      data={element}
                      initiateOrder={this.initiateOrder}
                      getState={() => this.state}
                      updateOrder={() => {}}
                      removeFromOrder={(id) => {
                        return this.removeFromOrder(id);
                      }}
                      onSubmit={async () => {
                        this.heartbeat();
                        this.wizard.current.next();
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'idVerif':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <IdVerification data={element} getState={() => this.state} onLearnMore={this.showLearnMore} onSubmit={(vals) => this.nextQuestion(vals, element.id, element.endpoint)} />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'letstalk':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer>
                    <LetsTalk
                      data={element}
                      onSubmit={(vals) => {
                        this.heartbeat();
                        this.wizard.current.next();
                      }}
                      isAccountCreated={this.props.location && this.props.location.state && this.props.location.state.isnew}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'multiple':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <Multiple
                      getState={() => this.state}
                      data={element}
                      onSubmit={(vals) => {
                        let submitHandler;

                        if (element.onSubmitHandler) {
                          submitHandler = element.onSubmitHandler(vals, () => this.state);
                        }

                        this.nextQuestion(vals, element.id, element.endpoint, submitHandler, element.onPostSubmitHandler);
                      }}
                      userData={values}
                      savedData={(savedValues.find((x) => x.id === element.id) || {}).values}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: element.preCheckDisplay ? element.preCheckDisplay(this.props.session, this.props.history) : true,
          });
          break;
        case 'input':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <InputField
                      getState={() => this.state}
                      data={element}
                      onSubmit={(vals) => {
                        let submitHandler;

                        if (element.onSubmitHandler) {
                          submitHandler = element.onSubmitHandler(vals, () => this.state);
                        }

                        this.nextQuestion(vals, element.id, element.endpoint, submitHandler, element.onPostSubmitHandler);
                      }}
                      userData={values}
                      savedData={(savedValues.find((x) => x.id === element.id) || {}).values}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'insuranceHas':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <InsuranceHas
                      getState={() => this.state}
                      data={element}
                      onSubmit={(vals) => {
                        let submitHandler;

                        if (element.onSubmitHandler) {
                          submitHandler = element.onSubmitHandler(vals, () => this.state);
                        }
                        this.nextQuestion(vals, element.id, element.endpoint, submitHandler, element.onPostSubmitHandler);
                      }}
                      saveOnFileInsurance={(vals) => {
                        this.setState({
                          onFileOnsurance: vals,
                        });
                      }}
                      userData={values}
                      savedData={(savedValues.find((x) => x.id === element.id) || {}).values}
                      session={this.props.session}
                      isMemberInsurance = {this.props.session && this.props.session.isMember && this.props.membershipSpecific==='insurance'}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: !!element.preCheckDisplay ? element.preCheckDisplay(this.props.session, this.props.onFileInsurance) : true,
          });
          break;
        case 'single':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <Single
                      getState={() => this.state}
                      data={element}
                      onSubmit={(vals) => {
                        let submitHandler;

                        if (element.onSubmitHandler) {
                          submitHandler = element.onSubmitHandler(vals, () => this.state);
                        }
                        this.nextQuestion(vals, element.id, element.endpoint, submitHandler, element.onPostSubmitHandler);
                      }}
                      userData={values}
                      savedData={(savedValues.find((x) => x.id === element.id) || {}).values}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: element.preCheckDisplay ? element.preCheckDisplay(this.props.session, this.props.history, this.props.onFileInsurance) : true,
          });
          break;

        case 'dobSex':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <DobSex
                      getState={() => this.state}
                      data={element}
                      onSubmit={(vals) => {
                        this.nextQuestion(vals, element.id, element.endpoint);
                      }}
                      userData={values}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'statePicker':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <StateSelector
                      getState={() => this.state}
                      data={element}
                      onSubmit={(vals) => {
                        this.nextQuestion(vals, element.id, element.endpoint);
                      }}
                      userData={values}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'eligibleStart':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <Eligible />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;

        case 'dualChoice':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <DualChoice getState={() => this.state} data={element} onLearnMore={this.showLearnMore} onSubmit={(vals) => this.nextQuestion(vals, element.id, element.endpoint)} />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;

        case 'medicationList':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <MedicationList
                      data={element}
                      getState={() => this.state}
                      gotoStep={(step) => this.wizardJumpTo(step)}
                      onSubmit={(vals) => {
                        let submitHandler;

                        if (element.onSubmitHandler) {
                          submitHandler = element.onSubmitHandler(vals);
                        }

                        this.nextQuestion(vals, element.id, element.endpoint, submitHandler);
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;

        case 'insurancecompanyselector':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <InsuranceSelector getState={() => this.state} data={element} onSubmit={(vals, submitHandler) => this.nextQuestion(vals, element.id, element.endpoint, submitHandler)} />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: element.preCheckDisplay ? element.preCheckDisplay(this.props.session, this.props.onFileInsurance) : true,
          });
          break;
        case 'insuranceeligibilitycheck':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <InsuranceEligibilityCheck
                      setCanLive={(val) => {
                        this.setState({
                          canLive: val,
                        });
                      }}
                      setOneTimeInsurance={({ type, provider }) => {
                        this.setState({
                          oneTimeInsurance: {
                            type,
                            provider,
                          },
                        });
                      }}
                      values={() => {
                        return this.state;
                      }}
                      step={element}
                      gotoStep={(step) => this.wizardJumpTo(step)}
                      data={element}
                      onSubmit={(endpoint) => {
                        this.wizardJumpTo(endpoint);
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: element.preCheckDisplay ? element.preCheckDisplay(this.props.session, this.props.onFileInsurance) : true,
          });
          break;
        case 'liveRefill':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <ScheduleLiveTelemedicine
                      gotoStep={(step) => {
                        this.wizardJumpTo(step);
                      }}
                      getState={() => {
                        return this.state;
                      }}
                      data={element}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'valuetsh':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <ValueTsh
                      gotoStep={(step) => {
                        this.wizardJumpTo(step);
                      }}
                      getState={() => {
                        return this.state;
                      }}
                      data={element}
                      onSubmit={(vals) => {
                        let submitHandler = null;
                        if (element.onSubmitHandler) {
                          submitHandler = element.onSubmitHandler(vals);
                        }
                        this.nextQuestion(vals, element.id, element.endpoint, submitHandler);
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'lastlevelcheck':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <LastLevelCheck
                      gotoStep={(step) => {
                        this.wizardJumpTo(step);
                      }}
                      getState={() => {
                        return this.state;
                      }}
                      data={element}
                      onSubmit={(vals) => {
                        let submitHandler;
                        if (element.onSubmitHandler) {
                          submitHandler = element.onSubmitHandler(vals, () => {
                            return this.state;
                          });
                        }
                        this.nextQuestion(vals, element.id, element.endpoint, submitHandler);
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'uploadlabs':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <UploadLab
                      gotoStep={(step) => {
                        this.wizardJumpTo(step);
                      }}
                      getState={() => {
                        return this.state;
                      }}
                      data={element}
                      onSubmit={(vals) => {
                        let submitHandler;
                        if (element.onSubmitHandler) {
                          submitHandler = element.onSubmitHandler(vals);
                        }
                        this.nextQuestion(vals, element.id, element.endpoint, submitHandler);
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'updatelevelswithkit':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <UpdateLevelsWithKit
                      gotoStep={(step) => {
                        this.wizardJumpTo(step);
                      }}
                      getState={() => {
                        return this.state;
                      }}
                      data={element}
                      onLearnMore={this.showLearnMore}
                      onSubmit={(vals) => {
                        const selected = vals.filter((x) => x.chosen === 'Y');

                        for (let index = 0; index < selected.length; index++) {
                          const element2 = selected[index];
                          if (element2.isUpsellValue) {
                            const selection = this.state.upsellSelection;
                            if (selection.filter((x) => x === element2.id).length > 1) continue;

                            selection.push(element2.id);
                            this.setState({
                              upsellSelection: selection,
                            });

                            if (selected.length === 1) {
                              this.props.history.push('/purchase');
                            }
                          }

                          if (element2.isFlowMarker) {
                            const selection = this.state.flowMarkers.filter((x) => x.id !== element2.id);

                            selection.push(element2);
                            this.setState({
                              flowMarkers: selection,
                            });
                            this.nextQuestion(selected, element.id, element.endpoint);
                          }
                        }
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'whatexpect30days':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <WhatExpect30days
                      onLearnMore={this.showLearnMore}
                      onSubmit={(vals) => {
                        this.wizardJumpTo('doctormanaginghypo');
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'updatelevelsnewformulation':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <UpdateLevelsWithKitNewFormulation
                      onLearnMore={this.showLearnMore}
                      onSubmit={(vals) => {
                        this.props.history.push('/purchase');
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'disqualified':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <Disqualified onLearnMore={this.showLearnMore} data={element} />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'disqualifiedpregnant':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <DisqualifiedPregnant onLearnMore={this.showLearnMore} data={element} />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'disqualifiedt3':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <DisqualifiedT3 onLearnMore={this.showLearnMore} data={element} />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'disqualifiedsymptoms':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <DisqualifiedSymptomatic onLearnMore={this.showLearnMore} data={element} />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'disqualifiedtsh':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <DisqualifiedTsh onLearnMore={this.showLearnMore} data={element} />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'heightweight':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <HeightWeight
                      gotoStep={(step) => {
                        this.wizardJumpTo(step);
                      }}
                      getState={() => {
                        return this.state;
                      }}
                      data={element}
                      onSubmit={(vals) => {
                        let submitHandler = null;
                        if (element.onSubmitHandler) {
                          submitHandler = element.onSubmitHandler(vals);
                        }
                        this.nextQuestion(vals, element.id, element.endpoint, submitHandler);
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;

        case 'medicationBottlePicture':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <MedicationBottlePicture
                      data={element}
                      onSubmit={(vals) => {
                        this.nextQuestion(vals, element.id, element.endpoint, null);
                      }}
                      onSkip={() => {
                        if (session.eligibility.consultation) {
                          this.wizardJumpTo('liveNoMeds');
                        } else {
                          this.wizardJumpTo('disqualifiedt3');
                        }
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;

        case 'pharmacy':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <Pharmacy
                      data={element}
                      continueIntake={() => {
                        this.heartbeat();
                        this.wizard.current.next();
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'testFirst':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <TestFirst
                      onSubmit={() => {
                        this.props.history.push({
                          pathname: '/order-kit',
                        });
                      }}
                      onLearnMore={this.showLearnMore}
                      client={this.props.client}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'insurancedisclaimer':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer p={3}>
                    <InsuranceDisclaimer
                      onNext={() => {
                        this.heartbeat();
                        this.wizard.current.next();
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'chooseprice':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer>
                    <ChoosePrice
                      getState={() => this.state}
                      onSubmit={(vals) => {
                        const selection = this.state.upsellSelection;
                        if (vals !== 'MEMBERSHIP') {
                          if (selection.filter((x) => x === 'MEMBERSHIP').length > 0) {
                            const newSel = [...selection.filter((x) => x !== 'MEMBERSHIP')];

                            this.setState({
                              upsellSelection: newSel,
                            });
                          }
                        } else {
                          if (selection.filter((x) => x === 'MEMBERSHIP').length < 1) {
                            const newSel = [...selection];
                            newSel.push('MEMBERSHIP');
                            this.setState({
                              upsellSelection: newSel,
                            });
                          }
                        }

                        this.nextQuestion(vals, element.id, element.endpoint);
                      }}
                      onLearnMore={this.showLearnMore}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: !session || !session.isMember,
          });
          break;
        case 'favorites':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer>
                    <OtherCustomersFavourites
                      getState={() => this.state}
                      onSubmit={(vals) => {
                        const newSel = [...this.state.upsellSelection.filter((x) => x !== 'vitamins' && x !== 'kits')];
                        for (let index = 0; index < vals.length; index++) {
                          const element = vals[index];
                          if (element === null) continue;
                          newSel.push(element);
                        }
                        this.setState({
                          upsellSelection: newSel,
                        });

                        this.heartbeat();
                        this.wizard.current.next();
                      }}
                      onLearnMore={this.showLearnMore}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        case 'allergiesList':
          stps.push({
            comp: (
              <Step id={element.id} key={element.id}>
                <Container>
                  <InnerContainer>
                    <AllergiesList
                      data={element}
                      getState={() => this.state}
                      gotoStep={(step) => this.wizardJumpTo(step)}
                      onSubmit={(vals) => {
                        let submitHandler;

                        if (element.onSubmitHandler) {
                          submitHandler = element.onSubmitHandler(vals);
                        }

                        this.nextQuestion(vals, element.id, element.endpoint, submitHandler);
                      }}
                    />
                  </InnerContainer>
                </Container>
              </Step>
            ),
            key: element.id,
            display: true,
          });
          break;
        default:
          break;
      }
    }

    return stps;
  };

  nextQuestion = async (val, id, endpoint, submitHandler, onPostSubmitHandler) => {
    // console.log(this.state.values);
    // console.log(val);
    try {
      const st = this.state.values;
      let Vs = st;
      try {
        Vs = JSON.parse(JSON.stringify(st));
      } catch (error) {}
      const vals = [];
      let found = false;
      const q = BookFlowQuestions(this.props.session).find((x) => x.id === id);
      // console.log({
      //   id,
      //   q,
      // });
      let removeNext = false;
      const finalId = !!val && !!val.forceQuestionId ? val.forceQuestionId : id;
      for (let index = 0; index < Vs.length; index++) {
        const element = Vs[index];
        if (element.id === finalId) {
          if (JSON.stringify(element.values) !== JSON.stringify(val)) {
            removeNext = true;
          }
          vals.push({
            id: finalId,
            values: val,
            endpoint,
            savingSection: q.savingSection,
          });
          found = true;
        } else {
          if (!removeNext || q.savingSection !== element.savingSection) {
            vals.push({ ...element });
          }
        }
      }

      if (!found) {
        vals.push({
          id: finalId,
          values: val,
          endpoint,
          savingSection: q.savingSection,
        });
      }

      let postSubmitHandlerPage = null;
      this.setState(
        {
          values: vals,
        },
        async () => {
          if (q && q.isSaveTemp) {
            await this.props.client.mutate({
              mutation: SAVE_ASYNC_TEMPORARY_RESPONSES,
              variables: { responses: JSON.stringify(vals) },
            });
          }
          // this.wizard.current.push('insuranceeligibilitycheck');
          if (onPostSubmitHandler) {
            postSubmitHandlerPage = onPostSubmitHandler(vals, () => this.state);
          }

          // this.stepsInitializer(vals);

          if (postSubmitHandlerPage) {
            if (postSubmitHandlerPage.indexOf('/') > -1) {
              this.props.history.push(postSubmitHandlerPage);
            } else {
              this.heartbeat();
              this.wizard.current.push(postSubmitHandlerPage);
            }
            return;
          }

          if (submitHandler) {
            if (submitHandler.indexOf('/') > -1) {
              this.props.history.push(submitHandler);
            } else {
              this.heartbeat();
              this.wizard.current.push(submitHandler);
            }
            return;
          }

          if (val && val.goTo) {
            this.props.history.push(val.goTo);
            return;
          }
          if (val && val.goToPage) {
            this.heartbeat();
            this.wizard.current.push(val.goToPage);
          } else {
            this.heartbeat();
            this.wizard.current.next();
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  previousQuestion = () => {
    this.props.history.goBack();
  };

  getPathDepth = () => {
    const { history } = this.props;
    const { stepPreviousHistory } = this.state;
    if (!history || !history.location || !history.location.pathname) {
      return 1;
    }
    if (!stepPreviousHistory) {
      this.setState({
        stepPreviousHistory: history.location.pathname.replace(DEFAULT_ROUTE + '/', ''),
        direction: 1,
      });
      return;
    }

    if (stepPreviousHistory === history.location.pathname.replace(DEFAULT_ROUTE + '/', '')) {
      return;
    }
    const previousIndex = BookFlowQuestions(this.props.session).findIndex((x) => x.id === stepPreviousHistory);

    const page = history.location.pathname.replace(DEFAULT_ROUTE + '/', '');
    const newIndex = BookFlowQuestions(this.props.session).findIndex((x) => x.id.toUpperCase() === page.toUpperCase());

    this.setState({
      stepPreviousHistory: history.location.pathname.replace(DEFAULT_ROUTE + '/', ''),
      direction: newIndex < previousIndex ? -1 : 1,
    });
  };

  render() {
    const { history, session } = this.props;
    const { steps, isLoading, direction, learnMoreModal, isInitializingTempData, isResumeQuestion } = this.state;

    if (!!session && !!session.isMember && session.membershipSpecific === 'insurance' && session.needInsuranceCompletion) {
      return (
        <>
          <Container>
            <InnerContainer p={3} mt={2}>
              <Card p={4} mb={4}>
                <Flex mb={4} flexDirection="column">
                  <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                    <Flex flexDirection={'column'}>
                      <Heading mt={4} mb={4} textAlign={'center'}>
                        Let's get your insurance information
                      </Heading>
                      <Text textAlign="center" >
                        You are benefiting from a reduced membership fee for using your insurance on doctor visits. Please add your insurance details on the next screen
                      </Text>

                      <Button variant="pink" mt={4} onClick={() => history.push('/my-insurances/add')}>
                        Continue
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </InnerContainer>
          </Container>
        </>
      );
    }

    if (isResumeQuestion) {
      return (
        <ConfirmModal
          isOpen={isResumeQuestion}
          onClose={() => this.initTempData(true, true)}
          onBackgroundClick={() => this.initTempData(true, true)}
          onEscapeKeydown={() => this.initTempData(true, true)}
          childrenManaged={true}
          cancelVariant="outline"
          buttonsDirection="column"
          displayX={true}
          cardStyle={{
            width: '500px',
            maxWidth: 'calc(100vw - 40px)',
          }}
        >
          <Box
            style={{
              maxWidth: '600px',
              width: '100%',
            }}
          >
            <Heading textAlign="center">Resume</Heading>
            <Text mt={3} mb={4} textAlign="center">
              Do you want to resume where you left off?
            </Text>

            <Flex flexDirection="row" justifyContent="center">
              <Button variant="primary" mr={2} onClick={() => this.initTempData(true, false)}>
                Yes
              </Button>
              <Button variant="white" ml={2} onClick={() => this.initTempData(true, true)}>
                No
              </Button>
            </Flex>
          </Box>
        </ConfirmModal>
      );
    }
    if (!steps || !steps.length || isLoading) return <div />;
    const vh100 = `${measureHeight()}px` || '100vh';
    // console.log(steps.filter((x) => !!x.display))
    return (
      <FullLayout2
        style={{
          zIndex: 11,
          overflowY: isMobile ? 'hidden' : 'auto',
          maxHeight: vh100,
        }}
        pb={5}
      >
        <Container>
          <Header previousPage={this.previousQuestion} />
          <Box
            mt="60px"
            p="8px"
            style={{
              height: `calc(${vh100} - ${isMobile ? '60px' : '120px'})`,
              position: 'relative',
              overflowY: isMobile ? 'auto' : '',
            }}
          >
            <Wizard
              ref={this.wizard}
              basename={DEFAULT_ROUTE}
              history={history}
              render={({ step, ...p }, a) => {
                const stp = BookFlowQuestions(this.props.session).filter((xx) => xx.id === step.id);
                return (
                  <Fragment>
                    {stp && stp.length && !stp[0].hideCountText ? (
                      <Box
                        style={{
                          maxWidth: '1024px',
                          width: '100%',
                          margin: '16px auto 0 auto',
                          paddingLeft: '24px',
                          paddingRight: '24px ',
                        }}
                      >
                        <BlueQuestionIndex />
                      </Box>
                    ) : null}
                    <TransitionGroup enter={true} exit={true}>
                      <CSSTransition key={step.id} timeout={{ enter: 500, exit: 500 }} classNames={direction < 0 ? 'example-back' : 'example'}>
                        <div
                          className="example-steps"
                          style={{
                            minHeight: 'calc(100% - 24px)',
                            height: 'calc(100% - 24px)',
                            width: 'calc(100% - 12px)',
                          }}
                        >
                          <Steps step={step}>{steps.filter((x) => !!x.display).map((x) => x.comp)}</Steps>
                        </div>
                      </CSSTransition>
                    </TransitionGroup>
                  </Fragment>
                );
              }}
            />
          </Box>
        </Container>
        {learnMoreModal && <LearnMoreModal learnMoreId={learnMoreModal} onClose={() => this.setState({ learnMoreModal: null })} />}
        {isInitializingTempData ? <LoadingScreen layoutStyle={{ zIndex: 1 }} /> : null}
      </FullLayout2>
    );
  }
}

const BookConsultation = ({ ...props }) => {
  if (props.session && props.session.isMember && props.session.membershipSpecific === 'insurance' && props.session.needInsuranceCompletion) {
    return (
      <>
        <Container>
          <InnerContainerInformation p={3} mt={2} height={'auto'} style={{height: 'auto !important'}}>
            <Card p={4} mb={4}>
              <Flex mb={4} flexDirection="column">
                <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                  <Flex flexDirection={'column'}>
                    <Heading mt={4} mb={4} textAlign={'center'}>
                      Let's get your insurance information
                    </Heading>
                    <Text textAlign="center" >
                      You are benefiting from a reduced membership fee for using your insurance on doctor visits. Please add your insurance details on the next screen
                    </Text>

                    <Button variant="pink" mt={4} onClick={() => props.history.push('/my-insurances/add')}>
                      Continue
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </InnerContainerInformation>
        </Container>
      </>
    );
  }

  return <BookConsultationInner {...props} />;
};

export default withApollo(withRouter(withSession(withOnFileInsurance(withToastManager(BookConsultation)))));
