const TIMEOUT_SESSION_DURATION_IN_MINUTES = 45
const TIMEOUT_SESSION_WARNING_IN_MINUTES = 40
const TOP_HEADER_STEPPER_THRESHOLD = 1200
const MEMBERSHIP_PRICE_2024 = 240
const MEMBERSHIP_PRICE = 192

const TPO_DESCRIPTION = `Thyroid Peroxidase (TPO) Antibodies and Hashimoto's Thyroiditis:

When you have a blood test that measures Thyroid Peroxidase (TPO) antibodies, and the levels are high, it can be an indicator of Hashimoto's thyroiditis. TPO antibodies are proteins that the immune system mistakenly makes in response to the thyroid gland. Their presence in your blood suggests that your immune system may be targeting your thyroid.

What is Hashimoto's Thyroiditis?

Hashimoto's thyroiditis is an autoimmune condition, where the immune system, which typically guards against infections, attacks your thyroid gland by mistake. The thyroid, a vital gland located in the front of your neck, regulates numerous metabolic processes throughout the body.

As the immune system continues to damage the thyroid cells over time, the gland may become less able to produce the hormones your body needs, leading to a condition known as hypothyroidism, or an underactive thyroid.

Symptoms of Hashimoto's Impact on Thyroid Function:

If Hashimoto's leads to hypothyroidism, you may notice symptoms such as:
\t• Fatigue: Feeling exhausted is common, even when you get plenty of sleep.
\t• Weight Gain: You may experience unexplained weight gain due to a slower metabolism.
\t• Cold Intolerance: You could feel colder than others in the same environment.
\t• Joint and Muscle Pain: New or worsening pain in joints and muscles can occur.
\t• Hair Loss and Dry Skin: You might notice your hair thinning and your skin becoming dry.
\t• Mood Swings: Changes in mood, including depression, are not uncommon.`

export const Constants = {
  TIMEOUT_SESSION_DURATION_IN_MINUTES,
  TIMEOUT_SESSION_WARNING_IN_MINUTES,
  TOP_HEADER_STEPPER_THRESHOLD,
  TPO_DESCRIPTION,
  MEMBERSHIP_PRICE_2024,
  MEMBERSHIP_PRICE
}