import React from 'react';

export const GraphPink = ({ style={},width = 712, stroke="#FFE3D8" }) => {
  return (
    <svg width={width} height={width * 0.69} viewBox="0 0 712 494" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M-69 490.752C-29.1041 417.543 14.6528 300.88 55.8357 271.124C108.088 233.37 124.045 358.976 197.402 353.308C270.759 347.64 260.463 150.861 327.385 145.015C421.299 136.812 410.881 412.834 499.879 353.308C601.288 285.48 556.465 -1.58799 642.692 6.15365C737.975 14.7084 736.64 211.612 816.432 196.026C848.119 189.836 880.781 139.347 880.781 139.347"
        stroke={stroke}
        stroke-width="11.3792"
      />
    </svg>
  );
};
