import React from 'react';
import { withRouter } from 'react-router';
import HealthCoachFormBuilder from './builder/Builder';
import { Query } from 'react-apollo';
import { GET_HEALTHCOACH_FORM_TEMPLATES } from '../../graphql';
import { Text } from 'rebass';
import PleaseWait from '../../components/PleaseWait';

const HealthCoachFormBuilderWrap = ({ history }) => {
  console.log({ history });
  const { location } = history;
  if (!location || !location.search) {
    return <HealthCoachFormBuilder />;
  }
  let id = location.search.replace('?', '');
  let isDuplicate = location.search.indexOf('?dup_') > -1;
  if (isDuplicate) {
    id = location.search.replace('?dup_', '');
  }
  return (
    <Query query={GET_HEALTHCOACH_FORM_TEMPLATES}>
      {({ data, loading, error }) => {
        if (!!loading) {
          return <PleaseWait />;
        }
        if (!data || !data.getHealthCoachFormTemplates || !!error) {
          return <Text textAlign={'center'}>An error occured</Text>;
        }
        const template = data.getHealthCoachFormTemplates.find((x) => x.id === id);
        if (!template) {
          return <Text textAlign={'center'}>Template not found</Text>;
        }
        return (
          <HealthCoachFormBuilder
            initialMeta={{
              id: !!isDuplicate ? null : template.id,
              name: template.name,
              description: template.description,
              target: template.target.id,
              targetName: template.target.target,
              createdBy: `${template.creator.firstName} (${template.creator.email})`,
              createdByUser: template.creator.id,
              version: template.version,
            }}
            initialContent={JSON.parse(template.content)}
          />
        );
      }}
    </Query>
  );
};

export default withRouter(HealthCoachFormBuilderWrap);
