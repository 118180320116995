import React, { useState } from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { loadStripe } from '@stripe/stripe-js';
import Intro from './sections/Intro';
import Diets from './sections/Diets';
import Essentials from './sections/Essentials';
import withSession from '../../lib/withSession';
import SelectMembership from './selectMembership';
import { Mutation } from 'react-apollo';
import { CREATE_SESSION_VITAMINS_CHECKOUT_SESSION, CREATE_SESSION_VITAMINS_SUBSCRIPTIONS_CHECKOUT_SESSION } from '../../graphql';
import withSubscriptions from '../../lib/withSubscriptions';
import OrderB2b from './OrderB2b';

const Container = styled(Box)`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 0;
  @media screen and (max-width: 480px) {
    max-width: 100vw;
    padding-left: 6px;
    padding-right: 6px;
  }
`;

const OrderVitamins = ({ history, location, session: { isMember, isDiagnosed, b2b }, subscriptions: { vitamins } }) => {
  const defaultSelectSubscribe = (location.state || {}).defaultSelectSubscribe;
  const [isSubscribeAndSave, setIsSubscribeAndSave] = useState(false);
  const [acquisitionType, setAcquisitionType] = useState(!vitamins?'SUBSCRIBE': 'ONCE'); //(!!defaultSelectSubscribe?'SUBSCRIBE':'ONCE');
  const pricesText = {
    once: 'Buy Once: $40.00',
    subscribe: 'Subscribe and Save (10%): $27.00',
  };
  if(!!b2b && !!b2b.vitamins) {
    return <OrderB2b />
  }
  return (
    <Mutation
      mutation={acquisitionType === 'ONCE' ? CREATE_SESSION_VITAMINS_CHECKOUT_SESSION : CREATE_SESSION_VITAMINS_SUBSCRIPTIONS_CHECKOUT_SESSION}
      variables={{
        forwardUrl: acquisitionType === 'ONCE' ? `order-vitamins-confirm/{CHECKOUT_SESSION_ID}` : 'my-subscriptions',
        cancel_url: 'order-vitamins',
      }}
      onCompleted={async (res) => {
        let sessionId = null;
        if (res.createVitaminsCheckoutSession) {
          sessionId = res.createVitaminsCheckoutSession.sessionId;
        } else if (res.createVitaminsSubscriptionsCheckoutSession) {
          sessionId = res.createVitaminsSubscriptionsCheckoutSession.sessionId;
        }
        if (!sessionId) {
          return;
        }
        const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_KEY);
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({
          sessionId: sessionId,
        });
      }}
    >
      {(createSession, { loading }) => (
        <>
          <Container>
          <>
                <Intro
                  isDiagnosed={isDiagnosed}
                  loading={loading}
                  pricesText={pricesText}
                  isMember={isMember}
                  isSubscribeAndSave={() => {
                    // setIsSubscribeAndSave(true);
                    createSession()
                  }}
                  setAcquisitionType={setAcquisitionType}
                  acquisitionType={acquisitionType}
                  createSession={createSession}
                />
                <Diets />
                <Essentials />
              </>
            {/* {!isSubscribeAndSave ? (
              <>
                <Intro
                  isDiagnosed={isDiagnosed}
                  loading={loading}
                  pricesText={pricesText}
                  isMember={isMember}
                  isSubscribeAndSave={() => {
                    setIsSubscribeAndSave(true);
                  }}
                  setAcquisitionType={setAcquisitionType}
                  acquisitionType={acquisitionType}
                  createSession={createSession}
                />
                <Diets />
                <Essentials />
              </>
            ) : (
              <SelectMembership
                onSubscribe={() => {
                  history.push({
                    pathname: '/subscribe-membership',
                    state: { vitaminsCombine: true },
                  });
                  // history.push('/subscribe-membership');
                }}
                onContinue={createSession}
              />
            )} */}
          </Container>
        </>
      )}
    </Mutation>
  );
};

export default withRouter(withSession(withSubscriptions(OrderVitamins)));
