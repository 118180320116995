import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';

const Container = styled.div`
  font-size: 14px;
  padding: 0 0 2px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.5;
`;

const StyledLabel = styled('label')`
  text-transform: uppercase;
`;

class LabelText extends PureComponent {
  render() {
    return (
      <Container>
        <StyledLabel htmlFor={this.props.htmlFor}>
          {this.props.children}
        </StyledLabel>
        <Box>{this.props.helpText}</Box>
      </Container>
    );
  }
}

LabelText.propTypes = {
  helpText: PropTypes.string,
  htmlFor: PropTypes.string,
}

export default LabelText;