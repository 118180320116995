import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Flex, Heading, Text, Box } from 'rebass';
import VitaminsBox from '../../../static/vitaminsBox.png';
import FormVitamins from './components/FormVitamins';

const Intro = ({
  isMember,
  isDiagnosed,
  history,
  isSubscribeAndSave,
  createSession,
  acquisitionType,
  setAcquisitionType,
  loading,
  defaultSelectSubscribe,
}) => {
  const [description, setDescription] = useState('');

  return (
    <Flex flexDirection={['column', 'row']}>
      <img
      alt=''
        style={{
          margin: '0 -170px',
          maxHeight: '400px',
        }}
        src={VitaminsBox}
      />
      <Flex flexDirection="column" justifyContent="left">
        <Flex
          flexDirection="column"
          justifyContent="left"
          style={{ padding: '0 12px' }}
        >
          <Heading fontSize="32px">Daily Thyroid Care Supplements</Heading>

          <Text fontWeight="500" mt={3}>
            The reinvented multivitamin that works smarter to help fill the gaps
            in your thyroid health journey.
          </Text>

          <Text mt={3} mb={4}>
            Daily Thyroid Care is an iodine-free combination of vitamins,
            minerals, and herbs that provides essential nutrients required for
            optimal thyroid gland function.
          </Text>

          <FormVitamins
            isDiagnosed={isDiagnosed}
            defaultSelectSubscribe={defaultSelectSubscribe}
            isMember={isMember}
            history={history}
            isSubscribeAndSave={isSubscribeAndSave}
            createSession={createSession}
            acquisitionType={acquisitionType}
            setAcquisitionType={setAcquisitionType}
            loading={loading}
          />
        </Flex>

        <Box
          style={{
            borderTop: '1px solid black',
            height: '1px',
            width: '100%',
          }}
        />
        <Flex flexDirection={['row']} style={{ margin: '16px 6px' }}>
          <Box
            style={{ cursor: 'pointer' }}
            flex={1}
            onClick={() => {
              setDescription(description === 'directions' ? '' : 'directions');
            }}
          >
            <Flex
              flexDirection="row"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text fontWeight={description === 'directions' ? '500' : ''}>
                Directions
              </Text>
              <Box
                style={{
                  borderRadius: '50%',
                  border: '1px solid #344f79',
                  width: '26px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: '20px',
                  padding: '0',
                  height: '26px',
                  marginLeft: '12px',
                }}
              >
                +
              </Box>
            </Flex>
          </Box>
          <Box
            style={{ cursor: 'pointer' }}
            flex={1}
            onClick={() => {
              setDescription(description === 'storage' ? '' : 'storage');
            }}
          >
            <Flex
              flexDirection="row"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text fontWeight={description === 'storage' ? '500' : ''}>
                Storage
              </Text>
              <Box
                style={{
                  borderRadius: '50%',
                  border: '1px solid #344f79',
                  width: '26px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: '20px',
                  padding: '0',
                  height: '26px',
                  marginLeft: '6px',
                }}
              >
                +
              </Box>
            </Flex>
          </Box>
          <Box
            style={{ cursor: 'pointer' }}
            flex={1}
            onClick={() => {
              setDescription(description === 'warnings' ? '' : 'warnings');
            }}
          >
            <Flex
              flexDirection="row"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text fontWeight={description === 'warnings' ? '500' : ''}>
                Warnings
              </Text>
              <Box
                style={{
                  borderRadius: '50%',
                  border: '1px solid #344f79',
                  width: '26px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: '20px',
                  padding: '0',
                  height: '26px',
                  marginLeft: '12px',
                }}
              >
                +
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Text>
          {description && description === 'directions'
            ? 'Includes 60 capsules. Adults take 2 capsules daily with food or as directed by a health professional.'
            : ''}
          {description && description === 'storage'
            ? 'Store in a cool, dry place, away from direct light. Keep out of reach of children.'
            : ''}
          {description && description === 'warnings'
            ? 'If you are pregnant, nursing, have any health condition or taking any medication, consult your healthcare professional before using this product.'
            : ''}
        </Text>
        <Box
          style={{
            borderTop: '1px solid black',
            height: '1px',
            width: '100%',
            marginTop: description ? '20px' : 0,
          }}
        />
      </Flex>
    </Flex>
  );
};

export default withRouter(Intro);
