import React, { Component, Fragment } from 'react';
import { Button, Flex, Box, Text, Heading, Card, Link as ExternalLink } from 'rebass';
import styled from 'styled-components';
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import { Steps, Step, Wizard } from 'react-albus';
import { compose, withApollo } from 'react-apollo';
import { injectStripe } from 'react-stripe-elements';

import Address from './Address';
import Header from '../../components/Header';
import FullLayout from '../../components/FullLayout';
import ConfirmModal from '../../components/ConfirmModal';
import Mailbox from '../../components/icons/Mailbox';
import LoadingScreen from '../../components/LoadingScreen';
import withSession from '../../lib/withSession';
import withAnalytics from '../../lib/withAnalytics';

import { B2B_ORDER_KIT } from '../../graphql';
import { isToggleActive } from '../../components/featureToggle/toggles';
import AdditionalBiomarkers from './AdditionalBiomarkers';
import { isMobile } from 'react-device-detect';
import KlaviyoEventer from './KlaviyoEventer';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const MobileButton = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% + 32px);
  /* height: 40px; */
  justify-content: center;
  z-index: 1;
`;

const StyledLink = styled(ExternalLink)`
  font-size: 14px;
  color: #999;
  text-decoration: none;
  align-self: center;
`;
const InfoBox = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${(props) => props.theme.color.darkBlue};
  justify-content: center;
`;

const initialState = {
  order: {
    id: '',
    amount: '',
    items: [],
  },
  address: {
    firstName: '',
    lastName: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
  },
  prompt: false,
  ready: false,
  loading: false,
  coupon: undefined,
  giveToFriend: undefined,
  isCouponBOGOdisabledModal: undefined,
  canMakePayment: false,
  canMakePaymentApplePay: undefined,
  paymentRequest: undefined,
  isPaymentThroughWallet: false,
  wizard: undefined,
  isConfirmNotDuplicateModalOpen: false,
  biomarkers: [],
  additionalBiomarkersFeature: false,
  reverseT3Feature: false,
  vitaminDFeature: false,
  isComputingBiomarker: false,
  quadPaySubscribedData: undefined,
};

class B2bPurchase extends Component {
  constructor(props, context) {
    super(props, context);
    this.wizard = React.createRef();

    const biom = {
      additionalBiomarkersFeature: isToggleActive('KIT_ADDITIONAL_BIOMARKERS'),
      reverseT3Feature: isToggleActive('KIT_REVERSE_T3'),
      vitaminDFeature: isToggleActive('KIT_VITAMIN_D'),
    };

    this.state = {
      ...initialState,
      ...biom,
    };
  }

  componentDidMount = async () => {
    const {
      session: { isMember },
    } = this.props;

    let tag = '';

    if (this.props.location.pathname.indexOf('address') > -1) {
      tag = 'address';
    } else if (this.props.location.pathname.indexOf('complete') > -1) {
      tag = 'complete';
    } else if (this.props.location.pathname.indexOf('payment') > -1) {
      tag = 'payment';
    } else if (this.props.location.pathname.indexOf('confirm') > -1) {
      tag = 'confirm';
    }
    if (tag) {
      this.props.analytics.page(tag, 'purchaseKit');
    }
    this.setState({ ready: true });
  };

  componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;
    if (currentPage !== prevPage) {
      let tag = '';
      window.scrollTo(0, 0);
      if (currentPage.indexOf('address') > -1) {
        tag = 'address b2b';
      } else if (currentPage.indexOf('complete') > -1) {
        tag = 'complete b2b';
      } else if (currentPage.indexOf('payment') > -1) {
        tag = 'payment b2b';
      } else if (currentPage.indexOf('confirm') > -1) {
        tag = 'confirm b2b';
      } else if (currentPage.indexOf('selectMembership') > -1) {
        tag = 'selectMembership b2b';
      } else if (currentPage.indexOf('selectPlan') > -1) {
        tag = 'selectPlan b2b';
      } else if (currentPage.indexOf('additionalBiomarkers') > -1) {
        tag = 'additionalBiomarkers b2b';
      } else if (currentPage.indexOf('address') > -1) {
        tag = 'address b2b';
      }
      if (tag) {
        this.props.analytics.page(tag, 'purchaseKit');
      }
    }
  }

  handleSubmit = (key, values, wizard) => {
    this.setState({ [key]: { ...values } }, () => {
      this.handleComplete(wizard);
    });
  };

  addBiomarkerToOrder = async (biomarkers) => {};

  handleClose = async () => {};

  handleComplete = async (wizard) => {
    const { toastManager } = this.props;
    const { address } = this.state;
    const order = await this.props.client.mutate({
      mutation: B2B_ORDER_KIT,
      variables: {
        biomarkers: this.state.biomarkers,
        address: {
          street1: address.street1,
          street2: address.street2,
          city: address.city,
          state: address.state,
          zip: address.zip,
        },
      },
    });

    if (order && order.data && order.data.b2bOrderKit && order.data.b2bOrderKit.ok) {
      wizard.next();
    } else {
      toastManager.add('An error occured', { appearance: 'error' });
    }
  };

  render() {
    const { history, session, refetch } = this.props;
    const {
      order,
      prompt,
      address,
      ready,
      loading,
      giveToFriend,
      isCouponBOGOdisabledModal,
      isConfirmNotDuplicateModalOpen,
      biomarkers,
      additionalBiomarkersFeature,
      wizard,
      isComputingBiomarker,
    } = this.state;

    if (!ready || loading) {
      return <LoadingScreen loading={true} text="Please Wait" />;
    }

    if (prompt) {
      return (
        <Fragment>
          <ConfirmModal isOpen={prompt} onClose={this.handleClose} onBackgroundClick={this.handleClose} onEscapeKeydown={this.handleClose} onConfirm={this.handleConfirm}>
            <ModalInner>
              <Heading mb={4} textAlign="center" fontWeight={500}>
                Existing Order Found
              </Heading>

              <Text fontSize={3} textAlign="center" mb={4}>
                Would you like to continue where you left off?
              </Text>
            </ModalInner>
          </ConfirmModal>
          <FullLayout />
        </Fragment>
      );
    }

    const stepsVal = [
      {
        comp: (
          <Step id="address" key="address">
            <Address values={address} onSubmit={this.handleSubmit} />
          </Step>
        ),
        key: 'address',
        display: true,
      },
      {
        comp: (
          <Step id="complete" key="complete">
            <Box flex={1} flexDirection="column">
              {!!isToggleActive('HOLIDAY_DELAYS') && (
                <Card
                  p={4}
                  mb={4}
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#d9e6f9',
                  }}
                >
                  <Heading textAlign="center" mb={4} color="#344f79">
                    Temporary Lab Closure for Thanksgiving
                  </Heading>
                  <Text textAlign="center" mb={4} color="#344f79">
                  Our laboratory will be closed on Thursday 11/23 and Friday 11/24 in observance of the Thanksgiving holiday. There will be a slight delay in the processing and delivery of your test kit results. As soon as our lab receive your sample we will be able to share an exact ETA. Please take these closure dates into consideration if you decide to book a visit before your results are released.
                  </Text>
                </Card>
              )}
              <KlaviyoEventer event="Kit Ordered" />
              <Card p={4}>
                <Flex flexDirection="column">
                  <Heading textAlign="center" fontWeight={600} mb={4}>
                    Your Order is Confirmed
                  </Heading>

                  <Text textAlign="center" mb={4}>
                    Your order has been placed and you should receive a kit soon.
                    {giveToFriend && giveToFriend.refereeFirstName ? (
                      <>
                        <br />
                        {`${giveToFriend.refereeFirstName} ${giveToFriend.refereeLastName} will receive an email with information on how to claim your gifted kit.`}
                      </>
                    ) : (
                      ''
                    )}
                  </Text>

                  <Flex justifyContent="center">
                    <Button variant="pink" width={[1, 1 / 3]} onClick={() => history.push('/')}>
                      Your Dashboard
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </Box>
          </Step>
        ),
        key: 'complete',
        display: true,
      },
    ];

    return (
      <Fragment>
        <Container flex={1} px={3}>
          {history.location.pathname !== '/purchase/selectMembership' && (
            <Flex flex={1}>
              <Header dark={false}>
                <Text color="#486288" fontSize={3} fontWeight={500} textAlign="center">
                  Purchase a Thyroid Blood Test Kit
                </Text>
              </Header>
            </Flex>
          )}
          <Flex
            flexDirection={
              history.location.pathname !== '/purchase/address' && history.location.pathname !== '/purchase/additionalBiomarkers'
                ? ['column-reverse', 'column-reverse', 'row']
                : ['column', 'column', 'row']
            }
          >
            <Flex flex={1} mr={[0, 0, 4]} mb={4}>
              <Wizard ref={this.wizard} history={history} basename="/purchase">
                <Steps>{stepsVal.filter((x) => x.display).map((x) => x.comp)}</Steps>
              </Wizard>
            </Flex>
            {history.location.pathname !== '/purchase/complete' &&
              history.location.pathname !== '/purchase/selectMembership' &&
              history.location.pathname !== '/purchase/selectPlan' &&
              history.location.pathname !== '/purchase/welcomemembership' && (
                <Flex width={[1, 1, 1 / 3]} mb={[4, 4, 0]} flexDirection="column">
                  <Box>
                    <Card p={4}>
                      <Flex flexDirection="column">
                        <Flex mb={3} justifyContent="center">
                          <Mailbox width={184} />
                        </Flex>

                        <Flex flex={1} mb={3} flexDirection="column">
                          {order.items.map((item, i) => (
                            <Flex flex={1} key={i}>
                              <Flex flex={1}>
                                <Text fontWeight={300}>{(item.description || '').startsWith('REF_') ? 'Referral credits' : item.description}</Text>
                              </Flex>
                              <Flex justifyContent="flex-end">${(item.amount / 100).toFixed(2)}</Flex>
                            </Flex>
                          ))}
                          <Text fontSize={3} fontWeight={500} textAlign="center" mt={3}>
                            {order.items.length > 0 ? 'Total' : 'Complete Thyroid Test'} ${order.amount ? (order.amount / 100).toFixed(2) : '0.00'}
                          </Text>
                        </Flex>

                        <Text fontWeight={300} textAlign="center">
                          You'll collect your sample at home.
                        </Text>

                        {/* <StyledLink mt={3} href="https://palomahealth.com/kit">
                        Learn More
                      </StyledLink> */}
                      </Flex>
                    </Card>
                  </Box>
                </Flex>
              )}
          </Flex>
          <Fragment>
            <ConfirmModal
              confirmLabel="OK"
              isOpen={isCouponBOGOdisabledModal}
              okOnly
              onClose={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
              onConfirm={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
              onBackgroundClick={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
              onEscapeKeydown={() => {
                this.setState({
                  isCouponBOGOdisabledModal: false,
                });
              }}
            >
              <ModalInner>
                <Heading mb={4} mx={3} textAlign="center">
                  January is National Thyroid Awareness Month – Buy a Kit, Give a Kit FREE
                </Heading>
                <InfoBox bg="#D9E6F9" mb={4}>
                  <Text fontSize={3} fontWeight={700} textAlign="center">
                    Paloma is committed to elevating and spreading access to great thyroid care. In the spirit of Thyroid Awareness Month, we are giving a free kit for every kit purchased. Instead of
                    using a promo code, in January only, Paloma is providing the ability to gift a friend or loved one a free thyroid test to.
                  </Text>
                </InfoBox>
              </ModalInner>
            </ConfirmModal>
          </Fragment>
        </Container>
        {/* </FeatureToggleProvider> */}
        {isComputingBiomarker ? <LoadingScreen bg={'rgba(0,0,0,0.33)'} loading={true} showLogo={false} text="Adding Biomarker" /> : null}
        <FullLayout />

        {isMobile &&
          history.location.pathname === '/purchase/selectMembership' && (
            <MobileButton
              onClick={() => {
                history.push('/purchase/selectPlan');
              }}
            >
              <Box
                style={{
                  background: '#354E79',
                  color: 'white',
                  width: '100%',
                  padding: '20px',
                  textAlign: 'center',
                  fontSize: '20px',
                }}
              >
                Join Now
              </Box>
            </MobileButton>
          )}
      </Fragment>
    );
  }
}

export default injectStripe(
  compose(
    withApollo,
    withRouter,
    withToastManager,
    withSession,
    withAnalytics
  )(B2bPurchase)
);
