import React, { Fragment } from 'react';
import { Flex, Button, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';
import { withApollo, compose } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import * as Yup from 'yup';

import FormField from '../../../components/FormField';
import RadioInput from '../../../components/RadioInput';

const Extended = ({ onSubmit, data, toastManager }) => {
  const schema = Yup.object().shape({
    medicationHistoryConsentVerified: Yup.string().required('Required'),
  });

  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
        <Formik
          isInitialValid={({ data }) => schema.isValidSync(data)}
          initialValues={{
            ...data,
            medicationHistoryConsentVerified: null,
          }}
          validationSchema={schema}
          onSubmit={async (values, actions) => {
            try {
              await onSubmit(values, actions);
            } catch (error) {
              toastManager.add(error, {
                appearance: 'error',
                autoDismissTimeout: 10000,
              });
            }
          }}
          render={({ touched, errors, isValid, isSubmitting, values }) => {
            return (
              <Form>
                <Flex flexDirection="column" alignItems="center">
                  <Text mb={3} fontSize={3} textAlign="center">
                    Medication history consent
                  </Text>
                  <Text textAlign="center" fontWeight={300} fontSize={2} color="#aaa" mb={3}>
                    Your medication history gives our physicians vital information to treat you.
                  </Text>
                  <FormField flexDirection="row" justifyContent="center" mb={5} error={touched.medicationHistoryConsentVerified && errors.medicationHistoryConsentVerified}>
                    <RadioInput name="medicationHistoryConsentVerified" value={true} label="Yes" mr={5} />
                    <RadioInput name="medicationHistoryConsentVerified" value={false} label="No" />
                  </FormField>
                  <Button
                    disabled={values.medicationHistoryConsentVerified === null}
                    variant="primary"
                    style={{
                      marginLeft: '12px',
                      marginRight: '12px',
                    }}
                    width={[1, 1 / 2, 1 / 4]}
                    type="submit"
                  >
                    Continue
                  </Button>
                </Flex>
              </Form>
            );
          }}
        />
      </Flex>
    </Fragment>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(Extended);
