import React, { useState } from 'react';
import { Box, Card, Flex, Heading, Button, Text } from 'rebass';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import FormField from '../components/FormField';
import TextInput from '../components/TextInput';
import { MaskInput } from '../components/MaskInput';
import withSession from '../lib/withSession';
import { compose, Mutation, Query, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { CREATE_HEALTHCOACH_USER, GET_CALENDLY_USER_BY_EMAIL } from '../graphql';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import ConfirmModal from '../components/ConfirmModal';
import Modal from 'styled-react-modal';
import { SpecialModalBackground } from './adminReorderPhysicianConvo/shared';
import AddNewHealthCoachUser from './adminHealthCoaches/AddNewHealthCoachUser';

const CardBox = styled(Card)`
  width: 100%;
  padding: 32px;
`;
const Container = styled(Box)`
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
  padding-top: 20px;
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;
const InfoBox = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${(props) => props.theme.color.darkBlue};
  justify-content: center;
`;
const phoneRegExp = /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .trim()
    .min(8, 'Password must be greater than 8 characters')
    .required('Required'),
  // helpscoutId: Yup.number().required('Required'),
  displayName: Yup.string().required('Required'),
  phone: Yup.string()
    .trim()
    .required('Required')
    .matches(phoneRegExp, 'Phone number is not valid'),
});

const CreateHealthCoachUser = ({ toastManager, client }) => {
  const [calendlyUsers, setCalendlyUsers] = useState(null);
  const [selectedCalendlyUser, setSelectedCalendlyUser] = useState(null);
  const [bypassCalendly, setBypassCalendly] = useState(false);
  const [bypassCalendlyDuplicate, setBypassCalendlyDuplicate] = useState(false);
  const [foundDuplicateCalendlyUser, setFoundDuplicateCalendlyUser] = useState(false);

  const getCalendlyUsers = async (values, actions) => {
    actions.setSubmitting(true);
    const users = await client.query({
      query: GET_CALENDLY_USER_BY_EMAIL,
      variables: {
        email: values.email.toLowerCase(),
      },
    });
    console.log({ users });
    setCalendlyUsers((users || {}).data);
    actions.setSubmitting(false);
  };
  return (
    <Container>
      <CardBox mb={4}>
        <Flex flexDirection="column">
          <Box>
            <Heading textAlign="left" mb={4}>
              Create Health Coach User
            </Heading>
            {!calendlyUsers && !selectedCalendlyUser ? (
              <Formik
                initialValues={{
                  email: '',
                }}
                onSubmit={(values, actions) => getCalendlyUsers(values, actions)}
                render={({ touched, errors, isValid, values, isSubmitting, handleChange, handleBlur }) => (
                  <Form>
                    <FormField mb={3} error={touched.email && errors.email}>
                      <TextInput placeholder="Email" type="email" name="email" />
                    </FormField>

                    <Flex alignItems="center" flexDirection="column">
                      <Button mb={4} disabled={!isValid || isSubmitting} variant="primary" width="200px" type="submit">
                        Find Calendly User
                      </Button>
                    </Flex>
                  </Form>
                )}
              />
            ) : (
              <>
                {!selectedCalendlyUser && !bypassCalendly ? (
                  <Flex flexDirection={'column'}>
                    <Flex>
                      <Text>{`(${calendlyUsers.adminGetCalendlyUserByEmail.length}) Calendly users found`}</Text>
                      <Text
                        ml={4}
                        style={{ textDecoration: 'underline' }}
                        onClick={() => {
                          setCalendlyUsers(null);
                          setSelectedCalendlyUser(null);
                        }}
                      >
                        Cancel
                      </Text>
                    </Flex>
                    {calendlyUsers.adminGetCalendlyUserByEmail.map((x) => (
                      <CardBox mt={3} style={{ position: 'relative' }}>
                        <Flex>
                          <img src={x.avatarUrl} width={'48px'} height={'48px'} />
                          <Flex flexDirection={'column'} ml={3} mr={3} flex={1}>
                            <Text mb={3}>{x.name}</Text>
                            <Text fontSize={'12px'} style={{ opacity: 0.7 }}>
                              Calendly Id: {x.uri.replace('https://api.calendly.com/users/', '')}
                            </Text>
                            <Text fontSize={'12px'} style={{ opacity: 0.7 }}>
                              Created At: {x.createdAt}
                            </Text>
                          </Flex>
                          {x.role && (
                            <Box
                              style={{
                                position: 'absolute',
                                top: 4,
                                right: 4,
                                borderRadius: '8px',
                                background: x.role === 'admin' ? '#d8ebd8' : x.role === 'owner' ? '#fdd0d0' : '#f0f0f0',
                                padding: '4px 8px',
                              }}
                            >
                              <Text>{x.role}</Text>
                            </Box>
                          )}
                          <Button variant={'primary'} style={{ alignSelf: 'center' }} onClick={() => setSelectedCalendlyUser(x)}>
                            Select
                          </Button>
                        </Flex>
                      </CardBox>
                    ))}
                  </Flex>
                ) : (
                  <AddNewHealthCoachUser selectedCalendlyUser={selectedCalendlyUser} setSelectedCalendlyUser={setSelectedCalendlyUser} />
                )}
              </>
            )}
          </Box>
        </Flex>
      </CardBox>
    </Container>
  );
};

export default compose(
  withApollo,
  withSession,
  withToastManager
)(CreateHealthCoachUser);
