import React, { useState } from 'react';
import { Box, Card, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { withRouter, NavLink as RouterNavLink } from 'react-router-dom';
import { withApollo, compose } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDollarSign,
  faAt,
  faArrowCircleRight,
  faPersonBooth,
  faQrcode,
  faCalendar,
  faCalendarAlt,
  faUserFriends,
  faQuestionCircle,
  faBarcode,
  faRedo,
  faMobileAlt,
  faUser,
  faUserSlash,
  faUserMd,
  faFileAlt,
  faCodeBranch,
  faLayerGroup,
  faDiagnoses,
} from '@fortawesome/free-solid-svg-icons';

import withSession from '../lib/withSession';
import { isToggleActive } from '../components/featureToggle/toggles';
import { AdminSearchPatientDashboard } from './adminSearchPatient';

const Container = styled(Box)`
  width: 100%;
  /* max-width: 850px; */
`;

const NavLink = styled(RouterNavLink)`
  color: #344f79;
  text-decoration: none;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 400;
  :disabled {
    opacity: 0.5;
  }
`;

const ForbiddenNavLink = styled(RouterNavLink)`
  color: #344f79;
  text-decoration: none;
  cursor: not-allowed;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 400;
  opacity: 0.5;
`;

const Title = styled(Text)`
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 500;
  margin: -8px;
  padding: 8px;
  background: #f1f1f1;
`;

const RIGHTS = (adm, toggleDisabled) => {
  const rights = [
    {
      category: 'PATIENT',
      link: '/change-email',
      title: 'change email',
      allowed: !!toggleDisabled || (!!adm && !!adm.canChangeEmail),
      icon: faAt,
    },
    {
      category: 'PATIENT',
      link: '/remap-account',
      title: 'remap account',
      allowed: !!toggleDisabled || (!!adm && !!adm.canRemapAccount),
      icon: faArrowCircleRight,
    },
    {
      category: 'PATIENT',
      link: '/remap-associated-user-to-mobile',
      title: 'remap associated user to mobile',
      allowed: !!toggleDisabled || (!!adm && !!adm.canRemapAccount),
      icon: faArrowCircleRight,
    },
    {
      category: 'PATIENT',
      link: '/admin-search-patients',
      title: "Search Patient's accounts",
      allowed: !!toggleDisabled || !!adm,
    },
    {
      category: 'PATIENT',
      link: '/remap-pwn',
      title: 'remap pwn orders',
      allowed: !!toggleDisabled || (!!adm && !!adm.canRemapAccount),
      icon: faArrowCircleRight,
    },
    {
      category: 'PATIENT',
      link: 'https://airtable.com/shrExpZIUolP9v4Wy',
      title: 'itemized receipt form',
      allowed: !!toggleDisabled || (!!adm),
      icon: faArrowCircleRight,
    },
    {
      category: 'PATIENT',
      link: '/admin-patient-stripe-change',
      title: 'change Stripe Id',
      allowed: !!toggleDisabled || (!!adm && !!adm.canStripeUserManagement),
      icon: faDollarSign,
    },
    {
      category: 'PATIENT',
      link: '/admin-patient-helpscout-change',
      title: 'change Helpscout Id',
      allowed: !!toggleDisabled || (!!adm && !!adm.canStripeUserManagement),
      icon: faDollarSign,
    },
    {
      category: 'PATIENT',
      link: '/admin-impersonate-patient',
      title: 'Impersonate',
      allowed: !!toggleDisabled || (!!adm && !!adm.imp),
      icon: faPersonBooth,
    },
    {
      category: 'PATIENT',
      link: '/generate-otp',
      title: 'Generate OTP',
      allowed: !!toggleDisabled || (!!adm && !!adm.canGenerateOtp),
      icon: faQrcode,
    },
    {
      category: 'PATIENT',
      link: '/consultations',
      title: 'Doctor Visits',
      allowed: !!toggleDisabled || !!adm,
      icon: faUserMd,
    },
    {
      category: 'PATIENT',
      link: '/referrals-management',
      title: 'Referrals Management',
      allowed: !!toggleDisabled || !!adm,
      icon: faUserFriends,
    },
    {
      category: 'PATIENT',
      link: '/batch-balance',
      title: 'Batch Balance Adjustment',
      allowed: !!toggleDisabled || !!adm,
      icon: faDollarSign,
    },
    {
      category: 'PATIENT',
      link: '/pending-registration',
      title: 'Pending Registrations',
      allowed: !!toggleDisabled || !!adm,
      icon: faQuestionCircle,
    },
    {
      category: 'PATIENT',
      link: '/unregister-kit',
      title: 'Unregister Kits',
      allowed: !!toggleDisabled || !!adm,
      icon: faBarcode,
    },
    {
      category: 'PATIENT',
      link: '/reset-intake-status',
      title: 'Reset Intake Status',
      allowed: !!toggleDisabled || !!adm,
      icon: faRedo,
    },
    {
      category: 'PATIENT',
      link: '/admin-care-managers',
      title: 'Care Managers',
      allowed: !!toggleDisabled || !!adm,
      icon: faUserFriends,
    },
    {
      category: 'PATIENT',
      link: '/admin-bulk-activate-survey-flag',
      title: 'Bulk activate survey flags',
      allowed: !!toggleDisabled || !!adm,
      icon: faBarcode,
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-mobile-push-notification',
      title: 'Send Push Notification',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
      icon: faMobileAlt,
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-mobile-symptom-tracker',
      title: 'Symptom Tracker',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
      icon: faMobileAlt,
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-mobile-webflow-articles',
      title: 'Articles',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
      icon: faMobileAlt,
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-mobile-recipes',
      title: 'Recipes',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
      icon: faMobileAlt,
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-mobile-motivation-quotes',
      title: 'Motivation Quotes',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
      icon: faMobileAlt,
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-mobile-courses',
      title: 'AIP Program',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
      icon: faMobileAlt,
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-preset-plans',
      title: 'Preset Plans',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
      icon: faMobileAlt,
    },
    {
      category: 'MOBILE_MANAGEMENT',
      link: '/admin-workouts',
      title: 'Workouts',
      allowed: !!toggleDisabled || (!!adm && !!adm.mobileMan),
      icon: faMobileAlt,
    },
    {
      category: 'UNITHROID',
      link: '/admin-mobile-symptom-tracker-unithroid',
      title: 'Symptom Trackers',
      allowed: !!toggleDisabled || (!!adm && !!adm.canUnithroid),
      icon: faMobileAlt,
    },

    {
      category: 'MOBILE_USERS',
      link: '/admin-mobile-change-user-week',
      title: 'Change User Week',
      allowed: !!toggleDisabled || (!!adm && !!adm.canMobileUserManagement),
      icon: faUser,
    },
    {
      category: 'MOBILE_USERS',
      link: '/admin-delete-mobile-user',
      title: 'Delete User',
      allowed: !!toggleDisabled || (!!adm && !!adm.canMobileUserManagement),
      icon: faUserSlash,
    },
    {
      category: 'MOBILE_USERS',
      link: '/admin-reset-mobile-user-week',
      title: 'Reset User to Week 1',
      allowed: !!toggleDisabled || (!!adm && !!adm.canMobileUserManagement),
      icon: faUser,
    },
    {
      category: 'MOBILE_USERS',
      link: '/admin-update-mobile-profile',
      title: 'Edit mobile profile',
      allowed: !!toggleDisabled || (!!adm && !!adm.canMobileUserManagement),
      icon: faUser,
    },

    {
      category: 'SUPER_ADMIN',
      link: '/admin-reports',
      title: 'Reporting',
      allowed: !!toggleDisabled || (!!adm && !!adm.canReporting),
      icon: faFileAlt,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/admin-backfill',
      title: 'Big Query Backfill',
      allowed: !!toggleDisabled || (!!adm && !!adm.canReporting),
      icon: faFileAlt,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/feature-toggles-management',
      title: 'Feature Toggles Management',
      allowed: !!toggleDisabled || (!!adm && !!adm.canFeatureTogglesManagement),
      icon: faCodeBranch,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/batch-migrate-provider-group',
      title: 'Migrate Prividers Group',
      allowed: !!toggleDisabled || (!!adm && !!adm.canBatchMigrateProviderGroups),
      icon: faLayerGroup,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/one-time-coupons-management',
      title: 'One Time Coupons',
      allowed: !!toggleDisabled || (!!adm && !!adm.canOneTimeCouponManagement),
      icon: faDollarSign,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/my-logs',
      title: 'My Logs',
      allowed: !!toggleDisabled || !!adm,
      icon: faDiagnoses,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/duplicated-ids',
      title: 'Duplicated Ids',
      allowed: !!toggleDisabled || !!adm,
      icon: faDiagnoses,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/admin-logs',
      title: 'Other Admins Logs',
      allowed: !!toggleDisabled || (!!adm && !!adm.canViewAdminsLogs),
      icon: faDiagnoses,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/admin-create-provider-user',
      title: 'Create Provider User',
      allowed: !!toggleDisabled || (!!adm && !!adm.canAdminProvidersApp),
      icon: faUser,
    },
    // {
    //   category: 'SUPER_ADMIN',
    //   link: '/admin-create-healthcoach-user',
    //   title: 'Create Health Coach User',
    //   allowed: !!toggleDisabled || (!!adm && !!adm.canAdminProvidersApp),
    //   icon: faUser,
    // },
    {
      category: 'HEALTH_COACH',
      link: '/admin-view-healthcoaches',
      title: 'View Health Coach Users',
      allowed: !!toggleDisabled || (!!adm && !!adm.canAdminProvidersApp),
      icon: faUser,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/admin-b2b-management',
      title: 'B2B Brokers management',
      allowed: !!toggleDisabled || (!!adm && !!adm.canB2BManagement),
      icon: faUser,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/admin-batch-slots',
      title: 'Batch Slots Creation',
      allowed: !!toggleDisabled || !!adm,
      icon: faUserMd,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/run-pr',
      title: 'Run process',
      allowed: !!toggleDisabled || (!!adm && !!adm.canBatchMigrateProviderGroups),
      icon: faDiagnoses,
    },
    {
      category: 'HEALTH_COACH',
      link: '/hc-template-list',
      title: 'Form Templates',
      allowed: !!toggleDisabled || (!!adm && !!adm.canAdminHealthCoachApp),
      icon: faFileAlt,
    },
    {
      category: 'SUPER_ADMIN',
      link: '/AdminBannerFilterList',
      title: 'Filtered Banners Management',
      allowed: !!toggleDisabled || (!!adm && !!adm.canFeatureTogglesManagement),
      icon: faCodeBranch,
    },
  ];

  return rights;
};

const CollapsingSection = ({ links, title }) => {
  const [isCollapsed, setIsCollapsed] = useState(links.filter((x) => !!x.allowed).length < 1);

  return (
    <Flex width="100%" p={3}>
      <Card>
        <Flex flexDirection="column">
          <Box p={2}>
            <Title onClick={() => setIsCollapsed(!isCollapsed)}>{title}</Title>
            {!isCollapsed && (
              <Flex flexDirection={'row'} flexWrap={'wrap'} mt={3}>
                {links.map((x) => {
                  if (!x.allowed) {
                    return (
                      <ForbiddenNavLink disabled to="#" style={{ width: 'calc(80% / 3)' }} mr={4}>
                        <Flex flexDirection={'row'} alignItems={'center'}>
                          <FontAwesomeIcon icon={x.icon} size="xs" style={{ marginRight: '6px' }} />
                          {x.title}
                        </Flex>
                      </ForbiddenNavLink>
                    );
                  }
                  if (x.link.indexOf('https://') > -1) {
                    return (
                      <NavLink style={{ width: 'calc(80% / 3)' }} to={{ pathname: x.link }} target="_blank" mr={4}>
                        <Flex flexDirection={'row'} alignItems={'center'}>
                          <FontAwesomeIcon icon={x.icon} size="xs" style={{ marginRight: '6px' }} />
                          {x.title}
                        </Flex>
                      </NavLink>
                    );
                  } else {
                    return (
                      <NavLink style={{ width: 'calc(80% / 3)' }} to={x.link} mr={4}>
                        <Flex flexDirection={'row'} alignItems={'center'}>
                          <FontAwesomeIcon icon={x.icon} size="xs" style={{ marginRight: '6px' }} />
                          {x.title}
                        </Flex>
                      </NavLink>
                    );
                  }
                })}
              </Flex>
            )}
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
};

const LandingAdmin = ({ session: { adm } }) => {
  const activeTogglePersonalAdmin = isToggleActive('PERSONAL_ADMIN');

  const getPatientsRights = RIGHTS(adm, !activeTogglePersonalAdmin)
    .filter((x) => x.category === 'PATIENT')
    .sort((a, b) => {
      if (a.title < b.title) return -1;

      return 1;
    });

  const getMobileRights = RIGHTS(adm, !activeTogglePersonalAdmin)
    .filter((x) => x.category === 'MOBILE_MANAGEMENT')
    .sort((a, b) => {
      if (a.title < b.title) return -1;

      return 1;
    });

  const getMobileUsersRights = RIGHTS(adm, !activeTogglePersonalAdmin)
    .filter((x) => x.category === 'MOBILE_USERS')
    .sort((a, b) => {
      if (a.title < b.title) return -1;

      return 1;
    });

  const getUnithroidRights = RIGHTS(adm, !activeTogglePersonalAdmin)
    .filter((x) => x.category === 'UNITHROID')
    .sort((a, b) => {
      if (a.title < b.title) return -1;

      return 1;
    });

  const getSuperAdminRights = RIGHTS(adm, !activeTogglePersonalAdmin)
    .filter((x) => x.category === 'SUPER_ADMIN')
    .sort((a, b) => {
      if (a.title < b.title) return -1;

      return 1;
    });

    const getHealthCoachAppRights = RIGHTS(adm, !activeTogglePersonalAdmin)
      .filter((x) => x.category === 'HEALTH_COACH')
      .sort((a, b) => {
        if (a.title < b.title) return -1;
  
        return 1;
      });

  return (
    <Container style={{ minHeight: 'calc(100% - 33px)' }}>
      <Flex justifyContent="center" style={{ zIndex: 1 }} flexDirection={'column'}>
        <AdminSearchPatientDashboard />
        <CollapsingSection title="Patients management" links={getPatientsRights} />
        <CollapsingSection title="Mobile management" links={getMobileRights} />
        <CollapsingSection title="Mobile Users management" links={getMobileUsersRights} />
        <CollapsingSection title="Unithroid management" links={getUnithroidRights} />
        <CollapsingSection title="Health Coach App management" links={getHealthCoachAppRights} />
        <CollapsingSection title="Administration" links={getSuperAdminRights} />
      </Flex>
    </Container>
  );
};

export default compose(
  withSession,
  withRouter,
  withApollo
)(LandingAdmin);
