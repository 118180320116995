import React, { Component } from 'react';
import { Card, Flex, Box, Heading, Text } from 'rebass';
import styled from 'styled-components';
import withSession from '../../lib/withSession';
import ConfirmModal from '../../components/ConfirmModal';
import { Mutation } from 'react-apollo';
import { CHANGE_MEDICATION_CONSENT } from '../../graphql';

const CardBox = styled(Card)`
  width: 100%;
  padding: 32px;
`;
const InfoBox = styled(Flex)`
  flex: 1;
  background: ${props => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${props => props.theme.color.darkBlue};
  justify-content: center;
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const Circle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  align-self: center;
  background-color: ${props => props.theme.color.white};
  width: ${props => props.theme.px(2)};
  height: ${props => props.theme.px(2)};
  margin-right: ${props => props.theme.px(1)};
`;

const Label = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  align-self: center;
  color: ${props => props.color || props.theme.color.greyDark};
`;

const styles = {
  focus: {
    Circle: {
      borderColor(props) {
        if (props.disabled) {
          return `0 0 0 1px ${props.theme.color.grey}`;
        }
        if (props.error) {
          return `0 0 0 1px ${props.theme.color.red}`;
        }
        return `0 0 0 1px ${props.theme.color.primary}`;
      },
    },
  },
  checked: {
    Circle: {
      backgroundColor(props) {
        if (props.disabled) {
          return props.theme.color.grey;
        }
        if (props.error) {
          return props.theme.color.red;
        }
        return props.theme.color.primary;
      },
    },
  },
  Circle: {
    borderColor(props) {
      if (props.disabled) {
        return props.theme.color.greyLight;
      }
      if (props.error) {
        return props.theme.color.red;
      }
      return props.theme.color.greyLight;
    },
  },
};

const InputStyled = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(1px 0 0 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
  + ${/* sc-selector */ Circle} {
    border-color: ${styles.Circle.borderColor};
  }
  &:focus {
    + ${/* sc-selector */ Circle} {
      border-color: ${props => props.theme.color.primary};
    }
  }
  &:checked {
    + ${/* sc-selector */ Circle} {
      border-color: ${props => props.theme.color.primary};
      &::before {
        content: '';
        width: ${props => props.theme.px(1)};
        height: ${props => props.theme.px(1)};
        border-radius: 50%;
        background-color: #344f79;
      }
    }
  }
`;

const RadioInput = props => {
  const { id, checked } = props;
  return (
    <Flex
      onClick={e => {
        props.onClicked(checked);
      }}
      alignItems="center"
      {...props}
    >
      <Label htmlFor={id} color={props.color}>
        <InputStyled id={id} type="radio" {...props} />
        <Circle />
        {props.label}
      </Label>
    </Flex>
  );
};

class MedicationHistoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prompt: false,
      medicationHistoryConsentVerified: this.props.session
        .medicationHistoryConsentVerified,
      promptCancelledConsent: false,
    };
  }

  closeConfirm = () => {
    this.setState({ prompt: false, medicationHistoryConsentVerified: true });
  };

  openConfirm = () => {
    this.setState({ prompt: true });
  };

  handleCancelMedication = () => {
    this.setState({ prompt: false, medicationHistoryConsentVerified: false });
  };

  closeConfirmConsent = () => {
    this.setState({ promptCancelledConsent: false });
  };

  render() {
    return (
      <Mutation
        mutation={CHANGE_MEDICATION_CONSENT}
        onCompleted={() => {
          if (!this.state.medicationHistoryConsentVerified) {
            this.setState({ promptCancelledConsent: true, prompt: false });
          }
        }}
      >
        {(changeMedication, { loading }) => (
          <CardBox mb={8}>
            <Flex flexDirection="column">
              <Box>
                <Heading textAlign="left" mb={4}>
                  Medication History
                </Heading>
                <Text textAlign="left" mb={3}>
                  Paloma Health can pull your medication list from your online
                  health profile. Do you agree to share that information with
                  your Paloma Health providers?
                </Text>
                <Flex style={{ justifyContent: 'center' }}>
                  <RadioInput
                    id="diagnosed"
                    checked={this.state.medicationHistoryConsentVerified}
                    label="Yes"
                    mr={5}
                    onClicked={value => {
                      if (!value) {
                        changeMedication({ variables: { consents: true } });
                        this.setState({
                          medicationHistoryConsentVerified: true,
                        });
                      }
                    }}
                  />
                  <RadioInput
                    id="diagnosed1"
                    checked={!this.state.medicationHistoryConsentVerified}
                    label="No"
                    onClicked={value => {
                      if (!value) this.openConfirm();
                    }}
                  />
                </Flex>
                {/* <ToggleSwitch
                  value={this.state.medicationHistoryConsentVerified}
                  disabled={loading}
                  onChange={value => {
                    console.log('VAL', value);
                    if (!value) this.openConfirm();
                    else {
                      changeMedication({ variables: { consents: true } });
                      this.setState({ medicationHistoryConsentVerified: true });
                    }
                  }}
                /> */}
              </Box>
            </Flex>

            <ConfirmModal
              isOpen={this.state.prompt}
              onClose={this.closeConfirm}
              onBackgroundClick={this.closeConfirm}
              onConfirm={() => {
                this.setState({ medicationHistoryConsentVerified: false });
                changeMedication({ variables: { consents: false } });
              }}
            >
              <ModalInner>
                <Heading mb={4} textAlign="center">
                  Medication History Consent
                </Heading>

                <InfoBox bg="#D9E6F9" mb={4}>
                  <Text fontSize={3} fontWeight={700} textAlign="center">
                    Are you sure? Your medication history gives our physicians
                    vital information to treat you.
                  </Text>
                </InfoBox>
              </ModalInner>
            </ConfirmModal>

            <ConfirmModal
              isOpen={this.state.promptCancelledConsent}
              onClose={this.closeConfirmConsent}
              onBackgroundClick={this.closeConfirmConsent}
              okOnly
              confirmLabel="OK"
              onConfirm={this.closeConfirmConsent}
            >
              <ModalInner>
                <Heading mb={4} textAlign="center">
                  Request successful
                </Heading>

                <InfoBox bg="#D9E6F9" mb={4}>
                  <Text fontSize={3} fontWeight={700} textAlign="center">
                    We will no longer have access to your medication history.
                    Please allow a few days for the changes to take effect.
                  </Text>
                </InfoBox>
              </ModalInner>
            </ConfirmModal>
          </CardBox>
        )}
      </Mutation>
    );
  }
}

export default withSession(MedicationHistoryForm);
