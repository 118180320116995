import React from 'react';
import { Query } from 'react-apollo';
import { Box, Button, Card, Flex, Heading, Image, Text } from 'rebass';
import styled from 'styled-components';
import { Skeleton } from '../components/loadingSkeleton/Skeleton';
import PleaseWait from '../components/PleaseWait';
import { GET_SPEAKER_SERIES } from '../graphql';

const SpeakerCardWrapper = styled(Flex)`
  width: 100%;
  background: #dae5fb;
  height: 253px;
  overflow: hidden;
  @media screen and (max-width: 480px) {
    width: calc(100vw - 24px);
    max-width: 100vw;
    flex-direction: column;
    height: auto;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 10px;
  }
`;

const TextWrapper = styled(Flex)`
  width: 236px;
  height: 100%;
  @media screen and (max-width: 480px) {
    width: 100%;
    height: initial;
  }
`;

const Description = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  flex: 1;
  font-size: 14px;
  @media screen and (max-width: 480px) {
    margin-bottom: 12px;
  }
`;

const SpeakerCard = ({ serie }) => {
  return (
    <SpeakerCardWrapper mb={4}>
      <Flex flexDirection={'column'} flex={1}>
        <img src={serie.image.replace('.png','')+'-p-800.png'} height="100%" width="100%" style={{ objectFit: 'cover' }} />
      </Flex>
      <TextWrapper flexDirection={'column'} style={{ background: 'white', padding: '22px', overflow: 'hidden' }}>
        <Heading fontSize={'18px'}>{serie.name}</Heading>
        <Description>{serie.smallDescription}</Description>

        <Button variant={'primary'} alignSelf="center" onClick={() => window.open(serie.videoLink, '_blank')}>
          Play
        </Button>
      </TextWrapper>
    </SpeakerCardWrapper>
  );
};

const SpeakerCardLoading = () => {
  return (
    <SpeakerCardWrapper mb={4}>
      <Flex flexDirection={'column'} style={{ overflow: 'hidden', justifyContent: 'center', alignItems: 'center' }} flex={1}>
        <PleaseWait
          text={' '}
          spinner={{
            sColor: '#ECEFF2',
            pColor: '#909FB5',
          }}
        />
      </Flex>
      <Flex flexDirection={'column'} style={{ background: 'white', width: '236px', height: '100%', padding: '22px', overflow: 'hidden' }}>
        <Heading fontSize={'18px'} mb={3}>
          <Skeleton height="20px" width="180px" />
        </Heading>
        <Skeleton height="14px" width="200px" />
        <Skeleton height="14px" width="180px" />
        <Skeleton height="14px" width="100px" />
      </Flex>
    </SpeakerCardWrapper>
  );
};

const SpeakerSeriesInner = ({ data, loading }) => {
  return (
    <Flex flexDirection={'column'} style={{ maxWidth: '700px', width: '100%' }}>
      <Heading textAlign={'center'} mt={'40px'} mb={'24px'}>
        Speaker Series
      </Heading>
      {!!loading
        ? [...Array(12)].map((x, i) => {
            return <SpeakerCardLoading key={`loadingSpeakerSerie${i}`} />;
          })
        : data.getSpeakerSeries.map((x) => {
            return <SpeakerCard serie={x} key={`SpeakerSerie${x.id}`} />;
          })}
    </Flex>
  );
};

const SpeakerSeries = () => {
  return (
    <Query query={GET_SPEAKER_SERIES}>
      {({ data, loading }) => {
        return <SpeakerSeriesInner data={data} loading={loading} />;
      }}
    </Query>
  );
};

export default SpeakerSeries;
