import React, { useState } from 'react';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import { Query } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';
import { ADMIN_GET_WORKOUTS_AUTHORS } from '../../graphql';
import ConfirmModal from '../../components/ConfirmModal';
import AddAuthor from './modalContent/AddAuthor';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
  max-height: 800px;
  overflow: auto;
`;
const WorkoutAuthors = () => {
  const [isAdd, setIsAdd] = useState(null);
  const CATEGORIES_COLUMNS = [
    {
      Header: 'name',
      accessor: 'name',
    },
    {
      Header: 'bio',
      accessor: 'bio',
    },{
      Header: '',
      Cell: (row, rest) => {
        return (
          <Flex flexDirection="row">
            
            <div
              title="edit"
              style={{ cursor: 'pointer', marginLeft: '12px' }}
              onClick={() =>
                setIsAdd(row.original)
              }
            >
              ✎
            </div>
          </Flex>
        );
      },
    },
  ];
  return (
    <div>
      <Flex flexDirection="row" style={{ justifyContent: 'space-between' }} mt={4} mb={3}>
        <Heading fontSize="20px">Authors</Heading>
        <Button
          style={{ background: '#344f79' }}
          onClick={() => {
            setIsAdd({});
          }}
        >
          Add Author
        </Button>
      </Flex>
      <Query query={ADMIN_GET_WORKOUTS_AUTHORS}>
        {({ data, loading, error }) => {
          if (loading || error) return <div />;
          console.log('ADMIN_GET_WORKOUTS_DIFFICULTIES', data);
          return (
            <Box>
              <ReactTable
                data={data.getAdminWorkoutAuthors}
                columns={CATEGORIES_COLUMNS}
                defaultPageSize={data.getAdminWorkoutAuthors && data.getAdminWorkoutAuthors.length > 3 ? Math.min(data.getAdminWorkoutAuthors.length, 25) : 3}
                pageSize={data.getAdminWorkoutAuthors && data.getAdminWorkoutAuthors.length > 3 ? data.getAdminWorkoutAuthors.length : 3}
                showPagination={data.getAdminWorkoutAuthors && data.getAdminWorkoutAuthors.length > 24}
              />
            </Box>
          );
        }}
      </Query>

      <ConfirmModal
        childrenManaged
        isOpen={!!isAdd}
        confirmLabel="create"
        onClose={() => {
          setIsAdd(null);
        }}
        onBackgroundClick={() => {
          setIsAdd(null);
        }}
        onEscapeKeydown={() => {
          setIsAdd(null);
        }}
        onConfirm={() => {
          setIsAdd(null);
        }}
      >
        <ModalInner>
          <Heading fontSize="20px" mb={3}>
            {!!isAdd && !!isAdd.id ? 'Update Author' : 'Add Author'}
          </Heading>
          <AddAuthor
            author={isAdd}
            onClose={() => {
              setIsAdd(null);
            }}
            onSave={() => {
              setIsAdd(null);
            }}
          />
        </ModalInner>
      </ConfirmModal>
    </div>
  );
};

export default WorkoutAuthors;
