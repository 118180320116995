import React, { Fragment, useState } from 'react';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import { withApollo, compose } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';

import PharmacySearch from '../../../components/PharmacySearch';
import withSession from '../../../lib/withSession';
import { BoxButton } from '../../../components/async/SharedComponents';
import PleaseWait from '../../../components/PleaseWait';

const Pharmacy = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const {
    session: { pharmacy },
    continueIntake,
  } = props;
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
        <Box m={2}>
          <Heading textAlign="left">Your Preferred Pharmacy</Heading>
        </Box>
        <Box m={2} mt="0" mb={3}>
          {!!isSaving && <PleaseWait />}
          {!pharmacy &&
            !isSaving && (
              <Text textAlign="left" fontSize="16px" fontWeight="300" lineHeight="24px">
                Select your preferred pharmacy and any prescription you might get through Paloma Health will be sent there.
                <br />
                if you don't see your pharmacy listed here, please contact your Care Manager
              </Text>
            )}
          {pharmacy &&
            !isSaving && (
              <Fragment>
                <Text fontSize={3} fontWeight={500} mb={3}>
                  {pharmacy.name}
                </Text>
                <Text mb={2} textAlign="left" fontSize="16px" fontWeight="300" lineHeight="24px">
                  {pharmacy.formattedAddress}
                </Text>
                <Text>{pharmacy.phone}</Text>
                {/* {continueIntake && ( */}
                <BoxButton className="visible">
                  <Button
                    variant="primary"
                    width={[1, 1 / 2, 1 / 4]}
                    style={{
                      marginLeft: '12px',
                      marginRight: '12px',
                    }}
                    onClick={continueIntake}
                  >
                    Continue
                  </Button>
                </BoxButton>
              </Fragment>
            )}
        </Box>
        <PharmacySearch forceOrientation={'column'} forceWidth={1} onSelected={continueIntake} setIsSaving={setIsSaving} />
        {window._env_.REACT_APP_ASYNC_RESUME_SAME_SCREEN && window._env_.REACT_APP_ASYNC_RESUME_SAME_SCREEN === 'true' ? (
          <Button variant="secondary" onClick={continueIntake}>
            skip
          </Button>
        ) : null}
      </Flex>
    </Fragment>
  );
};

export default compose(
  withSession,
  withApollo,
  withWizard,
  withToastManager
)(Pharmacy);
