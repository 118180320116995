import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Flex, Text } from 'rebass';
import { sleep } from '../../../utils/sleep';
import { TextAreaNoForm } from '../../TextArea';
import { SelectButton } from '../components/Shared';
import { getDefaultShowDetails, getDefaultValueMultipleDetails, getDefaultValueSingle } from '../utils/extractBackResponse';

const PickOneOtherLayout = ({ options, onNext, nextTitle, defaultValue, onSetWaitingUserInput, isActive, id }) => {
  const [selected, setSelected] = useState(getDefaultValueSingle(defaultValue));
  const [detailsValue, setDetailsValue] = useState(getDefaultValueMultipleDetails(defaultValue));
  const [showDetails, setShowDetails] = useState(getDefaultShowDetails(defaultValue, options));

  const onSelection = async (selected) => {
    setSelected(selected);
    setDetailsValue(null);
    const sel = options.find((x) => x.value === selected);
    console.log({ sel, id });
    if ((options.find((x) => x.value === selected) || {}).hasDetails) {
      onSetWaitingUserInput(true);
      setShowDetails(true);
      return;
    }
    setShowDetails(false);
    onSetWaitingUserInput(false);

    await sleep(300);
    onNext({
      id,
      selection:[{
        value: selected,
      }],
    });
  };

  useEffect(
    () => {
      if (!isActive) return;
      if (!selected) {
        onSetWaitingUserInput(false);
        return;
      }

      if (!(options.find((x) => x.value === selected) || {}).hasDetails) {
        onSetWaitingUserInput(false);
        return;
      }

      if (!!detailsValue && detailsValue.length > 1) {
        onSetWaitingUserInput(false);
      } else {
        onSetWaitingUserInput(true);
      }
    },
    [detailsValue, selected, isActive]
  );

  return (
    <>
      <Flex flexDirection={isMobile ? 'column' : 'row'} flexWrap={'wrap'}>
        {options.map((x) => {
          return (
            <SelectButton
            key={`slide-${id}-opt${x.value}`}
              isSelected={selected === x.value}
              text={x.text}
              hasSelection={!!selected}
              onClick={() => {
                onSelection(x.value);
              }}
            />
          );
        })}
      </Flex>
      {!!showDetails && (
        <>
          <Text mt={2} mb={2}>
            Give us some details:
          </Text>
          <TextAreaNoForm
            multiline
            containerStyle={{ display: 'block', flex: 'initial' }}
            style={{
              fontSize: '18px',
              width: '100%',
              minHeight: '100px',
            }}
            placeholder="Enter details here"
            onChange={(r) => {
              setDetailsValue(r.target.value);
            }}
            value={detailsValue}
          />
          <Button
            style={{ alignSelf: 'flex-start' }}
            mt={4}
            disabled={!detailsValue || detailsValue.length < 2}
            variant={'primary'}
            onClick={() => {
              const selection = [];

              if (selected) {
                if (showDetails) {
                  selection.push({
                    value: selected,
                    details: detailsValue,
                  });
                } else {
                  selection.push({
                    value: selected,
                  });
                }
              }

              console.log({ selection, id });

              onNext({
                id,
                selection,
              });
            }}
          >
            {nextTitle || `Let's do it!`}
          </Button>
        </>
      )}
    </>
  );
};

export default PickOneOtherLayout;
