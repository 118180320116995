import React, { Fragment, useState } from 'react';
import { Box, Button, Card, Flex, Heading, Link, Text } from 'rebass';
import { compose, withApollo } from 'react-apollo';
import { withWizard } from 'react-albus';
import gql from 'graphql-tag';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import styled from 'styled-components';
import Mailcheck from 'react-mailcheck';

import { TextInput } from '../../components/TextInput';
import { FormField } from '../../components/FormField';
import { CheckboxInput } from '../../components/CheckboxInput';
import { MaskInput } from '../../components/MaskInput';
import Aside from './Aside';
import { withRouter } from 'react-router';

const EMAIL_AVAILABLE = gql`
  query EmailAvailable($email: String!) {
    emailAvailable(email: $email) {
      ok
      mobile
    }
  }
`;


const MailcheckWrapper = styled.div`
  text-align: right;
  @media screen and (max-width: 52em) {
    text-align: left;
  }
`;

const MailcheckButton = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  font-size: inherit;
  font-style: italic;
  color: rgb(237, 183, 168);
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;

  &:focus {
    outline: none;
    color: rgb(237, 183, 168);
  }

  &:hover {
    color: rgb(237, 183, 168);
  }

  &:active {
    color: rgb(237, 183, 168);
  }
`;

const StyledLink = styled(Link)`
  color: #000;
`;

const Basic = props => {
  const { isNewPatientFlow } = props;
  const [emailAdd, setEmailAdd] = useState(null)
  const minDob = moment()
    .subtract(18, 'years')
    .toDate();

  const maxDob = moment()
    .subtract(125, 'years')
    .toDate();

  const submitCheck = async (values, actions) => {
    try {
      const { data } = await props.client.query({
        query: EMAIL_AVAILABLE,
        variables: {
          email: values.email,
        },
      });

      actions.setSubmitting(false);
      if (!data.emailAvailable.ok) {
        if(!!data.emailAvailable.mobile){
          props.history.push('/account-exists')
          return
        }
        throw Error('Email already in use');
      } else {
        props.onSubmit(values, actions, props.wizard);
      }
    } catch (error) {
      actions.setSubmitting(false);
      actions.setFieldError('email', error.message);
    }
  };
  
  let schema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    password: Yup.string()
      .trim()
      .min(8, 'Password must be greater than 8 characters')
      .required('Required'),
    dob: Yup.date()
      .transform(value => {
        return moment(value).toDate();
      })
      .min(maxDob, 'Must be younger than 125 years')
      .max(minDob, 'Must be 18 years or older')
      .required('Required'),
    consent: Yup.bool()
      .test(
        'consent',
        'You must agree to the Terms and Conditions',
        val => val === true
      )
      .required('You must agree to the Terms and Conditions'),
    consenthipaa: Yup.bool()
      .test(
        'consenthipaa',
        'You must agree to the HIPAA Terms',
        val => val === true
      )
      .required('You must agree to the HIPAA Terms'),
  });

  if(emailAdd && emailAdd.indexOf('+admin@palomahealth.com')>-1){
    schema = Yup.object().shape({
      email: Yup.string()
        .email('Invalid email')
        .required('Required'),
      password: Yup.string()
        .trim()
        .required('Required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.])(?=.{8,24})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      dob: Yup.date()
        .transform(value => {
          return moment(value).toDate();
        })
        .min(maxDob, 'Must be younger than 125 years')
        .max(minDob, 'Must be 18 years or older')
        .required('Required'),
      consent: Yup.bool()
        .test(
          'consent',
          'You must agree to the Terms and Conditions',
          val => val === true
        )
        .required('You must agree to the Terms and Conditions'),
      consenthipaa: Yup.bool()
        .test(
          'consenthipaa',
          'You must agree to the HIPAA Terms',
          val => val === true
        )
        .required('You must agree to the HIPAA Terms'),
    });
  }

  return (
    <Fragment>
      <Flex mb={4} flex={1} flexDirection={['column', 'column', 'row']}>
        <Flex flex={1} mr={[0, 0, 4]} mb={[4, 4, 0]}>
          <Card p={4}>
            <Flex flexDirection="column">
              <Box mb={4}>
                <Heading textAlign="center" mb={3}>
                  {isNewPatientFlow ? 'Create Your Account' : 'Get Started'}
                </Heading>
                {isNewPatientFlow ? (
                  <Text textAlign="center">
                    We are holding your spot. Create an account to confirm it
                  </Text>
                ) : (
                  <Text textAlign="center">Creating an account is free!</Text>
                )}
              </Box>
              <Formik
                isInitialValid={({ initialValues }) =>
                  schema.isValidSync(initialValues)
                }
                initialValues={props.values}
                validationSchema={schema}
                onSubmit={(values, actions) => submitCheck(values, actions)}
                render={({
                  isValid,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                }) => {
                  const dobError =
                    errors.dob &&
                    errors.dob !== 'Invalid Date' &&
                    errors.dob.indexOf('dob') !== 0
                      ? errors.dob
                      : null;

                  return (
                    <Form>
                      <Box>
                        <FormField mb={3} error={touched.email && errors.email}>
                          <Mailcheck email={values.email}>
                            {suggestion => (
                              <>
                                <TextInput
                                  placeholder="Your Email Address"
                                  type="email"
                                  name="email"
                                  id="input_email"
                                  onChange={(e)=>{
                                    handleChange(e)
setEmailAdd(e.target.value)
                                  }}
                                />
                                {suggestion && (
                                  <MailcheckWrapper className="mailcheck">
                                    Did you mean{' '}
                                    <MailcheckButton
                                      type="button"
                                      onClick={() =>
                                        setFieldValue('email', suggestion.full)
                                      }
                                    >
                                      {suggestion.full}
                                    </MailcheckButton>
                                    ?
                                  </MailcheckWrapper>
                                )}
                              </>
                            )}
                          </Mailcheck>
                        </FormField>

                        <FormField
                          mb={3}
                          error={touched.password && errors.password}
                        >
                          <TextInput
                            placeholder="Your Password"
                            type="password"
                            name="password"
                            id="input_password"
                          />
                        </FormField>

                        <FormField mb={4} error={touched.dob && dobError}>
                          <MaskInput
                            name="dob"
                            placeholder="Your Date of Birth (MM/DD/YYYY)"
                            mask="99/99/9999"
                            value={values.dob}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="input_dob"
                          />
                        </FormField>

                        <FormField
                          flexDirection="row"
                          mb={4}
                          error={touched.consent && errors.consent}
                        >
                          <CheckboxInput name="consent" />
                          <Text fontSize={1}>
                            I agree to Paloma Health's&nbsp;
                            <StyledLink
                              href="https://www.palomahealth.com/legal-doc/paloma-health-terms-of-use"
                              target="_blank"
                            >
                              Terms of Service
                            </StyledLink>{' '}
                            and{' '}
                            <StyledLink
                              href="https://www.palomahealth.com/legal-doc/consent-to-telehealth"
                              target="_blank"
                            >
                              Informed Consent
                            </StyledLink>
                          </Text>
                        </FormField>

                        <FormField
                          flexDirection="row"
                          mb={4}
                          error={touched.consenthipaa && errors.consenthipaa}
                        >
                          <CheckboxInput name="consenthipaa" />
                          <Text fontSize={1}>
                            I have read and agree to the terms of Paloma
                            Health's&nbsp;
                            <StyledLink
                              href="https://www.palomahealth.com/legal-doc/notice-of-privacy-practices"
                              target="_blank"
                            >
                              HIPAA Notice
                            </StyledLink>
                          </Text>
                        </FormField>
                      </Box>

                      <Flex justifyContent="center">
                        <Button
                          variant="primary"
                          width={[1 / 2, 1 / 3]}
                          type="submit"
                          disabled={!isValid}
                          id="btn_next"
                        >
                          Next
                        </Button>
                      </Flex>
                    </Form>
                  );
                }}
              />
            </Flex>
          </Card>
        </Flex>
        <Aside />
      </Flex>
    </Fragment>
  );
};

export default compose(withApollo, withWizard, withRouter)(Basic);
