import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Box, Button, Card, Flex, Text } from 'rebass';
import styled from 'styled-components';
import Modal from 'styled-react-modal';
import { SpecialModalBackground } from '../adminReorderPhysicianConvo/shared';
import AddNewHealthCoachUser from './AddNewHealthCoachUser';

const CardBox = styled(Card)`
  width: 100%;
  padding: 32px;
`;

const CalendlyUsersList = ({ data, healthCoaches }) => {
  const [selectedCalendlyUser, setSelectedCalendlyUser] = useState(null);
  const [calendlyUsers, setCalendlyUsers] = useState(
    ((data || {}).adminGetCalendlyUserByEmail || []).filter(
      (x) => ((healthCoaches || {}).adminGetHealthCoachUsersList || []).findIndex((xx) => xx.calendlyUserId === x.uri.replace('https://api.calendly.com/users/', '')) < 0
    )
  );

  useEffect(
    () => {
      const notFound = ((data || {}).adminGetCalendlyUserByEmail || []).filter(
        (x) => ((healthCoaches || {}).adminGetHealthCoachUsersList || []).findIndex((xx) => xx.calendlyUserId === x.uri.replace('https://api.calendly.com/users/', '')) < 0
      );
      setCalendlyUsers(notFound);
    },
    [data, healthCoaches]
  );

  if (!calendlyUsers || calendlyUsers.length < 1) {
    return <Text>All Calendly users have a Health Coach account</Text>;
  }
  return (
    <Flex flexDirection={'column'} mb={5}>
      {calendlyUsers && (
        <>
          {calendlyUsers.map((x) => (
            <CardBox mt={3} style={{ position: 'relative' }}>
              <Flex>
                <img src={x.avatarUrl} width={'48px'} height={'48px'} />
                <Flex flexDirection={'column'} ml={3} mr={3} flex={1}>
                  <Text mb={3}>{x.name}</Text>
                  <Text fontSize={'12px'} style={{ opacity: 0.7 }}>
                    Calendly Id: {x.uri.replace('https://api.calendly.com/users/', '')}
                  </Text>
                  <Text fontSize={'12px'} style={{ opacity: 0.7 }}>
                    Created At: {x.createdAt}
                  </Text>
                </Flex>
                {x.role && (
                  <Box
                    style={{
                      position: 'absolute',
                      top: 4,
                      right: 4,
                      borderRadius: '8px',
                      background: x.role === 'admin' ? '#d8ebd8' : x.role === 'owner' ? '#fdd0d0' : '#f0f0f0',
                      padding: '4px 8px',
                    }}
                  >
                    <Text>{x.role}</Text>
                  </Box>
                )}
                <Button variant={'primary'} style={{ alignSelf: 'center' }} onClick={() => setSelectedCalendlyUser(x)}>
                  Create Health Coach
                </Button>
              </Flex>
            </CardBox>
          ))}
        </>
      )}
      {!!selectedCalendlyUser && (
        <Modal
          isOpen={true}
          onBackgroundClick={() => {
            setSelectedCalendlyUser(null);
          }}
          onEscapeKeydown={() => {
            setSelectedCalendlyUser(null);
          }}
        >
          <SpecialModalBackground>
            <Box
              style={{
                margin: '0 auto',
                alignSelf: 'center',
                borderRadius: '20px',
                position: 'relative',
                backgroundColor: 'white',
                padding: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                maxWidth: '95vw',
                minWidth: 'initial',
                maxHeight: '95vh',
                overflow: 'hidden'
              }}
            >
              <AddNewHealthCoachUser selectedCalendlyUser={selectedCalendlyUser} setSelectedCalendlyUser={setSelectedCalendlyUser} />
            </Box>
          </SpecialModalBackground>
        </Modal>
      )}
    </Flex>
  );
};

export default CalendlyUsersList;
