import React from 'react';

import { Box, Button, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import visaIcon from '@iconify/icons-logos/visa';
import mcIcon from '@iconify/icons-logos/mastercard';
import amexIcon from '@iconify/icons-logos/amex';
import discoverIcon from '@iconify/icons-logos/discover';
import creditCard from '@iconify/icons-entypo/credit-card';
import withSession from '../../../../../lib/withSession';

const SelectedMarkerOut = styled(Flex)`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 2px solid #354e79;
  border-radius: 12px;
  margin-right: 12px;
`;
const SelectedMarkerIn = styled(Flex)`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #354e79;
  transition: all 450ms ease-in;
`;

const UseCurrentCard = ({ session: { card }, addCard }) => {
  const last4 = (card || {}).last4;
  const cardBrand = (card || {}).brand;
  let logo = creditCard;
  switch ((cardBrand || '').toUpperCase()) {
    case 'VISA':
      logo = visaIcon;
      break;
    case 'MC':
    case 'MASTERCARD':
    case 'MASTER CARD':
      logo = mcIcon;
      break;
    case 'AX':
    case 'AMEX':
    case 'AMERICANEXPRESS':
    case 'AMERICAN EXPRESS':
      logo = amexIcon;
      break;
    case 'DISCO':
    case 'DISCOVER':
      logo = discoverIcon;
      break;

    default:
      logo = creditCard;
      break;
  }

  return (
    <Flex flexDirection="row" flex={1} alignItems="center">
      <Box
        p={2}
        mr={2}
        style={{
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
          display: 'flex',
        }}
      >
        <Icon icon={logo} />
      </Box>

      <Flex flexDirection="column" flex={1}>
        <Text fontWeight="600" fontSize="14px">{`•••• ${last4}`}</Text>
        <Text fontWeight="300" fontSize="14px">{`Expires ${card.expMonth}/${
          card.expYear
        }`}</Text>
      </Flex>

      <SelectedMarkerOut>
        <SelectedMarkerIn />
      </SelectedMarkerOut>
    </Flex>
  );
};

export default withSession(UseCurrentCard);
