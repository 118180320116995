import React, { Fragment, useState } from 'react';
import { Flex, Box, Button, Heading, Text, Card } from 'rebass';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import { BoxButton } from '../../../components/async/SharedComponents';
import FingerPrick from '../../../static/FingerPrick.png'


const SelectedMarkerOut = styled(Flex)`
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #354e79;
  transition: background 150ms ease-in-out;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  &.isChecked {
    background: #354e79;
  }
`;

const options = [
  {
    label: 'Get a 30 day prescription',
    id: '30day',
    isFlowMarker: true,
  },
  {
    label: 'Buy a thyroid test kit',
    id: 'kits',
    isUpsellValue: true,
  },
];

const UpdateLevelsWithKit = ({ data, onSubmit, onLearnMore }) => {
  const [elements, setElements] = useState(options);
  const handleChosen = element => {
    const chosen =
      element.chosen === 'Y' ? (element.chosen = 'N') : (element.chosen = 'Y');
    // console.log({ element });
    const temps = [];
    for (let index = 0; index < elements.length; index++) {
      const el = elements[index];
      if (el.id === element.id) {
        el.chosen = chosen;
      }
      temps.push(el);
    }
    setElements(temps);
  };

  return (
    <Fragment>
      <Flex flex={1} id="ulwk" flexDirection="column" style={{ zIndex: 1 }} pb="60px">
        <img src={FingerPrick} width="112px" style={{margin:"12px auto"}} alt='' />
        <Box m={2} mt={4} mb={3}>
          <Heading textAlign="left">
            Update your levels with a thyroid test kit
          </Heading>
        </Box>
        <Box m={2} mt="0">
          <Text
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            You will need to get lab work done to get a 90-day supply of thyroid hormone medication. We can give you a
            30-day supply in the meantime.
          </Text>
          <Text
            mt={3}
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            You can use Paloma’s{' '}
            <span
              onClick={() => {
                onLearnMore('athomekit');
              }}
              style={{
                color: '#F6A293',
                fontWeight: 600,
                textDecoration: 'underline',
              }}
            >
              at home thyroid test kit
            </span>{' '}
            to update your thyroid levels and be eligibile for a 90 day
            prescription
          </Text>
          <Text
            mt={3}
            textAlign="left"
            fontSize="12px"
            fontWeight="300"
            lineHeight="24px"
            onClick={() => onLearnMore('athomekit')}
            style={{
              textTransform: 'uppercase',
              textDecoration: 'underline',
              textAlign: 'right',
            }}
          >
            learn more
          </Text>
        </Box>
        <Text
          mt={4}
          textAlign="left"
          fontSize="16px"
          fontWeight="600"
          lineHeight="27px"
        >
          How do you want to proceed?
        </Text>

        <Formik
          initialValues={{}}
          validationSchema={data.schema || ''}
          onSubmit={(values, actions) => {
            // console.log('AAA', { payload });
            onSubmit(options);
          }}
          render={({
            values,
            isValid,
            errors,
            touched,
            submitForm,
            setFieldValue,
          }) => {
            // console.log({ values });
            return (
              <Form>
                <Flex flex={1} flexDirection="column" mt={3}>
                  <Flex
                    mt={3}
                    mb={4}
                    flexWrap="wrap"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    {elements.map(el => (
                      <Card
                        p="12px"
                        mb={3}
                        onClick={() => handleChosen(el)}
                        key={el.id}
                        style={{
                          border: `1px solid ${
                            el.chosen === 'Y' ? '#354E79' : 'white'
                          }`,
                          backgroundColor:
                            el.chosen === 'Y' ? '#E7F0FF' : 'white',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Flex flexDirection="row">
                          <SelectedMarkerOut
                            className={el.chosen === 'Y' ? 'isChecked' : ''}
                          >
                            {el.chosen === 'Y' && (
                              <svg
                                width="42px"
                                height="42px"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.0866 6.84591C14.2627 6.67827 14.497 6.58559 14.7401 6.58743C14.9832 6.58927 15.2161 6.68548 15.3897 6.85577C15.5632 7.02605 15.6638 7.25709 15.6702 7.50012C15.6766 7.74316 15.5884 7.97919 15.4241 8.15841L10.4366 14.3959C10.3508 14.4883 10.2473 14.5624 10.1323 14.6139C10.0172 14.6653 9.89294 14.693 9.76691 14.6954C9.64089 14.6977 9.51569 14.6746 9.39879 14.6274C9.2819 14.5803 9.17572 14.51 9.0866 14.4209L5.7791 11.1134C5.68699 11.0276 5.61311 10.9241 5.56187 10.8091C5.51063 10.6941 5.48308 10.5699 5.48086 10.4441C5.47864 10.3182 5.50179 10.1931 5.54895 10.0764C5.5961 9.95967 5.66628 9.85363 5.7553 9.76461C5.84432 9.67558 5.95037 9.6054 6.0671 9.55825C6.18384 9.5111 6.30887 9.48795 6.43475 9.49017C6.56063 9.49239 6.68477 9.51994 6.79977 9.57118C6.91477 9.62242 7.01827 9.6963 7.1041 9.78841L9.7216 12.4047L14.0628 6.87341C14.0707 6.86378 14.079 6.8546 14.0878 6.84591H14.0866Z"
                                  fill="white"
                                />
                              </svg>
                            )}
                          </SelectedMarkerOut>

                          <Flex flexDirection="column" flex={1}>
                            <Text fontSize="14px">{el.label}</Text>
                          </Flex>
                        </Flex>
                      </Card>
                    ))}
                  </Flex>

                  <BoxButton
                    className={
                      elements.filter(x => x.chosen === 'Y').length > 0
                        ? 'visible'
                        : ''
                    }
                  >
                    <Button
                      variant="primary"
                      style={{
                        marginLeft: '12px',
                        marginRight: '12px',
                      }}
                      width={[1, 1 / 2, 1 / 4]}
                    >
                      Continue
                    </Button>
                  </BoxButton>
                </Flex>
              </Form>
            );
          }}
        />
      </Flex>
    </Fragment>
  );
};

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(UpdateLevelsWithKit);
// export default withWizard(Single);
