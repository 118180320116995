import React, { useState } from 'react';
import { Button, Text, Flex, Card } from 'rebass';
import styled from 'styled-components';
import { BoxButton } from '../../../components/async/SharedComponents';
import Imge from '../../../static/your_lab.png';

const BlueHeading = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #354e79;
`;

const SelectedMarkerOut = styled(Flex)`
  background: #ffffff;
  border: 2px solid #354e79;
  transition: background 150ms ease-in-out;
  width: 16px;
  height: 16px;
  margin-right: 20px;
  margin-top: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  &.isChecked {
    background: #354e79;
  }
`;

const DisclaimerEducationalVisit = ({ onSubmit }) => {
  const [isSelected, setIsSelected] = useState(false);
  return (
    <Flex flex={1} flexDirection="column" pb="60px">
      <Flex flex={1} flexDirection="column" p="16px">
        <BlueHeading mb={3} mt={3}>
          Schedule an Educational Visit
        </BlueHeading>

        <Text mb={3}>Your recent labs indicate that you may not fall within qualifying range for thyroid medication.</Text>
        <Text mb={3}>
          We can offer you a one-time educational visit without having to join the Paloma Membership. A Paloma provider will review your labs and symptoms and provide you with education regarding next
          steps.
        </Text>
        <Text mb={3}>The visit will be billed to insurance if applicable otherwise it's $110 cash pay</Text>
        <Text mb={4}>Please acknowledge the following:</Text>
        <Card
          onClick={() => setIsSelected(true)}
          style={{
            border: `1px solid rgba(53, 78, 121, ${isSelected ? '1' : '0'})`,
            borderRadius: '10px',
            padding: '20px',
            background: isSelected ? '#DAE6FA' : 'white',
            flex: 'initial',
            cursor: 'pointer'
          }}
        >
          <Flex flexDirection="row">
            <Flex>
              <SelectedMarkerOut mt="3px" className={isSelected ? 'isChecked' : ''}>
                {isSelected && (
                  <svg width="42px" height="42px" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.0866 6.84591C14.2627 6.67827 14.497 6.58559 14.7401 6.58743C14.9832 6.58927 15.2161 6.68548 15.3897 6.85577C15.5632 7.02605 15.6638 7.25709 15.6702 7.50012C15.6766 7.74316 15.5884 7.97919 15.4241 8.15841L10.4366 14.3959C10.3508 14.4883 10.2473 14.5624 10.1323 14.6139C10.0172 14.6653 9.89294 14.693 9.76691 14.6954C9.64089 14.6977 9.51569 14.6746 9.39879 14.6274C9.2819 14.5803 9.17572 14.51 9.0866 14.4209L5.7791 11.1134C5.68699 11.0276 5.61311 10.9241 5.56187 10.8091C5.51063 10.6941 5.48308 10.5699 5.48086 10.4441C5.47864 10.3182 5.50179 10.1931 5.54895 10.0764C5.5961 9.95967 5.66628 9.85363 5.7553 9.76461C5.84432 9.67558 5.95037 9.6054 6.0671 9.55825C6.18384 9.5111 6.30887 9.48795 6.43475 9.49017C6.56063 9.49239 6.68477 9.51994 6.79977 9.57118C6.91477 9.62242 7.01827 9.6963 7.1041 9.78841L9.7216 12.4047L14.0628 6.87341C14.0707 6.86378 14.079 6.8546 14.0878 6.84591H14.0866Z"
                      fill="white"
                    />
                  </svg>
                )}
              </SelectedMarkerOut>
            </Flex>

            <Flex flexDirection="column" flex={1}>
              <Flex flexDirection="row">
                <Flex flexDirection="column" flex={1}>
                  <Text lineHeight="20px" mb="10px">
                    I understand that this educational visit may not result in a prescription, and the purpose is to review and discuss my lab results and receive guidance
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>
        <BoxButton className="visible">
          <Button
            variant="primary"
            onClick={onSubmit}
            disabled={!isSelected}
            style={{
              marginLeft: '12px',
              marginRight: '12px',
            }}
            width={[1, 1 / 2, 1 / 4]}
          >
            Continue
          </Button>
        </BoxButton>
      </Flex>
    </Flex>
  );
};

export default DisclaimerEducationalVisit;
