import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { BaseModalBackground } from 'styled-react-modal';

export const CONVO_TREE = gql`
  query adminGetUserPhysicianConversationsTree($userId: String!) {
    adminGetUserPhysicianConversationsTree(userId: $userId) {
      id
      details {
        preview
        lastUserUpdate
        status
        primaryCustomerId
      }
      parentConvoId
    }
  }
`;
export const ALL_CONVO_TREE = gql`
  query adminGetPhysicianConversationByAthenaId($userId: String!) {
    adminGetPhysicianConversationByAthenaId(userId: $userId) {
      id
      details {
        preview
        lastUserUpdate
        status
        primaryCustomerId
      }
      parentConvoId
    }
  }
`;
export const FIND_ONE_CONVO = gql`
  query adminGetPhysicianConversationByConvoId($conversationId: String!) {
    adminGetPhysicianConversationByConvoId(conversationId: $conversationId) {
      id
      details {
        preview
        lastUserUpdate
        status
        primaryCustomerId
      }
      parentConvoId
    }
  }
`;

export const SAVE_TREE = gql`
  mutation adminSavePhysicianConvoTree($userId: String!, $tree: [String]) {
    adminSavePhysicianConvoTree(userId: $userId, tree: $tree) {
      ok
    }
  }
`;

export const INITIATE_CONVERSATION = gql`
  mutation adminInitiatePerpetualConversation($userId: String!) {
    adminInitiatePerpetualConversation(userId: $userId) {
      ok
    }
  }
`;

export const SpecialModalBackground = styled(BaseModalBackground)`
  backdrop-filter: blur(5px);
  z-index: 3500;
`;

export const PREVIEW_LIMIT = 100;

export const getWarnings = (item, helpscoutId, tree) => {
  const res = [];

  if (item.details && item.details.primaryCustomerId !== (helpscoutId || '').toString()) {
    res.push("Conversation primary customer ID different than patient's helpscout ID.");
  }
  if (!!tree && tree.length > 1) {
    const ind = tree.findIndex((x) => x.id === item.id);
    if (ind > 0) {
      if (item.id > tree[ind - 1].id) {
        res.push('Conversation ID is greater than previous one. Are you sure?');
      }
    }
  }
  return res;
};
