import React from 'react';
import Steps, { Step } from 'rc-steps';
import { withRouter } from 'react-router';

const CustomStep = ({ status, current, onClick, currentStep, canJumpBack, ...props }) => {
  const type = current >= currentStep ? 'process' : 'wait';
  return (
    <Step
      style={{
        cursor: canJumpBack ? 'pointer' : 'not-allowed',
      }}
      onClick={() => {
        if (current >= currentStep && onClick && canJumpBack) {
          onClick();
        }
      }}
      status={type}
      currentStep={currentStep}
      {...props}
    />
  );
};

const ScheduleStepper = (props) => {
  const { currentStep, history } = props;
  console.log({ history });

  let curr = 0;
  console.log({ currentStep });
  if (
    history.location.pathname.indexOf('/schedule/pickProvider') > -1 ||
    history.location.pathname.indexOf('/schedule/insurance') > -1 ||
    history.location.pathname.indexOf('/schedule/insuranceBenefitsCheck') > -1
  ) {
    curr = 0;
  } else if (history.location.pathname.indexOf('/schedule/pickDoctor') > -1) {
    curr = 1;
  } else if (
    history.location.pathname.indexOf('/schedule/payment') > -1 ||
    history.location.pathname.indexOf('/schedule/selectMemberships') > -1 ||
    history.location.pathname.indexOf('/schedule/selectMembership') > -1
  ) {
    curr = 2;
  } else if (
    history.location.pathname.indexOf('/schedule/complete') > -1 ||
    history.location.pathname.indexOf('/schedule/basic') > -1 ||
    history.location.pathname.indexOf('/schedule/extended') > -1 ||
    history.location.pathname.indexOf('/schedule/uploadInsuranceCard') > -1 ||
    history.location.pathname.indexOf('/schedule/profile') > -1
  ) {
    curr = 3;
  }
  return (
    <Steps currentStep={curr} labelPlacement="vertical" style={{ paddingLeft: '24px' }}>
      <CustomStep onClick={() => {}} canJumpBack={false} current={curr} currentStep={0} title="Insurance" />
      <CustomStep onClick={() => {}} canJumpBack={false} current={curr} currentStep={1} title="Pick Clinician" />
      <CustomStep current={curr} currentStep={2} canJumpBack={false} title="Pay" />
      <CustomStep current={curr} currentStep={3} canJumpBack={false} title="Confirmation" />
    </Steps>
  );
};

export default withRouter(ScheduleStepper);
