import React, { Fragment, Component } from 'react';
import { Flex, Text, Button } from 'rebass';
import styled from 'styled-components';
import { Route, NavLink as NavLinkRouter, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { Query, compose } from 'react-apollo';

import SpinLoader from '../SpinLoader';
import { Stethoscope } from '../icons';
import ScheduleNewNutritionist from './ScheduleNewNutritionist';
import { APPOINTMENTS_NUTRITIONIST } from '../../graphql';
import withSession from '../../lib/withSession';
import ConsultationNutritionistDetails from './ConsultationNutritionistDetails';
import withAnalytics from '../../lib/withAnalytics';

const ListContainer = styled(Flex)`
  min-width: 300px;
  border-right: 1px solid #ccc;
  width: 100%;
  overflow-y: auto;
  &.selected {
    display: none;
  }

  @media screen and (min-width: 52em) {
    width: auto;
    &.selected {
      display: flex;
    }
  }
`;

const Section = styled.div`
  color: #000;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  transition: all 300ms ease;
  &.active {
    background: rgba(180, 205, 244, 0.5);
  }
`;

const DetailContainer = styled(Flex)`
  display: none;
  overflow-y: auto;
  &.selected {
    display: flex;
  }

  @media screen and (min-width: 52em) {
    display: flex;
  }
`;

const Inner = styled(Flex)`
  max-width: 650px;
`;

const NavLink = styled(NavLinkRouter)`
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100px;
  transition: all 300ms ease;
  &.active {
    background: rgba(180, 205, 244, 0.5);
  }
  &:hover {
    background: rgba(180, 205, 244, 0.5);
  }
`;

const ListItem = styled(Flex)`
  border-bottom: 1px solid #ccc;
`;

const Avatar = styled(Flex)`
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

class ConsultationsNutritionist extends Component {
  state = {
    searchPatientId: '',
    prompt: false,
  };

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;

    document.body.appendChild(script);
  }

  componentWillUnmount() {
    var tags = document.getElementsByTagName('script');
    try {
      window.removeEventListener('message', this.windowMessageReceived);
    } catch (error) {}
    for (var i = tags.length; i >= 0; i--) {
      if (tags[i] && tags[i].getAttribute('src') != null && tags[i].getAttribute('src').indexOf('https://assets.calendly.com/assets/external/widget.js') > -1) tags[i].parentNode.removeChild(tags[i]); //remove element by calling parentNode.removeChild()
    }
  }

  extractTzFromAppt = (appointment) => {
    const splt = appointment.inviteLocation.split(' ');
    return splt.find((x) => x.startsWith('America'));
  };

  render() {
    const {
      session: { email, firstName, lastName, b2b },
    } = this.props;

    const name = encodeURI(`${firstName} ${lastName}`);
    const selected = !this.props.match.isExact ? 'selected' : null;

    return (
      <Fragment>
        <Query query={APPOINTMENTS_NUTRITIONIST}>
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <Flex flex={1} justifyContent="center" alignItems="center">
                  <SpinLoader />
                </Flex>
              );
            }

            if (error) {
              return (
                <Flex flex={1} justifyContent="center" alignItems="center">
                  <Text fontSize={5} fontWeight={600} textAlign="center">
                    Unable to retrieve visits. Please try again.
                  </Text>
                </Flex>
              );
            }

            const { appointmentsNutritionist } = data;

            if (appointmentsNutritionist.length === 0) {
              return <ScheduleNewNutritionist {...this.props} />;
            }

            return (
              <Flex style={{ overflowY: 'hidden' }} flex={1}>
                <ListContainer className={selected} flexDirection="column">
                  <Button
                    variant="primary"
                    style={{ margin: '12px' }}
                    onClick={() => {
                      window.Calendly.initPopupWidget({
                        url:
                          !b2b || !b2b.nutritionist
                            ? `https://calendly.com/paloma-video-visit/paloma-health-consultation?hide_event_type_details=1&name=${name}&email=${email}`
                            : `https://calendly.com/paloma-video-visit/paloma-health-consultation-fringe?hide_event_type_details=1&name=${name}&email=${email}`,
                      });

                      this.props.analytics.track('Book nutritionist consultation');
                    }}
                  >
                    Book a Visit
                  </Button>
                  <Section>
                    <Text fontSize="18px" fontWeight="500" ml="20px" color="#344f79">
                      Upcoming Appointments
                    </Text>
                  </Section>
                  {appointmentsNutritionist.filter((appointment) => appointment.status === 'f' || appointment.status === '2').length > 0 ? (
                    <>
                      {appointmentsNutritionist.filter((appointment) => appointment.status === 'f' || appointment.status === '2').map((appointment) => (
                        <ListItem key={appointment.id}>
                          <NavLink to={`/consultations-nutritionist/${appointment.id}`}>
                            <Flex p={3} alignItems="center">
                              <Flex mr={3}>
                                <Avatar>
                                  {appointment && appointment.provider && appointment.provider.picture ? (
                                    <img src={appointment.provider.picture} alt="" height="60px" width="60px" />
                                  ) : (
                                    <Stethoscope height={45} width={45} />
                                  )}
                                </Avatar>
                              </Flex>
                              <Flex flexDirection="column">
                                <Text mb={1} fontSize={'16px'} color="#344f79">
                                  {appointment.provider.displayName}
                                </Text>
                                <Text fontSize={1} color="grey">
                                  {`${moment(appointment.date, 'MM/DD/YYYY').format('ddd MMM Do')} ${moment(appointment.startTime, 'HH:mm').format('h:mma')}`}
                                </Text>
                              </Flex>
                            </Flex>
                          </NavLink>
                        </ListItem>
                      ))}
                    </>
                  ) : (
                    <Text mt={1} mb={1} fontSize="18px" fontWeight="400" color="#8e8e8e" ml="20px">
                      no upcoming appointment
                    </Text>
                  )}
                  <Section>
                    <Text fontSize="18px" fontWeight="500" ml="20px" color="#344f79">
                      Past Appointments
                    </Text>
                  </Section>
                  {appointmentsNutritionist.filter((appointment) => appointment.status !== 'f' && appointment.status !== '2').length > 0 ? (
                    <>
                      {appointmentsNutritionist.filter((appointment) => appointment.status !== 'f' && appointment.status !== '2').map((appointment) => (
                        <ListItem key={appointment.id}>
                          <NavLink to={`/consultations-nutritionist/${appointment.id}`}>
                            <Flex p={3} alignItems="center">
                              <Flex mr={3}>
                                <Avatar>
                                  {appointment && appointment.provider && appointment.provider.picture ? (
                                    <img src={appointment.provider.picture} alt="" height="60px" width="60px" />
                                  ) : (
                                    <Stethoscope height={45} width={45} />
                                  )}
                                </Avatar>
                              </Flex>
                              <Flex flexDirection="column">
                                <Text mb={1} fontSize={'16px'} color="#344f79">
                                  {appointment.provider.displayName}
                                </Text>
                                <Text fontSize={1} color="grey">
                                  {`${moment(appointment.date, 'MM/DD/YYYY').format('ddd MMM Do')} ${moment(appointment.startTime, 'HH:mm').format('h:mma')}`}
                                </Text>
                              </Flex>
                            </Flex>
                          </NavLink>
                        </ListItem>
                      ))}
                    </>
                  ) : (
                    <Text mt={1} mb={1} fontSize="18px" fontWeight="400" color="#8e8e8e" ml="20px">
                      no past appointment
                    </Text>
                  )}
                </ListContainer>
                <DetailContainer flex={1} justifyContent="center" className={selected}>
                  <Inner flex={1} flexDirection="column" pt={[0, 0, 3]}>
                    <Route path="/consultations-nutritionist/:id" component={(props) => <ConsultationNutritionistDetails appointments={appointmentsNutritionist} {...props} />} />
                  </Inner>
                </DetailContainer>
              </Flex>
            );
          }}
        </Query>
      </Fragment>
    );
  }
}

export default compose(
  withSession,
  withRouter,
  withAnalytics
)(ConsultationsNutritionist);
