import React from 'react';
import { Text } from 'rebass';

export const LabelCtrl = ({ properties }) => {
  return (
    <>
      <Text fontWeight={500}>{`${(properties || {}).question || 'Section'}`}</Text>
    </>
  );
};
