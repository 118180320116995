import React from 'react'
import { use100vh } from 'react-div-100vh'
import { Box, Flex } from 'rebass'

export const MobileAppProviderMessaging = () => {
  const height = use100vh()
  return (
    <Flex style={{height: height, backgroundColor: 'orange'}} flexDirection='column'>
<Box style={{flex:1}}></Box>
<Box>
  <input placeholder='test' />
</Box>
    </Flex>
  )
}