import React from 'react';
import { Flex, Text, Heading, Button } from 'rebass';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import SpinLoader from '../../components/SpinLoader';
import { GET_MY_WAITLIST } from '../../graphql';

const REMOVE_FROM_WAITLIST = gql`
  mutation removeFromWaitlist($waitlistId: String!) {
    removeFromWaitlist(waitlistId: $waitlistId) {
      ok
    }
  }
`;

const ModalInner = styled(Flex)`
  max-width: 800px;
  width: 100%;
  flex-direction: column;
`;

const RemovingFromWaitlistModalInner = ({ waitlistId, onClose }) => {
  return (
    <ModalInner>
      <Mutation
        mutation={REMOVE_FROM_WAITLIST}
        refetchQueries={[{ query: GET_MY_WAITLIST }]}
        awaitRefetchQueries={true}
        variables={{
          waitlistId,
        }}
        onCompleted={(data) => {
          if (data && data.removeFromWaitlist && data.removeFromWaitlist.ok) {
            onClose();
          }
        }}
      >
        {(mutate, { loading, data, error }) => {
          if (!!loading) {
            return (
              <Flex flex={1} flexDirection={'column'} justifyContent={'column'} alignItems={'center'}>
                <Heading mb={4} mx={3} textAlign="center">
                  Removing from waitlist
                </Heading>

                <SpinLoader />
              </Flex>
            );
          }

          if (!!error || (!!data && data.removeFromWaitlist && !data.removeFromWaitlist.ok)) {
            return (
              <>
                <Heading mb={4} mx={3} textAlign="center">
                  Remove from waitlist
                </Heading>

                <Text fontWeight={400} textAlign="center">
                  An error occured
                </Text>
                <Button variant={'primary'} mt={4} style={{ alignSelf: 'center', width: 'fit-content' }} onClick={onClose}>
                  close
                </Button>
              </>
            );
          }

          return (
            <>
              <Heading mb={4} mx={3} textAlign="center">
                Confirm Waitlist Removal
              </Heading>

              <Text fontWeight={400} textAlign="center">
                Are you sure you want to be removed from the waitlist?
                <br />
                Please confirm your choice.
              </Text>

              <Button variant={'primary'} mt={4} style={{ alignSelf: 'center', width: 'fit-content' }} onClick={mutate}>
                Confirm
              </Button>
            </>
          );
        }}
      </Mutation>
    </ModalInner>
  );
};

export default withRouter(RemovingFromWaitlistModalInner);
