import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import { Input } from '../../components/TextInput';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Creatable } from 'react-select';
import { queryMatch } from '../../utils/filterBannerQueryMatcher';
const ADMIN_GET_BANNER_TEST_USERS = gql`
  mutation getAdminFilterBannerTestForUsers($ids: [String!]!) {
    getAdminFilterBannerTestForUsers(ids: $ids) {
      id
      email
      firstName
      lastName
      gender
      phone
      intakeStatus
      intakeDate
      menoIntakeStatus
      menoIntakeDate
      departmentId
      dob
      primaryProviderId
      isLab
      helpscoutId
      medicationHistoryConsentVerified
      accountLocked
      isDiagnosed
      isMember
      membershipSpecific
      oonSchedulable
      mustCompleteProfile
      mustMergeAccount
      isMobileAppUser
      isCommunityUser
      mustCompleteBaselineSurvey
      baselineStatus
      mustScheduleOnboardingCall
      needInsuranceCompletion
      otbex
      perpetuousPhysicianConversationId
      diagnoseFull {
        hasHypo
        hypoNotes
        hasHashi
        hashiNotes
        hasAppointment
        hasAppointmentNutritionist
        reason
      }
      # isAthenaActive
      department {
        state
        departmentId
        timeZoneOffset
        timeZoneName
        tzAbbr
      }
      featureToggles {
        name
        isActive
      }
      address {
        street1
        street2
        city
        state
        zip
      }
      card {
        id
        brand
        last4
        expMonth
        expYear
        pm
      }
      eligibility {
        status
        canBook
        canPurchase
        consultation
        purchase
        pharmacy
        labCenter
        lab
        practitioner
        canBookNutritionist
        canBookAsync
        canBookPerimenopause
        canBookPostmenopause
      }
      pharmacy {
        id
        placeId
        name
        formattedAddress
        phone
      }
      labCenter {
        id
        placeId
        name
        formattedAddress
        phone
      }
      adm {
        imp
        mobileMan
        canFeatureTogglesManagement
        passwordExpired
        daysBeforePasswordExpires
        canUnithroid
        canStripeUserManagement
        canReporting
        canRemapAccount
        canChangeEmail
        canMobileUserManagement
        canGenerateOtp
        canBatchMigrateProviderGroups
        canOneTimeCouponManagement
        canViewAdminsLogs
        canAdminHealthCoachApp
        canAdminProvidersApp
        canB2BManagement
      }
      b2b {
        pendingApproval
        isActive
        mustVerify
        verificationSent
        verificationSentDate
        kit
        vitamins
        membership
        visits
        async
        nutritionist
        expiresAfter
      }
      dedicatedCareManager {
        id
        firstName
        lastName
        fullName
        bio
        thumbnail
        calendlyLink
      }
    }
  }
`;
const SelectStyled = styled(Box)`
  .region-select__control {
    border: 1px solid rgba(53, 78, 121, 0.1);
    border-radius: 10px;
    padding: 0px 12px;
    background: #fff;
    min-width: 150px;
    font-size: 12px;

    &:hover {
      border-color: #eee;
    }

    &--is-focused {
      box-shadow: none;
    }

    .region-select__indicator-separator {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const TestUser = ({ testUser, filters, selectedTestUser, setSelectedTestUser }) => {
  const [filterPassed, setFilterPassed] = useState('Checking...');
  useEffect(() => {
    const res = queryMatch(filters, testUser);
    if (!!res) {
      setFilterPassed('Visible');
    } else {
      setFilterPassed('Hidden');
    }
  }, []);
  return (
    <Flex
      flexDirection={'column'}
      key={testUser.id}
      mb={2}
      p={3}
      style={{
        border: '1px solid rgb(115, 158, 252)',
        borderRadius: '12px',
        cursor: 'pointer',
        backgroundColor: selectedTestUser && selectedTestUser.id === testUser.id ? 'rgba(115, 158, 252,0.33)' : '',
      }}
      onClick={() => setSelectedTestUser(testUser)}
    >
      <Flex alignItems={'center'} mb={3}>
        <Text fontWeight={500} flex={1} mb={0}>
          {testUser.firstName} {testUser.lastName}
        </Text>
        <Box style={{ borderRadius: '4px', width: 'fit-content', background: filterPassed === 'Checking...' ? 'transparent' : filterPassed === 'Visible' ? '#abdcab' : '#ba8352' }} p={'0 8px'}>
          <Text fontSize={'13px'} fontWeight={600} style={{ color: filterPassed === 'Checking...' ? '#777' : 'white' }}>
            {filterPassed}
          </Text>
        </Box>
      </Flex>
      <Text fontWeight={300} fontSize={'13px'}>
        {testUser.email}
      </Text>
    </Flex>
  );
};

export const TestUsersDrawer = ({ testUsers, setTestUsers, filters, selectedTestUser, setSelectedTestUser }) => {
  const [multiInputValue, setmultiInputValue] = React.useState('');
  const [multivalue, setmultiValue] = React.useState([]);

  const createOption = (label) => ({
    label,
    value: label,
  });

  const pasteMulti = (e) => {
    if (e && e.clipboardData) {
      const d = e.clipboardData.getData('text');
      if (!!d) {
        const arr = d.split(';');
        const l = [];

        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          if (index > 4) break;
          l.push(createOption(element));
        }
        // const l = d.split(';').map((x,i) => {
        //   return createOption(x);
        // });
        setmultiValue(l);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (!multiInputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (multivalue.length >= 5) return;
        setmultiValue((prev) => [...prev, createOption(multiInputValue)]);
        setmultiInputValue('');
        event.preventDefault();
    }
  };

  return (
    <Flex flexDirection={'column'} p={3} overflowY={'auto'}>
      <Heading mb={4}>Test Filter Against Users</Heading>
      <Text mb={3} fontSize={'13px'} fontWeight={300}>Validate visibility of the filters against some users (limit 5). <span style={{fontWeight:600}}>Enter the Athena Id</span> in the input below <span style={{fontWeight:600}}>and press Enter.</span></Text>

      <SelectStyled style={{ minWidth: '100%' }} onPaste={pasteMulti}>
        <Creatable
          classNamePrefix="region-select"
          isClearable
          isMulti
          menuIsOpen={false}
          inputValue={multiInputValue}
          onChange={(newValue) => setmultiValue(newValue)}
          onInputChange={(newValue) => setmultiInputValue(newValue)}
          onKeyDown={handleKeyDown}
          placeholder="Type an Athena ID and press enter..."
          value={multivalue}
        />
      </SelectStyled>
      <Flex mt={3} justifyContent={'center'} alignItems={'center'}>
        <Mutation
          mutation={ADMIN_GET_BANNER_TEST_USERS}
          variables={{ ids: multivalue.map((x) => x.value) }}
          onCompleted={(e) => {
            if (e.getAdminFilterBannerTestForUsers) {
              console.log('Test fetch', e.getAdminFilterBannerTestForUsers.length);
              setTestUsers([...e.getAdminFilterBannerTestForUsers, ...testUsers]);
            }
          }}
        >
          {(fetchUsers, { loading }) => {
            return (
              <Button variant={'primary'} onClick={fetchUsers} disabled={loading || !multivalue || multivalue.length < 1} style={{ padding: '8px 16px', fontSize: '12px', fontWeight: 300 }}>
                {!loading ? 'Fetch users' : 'Fetching...'}
              </Button>
            );
          }}
        </Mutation>
      </Flex>
      {!!testUsers &&
        testUsers.length > 0 && (
          <>
            <Text mb={3} mt={4}>
              Test Users:
            </Text>
            {testUsers.map((x) => {
              return <TestUser testUser={x} filters={filters} selectedTestUser={selectedTestUser} setSelectedTestUser={setSelectedTestUser} />;
            })}
          </>
        )}
    </Flex>
  );
};
