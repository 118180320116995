import React, { Fragment, useState } from 'react';
import { Card, Flex, Box, Heading, Text, Button } from 'rebass';
import { Query, withApollo } from 'react-apollo';
import styled from 'styled-components';
import moment from 'moment';
import { isMobile, isTablet } from 'react-device-detect';

import Communicator from '../pages/consultations/Communicator';
import PerpetualCommunicator from '../pages/perpetualCommunicator/Communicator';
import { GET_CLINICAL_PROVIDER, REQUEST_FOR_REFILL, REQUEST_FOR_SUPERBILL } from '../graphql';
import SpinLoader from '../components/SpinLoader';
import refillIcon from '../static/refillIcon.png';
import doctorIcon from '../static/doctorIcon.png';
import reportIcon from '../static/reportIcon.png';
import pillIcon from '../static/pillsIcon.png';
import fleche from '../static/fleche.png';
import ConfirmModal from './ConfirmModal';
import { TextAreaNoForm } from './TextArea';
import withSession from '../lib/withSession';
import UsrxModalMember, { UsrxContent } from './usrx/UsrxModalMember';
import { Icon } from '@iconify/react';
import chevronRight from '@iconify/icons-entypo/chevron-thin-right';
import { withRouter } from 'react-router';
import Modal from 'styled-react-modal';
import { withToastManager } from 'react-toast-notifications';
import withAnalytics from '../lib/withAnalytics';

const Icon2 = styled(Flex)`
  @media screen and (max-width: 480px) {
    display: none;
  }
`;
const BackButton = styled.div`
  background: rgba(180, 205, 244, 0.5);
  width: 100%;
  color: #333;
  padding: 6px 12px;
  margin-bottom: 160px;
  margin-left: -12px;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const CommunicatorFlex = styled(Flex)`
  width: 100%;
  flex-direction: column;
  @media screen and (min-width: 52em) {
    width: calc(100% - 80px);
  }
`;

const CommunicatorChoiceDesktopOnly = styled.div`
  @media screen and (max-width: 52em) {
    display: none;
  }
  max-height: calc(100% - 80px);
`;

const CommunicatorChoiceMobileOnly = styled.div`
  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const Avatar = styled(Flex)`
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  margin-right: 6px;
  & > img {
    width: 40px;
  }
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const InfoBox = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${(props) => props.theme.color.darkBluw};
  justify-content: center;
  max-width: 550px;
`;

const Bx = styled(Box)`
  background: #f3f7ff;
  border: 1px solid rgba(53, 80, 120, 0.2);
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  margin-top: 12px;
  cursor: pointer;
`;

const createMarkup = (html) => {
  return { __html: html };
};

const BoxSavings = ({ session, usarx, history, quantityValue, dosageQuantity }) => {
  if (!usarx) return null;

  const [isModalSavingsOpen, setIsModalSavingsOpen] = useState(false);

  return (
    <>
      <Bx onClick={() => setIsModalSavingsOpen(true)}>
        <Flex flexDirection="row">
          <Box flex={1}>
            <Text fontWeight="600">{session.isMember ? 'Your exclusive savings as a member' : "Don't miss out on savings "}</Text>{' '}
            {session.isMember ? (
              <Text mt={2}>
                Discounted price with member coupon at your selected pharmacy and for this quantity{' '}
                {quantityValue && dosageQuantity ? `(${quantityValue} ${(dosageQuantity || '').replace('(s)', 's')})` : ''}
                :&nbsp;
                <span style={{ fontWeight: 600 }}>${usarx.price.price}</span>
              </Text>
            ) : (
              <Text mt={2}>
                Paloma members pay&nbsp;
                <span style={{ fontWeight: 600 }}>${usarx.price.price}</span>
                &nbsp;at your selected pharmacy for this medication and quantity {quantityValue && dosageQuantity ? `(${quantityValue} ${(dosageQuantity || '').replace('(s)', 's')})` : ''}
              </Text>
            )}
          </Box>
          <Icon icon={chevronRight} style={{ color: '#355078', fontSize: '25px', alignSelf: 'center' }} />
        </Flex>
      </Bx>
      {!!isModalSavingsOpen ? (
        !isMobile || isTablet ? (
          <UsrxModalMember
            isOpen={isModalSavingsOpen}
            close={() => {
              setIsModalSavingsOpen(false);
            }}
            usarx={usarx}
          />
        ) : (
          <Modal
            isOpen={isModalSavingsOpen}
            close={() => {
              setIsModalSavingsOpen(false);
            }}
          >
            <Box
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: '0px',
                height: '100vh',
                maxHeight: '100vh',
                background: 'white',
                padding: '0px 12px 180px 12px',
                overflowY: 'scroll',
              }}
            >
              <BackButton onClick={() => setIsModalSavingsOpen(false)}>{`<  Back`}</BackButton>
              <UsrxContent usarx={usarx} isMember={session.isMember} history={history} />
            </Box>
          </Modal>
        )
      ) : null}
    </>
  );
};

class ConsultationPast extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      promptRefill: false,
      isRequestedRefill: false,
      promptRequestRefillDetails: false,
      refillDetails: null,
      promptRequestSuperbill: false,
      superbillRequested: false,
    };
  }

  requestForRefill = async () => {
    const { client } = this.props;
    const { refillDetails } = this.state;
    try {
      await client.mutate({
        mutation: REQUEST_FOR_REFILL,
        variables: {
          details: refillDetails,
        },
      });

      this.setState({
        promptRequestRefillDetails: false,
        refillDetails: null,
        promptRefill: true,
        isRequestedRefill: false,
      });
    } catch (error) {
      console.log('requestForRefill', error);
      this.setState({ isRequestedRefill: false });
    }
  };

  requestForSuperbill = async () => {
    const { client, toastManager, appointment, analytics } = this.props;
    this.setState({
      superbillRequested: true,
    });
    try {
      const res = await client.mutate({
        mutation: REQUEST_FOR_SUPERBILL,
        variables: {
          appointmentId: appointment.id,
          providerName: appointment.provider ? appointment.provider.displayName : '',
          appointmentDateTime: appointment.date + ' ' + appointment.startTime,
        },
      });

      if (!!res && !!res.data && !!res.data.requestForSuperbill && !!res.data.requestForSuperbill.ok) {
        analytics.track('Superbill Requested', {
          appointmentDateTime: appointment.date + ' ' + appointment.startTime,
          appointmentId: (appointment.appointmentId || '').toString(),
          providerName: appointment.patientFacingName || '',
        });

        toastManager.add('Request for superbill received', {
          appearance: 'success',
          autoDismissTimeout: 10000,
        });
      } else {
        toastManager.add('Request for superbill failed', {
          appearance: 'error',
          autoDismissTimeout: 10000,
        });
      }
    } catch (error) {
      toastManager.add('Request for superbill failed', {
        appearance: 'error',
        autoDismissTimeout: 10000,
      });
      console.log('requestForSuperbill', error);
      this.setState({ superbillRequested: false });
    }
  };

  handleClose = () => {
    this.setState({
      promptRefill: false,
    });
  };

  handleCloseRequest = () => {
    this.setState({
      promptRequestRefillDetails: false,
      refillDetails: null,
      promptRefill: false,
      isRequestedRefill: false,
      promptRequestSuperbill: false,
    });
  };

  render() {
    const { session, prescription, summary, appointment, history, isAsync = false, blockSend = false } = this.props;
    const { isRequestedRefill, promptRefill } = this.state;
    return (
      <Fragment>
        <Card p={[3, 3, 4]} mb={4}>
          <Flex>
            <Icon2 mr={[0, 4, 4]}>
              <img src={doctorIcon} height={50} width={50} alt="" />
            </Icon2>
            <Flex flexDirection="column">
              <Heading mb={3}>Patient</Heading>
              <Text>
                {session.firstName} {session.lastName}
              </Text>
            </Flex>
          </Flex>
        </Card>

        {prescription && (
          <Card p={[3, 3, 4]} mb={4}>
            <Flex>
              <Icon2 mr={[0, '12px', '12px']}>
                <img src={pillIcon} height={50} width={50} alt="" />
              </Icon2>
              <Flex flexDirection="column">
                <Heading mb={2}>Prescriptions</Heading>
                {prescription.map((element) => (
                  <Fragment key={element.prescriptionId}>
                    <Box mt={2} py={2}>
                      <Text fontWeight={500}>
                        {element.description}
                        {element.quantityValue && element.dosageQuantity ? `(${element.quantityValue} ${(element.dosageQuantity || '').replace('(s)', 's')})` : ''}
                      </Text>
                      <Text>{element.instructions}</Text>
                      {element.patientNote && <Text>{element.patientNote}</Text>}
                      <Text fontSize={1}>Prescribed {moment(element.rxDate).format('LL')}</Text>

                      <BoxSavings history={history} session={session} usarx={element.usarx} quantityValue={element.quantityValue} dosageQuantity={element.dosageQuantity} />
                    </Box>
                  </Fragment>
                ))}
              </Flex>
            </Flex>
          </Card>
        )}

        {summary && (
          <Card p={[3, 3, 4]} mb={4}>
            <Flex>
              <Icon2 mr={[0, 4, 4]}>
                <img src={reportIcon} height={50} width={50} alt="" />
              </Icon2>

              <Flex flexDirection="column" flex={1}>
                <Heading mb={2}>Visit Summary</Heading>

                <Box mt={2} py={2}>
                  <Text fontWeight={500}>Patient Instructions</Text>

                  {summary.instructions && <Box style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={createMarkup(summary.instructions)} />}

                  {!summary.instructions && <Text>None</Text>}
                </Box>
                {summary.goals !== null &&
                  summary.goals !== '' && (
                    <Box mt={2} py={2}>
                      <Text fontWeight={500} style={{ marginBottom: '16px' }}>
                        Patient Goals
                      </Text>

                      <Box>
                        <Text style={{ wordBreak: 'break-word' }}>{summary.goals}</Text>
                      </Box>
                    </Box>
                  )}
                {/* {summary.discussionNotes !== null &&
                    summary.discussionNotes !== '' && (
                      <Box mt={2} py={2}>
                        <Text fontWeight={500} style={{ marginBottom: '16px' }}>
                          Discussion Notes
                        </Text>
  
                        <Box>
                        <Box style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={createMarkup(summary.discussionNotes)} />
                        </Box>
                      </Box>
                    )} */}
                {appointment.appointmentTypeId &&
                  (appointment.appointmentTypeId || '').toString() !== window._env_.REACT_APP_ATHENA_MEMBER_CONSULT30_APPOINTMENT_TYPE_ID &&
                  (appointment.appointmentTypeId || '').toString() !== window._env_.REACT_APP_ATHENA_MEMBER_FOLLOWUP15_APPOINTMENT_TYPE_ID &&
                  (appointment.appointmentTypeId || '').toString() !== window._env_.REACT_APP_ATHENA_MEMBER_FOLLOWUP30_APPOINTMENT_TYPE_ID &&
                  (appointment.appointmentTypeId || '').toString() !== window._env_.REACT_APP_ATHENA_B2B_FOLLOWUP15_APPOINTMENT_TYPE_ID &&
                  (appointment.appointmentTypeId || '').toString() !== window._env_.REACT_APP_ATHENA_B2B_FOLLOWUP30_APPOINTMENT_TYPE_ID &&
                  (appointment.appointmentTypeId || '').toString() !== window._env_.REACT_APP_ATHENA_B2B_CONSULT30_APPOINTMENT_TYPE_ID &&
                  (appointment.appointmentTypeId || '').toString() !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
                  (appointment.appointmentTypeId || '').toString() !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID && (
                    <Button variant={'primary'} mt={3} type="button" onClick={this.requestForSuperbill} style={{ width: '190px' }}>
                      Request Superbill
                    </Button>
                  )}
              </Flex>
            </Flex>
          </Card>
        )}
        {appointment.appointmentTypeId &&
        appointment.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
        (appointment.appointmentTypeId || '').toString() !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID ? (
          <Card p={[3, 3, 4]} mb={4}>
            <Flex>
              <Icon2 mr={[0, '12px', '12px']}>
                <img src={refillIcon} height={70} width={70} alt="" />
              </Icon2>

              <Flex flexDirection="column">
                <Heading mb={2}>Need a refill?</Heading>

                <Box mt={2} py={2}>
                  <Text fontWeight={500}>Let us know if you need a refill and someone from the practice will reach out</Text>
                </Box>
                <div style={{ textAlign: 'left' }}>
                  <Button
                    mt={3}
                    variant="primary"
                    type="button"
                    disabled={isRequestedRefill}
                    onClick={() => {
                      this.setState({ promptRequestRefillDetails: true });
                      // this.setState({ isRequestedRefill: true }, () =>
                      //   this.requestForRefill()
                      // );
                    }}
                  >
                    Ask for a refill
                  </Button>
                </div>
              </Flex>
            </Flex>
          </Card>
        ) : null}
        {isMobile && !isTablet ? (
          <Card p={[3, 3, 4]} style={{ marginBottom: '64px' }}>
            <Flex>
              <Icon2 mr={[0, '12px', '12px']}>
                <img src={fleche} height={70} width={70} alt="" />
              </Icon2>

              <CommunicatorFlex id="t1">
                <Heading mb={4}>A question for your clinician?</Heading>
                <CommunicatorChoiceMobileOnly id="t2">
                  <Query
                    query={GET_CLINICAL_PROVIDER}
                    variables={{
                      athenaProviderId: appointment.provider.id.toString(),
                    }}
                  >
                    {({ loading, data, error }) => {
                      if (loading || (!data && !data.getClinicalProvider)) {
                        return (
                          <div
                            style={{
                              marginLeft: 'calc(50% - 25px)',
                              marginBottom: '12px',
                            }}
                          >
                            <SpinLoader />
                          </div>
                        );
                      }

                      if (error) {
                        return <div />;
                      }

                      return (
                        <div
                          id="t2"
                          style={{
                            border: '1px solid rgb(52, 79, 121)',
                            padding: '6px',
                            borderRadius: '4px',
                            boxShadow: '6px 6px 5px 0px rgba(52, 79, 121, 0.35)',
                            marginBottom: '12px',
                          }}
                          onClick={() => {
                            this.props.history.push({
                              pathname: `/consultations-communicator/${appointment.id}`,
                              state: { isAsync, blockSend },
                            });
                          }}
                          // onClick={() => {
                          //   this.props.history.push(
                          //     `/consultations-communicator/${appointment.id}`
                          //   );
                          // }}
                        >
                          <Flex flexDirection="row" id="t4">
                            <Avatar style={{ alignSelf: 'center' }}>
                              <img alt="" src={data.getClinicalProvider.picture} />
                            </Avatar>
                            <Flex flexDirection="column" id="t5">
                              <Text fontWeight="500">Chat with {` ${data.getClinicalProvider.patientFacingName}`}</Text>
                              <Text fontWeight="300" fontSize="14px">
                                {`usually reply within ${data.getClinicalProvider.doctorType === 'nutritionist'?'2':'3 to 5'} business days`}
                              </Text>
                            </Flex>
                          </Flex>
                        </div>
                      );
                    }}
                  </Query>
                </CommunicatorChoiceMobileOnly>
              </CommunicatorFlex>
            </Flex>
          </Card>
        ) : (
          <Card p={[3, 3, 4]} style={{ marginBottom: '64px' }}>
            <Flex>
              <Icon2 mr={[0, 4, 4]}>
                <img src={fleche} height={50} width={50} alt="" />
              </Icon2>

              <CommunicatorFlex id="t1" style={{ maxHeight: '600px', position: 'relative' }}>
                <Heading mb={4}>A question for your clinician?</Heading>
                <CommunicatorChoiceDesktopOnly>
                  {!!isAsync?( // || !session.perpetuousPhysicianConversationId ? (
                    <Communicator appointmentId={appointment.id} appointment={appointment} prescription={prescription} summary={summary} isAsync={isAsync} blockSend={blockSend} />
                  ) : (
                    <PerpetualCommunicator appointmentId={appointment.id} appointment={appointment} isAsync={false} />
                  )}
                </CommunicatorChoiceDesktopOnly>
              </CommunicatorFlex>
            </Flex>
          </Card>
        )}
        <div style={{ height: '64px', width: '100%' }}> </div>

        <ConfirmModal
          isOpen={this.state.promptRequestRefillDetails}
          confirmLabel="Request"
          onClose={this.handleCloseRequest}
          onBackgroundClick={this.handleCloseRequest}
          onEscapeKeydown={this.handleCloseRequest}
          onConfirm={this.requestForRefill}
          disabled={isRequestedRefill}
        >
          <ModalInner>
            <InfoBox bg="#D9E6F9" mb={4} flexDirection="row">
              <Flex flexDirection="column">
                <Text fontSize={3} fontWeight={700} textAlign="center" mb={4}>
                  Please confirm the medication(s) you would like to refill today and the dose you are currently taking
                </Text>
                <TextAreaNoForm
                  value={this.state.refillDetails}
                  onChange={(v) => {
                    this.setState({
                      refillDetails: v.target.value,
                    });
                  }}
                />
              </Flex>
            </InfoBox>
          </ModalInner>
        </ConfirmModal>

        <ConfirmModal okOnly isOpen={promptRefill} confirmLabel="OK" onClose={this.handleClose} onBackgroundClick={this.handleClose} onEscapeKeydown={this.handleClose} onConfirm={this.handleClose}>
          <ModalInner>
            <InfoBox bg="#D9E6F9" mb={4}>
              <Text fontSize={3} fontWeight={700} textAlign="center">
                We received your request for a refill, someone from our staff will be in touch shortly
              </Text>
            </InfoBox>
          </ModalInner>
        </ConfirmModal>
      </Fragment>
    );
  }
}

export default withAnalytics(withApollo(withSession(withRouter(withToastManager(ConsultationPast)))));
