import React, { useEffect, useState } from 'react';
import { Flex, Text } from 'rebass';
import { Input } from '../../../../components/TextInput';

export const CheckBoxesCtrl = ({ properties }) => {
  return (
    <Flex flex={1}>
      <Flex flexDirection={'column'} flex={1 / 4}>
        <Text onClick={() => {}} fontWeight={500}>
          {`${(properties || {}).question || 'Question'}${((properties || {}).ctrlProps || {}).required ? '*' : ''}`}
        </Text>
        <Text fontSize={'12px'} fontWeight={300}>
          {(properties || {}).description || 'Description'}
        </Text>
      </Flex>
      <Flex flex={1} justifyContent={'flex-start'} flexDirection={'column'}>
        {(((properties || {}).ctrlProps || {}).options || []).map((x) => {
          return (
            <Flex mb={2}>
              <Input type="checkbox" checked={x.isChecked} id={x.value} name={x.value} />
              <label htmlFor={x.value} style={{ marginLeft: '16px' }}>
                {x.label}
              </label>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
