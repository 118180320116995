import React, { Component, Fragment } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import ScrollAnimation from 'react-animate-on-scroll';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';

import { TextInput } from '../../components/TextInput';
import { TextArea } from '../../components/TextArea';
import { FormField } from '../../components/FormField';
import { APPOINTMENTS, CHARTS, CURRENT_USER, SET_AS_INACTIVE } from '../../graphql';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { ButtonWithSelectionCircle, ButtonWithSelectionCircleSelectedMarkerIn, ButtonWithSelectionCircleSelectedMarkerOut } from './components/ButtonWithSelectionCircle';
import { fadeInDown, fadeInUp } from '../../utils/animationsStyleBuilder';

class Single extends Component {
  state = {
    display: false,
    selected: {},
  };

  constructor(props) {
    super(props);

    if (props.data && props.data.skipIfNotQuestionId) {
      console.log({ skipIfNotQuestionId: props.data.skipIfNotQuestionId, dt: props.userData });
      let isFound = false;
      for (let index = 0; index < props.userData.length; index++) {
        const element = props.userData[index];
        for (let index = 0; index < element.values.length; index++) {
          const val = element.values[index];
          if (val.questionId && val.questionId === props.data.skipIfNotQuestionId) {
            isFound = true;
            break;
          }
        }
      }
      if (!isFound) {
        props.wizard.next();
      }
    }
  }

  handleClick = async (element) => {
    const { data, onSubmit, wizard } = this.props;

    if (element.isSkip) {
      this.props.wizard.next();
      return;
    }

    if (element.isDisqualifying) {
      const { client, toastManager } = this.props;
      this.setState({ loading: true });
      const dta = JSON.parse(JSON.stringify(this.props.userData));
      if (element.questionId) {
        const payload = element.value
          ? {
              values: [{ questionId: element.questionId, answer: element.answer, value: element.value }],
              endpoint: data.endpoint,
            }
          : {
              values: [{ questionId: element.questionId, answer: element.answer }],
              endpoint: data.endpoint,
            };
        dta.push(payload);
      }
      try {
        await client.mutate({
          mutation: CHARTS,
          variables: {
            data: dta,
            intakeStatus: 'disqualified',
          },
        });
        await client.mutate({
          mutation: SET_AS_INACTIVE,
          variables: {
            disqualifyingReason: data.disqualifyingText || data.id,
          },
          refetchQueries: [{ query: CURRENT_USER }, { query: APPOINTMENTS }],
          awaitRefetchQueries: true,
        });

        wizard.history.push('disqualified');
      } catch (err) {
        console.log('ERR', err);
        this.setState({ loading: false });
        toastManager.add('Error submitting intake form', {
          appearance: 'error',
        });
      }
    } else {
      if (element.placeholder) {
        this.setState({ display: true, selected: element });
      } else {
        const payload = element.value
          ? {
              values: [{ questionId: element.questionId, answer: element.answer, value: element.value }],
              endpoint: data.endpoint,
            }
          : {
              values: [{ questionId: element.questionId, answer: element.answer }],
              endpoint: data.endpoint,
            };

        onSubmit(payload, null, wizard, element);
      }
    }
  };

  shiftView = () => {
    setTimeout(() => {
      window.scrollTo(0, this.inputRef.offsetTop);
    }, 150);
  };

  render() {
    const { data, onSubmit, wizard } = this.props;
    const { selected, display, loading } = this.state;

    return (
      <Fragment>
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          <Box m={2} style={{ ...fadeInDown() }}>
            <Text fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
              {data.prompt}
            </Text>
          </Box>

          {data.description && (
            <Text fontWeight={300} color="#344f77" textAlign="center" style={{ ...fadeInDown(20, '1s', '200ms') }}>
              {data.description}
            </Text>
          )}

          <Formik
            initialValues={{ [data.id]: '' }}
            validationSchema={data.schema || ''}
            onSubmit={(values, actions) => {
              let value;

              if (selected.prefix) {
                value = `${selected.prefix} ${values[data.id]}`;
              } else {
                value = values[data.id];
              }

              const payload = {
                values: [
                  {
                    questionId: selected.questionId,
                    answer: selected.answer,
                    value,
                  },
                ],
                endpoint: data.endpoint,
              };

              onSubmit(payload, actions, wizard, selected);
            }}
            render={({ isValid, errors, touched, submitForm }) => (
              <Form>
                <Flex flex={1} flexDirection="column">
                  <Flex my={3} flexDirection="column" alignItems={'center'} justifyContent="center">
                    {data.options &&
                      data.options.map((el, ii) => (
                        <Fragment key={el.value}>
                          <ButtonWithSelectionCircle
                            type="button"
                            variant={'white'}
                            disabled={loading}
                            className={(!!data.extend || data.options.findIndex(x=>!!x.placeholder) > -1) && selected === el ? 'selected' : ''}
                            // variant={selected.value === (el.display || el.value) ? 'selected' : 'white'}
                            width={[1, 1 / 2]}
                            m={3}
                            style={{ ...fadeInUp(20, '1.1s', `${ii * 100}ms`) }}
                            onClick={() => this.handleClick(el)}
                          >
                            {!!data.extend || data.options.findIndex(x=>!!x.placeholder) > -1 ? (
                              <Flex alignItems={'center'}>
                                <ButtonWithSelectionCircleSelectedMarkerOut>{selected === el && <ButtonWithSelectionCircleSelectedMarkerIn />}</ButtonWithSelectionCircleSelectedMarkerOut>
                                <Text>{el.display || el.value}</Text>
                              </Flex>
                            ) : (
                              <>{el.display || el.value}</>
                            )}
                          </ButtonWithSelectionCircle>
                        </Fragment>
                      ))}
                  </Flex>

                  <Flex flex={1} my={3} justifyContent="center" ref={(ref) => (this.inputRef = ref)} onClick={this.shiftView}>
                    <FormField width={[1, 1 / 2]} px={3} error={touched[data.id] && errors[data.id]} style={{ display: display ? '' : 'none' }}>
                      {!data.extend && <TextInput name={data.id} placeholder={selected.placeholder} type={selected.inputType || 'text'} inputType={selected.inputmode || 'text'} inputMode={selected.inputmode || 'text'}/>}

                      {data.extend && <TextArea name={data.id} placeholder={selected.placeholder} />}
                    </FormField>
                  </Flex>

                  <Flex justifyContent="center" style={{ display: display ? '' : 'none' }}>
                    <Button disabled={!isValid || loading} variant="pink" width={[1 / 2, 1 / 3]} type="submit">
                      {loading ? 'Processing' : 'Next'}
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            )}
          />
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(Single);
// export default withWizard(Single);
