import moment from 'moment';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { APPOINTMENTS, ATHENA_CARD, CURRENT_USER, GET_ANALYTICS_ID, GET_USER_PCP, LABS, LOGIN_FROM_MOBILE } from '../graphql';
import withAnalytics from '../lib/withAnalytics';
import gql from 'graphql-tag';
import PleaseWait from '../components/PleaseWait';

const LOGIN_FROM_HC = gql`
mutation loginFromHC($token:String!, $athenaId:String!){
  loginFromHC(token: $token, athenaId:$athenaId) {
token
  }
}
`

const loginFromHC = ({ match, client, history, analytics }) => {
  useEffect(() => {
    console.log(match);

    if (!match) return;
    if (!match || !match.params || !match.params.token) {
      return;
    }

    async function Identify() {
      const { token, impersonate } = match.params;
      console.log({ token, impersonate });

      const res = await client.mutate({
        mutation: LOGIN_FROM_HC,
        variables: {
          token: match.params.token,
          athenaId: impersonate
        },
      });
      localStorage.setItem('initialToken', 'aa00');
localStorage.setItem('token', res.data.loginFromHC.token);

      await client.query({
        query: CURRENT_USER,
        refetchQueries: [
          { query: ATHENA_CARD },
          { query: APPOINTMENTS },
          { query: LABS },
          { query: GET_USER_PCP },
        ],
        fetchPolicy: 'network-only',
        awaitRefetchQueries: true,
      });
      // if (res && res.data && res.data.loginFromMobile && res.data.loginFromMobile.token) {
      //   localStorage.setItem('token', res.data.loginFromMobile.token);
      //   const aa = await client.query({
      //     query: GET_ANALYTICS_ID,
      //     fetchPolicy: 'network-only',
      //   });

      //   if (aa && aa.data && aa.data.getAnalyticsId) {
      //     analytics.Alias(aa.data.getAnalyticsId);
      //     try {
      //       const pt = await client.query({
      //         query: CURRENT_USER,
      //         fetchPolicy: 'network-only',
      //       });

      //     } catch (error) {
      //       // console.log('err id', error)
      //     }
      //   }

      let redirectTo = '/';

      history.push(redirectTo);
      // }
    }
    Identify();
  }, []);
  return <PleaseWait />;
};

export default withRouter(withAnalytics(loginFromHC));
