import React from 'react';
import { Query } from 'react-apollo';
import 'react-table-v6/react-table.css';
import Select from 'react-select';
import FormField from '../../../../../components/FormField';
import TextInput from '../../../../../components/TextInput';
import TextArea from '../../../../../components/TextArea';
import { GET_SYMPTOM_TRACKER_LABELS_GROUPS } from '../../../../../graphql';

const TrackingFormScreen = ({
  touched,
  errors,
  hormoneYValues,
  values,
  setFieldTouched,
  setFieldValue,
}) => {
  return (
    <Query query={GET_SYMPTOM_TRACKER_LABELS_GROUPS}>
      {({ data, loading, error }) => {
        if (loading || error) return <div />;
        const labelsSelect = [];

        for (
          let index = 0;
          index < data.getSymptomTrackerLabelsGroups.length;
          index++
        ) {
          const element = data.getSymptomTrackerLabelsGroups[index];
          labelsSelect.push({
            value: element.id,
            label: element.labels
              .map(x => `${x.value}% ${x.label}`)
              .join(' - '),
          });
        }
        return (
          <div style={{ overflowY: 'auto' }}>
            <FormField mb={3} error={touched.phone && errors.phone}>
              <label htmlFor="title">Title</label>
              <TextInput name="title" placeholder="Title" />
            </FormField>

            <FormField mb={3} error={touched.phone && errors.phone}>
              <label htmlFor="content">Content</label>
              <TextArea name="Content" placeholder="Content" />
            </FormField>

            <FormField mb={3} error={touched.phone && errors.phone}>
              <label htmlFor="tags">Tags (separate with ;)</label>
              <TextInput name="tags" placeholder="Tags" />
            </FormField>

            <FormField mb={3} error={touched.phone && errors.phone}>
              <label htmlFor="titleColor">Title Color</label>
              <TextInput name="titleColor" placeholder="Title Color" />
            </FormField>

            <FormField mb={3} error={touched.phone && errors.phone}>
              <label htmlFor="titleFontSize">Text Font Size</label>
              <TextInput
                type="number"
                name="titleFontSize"
                placeholder="Title Font Size"
              />
            </FormField>

            <FormField mb={3} error={touched.phone && errors.phone}>
              <label htmlFor="textColor">Text and Input Color</label>
              <TextInput name="textColor" placeholder="Text Color" />
            </FormField>

            <FormField mb={3} error={touched.phone && errors.phone}>
              <label htmlFor="textFontSize">Text Font Size</label>
              <TextInput
                type="number"
                name="textFontSize"
                placeholder="Text Font Size"
              />
            </FormField>

            <FormField mb={3} error={touched.phone && errors.phone}>
              <label htmlFor="negativeColor">Main Background Color</label>
              <TextInput name="negativeColor" placeholder="#cccccc" />
            </FormField>

            <FormField mb={3} error={touched.phone && errors.phone}>
              <label htmlFor="positiveColor">Progress Background Color</label>
              <TextInput name="positiveColor" placeholder="#cccccc" />
            </FormField>

            <FormField mb={3} error={touched.phone && errors.phone}>
              <label htmlFor="trackColor">Track Background Color</label>
              <TextInput name="trackColor" placeholder="#cccccc" />
            </FormField>

            <FormField mb={3} error={touched.phone && errors.phone}>
              <label htmlFor="knobColor">Knob Background Color</label>
              <TextInput name="knobColor" placeholder="#cccccc" />
            </FormField>
            <FormField mb={4} error={touched.phone && errors.phone}>
              <label htmlFor="hormoneY">Labels</label>
              <Select
                classNamePrefix="region-select"
                options={labelsSelect}
                name="labelGroupId"
                placeholder="select labels"
                value={labelsSelect.filter(
                  element => element.value === values.labelGroupId
                )}
                onChange={option => setFieldValue('labelGroupId', option.value)}
                onBlur={() => setFieldTouched('labelGroupId', true)}
                isSearchable={true}
              />
            </FormField>

            <FormField mb={4} error={touched.phone && errors.phone}>
              <label htmlFor="hormoneY">Hormone Y</label>
              <Select
                classNamePrefix="region-select"
                options={hormoneYValues}
                name="hormoneY"
                value={values.hormoneY}
                placeholder="select hormone Y"
                onChange={option => {
                  setFieldValue('hormoneY', option);
                }}
                onBlur={() => setFieldTouched('hormoneY', true)}
                isSearchable={true}
              />
            </FormField>
          </div>
        );
      }}
    </Query>
  );
};

export default TrackingFormScreen;
