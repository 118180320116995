import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

import Header from '../components/Header';
import FullLayout from '../components/FullLayout';
import PleaseWait from '../components/PleaseWait';

import { DELETE_INSURANCE, GET_INSURANCE_ON_FILE } from '../graphql';
import { RotatingText } from './components/RotatingText';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
`;

const withOnFileInsurance = Component => props => (
  <Mutation mutation={DELETE_INSURANCE} refetchQueries={GET_INSURANCE_ON_FILE}>
    {(mutate, mutationProps) => (
      <Query query={GET_INSURANCE_ON_FILE}>
        {({ data, loading, error }) => {
  // console.log('withOnFileInsurance', data)
          if (loading || mutationProps.loading) {
            return (
              <FullLayout hideBubbles={true} >
                <Container>
                  <Flex flex={1}>
                    <Header dark={false} />
                  </Flex>
                  <PleaseWait
                    text=" "
                    spinner={{
                      sColor: '#ECEFF2',
                      pColor: '#909FB5',
                    }}
                  >
                    <RotatingText />
                  </PleaseWait>
                </Container>
              </FullLayout>
            );
          }
          if (error) {
            if (error) {
              console.log('err curr', error);
            }
            return null;
          }

          return (
            <Component
              {...props}
              onFileInsurance={data.getInsurancesOnFile}
              deleteOnFileInsurance={mutate}
            />
          );
        }}
      </Query>
    )}
  </Mutation>
);

export default withOnFileInsurance;
