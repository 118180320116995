import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import * as Yup from 'yup';
import TextInput, { Input, TextInputWithLabel } from '../../../../components/TextInput';
import { Form, Formik } from 'formik';
import FormField from '../../../../components/FormField';
import { EditModalButtons } from '../Builder';

export const DropDownEdit = ({ close, save, properties }) => {
  const [options, setOptions] = useState(((properties || {}).ctrlProps || {}).options || []);
  const [duplicates, setDuplicates] = useState([]);

  useEffect(
    () => {
      const r = [];

      for (let index = 0; index < options.length; index++) {
        const element = options[index];
        const newId = options.findIndex((x) => x.label === element.label);
        if (newId !== index) {
          if (r.findIndex((xx) => xx === newId) < 0) {
            r.push(element.label);
          }
        }
      }
      setDuplicates(r);
    },
    [options]
  );
  const schema = Yup.object().shape({
    question: Yup.string()
      .trim()
      .min(2, 'Question must be greater than 1 character')
      .required('Required'),
    researchId: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9_]+$/, 'No special characters or space allowed except underscore (_)')
      .nullable(true),
  });
  return (
    <Flex flex={1} flexDirection={'column'} width={'600px'} style={{ overflowY: 'auto' }}>
      <Heading textAlign={'center'} mb={4}>
        Edit Dropdown
      </Heading>
      <Formik
        initialValues={{ ...properties }}
        isInitialValid={() => {
          return properties && properties.question && properties.question.length > 1;
        }}
        validationSchema={schema}
        onSubmit={(values, actions) => save({ ...values, ctrlProps: { ...values.ctrlProps, options } }, actions)}
        render={({ touched, errors, isValid, values, submitForm, setError }) => (
          <Form>
            <FormField mb={3} error={touched.question && errors.question}>
              <TextInputWithLabel placeholder="Question *" name="question" id="input_question" />
            </FormField>
            <FormField mb={3} error={touched.description && errors.description}>
              <TextInputWithLabel placeholder="description" name="description" id="input_description" />
            </FormField>
            <FormField mb={3} error={(touched.ctrlProps || {}).placeholder && (errors.ctrlProps || {}).placeholder}>
              <TextInputWithLabel placeholder="placeholder" name="ctrlProps.placeholder" id="input_placeholder" />
            </FormField>
            <FormField mb={3} error={touched.researchId && errors.researchId}>
              <TextInputWithLabel placeholder="research Id (if set, data can be used for data analysis)" name="researchId" id="input_researchId" />
            </FormField>
            <Text mt={4} mb={3}>
              Options:
            </Text>
            <Flex flexDirection={'column'} ml={4}>
              {options.map((x, i) => {
                return (
                  <Flex mb={2} alignItems={'center'}>
                    <Input
                      value={x.label}
                      onChange={(e) => {
                        const t = JSON.parse(JSON.stringify(options));
                        t[i].label = e.target.value;
                        t[i].value = e.target.value.replace(/ /g, '_');
                        setOptions(t);
                      }}
                    />

                    <Box
                      style={{ padding: '2px 8px', cursor: 'pointer' }}
                      ml={2}
                      onClick={() => {
                        const t = JSON.parse(JSON.stringify(options));
                        if (i > -1) {
                          t.splice(i, 1);
                        }

                        setOptions(t);
                      }}
                    >
                      <i className="fa fa-trash" />
                    </Box>
                  </Flex>
                );
              })}
              <Flex justifyContent={'center'} alignItems={'center'} mt={2} mb={3}>
                <Button
                  variant={'outline'}
                  type="button"
                  style={{ width: 'auto', padding: '6px 24px' }}
                  disabled={options.filter((x) => !x.value).length > 0}
                  onClick={() => {
                    const opts = JSON.parse(JSON.stringify(options || []));
                    opts.push({
                      label: '',
                      value: '',
                    });

                    setOptions(opts);
                  }}
                >
                  Add option
                </Button>
              </Flex>
            </Flex>
            <FormField
              flexDirection="row"
              mb={3}
              style={{
                alignContent: 'center',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
              error={touched.required && errors.required}
            >
              <TextInput type="checkbox" checked={values.ctrlProps.required} id={`required`} name="ctrlProps.required" />
              <label htmlFor="required" style={{ marginLeft: '16px' }}>
                is required
              </label>
            </FormField>
            <FormField
              flexDirection="row"
              mb={3}
              style={{
                alignContent: 'center',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
              error={touched.mustBeInherited && errors.mustBeInherited}
            >
              <TextInput type="checkbox" checked={values.mustBeInherited} id={`mustBeInherited`} name="mustBeInherited" />
              <label htmlFor="mustBeInherited" style={{ marginLeft: '16px' }}>
                must be inherited (templates created from this one will mandatorily have this item)
              </label>
            </FormField>
            {!!duplicates &&
              duplicates.length > 0 && (
                <Flex mb={4} flexDirection={'column'} style={{ background: '#a00b0b', padding: '8px 16px' }}>
                  <Text color={'white'} fontWeight={500}>
                    There are some duplicated options:
                  </Text>
                  {duplicates.map((x) => {
                    return <Text color={'white'}>- {x}</Text>;
                  })}
                </Flex>
              )}
            <EditModalButtons
              close={close}
              save={submitForm}
              isValid={isValid && options && options.length > 0 && options.filter((x) => !x.value).length < 1 && (!duplicates || duplicates.length < 1)}
            />
          </Form>
        )}
      />
    </Flex>
  );
};
