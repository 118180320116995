import React from 'react';
import { Flex, Box } from 'rebass';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const Header = props => (
  <Flex
    width={1}
    flex={1}
    mt={5}
    mb={props.mb || 4}
    justifyContent="center"
    flexDirection="column"
    alignItems="center"
  >
    <Box width={[1 / 2, 1 / 3, 1 / 4]}>
      <Link to="/">
        <Logo
          dark={
            props.dark === null || props.dark === undefined ? true : props.dark
          }
        />
      </Link>
    </Box>
    {!!props.children && (
    <Flex my={props.mYChildren ||3} justifyContent="center">
      {props.children}
    </Flex>
    )}
  </Flex>
);

export default Header;
