import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { ADMIN_GET_THYROID_PROGRAMS, ADMIN_GET_THYROID_PROGRAM_BY_ID } from '../graphql';
import ConfirmModal from '../components/ConfirmModal';
import AddPresetPlan from './adminPresetPlan/modals/AddPresetPlan';
import { withRouter } from 'react-router';
import SectionsList from './adminPresetPlan/Sections';
import ScreenFormatList from './adminPresetPlan/ScreenFormats';
import MobilePhonePreview from './adminPresetPlan/modals/MobilePhonePreview';
import ProgramCoursesList from './adminPresetPlan/ProgramCoursesList';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;
const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;

const AdminPresetPlan = ({ history, match }) => {
  const planId = match && match.params && match.params.id ? match.params.id : '';
  const [isAddNewPlan, setIsAddNewPlan] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  return (
    <Container>
      <Query query={ADMIN_GET_THYROID_PROGRAM_BY_ID} variables={{ id: planId }}>
        {({ data, loading, error }) => {
          if (loading || error) return <div />;
          return (
            <Box>
              <Flex flexDirection="row" style={{ justifyContent: 'space-between' }} mt={4} mb={3} alignItems="center">
                <Flex flex={1} alignItems="center">
                  <Heading>{data.getAdminThyroidProgramsById.name}</Heading>
                  <Box ml={2} style={{ background: data.getAdminThyroidProgramsById.isActive ? '#B0DEA8' : '#DBDBDB', padding: '2px 8px', borderRadius: '8px', color: 'white' }}>
                    {data.getAdminThyroidProgramsById.isActive ? 'Active' : 'Inactive'}
                  </Box>
                  {data.getAdminThyroidProgramsById.isArchived && (
                    <Box ml={2} style={{ background: '#D38782', color: 'white', padding: '2px 8px', borderRadius: '8px' }}>
                      Archived
                    </Box>
                  )}
                </Flex>
                <Button
                  variant={'primary'}
                  onClick={() => {
                    setShowPreview(true);
                  }}
                >
                  Preview
                </Button>
                <Button
                  variant={'pink'}
                  onClick={() => {
                    setIsAddNewPlan(true);
                  }}
                >
                  Edit
                </Button>
              </Flex>

              <Flex style={{ border: '1px solid #bbb', padding: '12px' }} flexDirection="column">
                <Text fontSize={'12px'} fontWeight={300}>
                  Description
                </Text>
                <Text>{data.getAdminThyroidProgramsById.description}</Text>
              </Flex>

              <SectionsList programId={planId} />
              <ScreenFormatList />

              <ProgramCoursesList programId={planId} />

              <ConfirmModal
                style={{ background: '#bbb' }}
                childrenManaged
                isOpen={showPreview}
                confirmLabel="create"
                onClose={() => {
                  setIsAddNewPlan(false);
                }}
                onBackgroundClick={() => {
                  setIsAddNewPlan(false);
                }}
                onEscapeKeydown={() => {
                  setIsAddNewPlan(false);
                }}
                onConfirm={() => {
                  setIsAddNewPlan(false);
                }}
              >
                <ModalInner padding={'14px'}>
                  <MobilePhonePreview program={data.getAdminThyroidProgramsById} />
                </ModalInner>
              </ConfirmModal>

              <ConfirmModal
                childrenManaged
                isOpen={isAddNewPlan}
                confirmLabel="create"
                onClose={() => {
                  setIsAddNewPlan(false);
                }}
                onBackgroundClick={() => {
                  setIsAddNewPlan(false);
                }}
                onEscapeKeydown={() => {
                  setIsAddNewPlan(false);
                }}
                onConfirm={() => {
                  setIsAddNewPlan(false);
                }}
              >
                <ModalInner style={{ maxHeight: 'initial' }}>
                  <Heading fontSize="20px" mb={'20px'}>
                    Create Preset Plan
                  </Heading>
                  <AddPresetPlan
                    initialValues={data.getAdminThyroidProgramsById}
                    onClose={() => {
                      setIsAddNewPlan(false);
                    }}
                    onSave={() => {
                      setIsAddNewPlan(false);
                    }}
                  />
                </ModalInner>
              </ConfirmModal>
            </Box>
          );
        }}
      </Query>
    </Container>
  );
};

export default withRouter(AdminPresetPlan);
