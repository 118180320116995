import React, { Fragment, Component, useState } from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { compose, withApollo, Query } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';

import SpinLoader from '../components/SpinLoader';
import ConfirmModal from '../components/ConfirmModal';
import ConsultationFuture from '../components/ConsultationFuture';
import ConsultationPast from '../components/ConsultationPast';
import withSession from '../lib/withSession';
import { APPOINTMENTS, CANCEL_APPT, PRESCRIPTION, CURRENT_USER, APPT_SUMMARY, CANCEL_APPT_MENO } from '../graphql';
import withAnalytics from '../lib/withAnalytics';
import { isToggleActive } from '../components/featureToggle/toggles';
import { CheckRadio } from './mySubscriptions/components/CheckRadio';
import FullLayout2 from '../components/FullLayout';
import PleaseWait from '../components/PleaseWait';
import Header from '../components/Header';
import { sleep } from '../utils/sleep';

const InfoBox = styled(Flex)`
  flex: 1;
  background: ${(props) => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${(props) => props.theme.color.darkBlue};
  justify-content: center;
`;
const A = styled(Text)`
  text-decoration: underline;
  margin-top: 28px;
  text-align: center;
  cursor: pointer;
`;

const DetailNavigation = styled.div`
  flex: 1;
  margin-bottom: 36px;
  margin-left: -16px;
  margin-top: -16px;
  @media screen and (min-width: 68.8125em) {
    display: none;
    margin-bottom: 0px;
    margin-left: 0;
    margin-top: 0;
  }
`;

const WrapperFlex = styled(Flex)`
  padding: 16px;
  flex-direction: column;
  /* margin-bottom: 64px; */
  padding-bottom: 128px;
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const BackButton = styled.div`
  background: rgba(180, 205, 244, 0.5);
  width: 100%;
  color: #333;
  padding: 6px 12px;

  @media screen and (min-width: 68.8125em) {
    display: none;
  }
`;
const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
  padding-top: 64px;
`;

const initialState = {
  prompt: false,
  promptReason: false,
  loading: false,
  cancelling: false,
  ready: false,
  appointment: {},
  prescription: '',
  summary: '',
};

class ConsultationDetailInner extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = initialState;
  }

  handleCancel = async (id, reason) => {
    const { client, history, toastManager } = this.props;
    const { appointment, prescription, summary, ready, cancelling } = this.state;
    let mut = CANCEL_APPT;
    this.setState({ loading: true, cancelling: true });
    if (appointment &&
      appointment.appointmentTypeId &&
      (appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID.toString() ||
        appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID.toString())
    ) {
      mut = CANCEL_APPT_MENO;
    }
    try {
      await client.mutate({
        mutation: mut,
        variables: {
          id,
        },
        refetchQueries: [{ query: APPOINTMENTS }, { query: CURRENT_USER }],
        awaitRefetchQueries: true,
      });
      const hasNewPriceFeature = isToggleActive('USE_NEW_PRICING');
      this.props.analytics.logCancel(
        hasNewPriceFeature ? -110 : -99,
        hasNewPriceFeature ? -110 : -99,
        0,
        '',
        [
          {
            productId: 'Appointment Cancel',
            name: 'Appointment',
            price: hasNewPriceFeature ? -110 : -99,
            quantity: 1,
            category: 'Appointment',
          },
        ],
        '',
        null,
        reason
      );
      this.setState({ cancelling: false }, async () => {
        // console.log('SHOU:D B ')
        // await sleep(300)
      });
      history.push('/consultations');
    } catch (err) {
      if (err.toString().indexOf('cannot be canceled at this time') > -1) {
        toastManager.add('contact us to cancel a same day appointment', {
          appearance: 'error',
        });
        if (window.Beacon) {
          window.Beacon('open');
        }
      }
      if (err.toString().indexOf('is too late') > -1) {
        toastManager.add('It is too late to cancel this appointment.', {
          appearance: 'error',
        });
      } else {
        toastManager.add('Unable to cancel appointment', {
          appearance: 'error',
        });
      }
      this.setState({ loading: false, cancelling: false });
    }
  };

  componentDidMount = async () => {
    const { appointments, match, client, toastManager, bypassPatientId } = this.props;
    const appointment = appointments.find((item) => {
      if (bypassPatientId) {
        return item.appointmentId.toString() === match.params.id.toString();
      }
      return item.id === match.params.id;
    });

    if (!appointment) return;

    try {
      if (appointment.status === '3' || appointment.status === '4') {
        const { data } = await client.query({
          query: PRESCRIPTION,
          fetchPolicy: 'network-only',
          variables: {
            id: appointment.id,
            bypassPatientId: bypassPatientId ? appointment.patientId : null,
          },
        });

        const response = await client.query({
          query: APPT_SUMMARY,
          fetchPolicy: 'network-only',
          variables: {
            id: appointment.id,
          },
        });

        this.setState({
          appointment,
          prescription: data.prescription,
          summary: response.data.appointmentSummary,
          ready: true,
        });
      } else {
        this.setState({ appointment, ready: true });
      }
    } catch (error) {
      console.log('ERROR FETCH', error);
      this.setState({ ready: true });
      toastManager.add('Unable to fetch consultation, try again!', {
        appearance: 'error',
      });
    }
  };

  handleModal = () => {
    this.setState({ prompt: !this.state.prompt });
  };

  render() {
    const { appointments, history, session, bypassPatientId } = this.props;

    const { appointment, prescription, summary, ready, cancelling } = this.state;

    if (!appointments || !appointments.length || !appointment) {
      return null;
    }

    if (!ready) {
      return (
        <Flex flex={1} justifyContent="center" alignItems="center">
          <SpinLoader />
        </Flex>
      );
    }
    if (cancelling) {
      return (
        <FullLayout2 style={{ overflow: 'auto', zIndex: 10 }}>
          <Container>
            <Flex flex={1}>
              <Header dark={false} />
            </Flex>
            <PleaseWait
              text="Visit cancellation..."
              spinner={{
                sColor: '#ECEFF2',
                pColor: '#909FB5',
              }}
            />
          </Container>
        </FullLayout2>
      );
    }

    let tiz;
    try {
      tiz = moment(`${appointment.date} ${appointment.startTime}`, 'MM/DD/YYYY HH:mm')
        .tz(appointment.timezoneName)
        .zoneAbbr();
    } catch (error) {}
    return (
      <Fragment>
        <WrapperFlex>
          <DetailNavigation mb={4} mt={3}>
            <BackButton onClick={() => history.push('/consultations')}>{`<  Your Visits`}</BackButton>
          </DetailNavigation>
          {appointment.appointmentTypeId && (
            <>
              {appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_CONSULT30_APPOINTMENT_TYPE_ID.toString() ||
              appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MEMBER_CONSULT30_APPOINTMENT_TYPE_ID.toString() ||
              appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_RETURNVISIT30_APPOINTMENT_TYPE_ID.toString() ||
              appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MEMBER_FOLLOWUP15_APPOINTMENT_TYPE_ID.toString() ||
              appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_FOLLOWUP15_APPOINTMENT_TYPE_ID.toString() ||
              appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_FUVISIT15_APPOINTMENT_TYPE_ID.toString() ||
              appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_NEWVISIT30_APPOINTMENT_TYPE_ID.toString() ||
              appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MEMBER_FOLLOWUP30_APPOINTMENT_TYPE_ID.toString() ||
              appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_FUVISIT30_APPOINTMENT_TYPE_ID.toString() ||
              appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_B2B_CONSULT30_APPOINTMENT_TYPE_ID.toString() ||
              appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_B2B_FOLLOWUP15_APPOINTMENT_TYPE_ID.toString() ||
              appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_B2B_FOLLOWUP30_APPOINTMENT_TYPE_ID.toString() ? (
                <Heading mb={3} textAlign={['center', 'center', 'center']}>
                  Thyroid Visit
                </Heading>
              ) : appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID.toString() ||
              appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID.toString() ? (
                <Heading mb={3} textAlign={['center', 'center', 'center']}>
                  Menopause Visit
                </Heading>
              ) : null}
            </>
          )}
          {(appointment.status === 'f' || appointment.status === '2' || bypassPatientId) && (
            <ConsultationFuture
              appointments={appointments}
              appointment={appointment}
              history={history}
              intakeStatus={session.intakeStatus}
              menoIntakeStatus={session.menoIntakeStatus}
              intakeDate={session.intakeDate}
              menoIntakeDate={session.menoIntakeDate}
              onClick={this.handleModal}
              perpetuousPhysicianConversationId={session.perpetuousPhysicianConversationId}
            />
          )}

          {/* TODO: Handle appointment status 2 */}

          {(appointment.status === '3' || appointment.status === '4') && (
            <ConsultationPast
              appointment={appointment}
              prescription={prescription}
              summary={summary}
              session={session}
              history={this.props.history}
              isAsync={
                appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID ||
                appointment.appointmentTypeId.toString() === window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID
              }
            />
          )}
        </WrapperFlex>

        <ConfirmModal
          isOpen={this.state.prompt}
          onClose={this.handleModal}
          onBackgroundClick={this.handleModal}
          onConfirm={() => {
            this.setState({ prompt: false, promptReason: true });
          }}
          cancelLabel="Go Back"
        >
          <ModalInner>
            <Heading mb={4} textAlign="center">
              Confirm Appointment Cancellation
            </Heading>
            {!this.state.loading && (
              <InfoBox bg="#D9E6F9" mb={4}>
                <Text fontSize={3} fontWeight={700} textAlign="center">
                  {`${moment(appointment.startTime, 'HH:mm').format('h:mma')}${tiz} on ${moment(appointment.date, 'MM/DD/YYYY').format('dddd MMMM Do')}`}
                </Text>
              </InfoBox>
            )}

            {this.state.loading && (
              <Flex justifyContent="center" alignItems="center" pb={3}>
                <SpinLoader />
              </Flex>
            )}
          </ModalInner>
        </ConfirmModal>

        <ConfirmModal
          isOpen={this.state.promptReason}
          onClose={() => {
            this.handleCancel(appointment.id);
          }}
          onBackgroundClick={this.handleModal}
          onConfirm={() => {
            this.handleCancel(appointment.id);
            // this.setState({ promptReason: false });
          }}
          childrenManaged
          cardStyle={{
            maxHeight: '100vh',
            overflow: 'auto',
          }}
          // cancelLabel="Skip"
        >
          <ModalInner>
            <ReasonModalContent
              onClose={() => {
                this.handleCancel(appointment.id);
                // this.setState({ promptReason: false });
              }}
              onSubmit={(reason) => {
                this.handleCancel(appointment.id, reason);
                // this.setState({ promptReason: false });
              }}
            />
          </ModalInner>
        </ConfirmModal>
      </Fragment>
    );
  }
}

const rs = [
  {
    label: `I am waiting for lab results`,
    isChecked: false,
  },
  {
    label: `I found another doctor / in-person`,
    isChecked: false,
  },
  {
    label: `I have a scheduling conflict`,
    isChecked: false,
  },
  {
    label: `Appointment too far out`,
    isChecked: false,
  },
  {
    label: `My insurance is not accepted`,
    isChecked: false,
  },
  {
    label: `Too expensive`,
    isChecked: false,
  },
  {
    label: `The intake form is too long`,
    isChecked: false,
  },
  {
    label: `Other`,
    isChecked: false,
  },
];

const ReasonModalContent = ({ onClose, onSubmit }) => {
  const [reasons, setReasons] = useState(rs);
  return (
    <Flex flexDirection="column">
      <Heading mb={4} textAlign="center">
        Would you mind sharing the reason for your cancellation?
      </Heading>
      <Flex
        flexDirection="column"
        style={{
          margin: '0 auto',
          display: 'table',
        }}
      >
        {reasons.map((x, ind) => (
          <CheckRadio
            isChecked={x.isChecked}
            key={x.label}
            onClick={() => {
              const r = JSON.parse(JSON.stringify(reasons));
              r[ind].isChecked = !x.isChecked;
              setReasons(r);
            }}
          >
            {x.label}
          </CheckRadio>
        ))}
      </Flex>
      <A
        onClick={() => {
          onSubmit(
            reasons
              .filter((x) => !!x.isChecked)
              .map((x) => x.label)
              .join(', ')
          );
        }}
      >
        Submit
      </A>
      <A
        onClick={() => {
          onClose();
        }}
      >
        Skip
      </A>
    </Flex>
  );
};

const ConsultationDetail = (props) => {
  return (
    <Query query={CURRENT_USER} fetchPolicy={'network-only'}>
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <Flex flex={1} justifyContent="center" alignItems="center">
              <SpinLoader />
            </Flex>
          );
        }
        return <ConsultationDetailInner session={data.currentUser} {...props} />;
      }}
    </Query>
  );
};

export default compose(
  withRouter,
  withApollo,
  withToastManager,
  withAnalytics
)(ConsultationDetail);
