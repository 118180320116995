import React from 'react';
import { Button, Flex, Text } from 'rebass';
import styled from 'styled-components';
import withAnalytics from '../../../lib/withAnalytics';
import { Hearts } from '../../YearlyReview/icons/Hearts';
import { CDescription, CText } from '../../baselineSurvey/pages/Components/Texts';

export const CompletedBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <svg width="28%" viewBox="0 0 173 78" style={{ position: 'absolute', left: '4%', top: 0 }} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M49.9048 71.1992C38.9051 64.6857 30.8188 55.7089 23.7252 45.8766C6.63073 22.1837 -0.56755 -4.38906 1.09384 -33.4836C1.43151 -39.3979 1.18593 -45.3523 1.03635 -51.2833C0.771379 -61.8894 2.75107 -71.9872 8.71198 -80.8762C15.4961 -90.9922 24.538 -98.8716 35.1511 -104.871C55.8893 -116.592 77.9952 -122.427 101.952 -119.836C110.436 -118.92 118.245 -115.805 126.066 -112.549C137.691 -107.712 147.27 -100.124 155.962 -91.3526C161.336 -85.9292 164.18 -78.921 166.043 -71.6459C173.307 -43.2492 174.892 -14.5391 169.837 14.398C168.921 19.6442 167.747 25.2652 165.006 29.6804C160.809 36.1925 155.915 42.2328 150.409 47.695C141.256 57.0615 129.844 63.1378 117.652 67.7181C104.629 72.6083 91.4413 77.003 77.3688 77.5575C67.5215 77.9428 58.3349 75.2473 49.9048 71.1992Z"
          fill="#FFE3D8"
        />
      </svg>
      <svg width="40%" viewBox="0 0 248 139" fill="none" style={{ position: 'absolute', right: 0, top: 0 }} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M200.74 -230.298C242.719 -218.758 279.72 -199.493 311.314 -169.877C320.618 -160.963 330.367 -152.522 340.524 -144.586C379.511 -114.817 388.422 -74.07 381.542 -29.1901C374.257 18.3274 354.126 60.6319 318.433 93.6943C284.481 125.141 242.639 140.768 196.689 138.703C177.414 137.837 157.46 127.677 139.817 118.074C100.474 96.6657 67.0142 67.5078 35.9365 35.1844C5.84994 3.87881 -2.61174 -33.1305 2.14837 -73.9099C6.99672 -115.478 22.8071 -153.604 46.1023 -188.058C68.7383 -221.536 102.579 -235.469 142.422 -234.798C161.876 -234.476 181.303 -231.872 200.74 -230.298Z"
          fill="#4E658A"
        />
      </svg>

      {/* <Hearts style={{ position: 'absolute', left: '25%', top: '50px' }} /> */}
      <svg width="25%" style={{ position: 'absolute', bottom: '7%', right: 0 }} viewBox="0 0 266 374" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M200.073 4.70219C242.053 16.2424 279.054 35.5073 310.648 65.1231C319.951 74.0373 329.7 82.4782 339.857 90.4137C378.844 120.183 387.755 160.93 380.876 205.81C373.591 253.327 353.459 295.632 317.767 328.694C283.814 360.141 241.972 375.768 196.022 373.703C176.748 372.837 156.794 362.677 139.15 353.074C99.8071 331.666 66.3476 302.508 35.2698 270.184C5.18332 238.879 -3.27836 201.87 1.48174 161.09C6.3301 119.522 22.1405 81.3962 45.4357 46.9416C68.0716 13.4636 101.912 -0.469154 141.755 0.201581C161.209 0.523621 180.636 3.12784 200.073 4.70219Z"
          fill="#E9F3FF"
        />
      </svg>

      <svg width={'100%'} style={{ position: 'absolute', bottom: '5.5%', left: 0, right: 0 }} viewBox="0 0 625 342" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M-35 340C-7.27642 289.155 23.1301 208.131 51.748 187.465C88.0581 161.244 99.1463 248.479 150.122 244.543C201.098 240.606 193.943 103.94 240.447 99.8809C305.708 94.1839 298.468 285.885 360.312 244.543C430.781 197.436 399.634 -1.93695 459.553 3.43972C525.765 9.38111 524.837 146.133 580.285 135.308C602.304 131.01 625 95.9445 625 95.9445"
          stroke="#7992B3"
          stroke-width="5"
        />
      </svg>
      <svg width="17%" style={{ position: 'absolute', bottom: '0', left: '15%' }} viewBox="0 0 159 109" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M150.227 111.113C143.69 119.146 135.529 124.549 126.801 129.054C105.769 139.912 83.6151 142.495 60.5027 137.677C55.8045 136.698 51.0042 136.181 46.2342 135.59C37.7044 134.532 29.851 131.735 23.4432 125.894C16.1509 119.246 10.9212 111.057 7.38601 101.835C0.47962 83.8141 -1.54613 65.4029 3.40036 46.5187C5.15149 39.8305 8.58263 33.9468 12.129 28.0705C17.3975 19.3363 24.6253 12.5707 32.6947 6.65729C37.684 3.00153 43.6401 1.56238 49.6923 0.941725C73.3151 -1.47538 96.5086 0.694925 119.088 8.21223C123.182 9.57529 127.545 11.1898 130.754 13.9146C135.469 18.0578 139.722 22.7031 143.439 27.7687C149.847 36.225 153.348 46.0972 155.556 56.4147C157.914 67.435 159.855 78.5279 158.613 89.8697C157.741 97.8059 154.481 104.843 150.227 111.113Z"
          fill="#FFE3D8"
        />
      </svg>
    </Flex>
  );
};

const CompletedInner = ({onRestart, analytics}) => {
  return (
    <Flex style={{ alignItems: 'center', justifyContent: 'center', height: '100%', zIndex: 100, flexDirection: 'column' }}>
      <CText style={{ fontWeight: 700, textAlign: 'center' }}>Thank You!</CText>

      <CDescription style={{ textAlign: 'center', marginBottom: window.matchMedia('(min-width: 52em)').matches ? 36 : 22 }} mt={5}>For trusting us with your thyroid health. Remember,  you're just at the beginning of your new health journey!</CDescription>
      <Button variant={'primary'} onClick={(e)=>{
        e.preventDefault()
        console.log('OnRestart')
        analytics.track('Followup Outro Replay')
        onRestart()
      }}>Replay</Button>
    </Flex>
  );
};

export const Completed = withAnalytics(CompletedInner)