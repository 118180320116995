import React, {  } from 'react';
import { Button, Card, Flex, Heading } from 'rebass';
import { withWizard } from 'react-albus';

const Relationship = ({ values, onSubmit, wizard }) => {
  return (
    <Flex flexDirection="column" width={[1, 1, 1 / 2]} style={{ margin: '0 auto' }} alignSelf="center">
      <Card mb={4} p={4}>
        <Flex mb={4} px={2} justifyContent="center" alignItems={'center'} flexDirection="column">
          <Heading textAlign="center" mb={3}>
            Who provides your free Paloma Membership?
          </Heading>

          <Button
            variant={'primary'}
            mt={4}
            onClick={() => {
              onSubmit(
                {
                  ...values,
                  relationship: 'self',
                },
                null,
                wizard
              );
            }}
            type="button"
          >
            My employer or health plan
          </Button>
          <Button
            variant={'pink'}
            mt={3}
            onClick={() => {
              onSubmit(
                {
                  ...values,
                  relationship: 'partner',
                },
                null,
                wizard
              );
            }}
            type="button"
          >
            A partner, parent or someone else
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
};

export default withWizard(Relationship);
