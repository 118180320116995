import React, { useState } from 'react';
import { Box, Card, Flex, Heading, Button, Text } from 'rebass';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import TextInput from '../../components/TextInput';
import { MaskInput } from '../../components/MaskInput';
import withSession from '../../lib/withSession';
import { compose, Mutation, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import { ADMIN_GET_HEALTH_COACHES_LIST, CREATE_HEALTHCOACH_USER } from '../../graphql';
import Modal from 'styled-react-modal';
import { SpecialModalBackground } from './../adminReorderPhysicianConvo/shared';

const CardBox = styled(Card)`
  width: 100%;
  padding: 32px;
`;
const Container = styled(Box)`
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
  padding-top: 20px;
`;

const phoneRegExp = /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .trim()
    .min(8, 'Password must be greater than 8 characters')
    .required('Required'),
  displayName: Yup.string().required('Required'),
  phone: Yup.string()
    .trim()
    .required('Required')
    .matches(phoneRegExp, 'Phone number is not valid'),
});

const AddNewHealthCoachUser = ({ toastManager, selectedCalendlyUser, setSelectedCalendlyUser, setCalendlyUsers }) => {
  const [foundDuplicateCalendlyUser, setFoundDuplicateCalendlyUser] = useState(false);

  return (
    <Flex flexDirection="column" style={{overflowY: 'auto'}}>
      <Box>
        <Heading textAlign="left" mb={4}>
          Create Health Coach User
        </Heading>
        <Mutation
          mutation={CREATE_HEALTHCOACH_USER}
          refetchQueries={[{ query: ADMIN_GET_HEALTH_COACHES_LIST }]}
          awaitRefetchQueries={true}
          onCompleted={(data) => {
            setFoundDuplicateCalendlyUser(false);
            if (!data || !data.adminCreateHealthCoachUser || !data.adminCreateHealthCoachUser.ok) {
              if (((data || {}).adminCreateHealthCoachUser || {}).customError === 'CalendlyAlreadyExists') {
                setFoundDuplicateCalendlyUser(true);
                return;
              }
              toastManager.add(((data || {}).adminCreateHealthCoachUser || {}).customError || 'An error occured', {
                appearance: 'error',
              });
              return;
            }
            toastManager.add('Health coach created', {
              appearance: 'success',
            });
            if (setCalendlyUsers) {
              setCalendlyUsers(null);
            }
            setSelectedCalendlyUser(null);
          }}
          onError={() => {
            setFoundDuplicateCalendlyUser(false);
            toastManager.add('An error occured', {
              appearance: 'error',
            });
          }}
        >
          {(create, { loading }) => (
            <>
              <Formik
                initialValues={{
                  email: selectedCalendlyUser.email,
                  password: '',
                  helpscoutId: null,
                  phone: '',
                  isActive: true,
                  displayName: selectedCalendlyUser.name,
                  mentionTag: `@${(selectedCalendlyUser.name || '').split(' ').length > -1 ? (selectedCalendlyUser.name || '').split(' ')[0] : ''}`,
                  calendlyUserId: selectedCalendlyUser.uri.replace('https://api.calendly.com/users/', ''),
                  calendlyEmail: selectedCalendlyUser.email,
                }}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                  const hid = parseInt(values.helpscoutId, 10);
                  console.log({
                    hid,
                    t: typeof hid,
                  });
                  create({
                    variables: {
                      email: values.email,
                      password: values.password,
                      helpscoutId: hid,
                      phone: values.phone,
                      displayName: values.displayName,
                      mentionTag: values.mentionTag,
                      calendlyUserId: values.calendlyUserId,
                      calendlyEmail: values.calendlyEmail,
                    },
                  });
                }}
                render={({ touched, errors, isValid, values, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
                  <Form>
                    <Flex>
                      <img src={selectedCalendlyUser.avatarUrl} width={'64px'} height={'64px'} />
                      <Flex flexDirection={'column'} ml={3} flex={1}>
                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Display name:
                        </Text>
                        <FormField mb={4} error={touched.email && errors.email}>
                          <TextInput placeholder="Display Name" name="displayName" />
                        </FormField>
                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Authentication Email:
                        </Text>
                        <FormField mb={3} error={touched.email && errors.email}>
                          <TextInput placeholder="Authentication Email" type="email" name="email" />
                        </FormField>
                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Password:
                        </Text>
                        <FormField mb={3} error={touched.password && errors.password}>
                          <TextInput placeholder="Password" type="password" name="password" />
                        </FormField>
                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Phone:
                        </Text>
                        <FormField mb={4} error={touched.phone && errors.phone}>
                          <MaskInput name="phone" placeholder="Phone Number" mask="999-999-9999" value={values.phone} onChange={handleChange} onBlur={handleBlur} />
                        </FormField>
                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Helpscout ID:
                        </Text>
                        <FormField mb={3} error={touched.helpscoutId && errors.helpscoutId}>
                          <TextInput placeholder="Helpscout ID" type="number" name="helpscoutId" />
                        </FormField>
                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Mention Tag (must start with @):
                        </Text>
                        <FormField mb={3} error={touched.mentionTag && errors.mentionTag}>
                          <TextInput placeholder="Mention Tag" name="mentionTag" />
                        </FormField>

                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Calendly User ID:
                        </Text>
                        <FormField mb={3} error={touched.calendlyUserId && errors.calendlyUserId}>
                          <TextInput name="calendlyUserId" disabled />
                        </FormField>
                        <Text fontSize={12} style={{ fontWeight: 300 }}>
                          Calendly User Email:
                        </Text>
                        <FormField mb={3} error={touched.calendlyEmail && errors.calendlyEmail}>
                          <TextInput name="calendlyEmail" disabled />
                        </FormField>

                        <Flex alignItems="center" justifyContent={'center'} flexDirection="row" mt={4}>
                          <Button mb={4} variant="pink" width="200px" type="button" onClick={() => setSelectedCalendlyUser(null)}>
                            Cancel
                          </Button>
                          <Button mb={4} disabled={!isValid || !!loading} variant="primary" width="200px" type="submit">
                            Create
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                    {!!foundDuplicateCalendlyUser && (
                      <Modal
                        isOpen={true}
                        onBackgroundClick={() => {
                          setFoundDuplicateCalendlyUser(false);
                        }}
                        onEscapeKeydown={() => {
                          setFoundDuplicateCalendlyUser(false);
                        }}
                      >
                        <SpecialModalBackground>
                          <Box
                            style={{
                              margin: '0 auto',
                              alignSelf: 'center',
                              borderRadius: '20px',
                              position: 'relative',
                              backgroundColor: 'white',
                              padding: '24px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'column',
                              maxWidth: '95vw',
                              minWidth: 'initial',
                              maxHeight: '95vh',
                            }}
                          >
                            <Flex flex={1} flexDirection={'column'}>
                              <Heading textAlign={'center'}>Warning</Heading>
                              <Text mt={3} textAlign={'center'}>
                                A health coach with this Calendly ID already exists. Do you want to proceed with creating this new user?
                              </Text>
                              <Flex mt={4} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                                <Flex flex={1} alignItems={'center'} justifyContent={'center'}>
                                  <Button
                                    variant={'outline'}
                                    style={{
                                      alignSelf: 'center',
                                      marginRight: '12px',
                                      width: 'auto',
                                    }}
                                    onClick={() => {
                                      setFoundDuplicateCalendlyUser(false);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Flex>

                                <Button
                                  variant={'pink'}
                                  style={{
                                    alignSelf: 'center',
                                  }}
                                  disabled={!isValid || !!loading}
                                  onClick={() => {
                                    create({
                                      variables: {
                                        email: values.email,
                                        password: values.password,
                                        helpscoutId: values.helpscoutId,
                                        phone: values.phone,
                                        displayName: values.displayName,
                                        mentionTag: values.mentionTag,
                                        calendlyUserId: values.calendlyUserId,
                                        calendlyEmail: values.calendlyEmail,
                                        forceDuplicateCalendly: true,
                                      },
                                    });
                                  }}
                                >
                                  Continue
                                </Button>
                              </Flex>
                            </Flex>
                          </Box>
                        </SpecialModalBackground>
                      </Modal>
                    )}
                  </Form>
                )}
              />
            </>
          )}
        </Mutation>
      </Box>
    </Flex>
  );
};

export default compose(
  withApollo,
  withSession,
  withToastManager
)(AddNewHealthCoachUser);
