import React from 'react';
import IconContainer from './IconContainer';

const Question = (props) => (
  <IconContainer {...props}>
    <svg height={props.height || '30px'} width={props.width || '30px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.07 12.85C11.84 11.46 13.32 10.64 14.18 9.41C15.09 8.12 14.58 5.71 12 5.71C10.31 5.71 9.48004 6.99 9.13004 8.05L6.54004 6.96C7.25004 4.83 9.18004 3 11.99 3C14.34 3 15.95 4.07 16.77 5.41C17.47 6.56 17.88 8.71 16.8 10.31C15.6 12.08 14.45 12.62 13.83 13.76C13.58 14.22 13.48 14.52 13.48 16H10.59C10.58 15.22 10.46 13.95 11.07 12.85ZM14 20C14 21.1 13.1 22 12 22C10.9 22 10 21.1 10 20C10 18.9 10.9 18 12 18C13.1 18 14 18.9 14 20Z"
        fill={props.fill || '#fff'}
      />
    </svg>
  </IconContainer>
);

export default Question;
