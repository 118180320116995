import React from 'react';
import { Flex } from 'rebass';

const FormerBackground = ({ questionsLength, preIndex }) => {
  const { innerHeight: height, innerWidth: width } = window;
  return (
    <Flex
      style={{
        top: 0,
        left: 0,
        right: 0,
        height: `${height * questionsLength}px`,
        position: 'absolute',
        transform: `translateY(${-preIndex * height * 0.5}px)`,
        transition: '1s all ease-in-out',
        zIndex: 0,
      }}
    >
      <svg width="267" height="441" style={{ position: 'absolute', top: 40, right: 0 }} viewBox="0 0 267 441" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M235.557 6.18981C284.85 19.7572 328.298 42.4063 365.396 77.2245C376.32 87.7046 387.768 97.6285 399.695 106.958C445.474 141.956 455.937 189.861 447.859 242.625C439.305 298.49 415.666 348.226 373.755 387.096C333.887 424.067 284.756 442.44 230.8 440.012C208.168 438.993 184.738 427.048 164.02 415.759C117.823 390.59 78.534 356.31 42.0419 318.308C6.71374 281.503 -3.22214 237.993 2.36727 190.05C8.06031 141.18 26.6252 96.3564 53.9789 55.8492C80.5584 16.4903 120.295 0.110054 167.079 0.898606C189.923 1.27721 212.734 4.33891 235.557 6.18981Z"
          fill="#FBE2D9"
        />
      </svg>
      <svg
        width="180"
        height="161"
        style={{ position: 'absolute', top: height * 0.23, left: width * 0.3, transform: 'scaleX(-1)' }}
        viewBox="0 0 180 161"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M56.8417 2.68331C76.8025 -1.30414 96.3092 -1.02316 115.735 4.71219C121.496 6.48182 127.344 7.96043 133.255 9.14237C155.801 13.3912 168.095 28.5302 174.663 48.7057C181.617 70.0673 182.109 91.9471 174.157 113.233C166.592 133.48 152.404 148.778 132.779 157.553C124.546 161.234 114.075 161.178 104.686 160.87C83.75 160.185 63.6413 155.032 43.8615 148.059C24.7097 141.302 13.3897 127.629 6.79883 109.609C0.0788997 91.2421 -1.33855 72.0125 1.14245 52.7456C3.55356 34.0246 14.7561 21.1119 31.538 13.0356C39.731 9.08994 48.3925 6.10265 56.8417 2.68331Z"
          fill="#C7DDFC"
        />
      </svg>

      <svg width="267" height="441" style={{ position: 'absolute', top: height, left: 0, transform: 'scaleX(-1)' }} viewBox="0 0 267 441" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M235.557 6.18981C284.85 19.7572 328.298 42.4063 365.396 77.2245C376.32 87.7046 387.768 97.6285 399.695 106.958C445.474 141.956 455.937 189.861 447.859 242.625C439.305 298.49 415.666 348.226 373.755 387.096C333.887 424.067 284.756 442.44 230.8 440.012C208.168 438.993 184.738 427.048 164.02 415.759C117.823 390.59 78.534 356.31 42.0419 318.308C6.71374 281.503 -3.22214 237.993 2.36727 190.05C8.06031 141.18 26.6252 96.3564 53.9789 55.8492C80.5584 16.4903 120.295 0.110054 167.079 0.898606C189.923 1.27721 212.734 4.33891 235.557 6.18981Z"
          fill="#FBE2D9"
        />
      </svg>
      <svg
        width="180"
        height="161"
        style={{ position: 'absolute', top: height * 1.15, left: width * 0.75, transform: 'scaleX(-1)' }}
        viewBox="0 0 180 161"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M56.8417 2.68331C76.8025 -1.30414 96.3092 -1.02316 115.735 4.71219C121.496 6.48182 127.344 7.96043 133.255 9.14237C155.801 13.3912 168.095 28.5302 174.663 48.7057C181.617 70.0673 182.109 91.9471 174.157 113.233C166.592 133.48 152.404 148.778 132.779 157.553C124.546 161.234 114.075 161.178 104.686 160.87C83.75 160.185 63.6413 155.032 43.8615 148.059C24.7097 141.302 13.3897 127.629 6.79883 109.609C0.0788997 91.2421 -1.33855 72.0125 1.14245 52.7456C3.55356 34.0246 14.7561 21.1119 31.538 13.0356C39.731 9.08994 48.3925 6.10265 56.8417 2.68331Z"
          fill="#C7DDFC"
        />
      </svg>

      <svg width="267" height="441" style={{ position: 'absolute', top: height * 2.27, right: 0 }} viewBox="0 0 267 441" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M235.557 6.18981C284.85 19.7572 328.298 42.4063 365.396 77.2245C376.32 87.7046 387.768 97.6285 399.695 106.958C445.474 141.956 455.937 189.861 447.859 242.625C439.305 298.49 415.666 348.226 373.755 387.096C333.887 424.067 284.756 442.44 230.8 440.012C208.168 438.993 184.738 427.048 164.02 415.759C117.823 390.59 78.534 356.31 42.0419 318.308C6.71374 281.503 -3.22214 237.993 2.36727 190.05C8.06031 141.18 26.6252 96.3564 53.9789 55.8492C80.5584 16.4903 120.295 0.110054 167.079 0.898606C189.923 1.27721 212.734 4.33891 235.557 6.18981Z"
          fill="#FBE2D9"
        />
      </svg>
      <svg
        width="180"
        height="161"
        style={{ position: 'absolute', top: height * 2.33, left: width * 0.5, transform: 'scaleX(-1)' }}
        viewBox="0 0 180 161"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M56.8417 2.68331C76.8025 -1.30414 96.3092 -1.02316 115.735 4.71219C121.496 6.48182 127.344 7.96043 133.255 9.14237C155.801 13.3912 168.095 28.5302 174.663 48.7057C181.617 70.0673 182.109 91.9471 174.157 113.233C166.592 133.48 152.404 148.778 132.779 157.553C124.546 161.234 114.075 161.178 104.686 160.87C83.75 160.185 63.6413 155.032 43.8615 148.059C24.7097 141.302 13.3897 127.629 6.79883 109.609C0.0788997 91.2421 -1.33855 72.0125 1.14245 52.7456C3.55356 34.0246 14.7561 21.1119 31.538 13.0356C39.731 9.08994 48.3925 6.10265 56.8417 2.68331Z"
          fill="#C7DDFC"
        />
      </svg>

      <svg width="267" height="441" style={{ position: 'absolute', top: height * 3.27, left: 0, transform: 'scaleX(-1)' }} viewBox="0 0 267 441" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M235.557 6.18981C284.85 19.7572 328.298 42.4063 365.396 77.2245C376.32 87.7046 387.768 97.6285 399.695 106.958C445.474 141.956 455.937 189.861 447.859 242.625C439.305 298.49 415.666 348.226 373.755 387.096C333.887 424.067 284.756 442.44 230.8 440.012C208.168 438.993 184.738 427.048 164.02 415.759C117.823 390.59 78.534 356.31 42.0419 318.308C6.71374 281.503 -3.22214 237.993 2.36727 190.05C8.06031 141.18 26.6252 96.3564 53.9789 55.8492C80.5584 16.4903 120.295 0.110054 167.079 0.898606C189.923 1.27721 212.734 4.33891 235.557 6.18981Z"
          fill="#FBE2D9"
        />
      </svg>
      <svg
        width="180"
        height="161"
        style={{ position: 'absolute', top: height * 3.33, left: width * 0.05, transform: 'scaleX(-1)' }}
        viewBox="0 0 180 161"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M56.8417 2.68331C76.8025 -1.30414 96.3092 -1.02316 115.735 4.71219C121.496 6.48182 127.344 7.96043 133.255 9.14237C155.801 13.3912 168.095 28.5302 174.663 48.7057C181.617 70.0673 182.109 91.9471 174.157 113.233C166.592 133.48 152.404 148.778 132.779 157.553C124.546 161.234 114.075 161.178 104.686 160.87C83.75 160.185 63.6413 155.032 43.8615 148.059C24.7097 141.302 13.3897 127.629 6.79883 109.609C0.0788997 91.2421 -1.33855 72.0125 1.14245 52.7456C3.55356 34.0246 14.7561 21.1119 31.538 13.0356C39.731 9.08994 48.3925 6.10265 56.8417 2.68331Z"
          fill="#C7DDFC"
        />
      </svg>
    </Flex>
  );
};

export default FormerBackground;
