import React from 'react';
import { Card, Box, Text, Flex, Button } from 'rebass';
import styled from 'styled-components';
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  FacebookMessengerIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';
import ScrollAnimation from 'react-animate-on-scroll';
import { Query, Mutation, withApollo } from 'react-apollo';

import { Input } from '../components/TextInput';
import {
  GET_MY_REFERRAL_INFORMATION,
  GENERATE_REFERRAL_LINK,
  REFERRAL_SEND_FRIEND_EMAIL,
} from '../graphql';
import LoadingScreen from '../components/LoadingScreen';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';
import ConfirmModal from '../components/ConfirmModal';
import ReferralsHistoryModalInner from './referral/referralsHistoryModalInner';
import { isToggleActive } from '../components/featureToggle/toggles';
import ShareImage from '../static/shareImage.png';
import ongoingAdvice from '../static/ongoingAdvice.png';
import nutritionistDashIcon from '../static/nutritionistDashIcon.png';
import friendBuyKit from '../static/friendBuyKit.png';
import blueThyroid from '../static/blueThyroid.png';
import pinkThyroid from '../static/pinkThyroid.png';

const Container = styled(Box)`
  max-width: calc(100vw - 32px);
  width: 1024px;
  margin: 0 auto;
  padding: 40px 16px;
`;

const BoxRight = styled(Box)`
  width: 330px;
  @media screen and (min-width: 52em) {
    -webkit-transition: width 0.5s; /* For Safari 3.1 to 6.0 */
    transition: width 0.5s;
    width: 330px;
    &.loaded {
      width: 330px;
    }
  }
`;

const ButtonHistory = styled(Button)`
  display: none;
  @media screen and (min-width: 52em) {
    display: block;
  }
`;

class ShowHide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExtended: false,
    };
  }

  render() {
    const { title, content } = this.props;
    const { isExtended } = this.state;
    return (
      <Flex flexDirection="column" mt={4} style={{ width: '100%' }}>
        <Flex
          flexDirection="row"
          style={{ cursor: 'pointer' }}
          alignItems="center"
          onClick={() => {
            this.setState({
              isExtended: !isExtended,
            });
          }}
        >
          <Text flex={1} fontSize="18px" fontWeight={600}>
            {title}
          </Text>
          <Text
            fontSize="32px"
            fontWeight={500}
            style={{
              transform: isExtended
                ? 'rotateX(0deg) rotateY(0deg) rotateZ(-45deg)'
                : null,
            }}
          >
            +
          </Text>
        </Flex>
        {isExtended && <Text>{content}</Text>}
      </Flex>
    );
  }
}

class Referral extends React.Component {
  // eslint-disable-next-line
  rEmail = new RegExp(/(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
  );

  constructor(props) {
    super(props);
    this.state = {
      isInputCopying: false,
      isHistoryOpened: false,
      friendsEmails: '',
      isSendingEmails: false,
    };
    this.linkRef = React.createRef();
  }

  copyLinkToClipboard = e => {
    try {
      const { toastManager } = this.props;
      this.setState({ isInputCopying: true }, () => {
        this.linkRef.current.select();
        document.execCommand('copy');
        
        this.setState({ isInputCopying: false });
        toastManager.add('Link copied to your clipboard!', {
          appearance: 'success',
        });
      });
    } catch (error) {
      this.setState({ isInputCopying: false });
    }
  };

  sendEmailFriend = async () => {
    const { friendsEmails } = this.state;
    const { client, toastManager } = this.props;

    this.setState({ isSendingEmails: true });

    await client.mutate({
      mutation: REFERRAL_SEND_FRIEND_EMAIL,
      variables: {
        email: friendsEmails,
      },
    });

    toastManager.add('Your friend will receive an email', {
      appearance: 'success',
    });

    this.setState({ friendsEmails: '', isSendingEmails: false });
  };

  render() {
    if (!isToggleActive('REFERRAL_PROGRAM')) {
      this.props.history.push('/');
    }
    const {
      isInputCopying,
      isHistoryOpened,
      friendsEmails,
      isSendingEmails,
    } = this.state;
    return (
      <Container>
        <Query query={GET_MY_REFERRAL_INFORMATION} fetchPolicy="network-only">
          {({ loading, data: { getMyReferralInformation }, error }) => {
            if (loading) {
              return <LoadingScreen loading />;
            }

            const shareUrl = getMyReferralInformation
              ? window._env_.REACT_APP_SENTRY_ENV === 'production'
                ? getMyReferralInformation.link
                : `https://preview-app.paloma.network/referral/${
                    getMyReferralInformation.coupon
                  }`
              : null;

            return (
              <Flex flexDirection="column" mb="80px">
                <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                  <Flex flexDirection={['column', 'row']}>
                    {/* <Card p={4}> */}
                    <Flex
                      flexDirection={['column', 'row']}
                      flex={1}
                      pr={['0', '50px']}
                      alignItems={['center', 'initial']}
                    >
                      <img src={ShareImage} width="100" height="77" alt="" />
                      <Flex
                        flexDirection={'column'}
                        pt="16px"
                        flex={1}
                        textAlign={['center', 'initial']}
                      >
                        <Text
                          fontSize="30px"
                          fontWeight={700}
                          fontFamily="Playfair Display"
                        >
                          Give $30, Get $30
                        </Text>

                        <Text mt={4}>
                          Invite Friends who are not on Paloma Health yet.
                          They’ll get $30 off their first at home thyroid kit
                          and once they complete their purchase, you will get a
                          $30 credit too.&nbsp;
                          <a
                            href="https://www.palomahealth.com/legal-doc/paloma-referral-program-terms-and-conditions"
                            target="_blank"
                          >
                            Terms Apply.
                          </a>
                        </Text>
                        {shareUrl ? (
                          <Flex flexDirection="column">
                            <Text mt={4} fontWeight={600} fontSize="16px">
                              Share your invite link
                            </Text>
                            <Flex
                              flexDirection={'row'}
                              mt="10px"
                              style={{
                                background: 'white',
                                boxShadow: '1px 1px 30px 0 #dad7d7',
                              }}
                            >
                              <Input
                                style={{
                                  border: '0px solid transparent',
                                  background: 'transparent',
                                }}
                                disabled={!isInputCopying}
                                value={shareUrl}
                                subref={this.linkRef}
                              />
                              <Button
                                variant="white"
                                onClick={this.copyLinkToClipboard}
                              >
                                <Flex flexDirection="row">
                                  <Text color="#344f79" ml={2}>
                                    Copy Link
                                  </Text>
                                </Flex>
                              </Button>
                            </Flex>
                            <Flex flexDirection="row" mt="24px" flexWrap="wrap">
                              <ScrollAnimation
                                animateIn="bounceIn"
                                animateOnce={true}
                                delay={0}
                              >
                                <EmailShareButton
                                  subject="Get $30 off an at-home thyroid test kit"
                                  url={''}
                                  separator=""
                                  body={`Hey!\n\nI just discovered Paloma Health. It's an online medical practice focused on hypothyroidism and they have this cool at-home thyroid test.\n\nDid you know that 60% of the people who suffer from thyroid disorders are never diagnosed !?\n\nThis link will give you $30 off your first purchase (${shareUrl}).\n\nJust use this link to buy your kit and your credit will be automatically applied. I will also get $30 credited to my account! Don’t guess, test!`}
                                  style={{ marginRight: '12px' }}
                                >
                                  <EmailIcon size={32} round />
                                </EmailShareButton>
                              </ScrollAnimation>
                              <ScrollAnimation
                                animateIn="bounceIn"
                                animateOnce={true}
                                delay={50}
                              >
                                <FacebookShareButton
                                  url={shareUrl}
                                  quote={`Get $30 off an at-home thyroid test kit with my referral link ${shareUrl}`}
                                  style={{ marginRight: '12px' }}
                                >
                                  <FacebookIcon size={32} round />
                                </FacebookShareButton>
                              </ScrollAnimation>
                              <ScrollAnimation
                                animateIn="bounceIn"
                                animateOnce={true}
                                delay={100}
                              >
                                <FacebookMessengerShareButton
                                  url={shareUrl}
                                  appId="534722467364054"
                                  quote={`Get $30 off an at-home thyroid test kit with my referral link ${shareUrl}`}
                                  style={{ marginRight: '12px' }}
                                >
                                  <FacebookMessengerIcon size={32} round />
                                </FacebookMessengerShareButton>
                              </ScrollAnimation>
                              <ScrollAnimation
                                animateIn="bounceIn"
                                animateOnce={true}
                                delay={150}
                              >
                                {/* <LinkedinShareButton
                              url={shareUrl}
                              source="https://palomahealth.com"
                              style={{ marginRight: '12px' }}
                            >
                              <LinkedinIcon size={32} round />
                            </LinkedinShareButton> */}
                                <PinterestShareButton
                                  url={shareUrl}
                                  quote={`Get $30 off an at-home thyroid test kit ${shareUrl}`}
                                  media="https://uploads-ssl.webflow.com/5c17fc782f30f90cd15c25b4/5c17fc782f30f9abb85c2618_kit%20copy%203%20copy.png"
                                  style={{ marginRight: '12px' }}
                                >
                                  <PinterestIcon size={32} round />
                                </PinterestShareButton>
                              </ScrollAnimation>
                              <ScrollAnimation
                                animateIn="bounceIn"
                                animateOnce={true}
                                delay={200}
                              >
                                {/* <RedditShareButton
                              url={shareUrl}
                              style={{ marginRight: '12px' }}
                            >
                              <RedditIcon size={32} round />
                            </RedditShareButton>
                            <TumblrShareButton
                              url={shareUrl}
                              style={{ marginRight: '12px' }}
                            >
                              <TumblrIcon size={32} round />
                            </TumblrShareButton> */}
                                <TwitterShareButton
                                  url={shareUrl}
                                  title={`I just discovered Paloma Health, an online medical practice for hypothyroidism. Click this link to receive a $30 discount on your first at-home thyroid test kit. Don’t guess, test! ${shareUrl}`}
                                  via="@healthpaloma"
                                  hashtags={[
                                    '#hypothyroidism',
                                    '#thyroid',
                                    '#hashimotos',
                                    '#palomahealth',
                                  ]}
                                  style={{ marginRight: '12px' }}
                                >
                                  <TwitterIcon size={32} round />
                                </TwitterShareButton>
                              </ScrollAnimation>
                              <ScrollAnimation
                                animateIn="bounceIn"
                                animateOnce={true}
                                delay={250}
                              >
                                <WhatsappShareButton
                                  url={shareUrl}
                                  quote={`If you use this link we both get $30 off an at-home thyroid test kit ${shareUrl}`}
                                  style={{ marginRight: '12px' }}
                                >
                                  <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                              </ScrollAnimation>
                            </Flex>

                            <Text mt={4} fontWeight={600} fontSize="16px">
                              Or enter your friends' email address
                            </Text>
                            <Flex
                              flexDirection={'row'}
                              mt="10px"
                              style={{
                                background: 'white',
                                boxShadow: '1px 1px 30px 0 #dad7d7',
                              }}
                            >
                              <Input
                                style={{
                                  border: '0px solid transparent',
                                  background: 'transparent',
                                }}
                                disabled={isSendingEmails}
                                onChange={e => {
                                  this.setState({
                                    friendsEmails: e.target.value,
                                  });
                                }}
                                value={friendsEmails}
                              />
                              <Button
                                variant="white2"
                                onClick={() => {
                                  this.sendEmailFriend();
                                }}
                                disabled={isSendingEmails ||
                                    !this.rEmail.test(friendsEmails)}
                              >
                                <Flex flexDirection="row">
                                  <Text color="#344f79" ml={2}>
                                    Send
                                  </Text>
                                </Flex>
                              </Button>
                            </Flex>
                          </Flex>
                        ) : (
                          <Flex flexDirection="column" alignItems="center">
                            <Mutation
                              mutation={GENERATE_REFERRAL_LINK}
                              refetchQueries={[
                                { query: GET_MY_REFERRAL_INFORMATION },
                              ]}
                            >
                              {(mutate, { loading }) => {
                                return (
                                  <Button
                                    variant="primary"
                                    mt={4}
                                    onClick={mutate}
                                  >
                                    Generate My Referral Link
                                  </Button>
                                );
                              }}
                            </Mutation>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                    {/* </Card> */}
                    {!!shareUrl && (
                      <BoxRight
                        className={shareUrl ? 'loaded' : ''}
                        mb={[4, 0]}
                        mt={[4, 0]}
                        flexDirection="column"
                        style={{
                          paddingLeft: '16px',
                          paddingRight: '16px',
                          width: '330px',
                        }}
                      >
                        <Box>
                          <Card p={4}>
                            <Flex flexDirection="column">
                              <Text fontSize="22px" fontWeight={600}>
                                Your Paloma Credits
                              </Text>
                              <Flex flexDirection={['row']} flex={1} mt={3}>
                                <Text flex={1}>Referrals</Text>
                                <Text>
                                  {getMyReferralInformation.referreeCount}
                                </Text>
                              </Flex>
                              <Flex flexDirection={['row']} flex={1} mt={3}>
                                <Text flex={1}>Collected credits</Text>
                                <Text>
                                  $
                                  {(
                                    getMyReferralInformation.centisizedCollectedCredits /
                                    100
                                  ).toFixed(2)}
                                </Text>
                              </Flex>
                              <Flex flexDirection={['row']} flex={1} mt={3}>
                                <Text flex={1}>Credits used</Text>
                                <Text>
                                  $
                                  {(
                                    getMyReferralInformation.centisizedUsedCredits /
                                    100
                                  ).toFixed(2)}
                                </Text>
                              </Flex>
                              <Flex flexDirection={['row']} flex={1} mt={3}>
                                <Text flex={1} fontWeight="bold">
                                  Available credits:
                                </Text>
                                <Text fontWeight="bold">
                                  $
                                  {(
                                    getMyReferralInformation.centisizedRemainingCredits /
                                    100
                                  ).toFixed(2)}
                                </Text>
                              </Flex>

                              <ButtonHistory
                                mt={4}
                                variant="pink"
                                onClick={() => {
                                  this.setState({ isHistoryOpened: true });
                                }}
                              >
                                View History
                              </ButtonHistory>
                            </Flex>
                          </Card>
                        </Box>
                      </BoxRight>
                    )}
                  </Flex>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    mt="80px"
                    style={{
                      backgroundImage: `url(${blueThyroid})`,
                      backgroundPosition: '100% 100%',
                      backgroundSize: '350px',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    <Text
                      fontSize="30px"
                      fontWeight={700}
                      mb="40px"
                      fontFamily="Playfair Display"
                    >
                      How It Works
                    </Text>

                    <ScrollAnimation
                      animateIn="fadeInLeft"
                      animateOnce={true}
                      delay={300}
                    >
                      <Box
                        mb={4}
                        style={{
                          background: 'white',
                          boxShadow: '0 0 50px 0 #d8d8d8',
                          padding: '29px 16px 29px 29px',
                          width: '420px',
                          maxWidth: 'calc(100vw - 50px)',
                        }}
                      >
                        <Flex flexDirection="row" alignItems="center">
                          <Text
                            fontSize="62px"
                            fontWeight={600}
                            ml="-43px"
                            fontFamily="Playfair Display"
                            mt="-20px"
                          >
                            1
                          </Text>
                          <div
                            style={{
                              width: '90px',
                              maxHeight: '60px',
                              margin: '0 24px',
                              textAlign: 'center',
                            }}
                          >
                            <img alt=''
                              src={ongoingAdvice}
                              style={{ width: '90px', maxHeight: '60px' }}
                            />
                          </div>
                          <Text flex={1} fontSize="24px" fontWeight={600}>
                            Share your unique link
                          </Text>
                        </Flex>
                      </Box>
                    </ScrollAnimation>
                    <ScrollAnimation
                      animateIn="fadeInRight"
                      animateOnce={true}
                      delay={500}
                    >
                      <Box
                        mb={4}
                        style={{
                          background: 'white',
                          boxShadow: '0 0 50px 0 #d8d8d8',
                          padding: '29px 16px 29px 29px',
                          width: '420px',
                          maxWidth: 'calc(100vw - 50px)',
                        }}
                      >
                        <Flex flexDirection="row" alignItems="center">
                          <Text
                            fontSize="62px"
                            fontWeight={600}
                            ml="-43px"
                            fontFamily="Playfair Display"
                            mt="-20px"
                          >
                            2
                          </Text>
                          <div
                            style={{
                              width: '90px',
                              maxHeight: '60px',
                              margin: '0 24px',
                              textAlign: 'center',
                            }}
                          >
                            <img alt='' src={friendBuyKit} style={{ width: '60px' }} />
                          </div>
                          <Text flex={1} fontSize="24px" fontWeight={600}>
                            Your friends buy a kit with a $30 discount
                          </Text>
                        </Flex>
                      </Box>
                    </ScrollAnimation>
                    <ScrollAnimation
                      animateIn="fadeInLeft"
                      animateOnce={true}
                      delay={700}
                    >
                      <Box
                        mb={4}
                        style={{
                          background: 'white',
                          boxShadow: '0 0 50px 0 #d8d8d8',
                          padding: '29px 16px 29px 29px',
                          width: '420px',
                          maxWidth: 'calc(100vw - 50px)',
                        }}
                      >
                        <Flex flexDirection="row" alignItems="center">
                          <Text
                            fontSize="62px"
                            fontWeight={600}
                            ml="-43px"
                            fontFamily="Playfair Display"
                            mt="-20px"
                          >
                            3
                          </Text>
                          <div
                            style={{
                              width: '90px',
                              maxHeight: '60px',
                              margin: '0 24px',
                              textAlign: 'center',
                            }}
                          >
                            <img alt=''
                              src={nutritionistDashIcon}
                              style={{ width: '60px', maxHeight: '60px' }}
                            />
                          </div>
                          <Text flex={1} fontSize="24px" fontWeight={600}>
                            We give you a $30 credit
                          </Text>
                        </Flex>
                      </Box>
                    </ScrollAnimation>
                  </Flex>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                  <div
                    style={{
                      flex: 1,
                      backgroundImage: `url(${pinkThyroid})`,
                      backgroundPosition: '0% 100%',
                      backgroundSize: '280px',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      width="600px"
                      style={{
                        margin: '80px auto',
                        maxWidth: 'calc(100% - 20px)',
                      }}
                    >
                      <Text
                        fontSize="30px"
                        fontWeight={700}
                        mb="40px"
                        fontFamily="Playfair Display"
                      >
                        FAQs
                      </Text>
                      <ShowHide
                        title="How can I check the status of my referrals?"
                        content="Just visit this page anytime to see a real-time picture of your referral progress."
                      />
                      <ShowHide
                        title="When will I receive my account credits?"
                        content="Once your friend purchase an at-home kit, you'll  instantly receive account credits."
                      />
                      <ShowHide
                        title="How can I use my account credits?"
                        content="Your account credits can only be used on our at home thyroid test kits. Your credit will be automatically applied at checkout."
                      />
                      <ShowHide
                        title="Is there a limit to how much account credit I can earn?"
                        content="Yes! $3000. Way to go!!!"
                      />
                      <ShowHide
                        title="Do account credits expire?"
                        content="Account credits do not expire"
                      />
                    </Flex>
                  </div>
                </ScrollAnimation>
              </Flex>
            );
          }}
        </Query>
        {isHistoryOpened && (
          <ConfirmModal
            childrenManaged
            confirmLabel="OK"
            isOpen={isHistoryOpened}
            okOnly
            displayX
            cardStyle={{
              maxHeight: '100vh',
              position: 'relative',
              overflowY: 'auto',
              width: '800px',
              maxWidth: '100%',
            }}
            onClose={() => {
              this.setState({ isHistoryOpened: false });
            }}
            onConfirm={() => {}}
            onBackgroundClick={() => {
              this.setState({ isHistoryOpened: false });
            }}
            onEscapeKeydown={() => {
              this.setState({ isHistoryOpened: false });
            }}
          >
            <ReferralsHistoryModalInner
              onClose={() => {
                this.setState({ isHistoryOpened: false });
              }}
            />
          </ConfirmModal>
        )}
      </Container>
    );
  }
}

export default withToastManager(withApollo(Referral));
