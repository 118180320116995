import gql from 'graphql-tag';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { compose, Query, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { withToastManager } from 'react-toast-notifications';
import { Flex, Heading, Text } from 'rebass';
import FullLayout2 from '../../components/FullLayout';
import Header from '../../components/Header';
import LoadingScreen from '../../components/LoadingScreen';
import { CURRENT_USER, GET_B2B_COMPANIES } from '../../graphql';
import withSession from '../../lib/withSession';
import B2bInformation from '../partnerSignup/b2bInformation';
import Extended from '../partnerSignup/Extended';
import Precheck from '../partnerSignup/Precheck';
import Relationship from '../partnerSignup/Relationship';
import SelectCompany from '../partnerSignup/SelectCompany';

const ADD_ELIGIBILITY_TO_ME = gql`
  mutation addEligibilityToMe(
    $companyId: String!
    $relationship: String!
    $employeeEmail: String!
    $employeeDob: DateTime!
    $employeeFirstName: String!
    $employeeLastName: String!
    $subscriberId: String
    $memberId: String
    $employeeId: String
    $uniqueCode: String
  ) {
    addEligibilityToMe(
      data: {
        companyId: $companyId
        relationship: $relationship
        employeeEmail: $employeeEmail
        employeeDob: $employeeDob
        employeeFirstName: $employeeFirstName
        employeeLastName: $employeeLastName
        subscriberId: $subscriberId
        memberId: $memberId
        employeeId: $employeeId
        uniqueCode: $uniqueCode
      }
    ) {
      ok
      reason
    }
  }
`;
const Inner = ({ companies, location, analytics, history, client, toastManager, values, setValues, setRegistering, selectedCompany, setSelectedCompany }) => {
  const [prevRoute, setPrevRoute] = useState((location || {}).pathname);

  useEffect(() => {
    if ((location || {}).pathname !== prevRoute) {
      let tag = '';

      if (location.pathname.indexOf('basic') > -1) {
        tag = 'basic';
      } else if (location.pathname.indexOf('profile') > -1) {
        tag = 'profile';
      } else if (location.pathname.indexOf('extended') > -1) {
        tag = 'extended';
      }
      if (tag) {
        analytics.page(tag, '/my-eligibility');
      }
      setPrevRoute((location || {}).pathname);
    }
  });

  const handleSubmit = (values, actions, wizard, force) => {
    setValues({
      ...values,
    });

    if (!!actions && !!actions.setSubmitting) {
      actions.setSubmitting(false);
    }
    if (!!force) {
      wizard.push(force);
    } else {
      wizard.next();
    }
  };

  const handleComplete = async (vals) => {
    setRegistering(true);

    const variables = {
      ...vals,
      companyId: selectedCompany.id,
    };

    try {
      const { data } = await client.mutate({
        mutation: ADD_ELIGIBILITY_TO_ME,
        variables: {
          ...variables,
        },
        refetchQueries: [{ query: CURRENT_USER }],
        awaitRefetchQueries: true,
      });

      const attemptedRoute = '/';
      if (data && data.addEligibilityToMe && data.addEligibilityToMe.ok) {
        toastManager.add(data.addEligibilityToMe.reason || 'Eligibility request sent', {
          appearance: 'success',
          autoDismiss: true,
        });
        window.sessionStorage.removeItem('tmppartner');
        window.sessionStorage.removeItem('tmppartnercomp');
        window.sessionStorage.removeItem('tmppartnerpart');

        setRegistering(false);
        history.push(attemptedRoute || '/');
      } else {
        toastManager.add(data.addEligibilityToMe.reason || 'An error occured on your eligibility request', {
          appearance: 'error',
          autoDismiss: true,
        });
        setRegistering(false);
      }
    } catch (error) {
      setRegistering(false);
      if (!!error && !!error.graphQLErrors) {
        error.graphQLErrors.forEach((error) => {
          toastManager.add(error.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
      } else {
        toastManager.add('An error occured', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };

  let stepsVal = [
    {
      comp: (
        <Step id="relationship">
          <Relationship onSubmit={handleSubmit} values={values} />
        </Step>
      ),
      key: 'relationship',
      display: true,
    },
    {
      comp: (
        <Step id="employer">
          <SelectCompany
            values={values}
            companiesList={companies}
            selectedCompany={selectedCompany}
            onSubmit={(selectedCompany, actions, wizard) => {
              setSelectedCompany(selectedCompany);
              actions.setSubmitting(false);
              wizard.next();
            }}
            pushNext={(wizard) => {
              wizard.next();
            }}
            relationship={(values || {}).relationship}
          />
        </Step>
      ),
      key: 'employer',
      display: true,
    },
    {
      comp: (
        <Step id="additional">
          <B2bInformation onSubmit={handleSubmit} values={values} selectedCompany={selectedCompany} />
        </Step>
      ),
      key: 'additional',
      display: true,
    },
    {
      comp: (
        <Step id="precheck">
          <Precheck
            values={values}
            companyId={(selectedCompany || {}).id}
            successContinueWithValues={(newValues, wizard) => {
              handleComplete({
                ...values,
                ...newValues,
              });
            }}
            failContinueWithValues={(newValues, wizard) => {
              handleComplete({
                ...values,
                ...newValues,
              });
            }}
            successContinue={(wizard) => {
              handleComplete(values);
            }}
            failContinue={(wizard) => {
              handleComplete(values);
            }}
            failEditInformation={(wizard) => {
              wizard.push('basic');
            }}
            relationship={(values || {}).relationship}
          />
        </Step>
      ),
      key: 'precheck',
      display: true,
    },
    // {
    //   comp: (
    //     <Step id="finalize">
    //       <Extended onSubmit={handleComplete} values={values} />
    //     </Step>
    //   ),
    //   key: 'additional',
    //   display: true,
    // },
  ];
  return (
    <Flex flexDirection={'column'} px={3}>
      <Flex
        flex={1}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          history.push('/');
        }}
      >
        <Header dark={false} mb={1}>
          {/* <Text color="#486288" fontSize={5} fontWeight={500} textAlign="center">
            Check Your Eligibility
          </Text> */}
        </Header>
      </Flex>
      {/* <Heading mb={4} textAlign='center'>Check Your Eligibility</Heading> */}
      <Wizard history={history} basename={`/my-eligibility`}>
        <Steps>{stepsVal.map((x) => x.comp)}</Steps>
      </Wizard>
    </Flex>
  );
};

const CheckB2BEligibility = ({ session, location, analytics, history, client, toastManager }) => {
  const [registering, setRegistering] = useState(false);
  const [values, setValues] = useState({
    firstName: session.firstName,
    lastName: session.lastName,
    employeeEmail: session.email,
    dob: moment(session.dob).toDate(),
  });
  const [selectedCompany, setSelectedCompany] = useState();
  return (
    <FullLayout2 style={{ overflow: 'auto', zIndex: 10, alignItems: 'flex-start', justifyContent: 'center' }}>
      <Query query={GET_B2B_COMPANIES}>
        {({ data, loading, error }) => {
          if (loading || !!registering) {
            return <LoadingScreen loading={true} />;
          }
          return (
            // <Flex alignItems={'center'} justifyContent="center" style={{ margin: '0 auto' }}>
            <Inner
              companies={data.getB2BCompanies}
              session={session}
              location={location}
              analytics={analytics}
              history={history}
              client={client}
              toastManager={toastManager}
              values={values}
              setValues={setValues}
              setRegistering={setRegistering}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
            />
            // </Flex>
          );
        }}
      </Query>
    </FullLayout2>
  );
};

export default compose(
  withSession,
  withRouter,
  withApollo,
  withToastManager
)(CheckB2BEligibility);
