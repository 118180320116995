import * as Yup from 'yup';
import { intakeCommonTrunks } from './intakesCommonTrunk';

export const medicalHistory = [
  {
    id: 'diagnosed',
    type: 'input',
    disqualifyingText: 'Bypass diagnosed hypothyroidism',
    hasSecondOption: 'disqualified',
    isSkipDisqualifying: true,
    secondOptionText: 'I have not been diagnosed',
    endpoint: 'medicalHistory',
    prompt: 'Approximately when were you diagnosed with hypothyroidism?',
    defaults: { '19': '' },
    prefix: 'Diagnosed in (Year) -',
    onlyFirstTime: true,
    bypassIfLabDiagnosed: true,
    // hasIdontKnow:true,
    options: [{ questionId: '19', placeholder: 'Year (YYYY)', mask: '9999' }],
  },
  {
    id: 'hashimoto',
    type: 'single',
    endpoint: 'medicalHistory',
    // onlyFirstTime: true,
    bypassIfLabDiagnosed: true,
    prompt: `Have you been diagnosed with Hashimoto's?`,
    options: [
      {
        questionId: '281',
        prefix: 'Diagnosed in (Year) -',
        answer: 'Y',
        value: 'Yes',
        placeholder: 'Approximately diagnosed (YYYY)',
        mask: '9999',
      },
      { questionId: '281', answer: 'N', value: 'No' },
      { questionId: '281', answer: 'N', value: `I'm not sure` },
    ],
  },
  {
    id: 'hyper',
    type: 'single',
    endpoint: 'medicalHistory',
    onlyFirstTime: false,
    prompt: 'Have you ever been diagnosed with hyperthyroidism?',
    options: [
      {
        questionId: '18',
        prefix: 'Diagnosed in (Year) -',
        answer: 'Y',
        value: 'Yes',
        placeholder: 'Approximately diagnosed (YYYY)',
        mask: '9999',
      },
      { questionId: '18', answer: 'N', value: 'No' },
    ],
  },
  {
    id: 'cancer',
    type: 'single',
    endpoint: 'medicalHistory',
    prompt: 'Do you have a history of thyroid cancer?',
    onlyFirstTime: false,
    disqualify: true,
    disqualifyingText: 'History of Thyroid cancer',
    no: 'thyroid',
    options: [
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '297' : '301',
        answer: 'Y',
        value: 'Yes',
        isDisqualifying: true,
        nextQuestion: 'disqualified',
      },
      { answer: 'N', value: 'No' },
    ],
  }, // {
  //   id: 'graves',
  //   type: 'single',
  //   endpoint: 'medicalHistory',
  //   questionId: 18,
  //   prompt: 'Have you ever been diagnosed with Graves disease?',
  //   options: [
  //     {
  //       value: 'Yes',
  //       placeholder: 'Approximately diagnosed (YYYY)',
  //       mask: '9999',
  //     },
  //     { value: 'No' },
  //   ],
  // },
  {
    id: 'thyroid',
    type: 'single',
    endpoint: 'medicalHistory',
    onlyFirstTime: false,
    prompt: 'Has your thyroid been surgically removed?',
    options: [
      {
        answer: 'Y',
        value: 'Yes',
        nextQuestion: 'thyroid-removal-details',
      },
      {
        questionId: '282',
        answer: 'N',
        value: 'No',
        nextQuestion: 'radiation',
      },
    ],
  },
  {
    id: 'thyroid-removal-details',
    type: 'single',
    endpoint: 'medicalHistory',
    prompt: `Details on your thyroid removal`,
    options: [
      {
        answer: 'Y',
        value: 'Partial',
        questionId: '282',
        prefix: 'Partial - Removed in (year) ',
        placeholder: 'Which year?',
        mask: '9999',
        inputmode: 'number',
        inputType: 'number'
      },
      {
        questionId: '282',
        answer: 'Y',
        value: 'Total',
        prefix: 'Total - Removed in (year) ',
        placeholder: 'Which year?',
        mask: '9999',
        inputmode: 'number',
        inputType: 'number'
      },
    ],
  },
  {
    id: 'radiation',
    type: 'single',
    endpoint: 'medicalHistory',
    onlyFirstTime: false,
    prompt: 'Have you undergone radiation therapy?',
    options: [
      {
        questionId: '283',
        prefix: 'Treatment in (Year) -',
        answer: 'Y',
        value: 'Yes',
        placeholder: 'When was the treatment? (YYYY)',
        mask: '9999',
      },
      { questionId: '283', answer: 'N', value: 'No' },
    ],
  },
  {
    id: 'goiter',
    type: 'single',
    endpoint: 'medicalHistory',
    onlyFirstTime: false,
    prompt: 'Have you been told you have a goiter?',
    no: 'uploadLabResults',
    options: [
      {
        questionId: '284',
        answer: 'Y',
        value: 'Yes',
      },
      {
        questionId: '284',
        answer: 'N',
        value: 'No',
        nextQuestion: 'uploadLabResults',
      },
    ],
  },
  {
    id: 'goiterUltraSound',
    type: 'single',
    endpoint: 'medicalHistory',
    onlyFirstTime: false,
    prompt: 'Have you had an ultrasound test?',
    no: 'uploadLabResults',
    options: [
      {
        answer: 'Y',
        value: 'Yes',
      },
      { answer: 'N', value: 'No', nextQuestion: 'uploadLabResults' },
    ],
  },
  {
    id: 'goiterBiopsy',
    type: 'single',
    endpoint: 'medicalHistory',
    prompt: 'Have you had a fine needle biopsy?',
    onlyFirstTime: false,
    no: 'uploadLabResults',
    options: [
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '362' : '381',
        answer: 'Y',
        value: 'Yes',
      },
      {
        questionId: window._env_.REACT_APP_GRAPHQL_URL.indexOf('preview') > -1 || window._env_.REACT_APP_GRAPHQL_URL.indexOf('localhost') > -1 ? '362' : '381',
        answer: 'N',
        value: 'No',
        nextQuestion: 'uploadLabResults',
      },
    ],
  },
  {
    id: 'uploadLabResults',
    type: 'uploadLabResults',
    endpoint: 'medicalHistory',
    prompt: 'Do you have previous lab results to upload?',
    onlyFirstTime: false,
    skip: 'family',
  },
  {
    id: 'family',
    type: 'multiple',
    endpoint: 'familyHistory',
    skip: 'familyConditions',
    onlyFirstTime: true,
    prompt: 'Do any family members have thyroid disease?',
    options: [
      { questionId: '1', answer: 'Has hypothyroidism', value: 'Mother' },
      { questionId: '2', answer: 'Has hypothyroidism', value: 'Father' },
      { questionId: '3', answer: 'Has hypothyroidism', value: 'Brother' },
      { questionId: '4', answer: 'Has hypothyroidism', value: 'Sister' },
      { skip: 'familyConditions', value: 'None' },
    ],
  },
  {
    id: 'familyConditions',
    type: 'input',
    endpoint: 'familyHistory',
    skip: 'methimazole',
    onlyFirstTime: true,
    prompt: 'Please indicate any major conditions/illnesses that your immediate family members have',
    defaults: { '8': '' },
    prefix: 'Major conditions / illnesses -',
    options: [
      {
        questionId: '8',
        placeholder: 'Indicate any major conditions/illnesses',
      },
    ],
  },
  {
    id: 'methimazole',
    type: 'single',
    endpoint: 'medications',
    prompt: 'Are you currently taking methimazole (Tapazole) and propylithiouracil (also called PTU)',
    onlyFirstTime: false,
    disqualify: true,
    disqualifyingText: 'Methimazole or propylithiouracil',
    no: 'hypomed',
    options: [
      {
        answer: 'methimazole',
        value: 'methimazole',
        isDisqualifying: true,
        nextQuestion: 'disqualified',
      },
      {
        answer: 'propylithiouracil',
        value: 'propylithiouracil',
        isDisqualifying: true,
        nextQuestion: 'disqualified',
      },
      { answer: 'None of the above', value: 'No' },
    ],
  },
  // {
  //   id: 'promptTyrosine',
  //   type: 'single',
  //   endpoint: 'medications',
  //   onlyFirstTime: false,
  //   prompt: 'Do you take tyrosine kinase inhibitors?',
  //   options: [
  //     { questionId: '', value: 'Yes' },
  //     { questionId: '', value: 'No', nextQuestion: 'drugExtended' },
  //   ],
  // },
  // {
  //   id: 'tyrosine',
  //   type: 'multiple',
  //   endpoint: 'medications',
  //   onlyFirstTime: false,
  //   prompt: 'Which tyrosine kinase inhibitor are you taking?',
  //   options: [
  //     { questionId: '314233', value: 'Imatinib' },
  //     { questionId: '328482', value: 'Gefitinib' },
  //     { questionId: '336084', value: 'Erlotinib' },
  //     { questionId: '340010', value: 'Sorafenib' },
  //     { questionId: '340093', value: 'Sunitinib' },
  //     { questionId: '340677', value: 'Dasatinib' },
  //   ],
  // },
  // {
  //   id: 'drugExtended',
  //   type: 'multiple',
  //   endpoint: 'medications',
  //   skip: 'hypomed',
  //   prompt: 'Do you take any of the following drugs? (Choose all that apply)',
  //   options: [
  //     {
  //       questionId: '296040',
  //       answer: 'N',
  //       value: 'Lithium',
  //     },
  //     {
  //       questionId: '299022',
  //       answer: 'N',
  //       value: 'Amiodarone',
  //     },
  //     {
  //       questionId: '294639',
  //       answer: 'N',
  //       value: 'Interferon alpha',
  //     },
  //     {
  //       questionId: '305519',
  //       answer: 'N',
  //       value: 'Interleukin-2',
  //     },
  //     {
  //       questionId: '309994',
  //       answer: 'N',
  //       value: 'Thalidomide',
  //     },
  //     {
  //       questionId: '311523',
  //       answer: 'N',
  //       value: 'Bexarotene',
  //     },
  //     {
  //       questionId: '359692',
  //       answer: 'N',
  //       value: 'Ipilimumab',
  //     },
  //   ],
  // },
  {
    id: 'hypomed',
    skip: 'medications',
    type: 'medicationPick',
    endpoint: 'medications',
    title: 'Hypothyroidism Medications',
    prompt: 'Please list your hypothyroidism medication',
    onlyFirstTime: false,
    other: { questionId: '367151' },
    isInputOnly: false,
    options: [
      {
        value: 'Unithroid',
        hasDosage: false,
        questionId: 313430,
      },
      {
        value: 'Unithroid 100 mcg tablet',
        hasDosage: true,
        questionId: 248353,
      },
      {
        value: 'Unithroid 112 mcg tablet',
        hasDosage: true,
        questionId: 239279,
      },
      {
        value: 'Unithroid 125 mcg tablet',
        hasDosage: true,
        questionId: 239194,
      },
      {
        value: 'Unithroid 137 mcg tablet',
        hasDosage: true,
        questionId: 348963,
      },
      {
        value: 'Unithroid 150 mcg tablet',
        hasDosage: true,
        questionId: 246691,
      },
      {
        value: 'Unithroid 175 mcg tablet',
        hasDosage: true,
        questionId: 252038,
      },
      {
        value: 'Unithroid 200 mcg tablet',
        hasDosage: true,
        questionId: 233390,
      },
      {
        value: 'Unithroid 25 mcg tablet',
        hasDosage: true,
        questionId: 251346,
      },
      {
        value: 'Unithroid 300 mcg tablet',
        hasDosage: true,
        questionId: 245346,
      },
      {
        value: 'Unithroid 50 mcg tablet',
        hasDosage: true,
        questionId: 244067,
      },
      {
        value: 'Unithroid 75 mcg tablet',
        hasDosage: true,
        questionId: 253346,
      },
      {
        value: 'Unithroid 88 mcg tablet',
        hasDosage: true,
        questionId: 226774,
      },
      {
        value: 'Thyrogen',
        hasDosage: false,
        questionId: 310408,
      },
      {
        value: 'Thyrogen 0.9 mg intramuscular solution',
        hasDosage: true,
        questionId: 396360,
      },
      {
        value: 'liothyronine',
        hasDosage: false,
        questionId: 302000,
      },
      {
        value: 'liothyronine (bulk)',
        hasDosage: true,
        questionId: 349888,
      },
      {
        value: 'liothyronine (bulk) 1 mg/gram powder',
        hasDosage: true,
        questionId: 361005,
      },
      {
        value: 'liothyronine (bulk) 100 % powder',
        hasDosage: true,
        questionId: 227807,
      },
      {
        value: 'liothyronine 10 mcg/mL intravenous solution',
        hasDosage: true,
        questionId: 230025,
      },
      {
        value: 'liothyronine 25 mcg tablet',
        hasDosage: true,
        questionId: 230116,
      },
      {
        value: 'liothyronine 5 mcg tablet',
        hasDosage: true,
        questionId: 236739,
      },
      {
        value: 'liothyronine 50 mcg tablet',
        hasDosage: true,
        questionId: 246932,
      },
      {
        value: 'LevoxyL',
        hasDosage: false,
        questionId: 300534,
      },
      {
        value: 'LevoxyL 100 mcg tablet',
        hasDosage: true,
        questionId: 231895,
      },
      {
        value: 'LevoxyL 112 mcg tablet',
        hasDosage: true,
        questionId: 252514,
      },
      {
        value: 'LevoxyL 125 mcg tablet',
        hasDosage: true,
        questionId: 227013,
      },
      {
        value: 'LevoxyL 137 mcg tablet',
        hasDosage: true,
        questionId: 257592,
      },
      {
        value: 'LevoxyL 150 mcg tablet',
        hasDosage: true,
        questionId: 225651,
      },
      {
        value: 'LevoxyL 175 mcg tablet',
        hasDosage: true,
        questionId: 233107,
      },
      {
        value: 'LevoxyL 200 mcg tablet',
        hasDosage: true,
        questionId: 229394,
      },
      {
        value: 'LevoxyL 25 mcg tablet',
        hasDosage: true,
        questionId: 239555,
      },
      {
        value: 'LevoxyL 50 mcg tablet',
        hasDosage: true,
        questionId: 250009,
      },
      {
        value: 'LevoxyL 75 mcg tablet',
        hasDosage: true,
        questionId: 233953,
      },
      {
        value: 'LevoxyL 88 mcg tablet',
        hasDosage: true,
        questionId: 226499,
      },
      {
        value: 'Levo-T',
        hasDosage: false,
        questionId: 307279,
      },
      {
        value: 'Levo-T 100 mcg tablet',
        hasDosage: true,
        questionId: 382302,
      },
      {
        value: 'Levo-T 112 mcg tablet',
        hasDosage: true,
        questionId: 382282,
      },
      {
        value: 'Levo-T 125 mcg tablet',
        hasDosage: true,
        questionId: 249338,
      },
      {
        value: 'Levo-T 137 mcg tablet',
        hasDosage: true,
        questionId: 382291,
      },
      {
        value: 'Levo-T 150 mcg tablet',
        hasDosage: true,
        questionId: 382281,
      },
      {
        value: 'Levo-T 175 mcg tablet',
        hasDosage: true,
        questionId: 382295,
      },
      {
        value: 'Levo-T 200 mcg tablet',
        hasDosage: true,
        questionId: 382307,
      },
      {
        value: 'Levo-T 25 mcg tablet',
        hasDosage: true,
        questionId: 382300,
      },
      {
        value: 'Levo-T 300 mcg tablet',
        hasDosage: true,
        questionId: 382289,
      },
      {
        value: 'Levo-T 50 mcg tablet',
        hasDosage: true,
        questionId: 382292,
      },
      {
        value: 'Levo-T 75 mcg tablet',
        hasDosage: true,
        questionId: 382290,
      },
      {
        value: 'Levo-T 88 mcg tablet',
        hasDosage: true,
        questionId: 382303,
      },
      {
        value: 'Euthyrox',
        hasDosage: false,
        questionId: 299187,
      },
      {
        value: 'Euthyrox 100 mcg tablet',
        hasDosage: true,
        questionId: 237400,
      },
      {
        value: 'Euthyrox 112 mcg tablet',
        hasDosage: true,
        questionId: 241991,
      },
      {
        value: 'Euthyrox 125 mcg tablet',
        hasDosage: true,
        questionId: 241213,
      },
      {
        value: 'Euthyrox 137 mcg tablet',
        hasDosage: true,
        questionId: 389367,
      },
      {
        value: 'Euthyrox 150 mcg tablet',
        hasDosage: true,
        questionId: 243351,
      },
      {
        value: 'Euthyrox 175 mcg tablet',
        hasDosage: true,
        questionId: 225746,
      },
      {
        value: 'Euthyrox 200 mcg tablet',
        hasDosage: true,
        questionId: 226297,
      },
      {
        value: 'Euthyrox 25 mcg tablet',
        hasDosage: true,
        questionId: 239811,
      },
      {
        value: 'Euthyrox 50 mcg tablet',
        hasDosage: true,
        questionId: 249702,
      },
      {
        value: 'Euthyrox 75 mcg tablet',
        hasDosage: true,
        questionId: 242062,
      },
      {
        value: 'Euthyrox 88 mcg tablet',
        hasDosage: true,
        questionId: 244029,
      },
      {
        value: 'Synthroid',
        hasDosage: false,
        questionId: 301371,
      },
      {
        value: 'Synthroid 100 mcg tablet',
        hasDosage: true,
        questionId: 231970,
      },
      {
        value: 'Synthroid 112 mcg tablet',
        hasDosage: true,
        questionId: 251383,
      },
      {
        value: 'Synthroid 125 mcg tablet',
        hasDosage: true,
        questionId: 231796,
      },
      {
        value: 'Synthroid 137 mcg tablet',
        hasDosage: true,
        questionId: 276415,
      },
      {
        value: 'Synthroid 150 mcg tablet',
        hasDosage: true,
        questionId: 230662,
      },
      {
        value: 'Synthroid 175 mcg tablet',
        hasDosage: true,
        questionId: 238520,
      },
      {
        value: 'Synthroid 200 mcg tablet',
        hasDosage: true,
        questionId: 230352,
      },
      {
        value: 'Synthroid 25 mcg tablet',
        hasDosage: true,
        questionId: 242725,
      },
      {
        value: 'Synthroid 300 mcg tablet',
        hasDosage: true,
        questionId: 248100,
      },
      {
        value: 'Synthroid 50 mcg tablet',
        hasDosage: true,
        questionId: 248284,
      },
      {
        value: 'Synthroid 75 mcg tablet',
        hasDosage: true,
        questionId: 247491,
      },
      {
        value: 'Synthroid 88 mcg tablet',
        hasDosage: true,
        questionId: 245881,
      },
      {
        value: 'Tirosint',
        hasDosage: false,
        questionId: 355544,
      },
      {
        value: 'Tirosint 100 mcg capsule',
        hasDosage: true,
        questionId: 355490,
      },
      {
        value: 'Tirosint 112 mcg capsule',
        hasDosage: true,
        questionId: 355501,
      },
      {
        value: 'Tirosint 125 mcg capsule',
        hasDosage: true,
        questionId: 355484,
      },
      {
        value: 'Tirosint 13 mcg capsule',
        hasDosage: true,
        questionId: 355475,
      },
      {
        value: 'Tirosint 137 mcg capsule',
        hasDosage: true,
        questionId: 355509,
      },
      {
        value: 'Tirosint 150 mcg capsule',
        hasDosage: true,
        questionId: 355503,
      },
      {
        value: 'Tirosint 175 mcg capsule',
        hasDosage: true,
        questionId: 389564,
      },
      {
        value: 'Tirosint 200 mcg capsule',
        hasDosage: true,
        questionId: 389561,
      },
      {
        value: 'Tirosint 25 mcg capsule',
        hasDosage: true,
        questionId: 355470,
      },
      {
        value: 'Tirosint-SoL 100 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389905,
      },
      {
        value: 'Tirosint-SoL 112 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389897,
      },
      {
        value: 'Tirosint-SoL 125 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389921,
      },
      {
        value: 'Tirosint-SoL 200 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389916,
      },
      {
        value: 'Tirosint-SoL 25 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389911,
      },
      {
        value: 'Tirosint 37.5 mcg capsule',
        hasDosage: true,
        questionId: 402011,
      },
      {
        value: 'Tirosint-SoL 37.5 mcg/mL oral solution',
        hasDosage: true,
        questionId: 397162,
      },
      {
        value: 'Tirosint 44 mcg capsule',
        hasDosage: true,
        questionId: 402019,
      },
      {
        value: 'Tirosint-SoL 44 mcg/mL oral solution',
        hasDosage: true,
        questionId: 397164,
      },
      {
        value: 'Tirosint 50 mcg capsule',
        hasDosage: true,
        questionId: 355467,
      },
      {
        value: 'Tirosint 62.5 mcg capsule',
        hasDosage: true,
        questionId: 402015,
      },
      {
        value: 'Tirosint-SoL 50 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389922,
      },
      {
        value: 'Tirosint-SoL 62.5 mcg/mL oral solution',
        hasDosage: true,
        questionId: 397169,
      },
      {
        value: 'Tirosint 75 mcg capsule',
        hasDosage: true,
        questionId: 355469,
      },
      {
        value: 'Tirosint-SoL 75 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389900,
      },
      {
        value: 'Tirosint 88 mcg capsule',
        hasDosage: true,
        questionId: 355496,
      },
      {
        value: 'Tirosint-SoL 88 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389910,
      },
      {
        value: 'Tirosint-SoL 13 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389902,
      },
      {
        value: 'Tirosint-SoL 137 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389899,
      },
      {
        value: 'Tirosint-SoL 150 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389901,
      },
      {
        value: 'Tirosint-SoL 175 mcg/mL oral solution',
        hasDosage: true,
        questionId: 389903,
      },
      {
        value: 'Tirosint-SoL',
        hasDosage: true,
        questionId: 389925,
      },
      {
        value: 'levothyroxine',
        hasDosage: false,
        questionId: 296025,
      },
      {
        value: 'levothyroxine (bulk)',
        hasDosage: true,
        questionId: 347088,
      },
      {
        value: 'levothyroxine (bulk) 1 mg/gram powder',
        hasDosage: true,
        questionId: 362431,
      },
      {
        value: 'levothyroxine (bulk) 100 % powder',
        hasDosage: true,
        questionId: 237253,
      },
      {
        value: 'levothyroxine 100 mcg capsule',
        hasDosage: true,
        questionId: 355507,
      },
      {
        value: 'levothyroxine 100 mcg intravenous powder for solution',
        hasDosage: true,
        questionId: 362885,
      },
      {
        value: 'levothyroxine 100 mcg tablet',
        hasDosage: true,
        questionId: 239330,
      },
      {
        value: 'levothyroxine 100 mcg/mL intravenous solution',
        hasDosage: true,
        questionId: 390404,
      },
      {
        value: 'levothyroxine 100 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383551,
      },
      {
        value: 'levothyroxine 112 mcg capsule',
        hasDosage: true,
        questionId: 355495,
      },
      {
        value: 'levothyroxine 112 mcg tablet',
        hasDosage: true,
        questionId: 257160,
      },
      {
        value: 'levothyroxine 112 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383553,
      },
      {
        value: 'levothyroxine 125 mcg capsule',
        hasDosage: true,
        questionId: 355488,
      },
      {
        value: 'levothyroxine 125 mcg tablet',
        hasDosage: true,
        questionId: 244788,
      },
      {
        value: 'levothyroxine 20 mcg/mL intravenous solution',
        hasDosage: true,
        questionId: 390400,
      },
      {
        value: 'levothyroxine 20 mcg/mL oral solution',
        hasDosage: true,
        questionId: 395094,
      },
      {
        value: 'levothyroxine 200 mcg capsule',
        hasDosage: true,
        questionId: 389574,
      },
      {
        value: 'levothyroxine 200 mcg intravenous powder for solution',
        hasDosage: true,
        questionId: 241562,
      },
      {
        value: 'levothyroxine 200 mcg tablet',
        hasDosage: true,
        questionId: 250544,
      },
      {
        value: 'levothyroxine 200 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383570,
      },
      {
        value: 'levothyroxine 25 mcg capsule',
        hasDosage: true,
        questionId: 355476,
      },
      {
        value: 'levothyroxine 25 mcg tablet',
        hasDosage: true,
        questionId: 237942,
      },
      {
        value: 'levothyroxine 25 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383568,
      },
      {
        value: 'levothyroxine 30 mcg/mL oral solution',
        hasDosage: true,
        questionId: 399165,
      },
      {
        value: 'levothyroxine 300 mcg tablet',
        hasDosage: true,
        questionId: 230002,
      },
      {
        value: 'levothyroxine 37.5 mcg capsule',
        hasDosage: true,
        questionId: 402032,
      },
      {
        value: 'levothyroxine 37.5 mcg/mL oral solution',
        hasDosage: true,
        questionId: 397165,
      },
      {
        value: 'levothyroxine 40 mcg/mL intravenous solution',
        hasDosage: true,
        questionId: 390397,
      },
      {
        value: 'levothyroxine 44 mcg capsule',
        hasDosage: true,
        questionId: 402023,
      },
      {
        value: 'levothyroxine 44 mcg/mL oral solution',
        hasDosage: true,
        questionId: 397173,
      },
      {
        value: 'levothyroxine 50 mcg capsule',
        hasDosage: true,
        questionId: 355493,
      },
      {
        value: 'levothyroxine 50 mcg tablet',
        hasDosage: true,
        questionId: 243806,
      },
      {
        value: 'levothyroxine 50 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383550,
      },
      {
        value: 'levothyroxine 500 mcg intravenous powder for solution',
        hasDosage: true,
        questionId: 242200,
      },
      {
        value: 'levothyroxine 62.5 mcg capsule',
        hasDosage: true,
        questionId: 402012,
      },
      {
        value: 'levothyroxine 62.5 mcg/mL oral solution',
        hasDosage: true,
        questionId: 397154,
      },
      {
        value: 'levothyroxine 75 mcg capsule',
        hasDosage: true,
        questionId: 355468,
      },
      {
        value: 'levothyroxine 75 mcg tablet',
        hasDosage: true,
        questionId: 238501,
      },
      {
        value: 'levothyroxine 75 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383563,
      },
      {
        value: 'levothyroxine 88 mcg capsule',
        hasDosage: true,
        questionId: 355486,
      },
      {
        value: 'levothyroxine 88 mcg tablet',
        hasDosage: true,
        questionId: 240026,
      },
      {
        value: 'levothyroxine 88 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383579,
      },
      {
        value: 'levothyroxine 125 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383582,
      },
      {
        value: 'levothyroxine 13 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383573,
      },
      {
        value: 'levothyroxine 137 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383565,
      },
      {
        value: 'levothyroxine 150 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383558,
      },
      {
        value: 'levothyroxine 175 mcg/mL oral solution',
        hasDosage: true,
        questionId: 383561,
      },
      {
        value: 'levothyroxine 137 mcg tablet',
        hasDosage: true,
        questionId: 226061,
      },
      {
        value: 'levothyroxine 150 mcg tablet',
        hasDosage: true,
        questionId: 246273,
      },
      {
        value: 'levothyroxine 175 mcg tablet',
        hasDosage: true,
        questionId: 225902,
      },
      {
        value: 'CytomeL',
        hasDosage: false,
        questionId: 301999,
      },
      {
        value: 'CytomeL 25 mcg tablet',
        hasDosage: true,
        questionId: 250263,
      },
      {
        value: 'CytomeL 5 mcg tablet',
        hasDosage: true,
        questionId: 237429,
      },
      {
        value: 'CytomeL 50 mcg tablet',
        hasDosage: true,
        questionId: 236623,
      },
      {
        value: 'Armour Thyroid',
        hasDosage: false,
        questionId: 298245,
      },
      {
        value: 'Armour Thyroid 120 mg tablet',
        hasDosage: true,
        questionId: 233637,
      },
      {
        value: 'Armour Thyroid 15 mg tablet',
        hasDosage: true,
        questionId: 239332,
      },
      {
        value: 'Armour Thyroid 180 mg tablet',
        hasDosage: true,
        questionId: 229937,
      },
      {
        value: 'Armour Thyroid 240 mg tablet',
        hasDosage: true,
        questionId: 250548,
      },
      {
        value: 'Armour Thyroid 30 mg tablet',
        hasDosage: true,
        questionId: 251437,
      },
      {
        value: 'Armour Thyroid 300 mg tablet',
        hasDosage: true,
        questionId: 255429,
      },
      {
        value: 'Armour Thyroid 60 mg tablet',
        hasDosage: true,
        questionId: 224882,
      },
      {
        value: 'Armour Thyroid 90 mg tablet',
        hasDosage: true,
        questionId: 226943,
      },
      {
        questionId: '299900',
        value: 'Westhroid',
      },
      // {
      //   questionId: '313430',
      //   value: 'Unithroid',
      // },
      {
        questionId: '303622',
        value: 'Triostat',
      },
      {
        questionId: '308071',
        value: 'Thyrolar-1',
      },
      {
        questionId: '307953',
        value: 'Thyrolar-2',
      },
      {
        questionId: '303601',
        value: 'Thyrolar-3',
      },
      // {
      //   questionId: '310408',
      //   value: 'Thyrogen',
      // },
      {
        questionId: '301146',
        value: 'Liotrix',
      },
      // {
      //   questionId: '302000',
      //   value: 'Liothyronine',
      // },
      // {
      //   questionId: '300534',
      //   value: 'Levoxyl',
      // },
      // {
      //   questionId: '307279',
      //   value: 'Levo-T',
      // },
      // {
      //   questionId: '299187',
      //   value: 'Euthyrox',
      // },
      // {
      //   questionId: '301371',
      //   value: 'Synthroid',
      // },
      // {
      //   questionId: '355544',
      //   value: 'Tirosint',
      // },
      // {
      //   questionId: '296025',
      //   value: 'Levothyroxine',
      // },
      // {
      //   questionId: '301999',
      //   value: 'Cytomel',
      // },
      // {
      //   questionId: '298245',
      //   value: 'Armour Thyroid',
      // },
      {
        questionId: '371624',
        value: 'WP Thyroid',
      },
      {
        questionId: '311558',
        value: 'Nature-Throid',
      },
      // {
      //   questionId: '357795',
      //   value: 'NP Thyroid',
      // },
      {
        value: 'NP Thyroid',
        questionId: '357795',
      },
      {
        value: 'NP Thyroid 120 mg tablet',
        questionId: '384698',
      },
      {
        value: 'NP Thyroid 15 mg tablet',
        questionId: '382981',
      },
      {
        value: 'NP Thyroid 30 mg tablet',
        questionId: '357746',
      },
      {
        value: 'NP Thyroid 60 mg tablet',
        questionId: '357763',
      },
      {
        value: 'NP Thyroid 90 mg tablet',
        questionId: '357753',
      },
    ],
  },

  // same ecran sans drop down
  {
    id: 'medications',
    type: 'medicationList',
    endpoint: 'medications',
    // skip: 'supplements',
    skip: 'allergies',
    onlyFirstTime: false,
    useUsaRx: true,
    title: 'Other Medications and Supplements',
    prompt: 'Please add the other medications and supplements you are currently taking',
    other: { questionId: '301410' },
    prefix: 'Medications currently taking -',
    extend: true,
    isInputOnly: true,
    options: [],
    nothingText: "I don't take other medications",
  },
  // fin same ecran
  // {
  //   id: 'supplements',
  //   type: 'medicationList2',
  //   endpoint: 'medications',
  //   skip: 'allergies',
  //   onlyFirstTime: false,
  //   title: 'Other Medication',
  //   useUsaRx: true,
  //   prompt: 'Please list all your current over the counter medications, supplements, and herbs',
  //   other: { questionId: '294886' },
  //   prefix: 'OTC medications, supplements, herbs -',
  //   extend: true,
  //   isInputOnly: true,
  //   options: [],
  //   nothingText: "I don't take other medications",
  // },
  {
    id: 'allergies',
    type: 'allergies',
    endpoint: 'allergies',
    onlyFirstTime: false,
    prompt: 'Do you have any allergies to medications or other substances?',
    options: [
      {
        questionId: '12990',
        prefix: 'Allergies to medications / other substances -',
        answer: 'Y',
        value: 'Yes',
        placeholder: 'Explain',
        check: (data) => {
          return data.gender === 'M' ? false : true;
        },
        failRoute: 'conditions',
      },
      {
        questionId: '12990',
        answer: 'NKDA',
        value: 'No',
        check: (data) => {
          return data.gender === 'M' ? false : true;
        },
        failRoute: 'conditions',
      },
    ],
  },
  {
    id: 'pcp',
    type: 'pcp',
    endpoint: 'medicalHistory',
    title: 'Your Primary Care Provider',
    prompt: 'Approximately when were you diagnosed with hypothyroidism?',
    defaults: { '19': '' },
    prefix: 'Diagnosed in (Year) -',
    onlyFirstTime: false,
    options: [{ questionId: '19', placeholder: 'Year (YYYY)', mask: '9999' }],
  },
  {
    id: 'currentPregnant',
    type: 'single',
    endpoint: 'obgyn',
    onlyFirstTime: false,
    prompt: 'Are you currently pregnant?',
    options: [
      { questionId: 'LOCAL.83', answer: 'Currently pregnant', value: 'Yes' },
      {
        questionId: 'LOCAL.83',
        answer: 'Planning to be pregnant',
        value: 'Plan to be',
      },
      { questionId: 'LOCAL.83', answer: 'Not pregnant', value: 'No' },
    ],
  },
  {
    id: 'pregnancies',
    type: 'input',
    endpoint: 'obgyn',
    onlyFirstTime: true,
    prompt: 'Tell us about your pregnancy history',
    defaults: { LOCAL_85: '', LOCAL_86: '' },
    options: [{ questionId: 'LOCAL_85', placeholder: 'Total Pregnancies', inputmode: 'decimal' }, { questionId: 'LOCAL_86', placeholder: 'Miscarriage', inputmode: 'decimal' }],
  },
  {
    id: 'conditions',
    type: 'multiple',
    endpoint: 'medicalHistory',
    onlyFirstTime: true,
    skip: 'concerns',
    prompt: 'Have you had any of the following conditions?',
    options: [
      { questionId: '287', value: 'Asthma' },
      { questionId: '78', value: 'Blood Transfusions' },
      { questionId: '17', value: 'Heart Disease / Disorder' },
      { questionId: '292', value: 'Pulmonary Embolism' },
      { questionId: '286', value: 'Arthritis' },
      { questionId: '293', value: 'Bowel Problems' },
      { questionId: '300', value: 'Lung Disorder' },
      { questionId: '294', value: 'Seizure / Epilepsy' },
      { questionId: '75', value: 'Bleeding Disorder' },
      { questionId: '290', value: 'Cancer' },
      { questionId: '10', value: 'Liver Disease' },
      { questionId: '11', value: 'Stroke' },
      { questionId: '8', value: 'High Blood Pressure' },
      { questionId: '7', value: 'High Cholesterol' },
      { questionId: '295', value: 'Chronic Headaches' },
      { questionId: '6', value: 'Diabetes' },
      { questionId: '9', value: 'Urinary / Kidney Disorder' },
      { questionId: '297', value: 'Psychiatric Disorder' },
      { questionId: '298', value: 'Gynecological Issues' },
      { questionId: '299', value: 'Eye Disorder' },
      { questionId: '24', value: 'PCOS' },
      { questionId: window._env_.REACT_APP_SENTRY_ENV !== 'production' ? '382' : '401', value: 'Endometriosis' },
      { skip: 'concerns', value: 'None' },
    ],
  },
  {
    id: 'heartAttack',
    type: 'single',
    endpoint: 'medicalHistory',
    onlyFirstTime: false,
    prompt: `Did you have a Heart Attack or a Heart failure class IV in the last 6 months?`,
    skipIfNotQuestionId: '17',
    disqualify: true,
    disqualifyingText: 'Heart Attack',
    no: 'family',
    options: [
      {
        questionId: '17',
        answer: 'Y',
        value: 'Yes',
        isDisqualifying: true,
        nextQuestion: 'disqualified',
      },
      { questionId: '17', answer: 'N', value: 'No' },
    ],
  },
  {
    id: 'pulmonaryEmbolism',
    type: 'single',
    endpoint: 'medicalHistory',
    onlyFirstTime: false,
    prompt: `Did you have a pulmonary Embolism within the last 6 months?`,
    skipIfNotQuestionId: '292',
    disqualify: true,
    disqualifyingText: 'Pulmonary Embolism',
    no: 'family',
    options: [
      {
        questionId: '292',
        answer: 'Y',
        value: 'Yes',
        isDisqualifying: true,
        nextQuestion: 'disqualified',
      },
      { questionId: '292', answer: 'N', value: 'No' },
    ],
  },
  {
    id: 'thyroidCancer',
    type: 'single',
    endpoint: 'medicalHistory',
    onlyFirstTime: false,
    prompt: `Do you or did you have thyroid cancer?`,
    skipIfNotQuestionId: '290',
    disqualify: true,
    disqualifyingText: 'Thyroid Cancer',
    no: 'family',
    options: [
      {
        questionId: '290',
        answer: 'Y',
        value: 'Yes',
        isDisqualifying: true,
        nextQuestion: 'disqualified',
      },
      { questionId: '290', answer: 'N', value: 'No' },
    ],
  },
  {
    id: 'concerns',
    type: 'input',
    endpoint: 'problems',
    skip: 'hospital',
    onlyFirstTime: true,
    prompt: 'Please list any other medical concerns you have and any details that may be important to your provider',
    defaults: { '29064005': '' },
    prefix: 'Other medical concerns / details -',
    options: [{ questionId: '29064005', placeholder: 'Add them here' }],
  },
  {
    id: 'concernsreturning',
    type: 'input',
    endpoint: 'problems',
    skip: 'hospitalReturning',
    onlyFollowingTimes: true,
    prompt: 'Please list any other medical concerns you have and any details that may be important to your provider',
    defaults: { '29064005': '' },
    prefix: 'Other medical concerns / details -',
    options: [{ questionId: '29064005', placeholder: 'Add them here' }],
  },
  {
    id: 'hospital',
    type: 'input',
    endpoint: 'surgicalHistory',
    skip: 'vitals',
    onlyFirstTime: true,
    prompt: 'Please list all past surgeries and hospitalizations with approximate dates',
    defaults: { '80050': '' },
    prefix: 'Past surgeries / hospitalizations -',
    extend: true,
    options: [{ questionId: '80050', placeholder: 'Add them here' }],
  },
  {
    id: 'hospitalReturning',
    type: 'single',
    endpoint: 'surgicalHistory',
    skip: 'vitals',
    onlyFollowingTimes: true,
    prompt: 'Did you have any surgeries or hospitalizations since your last visit?',
    defaults: { '80050': '' },
    prefix: 'Past surgeries / hospitalizations -',
    extend: true,
    options: [
      {
        answer: 'Y',
        value: 'Yes',
        questionId: '80050',
        placeholder: 'Please list all surgeries and hospitalizations with approximate dates',
      },
      {
        questionId: '80050',
        answer: 'N',
        value: 'No',
        isSkip: true,
      },
    ],
  },
  {
    id: 'vitals',
    type: 'HeightWeight',
    endpoint: 'socialHistory',
    prompt: 'Health Information',
    onlyFirstTime: false,
    defaults: { LOCAL_121: '', LOCAL_122: '' },
    schema: Yup.object().shape({
      LOCAL_121: Yup.string().required('Required'),
      LOCAL_122: Yup.string().required('Required'),
    }),
    options: [
      { questionId: 'LOCAL_121', placeholder: `Height (e.g. 6'3")` },
      {
        questionId: 'LOCAL_122',
        placeholder: 'Weight (pounds)',
        // inputType: 'number',
      },
    ],
  },
  {
    id: 'thanks',
    section: 'symptoms',
    type: 'information',
    title: 'Thank You',
    skip: 'great',
    status: 'symptoms',
    onlyFirstTime: false,
    text: [
      'You are almost done!',
      'We do have questions for you about your symptoms and your lifestyle. Please take the time to fill out the next questions. Your answers help us to better serve you!',
    ],
  },
  ...intakeCommonTrunks,
];
