import React from 'react';
import { Flex, Heading, Text } from 'rebass';
import { WhiteCard } from './WhiteCard';
import moment from 'moment';
import { CardHeader } from './CardHeader';

export const Summary = ({ details, history, token, appointmentId, athenaId }) => {
  return (
    <>
      <Heading>{details.isConfirmed ? 'Upcoming Video Visit' : 'Confirm Your Video Visit'}</Heading>

      <Flex alignItems={'center'} mt={'22px'}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <g clipPath="url(#clip0_1_101)">
            <path
              d="M8.515 1.019C8.34363 1.00635 8.17184 1.00001 8 1V0C8.19654 9.58276e-05 8.393 0.00743414 8.589 0.022L8.515 1.019ZM10.519 1.469C10.1984 1.3453 9.86922 1.24537 9.534 1.17L9.753 0.194C10.136 0.28 10.513 0.394 10.879 0.536L10.519 1.469ZM11.889 2.179C11.746 2.08365 11.5996 1.99359 11.45 1.909L11.943 1.039C12.2849 1.23274 12.6121 1.45132 12.922 1.693L12.307 2.482C12.1714 2.37623 12.032 2.2755 11.889 2.18V2.179ZM13.723 3.969C13.5252 3.68798 13.3069 3.42192 13.07 3.173L13.794 2.483C14.064 2.768 14.314 3.073 14.541 3.393L13.723 3.969ZM14.467 5.321C14.4014 5.16246 14.33 5.00636 14.253 4.853L15.146 4.403C15.3226 4.75409 15.473 5.11774 15.596 5.491L14.646 5.804C14.5923 5.64087 14.5326 5.47976 14.467 5.321ZM14.997 7.828C14.9889 7.48434 14.9555 7.14174 14.897 6.803L15.882 6.633C15.949 7.019 15.988 7.411 15.998 7.803L14.998 7.828H14.997ZM14.866 9.366C14.899 9.196 14.926 9.027 14.947 8.856L15.94 8.979C15.892 9.36915 15.8151 9.75521 15.71 10.134L14.746 9.867C14.792 9.702 14.832 9.535 14.866 9.366ZM13.914 11.745C14.098 11.455 14.26 11.151 14.4 10.837L15.314 11.242C15.154 11.602 14.969 11.948 14.759 12.28L13.914 11.745ZM12.95 12.95C13.072 12.828 13.189 12.702 13.3 12.572L14.058 13.225C13.9296 13.3738 13.7959 13.5179 13.657 13.657L12.95 12.95Z"
              fill="#4E658A"
            />
            <path
              d="M7.99995 1C6.84883 1.00008 5.71549 1.28405 4.70033 1.82674C3.68516 2.36943 2.8195 3.1541 2.18001 4.11125C1.54052 5.0684 1.14694 6.16848 1.03414 7.31406C0.921345 8.45964 1.0928 9.61536 1.53333 10.6789C1.97385 11.7423 2.66985 12.6808 3.55968 13.4111C4.4495 14.1413 5.50569 14.6409 6.63468 14.8655C7.76368 15.0901 8.93064 15.0328 10.0322 14.6987C11.1338 14.3645 12.1359 13.7639 12.95 12.95L13.6569 13.657C12.7267 14.5878 11.5812 15.2747 10.322 15.657C9.06278 16.0393 7.72872 16.105 6.43801 15.8485C5.14731 15.5919 3.93981 15.021 2.9225 14.1862C1.90519 13.3515 1.10949 12.2787 0.605884 11.0629C0.102284 9.84711 -0.0936637 8.52588 0.0354041 7.21627C0.164472 5.90666 0.61457 4.6491 1.34582 3.55502C2.07707 2.46094 3.06689 1.56411 4.22758 0.944003C5.38827 0.323895 6.684 -0.000348732 7.99995 2.81458e-07V1Z"
              fill="#4E658A"
            />
            <path
              d="M7.5 3C7.63261 3 7.75979 3.05268 7.85355 3.14645C7.94732 3.24021 8 3.36739 8 3.5V8.71L11.248 10.566C11.3598 10.6334 11.4408 10.7419 11.4736 10.8683C11.5065 10.9946 11.4886 11.1288 11.4238 11.2422C11.3591 11.3556 11.2525 11.4391 11.127 11.4749C11.0014 11.5108 10.8669 11.4961 10.752 11.434L7.252 9.434C7.17547 9.39029 7.11186 9.32712 7.06761 9.25091C7.02335 9.17469 7.00003 9.08813 7 9V3.5C7 3.36739 7.05268 3.24021 7.14645 3.14645C7.24021 3.05268 7.36739 3 7.5 3Z"
              fill="#4E658A"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_101">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <Text ml={2} fontWeight={400} fontSize={'14px'}>
          {`${moment(details.appointmentDate).format('dddd, MMMM Do')} ${moment(details.appointmentTime, 'HH:mm').format('hh:mm A')} ${details.tz}`}
        </Text>
      </Flex>

      <WhiteCard>
        <CardHeader details={details} />
        {!details.isConfirmed && (
          <Flex style={{ cursor: 'pointer', width: '100%' }} mb={4} alignItems={'center'} onClick={() => history.push(`${token}/confirm`)}>
            <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M37.6666 27.9003C36.0203 29.9174 33.9648 31.2741 31.7667 32.4054C26.4698 35.1317 20.8905 35.7803 15.0696 34.5706C13.8864 34.3247 12.6774 34.195 11.4761 34.0466C9.32792 33.7808 7.35005 33.0786 5.73624 31.6119C3.89968 29.9426 2.58259 27.8864 1.69226 25.5706C-0.047105 21.0456 -0.557288 16.4226 0.68848 11.6808C1.1295 10.0014 1.99363 8.52402 2.88678 7.04847C4.21365 4.85534 6.03396 3.15649 8.06623 1.67164C9.32278 0.75368 10.8228 0.392312 12.3471 0.236466C18.2964 -0.370466 24.1377 0.174495 29.8243 2.06208C30.8553 2.40435 31.9541 2.80975 32.7623 3.49394C33.9497 4.53431 35.0209 5.70073 35.957 6.97271C37.5708 9.09606 38.4525 11.575 39.0088 14.1657C39.6025 16.9329 40.0913 19.7183 39.7785 22.5662C39.5591 24.559 38.7379 26.3261 37.6666 27.9003Z"
                fill="#E7F0FF"
              />
              <rect x="8.99988" y="8" width="20" height="20" rx="10" fill="#49FF66" />
              <path d="M13.8275 18L17.7068 23.1724L24.1723 12.8276" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <Text ml={3} flex={1}>
              Confirm Visit
            </Text>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
              <path
                d="M7.85196 6.84526L1.69198 12.8568C1.49476 13.0477 1.17586 13.0477 0.978635 12.8568L0.147793 12.0526C-0.0494271 11.8617 -0.0494271 11.553 0.147793 11.3621L5.12026 6.5L0.147793 1.63795C-0.0494271 1.44704 -0.0494271 1.13834 0.147793 0.94743L0.978635 0.143181C1.17586 -0.0477266 1.49476 -0.0477266 1.69198 0.143181L7.85196 6.15474C8.04918 6.34565 8.04918 6.65435 7.85196 6.84526Z"
                fill="#354E79"
              />
            </svg>
          </Flex>
        )}
        <Flex style={{ cursor: 'pointer', width: '100%' }} mb={4} alignItems={'center'} onClick={() => history.push(`${token}/cancel`)}>
          <svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M37.8278 28.6101C36.1814 30.6271 34.126 31.9838 31.9278 33.1151C26.6309 35.8414 21.0516 36.4901 15.2308 35.2803C14.0475 35.0344 12.8386 34.9047 11.6373 34.7563C9.48905 34.4905 7.51118 33.7884 5.89737 32.3216C4.06081 30.6523 2.74373 28.5961 1.85339 26.2804C0.114028 21.7554 -0.396156 17.1323 0.849613 12.3905C1.29063 10.7111 2.15476 9.23373 3.04791 7.75819C4.37479 5.56505 6.19509 3.86621 8.22736 2.38136C9.48391 1.4634 10.9839 1.10203 12.5082 0.946183C18.4576 0.33925 24.2988 0.884212 29.9854 2.7718C31.0164 3.11406 32.1152 3.51946 32.9235 4.20365C34.1109 5.24403 35.182 6.41045 36.1181 7.68242C37.7319 9.80578 38.6136 12.2847 39.1699 14.8754C39.7637 17.6426 40.2525 20.428 39.9397 23.2759C39.7202 25.2687 38.899 27.0358 37.8278 28.6101Z"
              fill="#E7F0FF"
            />
            <path
              d="M22.7343 15.0777C22.8391 14.98 22.9236 14.8627 22.983 14.7323C23.0424 14.6019 23.0756 14.4611 23.0806 14.3179C23.0856 14.1747 23.0624 14.032 23.0122 13.8978C22.9621 13.7636 22.886 13.6405 22.7883 13.5358C22.6906 13.431 22.5732 13.3464 22.4428 13.287C22.3124 13.2276 22.1716 13.1944 22.0284 13.1894C21.8853 13.1844 21.7425 13.2076 21.6083 13.2578C21.4741 13.3079 21.3511 13.384 21.2463 13.4818L18.0543 16.4578L15.0783 13.2647C14.8791 13.0607 14.6081 12.9428 14.3231 12.9361C14.0382 12.9295 13.7619 13.0347 13.5535 13.2291C13.3451 13.4235 13.221 13.6918 13.2079 13.9766C13.1948 14.2613 13.2937 14.5399 13.4833 14.7527L16.4593 17.9447L13.2663 20.9207C13.1578 21.0174 13.0697 21.1348 13.0072 21.266C12.9447 21.3972 12.9091 21.5396 12.9024 21.6848C12.8957 21.83 12.9181 21.9751 12.9683 22.1115C13.0184 22.2479 13.0953 22.3729 13.1945 22.4792C13.2936 22.5855 13.413 22.6709 13.5456 22.7303C13.6783 22.7898 13.8214 22.8222 13.9667 22.8256C14.112 22.829 14.2565 22.8033 14.3918 22.7501C14.527 22.6968 14.6503 22.6171 14.7543 22.5156L17.9463 19.5407L20.9223 22.7327C21.0183 22.8432 21.1357 22.9332 21.2673 22.9974C21.3989 23.0615 21.5421 23.0986 21.6884 23.1062C21.8346 23.1139 21.9809 23.092 22.1185 23.0419C22.2561 22.9919 22.3822 22.9146 22.4893 22.8147C22.5965 22.7149 22.6824 22.5945 22.742 22.4607C22.8015 22.327 22.8336 22.1826 22.8362 22.0361C22.8388 21.8897 22.8119 21.7443 22.7571 21.6085C22.7023 21.4727 22.6207 21.3493 22.5172 21.2458L19.5423 18.0538L22.7343 15.0777Z"
              fill="#F8A294"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 17.9998C6 11.3725 11.3727 5.99976 18 5.99976C24.6273 5.99976 30 11.3725 30 17.9998C30 24.627 24.6273 29.9998 18 29.9998C11.3727 29.9998 6 24.627 6 17.9998ZM18 27.8179C16.7107 27.8179 15.4339 27.564 14.2427 27.0706C13.0515 26.5772 11.9692 25.854 11.0575 24.9423C10.1458 24.0306 9.42259 22.9482 8.92918 21.757C8.43577 20.5658 8.18182 19.2891 8.18182 17.9998C8.18182 16.7104 8.43577 15.4337 8.92918 14.2425C9.42259 13.0513 10.1458 11.969 11.0575 11.0573C11.9692 10.1456 13.0515 9.42235 14.2427 8.92894C15.4339 8.43553 16.7107 8.18157 18 8.18157C20.6039 8.18157 23.1012 9.21599 24.9425 11.0573C26.7838 12.8985 27.8182 15.3958 27.8182 17.9998C27.8182 20.6037 26.7838 23.101 24.9425 24.9423C23.1012 26.7835 20.6039 27.8179 18 27.8179Z"
              fill="#F8A294"
            />
          </svg>

          <Text ml={3} flex={1}>
            Cancel Visit
          </Text>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
            <path
              d="M7.85196 6.84526L1.69198 12.8568C1.49476 13.0477 1.17586 13.0477 0.978635 12.8568L0.147793 12.0526C-0.0494271 11.8617 -0.0494271 11.553 0.147793 11.3621L5.12026 6.5L0.147793 1.63795C-0.0494271 1.44704 -0.0494271 1.13834 0.147793 0.94743L0.978635 0.143181C1.17586 -0.0477266 1.49476 -0.0477266 1.69198 0.143181L7.85196 6.15474C8.04918 6.34565 8.04918 6.65435 7.85196 6.84526Z"
              fill="#354E79"
            />
          </svg>
        </Flex>
        <Flex style={{ cursor: 'pointer', width: '100%' }} alignItems={'center'} onClick={() => history.push(`${token}/reschedule`)}>
          <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M37.6665 27.9003C36.0202 29.9174 33.9647 31.2741 31.7666 32.4054C26.4697 35.1317 20.8903 35.7803 15.0695 34.5706C13.8863 34.3247 12.6773 34.195 11.476 34.0466C9.3278 33.7808 7.34993 33.0786 5.73612 31.6119C3.89956 29.9426 2.58247 27.8864 1.69214 25.5706C-0.0472271 21.0456 -0.55741 16.4226 0.688358 11.6808C1.12938 10.0014 1.9935 8.52402 2.88666 7.04847C4.21353 4.85534 6.03384 3.15649 8.06611 1.67164C9.32266 0.75368 10.8227 0.392312 12.3469 0.236466C18.2963 -0.370466 24.1375 0.174495 29.8242 2.06208C30.8552 2.40435 31.954 2.80975 32.7622 3.49394C33.9496 4.53431 35.0208 5.70073 35.9568 6.97271C37.5706 9.09606 38.4524 11.575 39.0086 14.1657C39.6024 16.9329 40.0912 19.7183 39.7784 22.5662C39.559 24.559 38.7378 26.3261 37.6665 27.9003Z"
              fill="#E7F0FF"
            />
            <path
              d="M23.9921 12.8281C23.9921 12.927 23.9112 13.0078 23.8124 13.0078H22.5546C22.4557 13.0078 22.3749 12.927 22.3749 12.8281V11.75H16.6249V12.8281C16.6249 12.927 16.544 13.0078 16.4452 13.0078H15.1874C15.0885 13.0078 15.0077 12.927 15.0077 12.8281V11.75H12.1327V14.8047H26.8671V11.75H23.9921V12.8281Z"
              fill="#FFE3D8"
            />
            <path
              d="M27.7655 10.1328H23.9921V8.69531C23.9921 8.59648 23.9112 8.51562 23.8124 8.51562H22.5546C22.4557 8.51562 22.3749 8.59648 22.3749 8.69531V10.1328H16.6249V8.69531C16.6249 8.59648 16.544 8.51562 16.4452 8.51562H15.1874C15.0885 8.51562 15.0077 8.59648 15.0077 8.69531V10.1328H11.2343C10.8367 10.1328 10.5155 10.454 10.5155 10.8516V25.7656C10.5155 26.1632 10.8367 26.4844 11.2343 26.4844H27.7655C28.1631 26.4844 28.4843 26.1632 28.4843 25.7656V10.8516C28.4843 10.454 28.1631 10.1328 27.7655 10.1328ZM26.8671 24.8672H12.1327V16.332H26.8671V24.8672ZM26.8671 14.8047H12.1327V11.75H15.0077V12.8281C15.0077 12.927 15.0885 13.0078 15.1874 13.0078H16.4452C16.544 13.0078 16.6249 12.927 16.6249 12.8281V11.75H22.3749V12.8281C22.3749 12.927 22.4557 13.0078 22.5546 13.0078H23.8124C23.9112 13.0078 23.9921 12.927 23.9921 12.8281V11.75H26.8671V14.8047Z"
              fill="#4E658A"
            />
          </svg>

          <Text ml={3} flex={1}>
            Reschedule
          </Text>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
            <path
              d="M7.85196 6.84526L1.69198 12.8568C1.49476 13.0477 1.17586 13.0477 0.978635 12.8568L0.147793 12.0526C-0.0494271 11.8617 -0.0494271 11.553 0.147793 11.3621L5.12026 6.5L0.147793 1.63795C-0.0494271 1.44704 -0.0494271 1.13834 0.147793 0.94743L0.978635 0.143181C1.17586 -0.0477266 1.49476 -0.0477266 1.69198 0.143181L7.85196 6.15474C8.04918 6.34565 8.04918 6.65435 7.85196 6.84526Z"
              fill="#354E79"
            />
          </svg>
        </Flex>
      </WhiteCard>
    </>
  );
};
