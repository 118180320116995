import React from 'react';
import IconContainer from './IconContainer';

const VideoCamera = props => (
  <IconContainer {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12">
      <path
        fill={props.fill ? props.fill : '#000'}
        d="M12,2v8H2V2H12m1-2H1A1,1,0,0,0,0,1V11a1,1,0,0,0,1,1H13a1,1,0,0,0,1-1V7.5l4,4V.5l-4,4V1A1,1,0,0,0,13,0Z"
      />
    </svg>
  </IconContainer>
);

export default VideoCamera;
