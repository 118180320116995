import React, { Component, Fragment } from 'react';
import { Flex, Box, Button, Heading, Card, Text } from 'rebass';
import { withWizard } from 'react-albus';
import { GET_USER_PCP, REMOVE_PCP } from '../../graphql';
import { compose, withApollo, Query, Mutation } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import SpinLoader from '../SpinLoader';
import PcpPickForm from './components/pcp/form';

class PcpPick extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddMode: false,
    };
  }

  shiftView = () => {
    setTimeout(() => {
      window.scrollTo(0, this.inputRef.offsetTop);
    }, 150);
  };

  render() {
    const { data, moveToNext, wizard } = this.props;
    const { pcp, isAddMode } = this.state;
    if (isAddMode) {
      return (
        <PcpPickForm
          {...this.props}
          currentPcp={pcp}
          onCanceled={() => {
            this.setState({ isAddMode: false });
          }}
          onSaved={() => {
            this.setState({
              isAddMode: false,
            });
          }}
        />
      );
    }
    return (
      <Card p={3} mb={5} mx={3} style={{ zIndex: 1 }}>
        <Text fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
          {data.title}
        </Text>

        <Fragment>
          <Flex flex={1} flexDirection="column">
            <Box m={2}>
              <Mutation
                mutation={REMOVE_PCP}
                refetchQueries={[{ query: GET_USER_PCP }]}
                awaitRefetchQueries={true}
                onCompleted={() => {
                  this.setState({
                    pcp: undefined,
                  });
                }}
              >
                {(removingPcp, { loading }) => {
                  const deleting = loading;
                  return (
                    <Query
                      query={GET_USER_PCP}
                      // onCompleted={data => {
                      //   console.log('OnCompleted', data)
                      //   if (!pcp && data.getPcp) {
                      //     this.setState({
                      //       pcp: data.getPcp,
                      //     });
                      //   }
                      // }}
                    >
                      {({ loading, error, data }) => {
                        if (loading) {
                          return (
                            <Flex flex={1} justifyContent="center" alignItems="center">
                              <SpinLoader />
                            </Flex>
                          );
                        }

                        if (error) {
                          return (
                            <Flex flex={1} justifyContent="center" alignItems="center">
                              <Text fontSize={5} fontWeight={600} textAlign="center">
                                Unable to retrieve Primary Care Provider. Please try again.
                              </Text>
                            </Flex>
                          );
                        }

                        if (data && !data.getPcp) {
                          return (
                            <div
                              style={{
                                padding: '16px',
                                border: '1px solid #344f77',
                                borderRadius: '4px',
                                marginTop: '36px',
                                position: 'relative',
                              }}
                            >
                              <Flex flex={1} flexDirection={['column', 'row', 'row']}>
                                <Flex flexDirection="column" fontWeight="300" flex="1">
                                  <Text
                                    color="#344f77"
                                    textAlign="left"
                                    fontSize="20px"
                                    fontWeight="bold"
                                    style={{
                                      marginBottom: '16px',
                                    }}
                                  >
                                    Primary Care Provider
                                  </Text>
                                  <Text fontStyle="italic">Paloma Health can send a report of your visit to your primary care provider for continuity of care</Text>
                                </Flex>
                                <Flex flexDirection="column">
                                  <Button
                                    variant="pink"
                                    style={{
                                      padding: '12px 24px',
                                      marginTop: '24px',
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        isAddMode: true,
                                      })
                                    }
                                  >
                                    Add a Provider
                                  </Button>
                                </Flex>
                              </Flex>
                            </div>
                          );
                        }

                        if (!pcp && data.getPcp) {
                          this.setState({
                            pcp: data.getPcp,
                          });
                        }
                        return (
                          <div
                            style={{
                              padding: '16px',
                              border: '1px solid #344f77',
                              borderRadius: '4px',
                              marginTop: '36px',
                              position: 'relative',
                            }}
                          >
                            <Flex flex={1} flexDirection={['column', 'row', 'row']}>
                              <Flex flexDirection="column" fontWeight="300" flex="1">
                                <Text
                                  color="#344f77"
                                  textAlign="left"
                                  fontSize="20px"
                                  fontWeight="bold"
                                  style={{
                                    marginBottom: '16px',
                                  }}
                                >
                                  {data.getPcp.fullname || `${data.getPcp.firstname} ${data.getPcp.lastname}`}
                                </Text>
                                <Text>
                                  <span
                                    style={{
                                      fontStyle: data.getPcp.city ? 'normal' : 'italic',
                                    }}
                                  >
                                    {data.getPcp.city || 'No City Defined'}
                                  </span>
                                  <span
                                    style={{
                                      fontStyle: data.getPcp.state ? 'normal' : 'italic',
                                    }}
                                  >
                                    , {data.getPcp.state || 'No State Defined'}
                                  </span>
                                </Text>
                                <Text>
                                  <span
                                    style={{
                                      fontStyle: data.getPcp.phone ? 'normal' : 'italic',
                                    }}
                                  >
                                    {data.getPcp.phone || 'No Phone on File'}
                                  </span>
                                </Text>
                                <Text>
                                  <span
                                    style={{
                                      fontStyle: data.getPcp.practice ? 'normal' : 'italic',
                                    }}
                                  >
                                    {data.getPcp.practice || ''}
                                  </span>
                                </Text>
                              </Flex>

                              {!deleting && (
                                <Flex flexDirection="column">
                                  <Button
                                    variant="outline"
                                    style={{
                                      padding: '12px 24px',
                                      marginTop: '12px',
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        isAddMode: true,
                                      })
                                    }
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    variant="outline"
                                    style={{
                                      padding: '12px 24px',
                                      marginTop: '12px',
                                    }}
                                    onClick={() => {
                                      removingPcp();
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Flex>
                              )}
                            </Flex>
                            {deleting && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  background: 'rgba(255,255,255,0.5)',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  display: 'flex',
                                  fontWeight: '600',
                                  color: '#757575',
                                }}
                              >
                                <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="row">
                                  <SpinLoader width={25} height={25} />
                                  &nbsp;&nbsp;Removing PCP
                                </Flex>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </Query>
                  );
                }}
              </Mutation>
            </Box>
          </Flex>
          <div
            style={{
              height: '1px',
              backgroundColor: '#ccc',
              margin: '40px -16px 20px',
            }}
          />

          <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column" textAlign="center">
            {!pcp ? (
              <Text
                textAlign="center"
                mb={1}
                fontSize="18px"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  moveToNext(wizard, data);
                }}
              >
                <span style={{ color: '#364f79' }}>Skip</span>
                <span style={{ color: '#f9a394', fontWeight: '600' }}>{' >'}</span>
              </Text>
            ) : (
              <Button
                variant="pink"
                width={[1 / 2, 1 / 3]}
                type="button"
                onClick={() => {
                  moveToNext(wizard, data);
                }}
              >
                Next
              </Button>
            )}
          </Flex>
        </Fragment>
      </Card>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(PcpPick);
