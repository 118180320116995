import React, { useState } from 'react';
import { Button, Card, Flex, Text, Box, Heading } from 'rebass';
import styled from 'styled-components';
import ConfirmModal from '../components/ConfirmModal';
import { Mutation, Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import PleaseWait from '../components/PleaseWait';
import { Input } from '../components/TextInput';

export const ADMIN_BANNERS_LIST = gql`
  query getAdminFilterBannersList {
    getAdminFilterBannersList {
      id
      name
      isPublished
      activeVersion
      athenaSpecific
    }
  }
`;

export const ADMIN_BANNERS_CREATE = gql`
  mutation adminCreateFilterBanner($name: String!) {
    adminCreateFilterBanner(name: $name)
  }
`;

export const ADMIN_BANNERS_DELETE = gql`
  mutation adminDeleteFilteredBanner($bannerId: String!) {
    adminDeleteFilteredBanner(bannerId: $bannerId) {
      ok
    }
  }
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  min-width: 1024px;
  max-height: 95vh;
  width: 100%;
  overflow-y: auto;
`;
const ModalInnerSmall = styled(Box)`
  max-width: 600px;
  min-width: 600px;
  max-height: 95vh;
  width: 100%;
  overflow-y: auto;
`;
const Container = styled(Flex)`
  width: 100%;
  /* background: #fdf5f2; //#f8f9fa; */
  height: 100%;
  max-width: 1024px;
  min-width: 1024px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: block;
  margin: 0 auto;
  padding: 24px;
`;

const AdminBannerFilterList = ({ history }) => {
  const [isAddNew, setIsAddNew] = useState(false);
  const [filter, setFilter] = useState(null);
  const [newName, setNewName] = useState('');
  const [toDelete, setToDelete] = useState(null);

  return (
    <Container>
      <Card flex={1} style={{ padding: '24px', borderRadius: '24px' }}>
        <Flex mb={4}>
          <Heading flex={1}>Users filtered banners</Heading>
          <Button variant={'primary'} onClick={() => setIsAddNew(true)} style={{ padding: '8px 16px', fontSize: '12px', fontWeight: 300 }}>
            Add New
          </Button>
        </Flex>
        <Query query={ADMIN_BANNERS_LIST}>
          {({ data, loading, error }) => {
            if (!!loading) return <PleaseWait text={' '} />;
            return (
              <>
                <Input value={filter} onChange={(e) => setFilter(e.target.value)} placeholder={'Search in name or Athena id specific'} style={{ padding: '12px 6px', fontSize: '13px' }} />
                <Text mb={3} mt={4} fontWeight={600}>
                  Templates:
                </Text>
                {!!filter &&
                  filter.length > 0 &&
                  (data.getAdminFilterBannersList || []).filter((x) => {
                    if (!filter || filter.length < 1) return true;

                    if ((x.name || '').toLowerCase().indexOf(filter.toLowerCase()) > -1) return true;
                    if (x.athenaSpecific && x.athenaSpecific.split(';').indexOf(filter) > -1) return true;

                    return false;
                  }).length < 1 && (
                    <Text style={{ textAlign: 'center', opacity: 0.5 }} mt={3}>
                      No banner matching filter
                    </Text>
                  )}
                {(data.getAdminFilterBannersList || [])
                  .filter((x) => {
                    if (!filter || filter.length < 1) return true;

                    if ((x.name || '').toLowerCase().indexOf(filter.toLowerCase()) > -1) return true;
                    if (x.athenaSpecific && x.athenaSpecific.split(';').indexOf(filter) > -1) return true;

                    return false;
                  })
                  .sort((a, b) => {
                    if (a.isPublished && !b.isPublished) return -1;
                    if (!a.isPublished && b.isPublished) return 1;

                    if (a.name < b.name) return -1;
                    return 0;
                  })
                  .map((x) => {
                    return (
                      <Box
                        flex={1}
                        style={{ borderBottom: '1px solid black', cursor: 'pointer' }}
                        p={3}
                        key={x.id}
                        onClick={() => {
                          history.push(`/AdminBannerFilterBuilder/${x.id}`);
                        }}
                      >
                        <Flex>
                          <Flex flex={1}>
                            <Text style={{ opacity: !!x.isPublished ? 1 : 0.5 }}>{x.name}</Text>
                            <Flex flex={1} flexWrap={'wrap'} ml={3}>
                              {x.athenaSpecific &&
                                x.athenaSpecific.length > 0 &&
                                (x.athenaSpecific || '').split(';').map((xx) => {
                                  if (!xx) return null;
                                  return (
                                    <Box
                                      key={`${x.id}__${xx}`}
                                      p={'2px 6px'}
                                      fontSize={'13px'}
                                      backgroundColor={'#fbfbfb'}
                                      style={{ border: '1px solid #ddd', background: '#fdfdfd', borderRadius: '4px', color: '#869ba7', height: 'fit-content', width: 'fit-content' }}
                                      mr={2}
                                    >
                                      {xx}
                                    </Box>
                                  );
                                })}
                            </Flex>
                          </Flex>
                          <Flex
                            alignItems={'center'}
                            ml={3}
                            mr={3}
                            style={{
                              fontSize: '12px',
                              background: x.isPublished ? '#def9de' : '#f8f4ed',
                              color: `${x.isPublished ? 'green' : 'orange'}`,
                              border: `1px solid ${x.isPublished ? 'green' : 'orange'}`,
                              opacity: !!x.isPublished ? 1 : 0.5,
                              borderRadius: '6px',
                            }}
                            p={'0 8px'}
                          >
                            {x.isPublished ? 'ON' : 'OFF'}
                            {/* <Text ml={3} style={{ opacity: !!x.isPublished ? 1 : 0.5 }}>
                            {x.isPublished ? 'ON' : 'OFF'}
                          </Text> */}
                          </Flex>

                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setToDelete(x);
                            }}
                          >
                            <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" fill="#555" />
                          </svg>
                        </Flex>
                      </Box>
                    );
                  })}
              </>
            );
          }}
        </Query>
      </Card>
      {!!isAddNew && (
        <ConfirmModal
          childrenManaged
          isOpen={isAddNew}
          okOnly
          onClose={() => {
            // this.setState({
            //   isCouponBOGOdisabledModal: false,
            // });
          }}
          onConfirm={() => {
            // this.setState({
            //   isCouponBOGOdisabledModal: false,
            // });
          }}
          onBackgroundClick={() => {
            // this.setState({
            //   isCouponBOGOdisabledModal: false,
            // });
          }}
          onEscapeKeydown={() => {
            // this.setState({
            //   isCouponBOGOdisabledModal: false,
            // });
          }}
        >
          <ModalInner id="mi">
            <Flex flexDirection={'column'}>
              <Heading>Create new filtered banner</Heading>

              <Text mt={4} mb={2}>
                Name
              </Text>
              <Input style={{ fontSize: '13px', padding: '12px' }} value={newName} onChange={(e) => setNewName(e.target.value)} />
              <Mutation
                mutation={ADMIN_BANNERS_CREATE}
                variables={{
                  name: newName,
                }}
                onCompleted={(e) => {
                  if (e && e.adminCreateFilterBanner) {
                    setNewName('');
                    setIsAddNew(false);
                    history.push(`/AdminBannerFilterBuilder/${e.adminCreateFilterBanner}`);
                  }
                }}
              >
                {(createBanner, { loading }) => {
                  if (loading) return <PleaseWait />;
                  return (
                    <Flex justifyContent={'center'} alignItems={'center'} mt={4}>
                      <Button variant={'outline'} style={{ width: 'fit-content' }} onClick={() => setIsAddNew(false)}>
                        Cancel
                      </Button>
                      <Button variant={'primary'} ml={3} disabled={!newName} onClick={createBanner}>
                        Create
                      </Button>
                    </Flex>
                  );
                }}
              </Mutation>
            </Flex>
          </ModalInner>
        </ConfirmModal>
      )}

      {!!toDelete && (
        <ConfirmModal
          childrenManaged
          isOpen={!!toDelete}
          okOnly
          onClose={() => {
            // this.setState({
            //   isCouponBOGOdisabledModal: false,
            // });
          }}
          onConfirm={() => {
            // this.setState({
            //   isCouponBOGOdisabledModal: false,
            // });
          }}
          onBackgroundClick={() => {
            setToDelete(null);
          }}
          onEscapeKeydown={() => {
            setToDelete(null);
          }}
        >
          <ModalInnerSmall id="mi">
            <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
              <Heading>Warning</Heading>

              <Text mt={4} mb={4}>
                Are you sure to want to delete the following filtered banner? <span style={{ color: 'red' }}>(this action is irreversible!)</span>
              </Text>
              <Mutation
                mutation={ADMIN_BANNERS_DELETE}
                variables={{
                  bannerId: toDelete.id,
                }}
                refetchQueries={[{ query: ADMIN_BANNERS_LIST }]}
                awaitRefetchQueries
                onCompleted={(e) => {
                  if (e && e.adminDeleteFilteredBanner && e.adminDeleteFilteredBanner.ok) {
                    setToDelete(null);
                  }
                }}
              >
                {(createBanner, { loading }) => {
                  if (loading) return <PleaseWait />;
                  return (
                    <Flex justifyContent={'center'} alignItems={'center'} mt={4}>
                      <Button variant={'primary'} style={{ width: 'fit-content' }} onClick={() => setToDelete(null)}>
                        Cancel
                      </Button>
                      <Button variant={'danger'} ml={3} onClick={createBanner}>
                        Delete
                      </Button>
                    </Flex>
                  );
                }}
              </Mutation>
            </Flex>
          </ModalInnerSmall>
        </ConfirmModal>
      )}
    </Container>
  );
};

export default withRouter(AdminBannerFilterList);
