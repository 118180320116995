import React, { Component } from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import { Wizard } from 'react-albus';

import UploadLabResults from '../components/intake/UploadLabResults';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;
class UploadLabResultsPage extends Component {
  state = {
    uploadedLabResults: [],
  };

  uploadFile = (file, labResultId) => {
    const files = this.state.uploadedLabResults.filter(x => {
      return x.labResultId.toString() !== '-1';
    });

    files.push({
      date: new Date(),
      filename: file.name,
      labResultId: labResultId,
    });

    this.setState({
      uploadedLabResults: files,
    });
  };

  startUploadFileProcess = file => {
    const files = this.state.uploadedLabResults;

    files.push({
      date: new Date(),
      filename: file.name,
      labResultId: -1,
    });

    this.setState({
      uploadedLabResults: files,
    });
  };

  deleteFile = labResultId => {
    const files = this.state.uploadedLabResults.filter(x => {
      return x.labResultId.toString() !== labResultId.toString();
    });

    this.setState({
      uploadedLabResults: files,
    });
  };
  render() {
    const { uploadedLabResults } = this.state;
    return (
      <Container>
        <Wizard>
          <UploadLabResults
            data={{
              prompt: 'Upload Your Lab Results',
            }}
            isStandaloneMode
            onSubmit={() => {}}
            uploadedLabResults={uploadedLabResults}
            startUploadFileProcess={this.startUploadFileProcess}
            uploadFile={this.uploadFile}
            deleteFile={this.deleteFile}
            history={this.props.history}
          />
        </Wizard>
      </Container>
    );
  }
}

export default UploadLabResultsPage;
