import React, { useState } from 'react';
import { Flex, Text } from 'rebass';
import Select from 'react-select';

export const DropdownCtrl = ({ properties }) => {
  const [value, setValue] = useState();
  return (
    <>
      <Flex flexDirection={'column'} flex={1 / 4}>
        <Text onClick={() => { }} fontWeight={500}>
          {`${(properties || {}).question || 'Question'}${((properties || {}).ctrlProps || {}).required ? '*' : ''}`}
        </Text>
        <Text fontSize={'12px'} fontWeight={300}>
          {(properties || {}).description || 'Description'}
        </Text>
      </Flex>
      <Flex flex={1} justifyContent={'flex-start'}>
        <div style={{ maxWidth: '500px', alignSelf: 'center', width: '100%' }}>
          <Select
            classNamePrefix="region-select"
            {...(properties || {}).ctrlProps}
            style={{
              minWidth: '400px',
            }}
            // options={insuranceCompaniesList}
            // placeholder="Search"
            // isSearchable={false}
            value={value}
            onChange={(e) => setValue(e)}
            noOptionsMessage={() => (
              <div style={{ padding: '12px 24px' }}>
                <Text textAlign="left" mb={2} fontSize="16px" fontWeight="400">
                  No options set
                </Text>
              </div>
            )}
            theme={(base) => ({
              ...base,
              colors: {
                ...base.colors,
                primary: '#364f79',
                primary50: '#dae6fa',
              },
            })} />
        </div>
      </Flex>
    </>
  );
};
