import React, { Component, Fragment } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Mutation, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';

import TextInput from '../components/TextInput';
import FormField from '../components/FormField';
import FullLayout from '../components/FullLayout';
import Header from '../components/Header';
import withAnalytics from '../lib/withAnalytics';
import {
  CHECK_UNLOCK_VALUES,
  UNLOCK_ACCOUNT,
  CHECK_LOCKED_ACCOUNT_UID,
  GET_ANALYTICS_ID,
} from '../graphql';
import moment from 'moment';
import { MaskInput } from '../components/MaskInput';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const CardContainer = styled(Flex)`
  max-width: 400px;
`;

class UnlockAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValuesChecked: false,
      isOriginalCheckComplete: false,
      invalidUid: false
    };
  }
  componentDidMount() {
    this.props.analytics.page();
    localStorage.removeItem('initialToken');
    this.initialCheck()
  }

  initialCheck = async () => {
    const { client, match, history } = this.props;
    if (!match || !match.params || !match.params.uid) {
      history.push('/');
    }

    try {
      const res = await client.mutate({
        mutation: CHECK_LOCKED_ACCOUNT_UID,
        variables: {
          uid: match.params.uid
        }
      })

      // console.log('RES', res)
      if(!res.data || !res.data.checkLockedAccountUid|| !res.data.checkLockedAccountUid.ok){
        this.setState({
          isOriginalCheckComplete: true,
          invalidUid: true
        })
      } else {
        this.setState({
          isOriginalCheckComplete: true
        })
      }

    } catch (error) {
      
      console.log('RES error', error)
    }
  };

  handleSubmit = async (values, login) => {
    const { toastManager } = this.props;

    try {
      await login({
        variables: { ...values, uid: this.props.match.params.uid },
      });
      this.setState({
        ...values,
        uid: this.props.match.params.uid,
        isValuesChecked: true,
      });
    } catch (error) {
      if (!error.graphQLErrors) {
        return;
      }

      error.graphQLErrors.forEach(error => {
        toastManager.add(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
    }
  };

  handleSubmitReactivate = async (values, login, actions) => {
    const { toastManager, analytics } = this.props;

    if (!values.password) {
      toastManager.add('Invalid Password', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    if (!values.passwordConfirm) {
      toastManager.add('Invalid Password Confirm', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    if (values.password !== values.passwordConfirm) {
      toastManager.add(`Password Confirm doesn't match`, {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    const { email, dob, uid } = this.state;
    try {
      const { data } = await login({
        variables: { ...values, email, dob, uid },
      });
      // console.log('LOGIN', data);
      // const attemptedRoute = localStorage.getItem('route');
      // localStorage.setItem('token', data.login.token);
      // analytics.identify(data.login.user.id);
      const aa = await this.props.client.query({
        query: GET_ANALYTICS_ID,
        fetchPolicy: "network-only"
      })
      if(aa && aa.data && aa.data.getAnalyticsId){
        analytics.Alias(aa.data.getAnalyticsId)
        analytics.Identify(aa.data.getAnalyticsId)
      }
      toastManager.add('Account Unlocked', {
        appearance: 'success',
        autoDismiss: true,
      });
      this.props.history.push('/');
    } catch (error) {
      if (!error.graphQLErrors) {
        return;
      }

      error.graphQLErrors.forEach(error => {
        // console.log(error);
        toastManager.add(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
    }
  };

  render() {
    const { isValuesChecked, isOriginalCheckComplete, invalidUid } = this.state;
// console.log('isOriginalCheckComplete', {isOriginalCheckComplete, invalidUid})

    if (!isOriginalCheckComplete) {
      return (
        <Fragment>
          <Container>
            <Flex flex={1}>
            <Header dark={false} />
            </Flex>
            <Flex flex={1} alignItems="center" justifyContent="center">
              <CardContainer flex={1} px={3}>
                <Card p={4}>
                  <Flex flexDirection="column">
                    <Box>
                      <Heading textAlign="center" mb={4}>
                        Unlock Account
                      </Heading>
                    </Box>
                    
                  </Flex>
                </Card>
              </CardContainer>
            </Flex>
          </Container>
          <FullLayout />
        </Fragment>
      );
    }
    if (invalidUid && isOriginalCheckComplete) {
      return (
        <Fragment>
          <Container>
            <Flex flex={1}>
            <Header dark={false} />
            </Flex>
            <Flex flex={1} alignItems="center" justifyContent="center">
              <CardContainer flex={1} px={3}>
                <Card p={4}>
                  <Flex flexDirection="column">
                    <Box>
                      <Heading textAlign="center" mb={4}>
                        Unlock Account
                      </Heading>
                    <Text textAlign="center" mb={4}>
                      No Account Found
                    </Text>
                    </Box>
                    <Button variant='primary' onClick={()=>{
                      this.props.history.push('/')
                    }}>Go Back To Login Page</Button>
                  </Flex>
                </Card>
              </CardContainer>
            </Flex>
          </Container>
          <FullLayout />
        </Fragment>
      );
    }
    
    // console.log('uid', uid);
    const schema = Yup.object().shape({
      email: Yup.string()
        .email('Invalid email')
        .required('Required'),
      dob: Yup.date()
        .transform(value => {
          return moment(value).toDate();
        })
        .required('Required'),
    });
    const schema2 = Yup.object().shape({
      password: Yup.string()
        .trim()
        .min(8, 'Password must be greater than 8 characters')
        .required('Required'),
      passwordConfirm: Yup.string()
        .trim()
        .min(8, 'Password must be greater than 8 characters')
        .required('Required'),
    });

    return (
      <Fragment>
        <Container>
          <Flex flex={1}>
            <Header dark={false} />
          </Flex>
          <Flex flex={1} alignItems="center" justifyContent="center">
            <CardContainer flex={1} px={3}>
              <Card p={4}>
                <Flex flexDirection="column">
                  <Box mb={4}>
                    <Heading textAlign="center" mb={4}>
                      Unlock Account
                    </Heading>
                    <Text textAlign="center">
                      {!isValuesChecked
                        ? 'In order to unlock your account, please fill your email address and date of birth'
                        : 'Enter a new password'}
                    </Text>
                  </Box>
                  {!isValuesChecked ? (
                    <Mutation mutation={CHECK_UNLOCK_VALUES}>
                      {(checkUnlockValues, { loading }) => {
                        return (
                          <Fragment>
                            <Formik
                              initialValues={{
                                email: '',
                                dob: null,
                              }}
                              validationSchema={schema}
                              onSubmit={(values, actions) =>
                                this.handleSubmit(
                                  values,
                                  checkUnlockValues,
                                  actions
                                )
                              }
                              render={({
                                touched,
                                errors,
                                isValid,
                                values,
                                handleBlur,
                                handleChange,
                              }) => (
                                <Form>
                                  <FormField
                                    mb={3}
                                    error={touched.email && errors.email}
                                  >
                                    <TextInput
                                      placeholder="Your email address"
                                      type="email"
                                      name="email"
                                      id="input_email"
                                    />
                                  </FormField>

                                  <FormField
                                    mb={4}
                                    error={touched.dob && errors.dob}
                                  >
                                    <MaskInput
                                      name="dob"
                                      placeholder="Your Date of Birth (MM/DD/YYYY)"
                                      mask="99/99/9999"
                                      value={values.dob}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      id="input_dob"
                                    />
                                  </FormField>

                                  <Flex
                                    alignItems="center"
                                    flexDirection="column"
                                  >
                                    <Button
                                      disabled={!isValid || loading}
                                      variant="primary"
                                      width={1 / 2}
                                      type="submit"
                                      id="btn_login"
                                    >
                                      Verify
                                    </Button>
                                  </Flex>
                                </Form>
                              )}
                            />
                          </Fragment>
                        );
                      }}
                    </Mutation>
                  ) : (
                    <Mutation mutation={UNLOCK_ACCOUNT}>
                      {(unlock, { loading }) => {
                        return (
                          <Fragment>
                            <Formik
                              initialValues={{
                                password: '',
                                passwordConfirm: '',
                              }}
                              validationSchema={schema2}
                              onSubmit={(values, actions) =>
                                this.handleSubmitReactivate(
                                  values,
                                  unlock,
                                  actions
                                )
                              }
                              render={({
                                touched,
                                errors,
                                isValid,
                                values,
                                handleBlur,
                                handleChange,
                              }) => (
                                <Form>
                                  <FormField
                                    mb={3}
                                    error={touched.password && errors.password}
                                  >
                                    <TextInput
                                      placeholder="Your New Password"
                                      type="password"
                                      name="password"
                                      id="input_password"
                                    />
                                  </FormField>

                                  <FormField
                                    mb={4}
                                    error={
                                      touched.passwordConfirm &&
                                      errors.passwordConfirm
                                    }
                                  >
                                    <TextInput
                                      placeholder="Confirm New Password"
                                      type="password"
                                      name="passwordConfirm"
                                      id="input_passwordConfirm"
                                    />
                                  </FormField>

                                  <Flex
                                    alignItems="center"
                                    flexDirection="column"
                                  >
                                    <Button
                                      disabled={!isValid || loading}
                                      variant="primary"
                                      width={1 / 2}
                                      type="submit"
                                      id="btn_login"
                                    >
                                      Unlock
                                    </Button>
                                  </Flex>
                                </Form>
                              )}
                            />
                          </Fragment>
                        );
                      }}
                    </Mutation>
                  )}
                </Flex>
              </Card>
            </CardContainer>
          </Flex>
        </Container>
        <FullLayout />
      </Fragment>
    );
  }
}

export default withApollo(withAnalytics(withToastManager(UnlockAccount)));
