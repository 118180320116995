import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { withWizard } from 'react-albus';
import Mailcheck from 'react-mailcheck';

import { TextInput } from '../../../components/TextInput';
import { FormField } from '../../../components/FormField';
import { MaskInput } from '../../../components/MaskInput';
import styled from 'styled-components';

const MailcheckWrapper = styled.div`
  text-align: right;
  @media screen and (max-width: 52em) {
    text-align: left;
  }
`;

const MailcheckButton = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  font-size: inherit;
  font-style: italic;
  color: rgb(237, 183, 168);
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;

  &:focus {
    outline: none;
    color: rgb(237, 183, 168);
  }

  &:hover {
    color: rgb(237, 183, 168);
  }

  &:active {
    color: rgb(237, 183, 168);
  }
`;
const minDob = moment()
  .subtract(18, 'years')
  .toDate();

const maxDob = moment()
  .subtract(125, 'years')
  .toDate();

let schemaSelf = Yup.object().shape({
  employeeEmail: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

let schemaPartner = Yup.object().shape({
  employeeEmail: Yup.string()
    .email('Invalid email')
    .required('Required'),
  dob: Yup.date()
    .transform((value) => {
      return moment(value).toDate();
    })
    .min(maxDob, 'Must be younger than 125 years')
    .max(minDob, 'Must be 18 years or older')
    .required('Required'),

    employeeFirstName: Yup.string()
    .trim()
    .required('Required'),
  employeeLastName: Yup.string()
    .trim()
    .required('Required'),
});

export const B2bSelf = ({ wizard, onSubmit, values }) => {
  return (
    <Flex mb={4} flex={1} flexDirection={['column', 'column', 'row']}>
      <Flex flex={1} mr={[0, 0, 4]} mb={[4, 4, 0]}>
        <Card p={4}>
          <Flex flexDirection="column">
            <Box mb={4}>
              <Heading textAlign="center" mb={3}>
              One Last Step
              </Heading>
              <Text textAlign="center">Please enter the work email that the sponsored individual has on file with their organization</Text>
            </Box>
            <Formik
              isInitialValid={({ initialValues }) => schemaSelf.isValidSync(initialValues)}
              initialValues={values}
              validationSchema={schemaSelf}
              onSubmit={(values, actions) =>
                onSubmit(
                  {
                    ...values,
                    employeeDob: values.dob,
                    employeeFirstName: values.firstName,
                    employeeLastName: values.lastName,
                  },
                  actions,
                  wizard
                )
              }
              render={({ isValid, errors, touched, handleChange, handleBlur, setFieldValue, values }) => {
                console.log({values, isValid})
                return (
                  <Form>
                    <Box>
                      <FormField mb={3} error={touched.employeeEmail && errors.employeeEmail}>
                        <Mailcheck email={values.employeeEmail}>
                          {(suggestion) => (
                            <>
                              <TextInput
                                placeholder="Your Work Email Address"
                                type="email"
                                name="employeeEmail"
                                id="input_email"
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldValue('employeeEmail', e.target.value);
                                }}
                              />
                              {suggestion && (
                                <MailcheckWrapper className="mailcheck">
                                  Did you mean{' '}
                                  <MailcheckButton type="button" onClick={() => setFieldValue('employeeEmail', suggestion.full)}>
                                    {suggestion.full}
                                  </MailcheckButton>
                                  ?
                                </MailcheckWrapper>
                              )}
                            </>
                          )}
                        </Mailcheck>
                      </FormField>
                    </Box>

                    <Flex justifyContent="center">
                      <Button variant="primary" width={[1 / 2, 1 / 3]} type="submit" disabled={!isValid} id="btn_next">
                        Submit
                      </Button>
                    </Flex>
                  </Form>
                );
              }}
            />
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

export const B2bPartner = ({ wizard, onSubmit, values }) => {
  return (
    <Flex mb={4} flex={1} flexDirection={['column', 'column', 'row']}>
      <Flex flex={1} mr={[0, 0, 4]} mb={[4, 4, 0]}>
        <Card p={4}>
          <Flex flexDirection="column">
            <Box mb={4}>
              <Heading textAlign="center" mb={3}>
              One Last Step
              </Heading>
              <Text textAlign="center">Please enter the work email that the sponsored individual has on file with their organization</Text>
            </Box>
            <Formik
              isInitialValid={({ initialValues }) => schemaPartner.isValidSync(initialValues)}
              initialValues={values}
              validationSchema={schemaPartner}
              onSubmit={(values, actions) => onSubmit(values, actions, wizard)}
              render={({ isValid, errors, touched, handleChange, handleBlur, setFieldValue, values }) => {
                const dobError = errors.dob && errors.dob !== 'Invalid Date' && errors.dob.indexOf('dob') !== 0 ? errors.dob : null;

                return (
                  <Form>
                    <Box>
                      <FormField mb={3} error={touched.employeeEmail && errors.employeeEmail}>
                        <Mailcheck email={values.employeeEmail}>
                          {(suggestion) => (
                            <>
                              <TextInput
                                placeholder="Your Parner Work Email Address"
                                type="email"
                                name="employeeEmail"
                                id="input_email"
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldValue('employeeEmail', e.target.value);
                                }}
                              />
                              {suggestion && (
                                <MailcheckWrapper className="mailcheck">
                                  Did you mean{' '}
                                  <MailcheckButton type="button" onClick={() => setFieldValue('employeeEmail', suggestion.full)}>
                                    {suggestion.full}
                                  </MailcheckButton>
                                  ?
                                </MailcheckWrapper>
                              )}
                            </>
                          )}
                        </Mailcheck>
                      </FormField>
                      <Flex flexDirection={['column', 'column', 'row']}>
                        <FormField flex={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.employeeFirstName && errors.employeeFirstName}>
                          <TextInput placeholder="Employee First Name" type="text" name="employeeFirstName" id="input_firstname" />
                        </FormField>
                        <FormField flex={[1, 1, 1 / 2]} mb={3} error={touched.employeeLastName && errors.employeeLastName}>
                          <TextInput placeholder="Employee Last Name" type="text" name="employeeLastName" id="input_lastname" />
                        </FormField>
                      </Flex>

                      <FormField mb={4} error={touched.employeeDob && dobError}>
                        <MaskInput
                          name="employeeDob"
                          placeholder="Your partner Date of Birth (MM/DD/YYYY)"
                          mask="99/99/9999"
                          value={values.employeeDob}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="input_dob"
                        />
                      </FormField>
                    </Box>

                    <Flex justifyContent="center">
                      <Button variant="primary" width={[1 / 2, 1 / 3]} type="submit" disabled={!isValid} id="btn_next">
                        Next
                      </Button>
                    </Flex>
                  </Form>
                );
              }}
            />
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

const B2bInformationDefault = ({ wizard, values, onSubmit }) => {
  if ((values || {}).relationship === 'self') {
    return <B2bSelf wizard={wizard} onSubmit={onSubmit} values={values} />;
  }
  if ((values || {}).relationship !== 'self') {
    return <B2bPartner wizard={wizard} onSubmit={onSubmit} values={values} />;
  }
};

export default withWizard(B2bInformationDefault);