import React from 'react';
import { Box } from 'rebass';
import TextTransition, { presets } from 'react-text-transition';
import useUnmount from '../../utils/hooks/lifecycle/useUnmount';

const TEXTS = [
  "Ain't no tired like thyroid tired.\nTake a 30 second power nap while we access your file.",
  'Did you know hydration is a pillar of thyroid health?\nPour a drink of water while you wait.',
  'Close your eyes & breathe while we access your file.\nYour adrenals will thank you!',
  'Please hold while we access your info\nso you can start feeling better faster than this page loads.',
  'Say something nice to yourself while you wait.\nSelf-compassion is part of a thyroid-healthy lifestyle.',
  'Time for a 30-second dance party!\nGet those endorphins going and blood flowing.',
  'Text a friend while you wait!\nStudies show that positive relationships are good for our health.',
];

export const RotatingText = props => {
  const [index, setIndex] = React.useState(0);
  const [intervalId, setIntervalId] = React.useState(null);
  const [arr, setArr] = React.useState(
    TEXTS.map(a => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map(a => a.value)
  );

  React.useEffect(() => {
    const handle = setInterval(
      () => setIndex(index => index + 1),
      3000 // every 3 seconds
    );
    setIntervalId(handle);
  }, []);

  useUnmount(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  });

  return (
    <Box
      style={{
        whiteSpace: 'nowrap',
        marginTop: '40px',
        width: 'min(400px, 80vw)',
        maxWidth: 'min(400px, 80vw)',
        justifyContent: 'center',
      }}
    >
      <TextTransition
        noOverflow
        style={{
          whiteSpace: 'nowrap',
          wordWrap: 'break-word',
        }}
        text={arr[index % arr.length]}
        springConfig={presets.default}
      />
    </Box>
  );
};