import React, { Fragment } from 'react';
import { Box, Button, Card, Flex, Heading } from 'rebass';
import { Redirect } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Mutation } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import gql from 'graphql-tag';
import styled from 'styled-components';

import TextInput from '../components/TextInput';
import FormField from '../components/FormField';
import FullLayout from '../components/FullLayout';
import Header from '../components/Header';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  password: Yup.string()
    .trim()
    .min(8, 'Password must be greater than 8 characters')
    .required('Required'),
  passwordConfirm: Yup.string()
    .trim()
    .oneOf([Yup.ref('password')], 'Passwords do not match')
    .required('Required'),
});

const PASSWORD_RESET = gql`
  mutation PasswordResetMobile(
    $email: String!
    $resetToken: String!
    $password: String!
  ) {
    passwordResetMobile(email: $email, resetToken: $resetToken, password: $password) {
      ok
    }
  }
`;

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const CardContainer = styled(Flex)`
  max-width: 400px;
`;

const ResetPasswordMobile = props => {
  const handleSubmit = async (values, passwordReset) => {
    const { match, toastManager } = props;
    const { email, password } = values;
// console.log('handleSubmit')
    try {
      await passwordReset({ variables: { email, password, resetToken: match.params.id } });
    } catch (error) {
      error.graphQLErrors.forEach(error => {
        toastManager.add(error.message, { appearance: 'error' });
      });
    }
  };

  return (
    <Fragment>
      <Container>
        <Flex flex={1}>
            <Header dark={false} />
        </Flex>
        <Flex flex={1} alignItems="center" justifyContent="center" mb={5}>
          <CardContainer flex={1} px={3}>
            <Card p={4}>
              <Flex flexDirection="column">
                <Box mb={4}>
                  <Heading textAlign="center" fontWeight={500} mb={3}>
                    Reset Password
                  </Heading>
                </Box>
                <Mutation mutation={PASSWORD_RESET}>
                  {(passwordReset, { loading, data }) => {
                    if (data) {
                      return <Redirect to="/login" />;
                    }

                    return (
                      <Formik
                        initialValues={{
                          email: '',
                          password: '',
                          passwordConfirm: '',
                        }}
                        validationSchema={schema}
                        onSubmit={values => handleSubmit(values, passwordReset)}
                        render={({ touched, errors, isValid }) => (
                          <Form>
                            <FormField
                              mb={3}
                              error={touched.email && errors.email}
                            >
                              <TextInput
                                placeholder="Email Address"
                                type="email"
                                name="email"
                              />
                            </FormField>

                            <FormField
                              mb={3}
                              error={touched.password && errors.password}
                            >
                              <TextInput
                                placeholder="New Password"
                                type="password"
                                name="password"
                              />
                            </FormField>

                            <FormField
                              mb={4}
                              error={
                                touched.passwordConfirm &&
                                errors.passwordConfirm
                              }
                            >
                              <TextInput
                                placeholder="Re-enter New Password"
                                type="password"
                                name="passwordConfirm"
                              />
                            </FormField>

                            <Flex justifyContent="center">
                              <Button
                                disabled={!isValid || loading}
                                variant="primary"
                                type="submit"
                              >
                                Reset Password
                              </Button>
                            </Flex>
                          </Form>
                        )}
                      />
                    );
                  }}
                </Mutation>
              </Flex>
            </Card>
          </CardContainer>
        </Flex>
      </Container>

      <FullLayout />
    </Fragment>
  );
};

export default withToastManager(ResetPasswordMobile);
