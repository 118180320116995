import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading, Text, Box } from 'rebass';

import { Formik, Form } from 'formik';
import {
  injectStripe,
  PaymentRequestButtonElement,
} from 'react-stripe-elements';
import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import * as Yup from 'yup';

import { StripeInput } from '../components/StripeInput';
import { FormField } from '../components/FormField';
import InformationSecuredDisclaimer from '../components/InformationSecuredDisclaimer';
import { isToggleActive } from '../components/featureToggle/toggles';

const schema = Yup.object().shape({
  number: Yup.string().required('Required'),
  cvc: Yup.string().required('Required'),
  expire: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
});

class TestPaymentWallet extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      canMakePayment: false,
      paymentRequest:null,
    }
    if (isToggleActive('MOBILE_WALLET')) {
      const paymentRequest = this.props.stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: true,
        shippingOptions: [
          // The first shipping option in this list appears as the default
          // option in the browser payment interface.
          {
            id: 'free-shipping',
            label: 'Free shipping',
            detail: 'Arrives in 5 to 7 days',
            amount: 0,
          },
        ],
        total: {
          label: 'Demo total',
          amount: 100,
        },
      });

      paymentRequest.on('token', async ({ complete, token, ...data }) => {
        console.log('Received Stripe token: ', token);
        console.log('Received customer information: ', data);
        complete('success');
      });


      paymentRequest.canMakePayment().then(result => {
        console.log('paymentRequest.canMakePayment: ', result);

        this.setState({ canMakePayment: !!result,
          paymentRequest, });
      });
    } else {
      this.state = {
        canMakePayment: false,
      };
    }
  }
  render() {
    const createSource = async actions => {};

    const createSourcePayNow = async setSubmitting => {
      setSubmitting(true);
    };

    return (
      <Fragment>
        <Flex flexDirection="column" flex={1}>
          <Card p={4}>
            <Flex flexDirection="column" mb={4}>
              <Heading textAlign="center" style={{ fontWeight: 'bold' }} mb={2}>
                Payment Method
              </Heading>
              <Text textAlign="center" fontWeight={300}>
                You can also use your HSA/FSA card to pay for a kit
              </Text>
            </Flex>

            <Formik
              initialValues={{ number: '', cvc: '', expire: '', zip: '' }}
              validationSchema={schema}
              onSubmit={async (values, actions) => {
                await createSource(actions);
              }}
              render={({
                errors,
                touched,
                isValid,
                isSubmitting,
                setSubmitting,
              }) => {
                return (
                  <Form>
                    <Flex flexDirection="column">
                      <FormField error={touched.number && errors.number} mb={3}>
                        <StripeInput type="number" name="number" />
                      </FormField>

                      <Flex flexDirection={['column', 'column', 'row']} mb={3}>
                        <FormField
                          width={[1, 1, 1 / 3]}
                          mr={[0, 0, 3]}
                          mb={1}
                          error={touched.expire && errors.expire}
                        >
                          <StripeInput type="expire" name="expire" />
                        </FormField>

                        <FormField
                          width={[1, 1, 1 / 3]}
                          mr={[0, 0, 3]}
                          mb={1}
                          error={touched.cvc && errors.cvc}
                        >
                          <StripeInput type="cvc" name="cvc" />
                        </FormField>

                        <FormField
                          width={[1, 1, 1 / 3]}
                          mb={1}
                          error={touched.zip && errors.zip}
                        >
                          <StripeInput type="zip" name="zip" />
                        </FormField>
                      </Flex>
                    </Flex>

                    {this.state && this.state.canMakePayment ? (
                      <Flex alignItems="end" flexDirection={['column', 'row']}>
                        <Box width={[1, 1 / 2]}>
                          <PaymentRequestButtonElement
                            paymentRequest={this.state.paymentRequest}
                            className="PaymentRequestButton"
                            value="Pay Now"
                            style={{
                              // For more details on how to style the Payment Request Button, see:
                              // https://stripe.com/docs/elements/payment-request-button#styling-the-element
                              paymentRequestButton: {
                                theme: 'dark',
                                height: '48px',
                                width: '100%',
                                borderRadius: '20px',
                                marginBottom: '16px',
                              },
                            }}
                          />
                        </Box>
                        <Button
                          disabled={!isValid || isSubmitting}
                          variant="pink"
                          mb={3}
                          width={[1, 1 / 2]}
                          type="submit"
                        >
                          I Have a Coupon
                        </Button>
                      </Flex>
                    ) : (
                      <Flex
                        alignItems="center"
                        flexDirection={['column', 'row']}
                      >
                        <Button
                          disabled={!isValid || isSubmitting}
                          variant="pink"
                          mb={3}
                          mr={[0, 3]}
                          width={[1, 1 / 2]}
                          type="button"
                          onClick={() => createSourcePayNow(setSubmitting)}
                        >
                          Pay Now
                        </Button>
                        <Button
                          disabled={!isValid || isSubmitting}
                          variant="pink"
                          mb={3}
                          width={[1, 1 / 2]}
                          type="submit"
                        >
                          I Have a Coupon
                        </Button>
                      </Flex>
                    )}

                    <InformationSecuredDisclaimer />
                  </Form>
                );
              }}
            />
          </Card>
        </Flex>
      </Fragment>
    );
  }
}

export default injectStripe(
  compose(withApollo, withToastManager)(TestPaymentWallet)
);
