import React from 'react';
import { Flex, Text } from 'rebass';

const FeatureRowHeaders = props => {
  return (
    <Flex flexDirection="row">
      <Text width={1} textAlign='center'>Friendly Name</Text>
      <Text width={1} textAlign='center'>Name</Text>
      <Text width='250px' textAlign='center'>Is Active</Text>
      <Text width={0.5} textAlign='center'></Text>
    </Flex>
  );
};

export default FeatureRowHeaders;
