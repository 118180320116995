import React from 'react';
import { isMobile } from 'react-device-detect';
import { Text, Box, Flex } from 'rebass';
import styled from 'styled-components';

export const TitleSection = styled(Flex)`
  width: 100%;
  position: relative;
  align-items: center;
  margin-bottom: 16px;
`;

export const QuestionNumberSection = styled(Flex)`
  position: absolute;
  right: 100%;
  margin-right: 12px;
  @media screen and (max-width: 52em) {
    display: none;
  }
`;

const Title = styled(Text)`
  font-weight: 500;
  font-size: 24px;

  @media screen and (max-width: 52em) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const FormerTitle = ({ questionNumber, title, isRequired }) => {
  return (
    <TitleSection>
      <QuestionNumberSection>
        <Text ml={2} fontSize="22px">
          {questionNumber}
        </Text>
        <svg
          class="renderer-components-block-counter__arrow-icon"
          style={{
            fontSize: '16px',
            width: '16px',
            strokeWidth: 2,
          }}
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          role="presentation"
        >
          <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" fill="black" />
        </svg>
      </QuestionNumberSection>
      <Title>
        {title}
        <span style={{ color: 'red' }}>{!!isRequired ? '*' : ''}</span>
      </Title>
    </TitleSection>
  );
};

export const SelectButton = ({ text, isSelected, onClick, hasSelection }) => {
  return (
    <Box
      style={{
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        opacity: !!hasSelection ? (isSelected ? 1 : 0.55) : 1,
        minWidth: '40%',
        cursor: 'pointer',
        border: `1px solid ${isSelected ? '#344f79' : '#5f769a'}`,
        background: '#e0e3e9',
        padding: isMobile ? '8px' : '8px 16px',
        fontSize: isMobile ? '14px' : '18px',
      }}
      mr={['4px', 3]}
      mb={['4px', 3]}
      onClick={onClick}
    >
      {text}
    </Box>
  );
};

export const SelectButtonOption = ({ text, isSelected, onClick, hasSelection }) => {
  return (
    <Flex
      style={{
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        minWidth: '100%',
        cursor: 'pointer',
        border: `1px solid ${isSelected ? '#F6A293' : 'white'}`,
        background: isSelected ? '#FCE4E0' : 'white',
        padding: isMobile ? '8px' : '8px 16px',
        fontSize: isMobile ? '14px' : '18px',
        color: isSelected ? '#344f79' : '#344f79',
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.15)',
      }}
      mr={['4px', 3]}
      mb={['4px', 3]}
      onClick={onClick}
    >
      {!isSelected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
          <circle cx="9.04746" cy="9.46586" r="7.94065" fill="white" stroke="#354E79" stroke-width="2" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
          <circle cx="9.04746" cy="9.15727" r="7.94065" fill="white" stroke="#F6A293" stroke-width="2" />
          <circle cx="9.04747" cy="9.15728" r="5.83086" fill="#F6A293" />
        </svg>
      )}
      <Text ml={3}>{text}</Text>
    </Flex>
  );
};
