import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Card, Flex, Heading, Link, Text, Box, Image } from 'rebass';
import styled from 'styled-components';
import { MembershipBulletItem } from '../../../components/MembershipBulletItem';
import discoverPossibleCauses from '../../../static/Character_2.png';
import { IntroSelfPay } from './components/IntroSelfPay';
import { IntroInsurance } from './components/IntroInsurance';
import SwitchSelector from '../../../components/switchSelector/SwitchSelector';
import { Query } from 'react-apollo';
import { GET_INSURANCE_IN_STATE } from '../../../graphql';
import { getLogoSource } from '../../scheduleDoctor/helpers/insuranceLogos';
import PleaseWait from '../../../components/PleaseWait';
import { isToggleActive } from '../../../components/featureToggle/toggles';

const A = styled(Text)`
  text-decoration: underline;
  margin-top: 28px;
  text-align: center;
  cursor: pointer;
`;
const options = [
  {
    label: 'Insurance',
    value: 'Insurance',
  },
  {
    label: 'Cash Pay',
    value: 'CashPay',
  },
];
export const Intro = ({ subscribe, skip }) => {
  const t = isToggleActive('INSURANCE_MEMBERSHIP_WIND_DOWN');
  const [selectedPricing, setSelectedPricing] = useState('CashPay');
  return (
    <Query query={GET_INSURANCE_IN_STATE}>
      {({ data, loading }) => {
        if (loading) {
          return <PleaseWait text="Initializing" />;
        }

        const insuranceState = !t && data && data.getInsurancesAcceptedInState && data.getInsurancesAcceptedInState.length > 0;
        return (
          <Flex flexDirection="column">
            <Heading fontSize="32px" textAlign="center">
              {!t ? 'Take Back Control of Your Thyroid Health' : 'Personalized treatment plans as unique as you are.'}
            </Heading>

            <Text textAlign="center" style={{ marginTop: '24px' }}>
              Our membership program gives you the highest level of care to take the guesswork out of managing your condition.
            </Text>
            {insuranceState && (
              <div className="your-required-wrapper" style={{ width: '300px', height: 38, margin: '0 auto', marginTop: 24 }}>
                <SwitchSelector
                  options={options}
                  initialSelectedIndex={options.findIndex((x) => x.value === 'CashPay')}
                  backgroundColor="#344F7A"
                  selectedBackgroundColor="white"
                  selectedFontColor="#344F7A"
                  fontColor="white"
                  onChange={(v) => {
                    setSelectedPricing(v);
                  }}
                />
              </div>
            )}
            <Flex flexDirection={['column', 'row']} style={{ margin: '32px auto 0 auto' }}>
              {selectedPricing === 'Insurance' && insuranceState ? <IntroInsurance subscribe={subscribe} skip={skip} /> : <IntroSelfPay subscribe={subscribe} skip={skip} />}
              <Card
                style={{
                  position: 'relative',
                  background: 'transparent',
                  padding: '14px',
                  margin: '12px',
                  paddingBottom: isMobile ? '24px' : '24px',
                  boxShadow: 'none',
                  marginBottom: !!skip ? '30px' : '12px',
                  maxWidth: '400px',
                }}
              >
                <Flex flexDirection={'column'} style={{ zIndex: 10 }}>
                  <Text
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      marginBottom: '16px',
                    }}
                  >
                    Also included in your membership:
                  </Text>
                  <MembershipBulletItem showBullet={false} text="• Dedicated Care Manager" />
                  <MembershipBulletItem showBullet={false} text="• Easy medication management" />
                  <MembershipBulletItem showBullet={false} text="• Unlimited chat with your provider" />
                  <MembershipBulletItem showBullet={false} text="• The Paloma private community" />
                  <MembershipBulletItem showBullet={false} text="• Live doctor Q&A" />
                  <MembershipBulletItem showBullet={false} text="• Symptom tracker application" />
                  <MembershipBulletItem showBullet={false} text="• Online courses on thyroid health" />
                  <MembershipBulletItem showBullet={false} text="• Monthly Speaker Series " />
                  <MembershipBulletItem showBullet={false} text="• Cancel anytime " />
                </Flex>
              </Card>
            </Flex>

            {insuranceState && (
              <Flex flexDirection={'column'} mt={4}>
                <Text textAlign={'center'} mb={3}>
                  We work with major insurance plans to keep your costs down for consults. (copays and insurance responsibility may apply)
                </Text>
                <Flex justifyContent={'center'} alignItems="center" flexDirection={['column', 'row']}>
                  <Image src={getLogoSource('aetna')} alt="" height={['20px !important', '24px !important']} mr={['0', '24px']} style={{ objectFit: 'contain' }} />
                  <Image src={getLogoSource('united healthcare')} mt={['22px', '0']} mr={['0', '24px']} alt="" height={'32px !important'} style={{ objectFit: 'contain' }} />
                  <Image src={getLogoSource('blue cross blue shield')} alt="" mr={['0', '24px']} mt={['22px', '0']} height={'32px !important'} style={{ objectFit: 'contain' }} />
                  <Image src={getLogoSource('cigna')} alt="" height={'32px !important'} mr={['0', '24px']} mt={['22px', '0']} style={{ objectFit: 'contain' }} />
                  <Image src={getLogoSource('multiplan')} alt="" height={'32px !important'} mr={['0', '24px']} mt={['22px', '0']} style={{ objectFit: 'contain' }} />
                </Flex>
              </Flex>
            )}
          </Flex>
        );
      }}
    </Query>
  );
};
