import PropTypes from 'prop-types';
import React from 'react';
import updateIn from './simpleUpdateIn';

import EventSpy from '../EventSpy';
import FunctionContext from './FunctionContext';
import InternalContext from './InternalContext';
import SpineTo from '../SpineTo';
import StateContext from './StateContext';

const MIN_CHECK_INTERVAL = 17;

function setImmediateInterval(fn, ms) {
  fn();

  return setInterval(fn, ms);
}

function computeViewState({ stateContext: { mode }, target: { offsetHeight, scrollHeight, scrollTop } }) {
  const atBottom = scrollHeight - scrollTop - offsetHeight <= 0;
  const atTop = scrollTop <= 0;
  const atEnd = mode === 'top' ? atTop : atBottom;

  return {
    atBottom,
    atEnd,
    atStart: !atEnd,
    atTop,
  };
}

export default class Composer extends React.Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);

    this._ignoreScrollEventBefore = 0;

    this.state = {
      functionContext: {
        scrollTo: (scrollTop) =>
          this.setState(({ stateContext }) => ({
            scrollTop,
            stateContext: updateIn(stateContext, ['animating'], () => true),
          })),
        scrollToBottom: () => this.state.functionContext.scrollTo('100%'),
        scrollToEnd: () => {
          const {
            state: { functionContext, stateContext },
          } = this;

          stateContext.mode === 'top' ? functionContext.scrollToTop() : functionContext.scrollToBottom();
        },
        scrollToStart: () => {
          const {
            state: { functionContext, stateContext },
          } = this;

          stateContext.mode === 'top' ? functionContext.scrollToBottom() : functionContext.scrollToTop();
        },
        scrollToTop: () => this.state.functionContext.scrollTo(0),
      },
      internalContext: {
        setTarget: (target) => {
          this.setState(() => ({ target }))
          if(props.forwardRef) {
            props.forwardRef(target)
          }
        },
      },
      scrollTop: props.mode === 'top' ? 0 : '100%',
      stateContext: {
        animating: false,
        atBottom: true,
        atEnd: true,
        atTop: true,
        mode: props.mode,
        sticky: true,
      },
      target: null,
    };
  }

  componentDidMount() {
    this.enableWorker();
  }

  disableWorker() {
    clearInterval(this._stickyCheckTimeout);
  }

  enableWorker() {
    clearInterval(this._stickyCheckTimeout);

    this._stickyCheckTimeout = setImmediateInterval(() => {
      const { state } = this;
      const {
        stateContext: { sticky },
        target,
      } = state;

      if (sticky && target) {
        const { atEnd } = computeViewState(state);

        !atEnd && state.functionContext.scrollToEnd();
      }
    }, Math.max(MIN_CHECK_INTERVAL, this.props.checkInterval) || MIN_CHECK_INTERVAL);
  }

  componentWillUnmount() {
    this.disableWorker();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(({ stateContext }) => ({
      stateContext: {
        ...stateContext,
        mode: nextProps.mode === 'top' ? 'top' : 'bottom',
      },
    }));
  }

  getRealCurrentHeight() {
    const {target} = this.state

    console.log({target})

    return target.scrollHeight
  }

  handleScroll({ timeStampLow }) {
    // Currently, there are no reliable way to check if the "scroll" event is trigger due to
    // user gesture, programmatic scrolling, or Chrome-synthesized "scroll" event to compensate size change.
    // Thus, we use our best-effort to guess if it is triggered by user gesture, and disable sticky if it is heading towards the start direction.

    if (timeStampLow <= this._ignoreScrollEventBefore) {
      // Since we debounce "scroll" event, this handler might be called after spineTo.onEnd (a.k.a. artificial scrolling).
      // We should ignore debounced event fired after scrollEnd, because without skipping them, the userInitiatedScroll calculated below will not be accurate.
      // Thus, on a fast machine, adding elements super fast will lose the "stickiness".

      return;
    }

    this.disableWorker();

    this.setState(
      (state) => {
        const { target } = state;

        if (target) {
          const { scrollTop, stateContext } = state;
          const { atBottom, atEnd, atStart, atTop } = computeViewState(state);
          let nextStateContext = stateContext;

          nextStateContext = updateIn(nextStateContext, ['atBottom'], () => atBottom);
          nextStateContext = updateIn(nextStateContext, ['atEnd'], () => atEnd);
          nextStateContext = updateIn(nextStateContext, ['atStart'], () => atStart);
          nextStateContext = updateIn(nextStateContext, ['atTop'], () => atTop);

          // Sticky means:
          // - If it is scrolled programatically, we are still in sticky mode
          // - If it is scrolled by the user, then sticky means if we are at the end
          nextStateContext = updateIn(nextStateContext, ['sticky'], () => (stateContext.animating ? true : atEnd));

          // If no scrollTop is set (not in programmatic scrolling mode), we should set "animating" to false
          // "animating" is used to calculate the "sticky" property
          if (scrollTop === null) {
            nextStateContext = updateIn(nextStateContext, ['animating'], () => false);
          }

          if (stateContext !== nextStateContext) {
            return { stateContext: nextStateContext };
          }
        }
      },
      () => {
        this.state.stateContext.sticky && this.enableWorker();
      }
    );
  }

  handleScrollEnd() {
    // We should ignore debouncing handleScroll that emit before this time
    this._ignoreScrollEventBefore = Date.now();

    this.setState(() => ({ scrollTop: null }));
  }

  render() {
    const {
      handleScroll,
      handleScrollEnd,
      props: { children, debounce },
      state: { functionContext, internalContext, scrollTop, stateContext, target },
    } = this;

    return (
      <InternalContext.Provider value={internalContext}>
        <FunctionContext.Provider value={functionContext}>
          <StateContext.Provider value={stateContext}>
            {children}
            {target && (
              <EventSpy
                debounce={debounce}
                name="scroll"
                onEvent={(e) => {
                  handleScroll(e);

                  if (this.props.onScroll) {
                    this.props.onScroll(e);
                  }
                }}
                target={target}
              />
            )}
            {target && scrollTop !== null && <SpineTo name="scrollTop" onEnd={handleScrollEnd} target={target} value={scrollTop} />}
          </StateContext.Provider>
        </FunctionContext.Provider>
      </InternalContext.Provider>
    );
  }
}

Composer.defaultProps = {
  checkInterval: 150,
  debounce: 17,
};

Composer.propTypes = {
  checkInterval: PropTypes.number,
  debounce: PropTypes.number,
};
