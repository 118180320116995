import React, { Fragment } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import { Formik, Form } from 'formik';
import { withApollo, compose } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import {
  TextInput,
  MaskInput,
} from '../../../components/async/SharedComponents';
import FormField from '../../../components/FormField';
import TextArea from '../../../components/TextArea';

const StyledText = styled(Text)`
  line-height: 1.6;
  border-bottom: 1px solid;
  text-align: left;

  &:hover {
    cursor: pointer;
    font-weight: 600;
  }
`;

class InputField extends React.Component {
  constructor(props) {
    super(props);

    let defaults;

    if (props.savedData) {
      defaults = this.unFormatSubmit(props.savedData);
    } else {
      defaults = (props.data || {}).defaults;
    }

    this.state = {
      loading: false,
      isInitialized: true,
      defaults,
    };
  }

  handleClick = async element => {
  };

  formatSubmit = values => {
    const { data } = this.props;
    const regex = /_/g;
    let payload = [];

    for (const k in values) {
      const questionId = k.replace(regex, '.');
      let answer;

      if (data.prefix) {
        answer = `${data.prefix} ${values[k]}`;
      } else {
        answer = values[k];
      }

      payload.push({ questionId, answer });
    }

    return payload;
  };

  unFormatSubmit = values => {
    const { data } = this.props;
    let payload = {};

    for (const k in values) {
      const questionId = values[k].questionId;
      let answer;
      // console.log({ k, values });

      if (data.prefix) {
        answer = values[k].answer.replace(`${data.prefix} `, '');
      } else {
        answer = values[k].answer;
      }

      payload[`${questionId.toString()}`] = answer;
    }

    return payload;
  };

  render() {
    const { data, onSubmit, wizard, savedData } = this.props;
    const { defaults, isInitialized } = this.state;
    if (!isInitialized) {
      return <div />;
    }
    return (
      <Fragment>
        <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }}>
          <Box m={2}>
            <Heading textAlign="left">{data.prompt}</Heading>
          </Box>
          {data.subTitle ? (
            <Box m={2} mt="0" mb={3}>
              <Text
                textAlign="left"
                fontSize="16px"
                fontWeight="300"
                lineHeight="24px"
              >
                {data.subTitle}
              </Text>
            </Box>
          ) : null}

          <Formik
            initialValues={defaults}
            validationSchema={data.schema || ''}
            enableReinitialize={true}
            isInitialValid={(aaa, bbb) => {
              if (!data.schema) return true;

              let schema = data.schema;
              try {
                const aa = schema.validateSync(defaults);

                // const bb = await aa.isValid()
                if (aa.errors && aa.errors.length) {
                  return false;
                }
                return true;
              } catch (err) {
                return false;
              }
            }}
            onSubmit={(values, actions) => {
              const formatted = this.formatSubmit(values);
              onSubmit(formatted, data.id, actions, wizard, data);
            }}
            render={({
              isValid,
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
            }) => {
              return (
                <Form>
                  <Flex my={3} flex={1} flexDirection="column">
                    {data.options.map(el => (
                      <Fragment key={el.questionId.toString()}>
                        <FormField
                          width={!data.extend?[1, 1 / 2]: 1}
                          px={3}
                          mb={3}
                          error={
                            touched[el.questionId] && errors[el.questionId]
                          }
                          // alignSelf={"center"}
                        >
                          {!data.extend &&
                            !el.mask && (
                              <TextInput
                                name={el.questionId}
                                placeholder={el.placeholder}
                                mask={el.mask}
                                type={el.inputType || 'text'}
                              />
                            )}
                          {!data.extend &&
                            el.mask && (
                              <FormField
                                mt={3}
                                mb={4}
                                // error={
                                //   touched[el.questionId] &&
                                //   errors[el.questionId]
                                // }
                              >
                                <MaskInput
                                  name={el.questionId}
                                  placeholder={el.placeholder}
                                  mask={el.mask}
                                  type={el.inputType || 'text'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values[el.questionId]}
                                />
                              </FormField>
                            )}

                          {data.extend && (
                            <TextArea
                              name={el.questionId}
                              placeholder={el.placeholder}
                              style={{
                                flexGrow: 1,
                                background: 'white',
                                border: '0px solid white',
                                maxHeight: '150px',
                                minHeight: '59px',
                                height: 'auto',
                              }}
                            />
                          )}
                        </FormField>
                      </Fragment>
                    ))}
                  </Flex>

                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    mx={[3, 3, 0]}
                    my={[2, 3]}
                  >
                    <Button
                      disabled={!isValid || this.state.loading}
                      variant="primary"
                      width={[1, 1 / 2, 1 / 4]}
                      type="submit"
                    >
                      Continue
                    </Button>

                    {data.hasSecondOption && (
                      <Button
                        disabled={this.state.loading}
                        variant="dim"
                        width="auto"
                        type="button"
                        mt={4}
                        onClick={() => this.handleClick(data.options[0])}
                      >
                        {data.secondOptionText}
                      </Button>
                    )}

                    {data.skip && (
                      <StyledText
                        mt={4}
                        onClick={() => {
                          wizard.push(data.skip);
                        }}
                      >
                        Skip
                      </StyledText>
                    )}
                  </Flex>
                </Form>
              );
            }}
          />
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(InputField);
