import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { Check } from '../../icons';
import { Query, Mutation } from 'react-apollo';
import {
  GET_PATIENT_BY_ID,
  SEND_INTAKE_REMINDER_EMAIL,
} from '../../../graphql';
import SpinLoader from '../../SpinLoader';
import Exclamation from '../../icons/Exclamation';
import { CrossSearch } from '../../icons';

const InfoBox = styled(Flex)`
  flex: 1;
  background: ${props => props.theme.color.information};
  border-radius: 10px;
  padding: 25px;
  color: ${props => props.theme.color.darkBlue};
  justify-content: center;
`;

const Icon = styled(Flex)`
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const ConsultationFutureAdmin = props => (
  <Query
    query={GET_PATIENT_BY_ID}
    variables={{ patientId: props.appointment.patientId }}
  >
    {({ loading, error, data }) => {
      // console.log('ConsultationFutureAdmin DATA', data);
      if (loading) {
        return (
          <Flex flex={1} justifyContent="center" alignItems="center">
            <SpinLoader />
          </Flex>
        );
      }
      if (error) {
        return <Flex flex={1} justifyContent="center" alignItems="center" />;
      }
      return (
        <Fragment>
          <Card p={[3, 3, 4]} mb={4}>
            <InfoBox mb={4}>
              <Icon mr={[0, 4, 4]} pt={1}>
                <Check width={40} />
              </Icon>
              <Flex flex={1} flexDirection="column">
                <Heading mb={3} textAlign={['center', 'center', 'left']}>
                  Visit confirmed
                </Heading>
                <Text fontWeight={700} textAlign={['center', 'center', 'left']}>
                  {`${moment
                    .tz(
                      props.appointment.startDate,
                      data.getPatientById.department.timeZoneName
                    )
                    .format('ddd MMM Do h:mma')}*`}
                </Text>
                <Text fontWeight={300} textAlign={['center', 'center', 'left']}>
                  * Time is at the department's timezone
                </Text>
              </Flex>
            </InfoBox>
            {props.appointment && !props.appointment.isZocDoc ? (
              <Flex
                alignItems="center"
                flexDirection={['column', 'row', 'row']}
              >
                <Button
                  variant="pink"
                  width={[1, 1 / 2, 1]}
                  mb={[3, 0, 0]}
                  onClick={() =>
                    props.history.push({
                      pathname: `/schedule/${props.appointment.patientId}`,
                      state: {
                        patientId: props.appointment.patientId,
                        oldId: props.appointment.appointmentId,
                        version: 'reschedule',
                      },
                    })
                  }
                >
                  Reschedule
                </Button>
                <Button
                  variant="dim"
                  width={[1, 1 / 2, 1]}
                  ml={[0, 3, 3]}
                  onClick={props.onClick}
                >
                  Cancel
                </Button>
              </Flex>
            ) : (
              <Text fontWeight={700} textAlign={['center', 'center', 'left']}>
                Any appointment booked through ZocDoc can be canceled or
                rescheduled only through ZocDoc
              </Text>
            )}
          </Card>

          <Card p={[3, 3, 4]} mb={4}>
            <Flex alignItems="left" flexDirection="column">
              <Heading mb={4}>Patient Information</Heading>

              <Text mb={3} fontSize={2} fontWeight={600}>
                {`${data.getPatientById.firstName} ${
                  data.getPatientById.lastName
                }`}
              </Text>
              <Text mb={2} fontSize={1}>
                {`${data.getPatientById.phone}`}
              </Text>
              <Text mb={2} fontSize={1}>
                {`${data.getPatientById.email}`}
              </Text>
              <div>
                <Icon mr={[0, 4, 4]} pt={1} style={{ display: 'inline-block' }}>
                  {data.getPatientById.intakeStatus &&
                  data.getPatientById.intakeStatus === 'completed' ? (
                    <Check height={12} width={12} fill="#344f77" />
                  ) : (
                    <Exclamation height={12} width={12} fill="#f9a49c" />
                  )}
                </Icon>
                <Text fontSize={2} style={{ display: 'inline-block' }}>
                  {`${
                    data.getPatientById.intakeStatus
                      ? data.getPatientById.intakeStatus === 'completed'
                        ? 'Has completed Intake'
                        : `Has not completed Intake (will resume from ${
                            data.getPatientById.intakeStatus
                          })`
                      : `Has not started Intake`
                  }`}
                </Text>
              </div>
              {(!data.getPatientById ||
                data.getPatientById.intakeStatus !== 'completed') && (
                <div>
                  <Mutation
                    mutation={SEND_INTAKE_REMINDER_EMAIL}
                    variables={{
                      patientId: props.appointment.patientId,
                    }}
                  >
                    {(sendEmailReminder, { loading }) => (
                      <Button
                        variant="primary"
                        width="auto"
                        mb={4}
                        mt={3}
                        onClick={() => {
                          sendEmailReminder();
                        }}
                      >
                        Send Patient a Reminder
                      </Button>
                    )}
                  </Mutation>
                </div>
              )}
              <div>
                <Icon mr={[0, 4, 4]} pt={1} style={{ display: 'inline-block' }}>
                  {data.getPatientById.card ? (
                    <Check height={12} width={12} fill="#344f77" />
                  ) : (
                    <Exclamation height={12} width={12} fill="#f9a49c" />
                  )}
                </Icon>
                <Text fontSize={2} style={{ display: 'inline-block' }}>
                  {`${
                    data.getPatientById.card
                      ? 'Card on file'
                      : 'No card on file'
                  }`}
                </Text>
              </div>
            </Flex>
          </Card>

          <Card p={[3, 3, 4]} mb={4}>
            <Flex flexDirection={['column', 'row']}>
              <Icon mr={[0, 4]} mb={[4, 0]}>
                <CrossSearch width={100} />
              </Icon>
              <Flex flex={1} flexDirection="column">
                <Heading mb={4}>Patient's Pharmacy</Heading>
                {!data.getPatientById.pharmacy && (
                  <Text>No Pharmacy Selected</Text>
                )}
                {data.getPatientById.pharmacy && (
                  <Fragment>
                    <Text fontSize={3} fontWeight={500} mb={3}>
                      {data.getPatientById.pharmacy.name}
                    </Text>
                    <Text mb={2}>
                      {data.getPatientById.pharmacy.formattedAddress}
                    </Text>
                    <Text>{data.getPatientById.pharmacy.phone}</Text>
                  </Fragment>
                )}
              </Flex>
            </Flex>
          </Card>
        </Fragment>
      );
    }}
  </Query>
);

export default ConsultationFutureAdmin;
