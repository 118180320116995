import React from 'react';
import { Image, Text } from 'rebass';
import RecipeIndicatorBase, { conversionTable } from './RecipeIndicatorBase';

const RecipeAllergenIndicator = ({ allergen }) => {
  const al = conversionTable.find((x) => x.allergen === allergen);
  return (
    <RecipeIndicatorBase indicatorValue={<Image src={al.image} height={22} width={22} style={{ height: 48, width: 48 }} />}>
      <Text fontWeight={300} textAlign='center' style={{wordBreak: 'break-all'}}>{allergen}</Text>
    </RecipeIndicatorBase>
  );
};

export default RecipeAllergenIndicator;
