import React from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';

const Container = styled(Flex)`
  align-items: center;
  margin-bottom: 18px;
  cursor: pointer;
`;

const Outer = styled(Box)`
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: 1px solid #344f7a;
`;

const Tick = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #344f7a;
`;

const Label = styled(Text)`
  flex: 1;
  margin-left: 12px;
  text-align: left;
`;

export const CheckRadio = ({ children, isChecked, onClick, labelStyle = {} }) => {
  return (
    <Container onClick={onClick}>
      <Outer>{isChecked && <Tick />}</Outer>
      <Label style={{ ...labelStyle }}>{children}</Label>
    </Container>
  );
};
