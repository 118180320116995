import React, { Fragment } from 'react';
import { Flex, Box, Heading, Text, Card } from 'rebass';
import { withWizard } from 'react-albus';

import { compose, withApollo } from 'react-apollo';
import { withToastManager } from 'react-toast-notifications';
import vitaminsBottle from '../../../static/vitaminsBottle.png';
import palomaKit from '../../../static/palomaKit.png';
import { withRouter } from 'react-router';
import yourLab from '../../../static/your_lab.png';
import { useMount } from '../../../utils/hooks/lifecycle';
import {
  ASYNC_HARD_DISQUALIFY,
  CURRENT_USER,
  SET_AS_INACTIVE,
  APPOINTMENTS
} from '../../../graphql';
import withSession from '../../../lib/withSession';

export const AddonCard = ({
  onClick,
  title,
  description,
  discounted,
  price,
  image,
  showLearnMore,
}) => {
  return (
    <Card
      mt={4}
      onClick={onClick}
      style={{
        border: '1px solid rgba(53, 78, 121, 0.15)',
        borderRadius: '10px',
        padding: '20px',
      }}
    >
      <Flex flexDirection="row">
        <Flex flexDirection="column" flex={1}>
          <Flex flexDirection="row">
            <img src={image} alt="" height="93px" />
            <Flex flexDirection="column" flex={1} ml={3}>
              <Text
                fontWeight="600"
                fontSize="16px"
                lineHeight="27px"
                mb="10px"
              >
                {title}
              </Text>
              <Text
                fontWeight="300"
                fontSize="14px"
                lineHeight="20px"
                mb="10px"
              >
                {description}
              </Text>
              <Flex flexDirection="row">
                <Text
                  fontWeight="600"
                  fontSize="14px"
                  lineHeight="20px"
                  mb="10px"
                >
                  {price}
                </Text>
                <Text
                  fontWeight="300"
                  fontSize="14px"
                  lineHeight="20px"
                  mb="10px"
                >
                  &nbsp;
                  {`${' '}${discounted}`}
                </Text>
              </Flex>
              {!!showLearnMore ? (
                <Text
                  fontWeight="300"
                  fontSize="12px"
                  mb="10px"
                  style={{
                    textDecoration: 'underline',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                    textAlign: 'right',
                    color: '#44A5FF',
                  }}
                >
                  LEARN MORE
                </Text>
              ) : null}
            </Flex>
          </Flex>
          <Flex />
        </Flex>
      </Flex>
    </Card>
  );
};

const Disqualified = ({
  onLearnMore,
  session: { isMember },
  history,
  data,
  client,
}) => {
  useMount(async () => {
    if (data.variant) {
      let reason = '';
      switch (data.variant) {
        case 'embolism':
          reason = 'EMBOLISM';
          break;
        case 'heartattack':
          reason = 'HEART_ATTACK';
          break;
        case 'cancer':
          reason = 'THYROID_CANCER';
          break;

        default:
          break;
      }

      await client.mutate({
        mutation: ASYNC_HARD_DISQUALIFY,
        variables: {
          reason,
        },
      });

      await client.mutate({
        mutation: SET_AS_INACTIVE,
        variables: {
          disqualifyingReason: reason,
          bypassKlaviyo: true
        },
        refetchQueries: [{ query: CURRENT_USER}, { query: APPOINTMENTS }],
        awaitRefetchQueries: true,
      });
    }
  });
  return (
    <Fragment>
      <Flex flex={1} flexDirection="column" style={{ zIndex: 1 }} pb="60px">
        <img
          src={yourLab}
          alt=""
          width="140px"
          style={{ alignSelf: 'center' }}
        />
        <Box m={2} mt={4} mb={3}>
          <Heading textAlign="left">We are very sorry</Heading>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Text
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            Treating patients with thyroid cancer is not supported by telehealth.
            We recommend you to visit your PCP or endocrinologist in person.
          </Text>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Heading textAlign="left">How we can help</Heading>
        </Box>
        <Box m={2} mt="0" mb={3}>
          <Text
            textAlign="left"
            fontSize="16px"
            fontWeight="300"
            lineHeight="24px"
          >
            We can still support you with some of our best sellers:
          </Text>
        </Box>
        <Flex flexDirection="column">
          <AddonCard
            title="Daily Thyroid Care"
            description="Vitamin Support to support thyroid health"
            price={!isMember ? '$34/month' : '$27/month'}
            discounted={!isMember ? '' : ' ($7 member discount applied)'}
            image={vitaminsBottle}
            onClick={() => {
              history.push('/order-vitamins');
            }}
            showLearnMore
          />
          {/* <Text
            fontWeight="300"
            fontSize="12px"
            style={{
              textDecoration: 'underline',
              textTransform: 'uppercase',
              cursor: 'pointer',
              textAlign: 'right',
              marginTop: '8px',
            }}
            onClick={() => onLearnMore('vitamins')}
          >
            LEARN MORE 
          </Text>*/}
          <AddonCard
            title="At-Home Thyroid Test Kit"
            description="Test your TSH, free T4, Free T3 and TPO antibodies from the comfort of your own home"
            price={!isMember ? '$99' : '$75'}
            discounted={!isMember ? '' : ' ($24 member discount applied)'}
            image={palomaKit}
            onClick={() => {
              // history.push('/purchase');
              window.location = 'https://palomahealth.com/kit';
            }}
            showLearnMore
          />
        </Flex>
        {/* <Text
          fontWeight="300"
          fontSize="12px"
          style={{
            textDecoration: 'underline',
            textTransform: 'uppercase',
            cursor: 'pointer',
            textAlign: 'right',
            marginTop: '8px',
          }}
          onClick={() => onLearnMore('athomekit')}
        >
          LEARN MORE
        </Text> */}
      </Flex>
    </Fragment>
  );
};

export default compose(
  withRouter,
  withSession,
  withApollo,
  withWizard,
  withToastManager
)(Disqualified);
