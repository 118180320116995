import React, { Fragment } from 'react';
import { Button, Card, Flex, Heading, Text } from 'rebass';
import { compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withWizard } from 'react-albus';
import { withToastManager } from 'react-toast-notifications';

import BuyAKitIcon from '../../static/BuyAKitIcon.png';
import { isToggleActive } from '../../components/featureToggle/toggles';

const PaymentReceived = (props) => {
  const { address, source, order, emailAddress, dob } = props;

  return (
    <Fragment>
      <Flex flexDirection="column" flex={1} mb={[4, 4, 5]} style={{ maxWidth: '100%' }}>
        {!!isToggleActive('HOLIDAY_DELAYS') && (
          <Card
            p={4}
            mb={4}
            style={{
              textAlign: 'center',
              backgroundColor: '#d9e6f9',
            }}
          >
            <Heading textAlign="center" mb={4} color="#344f79">
              Temporary Lab Closure for Thanksgiving
            </Heading>
            <Text textAlign="center" mb={4} color="#344f79">
            Our laboratory will be closed on Thursday 11/23 and Friday 11/24 in observance of the Thanksgiving holiday. There will be a slight delay in the processing and delivery of your test kit results. As soon as our lab receive your sample we will be able to share an exact ETA. Please take these closure dates into consideration if you decide to book a visit before your results are released.
            </Text>
            {/* <Button
                onClick={() => {
                  window.location.href =
                    'mailto:contact@palomahealth.com?subject=Change Kit Shipping Method';
                }}
                width={[1 / 2, 1 / 3]}
                variant="primary"
              >
                Request express shipping
              </Button> */}
          </Card>
        )}

        <Card p={4} mb={4} style={{ textAlign: 'center' }}>
          <Heading textAlign="center" mb={4}>
            Create Your Paloma Account
          </Heading>
          <Text textAlign="center" mb={4}>
            You won't be able to register your kit and review your results without a Paloma Health account
          </Text>
          <Button
            onClick={() =>
              props.history.push({
                pathname: '/registration',
                state: {
                  orderKit: {
                    email: emailAddress,
                    firstName: address.firstName,
                    lastName: address.lastName,
                    street1: address.street1,
                    street2: address.street2,
                    city: address.city,
                    state: address.state,
                    zip: address.zip,
                    dob,
                  },
                },
              })
            }
            width="200px"
            variant="pink"
          >
            Sign Up
          </Button>
        </Card>

        <Card p={4} id="receiptcontainer">
          <Heading textAlign="center" mb={4}>
            Your Order Confirmation
          </Heading>

          <Fragment>
            <Flex flexDirection={['column', 'column', 'row']}>
              <Flex flex={1} pr={2} pl={2} style={{ border: '1px solid rgba(0,0,0,0.25)' }} mb={2}>
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <Flex mb={4} justifyContent="left" />
                  <Text textAlign="center" mb={4} mt={2} lineHeight={1.6} fontSize="22px" fontWeight={600} color="#344f79">
                    Customer Information
                  </Text>
                  <div
                    style={{
                      borderBottom: '1px solid rgba(0,0,0,0.25)',
                      width: 'calc(100% + 16px)',
                      marginLeft: '-8px',
                      marginBottom: '32px',
                    }}
                  />

                  <Flex
                    flexDirection={['column', 'row']}
                    style={{
                      justifyContent: 'start',
                      alignItems: 'start',
                      textAlign: 'left',
                    }}
                  >
                    <Flex flexDirection="column" style={{ textAlign: 'left' }}>
                      <Text fontWeight={600}>Email</Text>
                      <Text mb={2}>{emailAddress}</Text>
                    </Flex>
                    <Flex flexDirection="column" style={{ textAlign: 'left' }}>
                      <Text fontWeight={600}>Shipping Address</Text>

                      <Text>
                        {address.firstName} {address.lastName}
                      </Text>
                      <Text>{address.street1}</Text>
                      <Text>{address.street2}</Text>
                      <Text>
                        {address.city}, {address.state} {address.zip}
                      </Text>
                    </Flex>
                  </Flex>
                  {/* <CustomerInfoContainer>
                    <GridLeft>
                    </GridLeft>
                    <GridRight>
                    </GridRight>
                    <GridLeft>
                    </GridLeft>
                    <GridRight>
                    </GridRight>
                  </CustomerInfoContainer> */}
                  <div
                    style={{
                      borderBottom: '1px solid rgba(0,0,0,0.25)',
                      width: 'calc(100% + 16px)',
                      marginLeft: '-8px',
                      marginBottom: '32px',
                    }}
                  />

                  <Fragment>
                    <div style={{ textAlign: 'left' }}>
                      <Text fontWeight={600} mb="12px">
                        Payment Info
                      </Text>
                      {source && (
                        <Fragment>
                          <Text>
                            {source.brand ? source.brand.toUpperCase() : ''} ****
                            {source.last4}
                          </Text>
                          <Text>
                            Expires {source.expMonth} / {source.expYear}
                          </Text>
                        </Fragment>
                      )}
                    </div>
                  </Fragment>
                </div>
              </Flex>
              <Flex
                flex={1}
                pr={2}
                pl={2}
                style={{
                  border: '1px solid rgba(0,0,0,0.25)',
                  // borderLeftWidth: 0,
                }}
                flexDirection="column"
                mb={2}
              >
                <div style={{ textAlign: 'left' }}>
                  <Flex mb={4} justifyContent="left" />
                  <Text textAlign="center" mb={4} mt={2} lineHeight={1.6} fontSize="22px" fontWeight={600} color="#344f79">
                    Your Order
                  </Text>
                  <div
                    style={{
                      borderBottom: '1px solid rgba(0,0,0,0.25)',
                      width: 'calc(100% + 16px)',
                      marginLeft: '-8px',
                      marginBottom: '32px',
                    }}
                  />

                  <Flex mb={3} justifyContent="center">
                    <img src={BuyAKitIcon} alt="" height="200px" />
                  </Flex>

                  <Flex flex={1} mb={3} flexDirection="column">
                    {order.items.map((item, i) => (
                      <Flex flex={1} key={i}>
                        <Flex flex={1}>
                          <Text fontWeight={300}>{item.description}</Text>
                        </Flex>
                        <Flex justifyContent="flex-end">${(item.amount / 100).toFixed(2)}</Flex>
                      </Flex>
                    ))}
                    <Text fontSize={3} fontWeight={500} textAlign="center" mt={3}>
                      {order.items.length > 0 ? 'Total' : 'Complete Thyroid Test'} ${order.amount ? (order.amount / 100).toFixed(2) : '0.00'}
                    </Text>
                  </Flex>
                </div>
              </Flex>
            </Flex>
          </Fragment>
        </Card>
      </Flex>
    </Fragment>
  );
};

export default compose(
  withApollo,
  withRouter,
  withToastManager,
  withWizard
)(PaymentReceived);
