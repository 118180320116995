import React from 'react';
import IconContainer from './IconContainer';

const InfoBubble = props => (
  <IconContainer {...props}>
    <svg data-name="InfoBubble" viewBox="0 0 50 48">
      <path
        d="M 0.00 0.00 L 50.00 0.00 L 50.00 48.00 L 0.00 48.00 L 0.00 0.00 Z M 11.94 40.24 C 14.33 38.18 16.59 35.97 18.82 33.75 C 24.55 33.72 30.30 33.91 36.02 33.73 C 38.58 33.80 40.80 31.58 40.73 29.02 C 40.85 24.39 40.85 19.61 40.73 14.98 C 40.80 12.42 38.58 10.20 36.02 10.27 C 29.36 10.16 22.64 10.16 15.98 10.27 C 13.41 10.20 11.20 12.41 11.27 14.98 C 11.07 19.64 11.30 24.33 11.22 29.00 C 11.28 32.91 10.78 36.44 11.94 40.24 Z"
        fill="#ffffff"
      />
      <path
        d="M 15.98 10.27 C 22.64 10.16 29.36 10.16 36.02 10.27 C 38.58 10.20 40.80 12.42 40.73 14.98 C 40.85 19.61 40.85 24.39 40.73 29.02 C 40.80 31.58 38.58 33.80 36.02 33.73 C 30.30 33.91 24.55 33.72 18.82 33.75 C 16.59 35.97 14.33 38.18 11.94 40.24 C 10.78 36.44 11.28 32.91 11.22 29.00 C 11.30 24.33 11.07 19.64 11.27 14.98 C 11.20 12.41 13.41 10.20 15.98 10.27 Z M 13.90 12.90 C 13.68 20.36 13.74 27.85 13.87 35.31 C 15.38 33.99 16.86 32.64 18.33 31.27 C 24.63 31.07 30.95 31.43 37.23 31.14 C 38.75 30.26 38.08 28.49 38.26 27.00 C 38.13 22.59 38.40 18.16 38.14 13.77 C 37.66 13.00 36.97 12.67 36.09 12.78 C 28.70 12.79 21.29 12.67 13.90 12.90 Z"
        fill="#9BBDF9"
      />
      <path
        d="M 13.90 12.90 C 21.29 12.67 28.70 12.79 36.09 12.78 C 36.97 12.67 37.66 13.00 38.14 13.77 C 38.40 18.16 38.13 22.59 38.26 27.00 C 38.08 28.49 38.75 30.26 37.23 31.14 C 30.95 31.43 24.63 31.07 18.33 31.27 C 16.86 32.64 15.38 33.99 13.87 35.31 C 13.74 27.85 13.68 20.36 13.90 12.90 Z"
        fill="#ffffff"
      />
    </svg>
  </IconContainer>
);

export default InfoBubble;
