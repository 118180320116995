import React, { useRef, useState } from 'react';
import { Box, Text, Flex, Heading, Button } from 'rebass';
import styled from 'styled-components';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import withSession from '../../lib/withSession';
import ConfirmModal from '../ConfirmModal';
import { UsrxCard } from './UsrxCard';
import usrxLarge from '../../static/usrxLarge.png';
import { withRouter } from 'react-router';

const Txt = styled(Text)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;

  color: #355078;
`;

const SmallTxt = styled(Text)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;

  color: #355078;
`;
const ModalInner = styled(Box)`
  max-width: 480px;
  width: 100%;
`;

export const UsrxContent = ({ isMember, usarx, history }) => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const componentRef = useRef();
  return (
    <>
      <Heading textAlign="center">
        {isMember ? 'Your Member Coupon' : 'Member Coupon'}
      </Heading>
      <Flex
        justifyContent="center"
        alignItems="center"
        style={{ margin: '24px auto' }}
      >
        <Txt textAlign="center" mr="12px">
          In partnership with{' '}
        </Txt>
        <img src={usrxLarge} alt="" />
      </Flex>
      {!!isMember && (
        <ReactToPrint
          content={() => componentRef.current}
          onBeforeGetContent={() => setShowDisclaimer(true)}
          onAfterPrint={() => setShowDisclaimer(false)}
        >
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <SmallTxt textAlign="center">
                Screenshot or{' '}
                <span
                  style={{
                    fontWeight: 500,
                    color: '#44A5FF',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={async()=>{
                    setShowDisclaimer(true)
                    await new Promise(resolve => setTimeout(resolve, 300));
                    handlePrint()
                  }}
                >
                  print
                </span>{' '}
                this coupon and show it to your pharmacist
              </SmallTxt>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
      )}

      <Box ref={componentRef}>
        <UsrxCard
          showDisclaimer={showDisclaimer}
          pharmacyName={usarx.price.name}
          medicationCommon={
            (usarx.slug || '').toLowerCase() !==
            (usarx.name || '').toLowerCase()
              ? usarx.slug
              : ''
          }
          medicationTitle={usarx.name}
          medicationPrice={usarx.price.price}
          canSee={isMember}
        />
      </Box>

      {isMember ? (
        <>
          <SmallTxt textAlign="center">
            This is your price estimate at {usarx.price.name}. Your pharmacy
            will provide the exact pricing.
          </SmallTxt>
          <SmallTxt textAlign="center" mt="24px">
            This is not insurance
          </SmallTxt>
          <Button
            m="24px auto 0"
            style={{ display: 'block' }}
            variant="primary"
            onClick={() => history.push('/my-saving-card')}
          >
            Check Your Member Card
          </Button>
        </>
      ) : (
        <>
          <SmallTxt textAlign="center">
            Become a member to enjoy this offer
          </SmallTxt>
          <Button
            m="24px auto 0"
            style={{ display: 'block' }}
            variant="primary"
            onClick={() => history.push('/become-member')}
          >
            Join the Membership
          </Button>
        </>
      )}
    </>
  );
};

const UsrxModalMember = ({
  close,
  session: { isMember },
  isOpen,
  usarx,
  history,
}) => {
  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={close}
      onBackgroundClick={close}
      onConfirm={close}
      childrenManaged
      displayX
    >
      <ModalInner>
        <UsrxContent usarx={usarx} history={history} isMember={isMember} />
      </ModalInner>
    </ConfirmModal>
  );
};

export default withSession(withRouter(UsrxModalMember));
