import React from 'react';
import { Heading } from 'rebass';
import styled from 'styled-components';

import FullLayout from '../components/FullLayout';
import Insurances from './myInsurances/Insurances';
import Subscriptions from './mySubscriptions/Subscriptions';

const Wrapper = styled.div`
  padding-left: 60px;
  padding-top: 40px;
  @media screen and (max-width: 480px) {
    padding-left: 0;
  }
`;

const Head = styled(Heading)`
  margin-bottom: 36px;
  @media screen and (max-width: 480px) {
    margin-left: 12px;
  }
`;

export const MyInsurances = () => {
  const isMobileAppEmbed = !!window && !!window.ReactNativeWebView
  return(  
  <Wrapper style={{paddingLeft: !!isMobileAppEmbed?'0': ''}}>
    <Head style={{paddingLeft: !!isMobileAppEmbed?'12px': '0'}}>Your Insurance</Head>
    <Insurances />
    <FullLayout />
  </Wrapper>
)};
