import React, { Fragment, Component } from 'react';
import { Flex, Text, Card, Heading, Box } from 'rebass';
import styled from 'styled-components';
import SpinLoader from '../../components/SpinLoader';
import { GET_CLINICAL_PROVIDERS_DOCTOR, SEND_KLAVIYO_STATUS } from '../../graphql';
import { Query } from 'react-apollo';
import CalendarGrid from './calendarGrid';
import { withWizard } from 'react-albus';
import ReadMore from '../../components/readMore/readmore';
import withSession from '../../lib/withSession';
import { objectHas } from '../../utils/helpers';
import moment from 'moment';
import StarRatings from '../../components/ratingStar/star-rating';
import ScrollAnimation from 'react-animate-on-scroll';
import telemedicine from '../../static/telemedicine.png';
import thyroid from '../../static/thyroid.png';
import iconBackgroundShape from '../../static/iconBackgroundShape.png';
import character from '../../static/character.png';
import ChatWithUs from './components/chatWithUs/chatWithUs';
import PractitionerTag from '../../components/PractitionerTag';
import ConfirmModal from '../../components/ConfirmModal';
import TimeDoesntWorkModalInner from './components/timeDoesntWork/timeDoesntWorkModalInner';
import { isMobile } from 'react-device-detect';
import InnerDetails from './InnerDetails';
import AddToWaitlistModalInner from '../scheduleDoctor/components/addToWaitlist/AddToWaitlistModalInner';

const ListContainer = styled(Flex)`
  border-right: 1px solid #ccc;
  width: 100%;
  /* background: #fdf5f2; //#f8f9fa; */
  height: 100%;
  max-width: 350px;
  min-width: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: block;
  &.selected {
    display: none;
  }

  @media screen and (min-width: 52em) {
    width: 30%;
    overflow-y: auto;
    /* display: flex; */
    &.selected {
      display: flex;
    }
  }

  @media screen and (max-width: 52em) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;

const BackButton = styled.div`
  background: #d8e1f3;
  width: 100%;
  color: #333;
  padding: 6px 12px;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;

const ClinicalFlex = styled(Flex)`
  display: block;

  @media screen and (min-width: 52em) {
    display: flex;
    overflow-y: hidden;
    max-height: calc(100vh - 80px);
  }
`;

const DetailContainer = styled(Flex)`
  display: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  &.selected {
    display: flex;
  }

  @media screen and (min-width: 52em) {
    display: flex;
  }
`;

const Inner = styled(Flex)`
  max-width: 650px;
`;

const NavLink = styled.div`
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100px;
  transition: all 300ms ease;
  background: white;
  position: relative;
  cursor: pointer;
  &.active {
    background: rgba(180, 205, 244, 0.5);
  }
  &:hover {
    background: rgba(180, 205, 244, 0.5);
  }
`;

const NoNavLink = styled.div`
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  background: transparent;
  width: 100%;
  min-height: 50px;
  transition: all 300ms ease;
`;

const Section = styled.div`
  color: #000;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  transition: all 300ms ease;
  &.active {
    background: rgba(180, 205, 244, 0.5);
  }
`;

const ListItem = styled(Flex)`
  border-bottom: 1px solid #ccc;
  position: relative;
`;

const Avatar = styled(Flex)`
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  &.small {
    width: 40px;
    height: 40px;
    & > img {
      width: 40px;
    }
  }
  @media screen and (min-width: 52em) {
    &.small {
      width: 60px;
      height: 60px;
      & > img {
        width: 60px;
      }
    }
  }
`;

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;

const NoAppointment = (props) => {
  return (
    <Fragment>
      <Container flex={1} px={3}>
        <Flex flex={1} textAlign="center" justifyContent="center">
          <Heading style={{ marginBottom: '18px', textAlign: 'center' }} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
            Pick Your Thyroid Specialist
          </Heading>
        </Flex>
        <Flex flex={1} textAlign="center">
          <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={700} style={{ margin: '0 auto', marginBottom: '36px' }}>
            <Text fontSize="18px" fontWeight={400} textAlign="center" mb={4} color="#344f79">
              Click on the provider's name on the left to view their availability and schedule your appointment
            </Text>
          </ScrollAnimation>
        </Flex>
        <Flex flexDirection="row" justifyContent="center">
          <Flex flex={1} width={[1, 1, 1 / 3]} flexDirection="column" mb={4} justifyContent="center" style={{ margin: '0 auto' }}>
            <div style={{ textAlign: 'center' }}>
              <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                <Flex mb={3} justifyContent="center">
                  <img src={iconBackgroundShape} alt="" style={{ height: '130px', marginLeft: '-40px' }} />
                  <img
                    src={thyroid}
                    alt=""
                    style={{
                      height: '130px',
                      position: 'absolute',
                      left: '63px',
                    }}
                  />
                </Flex>

                <Text textAlign="center" lineHeight={1.6} fontSize="18px" fontWeight={300} color="#344f79" style={{ textAlign: 'center', padding: '0 12px' }}>
                  Our providers are thyroid specialists. They will coordinate as needed with your primary care physician.
                </Text>
              </ScrollAnimation>
            </div>
          </Flex>
          <Flex flex={1} justifyContent="center" width={[1, 1, 1 / 3]} flexDirection="column" mb={4} style={{ margin: '0 auto' }}>
            <div style={{ textAlign: 'center' }}>
              <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                <Flex mb={3} justifyContent="center" alignItems="center">
                  <img src={iconBackgroundShape} alt="" style={{ height: '130px', marginLeft: '-40px' }} />
                  <img
                    src={telemedicine}
                    alt=""
                    style={{
                      height: '130px',
                      position: 'absolute',
                      left: '63px',
                    }}
                  />
                </Flex>

                <Text style={{ textAlign: 'center', padding: '0 12px' }} textAlign="center" lineHeight={1.6} fontSize="18px" fontWeight={300} color="#344f79">
                  Our providers have an average of 15 years experience and are trained at the top 50 medical institutions in the country.
                </Text>
              </ScrollAnimation>
            </div>
          </Flex>
          <Flex flex={1} width={[1, 1, 1 / 3]} flexDirection="column" mb={4} justifyContent="center" style={{ margin: '0 auto' }}>
            <div style={{ textAlign: 'center' }}>
              <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                <Flex mb={3} justifyContent="center">
                  <img src={iconBackgroundShape} alt="" style={{ height: '130px', marginLeft: '-40px' }} />
                  <img
                    src={character}
                    alt=""
                    style={{
                      height: '130px',
                      position: 'absolute',
                      left: '63px',
                    }}
                  />
                </Flex>

                <Text style={{ textAlign: 'center', padding: '0 12px' }} textAlign="center" lineHeight={1.6} fontSize="18px" fontWeight={300} color="#344f79">
                  You can keep the same provider for future visits. Continue seeing the same provider who will get to know you and track your progress.
                </Text>
              </ScrollAnimation>
            </div>
          </Flex>
        </Flex>
      </Container>
    </Fragment>
  );
};

class PickDoctor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDoctor: undefined,
      isInNetwork: undefined,
      isFirstTimeLoad: true,
      isTimeDoesntWorkOpen: undefined,
      hasInNetwork: undefined,
    };

    this.reviewCard = React.createRef();
  }

  // componentDidMount = async () => {
  //   try {
  //     await this.props.client.mutate({
  //       mutation: SEND_KLAVIYO_STATUS,
  //       variables: {
  //         step: 'Browsing Provider',
  //       },
  //     });
  //   } catch (error) {}
  // };

  getNextAppointmentText = (appts) => {
    if (appts && appts.length > 0) {
      return (
        <div>
          {`Next available ${moment(`${appts[0].date} ${appts[0].startTime}`, 'MM/DD/YYYY HH:mm').format('	dddd MM/DD')} at ${moment(
            `${appts[0].date} ${appts[0].startTime}`,
            'MM/DD/YYYY HH:mm'
          ).format('hh:mm a')}`}
        </div>
      );
    }

    return null;
  };

  onSlotSelected = (slot) => {
    const { onAppointmentSelection, wizard } = this.props;
    const { selectedDoctor, isInNetwork, hasInNetwork } = this.state;
    console.log({ selectedDoctor, isInNetwork });
    onAppointmentSelection(selectedDoctor, slot, isInNetwork, wizard, hasInNetwork);
  };
  getInNetworkPhysiciansInsurance = (list) => {
    const { isAdmin, impersonatedPatient, selectedInsurance } = this.props;
    // console.log('coverage in network', { selectedInsurance, list });
    const res = [];
    const providerId = isAdmin ? impersonatedPatient.providerId || '' : (this.props.session && this.props.session.primaryProviderId) || '';

    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.networks) {
        // if ((element.nextAppointmentAvailable && element.nextAppointmentAvailable.length > 0) || (providerId && element.id.toString() === providerId.toString())) {
          const ns = element.networks.split(';');
          for (let index = 0; index < ns.length; index++) {
            const nt = ns[index];
            if (nt.toLowerCase() === selectedInsurance.toLowerCase()) {
              res.push(element);
            }
          }
        // }
      }
    }

    return res;
  };

  getInNetworkPhysicians = (list) => {
    const { selectedProvider, isAdmin, impersonatedPatient, selectedInsurance } = this.props;
    console.log({ selectedInsurance });
    if (selectedInsurance) {
      return this.getInNetworkPhysiciansInsurance(list);
    }
    // console.log('coverage in network', { selectedProvider, list });
    const res = [];
    const providerId = isAdmin ? impersonatedPatient.providerId || '' : (this.props.session && this.props.session.primaryProviderId) || '';

    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.networks) {
        // if ((element.nextAppointmentAvailable && element.nextAppointmentAvailable.length > 0) || (providerId && element.id.toString() === providerId.toString())) {
          const ns = element.networks.split(';');
          for (let index = 0; index < ns.length; index++) {
            const nt = ns[index];
            if (nt.toLowerCase() === selectedProvider.value.toLowerCase()) {
              res.push(element);
            }
          }
        // }
      }
    }

    return res;
  };

  getInNetworkPhysiciansBare = (list) => {
    const { selectedProvider, isAdmin, impersonatedPatient, selectedInsurance } = this.props;
    console.log({ selectedInsurance });
    if (selectedInsurance) {
      return this.getInNetworkPhysiciansInsurance(list);
    }
    // console.log('coverage in network', { selectedProvider, list });
    const res = [];
    const providerId = isAdmin ? impersonatedPatient.providerId || '' : (this.props.session && this.props.session.primaryProviderId) || '';

    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.networks) {
        if (providerId && element.id.toString() === providerId.toString()) {
          const ns = element.networks.split(';');
          for (let index = 0; index < ns.length; index++) {
            const nt = ns[index];
            if (nt.toLowerCase() === selectedProvider.value.toLowerCase()) {
              res.push(element);
            }
          }
        }
      }
    }

    return res;
  };

  getOutNetworkPhysiciansInsurance = (list) => {
    const { isAdmin, impersonatedPatient, selectedInsurance } = this.props;

    const res = [];

    const providerId = isAdmin ? impersonatedPatient.providerId || '' : (this.props.session && this.props.session.primaryProviderId) || '';
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (!element.networks) {
        // if ((element.nextAppointmentAvailable && element.nextAppointmentAvailable.length > 0) || (providerId && element.id.toString() === providerId.toString())) {
          res.push(element);
        // }
        continue;
      }

      const ns = element.networks.split(';');
      let found = false;
      for (let index = 0; index < ns.length; index++) {
        const nt = ns[index];
        if (nt.toLowerCase() === selectedInsurance.toLowerCase()) {
          found = true;
        }
      }
      if (!found) {
        // if ((element.nextAppointmentAvailable && element.nextAppointmentAvailable.length > 0) || (providerId && element.id.toString() === providerId.toString())) {
          res.push(element);
        // }
      }
    }

    return res;
  };

  getOutNetworkPhysicians = (list) => {
    const { isAdmin, impersonatedPatient, selectedProvider, selectedInsurance } = this.props;

    const res = [];
    if (selectedInsurance) {
      return this.getOutNetworkPhysiciansInsurance(list);
    }

    const providerId = isAdmin ? impersonatedPatient.providerId || '' : (this.props.session && this.props.session.primaryProviderId) || '';
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (!element.networks) {
        // if ((element.nextAppointmentAvailable && element.nextAppointmentAvailable.length > 0) || (providerId && element.id.toString() === providerId.toString())) {
          res.push(element);
        // }
        continue;
      }

      const ns = element.networks.split(';');
      let found = false;
      for (let index = 0; index < ns.length; index++) {
        const nt = ns[index];
        if (nt.toLowerCase() === selectedProvider.value.toLowerCase()) {
          found = true;
        }
      }
      if (!found) {
        // if ((element.nextAppointmentAvailable && element.nextAppointmentAvailable.length > 0) || (providerId && element.id.toString() === providerId.toString())) {
          res.push(element);
        // }
      }
    }

    return res;
  };

  render() {
    const { reschedulingAppointment, isAdmin, impersonatedPatient, enforceDept, selectedInsurance, session } = this.props;

    const { isTimeDoesntWorkOpen } = this.state;
    const isMobileApp = !!window && !!window.ReactNativeWebView;

    // console.log('selectedInsurance', this.props.selectedInsurance);
    const departmentId = isAdmin
      ? impersonatedPatient.departmentId || ''
      : objectHas(() => {
          return this.props.session.department.departmentId;
        })
        ? this.props.session.department.departmentId
        : enforceDept
          ? enforceDept.id.toString()
          : '';

    const providerId = isAdmin ? impersonatedPatient.providerId || impersonatedPatient.primaryProviderId : (this.props.session && this.props.session.primaryProviderId) || '';

    const selected = this.state.selectedDoctor ? 'selected' : null;

    return (
      <ClinicalFlex flex={1} style={{ ...this.props.containerStyle }}>
        <Query
          query={GET_CLINICAL_PROVIDERS_DOCTOR}
          variables={{
            departmentId,
            selectedInsurance,
          }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            // console.log('ERROR CPD', this.props.selectedInsurance);
            if (!loading && !error && !this.state.selectedDoctor) {
              if (this.state.isFirstTimeLoad && data && data.getClinicalProvidersDoctorsList && data.getClinicalProvidersDoctorsList.length > 0 && providerId) {
                for (let index = 0; index < data.getClinicalProvidersDoctorsList.length; index++) {
                  const element = data.getClinicalProvidersDoctorsList[index];
                  const idToCompare =
                    reschedulingAppointment && reschedulingAppointment.provider && reschedulingAppointment.provider.id ? reschedulingAppointment.provider.id.toString() : providerId.toString();

                  if (element.id.toString() === idToCompare) {
                    let isInNetworks = false;
                    if (this.props.selectedInsurance || this.props.selectedProvider) {
                      // console.log('isIn net post', this.props.selectedProvider);
                      const inNetworks = this.getInNetworkPhysicians(data.getClinicalProvidersDoctorsList);

                      isInNetworks = inNetworks.filter((x) => x.id.toString() === element.id.toString()).length > 0;
                    }

                    this.setState({
                      selectedDoctor: JSON.parse(JSON.stringify(element)),
                      isFirstTimeLoad: false,
                      isInNetwork: isInNetworks,
                    });
                  }
                }
              } else {
                if (
                  data &&
                  data.getClinicalProvidersDoctorsList &&
                  data.getClinicalProvidersDoctorsList.filter((x) => {
                    return x.nextAppointmentAvailable && x.nextAppointmentAvailable.length > 0;
                  }).length === 1
                ) {
                  if (this.state.selectedDoctor === undefined) {
                    let isInNetworks = false;
                    if ((this.props.selectedInsurance || this.props.selectedProvider) && data.getClinicalProvidersDoctorsList && data.getClinicalProvidersDoctorsList.length > 0) {
                      // console.log('isIn net post', this.props.selectedProvider);
                      const inNetworks = this.getInNetworkPhysicians(data.getClinicalProvidersDoctorsList);

                      isInNetworks =
                        inNetworks.filter(
                          (x) =>
                            x.id.toString() ===
                            data.getClinicalProvidersDoctorsList
                              .filter((x) => {
                                return x.nextAppointmentAvailable && x.nextAppointmentAvailable.length > 0;
                              })[0]
                              .id.toString()
                        ).length > 0;
                    }
                    this.setState({
                      selectedDoctor: JSON.parse(JSON.stringify(data.getClinicalProvidersDoctorsList.filter((x) => {
                        return x.nextAppointmentAvailable && x.nextAppointmentAvailable.length > 0;
                      })[0])),
                      isFirstTimeLoad: false,
                      isInNetwork: isInNetworks,
                    });
                  }
                }
              }
            }

            let hideOutOfNetwork = false;

            if (data && data.getClinicalProvidersDoctorsList && !!session && session.isMember && session.membershipSpecific && session.membershipSpecific === 'insurance' && !session.oonSchedulable) {
              if (this.getInNetworkPhysiciansBare(data.getClinicalProvidersDoctorsList).length > 0) {
                hideOutOfNetwork = true;
              }
            }

            // console.log('DATA', data);
            let selectedDoc = {};
            if (data && data.getClinicalProvidersDoctorsList && selected) {
              selectedDoc = JSON.parse(JSON.stringify(this.state.selectedDoctor));
            }
            // console.log('data', { data, loading });
            return (
              <Fragment>
                <ListContainer flexDirection="column" className={selected}>
                  <Fragment>
                    {loading &&
                      (!data || !data.getClinicalProvidersDoctorsList) && (
                        <Flex
                          flex={1}
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          style={{
                            marginTop: '50%',
                          }}
                        >
                          <SpinLoader />
                          <br />
                          <Text style={{ opacity: 0.65 }}>Loading Providers</Text>
                        </Flex>
                      )}
                    {error && <Flex flex={1} justifyContent="center" alignItems="center" />}
                    {data &&
                      data.getClinicalProvidersDoctorsList && (
                        <Fragment>
                          {!this.props.selectedInsurance ? (
                            data.getClinicalProvidersDoctorsList.filter((x) => {
                              let res;
                              if (!this.props.session) {
                                // console.log('no s');
                                res =
                                  (x.nextAppointmentAvailable && x.nextAppointmentAvailable.length > 0) ||
                                  (providerId && (x.id.toString() === providerId.toString() || x.primaryId.toString() === providerId.toString()));
                              } else {
                                // console.log('with s');

                                res = true; //providerId && (x.id.toString() === providerId.toString() || x.primaryId.toString() === providerId.toString());
                              }
                              // console.log({ res });
                              return res;
                            }).length < 1 ? (
                              <NoNavLink>
                                <Text mb={1} fontSize="18px" fontWeight="400" color="#ccc" ml="20px">
                                  no physican available
                                </Text>
                              </NoNavLink>
                            ) : (
                              <Fragment>
                                {data.getClinicalProvidersDoctorsList
                                  .filter((x) => {
                                    let res;
                                    if (!this.props.session) {
                                      // console.log('no s2');
                                      res =
                                        (x.nextAppointmentAvailable && x.nextAppointmentAvailable.length > 0) ||
                                        (providerId && (x.id.toString() === providerId.toString() || x.primaryId.toString() === providerId.toString()));
                                    } else {
                                      // console.log('with s2');

                                      res = true;
                                    }
                                    // console.log({ res2: res });
                                    return res;
                                    // return (
                                    //   (x.nextAppointmentAvailable && x.nextAppointmentAvailable.length > 0) ||
                                    //   (providerId && (x.id.toString() === providerId.toString() || x.primaryId.toString() === providerId.toString()))
                                    // );
                                  })
                                  .sort((a, b) => {
                                    const aName = a.lastName.toLowerCase();
                                    const bName = b.lastName.toLowerCase();

                                    if (aName < bName) return -1;
                                    if (aName > bName) return 1;

                                    const aFirst = a.firstName.toLowerCase();
                                    const bFirst = b.firstName.toLowerCase();

                                    if (aFirst < bFirst) return -1;
                                    if (aFirst > bFirst) return 1;

                                    return 0;
                                  })
                                  .map((provider) => {
                                    // console.log('Provider', provider);
                                    return (
                                      <ListItem key={provider.id}>
                                        <NavLink
                                          onClick={() => {
                                            console.log('clicked in NA');
                                            this.setState(
                                              {
                                                selectedDoctor: JSON.parse(JSON.stringify(provider)),
                                              },
                                              () => {
                                                if (this.detailContainer) {
                                                  this.detailContainer.scrollTo(0, 0);
                                                }
                                              }
                                            );
                                          }}
                                          className={this.state.selectedDoctor && this.state.selectedDoctor.id === provider.id ? 'active' : ''}
                                        >
                                          <Flex style={{ padding: '24px 16px' }} alignItems="center">
                                            <Flex mr={3}>
                                              <Avatar className="small">
                                                <img src={provider.picture} alt="" />
                                              </Avatar>
                                            </Flex>
                                            <Flex flexDirection="column">
                                              <Text mb={1} fontSize="16px" fontWeight="400" color="#344f79">
                                                {provider.patientFacingName}
                                              </Text>
                                              {/* {selectedDoc.tags ? (
                              <Flex
                                flexDirection="row"
                                flexWrap="wrap"
                                mb={2}
                              >
                                {selectedDoc.tags.map(tag => {
                                  return <PractitionerTag tag={tag} />;
                                })}
                              </Flex>
                            ) : null} */}
                                              <Text fontSize={1} color="grey">
                                                {/* {this.getNextAppointmentText(
                                                  provider.nextAppointmentAvailable
                                                )} */}
                                              </Text>

                                              <Flex flexDirection="row" alignItems="center">
                                                {provider.rating && provider.rating.count > 0 ? (
                                                  <>
                                                    <StarRatings
                                                      starSpacing="0px"
                                                      starRatedColor="#f9a394"
                                                      rating={provider.rating.rate / 100}
                                                      numberOfStars={5}
                                                      // rating={
                                                      //   1
                                                      // }
                                                      // numberOfStars={1}
                                                      starDimension="18px"
                                                      name="rating"
                                                    />
                                                    <Text fontSize={1} color="#f9a394" ml={2}>
                                                      {provider.rating.rate / 100}
                                                    </Text>
                                                    <Text fontSize={1} color="grey" ml="1">
                                                      {'('}
                                                      {provider.rating.count}
                                                      {')'}
                                                    </Text>
                                                  </>
                                                ) : (
                                                  <div />
                                                )}
                                              </Flex>
                                              {provider.tags ? (
                                                <Flex flexDirection="row" flexWrap="wrap">
                                                  {provider.tags.map((tag) => {
                                                    return <PractitionerTag textStyle={{ fontSize: '10px' }} tag={tag} containerStyle={{ marginBottom: '6px' }} />;
                                                  })}
                                                </Flex>
                                              ) : null}
                                            </Flex>
                                          </Flex>
                                          {providerId.toString() === provider.id.toString() ||
                                            (providerId.toString() === provider.primaryId.toString() &&
                                              !provider.archived && (
                                                <div
                                                  style={{
                                                    position: 'absolute',
                                                    top: '0px',
                                                    right: '0px',
                                                    background: '#344f79',
                                                  }}
                                                >
                                                  <Text
                                                    fontSize="12px"
                                                    color="white"
                                                    style={{
                                                      margin: '4px',
                                                      color: 'white',
                                                    }}
                                                  >
                                                    <span>Provider from your last visit</span>
                                                  </Text>
                                                </div>
                                              ))}
                                        </NavLink>
                                      </ListItem>
                                    );
                                  })}
                              </Fragment>
                            )
                          ) : (
                            <Fragment>
                              <ListItem>
                                <Section>
                                  <Text mb={1} fontSize="18px" fontWeight="500" ml="20px" color="#344f79">
                                    Providers likely to be in-network:
                                  </Text>
                                </Section>
                              </ListItem>
                              {this.getInNetworkPhysicians(data.getClinicalProvidersDoctorsList).length < 1 ? (
                                <NoNavLink>
                                  <Text mb={1} fontSize="18px" fontWeight="400" color="#ccc" ml="20px">
                                    no in-network physican available
                                  </Text>
                                </NoNavLink>
                              ) : (
                                this.getInNetworkPhysicians(data.getClinicalProvidersDoctorsList)
                                  .sort((a, b) => {
                                    const aName = a.lastName.toLowerCase();
                                    const bName = b.lastName.toLowerCase();

                                    if (aName < bName) return -1;
                                    if (aName > bName) return 1;

                                    const aFirst = a.firstName.toLowerCase();
                                    const bFirst = b.firstName.toLowerCase();

                                    if (aFirst < bFirst) return -1;
                                    if (aFirst > bFirst) return 1;

                                    return 0;
                                  })
                                  .map((provider) => (
                                    <ListItem key={provider.id}>
                                      <NavLink
                                        onClick={() => {
                                          console.log('clicked in network');
                                          this.setState(
                                            {
                                              selectedDoctor: JSON.parse(JSON.stringify(provider)),
                                              isInNetwork: true,
                                              hasInNetwork: true,
                                            },
                                            () => {
                                              if (this.detailContainer) {
                                                this.detailContainer.scrollTo(0, 0);
                                              }
                                            }
                                          );
                                        }}
                                        className={this.state.selectedDoctor && this.state.selectedDoctor.id === provider.id ? 'active' : ''}
                                      >
                                        <Flex style={{ padding: '24px 16px' }} alignItems="center">
                                          <Flex mr={3}>
                                            <Avatar className="small">
                                              <img src={provider.picture} alt="" />
                                            </Avatar>
                                          </Flex>
                                          <Flex flexDirection="column">
                                            <Text mb={1} fontSize="16px" fontWeight="400" color="#344f79">
                                              {provider.patientFacingName}
                                            </Text>
                                            <Text fontSize={1} color="grey" />
                                            <Flex flexDirection="row" alignItems="center">
                                              {provider.rating && provider.rating.count > 0 ? (
                                                <>
                                                  <StarRatings
                                                    starSpacing="0px"
                                                    starRatedColor="#f9a394"
                                                    rating={provider.rating.rate / 100}
                                                    numberOfStars={5}
                                                    // rating={
                                                    //   1
                                                    // }
                                                    // numberOfStars={1}
                                                    starDimension="18px"
                                                    name="rating"
                                                  />
                                                  <Text fontSize={1} color="#f9a394" ml={2}>
                                                    {provider.rating.rate / 100}
                                                  </Text>
                                                  <Text fontSize={1} color="grey" ml="1">
                                                    {'('}
                                                    {provider.rating.count}
                                                    {')'}
                                                  </Text>
                                                </>
                                              ) : (
                                                <div />
                                              )}
                                            </Flex>
                                            {provider.tags ? (
                                              <Flex flexDirection="row" flexWrap="wrap">
                                                {provider.tags.map((tag) => {
                                                  return <PractitionerTag textStyle={{ fontSize: '10px' }} tag={tag} containerStyle={{ marginBottom: '6px' }} />;
                                                })}
                                              </Flex>
                                            ) : null}
                                          </Flex>
                                        </Flex>
                                        {providerId.toString() === provider.id.toString() ||
                                          (providerId.toString() === provider.primaryId.toString() &&
                                            !provider.archived && (
                                              <div
                                                style={{
                                                  position: 'absolute',
                                                  top: '0px',
                                                  right: '0px',
                                                  background: '#344f79',
                                                }}
                                              >
                                                <Text
                                                  fontSize="12px"
                                                  color="white"
                                                  style={{
                                                    margin: '4px',
                                                    color: 'white',
                                                  }}
                                                >
                                                  <span>Provider from your last visit</span>
                                                </Text>
                                              </div>
                                            ))}
                                      </NavLink>
                                    </ListItem>
                                  ))
                              )}
                              {!hideOutOfNetwork && (
                                <>
                                  <ListItem>
                                    <Section>
                                      <Text mb={1} fontSize="18px" fontWeight="500" ml="20px" color="#344f79">
                                        Out-of-Network Providers:
                                      </Text>
                                    </Section>
                                  </ListItem>
                                  {this.getOutNetworkPhysicians(data.getClinicalProvidersDoctorsList).length < 1 ? (
                                    <NoNavLink>
                                      <Text mb={1} fontSize="18px" fontWeight="400" color="#ccc" ml="20px">
                                        no out-of-network provider available
                                      </Text>
                                    </NoNavLink>
                                  ) : (
                                    this.getOutNetworkPhysicians(data.getClinicalProvidersDoctorsList)
                                      .sort((a, b) => {
                                        const aName = a.lastName.toLowerCase();
                                        const bName = b.lastName.toLowerCase();

                                        if (aName < bName) return -1;
                                        if (aName > bName) return 1;

                                        const aFirst = a.firstName.toLowerCase();
                                        const bFirst = b.firstName.toLowerCase();

                                        if (aFirst < bFirst) return -1;
                                        if (aFirst > bFirst) return 1;

                                        return 0;
                                      })
                                      .map((provider) => (
                                        <ListItem key={provider.id}>
                                          <NavLink
                                            onClick={() => {
                                              console.log('clicked in out');
                                              this.setState(
                                                {
                                                  selectedDoctor: JSON.parse(JSON.stringify(provider)),
                                                  isInNetwork: false,
                                                  hasInNetwork: this.getInNetworkPhysiciansBare(data.getClinicalProvidersDoctorsList).length > 0,
                                                },
                                                () => {
                                                  if (this.detailContainer) {
                                                    this.detailContainer.scrollTo(0, 0);
                                                  }
                                                }
                                              );
                                            }}
                                            className={selectedDoc && selectedDoc.id === provider.id ? 'active' : ''}
                                          >
                                            <Flex style={{ padding: '24px 16px' }} alignItems="center">
                                              <Flex mr={3}>
                                                <Avatar className="small">
                                                  <img src={provider.picture} alt="" />
                                                </Avatar>
                                              </Flex>
                                              <Flex flexDirection="column">
                                                <Text mb={1} fontSize="16px" fontWeight="400" color="#344f79">
                                                  {provider.patientFacingName}
                                                </Text>
                                                <Text fontSize={1} color="grey">
                                                  {/* {this.getNextAppointmentText(
                                                provider.nextAppointmentAvailable
                                              )} */}
                                                </Text>
                                                <Flex flexDirection="row" alignItems="center">
                                                  {provider.rating && provider.rating.count > 0 ? (
                                                    <>
                                                      <StarRatings
                                                        starSpacing="0px"
                                                        starRatedColor="#f9a394"
                                                        rating={provider.rating.rate / 100}
                                                        numberOfStars={5}
                                                        // rating={
                                                        //   1
                                                        // }
                                                        // numberOfStars={1}
                                                        starDimension="18px"
                                                        name="rating"
                                                      />
                                                      <Text fontSize={1} color="#f9a394" ml={2}>
                                                        {provider.rating.rate / 100}
                                                      </Text>
                                                      <Text fontSize={1} color="grey" ml="1">
                                                        {'('}
                                                        {provider.rating.count}
                                                        {')'}
                                                      </Text>
                                                    </>
                                                  ) : (
                                                    <div />
                                                  )}
                                                </Flex>
                                                {provider.tags ? (
                                                  <Flex flexDirection="row" flexWrap="wrap">
                                                    {provider.tags.map((tag) => {
                                                      return <PractitionerTag textStyle={{ fontSize: '10px' }} tag={tag} containerStyle={{ marginBottom: '6px' }} />;
                                                    })}
                                                  </Flex>
                                                ) : null}
                                              </Flex>
                                            </Flex>
                                            {providerId.toString() === provider.id.toString() &&
                                              !provider.archived && (
                                                <div
                                                  style={{
                                                    position: 'absolute',
                                                    top: '0px',
                                                    right: '0px',
                                                    background: '#344f79',
                                                  }}
                                                >
                                                  <Text
                                                    fontSize="12px"
                                                    color="white"
                                                    style={{
                                                      margin: '4px',
                                                      color: 'white',
                                                    }}
                                                  >
                                                    <span>Provider from your last visit</span>
                                                  </Text>
                                                </div>
                                              )}
                                          </NavLink>
                                        </ListItem>
                                      ))
                                  )}
                                </>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                  </Fragment>
                  <ChatWithUs />
                </ListContainer>
                <DetailContainer flex={1} justifyContent="center" className={selected} ref={(x) => (this.detailContainer = x)}>
                  {(!selected || !selectedDoc) && <NoAppointment />}
                  {selected &&
                    selectedDoc && (
                      <InnerDetails
                        isNotRegisteredFlow={!this.props.session}
                        reschedulingAppointment={reschedulingAppointment}
                        selectedDoc={selectedDoc}
                        onSlotSelected={this.onSlotSelected}
                        departmentId={departmentId}
                        reviewCard={this.reviewCard}
                        timeDoesntWorkOpen={(duration) => {
                          this.setState({ isTimeDoesntWorkOpen: duration });
                        }}
                        resetSelectedDoctor={() => {
                          this.setState({ selectedDoctor: null });
                        }}
                        setState={this.setState}
                      />
                    )}
                </DetailContainer>
              </Fragment>
            );
          }}
        </Query>

        <ConfirmModal
          childrenManaged
          confirmLabel="OK"
          isOpen={isTimeDoesntWorkOpen}
          okOnly
          displayX
          cardStyle={{
            maxHeight: '100vh',
            position: 'relative',
            overflowY: 'auto',
          }}
          onClose={() => {
            this.setState({ isTimeDoesntWorkOpen: undefined });
          }}
          onConfirm={() => {}}
          onBackgroundClick={() => {
            this.setState({ isTimeDoesntWorkOpen: undefined });
          }}
          onEscapeKeydown={() => {
            this.setState({ isTimeDoesntWorkOpen: undefined });
          }}
        >
          <AddToWaitlistModalInner
            selectedDoctor={this.state.selectedDoctor}
            // doctorName={
            //   this.state.selectedDoctor
            //     ? this.state.selectedDoctor.patientFacingName
            //     : ''
            // }
            reschedulingAppointment={reschedulingAppointment}
            appointmentDuration={isTimeDoesntWorkOpen || 15}
            departmentId={(departmentId || '').toString()}
            onClose={() => {
              this.setState({ isTimeDoesntWorkOpen: undefined });
            }}
          />
          {/* <TimeDoesntWorkModalInner
            doctorName={this.state.selectedDoctor ? this.state.selectedDoctor.patientFacingName : ''}
            isNewPatient={!this.props.session}
            departmentId={(departmentId || '').toString()}
            onClose={() => {
              this.setState({ isTimeDoesntWorkOpen: false });
            }}
          /> */}
        </ConfirmModal>
      </ClinicalFlex>
    );
  }
}

export const PickDoctorNewPatient = withWizard(PickDoctor);

export default withWizard(withSession(PickDoctor));
