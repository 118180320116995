import React from 'react';
import { Query } from 'react-apollo';
import { Box, Button, Card, Flex, Heading, Image, Text } from 'rebass';
import styled from 'styled-components';
import { Skeleton } from '../components/loadingSkeleton/Skeleton';
import PleaseWait from '../components/PleaseWait';
import { GET_THYROID_GUIDES } from '../graphql';

const SpeakerCardWrapper = styled(Flex)`
  width: 100%;
  background: #dae5fb;
  height: 253px;
  overflow: hidden;
  &:hover > ${Img} {
    transform: scale(1.1);
  }
  @media screen and (max-width: 480px) {
    width: calc(100vw - 24px);
    max-width: 100vw;
    flex-direction: column;
    height: auto;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 10px;
  }
`;

const Description = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  flex: 1;
  font-size: 14px;
  @media screen and (max-width: 480px) {
    margin-bottom: 12px;
  }
`;

const TextWrapper = styled(Flex)`
  width: 300px;
  height: 100%;
  @media screen and (max-width: 480px) {
    width: 100%;
    max-width: 100vw;
    flex-direction: column;
    height: initial;
  }
`;

const Img = styled(Image)`
  transition: 0.3s all ease-in-out;
  object-fit: cover;
  @media screen and (max-width: 480px) {
    max-height: 200px;
  }
`;

const SpeakerCard = ({ serie }) => {
  return (
    <SpeakerCardWrapper mb={4}>
      <Flex flexDirection={'column'} style={{ overflow: 'hidden' }} flex={1}>
        <Img src={serie.image} height="100%" width="100%" style={{ objectFit: 'cover' }} />
      </Flex>
      <TextWrapper flexDirection={'column'} style={{ background: 'white', padding: '22px', overflow: 'hidden' }}>
        <Heading fontSize={'18px'}>{serie.title}</Heading>
        <Description mt={1}>{serie.description}</Description>

        <Button variant={'primary'} alignSelf="center" onClick={() => window.open(serie.guidePdfLink, '_blank')}>
          Download
        </Button>
      </TextWrapper>
    </SpeakerCardWrapper>
  );
};

const SpeakerCardLoading = () => {
  return (
    <SpeakerCardWrapper mb={4}>
      <Flex flexDirection={'column'} style={{ overflow: 'hidden', background: 'rgb(121 146 179 / 8%)', justifyContent: 'center', alignItems: 'center' }} flex={1}>
        <PleaseWait
          text={' '}
          spinner={{
            sColor: '#ECEFF2',
            pColor: '#909FB5',
          }}
        />
      </Flex>
      <TextWrapper flexDirection={'column'} style={{ background: 'white', width: '300px', height: '100%', padding: '22px', overflow: 'hidden' }}>
        <Heading fontSize={'18px'} mb={3}>
          <Skeleton height="20px" width="200px" />
        </Heading>
        <Skeleton height="14px" width="240px" />
        <Skeleton height="14px" width="220px" />
        <Skeleton height="14px" width="140px" />
      </TextWrapper>
    </SpeakerCardWrapper>
  );
};

const ThyroidGuidesInner = ({ data, loading }) => {
  return (
    <Flex flexDirection={'column'} style={{ maxWidth: '700px', width: '100%' }}>
      <Heading textAlign={'center'} mt={'40px'} mb={'24px'}>
        Thyroid Guides
      </Heading>
      {!!loading
        ? [...Array(24)].map((x, i) => {
            return <SpeakerCardLoading key={`loadingGuide${i}`} />;
          })
        : data.getThyroidGuides.map((x) => {
            return <SpeakerCard serie={x} key={`Guide${x.id}`} />;
          })}
    </Flex>
  );
};

const ThyroidGuides = () => {
  return (
    <Query query={GET_THYROID_GUIDES}>
      {({ data, loading }) => {
        return <ThyroidGuidesInner data={data} loading={loading} />;
      }}
    </Query>
  );
};

export default ThyroidGuides;
