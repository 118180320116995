

export class ToggleValues {
  static list = [];
  static get staticVariable() {
    return ToggleValues.values;
  }

  static set staticVariable(data) {
    ToggleValues.values = data;
  }

  static isActive2(toggle) {
    if (!ToggleValues.staticVariable()) {
      return false;
    }

    const el = ToggleValues.staticVariable().filter(x => x.name === toggle);
    if (!el || el.length < 1) {
      return false;
    }
    // console.log('EL', el);
    return el[0].isActive;
  }

  getList() {
    return ToggleValues.list
  }
}

export const isToggleActive=(toggle) =>{ 
  try {
    if (!ToggleValues.list) {
      return false;
    }
  
    const el = ToggleValues.list.filter(x => x.name === toggle);
    if (!el || el.length < 1) {
      return false;
    }
    if(el.length>1){
      console.error(`multiple identical toggle keys`, toggle)
    }
    return el[0].isActive;
  } catch (error) {
    console.log('Err FT', error)
    return false
  }
};

const Toggles = {
  BUY_ONE_GIVE_ONE_KIT:
    window._env_.REACT_APP_FEATURE_TOGGLE_BUY_ONE_GIVE_ONE_KIT &&
    window._env_.REACT_APP_FEATURE_TOGGLE_BUY_ONE_GIVE_ONE_KIT === 'true',

  MOBILE_WALLET:
    window._env_.REACT_APP_FEATURE_TOGGLE_MOBILE_WALLET &&
    window._env_.REACT_APP_FEATURE_TOGGLE_MOBILE_WALLET === 'true',
};

export default Toggles;
