import React from 'react';
import TextTransition, { presets } from 'react-text-transition';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import Header from '../../../components/Header';
import PleaseWait from '../../../components/PleaseWait';
import useUnmount from '../../../utils/hooks/lifecycle/useUnmount';

const Wrapper = styled(Flex)`
  width: calc(100% + 16px);
  flex: 1;
  justify-content: center;
  align-items: center;
  height: calc(100% + 16px);
  background-color: #faf5f1;
  margin: -8px;
`;
const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
`;

const TEXTS = [
  "Ain't no tired like thyroid tired.\nTake a 30 second power nap while we access your file.",
  'Did you know hydration is a pillar of thyroid health?\nPour a drink of water while you wait.',
  'Close your eyes & breathe while we access your file.\nYour adrenals will thank you!',
  'Please hold while we access your info\nso you can start feeling better faster than this page loads.',
  'Say something nice to yourself while you wait.\nSelf-compassion is part of a thyroid-healthy lifestyle.',
  'Time for a 30-second dance party!\nGet those endorphins going and blood flowing.',
  'Text a friend while you wait!\nStudies show that positive relationships are good for our health.',
];

const RotatingText = props => {
  const [index, setIndex] = React.useState(0);
  const [intervalId, setIntervalId] = React.useState(null);
  const [arr, setArr] = React.useState(
    TEXTS.map(a => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map(a => a.value)
  );
  React.useEffect(() => {
    const handle = setInterval(
      () => setIndex(index => index + 1),
      3000 // every 3 seconds
    );
    setIntervalId(handle);
  }, []);

  useUnmount(() => {
    // console.log('unmount', intervalId);
    if (intervalId) {
      clearInterval(intervalId);
    }
  });

  return (
    <Box
      style={{
        whiteSpace: 'nowrap',
        marginTop: '40px',
        width: 'min(300px, 80vw)',
        maxWidth: 'min(300px, 80vw)',
        justifyContent: 'center',
      }}
    >
      <TextTransition
        noOverflow
        style={{
          whiteSpace: 'nowrap',
          wordWrap: 'break-word',
        }}
        text={arr[index % arr.length]}
        springConfig={presets.default}
      />
    </Box>
  );
};

export const Waiter = () => {
  return (
    <Wrapper>
      <Container>
        <Flex flex={1}>
          <Header dark={false} />
        </Flex>
        <PleaseWait
          text=" "
          spinner={{
            sColor: '#ECEFF2',
            pColor: '#909FB5',
          }}
        >
          <RotatingText />
        </PleaseWait>
      </Container>
    </Wrapper>
  );
};
