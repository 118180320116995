import React, { Fragment } from 'react';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';

import FullLayout from '../components/FullLayout';
import Header from '../components/Header';
import withAnalytics from '../lib/withAnalytics';

const Container = styled(Box)`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const CardContainer = styled(Flex)`
  max-width: 400px;
`;

const EmailExistsOtherPlatform = ({ history }) => {
  return (
    <Fragment>
      <Container>
        <Flex flex={1}>
          <Header dark={false} />
        </Flex>
        <Flex flex={1} alignItems="center" justifyContent="center">
          <CardContainer flex={1} px={3}>
            <Card p={4}>
              <Flex flexDirection="column">
                <Box>
                  <Heading textAlign="center" mb={4}>
                    Paloma Health Account
                  </Heading>
                  <Text textAlign="center" mb={2}>
                    It looks like you already have an account on our mobile app
                    (Paloma Health: AIP Guide)!
                  </Text>
                  <Text textAlign="center" mb={4}>
                    You can use the same info to log in here and benefit from a
                    fully connected experience!
                  </Text>
                </Box>
                <Button
                  variant="primary"
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  Go To Login Page
                </Button>
              </Flex>
            </Card>
          </CardContainer>
        </Flex>
      </Container>
      <FullLayout />
    </Fragment>
  );
};

export default withAnalytics(withToastManager(EmailExistsOtherPlatform));
