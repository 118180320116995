export const BlackListedCouponsOnBogo = [
    'kayla4paloma',
    'jessica4paloma',
    'CARLA4PALOMA',
    'carla4paloma',
    'FORTYOFF',
    'Paloma4Jessica',
    'TLC4PALOMA',
    'paloma4kelly',
    'MARIAJ40',
    'WOMEN20',
    'kelly10252019',
    'JILL4PALOMA',
    'jill4paloma',
    'drdemeri4paloma',
    'noelle4paloma',
    'amie4paloma',
    'kimi4paloma',
    'jenna4paloma',
    'artemis4paloma',
    'WINGWOMAN',
    'maria4paloma',
    'drew4paloma',
    'hannah4paloma',
    'Lori4paloma',
    'LORI4PALOMA',
    'lori4paloma',
    'INFLUENCER4PALOMA',
    'INTAKE',
    'THANKSCHRISTINA',
    'kristen4paloma',
    'HEATHER4PALOMA',
    'ANITA4PALOMA',
    'STACEY4PALOMA',
    'fitmommy4paloma',
    'empowered4paloma',
    'heather4paloma',
    'fawn4paloma',
    'drblock',
    'Brandi4paloma',
    'brandi4paloma',
    'XmQytYHQ',
    'cory4paloma',
    'Anita4paloma',
    'lunges4paloma',
    'nrx4paloma',
    'sheeva4paloma',
    'ginger4paloma',
    'zesty4paloma',
    'ilka4paloma',
    'momables4paloma',
    'shelley4paloma',
    'vanessa4paloma',
    'brittany4paloma',
    'Deena4Paloma',
    'THANKSANNA',
    'frolic4paloma',
    'paloma4anita',
    'ladyoflyme4paloma',
    'donna4paloma',
    'THYROIDYOGA',
    'shaw4paloma',
    'jana4paloma',
    'drkim4paloma',
    'veronika4paloma',
    'Paloma4anita',
    'jess4paloma',
    'thyroid4paloma',
    'anastasia4paloma',
    'ben4paloma',
    'felicia4paloma',
    'christina4paloma',
    'rebecca4paloma',
    'natasha4paloma',
    '40AGAIN',
    'aimee4paloma',
    'mariza4paloma',
    'taylor4paloma',
    'katie4paloma',
    'sjd62E2n',
    'WPKyJxlD',
    'OlCxWAWY',
    'HALFOFF',
    'lacey4paloma',
    'elissa4paloma',
    'kristie4paloma',
    'saarah4paloma',
    'maggie4paloma',
    'victoria4paloma',
    'anita4paloma',
    'alaena4paloma',
    'maranda4paloma',
    'rachael4paloma',
    'jenn4paloma',
    'tracey4paloma',
    'laura4paloma',
    'sophie4paloma',
    'becky4paloma',
    'stacey4paloma',
    'maryalice4paloma',
    'neeyaz4paloma',
    'Mia40',
    'arielle4paloma',
    'phoebe4paloma',
    'getbetter',
    'Getbetter',
    'bianca4paloma',
    'vicki4paloma',
    'whitney4paloma',
    'autumn4paloma',
    'amanda4paloma',
    'ivy4paloma',
    'ingrid4paloma',
    'michelle4paloma',
    'GETBETTER',
    'hworbteR',
    'mM1piWkA',
  ];
  
  export const checkBlackListed = (list, couponToCheck) => {
      const value = couponToCheck.toUpperCase().trim();
  
      const matches = list.filter(x=>x.toUpperCase().trim() === value);
  
      return matches && matches.length > 0;
  }
  