import React, { Fragment } from 'react';
import { Text, Button, Flex, Card, Box } from 'rebass';
import TextInput from '../../components/TextInput';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import * as Yup from 'yup';
import Select from 'react-select';
import { MaskInput } from '../../components/MaskInput';
import { schemaInsuranceInfoBypassMemberId, schemaInsuranceInfoDependantBypassMemberId } from '../scheduleDoctor/validation/yupSchemas/insuranceInfo';

const schema = Yup.object().shape({
  primarySubscriberFullName: Yup.string()
    .required('Required'),
    dob: Yup.string()
      .required('Required'),
});

const InsuranceInfo = ({ isDependant, setIsDependant, submitInsuranceInfo, selectedProvider, initialValues = {}, isDetailsSubmitted, unsubmitDetails }) => {
  const getValidationSchema = () => {
    return isDependant ? schemaInsuranceInfoDependantBypassMemberId : schemaInsuranceInfoBypassMemberId;
  };

  return (
    <Box>
      <Flex mb={4} flexDirection="column">
        <Formik
          initialValues={{
            primaryInsured: initialValues.primaryInsured !== undefined ? initialValues.primaryInsured : true,
            dob: initialValues.dob,
            primarySubscriberFullName: initialValues.primarySubscriberFullName,
          }}
          validationSchema={schema}
          onSubmit={(values) => submitInsuranceInfo(values)}
          render={({ touched, errors, isValid, values, handleBlur, handleChange, setFieldValue, setFieldTouched }) => (
            <Form>
              <Text mb={16}>
                Are you the primary subscriber?
              </Text>
              <Flex flexDirection={['row']} flexWrap="wrap" mb={32} alignItems="center">
                <Box
                  onClick={() => setIsDependant(false)}
                  style={{
                    backgroundColor: isDependant === false ? '#344f79' : 'white',
                    borderRadius: 12,
                    padding: '4px 8px',
                    color: isDependant === false ? 'white' : '#344f79',
                    border: '1px solid #344f79',
                    margin: 6,
                    cursor: 'pointer',
                    width: '140px',
                    borderRadius: '50px',
                    textAlign: 'center',
                  }}
                >
                  <Text>Yes</Text>
                </Box>
                <Box
                  onClick={() => setIsDependant(true)}
                  style={{
                    backgroundColor: isDependant === true ? '#344f79' : 'white',
                    borderRadius: 12,
                    padding: '4px 8px',
                    color: isDependant === true ? 'white' : '#344f79',
                    border: '1px solid #344f79',
                    margin: 6,
                    marginLeft: 0,
                    cursor: 'pointer',
                    width: '140px',
                    borderRadius: '50px',
                    textAlign: 'center',
                  }}
                >
                  <Text>No</Text>
                </Box>
              </Flex>
              {isDependant === true && (
                <Fragment>
                  <div
                    style={{
                      height: '1px',
                      backgroundColor: '#ccc',
                      margin: '40px -16px',
                    }}
                  />
                  <Text mb={2} mt={4} fontSize="20px">
                    Primary Subscriber Details
                  </Text>
                  <Text mb={4} fontSize="16px" style={{ opacity: 0.75 }}>
                    In order to retrieve your insurance, we need the primary subscriber's information
                  </Text>
                  {/* <Flex flexDirection={['column', 'column', 'row']} alignItems="baseline" mb={4}> */}
                  {/* <Text textAlign="center" fontSize="16px" style={{ opacity: 0.75 }}>
                      For the primary subscriber, I am:
                    </Text>
                    <FormField flex={1} ml={[0, 0, 3]} width="100%" error={touched.relationship && errors.relationship}>
                      <div style={{ width: '100%' }}>
                        <Select
                          classNamePrefix="region-select"
                          options={dependantRelationships}
                          name="relationship"
                          value={values.relationship}
                          placeholder="select relationship"
                          onChange={(option) => {
                            setFieldValue('relationship', option);
                            unsubmitDetails()
                          }}
                          onBlur={() => setFieldTouched('relationship', true)}
                          isSearchable={true}
                        />
                      </div>
                    </FormField>
                  </Flex>
                  <Flex flexDirection={['column', 'column', 'row']} style={{ alignItems: 'baseline' }}> */}
                  {/* <FormField flex={[1, 1, 1 / 3]} mb={3} mr={[0, 0, 3]} error={touched.primary_sex && errors.primary_sex}>
                      <Select
                        classNamePrefix="region-select"
                        options={insuranceHolderSex}
                        name="primary_sex"
                        value={values.primary_sex}
                        placeholder="select title"
                        onChange={(option) => {
                          setFieldValue('primary_sex', option);
                          unsubmitDetails()
                        }}
                        onBlur={() => setFieldTouched('primary_sex', true)}
                        isSearchable={true}
                      />
                    </FormField>
                    <FormField flex={[1, 1, 1 / 3]} mb={3} mr={[0, 0, 3]} error={touched.primary_firstName && errors.primary_firstName}>
                      <TextInput
                          onChange={(option) => {
                            setFieldValue('primary_firstName', option.target.value);
                            unsubmitDetails()
                          }} placeholder="First Name" type="text" name="primary_firstName" id="input_firstname" />
                    </FormField>
                    <FormField flex={[1, 1, 1 / 3]} mb={3} error={touched.primary_lastName && errors.primary_lastName}>
                      <TextInput
                          onChange={(option) => {
                            setFieldValue('primary_lastName', option.target.value);
                            unsubmitDetails()
                          }}  placeholder="Last Name" type="text" name="primary_lastName" id="input_lastname" />
                    </FormField>
                  </Flex>

                  <FormField mb={3} error={touched.primary_street1 && errors.primary_street1}>
                    <TextInput
                          onChange={(option) => {
                            setFieldValue('primary_street1', option.target.value);
                            unsubmitDetails()
                          }} placeholder="Street 1" type="text" name="primary_street1" id="input_street1" />
                  </FormField>

                  <FormField mb={3} error={touched.primary_street2 && errors.primary_street2}>
                    <TextInput
                          onChange={(option) => {
                            setFieldValue('primary_street2', option.target.value);
                            unsubmitDetails()
                          }} placeholder="Street 2" type="text" name="primary_street2" id="input_street2" />
                  </FormField>

                  <Flex flexDirection={['column', 'column', 'row']}>
                    <FormField width={[1, 1, 1 / 3]} mb={4} error={touched.primary_city && errors.primary_city}>
                      <TextInput
                          onChange={(option) => {
                            setFieldValue('primary_city', option.target.value);
                            unsubmitDetails()
                          }} placeholder="City" type="text" name="primary_city" id="input_city" />
                    </FormField>

                    <FormField width={[1, 1, 1 / 3]} mb={4} mx={[0, 0, 3]} error={touched.primary_state && errors.primary_state}>
                      <RegionSelect
                          onSelectionChange={(option) => {
                            unsubmitDetails()
                          }} name="primary_state" value={values.primary_state} id="select_state" />
                    </FormField>

                    <FormField width={[1, 1, 1 / 3]} mb={4} error={touched.primary_zip && errors.primary_zip}>
                      <TextInput
                          onChange={(option) => {
                            setFieldValue('primary_zip', option.target.value);
                            unsubmitDetails()
                          }} placeholder="Zip" type="text" name="primary_zip" id="input_zip" />
                    </FormField> */}
                  <FormField width={1} mb={3} error={touched.primarySubscriberFullName && errors.primarySubscriberFullName}>
                    <label for="primarySubscriberFullName">Full name of the primary subscriber</label>
                    <TextInput
                      name="primarySubscriberFullName"
                      placeholder={`Full name`}
                      onChange={(option) => {
                        setFieldValue('primarySubscriberFullName', option.target.value);
                        unsubmitDetails();
                      }}
                    />
                  </FormField>
                  <FormField mb={4} error={touched.dob && errors.dob}>
                    <label for="dob">Primary Subscriber Date of Birth (MM/DD/YYYY)</label>
                    <MaskInput
                      name="dob"
                      placeholder="MM/DD/YYYY"
                      mask="99/99/9999"
                      value={values.dob}
                      // onChange={handleChange}
                      onBlur={handleBlur}
                      id="input_dob"
                      onChange={(option) => {
                        setFieldValue('dob', option.target.value);
                        unsubmitDetails();
                      }}
                    />
                  </FormField>
                  {/* </Flex> */}
                </Fragment>
              )}
              {!isDetailsSubmitted && (
                <Flex flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                  <Button variant="pink" width={[1 / 2, 1 / 3]} type="submit" disabled={!isValid || !values.dob || values.dob.indexOf('_')>-1}>
                    Next
                  </Button>
                </Flex>
              )}
            </Form>
          )}
        />
      </Flex>
    </Box>
  );
};

export default InsuranceInfo;
