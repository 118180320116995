import React, { Fragment, useState } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import FullLayout from '../../../components/FullLayout';
import { Mutation, Query } from 'react-apollo';
import {
  ADMIN_ALLOW_ONE_TIME_VISIT_SKIP,
  ADMIN_CHANGE_USER_BOOLEAN_FLAG_VALUE,
  ADMIN_CHANGE_USER_STRING_VALUE,
  ADMIN_GET_CARE_MANAGERS_LIST,
  ADMIN_REMOVE_ONE_TIME_VISIT_SKIP,
  ADMIN_RESET_BASELINE,
  RESET_PATIENT_INTAKE_STATUS,
} from '../../../graphql';
import ConfirmModal from '../../../components/ConfirmModal';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Input } from '../../../components/TextInput';
import ModalReassignCareManager from '../../adminCareManagers/ModalReassignCareManager';
import PleaseWait from '../../../components/PleaseWait';
import { MobileView } from './MobileView';
import { AthenaView } from './AthenaView';
import { SurveysView } from './SurveysView';
import moment from 'moment';
import AdminReorderPhysicianConvo from '../../adminReorderPhysicianConvo/AdminReorderPhysicianConvo';

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const FullModalInner = styled(Box)`
  height: 95vh;
  max-height: 95vh;
  width: 95vw;
  max-width: 95vw;
  /* background-color: #f1f3f5; */
`;

export const WebView = ({ data, refetch, toastManager, isEditable }) => {
  const [isShowMobile, setIsShowMobile] = useState(false);
  const [showConfirmResetBaseline, setShowConfirmResetBaseline] = useState(false);
  const [showChangeBooleanFlag, setShowChangeBooleanFlag] = useState(null);
  const [showChangeStringValue, setShowChangeStringValue] = useState(null);
  const [showResetIntakeStatus, setShowResetIntakeStatus] = useState(null);
  const [showResetMenoIntakeStatus, setShowResetMenoIntakeStatus] = useState(null);
  const [showReassignCareManager, setShowReassignCareManager] = useState(false);
  const [showPhysicianConvoTree, setViewPhysicianConvoTree] = useState(null);
  const [editInputValue, setEditInputValue] = useState(null);
  const EDIT_MODE_FEATURE_ACTIVE = isEditable;

  const handleCloseModalConfirmationResetBaseline = () => {
    setShowConfirmResetBaseline(false);
  };

  const handleCloseModalChangeBooleanFlag = () => {
    setShowChangeBooleanFlag(null);
  };

  const handleCloseModalResetIntakeStatus = () => {
    setShowResetIntakeStatus(null);
    setShowResetMenoIntakeStatus(null)
  };

  const handleCloseModalViewPhysicianConvoTree = () => {
    setViewPhysicianConvoTree(false);
  };

  const handleCloseModalReassignCareManager = () => {
    setShowReassignCareManager(false);
  };

  const handleCloseModalChangeStringValue = () => {
    setShowChangeStringValue(null);
    setEditInputValue(null);
  };

  const handleShowModalChangeStringValue = (value) => {
    setShowChangeStringValue(value);
    setEditInputValue((value || {}).currentValue);
  };

  if (showConfirmResetBaseline) {
    return (
      <Mutation
        mutation={ADMIN_RESET_BASELINE}
        variables={{
          id: (data || {}).athenaId,
        }}
        onCompleted={async () => {
          await refetch();
          setShowConfirmResetBaseline(false);
        }}
      >
        {(mutate, { loading }) => (
          <Fragment>
            <ConfirmModal
              isOpen={showConfirmResetBaseline}
              onClose={handleCloseModalConfirmationResetBaseline}
              onBackgroundClick={handleCloseModalConfirmationResetBaseline}
              onEscapeKeydown={handleCloseModalConfirmationResetBaseline}
              childrenManaged
            >
              <ModalInner>
                <Heading mb={4} textAlign="center">
                  Warning
                </Heading>

                <Text fontSize={3} textAlign="center" mb={4}>
                  Resetting the baseline survey status will request the user to fill it. If a user already had an appointment it will give us wrong information and lower patient experience.
                </Text>

                <Flex justifyContent={'center'}>
                  <Button
                    disabled={!!loading}
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={handleCloseModalConfirmationResetBaseline}
                  >
                    Cancel
                  </Button>
                  <Button
                    ml={4}
                    disabled={!!loading}
                    style={{ background: '#F6A293' }}
                    onClick={() => {
                      mutate();
                    }}
                  >
                    Reset Survey Status
                  </Button>
                </Flex>
              </ModalInner>
            </ConfirmModal>
            <FullLayout />
          </Fragment>
        )}
      </Mutation>
    );
  }

  if (!!showChangeBooleanFlag) {
    const { flagName, name, description, currentValue, onNull } = showChangeBooleanFlag;
    return (
      <Mutation
        mutation={ADMIN_CHANGE_USER_BOOLEAN_FLAG_VALUE}
        variables={{
          id: (data || {}).id,
          flagName: flagName,
          value: !currentValue ? true : false,
        }}
        onCompleted={async () => {
          await refetch();
          handleCloseModalChangeBooleanFlag();
        }}
      >
        {(mutate, { loading }) => (
          <Fragment>
            <ConfirmModal
              isOpen={true}
              onClose={handleCloseModalChangeBooleanFlag}
              onBackgroundClick={handleCloseModalChangeBooleanFlag}
              onEscapeKeydown={handleCloseModalChangeBooleanFlag}
              childrenManaged
            >
              <ModalInner>
                <Heading mb={4} textAlign="center">
                  Change {name}
                </Heading>

                <Text fontSize={3} textAlign="center" mb={3}>
                  {description}
                </Text>
                <Text fontSize={3} textAlign="center" mb={4}>
                  change from <span style={{ fontWeight: 500 }}>{currentValue === null ? onNull : currentValue === true ? 'Yes' : 'No'}</span> to{' '}
                  <span style={{ fontWeight: 500 }}>{!currentValue ? 'Yes' : 'No'}</span>
                </Text>

                <Flex justifyContent={'center'}>
                  <Button
                    disabled={!!loading}
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={handleCloseModalChangeBooleanFlag}
                  >
                    Cancel
                  </Button>
                  <Button
                    ml={4}
                    disabled={!!loading}
                    style={{ background: '#F6A293' }}
                    onClick={() => {
                      mutate();
                    }}
                  >
                    Change
                  </Button>
                </Flex>
              </ModalInner>
            </ConfirmModal>
            <FullLayout />
          </Fragment>
        )}
      </Mutation>
    );
  }

  if (!!showResetIntakeStatus) {
    return (
      <Mutation
        mutation={RESET_PATIENT_INTAKE_STATUS}
        variables={{
          patientId: (data || {}).athenaId,
        }}
        onCompleted={async () => {
          await refetch();
          handleCloseModalResetIntakeStatus();
        }}
      >
        {(mutate, { loading }) => (
          <Fragment>
            <ConfirmModal
              isOpen={true}
              onClose={handleCloseModalResetIntakeStatus}
              onBackgroundClick={handleCloseModalResetIntakeStatus}
              onEscapeKeydown={handleCloseModalResetIntakeStatus}
              childrenManaged
            >
              <ModalInner>
                <Heading mb={4} textAlign="center">
                  Reset Intake Status
                </Heading>

                <Text fontSize={3} textAlign="center" mb={3}>
                  Will reset the patient intake status
                </Text>

                <Flex justifyContent={'center'}>
                  <Button
                    disabled={!!loading}
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={handleCloseModalResetIntakeStatus}
                  >
                    Cancel
                  </Button>
                  <Button
                    ml={4}
                    disabled={!!loading}
                    style={{ background: '#F6A293' }}
                    onClick={() => {
                      mutate();
                    }}
                  >
                    Reset
                  </Button>
                </Flex>
              </ModalInner>
            </ConfirmModal>
            <FullLayout />
          </Fragment>
        )}
      </Mutation>
    );
  }

  if (!!showResetMenoIntakeStatus) {
    return (
      <Mutation
        mutation={RESET_PATIENT_INTAKE_STATUS}
        variables={{
          patientId: (data || {}).athenaId,
          targetType: 'meno'
        }}
        onCompleted={async () => {
          await refetch();
          handleCloseModalResetIntakeStatus();
        }}
      >
        {(mutate, { loading }) => (
          <Fragment>
            <ConfirmModal
              isOpen={true}
              onClose={handleCloseModalResetIntakeStatus}
              onBackgroundClick={handleCloseModalResetIntakeStatus}
              onEscapeKeydown={handleCloseModalResetIntakeStatus}
              childrenManaged
            >
              <ModalInner>
                <Heading mb={4} textAlign="center">
                  Reset Menopause Intake Status
                </Heading>

                <Text fontSize={3} textAlign="center" mb={3}>
                  Will reset the patient's menopause intake status
                </Text>

                <Flex justifyContent={'center'}>
                  <Button
                    disabled={!!loading}
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={handleCloseModalResetIntakeStatus}
                  >
                    Cancel
                  </Button>
                  <Button
                    ml={4}
                    disabled={!!loading}
                    style={{ background: '#F6A293' }}
                    onClick={() => {
                      mutate();
                    }}
                  >
                    Reset
                  </Button>
                </Flex>
              </ModalInner>
            </ConfirmModal>
            <FullLayout />
          </Fragment>
        )}
      </Mutation>
    );
  }

  if (showReassignCareManager) {
    return (
      <ConfirmModal isOpen={true} onClose={handleCloseModalResetIntakeStatus} onBackgroundClick={handleCloseModalResetIntakeStatus} onEscapeKeydown={handleCloseModalResetIntakeStatus} childrenManaged>
        <ModalInner>
          <Query query={ADMIN_GET_CARE_MANAGERS_LIST}>
            {({ data: dataCare, loading, error }) => {
              if (!!loading) return <PleaseWait />;
              return (
                <ModalReassignCareManager
                  careManagers={(dataCare || {}).adminGetCareManagersList}
                  user={data}
                  onClose={() => {
                    setShowReassignCareManager(false);
                    refetch();
                  }}
                  closeAnyway={() => {
                    setShowReassignCareManager(false);
                    refetch();
                  }}
                  search={(data || {}).athenaId}
                />
              );
            }}
          </Query>
        </ModalInner>
      </ConfirmModal>
    );
  }

  const validateEmailProps = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  if (!!showChangeStringValue) {
    const { flagName, name, description, currentValue, onNull, isEmail, validate, warningText } = showChangeStringValue;
    return (
      <Mutation
        mutation={ADMIN_CHANGE_USER_STRING_VALUE}
        variables={{
          id: (data || {}).id,
          flagName: flagName,
          value: editInputValue,
        }}
        onCompleted={async (res) => {
          await refetch();
          handleCloseModalChangeStringValue();
          if (!!res && res.adminChangeUserStringValue) {
            if (!!res.adminChangeUserStringValue.ok) {
              toastManager.add('Update successful', {
                appearance: 'success',
              });
            } else {
              toastManager.add(res.adminChangeUserStringValue.customError || 'An error occured', {
                appearance: 'error',
              });
            }
          } else {
            toastManager.add('An error occured', {
              appearance: 'error',
            });
          }
        }}
      >
        {(mutate, { loading }) => (
          <Fragment>
            <ConfirmModal
              isOpen={true}
              onClose={handleCloseModalChangeStringValue}
              onBackgroundClick={handleCloseModalChangeStringValue}
              onEscapeKeydown={handleCloseModalChangeStringValue}
              childrenManaged
            >
              <ModalInner>
                <Heading mb={4} textAlign="center">
                  Change {name}
                </Heading>

                {!!warningText && (
                  <Box border="1px solid #F6A293" style={{ borderRadius: '6px', backgroundColor: '#FDF5F2' }} p={3} mb={4}>
                    <Text>{warningText}</Text>
                  </Box>
                )}

                <Text textAlign="center" mb={3}>
                  {description}
                </Text>
                <Text textAlign="center" mb={4}>
                  Current Value: <span style={{ fontWeight: 500 }}>{currentValue}</span>
                </Text>

                <Input
                  value={editInputValue}
                  onChange={(r) => {
                    setEditInputValue(r.target.value);
                  }}
                />

                <Flex justifyContent={'center'} mt={4}>
                  <Button
                    disabled={!!loading}
                    style={{
                      background: 'white',
                      color: '#344f79',
                      border: '1px solid #344f79',
                    }}
                    type="button"
                    onClick={handleCloseModalChangeStringValue}
                  >
                    Cancel
                  </Button>
                  <Button
                    ml={4}
                    disabled={!!loading || (!!isEmail && !validateEmailProps(editInputValue)) || (!!validate && !validate(editInputValue))}
                    style={{ background: '#F6A293' }}
                    onClick={() => {
                      mutate();
                    }}
                  >
                    Change
                  </Button>
                </Flex>
              </ModalInner>
            </ConfirmModal>
            <FullLayout />
          </Fragment>
        )}
      </Mutation>
    );
  }
  return (
    <Flex flexDirection={'column'} style={{ textAlign: 'left' }}>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>id:</Text>
          <Text fontWeight={500}>{(data || {}).id}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>email:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).email}
            </Text>
            {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change flag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleShowModalChangeStringValue({
                    flagName: 'email',
                    name: 'Email',
                    description: "Change the patient's email",
                    currentValue: (data || {}).email,
                    onNull: '',
                    isEmail: true,
                    warningText: 'This will update all credential emails both web and mobile if any',
                  });
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )}
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>athenaId:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).athenaId}
            </Text>
            {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change flag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleShowModalChangeStringValue({
                    flagName: 'athenaId',
                    name: 'Athena ID',
                    description: "Change the patient's Athena ID",
                    currentValue: (data || {}).athenaId,
                    onNull: '',
                    validate: (val) => {
                      if (!val) return false;
                      return !isNaN(val);
                    },
                  });
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>first name:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).firstName}
            </Text>
            {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change flag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleShowModalChangeStringValue({
                    flagName: 'firstName',
                    name: 'First Name',
                    description: "Change the patient's first name",
                    currentValue: (data || {}).firstName,
                    onNull: '',
                    validate: (val) => {
                      if (!val) return false;
                      return val.length > 1;
                    },
                  });
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )}
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>last name:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).lastName}
            </Text>
            {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change flag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleShowModalChangeStringValue({
                    flagName: 'lastName',
                    name: 'Last Name',
                    description: "Change the patient's last name",
                    currentValue: (data || {}).lastName,
                    onNull: '',
                    validate: (val) => {
                      if (!val) return false;
                      return val.length > 1;
                    },
                  });
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>is Member:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).isMember ? 'yes' : 'no'}
            </Text>
          </Flex>
        </Flex>
        {(data || {}).isMember && (
          <Flex flexDirection={'column'} flex={1}>
            <Text fontWeight={300}>Membership Type:</Text>
            <Flex alignItems={'center'}>
              <Text fontWeight={500} mr={3}>
                {(data || {}).membershipSpecific}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>

      {(data || {}).isMember &&
        (data || {}).membershipSpecific === 'insurance' && (
          <Flex flexDirection={'row'} mb={2}>
            <Flex flexDirection={'column'} flex={1}>
              <Text fontWeight={300}>Can schedule out of network:</Text>
              <Flex alignItems={'center'}>
                <Text fontWeight={500} mr={3}>
                  {(data || {}).oonSchedulable ? 'Yes' : 'No'}
                </Text>
                {EDIT_MODE_FEATURE_ACTIVE && (
                  <svg
                    data-tip="Change flag"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setShowChangeBooleanFlag({
                        flagName: 'oonSchedulable',
                        name: 'Out of Network Exception',
                        description: 'Change the flag value. If set to true, the patient will be able to book with out of network providers',
                        currentValue: (data || {}).oonSchedulable,
                        onNull: 'No',
                      });
                    }}
                  >
                    <path
                      d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                      fill="#F6A293"
                    />
                  </svg>
                )}
              </Flex>
            </Flex>
          </Flex>
        )}

      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>created at:</Text>
          <Text fontWeight={500}>{(data || {}).createdAt}</Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>account locked:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).accountLocked ? 'yes' : 'no'}
            </Text>
            {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change flag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowChangeBooleanFlag({
                    flagName: 'accountLocked',
                    name: 'Account Locked',
                    description: "Change the flag value. If set to true, the patient's account will be locked",
                    currentValue: (data || {}).accountLocked,
                    onNull: 'No',
                  });
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>must reset password:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).mustResetPassword ? 'yes' : 'no'}
            </Text>
            {/* {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change flag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowChangeBooleanFlag({
                    flagName: 'mustResetPassword',
                    name: 'Must reset password',
                    description: 'Change the flag value. If set to true, the patient will be required to set a new password',
                    currentValue: (data || {}).mustResetPassword,
                    onNull: 'No',
                  });
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )} */}
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>must complete profile:</Text>
          <Flex flexDirection={'row'}>
            <Text fontWeight={500}>{(data || {}).mustCompleteProfile ? 'yes' : 'no'}</Text>
            <svg
              data-tip="Change flag"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowChangeBooleanFlag({
                  flagName: 'mustCompleteProfile',
                  name: 'Complete profile from mobile user',
                  description: 'Change the flag value. If set to true, the patient will need to complete the web profile',
                  currentValue: (data || {}).mustCompleteProfile,
                  onNull: 'Legacy (No)',
                });
              }}
            >
              <path
                d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                fill="#F6A293"
              />
            </svg>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>intake status:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500}>{(data || {}).intakeStatus || 'not started'}</Text>
            <svg
              data-tip={'Reset intake status'}
              onClick={() => setShowResetIntakeStatus(true)}
              width="16"
              height="16"
              viewBox="0 0 24 24"
              style={{ cursor: 'pointer' }}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 21C9.7 21 7.696 20.2373 5.988 18.712C4.27933 17.1873 3.3 15.2833 3.05 13H5.1C5.33333 14.7333 6.104 16.1667 7.412 17.3C8.72067 18.4333 10.25 19 12 19C13.95 19 15.604 18.3207 16.962 16.962C18.3207 15.604 19 13.95 19 12C19 10.05 18.3207 8.39567 16.962 7.037C15.604 5.679 13.95 5 12 5C10.85 5 9.775 5.26667 8.775 5.8C7.775 6.33333 6.93333 7.06667 6.25 8H9V10H3V4H5V6.35C5.85 5.28333 6.88767 4.45833 8.113 3.875C9.33767 3.29167 10.6333 3 12 3C13.25 3 14.421 3.23733 15.513 3.712C16.6043 4.18733 17.5543 4.829 18.363 5.637C19.171 6.44567 19.8127 7.39567 20.288 8.487C20.7627 9.579 21 10.75 21 12C21 13.25 20.7627 14.4207 20.288 15.512C19.8127 16.604 19.171 17.554 18.363 18.362C17.5543 19.1707 16.6043 19.8127 15.513 20.288C14.421 20.7627 13.25 21 12 21ZM14.8 16.2L11 12.4V7H13V11.6L16.2 14.8L14.8 16.2Z"
                fill="#F6A293"
              />
            </svg>
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>intake date:</Text>
          <Text fontWeight={500}>{(data || {}).intakeDate}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>menopause intake status:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500}>{(data || {}).menoIntakeStatus || 'not started'}</Text>
            <svg
              data-tip={'Reset intake status'}
              onClick={() => setShowResetMenoIntakeStatus(true)}
              width="16"
              height="16"
              viewBox="0 0 24 24"
              style={{ cursor: 'pointer' }}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 21C9.7 21 7.696 20.2373 5.988 18.712C4.27933 17.1873 3.3 15.2833 3.05 13H5.1C5.33333 14.7333 6.104 16.1667 7.412 17.3C8.72067 18.4333 10.25 19 12 19C13.95 19 15.604 18.3207 16.962 16.962C18.3207 15.604 19 13.95 19 12C19 10.05 18.3207 8.39567 16.962 7.037C15.604 5.679 13.95 5 12 5C10.85 5 9.775 5.26667 8.775 5.8C7.775 6.33333 6.93333 7.06667 6.25 8H9V10H3V4H5V6.35C5.85 5.28333 6.88767 4.45833 8.113 3.875C9.33767 3.29167 10.6333 3 12 3C13.25 3 14.421 3.23733 15.513 3.712C16.6043 4.18733 17.5543 4.829 18.363 5.637C19.171 6.44567 19.8127 7.39567 20.288 8.487C20.7627 9.579 21 10.75 21 12C21 13.25 20.7627 14.4207 20.288 15.512C19.8127 16.604 19.171 17.554 18.363 18.362C17.5543 19.1707 16.6043 19.8127 15.513 20.288C14.421 20.7627 13.25 21 12 21ZM14.8 16.2L11 12.4V7H13V11.6L16.2 14.8L14.8 16.2Z"
                fill="#F6A293"
              />
            </svg>
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>menopause intake date:</Text>
          <Text fontWeight={500}>{(data || {}).menoIntakeDate}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>helpscout Id:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500}>{(data || {}).helpscoutId}</Text>
            {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change flag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleShowModalChangeStringValue({
                    flagName: 'helpscoutId',
                    name: 'Helpscout Id',
                    description: "Change the patient's Helpscout ID",
                    currentValue: (data || {}).helpscoutId,
                    onNull: '',
                    validate: (val) => {
                      if (!val) return false;
                      return !isNaN(val);
                    },
                  });
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )}
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>stripe Id:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).stripeId}
            </Text>
            {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change flag"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleShowModalChangeStringValue({
                    flagName: 'stripeId',
                    name: 'Stripe ID',
                    description: "Change the patient's stripe ID",
                    currentValue: (data || {}).stripeId,
                    onNull: '',
                  });
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>care Manager:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500}>{(data || {}).careManager}</Text>

            {EDIT_MODE_FEATURE_ACTIVE && (
              <svg
                data-tip="Change Care Manager"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowReassignCareManager(true);
                }}
              >
                <path
                  d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                  fill="#F6A293"
                />
              </svg>
            )}
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>is ZocDoc:</Text>
          <Text fontWeight={500}>{(data || {}).isZocDoc ? 'yes' : 'no'}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>profile Completion Uid:</Text>
          <Text fontWeight={500}>{(data || {}).profileCompletionUid}</Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>recovery Uid:</Text>
          <Text fontWeight={500}>{(data || {}).recoveryUid}</Text>
        </Flex>
      </Flex>

      <Flex flexDirection={'column'} flex={1}>
        <Text fontWeight={300}>can skip membership on schedule:</Text>
        <Flex alignItems={'center'}>
          <Text
            fontWeight={500}
            mr={3}
            color={(data || {}).hasOneTimeExceptionBook && (data || {}).hasOneTimeExceptionBook.expiresOn ? (moment().isAfter((data || {}).hasOneTimeExceptionBook.expiresOn) ? 'red' : '') : ''}
          >
            {(data || {}).hasOneTimeExceptionBook
              ? (data || {}).hasOneTimeExceptionBook.expiresOn
                ? moment().isAfter((data || {}).hasOneTimeExceptionBook.expiresOn)
                  ? `${moment((data || {}).hasOneTimeExceptionBook.expiresOn).format('MM/DD/YYYY hh:mmA')}-expired`
                  : moment((data || {}).hasOneTimeExceptionBook.expiresOn).format('MM/DD/YYYY hh:mmA')
                : 'no'
              : 'no'}
          </Text>
          {EDIT_MODE_FEATURE_ACTIVE && (
            <>
              {!(data || {}).hasOneTimeExceptionBook || !(data || {}).hasOneTimeExceptionBook.expiresOn || moment().isAfter((data || {}).hasOneTimeExceptionBook.expiresOn) ? (
                <Mutation
                  mutation={ADMIN_ALLOW_ONE_TIME_VISIT_SKIP}
                  variables={{
                    id: (data || {}).id,
                  }}
                  onCompleted={async () => {
                    await refetch();
                  }}
                >
                  {(mutate, { loading }) => {
                    if (!!loading) {
                      return <Text style={{ opacity: 0.5 }}>Processing...</Text>;
                    }
                    return (
                      <svg onClick={mutate} width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12 17C12.2833 17 12.521 16.904 12.713 16.712C12.905 16.52 13.0007 16.2827 13 16V13H16.025C16.3083 13 16.5417 12.904 16.725 12.712C16.9083 12.52 17 12.2827 17 12C17 11.7167 16.904 11.479 16.712 11.287C16.52 11.095 16.2827 10.9993 16 11H13V7.975C13 7.69167 12.904 7.45833 12.712 7.275C12.52 7.09167 12.2827 7 12 7C11.7167 7 11.479 7.096 11.287 7.288C11.095 7.48 10.9993 7.71733 11 8V11H7.975C7.69167 11 7.45833 11.096 7.275 11.288C7.09167 11.48 7 11.7173 7 12C7 12.2833 7.096 12.521 7.288 12.713C7.48 12.905 7.71733 13.0007 8 13H11V16.025C11 16.3083 11.096 16.5417 11.288 16.725C11.48 16.9083 11.7173 17 12 17ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.2217 16.105 20.0007 14.2173 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.105 4.77833 14.2173 3.99933 12 4C9.78333 4 7.89567 4.779 6.337 6.337C4.77833 7.895 3.99933 9.78267 4 12C4 14.2167 4.779 16.1043 6.337 17.663C7.895 19.2217 9.78267 20.0007 12 20Z"
                          fill="#F6A293"
                        />
                      </svg>
                    );
                  }}
                </Mutation>
              ) : (
                <Mutation
                  mutation={ADMIN_REMOVE_ONE_TIME_VISIT_SKIP}
                  variables={{
                    id: (data || {}).id,
                  }}
                  onCompleted={async () => {
                    await refetch();
                  }}
                >
                  {(mutate, { loading }) => {
                    if (!!loading) {
                      return <Text style={{ opacity: 0.5 }}>Processing...</Text>;
                    }
                    return (
                      <svg onClick={mutate} width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.4 16.5L12 13.9L14.6 16.5L16 15.1L13.4 12.5L16 9.9L14.6 8.5L12 11.1L9.4 8.5L8 9.9L10.6 12.5L8 15.1L9.4 16.5ZM7 21C6.45 21 5.979 20.804 5.587 20.412C5.195 20.02 4.99933 19.5493 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.804 20.021 18.412 20.413C18.02 20.805 17.5493 21.0007 17 21H7Z"
                          fill="black"
                        />
                      </svg>
                    );
                  }}
                </Mutation>
              )}
            </>
            // <svg
            //   data-tip="Change flag"
            //   width="16"
            //   height="16"
            //   viewBox="0 0 24 24"
            //   fill="none"
            //   xmlns="http://www.w3.org/2000/svg"
            //   style={{ cursor: 'pointer' }}
            //   onClick={() => {
            //     handleShowModalChangeStringValue({
            //       flagName: 'firstName',
            //       name: 'First Name',
            //       description: "Change the patient's first name",
            //       currentValue: (data || {}).firstName,
            //       onNull: '',
            //       validate: (val) => {
            //         if (!val) return false;
            //         return val.length > 1;
            //       },
            //     });
            //   }}
            // >
            //   <path
            //     d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
            //     fill="#F6A293"
            //   />
            // </svg>
          )}
        </Flex>
      </Flex>

      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>Must Schedule Onboarding Call:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).mustScheduleOnboardingCall !== null ? ((data || {}).mustScheduleOnboardingCall === true ? 'yes' : 'no') : 'Legacy (no)'}
            </Text>
            <svg
              data-tip="Change flag"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowChangeBooleanFlag({
                  flagName: 'mustScheduleOnboardingCall',
                  name: 'Schedule Onboarding Call',
                  description: 'Change the flag value. If set to true, the patient will see a todo entry on the dashboard to invite to schedule an onboarding call with their care manager',
                  currentValue: (data || {}).mustScheduleOnboardingCall,
                  onNull: 'Legacy (No)',
                });
              }}
            >
              <path
                d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                fill="#F6A293"
              />
            </svg>
          </Flex>
        </Flex>
      </Flex>

      <Flex flexDirection={'row'} mb={2}>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>Must Complete Baseline:</Text>
          <Text fontWeight={500}>
            {(data || {}).baselineSurvey ? ((data || {}).baselineSurvey.mustComplete === null ? 'Legacy' : ((data || {}).baselineSurvey || {}).mustComplete ? 'yes' : 'no') : 'Legacy'}
          </Text>
        </Flex>
        <Flex flexDirection={'column'} flex={1}>
          <Text fontWeight={300}>Baseline Survey Status:</Text>
          <Flex alignItems={'center'}>
            <Text fontWeight={500} mr={3}>
              {(data || {}).baselineSurvey ? ((data || {}).baselineSurvey.status === null ? 'Not Started' : (data || {}).baselineSurvey.status) : 'Not Started'}
            </Text>
            <svg
              data-tip={!(data || {}).baselineSurvey || (data || {}).baselineSurvey.status === null ? 'Request fill baseline survey' : 'Reset baseline survey status'}
              onClick={() => setShowConfirmResetBaseline(true)}
              width="16"
              height="16"
              viewBox="0 0 24 24"
              style={{ cursor: 'pointer' }}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 21C9.7 21 7.696 20.2373 5.988 18.712C4.27933 17.1873 3.3 15.2833 3.05 13H5.1C5.33333 14.7333 6.104 16.1667 7.412 17.3C8.72067 18.4333 10.25 19 12 19C13.95 19 15.604 18.3207 16.962 16.962C18.3207 15.604 19 13.95 19 12C19 10.05 18.3207 8.39567 16.962 7.037C15.604 5.679 13.95 5 12 5C10.85 5 9.775 5.26667 8.775 5.8C7.775 6.33333 6.93333 7.06667 6.25 8H9V10H3V4H5V6.35C5.85 5.28333 6.88767 4.45833 8.113 3.875C9.33767 3.29167 10.6333 3 12 3C13.25 3 14.421 3.23733 15.513 3.712C16.6043 4.18733 17.5543 4.829 18.363 5.637C19.171 6.44567 19.8127 7.39567 20.288 8.487C20.7627 9.579 21 10.75 21 12C21 13.25 20.7627 14.4207 20.288 15.512C19.8127 16.604 19.171 17.554 18.363 18.362C17.5543 19.1707 16.6043 19.8127 15.513 20.288C14.421 20.7627 13.25 21 12 21ZM14.8 16.2L11 12.4V7H13V11.6L16.2 14.8L14.8 16.2Z"
                fill="#F6A293"
              />
            </svg>
          </Flex>
        </Flex>
      </Flex>

      <SurveysView data={data} refetch={refetch} />

      <ReactTooltip place="bottom" type="warning" effect="float" />
      {/* <Flex flexDirection={'row'} mb={2}>
        <Button disabled={showConfirmResetBaseline} onClick={() => setShowConfirmResetBaseline(true)} variant="secondary">
          {!(data || {}).baselineSurvey || (data || {}).baselineSurvey.status === null ? 'Request fill baseline survey' : 'Reset baseline survey status'}
        </Button>
      </Flex> */}

      {data.athenaData && <AthenaView data={data} />}
      {data.mobileUser ? (
        <Flex flexDirection={'column'} pl={3} style={{ borderLeftColor: 'blue', borderWidth: 2 }} mt={3}>
          <Flex
            onClick={() => {
              setIsShowMobile(!isShowMobile);
            }}
          >
            <Text fontWeight={500} mb={3} flex={1}>
              Linked Mobile User
            </Text>
            <FontAwesomeIcon size="1x" color="black" icon={isShowMobile ? faChevronUp : faChevronRight} />
          </Flex>
          {!!isShowMobile && <MobileView data={data.mobileUser} refetch={refetch} toastManager={toastManager} isEditable={isEditable} />}
        </Flex>
      ) : (
        <Text>No Mobile User Associated</Text>
      )}
      <Button variant={'secondary'} onClick={() => setViewPhysicianConvoTree(data.id)}>
        View Physician Convo Tree
      </Button>
      {showPhysicianConvoTree && (
        <ConfirmModal
          isOpen={!!showPhysicianConvoTree}
          onClose={handleCloseModalViewPhysicianConvoTree}
          onBackgroundClick={handleCloseModalViewPhysicianConvoTree}
          onEscapeKeydown={(e) => {
            handleCloseModalViewPhysicianConvoTree();
          }}
          childrenManaged
        >
          <FullModalInner>
            <AdminReorderPhysicianConvo userId={showPhysicianConvoTree} close={handleCloseModalViewPhysicianConvoTree} helpscoutId={(data||{}).helpscoutId}/>
          </FullModalInner>
        </ConfirmModal>
      )}
    </Flex>
  );
};
