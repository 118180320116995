import React, { Fragment } from 'react';
import { Flex, Text, Button, Box, Card, Heading } from 'rebass';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { TextInput } from '../../components/TextInput';
import { FormField } from '../../components/FormField';
import palomaKit from '../../static/palomaKit.png';
import painFree from '../../static/painFree.png';
import resultsCanTrust from '../../static/resultsCanTrust.png';
import ongoingAdvice from '../../static/ongoingAdvice.png';
import iconBackgroundShape from '../../static/iconBackgroundShape.png';
import PlacesAutocomplete from '../../components/addressAutoComplete/PlacesAutocomplete';
import { MaskInput } from '../../components/MaskInput';
import RegionSelect from '../../components/RegionSelect';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 96px;
`;

const Biomarker = styled.div`
  border-radius: 15px;
  border: 1px solid #f1a493;
  color: #344f79;
  display: inline-block;
  margin-right: 12px;
  padding: 5px 16px;
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  z-index: 3;
  display: none;
  width: 250px;
  min-height: 5px;
  padding: 10px;
  border-radius: 30px;
  background-color: #344f79;
  bottom: 120%;
  left: -108px;
  font-weight: 300;
  color: white;
  padding: 10;
  text-align: center;
  font-size: 14px;
  ${Biomarker}:hover & {
    display: block;
  }
`;

const Image = styled.div`
  &::before {
    float: left;
    border-radius: 0px;
    background-image: url(${iconBackgroundShape});
    background-position: 0px 0px;
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    height: 110px;
    width: 100%;
  }
`;

const PersonalNote = styled.blockquote`
  padding: 15px;
  background: #eee;
  border-radius: 5px;
`;

const DesktopOnly = styled(Flex)`
  @media screen and (max-width: 52em) {
    display: none;
  }
`;

const MobileView = styled(Flex)`
  flex: 1;
  justify-content: center;

  @media screen and (min-width: 52em) {
    display: none;
  }
`;
const minDob = moment()
  .subtract(18, 'years')
  .toDate();

const maxDob = moment()
  .subtract(125, 'years')
  .toDate();

const eligibility = (value) => {
  const ineligible = ['NJ', 'NY', 'RI'];
  return !ineligible.includes(value);
};

const schema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('Required'),
  lastName: Yup.string()
    .trim()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  dob: Yup.date()
    .transform((value) => {
      return moment(value).toDate();
    })
    .min(maxDob, 'Must be younger than 125 years')
    .max(minDob, 'Must be 18 years or older')
    .required('Required'),
  street1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string()
    .required('Required')
    .test('state', 'Kits are currently not available in this state', eligibility),
  zip: Yup.string().required('Required'),
});

class ClaimingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormExtended: false,
    };
  }

  render() {
    const { isFormExtended } = this.state;
    const { claimInformation } = this.props;
    return (
      <Fragment>
        <Container flex={1} px={3}>
          <Flex flex={1} mr={[0, 0, 0]} mb={4}>
            <ScrollAnimation
              animateIn="fadeInUpFrom40"
              animateOnce={true}
              style={{
                textAlign: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <Text fontSize="38px" fontWeight={600} mb={4} textAlign="center" color="#344f79" lineHeight="44px" style={{ fontFamily: 'Playfair Display' }}>
                Claim your free thyroid test kit!
              </Text>
              <Text textAlign="center" lineHeight="24px" fontSize="18px" fontWeight={200} color="#344f79" mb="36px">
                January is thyroid awareness month and for the occasion we are giving a free kit for every purchase.{' '}
                <span style={{ color: '#f1a493', fontWeight: 500 }}>{claimInformation.purchaserName}</span> thought you would be interested in testing your thyroid. Feel out the form below and we'll
                send you your free at home thyroid test kit in no time
              </Text>

              {claimInformation.personalMessage && (
                <Flex flexDirection="column">
                  <PersonalNote>
                    <Text textAlign="left" fontSize="16px" fontWeight={300} mb="36px">
                      {claimInformation.personalMessage.split('\n').map((item, key) => {
                        return (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                    </Text>
                  </PersonalNote>
                  <Text textAlign="left" fontSize="16px" fontWeight={300} mb="36px" ml="40px">
                    -{claimInformation.purchaserName}
                  </Text>
                </Flex>
              )}

              <Card p={4}>
                <Heading textAlign="center" style={{ fontWeight: 'bold' }} mb={4}>
                  Enter Your Personal Information
                </Heading>
                <Formik
                  initialValues={{
                    email: claimInformation.refereeEmail,
                  }}
                  validationSchema={schema}
                  onSubmit={(values) => {
                    this.props.onSubmit(claimInformation.id, values);
                  }}
                  render={({ isValid, errors, touched, values, setFieldTouched, handleBlur, handleChange }) => {
                    let errStreet1;
                    if (touched.street1 && errors.street1) {
                      errStreet1 = errors.street1;
                    } else if (values && values.street1 && values.street1.indexOf('@') > -1) {
                      errStreet1 = 'incorrect street address';
                    } else {
                      errStreet1 = null;
                    }
                    return (
                      <Form>
                        <Flex flexDirection={['column', 'column', 'row']}>
                          <FormField width={[1, 1, 1 / 2]} mb={3} mr={[0, 0, 3]} error={touched.firstName && errors.firstName}>
                            <TextInput placeholder="First Name" type="text" name="firstName" />
                          </FormField>
                          <FormField width={[1, 1, 1 / 2]} mb={3} error={touched.lastName && errors.lastName}>
                            <TextInput placeholder="Last Name" type="text" name="lastName" />
                          </FormField>
                        </Flex>

                        <FormField mb={4} error={touched.dob && errors.dob}>
                          <MaskInput name="dob" placeholder="Your Date of Birth (MM/DD/YYYY)" mask="99/99/9999" value={values.dob} onChange={handleChange} onBlur={handleBlur} id="input_dob" />
                        </FormField>

                        <FormField mb={4} error={touched.email && errors.email}>
                          <TextInput placeholder="Email Address" type="email" name="email" />
                        </FormField>

                        <Text textAlign="left" mb={2}>
                          Your shipping address
                        </Text>
                        {!values.street1 && !values.city ? (
                          <FormField mb={3}>
                            <PlacesAutocomplete
                              fieldname="address"
                              onTextChanged={(val) => {
                                if (val && val.length > 0) {
                                  if (!isFormExtended) {
                                    this.setState({ isFormExtended: true });
                                  }
                                } else {
                                  if (!values.street1 && !values.street2 && !values.city && !values.zip) {
                                    this.setState({
                                      isFormExtended: false,
                                    });
                                  }
                                }
                              }}
                              onSelected={(val) => {
                                setFieldTouched('street1');
                                setFieldTouched('city');
                                setFieldTouched('zip');
                                setFieldTouched('state');
                              }}
                              street1FieldName="street1"
                              cityFieldName="city"
                              zipFieldName="zip"
                              stateFieldName="state"
                            />
                          </FormField>
                        ) : null}

                        {isFormExtended && (
                          <>
                            <FormField mb={3} error={errStreet1}>
                              <TextInput placeholder="Address" type="text" name="street1" />
                            </FormField>

                            <FormField mb={3} error={touched.street2 && errors.street2}>
                              <TextInput placeholder="Apartment, Suite, Floor (Optional)" type="text" name="street2" />
                            </FormField>

                            <Flex flexDirection={['column', 'column', 'row']}>
                              <FormField width={[1, 1, 1 / 3]} mr={[0, 0, 3]} mb={3} error={touched.city && errors.city}>
                                <TextInput placeholder="City" type="text" name="city" />
                              </FormField>

                              <FormField width={[1, 1, 1 / 3]} mr={[0, 0, 3]} mb={3} error={touched.state && errors.state}>
                                <RegionSelect name="state" value={values.state} />
                              </FormField>

                              <FormField width={[1, 1, 1 / 3]} mb={3} error={touched.zip && errors.zip}>
                                <TextInput placeholder="Zip" type="text" name="zip" />
                              </FormField>
                            </Flex>
                          </>
                        )}

                        <Flex justifyContent="center" flexDirection="column" alignItems="center">
                          <Button disabled={!isValid || !values || !values.street1 || values.street1.indexOf('@') > -1} variant="pink" width={[1, 1 / 2, 1 / 3]} type="submit">
                            Claim My Kit
                          </Button>
                        </Flex>
                      </Form>
                    );
                  }}
                />
              </Card>
            </ScrollAnimation>
          </Flex>
          <Flex flexDirection={['column', 'column', 'row']}>
            <Flex flex={1} mr={[0, 0, 4]} mb={3}>
              <DesktopOnly>
                <ScrollAnimation
                  animateIn="fadeInUpFrom40"
                  animateOnce={true}
                  style={{
                    textAlign: 'left',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'left',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <img
                      alt=""
                      src={palomaKit}
                      style={{
                        width: '90%',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                  </div>
                </ScrollAnimation>
              </DesktopOnly>
              <MobileView>
                <ScrollAnimation
                  animateIn="fadeInUpFrom40"
                  animateOnce={true}
                  style={{
                    textAlign: 'center',
                    height: '50vh',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'left',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <img
                      alt=""
                      src={palomaKit}
                      style={{
                        width: '90%',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                  </div>
                </ScrollAnimation>
              </MobileView>
            </Flex>

            <Flex width={[1, 1, 1 / 2]} flexDirection="column" mb={3}>
              <div style={{ textAlign: 'left' }}>
                <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                  <Text fontSize="38px" fontWeight={600} mb={3} color="#344f79" lineHeight="44px" style={{ fontFamily: 'Playfair Display' }}>
                    Complete Thyroid Blood Test Kit
                  </Text>
                  <Text textAlign="left" mb={3} lineHeight={1.6} fontSize={1} fontWeight={600} style={{ textTransform: 'uppercase' }}>
                    At-home collection. Meaningful insights. Personalized plan.
                  </Text>

                  <Text textAlign="left" lineHeight="24px" fontSize="18px" fontWeight={200} color="#344f79" mb={3}>
                    This at-home test can help you understand how your thyroid is working and if there may be a need for a further evaluation of your thyroid function. Expect your results in one week.
                  </Text>

                  <Text textAlign="left" lineHeight={1.6} fontSize={3} fontWeight={600} mb={3}>
                    $99
                  </Text>
                  <Text textAlign="left" mb={3} lineHeight={1.6} fontSize={1} fontWeight={200}>
                    Test takers must be 18+ and reside in the US
                  </Text>
                  <Text textAlign="left" mb={3} lineHeight={1.6} fontSize={1} fontWeight={600}>
                    Biomarkers Included
                  </Text>

                  <div>
                    <Biomarker>
                      <Text textAlign="left" lineHeight={1.6} fontSize={1} fontWeight={600}>
                        TSH
                      </Text>
                      <Tooltip className="tooltip">
                        <span style={{ fontWeight: 600 }}>TSH</span> is a hormone produce by your brain that stimulates the thyroid gland
                      </Tooltip>
                    </Biomarker>
                    <Biomarker>
                      <Text textAlign="left" lineHeight={1.6} fontSize={1} fontWeight={600}>
                        T4
                      </Text>
                      <Tooltip className="tooltip">
                        <span style={{ fontWeight: 600 }}>Thyroxine</span> (T4) is the main hormone secreted into the bloodstream by the thyroid gland
                      </Tooltip>
                    </Biomarker>
                    <Biomarker>
                      <Text textAlign="left" lineHeight={1.6} fontSize={1} fontWeight={600}>
                        T3
                      </Text>
                      <Tooltip className="tooltip">
                        <span style={{ fontWeight: 600 }}>Triiodothyronine</span> , known as&nbsp;
                        <span style={{ fontWeight: 600 }}>T3</span> is another hormone produce by your thyroid. Most of the&nbsp;
                        <span style={{ fontWeight: 600 }}>T3</span> in your body binds to protein and regulates your body's temperature, metabolism, and heart rate.
                      </Tooltip>
                    </Biomarker>
                    <Biomarker>
                      <Text textAlign="left" lineHeight={1.6} fontSize={1} fontWeight={600}>
                        TPO
                      </Text>
                      <Tooltip className="tooltip">
                        The presence of&nbsp;
                        <span style={{ fontWeight: 600 }}>TPO antibodies</span> in your blood suggests that the cause of thyroid disease is an autoimmune disorder, such as Hashimoto's disease.
                      </Tooltip>
                    </Biomarker>
                  </div>
                </ScrollAnimation>
              </div>
            </Flex>
          </Flex>

          <Flex flex={1} textAlign="center" style={{ marginTop: '200px', marginBottom: '72px' }}>
            <ScrollAnimation animateIn="fadeInUp" style={{ margin: '0 auto' }} animateOnce={true}>
              <Text fontSize="38px" fontWeight={600} textAlign="center" mb={3} color="#344f79" lineHeight="44px" style={{ fontFamily: 'Playfair Display' }}>
                The Benefits Of Our Tests
              </Text>
            </ScrollAnimation>
          </Flex>
          <Flex flexDirection={['column']}>
            <Flex flexDirection={['column', 'column', 'row']}>
              <Flex flex={1} mr={[0, 0, 4]} mb={3}>
                <div style={{ textAlign: 'left', padding: '0 12px' }}>
                  <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                    <Flex mb={3} justifyContent="left">
                      <Image>
                        <ScrollAnimation animateIn="fadeIn" delay="0.5" animateOnce={true}>
                          <img src={painFree} alt="" height="100px" />
                        </ScrollAnimation>
                      </Image>
                    </Flex>
                    <Text textAlign="left" mb={3} lineHeight={1.6} fontSize="22px" fontWeight={600} color="#344f79">
                      Pain-Free Home Testing
                    </Text>

                    <Text textAlign="left" lineHeight={1.6} fontSize="18px" fontWeight={300} color="#344f79">
                      - There's no needles
                      <br />
                      <br />- Finger-prick test
                      <br />
                      <br />- Only takes a few minutes
                    </Text>
                  </ScrollAnimation>
                </div>
              </Flex>

              <Flex width={[1, 1, 1 / 3]} flexDirection="column" mb={3}>
                <div style={{ textAlign: 'left', padding: '0 12px' }}>
                  <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                    <Flex mb={3} justifyContent="left">
                      <Image>
                        <img src={resultsCanTrust} alt="" height="100px" />
                      </Image>
                    </Flex>
                    <Text textAlign="left" mb={3} lineHeight={1.6} fontSize="22px" fontWeight={600} color="#344f79">
                      Results You Can Trust
                    </Text>

                    <Text textAlign="left" lineHeight={1.6} fontSize="18px" fontWeight={300} color="#344f79">
                      - Comprehensive results
                      <br />
                      <br />- Analyzed by CLIA CERTIFIED Labs ‍<br />
                      <br />- Reviewed by US physicians
                    </Text>
                  </ScrollAnimation>
                </div>
              </Flex>

              <Flex width={[1, 1, 1 / 3]} flexDirection="column" mb={3}>
                <div style={{ textAlign: 'left', padding: '0 12px' }}>
                  <ScrollAnimation animateIn="fadeInUpFrom20" animateOnce={true}>
                    <Flex mb={3} justifyContent="left">
                      <Image>
                        <img src={ongoingAdvice} alt="" height="100px" />
                      </Image>
                    </Flex>
                    <Text textAlign="left" mb={3} lineHeight={1.6} fontSize="22px" fontWeight={600} color="#344f79">
                      Ongoing Advice
                    </Text>

                    <Text textAlign="left" lineHeight={1.6} fontSize="18px" fontWeight={300} color="#344f79">
                      - Personal guidance based on your test results
                      <br />
                      <br />- Action-oriented recommendations
                    </Text>
                  </ScrollAnimation>
                </div>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Fragment>
    );
  }
}

export default ClaimingForm;
