import React from 'react';
import { Flex, Heading, Text } from 'rebass';
import styled from 'styled-components';

import ItemCollection from '../../components/icons/ItemCollection';
import { DateMobile } from './LabResults2';

const Icon = styled(Flex)`
  display: none;
  @media screen and (min-width: 52em) {
    display: block;
  }
`;
export const LabStep5 = ({currentStep, date}) => {
  return (
    <Flex flexDirection={['column', 'row']} flex={1}>
      <Flex flexDirection="column" flex={1}>
        <DateMobile mb={3}>{date}</DateMobile>
        <Text mb={3} fontWeight={600}>
          Your lab results are available
        </Text>
        <Text lineHeight={1.6} fontSize={'14px'}>
          The wait is over. Please review your results below.
          <br /> <br />
          Your results contain your levels of each marker tested, providing you insight into how they compare to the appropriate standards. Easily download your results so you can share them directly
          with your physician. If you book a visit with one of our clinicians, your results will be automatically shared with her/him.
        </Text>
      </Flex>
      <Icon mr={[0, 4]} mb={[3, 0]} alignItems="flex-start" justifyContent="center">
        <ItemCollection width={90} />
      </Icon>
    </Flex>
  );
};
