import React from 'react';
import { Flex, Box, Text} from 'rebass';

const TopCloud = ({ offsetY }) => {
  let style;
  switch (offsetY) {
    case 0:
      style = {
        position: 'absolute',
        top: 62,
        left: -27,
        overflow: 'hidden',
      };
      break;
    case 1:
      style = {
        position: 'absolute',
        top: 79,
        left: 69,
        overflow: 'hidden',
      };
      break;
    case 2:
      style = {
        position: 'absolute',
        top: 86,
        left: 104,
        overflow: 'hidden',
      };
      break;
    case 3:
      style = {
        position: 'absolute',
        top: 154,
        left: 161,
        overflow: 'hidden',
      };
      break;
    default:
      style = {
        position: 'absolute',
        top: 62,
        left: -27,
        overflow: 'hidden',
      };
      break;
  }
  return (
    <svg
      width="158"
      height="158"
      viewBox="0 0 158 158"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M135.41 82.2234C133.72 82.2234 132.079 82.4325 130.509 82.8199C130.868 81.2619 131.049 79.6683 131.051 78.0695C131.051 66.3775 121.572 56.8992 109.88 56.8992C105.612 56.8933 101.443 58.1837 97.9245 60.5995C93.9531 45.2983 80.0493 34.0004 63.506 34.0004C43.8683 34.0004 27.9485 49.9202 27.9485 69.5579C13.1841 69.5579 1.21484 81.5269 1.21484 96.2916C1.21484 111.056 13.1838 123.025 27.9485 123.025H135.41C146.677 123.025 155.811 113.891 155.811 102.624C155.811 91.3572 146.677 82.2234 135.41 82.2234Z"
        fill="#2D2CA8"
      />
    </svg>
  );
};

const BottomCloud = ({ offsetY }) => {
  let style;
  switch (offsetY) {
    case 0:
      style = {
        position: 'absolute',
        bottom: 0,
        left: 119,
        overflow: 'hidden',
      };
      break;
    case 1:
      style = {
        position: 'absolute',
        bottom: 0,
        left: 56,
        overflow: 'hidden',
      };
      break;
    case 2:
      style = {
        position: 'absolute',
        bottom: 0,
        left: -24,
        overflow: 'hidden',
      };
      break;
    case 3:
      style = {
        position: 'absolute',
        bottom: 0,
        left: -194,
        overflow: 'hidden',
      };
      break;
    default:
      style = {
        position: 'absolute',
        bottom: 0,
        left: 119,
        overflow: 'hidden',
      };
      break;
  }
  return (
    <svg
      width="295"
      height="219"
      viewBox="0 0 295 219"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M234.337 139.68C223.982 101.383 191.668 74.5347 154.291 74.5347C128.228 74.5347 103.95 87.674 88.1701 109.988C83.815 108.986 79.361 108.477 74.8921 108.471C39.8195 108.471 11.2812 139.562 11.2812 177.791C11.2812 191.499 14.9717 204.777 21.9366 216.194C23.5739 218.866 26.3322 220.468 29.2822 220.468H267.76C270.554 220.468 273.176 219.037 274.849 216.589C280.645 208.1 283.735 198.055 283.711 187.776C283.714 159.285 260.66 136.231 234.337 139.68Z"
        fill="#212075"
      />
    </svg>
  );
};
const SleepMeditationAudioSim = ({ values, original, calculateY }) => {
  return (
    <Box
      style={{
        backgroundColor: '#ccc',
        padding: '20px',
        width: '250px',
        height: '100%',
      }}
    >
      <Flex
        flexDirection="column"
        style={{
          height: '400px',
          width: '100%',
          position: 'relative',
          backgroundColor: '#292883',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <TopCloud offsetY={values.hormoneY ? values.hormoneY.value : 0} />
            <BottomCloud
              offsetY={values.hormoneY ? values.hormoneY.value : 0}
            />
          </div>
        </Box>
        <Flex
          flex="1"
          style={{
            padding: '6px',
            textAlign: 'center',
            zIndex: 9,
            color: 'white',
            maxHeight: '400px',
            overflowY: 'auto',
          }}
        >
          <Flex flexDirection="column" flex={1}>
            <Box flex={1}>
              <Flex flexDirection="column">
                <Flex
                  style={{ height: '159px' }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text
                    style={{
                      fontWeight: 500,
                      color: values.titleColor,
                    }}
                    mb={3}
                  >
                    {values.title}
                  </Text>
                </Flex>
                <Flex flexDirection="column" style={{ height: '241px' }} alignItems="center">
                  <svg
                    width="81"
                    height="92"
                    viewBox="0 0 375 628"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M219.288 77.9127C274.005 92.9729 322.232 118.114 363.413 156.763C375.539 168.397 388.246 179.412 401.485 189.768C452.301 228.617 463.916 281.794 454.949 340.363C445.454 402.374 419.214 457.583 372.692 500.73C328.437 541.769 273.9 562.163 214.008 559.468C188.885 558.337 162.877 545.078 139.88 532.546C88.5993 504.608 44.9876 466.556 4.48036 424.373C-34.735 383.519 -45.7641 335.221 -39.5597 282.003C-33.2402 227.755 -12.6327 178 17.7307 133.036C47.2348 89.3465 91.343 71.164 143.275 72.0393C168.632 72.4596 193.953 75.8582 219.288 77.9127Z"
                      fill="#FEFCFB"
                    />
                  </svg>

                  <Text
                    style={{
                      fontWeight: 300,
                      color: 'white',
                      margin: '12px',
                    }}
                  >
                    00:15
                  </Text>
                  <Text
                    style={{
                      fontWeight: 300,
                      color: 'white',
                      padding: '2px 12px',
                      borderRadius: '24px',
                      borderColor: 'white',
                      border: '1px solid white'
                    }}
                  >
                    15:15
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default SleepMeditationAudioSim;
