import React, { useEffect } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Button, Flex, Heading, Text } from 'rebass';
import fleche from '../../../static/fleche.png';
import Communicator from './Communicator';
import britCare from '../../../static/Brittany.jpeg';
import withSession from '../../../lib/withSession';
import withAnalytics from '../../../lib/withAnalytics';

const Icon = styled(Flex)`
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const CommunicatorFlex = styled(Flex)`
  width: 100%;
  flex-direction: column;
  flex: 1;
  @media screen and (max-width: 52em) {
    height: 332px;
  }
`;

const Wrap = styled(Flex)`
  flex: 1;
  /* background: white; */
  height: calc(100vh - 114px);
  max-height: calc(100vh - 114px);
  @media screen and (max-width: 1250px) {
    display: ${(props) => (props.isLanding ? 'none' : '')};
    max-width: 100vw;
  }
  @media screen and (max-width: 52em) {
    height: 100%;
    max-height: 100%;
  }
`;
const LargeScreenOnly = styled(Flex)`
  display: flex;
  max-width: 300px;
  flex-direction: column;
  @media screen and (max-width: 1250px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 37px;
  height: 37px;
  overflow: hidden;
  border-radius: 50%;
  margin-left: 12px;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CommunicatorWrapper = ({ isLanding = false, noBottomPadding = false, mobileApp = false, session: { dedicatedCareManager, email, firstName, lastName }, analytics }) => {
  const name = encodeURI(`${firstName} ${lastName}`);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      var tags = document.getElementsByTagName('script');
      try {
        window.removeEventListener('message', this.windowMessageReceived);
      } catch (error) {}
      for (var i = tags.length; i >= 0; i--) {
        if (tags[i] && tags[i].getAttribute('src') != null && tags[i].getAttribute('src').indexOf('https://assets.calendly.com/assets/external/widget.js') > -1)
          tags[i].parentNode.removeChild(tags[i]); //remove element by calling parentNode.removeChild()
      }
    };
  }, []);

  return (
    <Wrap id="wrp" isLanding={isLanding} style={{ position: isLanding ? 'fixed' : '', right: isLanding ? 0 : '', margin: !!mobileApp ? '0 -6px' : '' }}>
      <Flex flexDirection={'row'}>
        <Flex p={[0, '16px 0', 4]} style={{ background: 'white' }}>
          <Flex flexDirection="column" style={{ height: '100%', maxWidth: isMobile ? '' : window.matchMedia('(min-width: 1250px)').matches ? '388px' : '100%' }}>
            <CommunicatorFlex id="t1">
              {!mobileApp ? (
                <Flex flexDirection="row" alignItems={'center'}>
                  <>
                    {!isMobile && (
                      <Icon mr={[0, 4, 4]}>
                        <img src={fleche} height={50} width={50} />
                      </Icon>
                    )}
                    <Heading style={{ padding: '6px' }}>A Question For Your Care Team?</Heading>
                  </>
                </Flex>
              ) : (
                <Flex flexDirection="row" alignItems={'center'} style={{ padding: '6px 8px' }}>
                  <svg
                    onClick={() => {
                      if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage('closeMobilePane');
                      }
                    }}
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17.2969 5.90625L9.70312 13.5L17.2969 21.0938" stroke="#4E658A" stroke-width="4.83582" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  <ImageWrapper>
                    <Img src={dedicatedCareManager && dedicatedCareManager.thumbnail ? dedicatedCareManager.thumbnail : britCare} />
                  </ImageWrapper>

                  <Flex flexDirection={'column'} ml={3}>
                    <Text style={{ color: '#7992B3', fontSize: '18px' }}>{dedicatedCareManager ? dedicatedCareManager.firstName : 'Brittany'}</Text>
                    <Text style={{ color: '#7992B3', fontSize: '14px', fontWeight: 300 }}>Care Manager</Text>
                  </Flex>
                </Flex>
              )}
              <Communicator noBottomPadding={noBottomPadding} mobileApp={mobileApp} />
            </CommunicatorFlex>
          </Flex>
        </Flex>
        {!isMobile && (
          <LargeScreenOnly p={[0, 3, 4]}>
            <Text fontSize={'22px'} fontWeight="600">
              Your Care Manager
            </Text>
            <Flex mt={4} flexDirection={'row'}>
              <ImageWrapper>
                <Img src={dedicatedCareManager && dedicatedCareManager.thumbnail ? dedicatedCareManager.thumbnail : britCare} />
              </ImageWrapper>
              <Text style={{ fontSize: '22px' }} ml={3}>
                {dedicatedCareManager ? `${dedicatedCareManager.fullName}` : 'Brittany Curran'}
              </Text>
            </Flex>
            <Text fontWeight={'300'} fontSize="14px" mt={4}>
              {dedicatedCareManager
                ? `${dedicatedCareManager.bio}`
                : "Brittany is an ex-social worker who is so excited to be able to support patients through their thyroid journey. Thyroid conditions run in her family so this work is close to her heart. Can't wait to chat!"}
            </Text>
            <Text fontWeight={'300'} fontSize="14px" mt={4} mb={3}>
              You can also schedule a 15’ call with your care manager:
            </Text>
            <Button
              variant="pink"
              style={{ alignSelf: 'center' }}
              onClick={() => {
                window.Calendly.initPopupWidget({
                  url:
                    dedicatedCareManager && dedicatedCareManager.calendlyLink
                      ? `${dedicatedCareManager.calendlyLink}?hide_event_type_details=1&name=${name}&email=${email}&text_color=344f79&primary_color=f9a394`
                      : `https://calendly.com/paloma-onboarding/paloma-membership-onboarding?hide_event_type_details=1&name=${name}&email=${email}&text_color=344f79&primary_color=f9a394`,
                });
                analytics.track('Book Care Manager call');
              }}
            >
              <Flex flexDirection={'row'} justifyContent="center">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path
                    d="M11 9.625C11.1823 9.625 11.3572 9.69743 11.4861 9.82636C11.6151 9.9553 11.6875 10.1302 11.6875 10.3125V12.375H13.75C13.9323 12.375 14.1072 12.4474 14.2361 12.5764C14.3651 12.7053 14.4375 12.8802 14.4375 13.0625C14.4375 13.2448 14.3651 13.4197 14.2361 13.5486C14.1072 13.6776 13.9323 13.75 13.75 13.75H11.6875V15.8125C11.6875 15.9948 11.6151 16.1697 11.4861 16.2986C11.3572 16.4276 11.1823 16.5 11 16.5C10.8177 16.5 10.6428 16.4276 10.5139 16.2986C10.3849 16.1697 10.3125 15.9948 10.3125 15.8125V13.75H8.25C8.06766 13.75 7.8928 13.6776 7.76386 13.5486C7.63493 13.4197 7.5625 13.2448 7.5625 13.0625C7.5625 12.8802 7.63493 12.7053 7.76386 12.5764C7.8928 12.4474 8.06766 12.375 8.25 12.375H10.3125V10.3125C10.3125 10.1302 10.3849 9.9553 10.5139 9.82636C10.6428 9.69743 10.8177 9.625 11 9.625Z"
                    fill="#354E79"
                  />
                  <path
                    d="M4.8125 0C4.99484 0 5.1697 0.0724328 5.29864 0.201364C5.42757 0.330295 5.5 0.505164 5.5 0.6875V1.375H16.5V0.6875C16.5 0.505164 16.5724 0.330295 16.7014 0.201364C16.8303 0.0724328 17.0052 0 17.1875 0C17.3698 0 17.5447 0.0724328 17.6736 0.201364C17.8026 0.330295 17.875 0.505164 17.875 0.6875V1.375H19.25C19.9793 1.375 20.6788 1.66473 21.1945 2.18046C21.7103 2.69618 22 3.39565 22 4.125V19.25C22 19.9793 21.7103 20.6788 21.1945 21.1945C20.6788 21.7103 19.9793 22 19.25 22H2.75C2.02065 22 1.32118 21.7103 0.805456 21.1945C0.289731 20.6788 0 19.9793 0 19.25V4.125C0 3.39565 0.289731 2.69618 0.805456 2.18046C1.32118 1.66473 2.02065 1.375 2.75 1.375H4.125V0.6875C4.125 0.505164 4.19743 0.330295 4.32636 0.201364C4.4553 0.0724328 4.63016 0 4.8125 0V0ZM1.375 5.5V19.25C1.375 19.6147 1.51987 19.9644 1.77773 20.2223C2.03559 20.4801 2.38533 20.625 2.75 20.625H19.25C19.6147 20.625 19.9644 20.4801 20.2223 20.2223C20.4801 19.9644 20.625 19.6147 20.625 19.25V5.5H1.375Z"
                    fill="#354E79"
                  />
                </svg>
                <Text style={{ marginLeft: 16, fontSize: 16, fontWeight: 500, color: '#344f79' }}>Schedule</Text>
              </Flex>
            </Button>
          </LargeScreenOnly>
        )}
      </Flex>
    </Wrap>
  );
};

export default withAnalytics(withSession(CommunicatorWrapper));
