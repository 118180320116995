export const B2BExpiresAfterPeriods = [
  {
    label: 'year',
    value: 'y',
  },
  {
    label: 'month',
    value: 'm',
  },
  {
    label: 'day',
    value: 'd',
  },
];

export const convertExpiresAfterToFriendlyString = (expiresAfter) => {
  if (!expiresAfter || expiresAfter.indexOf('-') < 0) return '';

  const sp = expiresAfter.split('-');
  const duration = sp[0];
  const period = sp[1];
  const formattedPeriod = B2BExpiresAfterPeriods.find((x) => x.value === (period || '').toLowerCase()).label;

  return `${duration} ${formattedPeriod}`;
};

export const convertExpiresAfterObjectToFriendlyString = (duration, period) => {
  if (!duration || !period) return null;

  return `${duration}-${period}`;
};

export const convertFriendlyObjectToExpiresAfter = (expiresAfter) => {
  if (!expiresAfter || expiresAfter.indexOf('-') < 0) return {
    duration: null,
    period: null
  };

  const sp = expiresAfter.split('-');
  const duration = sp[0];
  const period = sp[1];

  return {
    duration,
    period
  };
};

export const convertFriendlyStringToExpiresAfter = (duration, period) => {
  let finalPeriod = period.length > 1 ? B2BExpiresAfterPeriods.find((x) => x.label === period).value : period;

  return {
    duration,
    period: finalPeriod
  };
};
