import React, { Component, Fragment } from 'react';
import { Flex, Box, Button, Text, Heading, Card } from 'rebass';
import { compose, withApollo } from 'react-apollo';
import { withWizard } from 'react-albus';
import { withToastManager } from 'react-toast-notifications';

import LoadingScreen from '../../components/LoadingScreen';

import { CHARTS, CANCEL_APPT, APPOINTMENTS, CURRENT_USER } from '../../graphql';
import moment from 'moment';

class Disqualified extends Component {
  state = {
    loading: true,
  };
  async componentDidMount() {
    if (!this.props.appointments || this.props.appointments.length < 1) {
      this.setState({ loading: false });
      return;
    }
    
    const hypoFuture = this.props.appointments.filter((item) => {
      if (
        item.status &&
        item.status === 'f' &&
        item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_APPOINTMENT_TYPE_ID &&
        item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_ASYNC_MEMBER_APPOINTMENT_TYPE_ID &&
        item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_MENO15_APPOINTMENT_TYPE_ID &&
        item.appointmentTypeId !== window._env_.REACT_APP_ATHENA_MENO30_APPOINTMENT_TYPE_ID
      ) {
        const dt = moment(`${item.date} ${item.startTime}`, 'MM/DD/YYYY HH:mm');
        if (dt.isAfter(moment())) return true;
        return false;
      }
      return false;
    });
    if (!hypoFuture || hypoFuture.length < 1) {
      this.setState({ loading: false });
      return;
    }

    try {
      await this.props.client.mutate({
        mutation: CANCEL_APPT,
        variables: {
          id: hypoFuture[0].id,
          bypassReasonEmail: true,
          byDisqualification: true
        },
        refetchQueries: [{ query: APPOINTMENTS }, { query: CURRENT_USER }],
        awaitRefetchQueries: true,
      });

      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      console.log('Cancel Appt Err', err);
    }
  }

  handleSubmit = async (skip) => {
    const { client, toastManager, data } = this.props;
    this.setState({ loading: true });

    try {
      if (!this.props.userData || !this.props.userData.length) {
        return this.handleRoute(skip);
      }

      await client.mutate({
        mutation: CHARTS,
        variables: {
          data: this.props.userData,
          intakeStatus: data.status,
        },
      });

      // await refetch();
      // await this.props.resetState();
      this.handleRoute(skip);
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
      toastManager.add('Error submitting intake form', { appearance: 'error' });
    }
  };

  handleRoute = (skip) => {
    const { data, wizard } = this.props;

    if (data.status === 'completed') {
      wizard.history.push(data.link);
    } else {
      skip ? wizard.push(skip) : wizard.next();
    }
  };

  render() {
    const { loading } = this.state;

    if (loading) {
      return <LoadingScreen showLogo={false} loading={true} />;
    }

    return (
      <Fragment>
        <Flex justifyContent="center" style={{ zIndex: 1 }}>
          <Flex width="600px" p={3}>
            <Card>
              <Flex flexDirection="column">
                <Box p={3} alignSelf="center">
                  <Text lineHeight={1.6} textAlign="center" mt={3}>
                    We are very sorry...
                  </Text>
                </Box>
                <Box p={2} pt={4}>
                  <Text fontSize={'20px'} fontWeight={400} color="#344f77" textAlign="center">
                    Treating Patients With This Ailment Is Not Supported By Telehealth.
                  </Text>
                </Box>

                <Box p={3} alignSelf="center">
                  <Text lineHeight={1.6} textAlign="center">
                    Your Paloma appointments are now cancelled.
                  </Text>
                </Box>

                <Box p={3} alignSelf="center">
                  <Text lineHeight={1.6} textAlign="center">
                    We recommend you to visit your PCP or endocrinologist in person. You can still use Paloma Health at-home kits.
                  </Text>
                </Box>
              </Flex>

              <Flex alignItems="center" flexDirection="column" p={3}>
                <Button type="button" variant="pink" width={[1, 1 / 2]} mb={3} onClick={() => this.props.history.push('/')}>
                  My Dashboard
                </Button>
              </Flex>
            </Card>
          </Flex>
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withWizard,
  withToastManager
)(Disqualified);
