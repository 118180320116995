import React from 'react';
import { Flex, Text, Image } from 'rebass';
import { GraphPink } from '../icons/GraphPink';
import { BottomLeftHormone, HugeInnerHormone, InnerLeftHormone, InnerRightHormone, SmallHormone } from '../icons/InnerRightHormone';
import Odometer from '../components/RollingNumbers';
import DocWithSample from '../../../static/DocWithSampleLarge.png';
import { ExercisesIcon } from '../icons/ExercisesIcon';
import styled from 'styled-components';

const Img = styled(Image)`
  width: 50%;
  margin-top: 80px;
  @media screen and (max-width: 52em) {
    /* width: 0; */
    margin-top:0;
  }

`
const CText = styled(Text)`
  font-size: 50px;
  line-height: 90px;

  @media screen and (max-width: 52em) {
    font-size: 32px;
  line-height: 50px;
  }
`

export const TestCountBackground = () => {
  return (
    <Flex style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <HugeInnerHormone style={{ position: 'absolute', top: '150px', left: '50%', transform: 'translateX(-50%)' }} />
      <InnerLeftHormone style={{ position: 'absolute', bottom: 120, left: 0 }} />
      <SmallHormone style={{ position: 'absolute', bottom: 200, right: 0 }} />
      <BottomLeftHormone />
    </Flex>
  );
};

export const TestCount = ({ testCount = 0 }) => {
  return (
    <Flex style={{ alignItems: 'center', justifyContent: 'flex-start', height: '100%', flexDirection: 'column', zIndex: 1001 }}>
      <Img src={DocWithSample} />
      <CText style={{  marginTop: '42px', fontWeight: 700, textAlign: 'center', color: 'white' }}>
        You tested your thyroid levels{' '}
        <span>
          <Odometer delay={0} startNumber={0} to={testCount} style={{ color: '#F8A294', fontSize: window.matchMedia('(min-width: 52em)').matches?70:42, fontWeight: 'bold', width: '100%' }} />
        </span>{' '}
        times this year
      </CText>
    </Flex>
  );
};
