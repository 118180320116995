import React, { useState } from 'react';
import { Elements } from 'react-stripe-elements';
import withSession from '../../lib/withSession';
import { useConstructor } from '../../utils/hooks/lifecycle';
import RegisterKitAdditionalBiomarkersComponent from './components/RegisterKitAdditionalBiomarkersComponent';
import { withRouter } from 'react-router';

const RegisterKitAdditionalBiomarkers = ({
  client,
  session,
  history,
  ...props
}) => {
  const [serial, setSerial] = useState('');
  const [mustPayOnActivationKit, setMustPayOnActivationKit] = useState(false);
  const [loadedSerial, setLoadedSerial] = useState(false);

  useConstructor(() => {
    let serialLoc;
    if (props.location && props.location.state) {
      serialLoc = props.location.state.serial;
      setMustPayOnActivationKit(!!props.location.state.payOnActivate);
    }

    if (!serialLoc) {
      history.push('/register-kit');
    }
    setSerial(serialLoc);
    setLoadedSerial(true);
  });

  if (!serial || !loadedSerial) {
    return <div />;
  }

  return (
    // <Container>
    <Elements>
      <RegisterKitAdditionalBiomarkersComponent
        {...props}
        serial={serial}
        mustPayOnActivationKit={mustPayOnActivationKit}
      />
    </Elements>
    // </Container>
  );
};

export default withRouter(withSession(RegisterKitAdditionalBiomarkers));
