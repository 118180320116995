import React from 'react';
import { Card, Text } from 'rebass';
import PropTypes from 'prop-types';

const OoopsError = ({ message }) => {
  return (
    <Card p={4}>
      <Text
        fontSize="38px"
        fontWeight={600}
        mb={4}
        textAlign="center"
        color="#344f79"
        lineHeight="44px"
        style={{ fontFamily: 'Playfair Display' }}
      >
        Oooops!
      </Text>
      <Text
        textAlign="center"
        lineHeight="24px"
        fontSize="18px"
        fontWeight={200}
        color="#344f79"
        mb="36px"
      >
        {message}
      </Text>
    </Card>
  );
};

OoopsError.propTypes = {
  message: PropTypes.string,
};

OoopsError.defaultProps = {
  message: 'An Error Occured',
};

export default OoopsError;
