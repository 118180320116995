import React from 'react';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import { Query, Mutation } from 'react-apollo';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import styled from 'styled-components';
import {
  ADMIN_GET_MOTIVATIONAL_QUOTES,
  ADMIN_ADD_MOTIVATIONAL_QUOTES,
} from '../graphql';
import ConfirmModal from '../components/ConfirmModal';
import { Input } from '../components/TextInput';

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 6px 0 6px;
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const categoriesColumns = [
  {
    Header: 'text',
    accessor: 'text',
  },
  {
    Header: 'author',
    accessor: 'author',
  },
];

class CategoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      author: '',
    };
  }

  validate = () => {
    const { existingQuotes } = this.props;
    const { text } = this.state;
    if (!text || !text.trim()) return false;
    if (
      existingQuotes.filter(
        x => x.text.toUpperCase() === (text || '').trim().toUpperCase()
      ).length > 0
    ) {
      return false;
    }

    return true;
  };

  render() {
    const { onClose, onSave } = this.props;
    const { text, author } = this.state;
    const isValid = this.validate();
    return (
      <Mutation
        mutation={ADMIN_ADD_MOTIVATIONAL_QUOTES}
        refetchQueries={[{ query: ADMIN_GET_MOTIVATIONAL_QUOTES }]}
        variables={{
          text: text.trim(),
          author: (author || '').trim(),
        }}
        onCompleted={() => {
          this.setState({
            text: '',
            author: '',
          });
          onSave();
        }}
      >
        {(createCategory, { loading }) => (
          <Flex flexDirection="column" width="400px">
            <Text>Quote</Text>
            <Input
              placeholder="Quote*"
              value={text}
              onChange={val => {
                this.setState({
                  text: val.target.value,
                });
              }}
            />
            <Text mt={2}>Author</Text>
            <Input
              placeholder="Author's name"
              value={author}
              onChange={val => {
                this.setState({
                  author: val.target.value,
                });
              }}
            />

            <Flex
              mt={4}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                style={{
                  background: 'white',
                  color: '#344f79',
                  border: '1px solid #344f79',
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid}
                style={{ background: '#344f79' }}
                onClick={() => {
                  createCategory();
                }}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        )}
      </Mutation>
    );
  }
}

class MobileMotivationQuotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNewQuoteVisible: false,
    };
  }

  showAddNewQuote = () => {
    this.setState({ isAddNewQuoteVisible: true });
  };

  cancelAddNewQuote = () => {
    this.setState({ isAddNewQuoteVisible: false });
  };

  render() {
    const { isAddNewQuoteVisible } = this.state;

    return (
      <Container>
        <Heading>MOTIVATIONAL QUOTES</Heading>
        <Flex
          flexDirection="row"
          style={{ justifyContent: 'space-between' }}
          mt={4}
          mb={3}
        >
          <Heading fontSize="20px">quotes</Heading>
          <Button
            style={{ background: '#344f79' }}
            onClick={this.showAddNewQuote}
          >
            Add Quote
          </Button>
        </Flex>
        <Query query={ADMIN_GET_MOTIVATIONAL_QUOTES}>
          {({ data, loading, error }) => {
            if (loading || error) return <div />;
            console.log('ADMIN_GET_MOTIVATIONAL_QUOTES', data);
            return (
              <Box>
                <ReactTable
                  data={data.getMotivationalQuotes}
                  columns={categoriesColumns}
                  defaultPageSize={
                    data.getMotivationalQuotes &&
                    data.getMotivationalQuotes.length > 3
                      ? Math.min(data.getMotivationalQuotes.length, 25)
                      : 3
                  }
                  showPagination={
                    data.getMotivationalQuotes &&
                    data.getMotivationalQuotes.length > 24
                  }
                />

                <ConfirmModal
                  childrenManaged
                  isOpen={isAddNewQuoteVisible}
                  confirmLabel="create"
                  onClose={this.cancelAddNewQuote}
                  onBackgroundClick={this.cancelAddNewQuote}
                  onEscapeKeydown={this.cancelAddNewQuote}
                  onConfirm={this.cancelAddNewQuote}
                >
                  <ModalInner>
                    <Heading fontSize="20px">Create Category</Heading>
                    <CategoryModal
                      existingQuotes={data.getMotivationalQuotes}
                      onClose={this.cancelAddNewQuote}
                      onSave={this.cancelAddNewQuote}
                    />
                  </ModalInner>
                </ConfirmModal>
              </Box>
            );
          }}
        </Query>
      </Container>
    );
  }
}

export default MobileMotivationQuotes;
