import React, { Component, useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { MaskInput } from '../components/MaskInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { withApollo } from 'react-apollo';
import { GET_REQUISITION_FORM_DETAILS, REPORT_LAB_ISSUE } from '../graphql';
import { withToastManager } from 'react-toast-notifications';
import Select from 'react-select';
import TextArea, { TextAreaNoForm } from '../components/TextArea';
import ConfirmModal from '../components/ConfirmModal';
import { sleep } from '../utils/sleep';
import gql from 'graphql-tag';
import withSession from '../lib/withSession';
import UploadLabReportPicture from './labScan/takePicture';

const SEND_IHD_CSV = gql`
  mutation sendIhdCsv($finalRows: String!) {
    sendIhdCsv(finalRows: $finalRows) {
      ok
    }
  }
`;

const eligibility = async (value) => {
  await Yup.string()
    .required('Required')
    .validate(value);

  return value.replace(/_/g, '').replace(/-/g, '').length === 9;
};

const schema = Yup.object().shape({
  serial: Yup.string()
    .required('Required')
    .test('serial', 'Incorrect serial number', eligibility),
});

const Container = styled(Box)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 48px;
`;

const ModalInner = styled(Box)`
  max-width: 600px;
  width: 100%;
`;

const IssueTypes = [
  {
    label: 'QNS',
    value: 'QNS',
  },
  {
    label: 'Registered with Different Name',
    value: 'Registed with Different Name',
  },
  {
    label: 'Missing Name / Date',
    value: 'Missing Name/Date',
  },
  {
    label: 'Partial Results',
    value: 'Partial Results',
  },
  {
    label: 'Expired Sample',
    value: 'Expired Sample',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const AddError = ({ serial, onCancel, client, toastManager, onClose, session }) => {
  const { email } = session;
  const [selected, setSelected] = useState();
  const [pic, setPic] = useState(null);
  const [otherDetails, setOtherDetails] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPictureConfirming, setIsPictureConfirming] = useState(false);
  const handleSubmit = async () => {
    setIsSubmitting(true);
    const { data } = await client.mutate({
      mutation: REPORT_LAB_ISSUE,
      variables: {
        serial: serial,
        issue: selected.value,
        details: otherDetails,
        file: pic,
      },
    });

    toastManager.add(`Issue submitted`, {
      appearance: 'success',
      autoDismissTimeout: 10000,
    });
    onClose();
  };
  return (
    <Flex flexDirection="column" style={{ width: '50vw', maxWidth: '600px' }}>
      <Heading mb={4} textAlign="center" fontWeight="bold">
        Report an Issue
      </Heading>

      <Text mb={4}>Barcode: {serial}</Text>

      {!isPictureConfirming && (
        <Flex mb={4}>
          <Text mr={3}>Issue:</Text>

          <div style={{ width: '100%' }}>
            <Select classNamePrefix="region-select" options={IssueTypes} placeholder="" value={selected} onChange={(opt) => setSelected(opt)} style={{ width: '100%' }} />
          </div>
        </Flex>
      )}
      {selected &&
        selected.value === 'QNS' &&
        email &&
        (email.indexOf('@ihdlab') > -1 || email.indexOf('ihd@palomahealth.com') > -1) && 
        (
          <UploadLabReportPicture
            setPic={(e) => {
              if (e && e.data) {
                setPic(e.data);
              } else {
                setPic(null);
              }
            }}
            setIsConfirming={(e, labs) => {
              setIsPictureConfirming(e);
            }}
          />
        )}
      {!isPictureConfirming && (
        <>
          <Text>Additional details:</Text>
          <TextAreaNoForm value={otherDetails} onChange={(e) => setOtherDetails(e.target.value)} />

          <Flex mt={4}>
            <Flex justifyContent="center" alignItems="center">
              <Button variant="pink" onClick={onCancel}>
                Cancel
              </Button>
            </Flex>
            <Flex justifyContent="center" alignItems="center">
              <Button
                variant="secondary"
                disabled={
                  !!isSubmitting || 
                  !selected || 
                  !selected.value || 
                  (selected.value === 'QNS' && !pic 
                  && email && (email.indexOf('@ihdlab') > -1 || email.indexOf('ihd@palomahealth.com') > -1)
                )
                }
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};
class LabScan extends Component {
  constructor(props) {
    super(props);
    // console.log({props})
    this.state = {
      serials: [],
      rows: [],
      addErrorVisible: false,
      isSending: false,
      setIsConfirming: false,
    };
  }

  handleSubmit = async (values, actions) => {
    const { client, toastManager } = this.props;
    const { data } = await client.query({
      query: GET_REQUISITION_FORM_DETAILS,
      variables: {
        serial: values.serial,
      },
      fetchPolicy: 'network-only',
    });

    if (data && data.getRequisitionFormDetails) {
      if (data.getRequisitionFormDetails.customError) {
        if (data.getRequisitionFormDetails.customError.userMessage) {
          console.log('e', data.getRequisitionFormDetails.customError);
          toastManager.add(data.getRequisitionFormDetails.customError.userMessage, {
            appearance: 'error',
            autoDismissTimeout: 10000,
          });
        } else {
          console.log('e', data.getRequisitionFormDetails.customError);
          toastManager.add('An error occured', {
            appearance: 'error',
            autoDismissTimeout: 10000,
          });
        }
      } else {
        const { id, firstName, lastName, dob, age, gender, testName, address, physiciansName, physiciansNpi } = data.getRequisitionFormDetails;

        const row = `${id || ''};${lastName || ''};${firstName || ''};${dob || ''};${age || ''};${gender || ''};${testName || ''};${address || ''};${physiciansName || ''};${physiciansNpi || ''};${
          values.serial
        };'';'';`;
        const rows = this.state.rows;
        rows.push((row || '').replace(/,/g, ''));
        this.setState({
          rows,
        });
        toastManager.add(`${firstName || ''} ${lastName || ''}: \n${testName}`, {
          appearance: 'success',
          autoDismissTimeout: 10000,
        });
      }
    }
    if (actions && actions.setSubmitting) {
      actions.setSubmitting(false);
    }
    // this.props.history.push(`/requisition/${values.serial}`);
  };
  download = (filename, text) => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  sendIhd = async () => {
    const { client, toastManager } = this.props;
    const { rows } = this.state;
    let finalRows = 'PWN ID;Last Name;First Name;DOB;Age;Gender;Test Name;Address;Physician Name;NPI#;Kit ID;Collection Date;Collection Time;Panel Code;' + '\n';
    //  + rows.join('\n');
    for (let index = 0; index < rows.length; index++) {
      const element = rows[index];
      finalRows += `${element}41011;\n`;
    }
    const { data } = await client.mutate({
      mutation: SEND_IHD_CSV,
      variables: {
        finalRows: finalRows,
      },
    });

    if (data && data.sendIhdCsv) {
      if (data.sendIhdCsv.ok) {
        toastManager.add(`CSV uploaded successfully`, {
          appearance: 'success',
          autoDismissTimeout: 10000,
        });
      } else {
        toastManager.add('An error occured', {
          appearance: 'error',
          autoDismissTimeout: 10000,
        });
      }
    }
  };

  generateCsv = async () => {
    const {
      session: { email },
    } = this.props;
    this.setState({
      isSending: true,
    });
    const { rows } = this.state;
    const finalRows = 'PWN ID;Last Name;First Name;DOB;Age;Gender;Test Name;Address;Physician Name;NPI#;Kit ID;Collection Date;Collection Time;' + '\n' + rows.join('\n');
    // console.log({email})
    if (email && (email.indexOf('@ihdlab') > -1 || email.indexOf('ihd@palomahealth.com') > -1)) {
      this.sendIhd();
    } else {
      this.download('paloma.csv', finalRows);
    }
    this.setState({
      rows: [],
      isSending: false,
    });
  };
  render() {
    const { isSending } = this.state;
    return (
      <Container>
        <Flex m={[3, 5]} flex={1} flexDirection="column">
          <Heading mb={4} mt={[3, 0]} ml={[1, 0]} fontSize={[4, 5]}>
            Requisition Form
          </Heading>
          <Card mb={4} p={[3, 4]}>
            <Flex flexDirection={'column'} flex={1} alignItems="center">
              <Flex flexDirection="column" flex={1}>
                <Heading mb={4}>Enter or Scan Kit Serial Number</Heading>
              </Flex>
              <Formik
                initialValues={{ willbeusing: false, serial: '' }}
                validationSchema={schema}
                onSubmit={async (values, actions) => {
                  await this.handleSubmit(values, actions);
                }}
                render={({ errors, touched, isValid, isSubmitting, handleChange, setFieldValue, handleBlur, actions, values }) => {
                  return (
                    <Form>
                      <MaskInput
                        id="inp"
                        mask="***-***-***"
                        style={{
                          marginBottom: '32px',
                          textAlign: 'center',
                          letterSpacing: '4px',
                        }}
                        type="serial"
                        name="serial"
                        value={values.serial}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoFocus
                      />

                      <Button mt={4} mb={4} mr={2} variant="primary" width={1 / 2} disabled={!isValid || isSubmitting} type="submit">
                        Add
                      </Button>
                      {/* {this.state.rows.length > 0 && ( */}
                      <Button
                        mt={4}
                        ml={2}
                        mb={4}
                        type="button"
                        variant="pink"
                        width={1 / 2}
                        disabled={!isValid || isSubmitting || !!isSending}
                        onClick={async () => {
                          this.setState({
                            addErrorVisible: values.serial,
                          });
                        }}
                      >
                        Report Error
                      </Button>
                      {/* )} */}
                      <Text style={{ textAlign: 'center' }}>
                        {this.state.rows.length} serial
                        {this.state.rows.length > 1 ? 's' : ''} scanned
                      </Text>
                      {!!this.state.rows &&
                        this.state.rows.length > 0 && (
                          <Button
                            mt={4}
                            mb={4}
                            type="button"
                            variant="pink"
                            width={1 / 2}
                            disabled={!this.state.rows || this.state.rows.length < 1 || ((values.serial && values.serial !== '___-___-___') || !!isSending)}
                            onClick={async () => {
                              await this.generateCsv();
                              setFieldValue('serial', '');
                              document.getElementById('inp').focus();
                            }}
                          >
                            Generate CSV
                          </Button>
                        )}
                      {!!this.state.addErrorVisible && (
                        <ConfirmModal
                          isOpen={this.state.addErrorVisible}
                          confirmLabel="Request"
                          onClose={() => {
                            this.setState({ addErrorVisible: null });
                          }}
                          onBackgroundClick={() => {
                            this.setState({ addErrorVisible: null });
                          }}
                          onEscapeKeydown={() => {
                            this.setState({ addErrorVisible: null });
                          }}
                          // onConfirm={()=>this.setState({addErrorVisible: false})}
                          childrenManaged
                          cardStyle={{
                            maxHeight: '90vh',
                            overflowY: 'auto',
                          }}
                        >
                          <ModalInner>
                            <AddError
                              session={this.props.session}
                              client={this.props.client}
                              toastManager={this.props.toastManager}
                              serial={this.state.addErrorVisible}
                              onCancel={() => {
                                this.setState({ addErrorVisible: null });
                              }}
                              onClose={async () => {
                                setFieldValue('serial', '');
                                this.setState({ addErrorVisible: null });
                                await sleep(300);
                                document.getElementById('inp').focus();
                              }}
                            />
                          </ModalInner>
                        </ConfirmModal>
                      )}
                    </Form>
                  );
                }}
              />
            </Flex>
          </Card>
        </Flex>
      </Container>
    );
  }
}

export default withSession(withApollo(withToastManager(LabScan)));
