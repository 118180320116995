import React from 'react';
import { getIn, Field } from 'formik';
import styled from 'styled-components';
import { Flex } from 'rebass';


const Circle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  align-self: center;
  background-color: ${props => props.theme.color.white};
  width: ${props => props.theme.px(2)};
  height: ${props => props.theme.px(2)};
  margin-right: ${props => props.theme.px(1)};
`;

const Label = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  align-self: center;
  color: ${props => props.color || props.theme.color.greyDark};
`;

const styles = {
  focus: {
    Circle: {
      borderColor(props) {
        if (props.disabled) {
          return `0 0 0 1px ${props.theme.color.grey}`;
        }
        if (props.error) {
          return `0 0 0 1px ${props.theme.color.red}`;
        }
        return `0 0 0 1px ${props.theme.color.primary}`;
      },
    },
  },
  checked: {
    Circle: {
      backgroundColor(props) {
        if (props.disabled) {
          return props.theme.color.grey;
        }
        if (props.error) {
          return props.theme.color.red;
        }
        return props.theme.color.primary;
      },
    },
  },
  Circle: {
    borderColor(props) {
      if (props.disabled) {
        return props.theme.color.greyLight;
      }
      if (props.error) {
        return props.theme.color.red;
      }
      return props.theme.color.greyLight;
    },
  },
};

const InputStyled = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(1px 0 0 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
  + ${/* sc-selector */ Circle} {
    border-color: ${styles.Circle.borderColor};
  }
  &:focus {
    + ${/* sc-selector */ Circle} {
      border-color: ${props => props.theme.color.primary};
    }
  }
  &:checked {
    + ${/* sc-selector */ Circle} {
      border-color: ${props => props.theme.color.primary};
      &::before {
        content: '';
        width: ${props => props.theme.px(1)};
        height: ${props => props.theme.px(1)};
        border-radius: 50%;
        background-color: #344f79;
      }
    }
  }
`;

const Input = props => {
  const { field, form } = props;
  const id = `${field.name}-${props.value}`;
  return (
    <Flex
      onClick={() => {
        form.setFieldValue(field.name, props.value);
      }}
      alignItems="center"
      {...props}
    >
      <Label htmlFor={id} color={props.color}>
        <InputStyled
          id={id}
          type="radio"
          {...field}
          {...props}
          checked={getIn(form.values, field.name) === props.value}
        />
        <Circle />
        {props.label}
      </Label>
    </Flex>
  );
};

const RadioInput = props => {
  return <Field {...props} type="radio" component={Input} />;
};

export const RadioInputNotForm = props => {
  const { onChecked, checked } = props;
  const id = `radio-${props.label}`;
  return (
    <Flex
      onClick={() => {
        onChecked()
      }}
      alignItems="center"
      {...props}
    >
      <Label htmlFor={id} color={props.color}>
        <InputStyled
          id={id}
          type="radio"
          {...props}
          checked={checked}
        />
        <Circle />
        {props.label}
      </Label>
    </Flex>
  );
};

export default RadioInput;
